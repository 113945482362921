import React from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import LoadingOverlay from "../util/LoadingOverlay";
import { Redirect, withRouter } from "react-router-dom";
import { accessTokenLogin, setRequestedContent, shareLinkDetails } from "../../redux/actions/index";
import { isMobile } from "react-device-detect";

class ViewSetup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initialised: false,
        };
    }

    componentDidMount() {
        this.componentDidUpdate(null, null, null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.access_token && !this.props.match.params.token) {
            if (this.props.match.params.sessionID) {
                let session_id = Number(this.props.match.params.sessionID);
                let timestamp = 0;
                if (this.props.match.params.ts) {
                    if (this.props.match.params.ts.indexOf("t") === -1) {
                        timestamp = Number(this.props.match.params.ts);
                    } else {
                        timestamp = this.props.match.params.ts;
                    }
                }
                let query_params = new URLSearchParams(this.props.location.search);
                let enhanced = query_params.get("ev") === "1";
                this.props.dispatch(
                    shareLinkDetails({
                        session_id,
                        timestamp,
                        enhanced,
                    }),
                );
            }
            this.setState({
                initialised: true,
            });
        } else if (this.props.match.params.token) {
            if (this.props.match.params.sessionID) {
                this.props.dispatch(
                    shareLinkDetails({
                        session_id: this.props.match.params.sessionID,
                        timestamp: 0,
                        enhanced: false,
                    }),
                );
            }
            let token = atob(this.props.match.params.token);
            this.props.dispatch(
                accessTokenLogin(token, (result) => {
                    console.log("Login result: ", result);
                    setTimeout(() => {
                        this.setState({
                            initialised: true,
                        });
                    }, 100);
                }),
            );
        } else if (this.props.match.params.contentData || this.props.match.params.issueID || this.props.match.params.assetID) {
            this.setState({
                initialised: true,
            });
        }
    }

    render() {
        let target_resource_data = {};
        if (this.props.match.params.sessionID) {
            let id = Number(this.props.match.params.sessionID);
            let workspace_id = Number(this.props.match.params.workspace);
            let type = "session";
            target_resource_data = {
                type: type,
                id: id,
                workspace_id: workspace_id,
            };
        } else if (this.props.match.params.contentData) {
            const decodedData = JSON.parse(atob(this.props.match.params.contentData));
            target_resource_data = decodedData;
        } else if (this.props.match.params.issueID) {
            target_resource_data = {
                type: "issue",
                id: this.props.match.params.issueID,
            };
            this.props.dispatch(setRequestedContent("issue", { id: this.props.match.params.issueID, observationID: this.props.match.params.observationID }));
        } else if (this.props.match.params.assetID) {
            target_resource_data = {
                type: "asset",
                id: this.props.match.params.assetID,
            };
            this.props.dispatch(setRequestedContent("asset", { id: this.props.match.params.assetID, observationID: this.props.match.params.observationID }));
        }

        if (!this.props.access_token) {
            let redirect = "/login";

            if (isMobile) {
                redirect = "/mobile/login";
            }

            return (
                <Redirect
                    to={{
                        pathname: redirect,
                        state: {
                            from: this.props.location,
                            target_resource_data,
                        },
                    }}
                />
            );
        } else if (this.state.initialised) {
            let redirect = "/";

            if (isMobile) {
                redirect = "/mobile/";
            }
            return (
                <Redirect
                    to={{
                        pathname: redirect,
                        state: {
                            target_resource_data,
                        },
                    }}
                />
            );
        } else {
            return <LoadingOverlay loading={true} />;
        }
    }
}

const mapStateToProps = (state) => {
    return { access_token: state.access_token };
};

export default connect(mapStateToProps)(withRouter(ViewSetup));
