import React from "react";
import { connect } from "react-redux";
import { faTrash, faSave, faAngleUp, faAngleDown, faPencilAlt, faCamera, faFont, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GithubPicker } from "react-color";
import { toggleSnapshot, changeToolMode } from "../../../redux/actions/index";
import { Modal, Popover, Button, notification } from "antd";
const { confirm } = Modal;

class SketchInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.popoverMountPoint = React.createRef();
        this.state = {};
    }

    componentDidMount() {
        this.notif_key = `annotate-notif-${Date.now()}`;

        // deliberately no handling of container element - in full screen
        // this notification will simply not display
        notification.open({
            message: "Annotate Tool",
            description: "The Annotate tool has replaced Markup. All your existing markup will remain available.",
            duration: 0,
            key: this.notif_key,
            btn: (
                <Button
                    type="primary"
                    onClick={() => {
                        notification.close(this.notif_key);
                        this.checkForChanges(
                            () => this.props.dispatch(changeToolMode("annotate")),
                            "You have unsaved changes, navigating to annotate mode will discard these changes.",
                        );
                    }}>
                    Go to Annotate
                </Button>
            ),
        });
    }

    componentWillUnmount() {
        notification.close(this.notif_key);
    }

    checkForChanges = (callback, message = "You have unsaved changes. These changes will not be shown on snapshots.") => {
        if (this.props.markupChanged) {
            confirm({
                title: "Unsaved Changes",
                content: message,
                onOk() {
                    callback();
                },
                onCancel() {},
                getContainer: this.props.fullscreenComponent.current.fullscreenComponent.current,
            });
        } else {
            callback();
        }
    };

    openSnapshot = () => {
        this.props.dispatch(toggleSnapshot());
    };

    render() {
        return (
            <div
                className="BottomToolbar BottomToolbar--markup"
                ref={this.popoverMountPoint}>
                <div
                    className="MarkupIconOuter"
                    style={{ flex: 1, justifyContent: "flex-start", paddingLeft: 10 }}>
                    <span
                        className={"MeasurementIcon"}
                        onClick={() => this.props.toggleTeam()}>
                        {this.props.showingTeam ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                        {this.props.showingTeam ? "Hide team" : "Show team"}
                    </span>
                </div>

                <div className="MarkupIconOuter">
                    <span
                        className={"MeasurementIcon" + (!this.props.sketchChangeMade && !this.props.currentSketch ? " disabled" : "")}
                        onClick={() => this.props.resetCanvas()}>
                        <FontAwesomeIcon icon={faUndo} />
                    </span>
                </div>
                <div className="MarkupIconOuter">
                    <span
                        className={"MeasurementIcon" + (!this.props.sketchChangeMade && !this.props.currentSketch ? " disabled" : "")}
                        onClick={() => this.props.clearCanvas()}>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </div>
                <div className="MarkupIconOuter">
                    <Popover
                        title="Annotate"
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content={
                            <div>
                                <div>
                                    <p style={{ marginBottom: 10 }}>The Annotate tool has replaced Markup. All your existing markup will remain available.</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.props.dispatch(changeToolMode("annotate"))}>
                                        Try Annotate
                                    </Button>
                                </div>
                            </div>
                        }
                        overlayStyle={{ width: 240 }}>
                        <span
                            // className={"MeasurementIcon disabledToolbarOption " + ((this.props.sketchMode === "pencil") ? " Active toolActive" : "")}
                            className="MeasurementIcon disabled"
                            onClick={() => this.props.changeSketchMode("pencil")}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </span>
                    </Popover>
                </div>
                <div className="MarkupIconOuter">
                    <Popover
                        title="Annotate"
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content={
                            <div>
                                <div>
                                    <p style={{ marginBottom: 10 }}>The Annotate tool has replaced Markup. All your existing markup will remain available.</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.props.dispatch(changeToolMode("annotate"))}>
                                        Try Annotate
                                    </Button>
                                </div>
                            </div>
                        }
                        overlayStyle={{ width: 240 }}>
                        <span
                            className={"MeasurementIcon disabled " + (this.props.sketchMode === "text" ? " Active toolActive" : "")}
                            onClick={() => this.props.changeSketchMode("text")}>
                            <FontAwesomeIcon icon={faFont} />
                        </span>
                    </Popover>
                </div>

                <div className="MarkupIconOuter">
                    {this.props.showingColors ? (
                        <div className="MeasurementIconPopover">
                            <GithubPicker
                                triangle="hide"
                                color={this.props.currentColor}
                                onChangeComplete={this.props.onColorChange}
                            />
                        </div>
                    ) : null}
                    {/*<span className="MeasurementIcon disabledToolbarOption" onClick={() => this.props.toggleColors()}>*/}
                    <span className="MeasurementIcon disabled">
                        {this.props.showingColors ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                        <div
                            className="colorCircle disabled"
                            style={{
                                background: this.props.currentColor,
                            }}
                        />
                    </span>
                </div>

                <div className="MarkupIconOuter">
                    <span
                        className={"MeasurementIcon" + (!this.props.sketchChangeMade ? " disabled" : "")}
                        onClick={() => this.props.handleSave()}>
                        <FontAwesomeIcon icon={faSave} />
                        Save
                    </span>
                </div>

                <div className="MarkupIconOuter">
                    <span
                        className="MeasurementIcon"
                        onClick={() => this.checkForChanges(() => this.openSnapshot())}>
                        {" "}
                        <FontAwesomeIcon icon={faCamera} />
                        Snapshot
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        markupChanged: state.markup.hasUnsavedChange,
    };
};

export default connect(mapStateToProps)(SketchInterface);
