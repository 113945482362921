import React, { useCallback, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faCheckCircle, faLink, faSpellCheck } from "@fortawesome/free-solid-svg-icons";
import OBCAutoIndicator from "components/OBC/OBCAutoIndicator";
import { Popconfirm, Tooltip } from "antd";
import { updateIssue } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AssetIDHelper from "components/util/AssetIDHelper";

export const GROUND_TRUTH_DEFAULTS = {
    Signal: '{"signal_id": ""}',
    "Structure ID Plate": '{"structure_id": ""}',
};

export const GROUND_TRUTH_DISPLAY_NAMES = {
    structure_id: "Structure ID",
    signal_id: "Signal ID",
};

export const GROUND_TRUTH_VALIDATION = {
    structure_id: {
        regex: /^[A-Z0-9]+(?:\/[A-Z0-9]+|,[A-Z0-9]+)*$/,
        error: "Ground truth must contain at least one slash or comma and only contain numbers and upper case letters eg: XX/123/YY",
    },
    signal_id: {
        regex: /^[A-Z0-9]+(?:\/[A-Z0-9]+)*$/,
        error: "Ground truth must contain at least one slash and only contain numbers and upper case letters eg. ZZ/123",
    },
    Signal: {
        regex: /^[A-Z0-9]+(?:\/[A-Z0-9]+)*$/,
        error: "Signal ID must contain at least one slash and only contain numbers and upper case letters eg: XX/123",
    },
    Speedboard: {
        regex: /^[0-9]/,
        error: "Speedboard can only contain numbers eg: 60",
    },
};

/* Explanation:
^[A-Z0-9]+: Match one or more uppercase letters or digits at the beginning.
(?:\/[A-Z0-9]+)*: Non-capturing group that allows a single / followed by one or more uppercase letters or digits, repeated zero or more times.
$: Assert the end of the string.
*/

export const validateGroundTruth = (groundTruthKey, value) => {
    const regexData = GROUND_TRUTH_VALIDATION[groundTruthKey];
    if (!regexData) {
        return false;
    }

    if (!regexData.regex.test(value)) {
        return regexData.error;
    }
    return false;
};

const emailSelector = (state) => state.userDetails.email;

const AutoStatusElement = ({ issue, groundTruthKey, onVerify }) => {
    const [hovered, setHovered] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const email = useSelector(emailSelector);
    const dispatch = useDispatch();

    const onClick = (e) => {
        const consensusValue = issue.consensus_data[groundTruthKey];
        let currentGroundTruthObject = _.clone(issue.ground_truth_data) || {};
        currentGroundTruthObject[groundTruthKey] = consensusValue;

        dispatch(
            updateIssue(
                issue.id,
                {
                    ground_truth_data: JSON.stringify(currentGroundTruthObject),
                    ground_truth_source: email,
                },
                false,
            ),
        );
        setConfirmOpen(false);
        if (onVerify) {
            onVerify();
        }
    };

    let onStatusClick = () => {};
    let elem = <OBCAutoIndicator className={"IssueStatus"} />;

    if (hovered) {
        elem = (
            <>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="VerifyIcon"
                />
                Verify
            </>
        );
        onStatusClick = (e) => {
            setConfirmOpen(true);
        };
    }

    return (
        <Popconfirm
            title="Are you sure you would like to verify this data?"
            onConfirm={onClick}
            onCancel={() => {
                setConfirmOpen(false);
                setHovered(false);
            }}
            visible={confirmOpen}
            okText="Yes"
            cancelText="No">
            <div
                onClick={onStatusClick}
                className="StatusContainer Clickable"
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => {
                    if (!confirmOpen) {
                        setHovered(false);
                    }
                }}>
                {elem}
            </div>
        </Popconfirm>
    );
};

export const StatusElement = ({ status, issue, groundTruthKey, onVerify }) => {
    if (status === "verified") {
        let tooltipElem = (
            <div className="VerifiedTooltipContainer">
                <p>
                    Verified by <b>{issue.ground_truth_source}</b>
                </p>
            </div>
        );

        if (issue.consensus_data && !_.isNil(issue.consensus_data[groundTruthKey])) {
            tooltipElem = (
                <div className="VerifiedTooltipContainer">
                    <p>
                        Verified by <b>{issue.ground_truth_source}</b>
                    </p>
                    <p>
                        Consensus was <b>{AssetIDHelper.from_asset_data(issue).csv_to_slashes()}</b>
                    </p>
                </div>
            );
        }

        return (
            <Tooltip title={tooltipElem}>
                <div className="StatusContainer">
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="VerifiedIcon"
                    />
                    <p>Verified</p>
                </div>
            </Tooltip>
        );
    }
    if (status === "auto") {
        return (
            <AutoStatusElement
                groundTruthKey={groundTruthKey}
                issue={issue}
                onVerify={onVerify}
            />
        );
    }
    return null;
};

export const filterIssues = (dateFilter, statusFilter, priorityFilter, issueGroupFilter, issues, searchQuery, extraFilters = {}, showClosed = false) => {
    if (!issues) {
        return [];
    }

    const newList = issues.filter((issue) => {
        if (!showClosed) {
            if (issue.state === 2) {
                return false;
            }
        }

        if (dateFilter.from && issue.summary_data.last_observed < dateFilter.from * 1000) {
            return false;
        }

        if (dateFilter.to && issue.summary_data.last_observed > dateFilter.to * 1000) {
            return false;
        }

        if (statusFilter === "updated" && (issue.state !== 1 || issue.state_change_ts * 1000 > issue.match_ts)) {
            return false;
        } else if (statusFilter === "acknowledged" && (issue.state !== 1 || issue.state_change_ts * 1000 < issue.match_ts)) {
            return false;
        } else if (statusFilter === "closed" && issue.state !== 2) {
            return false;
        } else if (statusFilter === "new" && issue.state !== 0) {
            return false;
        } else if (statusFilter === "all") {
            if (![0, 1].includes(issue.state)) {
                return false;
            }
        } else if (statusFilter === "new_and_updated") {
            if (issue.state !== 0 && issue.state !== 1) {
                // states that are neither new nor acknowledged
                return false;
            }
            if (issue.state === 1 && issue.state_change_ts * 1000 > issue.match_ts) {
                // acknowledged since matched
                return false;
            }
        }

        if (priorityFilter !== "all") {
            if (priorityFilter === null) {
                if (issue.priority) {
                    return false;
                }
            }

            if (priorityFilter > issue.priority) {
                return false;
            }
        }

        if (!issue.issue_group_id || (issueGroupFilter && issue.issue_group_id !== parseInt(issueGroupFilter))) {
            return false;
        }

        if (searchQuery && searchQuery.length > 0) {
            const noteFiltered = !issue.note || issue.note.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1;
            const idFiltered = String(issue.id) !== searchQuery;

            let groundTruthFiltered = true;
            if (!_.isEmpty(issue.ground_truth_data)) {
                Object.values(issue.ground_truth_data).forEach((value) => {
                    if (typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())) {
                        groundTruthFiltered = false;
                    }
                });
            }

            let consensusFiltered = true;
            const SEARCHABLE_CONSENSUS_FIELDS = ["signal_id", "structure_id"];
            if (!_.isEmpty(issue.consensus_data)) {
                Object.keys(issue.consensus_data).forEach((key) => {
                    if (!SEARCHABLE_CONSENSUS_FIELDS.includes(key)) {
                        return;
                    }
                    const value = issue.consensus_data[key];
                    if (typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())) {
                        consensusFiltered = false;
                    }
                });
            }

            if (noteFiltered && idFiltered && groundTruthFiltered && consensusFiltered) {
                return false;
            }
        }

        if (extraFilters.groundTruth !== null && extraFilters.groundTruth !== undefined) {
            const groundTruthFilter = extraFilters.groundTruth;
            if (groundTruthFilter === true && _.isEmpty(issue.ground_truth_data)) {
                return false;
            } else if (groundTruthFilter === false && !_.isEmpty(issue.ground_truth_data)) {
                return false;
            }
        }

        if (!_.isEmpty(extraFilters.classSearchValue)) {
            const classSearchName = Object.keys(extraFilters.classSearchValue)[0];
            const classSearchValues = extraFilters.classSearchValue[classSearchName];
            let shouldFilterOut = false;
            switch (classSearchName.toLocaleLowerCase()) {
                case "thermal hotspot":
                    Object.keys(classSearchValues).forEach((filterKey) => {
                        if (filterKey === "temp") {
                            let filterValues = classSearchValues[filterKey].values;
                            let filterBounds = classSearchValues[filterKey].bounds;

                            if (issue.summary_data.fields.highest_temperature) {
                                const isInRange = _.inRange(issue.summary_data.fields.highest_temperature, filterValues[0], filterValues[1] + 1);
                                // +1 is used because inrange is exclusive of the end of the range (and we want to be inclusive)
                                if (!isInRange) {
                                    shouldFilterOut = true;
                                }
                            } else if (filterValues[0] > filterBounds[0] || filterValues[1] < filterBounds[1]) {
                                shouldFilterOut = true;
                            }
                        }
                    });
                    if (shouldFilterOut) {
                        return false;
                    }
                    break;

                case "jointed rail end":
                    Object.keys(classSearchValues).forEach((filterKey) => {
                        if (filterKey === "distance") {
                            let filterValues = classSearchValues[filterKey].values;
                            let filterBounds = classSearchValues[filterKey].bounds;

                            if (issue.summary_data.fields.max_gap_mm && issue.summary_data.fields.min_gap_mm) {
                                if (
                                    (issue.summary_data.fields.max_gap_mm > filterValues[1] || issue.summary_data.fields.min_gap_mm < filterValues[0]) &&
                                    filterValues[1] > 0
                                ) {
                                    shouldFilterOut = true;
                                }
                            } else if (filterValues[0] > filterBounds[0] || filterValues[1] < filterBounds[1]) {
                                shouldFilterOut = true;
                            }
                        }
                    });
                    if (shouldFilterOut) {
                        return false;
                    }
                    break;

                case "tensioner":
                    Object.keys(classSearchValues).forEach((filterKey) => {
                        let filterValues = classSearchValues[filterKey].values;
                        let filterBounds = classSearchValues[filterKey].bounds;

                        if (filterKey === "distance") {
                            if (issue.summary_data.fields.severity) {
                                if (
                                    (issue.summary_data.fields.severity > filterValues[1] || issue.summary_data.fields.severity < filterValues[0]) &&
                                    filterValues[1] > 0
                                ) {
                                    shouldFilterOut = true;
                                }
                            } else if (filterValues[0] > filterBounds[0] || filterValues[1] < filterBounds[1]) {
                                //ooo
                                shouldFilterOut = true;
                            }
                        }

                        // bottom_out_temp range set to max 50C, if max = 50C display all issues
                        if (filterKey === "bottom_out_temp") {
                            if (issue.summary_data.fields.bottom_out_temperature) {
                                const maxRange = filterValues[1] < 50 ? filterValues[1] + 1 : 99999999;
                                const isInRange = _.inRange(issue.summary_data.fields.bottom_out_temperature, filterValues[0], maxRange);
                                if (!isInRange) {
                                    shouldFilterOut = true;
                                }
                            } else if (filterValues[0] > filterBounds[0] || filterValues[1] < filterBounds[1]) {
                                //ooo
                                shouldFilterOut = true;
                            }
                        }
                    });
                    if (shouldFilterOut) {
                        return false;
                    }
                    break;

                case "ramp":
                    Object.keys(classSearchValues).forEach((filterKey) => {
                        if (filterKey === "distance") {
                            let filterValues = classSearchValues[filterKey].values;
                            let filterBounds = classSearchValues[filterKey].bounds;

                            if (issue.summary_data.fields.max_gap_mm && issue.summary_data.fields.min_gap_mm) {
                                if (
                                    (issue.summary_data.fields.max_gap_mm > filterValues[1] || issue.summary_data.fields.min_gap_mm < filterValues[0]) &&
                                    filterValues[1] > 0
                                ) {
                                    shouldFilterOut = true;
                                }
                            } else if (filterValues[0] > filterBounds[0] || filterValues[1] < filterBounds[1]) {
                                shouldFilterOut = true;
                            }
                        }
                    });
                    if (shouldFilterOut) {
                        return false;
                    }
                    break;

                case "fishplate":
                    Object.keys(classSearchValues).forEach((filterKey) => {
                        if (filterKey === "health") {
                            let filterValue = classSearchValues[filterKey].values;
                            if (filterValue.toLocaleLowerCase() !== "all") {
                                if (
                                    issue.summary_data.fields.classification &&
                                    issue.summary_data.fields.classification.toLocaleLowerCase() !== filterValue.toLocaleLowerCase()
                                ) {
                                    shouldFilterOut = true;
                                }
                            }
                        }
                    });
                    if (shouldFilterOut) {
                        return false;
                    }
                    break;

                default:
                    return true;
            }
        }

        return true;
    });

    return newList;
};

export const combineGroundTruthConsensus = (groundTruthData, consensusData, assetType) => {
    let fields = [];

    let groundTruthFields = JSON.parse(_.get(GROUND_TRUTH_DEFAULTS, [assetType], "{}"));
    let fieldsToDisplay = Object.keys(groundTruthFields);

    fieldsToDisplay.forEach((fieldName) => {
        const fieldObj = {
            name: _.get(GROUND_TRUTH_DISPLAY_NAMES, [fieldName], fieldName),
        };
        fieldObj.fieldName = fieldName;
        if (groundTruthData[fieldName]) {
            fieldObj.value = groundTruthData[fieldName];
            fieldObj.status = "verified";
        } else if (consensusData[fieldName]) {
            fieldObj.value = consensusData[fieldName];
            fieldObj.status = "auto";
        } else {
            fieldObj.value = "Unknown";
            fieldObj.status = "unknown";
        }
        if (fieldName === "signal_id") {
            fieldObj.icon = faSpellCheck;
        }
        fields.push(fieldObj);
    });
    if (consensusData["confidence"]) {
        fields.push({
            name: "Confidence",
            value: consensusData["confidence"],
            fieldName: "confidence",
            icon: faBullseye,
        });
    }
    if (consensusData["coherence"]) {
        fields.push({
            name: "Coherence",
            value: `${consensusData["coherence"] * 100}%`,
            fieldName: "coherence",
            icon: faLink,
        });
    }
    return fields;
};
