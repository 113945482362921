import React from "react";
import { connect } from "react-redux";
import DeviceInformation from "../DeviceInformation";
import _ from "lodash";
import ImagePlayer from "./ImagePlayer";
import { Select } from "antd";

const { Option, OptGroup } = Select;

class ImageComponent extends React.PureComponent {
    render() {
        let enhancedIndex = 0;
        if (this.props.isEnhanced === "enhanced") {
            enhancedIndex = 2;
        } else if (this.props.isEnhanced === "low_res") {
            enhancedIndex = 1;
        }

        let typeIndex = this.props.isStills * (this.props.currentSession.first_seen > 1631710005 ? 3 : 2) + enhancedIndex * 1;
        const selectedStreamReference = `${this.props.sources.length - 1}.${typeIndex}`;

        return (
            <div className="ImageContainer Center-Content">
                <ImagePlayer
                    playerControls={true}
                    playSpeed={1}
                />
                <div style={{ display: "flex", backgroundColor: "#6C43DF", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
                        <span className="VideoControls__Item">
                            <Select
                                className="VideoControlsDropdown"
                                dropdownClassName="VideoControlsDropdownList"
                                size="small"
                                value={selectedStreamReference}
                                onChange={(value) =>
                                    this.props.selectStream({
                                        target: {
                                            value: value,
                                        },
                                    })
                                }
                                dropdownMatchSelectWidth={false}>
                                {_.map(this.props.sources, (source) => {
                                    return (
                                        <OptGroup
                                            key={source.name}
                                            label={source.name}>
                                            {_.map(source.streams, (stream, stream_index) => {
                                                return (
                                                    <Option
                                                        disabled={stream.disabled}
                                                        key={stream.name}
                                                        value={`${stream.videoIndex}.${stream_index}`}>
                                                        {stream.name}
                                                    </Option>
                                                );
                                            })}
                                        </OptGroup>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ playlist, sessions }) => {
    const currentSession = _.get(sessions, [playlist.data.routeID], {});

    return {
        isEnhanced: playlist.position.isEnhanced,
        isStills: playlist.position.isStills,
        currentSession,
    };
};

export default connect(mapStateToProps)(ImageComponent);
