import { faWindowRestore } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import SidekickPopoutContent from "components/SidekickPopoutContent";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const widgetSelector = (state) => state.widgets;

const RailInspectHeaderWidget = memo(({ manualAlignmentToolActive, autoScrollActive }) => {
    const widgets = useSelector(widgetSelector);

    return (
        <Tippy
            zIndex="2000"
            arrow={true}
            placement="bottom"
            interactive={true}
            maxWidth={"none"}
            theme={"aivr"}
            trigger="click"
            content={<SidekickPopoutContent openWidgets={widgets} />}>
            <button
                className={"inspection-default-button" + (manualAlignmentToolActive || autoScrollActive ? " Disabled" : "")}
                disabled={manualAlignmentToolActive || autoScrollActive}>
                <div className="TabSectionIcon">
                    <FontAwesomeIcon icon={faWindowRestore} />
                </div>
                Widgets
            </button>
        </Tippy>
    );
});

export default RailInspectHeaderWidget;
