import { combineReducers } from "redux";
import {
    SET_SESSION_SEARCH,
    SET_SESSION_FAVOURITES,
    SET_SESSION_DATES,
    SET_SESSION_TAGS,
    SET_SESSION_FLAGGED,
    SESSION_IDS,
    SET_SESSIONS_REFRESHING,
    EXIT_CURRENT_DASHBOARD,
    SESSION_FILTERS_DEFAULTS,
    SET_HIDE_LOW_QUALITY_SESSIONS,
    SESSION_QA_TAGS,
} from "redux/actions";

const hideLowQualitySessions = (state = false, action) => {
    if (action.type === SET_HIDE_LOW_QUALITY_SESSIONS) {
        state = action.hideLowQualitySessions;
    }
    return state;
};

const sessionQATags = (state = SESSION_QA_TAGS, action) => {
    return state;
};

const sessionSearchFilter = (state = SESSION_FILTERS_DEFAULTS.search, action) => {
    if (action.type === SET_SESSION_SEARCH) {
        state = action.query;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = SESSION_FILTERS_DEFAULTS.search;
    }

    return state;
};

const sessionFavouritesFilter = (state = SESSION_FILTERS_DEFAULTS.favourites, action) => {
    if (action.type === SET_SESSION_FAVOURITES) {
        state = action.favouritesOnly;
    }
    // if user have favourites toggled or fav. category selected and changes workspace select default favourites state
    else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = SESSION_FILTERS_DEFAULTS.favourites;
    }
    return state;
};

const sessionsDateFilter = (state = SESSION_FILTERS_DEFAULTS.date, action) => {
    if (action.type === SET_SESSION_DATES) {
        state = action.dates;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = SESSION_FILTERS_DEFAULTS.date;
    }
    return state;
};

const sessionsTagFilter = (state = SESSION_FILTERS_DEFAULTS.tags, action) => {
    if (action.type === SET_SESSION_TAGS) {
        state = action.tags;
    }
    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
};

const sessionsFlaggedFilter = (state = SESSION_FILTERS_DEFAULTS.show_flagged, action) => {
    if (action.type === SET_SESSION_FLAGGED) {
        state = action.flagged;
    }
    return state;
};

const refreshing = (state = true, action) => {
    if ([SET_SESSION_SEARCH, SET_SESSION_FAVOURITES, SET_SESSION_DATES, SET_SESSION_TAGS, SET_SESSION_FLAGGED, SET_SESSIONS_REFRESHING].includes(action.type)) {
        state = true;
    }

    if (action.type === SESSION_IDS) {
        state = false;
    }
    return state;
};

const sessionListFilters = combineReducers({
    search: sessionSearchFilter,
    favourites: sessionFavouritesFilter,
    date: sessionsDateFilter,
    tags: sessionsTagFilter,
    show_flagged: sessionsFlaggedFilter,
    refreshing,
    filter_qa_tags: hideLowQualitySessions,
    qa_tags: sessionQATags,
});

export default sessionListFilters;
