import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Measure from "react-measure";
import { fabric } from "fabric";
import OBCSpinner from "./util/OBC";
import { MEMOIZED_API_BASE_URL } from "./util/HostUtils";
import { useSelector } from "react-redux";
import { getRawApiImage } from "./RawImageComponent";

const EMPTY_FILTERS = {};
const EMPTY_OVERLAY_BOXES = [];
const STROKE_WIDTH = 1;

const currentDashboardSelector = (state) => _.find(state.dashboards, (dashboard) => dashboard.access_token === state.access_token);

const ZoomableImage = ({
    imgSrc,
    zoom,
    updateZoom,
    overlayBoxes = EMPTY_OVERLAY_BOXES,
    filters = EMPTY_FILTERS,
    bboxColor = "red",
    withSpinner = false,
    tag = false,
}) => {
    const [dimensions, setDimensions] = useState({});
    const [fabricCanvasRef, setFabricCanvasRef] = useState(null);
    const [fabricCanvas, setFabricCanvas] = useState(null);
    const [ratioChangeForZoom, setRatioChangeForZoom] = useState(null);
    const [loadingImage, setLoadingImage] = useState(true);
    const [imageObj, setImgObj] = useState(null);
    const [formattedImageSource, setFormattedImageSource] = useState();
    const [imageCacheTimestamp, setImageCacheTimestamp] = useState();

    const currentDashboard = useSelector(currentDashboardSelector);

    useEffect(() => {
        if (fabricCanvasRef) {
            const canvas = new fabric.Canvas(fabricCanvasRef, {
                defaultCursor: "default",
                uniformScaling: false,
                selection: false,
                containerClass: "ZoomableImageCanvasContainer",
            });
            setFabricCanvas(canvas);
        }
    }, [fabricCanvasRef]);

    const limitCanvasPosition = useCallback((canvas) => {
        const canvasViewPort = canvas.viewportTransform;

        const bottomEndPoint = canvas.height * (canvasViewPort[0] - 1);
        if (canvasViewPort[5] >= 0 || -bottomEndPoint > canvasViewPort[5]) {
            canvasViewPort[5] = canvasViewPort[5] >= 0 ? 0 : -bottomEndPoint;
        }

        const rightEndPoint = canvas.width * (canvasViewPort[0] - 1);
        if (canvasViewPort[4] >= 0 || -rightEndPoint > canvasViewPort[4]) {
            canvasViewPort[4] = canvasViewPort[4] >= 0 ? 0 : -rightEndPoint;
        }
        canvas.viewportTransform = canvasViewPort;
    }, []);

    const onMove = useCallback(
        (opt, canvas) => {
            if (canvas.isDragging) {
                const delta = new fabric.Point(opt.e.movementX, opt.e.movementY);
                canvas.relativePan(delta);
                limitCanvasPosition(canvas);
            }
        },
        [limitCanvasPosition],
    );

    const onScroll = useCallback(
        (opt) => {
            let zoom = fabricCanvas.getZoom();
            if (opt) {
                const delta = opt.e.deltaY;
                zoom *= 0.999 ** delta;
                zoom = Math.max(Math.min(20, zoom), 1);
                fabricCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
            }
            limitCanvasPosition(fabricCanvas);
            if (updateZoom) {
                updateZoom(zoom);
            }
        },
        [fabricCanvas, limitCanvasPosition],
    );

    useEffect(() => {
        if (fabricCanvas) {
            fabricCanvas.on("mouse:down", function (_) {
                this.isDragging = true;
            });

            fabricCanvas.on("mouse:move", function (opt) {
                onMove(opt, this);
            });

            fabricCanvas.on("mouse:up", function (_) {
                this.setViewportTransform(this.viewportTransform);
                this.isDragging = false;
            });

            fabricCanvas.on("mouse:wheel", function (opt) {
                onScroll(opt);
            });

            return () => {
                fabricCanvas.__eventListeners["mouse:move"] = [];
                fabricCanvas.__eventListeners["mouse:down"] = [];
                fabricCanvas.__eventListeners["mouse:up"] = [];
                fabricCanvas.__eventListeners["mouse:wheel"] = [];
            };
        }
    }, [fabricCanvas, onMove, onScroll]);

    const imageFilters = useMemo(() => {
        const imageFilters = [];
        if (!_.isNil(filters.brightness)) {
            imageFilters.push(new fabric.Image.filters.Brightness({ brightness: filters.brightness }));
        }
        if (!_.isNil(filters.contrast)) {
            imageFilters.push(new fabric.Image.filters.Contrast({ contrast: filters.contrast }));
        }
        return imageFilters;
    }, [filters]);

    useEffect(() => {
        if (imageObj) {
            imageObj.filters = imageFilters;
            imageObj.applyFilters();
            fabricCanvas.renderAll();
        }
    }, [imageObj, imageFilters, fabricCanvas]);

    useEffect(() => {
        if (imgSrc && currentDashboard) {
            setLoadingImage(true);
            getRawApiImage(imgSrc, currentDashboard.access_token, (imageSrc) => {
                setFormattedImageSource(imageSrc);
                setImageCacheTimestamp(Date.now());
            });
        }
    }, [currentDashboard, imgSrc]);

    useEffect(() => {
        if (fabricCanvas) {
            fabric.Image.fromURL(
                formattedImageSource,
                function (img) {
                    fabricCanvas.clear();
                    const widthRatioChange = dimensions.width / img.width;
                    const heightRatioChange = dimensions.height / img.height;

                    let ratioChange;
                    if (img.width * heightRatioChange > dimensions.width) {
                        ratioChange = widthRatioChange;
                        setRatioChangeForZoom(widthRatioChange);
                    } else {
                        ratioChange = heightRatioChange;
                        setRatioChangeForZoom(heightRatioChange);
                    }

                    const newWidth = img.width * ratioChange;
                    const newHeight = img.height * ratioChange;

                    fabricCanvas.setWidth(newWidth);
                    fabricCanvas.setHeight(newHeight);

                    const imageData = {
                        left: 0,
                        top: 0,
                        controls: {},
                        hasBorders: false,
                        selectable: false,
                        scaleY: ratioChange,
                        scaleX: ratioChange,
                    };

                    const oImg = img.set(imageData);
                    fabricCanvas.add(oImg);
                    setImgObj(oImg);

                    overlayBoxes.forEach((bbox) => {
                        const [left, top, width, height] = bbox;
                        const box = new fabric.Rect({
                            left: left * ratioChange,
                            top: top * ratioChange,
                            width: width * ratioChange,
                            height: height * ratioChange,
                            fill: "",
                            strokeWidth: STROKE_WIDTH,
                            centeredScaling: false,
                            stroke: bboxColor,
                            controls: {
                                ...fabric.Text.prototype.controls,
                                mtr: new fabric.Control({ visible: false }),
                            },
                            selectable: false,
                            hoverCursor: "all-scroll",
                            strokeUniform: true,
                        });
                        fabricCanvas.add(box);

                        if (tag) {
                            const inTopHalf = top * ratioChange + (height * ratioChange) / 2 < newHeight / 2;

                            const textBox = new fabric.Text(tag, {
                                left: left * ratioChange,
                                top: inTopHalf ? (top + height) * ratioChange + 10 : top * ratioChange - 15,
                                fontSize: 11,
                                fontFamily: "Helvetica",
                                fontWeight: 100,
                                stroke: bboxColor,
                                textBackgroundColor: "rgba(0, 0, 0, 0.35)",
                                padding: 3,
                                charSpacing: 150,
                            });
                            fabricCanvas.add(textBox);
                        }
                    });
                    setLoadingImage(false);
                },
                { crossOrigin: "anonymous" },
            );
            fabricCanvas.renderAll();
        }
    }, [bboxColor, dimensions.height, dimensions.width, fabricCanvas, formattedImageSource, overlayBoxes, tag, imageCacheTimestamp]);

    useEffect(() => {
        if (fabricCanvas && imageObj) {
            const currentZoom = fabricCanvas.getZoom();
            if (currentZoom !== zoom) {
                let x = fabricCanvas.width / 2;
                let y = fabricCanvas.height / 2;
                let zoomBasedOnBbox = 0;

                if (overlayBoxes && overlayBoxes[0] && ratioChangeForZoom) {
                    const [left, top, width, height] = overlayBoxes[0];
                    let leftOffset = 0;
                    let topOffset = 0;
                    if (left * ratioChangeForZoom < fabricCanvas.width / 2) {
                        leftOffset = width * ratioChangeForZoom;
                    }
                    if (top * ratioChangeForZoom < fabricCanvas.height / 2) {
                        topOffset = height * ratioChangeForZoom;
                    }
                    x = left * ratioChangeForZoom + (width * ratioChangeForZoom - leftOffset);
                    y = top * ratioChangeForZoom + (height * ratioChangeForZoom - topOffset);

                    zoomBasedOnBbox = fabricCanvas.height / (height * ratioChangeForZoom);
                }

                fabricCanvas.zoomToPoint(new fabric.Point(x, y), zoomBasedOnBbox < zoom && zoomBasedOnBbox ? zoomBasedOnBbox : zoom);
                limitCanvasPosition(fabricCanvas);
                fabricCanvas.renderAll();
            }
        }
    }, [fabricCanvas, imageObj, zoom, ratioChangeForZoom, overlayBoxes, limitCanvasPosition]);

    return (
        <>
            {loadingImage && withSpinner && (
                <div
                    className="SpinnerContainer"
                    style={{ position: "absolute", zIndex: 2000 }}>
                    <OBCSpinner
                        size={80}
                        speed={3}
                        colorScheme={"mono"}
                    />
                </div>
            )}
            <Measure
                bounds
                onResize={(contentRect) => {
                    setDimensions(contentRect.bounds);
                }}>
                {({ measureRef }) => {
                    return (
                        <div
                            className="ZoomableImage"
                            ref={measureRef}>
                            <canvas
                                ref={(c) => setFabricCanvasRef(c)}
                                className="ZoomFabricCanvas"
                            />
                        </div>
                    );
                }}
            </Measure>
        </>
    );
};

export default ZoomableImage;
