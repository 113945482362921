import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import DayPicker from "react-day-picker";
import { fetchANPRData } from "redux/actions/index";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import { Slider, Button, Input } from "antd";
const { Search } = Input;
class ANPRDrawer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.search = React.createRef();
        this.state = {
            confidenceValues: [90],
            confidenceFilterMin: 90,
            confidenceFilterMax: 100,
            from: null,
            to: null,
        };
    }

    handleDayClick = (day) => {
        const range = DayPicker.DateUtils.addDayToRange(day, this.state);
        //this lets the user filter by one date if they want, this isn't default behaviour of the day-picker lib so this is a hacky way to get around that
        //without this, the default behaviour is to treat the "to" date as the current date which is undesired.
        if (range.to === undefined || range.to === null) {
            range.to = day;
            range.from = day;
        }
        this.props.dispatch(fetchANPRData(moment(range.from).startOf("day").unix(), moment(range.to).endOf("day").unix()));

        this.setState(range);
        this.setState({ reset: false });
    };
    renderAnprData = (anprData) => {
        let confidentAnprData = _.filter(
            anprData,
            ({ confidence }) => confidence >= this.state.confidenceFilterMin && confidence <= this.state.confidenceFilterMax,
        ); //confidence threshold
        let dedupedAnprData = _.uniqBy(confidentAnprData, (o) => {
            return o.result.results[0].plate;
        });
        let sortedAnprData = _.groupBy(dedupedAnprData, "confidence");
        let i = 0;
        this.setState({ filterResults: _.size(dedupedAnprData) });
        return _.map(sortedAnprData, (confGroup) => {
            return (
                <div
                    key={i++}
                    style={{ margin: "20px 0px" }}>
                    <span style={{ fontSize: 14, fontWeight: "bold", margin: "5px 5px" }}>Confidence: {confGroup[0].confidence}%</span>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: 550 }}>
                        {confGroup.map((hit) => {
                            return (
                                <div
                                    key={i++}
                                    className="ANPR-ResultContainer">
                                    <div style={{ display: "flex", padding: 3.5 }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: 60 }}>
                                            <span className="ANPR-Plate">{hit.result.results[0].plate}</span>
                                            <Tippy
                                                placement="right"
                                                sticky={true}
                                                plugins={[sticky]}
                                                arrow={true}
                                                theme="light"
                                                content={
                                                    <img
                                                        src={hit.vehicle_image}
                                                        crossOrigin={"anonymous"}
                                                        alt="ANPR Result"
                                                        style={{ width: 200 }}
                                                    />
                                                }>
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: 101,
                                                        borderRadius: 5,
                                                        border: "2px solid #6C43DF",
                                                        color: "rgb(85, 55, 168)",
                                                        fontWeight: "bold",
                                                        padding: "2.5px 0px",
                                                    }}>
                                                    PREVIEW
                                                </span>
                                            </Tippy>
                                        </div>
                                        <span
                                            style={{
                                                width: 5,
                                                height: 40,
                                                marginLeft: 10,
                                                backgroundColor: hit.dvla_details.colour,
                                                borderRadius: 30,
                                                border: hit.dvla_details.colour === "WHITE" ? "1px solid black" : null,
                                            }}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: 5 }}>
                                            <span className="ANPR-PropText">{hit.dvla_details.make}</span>
                                            <span className="ANPR-PropText">{hit.dvla_details.model}</span>
                                            <div className="ANPR-TaxMotContainer">
                                                <span className={hit.dvla_details.mot ? "ANPR-TaxMotText-valid" : "ANPR-TaxMotText-invalid"}>
                                                    {hit.dvla_details.mot ? "Valid MOT" : "MOT Expired"}
                                                </span>
                                                <span className={hit.dvla_details.mot ? "ANPR-TaxMotText-valid" : "ANPR-TaxMotText-invalid"}>
                                                    {hit.dvla_details.taxed ? "Taxed" : "Untaxed"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        });
    };
    updateConfidenceFilter = (values) => {
        this.setState({
            confidenceValues: values,
            confidenceFilterMin: values[0],
            confidenceFilterMax: values[1],
        });
    };
    resetFilters = () => {
        this.setState({
            reset: true,
            from: null,
            to: null,
        });
        this.search.current.input.state.value = "";
    };
    plateSearch = (value) => {
        this.props.dispatch(fetchANPRData(moment(this.state.from).startOf("day").unix(), moment(this.state.to).endOf("day").unix(), value));
    };
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <div className="ANPR-InnerDrawer">
                <div className="ANPR-FilterContainer">
                    <DayPicker
                        className="Selectable Inverted"
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                    />
                    <div className="ANPR-Filters">
                        <div className="ANPR-ConfidenceFilter">
                            <label>
                                Confidence: {this.state.confidenceFilterMin}% to {this.state.confidenceFilterMax}%
                            </label>
                            <Slider
                                range
                                defaultValue={[90, 100]}
                                step={5}
                                onChange={(values) => this.updateConfidenceFilter(values)}
                            />
                        </div>
                        <div className="ANPR-PlateFilter">
                            <label>Search by License Plate</label>
                            <Search
                                ref={this.search}
                                allowClear
                                placeholder="Enter Plate Number"
                                onChange={(e) => this.plateSearch(e.target.value)}
                                spellCheck={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="ANPR-ResultTextContainer">
                    {this.state.from === null ? (
                        "Select dates to see results"
                    ) : (
                        <div style={{ display: "flex", margin: "10px 0px" }}>
                            <span style={{ marginRight: 5 }}>Found {this.state.filterResults} results</span>
                            <Button
                                type="danger"
                                shape="round"
                                size="small"
                                icon="close"
                                onClick={this.resetFilters}>
                                <span style={{ fontWeight: "bold" }}>Reset</span>
                            </Button>
                        </div>
                    )}
                </div>
                <div className="ANPR-Container">{!this.state.reset ? this.renderAnprData(this.props.anprData) : null}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    anprData: state.anprData,
    dateRange: state.sessionFilters.dateRange,
});

export default connect(mapStateToProps)(ANPRDrawer);
