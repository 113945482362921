import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import RailInspectNormalSource from "./display/image/RailInspectNormalSource";
import RailInspectControls from "./display/image/RailInspectControls";
import _, { set } from "lodash";
import {
    selectBookmark,
    routeSelected,
    logout,
    currentPlaylistPosition,
    getRouteCoordinateSystems,
    railInspectionOpenedAudit,
    railInspectionExited,
    refresh,
    getInspectionBookmarks,
    wsStatusPoll,
    toggleInspectionAnnotationMode,
    fetchUserAnnotationTypes,
    getRailInspectionConditions,
    getSessionData,
} from "../redux/actions/index";
import RailInspectHeader from "./display/image/RailInspectHeader";
import { Redirect } from "react-router-dom";
import RailInspectBigScroller from "./display/image/RailInspectBigScroller";
import Measure from "react-measure";
import DetailView from "./display/image/DetailView";
import PinCursor from "./DropPinCursor";
import PinCursorLine from "./AlignPinCursorLine";
import Settings from "./display/image/Settings";
import InspectionSnapshotModal from "./display/image/InspectionSnapshotModal";
import { RailInspectionLoader } from "../datastores/railInspectionStore";
import RailInspectionExtraDisplay from "./display/image/RailInspectionExtraDisplay";
import { notification } from "antd";
import GenericDialog from "./GenericDialog";
import { useHistory } from "react-router";
import AssetNavigation from "./display/image/inspectionNavigation/AssetNavigation";
import ContentNavigation from "./display/image/inspectionNavigation/ContentNavigation";
import SperrySuspectNavigation from "./display/image/inspectionNavigation/SperrySuspectNavigation";

const naiveIndexSelector = (state) => state.playlist.position.currentIndex;
const accessTokensSelector = (state) => state.access_token;
const coordsSelector = (state) => state.playlist.position.coords;
const supportedCoordinateSystemsSelector = (state) => state.routeCoordinateSystems;
const reduxSessionIDSelector = (state) => state.playlist.data.routeID;
const inspectionBookmarksSelector = (state) => state.railInspection.bookmarks;
const detailViewOpenSelector = (state) => state.railInspection.detailViewOpen;
const settingsOpenSelector = (state) => state.railInspection.settingsOpen;
const playlistSelector = (state) => state.playlist.data;
const openRailInspectionSourcesSelector = (state) => state.railInspection.railInspectionSourcesOpen;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const detectionsWindowOpenSelector = (state) => state.railInspection.detections.windowOpen;
const annotateModeEnabledSelector = (state) => state.railInspection.annotations.modeEnabled;
const userEmailSelector = (state) => state.userDetails.email;
const displayExtraLayersSelector = (state) => state.railInspection.displayExtraLayers;
const timelineWidgetOpenSelector = (state) => state.railInspection.timelineWidget.open;
const userPreferencesShowRailManualAlignmentSelector = (state) => _.get(state.userPreferences, "showRailManualAlignment", true);
const selectedRailInspectionImageTimestampSelector = (state) => state.railInspection.selectedRailInspectionImage.timestamp;

const INITIAL_SECONDARY_RAIL_CONFIG = {
    detail_cameras: [],
    inspection_images: [],
    video_offsets: {},
};

const INITIAL_SECONDARY_RAIL_ALIGNMENT = {
    primaryIndex: 0,
    secondaryIndex: 0,
    adjustment: 0,
    primaryIsUpstream: false,
    secondaryIsUpstream: false,
};

const INITIAL_SECONDARY_RAIL_IMAGE_STATE = {
    loaded: false,
    percentageComplete: 0,
    sessionID: null,
};

const RailInspectionComponent = ({ match }) => {
    const [assetNavigationWindowOpen, setAssetNavigationWindowOpen] = useState(false);
    const [bookmarksAndAnnotationsWindowOpen, setBookmarksAndAnnotationsWindowOpen] = useState(false);
    const [sperrySuspectNavigationWindowOpen, setSperrySuspectNavigationWindowOpen] = useState(false);

    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
    const [markerToolActive, setMarkerToolActive] = useState(false);
    const [manualAlignmentToolActive, setManualAlignmentToolActive] = useState(false);
    const [labelWindowOpen, setLabelWindowOpen] = useState(false);
    const [updatingID, setUpdatingID] = useState();
    const imagePlayer = useRef();
    const [newRailIndex, setNewRailIndex] = useState();
    const [findModalVisible, setFindModalVisible] = useState(false);
    const [archiveInspectionSessionModalVisible, setArchiveInspection] = useState(false);
    const [restoreArchivedInspectionSessionModalVisible, setRestoreArchivedInspection] = useState(false);
    const [annotationWindowOpen, setAnnotationWindowOpen] = useState(false);
    const [fetchedInitialData, setFetchedInitialData] = useState(false);
    const [favouritePopoverOpen, setFavouritePopoverOpen] = useState(false);

    const secondaryAlignment = useRef(INITIAL_SECONDARY_RAIL_ALIGNMENT);
    const setSecondaryAlignment = (value) => {
        secondaryAlignment.current = value;
    };
    const [secondaryAlignmentAdjustment, setSecondaryAlignmentAdjustment] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const naiveIndex = useSelector(naiveIndexSelector);
    const sessionID = parseInt(match.params.sessionID);
    const reduxSessionID = useSelector(reduxSessionIDSelector);
    const accessToken = useSelector(accessTokensSelector);
    const coords = useSelector(coordsSelector);
    const supportedCoordinateSystems = useSelector(supportedCoordinateSystemsSelector);
    const inspectionBookmarks = useSelector(inspectionBookmarksSelector);
    const detailViewOpen = useSelector(detailViewOpenSelector);
    const settingsOpen = useSelector(settingsOpenSelector);
    const playlist = useSelector(playlistSelector);
    const railData = useSelector(railDataObjectSelector);
    const openRailInspectionSources = useSelector(openRailInspectionSourcesSelector);
    const detectionsWindowOpen = useSelector(detectionsWindowOpenSelector);
    const annotateModeEnabled = useSelector(annotateModeEnabledSelector);
    const userEmail = useSelector(userEmailSelector);
    const displayExtraLayers = useSelector(displayExtraLayersSelector);
    const timelineWidgetOpen = useSelector(timelineWidgetOpenSelector);
    const [currentHeaderHeight, setCurrentHeaderHeight] = useState(0);
    const userPreferencesShowRailManualAlignment = useSelector(userPreferencesShowRailManualAlignmentSelector);
    const selectedRailInspectionImageTimestamp = useSelector(selectedRailInspectionImageTimestampSelector);

    const [secondaryManualAdjustmentValues, setSecondaryManualAdjustmentValues] = useState(null);
    const [railManualAdjustmentLoading, setRailManualAdjustmentLoading] = useState(false);
    const [extraLayersSyncOffset, setExtraLayersSyncOffset] = useState(0);
    const [reportProblemVisible, setReportProblemVisible] = useState();

    const showReportProblemDialog = () => {
        setReportProblemVisible(true);
    };

    const hideReportProblemDialog = () => {
        setReportProblemVisible(false);
    };

    const secondaryRailImageLoader = useRef();

    const [secondaryRailConfig, setSecondaryRailConfig] = useState(INITIAL_SECONDARY_RAIL_CONFIG);
    const [secondaryRailImageStatus, setSecondaryRailImageStatus] = useState(INITIAL_SECONDARY_RAIL_IMAGE_STATE);
    const [secondaryRailImages, setSecondaryRailImages] = useState(null);

    const [inspectionArchiveStatus, setInspectionArchiveStatus] = useState(null);
    const requestedTimestampUrl = match ? parseInt(match.params.ts) : 0;

    useEffect(() => {
        if (sessionID) {
            dispatch(getSessionData(sessionID)).then((sessionData) => {
                setInspectionArchiveStatus(_.get(sessionData, "inspection_archive_status", 0));
            });
        }
    }, [dispatch, sessionID]);

    const setNewSecondaryAlignmentAdjustment = useCallback(
        (adjustment) => {
            if (adjustment != null) {
                if (!railManualAdjustmentLoading) {
                    setRailManualAdjustmentLoading(true);
                }
                let newSecondaryAlignment = _.clone(secondaryAlignment.current);
                newSecondaryAlignment.adjustment = adjustment;
                setSecondaryAlignment(newSecondaryAlignment);

                // annoying timeout to improve UX
                setTimeout(() => {
                    setRailManualAdjustmentLoading(false);
                }, 1000);
            }
        },
        [railManualAdjustmentLoading],
    );

    const debouncedHandleChange = useCallback(_.debounce(setNewSecondaryAlignmentAdjustment, 500), []);

    // below to debounce new rail adjustment input when user click multiple times
    useEffect(() => {
        debouncedHandleChange(secondaryAlignmentAdjustment);
    }, [secondaryAlignmentAdjustment]);

    useEffect(() => {
        const railInspectionLoaderNotify = () => {
            setSecondaryRailConfig(secondaryRailImageLoader.current.config);
            if (secondaryRailImageLoader.current.loaded) {
                setSecondaryRailImages({
                    data: secondaryRailImageLoader.current.data,
                    imageLocations: secondaryRailImageLoader.current.imageLocations,
                });
            } else {
                setSecondaryRailImages(null);
            }
            setSecondaryRailImageStatus({
                loaded: secondaryRailImageLoader.current.loaded && !_.isEmpty(secondaryRailImageLoader.current.imageLocations),
                percentageComplete: secondaryRailImageLoader.current.percentageComplete,
                sessionID: secondaryRailImageLoader.current.sessionID,
            });
        };
        secondaryRailImageLoader.current = new RailInspectionLoader(railInspectionLoaderNotify);
        return () => {
            secondaryRailImageLoader.current.abort();
        };
    }, [setSecondaryRailConfig, setSecondaryRailImages, secondaryRailImageLoader]);

    const loadSecondarySession = useCallback(
        (sessionID) => {
            console.log("Loading secondary session");
            if (secondaryRailImageLoader.current) {
                dispatch(secondaryRailImageLoader.current.load(sessionID));
                dispatch(secondaryRailImageLoader.current.getSessionLocations());
            }
        },
        [dispatch, secondaryRailImageLoader],
    );

    const clearSecondarySession = (error = false) => {
        if (secondaryRailImageLoader.current) {
            secondaryRailImageLoader.current.clear();
            setSecondaryRailImages(null);
            setSecondaryRailConfig(INITIAL_SECONDARY_RAIL_CONFIG);
            setSecondaryAlignment(INITIAL_SECONDARY_RAIL_ALIGNMENT);
            setSecondaryRailImageStatus(INITIAL_SECONDARY_RAIL_IMAGE_STATE);
            if (error) {
                notification.warning({
                    message: "Awaiting Inspection Image Upload",
                    description: "The chosen session does not yet have images uploaded, please try a different session.",
                });
            }
        }
    };

    const videolessSession = useMemo(() => {
        return !!(playlist.video?.length === 0 && railData.length);
    }, [playlist, railData]);

    const getInitialData = useCallback(() => {
        if (_.isEmpty(supportedCoordinateSystems)) {
            dispatch(getRouteCoordinateSystems());
        }
        if (naiveIndex === 0 && supportedCoordinateSystems) {
            dispatch(currentPlaylistPosition(0, coords, 0));
        }
        if (!inspectionBookmarks || !inspectionBookmarks.length) {
            dispatch(getInspectionBookmarks(sessionID));
        }

        dispatch(fetchUserAnnotationTypes());
        dispatch(getRailInspectionConditions());
        dispatch(railInspectionOpenedAudit(sessionID));
        setFetchedInitialData(true);
    }, [coords, dispatch, inspectionBookmarks, naiveIndex, sessionID, supportedCoordinateSystems]);

    useEffect(() => {
        if (userEmail && !fetchedInitialData) {
            getInitialData();
        }
    }, [userEmail, getInitialData, fetchedInitialData]);

    useEffect(() => {
        const websocketStatusPoll = setInterval(() => {
            dispatch(wsStatusPoll());
        }, 1000);

        return () => {
            clearInterval(websocketStatusPoll);
        };
    }, [dispatch]);

    useEffect(() => {
        if (naiveIndex === 0 && supportedCoordinateSystems) {
            dispatch(currentPlaylistPosition(0, coords, 0));
        }
    }, [supportedCoordinateSystems]);

    const sessionSelected = useMemo(() => {
        return sessionID === reduxSessionID;
    }, [sessionID, reduxSessionID]);

    const onExitRailInspection = () => {
        dispatch(railInspectionExited());
        dispatch(refresh());
    };

    useEffect(() => {
        if (!sessionSelected) {
            let timestampToRequest = requestedTimestampUrl;
            if (timestampToRequest < 9999999999) {
                timestampToRequest *= 1000;
            }
            dispatch(routeSelected(sessionID, timestampToRequest, false, true));
        } else if (selectedRailInspectionImageTimestamp && selectedRailInspectionImageTimestamp !== requestedTimestampUrl) {
            const newUrlPath = `/rail-inspection/${sessionID}/${selectedRailInspectionImageTimestamp}`;
            history.replace(newUrlPath);
        }
    }, [dispatch, sessionID, sessionSelected, requestedTimestampUrl, history, selectedRailInspectionImageTimestamp]);

    const logoutUser = () => {
        dispatch(logout());
    };

    const imageIndexChanged = useCallback(
        (newIndex) => {
            dispatch(selectBookmark(null));
            setNewRailIndex(newIndex);
        },
        [dispatch, setNewRailIndex],
    );

    const toggleMarkerTool = useCallback(() => {
        setMarkerToolActive(!markerToolActive);
        dispatch(toggleInspectionAnnotationMode(false));
    }, [setMarkerToolActive, markerToolActive, dispatch]);

    const toggleManualAlignmentTool = useCallback(() => {
        setManualAlignmentToolActive(!manualAlignmentToolActive);
        if (userPreferencesShowRailManualAlignment) {
            if (!manualAlignmentToolActive) {
                notification.open({
                    message: "Primary Rail",
                    description: "Use your mouse to click on the point in the primary rail that you want to use as the alignment point.",
                    placement: "bottomLeft",
                });
            }
        }
    }, [manualAlignmentToolActive, userPreferencesShowRailManualAlignment]);

    useEffect(() => {
        if (manualAlignmentToolActive) {
            setSecondaryManualAdjustmentValues(null);
        }
    }, [manualAlignmentToolActive]);

    const toggleFindModalVisibility = useCallback(
        (value) => {
            setFindModalVisible(value);
        },
        [setFindModalVisible, findModalVisible, dispatch],
    );

    const toggleArchiveInspectionSessionModal = useCallback(() => {
        setArchiveInspection(!archiveInspectionSessionModalVisible);
    }, [setArchiveInspection, archiveInspectionSessionModalVisible]);

    const toggleRestoreArchivedInspectionSessionModal = useCallback(() => {
        setRestoreArchivedInspection(!restoreArchivedInspectionSessionModalVisible);
    }, [setRestoreArchivedInspection, restoreArchivedInspectionSessionModalVisible]);

    useEffect(() => {
        if (annotateModeEnabled) {
            setMarkerToolActive(false);
        }
    }, [annotateModeEnabled]);

    const videoWindows = useMemo(() => {
        let filteredSources = [];
        let videos = [];
        if (!videolessSession && playlist && playlist.video && playlist.video.length && openRailInspectionSources) {
            filteredSources = openRailInspectionSources.filter((el) => {
                return playlist.video.indexOf(el.id) < 0;
            });
        }

        if (filteredSources.length) {
            videos = filteredSources.map((item) => {
                let id;
                let isMainSource = false;
                if (item.id === 0) {
                    id = "master";
                    isMainSource = true;
                } else {
                    id = "slave" + item.id;
                }
                return (
                    <RailInspectNormalSource
                        idx={item.id}
                        imagePlayer={imagePlayer}
                        match={match}
                        windowDimensions={windowDimensions}
                        isSlave
                        fullscreenID={id}
                        isMainSource={isMainSource}
                    />
                );
            });
        }

        return videos;
    }, [openRailInspectionSources]);

    if (!accessToken) {
        return <Redirect to={{ pathname: "/login" }} />;
    }

    return (
        <>
            {reportProblemVisible && (
                <GenericDialog
                    title="Report problem"
                    reportOrigin="rail-inspection"
                    promptText="Use this form to report an issue with this inspection"
                    placeholder="Please describe the problem you are having"
                    onClose={hideReportProblemDialog}
                />
            )}
            <Measure
                bounds
                onResize={(contentRect) => {
                    setWindowDimensions({ width: contentRect.bounds.width, height: contentRect.bounds.height, bottom: contentRect.bounds.bottom });
                }}>
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        style={{ height: "100%" }}>
                        <PinCursor active={markerToolActive} />
                        {manualAlignmentToolActive && <PinCursorLine active={manualAlignmentToolActive} />}
                        {videoWindows}
                        {detailViewOpen && <DetailView windowDimensions={windowDimensions} />}
                        {settingsOpen && (
                            <Settings
                                windowDimensions={windowDimensions}
                                showGridSettings={true}
                                secondaryRailConfig={secondaryRailConfig}
                            />
                        )}
                        {assetNavigationWindowOpen && (
                            <AssetNavigation
                                setAssetNavigationWindowOpen={setAssetNavigationWindowOpen}
                                windowDimensions={windowDimensions}
                                annotationWindowOpen={annotationWindowOpen}
                                setLabelWindowOpen={setLabelWindowOpen}
                                labelWindowOpen={labelWindowOpen}
                                setUpdatingID={setUpdatingID}
                            />
                        )}
                        {bookmarksAndAnnotationsWindowOpen && (
                            <ContentNavigation
                                setBookmarksAndAnnotationsWindowOpen={setBookmarksAndAnnotationsWindowOpen}
                                windowDimensions={windowDimensions}
                                annotationWindowOpen={annotationWindowOpen}
                                setLabelWindowOpen={setLabelWindowOpen}
                                labelWindowOpen={labelWindowOpen}
                                setUpdatingID={setUpdatingID}
                            />
                        )}

                        {sperrySuspectNavigationWindowOpen && (
                            <SperrySuspectNavigation
                                setSperrySuspectNavigationWindowOpen={setSperrySuspectNavigationWindowOpen}
                                windowDimensions={windowDimensions}
                                annotationWindowOpen={annotationWindowOpen}
                                setLabelWindowOpen={setLabelWindowOpen}
                                labelWindowOpen={labelWindowOpen}
                                setUpdatingID={setUpdatingID}
                            />
                        )}

                        {displayExtraLayers && (
                            <RailInspectionExtraDisplay
                                syncOffset={extraLayersSyncOffset}
                                setSyncOffset={setExtraLayersSyncOffset}
                                labelWindowOpen={labelWindowOpen}
                                windowDimensions={windowDimensions}
                                annotationWindowOpen={annotationWindowOpen}
                            />
                        )}
                        <InspectionSnapshotModal patrolView={false} />

                        <div className="RailInspectionMainWrapper">
                            <RailInspectHeader
                                match={match}
                                markerToolActive={markerToolActive}
                                toggleMarkerTool={toggleMarkerTool}
                                videoless={videolessSession}
                                toggleFindModalVisibility={toggleFindModalVisibility}
                                findModalVisible={findModalVisible}
                                setFavouritePopoverOpen={setFavouritePopoverOpen}
                                loadSecondarySession={loadSecondarySession}
                                setSecondaryAlignment={setSecondaryAlignment}
                                secondaryRailImages={secondaryRailImages}
                                secondaryRailImageStatus={secondaryRailImageStatus}
                                secondaryRailConfig={secondaryRailConfig}
                                clearSecondarySession={clearSecondarySession}
                                toggleManualAlignmentTool={toggleManualAlignmentTool}
                                manualAlignmentToolActive={manualAlignmentToolActive}
                                setSecondaryAlignmentAdjustment={setSecondaryAlignmentAdjustment}
                                showReportProblemDialog={showReportProblemDialog}
                                toggleArchiveInspectionSessionModal={toggleArchiveInspectionSessionModal}
                                toggleRestoreArchivedInspectionSessionModal={toggleRestoreArchivedInspectionSessionModal}
                                archiveInspectionSessionModalVisible={archiveInspectionSessionModalVisible}
                                restoreArchivedInspectionSessionModalVisible={restoreArchivedInspectionSessionModalVisible}
                                archiveStatus={inspectionArchiveStatus}
                                setArchiveStatus={setInspectionArchiveStatus}
                                setCurrentHeaderHeight={setCurrentHeaderHeight}
                                onExitRailInspection={onExitRailInspection}
                            />
                            <RailInspectBigScroller
                                match={match}
                                markerToolActive={markerToolActive}
                                toggleMarkerTool={toggleMarkerTool}
                                labelWindowOpen={labelWindowOpen}
                                setLabelWindowOpen={setLabelWindowOpen}
                                updatingID={updatingID}
                                setUpdatingID={setUpdatingID}
                                pinCursorActive={markerToolActive}
                                videoless={videolessSession}
                                newRailIndex={newRailIndex}
                                findModalVisible={findModalVisible}
                                annotationWindowOpen={annotationWindowOpen}
                                setAnnotationWindowOpen={setAnnotationWindowOpen}
                                favouritePopoverOpen={favouritePopoverOpen}
                                secondaryRailImages={secondaryRailImages}
                                secondaryRailConfig={secondaryRailConfig}
                                secondaryAlignment={secondaryAlignment.current}
                                manualAlignmentToolActive={manualAlignmentToolActive}
                                setManualAlignmentToolActive={setManualAlignmentToolActive}
                                setSecondaryManualAdjustmentValues={setSecondaryManualAdjustmentValues}
                                secondaryManualAdjustmentValues={secondaryManualAdjustmentValues}
                                setSecondaryAlignmentAdjustment={setSecondaryAlignmentAdjustment}
                                secondaryAlignmentAdjustment={secondaryAlignmentAdjustment}
                                railManualAdjustmentLoading={railManualAdjustmentLoading}
                                setRailManualAdjustmentLoading={setRailManualAdjustmentLoading}
                                reportProblemVisible={reportProblemVisible}
                                currentHeaderHeight={currentHeaderHeight}
                                toggleFindModalVisibility={toggleFindModalVisibility}
                                toggleArchiveInspectionSessionModal={toggleArchiveInspectionSessionModal}
                                toggleRestoreArchivedInspectionSessionModal={toggleRestoreArchivedInspectionSessionModal}
                                showReportProblemDialog={showReportProblemDialog}
                                clearSecondarySession={clearSecondarySession}
                                secondaryRailImageStatus={secondaryRailImageStatus}
                                setSecondaryAlignment={setSecondaryAlignment}
                                toggleManualAlignmentTool={toggleManualAlignmentTool}
                                loadSecondarySession={loadSecondarySession}
                                assetNavigationWindowOpen={assetNavigationWindowOpen}
                                setAssetNavigationWindowOpen={setAssetNavigationWindowOpen}
                                bookmarksAndAnnotationsWindowOpen={bookmarksAndAnnotationsWindowOpen}
                                setBookmarksAndAnnotationsWindowOpen={setBookmarksAndAnnotationsWindowOpen}
                                setSperrySuspectNavigationWindowOpen={setSperrySuspectNavigationWindowOpen}
                                sperrySuspectNavigationWindowOpen={sperrySuspectNavigationWindowOpen}
                            />
                            <RailInspectControls
                                imagePlayer={imagePlayer}
                                imageIndexChanged={imageIndexChanged}
                                secondaryRailImages={secondaryRailImages}
                                newRailIndex={newRailIndex}
                                secondaryAlignmentAdjustment={secondaryAlignmentAdjustment}
                                setSecondaryAlignmentAdjustment={setSecondaryAlignmentAdjustment}
                            />
                        </div>
                    </div>
                )}
            </Measure>
        </>
    );
};

export default RailInspectionComponent;
