import _ from "lodash";
const BBOX_MERGE_THRESHOLD = 10;

const isConnecting = (bboxA, bboxB) => {
    const [leftA, topA, widthA, heightA] = bboxA;
    const [leftB, topB, widthB, heightB] = bboxB;

    if (
        (Math.abs(leftA - leftB) < BBOX_MERGE_THRESHOLD || Math.abs(leftA + widthA - (leftB + widthB)) < BBOX_MERGE_THRESHOLD) &&
        topA < BBOX_MERGE_THRESHOLD &&
        Math.abs(topB + heightB - 1000) < BBOX_MERGE_THRESHOLD
    ) {
        console.log("debug found match");
        return true;
    }

    return false;
};

const mergeBoundingBoxes = (bboxA, bboxB) => {
    const [leftA, topA, widthA, heightA] = bboxA;
    const [leftB, topB, widthB, heightB] = bboxB;

    const newBbox = [leftA, topA - heightB, widthA, heightA + heightB];
    return newBbox;
};

export const mergeInspectionDetections = (detections) => {
    const mergedDetections = [];

    const detectionsByTimestamp = {};
    detections.forEach((detection) => {
        if (!detectionsByTimestamp[detection.image_timestamp]) {
            detectionsByTimestamp[detection.image_timestamp] = [];
        }

        detectionsByTimestamp[detection.image_timestamp].push(detection);
    });

    const imageTimestamps = Object.keys(detectionsByTimestamp).sort((a, b) => {
        return parseInt(a) - parseInt(b);
    });

    const mergedDetectionIDs = [];

    for (let i = 0; i < imageTimestamps.length; i++) {
        const detections = detectionsByTimestamp[imageTimestamps[i]].filter((det) => mergedDetectionIDs.indexOf(det.id) === -1);
        const detectionsAfter = detectionsByTimestamp[imageTimestamps[i + 1]];

        if (detections.length) {
            for (let detAIndex = 0; detAIndex < detections.length; detAIndex++) {
                const detectionA = detections[detAIndex];
                const mergedDetection = _.cloneDeep(detectionA);
                mergedDetection.connecting_detections = [];
                if (detectionsAfter) {
                    for (let detBIndex = 0; detBIndex < detectionsAfter.length; detBIndex++) {
                        const detectionB = detectionsAfter[detBIndex];
                        if (detectionA.name === detectionB.name && isConnecting(detectionA.bbox, detectionB.bbox)) {
                            mergedDetection.connecting_detections.push(detectionB);
                            mergedDetectionIDs.push(detectionB.id);
                            mergedDetection.bbox = mergeBoundingBoxes(mergedDetection.bbox, detectionB.bbox);
                        }
                    }
                }
                mergedDetections.push(mergedDetection);
            }
        }
    }

    return mergedDetections;
};
