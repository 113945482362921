import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/translucent.css";
import App from "./components/App";
import MobileApp from "./components/MobileApp";
import AdminApp from "./admin/AdminApp";
import * as serviceWorker from "./serviceWorker";
import AnalyticsComponent from "./components/AnalyticsComponent";
import ViewSetup from "./components/viewSetup/ViewSetup";
import FindSetup from "./components/FindSetup";
import ForgotPassword from "./components/ForgotPassword";
import LoginComponent from "./components/LoginComponent";
import JwtTokenLogin from "./components/JwtTokenLogin";
import LogoutComponent from "./components/LogoutComponent";
import ExportPage from "./components/ExportPage/ExportPage";
import BrowserCompatibility from "./components/util/BrowserCompatibility";
import MobileSessionViewer from "./components/mobile/MobileSessionViewer";
import RailInspectionComponent from "./components/RailInspectionComponent";
import ActivityComponent from "./components/ActivityComponent";
import Sidekick from "./components/Widgets/Sidekick";
import SchemaInterface from "./components/SchemaInterface/SchemaInterface";
import PatrolNavigation from "./components/SchemaInterface/PatrolNavigation";
import HazardPerception from "./components/HazardPerception/HazardPerception";
import AuthedRoute from "./components/AuthedRoute";
import PatrolPlanInterface from "components/SchemaInterface/PatrolPlanInterface";
import MediaUploadsPage from "components/userMediaUploads/MediaUploadsPage";
import ZoomTest from "components/ZoomTest";
import TermsPage from "components/terms/TermsPage";
import PrivacyComponent from "components/PrivacyComponent";
import AssoComponent from "components/AssoComponent";

const RootRedirect = () => {
    return <Redirect to="/" />;
};

const routing = (
    <BrowserCompatibility>
        <Provider store={store}>
            <ActivityComponent />
            <Router>
                <Switch>
                    <AuthedRoute
                        exact
                        path="/"
                        component={App}
                    />

                    <AuthedRoute
                        path="/review-patrol/:patrolID/:markup?"
                        component={SchemaInterface}
                        patrolRoute
                    />
                    <AuthedRoute
                        path="/patrol/:patrolID"
                        component={SchemaInterface}
                        patrolRoute
                    />
                    <AuthedRoute
                        path="/patrols"
                        component={PatrolNavigation}
                        patrolRoute
                    />
                    <AuthedRoute
                        path="/patrol-plans"
                        component={PatrolPlanInterface}
                        patrolRoute
                    />
                    <AuthedRoute
                        path="/patrol-planner/:planID"
                        component={SchemaInterface}
                        patrolRoute
                    />

                    <AuthedRoute
                        path="/rail-inspection/:sessionID/:ts?"
                        component={RailInspectionComponent}
                        inspectionRoute
                    />
                    <AuthedRoute
                        exact
                        path="/mobile"
                        component={MobileApp}
                    />
                    <AuthedRoute
                        exact
                        path="/mobile/view/:sessionID"
                        component={MobileSessionViewer}
                    />
                    <AuthedRoute
                        path="/admin"
                        component={AdminApp}
                    />

                    <Route
                        exact
                        path="/view/:sessionID"
                        component={ViewSetup}
                    />
                    <Route
                        exact
                        path="/t/:token/:sessionID?"
                        component={ViewSetup}
                    />
                    <Route
                        path="/view/:sessionID/:ts"
                        component={ViewSetup}
                    />
                    <Route
                        path="/content/:contentData"
                        component={ViewSetup}
                    />
                    <Route
                        path="/share/:sessionID/:ts?/:workspace?"
                        component={ViewSetup}
                    />
                    <Route
                        path="/issue/:issueID/:observationID?"
                        component={ViewSetup}
                    />
                    <Route
                        path="/asset/:assetID/:observationID?"
                        component={ViewSetup}
                    />

                    <Route
                        exact
                        path="/mobile/login"
                        component={LoginComponent}
                    />
                    <Route
                        path="/login/:type?"
                        component={LoginComponent}
                    />
                    <Route
                        path="/jwt"
                        component={JwtTokenLogin}
                    />
                    <Route
                        path="/asso"
                        component={AssoComponent}
                    />
                    <Route
                        path="/logout"
                        component={LogoutComponent}
                    />
                    <Route
                        path="/export/:exportID"
                        component={ExportPage}
                    />
                    <Route path="/marker-export/:exportID">
                        <ExportPage type={"marker"} />
                    </Route>
                    <Route
                        path="/mobile/export/:exportID"
                        component={ExportPage}
                    />
                    <Route
                        path="/analytics/:sessionID/:type"
                        component={AnalyticsComponent}
                    />
                    <Route
                        path="/forgot/:token"
                        component={ForgotPassword}
                    />
                    <Route
                        path="/find/:type/:param1/:param2?/:param3?"
                        component={FindSetup}
                    />
                    <Route
                        path="/widget/:widgetType/:token?"
                        component={Sidekick}
                    />
                    <Route
                        path="/virtual-cab-ride"
                        component={HazardPerception}
                    />
                    <Route
                        path="/upload/:token"
                        component={MediaUploadsPage}
                    />
                    <Route
                        path="/a/:domain_prefix"
                        component={LoginComponent}
                    />
                    <Route
                        path="/terms"
                        component={TermsPage}
                    />
                    <Route
                        path="/privacy"
                        component={PrivacyComponent}
                    />

                    <Route
                        path="/zoom"
                        component={ZoomTest}
                    />

                    <Route component={RootRedirect} />
                </Switch>
            </Router>
        </Provider>
    </BrowserCompatibility>
);

ReactDOM.render(routing, document.getElementById("root"));

serviceWorker.unregister();
