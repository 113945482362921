import React from "react";
import _ from "lodash";
import "react-day-picker/lib/style.css";
import { Select, Tooltip, Button, Input, Modal } from "antd";
import { connect } from "react-redux";
import {
    routeSelected,
    selectMarker,
    reviewMarker,
    setMarkerConditionType,
    reviewSessionObservation,
    classifyContinuousObservation,
    customAudit,
    setObservationClassification,
    selectTag,
    classifyObservationsObject,
    classifyScrapRailLength,
} from "redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faEyeSlash,
    faQuestionCircle,
    faTimesCircle,
    faArrowRight,
    faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";
import memoizeOne from "memoize-one";
import {
    filterMarkers,
    keyLookup,
    nextContent,
    previousContent,
    jumpToContent,
    findNextExtent,
    findPreviousExtent,
    filterSessionObservations,
    getReviewLabel,
    findClosestMarker,
    videoKeyToTimestamp,
    calculateFrame,
    processTroughingBbox,
} from "./util/PlaylistUtils";
import MarkerThresholdFiltersModal from "./MarkerThresholdFiltersModal";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import MarkerHistoryComponent from "./MarkerHistoryComponent";
import OBCAutoIndicator from "./OBC/OBCAutoIndicator";
import { validateGroundTruth } from "./Issues/issueUtils";
import AssetIDHelper from "components/util/AssetIDHelper";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import { calculatePointDistance } from "./util/Geometry";
import MarkerPanel from "./route/MarkerPanel";
import MarkerExportModal from "./MarkerExportModal";
import { getRawApiImage } from "./RawImageComponent";

const { Option } = Select;
class MarkerInformation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            markerThresholdFilterOpen: false,
            jumpToOpen: false,
            previewOpen: false,
            preveiwImgSrc: null,
            isObservation: false,
            isContinuousObservation: false,
            continuousObservations: [],
            uniqueContinuousObservations: [],
            observationExtents: [],
            overallRailClassification: {},
            modalVisible: false,
            ocrResult: "",
            ocrVerificationMessage: "",
            observationsExportOpen: false,
        };
        this.conditionSelect = React.createRef();
        this.canvasRef = React.createRef();
    }

    setContinuousObservationState = () => {
        if (this.props.selectedMarker.hasOwnProperty("observation_number")) {
            this.setState({
                isObservation: true,
            });
            if (this.props.selectedMarker.continuous) {
                const continuousObservations = _.map(this.props.markers, (marker) => {
                    const obs = _.minBy(
                        this.props.allMatchingMarkers.filter((o) => o.observation_number === marker.observation_number),
                        "extent_number",
                    );
                    return { ...marker, first_video_key: obs.video_key, first_observation_frame: obs.frame };
                });
                let uniqueObservations = [];
                continuousObservations.forEach((item) => {
                    if (!_.find(uniqueObservations, { observation_number: item.observation_number })) {
                        uniqueObservations.push(item);
                    }
                });
                let observationExtents = continuousObservations.filter((item) => item.observation_number === this.props.selectedMarker.observation_number);
                this.setState({
                    isContinuousObservation: true,
                    continuousObservations: continuousObservations,
                    uniqueContinuousObservations: uniqueObservations,
                    observationExtents: observationExtents,
                });
            } else {
                this.setState({
                    isContinuousObservation: false,
                    continuousObservations: [],
                    uniqueContinuousObservations: [],
                    observationExtents: [],
                });
            }
        } else {
            this.setState({
                isObservation: false,
                continuousObservations: [],
                isContinuousObservation: false,
                uniqueContinuousObservations: [],
                observationExtents: [],
            });
        }
    };

    componentDidMount() {
        this.setContinuousObservationState();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedMarker.id !== prevProps.selectedMarker.id ||
            (this.props.selectedMarker.classifications && this.props.selectedMarker.classifications !== prevProps.selectedMarker.classifications)
        ) {
            this.setContinuousObservationState();
        }
        if (this.props.markers.length !== prevProps.markers.length) {
            if (this.props.selectedMarker.continuous) {
                const continuousObservations = _.map(this.props.markers, (marker) => {
                    const obs = _.minBy(
                        this.props.allMatchingMarkers.filter((o) => o.observation_number === marker.observation_number),
                        "extent_number",
                    );
                    return { ...marker, first_video_key: obs.video_key, first_observation_frame: obs.frame };
                });
                let uniqueObservations = [];
                continuousObservations.forEach((item) => {
                    if (!_.find(uniqueObservations, { observation_number: item.observation_number })) {
                        uniqueObservations.push(item);
                    }
                });
                let observationExtents = continuousObservations.filter((item) => item.observation_number === this.props.selectedMarker.observation_number);
                this.setState({
                    isContinuousObservation: true,
                    continuousObservations: continuousObservations,
                    uniqueContinuousObservations: uniqueObservations,
                    observationExtents: observationExtents,
                });

                if (!_.find(observationExtents, (o) => o.id === this.props.selectedMarker.id)) {
                    if (observationExtents.length > 0) {
                        this.props.dispatch(selectMarker(uniqueObservations[0], true));
                        this.props.dispatch(
                            routeSelected(this.props.sessionID, uniqueObservations[0].video_key, undefined, undefined, undefined, uniqueObservations[0].frame),
                        );
                    }
                }
            } else {
                this.setState({
                    isContinuousObservation: false,
                    continuousObservations: [],
                    uniqueContinuousObservations: [],
                    observationExtents: [],
                });
            }
        }
        if (this.props.selectedMarker.id !== prevProps.selectedMarker.id && !_.isEmpty(this.props.selectedMarker)) {
            this.props.dispatch(selectMarker(this.props.selectedMarker, _.has(this.props.selectedMarker, "observation_number")));
        }
    }

    nextMarker = () => {
        let next;
        if (this.state.isContinuousObservation) {
            let observationNumber = _.get(this.props.selectedMarker, "observation_number", -1);
            next = nextContent(
                this.state.uniqueContinuousObservations,
                1,
                this.props.currentIndex,
                this.props.imageKeys,
                this.props.currentTimeOffset,
                observationNumber,
            );
        } else {
            next = nextContent(this.props.markers, 1, this.props.currentIndex, this.props.imageKeys, this.props.currentTimeOffset);
        }
        if (next === null) {
            return;
        }
        let selectedMarkerName = this.state.isObservation ? this.props.selectedMarker.class : this.props.selectedMarker.name;
        this.props.dispatch(
            customAudit(
                "detection_navigation_click",
                { direction: "next", detection_type: selectedMarkerName, target: "Detection Info Tab", source: "mouse" },
                `detection_navigation_click button next clicked from Detection Info tab for ${selectedMarkerName}`,
            ),
        );
        const marker = next[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
    };

    prevMarker = () => {
        let prev;
        if (this.state.isContinuousObservation) {
            let observationNumber = _.get(this.props.selectedMarker, "observation_number", -1);
            prev = previousContent(
                this.state.uniqueContinuousObservations,
                1,
                this.props.currentIndex,
                this.props.imageKeys,
                this.props.currentTimeOffset,
                observationNumber,
            );
        } else {
            prev = previousContent(this.props.markers, 1, this.props.currentIndex, this.props.imageKeys, this.props.currentTimeOffset);
        }
        if (prev === null) {
            return;
        }
        let selectedMarkerName = this.state.isObservation ? this.props.selectedMarker.class : this.props.selectedMarker.name;
        this.props.dispatch(
            customAudit(
                "detection_navigation_click",
                { direction: "previous", detection_type: selectedMarkerName, target: "Detection Info Tab", source: "mouse" },
                `detection_navigation_click button previous clicked from Detection Info tab for ${selectedMarkerName}`,
            ),
        );
        const marker = prev[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
    };

    nextExtent = (observations = null, selectedMarker = null) => {
        let next = findNextExtent(
            observations ? observations : this.state.continuousObservations,
            this.props.imageKeys,
            selectedMarker ? selectedMarker : this.props.selectedMarker,
        );
        if (next === null) {
            return;
        }
        const marker = next[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
        let selectedMarkerName = this.state.isObservation ? this.props.selectedMarker.class : this.props.selectedMarker.name;
        this.props.dispatch(
            customAudit(
                "detection_navigation_click",
                { direction: "next", detection_type: selectedMarkerName, target: "Detection Info Tab", source: "mouse" },
                `detection_navigation_click button next clicked from Detection Info tab for ${selectedMarkerName}`,
            ),
        );
    };

    prevExtent = () => {
        let prev = findPreviousExtent(this.state.continuousObservations, this.props.imageKeys, this.props.selectedMarker);
        if (prev === null) {
            return;
        }
        const marker = prev[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
        let selectedMarkerName = this.state.isObservation ? this.props.selectedMarker.class : this.props.selectedMarker.name;
        this.props.dispatch(
            customAudit(
                "detection_navigation_click",
                { direction: "previous", detection_type: selectedMarkerName, target: "Detection Info Tab", source: "mouse" },
                `detection_navigation_click button previous clicked from Detection Info tab for ${selectedMarkerName}`,
            ),
        );
    };

    goToMarker = (value) => {
        let next;
        if (this.state.isContinuousObservation) {
            next = jumpToContent(this.state.uniqueContinuousObservations, this.props.imageKeys, value);
        } else {
            next = jumpToContent(this.props.markers, this.props.imageKeys, value);
        }
        if (next === null) {
            this.setState({
                jumpToOpen: false,
            });
            return;
        }
        this.setState({
            jumpToOpen: false,
        });

        const marker = next[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
    };

    goToExtent = (value) => {
        let next = jumpToContent(this.state.observationExtents, this.props.imageKeys, value);

        if (next === null) {
            return;
        }

        const marker = next[2];
        this.props.dispatch(selectMarker(marker, this.state.isObservation));
        this.props.dispatch(routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame));
    };

    revMarker = (review) => {
        const _this = this;
        const continuousObservations = _.cloneDeep(this.state.continuousObservations);
        const _selectedMarker = _.cloneDeep(this.props.selectedMarker);
        if (this.props.selectedMarker) {
            if (this.state.isObservation) {
                this.props.dispatch(
                    reviewSessionObservation(this.props.selectedMarker.id, this.state.isContinuousObservation, review, (success) => {
                        if (success) {
                            if (this.state.isContinuousObservation) {
                                _this.nextExtent(continuousObservations, _selectedMarker);
                            } else {
                                _this.nextMarker();
                            }
                            this.props.dispatch(
                                customAudit(
                                    "detection_review_click",
                                    {
                                        review: getReviewLabel(review),
                                        detection_type: this.props.selectedMarker.class,
                                        target: "Detection Info Tab",
                                        source: "mouse",
                                    },
                                    `detection_review_click button ${getReviewLabel(review)} clicked from Detection Info tab for ${this.props.selectedMarker.class} ID: ${this.props.selectedMarker.id}, session ${this.props.sessionID}`,
                                ),
                            );
                        }
                    }),
                );
            } else {
                this.props.dispatch(
                    reviewMarker(this.props.selectedMarker.id, review, this.props.sessionID, (success) => {
                        if (success) {
                            _this.nextMarker();
                            this.props.dispatch(
                                customAudit(
                                    "detection_review_click",
                                    {
                                        review: getReviewLabel(review),
                                        detection_type: this.props.selectedMarker.name,
                                        target: "Detection Info Tab",
                                        source: "mouse",
                                    },
                                    `detection_review_click button ${getReviewLabel(review)} clicked from Detection Info tab for ${this.props.selectedMarker.name} ID: ${this.props.selectedMarker.id}, session ${this.props.sessionID}`,
                                ),
                            );
                        }
                    }),
                );
            }
        }
    };

    onReviewChange = (value) => {
        const _this = this;
        const continuousObservations = _.cloneDeep(this.state.continuousObservations);
        const _selectedMarker = _.cloneDeep(this.props.selectedMarker);
        if (this.props.selectedMarker) {
            if (this.state.isObservation) {
                this.props.dispatch(
                    reviewSessionObservation(this.props.selectedMarker.id, this.state.isContinuousObservation, value, (success) => {
                        if (success) {
                            if (this.state.isContinuousObservation) {
                                _this.nextExtent(continuousObservations, _selectedMarker);
                            } else {
                                _this.nextMarker();
                            }
                            this.props.dispatch(
                                customAudit(
                                    "detection_review_click",
                                    {
                                        review: getReviewLabel(value),
                                        detection_type: this.props.selectedMarker.class,
                                        target: "Detection Info Tab",
                                        source: "mouse",
                                    },
                                    `detection_review_click button ${getReviewLabel(value)} clicked from Detection Info tab for ${this.props.selectedMarker.class} ID: ${this.props.selectedMarker.id}, session ${this.props.sessionID}`,
                                ),
                            );
                        }
                    }),
                );
            } else {
                this.props.dispatch(
                    reviewMarker(this.props.selectedMarker.id, value, this.props.sessionID, function () {
                        let isHidden = _.findIndex(_this.props.markers, (i) => i.id === _this.props.selectedMarker.id);
                        if (isHidden < 0) {
                            _this.nextMarker();
                        }
                        _this.props.dispatch(
                            customAudit(
                                "detection_review_click",
                                {
                                    review: getReviewLabel(value),
                                    detection_type: _this.props.selectedMarker.name,
                                    target: "Detection Info Tab",
                                    source: "mouse",
                                },
                                `detection_review_click button ${getReviewLabel(value)} clicked from Detection Info tab for ${_this.props.selectedMarker.name} ID: ${_this.props.selectedMarker.id}, session ${_this.props.sessionID}`,
                            ),
                        );
                    }),
                );
            }
        }
    };

    reviewPermissions = () => {
        let session = this.props.session;
        const reviewPermissions =
            !!_.get(this.props.data_pool_permissions, [_.get(session, ["data_pool"], false), "review"], false) || this.props.userDetails.userConfig.super_admin;
        return reviewPermissions;
    };

    onConditionChanged = (sessionID, detectionID, conditionName) => {
        this.props.dispatch(setMarkerConditionType(sessionID, detectionID, conditionName));
    };

    onMarkerIndexDoubleClick = (value) => {
        if (value === 2) {
            this.setState({
                jumpToOpen: true,
            });
        }
    };

    onMarkerIndexInputChanged = (value, position) => {
        if (value !== position + 1) {
            this.goToMarker(value, this.props.selectedMarker.continuous);
        } else {
            this.setState({
                jumpToOpen: false,
            });
        }
    };

    openPreviewWithImage = (src) => {
        this.setState({
            previewOpen: true,
            preveiwImgSrc: src,
        });
    };

    closePreviewModal = () => {
        this.setState({
            previewOpen: false,
        });
    };

    classifyObservationExtent = (e, option) => {
        this.props.dispatch(classifyContinuousObservation([this.props.selectedMarker.id], option.props.data_type, true, e));
    };

    setOverallClassification = (overrideSectionClassifications) => {
        const observationNumber = this.props.selectedMarker.observation_number;
        const observationClass = this.props.selectedMarker.class;
        const extentsToUpdate = this.props.sessionObservations
            .filter((item) => item.observation_number === observationNumber && item.class === observationClass)
            .map((item) => item.id);

        this.props.dispatch(
            classifyContinuousObservation(
                extentsToUpdate,
                this.state.overallRailClassification.data_type,
                overrideSectionClassifications,
                this.state.overallRailClassification.val,
            ),
        );

        this.setState({ modalVisible: false, overallRailClassification: {} });
    };

    displayModal = (e, option) => {
        this.setState({ overallRailClassification: { data_type: option.props.data_type, val: e }, modalVisible: true });
    };

    handleOCRChange = () => {
        const observation = this.props.selectedMarker;
        const classSpecificData = _.get(observation, "class_specific_data");
        classSpecificData["ocr_result_custom"] = AssetIDHelper.convert_slash_to_csv(this.state.ocrResult);
        this.props.dispatch(
            setObservationClassification(observation, classSpecificData, (success) => {
                if (success) {
                    this.setState({ ocrResult: "" });
                }
            }),
        );
    };

    setOCRResult = (e) => {
        this.setState({ ocrResult: e.target.value });
        const response = validateGroundTruth("structure_id", e.target.value);
        this.setState({ ocrVerificationMessage: response });
    };

    renderThumbnailImage = memoizeOne((selectedMarkerID, session, canvas) => {
        const selectedMarker = _.find(this.props.markers, { id: selectedMarkerID });

        let rawSrc = null;
        if (selectedMarker.custom_thumbnail) {
            rawSrc = selectedMarker.custom_thumbnail;
        } else if (session && selectedMarker.video_key) {
            rawSrc = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_uuid}/${session.uuid}/${selectedMarker.video_key}-${selectedMarker.frame}.jpg`;
            if (this.props.csrfToken) {
                rawSrc += `?csrf=${this.props.csrfToken}`;
            }
        }

        const imageSourceConvertCallback = (imageSrc) => {
            let thumbnail = new Image();
            thumbnail.crossOrigin = "anonymous";

            thumbnail.src = imageSrc;
            let ctx;
            if (canvas) {
                ctx = canvas.getContext("2d");

                canvas.width = 600;
                canvas.height = 350;

                thumbnail.onload = () => {
                    let scaleX = canvas.width / thumbnail.naturalWidth;
                    let scaleY = canvas.height / thumbnail.naturalHeight;

                    ctx.drawImage(thumbnail, 0, 0, 600, 350);

                    if (_.has(selectedMarker, "observation_number") && _.has(selectedMarker, "bbox") && selectedMarker["bbox"]) {
                        let boxCoords = JSON.parse(selectedMarker.bbox);
                        boxCoords = processTroughingBbox(boxCoords, selectedMarker);
                        let bboxColor = "#47C66B";
                        if (selectedMarker.class === "Troughing") {
                            const autoClassification = _.get(selectedMarker, "classifications.troughing_condition.auto", null);
                            const userClassification = _.get(selectedMarker, "classifications.troughing_condition.user", null);
                            bboxColor = "yellow";

                            if (!userClassification) {
                                if (autoClassification === "good") {
                                    bboxColor = "#47C66B";
                                }
                                if (autoClassification === "defect") {
                                    bboxColor = "red";
                                }
                                if (autoClassification === "invalid" || autoClassification === "no_result" || !autoClassification) {
                                    bboxColor = "white";
                                }
                            } else {
                                if (userClassification === "good") {
                                    bboxColor = "#47C66B";
                                }
                                if (userClassification === "defect") {
                                    bboxColor = "red";
                                }
                                if (userClassification === "invalid") {
                                    bboxColor = "white";
                                }
                            }
                        } else if (selectedMarker.display_name === "Low Ballast") {
                            bboxColor = "red";
                        }

                        ctx.strokeStyle = bboxColor;
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.rect(boxCoords[0] * scaleX, boxCoords[1] * scaleY, boxCoords[2] * scaleX, boxCoords[3] * scaleY);
                        ctx.stroke();
                    }
                };
            }
        };
        getRawApiImage(rawSrc, this.props.currentDashboard.access_token, imageSourceConvertCallback);
    });

    changeDetectionType = (e) => {
        const availableDetections = _.filter(this.props.combinedMarkers, (detection) => {
            if (detection.hasOwnProperty("name")) {
                return detection.name === e;
            } else {
                return detection.class === e;
            }
        });

        const closestMarker = findClosestMarker(
            availableDetections,
            this.props.currentVideoKey,
            this.props.playlist,
            this.props.currentIndex,
            this.props.currentTimeOffset,
        );

        this.props.dispatch(
            customAudit("detection_type_selected", { detection_type: e, session_id: this.props.sessionID }, `detection_type_selected used type: ${e}`),
        );
        this.props.dispatch(selectTag(e));
        this.props.dispatch(selectMarker(closestMarker, _.has(closestMarker, "observation_number")));
        this.props.dispatch(routeSelected(this.props.sessionID, closestMarker.video_key, false, true, undefined, closestMarker.frame));
    };

    render() {
        const { selectedMarker } = this.props;
        let markersLength = 0;
        let markerInformationContent = null;

        if (!_.isEmpty(selectedMarker)) {
            this.renderThumbnailImage(selectedMarker.id, this.props.sessions[this.props.sessionID], this.canvasRef.current);

            let reviewStatus = null;
            const statuses = {
                0: { text: "Needs Review", icon: faQuestionCircle, colour: "orange" },
                1: { text: "Verified", icon: faCheckCircle, colour: "lightGreen" },
                2: { text: "Verified & Hidden", icon: faEyeSlash, colour: "darkGreen" },
                3: { text: "Rejected", icon: faTimesCircle, colour: "red" },
            };

            if (!_.isNil(selectedMarker.review_status)) {
                const status = statuses[selectedMarker.review_status];

                if (status.text == "Needs Review") {
                    reviewStatus = (
                        <div className="markerStatus">
                            <div className={`markerStatusContent ${status.colour}`}>
                                <Tooltip title="This detection has not yet been user-verified">
                                    <FontAwesomeIcon icon={status.icon} />
                                </Tooltip>
                                <p>{status.text}</p>
                            </div>
                        </div>
                    );
                } else {
                    reviewStatus = (
                        <div className="markerStatus">
                            <div className={`markerStatusContent ${status.colour}`}>
                                <FontAwesomeIcon icon={status.icon} />
                                <p>{status.text}</p>
                            </div>
                        </div>
                    );
                }
            }

            let position = 0;

            if (this.props.markers && this.props.markers.length) {
                let indexes;
                if (this.props.selectedMarker.continuous) {
                    indexes = _.map(this.state.uniqueContinuousObservations, (marker) => [
                        keyLookup(marker.first_video_key, this.props.imageKeys),
                        marker.frame,
                        marker,
                    ]);
                } else {
                    indexes = _.map(this.props.markers, (marker) => [keyLookup(marker.video_key, this.props.imageKeys), marker.frame, marker]);
                }
                let sortedMarkers = _.sortBy(
                    _.filter(indexes, ([idx, _frame]) => idx > -1),
                    ([idx, _frame]) => idx,
                    ([idx, _frame]) => _frame,
                ).map((marker) => marker[2]);

                position = _.findIndex(sortedMarkers, (i) => i.id === selectedMarker.id);
                if (this.state.isContinuousObservation && position === -1) {
                    position = _.findIndex(sortedMarkers, (i) => i.observation_number === selectedMarker.observation_number);
                }
                markersLength = sortedMarkers.length;
            }

            let markerIndex = (
                <div
                    className="markerIndex"
                    onClick={(e) => this.onMarkerIndexDoubleClick(e.detail)}>
                    {this.state.jumpToOpen ? (
                        <Input.Group compact>
                            <Input
                                size="small"
                                type="number"
                                autoFocus={true}
                                style={{ width: "40px" }}
                                defaultValue={position + 1}
                                onBlur={(e) => this.onMarkerIndexInputChanged(e.target.value, position)}
                                onPressEnter={(e) => this.onMarkerIndexInputChanged(e.target.value, position)}
                                onSearch={(e) => this.onMarkerIndexInputChanged(e.target.value, position)}
                            />
                            <Button
                                type="primary"
                                size="small">
                                Go
                            </Button>
                        </Input.Group>
                    ) : (
                        <Tooltip title="Double click to jump to marker">
                            <p>{position + 1}</p>
                            <span>of</span>
                            <p>{markersLength}</p>
                        </Tooltip>
                    )}
                </div>
            );

            let extentLength = 0;
            let extentPosition = 0;

            if (this.state.observationExtents && this.state.observationExtents.length) {
                let indexes = _.map(this.state.observationExtents, (marker) => [keyLookup(marker.video_key, this.props.imageKeys), marker.frame, marker]);
                let sortedMarkers = _.sortBy(
                    _.filter(indexes, ([idx, _frame]) => idx > -1),
                    ([idx, _frame]) => idx,
                    ([idx, _frame]) => _frame,
                ).map((marker) => marker[2]);
                extentPosition = _.findIndex(sortedMarkers, (i) => i.id === selectedMarker.id);
                extentLength = sortedMarkers.length;
            }

            let extentIndex = (
                <div className="ContinuousObservationPosition">
                    <p>
                        <span>Section</span> {extentPosition + 1}
                    </p>
                    <span>of</span>
                    <p>{extentLength}</p>
                </div>
            );

            let filteredAmount = 0;
            let filteredExtents = 0;
            if (this.props.markers) {
                if (this.state.isContinuousObservation) {
                    let uniqueObservations = [];
                    this.props.allMatchingMarkers.forEach((item) => {
                        if (!_.find(uniqueObservations, { observation_number: item.observation_number })) {
                            uniqueObservations.push(item);
                        }
                    });
                    filteredAmount = uniqueObservations.length - this.state.uniqueContinuousObservations.length;
                    const unfilteredExtents = this.props.allMatchingMarkers.filter(
                        (item) => item.observation_number === this.props.selectedMarker.observation_number,
                    );
                    filteredExtents = unfilteredExtents.length - this.state.observationExtents.length;
                } else {
                    filteredAmount = this.props.allMatchingMarkers.length - this.props.markers.length;
                }
            }

            let conditions = [];
            if (this.props.conditionTypes) {
                conditions = _.filter(
                    this.props.conditionTypes,
                    (condition) => String(condition.marker_type).toLocaleUpperCase() === String(selectedMarker.name).toLocaleUpperCase(),
                );
            }

            let continuousObservationSteps = this.state.observationExtents.map((item, index) => {
                let colour = "white";

                const troughingCondition = _.get(item.classifications, "troughing_condition");

                if (troughingCondition) {
                    if (!troughingCondition.user) {
                        if (troughingCondition.auto !== "no_result") {
                            colour = "yellow";
                        }
                        if (troughingCondition.auto === "defect") {
                            colour = "red";
                        }
                        if (troughingCondition.auto === "good") {
                            colour = "green";
                        }
                        if (troughingCondition.auto === "invalid") {
                            colour = "white";
                        }
                    } else {
                        if (troughingCondition.user !== "no_result") {
                            colour = "yellow";
                        }
                        if (troughingCondition.user === "defect") {
                            colour = "red";
                        }
                        if (troughingCondition.user === "good") {
                            colour = "green";
                        }
                        if (troughingCondition.user === "invalid") {
                            colour = "white";
                        }
                    }
                }

                let step;

                if (item.id === this.props.selectedMarker.id) {
                    step = (
                        <div
                            key={item.id}
                            style={{ backgroundColor: colour, opacity: "0.8" }}
                            className="ContinuousObservationsStep StepHighlight"></div>
                    );
                } else {
                    step = (
                        <div
                            onClick={() => this.goToExtent(index + 1)}
                            key={item.id}
                            style={{ backgroundColor: colour, opacity: "0.3", cursor: "pointer" }}
                            className="ContinuousObservationsStep"></div>
                    );
                }

                return (
                    <Tooltip
                        title={
                            <div>
                                <p style={{ margin: "3px 0" }}>ID: {item.id}</p>
                                <p style={{ marginBottom: "3px" }}>Observation number: {item.observation_number}</p>
                                <p style={{ marginBottom: "3px" }}>Extent number: {item.extent_number}</p>
                            </div>
                        }>
                        {step}
                    </Tooltip>
                );
            });

            const observationClassificationOptionsMap = {
                "Scrap Rail": {
                    scrap_rail_type: [
                        { value: "scrap", tag: "Scrap Rail" },
                        { value: "strategic", tag: "Strategic Rail" },
                        { value: "future_works", tag: "Future Works" },
                    ],
                },
                Troughing: {
                    troughing_condition: [
                        { value: "defect", tag: "Defect" },
                        { value: "obscured", tag: "Obscured" },
                        { value: "cables", tag: "Cables Outside of Troughing" },
                        { value: "good", tag: "Good" },
                        { value: "uncertain", tag: "Uncertain" },
                    ],
                    troughing_type: [
                        { value: "concrete", tag: "Concrete" },
                        { value: "composite", tag: "Composite" },
                        { value: "elevated", tag: "Elevated" },
                        { value: "uncertain", tag: "Uncertain" },
                        { value: "not_troughing", tag: "Not Troughing" },
                    ],
                },
                low_ballast: {},
            };

            const observationClassificationOptions = _.get(observationClassificationOptionsMap, selectedMarker.class, {});

            const renderClassificationOptions = (setOverall) => {
                return Object.keys(observationClassificationOptions).map((item) => {
                    const renderOptions = observationClassificationOptions[item].map((option) => {
                        return (
                            <Option
                                key={option.value}
                                data_type={item}
                                value={option.value}>
                                {option.tag}
                            </Option>
                        );
                    });
                    let selectTag = "Condition";
                    if (/\b\w*type\w*\b/i.test(item)) {
                        selectTag = "Type";
                    }

                    let displayAuto = false;
                    let selectValue = "";
                    if (selectedMarker.classifications[item]) {
                        if (selectedMarker.classifications[item].user) {
                            selectValue = selectedMarker.classifications[item].user;
                        } else if (selectedMarker.classifications[item].auto) {
                            if (selectedMarker.classifications[item].auto === "no_result") {
                                selectValue = "No result";
                            } else {
                                selectValue = selectedMarker.classifications[item].auto;
                            }
                            displayAuto = true;
                        } else {
                            selectValue = "Not set";
                        }
                    } else {
                        selectValue = "Not set";
                    }

                    let overallClassificationValue = "Not set";

                    const extentClassifications = this.state.observationExtents.map((extent) => {
                        const userClassification = _.get(extent, `classifications.${item}.user`, null);
                        if (userClassification) {
                            return userClassification;
                        } else {
                            return _.get(extent, `classifications.${item}.auto`, null);
                        }
                    });
                    const uniqueClassifications = _.uniq(extentClassifications);

                    if (uniqueClassifications.length === 1 && !uniqueClassifications.includes(null)) {
                        overallClassificationValue = uniqueClassifications[0];
                    }

                    return (
                        <div className="ContinuousObservationsSelectContainer">
                            <p className="ContinuousObservationsSelectTag">{selectTag}</p>
                            <Select
                                value={setOverall ? overallClassificationValue : selectValue}
                                className="ContinuousObservationsSelect"
                                onChange={setOverall ? this.displayModal : this.classifyObservationExtent}>
                                {renderOptions}
                            </Select>
                            {displayAuto && !setOverall && (
                                <div className="ContinuousObservationsAuto">
                                    <OBCAutoIndicator
                                        tooltip="Reviewed automatically"
                                        placement="topRight"
                                    />
                                </div>
                            )}
                        </div>
                    );
                });
            };

            const renderObjectObservationClassifications = () => {
                if (!this.props.displayESSOW) {
                    return null;
                }

                const types = _.get(this.props.objectObservationDataTypes, _.get(selectedMarker, "class"), {});

                return _.map(Object.keys(types), (type) => {
                    const currentType = types[type];
                    let currentClassification = _.get(selectedMarker, ["classifications", type, "user"], undefined);
                    let auto;

                    if (!currentClassification) {
                        currentClassification = _.get(selectedMarker, ["classifications", type, "auto"], undefined);

                        if (currentClassification) {
                            auto = (
                                <OBCAutoIndicator
                                    tooltip="Reviewed automatically"
                                    placement="topRight"
                                />
                            );
                        }
                    }

                    const dataValues = _.get(currentType, "data_values", {});

                    return (
                        <div className="SignalClassification">
                            <p className="SignalClassificationLabel">{_.get(currentType, "display_name", "")}:</p>
                            <Select
                                value={currentClassification}
                                placeholder={_.get(currentType, "display_name", "")}
                                className="SignalClassificationSelect"
                                onChange={(e) => this.props.dispatch(classifyObservationsObject(selectedMarker.id, e, type))}>
                                {_.map(Object.keys(dataValues), (value) => {
                                    if (typeof dataValues[value] === "string") {
                                        return <Select.Option value={dataValues[value]}>{value}</Select.Option>;
                                    } else {
                                        return <Select.Option value={value}>{_.get(dataValues[value], "display_name")}</Select.Option>;
                                    }
                                })}
                            </Select>
                            {auto}
                        </div>
                    );
                });
            };

            markerInformationContent = (
                <div className="markerInformationContainer">
                    {!_.isNil(selectedMarker.review_status) && (
                        <div className="topRow">
                            <div className="markerInformationContainer__Inner image">
                                <canvas ref={this.canvasRef}></canvas>
                            </div>

                            <div className="markerInformationContainer__Inner">
                                <div className="markerInformationContainer__Inner__Row">
                                    {(selectedMarker.name || selectedMarker.class) && (
                                        <Select
                                            className="markerInformationSelector"
                                            value={_.has(selectedMarker, "name") ? selectedMarker.name : selectedMarker.class}
                                            bordered={false}
                                            onChange={this.changeDetectionType}>
                                            {this.props.detectionTypes.map((item) => {
                                                return (
                                                    <Select.Option value={item.name || item.class}>
                                                        {item.display_name || item.name || item.class}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    )}
                                    {this.reviewPermissions() && reviewStatus}
                                    {!_.isEmpty(this.props.markerDistance) && (
                                        <div className="markerDistance">
                                            {!this.props.markerDistance.ahead && (
                                                <FontAwesomeIcon
                                                    icon={faArrowLeft}
                                                    className="markerDistanceDirection"
                                                />
                                            )}
                                            <p className="markerDistanceText">{this.props.markerDistance.distance.toFixed(2)}m</p>
                                            {this.props.markerDistance.ahead && (
                                                <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                    className="markerDistanceDirection"
                                                />
                                            )}
                                        </div>
                                    )}
                                    {this.state.isObservation && this.props.userDetails.userConfig.super_admin && (
                                        <Button
                                            style={{ height: "auto", minHeight: 32 }}
                                            onClick={() => this.setState({ observationsExportOpen: true })}
                                            type="primary">
                                            Export {selectedMarker.display_name || selectedMarker.class} Detections
                                        </Button>
                                    )}
                                </div>
                                <div className="markerInformationContainer__Inner__Row">
                                    <div className="markerControls">
                                        <div
                                            className="markerControls__Item"
                                            onClick={() => this.prevMarker()}>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </div>
                                        {markerIndex}
                                        <div
                                            className="markerControls__Item"
                                            onClick={() => this.nextMarker()}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                    </div>
                                    <div
                                        className="markerControls__Filter"
                                        onClick={() => this.setState({ markerThresholdFilterOpen: true })}>
                                        <FontAwesomeIcon
                                            icon={faFilter}
                                            style={{ color: "#40A9FF" }}
                                        />
                                    </div>
                                    <div className="markerControls__Filter-Count">
                                        <span>{filteredAmount} Filtered</span>
                                    </div>
                                </div>
                                <div className="markerInformationContainerGrid">
                                    {this.reviewPermissions() && (
                                        <>
                                            <span className="improveLabel">Your reviews help improve future detections</span>
                                            <span className="Label">Status: </span>
                                            <div className="Controls">
                                                {!selectedMarker.review_status ? (
                                                    <>
                                                        <button
                                                            className="reviewButton reject"
                                                            onClick={() => this.revMarker(3)}>
                                                            <span>Reject</span>
                                                        </button>
                                                        <button
                                                            className="reviewButton verifyhide"
                                                            onClick={() => this.revMarker(2)}>
                                                            <span>Verify & Hide</span>
                                                        </button>
                                                        <button
                                                            className="reviewButton verify"
                                                            onClick={() => this.revMarker(1)}>
                                                            <span>Verify</span>
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Select
                                                            value={selectedMarker.review_status}
                                                            onChange={this.onReviewChange}
                                                            dropdownMatchSelectWidth={false}
                                                            style={{ width: "50%" }}
                                                            getPopupContainer={(node) => node.parentNode}>
                                                            <Select.Option value={3}>Rejected</Select.Option>
                                                            <Select.Option value={2}>Verified & Hidden</Select.Option>
                                                            <Select.Option value={1}>Verified</Select.Option>
                                                        </Select>
                                                    </>
                                                )}
                                            </div>
                                            <div className="AutoReviewIndicator">
                                                {selectedMarker.review_auto_selected && (
                                                    <OBCAutoIndicator
                                                        className="Button"
                                                        onClick={() => {
                                                            this.onReviewChange(selectedMarker.review_status);
                                                        }}
                                                        tooltip="Reviewed automatically"
                                                        placement="topRight"
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {this.reviewPermissions() && conditions.length > 0 && (
                                        <>
                                            <span className="Label">Condition: </span>
                                            <div className="Controls">
                                                <Select
                                                    ref={this.conditionSelect}
                                                    showSearch
                                                    dropdownMatchSelectWidth={false}
                                                    dropdownMenuStyle={{ maxHeight: "500px" }}
                                                    placeholder="Select condition"
                                                    optionFilterProp="children"
                                                    onChange={(e) => {
                                                        this.conditionSelect.current.blur();
                                                        this.onConditionChanged(selectedMarker.session_id, selectedMarker.id, e);
                                                    }}
                                                    value={selectedMarker.condition_id}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    <Option
                                                        key={0}
                                                        value={null}>
                                                        None
                                                    </Option>
                                                    {_.map(conditions, (type) => (
                                                        <Option
                                                            key={type.id}
                                                            value={type.id}>
                                                            {type.condition_name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="AutoReviewIndicator">
                                                {selectedMarker.condition_auto_selected && (
                                                    <OBCAutoIndicator
                                                        className="Button"
                                                        onClick={() => {
                                                            this.onConditionChanged(selectedMarker.session_id, selectedMarker.id, selectedMarker.condition_id);
                                                        }}
                                                        tooltip="Condition automatically detected"
                                                        placement="topRight"
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {renderObjectObservationClassifications()}
                            {selectedMarker && selectedMarker.continuous && (
                                <div className="ContinuousObservations">
                                    <Modal
                                        title={false}
                                        visible={this.state.modalVisible}
                                        footer={[
                                            <Button
                                                type="primary"
                                                onClick={() => this.setOverallClassification(true)}>
                                                Yes
                                            </Button>,
                                            <Button onClick={() => this.setOverallClassification(false)}>No</Button>,
                                            <Button onClick={() => this.setState({ modalVisible: false, overallRailClassification: "" })}>Cancel</Button>,
                                        ]}
                                        onCancel={() => this.setState({ modalVisible: false, overallRailClassification: "" })}>
                                        <p
                                            className="ContinuousObservationsModalText"
                                            style={{ margin: "5px 15px", fontWeight: 500 }}>
                                            Would you like to override the classifications of each section within this observation?
                                        </p>
                                    </Modal>

                                    <h3 className="ContinuousObservationsTitle">Observation Sections</h3>
                                    <div className="ContinuousObservationsControls">
                                        <div
                                            className="markerControls__Item"
                                            onClick={this.prevExtent}>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </div>
                                        <div className="ContinuousObservationPosition">{extentIndex}</div>
                                        <div
                                            className="markerControls__Item"
                                            onClick={() => this.nextExtent()}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                        {filteredExtents > 0 && (
                                            <p className="ContinuousObservationFiltered">
                                                {filteredExtents === 1 ? `${filteredExtents} Section` : `${filteredExtents} Sections`} Filtered
                                            </p>
                                        )}
                                    </div>
                                    <div className="ContinuousObservationsSteps">{continuousObservationSteps}</div>

                                    {!_.isEmpty(observationClassificationOptions) && (
                                        <div className="ContinuousObservationsReviewContainer">
                                            <div className="ContinuousObservationsContainer">
                                                <span className="ContinuousObservationsTag">Section Classification:</span>
                                                <div className="ContinuousObservationsSelects">{renderClassificationOptions(false)}</div>
                                            </div>

                                            <div className="ContinuousObservationsContainer">
                                                <span className="ContinuousObservationsTag">Overall Classification:</span>
                                                <div className="ContinuousObservationsSelects">{renderClassificationOptions(true)}</div>
                                            </div>

                                            {selectedMarker.class === "Scrap Rail" && (
                                                <div className="ContinuousObservationsContainer">
                                                    <span className="ContinuousObservationsTag">Length:</span>
                                                    <div className="ContinuousObservationsSelects">
                                                        <Select
                                                            className="ScrapRailLength"
                                                            placeholder="Select length"
                                                            onChange={(e) => this.props.dispatch(classifyScrapRailLength(selectedMarker.id, e))}
                                                            value={_.get(selectedMarker, ["classifications", "scrap_rail_length", "user"])}>
                                                            <Option value="under_10ft">Under 10ft</Option>
                                                            <Option value="over_10ft">Over 10ft</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {selectedMarker.class === "Structure ID Plate" && (
                        <div className="StructureIDPlateSelects">
                            <div className="StructureIDPlateSelect">
                                <p className="label">
                                    OCR Result:{" "}
                                    <span className="label-bold">
                                        {AssetIDHelper.from_class_specific_data(_.get(selectedMarker, "class_specific_data")).csv_to_slashes()}
                                    </span>
                                </p>
                                <div className="container">
                                    <Input
                                        placeholder="Override OCR result"
                                        value={this.state.ocrResult}
                                        className="input"
                                        onChange={this.setOCRResult}
                                    />
                                    <Button
                                        type="primary"
                                        className="submit"
                                        onClick={this.handleOCRChange}
                                        disabled={this.state.ocrResult === "" || this.state.ocrVerificationMessage}>
                                        Save
                                    </Button>
                                </div>
                            </div>

                            {this.state.ocrVerificationMessage && <p className="ocrWarning">{this.state.ocrVerificationMessage}</p>}
                        </div>
                    )}

                    {this.props.selectedMarker && this.props.selectedMarker.name === "Thermal Hotspot" && (
                        <MarkerHistoryComponent openPreviwWithImage={this.openPreviewWithImage} />
                    )}
                </div>
            );
        }

        return (
            <>
                <Modal
                    className="DetectionPreviewImageModal"
                    title={false}
                    visible={this.state.previewOpen}
                    onCancel={() => this.closePreviewModal()}
                    footer={false}>
                    <img
                        src={this.state.preveiwImgSrc}
                        className="TippyHistoryImage"
                        alt="Thermal Hotspot"
                        crossOrigin={"anonymous"}
                    />
                </Modal>
                <MarkerThresholdFiltersModal
                    markerThresholdFilterOpen={this.state.markerThresholdFilterOpen}
                    setMarkerThresholdFilterOpen={() => this.setState({ markerThresholdFilterOpen: false })}
                />

                {this.state.observationsExportOpen && (
                    <MarkerExportModal
                        observationType={this.props.selectedMarker?.class}
                        closeModal={() => this.setState({ observationsExportOpen: false })}
                    />
                )}

                <div
                    className="markerButtons"
                    style={{ marginTop: "15px" }}>
                    <MarkerPanel displayDownload={false} />
                </div>

                {markersLength > 0 ? (
                    markerInformationContent
                ) : (
                    <div className="markerInformationContainer__Empty">
                        <div
                            className="markerControls__Filter-Button"
                            onClick={() => this.setState({ markerThresholdFilterOpen: true })}>
                            <span>Edit Filters</span>
                            <FontAwesomeIcon icon={faFilter} />
                        </div>
                        <p>
                            All{" "}
                            <span>
                                {this.props.allMatchingMarkers &&
                                this.props.allMatchingMarkers.length &&
                                _.has(this.props.allMatchingMarkers[0], "observation_number")
                                    ? _.uniqBy(this.props.allMatchingMarkers, "observation_number").length
                                    : this.props.allMatchingMarkers.length}
                            </span>{" "}
                            markers filtered
                        </p>
                    </div>
                )}
            </>
        );
    }
}

const filteredMarkers = memoizeOne(
    (
        markers,
        observations,
        routeID,
        selectedMarker,
        markerReviewFilters,
        userAnnotationTypes,
        thresholdFilters,
        defaultScores,
        markerConditionFilter,
        observationFilters,
    ) => {
        const mks = _.get(markers.perSession, [routeID], []);
        let filtered;
        if (selectedMarker && selectedMarker.hasOwnProperty("observation_number")) {
            filtered = filterSessionObservations(
                observations.filter((marker) => {
                    return marker.class === selectedMarker.class && markerReviewFilters.includes(marker.review_status);
                }),
                observationFilters,
            );
        } else {
            filtered = filterMarkers(
                mks,
                selectedMarker.name,
                markerReviewFilters,
                userAnnotationTypes,
                thresholdFilters,
                false,
                defaultScores,
                markerConditionFilter,
            );
        }
        return filtered.filter((marker) => marker.source !== "ANNOTATION");
    },
);

const currentMarker = memoizeOne((filteredMarkers, currentVideoKey, imageKeys, currentIndex, timeOffset, previousMarker) => {
    return findClosestMarker(filteredMarkers, currentVideoKey, imageKeys, currentIndex, timeOffset, previousMarker);
});

const calculateMarkerDistance = memoizeOne((mapPosition, markerCoords, currentVideoKey, closestMarker, video, currentIndex, offset) => {
    const currentFrame = calculateFrame(video, currentIndex, offset);
    const markerFrame = _.get(closestMarker, "frame");
    const markerVideoKey = _.get(closestMarker, "video_key");
    let _markerCoords = markerCoords;

    if (
        (currentVideoKey === markerVideoKey && currentFrame === markerFrame) ||
        !mapPosition ||
        !markerCoords ||
        !closestMarker ||
        (markerCoords[0] === 0 && markerCoords[1] === 0)
    ) {
        return {};
    }

    if (_.has(closestMarker, "observation_number")) {
        _markerCoords = [markerCoords[1], markerCoords[0]];
    }

    return {
        distance: calculatePointDistance([mapPosition[1], mapPosition[0]], _markerCoords),
        ahead: markerVideoKey === currentVideoKey ? markerFrame > currentFrame : videoKeyToTimestamp(markerVideoKey) > videoKeyToTimestamp(currentVideoKey),
    };
});

const getDetectionTypes = memoizeOne((markers, observations, filters) => {
    const uniqueDetectionTypes = [];
    const uniqueDetectionNames = [];
    markers.forEach((item) => {
        if (!uniqueDetectionNames.includes(item.name) && filters.includes(item.review_status)) {
            uniqueDetectionNames.push(item.name);
            uniqueDetectionTypes.push(item);
        }
    });
    observations.forEach((item) => {
        if (!uniqueDetectionNames.includes(item.class) && filters.includes(item.review_status)) {
            uniqueDetectionNames.push(item.class);
            uniqueDetectionTypes.push(item);
        }
    });
    return uniqueDetectionTypes;
});

const EMPTY_OBJECT = {};
const mapStateToProps = ({
    userDetails,
    dashboards,
    playlist,
    markers,
    mapGeometry,
    sessions,
    markerReviewFilters,
    detectionTypes,
    markerThresholdFilters,
    defaultMarkersThresholds,
    markerConditionFilter,
    sessionObservations,
    observationFilters,
    csrfToken,
    objectObservationDataTypes,
}) => {
    const sessionID = _.get(playlist, ["data", "routeID"], 0);
    const markersPerSession = _.get(markers, ["perSession", sessionID], []);
    const allMatchingMarkers = _.filter(markersPerSession, (item) => item.name === markers.selectedMarker.name);
    const allMatchingObservations = _.filter(sessionObservations, (item) => item.class === markers.selectedMarker.class);
    const dashboardID = userDetails.dashboardAccessID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);

    const defThresholds = defaultMarkersThresholds[sessionID];

    const isVideo = playlist.position.isVideo;
    const sourceIndex = playlist.position.sourceIndex;
    const _playlist = isVideo ? _.get(playlist.data, ["video", sourceIndex], []) : playlist.data.image;
    const index = playlist.position.currentIndex;
    const timeOffset = playlist.position.currentTimeOffset;

    const currentVideoKey = _.get(_playlist, [index, 0]);
    const _markers = filteredMarkers(
        markers,
        sessionObservations,
        playlist.data.routeID,
        markers.selectedMarker,
        markerReviewFilters,
        detectionTypes,
        markerThresholdFilters,
        defThresholds,
        markerConditionFilter,
        observationFilters,
    );

    const closestMarker = currentMarker(_markers, currentVideoKey, _playlist, index, timeOffset, _.get(markers, "selectedMarker", {}));
    const mapPosition = _.get(playlist.position, "coords", null);
    const markerPosition = _.get(closestMarker, "coords", null);

    const combinedMarkers = [...markers.perSession[playlist.data.routeID], ...sessionObservations].filter((item) =>
        markerReviewFilters.includes(item.review_status),
    );

    const displayESSOW = _.get(currentDashboard, ["config", "show_essow_input"], false);

    return {
        currentDashboard,
        selectedMarker: closestMarker ? closestMarker : {},
        geometry: mapGeometry,
        sessions,
        sessionID: sessionID,
        session: sessions[sessionID],
        allMatchingMarkers: allMatchingMarkers.length ? allMatchingMarkers : allMatchingObservations,
        markers: _markers,
        imageKeys: _playlist,
        currentIndex: playlist.position.currentIndex,
        currentTimeOffset: timeOffset,
        data_pool_permissions: _.get(currentDashboard, ["data_pool_permissions"], EMPTY_OBJECT),
        userDetails,
        conditionTypes: markers.conditionTypes,
        sessionObservations,
        markerReviewFilters,
        markerDistance: calculateMarkerDistance(mapPosition, markerPosition, currentVideoKey, closestMarker, _playlist, index, timeOffset),
        detectionTypes: getDetectionTypes(markersPerSession, sessionObservations, markerReviewFilters),
        combinedMarkers,
        currentVideoKey,
        playlist: _playlist,
        csrfToken,
        displayESSOW,
        objectObservationDataTypes,
    };
};

export default connect(mapStateToProps)(MarkerInformation);
