import React, { useMemo, useEffect, useState, useCallback } from "react";
import { DatePicker, Modal, Select, Tooltip, Button, Input, notification, Divider, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/pro-regular-svg-icons";
import RoutePatrolSvg from "./RoutePatrolSvg";
import ELRMileAndChain from "../util/ELRMileAndChain";
import {
    getPatrolPlans,
    receivePatrolPlan,
    getPatrolRailImagesV2,
    getPatrolDeviceConfig,
    getPatrolDirections,
    clearPatrolImages,
    patrolImagesLoaded,
    toggleFetchingELRs,
    createPatrol,
    clearPatrolState,
    getPatrolRuns,
    getPatrolDirectionsV2,
    togglePatrolDetailModal,
    toggleSettingsOpen,
} from "../../redux/actions/index";
import { useHistory, withRouter } from "react-router-dom";
import _ from "lodash";
import { faCog, faProjectDiagram } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import UnitSelectionModal from "components/UnitSelectionModal";
import { calculatePatrolDeviceConfig } from "components/util/Geometry";
import { ReactComponent as DetailRailIcon } from "../display/image/RailButtons/DetailRailIcon.svg";
import { calculateRoutePercentageCovered } from "components/util/PlaylistUtils";

const viewedProgressSelector = (state) => state.schemaInterface.covered;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const schemaLocationSelector = (state) => state.schemaInterface.location;
const schemaPlanSelector = (state) => state.schemaInterface.plan;
const patrolPlansSelector = (state) => state.schemaInterface.patrolPlans;
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;
const patrolRunsSelector = (state) => state.schemaInterface.patrolRuns;
const detailModalOpenSelector = (state) => state.schemaInterface.detailModalOpen;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const railImagesSelector = (state) => state.schemaInterface.images;

const CreatePatrolWindow = ({ open, closeWindow, planID, startTimestamp, endTimestamp, device }) => {
    const [name, setName] = useState("");
    const [dueTs, setDueTs] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);

    const dispatch = useDispatch();

    const cancel = () => {
        setName("");
        setDueTs(0);
        setLoading(false);
        closeWindow();
    };

    const createRoutePatrol = () => {
        setLoading(true);
        const due = dueTs ? dueTs.unix() : 0;
        dispatch(
            createPatrol(planID, name, startTimestamp, endTimestamp, device, due, sendEmail, (success) => {
                if (success) {
                    cancel();
                    notification.success({ message: "Successfully created patrol" });
                } else {
                    notification.error({ message: "Error creating patrol" });
                }
            }),
        );
    };

    return (
        <Modal
            onCancel={cancel}
            visible={open}
            footer={[
                <Checkbox
                    value={sendEmail}
                    onChange={(e) => {
                        setSendEmail(e.target.checked);
                    }}>
                    Send email
                </Checkbox>,
                <Button
                    onClick={cancel}
                    loading={loading}>
                    Cancel
                </Button>,
                <Button
                    onClick={createRoutePatrol}
                    type="primary"
                    disabled={!name}
                    loading={loading}>
                    Create
                </Button>,
            ]}>
            <div className="CreatePatrolContainer">
                <div className="PatrolInputContainer">
                    <span>Patrol Name</span>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="PatrolInputContainer">
                    <span>Due Date</span>
                    <DatePicker
                        value={dueTs}
                        onChange={setDueTs}
                    />
                </div>
            </div>
        </Modal>
    );
};

const PatrolCreatorHeader = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const progressMap = useSelector(viewedProgressSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const currentLocation = useSelector(schemaLocationSelector);
    const schemaPlan = useSelector(schemaPlanSelector);
    const patrolPlans = useSelector(patrolPlansSelector);
    const superAdmin = useSelector(superAdminSelector);
    const patrolRuns = useSelector(patrolRunsSelector);
    const detailModalOpen = useSelector(detailModalOpenSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const railImages = useSelector(railImagesSelector);

    const [createWindowOpen, setCreateWindowOpen] = useState(false);
    const [diagramOpen, setDiagramOpen] = useState(false);

    const [dateRange, setDateRange] = useState([null, null]);
    const [deviceGroup, setDeviceGroup] = useState(undefined);
    const [unitSelectionModalVisible, setUnitSelectionModalVisible] = useState(false);
    const [selectedRunID, setSelectedRunID] = useState(undefined);
    const [coverage, setCoverage] = useState(0);

    const planID = parseInt(props.match.params.planID);

    const getQueryParams = () => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        return params;
    };

    useEffect(() => {
        const params = getQueryParams();
        const timeFrom = params.get("from");
        const timeTo = params.get("to");
        const deviceID = params.get("device");

        if (timeFrom && timeTo && deviceID) {
            setDateRange([moment.unix(timeFrom), moment.unix(timeTo)]);
            setDeviceGroup(deviceID);
        }
    }, []);

    const initialised = useMemo(() => {
        return patrolPlans.length;
    }, [patrolPlans.length]);

    useEffect(() => {
        if (!initialised) {
            dispatch(getPatrolPlans());
        }
    }, [initialised, dispatch, planID]);

    useEffect(() => {
        dispatch(getPatrolRuns(planID));
    }, [dispatch, planID]);

    useEffect(() => {
        const patrolPlan = _.cloneDeep(_.find(patrolPlans, { id: planID }));
        if (patrolPlan) {
            patrolPlan.svg = JSON.stringify(patrolPlan.svg);
            dispatch(receivePatrolPlan(patrolPlan));
            dispatch(patrolImagesLoaded(true));
        }
    }, [patrolPlans, dispatch, planID]);

    const getPatrolData = useCallback(
        (startTs, endTs, deviceGroup) => {
            if (schemaPlan.id) {
                const customData = {
                    start_timestamp: startTs,
                    end_timestamp: endTs,
                    device_group: deviceGroup,
                };

                dispatch(toggleFetchingELRs(true));
                dispatch(clearPatrolImages());
                dispatch(getPatrolDeviceConfig(deviceGroup));
                dispatch(getPatrolRailImagesV2(schemaPlan.id, deviceGroup, 0, customData, 0));
                dispatch(getPatrolDirectionsV2(deviceGroup, startTs - 60 * 60 * 2, endTs));
            }
        },
        [dispatch, schemaPlan.id],
    );

    useEffect(() => {
        if (selectedRunID) {
            // updating device/dates will trigger a fetch of patrol data
            const selectedRun = _.find(patrolRuns, { id: selectedRunID });
            setDateRange([moment.unix(selectedRun.start_ts), moment.unix(selectedRun.end_ts)]);
            setDeviceGroup(selectedRun.device_group);
        }
    }, [getPatrolData, patrolRuns, selectedRunID]);

    useEffect(() => {
        if (dateRange[0] && dateRange[1] && deviceGroup) {
            const startTimestamp = dateRange[0].startOf("day").unix();
            const endTimestamp = dateRange[1].endOf("day").unix();

            getPatrolData(startTimestamp, endTimestamp, deviceGroup);
        }
    }, [schemaPlan, dispatch, dateRange, deviceGroup, getPatrolData]);

    const distanceUnits = useMemo(() => {
        if (elrUnits === "elr_mile_chain") {
            return "ELR Mile & Chain";
        } else if (elrUnits === "elr_mile_yards") {
            return "ELR Mile & Yards";
        } else if (elrUnits === "elr_meterage") {
            return "ELR & Meterage";
        }
    }, [elrUnits]);

    const locationDisplay = useMemo(() => {
        if (!currentLocation.elr) {
            return null;
        }
        const fieldObj = {
            ELR: currentLocation.elr,
            METERAGE: currentLocation.chain * 20.1168,
            TRACK: currentLocation.trackID,
        };
        const location = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_meterage", fieldObj, true);
        return location.to_string(elrUnits, []);
    }, [currentLocation, elrUnits]);

    const onBackClick = () => {
        dispatch(clearPatrolState());
        history.push("/patrols");
    };

    const onDateChange = (dates) => {
        setDateRange(dates);
    };

    const onDeviceGroupChange = (deviceID) => {
        setDeviceGroup(deviceID);
    };

    const getRunName = (run) => {
        const startDate = moment.unix(run.start_ts).format("DD/MM/YYYY");
        const endDate = moment.unix(run.end_ts).format("DD/MM/YYYY");

        let device = "";
        if (run.device_group === 11) {
            device = "VIU 3 (Pre-upgrade)";
        } else if (run.device_group === 10) {
            device = "VIU 2 (Pre-upgrade)";
        } else if (run.device_group === 1158125) {
            device = "VIU 1";
        } else if (run.device_group === 1171445) {
            device = "VIU 2";
        }
        return `${startDate} - ${endDate} (${device} - ${run.coverage}% coverage)`;
    };

    const sortedRuns = useMemo(() => {
        const sorted = _.orderBy(
            patrolRuns.filter((run) => run.coverage),
            "start_ts",
            "desc",
        );
        return sorted;
    }, [patrolRuns]);

    const toggleDetailRails = () => {
        dispatch(togglePatrolDetailModal(!detailModalOpen));
    };

    const railImageConfig = useMemo(() => {
        return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [railImageConfigs, railImages]);

    const hasDetailImages = useMemo(() => {
        if (_.isEmpty(railImageConfig)) {
            return false;
        }

        return !!railImageConfig.inspection_images.filter((config) => config.showInModal).length;
    }, [railImageConfig]);

    const calculateCoveragePercentage = (coverageLines, patrolLines) => {
        const formatLines = {};

        coverageLines.forEach((line) => {
            const ELR = _.get(line, "ELR", "");
            const TRID = _.get(line, "TRID", "");
            const start = _.get(line.coverage[0], "start", null);
            const end = _.get(line.coverage[0], "end", null);
            const length = { start, end };

            if (!ELR || !TRID || !start || !end) {
                return;
            }

            if (!_.has(formatLines, ELR)) {
                formatLines[ELR] = { [TRID]: [length] };
            } else {
                if (!_.has(formatLines[ELR], TRID)) {
                    formatLines[ELR] = { ...formatLines[ELR], [TRID]: [length] };
                } else {
                    formatLines[ELR][TRID] = [...formatLines[ELR][TRID], length];
                }
            }
        });

        const percentage = calculateRoutePercentageCovered(patrolLines, formatLines, props.totalPatrolChainage);
        setCoverage(percentage);
    };

    return (
        <div className="SchemaContentWrapper">
            {unitSelectionModalVisible && <UnitSelectionModal onClose={() => setUnitSelectionModalVisible(false)} />}

            <CreatePatrolWindow
                planID={planID}
                startTimestamp={dateRange[0] ? dateRange[0].startOf("day").unix() : null}
                endTimestamp={dateRange[1] ? dateRange[1].endOf("day").unix() : null}
                device={deviceGroup}
                closeWindow={() => setCreateWindowOpen(false)}
                open={createWindowOpen}
            />

            <FontAwesomeIcon
                icon={faArrowLeft}
                className="BackIcon"
                onClick={onBackClick}
            />
            <div className="TopContainer">
                <div className="SchemaLocationContainer">
                    <p className="Units">{distanceUnits}</p>
                    <FontAwesomeIcon
                        style={{ marginRight: 13, marginLeft: -5 }}
                        className="RouteInfo-ELRSettings__Icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            setUnitSelectionModalVisible(true);
                        }}
                        icon={faCog}
                    />
                    <span className="Value">{!!locationDisplay ? locationDisplay : "Unknown"}</span>
                </div>
            </div>

            <div className="SchemaInfo">
                <div className="SchemaInfoInner">
                    <div
                        className="SchemaTitleContainer"
                        style={{ gap: 10 }}>
                        <h1>{schemaPlan.name ? schemaPlan.name : "Unnamed Patrol Diagram"}</h1>
                    </div>

                    <div className="label">Choose from recent shifts</div>

                    <Select
                        onChange={setSelectedRunID}
                        style={{ width: 350 }}
                        value={selectedRunID}
                        placeholder="Choose a recent shift"
                        dropdownMatchSelectWidth={false}>
                        {sortedRuns.map((run) => {
                            return (
                                <Select.Option
                                    value={run.id}
                                    key={run.id}>
                                    {getRunName(run)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>

                <Divider style={{ color: "white", margin: "5px 0" }}>OR</Divider>

                <div className="SchemaInfoInner">
                    <div
                        className="SchemaTitleContainer"
                        style={{ gap: 10 }}>
                        <div className="label">Manually select train & dates</div>
                        <div className="SchemaTitleContainer-Row">
                            <div>
                                <DatePicker.RangePicker
                                    onChange={onDateChange}
                                    value={dateRange}
                                />
                            </div>
                        </div>
                        <div className="SchemaCoverageContainer">
                            <Select
                                onChange={onDeviceGroupChange}
                                style={{ width: 200 }}
                                value={deviceGroup}
                                placeholder="Choose train">
                                <Select.Option value={1158125}>VIU 1</Select.Option>
                                <Select.Option value={1171445}>VIU 2</Select.Option>
                                <Select.Option value={10}>VIU 2 (Pre-upgrade)</Select.Option>
                                <Select.Option value={11}>VIU 3 (Pre-upgrade)</Select.Option>
                            </Select>
                            {deviceGroup && <p className="SchemaPercentage">Inspection coverage: {coverage}%</p>}
                        </div>
                    </div>
                </div>
            </div>

            <RoutePatrolSvg
                planData={schemaPlan}
                progressMap={progressMap}
                calculateCoveragePercentage={calculateCoveragePercentage}
            />

            <Modal
                title="Track Patrolling Diagram"
                visible={diagramOpen}
                width={800}
                cancelText={"Close"}
                okText={"Report a problem"}
                onCancel={() => setDiagramOpen(false)}
                footer={[<Button onClick={() => setDiagramOpen(false)}>Close</Button>]}>
                <img
                    style={{ width: "100%" }}
                    alt="track Diagram"
                    src={schemaPlan.diagram_link}
                    crossOrigin={"anonymous"}
                />
            </Modal>

            <div className="SchemaToolbar">
                {superAdmin && (
                    <Button
                        disabled={!dateRange[0] || !dateRange[1] || !deviceGroup}
                        // disabled={false}
                        onClick={() => setCreateWindowOpen(true)}
                        type="primary">
                        Create Patrol
                    </Button>
                )}

                {hasDetailImages && (
                    <Tooltip title="Show Rail Detail Images">
                        <div
                            onClick={toggleDetailRails}
                            className={"SchemaToolbarItem" + (detailModalOpen ? " Active" : "")}>
                            <DetailRailIcon />
                        </div>
                    </Tooltip>
                )}

                {schemaPlan.diagram_link && (
                    <Tooltip title="View Track Patrolling Diagram">
                        <div
                            className={"SchemaToolbarItem"}
                            onClick={() => setDiagramOpen(true)}>
                            <FontAwesomeIcon
                                icon={faProjectDiagram}
                                className="Icon"
                            />
                        </div>
                    </Tooltip>
                )}

                <Tooltip title="Navigation">
                    <div
                        className={"SchemaToolbarItem" + (props.assetNavigationActive ? " Active" : "")}
                        onClick={() => props.setAssetNavigationOpen(!props.assetNavigationActive)}>
                        <FontAwesomeIcon
                            icon={faBars}
                            className="Icon"
                        />
                    </div>
                </Tooltip>

                <Tooltip title="Settings">
                    <div
                        className="SchemaToolbarItem"
                        onClick={() => dispatch(toggleSettingsOpen())}>
                        <FontAwesomeIcon
                            icon={faCog}
                            className="Icon"
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default withRouter(PatrolCreatorHeader);
