import React, { useMemo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
    currentPlaylistPosition,
    logTiming,
    togglePlayerState,
    reviewMarker,
    selectMarker,
    reviewSessionObservation,
    routeSelected,
    customAudit,
} from "redux/actions/index";
import {
    asyncLoadImage,
    getCoordinates,
    getOffsetAdjustedPosition,
    interpolate,
    keyLookup,
    makePromiseCancelable,
    filterMarkers,
    absoluteTimeLookup,
    binarySearch,
    calculateFrame,
    findNextExtent,
    findPreviousExtent,
    filterSessionObservations,
    filterMarkersByObservations,
    getReviewLabel,
    processTroughingBbox,
    getUrlDataForFrame,
} from "../../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Slider, Select } from "antd";
import Tippy from "@tippyjs/react";
import OBCSpinner from "../../util/OBC";
import FullScreenCapable from "../FullScreenCapable";
import memoizeOne from "memoize-one";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import InformationOverlayComponent from "../video/InformationOverlay";
import RouteHistoryComponent from "../video/RouteHistoryComponent";
import Measure from "react-measure";
import { convertToTimezone } from "../../util/TimezoneUtils";
import OneFrameIcon from "../../../icons/one-frame.svg";
import ZoomableImage from "components/ZoomableImage";
import AbsoluteColourImageDisplay from "./AbsoluteColourImageDisplay";
import { BackwardFilled, CaretRightOutlined, ForwardOutlined, LeftCircleOutlined, PauseOutlined, RightCircleOutlined } from "@ant-design/icons";

const SliderPreview = ({ allPreviewImages, previewImageKeys, offsettedX, index }) => {
    const imageToDisplay = useMemo(() => {
        const imageKeyIndexToUse = binarySearch(index, previewImageKeys, (key) => key);
        const imageData = allPreviewImages[previewImageKeys[imageKeyIndexToUse]];
        return imageData;
    }, [allPreviewImages, index, previewImageKeys]);

    if (imageToDisplay) {
        return (
            <div
                className="ScrubberPreview"
                style={{ left: `${offsettedX - 100}px` }}>
                <img
                    src={imageToDisplay}
                    crossOrigin={"anonymous"}
                    alt="Scrubber Preview"
                    className="PreviewImage"
                />
            </div>
        );
    } else {
        return null;
    }
};

class ImagePlayer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fullscreenComponent = React.createRef();

        this.imageURLToDisplay = null;
        this.markerURLToDisplay = null;
        this.imageCache = {};
        this.imageCacheOrder = [];
        this.videoPausedTimer = null;
        this.nextFrameTimer = null;
        this.flashNextTimer = null;
        this.flashPreviousTimer = null;
        this.imageLoadPromises = {};

        this.imagePreviewCache = {};
        this.imagePreviewKeys = [];

        this.state = {
            index: 0,
            subIndex: 1,
            loading: false,
            timeOfLastRouteChange: 0,
            imagesViewed: 0,
            enhancedImagesViewed: 0,
            imageIsEquirectangular: false,
            loadedImage: null,
            playing: false,
            markers: {},
            backupImage: null,
            rawImage: null,
            displayingMarker: false,
            markerID: 0,
            flashNext: false,
            flashPrevious: false,
            filteredMarkers: [],
            filteredObservations: [],
            mouseInScrubber: false,
            detectionsInterfaceOpen: true,
            mouseScrubberX: 0,
            indexHovered: 0,
            sliderSize: {
                height: -1,
                width: -1,
            },
            detectionsToDisplay: [],
            absoluteImageLoading: true,
        };
    }

    isInCache = (imageURL) => {
        return this.imageCache.hasOwnProperty(imageURL);
    };

    getFromCache = (imageURL) => {
        return this.imageCache[imageURL];
    };

    clearImageCache = () => {
        this.imageCache = {};
        this.imageCacheOrder = [];
    };

    addToCache = (imageURL, imageData) => {
        if (!this.isInCache(imageURL)) {
            this.imageCache[imageURL] = imageData;
            this.imageCacheOrder.push(imageURL);

            if (this.imageCacheOrder.length > 15) {
                const urlToRemove = this.imageCacheOrder.shift();
                delete this.imageCache[urlToRemove];
            }
        }
    };

    updateUrlCachePriority = (imageURL) => {
        const urlIndex = this.imageCacheOrder.indexOf(imageURL);
        const element = this.imageCacheOrder.splice(urlIndex, 1);
        if (element && element.length) {
            this.imageCacheOrder.push(element[0]);
        }
    };

    componentDidMount() {
        this.componentDidUpdate({}, {});
        this.setState({
            timeOfLastRouteChange: new Date().getTime(),
        });

        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    handleKeyPress = (event) => {
        const target = event.target.nodeName;
        console.log("Event target:", target, "tool mode:", this.props.toolMode);

        if (target !== "BODY" && target !== "I" && target !== "BUTTON") {
            return;
        }
        const selectedMarker = this.props.selectedMarker;
        const reviewPermissions =
            !!_.get(this.props.data_pool_permissions, [_.get(this.props.session, ["data_pool"], false), "review"], false) ||
            this.props.userDetails.userConfig.super_admin;
        const reviewingMarker = this.state.displayingMarker && !!selectedMarker && !this.props.shareLink && reviewPermissions;

        if (reviewingMarker && (event.key === "v" || event.key === "V")) {
            this.reviewMarker(1);
        } else if (reviewingMarker && (event.key === "h" || event.key === "H")) {
            this.reviewMarker(2);
        } else if (reviewingMarker && (event.key === "r" || event.key === "R")) {
            this.reviewMarker(3);
        } else if (event.key === "ArrowLeft") {
            this.prevFrame();
        } else if (event.key === "ArrowRight") {
            this.nextFrame();
        } else if (event.key === ",") {
            if (this.hasPreviousMarker()) {
                _.get(this.props.selectedMarker, "continuous", false) ? this.prevExtent() : this.previousMarker(true);
            }
        } else if (event.key === ".") {
            if (this.hasNextMarker()) {
                _.get(this.props.selectedMarker, "continuous", false) ? this.nextExtent() : this.nextMarker(true);
            }
        }
    };

    playNext = () => {
        if (this.props.playSpeed !== 0) {
            let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, 1);
            if (index !== this.state.index || subIndex !== this.state.subIndex) {
                this.selectIndex(index, subIndex);
                const timeToNextFrame = 1000 / this.props.playSpeed;
                console.log("Next frame time: ", timeToNextFrame);
                this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
            } else {
                this.setState({
                    playing: false,
                });
            }
        }
    };

    toggleDetectionsInterface = () => {
        this.setState({ detectionsInterfaceOpen: !this.state.detectionsInterfaceOpen });
    };

    togglePlaying = (pause = false) => {
        if (this.state.playing || pause) {
            clearTimeout(this.nextFrameTimer);
            this.setState({
                playing: false,
            });
        } else {
            this.setState({
                playing: true,
            });
            if (this.props.playSpeed !== 0) {
                const timeToNextFrame = 1000 / this.props.playSpeed;
                this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
            }
        }
    };

    pausePlayer = () => {
        clearTimeout(this.nextFrameTimer);
        this.setState({
            playing: false,
        });
    };

    flashNext = () => {
        console.log("flashNext...");
        const _this = this;

        clearTimeout(this.flashNextTimer);

        this.setState(
            {
                flashNext: true,
            },
            () => {
                this.flashNextTimer = setTimeout(function () {
                    _this.setState({
                        flashNext: false,
                    });
                }, 200);
            },
        );
    };

    flashPrevious = () => {
        const _this = this;

        clearTimeout(this.flashPreviousTimer);

        this.setState(
            {
                flashPrevious: true,
            },
            () => {
                this.flashPreviousTimer = setTimeout(function () {
                    _this.setState({
                        flashPrevious: false,
                    });
                }, 200);
            },
        );
    };

    checkNextDisabled = () => {
        if (this.state.index === this.props.imageKeys.length - 1) {
            if (this.props.isStills) {
                let targetKey = this.props.imageKeys[this.state.index];
                let frameCount = 0;
                if (targetKey) {
                    frameCount = targetKey[5];
                }
                if (frameCount) {
                    if (this.state.subIndex >= frameCount) {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
        return false;
    };

    checkPreviousDisabled = () => {
        if (this.state.index < 1 && (!this.props.isStills || this.state.subIndex <= 1)) {
            return true;
        }
        return false;
    };

    updatePlaylistPosition = () => {
        if (this.props.hasOwnProps) {
            return;
        }
        let targetKey = this.props.imageKeys[this.state.index];
        if (targetKey) {
            if (this.props.isStills) {
                let ratio = 0;
                let timeOffset = 0;
                const frameCount = targetKey[5];
                const duration = targetKey[2];

                if (frameCount) {
                    ratio = (this.state.subIndex - 1) / frameCount;
                    if (ratio >= 0) {
                        timeOffset = ratio * duration;
                    }
                }
                let currentTime = targetKey[1] + timeOffset;

                let position = getOffsetAdjustedPosition(currentTime, this.props.imageKeys, this.props.offsets, this.props.use_snapped);

                if (position !== null) {
                    let playlistIndex = position[0];
                    let timeOffset = position[1];
                    let coords = [null, null];
                    if (position[2]) {
                        coords = position[2];
                    }
                    this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
                } else {
                    let interpolatedPosition;
                    let start = getCoordinates(targetKey[3], this.props.use_snapped);
                    let nextTargetKey = this.props.imageKeys[this.state.index + 1];
                    if (nextTargetKey) {
                        let end = getCoordinates(nextTargetKey[3], this.props.use_snapped);
                        if (start && end) {
                            interpolatedPosition = interpolate(start, end, ratio);
                        } else if (start) {
                            interpolatedPosition = start;
                        } else {
                            interpolatedPosition = start;
                        }
                    } else {
                        interpolatedPosition = start;
                    }
                    if (interpolatedPosition) {
                        this.props.dispatch(currentPlaylistPosition(this.state.index, interpolatedPosition, timeOffset));
                    }
                }
            } else {
                this.props.dispatch(currentPlaylistPosition(this.state.index, targetKey[1], 0));
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.toolMode && this.props.toolMode) {
            this.pausePlayer();
        }

        if (prevProps.routeID && prevProps.routeID !== this.props.routeID) {
            this.recordTimeSpent(prevProps.routeID);
        } else if (prevProps.routeID && prevProps.isEnhanced !== this.props.isEnhanced) {
            this.recordTimeSpentInMode(prevProps.routeID);
        }

        if (this.state.playing && this.props.playSpeed && !prevProps.playSpeed) {
            const timeToNextFrame = 1000 / this.props.playSpeed;
            this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
        }

        if (this.state.index !== prevState.index || this.state.subIndex !== prevState.subIndex) {
            if (this.state.index < 1 && (!this.props.isStills || this.state.subIndex <= 1)) {
                this.flashPrevious();
            } else if (this.state.index === this.props.imageKeys.length - 1) {
                if (this.props.isStills) {
                    let targetKey = this.props.imageKeys[this.state.index];
                    let frameCount = 0;
                    if (targetKey) {
                        frameCount = targetKey[5];
                    }
                    if (frameCount) {
                        if (this.state.subIndex >= frameCount) {
                            this.flashNext();
                        }
                    } else {
                        this.flashNext();
                    }
                } else {
                    this.flashNext();
                }
            }
        }

        if (this.props.imageKeys && this.props.imageKeys.length) {
            let forceUpdate = false;

            if (
                !(prevProps.imageKeys && prevProps.imageKeys.length) ||
                prevProps.routeID !== this.props.routeID ||
                prevProps.isEnhanced !== this.props.isEnhanced ||
                prevProps.imageKeys !== this.props.imageKeys
            ) {
                this.regenerateMarkers();
                this.clearImageCache();
                this.setState({
                    loadedImage: null,
                    rawImage: null,
                    backupImage: "",
                });
                forceUpdate = true;
            }
            if (this.props.isSlave) {
                if (this.props.slaveTimestamp !== prevProps.slaveTimestamp || forceUpdate) {
                    let targetKeyIndex = absoluteTimeLookup(this.props.slaveTimestamp, this.props.imageKeys);
                    targetKeyIndex = Math.min(Math.max(0, targetKeyIndex), this.props.imageKeys.length);

                    let targetKey = this.props.imageKeys[targetKeyIndex];

                    if (targetKey) {
                        const frameCount = targetKey[5];
                        let offset = this.props.slaveTimestamp - targetKey[3][2];

                        let subIndex = 1;
                        if (frameCount) {
                            const duration = targetKey[2];
                            const ratio = offset / duration;
                            if (ratio >= 1) {
                                subIndex = frameCount;
                            } else if (ratio <= 0) {
                                subIndex = 1;
                            } else {
                                subIndex = Math.floor(ratio * frameCount) + 1;
                            }
                        }
                        this.selectIndex(targetKeyIndex, subIndex, forceUpdate);
                    }
                }
            } else if (forceUpdate || this.props.requestTS !== prevProps.requestTS) {
                if (this.props.isStills) {
                    let subIndex = calculateFrame(this.props.imageKeys, this.props.position, this.props.requestedTimeOffset);
                    this.selectIndex(this.props.position, subIndex, forceUpdate);
                } else {
                    this.selectIndex(this.props.position, 1, forceUpdate);
                }
                this.updatePlaylistPosition();
            } else if (this.state.index !== prevState.index || this.state.subIndex !== prevState.subIndex) {
                let targetKey = this.props.imageKeys[this.state.index];
                if (targetKey) {
                    clearTimeout(this.videoPausedTimer);
                    this.props.dispatch(togglePlayerState("playing"));
                    this.videoPausedTimer = setTimeout(() => {
                        this.props.dispatch(togglePlayerState("paused"));
                    }, 1200);
                }
                this.updatePlaylistPosition();
            }
        } else if (this.state.loadedImage) {
            this.setState({
                loadedImage: null,
                rawImage: null,
                backupImage: "",
            });
        }

        if (
            this.props.selectedTagCategory !== prevProps.selectedTagCategory ||
            this.props.markers !== prevProps.markers ||
            this.props.routeStartTime !== prevProps.routeStartTime ||
            this.props.routeEndTime !== prevProps.routeEndTime ||
            this.props.markerReviewFilters !== prevProps.markerReviewFilters ||
            this.props.thresholdFilters !== prevProps.thresholdFilters ||
            this.props.markerConditionFilter !== prevProps.markerConditionFilter ||
            this.props.sessionObservations !== prevProps.sessionObservations
        ) {
            this.regenerateMarkers();
        }

        if (prevProps.offsets !== this.props.offsets && (_.get(prevProps.offsets, "length", 0) > 0 || _.get(this.props.offsets, "length", 0) > 0)) {
            this.updatePlaylistPosition();
        } else if (prevProps.use_snapped !== this.props.use_snapped) {
            this.updatePlaylistPosition();
        }

        if (prevProps.toolMode !== this.props.toolMode) {
            if (this.props.toolMode) {
                this.togglePlaying(true);
            }
        } else if (!prevProps.viewSnapshot && this.props.viewSnapshot) {
            this.togglePlaying(true);
        }

        if (
            (this.props.imageKeys?.length && !prevProps.imageKeys?.length) ||
            prevProps.sourceIndex !== this.props.sourceIndex ||
            prevProps.routeID !== this.props.routeID
        ) {
            this.cachePreviewImages();
        }
    }

    cachePreviewImages = () => {
        const PREVIEW_COUNT = 20;
        const interval = this.props.imageKeys.length / PREVIEW_COUNT;
        this.imagePreviewCache = {};
        this.imagePreviewKeys = [];
        const promises = [];
        for (let i = 0; i < this.props.imageKeys.length; i += interval) {
            const idx = Math.floor(i);
            const videoKey = this.props.imageKeys[idx][0];
            let imageURL = `${this.props.baseURL}${videoKey}.sml.jpg`;
            if (this.props.csrfToken) {
                imageURL += `?csrf=${this.props.csrfToken}`;
            }
            promises.push(
                asyncLoadImage(imageURL).then((imageData) => {
                    this.imagePreviewCache[idx] = imageData;
                }),
            );
        }

        Promise.all(promises).then(() => {
            this.imagePreviewKeys = Object.keys(this.imagePreviewCache).sort(function (a, b) {
                return a - b;
            });
        });
    };

    cacheMarker = (marker) => {
        let urlFrameData = this.getUrlDataForImage(0, marker.frame, false, marker.video_key);
        if (marker.custom_thumbnail && !marker.custom_thumbnail.includes("raw.aivr.video")) {
            urlFrameData = {
                url: marker.custom_thumbnail,
                cacheKey: marker.custom_thumbnail,
            };
        }

        const cacheKey = urlFrameData.cacheKey;
        if (!this.isInCache(cacheKey)) {
            return this.precacheImage(urlFrameData);
        } else {
            this.updateUrlCachePriority(cacheKey);
        }
    };

    cacheMarkerImages = (currentVideoIndex) => {
        if (!this.props.imageKeys || !this.props.imageKeys.length || currentVideoIndex < 0) {
            return;
        }

        const markers = _.sortBy(this.state.filteredMarkers, (marker) => marker.index);
        const observations = filterSessionObservations(
            this.props.sessionObservations.filter((obs) => obs.class === this.props.selectedMarker.class),
            this.props.observationFilters,
        );

        let currentObservationIndex = _.findIndex(observations, (observation) => observation.video_key === this.props.imageKeys[currentVideoIndex][0]);
        let currentMarkerIndex = _.findIndex(markers, (marker) => marker.video_key === this.props.imageKeys[currentVideoIndex][0]);

        const nextMarker = markers[currentMarkerIndex + 1];
        if (nextMarker) {
            this.cacheMarker(nextMarker);
        }

        const nextNextMarker = markers[currentMarkerIndex + 2];
        if (nextNextMarker) {
            this.cacheMarker(nextNextMarker);
        }

        const previousMarker = markers[currentMarkerIndex - 1];
        if (previousMarker) {
            this.cacheMarker(previousMarker);
        }

        const previousPreviousMarker = markers[currentMarkerIndex - 2];
        if (previousPreviousMarker) {
            this.cacheMarker(previousPreviousMarker);
        }

        const nextObservation = observations[currentObservationIndex + 1];
        if (nextObservation) {
            this.cacheMarker(nextObservation);
        }

        const nextNextObservation = observations[currentObservationIndex + 2];
        if (nextNextObservation) {
            this.cacheMarker(nextNextObservation);
        }

        const previousObservation = observations[currentObservationIndex - 1];
        if (previousObservation) {
            this.cacheMarker(previousObservation);
        }

        const previousPreviousObservation = observations[currentObservationIndex - 2];
        if (previousPreviousObservation) {
            this.cacheMarker(previousPreviousObservation);
        }
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);

        if (this.props.routeID) {
            this.recordTimeSpent(this.props.routeID);
        }

        // resolved promises should already have been removed
        for (const key in this.imageLoadPromises) {
            if (this.imageLoadPromises.hasOwnProperty(key)) {
                const promise = this.imageLoadPromises[key];
                promise.cancel();
            }
        }

        clearTimeout(this.videoPausedTimer);
        clearTimeout(this.nextFrameTimer);
        clearTimeout(this.flashNextTimer);
        clearTimeout(this.flashPreviousTimer);
        this.props.dispatch(togglePlayerState("paused"));
    }

    precacheImage = (imageUrlData) => {
        const imageCacheKey = imageUrlData.cacheKey;
        const imageURL = imageUrlData.url;
        const imageRange = imageUrlData.range;

        const imageLoadCallback = (imageData) => {
            this.addToCache(imageCacheKey, imageData);
        };
        return asyncLoadImage(imageURL, imageRange, imageLoadCallback);
    };

    loadImage = (imageUrlData, marker = false) => {
        const imageCacheKey = imageUrlData.cacheKey;

        if (this.isInCache(imageCacheKey)) {
            return new Promise((resolve) => {
                resolve(this.getFromCache(imageCacheKey));
            });
        } else {
            if (marker) {
                return this.cacheMarker(marker);
            } else {
                return this.precacheImage(imageUrlData);
            }
        }
    };

    recordTimeSpent = (lastRouteID) => {
        const now = new Date().getTime();
        logTiming("Images", "Time Spent Open", now - this.state.timeOfLastRouteChange, lastRouteID);
        this.setState({
            timeOfLastRouteChange: now,
        });
        this.recordTimeSpentInMode(lastRouteID);
    };

    recordTimeSpentInMode = (lastRouteID) => {
        if (this.state.imagesViewed) {
            logTiming("Images", "Images Viewed", this.state.imagesViewed, lastRouteID);
        }
        if (this.state.enhancedImagesViewed) {
            logTiming("Images", "Enhanced Images Viewed", this.state.enhancedImagesViewed, lastRouteID);
        }
        this.setState({
            imagesViewed: 0,
            enhancedImagesViewed: 0,
        });
    };

    imageIndexChanged = (newIndex) => {
        this.selectIndex(newIndex, 1);
    };

    selectIndex = (newIndex, subIndex, force = false) => {
        if (newIndex >= 0 && newIndex < this.props.imageKeys.length && (newIndex !== this.state.index || subIndex !== this.state.subIndex || force)) {
            this.setState({
                index: newIndex,
                subIndex,
                loading: true,
                imagesViewed: this.state.imagesViewed + (this.props.isEnhanced ? 0 : 1),
                enhancedImagesViewed: this.state.enhancedImagesViewed + (this.props.isEnhanced ? 1 : 0),
            });

            this.cacheMarkerImages(newIndex);

            const markers = this.state.filteredMarkers;
            const imageKeys = this.props.imageKeys;
            const observations = this.state.filteredObservations;
            const selectedMarker = this.props.selectedMarker;
            const selected_observation_number = _.get(selectedMarker, "observation_number");

            const markerFound = this.checkForMarker(newIndex, subIndex, markers, imageKeys, observations, selectedMarker, selected_observation_number);
            if (markerFound) {
                this.preloadMarker(newIndex, subIndex, false, markerFound);
            }
            this.preload(newIndex, subIndex, true);
        }
    };

    nextFrame = () => {
        let disabled = this.checkNextDisabled();
        if (disabled) {
            this.flashNext();
            return;
        }
        let { index, subIndex } = this.getIndexByDirection(1);
        this.selectIndex(index, subIndex);
        this.checkForDetections(index, subIndex);
    };

    prevFrame = () => {
        let disabled = this.checkPreviousDisabled();
        if (disabled) {
            this.flashPrevious();
            return;
        }
        let { index, subIndex } = this.getIndexByDirection(-1);
        this.selectIndex(index, subIndex);
        this.checkForDetections(index, subIndex);
    };

    getIndexByDirection(direction) {
        let nextIndex;
        let nextSubIndex;

        if (this.props.isStills) {
            nextIndex = this.state.index;
            nextSubIndex = this.state.subIndex + direction;

            const playlistItem = this.props.imageKeys[nextIndex];
            if (!playlistItem[5] || nextSubIndex > playlistItem[5]) {
                nextIndex += direction;
                nextSubIndex = 1;
            } else if (nextSubIndex <= 0) {
                if (nextIndex > 0) {
                    nextIndex -= 1;
                    const playlistItem = this.props.imageKeys[nextIndex];
                    if (playlistItem[5]) {
                        nextSubIndex = playlistItem[5];
                    } else {
                        nextSubIndex = 1;
                    }
                } else {
                    nextIndex = 0;
                    nextSubIndex = 1;
                }
            }
        } else {
            nextIndex = Math.max(0, this.state.index + direction);
            nextSubIndex = 1;
        }
        return { index: nextIndex, subIndex: nextSubIndex };
    }

    forwardByTime(time) {
        let disabled = this.checkNextDisabled();
        if (disabled) {
            this.flashNext();
            return;
        }
        let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, time);
        this.selectIndex(index, subIndex);
        this.checkForDetections(index, subIndex);
    }

    backwardByTime(time) {
        let disabled = this.checkPreviousDisabled();
        if (disabled) {
            this.flashPrevious();
            return;
        }
        let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, -time);
        this.selectIndex(index, subIndex);
        this.checkForDetections(index, subIndex);
    }

    adjust(index, subIndex, time) {
        let playlistItem = this.props.imageKeys[index];

        if (!this.props.isStills) {
            const currentTime = playlistItem[1][2];
            const newTime = currentTime + time;
            const newIndex = absoluteTimeLookup(newTime, this.props.imageKeys);
            return { index: newIndex, subIndex };
        }

        let frameCount = playlistItem[5];
        if (!frameCount) {
            frameCount = 1;
        }
        let frameDuration = playlistItem[2];

        let newIndex = index;

        const subIndexTimeOffset = ((subIndex - 1) / frameCount) * frameDuration;
        let targetTimeOffset = subIndexTimeOffset + time;
        while (targetTimeOffset < 0) {
            newIndex -= 1;
            if (newIndex < 0) {
                newIndex = 0;
                targetTimeOffset = 0;
                break;
            }
            playlistItem = this.props.imageKeys[newIndex];
            frameDuration = playlistItem[2];
            targetTimeOffset += frameDuration;
        }
        while (targetTimeOffset >= frameDuration) {
            targetTimeOffset -= frameDuration;
            newIndex += 1;
            if (newIndex >= this.props.imageKeys.length) {
                newIndex -= 1;
                targetTimeOffset = this.props.imageKeys[newIndex][2];
                break;
            }
            playlistItem = this.props.imageKeys[newIndex];
            frameDuration = playlistItem[2];
        }

        targetTimeOffset += 0.001;

        frameCount = playlistItem[5];

        const frameRatio = targetTimeOffset / frameDuration;
        let newSubIndex;

        if (!frameCount) {
            if (frameRatio >= 0.5 && newIndex < this.props.imageKeys.length - 1) {
                newIndex += 1;
            }
            newSubIndex = 1;
        } else if (frameRatio < 0) {
            newSubIndex = 1;
        } else if (frameRatio >= 1) {
            newSubIndex = frameCount;
        } else {
            newSubIndex = Math.floor(frameRatio * frameCount) + 1;
        }

        return { index: newIndex, subIndex: newSubIndex };
    }

    nextSecond = () => {
        this.forwardByTime(1);
    };

    prevSecond = () => {
        this.backwardByTime(1);
    };

    nextTenSeconds = () => {
        this.forwardByTime(10);
    };

    prevTenSeconds = () => {
        this.backwardByTime(10);
    };

    nextMinute = () => {
        this.forwardByTime(60);
    };

    prevMinute = () => {
        this.backwardByTime(60);
    };

    getUrlDataForImage = (index, subIndex, small, videoKey = null) => {
        if (index !== -1) {
            let selectedKey = this.props.imageKeys[index];
            if (selectedKey) {
                let imageKey = selectedKey[0];

                let imageFile = imageKey;
                let range = null;

                if (this.props.isStills) {
                    const urlFrameData = getUrlDataForFrame(this.props.imageKeys, index, subIndex, videoKey);
                    imageFile = urlFrameData.imageFile;
                    range = urlFrameData.range;
                } else {
                    if (this.props.isEnhanced === "enhanced") {
                        imageFile += ".enh.jpg";
                    }

                    if (small) {
                        imageFile += ".sml.jpg";
                    }
                }

                let url = this.props.baseURL + imageFile;
                if (this.props.csrfToken) {
                    url += `?csrf=${this.props.csrfToken}`;
                }

                return {
                    url,
                    range,
                    cacheKey: `${url}.${subIndex}`,
                };
            }
        }
        return null;
    };

    currentVideoKey = () => {
        return this.props.imageKeys[this.state.index][0] || null;
    };

    preload = (index, subIndex, small) => {
        console.log("Preload...");
        if (index !== -1) {
            let selectedKey = this.props.imageKeys[index];
            if (selectedKey) {
                const imageUrlData = this.getUrlDataForImage(index, subIndex, small);
                if (!imageUrlData || !imageUrlData.url) {
                    return;
                }

                let isEquirectangular;
                if (this.props.isStills) {
                    isEquirectangular = false;
                    small = false;
                } else {
                    isEquirectangular = selectedKey[0].startsWith("eq_");
                }

                const imageCacheKey = imageUrlData.cacheKey;
                this.imageURLToDisplay = imageCacheKey;
                const cancelableLoadImage = makePromiseCancelable(this.loadImage(imageUrlData));

                this.imageLoadPromises[imageCacheKey] = cancelableLoadImage;
                cancelableLoadImage.promise
                    .then((imageData) => {
                        if (imageData && this.imageURLToDisplay === imageCacheKey) {
                            delete this.imageLoadPromises[imageCacheKey];
                            this.imageLoadComplete(index, subIndex, small, isEquirectangular, imageData, false);
                        }
                    })
                    .catch(() => {});
            }
        }
    };

    imageLoadComplete = (index, subIndex, small, isEquirectangular, imageData, enhanced) => {
        let newState = {
            imageIsEquirectangular: isEquirectangular,
            loading: false,
        };

        if (enhanced) {
            newState.loadedImage = imageData;
        } else {
            newState.rawImage = imageData;
        }

        this.setState(newState, () => {
            if (small) {
                this.preload(index, subIndex, false);
            } else {
                let precache;
                if (this.props.isStills) {
                    precache = ({ index, subIndex }) => {
                        const frameData = this.getUrlDataForImage(index, subIndex, false);
                        if (frameData && !this.isInCache(frameData.cacheKey)) {
                            this.precacheImage(frameData).catch(() => {});
                        }
                    };
                } else {
                    precache = ({ index, subIndex }) => {
                        const smallFrameUrlData = this.getUrlDataForImage(index, subIndex, true);
                        const largeFrameUrlData = this.getUrlDataForImage(index, subIndex, false);

                        if (smallFrameUrlData && !this.isInCache(smallFrameUrlData.cacheKey)) {
                            this.precacheImage(smallFrameUrlData)
                                .catch(() => {})
                                .then(() => {
                                    if (largeFrameUrlData && !this.isInCache(largeFrameUrlData.cacheKey)) {
                                        this.precacheImage(largeFrameUrlData).catch(() => {});
                                    }
                                });
                        } else if (largeFrameUrlData && !this.isInCache(largeFrameUrlData.cacheKey)) {
                            this.precacheImage(largeFrameUrlData).catch(() => {});
                        }
                    };
                }

                precache(this.adjust(index, subIndex, 1));
                precache(this.adjust(index, subIndex, -1));
                precache(this.adjust(index, subIndex, 10));
                precache(this.adjust(index, subIndex, -10));
                precache(this.adjust(index, subIndex, 60));
                precache(this.adjust(index, subIndex, -60));

                precache(this.getIndexByDirection(1));
                precache(this.getIndexByDirection(-1));
            }
        });
    };

    preloadMarker = (index, subIndex, small, marker) => {
        if (index !== -1) {
            let selectedKey = this.props.imageKeys[index];
            if (selectedKey) {
                if (!marker) {
                    return;
                }

                let isEquirectangular;
                if (this.props.isStills) {
                    isEquirectangular = false;
                    small = false;
                } else {
                    isEquirectangular = selectedKey[0].startsWith("eq_");
                }

                let imageUrlData = this.getUrlDataForImage(0, marker.frame, false, marker.video_key);
                if (marker.custom_thumbnail && !marker.custom_thumbnail.includes("raw.aivr.video")) {
                    imageUrlData = {
                        url: marker.custom_thumbnail,
                        cacheKey: marker.custom_thumbnail,
                    };
                }
                const imageCacheKey = imageUrlData.cacheKey;

                this.markerURLToDisplay = imageCacheKey;
                this.loadImage(imageUrlData, marker)
                    .then((imageData) => {
                        if (imageData && this.markerURLToDisplay === imageCacheKey) {
                            this.imageLoadComplete(index, subIndex, small, isEquirectangular, imageData, true);
                        }
                    })
                    .catch((e) => {
                        console.log("Error getting marker image", e);
                    });
            }
        }
    };

    playlistIndexesWithMarkers = memoizeOne((selectedMarker, markerReviewFilters, observationFilters, sessionObservations, imageKeys, filteredMarkers) => {
        let indexes;
        if (selectedMarker.hasOwnProperty("observation_number")) {
            indexes = filterSessionObservations(
                sessionObservations.filter(
                    (observation) => observation.class === selectedMarker.class && markerReviewFilters.includes(observation.review_status),
                ),
                observationFilters,
            ).map((obs) => {
                let index = _.findIndex(imageKeys, (key) => key[0] === obs.video_key);
                return [index, obs.frame];
            });
        } else {
            indexes = _.map(filteredMarkers, (marker) => [marker.index, marker.frame]);
        }
        let playlistIndexesWithMarkers = _.sortBy(
            _.filter(indexes, ([idx, _frame]) => idx > -1),
            ([idx, _frame]) => idx,
            ([idx, _frame]) => _frame,
        );

        return playlistIndexesWithMarkers;
    });

    hasNextMarker = () => {
        let currentPlaylistIndex = this.state.index;
        let nextPlaylistIndex = _.find(
            this.playlistIndexesWithMarkers(
                this.props.selectedMarker,
                this.props.markerReviewFilters,
                this.props.observationFilters,
                this.props.sessionObservations,
                this.props.imageKeys,
                this.state.filteredMarkers,
            ),
            ([idx, frame]) => idx > currentPlaylistIndex || (idx === currentPlaylistIndex && frame > this.state.subIndex),
        );
        if (nextPlaylistIndex === undefined) {
            return false;
        }
        return true;
    };

    hasPreviousMarker = () => {
        let currentPlaylistIndex = this.state.index;
        let nextPlaylistIndex = _.findLast(
            this.playlistIndexesWithMarkers(
                this.props.selectedMarker,
                this.props.markerReviewFilters,
                this.props.observationFilters,
                this.props.sessionObservations,
                this.props.imageKeys,
                this.state.filteredMarkers,
            ),
            ([idx, frame]) => idx < currentPlaylistIndex || (idx === currentPlaylistIndex && frame < this.state.subIndex),
        );
        if (nextPlaylistIndex === undefined) {
            return false;
        }
        return true;
    };

    nextMarker = () => {
        let indexes;
        let isObservation = false;
        if (this.props.selectedMarker.hasOwnProperty("observation_number")) {
            indexes = filterSessionObservations(
                this.props.sessionObservations.filter(
                    (observation) =>
                        observation.class === this.props.selectedMarker.class && this.props.markerReviewFilters.includes(observation.review_status),
                ),
                this.props.observationFilters,
            ).map((obs) => {
                let index = _.findIndex(this.props.imageKeys, (key) => key[0] === obs.video_key);
                return [index, obs.frame, obs];
            });
            isObservation = true;
        } else {
            indexes = _.map(this.state.filteredMarkers, (marker) => [marker.index, marker.frame, marker]);
        }

        let playlistIndexesWithMarkers = _.sortBy(
            _.filter(indexes, ([idx, _frame]) => idx > -1),
            ([idx, _frame]) => idx,
            ([idx, _frame]) => _frame,
        );

        let currentPlaylistIndex = this.state.index;
        let nextPlaylistIndex = _.find(
            playlistIndexesWithMarkers,
            ([idx, frame]) => idx > currentPlaylistIndex || (idx === currentPlaylistIndex && frame > this.state.subIndex),
        );

        if (nextPlaylistIndex === undefined) {
            this.flashNext();
            nextPlaylistIndex = currentPlaylistIndex;
        }
        let marker = nextPlaylistIndex[2];
        this.props.dispatch(selectMarker(marker, isObservation));
        this.props.dispatch(
            routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame, false, false, null, null, false),
        );
    };

    previousMarker = () => {
        let indexes;
        let isObservation = false;
        if (this.props.selectedMarker.hasOwnProperty("observation_number")) {
            indexes = filterSessionObservations(
                this.props.sessionObservations.filter(
                    (observation) =>
                        observation.class === this.props.selectedMarker.class && this.props.markerReviewFilters.includes(observation.review_status),
                ),
                this.props.observationFilters,
            ).map((obs) => {
                let index = _.findIndex(this.props.imageKeys, (key) => key[0] === obs.video_key);
                return [index, obs.frame, obs];
            });
            isObservation = true;
        } else {
            indexes = _.map(this.state.filteredMarkers, (marker) => [marker.index, marker.frame, marker]);
        }
        let playlistIndexesWithMarkers = _.sortBy(
            _.filter(indexes, ([idx, _frame]) => idx > -1),
            ([idx, _frame]) => idx,
            ([idx, _frame]) => _frame,
        );

        let currentPlaylistIndex = this.state.index;
        let nextPlaylistIndex = _.findLast(
            playlistIndexesWithMarkers,
            ([idx, frame]) => idx < currentPlaylistIndex || (idx === currentPlaylistIndex && frame < this.state.subIndex),
        );
        if (!nextPlaylistIndex) {
            this.flashPrevious();
            nextPlaylistIndex = currentPlaylistIndex;
        }
        let marker = nextPlaylistIndex[2];
        this.props.dispatch(selectMarker(marker, isObservation));
        this.props.dispatch(
            routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame, false, false, null, null, false),
        );
    };

    nextExtent = (filteredObservations = null, selectedMarker = null) => {
        const observations = filterSessionObservations(
            this.props.sessionObservations.filter(
                (observation) => observation.class === this.props.selectedMarker.class && this.props.markerReviewFilters.includes(observation.review_status),
            ),
            this.props.observationFilters,
        );
        const obs = filteredObservations ? filteredObservations : observations;
        const observationWithFirstVideoKey = obs.map((obs) => {
            const observation = _.minBy(
                this.props.allMatchingObservations.filter((o) => o.observation_number === obs.observation_number),
                "extent_number",
            );
            return { ...obs, first_video_key: observation.video_key, first_observation_frame: observation.frame };
        });

        let next = findNextExtent(observationWithFirstVideoKey, this.props.imageKeys, selectedMarker ? selectedMarker : this.props.selectedMarker);
        if (!next) {
            return;
        }
        const marker = next[2];
        this.props.dispatch(selectMarker(marker, true));
        this.props.dispatch(
            routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame, false, false, null, null, false),
        );
    };

    prevExtent = () => {
        const observations = filterSessionObservations(
            this.props.sessionObservations.filter(
                (observation) => observation.class === this.props.selectedMarker.class && this.props.markerReviewFilters.includes(observation.review_status),
            ),
            this.props.observationFilters,
        );
        const observationWithFirstVideoKey = observations.map((obs) => {
            const observation = _.minBy(
                this.props.allMatchingObservations.filter((o) => o.observation_number === obs.observation_number),
                "extent_number",
            );
            return { ...obs, first_video_key: observation.video_key, first_observation_frame: observation.frame };
        });
        let prev = findPreviousExtent(observationWithFirstVideoKey, this.props.imageKeys, this.props.selectedMarker);
        if (!prev) {
            return;
        }
        const marker = prev[2];
        this.props.dispatch(selectMarker(marker, true));
        this.props.dispatch(
            routeSelected(this.props.sessionID, marker.video_key, undefined, undefined, undefined, marker.frame, false, false, null, null, false),
        );
    };

    checkForMarker = memoizeOne((newIndex, subIndex, markers, imageKeys, observations, selectedMarker, selected_observation_number) => {
        if (!imageKeys[newIndex]) {
            return false;
        }

        let newImageKey = imageKeys[newIndex][0];

        const observationsWithFrame = observations.filter((obs) => {
            return obs.frame === subIndex;
        });

        const observationToDisplay = _.find(observationsWithFrame, (observation) => {
            if (observation.video_key !== newImageKey) {
                // observation is for a different video key
                return false;
            }

            if (observation.class !== this.props.selectedTagCategory) {
                // observation is for a different type of detection
                return false;
            }

            if (
                selectedMarker.videoKey === observation.videoKey &&
                selected_observation_number &&
                observation.observation_number !== selected_observation_number
            ) {
                // N.B: There is a case where you can have multiple observations with the same videokey, frame and class.
                // This is when you have multiple overlapping cont observations which may be left/right of the frame.
                // This check is fine, but *ONLY* if props.selectedMarker has properly updated to the
                // right observation - i.e checkForMarker is triggered at the proper time
                return false;
            }

            return true;
        });

        const markerToDisplay = _.find(markers, (marker) => marker.video_key === newImageKey && marker.frame === subIndex);

        if (!markerToDisplay && !observationToDisplay) {
            this.setState({
                backupImage: "",
                loadedImage: null,
                displayingMarker: false,
                markerID: 0,
            });
            return false;
        }

        if (markerToDisplay) {
            this.cacheMarker(markerToDisplay);
            this.setState({
                displayingMarker: true,
            });
            return markerToDisplay;
        } else if (observationToDisplay) {
            let session = this.props.session;
            if ((observationToDisplay.frame >= 1 || observationToDisplay.frames.length > 0) && session) {
                this.cacheMarker(this.props.selectedMarker);
                this.setState({
                    displayingMarker: true,
                });
                return this.props.selectedMarker;
            } else {
                this.cacheMarker(observationToDisplay);
                this.setState({
                    displayingMarker: true,
                });
                return observationToDisplay;
            }
        }
    });

    checkForDetections = (index, frame) => {
        const detections = this.props.observationDetections;
        const observations = this.props.sessionObservations;
        const selectedMarker = this.props.selectedMarker;
        const imageKeys = this.props.imageKeys;

        if (!imageKeys[index]) {
            return false;
        }

        let newImageKey = imageKeys[index][0];
        let currentDetections = _.cloneDeep(detections);

        if (_.get(selectedMarker, "continuous")) {
            const currentObservations = observations.filter(
                (observation) => observation.class === selectedMarker.class && observation.observation_number === selectedMarker.observation_number,
            );

            currentDetections = detections.filter((detection) => {
                // if detection is the bbox shown for the extent then filter it out
                return (
                    detection.observation_number === selectedMarker.observation_number &&
                    !_.some(currentObservations, (obs) => obs.video_key === detection.video_key && obs.frame === detection.frame)
                );
            });
        }

        const detectionsToDisplay = currentDetections.filter((detection) => detection.video_key === newImageKey && detection.frame === frame);

        this.setState({
            detectionsToDisplay: detectionsToDisplay,
        });
    };

    formatTime = (sliderIndex) => {
        let playlistItem = this.props.imageKeys[sliderIndex];
        let displayTime = 0;
        let timeTooltip = "Unknown";
        if (playlistItem) {
            if (this.props.isStills) {
                if (playlistItem[3]) {
                    displayTime = playlistItem[3][2];
                }
            } else {
                if (playlistItem[1]) {
                    displayTime = playlistItem[1][2];
                }
            }
        }

        if (displayTime > 0) {
            const dpDate = new Date(displayTime * 1000);
            timeTooltip = convertToTimezone(dpDate, this.props.userConfig.convert_to_utc);
        }

        return timeTooltip;
    };

    regenerateMarkers = () => {
        if (this.props.imageKeys) {
            let markers = this.props.markers || [];
            let reviewFilters = this.props.markerReviewFilters;
            let selectedTagCategory = this.props.selectedTagCategory;
            let annotationTypes = this.props.annotationTypes;
            const thresholdFilters = this.props.thresholdFilters;

            const markerMap = {};
            markers = filterMarkers(
                markers,
                selectedTagCategory,
                reviewFilters,
                annotationTypes,
                thresholdFilters,
                false,
                this.props.defaultMarkersThreshold,
                this.props.markerConditionFilter,
            );

            if (markers.length < 50) {
                markers.forEach((marker) => {
                    let videoKey = marker.video_key;
                    let playlistIndex = keyLookup(videoKey, this.props.imageKeys);
                    if (playlistIndex === -1) {
                        return;
                    }

                    let displayText = marker.name;

                    let colour = "9b9b9b";

                    let annotationType = _.find(this.props.annotationTypes, function (ann) {
                        return ann.type === marker.name;
                    });
                    if (annotationType && annotationType.icon_colour) {
                        colour = annotationType.icon_colour.substring(1);
                    }

                    let snapshotURL;
                    if (marker.custom_thumbnail) {
                        snapshotURL = marker.custom_thumbnail;
                    } else {
                        snapshotURL = this.props.baseURL + videoKey + ".jpg";
                    }

                    let timeTooltip = this.formatTime(playlistIndex);
                    const markerContent = (
                        <div className={"Image-Slider-Marker-Tooltip"}>
                            <span>{timeTooltip}</span>
                            <span>{displayText}</span>
                            {/* <img style={{width: "180px"}} src={snapshotURL} crossOrigin={"anonymous"} alt="Snapshot"/> */}
                        </div>
                    );

                    markerMap[playlistIndex] = {
                        label: (
                            <div>
                                <Tippy
                                    arrow={true}
                                    placement={"top"}
                                    theme="aivr"
                                    content={markerContent}>
                                    <div
                                        className={"Image-Slider-Marker-Outer"}
                                        onClick={() => {
                                            this.selectIndex(playlistIndex, 1, true);
                                        }}>
                                        <div className={"Image-Slider-Marker marker-class-" + colour} />
                                    </div>
                                </Tippy>
                            </div>
                        ),
                        snapshotURL,
                        displayText,
                    };
                });
            }

            if (this.props.routeStartTime) {
                let videoKey = this.props.routeStartTime.videoKey;
                let playlistIndex = keyLookup(videoKey, this.props.imageKeys);
                markerMap[playlistIndex] = {
                    label: <div className={"Image-Slider-Export-Start-Marker"} />,
                    snapshotURL: this.props.routeStartTime.snapshot,
                    displayText: this.props.routeStartTime.text,
                };
            }
            if (this.props.routeEndTime) {
                console.log("Pushing end marker", this.props.routeEndTime);
                let videoKey = this.props.routeEndTime.videoKey;
                let playlistIndex = keyLookup(videoKey, this.props.imageKeys);
                markerMap[playlistIndex] = {
                    label: <div className={"Image-Slider-Export-End-Marker"} />,
                    snapshotURL: this.props.routeEndTime.snapshot,
                    displayText: this.props.routeEndTime.text,
                };
            }

            let observations = filterSessionObservations(
                this.props.sessionObservations.filter((obs) => reviewFilters.includes(obs.review_status)),
                this.props.observationFilters,
            );

            this.setState(
                {
                    markers: markerMap,
                    filteredMarkers: markers,
                    filteredObservations: observations,
                },
                () => {
                    // this.cacheMarkerImages(0);
                    this.selectIndex(this.state.index, this.state.subIndex, true);
                },
            );
        } else {
            this.setState({
                markers: {},
                filteredMarkers: [],
                filteredObservations: [],
            });
        }
    };

    reviewMarker = (review) => {
        const _this = this;
        let filteredObservations = filterSessionObservations(
            this.props.sessionObservations.filter((obs) => {
                return obs.class === this.props.selectedMarker.class && this.props.markerReviewFilters.includes(obs.review_status);
            }),
            this.props.observationFilters,
        );
        const _selectedMarker = _.cloneDeep(this.props.selectedMarker);
        if (this.props.selectedMarker.hasOwnProperty("observation_number")) {
            console.log("reviewing observations", review);
            this.props.dispatch(
                reviewSessionObservation(this.props.selectedMarker.id, this.props.selectedMarker.continuous, review, (success) => {
                    if (success) {
                        if (_this.hasNextMarker()) {
                            if (_selectedMarker.continuous) {
                                _this.nextExtent(filteredObservations, _selectedMarker);
                            } else {
                                _this.nextMarker();
                            }
                        }
                        _this.props.dispatch(
                            customAudit(
                                "detection_review_click",
                                { review: getReviewLabel(review), detection_type: _this.props.selectedMarker.name, target: "Image Player", source: "mouse" },
                                `detection_review_click button ${getReviewLabel(review)} clicked from Image Player for ${_this.props.selectedMarker.name} ID: ${_this.props.selectedMarker.id}, session ${_this.props.sessionID}`,
                            ),
                        );
                    }
                }),
            );
        } else {
            console.log("reviewing marker", review);
            this.props.dispatch(
                reviewMarker(_this.props.selectedMarker.id, review, _this.props.routeID, function () {
                    if (_this.hasNextMarker()) {
                        _this.nextMarker();
                    }
                    _this.props.dispatch(
                        customAudit(
                            "detection_review_click",
                            { review: getReviewLabel(review), detection_type: _this.props.selectedMarker.name, target: "Image Player", source: "mouse" },
                            `detection_review_click button ${getReviewLabel(review)} clicked from Image Player for ${_this.props.selectedMarker.name} ID: ${_this.props.selectedMarker.id}, session ${_this.props.sessionID}`,
                        ),
                    );
                }),
            );
        }
    };

    onSliderHover = (e) => {
        const x = e.clientX - this.state.sliderSize.left;
        const maxX = this.state.sliderSize.width;

        const ratio = x / maxX;
        const totalImages = this.props.imageKeys.length;
        const indexHovered = totalImages * ratio;
        const offsettedMouseScrubberX = Math.min(x, maxX - 100);

        this.setState({
            mouseScrubberX: x,
            indexHovered,
            offsettedMouseScrubberX,
        });
    };

    setBboxColor = (selectedMarker) => {
        if (selectedMarker) {
            if (selectedMarker.class === "Troughing") {
                const autoClassification = _.get(selectedMarker, "classifications.troughing_condition.auto", null);
                const userClassification = _.get(selectedMarker, "classifications.troughing_condition.user", null);

                if (!userClassification) {
                    if (autoClassification === "good") {
                        return "#47C66B";
                    }
                    if (autoClassification === "defect") {
                        return "red";
                    }
                    if (autoClassification === "invalid" || autoClassification === "no_result" || !autoClassification) {
                        return "white";
                    }
                } else {
                    if (userClassification === "good") {
                        return "#47C66B";
                    }
                    if (userClassification === "defect") {
                        return "red";
                    }
                    if (userClassification === "invalid") {
                        return "white";
                    }
                }

                return "yellow";
            } else if (selectedMarker.display_name === "Low Ballast") {
                return "red";
            }
        }
        return "#47C66B";
    };

    render() {
        let brightness = 0;
        let contrast = 0;
        if (this.props.currentStillImageAdjustments[this.props.sourceIndex] && this.props.currentStillImageAdjustments[this.props.sourceIndex].brightness) {
            brightness = this.props.currentStillImageAdjustments[this.props.sourceIndex].brightness * 0.6;
        }
        if (this.props.currentStillImageAdjustments[this.props.sourceIndex] && this.props.currentStillImageAdjustments[this.props.sourceIndex].contrast) {
            contrast = this.props.currentStillImageAdjustments[this.props.sourceIndex].contrast * 0.6;
        }

        let prevClasses = "Prev-Image Center-Content";
        let nextClasses = "Next-Image Center-Content";

        let prevButtonClasses = "";
        let nextButtonClasses = "";

        if (this.state.index < 1 && (!this.props.isStills || this.state.subIndex <= 1)) {
            prevButtonClasses = "Disabled";
        }

        if (this.state.flashNext) {
            nextClasses += " Flash";
        }
        if (this.state.flashPrevious) {
            prevClasses += " Flash";
        }

        if (this.state.index === this.props.imageKeys.length - 1) {
            if (this.props.isStills) {
                let targetKey = this.props.imageKeys[this.state.index];
                let frameCount = 0;
                if (targetKey) {
                    frameCount = targetKey[5];
                }
                if (frameCount) {
                    if (this.state.subIndex >= frameCount) {
                        nextButtonClasses = "Disabled";
                    }
                } else {
                    nextButtonClasses = "Disabled";
                }
            } else {
                nextButtonClasses = "Disabled";
            }
        }

        const selectedMarker = this.props.selectedMarker;
        let bboxOverlay = [];
        let bboxColor = this.setBboxColor(selectedMarker);
        let overlayTag = "";
        let detectionBbox = _.get(selectedMarker, "bbox");
        if (detectionBbox && this.state.displayingMarker && selectedMarker.hasOwnProperty("observation_number")) {
            detectionBbox = JSON.parse(detectionBbox);
            const classifiedBbox = processTroughingBbox(detectionBbox, selectedMarker);
            bboxOverlay.push(classifiedBbox);
            const tag = _.get(selectedMarker, "class")
                .replace(/\bobservations\b/gi, "")
                .trim();
            const userCondition = _.get(selectedMarker, "classifications.troughing_condition.user", null);

            // If user has overridden auto condition then not displaying confidence level
            if (!userCondition) {
                const autoCondition = _.get(selectedMarker, "classifications.troughing_condition.auto");
                const trustLevel = _.get(selectedMarker, "auto_data_full", {});
                overlayTag = `${tag}${autoCondition ? ` - ${autoCondition}` : ""}${trustLevel.hasOwnProperty(autoCondition) ? `: ${Math.floor(trustLevel[autoCondition] * 100)}%` : ""}`;
            } else {
                overlayTag = `${tag}${userCondition ? ` - ${userCondition}` : ""}`;
            }
        } else if (this.state.detectionsToDisplay.length) {
            bboxOverlay = [];
            _.forEach(this.state.detectionsToDisplay, (detection) => {
                const bbox = JSON.parse(detection.bbox);
                bboxOverlay.push(processTroughingBbox(bbox, selectedMarker));
            });
            let parentExtent = _.find(
                this.props.sessionObservations,
                (obs) =>
                    obs.observation_number === this.state.detectionsToDisplay[0].observation_number &&
                    obs.extent_number === this.state.detectionsToDisplay[0].extent_number,
            );
            bboxColor = this.setBboxColor(parentExtent);
            overlayTag = "";
        }

        const reviewPermissions =
            !!_.get(this.props.data_pool_permissions, [_.get(this.props.session, ["data_pool"], false), "review"], false) ||
            this.props.userDetails.userConfig.super_admin;

        return (
            <div className={"Prev-Next-Image-Container"}>
                <div className="MainHUDContainer">
                    <InformationOverlayComponent />

                    {this.state.displayingMarker && !!selectedMarker && !this.props.shareLink && reviewPermissions && (
                        <div className="DetectionsWrapper">
                            <div className={this.state.detectionsInterfaceOpen ? "DetectionsContainer active" : "DetectionsContainer"}>
                                <div className="markerReviewDiv">
                                    <div style={{ padding: ".5rem", display: "flex", flexDirection: "column", gap: ".2rem" }}>
                                        <div className="markerReviewTitleContainer">
                                            <p className="markerReviewTitle bold">Detection:</p>
                                            <p className="markerReviewTitle">
                                                {selectedMarker.hasOwnProperty("name") ? selectedMarker.name : selectedMarker.class}
                                            </p>
                                        </div>
                                        {!selectedMarker.review_status ? (
                                            <div className="markerReviewButtonContainer">
                                                <div className="markerButtons">
                                                    <button
                                                        className="markerReviewButton reject"
                                                        onClick={() => this.reviewMarker(3)}>
                                                        Reject (R)
                                                    </button>
                                                    <button
                                                        className="markerReviewButton accept"
                                                        onClick={() => this.reviewMarker(2)}>
                                                        Verify & Hide (H)
                                                    </button>
                                                    <button
                                                        className="markerReviewButton accept"
                                                        onClick={() => this.reviewMarker(1)}>
                                                        Verify (V)
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <Select
                                                value={selectedMarker.review_status}
                                                onChange={this.reviewMarker}
                                                style={{ width: "50%" }}
                                                getPopupContainer={(node) => node.parentNode}>
                                                <Select.Option value={3}>Rejected</Select.Option>
                                                <Select.Option value={2}>Verified & Hidden</Select.Option>
                                                <Select.Option value={1}>Verified</Select.Option>
                                            </Select>
                                        )}
                                        <div className="markerText">{<p>Your reviews help improve future detections</p>}</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="DetectionToggle"
                                onClick={() => {
                                    this.toggleDetectionsInterface();
                                }}>
                                {this.state.detectionsInterfaceOpen ? "Hide detections" : "Show detections"}
                                <FontAwesomeIcon
                                    icon={this.state.detectionsInterfaceOpen ? faCaretUp : faCaretDown}
                                    size={"2x"}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {this.props.playerControls && !this.state.playing && (
                    <>
                        <div className="stillsHistoryContainer">
                            <RouteHistoryComponent />
                        </div>
                        <div className={prevClasses}>
                            <Tippy
                                theme={"aivrlight"}
                                placement={"bottom"}
                                arrow={false}
                                content={"Step backward one frame"}>
                                <div className={prevButtonClasses}>
                                    <img
                                        src={OneFrameIcon}
                                        onClick={this.prevFrame}
                                        crossOrigin={"anonymous"}
                                    />
                                </div>
                            </Tippy>
                            <Tippy
                                theme={"aivrlight"}
                                placement={"bottom"}
                                arrow={false}
                                content={"Step backward one second"}>
                                <div className={prevButtonClasses}>
                                    <BackwardFilled onClick={this.prevSecond} />
                                    <p>
                                        1<span>s</span>
                                    </p>
                                </div>
                            </Tippy>
                            <Tippy
                                theme={"aivrlight"}
                                placement={"bottom"}
                                arrow={false}
                                content={"Step backward ten seconds"}>
                                <div className={prevButtonClasses}>
                                    <BackwardFilled onClick={this.prevTenSeconds} />
                                    <p>
                                        10<span>s</span>
                                    </p>
                                </div>
                            </Tippy>
                            <Tippy
                                theme={"aivrlight"}
                                placement={"bottom"}
                                arrow={false}
                                content={"Step backward one minute"}>
                                <div className={prevButtonClasses}>
                                    <BackwardFilled onClick={this.prevMinute} />
                                    <p>
                                        60<span>s</span>
                                    </p>
                                </div>
                            </Tippy>
                            <Tippy
                                theme={"aivrlight"}
                                placement={"bottom"}
                                arrow={false}
                                content={"Step backward to the previous detection"}>
                                <div className={this.hasPreviousMarker() ? "" : "Disabled"}>
                                    <LeftCircleOutlined onClick={this.props.selectedMarker.continuous ? this.prevExtent : this.previousMarker} />
                                </div>
                            </Tippy>
                        </div>
                    </>
                )}
                <div className="Image-Box">
                    {this.props.fullscreenID ? (
                        <FullScreenCapable
                            showExpand={true}
                            showCollapse={true}
                            fullscreenID={this.props.fullscreenID}
                            ref={this.fullscreenComponent}
                            extraStyle={{ height: "100%" }}>
                            {this.state.loading ? (
                                <div className="Image-Loading-Overlay">
                                    <OBCSpinner colorScheme={"mono"} />
                                </div>
                            ) : null}
                            <ZoomableImage
                                filters={{ brightness, contrast }}
                                imgSrc={
                                    (!this.props.showOverlayContent || !this.state.displayingMarker) && this.state.rawImage
                                        ? this.state.rawImage
                                        : this.state.loadedImage
                                }
                                zoom={1}
                                updateZoom={null}
                                overlayBoxes={this.props.showOverlayContent && bboxOverlay ? bboxOverlay : []}
                                bboxColor={bboxColor}
                                tag={overlayTag}
                            />
                        </FullScreenCapable>
                    ) : (
                        <>
                            {this.state.loading ||
                            (this.state.absoluteImageLoading && (this.props.displayPickTemperature || this.props.displayAbsoluteThermalImage)) ? (
                                <div className="Image-Loading-Overlay">
                                    <OBCSpinner colorScheme={"mono"} />
                                </div>
                            ) : null}
                            {this.props.isThermalStream && (this.props.displayPickTemperature || this.props.displayAbsoluteThermalImage) && (
                                <AbsoluteColourImageDisplay
                                    thermalImageSrc={this.state.rawImage}
                                    displayRawImage={this.props.displayAbsoluteThermalImage}
                                    sessionKey={this.props.session.uuid}
                                    deviceKey={this.props.session.device_uuid}
                                    videoKey={this.currentVideoKey()}
                                    setLoading={(val) => {
                                        this.setState({ absoluteImageLoading: val });
                                    }}
                                    loading={this.state.absoluteImageLoading}
                                    frame={this.state.subIndex}
                                />
                            )}
                            {(!this.props.isThermalStream ||
                                (this.props.isThermalStream && !this.props.displayPickTemperature && !this.props.displayAbsoluteThermalImage)) && (
                                <ZoomableImage
                                    filters={{ brightness, contrast }}
                                    imgSrc={
                                        (!this.props.showOverlayContent || !this.state.displayingMarker) && this.state.rawImage
                                            ? this.state.rawImage
                                            : this.state.loadedImage
                                    }
                                    zoom={1}
                                    updateZoom={null}
                                    overlayBoxes={this.props.showOverlayContent && bboxOverlay ? bboxOverlay : []}
                                    bboxColor={bboxColor}
                                    tag={overlayTag}
                                />
                            )}
                        </>
                    )}
                </div>
                {this.props.children}
                {this.props.playerControls && !this.state.playing && (
                    <div className={nextClasses}>
                        <Tippy
                            theme={"aivrlight"}
                            placement={"bottom"}
                            arrow={false}
                            content={"Step forward one frame"}>
                            <div className={nextButtonClasses}>
                                <img
                                    src={OneFrameIcon}
                                    onClick={this.nextFrame}
                                    crossOrigin={"anonymous"}
                                />
                            </div>
                        </Tippy>
                        <Tippy
                            theme={"aivrlight"}
                            placement={"bottom"}
                            arrow={false}
                            content={"Step forward one second"}>
                            <div className={nextButtonClasses}>
                                <ForwardOutlined onClick={this.nextSecond} />
                                <p>
                                    1<span>s</span>
                                </p>
                            </div>
                        </Tippy>
                        <Tippy
                            theme={"aivrlight"}
                            placement={"bottom"}
                            arrow={false}
                            content={"Step forward ten seconds"}>
                            <div className={nextButtonClasses}>
                                <ForwardOutlined onClick={this.nextTenSeconds} />
                                <p>
                                    10<span>s</span>
                                </p>
                            </div>
                        </Tippy>
                        <Tippy
                            theme={"aivrlight"}
                            placement={"bottom"}
                            arrow={false}
                            content={"Step forward one minute"}>
                            <div className={nextButtonClasses}>
                                <ForwardOutlined onClick={this.nextMinute} />
                                <p>
                                    60<span>s</span>
                                </p>
                            </div>
                        </Tippy>
                        <Tippy
                            theme={"aivrlight"}
                            placement={"bottom"}
                            arrow={false}
                            content={"Step forward to the next detection"}>
                            <div className={this.hasNextMarker() ? "" : "Disabled"}>
                                <RightCircleOutlined onClick={() => (this.props.selectedMarker.continuous ? this.nextExtent() : this.nextMarker())} />
                            </div>
                        </Tippy>
                    </div>
                )}
                {this.props.playerControls && (
                    <div className="Image-Step-And-Fullscreen">
                        <div className="Image-Icon-Wrapper">
                            {this.state.playing ? (
                                <PauseOutlined onClick={() => this.togglePlaying()} />
                            ) : (
                                <CaretRightOutlined onClick={() => this.togglePlaying()} />
                            )}
                        </div>
                        <div className="Image-Time-Display">{this.formatTime(this.state.index)}</div>
                        <Measure
                            bounds
                            onResize={(contentRect) => {
                                this.setState({ sliderSize: contentRect.bounds });
                            }}>
                            {({ measureRef }) => (
                                <div
                                    onMouseEnter={() => this.setState({ mouseInScrubber: true })}
                                    onMouseLeave={() => this.setState({ mouseInScrubber: false })}
                                    onMouseMove={this.onSliderHover}
                                    className={"Image-Step-Slider"}
                                    ref={measureRef}>
                                    <Slider
                                        min={0}
                                        max={this.props.imageKeys.length - 1}
                                        value={this.state.index}
                                        marks={this.state.markers}
                                        onChange={this.imageIndexChanged}
                                        tipFormatter={null}
                                        className="Slider"
                                    />
                                    {this.state.mouseInScrubber && (
                                        <SliderPreview
                                            allPreviewImages={this.imagePreviewCache}
                                            previewImageKeys={this.imagePreviewKeys}
                                            index={this.state.indexHovered}
                                            offsettedX={this.state.offsettedMouseScrubberX}
                                            x={this.state.mouseScrubberX}
                                        />
                                    )}
                                </div>
                            )}
                        </Measure>
                    </div>
                )}
            </div>
        );
    }
}

const filteredMarkers = memoizeOne((markersPerSession, routeID, selectedTagCategory, imageKeys, observations) => {
    const markers = _.filter(markersPerSession[routeID], (item) => item.name === selectedTagCategory).map((marker) => {
        return {
            ...marker,
            index: keyLookup(marker.video_key, imageKeys),
        };
    });
    return filterMarkersByObservations(markers, observations);
});

const mapStateToProps = (
    {
        playlist,
        markers,
        selectedTagCategory,
        userAnnotationTypes,
        shareLink,
        gpsTimeOffsets,
        viewSnapshot,
        markup,
        userDetails,
        markerReviewFilters,
        sessions,
        dashboards,
        widgetData,
        dashboardWidgetKey,
        snappedRoute,
        markerThresholdFilters,
        defaultMarkersThresholds,
        markerConditionFilter,
        sessionObservations,
        observationDetections,
        observationFilters,
        csrfToken,
    },
    ownProps,
) => {
    let playlistPositionData = playlist.position;
    const sidekickData = _.get(widgetData.DASHBOARD, [dashboardWidgetKey, "state"], null);
    let routeID = playlist.data.routeID;
    let isWidget = false;

    if (window.location.pathname.startsWith("/widget")) {
        playlistPositionData = sidekickData.position;
        routeID = sidekickData.routeID;
        isWidget = true;
    }
    const isSlave = ownProps.isSlave;
    const sourceIndex = isSlave ? ownProps.sourceIndex : playlistPositionData.sourceIndex;
    const slaveTimestamp = ownProps.newAbsoluteTimestamp;

    const session = sessions[playlist.data.routeID];

    const isThermalStream = _.includes(_.get(session, ["stream_info", sourceIndex, "type"], ""), "Thermal");

    let baseURL;
    let imageKeys;

    let offsets = [];

    if (playlistPositionData.isStills || isWidget) {
        baseURL = _.get(playlist.data, ["mpdURLs", `snapshots`]);
        imageKeys = _.get(playlist.data, ["video", sourceIndex], []);
        if (routeID === gpsTimeOffsets.sessionID) {
            offsets = _.get(gpsTimeOffsets.offsets, sourceIndex, []);
        }
    } else {
        baseURL = _.get(playlist.data, ["mpdURLs", "imageBase"]);
        imageKeys = playlist.data.image || [];
    }

    const dashboardID = userDetails.dashboardAccessID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);

    const selectedMarker = _.get(markers, "selectedMarker") || {};

    return {
        baseURL,
        routeID,
        imageKeys,
        offsets,
        markers: filteredMarkers(markers.perSession, playlist.data.routeID, selectedTagCategory, imageKeys, sessionObservations),
        selectedTagCategory: selectedTagCategory,
        annotationTypes: userAnnotationTypes,
        position: playlistPositionData.requestedIndex || 0,
        requestedTimeOffset: playlistPositionData.requestedTimeOffset || 0,
        requestTS: playlistPositionData.requestTimestamp,
        isEnhanced: playlistPositionData.isEnhanced,
        isStills: playlistPositionData.isStills,
        isThermalStream,
        sourceIndex: sourceIndex,
        use_snapped: snappedRoute || false,
        shareLink,
        viewSnapshot,
        toolMode: markup.tool_mode,
        userConfig: userDetails.userConfig,
        markerReviewFilters,
        data_pool_permissions: _.get(currentDashboard, ["data_pool_permissions"], {}),
        userDetails: userDetails,
        session,
        slaveTimestamp,
        isSlave,
        thresholdFilters: markerThresholdFilters,
        currentStillImageAdjustments: playlist.stillImageAdjustments,
        defaultMarkersThreshold: defaultMarkersThresholds[playlist.data.routeID],
        markerConditionFilter: markerConditionFilter,
        sessionObservations,
        selectedMarker,
        observationDetections,
        observationFilters,
        sessionID: playlist.data.routeID,
        allMatchingObservations: _.filter(sessionObservations, (item) => item.class === markers.selectedMarker?.class),
        csrfToken,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ImagePlayer);
