import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { calculateRouteCoordinatesForLocation, convertSpeed } from "../../util/Geometry";
import { useSelector, useDispatch } from "react-redux";
import _, { set } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
    faTimes,
    faMapPin,
    faSearchPlus,
    faWindowRestore,
    faCog,
    faCamera,
    faExchange,
    faArrowsAltV,
    faBug,
    faBox,
    faArrowFromBottom,
} from "@fortawesome/pro-solid-svg-icons";

import { useHistory } from "react-router-dom";
import RailInspectSourceModal from "./RailInspectSourceModal";
import {
    getDeviceGroupInfo,
    routeSelected,
    toggleDataView,
    toggleSettingsOpen,
    toggleDetectionsOpen,
    flipInspectionRails,
    toggleInspectionAnnotationMode,
    toggleInspectionSnapshot,
    getFavouriteCategories,
    setDisplayExtraLayers,
    targetResource,
    archiveInspectionSession,
    setAutoScrollActive,
} from "../../../redux/actions/index";
import { faBars, faObjectUngroup, faSearch, faClipboard } from "@fortawesome/free-solid-svg-icons";
import FindLocationPopup from "../../map/FindLocationPopup";
import { Tooltip, Checkbox, notification, Progress, Popover } from "antd";
import { binarySearch, getAbsoluteTimestamp, getInterpolatedPosition } from "../../util/PlaylistUtils";
import Tippy from "@tippyjs/react";
import { LazyTippy } from "../../util/LazyTooltips";
import { sticky } from "tippy.js";
import ELRMileAndChain from "../../util/ELRMileAndChain";
import SidekickPopoutContent from "components/SidekickPopoutContent";
import memoize from "memoize-one";
import MetadataFeed from "../../route/MetadataFeed";
import RailCompareModal from "./RailCompareModal";
import FavoriteButton from "components/session/FavoriteButton";
import UnitSelectionModal from "components/UnitSelectionModal";

import { ReactComponent as DetailRailIcon } from "./RailButtons/DetailRailIcon.svg";
import OBCSpinner from "components/util/OBC";
import { faExclamation, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { convertToTimezone } from "components/util/TimezoneUtils";
import moment from "moment";
import ArchiveInspectionSessionModal from "./ArchiveInspectionSessionModal";
import RestoreArchivedInspectionSessionModal from "./RestoreArchivedInspectionSessionModal";
import RailInspectHeaderWidget from "./RailInspectHeaderWidget";

const selectedRailImageIndexSelector = (state) => state.railInspection.selectedRailInspectionImage.index;
const selectedRailImageTimestampSelector = (state) => state.railInspection.selectedRailInspectionImage.timestamp;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const userRoleSelector = (state) => state.userDetails.userConfig.super_admin;
const userDetailsSelector = (state) => state.userDetails;
const dashboardsSelector = (state) => state.dashboards;
const viewsSelector = (state) => state.views;
const allSessionsSelector = (state) => state.sessions;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const timestampSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
};
const detailViewOpenSelector = (state) => state.railInspection.detailViewOpen;
const settingsOpenSelector = (state) => state.railInspection.settingsOpen;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const sidekickTypesSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
};
const imageKeysSelector = (state) => _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex], []);
const snappedRouteSelector = (state) => state.snappedRoute;
const videolessTimestampSelector = (state) => {
    const currentIndex = selectedRailImageIndexSelector(state);
    const railImages = state.railInspection.railInspectionImages.data;

    let timestamp = 0;
    if (railImages[currentIndex]) {
        const currentImage = railImages[currentIndex];
        timestamp = currentImage.timestamp;
    }
    return timestamp;
};
const deviceGroupInfoSelector = (state) => state.railInspection.deviceGroupInfo;
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const detectionsWindowOpenSelector = (state) => state.railInspection.detections.windowOpen;
const userConfigSelector = (state) => state.userDetails.userConfig;
const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], []);
const tachoDataSelector = (state) => _.get(state.routeMetadata, ["TACHO_DATA", state.playlist.data.routeID], []);
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const annotateModeEnabledSelector = (state) => state.railInspection.annotations.modeEnabled;
const inspectionSnapshotOpenSelector = (state) => state.railInspection.snapshot.open;
const displayExtraLayersSelector = (state) => state.railInspection.displayExtraLayers;
const autoScrollActiveSelector = (state) => state.railInspection.autoScrollActive;

const isFavouritedSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    if (state.sessions[routeID]) {
        return state.sessions[routeID].favourite;
    }
};
const railDataPercentageCompleteSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.percentageComplete;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const someMainRailInspectionImagesMissingSelector = (state) => state.railInspection.someMainRailInspectionImagesMissing;
const comparableSessionsSelector = (state) => state.railInspection.comparableSessions;
const foundRouteCoordinatesSelector = (state) => state.locationSearch.results;

const RailInspectHeader = ({
    match,
    toggleRestoreArchivedInspectionSessionModal,
    restoreArchivedInspectionSessionModalVisible,
    toggleArchiveInspectionSessionModal,
    archiveInspectionSessionModalVisible,
    toggleMarkerTool,
    markerToolActive,
    videoless,
    toggleFindModalVisibility,
    findModalVisible,
    setFavouritePopoverOpen,
    loadSecondarySession,
    setSecondaryAlignment,
    secondaryRailImages,
    secondaryRailImageStatus,
    secondaryRailConfig,
    clearSecondarySession,
    toggleManualAlignmentTool,
    manualAlignmentToolActive,
    setSecondaryAlignmentAdjustment,
    showReportProblemDialog,
    setCurrentHeaderHeight,
    onExitRailInspection,
}) => {
    const [sourceModalOpen, setSourceModalOpen] = useState(false);
    const [compareModalOpen, setCompareModalOpen] = useState(false);
    const railInspectionHeader = useRef();
    const [dataLoaded, setDataLoaded] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const userIsSuperAdmin = useSelector(userRoleSelector);

    const selectedRailImageIndex = useSelector(selectedRailImageIndexSelector);
    const selectedRailImageTimestamp = useSelector(selectedRailImageTimestampSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const userDetails = useSelector(userDetailsSelector);
    const dashboards = useSelector(dashboardsSelector);
    const views = useSelector(viewsSelector);
    const session = useSelector(sessionSelector);
    const sessionID = session.id || parseInt(match.params.sessionID);
    const autoScrollActive = useSelector(autoScrollActiveSelector);

    const timestamp = useSelector(timestampSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);

    const settingsOpen = useSelector(settingsOpenSelector);
    const wsToken = useSelector(wsTokenSelector);
    const sidekickTypes = useSelector(sidekickTypesSelector);
    const imageKeys = useSelector(imageKeysSelector);
    const snappedRoute = useSelector(snappedRouteSelector);
    const railsFlippedValue = useSelector(railsFlippedSelector);
    const detectionsWindowOpen = useSelector(detectionsWindowOpenSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const displayExtraLayers = useSelector(displayExtraLayersSelector);
    const percentageComplete = useSelector(railDataPercentageCompleteSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const someMainRailInspectionImagesMissing = useSelector(someMainRailInspectionImagesMissingSelector);
    const comparableSessions = useSelector(comparableSessionsSelector);
    const allSessions = useSelector(allSessionsSelector);
    const foundRouteCoordinates = useSelector(foundRouteCoordinatesSelector);
    const [selectedComparingSessionID, setSelectedComparingSessionID] = useState(null);

    const speedMetadata = useSelector(speedAndBearingSelector);
    const tachoMetadata = useSelector(tachoDataSelector);
    const userConfig = useSelector(userConfigSelector);
    const inspectionSnapshotOpen = useSelector(inspectionSnapshotOpenSelector);

    const annotateModeEnabled = useSelector(annotateModeEnabledSelector);
    const dashboardID = userDetails.dashboardAccessID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const compareToolEnabled = _.get(currentDashboard, ["config", "rail_inspection_compare_tool_enabled"], false);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const [unitSelectionModalVisible, setUnitSelectionModalVisible] = useState(false);
    const [moreImagesAvailable, setMoreImagesAvailable] = useState(false);

    const handleArchiveInspectionSession = (status) => {
        dispatch(archiveInspectionSession(sessionID, status));
        session["inspection_archive_status"] = status;
    };

    useEffect(() => {
        if (percentageComplete === 100) {
            setTimeout(() => {
                setDataLoaded(true);
            }, 1000);
        }
    }, [percentageComplete]);

    useEffect(() => {
        if (sessionID) {
            dispatch(getDeviceGroupInfo(sessionID));
        }
    }, [dispatch, sessionID]);

    useEffect(() => {
        dispatch(getFavouriteCategories());
    }, [dispatch]);

    useEffect(() => {
        if (someMainRailInspectionImagesMissing) {
            setMoreImagesAvailable(true);
        }
    }, [someMainRailInspectionImagesMissing]);

    const updateHeaderHeight = () => {
        setCurrentHeaderHeight(_.get(railInspectionHeader, ["current", "clientHeight"], 70)); // default to 70 (unwrapped header in case)
    };

    useEffect(() => {
        // add event listener on window resize
        window.addEventListener("resize", updateHeaderHeight);
    }, []);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject; //Find first device in data - will need changing to support multiple devices
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const railImage = useMemo(() => {
        if (railImages && railImages.length) {
            return railImages[selectedRailImageIndex];
        }
    }, [railImages, selectedRailImageIndex]);

    const positionCoords = useMemo(() => {
        let retVal = [];

        if (railImages?.length && selectedRailImageIndex && imageKeys?.length && railImages[selectedRailImageIndex]) {
            const selectedImage = railImages[selectedRailImageIndex];
            if (selectedImage.lat_lng && selectedImage.lat_lng.length) {
                retVal = selectedImage.lat_lng;
            } else {
                let timestamp = selectedImage.timestamp;

                let position = getInterpolatedPosition(timestamp / 1000, imageKeys, snappedRoute);
                if (position && position.length) {
                    retVal = position;
                }
            }
        }
        return retVal;
    }, [railImages, selectedRailImageIndex, imageKeys, snappedRoute]);

    const calculatedELR = useMemo(() => {
        let timestampToUse = timestamp;

        if (videoless) {
            timestampToUse = videolessTimestamp / 1000;
        }
        if (timestampToUse && routeLocationData) {
            const calculatedELR = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);
            if (calculatedELR) {
                return {
                    string: calculatedELR.to_string(elrUnits, datumOffsets),
                    route: calculatedELR.route,
                    track: calculatedELR.track,
                    position: calculatedELR.position,
                };
            }
        }
    }, [timestamp, routeLocationData, elrUnits, datumOffsets, videoless, videolessTimestamp, routeSystemID]);

    const mwvData = useMemo(() => {
        if (railImages && railImage) {
            let mwv = railImage["mwv"];
            if (mwv && mwv.elr && mwv.mile && mwv.trid && mwv.yard) {
                mwv = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: mwv.elr,
                        MILE: mwv.mile,
                        TRACK: mwv.trid,
                        YARDS: mwv.yard,
                    },
                    datumOffsets,
                    true,
                );
                return {
                    string: mwv.to_string(elrUnits, datumOffsets, true),
                    route: mwv.route,
                    track: mwv.track,
                    position: mwv.position,
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }, [railImage, railImages, datumOffsets, elrUnits]);

    const toggleSourceModal = useCallback(() => {
        setSourceModalOpen(!sourceModalOpen);
    }, [sourceModalOpen]);

    const toggleCompareModal = useCallback(() => {
        setCompareModalOpen(!compareModalOpen);
    }, [compareModalOpen]);

    const openCompareSession = (sessionID) => {
        console.log("debug selecting session ID'", sessionID);
        setSelectedComparingSessionID(sessionID);
        loadSecondarySession(sessionID);
        setCompareModalOpen(false);
    };

    const clearSelectedSecondarySession = (value) => {
        setSelectedComparingSessionID(null);
        clearSecondarySession(value);
    };

    const goHome = useCallback(() => {
        dispatch(setDisplayExtraLayers(false));
        dispatch(toggleInspectionAnnotationMode(false));
        dispatch(targetResource(null));
        history.push("/", { sessionID });
        onExitRailInspection();
    }, [sessionID, history, dispatch, onExitRailInspection]);

    const exitButton = useMemo(() => {
        return (
            <>
                <button
                    onClick={goHome}
                    className="inspection-default-button close">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </>
        );
    }, [goHome]);

    const addSourceButton = useMemo(() => {
        return (
            <>
                <button
                    className={"inspection-default-button" + (manualAlignmentToolActive || autoScrollActive ? " Disabled" : "")}
                    disabled={manualAlignmentToolActive || autoScrollActive}
                    onClick={toggleSourceModal}>
                    <span>Add Source</span> <FontAwesomeIcon icon={faPlusCircle} />
                </button>
            </>
        );
    }, [manualAlignmentToolActive, toggleSourceModal, autoScrollActive]);

    const archiveInspectionSessionButton = useMemo(() => {
        return (
            <>
                <button
                    className={`inspection-default-button ${autoScrollActive ? "Disabled" : ""}`}
                    onClick={toggleArchiveInspectionSessionModal}>
                    <div className="TabSectionIcon">
                        <FontAwesomeIcon icon={faBox} />
                    </div>
                    Archive Session
                </button>
            </>
        );
    }, [toggleArchiveInspectionSessionModal, autoScrollActive]);

    const restoreArchivedInspectionSessionButton = useMemo(() => {
        return (
            <>
                <button
                    className={`inspection-default-button ${autoScrollActive ? "Disabled" : ""}`}
                    onClick={toggleRestoreArchivedInspectionSessionModal}>
                    <div className="TabSectionIcon">
                        <FontAwesomeIcon icon={faBox} />
                    </div>
                    Restore Session
                </button>
            </>
        );
    }, [toggleRestoreArchivedInspectionSessionModal, autoScrollActive]);

    const compareButton = useMemo(() => {
        if (secondaryRailImageStatus.loaded || !secondaryRailImageStatus.sessionID) {
            return (
                <>
                    <button
                        disabled={manualAlignmentToolActive || autoScrollActive}
                        className={"inspection-default-button" + (manualAlignmentToolActive || autoScrollActive ? " Disabled" : "")}
                        onClick={toggleCompareModal}>
                        <div className="TabSectionIcon">
                            <FontAwesomeIcon icon={faExchange} />
                        </div>
                        Compare Sessions
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button className="inspection-default-button Disabled">
                        <span>Loading ({secondaryRailImageStatus.percentageComplete.toFixed(0)}%)</span>
                    </button>
                </>
            );
        }
    }, [toggleCompareModal, secondaryRailImageStatus, manualAlignmentToolActive, autoScrollActive]);

    const autoAlign = useCallback(() => {
        //TODO: This should take the current ELR track, position etc and find the index of that location in the secondary
        // rail data (which might require fetching the session information)
        // Once done, the secondary alignment offset can be set to the found array index minus the currently selected array index,
        // which should cause the two rail inspection image with the same ELR to be aligned at the current index.
        // A user control should then allow fine-grained adjustment.

        console.log("Auto align clicked");

        // if inspection don't have images, clear secondary rail data and display error message to the user.
        if (!_.get(secondaryRailImages, "data", []).length) {
            clearSecondarySession(true);
            return;
        }

        let timestampToUse = timestamp;
        if (videoless) {
            timestampToUse = videolessTimestamp / 1000;
        }
        let nextELR = calculateRouteCoordinatesForLocation(timestampToUse + 1, routeLocationData, routeSystemID);

        const travellingUpstream = nextELR.position > calculatedELR.position;
        const elr = mwvData ? mwvData.route : calculatedELR.route;
        const position = mwvData ? mwvData.position : calculatedELR.position;
        const trackID = mwvData ? mwvData.track : calculatedELR.track;

        console.log("Initial location: ", elr, position, trackID, travellingUpstream);

        const hasMwvData = _.get(secondaryRailImages, ["data", 0, "mwv", "elr"]);
        let imageLocations = _.clone(secondaryRailImages.imageLocations);

        // if compering session has mwv data use it instead.
        if (hasMwvData) {
            let newLocations = {};
            _.map(secondaryRailImages.data, (data) => {
                if (data.mwv) {
                    newLocations[data.timestamp] = [data.mwv.elr, parseInt(data.mwv.mile) * 80 + parseFloat(data.mwv.yard) / 22, data.mwv.trid];
                }
            });

            imageLocations = newLocations;
        }

        const timestamps = Object.keys(imageLocations);
        timestamps.sort();

        let beforeTimestamp = null;
        let afterTimestamp = null;

        let beforeLocation = null;
        let afterLocation = null;

        const alignment_offset = _.get(secondaryRailConfig, "alignmentOffset", 0);

        for (let i = 1; i < timestamps.length; i++) {
            const timestamp = timestamps[i];
            const lastTimestamp = timestamps[i - 1];

            const location = imageLocations[timestamp];
            const lastLocation = imageLocations[lastTimestamp];

            if (location[0] === elr && location[2] === trackID && lastLocation[0] === elr && lastLocation[2] === trackID) {
                const secondaryTravellingUpstream = location[1] > lastLocation[1];
                const invertAlignmentOffset = secondaryTravellingUpstream !== secondaryRailConfig.imageryIsBackward;
                const position_offset = (alignment_offset / ELRMileAndChain.meters_per_chain) * (invertAlignmentOffset ? -1 : 1);
                const lastPosition = lastLocation[1] + position_offset;
                const currentPosition = location[1] + position_offset;
                if ((lastPosition > position && currentPosition <= position) || (lastPosition < position && currentPosition >= position)) {
                    beforeTimestamp = parseInt(lastTimestamp);
                    afterTimestamp = parseInt(timestamp);

                    beforeLocation = lastLocation;
                    afterLocation = location;
                    break;
                }
            }
        }

        if (!beforeLocation || !afterLocation) {
            notification.warning({
                message: "Error finding matching position in compared session",
            });
            return;
        } else {
            console.log(
                "Found timestamps where secondary is at or overlapping current position: ",
                beforeTimestamp,
                beforeLocation,
                afterTimestamp,
                afterLocation,
            );
        }

        const secondaryTravellingUpstream = afterLocation[1] > beforeLocation[1];
        const invertAlignmentOffset = secondaryTravellingUpstream !== secondaryRailConfig.imageryIsBackward;

        console.log("Is secondary travelling upstream: ", secondaryTravellingUpstream);

        const position_offset = (alignment_offset / ELRMileAndChain.meters_per_chain) * (invertAlignmentOffset ? -1 : 1);
        const beforePosition = beforeLocation[1] + position_offset;
        const afterPosition = afterLocation[1] + position_offset;

        const posDifference = position - beforePosition;
        const interpolatePercent = posDifference / (afterPosition - beforePosition);
        const foundTimestamp = beforeTimestamp + (afterTimestamp - beforeTimestamp) * interpolatePercent;
        console.log("Interpolated timestamp in secondary rail imagery is ", foundTimestamp, interpolatePercent);

        let railIndex = binarySearch(parseInt(foundTimestamp), secondaryRailImages.data, (image) => image.timestamp);
        let railImageTimestamp = secondaryRailImages.data[railIndex].timestamp;
        let nextRailImageTimestamp = secondaryRailImages.data[railIndex + 1].timestamp;
        console.log("Image index matching interpolated timestamp is ", railIndex, "with timestamp", railImageTimestamp, nextRailImageTimestamp);
        const timestampInterpolation = Math.round((10 * (foundTimestamp - railImageTimestamp)) / (nextRailImageTimestamp - railImageTimestamp)) / 10;
        console.log("Currently selected rail image index is ", selectedRailImageIndex);

        let secondaryAlignment = {
            adjustment: timestampInterpolation,
            primaryIndex: selectedRailImageIndex,
            primaryIsUpstream: travellingUpstream,
            secondaryIndex: railIndex,
            secondaryIsUpstream: secondaryTravellingUpstream,
        };
        setSecondaryAlignment(secondaryAlignment);
        setSecondaryAlignmentAdjustment(timestampInterpolation);

        console.log("Secondary alignment: ", secondaryAlignment);
    }, [
        calculatedELR,
        secondaryRailImages,
        secondaryRailConfig,
        selectedRailImageIndex,
        setSecondaryAlignment,
        mwvData,
        timestamp,
        routeLocationData,
        videoless,
        videolessTimestamp,
        setSecondaryAlignmentAdjustment,
    ]);

    const [lastSecondaryRailImageLoadedStatus, setLastSecondaryRailImageLoadedStatus] = useState(false);

    useEffect(() => {
        if (secondaryRailImageStatus) {
            if (secondaryRailImageStatus.loaded && !lastSecondaryRailImageLoadedStatus) {
                autoAlign();
            }
            setLastSecondaryRailImageLoadedStatus(secondaryRailImageStatus.loaded);
        }
    }, [secondaryRailImageStatus, lastSecondaryRailImageLoadedStatus, autoAlign]);

    const autoAlignButton = useMemo(() => {
        return (
            <>
                <button
                    className={"inspection-default-button" + (!secondaryRailImageStatus.loaded || manualAlignmentToolActive ? " Disabled" : "")}
                    onClick={autoAlign}>
                    <div className="TabSectionIcon">
                        <FontAwesomeIcon icon={faArrowsAltV} />
                    </div>
                    Auto Align
                </button>
            </>
        );
    }, [autoAlign, secondaryRailImageStatus, manualAlignmentToolActive, autoScrollActive]);

    const manualALignButton = useMemo(() => {
        if (secondaryRailImageStatus.loaded) {
            return (
                <button
                    className={`inspection-default-button ${manualAlignmentToolActive || autoScrollActive ? "inspection-default-button-manual-aling-active" : ""}`}
                    onClick={() => toggleManualAlignmentTool()}>
                    <div className="TabSectionIcon">
                        <FontAwesomeIcon icon={manualAlignmentToolActive ? faTimes : faArrowFromBottom} />
                    </div>
                    {manualAlignmentToolActive ? "Cancel Align" : "Manual Align"}
                </button>
            );
        } else {
            return null;
        }
    }, [manualAlignmentToolActive, secondaryRailImageStatus.loaded, toggleManualAlignmentTool, autoScrollActive]);

    const hasDetailImages = useMemo(() => {
        return _.get(railImageConfigObject, "inspection_images", false)
            ? !!railImageConfigObject.inspection_images.filter((config) => config.showInModal).length
            : false;
    }, [railImageConfigObject]);

    const sidekickButton = useMemo(() => {
        if (wsToken && sidekickTypes.length) {
            return (
                <RailInspectHeaderWidget
                    manualAlignmentToolActive={manualAlignmentToolActive}
                    autoScrollActive={autoScrollActive}
                />
            );
        } else {
            return null;
        }
    }, [wsToken, sidekickTypes.length, manualAlignmentToolActive, autoScrollActive]);

    const distanceUnits = useMemo(() => {
        if (elrUnits === "elr_mile_chain") {
            return "ELR Mile & Chain";
        } else if (elrUnits === "elr_mile_yards") {
            return "ELR Mile & Yards";
        } else if (elrUnits === "elr_meterage") {
            return "ELR & Meterage";
        }
    }, [elrUnits]);

    const gotRailCoordinates = useCallback(
        (result) => {
            const l = [result[0].lon, result[0].lat];
            toggleFindModalVisibility(false);
            dispatch(routeSelected(sessionID, l));
        },
        [sessionID, dispatch],
    );

    useEffect(() => {
        if (foundRouteCoordinates && foundRouteCoordinates && foundRouteCoordinates.length) {
            gotRailCoordinates(foundRouteCoordinates);
        }
    }, [foundRouteCoordinates, gotRailCoordinates]);

    const toggleSettings = useCallback(() => {
        dispatch(toggleSettingsOpen());
    });

    const toggleDetections = () => {
        dispatch(toggleDetectionsOpen());
    };

    const formatPosition = (pos) => {
        let retVal = "";
        if (pos) {
            retVal = pos.toFixed(7);
        }
        return retVal;
    };

    const latLngPositionString = useMemo(() => {
        return formatPosition(positionCoords[1]) + ", " + formatPosition(positionCoords[0]);
    }, [positionCoords]);

    function LatLongCopier({ coords }) {
        return (
            <LazyTippy
                placement="bottom"
                sticky={true}
                plugins={[sticky]}
                arrow={true}
                interactive="true"
                content="Copy latitude and longitude to clipboard">
                <div
                    className="CopyToClipboard"
                    onClick={() => {
                        navigator.clipboard.writeText(coords);
                    }}>
                    <FontAwesomeIcon
                        icon={faClipboard}
                        color="white"
                        style={{ marginRight: 5, marginLeft: 5 }}
                    />
                </div>
            </LazyTippy>
        );
    }

    function ELRCopier({ distanceDetail }) {
        return (
            <LazyTippy
                placement="bottom"
                sticky={true}
                plugins={[sticky]}
                arrow={true}
                interactive="true"
                content="Copy ELR to clipboard">
                <div
                    className="CopyToClipboard"
                    onClick={() => {
                        navigator.clipboard.writeText(distanceDetail);
                    }}>
                    <FontAwesomeIcon
                        icon={faClipboard}
                        color="white"
                        style={{ marginRight: 5, marginLeft: 5 }}
                    />
                </div>
            </LazyTippy>
        );
    }

    const findMostRecent = memoize((metadata, timestamp) => {
        if (metadata) {
            return _.findLast(metadata, (data) => data.timestamp <= timestamp);
        } else {
            return null;
        }
    });

    const trainSpeed = useMemo(() => {
        let speed = "Unknown";
        let timestampSeconds = 0;

        if (!session) {
            return null;
        }

        if (videolessTimestamp) {
            timestampSeconds = videolessTimestamp / 1000;
            const tachoInfo = findMostRecent(tachoMetadata, timestampSeconds);
            let baseSpeed = null;
            let speedSource = "";
            if (!_.isNil(tachoInfo?.data.speed) && tachoInfo.timestamp > timestampSeconds - 5) {
                baseSpeed = tachoInfo?.data.speed;
                speedSource = " (Tachometer)";
            }

            const speedInfo = findMostRecent(speedMetadata, timestampSeconds);
            if (baseSpeed == null && !_.isNil(speedInfo?.data.speed) && speedInfo.timestamp > timestampSeconds - 5) {
                baseSpeed = speedInfo?.data.speed;
                speedSource = " (GPS)";
            }

            if (baseSpeed != null) {
                let unitDict = {
                    metres: "m/s",
                    miles: "MPH",
                    kilometers: "KPH",
                };
                let unit = userConfig.speed_units || "miles";
                speed = Math.round(convertSpeed(baseSpeed, unit)) + " " + unitDict[unit] + speedSource;
            }
        }

        return (
            <div className="inspectRail-Main__Header__Info">
                <MetadataFeed
                    items={["SPEED_AND_BEARING", "TACHO_DATA"]}
                    timestamp={timestampSeconds}>
                    <div className="source__Info">
                        <p>speed</p>
                        <span className="bar">{speed ? speed : "n/a"}</span>
                    </div>
                </MetadataFeed>
            </div>
        );
    }, [videolessTimestamp, speedMetadata, userConfig, findMostRecent, session]);

    const allowRailFlip = useMemo(() => {
        return railImageConfigObject.horizontal;
    }, [railImageConfigObject.horizontal]);

    const toggleAnnotationMode = useCallback(() => {
        dispatch(toggleInspectionAnnotationMode(!annotateModeEnabled));
    });

    const toggleSnapshot = () => {
        dispatch(toggleInspectionSnapshot(!inspectionSnapshotOpen));
        console.log(inspectionSnapshotOpen);
    };

    const primaryRailDate = useMemo(() => {
        const sessionTimestamp = _.get(session, "first_seen", 0);
        const date = moment.unix(sessionTimestamp);
        return sessionTimestamp ? date.format("DD/MM/YYYY") : null;
    }, [session]);

    const secondaryRailDate = useMemo(() => {
        const comparingSession = _.get(allSessions, selectedComparingSessionID);
        if (comparableSessions) {
            const sessionTimestamp = _.get(comparingSession, "first_seen", 0);
            const date = moment.unix(sessionTimestamp);
            return sessionTimestamp ? date.format("DD/MM/YYYY") : null;
        } else {
            return null;
        }
    }, [allSessions, comparableSessions, selectedComparingSessionID]);

    const [activeTab, setActiveTab] = useState(0);

    const InspectionToolGroup = useMemo(() => {
        const ShowDetailImages = () => {
            if (!hasDetailImages) {
                return null;
            }

            return (
                <button
                    onClick={() => {
                        dispatch(setDisplayExtraLayers(!displayExtraLayers));
                        setMoreImagesAvailable(false);
                    }}
                    className={`inspection-default-button ${displayExtraLayers ? "active" : ""} ${moreImagesAvailable ? "promote" : ""}`}>
                    <div className="TabSectionIcon">
                        <DetailRailIcon />
                    </div>
                    Rail Detail Images
                </button>
            );
        };

        const SessionToolContent = () => {
            return (
                <>
                    <div className="InspectionToolContentRow">
                        {!allowRailFlip && currentDashboard && currentDashboard.isBeta && (mwvData || calculatedELR) && compareButton}
                        {manualALignButton}
                        <button
                            onClick={toggleFindModalVisibility}
                            className="inspection-default-button">
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon
                                    className="mapSearchButton-icon"
                                    icon={faSearch}
                                />
                            </div>
                            Search for Location
                        </button>
                        {autoAlignButton}
                        {videoless && userIsSuperAdmin
                            ? session.inspection_archive_status === 0
                                ? archiveInspectionSessionButton
                                : restoreArchivedInspectionSessionButton
                            : null}
                    </div>
                </>
            );
        };

        const InspectionTools = () => {
            return (
                <>
                    <div className="InspectionToolContentRow">
                        <ShowDetailImages />
                        <button
                            onClick={toggleSnapshot}
                            className={`inspection-default-button ${inspectionSnapshotOpen ? "active" : ""} ${!selectedRailImageTimestamp ? "button-disabled" : ""}`}>
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon icon={faCamera} />
                            </div>
                            Take Snapshot
                        </button>
                        <button
                            onClick={toggleMarkerTool}
                            className={`inspection-default-button ${markerToolActive ? "active" : ""}`}>
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon icon={faMapPin} />
                            </div>
                            Place Bookmark
                        </button>
                        <button
                            onClick={toggleAnnotationMode}
                            className={`inspection-default-button ${annotateModeEnabled ? "active" : ""}`}>
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon icon={faObjectUngroup} />
                            </div>
                            Annotate
                        </button>
                        <button
                            onClick={toggleDetections}
                            className={`inspection-default-button wide`}>
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon icon={faBars} />
                            </div>
                            Asset Navigation
                        </button>
                        <button
                            onClick={toggleSettings}
                            className={`inspection-default-button ${settingsOpen ? "active" : ""}`}>
                            <div className="TabSectionIcon">
                                <FontAwesomeIcon icon={faCog} />
                            </div>
                            Image Adjustment Settings
                        </button>
                        {sidekickButton}
                    </div>
                </>
            );
        };

        return (
            <div className="ToolWrapper">
                <div className="InspectionToolGroup">
                    <div className="InspectionToolGroupTitleContainer">
                        <div
                            className={`InspectionToolGroupTitleTab ${activeTab === 0 ? "active" : activeTab === 1 ? "second-active" : ""}`}
                            onClick={() => {
                                setActiveTab(0);
                            }}>
                            Inspection Tools
                        </div>
                        <div
                            className={`InspectionToolGroupTitleTab ${activeTab === 1 ? "active" : activeTab === 2 ? "third-active" : ""}`}
                            onClick={() => {
                                setActiveTab(1);
                            }}>
                            Session Tools
                        </div>
                    </div>
                    <div className="InspectionToolContent">
                        {activeTab === 0 && <InspectionTools />}
                        {activeTab === 1 && <SessionToolContent />}
                    </div>
                </div>
                <div className="RailHeaderRouteInfo">
                    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", width: "100%" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div className={`${!dataLoaded ? "ProgressBarToolTip" : "ProgressBarToolTip hidden"}`}>
                                <Tooltip title="Rail images loading. Parts of the session may be unavailable until loading has completed.">
                                    <div className={`RailHeaderProgressContainer ${autoScrollActive ? "Disabled" : ""}`}>
                                        <div className="PercentageTextContainer">{Math.round(percentageComplete)}%</div>
                                        <Progress
                                            percent={percentageComplete}
                                            status="active"
                                            strokeWidth={6}
                                            strokeColor={{ "0%": "#b09aee", "100%": "#b09aee" }}
                                            railColor={{ "0%": "#414664", "100%": "#2b2f47" }}
                                            showInfo={false}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: ".5rem", width: "100%", flexWrap: "wrap" }}>
                            <div className="RailHeaderRow">
                                Primary Rail:
                                <span>{primaryRailDate}</span>
                            </div>
                            {selectedComparingSessionID && (
                                <div className="RailHeaderRow">
                                    Comparing Rail:
                                    <span>{secondaryRailImageStatus.percentageComplete.toFixed(0) < 100 ? "loading..." : secondaryRailDate}</span>
                                </div>
                            )}
                            <div className="RailHeaderRow">
                                Route:
                                <span>{calculatedELR?.route}</span>
                                Track:
                                <span>{calculatedELR?.track}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [
        annotateModeEnabled,
        dataLoaded,
        activeTab,
        hasDetailImages,
        displayExtraLayers,
        moreImagesAvailable,
        allowRailFlip,
        currentDashboard,
        mwvData,
        calculatedELR,
        compareButton,
        sidekickButton,
        calculatedELR,
        primaryRailDate,
        toggleMarkerTool,
        markerToolActive,
        settingsOpen,
    ]);

    return (
        <>
            {unitSelectionModalVisible && <UnitSelectionModal onClose={() => setUnitSelectionModalVisible(false)} />}
            <div className="InspectionWindowBar">
                <img
                    src="/aivr-hybrid.svg"
                    alt="logo"
                    className="RailInspectHeaderLogo"
                />
                <div className="inspectRail-Main__Header__Row-Info">
                    <div className="inspectRail-Main__Header__Info">
                        <div className="source__Info">
                            <p>Workspace:</p>
                            <span>{currentDashboard && currentDashboard.description ? currentDashboard.description : ""}</span>
                        </div>
                    </div>

                    {session && session.route_name && session.route_name.length && (
                        <div className="inspectRail-Main__Header__Info">
                            <div className="source__Info">
                                <p>{session.route_name}</p>
                                <span>#{session.id}</span>
                            </div>
                            <div style={{ color: "white", marginRight: "5px", display: "flex", height: "100%", alignItems: "center", paddingTop: "2px" }}>
                                <FavoriteButton
                                    sessionID={sessionID}
                                    setFavouritePopoverOpen={setFavouritePopoverOpen}
                                />
                            </div>
                        </div>
                    )}
                    <div className="inspectRail-Main__Header__Info">
                        <div className="source__Info">
                            <p>LAT:</p>
                            <span>{formatPosition(positionCoords[1])}</span>
                            <p>LNG:</p>
                            <span>{formatPosition(positionCoords[0])}</span>
                        </div>
                        <div>
                            <LatLongCopier coords={latLngPositionString}></LatLongCopier>
                        </div>
                        <div className="source__Info elr">
                            <p>{distanceUnits}</p>
                            <FontAwesomeIcon
                                style={{ marginRight: 5, marginLeft: 5 }}
                                className="RouteInfo-ELRSettings__Icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUnitSelectionModalVisible(true);
                                }}
                                icon={faCog}
                            />
                            {!mwvData && calculatedELR && calculatedELR.string && calculatedELR.string.length && (
                                <span
                                    className="bar"
                                    key={calculatedELR.string}>
                                    {calculatedELR.string}
                                </span>
                            )}
                            {mwvData && (
                                <span
                                    className="bar"
                                    key={mwvData.string}>
                                    {mwvData.string}
                                </span>
                            )}
                        </div>
                        <div>
                            <ELRCopier distanceDetail={!mwvData ? calculatedELR && calculatedELR.string : mwvData.string}></ELRCopier>
                        </div>
                    </div>
                    {trainSpeed}

                    {/* <Tooltip title="Report a problem with this inspection session">
                        <button onClick={showReportProblemDialog} className={"inspection-default-button marker header"}>
                            <FontAwesomeIcon icon={faBug} />
                        </button>
                    </Tooltip> */}
                </div>
                {exitButton}
            </div>
            {InspectionToolGroup}
            <div
                className="inspectRail-Main__Header"
                ref={railInspectionHeader}>
                <div className="DateRouteInfo">
                    <div className="inspectRail-Main__Header__Item">
                        <div className={`inspectRail-Main__Header__Item__SecondaryRailInfo`}>
                            {selectedComparingSessionID && (
                                <div className="inspectRail-Main__Header__Item__RailDateInfo">
                                    <p>Comparing Rail: </p>
                                    <span>{secondaryRailImageStatus.percentageComplete.toFixed(0) < 100 ? "loading..." : secondaryRailDate}</span>
                                </div>
                            )}
                        </div>

                        {/* <div className="inspectRail-Main__Header__ItemInnerRowRight">
                            
                            
                           

                            {allowRailFlip && <Checkbox onChange={(e) => dispatch(flipInspectionRails(e.target.checked))}
                                                        checked={railsFlippedValue}>
                                Flip Rails
                            </Checkbox>}

                    
                      
                 
     
                        </div> */}
                    </div>
                </div>
            </div>

            <RailInspectSourceModal
                toggleModal={toggleSourceModal}
                sourceModalOpen={sourceModalOpen}
            />
            <ArchiveInspectionSessionModal
                toggleModal={toggleArchiveInspectionSessionModal}
                archiveSessionModalVisible={archiveInspectionSessionModalVisible}
                sessionID={sessionID}
                archiveInspectionSession={handleArchiveInspectionSession}
            />

            <RestoreArchivedInspectionSessionModal
                toggleModal={toggleRestoreArchivedInspectionSessionModal}
                restoreArchivedSessionModalVisible={restoreArchivedInspectionSessionModalVisible}
                sessionID={sessionID}
                archiveInspectionSession={handleArchiveInspectionSession}
            />

            {compareModalOpen && (
                <RailCompareModal
                    toggleModal={toggleCompareModal}
                    visible={compareModalOpen}
                    calculatedELR={mwvData || calculatedELR}
                    selectedComparingSession={secondaryRailImageStatus.sessionID}
                    clearSecondarySession={clearSelectedSecondarySession}
                    selectSession={openCompareSession}
                />
            )}

            <FindLocationPopup
                visible={findModalVisible}
                onConfirm={() => toggleFindModalVisibility(false)}
                onCancel={() => toggleFindModalVisibility(false)}
                railInspection
            />
        </>
    );
};

export default RailInspectHeader;
