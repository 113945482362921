import ELRMileAndChain from "./ELRMileAndChain";

class RegexSearcher {
    constructor() {
        this.checkFunctions = [
            this.checkForLatLng,
            this.checkForWhat3Words,
            this.checkForElrYardage,
            this.checkForElrMileChain,
            this.checkForRawElrMileChain,
            this.checkForElrMeterage,
            this.checkForRawElrMeterage,
        ];

        this.latLngRegex1 = /^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/;
        this.latLngRegex2 = /^-?\d+(\.\d+)?\s-?\d+(\.\d+)?$/;
        this.what3WordsRegex = /^(?:\/\/\/)?[A-Za-z]+\.[A-Za-z]+\.[A-Za-z]+$/;
        this.elrMileYardRegex = /^([A-Za-z]{3}\d*) track (\d{4}) (\d{1,4})m (\d{4})y/;
        this.elrMileChainRegex = /^([A-Za-z]{3}\d*) track (\d{4}) (\d{1,4})\.(\d{1,2})$/;
        this.rawElrMileChainRegex = /^([A-Za-z]{3}\d*) (\d{1,4})\.(\d{1,2}) ?(\d{4})?/;
        this.elrMeterageRegex = /^([A-Za-z]{3}\d*) track (\d{4}) (\d*)m$/;
        this.rawElrMeterageRegex = /^([A-Za-z]{3}\d*) (\d*)m ?(\d{4})?/;
    }

    checkForLatLng = (query) => {
        if (this.latLngRegex1.test(query) || this.latLngRegex2.test(query)) {
            // Matches Lat / lng format
            // EG {float},{float} / {float}, {float} / {float} {float}
            const [lat, lng] = this.extractLatLon(query);
            return [
                {
                    resultTypeLabel: "Lat / Lon",
                    results: [
                        {
                            type: "latln",
                            label: `"${query}"`,
                            data: {
                                lat,
                                lng,
                            },
                        },
                    ],
                },
            ];
        }
        return null;
    };

    extractLatLon = (query) => {
        let lat, lng;
        if (query.includes(",")) {
            const parts = query.split(",");
            if (parts.length === 2) {
                lat = parseFloat(parts[0].replace(/ /g, ""));
                lng = parseFloat(parts[1].replace(/ /g, ""));
            }
        } else if (query.includes(" ")) {
            const parts = query.split(" ");
            if (parts.length === 2) {
                lat = parseFloat(parts[0].replace(/ /g, ""));
                lng = parseFloat(parts[1].replace(/ /g, ""));
            }
        }

        return [lat, lng];
    };

    checkForWhat3Words = (query) => {
        if (this.what3WordsRegex.test(query)) {
            // matches what three words format
            // E.g: apple.orange.banana OR ///apple.orange.banana

            let words = query;
            if (query.includes("///")) {
                words = words.replace("///", "");
            }
            return [
                {
                    resultTypeLabel: "what3words",
                    results: [
                        {
                            type: "what3words",
                            label: `"${query}"`,
                            data: {
                                words,
                            },
                        },
                    ],
                },
            ];
        }
    };

    checkForElrYardage = (query) => {
        if (this.elrMileYardRegex.test(query)) {
            //Matches elr_mile_yards position format
            // {ELR} track {trackID} {mileage}m {yardage}y

            const elrSearch = this.extractElrMileYard(query);
            if (!elrSearch) {
                return [];
            }

            return [
                {
                    resultTypeLabel: "ELR Mile & Yard",
                    enterLabel: "to search position",
                    results: [
                        {
                            type: "elr",
                            label: `"${query.toUpperCase()}"`,
                            data: {
                                elr: elrSearch.elr,
                                position: elrSearch.position,
                                trackID: elrSearch.subposition,
                            },
                        },
                    ],
                },
            ];
        }
    };

    extractElrMileYard = (query) => {
        const regexParts = this.elrMileYardRegex.exec(query);
        if (!regexParts || regexParts.length < 5) {
            return null;
        }
        const elr = regexParts[1];
        const trackID = regexParts[2];
        const mileage = parseInt(regexParts[3]);
        const yardage = parseInt(regexParts[4]);

        return ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_mile_yards",
            {
                ELR: elr,
                MILE: mileage,
                YARDS: yardage,
                TRACK: trackID,
            },
            null,
            false,
        );
    };

    checkForElrMileChain = (query) => {
        if (this.elrMileChainRegex.test(query)) {
            //Matches elr_mile_chain position format
            // {ELR} track {trackID} {mileage}.{chainage}
            const elrSearch = this.extractElrMileChain(query);
            if (!elrSearch) {
                return [];
            }

            return [
                {
                    resultTypeLabel: "ELR Mile & Chain",
                    enterLabel: "to search position",
                    results: [
                        {
                            type: "elr",
                            label: `"${query.toUpperCase()}"`,
                            data: {
                                elr: elrSearch.elr,
                                position: elrSearch.position,
                                trackID: elrSearch.subposition,
                            },
                        },
                    ],
                },
            ];
        }
    };

    extractElrMileChain = (query) => {
        const regexParts = this.elrMileChainRegex.exec(query);
        if (!regexParts || regexParts.length < 5) {
            return null;
        }
        const elr = regexParts[1];
        const trackID = regexParts[2];
        const mileage = parseInt(regexParts[3]);
        const chainage = parseInt(regexParts[4]);

        return ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_mile_chain",
            {
                ELR: elr,
                MILE: mileage,
                CHAIN: chainage,
                TRACK: trackID,
            },
            null,
            false,
        );
    };

    checkForRawElrMileChain = (query) => {
        if (this.rawElrMileChainRegex.test(query)) {
            //Matches elr_mile_chain position format
            // {ELR} {mileage}.{chainage}
            const elrSearch = this.extractRawElrMileChain(query);
            if (!elrSearch) {
                return [];
            }
            return [
                {
                    resultTypeLabel: "ELR & Mile & Chain",
                    enterLabel: "to search position",
                    results: [
                        {
                            type: "elr",
                            label: `"${query.toUpperCase()}"`,
                            data: {
                                elr: elrSearch.elr,
                                position: elrSearch.position,
                                trackID: elrSearch.subposition,
                            },
                        },
                    ],
                },
            ];
        }
    };

    extractRawElrMileChain = (query) => {
        const regexParts = this.rawElrMileChainRegex.exec(query);
        console.log("debug regexParts", regexParts);
        if (!regexParts || regexParts.length < 5) {
            return null;
        }

        const elr = regexParts[1];
        const mileage = parseInt(regexParts[2]);
        const chainage = parseInt(regexParts[3]);
        const trackID = regexParts[4] || null;

        return ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_mile_chain",
            {
                ELR: elr,
                MILE: mileage,
                CHAIN: chainage,
                TRACK: trackID,
            },
            null,
            false,
        );
    };

    checkForElrMeterage = (query) => {
        if (this.elrMeterageRegex.test(query)) {
            //Matches elr_meterage position format
            // {ELR} track {trackID} {meterage}m
            const elrSearch = this.extractElrMeterage(query);
            if (!elrSearch) {
                return [];
            }

            return [
                {
                    resultTypeLabel: "ELR & Meterage",
                    enterLabel: "to search position",
                    results: [
                        {
                            type: "elr",
                            label: `"${query.toUpperCase()}"`,
                            data: {
                                elr: elrSearch.elr,
                                position: elrSearch.position,
                                trackID: elrSearch.subposition,
                            },
                        },
                    ],
                },
            ];
        }
    };

    extractElrMeterage = (query) => {
        const regexMatches = this.elrMeterageRegex.exec(query);
        if (!regexMatches || regexMatches.length < 4) {
            return null;
        }
        const elr = regexMatches[1];
        const trackID = regexMatches[2];
        const meterage = parseInt(regexMatches[3]);

        return ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_meterage",
            {
                ELR: elr,
                METERAGE: meterage,
                TRACK: trackID,
            },
            null,
            false,
        );
    };

    checkForRawElrMeterage = (query) => {
        if (this.rawElrMeterageRegex.test(query)) {
            //Matches elr_meterage position format
            // {ELR} track {trackID} {meterage}m
            const elrSearch = this.extractRawElrMeterage(query);
            if (!elrSearch) {
                return [];
            }

            return [
                {
                    resultTypeLabel: "ELR & Meterage",
                    enterLabel: "to search position",
                    results: [
                        {
                            type: "elr",
                            label: `"${query.toUpperCase()}"`,
                            data: {
                                elr: elrSearch.elr,
                                position: elrSearch.position,
                                trackID: elrSearch.subposition,
                            },
                        },
                    ],
                },
            ];
        }
    };

    extractRawElrMeterage = (query) => {
        const regexMatches = this.rawElrMeterageRegex.exec(query);
        if (!regexMatches || regexMatches.length < 3) {
            return null;
        }
        const elr = regexMatches[1];
        const meterage = parseInt(regexMatches[2]);
        const trackID = regexMatches[3];

        return ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_meterage",
            {
                ELR: elr,
                METERAGE: meterage,
                TRACK: trackID,
            },
            null,
            false,
        );
    };
}

const RegexSearch = new RegexSearcher();

export const checkQueryForSingleMatch = (query) => {
    let match = null;

    if (query === "logout" || query === "Logout") {
        return [
            {
                resultTypeLabel: "Settings",
                enterLabel: "to logout",
                results: [
                    {
                        type: "logout",
                        label: `Logout of AIVR`,
                    },
                ],
            },
        ];
    }

    for (let i = 0; i < RegexSearch.checkFunctions.length && !match; i++) {
        match = RegexSearch.checkFunctions[i](query);
    }
    return match;
};

const checkForWorkspaceMatch = (query, workspaces) => {
    const workspaceMatches = workspaces.filter((workspace) => {
        return workspace.description.toLowerCase().includes(query.toLowerCase());
    });

    return {
        resultTypeLabel: "Workspaces",
        enterLabel: "to switch to workspace",
        results: workspaceMatches.slice(0, 5).map((workspace) => {
            return {
                type: "workspace",
                label: workspace.description,
                data: {
                    workspaceToken: workspace.access_token,
                },
            };
        }),
    };
};

export const checkForPartialMatch = (query, workspaces, callback) => {
    const matchResults = checkForWorkspaceMatch(query, workspaces);
    if (matchResults) {
        callback("workspaces", matchResults);
    }
};
