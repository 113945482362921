import * as echarts from "echarts";
import { useMemo } from "react";

function useLineSpeeds({ palette }) {
    const lineSpeedsDataset = useMemo(() => {
        return [
            {
                id: "line_speeds_dataset",
                transform: {
                    type: "aivr:lineSpeeds",
                },
                fromDatasetId: "overview_dataset",
            },
        ];
    }, []);

    const lineSpeedsSeries = useMemo(() => {
        return [
            {
                id: `line_speeds_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                datasetId: "line_speeds_dataset",
                xAxisId: "overview_x",
                yAxisId: "overview_y",
                encode: {
                    value: ["start", "end", "speed"],
                },
                renderItem: function (params, api) {
                    const start = api.value(0);
                    const end = api.value(1);
                    const speed = api.value(2);
                    const startPoint = api.coord([start, 0.7]);
                    const endPoint = api.coord([end, 0.3]);

                    let speedMaxWeight = Math.min(speed / 125, 1.0);
                    let speedMinWeight = 1.0 - speedMaxWeight;

                    let speed_r = palette.speedMinR * speedMinWeight + palette.speedMaxR * speedMaxWeight;
                    let speed_g = palette.speedMinG * speedMinWeight + palette.speedMaxG * speedMaxWeight;
                    let speed_b = palette.speedMinB * speedMinWeight + palette.speedMaxB * speedMaxWeight;

                    let speed_color = `rgb(${speed_r}, ${speed_g}, ${speed_b})`;

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: endPoint[0] - startPoint[0],
                            height: endPoint[1] - startPoint[1],
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    return (
                        bounds && {
                            type: "rect",
                            transition: ["shape"],
                            emphasisDisabled: true,
                            shape: bounds,
                            style: {
                                fill: speed_color,
                                lineWidth: 0,
                            },
                        }
                    );
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
                z: 1,
            },
        ];
    }, [palette]);

    return useMemo(
        () => ({
            dataset: lineSpeedsDataset,
            series: lineSpeedsSeries,
        }),
        [lineSpeedsDataset, lineSpeedsSeries],
    );
}

export default useLineSpeeds;
