import React, { useEffect, useMemo, useRef } from "react";
import { Line } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import _ from "lodash";
import { getEndCoordinate, getStartCoordinate, latLonToMeters, locationsAreEqual } from "../../../util/Geometry";
import { useSelector, useStore } from "react-redux";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";

const videoDataSelector = (state) => {
    const playlistData = state.playlist.data;
    const sourceIndex = state.playlist.position.sourceIndex;
    return _.get(playlistData, ["video", sourceIndex], null);
};

const useSnappedSelector = (state) => state.snappedRoute || false;
const currentPositionSelector = (state) => state.playlist.position.coords;
const calibrationSelector = (state) => {
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }
    return get_source_calibration(
        state.measurement.calibration,
        state.playlist.position.sourceIndex,
        getAbsoluteTimestamp(state.playlist) * 1000,
        true,
        defaultRailSeparation,
    );
};

function ThreeDRails() {
    // Trigger updates on redux position state changes for this component only

    const store = useStore();

    const ref = useRef();
    const currentPositionRef = useRef(currentPositionSelector(store.getState()));

    const videoData = useSelector(videoDataSelector);
    const useSnapped = useSelector(useSnappedSelector);
    const calibration = useSelector(calibrationSelector);
    const railSeparation = useMemo(() => calibration.railSeparation, [calibration]);

    const { routeGeometry, zeroPoint } = useMemo(() => {
        console.log("Generating route geometry...");

        const routeGeometry = [];
        let lastEndCoordinate = null;
        let zeroPoint = null;

        videoData.forEach((playlistItem) => {
            const startCoordinate = getStartCoordinate(playlistItem, useSnapped);
            const endCoordinate = getEndCoordinate(playlistItem, useSnapped);

            if (zeroPoint === null) {
                zeroPoint = startCoordinate;
            }

            if (lastEndCoordinate && !locationsAreEqual(startCoordinate, lastEndCoordinate)) {
                routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
            }
            if (startCoordinate) {
                routeGeometry.push(latLonToMeters(zeroPoint, startCoordinate));
            }
            lastEndCoordinate = endCoordinate;
        });
        if (lastEndCoordinate) {
            routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
        }

        return { routeGeometry, zeroPoint };
    }, [videoData, useSnapped]);

    const { leftRailCoords, rightRailCoords } = useMemo(() => {
        const halfRailWidth = railSeparation / 2 + 0.01;

        const leftRailCoords = [];
        const rightRailCoords = [];

        if (routeGeometry.length < 2) {
            //do nothing
        } else if (routeGeometry.length === 2) {
            const angle = Math.atan2(routeGeometry[1][1], routeGeometry[1][0]);
            const sinAngle = Math.sin(angle);
            const cosAngle = Math.cos(angle);
            const railXOffset = cosAngle * halfRailWidth;
            const railZOffset = -sinAngle * halfRailWidth;
            leftRailCoords.push([-railXOffset, 0, -railZOffset], [-routeGeometry[1][1] - railXOffset, 0, -routeGeometry[1][0] - railZOffset]);
            rightRailCoords.push([railXOffset, 0, railZOffset], [-routeGeometry[1][1] + railXOffset, 0, -routeGeometry[1][0] + railZOffset]);
        } else {
            for (let i = 0; i < routeGeometry.length; i++) {
                let o0, o1, p;
                if (i === 0) {
                    o0 = p = routeGeometry[i];
                    o1 = routeGeometry[i + 1];
                } else {
                    o0 = routeGeometry[i - 1];
                    o1 = p = routeGeometry[i];
                }
                const angle = Math.atan2(o1[1] - o0[1], o1[0] - o0[0]);
                const sinAngle = Math.sin(angle);
                const cosAngle = Math.cos(angle);
                const railXOffset = cosAngle * halfRailWidth;
                const railZOffset = -sinAngle * halfRailWidth;
                leftRailCoords.push([-p[1] - railXOffset, 0, -p[0] - railZOffset]);
                rightRailCoords.push([-p[1] + railXOffset, 0, -p[0] + railZOffset]);
            }
        }
        return { leftRailCoords, rightRailCoords };
    }, [routeGeometry, railSeparation]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
        });
    }, [store]);

    useFrame(() => {
        if (currentPositionRef.current) {
            const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);

            if (ref.current) {
                ref.current.position.set(positionOffset[1], 0, positionOffset[0]);
            }
        }
    });

    return (
        <group ref={ref}>
            <Line
                points={leftRailCoords}
                color="white"
            />
            <Line
                points={rightRailCoords}
                color="white"
            />
        </group>
    );
}

export default ThreeDRails;
