import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import {
    addMarkerReviewFilter,
    removeMarkerReviewFilter,
    updateThresholdFilter,
    addMarkerConditionFilter,
    removeMarkerConditionFilter,
    setTroughingFilter,
    setMarkerConditionFilter,
    setLowBallastConfidenceFilter,
} from "../redux/actions/index";
import { Button, Checkbox, InputNumber, Modal, Slider, Select } from "antd";
import { degreesToUserPreference, farenheitToCelsius } from "./util/TemperatureUtils";

const emptyArray = [];
const emptyObject = {};
const filtersArraySelector = (state) => state.markerReviewFilters || emptyArray;
const filtersConditionObjectSelector = (state) => state.markerConditionFilter || emptyObject;
const detectionTypeSelector = (state) => state.detectionTypes;
const thresholdFilterSelector = (state) => state.markerThresholdFilters;
const conditionTypesSelector = (state) => state.markers.conditionTypes || emptyArray;
const observationFilterSelector = (state) => state.observationFilters;
const selectedMarkerSelector = (state) => state.markers.selectedMarker;
const sessionObservationsSelector = (state) => state.sessionObservations;
const tempUnitPreferenceSelector = (state) => state.userDetails.userConfig.temperature_units || "celsius";
const lowBallastConfidenceFilterSelector = (state) => state.observationFilters.lowBallastConfidenceFilter;

const MarkerThresholdFiltersModal = ({ markerThresholdFilterOpen, setMarkerThresholdFilterOpen }) => {
    let dispatch = useDispatch();

    const filtersArray = useSelector(filtersArraySelector);
    const filtersConditionObject = useSelector(filtersConditionObjectSelector);
    const detectionTypes = useSelector(detectionTypeSelector);
    const thresholdFilters = useSelector(thresholdFilterSelector);
    const conditionTypes = useSelector(conditionTypesSelector);
    const currentRouteID = useSelector((state) => state.playlist.data.routeID);
    const defaultMarkers = useSelector((state) => state.defaultMarkersThresholds[currentRouteID] || emptyObject);
    const marker = useSelector((state) => state.markers);
    const observationFilters = useSelector(observationFilterSelector);
    const selectedMarker = useSelector(selectedMarkerSelector);
    const sessionObservations = useSelector(sessionObservationsSelector);
    const tempUnitPreference = useSelector(tempUnitPreferenceSelector);
    const lowBallastConfidenceFilter = useSelector(lowBallastConfidenceFilterSelector);

    const { Option } = Select;

    const markerName = useMemo(() => {
        return _.get(selectedMarker, "class", _.get(selectedMarker, "name", null));
    }, [selectedMarker]);

    const markersAndObservations = useMemo(() => {
        let combinedUniqueMarkers = [];
        if (sessionObservations && marker.perSession[currentRouteID]) {
            combinedUniqueMarkers = _.uniq([
                ...marker.perSession[currentRouteID].map((item) => item.name.toLowerCase()),
                ...sessionObservations.map((item) => item.class.toLowerCase()),
            ]);
        }
        return combinedUniqueMarkers;
    }, [sessionObservations, marker, currentRouteID]);

    const filterableTypes = useMemo(() => {
        return detectionTypes.filter((item) => {
            if (!markersAndObservations.includes(item.type.toLowerCase())) {
                return false;
            } else {
                return item.min_score || item.max_score;
            }
        });
    }, [detectionTypes, markersAndObservations]);

    const selectMarkerType = (markerType) => {
        if (_.includes(filtersArray, markerType)) {
            dispatch(removeMarkerReviewFilter(markerType));
        } else {
            dispatch(addMarkerReviewFilter(markerType));
        }
    };

    const onThresholdFilterChange = useCallback(
        (key, value) => {
            dispatch(updateThresholdFilter(key, value));
        },
        [dispatch],
    );

    const changeAuto = useCallback(
        (isAuto, type) => {
            if (isAuto) {
                onThresholdFilterChange(type.type, undefined);
            } else {
                const defValue = _.get(defaultMarkers, [type.type], type.default_score);
                const filterValue = _.get(thresholdFilters, [type.type], defValue);
                onThresholdFilterChange(type.type, filterValue);
            }
        },
        [onThresholdFilterChange, defaultMarkers, thresholdFilters],
    );

    const toggleMarkerCondition = useCallback(
        (checked, conditionType, conditionID) => {
            if (checked) {
                dispatch(addMarkerConditionFilter(conditionType, conditionID));
            } else {
                dispatch(removeMarkerConditionFilter(conditionType, conditionID));
            }
        },
        [dispatch],
    );

    const sliderFilters = useMemo(() => {
        return filterableTypes
            .map((type) => {
                if ((markerName && type.type.toLowerCase() === markerName.toLowerCase()) || !markerName) {
                    const defValue = _.get(defaultMarkers, [type.type], type.default_score);
                    const filterValue = _.get(thresholdFilters, [type.type], defValue);
                    let isAuto = thresholdFilters[type.type] === undefined;

                    let minScore = type.min_score;
                    let maxScore = type.max_score;
                    let scoreUnits = type.score_units;
                    let valueConverter = (val) => val;
                    let reverseConverter = (val) => val;

                    if (scoreUnits === "degrees") {
                        minScore = degreesToUserPreference(minScore, tempUnitPreference);
                        maxScore = degreesToUserPreference(maxScore, tempUnitPreference);
                        scoreUnits = tempUnitPreference;
                        valueConverter = (value) => degreesToUserPreference(value, tempUnitPreference);
                        if (tempUnitPreference === "fahrenheit") {
                            reverseConverter = (value) => {
                                const converted = degreesToUserPreference(value, tempUnitPreference, true);
                                return converted;
                            };
                            reverseConverter = (value) => farenheitToCelsius(value);
                        }
                    }

                    return (
                        <div
                            className="SliderFilterRow"
                            key={"MarkerConditionTypeKey" + type.type}>
                            <span className="Text">{type.type}</span>
                            <div className="SliderContainer">
                                <Slider
                                    onChange={(value) => onThresholdFilterChange(type.type, reverseConverter(value))}
                                    disabled={isAuto}
                                    min={minScore}
                                    max={maxScore}
                                    value={valueConverter(filterValue)}
                                    marks={{
                                        [minScore]: `${minScore}`,
                                        [maxScore]: `${maxScore}`,
                                    }}
                                    tipFormatter={(value) => `${value} ${scoreUnits}`}
                                    className="Slider"
                                />
                                <p className="Units">{scoreUnits}</p>
                            </div>
                            <InputNumber
                                min={minScore}
                                max={maxScore}
                                value={valueConverter(filterValue)}
                                disabled={isAuto}
                                style={{ marginLeft: 16 }}
                                onChange={(value) => onThresholdFilterChange(type.type, reverseConverter(value))}
                                className="Input"
                                size="small"
                            />
                            <Checkbox
                                checked={isAuto}
                                onChange={(e) => changeAuto(e.target.checked, type)}>
                                Auto
                            </Checkbox>
                        </div>
                    );
                }
                return null;
            })
            .filter((item) => item !== null);
    }, [filterableTypes, thresholdFilters, onThresholdFilterChange, defaultMarkers, changeAuto, markerName, tempUnitPreference]);

    const toggleAllConditions = useCallback(
        (checked, conditionType, conditions) => {
            if (checked) {
                const newFilters = _.cloneDeep(filtersConditionObject);
                newFilters[conditionType] = [null, ..._.map(conditions, "id")];
                dispatch(setMarkerConditionFilter(newFilters));
            } else {
                const newFilters = _.cloneDeep(filtersConditionObject);
                newFilters[conditionType] = [];
                dispatch(setMarkerConditionFilter(newFilters));
            }
        },
        [dispatch, filtersConditionObject],
    );

    const conditionFilters = useMemo(() => {
        let filteredConditionTypes = markerName
            ? conditionTypes.filter((item) => item.marker_type.toLowerCase() === markerName.toLowerCase())
            : conditionTypes.filter((item) => markersAndObservations.includes(item.marker_type.toLowerCase()));

        const groupedConditions = _.groupBy(filteredConditionTypes, "marker_type");
        let markerTypesPerSession = emptyObject;
        if (marker && currentRouteID) {
            markerTypesPerSession = _.groupBy(marker.perSession[currentRouteID], "name");
        }

        const isChecked = (name, condition) => {
            if (filtersConditionObject[name]) {
                return filtersConditionObject[name].includes(condition.id);
            } else {
                return false;
            }
        };

        return _.map(groupedConditions, (conditions, name) => {
            if (markerTypesPerSession[name]) {
                const allChecked = filtersConditionObject[name]?.length === conditions.length + 1;
                return (
                    <div
                        className="markerCondition"
                        key={"MarkerConditionKey" + name}>
                        <span className="markerConditionLabel">{name}</span>
                        <div className="markerConditionList">
                            <Checkbox
                                className="markerReviewFilter-Checkbox"
                                key={null}
                                checked={isChecked(name, { id: null })}
                                onChange={(e) => toggleMarkerCondition(e.target.checked, name, null)}>
                                None
                            </Checkbox>
                            {_.map(conditions, (condition) => {
                                return (
                                    <Checkbox
                                        className="markerReviewFilter-Checkbox"
                                        key={condition.id}
                                        checked={isChecked(name, condition)}
                                        onChange={(e) => toggleMarkerCondition(e.target.checked, name, condition.id)}>
                                        {condition.condition_name}
                                    </Checkbox>
                                );
                            })}

                            <Checkbox
                                className="markerReviewFilter-Checkbox"
                                checked={allChecked}
                                onChange={(e) => toggleAllConditions(e.target.checked, name, conditions)}>
                                All
                            </Checkbox>
                        </div>
                    </div>
                );
            }
        });
    }, [markerName, conditionTypes, marker, currentRouteID, markersAndObservations, filtersConditionObject, toggleMarkerCondition, toggleAllConditions]);

    const setTroughingFilters = (e, filterType) => {
        if (filterType === "condition") {
            dispatch(setTroughingFilter(e, observationFilters.troughingType));
        }
        if (filterType === "type") {
            dispatch(setTroughingFilter(observationFilters.condition, e));
        }
    };

    const displayTroughingFilters = useMemo(() => {
        if (!_.isEmpty(selectedMarker)) {
            return selectedMarker.class === "Troughing";
        } else {
            return markersAndObservations.includes("troughing");
        }
    }, [selectedMarker, markersAndObservations]);

    const renderLowBallastConfidenceFilter = useMemo(() => {
        if (markerName === "Low Ballast" || markerName === "low_ballast" || markerName === null) {
            return (
                <div className="LowBallast">
                    <h3 className="LowBallastTitle">Low Ballast</h3>
                    <div className="LowBallastContainer">
                        <span className="LowBallastLabel">Confidence</span>
                        <Slider
                            className="LowBallastConfidenceFilter"
                            range={true}
                            min={0}
                            max={1}
                            marks={{
                                0: `0`,
                                1: `1`,
                            }}
                            step={0.01}
                            value={lowBallastConfidenceFilter}
                            onChange={(e) => dispatch(setLowBallastConfidenceFilter(e))}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }, [markerName, dispatch, lowBallastConfidenceFilter]);

    return (
        <Modal
            className="MarkerThresholdModal"
            title="Marker Filters"
            visible={markerThresholdFilterOpen}
            onOk={() => {
                setMarkerThresholdFilterOpen(false);
            }}
            onCancel={() => {
                setMarkerThresholdFilterOpen(false);
            }}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        setMarkerThresholdFilterOpen(false);
                    }}>
                    Close
                </Button>,
            ]}>
            <div className="MarkerThresholdPopup">
                <h3>Review Status Filters</h3>
                <div className="markerReviewFilter">
                    <Checkbox
                        className="markerReviewFilter-Checkbox"
                        key={1}
                        checked={_.includes(filtersArray, 1)}
                        onChange={() => {
                            selectMarkerType(1);
                        }}>
                        Verified
                    </Checkbox>
                    <Checkbox
                        className="markerReviewFilter-Checkbox"
                        key={2}
                        checked={_.includes(filtersArray, 2)}
                        onChange={() => {
                            selectMarkerType(2);
                        }}>
                        Verified & Hidden
                    </Checkbox>
                    <Checkbox
                        className="markerReviewFilter-Checkbox"
                        key={0}
                        checked={_.includes(filtersArray, 0)}
                        onChange={() => {
                            selectMarkerType(0);
                        }}>
                        Needs Review
                    </Checkbox>
                    <Checkbox
                        className="markerReviewFilter-Checkbox"
                        key={3}
                        checked={_.includes(filtersArray, 3)}
                        onChange={() => {
                            selectMarkerType(3);
                        }}>
                        Rejected
                    </Checkbox>
                </div>
                {!!sliderFilters.length && (
                    <>
                        <h3>Score Filters</h3>
                        <div className="MarkerThresholdFilter">{sliderFilters}</div>
                    </>
                )}

                {renderLowBallastConfidenceFilter}

                {(!!conditionFilters[0] || displayTroughingFilters) && (
                    <div className="markerReviewCondition">
                        <h3>Conditions Filters</h3>
                        {conditionFilters}
                    </div>
                )}

                {displayTroughingFilters && (
                    <>
                        <h4 className="TroughingHeading">Troughing</h4>
                        <div className="TroughingFilters">
                            <Select
                                className="TroughingFilterSelect"
                                mode="multiple"
                                placeholder="Troughing Conditions"
                                onChange={(e) => setTroughingFilters(e, "condition")}
                                showArrow
                                maxTagTextLength={7}
                                maxTagCount={1}
                                value={observationFilters.condition}
                                allowClear>
                                <Option key={"defect"}>Defect</Option>
                                <Option key={"obscured"}>Obscured</Option>
                                <Option key={"cables"}>Cables outside of troughing</Option>
                                <Option key={"good"}>Good</Option>
                                <Option key={"invalid"}>Invalid</Option>
                                <Option key={"no_result"}>No result</Option>
                            </Select>
                            <Select
                                className="TroughingFilterSelect"
                                mode="multiple"
                                placeholder="Troughing Types"
                                onChange={(e) => setTroughingFilters(e, "type")}
                                showArrow
                                maxTagTextLength={7}
                                maxTagCount={1}
                                value={observationFilters.troughingType}
                                allowClear>
                                <Option key={"concrete"}>Concrete</Option>
                                <Option key={"composite"}>Composite</Option>
                                <Option key={"elevated"}>Elevated</Option>
                                <Option key={"uncertain"}>Uncertain</Option>
                                <Option key={"not troughing"}>Not troughing</Option>
                            </Select>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default MarkerThresholdFiltersModal;
