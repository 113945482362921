import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import DtTrainingCategoryList from "./DtTrainingCategoryList";
import DtCategoryPage from "./DtCategoryPage";
import { useDispatch } from "react-redux";
import { getDriverTrainings } from "redux/actions/driverTrainingActions";
import { getFavouriteCategories } from "redux/actions";
const { Content } = Layout;

const TrainingSessionsMainPage = () => {
    const dispatch = useDispatch();

    const [loadingCategoryData, setLoadingCategoryData] = useState(true);

    useEffect(() => {
        dispatch(
            getDriverTrainings((success) => {
                setLoadingCategoryData(false);
            }),
        );
        dispatch(
            getFavouriteCategories(true, (success) => {
                console.log("debug susccess ", success);
            }),
        );
    }, []);

    return (
        <Content className="DtMainPageContent">
            {/* list of all shorcuts (left column) */}
            <div className="LeftContainer">
                <DtTrainingCategoryList loadingCategoryData={loadingCategoryData} />
            </div>
            {/* preview of all available sessions for selected shortcut */}
            <div className="RightContainer">
                <DtCategoryPage loadingCategoryData={loadingCategoryData} />
            </div>
        </Content>
    );
};

export default TrainingSessionsMainPage;
