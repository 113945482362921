import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionLogs } from "../redux/actions/index";
import OBCSpinner from "./util/OBC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faMap } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "antd";
import _ from "lodash";

const sessionIDSelector = (state) => state.playlist.data.routeID;
const sessionLogsSelector = (state) => state.playlist.logData;

const sourceIconDict = {
    mapmatcher: <FontAwesomeIcon icon={faMap} />,
};

const copyClicked = (link) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(link);
    }
};

const LogListItem = ({ logObject }) => {
    const timeString = new Date(1000 * logObject.timestamp).toUTCString();
    const { subsystem, log } = logObject;
    const isString = typeof log === "string";

    const _logObject = isString ? {} : logObject.log;
    if (isString) {
        const keyValuePairs = log.split(";");
        keyValuePairs.forEach((kvp) => {
            const thisLog = kvp.replace(/\s/g, ""); // Remove whitespaces
            if (thisLog.indexOf("<https://") === 0) {
                const splitUrl = thisLog.split("|"); // Split out label
                const tidyLabel = splitUrl[1].replace(">", ":");
                const tidyUrl = splitUrl[0].replace("<", "");
                const link = (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={tidyUrl}>
                        {tidyUrl}
                    </a>
                );
                _logObject[tidyLabel] = link;
            } else {
                const splitLog = thisLog.split(":");
                _logObject[splitLog[0]] = splitLog[1];
            }
        });
    } else {
        Object.assign(_logObject, logObject.log);
    }

    return (
        <div className={isString ? "LogRow" : "LogRowObj"}>
            <div className={"LogRowObj__Header"}>
                <span>{timeString}</span>
                <Tooltip title={subsystem}>
                    <span>{sourceIconDict[subsystem] ?? subsystem}</span>
                </Tooltip>
            </div>
            {Object.keys(_logObject).map((key) =>
                isString ? (
                    <div
                        className="LogRow__Item"
                        key={key}>
                        <p className="label">{key}</p>
                        <p>{_logObject[key]}</p>
                        {_.isObject(_logObject[key]) && _logObject[key].type === "a" && (
                            <button
                                className="LogRow__Item__Copy"
                                onClick={() => copyClicked(_logObject[key].props.href)}>
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        )}
                    </div>
                ) : (
                    <ul
                        className="LogRowObj__List"
                        key={key}>
                        <li>
                            <strong>{key} - </strong>
                            <span>{_logObject[key]}</span>
                        </li>
                    </ul>
                ),
            )}
        </div>
    );
};

const SessionLogs = memo(() => {
    const dispatch = useDispatch();
    const sessionID = useSelector(sessionIDSelector);
    const logData = useSelector(sessionLogsSelector);
    const [loading, setLoading] = useState(true);

    const hasLogs = !loading && logData.length > 0;

    useEffect(() => {
        if (sessionID) {
            dispatch(
                getSessionLogs(sessionID, () => {
                    setLoading(false);
                }),
            );
        }
    }, [sessionID, dispatch]);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}>
                <OBCSpinner colorScheme={"mono"} />
            </div>
        );
    }

    return (
        <div className="LogRowContainer">
            {hasLogs ? logData.map((logObject) => <LogListItem logObject={logObject} />) : <span className="Text">No Session Logs Found</span>}
        </div>
    );
});

export default SessionLogs;
