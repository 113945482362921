import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

const ImageWithFallback = ({ imageSrc, imageAlt }) => {
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        setImageError(false);
    }, [imageSrc]);

    return (
        <div className="imageWithFallback">
            {imageError ? (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} />
                    <p>No image found</p>
                </>
            ) : (
                <img
                    src={imageSrc}
                    alt={imageAlt}
                    onError={() => setImageError(true)}
                    crossOrigin={"anonymous"}
                />
            )}
        </div>
    );
};

export default ImageWithFallback;
