import React from "react";
import "../aivr.scss";
import LoadingOverlay from "./util/LoadingOverlay";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { toggleMegasearch, updateWindowDimensions } from "redux/actions";
import InvalidShareLinkComponent from "./InvalidShareLinkComponent";
import { Redirect, withRouter } from "react-router-dom";
import Measure from "react-measure";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import V1Layout from "./layouts/v1/V1Layout";
import V2Layout from "./layouts/v2/V2Layout";
import DriverTrainingLayout from "./layouts/DriverTraining/DriverTrainingLayout";
import Minimal from "./layouts/Minimal/Minimal";
import Widget from "./layouts/Widget/Widget";
import { logout, wsStatusPoll, getWorkspaceUsers, targetResource, changeDashboard, receiveLoginFilters } from "redux/actions";
import PatrolNavigation from "./SchemaInterface/PatrolNavigation";
import DliDebugComponent from "./DliDebugComponent";
import OBCSpinner from "./util/OBC";
import MegaSearchLayout from "./layouts/MegaSearch/MegaSearchLayout";
class App extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
        this.websocketStatusPoll = null;
    }

    componentDidMount() {
        this.websocketStatusPoll = setInterval(() => {
            this.props.dispatch(wsStatusPoll());
        }, 1000);

        if (!isMobile) {
            this.props.dispatch(getWorkspaceUsers());
        }

        window.addEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (e) => {
        if (e.keyCode === 75 && (e.metaKey || e.ctrlKey)) {
            this.props.dispatch(toggleMegasearch(!this.props.megasearchOpen));
            e.preventDefault();
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.workspaceMemberPoll);
        clearInterval(this.websocketStatusPoll);
        window.removeEventListener("keydown", this.onKeyDown);
    };

    dismissTargetResource = () => {
        this.props.history.replace("", null);
        this.props.dispatch(targetResource(null));
    };

    showANPRWindow = () => {
        this.setState({
            anprWindowVisible: true,
        });
    };

    closeANPRWindow = () => {
        this.setState({
            anprWindowVisible: false,
        });
    };

    showHelpDetails = () => {
        this.setState({
            helpDetailsVisible: true,
        });
    };

    hideHelpDetails = () => {
        this.setState({
            helpDetailsVisible: false,
        });
    };

    showUserDetails = () => {
        this.setState({
            userDetailsVisible: true,
        });
    };

    hideUserDetails = () => {
        this.setState({
            userDetailsVisible: false,
        });
    };

    showExportDrawer = () => {
        this.setState({
            exportDrawerVisible: true,
        });
    };

    closeExportDrawer = () => {
        this.setState({
            exportDrawerVisible: false,
        });
    };

    logout = () => {
        this.hideUserDetails();
        this.props.dispatch(logout());
        //let baseURL = window.location.href.replace(/\?.*/, '');
        //window.location.replace(baseURL);
    };

    switchDashboard = () => {
        this.hideUserDetails();
        this.props.dispatch(changeDashboard());
    };

    showChangePasswordDialog = () => {
        this.setState({
            userDetailsVisible: false,
            changePasswordDialogVisible: true,
        });
    };

    showReportProblemDialog = () => {
        this.setState({
            userDetailsVisible: false,
            reportProblemVisible: true,
        });
    };

    hideReportProblemDialog = () => {
        this.setState({
            userDetailsVisible: false,
            reportProblemVisible: false,
        });
    };

    hideChangePasswordDialog = () => {
        this.setState({
            changePasswordDialogVisible: false,
        });
    };

    showUserSettingsDialog = () => {
        this.setState({
            userSettingsDialogVisible: true,
            userDetailsVisible: false,
        });
    };

    hideUserSettingsDialog = () => {
        this.setState({
            userSettingsDialogVisible: false,
        });
    };

    render() {
        if (this.props.shareLink) {
            if (!this.props.shareLinkDetails) {
                return <LoadingOverlay loading={true} />;
            } else if (!this.props.shareLinkDetails.sessionID) {
                return <InvalidShareLinkComponent />;
            }
        } else if (!this.props.access_token) {
            return <Redirect to={{ pathname: "/login" }} />;
        } else if (isMobile && !this.props.requestedDesktop) {
            let redirect = { pathname: "/mobile" };

            if (this.props.location.state && this.props.location.state.from) {
                let pathname = `/mobile${this.props.location.state.from.pathname}`;
                redirect = { pathname: pathname };
            }
            return <Redirect to={redirect} />;
        }

        return (
            <>
                {((!this.props.currentDashboard && !this.props.shareLinkDetails) || this.props.loading) && <LoadingOverlay loading={true} />}
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.props.dispatch(updateWindowDimensions(contentRect.bounds));
                    }}>
                    {({ measureRef }) => {
                        if (this.props.layoutType === "V1") {
                            return <V1Layout measureRef={measureRef} />;
                        } else if (this.props.layoutType === "V2") {
                            return <V2Layout />;
                        } else if (this.props.layoutType === "driver_training" && !this.props.adminPermission) {
                            return <DriverTrainingLayout />;
                        } else if (this.props.layoutType === "route_patrols") {
                            return <PatrolNavigation />;
                        } else if (this.props.layoutType === "mega_search") {
                            return <MegaSearchLayout />;
                        } else if (this.props.layoutType === "dli") {
                            return <DliDebugComponent />;
                        } else if (this.props.layoutType === "minimal_layout") {
                            return <Minimal measureRef={measureRef} />;
                        } else if (this.props.layoutType === "widget_layout") {
                            return <Widget measureRef={measureRef} />;
                        } else if (this.props.layoutType) {
                            return <V1Layout measureRef={measureRef} />;
                        } else if (this.props.shareLinkDetails) {
                            return <V1Layout measureRef={measureRef} />;
                        } else {
                            return null;
                        }
                    }}
                </Measure>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    // console.log("debug2 current dashboard from app", currentDashboard)

    return {
        shareLink: state.shareLink,
        shareLinkDetails: state.shareLinkDetails,
        access_token: state.access_token,
        requestedDesktop: state.requestedDesktop,
        currentDashboard,
        windowDimensions: state.windowDimensions,
        layoutType: currentDashboard?.workspace_layout,
        loading: false,
        loginDataFilters: state.loginDataFilters,
        adminPermission: !!state.permissions.admin,
        megasearchOpen: state.megasearch.open,
    };
};

export default connect(mapStateToProps)(withRouter(App));
