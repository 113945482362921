import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DraggableWrapper from "../../display/image/DraggableWrapper";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faLock, faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { selectAnnotation, setPatrolLocation, updateInspectionAnnotation, deleteInspectionAnnotation } from "../../../redux/actions/index";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import { Tooltip } from "antd";
import OBCSpinner from "../../util/OBC";
import { calculateOffsettedPatrolImage } from "../../util/PlaylistUtils";

const EMPTY_OBJ = {};
const EMPTY_ARR = [];

const annotationsSelector = (state) => state.railInspection.annotations.data;
const userNameSelector = (state) => state.userDetails.userConfig.name;
const currentAnnotationSelected = (state) => state.railInspection.selectedAnnotation;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const configObjectSelector = (state) => {
    const railInspectConfig = _.get(state, ["railInspection", "railInspectionImageConfig"], false);
    if (!_.isEmpty(railInspectConfig)) {
        return railInspectConfig;
    } else {
        return EMPTY_OBJ;
    }
};
const railDataObjectSelector = (state) => {
    return _.get(state, ["schemaInterface", "images"], EMPTY_ARR);
};
const railDataLoadedSelector = (state) => {
    return !!state.schemaInterface.imagesLoaded;
};
const allAnnotationsFetchedSelector = (state) => state.railInspection.annotations.allAnnotationsFetched;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const csrfTokenSelector = (state) => state.csrfToken;

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const AnnotationsList = ({ setAnnotationWindowOpen, annotationWindowOpen, setUpdatingID, routePatrol, wrapInDraggable, windowDimensions, closeAction }) => {
    const dispatch = useDispatch();

    const annotations = useSelector(annotationsSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const userName = useSelector(userNameSelector);
    const activeAnnotation = useSelector(currentAnnotationSelected);
    const railImageConfig = useSelector(configObjectSelector);
    const allAnnotationsFetched = useSelector(allAnnotationsFetchedSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const showButtons = useMemo(() => {
        if (!patrolStatus || (patrolStatus && patrolStatus === 1)) {
            return true;
        }
        if (patrolStatus === 2 || patrolStatus === 3) {
            return !!isSupervisor;
        }
    }, [patrolStatus, isSupervisor]);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject;
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const handleOpenUpdateModal = useCallback(
        (id) => {
            setUpdatingID(id);
            setAnnotationWindowOpen(true);
        },
        [setAnnotationWindowOpen, setUpdatingID],
    );

    const handleDelete = useCallback(
        (e, id) => {
            e.stopPropagation();
            dispatch(deleteInspectionAnnotation(id));
        },
        [dispatch],
    );

    const handlePrivate = useCallback(
        (e, id, description, isItemPrivate) => {
            e.stopPropagation();
            dispatch(updateInspectionAnnotation(id, { private: !isItemPrivate }));
        },
        [dispatch],
    );

    const handleAnnotationClick = useCallback(
        (annotation) => {
            if (!routePatrol) {
                dispatch(selectAnnotation(annotation.id));
            } else {
                const annotationImage = calculateOffsettedPatrolImage(annotation, railImageConfig, patrolDirections, railImages);
                if (!annotationImage) {
                    return null;
                }
                const chainValue = parseInt(annotationImage.mwv.mile) * 80 + parseFloat(annotationImage.mwv.yard) / 22;
                const trackID = annotationImage.mwv.trid;
                const elr = annotationImage.mwv.elr;
                const locationObj = {
                    elr: elr,
                    chain: chainValue,
                    trackID: trackID,
                };
                dispatch(setPatrolLocation(locationObj, annotationImage.timestamp));
            }
        },
        [dispatch, railImages, routePatrol, patrolDirections, railImageConfig],
    );

    const content = useMemo(() => {
        let body = (
            <div className="SchemaRuns__Spinner">
                <OBCSpinner
                    size={70}
                    speed={3}
                    colorScheme="mono"
                />
            </div>
        );

        if (allAnnotationsFetched) {
            body = (
                <div className="inspectRail__BookmarkList">
                    {annotations &&
                        railImageConfig.inspection_images &&
                        annotations.map((item, index) => {
                            const image = _.find(railImages, ["timestamp", item.image_timestamp]);
                            const source = railImageConfig.inspection_images[item.image_source];

                            if (_.isNil(source) || _.isNil(image)) {
                                return null;
                            }
                            const deviceName = source.source;
                            let base_path = image.base_path;
                            const new_base_path = base_path.replace("$DEVICE", `${deviceName}`);
                            let imagePath = baseURL + new_base_path + "/" + image.timestamp + ".jpg";

                            if (csrfToken) {
                                imagePath += `?csrf=${csrfToken}`;
                            }

                            const backgroundWidth = (source.img_width / source.src_width) * 90;
                            const backgroundX = (source.src_x / source.src_width) * 90;

                            const style = {
                                backgroundImage: `url(${imagePath})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundPosition: `-${backgroundX}px 0`,
                                backgroundSize: `${backgroundWidth}px 100%`,
                                width: `90px`,
                                height: `100%`,
                            };

                            const annotationType = _.find(annotationTypes, { id: item.annotation_type });

                            const annotationLabel = annotationType ? annotationType.type : "Other";

                            return (
                                <div
                                    key={index}
                                    className={`inspectRail__BookmarkList__Item ${activeAnnotation && activeAnnotation === item.id ? " active" : ""}`}
                                    onClick={() => handleAnnotationClick(item)}>
                                    <div className="inspectRail__BookmarkList__Item__Image">
                                        <div className="inspectRail__BookmarkList__Item__Image-Inner">{image && <div style={style} />}</div>
                                    </div>
                                    <div className="inspectRail__BookmarkList__Item__Inner">
                                        <div className="inspectRail__BookmarkList__Item__Info">
                                            <span>{annotationLabel}</span>
                                            <span className="user">{item.user_name}</span>
                                        </div>
                                        <div className="inspectRail__BookmarkList__Item__Controls">
                                            {userName === item.user_name && showButtons && (
                                                <>
                                                    <button
                                                        disabled={annotationWindowOpen}
                                                        onClick={() => handleOpenUpdateModal(item.id)}>
                                                        <Tooltip title="Edit">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Tooltip>
                                                    </button>
                                                    <button onClick={(e) => handleDelete(e, item.id)}>
                                                        <Tooltip title="Delete">
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Tooltip>
                                                    </button>
                                                    <button
                                                        onClick={(e) => handlePrivate(e, item.id, item.comment, item.private)}
                                                        className={item.private ? "private" : ""}>
                                                        <Tooltip title="Privacy">
                                                            <FontAwesomeIcon icon={item.private ? faLock : faUnlock} />
                                                        </Tooltip>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            );
        }

        if (allAnnotationsFetched && !annotations.length) {
            body = (
                <div className="inspectRail__Detections__Message">
                    <span>No Annotations on this session</span>
                </div>
            );
        }

        return body;
    }, [
        activeAnnotation,
        annotations,
        handleAnnotationClick,
        handleDelete,
        handleOpenUpdateModal,
        handlePrivate,
        annotationWindowOpen,
        railImages,
        userName,
        railImageConfig.inspection_images,
        allAnnotationsFetched,
        annotationTypes,
        showButtons,
    ]);

    if (wrapInDraggable) {
        return (
            <DraggableWrapper
                content={content}
                title="Bookmarks"
                windowDimensions={windowDimensions}
                closeAction={closeAction}
                customDimensions={{ x: "300px", y: "auto" }}
            />
        );
    } else {
        return content;
    }
};

export default AnnotationsList;
