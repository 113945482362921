import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { customAudit } from "redux/actions";
import { addWidget, focusWidget } from "redux/actions/widgetActions";

const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const widgetsSelector = (state) => state.widgets;

export const useOpenWidget = (widget, features) => {
    const wsToken = useSelector(wsTokenSelector);
    const dispatch = useDispatch();
    const widgets = useSelector(widgetsSelector);

    return useCallback(() => {
        if (!widgets.hasOwnProperty(widget)) {
            dispatch(
                customAudit(
                    "widget_opened",
                    {
                        widgetType: widget,
                    },
                    `${widget} widget opened`,
                ),
            );
            dispatch(addWidget(widget));
            window.open(`/widget/${widget}/${wsToken}`, widget, features);
        } else {
            focusWidget(widget);
        }
    }, [dispatch, features, widget, widgets, wsToken]);
};
