import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    customAudit,
    setDetailImageAdjustments,
    setDirectionArrow,
    setDisplayExtraLayers,
    toggleAutoDetailCameraOption,
    togglePatrolDetailModal,
} from "redux/actions";
import DraggableWrapper from "./DraggableWrapper";
import { ReactComponent as LeftOuterButton } from "./RailButtons/LeftOuterButton.svg";
import { ReactComponent as LeftInnerButton } from "./RailButtons/LeftInnerButton.svg";
import { ReactComponent as RightInnerButton } from "./RailButtons/RightInnerButton.svg";
import { ReactComponent as RightOuterButton } from "./RailButtons/RightOuterButton.svg";
import _ from "lodash";
import { Slider, InputNumber, Tooltip, Tag, Checkbox } from "antd";
import { calculatePatrolDeviceConfig } from "components/util/Geometry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt, faSearchMinus, faSearchPlus } from "@fortawesome/pro-regular-svg-icons";
import { faExternalLink, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { faAdjust as lightAdjust, faSun as lightSun, faTh as lightTh } from "@fortawesome/pro-light-svg-icons";
import { faDownload, faSun as solidSun } from "@fortawesome/free-solid-svg-icons";
import { faAdjust as solidAdjust } from "@fortawesome/pro-regular-svg-icons";
import OBCButton from "components/OBC/OBCButton";
import { windowFeaturesString as railDetailWidgetWindowFeaturesString } from "components/Widgets/RailDetailWidget";
import RailImageDisplay from "./RailImageDisplay";
import { addWidget, focusWidget } from "redux/actions/widgetActions";

const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const snapshotModalOpenSelector = (state) => state.railInspection.snapshot.open;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const railImagesSelector = (state) => _.get(state.schemaInterface.sessions, [state.schemaInterface.selectedSession], []);
const sessionSelector = (state) => state.schemaInterface.sessions;
const selectedSessionSelector = (state) => state.schemaInterface.selectedSession;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const adjustmentsSelector = (state) => state.railInspection.detailImageAdjustments;
const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const sidekickTypesSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
};
const widgetSidekickTypesSelector = (state) => {
    const workspace_id = _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "workspace_id"], 0);
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === workspace_id);
    return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
};
const widgetsSelector = (state) => state.widgets;
const autoCameraSelectionEnabledSelector = (state) => state.railInspection.autoDetailCameraSelectionActive;
const positionLeftBiasedSelector = (state) => state.railInspection.positionLeftBiased;

const BUTTON_ICON_MAP = {
    left_outer: <LeftOuterButton className="Icon" />,
    left_inner: <LeftInnerButton className="Icon" />,
    right_inner: <RightInnerButton className="Icon" />,
    right_outer: <RightOuterButton className="Icon" />,
};

const FLIPPED_ICON_MAP = {
    right_outer: <LeftOuterButton className="Icon" />,
    right_inner: <LeftInnerButton className="Icon" />,
    left_inner: <RightInnerButton className="Icon" />,
    left_outer: <RightOuterButton className="Icon" />,
};

const MAX_ZOOM = 7;

const WIDGET = "rail-detail";

const RailInspectionExtraDisplay = ({
    syncOffset,
    setSyncOffset,
    windowDimensions,
    labelWindowOpen,
    annotationWindowOpen,
    isPatrol,
    isWidget,
    widgetRailOption,
}) => {
    const dispatch = useDispatch();
    const widgets = useSelector(widgetsSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const railImages = useSelector(railImagesSelector);
    const sessions = useSelector(sessionSelector);
    const selectedSession = useSelector(selectedSessionSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const currentAdjustments = useSelector(adjustmentsSelector);
    const wsToken = useSelector(wsTokenSelector);
    const sidekickTypes = useSelector(isWidget ? widgetSidekickTypesSelector : sidekickTypesSelector);
    const [triggerSnapshot, setTriggerSnapshot] = useState(false);

    const imageConfigToUse = useMemo(() => {
        if (isPatrol) {
            return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
        } else {
            return railImageConfigObject;
        }
    }, [isPatrol, railImageConfigObject, railImageConfigs, railImages]);

    const snapshotModalOpen = useSelector(snapshotModalOpenSelector);
    const autoCameraSelectionEnabled = useSelector(autoCameraSelectionEnabledSelector);
    const isPositionLeftBiased = useSelector(positionLeftBiasedSelector);

    const [selectedDevice, setSelectedDevice] = useState([]);
    const [zoom, setZoom] = useState(1);
    const [showGrid, setShowGrid] = useState(false);

    const currentSession = useMemo(() => {
        if (selectedSession > -1 && sessions.length) {
            const currentSessionImages = sessions[selectedSession];
            const sessionStartTimestamp = currentSessionImages[0].timestamp / 1000;

            let nearestDirectionKey = null;
            const directionKeys = Object.keys(patrolDirections);
            const sortedDirectionKeys = directionKeys.map((ts) => parseInt(ts));
            sortedDirectionKeys.sort(function (a, b) {
                return a - b;
            });
            sortedDirectionKeys.forEach((directionTimestamp) => {
                if (sessionStartTimestamp >= directionTimestamp) {
                    nearestDirectionKey = directionTimestamp;
                }
            });
            if (nearestDirectionKey) {
                return patrolDirections[nearestDirectionKey];
            }
        }
        return null;
    }, [patrolDirections, selectedSession, sessions]);

    const sessionIsBackward = useMemo(() => {
        if (isPatrol) {
            if (currentSession.direction) {
                return currentSession.direction === "Backward";
            }
            return false;
        }

        return railImageConfigObject ? railImageConfigObject.imageryIsBackward : false;
    }, [isPatrol, railImageConfigObject, currentSession]);

    const railOptions = useMemo(() => {
        if (!imageConfigToUse || !imageConfigToUse.inspection_images) {
            return [];
        }
        const options = imageConfigToUse.inspection_images.filter((obj) => obj.showInModal);
        if (isPatrol && sessionIsBackward) {
            options.reverse();
        }
        return options;
    }, [imageConfigToUse, isPatrol, sessionIsBackward]);

    const onToggleButtonClick = useCallback(
        (device) => {
            setSelectedDevice([device]);
            dispatch(setDirectionArrow(device.includes("left")));
            setZoom(1);
        },
        [dispatch],
    );

    useEffect(() => {
        if (railOptions && railOptions.length) {
            setSelectedDevice([railOptions[0].source]);
        }
    }, [railOptions]);

    const onKeyDown = useCallback(
        (e) => {
            if (annotationWindowOpen || labelWindowOpen || snapshotModalOpen) {
                return;
            }

            const key = e.key;
            const numberPressed = parseInt(key);
            const railOption = railOptions[numberPressed - 1];
            const isPositionBiased =
                autoCameraSelectionEnabled && railOption && (isPositionLeftBiased ? railOption.source.includes("right") : railOption.source.includes("left"));

            if (numberPressed <= railOptions.length && numberPressed > 0 && !isPositionBiased) {
                onToggleButtonClick(railOption.source);
            }
        },
        [annotationWindowOpen, labelWindowOpen, onToggleButtonClick, railOptions, snapshotModalOpen, autoCameraSelectionEnabled, isPositionLeftBiased],
    );

    useEffect(() => {
        if (!isWidget && railOptions && railOptions.length) {
            document.addEventListener("keydown", onKeyDown);

            return () => {
                document.removeEventListener("keydown", onKeyDown);
            };
        }
    }, [onKeyDown, railOptions]);

    useEffect(() => {
        if (autoCameraSelectionEnabled && selectedDevice.length > 0) {
            if (isPositionLeftBiased && selectedDevice[0].includes("right")) {
                onToggleButtonClick(selectedDevice[0].replace("right", "left"));
            } else if (!isPositionLeftBiased && selectedDevice[0].includes("left")) {
                onToggleButtonClick(selectedDevice[0].replace("left", "right"));
            }
        }
    }, [autoCameraSelectionEnabled, isPositionLeftBiased]);

    const toggleButtons = () => {
        let iconMap = BUTTON_ICON_MAP;

        if (isPatrol && sessionIsBackward) {
            iconMap = FLIPPED_ICON_MAP;
        }
        return railOptions.map((option, idx) => {
            const isPositionBiased = isPositionLeftBiased ? option.source.includes("right") : option.source.includes("left");
            const disabled = autoCameraSelectionEnabled && isPositionBiased;

            return (
                <div
                    key={option.name}
                    className={"ToggleOption" + (selectedDevice.includes(option.source) ? " Selected" : "") + (disabled ? " disabled" : "")}
                    onClick={() => !disabled && onToggleButtonClick(option.source)}>
                    <span className="ToggleShortcut">{idx + 1}</span>
                    <div>{iconMap[option.source]}</div>
                </div>
            );
        });
    };

    const selectedConfigObject = useMemo(() => {
        const configObj = _.find(railOptions, { source: isWidget && widgetRailOption ? widgetRailOption.source : selectedDevice[0] });
        return configObj;
    }, [railOptions, selectedDevice, widgetRailOption, isWidget]);

    const closeAction = () => {
        dispatch(setDisplayExtraLayers(false));
        dispatch(togglePatrolDetailModal(false));
        dispatch(setDirectionArrow(true));
        dispatch(toggleAutoDetailCameraOption(false));
    };

    const imageAdjustmentGroups = useMemo(() => {
        const groups = [];

        if (imageConfigToUse && imageConfigToUse.inspection_images && imageConfigToUse.inspection_images.length) {
            _.map(imageConfigToUse.inspection_images, (image) => {
                if (!image.primary) {
                    groups.push(image);
                }
            });
        }

        return groups;
    }, [imageConfigToUse]);

    const initialiseAdjustments = useCallback(() => {
        let adjustments = {};

        imageAdjustmentGroups.forEach((group) => {
            adjustments[group.name] = {};
            if (currentAdjustments[group.name]) {
                adjustments[group.name] = currentAdjustments[group.name];
            } else {
                group.images &&
                    group.images.forEach((item) => {
                        adjustments[group.name][item.name] = {
                            brightness: item.default_brightness || 0,
                            contrast: 0,
                        };
                    });
            }
        });

        dispatch(setDetailImageAdjustments(adjustments));
    }, [dispatch, imageAdjustmentGroups, currentAdjustments]);

    const handleChange = (val, updatePath) => {
        let adj = _.cloneDeep(currentAdjustments);
        adj = _.set(adj, updatePath, val);
        dispatch(setDetailImageAdjustments(adj));
    };

    const selectedRailImageName = useMemo(() => {
        return isWidget && widgetRailOption
            ? widgetRailOption.name
            : _.get(
                  _.find(imageAdjustmentGroups, (image) => image.source === _.get(selectedDevice, [0], -1)),
                  "name",
              );
    }, [imageAdjustmentGroups, selectedDevice, isWidget, widgetRailOption]);

    const currentRailImageAdjustments = useMemo(() => {
        return _.get(currentAdjustments, [selectedRailImageName], {});
    }, [currentAdjustments, selectedRailImageName]);

    useEffect(() => {
        if (imageAdjustmentGroups) {
            initialiseAdjustments();
        }
    }, [imageAdjustmentGroups]);

    const handleAddButton = (updatePath) => {
        const currentVal = _.get(currentAdjustments, updatePath, 0);
        const newVal = currentVal + 0.1;
        if (newVal <= 1) {
            handleChange(newVal, updatePath);
        }
    };

    const handleSubtractButton = (updatePath) => {
        const currentVal = _.get(currentAdjustments, updatePath, 0);
        const newVal = currentVal - 0.1;
        if (newVal >= -1) {
            handleChange(newVal, updatePath);
        }
    };

    const handleResetButton = (updatePath) => {
        if (updatePath === "zoom") {
            setZoom(1);
        } else {
            handleChange(0, updatePath);
        }
    };

    const handleAddZoomButton = () => {
        if (zoom <= MAX_ZOOM - 0.5) {
            setZoom(zoom + 0.5);
        } else if (zoom < MAX_ZOOM && zoom > MAX_ZOOM - 0.5) {
            // if scroller was used and value is 6.8 set zoom to MAX_ZOOM
            setZoom(MAX_ZOOM);
        }
    };

    const handleSubtractZoomButton = () => {
        if (zoom >= 1.5) {
            setZoom(zoom - 0.5);
        } else if (zoom < 1.5 && zoom > 1) {
            // if scroller was used and value is 1.3 set zoom to 1
            setZoom(1);
        }
    };

    const isDefaultValue = (updatePath, defaultVal) => {
        let currentVal = _.get(currentAdjustments, updatePath, defaultVal);
        if (updatePath === "zoom") {
            currentVal = zoom;
        }
        return currentVal === defaultVal;
    };

    const triggerDetailImageSnapshot = () => {
        setTriggerSnapshot(true);
    };

    const popoutWidget = useCallback(() => {
        if (!widgets.hasOwnProperty(WIDGET)) {
            dispatch(
                customAudit(
                    "widget_opened",
                    {
                        widgetType: WIDGET,
                    },
                    `${WIDGET} widget opened`,
                ),
            );
            dispatch(addWidget(WIDGET));
            window.open(`/widget/${WIDGET}/${wsToken}?selected=${selectedDevice}`, WIDGET, railDetailWidgetWindowFeaturesString);
        } else {
            focusWidget(WIDGET);
        }
    }, [dispatch, selectedDevice, widgets, wsToken]);

    const content = (
        <div className="ExtraInspectionDisplayContainer">
            <div className="ExtraInspectionDisplayHeader">
                <div className="OptionContainer--left">
                    <Tooltip
                        title="Download Detail Image"
                        placement="bottomLeft">
                        <OBCButton
                            style={{ width: 30 }}
                            icon={faDownload}
                            onClick={triggerDetailImageSnapshot}
                        />
                    </Tooltip>
                    {!isPatrol && sidekickTypes && sidekickTypes.includes("Detail Rail") && !isWidget && (
                        <Tooltip
                            title="Pop-out rail detail widget"
                            placement="bottomLeft">
                            <OBCButton
                                style={{ width: 30 }}
                                icon={faExternalLink}
                                onClick={popoutWidget}
                            />
                        </Tooltip>
                    )}
                    {syncOffset !== undefined ? (
                        <div className="OutOfSyncContainer">
                            <h4>
                                Out of sync?
                                <Tooltip
                                    placement="right"
                                    title="If the detail images do not align with the primary inspection images, adjust this value to compensate.">
                                    <FontAwesomeIcon
                                        style={{ marginLeft: "0.3em", opacity: 0.8 }}
                                        icon={faInfoCircle}
                                    />
                                </Tooltip>
                            </h4>
                            <InputNumber
                                onChange={setSyncOffset}
                                value={syncOffset}
                            />
                        </div>
                    ) : null}

                    <Tooltip
                        title="Toggle Grid"
                        placement="bottom">
                        <Tag.CheckableTag
                            onChange={() => setShowGrid((prev) => !prev)}
                            checked={showGrid}>
                            <FontAwesomeIcon
                                icon={lightTh}
                                size="lg"
                            />
                        </Tag.CheckableTag>
                    </Tooltip>
                </div>

                {!widgetRailOption && (
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Checkbox
                            style={{ position: "absolute", color: "#fff", marginTop: -28 }}
                            checked={autoCameraSelectionEnabled}
                            onChange={() => dispatch(toggleAutoDetailCameraOption(!autoCameraSelectionEnabled))}>
                            Automatic camera selection
                        </Checkbox>
                        <div className="RailToggleContainer">{!widgetRailOption && toggleButtons()}</div>
                    </div>
                )}

                {isWidget && widgetRailOption && (
                    <div className="RailToggleContainer">
                        <div className="ToggleOption">
                            <span className="ToggleShortcut">{widgetRailOption.sourceIndex + 1}</span>
                            <div className="ToggleButton">{BUTTON_ICON_MAP[widgetRailOption.source]}</div>
                        </div>
                    </div>
                )}

                <div className="OptionContainer">
                    {/* zoom */}
                    <div className="OptionItem">
                        <div
                            className="OptionItemIconLeft"
                            onClick={handleSubtractZoomButton}
                            title="Zoom Out">
                            <FontAwesomeIcon icon={faSearchMinus} />
                        </div>
                        <div className="OptionItemControl">
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={1}
                                max={MAX_ZOOM}
                                onChange={setZoom}
                                value={zoom}
                            />
                        </div>
                        <div
                            className="OptionItemIconRight"
                            onClick={handleAddZoomButton}
                            title="Zoom In">
                            <FontAwesomeIcon icon={faSearchPlus} />
                        </div>
                        <div
                            className={`OptionItemIconRight ${isDefaultValue("zoom", 1) ? "NotActive" : "Active"}`}
                            title="Reset Zoom"
                            onClick={() => handleResetButton("zoom")}>
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </div>
                    </div>

                    {/* brightness */}
                    <div className="OptionItem">
                        <div
                            className="OptionItemIconLeft"
                            onClick={() => handleSubtractButton(`${selectedRailImageName}.brightness`)}
                            title="Brightness Down">
                            <FontAwesomeIcon icon={lightSun} />
                        </div>
                        <div className="OptionItemControl">
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={-1}
                                max={1}
                                onChange={(e) => handleChange(e, `${selectedRailImageName}.brightness`)}
                                value={_.get(currentRailImageAdjustments, "brightness", 0)}
                            />
                        </div>
                        <div
                            className="OptionItemIconRight"
                            onClick={() => handleAddButton(`${selectedRailImageName}.brightness`)}
                            title="Brightness Up">
                            <FontAwesomeIcon icon={solidSun} />
                        </div>
                        <div
                            className={`OptionItemIconRight ${isDefaultValue(`${selectedRailImageName}.brightness`, 0) ? "NotActive" : "Active"}`}
                            onClick={() => handleResetButton(`${selectedRailImageName}.brightness`)}
                            title="Reset Brightness">
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </div>
                    </div>

                    {/* contrast */}
                    <div className="OptionItem">
                        <div
                            className="OptionItemIconLeft"
                            onClick={() => handleSubtractButton(`${selectedRailImageName}.contrast`)}
                            title="Contrast Down">
                            <FontAwesomeIcon icon={lightAdjust} />
                        </div>
                        <div className="OptionItemControl">
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={-1}
                                max={1}
                                onChange={(e) => handleChange(e, `${selectedRailImageName}.contrast`)}
                                value={_.get(currentRailImageAdjustments, "contrast", 0)}
                            />
                        </div>
                        <div
                            className="OptionItemIconRight"
                            onClick={() => handleAddButton(`${selectedRailImageName}.contrast`)}
                            title="Contrast Up">
                            <FontAwesomeIcon icon={solidAdjust} />
                        </div>
                        <div
                            className={`OptionItemIconRight ${isDefaultValue(`${selectedRailImageName}.contrast`, 0) ? "NotActive" : "Active"}`}
                            onClick={() => handleResetButton(`${selectedRailImageName}.contrast`)}
                            title="Reset Contrast">
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </div>
                    </div>
                    {/* faSun faAdjust */}
                </div>
            </div>

            <div className="ImagesContainer">
                <RailImageDisplay
                    sessionIsBackward={sessionIsBackward}
                    isPatrol={isPatrol}
                    zoom={zoom}
                    setZoom={setZoom}
                    syncOffset={syncOffset}
                    imageConfigItem={selectedConfigObject ? selectedConfigObject : {}}
                    triggerSnapshot={triggerSnapshot}
                    setTriggerSnapshot={setTriggerSnapshot}
                    isWidget={isWidget}
                    showGrid={showGrid}
                />
            </div>
        </div>
    );

    const title = (
        <div className="inspectRail__BookmarkList__Title">
            <p>Rail Detail Images</p>
        </div>
    );

    return isWidget ? (
        content
    ) : (
        <DraggableWrapper
            title={title}
            customDimensions={{
                width: 1000,
                height: 375,
            }}
            customLocation={{ x: 5, y: 5 }}
            windowDimensions={windowDimensions}
            content={content}
            closeAction={closeAction}
            extraStyles={{ paddingBottom: 0 }}
        />
    );
};

export default RailInspectionExtraDisplay;
