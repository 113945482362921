import { jsonPostV2 } from "./apiUtils";
import _ from "lodash";

export const SET_UPLOAD_TOKENS_LIST = "SET_UPLOAD_TOKENS_LIST";
export const UPLOAD_TOKENS_LIST_LOADING = "UPLOAD_TOKENS_LIST_LOADING";
export const SET_MEDIA_FILTER = "SET_MEDIA_FILTER";
export const SET_MEDIA_UPLOAD_LIST = "SET_MEDIA_UPLOAD_LIST";
export const MEDIA_UPLOADS_LIST_LOADING = "MEDIA_UPLOADS_LIST_LOADING";
export const SET_SELECTED_MEDIA_UPLOAD_ID = "SET_SELECTED_MEDIA_UPLOAD_ID";
export const SET_SELECTED_MEDIA_UPLOAD_INDEX = "SET_SELECTED_MEDIA_UPLOAD_INDEX";
export const GO_TO_POSITION = "GO_TO_POSITION";
export const PREVIEW_MODAL_OPEN = "PREVIEW_MODAL_OPEN";

function setUploadTokensList(list) {
    return {
        type: SET_UPLOAD_TOKENS_LIST,
        list,
    };
}

function uploadTokensListLoading(state) {
    return {
        type: UPLOAD_TOKENS_LIST_LOADING,
        state,
    };
}

export function setMediaFilter(filters) {
    return {
        type: SET_MEDIA_FILTER,
        filters,
    };
}

function setMediaUploadsList(mediaUploadsList) {
    return {
        type: SET_MEDIA_UPLOAD_LIST,
        mediaUploadsList,
    };
}

function mediaUploadsListLoading(state) {
    return {
        type: MEDIA_UPLOADS_LIST_LOADING,
        state,
    };
}

export function setSelectedMediaUpload(mediaId) {
    return {
        type: SET_SELECTED_MEDIA_UPLOAD_ID,
        mediaId,
    };
}

export function setSelectedMediaUploadIndex(mediaIndex) {
    return {
        type: SET_SELECTED_MEDIA_UPLOAD_INDEX,
        mediaIndex,
    };
}

export function setPreveiwModalOpen(modalOpen) {
    return {
        type: PREVIEW_MODAL_OPEN,
        modalOpen: modalOpen,
    };
}

export function goToMapPosition(coords) {
    return {
        type: GO_TO_POSITION,
        coords,
    };
}

export function getWorkspaceNameByUploadToken(upload_token, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_workspace_details",
            upload_token,
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.workspace_name) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    callback({ workspace_name: null });
                }
            });
    };
}

export function uploadUserMedia(upload_token, upload_name, upload_description = null, upload_media, upload_location, upload_tags, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "upload_media",
            upload_token,
            upload_name,
            upload_description,
            upload_media,
            upload_location,
            upload_tags,
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback({
                            success: true,
                            upload_links: response.upload_links,
                        });
                    } else {
                        callback({
                            success: false,
                        });
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    callback({
                        success: false,
                    });
                }
            });
    };
}

export function confirmUserMediaUploaded(upload_token, upload_paths, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "confirm_uploaded_media",
            upload_token,
            upload_paths,
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    callback({ success: false });
                }
            });
    };
}

export function getMediaUploadTokens(quiet = false) {
    return (dispatch, getState) => {
        if (!quiet) {
            dispatch(uploadTokensListLoading(true));
        }
        let postBody = {
            action: "get_tokens",
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.token_list) {
                    dispatch(setUploadTokensList(response.token_list));
                    dispatch(
                        setMediaFilter({
                            owner: "mine",
                            date: {
                                from: null,
                                to: null,
                            },
                            status: 1,
                            searchQurey: null,
                            sortBy: "last_created",
                        }),
                    );
                }
                dispatch(uploadTokensListLoading(false));
            })
            .catch(() => {
                dispatch(uploadTokensListLoading(false));
            });
    };
}

export function getMediaUploads(quiet = false) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_media",
        };
        if (!quiet) {
            dispatch(mediaUploadsListLoading(true));
            dispatch(setSelectedMediaUpload(-1));
            dispatch(setSelectedMediaUploadIndex(-1));
        }

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.media_list) {
                    dispatch(setMediaUploadsList(response.media_list));
                }
                dispatch(mediaUploadsListLoading(false));
            })
            .catch(() => {
                dispatch(mediaUploadsListLoading(false));
            });
    };
}

export function createNewMediaUploadToken(description, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "create_token",
            description,
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    callback({ success: false });
                }
            });
    };
}

export function revokeMediaUploadToken(token, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "revoke_token",
            token,
        };

        const url = "/media";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    callback({ success: false });
                }
            });
    };
}

export function reviewUploadedMedia(uploadId, reviewStatus, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "approve_upload",
            uploadId,
            reviewStatus,
        };

        const url = "/media";

        let oldList = _.cloneDeep(getState().mediaUploads.mediaUploadsList);
        let newList = _.cloneDeep(oldList);

        const updatingIndex = _.findIndex(newList, (upload) => parseInt(upload.id) === parseInt(uploadId));
        if (updatingIndex > -1) {
            if (reviewStatus) {
                newList[updatingIndex].status = 1;
            } else {
                newList.splice(updatingIndex, 1);
            }
        }
        dispatch(setMediaUploadsList(newList));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    dispatch(setMediaUploadsList(oldList));
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                if (callback) {
                    dispatch(setMediaUploadsList(oldList));
                    callback({ success: false });
                }
            });
    };
}

export function removeUploadedMedia(uploadId, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "archive_upload",
            uploadId,
        };

        const url = "/media";

        let oldList = _.cloneDeep(getState().mediaUploads.mediaUploadsList);
        let newList = _.cloneDeep(oldList);

        const updatingIndex = _.findIndex(newList, (upload) => parseInt(upload.id) === parseInt(uploadId));
        if (updatingIndex > -1) {
            newList.splice(updatingIndex, 1);
        }
        dispatch(setMediaUploadsList(newList));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    dispatch(setMediaUploadsList(oldList));
                    if (callback) {
                        callback(response);
                    }
                }
            })
            .catch(() => {
                dispatch(setMediaUploadsList(oldList));
                if (callback) {
                    callback({ success: false });
                }
            });
    };
}
