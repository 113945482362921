import React, { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../images/new-branding-2.0/aivr-hybrid.svg";
import _, { set } from "lodash";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faBug,
    faCalendar,
    faCog,
    faCogs,
    faGlobeAsia,
    faKey,
    faMobileAlt,
    faQuestionCircle,
    faRoute,
    faSignInAlt,
    faSignOutAlt,
    faUser,
    faUserCog,
    faUsers,
    faCloudUploadAlt,
    faFileExport,
    faPhotoVideo,
    faObjectUngroup,
    faVideoPlus,
    faSearch,
    faLightbulb,
} from "@fortawesome/pro-light-svg-icons";
import { ReactComponent as CCTVIcon } from "../icons/CCTVNavbarIcon.svg";
import { PopupModal } from "react-calendly";
import { Drawer, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import DashboardLogo from "./DashboardLogoComponent";
import moment from "moment";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import {
    toggleDesktop,
    getWorkspaceUsers,
    changeDashboard,
    swapDashboard,
    refresh,
    shareLinkDetails,
    getMediaUploadTokens,
    toggleMegasearch,
} from "../redux/actions";
import ChangePasswordDialog from "./ChangePasswordDialog";
import UserSettingsDialog from "./UserSettingsDialog";
import ExportDrawer from "./ExportDrawer";
import UploadManagerDrawer from "./drawers/UploadManagerDrawer";
import NewUserModal from "./NewUserModal";
import DataCaptureDialog from "./Dialogs/DataCaptureDialog";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import CCTVListDrawer from "./CCTVListDrawer";
import CCTVCameraModal from "./CCTVCameraModal";
import { customAudit } from "../redux/actions";
import GenericDialog from "./GenericDialog";
import SideKickTippy from "./NavBar/SideKickTippy";

const dashboardsSelector = (state) => state.dashboards;
const accessTokenSelector = (state) => state.access_token;
const shareLinkSelector = (state) => state.shareLink;
const userDetailsSelector = (state) => state.userDetails;
const workspaceUsersSelector = (state) => state.team.users;
const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const widgetDataSelector = (state) => state.widgetData;
const adminPermissionSelector = (state) => !!state.permissions.admin;
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;

const AppNavbar = ({ startTour, logout }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const activeWorkspaceRef = useRef(null);

    const dashboards = useSelector(dashboardsSelector);
    const accessToken = useSelector(accessTokenSelector);
    const shareLink = useSelector(shareLinkSelector);
    const userDetails = useSelector(userDetailsSelector);
    const workspaceUsers = useSelector(workspaceUsersSelector);
    const wsToken = useSelector(wsTokenSelector);
    const widgetData = useSelector(widgetDataSelector);
    const adminPermission = useSelector(adminPermissionSelector);
    const superAdmin = useSelector(superAdminSelector);

    const [genericDialogVisible, setGenericDialogVisible] = useState(null);
    const [dataCaptureRequestVisible, setDataCaptureRequest] = useState(false);
    const [userSettingsDialogVisible, setUserSettingsDialogVisible] = useState(false);
    const [changePasswordDialogVisible, setChangePasswordDialogVisible] = useState(false);
    const [displayNewUserModal, setDisplayNewUserModal] = useState(false);
    const [exportDrawerVisible, setExportDrawerVisible] = useState(false);
    const [uploadManagerDrawerOpen, setUploadManagerDrawerOpen] = useState(false);
    const [activeDrawer, setActiveDrawer] = useState(null);
    const [activeDrawerInner, setActiveDrawerInner] = useState(null);
    const [isCalendyOpen, setIsCalendyOpen] = useState(false);
    const [calendyUrl, setCalendyUrl] = useState("https://calendly.com/aivr/training");

    const [cctvDrawerVisible, setCCTVDrawerVisible] = useState(false);
    const [cctvModalVisible, setCCTVModalVisible] = useState(false);
    const [activeIndexInCCTVDrawer, setActiveIndexInCCTVDrawer] = useState(0);
    const cameras = useSelector((state) => state.cctv.CCTVMarkers.cameras);

    const [cameraInfo, setCameraInfo] = useState(null);

    const switchDashboard = () => {
        dispatch(changeDashboard());
    };

    const navToPatrols = () => {
        history.push("/patrols");
    };

    const scrollToCurrent = () => {
        if (activeWorkspaceRef && activeWorkspaceRef.current) {
            activeWorkspaceRef.current.scrollIntoView({ block: "center" });
        }
    };

    // This useEffect handles some weird styling issues with the drawer. The drawer has to be opened and closed with the width property as opposed to
    // being translated off screen. If it's translated, when it goes off screen, it shifts the layout of the entire page. Using the width property
    // avoids this, but has some issues with elements within the navbar jumping around and generally being ugly until the drawer is done opening. The code
    // below adds an overlay that hides the ugliness until the drawer is done opening.

    useEffect(() => {
        const drawer = document.querySelector(".CCTVListDrawer");
        const overlay = document.querySelector(".DrawerOverlay");

        const handleTransitionEnd = (event) => {
            if (event.propertyName === "width") {
                if (cctvDrawerVisible) {
                    overlay.classList.remove("visible");
                }
            }
        };

        if (drawer) {
            drawer.addEventListener("transitionend", handleTransitionEnd);
        }

        const handleOverlayTransitionEnd = (event) => {
            if (event.propertyName === "opacity" && overlay.classList.contains("visible")) {
                drawer.classList.remove("visible");
                setCCTVDrawerVisible(false);
            }
        };

        if (overlay) {
            overlay.addEventListener("transitionend", handleOverlayTransitionEnd);
        }

        return () => {
            drawer.removeEventListener("transitionend", handleTransitionEnd);
            overlay.removeEventListener("transitionend", handleOverlayTransitionEnd);
        };
    });

    useEffect(() => {
        setTimeout(() => {
            scrollToCurrent();
        }, 100);
    }, [dashboards]);

    useEffect(() => {
        dispatch(getWorkspaceUsers());
    }, [dispatch]);

    const userCount = useMemo(() => {
        if (workspaceUsers && workspaceUsers.length < 100) {
            return workspaceUsers.length;
        } else if (workspaceUsers && workspaceUsers.length > 99) {
            return "99+";
        }
    }, [workspaceUsers]);

    const currentDashboard = useMemo(() => {
        if (dashboards && accessToken) {
            dispatch(getMediaUploadTokens());
            return _.find(dashboards, (dash) => dash.access_token === accessToken);
        }
    }, [dashboards, accessToken]);

    const exportsEnabled = useMemo(() => {
        return _.get(currentDashboard, ["config", "exports_enabled"], true);
    }, [currentDashboard]);

    const mediaUploadsEnabled = useMemo(() => {
        return !!_.get(currentDashboard, ["config", "media_upload_enabled"], false);
    }, [currentDashboard]);

    const sidekickTypes = useMemo(() => {
        if (currentDashboard) {
            return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
        }
    }, [currentDashboard]);

    const renderDashboardSelection = (dashboardList) => {
        if (dashboardList) {
            const alphabeticalList = dashboardList.sort((a, b) => a.description.localeCompare(b.description));

            return _.sortBy(alphabeticalList, (dash) => dash.description.toLowerCase()).map((dashboard) => {
                let isActiveDashboard = false;
                if (accessToken === dashboard.access_token) {
                    isActiveDashboard = true;
                }
                let refToUse = null;
                if (isActiveDashboard) {
                    refToUse = activeWorkspaceRef;
                }
                return (
                    <Tippy
                        key={dashboard.access_token}
                        arrow={true}
                        placement="right"
                        content={
                            <div className="DashboardSwapTooltipContent">
                                <span className="OrganisationName">{dashboard.organisation}</span>
                                <span className="OrganisationDescription">{dashboard.description}</span>
                                <span className="OrganisationDescription long">{dashboard.long_description}</span>
                                {dashboard.isBeta && <span className="BetaIdentifier">Beta</span>}
                            </div>
                        }
                        theme={"aivr"}
                        zIndex="600"
                        delay="100">
                        <div
                            className="DashboardSwapElement"
                            onClick={() => swapDash(dashboard)}
                            ref={refToUse}>
                            <DashboardLogo dashboard={dashboard} />
                            {isActiveDashboard && dashboardList.length > 1 && <div className="ActiveDashboardBadge"></div>}
                        </div>
                    </Tippy>
                );
            });
        } else {
            return null;
        }
    };

    let toolTipTitle = null;
    if (currentDashboard && currentDashboard.processing) {
        if (currentDashboard.processing.sessions) {
            toolTipTitle = "Sessions currently processing";
        } else if (currentDashboard.processing.map) {
            toolTipTitle = "Map segments currently processing";
        }
    }

    const widgetCount = () => {
        if (widgetData.WIDGET) {
            let result = Object.values(widgetData.WIDGET).filter((x) => x.last_updated > moment.now() - 7000);
            return result.length;
        }
        return 0;
    };

    const showChangePasswordDialog = () => {
        setChangePasswordDialogVisible(true);
    };

    const showNewUserModal = () => {
        setDisplayNewUserModal(true);
    };

    const showGenericDialog = (name /*:string*/) => {
        setGenericDialogVisible(name);
    };

    const toggleDataCaptureRequestDialog = () => {
        setDataCaptureRequest(!dataCaptureRequestVisible);
    };

    const hideGenericDialog = () => {
        setGenericDialogVisible(null);
    };

    const hideChangePasswordDialog = () => {
        setChangePasswordDialogVisible(false);
    };

    const hideNewUserModal = () => {
        setDisplayNewUserModal(false);
    };

    const showUserSettingsDialog = () => {
        setUserSettingsDialogVisible(true);
    };

    const hideUserSettingsDialog = () => {
        setUserSettingsDialogVisible(false);
    };

    const goToMobileSite = () => {
        dispatch(toggleDesktop(false));
        history.push("/mobile");
    };

    const showExportDrawer = () => {
        setExportDrawerVisible(true);
    };

    const closeExportDrawer = () => {
        setExportDrawerVisible(false);
    };

    const toggleUploadManagerDrawer = () => {
        setUploadManagerDrawerOpen(!uploadManagerDrawerOpen);
    };

    const toggleCCTVDrawer = () => {
        const overlay = document.querySelector(".DrawerOverlay");

        if (cctvDrawerVisible) {
            overlay.classList.add("visible");
        } else {
            setCCTVDrawerVisible(!cctvDrawerVisible);
        }
    };

    const toggleCCTVModal = () => {
        setCCTVModalVisible(!cctvModalVisible);
    };

    const swapDash = (dashboard) => {
        // on *manual* navigation away from current dashboard, effectively
        // dismiss any previously activated share link so it is not
        // re-activated if we swap back
        dispatch(
            shareLinkDetails({
                session_id: null,
                timestamp: 0,
                enhanced: false,
            }),
        );
        dispatch(swapDashboard(dashboard.access_token));
        dispatch(refresh());
    };

    const organisationDetails = () => {
        let logo = null;
        if (userDetails.branding) {
            logo = (
                <div
                    className={"OrgLogo"}
                    style={{
                        backgroundImage: `url(https://branding${MEMOIZED_DOMAIN_URL}/` + userDetails.branding + "/view_watermark1.png)",
                    }}
                />
            );
        }

        return (
            <div className={"DetailsLine"}>
                <div className={"DashboardDetailsBlock"}>
                    <div className={"DetailsText Description"}>{userDetails.description}</div>
                    <div className={"DetailsLine"}>
                        <FontAwesomeIcon icon={faBuilding} />
                        <div className={"DetailsText Organisation"}>{userDetails.organisation}</div>
                    </div>
                </div>
                {logo}
            </div>
        );
    };

    const canSwitchDashboard = useMemo(() => {
        return dashboards && dashboards.length > 1;
    }, [dashboards]);

    let patrols_enabled_on_workspace = _.get(currentDashboard, ["config", "patrols_enabled"], false);
    let user_has_patrol_access = _.get(userDetails.userConfig, ["patrol_access"], false);
    let patrols_enabled = patrols_enabled_on_workspace === 2 || (!!user_has_patrol_access && !!patrols_enabled_on_workspace);

    return (
        <>
            <PopupModal
                url={calendyUrl}
                prefill={{ email: userDetails.email, name: userDetails.userConfig.name ?? "" }}
                onModalClose={() => setIsCalendyOpen(false)}
                open={isCalendyOpen}
                rootElement={document.getElementById("root")}
            />
            <div className="NavBarContainer">
                <img
                    className="NavBarLogo"
                    src={logo}
                    alt="AIVR Logo"
                    crossOrigin={"anonymous"}
                />
                <div
                    className="DashboardScroller"
                    id="intro-tour-workspace-selector">
                    {renderDashboardSelection(dashboards)}
                </div>
                <div className="NavBarBottom">
                    <div className="SidebarButtonWrap">
                        {(userDetails.userConfig.super_admin || currentDashboard?.isBeta) && (
                            <Tippy
                                arrow={true}
                                placement="right"
                                content="AIVR Search"
                                theme="aivr"
                                zIndex="600"
                                delay="100">
                                <div
                                    className="SidebarButtonContainer"
                                    onClick={() => dispatch(toggleMegasearch(true))}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                    />
                                </div>
                            </Tippy>
                        )}
                        {cameras && cameras.length > 0 ? (
                            <Tippy
                                arrow={true}
                                placement="right"
                                content="CCTV Camera List"
                                maxWidth={"none"}
                                theme="aivr"
                                zIndex="600"
                                delay="100">
                                <div
                                    className={cctvDrawerVisible ? "SidebarButtonContainer active" : "SidebarButtonContainer"}
                                    onClick={() => {
                                        toggleCCTVDrawer();
                                        dispatch(customAudit("cctv_drawer_opened", {}, "CCTV drawer opened from navbar"));
                                        if (cctvModalVisible) {
                                            toggleCCTVModal();
                                        }
                                    }}>
                                    <CCTVIcon className="CCTVNavbarIcon" />
                                </div>
                            </Tippy>
                        ) : null}

                        {!shareLink && patrols_enabled && (
                            <Tippy
                                arrow={true}
                                placement="right"
                                content="BVI Route Patrols"
                                theme="aivr"
                                zIndex="600"
                                delay="100">
                                <div
                                    className="SidebarButtonContainer"
                                    onClick={navToPatrols}>
                                    <FontAwesomeIcon
                                        icon={faRoute}
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                    />
                                </div>
                            </Tippy>
                        )}

                        {((!shareLink && exportsEnabled) || adminPermission) && (
                            <Tippy
                                zIndex="600"
                                arrow={true}
                                placement="right"
                                interactive={true}
                                maxWidth={"none"}
                                theme={"aivr"}
                                hideOnClick={false}
                                onTrigger={() => setActiveDrawer("ImportExport")}
                                onUntrigger={() => setActiveDrawer("")}
                                content={
                                    <div>
                                        {!shareLink && exportsEnabled && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={showExportDrawer}>
                                                <FontAwesomeIcon
                                                    icon={faFileExport}
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Recent Exports</div>
                                            </div>
                                        )}
                                        {adminPermission && mediaUploadsEnabled && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={toggleUploadManagerDrawer}>
                                                <FontAwesomeIcon
                                                    icon={faCloudUploadAlt}
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Upload Media</div>
                                            </div>
                                        )}
                                    </div>
                                }>
                                <div
                                    className={`SidebarButtonContainer ${activeDrawer === "ImportExport" ? "active" : ""}`}
                                    role="button"
                                    aria-label="Import/Export">
                                    <FontAwesomeIcon
                                        icon={faPhotoVideo}
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                    />
                                </div>
                            </Tippy>
                        )}
                        {!shareLink && wsToken && sidekickTypes && !!sidekickTypes.length && (
                            <SideKickTippy
                                activeDrawer={activeDrawer}
                                setActiveDrawer={setActiveDrawer}
                            />
                        )}
                        {!shareLink && (
                            <Tippy
                                zIndex="600"
                                arrow={true}
                                placement="right"
                                interactive={true}
                                maxWidth={"none"}
                                theme={"aivr"}
                                hideOnClick={false}
                                onTrigger={() => setActiveDrawer("Help")}
                                onUntrigger={() => setActiveDrawer("")}
                                content={
                                    <div>
                                        <a
                                            target="_blank"
                                            href={`https://help${MEMOIZED_DOMAIN_URL}/`}
                                            rel="noopener noreferrer">
                                            <div className="UserDetailsPopupButton">
                                                <FontAwesomeIcon
                                                    icon={faBook}
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Help & FAQs</div>
                                            </div>
                                        </a>

                                        <div
                                            className="UserDetailsPopupButton"
                                            onClick={startTour}>
                                            <FontAwesomeIcon
                                                icon={faGlobeAsia}
                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                            />
                                            <div className={"UserDetailsPopupButtonText"}>Take the tour</div>
                                        </div>

                                        <div
                                            className="UserDetailsPopupButton"
                                            onClick={() => showGenericDialog("Report problem")}>
                                            <FontAwesomeIcon
                                                icon={faBug}
                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                            />
                                            <div className={"UserDetailsPopupButtonText"}>Report a problem</div>
                                        </div>

                                        <div
                                            className="UserDetailsPopupButton"
                                            onClick={() => showGenericDialog("Make a suggestion")}>
                                            <FontAwesomeIcon
                                                icon={faLightbulb}
                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                            />
                                            <div className={"UserDetailsPopupButtonText"}>Make a suggestion</div>
                                        </div>

                                        {userDetails.email && ["networkrail.co.uk", "onebigcircle.co.uk"].includes(userDetails.email.split("@")[1]) ? (
                                            <Tippy
                                                zIndex="600"
                                                arrow={true}
                                                placement="right"
                                                interactive={true}
                                                maxWidth={"none"}
                                                theme={"aivr"}
                                                hideOnClick={false}
                                                onTrigger={() => setActiveDrawerInner("Training")}
                                                onUntrigger={() => setActiveDrawerInner("")}
                                                content={
                                                    <>
                                                        <div
                                                            className="UserDetailsPopupButton"
                                                            onClick={() => {
                                                                setIsCalendyOpen(true);
                                                                setCalendyUrl("https://calendly.com/aivr/training");
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={faUser}
                                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                                            />
                                                            <div className={"UserDetailsPopupButtonText"}>Private Session</div>
                                                        </div>

                                                        <div
                                                            className="UserDetailsPopupButton"
                                                            onClick={() => {
                                                                setIsCalendyOpen(true);
                                                                setCalendyUrl("https://calendly.com/aivr/networkrail");
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={faUsers}
                                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                                            />
                                                            <div className={"UserDetailsPopupButtonText"}>Drop-in Session</div>
                                                        </div>
                                                    </>
                                                }>
                                                <div
                                                    className={`UserDetailsPopupButton UserDetailsPopupButton--primary ${activeDrawerInner === "Training" ? "active" : ""}`}>
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        style={{ fontSize: 28, color: "#e8dfff" }}
                                                    />
                                                    <div className={"UserDetailsPopupButtonText"}>Book a training session</div>
                                                </div>
                                            </Tippy>
                                        ) : (
                                            <div
                                                className="UserDetailsPopupButton UserDetailsPopupButton--primary"
                                                onClick={() => {
                                                    setIsCalendyOpen(true);
                                                    setCalendyUrl("https://calendly.com/aivr/training");
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faCalendar}
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Book a training session</div>
                                            </div>
                                        )}
                                        {!!_.get(currentDashboard, ["config", "capture_request"], false) && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={toggleDataCaptureRequestDialog}>
                                                <FontAwesomeIcon
                                                    icon={faVideoPlus}
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Request data capture</div>
                                            </div>
                                        )}
                                    </div>
                                }>
                                <div
                                    className={`SidebarButtonContainer ${activeDrawer === "Help" ? "active" : ""}`}
                                    role="button"
                                    aria-label="Help">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                    />
                                </div>
                            </Tippy>
                        )}
                        {!!shareLink && (
                            <Tooltip
                                title="Log out"
                                placement="right">
                                <div
                                    className="SidebarButtonContainer"
                                    onClick={logout}>
                                    <FontAwesomeIcon
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                        icon={faSignOutAlt}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {!shareLink && (
                            <Tippy
                                placement="right-end"
                                interactive={true}
                                arrow={true}
                                maxWidth={"none"}
                                theme={"aivr"}
                                hideOnClick={false}
                                onTrigger={() => setActiveDrawer("User")}
                                onUntrigger={() => setActiveDrawer("")}
                                content={
                                    <div>
                                        {toolTipTitle && userDetails.userConfig.super_admin && (
                                            <Tooltip title={toolTipTitle}>
                                                <FontAwesomeIcon
                                                    className="ProcessingDashboardBadge"
                                                    icon={faCog}
                                                    spin={true}
                                                    style={{ fontSize: 16 }}
                                                />
                                            </Tooltip>
                                        )}
                                        {organisationDetails()}
                                        <div className={"DetailsLine"}>
                                            <div className={"DetailsText"}>
                                                Logged in as{" "}
                                                <span className={"UserEmail"}>
                                                    {userDetails.email} {userDetails.userConfig.trial_user && "(Trial Account)"}
                                                </span>
                                            </div>
                                        </div>
                                        {adminPermission && (
                                            <Link to="/admin">
                                                <div className="UserDetailsPopupButton">
                                                    <FontAwesomeIcon
                                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                                        icon={faUserCog}
                                                        className={"UserDetailsPopupButtonIcon"}
                                                    />
                                                    <div className={"UserDetailsPopupButtonText"}>Administration</div>
                                                </div>
                                            </Link>
                                        )}
                                        {_.get(userDetails, ["userConfig", "sso_only"], false) ? null : (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={showChangePasswordDialog}>
                                                <FontAwesomeIcon
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                    icon={faKey}
                                                    className={"UserDetailsPopupButtonIcon"}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Change Password</div>
                                            </div>
                                        )}
                                        {superAdmin && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={showNewUserModal}>
                                                <FontAwesomeIcon
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                    icon={faObjectUngroup}
                                                    className={"UserDetailsPopupButtonIcon"}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>User Preferences</div>
                                            </div>
                                        )}
                                        {!userDetails.isPrimaryKey && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={showUserSettingsDialog}>
                                                <FontAwesomeIcon
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                    icon={faCogs}
                                                    className={"UserDetailsPopupButtonIcon"}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>User Settings</div>
                                            </div>
                                        )}
                                        {canSwitchDashboard && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={switchDashboard}>
                                                <FontAwesomeIcon
                                                    style={{ fontSize: 28, color: "#e8dfff" }}
                                                    icon={faSignInAlt}
                                                    className={"UserDetailsPopupButtonIcon"}
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Switch Workspace</div>
                                            </div>
                                        )}
                                        {isMobile && (
                                            <div
                                                className="UserDetailsPopupButton"
                                                onClick={goToMobileSite}>
                                                <FontAwesomeIcon
                                                    color="#e8dfff"
                                                    icon={faMobileAlt}
                                                    className={"UserDetailsPopupButtonIcon"}
                                                    size="2x"
                                                />
                                                <div className={"UserDetailsPopupButtonText"}>Go to Mobile Site</div>
                                            </div>
                                        )}
                                        <div
                                            className="UserDetailsPopupButton"
                                            onClick={logout}>
                                            <FontAwesomeIcon
                                                style={{ fontSize: 28, color: "#e8dfff" }}
                                                icon={faSignOutAlt}
                                                className={"UserDetailsPopupButtonIcon"}
                                            />
                                            <div className={"UserDetailsPopupButtonText"}>Log out</div>
                                        </div>
                                    </div>
                                }>
                                <div
                                    className={`SidebarButtonContainer ${activeDrawer === "User" ? "active" : ""}`}
                                    role="button"
                                    aria-label="User/Settings">
                                    <FontAwesomeIcon
                                        style={{ fontSize: 32, color: "#e8dfff" }}
                                        icon={faUser}
                                    />
                                </div>
                            </Tippy>
                        )}
                    </div>
                </div>
            </div>

            <Drawer
                onClose={closeExportDrawer}
                visible={exportDrawerVisible}
                placement="left"
                width={"50%"}
                closable={false}
                className="markupExportDrawer"
                drawerStyle={{ background: "#30334d", border: "none", color: "#e8dfff" }}
                headerStyle={{ background: "#30334d", border: "none", color: "#e8dfff" }}>
                <ExportDrawer visible={exportDrawerVisible} />
            </Drawer>

            <Drawer
                onClose={toggleUploadManagerDrawer}
                visible={uploadManagerDrawerOpen}
                placement="left"
                closable={false}
                className="MarkerMediaDrawer"
                drawerStyle={{ background: "#30334d", border: "none", color: "#e8dfff" }}
                headerStyle={{ background: "#30334d", border: "none", color: "#e8dfff" }}>
                <UploadManagerDrawer />
            </Drawer>

            {changePasswordDialogVisible && <ChangePasswordDialog onClose={hideChangePasswordDialog} />}
            {displayNewUserModal && <NewUserModal onClose={hideNewUserModal} />}
            {userSettingsDialogVisible && <UserSettingsDialog onClose={hideUserSettingsDialog} />}
            {!!genericDialogVisible && (
                <GenericDialog
                    reportOrigin="app-navbar"
                    onClose={hideGenericDialog}
                    title={genericDialogVisible}
                    promptText={genericDialogVisible === "Report problem" ? "Report a problem with AIVR" : ""}
                />
            )}
            <DataCaptureDialog
                onClose={toggleDataCaptureRequestDialog}
                visible={dataCaptureRequestVisible}
            />
            <CCTVListDrawer
                visible={cctvDrawerVisible}
                toggleVisible={toggleCCTVDrawer}
                toggleModal={toggleCCTVModal}
                modalVisible={cctvModalVisible}
                setCameraInfo={setCameraInfo}
                setActiveIndexInCCTVDrawer={setActiveIndexInCCTVDrawer}
            />

            <CCTVCameraModal
                visible={cctvModalVisible}
                setVisible={toggleCCTVModal}
                cameraInfo={cameraInfo}
                cctvIndex={activeIndexInCCTVDrawer}
            />
        </>
    );
};

export default AppNavbar;
