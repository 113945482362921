import React, { useMemo } from "react";
import { connect } from "react-redux";
import { clearRequestedTimestamp, currentPlaylistPosition, logEvent, logTiming, segmentCleared, togglePlayerState } from "redux/actions/index";
import _ from "lodash";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "videojs-markers-plugin/dist/videojs.markers.plugin.css";
import AnnotationOverlay from "./annotation/AnnotationOverlay";
import { getOffsetAdjustedPosition, keyLookup, videoTimeLookup, binarySearch, asyncLoadImage } from "../../util/PlaylistUtils";
import { convertToTimezone } from "../../util/TimezoneUtils";
import { Parser } from "m3u8-parser";

require("videojs-seek-buttons");
require("videojs-markers-plugin");
require("videojs-hotkeys");

const SliderPreview = ({ allPreviewImages, previewImageKeys, offsettedX, index }) => {
    const imageToDisplay = useMemo(() => {
        const imageKeyIndexToUse = binarySearch(index, previewImageKeys, (key) => key);
        const imageData = allPreviewImages[previewImageKeys[imageKeyIndexToUse]];
        return imageData;
    }, [allPreviewImages, index, previewImageKeys]);

    if (imageToDisplay) {
        return (
            <div
                className="VideoScrubberPreview"
                style={{ left: `${offsettedX}px` }}>
                <img
                    src={imageToDisplay}
                    crossOrigin={"anonymous"}
                    alt="Scrubber Preview"
                    className="PreviewImage"
                />
            </div>
        );
    } else {
        return null;
    }
};

class VideoPlayer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.video = React.createRef();
        this.csrfToken = React.createRef();

        this.timeUpdateTimer = null;
        this.timeUpdateLastTrigger = 0;

        this.lastPlayerTimestamp = -1;

        this.inFlightAsyncRequests = {};

        this.state = {
            annotating: false,
            measuring: false,
            timeCorrection: 0,
            currentTimestamp: 0,
            waitingForVideo: false,
            timeOfLastVideoChange: 0,
            paused: true,
            pausedBeforeLoading: true,
            pausedBeforePlaySpeedChange: false,
            timeOfLastPlay: 0,
            cumulativeTimePlaying: 0,
            cumulativeTimePlayingEnhanced: 0,
            seekTarget: null,
            seekAttempts: 0,
            videoLoading: true,

            mouseInScrubber: false,
            mouseScrubberX: 0,
            indexHovered: 0,
            playerStartX: 0,
            lastSeekTarget: null,
        };

        videojs.setFormatTime(this.formatTime);
    }

    formatTime = (seconds) => {
        try {
            if (this.props.video) {
                seconds -= this.state.timeCorrection;
                let playlistIndex = videoTimeLookup(seconds, this.props.video);
                if (playlistIndex !== -1) {
                    let playlistItem = this.props.video[playlistIndex];
                    let timeOffset = seconds - playlistItem[1];
                    if (playlistItem[3]) {
                        let displayTime = playlistItem[3][2] + timeOffset;
                        const dpDate = new Date(displayTime * 1000);
                        return convertToTimezone(dpDate, this.props.userConfig.convert_to_utc);
                    }
                }
            }
        } catch (ex) {
            console.log("Unexpected exception while formatting time!", ex);
        }

        return "";
    };

    createPlaylistFromVideoKeys = (videoSource) => {
        const videoKeys = this.props.video;

        const playlist = [
            "#EXTM3U",
            "#EXT-X-PLAYLIST-TYPE:VOD",
            "#EXT-X-TARGETDURATION:1.0",
            "#EXT-X-VERSION:4",
            "#EXT-X-MEDIA-SEQUENCE:0",
            "#EXT-X-DISCONTINUITY",
        ];

        // Could probably move this to the backend eventually. Leaving here for backward compatability
        const basePlaylistURL = videoSource.split("index")[0];

        videoKeys.forEach((keyData, index) => {
            const keyParts = keyData[0].split(".");
            if (keyParts[1] === "c0" || this.props.discontinuityIndexes.includes(index)) {
                playlist.push("#EXT-X-DISCONTINUITY");
            }
            playlist.push(`#EXTINF:${keyData[2]}`);
            playlist.push(`${basePlaylistURL}${keyData[0]}.ts`);
        });
        playlist.push("#EXT-X-ENDLIST");

        const playlistString = playlist.join("\n");
        var parser = new Parser();
        parser.push(playlistString);
        parser.end();
        return parser;
    };

    asyncPreloadVideo = (playlistIndex) => {
        _.forEach(this.inFlightAsyncRequests, (controller, sidx) => {
            const idx = 1 * sidx;
            if (idx < playlistIndex - 5 || idx > playlistIndex + 15) {
                controller.abort();
                delete this.inFlightAsyncRequests[sidx];
            }
        });

        let videoSource;
        if (this.props.isEnhanced === "enhanced") {
            videoSource = this.props.enhancedURL;
        } else if (this.props.isEnhanced === "low_res") {
            videoSource = this.props.lowResURL;
        } else {
            videoSource = this.props.rawURL;
        }
        const basePlaylistURL = videoSource.split("index")[0];

        let indexToRequest = playlistIndex;
        while (indexToRequest < Math.min(this.props.video.length, playlistIndex + 10)) {
            this.generateAsyncVideoRequest(indexToRequest, basePlaylistURL);
            indexToRequest += 1;
        }
    };

    generateAsyncVideoRequest(indexToRequest, basePlaylistURL) {
        const sidx = `${indexToRequest}`;
        if (_.has(this.inFlightAsyncRequests, sidx)) {
            //Do nothing
            return;
        }
        const videoKey = this.props.video[indexToRequest][0];
        const videoURI = `${basePlaylistURL}${videoKey}.ts`;
        this.inFlightAsyncRequests[sidx] = new AbortController();
        fetch(videoURI, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            signal: this.inFlightAsyncRequests[sidx].signal,
            headers: {
                "X-AIVR-Security-Token": this.csrfToken.current,
            },
        }).catch((err) => {});
    }

    componentDidMount() {
        require("./LoadingOverlay");
        require("./SeekOverlay");
        require("./EnhancedVideoOverlay");
        // require('./LoadingTimeNotification');  //commented until improvements have been made
        require("./InformationOverlay");

        if (!this.props.shareLink) {
            require("./PauseOverlay");
        }

        videojs.Hls.MAX_GOAL_BUFFER_LENGTH = 10;
        videojs.Hls.GOAL_BUFFER_LENGTH = 10;
        videojs.Vhs.xhr.beforeRequest = (options) => {
            if (!options.headers) {
                options.headers = {};
            }
            if (this.csrfToken.current) {
                options.headers["X-AIVR-Security-Token"] = this.csrfToken.current;
            }

            let videoKey = options.uri.replace(/.*\/([^/]*)\.ts/, "$1");
            const startingIndex = keyLookup(videoKey, this.props.video);
            this.asyncPreloadVideo(startingIndex);

            return options;
        };

        this.player = videojs(this.video.current, {
            controls: true,
            aspectRatio: "16:9",
            html5: {
                hls: {
                    overrideNative: true,
                },
                nativeVideoTracks: false,
                nativeAudioTracks: false,
                nativeTextTracks: false,
            },
            controlBar: {
                volumePanel: false,
                fullscreenToggle: false,
                pictureInPictureToggle: false,
            },
            userActions: {
                doubleClick: false,
            },
        });

        this.player.defaultPlaybackRate(this.props.playSpeed);
        this.player.playbackRate(this.props.playSpeed);

        this.player.loadingOverlay();
        this.player.seekOverlay();
        this.player.enhancedVideoOverlay();
        this.player.informationOverlay();

        this.player.on("keydown", (event) => {
            // Stop Video.js from handling the event if history if open and user press left/right arrow
            if ((event.which === 37 || event.which === 39) && !this.props.historyHidden) {
                event.stopImmediatePropagation();
            }
        });

        if (!this.props.shareLink) {
            this.player.pauseOverlay();
        }

        this.player.seekButtons({
            forward: 10,
            back: 10,
        });

        this.player.hotkeys({
            enableVolumeScroll: false,
            enableMute: false,
            volumeStep: 0,
            enableNumbers: false,
            enableModifiersForNumbers: false,
            alwaysCaptureHotkeys: true,
        });

        this.player.markers({
            markerStyle: {
                "background-color": "grey",
                "border-radius": "40%",
            },
            markerTip: {
                display: true,
                html: (marker) => `<div style="display: flex; flex-direction: column">${marker.text}</div>`,
                time: (marker) => marker.time,
                //marker tip is currently hidden in css (.vjs-tip) - display: false hides scrubber marker too.
            },
            markers: [],
            onMarkerClick: this.markerClicked,
        });

        this.player.ready(() => {
            let tracks = this.player.textTracks();

            tracks.on("addtrack", (event) => {
                try {
                    if (event.track.label === "segment-metadata") {
                        if (event.track.on) {
                            event.track.on("cuechange", _.partial(this.hlsSegmentChange, event.track));
                        }
                    }
                } catch (ex) {
                    console.log("Unexpected exception", ex);
                }
            });

            this.player.on("seeking", this.seeking);
            this.player.on("play", this.playing);
            this.player.on("pause", this.paused);
            this.player.on("seeked", this.seeked);

            const _this = this;
            this.player.controlBar.progressControl.on("mousemove", (e) => {
                _this.onSliderHover(e);
            });
            this.player.controlBar.progressControl.on("mouseenter", (e) => {
                _this.setState({ mouseInScrubber: true });
            });
            this.player.controlBar.progressControl.on("mouseleave", (e) => {
                _this.setState({ mouseInScrubber: false });
            });

            const bounds = this.video.current.getBoundingClientRect();
            this.setState({ playerStartX: bounds.left });

            this.timeUpdateTimer = setInterval(() => {
                if (new Date().getTime() - this.timeUpdateLastTrigger >= 40) {
                    _.debounce(this.videoTimeUpdate, 0, { leading: false });
                    this.timeUpdateLastTrigger = new Date().getTime();
                }
            }, 20);
        });

        this.componentDidUpdate({}, {});
    }

    cachePreviewImages = () => {
        this.imagePreviewCache = {};
        this.imagePreviewKeys = [];

        const PREVIEW_COUNT = 20;
        const interval = this.props.video.length / PREVIEW_COUNT;
        const promises = [];
        for (let i = 0; i < this.props.video.length; i += interval) {
            const idx = Math.floor(i);
            const videoKey = this.props.video[idx][0];
            let imageURL = `${this.props.snapshotBaseURL}${videoKey}.sml.jpg`;
            if (this.props.csrfToken) {
                imageURL += `?csrf=${this.props.csrfToken}`;
            }
            promises.push(
                asyncLoadImage(imageURL).then((imageData) => {
                    this.imagePreviewCache[idx] = imageData;
                }),
            );
        }

        Promise.all(promises).then(() => {
            this.imagePreviewKeys = Object.keys(this.imagePreviewCache).sort(function (a, b) {
                return a - b;
            });
        });
    };

    onSliderHover = (e) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const maxX = bounds.right - bounds.left;

        const ratio = x / maxX;
        const totalImages = this.props.video.length;
        const indexHovered = totalImages * ratio;
        const fullscreenOffset = this.props.fullscreen ? bounds.left : bounds.left - this.state.playerStartX;
        const offsettedMouseScrubberX = Math.min(x, maxX - 100) + fullscreenOffset - 100; // + bounds.left

        this.setState({
            mouseScrubberX: x,
            indexHovered,
            offsettedMouseScrubberX,
        });
    };

    seeking = () => {
        try {
            if (this.state.seekTarget === null || this.player.scrubbing()) {
                clearTimeout(this.stuckSeekMonitor);
                this.stuckSeekMonitor = setTimeout(() => {
                    this.player.currentTime(this.player.currentTime());
                }, 2000);

                const playerTimestamp = this.player.currentTime();
                const currentTime = Math.round((playerTimestamp - this.state.timeCorrection) * 1000) / 1000;
                console.log("currentTime, this.lastPlayerTimestamp", currentTime, this.lastPlayerTimestamp);
                if (currentTime !== this.lastPlayerTimestamp) {
                    let position = getOffsetAdjustedPosition(currentTime, this.props.video, this.props.offsets, this.props.use_snapped);

                    if (position !== null) {
                        let playlistIndex = position[0];
                        let timeOffset = position[1];
                        let coords = [null, null];
                        if (position[2]) {
                            coords = position[2];
                        }
                        this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
                    }
                    this.lastPlayerTimestamp = currentTime;
                }
            }
        } catch (ex) {
            console.log("Unexpected exception", ex);
        }
    };

    markerClicked = (marker) => {
        this.props.dispatch(logEvent("Marker", "Click", marker.name));
        return true;
    };

    paused = () => {
        console.log("Got paused event");
        this.props.dispatch(togglePlayerState("paused"));
        if (!this.state.paused) {
            if (this.props.isEnhanced) {
                this.setState({
                    cumulativeTimePlayingEnhanced: new Date().getTime() - this.state.timeOfLastPlay,
                    paused: true,
                });
            } else {
                this.setState({
                    cumulativeTimePlaying: new Date().getTime() - this.state.timeOfLastPlay,
                    paused: true,
                });
            }
        }
        this.videoTimeUpdate();
    };

    playing = () => {
        console.log("Got playing event");
        this.props.dispatch(togglePlayerState("playing"));

        if (this.state.paused) {
            this.setState({
                timeOfLastPlay: new Date().getTime(),
                paused: false,
            });
        }
    };

    seeked = () => {
        const lastSeekTarget = this.state.seekTarget;
        clearTimeout(this.stuckSeekMonitor);

        this.lastPlayerTimestamp = -1;

        let seekingAgain = false;

        if (lastSeekTarget !== null) {
            let correctedTimestamp = this.player.currentTime() - this.state.timeCorrection;

            if (this.state.seekAttempts > 0) {
                console.log("Timestamp following seek to target", lastSeekTarget, "is:", correctedTimestamp, "attempts:", this.state.seekAttempts);
                if (Math.abs(lastSeekTarget - correctedTimestamp) > 0.2) {
                    let startTime = lastSeekTarget + this.state.timeCorrection;
                    console.log(`Setting player timestamp to ${startTime} (to reach ${this.state.seekTarget})`);
                    this.seek(startTime);
                    seekingAgain = true;
                }
            }
        } else {
        }

        if (!seekingAgain) {
            this.setState({
                seekTarget: null,
                seekAttempts: 0,
                lastSeekTarget,
            });

            if (this.state.videoLoading) {
                let timeTaken = new Date().getTime() - this.loadingStartTime;
                let delayTime = 0;
                if (timeTaken < 3000) {
                    delayTime = 3000 - timeTaken;
                }
                clearTimeout(this.loadingCompleteEvent);
                this.loadingCompleteEvent = setTimeout(() => {
                    this.player.trigger("aivrLoadingComplete");
                    this.setState({
                        videoLoading: false,
                    });
                    if (!this.state.pausedBeforeLoading) {
                        this.player.play();
                    }
                }, delayTime);
            }
        } else {
            this.setState({
                seekAttempts: this.state.seekAttempts - 1,
            });
        }
    };

    videoTimeUpdate = (timeCorrection, force = false) => {
        if (!this.props.video || this.props.toolMode) {
            return;
        }
        if (_.isNumber(timeCorrection)) {
            if (Math.abs(this.state.timeCorrection - timeCorrection) > 0.5 && this.state.lastSeekTarget) {
                console.log("Time correction changed since seek. Reseeking");
                this.seek(this.state.lastSeekTarget);
            }
            this.setState({
                timeCorrection: timeCorrection,
                lastSeekTarget: null,
            });
        } else {
            timeCorrection = this.state.timeCorrection;
        }

        const playerTime = this.player.currentTime();
        const currentlySeeking = this.state.seekTarget !== null;
        const currentTime = Math.round((playerTime - timeCorrection) * 1000) / 1000;

        if (currentTime !== this.lastPlayerTimestamp) {
            this.setState({
                currentTimestamp: currentTime,
            });

            if ((!this.props.toolMode && !currentlySeeking) || force) {
                let position = getOffsetAdjustedPosition(currentTime, this.props.video, this.props.offsets, this.props.use_snapped);

                if (position !== null) {
                    let playlistIndex = position[0];
                    let timeOffset = position[1];
                    let coords = [null, null];
                    if (position[2]) {
                        coords = position[2];
                    }
                    this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
                }
            }
            this.lastPlayerTimestamp = currentTime;
        }
    };

    hlsSegmentChange = (track) => {
        if (!this.props.video || this.props.toolMode) {
            return;
        }
        let activeCue = track.activeCues[0];
        if (activeCue) {
            let actualStart = Math.round(activeCue.value.start * 1000) / 1000;

            let videoKey = activeCue.value.uri.replace(/.*\/([^/]*)\.ts/, "$1");

            let playlistIndex = keyLookup(videoKey, this.props.video);

            if (playlistIndex !== -1) {
                let playlistItem = this.props.video[playlistIndex];
                let assumedStartTime = playlistItem[1];
                const timeDifference = Math.round((actualStart - assumedStartTime) * 1000) / 1000;
                this.videoTimeUpdate(timeDifference);
            }
        }
    };

    regenerateMarkers = () => {
        if (this.props.video) {
            let markers = [];
            if (this.props.routeStartTime) {
                console.log("pushing marker");
                markers.push(this.props.routeStartTime);
            }
            if (this.props.routeEndTime) {
                console.log("Pushing end marker", this.props.routeEndTime);
                markers.push(this.props.routeEndTime);
            }

            this.player.markers.reset(markers);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let videoSource;
        let lastVideoSource;

        let routeID = this.props.routeID;
        let lastRouteID = prevProps.routeID;

        let sourceIndex = this.props.sourceIndex;
        let lastSourceIndex = prevProps.sourceIndex;

        if (prevProps.isEnhanced === "enhanced") {
            lastVideoSource = prevProps.enhancedURL;
        } else if (prevProps.isEnhanced === "low_res") {
            lastVideoSource = prevProps.lowResURL;
        } else {
            lastVideoSource = prevProps.rawURL;
        }

        if (this.props.isEnhanced === "enhanced") {
            videoSource = this.props.enhancedURL;
        } else if (this.props.isEnhanced === "low_res") {
            videoSource = this.props.lowResURL;
        } else {
            videoSource = this.props.rawURL;
        }

        let videoHasChanged = videoSource && videoSource !== lastVideoSource;
        let positionHasChanged = this.props.video && (this.props.video !== prevProps.video || this.props.playlistPositionTS !== prevProps.playlistPositionTS);

        const offsetsHaveChanged =
            prevProps.offsets !== this.props.offsets && (_.get(prevProps.offsets, "length", 0) > 0 || _.get(this.props.offsets, "length", 0) > 0);

        let wasPaused = !lastRouteID || this.player.paused();

        if (lastRouteID !== routeID) {
            // this.cachePreviewImages()
            if (lastRouteID) {
                this.recordTimeSpent(lastRouteID);
            }
            this.props.dispatch(logEvent("Video", "Open", routeID));

            this.setState({
                timeOfLastVideoChange: new Date().getTime(),
            });
        } else if (videoHasChanged) {
            if (lastRouteID) {
                this.recordTimeSpentInMode(lastRouteID);
            }
        }

        if (this.props.csrfToken !== prevProps.csrfToken) {
            this.csrfToken.current = this.props.csrfToken;
        }

        if (videoHasChanged) {
            this.cachePreviewImages();
            console.log("Video source has changed from", lastVideoSource, "to", videoSource);

            this.loadingStartTime = new Date().getTime();
            clearTimeout(this.loadingCompleteEvent);

            this.player.trigger("aivrLoadingStarted");

            this.setState({
                annotating: false,
                measuring: false,
                pausedBeforeLoading: wasPaused,
                videoLoading: true,
            });
            this.props.dispatch(segmentCleared());
            this.player.trigger("cancelExport");

            let lastTimestamp = this.player.currentTime();

            this.setState({
                waitingForVideo: true,
            });

            if (routeID !== lastRouteID) {
                this.player.markers.removeAll();
            }

            if (!this.state.waitingForVideo) {
                this.player.one("canplay", () => {
                    console.log("Can play now");
                    this.regenerateMarkers();
                    this.videoTimeUpdate(null, true);
                    try {
                        routeID = this.props.routeID;
                        let playlistPosition = this.props.playlistRequestedIndex || 0;

                        if (_.isEmpty(prevProps) && _.isNull(this.props.playlistRequestedIndex)) {
                            playlistPosition = this.props.currentIndex;
                        }

                        if (routeID === lastRouteID && sourceIndex === lastSourceIndex) {
                            console.log(`Setting player timestamp to ${lastTimestamp}`);
                            this.seek(lastTimestamp, true);
                        } else if (this.props.video) {
                            const videoKey = this.props.video[playlistPosition];
                            if (videoKey) {
                                let startTime = videoKey[1] + this.props.playlistTimeOffset;
                                console.log(`Canplay setting player timestamp to ${startTime} (${videoKey[0]})`);
                                this.seek(startTime, true);
                            } else {
                                console.log("Index", playlistPosition, "not in playlist of size", this.props.video.length);
                                this.seek(0, true);
                            }
                        }
                    } catch (ex) {
                        console.log("Unexpected exception:", ex);
                    }

                    this.setState({
                        waitingForVideo: false,
                    });
                });
            }
            const parser = this.createPlaylistFromVideoKeys(videoSource);

            this.player.src({ src: `data:application/vnd.videojs.vhs+json,${JSON.stringify(parser.manifest)}`, type: "application/vnd.videojs.vhs+json" });
        } else if (positionHasChanged && this.props.video && !this.state.waitingForVideo) {
            let playlistPosition = this.props.playlistRequestedIndex || 0;
            console.log("Playlist position has changed:", playlistPosition);
            const videoKey = this.props.video[playlistPosition];
            this.props.dispatch(clearRequestedTimestamp());
            if (!videoKey) {
                return;
            }

            this.setState({
                seekTarget: null,
            });

            const timestamp = videoKey[1] + this.props.playlistTimeOffset;

            let position = getOffsetAdjustedPosition(timestamp, this.props.video, this.props.offsets, this.props.use_snapped);

            if (position !== null) {
                let playlistIndex = position[0];
                let timeOffset = position[1];
                let coords = [null, null];
                if (position[2]) {
                    coords = position[2];
                }
                this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
            }

            console.log(`Setting player timestamp to approximately ${timestamp} (${videoKey[0]}, due to map or marker click)`);
            this.seek(timestamp, true);
        } else if (offsetsHaveChanged && this.props.video && !this.state.waitingForVideo) {
            let position = getOffsetAdjustedPosition(this.state.currentTimestamp, this.props.video, this.props.offsets, this.props.use_snapped);

            if (position !== null) {
                let playlistIndex = position[0];
                let timeOffset = position[1];
                let coords = [null, null];
                if (position[2]) {
                    coords = position[2];
                }
                this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
            }
        } else if (prevProps.toolMode !== this.props.toolMode) {
            if (this.props.toolMode) {
                this.player.pause();
            }
        } else if (prevProps.offsets !== this.props.offsets && (_.get(prevProps.offsets, "length", 0) > 0 || _.get(this.props.offsets, "length", 0) > 0)) {
            this.lastPlayerTimestamp = -1;
            this.videoTimeUpdate(null);
        } else if (prevProps.use_snapped !== this.props.use_snapped) {
            this.lastPlayerTimestamp = -1;
            this.videoTimeUpdate(null);
        } else if (!prevProps.viewSnapshot && this.props.viewSnapshot) {
            this.player.pause();
        }

        if (prevProps.playSpeed !== this.props.playSpeed) {
            if (this.props.playSpeed === 0) {
                let pausedBeforePlaySpeedChange = false;
                if (this.player.paused()) {
                    pausedBeforePlaySpeedChange = true;
                } else {
                    this.player.pause();
                }
                this.setState({
                    pausedBeforePlaySpeedChange,
                });
            } else {
                if (prevProps.playSpeed === 0 && !this.state.pausedBeforePlaySpeedChange) {
                    this.player.play();
                }
                this.player.defaultPlaybackRate(this.props.playSpeed);
                this.player.playbackRate(this.props.playSpeed);
            }
        }

        if (this.props.routeStartTime !== prevProps.routeStartTime || this.props.routeEndTime !== prevProps.routeEndTime) {
            this.regenerateMarkers();
        }

        if (!_.isEqual(this.props.userConfig, prevProps.userConfig)) {
            this.seek(this.player.currentTime());
        }
    }

    stuckSeekHandler = () => {
        if (this.state.seekTarget) {
            console.log("Looks like seek is stuck, trying to fix...");
            this.player.currentTime(this.player.currentTime() + 0.1);
            this.stuckSeekMonitor = setTimeout(this.stuckSeekHandler, 1000);
        }
    };

    seek = (time, forceNew) => {
        let isNewSeek = false;
        if (this.state.seekTarget === null || forceNew) {
            this.setState({
                seekTarget: time,
                seekAttempts: 10,
            });
            isNewSeek = true;
        }
        clearTimeout(this.stuckSeekMonitor);

        this.stuckSeekMonitor = setTimeout(this.stuckSeekHandler, 7500);
        let exactTimestampFound = false;

        if (isNewSeek) {
            let tracks = this.player.textTracks();
            let segmentMetadataTrack = _.find(tracks, (track) => {
                return track.label === "segment-metadata";
            });

            if (segmentMetadataTrack) {
                const playlistIndex = videoTimeLookup(time, this.props.video);
                const targetVideoKey = this.props.video[playlistIndex][0];
                const targetVideoTime = this.props.video[playlistIndex][1];

                let cue = _.find(segmentMetadataTrack.cues, (cue) => {
                    return cue.value.uri.replace(/.*\/([^/]*)\.ts/, "$1") === targetVideoKey;
                });

                if (cue) {
                    const timeCorrection = cue.startTime - targetVideoTime;
                    this.setState({
                        timeCorrection,
                    });
                    console.log("Compensating for ", timeCorrection, "s time offset");
                    this.player.currentTime(cue.startTime);
                    exactTimestampFound = true;
                }
            }
        }

        if (!exactTimestampFound) {
            this.player.currentTime(time);
        }
        this.player.trigger("aivrStartSeek");
    };

    recordTimeSpent = (lastRouteID) => {
        logTiming("Video", "Time spent open", new Date().getTime() - this.state.timeOfLastVideoChange, lastRouteID);
        this.recordTimeSpentInMode(lastRouteID);
    };

    recordTimeSpentInMode = (lastRouteID) => {
        let timeSpentPlaying = this.state.cumulativeTimePlaying;
        let timeSpentPlayingEnhanced = this.state.cumulativeTimePlayingEnhanced;

        if (!this.state.paused) {
            if (this.props.isEnhanced) {
                timeSpentPlayingEnhanced += new Date().getTime() - this.state.timeOfLastPlay;
            } else {
                timeSpentPlaying += new Date().getTime() - this.state.timeOfLastPlay;
            }
        }
        if (timeSpentPlaying > 0) {
            logTiming("Video", "Time spent playing", timeSpentPlaying, lastRouteID);
        }
        if (timeSpentPlayingEnhanced > 0) {
            logTiming("Video", "Time spent playing enhanced", timeSpentPlayingEnhanced, lastRouteID);
        }
        this.setState({
            paused: true,
            timeOfLastPlay: 0,
            cumulativeTimePlaying: 0,
            cumulativeTimePlayingEnhanced: 0,
        });
    };

    componentWillUnmount() {
        if (this.props.routeID) {
            this.recordTimeSpent(this.props.routeID);
        }

        clearInterval(this.timeUpdateTimer);
        clearTimeout(this.loadingCompleteEvent);
        clearTimeout(this.stuckSeekMonitor);

        this.player.dispose();
        this.player = null;

        _.forEach(this.inFlightAsyncRequests, (controller, sidx) => {
            controller.abort();
            delete this.inFlightAsyncRequests[sidx];
        });

        this.props.dispatch(togglePlayerState("paused"));
    }

    togglePlayPause = () => {
        console.log("Onclick here");
        if (this.state.paused) {
            this.player.play();
        } else {
            this.player.pause();
        }
    };

    render = () => {
        let annotationOverlay = null;

        if (this.props.isEnhanced === "enhanced" && !this.state.annotating && this.state.currentTimestamp) {
            annotationOverlay = (
                <AnnotationOverlay
                    timestamp={this.state.currentTimestamp}
                    togglePlayPause={this.togglePlayPause}
                />
            );
        }

        return (
            <div>
                <div data-vjs-player>
                    <video
                        ref={this.video}
                        className={"video-js"}
                    />
                    {annotationOverlay}
                    {this.state.mouseInScrubber && (
                        <SliderPreview
                            allPreviewImages={this.imagePreviewCache}
                            previewImageKeys={this.imagePreviewKeys}
                            index={this.state.indexHovered}
                            offsettedX={this.state.offsettedMouseScrubberX}
                            x={this.state.mouseScrubberX}
                        />
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({
    shareLink,
    playlist,
    dashboards,
    access_token,
    selectedTagCategory,
    markers,
    userAnnotationTypes,
    sessions,
    markup,
    gpsTimeOffsets,
    userPreferences,
    viewSnapshot,
    userDetails,
    markerReviewFilters,
    snappedRoute,
    markerThresholdFilters,
    fullscreen,
    defaultMarkersThresholds,
    csrfToken,
}) => {
    const routeID = playlist.data.routeID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_token === access_token);

    let offsets = [];
    if (routeID === gpsTimeOffsets.sessionID) {
        offsets = _.get(gpsTimeOffsets.offsets, playlist.position.sourceIndex, []);
    }

    return {
        shareLink,
        rawURL: _.get(playlist.data, ["mpdURLs", "raw." + playlist.position.sourceIndex]),
        enhancedURL: _.get(playlist.data, ["mpdURLs", "enhanced." + playlist.position.sourceIndex]),
        snapshotBaseURL: _.get(playlist.data, ["mpdURLs", "snapshots"]),
        lowResURL: _.get(playlist.data, ["mpdURLs", "low_res." + playlist.position.sourceIndex]),
        routeID,
        currentSession: sessions[routeID],
        video: _.get(playlist.data, ["video", playlist.position.sourceIndex]),
        discontinuityIndexes: _.get(playlist.data, ["discontinuity_indexes", playlist.position.sourceIndex], []),
        offsets,
        use_snapped: snappedRoute,
        playlistRequestedIndex: playlist.position.requestedIndex,
        playlistTimeOffset: playlist.position.requestedTimeOffset,
        playlistPositionTS: playlist.position.requestTimestamp,
        isEnhanced: playlist.position.isEnhanced,
        isStills: playlist.position.isStills,
        sourceIndex: playlist.position.sourceIndex,
        selectedTagCategory: selectedTagCategory,
        annotationTypes: userAnnotationTypes,
        toolMode: markup.tool_mode,
        viewSnapshot,
        markerReviewFilters,
        userConfig: userDetails.userConfig,
        thresholdFilters: markerThresholdFilters,
        fullscreen,
        defaultMarkersThreshold: defaultMarkersThresholds[playlist.data.routeID],
        currentIndex: playlist.position.currentIndex,
        historyHidden: playlist.history.hidden,
        csrfToken,
        isBeta: currentDashboard?.isBeta || userDetails?.isDashboardBeta,
    };
};

export default connect(mapStateToProps)(VideoPlayer);
