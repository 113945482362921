import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionData, selectShortcutSession } from "../redux/actions/index";
import _ from "lodash";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import { LazyTippy } from "./util/LazyTooltips";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFilm, faImage, faPhotoVideo, faSearch, faTags } from "@fortawesome/free-solid-svg-icons";
import ContentLoader from "react-content-loader";
import { Progress } from "antd";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";

const selectedSessionSelector = (state) => state.playlist.data.routeID;
const dashboardIDSelector = (state) => state.userDetails.dashboardAccessID;
const dashboardSelector = (state) => state.dashboards;
const csrfTokenSelector = (state) => state.csrfToken;

const SessionSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={55}
        viewBox="0 0 780 55"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175"
        {...props}>
        <rect
            x="0"
            y="11"
            rx="4"
            ry="4"
            width="40"
            height="13"
        />
        <rect
            x="0"
            y="33"
            rx="4"
            ry="4"
            width="40"
            height="13"
        />

        <rect
            x="50"
            y="11"
            rx="4"
            ry="4"
            width="238"
            height="13"
        />
        <rect
            x="50"
            y="33"
            rx="4"
            ry="4"
            width="238"
            height="13"
        />

        <rect
            x="750"
            y="14"
            rx="4"
            ry="4"
            width="29"
            height="29"
        />
    </ContentLoader>
);

const ShortcutSessionItem = ({ shortcutID, session }) => {
    const dispatch = useDispatch();
    const [date, setDate] = useState(0);
    const [duration, setDuration] = useState(0);
    const [sessionData, setSessionData] = useState(null);
    const [sessionVideoIcon, setSessionVideoIcon] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);

    const selectedSession = useSelector(selectedSessionSelector);
    const dashboardID = useSelector(dashboardIDSelector);
    const dashboards = useSelector(dashboardSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);

    const sessionID = session.session || session;

    useEffect(() => {
        dispatch(getSessionData(sessionID)).then((sessionData) => {
            setSessionData(sessionData);
        });
    }, [dispatch, sessionID]);

    useEffect(() => {
        if (sessionData) {
            let date = 0;
            const uploaded = sessionData.first_seen;

            if (uploaded > 0) {
                const newDate = new Date(uploaded * 1000);
                date = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
            }

            setDate(date);

            let duration = 0;
            let niceDuration = 0;
            if (sessionData.length_seconds > 0) {
                duration = moment.duration(sessionData.length_seconds * 1000);
                if (duration._data.hours === 0 && duration._data.minutes === 0 && duration._data.seconds <= 60) {
                    niceDuration = "00:01";
                } else {
                    niceDuration = moment.utc(duration.as("milliseconds")).format("HH:mm");
                }
            }

            setDuration(niceDuration);

            const hasVideo = _.get(sessionData, "last_vid_up", 0) > 0;
            const hasImages = _.get(sessionData, "last_img_up", 0) > 0;

            if (hasVideo && hasImages) {
                setSessionVideoIcon(faPhotoVideo);
            } else if (hasVideo) {
                setSessionVideoIcon(faFilm);
            } else if (hasImages) {
                setSessionVideoIcon(faImage);
            }
        }
    }, [sessionData]);

    const handleSessionClick = (e) => {
        e.stopPropagation();
        dispatch(selectShortcutSession(shortcutID, sessionID));
    };

    const tippyTags = (
        <>
            {sessionData &&
                sessionData.tags &&
                sessionData.tags.length &&
                sessionData.tags.map((tag, index) => {
                    return (
                        <div
                            key={index}
                            className="tag small">
                            {tag}
                        </div>
                    );
                })}
        </>
    );

    useEffect(() => {
        if (!sessionData) {
            return;
        }

        const device = sessionData.device_uuid;
        const uuid = sessionData.uuid;
        const allArchived = sessionData.stream_info.every((stream) => stream.archived === 1);

        if (device && uuid && !allArchived) {
            let url = `https://raw${MEMOIZED_DOMAIN_URL}/${device}/${uuid}/index.gif`;

            if (csrfToken) {
                url += `?csrf=${csrfToken}`;
            }

            setPreviewContent(
                <img
                    src={url}
                    width="200"
                    className="SessionPreview"
                    alt="No Preview Available"
                    crossOrigin={"anonymous"}
                />,
            );
        }
    }, [csrfToken, sessionData]);

    return (
        <div
            className={`ShortcutsTabSessionItem ${sessionData && selectedSession === sessionData.id ? " active" : ""}`}
            onClick={handleSessionClick}>
            {sessionData ? (
                <>
                    <div className="SessionItemLeft">
                        <div className="IdAndDuration">
                            {sessionData.id && <div className="routeID">#{sessionData.id}</div>}
                            {duration !== 0 && <div className="duration">{duration}</div>}
                        </div>
                        <div className="SessionMain">
                            {sessionData.route_name && <p className="SessionName">{sessionData.route_name}</p>}
                            {date !== 0 && <p className="SessionDate">{date}</p>}
                            {session.coverage !== undefined && (
                                <div className="CoveragePercentage">
                                    <p className="coverage">{Math.round(session.coverage * 100)}%</p>
                                    <Progress
                                        size="small"
                                        percent={session.coverage * 100}
                                        style={{ width: 115 }}
                                        showInfo={false}
                                        strokeColor={"#40a9ff"}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="SessionItemRight">
                        {previewContent && sessionVideoIcon && (
                            <LazyTippy
                                placement="left"
                                sticky={true}
                                plugins={[sticky]}
                                arrow={false}
                                theme="aivr"
                                content={previewContent}>
                                <div className="PreviewImage">
                                    {session.stream_count > 1 ? (
                                        <span className="fa-layers">
                                            <FontAwesomeIcon icon={sessionVideoIcon} />
                                            <span
                                                className="fa-layers-counter"
                                                style={{ background: "#1C1E30" }}>
                                                {session.stream_count}
                                            </span>
                                        </span>
                                    ) : (
                                        <FontAwesomeIcon icon={sessionVideoIcon} />
                                    )}
                                </div>
                            </LazyTippy>
                        )}
                        {sessionData.inspection && _.get(currentDashboard, ["config", "rail_inspection_enabled"], []) && (
                            <Tippy
                                placement="top"
                                arrow={true}
                                theme="dark"
                                content={"View Rail Inspection"}>
                                <div>
                                    <div
                                        className="RailInspectionButton"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                        <Link to={`/rail-inspection/${sessionID}`}>
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                style={{ color: "#e8dfff" }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </Tippy>
                        )}
                        {sessionData.tags && (
                            <Tippy
                                placement="top"
                                arrow={true}
                                theme="dark"
                                delay={[150, null]}
                                content={tippyTags}>
                                <div className="SessionTagIcon">
                                    <FontAwesomeIcon icon={faTags} />
                                </div>
                            </Tippy>
                        )}
                    </div>
                </>
            ) : (
                <SessionSkeleton />
            )}
        </div>
    );
};

export default ShortcutSessionItem;
