import memoizeOne from "memoize-one";

const getAPIBaseURL = (windowHostname) => {
    let baseURL = "https://api.aivr-dev.video";
    if (windowHostname === "view.aivr.video") {
        baseURL = "https://api.aivr.video";
    } else if (windowHostname === "view-dev.aivr.video") {
        baseURL = "https://api-dev.aivr.video";
    } else if (windowHostname === "view-test.aivr.video" || windowHostname === "test.localhost") {
        baseURL = "https://api-test.aivr.video";
    } else if (windowHostname === "view-widget.aivr.video" || windowHostname === "widget.localhost") {
        baseURL = "https://api-widget.aivr.video";
    } else if (windowHostname === "view-widgetdev.aivr.video" || windowHostname === "widgetdev.localhost") {
        baseURL = "https://api-widgetdev.aivr.video";
    }
    //To be removed at a later date
    else if (windowHostname === "localhost") {
        baseURL = "https://api-dev.aivr.video";
    }
    return baseURL;
};

const getBaseUrl = (windowHostname) => {
    const domain = windowHostname.split(".").slice(-2).join(".");
    let baseURL = ".aivr.video";
    if (domain === "aivr-dev.video") {
        baseURL = ".aivr-dev.video";
    }
    return baseURL;
};

const memoizedGetBaseUrl = memoizeOne(getBaseUrl);
const memoizedAPIBaseURL = memoizeOne(getAPIBaseURL);

export const MEMOIZED_DOMAIN_URL = memoizedGetBaseUrl(window.location.hostname);
export const MEMOIZED_API_BASE_URL = memoizedAPIBaseURL(window.location.hostname);
