import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { calculatePointDistance, getStartCoordinate } from "../../util/Geometry";
import { getExportSize, getTrimSuggestions, requestPlaylistPosition } from "redux/actions/index";
import { Modal, Tooltip } from "antd";
import { absoluteTimeLookup } from "../../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltToBottom, faClock, faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import memoizeOne from "memoize-one";

const calculateDistanceAndDuration = memoizeOne((allVideos, routeStartVidKey, routeEndVidKey, useSnapped) => {
    if (!allVideos) {
        return ["N/A", "N/A"];
    }

    let startIndex = _.findIndex(allVideos, function (vid) {
        return vid[0] === routeStartVidKey;
    });

    let endIndex = _.findIndex(allVideos, function (vid) {
        return vid[0] === routeEndVidKey;
    });

    if (startIndex > endIndex) {
        let temp = startIndex;
        startIndex = endIndex;
        endIndex = temp;
    }
    let segmentedClips = allVideos.slice(startIndex, endIndex);

    let totalDistance = 0;
    let totalDuration = 0;
    if (endIndex > -1 && startIndex > -1) {
        for (let i = 0; i < segmentedClips.length; i++) {
            let segmentDuration = segmentedClips[i][2];
            totalDuration += segmentDuration;

            if (i % 10 === 0) {
                let distanceBetweenPoints = calculatePointDistance(
                    getStartCoordinate(segmentedClips[i], useSnapped),
                    getStartCoordinate(segmentedClips[Math.min(i + 10, segmentedClips.length - 1)], useSnapped),
                );
                totalDistance += distanceBetweenPoints;
            }
        }
        if (totalDistance > 5000) {
            totalDistance = `${Math.round((totalDistance / 1000) * 100) / 100} KM`;
        } else {
            totalDistance = `${Math.round(totalDistance)}M`;
        }

        const hours = Math.floor(totalDuration / 3600);
        const minutes = Math.floor((totalDuration - hours * 3600) / 60);
        const seconds = Math.floor(totalDuration - hours * 3600 - minutes * 60);
        totalDuration = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    } else {
        return ["N/A", "N/A"];
    }

    return [totalDistance, totalDuration];
});

class ExportBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            exportSize: null,
            trimSuggestion: {},
            rawSize: null,
        };
    }

    componentDidMount = () => {
        this.props
            .dispatch(getTrimSuggestions(this.props.currentSession.id))
            .then((trimResponse) => {
                console.log("Trim response", trimResponse);
                if (trimResponse.suggested_start) {
                    let trimObj = {
                        start: trimResponse.suggested_start,
                        end: trimResponse.suggested_end,
                    };
                    this.setState({
                        trimSuggestion: trimObj,
                    });
                }
            })
            .catch((error) => {
                console.log("Error getting trim response");
            });
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.props.routeStartTime &&
            this.props.routeEndTime &&
            (!prevProps.routeEndTime ||
                !prevProps.routeStartTime ||
                this.props.routeStartTime !== prevProps.routeStartTime ||
                this.props.routeEndTime !== prevProps.routeEndTime)
        ) {
            console.log("Getting file size");
            this.getFileSize();
        }

        if ((!this.props.routeStartTime || !this.props.routeEndTime) && prevProps.routeEndTime && prevProps.routeStartTime) {
            this.setState({
                exportSize: null,
            });
        }
    };

    humanFileSize = (size) => {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return Math.round(size / Math.pow(1024, i)).toFixed(2) * 1 + ["B", "kB", "MB", "GB", "TB"][i];
    };

    getFileSize = () => {
        const _this = this;

        let allVideo = this.props.video;

        let startTimestamp =
            _.find(allVideo, function (vid) {
                return vid[0] === _this.props.routeStartTime.videoKey;
            })[3][2] * 1000;
        let endTimestamp =
            _.find(allVideo, function (vid) {
                return vid[0] === _this.props.routeEndTime.videoKey;
            })[3][2] * 1000;

        let newStartTimestamp = Math.min(startTimestamp, endTimestamp);
        let newEndTimestamp = Math.max(startTimestamp, endTimestamp);

        let deviceKey = this.props.currentSession.device_uuid;
        let sessionKey = this.props.currentSession.uuid;

        this.props.dispatch(
            getExportSize(sessionKey, deviceKey, newStartTimestamp, newEndTimestamp, function (size) {
                console.log("Export size", size);
                let formattedSize = null;
                if (size) {
                    formattedSize = _this.humanFileSize(size);
                }

                _this.setState({
                    exportSize: formattedSize,
                    rawSize: size,
                });
            }),
        );
    };

    setAutoTrim = () => {
        this.props.toggleRouteStartTime(this.state.trimSuggestion.start);
        this.props.toggleRouteEndTime(this.state.trimSuggestion.end);
    };

    navigateVideo = (timestamp) => {
        const videoKeyIndex = absoluteTimeLookup(timestamp / 1000, this.props.video) || 0;

        this.props.dispatch(requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, videoKeyIndex, 0));
    };

    triggerExport = () => {
        if (this.state.rawSize && this.state.rawSize > 20000000000) {
            Modal.error({
                title: "Export too big",
                content: "The size of this export is larger than the 20GB limit. Please select a smaller duration and retry.",
            });
            return;
        }
        this.props.createExport();
    };

    render() {
        return (
            <div style={{ display: "flex", backgroundColor: "#6C43DF", borderBottomRightRadius: 10, borderBottomLeftRadius: 10, height: 30 }}>
                <span className="VideoControls__Item">
                    <button
                        className="videoButton VideoControls__Item__BtnClose"
                        onClick={this.props.exitExportMode}>
                        <label className="VideoControls__Toggle videoButtonLabel">
                            <span>Close</span>
                        </label>
                    </button>
                </span>

                {this.props.mode === "trim" && (
                    <span className="VideoControls__Item">
                        <button
                            className="videoButton"
                            onClick={() => this.props.createTrim(true)}>
                            <label className="VideoControls__Toggle videoButtonLabel">
                                <span>Reset Slice</span>
                            </label>
                        </button>
                    </span>
                )}

                {/* {this.props.mode === "trim" && !_.isEmpty(this.state.trimSuggestion) &&
                    <Popover content={popoverContent} title="Auto Slice">
                        <span className="VideoControls__Item">
                            <button className="videoButton">
                                <label className="VideoControls__Toggle videoButtonLabel">
                                    <span>
                                        Auto
                                    </span>
                                </label>
                            </button>
                        </span>
                    </Popover>} */}

                <span className="VideoControls__Item">
                    <button
                        className="videoButton"
                        onClick={() => this.props.toggleRouteStartTime(null)}>
                        <label className="VideoControls__Toggle videoButtonLabel">
                            <span>Place start marker</span>
                        </label>
                    </button>
                </span>

                <span className="VideoControls__Item">
                    <button
                        className="videoButton"
                        onClick={() => this.props.toggleRouteEndTime(null)}>
                        <label className="VideoControls__Toggle videoButtonLabel">
                            <span>Place end marker</span>
                        </label>
                    </button>
                </span>

                {this.props.routeStartTime && this.props.routeEndTime && this.props.routeStartTime.videoKey !== this.props.routeEndTime.videoKey ? (
                    <React.Fragment>
                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle exportData">
                                <FontAwesomeIcon icon={faMapMarkedAlt} />
                                <span>
                                    {
                                        calculateDistanceAndDuration(
                                            this.props.video,
                                            this.props.routeStartTime.videoKey,
                                            this.props.routeEndTime.videoKey,
                                            this.props.use_snapped,
                                        )[0]
                                    }
                                </span>
                            </label>
                        </span>

                        {this.state.exportSize && this.props.mode === "export" && (
                            <span className="VideoControls__Item">
                                <label className="VideoControls__Toggle exportData">
                                    <FontAwesomeIcon icon={faArrowAltToBottom} />
                                    <span>{this.state.exportSize}</span>
                                </label>
                            </span>
                        )}

                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle exportData">
                                <FontAwesomeIcon icon={faClock} />
                                <span>
                                    {
                                        calculateDistanceAndDuration(
                                            this.props.video,
                                            this.props.routeStartTime.videoKey,
                                            this.props.routeEndTime.videoKey,
                                            this.props.use_snapped,
                                        )[1]
                                    }
                                </span>
                            </label>
                        </span>

                        {this.props.mode === "trim" ? (
                            <span className="VideoControls__Item">
                                <Tooltip
                                    title={"The selected section will be removed"}
                                    placement="top">
                                    <button
                                        className="videoButton"
                                        onClick={() => this.props.createTrim(false)}>
                                        <label className="VideoControls__Toggle videoButtonLabel">
                                            <span>Slice</span>
                                        </label>
                                    </button>
                                </Tooltip>
                            </span>
                        ) : (
                            <>
                                <span className="VideoControls__Item">
                                    <button
                                        className="videoButton"
                                        onClick={this.triggerExport}>
                                        <label className="VideoControls__Toggle videoButtonLabel">
                                            <span>Export</span>
                                        </label>
                                    </button>
                                </span>
                            </>
                        )}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({ playlist, userPreferences, sessions, snappedRoute }) => {
    const routeID = playlist.data.routeID;

    return {
        video: _.get(playlist.data, ["video", playlist.position.sourceIndex]),
        use_snapped: snappedRoute,
        currentSession: sessions[routeID],
        isVideo: playlist.position.isVideo,
        isEnhanced: playlist.position.isEnhanced,
        isStills: playlist.position.isStills,
        sourceIndex: playlist.position.sourceIndex,
    };
};

export default connect(mapStateToProps)(ExportBar);
