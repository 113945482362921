import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { LayerGroup } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { LazyMapTooltip } from "components/util/LazyTooltips";
import { Polygon } from "react-leaflet";
import { setEnvironmentalDataSelectedSegment } from "redux/actions";
import memoizeOne from "memoize-one";

const mapZoomSelector = (state) => state.sessionFilters.mapBounds.zoom;
const mapEnvironmentDataSelector = (state) => state.environmentData.mapData;
const mapEnvironmentDataFiltersSelector = (state) => state.environmentData.filters;
const mapEnvironmentDataSelectedSegmentSelector = (state) => state.environmentData.selectedSegment;

// below values might have to be changed if more data added to the database (also will have to be updated in SatelliteDataTab.js)
const DATA_SUB_TYPES_MAP = {
    vegetation_encroachment: [
        "data_type",
        "sub_data_type",
        "route_id",
        "full_data.vegetation_density_percentage",
        "full_data.display_fields.Vegetation Encroachment Status",
    ],
    vegetation_density: ["data_type", "sub_data_type", "route_id", "full_data.display_fields.Vegetation Density Level"],
    pluvial_flood_risk: ["data_type", "sub_data_type", "route_id", "full_data.display_fields.Pluvial Flood Risk"],
    fluvial_flood_risk: ["data_type", "sub_data_type", "route_id", "full_data.display_fields.Fluvial Flood Risk"],
    land_movement_average_velocity: [
        "data_type",
        "sub_data_type",
        "route_id",
        "full_data.number_of_points",
        "full_data.average_velocity",
        "full_data.display_fields.Land Movement Average Velocity",
    ],
    land_movement_velocity_rate_of_change: ["data_type", "sub_data_type", "route_id", "full_data.number_of_points", "full_data.velocity_rate_of_change"],
};

const EnvironmentalPolygons = memo(({ minZoom }) => {
    const dispatch = useDispatch();

    const mapZoom = useSelector(mapZoomSelector);
    const mapEnvironmentData = useSelector(mapEnvironmentDataSelector);
    const mapEnvironmentDataSelectedSegment = useSelector(mapEnvironmentDataSelectedSegmentSelector);
    const mapEnvironmentDataFilters = useSelector(mapEnvironmentDataFiltersSelector);

    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        const currentRouteID = _.get(mapEnvironmentDataSelectedSegment, "route_id", null);
        const selectedRouteID = _.get(selectedSection, "route_id", null);

        if (currentRouteID !== selectedRouteID) {
            setSelectedSection(mapEnvironmentDataSelectedSegment);
        }
    }, [mapEnvironmentDataSelectedSegment, selectedSection]);

    const filteredData = useMemo(() => {
        let filteredSegments = {};

        const filterType = _.get(mapEnvironmentDataFilters, "type");
        const filterSubType = _.get(mapEnvironmentDataFilters, "subType");
        const filterSearchQuery = _.get(mapEnvironmentDataFilters, "searchQuery");

        _.map(mapEnvironmentData, (segment, index) => {
            const segmentType = _.get(segment, "data_type");
            const segmentSubType = _.get(segment, "sub_data_type");
            const segmentRouteID = _.get(segment, "route_id");

            let includeSegment = false;

            if (filterSubType && segmentSubType === filterSubType && filterType && filterType === segmentType) {
                includeSegment = true;
            }

            if (filterSearchQuery && filterSearchQuery.length > 0) {
                if (String(segmentRouteID).toLowerCase().indexOf(filterSearchQuery.toLowerCase()) === -1) {
                    includeSegment = false;
                }
            }

            if (!_.isEmpty(selectedSection) && segmentRouteID !== selectedSection.route_id) {
                includeSegment = false;
            }

            if (includeSegment) {
                filteredSegments[index] = segment;
            }
        });
        // console.log("debug3 filteredSegments", filteredSegments)

        return filteredSegments;
    }, [mapEnvironmentData, mapEnvironmentDataFilters, selectedSection]);

    // this is where we do colouring and default values are predefined here too as a list [green, orange, red]
    const generatePolygonColour = useCallback(
        (segment) => {
            const fullData = _.get(segment, "full_data", null);
            const selectedSegmentID = _.get(mapEnvironmentDataSelectedSegment, "id");

            // if segment is selected color it purple
            if (selectedSegmentID && segment.id === selectedSegmentID) {
                return "#673ab7";
            }

            let valueToCompare = null;
            let valueThresholds = [];
            let colour = "#adc200";

            if (segment.sub_data_type === "vegetation_density") {
                valueToCompare = _.get(segment, ["data"], null);
                if (valueToCompare === "high") {
                    colour = "#ed4747"; // Red
                } else if (valueToCompare === "medium") {
                    colour = "#f99244"; // '#f99244' Amber
                } else {
                    colour = "#54c333"; // '#54c333' Green
                }
                return colour;
            } else if (segment.sub_data_type === "land_movement_average_velocity") {
                valueToCompare = Math.abs(_.get(fullData, ["average_velocity"], null));
                valueThresholds = [1, 2];
            } else if (segment.sub_data_type === "land_movement_velocity_rate_of_change") {
                valueToCompare = _.get(fullData, ["velocity_rate_of_change"], null);
                valueThresholds = [0.5, 1];
            } else if (segment.sub_data_type === "vegetation_encroachment") {
                valueToCompare = _.get(segment, ["data"], null);
                if (valueToCompare === "encroaching") {
                    colour = "#ed4747"; // Red
                } else if (valueToCompare === "not_encroaching") {
                    colour = "#54c333";
                }
                return colour;
            } else if (segment.data_type === "flood_risk") {
                valueToCompare = _.get(fullData, ["frequency"], null);
                if (!valueToCompare) {
                    colour = "#54c333"; // '#54c333' Green
                } else if (valueToCompare <= 10) {
                    colour = "#ed4747"; // Red
                } else if (10 < valueToCompare && valueToCompare <= 100) {
                    colour = "#f99244"; // '#f99244' Amber
                } else {
                    colour = "#54c333"; // '#54c333' Green
                }
                return colour;
            }

            if (valueToCompare) {
                if (valueToCompare <= valueThresholds[0]) {
                    colour = "#54c333"; // '#54c333' Green
                } else if (valueToCompare <= valueThresholds[1]) {
                    colour = "#f99244"; // '#f99244' Amber
                } else {
                    colour = "#ed4747"; // Red
                }
            }

            return colour;
        },
        [mapEnvironmentDataSelectedSegment],
    );

    const renderPolygon = memoizeOne((segment, index) => {
        const coordinates = _.get(segment, "coordinates", []);
        const segmentWithID = {
            ...segment,
            id: index,
        };

        const subTypes = _.get(DATA_SUB_TYPES_MAP, segment.sub_data_type);

        const tooltipContent = (
            <div>
                {_.map(subTypes, (type) => {
                    const keysList = _.split(type, ".");
                    const rowLabel = keysList.at(-1);
                    let value = _.get(segmentWithID, keysList, null);

                    if (keysList.at(-1) === "vegetation_density_percentage") {
                        value = `${_.floor(value, 2)}%`;
                    }
                    if (["data_type", "sub_data_type"].includes(keysList.at(-1))) {
                        value = _.startCase(value);
                    }

                    if (value) {
                        return (
                            <div
                                key={`${keysList.at(-1)}-${index}`}
                                className="SatelliteDataTabInfoContainerItem">
                                <div className="SatelliteDataTabInfoContainerItemLabel">{_.startCase(rowLabel)}:</div>
                                <div className="SatelliteDataTabInfoContainerItemValue">{value}</div>
                            </div>
                        );
                    }
                })}
            </div>
        );

        if (coordinates) {
            return (
                <Polygon
                    key={`Environmental-data-id-${index}`}
                    positions={coordinates}
                    color={generatePolygonColour(segmentWithID)}
                    weight={mapZoom < 11 ? 2 : 1} // Border width
                    opacity={mapZoom <= 12 ? 1 : 0.7} // Border opacity
                    fillOpacity={mapZoom <= 12 ? 1 : 0.7} // Fill opacity
                    onClick={() => dispatch(setEnvironmentalDataSelectedSegment(segmentWithID))}>
                    <LazyMapTooltip
                        className="SatelliteDataTooltip"
                        delay={0}
                        permanent={false}
                        interactive={true}
                        direction={"top"}
                        offset={[0, -5]}>
                        {tooltipContent}
                    </LazyMapTooltip>
                </Polygon>
            );
        }

        return null;
    });

    return <LayerGroup>{mapZoom >= minZoom ? _.map(filteredData, (segment, index) => renderPolygon(segment, index)) : null}</LayerGroup>;
});

export default EnvironmentalPolygons;
