import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faFilter, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { Input, Select } from "antd";
import CCTVCameraListItem from "./CCTVCameraListItem";
import { ReactComponent as CCTVIcon } from "../icons/CCTVNavbarIcon.svg";
import moment from "moment";
import { set } from "lodash";

const CameraGroupSelect = ({ cameras }) => {
    // Currently only the FloodCam group is configured in access_token_config, so there's nothing to filter at the moment.
    const [cameraGroups, setCameraGroups] = useState([]);

    useEffect(() => {
        if (cameras) {
            let uniqueGroups = [];
            cameras.forEach((camera) => {
                if (!uniqueGroups.includes(camera.group)) {
                    uniqueGroups.push(camera.group);
                }
            });

            setCameraGroups(uniqueGroups);
        }
    }, [cameras]);

    return (
        <div className="DrawerSectionGroup">
            <span>SELECT CAMERA GROUP</span>
            <div className="DrawerSelect">
                <Select
                    defaultValue="all-groups"
                    style={{ width: "100%" }}>
                    <Select.Option
                        value="all-groups"
                        className="IssuesSelectOption">
                        All Groups
                    </Select.Option>
                    {cameraGroups.map((group, index) => {
                        return (
                            <Select.Option
                                value={group}
                                className="IssuesSelectOption"
                                key={index}>
                                {group}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        </div>
    );
};

const CameraStatusSelect = ({ setRecencyFilter }) => {
    return (
        <div className="DrawerSectionGroup">
            <span>SELECT CAMERA IMAGE AGE</span>
            <div className="DrawerSelect">
                <Select
                    defaultValue="all"
                    style={{ width: "100%" }}
                    onChange={(value) => {
                        setRecencyFilter(value);
                    }}>
                    <Select.Option
                        value="all"
                        className="IssuesSelectOption">
                        All Cameras
                    </Select.Option>
                    <Select.Option
                        value="recent"
                        className="IssuesSelectOption">
                        Less than 12 hours old
                    </Select.Option>
                    <Select.Option
                        value="semi-recent"
                        className="IssuesSelectOption">
                        Between 12 and 24 hours old
                    </Select.Option>
                    <Select.Option
                        value="old"
                        className="IssuesSelectOption">
                        Older than 24 hours
                    </Select.Option>
                </Select>
            </div>
        </div>
    );
};

const FilteredCameraList = ({ filter, cameras, setCameraInfo, toggleModal, modalVisible, searchTerms, visible }) => {
    const [active, setActive] = useState(null);
    const [filteredCameras, setFilteredCameras] = useState([]);

    useEffect(() => {
        if (cameras) {
            setFilteredCameras(cameras);
        }
    }, [cameras]);

    useEffect(() => {
        if (filter === "all") {
            setFilteredCameras([...cameras].sort((a, b) => new Date(b.thumbnail_ts) - new Date(a.thumbnail_ts)));
        } else if (filter === "recent") {
            setFilteredCameras(
                cameras
                    .filter((camera) => {
                        const date = moment.unix(camera.thumbnail_ts);
                        const hoursAgo = moment().diff(date, "hours");
                        return hoursAgo < 12;
                    })
                    .slice()
                    .sort((a, b) => new Date(b.thumbnail_ts) - new Date(a.thumbnail_ts)),
            );
        } else if (filter === "semi-recent") {
            setFilteredCameras(
                cameras
                    .filter((camera) => {
                        const date = moment.unix(camera.thumbnail_ts);
                        const hoursAgo = moment().diff(date, "hours");
                        return hoursAgo >= 12 && hoursAgo <= 24;
                    })
                    .slice()
                    .sort((a, b) => new Date(b.thumbnail_ts) - new Date(a.thumbnail_ts)),
            );
        } else if (filter === "old") {
            setFilteredCameras(
                cameras
                    .filter((camera) => {
                        const date = moment.unix(camera.thumbnail_ts);
                        const hoursAgo = moment().diff(date, "hours");
                        return hoursAgo > 24;
                    })
                    .slice()
                    .sort((a, b) => new Date(b.thumbnail_ts) - new Date(a.thumbnail_ts)),
            );
        }
    }, [filter]);

    useEffect(() => {
        if (searchTerms !== "") {
            setFilteredCameras(cameras.filter((camera) => camera.name.toLowerCase().includes(searchTerms.toLowerCase())));
        } else {
            setFilteredCameras([...cameras].sort((a, b) => new Date(b.thumbnail_ts) - new Date(a.thumbnail_ts)));
        }
    }, [searchTerms]);

    return filteredCameras.map((camera, index) => {
        return (
            <CCTVCameraListItem
                camera={camera}
                setCameraInfo={setCameraInfo}
                toggleModal={toggleModal}
                key={index}
                index={index}
                modalVisible={modalVisible}
                setActive={setActive}
                active={active === index ? true : false}
                visible={visible}
            />
        );
    });
};

const CCTVListDrawer = ({ visible, toggleVisible, setCameraInfo, toggleModal, modalVisible }) => {
    const cameras = useSelector((state) => state.cctv.CCTVMarkers.cameras);
    const [filter, setFilter] = useState("all");
    const [searchTerms, setSearchTerms] = useState("");

    return (
        <div className={visible ? "CCTVListDrawer visible" : "CCTVListDrawer"}>
            <div className="DrawerOverlay visible"></div>
            <div className="DrawerContent">
                <div className="DrawerSection">
                    <div className="DrawerTitle">
                        <FontAwesomeIcon icon={faFilter} />
                        Camera filters
                    </div>
                    <div className="DrawerSectionGroup">
                        <span>SEARCH FOR A CAMERA</span>
                        <div className="SearchInputGroup">
                            <Input
                                placeholder="Enter camera name"
                                className="DrawerSearch"
                                value={searchTerms}
                                onChange={(e) => {
                                    setSearchTerms(e.target.value);
                                }}
                            />
                            {searchTerms.length ? (
                                <div
                                    className="CCTVClearSearchInput"
                                    onClick={() => {
                                        setSearchTerms("");
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="ClearSearchIcon"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <CameraGroupSelect cameras={cameras} />
                    <CameraStatusSelect setRecencyFilter={setFilter} />
                </div>
                <div className="DrawerSection CameraList">
                    <div className="DrawerTitle">
                        <CCTVIcon className="CCTVDrawerIcon" />
                        Camera list
                    </div>
                    <div className="CameraListContainer">
                        {cameras ? (
                            <FilteredCameraList
                                cameras={cameras}
                                filter={filter}
                                setCameraInfo={setCameraInfo}
                                toggleModal={toggleModal}
                                modalVisible={modalVisible}
                                searchTerms={searchTerms}
                                visible={visible}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className="DrawerClose"
                onClick={() => {
                    toggleVisible();
                    if (modalVisible) {
                        toggleModal();
                    }
                }}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </div>
        </div>
    );
};

export default CCTVListDrawer;
