import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import memoizeOne from "memoize-one";
import { getMarkersForWorkspace, receiveHistoryMarkers, setUserPreference, toggleMarkerHistoryLoading } from "redux/actions";
import OBCSpinner from "./util/OBC";
import { findMostRecentMetadata, getAbsoluteTimestamp, videoKeyToTimestamp } from "./util/PlaylistUtils";
import _ from "lodash";
import moment from "moment";
import { Divider, Select, Modal, Checkbox } from "antd";
import { calculateBearingText } from "./util/Geometry";

const currentMarker = memoizeOne((markers, routeID) => {
    let mks = markers.perSession[routeID];
    if (mks && mks.length) {
        let m = mks.filter((marker) => marker.id === markers.selectedMarker.id);
        return m[0];
    } else {
        return null;
    }
});

const EMPTY_ARRAY = [];

const currentMarkerSelector = (state) => {
    const markers = state.markers;
    const routeID = state.playlist.data.routeID;

    return currentMarker(markers, routeID);
};
const markerHistorySelector = (state) => state.markers.history;
const currentSessionSelector = (state) => state.sessions[state.playlist.data.routeID];
const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], EMPTY_ARRAY);
const timestampSelector = (state) => getAbsoluteTimestamp(state.playlist);
const markerHistoryEnabledSelector = (state) => state.userPreferences.thermalHistory;

const MarkerHistoryComponent = ({ openPreviwWithImage }) => {
    const dispatch = useDispatch();
    const currentMarker = useSelector(currentMarkerSelector);
    const markerHistory = useSelector(markerHistorySelector);
    const currentSession = useSelector(currentSessionSelector);
    const speedMetadata = useSelector(speedAndBearingSelector);
    const timestamp = useSelector(timestampSelector);
    const markerHistoryEnabled = useSelector(markerHistoryEnabledSelector);

    const [reviewStatus, setReviewStatus] = useState(1);
    const [direction, setDirection] = useState("all");
    const [stateMarker, setStateMarker] = useState({});

    const reqAbortRef = useRef();

    const markersWithBearing = useMemo(() => {
        return markerHistory.markers.map((marker) => {
            let { bearing, direction } = marker;
            let bearingText;
            if (direction === "Backward") {
                bearing += 180;
                bearingText = calculateBearingText(bearing);
            } else if (direction === "Forward") {
                bearingText = calculateBearingText(bearing);
            } else if (!direction) {
                bearingText = "Unknown";
            }

            return {
                ...marker,
                bearingText: bearingText,
            };
        });
    }, [markerHistory.markers]);

    useEffect(() => {
        if (stateMarker && stateMarker.id && stateMarker.name === "Thermal Hotspot" && markerHistoryEnabled) {
            abortMarkerRequest();
            dispatch(toggleMarkerHistoryLoading());
            const markerPosition = stateMarker.coords;

            const bounds = {
                north: markerPosition[0] + 0.00025,
                south: markerPosition[0] - 0.00025,
                east: markerPosition[1] + 0.00025,
                west: markerPosition[1] - 0.00025,
            };

            const timestamp = videoKeyToTimestamp(stateMarker.video_key) / 1000;

            let postBody = {
                action: "get_workspace_markers",
                ...bounds,
                pastTimestamp: (timestamp - 60 * 60 * 24 * 30) * 1000,
                type: "Thermal Hotspot",
                reviewStatus: reviewStatus,
            };

            const abortController = new AbortController();

            reqAbortRef.current = abortController;

            dispatch(
                getMarkersForWorkspace(
                    postBody,
                    (markers) => {
                        const timeNow = Date.now() / 1000;
                        reqAbortRef.current = null;

                        const timestampedMarkers = markers.map((marker) => {
                            const markerTimestamp = videoKeyToTimestamp(marker.video_key);
                            return {
                                ...marker,
                                timestamp: markerTimestamp,
                                fromNow: Math.abs(timeNow - markerTimestamp),
                            };
                        });
                        dispatch(receiveHistoryMarkers(timestampedMarkers));
                    },
                    abortController.signal,
                ),
            );
        } else {
            dispatch(receiveHistoryMarkers([]));
        }
    }, [stateMarker, dispatch, reviewStatus, markerHistoryEnabled]);

    useEffect(() => {
        if (currentMarker && currentMarker.id !== stateMarker.id) {
            setStateMarker(currentMarker);
        }
    }, [currentMarker, stateMarker]);

    const abortMarkerRequest = () => {
        if (reqAbortRef.current) {
            reqAbortRef.current.abort("user_abort");
            reqAbortRef.current = null;
        }
    };

    const filteredMarkers = useMemo(() => {
        if (!currentMarker) {
            return [];
        }
        return markersWithBearing.filter((marker) => {
            return (
                (direction === marker.bearingText || direction === "all") && (marker.id === currentMarker.id || marker.session_id !== currentMarker.session_id)
            );
        });
    }, [direction, markersWithBearing, currentMarker]);

    const markerHistoryItems = useMemo(() => {
        if (!currentMarker) {
            return null;
        }

        if (!markerHistory.markers.length) {
            return (
                <div className="NoDetectionsText">
                    <span>No detections found nearby</span>
                </div>
            );
        } else if (!filteredMarkers.length) {
            return (
                <div className="NoDetectionsText">
                    <span>No detections matching filters</span>
                </div>
            );
        }

        return _.orderBy(filteredMarkers, ["fromNow"], ["desc"]).map((marker) => {
            const relativeString = moment.unix(marker.timestamp / 1000).fromNow();
            return (
                <div
                    className={"MarkerHistoryItem" + (currentMarker.id === marker.id ? " Active" : "")}
                    onClick={() => openPreviwWithImage(marker.custom_thumbnail)}>
                    {currentMarker.id === marker.id && <span className="CurrentDetectionLabel">Current Detection</span>}
                    <img
                        src={marker.custom_thumbnail}
                        className="MarkerHistoryThumbnail"
                        alt="Thermal Hotspot"
                        crossOrigin={"anonymous"}
                    />
                    <p className="MarkerHistoryDate">{relativeString}</p>
                </div>
            );
        });
    }, [markerHistory.markers, filteredMarkers, currentMarker]);

    const sessionDirection = useMemo(() => {
        if (!currentSession || !currentSession.tags) {
            return false;
        }

        if (currentSession.tags.includes("Backward")) {
            return "Backward";
        } else if (currentSession.tags.includes("Forward")) {
            return "Forward";
        } else {
            return null;
        }
    }, [currentSession]);

    const currentMarkerDirection = useMemo(() => {
        const speedInfo = findMostRecentMetadata(speedMetadata, timestamp);

        let bearingText = "Unknown";

        if (!_.isNil(speedInfo?.data.bearing)) {
            let bearing = speedInfo.data.bearing;

            if (sessionDirection) {
                if (sessionDirection === "Backward") {
                    bearing += 180;
                }
                bearingText = calculateBearingText(bearing);
            }
        }
        return bearingText;
    }, [sessionDirection, speedMetadata, timestamp]);

    const directionOptions = useMemo(() => {
        const directions = _.uniq(
            markersWithBearing.map((marker) => {
                return marker.bearingText;
            }),
        );

        return directions.map((direction) => {
            return (
                <Select.Option
                    value={direction}
                    key={direction}>
                    {direction} {currentMarkerDirection === direction && "(Current direction)"}
                </Select.Option>
            );
        });
    }, [markersWithBearing, currentMarkerDirection]);

    if (!currentMarker) {
        return null;
    }

    return (
        <div className="MarkerHistoryItemsContainer">
            <Divider
                plain
                style={{ color: "white" }}>
                <Checkbox
                    onChange={(e) => dispatch(dispatch(setUserPreference("thermalHistory", e.target.checked)))}
                    checked={markerHistoryEnabled}>
                    Nearby Detections
                </Checkbox>
            </Divider>

            {markerHistoryEnabled && (
                <div className="MarkerHistoryContainer">
                    <div className="MarkerHistoryHeader">
                        <div className="FilterItem">
                            <span>Camera Direction</span>
                            <Select
                                value={direction}
                                onChange={setDirection}
                                dropdownMatchSelectWidth={false}
                                size="small">
                                <Select.Option
                                    value={"all"}
                                    key={"all"}>
                                    All
                                </Select.Option>
                                {directionOptions}
                            </Select>
                        </div>

                        <div className="FilterItem">
                            <span>Review Status</span>
                            <Select
                                value={reviewStatus}
                                onChange={setReviewStatus}
                                dropdownMatchSelectWidth={false}
                                size="small">
                                <Select.Option value={1}>Verified</Select.Option>
                                <Select.Option value={0}>Needs Review</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="ItemsGrid">
                        {markerHistory.loading && (
                            <div className="NoDetectionsText">
                                <OBCSpinner
                                    color={"#e8dfff"}
                                    size={100}
                                />
                            </div>
                        )}
                        {!markerHistory.loading && markerHistoryItems}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkerHistoryComponent;
