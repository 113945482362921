import React, { useState } from "react";
import { Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { removeTrainingForDriver, getDriverTrainingData } from "redux/actions/driverTrainingActions";

const RemoveTrainingModal = ({ removeTrainingModalOpen, setRemoveTrainingModalOpen, trainingData, selectedCategoryID, driverData }) => {
    const dispatch = useDispatch();
    const [removingTraining, setRemovingTraining] = useState(false);

    const onRemoveTrainingOkClicked = () => {
        console.log("debug onRemoveTrainingOkClicked");
        setRemovingTraining(true);
        dispatch(
            removeTrainingForDriver(driverData.user_seat_id, [selectedCategoryID], (res) => {
                console.log("debug onRemoveTrainingOkClicked", res);
                if (res.success) {
                    dispatch(
                        getDriverTrainingData(driverData.user_seat_id, (response) => {
                            setRemovingTraining(false);
                            setRemoveTrainingModalOpen(false);
                            notification.success({
                                message: "Success",
                                description: "Training successfully removed",
                            });
                        }),
                    );
                } else {
                    setRemovingTraining(false);
                }
            }),
        );
    };

    const onRemoveTrainingCancelClicked = () => {
        console.log("debug onRemoveTrainingOkClicked");
        setRemoveTrainingModalOpen(false);
    };

    return (
        <Modal
            className="AddTrainingModal"
            title="Remove training"
            visible={removeTrainingModalOpen}
            onOk={onRemoveTrainingOkClicked}
            okText={removingTraining ? "Removing" : "Remove"}
            okButtonProps={{ disabled: removingTraining, loading: removingTraining }}
            closable={false}
            onCancel={onRemoveTrainingCancelClicked}>
            <>
                <h3>
                    Are you sure you want to remove <span>{trainingData[selectedCategoryID] && trainingData[selectedCategoryID].category_name}</span> from the
                    list of traninings for <span>{driverData && driverData.name}</span>?
                </h3>
                <h4>Please note if removed progress will be lost!</h4>
            </>
        </Modal>
    );
};

export default RemoveTrainingModal;
