import React from "react";
import { connect } from "react-redux";
import { saveCableMeasurement, deleteCableMeasurement, toggleUnsavedChange } from "../../../../redux/actions/index";
import { drawLine, projectToIntersection, toPercent, worldToImageCoordinates, drawCircle } from "../../../util/Geometry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faArrowsAltH, faUndo, faSortUp, faSortDown, faTrashAlt, faEye, faEyeSlash, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { Input, Modal, Checkbox } from "antd";
import _ from "lodash";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";

class CableMeasureOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            horizontalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
            verticalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
            measureDistance: [0, 0, 0],
            railLines: [
                [
                    [-2, -2],
                    [-2, -2],
                ],
                [
                    [-2, -2],
                    [-2, -2],
                ],
            ],
            measureVertical: false,
            selectedPoints: [],
            mousePosition: [0, 0],
            showControls: false,
            measuringDirection: "z",
            totalDistance: 0,
            showSavedMeasureOverlay: true,
            completedMeasurements: [],
            savedMeasurements: [],
            displayModal: false,
            measurementNameInput: "",
            modalPrivacy: this.props.privateByDefault,
            newMousePos: [0, 0],
            inputError: "",
            segmentDistance: 0,
        };

        this.canvas = React.createRef();
    }

    componentDidMount() {
        this.initComponent();
        window.addEventListener("keydown", this.keyBindListener);
    }

    keyBindListener = (event) => {
        if (!this.state.displayModal) {
            return;
        }

        event.stopPropagation();
        switch (event.key) {
            case "Enter":
                this.saveMeasurement();
                break;
            default:
                break;
        }
    };

    initComponent = () => {
        const halfWidth = this.props.calibration.railSeparation / 2;
        const closestPoint1 = worldToImageCoordinates([halfWidth, 0, 0], this.props.calibration);
        const vanishingPoint = worldToImageCoordinates([0, 0, 10000], this.props.calibration);
        const closestPoint2 = worldToImageCoordinates([-halfWidth, 0, 0], this.props.calibration);

        this.setState({
            railLines: [
                [closestPoint1, vanishingPoint],
                [closestPoint2, vanishingPoint],
            ],
            savedMeasurements: this.props.measurements,
            completedMeasurements: [],
            selectedPoints: [],
            showControls: false,
            showSavedMeasureOverlay: this.props.measurements.length > 0,
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            JSON.stringify(this.props.measurements) !== JSON.stringify(prevProps.measurements) ||
            this.props.videoKey !== prevProps.videoKey ||
            prevProps.frame !== this.props.frame ||
            !_.isEqual(prevProps.calibration, this.props.calibration)
        ) {
            console.log("debug init component");

            console.log("debug", prevProps.calibration, this.props.calibration);

            this.initComponent();
        }

        if (this.props.privateByDefault !== prevProps.privateByDefault) {
            this.setState({
                modalPrivacy: this.props.privateByDefault,
            });
        }
    };

    plotCablePoints = () => {
        let points = [];

        this.state.completedMeasurements.forEach((measurement) => {
            //SKIP HIDDEN MEAUREMENTS HERE
            if (measurement.hidden) {
                return;
            }
            return measurement.measurements.forEach((point) => {
                let imageCoords = point[0];
                points.push(drawCircle(imageCoords, 3, JSON.stringify(imageCoords), "CableMarker"));
            });
        });

        this.state.selectedPoints.forEach((point, index) => {
            let imageCoords = point[0];
            points.push(drawCircle(imageCoords, 3, JSON.stringify(imageCoords), "CableMarker"));
        });

        return points;
    };

    plotCablePaths = () => {
        let lines = [];
        let selectedPoints = this.state.selectedPoints;
        if (selectedPoints.length > 1) {
            for (let i = 0; i < selectedPoints.length; i += 1) {
                let point1 = selectedPoints[i][0];
                let point2 = selectedPoints[i + 1];
                if (point2) {
                    let point2Coords = point2[0];
                    lines.push(drawLine(point1, point2Coords, `${i}`, "CableMeasurementLine"));
                }
            }
        }

        this.state.savedMeasurements.forEach((measurementObj) => {
            if (measurementObj.hidden) {
                return;
            }
            let measurements = measurementObj.measurements;
            if (measurements.length > 1) {
                for (let i = 0; i < measurements.length; i += 1) {
                    let point1 = measurements[i][0];
                    let point2 = measurements[i + 1];
                    if (point2) {
                        let point2Coords = point2[0];
                        lines.push(drawLine(point1, point2Coords, `${measurementObj.name}${i}`, "CableMeasurementLine"));
                    }
                }
            }
        });

        this.state.completedMeasurements.forEach((measurementObj) => {
            if (measurementObj.hidden) {
                return;
            }
            let measurements = measurementObj.measurements;
            if (measurements.length > 1) {
                for (let i = 0; i < measurements.length; i += 1) {
                    let point1 = measurements[i][0];
                    let point2 = measurements[i + 1];
                    if (point2) {
                        let point2Coords = point2[0];
                        lines.push(drawLine(point1, point2Coords, `${JSON.stringify(measurementObj)}${i}`, "CableMeasurementLine"));
                    }
                }
            }
        });
        return lines;
    };

    mouseClick = (evt) => {
        if (this.props.calibration) {
            this.openMeasureControls();

            let currentTargetRect = this.canvas.current.getBoundingClientRect();
            let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
                y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;

            x = 2 * Math.max(0, Math.min(1, x)) - 1;
            y = 2 * Math.max(0, Math.min(1, y)) - 1;

            let worldCoords = this.calculateWorldCoords(x, y);

            let newXY = worldToImageCoordinates(worldCoords, this.props.calibration);

            let distance = 0;

            if (this.state.selectedPoints.length > 0) {
                // let secondLastPoint = this.state.selectedPoints[this.state.selectedPoints.length - 2];
                let lastPointWorldCoords = this.state.selectedPoints[this.state.selectedPoints.length - 1][1];
                distance = this.calculatePointDistance(lastPointWorldCoords, worldCoords);
            }

            this.setState(
                {
                    selectedPoints: [...this.state.selectedPoints.slice(), [newXY, worldCoords, distance]],
                },
                () => {
                    if (this.state.selectedPoints.length > 1) {
                        this.props.dispatch(toggleUnsavedChange(true));
                    }
                },
            );

            this.setState({ newMousePos: [newXY[0], newXY[1]] });
        }
    };

    mouseMove = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        let currentTargetRect = this.canvas.current.getBoundingClientRect();
        let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
            y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;

        this.updateCurrentMousePosition(x, y);
        this.updateMeasureDistances(x, y);
    };

    updateCurrentMousePosition = (x, y) => {
        x = 2 * Math.max(0, Math.min(1, x)) - 1;
        y = 2 * Math.max(0, Math.min(1, y)) - 1;

        let worldCoords = this.calculateWorldCoords(x, y);

        let newXY = worldToImageCoordinates(worldCoords, this.props.calibration);

        let segmentDistance = 0;

        if (this.state.selectedPoints.length > 0) {
            let lastPointWorldCoords = this.state.selectedPoints[this.state.selectedPoints.length - 1][1];
            segmentDistance = this.calculatePointDistance(lastPointWorldCoords, worldCoords);
        }

        this.setState({
            newMousePos: [newXY[0], newXY[1]],
            segmentDistance,
        });
    };

    mouseLeave = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    calculatePointDistance = (point1, point2) => {
        let worldPoint1 = point1;
        let worldPoint2 = point2;

        let horizontalDistance = 0;
        let verticalDistance = 0;
        let zDistance = 0;

        if (this.state.measuringDirection === "x") {
            horizontalDistance = Math.max(worldPoint1[0], worldPoint2[0]) - Math.min(worldPoint1[0], worldPoint2[0]);
        } else if (this.state.measuringDirection === "y") {
            verticalDistance = Math.max(worldPoint1[1], worldPoint2[1]) - Math.min(worldPoint1[1], worldPoint2[1]);
        } else if (this.state.measuringDirection === "z") {
            zDistance = Math.max(worldPoint1[2], worldPoint2[2]) - Math.min(worldPoint1[2], worldPoint2[2]);
        }

        let distance = Math.max(horizontalDistance, verticalDistance, zDistance);
        return distance;
    };

    getDisplayDistance = (metres) => {
        if (this.props.measurementUnits === "yards") {
            return `${Math.round(metres * 1.09361 * 100) / 100}yd`;
        } else {
            return `${Math.round(metres * 100) / 100}m`;
        }
    };

    getTotalDistance = () => {
        let total = 0;
        this.state.selectedPoints.forEach((point) => {
            total += point[2];
        });

        return this.getDisplayDistance(total);
    };

    getCurrentTotalDistance = () => {
        let total = 0;
        this.state.selectedPoints.forEach((point) => {
            total += point[2];
        });

        total += this.state.segmentDistance;

        return this.getDisplayDistance(total);
    };

    updateMousePosition = (x, y) => {
        if (this.state.measuringDirection === "y") {
            this.setState({
                mousePosition: [this.state.mousePosition[0], y],
            });
        } else if (this.state.measuringDirection === "x") {
            this.setState({
                mousePosition: [x, this.state.mousePosition[1]],
            });
        } else if (this.state.measuringDirection === "z") {
            this.setState({
                mousePosition: [x, y],
            });
        }
    };

    calculateWorldCoords = (x, y) => {
        let worldIntersection;
        if (this.state.measuringDirection === "y" || this.state.measuringDirection === "x") {
            let lastPoint = this.state.selectedPoints[this.state.selectedPoints.length - 1];
            let zPoint = this.state.measureDistance[2];
            if (lastPoint) {
                zPoint = lastPoint[1][2];
            }
            worldIntersection = [-10000, -10000, zPoint];
        } else {
            let lastPoint = this.state.selectedPoints[this.state.selectedPoints.length - 1];
            let yPoint = 0;
            let xPoint = -10000;
            if (lastPoint) {
                xPoint = lastPoint[1][0];
                yPoint = lastPoint[1][1];
            }

            worldIntersection = [xPoint, yPoint, 1000];
        }

        let worldCoords = projectToIntersection([x, y], worldIntersection, this.props.calibration);

        if (this.state.selectedPoints.length > 0) {
            let lastPoint = this.state.selectedPoints[this.state.selectedPoints.length - 1];
            let lastWorldCoords = lastPoint[1];
            if (this.state.measuringDirection === "y") {
                worldCoords[0] = lastWorldCoords[0];
                worldCoords[2] = lastWorldCoords[2];
            } else if (this.state.measuringDirection === "x") {
                worldCoords[1] = lastWorldCoords[1];
                worldCoords[2] = lastWorldCoords[2];
            } else if (this.state.measuringDirection === "z") {
                worldCoords[0] = lastWorldCoords[0];
                worldCoords[1] = lastWorldCoords[1];
            }
        }

        return worldCoords;
    };

    updateMeasureDistances(x, y) {
        let worldCoordinates = this.calculateWorldCoords(x, y);

        if (worldCoordinates !== null) {
            let railPosition = [this.props.calibration.railSeparation / 2, 0, worldCoordinates[2]];
            let multiplier = 1;
            if (worldCoordinates[0] < 0) {
                railPosition[0] *= -1;
                multiplier *= -1;
            }

            this.setState({
                measureDistance: [
                    Math.round(multiplier * (worldCoordinates[0] - railPosition[0]) * 100) / 100, //distance from rail
                    Math.round(worldCoordinates[1] * 100) / 100, //from ground
                    Math.round(worldCoordinates[2] * 100) / 100, //distance
                ],
            });
            const railImageCoords = worldToImageCoordinates(railPosition, this.props.calibration);
            const groundImageCoords = worldToImageCoordinates([worldCoordinates[0], 0, worldCoordinates[2]], this.props.calibration);

            this.setState({
                horizontalMeasure: [railImageCoords, groundImageCoords],
                verticalMeasure: [groundImageCoords, [x, y]],
            });
        }
    }

    saveMeasurement = () => {
        let measureObj = {
            name: this.state.measurementNameInput,
            measurements: [...this.state.selectedPoints],
            private: this.state.modalPrivacy,
        };

        if (this.state.measurementNameInput === "") {
            this.setState({
                inputError: "Measurement name cannot be empty",
            });
            return;
        }

        this.setState(
            {
                completedMeasurements: [...this.state.completedMeasurements, measureObj],
                selectedPoints: [],
                measurementNameInput: "",
                measuringDirection: "z",
            },
            () => {
                let videoKey = this.props.videoKey;
                let frame = this.props.frame;
                let measurement = JSON.stringify(measureObj);
                let sessionKey = this.props.session.uuid;
                let deviceKey = this.props.session.device_uuid;
                let sessionID = this.props.session.id;

                this.props.dispatch(saveCableMeasurement(videoKey, frame, measurement, sessionKey, deviceKey, sessionID, measureObj.private));
                this.props.dispatch(toggleUnsavedChange(false));
                this.hideModal();
                this.closeMeasureControls();
                // this.initComponent();
            },
        );
    };

    openMeasureControls = () => {
        this.setState({
            showControls: true,
        });
    };

    closeMeasureControls = () => {
        this.setState({
            showControls: false,
        });
    };

    changeAxis = (axis) => {
        this.setState({
            measuringDirection: axis,
            showControls: false,
        });
    };

    getSvgClass = () => {
        if (this.state.showControls) {
            return "dimmedMeasurementOverlay ";
        } else {
            return "crosshair ";
        }
    };

    undoLastPoint = () => {
        if (this.state.selectedPoints.length > 0) {
            let newArray = this.state.selectedPoints.slice();
            newArray.pop();
            this.setState(
                {
                    selectedPoints: newArray,
                },
                () => {
                    if (this.state.selectedPoints.length === 0) {
                        this.setState({
                            showControls: false,
                            measuringDirection: "z",
                        });
                        this.props.dispatch(toggleUnsavedChange(false));
                        // this.initComponent();
                    }
                    // this.updateCurrentMousePosition();
                },
            );
        }
    };

    renderSegmentDistances = () => {
        let texts = [];

        let selectedPoints = this.state.selectedPoints;
        if (selectedPoints.length < 2) {
            return null;
        }

        selectedPoints.forEach((point, index) => {
            let distance = point[2];

            if (distance > 0) {
                texts.push(
                    <span className="segmentMeasurementText">
                        {`Stretch ${index}: `} <b>{this.getDisplayDistance(distance)}</b>
                    </span>,
                );
            }
        });
        return texts;
    };

    renderMeasurementList = () => {
        let texts = [];

        let savedMeasurements = this.state.savedMeasurements;
        let completedMeasurements = this.state.completedMeasurements;
        savedMeasurements.forEach((measurementObj) => {
            let totalLength = 0;
            measurementObj.measurements.forEach((measurement) => {
                if (measurement[2]) {
                    totalLength += measurement[2];
                }
            });

            texts.push(
                <tr
                    key={measurementObj.name}
                    className="measurementRow">
                    <td className="segmentTableCell">
                        <FontAwesomeIcon
                            color={"white"}
                            icon={measurementObj.hidden ? faEyeSlash : faEye}
                            onClick={() => this.hideMeasurement(measurementObj.name)}
                        />
                    </td>
                    <td className="segmentTableCell">
                        <p className="segmentMeasurementText">{this.getDisplayDistance(totalLength)}</p>
                    </td>
                    <td className="segmentTableCell">
                        <p className="segmentMeasurementText">{measurementObj.name}</p>
                    </td>
                    <td className="segmentTableCell">
                        <p className="segmentMeasurementText">{measurementObj.user}</p>
                    </td>
                    <td className="segmentTableCell">
                        {measurementObj.isMine && (
                            <FontAwesomeIcon
                                onClick={() => this.toggleMeasurementPrivacy(measurementObj)}
                                icon={measurementObj.private ? faLock : faLockOpen}
                                className="deleteMeasureIcon"
                                color={"white"}
                            />
                        )}
                    </td>
                    <td className="segmentTableCell">
                        {measurementObj.isMine && (
                            <FontAwesomeIcon
                                onClick={() => this.deleteMeasurement(measurementObj.id)}
                                icon={faTrashAlt}
                                className="deleteMeasureIcon"
                                color={"white"}
                            />
                        )}
                    </td>
                </tr>,
            );
        });

        completedMeasurements.forEach((measurementObj) => {
            texts.push(
                <tr
                    key={measurementObj.name}
                    className="measurementRow">
                    <td className="segmentTableCell">
                        <FontAwesomeIcon
                            color={"white"}
                            icon={measurementObj.hidden ? faEyeSlash : faEye}
                            onClick={() => this.hideMeasurement(measurementObj.name)}
                        />
                    </td>
                    <td className="segmentTableCell">35m</td>
                    <td className="segmentTableCell">
                        <p className="segmentMeasurementText">{measurementObj.name}</p>
                    </td>
                    <td className="segmentTableCell">
                        <p className="segmentMeasurementText">Me</p>
                    </td>
                    <td className="segmentTableCell">
                        <FontAwesomeIcon
                            onClick={() => this.toggleMeasurementPrivacy(measurementObj)}
                            icon={measurementObj.private ? faLock : faLockOpen}
                            className="deleteMeasureIcon"
                            color={"white"}
                        />
                    </td>
                    <td className="segmentTableCell">
                        <FontAwesomeIcon
                            onClick={() => this.deleteMeasurement(measurementObj.id)}
                            icon={faTrashAlt}
                            className="deleteMeasureIcon"
                            color={"white"}
                        />
                    </td>
                </tr>,
            );
        });
        return texts;
    };

    deleteMeasurement = (id) => {
        console.log("Deleting measurement with ID", id);
        this.props.dispatch(deleteCableMeasurement(id, this.props.session.id));
    };

    toggleMeasurementPrivacy = (measureObj) => {
        const index = _.findIndex(this.state.savedMeasurements, (m) => m.id === measureObj.id);
        if (index > -1) {
            const savedMeasurements = this.state.savedMeasurements.slice();
            const value = savedMeasurements[index].private;
            savedMeasurements[index].private = !value;
            this.setState({ savedMeasurements });
        } else {
            const index = _.findIndex(this.state.completedMeasurements, (m) => m.id === measureObj.id);
            if (index > -1) {
                const completedMeasurements = this.state.completedMeasurements.slice();
                const value = completedMeasurements[index].private;
                completedMeasurements[index].private = !value;
                this.setState({ completedMeasurements });
            }
        }

        const videoKey = this.props.videoKey;
        const frame = this.props.frame;
        const measurement = JSON.stringify(measureObj);
        const sessionKey = this.props.session.uuid;
        const deviceKey = this.props.session.device_uuid;
        const sessionID = this.props.session.id;
        this.props.dispatch(saveCableMeasurement(videoKey, frame, measurement, sessionKey, deviceKey, sessionID, measureObj.private, measureObj.id));
    };

    hideMeasurement = (measurementName) => {
        let measurementIndex = _.findIndex(this.state.savedMeasurements, function (measurement) {
            return measurement.name === measurementName;
        });

        if (measurementIndex > -1) {
            let savedMeasurements = this.state.savedMeasurements.slice();
            const value = savedMeasurements[measurementIndex].hidden;
            savedMeasurements[measurementIndex].hidden = !value;
            this.setState({
                savedMeasurements,
            });
        } else {
            let completedMeasurementIndex = _.findIndex(this.state.completedMeasurements, function (measurement) {
                return measurement.name === measurementName;
            });

            if (completedMeasurementIndex > -1) {
                let completedMeasurements = this.state.completedMeasurements.slice();
                const value = completedMeasurementIndex[completedMeasurementIndex].hidden;
                completedMeasurements[completedMeasurementIndex].hidden = !value;
                this.setState({
                    completedMeasurements,
                });
            }
        }
    };

    hideSegmentOverlay = () => {
        this.setState({
            showSavedMeasureOverlay: false,
        });
    };

    showSavedMeasureOverlay = () => {
        this.setState({
            showSavedMeasureOverlay: true,
        });
    };

    newMeasurement = () => {
        console.log("New measurement");
        this.setState({
            completedMeasurements: [...this.state.completedMeasurements, [...this.state.selectedPoints]],
            selectedPoints: [],
        });
    };

    hideModal = () => {
        this.setState({
            displayModal: false,
            inputError: "",
            measurementNameInput: "",
        });
    };

    showModal = () => {
        if (this.state.selectedPoints.length > 1) {
            this.setState({
                displayModal: true,
            });
        }
    };

    measurementNameChange = (e) => {
        this.setState({
            measurementNameInput: e.target.value,
        });
    };

    modalPrivacyChange = (e) => {
        this.setState({
            modalPrivacy: e.target.checked,
        });
    };

    render() {
        let xMouse = this.state.newMousePos[0];
        if (this.state.newMousePos[0] > 0) {
            const rectWidth = 400 / this.canvas.current.getBoundingClientRect().width;
            xMouse -= rectWidth;
        }

        let yMouse = this.state.newMousePos[1];
        if (this.state.newMousePos[1] > 0) {
            const rectHeight = 100 / this.canvas.current.getBoundingClientRect().height;
            yMouse -= rectHeight;
        }

        let rectYcord = toPercent(yMouse);
        let rectXcord = toPercent(xMouse);

        return (
            <React.Fragment>
                <Modal
                    getContainer={false}
                    title="Save Measurement"
                    visible={this.state.displayModal}
                    zIndex={2000}
                    onOk={this.saveMeasurement}
                    onCancel={this.hideModal}>
                    <React.Fragment>
                        <h3>Measurement Name</h3>
                        <Input
                            value={this.state.measurementNameInput}
                            onChange={this.measurementNameChange}
                            placeholder="Enter measurement name"
                            style={{ marginBottom: 10 }}
                        />
                        {this.state.inputError && <p className="error">{this.state.inputError}</p>}
                        <Checkbox
                            checked={this.state.modalPrivacy}
                            onChange={this.modalPrivacyChange}>
                            Private
                        </Checkbox>
                    </React.Fragment>
                </Modal>

                <div className={"MeasurementOverlay"}>
                    {this.state.showControls && (
                        <div className="cableMeasureOverlay">
                            <h1 className="white">Current Measurement {this.getTotalDistance()}</h1>

                            <div className="cableMeasureControls">
                                <div
                                    onClick={() => this.changeAxis("x")}
                                    className="cableMeasureButton">
                                    <FontAwesomeIcon
                                        className="markerIcon"
                                        icon={faArrowsAltH}
                                        size="3x"
                                        color={"white"}
                                    />
                                    <h4 className="white">Left / Right</h4>
                                </div>
                                <div
                                    onClick={() => this.changeAxis("y")}
                                    className="cableMeasureButton">
                                    <FontAwesomeIcon
                                        className="markerIcon"
                                        icon={faArrowsAltH}
                                        size="3x"
                                        color={"white"}
                                        rotation={90}
                                    />
                                    <h4 className="white">Up / Down</h4>
                                </div>
                                <div
                                    onClick={() => this.changeAxis("z")}
                                    className="cableMeasureButton">
                                    <FontAwesomeIcon
                                        icon={faArrowsAltH}
                                        size="3x"
                                        color={"white"}
                                        className="rotate-45-right"
                                    />
                                    <h4 className="white">Forward / Back</h4>
                                </div>
                                <div
                                    onClick={this.undoLastPoint}
                                    className={this.state.selectedPoints.length > 0 ? "cableMeasureButton" : "cableMeasureButtonDisabled"}>
                                    <FontAwesomeIcon
                                        className="markerIcon"
                                        icon={faUndo}
                                        size="2x"
                                        color={this.state.selectedPoints.length > 0 ? "white" : "grey"}
                                    />
                                    <h4 className="white">Undo</h4>
                                </div>
                                <div
                                    onClick={this.showModal}
                                    className={this.state.selectedPoints.length > 1 ? "cableMeasureButton" : "cableMeasureButtonDisabled"}>
                                    <FontAwesomeIcon
                                        className="markerIcon"
                                        icon={faSave}
                                        size="3x"
                                    />
                                    <h4 className={this.state.selectedPoints.length > 1 ? "white" : "grey"}>Save Measurement</h4>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.showSavedMeasureOverlay ? (
                        <div className={"cableMeasurementSegments"}>
                            <span
                                className="hideCableSegments"
                                onClick={this.hideSegmentOverlay}>
                                Hide
                                <FontAwesomeIcon
                                    className="toggleSegmentsIconHide"
                                    icon={faSortDown}
                                    size="1x"
                                />
                            </span>
                            <div className="segmentsDiv">
                                <table className="segmentsTable">
                                    <tbody>{this.renderMeasurementList()}</tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className={"cableMeasurementSegmentsHidden"}>
                            <span
                                className="hideCableSegments"
                                onClick={this.showSavedMeasureOverlay}>
                                Show Measurements
                                <FontAwesomeIcon
                                    className="toggleSegmentsIconShow"
                                    icon={faSortUp}
                                    size="1x"
                                />
                            </span>
                        </div>
                    )}

                    <svg
                        className={"MeasurementOverlay " + this.getSvgClass() + this.props.className}
                        onMouseMove={this.mouseMove}
                        onClick={this.mouseClick}
                        onMouseLeave={this.mouseLeave}
                        ref={this.canvas}>
                        {drawLine(this.state.railLines[0][0], this.state.railLines[0][1], "CentreLine", "CentreLine Dim")}
                        {drawLine(this.state.railLines[1][0], this.state.railLines[1][1], "CentreLine1", "CentreLine Dim")}

                        {this.state.measuringDirection === "x" && this.state.selectedPoints.length > 0 && !this.state.showControls
                            ? drawLine(
                                  this.state.selectedPoints[this.state.selectedPoints.length - 1][0],
                                  this.state.newMousePos,
                                  "HorizontalMeasureLine",
                                  "HorizontalMeasureLine",
                              )
                            : null}

                        {this.state.measuringDirection === "y" && this.state.selectedPoints.length > 0 && !this.state.showControls
                            ? drawLine(
                                  this.state.selectedPoints[this.state.selectedPoints.length - 1][0],
                                  this.state.newMousePos,
                                  "VerticalMeasureLine",
                                  "VerticalMeasureLine",
                              )
                            : null}
                        {this.state.measuringDirection === "z" && this.state.selectedPoints.length > 0 && !this.state.showControls
                            ? drawLine(
                                  this.state.selectedPoints[this.state.selectedPoints.length - 1][0],
                                  this.state.newMousePos,
                                  "VerticalMeasureLine",
                                  "VerticalMeasureLine",
                              )
                            : null}
                        {this.plotCablePoints()}
                        {this.plotCablePaths()}

                        {this.state.selectedPoints.length === 0 ? (
                            <React.Fragment>
                                <rect
                                    x={toPercent(this.state.newMousePos[0])}
                                    y={toPercent(this.state.newMousePos[1])}
                                    dx={20}
                                    dy={20}
                                    width={320}
                                    height={33}
                                    className="textRectangle"
                                />
                                <text
                                    x={toPercent(this.state.newMousePos[0])}
                                    y={toPercent(this.state.newMousePos[1])}
                                    dx={10}
                                    dy={20}
                                    className="MeasureText">
                                    Click a point on the ground to begin measuring
                                </text>
                            </React.Fragment>
                        ) : null}

                        {this.state.selectedPoints.length > 0 && !this.state.showControls && (
                            <>
                                <rect
                                    x={rectXcord}
                                    y={rectYcord}
                                    width={200}
                                    height={50}
                                    rx={8}
                                    className="MeasureBG"
                                />
                                <text
                                    x={rectXcord}
                                    y={rectYcord}
                                    dx={10}
                                    dy={20}
                                    className="MeasureText">
                                    Segment Length : {this.getDisplayDistance(this.state.segmentDistance)}
                                </text>
                                <text
                                    x={rectXcord}
                                    y={rectYcord}
                                    dx={10}
                                    dy={40}
                                    className="MeasureText">
                                    Total Length : {this.getCurrentTotalDistance()}
                                </text>
                            </>
                        )}
                    </svg>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const routeID = state.playlist.data.routeID;
    const sourceIndex = state.playlist.position.sourceIndex;
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }

    let videoKey = ownProps.videoKey;
    let frame = ownProps.frame;

    let measurements = state.userMeasurements;
    let videoMeasurements = measurements.filter((measurement) => {
        return measurement.video_key === videoKey && measurement.frame === frame;
    });

    let savedMeasurements = [];

    videoMeasurements.forEach((measurementObj) => {
        let measurement = JSON.parse(measurementObj.measurements);
        measurement.isMine = measurementObj.is_users_measurement;
        measurement.id = measurementObj.id;
        measurement.user = measurementObj.name;
        savedMeasurements.push(measurement);
    });

    let measurementUnits = _.get(state, "userDetails.userConfig.measurement_units", "metres");

    return {
        calibration: get_source_calibration(
            state.measurement.calibration,
            sourceIndex,
            getAbsoluteTimestamp(state.playlist) * 1000,
            false,
            defaultRailSeparation,
        ),
        session: state.sessions[routeID],
        measurements: savedMeasurements,
        measurementUnits,
        videoKey,
        privateByDefault: _.get(state.userDetails.userConfig, ["default_content_privacy"], false),
    };
};

export default connect(mapStateToProps)(CableMeasureOverlay);
