import { Alert, Button, Checkbox, Input, Modal, Select } from "antd";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { exportSegment, logEvent } from "../../../redux/actions/index";
import validator from "validator";

class VideoExportModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            exportNameInput: "",
            exportEmailInput: props.user_email ? props.user_email : "",
            exportTriggered: false,
            exportNameError: false,
            exportEmailError: false,
            exportEnhancedToggle: false,
            exportLengthError: false,
            sightingExportNameInput: "",
            sightingExportEmailInput: props.user_email ? props.user_email : "",
            annotationsSelect: "all",
            sketchesSelect: "all",
            measurementsSelect: "all",
            markers: false,
            rawVideo: false,
            routeInfo: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible && !this.props.visible) {
            setTimeout(() => {
                this.setState({
                    exportNameInput: "",
                    exportTriggered: false,
                    exportNameError: false,
                    exportEmailError: false,
                    exportLengthError: false,
                    rawVideo: false,
                });
            }, 300);
        }
    }

    handleExportNameChange = (event) => {
        this.setState({
            exportNameInput: event.target.value,
            exportNameError: false,
        });
    };

    handleExportEmailChange = (event) => {
        const newEmail = event.target.value.replace(/\s/g, "");
        this.setState({
            exportEmailInput: newEmail,
            exportEmailError: false,
        });
    };

    handleExportEnhancedToggle = (e) => {
        let checked = e.target.checked;
        this.setState({
            exportEnhancedToggle: checked,
            routeInfo: ["speed", "elr", "bearing", "timestamp"],
        });
    };

    handleRawToggle = (e) => {
        this.setState({
            rawVideo: e.target.checked,
        });
    };

    isExportNameWhitespaceOrEmpty = () => {
        if (this.state.exportNameInput.length >= 0) {
            return /^\s*$/.test(this.state.exportNameInput) && !this.state.exportNameInput.trim().length;
        }
    };

    createExportFooter = () => {
        if (this.state.exportTriggered) {
            return [
                <Button
                    key="Cancel"
                    type="primary"
                    onClick={this.props.onClose}>
                    Ok
                </Button>,
            ];
        } else {
            return [
                <Button
                    key="Cancel"
                    onClick={this.props.onClose}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    disabled={this.isExportNameWhitespaceOrEmpty()}
                    onClick={this.exportSegment}
                    type="primary">
                    Export
                </Button>,
            ];
        }
    };

    validate = () => {
        let errors = {};
        let valid = true;
        if (this.state.exportNameInput === "") {
            errors.exportNameError = "Export name cannot be empty";
            valid = false;
        }

        if (this.state.exportNameInput.length > 60) {
            errors.exportNameError = "Export name cannot be more than 60 characters";
            valid = false;
        }

        if (!validator.isEmail(this.state.exportEmailInput)) {
            errors.exportEmailError = "You must enter a valid email";
            valid = false;
        }

        this.setState(errors);
        return valid;
    };

    exportSegment = () => {
        if (!this.validate()) {
            return;
        }

        const _this = this;
        let allVideo = this.props.video;
        let startTimestamp =
            _.find(allVideo, function (vid) {
                return vid[0] === _this.props.routeStartTime.videoKey;
            })[3][2] * 1000;
        let endTimestamp =
            _.find(allVideo, function (vid) {
                return vid[0] === _this.props.routeEndTime.videoKey;
            })[3][2] * 1000;
        let deviceKey = this.props.currentSession.device_uuid;
        let sessionKey = this.props.currentSession.uuid;

        if (startTimestamp > endTimestamp) {
            let temp = endTimestamp;
            endTimestamp = startTimestamp;
            startTimestamp = temp;
        }

        let emailTo = false;
        if (this.state.exportEmailInput) {
            emailTo = this.state.exportEmailInput;
        }

        let exportName = this.state.exportNameInput;
        let exportIndex = this.props.sourceIndex;
        let isEnhanced = this.state.exportEnhancedToggle;

        this.props.dispatch(logEvent("Export", "Create", this.props.routeID));

        this.props.dispatch(
            exportSegment(
                startTimestamp,
                endTimestamp,
                deviceKey,
                sessionKey,
                exportName,
                emailTo,
                exportIndex,
                isEnhanced,
                this.state.annotationsSelect,
                this.state.sketchesSelect,
                this.state.measurementsSelect,
                this.state.markers,
                this.state.rawVideo,
                this.state.routeInfo,
            ),
        );
        this.setState({
            exportTriggered: true,
        });
    };

    onValueChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    toggleRouteInfoItem = (item) => {
        const newRouteInfo = _.clone(this.state.routeInfo);

        if (newRouteInfo.includes(item)) {
            let index = newRouteInfo.indexOf(item);
            newRouteInfo.splice(index, 1);
        } else {
            newRouteInfo.push(item);
        }

        this.setState({
            routeInfo: newRouteInfo,
        });
    };

    render = () => {
        let isBetaDashboard = this.props.dashboard && this.props.dashboard.isBeta;
        let getDifference = (a, b) => {
            return Math.abs(a - b);
        };
        let segmentStart = this.props.segmentSelection.start;
        let segmentEnd = this.props.segmentSelection.end;
        let segmentDuration = getDifference(segmentStart, segmentEnd);
        return (
            <Modal
                title="Video Export"
                visible={true}
                onOk={this.exportSegment}
                onCancel={this.props.onClose}
                footer={this.createExportFooter()}>
                {this.state.exportTriggered ? (
                    <p>The segment has been sent for exporting. A download link will be sent via email when the export is complete.</p>
                ) : (
                    <React.Fragment>
                        {this.state.exportLengthError && <p className="error">{this.state.exportLengthError}</p>}
                        {segmentDuration > 3600 ? (
                            <Alert
                                type="info"
                                className="ExportWarning"
                                message="Exports have a maximum duration of 1 hour. Your selection is longer than this, so only the first hour of video will be exported."
                            />
                        ) : null}
                        <h3>Export Name</h3>
                        <Input
                            value={this.state.exportNameInput}
                            onChange={this.handleExportNameChange}
                            placeholder="Enter export name"
                            style={{ marginBottom: 10 }}
                        />
                        {this.state.exportNameError && <p className="error">{this.state.exportNameError}</p>}
                        <h3>Email Recipient</h3>
                        <Input
                            value={this.state.exportEmailInput}
                            onChange={this.handleExportEmailChange}
                            placeholder="Enter email address"
                            style={{ marginBottom: 10 }}
                            whitespace={false}
                        />
                        {this.state.exportEmailError && <p className="error">{this.state.exportEmailError}</p>}

                        {this.props.userDetails.userConfig.csv_export && (
                            <div style={{ marginBottom: 15, marginTop: 10 }}>
                                <Checkbox
                                    checked={this.state.rawVideo}
                                    onChange={this.handleRawToggle}
                                    id="enhanced">
                                    Raw video and CSV metadata
                                </Checkbox>
                            </div>
                        )}
                        <div
                            style={{ marginBottom: 10 }}
                            className={this.state.rawVideo && "Disabled"}>
                            <h3>Video Overlay</h3>
                            <Checkbox
                                checked={this.state.exportEnhancedToggle}
                                onChange={this.handleExportEnhancedToggle}
                                id="enhanced">
                                Show speed and direction information in your export
                            </Checkbox>
                            {this.props.userDetails.email === "notian@onebigcircle.co.uk" && (
                                <div>
                                    <Checkbox
                                        checked={this.state.routeInfo.includes("speed")}
                                        onChange={() => this.toggleRouteInfoItem("speed")}
                                        id="enhanced">
                                        Speed
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.routeInfo.includes("bearing")}
                                        onChange={() => this.toggleRouteInfoItem("bearing")}
                                        id="enhanced">
                                        Bearing
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.routeInfo.includes("elr")}
                                        onChange={() => this.toggleRouteInfoItem("elr")}
                                        id="enhanced">
                                        ELR Mile & Chain
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.routeInfo.includes("timestamp")}
                                        onChange={() => this.toggleRouteInfoItem("timestamp")}
                                        id="enhanced">
                                        Timestamp
                                    </Checkbox>
                                </div>
                            )}
                        </div>

                        <div className={"exportMarkupsContainer" + (this.state.rawVideo ? " Disabled" : "")}>
                            <h3>Include Points of Interest</h3>

                            <div className="exportInputDiv">
                                <h4 className="exportInputLabel">Annotations</h4>
                                <Select
                                    className="exportInput"
                                    defaultValue="all"
                                    style={{ width: 120 }}
                                    onChange={(value) => this.onValueChange("annotationsSelect", value)}>
                                    <Select.Option value="none">None</Select.Option>
                                    <Select.Option value="mine">Mine</Select.Option>
                                    <Select.Option value="all">All</Select.Option>
                                </Select>
                            </div>

                            <div className="exportInputDiv">
                                <h4 className="exportInputLabel">Measurements</h4>
                                <Select
                                    className="exportInput"
                                    defaultValue="all"
                                    style={{ width: 120 }}
                                    onChange={(value) => this.onValueChange("measurementsSelect", value)}>
                                    <Select.Option value="none">None</Select.Option>
                                    <Select.Option value="mine">Mine</Select.Option>
                                    <Select.Option value="all">All</Select.Option>
                                </Select>
                            </div>

                            {isBetaDashboard && (
                                <div className="exportInputDiv">
                                    <h4 className="exportInputLabel">Markers</h4>
                                    <Checkbox
                                        className="exportInput"
                                        checked={this.state.markers}
                                        onChange={(e) => this.onValueChange("markers", e.target.checked)}
                                        id="markers"
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    };
}

const mapStateToProps = ({ playlist, sessions, userDetails, access_token, dashboards, segmentSelection }) => {
    const routeID = playlist.data.routeID;

    let dashboard = _.find(dashboards, { access_token: access_token });

    // below will extract the stream number from the first video frame of selected video stream.
    // This is to make sure the correct streamIndex is passed to the backend when workspace have
    // hide_streams set in the global config. If for some reason the video key is not provided this
    // will return 0 so the first stream will be exported.
    const videoStreamIndex = parseInt(_.get(_.get(playlist.data, ["video", playlist.position.sourceIndex, 0, 0], "").split(".")[0], 1, 0));

    return {
        user_email: userDetails.email,
        routeID,
        currentSession: sessions[routeID],
        video: _.get(playlist.data, ["video", playlist.position.sourceIndex]),
        sourceIndex: videoStreamIndex,
        dashboard,
        segmentSelection,
        userDetails: userDetails,
    };
};

export default connect(mapStateToProps)(VideoExportModal);
