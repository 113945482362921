import React from "react";
import "../../mobile.scss";
import { connect } from "react-redux";
import _ from "lodash";
import SelectedRouteSegmentGeometry from "../map/SelectedRouteSegmentGeometry";
import { fetchMapGeometryV2, fetchUserAnnotationTypes, setMobileMapZoomLevel } from "redux/actions/index";
import { Map, TileLayer } from "react-leaflet";
import PlaylistPositionMarker from "../map/PlaylistPositionMarker";

class MobileMap extends React.PureComponent {
    constructor(props) {
        super(props);

        this.map = React.createRef();

        this.state = {
            loading: true,
            displayMode: "player",
        };
    }

    goToBounds = (bounds) => {
        if (this.map.current && this.map.current.leafletElement) {
            let mapBounds = [
                [bounds.north, bounds.west],
                [bounds.south, bounds.east],
            ];
            console.log("Fit bounds here", mapBounds);
            this.map.current.leafletElement.fitBounds(mapBounds, { maxZoom: 20 });
        }
    };

    mapMove = (event) => {
        console.log("Map move event", event);
        let zoom = event.target.getZoom();

        this.props.updateMapPosition({
            zoom: zoom,
            lat: event.target.getCenter().lat,
            lng: event.target.getCenter().lng,
        });

        this.props.dispatch(setMobileMapZoomLevel(zoom));
    };

    componentDidMount() {
        let zoom;
        if (this.props.mapZoomLevel > 0) {
            zoom = this.props.mapZoomLevel;
        } else {
            zoom = 9;
        }

        if (!_.isEmpty(this.props.mapPosition) && this.props.playlistPosition) {
            this.map.current.leafletElement.setView([this.props.playlistPosition[1], this.props.playlistPosition[0]], zoom);
        } else {
            this.map.current.leafletElement.setView([52.9, -1.1743], 6);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.playlistPosition && this.props.playlistPosition) {
            this.map.current.leafletElement.setView([this.props.playlistPosition[1], this.props.playlistPosition[0]], 9);
        }
    }

    onReady = () => {
        this.props.dispatch(fetchMapGeometryV2());
        this.props.dispatch(fetchUserAnnotationTypes());
    };

    centerMap = (coords) => {
        if (coords[0] && coords[1] && this.state.followOnMap) {
            this.goToPosition(coords[1], coords[0]);
        }
    };

    goToPosition = (lat, lon, zoom = null) => {
        if (this.map.current && this.map.current.leafletElement) {
            if (lat && lon) {
                if (zoom !== null) {
                    this.map.current.leafletElement.setView({ lat: lat, lng: lon }, zoom);
                } else {
                    this.map.current.leafletElement.setView({ lat: lat, lng: lon });
                }
            }
        }
    };

    render() {
        return (
            <div className="mapContent">
                <Map
                    ref={this.map}
                    className="mobileMap"
                    maxZoom={24}
                    whenReady={this.onReady}
                    tap={false}
                    onMoveEnd={this.mapMove}>
                    <TileLayer
                        url="https://maptiles.aivr.video/{z}/{x}/{y}{r}.png"
                        maxNativeZoom={20}
                        attribution="&amp;copy OpenStreetMap contributors"
                    />
                    <PlaylistPositionMarker centerMap={this.centerMap} />

                    <SelectedRouteSegmentGeometry
                        key={"selectedRoute"}
                        routeSnapped={this.state.routeSnapped}
                    />
                </Map>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const dashboards = state.dashboards;
    const activeDashboard = _.find(dashboards, (dash) => state.access_token === dash.access_token);

    return {
        access_token: state.access_token,
        playlist: state.playlist,
        activeDashboard,
        mapGeometry: state.mapGeometry,
        requestedBounds: state.sessionFilters.mapBounds.requested,
        playlistPosition: state.playlist.position.coords,
        mapZoomLevel: state.mobileMapZoom,
    };
};

export default connect(mapStateToProps)(MobileMap);
