export const degreesToUserPreference = (temp, userPreference) => {
    if (userPreference === "celsius") {
        return temp;
    } else if (userPreference === "fahrenheit") {
        return celsiusToFarenheit(temp);
    }

    return temp;
};

export const getTempUnit = (userPreference) => {
    if (userPreference === "celsius") {
        return "ºC";
    } else if (userPreference === "fahrenheit") {
        return "ºF";
    }

    return "ºC";
};

const celsiusToFarenheit = (celsius, round = true) => {
    let farenheit = celsius * 1.8 + 32;

    if (round) {
        return Math.round(farenheit);
    }
    return farenheit;
};

export const farenheitToCelsius = (farenheit, round = true) => {
    const celsius = (farenheit - 32) / (9 / 5);

    if (round) {
        return Math.round(celsius);
    }
    return celsius;
};
