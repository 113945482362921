import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { exportSightingReport } from "../../../redux/actions/index";
import { calculatePointDistance, getStartCoordinate } from "../../util/Geometry";
import {
    asyncLoadImage,
    calculateFrame,
    calculateOffset,
    getCurrentVideoKey,
    getInterpolatedPosition,
    getOffsetAdjustedTime,
    getUrlDataForFrame,
    reverseOffsetAdjustment,
    videoTimeLookup,
} from "../../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class AdvancedSightingInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: "backwards",
            increment: 50,
            maximumRange: 100,
            sightingFrames: [],
            calculatingSighting: false,
            imageFocused: false,
        };

        this.remainingDistances = [];
        this.sightingProcess = null;
    }

    componentDidMount = () => {
        this.calculateSightingImages();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.hidden) {
            return;
        }
        let changed_props = _.filter(
            ["hidden", "snapshotBaseURL", "video", "videoKey", "timeOffset", "currentSession"],
            (item) => this.props[item] !== prevProps[item],
        );
        let changed_state = _.filter(["direction", "increment", "maximumRange"], (item) => this.state[item] !== prevState[item]);
        console.log("Changed props & state: ", changed_props, changed_state);
        if (changed_props.length > 0 || changed_state.length > 0) {
            this.calculateSightingImages();
        }
    };

    close = () => {
        this.cancelSightingCalculation();
        this.props.onClose();
    };

    cancelSightingCalculation = () => {
        clearTimeout(this.sightingProcess);
        if (this.state.calculatingSighting) {
            this.setState({
                calculatingSighting: false,
            });
        }
        this.remainingDistances = [];
    };

    handleDirectionChange = (event) => {
        this.setState({ direction: event.target.value });
    };

    handleIncrementChange = (event) => {
        this.setState({ increment: parseInt(event.target.value) });
    };

    handleRangeChange = (event) => {
        this.setState({ maximumRange: parseInt(event.target.value) });
    };

    calculateSightingImages = () => {
        this.cancelSightingCalculation();

        let currentVidIndex = _.findIndex(this.props.video, (vid) => {
            return vid[0] === this.props.videoKey;
        });

        if (currentVidIndex === -1) {
            return;
        }

        const frameTimeOffset = this.props.timeOffset;

        let frameIndex = 0;
        if (this.props.video[currentVidIndex][5]) {
            frameIndex = calculateFrame(this.props.video, currentVidIndex, frameTimeOffset);
        }
        this.addFirstFrameImage(currentVidIndex, frameIndex);
    };

    addFirstFrameImage = (playlistIndex, frameIndex) => {
        console.log("Adding sighting frame: ", playlistIndex, frameIndex, 0);
        let currentImage = {
            videoKey: this.props.videoKey,
            playlistIndex,
            frameIndex,
            accurateDistance: 0,
            distance: "Current Position",
        };
        const { url, range } = this.getSourceUrlData(playlistIndex, frameIndex);

        const imageLoadCallback = (imageData) => {
            console.log("Got image data for source URL: ", url);
            currentImage.imageData = imageData;
            const distanceSteps = Math.floor(this.state.maximumRange / this.state.increment);
            this.remainingDistances = Array.from(Array(distanceSteps), (_, i) => (i + 1) * this.state.increment);
            console.log("Remaining distances:", this.remainingDistances);
            this.setState({
                sightingFrames: [currentImage],
                calculatingSighting: true,
            });
            clearTimeout(this.sightingProcess);
            this.sightingProcess = setTimeout(this.calculateNextSightingFrame, 10);
        };

        asyncLoadImage(url, range, imageLoadCallback);
    };

    imageToGPS = (playlistIndex, frame, allVideos) => {
        let startTime = allVideos[playlistIndex][1] + calculateOffset(allVideos, playlistIndex, frame);
        let offsetStartTime = getOffsetAdjustedTime(startTime, this.props.offsets);
        let offsetStartIndex = videoTimeLookup(offsetStartTime, this.props.video);
        let offsetStartTimeOffset = offsetStartTime - this.props.video[offsetStartIndex][1];
        return [offsetStartTime, offsetStartIndex, offsetStartTimeOffset];
    };

    gpsToImage = (gpsIndex, timeOffset, allVideos) => {
        let offsetStartTime = allVideos[gpsIndex][1] + timeOffset;
        let startTime = reverseOffsetAdjustment(offsetStartTime, this.props.offsets);
        let playlistIndex = videoTimeLookup(startTime, this.props.video);
        let offset = startTime - this.props.video[playlistIndex][1];
        let frame = calculateFrame(allVideos, playlistIndex, offset);
        return [playlistIndex, frame];
    };

    calculateNextSightingFrame = () => {
        if (!this.state.calculatingSighting) {
            return;
        }

        const lastFrame = this.state.sightingFrames[this.state.sightingFrames.length - 1];

        let currentDistance = lastFrame.accurateDistance;
        let nextDistance = this.remainingDistances.shift();

        const allVideos = this.props.video;
        if (!allVideos) {
            //Nothing can be done
            this.setState({
                calculatingSighting: false,
            });
            return;
        }

        let gpsFrame = this.imageToGPS(lastFrame.playlistIndex, lastFrame.frameIndex, allVideos);
        let gpsTime = gpsFrame[0];
        let gpsPlaylistIndex = gpsFrame[1];
        let gpsTimeOffset = gpsFrame[2];

        let currentPosition = getInterpolatedPosition(gpsTime, allVideos, this.props.use_snapped);

        const direction = this.state.direction === "forwards" ? 1 : -1;

        let segmentEndCoordinate;
        if (direction === 1) {
            segmentEndCoordinate = getStartCoordinate(allVideos[gpsPlaylistIndex + 1], this.props.use_snapped);
        } else {
            segmentEndCoordinate = getStartCoordinate(allVideos[gpsPlaylistIndex], this.props.use_snapped);
        }
        let distanceToEndOfSegment = calculatePointDistance(currentPosition, segmentEndCoordinate);

        while (gpsPlaylistIndex > 0 && gpsPlaylistIndex < allVideos.length - 1 && currentDistance + distanceToEndOfSegment < nextDistance) {
            currentDistance += distanceToEndOfSegment;
            gpsPlaylistIndex += direction;
            if (distanceToEndOfSegment > 0) {
                currentPosition = segmentEndCoordinate;
            }
            if (direction === 1) {
                segmentEndCoordinate = getStartCoordinate(allVideos[gpsPlaylistIndex + 1], this.props.use_snapped);
                gpsTimeOffset = 0;
            } else {
                segmentEndCoordinate = getStartCoordinate(allVideos[gpsPlaylistIndex], this.props.use_snapped);
                gpsTimeOffset = allVideos[gpsPlaylistIndex][2];
            }
            distanceToEndOfSegment = calculatePointDistance(currentPosition, segmentEndCoordinate);
        }

        if (currentDistance + distanceToEndOfSegment < nextDistance) {
            //Nothing can be done
            this.setState({
                calculatingSighting: false,
            });
            return;
        }

        // currentPosition will either be start or end of the current segment depending on direction (unless total distance is below one index)
        // segmentEndCoordinate will be end or start depending on direction

        let remainingDistance = nextDistance - currentDistance;
        let segmentDistance = calculatePointDistance(
            getStartCoordinate(allVideos[gpsPlaylistIndex], this.props.use_snapped),
            getStartCoordinate(allVideos[gpsPlaylistIndex + 1], this.props.use_snapped),
        );

        let proportionOfDistanceRemaining = remainingDistance / segmentDistance;
        let durationRemaining = proportionOfDistanceRemaining * allVideos[gpsPlaylistIndex][2];

        if (direction === 1) {
            gpsTimeOffset += durationRemaining;
        } else {
            gpsTimeOffset -= durationRemaining;
        }

        let frameData = this.gpsToImage(gpsPlaylistIndex, gpsTimeOffset, allVideos);
        const frameCount = allVideos[frameData[0]][5];
        if (frameCount) {
            let frameAccurateGPSTime = this.imageToGPS(frameData[0], frameData[1], allVideos)[0];
            let frameAccurateFinalPosition = getInterpolatedPosition(frameAccurateGPSTime, allVideos, this.props.use_snapped);
            let frameAccurateFinalDistance = calculatePointDistance(currentPosition, frameAccurateFinalPosition);
            currentDistance += frameAccurateFinalDistance;
        } else {
            frameData[1] = 0;
            if (proportionOfDistanceRemaining > 0.5) {
                if (direction === -1) {
                    frameData[0] += 1;
                }
            } else {
                if (direction === 1) {
                    frameData[0] += 1;
                }
                currentDistance += segmentDistance;
            }
        }
        currentDistance = Math.round(currentDistance);
        this.addSightingFrame(frameData[0], frameData[1], currentDistance, allVideos[frameData[0]][0]);
    };

    displayDistance = (metres) => {
        if (this.props.sightingUnits === "yards") {
            return `${Math.round(metres * 1.09361)}yd`;
        } else {
            return `${metres}m`;
        }
    };

    addSightingFrame = (playlistIndex, frameIndex, accurateDistance, videoKey) => {
        console.log("Adding sighting frame: ", playlistIndex, frameIndex, accurateDistance);
        const nextFrame = {
            videoKey: videoKey,
            playlistIndex,
            frameIndex,
            accurateDistance: accurateDistance,
            distance: `${this.displayDistance(accurateDistance)} ${this.state.direction}`,
        };

        let { url, range } = this.getSourceUrlData(playlistIndex, frameIndex);

        const imageLoadCallback = (imageData) => {
            if (!this.state.calculatingSighting) {
                return;
            }
            nextFrame.imageData = imageData;
            const sightingFrames = _.clone(this.state.sightingFrames);
            sightingFrames.push(nextFrame);
            this.setState({
                sightingFrames,
            });
            clearTimeout(this.sightingProcess);
            if (this.remainingDistances.length) {
                this.sightingProcess = setTimeout(this.calculateNextSightingFrame, 100);
            } else {
                this.setState({
                    calculatingSighting: false,
                });
            }
        };

        asyncLoadImage(url, range, imageLoadCallback);
    };

    renderSightingImages = () => {
        let imageFrames = this.state.sightingFrames;
        return imageFrames.map((image, index) => {
            return (
                <div
                    className="sightingSectionDiv"
                    key={`sightingFrame${index}`}>
                    <div className="sightingSectionInner">
                        <div
                            className="hiddenHover"
                            onClick={() => this.focusImage(image)}>
                            <span>Click to view</span>
                        </div>
                        <img
                            src={image.imageData}
                            className="sightingImage"
                            alt={"distance thumbnail"}
                            crossOrigin={"anonymous"}
                        />
                    </div>
                    <p className="sightingDirectionText">{image.distance}</p>
                </div>
            );
        });
    };

    getSourceUrlData(index, frameIndex) {
        const urlData = getUrlDataForFrame(this.props.playlist, index, frameIndex);

        const url = `${this.props.snapshotBaseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;

        return {
            url,
            range: urlData.range,
        };
    }

    createReport = (email, name) => {
        console.log("Creating export");
        let sightingFrames = _.map(this.state.sightingFrames, ({ videoKey, distance, frameIndex }) => {
            const frameData = {
                videoKey,
                distance,
            };
            if (frameIndex >= 1) {
                frameData.frameIndex = frameIndex;
            }
            return frameData;
        });

        let firstFrame = sightingFrames[0];
        let lastFrame = sightingFrames[sightingFrames.length - 1];

        let timestamps = [
            Math.round(
                _.find(this.props.video, function (vid) {
                    return vid[0] === firstFrame.videoKey;
                })[3][2],
            ) * 1000,
            Math.round(
                _.find(this.props.video, function (vid) {
                    return vid[0] === lastFrame.videoKey;
                })[3][2],
            ) * 1000,
        ].sort();

        timestamps[0] = timestamps[0] - 5000;
        timestamps[1] = timestamps[1] + 5000;

        let sightingPayload = {
            frames: sightingFrames,
            direction: this.state.direction,
        };
        let deviceKey = this.props.currentSession.device_uuid;
        let sessionKey = this.props.currentSession.uuid;

        this.props.dispatch(exportSightingReport(sightingPayload, deviceKey, sessionKey, timestamps[0], timestamps[1], email, name, this.props.sourceIndex));
    };

    focusImage = (image) => {
        this.setState({
            imageFocused: image.imageData,
        });
    };

    closeImage = () => {
        this.setState({
            imageFocused: false,
        });
    };

    sightingIncrementOptions = () => {
        let metre_options = [10, 50, 100, 150, 200, 300, 400];
        let yard_options = [10, 50, 100, 150, 200, 300, 400];

        if (this.props.sightingUnits === "yards") {
            return yard_options.map((value) => {
                let m_value = Math.round(value * 0.9144);
                return (
                    <option
                        value={m_value}
                        disabled={this.state.maximumRange < m_value}>{`${value}yd`}</option>
                );
            });
        } else {
            return metre_options.map((value) => (
                <option
                    value={value}
                    disabled={this.state.maximumRange < value}>{`${value}m`}</option>
            ));
        }
    };

    sightingRangeOptions = () => {
        let options = [100, 200, 300, 400, 500];

        if (this.props.sightingUnits === "yards") {
            return options.map((value) => <option value={Math.round(value * 0.9144)}>{`${value}yd`}</option>);
        } else {
            return options.map((value) => <option value={value}>{`${value}m`}</option>);
        }
    };

    render() {
        if (this.state.imageFocused) {
            return (
                <div className="fullScreenSightingDiv">
                    <img
                        className="fullSightingImage"
                        src={this.state.imageFocused}
                        alt={"Full sighting thumbnail"}
                        crossOrigin={"anonymous"}
                    />
                    <button
                        onClick={this.closeImage}
                        className="sightingBackButton">
                        Back To Sightings
                    </button>
                </div>
            );
        }

        if (this.props.hidden) {
            return null;
        }

        return (
            <div>
                <div className="sightingModalHeader">
                    <div className="sightingModalInputDiv">
                        <p className="sightingModalInputTitle">Direction</p>
                        <select
                            className="sightingModalInput"
                            value={this.state.direction}
                            onChange={this.handleDirectionChange}>
                            <option value="forwards">Forwards</option>
                            <option value="backwards">Backwards</option>
                        </select>
                    </div>

                    <div className="sightingModalInputDiv">
                        <p className="sightingModalInputTitle">Sighting Increment</p>
                        <select
                            className="sightingModalInput"
                            value={this.state.increment}
                            onChange={this.handleIncrementChange}>
                            {this.sightingIncrementOptions()}
                        </select>
                    </div>

                    <div className="sightingModalInputDiv">
                        <p className="sightingModalInputTitle">Maximum Range</p>
                        <select
                            className="sightingModalInput"
                            value={this.state.maximumRange}
                            onChange={this.handleRangeChange}>
                            {this.sightingRangeOptions()}
                        </select>
                    </div>
                </div>
                <div className="sightingModalPreContent">
                    <div className="sighting-warning">
                        <FontAwesomeIcon
                            className="sighting-warning-icon"
                            icon={faInfoCircle}
                            size="1x"
                            color="rgba(0,0,0,0.65)"
                        />
                        <span className="sighting-warning-text">
                            Actual distances may differ slightly from expected increments due to speed of the moving device. The image shown is the closest
                            available frame to the requested location
                        </span>
                    </div>
                </div>
                <div className="sightingModalContent">{this.renderSightingImages()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const routeID = state.playlist.data.routeID;
    const sightingUnits = _.get(state, "userDetails.userConfig.sighting_units", "metres");
    let offsets = [];

    if (routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }

    const currentPlaylist = _.get(state.playlist.data, ["video", sourceIndex], []);
    return {
        snapshotBaseURL: _.get(state.playlist.data, ["mpdURLs", "snapshots"]),
        video: currentPlaylist,
        videoKey: getCurrentVideoKey(state.playlist),
        playlist: _.get(state.playlist.data, ["video", sourceIndex], []),
        timeOffset: state.playlist.position.currentTimeOffset,
        currentSession: state.sessions[routeID],
        sightingUnits,
        offsets,
        use_snapped: state.snappedRoute || false,
        sourceIndex,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AdvancedSightingInterface);
