import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    reviewDetection,
    setCurrentInspectionMarkup,
    setDetectionVisibility,
    toggleInspectionDetectionsExport,
    toggleRejectionModal,
    updateRailInspectionDetectionCondition,
    setFollowDetections,
    setDetectionConditionData,
    customAudit,
} from "../../../../redux/actions/index";
import { filterMarkers, binarySearch } from "../../../util/PlaylistUtils";
import _ from "lodash";
import { Select, Button, Checkbox, Tooltip } from "antd";
import {
    faCheckCircle,
    faArrowLeft,
    faArrowRight,
    faChevronLeft,
    faChevronRight,
    faEyeSlash,
    faQuestionCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye, faFilter } from "@fortawesome/free-solid-svg-icons";
import DetectionsFiltersModal from "./DetectionsFiltersModal";
import RejectionModal from "./RejectionModal";
import OBCSpinner from "../../../util/OBC";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";
import { useResizeContext } from "../DraggableWrapper";
import OBCAutoIndicator from "components/OBC/OBCAutoIndicator";

const detectionsDataSelector = (state) => state.railInspection.detections.data;
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railConfigSelector = (state) => state.railInspection.railInspectionImageConfig;
const allDetectionsFetchedSelector = (state) => state.railInspection.detections.allDetectionsFetched;
const currentDetectionIDSelector = (state) => state.railInspection.detections.selectedDetection;
const detectionsVisibleSelector = (state) => state.railInspection.detections.visibility;
const railInspectionConditionsSelector = (state) => state.railInspection.railInspectionConditions;
const detectionsTypeFiltersSelector = (state) => state.railInspection.detections.typeFilters;
const detectionsReviewFiltersSelector = (state) => state.railInspection.detections.reviewFilters;
const detectionConditionFiltersSelector = (state) => state.railInspection.detections.detectionConditionFilters || {};
const followDetectionsSelector = (state) => state.railInspection.detections.followDetections;
const detectionsExportOpenSelector = (state) => state.railInspection.detectionsExportOpen;
const csrfTokenSelector = (state) => state.csrfToken;
const conditionTypesSelector = (state) => state.railInspection.inspectionConditionTypes;
const sessionIDSelector = (state) => state.playlist.data.routeID;
const dipAngleFilterSelector = (state) => state.railInspection.dipAngleFilter;

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;
const emptyObject = {};

const ConditionalTooltip = ({ children, selectDisabled }) => {
    return selectDisabled ? <Tooltip title="Option not valid">{children}</Tooltip> : children;
};

const DetectionVerificationElement = ({ userData, autoData, currentDetection, condition }) => {
    const [hover, setHover] = useState(false);

    const dispatch = useDispatch();

    if (userData) {
        return (
            <div className="DetectionAutoVerification">
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="VerifyIcon"
                    style={{ color: "rgb(71, 159, 248", marginRight: "2px" }}
                />{" "}
                Verified
            </div>
        );
    }

    if (!autoData) {
        return null;
    }

    return (
        <div
            className="DetectionAutoVerification"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ cursor: "pointer" }}
            onClick={() => {
                const connectingDetections = _.get(currentDetection, "connecting_detections", []);
                _.map([...connectingDetections, currentDetection], (detection) => dispatch(setDetectionConditionData(detection.id, autoData, condition)));
                setTimeout(() => {
                    setHover(false);
                }, 500);
            }}>
            {hover ? (
                <>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="VerifyIcon"
                        style={{ color: "rgb(76, 160, 76", marginRight: "2px" }}
                    />{" "}
                    Verify
                </>
            ) : (
                <OBCAutoIndicator
                    tooltip="Reviewed automatically"
                    placement="topRight"
                />
            )}
        </div>
    );
};

const DetectionsNavigation = ({ shortcutsActive, reportProblemVisible }) => {
    const dispatch = useDispatch();
    const rejectionModalVisible = useSelector((state) => state.toggleRejectionModal);

    const detectionsExportOpen = useSelector(detectionsExportOpenSelector);
    const detectionsData = useSelector(detectionsDataSelector);
    const selectedRailInspectionImage = useSelector(selectedRailInspectionImageSelector);
    const railData = useSelector(railDataObjectSelector);
    const railConfig = useSelector(railConfigSelector);
    const detectionsReviewFilters = useSelector(detectionsReviewFiltersSelector);
    const detectionsTypeFilters = useSelector(detectionsTypeFiltersSelector);
    const detectionConditionFilters = useSelector(detectionConditionFiltersSelector);
    const allDetectionsFetched = useSelector(allDetectionsFetchedSelector);
    const currentDetectionID = useSelector(currentDetectionIDSelector);
    const detectionsVisible = useSelector(detectionsVisibleSelector);
    const currentRouteID = useSelector((state) => state.playlist.data.routeID);
    const defaultMarkers = useSelector((state) => state.defaultMarkersThresholds[currentRouteID] || emptyObject);
    const railInspectionConditions = useSelector(railInspectionConditionsSelector);
    const followDetections = useSelector(followDetectionsSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const conditionTypes = useSelector(conditionTypesSelector);
    const sessionID = useSelector(sessionIDSelector);
    const dipAngleFilter = useSelector(dipAngleFilterSelector);

    const [detectionsFiltersOpen, setDetectionsFiltersOpen] = useState(false);
    const [listOfConditions, setListOfConditions] = useState([]);
    const [autoNavigation, setAutoNavigation] = useState(true);
    const [numberOfFilters, setNumberOfFilters] = useState(0);

    const [handleResize, customDimensionsRef] = useResizeContext();

    const { Option } = Select;

    useEffect(() => {
        dispatch(
            customAudit(
                "inspection_detections_navigator.opened",
                {
                    session_id: sessionID,
                },
                `Inspection detections navigator opened - session ${sessionID}`,
            ),
        );
    }, []);

    const filteredDetections = useMemo(() => {
        let filtered = [];

        if (detectionsData && detectionsData.length && detectionsReviewFilters && detectionsTypeFilters && detectionConditionFilters) {
            filtered = filterMarkers(
                detectionsData,
                detectionsTypeFilters,
                detectionsReviewFilters,
                [],
                [],
                true,
                defaultMarkers,
                false,
                detectionConditionFilters,
                dipAngleFilter,
            );
        }
        return filtered;
    }, [detectionsData, detectionsReviewFilters, detectionsTypeFilters, detectionConditionFilters, dipAngleFilter]);

    const previousRailImage = useMemo(() => {
        const railIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);
        return railData[railIndex - 1];
    }, [railData, selectedRailInspectionImage]);

    const currentImageHasDetection = useMemo(() => {
        if (
            filteredDetections &&
            filteredDetections.length &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp &&
            railData &&
            railData.length
        ) {
            let index = _.findIndex(filteredDetections, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            if (index < 0) {
                const previousImageDetection = _.find(filteredDetections, (det) => det.image_timestamp === _.get(previousRailImage, "timestamp"));
                if (!previousImageDetection) {
                    return false;
                }
                const connectingDetection = _.get(previousImageDetection, "connecting_detections", []);
                index = _.findIndex(connectingDetection, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            }
            return index > -1;
        } else {
            return false;
        }
    }, [filteredDetections, selectedRailInspectionImage, railData, previousRailImage]);

    const closestToLeftIndex = useMemo(() => {
        if (!selectedRailInspectionImage || !filteredDetections || !filteredDetections.length) {
            return null;
        }
        return binarySearch(selectedRailInspectionImage.timestamp, filteredDetections, (det) => det.image_timestamp);
    }, [filteredDetections, selectedRailInspectionImage]);

    const closestToRightIndex = useMemo(() => {
        if (filteredDetections && closestToLeftIndex > -1) {
            const closestToLeftDetection = filteredDetections[closestToLeftIndex];
            if (closestToLeftDetection && closestToLeftDetection.image_timestamp > selectedRailInspectionImage.timestamp) {
                return closestToLeftIndex;
            } else {
                return closestToLeftIndex + 1;
            }
        } else {
            return null;
        }
    }, [closestToLeftIndex, filteredDetections, selectedRailInspectionImage]);

    const distanceToRight = useMemo(() => {
        if (
            filteredDetections &&
            railData &&
            closestToLeftIndex > -1 &&
            closestToRightIndex &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp
        ) {
            if (closestToRightIndex > filteredDetections.length - 1) {
                return null;
            }
            const closestToRight = filteredDetections[closestToRightIndex];
            const rightIndex = _.findIndex(railData, (i) => i.timestamp === closestToRight.image_timestamp);

            const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            if (rightIndex && currentIndex > -1) {
                return rightIndex - currentIndex;
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredDetections, railData, selectedRailInspectionImage, closestToRightIndex]);

    const distanceToLeft = useMemo(() => {
        if (
            filteredDetections &&
            railData &&
            closestToLeftIndex > -1 &&
            filteredDetections[closestToLeftIndex] &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp
        ) {
            const closestToLeft = filteredDetections[closestToLeftIndex];
            if (closestToLeft) {
                const connectingDetections = [..._.get(closestToLeft, "connecting_detections", []), closestToLeft];

                const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);
                const leftIndexDetection = _.minBy(connectingDetections, (detection) => {
                    const idx = _.findIndex(railData, (i) => i.timestamp === detection.image_timestamp);
                    return Math.abs(idx - currentIndex);
                });

                const leftIndex = _.findIndex(railData, (i) => i.timestamp === leftIndexDetection.image_timestamp);

                if (leftIndex && currentIndex > -1) {
                    return currentIndex - leftIndex;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredDetections, railData, selectedRailInspectionImage]);

    const closestIndex = useMemo(() => {
        if (filteredDetections && railData && selectedRailInspectionImage) {
            if (currentImageHasDetection) {
                return _.findIndex(filteredDetections, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            } else {
                if (!distanceToLeft) {
                    return closestToRightIndex;
                } else if (!distanceToRight) {
                    return closestToLeftIndex;
                } else if (distanceToLeft < distanceToRight) {
                    return closestToLeftIndex;
                } else {
                    return closestToRightIndex;
                }
            }
        } else {
            return null;
        }
    }, [
        closestToLeftIndex,
        currentImageHasDetection,
        filteredDetections,
        railData,
        selectedRailInspectionImage,
        closestToRightIndex,
        distanceToLeft,
        distanceToRight,
    ]);

    const currentDetection = useMemo(() => {
        if (!_.isNil(currentDetectionID)) {
            const currentDet = _.find(filteredDetections, { id: currentDetectionID });
            if (currentDet) {
                return currentDet;
            } else if (closestIndex >= 0 && filteredDetections) {
                const closestDetection = filteredDetections[closestIndex];
                return closestDetection;
            } else {
                return null;
            }
        } else {
            if (closestIndex >= 0 && filteredDetections) {
                const closestDetection = filteredDetections[closestIndex];
                return closestDetection;
            } else {
                return null;
            }
        }
    }, [filteredDetections, closestIndex, currentDetectionID]);

    const currentIndex = useMemo(() => {
        if (currentDetectionID) {
            return _.findIndex(filteredDetections, { id: currentDetectionID });
        }
        return null;
    }, [filteredDetections, currentDetectionID]);

    const navigate = useCallback(
        (index) => {
            if (filteredDetections && filteredDetections.length) {
                if (filteredDetections[index]) {
                    dispatch(setCurrentInspectionMarkup("detection", filteredDetections[index].id));
                }
            }
        },
        [dispatch, filteredDetections],
    );

    const disableBack = useMemo(() => {
        if (!filteredDetections.length) {
            return true;
        }
        if (filteredDetections.length > 1) {
            return closestToRightIndex <= 0;
        } else {
            return closestIndex === currentIndex || closestToRightIndex === 0;
        }
    }, [currentIndex, closestToLeftIndex, closestToRightIndex, filteredDetections, closestIndex]);

    const disableForward = useMemo(() => {
        if (!filteredDetections.length) {
            return true;
        }
        if (filteredDetections.length > 1) {
            return currentIndex + 2 > filteredDetections.length;
        } else {
            return closestIndex === currentIndex || closestToRightIndex === filteredDetections.length;
        }
    }, [currentIndex, filteredDetections.length, closestIndex, closestToRightIndex]);

    const goForward = useCallback(() => {
        if (!disableForward) {
            if (!_.isNil(currentIndex) && currentIndex > -1) {
                navigate(currentIndex + 1);
            } else {
                navigate(closestToRightIndex);
            }
            dispatch(
                customAudit(
                    "inspection_detections_navigator.navigate",
                    {
                        session_id: sessionID,
                    },
                    `Inspection detections navigator - navigate to detection - session ${sessionID}`,
                ),
            );
        }
    }, [navigate, currentIndex, closestToRightIndex, disableForward]);

    const revMarker = useCallback(
        (review, navigate = true) => {
            if (review === 3) {
                if (currentDetection.name.toLowerCase() === "defect") {
                    dispatch(toggleRejectionModal());
                } else {
                    dispatch(reviewDetection(currentDetection.id, review));
                }
            } else {
                dispatch(reviewDetection(currentDetection.id, review));
                if (currentDetection.connecting_detections && currentDetection.connecting_detections.length) {
                    currentDetection.connecting_detections.forEach((detection) => {
                        dispatch(reviewDetection(detection.id, review, "No Comment", true));
                    });
                }
                if (autoNavigation && navigate) {
                    goForward();
                }
            }
        },
        [currentDetection, dispatch, goForward, autoNavigation],
    );

    const onReviewChange = useCallback(
        (value) => {
            revMarker(value, false);
        },
        [revMarker],
    );

    const goBack = useCallback(() => {
        if (!disableBack) {
            if (currentIndex && currentIndex > -1) {
                navigate(currentIndex - 1);
            } else {
                navigate(closestToLeftIndex);
            }
            dispatch(
                customAudit(
                    "inspection_detections_navigator.navigate",
                    {
                        session_id: sessionID,
                    },
                    `Inspection detections navigator - navigate to detection - session ${sessionID}`,
                ),
            );
        }
    }, [navigate, currentIndex, closestToLeftIndex, disableBack]);

    const handleKeyPress = useCallback(
        (event) => {
            if (!shortcutsActive || rejectionModalVisible || detectionsExportOpen || reportProblemVisible) {
                return;
            }

            if ((event.key === "r" || event.key === "R") && (event.ctrlKey || event.metaKey)) {
                return;
            }

            if (event.key === "v" || event.key === "V") {
                revMarker(1);
            } else if (event.key === "h" || event.key === "H") {
                revMarker(2);
            } else if (event.key === "r" || event.key === "R") {
                revMarker(3);
            } else if (event.key === ",") {
                goBack();
            } else if (event.key === ".") {
                goForward();
            }

            // key bindings for condition types
            const pressedValue = parseInt(event.key);
            if (pressedValue === 0 && listOfConditions.length > 0) {
                onConditionTypeChanged(null);
            } else if (listOfConditions[pressedValue - 1]) {
                onConditionTypeChanged(listOfConditions[pressedValue - 1].id);
            }
        },
        [goBack, goForward, revMarker, shortcutsActive, listOfConditions, autoNavigation, rejectionModalVisible, detectionsExportOpen, reportProblemVisible],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress, false);

        return () => {
            document.removeEventListener("keydown", handleKeyPress, false);
        };
    }, [handleKeyPress]);

    const distanceFromDetection = useMemo(() => {
        let position = distanceToLeft;

        let directionIcon = <FontAwesomeIcon icon={faArrowLeft} />;

        if (Math.abs(distanceToLeft) >= Math.abs(distanceToRight)) {
            position = distanceToRight;
            directionIcon = <FontAwesomeIcon icon={faArrowRight} />;
        }

        if (!distanceToRight) {
            position = distanceToLeft;
            if (!_.isNil(closestIndex) && closestIndex + 1 > filteredDetections.length - 1) {
                directionIcon = <FontAwesomeIcon icon={faArrowLeft} />;
            } else {
                directionIcon = <FontAwesomeIcon icon={faArrowRight} />;
            }
        }

        if (position === 0) {
            directionIcon = null;
        }

        return (
            <div className="distance">
                <span>{Math.abs(position)}m</span>
                {directionIcon}
            </div>
        );
    }, [distanceToLeft, distanceToRight, closestIndex, filteredDetections]);

    const renderDetectionConditions = useMemo(() => {
        const selects = [];
        const checkboxes = [];
        const currentDetectionName = _.get(currentDetection, "name", "");
        const currentDetectionConditions = _.get(currentDetection, "conditions", {});
        if (currentDetectionName) {
            const conditions = _.get(conditionTypes, currentDetectionName, {});
            if (conditions && !_.isEmpty(conditions)) {
                _.map(Object.keys(conditions), (condition) => {
                    if (condition === "dip_angle") {
                        return;
                    }

                    const currentCondition = _.get(conditions, condition, {});
                    const dataValues = _.get(currentCondition, "data_values", {});
                    const userData = _.get(currentDetectionConditions, [condition, "user"]);
                    const autoData = _.get(currentDetectionConditions, [condition, "auto"]);

                    let selectDisabled = false;
                    const dependsOn = _.get(currentCondition, "depends_on", "");
                    if (dependsOn) {
                        const dependsOnValues = _.get(currentCondition, "depends_on_values", []);
                        let dependantCondition = _.get(currentDetectionConditions, [dependsOn, "user"], null);
                        if (!dependantCondition) {
                            dependantCondition = _.get(currentDetectionConditions, [dependsOn, "auto"], null);
                        }
                        if (!dependsOnValues.includes(dependantCondition)) {
                            selectDisabled = true;
                        }
                    }

                    if (!_.isEmpty(dataValues)) {
                        selects.push(
                            <div className="detectionCondition__selectContainer">
                                <div className="detectionCondition__labelContainer">
                                    <h2 className="detectionCondition__Label">{currentCondition.display_name}</h2>
                                    <DetectionVerificationElement
                                        userData={userData}
                                        autoData={autoData}
                                        currentDetection={currentDetection}
                                        condition={condition}
                                    />
                                </div>

                                <ConditionalTooltip selectDisabled={selectDisabled}>
                                    <Select
                                        className={`detectionCondition__Select ${selectDisabled ? "disabled" : ""}`}
                                        value={userData ? userData : autoData ? autoData : undefined}
                                        placeholder={`Select ${currentCondition.display_name}`}
                                        onChange={(e) => {
                                            const connectingDetectionIDs = _.get(currentDetection, "connecting_detections", []);
                                            _.map([...connectingDetectionIDs, currentDetection], (detection) =>
                                                dispatch(setDetectionConditionData(detection.id, e, condition)),
                                            );
                                        }}
                                        disabled={selectDisabled}>
                                        {_.map(Object.keys(dataValues), (dataValue) => {
                                            if (typeof dataValues[dataValue] === "string") {
                                                return <Option value={dataValues[dataValue]}>{dataValue}</Option>;
                                            } else {
                                                const displayName = _.get(dataValues[dataValue], "display_name");
                                                const ragStatus = _.get(dataValues[dataValue], "rag");

                                                const colours = {
                                                    red: "#E55151",
                                                    amber: "#F8C46D",
                                                    green: "#47C66B",
                                                };

                                                if (displayName) {
                                                    return (
                                                        <Option value={dataValue}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {displayName}
                                                                {ragStatus && (
                                                                    <span
                                                                        role="img"
                                                                        className="detectionCondition__Rag"
                                                                        style={{
                                                                            backgroundColor: colours[ragStatus],
                                                                            height: "10px",
                                                                            width: "10px",
                                                                            borderRadius: "50%",
                                                                            marginLeft: "5px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Option>
                                                    );
                                                }
                                            }
                                        })}
                                    </Select>
                                </ConditionalTooltip>
                            </div>,
                        );
                    } else {
                        checkboxes.push(
                            <Checkbox
                                checked={_.get(currentDetectionConditions, condition, "") === "true"}
                                className="detectionCondition__Checkbox"
                                onChange={(e) => {
                                    const connectingDetectionIDs = _.get(currentDetection, "connecting_detections", []);
                                    _.map([...connectingDetectionIDs, currentDetection], (detection) =>
                                        dispatch(setDetectionConditionData(detection.id, e.target.checked ? "true" : "false", condition)),
                                    );
                                }}>
                                {currentCondition.display_name}
                            </Checkbox>,
                        );
                    }
                });
            }
        }

        if (!selects.length && !checkboxes.length) {
            return null;
        }

        return (
            <div className="detectionCondition">
                {selects}
                {checkboxes.length ? <div className="detectionCondition__Container">{checkboxes}</div> : null}
            </div>
        );
    }, [conditionTypes, currentDetection, dispatch]);

    const detectionItem = useMemo(() => {
        if (currentDetection && railConfig && railConfig.inspection_images && railConfig.inspection_images.length) {
            let reviewStatus = null;
            const statuses = {
                0: { text: "Needs Review", icon: faQuestionCircle, colour: "orange" },
                1: { text: "Verified", icon: faCheckCircle, colour: "lightGreen" },
                2: { text: "Verified & Hidden", icon: faEyeSlash, colour: "darkGreen" },
                3: { text: "Rejected", icon: faTimesCircle, colour: "red" },
            };

            if (!_.isNil(currentDetection.review_status)) {
                const status = statuses[currentDetection.review_status];

                reviewStatus = (
                    <div className="inspectRail__Detections__Status">
                        <div className={`inspectRail__Detections__Status-Content ${status.colour}`}>
                            <FontAwesomeIcon icon={status.icon} />
                            <p>{status.text}</p>
                        </div>
                    </div>
                );
            }

            return (
                <div className="inspectRail__Detections__Item">
                    <div className="inspectRail__Detections__Item-Info">
                        <div className="header">
                            <p className="title">{currentDetection.name}</p>
                            <p className="id">{currentDetection.id}</p>
                        </div>
                        <div className="score">
                            <p>Score:</p>
                            <span className={currentDetection.score > 50 ? "green" : "red"}>{currentDetection.score}%</span>
                        </div>
                        {reviewStatus}
                        {distanceFromDetection}
                        {!_.isEmpty(_.get(currentDetection, "analysis")) &&
                            (_.get(currentDetection, ["analysis", "dip_angle_mrad"]) || _.get(currentDetection, ["analysis", "dip_angle_mrad"], -1) === 0) &&
                            _.get(currentDetection, ["analysis", "dip_angle_valid"]) && (
                                <div className="analysis">
                                    <p className="heading">Analysis</p>
                                    <div className="content">
                                        <p>Dip angle: {currentDetection.analysis.dip_angle_mrad.toFixed(2)} mrad</p>
                                        <Tooltip title={`Linespeed ${currentDetection.analysis.line_speed_mph}mph`}>
                                            {currentDetection.analysis.line_speed_mph && (
                                                <div className="line-speed-icon">
                                                    <span>{currentDetection.analysis.line_speed_mph}</span>
                                                </div>
                                            )}
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        {Math.abs(distanceToLeft) === 0 && renderDetectionConditions}
                    </div>
                </div>
            );
        }
    }, [currentDetection, railConfig, distanceFromDetection, distanceToLeft, autoNavigation, goForward, renderDetectionConditions]);

    let detectionIndex = useMemo(() => {
        let index = currentIndex;
        if (_.isNil(currentIndex) || currentIndex < 0) {
            index = closestIndex;
        }

        if (index > -1 && filteredDetections && filteredDetections.length) {
            return (
                <div className="markerIndex">
                    <p>{index + 1}</p>
                    <span>of</span>
                    <p>{filteredDetections.length}</p>
                </div>
            );
        } else {
            return null;
        }
    }, [filteredDetections, currentIndex, closestIndex]);

    const navigationButtons = useMemo(() => {
        if (!detectionItem) {
            return null;
        }
        return (
            <div className="markerControls wide">
                <div
                    className={`markerControls__Item ${disableBack ? "disabled" : ""}`}
                    onClick={goBack}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>Back (,)</span>
                </div>
                {detectionIndex}
                <div
                    className={`markerControls__Item ${disableForward ? "disabled" : ""}`}
                    onClick={goForward}>
                    <span>Next (.)</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </div>
        );
    }, [detectionIndex, goBack, goForward, disableForward, disableBack, detectionItem]);

    const distanceToNextDetection = useMemo(() => {
        if (selectedRailInspectionImage) {
            const index = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            let prevDetection;
            if (closestToLeftIndex > -1 && filteredDetections[closestToLeftIndex]) {
                const closestDet = filteredDetections[closestToLeftIndex];

                prevDetection = closestDet;
                if (closestDet && closestDet.image_timestamp === selectedRailInspectionImage.timestamp) {
                    prevDetection = filteredDetections[closestToLeftIndex - 1];
                }
            }

            const nextDetection = filteredDetections[closestToRightIndex];
            let prevDistance = 0;
            let nextDistance = 0;

            if (prevDetection && closestToLeftIndex !== 0) {
                const connectingDetections = [..._.get(prevDetection, "connecting_detections", []), prevDetection];
                const leftIndexDetection = _.minBy(connectingDetections, (detection) => {
                    const idx = _.findIndex(railData, (i) => i.timestamp === detection.image_timestamp);
                    return Math.abs(idx - index);
                });

                const prevDetectionIndex = _.findIndex(railData, (i) => i.timestamp === leftIndexDetection.image_timestamp);
                prevDistance = index - prevDetectionIndex;
            }
            if (nextDetection) {
                const nextDetectionIndex = _.findIndex(railData, (i) => i.timestamp === nextDetection.image_timestamp);
                nextDistance = nextDetectionIndex - index;
            }

            if (prevDetection || nextDetection) {
                return (
                    <div className="inspectRail__Detections__Distance">
                        <div className="inspectRail__Detections__Distance-Inner">
                            {!!prevDistance && (
                                <>
                                    <p>Previous Detection</p>
                                    <span>{prevDistance}m</span>
                                </>
                            )}
                        </div>
                        <div className="inspectRail__Detections__Distance-Inner next">
                            {!!nextDistance && (
                                <>
                                    <p>Next Detection</p>
                                    <span>{nextDistance}m</span>
                                </>
                            )}
                        </div>
                    </div>
                );
            }
        }
    }, [filteredDetections, railData, selectedRailInspectionImage, closestToLeftIndex, closestToRightIndex]);

    const reviewSection = useMemo(() => {
        if (currentDetection && Math.abs(distanceToLeft) === 0) {
            return (
                <div className="inspectRail__Detections__Review">
                    {Math.abs(distanceToLeft) === 0 && !currentDetection.review_status ? (
                        <div className="inspectRail__Detections__Review__Inner">
                            <button
                                className="reviewButton reject"
                                onClick={() => revMarker(3)}>
                                <span>Reject (R)</span>
                            </button>
                            <button
                                className="reviewButton verifyhide"
                                onClick={() => revMarker(2)}>
                                <span>Verify & Hide (H)</span>
                            </button>
                            <button
                                className="reviewButton verify"
                                onClick={() => revMarker(1)}>
                                <span>Verify (V)</span>
                            </button>
                        </div>
                    ) : (
                        <div className="RejectionContainer">
                            <Select
                                value={currentDetection.review_status}
                                onChange={onReviewChange}
                                style={{ width: "50%" }}
                                getPopupContainer={(node) => node.parentNode}>
                                <Select.Option value={3}>Rejected</Select.Option>
                                <Select.Option value={2}>Verified & Hidden</Select.Option>
                                <Select.Option value={1}>Verified</Select.Option>
                            </Select>
                            {currentDetection.review_status === 3 ? (
                                <div className="RejectionComment">
                                    <span>Rejection Comment:</span>
                                    {currentDetection.review_comment}
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            );
        }
    }, [currentDetection, revMarker, onReviewChange, distanceToLeft]);

    const onConditionTypeChanged = (typeID) => {
        dispatch(
            updateRailInspectionDetectionCondition(currentDetectionID, typeID, (response) => {
                if (response.success && currentDetection.review_status === 0) {
                    revMarker(1);
                } else if (autoNavigation) {
                    goForward();
                }
            }),
        );
    };

    const handleAutoNavigationToggle = (value) => {
        setAutoNavigation(value);
    };

    const detectionConditions = useMemo(() => {
        const getConditionsTypes = () => {
            if (currentDetection && currentDetection.name) {
                return _.filter(railInspectionConditions, (condition) => {
                    if (String(condition.class).toLocaleUpperCase() !== String(currentDetection.name).toLocaleUpperCase()) {
                        return false;
                    }
                    if (_.includes(Object.keys(conditionTypes), condition.class)) {
                        return false;
                    }
                    return true;
                });
            } else {
                return [];
            }
        };

        const isDisabled = () => {
            return Math.abs(distanceToLeft) === 0;
        };

        const conditions = getConditionsTypes();
        setListOfConditions(conditions);

        if (conditions.length > 0 && isDisabled()) {
            return (
                <>
                    <div className="inspectRail__Detections__Conditions">
                        <div className="rowOfButtons">
                            <Button
                                ghost={currentDetection.condition_id}
                                type={currentDetection.condition_id ? "" : "success"}
                                onClick={() => onConditionTypeChanged(null)}>
                                None (0)
                            </Button>
                            {_.map(conditions, (condition, index) => (
                                <Button
                                    ghost={condition.id !== currentDetection.condition_id}
                                    type={condition.id === currentDetection.condition_id ? "success" : ""}
                                    onClick={() => onConditionTypeChanged(condition.id)}>
                                    {`${condition.description} (${index + 1})`}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="rowOfButtons">
                        <Tooltip title="Automatically go to next detection after selecting condition">
                            <Checkbox
                                style={{ color: "white" }}
                                checked={autoNavigation}
                                onChange={(e) => handleAutoNavigationToggle(e.target.checked)}>
                                Auto next
                            </Checkbox>
                        </Tooltip>
                    </div>
                </>
            );
        }
    }, [railInspectionConditions, currentDetection, autoNavigation, currentDetectionID, distanceToLeft]);

    const filters = useMemo(() => {
        return (
            <div className="inspectRail__Detections__Filters">
                <div
                    className="markerControls__Filter"
                    onClick={() => setDetectionsFiltersOpen(true)}>
                    <span>Filters</span>
                    <FontAwesomeIcon
                        icon={faFilter}
                        style={{ color: "#40A9FF" }}
                    />
                    <div className="filterBadge">{numberOfFilters}</div>
                </div>
            </div>
        );
    }, [faFilter, numberOfFilters]);

    const handleVisibility = useCallback(() => {
        dispatch(setDetectionVisibility());
    }, [dispatch]);

    const setNewNumber = (value) => {
        setNumberOfFilters(value);
    };

    const visibilityButton = useMemo(() => {
        let icon = faEye;
        if (detectionsVisible) {
            icon = faEyeSlash;
        }

        return (
            <div className="inspectRail__Detections__Visibility">
                <div
                    className="inspection-default-button"
                    onClick={handleVisibility}
                    style={{ width: 34, padding: 7, borderRadius: 3 }}>
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
        );
    }, [detectionsVisible, handleVisibility]);

    const handleDownload = useCallback(() => {
        dispatch(toggleInspectionDetectionsExport());
    }, [dispatch]);

    const exportButton = useMemo(() => {
        const exportCount = filteredDetections.length;

        return (
            <Tooltip title={`Export detections`}>
                <div className={"inspectRail__Detections__Visibility" + (exportCount === 0 ? " Disabled" : "")}>
                    <div
                        className="inspection-default-button"
                        onClick={handleDownload}
                        style={{ width: 34, padding: 7, borderRadius: 3 }}>
                        <FontAwesomeIcon icon={faDownload} />
                    </div>
                </div>
            </Tooltip>
        );
    }, [handleDownload, filteredDetections.length]);

    useEffect(() => {
        if (customDimensionsRef.current.width < 500) {
            customDimensionsRef.current.width = 500;
            handleResize((prev) => {
                return { width: 500, height: prev.height };
            });
        }
        if (customDimensionsRef.current.height < 500) {
            customDimensionsRef.current.height = 500;
            handleResize((prev) => {
                return { width: prev.width, height: 500 };
            });
        }
    }, [customDimensionsRef, handleResize]);

    let content = (
        <div className="SchemaRuns__Spinner">
            <OBCSpinner
                size={70}
                speed={3}
                colorScheme="mono"
            />
        </div>
    );

    if (allDetectionsFetched) {
        content = (
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "2px", gridTemplateRows: "auto 1fr auto auto", height: "100%" }}>
                <RejectionModal
                    goForward={goForward}
                    autoNavigation={autoNavigation}
                    detection={currentDetection}
                />

                <DetectionsFiltersModal
                    detectionsFiltersOpen={detectionsFiltersOpen}
                    setDetectionsFiltersOpen={() => setDetectionsFiltersOpen(false)}
                    setNumberOfFilters={setNewNumber}
                />
                <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginBottom: 4 }}>
                    <h4>{Math.abs(distanceToLeft) === 0 ? "Current Detection" : "Closest Detection"}</h4>
                    <div className="inspectRail__Detections__Header-Inner">
                        {filters}
                        {visibilityButton}
                        {exportButton}
                    </div>
                </div>
                {!detectionItem && Object.keys(detectionsData).length > 0 && (
                    <div className="NoDetectionsWithFilterMessage">
                        There are no detections matching current filters, use the filter button above to adjust it
                    </div>
                )}
                <div style={{ maxHeight: "100%", overflow: "auto", paddingTop: 5 }}>{detectionItem}</div>
                <div className="inspectRail__Detections__Follow">
                    {detectionItem && (
                        <Checkbox
                            checked={followDetections}
                            onClick={(e) => dispatch(setFollowDetections(e.target.checked))}
                            className="xinspectRail__Detections__Follow">
                            Follow Detections
                        </Checkbox>
                    )}
                </div>
                <div>
                    {distanceToNextDetection}
                    {reviewSection}
                    <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", marginBottom: 5 }}>{navigationButtons}</div>
                    {detectionConditions}
                </div>
            </div>
        );
    }

    if (allDetectionsFetched && !detectionsData.length) {
        content = (
            <div className="inspectRail__Detections__Message">
                <span>No Detections</span>
            </div>
        );
    }

    return <>{content}</>;
};

export default DetectionsNavigation;
