export default class AssetIDHelper {
    constructor(value) {
        this.value = value;
    }

    static from_asset_data(assetData) {
        const groundTruthData = assetData.ground_truth_data || {};
        const consensusData = assetData.consensus_data || {};

        let landmarkId = "";

        if (groundTruthData["signal_id"]) {
            landmarkId = groundTruthData["signal_id"];
        } else if (groundTruthData["structure_id"]) {
            landmarkId = groundTruthData["structure_id"];
        } else if (consensusData["signal_id"]) {
            landmarkId = consensusData["signal_id"];
        } else if (consensusData["structure_id"]) {
            landmarkId = consensusData["structure_id"];
        } else {
            landmarkId = "Unknown";
        }

        return new AssetIDHelper(landmarkId);
    }

    static from_class_specific_data(specificData) {
        let ocrResult = "";
        if (specificData.ocr_result_custom) {
            ocrResult = specificData.ocr_result_custom;
        } else if (specificData.ocr_result) {
            ocrResult = specificData.ocr_result;
        } else {
            ocrResult = "Unknown";
        }

        return new AssetIDHelper(ocrResult);
    }

    // below will take a sring eg: nk233 or nk/233 or nk,233 and change to NK,233
    format_search_query() {
        let reformattedSearch = String(this.value).toUpperCase().replace(/\//g, ",").replace(/ /g, ",");
        reformattedSearch = reformattedSearch.split(/(?<=[a-zA-Z])(?=\d)|(?<=\d)(?=[a-zA-Z])/);
        reformattedSearch = reformattedSearch.join(",");
        return reformattedSearch;
    }

    formatted_search_query() {
        let reformattedSearch = String(this.value).toUpperCase().replace(/,/g, "/").replace(/ /g, "/");
        reformattedSearch = reformattedSearch.split(/(?<=[a-zA-Z])(?=\d)|(?<=\d)(?=[a-zA-Z])/);
        reformattedSearch = reformattedSearch.join("/");
        return reformattedSearch;
    }

    slashes_to_csv() {
        return AssetIDHelper.convert_slash_to_csv(this.value);
    }

    csv_to_slashes() {
        return AssetIDHelper.convert_csv_to_slashes(this.value);
    }

    static convert_slash_to_csv(value) {
        let convertedValue = String(value).split("/").join(",");
        return convertedValue;
    }

    static convert_csv_to_slashes(value) {
        let convertedValue = String(value).split(",").join("/");
        return convertedValue;
    }
}
