import React from "react";
import { connect, useSelector } from "react-redux";
import {
    goToBounds,
    routeSelected,
    routeHighlighted,
    updateSession,
    archiveSession,
    getSessionData,
    changeToolMode,
    railInspectionExited,
    customAudit,
    flagSession,
    sessionsList,
    resetStillImageAdjustments,
    submitCuration,
    updateZoom,
    sessionIDs,
    archiveInspectionSession,
    archiveSessionStream,
    tagSessionForDeletion,
    setLastFlagReason,
} from "redux/actions/index";
import _ from "lodash";
import moment from "moment";
import "react-day-picker/lib/style.css";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilm,
    faImage,
    faPhotoVideo,
    faChartLine,
    faCog,
    faRulerCombined,
    faObjectUngroup,
    faEdit,
    faStarHalfAlt,
    faTrashAlt,
    faSearch,
    faSun,
    faMoon,
    faArrowLeft,
    faArrowRight,
    faCheckCircle,
    faTag,
    faTags,
    faFlag as faFlagSolid,
    faInfo,
    faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle, faFlag, faPencil } from "@fortawesome/pro-regular-svg-icons";
import memoizeOne from "memoize-one";
import { Button, Input, Modal, notification, Tooltip, Popover, Select } from "antd";
import OBCSpinner from "./util/OBC";
import { LazyTippy } from "./util/LazyTooltips";
import CurationModal from "./CurationModal";
import ContentLoader from "react-content-loader";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { convertToTimezone } from "./util/TimezoneUtils";
import FavoriteButton from "./session/FavoriteButton";
import CameraPosition from "./CameraPosition/CameraPosition";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import Highlighter from "react-highlight-words";
import { getAbsoluteTimestamp } from "./util/PlaylistUtils";
import { formatSessionTags } from "./util/TagUtils";

const { Option } = Select;

const ICON_DICT = { Day: faSun, Night: faMoon, Forward: faArrowRight, Backward: faArrowLeft };
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("ss", 1);

moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d second",
        ss: "%d seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        w: "1 week",
        ww: "%d weeks",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
    },
});

const SessionSkeleton = () => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={55}
        viewBox="0 0 780 55"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175">
        <rect
            x="75"
            y="12"
            rx="3"
            ry="3"
            width="246"
            height="15"
        />
        <rect
            x="75"
            y="32"
            rx="3"
            ry="3"
            width="178"
            height="13"
        />
        <rect
            x="750"
            y="12"
            rx="3"
            ry="3"
            width="30"
            height="30"
        />
        <rect
            x="715"
            y="12"
            rx="3"
            ry="3"
            width="30"
            height="30"
        />
        <rect
            x="5"
            y="32"
            rx="3"
            ry="3"
            width="39"
            height="13"
        />
        <rect
            x="5"
            y="12"
            rx="3"
            ry="3"
            width="39"
            height="13"
        />
    </ContentLoader>
);

const absolutePlayerTimestampSelector = (state) => {
    let absolutePlaylistTimestamp = getAbsoluteTimestamp(state.playlist) * 1000;
    if (!absolutePlaylistTimestamp && state.railInspection.railInspectionImages.data?.length && !state.playlist.video?.length) {
        const currentIndex = _.get(state.playlist, ["position", "currentIndex"]);
        if (currentIndex) {
            absolutePlaylistTimestamp = _.get(state.railInspection.railInspectionImages.data, [currentIndex, "timestamp"], 0);
        }
    }
    return absolutePlaylistTimestamp;
};
const selectedSessionIDSelector = (state) => state.playlist.data.routeID;

const RailInspectionButton = ({ sessionID }) => {
    const absolutePlayerTimestamp = useSelector(absolutePlayerTimestampSelector);
    const selectedSessionID = useSelector(selectedSessionIDSelector);

    return (
        <div className="Session-ControlButton">
            <Tippy
                placement="top"
                arrow={false}
                theme="aivr"
                content={"View Rail Inspection"}>
                <div>
                    <Popover
                        overlayClassName="OpenRailInspectionPopoverWithButtons"
                        trigger="contextMenu"
                        destroyTooltipOnHide
                        content={
                            <div
                                className="OpenRailInspectionPopoverWithButtonsList"
                                onMouseEnter={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}>
                                <Button
                                    type="primary"
                                    size="small"
                                    onMouseEnter={(e) => e.stopPropagation()}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(`${window.location.href}rail-inspection/${sessionID}${absolutePlayerTimestamp}`, "_blank", "noreferrer");
                                    }}>
                                    Open in New Tab
                                </Button>
                            </div>
                        }>
                        <div
                            className="SessionIcon-Button"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            <Link
                                to={`/rail-inspection/${sessionID}${absolutePlayerTimestamp && selectedSessionID === sessionID ? "/" + absolutePlayerTimestamp : ""}`}
                                tabIndex={-1} // temporary disable focus on tab until rest of buttons fixed...
                            >
                                <FontAwesomeIcon
                                    className="SessionIcon"
                                    icon={faSearch}
                                    style={{ color: "#e8dfff" }}
                                />
                            </Link>
                        </div>
                    </Popover>
                </div>
            </Tippy>
        </div>
    );
};
class SessionComponent extends React.PureComponent {
    _mounted = false;

    constructor(props) {
        super(props);
        this.elementRef = React.createRef();

        this.state = {
            showEditModal: false,
            sessionData: null,
            sessionName: "",
            inputError: "",
            loading: false,
            favHovered: false,
            showCurateModal: false,
            sessionIdHovered: false,
            sessionIdCopyText: "Click to copy",
            flagComment: "",
            showFlagModal: false,
            mounted: true,
            displayCameraPosition: false,
            displayQaPolicy: false,
            reasonForFlagging: "",
            selectedReasonForFlagging: "Other (please specify)",
            displayQaModal: false,
            showArchiveModal: false,
            inspectionArchiveStatus: 0,
            allStreamsArchived: false,
        };
    }

    componentDidMount() {
        this._mounted = true;

        this.props.dispatch(getSessionData(this.props.sessionID)).then((sessionData) => {
            if (this._mounted) {
                this.setState({
                    sessionData,
                    sessionName: _.get(sessionData, ["route_name"], ""),
                });
            }
        });

        window.addEventListener("keydown", this.handleKeyPress);
    }

    componentWillUnmount() {
        this._mounted = false;
        window.removeEventListener("keydown", this.handleKeyPress);
    }

    onClick = () => {
        if (this.state.sessionIdHovered || this.props.selectedSessionID === this.props.sessionID) {
            return;
        }

        if (!this.state.sessionData.stream_count && !_.get(this.state.sessionData, "inspection", false)) {
            notification.warning({
                message: "No streams",
                description: "Streams filtered out.",
                duration: 4,
            });
            return;
        }
        if (new Date().getTime() / 1000 - this.state.sessionData.first_seen >= 300) {
            this.props.dispatch(customAudit("session_list_click", { selected_session: this.props.sessionID }));
            this.props.dispatch(goToBounds(this.state.sessionData.bounds));
            this.props.dispatch(routeSelected(this.props.sessionID, 0, undefined, this.props.has8K ? true : undefined));
            this.props.dispatch(routeHighlighted(null));
            this.props.dispatch(changeToolMode(null));
            this.props.dispatch(railInspectionExited());
            this.props.dispatch(resetStillImageAdjustments());
            this.props.dispatch(updateZoom(1));
        }
    };

    onKeyDown = (e) => {
        if (e.key && e.key === "Enter") {
            this.onClick();
        }
    };

    debounceSelectSession = _.debounce(() => {
        this.props.dispatch(routeHighlighted(this.props.sessionID));
    }, 200);

    onMouseOver = () => {
        this.debounceSelectSession();
    };

    onMouseOut = () => {
        this.debounceSelectSession.cancel();
        this.props.dispatch(routeHighlighted(null));
    };

    handleProcessing = () => {
        let sessionUploadTime = moment.unix(_.get(this.state.sessionData, "last_vid_up", 0));
        let differenceTime = moment().diff(sessionUploadTime, "minutes");

        return differenceTime <= 5 || _.get(this.state.sessionData, "last_geom_ts", 0) === 0;
    };

    renderGif = () => {
        let device = this.state.sessionData.device_uuid;
        let uuid = this.state.sessionData.uuid;
        let allArchived = this.state.allStreamsArchived;

        if (device && uuid && !allArchived) {
            let url = `https://raw${MEMOIZED_DOMAIN_URL}/` + device + "/" + uuid + "/index.gif";
            if (this.props.csrfToken) {
                url += `?csrf=${this.props.csrfToken}`;
            }

            return (
                <img
                    src={url}
                    width="200"
                    className="SessionPreview"
                    alt="No Preview Available"
                    crossOrigin={"anonymous"}
                />
            );
        } else {
            return null;
        }
    };

    revealCameraPosition = (e) => {
        e.stopPropagation();
        this.setState({
            displayCameraPosition: true,
        });
    };

    removeCameraPosition = (e) => {
        e.stopPropagation();
        this.setState({
            displayCameraPosition: false,
        });
    };

    editSession = (e) => {
        e.stopPropagation();
        this.setState({
            showEditModal: true,
        });
    };

    openCurateModal = (e) => {
        e.stopPropagation();
        this.setState({
            showCurateModal: true,
        });
    };

    closeCurateModal = () => {
        this.setState({
            showCurateModal: false,
        });
    };

    dispatchCuration = (curation) => {
        this.props.dispatch(
            submitCuration(this.props.sessionID, curation, false, (success) => {
                if (success) {
                    notification.success({
                        message: "Success",
                        description: "Curation Saved",
                    });
                    this.closeCurateModal();
                }
            }),
        );
    };

    onSessionNameChange = (e) => {
        let format = /[`!@#$%^&*+=[\]{};'"\\|,.<>/?~]/;
        let value = e.target.value;

        if (!value) {
            this.setState({
                inputError: "Session name cannot be empty",
            });
        } else if (value.trim().length < 5) {
            this.setState({
                inputError: "Session name must be more than 5 characters",
            });
        } else if (format.test(value)) {
            this.setState({
                inputError: "Session name cannot include special characters",
            });
        } else {
            this.setState({
                inputError: "",
            });
        }

        this.setState({
            sessionName: value,
        });
    };

    saveSessionNameChange = () => {
        console.log("save change");
        this.setState({
            loading: true,
        });
        this.props.dispatch(
            updateSession(this.props.sessionID, this.state.sessionName, (success) => {
                this.setState({
                    loading: false,
                });
                if (success) {
                    this.props.dispatch(getSessionData(this.props.sessionID, true)).then((sessionData) => {
                        this.setState({
                            sessionData,
                            sessionName: _.get(sessionData, ["route_name"], ""),
                        });
                    });
                    notification.success({ message: "Successfully updated session name" });
                }
            }),
        );
        this.closeEditModal();
    };

    closeEditModal = () => {
        this.setState({
            showEditModal: false,
            sessionName: this.state.sessionData.route_name,
        });
    };

    saveDisabled = () => {
        let format = /[`!@#$%^&*+=[\]{};'"\\|,.<>/?~]/;
        if (
            !this.state.sessionName ||
            this.state.sessionName === "" ||
            this.state.sessionName.trim().length < 5 ||
            this.state.sessionName === this.state.sessionData.route_name ||
            format.test(this.state.sessionName)
        ) {
            return true;
        } else {
            return false;
        }
    };

    archiveSession = () => {
        if (this.props.viewedRouteID === this.props.sessionID) {
            this.props.dispatch(routeSelected(null));
        }
        this.props.dispatch(
            archiveSession(this.props.sessionID, true, (success) => {
                if (success) {
                    notification.success({ message: "Successfully archived session" });
                } else {
                    notification.error({ message: "Error archiving session" });
                }
            }),
        );
    };

    archiveInspectionSession = () => {
        this.props.dispatch(archiveInspectionSession(this.state.sessionData.id, 1));
    };

    restoreArchivedInspection = () => {
        this.props.dispatch(archiveInspectionSession(this.state.sessionData.id, 0));
    };

    archiveSessionStream = (sessionID, streamIndex, status) => {
        this.props.dispatch(archiveSessionStream(sessionID, streamIndex, status));
    };

    tagSessionForDeletion = (sessionID) => {
        this.props.dispatch(tagSessionForDeletion(sessionID));
    };

    openArchiveModal = (e) => {
        e.stopPropagation();
        this.setState({ showArchiveModal: true });
    };

    confirmEntireSessionArchive = (e) => {
        e.stopPropagation();
        const _this = this;

        Modal.confirm({
            title: "Are you sure you would like to archive this session?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                console.log("OK");
                _this.archiveSession();
            },
            onCancel() {
                return;
            },
        });
    };

    confirmSessionInspectionArchive = (e) => {
        e.stopPropagation();
        const _this = this;

        Modal.confirm({
            title: "Are you sure you would like to archive this session inspection?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                _this.archiveInspectionSession();
            },
            onCancel() {
                return;
            },
        });
    };

    confirmRestoreArchivedInspection = (e) => {
        e.stopPropagation();
        const _this = this;

        Modal.confirm({
            title: "Are you sure you would like to restore this inspection?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                _this.restoreArchivedInspection();
            },
            onCancel() {
                return;
            },
        });
    };

    confirmStreamArchive = (streamIndex, status, e) => {
        e.stopPropagation();
        const _this = this;
        const sessionID = this.props.sessionID;
        const title =
            status === 1 ? "Are you sure you would like to archive this session stream?" : "Are you sure you would like to restore this session stream?";

        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                _this.archiveSessionStream(sessionID, streamIndex, status);
            },
            onCancel() {
                return;
            },
        });
    };

    confirmTagForDeletion = (e) => {
        e.stopPropagation();
        const _this = this;
        const sessionID = this.props.sessionID;

        Modal.confirm({
            title: "Are you sure you would like to tag this session for deletion?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                _this.tagSessionForDeletion(sessionID);
            },
            onCancel() {
                return;
            },
        });
    };

    handleCancel = () => {
        this.setState({ showArchiveModal: false });
    };

    handleSessionIdClick = (e, id) => {
        e.preventDefault();
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(id);
            this.setState({
                sessionIdCopyText: "Copied!",
            });
        }
    };

    handleSessionIdHovered = (e) => {
        if (e && this.state.sessionIdCopyText === "Copied!") {
            this.setState({
                sessionIdCopyText: "Click to copy",
            });
        }
        this.setState({
            sessionIdHovered: e,
        });
    };

    onFlagButtonClick = () => {
        const flagState = !this.state.sessionData.flagged;
        const flagReason = this.state.reasonForFlagging ? this.state.reasonForFlagging : this.state.selectedReasonForFlagging;
        console.log("debug flagReason", flagReason);
        let comment = flagState ? flagReason : null;
        this.props.dispatch(
            flagSession(this.props.sessionID, flagState, comment, () => {
                const newSessionData = _.cloneDeep(this.state.sessionData);
                newSessionData.flagged = !this.state.sessionData.flagged;
                newSessionData.flagged_comment = comment;
                this.props.dispatch(
                    sessionsList({
                        [this.props.sessionID]: newSessionData,
                    }),
                );
                let filteredSessions = this.props.sessionList.filter((ID) => {
                    return ID !== this.props.sessionID;
                });
                this.props.dispatch(sessionIDs(filteredSessions));
            }),
        );
    };

    flagModalFooter = () => {
        let footer = [<Button onClick={() => this.setState({ showFlagModal: false })}>Cancel</Button>];

        if (this.state.sessionData.flagged) {
            footer.push(
                <Button
                    onClick={this.onFlagButtonClick}
                    type="danger">
                    Unflag
                </Button>,
            );
        } else {
            footer.push(
                <Button
                    onClick={this.onFlagButtonClick}
                    type="danger">
                    Flag
                </Button>,
            );
        }

        return footer;
    };

    confirmFlagSession = (e) => {
        e.stopPropagation();
        if (this.props.playlistTimestamp && this.props.viewedRouteID === this.props.sessionID) {
            const dpDate = new Date(this.props.playlistTimestamp);
            const time = dpDate.toLocaleTimeString().toUpperCase();

            console.log("debug humanTime", time);
            this.setState({
                flagComment: `Video Time: ${time}. `,
                reasonForFlagging: `Video Time: ${time}. Other (please specify). `,
            });
        }
        this.setState({
            showFlagModal: true,
        });
    };

    showCategoryErrorMsg = (value) => {
        this.setState({
            newCategoryErrorMsg: value,
        });
    };

    openFavPopover = () => {
        this.setState({
            categoriesDropdownVisible: true,
        });
    };

    handleQaChange = () => {
        const session = _.cloneDeep(this.state.sessionData);
        let curationData = {};
        if (!session.hasOwnProperty("curation")) {
            curationData.curation_months = 36;
            curationData.qa_checked = true;
        } else {
            curationData = session.curation;
            curationData.qa_checked = !curationData.qa_checked;
        }
        this.props.dispatch(
            submitCuration(session.id, curationData, true, (success) => {
                if (success) {
                    session.curation = curationData;
                    this.setState({ sessionData: session, displayQaModal: false });
                }
            }),
        );
    };

    handleKeyPress = (e) => {
        if (this.state.showFlagModal) {
            if (e.key === "Enter") {
                this.onFlagButtonClick();
            }
        }

        if (this.state.displayQaModal) {
            if (e.key === "Enter") {
                this.handleQaChange();
            }
        }
    };

    render() {
        const session = this.state.sessionData;
        if (session !== null && session !== undefined) {
            const allArchived = session.stream_info.every((stream) => stream.archived === 1);

            if (allArchived) {
                this.setState({ allStreamsArchived: true });
            } else {
                this.setState({ allStreamsArchived: false });
            }
        }
        if (!session) {
            return (
                <div
                    className="SessionListItem"
                    style={this.props.style}>
                    <SessionSkeleton />
                </div>
            );
        }

        const startTime = new Date(_.get(session, "first_seen", 0) * 1000);
        const niceDate = convertToTimezone(startTime, this.props.userConfig.convert_to_utc);

        const hasVideo = _.get(session, "last_vid_up", 0) > 0;
        const hasImages = _.get(session, "last_img_up", 0) > 0;

        let sessionVideoIcon = null;
        if (hasVideo && hasImages) {
            sessionVideoIcon = faPhotoVideo;
        } else if (hasVideo) {
            sessionVideoIcon = faFilm;
        } else if (hasImages) {
            sessionVideoIcon = faImage;
        }

        const isProcessing = this.handleProcessing();
        let previewContent = this.renderGif();

        let duration = 0;
        let niceDuration = 0;
        if (session.length_seconds > 0) {
            duration = moment.duration(session.length_seconds * 1000);
            if (duration._data.hours === 0 && duration._data.minutes === 0 && duration._data.seconds <= 60) {
                niceDuration = "00:01";
            } else {
                niceDuration = moment.utc(duration.as("milliseconds")).format("HH:mm");
            }
        }

        const hasCurationPermissions =
            (!!_.get(this.props.currentDashboard, ["data_pool_permissions", session.data_pool, "curation"], false) && this.props.userIsAdmin) ||
            this.props.userConfig.super_admin;

        const SessionStreams = () => {
            const sessionStreamData = this.state.sessionData.stream_info;

            if (!sessionStreamData || sessionStreamData.length === 0) {
                return <div className="StreamArchiveNoData">No stream data for this session</div>;
            } else {
                return (
                    <>
                        <div className="StreamArchiveLabels">
                            <div>Stream Type</div>
                            <div>Stream Label</div>
                            <div>Archive Status</div>
                            <div>Action</div>
                        </div>
                        {sessionStreamData.map((stream, index) => {
                            const streamIndex = stream.stream_index;
                            const status = stream.archived === 0 ? 1 : 0;

                            return (
                                <div
                                    className="StreamArchiveDataRow"
                                    key={index}>
                                    {stream.type !== undefined ? <div>{stream.type}</div> : <div>No stream type</div>}
                                    {stream.label !== undefined ? <div>{stream.label}</div> : <div>No stream label</div>}
                                    {stream.archived !== undefined ? (
                                        stream.archived === 0 ? (
                                            <div className="NotArchived">Not archived</div>
                                        ) : (
                                            <div className="Archived">Archived</div>
                                        )
                                    ) : (
                                        <div>No stream</div>
                                    )}
                                    {stream.archived !== undefined ? (
                                        stream.archived === 0 ? (
                                            <Button
                                                size="small"
                                                type="danger"
                                                ghost
                                                style={{ width: "100px" }}
                                                onClick={(e) => {
                                                    this.confirmStreamArchive(streamIndex, status, e);
                                                }}>
                                                Archive
                                            </Button>
                                        ) : (
                                            <Button
                                                size="small"
                                                style={{ width: "100px" }}
                                                onClick={(e) => {
                                                    this.confirmStreamArchive(streamIndex, status, e);
                                                }}>
                                                Restore
                                            </Button>
                                        )
                                    ) : (
                                        <div>No stream</div>
                                    )}
                                </div>
                            );
                        })}
                    </>
                );
            }
        };

        return (
            <>
                <Modal
                    title={"Session Archival"}
                    visible={this.state.showArchiveModal}
                    onCancel={() => this.setState({ showArchiveModal: false })}
                    footer={[
                        <Button
                            key="submit"
                            type="primary"
                            onClick={this.handleCancel}>
                            Close
                        </Button>,
                    ]}>
                    <div className="ArchiveSessionModalContainer">
                        <div className="ArchiveModalSection">
                            <div className="Heading">
                                <h3>Session Archival Options</h3>
                            </div>
                            <div className="SessionArchiveContainer">
                                <div className="SessionArchiveLabels">
                                    <div>Session Type</div>
                                    <div>Archive Status</div>
                                    <div>Action</div>
                                </div>
                                <div className="SessionArchiveDataRow">
                                    <div>Entire session</div>
                                    <div className="NotArchived">Not archived</div>
                                    <Button
                                        size="small"
                                        type="danger"
                                        style={{ width: "100px" }}
                                        ghost
                                        onClick={this.confirmEntireSessionArchive}>
                                        Archive
                                    </Button>
                                </div>
                                {this.state.sessionData.inspection_archive_status === 1 ? (
                                    <div className="SessionArchiveDataRow">
                                        <div>Inspection session</div>
                                        <div className="Archived">Archived</div>
                                        <Button
                                            size="small"
                                            style={{ width: "100px" }}
                                            onClick={this.confirmRestoreArchivedInspection}>
                                            Restore
                                        </Button>
                                    </div>
                                ) : null}

                                {this.state.sessionData.inspection && this.state.sessionData.inspection_archive_status === 0 ? (
                                    <div className="SessionArchiveDataRow">
                                        <div>Inspection session</div>
                                        <div className="NotArchived">Not archived</div>
                                        <Button
                                            size="small"
                                            type="danger"
                                            style={{ width: "100px" }}
                                            ghost
                                            onClick={this.confirmSessionInspectionArchive}>
                                            Archive
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="ArchiveModalSection">
                            <div className="Heading">
                                <h3>Stream Archival Options</h3>
                            </div>
                            <div className="StreamArchiveContainer">
                                <SessionStreams />
                            </div>
                        </div>
                        <div className="ArchiveModalSection">
                            <div className="Heading">
                                <h3>Other Options</h3>
                            </div>
                            <Button
                                type="danger"
                                ghost
                                onClick={(e) => {
                                    this.confirmTagForDeletion(e);
                                }}>
                                Tag session for deletion
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title={`Edit Session Name`}
                    visible={this.state.showEditModal}
                    style={{ width: "50%" }}
                    onCancel={this.closeEditModal}
                    footer={[
                        <Button
                            key="close"
                            type="primary"
                            onClick={this.closeEditModal}>
                            Cancel
                        </Button>,
                        <Button
                            className="saveBehaviourButton"
                            key="save"
                            type="primary"
                            onClick={this.saveSessionNameChange}
                            disabled={this.saveDisabled()}>
                            Save
                        </Button>,
                    ]}>
                    {this.state.loading ? (
                        <div className="sessionModalSpinner">
                            <OBCSpinner
                                size={100}
                                speed={3}
                                color={"#e8dfff"}
                            />
                        </div>
                    ) : (
                        <>
                            <input
                                value={this.state.sessionName}
                                onChange={this.onSessionNameChange}
                                className="sessionNameInput"
                            />
                            {this.state.inputError && <p className="error">{this.state.inputError}</p>}
                        </>
                    )}
                </Modal>

                <Modal
                    footer={this.flagModalFooter()}
                    style={{ width: "50%" }}
                    onCancel={() => this.setState({ showFlagModal: false })}
                    visible={this.state.showFlagModal}>
                    {!this.state.sessionData.flagged &&
                        `Flagging this session will cause it to be hidden from everyone except superusers. This is not the same as archiving the session.`}
                    {this.state.sessionData.flagged && (
                        <>
                            <p>Unflagging this session will make it visible to everyone</p>
                            <p>Reason for flagging: {this.state.sessionData.flagged_comment}</p>
                        </>
                    )}
                    {!this.state.sessionData.flagged && (
                        <div style={{ marginTop: 10 }}>
                            <Select
                                defaultValue={this.props.lastFlagReason || "Other (please specify)"}
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                    this.props.dispatch(setLastFlagReason(value));
                                    this.setState({ selectedReasonForFlagging: value });
                                }}>
                                <Option value="Other (please specify). ">Other (please specify)</Option>
                                <Option value="Quality of video data. ">Quality of video data</Option>
                                <Option value="Quality of inspection data. ">Quality of inspection data</Option>
                                <Option value="Other data quality issue (please specify). ">Other data quality issue (please specify)</Option>
                                <Option value="Person visible in footage. ">Person visible in footage</Option>
                                <Option value="Positioning problem. ">Positioning problem</Option>
                                <Option value="Orientation of video data (e.g upside down). ">Orientation of video data (e.g upside down)</Option>
                            </Select>
                            <Input.TextArea
                                onChange={(e) => {
                                    this.setState({ reasonForFlagging: e.target.value });
                                }}
                                value={this.state.reasonForFlagging}
                                style={{ marginTop: 10 }}
                                placeholder="Reason"
                            />
                        </div>
                    )}
                </Modal>

                <Modal
                    title="Update QA Status"
                    visible={this.state.displayQaModal}
                    footer={[
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ displayQaModal: false });
                            }}>
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            onClick={this.handleQaChange}>
                            Confirm
                        </Button>,
                    ]}
                    onCancel={() => this.setState({ displayQaModal: false })}>
                    <p style={{ margin: "0" }}>
                        QA {this.state.sessionData.hasOwnProperty("curation") && this.state.sessionData.curation.qa_checked ? "Accepted" : "Unaccepted"}. Do you
                        want to update the QA status of this session?
                    </p>
                </Modal>

                {this.state.showCurateModal && (
                    <CurationModal
                        session={this.state.sessionData}
                        closeCuration={this.closeCurateModal}
                        curationMonths={this.state.sessionData.hasOwnProperty("curation") ? this.state.sessionData.curation.curation_months : 36}
                        dispatchCuration={this.dispatchCuration}
                        displayQaAccept={true}
                    />
                )}

                <div
                    className={`SessionListItem ${this.props.viewing ? " viewing" : ""} ${!this.state.sessionData.stream_count && !_.get(this.state.sessionData, "inspection", false) ? "SessionListItemDisabled" : ""} `}
                    ref={this.elementRef}
                    role="button"
                    tabIndex={0}
                    onClick={this.onClick}
                    onKeyDown={this.onKeyDown}
                    style={this.props.style}
                    title={!this.state.sessionData.stream_count && !_.get(this.state.sessionData, "inspection", false) ? "No stream available" : null}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}>
                    <div className="Session-IdAndDurationFlex">
                        <div className="SessionDurationContainer">
                            <Tooltip
                                title={this.state.sessionIdCopyText}
                                mouseEnterDelay={0.05}
                                mouseLeaveDelay={0}
                                onVisibleChange={(e) => this.handleSessionIdHovered(e)}>
                                <span
                                    className={`SessionID ${this.state.sessionIdHovered ? "hovered" : ""}`}
                                    onClick={(e) => this.handleSessionIdClick(e, session.id)}>
                                    <span>
                                        #
                                        <Highlighter
                                            highlightClassName="ObcTextHighlight"
                                            searchWords={this.props.sessionIDs.length === 1 ? this.props.sessionSearch.split(" ") : []}
                                            autoEscape={true}
                                            textToHighlight={String(session.id)}
                                        />
                                    </span>
                                </span>
                            </Tooltip>
                            {session.length_seconds > 0 && <span className="SessionStartTime">{niceDuration}</span>}
                        </div>
                    </div>
                    {this.state.displayCameraPosition && <CameraPosition sessionData={this.state.sessionData.camera_position} />}
                    <div className="Session-DetailsFlex">
                        <div className="SessionNameContainer">
                            <Tippy
                                placement="top-start"
                                arrow={false}
                                theme="dark"
                                delay={[150, null]}
                                content={session.route_name}>
                                <div className="SessionName">
                                    <Highlighter
                                        highlightClassName="ObcTextHighlight"
                                        searchWords={this.props.sessionSearch.split(" ")}
                                        autoEscape={true}
                                        textToHighlight={session.route_name}
                                    />
                                </div>
                            </Tippy>
                            <div className="SessionPermissionButtons">
                                {hasCurationPermissions && (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="dark"
                                        content={"Edit"}
                                        delay={0}>
                                        <div className="">
                                            <FontAwesomeIcon
                                                className="SessionEditIcon"
                                                icon={faEdit}
                                                onClick={this.editSession}
                                            />
                                        </div>
                                    </Tippy>
                                )}

                                {this.props.userConfig.super_admin && (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="dark"
                                        content={"Rate Session"}>
                                        <div className="">
                                            <FontAwesomeIcon
                                                className={"SessionEditIcon" + (session.curated ? " curated" : "")}
                                                icon={faStarHalfAlt}
                                                onClick={this.openCurateModal}
                                            />
                                        </div>
                                    </Tippy>
                                )}

                                {this.props.userConfig.super_admin && (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="dark"
                                        content={"Session QA"}>
                                        <div className="">
                                            <FontAwesomeIcon
                                                className={"SessionEditIcon" + (session.curation && session.curation.qa_checked ? " qa_checked" : "")}
                                                icon={faCheckCircle}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({ displayQaModal: true });
                                                }}
                                            />
                                        </div>
                                    </Tippy>
                                )}

                                {hasCurationPermissions && (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="dark"
                                        content={"Archive"}>
                                        <div>
                                            <FontAwesomeIcon
                                                className="SessionEditIcon"
                                                icon={faTrashAlt}
                                                onClick={this.openArchiveModal}
                                            />
                                        </div>
                                    </Tippy>
                                )}

                                {this.props.userConfig.super_admin && (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="dark"
                                        content={"QA Policy"}>
                                        <div className="">
                                            <FontAwesomeIcon
                                                className="SessionEditIcon"
                                                icon={faQuestion}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                        displayQaPolicy: !this.state.displayQaPolicy,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </Tippy>
                                )}
                            </div>
                        </div>
                        <Modal
                            title="QA Policy"
                            visible={this.state.displayQaPolicy}
                            footer={null}
                            onCancel={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    displayQaPolicy: false,
                                });
                            }}>
                            <p style={{ margin: "0" }}>
                                {this.state.sessionData.qa_policy ? JSON.parse(this.state.sessionData.qa_policy).notes : "Not set for this device"}
                            </p>
                        </Modal>
                        <div className="Session-DateAndTime">
                            <span className="SessionDate">{niceDate}</span>
                        </div>
                        <div className="Session-DetailsFlexInnerWhitelist">
                            {this.props.tagsWithIcons && this.props.tagsWithIcons.length
                                ? this.props.tagsWithIcons.map((tag) => {
                                      return (
                                          <span
                                              key={tag}
                                              className={"Session-DetailsFlexInnerWhitelist__WithIcons " + tag}>
                                              <FontAwesomeIcon icon={ICON_DICT[tag]} />
                                              {tag}
                                          </span>
                                      );
                                  })
                                : null}
                            {this.props.tagsWithoutIcons && this.props.tagsWithoutIcons.length ? (
                                <Tippy
                                    placement="top"
                                    arrow={false}
                                    theme="dark"
                                    content={this.props.tagsWithoutIcons.map((tag) => {
                                        return (
                                            <span
                                                key={tag.tag}
                                                className="hoverTagListItem">
                                                {typeof tag.tag_colour !== "string" ? (
                                                    <FontAwesomeIcon icon={faTag} />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        style={{ color: tag.tag_colour }}
                                                        icon={faExclamationCircle}
                                                    />
                                                )}
                                                {" " + (tag.display_name || tag.tag)}
                                            </span>
                                        );
                                    })}>
                                    <span className="tagListItemSpan">
                                        {session.incidents ? (
                                            <FontAwesomeIcon
                                                style={{ color: "#ff4d4d" }}
                                                icon={faExclamationCircle}
                                            />
                                        ) : (
                                            <FontAwesomeIcon icon={faTags} />
                                        )}
                                        {_.chain(this.props.tagsWithoutIcons.map((tag) => tag.display_name || tag.tag))
                                            .join(", ")
                                            .value()}
                                    </span>
                                </Tippy>
                            ) : null}
                        </div>
                    </div>

                    <div className="Session-ControlButtonsFlex">
                        {this.props.userConfig.super_admin ? (
                            <div className="Session-ControlButton">
                                <LazyTippy
                                    placement={"top"}
                                    sticky={true}
                                    plugins={[sticky]}
                                    arrow={false}
                                    theme="aivr"
                                    content={"Flag Session"}>
                                    <div>
                                        {session.flagged ? (
                                            <FontAwesomeIcon
                                                icon={faFlagSolid}
                                                style={{ fontSize: 18, color: "red" }}
                                                onClick={this.confirmFlagSession}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faFlag}
                                                style={{ fontSize: 18 }}
                                                onClick={this.confirmFlagSession}
                                            />
                                        )}
                                    </div>
                                </LazyTippy>
                            </div>
                        ) : null}

                        {previewContent && sessionVideoIcon ? (
                            <div className="Session-ControlButton">
                                <LazyTippy
                                    placement={isProcessing ? "top" : "left"}
                                    sticky={true}
                                    plugins={[sticky]}
                                    arrow={false}
                                    theme="aivr"
                                    content={isProcessing ? "Processing" : previewContent}>
                                    <div>
                                        {isProcessing ? (
                                            <FontAwesomeIcon
                                                icon={faCog}
                                                spin={true}
                                                style={{ fontSize: 18 }}
                                                color="#BEB9D8"
                                            />
                                        ) : session.stream_count > 1 ? (
                                            <span className="fa-layers SessionImageVideoIcon fa-fw">
                                                <FontAwesomeIcon icon={sessionVideoIcon} />
                                                <span
                                                    className="fa-layers-counter"
                                                    style={{ background: "#1C1E30" }}>
                                                    {session.stream_count}
                                                </span>
                                            </span>
                                        ) : (
                                            <FontAwesomeIcon
                                                className="SessionImageVideoIcon fa-fw"
                                                icon={sessionVideoIcon}
                                            />
                                        )}
                                    </div>
                                </LazyTippy>
                            </div>
                        ) : null}

                        {_.get(this.state.sessionData, "camera_position", false) &&
                            this.props.workspaceLayout &&
                            !["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                                <div
                                    className="Session-ControlButton"
                                    onMouseEnter={this.revealCameraPosition}
                                    onMouseLeave={this.removeCameraPosition}>
                                    <FontAwesomeIcon
                                        className="camera-position-icon"
                                        icon={faInfo}
                                        style={{ fontSize: "16px" }}
                                    />
                                </div>
                            )}

                        <div className="Session-ControlButton">
                            <div>
                                <FavoriteButton
                                    sessionID={session.id}
                                    placement="bottomLeft"
                                    align={{ offset: [-10, 1] }}
                                />
                            </div>
                        </div>
                        {session.has_annotation ||
                        session.has_measurement ||
                        session.has_sketch ||
                        (session.displayable_classifications && session.displayable_classifications.length > 0) ? (
                            <>
                                {session.has_annotation ? (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="aivr"
                                        content={"Annotations"}>
                                        <div className="Session-ControlButton">
                                            <FontAwesomeIcon
                                                className="SessionIcon"
                                                icon={faObjectUngroup}
                                                style={{ color: "#7AC7F7" }}
                                            />
                                        </div>
                                    </Tippy>
                                ) : null}
                                {session.has_measurement ? (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="aivr"
                                        content={"Measurements"}>
                                        <div className="Session-ControlButton">
                                            <FontAwesomeIcon
                                                className="SessionIcon"
                                                icon={faRulerCombined}
                                                style={{ color: "#4DDBC0" }}
                                            />
                                        </div>
                                    </Tippy>
                                ) : null}
                                {session.has_sketch ? (
                                    <Tippy
                                        placement="top"
                                        arrow={false}
                                        theme="aivr"
                                        content={"Markup"}>
                                        <div className="Session-ControlButton">
                                            <FontAwesomeIcon
                                                className="SessionIcon"
                                                icon={faPencil}
                                                style={{ color: "#E9DF6D" }}
                                            />
                                        </div>
                                    </Tippy>
                                ) : null}
                            </>
                        ) : null}
                        {session.inspection &&
                        this.state.inspectionArchiveStatus === 0 &&
                        _.get(this.props.currentDashboard, ["config", "rail_inspection_enabled"]) ? (
                            <RailInspectionButton sessionID={session.id} />
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
}

const detectionTypesByType = memoizeOne((annotationTypes) => _.keyBy(annotationTypes, "type"));
const EMPTY = [];

const mapStateToSessionProps = (state, ownProps) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const detectionTypes = detectionTypesByType(state.detectionTypes);
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const allTags = state.sessionTags;
    const session = _.get(state.sessions, [ownProps.sessionID], {});

    const [tagsWithIcons, tagsWithoutIcons, has8K] = formatSessionTags(session, detectionTypes, allTags);

    return {
        viewedRouteID: parseInt(state.playlist.data.routeID),
        userIsAdmin: state.permissions.admin,
        userConfig: state.userDetails.userConfig,
        currentDashboard,
        tagsWithIcons,
        tagsWithoutIcons,
        has8K,
        playlistTimestamp: state.playlist?.position.timestamp,
        favouriteCategories: state.admin.favouriteCategories,
        selectedSessionID: state.playlist.data.routeID,
        workspaceLayout: _.get(currentDashboard, "workspace_layout", false),
        sessionList: state.sessionList,
        sessionSearch: state.sessionListFilters.search,
        lastFlagReason: state.lastFlagReason,
        sessionIDs: state.sessionList,
        csrfToken: state.csrfToken,
        session,
        detectionTypes,
        allTags,
    };
};

const Session = connect(mapStateToSessionProps)(SessionComponent);
export default Session;
