import { updateWidgetData, updateDashboardKey, requestPlaylistPosition, routeSelected, widgetUserConfig } from "./actions/index";
import moment from "moment";
import { nanoid } from "nanoid";
import _ from "lodash";
import { getCurrentVideoKey, videoKeyToTimestamp, absoluteTimeLookup, binarySearch } from "../components/util/PlaylistUtils";
import { windowFeaturesString as railDetailWidgetWindowFeaturesString } from "components/Widgets/RailDetailWidget";
import { addWidget, changeWidgetStatus, removeWidget } from "./actions/widgetActions";

export const SOURCE_BLACKLIST = ["@devtools-page", "react-devtools-bridge", "react-devtools-content-script"];

export const MESSAGE_TYPES = {
    PARENT_CONNECTION: "PARENT_CONNECTION", //Called by child to connect to parent window
    CHILD_CONNECTION: "CHILD_CONNECTION", //Called by parent to connect to child window
    PARENT_REFRESHED: "PARENT_REFRESHED", //Sent by parent to trigger reconnection retry loop
    CHILD_CLOSE: "CLOSE", //Sent by parent to close a child
    CHILD_UNMOUNTED: "UNMOUNTED", //sent by child to parent to inform it that it is refreshing or closing
};

const windowManager = () => {
    let current_token = null;
    let local_id = nanoid(6);
    let ws_state = {};
    let dashboardKeyData = {};
    let current_status = {
        type: "DASHBOARD",
        src: local_id,
        data: {
            routeID: null,
            position: null,
        },
    };
    let childWindows = [];
    let parentWindow = null;

    window.addEventListener("beforeunload", () => {
        sendMessage({
            type: MESSAGE_TYPES.PARENT_REFRESHED,
        });
    });

    const removeChildWindow = (window) => {
        const index = childWindows.indexOf(window);
        if (index > -1) {
            childWindows.splice(index, 1);
        }
    };

    const handleMessage = (store, message) => {
        if (SOURCE_BLACKLIST.includes(message.data?.source)) {
            return;
        }
        const messageType = message.data.type;
        switch (messageType) {
            case MESSAGE_TYPES.CHILD_CONNECTION:
                childWindows.push(message.source);
                message.source.postMessage({ type: MESSAGE_TYPES.PARENT_CONNECTION }, "*");

                if (!store.getState().widgets.hasOwnProperty(message.source.name)) {
                    store.dispatch(addWidget(message.source.name));
                }
                store.dispatch(changeWidgetStatus(message.source.name, true));
                break;
            case MESSAGE_TYPES.PARENT_CONNECTION:
                parentWindow = message.source;
                break;
            case MESSAGE_TYPES.PARENT_REFRESHED:
                if (!window.opener) {
                    removeChildWindow(message.source);
                } else {
                    setTimeout(() => {
                        connectToParent(true);
                    }, 1000);
                }
                break;
            case MESSAGE_TYPES.CHILD_CLOSE:
                window.close();
                break;
            case MESSAGE_TYPES.CHILD_UNMOUNTED:
                store.dispatch(removeWidget(message.data.widgetType));
                break;
            default:
                onMessage(store, message);
        }
    };

    const sendMessage = (message, target) => {
        if (target) {
            const targetWindows = childWindows.filter((window) => window.name === target);
            let targetWindow;

            if (targetWindows.length > 0) {
                targetWindow = targetWindows[0];
                targetWindow.postMessage({ type: MESSAGE_TYPES.CHILD_CLOSE }, "*");
            }

            return;
        }

        if (parentWindow) {
            parentWindow.postMessage(message, "*");
        } else {
            childWindows.forEach((wind) => {
                wind.postMessage(message, "*");
            });
        }
    };

    const connectToParent = (forceNew = false) => {
        if (parentWindow && !forceNew) {
            return;
        }
        parentWindow = null;

        if (!window.opener) window.close();
        window.opener.postMessage({ type: MESSAGE_TYPES.CHILD_CONNECTION, widgetType: window.name }, "*");

        setTimeout(() => {
            connectToParent();
        }, 2000);
    };

    const onMessage = (store, event) => {
        const payload = event.data;
        if (payload.action) {
            switch (payload.action) {
                case "request_position":
                    const state = store.getState();
                    const playlistPosition = state.playlist.position;
                    const playlistData = state.playlist.data;

                    let playlistIndex = -1;
                    let newOffset = 0;
                    const sourceIndex = playlistPosition.sourceIndex;

                    let timestamp = payload.timestamp;
                    if (timestamp > 9999999999) {
                        timestamp = timestamp / 1000;
                    }

                    const video = _.get(playlistData, ["video", sourceIndex], []);
                    if (video.length) {
                        playlistIndex = absoluteTimeLookup(timestamp, video);

                        if (playlistIndex !== -1) {
                            const newAbsoluteTimestamp = video[playlistIndex][3][2];
                            newOffset = timestamp - newAbsoluteTimestamp;
                        }
                        if (newOffset < 0) {
                            newOffset = 0;
                        }
                    } else {
                        const railImages = _.get(state, ["railInspection", "railInspectionImages", "data"], []);
                        playlistIndex = binarySearch(payload.timestamp, railImages, (image) => image.timestamp);
                    }
                    store.dispatch(
                        requestPlaylistPosition(
                            playlistPosition.isVideo,
                            playlistPosition.isEnhanced,
                            playlistPosition.isStills,
                            sourceIndex,
                            playlistIndex,
                            newOffset,
                        ),
                    );
                    break;
                case "update_widget_user_config":
                    store.dispatch(widgetUserConfig(payload.data));
                    break;
                case "clone_rail_detail_widget":
                    const ws_token = store.getState().userDetails.userConfig.ws_token;
                    window.open(`/widget/rail-detail/${ws_token}`, "", railDetailWidgetWindowFeaturesString);
                    break;
                case "request_map_position":
                    store.dispatch(routeSelected(payload.sessionID, payload.coords));
                    break;
                default:
                    break;
            }
        } else if (payload.src && payload.type && payload.data) {
            if (!window.location.href.includes("widget")) {
                return;
            }
            if (!ws_state[payload.type]) {
                ws_state[payload.type] = {};
            }
            ws_state[payload.type][payload.src] = {
                last_updated: moment.now(),
                state: payload.data,
            };
            store.dispatch(updateWidgetData(_.cloneDeep(ws_state)));
            store.dispatch(updateDashboardKey(payload.src));
            if (_.isEmpty(dashboardKeyData) || dashboardKeyData.last_message < Date.now() / 1000 - 60) {
                dashboardKeyData = {
                    key: payload.src,
                    last_message: Date.now() / 1000,
                };
            } else if (dashboardKeyData.key === payload.src) {
                dashboardKeyData.last_message = Date.now() / 1000;
            }
        }
    };

    // Processing function for all dispatch events
    return (store) => (next) => (action) => {
        const result = next(action);

        switch (action.type) {
            case "__INIT__":
                window.addEventListener("message", (e) => handleMessage(store, e));
                break;
            case "WS_SEND":
                sendMessage(action.msg, action.target);
                break;
            case "WS_STATUS_POLL":
                if (childWindows.length) {
                    const state = store.getState();
                    current_status.data.position = state.playlist.position;
                    current_status.data.routeID = _.get(state, ["playlist", "data", "routeID"], null);

                    let currentTimestamp = 0;

                    const selectedRailImage = _.get(state, ["railInspection", "selectedRailInspectionImage"]);
                    if (!_.isEmpty(selectedRailImage) && !_.isNil(selectedRailImage.timestamp)) {
                        currentTimestamp = selectedRailImage.timestamp;
                    } else {
                        const currentVideoKey = getCurrentVideoKey(state.playlist);
                        if (currentVideoKey) {
                            currentTimestamp = videoKeyToTimestamp(currentVideoKey) + state.playlist.position.currentTimeOffset * 1000;
                        }
                    }

                    current_status.data.position.timestamp = currentTimestamp;
                    current_status.data.workspace_id = _.get(state, ["userDetails", "dashboardAccessID"], null);
                    current_status.data.position.railInspectionIndex = _.get(state, ["railInspection", "selectedRailInspectionImage", "index"], 0);
                    current_status.data.position.railInspectionLatPos = _.get(state, ["railInspection", "selectedRailInspectionImage", "latPos"], null);

                    sendMessage(current_status);
                } else if (current_token) {
                    console.log("[WS] Reconnecting");
                    // connectSocket(store);
                }
                break;
            case "WS_WIDGET_PING":
                sendMessage({
                    type: "WIDGET",
                    src: local_id,
                    data: {
                        widgetType: action.widgetType,
                    },
                });
                break;
            case "CONNECT_TO_PARENT":
                connectToParent();
                break;
            case "PARENT_REFRESHED":
                sendMessage({
                    type: MESSAGE_TYPES.PARENT_REFRESHED,
                });
                break;
            case "USER_CONFIG":
                sendMessage({
                    action: "update_widget_user_config",
                    data: action.userConfig,
                });
                break;
            default:
                break;
        }
        return result;
    };
};

export default windowManager();
