import React, { useEffect, useRef, useCallback, useMemo, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-edgebuffer";
import { useSelector, useStore, useDispatch } from "react-redux";
import {
    setPatrolLocation,
    patrolCovered,
    updatePatrolProgress,
    getPatrolDirectionsV2,
    setSelectedRailInspectionImage,
    setCurrentSchemaMarkup,
    setSelectedPatrolSessionId,
    setPatrolDisplayConfig,
} from "redux/actions/index";
import { findNearestRailImage, chainageFromImage, ONE_METRE_IN_CHAINS, calculatePatrolDeviceConfig } from "components/util/Geometry";
import OBCSpinner from "../util/OBC";
import _ from "lodash";
import LabelCreateWindow from "../display/image/LabelCreateWindow";
import AnnotationCreateWindow from "../display/image/AnnotationCreateWindow";
import { DynamicLayer, InfoLayer } from "./SchemaScrollerLayers";
import KeyboardShortcuts from "./KeyboardShortcuts";
import RailInspectionConverter from "../util/RailInspectionConverter";
import Measure from "react-measure";
import { Button, Progress } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { calculateOffsettedPatrolImage } from "../util/PlaylistUtils";
import { convertToTimezone } from "../util/TimezoneUtils";

const imageHeight = 62.5;
const imageWidth = 127.5;

const mapPinIcon = L.icon({
    iconUrl: "/bookmark-pin.png",
    iconSize: [30, 45], // size of the icon
    iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
});

const sessionSelector = (state) => state.schemaInterface.sessions;
const schemaLocationSelector = (state) => state.schemaInterface.location;
const railDataLoadedSelector = (state) => state.schemaInterface.imagesLoaded;
const railImageConfigSelector = (state) => state.schemaInterface.config;
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const selectedSessionSelector = (state) => state.schemaInterface.selectedSession;
const currentImageAdjustmentsSelector = (state) => state.railInspection.imageAdjustments;
const inspectingPatrolSelector = (state) => state.schemaInterface.inspectingPatrol;
const fetchingELRsSelector = (state) => state.schemaInterface.fetchingELRs;
const patrolPlanSelector = (state) => state.schemaInterface.plan;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const annotationsSelector = (state) => state.railInspection.annotations.data;
const patrolReportOpenSelector = (state) => state.schemaInterface.patrolReport.patrolReportOpen;
const selectedInspectionMarkupSelector = (state) => state.schemaInterface.selectedMarkup;
const patrolIDSelector = (state) => state.schemaInterface.plan.id;
const allImagesSelector = (state) => state.schemaInterface.images;
const userConfigSelector = (state) => state.userDetails.userConfig;
const bugModalOpenSelector = (state) => state.schemaInterface.bugReportOpen;
const diagramReportOpenSelector = (state) => state.schemaInterface.diagramReportOpen;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const arrowLeftSelector = (state) => state.railInspection.directionArrowLeft;
const patrolImagesLoadedSelector = (state) => state.schemaInterface.patrolImagesLoaded;
const csrfTokenSelector = (state) => state.csrfToken;

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SchemaScroller = ({
    bookmarkModeActive,
    historyOpen,
    toggleBookmarkMode,
    annotatingActive,
    setAnnotatingActive,
    setUpdatingBookmarkID,
    setUpdatingAnnotationID,
    updatingAnnotationID,
    updatingBookmarkID,
    setLabelWindowOpen,
    labelWindowOpen,
    setAnnotationWindowOpen,
    annotationWindowOpen,
    match,
    patrolCompleted,
    isPlanner,
    isSupervisor,
    patrolEditable,
}) => {
    const dispatch = useDispatch();
    const store = useStore();
    const map = useRef();
    const leafletMap = useRef();
    const infoTilesRef = useRef();
    const mapDragging = useRef(false);
    const isMouseDown = useRef(false);
    const scrollingRef = useRef();
    const scrollingCount = useRef(0);
    const layersDictRef = useRef({});
    const bookmarksLayerRef = useRef(L.layerGroup());

    const drawingLayerRef = useRef(null);
    const drawingControlRef = useRef(null);
    const [railInspectionConverter, setRailInspectionConverter] = useState(null);

    const isReview = match.path.includes("review-patrol");

    const sessions = useSelector(sessionSelector);
    const selectedSession = useSelector(selectedSessionSelector);
    const currentLocation = useSelector(schemaLocationSelector);
    const imagesLoaded = useSelector(railDataLoadedSelector);
    const railImageConfigs = useSelector(railImageConfigSelector);
    const inspectionBookmarks = useSelector(bookmarksSelector);
    const currentImageAdjustments = useSelector(currentImageAdjustmentsSelector);
    const inspectingPatrol = useSelector(inspectingPatrolSelector);
    const fetchingELRs = useSelector(fetchingELRsSelector);
    const patrolPlan = useSelector(patrolPlanSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const reduxAnnotations = useSelector(annotationsSelector);
    const patrolReportVisible = useSelector(patrolReportOpenSelector);
    const selectedMarkup = useSelector(selectedInspectionMarkupSelector);
    const patrolID = useSelector(patrolIDSelector);
    const allRailImages = useSelector(allImagesSelector);
    const userConfig = useSelector(userConfigSelector);
    const reportProblemOpen = useSelector(bugModalOpenSelector);
    const diagramReportOpen = useSelector(diagramReportOpenSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const arrowLeft = useSelector(arrowLeftSelector);
    const patrolImagesLoaded = useSelector(patrolImagesLoadedSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [imageTimestampClicked, setImageTimestampClicked] = useState();

    const [imageSource, setImageSource] = useState();
    const [bookmarkCoords, setBookmarkCoords] = useState();

    const [annotations, setAnnotations] = useState([]);
    const [annotationData, setAnnotationData] = useState({});
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

    const [zoom, setZoom] = useState(1);

    const [navigatedToQueryParams, setNavigatedToQueryParams] = useState(false);

    const queryParams = useQuery();

    const initialised = useMemo(() => {
        return !fetchingELRs && imagesLoaded;
    }, [fetchingELRs, imagesLoaded]);

    useEffect(() => {
        if (drawingControlRef && drawingControlRef.current) {
            if (annotatingActive) {
                drawingControlRef.current.enable();
            } else {
                drawingControlRef.current.disable();
            }
        }
    }, [annotatingActive]);

    useEffect(() => {
        setAnnotations(reduxAnnotations);
    }, [reduxAnnotations]);

    const currentSession = useMemo(() => {
        if (selectedSession > -1 && sessions.length) {
            const currentSessionImages = sessions[selectedSession];
            const sessionStartTimestamp = currentSessionImages[0].timestamp / 1000;

            let nearestDirectionKey = null;
            const directionKeys = Object.keys(patrolDirections);
            const sortedDirectionKeys = directionKeys.map((ts) => parseInt(ts));
            sortedDirectionKeys.sort(function (a, b) {
                return a - b;
            });
            sortedDirectionKeys.forEach((directionTimestamp) => {
                if (sessionStartTimestamp >= directionTimestamp) {
                    nearestDirectionKey = directionTimestamp;
                }
            });
            if (nearestDirectionKey) {
                return patrolDirections[nearestDirectionKey];
            }
        }
        return null;
    }, [patrolDirections, selectedSession, sessions]);

    const railImageConfig = useMemo(() => {
        const railImageConfig = calculatePatrolDeviceConfig(railImageConfigs, sessions[selectedSession] ? sessions[selectedSession][0].timestamp / 1000 : 0);
        dispatch(setPatrolDisplayConfig(railImageConfig));
        return railImageConfig;
    }, [railImageConfigs, selectedSession, sessions, dispatch]);

    const sessionIsBackward = useMemo(() => {
        if (currentSession) {
            return currentSession.direction === "Backward";
        }
        return false;
    }, [currentSession]);

    useEffect(() => {
        if (currentSession && currentSession.session_id) {
            dispatch(setSelectedPatrolSessionId(currentSession.session_id));
        }
    }, [currentSession, dispatch]);

    useEffect(() => {
        if (initialised) {
            if (sessions.length && patrolPlan.device_group_id) {
                const maxTimestamp = sessions[0][0].timestamp / 1000;
                const lastSession = sessions[sessions.length - 1];
                const minTimestamp = lastSession[0].timestamp / 1000;
                dispatch(getPatrolDirectionsV2(patrolPlan.device_group_id, minTimestamp - 60 * 60 * 2, maxTimestamp));
            }
        }
    }, [initialised, dispatch, patrolPlan.device_group_id, sessions]);

    const onAnnotationCreated = useCallback(
        (e) => {
            const bounds = e.layer._latlngs[0];

            let topLeft = bounds[1];
            let bottomRight = bounds[3];

            if (sessionIsBackward) {
                topLeft = bounds[3];
                bottomRight = bounds[1];
            }

            const imageData = railInspectionConverter.clickToImageData(topLeft.lat * 16, topLeft.lng * 16);
            if (!imageData) {
                setAnnotatingActive(false);
                return;
            }
            const width = Math.abs(topLeft.lng - bottomRight.lng) * 16;
            const height = Math.abs(topLeft.lat - bottomRight.lat) * 16;

            const bbox = [imageData.coordinates[0], imageData.coordinates[1], height, width];

            const newAnnotations = _.cloneDeep(annotations);
            newAnnotations.push({
                id: -2,
                image_timestamp: imageData.imageClicked.timestamp,
                image_source: imageData.source,
                bbox: JSON.stringify(bbox),
            });
            setAnnotations(newAnnotations);
            setAnnotationWindowOpen(true);
            setAnnotationData({
                bbox,
                imageTimestamp: imageData.imageClicked.timestamp,
                source: imageData.source,
            });
            setAnnotatingActive(false);
        },
        [annotations, sessionIsBackward, setAnnotatingActive, railInspectionConverter],
    );

    const railImages = useMemo(() => {
        let retVal;
        if (sessions && imagesLoaded && sessions[selectedSession]) {
            retVal = sessions[selectedSession];
        } else {
            retVal = [];
        }

        return retVal;
    }, [imagesLoaded, selectedSession, sessions]);

    const panToIndex = useCallback(
        (newRailImageIndex) => {
            if (leafletMap.current) {
                if (newRailImageIndex < 0 || !newRailImageIndex) {
                    newRailImageIndex = 0;
                }

                const railImage = railImages[newRailImageIndex];

                dispatch(setSelectedRailInspectionImage(newRailImageIndex, railImage, false, 0.5));

                leafletMap.current.setView([(newRailImageIndex - 0.5) * imageHeight, leafletMap.current.getCenter().lng], leafletMap.current.zoom, {
                    animate: true,
                    duration: 1,
                    easeLinearity: 0.5,
                });
            }
        },
        [dispatch, railImages],
    );

    useEffect(() => {
        const index = findNearestRailImage(currentLocation.elr, currentLocation.chain, currentLocation.trackID, railImages);
        if (!isMouseDown.current && !mapDragging.current && !scrollingRef.current && index > -1) {
            panToIndex(index);
        }
    }, [currentLocation, railImages, panToIndex]);

    useEffect(() => {
        let newRailInspectionConverter = new RailInspectionConverter({
            railImages,
            leafletTileOffset: 0,
            railImageConfig,
            horizontal: false,
            sessionIsBackward,
            patrol: true,
        });

        setRailInspectionConverter(newRailInspectionConverter);
        return () => {
            setRailInspectionConverter(null);
        };
    }, [railImageConfig, sessionIsBackward, railImages]);

    const onAnnotationClick = useCallback(
        (annotation) => {
            if (!isSupervisor && !annotation.is_users) {
                console.log("Cannot edit another users annotation, unless you are a supervisor");
                return;
            }
            setUpdatingAnnotationID(annotation.id);
            setAnnotationWindowOpen(true);
        },
        [isSupervisor],
    );

    const renderAnnotationLayer = useCallback(() => {
        drawingLayerRef.current = new L.FeatureGroup();

        if (annotations && annotations.length) {
            annotations.forEach((annotation) => {
                const bbox = JSON.parse(annotation.bbox);

                const annotationStartLocation = railInspectionConverter.sourceTimestampToCoordinates(annotation.image_timestamp, annotation.image_source, [
                    bbox[0],
                    bbox[1],
                ]);
                if (!annotationStartLocation) {
                    return;
                }
                const topLeft = [annotationStartLocation[0], annotationStartLocation[1]];
                let leftMost = topLeft;

                let bottomRightBbox = [bbox[0] - bbox[2], bbox[1] + bbox[3]];

                if (sessionIsBackward) {
                    bottomRightBbox = [bbox[0] + bbox[2], bbox[1] - bbox[3]];
                }

                const annotationEndLocation = railInspectionConverter.sourceTimestampToCoordinates(
                    annotation.image_timestamp,
                    annotation.image_source,
                    bottomRightBbox,
                );
                const bottomRight = [annotationEndLocation[0], annotationEndLocation[1]];

                if (sessionIsBackward) {
                    leftMost = bottomRight;
                }

                const rect = L.rectangle([topLeft, bottomRight], { weight: 2 });
                rect.on("click", () => onAnnotationClick(annotation));

                let markerText = "";
                if (annotation.annotation_type === -1) {
                    markerText = "Other";
                } else if (annotation.annotation_type) {
                    const annotationType = _.find(annotationTypes, (type) => parseInt(type.id) === parseInt(annotation.annotation_type));
                    if (annotationType) {
                        markerText = annotationType.type;
                    }
                }

                if (markerText) {
                    const invisibleMarker = L.circleMarker(leftMost, { opacity: 0, radius: 0 });
                    invisibleMarker.bindTooltip(markerText, {
                        permanent: true,
                        className: "AnnotationLabel dark",
                        offset: [10, 0],
                        direction: "top",
                        opacity: 0.8,
                    });
                    drawingLayerRef.current.addLayer(invisibleMarker);
                }
                drawingLayerRef.current.addLayer(rect);
            });
        }

        leafletMap.current.addLayer(drawingLayerRef.current);
    }, [annotations, annotationTypes, onAnnotationClick, sessionIsBackward, railInspectionConverter]);

    useEffect(() => {
        if (leafletMap.current) {
            leafletMap.current.invalidateSize();
        }
    }, [windowDimensions]);

    const maxOffset = useMemo(() => {
        if (!railImageConfig || !railImageConfig.inspection_images) {
            return 0;
        }
        let maxOffset = 0;

        let offsetParam;
        if (sessionIsBackward) {
            offsetParam = "offset_y_backward";
        } else {
            offsetParam = "offset_y_forward";
        }

        Object.keys(railImageConfig.inspection_images).forEach((key) => {
            const item = railImageConfig.inspection_images[key];
            const offset = _.get(item, [offsetParam], 0);
            if (Math.abs(offset) > Math.abs(maxOffset)) {
                maxOffset = offset;
            }
        });

        return maxOffset;
    }, [railImageConfig, sessionIsBackward]);

    useEffect(() => {
        if (initialised) {
            let sessionLength = 0;
            if (sessions[selectedSession]) {
                sessionLength = Math.max(5, sessions[selectedSession].length);
            }

            let bound = maxOffset * -1;

            if (maxOffset > 0) {
                bound += 3;
            } else {
                bound -= 3;
            }

            const mapOptions = {
                center: [0, imageWidth * 0.5],
                zoom: 1,
                crs: L.CRS.Simple,
                maxBounds: [
                    [imageHeight * bound, -imageWidth],
                    [sessionLength * imageHeight, imageWidth * 2],
                ],
                attributionControl: false,
            };
            leafletMap.current = new L.Map(map.current, mapOptions);

            drawingControlRef.current = new L.Draw.Rectangle(leafletMap.current, { showArea: false });
            L.drawLocal.draw.handlers.rectangle.tooltip.start = "Click and drag to create an annotation";
            L.drawLocal.draw.handlers.simpleshape.tooltip.end = "";

            return () => {
                leafletMap.current.remove();
                leafletMap.current = null;
                drawingControlRef.current = null;
            };
        }
    }, [initialised, selectedSession, sessions, maxOffset]);

    const navigateToMarkup = useCallback(
        (type, id) => {
            let markups = [];
            if (type === "bookmark") {
                markups = inspectionBookmarks;
            } else if (type === "annotation") {
                markups = reduxAnnotations;
            }

            if (markups && markups.length) {
                const markup = _.find(markups, (markup) => markup.id.toString() === id.toString());

                if (!markup) {
                    return;
                }

                const markupImage = calculateOffsettedPatrolImage(markup, railImageConfig, patrolDirections, allRailImages);

                if (!markupImage) {
                    return;
                }

                const locationObj = {
                    elr: markupImage.mwv.elr,
                    chain: parseInt(markupImage.mwv.mile) * 80 + parseFloat(markupImage.mwv.yard) / 22,
                    trackID: markupImage.mwv.trid,
                };
                dispatch(setPatrolLocation(locationObj, markupImage.timestamp));
            }
        },
        [inspectionBookmarks, patrolDirections, railImageConfig, allRailImages, reduxAnnotations, dispatch],
    );

    useEffect(() => {
        if (layersDictRef.current) {
            Object.values(layersDictRef.current).forEach((layer) => {
                layer.setCsrfToken(csrfToken);
            });
        }
    }, [csrfToken]);

    useEffect(() => {
        if (map.current && initialised && leafletMap.current) {
            let xOffset = 0;

            if (railImageConfig.inspection_images) {
                if (match.params.markup && !navigatedToQueryParams && !_.isEmpty(patrolDirections)) {
                    navigateToMarkup(match.params.markup, queryParams.get("id"));
                    setNavigatedToQueryParams(true);
                } else if (currentLocation.elr) {
                    const index = findNearestRailImage(currentLocation.elr, currentLocation.chain, currentLocation.trackID, railImages);
                    leafletMap.current.setZoom(zoom);
                    panToIndex(index);
                }

                let imagesConfig = railImageConfig.inspection_images;
                if (_.findIndex(imagesConfig, { showInModal: true }) > -1) {
                    imagesConfig = imagesConfig.filter((imageConfig) => !imageConfig.showInModal);
                }

                let totalWidth = 0;
                imagesConfig.forEach(function (item, key) {
                    totalWidth += item.display_width;
                });

                imagesConfig.forEach(function (item, key) {
                    const source = item.source;
                    const name = item.name;
                    const displayWidth = item.display_width;
                    const srcWidth = item.src_width;
                    const imgWidth = item.img_width;
                    const srcX = item.src_x;
                    let offsetY;

                    if (sessionIsBackward) {
                        offsetY = item.offset_y_backward || 0;
                    } else {
                        offsetY = item.offset_y_forward || 0;
                    }

                    const newLayer = new DynamicLayer({
                        minNativeZoom: 4,
                        maxNativeZoom: 4,
                        tileSize: L.point(displayWidth, 1000),
                        maxZoom: 6,
                        noWrap: true,
                        edgeBufferTiles: 1,
                    });

                    let opacity = 1;
                    const visible = _.get(currentImageAdjustments, ["Primary", name, "visibility"], true);
                    if (!visible) {
                        opacity = 0.25;
                    }

                    let defaultBrightness = item.default_brightness || 0;
                    const brightness = _.get(currentImageAdjustments, ["Primary", name, "brightness"], defaultBrightness);
                    const contrast = _.get(currentImageAdjustments, ["Primary", name, "contrast"], 0);

                    newLayer.setOpacity(opacity);
                    newLayer.setBrightness(brightness);
                    newLayer.setContrast(contrast);

                    newLayer.setDeviceName(source);
                    newLayer.setDisplayWidth(displayWidth);
                    newLayer.setSrcWidth(srcWidth);
                    newLayer.setImgWidth(imgWidth);
                    newLayer.setSrcX(srcX);
                    newLayer.setConstantOffset(offsetY);
                    newLayer.setCroppingOffset(maxOffset);
                    newLayer.setReverse(sessionIsBackward);
                    newLayer.setCsrfToken(csrfToken);

                    if (!sessionIsBackward) {
                        newLayer.setXOffset(xOffset);
                        xOffset += displayWidth;
                    } else {
                        totalWidth -= displayWidth;
                        newLayer.setXOffset(totalWidth);
                    }

                    newLayer.setup(railImages);

                    layersDictRef.current[name] = newLayer;
                    newLayer.addTo(leafletMap.current);
                });

                const infoTiles = new InfoLayer({
                    tileSize: L.point(imageWidth * 5, 1000),
                    maxZoom: 6,
                    minNativeZoom: 4,
                    maxNativeZoom: 4,
                });

                infoTiles.on("tileunload", (event) => infoTiles.destroyTile(event.tile));
                infoTiles.addTo(leafletMap.current);

                infoTiles.setCroppingOffset(maxOffset);
                infoTiles.setup(railImages, store, totalWidth, arrowLeft);
                infoTiles.redraw();

                infoTilesRef.current = infoTiles;
            }
        }

        return () => {
            if (infoTilesRef.current) {
                infoTilesRef.current.remove();
                infoTilesRef.current = null;
            }

            Object.keys(layersDictRef.current).forEach(function (key) {
                layersDictRef.current[key].remove();
            });

            layersDictRef.current = {};
        };
    }, [
        arrowLeft,
        currentImageAdjustments,
        railImageConfig,
        railImages,
        store,
        panToIndex,
        historyOpen,
        initialised,
        sessionIsBackward,
        patrolDirections,
        maxOffset,
    ]);

    const onBookmarkClick = (bookmark) => {
        if (!bookmark.is_users && !isSupervisor) {
            return;
        }
        setUpdatingBookmarkID(bookmark.id);
        setLabelWindowOpen(true);
    };

    const closeBookmarkWindow = () => {
        setLabelWindowOpen(false);
        setUpdatingBookmarkID(null);
        setBookmarkCoords(null);
        setImageSource(null);
    };

    useEffect(() => {
        if (leafletMap.current && inspectionBookmarks && initialised) {
            inspectionBookmarks.forEach((bookmark) => {
                const coordinates = railInspectionConverter.sourceTimestampToCoordinates(bookmark.image_timestamp, bookmark.source, bookmark.image_coordinates);
                if (!coordinates) {
                    return;
                }
                let bookmarkMarker = new L.marker(coordinates, { icon: mapPinIcon })
                    .bindTooltip(bookmark.description)
                    .on("click", () => onBookmarkClick(bookmark));
                bookmarksLayerRef.current.addLayer(bookmarkMarker);
            });
            bookmarksLayerRef.current.addTo(leafletMap.current);
        }
        let bookmarksRef = bookmarksLayerRef;
        return () => {
            bookmarksRef.current.clearLayers();
        };
    }, [inspectionBookmarks, initialised, railInspectionConverter]);

    useEffect(() => {
        if (railImages) {
            if (infoTilesRef.current) {
                infoTilesRef.current.setup(railImages, store);
                infoTilesRef.current.redraw();
            }
        }
    }, [railImages, store]);

    const panToMarkup = useCallback(
        (markup) => {
            const annotationClicked = _.find(annotations, { id: markup.id });
            if (annotationClicked) {
                let offsetParam;
                if (sessionIsBackward) {
                    offsetParam = "offset_y_backward";
                } else {
                    offsetParam = "offset_y_forward";
                }
                const annotationOffset = _.get(railImageConfig, ["inspection_images", annotationClicked.image_source, offsetParam], 0);

                let imageIndex = _.findIndex(railImages, (img) => img.timestamp.toString() === annotationClicked.image_timestamp.toString());
                const offsettedIndex = imageIndex + Math.round(annotationOffset);
                const indexToUse = Math.min(Math.max(offsettedIndex, 0), railImages.length);

                panToIndex(indexToUse);
                dispatch(setCurrentSchemaMarkup(null, null));
            }
        },
        [annotations, dispatch, panToIndex, railImageConfig, railImages, sessionIsBackward],
    );

    useEffect(() => {
        if (selectedMarkup && railImages) {
            panToMarkup(selectedMarkup);
        }
    }, [selectedMarkup, railImages, panToMarkup]);

    const savePatrolProgress = useCallback(
        _.throttle((progress) => {
            dispatch(updatePatrolProgress(patrolID, progress, null, null));
        }, 5000),
        [patrolID],
    );

    const updateProgressMap = useCallback(
        (elr, trackID, firstChain, secondChain) => {
            if (!inspectingPatrol || Math.abs(firstChain - secondChain) > 1 || isReview || patrolStatus > 1) {
                return;
            }

            let startChain = Math.min(firstChain, secondChain);
            let endChain = Math.max(firstChain, secondChain);

            startChain -= ONE_METRE_IN_CHAINS;
            endChain += ONE_METRE_IN_CHAINS;

            let viewedProgressCopy = _.cloneDeep(store.getState().schemaInterface.covered);

            if (!viewedProgressCopy[elr]) {
                viewedProgressCopy[elr] = {
                    [trackID]: [
                        {
                            start: startChain,
                            end: endChain,
                        },
                    ],
                };
            } else {
                if (viewedProgressCopy[elr][trackID]) {
                    let overlappingIndexes = [];
                    let movingUp = true;

                    viewedProgressCopy[elr][trackID].forEach((lineCoverage, idx) => {
                        if (startChain >= lineCoverage.start && startChain <= lineCoverage.end) {
                            overlappingIndexes.push(idx);
                        }
                        if (endChain >= lineCoverage.start && endChain <= lineCoverage.end) {
                            overlappingIndexes.push(idx);
                            movingUp = false;
                        }
                    });

                    if (overlappingIndexes.length === 1) {
                        overlappingIndexes.forEach((overlappingIndex) => {
                            if (movingUp) {
                                if (endChain > viewedProgressCopy[elr][trackID][overlappingIndex].end) {
                                    viewedProgressCopy[elr][trackID][overlappingIndex].end = endChain;
                                }
                            } else {
                                if (startChain < viewedProgressCopy[elr][trackID][overlappingIndex].start) {
                                    viewedProgressCopy[elr][trackID][overlappingIndex].start = startChain;
                                }
                            }
                        });
                    } else if (overlappingIndexes.length > 1) {
                        let startChain;
                        let endChain;
                        overlappingIndexes.forEach((index) => {
                            const coverage = viewedProgressCopy[elr][trackID][index];
                            if (!startChain || coverage.start < startChain) {
                                startChain = coverage.start;
                            }
                            if (!endChain || coverage.end > endChain) {
                                endChain = coverage.end;
                            }
                        });

                        viewedProgressCopy[elr][trackID] = viewedProgressCopy[elr][trackID].filter((_, index) => {
                            return overlappingIndexes.indexOf(index) === -1;
                        });
                        viewedProgressCopy[elr][trackID].push({
                            start: startChain,
                            end: endChain,
                        });
                    } else {
                        viewedProgressCopy[elr][trackID].push({
                            start: startChain,
                            end: endChain,
                        });
                    }
                } else {
                    viewedProgressCopy[elr][trackID] = [
                        {
                            start: startChain,
                            end: endChain,
                        },
                    ];
                }
            }

            dispatch(patrolCovered(viewedProgressCopy));
            savePatrolProgress(viewedProgressCopy);
        },
        [store, dispatch, inspectingPatrol, savePatrolProgress, patrolStatus, isReview],
    );

    const updateViewedProgress = useCallback(() => {
        if (!leafletMap.current) {
            return;
        }
        const bounds = leafletMap.current.getBounds();

        const lowerIndex = Math.max(Math.floor(bounds._southWest.lat / imageHeight), 0);
        const lowerImage = railImages[lowerIndex];
        if (!lowerImage) {
            return;
        }
        const lowerELR = lowerImage.mwv.elr;
        const lowerChain = chainageFromImage(lowerImage);
        const lowerTrackID = lowerImage.mwv.trid;

        const upperIndex = Math.min(Math.ceil(bounds._northEast.lat / imageHeight), railImages.length - 1);
        // const upperIndex = (Math.floor(leafletMap.current.getCenter().lat / imageHeight) + 1);
        const upperImage = railImages[upperIndex];
        if (!upperImage) {
            return;
        }
        const upperELR = upperImage.mwv.elr;
        const upperChain = chainageFromImage(upperImage);
        const upperTrackID = upperImage.mwv.trid;

        if (lowerELR === upperELR) {
            if (lowerTrackID === upperTrackID) {
                updateProgressMap(lowerELR, lowerTrackID, lowerChain, upperChain);
            } else {
                let switchingIndex = -1;
                let viewportImages = railImages.slice(lowerIndex - 1, upperIndex + 1);
                let lastTrid = lowerTrackID;
                viewportImages.forEach((image, idx) => {
                    if (image.mwv.trid !== lastTrid && switchingIndex < 0) {
                        switchingIndex = idx;
                    }
                });
                if (switchingIndex > -1) {
                    const lastLowerImage = viewportImages[switchingIndex < 1 ? switchingIndex : switchingIndex - 1];
                    const lastLowerChain = chainageFromImage(lastLowerImage);
                    updateProgressMap(lowerELR, lowerTrackID, lowerChain, lastLowerChain);

                    const firstUpperImage = viewportImages[switchingIndex];
                    const firstUpperChain = chainageFromImage(firstUpperImage);
                    updateProgressMap(upperELR, upperTrackID, firstUpperChain, upperChain);
                }
            }
        } else {
            let switchingIndex = -1;
            let viewportImages = railImages.slice(lowerIndex - 1, upperIndex + 1);
            let lastELR = lowerELR;
            viewportImages.forEach((image, idx) => {
                if (image.mwv.elr !== lastELR && switchingIndex < 0) {
                    switchingIndex = idx;
                }
            });

            if (switchingIndex > -1) {
                const lastLowerImage = viewportImages[switchingIndex < 1 ? switchingIndex : switchingIndex - 1];
                const lastLowerChain = chainageFromImage(lastLowerImage);
                updateProgressMap(lowerELR, lowerTrackID, lowerChain, lastLowerChain);

                const firstUpperImage = viewportImages[switchingIndex];
                const firstUpperChain = chainageFromImage(firstUpperImage);
                updateProgressMap(upperELR, upperTrackID, firstUpperChain, upperChain);
            }
        }
    }, [railImages, updateProgressMap]);

    useEffect(() => {
        updateViewedProgress();
    }, [updateViewedProgress, currentLocation]);

    const updateCurrentPosition = useCallback(
        (e, force = false) => {
            if (mapDragging.current || force) {
                const { lat } = leafletMap.current.getCenter();

                const latPos = lat / imageHeight;

                const centerIndex = Math.floor(latPos) + 1;
                if (centerIndex < 0 || centerIndex >= railImages.length) {
                    return;
                }

                const closestRailImage = railImages[centerIndex];

                dispatch(setSelectedRailInspectionImage(centerIndex, closestRailImage, false, latPos));
                const locationData = closestRailImage.mwv;

                const totalYardage = parseFloat(locationData.yard) + parseInt(locationData.mile) * 1760;

                const totalChain = totalYardage / 22;
                const elr = locationData.elr;
                const trackID = locationData.trid;
                const newLocationObj = {
                    elr,
                    chain: totalChain,
                    trackID,
                };

                updateViewedProgress();
                dispatch(setPatrolLocation(newLocationObj, closestRailImage.timestamp));
            }

            if (!isMouseDown.current && mapDragging.current) {
                setTimeout(() => {
                    mapDragging.current = false;
                }, 200);
            }
        },
        [railImages, dispatch, updateViewedProgress],
    );

    const mouseClick = useCallback(
        (e) => {
            if (bookmarkModeActive) {
                let lat = e.latlng.lat * 16;
                let lng = e.latlng.lng * 16;

                const imageData = railInspectionConverter.clickToImageData(lat, lng);
                if (!imageData) {
                    return;
                }
                const { imageClicked, source, coordinates } = imageData;
                const imageTimestampClicked = imageClicked.timestamp;

                setImageTimestampClicked(imageTimestampClicked);
                setImageSource(source);
                setBookmarkCoords(coordinates);
                setLabelWindowOpen(true);
                toggleBookmarkMode();
            }
        },
        [bookmarkModeActive, toggleBookmarkMode, railInspectionConverter],
    );

    const mouseDown = useCallback(() => {
        isMouseDown.current = true;
    }, []);

    const mouseUp = useCallback(() => {
        isMouseDown.current = false;
    }, []);

    const onZoom = useCallback((e) => {
        const zoom = e.target._zoom;
        infoTilesRef.current.setMapZoom(zoom);
        infoTilesRef.current.redraw();
        setZoom(zoom);
    }, []);

    const moveStart = useCallback(() => {
        if (isMouseDown.current) {
            mapDragging.current = true;
        }
    }, []);

    const onKeyDown = useCallback(
        (e) => {
            if (patrolReportVisible || labelWindowOpen || annotationWindowOpen || isPlanner || reportProblemOpen || diagramReportOpen) {
                return;
            }

            const keyPressed = e.key;
            e.stopPropagation();
            if (keyPressed === "ArrowUp") {
                if (!scrollingRef.current) {
                    leafletMap.current.panBy([0, -32]);
                    scrollingRef.current = setInterval(() => {
                        leafletMap.current.panBy([0, -32]);
                        scrollingCount.current += 1;
                        if (scrollingCount.current % 15 === 0) {
                            updateCurrentPosition(null, true);
                        }
                    }, 100);
                }
            }

            if (keyPressed === "ArrowDown") {
                if (!scrollingRef.current) {
                    leafletMap.current.panBy([0, 32]);
                    scrollingRef.current = setInterval(() => {
                        leafletMap.current.panBy([0, 32]);
                        scrollingCount.current += 1;
                        if (scrollingCount.current % 15 === 0) {
                            updateCurrentPosition(null, true);
                        }
                    }, 100);
                }
            }

            if (keyPressed === "b" && !patrolCompleted && patrolStatus) {
                toggleBookmarkMode();
            } else if (keyPressed === "a" && !patrolCompleted && patrolStatus) {
                setAnnotatingActive(!annotatingActive);
            }
        },
        [
            updateCurrentPosition,
            toggleBookmarkMode,
            annotatingActive,
            setAnnotatingActive,
            patrolReportVisible,
            annotationWindowOpen,
            labelWindowOpen,
            patrolCompleted,
            isPlanner,
            reportProblemOpen,
            diagramReportOpen,
            patrolStatus,
        ],
    );

    const onKeyUp = (e) => {
        const keyUp = e.key;
        if (keyUp === "ArrowUp" || keyUp === "ArrowDown") {
            if (scrollingRef.current) {
                mapDragging.current = true;
                updateCurrentPosition(null, true);
                clearInterval(scrollingRef.current);
                scrollingRef.current = null;
                scrollingCount.current += 0;
            }
        }
    };

    const date = useMemo(() => {
        if (sessions && sessions.length && !_.isNil(selectedSession) && sessions[selectedSession]) {
            const timestamp = sessions[selectedSession][0].timestamp;
            const date = new Date(timestamp);
            const niceTime = convertToTimezone(date, userConfig.convert_to_utc);

            return <span>{niceTime}</span>;
        }
    }, [selectedSession, sessions, userConfig.convert_to_utc]);

    const resetEditingID = useCallback(() => {
        setUpdatingAnnotationID(null);
    }, []);

    const cancelAnnotationCreation = useCallback(() => {
        let newAnnotations = _.cloneDeep(annotations);
        newAnnotations = newAnnotations.filter((ann) => ann.id !== -2);
        setAnnotations(newAnnotations);
    }, [annotations]);

    useEffect(() => {
        if (initialised) {
            if (leafletMap.current) {
                leafletMap.current.on("movestart", moveStart);
                leafletMap.current.on("moveend", updateCurrentPosition);
                leafletMap.current.on("mousedown", mouseDown);
                leafletMap.current.on("zoom", onZoom);
                leafletMap.current.on("click", mouseClick);
                leafletMap.current.on("draw:created", onAnnotationCreated);
            }
            document.addEventListener("mouseup", mouseUp);
            document.addEventListener("keydown", onKeyDown);
            document.addEventListener("keyup", onKeyUp);

            return () => {
                if (leafletMap.current) {
                    leafletMap.current.off("moveend", updateCurrentPosition); //cleans up by removing the old version of the function bound to leafletMap before binding the new one
                    leafletMap.current.off("mousedown", mouseDown);
                    leafletMap.current.off("zoom", onZoom);
                    leafletMap.current.off("movestart", moveStart);
                    leafletMap.current.off("click", mouseClick);
                    leafletMap.current.off("draw:created", onAnnotationCreated);
                }
                document.removeEventListener("mouseup", mouseUp);
                document.removeEventListener("keyup", onKeyUp);
                document.removeEventListener("keydown", onKeyDown);
            };
        }
    }, [initialised, mouseDown, onZoom, moveStart, mouseClick, onKeyDown, mouseUp, onAnnotationCreated, updateCurrentPosition]);

    useEffect(() => {
        if (initialised && leafletMap.current) {
            renderAnnotationLayer();

            return () => {
                if (drawingLayerRef && drawingLayerRef.current) {
                    drawingLayerRef.current.remove();
                }
            };
        }
    }, [initialised, renderAnnotationLayer]);

    const navigate = useCallback(
        (e, direction) => {
            const currentPosition = leafletMap.current.getCenter();
            const bounds = leafletMap.current.getBounds();

            const bottomLat = bounds._southWest.lat;
            const topLat = bounds._northEast.lat;
            const currentLat = currentPosition.lat;
            const latDiff = topLat - currentLat;

            let sessionLength = 0;
            if (sessions[selectedSession]) {
                sessionLength = Math.max(5, sessions[selectedSession].length);
            }

            mapDragging.current = true;
            if (direction === "forward") {
                let newPos = topLat + latDiff - imageHeight;
                if (newPos < currentLat) {
                    newPos = topLat + latDiff;
                }
                leafletMap.current.panTo([Math.min(newPos, sessionLength * imageHeight), leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            } else {
                let newPos = bottomLat - latDiff + imageHeight;
                if (newPos > currentLat) {
                    newPos = bottomLat - latDiff;
                }
                leafletMap.current.panTo([Math.max(newPos, 0), leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            }
        },
        [selectedSession, sessions],
    );

    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setWindowDimensions({ width: contentRect.bounds.width, height: contentRect.bounds.height, bottom: contentRect.bounds.bottom });
            }}>
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    className={"SchemaScroller__Container" + (bookmarkModeActive ? " no-cursor" : "")}>
                    <div className="SchemaScroller__Info">
                        <div className="SchemaScroller__Info__Date">{date}</div>
                        <KeyboardShortcuts controls={["annotate", "bookmark", "up", "down", "zoomOut", "zoomIn"]} />
                    </div>

                    {initialised && (
                        <div className="MapNavigationButtons">
                            <Button
                                className="Button"
                                onDoubleClick={(e) => e.stopPropagation()}
                                onClick={(e) => navigate(e, "forward")}>
                                <p className="Text">Navigate Up</p>
                            </Button>
                            <Button
                                className="Button"
                                onClick={(e) => navigate(e, "backward")}>
                                <p className="Text">Navigate Down</p>
                            </Button>
                        </div>
                    )}

                    <LabelCreateWindow
                        coords={bookmarkCoords}
                        imageTimestampClicked={imageTimestampClicked}
                        imageSource={imageSource}
                        labelWindowOpen={labelWindowOpen}
                        closeBookmarkWindow={closeBookmarkWindow}
                        updatingID={updatingBookmarkID}
                        disabled={!patrolEditable}
                        setUpdatingID={setUpdatingBookmarkID}
                        patrolID={patrolID}
                        isUsersPatrol={patrolPlan.is_users_patrol}
                    />

                    <AnnotationCreateWindow
                        visible={annotationWindowOpen}
                        annotationData={annotationData}
                        updatingID={updatingAnnotationID}
                        resetEditingID={resetEditingID}
                        cancelAnnotationCreation={cancelAnnotationCreation}
                        closeModal={() => setAnnotationWindowOpen(false)}
                        disabled={!patrolEditable}
                        patrol={patrolID}
                        isUsersPatrol={patrolPlan.is_users_patrol}
                    />

                    <div
                        ref={map}
                        id="mapContainer"
                        className={"map"}
                        style={{
                            flex: "1",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            cursor: bookmarkModeActive ? "none" : null,
                        }}>
                        {!initialised && (
                            <div className="LoadingContainer">
                                <OBCSpinner colorScheme={"mono"} />
                                {fetchingELRs && <p className="LoadingImagesText">{patrolImagesLoaded} images received...</p>}
                            </div>
                        )}
                        {initialised && !patrolPlan.image_file_link && !railImages.length && (
                            <div>
                                <p className="NoImagesText">Patrol images are currently being prepared</p>
                            </div>
                        )}
                        {!railImages.length && initialised && patrolPlan.image_file_link && (
                            <div>
                                <p className="NoImagesText">No images for current position</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Measure>
    );
};

export default withRouter(SchemaScroller);
