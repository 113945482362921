import { Select, Tooltip, Switch, Slider, Badge, Popover } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import _ from "lodash";
import {
    selectIssue,
    selectAsset,
    selectObservation,
    clearRequestedIssue,
    setIssueSearchQueryFilter,
    getIssues,
    getIssueObservations,
    setIssueDateFilter,
    setIssueStatusFilter,
    setIssuePriorityFilter,
    setClassSearchValue,
    setIssueGroupFilter,
    getIssueGroups,
    generateThermalReport,
    generateBalanceWeightReport,
    setFilteredIsuesData,
    generateIssueAssetReport,
} from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { useDispatch, useSelector } from "react-redux";
import IssueItem from "./IssueItem";
import DayPicker from "react-day-picker";
import moment from "moment";
import { filterIssues } from "components/Issues/issueUtils";
import OBCButton from "../OBC/OBCButton";
import OBCSearchInput from "../OBC/OBCSearchInput";
import OBCToggleButton from "components/OBC/OBCToggleButton";
import { statusRanker, priorityRanker, issueTimeRanker, issueMatchCountRanker } from "../util/PlaylistUtils";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { faSortAmountDown, faFilter, faMapMarkerExclamation, faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import "../../style/issuesTab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ObservationModal from "./ObservationModal";
import { CalendarOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const issuesSelector = (state) => state.issues.data;
const dateFiltersSelector = (state) => state.issues.filters.date;
const statusFilterSelector = (state) => state.issues.filters.status;
const priorityFilterSelector = (state) => state.issues.filters.priority;
const classSearchValueSelector = (state) => state.issues.filters.classSearchValue;
const requestedIssueSelector = (state) => state.issues.requested;
const searchQueryFilterSelector = (state) => state.issues.filters.searchQuery;
const superuserSelector = (state) => state.userDetails.userConfig.super_admin;
const selectedIssueSelector = (state) => state.issues.selected;
const issueGroupsSelector = (state) => state.issues.groups;
const issueGroupFilterSelector = (state) => state.issues.filters.group;
const issueObservationsSelector = (state) => state.issues.observations;
const filteredIssuesSelector = (state) => state.issues.filteredIssues;

const DEFAULT_SORT_VALUE = "priority_status_time";
const GUTTER_SIZE = 30;
const ISSUE_ITEM_HEIGHT_CLOSED = 250;

const CLASS_SPECIFIC_SORT_DATA = {
    "Thermal Hotspot": [
        {
            value: "last_temperature",
            label: "Last Temperature",
            short_title: "Last Temp",
            description: "most recent temperature",
        },
        {
            value: "highest_temperature",
            label: "Max Temperature",
            short_title: "Max Temp",
            description: "highest measured temperature",
        },
    ],
    "Jointed Rail End": [
        {
            value: "last_gap_mm",
            label: "Last Gap Size (mm)",
            short_title: "Last Gap",
            description: "most recent gap size",
        },
        {
            value: "max_gap_mm",
            label: "Max Gap Size (mm)",
            short_title: "Max Gap",
            description: "maximum gap size",
        },
        {
            value: "last_height_diff_px",
            label: "Last Height Diff (px)",
            short_title: "Last Height Diff",
            description: "most recent height difference",
        },
        {
            value: "max_height_diff_px",
            label: "Max Height Diff (px)",
            short_title: "Max Diff",
            description: "maximum height difference",
        },
    ],
    Ramp: [
        {
            value: "last_gap_mm",
            label: "Last Gap Size (mm)",
            short_title: "Last Gap",
            description: "most recent gap size",
        },
        {
            value: "min_gap_mm",
            label: "Min Gap Size (mm)",
            short_title: "Min Gap",
            description: "smallest gap size",
        },
    ],
    Tensioner: [
        {
            value: "severity",
            label: "Green Zone Overflow (cm)",
            short_title: "Overflow",
            description: "green zone overflow",
        },
        {
            value: "bottom_out_temperature",
            label: "Bottom Out Temperature (degrees)",
            short_title: "Bottom Out Temp",
            description: "bottom out temperature ascending",
            default: true,
        },
    ],
};

const PRIORITY_DATA = [
    { value: "all", label: "All" },
    { value: 3, label: "High" },
    { value: 2, label: "Medium" },
    { value: 1, label: "Low" },
    { value: null, label: "Not Set" },
];

const STATUS_DATA = [
    { value: "all_open", label: "All Open", description: "open" },
    {
        value: "new_and_updated",
        label: "New & Updated",
        description: "new & updated",
    },
    {
        value: "acknowledged",
        label: "Acknowledged",
        description: "acknowledged",
    },
    {
        value: "closed",
        label: "Closed",
        description: "closed",
    },
];

const GENERIC_SORT_DATA = [
    {
        value: "status_priority_time",
        label: "Newness",
        short_title: "Newness",
        description: "newness then priority",
    },
    {
        value: "priority_status_time",
        label: "Priority",
        short_title: "Priority",
        description: "highest priority then newness",
    },
    {
        value: "last_observed",
        label: "Most Recently Observed",
        short_title: "Most Recent",
        description: "most recently observed",
    },
    {
        value: "match_count",
        label: "Most Observations",
        short_title: "# Observations",
        description: "number of observations",
    },
];

const DEFAULT_CLASS_SPECIFIC_FILTERS = {
    "Thermal Hotspot": {
        temp: {
            values: [0, 80],
            bounds: [0, 80],
            unit: "°C",
            label: "Thermal temp range",
            type: "range",
        },
    },
    "Jointed Rail End": {
        distance: {
            values: [0, 100],
            bounds: [0, 100],
            unit: "mm",
            label: "Distance between rail ends",
            type: "range",
        },
    },
    Ramp: {
        distance: {
            values: [0, 500],
            bounds: [0, 100],
            unit: "mm",
            label: "Distance between ramp gaps",
            type: "range",
        },
    },
    Tensioner: {
        distance: {
            values: [0, 50],
            bounds: [0, 50],
            unit: "cm",
            label: "Green zone overflow",
            type: "range",
        },
        bottom_out_temp: {
            values: [0, 50],
            bounds: [0, 50],
            unit: "°C",
            label: "Bottom out temperature",
            type: "range",
        },
    },
    Fishplate: {
        health: {
            label: "Fishplate health",
            type: "select",
            options: ["all", "healthy", "unhealthy"],
            values: "all",
        },
    },
};

const classSpecificRangesReducer = (state, action) => {
    if (action.type === "update_class_range") {
        return {
            ...state,
            [action.class]: {
                ...state[action.class],
                [action.filter]: {
                    ...state[action.class][action.filter],
                    values: action.range,
                },
            },
        };
    }

    if (action.type === "update_class_range_defaults") {
        return {
            ...state,
            [action.class]: {
                ...state[action.class],
                [action.filter]: {
                    ...state[action.class][action.filter],
                    values: action.range,
                    bounds: action.range,
                },
            },
        };
    }

    return state;
};

export const useWindowResize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};

const IssuesTab = () => {
    const dispatch = useDispatch();

    const [sortValue, setSortValue] = useState(DEFAULT_SORT_VALUE);
    const [initialised, setInitialised] = useState(false);
    const [classesInitialised, setClassesInitialised] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [classOptions, setClassOptions] = useState([]);
    const [cacheCounter, setCacheCounter] = useState(0);
    const [fitlersOpen, setFilterOpen] = useState(false);
    const [issuesResultCount, setIssuesResultCount] = useState(0);

    const [classSpecificRanges, dispatchClassRanges] = useReducer(classSpecificRangesReducer, DEFAULT_CLASS_SPECIFIC_FILTERS);

    const dateFilter = useSelector(dateFiltersSelector);
    const issues = useSelector(issuesSelector);
    const statusFilter = useSelector(statusFilterSelector);
    const priorityFilter = useSelector(priorityFilterSelector);
    const issueGroupFilter = useSelector(issueGroupFilterSelector);
    const reduxClassRangeValues = useSelector(classSearchValueSelector);
    const requestedIssue = useSelector(requestedIssueSelector);
    const searchQueryFilter = useSelector(searchQueryFilterSelector);
    const superuser = useSelector(superuserSelector);
    const selectedIssue = useSelector(selectedIssueSelector);
    const issueGroups = useSelector(issueGroupsSelector);
    const issueObservations = useSelector(issueObservationsSelector);
    const orderedIssues = useSelector(filteredIssuesSelector);

    const [autoAck, setAutoAck] = useState(false);
    const [reloadingData, setReloadingData] = useState(false);
    const [currentlyVisibleTooltip, setCurrentlyVisibleTooltip] = useState(null);
    const [isReportPopoverVisible, setIsReportPopoverVisible] = useState(false);

    const listRef = useRef();
    const sizeMap = useRef({});

    const [windowWidth] = useWindowResize();

    const dateFrom = new Date(dateFilter.from * 1000);
    const dateTo = new Date(dateFilter.to * 1000);
    const modifiers = { start: dateFrom, end: dateTo };

    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);

    const breakIssueCache = () => {
        console.log("debug break issue cache...");
        setCacheCounter((count) => count + 1);
    };

    const showClosed = useMemo(() => {
        return statusFilter === "closed";
    }, [statusFilter]);

    const selectedClassValue = useMemo(() => {
        return _.get(issueGroups, [issueGroupFilter, "class"], "");
    }, [issueGroups, issueGroupFilter]);

    useEffect(() => {
        if (!_.isEmpty(issueGroups) && issueGroupFilter) {
            if (!issueGroups[issueGroupFilter]) {
                const firstIssueGroup = Object.keys(issueGroups)[0];
                if (firstIssueGroup) {
                    dispatch(setIssueGroupFilter(parseInt(firstIssueGroup)));
                }
            }
        }
    }, [issueGroups, issueGroupFilter, dispatch]);

    useEffect(() => {
        dispatch(getIssueGroups());
    }, [dispatch]);

    useEffect(() => {
        if (issueGroupFilter) {
            setInitialised(false);
            dispatch(
                getIssues(true, issueGroupFilter, () => {
                    setInitialised(true);
                    breakIssueCache();
                    if (requestedIssue.issue) {
                        dispatch(selectIssue(parseInt(requestedIssue.issue)));
                        dispatch(getIssueObservations(requestedIssue.issue));
                        dispatch(setIssuePriorityFilter("all"));
                        if (requestedIssue.observation) {
                            dispatch(selectObservation(parseInt(requestedIssue.observation)));
                        }
                        dispatch(clearRequestedIssue());
                    }
                }),
            );
            // reset classSearchValue and assign default values for a currently selected class
            resetClassSearchValue(selectedClassValue);
        }
    }, [dispatch, requestedIssue.issue, requestedIssue.observation, requestedIssue.class, issueGroupFilter]);

    const renderDatePicker = () => {
        return (
            <>
                <DayPicker
                    className="Selectable"
                    selectedDays={[dateFrom, { from: dateFrom, to: dateTo }]}
                    modifiers={modifiers}
                    onDayClick={handleDayClick}
                />
                <div className="DatePickerButtons">
                    <OBCButton
                        className="ButtonReset"
                        disabled={dateFilter.from ? false : true}
                        onClick={() => dispatch(setIssueDateFilter({ from: null, to: null }))}>
                        Reset
                    </OBCButton>
                    <OBCButton
                        className="ButtonOk"
                        variant="secondary"
                        onClick={() => setDatePickerOpen(false)}>
                        Close
                    </OBCButton>
                </div>
            </>
        );
    };

    const onSearchQueryChanged = (value) => {
        dispatch(setIssueSearchQueryFilter(value));
    };

    const onSortChange = (value) => {
        setSortValue(value);
        breakIssueCache();
    };

    const onStatusFilterChange = (value) => {
        setReloadingData(true);
        setTimeout(() => {
            dispatch(setIssueStatusFilter(value));
            breakIssueCache();
        }, 300);
    };

    const onPriorityFilterChange = (value) => {
        setReloadingData(true);
        setTimeout(() => {
            dispatch(setIssuePriorityFilter(value));
            breakIssueCache();
        }, 300);
    };

    useEffect(() => {
        if (issues && requestedIssue.issue) {
            dispatch(getIssueObservations(requestedIssue.issue)).then((observations) => {
                if (observations.length) {
                    const issueGroup = observations[0].issue_group;
                    dispatch(setIssueGroupFilter(issueGroup));
                }
            });
        }
    }, [issues, dispatch, requestedIssue.issue]);

    useEffect(() => {
        // get the unique set of issue classes in the unfiltered issue set, and use
        // these for the filter dropdown options
        const classes = _.uniq(issues.map((i) => _.get(i, "class", "")))
            .filter((issue_class) => !!issue_class)
            .sort();
        if (!_.isEqual(classes, classOptions)) {
            setClassOptions(classes);
        }
    }, [issues, classOptions]);

    useEffect(() => {
        if (issues) {
            let biggestPotGapValue = 500;
            let biggestTensionerValue = 50;
            let hottestThermalValue = 80;
            let highestTensionerBottomValue = 50;

            issues.forEach((issue) => {
                if (issue.class === "Ramp") {
                    const maxGapValue = _.get(issue, ["summary_data", "fields", "max_gap_mm"], 0);
                    if (maxGapValue > biggestPotGapValue) {
                        biggestPotGapValue = maxGapValue;
                    }
                    return;
                }

                if (issue.class === "Tensioner") {
                    const maxGapValue = _.get(issue, ["summary_data", "fields", "severity"], 0);
                    if (maxGapValue > biggestTensionerValue) {
                        biggestTensionerValue = maxGapValue;
                    }
                    return;
                }

                if (issue.class === "Thermal Hotspot") {
                    const maxTempValue = _.get(issue, ["summary_data", "fields", "highest_temperature"], 0);
                    if (maxTempValue > hottestThermalValue) {
                        hottestThermalValue = maxTempValue;
                    }
                    return;
                }
            });
            const tensionerRange = [0, biggestTensionerValue];
            const rampRange = [0, biggestPotGapValue];
            const hotspotRange = [0, hottestThermalValue];
            const tensionerBottomRange = [0, highestTensionerBottomValue];

            dispatchClassRanges({ type: "update_class_range_defaults", class: "Ramp", range: rampRange, filter: "distance" });
            dispatchClassRanges({ type: "update_class_range_defaults", class: "Tensioner", range: tensionerBottomRange, filter: "bottom_out_temp" });
            dispatchClassRanges({ type: "update_class_range_defaults", class: "Tensioner", range: tensionerRange, filter: "distance" });
            dispatchClassRanges({ type: "update_class_range_defaults", class: "Thermal Hotspot", range: hotspotRange, filter: "temp" });

            resetClassSearchValue(selectedClassValue);
        }
    }, [dispatch, issues, selectedClassValue]);

    useEffect(() => {
        const groupClassName = _.get(issueGroups, [issueGroupFilter, "class"], null);

        if (CLASS_SPECIFIC_SORT_DATA.hasOwnProperty(groupClassName)) {
            const defaultOverride = _.find(CLASS_SPECIFIC_SORT_DATA[groupClassName], { default: true });
            if (defaultOverride) {
                setSortValue(defaultOverride.value);
            } else {
                setSortValue(CLASS_SPECIFIC_SORT_DATA[groupClassName][0].value);
            }
            breakIssueCache();
        }
    }, [issueGroupFilter, issueGroups]);

    useEffect(() => {
        if (!_.isEmpty(issueGroups) && !issueGroupFilter && !classesInitialised) {
            const firstIssueGroup = Object.values(issueGroups)[0];
            if (firstIssueGroup.id) {
                dispatch(setIssueGroupFilter(parseInt(firstIssueGroup.id)));
                setClassesInitialised(true);

                // reset classSearchValue and assign default values for a currently selected class
                resetClassSearchValue(firstIssueGroup.class);
            }
        }
    }, [classOptions, dispatch, requestedIssue.issue, classesInitialised, issueGroups, issueGroupFilter]);

    // reset classSearchValue and assign default values for a currently selected class
    const resetClassSearchValue = (currentClass) => {
        if (classSpecificRanges[currentClass]) {
            let newClass = {
                [currentClass]: classSpecificRanges[currentClass],
            };
            dispatch(setClassSearchValue(newClass));
        } else {
            dispatch(setClassSearchValue(null));
        }
    };

    const cachedIssues = useMemo(() => {
        return issues;
    }, [cacheCounter]);

    const issueCountByStatus = useMemo(() => {
        let default_counts = { new_and_updated: 0, acknowledged: 0, closed: 0 };
        let counts = _.countBy(cachedIssues, (issue) => {
            if (issue.state === 0) {
                return "new_and_updated";
            }
            if (issue.state === 1) {
                if (issue.state_change_ts * 1000 < issue.match_ts) {
                    return "new_and_updated";
                } else {
                    return "acknowledged";
                }
            }
            if (issue.state === 2) {
                return "closed";
            }
        });

        counts = { ...default_counts, ...counts };
        console.log("ISSUE COUNTS:", counts);
        counts.all_open = counts.new_and_updated + counts.acknowledged;
        return counts;
    }, [cachedIssues]);

    const filteredCachedIssues = useMemo(() => {
        const rangeFilters = {};
        if (reduxClassRangeValues && reduxClassRangeValues[selectedClassValue]) {
            rangeFilters[selectedClassValue] = reduxClassRangeValues[selectedClassValue];
        }
        const filtered = filterIssues(
            dateFilter,
            statusFilter,
            priorityFilter,
            issueGroupFilter,
            cachedIssues,
            searchQueryFilter,
            { classSearchValue: rangeFilters },
            showClosed,
        );
        setReloadingData(false);
        return filtered;
    }, [reduxClassRangeValues, selectedClassValue, dateFilter, statusFilter, priorityFilter, issueGroupFilter, cachedIssues, searchQueryFilter, showClosed]);

    const orderedCachedIssues = useMemo(() => {
        // sort based on cached values, but display updated values
        if (sortValue === "status_priority_time") {
            return _.orderBy(filteredCachedIssues, [statusRanker, priorityRanker, issueTimeRanker], ["asc", "desc", "desc"]);
        } else if (sortValue === "match_count") {
            return _.orderBy(filteredCachedIssues, [issueMatchCountRanker, statusRanker, priorityRanker, issueTimeRanker], ["desc", "asc", "desc", "desc"]);
        } else if (sortValue === "priority_status_time") {
            return _.orderBy(filteredCachedIssues, [priorityRanker, statusRanker, issueTimeRanker], ["desc", "asc", "desc"]);
        } else if (sortValue === "last_observed") {
            return _.orderBy(filteredCachedIssues, [issueTimeRanker], "desc");
        } else if (selectedClassValue === "Thermal Hotspot" && sortValue === "last_temperature") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "last_temperature"])], "desc");
        } else if (selectedClassValue === "Thermal Hotspot" && sortValue === "highest_temperature") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "highest_temperature"])], "desc");
        } else if (selectedClassValue === "Jointed Rail End" && sortValue === "last_gap_mm") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "last_gap_mm"])], "desc");
        } else if (selectedClassValue === "Jointed Rail End" && sortValue === "max_gap_mm") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "max_gap_mm"])], "desc");
        } else if (selectedClassValue === "Jointed Rail End" && sortValue === "last_height_diff_px") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "last_height_diff_px"])], "desc");
        } else if (selectedClassValue === "Jointed Rail End" && sortValue === "max_height_diff_px") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "max_height_diff_px"])], "desc");
        } else if (selectedClassValue === "Ramp" && sortValue === "last_gap_mm") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "last_gap_mm"])], "asc");
        } else if (selectedClassValue === "Ramp" && sortValue === "min_gap_mm") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "min_gap_mm"])], "asc");
        } else if (selectedClassValue === "Tensioner" && sortValue === "severity") {
            return _.orderBy(
                filteredCachedIssues,
                [
                    (issue) => {
                        let severity = _.get(issue, ["summary_data", "fields", "severity"], 0);
                        return severity ? severity : 0;
                    },
                ],
                "desc",
            );
        } else if (selectedClassValue === "Tensioner" && sortValue === "bottom_out_temperature") {
            return _.orderBy(filteredCachedIssues, [(issue) => _.get(issue, ["summary_data", "fields", "bottom_out_temperature"], 999)], "asc");
        } else {
            // base case to prevent crash in case anything unexpected happens
            return _.orderBy(filteredCachedIssues, [issueTimeRanker], "desc");
        }
    }, [filteredCachedIssues, sortValue, selectedClassValue]);

    useEffect(() => {
        // sort and filter determined by the cached list - now get the updated issue data
        let ordered = orderedCachedIssues
            .map((cachedIssue) => {
                // probably a much more efficient way of doing this
                return _.find(issues, { id: cachedIssue.id });
            })
            .filter((item) => !!item); // make sure just in case we don't find an issue for some reason;
        dispatch(setFilteredIsuesData(ordered));
        // return ordered;
    }, [orderedCachedIssues, dispatch, issues]);

    const Row = useCallback(
        ({ index, style }) => {
            const issue = orderedIssues[index] || {};
            return (
                <div
                    style={{
                        ...style,
                        left: style.left + 2,
                    }}>
                    <IssueItem
                        index={index}
                        setSize={setSize}
                        key={`${issue.id}`}
                        issue={issue}
                        selected={issue.id === selectedIssue.issue}
                        isIssue={true}
                        searchQuery={searchQueryFilter}
                        minHeight={ISSUE_ITEM_HEIGHT_CLOSED}
                        autoAck={autoAck}
                    />
                </div>
            );
        },
        [autoAck, orderedIssues, searchQueryFilter, setSize, selectedIssue],
    );

    const getItemSize = (index) => {
        if (sizeMap.current[index]) {
            return Math.max(sizeMap.current[index] + GUTTER_SIZE, ISSUE_ITEM_HEIGHT_CLOSED);
        }
        return ISSUE_ITEM_HEIGHT_CLOSED;
    };

    useEffect(() => {
        if (selectedIssue.issue) {
            const issueIndex = Math.max(_.findIndex(orderedIssues, { id: selectedIssue.issue }), 0);
            if (listRef.current) {
                listRef.current.scrollToItem(issueIndex);
                listRef.current.resetAfterIndex(issueIndex);
            }
        }

        return () => {
            if (selectedIssue.issue) {
                const issueIndex = _.findIndex(orderedIssues, { id: selectedIssue.issue });
                if (listRef.current) {
                    listRef.current.resetAfterIndex(issueIndex);
                }
            }
        };
    }, [selectedIssue.issue, orderedIssues]);

    const content = () => {
        if (initialised) {
            if (!orderedIssues.length) {
                return (
                    <div className="NoIssuesContaner">
                        <h1 className="NoIssuesText">No issues matching current filters</h1>
                    </div>
                );
            } else {
                return (
                    <div
                        className="IssuesList"
                        id="ScrollList">
                        <AutoSizer className="issuesAutosizer">
                            {({ height, width }) => (
                                <VariableSizeList
                                    windowWidth={windowWidth}
                                    height={height}
                                    width={width - 5}
                                    style={{ overflowX: "hidden" }}
                                    itemSize={getItemSize}
                                    ref={listRef}
                                    itemCount={orderedIssues.length}>
                                    {Row.bind(this)}
                                </VariableSizeList>
                            )}
                        </AutoSizer>
                    </div>
                );
            }
        } else {
            return (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }
    };

    const handleDayClick = (day) => {
        const currentRange = {
            from: new Date(dateFilter.from * 1000),
            to: new Date(dateFilter.to * 1000),
        };
        const range = DayPicker.DateUtils.addDayToRange(day, currentRange);
        if (!dateFilter.to) {
            range.to = day;
            range.from = day;
        }
        const newDateFilter = {
            to: moment(range.to).endOf("day").unix(),
            from: moment(range.from).startOf("day").unix(),
        };
        dispatch(setIssueDateFilter(newDateFilter));
    };

    const classSpecificSorts = () => {
        let options = [];
        if (CLASS_SPECIFIC_SORT_DATA.hasOwnProperty(selectedClassValue)) {
            options.push(
                CLASS_SPECIFIC_SORT_DATA[selectedClassValue].map((option) => (
                    <Select.Option
                        key={option.value}
                        value={option.value}
                        label={
                            <>
                                <FontAwesomeIcon
                                    icon={faSortAmountDown}
                                    style={{ marginRight: "5px" }}
                                />
                                {option.short_title}
                            </>
                        }>
                        {option.label}
                    </Select.Option>
                )),
            );
        }
        return options;
    };

    const handleIssueGroupFilterChange = (oldIssueGroup, newIssueGroup) => {
        setReloadingData(true);
        sizeMap.current = {};
        dispatch(selectIssue(null));
        dispatch(selectAsset(null));

        const oldCassName = _.get(issueGroups, [oldIssueGroup, "class"], null);
        const newClassName = _.get(issueGroups, [newIssueGroup, "class"], null);

        setTimeout(() => {
            let sort_is_class_specific = false;
            let defaultOverride = false;
            if (CLASS_SPECIFIC_SORT_DATA.hasOwnProperty(oldCassName)) {
                sort_is_class_specific = _.find(CLASS_SPECIFIC_SORT_DATA[oldCassName], { value: sortValue });
            }
            if (CLASS_SPECIFIC_SORT_DATA.hasOwnProperty(newClassName)) {
                defaultOverride = _.find(CLASS_SPECIFIC_SORT_DATA[newClassName], { default: true });
            }

            if (!oldCassName || sort_is_class_specific || defaultOverride) {
                let defaultValue = DEFAULT_SORT_VALUE;

                if (defaultOverride) {
                    defaultValue = defaultOverride.value;
                }
                setSortValue(defaultValue);
            }

            dispatch(setIssueGroupFilter(newIssueGroup));
            if (listRef.current) {
                listRef.current.resetAfterIndex(0);
            }
            resetClassSearchValue(newClassName);
        }, 300);
    };

    const updateClassReduxValues = (filters, selectedClasss) => {
        const rangeValues = {
            [selectedClasss]: filters[selectedClasss] || {},
        };
        dispatch(setClassSearchValue(rangeValues));
    };

    const updateClassSearchReduxDebounce = useRef(_.debounce(updateClassReduxValues, 500));

    useEffect(() => {
        updateClassSearchReduxDebounce.current(classSpecificRanges, selectedClassValue);
    }, [classSpecificRanges, dispatch, selectedClassValue]);

    const toggleFilterDrawer = () => {
        setFilterOpen(!fitlersOpen);
        setCurrentlyVisibleTooltip(null);
    };

    const currentFilters = useMemo(() => {
        let label = [];
        let active_filters = [];

        if (priorityFilter !== "all") {
            label.push(_.find(PRIORITY_DATA, (priority) => priority.value === priorityFilter)["label"]);
            active_filters.push("priority");
        }

        if (dateFilter.from) {
            let date_string = moment.unix(dateFilter.from).format("MM/DD");
            if (dateFilter.to) {
                date_string += ` - ${moment.unix(dateFilter.to).format("MM/DD")}`;
            }
            label.push(date_string);
            active_filters.push("date_range");
        }

        const rangeFilters = _.get(classSpecificRanges, [selectedClassValue], {});

        Object.values(rangeFilters).forEach((filterData) => {
            if (filterData.type === "range") {
                const [defaultMin, defaultMax] = _.get(filterData, ["bounds"], []);
                const [manualMin, manualMax] = _.get(filterData, ["values"], []);
                const { unit } = filterData;

                if (defaultMin !== manualMin || defaultMax !== manualMax) {
                    let maxString = "";
                    if (filterData.label === "Bottom out temperature") {
                        maxString = manualMax === 50 ? "+" : "";
                    }
                    const rangeLabel = manualMin + "-" + manualMax + "" + unit + maxString;
                    label.push(rangeLabel);
                    active_filters.push("class_specific_range_filter");
                }
            }
        });

        if (label.length) {
            label = label.join(", ");
        } else {
            label = "Filter";
        }

        return { label, active_filters };
    }, [priorityFilter, statusFilter, autoAck, selectedClassValue, classSpecificRanges, dateFilter]);

    const updateCurrentRangeValues = useCallback(
        (filter, newRange) => {
            dispatchClassRanges({
                type: "update_class_range",
                class: selectedClassValue,
                range: newRange,
                filter,
            });
        },
        [selectedClassValue],
    );

    // below is used to calculate live values without rendering stuff on the screen to improve UX
    useMemo(() => {
        let tempClassRangeValues = {};
        if (selectedClassValue && !_.isEmpty(classSpecificRanges[selectedClassValue])) {
            tempClassRangeValues[selectedClassValue] = classSpecificRanges[selectedClassValue];
        }
        const filtered = filterIssues(
            dateFilter,
            statusFilter,
            priorityFilter,
            issueGroupFilter,
            cachedIssues,
            searchQueryFilter,
            { classSearchValue: tempClassRangeValues },
            showClosed,
        );
        setIssuesResultCount(filtered.length);
    }, [classSpecificRanges, selectedClassValue, dateFilter, statusFilter, priorityFilter, issueGroupFilter, cachedIssues, searchQueryFilter, showClosed]);

    const renderRangeFilter = useCallback(
        (filterKey, rangeData) => {
            const marks = () => {
                let marksObj = {};
                if (!_.isEmpty(rangeData)) {
                    const values = rangeData["bounds"];
                    const unit = rangeData["unit"];
                    marksObj[values[0]] = {
                        style: {
                            color: "#fff",
                        },
                        label: (
                            <strong>
                                {values[0]}
                                {unit}
                            </strong>
                        ),
                    };
                    marksObj[values[1]] = {
                        style: {
                            color: "#ffb100",
                        },
                        label: <strong>{values[1] === 50 && rangeData.label === "Bottom out temperature" ? `50${unit}+` : `${values[1]}${unit}`}</strong>,
                    };
                }
                return marksObj;
            };

            const [defaultMin, defaultMax] = _.get(rangeData, ["bounds"], []);
            const [manualMin, manualMax] = _.get(rangeData, ["values"], []);
            const { unit } = rangeData;
            const bounds = [defaultMin, defaultMax];
            const values = [manualMin, manualMax];

            return (
                <div className="IssueDrawerFilterSilder">
                    {_.isEqual(bounds, values) ? (
                        <>
                            {rangeData.label}: <span className="RangeLabelMax">Showing All</span>{" "}
                        </>
                    ) : (
                        <>
                            {rangeData.label} <span className="RangeLabelMin">{manualMin} </span>-{" "}
                            <span className="RangeLabelMax">
                                {rangeData.label === "Bottom out temperature" && manualMax === 50 ? `50${unit}+` : `${manualMax}${unit}`}
                            </span>
                        </>
                    )}
                    <Slider
                        key={selectedClassValue}
                        range
                        tooltipVisible={false}
                        value={[manualMin, manualMax]}
                        max={defaultMax}
                        marks={marks(selectedClassValue)}
                        onChange={(value) => updateCurrentRangeValues(filterKey, value)}
                    />
                </div>
            );
        },
        [selectedClassValue, updateCurrentRangeValues],
    );

    const renderSelectFilter = useCallback(
        (filterKey, selectData) => {
            const onChange = (value) => {
                updateCurrentRangeValues(filterKey, value);
            };

            return (
                <div className="IssueDrawerFilterFishplate IssueDrawerFilterItemCol IssuesFilterSelect">
                    {selectData.label}
                    <Select
                        defaultValue="all"
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => onChange(value)}>
                        {selectData.options.map((option) => {
                            return <Select.Option value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</Select.Option>;
                        })}
                    </Select>
                </div>
            );
        },
        [updateCurrentRangeValues],
    );

    const renderClassSpecificFilters = useMemo(() => {
        const classSpecificFilters = _.get(classSpecificRanges, [selectedClassValue], {});
        return (
            <div className="ExtraFiltersContainer">
                {Object.keys(classSpecificFilters).map((filterKey) => {
                    const filterConfig = classSpecificFilters[filterKey];
                    if (filterConfig.type === "range") {
                        return renderRangeFilter(filterKey, filterConfig);
                    } else if (filterConfig.type === "select") {
                        return renderSelectFilter(filterKey, filterConfig);
                    }
                })}
            </div>
        );
    }, [classSpecificRanges, selectedClassValue, renderRangeFilter, renderSelectFilter]);

    const RenderDrawerContent = useMemo(() => {
        return (
            <>
                <div className="DrawerFlexContainer">
                    <div className="IssueDrawerFilterItemCol">
                        <span>Date</span>
                        <Tooltip
                            overlayClassName="IssueTabDatePicker"
                            overlayStyle={{ opacity: 1 }}
                            title={renderDatePicker}
                            visible={datePickerOpen}
                            placement="bottomLeft"
                            trigger="click"
                            onVisibleChange={(visible) => setDatePickerOpen(visible)}>
                            <OBCToggleButton
                                active={datePickerOpen}
                                toggled={dateFilter.from}>
                                <CalendarOutlined />
                            </OBCToggleButton>
                        </Tooltip>
                    </div>

                    <div className={`IssueDrawerFilterItemCol IssuesFilterSelect`}>
                        <span>Priority</span>
                        <Select
                            className={priorityFilter === "all" ? "" : "Active"}
                            value={priorityFilter}
                            onChange={onPriorityFilterChange}
                            dropdownMatchSelectWidth={false}>
                            {_.map(PRIORITY_DATA, (priority) => (
                                <Select.Option
                                    key={priority.value}
                                    value={priority.value}>
                                    {priority.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>

                    {superuser && (
                        <div className="IssueDrawerFilterItemCol IssueDrawerFilterItemSwitch">
                            <span>Auto-ack</span>
                            <Switch
                                className="IssueDrawerSwitch"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={autoAck}
                                onChange={(checked) => setAutoAck(checked)}
                            />
                        </div>
                    )}

                    {renderClassSpecificFilters}
                </div>
            </>
        );
    }, [renderDatePicker, datePickerOpen, dateFilter.from, priorityFilter, onPriorityFilterChange, superuser, autoAck, renderClassSpecificFilters]);

    const requestThermalReport = () => {
        const startDate = moment.unix(dateFilter.from).format("YYYY-MM-DD");
        const endDate = moment.unix(dateFilter.to).format("YYYY-MM-DD");
        const parameters = {
            issue_group_id: issueGroupFilter,
            start_date: startDate,
        };

        if (startDate !== endDate) {
            parameters["end_date"] = endDate;
        }

        dispatch(generateIssueAssetReport(parameters, "issue_report_export"));
    };

    const requestBalanceWeightReport = () => {
        const startDate = moment.unix(dateFilter.from).format("YYYY-MM-DD");
        const endDate = moment.unix(dateFilter.to).format("YYYY-MM-DD");
        const parameters = {
            issue_group_id: issueGroupFilter,
            start_date: startDate,
        };

        if (startDate !== endDate) {
            parameters["end_date"] = endDate;
        }

        dispatch(generateBalanceWeightReport(parameters));
    };

    const closeObservationModal = () => {
        dispatch(selectObservation(null));
        // setCacheCounter(counter => counter + 1);
    };

    let all_sort_data = _.concat(GENERIC_SORT_DATA, CLASS_SPECIFIC_SORT_DATA[selectedClassValue]);
    console.log("all sort data:", all_sort_data);
    let sort_description = _.find(all_sort_data, { value: sortValue });
    if (sort_description) {
        sort_description = sort_description.description;
    } else {
        sort_description = "N/A";
    }

    let status_description = _.find(STATUS_DATA, { value: statusFilter });
    if (status_description) {
        status_description = status_description.description;
    } else {
        status_description = "N/A";
    }

    const typeTooltipLabel = () => {
        const selectedType = _.get(issueGroups, [issueGroupFilter], null);
        if (selectedType && selectedType.class) {
            let nameToDisplay = `${selectedType.class}`;
            if (selectedType.subclass) {
                nameToDisplay += ` - ${selectedType.subclass}`;
            }
            if (selectedType.display_name) {
                nameToDisplay = selectedType.display_name;
            }
            return nameToDisplay;
        } else {
            return null;
        }
    };

    const statusTooltipLabel = () => {
        return _.get(
            _.find(STATUS_DATA, (status) => status.value === statusFilter),
            "label",
            null,
        );
    };

    const sortTooltipLabel = () => {
        let allClasses = GENERIC_SORT_DATA;
        if (CLASS_SPECIFIC_SORT_DATA.hasOwnProperty(selectedClassValue)) {
            allClasses = _.concat(CLASS_SPECIFIC_SORT_DATA[selectedClassValue], GENERIC_SORT_DATA);
        }
        return _.get(
            _.find(allClasses, (status) => status.value === sortValue),
            "label",
            null,
        );
    };

    const isThermalHotspotsTypeSelected = useMemo(() => {
        const typeName = _.get(issueGroups, [issueGroupFilter, "class"]);
        return typeName === "Thermal Hotspot";
    }, [issueGroupFilter, issueGroups]);

    const isBalanceWeightTypeSelected = useMemo(() => {
        const typeName = _.get(issueGroups, [issueGroupFilter, "class"]);
        return typeName === "Tensioner";
    }, [issueGroupFilter, issueGroups]);

    return (
        <>
            {!_.isNil(selectedIssue.observation) && (
                <ObservationModal
                    isIssue={true}
                    closeModal={() => closeObservationModal()}
                    observations={issueObservations}
                    displayUnreadable={false}
                />
            )}
            <div className="IssuesContentTab">
                <div className="TopContainer">
                    <div className="IssueFilterContainer">
                        <div className="LeftColumn">
                            {/* Issue type Selector */}
                            {Object.values(issueGroups).length > 1 && (
                                <div className="IssuesFilterSelect">
                                    <span>Type</span>
                                    <Tooltip
                                        title={typeTooltipLabel()}
                                        mouseEnterDelay={0.5}
                                        visible={currentlyVisibleTooltip === "type"}
                                        onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "type")}>
                                        <Select
                                            optionLabelProp="label"
                                            value={issueGroupFilter}
                                            onDropdownVisibleChange={() => setCurrentlyVisibleTooltip(null)}
                                            onChange={(val) => handleIssueGroupFilterChange(issueGroupFilter, val)}
                                            dropdownMatchSelectWidth={false}>
                                            {Object.values(issueGroups).map((issueGroup) => {
                                                let nameToDisplay = `${issueGroup.class}`;
                                                if (issueGroup.subclass) {
                                                    nameToDisplay += ` - ${issueGroup.subclass}`;
                                                }
                                                if (issueGroup.display_name) {
                                                    nameToDisplay = issueGroup.display_name;
                                                }
                                                return (
                                                    <Select.Option
                                                        value={issueGroup.id}
                                                        key={issueGroup.id}
                                                        label={
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={faMapMarkerExclamation}
                                                                    style={{ marginRight: "7px" }}
                                                                />
                                                                {nameToDisplay}
                                                            </>
                                                        }>
                                                        {nameToDisplay}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Tooltip>
                                </div>
                            )}

                            {/* Issues Status Selector */}
                            <div className={`IssuesFilterSelect`}>
                                <span>Status</span>
                                <Tooltip
                                    title={statusTooltipLabel()}
                                    mouseEnterDelay={0.5}
                                    visible={currentlyVisibleTooltip === "status"}
                                    onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "status")}>
                                    <Select
                                        value={statusFilter}
                                        onChange={onStatusFilterChange}
                                        dropdownMatchSelectWidth={false}
                                        optionLabelProp="label"
                                        onDropdownVisibleChange={() => setCurrentlyVisibleTooltip(null)}>
                                        {_.map(STATUS_DATA, (status) => (
                                            <Select.Option
                                                value={status.value}
                                                label={<>{status.label}</>}
                                                className="IssuesSelectOption">
                                                {" "}
                                                {/* the <> </> around lablel is to stop rendering deafult tooltip and let only antd Tooltip to be shown */}
                                                {status.label}
                                                <span className="IssuesSelectOptionBadge">{issueCountByStatus[status.value]}</span>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Tooltip>
                            </div>

                            {/* Issues Sort Selector */}
                            <div className="IssuesFilterSelect">
                                <span>Sort</span>
                                <Tooltip
                                    title={sortTooltipLabel()}
                                    mouseEnterDelay={0.5}
                                    visible={currentlyVisibleTooltip === "sort"}
                                    onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "sort")}>
                                    <Select
                                        optionLabelProp="label"
                                        value={sortValue}
                                        onChange={onSortChange}
                                        dropdownMatchSelectWidth={false}
                                        onDropdownVisibleChange={() => setCurrentlyVisibleTooltip(null)}>
                                        {/* <Select.Option key="status_priority_time" value="status_priority_time" label={<><FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: "5px" }} />{"Status"}</>}>Status - Priority - Time</Select.Option> */}
                                        {/* <Select.Option key="priority_status_time" value="priority_status_time" label={<><FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: "5px" }} />{"Priority"}</>}>Priority - Status - Time</Select.Option> */}
                                        {/* <Select.Option key="last_observed" value="last_observed" label={<><FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: "5px" }} />{"Time"}</>}>Time (Most recent)</Select.Option> */}
                                        {/* <Select.Option key="match_count" value="match_count" label={<><FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: "5px" }} />{"Observations"}</>}>Number of Observations (Desc)</Select.Option> */}
                                        {_.map(GENERIC_SORT_DATA, (sort) => (
                                            <Select.Option
                                                key={sort.value}
                                                value={sort.value}
                                                label={<>{sort.short_title}</>}>
                                                {sort.label}
                                            </Select.Option>
                                        ))}
                                        {classSpecificSorts()}
                                    </Select>
                                </Tooltip>
                            </div>

                            {/* Thermal Hotspots report Button */}
                            {/* Only display if issueGroupFilter is 1 which is Thermal Hotspot */}

                            {isThermalHotspotsTypeSelected && (
                                <div className="IssuesFilterSelect">
                                    <span>Report</span>
                                    <Tooltip
                                        mouseEnterDelay={0.5}
                                        title={"Generate Thermal Issues Report"}
                                        visible={isReportPopoverVisible ? false : currentlyVisibleTooltip === "report"}
                                        onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "report")}>
                                        <div className="RequestIssuesReport">
                                            <Popover
                                                title="Thermal Issues Report"
                                                content={
                                                    <div className="ThermalIssueReportPopover">
                                                        <div>Select a date / date range to enable Thermal Issues Report generation.</div>
                                                        <OBCButton
                                                            onClick={() => {
                                                                setIsReportPopoverVisible(false);
                                                                setFilterOpen(true);
                                                                setDatePickerOpen(true);
                                                            }}>
                                                            <CalendarOutlined /> Open Date Picker
                                                        </OBCButton>
                                                    </div>
                                                }
                                                trigger="click"
                                                placement="bottom"
                                                visible={isReportPopoverVisible && (!dateFilter.to || !dateFilter.from)}
                                                onVisibleChange={(value) => {
                                                    setCurrentlyVisibleTooltip(null);
                                                    if (dateFilter.to || dateFilter.from) {
                                                        setIsReportPopoverVisible(false);
                                                    } else {
                                                        setIsReportPopoverVisible(value);
                                                    }
                                                }}>
                                                <OBCToggleButton
                                                    icon={faFileDownload}
                                                    onClick={() => {
                                                        if (dateFilter.to || dateFilter.from) {
                                                            requestThermalReport();
                                                            setIsReportPopoverVisible(false);
                                                        }
                                                    }}
                                                />
                                            </Popover>
                                        </div>
                                    </Tooltip>
                                </div>
                            )}

                            {isBalanceWeightTypeSelected && (
                                <div className="IssuesFilterSelect">
                                    <span>Report</span>
                                    <Tooltip
                                        mouseEnterDelay={0.5}
                                        title={"Generate Balance Weight Issues Report"}
                                        visible={isReportPopoverVisible ? false : currentlyVisibleTooltip === "report"}
                                        onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "report")}>
                                        <div className="RequestIssuesReport">
                                            <Popover
                                                title="Balance Weight Issues Report"
                                                content={
                                                    <div className="ThermalIssueReportPopover">
                                                        <div>To be able to generate a Balance Weight Report you have to select a date or a date range</div>
                                                        <OBCButton
                                                            onClick={() => {
                                                                setIsReportPopoverVisible(false);
                                                                setFilterOpen(true);
                                                                setDatePickerOpen(true);
                                                            }}>
                                                            <CalendarOutlined /> Open Date Picker
                                                        </OBCButton>
                                                    </div>
                                                }
                                                trigger="click"
                                                placement="bottom"
                                                visible={isReportPopoverVisible && (!dateFilter.to || !dateFilter.from)}
                                                onVisibleChange={(value) => {
                                                    setCurrentlyVisibleTooltip(null);
                                                    if (dateFilter.to || dateFilter.from) {
                                                        setIsReportPopoverVisible(false);
                                                    } else {
                                                        setIsReportPopoverVisible(value);
                                                    }
                                                }}>
                                                <OBCToggleButton
                                                    icon={faFileDownload}
                                                    onClick={() => {
                                                        if (dateFilter.to || dateFilter.from) {
                                                            requestBalanceWeightReport();
                                                            setIsReportPopoverVisible(false);
                                                        }
                                                    }}
                                                />
                                            </Popover>
                                        </div>
                                    </Tooltip>
                                </div>
                            )}

                            {/* Issues additional filter Button */}
                            <div className="IssuesFilterSelect">
                                <span>Filters</span>
                                <Tooltip
                                    title={currentFilters.label}
                                    mouseEnterDelay={0.5}
                                    visible={currentlyVisibleTooltip === "filters"}
                                    onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "filters")}>
                                    <Badge
                                        count={currentFilters.active_filters.length}
                                        showZero
                                        style={{ backgroundColor: currentFilters.active_filters.length ? "#E55151" : "#9379f7", color: "white" }}>
                                        <OBCToggleButton
                                            onClick={() => toggleFilterDrawer()}
                                            toggled={currentFilters.active_filters.length > 0}
                                            active={fitlersOpen}
                                            showArrow={true}>
                                            <div className="FilterButtonContainer">
                                                <FontAwesomeIcon
                                                    icon={faFilter}
                                                    style={{ marginTop: "3px" }}
                                                />
                                                <span className="ToggleButtonText">{currentFilters.label}</span>
                                            </div>
                                        </OBCToggleButton>
                                    </Badge>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="RightColumn">
                            <OBCSearchInput
                                className="IssuesFilterSearchInput"
                                placeholder="Search issues"
                                onChange={onSearchQueryChanged}
                                value={searchQueryFilter}
                            />
                        </div>
                    </div>
                    <div className={`TabDrawerMainNew ${fitlersOpen ? "Open" : "Close"}`}>
                        <div className="DrawerMainTitle">
                            <CloseOutlined
                                className="closeIcon"
                                onClick={() => toggleFilterDrawer()}
                            />
                        </div>
                        {RenderDrawerContent}
                    </div>
                    <div className="CountLabel">
                        Showing <span className={`${issuesResultCount !== orderedIssues.length ? "ValueNew" : "Value"}`}>{issuesResultCount}</span> of{" "}
                        {issueCountByStatus[statusFilter]} {status_description} issues, sorted by {sort_description}
                    </div>
                </div>
                <div
                    className="IssuesList"
                    id="ScrollList">
                    {(orderedIssues.length !== issuesResultCount || reloadingData) && (
                        <div className="IssuesUpdatingOverlay">
                            {reloadingData ? (
                                <OBCSpinner
                                    size={70}
                                    speed={3}
                                    color={"#e8dfff"}
                                />
                            ) : (
                                <div className="MatchingResults">
                                    <div>
                                        Matching results: <span className="ValueNew">{issuesResultCount}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {content()}
                </div>
            </div>
        </>
    );
};

export default IssuesTab;
