import React, { useEffect, useRef, useState, useCallback } from "react";
import Measure from "react-measure";
import { useDispatch, useSelector } from "react-redux";
import { getAbsoluteThermalImage } from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { getTempUnit, degreesToUserPreference } from "components/util/TemperatureUtils";
import { debounce } from "lodash";

const radius = 5;
const magnification = 5;

const tempUnitPreferenceSelector = (state) => state.userDetails.userConfig.temperature_units || "celsius";

const AbsoluteColourImageDisplay = ({ sessionKey, videoKey, deviceKey, frame, displayRawImage, thermalImageSrc, setLoading, absoluteImageLoading }) => {
    const dispatch = useDispatch();

    const imageCanvasRef = useRef(null);
    const absoluteImageCanvasRef = useRef(null);
    const magnifyImageCanvasRef = useRef(null);

    const [dpr, setDpr] = useState(window.devicePixelRatio);
    const [position, setPosition] = useState(null);
    const [annotationPosition, setAnnotationPosition] = useState({ x: 0, y: 0 });
    const [temps, setTemps] = useState({ px: 0, min: 0, max: 0, avg: 0 });
    const [selecting, setSelecting] = useState(true);
    const [image, setImage] = useState(null);
    const [dimensions, setDimensions] = useState({});
    const [canvasDimensions, setCanvasDimensions] = useState({ width: 100, height: 100 });

    const tempUnitPreference = useSelector(tempUnitPreferenceSelector);

    useEffect(() => {
        const updateDevicePixelRatio = () => setDpr(window.devicePixelRatio);

        const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${dpr}dppx)
        `,
        );

        mediaMatcher.addEventListener("change", updateDevicePixelRatio);

        return () => {
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
        };
    }, []);

    const getAbsoluteThermalImageDebounced = useCallback(
        debounce(
            (deviceKey, sessionKey, videoKey, frame) => {
                setLoading(true);

                dispatch(getAbsoluteThermalImage(deviceKey, sessionKey, videoKey, frame))
                    .then((absoluteImage) => {
                        if (absoluteImage.image) {
                            if (absoluteImage.image === image) {
                                setLoading(false);
                            }

                            setImage(absoluteImage.image);
                        } else {
                            setImage(null);
                        }
                    })
                    .catch((error) => {
                        console.log("debug error getting absolute temp image", error);
                    });
            },
            500,
            {
                leading: true,
                trailing: true,
            },
        ),
        [],
    );

    useEffect(() => {
        if (sessionKey && deviceKey && videoKey) {
            getAbsoluteThermalImageDebounced(deviceKey, sessionKey, videoKey, frame);
        }
    }, [getAbsoluteThermalImageDebounced, deviceKey, frame, sessionKey, videoKey]);

    const drawMagnified = () => {
        const imageCtx = imageCanvasRef.current.getContext("2d");
        const absoluteCtx = absoluteImageCanvasRef.current.getContext("2d");
        const magnifyCtx = magnifyImageCanvasRef.current.getContext("2d");

        // Wipe canvas clean
        magnifyCtx.clearRect(0, 0, magnifyCtx.canvas.width, magnifyCtx.canvas.height);

        // Create clipping mask
        magnifyCtx.save();
        magnifyCtx.beginPath();
        magnifyCtx.arc(position.x * dpr, position.y * dpr, radius * magnification * dpr, 0, 2 * Math.PI);
        magnifyCtx.strokeStyle = "#fff8";
        magnifyCtx.lineWidth = radius * 3 * dpr;
        magnifyCtx.stroke();
        magnifyCtx.clip();

        // Draw magnified fragment
        const diameter = radius * 2;
        const magnifiedRadius = radius * magnification;
        magnifyCtx.drawImage(
            displayRawImage ? absoluteCtx.canvas : imageCtx.canvas,
            (position.x - diameter / 2) * dpr,
            (position.y - diameter / 2) * dpr,
            diameter * dpr,
            diameter * dpr,
            (position.x - magnifiedRadius) * dpr,
            (position.y - magnifiedRadius) * dpr,
            2 * magnifiedRadius * dpr,
            2 * magnifiedRadius * dpr,
        );

        // Undo clipping
        magnifyCtx.restore();
    };

    const calculateCircularRedValueStats = useCallback(() => {
        const origRadius = Math.round(radius * dpr);
        const diameter = Math.round(origRadius * 2);

        const ctx = absoluteImageCanvasRef.current.getContext("2d");
        const imageData = ctx.getImageData(position.x * dpr - origRadius, position.y * dpr - origRadius, diameter, diameter).data;
        const centerPixelIndex = origRadius * diameter * 4 + origRadius * 4;

        let min = imageData[0];
        let max = imageData[0];
        let sum = 0;
        let count = 0;

        for (let y = 0; y < diameter; y++) {
            for (let x = 0; x < diameter; x++) {
                const dx = x - origRadius;
                const dy = y - origRadius;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= origRadius) {
                    count++;
                    const index = (y * diameter + x) * 4;
                    const value = imageData[index];

                    if (value < min) min = value;
                    if (value > max) max = value;
                    sum += value;
                }
            }
        }

        let pixelTemp = degreesToUserPreference(Math.floor(imageData[centerPixelIndex] * 0.64 - 35), tempUnitPreference);
        let minTemp = degreesToUserPreference(Math.floor(min * 0.64 - 35), tempUnitPreference);
        let maxTemp = degreesToUserPreference(Math.floor(max * 0.64 - 35), tempUnitPreference);
        let avgTemp = degreesToUserPreference(Math.floor((sum / count) * 0.64 - 35), tempUnitPreference);

        return {
            px: pixelTemp,
            min: minTemp,
            max: maxTemp,
            avg: avgTemp,
        };
    }, [tempUnitPreference, position]);

    useEffect(() => {
        if (position !== null) {
            drawMagnified();
            setTemps(calculateCircularRedValueStats());
        }
    }, [position, displayRawImage, calculateCircularRedValueStats]);

    const positionCrossHairs = () => {
        const ctx = magnifyImageCanvasRef.current.getContext("2d");
        let x = ctx.canvas.width / (2 * dpr);
        let y = ctx.canvas.height / (2 * dpr);
        setPosition({ x, y });
        setAnnotationPosition({ x: x - 48, y: y + 48 });
    };

    const updateCrossHairs = (newPosition) => {
        setPosition(newPosition);

        let annotationX = newPosition.x - 48;
        let annotationY = newPosition.y + 48;

        if (newPosition.x < 48) annotationX = radius;
        if (newPosition.x > canvasDimensions.width - 54) annotationX = canvasDimensions.width - 102;
        if (newPosition.y > canvasDimensions.height - 162) annotationY = annotationY - 180;

        setAnnotationPosition({ x: annotationX, y: annotationY });
    };

    useEffect(() => {
        if (imageCanvasRef.current) {
            const image = new Image();
            image.src = thermalImageSrc;

            const thermalImageCb = () => {
                const widthRatioChange = dimensions.width / image.naturalWidth;
                const heightRatioChange = dimensions.height / image.naturalHeight;
                const ratioChange = image.naturalWidth * heightRatioChange > dimensions.width ? widthRatioChange : heightRatioChange;

                const newWidth = image.naturalWidth * ratioChange;
                const newHeight = image.naturalHeight * ratioChange;

                if (position !== null) {
                    setPosition({
                        x: (position.x / (canvasDimensions.width / 2)) * (newWidth / 2),
                        y: (position.y / (canvasDimensions.height / 2)) * (newHeight / 2),
                    });
                }

                setCanvasDimensions({ width: newWidth, height: newHeight });

                const ctx = imageCanvasRef.current.getContext("2d");
                ctx.drawImage(image, 0, 0, newWidth * dpr, newHeight * dpr);
            };

            image.addEventListener("load", thermalImageCb);
            return () => image.removeEventListener("load", thermalImageCb);
        }
    }, [dimensions.width, dimensions.height, thermalImageSrc]);

    useEffect(() => {
        if (absoluteImageCanvasRef.current) {
            const imageElem = new Image();
            imageElem.src = image;

            const absoluteImageCb = () => {
                const ctx = absoluteImageCanvasRef.current.getContext("2d", { willReadFrequently: true });
                ctx.drawImage(imageElem, 0, 0, canvasDimensions.width * dpr, canvasDimensions.height * dpr);

                if (!position) {
                    positionCrossHairs();
                } else {
                    updateCrossHairs({ ...position });
                }

                setLoading(false);
            };

            imageElem.addEventListener("load", absoluteImageCb);
            return () => imageElem.removeEventListener("load", absoluteImageCb);
        }
    }, [canvasDimensions, image, dpr]);

    const handleMouseMove = ({ nativeEvent: event }) => {
        if (
            event.layerX >= radius &&
            event.layerX <= canvasDimensions.width - radius &&
            event.layerY >= radius &&
            event.layerY <= canvasDimensions.height - radius
        ) {
            updateCrossHairs({ x: event.layerX, y: event.layerY });
        }
    };

    const handleClick = ({ nativeEvent: event }) => {
        if (!selecting) {
            updateCrossHairs({ x: event.layerX, y: event.layerY });
        }

        setSelecting(!selecting);
    };

    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setDimensions(contentRect.bounds);
            }}>
            {({ measureRef }) => {
                return (
                    <>
                        {absoluteImageLoading && (
                            <div
                                className="AbsoluteImageSpinnerContainer"
                                style={{ position: "absolute", zIndex: 2000 }}>
                                <OBCSpinner
                                    size={80}
                                    speed={3}
                                    colorScheme={"mono"}
                                />
                            </div>
                        )}
                        <div
                            className="AbsoluteTempOverlay"
                            ref={measureRef}>
                            <div style={canvasDimensions}>
                                <canvas
                                    ref={imageCanvasRef}
                                    width={canvasDimensions.width * dpr}
                                    height={canvasDimensions.height * dpr}
                                    className="AbsoluteImageCanvas"
                                />
                                <canvas
                                    ref={absoluteImageCanvasRef}
                                    width={canvasDimensions.width * dpr}
                                    height={canvasDimensions.height * dpr}
                                    className={"AbsoluteImageCanvas" + (displayRawImage ? "" : " Hidden")}
                                />
                                <canvas
                                    ref={magnifyImageCanvasRef}
                                    onMouseMove={selecting ? handleMouseMove : null}
                                    onClick={handleClick}
                                    width={canvasDimensions.width * dpr}
                                    height={canvasDimensions.height * dpr}
                                    className="AbsoluteImageCanvas"
                                    style={{
                                        zIndex: 1,
                                        cursor: selecting ? "crosshair" : "default",
                                    }}
                                />
                                {position && (
                                    <div
                                        className="TempDisplayContainer"
                                        style={{ transform: `translateX(${annotationPosition.x}px) translateY(${annotationPosition.y}px)` }}>
                                        <div>
                                            <span>Centre</span>
                                            <span>
                                                <strong className="temperature">{temps.px}</strong>
                                                {getTempUnit(tempUnitPreference)}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Max</span>
                                            <span>
                                                <strong className="temperature">{temps.max}</strong>
                                                {getTempUnit(tempUnitPreference)}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Min</span>
                                            <span>
                                                <strong className="temperature">{temps.min}</strong>
                                                {getTempUnit(tempUnitPreference)}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Avg</span>
                                            <span>
                                                <strong className="temperature">{temps.avg}</strong>
                                                {getTempUnit(tempUnitPreference)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                );
            }}
        </Measure>
    );
};

export default AbsoluteColourImageDisplay;
