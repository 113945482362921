import { combineReducers } from "redux";
import { TOGGLE_MEGASEARCH } from "redux/actions";

const megasearchOpen = (state = false, action) => {
    if (action.type === TOGGLE_MEGASEARCH) {
        state = action.open;
    }

    return state;
};

const megasearchReducers = combineReducers({
    open: megasearchOpen,
});

export default megasearchReducers;
