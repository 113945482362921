import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { InputNumber, Select } from "antd";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { absoluteTimeLookup } from "../util/PlaylistUtils";
import ZoomableImage from "components/ZoomableImage";

const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
const playlistSelector = (state) => state.playlist.data;
const imageKeysSelector = (state) => {
    return _.get(state.playlist.data, ["video"], []);
};
const baseUrlSelector = (state) => {
    return _.get(state.playlist.data, ["mpdURLs", `snapshots`]);
};
const streamInfoSelector = (state) => {
    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], {});
    return _.get(currentSession, ["stream_info"], []);
};
const csrfTokenSelector = (state) => state.csrfToken;

const SidekickPlayer = () => {
    const dashboardData = useSelector(dashboardDataSelector);
    const playlist = useSelector(playlistSelector);
    const allImageKeys = useSelector(imageKeysSelector);
    const baseURL = useSelector(baseUrlSelector);
    const streamInfo = useSelector(streamInfoSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [sourceIndex, setSourceIndex] = useState(0);
    const [manualOffset, setManualOffset] = useState(0);
    const [zoom, setZoom] = useState(1);

    const currentTimestamp = useMemo(() => {
        return dashboardData.position?.timestamp;
    }, [dashboardData.position]);

    const currentOffset = useMemo(() => {
        return dashboardData.position?.currentTimeOffset;
    }, [dashboardData.position]);

    const imageKeys = useMemo(() => {
        console.log("debug allImageKeys", allImageKeys);
        return _.get(allImageKeys, [sourceIndex], []);
    }, [allImageKeys, sourceIndex]);

    const sourceList = useMemo(() => {
        const sources = [];

        if (!playlist || !playlist.video) {
            return sources;
        }

        for (let i = 0; i < playlist.video.length; i++) {
            if (!playlist.video[i].length) {
                continue;
            }

            let sourceName = _.get(streamInfo, [i, "info", "type"], "Camera " + (i + 1));
            let sourceLabel = _.get(streamInfo, [i, "info", "label"], "");

            let name = sourceName;
            if (sourceLabel) {
                name += " (" + sourceLabel + ")";
            }

            const source = {
                name: name,
                streams: [
                    {
                        name: "Still Images",
                        videoIndex: i,
                        isVideo: true,
                        isEnhanced: false,
                        isStills: true,
                    },
                ],
            };
            sources.push(source);
        }

        return sources;
    }, [playlist, streamInfo]);

    const getURLForImage = useCallback(
        (index, subIndex) => {
            if (index !== -1) {
                let selectedKey = imageKeys[index];
                if (selectedKey) {
                    let imageKey = selectedKey[0];
                    if (selectedKey[5]) {
                        imageKey += "-" + subIndex;
                    }
                    imageKey += ".jpg";
                    let imageUrl = baseURL + imageKey;
                    if (csrfToken) {
                        imageUrl += `?csrf=${csrfToken}`;
                    }
                    return imageUrl;
                }
            }
            return null;
        },
        [baseURL, imageKeys, csrfToken],
    );

    const imageSrc = useMemo(() => {
        if (!imageKeys || !imageKeys.length) {
            return "";
        }

        let indexToUse = absoluteTimeLookup(currentTimestamp / 1000, imageKeys);
        indexToUse = Math.min(Math.max(1, indexToUse), imageKeys.length - 1);

        const playlistItem = imageKeys[indexToUse];
        let frameCount = playlistItem[5];

        const timeOffset = currentOffset;

        let subIndex = Math.ceil(timeOffset * frameCount) + manualOffset;

        if (subIndex > frameCount) {
            subIndex = subIndex % frameCount;
            indexToUse += 1;
        } else if (subIndex < 0) {
            subIndex = frameCount + subIndex;
            indexToUse -= 1;
        }

        subIndex = Math.min(Math.max(1, subIndex), frameCount - 1);

        const imageURL = getURLForImage(indexToUse, subIndex);
        return imageURL;
    }, [currentTimestamp, imageKeys, manualOffset, getURLForImage, currentOffset]);

    if (sourceList && !sourceList.length) {
        return (
            <div className="widget__Info center">
                <p>No video data available for this session</p>
            </div>
        );
    }

    return (
        <div className="SidekickPlayer">
            <div className="SidekickPlayer__Header">
                <div className="InputGroup">
                    <label>Offset:</label>
                    <InputNumber
                        size="small"
                        max={50}
                        min={-50}
                        value={manualOffset}
                        onChange={setManualOffset}
                    />
                </div>
                <div className="InputGroup">
                    <label>Type:</label>
                    <Select
                        size="small"
                        value={sourceIndex}
                        onChange={setSourceIndex}>
                        {_.map(sourceList, (source, source_index) => (
                            <Select.Option
                                key={source.name}
                                value={source_index}>
                                {source.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>

            <ZoomableImage
                imgSrc={imageSrc}
                zoom={zoom}
                updateZoom={(zoom) => setZoom(zoom)}
            />
        </div>
    );
};

export default SidekickPlayer;
