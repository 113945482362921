import React, { useCallback, useEffect, useRef, useMemo, useState } from "react";
import "../../../aivr.scss";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, targetResource, routeSelected, setVideoSpeed, changeToolMode } from "redux/actions";

import { Modal } from "antd";
import ExpiryBanner from "../../ExpiryBanner";
import VideoOrImageComponent from "../../display/VideoOrImageComponent";

const shareLinkDetailsSelector = (state) => state.shareLinkDetails;
const targetResourceSelector = (state) => state.targetResource;

function Widget({ measureRef }) {
    const shareLinkDetails = useSelector(shareLinkDetailsSelector);
    const _targetResource = useSelector(targetResourceSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const appRef = useRef();

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (_targetResource) {
            if (shareLinkDetails && shareLinkDetails.sessionID) {
                dispatch(routeSelected(shareLinkDetails.sessionID, shareLinkDetails.timestamp));
            }
        }
    }, [_targetResource]);

    const logoutAction = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const dismissTargetResource = useCallback(() => {
        history.replace("", null);
        dispatch(targetResource(null));
    }, [history, dispatch]);

    const resourceAccessDenied = useMemo(() => {
        return _targetResource && _targetResource.access_granted === false;
    }, [_targetResource]);

    // event listner for post messages from the iframe in the widget.
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.target && event.data.target === "widget") {
                if (event.data.action && event.data.action === "load_session") {
                    setErrorMessage(null);
                    console.log("debug event", event.data);
                    const sessionID = event.data.sessionID;
                    const ts = event.data.ts;
                    const sessionPositionFilter = event.data.positionFilter;
                    const playbackSpeed = event.data.playbackSpeed ?? 1;
                    dispatch(setVideoSpeed(playbackSpeed));
                    dispatch(changeToolMode(null));
                    if (sessionID) {
                        dispatch(routeSelected(sessionID, ts ?? 0, 0, undefined, undefined, 0, false, false, null, sessionPositionFilter));
                    } else {
                        setErrorMessage("ERROR: Invalid request or missing data");
                    }
                }
            }
        });
    }, []);

    return (
        <div
            ref={measureRef}
            className="App">
            <Modal
                title="Access Denied"
                visible={resourceAccessDenied}
                onOk={dismissTargetResource}
                okText="Continue anyway"
                onCancel={logoutAction}
                cancelText="Logout"
                maskClosable={false}>
                <p>Your user account does not have access to the shared content.</p>
            </Modal>

            <div
                className="MainContent MainContentWidget"
                ref={appRef}>
                <div className="messageBannerContainer">
                    <ExpiryBanner />
                </div>

                <div className="MainContent__Inner">
                    <div className="Container Media MainContentVideoComponent">
                        <VideoOrImageComponent infoMessage="Session not selected" />
                        {errorMessage && (
                            <div
                                className="errorMessage"
                                style={{ color: "red", fontWeight: "bold" }}>
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Widget;
