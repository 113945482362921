import React from "react";
import "../mobile.scss";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MobileSessionList from "./mobile/MobileSessionList";
import DashboardLogo from "./DashboardLogoComponent";
import _ from "lodash";
import { logout, refresh, swapDashboard, toggleDesktop, setSessionFavourites, setSessionSearch, getSessionList } from "redux/actions/index";
import { Drawer, Input, Switch, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import OBCButton from "./OBC/OBCButton";
class MobileApp extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayDrawer: false,
            toolbarOpen: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(getSessionList());
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.targetResource !== this.props.targetResource && this.props.targetResource.id && this.props.targetResource.type === "session") {
            this.props.history.push(`/mobile/view/${this.props.targetResource.id}`);
        }
    };

    logout = () => {
        this.props.dispatch(logout());
        this.props.history.push("/login");
    };

    openDrawer = () => {
        this.setState({
            displayDrawer: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            displayDrawer: false,
        });
    };

    searchSessions = (event) => {
        console.log("[Mobile] Searching sessions for:", event.target.value);
        this.props.dispatch(setSessionSearch(event.target.value));
    };

    toggleFavourites = (checked) => {
        this.props.dispatch(setSessionFavourites(checked));
    };

    renderDashboardOptions = () => {
        const dashboardList = this.props.dashboards;
        if (dashboardList) {
            const alphabeticalList = dashboardList.sort((a, b) => a.description.localeCompare(b.description));

            return alphabeticalList.map((dashboard) => {
                return (
                    <div
                        className="dashboardSwapElement"
                        onClick={() => this.swapDashboard(dashboard)}
                        key={dashboard.access_id}>
                        <DashboardLogo
                            dashboard={dashboard}
                            customStyles={{ margin: 0, minHeight: 50, minWidth: 50 }}
                        />
                        <p className="dashboardListName">{dashboard.description}</p>
                    </div>
                );
            });
        } else {
            return null;
        }
    };
    swapDashboard = (dashboard) => {
        if (this.props.access_token === dashboard.access_token) {
            return;
        }
        this.closeDrawer();
        this.props.dispatch(swapDashboard(dashboard.access_token));
        this.props.dispatch(setSessionSearch(""));
        this.props.dispatch(refresh());
    };

    toggleToolbar = () => {
        this.setState({
            toolbarOpen: !this.state.toolbarOpen,
        });
    };

    goToDesktop = () => {
        const _this = this;
        console.log("Confirming here");
        Modal.confirm({
            content: (
                <div className="goToDesktopDiv">
                    <p>The desktop site is optimised for use on a desktop or laptop computer, some features may not work as expected.</p>
                </div>
            ),
            onOk() {
                _this.props.dispatch(toggleDesktop(true));
                _this.props.history.push("/");
            },
            onCancel() {
                return;
            },
        });
    };

    render() {
        if (this.props.access_token) {
            return (
                <>
                    {this.props.active_dashboard && (
                        <div className="mobileNavigationContainer">
                            <div className="mobileNavigationBlock">
                                <div
                                    className="dashboardInfoContainer"
                                    onClick={this.openDrawer}>
                                    <DashboardLogo
                                        dashboard={this.props.active_dashboard}
                                        width={45}
                                        height={45}
                                        customStyles={{ margin: 0 }}
                                    />
                                    <p className="dashboardName">{this.props.active_dashboard.description}</p>
                                </div>
                                <div
                                    className="sessionFilterItem"
                                    style={{ background: "#414664" }}>
                                    <OBCButton
                                        size="lg"
                                        variant={this.props.sessionSearchQuery === "" && this.props.favouritesOnly === false ? "ghost" : "primary"}
                                        aria-label="Filters"
                                        active={this.state.toolbarOpen}
                                        onClick={this.toggleToolbar}>
                                        <FontAwesomeIcon
                                            className="icon"
                                            icon={faFilter}
                                            size="2x"
                                            color={this.props.sessionSearchQuery === "" && this.props.favouritesOnly === false ? "#bfc5e1" : "white"}
                                        />
                                    </OBCButton>
                                </div>
                            </div>
                            <div className={"sessionToolbar " + (this.state.toolbarOpen ? "open" : "")}>
                                <div>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={this.toggleFavourites}
                                        checked={this.props.favouritesOnly}
                                    />
                                    <span>Favourites Only</span>
                                </div>
                                <div className="sessionSearch">
                                    <Input
                                        value={this.props.sessionSearchQuery}
                                        onChange={this.searchSessions}
                                        placeholder="Search for a session"
                                        style={{ width: "100%", height: 35 }}
                                        spellCheck={false}
                                        prefix={<SearchOutlined />}
                                        suffix={
                                            this.props.sessionSearchQuery ? (
                                                <div
                                                    aria-label="Clear"
                                                    type="button"
                                                    tabIndex={0}
                                                    onClick={() => this.props.dispatch(setSessionSearch(""))}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                            this.props.dispatch(setSessionSearch(""));
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon
                                                        className="sessionSearchInputClearButton"
                                                        icon={faTimesCircle}
                                                        style={{ color: "white" }}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                        data-lpignore
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <MobileSessionList toolbarOpen={this.state.toolbarOpen} />
                    <Drawer
                        placement={"left"}
                        onClose={this.closeDrawer}
                        visible={this.state.displayDrawer}
                        drawerStyle={{ backgroundColor: "#1E202F", padding: 25 }}
                        width={"80%"}
                        className="mobileDashboardsDrawer">
                        <div className="drawerContainer">
                            <div className="dashboardSwapContainer">{this.renderDashboardOptions()}</div>
                            <div className="bottomDrawerContainer">
                                <p className="drawerUserText">{this.props.userDetails.email}</p>
                                <p
                                    className="drawerUserText"
                                    onClick={this.goToDesktop}>
                                    Go to Desktop Site
                                </p>
                                <p
                                    className="logoutButton"
                                    onClick={this.logout}>
                                    Logout
                                </p>
                            </div>
                        </div>
                    </Drawer>
                </>
            );
        } else {
            let redirect = { pathname: "/mobile/login" };

            return <Redirect to={redirect} />;
        }
    }
}

const mapStateToProps = (state) => {
    const dashboards = state.dashboards;
    const active_dashboard = _.find(dashboards, (dash) => state.access_token === dash.access_token);
    return {
        access_token: state.access_token,
        active_dashboard,
        dashboards,
        userDetails: state.userDetails,
        favouritesOnly: state.sessionListFilters.favourites,
        targetResource: state.targetResource,
        sessionSearchQuery: state.sessionListFilters.search,
    };
};

export default connect(mapStateToProps)(withRouter(MobileApp));
