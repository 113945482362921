import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserSurveyOptions, getUserSurveyChoices, setUserSurveyChoices, skipUserPreferenceSurvey } from "redux/actions";
import "../style/aivrComponents.scss";
import { Select, Button, Modal, notification } from "antd";
import _ from "lodash";

const NewUserModal = ({ onClose }) => {
    const [modalOptions, setUserModalOptions] = useState({});
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [userPreferences, setUserPreferences] = useState([]);
    const [routeOptions, setRouteOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserSurveyOptions()).then((response) => {
            setUserModalOptions(response);
            setCategoryOptions(_.keys(response));
        });
        dispatch(getUserSurveyChoices()).then((response) => {
            setUserPreferences(response);
        });
    }, []);

    const { Option } = Select;

    const skipModal = () => {
        dispatch(skipUserPreferenceSurvey());
        onClose();
    };

    const handleTagSelection = (category, selection) => {
        let arr = userPreferences.filter((item) => item.hasOwnProperty(category));
        if (arr.filter((item) => item[category] === selection).length === 0) {
            let obj = {};
            obj[category] = selection;
            setUserPreferences([...userPreferences, obj]);
        } else {
            let filteredArr = userPreferences.filter((item) => item[category] !== selection);
            setUserPreferences(filteredArr);
        }
    };

    const handleRegionChange = (arr) => {
        const routes = [];

        if (arr.includes("Eastern")) {
            routes.push("Anglia", "East Midlands", "North East", "East Coast");
        }

        if (arr.includes("North West & Central")) {
            routes.push("North West", "Central", "West Coast");
        }

        if (arr.includes("Scotland's Railway")) {
            routes.push("Scotland");
        }

        if (arr.includes("Southern")) {
            routes.push("Kent", "Sussex", "Wessex", "Network Rail High Speed");
        }

        if (arr.includes("Wales & Western")) {
            routes.push("Wales", "Western");
        }

        setRouteOptions(routes);

        let filteredArr = userPreferences.filter((item) => {
            if (item.hasOwnProperty("Region")) {
                return false;
            }
            if (item.hasOwnProperty("Route")) {
                return routes.includes(item["Route"]);
            } else {
                return true;
            }
        });
        arr.forEach((item) => filteredArr.push({ Region: item }));
        setUserPreferences(filteredArr);
    };

    const handleRouteChange = (arr) => {
        let filteredArr = userPreferences.filter((item) => !item.hasOwnProperty("Route"));
        arr.forEach((item) => filteredArr.push({ Route: item }));
        setUserPreferences(filteredArr);
    };

    const submitUserSelection = () => {
        const arr = [];
        userPreferences.forEach((item) => {
            arr.push({
                categoryId: modalOptions[_.keys(item)[0]].id,
                option: item[_.keys(item)[0]],
            });
        });
        dispatch(setUserSurveyChoices(arr));
        onClose();
        notification.success({
            message: "Thank you",
            description: "We've saved your choices",
        });
    };

    const highlightSelectedTags = (category, selection) => {
        return userPreferences.filter((item) => item.hasOwnProperty(category) && item[category] === selection).length > 0;
    };

    const renderCategories = (arr) => {
        return arr.map((item, index) => {
            if (item !== "Region" && item !== "Route") {
                return (
                    <div className={`new-user-modal__container ${index + 1 === categoryOptions.length ? "new-user-modal__container--border" : ""}`}>
                        <h3 className="new-user-modal__divider">{item}(s)</h3>
                        <ul
                            className="new-user-modal__container--tag"
                            style={{ marginBottom: "25px" }}>
                            {modalOptions[item].options.map((option, idx) => {
                                return (
                                    <li
                                        key={idx}
                                        className={`new-user-modal__tag ${highlightSelectedTags(item, option) ? "new-user-modal__tag--highlight" : ""}`}
                                        onClick={() => handleTagSelection(item, option)}>
                                        {option}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }
        });
    };

    const renderRegions = (obj) => {
        return obj["Region"].options.map((item, index) => {
            return (
                <Option
                    value={item}
                    key={index}
                    style={{ color: "#2c2f45" }}>
                    {item}
                </Option>
            );
        });
    };

    const renderRoutes = routeOptions.sort().map((item, index) => {
        return (
            <Option
                value={item}
                key={index}
                style={{ color: "#2c2f45" }}>
                {item}
            </Option>
        );
    });

    return (
        <Modal
            className="new-user-modal"
            title="User Preferences"
            visible={true}
            onCancel={skipModal}
            width={750}
            footer={[
                <div className="new-user-modal__footer">
                    <p className="new-user-modal__text">We'll use this information to customise the data you see.</p>
                    <div>
                        <Button
                            key="skip"
                            className="new-user-modal__btn"
                            onClick={skipModal}>
                            Skip
                        </Button>
                        <Button
                            key="submit"
                            className="new-user-modal__btn"
                            disabled={userPreferences.length === 0}
                            type="primary"
                            onClick={submitUserSelection}>
                            Submit
                        </Button>
                    </div>
                </div>,
            ]}>
            {categoryOptions.includes("Region") && (
                <div className="new-user-modal__container new-user-modal__container--border">
                    <h3 className="new-user-modal__divider">{`Regions${categoryOptions.includes("Route") ? " and Routes" : ""}`}</h3>
                    <Select
                        mode="multiple"
                        className="new-user-modal__select--top"
                        placeholder="Select your regions"
                        maxTagCount={4}
                        onChange={(e) => handleRegionChange(e)}
                        value={userPreferences.filter((item) => item.hasOwnProperty("Region")).map((item) => item["Region"])}
                        allowClear
                        notFoundContent={<p style={{ fontSize: "12px" }}>We didn't find anything matching your search...</p>}
                        showArrow>
                        {modalOptions.hasOwnProperty("Region") && renderRegions(modalOptions)}
                    </Select>
                    {categoryOptions.includes("Route") && (
                        <Select
                            mode="multiple"
                            className="new-user-modal__select--bottom"
                            placeholder="Select your routes"
                            disabled={userPreferences.filter((item) => item.hasOwnProperty("Region")).length === 0}
                            maxTagCount={4}
                            onChange={(e) => handleRouteChange(e)}
                            value={userPreferences.filter((item) => item.hasOwnProperty("Route")).map((item) => item["Route"])}
                            allowClear
                            notFoundContent={<p style={{ fontSize: "12px" }}>We didn't find anything matching your search...</p>}
                            showArrow>
                            {renderRoutes}
                        </Select>
                    )}
                </div>
            )}
            {renderCategories(categoryOptions)}
        </Modal>
    );
};

export default NewUserModal;
