import React, { useEffect, useState, useMemo } from "react";
import { getTrackGeomHeaders, getTrackGeomData, routeSelected, receiveTrackGeomHeaders, receiveTrackGeomData } from "../../redux/actions/index";
import { useSelector, useDispatch } from "react-redux";
import Chart from "./Chart";
import { Select } from "antd";
import _ from "lodash";
import LoadingOverlay from "../util/LoadingOverlay";
import { findIndexAtDistance } from "components/util/Geometry";
import { videoKeyToTimestamp, absoluteTimeLookup, getPositionAndOffset } from "components/util/PlaylistUtils";
import ELRMileAndChain from "../util/ELRMileAndChain";

const { Option } = Select;

const geomHeadersSelector = (state) => state.trackGeometry.trackGeometryHeaders;
const geometryDataSelector = (state) => state.trackGeometry.data;
const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
const headerIDsSelector = (state) => state.trackGeometry.headerIDs;
const videoSelector = (state) => {
    return _.get(state.playlist.data, ["video"], []);
};

const getTimestampForIndex = (video, index) => {
    if (!video || !video.length) {
        return 0;
    }
    const videoKey = video[index][0];
    const timestamp = videoKeyToTimestamp(videoKey);
    return timestamp;
};

const ChartContainer = () => {
    const dispatch = useDispatch();

    const [selectedDataTypes, setSelectedDataTypes] = useState([]);
    const [initialised, setInitialised] = useState(false);
    const [zoom, setZoom] = useState(0);
    const [gettingData, setGettingData] = useState(false);
    const [dataPresent, setDataPresent] = useState(false);
    const [headersRouteID, setHeadersRouteID] = useState(null);
    const [clickedTimestamp, setClickedTimestamp] = useState(0);
    const [chartType, setChartType] = useState("area");

    const [startRequested, setStartRequested] = useState(0);
    const [endRequested, setEndRequested] = useState(0);
    const [dataOffsetFromStart, setDataOffsetFromStart] = useState(0);

    const geomHeaders = useSelector(geomHeadersSelector);
    const geometryData = useSelector(geometryDataSelector);
    const dashboardData = useSelector(dashboardDataSelector);
    const video = useSelector(videoSelector);
    const headerIDs = useSelector(headerIDsSelector);

    const currentVideo = useMemo(() => {
        return _.get(video, [dashboardData.position.sourceIndex], []);
    }, [video, dashboardData.position.sourceIndex]);

    useEffect(() => {
        setClickedTimestamp(0);
    }, [dashboardData.position.timestamp]);

    const handleSelect = (e) => {
        console.log("selected data types:", e);
        setSelectedDataTypes(e);
    };

    const currentDashboardTimestamp = useMemo(() => {
        const timestamp = _.get(dashboardData, ["position", "timestamp"], 0);
        return timestamp;
    }, [dashboardData]);

    const distanceToDisplay = useMemo(() => {
        let distanceToDisplay = 1000;
        switch (zoom) {
            case 1:
                // 500m
                distanceToDisplay = 500;
                break;

            case 2:
                // 100m
                distanceToDisplay = 100;
                break;

            case 3:
                // 50m
                distanceToDisplay = 50;
                break;

            case 4:
                // 10m
                distanceToDisplay = 10;
                break;

            default:
                break;
        }

        return distanceToDisplay;
    }, [zoom]);

    const orderedData = useMemo(() => {
        return _.orderBy(geometryData, "timestamp");
    }, [geometryData]);

    const pagingTimestamps = useMemo(() => {
        if (!currentVideo || !currentVideo.length) {
            return [0, 0];
        }
        const { playlistIndex, timeOffset } = getPositionAndOffset(dashboardData.position.timestamp / 1000, currentVideo, false);

        const currentIndex = playlistIndex;
        const currentOffset = timeOffset;
        if (currentIndex === undefined) {
            return [0, 0];
        }

        let [startIndex, startOffset] = findIndexAtDistance(distanceToDisplay / 2, currentVideo, currentIndex, currentOffset, [], false, false);
        let [endIndex, endOffset] = findIndexAtDistance(distanceToDisplay / 2, currentVideo, currentIndex, currentOffset, [], false, true);

        if (startIndex === currentIndex) {
            startIndex = Math.max(currentIndex - 1, 0);
        }
        if (endIndex === currentIndex) {
            endIndex = Math.min(currentIndex + 1, currentVideo.length - 1);
        }

        const startTimestamp = getTimestampForIndex(currentVideo, startIndex) + startOffset * 1000;
        const endTimestamp = getTimestampForIndex(currentVideo, endIndex) + endOffset * 1000;

        return [startTimestamp, endTimestamp];
    }, [currentVideo, dashboardData.position.timestamp, distanceToDisplay]);

    const dataToDisplay = useMemo(() => {
        if (!orderedData || !orderedData.length) {
            return [];
        }
        const [startTimestamp, endTimestamp] = pagingTimestamps;

        const dataBefore = orderedData.filter((point) => {
            return point.timestamp >= startTimestamp && point.timestamp < currentDashboardTimestamp;
        });

        let lengthBefore = dataBefore.length;

        const dataAfter = orderedData.filter((point) => {
            return point.timestamp > currentDashboardTimestamp && point.timestamp <= endTimestamp;
        });
        let lengthAfter = dataAfter.length;

        let shouldPadStart = true;
        let pointIndexInAllData = 0;
        if (dataBefore.length) {
            const firstPoint = dataBefore[0];
            pointIndexInAllData = _.findIndex(orderedData, { timestamp: firstPoint.timestamp });
            if (pointIndexInAllData !== 0) {
                shouldPadStart = false;
            }
        }

        let startPadding = [];

        if (lengthBefore < lengthAfter && shouldPadStart) {
            const distanceMissing = lengthAfter - lengthBefore;
            let startingTacho = _.get(dataBefore, [0, "tacho"], null);
            if (startingTacho === null) {
                startingTacho = dataAfter[0].tacho;
            }

            startPadding = new Array(distanceMissing).fill(null).map((_, idx) => {
                return {
                    timestamp: 1,
                    tacho: startingTacho - idx,
                    padding: true,
                };
            });
        }

        setDataOffsetFromStart(pointIndexInAllData - startPadding.length);

        let endPadding = [];
        let lastPointIndexInAllData = -1;
        let lastPoint = dataBefore[dataBefore.length - 1];
        if (dataAfter.length) {
            lastPoint = dataAfter[dataAfter.length - 1];
            lastPointIndexInAllData = _.findIndex(orderedData, { timestamp: lastPoint.timestamp });
        }

        if (lastPointIndexInAllData < 0 || lastPointIndexInAllData === orderedData.length - 1) {
            if (lengthAfter < lengthBefore) {
                const distanceMissing = lengthBefore - lengthAfter;
                endPadding = new Array(distanceMissing).fill(null).map((_, idx) => {
                    return {
                        timestamp: 9999999999999,
                        tacho: lastPoint.tacho + (idx + 1),
                        padding: true,
                    };
                });
            }
        }

        const data = startPadding.concat(dataBefore, dataAfter, endPadding);
        return data;
    }, [orderedData, pagingTimestamps, currentDashboardTimestamp]);

    const chunkedData = useMemo(() => {
        const points = [];
        let chunkCount = 50;
        switch (zoom) {
            case 1:
                // 500m
                chunkCount = 25;
                break;

            case 2:
                // 100m
                chunkCount = 5;
                break;

            case 3:
                // 50m
                chunkCount = 1;
                break;

            // case 4:
            //     // 10m
            //     chunkCount = 1
            //     break;

            default:
                break;
        }

        for (let i = 0 - (dataOffsetFromStart % chunkCount); i < dataToDisplay.length; i += chunkCount) {
            const startIdx = Math.max(0, i);
            const endIdx = Math.min(dataToDisplay.length - 1, i + chunkCount);
            const chunk = dataToDisplay.slice(startIdx, endIdx);

            const point = {
                tacho: _.meanBy(chunk, "tacho"),
                timestamp: _.meanBy(chunk, "timestamp"),
                speed_ms: _.meanBy(chunk, "speed_ms"),
            };

            let location = null;
            if (chunk[0]) {
                location = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: chunk[0].elr,
                        MILE: chunk[0].miles,
                        TRACK: chunk[0].trid,
                        YARDS: chunk[0].yards,
                    },
                    [],
                    true,
                );
            }
            point.location = location;

            selectedDataTypes.forEach((dataType) => {
                if (chartType === "minMax") {
                    let maxValue = _.maxBy(chunk, dataType);
                    let minValue = _.minBy(chunk, dataType);

                    // TODO need to use centre value to calc min/max
                    if (!_.isNil(maxValue) && !_.isNil(minValue)) {
                        if (Math.abs(maxValue[dataType]) > Math.abs(minValue[dataType])) {
                            point[dataType] = maxValue[dataType];
                        } else {
                            point[dataType] = minValue[dataType];
                        }
                    } else {
                        if (!_.isNil(maxValue)) {
                            point[dataType] = maxValue[dataType];
                        }
                        if (!_.isNil(minValue) && chunk.length > 1) {
                            point[dataType] = minValue[dataType];
                        }
                    }
                }

                if (chartType === "area") {
                    let min = null;
                    let max = null;

                    const maxValue = _.maxBy(chunk, dataType);
                    if (!_.isNil(maxValue)) {
                        max = maxValue;
                    }
                    const minValue = _.minBy(chunk, dataType);
                    if (!_.isNil(minValue) && chunk.length > 1) {
                        min = minValue;
                    } else {
                        if (!_.isNil(chunk[0])) {
                            min = chunk[0];
                        }
                    }

                    let dataPoints = [null, null];
                    if (min && min[dataType] && max && max[dataType]) {
                        dataPoints = [min[dataType], max[dataType]];
                    }

                    point[dataType] = dataPoints;
                    point[`${dataType}_average`] = _.meanBy(chunk, dataType);
                }

                if (chartType === "average") {
                    point[dataType] = _.meanBy(chunk, dataType);
                }
            });

            points.push(point);
        }

        //It is important that there are no points with the same tacho count or the reference line will disappear for some reason
        const ordered = _.orderBy(_.uniqBy(points, "tacho"), "timestamp");
        if (ordered.length % 2 !== 0) {
            ordered.pop();
        }
        return ordered;
    }, [dataToDisplay, zoom, chartType, selectedDataTypes, dataOffsetFromStart]);

    useEffect(() => {
        dispatch(routeSelected(null));
        dispatch(receiveTrackGeomData([]));
        dispatch(receiveTrackGeomHeaders([], [], [], null));
        setSelectedDataTypes([]);
        setZoom(0);
        setInitialised(false);
    }, [dashboardData.routeID, dispatch]);

    useEffect(() => {
        if (dashboardData.routeID && !initialised && currentDashboardTimestamp > 0) {
            dispatch(routeSelected(dashboardData.routeID, 0));
        }
    }, [dispatch, dashboardData.routeID, currentDashboardTimestamp, initialised]);

    useEffect(() => {
        if (dashboardData.routeID && dashboardData.routeID !== headersRouteID) {
            dispatch(getTrackGeomHeaders(dashboardData.routeID)).then(([headers, _]) => {
                setHeadersRouteID(dashboardData.routeID);
                if (headers) {
                    const defaultHeaders = headers
                        .filter((header) => {
                            return header.info.default;
                        })
                        .map((header) => header.header_name);

                    setSelectedDataTypes(defaultHeaders);
                }

                if (!headers || !headers.length) {
                    setInitialised(true);
                }
            });
        }
    }, [dashboardData.routeID, dispatch, headersRouteID]);

    useEffect(() => {
        if (!gettingData && currentVideo.length && dashboardData.routeID === headersRouteID) {
            const unpaddedData = dataToDisplay.filter((dp) => !dp.padding);

            let lastPointIndexInAllData = -1;
            let lastPoint = null;
            if (unpaddedData.length) {
                lastPoint = unpaddedData[unpaddedData.length - 1];
                lastPointIndexInAllData = _.findIndex(orderedData, { timestamp: lastPoint.timestamp });
            }

            if (lastPointIndexInAllData < 0 || lastPointIndexInAllData > orderedData.length - 500) {
                let lastTimestamp = lastPoint?.timestamp;
                if (!lastTimestamp) {
                    lastTimestamp = currentDashboardTimestamp;
                }

                const centreIndex = absoluteTimeLookup(lastTimestamp / 1000, currentVideo);

                const [startIndex] = findIndexAtDistance(1000, currentVideo, centreIndex, dashboardData.position.currentTimeOffset, [], false, false);
                const [endIndex] = findIndexAtDistance(1000, currentVideo, centreIndex, dashboardData.position.currentTimeOffset, [], false, true);

                const startTimestamp = getTimestampForIndex(currentVideo, startIndex);
                const endTimestamp = getTimestampForIndex(currentVideo, endIndex);

                if (headerIDs.length && (endTimestamp !== endRequested || startTimestamp !== startRequested)) {
                    setGettingData(true);
                    setStartRequested(startTimestamp);
                    setEndRequested(endTimestamp);

                    dispatch(getTrackGeomData(dashboardData.routeID, startTimestamp, endTimestamp)).then((data) => {
                        setInitialised(true);
                        setGettingData(false);
                        if (data) {
                            setDataPresent(true);
                        }
                    });
                }
            }
        }
    }, [
        currentDashboardTimestamp,
        currentVideo,
        dashboardData.position.currentTimeOffset,
        dataToDisplay,
        dispatch,
        gettingData,
        orderedData,
        video,
        headerIDs,
        dashboardData.routeID,
        headersRouteID,
        startRequested,
        endRequested,
    ]);

    if (!initialised) {
        return <LoadingOverlay loading={true} />;
    }

    let content = null;

    if (!dataPresent) {
        content = (
            <div className="widget__Info center">
                <p>No data for this session.</p>
            </div>
        );
    } else {
        if (selectedDataTypes && selectedDataTypes.length) {
            content = selectedDataTypes.map((dataType, idx) => (
                <Chart
                    chartType={chartType}
                    dataType={dataType}
                    zoom={zoom}
                    data={dataToDisplay}
                    key={dataType}
                    clickedTimestamp={clickedTimestamp}
                    setClickedTimestamp={setClickedTimestamp}
                    loading={gettingData}
                    first={idx === 0}
                    last={idx === selectedDataTypes.length - 1}
                    chunkedData={chunkedData}
                />
            ));
        } else {
            content = (
                <div className="widget__Info">
                    <p>Select measurement types above to populate the window</p>
                </div>
            );
        }
    }

    return (
        <div className="widget__Chart__Container">
            <div className="widget__Chart__Container__Controls">
                <Select
                    onChange={handleSelect}
                    value={selectedDataTypes}
                    mode="multiple"
                    allowClear
                    placeholder="Please select">
                    {geomHeaders &&
                        geomHeaders.map((option, index) => (
                            <Option
                                key={index}
                                value={option.header_name}>
                                {_.get(option, "info.display_name", option.name)}
                            </Option>
                        ))}
                </Select>

                <div className="widget__Chart__Container__Controls__Zoom">
                    <p>Chart Type:</p>
                    <Select
                        value={chartType}
                        onChange={(value) => setChartType(value)}
                        className="ChartTypeSelect">
                        <Option value="minMax">Line Min / Max</Option>
                        <Option value="average">Line Average</Option>
                        <Option value="area">Area Min / Max / Average</Option>
                    </Select>
                </div>
                <div className="widget__Chart__Container__Controls__Zoom">
                    <p>Range:</p>
                    <Select
                        value={zoom}
                        onChange={(value) => setZoom(value)}>
                        <Option value={0}>1km</Option>
                        <Option value={1}>500m</Option>
                        <Option value={2}>100m</Option>
                        <Option value={3}>50m</Option>
                        {/* <Option value={4}>10m </Option> */}
                    </Select>
                </div>
            </div>
            <div className="widget__Chart__Container__Disclaimer">
                <p>
                    <b>Track geometry data is in an evaluation phase</b>
                </p>
            </div>
            {content}
        </div>
    );
};

export default ChartContainer;
