import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { routeSelected } from "../../../../redux/actions/index";
import { Button } from "antd";
import moment from "moment";
import { binarySearch } from "components/util/PlaylistUtils";

const routeIDSelector = (state) => state.playlist.data.routeID;
const currentImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const railImagesSelector = (state) => state.railInspection.railInspectionImages.data;

const Event = ({ event }) => {
    const dispatch = useDispatch();

    const routeID = useSelector(routeIDSelector);
    const currentImage = useSelector(currentImageSelector);
    const railImages = useSelector(railImagesSelector);

    const isEventActive = useCallback(
        (area) => {
            let active = false;

            if (currentImage.timestamp / 1000 > area.start && currentImage.timestamp / 1000 < area.end) {
                active = true;
            }

            return active;
        },
        [currentImage.timestamp],
    );

    const navigate = useCallback(
        (timestamp) => {
            dispatch(routeSelected(routeID, timestamp));
        },
        [dispatch, routeID],
    );

    const eventLength = useMemo(() => {
        if (!event.start || !event.end) {
            return null;
        }
        const startIndex = binarySearch(event.start * 1000, railImages, (image) => image.timestamp);
        const endIndex = binarySearch(event.end * 1000, railImages, (image) => image.timestamp);

        return endIndex - startIndex;
    }, [event.end, event.start, railImages]);

    return (
        <div
            key={event.start}
            className={`inspectRail__BookmarkList__Item ${isEventActive(event) ? " active" : ""}`}>
            <div className="EventInfo">
                <div className="Top">
                    <p className="Type">{event.type}</p>
                    <p className="Time">{moment.unix(event.start).format("LTS")}</p>
                </div>
                <div className="Bottom">{eventLength && <p className="Length">{eventLength}M</p>}</div>
            </div>

            <div className="EventControls">
                <Button
                    onClick={() => navigate(event.start + 0.1)}
                    type="primary">
                    Jump to start
                </Button>

                <Button
                    onClick={() => navigate(event.end)}
                    type="primary">
                    Jump to End
                </Button>
            </div>
        </div>
    );
};

const EventsNavigation = ({ sessionAreasOfInterest }) => {
    const content = useMemo(() => {
        let body = (
            <div className="inspectRail__BookmarkList">
                {sessionAreasOfInterest &&
                    sessionAreasOfInterest.map((item, index) => {
                        return <Event event={item} />;
                    })}
            </div>
        );

        if (!sessionAreasOfInterest.length) {
            body = (
                <div className="inspectRail__Detections__Message">
                    <span>No Events in this session</span>
                </div>
            );
        }

        return body;
    }, [sessionAreasOfInterest]);

    return content;
};

export default EventsNavigation;
