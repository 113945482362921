import { notification } from "antd";
import _ from "lodash";
import { MEMOIZED_API_BASE_URL } from "../../components/util/HostUtils";

export const LOGOUT = "LOGOUT";

function logoutUser() {
    return {
        type: LOGOUT,
        logoutType: "forced",
    };
}

function getResponseErrorMessage(response) {
    console.log("Got response object:", response, response.message);
    return new Promise((resolve) => {
        if (response instanceof TypeError) {
            resolve(response.message);
        } else if (response instanceof Response) {
            response.json().then(
                (responseJSON) => {
                    if (responseJSON.message) {
                        resolve(responseJSON.message);
                    } else {
                        resolve(JSON.stringify(responseJSON));
                    }
                },
                () => {
                    response.text().then(
                        (responseText) => {
                            resolve(responseText);
                        },
                        (error) => {
                            resolve(error.message);
                        },
                    );
                },
            );
        } else {
            console.log("debug errore mesage in here", response);
            resolve(JSON.stringify(response));
        }
    });
}

export function handleJsonPostError(title, message, errorPromise) {
    if (_.hasIn(errorPromise, "then")) {
        errorPromise.then((errorMessage) => {
            if (errorMessage === "force_logout") {
                return;
            }
            notification.error({
                message: title,
                description: `${message}: ${errorMessage}`,
            });
            console.log(errorMessage);
        });
    } else {
        notification.error({
            message: title,
            description: `${message}: ${errorPromise}`,
        });
        console.log(errorPromise);
    }
}

export function jsonPostV2(path, state, postBody, dispatch, catchError = true, abortSignal = null) {
    // this version of jsonPost will throw an error if the response
    // has an error status code, whereas the original version would only
    // error in the event the request could not be completed (eg: a network error).
    // It also supports detailed error responses by returning the response object
    // to the calling code, without assuming anything about it's format

    let BASE_URL = MEMOIZED_API_BASE_URL;

    if (state.shareLink) {
        postBody = _.extend(
            {
                share_link: state.shareLink,
            },
            postBody,
        );
    } else {
        postBody = _.extend(
            {
                workspace_key: state.access_token,
            },
            postBody,
        );

        if (state.access_token && state.access_token.startsWith("ws_")) {
            postBody = _.extend(
                {
                    workspace_id: _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "workspace_id"], null),
                },
                postBody,
            );
        }
    }

    if (state.csrfToken) {
        postBody = _.extend(
            {
                csrf: state.csrfToken,
            },
            postBody,
        );
    }

    return fetch(BASE_URL + path, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            ...(state.widget_key && { "X-AIVR-Widget-Key": `"${state.widget_key}"` }),
        },
        body: JSON.stringify(postBody),
        signal: abortSignal,
    })
        .then((response) => {
            if (!response.ok) {
                // we throw an error, but return the response object to the calling
                // code in case there is useful data in the body, making no assumptions
                // about whether the body will be JSON
                return Promise.reject(response);
            } else {
                return response.json();
            }
        })
        .catch((error) => {
            if (error.name === "AbortError") {
                return {};
            }

            if (catchError) {
                throw Promise.resolve(
                    getResponseErrorMessage(error).then((errorMessage) => {
                        if (errorMessage === "You are not logged in.") {
                            console.log("Logging out", errorMessage);
                            dispatch(logoutUser());
                            return "force_logout";
                        }
                        return errorMessage;
                    }),
                );
            } else {
                throw error;
            }
        });
}
