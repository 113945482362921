import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { createCustomIcon } from "./Icons";
import _ from "lodash";
import { LazyMapTooltip } from "../util/LazyTooltips";
import { notification } from "antd";
import { nearestPointOnGeometry } from "../util/Geometry";
import { selectMapPoint } from "redux/actions/index";

class StationMakers extends React.PureComponent {
    renderMarkerTooltip = (station) => {
        // const fields = <tr key={key}>
        //                         <td className={"Key"}>{key}:</td><td className={"Value"}>{value}</td>
        //                     </tr>;
        const extraDataPanel = (
            <table className={"MarkerTooltipAdditionalInfo"}>
                <tbody>
                    <tr key={"name"}>
                        <td className={"name"}>Station:</td>
                        <td className={"Value"}>{station.name}</td>
                    </tr>
                    <tr key={"location"}>
                        <td className={"name"}>Location:</td>
                        <td className={"Value"}>{station.location.join(", ")}</td>
                    </tr>
                </tbody>
            </table>
        );

        return <div className={"MarkerTooltipOuter"}>{extraDataPanel}</div>;
    };

    iconClick = (station) => {
        const closestPoint = nearestPointOnGeometry(station.location[0], station.location[1], this.props.geometry);
        if (closestPoint) {
            this.openVideo(closestPoint);
        } else {
            notification.error({
                message: "No nearby session found",
            });
        }
    };

    openVideo = (closestPoint) => {
        console.log("Closest point: ", closestPoint);
        const closestPointID = closestPoint.point[0];
        this.props.dispatch(selectMapPoint(closestPointID, closestPoint.coordinates, this.props.isStills));
    };

    renderStation = (station) => {
        if (_.isEmpty(station)) {
            return null;
        }

        const stationIcon = createCustomIcon("train", "#ff0000");

        return (
            <Marker
                onClick={() => this.iconClick(station)}
                key={"Marker" + station.name}
                position={station.location}
                icon={stationIcon}>
                <LazyMapTooltip
                    className="Incident-Tooltip"
                    permanent={false}
                    interactive={true}
                    direction={"top"}>
                    {this.renderMarkerTooltip(station)}
                </LazyMapTooltip>
            </Marker>
        );
    };

    render() {
        let station = this.props.station;

        return <LayerGroup>{this.renderStation(station)}</LayerGroup>;
    }
}

const mapStateToProps = (state) => {
    return {
        station: state.assets.displayingStation,
        geometry: state.mapGeometry,
        isStills: state.playlist.position.isStills,
    };
};

export default connect(mapStateToProps)(StationMakers);
