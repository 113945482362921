import React from "react";
import { connect } from "react-redux";
import { drawLine, getHeading, getNextRouteCoordinates, worldToImageCoordinates } from "../../../util/Geometry";
import _ from "lodash";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";

class RouteOverlay extends React.PureComponent {
    render() {
        const playlist = this.props.video;

        if (!playlist || this.props.currentPlaylistIndex === null || !this.props.calibration) {
            return null;
        }

        let playlistIndex = this.props.currentPlaylistIndex;
        const timeOffset = this.props.timeOffset;

        let trainAngle = getHeading(playlist, playlistIndex, timeOffset, this.props.offsets, this.props.use_snapped, this.props.sessionIsBackward);
        let routeCoordinates = getNextRouteCoordinates(playlist, playlistIndex, timeOffset, this.props.offsets, this.props.use_snapped, 500);

        const cosTrainAngle = Math.cos(trainAngle);
        const sinTrainAngle = Math.sin(trainAngle);

        let lines = [];
        let i = 0;
        const halfRailWidth = this.props.calibration.railSeparation / 2;
        let lastLine1Pos = worldToImageCoordinates([-halfRailWidth, 0, 0], this.props.calibration);
        let lastLine2Pos = worldToImageCoordinates([halfRailWidth, 0, 0], this.props.calibration);
        routeCoordinates.forEach((coordinate) => {
            const pointZOffset = cosTrainAngle * coordinate[0] + sinTrainAngle * coordinate[1];
            const pointXOffset = sinTrainAngle * coordinate[0] - cosTrainAngle * coordinate[1];
            let line1Pos = worldToImageCoordinates([pointXOffset - halfRailWidth, 0, pointZOffset], this.props.calibration);
            let line2Pos = worldToImageCoordinates([pointXOffset + halfRailWidth, 0, pointZOffset], this.props.calibration);
            lines.push(drawLine(lastLine1Pos, line1Pos, "Line1." + i, "Rail"));
            lines.push(drawLine(lastLine2Pos, line2Pos, "Line2." + i, "Rail"));
            lastLine1Pos = line1Pos;
            lastLine2Pos = line2Pos;
            i += 1;
        });

        let viewWidth = 1000;
        let viewHeight = Math.floor(viewWidth * this.props.calibration.aspectRatio);
        let viewBox = `0 0 ${viewWidth} ${viewHeight}`;

        return (
            <svg
                className={"MeasurementOverlay Unclickable " + this.props.className}
                viewBox={viewBox}
                preserveAspectRatio={"xMidYMid meet"}>
                {lines}
            </svg>
        );
    }
}

const mapStateToProps = (state) => {
    let sourceIndex = state.playlist.position.sourceIndex;

    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }

    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }

    return {
        calibration: get_source_calibration(
            state.measurement.calibration,
            sourceIndex,
            getAbsoluteTimestamp(state.playlist) * 1000,
            state.userDetails.userConfig.super_admin,
            defaultRailSeparation,
        ),
        video: _.get(state.playlist.data, ["video", sourceIndex]),
        timeOffset: state.playlist.position.currentTimeOffset || 0,
        offsets,
        use_snapped: state.snappedRoute || false,
        currentPlaylistIndex: state.playlist.position.currentIndex,
        sessionIsBackward,
    };
};

export default connect(mapStateToProps)(RouteOverlay);
