import { notification } from "antd";
import { jsonPostV2 } from "./apiUtils";

export const GET_FUGRO_DATA = "GET_FUGRO_DATA";

export function fetchNearbyFugroData(callback) {
    return {
        queue: GET_FUGRO_DATA,

        callback: (next, dispatch, getState) => {
            const bounds = getState().sessionFilters.mapBounds.current;

            const lat = bounds.north - Math.abs(bounds.north - bounds.south);
            const lon = bounds.east - Math.abs(bounds.east - bounds.west);
            let postBody = {
                action: "get_fugro_nearby_data_point",
                lat: lat,
                lon: lon,
            };
            const url = "/mapGeometry";

            if (bounds) {
                jsonPostV2(url, getState(), postBody, dispatch)
                    .then((response) => {
                        if (response.success) {
                            if (callback) {
                                callback(response);
                            }
                        } else {
                            notification.error({
                                message: "Error occurred while fetching data from Fugro API",
                            });
                        }
                        next();
                    })
                    .catch((error) => {
                        console.log("debug error", error);
                        notification.error({
                            message: "Error occurred while fetching data from Fugro API",
                        });
                        if (callback) {
                            callback(false);
                        }
                        next();
                    });
            } else {
                console.log("debug error, map bounds not available");
                next();
            }
        },
    };
}
