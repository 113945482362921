import ReactDOM from "react-dom";
import videojs from "video.js";
import React, { useMemo, useState } from "react";
import { connect, Provider } from "react-redux";
import store from "../../../redux/store";
import { LatLonInfo, SystemCoordinates, PositionalInfo, Datum } from "../../route/RouteInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { WhatThreeWords } from "../../route/RouteInformation";

const Component = videojs.getComponent("Component");
const dom = videojs.dom || videojs;
const registerPlugin = videojs.registerPlugin || videojs.plugin;

const RouteInformationComponent = () => {
    const [isHidden, setHidden] = useState(false);
    const [coordSystem, setCoordSystem] = useState("elr");

    const toggleContent = useMemo(() => {
        if (isHidden) {
            return (
                <>
                    Show Route Information&nbsp;
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size={"2x"}
                    />
                </>
            );
        } else {
            return (
                <>
                    Hide Route Information&nbsp;
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        size={"2x"}
                    />
                </>
            );
        }
    }, [isHidden]);

    return (
        <div className="HUDContainer">
            <div className="DisplayWrapper">
                <div className={isHidden ? "RouteInformationContainer collapsed-route-info" : "RouteInformationContainer"}>
                    <div className="RouteInformation">
                        <PositionalInfo hud={true} />
                        <div className="OverlayDivider" />
                        <div className="CoordSelect">
                            <div className="SelectButtonContainer">
                                <div
                                    className={coordSystem === "latlon" ? "OverlayButton active" : "OverlayButton"}
                                    onClick={() => {
                                        setCoordSystem("latlon");
                                    }}>
                                    lat/lon
                                </div>
                                <div
                                    className={coordSystem === "osgb" ? "OverlayButton active" : "OverlayButton"}
                                    onClick={() => {
                                        setCoordSystem("osgb");
                                    }}>
                                    OSGB
                                </div>
                                <div
                                    className={coordSystem === "elr" ? "OverlayButton active" : "OverlayButton"}
                                    onClick={() => {
                                        setCoordSystem("elr");
                                    }}>
                                    ELR
                                </div>
                            </div>
                            {coordSystem === "latlon" || coordSystem === "osgb" ? (
                                <LatLonInfo
                                    coordSystem={coordSystem}
                                    hud={true}
                                />
                            ) : null}
                            {coordSystem === "elr" ? (
                                <SystemCoordinates
                                    classes=" SystemCoords"
                                    hud={true}
                                />
                            ) : null}
                        </div>
                        <div className="OverlayDivider" />
                        <WhatThreeWords hud={true} />
                        <div className="OverlayDivider" />
                        <Datum hud={true} />
                    </div>
                </div>
            </div>
            <div
                className="RouteInformationToggle"
                onClick={() => {
                    setHidden(!isHidden);
                }}>
                {toggleContent}
            </div>
        </div>
    );
};

class ReactInformationOverlay extends React.Component {
    render() {
        return <>{this.props.isFullscreen ? <RouteInformationComponent /> : null}</>;
    }
}

const mapStateToProps = (state) => {
    return {
        isFullscreen: state.fullscreen,
    };
};

const InformationOverlayComponent = connect(mapStateToProps, null, null, { forwardRef: true })(ReactInformationOverlay);
export default InformationOverlayComponent;

class InformationOverlay extends Component {
    constructor(player, options) {
        super(player, options);
        this.loading = false;

        player.on("aivrLoadingStarted", () => {
            console.log("Aivr loading started triggered here");
            this.loading = true;
            this.checkPause();
        });
        player.on("aivrLoadingComplete", () => {
            console.log("Aivr loading complete started triggered here");
            this.loading = false;
            this.checkPause();
        });
    }

    checkPause() {
        try {
            if (!this.loading) {
                this.show();
            } else {
                this.hide();
            }
        } catch (ex) {
            console.log("Unexpected exception:", ex);
        }
    }

    createEl() {
        const el = dom.createEl("div", {
            className: "vjs-information-overlay vjs-hidden",
        });

        this.containerRef = React.createRef();

        ReactDOM.render(
            <Provider store={store}>
                <InformationOverlayComponent
                    player={this.player()}
                    ref={this.containerRef}
                />
            </Provider>,
            el,
        );

        console.log("Container ref: ", this.containerRef);

        return el;
    }

    dispose() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el());
        }
    }

    hide() {
        super.hide();
        if (this.containerRef.current) {
            this.containerRef.current.hide();
        }
    }

    show() {
        super.show();
        if (this.containerRef.current && this.containerRef.current.show) {
            this.containerRef.current.show();
        }
    }
}

videojs.registerComponent("InformationOverlay", InformationOverlay);

const informationOverlayPlugin = function (options) {
    const playerChild = this.addChild("informationOverlay", options);

    this.el().insertBefore(playerChild.el(), this.controlBar.el());

    return playerChild;
};

registerPlugin("informationOverlay", informationOverlayPlugin);
