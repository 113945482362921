import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { findMostRecentMetadata, getAbsoluteTimestamp, getInterpolatedPosition, getOffsetAdjustedTime, keySourceLookup } from "components/util/PlaylistUtils";
import DtImageWithBbox from "./DtImageWithBbox";
import { updateSelectedSeenAnnotationsList } from "redux/actions/driverTrainingActions";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";
import { CheckOutlined, ClockCircleOutlined, StopOutlined } from "@ant-design/icons";

const userAnnotationsSelector = (state) => state.userAnnotations;
const playlistPositionSelector = (state) => state.playlist.position;
const playlistVideoDataSelector = (state) => state.playlist.data.video;
const offsetsSelector = (state) => state.gpsTimeOffsets.offsets;
const snappedRouteSelector = (state) => state.snappedRoute || false;
const selectedSessionSelector = (state) => state.driverTraining.trainee.selectedSession;
const currentPlayerIndexSelector = (state) => state.playlist.position.currentIndex;
const csrfTokenSelector = (state) => state.csrfToken;

// const timestampSelector = state => _.get(state.playlist, ["position", "timestamp"], null)
const timestampSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], EMPTY_ARRAY) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
};

const EMPTY_ARRAY = [];

const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], EMPTY_ARRAY);
const tachoDataSelector = (state) => _.get(state.routeMetadata, ["TACHO_DATA", state.playlist.data.routeID], EMPTY_ARRAY);

const DtAnnotationComponent = () => {
    const dispatch = useDispatch();
    const userAnnotations = useSelector(userAnnotationsSelector);
    const playlistPosition = useSelector(playlistPositionSelector) || null;
    const playlistVideoData = useSelector(playlistVideoDataSelector);
    const selectedSession = useSelector(selectedSessionSelector);
    const selectedSessionSeenData = _.get(selectedSession, ["progress", "seen"], []);
    const selectedSessionProgressData = _.get(selectedSession, ["progress", "completed"], []);
    const selectedSessionSkippedData = _.get(selectedSession, ["progress", "skipped"], []);
    const speedMetadata = useSelector(speedAndBearingSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const tachoMetadata = useSelector(tachoDataSelector);
    const timestamp = useSelector(timestampSelector);
    const [currentSpeed, setCurrentSpeed] = useState(0);

    const offsets = useSelector(offsetsSelector);
    const snappedRoute = useSelector(snappedRouteSelector);
    const currentPlayerIndex = useSelector(currentPlayerIndexSelector);

    const previousValueRef = useRef(currentPlayerIndex);

    const [allUpcomingAnnotationsIDs, setAllUpcomingAnnotationsIDs] = useState([]);
    const [skippedAnnotationsIDs, setSkippedAnnotationsIDs] = useState([]);
    // const [adjustedDistance, setAdjustedDistance] = useState(0.05) // initially start with 50m distance, this will increase with train speed.

    useEffect(() => {
        const valueJumpThreshold = 1; // Set the threshold for value jump here

        // Calculate the difference between the current and previous value
        const valueDifference = Math.abs(currentPlayerIndex - previousValueRef.current);
        console.log("debug4 valueDifference", valueDifference);

        if (valueDifference > valueJumpThreshold) {
            // Call your function here to handle the jump
            handleVideoSkipped(currentPlayerIndex);
        }

        previousValueRef.current = currentPlayerIndex;
    }, [currentPlayerIndex]);

    // this handles when the video possition has been changed to detect if user trying to go through annoations without watching it.
    const handleVideoSkipped = (newValue) => {
        // if newValue is not 0 and nextAnnotation is set then mark as player has been skipped
        resetAnnotationsSeen();
        if (newValue) {
            setSkippedAnnotationsIDs(allUpcomingAnnotationsIDs);
        } else {
            setSkippedAnnotationsIDs([]);
        }
    };

    useEffect(() => {
        console.log("debug5 allUpcomingAnnotationsIDs", allUpcomingAnnotationsIDs);
    }, [allUpcomingAnnotationsIDs]);

    // this will calculate when to display annotation based on train speed and distance from current train position and the annotation location
    const adjustedTriggerDistance = useMemo(() => {
        let speed = _.get(findMostRecentMetadata(tachoMetadata, timestamp), ["data", "speed"]);
        if (speed == null) {
            speed = _.get(findMostRecentMetadata(speedMetadata, timestamp), ["data", "speed"], 0);
        }
        setCurrentSpeed(speed);
        const baseDistance = 0.1; // start with 100m from annotation
        const additionalDistancePerKmPerS = 0.03;
        return baseDistance + (speed - 1) * additionalDistancePerKmPerS;
    }, [speedMetadata, tachoMetadata, timestamp]);

    function calculateDistance(sourceLocation, targetLocation) {
        if (!targetLocation[0] && !targetLocation[1]) {
            return null;
        }
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = ((targetLocation[0] - sourceLocation[0]) * Math.PI) / 180; // Difference in latitude
        const dLon = ((targetLocation[1] - sourceLocation[1]) * Math.PI) / 180; // Difference in longitude

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((sourceLocation[0] * Math.PI) / 180) * Math.cos((targetLocation[0] * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // Distance in kilometers
        return distance;
    }

    const annotationsTrackerList = useMemo(() => {
        let annotationTracker = [];
        let sortedUserAnnotations = _.sortBy(userAnnotations, "videoKey");

        if (userAnnotations && userAnnotations.length) {
            _.forEach(sortedUserAnnotations, (annotation) => {
                let keySource = keySourceLookup(annotation.videoKey, playlistVideoData);
                let playlistIndex = keySource[0];
                let sourceIndex = keySource[1];

                if (playlistIndex !== -1) {
                    let videoTimestamp = playlistVideoData[sourceIndex][playlistIndex][1];
                    let offsetTime = getOffsetAdjustedTime(videoTimestamp, offsets[sourceIndex]);
                    let coords = getInterpolatedPosition(offsetTime, playlistVideoData[sourceIndex], snappedRoute);
                    let url =
                        `https://raw${MEMOIZED_DOMAIN_URL}/` +
                        selectedSession.device_key +
                        "/" +
                        selectedSession.session_key +
                        "/" +
                        annotation.videoKey +
                        "-" +
                        annotation.frame +
                        ".jpg";
                    if (csrfToken) {
                        url += `?csrf=${csrfToken}`;
                    }

                    annotationTracker.push({
                        ...annotation,
                        url: url,
                        coords: [coords[0], coords[1]],
                    });
                }
            });
        }
        return annotationTracker;
    }, [userAnnotations, playlistVideoData, offsets, snappedRoute, selectedSession.device_key, selectedSession.session_key]);

    const resetAnnotationsSeen = () => {
        console.log("debug4 resetting seen annotations... []");

        dispatch(updateSelectedSeenAnnotationsList(EMPTY_ARRAY));
    };

    const updateUpcommingAnntationsIDs = useCallback(
        (newList) => {
            if (!_.isEqual(allUpcomingAnnotationsIDs, newList)) {
                setAllUpcomingAnnotationsIDs(newList);
            }
        },
        [allUpcomingAnnotationsIDs],
    );

    const upcommingAnnotations = useMemo(() => {
        // console.log('debug2 upcommingAnnotationsList', annotationsTrackerList);

        if (playlistVideoData && playlistPosition && playlistPosition.coords && annotationsTrackerList.length) {
            let _nextAnnotation = null;
            let _allUpcomingAnnotationsIDs = [];
            let _upcomingAnnotations = [];
            let isFirst = true;

            _.forEach(annotationsTrackerList, (annotation, index) => {
                if (annotation) {
                    const distance = calculateDistance(annotation.coords, playlistPosition.coords);
                    const annotationSeen = _.includes(selectedSessionSeenData, annotation.id);

                    if (distance < adjustedTriggerDistance && !annotationSeen) {
                        _allUpcomingAnnotationsIDs.push(annotation.id);

                        if (isFirst) {
                            _nextAnnotation = (
                                <DtImageWithBbox
                                    key={annotation.id}
                                    annotation={annotation}
                                    first={index === 0}
                                    distance={distance}
                                    adjustedTriggerDistance={adjustedTriggerDistance}
                                    skippedAnnotationsIDs={skippedAnnotationsIDs}
                                    currentSpeed={currentSpeed}
                                />
                            );
                            isFirst = false;
                        } else {
                            _upcomingAnnotations.push(
                                <DtImageWithBbox
                                    key={annotation.id}
                                    annotation={annotation}
                                    first={index === 0}
                                    distance={distance}
                                    adjustedTriggerDistance={adjustedTriggerDistance}
                                    skippedAnnotationsIDs={skippedAnnotationsIDs}
                                    currentSpeed={currentSpeed}
                                />,
                            );
                        }
                    }
                }
            });
            updateUpcommingAnntationsIDs(_allUpcomingAnnotationsIDs);

            return (
                <>
                    <div className="UpcomingAnnotationsGridItem UpcomingAnnotationsGridItemMain">{_nextAnnotation}</div>
                    <div className="UpcomingAnnotationsGridItem">{_upcomingAnnotations}</div>
                </>
            );
        }
    }, [
        adjustedTriggerDistance,
        annotationsTrackerList,
        playlistPosition,
        playlistVideoData,
        selectedSessionSeenData,
        skippedAnnotationsIDs,
        updateUpcommingAnntationsIDs,
    ]);

    const listOfSessionAnnotations = useMemo(() => {
        return _.map(annotationsTrackerList, (annotation) => {
            const isCompleted = _.includes(selectedSessionProgressData, String(annotation.id));
            const wasSkipped = _.includes(selectedSessionSkippedData, annotation.id);

            if (annotation) {
                return (
                    <div className={`AnnotationListItem`}>
                        <div className="AnnotationText">{annotation.custom_tag}</div>
                        <div className="AnnotationListItemBadge">
                            {!isCompleted ? (
                                wasSkipped ? (
                                    <StopOutlined style={{ fontSize: "16px", color: "#df4343" }} />
                                ) : (
                                    <ClockCircleOutlined style={{ fontSize: "16px", color: "#fa8c16" }} />
                                )
                            ) : (
                                <CheckOutlined style={{ fontSize: "16px", color: "#52c41a" }} />
                            )}
                        </div>
                    </div>
                );
            }
        });
    }, [annotationsTrackerList, selectedSessionProgressData, selectedSessionSkippedData]);

    useEffect(() => {
        console.log("debug4 annotationsTrackerList", annotationsTrackerList);
    }, [annotationsTrackerList]);

    return (
        <>
            <div className="AnnotationsContainer">
                <h4>Upcoming annotation:</h4>
                <div className="AnnotationsContainerMain">
                    <div className="UpcomingAnnotations">
                        <div className="UpcomingAnnotationsGrid">{upcommingAnnotations}</div>
                    </div>
                    <div className="ListOfAnnotations">{listOfSessionAnnotations}</div>
                </div>
            </div>
        </>
    );
};

export default DtAnnotationComponent;
