import React from "react";
import "../../../aivr.scss";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout, targetResource, routeSelected, toggleMarkerInfoShowing } from "redux/actions";
import _ from "lodash";

import { Modal } from "antd";
import ExpiryBanner from "../../ExpiryBanner";
import SessionsComponent from "../../SessionsComponent";
import VideoOrImageComponent from "../../display/VideoOrImageComponent";

class Minimal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: "sessions",
            radioTab: "sessions",
        };

        this.appRef = React.createRef();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.windowDimensions.width !== this.props.windowDimensions.width) {
            if (this.props.windowDimensions.width < 1024 && prevProps.windowDimensions.width > 1024) {
                if (this.props.noPlaylistData) {
                    this.updateCurrentTab("sessions");
                } else {
                    if (!_.isEmpty(this.props.selectedMarker)) {
                        this.updateCurrentTab("markerInfo");
                    } else {
                        this.updateCurrentTab("info");
                    }
                }
            }
        }

        if (this.props.windowDimensions.width < 1024 && _.isEmpty(prevProps.selectedMarker) && !_.isEmpty(this.props.selectedMarker)) {
            this.updateCurrentTab("markerInfo");
        }

        if (this.props.currentDashboard !== prevProps.currentDashboard) {
            this.setState({
                radioTab: "sessions",
            });
        }

        if (!prevProps.showingMarkerInfo && this.props.showingMarkerInfo) {
            console.log("this.state.currentTab", this.state.currentTab);
            if (this.state.currentTab !== "markerInfo") {
                console.log("Updating current tab");
                this.updateCurrentTab("markerInfo");
            }
        }

        if (!prevProps.targetResource && this.props.targetResource && this.props.shareLinkDetails && this.props.shareLinkDetails.sessionID) {
            this.props.dispatch(routeSelected(this.props.shareLinkDetails.sessionID, this.props.shareLinkDetails.timestamp));
        }

        if (!prevProps.targetResource && this.props.targetResource) {
            if (this.props.targetResource && ["Annotation", "Measurement", "Sketch", "Bookmark"].includes(this.props.targetResource.type)) {
                this.setState({
                    radioTab: "content_feed",
                });
            }
        }
    };

    dismissTargetResource = () => {
        this.props.history.replace("", null);
        this.props.dispatch(targetResource(null));
    };

    logout = () => {
        this.props.dispatch(logout());
    };

    updateCurrentTab = (tab) => {
        this.setState({ currentTab: tab });
        this.props.dispatch(toggleMarkerInfoShowing(tab === "markerInfo"));
    };

    render() {
        let resource_access_denied = this.props.targetResource && this.props.targetResource.access_granted === false;

        return (
            <div
                ref={this.props.measureRef}
                className="App">
                <Modal
                    title="Access Denied"
                    visible={resource_access_denied}
                    onOk={this.dismissTargetResource}
                    okText="Continue anyway"
                    onCancel={this.logout}
                    cancelText="Logout"
                    maskClosable={false}>
                    <p>Your user account does not have access to the shared content.</p>
                </Modal>

                <div
                    className="MainContent MainContentMinimal"
                    ref={this.appRef}>
                    <div className="messageBannerContainer">
                        <ExpiryBanner />
                    </div>

                    <div className="MainContent__Inner">
                        <div
                            className="Left-Container MainContentMainContainer"
                            style={{ display: "flex" }}>
                            <div className="Container Media MainContentVideoComponent">
                                <VideoOrImageComponent infoMessage="Please select a session from the list." />
                            </div>
                            <div className="Container Sessions MainContentSessionListComponent">
                                <div className="route-tabs-container hideOverflow">
                                    <SessionsComponent appRef={this.appRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);

    return {
        shareLink: state.shareLink,
        shareLinkDetails: state.shareLinkDetails,
        loading: state.mapGeometry === null && (!state.sessionList || _.isEmpty(state.sessionList)),
        targetResource: state.targetResource,
        logout: state.logout,
        currentDashboard,
        noPlaylistData: _.isEmpty(state.playlist.data),
        windowDimensions: state.windowDimensions,
        showingMarkerInfo: state.markers.showingMarkerInfo,
    };
};

export default connect(mapStateToProps)(withRouter(Minimal));
