import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import _, { set } from "lodash";

import MetadataFeed from "./MetadataFeed";
import MarkerPanel from "./MarkerPanel";
import {
    deleteSnappingValidation,
    getSessionData,
    featureOverlaySetDatum,
    validateSnapping,
    featureOverlayAddFeature,
    getRouteSystemCoords,
    featureOverlayClearFeatures,
    setUserPreference,
    getDeviceGroupInfo,
    fetchWhatThreeWords,
    customAudit,
    getSessionWeatherData,
    getClosestAssets,
    setClosestAssets,
    setDisplayNearbyAssets,
    setCurrentNearbyAsset,
    routeSelected,
} from "redux/actions/index";
import { getAbsoluteTimestamp, findMostRecentMetadata, findEnvironmentalData } from "../util/PlaylistUtils";
import { Link } from "react-router-dom";
import {
    calculateRouteCoordinatesForLocation,
    routePointDistance,
    getHeading,
    calculateELREstimates,
    calculateBearingText,
    coordinateToLatLon,
    coordinateToOSGB,
    convertSpeed,
    coordinateToIrishEasting,
} from "../util/Geometry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClipboard, faCog } from "@fortawesome/free-solid-svg-icons";
import {
    faAnalytics,
    faInfoCircle,
    faCamera,
    faMountain,
    faCompass,
    faTachometerFast,
    faTemperatureHigh,
    faHumidity,
    faCloudRain,
    faWindsock,
    faSyncAlt,
    faHome,
    faChevronLeft,
    faChevronRight,
    faImage,
} from "@fortawesome/pro-regular-svg-icons";
import { sticky } from "tippy.js";
import UnitSelectionModal from "../UnitSelectionModal";
import RouteCoordinateSystems from "../util/RouteCoordinateSystems";
import { LazyTippy } from "../util/LazyTooltips";
import { Spin, Button, Icon, Collapse, Popover, Select, Divider } from "antd";
import mwvLogo from "../../images/MWV-logo-small.png";
import { convertToTimezone } from "../util/TimezoneUtils";
import Tippy from "@tippyjs/react";
import moment from "moment";
import GenericDialog from "components/GenericDialog";
import OBCSpinner from "components/util/OBC";
import { useOpenWidget } from "components/hooks/useOpenWidget";

const routeIDSelector = (state) => state.playlist.data.routeID;
const routeDetailsSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], null);
const fullscreenSelector = (state) => state.fullscreen;
const openInfoPanelsSelector = (state) => state.userPreferences.openInfoPanels;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const sessionIDSelector = (state) => state.gpsTimeOffsets;
const displayNearbyAssetsSelector = (state) => state.assets.displayNearbyAssets;
const currentNearbyAssetSelector = (state) => state.assets.currentNearbyAsset;
const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const sidekickTypesSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
};
const sessionTagsSelector = (state) => {
    const currentSession = _.get(state.sessions, state.playlist.data.routeID);
    return _.get(currentSession, "tags");
};
const absoluteTimestampSelector = (state) => {
    let absolutePlaylistTimestamp = getAbsoluteTimestamp(state.playlist) * 1000;
    if (!absolutePlaylistTimestamp && state.railInspection.railInspectionImages.data?.length && !state.playlist.video?.length) {
        const currentIndex = _.get(state.playlist, ["position", "currentIndex"]);
        if (currentIndex) {
            absolutePlaylistTimestamp = _.get(state.railInspection.railInspectionImages.data, [currentIndex, "timestamp"], 0);
        }
    }

    return absolutePlaylistTimestamp;
};
const dashboardSelector = (state) => _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
const environmentDataSelector = (state) => state.sessionEnvironmentData;

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const PRE_FILL_MESSAGES = {
    other: null,
    dirty_lens: "Lens is not clean",
    obscured: "Footage is obscured by other object (train, wiper, cables, etc)",
    wrong_track: "Wrong track detected",
    camera_not_working: "Camera not working properly or not at all",
    too_dark_bright: "Recording is too bright or too dark",
    blurry: "Recording is blurry",
};

const RouteTitleDetails = () => {
    const dispatch = useDispatch();
    const [reportProblemVisible, setReportProblemVisible] = useState();
    const [sessionIdCopyText, setSessionIdCopyText] = useState("Click to copy");
    const [sessionIdHovered, setSessionIdHovered] = useState(false);
    const [preFilledMessage, setPreFilledMessage] = useState(null);

    const showReportProblemDialog = () => {
        setReportProblemVisible(true);
    };

    const hideReportProblemDialog = () => {
        setReportProblemVisible(false);
        setPreFilledMessage(null);
    };

    const routeID = useSelector(routeIDSelector);
    const routeBeingViewed = useSelector(routeDetailsSelector);
    const currentDashboard = useSelector(currentDashboardSelector);

    const sidekickTypes = useSelector(sidekickTypesSelector);
    const sessionTags = useSelector(sessionTagsSelector);
    const absoluteTimestamp = useSelector(absoluteTimestampSelector);

    const handleOpenTrackGeometry = useOpenWidget("track-geom-v2", "width=600,height=400,left=200,top=200");

    useEffect(() => {
        dispatch(getSessionData(routeID));
    }, [dispatch, routeID]);

    const handleSessionIdHovered = () => {
        if (sessionIdCopyText === "Copied!") {
            setSessionIdCopyText("Click to copy");
        }

        setSessionIdHovered(false);
    };

    const handleSessionIdClick = (e, id) => {
        navigator.clipboard.writeText(id);
        setSessionIdCopyText("Copied!");
    };

    let analyticsLink = null;
    let railInspectionLink = null;

    if (routeBeingViewed && routeBeingViewed.inspection && _.get(currentDashboard, ["config", "rail_inspection_enabled"])) {
        const linkData = `rail-inspection/${routeID}${absoluteTimestamp ? "/" + absoluteTimestamp : ""}`;

        railInspectionLink = (
            <Popover
                overlayClassName="OpenRailInspectionPopoverWithButtons"
                trigger="contextMenu"
                destroyTooltipOnHide
                content={
                    <div className="OpenRailInspectionPopoverWithButtonsList">
                        <Button
                            type="primary"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(`${window.location.href}${linkData}`, "_blank", "noreferrer");
                            }}>
                            Open in New Tab
                        </Button>
                    </div>
                }>
                <Link to={"/" + linkData}>
                    <button className="ConfirmActionButton blue">Rail Inspection</button>
                </Link>
            </Popover>
        );
    }

    const trackGeometryButton = useMemo(() => {
        if (sidekickTypes && sessionTags) {
            if (
                (sidekickTypes.includes("Track Geometry V2") || sidekickTypes.includes("Track Geometry")) &&
                (sessionTags.includes("Track Geometry") || sessionTags.includes("Track Geometry V2"))
            ) {
                return (
                    <button
                        className="ConfirmActionButton blue"
                        onClick={() => handleOpenTrackGeometry()}>
                        Track Geometry
                    </button>
                );
            }
            return null;
        }
        return null;
    }, [sidekickTypes, sessionTags, handleOpenTrackGeometry]);

    const selectPrefillMessage = (value) => {
        if (value !== "other") {
            setPreFilledMessage(`[${value}] - ${PRE_FILL_MESSAGES[value]}`);
        } else {
            setPreFilledMessage(null);
        }
    };

    const reportProblemWithSessionContent = useMemo(() => {
        let content = (
            <>
                <div>
                    Use this form to report an issue with this session. For example video not aligning with location, incorrect location or bad footage etc. or
                    select relevant issue from the list below.
                </div>

                <Select
                    defaultValue="other"
                    style={{ width: "100%", marginTop: "3px" }}
                    onChange={selectPrefillMessage}>
                    <Option value="other">Other</Option>
                    <OptGroup label="Common issues">
                        <Option value="blurry">Blurry</Option>
                        <Option value="camera_not_working">Camera not working</Option>
                        <Option value="dirty_lens">Dirty lens</Option>
                        <Option value="obscured">Obscured</Option>
                        <Option value="too_dark_bright">Too dark/bright</Option>
                        <Option value="wrong_track">Wrong track</Option>
                    </OptGroup>
                </Select>
            </>
        );

        return content;
    }, []);

    return (
        <>
            <div className="RouteInfo-Viewing">
                <span className="RouteInfo-Label">Viewing</span>
                <div className="RouteInfo-Text-Container">
                    {routeBeingViewed && (
                        <div style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
                            <Tippy
                                hideOnClick={false}
                                placement="top"
                                content={sessionIdCopyText}
                                onHidden={() => {
                                    handleSessionIdHovered();
                                }}
                                theme="aivrlight"
                                zIndex="600">
                                <span
                                    onClick={(e) => handleSessionIdClick(e, routeID)}
                                    style={{
                                        padding: 5,
                                        fontSize: 12,
                                        background: sessionIdHovered ? "rgba(109, 67, 223, 0.6)" : "rgba(109, 67, 223, 0.4)",
                                        borderRadius: 2,
                                        marginRight: 7.5,
                                        letterSpacing: 0.5,
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}>
                                    #{routeID}
                                </span>
                            </Tippy>

                            <span style={{ fontWeight: "bold" }}>{routeBeingViewed ? routeBeingViewed.route_name : "No Route Selected"}</span>
                            <Tippy
                                placement="top"
                                content="Report a problem with this session"
                                theme="aivrlight"
                                zIndex="600">
                                <div>
                                    <Button
                                        className="RouteInfo-Button"
                                        onClick={showReportProblemDialog}>
                                        <Icon
                                            type="bug"
                                            theme="outlined"
                                        />
                                    </Button>
                                </div>
                            </Tippy>
                        </div>
                    )}
                    <div className="ConfirmActionButton-Container">
                        {analyticsLink}
                        {railInspectionLink}
                        {trackGeometryButton}
                    </div>
                </div>
            </div>
            {reportProblemVisible && (
                <GenericDialog
                    title="Report problem"
                    onClose={hideReportProblemDialog}
                    placeholder={`Problem with session ${routeID}`}
                    promptText={reportProblemWithSessionContent}
                    reportOrigin="session-info"
                    preFilledMessage={preFilledMessage}
                />
            )}
        </>
    );
};

const EMPTY_ARRAY = [];

const timestampSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], EMPTY_ARRAY) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
};

const routeIsLoadedSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], EMPTY_ARRAY) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    return routeID && playlist && index !== -1;
};

const altitudeSelector = (state) => {
    return _.get(state.routeMetadata, ["ALTITUDE", state.playlist.data.routeID], EMPTY_ARRAY);
};

const humidityAndTempSelector = (state) => {
    return _.get(state.routeMetadata, ["EZO_HUM_STATUS", state.playlist.data.routeID], EMPTY_ARRAY);
};

const tachoDataSelector = (state) => _.get(state.routeMetadata, ["TACHO_DATA", state.playlist.data.routeID], EMPTY_ARRAY);
const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], EMPTY_ARRAY);

export const PositionalInfo = ({ hud = false }) => {
    const timestamp = useSelector(timestampSelector);
    const altMetadata = useSelector(altitudeSelector);
    const speedMetadata = useSelector(speedAndBearingSelector);
    const tachoMetadata = useSelector(tachoDataSelector);
    const humidityAndTempMetadata = useSelector(humidityAndTempSelector);
    const userConfig = useSelector(userConfigSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);

    let altitude = "Unknown";
    let speed = "Unknown";
    let speedSource = "";
    let bearing = "Unknown";
    let temperature = null;
    let humidity = null;
    let timestampSeconds = videolessTimestamp ? videolessTimestamp / 1000 : timestamp;

    if (timestampSeconds) {
        const altitudeInfo = findMostRecentMetadata(altMetadata, timestampSeconds);
        if (altitudeInfo) {
            altitude = Math.round(altitudeInfo.data) + "m";
        }
        const tachoData = findMostRecentMetadata(tachoMetadata, timestampSeconds);
        let baseSpeed = null;
        if (!_.isNil(tachoData?.data.speed) && tachoData.timestamp > timestampSeconds - 5) {
            baseSpeed = tachoData?.data.speed;
            speedSource = " (Tachometer)";
        }
        const speedInfo = findMostRecentMetadata(speedMetadata, timestampSeconds);
        if (baseSpeed == null && !_.isNil(speedInfo?.data.speed) && speedInfo.timestamp > timestampSeconds - 5) {
            baseSpeed = speedInfo?.data.speed;
            speedSource = " (GPS)";
        }
        if (!_.isNil(speedInfo?.data.bearing)) {
            bearing = calculateBearingText(speedInfo.data.bearing);
        }
        if (baseSpeed != null) {
            let unitDict = {
                metres: "m/s",
                miles: "MPH",
                kilometers: "KPH",
            };
            let unit = userConfig.speed_units || "miles";
            speed = Math.round(convertSpeed(baseSpeed, unit)) + " " + unitDict[unit] + speedSource;
        }
        const humidityAndTempInfo = findMostRecentMetadata(humidityAndTempMetadata, timestampSeconds);
        if (!_.isNil(humidityAndTempInfo?.data.temperature)) {
            temperature = Number(humidityAndTempInfo.data.temperature).toFixed(2) + "°C";
        }
        if (!_.isNil(humidityAndTempInfo?.data.humidity)) {
            humidity = Number(humidityAndTempInfo.data.humidity).toFixed(2) + "%";
        }
    }

    return (
        <MetadataFeed
            items={["ALTITUDE", "SPEED_AND_BEARING", "TACHO_DATA", "EZO_HUM_STATUS"]}
            timestamp={timestampSeconds || null}>
            <div className={hud ? "HUD-RouteInfo-Row" : "RouteInfo-Row"}>
                <div className={hud ? "HUD-RouteInfo-Altitude" : "RouteInfo-Altitude"}>
                    <div className={hud ? "HUD-RouteInfo-Text-Container" : "RouteInfo-Text-Container"}>
                        <Tippy
                            arrow={true}
                            placement="top"
                            content="Speed"
                            theme="aivrlight"
                            zIndex="600">
                            <div className={hud ? "HUD-RouteInfo-BlockItem" : "RouteInfo-BlockItem"}>
                                <FontAwesomeIcon icon={faTachometerFast} />
                                <span className="secondary">{speed ? speed : "No Route Selected"}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className={hud ? "HUD-RouteInfo-Altitude" : "RouteInfo-Altitude"}>
                    <div className={hud ? "HUD-RouteInfo-Text-Container" : "RouteInfo-Text-Container"}>
                        <Tippy
                            placement="top"
                            content="Bearing"
                            theme="aivrlight"
                            zIndex="600">
                            <div className={hud ? "HUD-RouteInfo-BlockItem" : "RouteInfo-BlockItem"}>
                                <FontAwesomeIcon icon={faCompass} />
                                <span className="secondary">{bearing ? bearing : "No Route Selected"}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className={hud ? "HUD-RouteInfo-Altitude" : "RouteInfo-Altitude"}>
                    <div className={hud ? "HUD-RouteInfo-Text-Container" : "RouteInfo-Text-Container"}>
                        <Tippy
                            placement="top"
                            content="Altitude"
                            theme="aivrlight"
                            zIndex="600">
                            <div className={hud ? "HUD-RouteInfo-BlockItem" : "RouteInfo-BlockItem"}>
                                <FontAwesomeIcon icon={faMountain} />
                                <span className="secondary">{altitude ? altitude : "No Route Selected"}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
            </div>
            <div className={temperature ? "HUD-RouteInfo-Row" : "HUD-RouteInfo-Row disabled"}>
                <div className="HUD-RouteInfo-Altitude">
                    <div className="HUD-RouteInfo-Text-Container">
                        <Tippy
                            placement="top"
                            content="Temperature"
                            theme="aivrlight"
                            zIndex="600">
                            <div className={`HUD-RouteInfo-BlockItem ${temperature}`}>
                                <FontAwesomeIcon icon={faTemperatureHigh} />
                                <span className="secondary">{temperature}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className="HUD-RouteInfo-Altitude">
                    <div className="HUD-RouteInfo-Text-Container">
                        <Tippy
                            placement="top"
                            content="Humidity"
                            theme="aivrlight"
                            zIndex="600">
                            <div className={`HUD-RouteInfo-BlockItem ${humidity}`}>
                                <FontAwesomeIcon icon={faHumidity} />
                                <span className="secondary">{humidity}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
            </div>
        </MetadataFeed>
    );
};

const routeIndexSelector = (state) => state.playlist.position.currentIndex;

const routeCoordinateSelector = (state) => {
    let playlistCoords = state.playlist.position.coords;
    if (playlistCoords && playlistCoords[0] !== null && playlistCoords[1] !== null) {
        return playlistCoords;
    } else {
        return null;
    }
};

const sessionPositionTimestampSelector = (state) => state.playlist.position.timestamp;
const routeLocationsSelector = (state) => state.playlist.data.route_locations;
const nearestAssetsSelector = (state) => state.assets.nearestAssets;

const routeDatumSelector = (state) => state.featureOverlay.route;

function WhatThreeWordsCopier({ words }) {
    return (
        <LazyTippy
            placement="top"
            sticky={true}
            plugins={[sticky]}
            arrow={true}
            theme="aivrlight"
            interactive="true"
            content="Copy what3words data to clipboard">
            <div
                className="CopyToClipboard"
                onClick={() => {
                    navigator.clipboard.writeText(words);
                }}>
                <FontAwesomeIcon icon={faClipboard} />
            </div>
        </LazyTippy>
    );
}

function LatLonCopier() {
    const store = useStore();
    return (
        <LazyTippy
            placement="top"
            sticky={true}
            plugins={[sticky]}
            arrow={true}
            theme="aivrlight"
            interactive="true"
            content="Copy Lat / Lon data to clipboard">
            <div
                className="CopyToClipboard"
                onClick={() => {
                    const coordinate = routeCoordinateSelector(store.getState());
                    console.log("debug copying coords", coordinate);

                    navigator.clipboard.writeText(coordinateToLatLon(coordinate));
                }}>
                <FontAwesomeIcon icon={faClipboard} />
            </div>
        </LazyTippy>
    );
}

function OsgbCopier({ eastingNorthing }) {
    return (
        <LazyTippy
            placement="top"
            sticky={true}
            plugins={[sticky]}
            arrow={true}
            theme="aivrlight"
            interactive="true"
            content="Copy Easting / Northing data to clipboard">
            <div
                className="CopyToClipboard"
                onClick={() => {
                    navigator.clipboard.writeText(eastingNorthing);
                }}>
                <FontAwesomeIcon icon={faClipboard} />
            </div>
        </LazyTippy>
    );
}

const headingSelector = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);
    const playlistIndex = state.playlist.position.currentIndex;
    const timeOffset = state.playlist.position.currentTimeOffset || 0;
    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }
    const use_snapped = state.snappedRoute || false;
    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;

    return getHeading(video, playlistIndex, timeOffset, offsets, use_snapped, sessionIsBackward);
};

export const WhatThreeWords = ({ hud = false }) => {
    const [whatThreeWords, setWhatThreeWords] = useState("");
    const dispatch = useDispatch();

    const currentCoordinate = useSelector(routeCoordinateSelector);
    const playerState = useSelector(playerStateSelector);

    const getWhatThreeWords = useCallback(() => {
        dispatch(fetchWhatThreeWords(currentCoordinate[1], currentCoordinate[0])).then((words) => {
            setWhatThreeWords(words);
        });
    }, [dispatch, currentCoordinate]);

    useEffect(() => {
        setWhatThreeWords("");
    }, [currentCoordinate]);

    const content = useMemo(() => {
        if (!currentCoordinate) {
            return <p className="RouteInfoMsg">No Route Selected</p>;
        } else if (playerState === "playing") {
            return <p className="RouteInfoMsg">Pause video for W3W</p>;
        } else if (!whatThreeWords) {
            return hud ? (
                <div
                    onClick={getWhatThreeWords}
                    className="OverlayButton active">
                    Get W3W
                </div>
            ) : (
                <Button
                    onClick={getWhatThreeWords}
                    className="WhatThreeWordsButton">
                    Get what3words
                </Button>
            );
        } else {
            return (
                <div className={hud ? "HUD-RouteInfoELR__Container" : "RouteInfoELR__Container"}>
                    <p style={{ marginRight: 7.5, marginBottom: 0 }}>{whatThreeWords}</p>
                    <WhatThreeWordsCopier words={whatThreeWords} />
                </div>
            );
        }
    }, [playerState, getWhatThreeWords, whatThreeWords, currentCoordinate]);

    return (
        <div className={hud ? "W3W-Container" : "RouteInfo-Altitude"}>
            <span className={hud ? "" : "RouteInfo-Label"}>what3words</span>
            <div className={hud ? "HUD-RouteInfo-Text-Container" : "RouteInfo-Text-Container"}>
                <span className="W3WValue">{content}</span>
            </div>
        </div>
    );
};

export const LatLonInfo = ({ coordSystem, hud = false }) => {
    const currentTimestamp = useSelector(absoluteTimestampSelector);
    const routeIsLoaded = useSelector(routeIsLoadedSelector);
    const currentCoordinate = useSelector(routeCoordinateSelector);
    const routeLocations = useSelector(routeLocationsSelector);
    const playerState = useSelector(playerStateSelector);
    const nearestAssets = useSelector(nearestAssetsSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const sessionID = useSelector(sessionIDSelector);
    const displayNearbyAssets = useSelector(displayNearbyAssetsSelector);
    const currentNearbyAsset = useSelector(currentNearbyAssetSelector);
    const dashboard = useSelector(dashboardSelector);

    const [closestLocation, setClosestLocation] = useState({});
    const [assetError, setAssetError] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDisplayNearbyAssets(false));
        dispatch(setClosestAssets([]));
    }, [sessionID, dispatch]);

    useEffect(() => {
        const handleClick = (e) => {
            const availableAssetGroups = _.get(dashboard, ["config", "asset_groups"], []);

            const checkList = ["NearbyAssets", "fa-chevron-left", "fa-chevron-right", "NearbyAssetsNav"];
            const classList = [
                ..._.get(e, "target.classList", []),
                ..._.get(e, "target.parentElement.classList", []),
                ..._.get(e, "target.parentElement.parentElement.classList", []),
                ..._.get(e, "target.parentElement.parentElement.parentElement.classList", []),
                ..._.get(e, "target.parentElement.parentElement.parentElement.parentElement.classList", []),
                _.get(e, "target.nearestViewportElement.id", ""),
            ];

            if (!availableAssetGroups.includes(_.get(currentNearbyAsset, "asset_group_id", "").toString())) {
                checkList.push("leaflet-marker-icon");
            }

            if (!_.intersection(checkList, classList).length) {
                if (displayNearbyAssets) {
                    dispatch(setDisplayNearbyAssets(false));
                    dispatch(setClosestAssets([]));
                }
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    let latLong = useMemo(() => {
        if (routeIsLoaded) {
            return coordinateToLatLon(currentCoordinate);
        } else {
            return null;
        }
    }, [routeIsLoaded, currentCoordinate]);

    let osgb = useMemo(() => {
        if (routeIsLoaded) {
            let coord;
            if (routeSystemID === "Irish Rail") {
                coord = coordinateToIrishEasting(currentCoordinate);
            } else if (routeSystemID === "ELR Mile & Chain") {
                coord = coordinateToOSGB(currentCoordinate);
            }
            return coord;
        } else {
            return null;
        }
    }, [routeIsLoaded, routeSystemID, currentCoordinate]);

    const latLonCopier = useMemo(() => {
        return <LatLonCopier />;
    }, []);

    const osgbCopier = useMemo(() => {
        return <OsgbCopier eastingNorthing={osgb} />;
    }, [osgb]);

    const latLonDisplay = useMemo(() => {
        if (latLong) {
            if (hud) {
                const lat = latLong.split(",")[0];
                const long = latLong.split(",")[1].replace(" ", "");

                return (
                    <>
                        <span>{lat}</span>
                        <span>{long}</span>
                    </>
                );
            } else {
                return (
                    <>
                        <span>{latLong}</span>
                        {latLonCopier}
                    </>
                );
            }
        } else {
            return "No Route Selected";
        }
    }, [latLong, latLonCopier, hud]);

    const osgbDisplay = useMemo(() => {
        if (osgb) {
            if (hud) {
                const easting = osgb.split(",")[0];
                const northing = osgb.split(",")[1].replace(" ", "");

                return (
                    <>
                        <span>{easting}</span>
                        <span>{northing}</span>
                    </>
                );
            } else {
                return (
                    <>
                        <span>{osgb}</span>
                        {osgbCopier}
                    </>
                );
            }
        } else {
            return "No Route Selected";
        }
    }, [osgb, hud]);

    const nearbyAssets = useMemo(() => {
        if (!_.get(dashboard, ["config", "asset_groups", "length"], false)) return null;

        let nearestLocation = {};

        if (currentTimestamp && routeLocations && routeLocations.length) {
            nearestLocation = calculateRouteCoordinatesForLocation(currentTimestamp, routeLocations, routeSystemID);
            setClosestLocation(nearestLocation);
        }

        const fetchClosestAssets = () => {
            if (nearestLocation && nearestLocation.hasOwnProperty("elr")) {
                dispatch(getClosestAssets(nearestLocation.elr, nearestLocation.position));
                return true;
            } else {
                console.log("Cannot get current chains location");
                return false;
            }
        };

        if (!osgb) {
            return <p className="RouteInfoMsg">No Route Selected</p>;
        } else if (playerState === "playing") {
            return <p className="RouteInfoMsg">Pause the video to calculate current position</p>;
        } else {
            return (
                <>
                    {displayNearbyAssets ? (
                        <Button
                            className="RevealNearbyAssets"
                            onClick={() => {
                                dispatch(setDisplayNearbyAssets(!displayNearbyAssets));
                                dispatch(setClosestAssets([]));
                                dispatch(setCurrentNearbyAsset({}));
                            }}>
                            Close
                        </Button>
                    ) : (
                        <Button
                            className="RevealNearbyAssets"
                            onClick={() => {
                                const assetsFetched = fetchClosestAssets();
                                if (assetsFetched) {
                                    setAssetError(false);
                                } else {
                                    setAssetError(true);
                                }

                                dispatch(setDisplayNearbyAssets(!displayNearbyAssets));
                            }}>
                            Get nearby assets
                        </Button>
                    )}
                </>
            );
        }
    }, [dashboard, currentTimestamp, routeLocations, osgb, playerState, routeSystemID, dispatch, displayNearbyAssets]);

    const nearbyAssetIndex = useMemo(() => {
        return _.findIndex(nearestAssets, (asset) => asset.id === currentNearbyAsset.id);
    }, [nearestAssets, currentNearbyAsset]);

    const navigateAssets = useCallback(
        (direction) => {
            if (direction === "left") {
                dispatch(setCurrentNearbyAsset(nearestAssets[nearbyAssetIndex - 1]));
            } else {
                dispatch(setCurrentNearbyAsset(nearestAssets[nearbyAssetIndex + 1]));
            }
        },
        [nearestAssets, nearbyAssetIndex, dispatch],
    );

    const nearbyAssetContent = useMemo(() => {
        if (nearestAssets) {
            if (!_.isEmpty(currentNearbyAsset)) {
                let id = "Unknown";
                if (currentNearbyAsset.consensus_data) {
                    if (currentNearbyAsset.consensus_data.hasOwnProperty("structure_id")) {
                        id = currentNearbyAsset.consensus_data.structure_id;
                    } else if (currentNearbyAsset.consensus_data.hasOwnProperty("signal_id")) {
                        id = currentNearbyAsset.consensus_data.signal_id;
                    }

                    if (id !== "Unknown") {
                        id = id.replace(/,/g, "/");
                    }
                }

                let assetType = null;
                if (currentNearbyAsset.summary_data) {
                    if (currentNearbyAsset.summary_data.match_count > 0 && currentNearbyAsset.summary_data.external_match_count > 0) {
                        assetType = "AIVR and External";
                    } else if (currentNearbyAsset.summary_data.match_count > 0 && !currentNearbyAsset.summary_data.external_match_count) {
                        assetType = "AIVR";
                    } else if (currentNearbyAsset.summary_data.match_count === 0 && currentNearbyAsset.summary_data.external_match_count > 0) {
                        assetType = "External";
                    }
                }

                let thumbnail = null;
                const images = _.get(currentNearbyAsset, ["summary_data", "images"], {});
                if (images.hasOwnProperty("thumbnail")) {
                    thumbnail = images.thumbnail;
                }

                let description = null;
                const summaryData = _.get(currentNearbyAsset, "summary_data", {});
                if (summaryData.hasOwnProperty("external_descriptions")) {
                    description = summaryData.external_descriptions;
                }

                const chainsToMetres = (chains) => {
                    if (closestLocation) {
                        let chainsDistance = Math.abs(chains - closestLocation.position);
                        let metres = Math.floor(chainsDistance * 20.1186);
                        return `${metres}m`;
                    }

                    return `${chains} (chains)`;
                };

                const formatLineReference = (chains) => {
                    let miles = Math.floor(chains / 80);
                    let yards = Math.floor((chains - miles * 80) * 22);
                    let elr = _.get(currentNearbyAsset, "route_position_elr");
                    let trid = _.get(currentNearbyAsset, "route_position_trid");
                    return `${elr} ${miles}m ${yards}y ${trid}`;
                };

                const content = (
                    <div className="NearbyAssetsContent">
                        <div className="NearbyAssetsInfoContainer">
                            {thumbnail && (
                                <img
                                    src={thumbnail}
                                    alt="Asset thumbnail"
                                    className="NearbyAssetsContentImg"
                                />
                            )}
                            <div className="AssetInfoRow">
                                <span className="NearbyAssetsContentLabel">ID</span>
                                <span className="NearbyAssetsContentValue">{id}</span>
                            </div>
                            <div className="AssetInfoRow">
                                <span className="NearbyAssetsContentLabel">Distance</span>
                                <span className="NearbyAssetsContentValue">{chainsToMetres(currentNearbyAsset.route_position_chains)}</span>
                            </div>
                            <div className="AssetInfoRow">
                                <span className="NearbyAssetsContentLabel">Line reference</span>
                                <span className="NearbyAssetsContentValue">{formatLineReference(currentNearbyAsset.route_position_chains)}</span>
                            </div>
                            <div className="AssetInfoRow">
                                <span className="NearbyAssetsContentLabel">Asset type</span>
                                <span className="NearbyAssetsContentValue">{currentNearbyAsset.asset_class}</span>
                            </div>
                            {assetType && (
                                <div className="AssetInfoRow">
                                    <span className="NearbyAssetsContentLabel">Type</span>
                                    <span className="NearbyAssetsContentValue">{assetType}</span>
                                </div>
                            )}
                            {description && (
                                <div className="AssetInfoRow">
                                    <span className="NearbyAssetsContentLabel">Description</span>
                                    <span className="NearbyAssetsContentValue">{description[0]}</span>
                                </div>
                            )}
                        </div>
                    </div>
                );

                return (
                    <div className="NearbyAssetContainer">
                        <div className="NearbyAssets">
                            {nearestAssets.length > 1 && (
                                <button
                                    className={`NearbyAssetsNav ${nearbyAssetIndex === 0 && "Disabled"}`}
                                    disabled={nearbyAssetIndex === 0}
                                    onClick={() => navigateAssets("left")}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            )}
                            {content}
                            {nearestAssets.length > 1 && (
                                <button
                                    className={`NearbyAssetsNav ${nearbyAssetIndex + 1 === nearestAssets.length && "Disabled"}`}
                                    disabled={nearbyAssetIndex + 1 === nearestAssets.length}
                                    onClick={() => navigateAssets("right")}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            )}
                        </div>
                    </div>
                );
            }
        } else {
            return "Unavailable at this position";
        }
    }, [nearestAssets, closestLocation, currentNearbyAsset, navigateAssets, nearbyAssetIndex]);

    const LatLonInfoForHUD = () => {
        return (
            <>
                <div className="HUD-RouteInfo-Inline">
                    {coordSystem === "latlon" ? (
                        <div className="HUD-RouteInfo-RowBlock">
                            <div class="HUD-LatLonLabel">
                                <span className="HUD-RouteInfo-Label">LAT</span>
                                <span className="HUD-RouteInfo-Label">LON</span>
                            </div>
                            <div className="HUD-LatLonDisplay">{latLonDisplay}</div>
                        </div>
                    ) : routeSystemID && coordSystem === "osgb" ? (
                        <div className="HUD-RouteInfo-RowBlock">
                            <div class="HUD-LatLonLabel">
                                <span className="HUD-RouteInfo-Label">EASTING</span>
                                <span className="HUD-RouteInfo-Label">NORTHING</span>
                            </div>
                            <div className="HUD-LatLonDisplay">{osgbDisplay}</div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    const NearbyAssetLoader = ({ error }) => {
        if (error) {
            return <div>Cannot get assets for this route or location</div>;
        }
        return (
            <OBCSpinner
                size={40}
                color="#e8dfff"
            />
        );
    };

    const LatLonInfoForMainUI = ({ showAssets, assetError }) => {
        return (
            <>
                <div className="RouteInfo-Inline">
                    <div className="RouteInfo-Altitude">
                        <span className="RouteInfo-Label">(LAT, LON)</span>
                        <div className="RouteInfo-Text-Container nowrap">
                            <span className="RouteInfoDisplayWrapper">{latLonDisplay}</span>
                        </div>
                    </div>
                    {(routeSystemID === "ELR Mile & Chain" || routeSystemID === "Irish Rail") && (
                        <div className="RouteInfo-Altitude">
                            {routeSystemID === "Irish Rail" ? (
                                <Tippy
                                    placement="top"
                                    content={<span>Irish Grid</span>}
                                    theme="aivrlight"
                                    zIndex="600">
                                    <span className="RouteInfo-Label">(EASTING, NORTHING)</span>
                                </Tippy>
                            ) : (
                                <span className="RouteInfo-Label">(EASTING, NORTHING)</span>
                            )}
                            <div className="RouteInfo-Text-Container nowrap">
                                <span className="RouteInfoDisplayWrapper">{osgbDisplay}</span>
                            </div>
                        </div>
                    )}
                </div>
                <WhatThreeWords />
                {_.get(dashboard, ["config", "asset_groups", "length"], false) ? (
                    <div className="RouteInfo-Altitude">
                        <span className="RouteInfo-Label">(ASSETS)</span>
                        <div className="RouteInfo-Text-Container">
                            <Tippy
                                visible={showAssets}
                                maxWidth={400}
                                content={nearbyAssetContent ? nearbyAssetContent : <NearbyAssetLoader error={assetError} />}
                                className="AssetTippy"
                                arrow={false}>
                                <span className="secondary">{nearbyAssets}</span>
                            </Tippy>
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    if (hud) {
        return <LatLonInfoForHUD />;
    } else {
        return (
            <LatLonInfoForMainUI
                showAssets={displayNearbyAssets}
                assetError={assetError}
            />
        );
    }
};

export const VideoTimeDisplay = () => {
    const videoTimestamp = useSelector(timestampSelector);
    const userConfig = useSelector(userConfigSelector);

    const humanTime = useMemo(() => {
        const dpDate = new Date(videoTimestamp * 1000);
        return convertToTimezone(dpDate, userConfig.convert_to_utc);
    }, [videoTimestamp, userConfig.convert_to_utc]);

    return (
        <div className="RouteInfo-Inline">
            <div className="RouteInfo-Altitude">
                <div className="RouteInfo-Text-Container nowrap">
                    <span className="secondary">{videoTimestamp ? humanTime : "No Route Selected"}</span>
                </div>
            </div>
        </div>
    );
};

export const Datum = ({ hud = false }) => {
    const dispatch = useDispatch();
    const comparisonCoord = useSelector(comparisonCoordSelector);
    const timestamp = useSelector(timestampSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);
    const heading = useSelector(headingSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const playlistPositionCoords = useSelector(playlistPositionCoordsSelector);
    const currentIndex = useSelector(routeIndexSelector);
    const datumInformation = useSelector(routeDatumSelector);
    const routeIsLoaded = useSelector(routeIsLoadedSelector);
    const currentCoordinate = useSelector(routeCoordinateSelector);
    const userConfig = useSelector(userConfigSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const routeID = useSelector(routeIDSelector);
    const [datumTimestamp, setDatumTimestamp] = useState(0);

    const timestampSeconds = useMemo(() => {
        return timestamp ? timestamp : videolessTimestamp / 1000;
    }, [timestamp, videolessTimestamp]);

    const distanceFromDatumInfo = useMemo(() => {
        if (routeIsLoaded && currentCoordinate && datumInformation) {
            const { datum, distanceMap } = datumInformation;
            let distanceFromDatum = routePointDistance(datum, currentCoordinate, distanceMap, timestamp ? currentIndex : undefined);
            if (distanceFromDatum < 10) {
                distanceFromDatum = Math.round(distanceFromDatum * 10) / 10;
            } else {
                distanceFromDatum = Math.round(distanceFromDatum);
            }
            const measurementUnit = userConfig.measurement_units;

            if (measurementUnit === "metres") {
                return `${distanceFromDatum}m from datum`;
            } else if (measurementUnit === "yards") {
                let yardValue = distanceFromDatum * 1.0936132983;
                if (yardValue < 10) {
                    yardValue = Math.round(yardValue * 10) / 10;
                } else {
                    yardValue = Math.round(yardValue);
                }
                return `${yardValue}y from datum`;
            }
        } else {
            return null;
        }
    }, [routeIsLoaded, currentCoordinate, datumInformation, currentIndex, userConfig.measurement_units]);

    const setDatum = useCallback(() => {
        const elr = calculateRouteCoordinatesForLocation(timestampSeconds, routeLocationData, routeSystemID);
        setDatumTimestamp(timestampSeconds);
        dispatch(
            featureOverlayAddFeature(
                {
                    type: "flag",
                    heading: (180 * heading) / Math.PI,
                    location: playlistPositionCoords,
                    routePosition: elr,
                    routeInformationDatum: true,
                },
                false,
            ),
        );
        if (elr) {
            dispatch(
                customAudit(
                    "datum_placed",
                    {
                        position: `${elr.route} ${elr.mile}m ${elr.chain}c`,
                    },
                    `Datum placed - ${elr.route} ${elr.mile}m ${elr.chain}c`,
                ),
            );
        }
        dispatch(featureOverlaySetDatum(timestampSeconds, playlistPositionCoords));
    }, [dispatch, playlistPositionCoords, timestampSeconds, heading, routeLocationData, routeSystemID]);

    const removeDatum = useCallback(() => {
        dispatch(featureOverlaySetDatum(null, null));
        dispatch(featureOverlayClearFeatures());
        setDatumTimestamp(0);
    }, [dispatch]);

    const isDatumDisabled = useMemo(() => {
        return !playlistPositionCoords || !calculateRouteCoordinatesForLocation(timestampSeconds, routeLocationData, routeSystemID);
    }, [playlistPositionCoords, routeLocationData, routeSystemID, timestampSeconds]);

    const datumDisplay = useMemo(() => {
        let datum_text = null;
        let distance_text = "Datum not set";

        if (comparisonCoord) {
            datum_text = (
                <RouteCoordinateDisplay
                    key={`${comparisonCoord.route}.${comparisonCoord.subposition}`}
                    coordinate={comparisonCoord}
                    readonly={true}
                    hud={hud}
                />
            );
        }

        if (distanceFromDatumInfo) {
            distance_text = distanceFromDatumInfo;
        }

        const handleGoToDatum = () => {
            updatePlaylistTime(dispatch, routeID, datumTimestamp);
        };

        const updatePlaylistTime = (dispatch, routeId, datumTimestamp) => {
            dispatch(routeSelected(routeId, datumTimestamp));
        };

        return (
            <div className={hud ? "HUD-RouteInfoDatum" : "RouteInfoDatum"}>
                <div className={hud ? "HUD-RouteInfoDatum__offset-container" : "RouteInfoDatum__offset-container"}>
                    <div className={hud ? "HUD-RouteInfoDatum__OffsetText" : "RouteInfoDatum__OffsetText"}>
                        <span className="secondary">{distance_text}</span>
                    </div>
                    <div
                        size="small"
                        disabled={isDatumDisabled}
                        className={hud ? "OverlayButton active full" : "ant-btn RouteInfoDatum__DatumButton ant-btn-sm"}
                        onClick={() => {
                            if (!isDatumDisabled) {
                                setDatum();
                            }
                        }}
                        title={
                            !calculateRouteCoordinatesForLocation(timestampSeconds, routeLocationData, routeSystemID)
                                ? "ELR data missing, unable to set Datum"
                                : false
                        }>
                        Set Here
                    </div>

                    {datumInformation && (
                        <>
                            <div
                                size="small"
                                disabled={!playlistPositionCoords}
                                className={hud ? "OverlayButton active full" : "ant-btn RouteInfoDatum__DatumButton ant-btn-sm"}
                                onClick={handleGoToDatum}>
                                Go To Datum
                            </div>
                            <div
                                size="small"
                                disabled={!playlistPositionCoords}
                                className={hud ? "OverlayButton active full" : "ant-btn RouteInfoDatum__DatumButton ant-btn-sm"}
                                onClick={removeDatum}>
                                Clear Datum
                            </div>
                        </>
                    )}
                </div>
                {datum_text && (
                    <div className={hud ? "HUD-RouteInfoDatum__datum-container" : "RouteInfoDatum__datum-container"}>
                        <span className="DatumLabel">Current datum</span>
                        {datum_text}
                    </div>
                )}
            </div>
        );
    }, [distanceFromDatumInfo, comparisonCoord, playlistPositionCoords, setDatum, removeDatum, datumInformation, routeSystemID]);

    return (
        <div className={hud ? "HUD-RouteInfo-Altitude" : "RouteInfo-Altitude"}>
            <div className={hud ? "HUD-RouteInfo-Datum-Container" : "RouteInfo-Text-Container"}>{datumDisplay}</div>
        </div>
    );
};

const playlistPositionCoordsSelector = (state) => state.playlist.position.coords;
const routeCoordDataSelector = (state) => state.playlist.position.routeCoordData;
const userConfigSelector = (state) => state.userDetails.userConfig;
const supportedCoordinateSystemsSelector = (state) => state.routeCoordinateSystems;
const currentDashboardSelector = (state) => _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);
const datumOffsetsSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);
    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    return workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
};
const validatedSnappingSelector = (state) => state.playlist.position.snappingValidations;
const comparisonCoordSelector = (state) => _.get(state.featureOverlay.datum, "routePosition");

const validationLoadingIcon = (
    <div className="elrSpinnerContainer">
        <Spin className="elrValidationSpinner" />
    </div>
);

const notValidatedIcon = (
    <LazyTippy
        placement="right"
        sticky={true}
        plugins={[sticky]}
        arrow={true}
        theme="aivrlight"
        interactive="true"
        content="Click to validate ELR">
        <div>
            <FontAwesomeIcon
                icon={faCheck}
                style={{ marginLeft: 7.5 }}
                className={"snapValidationIcon"}
            />
        </div>
    </LazyTippy>
);

const validatedIcon = (
    <LazyTippy
        placement="right"
        sticky={true}
        plugins={[sticky]}
        arrow={true}
        theme="aivrlight"
        interactive="true"
        content="ELR Validated">
        <div>
            <FontAwesomeIcon
                icon={faCheck}
                style={{ marginLeft: 7.5 }}
                className={"snapValidationIcon validated"}
            />
        </div>
    </LazyTippy>
);

const copyToClipboardIcon = (
    <LazyTippy
        placement="top"
        sticky={true}
        plugins={[sticky]}
        arrow={true}
        theme="aivrlight"
        interactive="true"
        content="Copy to clipboard">
        <div>
            <FontAwesomeIcon
                icon={faClipboard}
                style={{ marginLeft: 7.5 }}
            />
        </div>
    </LazyTippy>
);

const playerStateSelector = (state) => _.get(state.playlist, ["position", "playerState"], "paused");

function RouteCoordinateDisplay({ coordinate, readonly = false, estimate = false, setDisplayEstimates, triggerCoordinateLookup, hud = false }) {
    const dispatch = useDispatch();
    const validatedSnapping = useSelector(validatedSnappingSelector);
    const userConfig = useSelector(userConfigSelector);
    const datum_offsets = useSelector(datumOffsetsSelector);
    const routeID = useSelector(routeIDSelector);
    const isFullscreen = useSelector(fullscreenSelector);
    const playerState = useSelector(playerStateSelector);

    const [elrValidationLoading, setElrValidationLoading] = useState();

    const ELRCopyString = useMemo(() => coordinate.to_string(userConfig.elr_units, datum_offsets), [coordinate, userConfig.elr_units, datum_offsets]);

    const validationIcon = useMemo(() => {
        const validatedIndex = _.findIndex(validatedSnapping, (snapping) => {
            return (
                snapping.routeID === coordinate.route &&
                Math.abs(snapping.routePosition - coordinate.position) <= 1 &&
                snapping.routeSubPosition === coordinate.subposition
            );
        });
        const elrValidated = validatedIndex > -1;
        const validatedID = elrValidated ? validatedSnapping[validatedIndex].id : -1;

        if (!readonly) {
            if (elrValidationLoading) {
                return validationLoadingIcon;
            } else if (elrValidated && userConfig.super_admin) {
                return (
                    <div
                        onClick={() => {
                            setElrValidationLoading(true);
                            dispatch(
                                deleteSnappingValidation(validatedID, () => {
                                    setElrValidationLoading(false);
                                }),
                            );
                        }}>
                        {validatedIcon}
                    </div>
                );
            } else {
                if (estimate) {
                    if (userConfig.super_admin) {
                        return (
                            <div
                                onClick={() => {
                                    setElrValidationLoading(true);
                                    dispatch(
                                        validateSnapping(routeID, coordinate.system, coordinate.route, coordinate.position, coordinate.subposition, () => {
                                            setElrValidationLoading(false);
                                        }),
                                    );
                                }}>
                                {notValidatedIcon}
                            </div>
                        );
                    }
                } else if (playerState !== "playing") {
                    return (
                        <div
                            onClick={() => {
                                triggerCoordinateLookup();
                                setDisplayEstimates(true);
                            }}
                            className="elrIncorrectButton">
                            Show more...
                        </div>
                    );
                }
            }
        } else {
            return null;
        }
    }, [
        userConfig,
        coordinate,
        elrValidationLoading,
        dispatch,
        routeID,
        validatedSnapping,
        readonly,
        estimate,
        setDisplayEstimates,
        playerState,
        triggerCoordinateLookup,
    ]);

    const ELRInfo = [];

    _.map(coordinate.displayFields(userConfig.elr_units), (field_name) => {
        if (!_.isNil(coordinate.field_value(field_name, datum_offsets))) {
            ELRInfo.push({
                name: field_name,
                value: coordinate.field_value(field_name, datum_offsets),
            });
        }
    });

    if (hud) {
        return (
            <div
                className={"HUD-elrRow"}
                key={`${coordinate.route}.${coordinate.subposition}`}>
                <div className={"HUD-ELRContainer"}>
                    <div className={"HUD-ELRInfo"}>
                        {ELRInfo.map((field, index) => {
                            return (
                                <>
                                    <div className="HUD-ELRInfo-Row">
                                        <span className="name-label">{field.name}</span>
                                        <span>{field.value}</span>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className="HUD-CoordinateTools">
                        {!!coordinate.sources.length && (
                            <LazyTippy
                                placement="top"
                                sticky={true}
                                plugins={[sticky]}
                                arrow={true}
                                theme="aivrlight"
                                interactive="true"
                                allowHTML={true}
                                content={
                                    <div className="ElrSources">
                                        <p className="Header">Location Sources:</p>
                                        {coordinate.sources.map((source) => {
                                            return (
                                                <p
                                                    className="Source"
                                                    key={source}>
                                                    {source}
                                                </p>
                                            );
                                        })}
                                    </div>
                                }>
                                <div className="CoordinateSources">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{ marginLeft: 7.5 }}
                                    />
                                </div>
                            </LazyTippy>
                        )}
                        <div
                            className="CopyToClipboard"
                            onClick={() => {
                                navigator.clipboard.writeText(ELRCopyString);
                            }}>
                            {copyToClipboardIcon}
                        </div>
                    </div>
                </div>

                {!!coordinate.sources.length && coordinate.sources.includes("MWV") && (
                    <div className="elrRow__logo">
                        <img
                            src={mwvLogo}
                            alt="Machines With Vision Logo"
                            crossOrigin={"anonymous"}
                        />
                    </div>
                )}

                {!isFullscreen && validationIcon}
            </div>
        );
    } else {
        return (
            <div
                className="elrRow"
                key={`${coordinate.route}.${coordinate.subposition}`}>
                {ELRInfo.map((field) => {
                    return (
                        <>
                            <span
                                key={field.name}
                                className="RouteInfoELR__Item">
                                <b>{field.name}:</b> <span className="secondary">{field.value}</span>
                            </span>
                        </>
                    );
                })}

                {!!coordinate.sources.length && coordinate.sources.includes("MWV") && (
                    <div className="elrRow__logo">
                        <img
                            src={mwvLogo}
                            alt="Machines With Vision Logo"
                            crossOrigin={"anonymous"}
                        />
                    </div>
                )}

                {!!coordinate.sources.length && (
                    <LazyTippy
                        placement="top"
                        sticky={true}
                        plugins={[sticky]}
                        arrow={true}
                        theme="aivrlight"
                        interactive="true"
                        allowHTML={true}
                        content={
                            <div className="ElrSources">
                                <p className="Header">Location Sources:</p>
                                {coordinate.sources.map((source) => {
                                    return (
                                        <p
                                            className="Source"
                                            key={source}>
                                            {source}
                                        </p>
                                    );
                                })}
                            </div>
                        }>
                        <div className="CoordinateSources">
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{ marginLeft: 7.5 }}
                            />
                        </div>
                    </LazyTippy>
                )}
                <div
                    className="CopyToClipboard"
                    onClick={() => {
                        navigator.clipboard.writeText(ELRCopyString);
                    }}>
                    {copyToClipboardIcon}
                </div>
                {!isFullscreen && validationIcon}
            </div>
        );
    }
}

const routeLocationSelector = (state) => state.playlist.data.route_locations;
const videolessTimestampSelector = (state) => {
    if (_.get(state, ["playlist", "data", "video", "length"], 0)) {
        return null;
    }
    const currentIndex = state.playlist.position.currentIndex;
    const railImages = state.railInspection.railInspectionImages.data;

    let timestamp = 0;
    if (railImages[currentIndex]) {
        const currentImage = railImages[currentIndex];
        timestamp = currentImage.timestamp;
    }
    return timestamp;
};
const videolessSessionSelector = (state) => {
    return !!(state.playlist.data.video?.length === 0 && state.railInspection.railInspectionImages.data.length);
};

function CoordinatesForSystem({ system, classes, hud }) {
    const timestamp = useSelector(timestampSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const playlistPositionCoords = useSelector(playlistPositionCoordsSelector);
    const routeCoordData = useSelector(routeCoordDataSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);
    const videolessSession = useSelector(videolessSessionSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const [displayEstimates, setDisplayEstimates] = useState(false);

    const [loadingCoordinates, setCoordinatesLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setDisplayEstimates(false);
    }, [timestamp]);

    const toggleDisplayEstimates = (value) => {
        setDisplayEstimates(value);
    };

    const currentCoords = useMemo(() => {
        let playlistCoords = playlistPositionCoords;
        if (playlistCoords && playlistCoords[0] !== null && playlistCoords[1] !== null) {
            return playlistCoords;
        } else {
            return null;
        }
    }, [playlistPositionCoords]);

    const groupedDataForSystem = useMemo(() => {
        const retVal = {};
        if (system && system.ID) {
            retVal[system.ID] = _.get(routeCoordData, [system.ID], []);
        }
        return retVal;
    }, [routeCoordData, system]);

    const routeCoordinatesForSystem = useMemo(() => {
        if (!displayEstimates) {
            let timestampToUse = timestamp;
            if (videolessSession) {
                timestampToUse = videolessTimestamp;
            }
            if (!timestampToUse) {
                return [];
            }
            let location = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);
            if (location) {
                return [location];
            } else {
                return [];
            }
        } else {
            if (!currentCoords || !system) {
                return [];
            }
            return calculateELREstimates(currentCoords, groupedDataForSystem, [system.ID]);
        }
    }, [timestamp, routeLocationData, displayEstimates, currentCoords, groupedDataForSystem, system, videolessTimestamp, videolessSession, routeSystemID]);

    useEffect(() => {
        if (routeCoordinatesForSystem.length) {
            setCoordinatesLoading(false);
        }
    }, [routeCoordinatesForSystem]);

    const triggerCoordinateLookup = useCallback(() => {
        setCoordinatesLoading(true);
        dispatch(getRouteSystemCoords(currentCoords));
    }, [currentCoords, dispatch]);

    let routePositionItems = useMemo(() => {
        if (routeCoordinatesForSystem && routeCoordinatesForSystem.length) {
            return routeCoordinatesForSystem.map((coordinateSystem) => {
                if (!coordinateSystem) {
                    return null;
                } else {
                    return (
                        <RouteCoordinateDisplay
                            key={`${coordinateSystem.route}.${coordinateSystem.subposition}`}
                            coordinate={coordinateSystem}
                            estimate={displayEstimates}
                            setDisplayEstimates={toggleDisplayEstimates}
                            triggerCoordinateLookup={triggerCoordinateLookup}
                            hud={hud}
                        />
                    );
                }
            });
        } else {
            return false;
        }
    }, [routeCoordinatesForSystem, displayEstimates, triggerCoordinateLookup]);

    if (!routePositionItems && !loadingCoordinates) {
        return <span className="secondary">No location data</span>;
    } else if (!routePositionItems && loadingCoordinates) {
        routePositionItems = (
            <div className="elrSpinnerContainer">
                <Spin className="elrValidationSpinner" />
            </div>
        );
    }

    return <div className={hud ? "HUD-RouteInfoELR__ContainerList" : "RouteInfoELR__ContainerList"}>{routePositionItems}</div>;
}

export function SystemCoordinates({ classes, hud = false }) {
    const routeIsLoaded = useSelector(routeIsLoadedSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const coordinates = useMemo(() => {
        const coordSystemToUse = _.find(RouteCoordinateSystems, (system) => routeSystemID === system.ID);
        if (coordSystemToUse) {
            return (
                <CoordinatesForSystem
                    classes={classes}
                    key={coordSystemToUse.ID}
                    system={coordSystemToUse}
                    hud={hud}
                />
            );
        }
        return null;
    }, [routeSystemID, classes]);

    if (routeIsLoaded) {
        return <>{coordinates && coordinates}</>;
    } else {
        return <span className="secondary">No Route Selected</span>;
    }
}

const deviceGroupInfoSelector = (state) => state.railInspection.deviceGroupInfo;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], EMPTY_ARRAY);

export const RailInspectionInfo = () => {
    const dispatch = useDispatch();
    const deviceGroupInfo = useSelector(deviceGroupInfoSelector);

    const session = useSelector(sessionSelector);
    const sessionID = session.id;

    useEffect(() => {
        dispatch(getDeviceGroupInfo(sessionID));
    }, [dispatch, sessionID]);

    const deviceGroupDatapointPercentages = useMemo(() => {
        let percentages = {};
        if (deviceGroupInfo && deviceGroupInfo.total_datapoints) {
            const totalDps = deviceGroupInfo.total_datapoints;
            const mwvDps = deviceGroupInfo.mwv_datapoints;
            const mwvPercentage = ((mwvDps / totalDps) * 100).toFixed(0);
            const geomDps = deviceGroupInfo.track_geometry_datapoints;
            const geomTotal = deviceGroupInfo.track_geometry_total;
            const geomMWV = deviceGroupInfo.mwv_track_geometry;
            const geomMWVPercentageDecimal = geomMWV / (0.000000001 + geomTotal);
            const geomMWVPercentage = (geomMWVPercentageDecimal * 100).toFixed(0);
            const geomPercentage = ((geomDps / totalDps) * 100).toFixed(0);
            const inspectionDps = deviceGroupInfo.inspection_datapoints;
            const inspectionPercentage = ((inspectionDps / totalDps) * 100).toFixed(0);
            const inspectionTotal = deviceGroupInfo.inspection_total;
            const inspectionMWV = deviceGroupInfo.mwv_inspection;
            const mwvInspectionPercentage = ((100 * inspectionMWV) / (0.0000000001 + inspectionTotal)).toFixed(0);

            if (mwvPercentage > 0) {
                percentages["mwv"] = mwvPercentage;
            }
            if (geomPercentage > 0) {
                percentages["geom"] = geomPercentage;
            }
            if (geomMWVPercentage > 0) {
                percentages["mwv_geom"] = geomMWVPercentage;
            }
            if (inspectionPercentage > 0) {
                percentages["rail"] = inspectionPercentage;
            }
            if (mwvInspectionPercentage > 0) {
                percentages["mwv_inspect"] = mwvInspectionPercentage;
            }
        }
        return percentages;
    }, [deviceGroupInfo]);

    return (
        <div className="RouteInfo-Row">
            {Object.keys(deviceGroupDatapointPercentages).length ? (
                Object.keys(deviceGroupDatapointPercentages).map((key) => {
                    let icon;
                    let tooltip;
                    if (key === "mwv") {
                        icon = (
                            <img
                                src={mwvLogo}
                                alt="Machines With Vision Logo"
                                crossOrigin={"anonymous"}
                            />
                        );
                        tooltip = "Machines With Vision Datapoint Coverage";
                    } else if (key === "mwv_inspect") {
                        icon = (
                            <img
                                src={mwvLogo}
                                alt="Machines With Vision Logo"
                                crossOrigin={"anonymous"}
                            />
                        );
                        tooltip = "Machines With Vision Inspection Coverage";
                    } else if (key === "mwv_geom") {
                        icon = (
                            <img
                                src={mwvLogo}
                                alt="Machines With Vision Logo"
                                crossOrigin={"anonymous"}
                            />
                        );
                        tooltip = "Machines With Vision Geometry Coverage";
                    } else if (key === "geom") {
                        icon = <FontAwesomeIcon icon={faAnalytics} />;
                        tooltip = "Track Geometry Datapoint Coverage";
                    } else if (key === "rail") {
                        icon = <FontAwesomeIcon icon={faCamera} />;
                        tooltip = "Inspection Imagery Coverage";
                    }
                    return (
                        <Tippy
                            placement="right"
                            content={tooltip}
                            theme="aivrlight"
                            zIndex="600">
                            <div className="RouteInfo-Altitude">
                                <div className="RouteInfo-Text-Container">
                                    <div className="RouteInfo-BlockItem">
                                        {icon}
                                        {deviceGroupDatapointPercentages[key]}%
                                    </div>
                                </div>
                            </div>
                        </Tippy>
                    );
                })
            ) : (
                <div className="RouteInfo-Altitude">
                    <div className="RouteInfo-Text-Container">
                        <span className="secondary">No Information Available</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const EnvironmentDataPanel = () => {
    const sessionEnvironmentData = useSelector(environmentDataSelector);

    const timestamp = useSelector(timestampSelector);
    const videolessSession = useSelector(videolessSessionSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const routeIsLoaded = useSelector(routeIsLoadedSelector);

    const envDataToDisplay = useMemo(() => {
        let timestampToUse = timestamp;
        if (videolessSession) {
            timestampToUse = videolessTimestamp;
        }
        if (!timestampToUse) {
            return [];
        }
        let location = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);

        let envDatas = findEnvironmentalData(sessionEnvironmentData, location);
        if (!envDatas.length) {
            return (
                <div className="EnvDatasContainer">
                    <span>No Data Available</span>
                </div>
            );
        }

        const groupedDatas = _.groupBy(envDatas, "display_data_type");

        return (
            <div className="EnvDatasContainer">
                {Object.keys(groupedDatas).map((groupName) => {
                    const datas = groupedDatas[groupName];
                    return (
                        <div className="EnvDataContainer">
                            <span className="GroupHeader">{groupName}</span>
                            {datas.flatMap((dataObj) => {
                                return Object.keys(dataObj.display_fields).map((field) => {
                                    return (
                                        <div className="EnvDataContainerInfo">
                                            <span className="Header">{field}:</span>
                                            <span className="Value">{dataObj.display_fields[field]}</span>
                                        </div>
                                    );
                                });
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }, [timestamp, videolessSession, routeLocationData, routeSystemID, sessionEnvironmentData, videolessTimestamp]);

    if (!routeIsLoaded) {
        return <span className="secondary">{"No Route Selected"}</span>;
    }

    return <div>{envDataToDisplay}</div>;
};

export const WeatherStationPanel = ({ weatherData, routeID }) => {
    const elements = useMemo(() => {
        let elements = [];

        if (weatherData) {
            if (weatherData.name) {
                elements.push({
                    name: weatherData.name,
                    value: null,
                    icon: faHome,
                });
            }

            if (weatherData.air_temp_c) {
                elements.push({
                    name: "Temperature",
                    value: weatherData.air_temp_c + "°C",
                    icon: faTemperatureHigh,
                });
            }
            if (weatherData.rainfall_last_hour_mm) {
                elements.push({
                    name: "Rainfall",
                    value: weatherData.rainfall_last_hour_mm + "mm",
                    icon: faCloudRain,
                });
            }

            if (weatherData.w_dir) {
                const directions = {
                    N: "North",
                    NNE: "North-Northeast",
                    NE: "Northeast",
                    ENE: "East-Northeast",
                    E: "East",
                    ESE: "East-Southeast",
                    SE: "Southeast",
                    SSE: "South-Southeast",
                    S: "South",
                    SSW: "South-Southwest",
                    SW: "Southwest",
                    WSW: "West-Southwest",
                    W: "West",
                    WNW: "West-Northwest",
                    NW: "Northwest",
                    NNW: "North-Northwest",
                };
                elements.push({
                    name: "Wind Direction",
                    value: _.get(directions, weatherData.w_dir, weatherData.w_dir),
                    icon: faWindsock,
                });
            }
        }
        return elements;
    }, [weatherData]);

    return (
        <div className="RouteInfo-Row">
            {!!elements.length &&
                _.map(elements, (element) => {
                    return (
                        <div className="RouteInfo-Altitude">
                            <div className="RouteInfo-Text-Container">
                                <Tippy
                                    arrow={true}
                                    placement="top"
                                    content={element.name}
                                    theme="aivrlight"
                                    zIndex="600">
                                    <div
                                        className={`RouteInfo-BlockItem ${element.name}`}
                                        style={!element.value ? { gap: 0 } : {}}>
                                        <FontAwesomeIcon icon={element.icon} />
                                        <span className="secondary">{element.value}</span>
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

const coordsSelector = (state) => state.playlist.position.coords;

const RouteInformation = () => {
    const dispatch = useDispatch();
    const openInfoPanels = useSelector(openInfoPanelsSelector);
    const isFullscreen = useSelector(fullscreenSelector);
    const [unitSelectionModalVisible, setUnitSelectionModalVisible] = useState();
    const routeID = useSelector(routeIDSelector);
    const reduxCoords = useSelector(coordsSelector);
    const [initialTs, setInitialTs] = useState(0);
    const [refreshingWeatherData, setRefreshingWeatherData] = useState(false);
    const [weatherData, setWeatherData] = useState(null);
    const [weatherCoords, setWeatherCoords] = useState(null);
    const currentTs = useSelector(timestampSelector);
    const currentDashboard = useSelector(currentDashboardSelector);

    const pullWeatherData = useCallback(
        (coords, newTs) => {
            setRefreshingWeatherData(true);
            setInitialTs(newTs);
            dispatch(
                getSessionWeatherData(newTs, coords[1], coords[0], (response) => {
                    if (_.isEqual(coords, reduxCoords)) {
                        if (!_.isEmpty(response)) {
                            setWeatherData(response);
                            setWeatherCoords(coords);
                        }
                        setRefreshingWeatherData(false);
                    }
                }),
            );
        },
        [dispatch, reduxCoords],
    );

    useEffect(() => {
        if (reduxCoords && !reduxCoords[0] && !reduxCoords[1]) {
            setWeatherData(null);
        } else if (reduxCoords && reduxCoords[0] && reduxCoords[1]) {
            // this to be run if routeID change
            if (routeID && !_.isEqual(reduxCoords, weatherCoords) && currentTs) {
                const duration = moment.duration(Math.abs(currentTs - initialTs) * 1000);
                if (duration && duration.asMinutes() > 15) {
                    pullWeatherData(reduxCoords, currentTs);
                }
            }
        }
    }, [routeID, currentTs, reduxCoords, initialTs, pullWeatherData, weatherCoords]);

    // if session is closed
    useEffect(() => {
        setWeatherCoords(null);
        setInitialTs(0);
        setWeatherData(null);
    }, [routeID]);

    const showUnitSelectionIcon = useMemo(() => {
        if (!isFullscreen) {
            return (
                <div style={{ display: "flex", gap: 5 }}>
                    <span>ROUTE POSITION (ESTIMATE)</span>
                    <Tippy
                        placement="top"
                        sticky={true}
                        plugins={[sticky]}
                        arrow={true}
                        theme="aivrlight"
                        content="Change Unit System">
                        <div>
                            <FontAwesomeIcon
                                className="RouteInfo-ELRSettings__Icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUnitSelectionModalVisible(true);
                                }}
                                icon={faCog}
                            />
                        </div>
                    </Tippy>
                </div>
            );
        } else {
            return <span>ROUTE POSITION (ESTIMATE)</span>;
        }
    }, [setUnitSelectionModalVisible, isFullscreen]);

    const showWeatherUpdatingIcon = useMemo(() => {
        if (!isFullscreen) {
            return (
                <div style={{ display: "flex", gap: 5 }}>
                    <span>WEATHER STATION</span>
                    {weatherData && (
                        <Tippy
                            placement="top"
                            sticky={true}
                            plugins={[sticky]}
                            arrow={true}
                            theme="aivrlight"
                            content={refreshingWeatherData ? "Updating data..." : "Click to update"}>
                            <div>
                                <FontAwesomeIcon
                                    spin={refreshingWeatherData}
                                    className="RouteInfo-ELRSettings__Icon"
                                    icon={faSyncAlt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        pullWeatherData(reduxCoords, currentTs);
                                    }}
                                />
                            </div>
                        </Tippy>
                    )}
                </div>
            );
        }
    }, [isFullscreen, weatherData, refreshingWeatherData, pullWeatherData, reduxCoords, currentTs]);

    const datumHeader = useMemo(() => {
        return <span className="RouteInfo-Label">DATUM</span>;
    }, []);

    const infoPanelChange = useCallback(
        (openPanels) => {
            dispatch(setUserPreference("openInfoPanels", openPanels));
        },
        [dispatch],
    );

    const activeKeys = useMemo(() => {
        if (openInfoPanels && openInfoPanels.length) {
            return openInfoPanels;
        } else {
            return ["1", "2", "3", "4", "5", "6", "7"];
        }
    }, [openInfoPanels]);

    const environmentTabEnabled = useMemo(() => {
        const enabled = _.get(currentDashboard, ["config", "environmental_data_enabled"], false);
        return enabled;
    }, [currentDashboard]);

    return (
        <React.Fragment>
            <RouteTitleDetails />
            {unitSelectionModalVisible && <UnitSelectionModal onClose={() => setUnitSelectionModalVisible(false)} />}
            <Collapse
                defaultActiveKey={activeKeys}
                ghost
                onChange={infoPanelChange}>
                <Panel
                    header={showUnitSelectionIcon}
                    key="1">
                    <SystemCoordinates unitSelectionModalVisible={unitSelectionModalVisible} />
                </Panel>
                <Panel
                    header="SPEED, BEARING & ALTITUDE"
                    key="2">
                    <PositionalInfo />
                </Panel>
                <Panel
                    header="TIME & DATE"
                    key="time">
                    <VideoTimeDisplay />
                </Panel>
                {weatherData && (
                    <Panel
                        header={showWeatherUpdatingIcon}
                        key="7">
                        <WeatherStationPanel
                            weatherData={weatherData}
                            routeID={routeID}
                        />
                    </Panel>
                )}
                {environmentTabEnabled && (
                    <Panel
                        header="ENVIRONMENTAL DATA"
                        key="8">
                        <EnvironmentDataPanel />
                    </Panel>
                )}
                <Panel
                    header={
                        <div style={{ display: "flex", gap: 5 }}>
                            <span>DETECTIONS</span>
                        </div>
                    }
                    key="3">
                    <MarkerPanel />
                </Panel>
                <Panel
                    header="POSITION"
                    key="4"
                    style={{ position: "relative" }}>
                    <LatLonInfo />
                </Panel>
                <Panel
                    header={datumHeader}
                    key="5">
                    <Datum />
                </Panel>
                <Panel
                    header="RAIL INSPECTION DATA"
                    key="6">
                    <RailInspectionInfo />
                </Panel>
            </Collapse>
        </React.Fragment>
    );
};

export default RouteInformation;
