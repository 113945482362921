import React from "react";
import { useDispatch } from "react-redux";
import DraggableWrapper from "./DraggableWrapper";
import ImageAdjustments from "./ImageAdjustments";
import { toggleSettingsOpen } from "../../../redux/actions/index";
import GridAdjustments from "./GridAdjustments";

const Settings = ({ windowDimensions, showGridSettings, secondaryRailConfig }) => {
    const dispatch = useDispatch();

    const title = (
        <div className="inspectRail__BookmarkList__Title">
            <p>Settings</p>
        </div>
    );

    const content = (
        <>
            <ImageAdjustments secondaryRailConfig={secondaryRailConfig} />
            {showGridSettings && <GridAdjustments />}
        </>
    );

    const closeSettings = () => {
        dispatch(toggleSettingsOpen());
    };

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            closeAction={closeSettings}
            customDimensions={{ x: "200px", y: "auto" }}
            customLocation={{ x: 70, y: 0 }}
        />
    );
};

export default Settings;
