import _ from "lodash";

const TRACK_DIRECTION_LOOKUP = {
    1100: "UP",
    1200: "UP",
    2100: "DOWN",
    2200: "DOWN",
};

export default class ELRMileAndChain {
    static meters_per_chain = 20.1168;
    static display_formats = {
        elr_mile_chain: "ELR Mile and Chains",
        elr_meterage: "ELR and Meterage",
        elr_mile_yards: "ELR Mile and Yards",
    };

    static display_fields = {
        ELR: {
            name: "ELR",
            rules: [{ required: true, min: 1, max: 4 }],
        },
        MILE: {
            name: "Mile",
            rules: [{ required: true, type: "integer" }],
        },
        CHAIN: {
            name: "Chain",
            rules: [{ required: true, type: "integer", min: 0, max: 79 }],
        },
        YARDS: {
            name: "Yards",
            rules: [{ required: true, type: "integer", min: 0, max: 1759 }],
        },
        METERAGE: {
            name: "Meterage",
            rules: [{ required: true, type: "integer" }],
        },
        TRACK: {
            name: "Track ID",
            rules: [{}],
        },
    };

    fields(display_format) {
        return ELRMileAndChain.fields(display_format);
    }

    static fields(display_format) {
        if (display_format === "elr_mile_yards") {
            return ["ELR", "MILE", "YARDS", "TRACK"];
        } else if (display_format === "elr_meterage") {
            return ["ELR", "METERAGE", "TRACK"];
        } else if (display_format === "mobile") {
            //Special display format for mobile, not in standard list
            return ["ELR", "MILE", "CHAIN", "METERAGE", "TRACK"];
        } else {
            return ["ELR", "MILE", "CHAIN", "TRACK"];
        }
    }

    displayFields(display_format) {
        return ELRMileAndChain.displayFields(display_format);
    }

    static displayFields(display_format) {
        if (display_format === "elr_mile_yards") {
            return ["ELR", "TRACK", "MILE", "YARDS"];
        } else if (display_format === "elr_meterage") {
            return ["ELR", "TRACK", "METERAGE"];
        } else if (display_format === "mobile") {
            //Special display format for mobile, not in standard list
            return ["ELR", "TRACK", "MILE", "CHAIN", "METERAGE"];
        } else {
            return ["ELR", "TRACK", "MILE", "CHAIN"];
        }
    }

    field_value(field, datum_offsets) {
        switch (field) {
            case "ELR":
                return this.elr;
            case "MILE":
                return this.mile;
            case "CHAIN":
                return this.chain;
            case "YARDS":
                return this.yard;
            case "METERAGE":
                return this.meterage(datum_offsets);
            case "TRACK":
                return this.track_string();
            default:
                return null;
        }
    }

    track_string() {
        let track = this.track;
        let trackLookup = this.track;
        if (trackLookup && trackLookup.toString) {
            trackLookup = trackLookup.toString();
        }

        const directionLookup = TRACK_DIRECTION_LOOKUP[trackLookup];
        if (directionLookup) {
            track += ` (${directionLookup})`;
        }

        return track;
    }

    static from_fields(system_id, display_format, fields, datum_offsets, isMwv) {
        if (display_format === "elr_mile_yards") {
            const position = fields.MILE * 80 + fields.YARDS / 22;
            return new ELRMileAndChain(system_id, fields.ELR, position, fields.TRACK, isMwv);
        } else if (display_format === "elr_meterage") {
            return ELRMileAndChain.from_meters(system_id, fields.ELR, fields.METERAGE, fields.TRACK, datum_offsets);
        } else {
            const position = fields.MILE * 80 + fields.CHAIN;
            return new ELRMileAndChain(system_id, fields.ELR, position, fields.TRACK);
        }
    }

    static from_search(system_id, fields) {
        const position = parseInt(fields.paramTwo) * 80 + parseInt(fields.paramThree);
        return new ELRMileAndChain(system_id, fields.paramOne, position, null);
    }

    constructor(system_id, route, position, subposition, isMwv, sources) {
        this.system = system_id;
        this.route = this.elr = route;
        this.position = position;
        this.subposition = this.track = subposition;
        this.sources = sources;
        this.sign = position >= 0 ? 1 : -1;
        if (this.sign < 0) {
            position = Math.abs(position);
        }
        this.mile = Math.floor(position / 80);
        this.chain = Math.floor(position - this.mile * 80);
        if (isMwv) {
            this.yard = ((position - this.mile * 80) * 22).toFixed(2);
        } else {
            this.yard = Math.floor((position - this.mile * 80) * 22);
        }
    }

    generalise() {
        return new ELRMileAndChain(this.system, this.route, this.position, null);
    }

    meter_offset(datum_offsets) {
        const base_position = Math.trunc(this.position);
        const meterage = ELRMileAndChain.position_to_meters(this.system, this.elr, this.position, datum_offsets);
        const base_meterage = ELRMileAndChain.position_to_meters(this.system, this.elr, base_position, datum_offsets);
        return Math.round(meterage - base_meterage);
    }

    to_mile_and_chain_string(datum_offsets, hide_offset) {
        const prefix = this.sign < 0 ? "-" : "";
        const chain = Math.floor(this.chain).toString().padStart(2, "0");
        let offset = "";
        if (!hide_offset) {
            const meter_offset = this.meter_offset(datum_offsets);
            if (meter_offset > 0) {
                offset = ` +${meter_offset}m`;
            } else if (meter_offset < 0) {
                offset = ` ${meter_offset}m`;
            }
        }

        return `${prefix}${this.mile}.${chain}${offset}`;
    }

    to_mile_and_yard_string(isMwv) {
        const prefix = this.sign < 0 ? "-" : "";
        let yard;
        if (isMwv) {
            yard = this.yard.toString().padStart(7, "0");
        } else {
            yard = this.yard.toString().padStart(4, "0");
        }
        return `${prefix}${this.mile}m ${yard}y`;
    }

    to_meterage_string(datum_offsets) {
        return `${this.meterage(datum_offsets)}m`;
    }

    to_string(display_format, datum_offsets, isMwv, isHorizontal) {
        let trackLabel = "track ";
        if (isHorizontal) {
            trackLabel = "";
        }
        let position;
        if (display_format === "elr_mile_yards") {
            position = this.to_mile_and_yard_string(isMwv);
        } else if (display_format === "elr_meterage") {
            position = this.to_meterage_string(datum_offsets);
        } else {
            position = this.to_mile_and_chain_string(datum_offsets);
        }
        if (this.track !== null) {
            return `${this.elr} ${trackLabel}${this.track} ${position}`;
        } else {
            return `${this.elr} ${position}`;
        }
    }

    to_simple_string(display_format, datum_offsets, remove_label) {
        let position;
        if (display_format === "elr_mile_yards") {
            position = this.to_mile_and_yard_string();
        } else if (display_format === "elr_meterage") {
            position = this.to_meterage_string(datum_offsets);
        } else {
            position = this.to_mile_and_chain_string(datum_offsets, true);
        }

        if (remove_label) {
            return `${position}`;
        } else {
            return `${this.elr} ${position}`;
        }
    }

    meterage(datum_offsets) {
        return Math.round(ELRMileAndChain.position_to_meters(this.system, this.elr, this.position, datum_offsets));
    }

    static from_meters(system_id, route_id, meters, subposition, datum_offsets) {
        const offsets_for_route = _.chain(datum_offsets)
            .filter((o) => o.system_id === system_id && o.route_id === route_id)
            .sortBy((o) => o.datum_offset)
            .value();

        let position;
        if (offsets_for_route.length === 0) {
            position = meters / ELRMileAndChain.meters_per_chain;
        } else {
            const nearestHigherOffset = _.find(offsets_for_route, (o) => o.datum_offset >= meters);
            const nearestLowerOffset = _.findLast(offsets_for_route, (o) => o.datum_offset < meters);

            let interpolatedOffset;
            let interpolatedPosition;
            if (nearestHigherOffset && nearestLowerOffset) {
                let distanceFromLower = meters - nearestLowerOffset.datum_offset;
                let offsetRatio = 0;
                if (nearestHigherOffset.datum_offset > nearestLowerOffset.datum_offset) {
                    let distanceBetweenOffsets = nearestHigherOffset.datum_offset - nearestLowerOffset.datum_offset;
                    offsetRatio = distanceFromLower / distanceBetweenOffsets;
                }
                interpolatedOffset = nearestHigherOffset.datum_offset * offsetRatio + nearestLowerOffset.datum_offset * (1 - offsetRatio);
                interpolatedPosition = nearestHigherOffset.position * offsetRatio + nearestLowerOffset.position * (1 - offsetRatio);
            } else if (nearestHigherOffset) {
                interpolatedOffset = nearestHigherOffset.datum_offset;
                interpolatedPosition = nearestHigherOffset.position;
            } else {
                interpolatedOffset = nearestLowerOffset.datum_offset;
                interpolatedPosition = nearestLowerOffset.position;
            }

            position = (meters - interpolatedOffset) / ELRMileAndChain.meters_per_chain + interpolatedPosition;
        }

        return new ELRMileAndChain(system_id, route_id, position, subposition);
    }

    static position_to_meters(system_id, elr, position, datum_offsets) {
        const offsets_for_route = _.chain(datum_offsets)
            .filter((o) => o.system_id === system_id && o.route_id === elr)
            .sortBy((o) => o.position)
            .value();

        if (offsets_for_route.length === 0) {
            return position * ELRMileAndChain.meters_per_chain;
        }

        const nearestHigherOffset = _.find(offsets_for_route, (o) => o.position >= position);
        const nearestLowerOffset = _.findLast(offsets_for_route, (o) => o.position < position);

        let interpolatedOffset;
        let interpolatedPosition;
        if (nearestHigherOffset && nearestLowerOffset) {
            let distanceFromLower = position - nearestLowerOffset.position;
            let offsetRatio = 0;
            if (nearestHigherOffset.position > nearestLowerOffset.position) {
                let distanceBetweenOffsets = nearestHigherOffset.position - nearestLowerOffset.position;
                offsetRatio = distanceFromLower / distanceBetweenOffsets;
            }
            interpolatedOffset = nearestHigherOffset.datum_offset * offsetRatio + nearestLowerOffset.datum_offset * (1 - offsetRatio);
            interpolatedPosition = nearestHigherOffset.position * offsetRatio + nearestLowerOffset.position * (1 - offsetRatio);
        } else if (nearestHigherOffset) {
            interpolatedOffset = nearestHigherOffset.datum_offset;
            interpolatedPosition = nearestHigherOffset.position;
        } else {
            interpolatedOffset = nearestLowerOffset.datum_offset;
            interpolatedPosition = nearestLowerOffset.position;
        }

        return (position - interpolatedPosition) * ELRMileAndChain.meters_per_chain + interpolatedOffset;
    }
}
