import React from "react";
import { connect } from "react-redux";
import {
    createShareLink,
    segmentSelection,
    changeToolMode,
    toggleUnsavedChange,
    logEvent,
    toggleSnapshot,
    closeFullscreen,
    toggleFullscreen,
    trimVideo,
    setStillImageAdjustments,
    resetStillImageAdjustments,
    setVideoSpeed,
    setVideoOverlay,
} from "redux/actions/index";
import _ from "lodash";
import DeviceInformation from "../DeviceInformation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AnnotationInterface from "./annotation/AnnotationInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faShareSquare,
    faDownload,
    faCamera,
    faCompress,
    faExpand,
    faCut,
    faSun as solidSun,
    faEyeDropper,
    faThermometerThreeQuarters,
    faObjectUngroup,
} from "@fortawesome/free-solid-svg-icons";
import ToolInterface from "./ToolInterface";
import DistanceViewInterface from "./DistanceViewInterface";
import ExportBar from "./ExportBar";
import MarkupNavigation from "./MarkupNavigation";
import VideoExportModal from "./VideoExportModal";
import AdvancedSightingModal from "./AdvancedSightingModal";
import VideoPlayer from "./VideoPlayer";
import {
    getCurrentPlaylistTS,
    getCurrentVideoKey,
    videoKeyToTimestamp,
    absoluteTimeLookup,
    source_is_calibrated,
    get_source_calibration,
    getAbsoluteTimestamp,
} from "../../util/PlaylistUtils";
import ImagePlayer from "../image/ImagePlayer";
import SnapshotModal from "./SnapshotModal";
import FeaturePlacementInterface from "./FeaturePlacementInterface";
import ThreeDFeatureOverlay from "./3d/3dFeatureOverlay";
import ZoomInterface from "./ZoomInterface";
import FullScreenCapable from "../FullScreenCapable";
import { WithAspectRatio } from "../../util/WithAspectRatio";
import { Popover, notification, Tooltip, Slider, Select, Checkbox, Badge, InputNumber } from "antd";
import { faSlidersH, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { faAdjust as lightAdjust, faSun as lightSun } from "@fortawesome/pro-light-svg-icons";
import {
    faArchway,
    faArrowToLeft,
    faArrowToRight,
    faChevronDown,
    faExclamationTriangle,
    faTimes,
    faAdjust as solidAdjust,
} from "@fortawesome/pro-regular-svg-icons";
import OBCButton from "components/OBC/OBCButton";
import { Link } from "react-router-dom";

const { Option, OptGroup } = Select;

class VideoComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fullscreenComponent = React.createRef();
        this.toolInterfaceRef = React.createRef();
        this.popoverMountPoint = React.createRef();
        this.overlayButtonRef = React.createRef();
        this.popoverOverlaysModalRef = React.createRef();
        this.speedSelectRef = React.createRef();

        this.state = {
            showCopyLinkTooltip: false,
            copyLinkHovered: false,
            copyLinkClicked: false,
            shareLinkURL: null,
            routeStartTime: false,
            routeEndTime: false,
            showExportModal: false,
            exporting: false,
            trimming: false,
            showAdvancedSighting: false,
            expandedOverlayName: null,
            measureMode: false,
            overlaysPopoverOpen: false,
            shouldOverlayFocusContent: false,
            showOverlayContent: true,
            displayAbsoluteThermalImage: false,
            displayPickTemperature: false,
        };
    }

    componentDidMount() {
        window.addEventListener("keyup", this.handleKeyUp);

        if (!this.props.isCalibrated || !this.props.sessionHasDirectionTag) {
            this.disableAllOverlays();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyUp);
    }

    disableAllOverlays = () => {
        if (_.get(this.props.safecessOverlay, "enabled", false)) {
            this.selectOverlayType("Safe Cess", true);
        }
        if (_.get(this.props.tunnelOverlay, "enabled", false)) {
            this.selectOverlayType("Tunnel", true);
        }
        if (_.get(this.props.groundClearanceOverlay, "enabled", false)) {
            this.selectOverlayType("Ground Clearance", true);
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.routeID !== this.props.routeID) {
            this.exitExportMode();
        }

        // if calibration changes
        if (prevProps.isCalibrated !== this.props.isCalibrated && !this.props.isCalibrated) {
            this.disableAllOverlays();
        }
        // if sessionHasDirectionTag has changed and session do not have forward or backward tag
        if (prevProps.sessionHasDirectionTag !== this.props.sessionHasDirectionTag && !this.props.sessionHasDirectionTag) {
            this.disableAllOverlays();
        }

        // Check if the Popover is visible and shouldFocusContent is true, if so focus on first element in the popover (this might change based on workspace config)
        if (this.state.overlaysPopoverOpen && this.state.shouldOverlayFocusContent) {
            const div = this.popoverOverlaysModalRef.current;
            if (div) {
                const firstFocusableElement = div.querySelector(
                    "input:not([disabled]), button:not([disabled]), select:not([disabled]), textarea:not([disabled])",
                );

                if (firstFocusableElement) {
                    // Focus the first focusable element
                    firstFocusableElement.focus();
                }
            }
            this.setState({ shouldOverlayFocusContent: false });
        }

        if (this.props.selectedTagCategory !== prevProps.selectedTagCategory && !this.props.showOverlayContent) {
            this.setState({
                showOverlayContent: true,
            });
        }
    };

    handleKeyUp = (event) => {
        // if overlay popover is open
        if (this.state.overlaysPopoverOpen) {
            if (event.key === "Tab") {
                const div = this.popoverOverlaysModalRef.current;
                const focusedElement = document.activeElement;

                if (div && !div.contains(focusedElement)) {
                    this.setState(
                        {
                            overlaysPopoverOpen: false,
                            shouldOverlayFocusContent: false,
                        },
                        () => {
                            // if Tab + Shift pressed
                            if (event.shiftKey) {
                                this.overlayButtonRef.current.focus();
                            } else {
                                this.speedSelectRef.current.focus();
                            }
                        },
                    );
                }
            } else if (event.key === "Escape") {
                this.setState(
                    {
                        overlaysPopoverOpen: false,
                        shouldOverlayFocusContent: false,
                    },
                    () => this.overlayButtonRef.current.focus(),
                );
            }
        }
    };

    showExportModal = () => {
        const _this = this;
        let allVideo = this.props.video;

        let startClipIndex = _.findIndex(allVideo, function (vid) {
            return vid[0] === _this.state.routeStartTime.videoKey;
        });

        let endClipIndex = _.findIndex(allVideo, function (vid) {
            return vid[0] === _this.state.routeEndTime.videoKey;
        });

        console.log("startClipIndex", startClipIndex);
        console.log("endClipIndex", endClipIndex);

        if (startClipIndex > -1 && endClipIndex > -1) {
            let totalSegments = Math.max(endClipIndex, startClipIndex) - Math.min(endClipIndex, startClipIndex);
            console.log("totalSegments", totalSegments);
            if (totalSegments > 60 * 30) {
                this.setState({
                    exportLengthError: "This export will be trimmed to the first 30 minutes",
                });
            }
        }

        this.props.dispatch(closeFullscreen());

        this.setState({
            showExportModal: true,
        });
    };

    createTrim = (reset = false) => {
        const _this = this;
        let startTS = 0;
        let endTS = 0;

        if (!reset) {
            startTS = this.state.routeStartTime.timestamp;
            endTS = this.state.routeEndTime.timestamp;
        }

        this.props.dispatch(
            trimVideo(this.props.routeID, startTS, endTS, function (success) {
                if (success) {
                    notification.success({ message: "Successfully submitted trimming request" });
                } else {
                    notification.error({ message: "Error submitting trimming request" });
                }
                _this.exitExportMode();
            }),
        );
    };

    hideExportModal = () => {
        this.setState({
            showExportModal: false,
        });
    };

    startExport = () => {
        this.setState({
            exporting: true,
        });
    };

    startTrim = () => {
        this.setState({
            trimming: true,
        });
    };
    toggleRouteStartTime = (timestamp) => {
        console.log("Toggling route start time", timestamp);

        let trimTimestamp = videoKeyToTimestamp(this.props.currentVideoKey);
        let videoKey = this.props.currentVideoKey;
        let playerTimestamp = this.props.currentPlaylistTS;
        let videoKeyIndex;

        if (timestamp) {
            trimTimestamp = timestamp;
            videoKeyIndex = absoluteTimeLookup(timestamp / 1000, this.props.video) || 0;
            videoKey = this.props.video[videoKeyIndex][0];
            playerTimestamp = this.props.video[videoKeyIndex][1];
        }

        this.setState(
            {
                routeStartTime: {
                    text: "Snippet selection start",
                    name: "Snippet selection start",
                    snapshot: this.props.snapshotBaseURL + videoKey + ".jpg",
                    time: playerTimestamp,
                    class: "videoStartMarker",
                    videoKey: videoKey,
                    timestamp: trimTimestamp,
                },
            },
            () => {
                this.projectSegmentOnMap();
            },
        );
        if (this.state.routeEndTime && this.state.exporting) {
            this.startExport();
        }
    };

    toggleRouteEndTime = (timestamp) => {
        console.log("Toggling route end time", timestamp);

        let trimTimestamp = videoKeyToTimestamp(this.props.currentVideoKey);
        let videoKey = this.props.currentVideoKey;
        let playerTimestamp = this.props.currentPlaylistTS;
        let videoKeyIndex;

        if (timestamp) {
            trimTimestamp = timestamp;
            videoKeyIndex = absoluteTimeLookup(timestamp / 1000, this.props.video) || 0;
            videoKey = this.props.video[videoKeyIndex][0];
            playerTimestamp = this.props.video[videoKeyIndex][1];
        }

        this.setState(
            {
                routeEndTime: {
                    text: "Snippet selection end",
                    name: "Snippet selection end",
                    snapshot: this.props.snapshotBaseURL + videoKey + ".jpg",
                    time: playerTimestamp - 0.1,
                    class: "videoEndMarker",
                    videoKey: videoKey,
                    timestamp: trimTimestamp,
                },
            },
            () => {
                this.projectSegmentOnMap();
            },
        );
        if (this.state.routeStartTime && this.state.exporting) {
            this.startExport();
        }
    };

    projectSegmentOnMap = () => {
        let allVideos = this.props.video;
        let routeStartVidKey = this.state.routeStartTime.videoKey;
        let routeEndVidKey = this.state.routeEndTime.videoKey;
        let startObjIndex = _.findIndex(allVideos, function (vid) {
            return vid[0] === routeStartVidKey;
        });
        let endObjIndex = _.findIndex(allVideos, function (vid) {
            return vid[0] === routeEndVidKey;
        });
        this.props.dispatch(segmentSelection(this.props.routeID, startObjIndex, endObjIndex));
    };

    exitExportMode = () => {
        this.setState(
            {
                trimming: false,
                exporting: false,
                routeStartTime: false,
                routeEndTime: false,
            },
            function () {
                this.projectSegmentOnMap();
            },
        );
    };

    shareLinkCreated = (link_token) => {
        let shareURL = window.location.href.replace(/\?.*/, "");
        shareURL += "?share=" + link_token;
        this.setState({
            shareLinkURL: shareURL,
        });
        navigator.clipboard.writeText(shareURL);
    };

    createPrivateShareLink = (sessionID, timestamp, enhanced) => {
        let shareURL = `${window.location.origin}/share/${sessionID}`;
        shareURL = `${shareURL}/${timestamp}/${this.props.dashboard.access_id}`;

        if (enhanced === "enhanced") {
            shareURL += "?ev=2";
        } else if (enhanced === "low_res") {
            shareURL += "?ev=1";
        }
        this.setState({
            shareLinkURL: shareURL,
        });
        navigator.clipboard.writeText(shareURL);
    };

    onClickShare = () => {
        let sessionID = this.props.routeID;
        let timestamp = this.props.currentPlaylistTS;
        let enhanced = this.props.isEnhanced;

        if (this.props.share_links_are_private) {
            this.createPrivateShareLink(sessionID, parseInt(timestamp), enhanced);
        } else {
            this.props.dispatch(createShareLink(sessionID, timestamp, enhanced, this.shareLinkCreated));
        }
    };

    hideTooltip = () => {
        this.setState({
            copyLinkHovered: false,
            copyLinkClicked: false,
        });
    };

    handleHoverChange = (visible) => {
        this.setState({
            copyLinkHovered: visible,
            copyLinkClicked: false,
        });
    };

    handleClickChange = (visible) => {
        setTimeout(() => {
            this.setState({
                copyLinkClicked: visible,
                copyLinkHovered: false,
            });
        }, 150);
    };

    handlePlaybackSpeedChange = (speed) => {
        this.props.dispatch(setVideoSpeed(Number(speed)));
    };

    shareTooltipContent = () => {
        let shareURL = this.state.shareLinkURL;
        return (
            <React.Fragment>
                <CopyToClipboard
                    options={{ format: "text/plain" }}
                    text={shareURL}>
                    <div
                        className="CopyToClipboard"
                        onClick={this.hideTooltip}>
                        <div>
                            <small>{shareURL}</small>
                        </div>
                    </div>
                </CopyToClipboard>
            </React.Fragment>
        );
    };

    renderAnnotationInterface = () => {
        return (
            <AnnotationInterface
                fullscreenComponent={this.fullscreenComponent}
                onClose={() => {
                    this.changeToolMode(false);
                }}
            />
        );
    };

    renderToolInterface = () => {
        const measureMode = this.state.measureMode;
        if (measureMode) {
            this.setState({
                measureMode: false,
            });
        }
        return (
            <ToolInterface
                exitMarkupMode={this.exitMarkupMode}
                fullscreenComponent={this.fullscreenComponent}
                openMeasureCalibrationMode={measureMode}
                ref={this.toolInterfaceRef}
            />
        );
    };

    renderDistanceViewInterface = () => {
        return (
            <DistanceViewInterface
                onClose={() => {
                    this.changeToolMode(false);
                }}
            />
        );
    };

    renderFeaturePlacementInterface = () => {
        return (
            <FeaturePlacementInterface
                onClose={() => {
                    this.changeToolMode(false);
                }}
            />
        );
    };

    renderZoomInterface = () => {
        return (
            <ZoomInterface
                onClose={() => {
                    this.props.dispatch(changeToolMode(false));
                }}
            />
        );
    };

    changeToolMode = (toolMode) => {
        if (!this.props.shareLink && !this.state.videoLoading) {
            this.props.dispatch(changeToolMode(toolMode));
            this.props.dispatch(toggleUnsavedChange(false));
            this.exitExportMode();
        }
    };

    openAdvancedSighting = () => {
        if (this.props.shareLink) {
            return;
        }
        this.props.dispatch(logEvent("Advanced Sighting", "Open"));
        this.setState({
            showAdvancedSighting: true,
        });
        this.props.dispatch(closeFullscreen());
    };

    closeAdvancedSighting = () => {
        this.props.dispatch(logEvent("Advanced Sighting", "Close"));
        this.setState({
            showAdvancedSighting: false,
        });
    };

    toggleFullscreen = () => {
        this.props.dispatch(toggleFullscreen());
    };

    toggleSnapshot = () => {
        if (!this.props.shareLink) {
            this.props.dispatch(toggleSnapshot());
        }
    };

    handleChange = (val, adjustmentType) => {
        this.props.dispatch(setStillImageAdjustments(val, adjustmentType, this.props.sourceIndex));
    };

    handleReset = () => {
        this.props.dispatch(resetStillImageAdjustments());
    };

    renderImageAdjustments = () => {
        return (
            <>
                <div className="inspectRail__Adjustments__Item-Container">
                    <div className="titleContainer">
                        <p>Image Adjustments</p>
                        <FontAwesomeIcon
                            icon={faUndo}
                            onClick={this.handleReset}
                        />
                    </div>

                    <div className="inspectRail__Adjustments__Item">
                        <Tooltip
                            title="Brightness"
                            placement="top">
                            <FontAwesomeIcon icon={lightSun} />
                        </Tooltip>
                        <Slider
                            step={0.1}
                            min={-1}
                            max={1}
                            onChange={(e) => this.handleChange(e, "brightness")}
                            value={_.get(this.props.currentStillImageAdjustments, [this.props.sourceIndex, "brightness"], 0)}
                        />
                        <FontAwesomeIcon icon={solidSun} />
                    </div>
                    <div className="inspectRail__Adjustments__Item">
                        <Tooltip
                            title="Contrast"
                            placement="top">
                            <FontAwesomeIcon icon={lightAdjust} />
                        </Tooltip>
                        <Slider
                            step={0.1}
                            min={-1}
                            max={1}
                            onChange={(e) => this.handleChange(e, "contrast")}
                            value={_.get(this.props.currentStillImageAdjustments, [this.props.sourceIndex, "contrast"], 0)}
                        />
                        <FontAwesomeIcon icon={solidAdjust} />
                    </div>
                </div>
            </>
        );
    };

    exportsEnabled = () => {
        return _.get(this.props.dashboard, ["config", "exports_enabled"], true);
    };

    shareLinkEnabled = () => {
        return _.get(this.props.dashboard, ["config", "share_links_enabled"], true);
    };

    expandOverlaySettings = (overlayName) => {
        if (this.state.expandedOverlayName !== overlayName) {
            this.setState({
                expandedOverlayName: overlayName,
            });
        } else {
            this.setState({
                expandedOverlayName: null,
            });
        }
    };

    selectOverlayType = (overlayType, forceDisable = false) => {
        const overlayCurrentSettings = _.get(this.props.allOverlays, overlayType, null);

        if (overlayCurrentSettings) {
            const newOverlaySettings = _.clone(overlayCurrentSettings);

            // if forceDisable true set enabled to false
            newOverlaySettings["enabled"] = forceDisable ? false : !overlayCurrentSettings["enabled"];
            this.props.dispatch(setVideoOverlay(overlayType, newOverlaySettings));
        }
    };

    changeOverlaySetting = (overlayType, key, value) => {
        const overlayCurrentSettings = _.get(this.props.allOverlays, overlayType, null);
        let newOverlaySettings = _.clone(overlayCurrentSettings);
        newOverlaySettings[key] = ["width", "height"].includes(key) ? value * 1000 : value;
        this.props.dispatch(setVideoOverlay(overlayType, newOverlaySettings));

        // Ground Clearance is specifically for DLI and below is to link Ground clearance width when tunnel width
        // we might in the future move this to the config?
        if (_.includes(this.props.overlaysEnabled, "Ground Clearance") && key === "width") {
            if (overlayType === "Ground Clearance") {
                const tunnelCurrentSettings = _.get(this.props.allOverlays, "Tunnel", null);
                let newTunnelOverlaySettings = _.clone(tunnelCurrentSettings);
                newTunnelOverlaySettings["width"] = value * 2000 + this.props.railSeparation * 1000;
                this.props.dispatch(setVideoOverlay("Tunnel", newTunnelOverlaySettings));
            } else if (overlayType === "Tunnel") {
                const groundClearanceCurrentSettings = _.get(this.props.allOverlays, "Ground Clearance", null);
                let newGroundClearancelOverlaySettings = _.clone(groundClearanceCurrentSettings);
                newGroundClearancelOverlaySettings["width"] = (value * 1000 - this.props.railSeparation * 1000) / 2;
                this.props.dispatch(setVideoOverlay("Ground Clearance", newGroundClearancelOverlaySettings));
            }
        }
    };

    overlayBadgeCount = () => {
        let count = _.filter(this.props.allOverlays, (overlay) => overlay.enabled);
        return Object.keys(count).length;
    };

    sessionCalibrationButtonEnabled = () => {
        if (this.props.isCalibrated && !this.props.allowToResetCalibrationWithoutOverlaySelection) {
            const safecessOverlayEnabled = _.get(this.props.safecessOverlay, "enabled", false);
            const tunnelOverlayEnabled = _.get(this.props.tunnelOverlay, "enabled", false);
            const groundClearanceOverlayEnabled = _.get(this.props.groundClearanceOverlay, "enabled", false);
            if (safecessOverlayEnabled || tunnelOverlayEnabled || groundClearanceOverlayEnabled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    isPatronusSession = () => {
        if (this.props.video?.length) {
            const videoData = this.props.video[0];
            if (videoData?.length) {
                const parts = videoData[0].split(".");
                if (parts[parts.length - 1] === "pTR") {
                    return true;
                }
            }
        }

        return false;
    };

    closeOverlaysPopover = () => {
        this.setState({ overlaysPopoverOpen: false });
        if (this.overlayButtonRef && this.overlayButtonRef.current) {
            this.overlayButtonRef.current.focus();
        }
    };

    render = () => {
        let enhancedIndex = 0;
        if (this.props.isEnhanced === "enhanced") {
            enhancedIndex = this.props.currentSession.first_seen > 1631710005 ? 2 : 1;
        } else if (this.props.isEnhanced === "low_res") {
            enhancedIndex = 1;
        }

        let typeIndex = this.props.isStills * (this.props.currentSession.first_seen > 1631710005 ? 2 : 1) + enhancedIndex * 1;
        const selectedStreamReference = `${this.props.sourceIndex}.${typeIndex}`;

        const inOverlayInterface = this.props.toolMode;

        const overlaysPopoverContent = (
            <div
                ref={this.popoverOverlaysModalRef}
                className="OverlaysPopoverMain">
                <div className="VideoControlsOverlayMainTitle">
                    <span className="VideoControlsOverlayMainTitleText">{`Overlay ${this.props.sessionHasDirectionTag ? "Modes" : "Not Available"}`}</span>
                    <button
                        className="VideoControlsOverlayMainTitleCloseButton"
                        onClick={this.closeOverlaysPopover}
                        tabIndex={0}
                        aria-label="Close Overlays popover">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="OverlayMainContent">
                    <div className="OverlayMainList">
                        {/* Safe Cess */}
                        {_.includes(this.props.overlaysEnabled, "Safe Cess") && (
                            <div
                                className={`OverlayListItem ${this.state.expandedOverlayName === "Safe Cess" ? "OverlayIsExpanded" : "OverlayIsCollapse"} ${!this.props.isCalibrated ? "Disabled" : ""}`}>
                                <div className="OverlayListItemTop">
                                    <Checkbox
                                        onChange={() => this.selectOverlayType("Safe Cess")}
                                        checked={_.get(this.props.safecessOverlay, "enabled", false)}
                                        disabled={!this.props.isCalibrated}
                                        aria-label="Safe Cess"
                                    />
                                    <div
                                        className="OverlayListItemTopRight"
                                        tabIndex={this.props.isCalibrated ? 0 : -1}
                                        role="group"
                                        aria-label="Safe Cess Settings"
                                        onKeyDown={(e) => {
                                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                this.expandOverlaySettings("Safe Cess");
                                            }
                                        }}>
                                        <div
                                            className="OverlayListItemIconAndLabel SafeCess"
                                            onClick={() => this.expandOverlaySettings("Safe Cess")}>
                                            <FontAwesomeIcon icon={faArrowToLeft} />
                                            <div className="OverlayListItemLabel">Safe Cess</div>
                                        </div>
                                        <div
                                            className="OverlayListItemExpandButton"
                                            onClick={() => this.expandOverlaySettings("Safe Cess")}>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`OverlayListItemBottom${this.state.expandedOverlayName === "Safe Cess" ? "-Expanded" : ""}`}>
                                    {/* side */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Side:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <Select
                                                defaultValue={"both"}
                                                dropdownClassName="OverlayListItemFormItemControlDropdown"
                                                getPopupContainer={() => this.popoverOverlaysModalRef.current}
                                                onChange={(e) => this.changeOverlaySetting("Safe Cess", "mode", e)}
                                                size="small"
                                                value={_.get(this.props.safecessOverlay, "mode")}
                                                disabled={!this.props.isCalibrated}>
                                                <Option value="both">Both sides</Option>
                                                <Option value={"left"}>Left only</Option>
                                                <Option value={"right"}>Right only</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    {/* Speed */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Line Speed:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <Select
                                                defaultValue={"-100"}
                                                dropdownClassName="OverlayListItemFormItemControlDropdown"
                                                getPopupContainer={() => this.popoverOverlaysModalRef.current}
                                                onChange={(e) => this.changeOverlaySetting("Safe Cess", "zoneSpeed", e)}
                                                size="small"
                                                value={_.get(this.props.safecessOverlay, "zoneSpeed")}
                                                disabled={!this.props.isCalibrated}>
                                                <Option value="-100">Up to 100Mph</Option>
                                                <Option value="+100">Above 100Mph</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    {/* legend */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayImageInstructions">
                                            {_.get(this.props.safecessOverlay, "zoneSpeed") === "-100" ? (
                                                <>
                                                    <div className="OverlayImageInstructionsRed">0-{this.props.safeCessDefaultRedWidth}m</div>
                                                    <div className="OverlayImageInstructionsAmber">
                                                        {this.props.safeCessDefaultRedWidth}-
                                                        {this.props.safeCessDefaultRedWidth + this.props.safeCessDefaultAmberWidth}m
                                                    </div>
                                                    <div className="OverlayImageInstructionsGreen">
                                                        {this.props.safeCessDefaultRedWidth + this.props.safeCessDefaultAmberWidth}m+
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="OverlayImageInstructionsRed">0-{this.props.safeCessDefaultRedWidthOver100}m</div>
                                                    <div className="OverlayImageInstructionsAmber">
                                                        {this.props.safeCessDefaultRedWidthOver100}-
                                                        {this.props.safeCessDefaultRedWidthOver100 + this.props.safeCessDefaultAmberWidthOver100}m
                                                    </div>
                                                    <div className="OverlayImageInstructionsGreen">
                                                        {this.props.safeCessDefaultRedWidthOver100 + this.props.safeCessDefaultAmberWidthOver100}m+
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Ground Clearance */}
                        {_.includes(this.props.overlaysEnabled, "Ground Clearance") && (
                            <div
                                className={`OverlayListItem  ${this.state.expandedOverlayName === "Ground Clearance" ? "OverlayIsExpanded" : "OverlayIsCollapse"}  ${!this.props.isCalibrated ? "Disabled" : ""}`}>
                                <div className="OverlayListItemTop">
                                    <Checkbox
                                        onChange={() => this.selectOverlayType("Ground Clearance")}
                                        checked={_.get(this.props.groundClearanceOverlay, "enabled", false)}
                                        tabIndex={this.props.isCalibrated ? 0 : -1}
                                        disabled={!this.props.isCalibrated}
                                        aria-label="Ground Clearance"
                                    />
                                    <div
                                        className="OverlayListItemTopRight"
                                        tabIndex={this.props.isCalibrated ? 0 : -1}
                                        role="group"
                                        aria-label="Ground Clearance Settings"
                                        onKeyDown={(e) => {
                                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                this.expandOverlaySettings("Ground Clearance");
                                            }
                                        }}>
                                        <div
                                            className="OverlayListItemIconAndLabel GroundClearance"
                                            onClick={() => this.expandOverlaySettings("Ground Clearance")}>
                                            <FontAwesomeIcon icon={faArrowToRight} />
                                            <div className="OverlayListItemLabel">Ground Clearance</div>
                                        </div>
                                        <div
                                            className="OverlayListItemExpandButton"
                                            onClick={() => this.expandOverlaySettings("Ground Clearance")}>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`OverlayListItemBottom OverlayListItemBottom${this.state.expandedOverlayName === "Ground Clearance" ? "-Expanded" : ""}`}>
                                    {/* side */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Side:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <div className="OverlayListItemFormItemControlSelect">
                                                {/* below we are using html select element instead of due to accessibility issues with DLI... */}
                                                {/* bare that in mind when changing anything here! */}
                                                <select
                                                    disabled={!this.props.isCalibrated}
                                                    aria-label="Ground Clearance Side"
                                                    defaultValue={"left"}
                                                    value={_.get(this.props.groundClearanceOverlay, "mode")}
                                                    onChange={(e) => {
                                                        this.changeOverlaySetting("Ground Clearance", "mode", e.target.value);
                                                    }}>
                                                    <option value={"left"}>Left only</option>
                                                    <option value={"right"}>Right only</option>
                                                </select>
                                                <div className="select_arrow"></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* width */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Width:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <InputNumber
                                                size="small"
                                                max={5}
                                                step={0.1}
                                                precision={3}
                                                min={0.5}
                                                disabled={!this.props.isCalibrated}
                                                onChange={(e) => this.changeOverlaySetting("Ground Clearance", "width", e)}
                                                value={_.get(this.props.groundClearanceOverlay, "width") / 1000}
                                                formatter={(v) => `${v} m`}
                                                parser={(v) => v.replace(/[^0-9.]/g, "")}
                                                aria-label="Ground Clearance width"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Tunnel */}
                        {_.includes(this.props.overlaysEnabled, "Tunnel") && (
                            <div
                                className={`OverlayListItem  ${this.state.expandedOverlayName === "Tunnel" ? "OverlayIsExpanded" : "OverlayIsCollapse"} ${!this.props.isCalibrated ? "Disabled" : ""}`}>
                                <div className="OverlayListItemTop">
                                    <Checkbox
                                        onChange={() => this.selectOverlayType("Tunnel")}
                                        checked={_.get(this.props.tunnelOverlay, "enabled", false)}
                                        disabled={!this.props.isCalibrated}
                                        aria-label="Tunnel"
                                    />
                                    <div
                                        className="OverlayListItemTopRight"
                                        tabIndex={this.props.isCalibrated ? 0 : -1}
                                        role="group"
                                        aria-label="Tunnel Settings"
                                        onKeyDown={(e) => {
                                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                this.expandOverlaySettings("Tunnel");
                                            }
                                        }}>
                                        <div
                                            className="OverlayListItemIconAndLabel Tunnel"
                                            onClick={() => this.expandOverlaySettings("Tunnel")}>
                                            <FontAwesomeIcon icon={faArchway} />
                                            <div className="OverlayListItemLabel">Tunnel</div>
                                        </div>
                                        <div
                                            className="OverlayListItemExpandButton"
                                            onClick={() => this.expandOverlaySettings("Tunnel")}>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`OverlayListItemBottom${this.state.expandedOverlayName === "Tunnel" ? "-Expanded" : ""}`}>
                                    {/* width */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Width:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <InputNumber
                                                size="small"
                                                min={0.1}
                                                step={0.1}
                                                precision={3}
                                                disabled={!this.props.isCalibrated}
                                                onChange={(e) => this.changeOverlaySetting("Tunnel", "width", e)}
                                                value={_.get(this.props.tunnelOverlay, "width") / 1000}
                                                formatter={(v) => `${v} m`}
                                                parser={(v) => v.replace(/[^0-9.]/g, "")}
                                                aria-label="Tunnel width"
                                            />
                                        </div>
                                    </div>

                                    {/* height */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Height:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <InputNumber
                                                size="small"
                                                max={50}
                                                step={0.1}
                                                precision={3}
                                                disabled={!this.props.isCalibrated}
                                                onChange={(e) => this.changeOverlaySetting("Tunnel", "height", e)}
                                                value={_.get(this.props.tunnelOverlay, "height") / 1000}
                                                formatter={(v) => `${v} m`}
                                                parser={(v) => v.replace(/[^0-9.]/g, "")}
                                                aria-label="Tunnel height"
                                            />
                                        </div>
                                    </div>

                                    {/* length */}
                                    <div className="OverlayListItemFormItem">
                                        <div className="OverlayListItemFormItemLabel">Length:</div>
                                        <div className="OverlayListItemFormItemControl">
                                            <InputNumber
                                                size="small"
                                                max={50}
                                                min={1}
                                                step={1}
                                                disabled={!this.props.isCalibrated}
                                                onChange={(e) => this.changeOverlaySetting("Tunnel", "length", e)}
                                                value={_.get(this.props.tunnelOverlay, "length")}
                                                formatter={(v) => `${v}`}
                                                parser={(v) => v.replace(/[^0-9.]/g, "")}
                                                aria-label="Tunnel length"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!this.props.isCalibrated && (
                        <div className="CalibrationInfoText">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                color="orange"
                            />{" "}
                            {`To use Overlay mode, the session needs to be calibrated. Click on the button below to calibrate this session`}
                        </div>
                    )}
                    <OBCButton
                        size="sm"
                        disabled={!this.sessionCalibrationButtonEnabled()}
                        onClick={() => {
                            this.setState({
                                measureMode: true,
                                overlaysPopoverOpen: false,
                            });
                            this.changeToolMode("measure");
                        }}>
                        {this.props.isCalibrated ? "Update Calibration" : "Set Calibration"}
                    </OBCButton>
                </div>
            </div>
        );

        const linkData = `rail-inspection/${this.props.routeID}${this.props.absolutePlaylistTimestamp ? "/" + Math.floor(this.props.absolutePlaylistTimestamp * 1000) : ""}`;

        const overlaysNotAvailablePopoverContent = (
            <div className="OverlaysPopoverMain">
                <div className="CalibrationInfoText">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color="orange"
                    />{" "}
                    {`Overlays not available for this session, please find a session with Forward or Backward tag.`}
                </div>
            </div>
        );

        const videoContainerClassname = this.props.fullscreen ? "VideoBoundsFullScreen" : "VideoBounds";
        return (
            <div
                className={"VideoContainer" + (this.props.fullscreen ? " videoJsFullscreen" : "")}
                id="intro-tour-video-window">
                <FullScreenCapable
                    showExpand={false}
                    showCollapse={false}
                    ref={this.fullscreenComponent}>
                    <WithAspectRatio
                        ratio={1.7778}
                        vPadding={60}>
                        {this.props.viewSnapshot && <SnapshotModal />}
                        {this.state.showAdvancedSighting && <AdvancedSightingModal onClose={this.closeAdvancedSighting} />}
                        {this.state.showExportModal && (
                            <VideoExportModal
                                routeStartTime={this.state.routeStartTime}
                                routeEndTime={this.state.routeEndTime}
                                onClose={this.hideExportModal}
                            />
                        )}

                        {(!this.props.toolMode || this.props.toolMode === "measure" || this.props.toolMode === "draw") && (
                            <MarkupNavigation
                                openAdvancedSighting={this.openAdvancedSighting}
                                changeToolMode={this.changeToolMode}
                                toolMode={this.props.toolMode}
                                videoLoading={this.state.videoLoading}
                                fullscreenComponent={this.fullscreenComponent}
                            />
                        )}
                        <div className={`${videoContainerClassname}  ${inOverlayInterface ? " Hidden" : ""}`}>
                            <div
                                className={"FullScreenIconOverlay Top Right"}
                                id="intro-tour-video-fullscreen"
                                role="button"
                                aria-label="fullscreen"
                                onClick={this.toggleFullscreen}
                                onKeyDown={(e) => {
                                    if (e.key && e.key === "Enter") {
                                        this.toggleFullscreen();
                                    }
                                }}
                                tabIndex={0}>
                                <div className="Image-Fullscreen-Icon">
                                    <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                                </div>
                            </div>
                            {this.props.isStills ? (
                                <ImagePlayer
                                    playerControls={true}
                                    playSpeed={this.state.showAdvancedSighting ? 0 : this.props.playbackSpeed}
                                    routeStartTime={this.state.routeStartTime}
                                    routeEndTime={this.state.routeEndTime}
                                    displayAbsoluteThermalImage={this.state.displayAbsoluteThermalImage}
                                    displayPickTemperature={this.state.displayPickTemperature}
                                    showOverlayContent={this.state.showOverlayContent}>
                                    {this.props.toolMode !== "zoom" && (
                                        <div className="ThreeDFeatureOverlayContainer">
                                            <ThreeDFeatureOverlay />
                                        </div>
                                    )}
                                </ImagePlayer>
                            ) : (
                                <VideoPlayer
                                    playSpeed={this.state.showAdvancedSighting ? 0 : this.props.playbackSpeed}
                                    routeStartTime={this.state.routeStartTime}
                                    routeEndTime={this.state.routeEndTime}
                                />
                            )}
                        </div>
                        <div className={this.props.toolMode === "annotate" ? "ToolComponent" : "Hidden"}>
                            {this.props.toolMode === "annotate" && this.renderAnnotationInterface()}
                        </div>
                        <div className={this.props.toolMode === "draw" || this.props.toolMode === "measure" ? "ToolComponent" : "Hidden"}>
                            {(this.props.toolMode === "draw" || this.props.toolMode === "measure") && this.renderToolInterface()}
                        </div>
                        <div className={this.props.toolMode === "distance_view" ? "ToolComponent" : "Hidden"}>
                            {this.props.toolMode === "distance_view" && this.renderDistanceViewInterface()}
                        </div>
                        <div className={this.props.toolMode === "feature_placement" ? "ToolComponent" : "Hidden"}>
                            {this.props.toolMode === "feature_placement" && this.renderFeaturePlacementInterface()}
                        </div>

                        <div className={this.props.toolMode === "zoom" ? "ZoomComponent" : "Hidden"}>
                            {this.props.toolMode === "zoom" && this.renderZoomInterface()}
                        </div>

                        {(this.state.exporting || this.state.trimming) && !this.props.toolMode ? (
                            <ExportBar
                                routeStartTime={this.state.routeStartTime}
                                routeEndTime={this.state.routeEndTime}
                                toggleRouteStartTime={this.toggleRouteStartTime}
                                toggleRouteEndTime={this.toggleRouteEndTime}
                                exitExportMode={this.exitExportMode}
                                createExport={this.showExportModal}
                                createTrim={this.createTrim}
                                mode={this.state.exporting ? "export" : "trim"}
                            />
                        ) : (
                            <div
                                style={{ display: "flex", backgroundColor: "#6C43DF", borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }}
                                className={inOverlayInterface ? "Hidden" : "ExportBarContainer"}>
                                <div className="VideoControlsLeft">
                                    {/* show/hide marker overlay - only show when marker/observation selected*/}
                                    {this.props.selectedTagCategory && this.props.isStills && (
                                        <div className="VideoControls__Item SmallWidthHidden">
                                            <Popover
                                                getPopupContainer={() => this.popoverMountPoint.current}
                                                placement="topRight"
                                                content={`${this.state.showOverlayContent ? "Hide" : "Show"} detection overlay on the image`}>
                                                <Checkbox
                                                    checked={this.state.showOverlayContent}
                                                    onClick={(value) => this.setState({ showOverlayContent: !this.state.showOverlayContent })}
                                                    size="small">
                                                    Detections Overlay
                                                </Checkbox>
                                            </Popover>
                                        </div>
                                    )}

                                    {this.isPatronusSession() &&
                                        this.props.isStills &&
                                        !!this.props.userConfig.super_admin &&
                                        this.state.displayPickTemperature && (
                                            <div className="VideoControls__Item SmallWidthHidden">
                                                <Popover
                                                    getPopupContainer={() => this.popoverMountPoint.current}
                                                    placement="topRight"
                                                    content={"Toggle absolute temperature image"}>
                                                    <Checkbox
                                                        checked={this.state.displayAbsoluteThermalImage}
                                                        onClick={(value) =>
                                                            this.setState({ displayAbsoluteThermalImage: !this.state.displayAbsoluteThermalImage })
                                                        }
                                                        size="small">
                                                        Absolute temp
                                                    </Checkbox>
                                                </Popover>
                                            </div>
                                        )}

                                    {this.props.currentSession.inspection &&
                                        _.get(this.props.dashboard, ["config", "rail_inspection_enabled"]) &&
                                        this.props.workspaceLayout !== "widget_layout" && (
                                            <Link
                                                to={"/" + linkData}
                                                id="railInspectionButton"
                                                className="OBCButton OBCButton-sm">
                                                <span className="RailInspectionPlayerButton">Rail Inspection</span>
                                            </Link>
                                        )}
                                </div>

                                <div
                                    id="VideoControls"
                                    className="VideoControls"
                                    ref={this.popoverMountPoint}>
                                    {this.props.selectedTagCategory && this.props.isStills && (
                                        <div className="VideoControls__Item BigWidthHidden">
                                            <Popover
                                                getPopupContainer={() => this.popoverMountPoint.current}
                                                placement="top"
                                                content={`${this.state.showOverlayContent ? "Hide" : "Show"} detection overlay on the image`}>
                                                <FontAwesomeIcon
                                                    className={"ColourPickerIcon AbsoluteTemp" + (this.state.showOverlayContent ? " Active" : "")}
                                                    icon={faObjectUngroup}
                                                    onClick={() => this.setState({ showOverlayContent: !this.state.showOverlayContent })}
                                                />
                                            </Popover>
                                        </div>
                                    )}

                                    {this.isPatronusSession() &&
                                        this.props.isStills &&
                                        !!this.props.userConfig.super_admin &&
                                        this.state.displayPickTemperature && (
                                            <div className="VideoControls__Item BigWidthHidden">
                                                <Popover
                                                    getPopupContainer={() => this.popoverMountPoint.current}
                                                    placement="top"
                                                    content={"Toggle absolute picker"}>
                                                    <FontAwesomeIcon
                                                        className={"ColourPickerIcon AbsoluteTemp" + (this.state.displayAbsoluteThermalImage ? " Active" : "")}
                                                        icon={faThermometerThreeQuarters}
                                                        onClick={() => this.setState({ displayAbsoluteThermalImage: !this.state.displayAbsoluteThermalImage })}
                                                    />
                                                </Popover>
                                            </div>
                                        )}

                                    {this.isPatronusSession() && this.props.isStills && (
                                        <div className="VideoControls__Item">
                                            <Popover
                                                getPopupContainer={() => this.popoverMountPoint.current}
                                                placement="top"
                                                content={"Toggle temperature picker"}>
                                                <FontAwesomeIcon
                                                    className={"ColourPickerIcon" + (this.state.displayPickTemperature ? " Active" : "")}
                                                    icon={faEyeDropper}
                                                    onClick={() =>
                                                        this.setState({
                                                            displayPickTemperature: !this.state.displayPickTemperature,
                                                            displayAbsoluteThermalImage: false,
                                                        })
                                                    }
                                                />
                                            </Popover>
                                        </div>
                                    )}
                                    {this.props.isStills && (
                                        <div className="VideoControls__Item">
                                            <Popover
                                                getPopupContainer={() => this.popoverMountPoint.current}
                                                content={this.renderImageAdjustments()}>
                                                <FontAwesomeIcon icon={faSlidersH} />
                                            </Popover>
                                        </div>
                                    )}

                                    {!this.props.shareLink && !!this.props.userConfig.super_admin && (
                                        <div
                                            className="VideoControls__Item"
                                            onClick={this.startTrim}>
                                            <Popover
                                                content="Slice Video"
                                                getPopupContainer={() => this.popoverMountPoint.current}>
                                                <FontAwesomeIcon icon={faCut} />
                                            </Popover>
                                        </div>
                                    )}

                                    <div
                                        id="intro-tour-video-toolbar"
                                        style={{ display: "flex" }}>
                                        {!this.props.shareLink && this.props.workspaceLayout !== "widget_layout" && (
                                            <div
                                                className="VideoControls__Item SnapshotButton"
                                                onClick={this.toggleSnapshot}>
                                                <Popover
                                                    content="Take Snapshot"
                                                    getPopupContainer={() => this.popoverMountPoint.current}>
                                                    <FontAwesomeIcon icon={faCamera} />
                                                </Popover>
                                            </div>
                                        )}

                                        {this.exportsEnabled() && (
                                            <div
                                                className="VideoControls__Item"
                                                onClick={this.startExport}>
                                                <Popover
                                                    getPopupContainer={() => this.popoverMountPoint.current}
                                                    content="Export">
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Popover>
                                            </div>
                                        )}

                                        {!this.props.shareLink && this.shareLinkEnabled() && (
                                            <div
                                                className="VideoControls__Item"
                                                onClick={this.onClickShare}>
                                                <Popover
                                                    content="Copy share URL"
                                                    trigger="hover"
                                                    visible={this.state.copyLinkHovered}
                                                    getPopupContainer={() => this.popoverMountPoint.current}
                                                    onVisibleChange={this.handleHoverChange}>
                                                    <Popover
                                                        visible={this.state.copyLinkClicked}
                                                        onVisibleChange={this.handleClickChange}
                                                        getPopupContainer={() => this.popoverMountPoint.current}
                                                        title="Link copied!"
                                                        content={this.state.shareLinkURL}
                                                        trigger="click">
                                                        <FontAwesomeIcon icon={faShareSquare} />
                                                    </Popover>
                                                </Popover>
                                            </div>
                                        )}
                                    </div>

                                    {/* Overlays popover to to have different content based on Forward/Backward tag */}
                                    <Badge
                                        className="OverlaysCountBadge"
                                        showZero={false}
                                        count={this.overlayBadgeCount()}
                                        offset={[-5, 3]}>
                                        <div className="VideoControls__Item">
                                            <Popover
                                                placement="bottomLeft"
                                                overlayClassName="VideoControlsOverlayMain"
                                                trigger={this.props.sessionHasDirectionTag ? "click" : "focus"}
                                                title={null}
                                                content={this.props.sessionHasDirectionTag ? overlaysPopoverContent : overlaysNotAvailablePopoverContent}
                                                visible={this.state.overlaysPopoverOpen}
                                                getPopupContainer={() => this.fullscreenComponent.current.fullscreenComponent.current}
                                                onVisibleChange={(value) => {
                                                    this.setState({ overlaysPopoverOpen: value }, () => {
                                                        if (value) {
                                                            this.setState({
                                                                shouldOverlayFocusContent: true,
                                                            });
                                                        }
                                                    });
                                                }}>
                                                <OBCButton
                                                    className={this.props.sessionHasDirectionTag ? "" : "GrayedOut"}
                                                    ref={this.overlayButtonRef}
                                                    variant="white"
                                                    size="sm"
                                                    active={this.state.overlaysPopoverOpen}
                                                    aria-label="Open Overlays"
                                                    tabIndex={0}>
                                                    Overlays
                                                </OBCButton>
                                            </Popover>
                                        </div>
                                    </Badge>

                                    <span className="VideoControls__Item">
                                        <label className="VideoControls__Label">Speed:</label>
                                        <Select
                                            ref={this.speedSelectRef}
                                            placement="topLeft"
                                            className="VideoControlsDropdown"
                                            dropdownClassName="VideoControlsDropdownList"
                                            size="small"
                                            value={this.props.playbackSpeed}
                                            onChange={this.handlePlaybackSpeedChange}
                                            getPopupContainer={() => this.fullscreenComponent.current.fullscreenComponent.current}>
                                            <Option value={0.15}>15%</Option>
                                            <Option value={0.25}>25%</Option>
                                            <Option value={0.5}>50%</Option>
                                            <Option value={1.0}>100%</Option>
                                            <Option value={2.0}>200%</Option>
                                        </Select>
                                    </span>

                                    <span
                                        className="VideoControls__Item"
                                        id="intro-tour-video-type">
                                        <label className="VideoControls__Label">Type:</label>
                                        <Select
                                            placement="topLeft"
                                            className="VideoControlsDropdown"
                                            dropdownClassName="VideoControlsDropdownList VideoControlsDropdownListRight"
                                            size="small"
                                            value={selectedStreamReference}
                                            onChange={(value) =>
                                                this.props.selectStream({
                                                    target: {
                                                        value: value,
                                                    },
                                                })
                                            }
                                            dropdownMatchSelectWidth={false}
                                            getPopupContainer={() => this.fullscreenComponent.current.fullscreenComponent.current}>
                                            {_.map(this.props.sources, (source) => {
                                                return (
                                                    <OptGroup
                                                        key={source.name}
                                                        label={source.name}>
                                                        {_.map(source.streams, (stream, stream_index) => {
                                                            return (
                                                                <Option
                                                                    disabled={stream.disabled}
                                                                    key={stream.name}
                                                                    value={`${stream.videoIndex}.${stream_index}`}>
                                                                    {stream.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </OptGroup>
                                                );
                                            })}
                                        </Select>
                                    </span>
                                </div>
                            </div>
                        )}
                    </WithAspectRatio>
                </FullScreenCapable>
            </div>
        );
    };
}

const mapStateToProps = ({
    shareLink,
    playlist,
    userDetails,
    markup,
    access_token,
    dashboards,
    viewSnapshot,
    fullscreen,
    sessions,
    measurement,
    userAnnotations,
    userMeasurements,
    selectedTagCategory,
}) => {
    const routeID = playlist.data.routeID;

    let share_links_are_private = false;
    let dashboard = _.find(dashboards, { access_token: access_token });
    let workspaceLayout = null;
    if (dashboard) {
        if (dashboard.config && dashboard.config.hasOwnProperty("private_share_links")) {
            share_links_are_private = dashboard.config.private_share_links;
        }
        workspaceLayout = dashboard.workspace_layout;
    }
    const currentSession = _.get(sessions, [playlist.data.routeID], {});

    const sourceIndex = playlist.position.sourceIndex;

    const overlaysEnabled = _.get(dashboard, ["config", "overlays_enabled"], []); // list of available ovrlayes per workspace
    const allOverlays = playlist.overlays; // list of all current overlays settings
    const safecessOverlay = _.get(allOverlays, "Safe Cess", null);
    const groundClearanceOverlay = _.get(allOverlays, "Ground Clearance", null);
    const tunnelOverlay = _.get(allOverlays, "Tunnel", null);
    const currentSessionTags = _.get(currentSession, "tags", []);
    const sessionHasDirectionTag = _.includes(currentSessionTags, "Forward") || _.includes(currentSessionTags, "Backward");
    const allowToResetCalibrationWithoutOverlaySelection = _.get(dashboard, ["config", "overlays_update_calibration_without_selection_enabled"], false);

    const safeCessDefaultRedWidth = _.get(safecessOverlay, "red_width", 1250) / 1000;
    const safeCessDefaultAmberWidth = _.get(safecessOverlay, "amber_width", 750) / 1000;
    const safeCessDefaultGreenWidth = _.get(safecessOverlay, "green_width", 1500) / 1000;
    const safeCessDefaultRedWidthOver100 = _.get(safecessOverlay, "red_width_over_100", 2000) / 1000;
    const safeCessDefaultAmberWidthOver100 = _.get(safecessOverlay, "amber_width_over_100", 1000) / 1000;
    const safeCessDefaultGreenWidthOver100 = _.get(safecessOverlay, "green_width_over_100", 1500) / 1000;

    let defaultRailSeparation = 1.435;
    if (dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(dashboards, (dashboard) => dashboard.access_id === userDetails.dashboardAccessID);
        defaultRailSeparation = _.get(dashboards, [dashboardIndex, "config", "measurements_rail_gauge"], 1435) / 1000;
    }
    const absolutePlaylistTimestamp = getAbsoluteTimestamp(playlist);
    const railSeparation = _.get(
        get_source_calibration(measurement.calibration, playlist.position.sourceIndex, absolutePlaylistTimestamp * 1000, true, defaultRailSeparation),
        "railSeparation",
        defaultRailSeparation,
    );

    return {
        user_email: userDetails.email,
        shareLink,
        snapshotBaseURL: _.get(playlist.data, ["mpdURLs", "snapshots"]),
        routeID,
        video: _.get(playlist.data, ["video", sourceIndex]),
        currentVideoKey: getCurrentVideoKey(playlist),
        currentPlaylistTS: getCurrentPlaylistTS(playlist),
        absolutePlaylistTimestamp,
        isEnhanced: playlist.position.isEnhanced,
        isStills: playlist.position.isStills,
        sourceIndex,
        toolMode: markup.tool_mode,
        share_links_are_private,
        viewSnapshot,
        fullscreen,
        userConfig: userDetails.userConfig,
        currentSession,
        dashboard,
        currentStillImageAdjustments: playlist.stillImageAdjustments,
        hasMarkup: userAnnotations.length || userMeasurements.length,
        workspaceLayout,
        playbackSpeed: playlist.playbackSpeed,
        isCalibrated: source_is_calibrated(measurement.calibration, sourceIndex),
        overlaysEnabled,
        allowToResetCalibrationWithoutOverlaySelection,
        allOverlays,
        safecessOverlay,
        tunnelOverlay,
        groundClearanceOverlay,
        sessionHasDirectionTag,
        railSeparation,
        selectedTagCategory,
        safeCessDefaultRedWidth,
        safeCessDefaultAmberWidth,
        safeCessDefaultGreenWidth,
        safeCessDefaultRedWidthOver100,
        safeCessDefaultAmberWidthOver100,
        safeCessDefaultGreenWidthOver100,
    };
};

export default connect(mapStateToProps)(VideoComponent);
