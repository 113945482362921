import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faKeyboard, faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "antd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const KeyboardShortcuts = ({ controls }) => {
    const [shortcutsVisible, setShortcutsVisibility] = useState(false);

    const toggleShortcuts = () => {
        setShortcutsVisibility(!shortcutsVisible);
    };

    const icon = () => {
        if (shortcutsVisible) {
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={toggleShortcuts}
                    className="keyboardIcon"
                />
            );
        } else {
            return (
                <FontAwesomeIcon
                    icon={faKeyboard}
                    onClick={toggleShortcuts}
                    className="keyboardIcon"
                />
            );
        }
    };

    return (
        <div className="keyboardShortcuts">
            {shortcutsVisible && (
                <div className="keyboardShortcuts__Items">
                    <span className="keyboardShortcuts__Title">Keyboard Shortcuts</span>
                    {controls && controls.length ? (
                        <>
                            {controls.includes("up") && (
                                <Tooltip
                                    title="Navigate Up"
                                    placement="left">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="up">
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("down") && (
                                <Tooltip
                                    title="Navigate Down"
                                    placement="top">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="down">
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("left") && (
                                <Tooltip
                                    title="Navigate Left"
                                    placement="left">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="left">
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("right") && (
                                <Tooltip
                                    title="Navigate Right"
                                    placement="bottom">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="right">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("zoomOut") && (
                                <Tooltip
                                    title="Zoom Out"
                                    placement="top">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="right">
                                        <FontAwesomeIcon icon={faMinus} />
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("zoomIn") && (
                                <Tooltip
                                    title="Zoom In"
                                    placement="left">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="left">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>
                                </Tooltip>
                            )}

                            {controls.includes("annotate") && (
                                <Tooltip
                                    title="Annotate"
                                    placement="bottom">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="a">
                                        <span>A</span>
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("bookmark") && (
                                <Tooltip
                                    title="Bookmark"
                                    placement="bottom">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="b">
                                        <span>B</span>
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("flip") && (
                                <Tooltip
                                    title="Flip"
                                    placement="bottom">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="f">
                                        <span>F</span>
                                    </div>
                                </Tooltip>
                            )}
                            {controls.includes("magnify") && (
                                <Tooltip
                                    title="Magnifying glass"
                                    placement="bottom">
                                    <div
                                        className="keyboardShortcuts__Items__Item"
                                        key="m">
                                        <span>M</span>
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                </div>
            )}
            {icon()}
        </div>
    );
};

export default KeyboardShortcuts;
