import React from "react";
import DraggableWrapper from "../../display/image/DraggableWrapper";
import { Tabs } from "antd";
import SchemaRunsList from "../SchemaRunsList";
import BookmarkList from "../../display/image/BookmarkList";
import AnnotationsList from "./AnnotationsList";

const AssetNavigation = ({
    windowDimensions,
    planData,
    closeAction,
    setUpdatingBookmarkID,
    setUpdatingAnnotationID,
    setLabelWindowOpen,
    setAnnotationWindowOpen,
}) => {
    const title = (
        <div className="inspectRail__BookmarkList__Title">
            <p>Navigation</p>
        </div>
    );

    const content = (
        <div className="inspectRail__Detections">
            <Tabs
                className="draggable-tabs patrol"
                defaultActiveKey={"Runs"}
                destroyInactiveTabPane
                size="large">
                <Tabs.TabPane
                    tab="Runs"
                    key="Runs">
                    <SchemaRunsList planData={planData} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab="Bookmarks"
                    key="Bookmarks">
                    <BookmarkList
                        routePatrol
                        setUpdatingBookmarkID={setUpdatingBookmarkID}
                        setLabelWindowOpen={setLabelWindowOpen}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab="Annotations"
                    key="Annotations">
                    <AnnotationsList
                        setUpdatingID={setUpdatingAnnotationID}
                        setAnnotationWindowOpen={setAnnotationWindowOpen}
                        routePatrol
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            closeAction={closeAction}
            customDimensions={{ x: "550px", y: "500px" }}
        />
    );
};

export default AssetNavigation;
