import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Popover, List, notification, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { favouriteSession, addSessionToFavouriteCategory } from "redux/actions";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/pro-regular-svg-icons";
import { LazyTippy } from "components/util/LazyTooltips";
import { sticky } from "tippy.js";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;

const FavoriteButton = ({ sessionID, placement, setFavouritePopoverOpen = () => {}, align = {} }) => {
    const dispatch = useDispatch();
    const sessionSelector = (state) => state.sessions[sessionID] ?? {};
    const favouriteCategoriesSelector = (state) => state.userDetails.favouriteCategories ?? [];

    const session = useSelector(sessionSelector);
    const [addToFavPopoverOpen, setAddToFavPopoverOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryErrorMsg, setNewCategoryErrorMsg] = useState(false);
    const [favHovered, setFavHovered] = useState(false);
    const [cursor, setCursor] = useState(0);

    const favouriteCategories = useSelector(favouriteCategoriesSelector);

    const _favouriteCategories = useMemo(() => {
        return _.sortBy(favouriteCategories, "privacy");
    }, [favouriteCategories]);

    const toggleSessionFavourite = useCallback(() => {
        dispatch(favouriteSession(session.id, !session.favourite));
    }, [dispatch, session.favourite, session.id]);

    const addSessionToCategory = useCallback(
        (categoryID = 0) => {
            const selectedFavCat = _favouriteCategories.find((item) => item.id === categoryID);
            const catName = selectedFavCat !== undefined ? selectedFavCat.name : newCategoryName;

            dispatch(
                addSessionToFavouriteCategory(session.id, categoryID, newCategoryName, (success) => {
                    notification.success({
                        message: (
                            <span>
                                Session added to favourite <br />
                                category: <strong>{catName}</strong>
                            </span>
                        ),
                        duration: 2.5,
                    });
                }),
            );
            setNewCategoryName("");
        },
        [_favouriteCategories, dispatch, newCategoryName, session.id],
    );

    const generateToolTip = useMemo(() => {
        if (session.favourite) {
            if (typeof session.favourite_category_id !== "undefined") {
                const selectedCategory = _favouriteCategories.find((item) => item.id === session.favourite_category_id);
                return selectedCategory ? selectedCategory.name : "General";
            }
        } else {
            return "Add to Favourites";
        }
    }, [session, _favouriteCategories]);

    const handleKeyDown = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (e.keyCode === 13) {
            if (cursor === 0) {
                toggleSessionFavourite();
                setAddToFavPopoverOpen(false);
            }
        }
    };

    const groupedCategoriesList = useMemo(() => {
        let userCategories = [];
        let sharedWithUserCategories = [];

        _.map(_favouriteCategories, (category) => {
            if (category.id !== -1) {
                if (_.get(category, "is_user_category")) {
                    userCategories.push(
                        <List.Item
                            className={`FavouriteCategoriesListItem ${cursor === category.id ? "selected" : ""}`}
                            key={category.id}
                            style={{ height: "30px" }}
                            onMouseOver={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setCursor(category.id);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setAddToFavPopoverOpen(false);
                                addSessionToCategory(category.id);
                            }}>
                            {category.name}
                        </List.Item>,
                    );
                } else if (_.get(category, "privacy") === 2) {
                    sharedWithUserCategories.push(
                        <List.Item
                            className={`FavouriteCategoriesListItem ${cursor === category.id ? "selected" : ""}`}
                            key={category.id}
                            style={{ height: "30px" }}
                            onMouseOver={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setCursor(category.id);
                            }}
                            title={`Shared by ${_.get(category, "owner_name", "unknown")}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setAddToFavPopoverOpen(false);
                                addSessionToCategory(category.id);
                            }}>
                            {category.name}
                        </List.Item>,
                    );
                }
            }
        });

        return (
            <>
                <List.Item
                    className={`FavouriteCategoriesListItem ${cursor === 0 ? "selected" : ""}`}
                    key={0}
                    style={{ height: "30px" }}
                    onMouseOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setCursor(0);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleSessionFavourite();
                        setAddToFavPopoverOpen(false);
                    }}>
                    General
                </List.Item>
                {userCategories}
                <div className="FavouriteCategoriesListDivider">Shared with me:</div>
                {sharedWithUserCategories}
            </>
        );
    }, [_favouriteCategories, addSessionToCategory, cursor, toggleSessionFavourite]);

    const popoverContent = () => {
        return (
            <div
                className="FavouriteCategoriesPopover"
                style={{ margin: "-12px -15px" }}>
                <div className="FavouriteCategoriesListHeader">
                    <span>Add to Favourite List</span>
                </div>
                <input
                    className="FavouriteCategoriesInputHidden"
                    type="text"
                    onKeyDown={(e) => handleKeyDown(e)}
                    autoFocus={true}
                />
                <div
                    style={{ maxHeight: "180px", overflowY: "auto", overflowX: "hidden", padding: "0" }}
                    className="FavouriteCategoriesList">
                    {groupedCategoriesList}
                </div>

                <Search
                    id="new-category-input"
                    className="FavouriteCategoriesSearch"
                    placeholder="Add new list"
                    enterButton={<PlusOutlined style={{ marginRight: "0" }} />}
                    size="small"
                    value={newCategoryName}
                    onSearch={(inputText, e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (inputText.length > 0) {
                            setAddToFavPopoverOpen(false);
                            addSessionToCategory();
                        } else {
                            setNewCategoryErrorMsg(true);
                        }
                    }}
                    onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setNewCategoryErrorMsg(false);
                        setNewCategoryName(e.target.value);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                />
                <div className="FavouriteCategoriesError">
                    <span className={`${newCategoryErrorMsg ? "show" : "hidden"}`}>You need to enter list name</span>
                </div>
            </div>
        );
    };

    useEffect(() => {
        setFavouritePopoverOpen(addToFavPopoverOpen);
    }, [addToFavPopoverOpen]);

    return (
        <LazyTippy
            placement={"top"}
            sticky={true}
            plugins={[sticky]}
            arrow={false}
            theme="aivr"
            content={generateToolTip}>
            <div className="">
                <Popover
                    trigger="click"
                    content={popoverContent()}
                    placement={placement}
                    align={align}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (session.favourite) {
                            toggleSessionFavourite();
                        }
                    }}
                    visible={addToFavPopoverOpen}
                    onVisibleChange={(open) => {
                        if (!session.favourite) {
                            setAddToFavPopoverOpen(open);
                        }
                        if (!open) {
                            setAddToFavPopoverOpen(false);
                            toggleSessionFavourite();
                        }
                    }}>
                    <FontAwesomeIcon
                        className={`${session.favourite || favHovered || addToFavPopoverOpen ? "favouriteIcon" : "nonFavouriteIcon"}`}
                        style={{ margin: 0 }}
                        icon={session.favourite || favHovered || addToFavPopoverOpen ? faStar : faStarOutline}
                        onMouseEnter={() => setFavHovered(true)}
                        onMouseLeave={() => setFavHovered(false)}
                        onClick={() => {
                            if (session.favourite) {
                                setFavHovered(false);
                            }
                        }}
                    />
                </Popover>
            </div>
        </LazyTippy>
    );
};

export default FavoriteButton;
