import React from "react";
import { connect } from "react-redux";
import { Form, Input, InputNumber, Modal, Select, Button, Tabs, Checkbox, AutoComplete, Divider } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment";
import {
    routeCoordinateReverseLookup,
    getSessionsFromCoords,
    routeSelected,
    goToBounds,
    swapDashboard,
    refresh,
    findAudit,
    searchForTimestamp,
    whatThreeWordsLookup,
    getAutoCompleteValuesBySystemID,
    getMlAssetTypes,
    searchForInspectionPulse,
    setTargetInpectionTimestamp,
    lookupLatLon,
    receiveLocationSearchResults,
    customAudit,
    requestPlaylistTimestamp,
    setLocationSearchLoading,
    SESSION_FILTERS_DEFAULTS,
} from "../../redux/actions/index";
import OBCSpinner from "../util/OBC";
import { withRouter } from "react-router-dom";
import DashboardLogo from "../DashboardLogoComponent";
import FindAssetForm from "./FindAssetForm";
import FindStationForm from "./FindStationForm";
import RouteCoordinateSystems from "../util/RouteCoordinateSystems";
import defaultPlaceHolder from "../../images/default-placeholder.png";
import { calculatePulseFromTimestamp } from "components/util/Geometry";
import SessionListFilterPill from "components/session/SessionListFilterPill";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import SessionsSearch from "components/session/SessionsSearch";
import { osgbToCoordinate } from "components/util/Geometry";

const { TabPane } = Tabs;
const { Option } = AutoComplete;

const WHITELISTED_TAGS = ["day", "night", "forward", "backward"];

const FindLocationForm = Form.create({
    name: "select_location",
    onFieldsChange(props, changedFields, allFields) {
        props.onChange(allFields);
    },
})(
    // eslint-disable-next-line
    class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                selectedLocationType: props.defaultSystemString ? props.defaultSystemString : "lat/lon",
                filteredElrsList: [],
            };
        }

        selectLocationType = (selectedLocationType) => {
            this.setState({
                selectedLocationType,
            });
        };

        componentDidMount() {
            this.componentDidUpdate({}, {});
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevState.selectedLocationType !== this.state.selectedLocationType) {
                const { form } = this.props;
                form.validateFields((errors, values) => {});
            }
            if (this.props.visible !== prevProps.visible) {
                if (this.focusedInput) {
                    this.focusedInput.focus();
                }
            }
        }

        handleElrsSearch = (value, systemID) => {
            let filteredElrsList;
            if (!value) {
                filteredElrsList = [];
            } else {
                filteredElrsList = this.props.autoCompleteValues[systemID].filter((elr) => elr.toLowerCase().indexOf(value.toLowerCase()) > -1);
            }
            this.setState({ filteredElrsList });
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
            };
            const locationSelectFields = [];

            if (this.state.selectedLocationType === "lat/lon") {
                locationSelectFields.push(
                    <Form.Item
                        key={"latitude"}
                        label={"Latitude"}
                        {...formItemLayout}>
                        {getFieldDecorator("Latitude", {
                            rules: [{ required: true, type: "number" }],
                            initialValue: this.props.values.Latitude,
                        })(
                            <InputNumber
                                autoFocus
                                ref={(input) => {
                                    this.focusedInput = input;
                                }}
                                className="full-width"
                            />,
                        )}
                    </Form.Item>,
                );
                locationSelectFields.push(
                    <Form.Item
                        key={"longitude"}
                        label={"Longitude"}
                        {...formItemLayout}>
                        {getFieldDecorator("Longitude", {
                            rules: [{ required: true, type: "number" }],
                            initialValue: this.props.values.Longitude,
                        })(<InputNumber className="full-width" />)}
                    </Form.Item>,
                );
            } else if (this.state.selectedLocationType === "manualPulse") {
                locationSelectFields.push(
                    <Form.Item
                        key={"pulse"}
                        label={"Pulse"}
                        {...formItemLayout}>
                        {getFieldDecorator("Pulse", {
                            rules: [{ required: true, type: "number" }],
                            initialValue: "",
                        })(
                            <InputNumber
                                autoFocus
                                ref={(input) => {
                                    this.focusedInput = input;
                                }}
                                className="full-width"
                            />,
                        )}
                    </Form.Item>,
                );
            } else if (this.state.selectedLocationType === "what3words") {
                locationSelectFields.push(
                    <Form.Item
                        key={"words"}
                        label={"Words"}
                        {...formItemLayout}>
                        {getFieldDecorator("Words", {
                            rules: [{ required: true, type: "string" }],
                            initialValue: "",
                        })(
                            <Input
                                className="full-width"
                                autoFocus
                                ref={(input) => {
                                    this.focusedInput = input;
                                }}
                            />,
                        )}
                    </Form.Item>,
                );
            } else if (this.state.selectedLocationType === "east/north") {
                locationSelectFields.push(
                    <Form.Item
                        key={"easting"}
                        label={"Easting"}
                        {...formItemLayout}>
                        {getFieldDecorator("Easting", {
                            rules: [{ required: true, type: "number" }],
                            initialValue: undefined,
                        })(
                            <InputNumber
                                className="full-width"
                                autoFocus
                                ref={(input) => {
                                    this.focusedInput = input;
                                }}
                            />,
                        )}
                    </Form.Item>,
                );
                locationSelectFields.push(
                    <Form.Item
                        key={"northing"}
                        label={"Northing"}
                        {...formItemLayout}>
                        {getFieldDecorator("Northing", {
                            rules: [{ required: true, type: "number" }],
                            initialValue: undefined,
                        })(
                            <InputNumber
                                className="full-width"
                                autoFocus
                                ref={(input) => {
                                    this.focusedInput = input;
                                }}
                            />,
                        )}
                    </Form.Item>,
                );
            } else {
                _.forEach(RouteCoordinateSystems, (system) => {
                    const includeSystem = this.props.supportedCoordinateSystems.includes(system.ID);
                    if (!includeSystem) {
                        return;
                    }
                    _.forEach(system.handlerClass.display_formats, (desc, name) => {
                        const key = `${system.ID}.${name}`;
                        if (this.state.selectedLocationType !== key) {
                            return;
                        }
                        _.forEach(system.handlerClass.fields(name), (field_name, idx) => {
                            const field_details = system.handlerClass.display_fields[field_name];
                            if (!field_details) {
                                return;
                            }
                            let InputType;
                            if (field_details.rules[0].type === "integer") {
                                InputType = InputNumber;
                            } else {
                                InputType = Input;
                            }
                            let input;
                            if (idx === 0) {
                                input = (
                                    <InputType
                                        className="full-width"
                                        autoFocus
                                        ref={(input) => {
                                            this.focusedInput = input;
                                        }}
                                    />
                                );
                            } else {
                                input = <InputType className="full-width" />;
                            }
                            if (field_name === "ELR" || field_name === "ROUTE") {
                                input = (
                                    <Select
                                        ref={(input) => {
                                            this.focusedInput = input;
                                        }}
                                        style={{ width: "100%" }}
                                        placeholder={!this.props.autoCompleteValues[system.ID] ? "loading..." : "start typing"}
                                        onSearch={(value) => this.handleElrsSearch(value, system.ID)}
                                        showSearch
                                        autoFocus
                                        showArrow={false}
                                        notFoundContent={null}
                                        allowClear
                                        loading={!this.props.autoCompleteValues[system.ID]}
                                        disabled={!this.props.autoCompleteValues[system.ID]}>
                                        {this.state.filteredElrsList.map((elr) => (
                                            <Option key={elr}>{elr}</Option>
                                        ))}
                                    </Select>
                                );
                            }
                            let rules = _.cloneDeep(field_details.rules);
                            if (this.props.railInspection && field_name === "TRACK") {
                                rules[0]["required"] = true;
                            }
                            locationSelectFields.push(
                                <Form.Item
                                    key={field_name}
                                    label={field_details.name}
                                    {...formItemLayout}>
                                    {getFieldDecorator(field_name, {
                                        rules: rules,
                                        initialValue: this.props.values[field_name],
                                    })(input)}
                                </Form.Item>,
                            );
                        });
                    });
                });
            }

            const select_options = [
                <Select.Option
                    key={"lat/lon"}
                    value="lat/lon">
                    Latitude/Longitude
                </Select.Option>,
                <Select.Option
                    key={"what3words"}
                    value="what3words">
                    what3words
                </Select.Option>,
                <Select.Option
                    key={"easting/northing"}
                    value="east/north">
                    Easting/Northing
                </Select.Option>,
            ];

            if (this.props.railInspection && !_.isEmpty(this.props.inspectionPulseCounts)) {
                select_options.push(
                    <Select.Option
                        key={"pulse"}
                        value="manualPulse">
                        Pulse Count
                    </Select.Option>,
                );
            }

            _.forEach(RouteCoordinateSystems, (system) => {
                const includeSystem = this.props.supportedCoordinateSystems.includes(system.ID);
                if (includeSystem) {
                    const sub_options = _.map(system.handlerClass.display_formats, (desc, name) => (
                        <Select.Option
                            key={name}
                            value={`${system.ID}.${name}`}>
                            {desc}
                        </Select.Option>
                    ));
                    if (sub_options.length) {
                        select_options.push(
                            <Select.OptGroup
                                label={system.ID}
                                key={system.ID}>
                                {sub_options}
                            </Select.OptGroup>,
                        );
                    }
                }
            });

            return (
                <>
                    <div className="SearchLocationFilters">
                        <SessionsSearch
                            searchID="SearchLocation"
                            lightTheme={true}
                            tagsOnly={true}
                        />
                        <div className="SearchLocationFiltersPill">
                            <SessionListFilterPill
                                showText
                                showDivider={true}
                            />
                        </div>
                    </div>
                    <Form
                        id="elrForm"
                        style={{ paddingTop: "5px" }}>
                        <Form.Item
                            label="Location Type"
                            {...formItemLayout}>
                            {getFieldDecorator("location_type", {
                                initialValue: this.state.selectedLocationType,
                                rules: [{ required: true }],
                            })(<Select onSelect={this.selectLocationType}>{select_options}</Select>)}
                        </Form.Item>
                        {locationSelectFields}
                    </Form>
                </>
            );
        }
    },
);

class FindLocationPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                location_type: "lat/lon",
                hasErrors: true,
            },
            findingLocation: false,
            noMatchesFound: false,
            searchAllDashboards: true,
            sessionsFound: [],
            displaySessionList: false,
            coordinatesFound: [],
            loadingManualSearch: false,
            activeTab: "location",
            findStationDisabled: true,
            result_type: null,
            searchAcrossDashboards: false,
            sessionToSelect: null,
        };

        this.stationTab = React.createRef();
    }

    componentDidMount() {
        this.componentDidUpdate({}, {});
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (_.isEmpty(prevProps.searchValues) && !_.isEmpty(this.props.searchValues) && this.props.searchValues.searchType) {
            let searchParams = {};

            const createFindUrl = () => {
                let params = [];
                let filtersParams = [];
                params.push(this.props.searchValues.searchType);

                if (this.props.searchValues.paramOne) {
                    params.push(this.props.searchValues.paramOne);
                }

                if (this.props.searchValues.paramTwo) {
                    params.push(this.props.searchValues.paramTwo);
                }

                if (this.props.searchValues.paramThree) {
                    params.push(this.props.searchValues.paramThree);
                }

                if (this.props.searchValues.filterParams) {
                    if (this.props.searchValues.filterParams.deviceKey) {
                        filtersParams.push(`device_key=${this.props.searchValues.filterParams.deviceKey}`);
                    }

                    if (this.props.searchValues.filterParams.timestamp) {
                        filtersParams.push(`timestamp=${this.props.searchValues.filterParams.deviceKey}`);
                    }
                }

                const filtersUrl = filtersParams ? "?" + filtersParams.join("&") : "";

                return "/find/" + params.join("/") + filtersUrl;
            };

            const usedUrl = window.location.protocol + "//" + window.location.host + createFindUrl();

            const auditText = `find_link_used by user ${this.props.userEmail}: ${usedUrl}`;
            this.props.dispatch(customAudit("find_link_used", this.props.searchValues, auditText));

            if (this.props.searchValues.searchType === "location") {
                searchParams = {
                    location_type: "lat/lon",
                    Latitude: parseFloat(this.props.searchValues.paramOne),
                    Longitude: parseFloat(this.props.searchValues.paramTwo),
                };
            } else if (this.props.searchValues.searchType === "timestamp") {
                searchParams = {
                    location_type: "timestamp",
                    timestamp: parseFloat(this.props.searchValues.paramOne),
                    filters: this.props.searchValues.filterParams,
                };
            } else if (this.props.searchValues.searchType === "pulse") {
                searchParams = {
                    location_type: "pulse",
                    pulse: this.props.searchValues.paramOne,
                    timestamp: this.props.searchValues.paramTwo,
                    deviceGroup: this.props.searchValues.paramThree,
                    extraFilters: this.props.searchValues.filterParams,
                };
            } else {
                _.forEach(RouteCoordinateSystems, (system) => {
                    if (this.props.searchValues.searchType === system.simpleName) {
                        const display_format = _.keys(system.handlerClass.display_formats)[0];
                        const key = `${system.ID}.${display_format}`;
                        searchParams = {
                            ...this.props.searchValues,
                            location_type: key,
                        };
                    }
                });
            }

            this.dismissLocationSearch();

            if (searchParams.location_type) {
                this.setState(
                    {
                        loadingManualSearch: true,
                        searchAcrossDashboards: true,
                    },
                    () => {
                        this.findLocation(searchParams);
                    },
                );
            }
        }

        if (!prevProps.visible && this.props.visible) {
            this.setState({
                activeTab: "location",
            });

            this.props.dispatch(getMlAssetTypes());

            this.props.dispatch(findAudit("open_find_dialog", {}));
            _.forEach(RouteCoordinateSystems, (system) => {
                const includeSystem = this.props.supportedCoordinateSystems.includes(system.ID);
                if (!includeSystem) {
                    return;
                }
                this.props.dispatch(getAutoCompleteValuesBySystemID(system.ID));
            });
        }

        if (!_.isEqual(prevProps.supportedCoordinateSystems, this.props.supportedCoordinateSystems)) {
            let defaultLocationType = "lat/lon";

            _.forEach(RouteCoordinateSystems, (system) => {
                if (defaultLocationType === "lat/lon") {
                    const includeSystem = this.props.supportedCoordinateSystems.includes(system.ID);
                    if (includeSystem) {
                        _.forEach(system.handlerClass.display_formats, (desc, name) => {
                            if (defaultLocationType === "lat/lon") {
                                defaultLocationType = `${system.ID}.${name}`;
                            }
                        });
                    }
                }
            });
            const newFields = _.clone(this.state.fields);
            newFields["location_type"] = defaultLocationType;
            this.setState({
                fields: newFields,
            });
        }

        if (!prevProps.currentDashboard && this.props.currentDashboard && this.state.sessionToSelect) {
            this.onSessionClick(this.state.sessionToSelect);
        }

        // if popup with search result displayed on the map and fitlers adjusted fetch new results to match new filters
        if (
            prevProps.sessionListFilters !== this.props.sessionListFilters &&
            !this.props.sessionListFilters.refreshing &&
            this.props.nearbyRouteCoordinates &&
            this.props.nearbyRouteCoordinates.length
        ) {
            this.props.dispatch(setLocationSearchLoading(true)); // this to help with user feedback that its loading data
            this.findLocation();
        }
    };

    searchAcrossDashboardsChange = (e) => {
        this.setState({
            searchAcrossDashboards: e.target.checked,
        });
    };

    onFieldChanged = (changedFields) => {
        const relevantFields = _.chain(changedFields)
            .toPairs()
            .filter((value) => !value[1].dirty && !value[1].errors)
            .map((value) => [value[0], value[1].value])
            .fromPairs()
            .value();

        const fieldsWithErrors = _.chain(changedFields)
            .toPairs()
            .filter((value) => value[1].errors)
            .map((value) => value[0])
            .value();

        this.setState({
            hasErrors: fieldsWithErrors.length > 0,
            fields: { ...this.state.fields, ...relevantFields },
        });
    };

    dismissLocationSearch = () => {
        this.props.history.replace("", null);
    };

    findLocation = (manualParams = {}) => {
        const searchDashboards = this.state.searchAcrossDashboards;

        const _this = this;
        const fields = this.state.fields;
        let locationType = fields.location_type;

        if (manualParams.location_type) {
            locationType = manualParams.location_type;
        }

        this.setState({
            findingLocation: true,
            noMatchesFound: false,
        });

        if (locationType === "lat/lon") {
            let lat = fields.Latitude;
            if (manualParams.Latitude) {
                lat = manualParams.Latitude;
            }

            let lon = fields.Longitude;
            if (manualParams.Longitude) {
                lon = manualParams.Longitude;
            }

            console.log("lat, lon", lat, lon);
            this.props.dispatch(findAudit("find_location", { lat, lon, locationType }));

            this.props.dispatch(
                lookupLatLon(lat, lon, searchDashboards, (resultType, parsedCoordinates) => {
                    if (parsedCoordinates) {
                        if (!searchDashboards) {
                            _this.setState({
                                findingLocation: false,
                                loadingManualSearch: false,
                            });
                            this.props.dispatch(receiveLocationSearchResults(resultType, parsedCoordinates));
                            this.props.onConfirm();
                        } else {
                            const payload = { lon, lat };

                            if (_.get(this.props.searchValues, ["filterParams", "timestamp"], false)) {
                                payload["timestamp"] = this.props.searchValues.filterParams.timestamp;
                            }

                            if (_.get(this.props.searchValues, ["filterParams", "deviceKey"], false)) {
                                payload["device_key"] = this.props.searchValues.filterParams.deviceKey;
                            }

                            this.props.dispatch(
                                getSessionsFromCoords(payload, function (sessions) {
                                    console.log("FOUND SESSIONS", sessions, _this.props.searchValues);
                                    // if(manualParams)
                                    if (sessions.length === 1 && _this.props.searchValues.autoplay) {
                                        const firstSession = sessions[0];
                                        if (this.props.currentDashboard) {
                                            _this.onSessionClick(firstSession);
                                        } else {
                                            _this.setState({
                                                sessionToSelect: firstSession,
                                            });
                                        }
                                        return;
                                    }
                                    _this.setState({
                                        result_type: resultType,
                                        coordinatesFound: parsedCoordinates,
                                        loadingManualSearch: false,
                                        sessionsFound: sessions,
                                        displaySessionList: true,
                                        findingLocation: false,
                                    });
                                }),
                            );
                        }
                    } else {
                        this.setState({
                            noMatchesFound: true,
                            loadingManualSearch: false,
                            findingLocation: false,
                        });
                    }
                }),
            );
        } else if (locationType === "what3words") {
            let words = fields.Words;

            if (words.includes(" ")) {
                words = words.replaceAll(" ", ".");
            } else if (words.includes("-")) {
                words = words.replaceAll("-", ".");
            }
            this.props.dispatch(
                whatThreeWordsLookup(words, !searchDashboards, (result_type, parsedCoordinates, location) => {
                    if (parsedCoordinates) {
                        if (!searchDashboards) {
                            _this.setState({
                                findingLocation: false,
                                loadingManualSearch: false,
                            });
                            this.props.dispatch(receiveLocationSearchResults("closest", parsedCoordinates));
                            this.props.onConfirm();
                        } else {
                            const payload = {
                                lat: location.lat,
                                lon: location.lon,
                            };

                            if (_.get(this.props.searchValues, ["filterParams", "timestamp"], false)) {
                                payload["timestamp"] = this.props.searchValues.filterParams.timestamp;
                            }

                            if (_.get(this.props.searchValues, ["filterParams", "deviceKey"], false)) {
                                payload["device_key"] = this.props.searchValues.filterParams.deviceKey;
                            }

                            this.props.dispatch(
                                getSessionsFromCoords(payload, function (sessions) {
                                    console.log("FOUND SESSIONS", sessions);
                                    _this.setState({
                                        result_type: result_type,
                                        coordinatesFound: parsedCoordinates,
                                        loadingManualSearch: false,
                                        sessionsFound: sessions,
                                        displaySessionList: true,
                                        findingLocation: false,
                                    });
                                }),
                            );
                        }
                    } else {
                        this.setState({
                            noMatchesFound: true,
                            loadingManualSearch: false,
                            findingLocation: false,
                        });
                    }
                }),
            );
        } else if (locationType === "east/north") {
            let eastingNorthing = [parseInt(fields.Easting), parseInt(fields.Northing)];
            const latLon = osgbToCoordinate(eastingNorthing);

            this.props.dispatch(
                lookupLatLon(latLon[0], latLon[1], searchDashboards, (resultType, parsedCoordinates) => {
                    if (parsedCoordinates) {
                        if (!searchDashboards) {
                            _this.setState({
                                findingLocation: false,
                                loadingManualSearch: false,
                            });
                            this.props.dispatch(receiveLocationSearchResults(resultType, parsedCoordinates));
                            this.props.onConfirm();
                        } else {
                            const lon = latLon[1];
                            const lat = latLon[0];
                            console.log(lon, lat);

                            const payload = { lon, lat };

                            if (_.get(this.props.searchValues, ["filterParams", "timestamp"], false)) {
                                payload["timestamp"] = this.props.searchValues.filterParams.timestamp;
                            }

                            if (_.get(this.props.searchValues, ["filterParams", "deviceKey"], false)) {
                                payload["device_key"] = this.props.searchValues.filterParams.deviceKey;
                            }

                            this.props.dispatch(
                                getSessionsFromCoords(payload, function (sessions) {
                                    console.log("FOUND SESSIONS", sessions, _this.props.searchValues);
                                    // if(manualParams)
                                    if (sessions.length === 1 && _this.props.searchValues.autoplay) {
                                        const firstSession = sessions[0];
                                        if (this.props.currentDashboard) {
                                            _this.onSessionClick(firstSession);
                                        } else {
                                            _this.setState({
                                                sessionToSelect: firstSession,
                                            });
                                        }
                                        return;
                                    }
                                    _this.setState({
                                        result_type: resultType,
                                        coordinatesFound: parsedCoordinates,
                                        loadingManualSearch: false,
                                        sessionsFound: sessions,
                                        displaySessionList: true,
                                        findingLocation: false,
                                    });
                                }),
                            );
                        }
                    } else {
                        this.setState({
                            noMatchesFound: true,
                            loadingManualSearch: false,
                            findingLocation: false,
                        });
                    }
                }),
            );
        } else if (locationType === "timestamp") {
            this.props.dispatch(
                searchForTimestamp(manualParams.timestamp, manualParams.filters, (resultType, sessions) => {
                    if (sessions) {
                        if (sessions.length === 1 && _this.props.searchValues.autoplay) {
                            const firstSession = sessions[0];
                            if (_this.props.currentDashboard) {
                                _this.onSessionClick(firstSession);
                            } else {
                                _this.setState({
                                    sessionToSelect: firstSession,
                                });
                            }
                            return;
                        }
                        _this.setState({
                            result_type: resultType,
                            loadingManualSearch: false,
                            sessionsFound: sessions,
                            displaySessionList: true,
                            findingLocation: false,
                        });
                    } else {
                        this.setState({
                            noMatchesFound: true,
                            loadingManualSearch: false,
                            findingLocation: false,
                        });
                    }
                }),
            );
        } else if (locationType === "pulse") {
            this.props.dispatch(
                searchForInspectionPulse(manualParams.pulse, manualParams.timestamp, manualParams.deviceGroup, manualParams.extraFilters, (response) => {
                    if (response.image_timestamp && response.session_id) {
                        if (response.workspace_id !== this.props.currentDashboard.access_id) {
                            const dashboard = _.find(this.props.dashboards, (dash) => dash.access_id === parseInt(response.workspace_id));
                            this.props.dispatch(swapDashboard(dashboard.access_token));
                        }

                        if (!_.isNil(response.target_interpolation_distance)) {
                            this.props.dispatch(setTargetInpectionTimestamp(response.image_timestamp, response.target_interpolation_distance));
                        }
                        this.onModalClose();
                        this.props.dispatch(
                            refresh(() => {
                                _this.props.dispatch(routeSelected(response.session_id, parseInt(response.image_timestamp), undefined, _this.props.isStills));
                            }),
                        );
                    } else {
                        this.setState({
                            noMatchesFound: true,
                            loadingManualSearch: false,
                            findingLocation: false,
                        });
                    }
                }),
            );
        } else if (locationType === "manualPulse") {
            const { timestamp } = calculatePulseFromTimestamp(fields.Pulse, this.props.inspectionPulseCounts);
            if (timestamp) {
                this.props.dispatch(requestPlaylistTimestamp(timestamp / 1000));
            }
            _this.setState({
                loadingManualSearch: false,
                findingLocation: false,
            });
        } else {
            let found = false;
            _.forEach(RouteCoordinateSystems, (system) => {
                _.forEach(system.handlerClass.display_formats, (desc, name) => {
                    const key = `${system.ID}.${name}`;
                    if (locationType !== key) {
                        return;
                    }

                    let route_coordinate;
                    if (manualParams.location_type === locationType) {
                        route_coordinate = system.from_search(manualParams);
                        _.forEach(fields, (field, name) => {
                            fields[name] = route_coordinate.field_value(name, this.props.datum_offsets);
                        });
                    } else {
                        route_coordinate = system.from_fields(name, fields, this.props.datum_offsets);
                    }
                    found = true;

                    this.props.dispatch(
                        findAudit("find_location", {
                            route: route_coordinate.route,
                            position: route_coordinate.position,
                            subposition: route_coordinate.subposition,
                            locationType: system.ID,
                        }),
                    );

                    _this.findInterpolatedLocation(
                        system.ID,
                        route_coordinate.route,
                        route_coordinate.position,
                        route_coordinate.subposition,
                        searchDashboards,
                    );
                });
            });
            if (!found) {
                this.setState({
                    noMatchesFound: true,
                    loadingManualSearch: false,
                    findingLocation: false,
                });
            }
        }
    };

    onCheckboxToggle = (e) => {
        this.setState({
            searchAllDashboards: e.target.checked,
        });
    };

    renderSessionList = () => {
        const _this = this;

        if (!this.state.sessionsFound.length) {
            if (this.props.sessionListFiltersActive) {
                return <span style={{ color: "red" }}>No Sessions Found - Please review your session list filters and try again.</span>;
            } else {
                return (
                    <a
                        href={this.footageRequestLink()}
                        className="Error"
                        style={{ color: "red" }}
                        target="_blank"
                        rel="noopener noreferrer">
                        No Sessions Found - Click here to enquire about data collection options
                    </a>
                );
            }
        }

        if (!_this.props.currentDashboard) {
            return null;
        }
        let groupedSessions = _.groupBy(this.state.sessionsFound, "workspace_id");

        let dashboardKeys = Object.keys(groupedSessions);
        dashboardKeys.sort(function (x, y) {
            return x === _this.props.currentDashboard.access_id ? -1 : y === _this.props.currentDashboard.access_id ? 1 : 0;
        });
        return dashboardKeys.map((dashboardKey) => {
            let currentDashboard = false;
            let dashboardData = _.find(this.props.dashboards, (dash) => dash.access_id.toString() === dashboardKey);
            if (!dashboardData) {
                dashboardData = {
                    description: "Unknown dashboard",
                    unknown: true,
                };
            }

            if (this.props.currentDashboard && dashboardData.access_id === this.props.currentDashboard.access_id) {
                currentDashboard = true;
            }

            let orderedSessions = groupedSessions[dashboardKey];

            let timestampToFind = 0;

            if (_.get(this.props.searchValues, ["filterParams", "timestamp"], false)) {
                timestampToFind = this.props.searchValues.filterParams.timestamp;
                if (timestampToFind < 9999999999) {
                    timestampToFind *= 1000;
                }

                orderedSessions = orderedSessions.sort((a, b) => Math.abs(a.timestamp - timestampToFind) - Math.abs(b.timestamp - timestampToFind));
            } else {
                orderedSessions = _.orderBy(groupedSessions[dashboardKey], "session_id", "desc");
            }

            let sessionsList = orderedSessions.map((session) => {
                const isHighlighted = timestampToFind / 1000 > session.session_start && timestampToFind / 1000 < session.session_end;

                const date = moment(session.timestamp).format("lll");

                let thumbnail = defaultPlaceHolder;
                if (session.frame_url) {
                    thumbnail = `https://raw${MEMOIZED_DOMAIN_URL}/${session.frame_url}`;
                    if (this.props.csrfToken) {
                        thumbnail += `?csrf=${this.props.csrfToken}`;
                    }
                }

                const tags = _.filter(session.session_tags, (tag) => WHITELISTED_TAGS.includes(String(tag).toLocaleLowerCase()));

                return (
                    <div
                        key={`${session.session_id}_${session.stream_index}`}
                        className={"searchSessionListItem" + (dashboardData.unknown ? " disabled" : "") + (isHighlighted ? " Highlight" : "")}
                        onClick={() => !dashboardData.unknown && this.onSessionClick(session)}>
                        <div className="searchSessionListItem__Image">
                            <img
                                src={thumbnail}
                                alt="Session thumbnail"
                                crossOrigin={"anonymous"}
                            />
                        </div>
                        <div className="searchSessionListItem__Info">
                            <p className="searchSessionListID">{`#${session.session_id}`}</p>
                            <p className="searchSessionListTitle">{session.name}</p>
                            <p className="searchSessionListTags">{tags.join(", ")}</p>
                            <p className="date">{date}</p>
                        </div>
                    </div>
                );
            });

            return (
                <div key={dashboardKey}>
                    <div className="finderDashboardHeader">
                        <div>
                            <DashboardLogo
                                dashboard={dashboardData}
                                customStyles={{ margin: 0, marginRight: 10 }}
                            />
                        </div>
                        <p className="sessionDashboardHeaderTitle">{dashboardData.description + (currentDashboard ? " (Current dashboard)" : "")}</p>
                    </div>

                    {sessionsList}
                </div>
            );
        });
    };

    onSessionClick = (session) => {
        const _this = this;
        if (this.props.sessions[session.session_id]) {
            const localSession = this.props.sessions[session.session_id];

            let timestamp = _.get(this.props.searchValues, ["filterParams", "timestamp"], false);
            if (timestamp) {
                this.props.dispatch(routeSelected(session.session_id, parseInt(timestamp), undefined, this.props.isStills, session.stream_index));
            } else {
                this.props.dispatch(
                    routeSelected(session.session_id, session.video_key, undefined, this.props.isStills, session.stream_index, session.video_frame),
                );
            }

            this.props.dispatch(goToBounds(localSession.bounds));
            this.onModalClose();
        } else {
            if (session.workspace_id !== this.props.currentDashboard.access_id) {
                const dashboard = _.find(this.props.dashboards, (dash) => dash.access_id === session.workspace_id);
                this.props.dispatch(swapDashboard(dashboard.access_token));
            }

            this.onModalClose();
            this.props.dispatch(
                refresh(() => {
                    let timestamp = _.get(_this.props.searchValues, ["filterParams", "timestamp"], false);
                    if (timestamp) {
                        _this.props.dispatch(routeSelected(session.session_id, parseInt(timestamp), undefined, _this.props.isStills, session.stream_index));
                    } else {
                        _this.props.dispatch(
                            routeSelected(session.session_id, session.video_key, undefined, _this.props.isStills, session.stream_index, session.video_frame),
                        );
                    }
                }),
            );
        }
    };

    onModalClose = () => {
        this.setState({
            searchAllDashboards: true,
            sessionsFound: [],
            displaySessionList: false,
            loadingManualSearch: false,
            findingLocation: false,
            fields: {
                location_type: "lat/lon",
                hasErrors: true,
            },
        });
        this.props.onCancel();
    };

    renderFooter = () => {
        if (this.state.activeTab === "location") {
            if (this.state.loadingManualSearch) {
                return [];
            } else if (this.props.railInspection) {
                let footer = [
                    <Button
                        onClick={this.props.onCancel}
                        key="cancel">
                        Close
                    </Button>,
                    <Button
                        loading={this.state.findingLocation}
                        onClick={this.findLocation}
                        key="findMap"
                        type="primary"
                        disabled={this.state.hasErrors}
                        htmlType="submit"
                        form="elrForm">
                        Search
                    </Button>,
                ];
                return footer;
            } else if (!this.state.displaySessionList) {
                if (this.props.dashboards.length > 1) {
                    return [
                        <Checkbox
                            checked={this.state.searchAcrossDashboards}
                            onChange={this.searchAcrossDashboardsChange}
                            style={{ marginRight: 10 }}>
                            Search across all workspaces
                        </Checkbox>,
                        <Button
                            loading={this.state.findingLocation}
                            onClick={this.findLocation}
                            key="findSessions"
                            type="primary"
                            disabled={this.state.hasErrors}
                            htmlType="submit"
                            form="elrForm">
                            Find
                        </Button>,
                    ];
                } else {
                    return [
                        <Button
                            loading={this.state.findingLocation}
                            onClick={this.findLocation}
                            key="findSessions"
                            type="primary"
                            disabled={this.state.hasErrors}
                            htmlType="submit"
                            form="elrForm">
                            Find
                        </Button>,
                    ];
                }
            } else {
                return [
                    <Button
                        onClick={this.backToSearch}
                        key="back">
                        Back
                    </Button>,
                    <Button
                        onClick={this.onModalClose}
                        key="cancel">
                        Close
                    </Button>,
                ];
            }
        } else if (this.state.activeTab === "assets") {
            return [
                <Button
                    onClick={this.onModalClose}
                    key="cancel">
                    Close
                </Button>,
            ];
        } else if (this.state.activeTab === "stations") {
            return [
                <Button
                    onClick={this.onModalClose}
                    key="cancel">
                    Close
                </Button>,
                <Button
                    onClick={this.findStation}
                    key="search"
                    type="primary"
                    disabled={this.state.findStationDisabled}>
                    Find
                </Button>,
            ];
        }
    };

    findStationDisabledToggle = (value) => {
        this.setState({
            findStationDisabled: value,
        });
    };

    findStation = () => {
        this.stationTab.current.findStation();
    };

    backToSearch = () => {
        // if back button pressed and autoCompleteValues is empty getAutoCompleteValues
        // itswill get triggered when user used find link and then press back button
        if (_.isEmpty(this.props.autoCompleteValues)) {
            _.forEach(RouteCoordinateSystems, (system) => {
                const includeSystem = this.props.supportedCoordinateSystems.includes(system.ID);
                if (!includeSystem) {
                    return;
                }
                this.props.dispatch(getAutoCompleteValuesBySystemID(system.ID));
            });
        }
        this.setState({
            searchAllDashboards: true,
            sessionsFound: [],
            displaySessionList: false,
        });
    };

    findInterpolatedLocation = (system, route, position, subposition, searchDashboards = false) => {
        const _this = this;

        this.props.dispatch(
            routeCoordinateReverseLookup(system, route, position, subposition, true, (result_type, parsedCoordinates) => {
                if (parsedCoordinates) {
                    if (!searchDashboards) {
                        _this.setState({
                            findingLocation: false,
                            loadingManualSearch: false,
                        });
                        this.props.dispatch(receiveLocationSearchResults(result_type, parsedCoordinates));
                        this.props.onConfirm();
                    } else {
                        const payload = {
                            system: system,
                            route: route,
                            position: position,
                            subposition: subposition,
                        };

                        if (_.get(this.props.searchValues, ["filterParams", "timestamp"], false)) {
                            payload["timestamp"] = this.props.searchValues.filterParams.timestamp;
                        }

                        if (_.get(this.props.searchValues, ["filterParams", "deviceKey"], false)) {
                            payload["device_key"] = this.props.searchValues.filterParams.deviceKey;
                        }

                        _this.props.dispatch(
                            getSessionsFromCoords(payload, function (sessions) {
                                if (sessions.length === 1 && _this.props.searchValues.autoplay) {
                                    const firstSession = sessions[0];
                                    if (_this.props.currentDashboard) {
                                        _this.onSessionClick(firstSession);
                                    } else {
                                        _this.setState({
                                            sessionToSelect: firstSession,
                                        });
                                    }
                                    return;
                                }
                                _this.setState({
                                    coordinatesFound: parsedCoordinates,
                                    loadingManualSearch: false,
                                    sessionsFound: sessions,
                                    displaySessionList: true,
                                    findingLocation: false,
                                    result_type,
                                });
                            }),
                        );
                    }
                } else {
                    _this.setState({
                        noMatchesFound: true,
                        loadingManualSearch: false,
                        findingLocation: false,
                    });
                }
            }),
        );
    };

    onTabChange = (tab) => {
        this.setState({
            activeTab: tab,
        });
    };

    footageRequestLink = () => {
        let locationString = "";
        if (this.state.fields.location_type === "lat/lon") {
            locationString = `(${this.state.fields.Latitude},${this.state.fields.Longitude})`;
        } else if (this.state.fields.location_type === "ELR Mile & Chain.elr_mile_chain") {
            locationString = `${this.state.fields.ELR} ${this.state.fields.MILE} ${this.state.fields.CHAIN}`;
        } else if (this.state.fields.location_type === "ELR Mile & Chain.elr_meterage") {
            locationString = `${this.state.fields.ELR} ${this.state.fields.METERAGE}m`;
        } else if (this.state.fields.location_type === "ELR Mile & Chain.elr_mile_yards") {
            locationString = `${this.state.fields.ELR} ${this.state.fields.MILE} ${this.state.fields.YARDS}y`;
        }

        const link = `mailto:support@onebigcircle.co.uk?subject=Coverage Enquiry&body=Location : ${locationString}%0D%0AWorkspace Name - ${encodeURIComponent(_.get(this.props.currentDashboard, ["description"], ""))}`;
        return link;
    };

    render() {
        let content = (
            <>
                <FindLocationForm
                    dispatch={this.props.dispatch}
                    autoCompleteValues={this.props.autoCompleteValues}
                    onChange={this.onFieldChanged}
                    visible={this.props.visible}
                    supportedCoordinateSystems={this.props.supportedCoordinateSystems}
                    values={this.state.fields}
                    defaultSystemString={this.props.defaultSystemString}
                    railInspection={this.props.railInspection}
                    inspectionPulseCounts={this.props.inspectionPulseCounts}
                />
                {this.state.noMatchesFound && (
                    <a
                        href={this.footageRequestLink()}
                        className="Error"
                        style={{ color: "red" }}
                        target="_blank">
                        Location Not Found - Click here to enquire about data collection options
                    </a>
                )}
            </>
        );

        if (this.state.loadingManualSearch) {
            content = (
                <div className="locationSearchSpinnerDiv">
                    <OBCSpinner
                        size={150}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        if (this.state.displaySessionList) {
            let coordinate = false;
            let coordinateInfo = false;

            if (this.state.coordinatesFound) {
                coordinate = this.state.coordinatesFound[0];
            }

            let locationDescription = "Unknown";

            if (coordinate) {
                if (coordinate.system === "Latitude/Longitude") {
                    locationDescription = "";
                } else {
                    const system = _.find(
                        RouteCoordinateSystems,
                        (system) => coordinate.system === system.ID && this.props.supportedCoordinateSystems.includes(system.ID),
                    );
                    if (system) {
                        const route_coordinate = system.create(coordinate.route, coordinate.position, coordinate.subposition);
                        locationDescription = route_coordinate.to_string(this.props.elrUnits, this.props.datum_offsets);
                    }
                }

                coordinateInfo = (
                    <div className={"RouteLocationInfoModal"}>
                        <div className={"RouteLocation"}>
                            <div className={"RouteLocationDescription"}>{locationDescription}</div>
                            <div className={"RouteLocationSystem"}>{coordinate.system}</div>
                            <div className={"RouteLocationOrigin"}>
                                {coordinate.lat.toFixed(6)}, {coordinate.lon.toFixed(6)}
                            </div>
                        </div>
                    </div>
                );
            }

            content = (
                <div>
                    {coordinateInfo}
                    {this.renderSessionList()}
                </div>
            );
        }

        return (
            <Modal
                className="SearchLocationModal"
                bodyStyle={{ maxHeight: "75vh", overflow: "hidden", padding: "24px 24px 0 24px" }}
                visible={this.props.visible}
                onCancel={this.onModalClose}
                onOk={this.findLocation}
                footer={this.renderFooter()}
                keyboard={false}
                destroyOnClose={true}
                style={{ width: "50%" }}>
                <Tabs
                    defaultActiveKey="location"
                    onChange={this.onTabChange}>
                    <TabPane
                        tab="Find Location"
                        key="location"
                        className="findTabs"
                        style={{ color: "black" }}>
                        {content}
                    </TabPane>
                    {!this.props.railInspection && (
                        <TabPane
                            tab="Assets"
                            key="assets"
                            className="findTabs"
                            style={{ color: "black" }}>
                            <FindAssetForm closeModal={this.onModalClose} />
                        </TabPane>
                    )}
                    {!this.props.railInspection && (
                        <TabPane
                            tab="Stations"
                            key="stations"
                            className="findTabs"
                            style={{ color: "black" }}>
                            <FindStationForm
                                closeModal={this.onModalClose}
                                ref={this.stationTab}
                                findStationDisabled={this.findStationDisabledToggle}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;

    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const autoCompleteValues = state.searchLocationData.autoCompleteValues;

    let routePositionUnits = _.get(state, ["userDetails", "userConfig", "elr_units"], "elr_mile_chain");
    const supportedCoordinateSystems = state.routeCoordinateSystems;

    let selectedSystem = _.find(RouteCoordinateSystems, (system) => {
        return supportedCoordinateSystems.includes(system.ID) && system.handlerClass.display_formats[routePositionUnits];
    });
    let defaultSystemString = "";

    if (!selectedSystem) {
        const supportedSystems = RouteCoordinateSystems.filter((system) => {
            return supportedCoordinateSystems.includes(system.ID);
        });
        if (supportedSystems.length) {
            selectedSystem = supportedSystems[0];
            routePositionUnits = Object.keys(supportedSystems[0].handlerClass.display_formats);
            defaultSystemString = `${selectedSystem.ID}.${routePositionUnits}`;
        }
    } else {
        defaultSystemString = `${selectedSystem.ID}.${routePositionUnits}`;
    }
    const sessionListFilters = state.sessionListFilters;
    const sessionListFiltersActive = !sessionListFilters.refreshing && !_.isEqual(sessionListFilters, SESSION_FILTERS_DEFAULTS);

    return {
        currentDashboard: _.find(state.dashboards, (dash) => dash.access_token === state.access_token),
        supportedCoordinateSystems: state.routeCoordinateSystems,
        sessions: state.sessions,
        dashboards: state.dashboards || [],
        video: _.get(state.playlist.data, ["video", sourceIndex], []),
        elrUnits: _.get(state, ["userDetails", "userConfig", "elr_units"], "elr_mile_chain"),
        routePositionUnits,
        selectedSystem,
        defaultSystemString,
        datum_offsets: datumOffsets,
        isStills: state.playlist.position.isStills,
        autoCompleteValues,
        userEmail: _.get(state.userDetails, "email", "unknown email"),
        inspectionPulseCounts: state.railInspection.railInspectionPulseData,
        nearbyRouteCoordinates: state.locationSearch.results,
        sessionListFilters,
        sessionListFiltersActive,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(withRouter(FindLocationPopup));
