import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import { changeToolMode, routeSelected } from "./index";

export const RECEIVE_SHORTCUT_DEVICES = "RECEIVE_SHORTCUT_DEVICES";
export const RECEIVE_SHORTCUTS = "RECEIVE_SHORTCUTS";
export const RECEIVE_SHORTCUT_SESSIONS = "RECEIVE_SHORTCUT_SESSIONS";
export const RESET_SHORTCUT_SELECTED = "RESET_SHORTCUT_SELECTED";
export const SHORTCUT_SELECTED = "SHORTCUT_SELECTED";
export const RESET_SHORTCUT_SESSION = "RESET_SHORTCUT_SESSION";
export const RECEIVE_SHORTCUT_POINTS = "RECEIVE_SHORTCUT_POINTS";

export const receiveShortcutPoints = (points, sessionID) => {
    return {
        type: RECEIVE_SHORTCUT_POINTS,
        points,
        sessionID,
    };
};

export const receiveShortcutDevices = (devices) => {
    return {
        type: RECEIVE_SHORTCUT_DEVICES,
        devices,
    };
};

const receiveShortcuts = (shortcuts) => {
    return {
        type: RECEIVE_SHORTCUTS,
        shortcuts,
    };
};

const receiveShortcutSessions = (shortcutID, sessions) => {
    return {
        type: RECEIVE_SHORTCUT_SESSIONS,
        sessions,
        shortcutID,
    };
};

export const resetShortcutSessions = (shortcutID) => {
    return {
        type: RESET_SHORTCUT_SESSION,
        shortcutID,
    };
};

export function getShortcutDevices() {
    return {
        queue: RECEIVE_SHORTCUT_DEVICES,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_shortcut_devices",
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Received shortcut devices: ", response);
                        dispatch(receiveShortcutDevices(response.devices));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch shortcut devices", error);
                    next();
                });
        },
    };
}

export function createShortcut(payload, isPrivate, emailNotifications) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "create_shortcut",
                config: payload,
                private: isPrivate,
                emailNotifications,
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("create shortcut response:", response);
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject();
                    }
                })
                .catch((error) => {
                    console.log("create shortcut error", error);
                    reject();
                });
        });
    };
}

export function getShortcuts(callback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_shortcuts",
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("get shortcuts response:", response);
                    dispatch(receiveShortcuts(response.shortcuts));
                    resolve(response);
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    console.log("get shortcuts error", error);
                    reject();
                    if (callback) {
                        callback();
                    }
                });
        });
    };
}

export function getShortcutSessions(shortcutID) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_shortcut_sessions",
                id: shortcutID,
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("get shortcut sessions:", response);
                    dispatch(receiveShortcutSessions(shortcutID, response.sessions));
                    resolve(response);
                })
                .catch((error) => {
                    console.log("got shortcuts error", error);
                    reject();
                });
        });
    };
}

export function selectShortcutSession(shortcutID, sessionID) {
    return (dispatch, getState) => {
        dispatch({
            type: SHORTCUT_SELECTED,
            shortcutID,
        });
        dispatch(changeToolMode(null));
        dispatch(routeSelected(sessionID, 0, undefined, undefined, undefined, 0, false, false, shortcutID));
    };
}

export function archiveShortcut(shortcutID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "archive_shortcut",
            shortcut_id: shortcutID,
        };

        let url = "/sessions";

        dispatch(removeShortcutPreResponse(shortcutID));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("archive shortcut response: ", response);
                if (!response.success) {
                    dispatch(getShortcuts());
                }
            })
            .catch((error) => {
                console.log("archiving shortcut error: ", error);
            });
    };
}

export function removeShortcutPreResponse(shortcutID) {
    return (dispatch, getState) => {
        const newState = getState().shortcuts.shortcuts.filter((item) => item.id !== shortcutID);
        dispatch({
            type: RECEIVE_SHORTCUTS,
            shortcuts: newState,
        });
    };
}

export function updateShortcut(shortcutID, shortcutConfig = null, shortcutPrivate = null, emailNotifications = null) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "update_shortcut",
                shortcut_id: shortcutID,
            };

            if (shortcutConfig) {
                postBody["config"] = shortcutConfig;
            }

            if (shortcutPrivate) {
                postBody["private"] = shortcutPrivate;
            }

            if (emailNotifications) {
                postBody["email_notifications"] = emailNotifications;
            }

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("update shortcut response:", response);
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject();
                    }
                })
                .catch((error) => {
                    console.log("update shortcut error", error);
                    reject();
                });
        });
    };
}
