import moment from "moment";

export function convertToTimezone(date, UTC, dateOpt) {
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = Intl.NumberFormat().resolvedOptions().locale;

    // override timezone to Europe/Isle_of_Man if system timezone is Unknown
    let timezone = systemTimezone.includes("Unknown") ? "Europe/Isle_of_Man" : systemTimezone;

    let dateOptions = {
        dateStyle: "short",
    };

    if (dateOpt) {
        dateOptions = dateOpt;
    }

    dateOptions["timeZone"] = UTC ? "UTC" : timezone;

    let timeOptions = {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: UTC ? "UTC" : timezone,
    };

    const d = date.toLocaleDateString(locale, dateOptions);
    let time = moment(date).format(`HH:mm:ss`);
    const tz = date
        .toLocaleDateString(locale, { timeZoneName: "short", ...timeOptions })
        .split(" ")
        .pop();

    if (UTC) {
        time = moment(date).utc().format(`HH:mm:ss`);
    }

    return d + " " + time + " " + tz;
}
