import React from "react";
import { LayerGroup, LayersControl, Map, TileLayer, Polyline, SVGOverlay, CircleMarker, Marker, Popup } from "react-leaflet";
import { BingLayer } from "react-leaflet-bing-v2";
import { connect } from "react-redux";
import {
    updateBounds,
    fetchUserAnnotationTypes,
    goToBounds,
    getRouteCoordinateSystems,
    setUserPreference,
    toggleAssetDisplay,
    updateMapZoom,
    getNearbyAssets,
    updateRouteDatumDistances,
    toggleStationDisplay,
    setAssetSearchType,
    mapGeometry,
    toggleRouteSnapping,
    getThermalAssets,
    fetchNearbyFugroData,
    setSessionTagFilter,
    clearLocationSearchResults,
    setSessionDateFilter,
    fetchMapGeometryV2,
    getRouteAge,
    getEnvironmentalDataForBounds,
    fetchRemoteFeatures,
    SESSION_FILTERS_DEFAULTS,
} from "redux/actions/index";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import DeviceMarkers from "./DeviceMarkers";
import Markers from "./Markers";
import MarkerClusterGroup from "react-leaflet-markercluster";
import SegmentMarkers from "./SegmentMarkers";
import ShortcutMarkers from "./ShortcutMarkers";
import BookmarkMarkers from "./BookmarkMarkers";
import SelectedRouteSegmentGeometry from "./SelectedRouteSegmentGeometry";
import PlaylistPositionMarker from "./PlaylistPositionMarker";
import MarkupMarkers from "./MarkupMarkers";
import memoizeOne from "memoize-one";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Control from "react-leaflet-control";
import { faSearch, faTimes, faSyncAlt, faMapMarkerAlt, faRuler } from "@fortawesome/free-solid-svg-icons";
import { faSubway, faExpandWide } from "@fortawesome/pro-regular-svg-icons";
import FindLocationPopup from "./FindLocationPopup";
import RouteCoordinatePopup from "./RouteCoordinatePopup";
import { withRouter } from "react-router-dom";
import AssetMarkers from "./AssetMarkers";
import StationMarkers from "./StationMarkers";
import memoize from "memoize-one";
import OBCSpinner from "../util/OBC";
import FeatureMarkers from "./FeatureMarkers";
import { Popover, Badge, Alert } from "antd";

import { calculatePointDistance } from "../util/Geometry";
import MlAssetMarkers from "./MlAssetMarkers";
import AssetShortcutItem from "./AssetShortcutItem";
import MediaMarkers from "./MediaMarkers";
import { filterIssues } from "components/Issues/issueUtils";
import ObservationMarkers from "components/map/ObservationMarkers";
import CCTVImageMarkers from "./CCTVImageMarkers";
import CCTVImageModal from "./CCTVImageModal";
import osLogo from "../../images/os_data.png";
import NearbyAssetMarkers from "./NearbyAssetMarkers";
import { LoadingOutlined } from "@ant-design/icons";
import EnvironmentalPolygons from "./EnvironmentalPolygons";
import { MapGeometryV2 } from "./MapGeometryV2";

const bing_key = "AmdvUiZuzGlKub5MsDDhANbeYMt8Zzod0YBgiGQsl-MJY8FQIiSZWeClq54DZjsS";
const SATELLITE_MIN_ZOOM = 8;

const { BaseLayer, Overlay } = LayersControl;
require("react-leaflet-markercluster/dist/styles.min.css"); // inside .js file

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("icons/marker.svg"),
    iconUrl: require("icons/marker.svg"),
    iconAnchor: new L.Point(15, 30),
    shadowAnchor: new L.Point(14, 40),
    shadowSize: new L.Point(41, 41),
    iconSize: new L.Point(30, 30),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

class IssuesAlert extends React.Component {
    render() {
        return (
            <Alert
                message={
                    <div className="TooManyMarkersNotificationContainer">
                        <div className="TooManyMarkersNotificationText">Too many {this.props.markerName} to display</div>
                        <Badge
                            className="TooManyMarkersNotificationBadge"
                            count={this.props.issuesToDisplayCount}
                            overflowCount={1000}
                            // style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                            style={{ backgroundColor: "#52c41a" }}
                        />
                    </div>
                }
                type="warning"
                closable
            />
        );
    }
}

class SatelliteAlert extends React.Component {
    render() {
        return (
            <Alert
                message={
                    <div className="TooManyMarkersNotificationContainer">
                        <div className="TooManyMarkersNotificationText">
                            {this.props.loading ? "Loading Satellite data..." : "Zoom in to display Satellite data"}
                        </div>
                    </div>
                }
                type="warning"
                closable
            />
        );
    }
}

class MapComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.map = React.createRef();

        let initialLayerName = "Rail";

        if (this.props.userConfig.bing_map) {
            initialLayerName = "Aerial";
        }
        if (this.props.userPreferences.aivrMapBaseLayer) {
            initialLayerName = this.props.userPreferences.aivrMapBaseLayer;
        }

        this.state = {
            cctvModalVisible: false,
            cctvModalCameraInfo: null,
            mapMouseDown: false,
            followOnMap: true,
            filterMapSegments: true,
            nearbyRouteCoordinates: null,
            findPopupVisible: false,
            showHiddenMarkers: false,
            searchValues: {},
            assetWarningDismissed: false,
            noAssetWarningVisibility: false,
            refreshLoading: false,
            refreshAssetsDisabled: true,
            currentMinZoomLevel: 13,
            showRouteAge: false,
            layerName: initialLayerName,
            initialisedToBounds: false,
            measuring: false,
            measuringActive: false,
            mapMeasurementPoints: [],
            measuringMousePoint: [],
            measuringMousePosition: [],
            markersVisible: true,
            markerClusterKey: Date.now(),
            disableFilterButton: false,
            fugroLoading: false,
            fugroPosition: null,
            fugroUrl: null,
            fugroFramesCount: null,
            maxZoom: 24,
            selectRouteToFront: false,
            displayIssues: false,
            newFilteredIssuesList: [],
            issuesToDisplayCount: 0,
            cctvMarkers: null,
            loading: false,
        };
    }

    toggleRouteAge = (event) => {
        if (event.target.checked) {
            if (_.isEmpty(this.props.routeAgeData)) {
                this.setState({
                    loading: true,
                });
                this.props.dispatch(getRouteAge()).then(() => {
                    this.setState({
                        loading: false,
                    });
                });
            }
        }
        this.setState({
            showRouteAge: event.target.checked,
        });
    };

    toggleFilterMapSegments = (event) => {
        this.setState({
            filterMapSegments: event.target.checked,
        });
    };

    // if current tab issues or assets check number of assets and display
    // notification if more then 100 instead of rendering on the map
    getfilteredIssuesWithinBounds(bounds) {
        if (["issues", "assets"].includes(this.props.currentTab)) {
            const newList = _.filter(this.props.filteredIssues, (marker) => bounds.contains([...marker.coords].reverse()));
            const limit = this.props.assetELRSearch || this.props.assetSearchTerm ? 1000 : 100;
            if (newList.length > limit) {
                this.setState({
                    displayIssues: false,
                    issuesToDisplayCount: newList.length,
                    newFilteredIssuesList: [],
                });
            } else {
                this.setState({
                    displayIssues: true,
                    issuesToDisplayCount: newList.length,
                    newFilteredIssuesList: newList,
                });
            }
        }
    }

    onViewportChanged = (viewport) => {
        let type = _.find(this.props.assets.assetTypes, (type) => type.id === this.props.assetSearchTypeID);
        let currentMinZoomLevel = 13;
        if (type && type.min_zoom_level !== null) {
            currentMinZoomLevel = type.min_zoom_level;
        }
        this.setState({
            currentMinZoomLevel: currentMinZoomLevel,
        });

        let bounds = this.map.current.leafletElement.getBounds();

        // below will only run if current tab is issues or assets
        this.getfilteredIssuesWithinBounds(bounds);

        this.props.dispatch(updateBounds(bounds));
        this.props.dispatch(updateMapZoom(this.map.current.leafletElement.getZoom()));
        if (this.props.mapBounds.zoom >= currentMinZoomLevel) {
            this.setState({
                refreshAssetsDisabled: false,
            });
        }
    };

    onReady = () => {
        if (this.props.stripped) {
            this.props.dispatch(mapGeometry([]));
        } else if (this.props.mapGeometry === null) {
            this.props.dispatch(getRouteCoordinateSystems());
            this.props.dispatch(fetchUserAnnotationTypes());
            this.props.dispatch(fetchRemoteFeatures());
        }
    };

    componentDidMount() {
        this.map.current.leafletElement.setView([52.9, -1.1743], 7);

        if (this.props.access_token) {
            if (this.props.location.state && this.props.location.state.searchType) {
                let searchValues = {
                    paramOne: this.props.location.state.paramOne,
                    paramTwo: this.props.location.state.paramTwo,
                    paramThree: this.props.location.state.paramThree,
                    searchType: this.props.location.state.searchType,
                    filterParams: this.props.location.state.filterParams,
                    autoplay: this.props.location.state.autoplay,
                };
                this.setState({
                    findPopupVisible: true,
                    searchValues: searchValues,
                });
            }
        }
        if (this.props.dashboards?.length && !this.props.stripped) {
            this.props.dispatch(fetchMapGeometryV2());
        }

        if (this.props.sessionConfig && this.props.sessionConfig.force_filters) {
            this.setState({
                filterMapSegments: true,
                disableFilterButton: true,
            });
        }

        if (this.props.railInspection) {
            this.props.dispatch(clearLocationSearchResults());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.requestedBounds && this.props.requestedBounds !== prevProps.requestedBounds) {
            this.goToBounds(this.props.requestedBounds);
            this.setState({
                initialisedToBounds: true,
            });
        }

        if (this.props.access_token !== prevProps.access_token) {
            this.setState({
                initialisedToBounds: false,
            });
        }

        if (this.props.requestedPosition && this.props.requestedPosition !== prevProps.requestedPosition) {
            const [lat, lon, zoom] = this.props.requestedPosition;
            this.goToPosition(lat, lon, zoom);
        }

        if (this.props.sessionConfig !== prevProps.sessionConfig) {
            if (this.props.sessionConfig.force_filters) {
                this.setState({
                    filterMapSegments: true,
                    disableFilterButton: true,
                });
            }
        }
        if (this.props.dashboards !== prevProps.dashboards) {
            if (this.props.dashboards?.length) {
                this.props.dispatch(fetchMapGeometryV2());
            }
        }
        if (prevProps.assets !== this.props.assets) {
            this.setState({
                markerClusterKey: Date.now(),
            });
        }

        if (this.props.mapGeometry !== null && prevProps.mapGeometry === null && !this.state.initialisedToBounds) {
            let bounds = {
                west: 180,
                south: 90,
                east: -180,
                north: -90,
            };
            let segmentBounds = this.props.mapGeometry
                .map((segment) => {
                    return segment.bounds;
                })
                .filter((_) => _);
            if (segmentBounds.length) {
                segmentBounds.forEach((b) => {
                    if (b[0] >= -180) {
                        bounds.west = Math.min(bounds.west, b[0]);
                    }
                    if (b[1] >= -90) {
                        bounds.south = Math.min(bounds.south, b[1]);
                    }
                    if (b[2] <= 180) {
                        bounds.east = Math.max(bounds.east, b[2]);
                    }
                    if (b[3] <= 90) {
                        bounds.north = Math.max(bounds.north, b[3]);
                    }
                });
                this.props.dispatch(goToBounds(bounds));
            }
        }

        if (this.props.assets.displayingAssets && !prevProps.assets.displayingAssets) {
            this.setState({
                assetWarningDismissed: false,
            });
        }

        if (this.props.userConfig.bing_map !== prevProps.userConfig.bing_map) {
            if (!this.props.userPreferences.aivrMapBaseLayer) {
                this.setState({
                    layerName: "Aerial",
                });
            }
        }

        if (this.props.sessionID && this.props.sessionID !== prevProps.sessionID) {
            this.setState({
                followOnMap: true,
            });
        }

        if (this.state.maxZoom && this.state.maxZoom !== prevState.maxZoom) {
            this.map.current.leafletElement.setMaxZoom(this.state.maxZoom);
        }

        if (this.props.extraStyle && this.props.extraStyle !== prevProps.extraStyle && !this.props.resizingMap) {
            this.map.current.leafletElement.invalidateSize();
        }

        // render Alert if filteredIssues list changed
        if (prevProps.filteredIssues !== this.props.filteredIssues) {
            let bounds = this.map.current.leafletElement.getBounds();
            this.getfilteredIssuesWithinBounds(bounds);
        }

        // bring selected green line on the map to the top on if filter/sessionList changes
        if (prevProps.sessionList !== this.props.sessionList) {
            setTimeout(() => {
                this.setState({ selectRouteToFront: !this.state.selectRouteToFront });
            }, 0); // 0 here makes sure other current tasks will be executed before we change state of selectRouteToFront
            // so we wait for new gray lines to be drawn on the map then we force rerender of selected route line
        }
    }

    goToBounds = (bounds) => {
        if (this.map.current && this.map.current.leafletElement) {
            let mapBounds = [
                [bounds.north, bounds.west],
                [bounds.south, bounds.east],
            ];
            this.map.current.leafletElement.fitBounds(mapBounds, { maxZoom: 20 });
        }
    };

    centerMap = (coords) => {
        if (coords[0] && coords[1] && this.state.followOnMap) {
            this.goToPosition(coords[1], coords[0]);
        }
    };

    goToPosition = (lat, lon, zoom = null) => {
        if (this.map.current && this.map.current.leafletElement) {
            if (lat && lon) {
                if (zoom !== null) {
                    this.map.current.leafletElement.setView({ lat: lat, lng: lon }, zoom);
                } else {
                    this.map.current.leafletElement.setView({ lat: lat, lng: lon });
                }
            }
        }
    };

    filterMapGeometry = memoizeOne((mapGeometry, mapSegmentIDs) => {
        return _.map(mapGeometry, (segment) => {
            return { ...segment, isFilteredOut: !mapSegmentIDs.includes(segment.id) };
        });
    });

    convertMapGeometry = memoizeOne((mapGeometry, showRouteAge, mapType) => {
        return mapGeometry.map((segment, index) => {
            return (
                <MapGeometryV2
                    key={"Geometry" + index}
                    segment={segment}
                    showRouteAge={showRouteAge}
                    sessionListFiltersActive={this.props.sessionListFiltersActive}
                    mapType={mapType}
                />
            );
        });
    });

    addRouteAgeToGeom = memoizeOne((geometry, routeAgeData) => {
        return geometry.map((geom) => {
            return {
                ...geom,
                latest_session: _.get(routeAgeData, [geom.id], 0),
            };
        });
    });

    renderMapGeometry = () => {
        if (this.props.mapGeometry === null) {
            return [];
        }
        let filteredMapGeometry = this.props.mapGeometry;
        if (this.state.filterMapSegments) {
            filteredMapGeometry = this.filterMapGeometry(this.props.mapGeometry, this.props.mapSegmentIDs);
        }

        if (this.state.showRouteAge) {
            filteredMapGeometry = this.addRouteAgeToGeom(filteredMapGeometry, this.props.routeAgeData);
            console.log("debug goeom with routeage", filteredMapGeometry);
        }
        return this.convertMapGeometry(filteredMapGeometry, this.state.showRouteAge, this.state.layerName);
    };

    toggleFollowOnMap = (event) => {
        this.setState({
            followOnMap: event.target.checked,
        });
    };

    toggleRouteSnapped = (event) => {
        this.props.dispatch(toggleRouteSnapping(event.target.checked));
        this.props.dispatch(updateRouteDatumDistances());
    };

    mapMouseDown = (event) => {
        this.setState({
            mapMouseDown: true,
        });
    };

    mapMouseUp = (event) => {
        this.setState({
            mapMouseDown: false,
        });
    };

    mapMove = (event) => {
        if (this.state.mapMouseDown) {
            this.setState({
                followOnMap: false,
            });
        }
    };

    showFindPopup = () => {
        this.setState({
            findPopupVisible: true,
        });
        this.props.dispatch(clearLocationSearchResults());
    };

    toggleCCTVModal = (visible) => {
        console.log("map component modal toggle", visible);
        this.setState({
            cctvModalVisible: visible,
        });
    };

    setCCTVModalCameraInfo = (info) => {
        this.setState({
            cctvModalCameraInfo: info,
        });
    };

    hideFindPopup = () => {
        this.setState({
            findPopupVisible: false,
        });
    };

    closeFindModal = () => {
        this.setState({
            findPopupVisible: false,
        });
    };

    routeCoordinatePopupClosed = () => {
        console.log("debug closing coordinate popup");
        this.props.dispatch(clearLocationSearchResults());
    };

    layerChange = (e) => {
        const _maxZoom = e.name === "Aerial" ? 19 : 24;
        this.setState({
            layerName: e.name,
            maxZoom: _maxZoom,
        });
        this.props.dispatch(setUserPreference("aivrMapBaseLayer", e.name));
    };

    overlayRemove = (e) => {
        if (e.name === "Markers") {
            this.setState({ markersVisible: false });
        }
    };

    overlayAdd = (e) => {
        if (e.name === "Routes") {
            this.setState({ selectRouteToFront: !this.state.selectRouteToFront });
        }
        if (e.name === "Markers") {
            this.setState({ markersVisible: true });
        }
    };

    toggleMarkerVisibility = (e) => {
        this.setState({ markersVisible: e.target.checked });
    };

    toggleHiddenMarkers = (event) => {
        this.setState({
            showHiddenMarkers: event.target.checked,
        });
    };

    dismissAssetWarning = () => {
        this.setState({
            assetWarningDismissed: true,
        });
    };

    dismissNoAssetWarning = () => {
        this.setState({
            noAssetWarningVisibility: false,
        });
    };

    refreshAssets = () => {
        const _this = this;
        this.setState({
            refreshLoading: true,
        });
        this.props.dispatch(
            getNearbyAssets(
                null,
                null,
                function (assets) {
                    _this.setState({
                        refreshLoading: false,
                        refreshAssetsDisabled: true,
                    });
                    if (assets.length === 0) {
                        _this.setState({
                            noAssetWarningVisibility: true,
                        });
                    } else {
                        _this.setState({
                            noAssetWarningVisibility: false,
                        });
                    }
                },
                true,
            ),
        );
    };

    getAssets = (assetType, zoomLevel, mlAsset) => {
        const _this = this;

        this.setState({
            currentMinZoomLevel: zoomLevel ?? 13,
        });

        this.props.dispatch(
            getNearbyAssets(
                assetType,
                mlAsset,
                function (assets) {
                    if (assets) {
                        if (assets.length > 0) {
                            _this.props.dispatch(toggleAssetDisplay());
                            _this.props.dispatch(setAssetSearchType({ searchType: "nearby" }));
                            _this.setState({
                                noAssetWarningVisibility: false,
                            });
                        } else {
                            _this.setState({
                                noAssetWarningVisibility: true,
                            });
                        }
                    }
                },
                false,
            ),
        );
    };

    renderShortcuts = () => {
        if (!this.props.stripped) {
            if (!this.props.assets.displayingAssets && this.props.assetShortcuts) {
                return this.props.assetShortcuts
                    .filter((asset) => asset.map_shortcut)
                    .map((asset) => {
                        return (
                            <AssetShortcutItem
                                asset={asset}
                                getAssets={this.getAssets}
                                key={asset.name}
                            />
                        );
                    });
            }
            return null;
        }
        return null;
    };

    toggleMapMeasure = () => {
        let newValue = true;

        if (this.state.measuringActive) {
            newValue = false;
        }

        const updates = {
            measuring: newValue,
            measuringActive: newValue,
        };

        if (!newValue) {
            updates.mapMeasurementPoints = [];
        }
        this.setState(updates);
    };

    renderMeasureContent = () => {
        if (!this.state.measuring && !this.state.measuringActive) {
            return null;
        }

        const lines = [];
        let measureDistance = 0;
        let totalMeasureDistance = 0;

        for (let i = 0; i < this.state.mapMeasurementPoints.length; i++) {
            if (this.state.mapMeasurementPoints[i + 1]) {
                const currentPoint = this.state.mapMeasurementPoints[i];
                const nextPoint = this.state.mapMeasurementPoints[i + 1];
                lines.push(<Polyline positions={[currentPoint, nextPoint]} />);

                measureDistance += calculatePointDistance([currentPoint.lng, currentPoint.lat], [nextPoint.lng, nextPoint.lat]);
            }
        }

        if (this.state.mapMeasurementPoints.length) {
            lines.push(
                <Polyline
                    // dashArray={[5, 5]}
                    positions={[this.state.mapMeasurementPoints[this.state.mapMeasurementPoints.length - 1], this.state.measuringMousePoint]}
                    className="DotLine"
                />,
            );
            totalMeasureDistance =
                measureDistance +
                calculatePointDistance(
                    [
                        this.state.mapMeasurementPoints[this.state.mapMeasurementPoints.length - 1].lng,
                        this.state.mapMeasurementPoints[this.state.mapMeasurementPoints.length - 1].lat,
                    ],
                    [this.state.measuringMousePoint.lng, this.state.measuringMousePoint.lat],
                );
        }

        let measurementSVG = null;

        const circles = this.state.mapMeasurementPoints.map((point) => {
            return (
                <CircleMarker
                    center={point}
                    radius={3}
                />
            );
        });

        if (this.state.mapMeasurementPoints.length) {
            const mousePosition = this.state.measuringMousePosition;
            const mousePositionX = mousePosition.x;
            const mousePositionY = mousePosition.y;

            const svgHeight = 25;
            const lastCirclePosition = this.state.mapMeasurementPoints[this.state.mapMeasurementPoints.length - 1];

            const circleLat = lastCirclePosition.lat;
            const circleLng = lastCirclePosition.lng;

            const bounds = this.mapBounds();

            const textY = 1 - (circleLat - bounds._southWest.lat) / (bounds._northEast.lat - bounds._southWest.lat);

            const textX = (circleLng - bounds._southWest.lng) / (bounds._northEast.lng - bounds._southWest.lng);

            const distanceString = `${Math.round(measureDistance * 100) / 100}m`;
            const totalDistanceString = `${Math.round(totalMeasureDistance * 100) / 100}m`;

            measurementSVG = (
                <SVGOverlay
                    attributes={{ stroke: "red" }}
                    bounds={this.mapBounds()}>
                    {this.state.measuring && (
                        <text
                            x={mousePositionX + 15}
                            y={mousePositionY - svgHeight + Math.round(svgHeight / 2)}
                            stroke="white"
                            fill="white">
                            {totalDistanceString}
                        </text>
                    )}

                    {this.state.mapMeasurementPoints.length > 1 && (
                        <text
                            x={`${textX * 100}%`}
                            y={`${textY * 100 - 1}%`}
                            stroke="white"
                            fill="white">
                            {distanceString}
                        </text>
                    )}
                </SVGOverlay>
            );
        }

        return (
            <>
                {circles}
                {lines}
                {measurementSVG}
            </>
        );
    };

    onMapClick = (e) => {
        if (this.state.measuring) {
            if (e.type === "click") {
                if (this.state.measuringActive) {
                    const newPoints = _.clone(this.state.mapMeasurementPoints);
                    newPoints.push(e.latlng);
                    this.setState({
                        mapMeasurementPoints: newPoints,
                    });
                }
            } else if (e.type === "contextmenu") {
                e.originalEvent.preventDefault();
                const newPoints = _.clone(this.state.mapMeasurementPoints);
                newPoints.push(e.latlng);
                this.setState({
                    measuring: false,
                    mapMeasurementPoints: newPoints,
                });
            }
        }
    };

    onMouseMove = (e) => {
        if (!this.state.measuring) {
            return null;
        }
        this.setState({
            measuringMousePoint: e.latlng,
            measuringMousePosition: e.containerPoint,
        });
    };

    mapBounds = () => {
        if (_.get(this.map, ["current", "leafletElement"], false)) {
            return this.map.current.leafletElement.getBounds();
        }
        return [
            [-1.9, 54.197],
            [-6.02, 52.38],
        ];
    };

    getThermalAssets = () => {
        console.log("debug getting thermal assets");

        this.props.dispatch(getThermalAssets());
    };

    getFugroData = () => {
        if (this.state.fugroLoading) {
            return;
        }
        this.setState({
            fugroLoading: true,
        });
        this.props.dispatch(
            fetchNearbyFugroData((response) => {
                if (response.success) {
                    const zoom = this.props.mapBounds.zoom < 18 ? this.props.mapBounds.zoom : 18;
                    this.goToPosition(response.data.lat, response.data.lon, zoom);
                    this.setState({
                        fugroPosition: [response.data.lat, response.data.lon],
                        fugroUrl: response.data.url,
                        fugroFramesCount: response.data.frameNumber,
                        fugroLoading: false,
                    });
                } else {
                    this.setState({
                        fugroLoading: false,
                    });
                }
            }),
        );
    };

    renderFugroControlButton = () => {
        if (this.props.fugroEnabled && !this.props.stripped) {
            return (
                <Popover
                    content={
                        this.state.fugroLoading
                            ? "Searching..."
                            : this.props.mapBounds.zoom < 15
                              ? "Zoom in to search for Fugro data"
                              : "Click to find nearby Fugro data"
                    }
                    placement="bottom"
                    trigger="hover"
                    mouseLeaveDelay={0}
                    key={"fugroControl"}>
                    <div
                        className={`mapShortcutWidget`}
                        onClick={() => this.getFugroData()}>
                        <button
                            style={{ pointerEvents: "none" }}
                            disabled={this.state.fugroLoading || this.props.mapBounds.zoom < 12}>
                            {this.state.fugroLoading ? (
                                <LoadingOutlined />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faSubway}
                                    style={{ pointerEvents: "none" }}
                                />
                            )}
                        </button>
                    </div>
                </Popover>
            );
        } else {
            return null;
        }
    };

    layerOptions = () => {
        const layerOptions = ["Detailed", "Rail"];
        if (this.props.userConfig.bing_map) {
            layerOptions.push("Aerial");
        }
        if (this.props.extraMapLayers.includes("satellite")) {
            layerOptions.push("Satellite");
        }
        return layerOptions;
    };

    layerPreference = () => {
        let layerPreference = this.props.userPreferences.aivrMapBaseLayer || "Rail";
        if (layerPreference && !_.includes(this.layerOptions(), layerPreference)) {
            layerPreference = false;
        }
        return layerPreference;
    };

    maxZoom = () => {
        let _maxZoom = null;
        if (this.layerPreference() === "Aerial" || !this.layerPreference()) {
            _maxZoom = 19;
        } else {
            _maxZoom = 24;
        }

        return _maxZoom;
    };

    onClearFiltersClicked = () => {
        this.props.dispatch(setSessionTagFilter([]));
        this.props.dispatch(setSessionDateFilter({ from: null, to: null }));
    };

    render() {
        const markers = [
            <Markers
                key="alwaysDisplayedMarkers"
                sessionID={null}
                showHidden={this.state.showHiddenMarkers}
            />,
        ];

        if (this.props.viewingRouteData.routeID) {
            markers.push(
                <Markers
                    key="viewingRouteMarkers"
                    sessionID={this.props.viewingRouteData.routeID}
                    showHidden={this.state.showHiddenMarkers}
                />,
            );
        }

        if (this.props.highlightedRouteID && this.props.highlightedRouteID !== this.props.viewingRouteData.routeID) {
            markers.push(
                <Markers
                    key="highlightedRouteMarkers"
                    sessionID={this.props.highlightedRouteID}
                    showHidden={this.state.showHiddenMarkers}
                />,
            );
        }

        let markerObject;
        if (this.props.mapBounds.zoom <= 12) {
            markerObject = (
                <MarkerClusterGroup
                    maxClusterRadius={32}
                    showCoverageOnHover={false}
                    spiderfyOnMaxZoom={false}
                    animate={false}>
                    {markers}
                </MarkerClusterGroup>
            );
        } else {
            markerObject = markers;
        }

        let assetMarkersClustered = (
            <MarkerClusterGroup
                maxClusterRadius={32}
                showCoverageOnHover={false}
                spiderfyOnMaxZoom={true}
                animate={false}
                key={this.state.markerClusterKey}>
                <AssetMarkers />
            </MarkerClusterGroup>
        );

        let routeCoordinatePopup = null;

        if (this.props.nearbyRouteCoordinates && this.props.nearbyRouteCoordinates.length && !this.props.railInspection) {
            routeCoordinatePopup = (
                <RouteCoordinatePopup
                    coordinates={this.props.nearbyRouteCoordinates}
                    onClose={this.routeCoordinatePopupClosed}
                />
            );
        }

        // let layerPreference = this.props.userPreferences.aivrMapBaseLayer || "Rail";

        let bingLayer = "";
        if (this.props.userConfig.bing_map) {
            bingLayer = (
                <BaseLayer
                    name="Aerial"
                    checked={this.layerPreference() === "Aerial" || !this.layerPreference()}>
                    <BingLayer
                        bingkey={bing_key}
                        attribution="Contains Ordnance Survey data &amp;copy Crown copyright and database right 2024. Licence No: AC0000849662"
                    />
                </BaseLayer>
            );
        }

        let satelliteLayer = null;
        if (this.props.extraMapLayers.includes("satellite")) {
            satelliteLayer = (
                <BaseLayer
                    name="Satellite"
                    checked={this.layerPreference() === "Satellite" || !this.layerPreference()}>
                    <TileLayer
                        url="https://tiles.aivr.video/rail_sat/{z}/{x}/{y}{r}.png"
                        maxNativeZoom={20}
                    />
                </BaseLayer>
            );
        }

        // if (layerPreference && !_.includes(this.layerOptions(), layerPreference)) {
        //     layerPreference = false;
        // }

        // let _maxZoom = null
        // if (layerPreference === "Aerial" || !layerPreference) {
        //     _maxZoom = 19
        // } else {
        //     _maxZoom = 24
        // }
        // this.setState({
        //     maxZoom: _maxZoom
        // })

        let loading_spinner =
            ((this.props.loading || this.props.locationSearchLoading) && !this.props.nearbyRouteCoordinates.length) || this.state.loading ? (
                <div className="map-container__loading-overlay">
                    <OBCSpinner
                        size={50}
                        speed={3}
                        color={"white"}
                    />
                </div>
            ) : null;

        let findButton = (
            <div className="mapSearchButton">
                <button
                    onClick={this.showFindPopup}
                    className="mapSearchButton">
                    <FontAwesomeIcon
                        className="mapSearchButton-icon"
                        icon={faSearch}
                    />
                    <span>Search Location</span>
                </button>
            </div>
        );

        if (this.props.assets.displayingAssets) {
            findButton = (
                <>
                    <Popover
                        content="Zoom in to refresh"
                        placement="bottom"
                        trigger={this.props.mapBounds.zoom < this.state.currentMinZoomLevel ? "hover" : ""}>
                        <div className="mapSearchButton">
                            <button
                                onClick={() => this.refreshAssets()}
                                disabled={this.props.mapBounds.zoom < this.state.currentMinZoomLevel || this.state.refreshAssetsDisabled}>
                                {this.props.assetSearchType === "specific" ? (
                                    <>
                                        <FontAwesomeIcon
                                            className="mapSearchButton-icon"
                                            icon={faMapMarkerAlt}
                                        />
                                        <span>Find nearby</span>
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            className={"mapSearchButton-icon" + (this.state.refreshLoading ? " refreshSpinner" : "")}
                                            icon={faSyncAlt}
                                        />
                                        <span>Refresh</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </Popover>
                    <div className="mapSearchButton round">
                        <button onClick={() => this.props.dispatch(toggleAssetDisplay())}>
                            <FontAwesomeIcon
                                className="mapSearchButton-icon"
                                icon={faTimes}
                            />
                        </button>
                    </div>
                </>
            );
        }

        if (!_.isEmpty(this.props.assets.displayingStation)) {
            findButton = (
                <div className="mapSearchButton">
                    <button onClick={() => this.props.dispatch(toggleStationDisplay())}>
                        <FontAwesomeIcon
                            className="mapSearchButton-icon"
                            icon={faTimes}
                        />
                    </button>
                </div>
            );
        }

        if (this.props.stripped) {
            findButton = null;
        }

        let filterCount = this.props.tagFilters.length;
        if (this.props.dateFilter.from || this.props.dateFilter.to) {
            filterCount++;
        }
        if (this.props.qualityFilter) {
            filterCount++;
        }

        return (
            <div
                className="map-container"
                id="intro-tour-map-window"
                style={this.props.extraStyle ? this.props.extraStyle : null}>
                {this.props.stripped && this.props.resizingMap && (
                    <div className="ResizingMapOverlay">
                        <FontAwesomeIcon
                            className="MapResizingIcon"
                            icon={faExpandWide}
                            size="8x"
                        />
                    </div>
                )}

                {loading_spinner}

                {this.state.showRouteAge && (
                    <div className="MapKey">
                        <p className="KeyText">
                            <u>Segment Colour</u>
                        </p>
                        <div className="KeyRow">
                            <div className="ColourBlock Light" />
                            <p className="KeyText">&lt; 6 weeks</p>
                        </div>
                        <div className="KeyRow">
                            <div className="ColourBlock Lighter" />
                            <p className="KeyText">&lt; 10 weeks</p>
                        </div>
                        <div className="KeyRow">
                            <div className="ColourBlock Lightest" />
                            <p className="KeyText">&gt; 10 weeks</p>
                        </div>
                    </div>
                )}

                {!this.props.stripped && this.state.filterMapSegments && filterCount > 0 && (
                    <div className="FilterTag">
                        {filterCount} filter{filterCount > 1 ? "s" : ""} applied{" "}
                        <FontAwesomeIcon
                            className="FilterTagClear"
                            icon={faTimes}
                            onClick={this.onClearFiltersClicked}
                        />
                    </div>
                )}

                <Map
                    ref={this.map}
                    className={"Map" + (this.state.measuring ? " Measuring" : "")}
                    whenReady={this.onReady}
                    onMouseDown={this.mapMouseDown}
                    keyboard={this.state.cctvModalVisible ? false : true}
                    onMouseUp={this.mapMouseUp}
                    onMoveStart={this.mapMove}
                    preferCanvas={true}
                    onViewportChanged={this.onViewportChanged}
                    maxZoom={this.maxZoom()}
                    onBaseLayerChange={this.layerChange}
                    onOverlayRemove={this.overlayRemove}
                    onOverlayAdd={this.overlayAdd}
                    onClick={this.onMapClick}
                    onContextMenu={this.onMapClick}
                    onMouseMove={this.onMouseMove}
                    style={this.props.stripped && { borderRadius: "4px" }}>
                    <img
                        className="OrdnanceSurveyLogo"
                        src={osLogo}
                        alt="Ordnance Survey"
                        crossOrigin={"anonymous"}
                    />
                    <Control
                        position="topright"
                        className={"leaflet-bar"}>
                        <div
                            id="intro-tour-map-search"
                            style={{ display: "flex", alignItems: "center" }}>
                            {this.renderFugroControlButton()}
                            {this.renderShortcuts()}
                            {findButton}
                        </div>
                    </Control>
                    <Control
                        position="bottomright"
                        className="MapBottomLeft">
                        {!this.state.displayIssues && ["issues", "assets"].includes(this.props.currentTab) && (
                            <IssuesAlert
                                issuesToDisplayCount={this.state.issuesToDisplayCount}
                                markerName={this.props.currentTab}
                            />
                        )}
                        {this.props.mapBounds.zoom < SATELLITE_MIN_ZOOM &&
                        _.includes(this.props.extraMapLayers, "satellite") &&
                        this.props.satelliteTabOpen &&
                        !this.props.stripped ? (
                            <SatelliteAlert loading={!this.props.mapEnvironmentDataLoaded} />
                        ) : null}
                    </Control>
                    {!this.state.assetWarningDismissed && this.props.assets.displayingAssets && this.props.assets.assetsFound.length === 50 && (
                        <Control
                            position="bottomright"
                            className={"mapAssetOverlay"}>
                            <div className="mapAssetOverlay">
                                <p className="mapWarningText">Too many assets to display</p>
                                <FontAwesomeIcon
                                    className="mapWarningIcon"
                                    icon={faTimes}
                                    onClick={this.dismissAssetWarning}
                                />
                            </div>
                        </Control>
                    )}
                    {this.state.noAssetWarningVisibility && (
                        <Control
                            position="bottomleft"
                            className={"mapAssetOverlay AssetWarning"}>
                            <p className="mapWarningText">No assets found in area</p>
                            <FontAwesomeIcon
                                className="mapWarningIcon"
                                icon={faTimes}
                                onClick={this.dismissNoAssetWarning}
                            />
                        </Control>
                    )}
                    {!this.props.railInspection && this.props.stripped !== "driver_training" ? (
                        <LayersControl position="topleft">
                            <BaseLayer
                                checked={this.layerPreference() === "Detailed"}
                                name="Detailed">
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | Contains Ordnance Survey data &amp;copy Crown copyright and database right 2024. Licence No: AC0000849662'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    maxNativeZoom={19}
                                />
                            </BaseLayer>
                            <BaseLayer
                                name="Rail"
                                checked={this.layerPreference() === "Rail" || (!this.layerPreference() && !this.props.userConfig.bing_map)}>
                                <TileLayer
                                    url="https://tiles.aivr.video/rail/{z}/{x}/{y}{r}.png"
                                    maxNativeZoom={20}
                                    attribution="&amp;copy MapTiler &amp;copy OpenStreetMap contributors | Contains Ordnance Survey data &amp;copy Crown copyright and database right 2024. Licence No: AC0000849662"
                                />
                            </BaseLayer>
                            {satelliteLayer}
                            {bingLayer}
                            {!this.props.stripped && (
                                <Overlay
                                    checked
                                    name="CCTV Images">
                                    <LayerGroup>
                                        <CCTVImageMarkers
                                            map={this.map}
                                            setModalVisible={this.toggleCCTVModal}
                                            setCameraInfo={this.setCCTVModalCameraInfo}
                                        />
                                    </LayerGroup>
                                </Overlay>
                            )}
                            <Overlay
                                checked
                                name="Routes">
                                <LayerGroup>{this.renderMapGeometry()}</LayerGroup>
                            </Overlay>
                            <Overlay
                                checked
                                name="Selected Route">
                                <LayerGroup>
                                    <SelectedRouteSegmentGeometry
                                        key={"selectedRoute"}
                                        routeSnapped={this.props.snapRoute}
                                        stripped={this.props.stripped}
                                        selectRouteToFront={this.state.selectRouteToFront}
                                    />
                                </LayerGroup>
                            </Overlay>
                            <Overlay
                                checked={this.state.markersVisible}
                                name="Markers">
                                <LayerGroup>{markerObject}</LayerGroup>
                            </Overlay>
                            <Overlay
                                checked
                                name="Segment Markers">
                                <LayerGroup>
                                    <SegmentMarkers />
                                </LayerGroup>
                            </Overlay>
                            <Overlay
                                checked
                                name="Shortcut Markers">
                                <LayerGroup>
                                    <ShortcutMarkers />
                                </LayerGroup>
                            </Overlay>
                            <Overlay
                                checked
                                name="Current Device Locations">
                                <DeviceMarkers />
                            </Overlay>
                            {this.props.assets.displayingAssets && (
                                <Overlay
                                    checked
                                    name="Asset Markers">
                                    {assetMarkersClustered}
                                </Overlay>
                            )}

                            <Overlay
                                checked={this.props.currentTab === "issues" || this.props.currentTab === "assets"}
                                name="Thermal Hotspot Assets">
                                <MlAssetMarkers
                                    displayIssues={this.state.displayIssues}
                                    filteredIssues={this.state.newFilteredIssuesList}
                                />
                            </Overlay>
                            {this.props.selectedTagCategory && (
                                <Overlay
                                    checked={this.props.displayObservationMarkers}
                                    name="Observation Markers">
                                    <ObservationMarkers />
                                </Overlay>
                            )}
                            {this.props.displayNearbyAssets && <NearbyAssetMarkers />}
                            <Overlay
                                checked={this.props.currentTab === "media"}
                                name="Media Uploads">
                                <MediaMarkers />
                            </Overlay>
                            {this.props.assets.displayingStation && (
                                <Overlay
                                    checked
                                    name="Station Markers">
                                    <StationMarkers />
                                </Overlay>
                            )}
                            <Overlay
                                name="Virtual Assets"
                                checked={this.props.toolMode === "feature_placement"}>
                                <FeatureMarkers />
                            </Overlay>

                            <Overlay
                                checked
                                name="Sketch and Measure markers">
                                <MarkupMarkers />
                            </Overlay>
                            <Overlay
                                checked
                                name="Fugro">
                                <LayerGroup>
                                    {this.state.fugroPosition && this.props.fugroEnabled && (
                                        <Marker position={this.state.fugroPosition}>
                                            <Popup
                                                className="FugroPopover"
                                                autoPan={false}>
                                                <div>Frame Number:</div>{" "}
                                                <div>
                                                    <b>{this.state.fugroFramesCount ?? "n/a"}</b>
                                                </div>
                                                <div>External link:</div>{" "}
                                                <div>
                                                    {this.state.fugroUrl ? (
                                                        <a
                                                            href={this.state.fugroUrl}
                                                            target="_blank">
                                                            Fugro (OnePortal)
                                                        </a>
                                                    ) : (
                                                        "n/a"
                                                    )}
                                                </div>
                                            </Popup>
                                        </Marker>
                                    )}
                                </LayerGroup>
                            </Overlay>
                            {this.props.extraMapLayers.includes("satellite") && this.props.satelliteTabOpen ? (
                                <Overlay
                                    name="Satellite Data"
                                    checked={this.props.satelliteTabOpen}>
                                    <EnvironmentalPolygons minZoom={SATELLITE_MIN_ZOOM} />
                                </Overlay>
                            ) : null}

                            {!this.props.stripped && (
                                <Control position="topleft">
                                    <button
                                        onClick={this.toggleMapMeasure}
                                        className={"MeasureControl" + (this.state.measuringActive ? " Active" : "")}>
                                        <FontAwesomeIcon
                                            icon={faRuler}
                                            className="MeasureIcon"
                                        />
                                    </button>
                                </Control>
                            )}
                        </LayersControl>
                    ) : (
                        <>
                            <LayersControl position="topleft">
                                <BaseLayer
                                    checked={this.layerPreference() === "Detailed"}
                                    name="Detailed">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | Contains Ordnance Survey data &amp;copy Crown copyright and database right 2024. Licence No: AC0000849662'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        maxNativeZoom={19}
                                    />
                                </BaseLayer>
                                <BaseLayer
                                    name="Rail"
                                    checked={this.layerPreference() === "Rail" || (!this.layerPreference() && !this.props.userConfig.bing_map)}>
                                    <TileLayer
                                        url="https://tiles.aivr.video/rail/{z}/{x}/{y}{r}.png"
                                        maxNativeZoom={20}
                                        attribution="&amp;copy MapTiler &amp;copy OpenStreetMap contributors | Contains Ordnance Survey data &amp;copy Crown copyright and database right 2024. Licence No: AC0000849662"
                                    />
                                </BaseLayer>

                                {bingLayer}
                                {satelliteLayer}
                            </LayersControl>
                            {!this.props.stripped && this.renderMapGeometry()}
                            {this.props.stripped && <BookmarkMarkers />}
                            <SelectedRouteSegmentGeometry
                                key={"selectedRoute"}
                                routeSnapped={this.props.snapRoute}
                                stripped
                            />
                        </>
                    )}

                    <PlaylistPositionMarker
                        centerMap={this.centerMap}
                        stripped={this.props.stripped === "sidekick"}
                        mapRef={this.map}
                    />
                    {this.renderMeasureContent()}
                    {/* <SVGOverlay attributes={{ stroke: 'red' }} bounds={this.mapBounds()}>
                    <rect x="10" y="10" width="20%" height="20%" fill="blue" />
                    </SVGOverlay> */}
                    {routeCoordinatePopup}
                </Map>
                {!this.props.stripped && (
                    <div className="map-footer">
                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle">
                                <input
                                    type="checkbox"
                                    checked={this.props.snapRoute}
                                    onChange={this.toggleRouteSnapped}
                                />
                                <span>Show Snapped Route</span>
                            </label>
                        </span>
                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle">
                                <input
                                    type="checkbox"
                                    checked={this.state.followOnMap}
                                    onChange={this.toggleFollowOnMap}
                                />
                                <span>Follow Video/Images on Map</span>
                            </label>
                        </span>
                        {(this.props.userConfig.route_age || this.props.userConfig.super_admin) && (
                            <span className="VideoControls__Item">
                                <label className="VideoControls__Toggle">
                                    <input
                                        type="checkbox"
                                        checked={this.state.showRouteAge}
                                        onChange={this.toggleRouteAge}
                                    />
                                    <span>Route age</span>
                                </label>
                            </span>
                        )}

                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle">
                                <input
                                    disabled={this.state.disableFilterButton}
                                    type="checkbox"
                                    checked={this.state.filterMapSegments}
                                    onChange={this.toggleFilterMapSegments}
                                />
                                <span>Filter Map</span>
                            </label>
                        </span>

                        <span className="VideoControls__Item">
                            <label className="VideoControls__Toggle">
                                <input
                                    type="checkbox"
                                    checked={this.state.markersVisible}
                                    onChange={this.toggleMarkerVisibility}
                                />
                                <span>Show Markers</span>
                            </label>
                        </span>
                    </div>
                )}
                <FindLocationPopup
                    visible={this.state.findPopupVisible}
                    onConfirm={this.closeFindModal}
                    onCancel={this.hideFindPopup}
                    searchValues={this.state.searchValues}
                />

                {this.state.cctvModalVisible && (
                    <CCTVImageModal
                        visible={true}
                        setVisible={this.toggleCCTVModal}
                        cameraInfo={this.state.cctvModalCameraInfo}
                    />
                )}
            </div>
        );
    }
}

const uniqueMeasurements = memoize((ms) =>
    _.uniqBy(ms, function (m) {
        return m.video_key;
    }),
);
const uniqueSketches = memoize((ss) =>
    _.uniqBy(ss, function (s) {
        return s.video_key;
    }),
);

const mapStateToProps = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const userViewEnabled = !!_.get(state.views, [state.userDetails.userConfig.view_id, "ui_controls", "feature_overlay"], false);
    const workspaceViewEnabled = !!_.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "ui_controls", "feature_overlay"], false);
    const session = state.sessions[state.playlist.data.routeID];
    const featureOverlayEnabled = userViewEnabled || workspaceViewEnabled || _.get(session, ["3d_features_enabled"], false);
    const rangeFilters = state.issues.filters.classSearchValue;
    const assetSearchTerm = state.mlAssets.filters.searchQuery;
    const displayNearbyAssets = state.assets.displayNearbyAssets;
    const mapEnvironmentData = state.environmentData.mapData;
    const mapEnvironmentDataLoaded = state.environmentData.dataLoaded;

    let filteredIssues = [];
    if (state.currentTab === "issues") {
        filteredIssues = filterIssues(
            state.issues.filters.date,
            state.issues.filters.status,
            state.issues.filters.priority,
            state.issues.filters.group,
            state.issues.data,
            state.issues.filters.searchQuery,
            { classSearchValue: rangeFilters },
        );
    } else if (state.currentTab === "assets") {
        filteredIssues = state.mlAssets.ids.map((asset) => {
            return {
                id: asset[0],
                coords: asset[1],
                assetGroup: asset[2],
                assetObservationType: asset[3],
            };
        });
    }

    return {
        loading: state.mapGeometry === null,
        dashboards: state.dashboards,
        access_token: state.access_token,
        mapGeometry: state.mapGeometry,
        mapSegmentIDs: state.mapSegmentList,
        highlightedRouteID: state.highlightedRouteID,
        requestedBounds: state.sessionFilters.mapBounds.requested,
        requestedPosition: state.sessionFilters.mapBounds.requestedPosition,
        viewingRouteData: state.playlist.data,
        measurements: uniqueMeasurements(state.userMeasurements),
        sketches: uniqueSketches(state.userSketches),
        featureOverlayEnabled: featureOverlayEnabled,
        userPreferences: state.userPreferences,
        userConfig: state.userDetails.userConfig,
        currentEmail: state.userDetails.email,
        assets: state.assets,
        assetSearchTypeID: state.assets.assetSearch.type,
        mapBounds: state.sessionFilters.mapBounds,
        assetShortcuts: state.assets.assetTypes,
        assetSearchType: state.assetSearchType.searchType,
        snapRoute: state.snappedRoute,
        sessionConfig: state.userDetails.sessionConfig,
        currentTab: state.currentTab,
        sessionID: state.playlist.data.routeID,
        fugroEnabled: _.get(currentDashboard, ["config", "fugro_enabled"], []),
        filteredIssues,
        displayObservationMarkers: state.displayObservationMarkers,
        assetSearchTerm,
        selectedTagCategory: state.selectedTagCategory,
        nearbyRouteCoordinates: state.locationSearch.results,
        locationSearchLoading: state.locationSearch.loading,
        sessionList: state.sessionList,
        tagFilters: state.sessionListFilters.tags,
        dateFilter: state.sessionListFilters.date,
        qualityFilter: state.sessionListFilters.filter_qa_tags,
        cctvMarkers: state.setCCTVMarkers,
        displayNearbyAssets,
        routeAgeData: state.routeAge,
        extraMapLayers: _.get(currentDashboard, ["config", "extra_map_layers"], []),
        mapEnvironmentData,
        mapEnvironmentDataLoaded,
        toolMode: state.markup.tool_mode,
        sessionListFiltersActive: !_.isEqual(SESSION_FILTERS_DEFAULTS, state.sessionListFilters),
        satelliteTabOpen: state.environmentData.tabOpen,
    };
};

export default connect(mapStateToProps)(withRouter(MapComponent));
