import React, { useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import _ from "lodash";
import { getEndCoordinate, getStartCoordinate, latLonToMeters, locationsAreEqual } from "../../../util/Geometry";
import { useSelector, useStore } from "react-redux";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";
import * as THREE from "three";

const videoDataSelector = (state) => {
    const playlistData = state.playlist.data;
    const sourceIndex = state.playlist.position.sourceIndex;
    return _.get(playlistData, ["video", sourceIndex], null);
};

const useSnappedSelector = (state) => state.snappedRoute || false;
const currentPositionSelector = (state) => state.playlist.position.coords;
const safeCessSettingsSelector = (state) => _.get(state.playlist.overlays, ["Safe Cess"]);
const currentSessionTagsSelector = (state) => _.get(state.sessions[state.playlist.data.routeID], "tags", []);
const currentSessionIDSelector = (state) => state.playlist.data.routeID;

const calibrationSelector = (state) => {
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }
    return get_source_calibration(
        state.measurement.calibration,
        state.playlist.position.sourceIndex,
        getAbsoluteTimestamp(state.playlist) * 1000,
        true,
        defaultRailSeparation,
    );
};

const vertexShader = `
      varying float Opacity;
      attribute float opacity;
    
      void main() {
        Opacity = float(opacity);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;

const redZoneShader = `
    varying float Opacity;
    void main() {
        vec3 red = vec3(1.0, 0.0, 0.0);
        gl_FragColor = vec4(red, pow(Opacity, 0.5) * min(0.5, 1.0 - (gl_FragCoord.z / gl_FragCoord.w)/25.0));
    }`;

const amberZoneShader = `
    void main() {
        vec3 amber = vec3(1.0, 1.0, 0.0);
        gl_FragColor = vec4(amber, min(0.5, 1.0 - (gl_FragCoord.z / gl_FragCoord.w)/25.0));
    }`;

const greenZoneShader = `
    varying float Opacity;
    void main() {
        vec3 green = vec3(0.0, 0.5, 0.0);
        gl_FragColor = vec4(green, Opacity * min(0.5, 1.0 - (gl_FragCoord.z / gl_FragCoord.w)/25.0));
    }`;

function triangulate(triangle_strip) {
    let ccw = false;

    let coords = [];
    let indices = [];

    for (let i = 0; i < triangle_strip.length; i++) {
        triangle_strip[i].forEach((c) => coords.push(c));
    }

    for (let i = 2; i < triangle_strip.length; i++) {
        const p0 = i - 2;
        const p1 = i - 1;
        const p2 = i;

        if (ccw) {
            indices.push(p1, p0, p2);
        } else {
            indices.push(p0, p1, p2);
        }

        ccw = !ccw;
    }

    return { coords, indices };
}

function convertToGeometry(coordinates, opacity = null) {
    const { coords, indices } = triangulate(coordinates);

    return (
        <bufferGeometry attach={"geometry"}>
            <bufferAttribute
                attachObject={["attributes", "position"]}
                array={new Float32Array(coords)}
                count={coords.length / 3}
                itemSize={3}
            />
            <bufferAttribute
                attach="index"
                array={new Uint32Array(indices)}
                count={indices.length}
                itemSize={1}
            />
            {opacity !== null ? (
                <bufferAttribute
                    attachObject={["attributes", "opacity"]}
                    array={new Float32Array(opacity)}
                    count={opacity.length}
                    itemSize={1}
                />
            ) : null}
        </bufferGeometry>
    );
}

function ThreeDCorridor() {
    // Trigger updates on redux position state changes for this component only
    const store = useStore();

    const ref = useRef();
    const currentPositionRef = useRef(currentPositionSelector(store.getState()));

    const videoData = useSelector(videoDataSelector);
    const useSnapped = useSelector(useSnappedSelector);
    const safeCessSettings = useSelector(safeCessSettingsSelector);
    const mode = _.get(safeCessSettings, "mode", "both");
    const zoneSpeed = _.get(safeCessSettings, "zoneSpeed", -100);
    // all below values can/are set in workspace config
    const safeCessDefaultRedWidth = _.get(safeCessSettings, "red_width", 1250) / 1000;
    const safeCessDefaultAmberWidth = _.get(safeCessSettings, "amber_width", 750) / 1000;
    const safeCessDefaultGreenWidth = _.get(safeCessSettings, "green_width", 1500) / 1000;
    const safeCessDefaultRedWidthOver100 = _.get(safeCessSettings, "red_width_over_100", 2000) / 1000;
    const safeCessDefaultAmberWidthOver100 = _.get(safeCessSettings, "amber_width_over_100", 1000) / 1000;
    const safeCessDefaultGreenWidthOver100 = _.get(safeCessSettings, "green_width_over_100", 1500) / 1000;

    const calibration = useSelector(calibrationSelector);
    const currentSessionTags = useSelector(currentSessionTagsSelector);
    const currentSessionID = useSelector(currentSessionIDSelector);

    const { sessionHasDirectionTag, sessionIsForward } = useMemo(() => {
        const sessionHasDirectionTag = _.includes(currentSessionTags, "Forward") || _.includes(currentSessionTags, "Backward");
        const sessionIsForward = _.includes(currentSessionTags, "Forward");
        return { sessionHasDirectionTag, sessionIsForward };
    }, [currentSessionTags]);

    let showLeft = (sessionIsForward ? mode === "left" : mode === "right") || mode === "both" ? true : false;
    let showRight = (sessionIsForward ? mode === "right" : mode === "left") || mode === "both" ? true : false;

    const railSeparation = useMemo(() => calibration.railSeparation, [calibration]);

    const { routeGeometry, zeroPoint } = useMemo(() => {
        console.log("Generating corridor geometry...");

        const routeGeometry = [];
        let lastEndCoordinate = null;
        let zeroPoint = null;

        videoData.forEach((playlistItem) => {
            const startCoordinate = getStartCoordinate(playlistItem, useSnapped);
            const endCoordinate = getEndCoordinate(playlistItem, useSnapped);

            if (zeroPoint === null) {
                zeroPoint = startCoordinate;
            }

            if (lastEndCoordinate && !locationsAreEqual(startCoordinate, lastEndCoordinate)) {
                routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
            }
            if (startCoordinate) {
                routeGeometry.push(latLonToMeters(zeroPoint, startCoordinate));
            }
            lastEndCoordinate = endCoordinate;
        });
        if (lastEndCoordinate) {
            routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
        }

        return { routeGeometry, zeroPoint };
    }, [videoData, useSnapped]);

    const { leftRedZoneCoords, rightRedZoneCoords, leftAmberZoneCoords, rightAmberZoneCoords, leftGreenZoneCoords, rightGreenZoneCoords } = useMemo(() => {
        const halfRailWidth = railSeparation / 2 + 0.01;

        let redZoneWidth = safeCessDefaultRedWidth;
        let amberZoneWidth = safeCessDefaultAmberWidth;
        let greenZoneWidth = safeCessDefaultGreenWidth;

        if (zoneSpeed === "+100") {
            redZoneWidth = safeCessDefaultRedWidthOver100;
            amberZoneWidth = safeCessDefaultAmberWidthOver100;
            greenZoneWidth = safeCessDefaultGreenWidthOver100;
        }

        const leftRedZoneCoords = [];
        const rightRedZoneCoords = [];

        const leftAmberZoneCoords = [];
        const rightAmberZoneCoords = [];

        const leftGreenZoneCoords = [];
        const rightGreenZoneCoords = [];

        if (routeGeometry.length < 2) {
            //do nothing
        } else {
            for (let i = 0; i < routeGeometry.length; i++) {
                let o0, o1, p;
                if (i === 0) {
                    o0 = p = routeGeometry[i];
                    o1 = routeGeometry[i + 1];
                } else {
                    o0 = routeGeometry[i - 1];
                    o1 = p = routeGeometry[i];
                }
                const angle = Math.atan2(o1[1] - o0[1], o1[0] - o0[0]);
                const sinAngle = Math.sin(angle);
                const cosAngle = Math.cos(angle);
                const railXOffset = cosAngle * halfRailWidth;
                const railZOffset = -sinAngle * halfRailWidth;
                const redZoneXOffset = cosAngle * (halfRailWidth + redZoneWidth);
                const redZoneZOffset = -sinAngle * (halfRailWidth + redZoneWidth);
                const amberZoneXOffset = cosAngle * (halfRailWidth + redZoneWidth + amberZoneWidth);
                const amberZoneZOffset = -sinAngle * (halfRailWidth + redZoneWidth + amberZoneWidth);
                const greenZoneXOffset = cosAngle * (halfRailWidth + redZoneWidth + amberZoneWidth + greenZoneWidth);
                const greenZoneZOffset = -sinAngle * (halfRailWidth + redZoneWidth + amberZoneWidth + greenZoneWidth);

                if (i === 0) {
                    const startX = sinAngle * 25;
                    const startZ = cosAngle * 25;

                    leftRedZoneCoords.push([-p[1] - redZoneXOffset + startX, 0, -p[0] - redZoneZOffset + startZ]);
                    leftRedZoneCoords.push([-p[1] - railXOffset + startX, 0, -p[0] - railZOffset + startZ]);
                    rightRedZoneCoords.push([-p[1] + railXOffset + startX, 0, -p[0] + railZOffset + startZ]);
                    rightRedZoneCoords.push([-p[1] + redZoneXOffset + startX, 0, -p[0] + redZoneZOffset + startZ]);

                    leftAmberZoneCoords.push([-p[1] - amberZoneXOffset + startX, 0, -p[0] - amberZoneZOffset + startZ]);
                    leftAmberZoneCoords.push([-p[1] - redZoneXOffset + startX, 0, -p[0] - redZoneZOffset + startZ]);
                    rightAmberZoneCoords.push([-p[1] + redZoneXOffset + startX, 0, -p[0] + redZoneZOffset + startZ]);
                    rightAmberZoneCoords.push([-p[1] + amberZoneXOffset + startX, 0, -p[0] + amberZoneZOffset + startZ]);

                    leftGreenZoneCoords.push([-p[1] - greenZoneXOffset + startX, 0, -p[0] - greenZoneZOffset + startZ]);
                    leftGreenZoneCoords.push([-p[1] - amberZoneXOffset + startX, 0, -p[0] - amberZoneZOffset + startZ]);
                    rightGreenZoneCoords.push([-p[1] + amberZoneXOffset + startX, 0, -p[0] + amberZoneZOffset + startZ]);
                    rightGreenZoneCoords.push([-p[1] + greenZoneXOffset + startX, 0, -p[0] + greenZoneZOffset + startZ]);
                }

                leftRedZoneCoords.push([-p[1] - redZoneXOffset, 0, -p[0] - redZoneZOffset]);
                leftRedZoneCoords.push([-p[1] - railXOffset, 0, -p[0] - railZOffset]);
                rightRedZoneCoords.push([-p[1] + railXOffset, 0, -p[0] + railZOffset]);
                rightRedZoneCoords.push([-p[1] + redZoneXOffset, 0, -p[0] + redZoneZOffset]);

                leftAmberZoneCoords.push([-p[1] - amberZoneXOffset, 0, -p[0] - amberZoneZOffset]);
                leftAmberZoneCoords.push([-p[1] - redZoneXOffset, 0, -p[0] - redZoneZOffset]);
                rightAmberZoneCoords.push([-p[1] + redZoneXOffset, 0, -p[0] + redZoneZOffset]);
                rightAmberZoneCoords.push([-p[1] + amberZoneXOffset, 0, -p[0] + amberZoneZOffset]);

                leftGreenZoneCoords.push([-p[1] - greenZoneXOffset, 0, -p[0] - greenZoneZOffset]);
                leftGreenZoneCoords.push([-p[1] - amberZoneXOffset, 0, -p[0] - amberZoneZOffset]);
                rightGreenZoneCoords.push([-p[1] + amberZoneXOffset, 0, -p[0] + amberZoneZOffset]);
                rightGreenZoneCoords.push([-p[1] + greenZoneXOffset, 0, -p[0] + greenZoneZOffset]);

                if (i === routeGeometry.length - 1) {
                    const endX = -sinAngle * 25;
                    const endZ = -cosAngle * 25;

                    leftRedZoneCoords.push([-p[1] - redZoneXOffset + endX, 0, -p[0] - redZoneZOffset + endZ]);
                    leftRedZoneCoords.push([-p[1] - railXOffset + endX, 0, -p[0] - railZOffset + endZ]);
                    rightRedZoneCoords.push([-p[1] + railXOffset + endX, 0, -p[0] + railZOffset + endZ]);
                    rightRedZoneCoords.push([-p[1] + redZoneXOffset + endX, 0, -p[0] + redZoneZOffset + endZ]);

                    leftAmberZoneCoords.push([-p[1] - amberZoneXOffset + endX, 0, -p[0] - amberZoneZOffset + endZ]);
                    leftAmberZoneCoords.push([-p[1] - redZoneXOffset + endX, 0, -p[0] - redZoneZOffset + endZ]);
                    rightAmberZoneCoords.push([-p[1] + redZoneXOffset + endX, 0, -p[0] + redZoneZOffset + endZ]);
                    rightAmberZoneCoords.push([-p[1] + amberZoneXOffset + endX, 0, -p[0] + amberZoneZOffset + endZ]);

                    leftGreenZoneCoords.push([-p[1] - greenZoneXOffset + endX, 0, -p[0] - greenZoneZOffset + endZ]);
                    leftGreenZoneCoords.push([-p[1] - amberZoneXOffset + endX, 0, -p[0] - amberZoneZOffset + endZ]);
                    rightGreenZoneCoords.push([-p[1] + amberZoneXOffset + endX, 0, -p[0] + amberZoneZOffset + endZ]);
                    rightGreenZoneCoords.push([-p[1] + greenZoneXOffset + endX, 0, -p[0] + greenZoneZOffset + endZ]);
                }
            }
        }
        return { leftRedZoneCoords, rightRedZoneCoords, leftAmberZoneCoords, rightAmberZoneCoords, leftGreenZoneCoords, rightGreenZoneCoords };
    }, [
        routeGeometry,
        railSeparation,
        zoneSpeed,
        safeCessDefaultRedWidth,
        safeCessDefaultAmberWidth,
        safeCessDefaultGreenWidth,
        safeCessDefaultRedWidthOver100,
        safeCessDefaultAmberWidthOver100,
        safeCessDefaultGreenWidthOver100,
    ]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
        });
    }, [store]);

    useFrame(() => {
        if (currentPositionRef.current) {
            const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);

            if (ref.current) {
                ref.current.position.set(positionOffset[1], 0, positionOffset[0]);
            }
        }
    });

    const leftRedGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < leftRedZoneCoords.length; i += 2) {
            opacities.push(1.0);
            opacities.push(0.0);
        }
        return convertToGeometry(leftRedZoneCoords, opacities);
    }, [leftRedZoneCoords]);

    const rightRedGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < rightRedZoneCoords.length; i += 2) {
            opacities.push(0.0);
            opacities.push(1.0);
        }
        return convertToGeometry(rightRedZoneCoords, opacities);
    }, [rightRedZoneCoords]);

    const leftAmberGeometry = useMemo(() => {
        return convertToGeometry(leftAmberZoneCoords);
    }, [leftAmberZoneCoords]);

    const rightAmberGeometry = useMemo(() => {
        return convertToGeometry(rightAmberZoneCoords);
    }, [rightAmberZoneCoords]);

    const leftGreenGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < leftGreenZoneCoords.length; i += 2) {
            opacities.push(0.0);
            opacities.push(1.0);
        }
        return convertToGeometry(leftGreenZoneCoords, opacities);
    }, [leftGreenZoneCoords]);

    const rightGreenGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < rightGreenZoneCoords.length; i += 2) {
            opacities.push(1.0);
            opacities.push(0.0);
        }
        return convertToGeometry(rightGreenZoneCoords, opacities);
    }, [rightGreenZoneCoords]);

    return (
        <group ref={ref}>
            {showLeft && sessionHasDirectionTag && (
                <>
                    {/* we are passing currentSessionID to avoid problems with overlay not being drawn on session change */}
                    <mesh key={`left-red-${currentSessionID}-${zoneSpeed}`}>
                        {leftRedGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={redZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                    <mesh key={`left-amber-${currentSessionID}-${zoneSpeed}`}>
                        {leftAmberGeometry}
                        <shaderMaterial
                            attach="material"
                            fragmentShader={amberZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                    <mesh key={`left-green-${currentSessionID}-${zoneSpeed}`}>
                        {leftGreenGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={greenZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                </>
            )}
            {showRight && sessionHasDirectionTag && (
                <>
                    <mesh key={`right-red-${currentSessionID}-${zoneSpeed}`}>
                        {rightRedGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={redZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                    <mesh key={`right-amber-${currentSessionID}-${zoneSpeed}`}>
                        {rightAmberGeometry}
                        <shaderMaterial
                            attach="material"
                            fragmentShader={amberZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                    <mesh key={`right-green-${currentSessionID}-${zoneSpeed}`}>
                        {rightGreenGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={greenZoneShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                </>
            )}
        </group>
    );
}

export default ThreeDCorridor;
