import {
    ACCEPT_TERMS,
    ACCESS_TOKEN,
    WIDGET_KEY,
    ADD_CUSTOM_ANNOTATION_LABEL,
    ADD_DETECTIONS_REVIEW_FILTER,
    GET_USER_FAVOURITE_CATEGORIES,
    ADD_FAVOURITE_SESSION,
    ADD_FEATURE_OVERLAY_CORRECTION,
    ADD_MARKER_REVIEW_FILTER,
    ADD_MARKER_THRESHOLD_FILTER,
    ALWAYS_SHOWN_MARKERS,
    ANPR_DATA,
    CHANGE_GRID_OFFSET,
    CHANGE_GRID_SIZE,
    CHANGE_TOOL_MODE,
    CLASSIFICATIONS_DATA,
    CLEAR_ASSETS,
    CLEAR_GLOBAL_CONTENT,
    CLEAR_MARKER_INFO,
    CLEAR_PATROL_STATE,
    CLEAR_REQUESTED_TIMESTAMP,
    CLEAR_STATIONS,
    CLEAR_TRACK_GEOM,
    CLOSE_FULLSCREEN,
    CONSOLIDATED_HISTORY,
    CURRENT_PLAYLIST_POSITION,
    DASHBOARD_CHOICE,
    DASHBOARD_WIDGET_KEY,
    DEVICE_GROUP_INFO,
    DEVICE_STATUSES,
    DISMISS_MESSAGE,
    EXCEEDENCE_DATA,
    EXIT_CURRENT_DASHBOARD,
    EXTRA_RAIL_INSPECTION_SOURCE,
    SET_SOME_MAIN_RAIL_INSPECTION_IMAGES_MISSING,
    FEATURE_OVERLAY_ADD_FEATURE,
    FEATURE_OVERLAY_CLEAR_FEATURES,
    FEATURE_OVERLAY_REMOVE_FEATURE,
    FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES,
    FEATURE_OVERLAY_SET_DATUM,
    FEATURE_OVERLAY_UPDATE_FEATURE,
    FETCHING_EXPORT,
    FETCHING_HISTORY,
    FLIP_INSPECTION_RAILS,
    GO_TO_BOUNDS,
    GPS_TIME_OFFSETS,
    INSPECTING_PATROL,
    INSPECTION_DETECTIONS,
    KEY_TYPE,
    LOGOUT,
    MAP_GEOMETRY,
    MAP_SEGMENT_IDS,
    MARKER_INFO,
    MARKER_INFO_SHOWING,
    PATROL_COVERED,
    PATROL_IMAGES_LOADED,
    PLAYLIST,
    RAIL_INSPECTION_EXITED,
    RAIL_INSPECTION_SOURCE_CLOSED,
    RAIL_INSPECTION_SOURCES_OPEN,
    RECEIVE_ACCCESS_TOKEN,
    RECEIVE_ASSET_TYPES,
    RECEIVE_ASSETS,
    RECEIVE_BOOKMARKS,
    RECEIVE_DATA_POOLS,
    RECEIVE_EXPORT,
    RECEIVE_EXPORT_UNAUTHORISED,
    RECEIVE_GLOBAL_CONTENT,
    RECEIVE_PATROL_DISPLAY_CONFIGS,
    RECEIVE_PATROL_DISPLAY_CONFIG,
    RECEIVE_PATROL_IMAGES,
    RECEIVE_PATROL_PLAN,
    PRIMARY_RAIL_IMAGE_CONFIG,
    RECEIVE_ROUTE_COORD_DATA,
    RECEIVE_ROUTE_PATROLS,
    RECEIVE_REVIEWER_ROUTE_PATROLS,
    RECEIVE_SESSION_TAGS,
    RECEIVE_SHORTCUT_DEVICES,
    RECEIVE_SHORTCUT_POINTS,
    RECEIVE_SHORTCUT_SESSIONS,
    RECEIVE_SHORTCUTS,
    RECEIVE_SNAPPING_VALIDATION,
    RECEIVE_STATIONS,
    RECEIVE_TRACK_GEOM_DATA,
    RECEIVE_TRACK_GEOM_HEADERS,
    RECEIVE_USER_EXPORTS,
    REMOVE_CUSTOM_ANNOTATION_LABEL,
    REMOVE_DETECTIONS_REVIEW_FILTER,
    REMOVE_FAVOURITE_SESSION,
    REMOVE_FEATURE_OVERLAY_CORRECTION,
    REMOVE_MARKER_REVIEW_FILTER,
    REQUEST_PLAYLIST_POSITION,
    REQUEST_PLAYLIST_TIMESTAMP,
    RESET_GRID_ADJUSTMENTS,
    RESET_IMAGE_ADJUSTMENTS,
    RESET_LOGOUT,
    RESET_SHORTCUT_SELECTED,
    RESET_SHORTCUT_SESSION,
    ROUTE_CALIBRATION_DATA,
    ROUTE_COORDINATE_SYSTEMS,
    ROUTE_HIGHLIGHTED,
    ROUTE_HISTORY,
    ROUTE_HISTORY_TOGGLE,
    ROUTE_METADATA,
    SAVING_ANNOTATIONS,
    SEARCH_ASSETS,
    SEGMENT_CLEARED,
    SEGMENT_SELECTED,
    SELECT_BOOKMARK,
    SELECT_ANNOTATION,
    SELECTED_RAIL_INSPECTION_IMAGE,
    SELECTED_TAG_CATEGORY,
    SESSION_IDS,
    SESSION_LOGS,
    SESSION_MARKERS,
    SESSIONS_LIST,
    SET_ASSET_SEARCH_TYPE,
    SET_CONTENT_PAGE_COUNT,
    SET_CURRENT_DETECTION,
    SET_FULLSCREEN_VALUE,
    SET_MAP_ZOOM_LEVEL,
    SET_NEW_IMAGE_ADJUSTMENTS,
    SET_NEW_DETAIL_IMAGE_ADJUSTMENTS,
    SET_PATROL_LOCATION,
    SET_PREFERENCE,
    SET_SELECTED_PATROL_SESSION,
    SET_SELECTED_PATROL_SESSION_ID,
    SHARE_LINK,
    SHARE_LINK_DETAILS,
    SHORTCUT_SELECTED,
    SHOW_ROUTE_HISTORY,
    SUBMIT_NAME,
    TARGET_RESOURCE,
    TOGGLE_ASSET_DISPLAY,
    TOGGLE_DESKTOP,
    TOGGLE_DETAIL_VIEW,
    TOGGLE_DETECTIONS_OPEN,
    TOGGLE_FULLSCREEN,
    TOGGLE_GRID_VISIBLE,
    TOGGLE_PLAYER_STATE,
    TOGGLE_ROUTE_SNAPPING,
    TOGGLE_SETTINGS_OPEN,
    SET_AUTO_SCROLL,
    TOGGLE_SNAPSHOT,
    TOGGLE_STATION_DISPLAY,
    TOGGLE_UNSAVED_CHANGE,
    TOGGLE_VERTICAL_NAV_WINDOW,
    UNCACHE_SESSION_LIST,
    UPDATE_DATE_RANGE,
    UPDATE_DIMENSIONS,
    UPDATE_LAST_ACTIVE,
    UPDATE_MAP_BOUNDS,
    UPDATE_MAP_ZOOM,
    UPDATE_ZOOM,
    USER_ANNOTATION_TYPES,
    USER_ANNOTATIONS,
    USER_CONFIG,
    USER_DETAILS,
    USER_MEASUREMENTS,
    USER_SKETCHES,
    VIEW_DATA,
    WIDGET_DATA,
    WORKSPACE_USERS,
    ADD_DETECTIONS_TYPE_FILTER,
    REMOVE_DETECTIONS_TYPE_FILTER,
    INSPECTION_DETECTIONS_FETCHED,
    RECEIVE_INSPECTION_ANNOTATION_TYPES,
    RECEIVE_INSPECTION_ANNOTATIONS,
    TOGGLE_INSPECTION_ANNOTATION_MODE,
    SET_INSPECTION_MARKUP,
    SET_SELECTED_CLASSIFICATION,
    INSPECTION_ANNOTATIONS_FETCHED,
    INSPECTION_BOOKMARKS_FETCHED,
    TOGGLE_DETECTION_VISIBILITY,
    TOGGLE_INSPECTION_SNAPSHOT,
    HOVERED_SESSION_LINES,
    SCHEMA_NAVIGATION_PAGE,
    RESET_INSPECTION_DETECTIONS,
    TOGGLE_FETCHING_DETECTIONS,
    RECEIVE_PATROL_DIRECTIONS,
    ROUTE_PATROL_EXITED,
    SET_STILL_IMAGE_ADJUSTMENTS,
    RESET_STILL_IMAGE_ADJUSTMENTS,
    SET_PATROL_REPORT_OPEN,
    SET_PATROL_REPORT_FORM,
    RECEIVE_PATROL_HISTORY,
    RECEIVE_PATROL_LOCATION,
    SET_SCHEMA_MARKUP,
    RESET_PATROL_COVERAGE,
    PATROL_STATUS,
    RECEIVE_PATROL_USERS,
    ROUTE_PATROLS_FETCHED,
    SAVE_MARKER_DEFAULTS,
    RECEIVE_MARKUP_EXPORT_TYPES,
    TOGGLE_FETCHING_ELRS,
    SET_PATROL_HISTORY_TIMESTAMP,
    CALCULATE_PATROL_SESSIONS,
    TOGGLE_FETCHING_PATROL_HISTORY,
    RECEIVE_DATA_FILTERS,
    RECEIVE_SESSION_CONFIG,
    RECEIVE_HISTORY_MARKERS,
    LOADING_MARKER_HISTORY,
    UPDATE_PRIMARY_RAIL_IMAGES,
    RECEIVE_PATROL_PLANS,
    CLEAR_PATROL_IMAGES,
    COMPARABLE_SESSIONS,
    INSPECTION_CONDITIONS_FETCHED,
    UPDATE_RAIL_INSPECTION_DETECTION_CONDITION,
    STORE_CONDITION_TYPES,
    UPDATE_SESSION_MARKERS_BY_SESSION_ID,
    CLEAR_SHARE_DETAILS,
    SET_MARKER_CONDITION_FILTER,
    ADD_MARKER_CONDITION_FILTER,
    REMOVE_MARKER_CONDITION_FILTER,
    TOGGLE_PATROL_BUG_REPORT,
    TOGGLE_PATROL_DIAGRAM_REPORT,
    SET_PATROL_PAGE,
    SET_TOTAL_PATROL_PAGES,
    SET_DETECTION_CONDITIONS_FILTERS,
    SET_CURRENT_TAB,
    GO_TO_POSITION,
    MAP_POINT_SELECTED,
    SET_VIDEO_SPEED,
    DISPLAY_EXTRA_INSPECTION_LAYERS,
    RECEIVE_PATROL_RUNS,
    SET_DIRECTION_ARROW,
    TOGGLE_PATROL_DETAIL_MODAL,
    SET_REQUESTED_CONTENT,
    CLEAR_REQUESTED_CONTENT,
    SESSION_CONTINUOUS_OBSERVATIONS,
    SESSION_OBSERVATIONS,
    SET_CURRENT_QA_TAB,
    SET_DISPLAY_USER_PREFERENCES,
    USER_ORGANISATION_PREFIX,
    RESET_USER_ORGANISATION_PREFIX,
    INSPECTION_CLASSIFICATIONS_FETCHED,
    INSPECTION_CLASSIFICATIONS,
    RESET_INSPECTION_CLASSIFICATIONS,
    TOGGLE_FETCHING_CLASSIFICATIONS,
    SET_CLASSIFICATION_SCORE,
    PATROL_PLAN_IMAGES,
    SET_CLOSEST_ASSETS,
    SET_OVERLAY,
    SET_OVERLAY_DEFAULTS,
    TOGGLE_CLASSIFICATION_VISIBILITY,
    CLASSIFICATIONS_TAB_OPEN,
    SET_INSPECTION_TARGET_TIMESTAMP,
    TOGGLE_LOGGING,
    RECEIVE_LOCATION_SEARCH_RESULT,
    DISPLAY_LOCATION_RESULTS_ON_MAP,
    CLEAR_LOCATION_RESULTS,
    LOCATION_SEARCH_LOADING,
    RECEIVE_INSPECTION_PULSE_COUNTS,
    UPDATE_SESSIONS_LIST,
    TOGGLE_RAIL_INSPECTION_WIDGET_TIMELINE,
    CCTV_MARKERS,
    CCTV_IMAGES,
    PARENT_CONNECTED,
    RECEIVE_CSRF_TOKEN,
    OBSERVATION_DETECTIONS,
    SET_TROUGHING_FILTER,
    SET_RAIL_DETAIL_WIDGET_SELECTED_SOURCES,
    SET_LAST_FLAG_REASON,
    TOGGLE_REJECTION_MODAL,
    SET_FOLLOW_DETECTIONS,
    TOGGLE_DETECTIONS_EXPORT_OPEN,
    WIDGET_USER_CONFIG,
    RECEIVE_INSPECTION_FAULTS,
    SET_LOW_BALLAST_CONFIDENCE_FILTER,
    SET_DISPLAY_NEARBY_ASSETS,
    SET_CURRENT_NEARBY_ASSET,
    RECEIVE_DETECTION_TYPES,
    RECEIVE_ROUTE_AGE,
    TOGGLE_ANNOTATION_VISIBILITY,
    SESSIONS_ENVIRONMENT_DATA,
    RECEIVE_INSPECTION_CONDITION_TYPES,
    SET_SPERRY_MARKERS,
    SET_MAP_ENVIRONMENT_DATA,
    SET_MAP_ENVIRONMENT_DATA_TYPES,
    SET_MAP_ENVIRONMENT_SUB_DATA_TYPES,
    SET_MAP_ENVIRONMENT_SELECTED_SEGMENT,
    SET_MAP_ENVIRONMENT_FILTERS,
    IS_MAGNIFY_TOGGLED,
    RECEIVE_REMOTE_FEATURES,
    SET_POSITION_LEFT_BIASED,
    TOGGLE_DETAIL_CAMERA_AUTO_SELECTION,
    SET_SATELLITE_TAB_OPEN,
    SET_DIP_ANGLE_FILTER,
    OBJECT_OBSERVATION_DATA_TYPE,
    RECEIVE_API_VERSION,
    SET_INSPECTION_DETECTIONS_EXPORT_RANGE,
    RESET_INSPECTION_DETECTIONS_EXPORT_RANGE,
} from "./actions/index";
import { combineReducers } from "redux";
import _ from "lodash";

import sessionListFilters from "./reducers/sessionFilterReducers";
import issues from "./reducers/issueReducers";
import mlAssets from "./reducers/assetReducers";
import mediaUploads from "./reducers/mediaReducers";
import searchLocationData from "./reducers/locationSearchReducers";
import admin from "./reducers/adminPanelReducer";
import driverTraining from "./reducers/driverTrainingReducers";
import megasearchReducers from "./reducers/megasearchReducers";
import { mergeInspectionDetections } from "../components/util/InspectionDetectionUtils";
import widgets from "./reducers/widgetReducers";

const DEFAULT_ROUTE_METADATA = {};

function lastFlagReason(state = null, action) {
    if (action.type === SET_LAST_FLAG_REASON) {
        state = action.reason;
    }
    return state;
}

function toggleRejectionModal(state = false, action) {
    if (action.type === TOGGLE_REJECTION_MODAL) {
        state = !state;
    }
    return state;
}

function CCTVMarkers(state = [], action) {
    if (action.type === CCTV_MARKERS) {
        state = action.cctvMarkers;
    }
    return state;
}

function CCTVImages(state = [], action) {
    if (action.type === CCTV_IMAGES) {
        state = action.cctvImages;
    }
    return state;
}

function assetTypes(state = [], action) {
    if (action.type === RECEIVE_ASSET_TYPES) {
        state = action.types;
    }
    return state;
}

function defaultMarkersThresholds(state = {}, action) {
    if (action.type === SAVE_MARKER_DEFAULTS) {
        state = _.clone(state);
        state[action.sessionID] = action.scoreObject;
    }
    return state;
}

function assetsFound(state = [], action) {
    if (action.type === RECEIVE_ASSETS) {
        state = action.assets;
    }

    if (action.type === CLEAR_ASSETS || action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
}

function displayingAssets(state = false, action) {
    if (action.type === TOGGLE_ASSET_DISPLAY) {
        state = !state;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }

    return state;
}

function displayingStation(state = {}, action) {
    if (action.type === TOGGLE_STATION_DISPLAY) {
        state = action.station;
    }

    if (action.type === CLEAR_STATIONS) {
        state = {};
    }

    return state;
}

function assetSearch(state = {}, action) {
    if (action.type === SEARCH_ASSETS) {
        state = {
            type: action.assetType,
            mlAsset: action.mlAsset || false,
        };
    }

    if (action.type === CLEAR_ASSETS) {
        state = {};
    }

    return state;
}

function stations(state = [], action) {
    if (action.type === RECEIVE_STATIONS) {
        state = action.stations;
    }

    return state;
}

function nearestAssets(state = [], action) {
    if (action.type === SET_CLOSEST_ASSETS) {
        state = action.assets;
    }

    return state;
}

function displayNearbyAssets(state = false, action) {
    if (action.type === SET_DISPLAY_NEARBY_ASSETS) {
        state = action.bool;
    }

    return state;
}

function currentNearbyAsset(state = {}, action) {
    if (action.type === SET_CURRENT_NEARBY_ASSET) {
        state = action.asset;
    }

    return state;
}

const assets = combineReducers({
    assetTypes,
    assetsFound,
    displayingAssets,
    displayingStation,
    assetSearch,
    stations,
    nearestAssets,
    displayNearbyAssets,
    currentNearbyAsset,
});

function userPreferences(state = {}, action) {
    if (action.type === SET_PREFERENCE) {
        state = _.clone(state);
        state[action.preferenceKey] = action.preferenceValue;
    } else if (action.type === USER_CONFIG) {
        if (state.loggingEnabled === undefined && _.get(action, ["userConfig", "super_admin"], false)) {
            state = _.clone(state);
            state.loggingEnabled = true;
        }
    }

    return state;
}

function snappedRoute(state = true, action) {
    if (action.type === TOGGLE_ROUTE_SNAPPING) {
        state = action.snap;
    }
    return state;
}

function requestedBounds(state = null, action) {
    if (action.type === GO_TO_BOUNDS) {
        state = action.bounds;
    } else if (action.type === UPDATE_MAP_BOUNDS) {
        state = null;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function requestedPosition(state = null, action) {
    if (action.type === GO_TO_POSITION) {
        state = action.coords;
    }

    return state;
}

function currentBounds(state = null, action) {
    if (action.type === UPDATE_MAP_BOUNDS) {
        state = {
            north: action.north,
            east: action.east,
            south: action.south,
            west: action.west,
        };
    }
    return state;
}

function currentZoom(state = null, action) {
    if (action.type === UPDATE_MAP_ZOOM) {
        state = action.zoom;
    }
    return state;
}

const mapBounds = combineReducers({
    requested: requestedBounds,
    current: currentBounds,
    zoom: currentZoom,
    requestedPosition: requestedPosition,
});

function dateRange(state = {}, action) {
    if (action.type === UPDATE_DATE_RANGE) {
        state = {
            from: action.from,
            to: action.to,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }
    return state;
}

const sessionFilters = combineReducers({
    mapBounds,
    dateRange,
});

function updateSessions(state = {}, action) {
    if (action.type === UPDATE_SESSIONS_LIST) {
        state = action.sessions;
    }

    return state;
}

function sessions(state = {}, action) {
    if (action.type === SESSIONS_LIST) {
        let newState = _.clone(state);
        state = {
            ...newState,
            ...action.sessions,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }

    if (action.type === UNCACHE_SESSION_LIST) {
        state = {};
    }

    if (action.type === ADD_FAVOURITE_SESSION) {
        state = _.clone(state);
        state[action.sessionID] = {
            ...state[action.sessionID],
            favourite: true,
            favourite_category_id: action.categoryID,
        };
    }

    if (action.type === REMOVE_FAVOURITE_SESSION) {
        state = _.clone(state);
        state[action.sessionID] = {
            ...state[action.sessionID],
            favourite: false,
            favourite_category_id: null,
        };
    }

    return state;
}

function mapSegmentList(state = [], action) {
    if (action.type === MAP_SEGMENT_IDS) {
        state = action.mapSegmentIDs;
    }

    return state;
}

function sessionList(state = [], action) {
    if (action.type === SESSION_IDS) {
        state = action.sessionIDs;
    }

    if (action.type === UNCACHE_SESSION_LIST) {
        state = [];
    }

    return state;
}

function sessionTags(state = [], action) {
    if (action.type === RECEIVE_SESSION_TAGS) {
        state = action.tags;
    }

    if (action.type === UNCACHE_SESSION_LIST) {
        state = [];
    }

    return state;
}

function routeMetadata(state = DEFAULT_ROUTE_METADATA, action) {
    if (action.type === ROUTE_METADATA) {
        state = _.clone(state);
        if (_.has(state, action.metadataType)) {
            state[action.metadataType] = _.clone(state[action.metadataType]);
        } else {
            state[action.metadataType] = {};
        }

        if (_.has(state[action.metadataType], action.sessionID)) {
            state[action.metadataType][action.sessionID] = _.sortBy(
                _.uniqBy(_.concat(state[action.metadataType][action.sessionID], action.metadata), "timestamp"),
                "timestamp",
            );
        } else {
            state[action.metadataType][action.sessionID] = action.metadata;
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = DEFAULT_ROUTE_METADATA;
    }
    return state;
}

function highlightedRouteID(state = null, action) {
    if (action.type === ROUTE_HIGHLIGHTED) {
        state = action.sessionID;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function playlistData(state = {}, action) {
    if (action.type === PLAYLIST) {
        state = {
            routeID: action.routeID,
            mpdURLs: action.mpdURLs,
            video: action.videoPlaylist,
            image: action.imagePlaylist,
            loaded: action.routeID !== null && action.videoPlaylist !== null,
            route_locations: action.route_locations,
            data: action.data || null,
            discontinuity_indexes: action.discontinuity_indexes,
            system_id: action.system_id,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }
    return state;
}

function playlistCache(state = {}, action) {
    if (action.type === PLAYLIST) {
        if (action.videoPlaylist !== null) {
            state = _.clone(state);
            let gps_offsets = [];
            if (state[action.routeID]) {
                gps_offsets = state[action.routeID].gps_offsets;
            }
            state[action.routeID] = {
                lastUsed: new Date().getTime(),
                mpdURLs: action.mpdURLs,
                videoPlaylist: action.videoPlaylist,
                imagePlaylist: action.imagePlaylist,
                gps_offsets: gps_offsets,
                route_locations: action.route_locations,
                data: action.data,
                discontinuity_indexes: action.discontinuity_indexes,
                system_id: action.system_id,
            };
            if (_.keys(state).length > 5) {
                let lastUsedKey = _.sortBy(_.toPairs(state), "1.lastUsed")[0][0];
                delete state[lastUsedKey];
            }
        }
    } else if (action.type === GPS_TIME_OFFSETS) {
        state = _.clone(state);
        if (state[action.sessionID]) {
            state[action.sessionID] = {
                ...state[action.sessionID],
                gps_offsets: action.offsets,
            };
        } else {
            state[action.sessionID] = {
                gps_offsets: action.offsets,
            };
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }
    return state;
}

function playlistPosition(state = { currentIndex: 0, currentTimeOffset: 0, pointOnTheMapClicked: false }, action) {
    if (action.type === REQUEST_PLAYLIST_POSITION) {
        state = _.clone(state);
        state.isVideo = action.isVideo;
        state.isEnhanced = action.isEnhanced;
        state.isStills = action.isStills;
        state.sourceIndex = action.sourceIndex;
        state.requestedIndex = action.index;
        state.requestedTimeOffset = action.timeOffset;
        state.requestTimestamp = new Date().getTime();
    } else if (action.type === CURRENT_PLAYLIST_POSITION) {
        state = _.clone(state);
        state.currentIndex = action.index;
        state.coords = action.coords;
        state.currentTimeOffset = action.offset;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    } else if (action.type === TOGGLE_PLAYER_STATE) {
        state = _.clone(state);
        state.playerState = action.playerState;
    } else if (action.type === RECEIVE_ROUTE_COORD_DATA) {
        state = _.clone(state);
        state.routeCoordData = action.data;
    } else if (action.type === RECEIVE_SNAPPING_VALIDATION) {
        state = _.clone(state);
        state.snappingValidations = action.validations;
    } else if (action.type === REQUEST_PLAYLIST_TIMESTAMP) {
        state = _.clone(state);
        state.requestedTimestamp = action.timestamp;
    } else if (action.type === CLEAR_REQUESTED_TIMESTAMP) {
        state = _.clone(state);
        state.requestedTimestamp = null;
        state.requestedIndex = null;
    } else if (action.type === MAP_POINT_SELECTED) {
        state = _.clone(state);
        state.pointOnTheMapClicked = action.mapPointSelected;
        state.coords = action.coords;
    } else if (action.type === PLAYLIST) {
        state = _.clone(state);
        state.pointOnTheMapClicked = false;
    }
    return state;
}

function routeHistory(state = { hidden: null }, action) {
    if (action.type === ROUTE_HISTORY) {
        state = {
            ...state,
            videoKey: action.key,
            success: action.success,
            history: action.history,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = { hidden: null };
    } else if (action.type === ROUTE_HISTORY_TOGGLE) {
        state = _.clone(state);
        state.hidden = action.hidden;
    }
    return state;
}

const INTIAL_HISTORY_STATE = {
    show: false,
    requestedTimestamp: null,
    fetching: false,
    history: [],
};

function consolidatedHistory(state = INTIAL_HISTORY_STATE, action) {
    if (action.type === CONSOLIDATED_HISTORY) {
        state = {
            ...state,
            requestedTimestamp: action.key,
            success: action.success,
            history: action.history,
            fetching: false,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = INTIAL_HISTORY_STATE;
    } else if (action.type === ROUTE_HISTORY_TOGGLE) {
        state = _.clone(state);
        state.hidden = action.hidden;
    } else if (action.type === FETCHING_HISTORY) {
        state = _.clone(state);
        state.fetching = true;
    } else if (action.type === SHOW_ROUTE_HISTORY) {
        state = _.clone(state);
        state.show = action.show;
    }
    return state;
}

function sessionLogs(state = [], action) {
    if (action.type === SESSION_LOGS) {
        state = action.data;
    }

    return state;
}

function stillImageAdjustments(state = {}, action) {
    if (action.type === SET_STILL_IMAGE_ADJUSTMENTS) {
        let newState = _.clone(state);
        newState = _.set(newState, [action.sourceIndex, action.adjustmentType], action.value);
        state = newState;
    } else if (action.type === RESET_STILL_IMAGE_ADJUSTMENTS) {
        state = {};
    }
    return state;
}

function playbackSpeed(state = 1.0, action) {
    if (action.type === SET_VIDEO_SPEED) {
        let newState = _.clone(state);
        newState = action.videoSpeed;
        state = newState;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = 1.0;
    }
    return state;
}

const OVERLAY_INITIAL_STATE = {
    "Safe Cess": {
        mode: "both",
        zoneSpeed: "-100",
        enabled: false,
    },
    Tunnel: {
        width: 4000, //this is getting overridden from dashboard config
        height: 4000, //this is getting overridden from dashboard config
        enabled: false,
        length: 10, //this is getting overridden from dashboard config
    },
    "Ground Clearance": {
        mode: "left",
        enabled: false,
        width: 3000, //this is getting overridden from dashboard config
    },
};

function overlays(state = OVERLAY_INITIAL_STATE, action) {
    if (action.type === SET_OVERLAY) {
        state = _.clone(state);
        state[action.overlayType] = action.overlaySettings;
    } else if (action.type === SET_OVERLAY_DEFAULTS) {
        state = _.clone(state);
        state = _.merge(state, action.overlaysSettings);
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = OVERLAY_INITIAL_STATE;
    }
    return state;
}

const playlist = combineReducers({
    data: playlistData,
    cache: playlistCache,
    position: playlistPosition,
    history: routeHistory,
    consolidatedHistory,
    logData: sessionLogs,
    stillImageAdjustments,
    playbackSpeed,
    overlays,
});

function access_token(state = null, action) {
    if (action.type === ACCESS_TOKEN || action.type === RECEIVE_ACCCESS_TOKEN) {
        state = action.token || null;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function widget_key(state = null, action) {
    if (action.type === WIDGET_KEY) {
        state = action.key || null;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

const DEFAULT_USER_DETAILS = {
    email: null,
    description: null,
    organisation: null,
    branding: null,
    isDashboardBeta: false,
    isPrimaryKey: false,
    userConfig: {},
    lastActive: Math.round(Date.now() / 1000),
    sessionConfig: {},
    favouriteCategories: [],
    apiVersion: null,
};

function targetResource(state = null, action) {
    if (action.type === TARGET_RESOURCE) {
        state = action.target_resource;
    }
    return state;
}

function widgetData(state = {}, action) {
    if (action.type === WIDGET_DATA) {
        state = action.data;
    }
    return state;
}

function dashboardWidgetKey(state = null, action) {
    if (action.type === DASHBOARD_WIDGET_KEY) {
        state = action.key;
    }
    return state;
}

function userDetails(state = DEFAULT_USER_DETAILS, action) {
    if (action.type === USER_DETAILS) {
        state = _.clone(state);
        state.email = action.email;
        state.description = action.description;
        state.organisation = action.organisation;
        state.branding = action.branding;
        state.dashboardAccessID = action.dashboardAccessID;
        state.isDashboardBeta = action.isDashboardBeta;
    } else if (action.type === DASHBOARD_CHOICE && !action.dashboards) {
        state = DEFAULT_USER_DETAILS;
    } else if (action.type === USER_CONFIG || action.type === WIDGET_USER_CONFIG) {
        state = _.clone(state);
        state.userConfig = action.userConfig;
    } else if (action.type === KEY_TYPE) {
        state = _.clone(state);
        state.isPrimaryKey = action.isPrimaryKey;
    } else if (action.type === UPDATE_LAST_ACTIVE) {
        state = _.clone(state);
        state.lastActive = Math.round(Date.now() / 1000);
    } else if (action.type === ACCEPT_TERMS) {
        state = _.cloneDeep(state);
        state.userConfig.terms_accepted = true;
    } else if (action.type === SUBMIT_NAME) {
        state = _.cloneDeep(state);
        state.userConfig.user_hidden = action.remainHidden;
        if (!action.remainHidden) {
            state.userConfig.name = action.name;
        }
    } else if (action.type === RECEIVE_SESSION_CONFIG) {
        state = _.cloneDeep(state);
        state.sessionConfig = action.config;
    } else if (action.type === GET_USER_FAVOURITE_CATEGORIES) {
        state = _.cloneDeep(state);
        state.favouriteCategories = action.categories;
    } else if (action.type === ADD_FAVOURITE_SESSION) {
        if (action.categoryID) {
            state = _.cloneDeep(state);
            const favCatIndex = _.findIndex(state.favouriteCategories, { id: action.categoryID });
            if (favCatIndex) {
                state.favouriteCategories[favCatIndex].session_data = {
                    ...state.favouriteCategories[favCatIndex].session_data,
                    [action.sessionID]: {
                        description: null,
                    },
                };
            }
        }
    } else if (action.type === REMOVE_FAVOURITE_SESSION) {
        state = _.cloneDeep(state);
        const favCatIndex = _.findIndex(state.favouriteCategories, (cat) => {
            const session_data = _.get(cat, "session_data", []);
            if (_.hasIn(session_data, action.sessionID)) {
                return true;
            }
        });

        try {
            delete state.favouriteCategories[favCatIndex].session_data[action.sessionID];
        } catch (error) {
            console.error("Error removing session from favourite category", error);
        }
    } else if (action.type === SET_DISPLAY_USER_PREFERENCES) {
        state = _.cloneDeep(state);
        state.userConfig.display_user_preferences = false;
    } else if (action.type === RECEIVE_API_VERSION) {
        state = _.cloneDeep(state);
        state.apiVersion = action.version;
    }
    return state;
}

function userOrganisationPrefix(state = null, action) {
    if (action.type === USER_ORGANISATION_PREFIX) {
        state = _.cloneDeep(state);
        state = action.userOrganisationPrefix;
    } else if (action.type === RESET_USER_ORGANISATION_PREFIX) {
        state = _.cloneDeep(state);
        state = null;
    }
    return state;
}

function permissions(state = {}, action) {
    if (action.type === ACCESS_TOKEN) {
        state = action.permissions || {};
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }
    return state;
}

function dataPools(state = {}, action) {
    if (action.type === RECEIVE_DATA_POOLS) {
        state = action.dataPools;
    }

    return state;
}

function deviceStatuses(state = [], action) {
    if (action.type === DEVICE_STATUSES) {
        state = action.statuses;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function shareLink(state = null, action) {
    if (action.type === SHARE_LINK) {
        state = action.link_token;
    } else if (action.type === LOGOUT || action.type === RESET_LOGOUT) {
        state = null;
    }
    return state;
}

function shareLinkDetails(state = null, action) {
    if (action.type === SHARE_LINK_DETAILS) {
        state = {
            sessionID: action.sessionID,
            timestamp: action.timestamp,
            enhanced: action.enhanced,
            inspection: action.inspection,
        };
    } else if (action.type === CLEAR_SHARE_DETAILS) {
        state = null;
    }
    return state;
}

function anprData(state = [], action) {
    if (action.type === ANPR_DATA) {
        state = action.data;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function selectedTagCategory(state = null, action) {
    if (action.type === SELECTED_TAG_CATEGORY) {
        state = action.category;
    } else if (action.type === PLAYLIST) {
        state = null;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function userAnnotationTypes(state = [], action) {
    if (action.type === USER_ANNOTATION_TYPES) {
        state = action.types;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function detectionTypes(state = [], action) {
    if (action.type === RECEIVE_DETECTION_TYPES) {
        state = action.types;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function userAnnotations(state = [], action) {
    if (action.type === USER_ANNOTATIONS) {
        state = action.annotations;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function userSketches(state = [], action) {
    if (action.type === USER_SKETCHES) {
        state = action.sketches;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function userMeasurements(state = [], action) {
    if (action.type === USER_MEASUREMENTS) {
        state = action.measurements;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function savingAnnotations(state = false, action) {
    if (action.type === SAVING_ANNOTATIONS) {
        state = action.saving;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }
    return state;
}

function alwaysShownMarkers(state = null, action) {
    if (action.type === ALWAYS_SHOWN_MARKERS) {
        state = action.markers;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function sessionMarkers(state = {}, action) {
    if (action.type === SESSION_MARKERS) {
        state = _.clone(state);
        state[action.sessionID] = action.markers;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }
    if (action.type === UPDATE_SESSION_MARKERS_BY_SESSION_ID) {
        state = _.cloneDeep(state);
        state[action.sessionID] = action.newData;
    }
    return state;
}

function selectedMarker(state = {}, action) {
    if (action.type === MARKER_INFO) {
        state = action.marker;
    } else if ([CLEAR_MARKER_INFO, EXIT_CURRENT_DASHBOARD, PLAYLIST].includes(action.type)) {
        state = {};
    }
    return state;
}

function showingMarkerInfo(state = false, action) {
    if (action.type === MARKER_INFO) {
        if (!_.isEmpty(action.marker)) {
            state = true;
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    } else if (action.type === MARKER_INFO_SHOWING) {
        state = action.showing;
    }

    return state;
}

function markerHistoryLoading(state = false, action) {
    if (action.type === RECEIVE_HISTORY_MARKERS) {
        state = false;
    }

    if (action.type === LOADING_MARKER_HISTORY) {
        state = true;
    }

    return state;
}

function historyMarkers(state = [], action) {
    if (action.type === RECEIVE_HISTORY_MARKERS) {
        state = action.markers;
    }

    return state;
}

function markerHistoryFetched(state = {}, action) {
    // if(action.type === RECEIVE_HISTORY_MARKERS){
    //     state = action.fetched;
    // }

    return state;
}

function conditionTypes(state = [], action) {
    if (action.type === STORE_CONDITION_TYPES) {
        state = action.conditionTypes;
    }
    return state;
}

const markerHistory = combineReducers({
    markers: historyMarkers,
    loading: markerHistoryLoading,
    fetched: markerHistoryFetched,
});

const markers = combineReducers({
    alwaysShown: alwaysShownMarkers,
    perSession: sessionMarkers,
    selectedMarker: selectedMarker,
    showingMarkerInfo: showingMarkerInfo,
    history: markerHistory,
    conditionTypes: conditionTypes,
});

function mapGeometry(state = null, action) {
    if (action.type === MAP_GEOMETRY) {
        state = action.geometry;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function segmentSelection(state = { sessionID: 0, start: -1, end: -1 }, action) {
    if (action.type === SEGMENT_SELECTED) {
        state = action.selection;
    } else if (action.type === SEGMENT_CLEARED || action.type === EXIT_CURRENT_DASHBOARD) {
        state = { sessionID: 0, start: -1, end: -1 };
    }

    return state;
}

function calibration(state = [], action) {
    if (action.type === ROUTE_CALIBRATION_DATA) {
        state = action.data;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

const measurement = combineReducers({
    calibration,
});

function classifications(state = null, action) {
    if (action.type === CLASSIFICATIONS_DATA) {
        state = action.classificationsData;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function tool_mode(state = false, action) {
    if (action.type === CHANGE_TOOL_MODE) {
        state = action.tool;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }
    return state;
}

function hasUnsavedChange(state = false, action) {
    if (action.type === TOGGLE_UNSAVED_CHANGE) {
        state = action.state;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }

    return state;
}

function zoom(state = 1, action) {
    if (action.type === UPDATE_ZOOM) {
        state = action.zoom;
    }
    return state;
}

const markup = combineReducers({
    tool_mode,
    hasUnsavedChange,
    zoom,
});

function exports(state = false, action) {
    if (action.type === RECEIVE_USER_EXPORTS) {
        state = action.exports;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }
    return state;
}

function dashboards(state = null, action) {
    if (action.type === DASHBOARD_CHOICE) {
        state = action.dashboards;
    }

    if (action.type === DISMISS_MESSAGE) {
        state = state.map((dashboard) => {
            if (dashboard && dashboard.message && dashboard.message.id === action.message_id) {
                return {
                    ...dashboard,
                    message: null,
                };
            } else {
                return dashboard;
            }
        });
    }

    return state;
}

function routeCoordinateSystems(state = [], action) {
    if (action.type === ROUTE_COORDINATE_SYSTEMS) {
        state = action.systems;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function exportDetails(state = null, action) {
    if (action.type === RECEIVE_EXPORT) {
        state = action.export_details;
    }
    if (action.type === RECEIVE_EXPORT_UNAUTHORISED) {
        state = null;
    }
    return state;
}

function exportLoading(state = false, action) {
    if (action.type === RECEIVE_EXPORT || action.type === RECEIVE_EXPORT_UNAUTHORISED) {
        state = false;
    }
    if (action.type === FETCHING_EXPORT) {
        state = true;
    }
    return state;
}

function exportRequiredAccessId(state = null, action) {
    if (action.type === RECEIVE_EXPORT_UNAUTHORISED) {
        state = action.required_access_id;
    }
    return state;
}

const viewExport = combineReducers({
    details: exportDetails,
    loading: exportLoading,
    required_access_id: exportRequiredAccessId,
});

function gpsTimeOffsets(state = null, action) {
    if (state === null) {
        state = {
            sessionID: null,
            offsets: [],
            originalOffsets: [],
        };
    }
    if (action.type === GPS_TIME_OFFSETS) {
        state = {
            sessionID: action.sessionID,
            offsets: action.offsets,
            originalOffsets: action.from_server ? action.offsets : state.originalOffsets,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {
            sessionID: null,
            offsets: [],
            originalOffsets: [],
        };
    }
    return state;
}

function views(state = null, action) {
    if (state === null) {
        state = [
            {
                id: 0,
                name: "Default View",
                datum_offsets: [],
            },
        ];
    }
    if (action.type === VIEW_DATA) {
        state = action.views;
    }
    return state;
}

function logout(state = false, action) {
    if (action.type === LOGOUT) {
        state = action.logoutType || true;
    } else if (action.type === RESET_LOGOUT || action.type === ACCESS_TOKEN || action.type === RECEIVE_ACCCESS_TOKEN) {
        state = false;
    }
    return state;
}

function viewSnapshot(state = false, action) {
    if (action.type === TOGGLE_SNAPSHOT) {
        state = !state;
    }

    return state;
}

function featureOverlayFeatures(state = [], action) {
    if (action.type === FEATURE_OVERLAY_ADD_FEATURE) {
        let newState = _.clone(state);
        if (action.feature.routeInformationDatum) {
            // remove any existing feature that was created from the 'set here' button.
            // the desired behaviour being for these to be more dynamic and ephemeral rather than
            // creating a chain of features left behind. NOTE that in future work we could alter this
            // behaviour so that you can drop multiple flags from even outside 3D features tool
            newState = newState.filter((feature) => !feature.routeInformationDatum);
        }
        newState.push(action.feature);
        state = newState;
    } else if (action.type === FEATURE_OVERLAY_UPDATE_FEATURE) {
        state = _.map(state, (feature, idx) => (idx === action.index ? action.feature : feature));
    } else if (action.type === FEATURE_OVERLAY_REMOVE_FEATURE) {
        state = _.filter(state, (feature, idx) => idx !== action.index);
    } else if (action.type === FEATURE_OVERLAY_CLEAR_FEATURES) {
        state = [];
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    } else if (action.type === RECEIVE_REMOTE_FEATURES) {
        state = action.features;
    }
    return state;
}

function featureOverlayDatum(state = null, action) {
    if (action.type === FEATURE_OVERLAY_SET_DATUM) {
        state = action.datum;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function featureOverlayRouteDistanceMap(state = null, action) {
    if (action.type === FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES) {
        if (action.routeDatum) {
            state = {
                datum: action.routeDatum,
                distanceMap: action.distanceMap,
            };
        } else {
            state = null;
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function fullscreen(state = false, action) {
    if (action.type === TOGGLE_FULLSCREEN) {
        state = !state;
    }

    if (action.type === CLOSE_FULLSCREEN) {
        state = false;
    }

    if (action.type === SET_FULLSCREEN_VALUE) {
        state = action.value;
    }

    return state;
}

function windowDimensions(state = { width: 0, height: 0 }, action) {
    if (action.type === UPDATE_DIMENSIONS) {
        state = action.dimensions;
    }

    return state;
}

function assetSearchType(state = {}, action) {
    if (action.type === SET_ASSET_SEARCH_TYPE) {
        state = action.searchType;
    }

    return state;
}

function mobileMapZoom(state = {}, action) {
    if (action.type === SET_MAP_ZOOM_LEVEL) {
        state = action.zoom;
    }

    return state;
}

function markerReviewFilters(state = [0, 1], action) {
    if (action.type === ADD_MARKER_REVIEW_FILTER) {
        state = [...state, action.reviewType];
    } else if (action.type === REMOVE_MARKER_REVIEW_FILTER) {
        state = _.filter(state, (idx) => idx !== action.reviewType);
    }

    return state;
}

function markerThresholdFilters(state = {}, action) {
    if (action.type === ADD_MARKER_THRESHOLD_FILTER) {
        // state = [...state, action.reviewType];
        state = _.cloneDeep(state);
        state[action.key] = action.value;
    }

    return state;
}

function markerConditionFilter(state = {}, action) {
    if (action.type === ADD_MARKER_CONDITION_FILTER) {
        state = _.cloneDeep(state);
        let newArray = state[action.conditionType] || [];
        newArray.push(action.conditionID);
        state[action.conditionType] = newArray;
    } else if (action.type === REMOVE_MARKER_CONDITION_FILTER) {
        state = _.cloneDeep(state);
        state[action.conditionType] = _.filter(state[action.conditionType], (idx) => idx !== action.conditionID);
    } else if (action.type === SET_MARKER_CONDITION_FILTER) {
        state = action.conditions;
    }

    return state;
}

function observationFilters(state = { troughingType: [], condition: [], lowBallastConfidenceFilter: [0, 1] }, action) {
    if (action.type === SET_TROUGHING_FILTER) {
        state = _.cloneDeep(state);
        state.troughingType = action.troughingType;
        state.condition = action.condition;
    }

    if (action.type === SET_LOW_BALLAST_CONFIDENCE_FILTER) {
        state = _.cloneDeep(state);
        state.lowBallastConfidenceFilter = action.lowBallastConfidenceFilter;
    }

    return state;
}

function customAnnotationLabels(state = [], action) {
    if (action.type === ADD_CUSTOM_ANNOTATION_LABEL) {
        state = [action.label, ...state];
    } else if (action.type === REMOVE_CUSTOM_ANNOTATION_LABEL) {
        state = _.filter(state, (idx) => idx !== action.label);
    }

    return state;
}

function railInspectionImages(state = [], action) {
    if (action.type === UPDATE_PRIMARY_RAIL_IMAGES) {
        if (!state.length) {
            return action.railImages;
        }

        if (action.loaded) {
            state = action.railImages;
        }
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        return [];
    }

    return state;
}

function railInspectionImageLoadingData(state = { loaded: false, percentageComplete: 0, sessionID: null }, action) {
    if (action.type === UPDATE_PRIMARY_RAIL_IMAGES) {
        state = _.cloneDeep(state);
        state.loaded = action.loaded;
        state.percentageComplete = action.percentageComplete;
        state.sessionID = action.sessionID;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = { loaded: false, percentageComplete: 0, sessionID: null };
    }

    return state;
}

function railInspectionPulseData(state = {}, action) {
    if (action.type === RECEIVE_INSPECTION_PULSE_COUNTS) {
        state = action.pulseCounts;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        return {};
    }

    return state;
}

function timelineWidget(state = { open: false }, action) {
    if (action.type === TOGGLE_RAIL_INSPECTION_WIDGET_TIMELINE) {
        state = _.clone(state);
        state.open = !state.open;
    }
    return state;
}

const railInspectionImageData = combineReducers({
    data: railInspectionImages,
    loadingInfo: railInspectionImageLoadingData,
});

const initialSnapshotState = {
    open: false,
};
function inspectionSnapshot(state = initialSnapshotState, action) {
    if (action.type === TOGGLE_INSPECTION_SNAPSHOT) {
        state = _.clone(state);
        state.open = action.open;
    }

    return state;
}

const initialConfigState = { detail_cameras: [], inspection_images: [], video_offsets: {} };

function railInspectionImageConfig(state = initialConfigState, action) {
    if (action.type === PRIMARY_RAIL_IMAGE_CONFIG) {
        state = action.config;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = initialConfigState;
    } else if (action.type === RECEIVE_PATROL_DISPLAY_CONFIG) {
        state = _.isEmpty(action.config) ? initialConfigState : action.config;
    }

    return state;
}

function selectedRailInspectionImage(state = { index: 0 }, action) {
    if (action.type === SELECTED_RAIL_INSPECTION_IMAGE) {
        state = _.clone(state);
        state = {
            index: action.index,
            ...action.object,
            requestTimestamp: _.get(state, ["requestTimestamp"], 0),
            latPos: action.latPos,
            leftRightConfig: action.leftRightConfig,
        };
        if (action.updateTimestamp) {
            state.requestTimestamp = new Date().getTime();
        }
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = { index: 0 };
    }

    return state;
}

function extraRailInspectionSource(state = null, action) {
    if (action.type === EXTRA_RAIL_INSPECTION_SOURCE) {
        state = action.sourceIndex;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = null;
    }

    return state;
}

function someMainRailInspectionImagesMissing(state = false, action) {
    if (action.type === SET_SOME_MAIN_RAIL_INSPECTION_IMAGES_MISSING) {
        state = action.value;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = false;
    }

    return state;
}

function railInspectionSourcesOpen(state = [], action) {
    const oldState = _.clone(state);
    if (action.type === RAIL_INSPECTION_SOURCES_OPEN) {
        state = _.map(action.sourceIndexes, (idx) => ({ id: idx }));
    } else if (action.type === RAIL_INSPECTION_SOURCE_CLOSED) {
        state = oldState.filter((i) => i.id !== action.sourceIndex);
    }

    return state;
}

function featureOverlayCorrections(state = [], action) {
    if (action.type === ADD_FEATURE_OVERLAY_CORRECTION) {
        state = [...state, action.data];
    } else if (action.type === REMOVE_FEATURE_OVERLAY_CORRECTION) {
        state = _.filter(state, (idx) => idx !== action.index);
    }

    return state;
}

function shortcutDevices(state = [], action) {
    if (action.type === RECEIVE_SHORTCUT_DEVICES) {
        state = action.devices;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function shortcutItems(state = [], action) {
    if (action.type === RECEIVE_SHORTCUTS) {
        if (state.length > 0 && state.length === action.shortcuts.length) {
            const stateShortcutsMap = {};
            state.forEach((shortcut, index) => {
                stateShortcutsMap[shortcut.id] = index;
            });

            let hasUpdated = false;
            state.forEach((shortcut) => {
                const updatedShortcut = action.shortcuts[stateShortcutsMap[shortcut.id]];
                if (updatedShortcut.processing !== shortcut.processing) {
                    hasUpdated = true;
                }
            });

            if (hasUpdated) {
                state = action.shortcuts;
            }
        } else {
            state = action.shortcuts;
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function shortcutSessions(state = {}, action) {
    if (action.type === RECEIVE_SHORTCUT_SESSIONS) {
        const newShortcutSessions = _.clone(state);
        newShortcutSessions[action.shortcutID] = action.sessions;
        state = newShortcutSessions;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    } else if (action.type === RESET_SHORTCUT_SESSION) {
        const newShortcutSessions = _.clone(state);
        delete newShortcutSessions[action.shortcutID];
        state = newShortcutSessions;
    }
    return state;
}

function selectedShortcutID(state = 0, action) {
    if (action.type === SHORTCUT_SELECTED) {
        state = action.shortcutID;
    } else if (action.type === EXIT_CURRENT_DASHBOARD || action.type === RESET_SHORTCUT_SELECTED) {
        state = null;
    }

    return state;
}

function shortcutSessionPoints(state = {}, action) {
    if (action.type === RECEIVE_SHORTCUT_POINTS) {
        const newShortcutPoints = _.clone(state);
        newShortcutPoints[action.sessionID] = action.points;
        state = newShortcutPoints;
    } else if (action.type === EXIT_CURRENT_DASHBOARD || action.type === RESET_SHORTCUT_SELECTED) {
        state = {};
    }

    return state;
}

function workspaceUsers(state = [], action) {
    if (action.type === WORKSPACE_USERS) {
        state = action.users;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
}

const shortcuts = combineReducers({
    devices: shortcutDevices,
    shortcuts: shortcutItems,
    sessions: shortcutSessions,
    selectedShortcut: selectedShortcutID,
    shortcutSessionPoints: shortcutSessionPoints,
});

const team = combineReducers({
    users: workspaceUsers,
});

function globalSessionContent(state = [], action) {
    if (action.type === RECEIVE_GLOBAL_CONTENT) {
        if (action.clear) {
            state = action.content;
        } else {
            state = _.clone(state);
            state = state.concat(action.content);
        }
    } else if (action.type === EXIT_CURRENT_DASHBOARD || action.type === CLEAR_GLOBAL_CONTENT) {
        state = [];
    }

    return state;
}

function inspectionBookmarks(state = [], action) {
    if (action.type === RECEIVE_BOOKMARKS) {
        state = action.bookmarks;
    } else if (action.type === EXIT_CURRENT_DASHBOARD || action.type === RAIL_INSPECTION_EXITED) {
        state = [];
    } else if (action.type === ROUTE_PATROL_EXITED) {
        state = [];
    }
    return state;
}

function sperryFaults(state = { faults: [] }, action) {
    if (action.type === RECEIVE_INSPECTION_FAULTS) {
        state = _.cloneDeep(state);
        state.faults = action.faults;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = _.cloneDeep(state);
        state.faults = [];
    }
    return state;
}

function sperryFaultMarkers(state = { sperryMarkers: [] }, action) {
    if (action.type === SET_SPERRY_MARKERS) {
        state = _.cloneDeep(state);
        state.sperryMarkers = action.sperryMarkers;
    }

    return state;
}

function inspectionConditionTypes(state = {}, action) {
    if (action.type === RECEIVE_INSPECTION_CONDITION_TYPES) {
        state = action.types;
    }
    return state;
}

function dipAngleFilter(state = [0, 30], action) {
    if (action.type === SET_DIP_ANGLE_FILTER) {
        state = action.range;
    }
    return state;
}

function allInspectionBookmarksFetched(state = false, action) {
    if (action.type === INSPECTION_BOOKMARKS_FETCHED) {
        state = action.fetched;
    }

    return state;
}

function selectedBookmark(state = null, action) {
    if (action.type === SELECT_BOOKMARK) {
        state = action.bookmarkID;
    }
    return state;
}

function requestedDesktop(state = false, action) {
    if (action.type === TOGGLE_DESKTOP) {
        state = action.value;
    }
    return state;
}

function verticalNavWindowOpen(state = false, action) {
    if (action.type === TOGGLE_VERTICAL_NAV_WINDOW) {
        state = !state;
    }
    return state;
}

function detailViewOpen(state = false, action) {
    if (action.type === TOGGLE_DETAIL_VIEW) {
        state = !state;
    }
    return state;
}

function settingsOpen(state = true, action) {
    if (action.type === TOGGLE_SETTINGS_OPEN) {
        state = !state;
    }
    return state;
}

function autoScrollActive(state = false, action) {
    if (action.type === SET_AUTO_SCROLL) {
        state = action.value;
    } else if (action.type === PLAYLIST) {
        state = false;
    }
    return state;
}

function detectionsExportOpen(state = false, action) {
    if (action.type === TOGGLE_DETECTIONS_EXPORT_OPEN) {
        state = !state;
    }
    return state;
}

const defaultDetectionsExportIndexRange = {
    start: null,
    end: null,
};

function detectionsExportIndexRange(state = defaultDetectionsExportIndexRange, action) {
    if (action.type === SET_INSPECTION_DETECTIONS_EXPORT_RANGE) {
        state = _.clone(state);
        state[action.key] = action.value;
    }
    if (action.type === RESET_INSPECTION_DETECTIONS_EXPORT_RANGE) {
        state = defaultDetectionsExportIndexRange;
    }

    return state;
}

function imageAdjustments(state = {}, action) {
    if (action.type === SET_NEW_IMAGE_ADJUSTMENTS) {
        state = action.adjustments;
    } else if (action.type === RESET_IMAGE_ADJUSTMENTS || action.type === RAIL_INSPECTION_EXITED) {
        state = {};
    }
    return state;
}

function detailImageAdjustments(state = {}, action) {
    if (action.type === SET_NEW_DETAIL_IMAGE_ADJUSTMENTS) {
        state = action.adjustments;
    } else if (action.type === RESET_IMAGE_ADJUSTMENTS || action.type === RAIL_INSPECTION_EXITED) {
        state = {};
    }
    return state;
}

function railDetailWidgetSelectedSources(state = [], action) {
    if (action.type === SET_RAIL_DETAIL_WIDGET_SELECTED_SOURCES) {
        state = action.sources;
    }

    return state;
}

function trackGeometryHeaders(state = [], action) {
    if (action.type === RECEIVE_TRACK_GEOM_HEADERS) {
        state = action.headers;
    } else if (action.type === CLEAR_TRACK_GEOM) {
        state = [];
    }
    return state;
}

function trackGeometryDataFiles(state = [], action) {
    if (action.type === RECEIVE_TRACK_GEOM_HEADERS) {
        state = action.data_files;
    } else if (action.type === CLEAR_TRACK_GEOM) {
        state = [];
    }
    return state;
}

function trackGeometryData(state = [], action) {
    if (action.type === RECEIVE_TRACK_GEOM_DATA) {
        state = action.data;
    } else if (action.type === CLEAR_TRACK_GEOM) {
        state = [];
    }
    return state;
}

function trackGeometryHeaderIDs(state = [], action) {
    if (action.type === RECEIVE_TRACK_GEOM_HEADERS) {
        state = action.header_ids;
    } else if (action.type === CLEAR_TRACK_GEOM) {
        state = [];
    }
    return state;
}

function trackGeometryDeviceDescription(state = [], action) {
    if (action.type === RECEIVE_TRACK_GEOM_HEADERS) {
        state = action.description;
    } else if (action.type === CLEAR_TRACK_GEOM) {
        state = [];
    }
    return state;
}

function schemaRailImages(state = [], action) {
    if (action.type === RECEIVE_PATROL_IMAGES) {
        // according to this, railimages is just a big flat list of every image collected along the current ELR
        // (all tracks) in the given mileage range. one image is 'picked' per timestamp (which assumes that we
        // don't lose any useful data by throwing away additional images - which may not be true)

        const allImages = [...state, ...action.images];
        state = allImages;
    }

    if (action.type === CLEAR_PATROL_STATE || action.type === CLEAR_PATROL_IMAGES) {
        state = [];
    }

    return state;
}

const patrolSession = (state = 0, action) => {
    if (action.type === SET_SELECTED_PATROL_SESSION) {
        state = action.session;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = 0;
    }
    return state;
};

const schemaSessions = (state = [], action) => {
    // this is just deriving something from the existing
    // rail image state. it should probably be implemented as a memoized selector
    // that components use against the image state.

    if (action.type === CLEAR_PATROL_STATE) {
        state = [];
    }

    if (action.type === CALCULATE_PATROL_SESSIONS) {
        let all_images = action.images;

        let sorted_images = _.orderBy(all_images, "timestamp", "asc");
        let sessions = [];
        let lastTimestamp = 0;
        let currentSession = [];

        sorted_images.forEach((image) => {
            if (!lastTimestamp || image.timestamp - lastTimestamp < 5000) {
                currentSession.push(image);
            } else {
                sessions.push(currentSession);
                currentSession = [image];
            }

            lastTimestamp = image.timestamp;
        });

        if (currentSession.length) {
            sessions.push(currentSession);
        }

        return _.orderBy(
            sessions.filter((session) => session.length > 10),
            [
                (session) => {
                    return session[0].timestamp;
                },
            ],
            ["desc"],
        );
    }

    return state;
};

const INITIAL_PATROL_LOCATION = {
    elr: null,
    chain: null,
    trackID: null,
};

function schemaLocation(state = INITIAL_PATROL_LOCATION, action) {
    if (action.type === SET_PATROL_LOCATION) {
        state = action.location;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = INITIAL_PATROL_LOCATION;
    }

    return state;
}

function schemaImagesLoaded(state = false, action) {
    if (action.type === PATROL_IMAGES_LOADED) {
        state = action.loaded;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = false;
    }
    return state;
}

function schemaCovered(state = {}, action) {
    if (action.type === PATROL_COVERED) {
        state = action.coverage;
    }

    if (action.type === CLEAR_PATROL_STATE || action.type === RESET_PATROL_COVERAGE) {
        state = {};
    }

    return state;
}

function patrolStatus(state = null, action) {
    if (action.type === PATROL_STATUS) {
        state = action.status;
    }

    if (action.type === CLEAR_PATROL_STATE || action.type === RESET_PATROL_COVERAGE) {
        state = null;
    }

    return state;
}

const DEFAULT_SCHEMA_PLAN = {
    svg: {
        line: [],
        text: [],
    },
    name: "",
    start_timestamp: 0,
    end_timestamp: 0,
    device_group_id: null,
};

function schemaPlan(state = DEFAULT_SCHEMA_PLAN, action) {
    if (action.type === RECEIVE_PATROL_PLAN) {
        state = {
            ...action.plan,
            svg: JSON.parse(action.plan.svg),
            diagram_link: action.plan.patrol_diagram_link,
        };
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = DEFAULT_SCHEMA_PLAN;
    }

    return state;
}

function schemaConfig(state = {}, action) {
    if (action.type === RECEIVE_PATROL_DISPLAY_CONFIGS) {
        state = action.configs;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = {};
    }

    return state;
}

function routePatrols(state = [], action) {
    if (action.type === RECEIVE_ROUTE_PATROLS) {
        if (action.page === 0) {
            state = action.patrols;
        } else {
            let newState = _.clone(state);
            state = newState.concat(action.patrols);
        }
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = [];
    }

    return state;
}

function reviewerRoutePatrols(state = [], action) {
    if (action.type === RECEIVE_REVIEWER_ROUTE_PATROLS) {
        state = action.patrols;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = [];
    }

    return state;
}

function reviewerRoutePatrolsLoaded(state = false, action) {
    if (action.type === RECEIVE_REVIEWER_ROUTE_PATROLS) {
        state = true;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = false;
    }

    return state;
}

function routePatrolsLoaded(state = false, action) {
    if (action.type === ROUTE_PATROLS_FETCHED) {
        state = true;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = false;
    }

    return state;
}

function deviceGroupInfo(state = [], action) {
    if (action.type === DEVICE_GROUP_INFO) {
        state = action.data;
    }

    return state;
}

function inspectingPatrol(state = false, action) {
    if (action.type === INSPECTING_PATROL) {
        state = action.inspecting;
    }

    return state;
}

function hoveredSessionLines(state = [], action) {
    if (action.type === HOVERED_SESSION_LINES) {
        state = action.lines;
    }

    return state;
}

const PAGES_INITIAL_STATE = {
    page: 0,
    itemOffset: 0,
};

function schemaNavigationPages(state = PAGES_INITIAL_STATE, action) {
    if (action.type === SCHEMA_NAVIGATION_PAGE) {
        let newState = _.clone(state);
        newState.page = action.page;
        newState.itemOffset = action.itemOffset;
        state = newState;
    }

    return state;
}

function patrolDirections(state = {}, action) {
    if (action.type === RECEIVE_PATROL_DIRECTIONS) {
        state = action.directions;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = {};
    }

    return state;
}

function fetchingELRs(state = false, action) {
    if (action.type === TOGGLE_FETCHING_ELRS) {
        state = action.fetching;
    }

    return state;
}

function patrolReportForm(state = {}, action) {
    if (action.type === SET_PATROL_REPORT_FORM) {
        state = action.form;
    } else if (action.type === ROUTE_PATROL_EXITED) {
        state = {};
    }

    return state;
}

function patrolReportOpen(state = false, action) {
    if (action.type === SET_PATROL_REPORT_OPEN) {
        state = action.open;
    }

    return state;
}

function patrolUsers(state = [], action) {
    if (action.type === RECEIVE_PATROL_USERS) {
        state = action.users;
    }

    if (action.type === CLEAR_PATROL_STATE) {
        state = [];
    }

    return state;
}

const patrolReport = combineReducers({
    patrolReportOpen,
    form: patrolReportForm,
});

function patrolHistoryImages(state = [], action) {
    if (action.type === RECEIVE_PATROL_HISTORY) {
        state = action.history;
    }

    return state;
}

function patrolHistoryPosition(state = {}, action) {
    if (action.type === RECEIVE_PATROL_LOCATION) {
        state = action.position;
    }

    return state;
}

function lastViewingTimestamp(state = 0, action) {
    if (action.type === SET_PATROL_HISTORY_TIMESTAMP) {
        state = action.timestamp;
    }

    return state;
}

function fetchingPatrolHistory(state = false, action) {
    if (action.type === TOGGLE_FETCHING_PATROL_HISTORY) {
        state = action.fetching;
    }

    return state;
}

function patrolPlans(state = [], action) {
    if (action.type === RECEIVE_PATROL_PLANS) {
        state = action.plans;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
}

function patrolPlanLoading(state = 0, action) {
    if (action.type === PATROL_PLAN_IMAGES) {
        state = action.value;
    }
    return state;
}

function patrolBugReportOpen(state = false, action) {
    if (action.type === TOGGLE_PATROL_BUG_REPORT) {
        state = action.open;
    }

    return state;
}

const INITIAL_PATROL_PAGING_OBJ = {
    page: 0,
    totalPages: 0,
};

function patrolImagePaging(state = INITIAL_PATROL_PAGING_OBJ, action) {
    if (action.type === SET_TOTAL_PATROL_PAGES) {
        state = _.cloneDeep(state);
        state.totalPages = action.pages;
    } else if (action.type === SET_PATROL_PAGE) {
        state = _.cloneDeep(state);
        state.page = action.page;
    } else if (action.type === CLEAR_PATROL_STATE) {
        state = INITIAL_PATROL_PAGING_OBJ;
    }

    return state;
}

function patrolDiagramReportOpen(state = false, action) {
    if (action.type === TOGGLE_PATROL_DIAGRAM_REPORT) {
        state = action.open;
    }
    return state;
}

function currentSessionId(state = 0, action) {
    if (action.type === SET_SELECTED_PATROL_SESSION_ID) {
        state = action.id;
    }
    return state;
}

function patrolRuns(state = [], action) {
    if (action.type === RECEIVE_PATROL_RUNS) {
        state = action.runs;
    }
    return state;
}

function patrolDetailRailsOpen(state = false, action) {
    if (action.type === TOGGLE_PATROL_DETAIL_MODAL) {
        state = action.open;
    }
    return state;
}

const patrolHistory = combineReducers({
    images: patrolHistoryImages,
    position: patrolHistoryPosition,
    lastViewingTimestamp: lastViewingTimestamp,
    fetching: fetchingPatrolHistory,
});

const schemaInterface = combineReducers({
    imagePaging: patrolImagePaging,
    images: schemaRailImages,
    sessions: schemaSessions,
    selectedSession: patrolSession,
    location: schemaLocation,
    imagesLoaded: schemaImagesLoaded,
    covered: schemaCovered,
    status: patrolStatus,
    plan: schemaPlan,
    config: schemaConfig,
    patrols: routePatrols,
    patrolsLoaded: routePatrolsLoaded,
    reviewerPatrols: reviewerRoutePatrols,
    reviewerPatrolsLoaded: reviewerRoutePatrolsLoaded,
    patrolUsers,
    inspectingPatrol,
    pages: schemaNavigationPages,
    hoveredSessionLines,
    patrolDirections,
    fetchingELRs,
    patrolReport,
    history: patrolHistory,
    selectedMarkup: selectedSchemaMarkup,
    patrolPlans,
    bugReportOpen: patrolBugReportOpen,
    diagramReportOpen: patrolDiagramReportOpen,
    currentSessionId,
    patrolRuns,
    detailModalOpen: patrolDetailRailsOpen,
    patrolImagesLoaded: patrolPlanLoading,
});

function flipRails(state = false, action) {
    if (action.type === FLIP_INSPECTION_RAILS) {
        state = action.flipped;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = false;
    }

    return state;
}

function gridVisible(state = false, action) {
    if (action.type === TOGGLE_GRID_VISIBLE) {
        state = !state;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = false;
    }

    return state;
}

function gridSize(state = 1, action) {
    if (action.type === CHANGE_GRID_SIZE) {
        state = action.size;
    }

    return state;
}

function magnifyToggled(state = false, action) {
    if (action.type === IS_MAGNIFY_TOGGLED) {
        state = action.value;
    }

    return state;
}

const initialOffsetState = { x: 0, y: 0 };
function gridOffset(state = initialOffsetState, action) {
    if (action.type === CHANGE_GRID_OFFSET) {
        const newState = _.clone(state);
        newState[action.direction] = action.value;
        state = newState;
    } else if (action.type === RESET_GRID_ADJUSTMENTS) {
        state = initialOffsetState;
    }

    return state;
}

function exceedenceData(state = [], action) {
    if (action.type === EXCEEDENCE_DATA) {
        state = action.data;
    }

    return state;
}

function globalContentPageCount(state = { count: 0, isMore: false }, action) {
    if (action.type === SET_CONTENT_PAGE_COUNT) {
        state = _.clone(state);
        state.count = action.count;
        state.isMore = action.isMore;
    }

    return state;
}

const INITIAL_ANNOTATIONS_OBJ = {
    data: [],
    modeEnabled: false,
    types: [],
    allAnnotationsFetched: false,
    visibility: true,
};

function railInspectionAnnotations(state = INITIAL_ANNOTATIONS_OBJ, action) {
    if (action.type === RECEIVE_INSPECTION_ANNOTATIONS) {
        state = _.clone(state);
        state.data = action.annotations;
    } else if (action.type === TOGGLE_INSPECTION_ANNOTATION_MODE) {
        state = _.clone(state);
        state.modeEnabled = action.value;
    } else if (action.type === RECEIVE_INSPECTION_ANNOTATION_TYPES) {
        state = _.clone(state);
        state.types = action.types;
    } else if (action.type === INSPECTION_ANNOTATIONS_FETCHED) {
        state = _.clone(state);
        state.allAnnotationsFetched = action.fetched;
    } else if (action.type === SELECT_ANNOTATION) {
        state = _.clone(state);
        state.selectedAnnotation = action.annotationID;
    } else if (action.type === TOGGLE_ANNOTATION_VISIBILITY) {
        state = _.clone(state);
        state.visibility = action.visible;
    }

    return state;
}

const INITIAL_DETECTIONS_OBJ = {
    data: [],
    windowOpen: false,
    selectedDetection: null,
    reviewFilters: [0, 1],
    typeFilters: [],
    types: [],
    allDetectionsFetched: false,
    visibility: true,
    fetchingSession: null,
    detectionConditionFilters: {},
    followDetections: true,
};

const INITIAL_CLASSIFICATION_OBJ = {
    data: [],
    selectedClassification: null,
    reviewFilters: [0, 1],
    filters: [],
    score: 0,
    allClassificationsFetched: false,
    visibility: true,
    fetchingSession: null,
    classificationsTabOpen: false,
};

function sortDetections(detections) {
    return _.orderBy(detections, [(det) => det.image_timestamp, (det) => det.bbox[1]], ["asc", "desc"]);
}

function displayExtraLayers(state = false, action) {
    if (action.type === DISPLAY_EXTRA_INSPECTION_LAYERS) {
        state = action.display;
    }
    return state;
}

function railInspectionDetections(state = INITIAL_DETECTIONS_OBJ, action) {
    if (action.type === INSPECTION_DETECTIONS) {
        state = _.clone(state);

        if (action.replace) {
            const mergedDetections = mergeInspectionDetections(action.detections);
            state.data = mergedDetections;
            if (action.detections.length) {
                state.selectedDetection = action.detections[0].id;
            }
        } else {
            const allDetections = [...state.data, ...action.detections];
            const mergedDetections = mergeInspectionDetections(allDetections);
            state.data = mergedDetections;
        }
    } else if (action.type === TOGGLE_DETECTIONS_OPEN) {
        state = _.clone(state);
        state.windowOpen = !state.windowOpen;
    } else if (action.type === RAIL_INSPECTION_EXITED || action.type === RESET_INSPECTION_DETECTIONS) {
        state = INITIAL_DETECTIONS_OBJ;
    } else if (action.type === SET_CURRENT_DETECTION) {
        state = _.clone(state);
        state.selectedDetection = action.detectionID;
    } else if (action.type === ADD_DETECTIONS_REVIEW_FILTER) {
        state = _.clone(state);
        state.reviewFilters = [...state.reviewFilters, action.reviewType];
    } else if (action.type === REMOVE_DETECTIONS_REVIEW_FILTER) {
        state = _.clone(state);
        state.reviewFilters = _.filter(state.reviewFilters, (idx) => idx !== action.reviewType);
    } else if (action.type === ADD_DETECTIONS_TYPE_FILTER) {
        state = _.clone(state);
        if (action.replaceExisting) {
            state.typeFilters = action.detectionType;
        } else {
            state.typeFilters = _.uniq([...action.detectionType, ...state.typeFilters]);
        }
    } else if (action.type === REMOVE_DETECTIONS_TYPE_FILTER) {
        state = _.clone(state);
        state.typeFilters = _.filter(state.typeFilters, (name) => name !== action.detectionType);
    } else if (action.type === INSPECTION_DETECTIONS_FETCHED) {
        state = _.clone(state);
        state.allDetectionsFetched = action.fetched;
        state.fetchingSession = null;
        if (action.fetched) {
            const allTypes = state.data.map((detection) => detection.name);
            const types = [...new Set(allTypes)];
            state.types = types;

            const sortedDetections = sortDetections(state.data);
            state.data = sortedDetections;
        }
    } else if (action.type === TOGGLE_DETECTION_VISIBILITY) {
        state = _.clone(state);
        state.visibility = !state.visibility;
    } else if (action.type === TOGGLE_FETCHING_DETECTIONS) {
        state = _.clone(state);
        state.fetchingSession = action.sessionID;
    } else if (action.type === UPDATE_RAIL_INSPECTION_DETECTION_CONDITION) {
        state = _.cloneDeep(state);
        state.data = action.newData;
    }
    if (action.type === SET_DETECTION_CONDITIONS_FILTERS) {
        state.detectionConditionFilters = action.types;
    }
    if (action.type === SET_FOLLOW_DETECTIONS) {
        state = _.cloneDeep(state);
        state.followDetections = action.bool;
    }

    return state;
}

function railInspectionClassifications(state = INITIAL_CLASSIFICATION_OBJ, action) {
    if (action.type === INSPECTION_CLASSIFICATIONS) {
        state = _.clone(state);

        if (action.replace) {
            state.data = action.classifications;
            if (action.classifications.length) {
                state.selectedClassification = action.classifications[0].id;
            }
        } else {
            state.data = [...state.data, ...action.classifications];
        }
    } else if (action.type === INSPECTION_CLASSIFICATIONS_FETCHED) {
        state = _.clone(state);
        state.allClassificationsFetched = action.fetched;
    } else if (action.type === RAIL_INSPECTION_EXITED || action.type === RESET_INSPECTION_CLASSIFICATIONS) {
        state = INITIAL_CLASSIFICATION_OBJ;
    } else if (action.type === TOGGLE_FETCHING_CLASSIFICATIONS) {
        state = _.clone(state);
        state.fetchingSession = action.sessionID;
    } else if (action.type === RAIL_INSPECTION_EXITED || action.type === RESET_INSPECTION_CLASSIFICATIONS) {
        state = INITIAL_CLASSIFICATION_OBJ;
    } else if (action.type === SET_SELECTED_CLASSIFICATION) {
        state = _.clone(state);
        state.selectedClassification = action.id;
    } else if (action.type === SET_CLASSIFICATION_SCORE) {
        state = _.clone(state);
        state.score = action.score;
    } else if (action.type === TOGGLE_CLASSIFICATION_VISIBILITY) {
        state = _.clone(state);
        state.visibility = !state.visibility;
    } else if (action.type === CLASSIFICATIONS_TAB_OPEN) {
        state = _.clone(state);
        state.classificationsTabOpen = action.open;
    }

    return state;
}

function selectedInspectionMarkup(state = {}, action) {
    if (action.type === SET_INSPECTION_MARKUP) {
        state = {
            type: action.markupType,
            id: action.id,
        };
    }

    return state;
}

function targetInspectionData(state = null, action) {
    if (action.type === SET_INSPECTION_TARGET_TIMESTAMP) {
        state = {
            timestamp: action.timestamp,
            distance: action.interpolationDistance,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD || action.type === RAIL_INSPECTION_EXITED) {
        state = null;
    }
    return state;
}

function selectedSchemaMarkup(state = {}, action) {
    if (action.type === SET_SCHEMA_MARKUP) {
        state = {
            type: action.markupType,
            id: action.id,
        };
    }

    return state;
}

function fetchedMarkupExportTypes(state = false, action) {
    if (action.type === RECEIVE_MARKUP_EXPORT_TYPES) {
        state = true;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = false;
    }

    return state;
}

function markupExportTypes(state = { annotations: [], measurements: [] }, action) {
    if (action.type === RECEIVE_MARKUP_EXPORT_TYPES) {
        state = action.types;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = { annotations: [], measurements: [] };
    }

    return state;
}

function loginDataFilters(state = {}, action) {
    if (action.type === RECEIVE_DATA_FILTERS) {
        state = action.filters;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }

    return state;
}

function comparableSessions(state = [], action) {
    if (action.type === COMPARABLE_SESSIONS) {
        state = action.sessions;
    }
    return state;
}

function railInspectionConditions(state = [], action) {
    if (action.type === INSPECTION_CONDITIONS_FETCHED) {
        state = action.types;
    }
    return state;
}

function currentTab(state = "sessions", action) {
    if (action.type === SET_CURRENT_TAB) {
        state = action.tabName;
    }
    return state;
}

function currentQaTab(state = 1, action) {
    if (action.type === SET_CURRENT_QA_TAB) {
        state = action.option;
    }
    return state;
}

function directionArrowLeft(state = true, action) {
    if (action.type === SET_DIRECTION_ARROW) {
        state = action.left;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = true;
    }
    return state;
}

function parentConnected(state = false, action) {
    if (action.type === PARENT_CONNECTED) {
        state = action.connected;
    }
    return state;
}

function requestedContent(state = { id: null, data: null }, action) {
    if (action.type === SET_REQUESTED_CONTENT) {
        state = {
            type: action.contentType,
            data: action.contentData,
        };
    } else if (action.type === CLEAR_REQUESTED_CONTENT) {
        state = { id: null, data: null };
    }
    return state;
}

function continuousObservations(state = [], action) {
    if (action.type === SESSION_CONTINUOUS_OBSERVATIONS) {
        state = action.observations;
    }

    return state;
}

function sessionObservations(state = [], action) {
    if (action.type === SESSION_OBSERVATIONS) {
        state = action.observations;
    }

    return state;
}

function objectObservationDataTypes(state = {}, action) {
    if (action.type === OBJECT_OBSERVATION_DATA_TYPE) {
        state = action.types;
    }
    return state;
}

function displayObservationMarkers(state = false, action) {
    if (action.type === MARKER_INFO) {
        state = action.isObservation;
    }
    return state;
}

const LOCATION_SEARCH_STATE = {
    resultType: null,
    results: [],
    displayResultsOnMap: false,
    loading: false,
};

function locationSearch(state = LOCATION_SEARCH_STATE, action) {
    if (action.type === RECEIVE_LOCATION_SEARCH_RESULT) {
        state = _.cloneDeep(state);
        state.results = action.results;
        state.resultType = action.resultType;
        state.loading = false;
    }

    if (action.type === CLEAR_LOCATION_RESULTS) {
        state = LOCATION_SEARCH_STATE;
    }

    if (action.type === LOCATION_SEARCH_LOADING) {
        state = _.cloneDeep(state);
        state.loading = action.loading;
    }

    if (action.type === DISPLAY_LOCATION_RESULTS_ON_MAP) {
        state = _.cloneDeep(state);
        state.displayResultsOnMap = action.display;
    }

    return state;
}

function sessionEnvironmentData(state = {}, action) {
    if (action.type === SESSIONS_ENVIRONMENT_DATA) {
        state = action.data;
    }

    return state;
}

const ENVIRONMENT_DATA_STATE = {
    mapData: {},
    filters: {
        type: null,
        subType: null,
        searchQuery: "",
    },
    types: [],
    subTypes: [],
    selectedSegment: {},
    dataLoaded: false,
    errorLoading: false,
    tabOpen: false,
};

function environmentData(state = ENVIRONMENT_DATA_STATE, action) {
    if (action.type === SET_MAP_ENVIRONMENT_DATA) {
        state = _.clone(state);
        state.mapData = _.merge(state.mapData, action.mapData);

        // if there is no more data to load update dataLoaded
        if (!action.isMore) {
            state.dataLoaded = true;
        }

        // if there was an error loading data update errorLoading
        if (action.errorLoading) {
            state.errorLoading = true;
        }
    }
    // on dashboard exit/change
    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = ENVIRONMENT_DATA_STATE;
    }
    if (action.type === SET_MAP_ENVIRONMENT_DATA_TYPES) {
        state = {
            ...state,
            types: _.union(state.types, action.types),
        };

        if (!state.filters.type) {
            let _filters = _.clone(state.filters);
            _filters.type = _.get(state.types, 0);
            state = {
                ...state,
                filters: _filters,
            };
        }
    }
    if (action.type === SET_MAP_ENVIRONMENT_SUB_DATA_TYPES) {
        state = {
            ...state,
            subTypes: _.union(state.subTypes, action.subTypes),
        };

        if (!state.filters.subType) {
            let _filters = _.clone(state.filters);
            _filters.subType = _.get(state.subTypes, 0);
            state = {
                ...state,
                filters: _filters,
            };
        }
    }
    if (action.type === SET_MAP_ENVIRONMENT_SELECTED_SEGMENT) {
        state = {
            ...state,
            selectedSegment: action.selectedSegment,
        };
    }
    if (action.type === SET_MAP_ENVIRONMENT_FILTERS) {
        const _filters = { ...state.filters, ...action.filters };
        state = {
            ...state,
            filters: _filters,
        };
    }
    if (action.type === SET_SATELLITE_TAB_OPEN) {
        state = {
            ...state,
            tabOpen: action.open,
        };
    }

    return state;
}

function csrfToken(state = "", action) {
    if (action.type === RECEIVE_CSRF_TOKEN) {
        return action.token;
    }

    return state;
}

function observationDetections(state = [], action) {
    if (action.type === OBSERVATION_DETECTIONS) {
        state = action.detections;
    }

    return state;
}

function routeAge(state = {}, action) {
    if (action.type === RECEIVE_ROUTE_AGE) {
        state = action.routeAge;
    }
    return state;
}

function positionLeftBiased(state = true, action) {
    if (action.type === SET_POSITION_LEFT_BIASED) {
        state = action.isLeft;
    }
    return state;
}

function autoDetailCameraSelectionActive(state = false, action) {
    if (action.type === TOGGLE_DETAIL_CAMERA_AUTO_SELECTION) {
        state = action.value;
    } else if (action.type === RAIL_INSPECTION_EXITED) {
        state = false;
    }
    return state;
}

const markupExport = combineReducers({
    fetched: fetchedMarkupExportTypes,
    types: markupExportTypes,
});

const globalContent = combineReducers({
    content: globalSessionContent,
    paging: globalContentPageCount,
});

const featureOverlay = combineReducers({
    features: featureOverlayFeatures,
    corrections: featureOverlayCorrections,
    datum: featureOverlayDatum,
    route: featureOverlayRouteDistanceMap,
});

const railInspection = combineReducers({
    railInspectionImages: railInspectionImageData,
    railInspectionImageConfig,
    selectedRailInspectionImage,
    extraRailInspectionSource,
    someMainRailInspectionImagesMissing,
    railInspectionSourcesOpen,
    bookmarks: inspectionBookmarks,
    allInspectionBookmarksFetched,
    verticalNavWindowOpen,
    selectedBookmark,
    detailViewOpen,
    settingsOpen,
    imageAdjustments,
    detailImageAdjustments,
    railDetailWidgetSelectedSources,
    deviceGroupInfo,
    flipRails,
    gridVisible,
    gridSize,
    magnifyToggled,
    gridOffset,
    annotations: railInspectionAnnotations,
    detections: railInspectionDetections,
    classifications: railInspectionClassifications,
    selectedMarkup: selectedInspectionMarkup,
    snapshot: inspectionSnapshot,
    comparableSessions,
    railInspectionConditions,
    displayExtraLayers,
    directionArrowLeft,
    targetInspectionData,
    railInspectionPulseData,
    timelineWidget,
    detectionsExportOpen,
    detectionsExportIndexRange,
    autoScrollActive,
    sperryFaults,
    sperryFaultMarkers,
    inspectionConditionTypes,
    positionLeftBiased,
    autoDetailCameraSelectionActive,
    dipAngleFilter,
});

const trackGeometry = combineReducers({
    trackGeometryHeaders,
    dataFiles: trackGeometryDataFiles,
    data: trackGeometryData,
    headerIDs: trackGeometryHeaderIDs,
    description: trackGeometryDeviceDescription,
});

const cctv = combineReducers({
    CCTVMarkers,
    CCTVImages,
});

const rootReducer = combineReducers({
    dashboards,
    access_token,
    widget_key,
    permissions,
    sessions,
    updateSessions,
    sessionList,
    mapSegmentList,
    sessionTags,
    sessionFilters,
    routeMetadata,
    highlightedRouteID,
    playlist,
    deviceStatuses,
    anprData,
    selectedTagCategory,
    userAnnotationTypes,
    userAnnotations,
    markers,
    mapGeometry,
    shareLink,
    shareLinkDetails,
    measurement,
    classifications,
    admin,
    segmentSelection,
    userSketches,
    userMeasurements,
    userDetails,
    userOrganisationPrefix,
    targetResource,
    markup,
    exports,
    routeCoordinateSystems,
    viewExport,
    userPreferences,
    savingAnnotations,
    logout,
    gpsTimeOffsets,
    views,
    viewSnapshot,
    featureOverlay,
    assets,
    fullscreen,
    windowDimensions,
    assetSearchType,
    mobileMapZoom,
    markerReviewFilters,
    markerThresholdFilters,
    markerConditionFilter,
    customAnnotationLabels,
    dataPools,
    railInspection,
    shortcuts,
    team,
    globalContent,
    requestedDesktop,
    widgetData,
    dashboardWidgetKey,
    trackGeometry,
    snappedRoute,
    schemaInterface,
    exceedenceData,
    defaultMarkersThresholds,
    markupExport,
    loginDataFilters,
    sessionListFilters,
    issues,
    mediaUploads,
    currentTab,
    currentQaTab,
    searchLocationData,
    mlAssets,
    parentConnected,
    driverTraining,
    requestedContent,
    continuousObservations,
    sessionObservations,
    displayObservationMarkers,
    megasearch: megasearchReducers,
    locationSearch,
    cctv,
    csrfToken,
    observationDetections,
    observationFilters,
    lastFlagReason,
    toggleRejectionModal,
    widgets,
    detectionTypes,
    routeAge,
    sessionEnvironmentData,
    environmentData,
    objectObservationDataTypes,
});

export default rootReducer;
