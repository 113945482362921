import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { accessTokenLogin, wsWidgetPing, receiveAccessToken, routeSelected, goToBounds } from "../../redux/actions/index";
import LoadingOverlay from "../util/LoadingOverlay";
import MapComponent from "../map/MapComponent";
import ChartContainer from "./ChartContainer";
import ChartV2Container from "./ChartV2Container";
import _ from "lodash";
import SidekickPlayer from "./SidekickPlayer";
import LocationWidget from "./LocationWidget";
import ExceedencesPage from "./ExceedencesPage";
import { Button } from "antd";
import SessionTimeline from "components/session/SessionTimeline";
import RailDetailWidget from "./RailDetailWidget";
import { MESSAGE_TYPES } from "redux/windowManager";

const accessTokenSelector = (state) => state.access_token;
const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
const sessionsSelector = (state) => {
    return state.sessions;
};

const titles = {
    map: "Route Map",
    "rail-detail": "Rail Detail Images",
    "track-geom": "Track Geometry",
    "track-geom-v2": "Track Geometry",
    exceedences: "Exceedences",
    location: "Location Information",
    stills: "Stills Player",
    timeline: "Timeline",
};

const Sidekick = ({ match }) => {
    const dispatch = useDispatch();
    const { widgetType } = useParams();
    let widgetPingInterval = useRef;

    const [initialised, setInitialised] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);
    const [userAcknowledgedWarning, setUserAcknowledgedWarning] = useState(false);

    const accessToken = useSelector(accessTokenSelector);
    const dashboardData = useSelector(dashboardDataSelector);
    const sessions = useSelector(sessionsSelector);

    useEffect(() => {
        dispatch({
            type: "CONNECT_TO_PARENT",
        });
    }, [dispatch]);

    const handleUnload = useCallback(() => {
        window.opener.postMessage({ type: MESSAGE_TYPES.CHILD_UNMOUNTED, widgetType: widgetType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!window.opener) {
            window.close();
        }

        window.addEventListener("beforeunload", handleUnload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentSession = useMemo(() => {
        return sessions[dashboardData.routeID];
    }, [sessions, dashboardData.routeID]);

    useEffect(() => {
        let title = "AIVR";

        if (dashboardData.routeID) {
            title += ` #${dashboardData.routeID}`;
        }

        if (titles[widgetType]) {
            title += `: ${titles[widgetType]}`;
        }

        document.title = title;
    }, [dashboardData.routeID, widgetType]);

    useEffect(() => {
        if (match.params.token) {
            dispatch(receiveAccessToken(match.params.token));
        }
    }, [dispatch, match.params.token]);

    const validateAccessToken = useCallback(() => {
        dispatch(
            accessTokenLogin(accessToken, (success) => {
                if (!success) {
                    // if login/validate failed then logout and go to index
                    setInvalidToken(true);
                }
                setInitialised(true);
            }),
        );
    }, [accessToken, dispatch]);

    useEffect(() => {
        if (dashboardData.routeID) {
            dispatch(routeSelected(dashboardData.routeID, 0));
        } else {
            dispatch(routeSelected(null));
        }
    }, [dashboardData.routeID, dispatch]);

    useEffect(() => {
        if (currentSession && dashboardData.routeID) {
            dispatch(goToBounds(dashboardData.routeID));
        }
    }, [currentSession, dashboardData.routeID, dispatch]);

    useEffect(() => {
        if (accessToken) {
            validateAccessToken();
            widgetPingInterval.current = setInterval(() => {
                dispatch(wsWidgetPing(widgetType));
            }, 5000);
        }

        return () => {
            clearInterval(widgetPingInterval.current);
        };
    }, [dispatch, accessToken, validateAccessToken, widgetPingInterval, widgetType]);

    let content = useMemo(
        () => (
            <div className="widget__Info center">
                <p>Select a session in the main window to view data</p>
            </div>
        ),
        [],
    );

    const makeUserAcknowledgedWarning = useMemo(
        () => (
            <div className="widget__Info center">
                <p>Geometry data is under evaluation and should not be used for operational decision making</p>
                <Button
                    className="smallButton"
                    type="primary"
                    onClick={() => setUserAcknowledgedWarning(true)}>
                    I understand
                </Button>
            </div>
        ),
        [],
    );

    if (!initialised) {
        return <LoadingOverlay loading={true} />;
    }

    if (dashboardData.routeID) {
        switch (widgetType) {
            case "map":
                content = <MapComponent stripped="sidekick" />;
                break;
            case "rail-detail":
                content = <RailDetailWidget />;
                break;
            case "track-geom":
                content = !userAcknowledgedWarning ? makeUserAcknowledgedWarning : <ChartContainer />;
                break;
            case "track-geom-v2":
                content = !userAcknowledgedWarning ? makeUserAcknowledgedWarning : <ChartV2Container />;
                break;
            case "exceedences":
                content = <ExceedencesPage />;
                break;
            case "location":
                content = <LocationWidget />;
                break;
            case "stills":
                content = <SidekickPlayer />;
                break;
            case "timeline":
                content = <SessionTimeline />;
                break;
            default:
                break;
        }
    }

    if (invalidToken) {
        content = (
            <div className="widget__Info center">
                <p>This widget is no longer active. Please close this window.</p>
            </div>
        );
    }

    let extraClasses = "";

    if (widgetType) {
        if (widgetType === "map") {
            extraClasses = "no-overflow";
        }
        if (widgetType === "exceedences") {
            extraClasses = "no-body-overflow";
        }
    }

    return (
        <div className={`sidekick ${extraClasses}`}>
            <div className="sidekick__Body">{content}</div>
        </div>
    );
};

export default Sidekick;
