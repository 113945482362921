/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import flag from "../../../../../models/flag.glb";
import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import Meshes from "./Meshes";
import { Color, MeshStandardMaterial } from "three";
function Flag(props) {
    const scene = useGLTF(flag);

    const geometries = useMemo(
        () => [scene.nodes.Flag_pivot.geometry, scene.nodes.Flag_stick_pivot.geometry, scene.nodes.Flag_stick_detail_pivot.geometry],
        [scene],
    );

    const options = useMemo(() => {
        return props.options || {};
    }, [props.options]);

    const flagMaterial = useMemo(() => {
        if (options.color) {
            return new MeshStandardMaterial({ color: new Color(options.color) });
        } else {
            return scene.materials.Flag_cloth;
        }
    }, [options, scene]);

    const materials = useMemo(() => [flagMaterial, scene.materials.Flag_stick, scene.materials.Flag_stick_detail], [scene, flagMaterial]);

    return (
        <group
            {...props}
            dispose={null}>
            <group
                position={[0.3, 0, 0.1]}
                scale={[0.015, 0.015, 0.015]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <Meshes
                    geometries={geometries}
                    materials={materials}
                    selected={props.selected}
                    highlighted={props.highlighted}
                />
            </group>
        </group>
    );
}

export const Options = [
    {
        title: "Colour",
        value: "color",
        selectable: false,
        checkable: false,
        children: [
            {
                title: "Red",
                value: JSON.stringify({ color: "#ff0000" }),
            },
            {
                title: "Green",
                value: JSON.stringify({ color: "#00ff00" }),
            },
            {
                title: "Blue",
                value: JSON.stringify({ color: "#0000ff" }),
            },
            {
                title: "Black",
                value: JSON.stringify({ color: "#000000" }),
            },
            {
                title: "White",
                value: JSON.stringify({ color: "#ffffff" }),
            },
            {
                title: "Yellow",
                value: JSON.stringify({ color: "#ffff00" }),
            },
            {
                title: "Orange",
                value: JSON.stringify({ color: "#ff8000" }),
            },
        ],
    },
];

export default Flag;
