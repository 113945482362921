import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../../style/mediaTab.scss";
import { Select, Tooltip, Divider } from "antd";
import OBCSearchInput from "components/OBC/OBCSearchInput";
import OBCButton from "components/OBC/OBCButton";
import OBCSpinner from "components/util/OBC";
import OBCToggleButton from "components/OBC/OBCToggleButton";
import DayPicker from "react-day-picker";
import { useSelector, useDispatch } from "react-redux";
import { setMediaFilter, getMediaUploads } from "redux/actions";
import moment from "moment";
import _ from "lodash";
import MediaTabListItem from "./MediaTabListItem";
import { filterMedia } from "components/util/PlaylistUtils";
import { faEye, faFileSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarOutlined, SyncOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;

const mediaTabFilterSelector = (state) => state.mediaUploads.mediaTabFilters || [];
const mediaUploadListSelector = (state) => state.mediaUploads.mediaUploadsList || [];
const mediaUploadsListLoadingSelector = (state) => state.mediaUploads.mediaUploadsListLoading;
const isSuperAdminSelector = (state) => state.userDetails.userConfig.super_admin || false;
const isAdminSelector = (state) => state.permissions.admin || false;
const selectedMediaUploadIndexSelector = (state) => state.mediaUploads.selectedMediaUploadIndex;

const MediaTab = () => {
    const dispatch = useDispatch();
    const myRef = useRef(null);

    const [filterTags, setFilterTags] = useState([]);
    const [availableTags, setAvailableTags] = useState({});

    const mediaTabFilter = useSelector(mediaTabFilterSelector);
    const mediaUploadsList = useSelector(mediaUploadListSelector);
    const mediaUploadsListLoading = useSelector(mediaUploadsListLoadingSelector);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [searchString, setSearchString] = useState("");
    const selectedMediaUploadIndex = useSelector(selectedMediaUploadIndexSelector);

    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isAdmin = useSelector(isAdminSelector);

    const checkIfAdmin = isSuperAdmin || isAdmin;

    useEffect(() => {
        dispatch(getMediaUploads());
    }, []);

    useEffect(() => {
        if (selectedMediaUploadIndex >= 0 && myRef.current.children[selectedMediaUploadIndex]) {
            let y =
                myRef.current.children[selectedMediaUploadIndex].offsetTop - (filteredMedia.awaitingReview !== 0 && mediaTabFilter.status !== 0 ? 200 : 140);
            myRef.current.scrollTo({ behavior: "smooth", top: y });
        }
    }, [selectedMediaUploadIndex]);

    useEffect(() => {
        let tags = {};
        _.forEach(mediaUploadsList, (upload) => {
            if (upload.tags) {
                _.forIn(upload.tags, function (value, key) {
                    if (!tags.hasOwnProperty(key)) {
                        tags[key] = [...value];
                    } else {
                        value.forEach((item) => {
                            if (!tags[key].includes(item)) {
                                tags[key].push(item);
                            }
                        });
                    }
                });
            }
        });
        setAvailableTags(tags);
    }, [mediaUploadsList]);

    const onDateRangeChanged = (day) => {
        const currentRange = {
            from: new Date(mediaTabFilter.date.from * 1000),
            to: new Date(mediaTabFilter.date.to * 1000),
        };
        const range = DayPicker.DateUtils.addDayToRange(day, currentRange);
        if (!mediaTabFilter.date.to) {
            range.to = day;
            range.from = day;
        }
        const newDateFilter = {
            to: moment(range.to).endOf("day").unix(),
            from: moment(range.from).startOf("day").unix(),
        };
        dispatch(setMediaFilter({ ...mediaTabFilter, date: newDateFilter }));
    };

    const renderDatePicker = () => {
        const dateFrom = mediaTabFilter.date.from ? new Date(mediaTabFilter.date.from * 1000) : null;
        const dateTo = mediaTabFilter.date.to ? new Date(mediaTabFilter.date.to * 1000) : null;
        const modifiers = { start: dateFrom, end: dateTo };

        return (
            <>
                <DayPicker
                    className="Selectable"
                    selectedDays={[dateFrom, { from: dateFrom, to: dateTo }]}
                    modifiers={modifiers}
                    onDayClick={onDateRangeChanged}
                />
                <div className="DatePickerButtons">
                    <OBCButton
                        className="ButtonReset"
                        disabled={mediaTabFilter.date.from ? false : true}
                        onClick={() => dispatch(setMediaFilter({ ...mediaTabFilter, date: { from: null, to: null } }))}>
                        Reset
                    </OBCButton>
                    <OBCButton
                        className="ButtonOk"
                        variant="secondary"
                        onClick={() => setDatePickerOpen(false)}>
                        Close
                    </OBCButton>
                </div>
            </>
        );
    };

    const onDisplayChnaged = (value) => {
        dispatch(setMediaFilter({ ...mediaTabFilter, owner: value }));
    };

    const onStatusChnaged = (value) => {
        dispatch(setMediaFilter({ ...mediaTabFilter, status: value }));
    };

    const onSortByChanged = (value) => {
        dispatch(setMediaFilter({ ...mediaTabFilter, sortBy: value }));
    };

    const onSearchQueryChanged = (value) => {
        setSearchString(value);
        let new_value = value.length === 0 ? null : value;
        dispatch(setMediaFilter({ ...mediaTabFilter, searchQurey: new_value }));
    };

    const displayAllUploadsNeedsReview = () => {
        dispatch(setMediaFilter({ ...mediaTabFilter, owner: "all", status: 0, searchQurey: "", date: { from: null, to: null } }));
    };

    const filteredMedia = useMemo(() => {
        let filteredList = filterMedia(
            mediaTabFilter.date,
            mediaTabFilter.owner,
            mediaTabFilter.searchQurey,
            mediaTabFilter.sortBy,
            checkIfAdmin,
            mediaTabFilter.status,
            mediaUploadsList,
            filterTags,
        );
        return filteredList;
    }, [mediaTabFilter, mediaUploadsList, filterTags]);

    const renderTagFilters = _.keys(availableTags).map((item, index) => {
        return (
            <OptGroup
                key={index}
                label={item}>
                {availableTags[item].map((tag) => (
                    <Option value={`${item}~${tag}`}>{tag}</Option>
                ))}
            </OptGroup>
        );
    });

    const handleTagSelection = (tag) => {
        const filter = {};
        _.keys(availableTags).forEach((item) => (filter[item] = []));
        tag.forEach((item) => filter[item.slice(0, item.indexOf("~"))].push(item.slice(item.indexOf("~") + 1)));
        setFilterTags(filter);
    };

    return (
        <div className="MediaMainContent">
            <div className="MediaFilterRow">
                <div className="LeftCol">
                    <div className="FilterItemDatePicker">
                        <Tooltip
                            overlayClassName="MediaTabDatePicker"
                            overlayStyle={{ opacity: 1 }}
                            title={renderDatePicker}
                            visible={datePickerOpen}
                            placement="bottomLeft"
                            trigger="click"
                            onVisibleChange={(visible) => setDatePickerOpen(visible)}>
                            <OBCToggleButton
                                active={mediaTabFilter.date && mediaTabFilter.date.from}
                                toggled={datePickerOpen}>
                                <CalendarOutlined classID="icon" />
                            </OBCToggleButton>
                        </Tooltip>
                    </div>
                    <div className="FilterItemContainer">
                        <span>Display:</span>
                        <div className="control">
                            <Select
                                defaultValue="mine"
                                value={mediaTabFilter.owner ?? "mine"}
                                onChange={onDisplayChnaged}
                                dropdownMatchSelectWidth={false}>
                                <Option value="all">All</Option>
                                <Option value="mine">Mine</Option>
                                <Option value="others">Others</Option>
                            </Select>
                        </div>
                    </div>
                    {checkIfAdmin && (
                        <div className="FilterItemContainer">
                            <span>Status:</span>
                            <div className="control">
                                <Select
                                    defaultValue={1}
                                    value={mediaTabFilter.status ?? 1}
                                    onChange={onStatusChnaged}
                                    dropdownMatchSelectWidth={false}>
                                    <Option value={1}>Reviewed</Option>
                                    <Option value={0}>Awaiting review</Option>
                                </Select>
                            </div>
                        </div>
                    )}
                    <div className="FilterItemContainer">
                        <span>Sort by:</span>
                        <div className="control">
                            <Select
                                defaultValue="last_uploaded"
                                style={{ minWidth: "135px" }}
                                onChange={onSortByChanged}
                                dropdownMatchSelectWidth={false}>
                                <Option value="last_uploaded">Last uploaded</Option>
                                <Option value="descr_az">Description (Az)</Option>
                                <Option value="descr_za">Description (Za)</Option>
                                <Option value="media_count_high">Media count 🔻</Option>
                                <Option value="media_count_low">Media count 🔺</Option>
                                <Option value="name_az">Name (Az)</Option>
                                <Option value="name_za">Name (Za)</Option>
                                <Option value="token_name_az">Token (Az)</Option>
                                <Option value="token_name_za">Token (Za)</Option>
                            </Select>
                        </div>
                    </div>
                    {_.keys(availableTags).length > 0 && (
                        <div className="FilterItemContainer">
                            <span>Filter by:</span>
                            <div className="control">
                                <Select
                                    className="tagFilters"
                                    maxTagCount={1}
                                    allowClear={true}
                                    mode="tags"
                                    clearIcon="X"
                                    placeholder="Tag"
                                    style={{ minWidth: "185px" }}
                                    dropdownMatchSelectWidth={false}
                                    onChange={handleTagSelection}>
                                    {renderTagFilters}
                                </Select>
                            </div>
                        </div>
                    )}
                    <div className="FilterItemRefresh">
                        <OBCToggleButton onClick={() => dispatch(getMediaUploads())}>
                            <SyncOutlined
                                className="icon"
                                spin={mediaUploadsListLoading}
                            />
                        </OBCToggleButton>
                    </div>
                </div>
                <div className="RightCol">
                    <OBCSearchInput
                        placeholder="Search for description"
                        onChange={onSearchQueryChanged}
                        value={mediaTabFilter.searchQurey}
                    />
                </div>
            </div>
            {filteredMedia.awaitingReview !== 0 && mediaTabFilter.status !== 0 && (
                <OBCButton
                    className="AwaitingReviewButton"
                    onClick={() => displayAllUploadsNeedsReview()}>
                    <div className="ButtonInner">
                        <FontAwesomeIcon
                            className="Icon"
                            icon={faFileSearch}
                        />
                        <span className="Label">
                            {filteredMedia.awaitingReview > 1
                                ? `There are ${filteredMedia.awaitingReview} uploads awaiting review, click here to see them`
                                : `There is 1 upload awaiting review, click here to see it`}
                        </span>
                    </div>
                </OBCButton>
            )}
            <div
                className="MediaMainList"
                ref={myRef}>
                {mediaUploadsListLoading ? (
                    <div className="OBCSpinner">
                        <OBCSpinner
                            size={70}
                            speed={3}
                            color={"#e8dfff"}
                        />
                    </div>
                ) : (
                    <>
                        {filteredMedia.list.length > 0 ? (
                            _.map(filteredMedia.list, (item, index) => {
                                return (
                                    <MediaTabListItem
                                        key={"MediaTabListItem" + index}
                                        index={index}
                                        mediaData={item}
                                        searchString={searchString}
                                    />
                                );
                            })
                        ) : (
                            <div className="NoMediaFoundLabel">
                                <h1>No media found</h1>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MediaTab;
