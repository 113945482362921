import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { routeSelected, selectMapPoint } from "../redux/actions/index";
import { Button, notification } from "antd";
import { nearestPointOnGeometry } from "./util/Geometry";
import { GROUND_TRUTH_DISPLAY_NAMES } from "./Issues/issueUtils";
import ZoomableImage from "./ZoomableImage";

const selectedMarkerSelector = (state) => state.markers.selectedMarker;
const geometrySelector = (state) => state.mapGeometry;

const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

const AssetTable = () => {
    const dispatch = useDispatch();

    const selectedMarker = useSelector(selectedMarkerSelector);
    const geometry = useSelector(geometrySelector);

    console.log("debug selectedMarker", selectedMarker);
    if (_.isEmpty(selectedMarker)) {
        return null;
    }

    let markerInfo = {};
    if (selectedMarker.data) {
        if (typeof selectedMarker.data === "string") {
            markerInfo = _.extend(markerInfo, JSON.parse(selectedMarker.data));
        } else {
            markerInfo = _.extend(markerInfo, selectedMarker.data);
        }
    }

    if (selectedMarker.custom_data) {
        if (selectedMarker.custom_data.custom_type === "fields") {
            markerInfo = _.extend(markerInfo, selectedMarker.custom_data.fields);
            markerInfo["type"] = selectedMarker.name;
        } else if (selectedMarker.custom_data.area_of_interest) {
            markerInfo["trigger"] = JSON.stringify(selectedMarker.custom_data.area_of_interest.description);
            markerInfo["startend"] = selectedMarker.custom_data.area_of_interest.entered ? "Start" : "End";
        } else {
            markerInfo = _.extend(markerInfo, selectedMarker.custom_data);
        }
    }

    const fields = _.map(markerInfo, (value, key) => {
        if (value && value !== "null" && value !== "Unknown" && typeof key === "string" && typeof value === "string") {
            if (key.charAt(0) === "~") {
                return (
                    <tr
                        key={key}
                        className="Row">
                        <td className={"Key"}>{key.substring(1)}:</td>
                        <td className={"Value"}>
                            <div className="mapSearchButton">
                                <button
                                    style={{ marginLeft: 0 }}
                                    title={"This will open a new window"}
                                    onClick={() => openInNewTab(value)}
                                    className="mapSearchButton">
                                    <span style={{ marginLeft: 0 }}>Open link</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr
                        key={key}
                        className="Row">
                        <td className={"Key"}>{key.charAt(0) === "_" ? key.substring(1) : key}:</td>
                        <td className={"Value"}>{value}</td>
                    </tr>
                );
            }
        }
    });

    const thumbnailImage = _.get(selectedMarker, ["summary_data", "images", "thumbnail"], null);
    if (selectedMarker.ground_truth_data) {
        Object.keys(selectedMarker.ground_truth_data).forEach((groundTruthKey) => {
            if (GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]) {
                markerInfo[GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]] = selectedMarker.ground_truth_data[groundTruthKey];
            }
        });
    } else if (selectedMarker.consensus_data) {
        Object.keys(selectedMarker.consensus_data).forEach((groundTruthKey) => {
            if (GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]) {
                markerInfo[GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]] = selectedMarker.consensus_data[groundTruthKey];
            }
        });
    }

    const goToSession = () => {
        let { lat, lon, coords } = selectedMarker;
        let closestPoint = null;
        console.log("debug pos", { lat, lon, coords });
        if (coords) {
            coords = [coords[1], coords[0]];
            closestPoint = nearestPointOnGeometry(coords[0], coords[1], geometry);
        } else {
            closestPoint = nearestPointOnGeometry(lat, lon, geometry);
        }
        if (closestPoint) {
            openVideo(closestPoint);
        } else {
            notification.error({
                message: "No nearby session found",
            });
        }
    };

    const openVideo = (closestPoint) => {
        const { video_key, session_id, frame } = selectedMarker;
        dispatch(routeSelected(session_id, video_key, undefined, undefined, undefined, frame));

        const closestPointID = closestPoint.point[0];
        dispatch(selectMapPoint(closestPointID, closestPoint.coordinates));
    };

    let button = null;

    if (_.isNil(selectedMarker.review_status)) {
        button = (
            <Button
                onClick={goToSession}
                type="primary"
                className="markerSessionButton">
                View In Session
            </Button>
        );
    }

    return (
        <>
            <div className="markerSessionButton-Container">{button}</div>
            <div className="markerInformationContainer table">
                <div className="markerInformationContainer__Inner table">
                    {thumbnailImage && (
                        <div className="Image">
                            <ZoomableImage
                                zoom={1}
                                imgSrc={thumbnailImage}
                                destroyOnClose={true}
                                updateZoom={() => {}}
                            />
                        </div>
                    )}
                    {/* {thumbnailImage && <img src={thumbnailImage} className="AssetImage" alt="Asset" crossOrigin={"anonymous"}/>} */}
                    <table className={"markerInformationTable"}>
                        <tbody>{fields}</tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AssetTable;
