import React, { useEffect, useMemo, useState } from "react";
import LoginComponent from "./LoginComponent";
import { useDispatch, useSelector } from "react-redux";
import { accessTokenLogin, logout } from "redux/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../images/new-branding-2.0/aivr-purple.svg";
import OBCSpinner from "./util/OBC";

const accessTokenSelector = (state) => state.access_token;

const AssoComponent = (props) => {
    const dispatch = useDispatch();
    const accessToken = useSelector(accessTokenSelector);
    const [error, setError] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    const getQueryParams = () => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        return params;
    };

    const queryParams = getQueryParams();

    const onLogin = (data) => {
        if (data.jwt) {
            directToRedirect(data.jwt);
        } else {
            setError(true);
        }
    };

    const domainPrefix = useMemo(() => {
        return queryParams.get("a") || null;
    }, [queryParams]);

    const directToRedirect = (jwt) => {
        const redirectURL = decodeURI(queryParams.get("redirect"));
        const urlData = queryParams.get("data");

        let fullRedirectURL = `${redirectURL}?token=${jwt}`;
        if (urlData) {
            fullRedirectURL += `&data=${urlData}`;
        }

        window.location.href = fullRedirectURL;
    };

    const ssoLogin = () => {
        const redirectURL = decodeURI(queryParams.get("redirect"));
        dispatch(
            accessTokenLogin(
                accessToken,
                (data) => {
                    if (data.jwt) {
                        directToRedirect(data.jwt);
                    } else {
                        dispatch(logout(null));
                    }
                },
                {},
                redirectURL,
            ),
        );
    };

    const ssoLogout = () => {
        dispatch(logout());
        setLoggedOut(true);
    };

    if (error) {
        return (
            <div className="LoginOverlay Center-Content">
                <div className="LoginContent__Container">
                    <div className="LoginContent">
                        <img
                            className="LoginLogo"
                            src={logo}
                            alt="AIVR Logo"
                            crossOrigin={"anonymous"}
                        />
                        <p className="LoginErrorMessage">Error authenticating with AIVR Auth</p>
                        <div className="LoginFooter">
                            <div className="LoginSales">
                                Please contact <a href="mailto:support@onebigcircle.co.uk?subject=AIVR Auth">support</a> if the issue persists
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (accessToken) {
        if (queryParams.get("logout") && !loggedOut) {
            ssoLogout();
        } else {
            ssoLogin();
        }
        return (
            <div className="AssoSpinnerContainer">
                <OBCSpinner
                    colorScheme={"mono"}
                    size={70}
                />
            </div>
        );
    } else {
        return (
            <LoginComponent
                loginCompleteCallback={onLogin}
                ssoRedirect={decodeURI(queryParams.get("redirect"))}
                domainPrefixFromASSO={domainPrefix}
            />
        );
    }
};

export default withRouter(AssoComponent);
