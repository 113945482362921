import React from "react";
import OBCButton from "./OBCButton";

const OBCToggleButton = ({ ...props }) => {
    return (
        <OBCButton
            {...props}
            size="lg"
            className={`OBCToggleButton ${props.toggled ? "Enabled" : ""}`}>
            <>{props.children}</>
            {props.showArrow && props.active && <div className="ArrowDown"></div>}
        </OBCButton>
    );
};

OBCToggleButton.defaultProps = {
    active: false,
    toggled: false,
    showArrow: false,
};

export default OBCToggleButton;
