import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { MEMOIZED_DOMAIN_URL } from "components/util/HostUtils";
import _ from "lodash";
import { fabric } from "fabric";
import moment from "moment";
import { notification } from "antd";
import Measure from "react-measure";

const BASE_URL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const widgetRailImageLatPosSelector = (state) => _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "position", "railInspectionLatPos"], 0);
const railImageLatPosSelector = (state) => state.railInspection.selectedRailInspectionImage.latPos;
const adjustmentsSelector = (state) => state.railInspection.detailImageAdjustments;
const routeIdSelector = (state) => state.playlist.data.routeID;
const csrfTokenSelector = (state) => state.csrfToken;
const widgetSelectedRailImageIndexSelector = (state) =>
    _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "position", "railInspectionIndex"], 0);
const selectedRailImageIndexSelector = (state) => state.railInspection.selectedRailInspectionImage.index;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railImagesSelector = (state) => _.get(state.schemaInterface.sessions, [state.schemaInterface.selectedSession], []);

let UNSELECTABLE_PROPERTIES = {
    controls: {},
    hasBorders: false,
    selectable: false,
};

const DEBUG = false;
if (DEBUG) {
    UNSELECTABLE_PROPERTIES = {};
}

const DIVISIONS_PER_METER = 10;

const RailImageDisplay = ({
    imageConfigItem,
    zoom,
    setZoom,
    syncOffset,
    isPatrol,
    sessionIsBackward,
    triggerSnapshot,
    setTriggerSnapshot,
    isWidget,
    showGrid,
}) => {
    const [dimensions, setDimensions] = useState({});
    const [fabricCanvas, setFabricCanvas] = useState(null);
    const [fabricCanvasRef, setFabricCanvasRef] = useState(null);
    const [configItemRendered, setConfigItemRendered] = useState();
    const [canTakeSnapshot, setCanTakeSnapshot] = useState(false);
    const [imageAdjustments, setImageAdjustments] = useState({});
    const [previousImage, setPreviousImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [nextImage, setNextImage] = useState(null);

    const railImageLatPos = useSelector(isWidget ? widgetRailImageLatPosSelector : railImageLatPosSelector);
    const selectedRailImageIndexOriginal = useSelector(isWidget ? widgetSelectedRailImageIndexSelector : selectedRailImageIndexSelector);
    const currentAdjustments = useSelector(adjustmentsSelector);
    const routeID = useSelector(routeIdSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    let selectedRailImageIndex = selectedRailImageIndexOriginal;
    if (syncOffset) {
        selectedRailImageIndex = selectedRailImageIndexOriginal + syncOffset;
    }

    const inspectionImages = useSelector(railDataObjectSelector);
    const patrolImages = useSelector(railImagesSelector);

    const railImages = useMemo(() => {
        if (isPatrol) {
            return patrolImages;
        } else {
            return inspectionImages;
        }
    }, [inspectionImages, isPatrol, patrolImages]);

    const previousImageRef = useRef(null);
    const currentImageRef = useRef(null);
    const nextImageRef = useRef(null);
    const previousImageGridRef = useRef(null);
    const currentImageGridRef = useRef(null);
    const nextImageGridRef = useRef(null);
    const arrowRef = useRef(null);

    const imageScrollOffset = Math.round((railImageLatPos % 1) * 1000) / 1000;

    const currentImageAdjustments = useMemo(() => {
        return _.get(currentAdjustments, [_.get(imageConfigItem, "name", null)], {});
    }, [currentAdjustments, imageConfigItem]);

    useEffect(() => {
        if (!_.isEqual(imageAdjustments, currentImageAdjustments)) {
            setImageAdjustments(currentImageAdjustments);
        }
    }, [imageAdjustments, currentImageAdjustments]);

    const railImagesToDisplay = useMemo(() => {
        if (!imageConfigItem) {
            return [];
        }

        let leftOffset = imageScrollOffset;
        if (imageScrollOffset < 0) {
            leftOffset += 0.5;
        } else {
            leftOffset -= 0.5;
        }
        let images = [];
        if (selectedRailImageIndex - 1 > -1) {
            const previousIndex = selectedRailImageIndex - 1;
            const image = railImages[previousIndex];
            if (image) {
                const basePath = image.base_path;
                const newBasePath = basePath.replace("$DEVICE", `${imageConfigItem.source}`);
                let imageSource = `${BASE_URL}${newBasePath}/${image.timestamp}.jpg`;
                if (csrfToken) {
                    imageSource += `?csrf=${csrfToken}`;
                }

                const previousRailImage = {
                    isPrevious: true,
                    leftOffset: leftOffset,
                    index: previousIndex,
                    source: imageSource,
                };
                images.push(previousRailImage);
            }
        }

        if (selectedRailImageIndex > -1) {
            const image = railImages[selectedRailImageIndex];
            if (image) {
                const basePath = image.base_path;
                const newBasePath = basePath.replace("$DEVICE", `${imageConfigItem.source}`);
                let imageSource = `${BASE_URL}${newBasePath}/${image.timestamp}.jpg`;
                if (csrfToken) {
                    imageSource += `?csrf=${csrfToken}`;
                }

                const currentRailImage = {
                    isCurrent: true,
                    leftOffset: leftOffset,
                    index: selectedRailImageIndex,
                    source: imageSource,
                };
                images.push(currentRailImage);
            }
        }

        if (selectedRailImageIndex + 1 > -1 && selectedRailImageIndex + 1 < railImages.length) {
            const nextIndex = selectedRailImageIndex + 1;
            const image = railImages[nextIndex];
            if (image) {
                const basePath = image.base_path;
                const newBasePath = basePath.replace("$DEVICE", `${imageConfigItem.source}`);
                let imageSource = `${BASE_URL}${newBasePath}/${image.timestamp}.jpg`;
                if (csrfToken) {
                    imageSource += `?csrf=${csrfToken}`;
                }

                const nextRailImage = {
                    isNext: true,
                    leftOffset: leftOffset,
                    index: nextIndex,
                    source: imageSource,
                };
                images.push(nextRailImage);
            }
        }
        return images;
    }, [imageConfigItem, imageScrollOffset, selectedRailImageIndex, railImages]);

    useEffect(() => {
        if (fabricCanvasRef) {
            const canvas = new fabric.Canvas(fabricCanvasRef, {
                defaultCursor: "pointer",
                containerClass: "ScrollingCanvasContainer",
                selection: DEBUG,
            });
            setFabricCanvas(canvas);
        }
    }, [fabricCanvasRef]);

    useEffect(() => {
        if (fabricCanvas) {
            fabricCanvas.setWidth(dimensions.width);
            fabricCanvas.setHeight(dimensions.height);
        }
    }, [dimensions, fabricCanvas]);

    const drawArrow = useCallback(
        (arrowVerticalPos, arrowWidth, posLeft) => {
            if (arrowRef.current) {
                fabricCanvas.remove(arrowRef.current);
            }
            let arrowRight = imageConfigItem.rotate === "right";
            if (sessionIsBackward) {
                arrowRight = imageConfigItem.rotate === "left";
            }

            let objGroup;
            if (arrowRight) {
                const rightArrow = [
                    new fabric.Triangle({
                        width: 28,
                        height: 22,
                        fill: "red",
                        left: posLeft + 5,
                        top: arrowVerticalPos - 14,
                        angle: 90,
                        ...UNSELECTABLE_PROPERTIES,
                    }),
                    new fabric.Line([posLeft, arrowVerticalPos, posLeft - arrowWidth, arrowVerticalPos], {
                        stroke: "red",
                        strokeWidth: 3,
                        ...UNSELECTABLE_PROPERTIES,
                    }),
                ];
                objGroup = new fabric.Group(rightArrow, UNSELECTABLE_PROPERTIES);
            } else {
                const leftArrow = [
                    new fabric.Triangle({
                        width: 28,
                        height: 22,
                        fill: "red",
                        left: Math.max(posLeft - 10, 0),
                        top: arrowVerticalPos + 14,
                        angle: -90,
                        ...UNSELECTABLE_PROPERTIES,
                    }),
                    new fabric.Line([Math.max(posLeft, 5), arrowVerticalPos, posLeft + imageConfigItem.display_width, arrowVerticalPos], {
                        stroke: "red",
                        strokeWidth: 3,
                        ...UNSELECTABLE_PROPERTIES,
                    }),
                ];
                objGroup = new fabric.Group(leftArrow, UNSELECTABLE_PROPERTIES);
            }
            fabricCanvas.add(objGroup);
            arrowRef.current = objGroup;
        },
        [fabricCanvas, imageConfigItem.rotate, sessionIsBackward, imageConfigItem.display_width],
    );

    const calculateImagePositionProperties = useCallback(
        (img, imageData) => {
            // When imageConfigItem.rotate === left, the left value is actually the right side of the shape

            const SINGLE_IMAGE_WIDTH = imageConfigItem.display_width;
            const SQUASH_FACTOR = imageConfigItem.display_height / img.width;
            const widthRatioChange = SINGLE_IMAGE_WIDTH / img.height;
            const newWidth = img.height * SQUASH_FACTOR;

            let rotation = imageConfigItem.rotate === "left" ? -90 : 90;
            let top = imageConfigItem.rotate === "left" ? newWidth : 0;
            let left = imageConfigItem.rotate === "left" ? 0 : SINGLE_IMAGE_WIDTH;

            if (sessionIsBackward) {
                rotation = imageConfigItem.rotate === "left" ? 90 : -90;
                top = imageConfigItem.rotate === "left" ? 0 : newWidth;
                left = imageConfigItem.rotate === "left" ? SINGLE_IMAGE_WIDTH : 0;
            }

            const newImageWidth = img.height * widthRatioChange;
            left += (dimensions.width - newImageWidth) / 2;
            if (imageData.leftOffset) {
                let imageOffset = imageData.leftOffset * newImageWidth;
                if (imageConfigItem.rotate === "left") {
                    if (sessionIsBackward) {
                        left -= imageOffset;
                    } else {
                        left += imageOffset;
                    }
                } else {
                    if (sessionIsBackward) {
                        left += imageOffset;
                    } else {
                        left -= imageOffset;
                    }
                }
            }

            if ((imageData.isNext && imageConfigItem.rotate === "left") || (imageData.isPrevious && imageConfigItem.rotate === "right")) {
                if (sessionIsBackward) {
                    left += img.height * widthRatioChange;
                } else {
                    left -= img.height * widthRatioChange;
                }
            } else if ((imageData.isPrevious && imageConfigItem.rotate === "left") || (imageData.isNext && imageConfigItem.rotate === "right")) {
                if (sessionIsBackward) {
                    left -= img.height * widthRatioChange;
                } else {
                    left += img.height * widthRatioChange;
                }
            }

            return {
                top,
                left: Math.floor(left),
                angle: rotation,
            };
        },
        [imageConfigItem.display_height, imageConfigItem.display_width, imageConfigItem.rotate, sessionIsBackward, dimensions.width],
    );

    const imageFilters = useMemo(() => {
        const imageFilters = [];
        if (!_.isNil(imageAdjustments.brightness)) {
            imageFilters.push(new fabric.Image.filters.Brightness({ brightness: imageAdjustments.brightness }));
        }
        if (!_.isNil(imageAdjustments.contrast)) {
            imageFilters.push(new fabric.Image.filters.Contrast({ contrast: imageAdjustments.contrast }));
        }
        return imageFilters;
    }, [imageAdjustments]);

    useEffect(() => {
        if (fabricCanvas) {
            if (previousImageRef.current) {
                previousImageRef.current.filters = imageFilters;
                previousImageRef.current.applyFilters();

                if (previousImageGridRef.current) {
                    previousImageGridRef.current.visible = showGrid;
                }
            }
            if (nextImageRef.current) {
                nextImageRef.current.filters = imageFilters;
                nextImageRef.current.applyFilters();

                if (nextImageGridRef.current) {
                    nextImageGridRef.current.visible = showGrid;
                }
            }

            if (currentImageRef.current) {
                currentImageRef.current.filters = imageFilters;
                currentImageRef.current.applyFilters();

                if (currentImageGridRef.current) {
                    currentImageGridRef.current.visible = showGrid;
                }
            }

            fabricCanvas.renderAll();
        }
    }, [fabricCanvas, imageFilters, previousImage, currentImage, nextImage, showGrid]);

    const loadGrid = useCallback(
        (imagePositionProperties) => {
            const width = imageConfigItem.display_width;
            const height = imageConfigItem.display_height;
            const gridSize = width / DIVISIONS_PER_METER;

            let left = imagePositionProperties.left;
            if (imagePositionProperties.angle === 90) {
                left -= width;
            }

            const grid = [];
            const properties = {
                stroke: "#FF0000",
                strokeWidth: 1,
                ...UNSELECTABLE_PROPERTIES,
            };

            for (let i = 0; i <= height; i += gridSize) {
                grid.push(new fabric.Line([left, i, left + width, i], properties));
            }

            for (let i = 0; i <= width; i += gridSize) {
                grid.push(new fabric.Line([left + i, 0, left + i, height], properties));
            }

            return new fabric.Group(grid, UNSELECTABLE_PROPERTIES);
        },
        [imageConfigItem],
    );

    const loadFabricImage = useCallback(
        (SINGLE_IMAGE_WIDTH, img, imageData, ignoreFlip = false, isFallback = false) => {
            const SQUASH_FACTOR = imageConfigItem.display_height / img.width;
            const widthRatioChange = SINGLE_IMAGE_WIDTH / img.height;
            const imagePositionProperties = calculateImagePositionProperties(img, imageData);

            if (!imageData.isNext && !imageData.isPrevious) {
                const arrowPosLeft = imagePositionProperties.left;
                const verticalArrowPos = img.height * SQUASH_FACTOR + (dimensions.height - img.height * SQUASH_FACTOR) / 2;
                drawArrow(verticalArrowPos, SINGLE_IMAGE_WIDTH, arrowPosLeft);
            }

            let imageProperties;

            if (isFallback) {
                imageProperties = {
                    ...UNSELECTABLE_PROPERTIES,
                    scaleY: 0.5,
                    scaleX: SQUASH_FACTOR,
                    railIndex: imageData.index,
                    ...imagePositionProperties,
                    flipX: ignoreFlip ? false : sessionIsBackward,
                };
            } else {
                imageProperties = {
                    ...UNSELECTABLE_PROPERTIES,
                    scaleY: widthRatioChange,
                    scaleX: SQUASH_FACTOR,
                    railIndex: imageData.index,
                    ...imagePositionProperties,
                    flipX: ignoreFlip ? false : sessionIsBackward,
                };
            }

            const imgObj = img.set(imageProperties);
            const gridObj = loadGrid(imagePositionProperties);

            if (imageData.isPrevious) {
                previousImageRef.current = imgObj;
                setPreviousImage(previousImageRef.current);
                previousImageGridRef.current = gridObj;
            } else if (imageData.isNext) {
                nextImageRef.current = imgObj;
                setNextImage(nextImageRef.current);
                nextImageGridRef.current = gridObj;
            } else {
                currentImageRef.current = imgObj;
                setCurrentImage(currentImageRef.current);
                currentImageGridRef.current = gridObj;
            }

            return [imgObj, gridObj];
        },
        [calculateImagePositionProperties, dimensions.height, drawArrow, imageConfigItem.display_height, sessionIsBackward, loadGrid],
    );

    // rerender new images
    useEffect(() => {
        if (fabricCanvas && railImagesToDisplay) {
            const SINGLE_IMAGE_WIDTH = imageConfigItem.display_width;

            const newRailImages = railImagesToDisplay.filter((image) => {
                return (
                    configItemRendered !== imageConfigItem.name ||
                    ((!previousImageRef.current || image.source !== previousImageRef.current._originalElement.currentSrc) &&
                        (!currentImageRef.current || image.source !== currentImageRef.current._originalElement.currentSrc) &&
                        (!nextImageRef.current || image.source !== nextImageRef.current._originalElement.currentSrc))
                );
            });

            if (newRailImages.length) {
                fabricCanvas.getObjects().forEach((obj) => {
                    if (obj.dispose) {
                        obj.dispose();
                    }
                });
                previousImageRef.current = null;
                currentImageRef.current = null;
                nextImageRef.current = null;
                fabricCanvas.clear();
                setConfigItemRendered(imageConfigItem.name);

                railImagesToDisplay.forEach((imageData) => {
                    fabric.Image.fromURL(
                        imageData.source,
                        function (img, isError) {
                            if (isError) {
                                if (imageData.isCurrent === true) {
                                    setCanTakeSnapshot(false);
                                }
                                // if image not loaded, load fallback image instead
                                let fallbackImgUrl =
                                    imageConfigItem.rotate === "left" ? "/image_not_available_rotated_left.png" : "/image_not_available_rotated_right.png";
                                if (sessionIsBackward) {
                                    fallbackImgUrl =
                                        imageConfigItem.rotate === "right" ? "/image_not_available_rotated_left.png" : "/image_not_available_rotated_right.png";
                                }

                                fabric.Image.fromURL(
                                    fallbackImgUrl,
                                    function (newImg) {
                                        const [imgObj, gridObj] = loadFabricImage(SINGLE_IMAGE_WIDTH, newImg, imageData, true, true);
                                        fabricCanvas.add(imgObj);
                                        fabricCanvas.add(gridObj);
                                        gridObj.visible = showGrid;
                                    },
                                    { crossOrigin: "anonymous" },
                                );
                            } else {
                                if (imageData.isCurrent === true) {
                                    setCanTakeSnapshot(true);
                                }

                                const [imgObj, gridObj] = loadFabricImage(SINGLE_IMAGE_WIDTH, img, imageData);
                                fabricCanvas.add(imgObj);
                                fabricCanvas.add(gridObj);
                                gridObj.visible = showGrid;
                            }
                        },
                        { crossOrigin: "anonymous" },
                    );
                });
            }
        }
    }, [fabricCanvas, imageConfigItem.display_width, imageConfigItem.name, imageConfigItem.rotate, railImagesToDisplay, sessionIsBackward, showGrid]);

    // Reposition existing images
    useEffect(() => {
        const previousImageData = _.find(railImagesToDisplay, { index: _.get(previousImageRef.current, ["railIndex"]) });
        if (previousImageData) {
            const newPosition = calculateImagePositionProperties(previousImageRef.current, previousImageData);
            previousImageRef.current.set({
                ...newPosition,
            });
            previousImageRef.current.setCoords();

            if (previousImageGridRef.current) {
                previousImageGridRef.current.set({
                    left: newPosition.left - (newPosition.angle === 90 ? imageConfigItem.display_width : 0),
                });
                previousImageGridRef.current.setCoords();
            }
        }

        const currentImageData = _.find(railImagesToDisplay, { index: _.get(currentImageRef.current, ["railIndex"]) });
        if (currentImageData) {
            const newPosition = calculateImagePositionProperties(currentImageRef.current, currentImageData);
            currentImageRef.current.set({
                ...newPosition,
            });
            currentImageRef.current.setCoords();

            if (currentImageGridRef.current) {
                currentImageGridRef.current.set({
                    left: newPosition.left - (newPosition.angle === 90 ? imageConfigItem.display_width : 0),
                });
                currentImageGridRef.current.setCoords();
            }

            const SQUASH_FACTOR = imageConfigItem.display_height / currentImageRef.current.width;
            const verticalArrowPos = currentImageRef.current.height * SQUASH_FACTOR + (dimensions.height - currentImageRef.current.height * SQUASH_FACTOR) / 2;
            const arrowPosLeft = newPosition.left;

            drawArrow(verticalArrowPos, imageConfigItem.display_width, arrowPosLeft);
        }

        const nextImageData = _.find(railImagesToDisplay, { index: _.get(nextImageRef.current, ["railIndex"]) });
        if (nextImageData) {
            const newPosition = calculateImagePositionProperties(nextImageRef.current, nextImageData);
            nextImageRef.current.set({
                ...newPosition,
            });
            nextImageRef.current.setCoords();

            if (nextImageGridRef.current) {
                nextImageGridRef.current.set({
                    left: newPosition.left - (newPosition.angle === 90 ? imageConfigItem.display_width : 0),
                });
                nextImageGridRef.current.setCoords();
            }
        }

        if (fabricCanvas) {
            fabricCanvas.renderAll();
        }
    }, [
        calculateImagePositionProperties,
        dimensions.height,
        drawArrow,
        fabricCanvas,
        railImagesToDisplay,
        imageConfigItem.display_width,
        imageConfigItem.display_height,
    ]);

    const limitCanvasPosition = useCallback((canvas) => {
        const canvasViewPort = canvas.viewportTransform;

        const bottomEndPoint = canvas.height * (canvasViewPort[0] - 1);
        if (canvasViewPort[5] >= 0 || -bottomEndPoint > canvasViewPort[5]) {
            canvasViewPort[5] = canvasViewPort[5] >= 0 ? 0 : -bottomEndPoint;
        }

        const rightEndPoint = canvas.width * (canvasViewPort[0] - 1);
        if (canvasViewPort[4] >= 0 || -rightEndPoint > canvasViewPort[4]) {
            canvasViewPort[4] = canvasViewPort[4] >= 0 ? 0 : -rightEndPoint;
        }
        canvas.viewportTransform = canvasViewPort;
    }, []);

    const onMove = useCallback(
        (opt, canvas) => {
            if (canvas.isDragging) {
                const delta = new fabric.Point(opt.e.movementX, opt.e.movementY);
                canvas.relativePan(delta);
                limitCanvasPosition(canvas);
            }
        },
        [limitCanvasPosition],
    );

    const onScroll = useCallback(
        (opt) => {
            let canvasZoom = fabricCanvas.getZoom();
            if (opt) {
                const delta = opt.e.deltaY;
                canvasZoom *= 0.999 ** delta;
                canvasZoom = Math.max(Math.min(7, canvasZoom), 1);
                fabricCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, canvasZoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
                setZoom(canvasZoom);
            }
            limitCanvasPosition(fabricCanvas);
        },
        [fabricCanvas, limitCanvasPosition],
    );

    useEffect(() => {
        if (fabricCanvas) {
            fabricCanvas.on("mouse:down", function (_) {
                this.isDragging = true;
            });

            fabricCanvas.on("mouse:move", function (opt) {
                onMove(opt, this);
            });

            fabricCanvas.on("mouse:up", function (_) {
                this.setViewportTransform(this.viewportTransform);
                this.isDragging = false;
            });

            fabricCanvas.on("mouse:wheel", function (opt) {
                onScroll(opt);
            });

            return () => {
                fabricCanvas.__eventListeners["mouse:move"] = [];
                fabricCanvas.__eventListeners["mouse:down"] = [];
                fabricCanvas.__eventListeners["mouse:up"] = [];
                fabricCanvas.__eventListeners["mouse:wheel"] = [];
            };
        }
    }, [fabricCanvas, onMove, onScroll]);

    useEffect(() => {
        if (fabricCanvas) {
            const currentZoom = fabricCanvas.getZoom();
            if (currentZoom !== zoom) {
                let x = fabricCanvas.width / 2;
                let y = fabricCanvas.height / 2;
                fabricCanvas.zoomToPoint(new fabric.Point(x, y), zoom);
                limitCanvasPosition(fabricCanvas);
                fabricCanvas.renderAll();
            }
        }
    }, [fabricCanvas, zoom, limitCanvasPosition]);

    const renderSnapshotCanvas = useCallback(() => {
        const SINGLE_IMAGE_WIDTH = imageConfigItem.display_width;

        // if below set to 1 the generated image will be the same size in pixels as the canvas in detailed view
        // otherwise we are scaling canvas and images up so it will get highest resolution
        const SCALE_FACTOR = imageConfigItem.img_width / imageConfigItem.display_height;

        // Create a new off-screen Fabric canvas
        const canvas = new fabric.StaticCanvas(null, {
            width: dimensions.width * SCALE_FACTOR,
            isDrawingMode: false,
            backgroundColor: "black",
        });

        let canvasHeightSet = false;

        const imagePromises = railImagesToDisplay.map((imageData) => {
            return new Promise((resolve, reject) => {
                fabric.Image.fromURL(
                    imageData.source,
                    (img) => {
                        if (!canvasHeightSet) {
                            canvas.setHeight(img.height);
                            canvasHeightSet = true;
                        }
                        const SQUASH_FACTOR = imageConfigItem.display_height / img.width;
                        const widthRatioChange = SINGLE_IMAGE_WIDTH / img.height;
                        let imagePositionProperties = calculateImagePositionProperties(img, imageData);
                        imagePositionProperties["left"] = imagePositionProperties.left * SCALE_FACTOR;
                        imagePositionProperties["top"] = imagePositionProperties.top * SCALE_FACTOR;

                        const imageProperties = {
                            ...UNSELECTABLE_PROPERTIES,
                            scaleY: widthRatioChange * SCALE_FACTOR,
                            scaleX: SQUASH_FACTOR * SCALE_FACTOR,
                            railIndex: imageData.index,
                            ...imagePositionProperties,
                            flipX: sessionIsBackward,
                        };

                        const imgObj = img.set(imageProperties);

                        // apply filters to image object
                        imgObj.filters = imageFilters;
                        imgObj.applyFilters();

                        canvas.add(imgObj);
                        resolve();
                    },
                    { crossOrigin: "anonymous" },
                );
            });
        });

        // Convert the off-screen canvas to a data URL when all images loaded.
        Promise.all(imagePromises)
            .then(() => {
                const dataURL = canvas.toDataURL({ format: "png", quality: 1 });

                const now = moment();
                const formattedDateTime = now.format("DD-MM-YYYY-HH-mm");
                const fileName = `Snapshot_from_#${routeID}_${_.get(imageConfigItem, "name", "Detail_rail").replace(" ", "_")}_Rail_${formattedDateTime}.png`;

                // Create a temporary anchor element to trigger the download
                const link = document.createElement("a");
                link.href = dataURL;
                link.download = fileName;
                link.click();

                // Dispose of the off-screen canvas
                canvas.dispose();
            })
            .catch((error) => {
                notification.error({
                    message: "Error downloading image",
                    description: "There was an error downloading Detailed Images, try again.",
                });
                console.error("Error loading images:", error);
            });
    }, [calculateImagePositionProperties, dimensions.height, dimensions.width, imageConfigItem, imageFilters, railImagesToDisplay, routeID, sessionIsBackward]);

    // useEffect to be triggered from parrent component RailInspectionExtraDisplay
    useEffect(() => {
        if (triggerSnapshot) {
            if (canTakeSnapshot) {
                renderSnapshotCanvas();
                setTriggerSnapshot(false);
            } else {
                setTriggerSnapshot(false);
                notification.info({
                    message: "No image available",
                });
            }
        }
    }, [canTakeSnapshot, renderSnapshotCanvas, setTriggerSnapshot, triggerSnapshot]);

    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setDimensions(contentRect.bounds);
            }}>
            {({ measureRef }) => {
                return (
                    <div
                        className="InspectionScrollingCanvas"
                        ref={measureRef}>
                        <canvas
                            ref={(c) => setFabricCanvasRef(c)}
                            className="ScrollingFabricCanvas"
                        />
                    </div>
                );
            }}
        </Measure>
    );
};

export default RailImageDisplay;
