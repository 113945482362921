import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";
export const SELECT_SESSION = "SELECT_SESSION";
export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const GET_DRIVER_TRAINING = "GET_DRIVER_TRAINING";
export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA";
export const SET_DRIVERS_DATA = "SET_DRIVERS_DATA";
export const SET_TRAINING_DATA = "SET_TRAINING_DATA";
export const SET_TRAININGS_LIST = "SET_TRAININGS_LIST";
export const SET_SELECTED_CATEGORY_DATA = "SET_SELECTED_CATEGORY_DATA";
export const GET_SESSION_DATA = "GET_SESSION_DATA";
export const SET_AVALIABLE_TRAINING_CATEGORIES = "SET_AVALIABLE_TRAINING_CATEGORIES";
export const ADD_TRAINING_TO_DRIVER = "ADD_TRAINING_TO_DRIVER";
export const SET_TRAINEE_TRAINING_DATA = "SET_TRAINEE_TRAINING_DATA";
export const SET_PROGRESS_COMPLETED_DATA = "SET_PROGRESS_COMPLETED_DATA";
export const SET_PROGRESS_SEEN_DATA = "SET_PROGRESS_SEEN_DATA";
export const SET_PROGRESS_SKIPPED_DATA = "SET_PROGRESS_SKIPPED_DATA";

export function setCurrentPage(currentPage) {
    return {
        type: SET_CURRENT_PAGE,
        currentPage,
    };
}

export function setSelectedSessionData(sessionData) {
    return {
        type: SELECT_SESSION,
        sessionData,
    };
}

export function setSelecteCategoryData(categoryData) {
    return {
        type: SET_CATEGORY_ID,
        categoryData,
    };
}

function setDriversData(driversData) {
    return {
        type: SET_DRIVERS_DATA,
        driversData,
    };
}

function setDrvierTrainingData(trainingData) {
    return {
        type: SET_TRAINING_DATA,
        trainingData,
    };
}

function setSelectedCategoryData(categoryData) {
    return {
        type: SET_SELECTED_CATEGORY_DATA,
        categoryData,
    };
}

function setAvailableTrainingCategories(categories) {
    return {
        type: SET_AVALIABLE_TRAINING_CATEGORIES,
        categories,
    };
}

function setAvailableTraineeTrainings(trainingData) {
    return {
        type: SET_TRAINEE_TRAINING_DATA,
        trainingData,
    };
}

function updateSelectedCompletedAnnotationsList(annotations) {
    return {
        type: SET_PROGRESS_COMPLETED_DATA,
        annotations,
    };
}

export function updateSelectedSeenAnnotationsList(annotations) {
    return {
        type: SET_PROGRESS_SEEN_DATA,
        annotations,
    };
}

export function updateSkippedAnnotationsList(annotations) {
    return {
        type: SET_PROGRESS_SKIPPED_DATA,
        annotations,
    };
}

// const CATEGORY_FAKE_DATA = [
//     {
//         'category_id': 259,
//         'category_name': 'Derby to Syston',
//         'category_last_update': null,
//         'training_last_update': null,
//         'total_sessions_count': 2,
//         'completed_sessions_count': null,
//         'progress': null
//     },
//     {
//         'category_id': 260,
//         'category_name': 'Derby to Buxton',
//         'category_last_update': null,
//         'training_last_update': null,
//         'total_sessions_count': 1,
//         'completed_sessions_count': null,
//         'progress': null
//     }
// ]

export function getAllDriversData(callback) {
    return {
        queue: GET_ALL_DRIVERS,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            let postBody = {
                action: "get_all_drivers",
                access_secret: accessSecret,
            };

            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug response2", response.data);
                    dispatch(setDriversData(response.data));
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    console.log("debug Error fetching get_all_drivers_training", error);
                    handleJsonPostError("Unable to fetch drivers data", error);
                    if (callback) {
                        callback(true);
                    }
                    next();
                });
        },
    };
}

export function getDriverTrainingData(driverSeatID, callback) {
    return {
        queue: GET_DRIVER_TRAINING,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            let postBody = {
                action: "get_driver_categories",
                user_seat_id: driverSeatID,
                access_secret: accessSecret,
            };

            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug get_driver_categories", response.data);
                    dispatch(setDrvierTrainingData(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                    next();
                })
                .catch((error) => {
                    console.log("debug Error fetching get_driver_categories", error);
                    handleJsonPostError("Unable to fetch driver training data", error);
                    dispatch(setDrvierTrainingData([]));
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}

export function getDriverTrainingCategoryData(user_seat_id, session_ids, category_id, callback, abortSignal = null) {
    return {
        queue: GET_SESSION_DATA,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            let postBody = {
                action: "get_category_data",
                user_seat_id,
                session_ids,
                category_id,
                access_secret: accessSecret,
            };

            console.log("Making session list request with IDs: ", session_ids);

            let url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch, true, abortSignal)
                .then((response) => {
                    console.log("debug get_category_data", response.data);
                    if (response.data) {
                        dispatch(setSelectedCategoryData(response.data));
                    } else {
                    }
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    if (callback) {
                        callback(false);
                    }
                    dispatch(setSelectedCategoryData([]));
                    console.log("debug error", error);

                    handleJsonPostError("Unable to retrieve sessions list", "An error occurred while fetching the session list [admin]", error);
                    next();
                });
        },
    };
}

export function getAvailableTrainingCategories(global = true, callback) {
    let postBody = {
        action: "get_favourite_categories",
        global,
    };

    let url = "/sessions";
    return {
        queue: SET_AVALIABLE_TRAINING_CATEGORIES,
        callback: (next, dispatch, getState) => {
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((categories) => {
                    if (categories.success) {
                        console.log("debug categories", categories);
                        dispatch(setAvailableTrainingCategories(categories.favourite_categories));
                    }
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve user categories", error);
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}

export function addTrainingToDriver(userSeatId, categoryIds, trainingDueTs, lastCompletedTs, trainingIntervals, callback) {
    return {
        queue: ADD_TRAINING_TO_DRIVER,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            let postBody = {
                action: "add_training_to_driver",
                user_seat_id: userSeatId,
                category_ids: categoryIds,
                training_due_ts: trainingDueTs,
                last_completed_ts: lastCompletedTs,
                interval_days: trainingIntervals,
                access_secret: accessSecret,
            };

            const url = "/admin";

            // update state in Redux
            // const observations = _.cloneDeep(getState().admin.observationReview.selectedSession.observations);
            // let newObservations = _.cloneDeep(observations);
            // const index = _.findIndex(newObservations, obs => obs["id"] === observationID);
            // newObservations[index]["review_status"] = reviewStatus;
            // dispatch(updateObservations(newObservations));

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        console.log("debug add training to the driver", response);
                    }
                    if (callback) {
                        callback(response);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to add new training to this driver", error);
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
            next();
        },
    };
}

export function removeTrainingForDriver(userSeatId, categoryIds, callback) {
    return {
        queue: ADD_TRAINING_TO_DRIVER,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            let postBody = {
                action: "remove_training_for_driver",
                user_seat_id: userSeatId,
                category_ids: categoryIds,
                access_secret: accessSecret,
            };

            const url = "/admin";

            // update state in Redux
            // const observations = _.cloneDeep(getState().admin.observationReview.selectedSession.observations);
            // let newObservations = _.cloneDeep(observations);
            // const index = _.findIndex(newObservations, obs => obs["id"] === observationID);
            // newObservations[index]["review_status"] = reviewStatus;
            // dispatch(updateObservations(newObservations));

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        console.log("debug removeTrainingForDriver traning removed", response);
                    }
                    if (callback) {
                        callback(response);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to remove training for this driver", error);
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
            next();
        },
    };
}

export function getTrainingCategoryData(session_ids, category_id, callback) {
    return {
        queue: GET_SESSION_DATA,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_dt_category_data",
                session_ids: session_ids,
                category_id: category_id,
            };

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    // console.log("debug get_dt_category_data", response.data);
                    // dispatch(setDrvierTrainingData(response.data))
                    if (callback) {
                        callback(response.data);
                    }
                    next();
                })
                .catch((error) => {
                    // console.log('debug Error fetching get_dt_category_data', error)
                    handleJsonPostError("Unable to fetch driver training data", error);
                    // dispatch(setDrvierTrainingData([]))
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}

export function getDriverTrainings(callback) {
    return {
        queue: GET_DRIVER_TRAINING,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_dt_driver_categories",
            };

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug get_dt_driver_categories", response.data);
                    dispatch(setAvailableTraineeTrainings(response.data));
                    // dispatch(setDrvierTrainingData(response.data))
                    if (callback) {
                        callback(response.data);
                    }
                    next();
                })
                .catch((error) => {
                    console.log("debug Error fetching get_dt_driver_categories", error);
                    handleJsonPostError("Unable to fetch driver training data", error);
                    dispatch(setAvailableTraineeTrainings([]));
                    // dispatch(setDrvierTrainingData([]))
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}

export function updateTrainingProgress(newProgressList, callback) {
    return {
        queue: SET_PROGRESS_COMPLETED_DATA,
        callback: (next, dispatch, getState) => {
            const categoryID = getState().driverTraining.trainee.selectedTraineeCategoryData.category_id || null;
            const sessionID = getState().driverTraining.trainee.selectedSession.session_id || null;

            let postBody = {
                action: "update_training_progress",
                category_id: categoryID,
                session_id: sessionID,
                progress_data: newProgressList,
            };

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug update_training_progress", response);
                    if (response) {
                        dispatch(updateSelectedCompletedAnnotationsList(newProgressList));
                    }
                    if (callback) {
                        callback(response);
                    }
                    next();
                })
                .catch((error) => {
                    console.log("debug Error fetching update_training_progress", error);
                    handleJsonPostError("Unable to upodate training progress data", error);
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}
