import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAbacus, faBurn, faTrafficLightGo } from "@fortawesome/pro-solid-svg-icons";
import { ReactComponent as BallastBagSvg } from "../../icons/ballast-bag-plain.svg";
import { ReactComponent as ScrapRailSvg } from "../../icons/scrap-rail-plain.svg";
import { ReactComponent as BridgeSvg } from "../../icons/bridge-plain.svg";
import { ReactComponent as GraffitiSvg } from "../../icons/graffiti-plain.svg";
import { ReactComponent as SpeedboardSvg } from "../../icons/speedboard-plain.svg";
import { ReactComponent as SnowploughSvg } from "../../icons/snowplough-plain.svg";
import { ReactComponent as AnimalSvg } from "../../icons/animal-plain.svg";
import { Button } from "antd";
import limitedClearance from "../../icons/limited-clearance.png";
import damagedTroughing from "../../icons/damaged-troughing.png";
import wetBeds from "../../icons/wetbed.png";

const HazardInterface = ({ countClick, totalCount, clickCounter, resetClicked, submitClicked }) => {
    return (
        <div className="HazardPerception__Interface">
            <div className="HazardPerception__Interface__Header">
                <div className="HazardPerception__Interface__Header-Counter">
                    <FontAwesomeIcon icon={faAbacus} />
                    <span>Total: {totalCount}</span>
                </div>
            </div>
            <div className="HazardPerception__Interface__Inner">
                <div className="HazardPerception__Interface__Inner-Row">
                    <div
                        className="HazardPerception__Interface__Item ballast"
                        onTouchStart={() => countClick("ballastBag")}>
                        <div className="itemCount">
                            <span>{clickCounter["ballastBag"] ? clickCounter["ballastBag"] : 0}</span>
                        </div>
                        <BallastBagSvg />
                        <span>Ballast Bag</span>
                    </div>
                    <div
                        className="HazardPerception__Interface__Item speedboard"
                        onTouchStart={() => countClick("limitedClearance")}>
                        <div className="itemCount">
                            <span>{clickCounter["limitedClearance"] ? clickCounter["limitedClearance"] : 0}</span>
                        </div>
                        <img
                            src={limitedClearance}
                            alt="limited clerance"
                            height={40}
                            width={40}
                        />
                        <span>Limited Clearance</span>
                    </div>
                </div>
                <div className="HazardPerception__Interface__Inner-Row">
                    <div
                        className="HazardPerception__Interface__Item scraprail"
                        onTouchStart={() => countClick("scrapRail")}>
                        <div className="itemCount">
                            <span>{clickCounter["scrapRail"] ? clickCounter["scrapRail"] : 0}</span>
                        </div>
                        <ScrapRailSvg />
                        <span>Scrap Rail</span>
                    </div>
                    <div
                        className="HazardPerception__Interface__Item bridge"
                        onTouchStart={() => countClick("damagedTroughing")}>
                        <div className="itemCount">
                            <span>{clickCounter["damagedTroughing"] ? clickCounter["damagedTroughing"] : 0}</span>
                        </div>
                        <img
                            src={damagedTroughing}
                            alt="limited clerance"
                            height={40}
                            width={40}
                        />
                        <span>Damaged Troughing</span>
                    </div>
                </div>
                <div className="HazardPerception__Interface__Inner-Row">
                    <div
                        className="HazardPerception__Interface__Item graffiti"
                        onTouchStart={() => countClick("wetBeds")}>
                        <div className="itemCount">
                            <span>{clickCounter["wetBeds"] ? clickCounter["wetBeds"] : 0}</span>
                        </div>
                        <img
                            src={wetBeds}
                            alt="limited clerance"
                            height={40}
                            width={40}
                        />
                        <span>Wet Beds / Voiding</span>
                    </div>
                    <div
                        className="HazardPerception__Interface__Item signal"
                        onTouchStart={() => countClick("signal")}>
                        <div className="itemCount">
                            <span>{clickCounter["signal"] ? clickCounter["signal"] : 0}</span>
                        </div>
                        <FontAwesomeIcon icon={faTrafficLightGo} />
                        <span>Signals</span>
                    </div>
                </div>

                <div className="HazardPerception__Interface__Inner-Row">
                    <Button
                        className="smallButton reset"
                        onClick={resetClicked}>
                        Reset
                    </Button>
                    <Button
                        className="smallButton submit"
                        onClick={submitClicked}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HazardInterface;
