import "tippy.js/dist/border.css";
import "tippy.js/dist/svg-arrow.css";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    toggleInspectionSnapshot,
    setDisplayExtraLayers,
    toggleInspectionAnnotationMode,
    toggleDetectionsOpen,
    toggleSettingsOpen,
    flipInspectionRails,
    setIsMagnifyToggled,
    setDirectionArrow,
    toggleAutoDetailCameraOption,
    toggleInspectionDetectionsExport,
    resetInspectionDetectionsExportIndexRange,
} from "redux/actions";
import {
    faCamera,
    faMapPin,
    faObjectUngroup,
    faBars,
    faCog,
    faWindowRestore,
    faSearch,
    faArrowsAltV,
    faBox,
    faBug,
    faChevronLeft,
    faArrowsAltH,
    faFilm,
    faBrain,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowFromBottom, faExchange, faSearchPlus } from "@fortawesome/pro-solid-svg-icons";
import { getAbsoluteTimestamp, binarySearch } from "components/util/PlaylistUtils";
import { ReactComponent as DetailRailIcon } from "./RailButtons/DetailRailIcon.svg";
import _, { set } from "lodash";
import RailInspectToolbarWidgets from "./RailInspectToolbarWidgets";
import { calculateRouteCoordinatesForLocation } from "components/util/Geometry";
import ELRMileAndChain from "../../util/ELRMileAndChain";
import { notification } from "antd";
import RailCompareModal from "./RailCompareModal";
import Tippy from "@tippyjs/react";
import { roundArrow } from "tippy.js";
import "tippy.js/dist/border.css";
import "tippy.js/dist/svg-arrow.css";

const magnifyToggledSelector = (state) => state.railInspection.magnifyToggled;
const inspectionSnapshotOpenSelector = (state) => state.railInspection.snapshot.open;
const railImageConotificationctSelector = (state) => state.railInspection.railInspectionImageConfig;
const displayExtraLayersSelector = (state) => state.railInspection.displayExtraLayers;
const annotateModeEnabledSelector = (state) => state.railInspection.annotations.modeEnabled;
const detectionsWindowOpenSelector = (state) => state.railInspection.detections.windowOpen;
const settingsOpenSelector = (state) => state.railInspection.settingsOpen;
const userRoleSelector = (state) => state.userDetails.userConfig.super_admin;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const timestampSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
};
const autoScrollActiveSelector = (state) => state.railInspection.autoScrollActive;
const selectedRailImageIndexSelector = (state) => state.railInspection.selectedRailInspectionImage.index;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const viewsSelector = (state) => state.views;
const dashboardsSelector = (state) => state.dashboards;
const userDetailsSelector = (state) => state.userDetails;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const videolessTimestampSelector = (state) => {
    const currentIndex = selectedRailImageIndexSelector(state);
    const railImages = state.railInspection.railInspectionImages.data;

    let timestamp = 0;
    if (railImages[currentIndex]) {
        const currentImage = railImages[currentIndex];
        timestamp = currentImage.timestamp;
    }
    return timestamp;
};
const faultsSelector = (state) => state.railInspection.sperryFaults.faults;
const detectionsExportOpenSelector = (state) => state.railInspection.detectionsExportOpen;

const ToolTip = ({ text, children, active }) => {
    const [show, setShow] = useState(false);
    const childWithProps = React.cloneElement(children, {
        onMouseEnter: () => setShow(true),
        onMouseLeave: () => setShow(false),
    });

    if (!active) {
        return children;
    }
    return (
        <div className="Tooltip">
            {childWithProps}
            <span className={`text ${!show ? "hidden" : null}`}>
                <div className="arrow"></div>
                {text}
            </span>
        </div>
    );
};

const RailInspectToolbar = ({
    toggleMarkerTool,
    markerToolActive,
    toggleFindModalVisibility,
    videoless,
    toggleArchiveInspectionSessionModal,
    toggleRestoreArchivedInspectionSessionModal,
    showReportProblemDialog,
    navbarCollapsed,
    setNavbarCollapsed,
    secondaryRailImages,
    clearSecondarySession,
    secondaryRailConfig,
    setSecondaryAlignment,
    setSecondaryAlignmentAdjustment,
    toggleManualAlignmentTool,
    manualAlignmentToolActive,
    secondaryRailImageStatus,
    loadSecondarySession,
    toggleSourceModal,
    sourceModalOpen,
    assetNavigationWindowOpen,
    setAssetNavigationWindowOpen,
    setBookmarksAndAnnotationsWindowOpen,
    bookmarksAndAnnotationsWindowOpen,
    sperrySuspectNavigationWindowOpen,
    setSperrySuspectNavigationWindowOpen,
}) => {
    const [collapsed, setCollapsed] = useState(false);
    const [buttonsCollapsed, setButtonsCollapsed] = useState(false);
    const [widgetsVisible, setWidgetsVisible] = useState(false);

    const toolBarRef = useRef(null);
    const prevWidthRef = useRef(null);
    const dispatch = useDispatch();
    const sperryFaults = useSelector(faultsSelector);
    const [moreImagesAvailable, setMoreImagesAvailable] = useState(false);
    const [compareModalOpen, setCompareModalOpen] = useState(false);

    const inspectionSnapshotOpen = useSelector(inspectionSnapshotOpenSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const displayExtraLayers = useSelector(displayExtraLayersSelector);
    const annotateModeEnabled = useSelector(annotateModeEnabledSelector);
    const detectionsOpen = useSelector(detectionsWindowOpenSelector);
    const settingsOpen = useSelector(settingsOpenSelector);
    const userIsSuperAdmin = useSelector(userRoleSelector);
    const session = useSelector(sessionSelector);
    const railsFlippedValue = useSelector(railsFlippedSelector);
    const timestamp = useSelector(timestampSelector);
    const videolessTimestamp = useSelector(videolessTimestampSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const views = useSelector(viewsSelector);
    const dashboards = useSelector(dashboardsSelector);
    const userDetails = useSelector(userDetailsSelector);
    const dashboardID = userDetails.dashboardAccessID;
    const railDataObject = useSelector(railDataObjectSelector);
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const selectedRailImageIndex = useSelector(selectedRailImageIndexSelector);
    const autoScrollActive = useSelector(autoScrollActiveSelector);
    const detectionsExportOpen = useSelector(detectionsExportOpenSelector);

    const magnifyToggled = useSelector(magnifyToggledSelector);

    const [selectedComparingSessionID, setSelectedComparingSessionID] = useState(null);

    const openCompareSession = (sessionID) => {
        console.log("debug selecting session ID'", sessionID);
        setSelectedComparingSessionID(sessionID);
        loadSecondarySession(sessionID);
        setCompareModalOpen(false);
    };

    const clearSelectedSecondarySession = (value) => {
        setSelectedComparingSessionID(null);
        clearSecondarySession(value);
    };

    const handleToggleMagnify = useCallback(() => {
        dispatch(setIsMagnifyToggled(!magnifyToggled));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [magnifyToggled]);

    const toggleCompareModal = useCallback(() => {
        setCompareModalOpen(!compareModalOpen);
    }, [compareModalOpen]);

    const calculatedELR = useMemo(() => {
        let timestampToUse = timestamp;

        if (videoless) {
            timestampToUse = videolessTimestamp / 1000;
        }
        if (timestampToUse && routeLocationData) {
            const calculatedELR = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);
            if (calculatedELR) {
                return {
                    string: calculatedELR.to_string(elrUnits, datumOffsets),
                    route: calculatedELR.route,
                    track: calculatedELR.track,
                    position: calculatedELR.position,
                };
            }
        }
    }, [timestamp, routeLocationData, elrUnits, datumOffsets, videoless, videolessTimestamp, routeSystemID]);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject; //Find first device in data - will need changing to support multiple devices
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const railImage = useMemo(() => {
        if (railImages && railImages.length) {
            return railImages[selectedRailImageIndex];
        }
    }, [railImages, selectedRailImageIndex]);

    const mwvData = useMemo(() => {
        if (railImages && railImage) {
            let mwv = railImage["mwv"];
            if (mwv && mwv.elr && mwv.mile && mwv.trid && mwv.yard) {
                mwv = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: mwv.elr,
                        MILE: mwv.mile,
                        TRACK: mwv.trid,
                        YARDS: mwv.yard,
                    },
                    datumOffsets,
                    true,
                );
                return {
                    string: mwv.to_string(elrUnits, datumOffsets, true),
                    route: mwv.route,
                    track: mwv.track,
                    position: mwv.position,
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }, [railImage, railImages, datumOffsets, elrUnits]);

    const autoAlign = useCallback(() => {
        //TODO: This should take the current ELR track, position etc and find the index of that location in the secondary
        // rail data (which might require fetching the session information)
        // Once done, the secondary alignment offset can be set to the found array index minus the currently selected array index,
        // which should cause the two rail inspection image with the same ELR to be aligned at the current index.
        // A user control should then allow fine-grained adjustment.

        console.log("Auto align clicked");

        // if inspection don't have images, clear secondary rail data and display error message to the user.
        if (!_.get(secondaryRailImages, "data", []).length) {
            clearSecondarySession(true);
            return;
        }

        let timestampToUse = timestamp;
        if (videoless) {
            timestampToUse = videolessTimestamp / 1000;
        }
        let nextELR = calculateRouteCoordinatesForLocation(timestampToUse + 1, routeLocationData, routeSystemID);

        const travellingUpstream = nextELR.position > calculatedELR.position;
        const elr = mwvData ? mwvData.route : calculatedELR.route;
        const position = mwvData ? mwvData.position : calculatedELR.position;
        const trackID = mwvData ? mwvData.track : calculatedELR.track;

        console.log("Initial location: ", elr, position, trackID, travellingUpstream);

        const hasMwvData = _.get(secondaryRailImages, ["data", 0, "mwv", "elr"]);
        let imageLocations = _.clone(secondaryRailImages.imageLocations);

        // if compering session has mwv data use it instead.
        if (hasMwvData) {
            let newLocations = {};
            _.map(secondaryRailImages.data, (data) => {
                if (data.mwv) {
                    newLocations[data.timestamp] = [data.mwv.elr, parseInt(data.mwv.mile) * 80 + parseFloat(data.mwv.yard) / 22, data.mwv.trid];
                }
            });

            imageLocations = newLocations;
        }

        const timestamps = Object.keys(imageLocations);
        timestamps.sort();

        let beforeTimestamp = null;
        let afterTimestamp = null;

        let beforeLocation = null;
        let afterLocation = null;

        const alignment_offset = _.get(secondaryRailConfig, "alignmentOffset", 0);

        for (let i = 1; i < timestamps.length; i++) {
            const timestamp = timestamps[i];
            const lastTimestamp = timestamps[i - 1];

            const location = imageLocations[timestamp];
            const lastLocation = imageLocations[lastTimestamp];

            if (location[0] === elr && location[2] === trackID && lastLocation[0] === elr && lastLocation[2] === trackID) {
                const secondaryTravellingUpstream = location[1] > lastLocation[1];
                const invertAlignmentOffset = secondaryTravellingUpstream !== secondaryRailConfig.imageryIsBackward;
                const position_offset = (alignment_offset / ELRMileAndChain.meters_per_chain) * (invertAlignmentOffset ? -1 : 1);
                const lastPosition = lastLocation[1] + position_offset;
                const currentPosition = location[1] + position_offset;
                if ((lastPosition > position && currentPosition <= position) || (lastPosition < position && currentPosition >= position)) {
                    beforeTimestamp = parseInt(lastTimestamp);
                    afterTimestamp = parseInt(timestamp);

                    beforeLocation = lastLocation;
                    afterLocation = location;
                    break;
                }
            }
        }

        if (!beforeLocation || !afterLocation) {
            notification.warning({
                message: "Error finding matching position in compared session",
            });
            return;
        } else {
            console.log(
                "Found timestamps where secondary is at or overlapping current position: ",
                beforeTimestamp,
                beforeLocation,
                afterTimestamp,
                afterLocation,
            );
        }

        const secondaryTravellingUpstream = afterLocation[1] > beforeLocation[1];
        const invertAlignmentOffset = secondaryTravellingUpstream !== secondaryRailConfig.imageryIsBackward;

        console.log("Is secondary travelling upstream: ", secondaryTravellingUpstream);

        const position_offset = (alignment_offset / ELRMileAndChain.meters_per_chain) * (invertAlignmentOffset ? -1 : 1);
        const beforePosition = beforeLocation[1] + position_offset;
        const afterPosition = afterLocation[1] + position_offset;

        const posDifference = position - beforePosition;
        const interpolatePercent = posDifference / (afterPosition - beforePosition);
        const foundTimestamp = beforeTimestamp + (afterTimestamp - beforeTimestamp) * interpolatePercent;
        console.log("Interpolated timestamp in secondary rail imagery is ", foundTimestamp, interpolatePercent);

        let railIndex = binarySearch(parseInt(foundTimestamp), secondaryRailImages.data, (image) => image.timestamp);
        let railImageTimestamp = secondaryRailImages.data[railIndex].timestamp;
        let nextRailImageTimestamp = secondaryRailImages.data[railIndex + 1].timestamp;
        console.log("Image index matching interpolated timestamp is ", railIndex, "with timestamp", railImageTimestamp, nextRailImageTimestamp);
        const timestampInterpolation = Math.round((10 * (foundTimestamp - railImageTimestamp)) / (nextRailImageTimestamp - railImageTimestamp)) / 10;
        console.log("Currently selected rail image index is ", selectedRailImageIndex);

        let secondaryAlignment = {
            adjustment: timestampInterpolation,
            primaryIndex: selectedRailImageIndex,
            primaryIsUpstream: travellingUpstream,
            secondaryIndex: railIndex,
            secondaryIsUpstream: secondaryTravellingUpstream,
        };
        setSecondaryAlignment(secondaryAlignment);
        setSecondaryAlignmentAdjustment(timestampInterpolation);

        console.log("Secondary alignment: ", secondaryAlignment);
    }, [
        calculatedELR,
        secondaryRailImages,
        secondaryRailConfig,
        selectedRailImageIndex,
        setSecondaryAlignment,
        mwvData,
        timestamp,
        routeLocationData,
        videoless,
        videolessTimestamp,
        setSecondaryAlignmentAdjustment,
    ]);

    const allowRailFlip = useMemo(() => {
        return railImageConfigObject.horizontal;
    }, [railImageConfigObject.horizontal]);

    const toggleSnapshot = () => {
        dispatch(toggleInspectionSnapshot(!inspectionSnapshotOpen));
    };

    const hasDetailImages = useMemo(() => {
        return _.get(railImageConfigObject, "inspection_images", false)
            ? !!railImageConfigObject.inspection_images.filter((config) => config.showInModal).length
            : false;
    }, [railImageConfigObject]);

    const toggleAnnotationMode = () => {
        dispatch(toggleInspectionAnnotationMode(!annotateModeEnabled));
    };

    const toggleDetections = () => {
        if (detectionsExportOpen) {
            dispatch(toggleInspectionDetectionsExport());
            dispatch(resetInspectionDetectionsExportIndexRange());
        }
        setAssetNavigationWindowOpen(!assetNavigationWindowOpen);
        dispatch(toggleDetectionsOpen());
    };

    const toggleContent = () => {
        setBookmarksAndAnnotationsWindowOpen(!bookmarksAndAnnotationsWindowOpen);
        dispatch(toggleDetectionsOpen());
    };

    const toggleSperrySuspects = () => {
        setSperrySuspectNavigationWindowOpen(!sperrySuspectNavigationWindowOpen);
        // dispatch(toggleDetectionsOpen());
    };

    const toggleSettings = () => {
        dispatch(toggleSettingsOpen());
    };

    useEffect(() => {
        const handleTransitionEnd = () => {
            const currentWidth = toolBarRef.current.offsetWidth;
            if (prevWidthRef.current < currentWidth) {
                setButtonsCollapsed(false);
            }
            prevWidthRef.current = currentWidth;
        };

        const currentElement = toolBarRef.current;
        if (currentElement) {
            currentElement.addEventListener("transitionend", handleTransitionEnd);
            prevWidthRef.current = currentElement.offsetWidth;
        }

        return () => {
            if (!currentElement) return;
            currentElement.removeEventListener("transitionend", handleTransitionEnd);
        };
    }, []);

    if (!window.location.href.includes("rail-inspect")) {
        return null;
    }

    return (
        <>
            <div className="ToolbarMainWrapper">
                <div
                    className={!collapsed ? "Toolbar" : "Toolbar collapsed"}
                    ref={toolBarRef}>
                    <div className="ToolGroup">
                        {hasDetailImages ? (
                            <Tippy
                                content={"Show detail rail images"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        dispatch(setDisplayExtraLayers(!displayExtraLayers));
                                        dispatch(setDirectionArrow(true));
                                        dispatch(toggleAutoDetailCameraOption(false));
                                        setMoreImagesAvailable(false);
                                    }}
                                    className={
                                        !collapsed
                                            ? displayExtraLayers
                                                ? "ToolbarButton open active"
                                                : "ToolbarButton open"
                                            : displayExtraLayers
                                              ? "ToolbarButton active"
                                              : "ToolbarButton"
                                    }>
                                    <div className="ButtonIcon">
                                        <DetailRailIcon />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Detail rail images</span>
                                </button>
                            </Tippy>
                        ) : null}

                        <Tippy
                            content={"Take a snapshot"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={toggleSnapshot}
                                className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faCamera} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Take a snapshot</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Place a bookmark"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleMarkerTool();
                                }}
                                className={
                                    !collapsed
                                        ? markerToolActive
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : markerToolActive
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faMapPin} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Place a bookmark</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Annotate"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleAnnotationMode(!annotateModeEnabled);
                                }}
                                className={
                                    !collapsed
                                        ? annotateModeEnabled
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : annotateModeEnabled
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faObjectUngroup} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Annotate</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Content"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleContent();
                                }}
                                className={
                                    !collapsed
                                        ? bookmarksAndAnnotationsWindowOpen
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : bookmarksAndAnnotationsWindowOpen
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Content</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Detections"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleDetections();
                                }}
                                className={
                                    !collapsed
                                        ? assetNavigationWindowOpen
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : assetNavigationWindowOpen
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faBrain} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Detections</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Image adjustments"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleSettings();
                                }}
                                className={
                                    !collapsed
                                        ? settingsOpen
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : settingsOpen
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faCog} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Image adjustment</span>
                            </button>
                        </Tippy>
                        <Tippy
                            content={
                                <RailInspectToolbarWidgets
                                    visible={widgetsVisible}
                                    collapsed={collapsed}
                                />
                            }
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            interactive={true}
                            appendTo={document.body}>
                            <button
                                className={
                                    !collapsed
                                        ? widgetsVisible
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : widgetsVisible
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faWindowRestore} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Widgets</span>
                            </button>
                        </Tippy>
                        <Tippy
                            content={"Search for a session"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleFindModalVisibility(true);
                                }}
                                className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon
                                        className="mapSearchButton-icon"
                                        icon={faSearch}
                                    />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Search for location</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"More cameras"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleSourceModal(!sourceModalOpen);
                                }}
                                className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon
                                        className="mapSearchButton-icon"
                                        icon={faFilm}
                                    />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>More cameras</span>
                            </button>
                        </Tippy>

                        <Tippy
                            content={"Sperry suspects"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    toggleSperrySuspects();
                                }}
                                className={
                                    !collapsed
                                        ? sperrySuspectNavigationWindowOpen
                                            ? "ToolbarButton open active"
                                            : "ToolbarButton open"
                                        : sperrySuspectNavigationWindowOpen
                                          ? "ToolbarButton active"
                                          : "ToolbarButton"
                                }
                                disabled={manualAlignmentToolActive || autoScrollActive}>
                                <div className="ButtonIcon">
                                    <DetailRailIcon />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Sperry viewer</span>
                            </button>
                        </Tippy>

                        <ToolTip
                            text="Magnifying glass"
                            active={collapsed}>
                            <button
                                onClick={handleToggleMagnify}
                                className={`ToolbarButton ${magnifyToggled ? "active" : ""} ${!collapsed ? "open" : ""}`}>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon
                                        className="mapSearchButton-icon"
                                        icon={faSearchPlus}
                                    />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Magnifying glass</span>
                            </button>
                        </ToolTip>
                        {!allowRailFlip && currentDashboard && currentDashboard.isBeta && (mwvData || calculatedELR) && (
                            <Tippy
                                content={"Compare sessions"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        toggleCompareModal();
                                    }}
                                    className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}
                                    disabled={manualAlignmentToolActive || autoScrollActive}>
                                    <div className="ButtonIcon">
                                        <FontAwesomeIcon
                                            className="mapSearchButton-icon"
                                            icon={faExchange}
                                        />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Compare sessions</span>
                                </button>
                            </Tippy>
                        )}
                        {secondaryRailImageStatus.loaded ? (
                            <Tippy
                                content={"Auto align"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        autoAlign();
                                    }}
                                    className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                    <div className="ButtonIcon">
                                        <FontAwesomeIcon icon={faArrowsAltV} />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Auto align</span>
                                </button>
                            </Tippy>
                        ) : null}
                        {secondaryRailImageStatus.loaded ? (
                            <Tippy
                                content={"Manual align"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        toggleManualAlignmentTool();
                                    }}
                                    className={
                                        !collapsed
                                            ? manualAlignmentToolActive
                                                ? "ToolbarButton open active"
                                                : "ToolbarButton open"
                                            : manualAlignmentToolActive
                                              ? "ToolbarButton active"
                                              : "ToolbarButton"
                                    }>
                                    <div className="ButtonIcon">
                                        <FontAwesomeIcon icon={faArrowFromBottom} />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Manual align</span>
                                </button>
                            </Tippy>
                        ) : null}
                        {videoless && userIsSuperAdmin ? (
                            <Tippy
                                content={session.inspection_archive_status === 0 ? "Archive session" : "Restore session"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        if (session.inspection_archive_status === 0) {
                                            toggleArchiveInspectionSessionModal();
                                        } else if (session.inspection_archive_status === 1) {
                                            toggleRestoreArchivedInspectionSessionModal();
                                        }
                                    }}
                                    className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                    <div className="ButtonIcon">
                                        <FontAwesomeIcon icon={faBox} />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>
                                        {session.inspection_archive_status === 0 ? "Archive session" : "Restore session"}
                                    </span>
                                </button>
                            </Tippy>
                        ) : null}
                        {allowRailFlip && (
                            <Tippy
                                content={"Flip rails"}
                                placement="right"
                                theme="inspection-ui"
                                arrow={roundArrow + roundArrow}
                                disabled={!collapsed}>
                                <button
                                    onClick={() => {
                                        dispatch(flipInspectionRails(!railsFlippedValue));
                                    }}
                                    className={
                                        !collapsed
                                            ? railsFlippedValue
                                                ? "ToolbarButton open active"
                                                : "ToolbarButton open"
                                            : railsFlippedValue
                                              ? "ToolbarButton active"
                                              : "ToolbarButton"
                                    }>
                                    <div className="ButtonIcon">
                                        <FontAwesomeIcon icon={faArrowsAltH} />
                                    </div>
                                    <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Flip Rails</span>
                                </button>
                            </Tippy>
                        )}
                    </div>
                    <div className="ToolGroup">
                        <Tippy
                            content={"Report a bug"}
                            placement="right"
                            theme="inspection-ui"
                            arrow={roundArrow + roundArrow}
                            disabled={!collapsed}>
                            <button
                                onClick={() => {
                                    showReportProblemDialog();
                                }}
                                className={!collapsed ? "ToolbarButton open" : "ToolbarButton"}>
                                <div className="ButtonIcon">
                                    <FontAwesomeIcon icon={faBug} />
                                </div>
                                <span className={buttonsCollapsed ? "ButtonText closed" : "ButtonText open"}>Report a bug</span>
                            </button>
                        </Tippy>
                    </div>
                </div>
                <div className="CollapseButtonWrapper">
                    <div
                        className="CollapseButton"
                        onClick={() => {
                            setCollapsed(!collapsed);
                            setButtonsCollapsed(!buttonsCollapsed);
                            setNavbarCollapsed(!navbarCollapsed);
                        }}>
                        <FontAwesomeIcon
                            className={!collapsed ? "close" : "open"}
                            icon={faChevronLeft}
                        />
                    </div>
                </div>
            </div>
            {compareModalOpen && (
                <RailCompareModal
                    toggleModal={toggleCompareModal}
                    visible={compareModalOpen}
                    calculatedELR={mwvData || calculatedELR}
                    selectedComparingSession={secondaryRailImageStatus.sessionID}
                    clearSecondarySession={clearSelectedSecondarySession}
                    selectSession={openCompareSession}
                />
            )}
        </>
    );
};

export default RailInspectToolbar;
