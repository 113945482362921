import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentInspectionMarkup, toggleAnnotationVisibility } from "../../../../redux/actions/index";
import { binarySearch } from "../../../util/PlaylistUtils";
import _ from "lodash";
import { faChevronLeft, faChevronRight, faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OBCSpinner from "../../../util/OBC";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";
import { useRef } from "react";

const annotationsSelector = (state) => state.railInspection.annotations.data;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railConfigSelector = (state) => state.railInspection.railInspectionImageConfig;
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const allAnnotationsFetchedSelector = (state) => state.railInspection.annotations.allAnnotationsFetched;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const csrfTokenSelector = (state) => state.csrfToken;
const annotationsVisibleSelector = (state) => state.railInspection.annotations.visibility;

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const AnnotationNavigation = () => {
    const annotations = useSelector(annotationsSelector);
    const railData = useSelector(railDataObjectSelector);
    const railConfig = useSelector(railConfigSelector);
    const selectedRailInspectionImage = useSelector(selectedRailInspectionImageSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const allAnnotationsFetched = useSelector(allAnnotationsFetchedSelector);
    const currentSession = useSelector(sessionSelector);
    const railsFlipped = useSelector(railsFlippedSelector);
    const railImageConfig = useSelector(railImageConfigObjectSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const annotationsVisible = useSelector(annotationsVisibleSelector);

    const dispatch = useDispatch();

    const imageRef = useRef(null);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const rawDimensions = useRef({ width: 600, height: 400 });

    const [lastNavigatedIndex, setLastNavigatedIndex] = useState(-1);

    const horizontal = useMemo(() => {
        return !!railConfig.horizontal;
    }, [railConfig.horizontal]);

    const cameraAtRear = useMemo(() => {
        return _.get(railImageConfig, ["video_orientation"]) === "Rear";
    }, [railImageConfig]);

    const sessionIsBackward = useMemo(() => {
        const sessionTags = _.get(currentSession, ["tags"], []);
        const backwardSession = (_.indexOf(sessionTags, "Backward") !== -1) !== cameraAtRear;
        return (backwardSession && !railsFlipped) || (!backwardSession && railsFlipped);
    }, [currentSession, cameraAtRear, railsFlipped]);

    const imagesWithOffsettedTimestamps = useMemo(() => {
        return annotations.map((annotation) => {
            let imageIndex = _.findIndex(railData, (img) => img.timestamp.toString() === annotation.image_timestamp.toString());

            let offsetParam;
            if (sessionIsBackward) {
                offsetParam = "offset_y_backward";
            } else {
                offsetParam = "offset_y_forward";
            }
            const annotationOffset = _.get(railImageConfig, ["inspection_images", annotation.image_source, offsetParam], 0);
            const offsettedIndex = imageIndex + Math.round(annotationOffset);

            const indexToUse = Math.min(Math.max(offsettedIndex, 0), railData.length);
            return {
                ...annotation,
                image_timestamp: railData[indexToUse].timestamp,
            };
        });
    }, [annotations, railData, railImageConfig, sessionIsBackward]);

    const filteredAnnotations = useMemo(() => {
        let filtered = null;
        if (imagesWithOffsettedTimestamps && imagesWithOffsettedTimestamps.length) {
            filtered = _.orderBy(imagesWithOffsettedTimestamps, "image_timestamp");
        }
        return filtered;
    }, [imagesWithOffsettedTimestamps]);

    const currentImageHasAnnotation = useMemo(() => {
        const index = _.findIndex(filteredAnnotations, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
        return index > -1;
    }, [filteredAnnotations, selectedRailInspectionImage]);

    const closestToLeftIndex = useMemo(() => {
        if (!selectedRailInspectionImage || !filteredAnnotations || !filteredAnnotations.length) {
            return null;
        }
        return binarySearch(selectedRailInspectionImage.timestamp, filteredAnnotations, (det) => det.image_timestamp);
    }, [filteredAnnotations, selectedRailInspectionImage]);

    const closestToRightIndex = useMemo(() => {
        if (filteredAnnotations && closestToLeftIndex > -1) {
            const closestToLeftAnnotation = filteredAnnotations[closestToLeftIndex];
            if (closestToLeftAnnotation && closestToLeftAnnotation.image_timestamp > selectedRailInspectionImage.timestamp) {
                return closestToLeftIndex;
            } else {
                return closestToLeftIndex + 1;
            }
        } else {
            return null;
        }
    }, [closestToLeftIndex, filteredAnnotations, selectedRailInspectionImage]);

    const distanceToRight = useMemo(() => {
        if (
            filteredAnnotations &&
            railData &&
            closestToLeftIndex > -1 &&
            closestToRightIndex &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp
        ) {
            if (closestToRightIndex > filteredAnnotations.length - 1) {
                return null;
            }
            const closestToRight = filteredAnnotations[closestToRightIndex];
            const rightIndex = _.findIndex(railData, (i) => i.timestamp === closestToRight.image_timestamp);

            const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            if (rightIndex && currentIndex > -1) {
                return rightIndex - currentIndex;
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredAnnotations, railData, selectedRailInspectionImage, closestToRightIndex]);

    const distanceToLeft = useMemo(() => {
        if (filteredAnnotations && railData && closestToLeftIndex > -1 && selectedRailInspectionImage && selectedRailInspectionImage.timestamp) {
            const closestToLeft = filteredAnnotations[closestToLeftIndex];
            if (closestToLeft) {
                const leftIndex = _.findIndex(railData, (i) => i.timestamp === closestToLeft.image_timestamp);
                const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

                if (leftIndex && currentIndex > -1) {
                    return currentIndex - leftIndex;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredAnnotations, railData, selectedRailInspectionImage]);

    const closestIndex = useMemo(() => {
        if (filteredAnnotations && railData && selectedRailInspectionImage) {
            if (currentImageHasAnnotation) {
                return _.findIndex(filteredAnnotations, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            } else {
                if (!distanceToLeft) {
                    return closestToRightIndex;
                } else if (!distanceToRight) {
                    return closestToLeftIndex;
                } else if (distanceToLeft < distanceToRight) {
                    return closestToLeftIndex;
                } else {
                    return closestToRightIndex;
                }
            }
        } else {
            return null;
        }
    }, [
        closestToLeftIndex,
        currentImageHasAnnotation,
        filteredAnnotations,
        railData,
        selectedRailInspectionImage,
        closestToRightIndex,
        distanceToLeft,
        distanceToRight,
    ]);

    const currentAnnotation = useMemo(() => {
        if (closestIndex > -1 && filteredAnnotations) {
            const closest = filteredAnnotations[closestIndex];
            return closest;
        } else {
            return null;
        }
    }, [filteredAnnotations, closestIndex]);

    const navigate = useCallback(
        (index) => {
            if (filteredAnnotations && filteredAnnotations.length) {
                if (filteredAnnotations[index]) {
                    setLastNavigatedIndex(index);
                    dispatch(setCurrentInspectionMarkup("annotation", filteredAnnotations[index].id));
                }
            }
        },
        [dispatch, filteredAnnotations],
    );

    const goBack = useCallback(() => {
        if (currentImageHasAnnotation) {
            if (lastNavigatedIndex === closestToLeftIndex - 1) {
                navigate(closestToLeftIndex - 2);
            } else {
                navigate(closestToLeftIndex - 1);
            }
        } else {
            navigate(closestToLeftIndex);
        }
    }, [currentImageHasAnnotation, navigate, closestToLeftIndex, lastNavigatedIndex]);

    const goForward = useCallback(() => {
        navigate(closestToRightIndex);
    }, [navigate, closestToRightIndex]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === ",") {
                goBack();
            } else if (event.key === ".") {
                goForward();
            }
        },
        [goBack, goForward],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress, false);

        return () => {
            document.removeEventListener("keydown", handleKeyPress, false);
        };
    }, [handleKeyPress]);

    const annotationAuthor = useMemo(() => {
        if (currentAnnotation && currentAnnotation.user_name) {
            return <p className="author">{currentAnnotation.user_name}</p>;
        } else {
            return null;
        }
    }, [currentAnnotation]);
    const resizeImage = useCallback(() => {
        const containerWidth = imageRef.current.clientWidth;
        const containerHeight = imageRef.current.clientHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        const rawWidth = rawDimensions.current.width;
        const rawHeight = rawDimensions.current.height;
        const rawAspectRatio = rawWidth / rawHeight;

        let newWidth = 0;
        let newHeight = 0;

        if (containerWidth > rawWidth && containerHeight > rawHeight) {
            newWidth = rawWidth;
            newHeight = rawHeight;
        } else if (containerAspectRatio > rawAspectRatio) {
            newHeight = containerHeight;
            newWidth = containerHeight * rawAspectRatio;
        } else {
            newWidth = containerWidth;
            newHeight = containerWidth / rawAspectRatio;
        }

        setImageDimensions({ width: newWidth, height: newHeight });
    }, [rawDimensions]);

    useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement) return;

        const observer = new ResizeObserver(resizeImage);

        observer.observe(imageElement);

        return () => {
            observer.disconnect();
        };
    }, [resizeImage]);

    const image = useMemo(() => {
        if (!currentAnnotation) {
            return null;
        }
        if (railData && railData.length && railConfig.inspection_images && railConfig.inspection_images.length) {
            const deviceName = railConfig.inspection_images[currentAnnotation.image_source].source;
            const image = _.find(railData, ["timestamp", currentAnnotation.image_timestamp]);

            if (image) {
                let base_path = image.base_path;
                const new_base_path = base_path.replace("$DEVICE", `${deviceName}`);
                let imagePath = baseURL + new_base_path + "/" + image.timestamp + ".jpg";

                if (csrfToken) {
                    imagePath += `?csrf=${csrfToken}`;
                }
                let extraClass = "";
                if (horizontal) {
                    extraClass = "horizontal";
                }

                return (
                    <img
                        alt="Annotation Thumbnail"
                        className={extraClass}
                        src={imagePath}
                        crossOrigin={"anonymous"}
                        style={{ flex: 1 }}
                        onLoad={(e) => {
                            rawDimensions.current = { width: e.target.naturalWidth, height: e.target.naturalHeight };
                            resizeImage();
                        }}
                    />
                );
            }
        } else {
            return null;
        }
    }, [currentAnnotation, railConfig.inspection_images, railData, horizontal]);

    const annotationIndex = useMemo(() => {
        if (closestIndex > -1 && filteredAnnotations) {
            return (
                <div className="markerIndex">
                    <p>{closestIndex + 1}</p>
                    <span>of</span>
                    <p>{filteredAnnotations.length}</p>
                </div>
            );
        } else {
            return null;
        }
    }, [filteredAnnotations, closestIndex]);

    const navigationButtons = useMemo(() => {
        return (
            <div className="markerControls wide">
                <div
                    className="markerControls__Item"
                    onClick={goBack}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>Back</span>
                </div>
                {annotationIndex}
                <div
                    className="markerControls__Item"
                    onClick={goForward}>
                    <span>Next</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </div>
        );
    }, [annotationIndex, goBack, goForward]);

    const navigationLabel = useMemo(() => {
        if (!currentAnnotation) {
            return null;
        }
        let text = "";
        if (currentAnnotation.annotation_type === -1) {
            text = currentAnnotation.custom_label;
        } else if (currentAnnotation.annotation_type) {
            const annotationType = _.find(annotationTypes, (type) => parseInt(type.id) === parseInt(currentAnnotation.annotation_type));
            if (annotationType) {
                text = annotationType.type;
            }
        }

        return <p className="title">{text}</p>;
    }, [currentAnnotation, annotationTypes]);

    const handleVisibilityChange = useCallback(() => {
        dispatch(toggleAnnotationVisibility(!annotationsVisible));
    }, [annotationsVisible, dispatch]);

    const visibilityButton = useMemo(() => {
        let icon = faEye;
        if (!annotationsVisible) {
            icon = faEyeSlash;
        }

        return (
            <div className="inspectRail__Detections__Visibility">
                <div
                    className="inspection-default-button"
                    onClick={handleVisibilityChange}
                    style={{ width: 34, padding: 7, borderRadius: 3 }}>
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
        );
    }, [handleVisibilityChange, annotationsVisible]);

    const distanceToNextAnnotation = useMemo(() => {
        if (selectedRailInspectionImage && filteredAnnotations) {
            const index = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            let prevAnnotation;
            if (closestToLeftIndex > -1) {
                const closestDet = filteredAnnotations[closestToLeftIndex];

                prevAnnotation = closestDet;
                if (closestDet && closestDet.image_timestamp === selectedRailInspectionImage.timestamp) {
                    prevAnnotation = filteredAnnotations[closestToLeftIndex - 1];
                }
            }

            const nextAnnotation = filteredAnnotations[closestToRightIndex];
            let prevDistance = 0;
            let nextDistance = 0;

            if (prevAnnotation && closestToLeftIndex !== 0) {
                const prevAnnotationIndex = _.findIndex(railData, (i) => i.timestamp === prevAnnotation.image_timestamp);
                prevDistance = index - prevAnnotationIndex;
            }
            if (nextAnnotation) {
                const nextAnnotationIndex = _.findIndex(railData, (i) => i.timestamp === nextAnnotation.image_timestamp);
                nextDistance = nextAnnotationIndex - index;
            }

            if (prevAnnotation || nextAnnotation) {
                return (
                    <div className="inspectRail__Detections__Distance">
                        <div className="inspectRail__Detections__Distance-Inner">
                            {!!prevDistance && (
                                <>
                                    <p>Previous Annotation</p>
                                    <span>{prevDistance}m</span>
                                </>
                            )}
                        </div>
                        <div className="inspectRail__Detections__Distance-Inner next">
                            {!!nextDistance && (
                                <>
                                    <p>Next Annotation</p>
                                    <span>{nextDistance}m</span>
                                </>
                            )}
                        </div>
                    </div>
                );
            }
        }
    }, [filteredAnnotations, railData, selectedRailInspectionImage, closestToLeftIndex]);

    let content = (
        <div className="SchemaRuns__Spinner">
            <OBCSpinner
                size={70}
                speed={3}
                colorScheme="mono"
            />
        </div>
    );

    if (allAnnotationsFetched) {
        content = (
            <>
                <div className="inspectRail__Annotations__Item">
                    <div className="inspectRail__Annotations__Item__Header">
                        <div className="inspectRail__Detections__Header">
                            <h4>Closest Annotation</h4>
                            {visibilityButton}
                        </div>
                        {navigationLabel}
                        {annotationAuthor}
                    </div>
                    <div
                        className="inspectRail__Annotations__ItemImage"
                        ref={imageRef}>
                        {image && (
                            <div
                                className="inspectRail__Annotations__Image"
                                style={{ width: imageDimensions.width, height: imageDimensions.height }}>
                                {image}
                                {/* {imageDimensions.width.toString() + " " + imageDimensions.height.toString()} */}
                            </div>
                        )}
                    </div>
                    <div className="inspectRail__Annotations__NavContainer">
                        {distanceToNextAnnotation}
                        {navigationButtons}
                    </div>
                </div>
            </>
        );
    }

    if (allAnnotationsFetched && !annotations.length) {
        content = (
            <div className="inspectRail__Detections__Message">
                <span>No Annotations</span>
            </div>
        );
    }

    return <>{content}</>;
};

export default AnnotationNavigation;
