import signal_head from "../../../../../models/signal_head.glb";
import signal_head_red from "../../../../../models/signal_head_red.glb";
import signal_head_green from "../../../../../models/signal_head_green.glb";
import glow_sprite from "../../../../../models/glow.png";
import { useGLTF, useTexture } from "@react-three/drei";
import React, { useMemo, useRef } from "react";
import Meshes from "./Meshes";
import { Color, Quaternion, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";

const maxIncidenceAngle = 15 * (Math.PI / 180);
const incidenceOffset = maxIncidenceAngle - Math.PI;

function SignalHead(props) {
    const groupRef = useRef();
    const spriteRef = useRef();

    const model = useMemo(() => {
        if (props.lit === "red") {
            return signal_head_red;
        } else if (props.lit === "green") {
            return signal_head_green;
        } else {
            return signal_head;
        }
    }, [props.lit]);

    const lightTexture = useTexture(glow_sprite);

    const lightSprite = useMemo(() => {
        if (props.lit === "green") {
            return (
                <sprite position={[0, 0.72, 0.1]}>
                    <spriteMaterial
                        ref={spriteRef}
                        map={lightTexture}
                        color={"#00ff00"}
                    />
                </sprite>
            );
        } else if (props.lit === "red") {
            return (
                <sprite position={[0, 0.18, 0.1]}>
                    <spriteMaterial
                        ref={spriteRef}
                        map={lightTexture}
                        color={"#ff0000"}
                    />
                </sprite>
            );
        } else {
            return null;
        }
    }, [props.lit, spriteRef, lightTexture]);

    const scene = useGLTF(model);

    const geometries = useMemo(() => [scene.nodes.LEDS.geometry, scene.nodes.Back_Box.geometry, scene.nodes.Signal.geometry], [scene]);

    const ledMaterial = useMemo(() => {
        const material = scene.materials["Lenses__lenses_jpg"].clone();
        material.roughness = 0;
        material.metalness = 0;
        material.emissive = new Color(0xffffff);
        material.emissiveMap = material.map.clone();
        return material;
    }, [scene]);

    const materials = useMemo(
        () => [ledMaterial, scene.materials["Back_Box__Back_Box_jpg"], scene.materials["3_Aspect__Signal_Front_-_3_Aperture_jpg"]],
        [scene, ledMaterial],
    );

    const signalPosition = useMemo(() => new Vector3(), []);
    const signalQuaternion = useMemo(() => new Quaternion(), []);
    const signalOrientation = useMemo(() => new Vector3(0, 1, 0), []);

    useFrame(({ camera }) => {
        let brightness = 1;
        if (props.sunBrightness && props.sunBrightness.current) {
            brightness = 1 - props.sunBrightness.current;
        }

        if (groupRef.current && spriteRef.current) {
            groupRef.current.getWorldPosition(signalPosition);
            groupRef.current.getWorldQuaternion(signalQuaternion);
            signalOrientation.set(0, 1, 0);
            signalOrientation.applyQuaternion(signalQuaternion);
            const incidenceAngle = signalOrientation.angleTo(signalPosition);
            const incidence = Math.max(0, incidenceAngle + incidenceOffset) / maxIncidenceAngle;
            ledMaterial.emissiveIntensity = Math.max(0.04, 0.5 * incidence);
            ledMaterial.color.setScalar(Math.max(0.04, incidence));
            spriteRef.current.opacity = 2 * incidence * brightness;
        } else {
            ledMaterial.emissiveIntensity = 0;
            ledMaterial.color.setScalar(1);
        }
    });

    return (
        <group
            {...props}
            dispose={null}>
            <group
                rotation={[-Math.PI / 2, 0, Math.PI]}
                scale={50}
                ref={groupRef}>
                <Meshes
                    geometries={geometries}
                    materials={materials}
                    selected={props.selected}
                    highlighted={props.highlighted}
                />
            </group>
            {lightSprite}
        </group>
    );
}

export default SignalHead;
