import { fabric } from "fabric";
import React, { useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

const userAnnotationTypeSelector = (state) => state.userAnnotationTypes;

const STROKE_WIDTH = 3;
const DEFAULT_COLOUR = "#ffeb3b";

const DtAnnotationCanvas = ({ annotation, width_, height_, small, bBoxVisible }) => {
    const fabricCanvas = useRef(null);
    const userAnnotationTypes = useSelector(userAnnotationTypeSelector);

    const initCanvas = useCallback(() => {
        const options = { defaultCursor: "crosshair", uniformScaling: false };
        const canvas = new fabric.StaticCanvas(fabricCanvas.current, options);
        canvas.hoverCursor = "not-allowed";

        // for now hardcode the with of the canvas
        canvas.setHeight(height_);
        canvas.setWidth(width_);
        const left = canvas.width * annotation.bounds[1];
        const right = canvas.width * annotation.bounds[3];
        const top = canvas.height * annotation.bounds[0];
        const bottom = canvas.height * annotation.bounds[2];
        const width = Math.abs(left - right);
        const height = Math.abs(top - bottom);

        let element = null;
        // canvas.clear();

        let tagName = "";
        if (annotation.type) {
            if (annotation.type === -1) {
                tagName = annotation.custom_tag;
            } else {
                let selectedTag = userAnnotationTypes.find((type) => type.id === annotation.type);
                if (selectedTag) {
                    tagName = selectedTag.type;
                }
            }
        }

        // disable edit mode
        let canEditAnnotation = false;

        if (annotation.shape === "box") {
            const box = new fabric.Rect({
                left: left,
                top: top,
                width: width,
                height: height,
                fill: "",
                strokeWidth: small ? STROKE_WIDTH / 2 : STROKE_WIDTH,
                centeredScaling: false,
                stroke: annotation.colour || DEFAULT_COLOUR,
                id: annotation.id,
                saved: true,
                hasBorders: false,
                controls: {
                    ...fabric.Text.prototype.controls,
                    mtr: new fabric.Control({ visible: false }),
                },
                selectable: false,
                hoverCursor: "default",
                strokeUniform: true,
            });

            const boxlabel = new fabric.Text(tagName, {
                fontFamily: "Helvetica",
                fontSize: small ? 12 : 23,
                fill: "white",
                textAlign: "left",
                originX: "left",
                originY: "top",
                left: left,
                top: top - (small ? 15 : 27),
                lockScalingY: true,
                backgroundColor: "rgba(0,0,0,0.55)",
                hasBorders: false,
                hasControls: false,
                evented: false,
            });

            box.boxLabel = boxlabel;
            boxlabel.box = box;
            canvas.add(box);
            canvas.add(boxlabel);
        } else if (annotation.shape === "circle") {
            const circle = new fabric.Ellipse({
                left: left,
                top: top,
                rx: width / 2,
                ry: height / 2,
                angle: 0,
                fill: "",
                strokeWidth: small ? STROKE_WIDTH / 2 : STROKE_WIDTH,
                type: "circle",
                saved: true,
                stroke: annotation.colour || DEFAULT_COLOUR,
                id: annotation.id,
                hasBorders: false,
                controls: {
                    ...fabric.Text.prototype.controls,
                    mtr: new fabric.Control({ visible: false }),
                },
                selectable: false,
                hoverCursor: "default",
                strokeUniform: true,
            });

            const boxlabel = new fabric.Text(tagName, {
                fontFamily: "Helvetica",
                fontSize: 23,
                fill: "white",
                textAlign: "left",
                originX: "left",
                originY: "top",
                left: left,
                top: top - 27,
                lockScalingY: true,
                backgroundColor: "rgba(0,0,0,0.55)",
                hasBorders: false,
                hasControls: false,
                saved: true,
                evented: false,
            });

            circle.boxLabel = boxlabel;
            boxlabel.box = circle;
            canvas.add(circle);
            canvas.add(boxlabel);
        } else if (annotation.shape === "line") {
            const points = [left, top, right, bottom];
            const circle1 = new fabric.Circle({
                left: left,
                top: top,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                hasBorders: false,
                radius: 2,
                strokeWidth: 3,
                stroke: "red",
                fill: "red",
                originX: "center",
                originY: "center",
                hasControls: false,
                selectable: false,
                hoverCursor: "default",
            });

            const circle2 = new fabric.Circle({
                left: right,
                top: bottom,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                hasBorders: false,
                hasControls: false,
                radius: 2,
                strokeWidth: 3,
                stroke: "red",
                fill: "red",
                originX: "center",
                originY: "center",
                selectable: false,
                hoverCursor: "default",
            });

            const line = new fabric.Line(points, {
                originX: "center",
                originY: "center",
                strokeWidth: small ? STROKE_WIDTH / 2 : STROKE_WIDTH + 1,
                stroke: annotation.colour || DEFAULT_COLOUR,
                id: annotation.id,
                saved: true,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                perPixelTargetFind: true,
                hasControls: false,
                selectable: false,
                hoverCursor: "default",
            });
            line.lockScalingX = line.lockScalingY = true;

            circle1.line = circle2.line = line;
            line.circle1 = circle1;
            line.circle2 = circle2;
            canvas.add(line);
            if (annotation.isUsersAnnotation) {
                canvas.add(circle1);
                canvas.add(circle2);
                circle1.bringToFront();
                circle2.bringToFront();
            }
        } else if (annotation.shape === "text") {
            element = new fabric.Text(tagName, {
                width: width,
                fontSize: 25,
                fill: "white",
                backgroundColor: "rgba(0, 0, 0, 0.55)",
                padding: 4,
                left: left,
                top: top,
                originX: "left",
                originY: "top",
                type: "text",
                fontFamily: "Helvetica",
                id: annotation.id,
                saved: true,
                controls: {
                    ...fabric.Text.prototype.controls,
                    mtr: new fabric.Control({ visible: false }),
                    bl: new fabric.Control({ visible: false }),
                    br: new fabric.Control({ visible: false }),
                    tl: new fabric.Control({ visible: false }),
                    tr: new fabric.Control({ visible: false }),
                    ml: new fabric.Control({ visible: false }),
                    mr: new fabric.Control({ visible: false }),
                    mb: new fabric.Control({ visible: false }),
                    mt: new fabric.Control({ visible: false }),
                },
                selectable: false,
                hoverCursor: "default",
            });
        }

        if (element) {
            console.log("debug element", element);
            canvas.add(element);
        }
    }, [
        annotation.bounds,
        annotation.colour,
        annotation.custom_tag,
        annotation.id,
        annotation.isUsersAnnotation,
        annotation.shape,
        annotation.type,
        height_,
        small,
        userAnnotationTypes,
        width_,
    ]);

    useEffect(() => {
        initCanvas();
    }, [annotation, userAnnotationTypes, width_, height_, initCanvas]);

    return (
        <div className="canvas-container">
            {bBoxVisible && (
                <canvas
                    id="canvas"
                    ref={fabricCanvas}
                />
            )}
        </div>
    );
};

export default DtAnnotationCanvas;
