import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import { getSessionData, closeFullscreen, saveMarkerDefaults, calculateThreshold } from "./index";

export const SELECT_ANNOTATION = "SELECT_ANNOTATION";
export const ADD_CUSTOM_ANNOTATION_LABEL = "ADD_CUSTOM_ANNOTATION_LABEL";
export const REMOVE_CUSTOM_ANNOTATION_LABEL = "REMOVE_CUSTOM_ANNOTATION_LABEL";
export const TOGGLE_SNAPSHOT = "TOGGLE_SNAPSHOT";
export const SAVING_ANNOTATIONS = "SAVING_ANNOTATIONS";
export const TOGGLE_UNSAVED_CHANGE = "TOGGLE_UNSAVED_CHANGE";
export const CHANGE_TOOL_MODE = "CHANGE_TOOL_MODE";
export const USER_MEASUREMENTS = "USER_MEASUREMENTS";
export const USER_ANNOTATIONS = "USER_ANNOTATIONS";
export const USER_ANNOTATION_TYPES = "USER_ANNOTATION_TYPES";
export const MARKER_INFO_SHOWING = "MARKER_INFO_SHOWING";
export const USER_SKETCHES = "USER_SKETCHES";
export const RECEIVE_MARKUP_EXPORT_TYPES = "RECEIVE_MARKUP_EXPORT_TYPES";
export const SET_CONTENT_PAGE_COUNT = "SET_CONTENT_PAGE_COUNT";
export const CLEAR_GLOBAL_CONTENT = "CLEAR_GLOBAL_CONTENT";
export const RECEIVE_GLOBAL_CONTENT = "RECEIVE_GLOBAL_CONTENT";
export const SESSION_CONTENT = "SESSION_CONTENT";
export const REVIEW_ANNOTATION = "REVIEW_ANNOTATION";
export const RECEIVE_ADMIN_ANNOTATIONS = "RECEIVE_ADMIN_ANNOTATIONS";
export const PAGE_LOADED = "PAGE_LOADED";
export const SET_SATELLITE_TAB_OPEN = "SET_SATELLITE_TAB_OPEN";

export const selectAnnotation = (annotationID) => {
    return {
        type: SELECT_ANNOTATION,
        annotationID,
    };
};

export function setContentPageCount(count, isMore) {
    return {
        type: SET_CONTENT_PAGE_COUNT,
        count,
        isMore,
    };
}

export function clearGlobalContent() {
    return {
        type: CLEAR_GLOBAL_CONTENT,
    };
}

function receiveGlobalContent(content, clear) {
    return {
        type: RECEIVE_GLOBAL_CONTENT,
        content,
        clear,
    };
}

function userAnnotations(annotations) {
    return {
        type: USER_ANNOTATIONS,
        annotations,
    };
}

export function toggleMarkerInfoShowing(showing) {
    return {
        type: MARKER_INFO_SHOWING,
        showing,
    };
}

export function toggleSatelliteTabOpen(open) {
    return {
        type: SET_SATELLITE_TAB_OPEN,
        open,
    };
}

export const addCustomAnnotationLabel = (label) => {
    return {
        type: ADD_CUSTOM_ANNOTATION_LABEL,
        label,
    };
};

export function savingAnnotations(saving) {
    return {
        type: SAVING_ANNOTATIONS,
        saving,
    };
}

function userAnnotationTypes(types) {
    return {
        type: USER_ANNOTATION_TYPES,
        types,
    };
}

function userSketches(sketches) {
    return {
        type: USER_SKETCHES,
        sketches,
    };
}

function userMeasurements(measurements) {
    return {
        type: USER_MEASUREMENTS,
        measurements,
    };
}

export const removeCustomAnnotationLabel = (label) => {
    return {
        type: REMOVE_CUSTOM_ANNOTATION_LABEL,
        label,
    };
};

export function changeToolMode(tool) {
    return {
        type: CHANGE_TOOL_MODE,
        tool,
    };
}

export function toggleUnsavedChange(state) {
    return {
        type: TOGGLE_UNSAVED_CHANGE,
        state,
    };
}

export function toggleSnapshot() {
    return (dispatch, getState) => {
        dispatch(closeFullscreen());
        dispatch({
            type: TOGGLE_SNAPSHOT,
        });
    };
}

function recieveAdminAnnotations(annotations, nextPage) {
    return {
        type: RECEIVE_ADMIN_ANNOTATIONS,
        annotations,
        nextPage: nextPage,
    };
}

export function getAdminAnnotations(page = 0) {
    return {
        queue: REVIEW_ANNOTATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_admin_annotations",
                page,
                access_secret: accessSecret,
            };
            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    // if (response.length) {
                    // dispatch(routeHistory(videoKey, true, _.sortBy(response, dp => dp.timestamp)));
                    // }
                    if (response.annotations) {
                        dispatch(recieveAdminAnnotations(response.annotations, page + 1));
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting admin annotations", "An error occurred while fetching admin annotations", error);
                    next();
                });
        },
    };
}

export function reviewAnnotation(annotation_id, reviewed) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                return;
            }

            let postBody = {
                action: "review_annotation",
                access_secret: accessSecret,
                id: annotation_id,
                reviewed,
            };

            let url = "/admin";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        dispatch({
                            type: REVIEW_ANNOTATION,
                            id: annotation_id,
                            reviewed: reviewed,
                        });
                    }

                    resolve(response.success);
                })
                .catch((error) => {
                    reject();
                });
        });
    };
}

export function getMarkupExportTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_markup_export_types",
        };

        const url = "/route";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.types) {
                    dispatch({
                        type: RECEIVE_MARKUP_EXPORT_TYPES,
                        types: response.types,
                    });
                }
            })
            .catch((e) => {
                console.log("Error getting content export types", e);
            });
    };
}

export function fetchUserAnnotationTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "list",
        };
        let url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(userAnnotationTypes(response));
                // let allMarkers = getState().markers.perSession;
                // Object.keys(allMarkers).forEach((sessionID) => {
                //     let sessionMarkers = allMarkers[sessionID];
                //     dispatch(saveMarkerDefaults(calculateThreshold(sessionMarkers, response), sessionID));
                // })
            })
            .catch(() => {
                dispatch(userAnnotationTypes([]));
            });
    };
}

export function fetchUserSketches(sessionID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_video_sketches",
            session_id: sessionID,
        };
        let url = "/route";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(userSketches(response));
            })
            .catch(() => {
                dispatch(userSketches([]));
            });
    };
}

export function fetchUserMeasurements(sessionID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_video_measurements",
            session_id: sessionID,
        };
        let url = "/route";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(userMeasurements(response));
            })
            .catch(() => {
                dispatch(userMeasurements([]));
            });
    };
}

export function updateAnnotations(sessionID, videoKey, deleted, modified, created, frame, callback) {
    let typesHaveChanged = false;
    let postBody = {};

    if (deleted) {
        postBody = {
            action: "delete",
            annotation_id: deleted,
            session_id: sessionID,
            video_key: videoKey,
            frame,
        };
    }

    if (modified) {
        let bounds = [modified.bbTop, modified.bbLeft, modified.bbBottom, modified.bbRight];

        postBody = {
            action: "put",
            annotation_id: modified.id,
            session_id: sessionID,
            video_key: videoKey,
            bounds: bounds.join(","),
            type_id: modified.bbType,
            private: modified.private,
            frame,
            shape: modified.shape,
            custom_tag: modified.customTag ? modified.customTag : null,
            colour: modified.colour,
        };
        if (modified.bbType === -1) {
            postBody["custom_type"] = modified.bbCustomType;
        }
    }

    if (created) {
        let bounds = [created.bbTop, created.bbLeft, created.bbBottom, created.bbRight];

        postBody = {
            action: "put",
            session_id: sessionID,
            video_key: videoKey,
            bounds: bounds.join(","),
            type_id: created.bbType,
            private: created.private,
            frame,
            shape: created.shape,
            custom_tag: created.customTag ? created.customTag : null,
            colour: created.colour,
        };
        if (created.bbType === -1) {
            postBody["custom_type"] = created.bbCustomType;
        }
    }

    return (dispatch, getState) => {
        let url = "/annotations";
        dispatch(savingAnnotations(true));

        dispatch({
            queue: USER_ANNOTATIONS,
            callback: (next, dispatch, getState) => {
                jsonPostV2(url, getState(), postBody, dispatch)
                    .then((response) => {
                        dispatch(savingAnnotations(false));
                        if (response.annotation_id) {
                            callback(response.annotation_id);
                        } else {
                            callback(true);
                        }
                        next();
                    })
                    .catch((error) => {
                        dispatch(savingAnnotations(false));
                        handleJsonPostError("Update User Annotations", "Unable to update user annotation", error);
                        next();
                    });
            },
        });
        dispatch({
            queue: USER_ANNOTATIONS,
            callback: (next, dispatch, getState) => {
                if (typesHaveChanged) {
                    dispatch(userAnnotationTypes([]));
                }
                dispatch(fetchUserAnnotations(sessionID));
                next();
            },
        });
    };
}

export function fetchUserAnnotations(sessionID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get",
            session_id: sessionID,
        };
        let url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(userAnnotations(response));
            })
            .catch(() => {
                dispatch(userAnnotations([]));
            });
    };
}

export function saveSketch(videoKey, frame, encodedPNG, sessionKey, deviceKey, sessionID, isPrivate, colour) {
    return {
        queue: USER_SKETCHES,
        callback: (next, dispatch, getState) => {
            console.log("Saving sketch");

            let postBody = {
                encoded_png: encodedPNG,
                video_key: videoKey,
                frame: frame,
                session_key: sessionKey,
                device_key: deviceKey,
                private: isPrivate,
                action: "save_sketch",
                colour,
            };

            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Succesfully saved sketch");
                    dispatch(fetchUserSketches(sessionID));
                    dispatch(getSessionData(sessionID, true));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to save sketch", "An error occurred while saving this sketch", error);
                    next();
                });
        },
    };
}

export function deleteSketch(videoKey, frame, sessionKey, deviceKey, sessionID) {
    return {
        queue: USER_SKETCHES,
        callback: (next, dispatch, getState) => {
            console.log("Saving sketch");

            let postBody = {
                video_key: videoKey,
                frame: frame,
                session_key: sessionKey,
                device_key: deviceKey,
                action: "delete_sketch",
            };
            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Succesfully deleted sketch");
                    dispatch(fetchUserSketches(sessionID));
                    dispatch(getSessionData(sessionID, true));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to delete sketch", "An error occurred while deleting this sketch", error);
                    next();
                });
        },
    };
}

export function saveCableMeasurement(videoKey, frame, selectedPoints, sessionKey, deviceKey, sessionID, isPrivate, id) {
    return {
        queue: USER_MEASUREMENTS,
        callback: (next, dispatch, getState) => {
            console.log("Saving measurement");

            let postBody = {
                measurement: selectedPoints,
                video_key: videoKey,
                frame: frame,
                session_key: sessionKey,
                device_key: deviceKey,
                action: "save_video_measurement",
                private: isPrivate,
            };
            if (id != null) {
                postBody["id"] = id;
            }

            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Succesfully saved measurements");
                    dispatch(fetchUserMeasurements(sessionID));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to save measurement", "An error occurred while saving this measurement", error);
                    next();
                });
        },
    };
}

export function deleteCableMeasurement(measurementID, sessionID) {
    return {
        queue: USER_MEASUREMENTS,
        callback: (next, dispatch, getState) => {
            console.log("Deleting measurement");

            let postBody = {
                measure_id: measurementID,
                action: "delete_video_measurement",
            };
            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Succesfully deleted measurements");
                    dispatch(fetchUserMeasurements(sessionID));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to delete measurement", "An error occurred while deleting this measurement", error);
                    next();
                });
        },
    };
}

export const fetchGlobalSessionContent = (options, callback = false, page = 0, saveToRedux = true) => {
    return {
        queue: SESSION_CONTENT,
        callback: (next, dispatch, getState) => {
            const postBody = {
                action: "get_global_content",
                ...options,
                page,
            };

            const url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("global session content", response);

                    if (saveToRedux) {
                        dispatch(receiveGlobalContent(response.content, page === 0));
                        dispatch(setContentPageCount(page, response.is_more));
                    }

                    if (callback) {
                        callback(response.content);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetching global session content", "An error occurred while fetching global session content", error);

                    if (callback) {
                        callback();
                    }
                    next();
                });
        },
    };
};

export function toggleAnnotationPrivacy(privacy, video_key, frame, sessionID) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "toggle_annotation_privacy",
                privacy,
                video_key,
                frame,
            };

            let url = "/annotations";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("annotation privacy toggle response", response);
                    dispatch(fetchUserAnnotations(sessionID));
                    resolve(response);
                })
                .catch(() => {
                    reject();
                });
        });
    };
}
