export const ADD_WIDGET = "ADD_WIDGET";
export const REMOVE_WIDGET = "REMOVE_WIDGET";
export const CHANGE_WIDGET_STATUS = "CHANGE_WIDGET_STATUS";

/**@typedef {{[key: string]: any}} ReduxAction */

/**
 * Focuses existing widget
 * @param {string} widget - widget name e.g. "stills"
 * @returns {void}
 */
export const focusWidget = (widget) => window.open("", widget).focus();

/**
 * @param {string} widget
 * @returns {ReduxAction}
 */
export const addWidget = (widget) => {
    return {
        type: ADD_WIDGET,
        widget,
    };
};

/**
 * @param {string} widget
 * @returns {ReduxAction}
 */
export const removeWidget = (widget) => {
    return {
        type: REMOVE_WIDGET,
        widget,
    };
};

/**
 *
 * @param {string} widget
 * @param {boolean} status
 * @returns {ReduxAction}
 */
export const changeWidgetStatus = (widget, status) => {
    return {
        type: CHANGE_WIDGET_STATUS,
        widget,
        status,
    };
};
