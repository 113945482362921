import _ from "lodash";

export default class IrishRailPositionalSystem {
    constructor(systemID, route, position, subpostion, sources) {
        this.system = systemID;
        this.route = route;
        this.position = position;
        this.subposition = subpostion;
        this.sources = sources;
        this.mile = Math.floor(position / 80);
        this.kilometer = Math.floor(position / 49.7097);
        this.meterage = Math.floor(position - this.kilometer * 49.7097);
        this.chain = Math.floor(position - this.mile * 80);
        this.yard = Math.floor((position - this.mile * 80) * 22);
    }

    static meters_per_chain = 20.1168;
    static display_formats = {
        elr_mile_yards: "Route ID Mile and Yards",
        elr_kilometer_meter: "Route ID Kilometer and Meterage",
    };

    static display_fields = {
        ROUTE: {
            name: "Route ID",
            rules: [{ required: true, min: 1, max: 5 }],
        },
        MILE: {
            name: "Mile",
            rules: [{ required: true, type: "integer" }],
        },
        CHAIN: {
            name: "Chain",
            rules: [{ required: true, type: "integer", min: 0, max: 79 }],
        },
        YARD: {
            name: "Yard",
            rules: [{ required: true, type: "integer", min: 0, max: 1759 }],
        },
        METER: {
            name: "Meter",
            rules: [{ required: true, type: "integer" }],
        },
        TRACK: {
            name: "Track ID",
            rules: [{}],
        },
        KILOMETER: {
            name: "Kilometer",
            rules: [{ required: true, type: "integer" }],
        },
    };

    static routeAbbreviationDict = {
        AW2WT: "Anthenry to Tuam",
        BS2SO: "Broadstone to Sligo",
        BY2KJ: "Ballybrophy to Killonan Jct",
        CE2VW: "Carlow Branch",
        CJ2EW: "Church Rd to East Wall Jct",
        CK2CH: "Cork to Cobh",
        CL2PC: "Clonsilla to Pace",
        CY2BD: "Connolly to Border",
        CY2BJ: "Connolly to Suburban Jct",
        DA2TM: "Drogheda to Tara Mines",
        EJ2AT: "Ennis Junction to Athenry",
        GE2YL: "Glountaune to Youghal",
        GM2GJ: "Glasnevin Jct (Midland) to Glasnevin Jct",
        GS2WR: "GSWR",
        HJ2HT: "Howth Junction to Howth",
        HN2CK: "Heuston to Cork",
        KK2WW: "Kilkenny to Waterford",
        KL2TL: "Killarney to Tralee",
        LK2WW: "Limerick to Waterford",
        LN2LW: "Limerick Jct Loop",
        MG2WR: "Liffey Jctn - Docklands",
        MJ2BA: "Manulla Junction to Ballina",
        MR2GY: "Mullingar to Galway",
        MW2KL: "Mallow to Killarney",
        NJ2CY: "Newcomen Jct to Connolly",
        OR2NS: "Ossary Rd to North Strand",
        PN2AE: "Portarlington to Athlone",
        PS2CY: "Pearse to Connolly",
        PS2SJ: "Pearse to Shanganagh Jct",
        RS2WX: "Rosslare Strand to Wexford",
        SJ2WX: "Shanganagh Jct to Wexford",
        VN2VS: "Lavistown (North) to Lavistown (South)",
        WW2RO: "Waterford to Rosslare Europort",
    };

    static from_fields(system_id, display_format, fields) {
        if (display_format === "elr_mile_yards") {
            const position = fields.MILE * 80 + fields.YARD / 22;
            return new IrishRailPositionalSystem(system_id, fields.ROUTE, position, fields.TRACK, []);
        } else if (display_format === "elr_kilometer_meter") {
            return IrishRailPositionalSystem.from_meters(system_id, fields.ROUTE, fields.METER, fields.TRACK, []);
        }
    }

    static from_meters(system_id, route_id, meters, subposition) {
        const position = meters / IrishRailPositionalSystem.meters_per_chain;
        return new IrishRailPositionalSystem(system_id, route_id, position, subposition);
    }

    to_kilometer_meterage_string() {
        return `${this.kilometer}km ${this.meterage}m`;
    }

    to_mile_and_yard_string(isMwv) {
        const yard = this.yard.toString().padStart(4, "0");
        return `${this.mile}m ${yard}y`;
    }

    to_string(display_format, datum_offsets) {
        let position;
        if (display_format === "elr_mile_yards") {
            position = this.to_mile_and_yard_string();
        } else {
            position = this.to_kilometer_meterage_string();
        }

        if (this.track !== null) {
            return `${this.lookup_route_name(this.route)} track ${this.subposition} ${position}`;
        } else {
            return `${this.lookup_route_name(this.route)} ${position}`;
        }
    }

    lookup_route_name(routeID) {
        return _.get(IrishRailPositionalSystem.routeAbbreviationDict, [routeID], routeID);
    }

    field_value(field, datum_offsets) {
        switch (field) {
            case "ROUTE":
                return this.lookup_route_name(this.route);
            case "METER":
                return this.meterage;
            case "TRACK":
                return this.subposition;
            case "KILOMETER":
                return this.kilometer;
            case "MILE":
                return this.mile;
            case "YARD":
                return this.yard;
            default:
                return null;
        }
    }

    fields(display_format) {
        return IrishRailPositionalSystem.fields(display_format);
    }

    static fields(displayFormat) {
        if (displayFormat === "elr_kilometer_meter") {
            return ["ROUTE", "KILOMETER", "METER", "TRACK"];
        } else if (displayFormat === "elr_mile_yards") {
            //Special display format for mobile, not in standard list
            return ["ROUTE", "MILE", "YARD", "TRACK"];
        } else {
            return ["ROUTE", "METERAGE", "TRACK"];
        }
    }

    displayFields(display_format) {
        return IrishRailPositionalSystem.displayFields(display_format);
    }

    static displayFields(displayFormat) {
        if (displayFormat === "elr_kilometer_meter") {
            return ["ROUTE", "TRACK", "KILOMETER", "METER"];
        } else if (displayFormat === "elr_mile_yards") {
            //Special display format for mobile, not in standard list
            return ["ROUTE", "TRACK", "MILE", "YARD"];
        } else {
            return ["ROUTE", "TRACK", "METERAGE"];
        }
    }

    position_to_meters(position) {
        return position * IrishRailPositionalSystem.meters_per_chain;
    }
}
