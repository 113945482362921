import React from "react";
import _ from "lodash";

const PatrolStatus = ({ status }) => {
    if (_.isNil(status)) {
        return null;
    }

    let text = null;
    let light = null;

    switch (status) {
        case 0:
            text = <p>Not Started</p>;
            light = "Grey";
            break;
        case 1:
            text = <p>Patrol In Progress</p>;
            light = "Orange";
            break;
        case 2:
            text = <p>Awaiting Review</p>;
            light = "Purple";
            break;
        case 3:
            text = <p>Reviewed</p>;
            light = "Green";
            break;
        default:
            break;
    }

    return (
        <div className="ReportStatusContainer">
            <div className={`StatusLight ${light}`} />
            {text}
        </div>
    );
};

export default PatrolStatus;
