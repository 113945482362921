import React, { useEffect, useState, useMemo } from "react";
import { getPatrolPlan } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import SchemaScroller from "./SchemaScroller";
import PatrolInterfaceHeader from "./PatrolInterfaceHeader";
import PinCursor from "../DropPinCursor";
import { useHistory } from "react-router-dom";
import Measure from "react-measure";
import Settings from "../display/image/Settings";
import PatrolHistory from "./PatrolHistory";
import PatrolReportForm from "./PatrolReportForm";
import AssetNavigation from "./AssetNavigation/AssetNavigation";
import _ from "lodash";
import { calculateRoutePercentageCovered, calculateTotalPatrolChainage } from "components/util/PlaylistUtils";
import PatrolCreatorHeader from "./PatrolCreatorHeader";
import InspectionSnapshotModal from "components/display/image/InspectionSnapshotModal";
import DetailView from "components/display/image/DetailView";
import RailInspectionExtraDisplay from "components/display/image/RailInspectionExtraDisplay";

const schemaPlanSelector = (state) => state.schemaInterface.plan;
const settingsOpenSelector = (state) => state.railInspection.settingsOpen;
const userEmailSelector = (state) => state.userDetails.email;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const viewedProgressSelector = (state) => state.schemaInterface.covered;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const detailModalOpenSelector = (state) => state.schemaInterface.detailModalOpen;

const SchemaInterface = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const schemaPlan = useSelector(schemaPlanSelector);
    const settingsOpen = useSelector(settingsOpenSelector);
    const userEmail = useSelector(userEmailSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const progressMap = useSelector(viewedProgressSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const detailViewOpen = useSelector(detailModalOpenSelector);

    const isReview = match.path.includes("review-patrol");
    const isPlanner = match.path.includes("patrol-planner");
    const planID = parseInt(match.params.patrolID);

    const [placingBookmark, setPlacingBookmark] = useState(false);
    const [assetNavigationOpen, setAssetNavigationOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [annotatingActive, setAnnotatingActive] = useState(false);
    const [updatingBookmarkID, setUpdatingBookmarkID] = useState();
    const [updatingAnnotationID, setUpdatingAnnotationID] = useState(null);
    const [labelWindowOpen, setLabelWindowOpen] = useState();
    const [annotationWindowOpen, setAnnotationWindowOpen] = useState(false);
    const [fetchedPatrol, setFetchedPatrol] = useState(false);
    const [extraLayersSyncOffset, setExtraLayersSyncOffset] = useState(0);
    const [snapshotActive, setSnapshotActive] = useState(false);

    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (userEmail && !fetchedPatrol && !isPlanner) {
            dispatch(
                getPatrolPlan(planID, isReview, (error) => {
                    setFetchedPatrol(true);
                    if (error) {
                        history.push("/patrols");
                    }
                }),
            );
        }
    }, [dispatch, history, isReview, planID, fetchedPatrol, userEmail, isPlanner]);

    const toggleBookmarkMode = () => {
        if (annotatingActive) {
            setAnnotatingActive(false);
        }
        setPlacingBookmark((active) => !active);
    };

    const patrolCompleted = useMemo(() => {
        return isReview || patrolStatus === 2 || patrolStatus === 3;
    }, [isReview, patrolStatus]);

    const patrolEditable = useMemo(() => {
        return !patrolCompleted || (isSupervisor && patrolStatus <= 2);
    }, [patrolCompleted, isSupervisor, patrolStatus]);

    const totalPatrolChainage = useMemo(() => {
        if (schemaPlan.svg.line) {
            return calculateTotalPatrolChainage(schemaPlan.svg.line);
        }
    }, [schemaPlan.svg.line]);

    const totalCoveragePercent = useMemo(() => {
        if (progressMap && totalPatrolChainage && schemaPlan.svg.line) {
            return calculateRoutePercentageCovered(schemaPlan.svg.line, progressMap, totalPatrolChainage);
        }
    }, [progressMap, totalPatrolChainage, schemaPlan.svg.line]);

    return (
        <>
            <PatrolReportForm
                patrolID={planID}
                patrolPlan={schemaPlan}
                totalCoveragePercent={totalCoveragePercent}
            />

            <Measure
                bounds
                onResize={(contentRect) => {
                    setWindowDimensions({
                        width: contentRect.bounds.width,
                        height: contentRect.bounds.height,
                        bottom: contentRect.bounds.bottom,
                    });
                }}>
                {({ measureRef }) => (
                    <div
                        className="SchemaInterface"
                        ref={measureRef}>
                        {settingsOpen && (
                            <Settings
                                windowDimensions={windowDimensions}
                                showGridSettings={false}
                            />
                        )}
                        {assetNavigationOpen && (
                            <AssetNavigation
                                windowDimensions={windowDimensions}
                                closeAction={() => setAssetNavigationOpen(false)}
                                planData={schemaPlan}
                                setUpdatingBookmarkID={setUpdatingBookmarkID}
                                setUpdatingAnnotationID={setUpdatingAnnotationID}
                                setLabelWindowOpen={setLabelWindowOpen}
                                setAnnotationWindowOpen={setAnnotationWindowOpen}
                                annotationWindowOpen={annotationWindowOpen}
                            />
                        )}

                        {detailViewOpen && (
                            <RailInspectionExtraDisplay
                                syncOffset={extraLayersSyncOffset}
                                setSyncOffset={setExtraLayersSyncOffset}
                                labelWindowOpen={labelWindowOpen}
                                windowDimensions={windowDimensions}
                                annotationWindowOpen={annotationWindowOpen}
                                isPatrol={true}
                            />
                        )}

                        {/* <InspectionSnapshotModal /> */}

                        {isPlanner ? (
                            <PatrolCreatorHeader
                                setAssetNavigationOpen={setAssetNavigationOpen}
                                assetNavigationOpen={assetNavigationOpen}
                                totalPatrolChainage={totalPatrolChainage}
                            />
                        ) : (
                            <PatrolInterfaceHeader
                                totalCoveragePercent={totalCoveragePercent}
                                patrolCompleted={patrolCompleted}
                                setAssetNavigationOpen={setAssetNavigationOpen}
                                assetNavigationOpen={assetNavigationOpen}
                                annotatingActive={annotatingActive}
                                setAnnotatingActive={setAnnotatingActive}
                                placingBookmark={placingBookmark}
                                toggleBookmarkMode={toggleBookmarkMode}
                                setHistoryOpen={setHistoryOpen}
                                historyOpen={historyOpen}
                                snapshotActive={snapshotActive}
                                setSnapshotActive={setSnapshotActive}
                            />
                        )}

                        <PinCursor active={placingBookmark} />

                        <InspectionSnapshotModal patrolView={true} />

                        <div className="SchemaBottom">
                            <SchemaScroller
                                patrolCompleted={patrolCompleted}
                                bookmarkModeActive={placingBookmark}
                                historyOpen={historyOpen}
                                toggleBookmarkMode={toggleBookmarkMode}
                                annotatingActive={annotatingActive}
                                setAnnotatingActive={setAnnotatingActive}
                                setUpdatingBookmarkID={setUpdatingBookmarkID}
                                setUpdatingAnnotationID={setUpdatingAnnotationID}
                                updatingBookmarkID={updatingBookmarkID}
                                updatingAnnotationID={updatingAnnotationID}
                                setLabelWindowOpen={setLabelWindowOpen}
                                labelWindowOpen={labelWindowOpen}
                                setAnnotationWindowOpen={setAnnotationWindowOpen}
                                annotationWindowOpen={annotationWindowOpen}
                                isPlanner={isPlanner}
                                patrolEditable={patrolEditable}
                                isSupervisor={isSupervisor}
                            />

                            {historyOpen && <PatrolHistory />}
                        </div>
                    </div>
                )}
            </Measure>
        </>
    );
};

export default SchemaInterface;
