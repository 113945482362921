import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress, faSearchMinus, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { logEvent, setFullscreenValue } from "../../redux/actions/index";

class FullScreenCapableComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.fullscreenComponent = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fullscreen !== this.props.fullscreen) {
            if (this.props.fullscreen) {
                if (this.props.fullscreen === true || this.props.fullscreenID === this.props.fullscreen) {
                    this.openFullscreen();
                }
            } else {
                this.closeFullscreen();
            }
        }
    };

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onFullscreenChanged, false);
        document.addEventListener("mozfullscreenchange", this.onFullscreenChanged, false);
        document.addEventListener("MSFullscreenChange", this.onFullscreenChanged, false);
        document.addEventListener("webkitfullscreenchange", this.onFullscreenChanged, false);
    }

    onFullscreenChanged = () => {
        let category = this.props.isVideo && !this.props.isStills ? "Video" : "Images";
        this.props.dispatch(logEvent(category, "Toggle Fullscreen", this.isFullscreen()));
        if (this.props.fullscreen !== this.isFullscreen()) {
            if (this.props.fullscreen === true || this.props.fullscreenID === this.props.fullscreen) {
                let fullScreenValue = false;
                if (this.isFullscreen()) {
                    fullScreenValue = this.props.fullscreenID || true;
                }
                this.props.dispatch(setFullscreenValue(fullScreenValue));
            }
        }
    };

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.onFullscreenChanged, false);
        document.removeEventListener("mozfullscreenchange", this.onFullscreenChanged, false);
        document.removeEventListener("MSFullscreenChange", this.onFullscreenChanged, false);
        document.removeEventListener("webkitfullscreenchange", this.onFullscreenChanged, false);
    }

    openFullscreen = () => {
        console.log("Entering fullscreen...");
        this.props.dispatch(setFullscreenValue(this.props.fullscreenID || true));

        if (!this.isFullscreen()) {
            let obj = this.fullscreenComponent.current;
            if (obj.requestFullscreen) {
                obj.requestFullscreen();
            } else if (obj.webkitRequestFullscreen) {
                obj.webkitRequestFullscreen();
            } else if (obj.mozRequestFullScreen) {
                obj.mozRequestFullScreen();
            } else if (obj.msRequestFullscreen) {
                obj.msRequestFullscreen();
            }
        }
    };

    closeFullscreen = () => {
        console.log("Exiting fullscreen...");
        this.props.dispatch(setFullscreenValue(false));

        if (this.isFullscreen()) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozExitFullScreen) {
                document.mozExitFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    toggleFullscreen = () => {
        let newFullscreen = !this.isFullscreen();
        if (newFullscreen) {
            newFullscreen = this.props.fullscreenID || true;
        }
        this.props.dispatch(setFullscreenValue(newFullscreen));
    };

    isFullscreen = () => {
        let fse = null;
        if (document.fullscreenElement) {
            fse = document.fullscreenElement;
        } else if (document.mozFullScreenElement) {
            fse = document.mozFullScreenElement;
        } else if (document.msFullscreenElement) {
            fse = document.msFullscreenElement;
        } else if (document.webkitFullscreenElement) {
            fse = document.webkitFullscreenElement;
        }

        return !!fse && fse === this.fullscreenComponent.current;
    };

    render() {
        let expandCollapseIcon = null;

        if ((!this.props.fullscreen && this.props.showExpand) || (this.props.fullscreen && this.props.showCollapse)) {
            expandCollapseIcon = (
                <div className={"FullScreenIconOverlay" + (this.props.bottom ? " Bottom" : " Top") + (this.props.left ? " Left" : " Right")}>
                    <div
                        className="Image-Fullscreen-Icon"
                        onClick={this.toggleFullscreen}>
                        {this.props.magnifyIcon ? (
                            <FontAwesomeIcon icon={this.props.fullscreen ? faSearchMinus : faSearchPlus} />
                        ) : (
                            <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div
                className="FullScreenComponentWrapper"
                ref={this.fullscreenComponent}
                style={this.props.extraStyle}>
                {this.props.children}
                {expandCollapseIcon}
            </div>
        );
    }
}

const mapStateToProps = ({ fullscreen, playlist }) => {
    return {
        isVideo: playlist.position.isVideo,
        isStills: playlist.position.isStills,
        fullscreen,
    };
};

const FullScreenCapable = connect(mapStateToProps, null, null, { forwardRef: true })(FullScreenCapableComponent);

FullScreenCapable.defaultProps = {
    showExpand: true,
    showCollapse: true,
};

export default FullScreenCapable;
