import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { faMapPin, faCog, faClock, faCamera, faBars, faProjectDiagram, faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { faObjectUngroup } from "@fortawesome/free-solid-svg-icons";
import { Modal, Select, Tooltip } from "antd";
import {
    reportPatrolDiagramProblem,
    toggleDiagramReport,
    togglePatrolBugReport,
    toggleSettingsOpen,
    toggleInspectionSnapshot,
    togglePatrolDetailModal,
} from "../../redux/actions/index";
import { calculatePatrolDeviceConfig } from "components/util/Geometry";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import _ from "lodash";
import { Link } from "react-router-dom";

import { ReactComponent as DetailRailIcon } from "../display/image/RailButtons/DetailRailIcon.svg";
import GenericDialog from "components/GenericDialog";

const { Option } = Select;

const userDetailsSelector = (state) => state.userDetails;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const planIDSelector = (state) => state.schemaInterface.plan.id;
const planDiagramSelector = (state) => state.schemaInterface.plan.diagram_link;
const settingsOpenSelector = (state) => state.railInspection.settingsOpen;
const bugModalOpenSelector = (state) => state.schemaInterface.bugReportOpen;
const diagramReportOpenSelector = (state) => state.schemaInterface.diagramReportOpen;
const inspectionSnapshotOpenSelector = (state) => state.railInspection.snapshot.open;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const railImagesSelector = (state) => {
    return _.get(state.schemaInterface.sessions, [state.schemaInterface.selectedSession], []);
};
const detailModalOpenSelector = (state) => state.schemaInterface.detailModalOpen;

const SchemaToolbar = ({
    toggleBookmarkMode,
    placingBookmark,
    assetNavigationActive,
    setHistoryOpen,
    historyActive,
    annotatingActive,
    setAnnotatingActive,
    setAssetNavigationOpen,
}) => {
    const userDetails = useSelector(userDetailsSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const planID = useSelector(planIDSelector);
    const planDiagram = useSelector(planDiagramSelector);
    const settingsOpen = useSelector(settingsOpenSelector);
    const reportBugOpen = useSelector(bugModalOpenSelector);
    const diagramReportOpen = useSelector(diagramReportOpenSelector);
    const inspectionSnapshotOpen = useSelector(inspectionSnapshotOpenSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const railImages = useSelector(railImagesSelector);
    const detailModalOpen = useSelector(detailModalOpenSelector);

    const dispatch = useDispatch();

    const [diagramOpen, setDiagramOpen] = useState(false);
    const [issueType, setIssueType] = useState();
    const [issueDescription, setIssueDescription] = useState();
    const [displayHelp, setDisplayHelp] = useState(false);

    const railImageConfig = useMemo(() => {
        return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [railImageConfigs, railImages]);

    const showButtons = useMemo(() => {
        if (!patrolStatus || (patrolStatus && patrolStatus === 1)) {
            return true;
        }
        if (patrolStatus === 2 || patrolStatus === 3) {
            return !!isSupervisor;
        }
    }, [patrolStatus, isSupervisor]);

    const submitProblem = () => {
        dispatch(
            reportPatrolDiagramProblem(planID, issueType, issueDescription, () => {
                dispatch(toggleDiagramReport(false));
                setDiagramOpen(false);
            }),
        );
    };

    const toggleDiagram = () => {
        setDiagramOpen(!diagramOpen);
    };

    const markupDisabled = useMemo(() => {
        if (!patrolStatus) {
            return true;
        }
        return false;
    }, [patrolStatus]);

    const toggleSettings = () => {
        dispatch(toggleSettingsOpen());
    };

    const toggleHelp = () => {
        setDisplayHelp(!displayHelp);
    };

    const toggleSnapshot = () => {
        dispatch(toggleInspectionSnapshot(!inspectionSnapshotOpen));
    };

    const toggleDetailRails = () => {
        dispatch(togglePatrolDetailModal(!detailModalOpen));
    };

    const hasDetailImages = useMemo(() => {
        if (_.isEmpty(railImageConfig)) {
            return false;
        }

        return !!railImageConfig.inspection_images.filter((config) => config.showInModal).length;
    }, [railImageConfig]);

    return (
        <>
            <Modal
                title="Report a problem"
                visible={diagramReportOpen}
                width={600}
                cancelText={"Cancel"}
                okText={"Submit"}
                onOk={submitProblem}
                onCancel={() => dispatch(toggleDiagramReport(false))}
                className="SchemaToolbar__ReportModal">
                <div className="SchemaToolbar__ReportModal-Inner">
                    <Select
                        value={issueType}
                        onChange={(value) => setIssueType(value)}
                        placeholder={"Problem Type"}>
                        <Option value="ChangeToPatrol">Change to patrol</Option>
                        <Option value="incorrectVersion">Incorrect patrol version</Option>
                        <Option value="changeToSignOff">Change to sign off</Option>
                        <Option value="other">Other</Option>
                    </Select>
                    <textarea
                        placeholder="Describe the issue..."
                        value={issueDescription}
                        onChange={(e) => setIssueDescription(e.target.value)}
                    />
                </div>
            </Modal>
            <Modal
                title="Track Patrolling Diagram"
                visible={diagramOpen}
                width={800}
                cancelText={"Close"}
                okText={"Report a problem"}
                onOk={() => dispatch(toggleDiagramReport(true))}
                onCancel={() => setDiagramOpen(false)}>
                <img
                    style={{ width: "100%" }}
                    alt="track Diagram"
                    src={planDiagram}
                    crossOrigin={"anonymous"}
                />
            </Modal>
            <Modal
                title="Help"
                visible={displayHelp}
                width={500}
                footer={null}
                okText={"Close"}
                onCancel={toggleHelp}>
                <p style={{ marginBottom: "20px" }}>
                    For assistance of any kind with the VIU BVI tool, email{" "}
                    <a href={`mailto:support@onebigcircle.co.uk?subject=VIU%20BVI%20tool%20support`}>support@onebigcircle.co.uk</a>
                </p>
                <a
                    className="schema-toolbar-manual"
                    target="_blank"
                    href={`https://static${MEMOIZED_DOMAIN_URL}/MAN_036+AIVR+S%26C+Video+BVI+System+-+User+Manual+ISSUE_2.pdf`}>
                    View the User Manual
                </a>
            </Modal>

            {reportBugOpen && (
                <GenericDialog
                    title="Report problem"
                    onClose={() => dispatch(togglePatrolBugReport(false))}
                    placeholder={`There is a problem with patrol ${planID}`}
                />
            )}
            <div className="SchemaToolbar">
                {showButtons && (
                    <>
                        <Tooltip title="Report A Problem">
                            <div
                                className={"SchemaToolbarItem Text"}
                                onClick={() => dispatch(togglePatrolBugReport(true))}>
                                <div className="ButtonText">Report A Problem</div>
                            </div>
                        </Tooltip>
                        {planDiagram && (
                            <Tooltip title="View Track Patrolling Diagram">
                                <div
                                    className={"SchemaToolbarItem"}
                                    onClick={toggleDiagram}>
                                    <FontAwesomeIcon
                                        icon={faProjectDiagram}
                                        className="Icon"
                                    />
                                </div>
                            </Tooltip>
                        )}

                        {hasDetailImages && (
                            <Tooltip title="Show Rail Detail Images">
                                <div
                                    onClick={toggleDetailRails}
                                    className={"SchemaToolbarItem" + (detailModalOpen ? " Active" : "")}>
                                    <DetailRailIcon />
                                </div>
                            </Tooltip>
                        )}

                        {markupDisabled ? (
                            <>
                                <Tooltip title="Start inspection to use the snapshot tool">
                                    <div className={"SchemaToolbarItem Inactive"}>
                                        <FontAwesomeIcon
                                            icon={faCamera}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip title="Start inspection to use the annotation tool">
                                    <div className={"SchemaToolbarItem Inactive"}>
                                        <FontAwesomeIcon
                                            icon={faObjectUngroup}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip title="Start inspection to use the bookmark tool">
                                    <div className={"SchemaToolbarItem Inactive"}>
                                        <FontAwesomeIcon
                                            icon={faMapPin}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="New Snapshot">
                                    <div
                                        className={"SchemaToolbarItem" + (inspectionSnapshotOpen ? " Active" : "")}
                                        onClick={toggleSnapshot}>
                                        <FontAwesomeIcon
                                            icon={faCamera}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip title="New Annotation / Defect">
                                    <div
                                        className={"SchemaToolbarItem" + (annotatingActive ? " Active" : "")}
                                        onClick={() => setAnnotatingActive(!annotatingActive)}>
                                        <FontAwesomeIcon
                                            icon={faObjectUngroup}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip title="Create Bookmark">
                                    <div
                                        className={"SchemaToolbarItem" + (placingBookmark ? " Active" : "")}
                                        onClick={toggleBookmarkMode}>
                                        <FontAwesomeIcon
                                            icon={faMapPin}
                                            className="Icon"
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        )}
                    </>
                )}

                <Tooltip title="Navigation">
                    <div
                        className={"SchemaToolbarItem" + (assetNavigationActive ? " Active" : "")}
                        onClick={() => setAssetNavigationOpen(!assetNavigationActive)}>
                        <FontAwesomeIcon
                            icon={faBars}
                            className="Icon"
                        />
                    </div>
                </Tooltip>

                {userDetails.userConfig.super_admin && (
                    <Tooltip title="History">
                        <div
                            className={"SchemaToolbarItem" + (historyActive ? " Active" : "")}
                            onClick={() => setHistoryOpen(!historyActive)}>
                            <FontAwesomeIcon
                                icon={faClock}
                                className="Icon"
                            />
                        </div>
                    </Tooltip>
                )}

                <Tooltip title="Settings">
                    <div
                        className={"SchemaToolbarItem" + (settingsOpen ? " Active" : "")}
                        onClick={toggleSettings}>
                        <FontAwesomeIcon
                            icon={faCog}
                            className="Icon"
                        />
                    </div>
                </Tooltip>

                <Tooltip title="Help">
                    <div
                        className={"SchemaToolbarItem" + (displayHelp ? " Active" : "")}
                        onClick={toggleHelp}>
                        <FontAwesomeIcon
                            icon={faQuestion}
                            className="Icon"
                        />
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default SchemaToolbar;
