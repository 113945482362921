export default class MBTAPositionalSystem {
    static feet_per_marker = 100;
    static display_formats = {
        line_marker_feet: "MBTA",
    };

    static display_fields = {
        LINE: {
            name: "Line",
            rules: [{ required: true }],
        },
        MARKER: {
            name: "Marker",
            rules: [{ required: true, type: "integer" }],
        },
        FEET: {
            name: "Feet",
            rules: [{ required: true, type: "integer", min: 0, max: 99 }],
        },
        TRACK: {
            name: "Track ID",
            rules: [{}],
        },
    };

    fields(display_format) {
        return MBTAPositionalSystem.fields(display_format);
    }

    static fields(display_format) {
        return ["LINE", "MARKER", "FEET", "TRACK"];
    }

    displayFields(display_format) {
        return MBTAPositionalSystem.displayFields(display_format);
    }

    static displayFields(display_format) {
        return ["LINE", "TRACK", "MARKER", "FEET"];
    }

    field_value(field) {
        switch (field) {
            case "LINE":
                return this.line;
            case "MARKER":
                return this.marker;
            case "FEET":
                return this.feet;
            case "TRACK":
                return this.track;
            default:
                return null;
        }
    }

    static from_fields(system_id, display_format, fields) {
        const position = fields.MARKER + fields.FEET / MBTAPositionalSystem.feet_per_marker;
        return new MBTAPositionalSystem(system_id, fields.LINE, position, fields.TRACK);
    }

    static from_search(system_id, fields) {
        const position = parseInt(fields.paramTwo) + parseInt(fields.paramThree) / MBTAPositionalSystem.feet_per_marker;
        return new MBTAPositionalSystem(system_id, fields.paramOne, position, null);
    }

    constructor(system_id, route, position, subposition, sources) {
        this.system = system_id;
        this.route = this.line = route;
        this.position = position;
        this.subposition = this.track = subposition;
        this.sources = sources;
        this.sign = position >= 0 ? 1 : -1;
        if (this.sign < 0) {
            position = Math.abs(position);
        }
        this.marker = Math.floor(position);
        this.feet = Math.floor((position - this.marker) * MBTAPositionalSystem.feet_per_marker);
    }

    generalise() {
        return new MBTAPositionalSystem(this.system, this.route, this.position, null);
    }

    to_marker_and_feet_string() {
        const prefix = this.sign < 0 ? "-" : "";
        const feet = Math.floor(this.feet).toString().padStart(2, "0");
        return `Marker: ${prefix}${this.marker} Feet: ${feet}`;
    }

    to_string(display_format, isHorizontal) {
        let trackLabel = "Track ";
        if (isHorizontal) {
            trackLabel = "";
        }
        let position;
        position = this.to_marker_and_feet_string();
        if (this.track !== null) {
            return `${this.line} ${trackLabel}${this.track} ${position}`;
        } else {
            return `${this.line} ${position}`;
        }
    }

    to_simple_string(display_format, remove_label) {
        let position;
        position = this.to_marker_and_feet_string();

        if (remove_label) {
            return `${position}`;
        } else {
            return `${this.line} ${position}`;
        }
    }
}
