import React, { useMemo } from "react";

const MissingImageComponent = ({ zoom, backwards }) => {
    const fontSize = useMemo(() => {
        const f = 150 - 30 * zoom;

        return f;
    }, [zoom]);

    return (
        <div className={`ImageMissing ${backwards ? "backwards" : null}`}>
            <span style={{ fontSize: fontSize + "px" }}>Awaiting image upload</span>
            <img src="/error.svg" />
        </div>
    );
};

export default MissingImageComponent;
