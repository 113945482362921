import React, { useState, useEffect } from "react";
import { Input, Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { submitBugReport, submitUserSuggestion } from "redux/actions";

//dialogRef: React.MutableRefObject<string>
//onClose: ()=>{}
//use dialog ref and change the current value if more than one dialog is required in a component

const GenericDialog = ({ title, onClose, reportOrigin, timeStamp, placeholder, promptText, preFilledMessage }) => {
    const dialogTitle = title;
    const [userInput, setUserInput] = useState("");
    const [userSignOff, setUserSignOff] = useState("");
    const [submittingForm, setSubmittingForm] = useState(false);

    const dispatch = useDispatch();

    //keys should match dialogTitle
    const displayDict /*{[key: string]: {
    promtText: string,
    onSubmit : () => void,
    includeSignoff: boolean
  }}*/ = {
        "Make a suggestion": {
            promptText:
                promptText ??
                "Is there something you would like to see in AIVR? Use this form to let us know about any improvements or new feature suggestions",
            onSubmit: () => submit(submitSuggestion),
            includeSignoff: false,
            placeholder: placeholder,
        },
        "Report problem": {
            promptText: promptText ?? "Use this form to report an issue with this patrol. For example a data quality, coverage or other technical problem",
            onSubmit: () => submit(submitReport),
            includeSignoff: false,
            placeholder: placeholder,
        },
    };

    useEffect(() => {
        if (preFilledMessage) {
            setUserInput(preFilledMessage);
        } else {
            setUserInput("");
        }
    }, [preFilledMessage]);

    const submitReport = () => {
        setSubmittingForm(true);
        const additionalInformation = {
            timestamp: timeStamp ?? 0,
            report_origin: reportOrigin,
        };

        let submission = userInput;

        if (dialogValues.includeSignoff) {
            submission += "-Reported by " + userSignOff;
        }

        dispatch(
            submitBugReport(submission, additionalInformation, function (success) {
                setSubmittingForm(false);
                if (success) {
                    notification.success({
                        message: "Successfully submitted bug report",
                    });
                    setUserInput("");
                    setUserSignOff("");
                    onClose();
                } else {
                    notification.error({
                        message: "Error submitting bug report",
                    });
                }
            }),
        );
    };

    const submitSuggestion = () => {
        setSubmittingForm(true);

        let submission = userInput;

        if (dialogValues.includeSignoff) {
            submission += "-Reported by " + userSignOff;
        }

        dispatch(
            submitUserSuggestion(submission, function (success) {
                setSubmittingForm(false);
                if (success) {
                    notification.success({
                        message: "Successfully sent suggestion",
                    });
                    setUserInput("");
                    onClose();
                } else {
                    notification.error({
                        message: "Error sending suggestion",
                    });
                }
            }),
        );
    };

    const noKeyFound = () => {
        console.warn("No function found");
    };

    const submit = (submitFunc /*:()=>{}*/) => {
        submitFunc();
    };

    const dialogValues = displayDict[dialogTitle] ?? {
        promptText: "Dialog values not found, please check the name matches",
        onSubmit: () => submit(noKeyFound),
        includeSignoff: true,
    };

    return (
        <Modal
            title={dialogTitle}
            centered
            zIndex={1001}
            getContainer={false}
            okButtonProps={{
                disabled: (userInput === "" && !preFilledMessage) || (dialogValues.includeSignoff && userSignOff === ""),
                loading: submittingForm,
            }}
            visible={true}
            okText={"Submit"}
            cancelText={"Cancel"}
            onOk={dialogValues.onSubmit}
            onCancel={onClose}>
            <p style={{ marginBottom: "10px" }}>{dialogValues.promptText || ""}</p>
            <Input.TextArea
                rows={6}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder={dialogValues.placeholder || ""}
                value={userInput}
                disabled={preFilledMessage}
            />
            {dialogValues.includeSignoff && (
                <>
                    <span>Login was via access token - please state name</span>
                    <Input
                        onChange={(e) => setUserSignOff(e.target.value)}
                        placeholder={"Name"}
                    />
                </>
            )}
        </Modal>
    );
};

export default GenericDialog;
