import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo } from "react";
import moment from "moment";
import { assignPatrol, railPatrolAudit } from "../../redux/actions/index";
import { Progress, Tooltip, Select } from "antd";
import _ from "lodash";
import RoutePatrolSvg from "./RoutePatrolSvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faUser, faUndo, faCalendar, faTrain } from "@fortawesome/pro-solid-svg-icons";

const { Option } = Select;

const accessTokensSelector = (state) => state.access_token;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const patrolUsersSelector = (state) => state.schemaInterface.patrolUsers.users;
const userDetailsSelector = (state) => state.userDetails;

const PatrolItem = ({ planData, style, openConfirmModal, openReassignModal, openArchiveModal }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const accessToken = useSelector(accessTokensSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const patrolUsers = useSelector(patrolUsersSelector);
    const userDetails = useSelector(userDetailsSelector);

    const dueDate = useMemo(() => {
        if (planData.due_ts) {
            let due_moment = moment.unix(planData.due_ts);
            return due_moment.calendar(moment(), {
                sameDay: "[Today]",
                nextDay: "[Tomorrow]",
                nextWeek: "dddd",
                lastDay: "[Yesterday]",
                lastWeek: "[Last] dddd",
                sameElse: "DD/MM/YY",
            });
        } else {
            return null;
        }
    }, [planData.due_ts]);

    const planStatus = useMemo(() => {
        if (!_.isNil(planData.status)) {
            let st = planData.status;
            let status = "Not Started";
            if (st === 1) {
                status = "In Progress";
            } else if (st === 2) {
                status = "Awaiting Review";
            } else if (st === 3) {
                status = "Reviewed";
            }

            return <div className={`Status ${status}`}>{status}</div>;
        }
    }, [planData]);

    const supervisorPatrolling = useMemo(() => {
        if (planData.user_email === userDetails.email) {
            return planData.status === 0 || planData.status === 1;
        } else {
            return false;
        }
    }, [planData.user_email, userDetails.email, planData.status]);

    const onClick = () => {
        let metadata = {
            patrol_id: planData.id,
        };
        dispatch(railPatrolAudit("route_patrol_selected", metadata));

        let url = `/patrol/${planData.id}`;
        if (isSupervisor && !supervisorPatrolling) {
            url = `/review-patrol/${planData.id}`;
        }
        history.push(url);
    };

    const archive = useMemo(() => {
        let text = "Archive Patrol";
        let icon = faArchive;
        if (planData.archived) {
            text = "Un-archive Patrol";
            icon = faUndo;
        }
        return { text: text, icon: icon };
    }, [planData]);

    const onAssignChange = (userID) => {
        if (!planData.user_id) {
            openConfirmModal(planData.id, userID);
        } else {
            openReassignModal(planData.id, userID);
        }
    };

    const assignUserOptions = useMemo(() => {
        if (patrolUsers && patrolUsers.length) {
            patrolUsers.sort((a, b) => {
                let compareA = a.name;
                let compareB = b.name;

                if (!compareA) {
                    compareA = a.email;
                }
                if (!compareB) {
                    compareB = b.email;
                }

                if (compareA && compareB) {
                    return compareA.localeCompare(compareB);
                } else {
                    return a;
                }
            });

            return patrolUsers.map((user) => {
                return (
                    <Option
                        key={user.id}
                        value={user.id}>
                        {user.name ? user.name : user.email}
                    </Option>
                );
            });
        }
    }, [patrolUsers]);

    return (
        <div
            className="PatrolItem"
            onClick={onClick}
            style={style}>
            <span className="PatrolID">#{planData.id}</span>
            <div className="PatrolItem__Svg">
                <RoutePatrolSvg
                    patrolID={planData.id}
                    planData={planData}
                    progressMap={planData.progress}
                    preview
                />
            </div>
            <div className="PatrolItem__Info">
                <div>
                    <h3 className="Title">{planData.name}</h3>

                    {dueDate && (
                        <Tooltip
                            title="Scheduled date of patrol"
                            placement="left">
                            <div className="Deadline">
                                <FontAwesomeIcon icon={faCalendar} />
                                <span>Scheduled DOP: {dueDate}</span>
                            </div>
                        </Tooltip>
                    )}

                    {planData.staging && <p className="StagingText">Staging</p>}

                    {planData.start_timestamp && planData.end_timestamp && (
                        <Tooltip
                            title="Data collection window"
                            placement="left">
                            <div className="data-collection">
                                <FontAwesomeIcon icon={faTrain} />
                                {moment.unix(planData.start_timestamp).format("DD/MM/YY")} - {moment.unix(planData.end_timestamp).format("DD/MM/YY")}
                            </div>
                        </Tooltip>
                    )}

                    <Tooltip
                        title="Current data coverage"
                        placement="left">
                        <div className="PatrolItem__progress-bar">
                            <Progress percent={planData.coverage || 0} />
                        </div>
                    </Tooltip>
                </div>

                <div className="End">
                    {isSupervisor && (
                        <div
                            className="User"
                            onClick={(e) => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faUser} />
                            <Select
                                disabled={planData.status === 2 || planData.status === 3}
                                showSearch
                                style={{ width: 200, fontWeight: planData.user_name || planData.user_email ? 600 : 300 }}
                                value={planData.user_id ? planData.user_id : undefined}
                                placeholder="Select Patroller..."
                                optionFilterProp="children"
                                onChange={(val) => onAssignChange(val)}>
                                {assignUserOptions}
                            </Select>
                        </div>
                    )}

                    {planStatus}
                    <Tooltip title="Total Inspected">
                        <Progress
                            type="circle"
                            percent={planData.calculatedCoverage || 0}
                            width={60}
                            strokeColor="#a9c447"
                            className="ProgressCircle small"
                        />
                    </Tooltip>
                    {isSupervisor && (
                        <Tooltip title={archive.text}>
                            <FontAwesomeIcon
                                style={{ fontSize: 18 }}
                                icon={archive.icon}
                                onClick={(e) => openArchiveModal(e, planData)}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PatrolItem;
