import * as echarts from "echarts";
import { useCallback, useMemo } from "react";
import { boundedRange, boundedValue, useMouseMovement } from "./utils";

function useOverview({ chart, palette, elrData, poiData, viewBounds, selectBounds, selectIndex, maxBoundsRef }) {
    const overviewDataset = useMemo(() => {
        return [
            {
                id: "overview_dataset",
                source: elrData,
                dimensions: [
                    {
                        name: "name",
                        type: "ordinal",
                    },
                    {
                        name: "start",
                        type: "number",
                    },
                    {
                        name: "end",
                        type: "number",
                    },
                    {
                        name: "startPos",
                        type: "number",
                    },
                    {
                        name: "endPos",
                        type: "number",
                    },
                ],
            },
            {
                id: "poi_dataset",
                source: poiData,
                dimensions: [
                    {
                        name: "location",
                        type: "number",
                    },
                    {
                        name: "name",
                        type: "ordinal",
                    },
                    {
                        name: "index",
                        type: "number",
                    },
                ],
            },
        ];
    }, [elrData, poiData]);

    const overviewGrid = useMemo(() => {
        return [
            {
                id: `overview_grid`,
                top: `95%`,
                bottom: `0%`,
            },
        ];
    }, []);

    const overviewXAxis = useMemo(() => {
        return [
            {
                id: `overview_x`,
                type: "value",
                min: "dataMin",
                max: "dataMax",
                gridId: "overview_grid",
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisPointer: {
                    show: true,
                    snap: false,
                },
            },
        ];
    }, []);

    const overviewYAxis = useMemo(() => {
        return [
            {
                id: `overview_y`,
                min: 0,
                max: 1,
                gridId: "overview_grid",
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                    onZero: false,
                },
            },
        ];
    }, []);

    const overviewSeries = useMemo(() => {
        return [
            {
                id: `overview_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                datasetId: "overview_dataset",
                xAxisId: "overview_x",
                yAxisId: "overview_y",
                encode: {
                    x: ["start", "end"],
                    value: ["name", "start", "end", "startPos", "endPos"],
                },
                renderItem: function (params, api) {
                    const elr = api.value(0);
                    const startIndex = api.value(1);
                    const endIndex = api.value(2);

                    const startPos = api.value(3);
                    const endPos = api.value(4);

                    const startMile = Math.floor(startPos / 80);
                    const endMile = Math.floor(endPos / 80);

                    const startChain = Math.floor((startPos - startMile * 80) / 10);
                    const endChain = Math.floor((endPos - endMile * 80) / 10);

                    const startPoint = api.coord([startIndex, 0.7]);
                    const endPoint = api.coord([endIndex, 0.3]);

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: endPoint[0] - startPoint[0],
                            height: endPoint[1] - startPoint[1],
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    if (!bounds) {
                        return null;
                    }

                    const fullElrText = `${elr} ${startMile}.${startChain} - ${endMile}.${endChain}`;
                    const elrText = `${elr}`;
                    const fullTextWidth = echarts.format.getTextRect(fullElrText).width;
                    const smallTextWidth = echarts.format.getTextRect(elrText).width;

                    let text = "";
                    if (bounds.width > fullTextWidth + 4) {
                        text = fullElrText;
                    } else if (bounds.width > smallTextWidth + 4) {
                        text = elrText;
                    }

                    return {
                        type: "group",
                        transition: ["shape"],
                        children: [
                            {
                                type: "line",
                                transition: ["shape"],
                                ignore: !bounds,
                                shape: {
                                    x1: bounds.x,
                                    y1: bounds.y,
                                    x2: bounds.x,
                                    y2: bounds.y + bounds.height,
                                },
                                emphasisDisabled: true,
                                style: {
                                    stroke: palette.overviewBorder,
                                    lineWidth: 1,
                                },
                            },
                            {
                                type: "rect",
                                transition: ["shape"],
                                ignore: !bounds,
                                shape: bounds,
                                emphasisDisabled: true,
                                style: {
                                    text,
                                    fill: "rgba(0, 0, 0, 0)",
                                    textFill: palette.overviewLabel,
                                },
                            },
                        ],
                    };
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
            {
                id: `poi_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                datasetId: "poi_dataset",
                xAxisId: "overview_x",
                yAxisId: "overview_y",
                encode: {
                    x: ["location"],
                    value: ["name", "index"],
                },
                renderItem: function (params, api) {
                    const location = api.value(0);
                    const name = api.value(1);
                    const index = api.value(2);

                    const startPoint = api.coord([location, 0.85]);
                    const endPoint = api.coord([location, 0.7]);

                    const bounds = echarts.graphic.clipPointsByRect([startPoint, endPoint], {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height,
                    });

                    if (!bounds) {
                        return null;
                    }

                    const textHeight = echarts.format.getTextRect("Tg").height;

                    bounds[0][1] -= index % 2 ? textHeight : 0;

                    return {
                        type: "group",
                        children: [
                            {
                                type: "text",
                                ignore: !bounds,
                                x: bounds[0][0],
                                y: bounds[0][1],
                                emphasisDisabled: true,
                                style: {
                                    text: name,
                                    textAlign: "center",
                                    textVerticalAlign: "bottom",
                                    lineWidth: 1,
                                    fill: palette.poiLabel,
                                },
                            },
                            {
                                type: "line",
                                ignore: !bounds,
                                shape: {
                                    x1: bounds[0][0],
                                    x2: bounds[0][0],
                                    y1: bounds[0][1],
                                    y2: bounds[1][1],
                                },
                                emphasisDisabled: true,
                                style: {
                                    lineWidth: 1,
                                    stroke: palette.poiLabel,
                                },
                            },
                        ],
                    };
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
            {
                id: `current_view_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                xAxisId: "overview_x",
                yAxisId: "overview_y",
                data: [[0, 8199]],
                renderItem: function (params, api) {
                    const startIndex = api.value(0);
                    const endIndex = api.value(1);

                    const startPoint = api.coord([startIndex, 0.8]);
                    const endPoint = api.coord([endIndex, 0.2]);

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: endPoint[0] - startPoint[0],
                            height: endPoint[1] - startPoint[1],
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    return (
                        bounds && {
                            type: "rect",
                            ignore: !bounds,
                            transition: ["shape"],
                            shape: bounds,
                            emphasisDisabled: true,
                            style: {
                                fill: palette.currentViewFill,
                                stroke: palette.currentViewBorder,
                            },
                        }
                    );
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
            {
                id: `selected_index_overview_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                xAxisId: "overview_x",
                yAxisId: "overview_y",
                data: [[-1]],
                renderItem: function (params, api) {
                    const index = api.value(0);

                    const startPoint = api.coord([index, 0.9]);
                    const endPoint = api.coord([index, 0.1]);

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: 0,
                            height: endPoint[1] - startPoint[1],
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    return (
                        bounds && {
                            type: "line",
                            transition: ["shape"],
                            emphasisDisabled: true,
                            shape: {
                                x1: bounds.x,
                                x2: bounds.x,
                                y1: bounds.y,
                                y2: bounds.y + bounds.height,
                            },
                            style: {
                                stroke: palette.selected,
                                lineWidth: 1,
                            },
                        }
                    );
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
        ];
    }, [palette]);

    const goToIndex = useCallback(
        (index) => {
            index = boundedValue(0, Math.round(index), maxBoundsRef.current);
            selectIndex(index);
            const newBounds = boundedRange(0, index - 4100, index + 4100, maxBoundsRef.current);
            selectBounds(newBounds);
        },
        [selectBounds, selectIndex, maxBoundsRef],
    );

    const dragCallback = useCallback(
        (dragState) => {
            if (dragState.type !== "drag") {
                return;
            }
            const coordinates = dragState.current;
            const clickIndex = boundedValue(0, Math.round(coordinates[0]), maxBoundsRef.current);
            const newBounds = boundedRange(0, clickIndex - 4100, clickIndex + 4100, maxBoundsRef.current);
            viewBounds(newBounds);
        },
        [maxBoundsRef, viewBounds],
    );

    const endDragCallback = useCallback(
        (dragState) => {
            if (dragState.type !== "drag") {
                return;
            }
            const coordinates = dragState.current;
            goToIndex(Math.round(coordinates[0]));
        },
        [goToIndex],
    );

    useMouseMovement(chart, "overview_grid", dragCallback, endDragCallback);

    return useMemo(
        () => ({
            dataset: overviewDataset,
            grid: overviewGrid,
            xAxis: overviewXAxis,
            yAxis: overviewYAxis,
            series: overviewSeries,
        }),
        [overviewDataset, overviewGrid, overviewXAxis, overviewYAxis, overviewSeries],
    );
}

export default useOverview;
