import ReactDOM from "react-dom";
import videojs from "video.js";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Provider, useStore } from "react-redux";
import store from "../../../redux/store";
import _ from "lodash";
import OBCSpinner from "../../util/OBC";
import { asyncLoadImage, calculateFrame, getUrlDataForFrame } from "../../util/PlaylistUtils";

const Component = videojs.getComponent("Component");
const dom = videojs.dom || videojs;
const registerPlugin = videojs.registerPlugin || videojs.plugin;

const sourceIndexSelector = (state) => state.playlist.position.sourceIndex;
const snapshotBaseURLSelector = (state) => _.get(state.playlist.data, ["mpdURLs", "snapshots"]);
const videoSelector = (state) => _.get(state.playlist.data, ["video", sourceIndexSelector(state)], []);
const videoIndexSelector = (state) => state.playlist.position.currentIndex;
const timeOffsetSelector = (state) => state.playlist.position.currentTimeOffset;
const csrfTokenSelector = (state) => state.csrfToken;

function ReactSeekOverlay({ setVisibleRef }) {
    const store = useStore();

    const [visible, setVisible] = useState();
    const [overlayImage, setOverlayImage] = useState();

    const requestedTargetRef = useRef();

    useEffect(() => {
        setVisibleRef.current = setVisible;
    }, [setVisibleRef, setVisible]);

    useEffect(() => {
        if (!visible) {
            console.log("Hiding seek overlay");
            setOverlayImage(null);
            requestedTargetRef.current = null;
        } else {
            console.log("Showing seek overlay");

            const checkFunction = () => {
                const state = store.getState();
                const video = videoSelector(state);
                const videoIndex = videoIndexSelector(state);
                const timeOffset = timeOffsetSelector(state);
                const snapshotBaseURL = snapshotBaseURLSelector(state);
                const csrfToken = csrfTokenSelector(state);
                const currentVideo = video[videoIndex];

                let targetURL;
                if (currentVideo) {
                    const frameCount = currentVideo[5];
                    let range = null;
                    let cacheKey;
                    if (frameCount) {
                        const frameIndex = calculateFrame(video, videoIndex, timeOffset);
                        const urlData = getUrlDataForFrame(video, videoIndex, frameIndex);
                        targetURL = snapshotBaseURL + urlData.imageFile;
                        range = urlData.range;
                        cacheKey = targetURL + frameIndex;
                    } else {
                        targetURL = snapshotBaseURL + currentVideo[0] + ".jpg";
                        cacheKey = targetURL;
                    }

                    if (csrfToken) {
                        targetURL += `?csrf=${csrfToken}`;
                    }

                    if (cacheKey !== requestedTargetRef.current) {
                        requestedTargetRef.current = cacheKey;
                        console.log("Showing seek overlay with URL:", targetURL);
                        asyncLoadImage(targetURL, range)
                            .then((imageData) => {
                                if (visible && requestedTargetRef.current === targetURL) {
                                    console.log("Got frame image data");
                                    setOverlayImage(imageData);
                                }
                            })
                            .catch(() => {
                                //do nothing
                            });
                    }
                }
            };
            checkFunction();
            return store.subscribe(checkFunction);
        }
    }, [visible, store, requestedTargetRef]);

    const style = useMemo(() => {
        let style = {};
        if (visible) {
            if (overlayImage) {
                style.backgroundImage = `url(${overlayImage})`;
            }
        } else {
            style.display = "none";
        }
        return style;
    }, [visible, overlayImage]);

    const innerContent = useMemo(() => {
        if (visible) {
            return <OBCSpinner colorScheme="mono" />;
        } else {
            return null;
        }
    }, [visible]);

    return (
        <div
            className="SeekOverlay"
            style={style}>
            {innerContent}
        </div>
    );
}

class SeekOverlay extends Component {
    constructor(player, options) {
        super(player, options);

        this.loading = false;

        player.on("aivrStartSeek", () => {
            this.checkSeek(true);
        });
        player.on("seeking", () => {
            this.checkSeek(true);
        });
        player.on("seeked", () => {
            this.checkSeek(false);
        });
        player.on("aivrLoadingStarted", () => {
            const thisPlayer = this.player();
            if (thisPlayer) {
                this.loading = true;
                this.checkSeek(thisPlayer.seeking() || thisPlayer.scrubbing());
            }
        });
        player.on("aivrLoadingComplete", () => {
            const thisPlayer = this.player();
            if (thisPlayer) {
                this.loading = false;
                this.checkSeek(thisPlayer.seeking() || thisPlayer.scrubbing());
            }
        });

        this.hide();
    }

    checkSeek(seeking) {
        const self = this;
        try {
            if (seeking && !this.loading) {
                this.show();
                setTimeout(() => {
                    try {
                        const thisPlayer = self.player();
                        if (thisPlayer) {
                            self.checkSeek(thisPlayer.seeking() || thisPlayer.scrubbing());
                        }
                    } catch (e) {
                        //do nothing
                    }
                }, 1000);
            } else {
                this.hide();
            }
        } catch (ex) {
            console.log("Unexpected exception:", ex);
        }
    }

    createEl() {
        const el = dom.createEl("div", {
            className: "vjs-seek-overlay vjs-hidden",
        });

        this.setVisibleRef = React.createRef();

        ReactDOM.render(
            <Provider store={store}>
                <ReactSeekOverlay setVisibleRef={this.setVisibleRef} />
            </Provider>,
            el,
        );

        return el;
    }

    dispose() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el());
        }
    }

    hide() {
        super.hide();
        if (this.setVisibleRef.current) {
            this.setVisibleRef.current(false);
        }
    }

    show() {
        if (this.setVisibleRef.current) {
            this.setVisibleRef.current(true);
        }
        super.show();
    }
}

videojs.registerComponent("SeekOverlay", SeekOverlay);

const seekOverlayPlugin = function (options) {
    const playerChild = this.addChild("seekOverlay", options);

    this.el().insertBefore(playerChild.el(), this.controlBar.el());

    return playerChild;
};

registerPlugin("seekOverlay", seekOverlayPlugin);
