import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PatrolTablePrinted from "./PatrolTablePrinted";

const patrolReportFormSelector = (state) => state.schemaInterface.patrolReport.form;

const DEFECT_CHUNK_SIZE = 6;

const PatrolDefectsPrinted = React.forwardRef((props, ref) => {
    const patrolReportForm = useSelector(patrolReportFormSelector);

    const numberOfSheets = useMemo(() => {
        const numberOfDefects = patrolReportForm.defectList.length;
        if (numberOfDefects < 8) {
            return 1;
        } else {
            return Math.ceil(numberOfDefects / DEFECT_CHUNK_SIZE);
        }
    }, [patrolReportForm]);

    const allDefects = useMemo(() => {
        return [...patrolReportForm.defectList, ...patrolReportForm.customDefectList];
    }, [patrolReportForm]);

    const chunkedDefects = useMemo(() => {
        if (allDefects && allDefects.length) {
            return _.chunk(allDefects, DEFECT_CHUNK_SIZE);
        } else {
            return [];
        }
    }, [allDefects]);

    const extraBlankRows = (i, chunk) => {
        if (i === chunkedDefects.length - 1) {
            const extraRows = DEFECT_CHUNK_SIZE - chunk.length;
            return Array.from(Array(extraRows).keys()).map(() => {
                return (
                    <tr className="row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            });
        }
    };

    return (
        <div ref={ref}>
            {chunkedDefects && chunkedDefects.length ? (
                chunkedDefects.map((chunk, i) => {
                    return (
                        <PatrolTablePrinted
                            chunk={chunk}
                            i={i}
                            extraBlankRows={extraBlankRows}
                            numberOfSheets={numberOfSheets}
                        />
                    );
                })
            ) : (
                <PatrolTablePrinted
                    chunkedDefects={chunkedDefects}
                    chunk={[]}
                    i={-1}
                    extraBlankRows={extraBlankRows}
                    numberOfSheets={numberOfSheets}
                    blankPage
                />
            )}
        </div>
    );
});

export default PatrolDefectsPrinted;
