import React, { useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import PatrolItem from "./PatrolItem";
import _ from "lodash";
import { Button, Modal } from "antd";
import { archivePatrolPlan, assignPatrol } from "../../redux/actions/index";
import moment from "moment";

const GUTTER_SIZE = 8;
const SESSION_ITEM_HEIGHT = 100;

const InfinitePatrols = ({ patrols, searchQuery, groupByDate }) => {
    const dispatch = useDispatch();

    const [confirmModalOpen, setConfirmModalOpen] = useState();
    const [reassignModalOpen, setReassignModalOpen] = useState();
    const [archiveModalOpen, setArchiveModalOpen] = useState();
    const [currentPatrol, setCurrentPatrol] = useState();
    const [currentPlanData, setCurrentPlanData] = useState();
    const [userToAssign, setUserToAssign] = useState();

    const openConfirmModal = (patrolID, userID) => {
        setCurrentPatrol(patrolID);
        setUserToAssign(userID);
        setConfirmModalOpen(true);
    };

    const openReassignModal = (patrolID, userID) => {
        setCurrentPatrol(patrolID);
        setUserToAssign(userID);
        setReassignModalOpen(true);
    };

    const confirmAssignUser = (notify) => {
        dispatch(assignPatrol(currentPatrol, userToAssign, notify));
        setConfirmModalOpen(false);
        setReassignModalOpen(false);
    };

    const openArchiveModal = (e, planData) => {
        e.stopPropagation();
        setCurrentPlanData(planData);
        setArchiveModalOpen(true);
    };

    const confirmArchive = () => {
        dispatch(archivePatrolPlan(currentPlanData.id, !currentPlanData.archived));
        setArchiveModalOpen(false);
    };

    let today = moment().startOf("day");
    let listItems = [];
    let last_patrol_date = null;
    let last_patrol_unix = null;
    patrols.forEach((patrol, index) => {
        // patrols can be assumed to be ascending due_ts order

        let patrol_unix = _.get(patrol, "due_ts", 0);
        let patrol_date = moment.unix(patrol_unix).format("DD/MM/YYYY");

        if (groupByDate && patrol_unix && (!last_patrol_date || last_patrol_date != patrol_date)) {
            // this patrol is scheduled for a different day to the last one

            let divider_name = moment.unix(patrol_unix).calendar(null, {
                sameDay: "DD/MM/YYYY - [Today]",
                nextDay: "DD/MM/YYYY - [Tomorrow]",
                nextWeek: "DD/MM/YYYY - [This] dddd",
                lastDay: "DD/MM/YYYY - [Yesterday]",
                lastWeek: "DD/MM/YYYY - [Last] dddd",
                sameElse: "DD/MM/YYYY",
            });

            if (!last_patrol_date && patrol_unix < today.unix()) {
                listItems.push(<div className="PatrolListGroupDivider">Due Patrols</div>);
            }

            if (patrol_unix > today.unix() && last_patrol_unix < today.unix()) {
                listItems.push(<div className="PatrolListGroupDivider">Upcoming Patrols</div>);
            }

            // always add date section divider
            listItems.push(<div className="PatrolListDivider">{divider_name}</div>);
        }

        last_patrol_date = patrol_date;
        last_patrol_unix = patrol_unix;

        listItems.push(
            <PatrolItem
                style={
                    {
                        // ...style,
                        // left: style.left,
                        // top: style.top + GUTTER_SIZE,
                        // height: SESSION_ITEM_HEIGHT
                    }
                }
                planData={patrol}
                index={index}
                openConfirmModal={openConfirmModal}
                openReassignModal={openReassignModal}
                openArchiveModal={openArchiveModal}
            />,
        );
    });

    const assignModal = (
        <Modal
            title="Confirm"
            visible={confirmModalOpen}
            // onOk={confirmAssignUser}
            footer={[
                <Button onClick={() => setConfirmModalOpen(false)}>Cancel</Button>,
                <Button
                    onClick={() => confirmAssignUser(false)}
                    type="primary">
                    Assign
                </Button>,
                <Button
                    onClick={() => confirmAssignUser(true)}
                    type="primary">
                    Assign & Notify
                </Button>,
            ]}
            onCancel={() => setConfirmModalOpen(false)}>
            <p>Are you sure you would like to assign this patrol?</p>
        </Modal>
    );

    const reassignModal = (
        <Modal
            title="Confirm"
            visible={reassignModalOpen}
            // onOk={confirmAssignUser}
            footer={[
                <Button onClick={() => setReassignModalOpen(false)}>Cancel</Button>,
                <Button
                    onClick={() => confirmAssignUser(false)}
                    type="primary">
                    Re-Assign
                </Button>,
                <Button
                    onClick={() => confirmAssignUser(true)}
                    type="primary">
                    Re-Assign & Notify
                </Button>,
            ]}
            onCancel={() => setReassignModalOpen(false)}>
            <p>Are you sure you would like to re-assign this patrol? All existing progress will be reset.</p>
        </Modal>
    );

    const archiveModal = (
        <Modal
            title="Confirm"
            visible={archiveModalOpen}
            onOk={confirmArchive}
            onCancel={() => setArchiveModalOpen(false)}>
            <p>Are you sure? This will cause this patrol to be completely hidden, and only supervisors will be able to view and unarchive it.</p>
        </Modal>
    );

    return (
        <>
            {assignModal}
            {reassignModal}
            {archiveModal}
            {/* <AutoSizer className="sessionAutosizer">
                {({height, width}) => (
                    <FixedSizeList
                        height={height + GUTTER_SIZE}
                        width={width}
                        itemSize={SESSION_ITEM_HEIGHT}
                        itemCount={patrolPlans.length}>
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer> */}
            {listItems}
        </>
    );
};

export default InfinitePatrols;
