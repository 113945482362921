import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEllipsisH, faExclamationCircle, faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { createGPXImport, fetchImportTasks } from "../redux/actions/index";
import { MEMOIZED_DOMAIN_URL } from "../components/util/HostUtils";
import { Button, Modal, Select } from "antd";
import moment from "moment";
import Tippy from "@tippyjs/react";

const DUMMY_GPX_PREFIX = "GOPRO-";

class ImportTaskItem extends React.PureComponent {
    goToSession = () => {
        window.open(`/view/${this.props.task.sessionID}`);
    };

    render() {
        const task = this.props.task;
        const index = this.props.index;

        let creationDateTime = moment.unix(task.created).utc();

        let resultIcon = faSpinner;
        let resultIconClass = "InProgress";
        let statusDescription;
        if (task.status === 0) {
            statusDescription = <span>Waiting for Upload...</span>;
        } else if (task.status === 1) {
            statusDescription = <span>Awaiting Processing...</span>;
        } else if (task.status === 2) {
            let thumbnail = "https://raw" + MEMOIZED_DOMAIN_URL + `${task.deviceUUID}/${task.sessionUUID}/index.jpg`;
            let processedDateTime = moment.unix(task.processed).utc();
            if (moment().subtract(5, "minutes").isSameOrAfter(processedDateTime)) {
                resultIcon = faCheckCircle;
                resultIconClass = "Success";
                statusDescription = (
                    <Tippy
                        arrow={true}
                        theme="aivrlight"
                        content={"Click to view session (opens in new window)"}>
                        <img
                            className={"Thumbnail Clickable"}
                            src={thumbnail}
                            crossOrigin={"anonymous"}
                            onClick={this.goToSession}
                            alt={"thumbnail"}
                        />
                    </Tippy>
                );
            } else {
                statusDescription = (
                    <div className={"ThumbnailWithOverlay"}>
                        <div className={"StillProcessingOverlay"}>
                            <span>Processing...</span>
                        </div>
                        <img
                            className={"Thumbnail"}
                            src={thumbnail}
                            crossOrigin={"anonymous"}
                            alt={"Thumbnail"}
                        />
                    </div>
                );
            }
        } else {
            statusDescription = <span>{task.processingResult}</span>;
            resultIcon = faExclamationCircle;
            resultIconClass = "Failure";
        }

        return (
            <div className={"ImportTaskItem" + (index % 2 === 0 ? " Even" : " Odd")}>
                <div className={"StatusIcon"}>
                    <FontAwesomeIcon
                        className={resultIconClass}
                        icon={resultIcon}
                        spin={resultIcon === faSpinner}
                    />
                </div>
                <div className={"ImportInfo"}>
                    <div className={"CreatedDate"}>Created {creationDateTime.format("HH:mm:ss DD/MM/YYYY")}</div>
                    <div className={"Filename"}>{task.gpxFilename}</div>
                    <div className={"Filename"}>{task.videoFilename}</div>
                </div>
                <div className={"Status"}>{statusDescription}</div>
            </div>
        );
    }
}

class ImportTaskListComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.taskRefreshTimer = 0;
    }

    componentDidMount() {
        this.taskRefreshTimer = setInterval(this.refreshTasks, 30000);
        this.refreshTasks();
    }

    componentWillUnmount() {
        clearInterval(this.taskRefreshTimer);
    }

    refreshTasks = () => {
        this.props.dispatch(fetchImportTasks());
    };

    render() {
        return (
            <div className={"ImportUIComponent TaskList"}>
                <div className={"TaskListTitle"}>Import Tasks</div>
                <div className={"TaskListOuter"}>
                    <div className={"TaskInnerList"}>
                        {_.map(
                            _.sortBy(this.props.gpxImportTasks, (task) => -task.created),
                            (task, index) => (
                                <ImportTaskItem
                                    key={task.id}
                                    task={task}
                                    index={index}
                                />
                            ),
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gpxImportTasks: state.admin.gpxImportTasks,
    };
};

const ImportTaskList = connect(mapStateToProps)(ImportTaskListComponent);

class ImportManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedGPXFiles: [],
            selectedVideoFiles: [],
            requiredVideoFiles: {},
            sessionTitles: {},
            uploadInProgress: false,
            allUploadsComplete: false,
            someUploadsFailed: false,
            fileProgress: {},
            dataPoolValue: "",
        };

        this.fileObjects = {};
        this.importJobs = [];
        this.queuedUploads = [];
    }

    refreshTasks = () => {
        this.props.dispatch(fetchImportTasks());
    };

    onDrop = (acceptedFiles) => {
        _.forEach(acceptedFiles, (file) => {
            const filename = file.name.toLowerCase();
            if (filename.endsWith(".gpx")) {
                const reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = (evt) => {
                    const parser = new DOMParser();
                    const data = parser.parseFromString(evt.target.result, "application/xml");
                    const videoFullFilename = data.getElementsByTagName("trk")[0].getElementsByTagName("link")[0].getAttribute("href");
                    const defaultTitle = data.getElementsByTagName("trk")[0].getElementsByTagName("name")[0].textContent;
                    const videoFilenameParts = videoFullFilename.split("\\");
                    const requiredVideoFilename = videoFilenameParts[videoFilenameParts.length - 1].toLowerCase();
                    let selectedGPXFiles = _.reject(this.state.selectedGPXFiles, (f) => f === filename);
                    const requiredVideoFiles = _.clone(this.state.requiredVideoFiles);
                    requiredVideoFiles[filename] = requiredVideoFilename;
                    const sessionTitles = _.clone(this.state.sessionTitles);
                    sessionTitles[filename] = defaultTitle;
                    selectedGPXFiles.push(filename);
                    this.fileObjects[filename] = file;
                    selectedGPXFiles.sort();

                    this.setState({
                        selectedGPXFiles,
                        requiredVideoFiles,
                        sessionTitles,
                    });
                };
            } else if (filename.endsWith(".mp4") || filename.endsWith(".360")) {
                let selectedVideoFiles = _.reject(this.state.selectedVideoFiles, (f) => f === filename);
                selectedVideoFiles.push(filename);
                this.fileObjects[filename] = file;
                selectedVideoFiles.sort();
                let fake_gpx_name = DUMMY_GPX_PREFIX + filename.replace(".mp4", ".gpx").replace(".360", ".gpx");
                let selectedGPXFiles = _.reject(this.state.selectedGPXFiles, (f) => f === fake_gpx_name);
                const requiredVideoFiles = _.clone(this.state.requiredVideoFiles);
                const sessionTitles = _.clone(this.state.sessionTitles);
                if (filename.endsWith(".360")) {
                    sessionTitles[fake_gpx_name] = "gopro 360° track";
                    requiredVideoFiles[fake_gpx_name] = filename;
                    selectedGPXFiles.push(fake_gpx_name);
                    selectedGPXFiles.sort();
                } else if (filename.startsWith("gh")) {
                    sessionTitles[fake_gpx_name] = "gopro track";
                    requiredVideoFiles[fake_gpx_name] = filename;
                    selectedGPXFiles.push(fake_gpx_name);
                    selectedGPXFiles.sort();
                }
                this.setState({
                    selectedGPXFiles,
                    selectedVideoFiles,
                    requiredVideoFiles,
                    sessionTitles,
                });
            }
        });
    };

    editTitle = (gpxFilename, event) => {
        const sessionTitles = _.clone(this.state.sessionTitles);
        sessionTitles[gpxFilename] = event.target.value;
        this.setState({
            sessionTitles,
        });
    };

    removeItem = (filename) => {
        if (filename.endsWith(".gpx")) {
            const selectedGPXFiles = _.reject(this.state.selectedGPXFiles, (f) => f === filename);
            const requiredVideoFiles = _.pickBy(this.state.requiredVideoFiles, (v, k) => {
                return k !== filename;
            });
            const sessionTitles = _.pickBy(this.state.sessionTitles, (v, k) => {
                return k !== filename;
            });
            this.setState({
                selectedGPXFiles,
                requiredVideoFiles,
                sessionTitles,
            });
            delete this.fileObjects[filename];
        } else {
            const selectedVideoFiles = _.reject(this.state.selectedVideoFiles, (f) => f === filename);
            this.setState({
                selectedVideoFiles,
            });
            delete this.fileObjects[filename];
        }
    };

    clearList = () => {
        this.setState({
            selectedGPXFiles: [],
            selectedVideoFiles: [],
            requiredVideoFiles: {},
            sessionTitles: {},
        });
        this.fileObjects = {};
    };

    checkUploadStatus = () => {
        //Go through the state's fileProgress object and check if the upload is done, failed etc.
        let allUploadsComplete = true;
        let someUploadsFailed = false;
        let noUploadsInProgress = true;

        _.forEach(this.state.fileProgress, (progress) => {
            if (progress.failed) {
                someUploadsFailed = true;
            }
            if (!(progress.complete || progress.failed)) {
                allUploadsComplete = false;
                if (progress.started) {
                    noUploadsInProgress = false;
                }
            }
        });

        this.setState({
            allUploadsComplete,
            someUploadsFailed,
        });

        if (noUploadsInProgress) {
            if (this.queuedUploads.length > 0) {
                const nextUpload = this.queuedUploads.shift();
                this.startUpload(nextUpload);
            } else if (this.importJobs.length > 0) {
                const nextJob = this.importJobs.shift();
                this.props.dispatch(nextJob);
            }
        }
    };

    initialiseUpload = (fileObj, fileType, url) => {
        this.queuedUploads.push({ fileObj, fileType, url });
    };

    startUpload = ({ fileObj, fileType, url }) => {
        const filename = fileObj.name.toLowerCase();

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", fileType);

        const handleFailure = () => {
            const fileProgress = _.clone(this.state.fileProgress);
            const progressObj = _.clone(fileProgress[filename]);
            progressObj.failed = true;
            fileProgress[filename] = progressObj;
            this.setState(
                {
                    fileProgress,
                },
                this.checkUploadStatus,
            );
        };

        const handleSuccess = () => {
            if (xhr.status === 200) {
                const fileProgress = _.clone(this.state.fileProgress);
                const progressObj = _.clone(fileProgress[filename]);
                progressObj.complete = true;
                progressObj.current = progressObj.size;
                fileProgress[filename] = progressObj;
                this.setState(
                    {
                        fileProgress,
                    },
                    this.checkUploadStatus,
                );
            }
        };

        const noProgressAfter30SecondsTimeout = setTimeout(() => {
            xhr.abort();
        }, 30000);

        const handleProgress = (event) => {
            clearTimeout(noProgressAfter30SecondsTimeout);
            if (event.lengthComputable) {
                const progressObj = _.clone(this.state.fileProgress[filename]);
                if ((event.loaded - progressObj.current) / progressObj.size >= 0.001) {
                    progressObj.current = event.loaded;
                    const fileProgress = _.clone(this.state.fileProgress);
                    fileProgress[filename] = progressObj;
                    this.setState(
                        {
                            fileProgress,
                        },
                        this.checkUploadStatus,
                    );
                }
            }
        };

        xhr.onload = handleSuccess;
        xhr.onerror = handleFailure;
        xhr.onabort = handleFailure;
        xhr.ontimeout = handleFailure;
        xhr.upload.onprogress = handleProgress;

        const fileProgress = _.clone(this.state.fileProgress);
        const progressObj = _.clone(fileProgress[filename]);
        progressObj.started = true;
        fileProgress[filename] = progressObj;
        this.setState(
            {
                fileProgress,
            },
            this.checkUploadStatus,
        );

        try {
            xhr.send(fileObj);
        } catch (ex) {
            console.log("Exception starting upload: ", ex);
            xhr.onerror(ex);
        }
    };

    onImportCreated = (gpxFilename, videoFilename, gpxURL, videoURL) => {
        console.log("Import was created", gpxFilename, videoFilename, gpxURL, videoURL);
        const gpxFile = this.fileObjects[gpxFilename];
        const videoFile = this.fileObjects[videoFilename];
        const fileProgress = _.clone(this.state.fileProgress);
        const gpxProgressObj = _.clone(fileProgress[gpxFilename]);
        const videoProgressObj = _.clone(fileProgress[videoFilename]);
        gpxProgressObj.uploadURL = gpxURL;
        videoProgressObj.uploadURL = videoURL;
        fileProgress[gpxFilename] = gpxProgressObj;
        fileProgress[videoFilename] = videoProgressObj;
        if (!gpxFilename.startsWith(DUMMY_GPX_PREFIX)) {
            this.initialiseUpload(gpxFile, "application/xml", gpxURL);
        }
        this.initialiseUpload(videoFile, "video/mp4", videoURL);
        this.setState(
            {
                fileProgress,
            },
            this.checkUploadStatus,
        );

        this.refreshTasks();
    };

    onImportCreationFailed = (gpxFilename, videoFilename) => {
        console.log("Import creation failed", gpxFilename, videoFilename);
        const fileProgress = _.clone(this.state.fileProgress);
        const gpxProgressObj = _.clone(fileProgress[gpxFilename]);
        const videoProgressObj = _.clone(fileProgress[videoFilename]);
        gpxProgressObj.started = true;
        videoProgressObj.started = true;
        gpxProgressObj.failed = true;
        videoProgressObj.failed = true;
        fileProgress[gpxFilename] = gpxProgressObj;
        fileProgress[videoFilename] = videoProgressObj;
        this.setState(
            {
                fileProgress,
            },
            this.checkUploadStatus,
        );

        delete this.fileObjects[gpxFilename];
        delete this.fileObjects[videoFilename];
    };

    upload = () => {
        console.log("Starting Upload");
        const newFileProgress = {};

        _.forEach(this.state.selectedGPXFiles, (gpxFilename) => {
            const videoFilename = this.state.requiredVideoFiles[gpxFilename];
            const callback = _.partial(this.onImportCreated, gpxFilename, videoFilename);
            const errorCallback = _.partial(this.onImportCreationFailed, gpxFilename, videoFilename);
            const title = this.state.sessionTitles[gpxFilename];
            const dataPoolID = this.state.dataPoolValue;

            if (gpxFilename.startsWith(DUMMY_GPX_PREFIX)) {
                newFileProgress[gpxFilename] = {
                    size: 1,
                    current: 1,
                    started: true,
                    complete: true,
                    failed: false,
                };
            } else {
                const gpxFile = this.fileObjects[gpxFilename];
                newFileProgress[gpxFilename] = {
                    size: gpxFile.size,
                    current: 0,
                    started: false,
                    complete: false,
                    failed: false,
                };
            }
            const videoFile = this.fileObjects[videoFilename];
            newFileProgress[videoFilename] = {
                size: videoFile.size,
                current: 0,
                started: false,
                complete: false,
                failed: false,
            };
            const job = createGPXImport(title, gpxFilename, videoFilename, dataPoolID, callback, errorCallback);
            this.importJobs.push(job);
        });

        this.setState(
            {
                uploadInProgress: true,
                allUploadsComplete: false,
                someUploadsFailed: false,
                fileProgress: newFileProgress,
            },
            this.checkUploadStatus,
        );
    };

    addFileToList = (list, fileName, fileSize, message) => {
        let icon = faCheckCircle;
        let iconClass = "ImportFileOK";

        if (message) {
            icon = faExclamationCircle;
            iconClass = "ImportFileBad";
        }

        let inputField = null;
        let fileSizeDiv = null;
        if (fileName.endsWith(".gpx")) {
            const currentValue = this.state.sessionTitles[fileName];
            const onChange = _.partial(this.editTitle, fileName);
            inputField = (
                <input
                    className={"ImportTitle"}
                    value={currentValue}
                    onChange={onChange}
                />
            );
        } else {
            if (fileSize >= 2 * 1048576) {
                fileSizeDiv = <div className={"ImportFileSize"}>{Math.round(fileSize / 1048576)}MB</div>;
            } else {
                fileSizeDiv = <div className={"ImportFileSize"}>{Math.round(fileSize / 1024)}KB</div>;
            }
        }

        list.push(
            <div
                className={"ImportFileItem"}
                key={fileName}>
                <FontAwesomeIcon
                    className={iconClass}
                    icon={icon}
                />

                <div className={"ImportFileDetails"}>
                    <div className={"ImportFileNameAndSize"}>
                        <div className={"ImportFileName"}>{fileName}</div>
                        {fileSizeDiv}
                    </div>
                    {inputField}
                    {message && <div className={"ImportFileMessage"}>{message}</div>}
                </div>
                <button
                    className={"Remove ImportButton"}
                    onClick={_.partial(this.removeItem, fileName)}>
                    Remove
                </button>
            </div>,
        );
    };

    renderFileProgress = (progress, fileName) => {
        const progressPercentage = Math.floor((progress.current * 1000) / progress.size) / 10;
        return (
            <div
                className={"UploadProgress"}
                key={fileName}>
                {!progress.started && (
                    <FontAwesomeIcon
                        className={"ProgressNotStarted"}
                        icon={faEllipsisH}
                    />
                )}
                {progress.complete && (
                    <FontAwesomeIcon
                        className={"ProgressComplete"}
                        icon={faCheckCircle}
                    />
                )}
                {progress.failed && (
                    <FontAwesomeIcon
                        className={"ProgressFailed"}
                        icon={faTimesCircle}
                    />
                )}
                {progress.started && !(progress.complete || progress.failed) && (
                    <FontAwesomeIcon
                        className={"ProgressUploading"}
                        icon={faSpinner}
                        spin={true}
                    />
                )}
                <div className={"ProgressState"}>
                    <div className={"ProgressText"}>
                        {fileName}: {progressPercentage}%
                    </div>
                    <div
                        className={"ProgressBar"}
                        style={{ width: `${progressPercentage}%` }}
                    />
                </div>
            </div>
        );
    };

    confirmUploadsComplete = () => {
        this.setState({
            uploadInProgress: false,
            allUploadsComplete: false,
            someUploadsFailed: false,
            fileProgress: {},
            selectedGPXFiles: [],
            selectedVideoFiles: [],
            requiredVideoFiles: {},
            sessionTitles: {},
        });
        this.fileObjects = {};
        this.importJobs = [];

        this.refreshTasks();
    };

    retryFailedUploads = () => {
        const fileProgress = _.clone(this.state.fileProgress);

        _.forEach(this.state.fileProgress, (progressObj, filename) => {
            if (progressObj.failed) {
                const file = this.fileObjects[filename];
                progressObj = _.clone(progressObj);
                progressObj.started = false;
                progressObj.failed = false;
                progressObj.completed = false;
                progressObj.current = 0;
                fileProgress[filename] = progressObj;
                let fileType = "video/mp4";
                if (filename.endsWith(".gpx")) {
                    fileType = "application/xml";
                }

                this.initialiseUpload(file, fileType, progressObj.uploadURL);
            }
        });

        this.setState(
            {
                fileProgress,
            },
            this.checkUploadStatus,
        );
    };

    suppressPropagation = (event) => {
        event.stopPropagation();
    };

    renderDataPoolOptions = () => {
        return Object.keys(this.props.data_pool_permissions)
            .filter((dataPool) => {
                return this.props.data_pool_permissions[dataPool].import || this.props.userConfig.super_admin;
            })
            .map((dataPoolID) => {
                const dataPool = _.find(this.props.dataPools, (dp) => dp.id.toString() === dataPoolID.toString());
                if (dataPool) {
                    return (
                        <Select.Option
                            key={dataPoolID}
                            value={dataPoolID}>
                            {dataPool.description}
                        </Select.Option>
                    );
                }
            });
    };

    onDataPoolChange = (dataPoolID) => {
        this.setState({
            dataPoolValue: dataPoolID,
        });
    };

    render() {
        const fileDetails = [];
        let someFilesAreBad = false;

        this.state.selectedGPXFiles.forEach((fileName) => {
            const requiredVideo = this.state.requiredVideoFiles[fileName];
            const sessionTitle = this.state.sessionTitles[fileName];
            let fileSize = -1;
            if (!fileName.startsWith(DUMMY_GPX_PREFIX)) {
                fileSize = this.fileObjects[fileName].size;
            }
            const videoFilename = _.find(this.state.selectedVideoFiles, (f) => f === requiredVideo);

            let message;
            if (videoFilename) {
                if (!sessionTitle) {
                    message = `A session title is required`;
                    someFilesAreBad = true;
                } else {
                    message = false;
                }
            } else {
                message = `Missing video file ${requiredVideo}`;
                someFilesAreBad = true;
            }
            this.addFileToList(fileDetails, fileName, fileSize, message);
        });

        this.state.selectedVideoFiles.forEach((fileName) => {
            const videoIsRequired = Object.values(this.state.requiredVideoFiles).includes(fileName);

            const fileSize = this.fileObjects[fileName].size;

            let message;
            if (videoIsRequired) {
                message = false;
            } else {
                message = "No GPX file references this video";
                someFilesAreBad = true;
            }

            this.addFileToList(fileDetails, fileName, fileSize, message);
        });

        if (fileDetails.length === 0) {
            someFilesAreBad = true;
        }

        if (this.state.dataPoolValue === "") {
            someFilesAreBad = true;
        }

        let uploadProgressMessage;

        if (this.state.allUploadsComplete) {
            if (this.state.someUploadsFailed) {
                uploadProgressMessage = "One or more uploads were not successful.";
            } else {
                uploadProgressMessage = "All uploads complete.";
            }
        } else {
            uploadProgressMessage = "Upload in progress, please wait...";
        }

        let modalFooter = null;

        if (this.state.allUploadsComplete) {
            modalFooter = [];
            if (this.state.someUploadsFailed) {
                modalFooter.push(
                    <Button
                        key="back"
                        onClick={this.retryFailedUploads}>
                        Retry Failed
                    </Button>,
                );
            }
            modalFooter.push(
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.confirmUploadsComplete}>
                    Close
                </Button>,
            );
        }

        return (
            <div className="adminTableDiv ImportUI">
                <ImportTaskList />
                <div className={"ImportUIComponent DropZone"}>
                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className={"ImportFileSelector"}>
                                <input {...getInputProps()} />
                                <p className={"ImportInstructions"}>Drag and drop GPX and video files here, or click in this box to select them.</p>
                                <div className={"ImportFileListOuter"}>
                                    <div className={"ImportFileList"}>
                                        <div
                                            className={"ImportFileListInner"}
                                            onClick={this.suppressPropagation}>
                                            {fileDetails}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>

                    <div className="ImportDataPoolSelectContainer">
                        <p className="ImportDataPoolText">Data Pool:</p>
                        <Select
                            value={this.state.dataPoolValue}
                            onChange={this.onDataPoolChange}
                            className="ImportDataPoolSelect">
                            {this.renderDataPoolOptions()}
                        </Select>
                    </div>

                    <div className={"ImportButtons"}>
                        <button
                            className={"Reset ImportButton"}
                            onClick={this.clearList}>
                            Clear
                        </button>
                        <button
                            className={"Upload ImportButton"}
                            disabled={someFilesAreBad}
                            onClick={this.upload}>
                            Import
                        </button>
                    </div>
                </div>
                <Modal
                    visible={this.state.uploadInProgress}
                    closable={false}
                    maskClosable={false}
                    onOk={this.confirmUploadsComplete}
                    title={uploadProgressMessage}
                    footer={modalFooter}>
                    {_.map(this.state.fileProgress, this.renderFileProgress)}
                </Modal>
            </div>
        );
    }
}

const mapStateToManagementProps = (state) => {
    let dashboardID = state.userDetails.dashboardAccessID;
    let currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);

    return {
        data_pool_permissions: _.get(currentDashboard, ["data_pool_permissions"], {}),
        userConfig: state.userDetails.userConfig,
        dataPools: state.admin.dataPools,
    };
};

export default connect(mapStateToManagementProps)(ImportManagement);
