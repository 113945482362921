import React, { useMemo } from "react";
import _ from "lodash";
import { svgLineHasCoverage } from "../util/Geometry";

const Y_OFFSET = 0;
const X_OFFSET = 0;

const MiniPatrolSvg = ({ sessionImages, planData, selected }) => {
    const linesWithFootageCoverage = useMemo(() => {
        if (sessionImages) {
            return planData.svg.line.map((original_line) => {
                let line = { ...original_line };

                line.covered = svgLineHasCoverage(line, sessionImages, true);

                return line;
            });
        }
    }, [sessionImages, planData.svg.line]);

    const svgContent = useMemo(() => {
        let orderedLines = _.orderBy(linesWithFootageCoverage, ["covered"]);

        const lines = [];

        orderedLines.forEach((line) => {
            line["y1"] = parseFloat(line["y1"]) + Y_OFFSET;
            line["y2"] = parseFloat(line["y2"]) + Y_OFFSET;

            line["x1"] = parseFloat(line["x1"]) + X_OFFSET;
            line["x2"] = parseFloat(line["x2"]) + X_OFFSET;

            line["stroke-width"] = 8;

            let colour = "grey";
            if (line.covered) {
                colour = "orange";
            }

            if (line.covered && selected) {
                colour = "rgb(50, 150, 250)";
            }

            lines.push(
                <line
                    {...line}
                    stroke={colour}
                />,
            );
        });

        return <>{lines}</>;
    }, [linesWithFootageCoverage, selected]);

    return (
        <svg
            className="SchemaRuns__Item__Svg"
            baseProfile="full"
            version="1.1"
            viewBox="0 0 1176 350"
            xmlns="http://www.w3.org/2000/svg">
            {svgContent}
        </svg>
    );
};

export default MiniPatrolSvg;
