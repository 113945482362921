import { notification } from "antd";
import _ from "lodash";
import moment from "moment";
import {
    keyLookup,
    videoTimeLookup,
    absoluteTimeLookup,
    videoToImageIndex,
    imageToVideoIndex,
    keySourceLookup,
    calculateOffset,
    getPositionAndOffset,
    videoKeyToTimestamp,
    binarySearch,
} from "../../components/util/PlaylistUtils";
import { MEMOIZED_DOMAIN_URL } from "../../components/util/HostUtils";
import { coordinateToTimestamp } from "../../components/util/Geometry";

import { getObservation, getSnappingValidations, primaryRailImageConfig } from "./index";
import { receiveShortcutPoints } from "./shortcutActions";
import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import { updateRouteDatumDistances, featureOverlaySetDatum } from "./featureActions";
import { fetchUserSketches, fetchUserMeasurements, fetchUserAnnotations, fetchUserAnnotationTypes } from "./contentActions";
import { resetObservations } from "./adminActions";
import { getFavouriteCategories, userFavouriteCategories } from "./userActions";
import { logEvent, updateLastActive } from "./auditActions";
import { getMarkersForSession } from "./markerActions";
import { fetchMapGeometry, fetchMapGeometryV2, getRouteCoordinateSystems, routeCoordinateLookup } from "./geometryActions";
import { inflateSync } from "react-zlib-js";
import { getCCTVMarkers } from "./markerActions";

export const DATA_FETCH_QUEUE = "DATA_FETCH_QUEUE";
export const REQUEST_PLAYLIST_TIMESTAMP = "REQUEST_PLAYLIST_TIMESTAMP";
export const CLEAR_REQUESTED_TIMESTAMP = "CLEAR_REQUESTED_TIMESTAMP";
export const SESSION_LOGS = "SESSION_LOGS";
export const SEGMENT_SELECTED = "SEGMENT_SELECTED";
export const SEGMENT_CLEARED = "SEGMENT_CLEARED";
export const ROUTE_HIGHLIGHTED = "ROUTE_HIGHLIGHTED";
export const RECEIVE_SESSION_TAGS = "RECEIVE_SESSION_TAGS";
export const UNCACHE_SESSION_LIST = "UNCACHE_SESSION_LIST";
export const ROUTE_CALIBRATION_DATA = "ROUTE_CALIBRATION_DATA";
export const SESSIONS_LIST = "SESSIONS_LIST";
export const ADMIN_SESSIONS_LIST = "ADMIN_SESSIONS_LIST";
export const ROUTE_METADATA = "ROUTE_METADATA";
export const SESSION_IDS = "SESSION_IDS";
export const REQUEST_PLAYLIST_POSITION = "REQUEST_PLAYLIST_POSITION";
export const CURRENT_PLAYLIST_POSITION = "CURRENT_PLAYLIST_POSITION";
export const PLAYLIST = "PLAYLIST";
export const EXPORT_SEGMENT = "EXPORT_SEGMENT";
export const FETCHING_EXPORT = "FETCHING_EXPORT";
export const RECEIVE_EXPORT = "RECEIVE_EXPORT";
export const RECEIVE_EXPORT_UNAUTHORISED = "RECEIVE_EXPORT_UNAUTHORISED";
export const RECEIVE_USER_EXPORTS = "RECEIVE_USER_EXPORTS";
export const USER_EXPORTS = "USER_EXPORTS";
export const MAP_SEGMENT_IDS = "MAP_SEGMENT_IDS";
export const GPS_TIME_OFFSETS = "GPS_TIME_OFFSETS";
export const GO_TO_BOUNDS = "GO_TO_BOUNDS";
export const RECEIVE_TRACK_GEOM_DATA = "RECEIVE_TRACK_GEOM_DATA";
export const CLEAR_TRACK_GEOM = "CLEAR_TRACK_GEOM";
export const RECEIVE_TRACK_GEOM_HEADERS = "RECEIVE_TRACK_GEOM_HEADERS";
export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const TOGGLE_PLAYER_STATE = "TOGGLE_PLAYER_STATE";
export const CLOSE_FULLSCREEN = "CLOSE_FULLSCREEN";
export const SHARE_LINK_DETAILS = "SHARE_LINK_DETAILS";
export const SHARE_LINK = "SHARE_LINK";
export const TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN";
export const SET_FULLSCREEN_VALUE = "SET_FULLSCREEN_VALUE";
export const RESET_STILL_IMAGE_ADJUSTMENTS = "RESET_STILL_IMAGE_ADJUSTMENTS";
export const ADD_FAVOURITE_SESSION = "ADD_FAVOURITE_SESSION";
export const REMOVE_FAVOURITE_SESSION = "REMOVE_FAVOURITE_SESSION";
export const SET_SESSION_SEARCH = "SET_SESSION_SEARCH";
export const SET_SESSION_FAVOURITES = "SET_SESSION_FAVOURITES";
export const SET_SESSION_DATES = "SET_SESSION_DATES";
export const SET_SESSION_TAGS = "SET_SESSION_TAGS";
export const SET_SESSION_FLAGGED = "SET_SESSION_FLAGGED";
export const SET_SESSIONS_REFRESHING = "SET_SESSIONS_REFRESHING";
export const MAP_POINT_SELECTED = "MAP_POINT_SELECTED";
export const SET_VIDEO_SPEED = "SET_VIDEO_SPEED";
export const SESSIONS_WEATHER_DATA = "SESSIONS_WEATHER_DATA";
export const SET_REQUESTED_CONTENT = "SET_REQUESTED_CONTENT";
export const CLEAR_REQUESTED_CONTENT = "CLEAR_REQUESTED_CONTENT";
export const SESSION_CONTINUOUS_OBSERVATIONS = "SESSION_CONTINUOUS_OBSERVATIONS";
export const SESSION_OBSERVATIONS = "SESSION_OBSERVATIONS";
export const PROCESS_OBSERVATIONS = "PROCESS_OBSERVATIONS";
export const SET_OVERLAY = "SET_OVERLAY";
export const SET_OVERLAY_DEFAULTS = "SET_OVERLAY_DEFAULTS";
export const UPDATE_SESSIONS_LIST = "UPDATE_SESSIONS_LIST";
export const ARCHIVE_INSPECTION_SESSION = "ARCHIVE_INSPECTION_SESSION";
export const SET_LAST_FLAG_REASON = "SET_LAST_FLAG_REASON";
export const SET_HIDE_LOW_QUALITY_SESSIONS = "SET_HIDE_LOW_QUALITY_SESSIONS";
export const OBSERVATION_DETECTIONS = "OBSERVATION_DETECTIONS";
export const SESSIONS_ENVIRONMENT_DATA = "SESSIONS_ENVIRONMENT_DATA";

export const SESSION_FILTERS_DEFAULTS = {
    search: "",
    favourites: false,
    date: {
        from: null,
        to: null,
    },
    tags: [],
    show_flagged: false,
    refreshing: false,
    filter_qa_tags: false,
    qa_tags: [],
};

export const SESSION_QA_TAGS = ["QA_Normal", "QA_Out_Of_Focus"];

export function refresh(callback = false) {
    return (dispatch) => {
        dispatch(uncacheSessions());
        dispatch(getMarkersForSession(null));
        dispatch(fetchMapGeometryV2());
        dispatch(fetchUserAnnotationTypes());
        dispatch(getRouteCoordinateSystems());
        dispatch(getSessionList(callback));
        dispatch(getAllSessionTags());
        dispatch(getCCTVMarkers());
    };
}

const getSessionListDebounced = _.debounce((dispatch) => {
    dispatch(getSessionList());
}, 1500);

export function setHideLowQualitySessions(hideLowQualitySessions) {
    return {
        type: SET_HIDE_LOW_QUALITY_SESSIONS,
        hideLowQualitySessions,
    };
}

export function setSessionSearch(query) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SESSION_SEARCH,
            query,
        });

        getSessionListDebounced(dispatch);
    };
}

export function setSessionFavourites(favouritesOnly) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SESSION_FAVOURITES,
            favouritesOnly,
        });
        dispatch(getSessionList());
    };
}

export function setSessionDateFilter(dates) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SESSION_DATES,
            dates,
        });
        dispatch(getSessionList());
    };
}

export function setSessionTagFilter(tags, abortSignal = null) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SESSION_TAGS,
            tags,
        });
        dispatch(getSessionList(null, abortSignal));
    };
}

export function setSessionFlagged(flagged) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SESSION_FLAGGED,
            flagged,
        });
        dispatch(getSessionList());
    };
}

export function toggleFullscreen() {
    return {
        type: TOGGLE_FULLSCREEN,
    };
}

export function setFullscreenValue(value) {
    return {
        type: SET_FULLSCREEN_VALUE,
        value,
    };
}

export function closeFullscreen() {
    return {
        type: CLOSE_FULLSCREEN,
    };
}

export function updateRange({ to, from }) {
    return {
        type: UPDATE_DATE_RANGE,
        to,
        from,
    };
}

export function togglePlayerState(state) {
    return {
        type: TOGGLE_PLAYER_STATE,
        playerState: state,
    };
}

export function gpsTimeOffsets(sessionID, offsets, from_server = false) {
    return {
        type: GPS_TIME_OFFSETS,
        sessionID,
        offsets,
        from_server,
    };
}

export function receiveExports(exports) {
    return {
        type: RECEIVE_USER_EXPORTS,
        exports,
    };
}

export function startFetchExport() {
    return {
        type: FETCHING_EXPORT,
    };
}

export const receiveTrackGeomData = (data) => {
    return {
        type: RECEIVE_TRACK_GEOM_DATA,
        data,
    };
};

export const clearTrackGeomData = () => {
    return {
        type: CLEAR_TRACK_GEOM,
    };
};

export function receiveExport(export_details) {
    return {
        type: RECEIVE_EXPORT,
        export_details,
    };
}

export function exportUnauthorised(required_access_id) {
    return {
        type: RECEIVE_EXPORT_UNAUTHORISED,
        required_access_id,
    };
}

export function mapSegmentIDs(mapSegmentIDs) {
    return {
        type: MAP_SEGMENT_IDS,
        mapSegmentIDs,
    };
}

export function requestPlaylistTimestamp(timestamp) {
    return {
        type: REQUEST_PLAYLIST_TIMESTAMP,
        timestamp,
    };
}

export function clearRequestedTimestamp() {
    return {
        type: CLEAR_REQUESTED_TIMESTAMP,
    };
}

export function segmentSelection(sessionID, startIndex, endIndex) {
    return {
        selection: {
            session: sessionID,
            start: startIndex,
            end: endIndex,
        },
        type: SEGMENT_SELECTED,
    };
}

export function routeHighlighted(sessionID) {
    return {
        type: ROUTE_HIGHLIGHTED,
        sessionID,
    };
}

function receiveSessionTags(tags) {
    return {
        type: RECEIVE_SESSION_TAGS,
        tags,
    };
}

export const uncacheSessions = () => {
    return {
        type: UNCACHE_SESSION_LIST,
    };
};

export function routeCalibrationData(data) {
    console.log("Route calibration data:", data);
    return {
        type: ROUTE_CALIBRATION_DATA,
        data,
    };
}

export function segmentCleared() {
    return {
        type: SEGMENT_CLEARED,
    };
}

export function sessionsList(sessions) {
    return {
        type: SESSIONS_LIST,
        sessions,
    };
}

export function sessionIDs(sessionIDs) {
    return {
        type: SESSION_IDS,
        sessionIDs,
    };
}

export function adminSessionsList(sessions) {
    return {
        type: ADMIN_SESSIONS_LIST,
        sessions,
    };
}

export function routeMetadata(sessionID, metadataType, metadata) {
    return {
        type: ROUTE_METADATA,
        sessionID,
        metadataType,
        metadata,
    };
}

export function setMapPointSelected(mapPointSelected, coords) {
    return {
        type: MAP_POINT_SELECTED,
        mapPointSelected,
        coords,
    };
}

export function updateSessions(sessions) {
    return {
        type: UPDATE_SESSIONS_LIST,
        sessions: sessions,
    };
}

export function setLastFlagReason(reason) {
    return {
        type: SET_LAST_FLAG_REASON,
        reason: reason,
    };
}

let pendingSessionsToRequest = {};

export function getSessionData(session_id, force = false, category_id = null) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const cachedSessions = getState().sessions;
            if (!force && cachedSessions.hasOwnProperty(session_id)) {
                resolve(cachedSessions[session_id]);
                return;
            }

            //Need to request the session data for this session

            //General flow here:
            //Add the session ID & promise to the list of sessions to request
            if (!pendingSessionsToRequest.hasOwnProperty(session_id)) {
                pendingSessionsToRequest[session_id] = [{ resolve, reject }];
            } else {
                pendingSessionsToRequest[session_id].push({ resolve, reject });
            }

            //Call the method that checks and requests the session data
            dispatch(sessionDataRequestDispatcher(category_id));
        }).catch((e) => {});
    };
}

function sessionDataRequestDispatcher(category_id) {
    return {
        queue: SESSIONS_LIST,
        callback: (next, dispatch, getState) => {
            const pendingSessionsAtTimeOfRequest = pendingSessionsToRequest;

            const session_ids = Object.keys(pendingSessionsAtTimeOfRequest).slice(0, 100);
            const keysFiltered = Object.keys(pendingSessionsAtTimeOfRequest).slice(100);

            pendingSessionsToRequest = {};
            keysFiltered.forEach((sessionID) => {
                pendingSessionsToRequest[sessionID] = pendingSessionsAtTimeOfRequest[sessionID];
            });

            if (session_ids.length === 0) {
                //Nothing to do
                setTimeout(() => next(), 0);
                return;
            }

            let postBody = {
                action: "get",
                session_ids,
                category_id,
            };

            console.log("Making session list request with IDs: ", session_ids);

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ sessions }) => {
                    if (sessions) {
                        console.log("Session list retrieved, contains " + sessions.length + " sessions.");

                        let sessionsMap = _.keyBy(sessions, (session) => {
                            return session.id;
                        });
                        dispatch(sessionsList(sessionsMap));
                    } else {
                        dispatch(sessionsList({}));
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve sessions list", "An error occurred while fetching the session list", error);
                    dispatch(sessionsList({}));
                })
                .then(() => {
                    const cachedSessions = getState().sessions;
                    session_ids.forEach((session_id) => {
                        const promises = pendingSessionsAtTimeOfRequest[session_id];
                        if (cachedSessions.hasOwnProperty(session_id)) {
                            const session_data = cachedSessions[session_id];
                            for (let i = 0; i < promises.length; i++) {
                                promises[i].resolve(session_data);
                            }
                        } else {
                            for (let i = 0; i < promises.length; i++) {
                                promises[i].reject();
                            }
                        }
                    });
                })
                .finally(() => {
                    //After the request is complete, schedule another in case more session data is pending
                    next();
                    dispatch(sessionDataRequestDispatcher(category_id));
                    console.log("Session list request complete.");
                });
        },
    };
}

function playlist(routeID, mpdURLs, videoPlaylist, imagePlaylist, route_locations, discontinuity_indexes, system_id, data = null) {
    return (dispatch, getState) => {
        if (routeID && videoPlaylist && imagePlaylist) {
            dispatch(logEvent("Routes", "Select Route", routeID));
        }
        dispatch({
            type: PLAYLIST,
            mpdURLs,
            routeID,
            videoPlaylist,
            imagePlaylist,
            route_locations,
            discontinuity_indexes,
            data,
            system_id,
        });
        dispatch(updateRouteDatumDistances());
    };
}

export function requestPlaylistPosition(isVideo, isEnhanced, isStills, sourceIndex, index, timeOffset) {
    return (dispatch, getState) => {
        const lastSourceIndex = getState().playlist.position.sourceIndex;
        dispatch({
            type: REQUEST_PLAYLIST_POSITION,
            isVideo,
            isEnhanced,
            isStills,
            sourceIndex,
            index,
            timeOffset,
        });
        if (sourceIndex !== lastSourceIndex) {
            dispatch(updateRouteDatumDistances());
        }
    };
}

export function currentPlaylistPosition(index, coords, offset) {
    const doCurrentPlaylistPosition = (dispatch, getState) => {
        dispatch({
            type: CURRENT_PLAYLIST_POSITION,
            index,
            coords,
            offset,
        });
    };
    return doCurrentPlaylistPosition;
}

export function goToBounds(bounds) {
    if (bounds.north !== undefined && bounds.south !== undefined) {
        console.log("debug bounds", bounds);
        return {
            type: GO_TO_BOUNDS,
            bounds,
        };
    } else {
        return (dispatch, getState) => {
            dispatch(getSessionData(bounds)).then((sessionData) => {
                console.log("debug session data", sessionData.bounds);
                dispatch(goToBounds(sessionData.bounds));
            });
        };
    }
}

function calculateSourceOverride(tags, streamInfo) {
    let sourceIndexOverride = null;
    if (tags && tags.length && streamInfo && streamInfo.length) {
        streamInfo.forEach((info, idx) => {
            if (tags.includes(_.get(info, ["info", "priority_tag"]))) {
                sourceIndexOverride = idx;
            }
        });
    }

    return sourceIndexOverride;
}

export function routeSelected(
    sessionID,
    timestamp,
    enhanced,
    stills,
    sourceIndex,
    frame = 0,
    requested = false,
    updateCurrent = false,
    fromShortcut = null,
    sessionPositionFilter = null,
    exitFullscreen = true,
) {
    return (dispatch, getState) => {
        let wasVideo = getState().playlist.position.isVideo;
        if (wasVideo === undefined) {
            wasVideo = true;
        }

        let wasEnhanced = getState().playlist.position.isEnhanced;
        if (wasEnhanced === undefined) {
            wasEnhanced = _.get(getState(), ["userPreferences", "defaultEnhanced"], false);
        }
        if (enhanced === undefined) {
            enhanced = wasEnhanced;
        }

        let wasStills = getState().playlist.position.isStills;
        if (wasStills === undefined) {
            wasStills = _.get(getState(), ["userPreferences", "defaultStills"], false);
        }
        if (stills === undefined) {
            stills = wasStills;
        }

        let lastSourceIndex = getState().playlist.position.sourceIndex;
        if (lastSourceIndex === undefined) {
            lastSourceIndex = 0;
        }

        const positionFilters = getState().loginDataFilters;
        // video playlists == empty
        //
        function onGetPlaylist(
            sessionID,
            mpdURLs,
            videoPlaylists,
            imagePlaylist,
            gpsOffsets,
            route_coordinates,
            discontinuity_indexes,
            system_id,
            _timestamp = 0,
            data = null,
            _sourceIndex = null,
        ) {
            let hasVideo = true;

            const hasImages = imagePlaylist && imagePlaylist.length > 0;

            const hasLastSourceIndex = !!videoPlaylists[lastSourceIndex] && !!videoPlaylists[lastSourceIndex].length;

            let updatedIndex = null;
            if (!hasLastSourceIndex) {
                const sessionData = getState().sessions[sessionID];
                let sourceIndexOverride = calculateSourceOverride(_.get(sessionData, "tags", []), _.get(sessionData, "stream_info", []));
                if (_.isNil(sourceIndexOverride)) {
                    videoPlaylists.forEach((playlist, idx) => {
                        if (playlist.length && _.isNil(updatedIndex)) {
                            updatedIndex = idx;
                        }
                    });
                }
            }
            lastSourceIndex = hasLastSourceIndex ? lastSourceIndex : updatedIndex;

            let useVideo = wasVideo;
            if (useVideo && !hasVideo) {
                useVideo = false;
            } else if (!useVideo && !hasImages && hasVideo) {
                useVideo = true;
            }

            if (!useVideo) {
                stills = false;
            }
            if (_sourceIndex > videoPlaylists.length - 1) {
                _sourceIndex = null;
            }

            if (
                getState().playlist.data.routeID !== sessionID ||
                getState().playlist.data.video !== videoPlaylists ||
                getState().playlist.data.image !== imagePlaylist
            ) {
                dispatch(featureOverlaySetDatum(null, null));

                let sortedRouteCoordinates = route_coordinates;

                if (!_.isArray(route_coordinates)) {
                    sortedRouteCoordinates = _.orderBy(
                        Object.keys(route_coordinates).map((key) => {
                            let locationObj = {
                                location: route_coordinates[key],
                                timestamp: key,
                                sources: route_coordinates[key][3],
                            };
                            return locationObj;
                        }),
                        "timestamp",
                    );
                }

                dispatch(playlist(sessionID, mpdURLs, videoPlaylists, imagePlaylist, sortedRouteCoordinates, discontinuity_indexes, system_id, data));
                dispatch(fetchUserSketches(sessionID));
                dispatch(fetchUserMeasurements(sessionID));
                dispatch(fetchUserAnnotations(sessionID));
                dispatch(getSnappingValidations(sessionID));
                dispatch(getSessionObservations(sessionID));
                dispatch(getSessionEnvironmentData(sessionID));
            }

            if (gpsOffsets) {
                dispatch(gpsTimeOffsets(sessionID, gpsOffsets, true));
            }

            if (sourceIndex === undefined) {
                sourceIndex = lastSourceIndex;
            }

            if (!_.isNil(_sourceIndex)) {
                sourceIndex = _sourceIndex;
            }

            let use_snapped = getState().snappedRoute || false;

            let videoPlaylistIndex = -1;
            timestamp = timestamp || _timestamp;
            if (timestamp) {
                if (_.isArray(timestamp)) {
                    if (sourceIndex === undefined) {
                        sourceIndex = lastSourceIndex;
                    }

                    let playlist;
                    if (useVideo) {
                        playlist = videoPlaylists[sourceIndex];
                    } else {
                        playlist = imagePlaylist;
                    }

                    const offsets = _.get(gpsOffsets, sourceIndex, []);
                    if ((!playlist || !playlist.length) && data) {
                        playlist = data;
                    }
                    timestamp = coordinateToTimestamp(timestamp, playlist, use_snapped, offsets);
                }

                if (typeof timestamp === "string") {
                    if (hasVideo && sourceIndex !== undefined) {
                        let keySource = keySourceLookup(timestamp, videoPlaylists);
                        videoPlaylistIndex = keySource[0];
                        sourceIndex = keySource[1];
                    }
                    if (hasVideo && videoPlaylistIndex === -1) {
                        for (let sidx = 0; sidx < videoPlaylists.length; sidx++) {
                            videoPlaylistIndex = keyLookup(timestamp, videoPlaylists[sidx]);
                            if (videoPlaylistIndex !== -1) {
                                if (sourceIndex !== undefined) {
                                    let playlistItem = videoPlaylists[sidx][videoPlaylistIndex];
                                    let absoluteTimestamp = playlistItem[3][2];
                                    videoPlaylistIndex = absoluteTimeLookup(absoluteTimestamp, videoPlaylists[sourceIndex]);
                                } else {
                                    sourceIndex = sidx;
                                }
                                break;
                            }
                        }
                        if (hasImages) {
                            let imageIndex = keyLookup(timestamp, imagePlaylist);
                            if (imageIndex !== -1) {
                                videoPlaylistIndex = imageToVideoIndex(imageIndex, videoPlaylists[sourceIndex], imagePlaylist);
                            }
                        }
                    }
                } else {
                    if (sourceIndex === undefined) {
                        sourceIndex = lastSourceIndex;
                    }
                    if (videoPlaylists[sourceIndex]) {
                        if (timestamp < 1000000000) {
                            videoPlaylistIndex = videoTimeLookup(timestamp, videoPlaylists[sourceIndex]);
                        } else {
                            videoPlaylistIndex = absoluteTimeLookup(timestamp, videoPlaylists[sourceIndex]);
                        }
                    } else if (data) {
                        const railInspectionData = _.get(getState(), ["railInspection", "railInspectionImages", "data"], []);
                        if (timestamp < 9999999999) {
                            timestamp *= 1000;
                        }
                        videoPlaylistIndex = binarySearch(timestamp, railInspectionData, (image) => image.timestamp);
                        dispatch(requestPlaylistPosition(true, false, true, sourceIndex, videoPlaylistIndex, timestamp));
                        return;
                    }
                }
            }

            // here we need to check if state.playlist.video has actually any data for sourceIndex
            // if not use the one which has some date in it
            let availableStreamIndexes = [];
            _.map(getState().playlist.data.video, (video, index) => {
                if (video.length) {
                    availableStreamIndexes.push(index);
                }
            });
            if (!availableStreamIndexes.includes(sourceIndex) && availableStreamIndexes.length) {
                sourceIndex = availableStreamIndexes[0];
            }

            if (videoPlaylistIndex !== -1) {
                const playlistIndexTimestamp = videoPlaylists[sourceIndex][videoPlaylistIndex][1];
                let timestampOffset = 0;
                if (timestamp && typeof timestamp !== "string") {
                    if (timestamp < 1000000000) {
                        timestampOffset = timestamp - playlistIndexTimestamp;
                    } else {
                        timestampOffset = (timestamp * 1000 - videoKeyToTimestamp(videoPlaylists[sourceIndex][videoPlaylistIndex][0])) / 1000;
                    }
                } else if (timestamp && typeof timestamp === "string") {
                    timestampOffset = calculateOffset(videoPlaylists[sourceIndex], videoPlaylistIndex, frame);
                }
                console.log("Playlist position:", videoPlaylistIndex, "has ts:", playlistIndexTimestamp);
                console.log("useVideo", useVideo);
                if (useVideo) {
                    console.log("Updating current position", updateCurrent);
                    if (updateCurrent) {
                        console.log("actually Updating current position", updateCurrent);
                        dispatch(currentPlaylistPosition(videoPlaylistIndex, null, timestampOffset));
                    }
                    dispatch(
                        requestPlaylistPosition(
                            true,
                            enhanced,
                            stills,
                            _.isNil(sourceIndex) ? lastSourceIndex : sourceIndex,
                            videoPlaylistIndex,
                            timestampOffset,
                        ),
                    );
                } else {
                    let imageIndex = videoToImageIndex(videoPlaylistIndex, videoPlaylists[sourceIndex], imagePlaylist);
                    console.log("Updating current position", updateCurrent);
                    if (updateCurrent) {
                        console.log("actually Updating current position");
                        dispatch(currentPlaylistPosition(videoPlaylistIndex, null, 0));
                    }
                    dispatch(requestPlaylistPosition(false, enhanced, stills, sourceIndex, imageIndex, 0));
                }
            } else {
                console.log("No timestamp, using playlist position 0");
                dispatch(requestPlaylistPosition(useVideo, enhanced, stills, sourceIndex, 0, 0));
            }
        }

        if (exitFullscreen) {
            dispatch(closeFullscreen());
        }

        if (sessionID === null) {
            dispatch(playlist(null, {}, null, null, []));
            return;
        } else if (
            getState().playlist.cache[sessionID] &&
            (getState().playlist.cache[sessionID].mpdURLs || getState().playlist.cache[sessionID].data) &&
            !sessionPositionFilter
        ) {
            const cache = getState().playlist.cache[sessionID];
            const sessionData = getState().sessions[sessionID];

            if (enhanced === "low_res" && sessionData.first_seen < 1631710005) {
                enhanced = false;
            }

            let timestamp = 0;
            const shortcutPoints = _.get(getState().shortcuts.shortcutSessionPoints, [sessionID], []);
            if (shortcutPoints.length) {
                timestamp =
                    _.get(
                        _.minBy(shortcutPoints, (point) => _.get(point, [0, "timestamp"], 0)),
                        [0, "timestamp"],
                        0,
                    ) / 1000;
                timestamp = absoluteTimeLookup(timestamp, cache.videoPlaylist[0]);
            }

            onGetPlaylist(
                sessionID,
                cache.mpdURLs,
                cache.videoPlaylist,
                cache.imagePlaylist,
                cache.gps_offsets,
                cache.route_locations,
                cache.discontinuity_indexes,
                cache.system_id,
                timestamp,
                cache.data,
            );
        } else {
            let mpdURLs = {};
            let session = getState().sessions[sessionID];
            if (enhanced === "low_res" && session && session.first_seen < 1631710005) {
                enhanced = false;
            }
            if (!session) {
                console.log("No session, getting data");
                if (!requested) {
                    dispatch(getSessionData(sessionID)).then(() => {
                        console.log("Selecting route again");
                        dispatch(routeSelected(sessionID, timestamp, enhanced, stills, sourceIndex, frame, true, false, fromShortcut, sessionPositionFilter));
                    });
                }
                return;
            }

            let sourceIndexOverride = calculateSourceOverride(session.tags, session.stream_info);

            let path = `/${session.device_uuid}/${session.uuid}/`;
            if (session.last_vid_up > 0) {
                mpdURLs["snapshots"] = `https://raw${MEMOIZED_DOMAIN_URL}` + path;

                mpdURLs["raw.0"] = `https://raw${MEMOIZED_DOMAIN_URL}` + path + "index.m3u8?ts=" + session.last_vid_up;
                mpdURLs["enhanced.0"] = `https://raw${MEMOIZED_DOMAIN_URL}` + path + "index.m3u8?ts=" + session.last_vid_up;

                mpdURLs["low_res.0"] = `https://lr${MEMOIZED_DOMAIN_URL}` + path + "index.0.m3u8?ts=" + session.last_vid_up;

                for (let i = 1; i < session.stream_count; i++) {
                    mpdURLs["raw." + i] = `https://raw${MEMOIZED_DOMAIN_URL}` + path + "index." + i + ".m3u8?ts=" + session.last_vid_up;
                    mpdURLs["enhanced." + i] = `https://raw${MEMOIZED_DOMAIN_URL}` + path + "index." + i + ".m3u8?ts=" + session.last_vid_up;
                    mpdURLs["low_res." + i] = `https://lr${MEMOIZED_DOMAIN_URL}` + path + "index." + i + ".m3u8?ts=" + session.last_vid_up;
                }
            }
            if (session.last_img_up > 0) {
                mpdURLs["imageBase"] = `https://images${MEMOIZED_DOMAIN_URL}` + path;
            }

            dispatch(playlist(sessionID, mpdURLs, null, null));

            let postBody = {
                action: "playlist",
                session_id: sessionID,
            };
            if (fromShortcut) {
                postBody["shortcut_id"] = fromShortcut;
            }

            if (positionFilters && positionFilters.elr) {
                const startChain = positionFilters.elr.start_mile * 80 + positionFilters.elr.start_chain;
                const endChain = positionFilters.elr.end_mile * 80 + positionFilters.elr.end_chain;

                postBody["position_filter"] = {
                    elr: positionFilters.elr.route,
                    start_pos: startChain,
                    end_pos: endChain,
                };
            } else if (sessionPositionFilter && sessionPositionFilter.elr) {
                postBody["position_filter"] = sessionPositionFilter;
            }

            let url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    let videoPlaylists = [];
                    if (response.video_playlist) {
                        if (response.video_playlist.length) {
                            videoPlaylists = [response.video_playlist];
                        }
                    } else if (response.video_playlists) {
                        videoPlaylists = response.video_playlists;
                    }

                    // this should only affect the widget (DLI) due to position filters being applied, if session has been trimmed
                    // and position filter outside the trimmed session it will get triggered
                    if (!videoPlaylists.length && !response.data) {
                        notification.error({
                            message: "Error loading video",
                            description: "Problem occurred while loading session data, please try different session",
                        });
                        dispatch(routeSelected(null));
                        return;
                    }

                    videoPlaylists.forEach((playlist) => {
                        playlist.forEach((item) => {
                            item[3] = response.video_coords[item[3]];
                            item[4] = response.video_coords[item[4]];
                            // Default playlist time offset is zero, if the server doesn't adjust for it
                            if (item.length < 7) {
                                item.push(0);
                            }
                        });
                    });

                    let videoTimestamp = 0;

                    if (response.shortcut_points && response.shortcut_points.length && videoPlaylists.length) {
                        let allPoints = [];
                        response.shortcut_points.forEach((pointsPair) => {
                            if (!pointsPair[0]) {
                                let startVideoKey = videoPlaylists[0][0][0];
                                let timestamp = videoKeyToTimestamp(startVideoKey);
                                pointsPair[0] = {
                                    timestamp: timestamp,
                                    type: "start",
                                };
                            }
                            if (!pointsPair[1]) {
                                let endVideoKey = videoPlaylists[0][videoPlaylists[0].length - 1][0];
                                let timestamp = videoKeyToTimestamp(endVideoKey);
                                pointsPair[1] = {
                                    timestamp: timestamp,
                                    type: "end",
                                };
                            }

                            let startLocation = getPositionAndOffset(pointsPair[0].timestamp / 1000, videoPlaylists[0], true);
                            if (startLocation && startLocation.startCoordinates) {
                                allPoints.push(startLocation.startCoordinates);
                            }

                            let endLocation = getPositionAndOffset(pointsPair[1].timestamp / 1000 - 1, videoPlaylists[0], true);

                            if (endLocation && endLocation.startCoordinates) {
                                allPoints.push(endLocation.startCoordinates);
                            }
                        });
                        let bounds = {
                            north: _.maxBy(allPoints, (point) => point[1])[1],
                            east: _.maxBy(allPoints, (point) => point[0])[0],
                            south: _.minBy(allPoints, (point) => point[1])[1],
                            west: _.minBy(allPoints, (point) => point[0])[0],
                        };

                        dispatch(goToBounds(bounds));
                        dispatch(receiveShortcutPoints(response.shortcut_points, sessionID));
                        const timestampToSearch =
                            _.get(
                                _.minBy(response.shortcut_points, (point) => _.get(point, [0, "timestamp"], 0)),
                                [0, "timestamp"],
                                0,
                            ) / 1000;
                        const indexFound = absoluteTimeLookup(timestampToSearch, videoPlaylists[0]);
                        videoTimestamp = videoPlaylists[0][indexFound][1];
                    } else {
                        const video = response.video_playlist ? [response.video_playlist] : response.video_playlists;
                        const videoCoords = response.video_coords;

                        if (video.length && videoCoords && videoCoords.length) {
                            let firstTimestamp = null;
                            let lastTimestamp = null;
                            video.forEach((playlist) => {
                                if (playlist.length) {
                                    const firstElem = playlist[0];
                                    const startTimestamp = videoKeyToTimestamp(firstElem[0]);
                                    if (!firstTimestamp || startTimestamp < firstTimestamp) {
                                        firstTimestamp = startTimestamp;
                                    }

                                    const lastElem = playlist[playlist.length - 1];
                                    const endTimestamp = videoKeyToTimestamp(lastElem[0]);
                                    if (!lastTimestamp || endTimestamp > lastTimestamp) {
                                        lastTimestamp = endTimestamp;
                                    }
                                }
                            });

                            const filteredCoords = videoCoords
                                .filter((coord) => {
                                    return coord[2] > firstTimestamp / 1000 && coord[2] < lastTimestamp / 1000;
                                })
                                .map((coordObj) => {
                                    return [coordObj[0], coordObj[1]];
                                });

                            if (filteredCoords.length) {
                                const north = _.maxBy(filteredCoords, (point) => point[1]);
                                const east = _.maxBy(filteredCoords, (point) => point[0]);
                                const south = _.minBy(filteredCoords, (point) => point[1]);
                                const west = _.minBy(filteredCoords, (point) => point[0]);

                                if (north && south && east && west && north[1] && east[0] && south[1] && west[0]) {
                                    let bounds = {
                                        north: north[1],
                                        east: east[0],
                                        south: south[1],
                                        west: west[0],
                                    };
                                    dispatch(goToBounds(bounds));
                                }
                            }
                        }
                    }

                    onGetPlaylist(
                        sessionID,
                        mpdURLs,
                        videoPlaylists,
                        response.image_playlist,
                        response.gps_offsets,
                        response.route_coordinates,
                        response.discontinuity_indexes,
                        response.route_system_id,
                        videoTimestamp,
                        response.data,
                        sourceIndexOverride,
                    );
                })
                .catch((error) => {
                    handleJsonPostError("Session load failed", "Unable to retrieve session playlist", error);
                    dispatch(playlist(null, null, null, null));
                });
        }

        let url = "/route";
        let postBody = {
            action: "get_camera_calibration",
            session_id: sessionID,
        };
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.calibration) {
                    if (response.calibration.railSeparation) {
                        dispatch(
                            routeCalibrationData([
                                {
                                    timestamp: 0,
                                    ...response.calibration,
                                    horizontalFov: 66.5,
                                    aspectRatio: 0.5625,
                                    source: 0,
                                },
                            ]),
                        );
                    } else {
                        response.calibration.forEach((c) => {
                            if (_.isNil(c.timestamp)) {
                                c.timestamp = 0;
                            }
                        });
                        dispatch(routeCalibrationData(response.calibration));
                    }
                } else {
                    dispatch(routeCalibrationData([]));
                }
            })
            .catch(() => {
                dispatch(routeCalibrationData([]));
            });
    };
}

export function getSessionList(callback = null, abortSignal = null) {
    let url = "/sessions";

    return {
        queue: SESSIONS_LIST,
        callback: (next, dispatch, getState) => {
            dispatch({ type: SET_SESSIONS_REFRESHING });

            const sessionListFilters = getState().sessionListFilters;
            const hideLowQualitySessions = getState().sessionListFilters.filter_qa_tags;

            let postBody = {
                action: "get_ids",
            };

            // if sessionListFilters are in its default state, do not pass session_list_filters with a payload
            if (!_.isEqual(SESSION_FILTERS_DEFAULTS, sessionListFilters)) {
                postBody["session_list_filters"] = sessionListFilters;
            }

            if (!hideLowQualitySessions) {
                postBody["session_list_filters"]["qa_tags"] = [];
            } else {
                postBody["session_list_filters"]["qa_tags"] = SESSION_QA_TAGS;
            }

            const positionFilters = getState().loginDataFilters;
            if (positionFilters && positionFilters.elr) {
                const startChain = positionFilters.elr.start_mile * 80 + positionFilters.elr.start_chain;
                const endChain = positionFilters.elr.end_mile * 80 + positionFilters.elr.end_chain;

                postBody["position_filter"] = {
                    elr: positionFilters.elr.route,
                    start_pos: startChain,
                    end_pos: endChain,
                };

                if (positionFilters.timestamp_min) {
                    postBody.session_list_filters.date.from = positionFilters.timestamp_min;
                }
                if (positionFilters.timestamp_max) {
                    postBody.session_list_filters.date.to = positionFilters.timestamp_max;
                }
                if (positionFilters.day_only) {
                    postBody["day_only"] = positionFilters.day_only;
                }
            }
            jsonPostV2(url, getState(), postBody, dispatch, true, abortSignal)
                .then(({ session_ids, map_segment_ids }) => {
                    if (session_ids) {
                        dispatch(sessionIDs(session_ids));
                        if (callback) {
                            callback(session_ids);
                        }
                    } else {
                        dispatch(sessionIDs([]));
                    }
                    if (map_segment_ids) {
                        dispatch(mapSegmentIDs(map_segment_ids));
                    } else {
                        dispatch(mapSegmentIDs([]));
                    }

                    next();
                })
                .catch((error) => {
                    error.then((result) => {
                        if (result !== '"user_abort"') {
                            handleJsonPostError("Unable to retrieve sessions list", "An error occurred while fetching the session list", error);
                        }
                    });
                    dispatch(sessionIDs([]));
                    next();
                });
        },
    };
}

export function getRouteMetadata(sessionID, types, first_timestamp, last_timestamp) {
    return {
        queue: ROUTE_METADATA,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get",
                session_id: sessionID,
                type: types,
                first_timestamp,
                last_timestamp,
            };
            let url = "/routeMetadata";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.length) {
                        response.forEach((metadataItem) => {
                            if (metadataItem.session_id && metadataItem.type && metadataItem.metadata) {
                                let metadata = _.sortBy(metadataItem.metadata, "timestamp");
                                dispatch(routeMetadata(metadataItem.session_id, metadataItem.type, metadata));
                            }
                        });
                    } else if (response.session_id && response.type && response.metadata) {
                        let metadata = _.sortBy(response.metadata, "timestamp");
                        dispatch(routeMetadata(response.session_id, response.type, metadata));
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve route data", "An error occurred while fetching session metadata of types " + types, error);
                    next();
                });
        },
    };
}

export function updateSession(sessionID, name, callback) {
    return {
        queue: DATA_FETCH_QUEUE,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "update",
                id: sessionID,
                name,
            };
            let url = "/sessions";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to update session", "An error occurred while updating the session name", error);
                    next();
                    callback(false);
                });
        },
    };
}

export function archiveSession(sessionID, archive, callback) {
    return {
        queue: DATA_FETCH_QUEUE,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "archive",
                id: sessionID,
                value: archive ? 1 : 0,
            };

            console.log("ARCHIVING SESSION", postBody);

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    if (archive) {
                        let filteredSessions = _.cloneDeep(getState().sessionList).filter((ID) => {
                            return ID !== sessionID;
                        });
                        dispatch(sessionIDs(filteredSessions));
                    }
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError(
                        "Unable to " + (archive ? "" : "un") + "archive session",
                        "An error occurred while " + (archive ? "" : "un") + "archiving the session",
                        error,
                    );

                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}

export const archiveInspectionSession = (session_id, status) => {
    if (status === 1) {
        console.log("Archiving inspection session ", session_id);
    } else {
        console.log("Restoring inspection session ", session_id);
    }

    return {
        queue: ARCHIVE_INSPECTION_SESSION,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "archive_inspection_session",
                session_id: session_id,
                status: status,
            };

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    let sessions = _.clone(getState().sessions);

                    const logStatus = status === 1 ? "archived" : "restored";
                    console.log("Successfully " + logStatus + " session.");

                    sessions[session_id]["inspection_archive_status"] = status;
                    if (status === 0) {
                        sessions[session_id]["inspection"] = true;
                    }
                    dispatch(updateSessions(sessions));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error archiving session: ", error);
                    next();
                });
        },
    };
};

export function archiveSessionStream(sessionID, streamIndex, archive) {
    return {
        queue: DATA_FETCH_QUEUE,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "archive_session_stream",
                session_id: sessionID,
                status: archive,
                stream_index: streamIndex,
            };

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    let sessions = _.clone(getState().sessions);
                    const logStatus = archive ? "archived" : "restored";

                    sessions[sessionID]["stream_info"].forEach((stream, index) => {
                        if (stream.stream_index === streamIndex) {
                            sessions[sessionID]["stream_info"][index] = { ...stream, archived: archive };
                        }
                    });
                    console.log("Successfully " + logStatus + " session stream for session " + sessionID);
                    dispatch(updateSessions(sessions));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to archive session stream: ", error);
                    next();
                });
        },
    };
}

export function tagSessionForDeletion(sessionID) {
    return {
        queue: DATA_FETCH_QUEUE,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "tag_for_deletion",
                session_id: sessionID,
            };

            console.log(postBody);

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    console.log(`Successfully marked session ${sessionID} for deletion.`);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to archive session for deletion stream: ", error);
                    next();
                });
        },
    };
}

export function deleteCameraCalibration(session_id, sourceIndex, timestamp) {
    return (dispatch, getState) => {
        let url = "/route";
        let postBody = {
            action: "delete_camera_calibration",
            session_id,
            sourceIndex,
            timestamp,
        };
        console.log("Deleting calibration...");
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.result) {
                    let calibrationData = _.reject(getState().measurement.calibration, (c) => c.source === sourceIndex && c.timestamp === timestamp);
                    dispatch(routeCalibrationData(calibrationData));
                    notification.success({ message: "Successfully removed calibration" });
                } else {
                    handleJsonPostError("Unable to delete camera calibration", "An error occurred while deleting the camera calibration", response);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to delete camera calibration", "An error occurred while deleting the camera calibration", error);
            });
    };
}

export function clearCameraCalibrations(session_id, sourceIndex) {
    return (dispatch, getState) => {
        let url = "/route";
        let postBody = {
            action: "clear_camera_calibrations",
            session_id,
            sourceIndex,
        };
        console.log("Clearing all calibrations...");
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.result) {
                    let calibrationData = _.reject(getState().measurement.calibration, (c) => c.source === sourceIndex);
                    dispatch(routeCalibrationData(calibrationData));
                    notification.success({ message: "Successfully cleared calibrations" });
                } else {
                    handleJsonPostError("Unable to clear camera calibrations", "An error occurred while clearing the camera calibrations", response);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to clear camera calibration", "An error occurred while clearing the camera calibrations", error);
            });
    };
}

export function persistCameraCalibration(sessionID, calibration) {
    return (dispatch, getState) => {
        let url = "/route";
        let postBody = {
            action: "set_camera_calibration",
            session_id: sessionID,
            sourceIndex: calibration.source,
            rotationMatrix: calibration.rotationMatrix,
            translationVector: calibration.translationVector,
            railSeparation: calibration.railSeparation,
            horizontalFov: calibration.horizontalFov,
            aspectRatio: calibration.aspectRatio,
            timestamp: calibration.timestamp,
        };
        console.log("Setting new calibration: ", calibration);
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.result) {
                    let calibrationData = _.filter(
                        getState().measurement.calibration,
                        (c) => c.source !== calibration.source || (c.timestamp !== 0 && c.timestamp !== calibration.timestamp),
                    );
                    calibrationData.push(calibration);
                    dispatch(routeCalibrationData(calibrationData));
                    notification.success({ message: "Successfully set calibration" });
                } else {
                    handleJsonPostError("Unable to record camera calibration", "An error occurred while recording the camera calibration", response);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to record camera calibration", "An error occurred while recording the camera calibration", error);
            });
    };
}

export const resetStillImageAdjustments = () => {
    return {
        type: RESET_STILL_IMAGE_ADJUSTMENTS,
    };
};

export function exportSegment(
    startTimestamp,
    endTimestamp,
    deviceKey,
    sessionKey,
    exportName,
    emailTo,
    exportIndex,
    isEnhanced,
    annotations,
    sketches,
    measurements,
    markers,
    raw,
    route_info,
) {
    return {
        queue: EXPORT_SEGMENT,
        callback: (next, dispatch, getState) => {
            console.log("Exporting segment");

            let postBody = {
                session_key: sessionKey,
                device_key: deviceKey,
                action: "create_segment",
                start_ts: startTimestamp,
                end_ts: endTimestamp,
                export_name: exportName,
                export_index: exportIndex,
                is_enhanced: isEnhanced,
                annotations,
                sketches,
                measurements,
                markers,
                raw,
                route_info,
            };

            if (emailTo) {
                postBody.email_to = emailTo;
            }

            const url = "/route";
            dispatch(updateLastActive());

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Send export segment request");
                    dispatch(fetchUserExports());
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to create export", "An error occurred while creating this export", error);
                    next();
                });
        },
    };
}

export function fetchExport(export_uuid, type = null) {
    return (dispatch, getState) => {
        const url = "/route";
        let postBody = {
            action: "get_export",
            export_id: export_uuid,
            type,
        };

        dispatch(startFetchExport());

        jsonPostV2(url, getState(), postBody, dispatch, false)
            .then((export_data) => {
                dispatch(receiveExport(export_data));
            })
            .catch((error) => {
                if (error.status === 403) {
                    error.json().then((error_json) => {
                        dispatch(exportUnauthorised(error_json.required_access_id));
                    });
                } else {
                    dispatch(receiveExport(null));
                }
            });
    };
}

export function fetchUserExports() {
    return {
        queue: USER_EXPORTS,
        callback: (next, dispatch, getState) => {
            console.log("Fetching user exports");

            let postBody = {
                action: "get_exports",
            };

            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.exports) {
                        console.log("Succesfully fetched exports", response);
                        dispatch(receiveExports(response.exports));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch exports", "An error occurred while fetching the list of exports", error);
                    next();
                });
        },
    };
}

export function reprocessExport(exportID, email) {
    return {
        queue: USER_EXPORTS,
        callback: (next, dispatch, getState) => {
            console.log("Sending re process export request");

            let postBody = {
                action: "reset_export",
                export_id: exportID,
                email,
            };
            dispatch(updateLastActive());

            const url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        console.log("Succesfully reprocessed export", response);
                        dispatch(fetchUserExports(response.exports));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to reprocess export", "An error occurred while submitting this export for reprocessing", error);
                    next();
                });
        },
    };
}

export const trimVideo = (sessionID, startTS, endTS, callback) => {
    return {
        queue: PLAYLIST,
        callback: (next, dispatch, getState) => {
            console.log("trim video request");

            let postBody = {
                action: "trim_session",
                session_id: sessionID,
                start_ts: startTS,
                end_ts: endTS,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("trim video", response);
                    callback(response.success);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to trim video", "An error occurred while trimming video", error);

                    next();
                });
        },
    };
};

export const getExportSize = (session_key, device_key, start_ts, end_ts, callback) => {
    return {
        queue: PLAYLIST,
        callback: (next, dispatch, getState) => {
            console.log("Export size request");

            let postBody = {
                action: "get_export_size",
                session_key,
                device_key,
                start_ts,
                end_ts,
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("export size", response);
                    callback(response.raw_size);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get export size", "An error occurred while getting export size", error);
                    next();
                });
        },
    };
};

export const submitCuration = (session_id, curation_data, removeFlag, callback) => {
    return {
        queue: SESSIONS_LIST,
        callback: (next, dispatch, getState) => {
            console.log("Submit curation request");

            let postBody = {
                action: "rate_session",
                session_id,
                ratings: curation_data,
                remove_flag: removeFlag,
            };

            const url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("rate response", response);
                    dispatch(getSessionData(session_id, true));
                    callback(true);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to submit curation report", "An error occurred while submitting curation report", error);
                    callback(false);
                    next();
                });
        },
    };
};

export const getAllSessionTags = () => {
    return {
        queue: RECEIVE_SESSION_TAGS,
        callback: (next, dispatch, getState) => {
            console.log("Get session tags");

            let postBody = {
                action: "get_all_tags",
            };

            const url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    dispatch(receiveSessionTags(response.tags));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get session tags", "An error occurred while fetching session tags", error);
                    next();
                });
        },
    };
};

export function getSessionLogs(sessionID, callback) {
    return {
        queue: SESSION_LOGS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_session_logs",
                session_id: sessionID,
            };
            let url = "/sessions";
            console.log("getting session logs");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response.logs) {
                        dispatch({
                            type: SESSION_LOGS,
                            data: response.logs,
                        });
                    }
                    callback();
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting session logs", "An error occurred while fetching session logs", error);
                    dispatch({
                        type: SESSION_LOGS,
                        data: [],
                    });
                    next();
                });
        },
    };
}

export function flagSession(session_id, flagged, comment, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "toggle_session_flag",
            session_id,
            flagged,
            comment,
        };

        const url = "/sessions";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    flagged
                        ? notification.success({ message: "Successfully flagged session" })
                        : notification.success({ message: "Successfully unflagged session" });
                    callback();
                } else {
                    notification.error("Error flagging session");
                }
            })
            .catch((e) => {
                console.log("Error flagging session here", e);
                notification.error({ message: "Error flagging session" });
            });
    };
}

export function persistGPSOffsets(sessionID, offsets) {
    return {
        queue: GPS_TIME_OFFSETS,
        callback: (next, dispatch, getState) => {
            console.log("Persisting GPS offsets");

            if (offsets === null) {
                offsets = getState().gpsTimeOffsets.offsets;
                let original_offsets = getState().gpsTimeOffsets.originalOffsets;
                if (offsets === original_offsets) {
                    next();
                    return;
                }
            }

            let postBody = {
                action: "update_gps_offsets",
                session_id: sessionID,
                offsets: _.flatMap(offsets, (sourceOffsets, sourceIndex) => _.map(sourceOffsets, (offset) => [sourceIndex, ...offset])),
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Persisted GPS offsets");
                    dispatch(gpsTimeOffsets(sessionID, offsets, true));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to save GPS offsets", "An error occurred while updating the stored GPS offsets", error);
                    next();
                });
        },
    };
}

export function exportSightingReport(sightingJson, deviceKey, sessionKey, startTS, endTS, email, name, source) {
    return {
        queue: EXPORT_SEGMENT,
        callback: (next, dispatch, getState) => {
            console.log("Exporting sighting");

            let postBody = {
                action: "create_sighting_export",
                sightingJson,
                device_key: deviceKey,
                session_key: sessionKey,
                startTS,
                endTS,
                email,
                name,
                source,
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Send export sighting report request");
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to create sighting report", "An error occurred while creating this sighting report", error);
                    next();
                });
        },
    };
}

export function createMarkerExport(session_id, export_name, export_email, filters, type = "marker") {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "create_markup_export",
                session_id,
                export_name,
                export_email,
                type: type,
                filters,
            };

            let url = "/route";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response.success);
                })
                .catch((error) => {
                    console.log("get track geometry data error: ", error);
                    reject();
                });
        });
    };
}

export function requestContinuousObservationsReport(form, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_continuous_observation_report",
            form,
        };

        const url = "/routeMetadata";

        return jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log("Error requesting continuous observation report ", error);
            });
    };
}

export const getTrimSuggestions = (session_id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_trim_suggestion",
                session_id,
            };

            console.log("FETCHING trim suggestion", postBody);

            let url = "/sessions";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Trim suggestion response", response);
                    resolve(response);
                })
                .catch(() => {
                    reject();
                });
        });
    };
};

export function receiveTrackGeomHeaders(headers, header_ids, data_files, description) {
    return {
        type: RECEIVE_TRACK_GEOM_HEADERS,
        headers,
        header_ids,
        data_files,
        description,
    };
}

export function getTrackGeomHeaders(session_id) {
    console.log("get track geom headers called");
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_track_geometry_headers",
                session_id,
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("geometry response", response);
                    const data_files = _.sortedUniqBy(response.data_files || [], (f) => f.start_timestamp);
                    dispatch(receiveTrackGeomHeaders(response.geometry_headers || [], response.header_ids, data_files, response.description || "Unknown"));
                    resolve([response.geometry_headers, data_files, response.presigned_src_file_links]);
                })
                .catch((error) => {
                    console.log("get track geometry headers error: ", error);
                    reject();
                });
        });
    };
}

export function getTrackGeomHistory(session_id, elr, track, miles, yards) {
    console.log("get track geom history called");
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const mile = miles + Math.floor(yards / 220) / 10;

            let postBody = {
                action: "get_track_geometry_history",
                session_id,
                elr,
                track,
                mile,
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("geometry history response", response);
                    resolve(response.history);
                })
                .catch((error) => {
                    console.log("get track geometry history error: ", error);
                    reject();
                });
        });
    };
}

export function getTrackGeomData(session_id, min_timestamp, max_timestamp, combine = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_track_geometry_data",
                session_id,
                min_timestamp,
                max_timestamp,
            };

            const headerIDs = getState().trackGeometry.headerIDs;
            postBody["header_ids"] = headerIDs;

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.geometry_data) {
                        let inflated = inflateSync(new Buffer(response.geometry_data, "base64")).toString();
                        response.geometry_data = JSON.parse(inflated);

                        let geomData = _.orderBy(response.geometry_data, "timestamp");
                        if (combine) {
                            geomData = getState().trackGeometry.data.concat(geomData);
                        }

                        dispatch(receiveTrackGeomData(geomData));
                        resolve(geomData.length);
                    } else {
                        resolve(0);
                    }
                })
                .catch((error) => {
                    console.log("get track geometry data error: ", error);
                    reject();
                });
        });
    };
}

export function addTrackGeomExport(session_id, email) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let url = "/sessions";
            let postBody = {
                action: "add_track_geometry_exports",
                session_id,
                email,
            };

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response.success);
                })
                .catch((error) => {
                    console.log("add track geometry exports error: ", error);
                    reject();
                });
        });
    };
}

export function getEncodedSnapshot(url, body) {
    return (dispatch, getState) => {
        return fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        })
            .then((response) => {
                if (!response.ok) {
                    // we throw an error, but return the response object to the calling
                    // code in case there is useful data in the body, making no assumptions
                    // whether the body will be JSON
                    return Promise.reject(response);
                } else {
                    return response.json();
                }
            })
            .then((json) => {
                return json.base64Jpeg;
            })
            .catch((err) => {
                console.log("Error getting snapshot", err);
            });
    };
}

export function updateDateRange(to, from) {
    return (dispatch, getState) => {
        let dateRange = {
            to: null,
            from: null,
        };
        if (to && from) {
            dateRange = {
                to: to,
                from: from,
            };
        }
        dispatch(updateRange(dateRange));
    };
}

export function setRequestedContent(contentType, contentData) {
    return {
        type: SET_REQUESTED_CONTENT,
        contentType,
        contentData,
    };
}

export function clearRequestedContent() {
    return {
        type: CLEAR_REQUESTED_CONTENT,
    };
}

export function shareLinkDetails({ session_id, timestamp, enhanced, inspection = false }) {
    return (dispatch, getState) => {
        dispatch({
            type: SHARE_LINK_DETAILS,
            sessionID: session_id,
            timestamp,
            enhanced: !!enhanced,
            inspection,
        });
        if (session_id && !inspection) {
            dispatch(routeSelected(session_id, timestamp, enhanced));
            if (getState().mapGeometry) {
                dispatch(goToBounds(session_id));
            }
        }
    };
}

export function shareLink(link_token) {
    return (dispatch, getState) => {
        console.log("Parsing link:", link_token);
        if (link_token.startsWith("{")) {
            let details = JSON.parse(link_token);
            if (details.session_id) {
                dispatch(shareLinkDetails(details));
            }
            return;
        }

        dispatch({
            type: SHARE_LINK,
            link_token,
        });
        let postBody = {
            action: "share_link_details",
            share_link: link_token,
        };
        let url = "/route";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                let detailsArray = [];
                if (response.length) {
                    detailsArray = response.filter((details) => details.token === link_token);
                }

                if (detailsArray.length) {
                    dispatch(shareLinkDetails(detailsArray[0]));
                } else {
                    dispatch(
                        shareLinkDetails({
                            session_id: null,
                            timestamp: 0,
                            enhanced: false,
                        }),
                    );
                }
            })
            .catch((error) => {
                handleJsonPostError("Error reading share link", "An error occurred while finding details of the provided share link", error);
                dispatch(
                    shareLinkDetails({
                        session_id: null,
                        timestamp: 0,
                        enhanced: false,
                    }),
                );
            });
    };
}

export function createShareLink(sessionID, timestamp, enhanced, callback) {
    return (dispatch, getState) => {
        dispatch(logEvent("Social", "Create Share Link", `Session: ${sessionID}, TS: ${timestamp}, Enhanced: ${enhanced}`));

        let postBody = {
            action: "share",
            session_id: sessionID,
            timestamp,
            enhanced,
        };
        let url = "/route";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.result) {
                    callback(response.link_token);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to create share link", "An error occurred while creating the requested share link", error);
            });
    };
}

export function getElrStartEnd(elr) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_elr_start_end",
                elr,
            };

            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log("Error getting elr start and end: ", error);
                    reject();
                });
        });
    };
}

const addFavouriteSession = (sessionID, categoryID = 0) => {
    return {
        type: ADD_FAVOURITE_SESSION,
        sessionID,
        categoryID,
    };
};

const removeFavouriteSession = (sessionID) => {
    return {
        type: REMOVE_FAVOURITE_SESSION,
        sessionID,
    };
};

export function favouriteSession(sessionID, value) {
    return {
        queue: SESSIONS_LIST,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "favourite_session",
                session_id: sessionID,
                favourite: value,
            };

            const url = "/sessions";

            if (value) {
                dispatch(addFavouriteSession(sessionID));
                notification.success({ message: `Session added to favourites`, duration: 1.5 });
            } else {
                dispatch(removeFavouriteSession(sessionID));
            }

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("favourite session response", response);
                    if (!response.success) {
                        if (!value) {
                            dispatch(addFavouriteSession(sessionID));
                        } else {
                            dispatch(removeFavouriteSession(sessionID));
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get favourite sessions", "An error occurred while fetching favourite sessions", error);
                    if (!value) {
                        dispatch(addFavouriteSession(sessionID));
                    } else {
                        dispatch(removeFavouriteSession(sessionID));
                    }
                    next();
                });
        },
    };
}

export function addSessionToFavouriteCategory(sessionID, categoryID, categoryName, callback) {
    return (dispatch, getState) => {
        let postBody = {};
        if (categoryID === 0) {
            postBody = {
                action: "add_session_to_favourite_category",
                category_name: categoryName,
                session_id: sessionID,
            };
        } else {
            postBody = {
                action: "add_session_to_favourite_category",
                category_id: categoryID,
                session_id: sessionID,
            };
        }

        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    // if response from the api is number it means that new category has been created and it ID is returned instead of true/false
                    let _categoryID = _.isNumber(response.success) ? response.success : categoryID;
                    dispatch(
                        getFavouriteCategories(true, () => {
                            dispatch(addFavouriteSession(sessionID, _categoryID));
                        }),
                    );
                    callback(true);
                } else {
                    notification.error({ message: "Error adding session to favourites.", duration: 2.5 });
                    callback(false);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to add session to favourite category", error);
                callback(false);
            });
    };
}

export function setFavouriteCategoryPrivacy(categoryID, categoryPrivacy, callback) {
    return (dispatch, getState) => {
        let postBody = {};
        postBody = {
            action: "set_favourite_category_privacy",
            category_id: categoryID,
            category_privacy: categoryPrivacy,
        };

        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                callback(response);
            })
            .catch(() => {
                callback({ success: false });
            });
    };
}

export function setFavouriteSessionDescription(categoryID, sessionID, description, callback) {
    return (dispatch, getState) => {
        let postBody = {};
        postBody = {
            action: "set_favourite_session_description",
            category_id: categoryID,
            session_id: sessionID,
            description: description,
        };

        const currentDataState = _.cloneDeep(getState().userDetails.favouriteCategories);
        const oldDataState = _.cloneDeep(currentDataState);
        const index = _.findIndex(currentDataState, (category) => category.id === categoryID);

        currentDataState[index].session_data[sessionID].description = description;

        dispatch(userFavouriteCategories(currentDataState));

        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                callback(response);
                if (!response.success) {
                    dispatch(userFavouriteCategories(oldDataState));
                } else {
                    notification.success({ message: "Successfully changed description" });
                }
            })
            .catch(() => {
                dispatch(userFavouriteCategories(oldDataState));
                notification.error({ message: "Error while changing session description" });
                callback({ success: false });
            });
    };
}

export function getQaSessions() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_qa_sessions",
            };
            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    console.log("Error getting QA Sessions");
                    reject(error);
                });
        });
    };
}

export function getFlaggedSessionsByUser(byUser) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_flagged_sessions_by_user",
                by_user: byUser === undefined ? true : byUser,
            };
            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    console.log("Error getting users flagged sessions");
                    reject(error);
                });
        });
    };
}

export function getSessionContinuousObservations(sessionId) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_session_continuous_observations",
            session_id: sessionId,
        };
        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("SESSION CONTINUOUS DATA:", response);
                dispatch(sessionContinuousObservations(response));
            })
            .catch((error) => {
                console.log("Error fetching continuous observations", error);
                dispatch(sessionContinuousObservations([]));
            });
    };
}

export function getSessionObservations(session_id, page = 0, observations = []) {
    return {
        queue: SESSION_OBSERVATIONS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_session_observations",
                session_id,
                page,
            };
            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.observations) {
                        observations = _.concat(observations, response.observations);
                    }

                    if (response.is_more) {
                        dispatch(getSessionObservations(session_id, page + 1, observations));
                    } else {
                        dispatch(sessionObservations(observations));
                    }
                    next();
                })
                .catch((error) => {
                    console.log("Error fetching object observations", error);
                    dispatch(sessionObservations([]));
                    next();
                });
        },
    };
}

export function getObservationDetections(sessionId, observation_type, isContinuous) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_observation_detections",
            session_id: sessionId,
            type: observation_type,
            isContinuous,
        };
        let url = "/sessions";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(observationDetections(response));
            })
            .catch((error) => {
                console.log("Error fetching observation detections: ", error);
                dispatch(observationDetections([]));
            });
    };
}

export function observationDetections(detections) {
    return {
        type: OBSERVATION_DETECTIONS,
        detections,
    };
}

export function sessionObservations(observations) {
    return {
        type: SESSION_OBSERVATIONS,
        observations,
    };
}

export function sessionContinuousObservations(observations) {
    return {
        type: SESSION_CONTINUOUS_OBSERVATIONS,
        observations,
    };
}

export function processSessionObservations(observations) {
    return {
        type: PROCESS_OBSERVATIONS,
        observations,
    };
}

export function getSessionTrims(sessionId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_session_trims",
                session_id: sessionId,
            };
            let url = "/sessions";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    console.log("Error getting session trims");
                    reject(error);
                });
        });
    };
}

export function setVideoSpeed(videoSpeed) {
    const speed = [0.15, 0.25, 0.5, 1, 2].includes(videoSpeed) ? videoSpeed : 1;
    return {
        type: SET_VIDEO_SPEED,
        videoSpeed: speed,
    };
}

export function getSessionWeatherData(ts, lat, lon, callback) {
    return {
        queue: SESSIONS_WEATHER_DATA,
        callback: (next, dispatch, getState) => {
            const url = "/sessions";
            const postBody = {
                action: "get_session_weather_data",
                ts: ts,
                latitude: lat,
                longitude: lon,
            };

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (callback) {
                        callback(response.weather_data);
                    }
                    next();
                })
                .catch(() => {
                    callback(null);
                    next();
                });
        },
    };
}

export function setVideoOverlay(overlayType, overlaySettings) {
    return {
        type: SET_OVERLAY,
        overlayType,
        overlaySettings,
    };
}

export function setDefaultVideoOverlaysSettings(overlaysSettings) {
    return {
        type: SET_OVERLAY_DEFAULTS,
        overlaysSettings,
    };
}

export function getAbsoluteThermalImage(deviceKey, sessionKey, videoKey, frame) {
    console.log("get absolute thermal image called");
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_absolute_thermal_image",
                session_key: sessionKey,
                video_key: videoKey,
                device_key: deviceKey,
                frame,
            };

            let url = "/routeMetadata";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log("Get absolute thermal image error: ", error);
                    reject();
                });
        });
    };
}

const receiveEnvironmentData = (data) => {
    return {
        type: SESSIONS_ENVIRONMENT_DATA,
        data,
    };
};

export function getSessionEnvironmentData(sessionID) {
    return {
        queue: SESSIONS_ENVIRONMENT_DATA,
        callback: (next, dispatch, getState) => {
            const url = "/routeMetadata";
            const postBody = {
                action: "get_environment_analysis",
                session_id: sessionID,
            };

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.environment_data) {
                        dispatch(receiveEnvironmentData(response.environment_data));
                    }
                    next();
                })
                .catch(() => {
                    next();
                });
        },
    };
}
