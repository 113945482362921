import React from "react";
import { connect } from "react-redux";
import { changeUserPassword } from "../redux/actions/index";
import { Input, Modal } from "antd";

class ChangePasswordDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPassword2: "",
            newPasswordTouched: false,
            newPassword2Touched: false,
            passwordChangeValid: false,
            passwordChangeError: null,
            passwordChangeInProgress: false,
        };
    }

    confirmChangePassword = () => {
        this.setState({
            passwordChangeInProgress: true,
        });
        this.props.dispatch(
            changeUserPassword(this.state.oldPassword, this.state.newPassword, (error) => {
                if (!error) {
                    this.props.onClose();
                    Modal.success({
                        title: "Password changed",
                        content: "Your password has been changed.",
                    });
                } else {
                    this.setState({
                        passwordChangeInProgress: false,
                        passwordChangeValid: false,
                        passwordChangeError: error,
                    });
                }
            }),
        );
    };

    cancelChangePassword = () => {
        this.props.onClose();
    };

    onOldPasswordChange = (evt) => {
        this.setState({
            oldPassword: evt.target.value,
        });
        this.validatePassword(evt.target.value, this.state.newPassword, this.state.newPassword2);
    };

    onNewPasswordChange = (evt) => {
        this.setState({
            newPassword: evt.target.value,
        });
        if (this.state.newPasswordTouched) {
            this.validatePassword(this.state.oldPassword, evt.target.value, this.state.newPassword2);
        }
    };

    validatePassword(oldPassword, newPassword, newPassword2) {
        const oldMatchesNew = oldPassword === newPassword;
        const newDontMatch = newPassword !== newPassword2;
        const newTooShort = String(newPassword).length < 12;

        this.setState({
            passwordChangeValid: newPassword !== "" && oldPassword !== "" && !oldMatchesNew && !newDontMatch && !newTooShort,
        });

        if (newPassword !== "" && oldPassword !== "") {
            if (oldMatchesNew) {
                this.setState({
                    passwordChangeError: "Old and new passwords must not match.",
                });
            } else if (newDontMatch) {
                this.setState({
                    passwordChangeError: "New passwords do not match.",
                });
            } else if (newTooShort) {
                this.setState({
                    passwordChangeError: "New passwords need to be at least 12 characters.",
                });
            } else {
                this.setState({
                    passwordChangeError: null,
                });
            }
        } else {
            this.setState({
                passwordChangeError: null,
            });
        }
    }

    onNewPassword2Change = (evt) => {
        this.setState({
            newPassword2: evt.target.value,
        });
        if (this.state.newPassword2Touched) {
            this.validatePassword(this.state.oldPassword, this.state.newPassword, evt.target.value);
        }
    };

    render() {
        return (
            <Modal
                title="Change Password"
                centered
                zIndex={2000}
                getContainer={false}
                okButtonProps={{ disabled: !this.state.passwordChangeValid }}
                confirmLoading={this.state.passwordChangeInProgress}
                visible={true}
                okText={"Change Password"}
                cancelText={"Cancel"}
                maskClosable={false}
                onOk={this.confirmChangePassword}
                onCancel={this.cancelChangePassword}>
                <div className={"PasswordInputLine"}>
                    Enter old password:
                    <Input.Password
                        className={"ChangePasswordInput"}
                        placeholder="Old Password"
                        disabled={this.state.passwordChangeInProgress}
                        onChange={this.onOldPasswordChange}
                        value={this.state.oldPassword}
                    />
                </div>

                <div className={"PasswordInputLine"}>
                    Enter new password:
                    <Input.Password
                        className={"ChangePasswordInput"}
                        placeholder="New Password"
                        disabled={!this.state.oldPassword || this.state.passwordChangeInProgress}
                        onChange={this.onNewPasswordChange}
                        onBlur={() => this.setState({ newPasswordTouched: true })}
                        value={this.state.newPassword}
                    />
                </div>
                <div className={"PasswordInputLine"}>
                    Re-enter new password:
                    <Input.Password
                        className={"ChangePasswordInput"}
                        placeholder="Repeat New Password"
                        disabled={!this.state.oldPassword || this.state.passwordChangeInProgress}
                        onChange={this.onNewPassword2Change}
                        onBlur={() =>
                            this.setState({ newPassword2Touched: true }, () => this.onNewPassword2Change({ target: { value: this.state.newPassword2 } }))
                        }
                        value={this.state.newPassword2}
                    />
                </div>
                {this.state.passwordChangeError && <div className={"ChangePasswordError"}>{this.state.passwordChangeError}</div>}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userDetails,
});

export default connect(mapStateToProps)(ChangePasswordDialog);
