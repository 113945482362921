import { Cone } from "@react-three/drei";
import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { CompassRose } from "./CompassRose";
import ThreeDFeature from "./3dFeature";

const ThreeDCursor = ({ type, heading, location, routePosition, showOrientation, showLabel, options, sunBrightness }) => {
    const outerRef = useRef();

    const _orientation = (Math.PI * (heading || 0)) / 180;

    useFrame(() => {
        if (outerRef.current) {
            outerRef.current.rotation.set(0, -_orientation, 0);
        }
    });

    return (
        <group>
            <ThreeDFeature
                feature={{ type, heading, location, routePosition, options }}
                showLabel={showLabel}
                sunBrightness={sunBrightness}>
                {showOrientation && (
                    <group>
                        <Cone
                            args={[1, 1, 8, 1, false, 0, Math.PI]}
                            position={[0, 0, 1.1]}
                            scale={[0.01, 2.2, 0.1]}
                            rotation={[0, Math.PI / 2, Math.PI / 2]}>
                            <meshStandardMaterial color="#ff8080" />
                        </Cone>
                        <group ref={outerRef}>
                            <CompassRose complexity={5} />
                        </group>
                    </group>
                )}
            </ThreeDFeature>
        </group>
    );
};

export default ThreeDCursor;
