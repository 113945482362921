import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import OBCButton from "components/OBC/OBCButton";
import { Tag, Select, Input, Button, Tooltip, Popover, Modal, Slider, notification } from "antd";
import {
    getObservation,
    getObservationData,
    getSessionIdByObservationId,
    getThermalClassifications,
    goToObservation,
    reviewObservationAdmin,
    setObservationClassificationAdmin,
} from "redux/actions";
import { MEMOIZED_DOMAIN_URL } from "../components/util/HostUtils";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import OBCSpinner from "components/util/OBC";
import OBCAutoIndicator from "components/OBC/OBCAutoIndicator";
import { faAngleLeft, faAngleRight, faTemperatureHigh, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Measure from "react-measure";
import { emulateTab } from "emulate-tab";
import ZoomableImage from "components/ZoomableImage";
import AssetIDHelper from "components/util/AssetIDHelper";
import { validateGroundTruth } from "components/Issues/issueUtils";
import { InfoCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const sessionListSelector = (state) => state.admin.observationReview.sessionList;
const selectedSessionSelector = (state) => state.admin.observationReview.selectedSession;
const thermalClassificationsSelector = (state) => state.admin.observationReview.thermalClassifications;

const DetectionImage = ({ ...props }) => {
    const [loaded, setLoaded] = useState(false);
    const [orgDimensions, setOrgDimensions] = useState({ height: 0, width: 0 });
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    useEffect(() => {
        setLoaded(false);
    }, [props.src]);

    const renderBboxDiv = useMemo(() => {
        if (props.bbox) {
            let factor = dimensions.width / orgDimensions.width;
            const height = props.bbox[3] * factor;
            const width = props.bbox[2] * factor;
            const top = props.bbox[1] * factor;
            const left = props.bbox[0] * factor;
            return (
                <div
                    className={`Bbox ${props.bboxColor}`}
                    style={{ outlineWidth: height > 0 ? "3px" : "2px", height, width, left, top }}
                />
            );
        } else {
            return null;
        }
    }, [dimensions.width, orgDimensions.width, props.bbox, props.bboxColor]);

    return (
        <>
            {loaded ? null : (
                <div className="ImageLoadingSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            )}

            {renderBboxDiv}
            <Measure
                bounds
                onResize={(contentRect) => {
                    setDimensions(contentRect.bounds);
                }}>
                {({ measureRef }) => {
                    return (
                        <img
                            style={loaded ? {} : { display: "none" }}
                            {...props}
                            ref={measureRef}
                            src={props.src}
                            crossOrigin={"anonymous"}
                            onLoad={(img) => {
                                setLoaded(true);
                                setOrgDimensions({ height: img.target.height, width: img.target.width });
                            }}
                        />
                    );
                }}
            </Measure>
        </>
    );
};

const csrfTokenSelector = (state) => state.csrfToken;

const ObservationReview = () => {
    const dispatch = useDispatch();

    const sessionList = useSelector(sessionListSelector);
    const selectedSession = useSelector(selectedSessionSelector);
    const thermalClassifications = useSelector(thermalClassificationsSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [searchQuery, setSearchQuery] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [listCount, setListCount] = useState(0);
    const [listIsLoading, setListIsLoading] = useState(true);
    const [ocrInputActive, setOcrInputActive] = useState(false);
    const [observationSearchInputActive, setObservationSearchInputActive] = useState(false);
    const [ocrCustomResultInput, setOcrCustomResultInput] = useState("");
    const [ocrCustomResultInputUpdated, setOcrCustomResultInputUpdated] = useState("");
    const [ocrCustomResultInputUpdatedError, setOcrCustomResultInputUpdatedError] = useState(false);
    const [observationHasDetectionNumber, setObservationHasDetectionNumber] = useState(true);
    const [copyToClipboardText, setCopyToClipboardText] = useState("Copy to clipboard");
    const [jumpToDetectionOpen, setJumpToDetectionOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [previewImageBbox, setPreviewImageBbox] = useState([]);
    const [updatingObservation, setUpdatingObservcation] = useState(false);
    const [obsIdFromSearch, setObsIdFromSearch] = useState(null);
    const [searchingForObseravtion, setSearchingForObseravtion] = useState(false);

    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [zoom, setZoom] = useState(5);

    const [filters, setFilters] = useState({
        status: "all",
        type: "all",
        hasClassification: "all",
        hasOcrResult: "all",
        state: "all",
    });

    const ocrInput = useRef();
    const observationSearchInput = useRef();
    const targetedSessionListItemRef = useRef(null);

    useEffect(() => {
        dispatch(getObservation(null, 0, () => setListIsLoading(false)));
        dispatch(getThermalClassifications());
    }, []);

    const filtersActive = useMemo(() => {
        let active = false;

        if (_.get(filters, "status") !== "all") {
            active = true;
        }
        if (_.get(filters, "hasClassification") !== "all") {
            active = true;
        }
        if (_.get(filters, "hasOcrResult") !== "all") {
            active = true;
        }
        if (_.get(filters, "state") !== "all") {
            active = true;
        }

        return active;
    }, [filters]);

    const loadMoreObservations = () => {
        setLoadingMore(true);
        dispatch(getObservation(parseInt(searchQuery), searchQuery.length ? 0 : sessionList.page_index + 1, () => setLoadingMore(false)));
    };

    const observations = useMemo(() => {
        let observationsList = [];
        if (!_.isEmpty(selectedSession.observations)) {
            observationsList = _.filter(selectedSession.observations, (obs) => {
                if (filters.status !== "all") {
                    if (obs.review_status !== filters.status) {
                        return false;
                    }
                }
                if (filters.type !== "all") {
                    if (String(obs.class).toLocaleLowerCase().replaceAll(" ", "_") !== filters.type) {
                        return false;
                    }
                }
                if (filters.hasClassification !== "all") {
                    if (filters.hasClassification === "set" && !obs.classification) {
                        return false;
                    }
                    if (filters.hasClassification === "unset" && obs.classification) {
                        return false;
                    }
                }
                if (filters.hasOcrResult !== "all") {
                    let ocrResult = JSON.parse(obs.classification);

                    if (filters.hasOcrResult === "set" && (!ocrResult || (ocrResult && !ocrResult.ocr_result))) {
                        return false;
                    }
                    if (filters.hasOcrResult === "custom_set" && (!ocrResult || (ocrResult && !ocrResult.ocr_result_custom))) {
                        return false;
                    }
                    if (
                        filters.hasOcrResult === "unset" &&
                        (!["Signal", "Speedboard", "Structure ID Plate"].includes(obs.class) ||
                            (ocrResult && (ocrResult.ocr_result || ocrResult.ocr_result_custom)))
                    ) {
                        return false;
                    }
                }
                if (filters.state !== "all") {
                    if (obs.state !== filters.state) {
                        return false;
                    }
                }
                return true;
            });
        }
        return observationsList;
    }, [filters, selectedSession.observations]);

    useEffect(() => {
        const _filters = _.cloneDeep(filters);
        setFilters({ ..._filters, state: "all" });
    }, [selectedSession.sessionID]);

    useEffect(() => {
        if (obsIdFromSearch && !selectedSession.loading && observations.length) {
            const index = _.findIndex(observations, (obs) => obs.id === parseInt(obsIdFromSearch));
            if (index) {
                dispatch(goToObservation(index));
                setObsIdFromSearch(null);
            }
        }
    }, [selectedSession.loading, obsIdFromSearch, observations, dispatch]);

    const observationTypes = useMemo(() => {
        const types = Object.keys(_.groupBy(selectedSession.observations, "class")).sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        return types;
    }, [selectedSession.observations]);

    const observation = useMemo(() => {
        if (!_.isNaN(selectedSession.currentObservation) && !_.isEmpty(observations)) {
            return observations[selectedSession.currentObservation];
        } else {
            return null;
        }
    }, [selectedSession.currentObservation, observations]);

    const sessionListComponent = useMemo(() => {
        let count = 0;
        const newList = _.map(sessionList.data, (session) => {
            if (String(session.id).indexOf(String(searchQuery)) > -1) {
                count += 1;
                return (
                    <div
                        key={session.id}
                        ref={session.id === selectedSession.sessionID ? targetedSessionListItemRef : null}
                        className={`ListItem ${session.id === selectedSession.sessionID ? "active" : ""}`}
                        onClick={() => getSessionObservationsData(session.id, session.count)}>
                        {session.id}
                        <div className={`badge `}>{session.count}</div>
                    </div>
                );
            }
        });
        setListCount(count);

        if (count) {
            return (
                <>
                    {newList}
                    {sessionList.has_more && (
                        <div className="LoadMoreButton">
                            <OBCButton
                                size="sm"
                                disabled={loadingMore}
                                onClick={loadMoreObservations}>
                                Load more {loadingMore && <LoadingOutlined style={{ marginLeft: "4px" }} />}
                            </OBCButton>
                        </div>
                    )}
                </>
            );
        } else {
            if (sessionList.has_more) {
                return (
                    <div className="LoadMoreButton">
                        <OBCButton
                            size="sm"
                            disabled={loadingMore}
                            onClick={loadMoreObservations}>
                            Load more {loadingMore && <LoadingOutlined style={{ marginLeft: "4px" }} />}
                        </OBCButton>
                    </div>
                );
            } else {
                return loadingMore ? <div>Searching...</div> : <div>{searchQuery ? "No match found" : "No sessions"}</div>;
            }
        }
    }, [sessionList, searchQuery, selectedSession, loadingMore, targetedSessionListItemRef]);

    const getSessionObservationsData = (sessionID, count, observationID = null) => {
        setObservationHasDetectionNumber(true);
        dispatch(
            getObservationData(sessionID, count, (res) => {
                if (res.success && observationID) {
                    setObsIdFromSearch(observationID);
                }
            }),
        );
    };

    const updateFilter = (key, value) => {
        const newFitlers = _.cloneDeep(filters);
        newFitlers[key] = value;
        setFilters(newFitlers);
        dispatch(goToObservation(0));
    };

    const isNextPossible = useMemo(() => {
        return selectedSession.currentObservation + 1 < observations.length;
    }, [selectedSession.currentObservation, observations]);

    const isPreviusPossible = useMemo(() => {
        return selectedSession.currentObservation !== 0;
    }, [selectedSession.currentObservation, observations]);

    const navigateToNextObservation = () => {
        if (isNextPossible) {
            setImageModalOpen(false);
            dispatch(goToObservation(selectedSession.currentObservation + 1));
        }
    };

    const navigateToPreviousObservation = () => {
        if (isPreviusPossible) {
            setImageModalOpen(false);
            dispatch(goToObservation(selectedSession.currentObservation - 1));
        }
    };

    const navigateToObservationID = (observationID) => {
        const index = _.findIndex(observations, (obs) => obs.id === observationID);
        dispatch(goToObservation(index));
        setJumpToDetectionOpen(false);
    };

    const renderImages = useMemo(() => {
        if (observation && observation["detections"]) {
            const renderedImages = _.map(_.reverse(observation["detections"]), (detection, key) => {
                let imageSource = `https://raw${MEMOIZED_DOMAIN_URL}/${detection.device_key}/${detection.session_key}/${detection.video_key}-${detection.frame}.jpg`;
                if (csrfToken) {
                    imageSource += `?csrf=${csrfToken}`;
                }
                if (key === "best_detection") {
                    setPreviewImageUrl(imageSource);
                    setPreviewImageBbox([detection.bbox]);
                }

                return (
                    <div
                        className={`ImageContainer ${key === "best_detection" ? "Large" : "Small"}`}
                        onClick={() => {
                            setImageModalOpen(true);
                            setPreviewImageUrl(imageSource);
                            setPreviewImageBbox([detection.bbox]);
                        }}
                        key={key}>
                        <div className="Tags">
                            <div className="TagLeftCol">
                                {observation.observation_type === 0 && (
                                    <>
                                        {detection.value && (
                                            <Tag className="Value">
                                                <FontAwesomeIcon
                                                    size="xs"
                                                    icon={faTemperatureHigh}
                                                />
                                                {detection.value}°C
                                            </Tag>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="TagRightCol">
                                <Tag color="orange">{_.capitalize(key.replace("_detection", ""))}</Tag>
                            </div>
                        </div>

                        <DetectionImage
                            key={detection.session_key + Date.now()} // to help with loading progress/spinner...
                            src={imageSource}
                            bbox={detection.bbox}
                            bboxColor={String(observation.class).toLocaleLowerCase() === "thermal" ? "Thermal" : ""}
                        />
                    </div>
                );
            });
            if (_.isEmpty(observation["detections"])) {
                setObservationHasDetectionNumber(false);
            }
            return renderedImages.reverse();
        } else {
            return null;
        }
    }, [observation]);

    const updateClassification = (classification) => {
        setUpdatingObservcation(true);
        dispatch(
            setObservationClassificationAdmin(observation.id, observation.observation_type, classification, () => {
                setUpdatingObservcation(false);
            }),
        );
    };

    const updateOcrCustomResult = () => {
        if (observation) {
            setUpdatingObservcation(true);
            const newOcrCustomResultInputUpdated = new AssetIDHelper(ocrCustomResultInputUpdated).slashes_to_csv();
            dispatch(
                setObservationClassificationAdmin(observation.id, observation.observation_type, newOcrCustomResultInputUpdated, () => {
                    setUpdatingObservcation(false);
                }),
            );
        }
    };

    const confirm = () => {
        const saveOCRandAcceptObservation = () => {
            dispatch(
                setObservationClassificationAdmin(observation.id, observation.observation_type, ocrCustomResultInputUpdated, (response) => {
                    if (response.success) {
                        acceptObservationReview();
                    }
                }),
            );
        };

        Modal.confirm({
            title: "Custom OCR not saved",
            content: (
                <span>
                    Do you want to save custom OCR of <strong>{ocrCustomResultInputUpdated}</strong> and Accept this observation?
                </span>
            ),
            okText: "Yes",
            cancelText: "No",
            onOk() {
                saveOCRandAcceptObservation();
            },
        });
    };

    const checkIfOcrSaved = () => {
        if (ocrCustomResultInputUpdated !== ocrCustomResultInput) {
            confirm();
        } else {
            acceptObservationReview();
        }
    };

    const acceptObservationReview = () => {
        setUpdatingObservcation(true);
        if (observation) {
            dispatch(
                reviewObservationAdmin(observation["id"], 1, observation["observation_type"], (response) => {
                    setUpdatingObservcation(false);

                    // if success but also status filter not set - navigate to next observation
                    if (response.success && !filtersActive) {
                        if (isNextPossible) {
                            navigateToNextObservation();
                        }
                    }
                }),
            );
        }
    };

    const rejectObservationReview = () => {
        setUpdatingObservcation(true);
        if (observation) {
            dispatch(
                reviewObservationAdmin(observation["id"], 3, observation["observation_type"], (response) => {
                    setUpdatingObservcation(false);

                    // if success but also status filter not set - navigate to next observation
                    if (response.success && !filtersActive) {
                        if (isNextPossible) {
                            navigateToNextObservation();
                        }
                    }
                }),
            );
        }
    };

    const renderObservationStatus = useMemo(() => {
        if (observation) {
            // console.log("debug observation", observation);
            let _name = "Need Reviewing";
            let _color = "orange";
            if (observation["review_status"] === 1) {
                _name = "Verified";
                _color = "green";
            } else if (observation["review_status"] === 3) {
                _name = "Rejected";
                _color = "#FF4D4F";
            }
            return (
                <div className="StatusWithIcon">
                    <span>{_name}</span>
                    <QuestionCircleOutlined
                        className="StatusIcon"
                        style={{ color: _color }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }, [observation]);

    const observationClassification = useMemo(() => {
        if (observation && observation.classification) {
            // below is due to result being joined from two different tables and
            // sometimes (when thermal detection) result will be string insted of object
            try {
                JSON.parse(observation.classification);
                return JSON.parse(observation.classification);
            } catch {
                return { thermal_classification: observation.classification };
            }
        } else {
            return null;
        }
    }, [observation, observations]);

    const shortcutsPopoverContent = (
        <div className="ObservationOverviewShortcutsPopover">
            <div>Next Observation:</div> <div className="Value">"."</div>
            <div>Previous Observation:</div>
            <div className="Value">","</div>
            <div>Edit OCR results:</div>
            <div className="Value">"e"</div>
            <div>Accept Observation:</div>
            <div className="Value">"a"</div>
            <div>Reject Observation:</div>
            <div className="Value">"r"</div>
            <div>Open/Close best observation preview:</div>
            <div className="Value">"z"</div>
            <div className="Note">Remember to save the OCR result before accepting Observation.</div>
        </div>
    );

    const onOverrideOcrChanged = (value) => {
        if (value.length) {
            const regexError = validateGroundTruth(observation["class"], value);
            setOcrCustomResultInputUpdatedError(regexError);
        } else {
            setOcrCustomResultInputUpdatedError(false);
        }

        setOcrCustomResultInputUpdated(value);
    };

    const searchForSession = (value) => {
        if (listCount === 0 && String(value).length) {
            loadMoreObservations();
        }
    };

    const handleKeyPress = useCallback(
        (event) => {
            if (!ocrInputActive && !jumpToDetectionOpen && !observationSearchInputActive) {
                switch (event.key) {
                    case ",":
                        navigateToPreviousObservation();
                        break;
                    case ".":
                        navigateToNextObservation();
                        break;
                    case "a":
                    case "A":
                        checkIfOcrSaved();
                        break;
                    case "r":
                    case "R":
                        rejectObservationReview();
                        break;
                    case "e":
                    case "E":
                        if (!ocrInputActive && ocrInput.current) {
                            ocrInput.current.focus();
                        }
                        break;
                    case "z":
                    case "Z":
                        console.log("debug open image preview....");
                        setImageModalOpen(!imageModalOpen);
                        break;
                    default:
                        break;
                }
            }
            // when ocrInput is active, input not empty and enter is pressed update ocr custom results
            if (ocrInputActive) {
                if (event.key === "Enter" && ocrCustomResultInputUpdated.length) {
                    updateOcrCustomResult();
                    if (ocrInput) {
                        ocrInput.current.blur();
                    }
                }
                if (event.key === "Escape") {
                    ocrInput.current.blur();
                }
            }
        },
        [navigateToPreviousObservation, navigateToNextObservation, ocrCustomResultInputUpdated],
    );

    useEffect(() => {
        document.addEventListener("keyup", handleKeyPress, false);

        return () => {
            document.removeEventListener("keyup", handleKeyPress, false);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        if (observationClassification) {
            setOcrCustomResultInput(observationClassification["ocr_result_custom"] ?? "");
            setOcrCustomResultInputUpdated(observationClassification["ocr_result_custom"] ?? "");
        } else {
            setOcrCustomResultInputUpdated("");
        }
        setOcrCustomResultInputUpdatedError(false);
    }, [observation, observationClassification]);

    const onCopyLinkCliked = (e) => {
        e.stopPropagation();
        setCopyToClipboardText("Copied!");
        navigator.clipboard.writeText(observation.id);
    };

    const closeImagePreviewModal = () => {
        setImageModalOpen(false);
    };

    const scrollToItem = () => {
        if (targetedSessionListItemRef.current) {
            targetedSessionListItemRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    };

    const searchForSessionIdByObsId = (obsID) => {
        if (String(obsID).length) {
            setSearchingForObseravtion(true);
            dispatch(
                getSessionIdByObservationId(obsID, (sessions) => {
                    setSearchingForObseravtion(false);
                    if (sessions) {
                        if (sessions.length) {
                            // in case backend return more then one session id, display notification with list of sessions
                            // this should not happen but in case it will
                            if (sessions.length > 1) {
                                // setSearchingForObseravtion(false)
                                openNotification(obsID, sessions);
                            } else {
                                getSessionObservationsData(_.head(sessions), null, obsID);
                                emulateTab();
                                scrollToItem();
                            }
                        } else {
                            // setSearchingForObseravtion(false)
                            notification.info({
                                message: "Not found!",
                                description: (
                                    <span>
                                        Unable to find observation <strong>{obsID}</strong>, try again.
                                    </span>
                                ),
                            });
                        }
                    }
                }),
            );
        }
    };

    const openNotification = (obsID, sessions) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button
                type="primary"
                size="small"
                onClick={() => notification.close(key)}>
                Close
            </Button>
        );
        notification.open({
            className: "ObservationSearchModal",
            message: "Observation Search Results",
            duration: 0,
            description: (
                <div className="ObservationSearchModalContent">
                    <span>
                        Observation <strong>{obsID}</strong> was found in more then one session:
                    </span>
                    {_.map(sessions, (session) => {
                        return (
                            <OBCButton
                                variant="success"
                                size="sm"
                                onClick={() => {
                                    getSessionObservationsData(session, null, obsID);
                                    scrollToItem();
                                    notification.close(key);
                                }}>
                                {session}
                            </OBCButton>
                        );
                    })}
                </div>
            ),
            btn,
            key,
        });
    };

    const stateOptions = useMemo(() => {
        let observationState = _.get(selectedSession, "observations", []).map((observation) => observation.state);
        observationState = _.uniq(observationState);
        const formatObservationState = _.map(observationState, (state) => {
            if (state === null) {
                return { value: state, label: "No state" };
            } else if (typeof state === "number") {
                return { value: state, label: String(state) };
            } else {
                return { value: state, label: state };
            }
        });
        return formatObservationState.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedSession]);

    return (
        <>
            <Modal
                className="PreviewImageModal"
                title={null}
                visible={imageModalOpen}
                destroyOnClose
                footer={
                    <div className="PreviewImageModalFooter">
                        <div className="OptionContainer">
                            <h4>Zoom</h4>
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={1}
                                max={20}
                                onChange={(val) => setZoom(val)}
                                value={zoom}
                            />
                        </div>

                        <div className="OptionContainer">
                            <h4>Brightness</h4>
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={-1}
                                max={1}
                                onChange={(val) => setBrightness(val)}
                                value={brightness}
                            />
                        </div>

                        <div className="OptionContainer">
                            <h4>Contrast</h4>
                            <Slider
                                tooltipVisible={false}
                                step={0.1}
                                min={-1}
                                max={1}
                                onChange={(val) => setContrast(val)}
                                value={contrast}
                            />
                        </div>
                    </div>
                }
                onCancel={() => {
                    closeImagePreviewModal();
                    setZoom(5);
                    //we might want to comment out below but due to performance issue when brigthness or contrast set this need to be set to 0 on modal close.
                    setBrightness(0);
                    setContrast(0);
                }}>
                <div className="ZoomableImageMain">
                    <ZoomableImage
                        filters={{ brightness, contrast }}
                        zoom={zoom}
                        imgSrc={previewImageUrl}
                        overlayBoxes={previewImageBbox}
                        destroyOnClose={true}
                        bboxColor={"green"}
                        updateZoom={(val) => setZoom(val)}
                    />
                </div>
            </Modal>
            <div className="ObservationReviewMain">
                <div className="LeftCol">
                    <div className="ListHeader">
                        <Input.Search
                            value={searchQuery}
                            placeholder="Search for session"
                            size="small"
                            onSearch={searchForSession}
                            disabled={listIsLoading}
                            allowClear={true}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Input.Search
                            ref={observationSearchInput}
                            size="small"
                            placeholder="Search for observation"
                            disabled={listIsLoading}
                            loading={searchingForObseravtion}
                            allowClear={true}
                            onSearch={searchForSessionIdByObsId}
                            onFocus={() => setObservationSearchInputActive(true)}
                            onBlur={() => setObservationSearchInputActive(false)}
                        />
                    </div>
                    <div className="SessionList">
                        {listIsLoading ? (
                            <OBCSpinner
                                size={70}
                                speed={3}
                                color={"#e8dfff"}
                            />
                        ) : (
                            sessionListComponent
                        )}
                    </div>
                </div>
                <div className="RightCol">
                    {selectedSession.loading === null ? (
                        <div className="SelectSessionLabel">
                            {listIsLoading ? "Loading..." : <span>Select session on the left or search for observation ID in the second search box</span>}
                        </div>
                    ) : (
                        <>
                            {updatingObservation && (
                                <div className="UpdatingObservationOverlay">
                                    <OBCSpinner
                                        className="ObservationMainSpinner"
                                        size={70}
                                        speed={3}
                                        color={"#e8dfff"}
                                    />
                                </div>
                            )}
                            <div className="ObservationOverviewTop">
                                <div className="TopLeftCol">
                                    <div className="SelectWrapper">
                                        <span>Type:</span>
                                        <Select
                                            size="small"
                                            defaultValue="all"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => updateFilter("type", value)}>
                                            <Select.Option
                                                key="all"
                                                value="all">
                                                All
                                            </Select.Option>
                                            {_.map(observationTypes, (type) => {
                                                return (
                                                    <Select.Option
                                                        key={type.toLocaleLowerCase().replace(" ", "_")}
                                                        value={type.toLocaleLowerCase().replaceAll(" ", "_")}>
                                                        {type}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className="SelectWrapper">
                                        <span>Status:</span>
                                        <Select
                                            size="small"
                                            defaultValue="all"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => updateFilter("status", value)}>
                                            <Select.Option
                                                key="all"
                                                value="all">
                                                All
                                            </Select.Option>
                                            <Select.Option
                                                key="need_reviewing"
                                                value={0}>
                                                Need Reviewing
                                            </Select.Option>
                                            <Select.Option
                                                key="rejected"
                                                value={3}>
                                                Rejected
                                            </Select.Option>
                                            <Select.Option
                                                key="verified"
                                                value={1}>
                                                Verified
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <div className="SelectWrapper">
                                        <span>Classification:</span>
                                        <Select
                                            size="small"
                                            defaultValue="all"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => updateFilter("hasClassification", value)}>
                                            <Select.Option
                                                key="all"
                                                value="all">
                                                All
                                            </Select.Option>
                                            <Select.Option
                                                key="set"
                                                value={"set"}>
                                                Set
                                            </Select.Option>
                                            <Select.Option
                                                key="unset"
                                                value={"unset"}>
                                                Not Set
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <div className="SelectWrapper">
                                        <span>OCR Result:</span>
                                        <Select
                                            size="small"
                                            defaultValue="all"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => updateFilter("hasOcrResult", value)}>
                                            <Select.Option
                                                key="all"
                                                value="all">
                                                All
                                            </Select.Option>
                                            <Select.Option
                                                key="set"
                                                value={"set"}>
                                                Set
                                            </Select.Option>
                                            <Select.Option
                                                key="custom_set"
                                                value={"custom_set"}>
                                                Custom Set
                                            </Select.Option>
                                            <Select.Option
                                                key="unset"
                                                value={"unset"}>
                                                Not Set
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <div className="SelectWrapper">
                                        <span>State:</span>
                                        <Select
                                            value={filters.state}
                                            size="small"
                                            defaultValue="all"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => updateFilter("state", value)}>
                                            <Select.Option
                                                key={"all"}
                                                value={"all"}>
                                                All
                                            </Select.Option>
                                            {stateOptions.map((state) => {
                                                return (
                                                    <Select.Option
                                                        key={state.value}
                                                        value={state.value}>
                                                        {state.label}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="TopRightCol">
                                    <Popover
                                        placement="bottomRight"
                                        arrowPointAtCenter={true}
                                        title={"Keyboard shortcuts"}
                                        content={shortcutsPopoverContent}
                                        trigger="click">
                                        <InfoCircleOutlined className="InfoIcon" />
                                    </Popover>
                                </div>
                            </div>

                            <div className="ObservationMain">
                                {selectedSession.loading ? (
                                    <OBCSpinner
                                        className="ObservationMainSpinner"
                                        size={70}
                                        speed={3}
                                        color={"#e8dfff"}
                                    />
                                ) : !observationHasDetectionNumber ? (
                                    <div>This observation missing some data... please come back at later time...</div>
                                ) : observations.length > 0 ? (
                                    <>
                                        <div className="Top">
                                            <div className="NavigationContainer">
                                                <div className="LeftCol">
                                                    <OBCButton
                                                        disabled={!isPreviusPossible}
                                                        size="sm"
                                                        icon={faAngleLeft}
                                                        onClick={navigateToPreviousObservation}>
                                                        Back (,)
                                                    </OBCButton>
                                                    <div className="CountLabel">{`${selectedSession.currentObservation + 1} of ${observations.length}`}</div>
                                                    <OBCButton
                                                        disabled={!isNextPossible}
                                                        size="sm"
                                                        icon={faAngleRight}
                                                        iconPosition="right"
                                                        onClick={navigateToNextObservation}>
                                                        Next (.)
                                                    </OBCButton>
                                                </div>
                                                <div className="RightCol">
                                                    {!jumpToDetectionOpen && (
                                                        <div
                                                            className="ObservationId SearchButton"
                                                            onClick={() => setJumpToDetectionOpen(true)}>
                                                            <FontAwesomeIcon
                                                                size="xs"
                                                                icon={faSearch}
                                                            />
                                                        </div>
                                                    )}
                                                    {jumpToDetectionOpen ? (
                                                        <Select
                                                            showSearch
                                                            size="small"
                                                            style={{ width: 100 }}
                                                            placeholder="Select observation"
                                                            optionFilterProp="children"
                                                            value={observation.id}
                                                            defaultOpen={true}
                                                            autoFocus={true}
                                                            onChange={navigateToObservationID}
                                                            onFocus={() => emulateTab()}
                                                            onBlur={() => setJumpToDetectionOpen(false)}
                                                            filterOption={(input, option) =>
                                                                option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                            }>
                                                            {observations &&
                                                                _.map(observations, (obs) => {
                                                                    return <Select.Option value={obs.id}>{obs.id}</Select.Option>;
                                                                })}
                                                        </Select>
                                                    ) : (
                                                        <Tooltip
                                                            className="TokenManagerTooltip"
                                                            title={copyToClipboardText}
                                                            onVisibleChange={(visible) => {
                                                                if (!visible) {
                                                                    setCopyToClipboardText("Copy to clipboard");
                                                                }
                                                            }}>
                                                            <div
                                                                className="ObservationId"
                                                                onClick={(e) => onCopyLinkCliked(e)}>
                                                                #{observation && observation.id}
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ObservationThreeImages">{renderImages}</div>
                                            <div className="ObservationMatchCount">Class: {(observation && observation["class"]) ?? "unknown"}</div>
                                            <div className="ObservationMatchCount">Match count: {(observation && observation["match_count"]) ?? "unknown"}</div>
                                            <div className="ObservationStatus">
                                                <span>Status:</span>
                                                {renderObservationStatus}
                                            </div>
                                            {observation &&
                                                observation["observation_type"] === 0 &&
                                                thermalClassifications &&
                                                thermalClassifications.length > 0 && (
                                                    <div className="DropdownContainer">
                                                        <span>Classification:</span>
                                                        <Select
                                                            className="ThermalClassificationsSelect"
                                                            size="small"
                                                            value={
                                                                observationClassification && observationClassification["thermal_classification"]
                                                                    ? observationClassification["thermal_classification"]
                                                                    : "None"
                                                            }
                                                            onChange={(classification) => updateClassification(classification)}
                                                            style={{
                                                                width: 120,
                                                                color:
                                                                    observationClassification &&
                                                                    !thermalClassifications.includes(observationClassification["thermal_classification"])
                                                                        ? "red"
                                                                        : "#000000a6",
                                                            }}>
                                                            {observationClassification &&
                                                                !thermalClassifications.includes(observationClassification["thermal_classification"]) && (
                                                                    <Select.Option
                                                                        className="ThermalClassificationNotListed"
                                                                        key={JSON.parse(JSON.parse(observation.classification[0]))}
                                                                        value={JSON.parse(observation.classification[0])}>
                                                                        {JSON.parse(observation.classification[0])}
                                                                    </Select.Option>
                                                                )}
                                                            {_.map(thermalClassifications, (classification) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={classification}
                                                                        value={classification}>
                                                                        {_.capitalize(classification.replace("_", " "))}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {observation.auto_classified && <OBCAutoIndicator />}
                                                    </div>
                                                )}
                                            {(observation && observation["observation_type"]) !== 0 && (
                                                <div className="ObservationControlPanel">
                                                    {observation && ["Signal", "Speedboard", "Structure ID Plate"].includes(observation["class"]) && (
                                                        <div className="DropdownContainer">
                                                            <span>OCR Results:</span>
                                                            <div className="Col">
                                                                <div className="Row">
                                                                    <Tag color="orange">
                                                                        {observationClassification && observationClassification["ocr_result"]
                                                                            ? new AssetIDHelper(observationClassification["ocr_result"]).csv_to_slashes()
                                                                            : "n/a"}
                                                                    </Tag>
                                                                    {observation.auto_classified && <OBCAutoIndicator />}
                                                                </div>
                                                                <div className="Row">
                                                                    <Input
                                                                        style={{ width: "200px" }}
                                                                        size="small"
                                                                        ref={ocrInput}
                                                                        onFocus={() => setOcrInputActive(true)}
                                                                        onBlur={() => setOcrInputActive(false)}
                                                                        placeholder="Override OCR result"
                                                                        title="Keep the same format as above (/) is a new line"
                                                                        value={ocrCustomResultInputUpdated}
                                                                        onChange={(e) => onOverrideOcrChanged(e.target.value)}
                                                                    />
                                                                    <Button
                                                                        type="primary"
                                                                        size="small"
                                                                        disabled={
                                                                            ocrCustomResultInputUpdated === ocrCustomResultInput ||
                                                                            ocrCustomResultInputUpdatedError
                                                                        }
                                                                        onClick={updateOcrCustomResult}>
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                                <div className="Row">
                                                                    <span style={{ color: "red" }}>{ocrCustomResultInputUpdatedError}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="Bottom">
                                            <div className="Buttons">
                                                <OBCButton
                                                    onClick={rejectObservationReview}
                                                    variant="danger">
                                                    Reject (R)
                                                </OBCButton>
                                                <OBCButton onClick={checkIfOcrSaved}>Accept (A)</OBCButton>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="NoMatchFoundLabel">
                                            No match found, <span>Try to adjust your filter options above</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ObservationReview;
