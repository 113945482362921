import { faArrowCircleLeft, faArrowCircleRight, faEdit, faSortNumericUp, faSpellCheck, faTimes, faRedo } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Input, Modal, Popconfirm, Slider, Tooltip, Switch } from "antd";
import ZoomableImage from "components/ZoomableImage";
import moment from "moment";
import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    reviewIssueObservation,
    routeSelected,
    selectObservation,
    updateIssue,
    setAssetUnreadable,
    selectAsset,
    goToBounds,
    selectIssue,
    getIssueObservations,
} from "redux/actions";
import _ from "lodash";
import { combineGroundTruthConsensus, StatusElement, validateGroundTruth } from "./issueUtils";
import OBCSpinner from "components/util/OBC";
import AssetIDHelper from "components/util/AssetIDHelper";

const PopoverButton = ({ onClick, buttonText, popoverText, type, disabled }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popconfirm
            placement="topLeft"
            title={"Are you sure?"}
            onConfirm={onClick}
            okText="Yes"
            cancelText="No">
            <Tooltip
                placement="topRight"
                title={popoverText}
                visible={open}>
                <Button
                    type={type}
                    className={`WarnButton ${disabled ? "Disabled" : ""}`}
                    onClick={() => setOpen(false)}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}>
                    {buttonText}
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={"ButtonIcon"}
                    />
                </Button>
            </Tooltip>
        </Popconfirm>
    );
};

const issuesSelector = (state) => state.issues.data;
const assetsSelector = (state) => state.mlAssets.assetData;
const selectedIssueDataSelector = (state) => state.issues.selected;
const selectedAssetDataSelector = (state) => state.mlAssets.selected;
const superuserSelector = (state) => state.userDetails.userConfig.super_admin;
const assetIDsSelector = (state) => state.mlAssets.ids;
const filteredIssuesSelector = (state) => state.issues.filteredIssues;
const issuesObservationsSelector = (state) => state.issues.observations;
const csrfTokenSelector = (state) => state.csrfToken;

const ObservationModal = ({ closeModal, observations, isIssue, unreadableUpdated, setUnreadableUpdated, displayUnreadable }) => {
    const dispatch = useDispatch();

    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [editingFieldKey, setEditingFieldKey] = useState();
    const [editingFieldValue, setEditingFieldValue] = useState();
    const [groundTruthError, setGroundTruthError] = useState();
    const [displayReadableModal, setDisplayReadableModal] = useState(false);
    const [loadingNextAsset, setLoadingNextAsset] = useState(false);
    const [showImageOverlay, setShowImageOverlay] = useState(true);

    const issues = useSelector(issuesSelector);
    const assets = useSelector(assetsSelector);
    const selectedIssueData = useSelector(selectedIssueDataSelector);
    const selectedAssetData = useSelector(selectedAssetDataSelector);
    const superuser = useSelector(superuserSelector);
    const assetIDs = useSelector(assetIDsSelector);
    const filteredIssues = useSelector(filteredIssuesSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const issuesObservations = useSelector(issuesObservationsSelector);

    const groundTruthInputRef = useRef();

    const observation = useMemo(() => {
        if (!observations || !observations.length) {
            return {};
        }

        if (isIssue) {
            const selectedObservation = _.find(observations, { id: selectedIssueData.observation });
            if (selectedObservation) {
                return selectedObservation;
            }
        } else {
            const selectedObservation = _.find(observations, { id: selectedAssetData.observation });
            if (selectedObservation) {
                return selectedObservation;
            }
        }
        return {};
    }, [isIssue, observations, selectedAssetData, selectedIssueData]);

    const bottomOutTemperature = useMemo(() => {
        if (!isIssue) {
            let assetType = _.get(_.find(assets, { id: selectedAssetData.asset }), "class");
            if (assetType !== "Tensioner") {
                return undefined;
            }
            return _.get(observation, "bottom_out_temperature", null);
        }
        return undefined;
    }, [observation, assets, selectedAssetData, isIssue]);

    const humanDate = useMemo(() => {
        if (observation.timestamp) {
            return moment.unix(observation.timestamp / 1000).format("LLL");
        }
    }, [observation]);

    const orderedObservations = useMemo(() => {
        return _.orderBy(observations, "timestamp", "desc");
    }, [observations]);

    const currentIndex = useMemo(() => {
        return _.findIndex(orderedObservations, { id: selectedAssetData.observation });
    }, [orderedObservations, selectedAssetData.observation]);

    const navigate = useCallback(
        (forward) => {
            if (forward) {
                const nextObservation = orderedObservations[currentIndex + 1];
                dispatch(selectObservation(nextObservation.id));
            } else {
                const nextObservation = orderedObservations[currentIndex - 1];
                dispatch(selectObservation(nextObservation.id));
            }
            if (!showImageOverlay) {
                setShowImageOverlay(true);
            }
            if (zoom !== 1) {
                setZoom(1);
            }
        },
        [currentIndex, dispatch, orderedObservations, showImageOverlay, zoom],
    );

    const currentIssue = useMemo(() => {
        if (isIssue) {
            return _.find(issues, { id: selectedIssueData.issue });
        } else {
            return assets[selectedAssetData.asset];
        }
    }, [assets, isIssue, issues, selectedAssetData.asset, selectedIssueData.issue]);

    const onEditClick = (fieldKey, fieldValue) => {
        setEditingFieldKey(fieldKey);

        let value = fieldValue;
        if (value === "Unknown") {
            value = "";
        }
        if (fieldKey !== "Midpoint Distance") {
            value = new AssetIDHelper(value).csv_to_slashes();
        }
        setEditingFieldValue(value);
        setTimeout(() => {
            if (groundTruthInputRef && groundTruthInputRef.current) {
                groundTruthInputRef.current.focus();
            }
        }, 100);
    };

    const saveGroundTruthUpdate = useCallback(
        (fieldKey) => {
            const newGroundTruthObj = _.cloneDeep(currentIssue.ground_truth_data);
            newGroundTruthObj[fieldKey] = new AssetIDHelper(editingFieldValue).slashes_to_csv();
            const newGroundTruthString = JSON.stringify(newGroundTruthObj);
            dispatch(
                updateIssue(
                    currentIssue.id,
                    {
                        ground_truth_data: newGroundTruthString,
                    },
                    false,
                ),
            );
            setEditingFieldKey(null);
            setEditingFieldValue(null);
            closeModal();
        },
        [currentIssue, dispatch, editingFieldValue, closeModal],
    );

    const saveMidpointDistanceUpdate = useCallback(
        (fieldKey) => {
            let newGroundTruthObj = _.cloneDeep(currentIssue.ground_truth_data);
            // if value has been cleared then remove midpoint_distance_meters key from the object
            if (editingFieldValue.length) {
                newGroundTruthObj["midpoint_distance_meters"] = Number(editingFieldValue);
            } else {
                newGroundTruthObj = _.omit(newGroundTruthObj, "midpoint_distance_meters");
            }
            const newGroundTruthString = JSON.stringify(newGroundTruthObj);
            dispatch(
                updateIssue(
                    currentIssue.id,
                    {
                        ground_truth_data: newGroundTruthString,
                    },
                    isIssue,
                    true,
                ),
            );
            setEditingFieldKey(null);
            setEditingFieldValue(null);
        },
        [currentIssue, dispatch, editingFieldValue, isIssue],
    );

    const formatImage = (imgUrl) => {
        // remove frame index if session is from before 01/05/2020
        if (Math.round(observation.timestamp / 1000) <= 1588287599) {
            const arr = imgUrl.split("");
            const lastSlash = _.findLastIndex(arr, (e) => e === "/");
            const lastIndex = _.findLastIndex(arr, (e) => e === "-");
            if (lastIndex > lastSlash) {
                return arr.slice(0, lastIndex).join("") + ".jpg";
            }
        }

        if (csrfToken) {
            imgUrl += `?csrf=${csrfToken}`;
        }
        return imgUrl;
    };

    const onEditingFieldChange = (fieldKey, value) => {
        const regexError = validateGroundTruth(fieldKey, value);
        let newValue = value;
        if (regexError) {
            setGroundTruthError(regexError);
        } else {
            setGroundTruthError(null);
        }

        if (fieldKey === "Midpoint Distance") {
            newValue = value.replace(/[^0-9.]+/g, "").replace(/^(\d+\.\d{0,2}).*/g, "$1");
        } else {
            // if Signal ID or Structural ID
            newValue = new AssetIDHelper(value).csv_to_slashes();
        }
        setEditingFieldValue(newValue);
    };

    const issueInfo = useMemo(() => {
        const infoItems = [];
        if (_.get(currentIssue, ["summary_data", "match_count"], 0)) {
            infoItems.push(
                <div
                    className="InfoItem"
                    key="gap">
                    <div className="TitleContainer">
                        <p className="Title">{isIssue ? "Issue " : "Asset "}Observation Count</p>
                    </div>
                    <div className="InfoValues">
                        <FontAwesomeIcon
                            className="InfoIcon"
                            icon={faSortNumericUp}
                        />
                        <p className="InfoText">{_.get(currentIssue, ["summary_data", "match_count"], 0)}</p>
                    </div>
                </div>,
            );
        }

        // ground truth for Assets
        if (currentIssue && currentIssue.ground_truth_data && currentIssue.consensus_data) {
            const issuePropertyData = combineGroundTruthConsensus(currentIssue.ground_truth_data, currentIssue.consensus_data, currentIssue.class);

            console.log("debug issuePropertyData", issuePropertyData);

            if (!_.isEmpty(issuePropertyData)) {
                issuePropertyData.forEach((property) => {
                    let value = property.value;

                    // replace the way we display Signal and "Structure IDs
                    if (["Signal ID", "Structure ID"].includes(property.name)) {
                        value = new AssetIDHelper(property.value).csv_to_slashes();
                    }
                    // do not display too many digits after decimal point for coherence
                    else if (property.name === "Coherence") {
                        value = String(_.floor(parseInt(property.value), 2)) + "%";
                    }

                    let valueElement = (
                        <>
                            <FontAwesomeIcon
                                className="InfoIcon"
                                icon={property.icon}
                            />
                            <p className="InfoText">{value}</p>
                            <StatusElement
                                status={property.status}
                                issue={currentIssue}
                                groundTruthKey={property.fieldName}
                                onVerify={closeModal}
                            />
                        </>
                    );
                    let editIcon = faEdit;
                    let onIconClick = () => onEditClick(property.fieldName, property.value);

                    if (property.fieldName === editingFieldKey) {
                        valueElement = (
                            <Input.Search
                                ref={groundTruthInputRef}
                                size="medium"
                                onSearch={() => saveGroundTruthUpdate(property.fieldName)}
                                className="InfoValueInput"
                                value={editingFieldValue}
                                onChange={(e) => onEditingFieldChange(property.fieldName, e.target.value)}
                                enterButton={
                                    <Button
                                        disabled={!!groundTruthError}
                                        type="primary"
                                        onClick={() => saveGroundTruthUpdate(property.fieldName)}>
                                        Save
                                    </Button>
                                }
                            />
                        );
                        editIcon = faTimes;
                        onIconClick = () => {
                            setEditingFieldKey(null);
                            setEditingFieldValue(null);
                            setGroundTruthError(null);
                        };
                    }
                    infoItems.push(
                        <div
                            className="InfoItem"
                            key="gap">
                            <div className="TitleContainer">
                                <p className="Title">{property.name}</p>
                                {property.status && (
                                    <FontAwesomeIcon
                                        className="EditIcon"
                                        icon={editIcon}
                                        onClick={onIconClick}
                                    />
                                )}
                            </div>
                            <div className="InfoValues">{valueElement}</div>
                        </div>,
                    );
                });
            }
        }

        // Enable ground truth input field for Issues and Assets
        if (currentIssue && currentIssue.class === "Tensioner" && currentIssue.ground_truth_data) {
            let editIcon = faEdit;
            const balanceWeightStructureID = _.get(currentIssue, ["ground_truth_data", "structure_id"], "");
            let onIconClick = () => onEditClick("Structure ID", balanceWeightStructureID);

            let structureIDValueElement = <p className="InfoText">{balanceWeightStructureID}</p>;

            if (editingFieldKey === "Structure ID") {
                structureIDValueElement = (
                    <div>
                        <Input
                            ref={groundTruthInputRef}
                            size="medium"
                            style={{ width: "160px" }}
                            className="InfoValueInput"
                            value={editingFieldValue}
                            onChange={(e) => onEditingFieldChange("structure_id", e.target.value)}
                        />
                        <Button
                            type="primary"
                            disabled={!!groundTruthError}
                            onClick={() => saveGroundTruthUpdate("structure_id")}>
                            Save
                        </Button>
                    </div>
                );

                editIcon = faTimes;
                onIconClick = () => {
                    setEditingFieldKey(null);
                    setEditingFieldValue(null);
                    setGroundTruthError(null);
                };
            }

            infoItems.push(
                <div
                    className="InfoItem"
                    key="gap">
                    <div className="TitleContainer">
                        <p className="Title">Structure ID</p>
                        <FontAwesomeIcon
                            className="EditIcon"
                            icon={editIcon}
                            onClick={onIconClick}
                        />
                    </div>
                    <div className="InfoValues">{structureIDValueElement}</div>
                </div>,
            );

            let issueMPD;

            if (observation.class_specific_data) {
                issueMPD = _.get(observation, ["class_specific_data", "estimated_mp_distance"]);
            } else {
                issueMPD = _.get(observation, ["observation_info", "estimated_mp_distance"]);
            }

            let valueElement;
            if (issueMPD && !currentIssue.ground_truth_data?.midpoint_distance_meters) {
                valueElement = (
                    <>
                        <p className="InfoText">{issueMPD + "m" + " (MPD Estimated Automatically)"}</p>
                    </>
                );
            } else if (issueMPD && currentIssue.ground_truth_data?.midpoint_distance_meters) {
                valueElement = (
                    <>
                        <p className="InfoText">{currentIssue.ground_truth_data.midpoint_distance_meters + "m"}</p>
                    </>
                );
            }

            onIconClick = () => onEditClick("Midpoint Distance", issueMPD ?? "");
            editIcon = faEdit;

            if ("Midpoint Distance" === editingFieldKey) {
                valueElement = (
                    <Input.Search
                        ref={groundTruthInputRef}
                        size="medium"
                        style={{ width: "220px" }}
                        onSearch={() => saveMidpointDistanceUpdate("Midpoint Distance")}
                        className="InfoValueInput"
                        value={editingFieldValue}
                        onChange={(e) => onEditingFieldChange("Midpoint Distance", e.target.value)}
                        suffix={"meters"}
                        maxLength={8}
                        enterButton={
                            <Button
                                disabled={!!groundTruthError}
                                type="primary"
                                onClick={() => saveMidpointDistanceUpdate("Midpoint Distance")}>
                                Save
                            </Button>
                        }
                    />
                );
                editIcon = faTimes;
                onIconClick = () => {
                    setEditingFieldKey(null);
                    setEditingFieldValue(null);
                    setGroundTruthError(null);
                };
            }
            infoItems.push(
                <div
                    className="InfoItem"
                    key="gap">
                    <div className="TitleContainer">
                        <p className="Title">{"Midpoint Distance"}</p>
                        {true && (
                            <FontAwesomeIcon
                                className="EditIcon"
                                icon={editIcon}
                                onClick={onIconClick}
                            />
                        )}
                    </div>
                    <div className="InfoValues">{valueElement}</div>
                </div>,
            );
        }

        if (bottomOutTemperature !== undefined) {
            infoItems.push(
                <div
                    className="InfoItem"
                    key="gap">
                    <div className="TitleContainer">
                        <p className="Title">Bottom Out Temperature</p>
                    </div>
                    <div className="InfoValues">{bottomOutTemperature || bottomOutTemperature === 0 ? `${bottomOutTemperature}°C` : ""}</div>
                </div>,
            );
        }

        return infoItems;
    }, [
        currentIssue,
        isIssue,
        closeModal,
        editingFieldKey,
        editingFieldValue,
        groundTruthError,
        saveGroundTruthUpdate,
        saveMidpointDistanceUpdate,
        bottomOutTemperature,
        observation,
    ]);

    const openSession = () => {
        console.log("debug observation", observation);
        if (observation.rail_inspection) {
            const railInspectionLink = `/rail-inspection/${observation.session_id}/${observation.timestamp}`;
            window.open(railInspectionLink);
        } else {
            const timeToJumpTo = observation.timestamp / 1000 || observation.video_key || observation.rail_image_timestamp / 1000;
            dispatch(routeSelected(observation.session_id, timeToJumpTo));
        }
        closeModal();
    };

    const reviewObservation = (reviewStatus) => {
        if (observations.length === 1) {
            dispatch(updateIssue(currentIssue.id, { state: 2 }, isIssue));
        }
        dispatch(reviewIssueObservation(observation.id, reviewStatus, observation.observation_type, currentIssue.id, isIssue));
        closeModal();
    };

    const handleKeyPress = useCallback(
        (event) => {
            switch (event.key) {
                case "ArrowLeft":
                    if (currentIndex > 0) {
                        navigate(false);
                    }
                    break;
                case "ArrowRight":
                    if (currentIndex + 1 !== observations.length) {
                        navigate(true);
                    }
                    break;
                default:
                    break;
            }
        },
        [currentIndex, observations.length, navigate],
    );

    useEffect(() => {
        document.addEventListener("keyup", handleKeyPress, false);
        return () => {
            document.removeEventListener("keyup", handleKeyPress, false);
        };
    }, [handleKeyPress]);

    const updateZoom = useCallback((zoom) => {
        setZoom(zoom);
    }, []);

    const overlayBbox = useMemo(() => {
        if (observation.bbox) {
            return [observation.bbox];
        } else {
            return [];
        }
    }, [observation.bbox]);

    const ocrResult = useMemo(() => {
        if (!superuser) {
            return null;
        }

        let ocrResult = _.get(observation, ["class_specific_data", "ocr_result_custom"], "");
        if (!ocrResult) {
            ocrResult = _.get(observation, ["class_specific_data", "ocr_result"]);
        }
        const formattedOcrResult = new AssetIDHelper(ocrResult).csv_to_slashes();
        if (!ocrResult) {
            return null;
        }
        return (
            <div
                className="InfoItem"
                key="gap">
                <div className="TitleContainer">
                    <p className="Title">OCR Result</p>
                </div>
                <div className="InfoValues">
                    <p className="InfoText">{formattedOcrResult}</p>
                </div>
            </div>
        );
    }, [observation, superuser]);

    const vegetationState = useMemo(() => {
        const vegetation = _.get(observation, "vegetation_state", []);
        if (vegetation.length) {
            let vegetationContent = "Acceptable vegetation observed";
            if (vegetation.includes("vegetation_around_base")) {
                vegetationContent = "Vegetation around the base";
            }
            if (vegetation.includes("bad")) {
                vegetationContent = "Unacceptable vegetation observed";
            }
            return (
                <div
                    className="InfoItem"
                    key="vegetation">
                    <div className="TitleContainer">
                        <p className="Title">Vegetation</p>
                    </div>
                    <div className="InfoValues">{vegetationContent}</div>
                </div>
            );
        }
    }, [observation]);

    const toggleAssetReadable = () => {
        const unreadable = !currentIssue.category;
        dispatch(setAssetUnreadable(currentIssue.id, unreadable));
        setDisplayReadableModal(false);
        currentIssue.category = unreadable ? 1 : 0;
        setUnreadableUpdated(!unreadableUpdated);
    };

    const prevAssetData = useMemo(() => {
        let prevAsset = false;
        if (currentIssue.id) {
            let currentIndex = _.findIndex(assetIDs, (asset) => asset[0] === currentIssue.id);
            if (isIssue) {
                let currentIndex = _.findIndex(filteredIssues, (issue) => issue.id === currentIssue.id);
                prevAsset = _.get(filteredIssues, [currentIndex - 1], false);
            } else {
                prevAsset = _.get(assetIDs, [currentIndex - 1], false);
            }
        }
        return prevAsset;
    }, [assetIDs, currentIssue.id, filteredIssues, isIssue]);

    const nextAssetData = useMemo(() => {
        let nextAvailable = false;
        if (currentIssue.id) {
            let currentIndex = _.findIndex(assetIDs, (asset) => asset[0] === currentIssue.id);
            if (isIssue) {
                currentIndex = _.findIndex(filteredIssues, (issue) => issue.id === currentIssue.id);
                nextAvailable = _.get(filteredIssues, [currentIndex + 1], false);
            } else {
                nextAvailable = _.get(assetIDs, [currentIndex + 1], false);
            }
        }
        return nextAvailable;
    }, [assetIDs, currentIssue.id, filteredIssues, isIssue]);

    const goToAssetID = (assetData) => {
        if (assetData) {
            setLoadingNextAsset(true);

            if (isIssue) {
                const issueID = _.get(assetData, "id");
                dispatch(selectIssue(issueID));
                dispatch(getIssueObservations(issueID)).then((observations) => {
                    let observationsCount = Object.keys(observations).length;
                    let observationID = _.get(observations, [[observationsCount - 1], "id"], false);

                    let north = _.get(assetData, ["coords", [1]], 0) + 0.002;
                    let east = _.get(assetData, ["coords", [0]], 0) + 0.002;
                    let south = _.get(assetData, ["coords", [1]], 0) - 0.002;
                    let west = _.get(assetData, ["coords", [0]], 0) - 0.002;

                    if (observationID) {
                        dispatch(selectObservation(observationID));
                        dispatch(
                            goToBounds({
                                north,
                                south,
                                east,
                                west,
                            }),
                        );
                    }
                    setLoadingNextAsset(false);
                });
            } else {
                const assetID = _.get(assetData, [0]);
                dispatch(selectAsset(assetID)).then((observations) => {
                    let observationsCount = _.get(observations, ["observations"], []).length || _.get(observations, ["external_observations"], []).length;
                    let observationID =
                        _.get(observations, ["observations", [observationsCount - 1], "id"], false) ||
                        _.get(observations, ["external_observations", [observationsCount - 1], "id"], false);

                    let north = _.get(assetData, [[1], [1]], 0) + 0.002;
                    let east = _.get(assetData, [[1], [0]], 0) + 0.002;
                    let south = _.get(assetData, [[1], [1]], 0) - 0.002;
                    let west = _.get(assetData, [[1], [0]], 0) - 0.002;

                    if (observationID) {
                        dispatch(selectObservation(observationID));
                        dispatch(
                            goToBounds({
                                north,
                                south,
                                east,
                                west,
                            }),
                        );
                    }
                    setLoadingNextAsset(false);
                });
            }
        }
    };

    const toggleImageOverlay = () => {
        setShowImageOverlay(!showImageOverlay);
    };

    return (
        <Modal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            width={"90%"}
            transitionName=""
            className="ObservationModal"
            bodyStyle={{ height: "85vh", overflow: "hidden", padding: 15 }}
            footer={
                <div className="ObservationModalFooter">
                    <div className="LeftContainer">
                        <PopoverButton
                            type="danger"
                            onClick={() => reviewObservation(4)}
                            buttonText={"Unmatch"}
                            disabled={loadingNextAsset}
                            popoverText={"This observation does not match with the others in this issue, it should be treated separately."}
                        />

                        <PopoverButton
                            type="danger"
                            onClick={() => reviewObservation(3)}
                            buttonText={"Reject"}
                            disabled={loadingNextAsset}
                            popoverText={"This observation is incorrect. It should be completely ignored."}
                        />

                        {[88, 89].includes(observation.matching_state) && (
                            <PopoverButton
                                type="primary"
                                onClick={() => reviewObservation(5)}
                                buttonText={"Match"}
                                disabled={loadingNextAsset}
                                popoverText={"Match this observation to the current issue."}
                            />
                        )}
                    </div>

                    <div className="RightContainer">
                        <Button
                            onClick={closeModal}
                            key="close">
                            Close
                        </Button>
                        <Button
                            onClick={openSession}
                            type="primary"
                            key="open">
                            Open Session
                        </Button>
                    </div>
                </div>
            }>
            <div className="ObservationModalContainer">
                <div className="Header">
                    <div className="TitleContainer">
                        <p className="TitleLabel">{isIssue ? "Issue " : "Asset "} ID</p>
                        <div className="TitleRow">
                            <Tooltip title={isIssue ? "Previous Issue" : "Previous Asset"}>
                                <FontAwesomeIcon
                                    onClick={() => goToAssetID(prevAssetData)}
                                    className={"AssetNavigationIcon Left" + (!prevAssetData ? " Disabled" : "")}
                                    size="lg"
                                    icon={faArrowCircleLeft}
                                />
                            </Tooltip>
                            {!!currentIssue && <div className="TitleValue">#{currentIssue.id}</div>}
                            <Tooltip title={isIssue ? "Next Issue" : "Next Asset"}>
                                <FontAwesomeIcon
                                    onClick={() => goToAssetID(nextAssetData)}
                                    className={"AssetNavigationIcon Right" + (!nextAssetData ? " Disabled" : "")}
                                    size="lg"
                                    icon={faArrowCircleRight}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="InfoItems">
                        {issueInfo}
                        {displayUnreadable && (
                            <Button
                                type="danger"
                                onClick={() => setDisplayReadableModal(!displayReadableModal)}>
                                {!currentIssue.category ? "Unreadable" : "Readable"}
                            </Button>
                        )}
                    </div>
                    {!!groundTruthError && <p className="GroundTruthError">{groundTruthError}</p>}
                </div>
                {_.get(observation, "reprocessing", false) && <p className="RecalculationMessage">Midpoint distance is currently being recalculated.</p>}
                <Modal
                    visible={displayReadableModal}
                    onOk={toggleAssetReadable}
                    onCancel={() => setDisplayReadableModal(false)}>
                    <p style={{ margin: "0" }}>Mark this asset as {!currentIssue.category ? "unreadable" : "readable"}?</p>
                </Modal>

                <Divider className="Divider" />
                {!loadingNextAsset && (
                    <div className="ObservationInfoContainer">
                        <div
                            className="InfoItem"
                            key="gap">
                            <div className="TitleContainer">
                                <p className="Title">Observation ID</p>
                            </div>
                            <div className="InfoValues">
                                <p className="InfoText">#{observation.id}</p>
                            </div>
                        </div>

                        <div
                            className="InfoItem"
                            key="gap">
                            <div className="TitleContainer">
                                <p className="Title">Observed On</p>
                            </div>
                            <div className="InfoValues">
                                <p className="InfoText">{humanDate}</p>
                            </div>
                        </div>

                        {ocrResult}
                        {vegetationState}
                    </div>
                )}

                {!loadingNextAsset && (
                    <>
                        <Tooltip title="Previous Observation">
                            <FontAwesomeIcon
                                onClick={() => {
                                    navigate(false);
                                    setBrightness(0);
                                    setContrast(0);
                                }}
                                className={"NavigationIcon Left" + (currentIndex - 1 < 0 ? " Disabled" : "")}
                                icon={faArrowCircleLeft}
                            />
                        </Tooltip>

                        <Tooltip title="Next Observation">
                            <FontAwesomeIcon
                                onClick={() => {
                                    navigate(true);
                                    setBrightness(0);
                                    setContrast(0);
                                }}
                                className={"NavigationIcon Right" + (currentIndex + 1 > observations.length - 1 ? " Disabled" : "")}
                                icon={faArrowCircleRight}
                            />
                        </Tooltip>
                    </>
                )}

                <div className="ObservationImageContainer">
                    {loadingNextAsset ? (
                        <OBCSpinner
                            className="ObservationMainSpinner"
                            size={100}
                            speed={3}
                            colorScheme="mono"
                        />
                    ) : (
                        <ZoomableImage
                            filters={{ brightness, contrast }}
                            zoom={zoom}
                            overlayBoxes={showImageOverlay && !observation.source_image_url ? overlayBbox : []} //do not draw bbox if obsercation has source_image_url
                            imgSrc={formatImage(observation.source_image_url && !showImageOverlay ? observation.source_image_url : observation.image_url)}
                            withSpinner={true}
                            updateZoom={updateZoom}
                        />
                    )}
                </div>
                <div className="SettingsContainer">
                    <div className="OptionContainer">
                        <h4>Zoom</h4>
                        <Slider
                            tooltipVisible={false}
                            step={0.1}
                            min={1}
                            max={20}
                            onChange={updateZoom}
                            disabled={loadingNextAsset}
                            value={zoom}
                        />
                    </div>

                    <div className="OptionContainer">
                        <div className="obsModalOptionContainer">
                            <h4>Brightness</h4>
                            <Tooltip title="Reset Brightness">
                                <FontAwesomeIcon
                                    className="ResetButton"
                                    icon={faRedo}
                                    onClick={() => setBrightness(0)}
                                    size="sm"
                                    style={{ cursor: "pointer", margin: "5px", transform: "scaleX(-1)" }}
                                />
                            </Tooltip>
                        </div>
                        <Slider
                            tooltipVisible={false}
                            step={0.1}
                            min={-1}
                            max={1}
                            onChange={(val) => setBrightness(val)}
                            disabled={loadingNextAsset}
                            value={brightness}
                        />
                    </div>

                    <div className="OptionContainer">
                        <div className="obsModalOptionContainer">
                            <h4>Contrast</h4>
                            <Tooltip title="Reset Contrast">
                                <FontAwesomeIcon
                                    className="ResetButton"
                                    icon={faRedo}
                                    onClick={() => setContrast(0)}
                                    size="sm"
                                    style={{ cursor: "pointer", margin: "5px", transform: "scaleX(-1)" }}
                                />
                            </Tooltip>
                        </div>
                        <Slider
                            tooltipVisible={false}
                            step={0.1}
                            min={-1}
                            max={1}
                            onChange={(val) => setContrast(val)}
                            disabled={loadingNextAsset}
                            value={contrast}
                        />
                    </div>
                    {observation.source_image_url && (
                        <div
                            className="InfoItem"
                            key="overlay">
                            <div className="TitleContainer">
                                <h4>Show Overlay</h4>
                            </div>
                            <div className="InfoValues">
                                <Switch
                                    defaultChecked
                                    size="small"
                                    checked={showImageOverlay}
                                    onChange={toggleImageOverlay}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ObservationModal;
