import _ from "lodash";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { jsonPostV2, receiveTrackGeomData } from "../../../redux/actions";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";

const GEOMETRY_BASE_URL = `https://tg${MEMOIZED_DOMAIN_URL}/`;

function boundedValue(min, value, max) {
    return Math.max(min, Math.min(max, value));
}

function boundedRange(min, low, high, max) {
    const delta = high - low;
    if (max - min < delta) {
        return [min, max];
    }
    if (low < min) {
        low = min;
        high = low + delta;
    }
    if (high > max) {
        high = max;
        low = high - delta;
    }
    return [low, high];
}

function combineBy(options, key) {
    return _.chain(options)
        .flatMap(_.partial(_.get, _, key, undefined))
        .filter((i) => i)
        .value();
}

function convertRange(value, startA, endA, startB, endB) {
    const diffA = endA - startA;
    const diffB = endB - startB;
    const ratio = (value - startA) / diffA;
    return ratio * diffB + startB;
}

function useCombinedOptions(options) {
    const combinedDataRef = useRef({});

    //Options contains an array of objects
    //If all three objects are the same, do nothing
    //Otherwise, for each key combine the values from the different arrays

    const keyList = useMemo(() => {
        return _.uniq(_.flatMap(options, _.keysIn));
    }, [...options]);

    return useMemo(() => {
        let changed = false;
        const changedData = {};
        const newCombinedData = { ...combinedDataRef.current };
        keyList.forEach((key) => {
            if (!(key in newCombinedData)) {
                newCombinedData[key] = [];
                changed = true;
            }
            const lastOptions = newCombinedData[key];
            const optionsForKey = combineBy(options, key);
            if (optionsForKey.length !== lastOptions.length || _.some(optionsForKey, (option, idx) => !Object.is(option, lastOptions[idx]))) {
                changedData[key] = optionsForKey;
                newCombinedData[key] = optionsForKey;
                changed = true;
            }
        });
        if (changed) {
            mapIdsToIndices(newCombinedData);
            combinedDataRef.current = newCombinedData;
        }
        return [combinedDataRef.current, changedData];
    }, [...options, keyList]);
}

function mapIdsToIndices(options) {
    if ("series" in options) {
        options.series.forEach((series) => {
            series.datasetIndex = options.dataset.findIndex((_i) => _i.id === series.datasetId);
            series.xAxisIndex = options.xAxis.findIndex((_i) => _i.id === series.xAxisId);
            series.yAxisIndex = options.yAxis.findIndex((_i) => _i.id === series.yAxisId);
        });
    }

    if ("xAxis" in options) {
        options.xAxis.forEach((xAxis) => {
            xAxis.gridIndex = options.grid.findIndex((_i) => _i.id === xAxis.gridId);
        });
    }
    if ("yAxis" in options) {
        options.yAxis.forEach((yAxis) => {
            yAxis.gridIndex = options.grid.findIndex((_i) => _i.id === yAxis.gridId);
        });
    }
    const axisPointer = _.get(options, "axisPointer[0].link[0]");
    if (axisPointer && "xAxisId" in axisPointer) {
        axisPointer.xAxisIndex = axisPointer.xAxisId.map((id) => options.xAxis.findIndex((_i) => _i.id === id));
    }
}

function useOnClick(chart, grids, callback) {
    const onChartClick = useCallback(
        (e) => {
            if (chart) {
                const x = e.event.zrX;
                const y = e.event.zrY;
                const clickWithinGrid = grids.reduce((map, grid) => {
                    if (chart.containPixel({ gridId: grid }, [x, y])) {
                        map[grid] = chart.convertFromPixel({ gridId: grid }, [x, y]);
                    }
                    return map;
                }, {});
                if (Object.keys(clickWithinGrid).length) {
                    callback(clickWithinGrid);
                }
            }
        },
        [chart, grids, callback],
    );

    useEffect(() => {
        if (chart) {
            const zr = chart.getZr();
            if (zr) {
                zr.on("click", onChartClick);
                return () => {
                    if (zr.handler) {
                        zr.off("click", onChartClick);
                    }
                };
            }
        }
    }, [chart, onChartClick]);
}

function useHover(chart, grids, callback) {
    const hoverStateRef = useRef({});
    const onMouseMove = useCallback(
        (e) => {
            if (chart) {
                const x = e.event.zrX;
                const y = e.event.zrY;
                const lastHoverState = hoverStateRef.current;
                hoverStateRef.current = grids.map((grid) => {
                    return chart.containPixel({ gridId: grid }, [x, y]);
                });
                const changedHoverStates = hoverStateRef.current.reduce((map, state, idx) => {
                    if (state !== lastHoverState[idx]) {
                        map[grids[idx]] = state;
                    }
                    return map;
                }, {});
                if (Object.keys(changedHoverStates).length) {
                    callback(changedHoverStates);
                }
            }
        },
        [chart, grids, callback],
    );

    useEffect(() => {
        if (chart) {
            const zr = chart.getZr();
            if (zr) {
                zr.on("mouseover", onMouseMove);
                zr.on("mousemove", onMouseMove);
                zr.on("mouseout", onMouseMove);
                return () => {
                    if (zr.handler) {
                        zr.off("mouseover", onMouseMove);
                        zr.off("mousemove", onMouseMove);
                        zr.off("mouseout", onMouseMove);
                    }
                };
            }
        }
    }, [chart, onMouseMove]);
}

function useMouseMovement(chart, grid_id, dragCallback, endDragCallback) {
    const dragStateRef = useRef(null);

    const onMouseDown = useCallback(
        (e) => {
            if (chart) {
                if (dragStateRef.current) {
                    return;
                }
                const x = e.event.zrX;
                const y = e.event.zrY;
                const withinGridBounds = chart.containPixel({ gridId: grid_id }, [x, y]);
                if (!withinGridBounds) {
                    return;
                }
                dragStateRef.current = {
                    type: "pan",
                    startXY: [x, y],
                    start: chart.convertFromPixel({ gridId: grid_id }, [x, y]),
                };

                dragCallback({
                    type: "drag",
                    start: dragStateRef.current.start,
                    current: dragStateRef.current.start,
                    delta: [0, 0, 0],
                });
            }
        },
        [chart, grid_id, dragCallback],
    );

    const onMouseMove = useCallback(
        (e) => {
            if (chart) {
                if (!dragStateRef.current || dragStateRef.current.type !== "pan") {
                    return;
                }
                const x = e.event.zrX;
                const y = e.event.zrY;
                const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
                const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [x, y]);

                dragCallback({
                    type: "drag",
                    start: dragStateRef.current.start,
                    current: chart.convertFromPixel({ gridId: grid_id }, [x, y]),
                    delta: [startCoordinates[0] - endCoordinates[0], startCoordinates[1] - endCoordinates[1], 0],
                });
            }
        },
        [chart, grid_id, dragCallback],
    );

    const onMouseUp = useCallback(
        (e) => {
            if (chart) {
                if (!dragStateRef.current || dragStateRef.current.type !== "pan") {
                    return;
                }
                const x = e.event.zrX;
                const y = e.event.zrY;
                const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
                const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [x, y]);
                endDragCallback({
                    type: "drag",
                    start: dragStateRef.current.start,
                    current: chart.convertFromPixel({ gridId: grid_id }, [x, y]),
                    delta: [startCoordinates[0] - endCoordinates[0], startCoordinates[1] - endCoordinates[1], 0],
                });
                dragStateRef.current = null;
            }
        },
        [chart, grid_id, endDragCallback],
    );

    const wheelMovementUpdate = useCallback(() => {
        if (!dragStateRef.current || dragStateRef.current.type !== "wheel") {
            return;
        }

        if (dragStateRef.current.direction === "pan") {
            const currentX = dragStateRef.current.startXY[0] + dragStateRef.current.dX;
            const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
            const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [currentX, dragStateRef.current.startXY[1]]);
            dragCallback({
                type: "wheel_pan",
                start: dragStateRef.current.start,
                current: startCoordinates,
                delta: [startCoordinates[0] - endCoordinates[0], 0, 0],
            });
        } else {
            const currentY = dragStateRef.current.startXY[1] + dragStateRef.current.dY;
            const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
            const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [dragStateRef.current.startXY[0], currentY]);
            dragCallback({
                type: "wheel_zoom",
                start: dragStateRef.current.start,
                current: startCoordinates,
                delta: [0, 0, startCoordinates[1] - endCoordinates[1]],
            });
        }
    }, [chart, grid_id, dragCallback]);

    const wheelMovementComplete = useCallback(() => {
        if (!dragStateRef.current || dragStateRef.current.type !== "wheel") {
            return;
        }

        if (dragStateRef.current.direction === "pan") {
            const currentX = dragStateRef.current.startXY[0] + dragStateRef.current.dX;
            const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
            const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [currentX, dragStateRef.current.startXY[1]]);
            endDragCallback({
                type: "wheel_pan",
                start: dragStateRef.current.start,
                current: startCoordinates,
                delta: [startCoordinates[0] - endCoordinates[0], 0, 0],
            });
        } else {
            const currentY = dragStateRef.current.startXY[1] + dragStateRef.current.dY;
            const startCoordinates = chart.convertFromPixel({ gridId: grid_id }, dragStateRef.current.startXY);
            const endCoordinates = chart.convertFromPixel({ gridId: grid_id }, [dragStateRef.current.startXY[0], currentY]);
            endDragCallback({
                type: "wheel_zoom",
                start: dragStateRef.current.start,
                current: startCoordinates,
                delta: [0, 0, startCoordinates[1] - endCoordinates[1]],
            });
        }

        dragStateRef.current = null;
    }, [chart, grid_id, endDragCallback]);

    const onMouseWheel = useCallback(
        (e) => {
            if (chart) {
                e.stop();
                if (dragStateRef.current && dragStateRef.current.type !== "wheel") {
                    return;
                }

                const dX = -e.event.deltaX;
                const dY = e.event.deltaY;

                if (!dragStateRef.current) {
                    const direction = Math.abs(dY) >= Math.abs(dX) ? "zoom" : "pan";
                    const x = e.event.zrX;
                    const y = e.event.zrY;
                    dragStateRef.current = {
                        type: "wheel",
                        direction,
                        startXY: [x, y],
                        start: chart.convertFromPixel({ gridId: grid_id }, [x, y]),
                        dX,
                        dY,
                        timeout: setTimeout(wheelMovementComplete, 500),
                    };
                } else {
                    dragStateRef.current.dX += dX;
                    dragStateRef.current.dY += dY;
                    clearTimeout(dragStateRef.current.timeout);
                    dragStateRef.current.timeout = setTimeout(wheelMovementComplete, 500);
                }

                wheelMovementUpdate();
            }
        },
        [chart, grid_id, wheelMovementUpdate, wheelMovementComplete],
    );

    useEffect(() => {
        if (chart) {
            const zr = chart.getZr();
            if (zr) {
                zr.on("mousedown", onMouseDown);
                zr.on("mousemove", onMouseMove);
                zr.on("mouseup", onMouseUp);
                zr.on("mousewheel", onMouseWheel);
                return () => {
                    if (zr.handler) {
                        zr.off("mousedown", onMouseDown);
                        zr.off("mousemove", onMouseMove);
                        zr.off("mouseup", onMouseUp);
                        zr.off("mousewheel", onMouseWheel);
                    }
                };
            }
        }
    }, [chart, onMouseDown, onMouseMove, onMouseUp, onMouseWheel]);
}

const geometryDataSelector = (state) => state.trackGeometry.data;

function asyncLoadGeometry(filename) {
    return fetch(GEOMETRY_BASE_URL + filename + ".json", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
    }).then((response) => {
        if (response && response.ok) {
            return response.json();
        } else {
            return Promise.reject();
        }
    });
}

function asyncLoadGeometryFile(data, store, dispatch) {
    const _initialData = geometryDataSelector(store.getState());

    const firstDataItem = _initialData[data.start];
    if (firstDataItem.loaded === false) {
        console.log("Downloading track geometry data from file: ", data.filename);
        return asyncLoadGeometry(data.filename)
            .then((value) =>
                value.source.map((row) => ({
                    ..._.zipObject(value.dimensions, row),
                    elr: data.elr,
                    trid: data.track,
                    track_category: data.track_category,
                    line_speed: data.line_speed,
                    upstream: data.upstream,
                })),
            )
            .then((newData) => {
                newData[0].loaded = true;
                const _currentData = geometryDataSelector(store.getState());
                const newGeometryData = [..._currentData.slice(0, data.start), ...newData, ..._currentData.slice(data.end + 1)];
                dispatch(receiveTrackGeomData(newGeometryData));
                return newGeometryData;
            });
    } else {
        return Promise.resolve(_initialData);
    }
}

function downloadTrackGeometryHeaders(session_id, store, dispatch) {
    let postBody = {
        action: "get_track_geometry_headers",
        session_id,
    };

    let url = "/sessions";

    return jsonPostV2(url, store.getState(), postBody, dispatch).then((response) => {
        return _.sortedUniqBy(response.data_files || [], (f) => f.start_timestamp);
    });
}

function asyncSideLoadGeometryFile(data, _initialData) {
    const firstDataItem = _initialData[data.start];
    if (firstDataItem.loaded === false) {
        console.log("Downloading comparison geometry data from file: ", data.filename);
        return asyncLoadGeometry(data.filename)
            .then((value) =>
                value.source.map((row) => ({
                    ..._.zipObject(value.dimensions, row),
                    elr: data.elr,
                    trid: data.track,
                    track_category: data.track_category,
                    line_speed: data.line_speed,
                    upstream: data.upstream,
                })),
            )
            .then((newData) => {
                newData[0].loaded = true;
                for (let idx = 0; idx < newData.length; idx++) {
                    _initialData[idx + data.start] = newData[idx];
                }
                return true;
            });
    } else {
        return Promise.resolve(false);
    }
}

export {
    useCombinedOptions,
    useOnClick,
    useHover,
    boundedValue,
    boundedRange,
    useMouseMovement,
    mapIdsToIndices,
    convertRange,
    asyncLoadGeometry,
    geometryDataSelector,
    asyncLoadGeometryFile,
    asyncSideLoadGeometryFile,
    downloadTrackGeometryHeaders,
};
