import * as React from "react";

export default class YAxisComponent extends React.PureComponent {
    render() {
        return (
            <div
                style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 5,
                    background: "#2f334d",
                }}>
                <svg
                    width="60"
                    height="250"
                    viewBox="1 0 60 250">
                    <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
                        <line
                            className="recharts-yAxis yAxis"
                            orientation="left"
                            width="60"
                            height="245"
                            type="number"
                            x="0"
                            y="5"
                            stroke="#ffffff"
                            fill="none"
                            x1="60"
                            y1="4"
                            x2="60"
                            y2="250"></line>
                        <g className="recharts-cartesian-axis-ticks">
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line
                                    className="recharts-yAxis yAxis"
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="0"
                                    y="5"
                                    stroke="#ffffff"
                                    fill="none"
                                    x1="54"
                                    y1="188.75"
                                    x2="60"
                                    y2="188.75"></line>
                                <text
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="52"
                                    y="218.75"
                                    stroke="none"
                                    fill="#ffffff"
                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                    textAnchor="end">
                                    <tspan
                                        x="52"
                                        dy="0.355em">
                                        None
                                    </tspan>
                                </text>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line
                                    className="recharts-yAxis yAxis"
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="0"
                                    y="5"
                                    stroke="#ffffff"
                                    fill="none"
                                    x1="54"
                                    y1="127.5"
                                    x2="60"
                                    y2="127.5"></line>
                                <text
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="52"
                                    y="157.5"
                                    stroke="none"
                                    fill="#ffffff"
                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                    textAnchor="end">
                                    <tspan
                                        x="52"
                                        dy="0.355em">
                                        Low
                                    </tspan>
                                </text>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line
                                    className="recharts-yAxis yAxis"
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="0"
                                    y="5"
                                    stroke="#ffffff"
                                    fill="none"
                                    x1="54"
                                    y1="66.25"
                                    x2="60"
                                    y2="66.25"></line>
                                <text
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="52"
                                    y="96.25"
                                    stroke="none"
                                    fill="#ffffff"
                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                    textAnchor="end">
                                    <tspan
                                        x="52"
                                        dy="0.355em">
                                        Medium
                                    </tspan>
                                </text>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line
                                    className="recharts-yAxis yAxis"
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="0"
                                    y="5"
                                    stroke="#ffffff"
                                    fill="none"
                                    x1="54"
                                    y1="5"
                                    x2="60"
                                    y2="5"></line>
                                <text
                                    orientation="left"
                                    width="60"
                                    height="245"
                                    type="number"
                                    x="52"
                                    y="35"
                                    stroke="none"
                                    fill="#ffffff"
                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                    textAnchor="end">
                                    <tspan
                                        x="52"
                                        dy="0.355em">
                                        High
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
