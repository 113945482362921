import React, { memo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import memoizeOne from "memoize-one";
import OBCSpinner from "../util/OBC";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarOutline } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { favouriteSession, getSessionData, routeSelected } from "../../redux/actions/index";
import AutoSizer from "react-virtualized-auto-sizer";
import { areEqual, FixedSizeList } from "react-window";
import ContentLoader from "react-content-loader";
import { convertToTimezone } from "../util/TimezoneUtils";

const SessionSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={62}
        viewBox="0 0 440 62"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175"
        {...props}>
        <rect
            x="0"
            y="18"
            rx="4"
            ry="4"
            width="60"
            height="10"
        />
        <rect
            x="0"
            y="37"
            rx="4"
            ry="4"
            width="60"
            height="10"
        />
        <rect
            x="75"
            y="18"
            rx="4"
            ry="4"
            width="100"
            height="14"
        />
        <rect
            x="75"
            y="40"
            rx="4"
            ry="4"
            width="200"
            height="14"
        />
    </ContentLoader>
);

class SessionComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();

        this.state = {
            sessionData: null,
            sessionName: "",
        };
    }

    componentDidMount() {
        this.props.dispatch(getSessionData(this.props.sessionID)).then((sessionData) => {
            this.setState({
                sessionData,
                sessionName: _.get(sessionData, ["route_name"], ""),
            });
        });
    }

    navigateToSession = (sessionID) => {
        this.props.dispatch(routeSelected(sessionID, 0));
        this.props.history.push("/mobile/view/" + sessionID);
    };

    favouriteSession = (e) => {
        this.props.dispatch(favouriteSession(this.props.session.id, !this.props.favourite));
        e.stopPropagation();
    };

    render() {
        const session = this.state.sessionData;

        if (!session) {
            return (
                <div
                    className="SessionListItem mobile"
                    style={this.props.style}>
                    <SessionSkeleton sessionID={this.props.sessionID} />
                </div>
            );
        }

        let className = "SessionListItem mobile";

        let duration = 0;
        let niceDuration = 0;
        if (session.length_seconds > 0) {
            duration = moment.duration(session.length_seconds * 1000);
            if (duration._data.hours === 0 && duration._data.minutes === 0 && duration._data.seconds <= 60) {
                niceDuration = "00:01";
            } else {
                niceDuration = moment.utc(duration.as("milliseconds")).format("HH:mm");
            }
        }

        const startTime = new Date(session.first_seen * 1000);
        const niceDate = convertToTimezone(startTime, this.props.userConfig.convert_to_utc);

        let favIcon = (
            <FontAwesomeIcon
                className="nonFavouriteIcon"
                icon={faStarOutline}
                onClick={(e) => this.favouriteSession(e, session.id)}
            />
        );

        if (session.favourite) {
            favIcon = (
                <FontAwesomeIcon
                    className="favouriteIcon"
                    icon={faStar}
                    onClick={(e) => this.favouriteSession(e, session.id)}
                />
            );
        }

        return (
            <div
                className={className}
                ref={this.elementRef}
                onClick={() => this.navigateToSession(session.id)}
                style={this.props.style}>
                <div className="sessionIDandDurationContainer">
                    <div className="sessionIDContainer light">
                        <p className="sessionID">#{session.id}</p>
                    </div>

                    {duration > 0 && (
                        <div className="sessionIDContainer dark">
                            <p className="sessionDuration">{niceDuration}</p>
                        </div>
                    )}
                </div>
                <div className="sessionNameContainer">
                    <p className="sessionName">{this.state.sessionName}</p>
                    <p className="sessionDate">{niceDate}</p>
                </div>

                <div className="SessionFavourite">{favIcon}</div>
            </div>
        );
    }
}

const mapStateToSessionProps = (state) => {
    return {
        userIsAdmin: state.permissions.admin,
        userConfig: state.userDetails.userConfig,
    };
};

const Session = connect(mapStateToSessionProps)(withRouter(SessionComponent));

class MobileSessionList extends React.PureComponent {
    render() {
        const sessions = this.props.sessionIDs;
        const GUTTER_SIZE = 8;

        const Row = memo(({ index, style }) => {
            const sessionID = sessions[index];

            return (
                <Session
                    style={{
                        ...style,
                        left: style.left,
                        top: style.top + GUTTER_SIZE,
                        width: style.width - GUTTER_SIZE,
                        height: style.height - GUTTER_SIZE,
                    }}
                    key={sessionID}
                    viewing={sessionID === this.props.viewedRouteID}
                    sessionID={sessionID}
                />
            );
        }, areEqual);

        let content = (
            <div className="sessionListContainer mobile">
                <AutoSizer className="sessionAutosizer">
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height + GUTTER_SIZE}
                            width={width}
                            itemSize={70}
                            itemCount={this.props.sessionIDs.length}>
                            {Row}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </div>
        );

        if (this.props.sessionListLoading) {
            content = (
                <div className="sessionSpinner">
                    <OBCSpinner
                        size={100}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        if (!this.props.sessionIDs.length && !this.props.sessionListLoading) {
            content = <h1 className="NoSessionText">No sessions found</h1>;
        }

        return (
            <div className={"SessionList mobile " + (this.props.toolbarOpen ? "toolbarOpen" : "")}>
                <div className="sessionContainer">{content}</div>
            </div>
        );
    }
}

const orderSessions = memoizeOne((sessions) => _.orderBy(sessions, ["first_seen"], ["desc"]));

const mapStateToProps = (state) => {
    const sessions = orderSessions(state.sessions);

    return {
        sessions,
        viewedRouteID: state.playlist.data.routeID,
        userConfig: state.userDetails.userConfig,
        sessionIDs: state.sessionList,
        sessionListLoading: state.sessionListFilters.refreshing,
    };
};

export default connect(mapStateToProps)(withRouter(MobileSessionList));
