import _ from "lodash";
import React from "react";
import { faCamera, faDrawSquare, faFastBackward, faFastForward, faMoon, faSun, faTag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Button } from "antd";
import memoizeOne from "memoize-one";

export const TOGGLE_TAG_PAIRS = [
    ["Forward", "Backward"],
    ["Day", "Night"],
];
const TAG_ICON_COLOUR = {
    Day: 1,
    Night: 2,
    Forward: 3,
    Backward: 4,
};

export const tagIcons = {
    sun: {
        icon: faSun,
        color: "yellow",
    },
    moon: {
        icon: faMoon,
        color: "black",
    },
    fastForward: {
        icon: faFastForward,
    },
    fastBackward: {
        icon: faFastBackward,
    },
    camera: {
        icon: faCamera,
        color: "blue",
    },
    Detection: {
        icon: faDrawSquare,
        color: "purple",
    },
};

export const tagCategoryProperties = {
    "Day / Night": {
        priority: 1,
        defaultOpen: true,
    },
    "Forward / Backward": {
        priority: 2,
        defaultOpen: true,
    },
    "Video Type": {
        priority: 3,
        defaultOpen: true,
    },
    Detection: {
        priority: 4,
        defaultOpen: false,
    },
    Zone: {
        priority: 5,
        defaultOpen: false,
    },
    Other: {
        priority: 6,
        defaultOpen: false,
    },
};

export const filterSessionTags = memoizeOne((sessionTags, detectionTypes, whitelistFilters) => {
    let filteredTags = [];
    sessionTags.forEach((tag) => {
        const detectionType = _.find(detectionTypes, { type: tag.tag });
        if (detectionType && detectionType.display_type === "never") {
            return;
        }

        filteredTags.push({
            ...tag,
            display_name: detectionType?.display_name,
        });
    });

    if (whitelistFilters && whitelistFilters.length > 0) {
        filteredTags = filteredTags.filter((tag) => whitelistFilters.map((item) => item.toLowerCase()).includes(tag.tag.toLowerCase()));
    }

    filteredTags = _.uniqBy(filteredTags, "tag");

    return _.groupBy(filteredTags, (tag) => {
        if (tag.category) {
            return tag.category;
        } else {
            if (tag.source === "marker" || tag.tag_type === "detection") {
                return "Detection";
            } else if (tag.source === "zone") {
                return "Zone";
            } else if (tag.source === "aoi") {
                return "Area Of Interest";
            }
        }
        return "Other";
    });
});

export const renderTagPanels = (tags, onTagClick, selectedTags) => {
    const tagCategories = Object.keys(tags);
    const orderedCategories = _.orderBy(tagCategories, (cat) => _.get(tagCategoryProperties, [cat, "priority"], 999));
    const isSelected = (tag) => {
        return selectedTags.includes(tag.toLowerCase());
    };

    return orderedCategories.map((tagCategory) => {
        const tagButtons = tags[tagCategory].map((tag) => {
            const icon = _.get(tagIcons, [tag.icon, "icon"], _.get(tagIcons, [tagCategory, "icon"], faTag));
            const iconColor = _.get(tagIcons, [tag.icon, "color"], _.get(tagIcons, [tagCategory, "color"], "darkGrey"));
            return (
                <Button
                    className={`sessionTagButton ${isSelected(tag.tag) ? " active" : ""}`}
                    key={tag.tag}
                    onClick={() => onTagClick(tag.tag)}>
                    <div className={`sessionTagButton__Icon ${iconColor}`}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                    {tag.display_name || tag.tag}
                </Button>
            );
        });

        return (
            <Collapse.Panel
                header={tagCategory}
                key={tagCategory}>
                <div className="TagsFlexBox">{tagButtons}</div>
            </Collapse.Panel>
        );
    });
};

const SESSION_TAGS_CACHE = {};
let SESSION_TAGS_CACHE_KEY = null;

export const formatSessionTags = (session, detectionTypes, allTags) => {
    const newCacheKey = Object.keys(detectionTypes).length;
    if (SESSION_TAGS_CACHE[session.id] && newCacheKey === SESSION_TAGS_CACHE_KEY) {
        return SESSION_TAGS_CACHE[session.id];
    }

    let tags = [];
    let tagsWithIcons = [];
    let tagsWithoutIcons = [];
    let whitelist = ["Night", "Day", "Forward", "Backward"];

    let has8K = false;
    if (!_.isEmpty(session)) {
        tags = session.sourced_tags || [];
        if (tags && tags.length) {
            [tagsWithIcons, tagsWithoutIcons] = _.partition(tags, (tag) => whitelist.includes(tag.tag));
            tagsWithIcons = tagsWithIcons.map((tagObj) => tagObj.tag);

            tagsWithIcons = _.sortBy(tagsWithIcons, (tag) => TAG_ICON_COLOUR[tag.tag]);
            tagsWithoutIcons =
                tagsWithoutIcons.length > 0 &&
                _.chain(tagsWithoutIcons)
                    .map((tag) => {
                        return {
                            ..._.find(allTags, (tagObj) => {
                                return (
                                    tagObj.tag === tag.tag &&
                                    ((tagObj.source === "marker" && tag.source === "marker") || (tagObj.source !== "marker" && tag.source !== "marker"))
                                );
                            }),
                            display_name: _.get(detectionTypes, [tag.tag, "display_name"]),
                        };
                    })
                    .filter((tag) => {
                        if (!tag) {
                            return false;
                        }
                        const detectionType = detectionTypes[tag.tag];
                        if (!detectionType) {
                            return false;
                        }
                        if (detectionType.display_type === "never") {
                            return false;
                        }

                        return tag && detectionTypes.hasOwnProperty(tag.tag) && detectionTypes[tag.tag]["display_type"] !== "never";
                    })
                    .sortBy((tag) => tag.tag_colour)
                    .sortBy((tag) => -_.get(detectionTypes, [tag.tag, "importance"], 0))
                    .value();
        }

        const streamInfo = _.get(session, ["stream_info"], []);
        if (Array.isArray(streamInfo)) {
            streamInfo.forEach((stream) => {
                let label = _.get(stream, ["info", "label"], "");
                if (label.toLowerCase().includes("8k")) {
                    has8K = true;
                }
            });
        }
    }

    SESSION_TAGS_CACHE[session.id] = [tagsWithIcons, tagsWithoutIcons, has8K];
    SESSION_TAGS_CACHE_KEY = newCacheKey;
    return [tagsWithIcons, tagsWithoutIcons, has8K];
};
