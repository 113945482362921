import ReactDOM from "react-dom";
import videojs from "video.js";
import React from "react";
import { connect, Provider } from "react-redux";
import store from "../../../redux/store";
import moment from "moment";
import OBCSpinner from "../../util/OBC";
import { convertToTimezone } from "../../util/TimezoneUtils";

const Component = videojs.getComponent("Component");
const dom = videojs.dom || videojs;
const registerPlugin = videojs.registerPlugin || videojs.plugin;

class ReactLoadingOverlay extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            visible: false,
        };
        this.hideEvent = null;
        this.loadingSessionInfoData = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.visible && prevState.visible !== this.state.visible) {
            // if loadingSessionInfoData is rendered, focus on it so screen reader if triggered.
            if (this.loadingSessionInfoData && this.loadingSessionInfoData.current) {
                this.loadingSessionInfoData.current.focus();
            }
        }
    }

    hide = () => {
        clearTimeout(this.hideEvent);
        this.hideEvent = setTimeout(() => {
            this.setState({
                visible: false,
            });
        }, 500);
    };

    show = () => {
        clearTimeout(this.hideEvent);
        this.setState({
            visible: true,
        });
    };

    render() {
        const session = this.props.session;
        if (!session) {
            return null;
        }

        const startDate = new Date(session.first_seen * 1000);
        const niceDate = convertToTimezone(startDate, this.props.userConfig.convert_to_utc);

        let duration = 0;
        if (session.length_seconds > 0) {
            duration = moment.duration(session.length_seconds * 1000);
        }

        return (
            <React.Fragment>
                {this.state.visible && (
                    <div
                        className="VideoLoadingOverlay HiddenFocus"
                        tabIndex={0}
                        ref={this.loadingSessionInfoData}>
                        <div>
                            <OBCSpinner
                                size={64}
                                colorScheme={"mono"}
                            />
                        </div>
                        <div className="VideoLoadingLoading">Now loading</div>
                        <div className="VideoLoadingTitle">{session.route_name}</div>
                        <div className="VideoLoadingDate">Recorded on {niceDate}</div>
                        {session.length_seconds > 0 && <div className="VideoLoadingDuration">Duration: {duration.humanize()}</div>}
                        {this.props.sourceCount > 1 && (
                            <div className="VideoLoadingSource">
                                Source {this.props.sourceIndex + 1} of {this.props.sourceCount}
                            </div>
                        )}
                        {!!this.props.isEnhanced && (
                            <div className="VideoLoadingEnhanced">{this.props.isEnhanced === "enhanced" ? "Overlaid" : "Low Bandwidth"} Video</div>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const routeID = state.playlist.data.routeID;

    return {
        routeID,
        session: state.sessions[routeID],
        isEnhanced: state.playlist.position.isEnhanced,
        sourceIndex: state.playlist.position.sourceIndex,
        sourceCount: (state.playlist.data.video || []).length,
        userConfig: state.userDetails.userConfig,
    };
};

const LoadingOverlayComponent = connect(mapStateToProps, null, null, { forwardRef: true })(ReactLoadingOverlay);

class LoadingOverlay extends Component {
    constructor(player, options) {
        super(player, options);

        this.timer = null;

        player.on("aivrLoadingStarted", () => {
            console.log("Got aivrLoadingStarted event");
            this.show();
        });
        player.on("aivrLoadingComplete", () => {
            console.log("Got aivrLoadingComplete event");
            this.hide();
        });

        this.hide();
    }

    createEl() {
        const el = dom.createEl("div", {
            className: "vjs-loading-overlay vjs-hidden",
        });

        this.containerRef = React.createRef();

        ReactDOM.render(
            <Provider store={store}>
                <LoadingOverlayComponent
                    player={this.player()}
                    ref={this.containerRef}
                />
            </Provider>,
            el,
        );

        console.log("Container ref: ", this.containerRef);

        return el;
    }

    dispose() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el());
        }
    }

    hide() {
        super.hide();
        clearTimeout(this.timer);
        if (this.containerRef.current) {
            this.containerRef.current.hide();
        } else {
            this.timer = setTimeout(() => {
                this.hide();
            }, 50);
        }
    }

    show() {
        super.show();
        clearTimeout(this.timer);
        if (this.containerRef.current) {
            this.containerRef.current.show();
        } else {
            this.timer = setTimeout(() => {
                this.show();
            }, 50);
        }
    }
}

videojs.registerComponent("LoadingOverlay", LoadingOverlay);

const loadingOverlayPlugin = function (options) {
    const playerChild = this.addChild("loadingOverlay", options);

    this.el().insertBefore(playerChild.el(), this.controlBar.el());

    return playerChild;
};

registerPlugin("loadingOverlay", loadingOverlayPlugin);
