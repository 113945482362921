import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getComparableSessions, getFavouriteCategories, getSessionData, resetComparableSessions } from "redux/actions";
import OBCSpinner from "components/util/OBC";
import _ from "lodash";
import { convertToTimezone } from "components/util/TimezoneUtils";
import ContentLoader from "react-content-loader";

const comparableSessionsSelector = (state) => state.railInspection.comparableSessions;
const currentSessionSelector = (state) => state.playlist.data.routeID;
const userConfigSelector = (state) => state.userDetails.userConfig;
const favouriteCategoriesSelector = (state) => state.userDetails.favouriteCategories;

const SessionSkeleton = () => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={52}
        viewBox="0 0 780 52"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175">
        <rect
            x="15"
            y="0"
            rx="0"
            ry="0"
            width="346"
            height="20"
        />
        <rect
            x="15"
            y="35"
            rx="0"
            ry="0"
            width="278"
            height="20"
        />
    </ContentLoader>
);

const LoadedSession = ({ sessionID, onClick, active }) => {
    const dispatch = useDispatch();
    const [sessionData, setSessionData] = useState({});
    const userConfig = useSelector(userConfigSelector);

    useEffect(() => {
        dispatch(getSessionData(sessionID)).then((sessionData) => {
            setSessionData(sessionData);
        });
    }, [dispatch, sessionID]);

    const startTime = useMemo(() => {
        const startTime = new Date(_.get(sessionData, "first_seen", 0) * 1000);
        return convertToTimezone(startTime, userConfig.convert_to_utc);
    }, [sessionData, userConfig.convert_to_utc]);
    if (_.isEmpty(sessionData)) {
        return (
            <div
                className="SessionItem"
                style={{ maxHeight: "52px", padding: "0" }}>
                <SessionSkeleton />
            </div>
        );
    }

    return (
        <div
            className={"SessionItem" + (active ? " Active" : "")}
            onClick={() => onClick(sessionData.id)}>
            <span className="RouteName">
                #{sessionData.id} - {sessionData.route_name}
            </span>

            <span className="RouteDate">{startTime}</span>
        </div>
    );
};

const RailCompareModal = ({ visible, toggleModal, calculatedELR, selectSession, selectedComparingSession, clearSecondarySession }) => {
    const comparableSessions = useSelector(comparableSessionsSelector);
    const currentSessionID = useSelector(currentSessionSelector);
    const favouriteCategories = useSelector(favouriteCategoriesSelector);

    const [loading, setLoading] = useState(false);
    const [favCategory, setFavCategory] = useState("all");

    const dispatch = useDispatch();

    // get all favourite categories, users and shared by other users
    useEffect(() => {
        dispatch(getFavouriteCategories(true));
    }, []);

    useEffect(() => {
        if (visible) {
            setLoading(true);
            dispatch(resetComparableSessions());
            dispatch(
                getComparableSessions(calculatedELR.route, calculatedELR.position, () => {
                    setLoading(false);
                }),
            );
        }
    }, [calculatedELR, dispatch, visible]);

    const favCategorySessions = useMemo(() => {
        const favCategoryIndex = _.findIndex(favouriteCategories, { id: favCategory });
        const sessions = _.get(favouriteCategories, [favCategoryIndex, "session_data"]);
        return sessions;
    }, [favouriteCategories, favCategory]);

    const filteredSessions = useMemo(() => {
        const sessions = comparableSessions.filter((session) => {
            if (favCategory === "all") {
                return session !== currentSessionID && favCategory;
            } else {
                return (_.get(favCategorySessions, session, false) ? true : false) && session.toString() !== currentSessionID.toString();
            }
        });
        return sessions;
    }, [comparableSessions, currentSessionID, favCategory, favCategorySessions]);

    const sessionItems = useMemo(() => {
        return _.map(filteredSessions, (sessionID) => {
            return (
                <LoadedSession
                    key={sessionID}
                    sessionID={sessionID}
                    onClick={selectSession}
                    active={selectedComparingSession === sessionID}
                />
            );
        });
    }, [selectSession, filteredSessions, selectedComparingSession]);

    const favouriteCategoryOptions = useMemo(() => {
        return _.sortBy(favouriteCategories, (cat) => !cat.is_user_category).map((cat) => {
            return (
                <Select.Option
                    value={cat.id}
                    key={cat.id}>
                    {cat.name} {cat.is_user_category ? " (Mine)" : ` (${cat.owner_name})`}
                </Select.Option>
            );
        });
    }, [favouriteCategories]);

    const content = useMemo(() => {
        if (loading) {
            return (
                <div className="Center-Content">
                    <OBCSpinner
                        size={50}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        } else {
            return (
                <div className="ComparableSessionsContainer">
                    {!Object.keys(sessionItems).length ? (
                        <div className="NoSessionsText">
                            No sessions to compare with.
                            <br />
                            <span>Add sessions to your favourites at the same location to be able to compare.</span>
                        </div>
                    ) : (
                        sessionItems
                    )}
                </div>
            );
        }
    }, [loading, sessionItems]);

    return (
        <Modal
            onOk={toggleModal}
            onCancel={toggleModal}
            visible={visible}
            destroyOnClose={true}
            footer={[<Button onClick={toggleModal}>Close</Button>]}>
            <div className="ComparableSessionsModal">
                <div className="Header">
                    <div className="HeaderItem">
                        <span>Favourite Category</span>
                        <Select
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            value={favCategory}
                            onChange={setFavCategory}>
                            <Select.Option
                                value="all"
                                key="all">
                                All
                            </Select.Option>
                            {favouriteCategoryOptions}
                        </Select>
                    </div>
                    <div className="HeaderItem">
                        <Button
                            disabled={!selectedComparingSession}
                            onClick={() => clearSecondarySession(false)}
                            type="primary">
                            Close Comparable Session
                        </Button>
                    </div>
                </div>

                {content}
            </div>
        </Modal>
    );
};

export default RailCompareModal;
