import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import _ from "lodash";
import { Line } from "rc-progress";
import PieChart from "react-minimal-pie-chart";
import { getMarkerStats } from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { Alert } from "antd";

class MarkerDashboard extends React.PureComponent {
    constructor(props) {
        super(props);
        let markerTypes = Object.keys(this.props.markers ?? []);

        this.state = {
            markerTypes: markerTypes,
            sortedMarkers: [],
            selectedMarkerType: "All",
            selectedMarkerTypeData: {},
            sortBy: "a-z",
            chartData: [],
            totals: [],
            loading: true,
            loadingErrorMsg: "",
        };
    }

    componentDidMount() {
        this.props.dispatch(
            getMarkerStats((response) => {
                this.setState({
                    loading: false,
                    loadingErrorMsg: response.errorMsg,
                });
            }),
        );
    }

    percentageUtil = (value, total, formatted) => {
        let percentage = Math.round((100 * value) / total);
        if (formatted) {
            return `${percentage}% (${value})`;
        } else {
            return percentage;
        }
    };

    getColourFromType = (type) => {
        switch (type) {
            case "ANNOTATION":
                return "#ADD46D";
            case "DETECTION":
                return "#7BBAE8";
            default:
                console.log("No type passed");
        }
    };

    setMarkerTypeFromEvent = (evt) => {
        let selectedMarkerType = evt.target.value;
        this.getMarkerDataFromType(selectedMarkerType);
    };

    getMarkerDataFromType = (markerType) => {
        let { selectedMarkerType } = this.state;
        selectedMarkerType = markerType;
        let selectedMarkerTypeData = _.pickBy(this.props.markers, (value, key) => key === selectedMarkerType);
        this.setState({
            selectedMarkerType,
            selectedMarkerTypeData,
        });
        this.generateChartData(selectedMarkerTypeData);
    };

    renderMarkerTypes = (markerTypes) => {
        return (
            <select
                className="MarkerTypeSelect"
                value={this.state.selectedMarkerType}
                onChange={this.setMarkerTypeFromEvent}>
                <option key={"all"}>All</option>
                {_.map(markerTypes, (markerType) => {
                    return <option key={markerType}>{markerType}</option>;
                })}
            </select>
        );
    };

    generateChartData = (data) => {
        let chartData = [];
        let totals = [];

        const generator = (value, key) => {
            if (key === "TRIGGER") key = "DETECTION";
            let obj = {};
            obj["title"] = key;
            obj["value"] = value;
            obj["color"] = this.getColourFromType(key);
            chartData.push(obj);
            totals.push(value);
        };

        for (let value of Object.entries(data)) {
            _.map(value[1], generator);
        }
        totals = totals.reduce((a, b) => a + b, 0);
        this.setState({
            chartData,
            totals,
        });
    };

    renderMarkerDataDoughnut = () => {
        return (
            <div>
                <div className="ChartWrapper">
                    <PieChart
                        key={this.state.selectedMarkerType}
                        data={this.state.chartData}
                        animate={true}
                        animationDuration={500}
                        animationEasing="ease-in-out"
                        radius={50}
                        rounded={true}
                        lengthAngle={360}
                        paddingAngle={15}
                        lineWidth={10}
                        cy={50}
                        cx={50}></PieChart>
                </div>
                <div className="ChartBreakdown">
                    {_.map(this.state.chartData, (data) => {
                        return (
                            <div
                                className="BreakdownItem"
                                key={data.title}>
                                <div className="BreakdownType">
                                    <div
                                        className="Indicator"
                                        style={{ background: this.getColourFromType(data.title) }}
                                    />
                                    <span className="Type">{data.title}</span>
                                </div>
                                <div className="BreakdownValues">
                                    <span className="Value">{data.value} / </span>
                                    <span className="Total">{this.state.totals}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    handleMarkerNameClick = (evt) => {
        let selectedMarkerType = evt.target.innerHTML;
        this.setState({
            selectedMarkerType,
        });
        this.getMarkerDataFromType(selectedMarkerType);
    };

    handleSortChange = (evt) => {
        this.setState({
            sortBy: evt.target.value,
        });
    };

    renderOverview = (markers) => {
        let total = [];
        let sortedArr;
        let markersArr = Object.entries(markers);
        switch (this.state.sortBy) {
            case "total asc":
                sortedArr = markersArr.sort((a, b) => (_.sum(Object.values(a[1])) < _.sum(Object.values(b[1])) ? -1 : 1));
                break;
            case "total desc":
                sortedArr = markersArr.sort((a, b) => (_.sum(Object.values(a[1])) > _.sum(Object.values(b[1])) ? -1 : 1));
                break;
            case "z-a":
                sortedArr = markersArr.sort((a, b) => (a[0] > b[0] ? -1 : 1));
                break;
            case "a-z":
            default:
                sortedArr = markersArr.sort((a, b) => (a[0] < b[0] ? -1 : 1));
                break;
        }

        return _.map(sortedArr, (markerType) => {
            let obj = { Annotations: 0, Detections: 0 };
            let markerName = markerType[0];
            let markerData = markerType[1];
            for (let [key, value] of Object.entries(markerData)) {
                switch (key) {
                    case "ANNOTATION":
                        obj["Annotations"] = value;
                        break;
                    case "TRIGGER":
                        obj["Detections"] = value;
                        break;
                    case "INCIDENT":
                        break;
                    default:
                        console.log("No key passed");
                }
            }
            total = Object.values(markerData);
            total = total.reduce((a, b) => a + b, 0);
            return (
                <div
                    className="MarkerBreakdownItem"
                    key={markerName}>
                    <div className="ItemHeaderContainer">
                        <span onClick={this.handleMarkerNameClick}>{markerName}</span>
                        <span>Total {total}</span>
                    </div>
                    <div className="GaugeContainer">
                        <span>Annotations</span>
                        <div className="GaugeItem">
                            <Line
                                percent={this.percentageUtil(obj["Annotations"], total, false)}
                                style={{ width: 285 }}
                                strokeWidth="3"
                                trailWidth="3"
                                trailColor="rgba(168, 168, 168, 0.6)"
                                strokeColor="#ADD46D"
                            />
                            <span>{this.percentageUtil(obj["Annotations"], total, true)}</span>
                        </div>
                        <span>Detections</span>
                        <div className="GaugeItem">
                            <Line
                                percent={this.percentageUtil(obj["Detections"], total, false)}
                                style={{ width: 285 }}
                                strokeWidth="3"
                                trailWidth="3"
                                trailColor="rgba(168, 168, 168, 0.6)"
                                strokeColor="#7BBAE8"
                            />
                            <span>{this.percentageUtil(obj["Detections"], total, true)}</span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    render() {
        return (
            <div className="MarkerDashboardContainer">
                <div className="AdminPageMainHeader">
                    <span className="Header">Detection Dashboard (past month)</span>
                </div>
                {this.state.loading ? (
                    <div className="LoadiingSpinner">
                        <OBCSpinner
                            size={70}
                            speed={3}
                            color={"#e8dfff"}
                        />
                    </div>
                ) : this.state.loadingErrorMsg ? (
                    <Alert
                        message="Error"
                        description={this.state.loadingErrorMsg}
                        type="warning"
                        showIcon
                        closable
                    />
                ) : (
                    !_.isEmpty(this.props.markers) && (
                        <>
                            <div className="SelectContainer">
                                {this.renderMarkerTypes(this.state.markerTypes)}
                                {this.state.selectedMarkerType === "All" && (
                                    <select
                                        className="SortBySelect"
                                        value={this.state.sortBy}
                                        onChange={this.handleSortChange}>
                                        <option value={"a-z"}>A-Z</option>
                                        <option value={"z-a"}>Z-A</option>
                                        <option value={"total asc"}>Total Ascending</option>
                                        <option value={"total desc"}>Total Descending</option>
                                    </select>
                                )}
                            </div>
                            <div
                                className="ChartContainer"
                                style={{ display: this.state.selectedMarkerType === "All" ? "none" : "flex" }}>
                                <div style={{ marginBottom: 15 }}>
                                    <span className="SubHeader">{this.state.selectedMarkerType}</span>
                                </div>
                                <div>{this.state.selectedMarkerType !== "All" && this.renderMarkerDataDoughnut()}</div>
                            </div>
                            <div className="OverviewContainer">{this.state.selectedMarkerType === "All" && this.renderOverview(this.props.markers)}</div>
                            {_.isEmpty(this.props.markers) && (
                                <div>
                                    <span className="SubHeader">There are no statistics to show.</span>
                                </div>
                            )}
                        </>
                    )
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    markers: state.admin.markerStats.markers,
});

export default connect(mapStateToProps)(MarkerDashboard);
