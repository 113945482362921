import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { MarkerIcon, createCustomIcon } from "./Icons";
import _ from "lodash";
import { selectMarker } from "redux/actions/index";
import { LazyMapTooltip } from "../util/LazyTooltips";
import MlAssetMarker from "./MlAssetMarker";

const customMarkerDict = {};

const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

class AssetMarkers extends React.PureComponent {
    iconClick = (marker) => {
        console.log("debug iconClick", marker);

        this.props.dispatch(selectMarker(marker));
    };

    renderMarkerTooltip = (asset) => {
        const fields = _.map(JSON.parse(asset.data), (value, key) => {
            if (value && value !== "null" && value !== "Unknown" && typeof key === "string" && typeof value === "string") {
                if (!["_", "~"].includes(key.charAt(0))) {
                    return (
                        <tr key={key}>
                            <td className={"Key"}>{key}:</td>
                            <td className={"Value"}>{value}</td>
                        </tr>
                    );
                }
            }
        });
        const extraDataPanel = (
            <table className={"MarkerTooltipAdditionalInfo"}>
                <tbody>{fields}</tbody>
            </table>
        );

        return <div className={"MarkerTooltipOuter"}>{extraDataPanel}</div>;
    };

    renderAsset = (asset) => {
        if (this.props.assetSearch.mlAsset) {
            return (
                <MlAssetMarker
                    asset={asset}
                    displayingAsNearby={true}
                />
            );
        }
        let markerIconToUse = MarkerIcon;

        if (asset) {
            const selected = asset === this.props.selectedMarker;

            if (asset.icon_name) {
                let cacheIconName = asset.icon_name + asset.icon_colour + (selected ? "_selected" : "");
                if (!(cacheIconName in customMarkerDict)) {
                    customMarkerDict[cacheIconName] = createCustomIcon(asset.icon_name, asset.icon_colour, selected);
                }

                markerIconToUse = customMarkerDict[cacheIconName];
            }
        }
        return (
            <Marker
                onClick={() => this.iconClick(asset)}
                key={"Marker" + asset.asset_id}
                position={[asset.lat, asset.lon]}
                icon={markerIconToUse}>
                <LazyMapTooltip
                    className="Incident-Tooltip"
                    permanent={false}
                    interactive={true}
                    direction={"top"}
                    offset={[0, -30]}>
                    {this.renderMarkerTooltip(asset)}
                </LazyMapTooltip>
            </Marker>
        );
    };

    render() {
        let assets = this.props.assets;

        return <LayerGroup>{assets.map(this.renderAsset)}</LayerGroup>;
    }
}

const mapStateToProps = (state) => {
    return {
        assets: state.assets.assetsFound,
        assetTypes: state.assets.assetTypes,
        isStills: state.playlist.position.isStills,
        selectedMarker: state.markers.selectedMarker,
        assetSearch: state.assets.assetSearch,
    };
};

export default connect(mapStateToProps)(AssetMarkers);
