import { Button, Drawer, Input, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RoutePatrolSvg from "./RoutePatrolSvg";
import { Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPatrolPlans } from "redux/actions";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import moment from "moment";
import _ from "lodash";
import PatrolPlanCreator from "./PatrolPlanCreator";
import Tippy from "@tippyjs/react";

const GUTTER_SIZE = 8;
const SESSION_ITEM_HEIGHT = 116;
const RECENT_COVERAGE_PERIOD_IN_DAYS = 60;

const patrolPlansSelector = (state) => state.schemaInterface.patrolPlans;
const isSuperUserSelector = (state) => state.userDetails.userConfig.super_admin;

const recentCoverageDays = Array.from({ length: RECENT_COVERAGE_PERIOD_IN_DAYS }, (_, index) =>
    moment()
        .subtract(RECENT_COVERAGE_PERIOD_IN_DAYS - index, "days")
        .format("YYYY-MM-DD"),
);

const PatrolPlanItem = ({ plan, style }) => {
    const history = useHistory();

    const navigate = () => {
        history.push(`/patrol-planner/${plan.id}`, {
            planID: plan.id,
        });
    };

    const recentRuns = useMemo(() => {
        return recentCoverageDays.map((date, index) => {
            const formattedDate = moment(date).format("D MMM YYYY");

            const coverage = _.get(plan, ["recent_coverage", date], null);

            if (coverage) {
                let dotColourClass = "yellow";

                if (coverage >= plan.coverage_threshold) {
                    dotColourClass = "green";
                }

                return (
                    <Tippy key={index} content={`${formattedDate}: ${coverage}% covered`}>
                        <span className={`dot ${dotColourClass}`}></span>
                    </Tippy>
                );
            }

            return (
                <Tippy key={index} content={`${formattedDate}: Not covered`}>
                    <span className="dot gray"></span>
                </Tippy>
            );
        });
    }, [plan]);

    return (
        <div
            style={style}
            className="PatrolPlanContainer">
            <div
                className="PatrolPlan"
                onClick={navigate}>
                <div className="PatrolPlan__Svg">
                    <RoutePatrolSvg
                        planData={plan}
                        preview
                    />
                </div>
                <div
                    className="PatrolPlan__Info"
                    style={{ width: "70%" }}>
                    <Tippy
                        content={`${plan.name} ${plan.diagram_id ? `(${plan.diagram_id})` : ""}`}
                        placement="top-start">
                        <h3 className="Title">
                            {plan.name} {plan.diagram_id ? `(${plan.diagram_id})` : null}
                        </h3>
                    </Tippy>
                    <h3
                        className="data-collection"
                        style={{ marginBottom: 0 }}>
                        {plan.last_covered_percentage || 0}% covered {plan.last_covered_ts ? moment.unix(plan.last_covered_ts / 1000).fromNow() : ""}{" "}
                        {plan.last_covered_device ? `by ${plan.last_covered_device}` : ""}
                    </h3>
                    <Progress
                        percent={plan.last_covered_percentage || 0}
                        showInfo={false}
                        trailColor={"#26293a"}
                    />
                </div>
            </div>
            <div className="PatrolPlanFooter">{recentRuns}</div>
        </div>
    );
};

const PatrolPlanDrawer = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const isSuperUser = useSelector(isSuperUserSelector);
    const patrolPlans = useSelector(patrolPlansSelector);

    const [sortValue, setSortValue] = useState("alphabetical");
    const [searchValue, setSearchValue] = useState();
    const [planCreatorOpen, setPlanCreatorVisible] = useState(false);

    useEffect(() => {
        if (!patrolPlans.length) {
            dispatch(getPatrolPlans());
        }
    }, [dispatch, patrolPlans.length]);

    const Row = ({ index, style }) => {
        const patrol = filteredPlans[index];

        const styles = {
            ...style,
            left: style.left,
            top: style.top + GUTTER_SIZE,
            height: style.height - GUTTER_SIZE,
        };

        return (
            <PatrolPlanItem
                style={styles}
                plan={patrol}
                index={index}
            />
        );
    };

    const filteredPlans = useMemo(() => {
        const filteredPlans = patrolPlans.filter((plan) => {
            if (searchValue) {
                let plan_name = _.get(plan, "name", "");
                let diagram_id = _.get(plan, "diagram_id", "");
                plan_name = plan_name ? plan_name : "";
                diagram_id = diagram_id ? plan_name : "";
                return plan_name.toLowerCase().includes(searchValue.toLowerCase()) || diagram_id.toLowerCase().includes(searchValue.toLowerCase());
            }
            return true;
        });

        let sortedPlans = filteredPlans;
        if (sortValue === "time") {
            sortedPlans = _.orderBy(filteredPlans, "last_covered_ts", "desc");
        } else if (sortValue === "coverage") {
            sortedPlans = _.orderBy(filteredPlans, "last_covered_percentage", "desc");
        } else if (sortValue == "alphabetical") {
            sortedPlans = _.orderBy(filteredPlans, "name", "asc");
        }
        return sortedPlans;
    }, [patrolPlans, searchValue, sortValue]);

    return (
        <Drawer
            placement={"left"}
            closable={false}
            onClose={() => onClose()}
            className="PatrolPlanDrawer"
            width="50%"
            visible={open}>
            <PatrolPlanCreator
                visible={planCreatorOpen}
                closeModal={() => setPlanCreatorVisible(false)}
            />

            <div className="PlansDrawerContainer">
                <div className="Header">
                    <Input
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        className="Input"
                    />

                    <Select
                        value={sortValue}
                        onChange={setSortValue}
                        style={{ width: 200 }}>
                        <Select.Option value="alphabetical">Sort alphabetically</Select.Option>
                        <Select.Option value="time">Sort by last covered</Select.Option>
                        <Select.Option value="coverage">Sort by coverage percent</Select.Option>
                    </Select>

                    {isSuperUser && (
                        <Button
                            onClick={() => setPlanCreatorVisible(true)}
                            type="primary"
                            className="CreateButton">
                            Upload New SVG
                        </Button>
                    )}
                </div>
                <div className="PlansContainer">
                    {filteredPlans.length ? (
                        <AutoSizer className="sessionAutosizer">
                            {({ height, width }) => (
                                <FixedSizeList
                                    height={height + GUTTER_SIZE * 2}
                                    width={width}
                                    itemSize={SESSION_ITEM_HEIGHT}
                                    itemCount={filteredPlans.length}>
                                    {Row}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    ) : (
                        <span className="NoPatrolsText">No patrol plans matching filter</span>
                    )}
                </div>
            </div>
        </Drawer>
    );
};

export default PatrolPlanDrawer;
