import { Button, notification } from "antd";
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { getSessionData, goToBounds, introTourStageAudit, routeSelected, updateUserConfig } from "../redux/actions/index";
import { Steps } from "intro.js-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const sessionsSelector = (state) => state.sessions;
const routeSelectedSelector = (state) => state.playlist.data;
const introTourViewedSelector = (state) => state.userDetails.userConfig.intro_tour_viewed;
const playlistPositionSelector = (state) => state.playlist.position;
const sessionListSelector = (state) => state.sessionList;
const shareLinkSelector = (state) => state.shareLink;

const IntroTour = ({ manualTrigger, setManualTrigger }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [tourConfirmed, setTourConfirmed] = useState(false);
    const [introNotificationOpen, setIntroNotificationOpen] = useState(false);
    const [introRejected, setIntroRejected] = useState(false);
    const [notificationKey, setNotificationKey] = useState();
    const [noViableSession, setNoViableSession] = useState(false);
    const [settingUpIntro, setSettingUpIntro] = useState(false);

    const sessions = useSelector(sessionsSelector);
    const route = useSelector(routeSelectedSelector);
    const introTourViewed = useSelector(introTourViewedSelector);
    const playlistPosition = useSelector(playlistPositionSelector);
    const sessionList = useSelector(sessionListSelector);
    const shareLink = useSelector(shareLinkSelector);

    const stepsRef = useRef();

    const setTourConfig = useCallback(
        (viewed) => {
            let fieldsToUpdate = {};
            fieldsToUpdate.introTourViewedSetting = viewed;

            if (!shareLink && !introTourViewed) {
                dispatch(updateUserConfig(fieldsToUpdate));
            }
        },
        [dispatch, shareLink, introTourViewed],
    );

    const openWelcomeNotification = useCallback(() => {
        const key = `aivr-tour-notification`;
        setNotificationKey(key);

        const btn = (
            <>
                <Button
                    type="secondary"
                    style={{ marginRight: "10px" }}
                    size="middle"
                    onClick={() => {
                        notification.close(key);
                        setIntroNotificationOpen(false);
                        setIntroRejected(true);
                        setTourConfig(true);
                    }}>
                    No thanks
                </Button>
                <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                        notification.close(key);
                        setIntroNotificationOpen(false);
                        setSettingUpIntro(true);
                    }}>
                    Yes, show me
                </Button>
            </>
        );

        notification.open({
            message: "Welcome to AIVR",
            description: "Would you like to take the tour?",
            btn,
            key,
            duration: 0,
            onClose: () => {
                setTourConfig(true);
                setIntroRejected(true);
                setIntroNotificationOpen(false);
            },
        });

        setIntroNotificationOpen(true);
    }, [setTourConfig]);

    useEffect(() => {
        return history.listen((location) => {
            if (location.pathname !== "/") {
                notification.close(notificationKey);
                setTourConfig(true);
            }
        });
    }, [history, notificationKey, setTourConfig]);

    const onIntroExit = () => {
        setStepsEnabled(false);
        setManualTrigger(false);
        setTourConfirmed(false);
        if (stepsRef && stepsRef.current) {
            dispatch(introTourStageAudit(stepsRef.current.introJs._currentStep));
        }
    };

    const isSessionProcessing = (session) => {
        let sessionUploadTime = moment.unix(_.get(session, "last_vid_up", 0));
        let differenceTime = moment().diff(sessionUploadTime, "minutes");

        return differenceTime <= 5 || _.get(session, "last_geom_ts", 0) === 0;
    };

    const selectSession = useCallback(
        (notInspectionOnlyLength) => {
            let viableSession = null;

            for (let i in sessionList) {
                let sessionID = sessionList[i];
                let session = sessions[sessionID];
                let cameraPosition = _.get(session, "camera_position", "");

                if (!isSessionProcessing(session)) {
                    if (notInspectionOnlyLength > 0) {
                        if (cameraPosition !== "inspection-only") {
                            setNoViableSession(false);
                            viableSession = sessionID;
                            break;
                        }
                    } else {
                        viableSession = sessionID;
                        setNoViableSession(true);
                        break;
                    }
                }
            }
            return viableSession;
        },
        [sessionList, sessions],
    );

    const setupIntro = useCallback(() => {
        if (sessions) {
            let viableSession = null;

            let notInspectionOnly = _.keysIn(sessions).filter((session) => sessions[session]["camera_position"] !== "inspection-only");
            if (_.isEmpty(route)) {
                viableSession = selectSession(notInspectionOnly.length);
            } else {
                let session = sessions[route.routeID];
                if (notInspectionOnly.length > 0) {
                    if (_.get(session, "camera_position", "") === "inspection-only") {
                        viableSession = selectSession(notInspectionOnly.length);
                    } else {
                        setNoViableSession(false);
                    }
                } else {
                    setNoViableSession(true);
                }
            }
            if (viableSession) {
                dispatch(getSessionData(viableSession)).then((sessionData) => {
                    dispatch(goToBounds(sessionData.bounds));
                    dispatch(routeSelected(sessionData.id, 0));

                    if (!manualTrigger) {
                        setTourConfirmed(true);
                    }
                });
            } else {
                if (!manualTrigger) {
                    setTourConfirmed(true);
                }
            }
        }
    }, [sessions, route, manualTrigger, dispatch, selectSession]);

    useEffect(() => {
        if (settingUpIntro) {
            setupIntro();
        }
    }, [settingUpIntro, setupIntro]);

    const introSteps = [
        {
            element: "#intro-tour-workspace-selector",
            intro: "If your user is assigned to more than one workspace, you can switch by clicking the icons in this navigation bar.",
            position: "right",
        },
        {
            element: "#intro-tour-map-window",
            intro: "The map shows all the available footage in your active workspace. Click on any line on the map to see video from that location.",
            position: "left",
        },
        {
            element: "#intro-tour-map-search",
            intro: "You can search by geographic coordinates, ELR, station name and even asset I.D. Use the icons to highlight assets in your current map view.",
            position: "left",
        },
        {
            element: ".leaflet-control-layers-toggle",
            intro: "Click here to change the map layer for a different perspective.",
            position: "right",
        },
        {
            element: "#intro-tour-info-window",
            intro: "Detailed positional information and data is shown here. You can also view historical sessions on your current route in the History tab.",
            position: "left",
        },
        {
            element: "#intro-tour-session-list",
            intro: "Your active session is highlighted in green in this list.",
            position: "right",
        },
        {
            element: "#intro-tour-session-filter-fav",
            intro: "Filter the session list by tags such as video type or time of day, and even favourite sessions for fast access.",
            position: "right",
        },
    ];

    if (!noViableSession) {
        introSteps.splice(4, 0, {
            element: "#intro-tour-video-window",
            intro: "Imagery from your current location is shown here, and will update in sync with your position.",
            position: "right",
        });
        introSteps.push(
            {
                element: "#intro-tour-video-annotate",
                intro: "Add annotations to the current image, for sharing with colleagues or as a note to self.",
                position: "bottom",
            },
            {
                element: "#intro-tour-video-measure",
                intro: "Measure the size and position of objects in the scene.",
                position: "bottom",
            },
            {
                element: "#intro-tour-video-fullscreen",
                intro: "Click this icon to expand the video into full-screen mode.",
                position: "bottom",
            },
            {
                element: "#intro-tour-video-toolbar",
                intro: "Use this toolbar to take snaphots, download video or copy share links.",
                position: "top",
            },
            {
                element: "#intro-tour-video-type",
                intro: "Use this dropdown to choose your image type - pick from HD video, still images and even heads-up data overlay.",
                position: "top",
            },
            {
                element: "#intro-tour-help",
                intro: "If you have any questions, please get in touch!",
                position: "right",
            },
        );
    } else {
        introSteps.splice(4, 0, {
            element: "#intro-tour-rail-inspection",
            intro: "Rail inspection imagery from your current location is shown here, and will update in sync with your position.",
            position: "right",
        });
        introSteps.push(
            {
                element: "#intro-tour-image-fullscreen",
                intro: "Click this icon to expand the images into full-screen mode.",
                position: "bottom",
            },
            {
                element: "#intro-tour-help",
                intro: "If you have any questions, please get in touch!",
                position: "right",
            },
        );
    }

    useEffect(() => {
        if (
            sessions &&
            Object.keys(sessions).length !== 0 &&
            introTourViewed === false &&
            !introNotificationOpen &&
            !stepsEnabled &&
            !tourConfirmed &&
            !introRejected &&
            !settingUpIntro
        ) {
            setTimeout(() => {
                openWelcomeNotification();
            }, [2500]);
        }
    }, [sessions, introTourViewed, introNotificationOpen, stepsEnabled, tourConfirmed, introRejected, settingUpIntro, openWelcomeNotification]);

    const playlistCoordinatesDefined = useMemo(() => {
        return !!playlistPosition.coords;
    }, [playlistPosition]);

    const routeLoaded = useMemo(() => {
        return !_.isEmpty(route) && route.loaded;
    }, [route]);

    useEffect(() => {
        if (
            (routeLoaded && (playlistCoordinatesDefined || noViableSession) && tourConfirmed) ||
            (routeLoaded && (playlistCoordinatesDefined || noViableSession) && manualTrigger)
        ) {
            setStepsEnabled(true);
            setTourConfig(true);
            setSettingUpIntro(false);
        }
    }, [routeLoaded, tourConfirmed, manualTrigger, playlistCoordinatesDefined, noViableSession, setTourConfig]);

    useEffect(() => {
        if (manualTrigger) {
            setupIntro();
        }
    }, [manualTrigger]);

    return (
        <Steps
            ref={stepsRef}
            enabled={stepsEnabled}
            steps={introSteps}
            initialStep={0}
            onExit={onIntroExit}
            options={{
                doneLabel: "Finish",
            }}
        />
    );
};

export default IntroTour;
