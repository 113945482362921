import React, { useMemo } from "react";
import { Color } from "three";
import _ from "lodash";

export default function Meshes({ geometries, materials, highlighted, selected }) {
    const highlightedMaterials = useMemo(
        () =>
            materials.map((m) => {
                const m2 = m.clone();
                m2.emissive = new Color(0x008000);
                m2.emissiveIntensity = 0.3;
                return m2;
            }),
        [materials],
    );

    const selectedMaterials = useMemo(
        () =>
            materials.map((m) => {
                const m2 = m.clone();
                m2.emissive = new Color(0x000080);
                m2.emissiveIntensity = 0.3;
                return m2;
            }),
        [materials],
    );

    const meshes = useMemo(() => {
        let materialArray;
        if (selected) {
            materialArray = selectedMaterials;
        } else if (highlighted) {
            materialArray = highlightedMaterials;
        } else {
            materialArray = materials;
        }

        return _.zip(geometries, materialArray).map(([geometry, material], idx) => (
            <mesh
                key={`${geometry.uuid}.${material.uuid}`}
                castShadow
                receiveShadow
                material={material}
                geometry={geometry}
            />
        ));
    }, [geometries, materials, highlightedMaterials, selectedMaterials, highlighted, selected]);

    return <group>{meshes}</group>;
}
