import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import RailInspectionExtraDisplay from "components/display/image/RailInspectionExtraDisplay";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RailInspectionLoader } from "datastores/railInspectionStore";
import { getSessionData, primaryRailImageConfig, railInspectionExited, setRailDetailWidgetSelectedSources, updatePrimaryRailImages } from "redux/actions";
import { convertToTimezone } from "components/util/TimezoneUtils";
import { calculateRouteCoordinatesForLocation } from "components/util/Geometry";
import ELRMileAndChain from "components/util/ELRMileAndChain";
import { ReactComponent as LeftOuterButton } from "components/display/image/RailButtons/LeftOuterButton.svg";
import { ReactComponent as LeftInnerButton } from "components/display/image/RailButtons/LeftInnerButton.svg";
import { ReactComponent as RightInnerButton } from "components/display/image/RailButtons/RightInnerButton.svg";
import { ReactComponent as RightOuterButton } from "components/display/image/RailButtons/RightOuterButton.svg";

const dashboardDataSelector = (state) => _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
const selectedRailImageIndexSelector = (state) => _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "position", "railInspectionIndex"], 0);
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const selectedSourcesSelector = (state) => state.railInspection.railDetailWidgetSelectedSources;
const userDetailsSelector = (state) => state.userDetails;
const userConfigSelector = (state) => state.userDetails.userConfig;
const dashboardsSelector = (state) => state.dashboards;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const viewsSelector = (state) => state.views;

export const windowFeaturesString = "width=1012,height=473,left=200,top=200";

const BUTTON_ICON_MAP = {
    left_outer: <LeftOuterButton className="Icon" />,
    left_inner: <LeftInnerButton className="Icon" />,
    right_inner: <RightInnerButton className="Icon" />,
    right_outer: <RightOuterButton className="Icon" />,
};

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const RailDetailWidget = () => {
    const query = useQuery();
    const dispatch = useDispatch();

    const railImageLoader = useRef();
    const [hasNoDetailData, setHasNoDetailData] = useState(false);
    const [extraLayersSyncOffset, setExtraLayersSyncOffset] = useState(0);
    const [dataLoadError, setDataLoadError] = useState(false);
    const [sessionData, setSessionData] = useState(null);

    const dashboardData = useSelector(dashboardDataSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const userConfig = useSelector(userConfigSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const dashboards = useSelector(dashboardsSelector);
    const userDetails = useSelector(userDetailsSelector);
    const selectedRailImageIndex = useSelector(selectedRailImageIndexSelector);
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === userDetails.dashboardID);
    const views = useSelector(viewsSelector);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const selectedSources = useSelector(selectedSourcesSelector);

    const railImageConfigObject = useSelector(railImageConfigObjectSelector);

    const railOptions = useMemo(() => {
        if (!railImageConfigObject || !railImageConfigObject.inspection_images) {
            return [];
        }

        return railImageConfigObject.inspection_images.filter((obj) => obj.showInModal);
    }, [railImageConfigObject]);

    useEffect(() => {
        if (selectedSources.length === 0) {
            const selectedRail = query.get("selected");

            if (selectedRail) {
                dispatch(setRailDetailWidgetSelectedSources([selectedRail]));
            } else if (railOptions.length) {
                dispatch(setRailDetailWidgetSelectedSources([railOptions[0].source]));
            }
        }
    }, [query, railOptions]);

    useEffect(() => {
        const railInspectionLoaderNotify = () => {
            dispatch(primaryRailImageConfig(railImageLoader.current.config));
            dispatch(
                updatePrimaryRailImages(
                    railImageLoader.current.data,
                    railImageLoader.current.loaded,
                    railImageLoader.current.percentageComplete,
                    railImageLoader.current.sessionID,
                ),
            );
            setDataLoadError(railImageLoader.current.failed);

            const hasDetailData =
                !railImageLoader.current.config ||
                _.isEmpty(railImageLoader.current.config.inspection_images) ||
                !railImageLoader.current.config.inspection_images.filter((config) => config.showInModal).length;

            setHasNoDetailData(hasDetailData);
        };

        railImageLoader.current = new RailInspectionLoader(railInspectionLoaderNotify);

        return () => {
            railImageLoader.current.abort();
        };
    }, [railImageLoader]);

    useEffect(() => {
        if (dashboardData.routeID) {
            dispatch(getSessionData(dashboardData.routeID)).then((sessionData) => {
                setSessionData(sessionData);
            });
            dispatch(railImageLoader.current.load(dashboardData.routeID));
        } else {
            dispatch(railInspectionExited());
        }
    }, [dashboardData.routeID]);

    const formatTime = useCallback(() => {
        if (!dashboardData.position.timestamp) {
            return "";
        }

        const dpDate = new Date(dashboardData.position.timestamp);
        const tzDate = convertToTimezone(dpDate, userConfig.convert_to_utc);
        return tzDate.split(" ").map((part, i) => <span key={i}>{part}</span>);
    }, [userConfig, dashboardData.position.timestamp]);

    const formattedTime = useMemo(() => {
        return formatTime();
    }, [formatTime]);

    const distanceUnits = useMemo(() => {
        if (userConfig.elr_units === "elr_mile_chain") {
            return "ELR Mile & Chain";
        } else if (userConfig.elr_units === "elr_mile_yards") {
            return "ELR Mile & Yards";
        } else if (userConfig.elr_units === "elr_meterage") {
            return "ELR & Meterage";
        }
    }, [userConfig.elr_units]);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject; //Find first device in data - will need changing to support multiple devices
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const railImage = useMemo(() => {
        if (railImages && railImages.length) {
            return railImages[selectedRailImageIndex];
        }
    }, [railImages, selectedRailImageIndex]);

    const calculatedPositionString = useMemo(() => {
        let timestampToUse = dashboardData.position.timestamp;

        if (timestampToUse && routeLocationData) {
            const calculatedELR = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);
            if (calculatedELR) {
                return calculatedELR.to_string(userConfig.elr_units, datumOffsets);
            }
        }
    }, [dashboardData.position.timestamp, routeLocationData, userConfig.elr_units, datumOffsets, routeSystemID]);

    const mwvPositionString = useMemo(() => {
        if (railImages && railImage) {
            let mwv = railImage["mwv"];
            if (mwv && mwv.elr && mwv.mile && mwv.trid && mwv.yard) {
                mwv = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: mwv.elr,
                        MILE: mwv.mile,
                        TRACK: mwv.trid,
                        YARDS: mwv.yard,
                    },
                    datumOffsets,
                    true,
                );
                return mwv.to_string(userConfig.elr_units, datumOffsets, true);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }, [railImage, railImages, userConfig.elr_units, datumOffsets]);

    const onToggleButtonClick = useCallback(
        (source) => {
            const newSources = selectedSources.includes(source) ? selectedSources.filter((item) => item !== source) : [...selectedSources, source];

            dispatch(setRailDetailWidgetSelectedSources(newSources));
        },
        [selectedSources],
    );

    const toggleButtons = () => {
        let iconMap = BUTTON_ICON_MAP;

        return railOptions.map((option, idx) => (
            <div
                className={"ToggleOption" + (selectedSources.includes(option.source) ? " Selected" : "")}
                key={option.name}
                onClick={() => onToggleButtonClick(option.source)}>
                <span className="ToggleShortcut">{idx + 1}</span>
                <div>{iconMap[option.source]}</div>
            </div>
        ));
    };

    const onKeyDown = useCallback(
        (e) => {
            const key = e.key;
            const numberPressed = parseInt(key);

            if (numberPressed <= railOptions.length && numberPressed > 0) {
                onToggleButtonClick(railOptions[numberPressed - 1].source);
            }
        },
        [onToggleButtonClick, railOptions],
    );

    useEffect(() => {
        if (railOptions && railOptions.length) {
            document.addEventListener("keydown", onKeyDown);

            return () => {
                document.removeEventListener("keydown", onKeyDown);
            };
        }
    }, [onKeyDown, railOptions]);

    if (hasNoDetailData) {
        return (
            <div className="widget__Info center">
                <p>This session has no detailed rail images</p>
            </div>
        );
    }

    if (dataLoadError) {
        return (
            <div className="widget__Info center">
                <p>There was an error loading detailed rail images</p>
            </div>
        );
    }

    return (
        <div className="RailDetailWidgetContainer">
            <header className="RailDetailWidgetHeader">
                {sessionData && sessionData.route_name && sessionData.route_name.length && (
                    <div className="RailDetailWidgetHeader__Item">
                        <span className="RailDetailWidgetHeader__Item_Label">{sessionData.route_name}</span>
                        <span className="RailDetailWidgetHeader__Item_Value">#{sessionData.id}</span>
                    </div>
                )}
                {formattedTime && (
                    <div className="RailDetailWidgetHeader__Item">
                        <div className="RailDetailWidgetHeader__Item_Time">{formattedTime}</div>
                    </div>
                )}
                {!mwvPositionString && calculatedPositionString && calculatedPositionString.length && (
                    <div className="RailDetailWidgetHeader__Item">
                        <span className="RailDetailWidgetHeader__Item_Label">{distanceUnits}:</span>
                        <span className="RailDetailWidgetHeader__Item_Value">{calculatedPositionString}</span>
                    </div>
                )}
                {mwvPositionString && (
                    <div className="RailDetailWidgetHeader__Item">
                        <span className="RailDetailWidgetHeader__Item_Label">{distanceUnits}:</span>
                        <span className="RailDetailWidgetHeader__Item_Value">{mwvPositionString}</span>
                    </div>
                )}
            </header>
            <div className="RailToggleContainer">{toggleButtons()}</div>
            <div className="RailDetailWidgetList">
                {selectedSources &&
                    railOptions
                        .filter((option) => {
                            return selectedSources.includes(option.source);
                        })
                        .map((option) => {
                            const selectedIndex = _.findIndex(railOptions, { name: option.name });

                            return (
                                <div
                                    key={option.source}
                                    className="RailDetailWidget">
                                    <RailInspectionExtraDisplay
                                        syncOffset={extraLayersSyncOffset}
                                        setSyncOffset={setExtraLayersSyncOffset}
                                        isWidget={true}
                                        widgetRailOption={{ ...option, sourceIndex: selectedIndex }}
                                    />
                                </div>
                            );
                        })}
            </div>
        </div>
    );
};

export default RailDetailWidget;
