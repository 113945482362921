import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";
import memoize from "memoize-one";
import { handleLocationResults, latLonToMeters } from "../../components/util/Geometry";
import { d2 } from "../../components/util/PlaylistUtils";

import { goToBounds, routeSelected, getSessionData, setMapPointSelected, SESSION_FILTERS_DEFAULTS } from "./sessionActions";
import { customAudit } from "./auditActions";
import { goToMapPosition } from "./mediaActions";
import { notification } from "antd";
import { MEMOIZED_DOMAIN_URL } from "../../components/util/HostUtils";

export const RECEIVE_SNAPPING_VALIDATION = "RECEIVE_SNAPPING_VALIDATION";
export const MAP_GEOMETRY = "MAP_GEOMETRY";
export const BEHAVIOUR_ZONES = "BEHAVIOUR_ZONES";
export const SET_DATA_POOLS = "SET_DATA_POOLS";
export const UPDATE_MAP_BOUNDS = "UPDATE_MAP_BOUNDS";
export const UPDATE_MAP_ZOOM = "UPDATE_MAP_ZOOM";
export const UPDATE_ZOOM = "UPDATE_ZOOM";
export const RECEIVE_ROUTE_COORD_DATA = "RECEIVE_ROUTE_COORD_DATA";
export const ROUTE_COORDINATE_SYSTEMS = "ROUTE_COORDINATE_SYSTEMS";
export const SET_MAP_ZOOM_LEVEL = "SET_MAP_ZOOM_LEVEL";
export const SET_CLOSEST_ASSETS = "SET_CLOSEST_ASSETS";
export const RECEIVE_LOCATION_SEARCH_RESULT = "RECEIVE_LOCATION_SEARCH_RESULT";
export const DISPLAY_LOCATION_RESULTS_ON_MAP = "DISPLAY_LOCATION_RESULTS_ON_MAP";
export const CLEAR_LOCATION_RESULTS = "CLEAR_LOCATION_RESULTS";
export const LOCATION_SEARCH_LOADING = "LOCATION_SEARCH_LOADING";
export const ADD_BEHAVIOUR_ZONES_DETECTION = "ADD_BEHAVIOUR_ZONES_DETECTION";
export const SET_DETECTIONS_LOADING = "SET_DETECTIONS_LOADING";
export const SET_DISPLAY_NEARBY_ASSETS = "SET_DISPLAY_NEARBY_ASSETS";
export const SET_CURRENT_NEARBY_ASSET = "SET_CURRENT_NEARBY_ASSET";
export const RECEIVE_ROUTE_AGE = "RECEIVE_ROUTE_AGE";
export const SET_MAP_ENVIRONMENT_DATA = "SET_MAP_ENVIRONMENT_DATA";
export const SET_MAP_ENVIRONMENT_DATA_TYPES = "SET_MAP_ENVIRONMENT_DATA_TYPES";
export const SET_MAP_ENVIRONMENT_SUB_DATA_TYPES = "SET_MAP_ENVIRONMENT_SUB_DATA_TYPES";
export const SET_MAP_ENVIRONMENT_SELECTED_SEGMENT = "SET_MAP_ENVIRONMENT_SELECTED_SEGMENT";
export const SET_MAP_ENVIRONMENT_FILTERS = "SET_MAP_ENVIRONMENT_FILTERS";

export function behaviourZones(devices, zones) {
    return {
        type: BEHAVIOUR_ZONES,
        devices,
        zones,
    };
}

export function setDataPools(data_pools) {
    return {
        type: SET_DATA_POOLS,
        data_pools,
    };
}

export function updateMapBounds({ north, east, south, west }) {
    return {
        type: UPDATE_MAP_BOUNDS,
        north,
        east,
        south,
        west,
    };
}

export const setMobileMapZoomLevel = (zoom) => {
    return {
        type: SET_MAP_ZOOM_LEVEL,
        zoom,
    };
};

export function updateMapZoom(zoom) {
    return {
        type: UPDATE_MAP_ZOOM,
        zoom,
    };
}

export function updateZoom(zoom) {
    return {
        type: UPDATE_ZOOM,
        zoom,
    };
}

const receiveSnappingValidations = (validations) => {
    return {
        type: RECEIVE_SNAPPING_VALIDATION,
        validations,
    };
};

export function receiveRouteCoordData(data) {
    return {
        type: RECEIVE_ROUTE_COORD_DATA,
        data,
    };
}

function routeCoordinateSystems(systems) {
    return {
        type: ROUTE_COORDINATE_SYSTEMS,
        systems,
    };
}

export function updateBounds(bounds) {
    return (dispatch, getState) => {
        let mapBounds = {
            north: bounds.getNorth(),
            east: bounds.getEast(),
            south: bounds.getSouth(),
            west: bounds.getWest(),
        };
        dispatch(updateMapBounds(mapBounds));
    };
}

export function fetchMapGeometryV2() {
    return (dispatch, getState) => {
        const state = getState();
        const currentWorkspace = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
        dispatch(getMapGeometryFileName())
            .then(([fileName, updatedTs]) => {
                dispatch(fetchMapGeometryFile(fileName, currentWorkspace.access_id, updatedTs))
                    .then((mapGeomFileResponse) => {
                        dispatch(mapGeometry(mapGeomFileResponse));
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Unable to get map coverage",
                            description: "An error occurred while fetching map coverage: (Error getting geometry file)",
                        });
                        console.log("Error fetching map geometry", error);
                    });
            })
            .catch((error) => {
                notification.error({
                    message: "Unable to get map coverage",
                    description: "An error occurred while fetching map coverage: (No geometry file found)",
                });
                console.log("Error fetching map geometry", error);
            });
    };
}

export function fetchAllMapGeometry() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch(`https://static${MEMOIZED_DOMAIN_URL}/map_geometry_points.json`, {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                cache: "default",
            })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response);
                    } else {
                        return response.json();
                    }
                })
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    console.log("Error getting static geometry points", error);
                    reject();
                });
        });
    };
}

export function getSessionsFromCoords(body, callback) {
    return (dispatch, getState) => {
        const sessionListFilters = getState().sessionListFilters;

        let postBody = {
            action: "get_local_points",
            ...body,
        };
        // if sessionListFilters are in its default state, do not pass session_list_filters with a payload
        if (!_.isEqual(SESSION_FILTERS_DEFAULTS, sessionListFilters)) {
            postBody["session_list_filters"] = sessionListFilters;
        }

        let url = "/mapGeometry";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("Response", response);
                callback(response);
            })
            .catch((error) => {
                handleJsonPostError("Error getting local sessions", "An error occurred while identifying sessions near the selected location", error);
            });
    };
}

export function mapGeometry(geometry) {
    return {
        type: MAP_GEOMETRY,
        geometry,
    };
}

export function selectMapPoint(pointID, coords, useStills, callback) {
    return {
        queue: MAP_GEOMETRY,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "lookup",
                point_id: pointID,
                videoless: true,
            };
            let url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.length) {
                        const state = getState();

                        const sortedPoints = _.sortBy(response, (dp) => -dp.timestamp);
                        const pointsInCurrentSession = _.filter(sortedPoints, (dp) => dp.session_id === state.playlist.data.routeID);

                        if (pointsInCurrentSession.length) {
                            const selectedPoint = pointsInCurrentSession[0];
                            dispatch(setMapPointSelected(false));
                            notification.warning({
                                message: "No results found for requested location",
                            });
                            dispatch(routeSelected(selectedPoint.session_id, coords, undefined, useStills));
                            dispatch(customAudit("grey_map_line_clicked", { selected_session: selectedPoint.session_id }));
                        } else {
                            const mapFilteredPoints = sortedPoints.filter((dp) => {
                                return state.sessionList.includes(dp.session_id);
                            });

                            if (mapFilteredPoints.length) {
                                const closestPoint = mapFilteredPoints[0];
                                dispatch(routeSelected(closestPoint.session_id, coords, undefined, useStills));
                                dispatch(customAudit("grey_map_line_clicked", { selected_session: closestPoint.session_id }));
                            } else {
                                const filteredPoints = sortedPoints.filter((dp) => state.sessions[dp.session_id]);
                                if (!filteredPoints.length) {
                                    let closest = sortedPoints[0];
                                    dispatch(getSessionData(closest.session_id)).then((_) => {
                                        dispatch(selectMapPoint(pointID, coords, useStills));
                                    });
                                } else {
                                    dispatch(routeSelected(filteredPoints[0].session_id, coords, undefined, useStills));
                                    dispatch(customAudit("grey_map_line_clicked", { selected_session: filteredPoints[0].session_id }));
                                }
                            }
                        }
                    } else {
                        dispatch(setMapPointSelected(false));
                        notification.warning({
                            message: "No results found for requested location",
                        });
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error selecting location", "An error occurred while identifying routes near the selected location", error);
                    next();
                });
        },
    };
}

const calculateGroupedCoordinates = memoize((currentCoords, cache) => {
    return _.chain(cache)
        .map((coordinate) => {
            const offset = latLonToMeters(currentCoords, [coordinate.lon, coordinate.lat]);
            let distanceAwaySquared = d2(offset);
            return {
                ...coordinate,
                distanceAwaySquared,
                from: currentCoords,
            };
        })
        .sortBy("distanceAwaySquared")
        .groupBy((coordinate) => coordinate.system)
        .value();
});

let coordinateCache = null;
let lastCoordinateUpdate = 0;
let lastCoordinateUpdatePosition = [0, 0];
let nextUpdateMaxDistance = 0;
let coordinateUpdateTimer = null;

export function getRouteSystemCoords(currentCoords) {
    return (dispatch, getState) => {
        if (coordinateCache !== null) {
            dispatch(updateFromCache(currentCoords));
        }
        const offset = latLonToMeters(lastCoordinateUpdatePosition, currentCoords);
        let distance = Math.sqrt(offset[0] ** 2 + offset[1] ** 2);
        if (coordinateCache === null || distance >= nextUpdateMaxDistance) {
            clearTimeout(coordinateUpdateTimer);
            const timeSinceLastUpdate = new Date().getTime() - lastCoordinateUpdate;
            if (timeSinceLastUpdate >= 1000) {
                console.log("Distance from last cache position:", distance, "is > than max:", nextUpdateMaxDistance, ", triggering cache update from server");
                dispatch(updateRouteSystemCoords(currentCoords));
            } else {
                coordinateUpdateTimer = setTimeout(() => {
                    console.log(
                        "Distance from last cache position:",
                        distance,
                        "is > than max:",
                        nextUpdateMaxDistance,
                        ", triggering cache update from server",
                    );
                    dispatch(updateRouteSystemCoords(currentCoords));
                }, 1000 - timeSinceLastUpdate);
            }
        }
    };
}

export function updateRouteSystemCoords(currentCoords) {
    const doUpdateRouteSystemCoords = (dispatch) => {
        lastCoordinateUpdatePosition = currentCoords;
        lastCoordinateUpdate = new Date().getTime();
        dispatch(
            routeCoordinateLookup(currentCoords[1], currentCoords[0], false, (result_type, coordinates) => {
                if (result_type === "closest") {
                    coordinateCache = coordinates;
                    let furthestCoordinate = coordinates[coordinates.length - 1];
                    const offset = latLonToMeters(currentCoords, [furthestCoordinate.lon, furthestCoordinate.lat]);
                    nextUpdateMaxDistance = Math.sqrt(offset[0] ** 2 + offset[1] ** 2) / 2;
                    dispatch(updateFromCache(currentCoords));
                } else {
                    coordinateCache = [];
                    nextUpdateMaxDistance = 0;
                    dispatch(receiveRouteCoordData({}));
                }
            }),
        );
    };
    return doUpdateRouteSystemCoords;
}

function updateFromCache(currentCoords) {
    const doUpdateFromCache = (dispatch, getState) => {
        let groupedCoords = calculateGroupedCoordinates(currentCoords, coordinateCache);
        if (groupedCoords !== getState().playlist.position.routeCoordData) {
            dispatch(receiveRouteCoordData(groupedCoords));
        }
    };
    return doUpdateFromCache;
}

export function fetchBehaviourZones() {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("Fetching behaviour zones");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_behaviour_zones",
                access_secret: accessSecret,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.devices) {
                        console.log("Successfully fetched behaviour zones", response);
                        dispatch(behaviourZones(response.devices, response.zones));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch behaviour zones", "An error occurred while fetching the list of behaviour zones", error);
                    next();
                });
        },
    };
}

export function fetchWheelSlipsDetections(detectionType, callback = null, page = 0) {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("Fetching wheel slips detections");
            const accessSecret = getState().admin.password;
            const detectionsLoading = getState().admin.detectionsLoading[detectionType];
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_behaviour_zones_detections",
                access_secret: accessSecret,
                detection_type: detectionType,
                page_index: page,
            };

            const url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(addBehaviourZonesDetections(detectionType, response.data));
                    }
                    if (response.has_more) {
                        if (!detectionsLoading) {
                            dispatch(setDetectionsLoading(detectionType, true));
                        }
                        dispatch(fetchWheelSlipsDetections(detectionType, null, page + 1));
                    } else {
                        dispatch(setDetectionsLoading(detectionType, false));
                        if (callback) {
                            callback(true);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError(
                        `Unable to fetch ${detectionType} detections`,
                        `An error occurred while fetching the list of ${detectionType} detections`,
                        error,
                    );
                    dispatch(setDetectionsLoading(detectionType, false));
                    if (callback) {
                        callback(true);
                    }
                    next();
                });
        },
    };
}

export function fetchDataPools() {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("Fetching data pools");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_all_data_pools",
                access_secret: accessSecret,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        console.log("Successfully fetched data pools", response);
                        dispatch(setDataPools(response.data_pools));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch data pools", "An error occurred while fetching the list of data pools", error);
                    next();
                });
        },
    };
}

export function deleteBehaviourZone(id, callback) {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("Deleting behaviour zone", id);
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "delete_behaviour_zone",
                access_secret: accessSecret,
                zone_id: id,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.result) {
                        console.log("Successfully deleted behaviour zone", response);
                        dispatch(fetchBehaviourZones());
                        if (callback) {
                            callback(true);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    if (callback) {
                        callback(false);
                    }
                    handleJsonPostError("Unable to delete behaviour zone", "An error occurred while deleting the selected behviour zone", error);
                    next();
                });
        },
    };
}

export function updateBehaviourZone(id, latitude, longitude, radius, behaviours, devices, tag, callback) {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("updating behaviour zone", id);
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "update_behaviour_zone",
                access_secret: accessSecret,
                zone_id: id,
                latitude,
                longitude,
                radius,
                behaviours,
                devices,
                tag,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.result) {
                        console.log("Successfully updated behaviour zone", response);
                        dispatch(fetchBehaviourZones());
                    }
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    if (callback) {
                        callback(false);
                    }
                    handleJsonPostError("Unable to update behaviour zone", "An error occurred while updating this behaviour zone", error);
                    next();
                });
        },
    };
}

export function newBehaviourZone(latitude, longitude, radius, devices, behaviours, tag, callback) {
    return {
        queue: BEHAVIOUR_ZONES,
        callback: (next, dispatch, getState) => {
            console.log("saving new behaviour zone");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "add_behaviour_zone",
                access_secret: accessSecret,
                center_latitude: latitude,
                center_longitude: longitude,
                radius,
                behaviours,
                devices,
                tag,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.result) {
                        console.log("Successfully saving new behaviour zone", response);
                        dispatch(fetchBehaviourZones());
                        if (callback) {
                            callback(true);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    if (callback) {
                        callback(false);
                    }
                    handleJsonPostError("Unable to add behaviour zone", "An error occurred while adding this new behaviour zone", error);
                    next();
                });
        },
    };
}

export function routeCoordinateLookup(lat, lon, latest_video, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "route_coordinate_lookup",
            lat,
            lon,
            latest_video,
        };

        const url = "/mapGeometry";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.coordinates) {
                    callback("closest", response.coordinates);
                } else {
                    callback("no_matches", []);
                }
            })
            .catch(() => {
                callback("no_matches", []);
                dispatch(setLocationSearchLoading(false));
            });
    };
}

export function routeCoordinateReverseLookup(system, route, position, subposition, include_video, callback) {
    return (dispatch, getState) => {
        if (!callback) {
            dispatch(setLocationSearchLoading(true));
        }
        const sessionListFilters = getState().sessionListFilters;

        let postBody = {
            action: "route_coordinate_lookup",
            system,
            route,
            position,
            subposition,
            latest_video: include_video,
        };

        // if sessionListFilters are in its default state, do not pass session_list_filters with a payload
        if (!_.isEqual(SESSION_FILTERS_DEFAULTS, sessionListFilters)) {
            postBody["session_list_filters"] = sessionListFilters;
        }

        const url = "/mapGeometry";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                // callback(response.result_type, response.coordinates);
                const resultType = response.result_type;
                let coordinates = response.coordinates;

                if (resultType === "no_matches") {
                    dispatch(receiveLocationSearchResults(resultType, []));
                    notification.error({
                        message: "No results found for requested location",
                    });
                    return false;
                }

                if (resultType === "closest") {
                    let coordinateMap = {};
                    _.forEach(coordinates, (coordinate) => {
                        const coordinateIdentifier = coordinate.route + "." + coordinate.subposition;
                        if (coordinateMap[coordinateIdentifier]) {
                            let otherCoordinate = coordinateMap[coordinateIdentifier];
                            let ratio = 0;
                            if (coordinate.position !== otherCoordinate.position) {
                                ratio = (position - otherCoordinate.position) / (coordinate.position - otherCoordinate.position);
                            }
                            const newLat = coordinate.lat * ratio + otherCoordinate.lat * (1 - ratio);
                            const newLon = coordinate.lon * ratio + otherCoordinate.lon * (1 - ratio);
                            otherCoordinate.position = position;
                            otherCoordinate.lat = newLat;
                            otherCoordinate.lon = newLon;

                            if (otherCoordinate.video && coordinate.video) {
                                if (ratio >= 0.5) {
                                    otherCoordinate.video = coordinate.video;
                                }
                            } else if (coordinate.video) {
                                otherCoordinate.video = coordinate.video;
                            }
                        } else {
                            coordinateMap[coordinateIdentifier] = coordinate;
                        }
                    });
                    coordinates = _.valuesIn(coordinateMap);
                }

                const parsedCoordinates = handleLocationResults(resultType, coordinates, false);
                if (callback) {
                    callback(resultType, parsedCoordinates);
                } else {
                    dispatch(receiveLocationSearchResults(resultType, parsedCoordinates));
                }
            })
            .catch((error) => {
                handleJsonPostError("Find location failed", "An error occurred while finding the specified location", error);
                dispatch(setLocationSearchLoading(false));
                if (callback) {
                    callback(error, null);
                }
            });
    };
}

export function routeCoordinateReverseLookupPromise(system, route, callback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "route_coordinate_lookup",
                system,
                route: route.route,
                position: route.position,
            };

            const url = "/mapGeometry";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response.result_type, response.coordinates);
                })
                .catch((error) => {
                    handleJsonPostError("Find location failed", "An error occurred while finding the specified location", error);
                    reject(error, null);
                });
        });
    };
}

export function multiRouteCoordinateReverseLookup(system, routes = [], callback) {
    return (dispatch, getState) => {
        let routeCoordinatePromises = routes.flatMap((route) => {
            return [
                dispatch(routeCoordinateReverseLookupPromise(system, { route: route.elr, position: route.start_chain })),
                dispatch(routeCoordinateReverseLookupPromise(system, { route: route.elr, position: route.end_chain })),
            ];
        });

        Promise.all(routeCoordinatePromises).then((results) => {
            callback(results);
        });
    };
}

export function getRouteCoordinateSystems() {
    let postBody = {
        action: "get_route_coordinate_systems",
    };

    let url = "/mapGeometry";

    return {
        queue: MAP_GEOMETRY,
        callback: (next, dispatch, getState) => {
            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ systems }) => {
                    // dispatch(routeCoordinateSystems(["Irish Rail"]));
                    dispatch(routeCoordinateSystems(systems || []));
                    next();
                })
                .catch((error) => {
                    console.log("Error fetching route coordinate systems: ", error);
                    dispatch(routeCoordinateSystems([]));
                    next();
                });
        },
    };
}

export function getSnappingValidations(sessionID, callback) {
    return {
        queue: RECEIVE_SNAPPING_VALIDATION,
        callback: (next, dispatch, getState) => {
            console.log("Getting local assets");

            let postBody = {
                action: "get_snap_validations",
                session_id: sessionID,
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got validations", response);
                    if (response.validations) {
                        dispatch(receiveSnappingValidations(response.validations));
                    }
                    if (callback) {
                        callback();
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get snapping validation", "An error occurred while fetching snapping validation", error);
                    next();
                });
        },
    };
}

export function validateSnapping(sessionID, systemID, routeID, routePosition, routeSubposition, callback) {
    return {
        queue: RECEIVE_SNAPPING_VALIDATION,
        callback: (next, dispatch, getState) => {
            console.log("Getting local assets");

            let postBody = {
                action: "validate_snapping",
                session_id: sessionID,
                system_id: systemID,
                route_id: routeID,
                route_position: routePosition,
                route_subposition: routeSubposition,
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Updated snapping validation", response);
                    if (response.success) {
                        dispatch(getSnappingValidations(sessionID, callback));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get snapping validation", "An error occurred while fetching snapping validation", error);
                    next();
                });
        },
    };
}

export function deleteSnappingValidation(snappingID, callback) {
    return {
        queue: RECEIVE_SNAPPING_VALIDATION,
        callback: (next, dispatch, getState) => {
            console.log("Getting local assets");

            let postBody = {
                action: "delete_snapping_validation",
                snap_id: snappingID,
            };

            const url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Updated snapping validation", response);
                    if (response.success) {
                        dispatch(getSnappingValidations(getState().playlist.data.routeID, callback));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get snapping validation", "An error occurred while fetching snapping validation", error);
                    next();
                });
        },
    };
}

export function fetchWhatThreeWords(lat, lng) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_what_three_words",
                lat,
                lng,
            };

            let url = "/mapGeometry";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.words) {
                        resolve(response.words);
                    } else {
                        resolve("Error getting location");
                    }
                })
                .catch((error) => {
                    reject();
                });
        });
    };
}

export function searchForTimestamp(timestamp, filters, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "find_timestamp_sessions",
            timestamp,
            filters,
        };

        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.sessions) {
                    callback("closest", response.sessions);
                } else {
                    callback("no_matches", []);
                }
            })
            .catch(() => {
                console.log("Error searching for timestamp");
                callback("no_matches", []);
            });
    };
}

export function getClosestAssets(elr, positionChains) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_nearest_assets",
            elr,
            positionChains,
        };

        let url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    const uniqueResponse = _.uniqBy(response, "id");
                    dispatch(setClosestAssets(uniqueResponse));
                    dispatch(setCurrentNearbyAsset(uniqueResponse[0]));
                }
            })
            .catch((error) => {
                console.log("Error getting closest assets", error);
            });
    };
}

export function setClosestAssets(assets) {
    return {
        type: SET_CLOSEST_ASSETS,
        assets,
    };
}

export function setDisplayNearbyAssets(bool) {
    return {
        type: SET_DISPLAY_NEARBY_ASSETS,
        bool,
    };
}

export function setCurrentNearbyAsset(asset) {
    return {
        type: SET_CURRENT_NEARBY_ASSET,
        asset,
    };
}

export function lookupLatLon(lat, lon, searchWorkspaces, callback) {
    return (dispatch, getState) => {
        if (!callback) {
            dispatch(setLocationSearchLoading(true));
        }
        dispatch(goToMapPosition([lat, lon, 13]));
        dispatch(
            routeCoordinateLookup(lat, lon, !searchWorkspaces, (resultType, coordinates) => {
                const parsedCoordinates = handleLocationResults(resultType, coordinates, { lat, lon });
                console.log("debug parsed coordinates here", parsedCoordinates);
                if (callback) {
                    callback(resultType, parsedCoordinates);
                } else {
                    dispatch(receiveLocationSearchResults(resultType, parsedCoordinates));
                }
            }),
        );
    };
}

export function whatThreeWordsLookup(words, request_video, callback) {
    return (dispatch, getState) => {
        if (!callback) {
            dispatch(setLocationSearchLoading(true));
        }

        let postBody = {
            action: "lookup_what_three_words",
            words,
            latest_video: request_video,
        };

        const url = "/mapGeometry";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.coordinates) {
                    const resultType = "closest";
                    const parsedCoordinates = handleLocationResults("closest", response.coordinates, response.location);
                    if (callback) {
                        callback(resultType, parsedCoordinates, response.location);
                    } else {
                        dispatch(receiveLocationSearchResults(resultType, parsedCoordinates));
                    }
                } else if (callback) {
                    callback("no_matches", []);
                }
            })
            .catch(() => {
                dispatch(setLocationSearchLoading(false));
                if (callback) {
                    callback("no_matches", []);
                }
            });
    };
}

export const setLocationSearchLoading = (loading) => {
    return {
        type: LOCATION_SEARCH_LOADING,
        loading,
    };
};

export const displayResultsOnMap = (display) => {
    return {
        type: DISPLAY_LOCATION_RESULTS_ON_MAP,
        display,
    };
};

export const clearLocationSearchResults = () => {
    return {
        type: CLEAR_LOCATION_RESULTS,
    };
};

export const receiveLocationSearchResults = (resultType, results) => {
    console.log("debug results", results);
    return (dispatch, getState) => {
        if (results && results.length) {
            dispatch(goToMapPosition([results[0].lat, results[0].lon, 13]));
        }
        dispatch({
            type: RECEIVE_LOCATION_SEARCH_RESULT,
            resultType,
            results,
        });
    };
};

export const addBehaviourZonesDetections = (detectionType, detections) => {
    return {
        type: ADD_BEHAVIOUR_ZONES_DETECTION,
        detectionType,
        detections,
    };
};

export const setDetectionsLoading = (detectionType, loading) => {
    return {
        type: SET_DETECTIONS_LOADING,
        detectionType,
        loading,
    };
};

function fetchMapGeometryFile(fileName, workspaceID, updatedTs) {
    return (_dispatch, _getState) => {
        return new Promise((resolve, reject) => {
            return fetch(`https://workspace-static${MEMOIZED_DOMAIN_URL}/${workspaceID}/${fileName}.json?updated=${updatedTs}`, {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                cache: "default",
            })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response);
                    } else {
                        return response.json();
                    }
                })
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    console.log("Error getting static geometry points", error);
                    reject();
                });
        });
    };
}

function getMapGeometryFileName() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_geometry_file",
            };

            let url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response && response.geometry_file_id && response.updated_ts) {
                        resolve([response.geometry_file_id, response.updated_ts]);
                    } else if (response.segments) {
                        dispatch(mapGeometry(response.segments));
                        return;
                    }
                    reject();
                })
                .catch((error) => {
                    console.log("Error getting map geometry file name", error);
                    reject();
                });
        });
    };
}

export function getRouteAge() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_route_age",
            };

            let url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response && response.route_age) {
                        dispatch({
                            type: RECEIVE_ROUTE_AGE,
                            routeAge: response.route_age,
                        });
                        resolve();
                    }
                })
                .catch((error) => {
                    console.log("Error getting geometry route age", error);
                    reject();
                });
        });
    };
}

export function getEnvironmentalDataForBounds(page = 0, callback) {
    return {
        queue: SET_MAP_ENVIRONMENT_DATA,
        callback: (next, dispatch, getState) => {
            console.log("Fetching map environmental data");

            let postBody = {
                action: "get_map_environment_data",
                page,
            };

            const url = "/routeMetadata";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        if (response.data) {
                            dispatch({
                                type: SET_MAP_ENVIRONMENT_DATA,
                                mapData: response.data,
                                isMore: response.is_more,
                            });

                            dispatch({
                                type: SET_MAP_ENVIRONMENT_DATA_TYPES,
                                types: response.data_types,
                            });

                            dispatch({
                                type: SET_MAP_ENVIRONMENT_SUB_DATA_TYPES,
                                subTypes: response.sub_data_types,
                            });
                        }

                        if (response.is_more) {
                            dispatch(getEnvironmentalDataForBounds(page + 1));
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError(`Unable to fetch environmental data`, `An error occurred while fetching the environmental data`, error);
                    if (callback) {
                        callback(true);
                    }
                    dispatch({
                        type: SET_MAP_ENVIRONMENT_DATA,
                        mapData: {},
                        isMore: false,
                        errorLoading: true,
                    });
                    next();
                });
        },
    };
}

export const setEnvironmentalDataSelectedSegment = (selectedSegment) => {
    return {
        type: SET_MAP_ENVIRONMENT_SELECTED_SEGMENT,
        selectedSegment,
    };
};

export const setEnvironmentalDataFilters = (filters) => {
    return {
        type: SET_MAP_ENVIRONMENT_FILTERS,
        filters,
    };
};
