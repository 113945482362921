import React from "react";
import { connect } from "react-redux";
import OBCSpinner from "./OBC";

class LoadingOverlay extends React.PureComponent {
    render() {
        if (this.props.loading) {
            return (
                <div className="Loading-Overlay Center-Content">
                    <div className="Loading-Overlay-Content">
                        <OBCSpinner
                            size={150}
                            speed={3}
                            color={"white"}
                        />
                        Loading...
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LoadingOverlay);
