import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
    faStepBackward,
    faStepForward,
    faTimes,
    faFastForward,
    faFastBackward,
    faLock,
    faLockOpen,
    faVectorSquare,
    faFont,
    faCompress,
    faExpand,
    faCamera,
    faPlus,
    faArrowLeft,
    faEyeDropper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    logEvent,
    logTiming,
    updateAnnotations,
    toggleFullscreen,
    toggleSnapshot,
    addCustomAnnotationLabel,
    removeCustomAnnotationLabel,
    requestPlaylistPosition,
    toggleAnnotationPrivacy,
} from "redux/actions/index";
import {
    asyncLoadImage,
    getCurrentVideoKey,
    getCurrentFrame,
    calculateNext,
    calculatePrevious,
    previousContent,
    nextContent,
    getUrlDataForFrame,
} from "components/util/PlaylistUtils";
import { Tooltip, Button, AutoComplete, Select, Checkbox } from "antd";
import { faHorizontalRule, faCircle, faTimesCircle, faEdit, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import Measure from "react-measure";
import Draggable from "react-draggable";
import OBCSpinner from "../../../util/OBC";

import { fabric } from "fabric";
import { faArrowsAlt } from "@fortawesome/pro-solid-svg-icons";
import CustomColorPicker from "./CustomColorPicker";
import { emulateTab } from "emulate-tab";
import ThreeDFeatureOverlay from "../3d/3dFeatureOverlay";

const STROKE_WIDTH = 3;
const DEFAULT_COLOUR = "#ffeb3b";
const { Option, OptGroup } = AutoComplete;

class AnnotationInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            annotations: [],
            selectingBoundsType: false,
            bbCustomType: "",
            timeInterfaceEntered: 0,
            imageData: null,
            imageDataIndex: 0,
            imageDataFrame: 0,
            annotateMode: "box", //["box", "circle", "line", "text"]
            dimensions: {
                height: -1,
                width: -1,
            },
            customAnnotationInput: "",
            newTextInput: "",
            firstRender: true,
            textRefs: {},
            canvas: null,
            mouseDown: false,
            startX: null,
            startY: null,
            textAnnotationInput: "",
            inputtingText: false,
            privateAnnotations: props.privateByDefault,
            draggablePosition: {},
            labelList: [],
            showSaveSuccess: false,
            pickingColour: false,
            currentColour: "#ffeb3b",
            selectingLine: false,
            isShapeSelected: false,
            currentAnnotation: null,
            annotationUsers: [],
        };

        this.tagInputRef = React.createRef();
        this.displayDiv = React.createRef();
        this.textInput = React.createRef();

        fabric.Object.prototype.noScaleCache = false;
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor = "#ffffff";
    }
    componentDidMount() {
        this.setState(
            {
                timeInterfaceEntered: new Date().getTime(),
            },
            () => {
                this.initialiseFromState(true);
            },
        );

        this.props.dispatch(logEvent("Annotation", "Enter Annotation Interface"));
        const urlData = getUrlDataForFrame(this.props.imageKeys, this.props.currentIndex, this.props.currentFrame);
        const url = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;
        asyncLoadImage(url, urlData.range).then((imageData) => {
            const urlData = getUrlDataForFrame(this.props.imageKeys, this.props.currentIndex, this.props.currentFrame);
            const currentURL = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;
            if (url === currentURL) {
                this.setState({
                    imageData,
                    imageDataIndex: this.props.currentIndex,
                    imageDataFrame: this.props.currentFrame,
                });
            }
        });
        document.addEventListener("keydown", this.handleKeyPress, false);

        if (this.props.userAnnotationNames) {
            this.setState({
                annotationUsers: this.props.userAnnotationNames,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userAnnotations !== prevProps.userAnnotations) {
            this.initialiseFromState(true);
        }

        if (this.props.currentIndex !== prevProps.currentIndex || this.props.currentFrame !== prevProps.currentFrame) {
            const urlData = getUrlDataForFrame(this.props.imageKeys, this.props.currentIndex, this.props.currentFrame);
            const url = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;
            asyncLoadImage(url, urlData.range).then((imageData) => {
                const urlData = getUrlDataForFrame(this.props.imageKeys, this.props.currentIndex, this.props.currentFrame);
                const currentURL = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;
                if (url === currentURL) {
                    this.setState({
                        imageData,
                        imageDataIndex: this.props.currentIndex,
                        imageDataFrame: this.props.currentFrame,
                    });
                }
                this.initialiseFromState(true);
            });
        }

        if (this.props.privateByDefault !== prevProps.privateByDefault) {
            this.setState({
                privateAnnotations: this.props.privateByDefault,
            });
        }

        if (this.state.dimensions !== prevState.dimensions) {
            this.state.canvas.setHeight(this.state.dimensions.height);
            this.state.canvas.setWidth(this.state.dimensions.width);
            this.renderCanvasShapes();
        }

        if (!this.state.canvas && this.fabricCanvas) {
            const _this = this;
            const canvas = new fabric.Canvas(this.fabricCanvas, { defaultCursor: "crosshair", uniformScaling: false });
            canvas.on("mouse:down", function (e) {
                _this.onFabricClick(e);
            });
            canvas.on("mouse:move", function (e) {
                _this.onFabricMove(e);
            });
            canvas.on("mouse:up", function (e) {
                _this.onFabricUp(e);
            });
            canvas.on("object:modified", function (e) {
                _this.onFabricModified(e);
            });
            canvas.on("selection:created", function (e) {
                _this.onFabricSelected(e);
                _this.setState({
                    isShapeSelected: true,
                });
            });
            canvas.on("selection:updated", function (e) {
                _this.onFabricSelectionUpdated(e);
                _this.setState({
                    isShapeSelected: true,
                });
            });
            canvas.on("selection:cleared", function (e) {
                _this.onFabricSelectionUpdated(e);
                _this.setState({
                    isShapeSelected: false,
                });
            });
            canvas.on("object:moving", function (e) {
                _this.onFabricObjectMoving(e);
            });
            canvas.on("object:scaling", function (e) {
                _this.onFabricObjectMoving(e);
            });

            this.setState({ canvas });
        }

        if (this.state.annotationUsers !== prevState.annotationUsers) {
            this.initialiseFromState(true);
        }
    }

    onColorChange = (color) => {
        this.setState(
            {
                currentColour: color,
                pickingColour: false,
                selectingLine: false,
            },
            () => {
                const shape = this.state.canvas.getActiveObject();
                if (shape && shape.saved) {
                    const annotations = _.clone(this.state.annotations);
                    const editingAnnotationIndex = _.findIndex(annotations, (ann) => ann.id === shape.id);
                    if (editingAnnotationIndex === -1) {
                        return;
                    }
                    let bbType = annotations[editingAnnotationIndex].bbType;
                    let customTag = annotations[editingAnnotationIndex].customTag;
                    this.onTagSelect(bbType, customTag);
                } else if (shape && shape.type === "line") {
                    this.onTagSelect(null);
                    this.setState({
                        pickingColour: false,
                    });
                } else {
                    this.setState({
                        pickingColour: false,
                    });
                }
            },
        );
    };

    deleteAnnotation = (eventData, transform) => {
        const activeObj = this.state.canvas.getActiveObject();

        if (activeObj) {
            const annotationID = activeObj.id;
            this.removeAnnotation(annotationID);
        }
    };

    handleKeyPress = (e) => {
        if (e.key === "Backspace" && !this.state.selectingBoundsType) {
            const activeObj = this.state.canvas.getActiveObject();

            if (activeObj) {
                this.deleteAnnotation(_, { target: { id: activeObj.id } });
            }
        }
    };

    openEditPopup = () => {
        const activeObj = this.state.canvas.getActiveObject();
        if (!activeObj) {
            return;
        }
        let currentLabel = "";
        let currentColour = "#ffeb3b";
        const annotationObj = _.find(this.state.annotations, { id: activeObj.id });
        if (annotationObj && annotationObj.bbType === -1) {
            currentLabel = annotationObj.customTag;
        } else if (annotationObj && annotationObj.bbType) {
            currentLabel = annotationObj.bbType;
        }
        if (annotationObj && annotationObj.colour) {
            currentColour = annotationObj.colour;
        }

        this.setState(
            {
                selectingBoundsType: true,
                customAnnotationInput: currentLabel,
                currentColour: currentColour,
                selectingLine: activeObj.type === "line",
            },
            () => {
                if (this.tagInputRef.current) {
                    this.tagInputRef.current.focus();
                }
            },
        );
    };

    navigate = (nav) => {
        if (nav === null) {
            return;
        }
        const newKeyIndex = nav[0];
        const offset = nav[1];
        console.log("Requesting index/offset:", newKeyIndex, offset);
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, newKeyIndex, offset),
        );
    };

    navigateNext = () => this.navigate(calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePrevious = () => this.navigate(calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));

    get isNextDisabled() {
        return calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    get isPreviousDisabled() {
        return calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    navigateNextAnnotation = () =>
        this.navigate(nextContent(this.props.userAnnotations, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePreviousAnnotation = () =>
        this.navigate(previousContent(this.props.userAnnotations, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));

    get isNextAnnotationDisabled() {
        return nextContent(this.props.userAnnotations, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    get isPreviousAnnotationDisabled() {
        return previousContent(this.props.userAnnotations, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    onFabricObjectMoving = (e) => {
        const p = e.target;

        // move line
        if (p.circle1) {
            const oldCenterX = (p.x1 + p.x2) / 2;
            const oldCenterY = (p.y1 + p.y2) / 2;

            const deltaX = p.left - oldCenterX;
            const deltaY = p.top - oldCenterY;

            p.circle1 &&
                p.circle1
                    .set({
                        left: p.x1 + deltaX,
                        top: p.y1 + deltaY,
                    })
                    .setCoords();
            p.circle2 &&
                p.circle2
                    .set({
                        left: p.x2 + deltaX,
                        top: p.y2 + deltaY,
                    })
                    .setCoords();

            p.set({
                x1: p.x1 + deltaX,
                y1: p.y1 + deltaY,
            });
            p.set({
                x2: p.x2 + deltaX,
                y2: p.y2 + deltaY,
            });

            p.set({
                left: (p.x1 + p.x2) / 2,
                top: (p.y1 + p.y2) / 2,
            });
        }

        // move circle
        if (p.line) {
            const cir1 = p.line.circle1;
            const cir2 = p.line.circle2;

            p.line.set({
                x1: cir1.left,
                y1: cir1.top,
            });
            p.line.set({
                x2: cir2.left,
                y2: cir2.top,
            });
            p.line.setCoords();
        }

        if (p.boxLabel) {
            const label = p.boxLabel;
            const box = p.boxLabel.box;

            label.set({
                top: box.top - 27,
                left: box.left,
            });
        }
        this.state.canvas.renderAll();
    };

    onFabricSelectionUpdated = (e) => {
        if (e.deselected[0] && !e.deselected[0].isNew) {
            this.save();
        }
    };

    onFabricSelected = (e) => {
        const annotationID = e.target.id;
        const annotations = _.cloneDeep(this.state.annotations);

        const editingAnnotationIndex = _.findIndex(annotations, (ann) => ann.id === annotationID);

        if (editingAnnotationIndex === -1) {
            return;
        }

        annotations[editingAnnotationIndex] = {
            ...annotations[editingAnnotationIndex],
            editing: true,
        };

        e.target.set({
            editing: true,
            dirty: true,
        });

        this.state.canvas.renderAll();
        this.setState({
            isShapeSelected: true,
            annotations: annotations,
        });
    };

    onFabricModified = (e) => {
        const annotationID = e.target.id;
        const annotations = _.cloneDeep(this.state.annotations);
        const canvas = this.state.canvas;

        let editingAnnotationIndex = _.findIndex(annotations, (ann) => ann.id === annotationID);
        if (editingAnnotationIndex === -1) {
            if (e.target.line) {
                const cir1 = e.target.line.circle1;
                const cir2 = e.target.line.circle2;

                editingAnnotationIndex = _.findIndex(annotations, (ann) => ann.id === e.target.line.id);

                if (editingAnnotationIndex > -1) {
                    const cir1Top = cir1.top / canvas.height;
                    const cir1Left = cir1.left / canvas.width;
                    const cir2Top = cir2.top / canvas.height;
                    const cir2Left = cir2.left / canvas.width;

                    const newAnnotation = {
                        ...annotations[editingAnnotationIndex],
                        bbTop: cir1Top,
                        bbLeft: cir1Left,
                        bbBottom: cir2Top,
                        bbRight: cir2Left,
                        bbWidth: Math.abs(cir1.left - cir2.left) / canvas.width,
                        bbHeight: Math.abs(cir1.top - cir2.top) / canvas.height,
                        changed: true,
                        saved: true,
                        editing: true,
                    };

                    if (!_.isEqual(annotations[editingAnnotationIndex], newAnnotation)) {
                        annotations[editingAnnotationIndex] = newAnnotation;
                    }
                }
            }
        } else {
            const boundingRect = e.target.getBoundingRect();

            let top = boundingRect.top;
            let left = boundingRect.left;
            let bottom = (top + boundingRect.height) / canvas.height;
            let right = (left + boundingRect.width) / canvas.width;

            if (e.target.circle1) {
                const circle1 = e.target.circle1;
                const circle2 = e.target.circle2;

                top = circle1.top;
                left = circle1.left;
                bottom = circle2.top / canvas.height;
                right = circle2.left / canvas.width;
            }

            const annotation = {
                ...annotations[editingAnnotationIndex],
                bbTop: top / canvas.height,
                bbLeft: left / canvas.width,
                bbBottom: bottom,
                bbRight: right,
                changed: true,
            };

            annotations[editingAnnotationIndex] = annotation;
        }

        this.setState(
            {
                annotations: annotations,
            },
            () => {
                this.save();
            },
        );
    };

    initialiseFromState = (render = false) => {
        let currentAnnotations = this.props.userAnnotations.filter((annotation) => {
            return (
                annotation.videoKey === this.props.videoKey &&
                annotation.frame === this.props.currentFrame
            );
        });

        let privateAnnotations = this.props.privateByDefault;

        let pageAnnotations = currentAnnotations.map((annotation) => {
            if (annotation.is_users_annotation) {
                privateAnnotations = annotation.private;
            }

            return {
                id: annotation.id,
                bbTop: annotation.bounds[0],
                bbLeft: annotation.bounds[1],
                bbBottom: annotation.bounds[2],
                bbRight: annotation.bounds[3],
                bbHeight: annotation.bounds[2] - annotation.bounds[0],
                bbWidth: annotation.bounds[3] - annotation.bounds[1],
                bbType: annotation.type,
                changed: false,
                isUsersAnnotation: annotation.is_users_annotation,
                isAdmin: annotation.is_admin,
                private: annotation.private,
                shape: annotation.shape,
                display: true,
                customTag: annotation.custom_tag,
                colour: annotation.colour,
                reviewed: annotation.reviewed,
                name: annotation.name
            };
        });

        this.setState(
            {
                privateAnnotations,
                annotations: pageAnnotations,
                mouseDown: false,
                selectingBoundsType: false,
            },
            () => {
                if (render) {
                    this.renderCanvasShapes();
                }
            },
        );
    };

    togglePrivacy = () => {
        if (this.isToolsDisabled()) {
            return;
        }
        this.props
            .dispatch(toggleAnnotationPrivacy(!this.state.privateAnnotations, this.props.videoKey, this.props.currentFrame, this.props.sessionID))
            .then((success) => {
                if (success) {
                    this.setState({
                        showSaveSuccess: true,
                        privateAnnotations: !this.state.privateAnnotations,
                    });
                    setTimeout(() => {
                        this.setState({
                            showSaveSuccess: false,
                        });
                    }, 1000);
                }
            });
    };

    renderCanvasShapes = () => {
        if (!this.state.canvas) {
            return;
        }
        const activeObj = this.state.canvas.getActiveObject();
        const canvas = this.state.canvas;
        canvas.clear();

        let allAnnotations = _.cloneDeep(this.state.annotations);

        if (this.state.currentAnnotation) {
            allAnnotations.push(this.state.currentAnnotation);
        }

        allAnnotations.filter(ann => {
            return _.includes(this.state.annotationUsers, ann.name)
        }).forEach((annotation) => {
            annotation = _.cloneDeep(annotation);
            let left = canvas.width * annotation.bbLeft;
            let right = canvas.width * annotation.bbRight;

            let top = canvas.height * annotation.bbTop;
            let bottom = canvas.height * annotation.bbBottom;

            if ((annotation.shape === "box" || annotation.shape === "circle") && (!annotation.editing || !annotation.inProgress)) {
                right = canvas.width * annotation.bbRight - STROKE_WIDTH;
                bottom = canvas.height * annotation.bbBottom - STROKE_WIDTH;
            }

            if (annotation.shape === "text" && (!annotation.editing || !annotation.inProgress)) {
                left = canvas.width * annotation.bbLeft + STROKE_WIDTH + 1;
                top = canvas.height * annotation.bbTop + STROKE_WIDTH + 1;
            }

            const width = Math.abs(left - right);
            const height = Math.abs(top - bottom);

            let element = null;

            let tagName = "";
            if (annotation.bbType) {
                if (annotation.bbType === -1) {
                    tagName = annotation.customTag;
                } else {
                    let selectedTag = this.props.userAnnotationTypes.find((type) => type.id === annotation.bbType);
                    if (selectedTag) {
                        tagName = selectedTag.type;
                    }
                }
            }

            let canEditAnnotation = false;
            if (
                (annotation.isUsersAnnotation && !annotation.reviewed) ||
                (this.props.currentDashboard.config.annotation_review_enabled && this.props.currentDashboard.permissions.admin)
            ) {
                canEditAnnotation = true;
            }

            if (annotation.shape === "box") {
                const box = new fabric.Rect({
                    left: left,
                    top: top,
                    width: width,
                    height: height,
                    fill: "",
                    strokeWidth: STROKE_WIDTH,
                    centeredScaling: false,
                    stroke: annotation.colour || DEFAULT_COLOUR,
                    id: annotation.id,
                    saved: annotation.inProgress ? false : true,
                    hasBorders: false,
                    controls: {
                        ...fabric.Text.prototype.controls,
                        mtr: new fabric.Control({ visible: false }),
                    },
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "move" : "default",
                    strokeUniform: true,
                });

                if (annotation.inProgress) {
                    box.strokeDashArray = [5, 5];
                } else {
                    const boxlabel = new fabric.Text(tagName, {
                        fontFamily: "Helvetica",
                        fontSize: 23,
                        fill: "white",
                        textAlign: "left",
                        originX: "left",
                        originY: "top",
                        left: left,
                        top: top - 27,
                        lockScalingY: true,
                        backgroundColor: "rgba(0,0,0,0.55)",
                        hasBorders: false,
                        hasControls: false,
                        evented: false,
                    });
                    box.boxLabel = boxlabel;
                    boxlabel.box = box;
                    this.state.canvas.add(boxlabel);
                }

                this.state.canvas.add(box);
                if (annotation.editing || annotation.inProgress || (activeObj && activeObj.id === annotation.id)) {
                    this.state.canvas.setActiveObject(box);
                }
            } else if (annotation.shape === "circle") {
                const circle = new fabric.Ellipse({
                    left: left,
                    top: top,
                    rx: width / 2,
                    ry: height / 2,
                    angle: 0,
                    fill: "",
                    strokeWidth: STROKE_WIDTH,
                    type: "circle",
                    saved: annotation.inProgress ? false : true,
                    stroke: annotation.colour || DEFAULT_COLOUR,
                    id: annotation.id,
                    hasBorders: false,
                    controls: {
                        ...fabric.Text.prototype.controls,
                        mtr: new fabric.Control({ visible: false }),
                    },
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "move" : "default",
                    strokeUniform: true,
                });

                const boxlabel = new fabric.Text(tagName, {
                    fontFamily: "Helvetica",
                    fontSize: 23,
                    fill: "white",
                    textAlign: "left",
                    originX: "left",
                    originY: "top",
                    left: left,
                    top: top - 27,
                    lockScalingY: true,
                    backgroundColor: "rgba(0,0,0,0.55)",
                    hasBorders: false,
                    hasControls: false,
                    saved: annotation.inProgress ? false : true,
                    evented: false,
                });

                circle.boxLabel = boxlabel;
                boxlabel.box = circle;
                this.state.canvas.add(circle);
                this.state.canvas.add(boxlabel);
                if (annotation.editing || annotation.inProgress || (activeObj && activeObj.id === annotation.id)) {
                    this.state.canvas.setActiveObject(circle);
                }
            } else if (annotation.shape === "line") {
                const points = [left, top, right, bottom];
                const circle1 = new fabric.Circle({
                    left: left,
                    top: top,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockRotation: true,
                    hasBorders: false,
                    radius: 2,
                    strokeWidth: 4,
                    type: "line",
                    stroke: "red",
                    fill: "red",
                    originX: "center",
                    originY: "center",
                    hasControls: false,
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "pointer" : "default",
                });

                const circle2 = new fabric.Circle({
                    left: right,
                    top: bottom,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockRotation: true,
                    hasBorders: false,
                    hasControls: false,
                    radius: 2,
                    strokeWidth: 4,
                    stroke: "red",
                    fill: "red",
                    originX: "center",
                    originY: "center",
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "pointer" : "default",
                });

                const line = new fabric.Line(points, {
                    originX: "center",
                    originY: "center",
                    strokeWidth: STROKE_WIDTH + 1,
                    stroke: annotation.colour || DEFAULT_COLOUR,
                    id: annotation.id,
                    lockScalingX: true,
                    lockScalingY: true,
                    saved: annotation.inProgress ? false : true,
                    lockRotation: true,
                    perPixelTargetFind: true,
                    hasControls: false,
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "move" : "default",
                });
                line.lockScalingX = line.lockScalingY = true;

                circle1.line = circle2.line = line;
                line.circle1 = circle1;
                line.circle2 = circle2;
                this.state.canvas.add(line);
                if (annotation.isUsersAnnotation) {
                    this.state.canvas.add(circle1);
                    this.state.canvas.add(circle2);
                    circle1.bringToFront();
                    circle2.bringToFront();
                }
                if (annotation.editing || annotation.inProgress || (activeObj && activeObj.id === annotation.id)) {
                    this.state.canvas.setActiveObject(line);
                }
            } else if (annotation.shape === "text") {
                element = new fabric.Text(tagName, {
                    width: width,
                    fontSize: 25,
                    fill: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.55)",
                    padding: 4,
                    left: left,
                    top: top,
                    originX: "left",
                    originY: "top",
                    type: "text",
                    fontFamily: "Helvetica",
                    id: annotation.id,
                    saved: annotation.inProgress ? false : true,
                    controls: {
                        ...fabric.Text.prototype.controls,
                        mtr: new fabric.Control({ visible: false }),
                        bl: new fabric.Control({ visible: false }),
                        br: new fabric.Control({ visible: false }),
                        tl: new fabric.Control({ visible: false }),
                        tr: new fabric.Control({ visible: false }),
                        ml: new fabric.Control({ visible: false }),
                        mr: new fabric.Control({ visible: false }),
                        mb: new fabric.Control({ visible: false }),
                        mt: new fabric.Control({ visible: false }),
                    },
                    selectable: canEditAnnotation,
                    hoverCursor: canEditAnnotation ? "move" : "default",
                });

                if (element) {
                    canvas.add(element);
                    if (annotation.editing || annotation.inProgress || (activeObj && activeObj.id === annotation.id)) {
                        this.state.canvas.setActiveObject(element);
                    }
                }
            }
        });
    };

    componentWillUnmount() {
        this.props.dispatch(logEvent("Annotation", "Exit Annotation Interface"));
        logTiming("Annotation", "Time spent Annotating", new Date().getTime() - this.state.timeInterfaceEntered);
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    close = () => {
        this.props.onClose();
    };

    closeLabelModal = () => {
        const annotations = this.state.annotations.map((annotation) => {
            return { ...annotation, editing: false };
        });

        this.setState(
            {
                selectingBoundsType: false,
                customAnnotationInput: "",
                newTextInput: "",
                firstRender: true,
                currentAnnotation: null,
                annotations: annotations,
            },
            () => {
                this.renderCanvasShapes();
            },
        );
    };

    save = (rerender = false) => {
        let originalAnnotations = this.props.userAnnotations.filter((annotation) => {
            return annotation.videoKey === this.props.videoKey && annotation.frame === this.props.currentFrame && annotation.is_users_annotation;
        });
        let deletedAnnotation = originalAnnotations
            .filter((annotation) => {
                return !_.some(this.state.annotations, (other) => other.id === annotation.id);
            })
            .map((annotation) => annotation.id)[0];

        let modifiedAnnotation = this.state.annotations.filter((annotation) => {
            return annotation.id !== -1 && annotation.changed;
        })[0];

        let newAnnotation = this.state.annotations.filter((annotation) => {
            return annotation.id === -1;
        })[0];

        if (deletedAnnotation || modifiedAnnotation || newAnnotation) {
            this.props.dispatch(logEvent("Annotation", "Update Annotations", `${this.props.sessionID}/${this.props.videoKey}`));

            this.props.dispatch(
                updateAnnotations(
                    this.props.sessionID,
                    this.props.videoKey,
                    deletedAnnotation,
                    modifiedAnnotation,
                    newAnnotation,
                    this.props.currentFrame,
                    (success) => {
                        if (success) {
                            const addedIndex = _.findIndex(this.state.annotations, (ann) => ann.id === -1);
                            if (addedIndex > -1) {
                                const newAnnotations = _.clone(this.state.annotations);
                                newAnnotations[addedIndex].id = success;
                                this.setState({ annotations: newAnnotations });
                            }

                            if (rerender) {
                                this.renderCanvasShapes();
                            }

                            this.setState({
                                showSaveSuccess: true,
                            });
                            setTimeout(() => {
                                this.setState({
                                    showSaveSuccess: false,
                                });
                            }, 1000);
                        }
                    },
                ),
            );
        }
    };

    fabricShapeToAnnotation = (shape, isShape) => {
        let canvas = this.state.canvas;
        let newAnnotation = {};

        let top = this.state.startY / canvas.height;
        let left = this.state.startX / canvas.width;
        if (shape) {
            top = shape.top / canvas.height;
            left = shape.left / canvas.width;
        }

        newAnnotation.changed = true;
        newAnnotation.isUsersAnnotation = true;
        newAnnotation.private = this.state.privateAnnotations;
        newAnnotation.bbTop = top;
        newAnnotation.bbLeft = left;

        if (isShape) {
            let bottom = (shape.top + shape.height) / canvas.height;
            let right = (shape.left + shape.width) / canvas.width;

            if (shape.type === "circle") {
                if (shape.originX === "right") {
                    left = (shape.left - shape.width) / canvas.width;
                    right = shape.left / canvas.width;
                }

                if (shape.originY === "bottom") {
                    top = (shape.top - shape.height) / canvas.height;
                    bottom = shape.top / canvas.height;
                }
            }

            newAnnotation.bbBottom = bottom;
            newAnnotation.bbRight = right;
            newAnnotation.bbTop = top;
            newAnnotation.bbLeft = left;
            newAnnotation.shape = shape.type;
            newAnnotation.colour = this.state.currentColour;
            newAnnotation.id = -1;

            if (shape.type === "line") {
                newAnnotation.bbTop = shape.y1 / canvas.height;
                newAnnotation.bbLeft = shape.x1 / canvas.width;
                newAnnotation.bbBottom = shape.y2 / canvas.height;
                newAnnotation.bbRight = shape.x2 / canvas.width;
            }
        } else {
            newAnnotation.bbType = -1;
            newAnnotation.bbBottom = top;
            newAnnotation.bbRight = left;
            newAnnotation.shape = "text";
        }

        return newAnnotation;
    };

    onTagSelect = (tag, customTag = false) => {
        if (tag === -1 && !customTag) {
            return;
        }

        const shape = this.state.canvas.getActiveObject();
        const annotations = _.cloneDeep(this.state.annotations);

        if (shape && shape.saved) {
            const editingAnnotationIndex = _.findIndex(annotations, (ann) => ann.id === shape.id);
            if (editingAnnotationIndex === -1) {
                return;
            }

            let updatedData = {
                bbType: tag,
                colour: this.state.currentColour,
            };

            if (customTag) {
                updatedData.customTag = customTag;
                updatedData.bbType = -1;
            }
            annotations[editingAnnotationIndex] = {
                ...annotations[editingAnnotationIndex],
                ...updatedData,
                changed: true,
            };
        } else {
            let newAnnotation = null;
            if (this.state.annotateMode === "text") {
                newAnnotation = this.fabricShapeToAnnotation(shape, false);
                newAnnotation.customTag = customTag;
            } else if (this.state.annotateMode === "line") {
                newAnnotation = this.state.currentAnnotation;
                newAnnotation.colour = this.state.currentColour;
            } else {
                newAnnotation = this.state.currentAnnotation;
                newAnnotation.bbType = tag;
                newAnnotation.colour = this.state.currentColour;

                if (customTag) {
                    newAnnotation.customTag = customTag;
                    newAnnotation.bbType = -1;
                }
            }
            annotations.push(newAnnotation);
        }

        this.setState(
            {
                annotations: annotations,
                selectingBoundsType: false,
                customAnnotationInput: "",
                newTextInput: "",
                firstRender: true,
                currentAnnotation: null,
            },
            function () {
                this.save(true);
            },
        );
    };

    removeAnnotation = (id) => {
        const _this = this;
        let newAnnotations = _.clone(this.state.annotations);
        newAnnotations = newAnnotations.filter((ann) => {
            return ann.id !== id;
        });

        this.setState(
            {
                annotations: newAnnotations,
                bbCustomType: "",
                editingAnnotation: false,
            },
            () => {
                _this.save();
                this.renderCanvasShapes();
            },
        );
    };

    toggleColourPicker = () => {
        this.setState({
            pickingColour: !this.state.pickingColour,
        });
    };

    onInputChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    generateUUID = () => {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : r && 0x3 | 0x8).toString(16);
        });
        return uuid;
    };

    setAnnotationMode = (mode) => {
        if (this.isToolsDisabled()) {
            return;
        }
        this.setState({
            annotateMode: mode,
        });
    };

    onFabricClick = (o) => {
        if (!this.state.canvas.getActiveObject() && this.state.currentAnnotation) {
            return;
        }
        // if new annoation is being created and other annotation cliked discard changes
        if (this.state.currentAnnotation && this.state.currentAnnotation.inProgress && o.target.id !== -1 && typeof o.target.id === "number") {
            this.closeLabelModal();
            return;
        }

        const canvas = this.state.canvas;
        canvas.selection = false;

        if (!o.target) {
            var pointer = this.state.canvas.getPointer(o.e);
            const origX = pointer.x;
            const origY = pointer.y;

            let shape = null;
            if (this.state.annotateMode === "box") {
                shape = new fabric.Rect({
                    left: origX,
                    top: origY,
                    width: pointer.x - origX,
                    height: pointer.y - origY,
                    fill: "",
                    centeredScaling: false,
                    strokeDashArray: [5, 5],
                    stroke: this.state.currentColour,
                    strokeWidth: STROKE_WIDTH + 1,
                    id: this.generateUUID(),
                    isNew: true,
                    type: "box",
                    hasBorders: false,
                    controls: {
                        ...fabric.Text.prototype.controls,
                        mtr: new fabric.Control({ visible: false }),
                        ml: new fabric.Control({ visible: false }),
                        mr: new fabric.Control({ visible: false }),
                        mb: new fabric.Control({ visible: false }),
                        mt: new fabric.Control({ visible: false }),
                    },
                    lockMovementX: true,
                    lockMovementY: true,
                    strokeUniform: true,
                    hasControls: false,
                });
            } else if (this.state.annotateMode === "circle") {
                shape = new fabric.Ellipse({
                    left: origX,
                    top: origY,
                    originX: "left",
                    originY: "top",
                    rx: pointer.x - origX,
                    ry: pointer.y - origY,
                    angle: 0,
                    fill: "",
                    strokeDashArray: [5, 5],
                    strokeWidth: STROKE_WIDTH + 1,
                    id: this.generateUUID(),
                    stroke: this.state.currentColour,
                    isNew: true,
                    type: "circle",
                    hasBorders: false,
                    controls: {
                        ...fabric.Text.prototype.controls,
                        mtr: new fabric.Control({ visible: false }),
                        ml: new fabric.Control({ visible: false }),
                        mr: new fabric.Control({ visible: false }),
                        mb: new fabric.Control({ visible: false }),
                        mt: new fabric.Control({ visible: false }),
                    },
                    lockMovementX: true,
                    lockMovementY: true,
                    strokeUniform: true,
                    hasControls: false,
                });
            } else if (this.state.annotateMode === "line") {
                const points = [pointer.x, pointer.y, pointer.x, pointer.y];
                shape = new fabric.Line(points, {
                    hasControls: false,
                    left: origX,
                    top: origY,
                    fill: "",
                    centeredScaling: false,
                    strokeDashArray: [5, 5],
                    stroke: this.state.currentColour,
                    strokeWidth: STROKE_WIDTH + 1,
                    id: this.generateUUID(),
                    isNew: true,
                    type: "line",
                    hasBorders: false,
                    strokeUniform: true,
                });
            }

            if (shape) {
                this.state.canvas.add(shape);
                this.state.canvas.setActiveObject(shape);
            }

            this.setState({
                mouseDown: true,
                startX: origX,
                startY: origY,
            });
        }

        this.state.canvas.renderAll();
    };

    onFabricMove = (o) => {
        if (this.state.mouseDown) {
            const pointer = this.state.canvas.getPointer(o.e);
            const shape = this.state.canvas.getActiveObject();

            if (this.state.annotateMode === "box") {
                if (this.state.startX > pointer.x) {
                    shape.set({ left: Math.abs(pointer.x) });
                }
                if (this.state.startY > pointer.y) {
                    shape.set({ top: Math.abs(pointer.y) });
                }

                shape.set({ width: Math.abs(this.state.startX - pointer.x) });
                shape.set({ height: Math.abs(this.state.startY - pointer.y) });
            } else if (this.state.annotateMode === "circle") {
                let rx = Math.abs(this.state.startX - pointer.x) / 2;
                let ry = Math.abs(this.state.startY - pointer.y) / 2;
                shape.set({ rx: rx, ry: ry });

                if (this.state.startX > pointer.x) {
                    shape.set({ originX: "right" });
                } else {
                    shape.set({ originX: "left" });
                }
                if (this.state.startY > pointer.y) {
                    shape.set({ originY: "bottom" });
                } else {
                    shape.set({ originY: "top" });
                }
            } else if (this.state.annotateMode === "line") {
                shape.set({
                    x2: pointer.x,
                    y2: pointer.y,
                });
            }

            this.state.canvas.renderAll();
        }
    };

    onFabricUp = (o) => {
        const shape = this.state.canvas.getActiveObject();
        if (this.state.mouseDown) {
            if (shape) {
                let validBounds = shape.width > 0 && shape.height > 0;
                if (validBounds) {
                    if (shape.type !== "text") {
                        this.setState(
                            {
                                selectingBoundsType: true,
                                selectingLine: shape.type === "line",
                            },
                            () => {
                                if (this.tagInputRef.current) {
                                    this.tagInputRef.current.focus();
                                }
                            },
                        );
                    }
                } else {
                    this.state.canvas.discardActiveObject().renderAll();
                    this.setState({
                        mouseDown: false,
                    });
                    return;
                }

                const annotation = this.fabricShapeToAnnotation(shape, true);
                annotation.inProgress = true;
                annotation.saved = false;
                this.setState({
                    currentAnnotation: annotation,
                });
            } else {
                this.setState(
                    {
                        selectingBoundsType: true,
                        selectingLine: false,
                    },
                    () => {
                        if (this.tagInputRef.current) {
                            this.tagInputRef.current.focus();
                        }
                    },
                );
            }

            const objs = this.state.canvas.getObjects();
            objs.forEach((obj, idx) => {
                obj.setCoords();
            });

            this.setState({
                mouseDown: false,
            });
            this.state.canvas.renderAll();
        }
    };

    saveDraggablePosition = (event, data) => {
        this.setState({
            draggablePosition: { x: data.x, y: data.y },
        });
    };

    selectLabel = (name, typeID) => {
        this.onTagSelect(typeID, name);
    };

    saveCustomLabel = () => {
        const label = this.state.newTextInput;

        if (label !== "") {
            this.props.dispatch(addCustomAnnotationLabel(label));
        }

        this.setState({
            customAnnotationInput: "",
        });
    };

    getAnnotationsTypes = () => {
        let annotationOptions = [];
        if (this.state.annotateMode !== "text") {
            annotationOptions = this.props.userAnnotationTypes.map((annotationType) => {
                const reviewedType = _.find(this.state.annotations, { reviewed: true, bbType: annotationType.id });
                return (
                    <Option
                        disabled={reviewedType}
                        key={annotationType.type}
                        value={annotationType.id}>
                        {annotationType.type}
                    </Option>
                );
            });
            let customAnnotations = this.props.customAnnotationLabels.map((element) => {
                return (
                    <Option
                        key={element}
                        value={element}
                        customType={element}>
                        <span className="AnnotationLabelsItem">
                            {element}
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.dispatch(removeCustomAnnotationLabel(element));
                                }}
                            />
                        </span>
                    </Option>
                );
            });

            annotationOptions = [
                <OptGroup
                    label="Preset types"
                    key={0}>
                    {annotationOptions}
                </OptGroup>,
                <OptGroup
                    label="Custom types"
                    key={1}>
                    {customAnnotations}
                </OptGroup>,
            ];
        }
        return annotationOptions;
    };

    tagContent = () => {
        if (!this.state.selectingBoundsType) {
            return null;
        }
        const canvasHeight = this.state.dimensions.height;
        const canvasWidth = this.state.dimensions.width;
        const annotationTypes = this.getAnnotationsTypes();

        let tagContentComponent = (
            <>
                <div className="AnnotationButtons">
                    <div className="AnnotationButtonsHeader">
                        <div
                            className="ColourPickerButton"
                            onClick={this.toggleColourPicker}>
                            {((this.state.canvas.getActiveObject() && this.state.canvas.getActiveObject().type !== "text") || this.state.currentAnnotation) && (
                                <div
                                    className="ColourPickerButton__Box"
                                    style={{ background: this.state.currentColour }}>
                                    <FontAwesomeIcon icon={faEyeDropper} />
                                </div>
                            )}
                        </div>
                        <div>
                            <span
                                style={{ cursor: "grab" }}
                                id="AnnotationDragHandle">
                                <FontAwesomeIcon icon={faArrowsAlt} />
                            </span>
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={this.closeLabelModal}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </div>
                    </div>
                    <div className="AnnotationButtonListOuter">
                        <div className="AnnotationButtonList">
                            <div className="AnnotationButtonContainer">
                                <Select
                                    value={this.state.newTextInput.length > 0 ? this.state.newTextInput : this.state.customAnnotationInput}
                                    style={{ width: 200 }}
                                    autoFocus={true}
                                    defaultOpen={this.state.customAnnotationInput.length === 0 && this.state.newTextInput.length === 0}
                                    showSearch={true}
                                    showArrow={false}
                                    getPopupContainer={() => this.props.fullscreenComponent.current.fullscreenComponent.current}
                                    notFoundContent={this.state.newTextInput}
                                    filterOption={(inputValue, option) => {
                                        const { children } = option.props;
                                        if (option.type.isSelectOptGroup) {
                                            return children.includes((child) => child.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);
                                        } else if (option.props.customType) {
                                            return option.props.customType.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                        } else {
                                            return children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.onInputChange({ target: { value: e } }, "customAnnotationInput");
                                    }}
                                    onSearch={(e) => {
                                        if (Math.abs(this.state.newTextInput.length - e.length) === 1) {
                                            this.setState({
                                                newTextInput: e,
                                            });
                                        }
                                    }}
                                    onSelect={(value, option) => {
                                        this.onInputChange({ target: { value: value } }, "customAnnotationInput");
                                        if (option.props.customType) {
                                            this.onTagSelect(-1, option.props.value);
                                        } else {
                                            this.onTagSelect(parseInt(value));
                                        }
                                    }}
                                    onInputKeyDown={(e) => {
                                        // check if enter preseed
                                        if (e.keyCode === 13) {
                                            // check how many results in the dropdown, if 0 treat as custom annotation
                                            let filteredList_new = _.flatten(
                                                _.map(annotationTypes, (type) => {
                                                    return _.filter(
                                                        type.props.children,
                                                        (type) => type.key.toLowerCase().indexOf(this.state.newTextInput.toLowerCase()) >= 0,
                                                    );
                                                }),
                                            );

                                            // save as custom annotation if there is no
                                            if (filteredList_new.length === 0) {
                                                this.onTagSelect(-1, this.state.newTextInput);
                                            }
                                        }
                                    }}
                                    onFocus={() => {
                                        //below is a workaround to have input ready for input when annoation component is rendered as it only gets focused but not focused on input
                                        if (this.state.customAnnotationInput.length === 0 && this.state.newTextInput.length === 0) {
                                            if (this.state.firstRender) {
                                                emulateTab();
                                                this.setState({ firstRender: false });
                                            }
                                        }
                                    }}>
                                    {annotationTypes}
                                </Select>
                            </div>
                            <Button
                                disabled={!this.state.newTextInput}
                                type="primary"
                                onClick={_.partial(this.onTagSelect, -1, this.state.newTextInput)}>
                                Save
                            </Button>
                            <span
                                className="AnnotationAddButton"
                                title="Click to add to your list">
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    onClick={this.saveCustomLabel}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );

        if (this.state.pickingColour || this.state.selectingLine) {
            tagContentComponent = (
                <>
                    <div className="AnnotationButtons">
                        <div className="AnnotationButtonsHeader">
                            <div
                                className="BackToTagsContainer"
                                onClick={this.toggleColourPicker}>
                                {!this.state.selectingLine && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="BackIcon"
                                        />
                                        <p className="BackText">Tags</p>
                                    </>
                                )}
                            </div>

                            <div>
                                <span
                                    style={{ cursor: "grab" }}
                                    id="AnnotationDragHandle">
                                    <FontAwesomeIcon icon={faArrowsAlt} />
                                </span>
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={this.closeLabelModal}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <CustomColorPicker
                        color={this.state.currentColour}
                        onChangeComplete={this.onColorChange}
                        colors={["#ffeb3b", "#319c1c", "#fc0303", "#03a9f4", "#444444"]}
                    />
                </>
            );
        }

        return (
            <Draggable
                handle="#AnnotationDragHandle"
                enableUserSelectHack={false}
                onStop={(event, data) => this.saveDraggablePosition(event, data)}
                position={_.isEmpty(this.state.draggablePosition) ? { x: canvasWidth / 2 - 320, y: canvasHeight / 2 - 40.5 } : this.state.draggablePosition}
                bounds="parent"
                disabled={false}>
                <div className="AnnotationPicker">{tagContentComponent}</div>
            </Draggable>
        );
    };

    openSnapshot = () => {
        if (this.isToolsDisabled()) {
            return;
        }
        this.state.canvas.discardActiveObject().renderAll();
        this.props.dispatch(toggleSnapshot());
    };

    isToolsDisabled = () => {
        return this.state.selectingBoundsType;
    };

    isLoading = () => {
        return !this.state.imageData || this.state.imageDataIndex !== this.props.currentIndex || this.state.imageDataFrame !== this.props.currentFrame;
    };

    render() {
        let imageToAnnotate = "about/blank";

        if (this.state.imageData) {
            imageToAnnotate = this.state.imageData;
        }

        let height = 0;
        let width = 0;

        if (this.state.dimensions) {
            height = this.state.dimensions.height;
            width = this.state.dimensions.width;
        }

        const toolsDisabled = this.isToolsDisabled();

        return (
            <>
                <div className="AnnotationFrame">
                    <div className="TopToolbar TopToolbar--annotation">
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon" + (this.isPreviousDisabled ? " Disabled" : "")}
                                onClick={this.navigatePrevious}>
                                <FontAwesomeIcon icon={faStepBackward} /> Previous Image
                            </span>
                        </div>
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon" + (this.isPreviousAnnotationDisabled ? " Disabled" : "")}
                                onClick={this.navigatePreviousAnnotation}>
                                <FontAwesomeIcon icon={faFastBackward} /> Previous Annotation
                            </span>
                        </div>
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon" + (this.isNextAnnotationDisabled ? " Disabled" : "")}
                                onClick={this.navigateNextAnnotation}>
                                <FontAwesomeIcon icon={faFastForward} /> Next Annotation
                            </span>
                        </div>
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon" + (this.isNextFrameDisabled ? " Disabled" : "")}
                                onClick={this.navigateNext}>
                                <FontAwesomeIcon icon={faStepForward} /> Next Image
                            </span>
                        </div>
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon"}
                                onClick={this.close}>
                                <FontAwesomeIcon icon={faTimes} /> Close
                            </span>
                        </div>
                    </div>
                    <div
                        className="AnnotationImageFrame"
                        ref={this.displayDiv}>
                        <div
                            className={"FullScreenIconOverlay Top Right"}
                            onClick={() => this.props.dispatch(toggleFullscreen())}>
                            <div className="Image-Fullscreen-Icon">
                                <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                            </div>
                        </div>

                        {this.tagContent()}

                        <div className="AnnotationImageContainer">
                            <Measure
                                bounds
                                onResize={(contentRect) => {
                                    this.setState({ dimensions: contentRect.bounds });
                                }}>
                                {({ measureRef }) => {
                                    if (!this.state.imageData) {
                                        return null;
                                    }

                                    return (
                                        <>
                                            <img
                                                ref={measureRef}
                                                className="AnnotationImage"
                                                src={imageToAnnotate}
                                                alt="Placeholder"
                                                crossOrigin={"anonymous"}
                                            />
                                            <canvas
                                                ref={(c) => (this.fabricCanvas = c)}
                                                className="AnnotationFabricCanvas"
                                                width={width}
                                                height={height}
                                            />
                                            {this.isLoading() && (
                                                <div className="AnnotationSpinner">
                                                    <OBCSpinner
                                                        size={90}
                                                        speed={3}
                                                        color={"#e8dfff"}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            </Measure>

                            <div className="ThreeDFeatureOverlayContainer">
                                <ThreeDFeatureOverlay />
                            </div>
                        </div>
                        <div className="AnnotationToolContainer">
                            {this.state.isShapeSelected && (
                                <>
                                    <Tooltip title="Delete Annotation">
                                        <span onClick={this.deleteAnnotation}>
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                                className={"EditToolIcon" + (!this.state.isShapeSelected ? " Disabled" : " Red")}
                                            />
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Edit Annotation">
                                        <span onClick={this.openEditPopup}>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className={"EditToolIcon" + (!this.state.isShapeSelected ? " Disabled" : " Orange")}
                                            />
                                        </span>
                                    </Tooltip>
                                </>
                            )}

                            {!this.state.isShapeSelected && (
                                <>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className={"EditToolIcon" + (!this.state.isShapeSelected ? " Disabled" : " Red")}
                                        />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className={"EditToolIcon" + (!this.state.isShapeSelected ? " Disabled" : " Orange")}
                                        />
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="AnnotationFilterContainer">
                            {_.map(this.props.userAnnotationNames, (name, index) => (
                                <Checkbox
                                    className="AnnotationFilter"
                                    key={index}
                                    value={name}
                                    checked={_.includes(this.state.annotationUsers, name)}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            this.setState({
                                                annotationUsers: _.filter(this.state.annotationUsers, (user) => user !== name),
                                            });
                                        } else {
                                            this.setState({
                                                annotationUsers: [...this.state.annotationUsers, name],
                                            });
                                        }
                                    }}>
                                    {name}
                                </Checkbox>
                            ))}
                        </div>
                    </div>
                    <div className="BottomToolbar BottomToolbar--markup">
                        <Tooltip
                            visible={this.state.showSaveSuccess}
                            title={"Changes Saved"}>
                            <div className="MarkupIconOuter" />
                        </Tooltip>

                        <div
                            className="MarkupIconOuter"
                            title={this.props.forceAnnotationsToBePublic ? "In this workspace annotations are public and can't be changed" : null}>
                            <Tooltip title={this.state.privateAnnotations ? "Make annotations public" : "Make annotations private"}>
                                <span
                                    className={"AnnotationModeIcon" + (this.props.forceAnnotationsToBePublic ? " Disabled" : toolsDisabled ? " Disabled" : "")}
                                    onClick={() => this.togglePrivacy()}>
                                    <FontAwesomeIcon icon={this.state.privateAnnotations ? faLock : faLockOpen} />
                                </span>
                            </Tooltip>
                        </div>

                        <div className="MarkupIconOuter">
                            <span
                                className={"AnnotationModeIcon" + (this.state.annotateMode === "box" ? " Active" : "") + (toolsDisabled ? " Disabled" : "")}
                                onClick={() => this.setAnnotationMode("box")}>
                                <FontAwesomeIcon icon={faVectorSquare} />
                            </span>
                        </div>

                        <div className="MarkupIconOuter">
                            <span
                                className={"AnnotationModeIcon" + (this.state.annotateMode === "circle" ? " Active" : "") + (toolsDisabled ? " Disabled" : "")}
                                onClick={() => this.setAnnotationMode("circle")}>
                                <FontAwesomeIcon icon={faCircle} />
                            </span>
                        </div>

                        <div className="MarkupIconOuter">
                            <span
                                className={"AnnotationModeIcon" + (this.state.annotateMode === "line" ? " Active" : "") + (toolsDisabled ? " Disabled" : "")}
                                onClick={() => this.setAnnotationMode("line")}>
                                <FontAwesomeIcon icon={faHorizontalRule} />
                            </span>
                        </div>

                        <div className="MarkupIconOuter">
                            <span
                                className={"AnnotationModeIcon" + (this.state.annotateMode === "text" ? " Active" : "") + (toolsDisabled ? " Disabled" : "")}
                                onClick={() => this.setAnnotationMode("text")}>
                                <FontAwesomeIcon icon={faFont} />
                            </span>
                        </div>

                        <div className={"MarkupIconOuter"}>
                            <span
                                className={"MeasurementIcon" + (toolsDisabled ? " Disabled" : "")}
                                onClick={() => this.openSnapshot()}>
                                {" "}
                                <FontAwesomeIcon icon={faCamera} />
                                Snapshot
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const routeID = state.playlist.data.routeID;
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);
    // const videoKey = getCurrentVideoKey(state.playlist);
    const currentFrame = getCurrentFrame(state.playlist);
    const videoKey = getCurrentVideoKey(state.playlist);
    const baseURL = _.get(state.playlist.data, ["mpdURLs", `snapshots`]);
    const imageKeys = _.get(state.playlist.data, ["video", sourceIndex], []);
    let currentDashboard = undefined;
    const userIsAdmin = state.permissions.admin;

    if (state.dashboards) {
        // this could be null, depending on timings?
        let dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        currentDashboard = state.dashboards[dashboardIndex];
    }
    const forceAnnotationsToBePublic = _.get(currentDashboard, ["workspace_layout"], false) === "driver_training" && userIsAdmin;

    const userAnnotationNames = _.uniq(_.map(state.userAnnotations, (annotation) => _.get(annotation, "name")));

    return {
        sessionID: routeID,
        userAnnotationTypes: state.userAnnotationTypes,
        userAnnotations: state.userAnnotations,
        video,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        sourceIndex,
        videoKey,
        userIsAdmin: userIsAdmin,
        currentFrame: currentFrame,
        currentIndex: state.playlist.position.currentIndex,
        requestedIndex: state.playlist.position.requestedIndex,
        offset: state.playlist.position.currentTimeOffset,
        fullscreen: state.fullscreen,
        customAnnotationLabels: state.customAnnotationLabels,
        imageKeys: imageKeys,
        baseURL,
        currentDashboard,
        privateByDefault: forceAnnotationsToBePublic ? false : _.get(state.userDetails.userConfig, ["default_content_privacy"], false),
        forceAnnotationsToBePublic: forceAnnotationsToBePublic,
        userAnnotationNames,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(AnnotationInterface);
