import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import CalibrationOverlay from "./measurement/CalibrationOverlay";
import CalibrationOverlayV2 from "./measurement/CalibrationOverlayV2";
import MeasureOverlay from "./measurement/MeasureOverlay";
import CurvesOverlay from "./measurement/RouteOverlay";
import MeasureInterface from "./measurement/MeasureInterface";
import CableMeasureOverlay from "./measurement/CableMeasureOverlay";
import SketchInterface from "./SketchInterface";
import SketchOverlay from "./SketchOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currentPlaylistPosition, deleteSketch, logEvent, logTiming, saveSketch, toggleUnsavedChange, toggleFullscreen } from "../../../redux/actions/index";
import { Modal, notification } from "antd";
import {
    get_source_calibration,
    getAbsoluteTimestamp,
    getCurrentFrame,
    getCurrentVideoKey,
    getInterpolatedPosition,
    getOffsetAdjustedTime,
    getStreamFOVs,
} from "../../util/PlaylistUtils";
import MetadataFeed from "../../route/MetadataFeed";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { WithAspectRatio } from "../../util/WithAspectRatio";
import ThreeDFeatureOverlay from "./3d/3dFeatureOverlay";

class ToolInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.withAspectRatioRef = React.createRef();

        this.state = {
            timeInterfaceEntered: 0,
            currentMode: "draw",
            measureMode: -1,
            showingTeam: true,
            drawMode: "pencil",
            sketchChangeMade: false,
            showingColors: false,
            currentColor: "",
            currentSketch: "",
            isPrivate: false,
            calibrationOpenFromOverlayPopover: false,
            calibrationWalkThroughOpen: false,
        };

        this.sketchOverlayRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSketch !== this.props.currentSketch) {
            this.setPrivateFromSketch();
        }
    }

    componentDidMount() {
        this.setState({
            timeInterfaceEntered: new Date().getTime(),
        });
        this.setPrivateFromSketch();
        this.props.dispatch(logEvent("Markup", "Enter Sketching Interface"));

        // if openMeasureCalibrationMode passed and true open calibration mode
        if (this.props.openMeasureCalibrationMode) {
            this.setState({
                measureMode: 0,
                calibrationOpenFromOverlayPopover: true,
                calibrationWalkThroughOpen: true,
            });
        }
    }

    componentWillUnmount() {
        console.log("Unmounted and sent ga");
        this.props.dispatch(logEvent("Markup", "Exit Sketching Interface"));
        logTiming("Markup", "Time Spent Marking Up", new Date().getTime() - this.state.timeInterfaceEntered);
    }

    setPrivateFromSketch = () => {
        if (this.props.currentSketch != null && "private" in this.props.currentSketch) {
            this.setState({ isPrivate: this.props.currentSketch.private });
        } else {
            this.setState({ isPrivate: false });
        }
    };

    changeMeasureMode = (mode) => {
        this.hideColors();
        this.setState({
            measureMode: mode,
        });
    };

    clearCanvas = () => {
        if (!this.state.sketchChangeMade && !this.state.currentSketch) {
            return;
        }
        this.hideColors();
        console.log("sketchOverlayRef", this.sketchOverlayRef);
        this.sketchOverlayRef.current.clearUserCanvas();
    };

    onColorChange = (color, evt) => {
        this.setState({
            currentColor: color.hex,
        });
    };

    toggleTeam = () => {
        this.setState({
            showingTeam: !this.state.showingTeam,
            showingColors: false,
        });
    };

    toggleColors = () => {
        this.setState({
            showingColors: !this.state.showingColors,
            showingTeam: false,
        });
    };

    hideColors = () => {
        this.setState({
            showingColors: false,
        });
    };

    handleSave = () => {
        if (!this.state.sketchChangeMade) {
            return;
        }

        this.hideColors();

        let sessionKey = this.props.session.uuid;
        let deviceKey = this.props.session.device_uuid;
        let sessionID = this.props.session.id;

        if (this.state.currentSketch) {
            let pngData = this.sketchOverlayRef.current.getCanvasData();
            this.props.dispatch(
                saveSketch(this.props.videoKey, this.props.frame, pngData, sessionKey, deviceKey, sessionID, this.state.isPrivate, this.state.currentColor),
            );
            notification.success({ message: "Your sketch has been saved" });
        } else {
            console.log("Deleting sketch");
            this.props.dispatch(deleteSketch(this.props.videoKey, this.props.frame, sessionKey, deviceKey, sessionID));
            notification.success({ message: "Your sketch has been deleted" });
        }
        this.setChangeMade(false);
    };

    changeSketchMode = (mode) => {
        console.log("Setting mode to ", mode);
        this.hideColors();
        this.setState({
            drawMode: mode,
        });
    };

    setChangeMade = (changeMade) => {
        this.props.dispatch(toggleUnsavedChange(changeMade));
        this.setState({
            sketchChangeMade: changeMade,
        });
    };

    setCurrentSketch = (sketch) => {
        this.setState({
            currentSketch: sketch,
        });
    };

    resetCanvas = () => {
        this.hideColors();
        this.sketchOverlayRef.current.initialiseSketches();
    };

    togglePrivate = () => {
        this.setChangeMade(true);
        this.setState((state) => ({ isPrivate: !state.isPrivate }));
    };

    get imageURL() {
        if (this.props.index !== -1) {
            let selectedKey = this.props.imageKeys[this.props.index];
            if (selectedKey) {
                let imageKey = selectedKey[0];
                const frameCount = selectedKey[5];
                if (frameCount) {
                    imageKey += "-" + this.props.frame;
                }
                imageKey += ".jpg";

                let imageURL = this.props.baseURL + imageKey;
                if (this.props.csrfToken) {
                    imageURL += `?csrf=${this.props.csrfToken}`;
                }
                return imageURL;
            }
        }
        return null;
    }

    resetFrame = () => {
        let selectedKey = this.props.imageKeys[this.props.index];
        const newTime = selectedKey[1];
        let offsetTime = getOffsetAdjustedTime(newTime, this.props.offsets);
        let position = getInterpolatedPosition(offsetTime, this.props.imageKeys, this.props.use_snapped);
        this.props.dispatch(currentPlaylistPosition(this.props.index, position, 0));
    };

    showModal = () => {
        const _this = this;
        if (!this.props.currentSketch) {
            Modal.confirm({
                title: "Sketch privacy",
                content: "Would you like this annotation to be private?",
                onOk() {
                    _this.setState({ isPrivate: true }, _this.handleSave);
                },
                onCancel() {
                    _this.setState({ isPrivate: false }, _this.handleSave);
                },
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                getContainer: this.props.fullscreenComponent.current.fullscreenComponent.current,
            });
        } else {
            this.handleSave();
        }
    };

    hideCalibrationWalkThroughModal = () => {
        this.setState({
            calibrationWalkThroughOpen: false,
        });
    };

    openCalibrateWalkThrouModel = () => {
        this.setState({
            calibrationWalkThroughOpen: true,
        });
    };

    render() {
        let overlay = null;

        if (this.props.toolMode === "measure") {
            if (this.state.measureMode === 0) {
                overlay = (
                    <MetadataFeed
                        items={["VIDEO_FOV"]}
                        lowerOffset={3600}
                        upperOffset={3600}
                        stepSize={1800}>
                        <CalibrationOverlay
                            className="measureCanvas"
                            changeMode={this.changeMeasureMode}
                            fullscreenComponent={this.props.fullscreenComponent}
                            hideCalibrationWalkThroughModal={this.hideCalibrationWalkThroughModal}
                            openCalibrateWalkThrouModel={this.openCalibrateWalkThrouModel}
                            calibrationOpenFromOverlayPopover={this.state.calibrationOpenFromOverlayPopover}
                            calibrationWalkThroughOpen={this.state.calibrationWalkThroughOpen}
                            withAspectRatioRef={this.withAspectRatioRef}
                        />
                    </MetadataFeed>
                );
            } else if (this.state.measureMode === 1) {
                overlay = <CurvesOverlay className="measureCanvas" />;
            } else if (this.state.measureMode === 2) {
                overlay = <MeasureOverlay className="measureCanvas" />;
            } else if (this.state.measureMode === 4) {
                overlay = (
                    <CableMeasureOverlay
                        className="measureCanvas"
                        videoKey={this.props.videoKey}
                        frame={this.props.frame}
                    />
                );
            } else if (this.state.measureMode === 5) {
                overlay = (
                    <MetadataFeed
                        items={["VIDEO_FOV"]}
                        lowerOffset={3600}
                        upperOffset={3600}
                        stepSize={1800}>
                        <CalibrationOverlayV2
                            className="measureCanvas"
                            changeMode={this.changeMeasureMode}
                            fullscreenComponent={this.props.fullscreenComponent}
                        />
                    </MetadataFeed>
                );
            }
        } else if (this.props.toolMode === "draw") {
            overlay = (
                <SketchOverlay
                    ref={this.sketchOverlayRef}
                    videoKey={this.props.videoKey}
                    frame={this.props.frame}
                    showingTeam={this.state.showingTeam}
                    currentColor={this.state.currentColor}
                    sketchMode={false}
                    setChangeMade={this.setChangeMade}
                    setCurrentSketch={this.setCurrentSketch}
                    closeColourPicker={this.hideColors}
                    isPrivate={this.state.isPrivate}
                    togglePrivate={this.togglePrivate}
                    onColorChange={this.onColorChange}
                />
            );
        }

        return (
            <React.Fragment>
                <div
                    className="MeasurementFrame"
                    ref={this.withAspectRatioRef}>
                    <div className={"FullScreenIconOverlay Top Right"}>
                        <div
                            className="Image-Fullscreen-Icon"
                            onClick={() => this.props.dispatch(toggleFullscreen())}>
                            <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                        </div>
                    </div>
                    <WithAspectRatio ratio={1 / this.props.aspectRatio}>
                        <div className="MeasurementImageFrame">
                            <img
                                className="MarkupImage"
                                src={this.imageURL}
                                crossOrigin={"anonymous"}
                                alt="Placeholder"
                                onError={this.resetFrame}
                            />
                            {overlay}
                        </div>
                        <div className="ThreeDFeatureOverlayContainer">
                            <ThreeDFeatureOverlay />
                        </div>
                    </WithAspectRatio>
                    {this.props.toolMode === "measure" && (
                        <MeasureInterface
                            mode={this.state.measureMode}
                            changeMode={this.changeMeasureMode}
                            fullscreenComponent={this.props.fullscreenComponent}
                        />
                    )}
                    {this.props.toolMode === "draw" && (
                        <SketchInterface
                            clearCanvas={this.clearCanvas}
                            handleSave={this.showModal}
                            showingTeam={this.state.showingTeam}
                            toggleTeam={this.toggleTeam}
                            showingColors={this.state.showingColors}
                            toggleColors={this.toggleColors}
                            currentColor={this.state.currentColor}
                            onColorChange={this.onColorChange}
                            changeSketchMode={this.changeSketchMode}
                            sketchMode={this.state.drawMode}
                            sketchChangeMade={this.state.sketchChangeMade}
                            currentSketch={this.state.currentSketch}
                            resetCanvas={this.resetCanvas}
                            fullscreenComponent={this.props.fullscreenComponent}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const routeID = state.playlist.data.routeID;
    const sourceIndex = state.playlist.position.sourceIndex;
    const baseURL = _.get(state.playlist.data, ["mpdURLs", `snapshots`]);
    const imageKeys = _.get(state.playlist.data, ["video", sourceIndex], []);
    const index = state.playlist.position.currentIndex;
    const videoKey = getCurrentVideoKey(state.playlist);
    const frame = getCurrentFrame(state.playlist);
    const currentSketch = state.userSketches.find((sketch) => {
        return sketch.video_key === videoKey && sketch.frame === frame && sketch.is_users_sketch;
    });
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }

    let offsets = [];

    if (routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }

    const timestamp = getAbsoluteTimestamp(state.playlist);

    let aspectRatio;

    const calibration = get_source_calibration(
        state.measurement.calibration,
        sourceIndex,
        timestamp * 1000,
        state.userDetails.userConfig.super_admin,
        defaultRailSeparation,
    );
    if (calibration.aspectIsKnown) {
        aspectRatio = calibration.aspectRatio;
    } else {
        const session = _.get(state.sessions, state.playlist.data.routeID, false);
        let fovsForIndex = getStreamFOVs(state.playlist, state.routeMetadata, session, sourceIndex);

        let deviceFov = null;

        if (fovsForIndex.length > 0) {
            deviceFov = Math.max(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
            let vFov = Math.min(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
            aspectRatio = Math.tan((Math.PI * vFov) / 360) / Math.tan((Math.PI * deviceFov) / 360);
        } else {
            aspectRatio = 0.5625;
        }
    }

    return {
        baseURL,
        session: state.sessions[routeID],
        videoKey,
        imageKeys,
        currentSketch,
        toolMode: state.markup.tool_mode,
        index,
        frame,
        offsets,
        use_snapped: state.snappedRoute || false,
        fullscreen: state.fullscreen,
        aspectRatio,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ToolInterface);
