import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useCookies } from "react-cookie";

const userConfigSelector = (state) => _.get(state.userDetails, ["userConfig"], {});
const userOnTrialSelector = (state) => state.userDetails.userConfig.trial_user;

const ExpiryBanner = () => {
    const [cookies, setCookie] = useCookies(["name"]);
    const userConfig = useSelector(userConfigSelector);
    const userOnTrial = useSelector(userOnTrialSelector);

    const [bannerClosed, setBannerClosed] = useState(cookies.trialBannerClosed);

    const expiryDays = useMemo(() => {
        const expiry = userConfig.expiry + 60 * 60 * 24;
        const now = Date.now() / 1000;

        const difference = expiry - now;
        const daysRemaining = difference / (60 * 60 * 24);

        return Math.ceil(daysRemaining);
    }, [userConfig.expiry]);

    const closeBanner = () => {
        const expiry = new Date();
        expiry.setDate(expiry.getDate() + 1);
        expiry.setHours(0, 0, 0, 0);

        setCookie("trialBannerClosed", true, { path: "/", expires: expiry });
        setBannerClosed(true);
    };

    if (!userConfig.expiry || bannerClosed) {
        return null;
    }

    let messageTitle = "License Renewal";
    let messageType = "license";
    let buttonText = "License Renewal";
    let mailto =
        "mailto:support@onebigcircle.co.uk?subject=AIVR%20License%20Renewal&body=Dear AIVR Support,%0D%0A %0D%0AI would like to retain access to AIVR, please advise on how to obtain a license renewal.";
    if (userOnTrial) {
        messageTitle = "Trial Expiry";
        messageType = "trial";
        buttonText = "Full License";
        mailto =
            "mailto:support@onebigcircle.co.uk?subject=AIVR%20Full%20License%20Request&body=Dear AIVR Support,%0D%0A %0D%0AI would like to retain access to AIVR, please advise on how to obtain a full license.";
    }

    return (
        <div className="messageBanner">
            <h5 className="messageBannerText title">{messageTitle}</h5>
            {userConfig.expiry + 60 * 60 * 24 > Date.now() / 1000 ? (
                <p className="messageBannerText">
                    Your {messageType} expires in {expiryDays} {expiryDays > 1 ? "days" : "day"}
                </p>
            ) : (
                <p className="messageBannerText">Your {messageType} has expired - your account will be deactivated in the next 24 hours.</p>
            )}

            <div className="messageBannerButtonContainer">
                <button
                    className="messageBannerButton dismiss"
                    onClick={closeBanner}>
                    Dismiss
                </button>
                <button className="messageBannerButton solid">
                    <a
                        href={mailto}
                        target="_blank">
                        Request {buttonText}
                    </a>
                </button>
            </div>
        </div>
    );
};

export default ExpiryBanner;
