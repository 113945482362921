import { Cylinder } from "@react-three/drei";
import React from "react";

export default function Datum() {
    return (
        <group>
            <Cylinder
                args={[0.05, 0.05, 10, 16, 1, false, 0, Math.PI]}
                rotation={[0, 0, Math.PI / 2]}>
                <meshStandardMaterial color="#f08000" />
            </Cylinder>
        </group>
    );
}
