import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
    addDetectionsReviewFilter,
    removeDetectionsReviewFilter,
    addDetectionsTypeFilter,
    removeDetectionsTypeFilter,
    setSelectedRailInspectionImage,
    setCurrentDetection,
    setCurrentInspectionMarkup,
    customAudit,
    setDetectionConditionFilters,
    setDipAngleFilter,
} from "../../../../redux/actions/index";
import { Button, Checkbox, Modal, Slider } from "antd";

const detectionReviewFiltersSelector = (state) => state.railInspection.detections.reviewFilters;
const detectionTypesSelector = (state) => state.railInspection.detections.types;
const detectionTypeFiltersSelector = (state) => state.railInspection.detections.typeFilters;
const firstRailInspectionImagesSelector = (state) => _.get(state.railInspection.railInspectionImages, "data", []);
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const detectionConditionFiltersSelector = (state) => state.railInspection.detections.detectionConditionFilters || [];
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;
const sessionIDSelector = (state) => state.playlist.data.routeID;
const conditionTypesSelector = (state) => state.railInspection.inspectionConditionTypes;
const detectionsDataSelector = (state) => state.railInspection.detections.data;
const dipAngleFilterSelector = (state) => state.railInspection.dipAngleFilter;

const DetectionsFiltersModal = ({ detectionsFiltersOpen, setDetectionsFiltersOpen, setNumberOfFilters }) => {
    let dispatch = useDispatch();

    const detectionReviewFilters = useSelector(detectionReviewFiltersSelector);
    const detectionTypes = useSelector(detectionTypesSelector);
    const detectionTypeFilters = useSelector(detectionTypeFiltersSelector);
    const selectedRailInspection = useSelector(selectedRailInspectionImageSelector);
    const firstRailInspectionImages = useSelector(firstRailInspectionImagesSelector);
    const detectionConditionFilters = useSelector(detectionConditionFiltersSelector);
    const superAdmin = useSelector(superAdminSelector);
    const conditionTypes = useSelector(conditionTypesSelector);
    const sessionID = useSelector(sessionIDSelector);
    const detectionsData = useSelector(detectionsDataSelector);
    const dipAngleFilter = useSelector(dipAngleFilterSelector);

    const [selectAllDetectionChecked, setSelectAllDetectionChecked] = useState(true);
    const [totalNumberOfFilters, setTotalNumberOfFilters] = useState(false);

    const filteredDetectionTypes = useMemo(() => {
        if (superAdmin) {
            return detectionTypes;
        } else {
            dispatch(removeDetectionsTypeFilter("Cracked Rail"));
            return _.filter(detectionTypes, (type) => type !== "Cracked Rail");
        }
    }, [detectionTypes, superAdmin, dispatch]);

    const selectDetectionReview = (detectionReview) => {
        if (_.includes(detectionReviewFilters, detectionReview)) {
            dispatch(removeDetectionsReviewFilter(detectionReview));
        } else {
            dispatch(addDetectionsReviewFilter(detectionReview));
        }
    };

    const selectDetectionType = useCallback(
        (detectionType) => {
            if (_.includes(detectionTypeFilters, detectionType)) {
                dispatch(removeDetectionsTypeFilter(detectionType));
            } else {
                dispatch(addDetectionsTypeFilter([...detectionTypeFilters, detectionType]));
                dispatch(
                    customAudit(
                        "inspection_detections_navigator.filter",
                        {
                            detectionType: detectionType,
                            session_id: sessionID,
                        },
                        `Inspection detections navigator filter changed: ${detectionType}`,
                    ),
                );
            }
        },
        [detectionTypeFilters, dispatch, sessionID],
    );

    const toggleAllDetectionsType = useCallback(
        (checked) => {
            setSelectAllDetectionChecked(checked);

            if (checked) {
                const filtersToAdd = [];
                _.forEach(filteredDetectionTypes, (type) => {
                    if (!_.find(detectionTypeFilters, type)) {
                        filtersToAdd.push(type);
                    }
                });
                dispatch(addDetectionsTypeFilter(filtersToAdd));
            } else {
                _.forEach(detectionTypeFilters, (filter) => {
                    dispatch(removeDetectionsTypeFilter(filter));
                });
            }
        },
        [detectionTypeFilters, dispatch, filteredDetectionTypes],
    );

    useEffect(() => {
        setSelectAllDetectionChecked(_.every(filteredDetectionTypes, (filter) => _.includes(detectionTypeFilters, filter)));
    }, [filteredDetectionTypes, detectionTypeFilters]);

    const detectionFilters = useMemo(() => {
        if (filteredDetectionTypes && filteredDetectionTypes.length) {
            return (
                <div className="detectionCondition">
                    <Checkbox
                        className="detectionConditionLabel detectionReviewFilter-Checkbox-Select"
                        key={"select-all"}
                        checked={selectAllDetectionChecked}
                        onChange={(e) => toggleAllDetectionsType(e.target.checked)}>
                        {selectAllDetectionChecked ? "Deselect" : "Select"} all
                    </Checkbox>
                    <div className="detectionConditionList">
                        {filteredDetectionTypes.sort().map((name, idx) => {
                            return (
                                <Checkbox
                                    className="detectionReviewFilter-Checkbox"
                                    key={idx}
                                    checked={_.includes(detectionTypeFilters, name)}
                                    onChange={() => selectDetectionType(name)}>
                                    {name}
                                </Checkbox>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return <span>No Detections</span>;
        }
    }, [detectionTypeFilters, selectAllDetectionChecked, filteredDetectionTypes, selectDetectionType, toggleAllDetectionsType]);

    const toggleDetectionCondition = useCallback(
        (e, condition, type) => {
            let listToUpdate = _.get(detectionConditionFilters, [type, condition]);

            if (e.target.checked) {
                listToUpdate = _.uniq([...listToUpdate, e.target.value]);
            } else {
                listToUpdate = _.filter(listToUpdate, (item) => item !== e.target.value);
            }

            const updatedConditionFilters = _.cloneDeep(detectionConditionFilters);
            updatedConditionFilters[type][condition] = listToUpdate;

            dispatch(setDetectionConditionFilters(updatedConditionFilters));
        },
        [detectionConditionFilters, dispatch],
    );

    const toggleAllDetectionConditions = useCallback(
        (e, condition, type, dataValues) => {
            const updatedConditionFilters = _.cloneDeep(detectionConditionFilters);

            if (e.target.checked) {
                const listToUpdate = Object.keys(dataValues).map((value) => (typeof dataValues[value] === "string" ? dataValues[value] : value));
                listToUpdate.push("none");
                updatedConditionFilters[type][condition] = listToUpdate;
            } else {
                updatedConditionFilters[type][condition] = [];
            }

            dispatch(setDetectionConditionFilters(updatedConditionFilters));
        },
        [detectionConditionFilters, dispatch],
    );

    const setDipAngle = useCallback(
        (e) => {
            dispatch(setDipAngleFilter(e));
        },
        [dispatch],
    );

    const maxDipAngle = useMemo(() => {
        let maxValue = 30;
        const maxDipJoint = _.maxBy(
            detectionsData.filter((item) => item.name === "Joint" && _.get(item, ["analysis", "dip_angle_valid"], false)),
            "analysis.dip_angle_mrad",
        );
        const maxDip = _.get(maxDipJoint, ["analysis", "dip_angle_mrad"], 0);

        if (maxDip > maxValue) {
            setDipAngle([0, maxDip]);
            return maxDip;
        }

        return maxValue;
    }, [detectionsData, setDipAngle]);

    const disableSlider = useMemo(() => {
        const dipAngleFilters = _.get(detectionConditionFilters, ["Joint", "dip_angle"], []);
        if (_.includes(dipAngleFilters, "dip_angle_present")) {
            setDipAngle([0, maxDipAngle]);
            return false;
        }
        return true;
    }, [detectionConditionFilters, setDipAngle, maxDipAngle]);

    const conditionFilters = useMemo(() => {
        const filters = [];
        let count = 0;

        _.forEach(detectionTypeFilters, (type) => {
            if (_.includes(filteredDetectionTypes, type) && _.has(conditionTypes, type)) {
                let detectionConditions = _.get(conditionTypes, type, {});
                const detectionFilters = [];

                if (type === "Joint") {
                    detectionConditions = _.merge(
                        {
                            dip_angle: {
                                display_name: "Dip Angle",
                                data_values: {
                                    "Dip angle present": "dip_angle_present",
                                    "No dip angle found": "no_dip_angle",
                                },
                            },
                        },
                        detectionConditions,
                    );
                }

                _.forEach(Object.keys(detectionConditions), (detectionCondition) => {
                    const _detectionCondition = detectionConditions[detectionCondition];
                    const dataValues = _.get(_detectionCondition, "data_values", {});
                    const filterList = _.get(detectionConditionFilters, [type, detectionCondition], []);
                    const allSelected = Object.keys(dataValues).length + 1 === filterList.length;
                    let nullCount = 0;
                    detectionFilters.push(
                        <div className="detectionReviewConditionFilter">
                            <p>{_detectionCondition.display_name}</p>
                            <div className="detectionReviewConditionFilterContainer">
                                <Checkbox
                                    className="detectionReviewConditionCheckboxAll"
                                    onChange={(e) => toggleAllDetectionConditions(e, detectionCondition, type, dataValues, filterList)}
                                    checked={allSelected}>
                                    {allSelected ? "Deselect all" : "Select all"}
                                </Checkbox>

                                {_.map(Object.keys(dataValues), (value) => {
                                    const dataValue = typeof dataValues[value] === "string" ? dataValues[value] : value;
                                    const checked = filterList.includes(dataValue);
                                    if (checked) {
                                        count++;
                                    }
                                    let _nullCount = 0;
                                    const detectionCount = _.filter(detectionsData, (detection) => {
                                        if (detection.name !== type) {
                                            return false;
                                        }
                                        let detectionClassification = _.get(detection, ["conditions", detectionCondition, "user"], null);
                                        if (!detectionClassification) {
                                            detectionClassification = _.get(detection, ["conditions", detectionCondition, "auto"], null);
                                        }

                                        if (!detectionClassification && type === "Joint" && detectionCondition === "dip_angle") {
                                            if (detection.analysis.dip_angle_valid && detection.analysis.dip_angle_mrad > 0) {
                                                detectionClassification = "dip_angle_present";
                                            } else if (detection.analysis.dip_angle_valid && detection.analysis.dip_angle_mrad === 0) {
                                                detectionClassification = "no_dip_angle";
                                            }
                                        }

                                        if (!detectionClassification) {
                                            _nullCount++;
                                        }
                                        if (detectionClassification !== dataValue) {
                                            return false;
                                        }

                                        return true;
                                    }).length;

                                    nullCount = _nullCount;

                                    if (dataValue) {
                                        return (
                                            <Checkbox
                                                className="detectionReviewConditionCheckbox"
                                                value={dataValue}
                                                onChange={(e) => toggleDetectionCondition(e, detectionCondition, type)}
                                                checked={checked}>
                                                {`${typeof dataValues[value] === "string" ? value : _.get(dataValues[value], "display_name", value)} (${detectionCount})`}
                                            </Checkbox>
                                        );
                                    }
                                })}

                                <Checkbox
                                    className="detectionReviewConditionCheckbox"
                                    value="none"
                                    onChange={(e) => toggleDetectionCondition(e, detectionCondition, type)}
                                    checked={filterList.includes("none")}>
                                    None ({nullCount})
                                </Checkbox>

                                {type === "Joint" && detectionCondition === "dip_angle" && (
                                    <div className="detectionReviewConditionSliderContainer">
                                        <p>Dip angle (mrad)</p>
                                        <div className="SliderContainer">
                                            <p>0</p>
                                            <Slider
                                                className="detectionReviewConditionSlider"
                                                disabled={disableSlider}
                                                range
                                                value={dipAngleFilter}
                                                min={0}
                                                max={maxDipAngle}
                                                step={0.5}
                                                onChange={(e) => setDipAngle(e)}
                                                tooltipPlacement="bottom"
                                            />
                                            <p>{maxDipAngle}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>,
                    );
                });

                filters.push(
                    <div className="detectionReviewConditionType">
                        <h4>{type}</h4>
                        {detectionFilters}
                    </div>,
                );
            }
        });

        // if image position not moved on big sroller (no red arrow present) setTotalNumberOfFilters
        const filtersSum = count + detectionTypeFilters.length + detectionReviewFilters.length;
        if (filtersSum !== 2 && !selectedRailInspection.timestamp && firstRailInspectionImages.length > 0 && !totalNumberOfFilters) {
            setTotalNumberOfFilters(filtersSum);
        }

        setNumberOfFilters(filtersSum);

        if (filters.length) {
            return (
                <div className="detectionReviewCondition">
                    <h3>Conditions Filters</h3>
                    {filters}
                </div>
            );
        }

        return null;
    }, [
        conditionTypes,
        detectionTypeFilters,
        detectionConditionFilters,
        detectionReviewFilters,
        selectedRailInspection,
        firstRailInspectionImages,
        totalNumberOfFilters,
        maxDipAngle,
        detectionsData,
        dipAngleFilter,
        filteredDetectionTypes,
        setDipAngle,
        disableSlider,
        setNumberOfFilters,
        toggleAllDetectionConditions,
        toggleDetectionCondition,
    ]);

    // if filters changed in modal and inspection image is NOT selected, select first image so the nearby detections are displayed in modal
    useEffect(() => {
        if (totalNumberOfFilters && !selectedRailInspection.timestamp && firstRailInspectionImages.length > 0) {
            dispatch(setSelectedRailInspectionImage(0, firstRailInspectionImages[0], false));
            dispatch(setCurrentDetection(null));
            dispatch(setCurrentInspectionMarkup(null, null));
        }
    }, [selectedRailInspection, firstRailInspectionImages, totalNumberOfFilters]);

    return (
        <Modal
            className="RailInsectionFilterModal"
            title="Detections Filters"
            visible={detectionsFiltersOpen}
            width={600}
            onOk={() => {
                setDetectionsFiltersOpen(false);
            }}
            onCancel={() => {
                setDetectionsFiltersOpen(false);
            }}
            footer={[
                <Button
                    type="primary"
                    onClick={() => {
                        setDetectionsFiltersOpen(false);
                    }}>
                    Close
                </Button>,
            ]}>
            <div className="MarkerThresholdPopup">
                <div className="detectionReviewRow">
                    <h3>Review Status</h3>
                    <div className="detectionReviewFilter">
                        <Checkbox
                            className="detectionReviewFilter-Checkbox"
                            key={1}
                            checked={_.includes(detectionReviewFilters, 1)}
                            onChange={() => {
                                selectDetectionReview(1);
                            }}>
                            Verified
                        </Checkbox>
                        <Checkbox
                            className="detectionReviewFilter-Checkbox"
                            key={2}
                            checked={_.includes(detectionReviewFilters, 2)}
                            onChange={() => {
                                selectDetectionReview(2);
                            }}>
                            Verified & Hidden
                        </Checkbox>
                        <Checkbox
                            className="detectionReviewFilter-Checkbox"
                            key={0}
                            checked={_.includes(detectionReviewFilters, 0)}
                            onChange={() => {
                                selectDetectionReview(0);
                            }}>
                            Needs Review
                        </Checkbox>
                        <Checkbox
                            className="detectionReviewFilter-Checkbox"
                            key={3}
                            checked={_.includes(detectionReviewFilters, 3)}
                            onChange={() => {
                                selectDetectionReview(3);
                            }}>
                            Rejected
                        </Checkbox>
                    </div>
                </div>

                <div className="detectionReviewCondition">
                    <h3>Detection Type</h3>
                    {detectionFilters}
                </div>
                {conditionFilters}
            </div>
        </Modal>
    );
};

export default DetectionsFiltersModal;
