import React from "react";
import { Button, Modal } from "antd";
import OBCSpinner from "./OBC";
import { isMobile, isIE } from "react-device-detect";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class BrowserCompatibility extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            compatible: false,
        };
    }

    componentDidMount() {
        let compatible = !isIE;

        if (!isMobile) {
            // the desktop site must check for MediaSource because it plays video
            // NOTE we previously checked for AbortController here, which we no longer
            // believe is necessary
            compatible &= window.MediaSource !== undefined;
        }

        this.setState({
            checked: true,
            compatible,
        });
    }

    continueAnyway = () => {
        this.setState({
            compatible: true,
        });
    };

    render() {
        if (this.state.checked) {
            if (this.state.compatible) {
                return this.props.children;
            } else {
                return (
                    <Modal
                        title={"Browser compatibility check"}
                        visible={true}
                        closable={false}
                        maskClosable={false}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.continueAnyway}>
                                Continue Anyway
                            </Button>,
                        ]}>
                        <div className={"Center-Content Vertical"}>
                            <ExclamationCircleOutlined className={"Error-Icon"} />
                            <div>
                                <p>Your browser does not support all of the features used by this website.</p>
                                <p>We recommend you use one of the following browsers:</p>
                                <ul>
                                    <li>Mozilla Firefox</li>
                                    <li>Google Chrome</li>
                                    <li>Microsoft Edge</li>
                                    <li>OSX Safari</li>
                                </ul>
                            </div>
                        </div>
                    </Modal>
                );
            }
        } else {
            return (
                <Modal
                    title={"Browser compatibility check"}
                    visible={true}
                    closable={false}
                    maskClosable={false}
                    footer={null}>
                    <p>Your browser is being checked for compatibility, please wait...</p>
                    <div className={"Center-Content"}>
                        <OBCSpinner />
                    </div>
                </Modal>
            );
        }
    }
}

export default BrowserCompatibility;
