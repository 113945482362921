import React, { useRef, useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DraggableWrapper from "./DraggableWrapper";
import { toggleDataView } from "../../../redux/actions/index";
import FullScreenCapable from "../FullScreenCapable";
import ImageWithFallback from "../../ImageWithFallback";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import { Input } from "antd";

const selectedRailImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const currentIndexSelector = (state) => state.playlist.position.currentIndex;
const csrfTokenSelector = (state) => state.csrfToken;
const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const DetailView = ({ windowDimensions }) => {
    const fullscreenRef = useRef();
    const dispatch = useDispatch();

    const selectedRailImage = useSelector(selectedRailImageSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const currentIndex = useSelector(currentIndexSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [offset, setOffset] = useState(1);

    const railImageConfig = useMemo(() => {
        if (railImageConfigObject) {
            return railImageConfigObject;
        } else {
            return {};
        }
    }, [railImageConfigObject]);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject;
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const imagePaths = useMemo(() => {
        let paths = [];

        if (railImages && railImages.length && railImageConfig && railImageConfig.detail_cameras[0]) {
            for (let i = -1; i < 2; i++) {
                const offsetIndex = currentIndex + offset;
                const indexToUse = Math.max(Math.min(offsetIndex + i, railImages.length - 1), 0);
                const imageToUse = railImages[indexToUse];

                if (imageToUse) {
                    let base_path = imageToUse.base_path;
                    const new_base_path = base_path.replace("$DEVICE", `${railImageConfig.detail_cameras[0].source}`); //First detail camera for now
                    let imagePath = baseURL + new_base_path + "/" + imageToUse.timestamp + "_detail.jpg";
                    if (csrfToken) {
                        imagePath += `?csrf=${csrfToken}`;
                    }
                    paths.push(imagePath);
                }
            }
        }

        if (paths.length) {
            return paths;
        } else {
            return "";
        }
    }, [offset, railImages, currentIndex, railImageConfig]);

    const onOffsetChange = useCallback((e) => {
        let value = parseInt(e.target.value);

        if (!value) {
            value = 0;
            setOffset(value);
        } else {
            if (value < -50) {
                value = -50;
            }
            if (value > 50) {
                value = 50;
            }
            setOffset(value);
        }
    }, []);

    let content = (
        <div className="NoDetailImageContainer">
            <p>No detailed image available</p>
        </div>
    );

    if (selectedRailImage && selectedRailImage.detail) {
        content = (
            <>
                <div className="inspectRail__Source__Image">
                    <div className="Image-Box">
                        <FullScreenCapable
                            showExpand={true}
                            showCollapse={true}
                            fullscreenID={"detail3"}
                            ref={fullscreenRef}
                            extraStyle={{ height: "100%" }}>
                            {imagePaths && imagePaths[2] && (
                                <ImageWithFallback
                                    imageSrc={`${imagePaths[2]}`}
                                    imageAlt="detail"
                                />
                            )}
                        </FullScreenCapable>
                    </div>
                </div>

                <div className="inspectRail__Source__Image">
                    <div className="Image-Box">
                        <FullScreenCapable
                            showExpand={true}
                            showCollapse={true}
                            fullscreenID={"detail2"}
                            ref={fullscreenRef}
                            extraStyle={{ height: "100%" }}>
                            {imagePaths && imagePaths[1] && (
                                <ImageWithFallback
                                    imageSrc={`${imagePaths[1]}`}
                                    imageAlt="detail"
                                />
                            )}
                        </FullScreenCapable>
                    </div>
                </div>

                <div className="inspectRail__Source__Image">
                    <div className="Image-Box">
                        <FullScreenCapable
                            showExpand={true}
                            showCollapse={true}
                            fullscreenID={"detail1"}
                            ref={fullscreenRef}
                            extraStyle={{ height: "100%" }}>
                            {imagePaths && imagePaths[0] && (
                                <ImageWithFallback
                                    imageSrc={`${imagePaths[0]}`}
                                    imageAlt="detail"
                                />
                            )}
                        </FullScreenCapable>
                    </div>
                </div>
            </>
        );
    }

    const title = (
        <div className="inspectRail__BookmarkList__Title">
            <p>Detailed Image</p>
            <div className="inspectRail-Main__Header__Item small">
                <Input
                    type="number"
                    min={-10}
                    max={10}
                    value={offset}
                    onChange={onOffsetChange}
                    data-lpignore
                />
                <label>metres rail offset</label>
            </div>
        </div>
    );

    const closeDetailView = () => {
        dispatch(toggleDataView());
    };

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            closeAction={closeDetailView}
            customDimensions={{ x: "350px", y: "80vh" }}
        />
    );
};

export default DetailView;
