import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Select, Button, Input, notification, Divider, Checkbox } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { createMarkerExport, getMarkupExportTypes } from "../redux/actions/index";

const globalContentSelector = (state) => state.globalContent.content;
const userEmailSelector = (state) => state.userDetails.email;
const exportTypesSelector = (state) => state.markupExport;
const userAnnotationsSelector = (state) => state.userAnnotations;
const userAnnotationTypesSelector = (state) => state.userAnnotationTypes;
const userMeasurementsSelector = (state) => state.userMeasurements;

const MarkupExportModal = ({ visible, closeModal, sessionID }) => {
    const dispatch = useDispatch();

    const userEmail = useSelector(userEmailSelector);
    const exportTypes = useSelector(exportTypesSelector);
    const globalContent = useSelector(globalContentSelector);
    const userAnnotations = useSelector(userAnnotationsSelector);
    const userAnnotationTypes = useSelector(userAnnotationTypesSelector);
    const userMeasurements = useSelector(userMeasurementsSelector);

    const [exportName, setExportName] = useState("");
    const [exportRecipient, setExportRecipient] = useState(userEmail ? userEmail : "");

    const [annotationsEnabled, setAnnotationsEnabled] = useState(false);
    const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState(["all"]);
    const [selectedAnnotationOwner, setSelectedAnnotationOwner] = useState("all");

    const [measurementsEnabled, setMeasurementsEnabled] = useState(false);
    const [selectedMeasurementTypes, setSelectedMeasurementTypes] = useState(["all"]);
    const [selectedMeasurementOwner, setSelectedMeasurementOwner] = useState("all");

    const [exportTriggered, setExportTriggered] = useState(false);

    useEffect(() => {
        if (!exportTypes.fetched && !sessionID) {
            dispatch(getMarkupExportTypes());
        }
    }, [dispatch, exportTypes.fetched, sessionID]);

    const createExport = useCallback(() => {
        const filters = {};

        if (annotationsEnabled) {
            filters["annotations"] = {
                types: selectedAnnotationTypes,
                owner: selectedAnnotationOwner,
            };
        }

        if (measurementsEnabled) {
            filters["measurements"] = {
                types: selectedMeasurementTypes,
                owner: selectedMeasurementOwner,
            };
        }

        let sessionIDToUse = -1;

        if (sessionID) {
            sessionIDToUse = sessionID;
        }

        dispatch(createMarkerExport(sessionIDToUse, exportName, exportRecipient, filters, "markup"));
        setExportTriggered(true);
    }, [
        exportName,
        exportRecipient,
        dispatch,
        annotationsEnabled,
        measurementsEnabled,
        selectedAnnotationTypes,
        selectedAnnotationOwner,
        selectedMeasurementTypes,
        selectedMeasurementOwner,
        sessionID,
    ]);

    const resetFields = useCallback(() => {
        setExportName("");
        setExportRecipient(userEmail ? userEmail : "");
        setAnnotationsEnabled(false);
        setSelectedAnnotationTypes(["all"]);
        setSelectedAnnotationOwner("all");
        setMeasurementsEnabled(false);
        setSelectedMeasurementTypes(["all"]);
        setSelectedMeasurementOwner("all");
        setExportTriggered(false);
    }, [userEmail]);

    const cancelExport = useCallback(() => {
        closeModal();
        setTimeout(() => {
            resetFields();
        }, 200);
    }, [closeModal, resetFields]);

    const exportFooter = useMemo(() => {
        if (exportTriggered) {
            return [<Button onClick={cancelExport}>Close</Button>];
        }

        return [
            <Button onClick={cancelExport}>Cancel</Button>,
            <Button
                disabled={!exportName || !exportRecipient || (!annotationsEnabled && !measurementsEnabled)}
                onClick={createExport}
                type="primary">
                Export
            </Button>,
        ];
    }, [exportTriggered, cancelExport, createExport, exportName, exportRecipient, annotationsEnabled, measurementsEnabled]);

    const annotationTypes = useMemo(() => {
        if (!sessionID) {
            return exportTypes.types.annotations.map((type) => {
                return (
                    <Select.Option
                        value={type}
                        key={type}>
                        {type}
                    </Select.Option>
                );
            });
        } else {
            return _.uniq(
                userAnnotations.map((annotation) => {
                    let typeValue = annotation.custom_tag;
                    if (annotation.type !== -1) {
                        const matchingType = _.find(userAnnotationTypes, (type) => {
                            return type.id === annotation.type;
                        });
                        if (matchingType) {
                            typeValue = matchingType.type;
                        }
                    }
                    return typeValue;
                }),
            ).map((annotationType) => {
                return (
                    <Select.Option
                        value={annotationType}
                        key={annotationType}>
                        {annotationType}
                    </Select.Option>
                );
            });
        }
    }, [exportTypes.types.annotations, sessionID, userAnnotationTypes, userAnnotations]);

    const measurementTypes = useMemo(() => {
        if (!sessionID) {
            return exportTypes.types.measurements.map((type) => {
                return (
                    <Select.Option
                        value={type}
                        key={type}>
                        {type}
                    </Select.Option>
                );
            });
        } else {
            return _.uniq(
                userMeasurements.map((measurement) => {
                    const parsedMeasurement = JSON.parse(measurement.measurements);
                    return parsedMeasurement.name;
                }),
            ).map((measureType) => {
                return (
                    <Select.Option
                        value={measureType}
                        key={measureType}>
                        {measureType}
                    </Select.Option>
                );
            });
        }
    }, [exportTypes.types.measurements, sessionID, userMeasurements]);

    const onAnnotationTypeSelect = useCallback(
        (options) => {
            console.log("debug options", options);
            if (options.includes("all") && !selectedAnnotationTypes.includes("all")) {
                options = ["all"];
            } else if (selectedAnnotationTypes.includes("all") && options.length) {
                const index = options.indexOf("all");
                if (index > -1) {
                    options.splice(index, 1);
                }
            }
            setSelectedAnnotationTypes(options);
        },
        [selectedAnnotationTypes],
    );

    const onMeasurementTypeSelect = useCallback(
        (options) => {
            console.log("debug options", options);
            if (options.includes("all") && !selectedMeasurementTypes.includes("all")) {
                options = ["all"];
            } else if (selectedMeasurementTypes.includes("all") && options.length) {
                const index = options.indexOf("all");
                if (index > -1) {
                    options.splice(index, 1);
                }
            }
            setSelectedMeasurementTypes(options);
        },
        [selectedMeasurementTypes],
    );

    const modalContent = useMemo(() => {
        if (exportTriggered) {
            return <p>The files have been sent for exporting. A download link will be sent via email when the export is complete.</p>;
        } else {
            return (
                <div>
                    <h3>Export Name</h3>
                    <Input
                        value={exportName}
                        onChange={(e) => setExportName(e.target.value)}
                        placeholder="Enter export name"
                        style={{ marginBottom: 15 }}
                    />

                    <h3>Email Recipient</h3>
                    <Input
                        value={exportRecipient}
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/\s/g, "");
                            setExportRecipient(newValue);
                        }}
                        placeholder="Enter export recipient"
                        whitespace={false}
                        style={{ marginBottom: 10 }}
                    />

                    <Divider plain>
                        <Checkbox
                            style={{ marginLeft: 5 }}
                            checked={annotationsEnabled}
                            onChange={(e) => setAnnotationsEnabled(e.target.checked)}>
                            Include Annotations
                        </Checkbox>
                    </Divider>

                    <div className={annotationsEnabled ? "" : "Disabled"}>
                        <div className="exportInputDiv">
                            <h4 className="MarkupExportInputLabel">Annotation Types To export:</h4>
                            <Select
                                mode="multiple"
                                className="exportInput"
                                value={selectedAnnotationTypes}
                                style={{ width: 250 }}
                                onChange={(values) => onAnnotationTypeSelect(values)}>
                                <Select.Option
                                    value="all"
                                    key="all">
                                    All
                                </Select.Option>
                                {annotationTypes}
                            </Select>
                        </div>

                        <div className="exportInputDiv">
                            <h4 className="MarkupExportInputLabel">Export Annotations From:</h4>
                            <Select
                                className="exportInput"
                                value={selectedAnnotationOwner}
                                style={{ width: 250 }}
                                onChange={(values) => setSelectedAnnotationOwner(values)}>
                                <Select.Option
                                    value="all"
                                    key="all">
                                    Everyone
                                </Select.Option>
                                <Select.Option
                                    value="mine"
                                    key="all">
                                    Only Me
                                </Select.Option>
                            </Select>
                        </div>
                    </div>

                    <Divider plain>
                        <Checkbox
                            style={{ marginLeft: 5 }}
                            checked={measurementsEnabled}
                            onChange={(e) => setMeasurementsEnabled(e.target.checked)}>
                            Include Measurements
                        </Checkbox>
                    </Divider>

                    <div className={measurementsEnabled ? "" : "Disabled"}>
                        <div className="exportInputDiv">
                            <h4 className="MarkupExportInputLabel">Export Measurement Type:</h4>
                            <Select
                                mode="multiple"
                                className="exportInput"
                                value={selectedMeasurementTypes}
                                style={{ width: 250 }}
                                onChange={(values) => onMeasurementTypeSelect(values)}>
                                <Select.Option
                                    value="all"
                                    key="all">
                                    All
                                </Select.Option>
                                {measurementTypes}
                            </Select>
                        </div>

                        <div className="exportInputDiv">
                            <h4 className="MarkupExportInputLabel">Export Measurements From:</h4>
                            <Select
                                className="exportInput"
                                value={selectedMeasurementOwner}
                                style={{ width: 250 }}
                                onChange={(values) => setSelectedMeasurementOwner(values)}>
                                <Select.Option
                                    value="all"
                                    key="all">
                                    Everyone
                                </Select.Option>
                                <Select.Option
                                    value="mine"
                                    key="all">
                                    Only Me
                                </Select.Option>
                            </Select>
                        </div>
                    </div>
                </div>
            );
        }
    }, [
        annotationTypes,
        annotationsEnabled,
        exportName,
        exportRecipient,
        exportTriggered,
        measurementTypes,
        measurementsEnabled,
        onAnnotationTypeSelect,
        onMeasurementTypeSelect,
        selectedAnnotationOwner,
        selectedAnnotationTypes,
        selectedMeasurementOwner,
        selectedMeasurementTypes,
    ]);

    return (
        <Modal
            onCancel={closeModal}
            title="Content Export"
            visible={visible}
            footer={exportFooter}>
            {modalContent}
        </Modal>
    );
};

export default MarkupExportModal;
