import React from "react";
import { connect } from "react-redux";
import { changeToolMode, persistCameraCalibration, setUserPreference } from "../../../../redux/actions/index";
import {
    drawCircle,
    drawLine,
    getCameraCharacteristics,
    intersection,
    matrix,
    matrixMultiply,
    vector,
    vectorFromImageCoordinate,
    worldToImageCoordinates,
} from "../../../util/Geometry";
import { Select, Steps, Checkbox } from "antd";
import Measure from "react-measure";
import _ from "lodash";

import { get_source_calibration, getAbsoluteTimestamp, getStreamFOVs } from "../../../util/PlaylistUtils";
import OBCButton from "components/OBC/OBCButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressWide, faExpandWide, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown, faCaretUp, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import calibrationStep1Correct from "../../../../images/instructions/calibration_setp1_correct.png";
import calibrationStep1Wrong from "../../../../images/instructions/calibration_setp1_wrong.png";
import calibrationStep2Animation from "../../../../images/instructions/calibration_step2_adjust.gif";
import calibrationStep3Animation from "../../../../images/instructions/calibration_step3_save.gif";

class CalibrationOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mouseDown: false,
            horizon: [
                [-0.9, -0.35],
                [0.9, -0.35],
            ],
            vanishingPoint: [0, -0.3],
            parallels: [
                [-0.2, 0.8],
                [0.2, 0.8],
            ],
            rail: [
                [-2, -2],
                [-2, -2],
            ],
            railSeparation: null,
            horizontalFov: 66.5,
            aspectRatio: 0.5625,
            rotationMatrix: [
                [1, 0, 0],
                [0, 1, 0],
                [0, 0, 1],
            ],
            translationVector: [0, 2, 0],
            selectedMarker: null,
            measureVertical: false,
            sessionIDOnEntry: null,
            sourceIndexOnEntry: null,
            changed: false,
            dimensions: { width: 0, height: 0 },
            fovLocked: true,
            aspectLocked: true,
            currentCalibrationStep: 0,
            calibrationWalkThroughOpen: false,
            showCalibrationTutorialNewValue: true,
            calibrationInstructionFullScreen: false,
            calibrationInfoPaneOpen: true,
            withAspectRatioTop: 0,
        };

        this.horizonOne = React.createRef();
        this.horizonTwo = React.createRef();
        this.vanishingPoint = React.createRef();
        this.parallelOne = React.createRef();
        this.parallelTwo = React.createRef();
        this.canvas = React.createRef();
        this.calibrateWalkThroughCloseButtonRef = React.createRef();
    }

    changeRailSeparation = (value) => {
        let width = parseFloat(value);

        if (width !== undefined && !isNaN(width)) {
            this.setState(
                {
                    railSeparation: width,
                },
                this.updateCalculations,
            );
        }
    };

    changeDeviceFOV = (evt) => {
        if (!this.state.fovLocked) {
            let valueStr = evt.target.value;
            let fov = parseFloat(valueStr);

            if (fov !== undefined && !isNaN(fov)) {
                this.setState(
                    {
                        horizontalFov: fov,
                    },
                    this.updateCalculations,
                );
            }
        }
    };

    resetDeviceFov = () => {
        if (this.props.deviceFov === null) {
            this.setState(
                {
                    horizontalFov: 66.5,
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    horizontalFov: this.props.deviceFov,
                    fovLocked: true,
                },
                this.updateCalculations,
            );
        }
    };

    unlockDeviceFov = () => {
        this.setState({
            fovLocked: false,
        });
    };

    changeDeviceAspectRatio = (evt) => {
        if (!this.state.aspectLocked) {
            let valueStr = evt.target.value;
            let aspectRatio = parseFloat(valueStr);

            if (aspectRatio !== undefined && !isNaN(aspectRatio)) {
                this.setState(
                    {
                        aspectRatio: aspectRatio,
                    },
                    this.updateCalculations,
                );
            }
        }
    };

    resetDeviceAspectRatio = () => {
        if (this.props.deviceAspectRatio === null) {
            this.setState(
                {
                    aspectRatio: 0.5625,
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    aspectRatio: this.props.deviceAspectRatio,
                    aspectLocked: true,
                },
                this.updateCalculations,
            );
        }
    };

    unlockDeviceAspectRatio = () => {
        this.setState({
            aspectLocked: false,
        });
    };

    componentDidMount() {
        // below to make sure we have correct position of the calibration info bar
        const targetElement = this.props.withAspectRatioRef.current;
        const relativeElement = this.canvas.current;

        if (targetElement && relativeElement) {
            const targetRect = targetElement.getBoundingClientRect();
            const relativeRect = relativeElement.getBoundingClientRect();
            const top = _.get(targetRect, "top", 0) - _.get(relativeRect, "top", 0);
            this.setState({
                withAspectRatioTop: top,
            });
        }

        this.setState({
            sessionIDOnEntry: this.props.sessionID,
            sourceIndexOnEntry: this.props.sourceIndex,
            changed: false,
            railSeparation: this.props.defaultRailSeparation,
        });

        this.generateCalibrationHandles(this.props.deviceFov !== null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.calibration, this.props.calibration) || prevProps.deviceFov !== this.props.deviceFov) {
            const forceDeviceFov = this.props.deviceFov && !prevProps.deviceFov;
            this.generateCalibrationHandles(forceDeviceFov);
        }
    }

    generateCalibrationHandles(forceDeviceFov) {
        if (this.props.calibration) {
            const calibration = _.cloneDeep(this.props.calibration);
            let fovLocked = false;
            let aspectLocked = false;

            if (!calibration.horizontalFov || forceDeviceFov) {
                if (this.props.deviceFov === null) {
                    calibration.horizontalFov = 66.5;
                } else if (!calibration.horizontalFov) {
                    fovLocked = true;
                    calibration.horizontalFov = this.props.deviceFov;
                } else if (calibration.horizontalFov === 66.5) {
                    fovLocked = true;
                    calibration.horizontalFov = this.props.deviceFov;
                } else if (calibration.horizontalFov === this.props.deviceFov) {
                    fovLocked = true;
                }
            } else if (calibration.horizontalFov === this.props.deviceFov) {
                fovLocked = true;
            }

            if (!calibration.aspectRatio || forceDeviceFov) {
                if (this.props.deviceAspectRatio === null) {
                    calibration.aspectRatio = 0.5625;
                } else if (!calibration.aspectRatio) {
                    aspectLocked = true;
                    calibration.aspectRatio = this.props.deviceAspectRatio;
                } else if (calibration.aspectRatio === 0.5625) {
                    aspectLocked = true;
                    calibration.aspectRatio = this.props.deviceAspectRatio;
                } else if (calibration.aspectRatio === this.props.deviceAspectRatio) {
                    aspectLocked = true;
                }
            } else if (calibration.aspectRatio === this.props.deviceAspectRatio) {
                aspectLocked = true;
            }

            console.log("New calibration: ", calibration);

            let distance = 1;
            let railP1, railP2;
            while (distance < 100) {
                railP1 = worldToImageCoordinates([-calibration.railSeparation / 2, 0, distance], calibration);
                railP2 = worldToImageCoordinates([calibration.railSeparation / 2, 0, distance], calibration);
                if (railP1[0] > -0.9 && railP1[0] < 0.9 && railP1[1] < 0.9 && railP2[0] > -0.9 && railP2[0] < 0.9 && railP2[1] < 0.8) {
                    break;
                } else {
                    distance *= 1.414;
                }
            }
            if (railP1[0] < -0.9 || railP1[0] > 0.9 || railP1[1] < -0.9 || railP1[1] > 0.9) {
                railP1 = [-0.5, 0.5];
            }
            if (railP2[0] < -0.9 || railP2[0] > 0.9 || railP2[1] < -0.9 || railP2[1] > 0.9) {
                railP2 = [0.5, 0.5];
            }

            const { pitch, yaw, roll } = getCameraCharacteristics(calibration);

            const tanHalfFov = Math.tan((Math.PI * calibration.horizontalFov) / 360);

            let vpxp = Math.tan(yaw) / tanHalfFov;
            let vpyp = Math.tan(pitch) / tanHalfFov;

            let vpx = vpxp * Math.cos(roll) - vpyp * Math.sin(roll);
            let vpy = vpyp * Math.cos(roll) + vpxp * Math.sin(roll);

            if (vpx < -0.9) {
                vpx = -0.9;
            } else if (vpx > 0.9) {
                vpx = 0.9;
            }
            if (vpy < -0.9) {
                vpy = -0.9;
            } else if (vpy > 0.9) {
                vpy = 0.9;
            }
            const vanishingPoint = [vpx, vpy / calibration.aspectRatio];

            let hyOffset = (Math.sin(roll) * 0.8) / calibration.aspectRatio;
            let hxOffset = Math.cos(roll) * 0.8;

            let h1 = [-hxOffset, -hyOffset - 0.5];
            let h2 = [hxOffset, hyOffset - 0.5];

            this.setState(
                {
                    railSeparation: calibration.railSeparation,
                    rotationMatrix: calibration.rotationMatrix,
                    translationVector: calibration.translationVector,
                    horizontalFov: calibration.horizontalFov,
                    aspectRatio: calibration.aspectRatio,
                    fovLocked,
                    aspectLocked,
                    horizon: [h1, h2],
                    vanishingPoint: vanishingPoint,
                    parallels: [railP1, railP2],
                    rail: [railP1, railP2],
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    aspectRatio: this.props.deviceAspectRatio === null ? 0.5625 : this.props.deviceAspectRatio,
                    aspectLocked: this.props.deviceAspectRatio !== null,
                    horizontalFov: this.props.deviceFov === null ? 66.5 : this.props.deviceFov,
                    fovLocked: this.props.deviceFov !== null,
                },
                this.updateCalculations,
            );
        }
    }

    confirmCalibration = () => {
        if (this.state.sessionIDOnEntry && this.state.changed) {
            let calibration = {
                source: this.state.sourceIndexOnEntry,
                railSeparation: this.state.railSeparation,
                rotationMatrix: this.state.rotationMatrix,
                translationVector: this.state.translationVector,
                horizontalFov: this.state.horizontalFov,
                aspectRatio: this.state.aspectRatio,
                timestamp: 0,
            };
            this.props.dispatch(persistCameraCalibration(this.state.sessionIDOnEntry, calibration));
            this.setState({
                changed: false,
            });

            // if calibration open from Overlay popover, close Meauser tools and go back to the video player
            if (this.props.calibrationOpenFromOverlayPopover) {
                this.props.dispatch(changeToolMode(null));
            } else {
                this.props.changeMode(4);
            }
        }
    };

    mouseDown = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        let target = evt.target;
        if (target === this.horizonOne.current) {
            this.setState({
                selectedMarker: 0,
            });
        } else if (target === this.horizonTwo.current) {
            this.setState({
                selectedMarker: 1,
            });
        } else if (target === this.vanishingPoint.current) {
            this.setState({
                selectedMarker: 2,
            });
        } else if (target === this.parallelOne.current) {
            this.setState({
                selectedMarker: 3,
            });
        } else if (target === this.parallelTwo.current) {
            this.setState({
                selectedMarker: 4,
            });
        } else {
            this.setState({
                selectedMarker: null,
            });
        }
    };

    mouseMove = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        let currentTargetRect = this.canvas.current.getBoundingClientRect();
        let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
            y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;

        x = 2 * Math.max(0.05, Math.min(0.95, x)) - 1;
        y = 2 * Math.max(0.05, Math.min(0.95, y)) - 1;

        if (this.state.selectedMarker !== null) {
            let newState = {};

            if (this.state.selectedMarker === 0) {
                newState = {
                    horizon: [[x, y], this.state.horizon[1]],
                };
            } else if (this.state.selectedMarker === 1) {
                newState = {
                    horizon: [this.state.horizon[0], [x, y]],
                };
            } else if (this.state.selectedMarker === 2) {
                newState = {
                    vanishingPoint: [x, y],
                };
            } else if (this.state.selectedMarker === 3) {
                newState = {
                    parallels: [[x, y], this.state.parallels[1]],
                };
            } else if (this.state.selectedMarker === 4) {
                newState = {
                    parallels: [this.state.parallels[0], [x, y]],
                };
            }

            this.setState(newState, this.updateCalculations);
        }
    };

    mouseUp = () => {
        this.setState({
            selectedMarker: null,
        });
    };

    steps = [
        {
            title: "Step 1",
            content: (
                <div className="CalibrationWalkThroughModalSlideContent">
                    <div className="CalibrationWalkThroughModalSlideLabel">
                        When calibrating ignore the distant curve when setting the calibration on a curved track.
                    </div>
                    <div className="CalibrationWalkThroughModalStep1Images">
                        <div>
                            <img
                                height="100%"
                                width="100%"
                                style={{ borderRadius: "6px" }}
                                crossOrigin={"anonymous"}
                                src={calibrationStep1Correct}
                                alt={"Straight rail"}
                            />
                        </div>
                        <div>
                            <img
                                height="100%"
                                width="100%"
                                style={{ borderRadius: "6px" }}
                                crossOrigin={"anonymous"}
                                src={calibrationStep1Wrong}
                                alt={"Bent rail"}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "Step 2",
            content: (
                <div className="CalibrationWalkThroughModalSlideContent">
                    <div className="CalibrationWalkThroughModalStep2Gif">
                        <div className="CalibrationWalkThroughModalSlideLabel">
                            Using the handles on the yellow lines, align them with the inside edge of each rail. Then using the handles on the green line, align
                            them with the angle of the horizon.
                        </div>
                        <img
                            style={{ borderRadius: "6px" }}
                            crossOrigin={"anonymous"}
                            src={calibrationStep2Animation}
                            alt={"Animated calibration instructions"}
                        />
                    </div>
                </div>
            ),
        },
        {
            title: "Step 3",
            content: (
                <div className="CalibrationWalkThroughModalSlideContent">
                    <div className="CalibrationWalkThroughModalStep3">
                        <div className="CalibrationWalkThroughModalSlideLabel">Save Calibration Settings</div>
                        <img
                            style={{ borderRadius: "6px" }}
                            crossOrigin={"anonymous"}
                            src={calibrationStep3Animation}
                            alt={"Animated calibration instructions"}
                        />
                    </div>
                </div>
            ),
        },
    ];

    calibrationNextStep = () => {
        this.setState({
            currentCalibrationStep: this.state.currentCalibrationStep + 1,
        });
    };

    calibrationPrevStep = () => {
        this.setState({
            currentCalibrationStep: this.state.currentCalibrationStep - 1,
        });
    };

    closeCalibrationInstructionModal = () => {
        this.setState({
            currentCalibrationStep: 0,
            calibrationWalkThroughOpen: false,
            calibrationInstructionFullScreen: false,
        });

        // if modal opened from overlay popover call function to close it in ToolInterface.js
        if (!this.state.calibrationWalkThroughOpen) {
            this.props.hideCalibrationWalkThroughModal();
            this.props.dispatch(setUserPreference("showCalibrationTutorial", this.state.showCalibrationTutorialNewValue));
        }
    };

    openWalkThroughModal = () => {
        this.setState({ calibrationWalkThroughOpen: true }, () => {
            if (this.calibrateWalkThroughCloseButtonRef && this.calibrateWalkThroughCloseButtonRef.current) {
                this.calibrateWalkThroughCloseButtonRef.current.focus();
            }
        });
    };

    closeCalibrationTool = () => {
        this.props.dispatch(changeToolMode(null));
    };

    getMeasuredContent = ({ measureRef }) => {
        return (
            <div
                ref={measureRef}
                className="MeasuredContainer">
                {/* Calibration walkthrough modal */}
                {((this.props.calibrationWalkThroughOpen && this.props.userPreferencesShowCalibrationTutorial) || this.state.calibrationWalkThroughOpen) && (
                    <div
                        className={`CalibrationWalkThroughModal ${this.state.calibrationInstructionFullScreen || this.props.isWidget ? "CalibrationWalkThroughModalFullScreen" : ""}`}
                        onClick={() => this.closeCalibrationInstructionModal()}>
                        <div
                            className={`CalibrationWalkThroughModalMainContent ${this.props.isWidget ? "SmallScreen" : ""}`}
                            onClick={(e) => e.stopPropagation()}>
                            <div className="CalibrationWalkThroughModalTitle">
                                <div>Session Calibration Instructions</div>
                                <div className="CalibrationWalkThroughModalTitleButtons">
                                    {/* Full Screen Button */}
                                    {!this.props.isWidget && (
                                        <div
                                            className="CalibrationWalkThroughModalTitleButton"
                                            onClick={() => {
                                                this.setState({
                                                    calibrationInstructionFullScreen: !this.state.calibrationInstructionFullScreen,
                                                });
                                            }}
                                            type="button"
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                                if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                    this.setState({
                                                        calibrationInstructionFullScreen: !this.state.calibrationInstructionFullScreen,
                                                    });
                                                }
                                            }}>
                                            <FontAwesomeIcon
                                                size="sm"
                                                icon={this.state.calibrationInstructionFullScreen ? faCompressWide : faExpandWide}
                                            />
                                        </div>
                                    )}

                                    {/* Close button */}
                                    <div
                                        ref={this.calibrateWalkThroughCloseButtonRef}
                                        className="CalibrationWalkThroughModalTitleButton"
                                        onClick={() => this.closeCalibrationInstructionModal()}
                                        tabIndex={0}
                                        type="button"
                                        onKeyDown={(e) => {
                                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                                this.closeCalibrationInstructionModal();
                                            }
                                        }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                            <Steps
                                current={this.state.currentCalibrationStep}
                                size={this.props.isWidget ? "small" : "default"}>
                                {this.steps.map((item) => (
                                    <Steps.Step
                                        key={item.title}
                                        title={item.title}
                                    />
                                ))}
                            </Steps>
                            <div className="steps-content">{this.steps[this.state.currentCalibrationStep].content}</div>
                            <div className="CalibrationWalkThroughModalNavButtons">
                                <OBCButton
                                    size="sm"
                                    style={{ opacity: this.state.currentCalibrationStep === 0 ? 0 : 1 }}
                                    disabled={this.state.currentCalibrationStep === 0}
                                    onClick={() => this.calibrationPrevStep()}>
                                    Previous
                                </OBCButton>
                                <div className="CalibrationWalkThroughModalNavRight">
                                    {this.state.currentCalibrationStep === 2 && !this.state.calibrationWalkThroughOpen && (
                                        <Checkbox
                                            onChange={(e) => {
                                                this.setState({
                                                    showCalibrationTutorialNewValue: !e.target.checked,
                                                });
                                            }}>
                                            Don't show again
                                        </Checkbox>
                                    )}
                                    <OBCButton
                                        size="sm"
                                        onClick={() => {
                                            if (this.state.currentCalibrationStep === 2) {
                                                this.closeCalibrationInstructionModal();
                                            } else {
                                                this.calibrationNextStep();
                                            }
                                        }}>
                                        {this.state.currentCalibrationStep === 2 ? "Close" : "Next"}
                                    </OBCButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <svg
                    className={"CalibrationUI MeasurementOverlay" + (this.state.selectedMarker !== null ? " Dragging" : "") + " " + this.props.className}
                    onMouseDown={this.mouseDown}
                    onMouseMove={this.mouseMove}
                    onMouseUp={this.mouseUp}
                    onMouseLeave={this.mouseUp}
                    ref={this.canvas}
                    viewBox={`0 0 ${this.state.dimensions.width} ${this.state.dimensions.height}`}>
                    {drawLine(this.state.horizon[0], this.state.horizon[1], "Horizon", "Horizon")}
                    {drawLine(this.state.vanishingPoint, this.state.parallels[0], "Perspective1", "Perspective")}
                    {drawLine(this.state.vanishingPoint, this.state.parallels[1], "Perspective2", "Perspective")}
                    {drawLine(this.state.rail[0], this.state.rail[1], "Rail", "Rail")}
                    {drawCircle(this.state.horizon[0], 3, "HorizonMarker1", "HorizonMarker", this.horizonOne)}
                    {drawCircle(this.state.horizon[1], 3, "HorizonMarker2", "HorizonMarker", this.horizonTwo)}
                    {drawCircle(this.state.vanishingPoint, 3, "VanishingPointMarker", "VanishingPointMarker", this.vanishingPoint)}
                    {drawCircle(this.state.parallels[0], 3, "ParallelLineMarker1", "ParallelLineMarker", this.parallelOne)}
                    {drawCircle(this.state.parallels[1], 3, "ParallelLineMarker2", "ParallelLineMarker", this.parallelTwo)}
                </svg>
                <div
                    style={{
                        top: this.state.calibrationInfoPaneOpen
                            ? this.state.withAspectRatioTop
                            : this.state.withAspectRatioTop - (this.props.isWidget ? 35 : 70),
                    }}
                    className={`MeasurementEntryPanel ${this.state.calibrationInfoPaneOpen ? "" : "MeasurementEntryPanelHidden"} ${this.props.isWidget ? "SmallScreen" : ""}`}>
                    <div className={`EntryContainer ${!this.props.truckGaugeAdjustmentEnabled ? "EntryContainerHidden" : ""}`}>
                        <span className="MeasurementEntryDescription">Track Gauge:</span>
                        <Select
                            autoFocus
                            size="small"
                            value={this.state.railSeparation}
                            dropdownMatchSelectWidth={false}
                            onChange={(value) => (this.props.truckGaugeAdjustmentEnabled ? this.changeRailSeparation(value) : null)}>
                            <Select.Option value={1.435}>1.435m {this.props.defaultRailSeparation === 1.435 && "(default)"}</Select.Option>
                            <Select.Option value={1.6}>1.6m {this.props.defaultRailSeparation === 1.6 && "(default)"}</Select.Option>
                        </Select>
                    </div>
                    <div className={`EntryContainer ${!this.props.fovAdjustmentEnabled ? "EntryContainerHidden" : ""}`}>
                        <span className="MeasurementEntryDescription">Field of View:</span>
                        <input
                            type="number"
                            min={0.01}
                            max={180}
                            step={0.1}
                            className="MeasurementEntry"
                            value={this.state.horizontalFov}
                            disabled={this.state.fovLocked}
                            onChange={(e) => (this.props.fovAdjustmentEnabled ? this.changeDeviceFOV(e) : null)}
                        />
                        {this.state.fovLocked ? (
                            <button
                                type="button"
                                className="MeasurementEntryReset"
                                onClick={this.unlockDeviceFov}>
                                Edit
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="MeasurementEntryReset"
                                onClick={this.resetDeviceFov}>
                                Reset
                            </button>
                        )}
                    </div>
                    <div className={`EntryContainer ${!this.props.aspectRatioAdjustmentEnabled ? "EntryContainerHidden" : ""}`}>
                        <span className="MeasurementEntryDescription">Aspect Ratio:</span>
                        <input
                            type="number"
                            min={0.01}
                            max={100}
                            step={0.0001}
                            className="MeasurementEntry"
                            value={this.state.aspectRatio}
                            disabled={this.state.aspectLocked}
                            onChange={(e) => (this.props.aspectRatioAdjustmentEnabled ? this.changeDeviceAspectRatio(e) : null)}
                        />
                        {this.state.aspectLocked ? (
                            <button
                                type="button"
                                className="MeasurementEntryReset"
                                onClick={this.unlockDeviceAspectRatio}>
                                Edit
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="MeasurementEntryReset"
                                onClick={this.resetDeviceAspectRatio}>
                                Reset
                            </button>
                        )}
                    </div>
                    <div className="SubmitButtonContainer">
                        {this.props.isWidget && (
                            <button
                                type="button"
                                className={`MeasurementEntrySubmit  ${this.state.calibrationInfoPaneOpen ? "" : "MeasurementEntryPanelSetCalibrationAbsoluteClose"}`}
                                onClick={this.closeCalibrationTool}>
                                Cancel
                            </button>
                        )}
                        <button
                            type="button"
                            className={`MeasurementEntrySubmit  ${this.state.calibrationInfoPaneOpen ? "" : "MeasurementEntryPanelSetCalibrationAbsolute"}`}
                            disabled={!this.state.changed}
                            onClick={this.confirmCalibration}>
                            Set Calibration
                        </button>
                        <button
                            type="button"
                            className="MeasurementEntrySubmit"
                            disabled={!this.state.changed}
                            onClick={this.resetCalibration}>
                            Reset Calibration
                        </button>
                    </div>
                    <div
                        className="EntryContainer EntryContainerSmall"
                        onClick={() => this.openWalkThroughModal()}
                        type="button"
                        aria-label="Open Modal with help"
                        title="Help"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                this.openWalkThroughModal();
                            }
                        }}>
                        <FontAwesomeIcon
                            color="white"
                            size="lg"
                            icon={faInfoCircle}
                        />
                    </div>
                    <div
                        className="MeasurementEntryPanelHideShowNotch"
                        onClick={() => this.setState({ calibrationInfoPaneOpen: !this.state.calibrationInfoPaneOpen })}>
                        <span>Calibration info</span>
                        <FontAwesomeIcon
                            icon={this.state.calibrationInfoPaneOpen ? faCaretUp : faCaretDown}
                            size={"1x"}
                        />
                    </div>
                </div>
            </div>
        );
    };

    resetCalibration = () => {
        this.setState({
            horizon: [
                [-0.8, -0.5],
                [0.8, -0.5],
            ],
            aspectRatio: this.props.deviceAspectRatio === null ? 0.5625 : this.props.deviceAspectRatio,
            aspectLocked: this.props.deviceAspectRatio !== null,
            horizontalFov: this.props.deviceFov === null ? 66.5 : this.props.deviceFov,
            fovLocked: this.props.deviceFov !== null,
            parallels: [
                [-0.13692018362663982, 0.6785049749984474],
                [0.13692018362663982, 0.6785049749984474],
            ],
            rail: [
                [-0.13692018362663982, 0.6785049749984474],
                [0.13692018362663982, 0.6785049749984474],
            ],
            railSeparation: this.props.defaultRailSeparation,
            rotationMatrix: [
                [1, 0, 0],
                [0, 1, 0],
                [0, 0, 1],
            ],
            translationVector: [0, 2, 0],
            vanishingPoint: [0, 0],
        });
    };

    render() {
        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.setState({ dimensions: contentRect.bounds });
                }}>
                {this.getMeasuredContent}
            </Measure>
        );
    }

    calculateRotationMatrix = (pitch, roll, yaw) => {
        let rollMatrix = [
            [Math.cos(roll), -Math.sin(roll), 0],
            [Math.sin(roll), Math.cos(roll), 0],
            [0, 0, 1],
        ];
        let pitchMatrix = [
            [1, 0, 0],
            [0, Math.cos(pitch), Math.sin(pitch)],
            [0, -Math.sin(pitch), Math.cos(pitch)],
        ];
        let yawMatrix = [
            [Math.cos(yaw), 0, -Math.sin(yaw)],
            [0, 1, 0],
            [Math.sin(yaw), 0, Math.cos(yaw)],
        ];
        return matrixMultiply(rollMatrix, matrixMultiply(yawMatrix, pitchMatrix));
    };

    updateCalculations = () => {
        let hfov = this.state.horizontalFov;
        let aspectRatio = this.state.aspectRatio;
        if (!hfov || !aspectRatio) {
            return;
        }

        const vpxp = this.state.vanishingPoint[0];
        const vpyp = this.state.vanishingPoint[1] * aspectRatio;

        const tanHalfFov = Math.tan((Math.PI * hfov) / 360);

        let horizonX = this.state.horizon[1][0] - this.state.horizon[0][0];
        let horizonY = (this.state.horizon[1][1] - this.state.horizon[0][1]) * aspectRatio;
        if (horizonX < 0) {
            horizonX *= -1;
            horizonY *= -1;
        }

        const roll = Math.atan2(horizonY, horizonX);

        const cosRoll = Math.cos(roll);
        const sinRoll = Math.sin(roll);
        const vpx = vpxp * cosRoll + vpyp * sinRoll;
        const vpy = vpyp * cosRoll - vpxp * sinRoll;

        const yaw = Math.atan(vpx * tanHalfFov);
        const pitch = Math.atan(vpy * tanHalfFov);

        let rotationMatrix = this.calculateRotationMatrix(pitch, roll, yaw);

        //rail 1 point 1
        let rail1 = [this.state.parallels[0][0], this.state.parallels[0][1] * aspectRatio];

        let rail2 = intersection(
            [rail1, [rail1[0] + horizonX, rail1[1] + horizonY]],
            [
                [this.state.vanishingPoint[0], this.state.vanishingPoint[1] * aspectRatio],
                [this.state.parallels[1][0], this.state.parallels[1][1] * aspectRatio],
            ],
        );
        const newState = {};

        let translationVector = this.calculateTranslationVector(rail1, rail2, tanHalfFov, rotationMatrix);

        if (translationVector[1] > 0) {
            newState.rotationMatrix = rotationMatrix;
            newState.translationVector = translationVector;
            newState.changed = true;
        }

        let distance = 1;
        let railP1, railP2;
        let calibration = {
            ...this.state,
            rotationMatrix,
            translationVector,
        };

        getCameraCharacteristics(calibration);

        while (distance < 100) {
            railP1 = worldToImageCoordinates([-this.state.railSeparation / 2, 0, distance], calibration);
            railP2 = worldToImageCoordinates([this.state.railSeparation / 2, 0, distance], calibration);
            if (railP1[0] > -0.9 && railP1[0] < 0.9 && railP1[1] < 0.8 && railP2[0] > -0.9 && railP2[0] < 0.9 && railP2[1] < 0.8) {
                break;
            } else {
                distance *= 2;
            }
        }
        this.setState({
            ...newState,
            rail: [railP1, railP2],
        });
    };

    calculateTranslationVector(rail1, rail2, tanHalfFov, rotationMatrix) {
        let rail1CameraRelative = vectorFromImageCoordinate(rail1[0], rail1[1], tanHalfFov);
        let rail2CameraRelative = vectorFromImageCoordinate(rail2[0], rail2[1], tanHalfFov);

        let rail1World = vector(matrixMultiply(matrix(rail1CameraRelative, 1, 3), rotationMatrix));
        let rail2World = vector(matrixMultiply(matrix(rail2CameraRelative, 1, 3), rotationMatrix));

        let r1p1ThetaXZ = Math.atan2(rail1World[0], rail1World[2]);
        let r2p1ThetaXZ = Math.atan2(rail2World[0], rail2World[2]);

        let rp1AngleXZ = r2p1ThetaXZ - r1p1ThetaXZ;

        let sinA = Math.sin(rp1AngleXZ);
        let sinB = Math.sin(Math.PI / 2 - r1p1ThetaXZ);
        let sinC = Math.sin(Math.PI / 2 - r2p1ThetaXZ);

        let rail1XZHyp = (this.state.railSeparation / sinA) * sinB;
        let rail2XZHyp = (this.state.railSeparation / sinA) * sinC;
        let cameraP1ZOffset = (Math.cos(r1p1ThetaXZ) * rail1XZHyp + Math.cos(r2p1ThetaXZ) * rail2XZHyp) / 2;

        let r1X = -0.5 * this.state.railSeparation;
        let r2X = 0.5 * this.state.railSeparation;

        let r1XZRatio = rail1World[0] / rail1World[2];
        let r2XZRatio = rail2World[0] / rail2World[2];

        let r1YZRatio = rail1World[1] / rail1World[2];
        let r2YZRatio = rail2World[1] / rail2World[2];

        let cameraX = -(r1X + r1XZRatio * cameraP1ZOffset + r2X + r2XZRatio * cameraP1ZOffset) / 2;
        let cameraY = -(r1YZRatio * cameraP1ZOffset + r2YZRatio * cameraP1ZOffset) / 2;
        let translationVector = [cameraX, cameraY, 0];
        return translationVector;
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex || 0;

    const session = _.get(state.sessions, state.playlist.data.routeID, false);
    let fovsForIndex = getStreamFOVs(state.playlist, state.routeMetadata, session, sourceIndex);
    let dashboard = _.find(state.dashboards, { access_token: state.access_token });

    let defaultRailSeparation = 1.435;
    let truckGaugeAdjustmentEnabled = true;
    let fovAdjustmentEnabled = true;
    let aspectRatioAdjustmentEnabled = true;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
        truckGaugeAdjustmentEnabled = state.dashboards[dashboardIndex].config.calibration_track_guage_adjusting_enabled;
        fovAdjustmentEnabled = state.dashboards[dashboardIndex].config.calibration_fov_adjusting_enabled;
        aspectRatioAdjustmentEnabled = state.dashboards[dashboardIndex].config.calibration_aspect_ratio_adjusting_enabled;
    }
    let deviceFov = null;
    let deviceAspectRatio = null;
    if (fovsForIndex.length > 0) {
        deviceFov = Math.max(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
        let vFov = Math.min(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
        deviceAspectRatio = Math.tan((Math.PI * vFov) / 360) / Math.tan((Math.PI * deviceFov) / 360);
    }

    const userPreferencesShowCalibrationTutorial = _.get(state.userPreferences, "showCalibrationTutorial", true);

    const isWidget = ["widget_layout", "minimal_layout"].includes(dashboard?.workspace_layout);

    return {
        calibration: get_source_calibration(
            state.measurement.calibration,
            state.playlist.position.sourceIndex,
            getAbsoluteTimestamp(state.playlist) * 1000,
            false,
            defaultRailSeparation,
        ),
        deviceAspectRatio: deviceAspectRatio === null ? null : Math.round(deviceAspectRatio * 10000) / 10000,
        deviceFov: deviceFov === null ? null : Math.round(deviceFov * 10) / 10,
        sessionID: state.playlist.data.routeID,
        sourceIndex,
        defaultRailSeparation,
        userPreferencesShowCalibrationTutorial,
        truckGaugeAdjustmentEnabled,
        fovAdjustmentEnabled,
        aspectRatioAdjustmentEnabled,
        isWidget,
    };
};

export default connect(mapStateToProps)(CalibrationOverlay);
