import React from "react";

export class SvgLabel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            textRef: null,
        };
    }

    onRefChange = (node) => {
        this.setState({ textRef: node });
    };

    render = () => {
        const annotation = this.props.annotation;

        let top = annotation.bbTop * this.props.boundingClientHeight;
        let left = annotation.bbLeft * this.props.boundingClientWidth;

        let customTag = annotation.customTag;
        if (!customTag) {
            customTag = "";
        }

        let width = customTag.length * 13;

        if (this.state.textRef) {
            let textBounds = this.state.textRef.getBBox();
            width = textBounds.width + 10;
        }

        if (this.props.boundingClientWidth < width + left) {
            left = this.props.boundingClientWidth - width;
        }

        if (this.props.boundingClientHeight < 35 + top) {
            top = this.props.boundingClientHeight - 35;
        }

        return (
            <g className="svgTextLabel">
                <rect
                    x={left}
                    y={top}
                    width={width}
                    height={35}
                    className="MeasureBG"
                />
                <text
                    x={left}
                    y={top}
                    fill="white"
                    fontSize="2em"
                    dx={5}
                    dy={25}
                    ref={this.onRefChange}>
                    {customTag}
                </text>
            </g>
        );
    };
}

export const SVGAnnotations = (props) => {
    return props.annotations.map((annotation, index) => {
        if (annotation.shape !== "line" && annotation.shape !== "text") {
            return null;
        }

        let top = annotation.bbTop * props.boundingClientHeight;
        let left = annotation.bbLeft * props.boundingClientWidth;
        let bottom = annotation.bbBottom * props.boundingClientHeight;
        let right = annotation.bbRight * props.boundingClientWidth;

        if (annotation.shape === "line") {
            let lineStyle = {};

            if (props.selectedAnnotationIndex === index) {
                lineStyle = {
                    strokeDasharray: 5,
                };
            }

            return (
                <line
                    x1={left}
                    y1={top}
                    x2={right}
                    y2={bottom}
                    className="AnnotationLine"
                    key={annotation.id}
                    style={lineStyle}
                />
            );
        } else if (annotation.shape === "text") {
            let customTag = annotation.customTag;
            if (!customTag) {
                customTag = "";
            }
            return (
                <React.Fragment key={annotation.id}>
                    <g>
                        <SvgLabel
                            annotation={annotation}
                            boundingClientHeight={props.boundingClientHeight}
                            boundingClientWidth={props.boundingClientWidth}
                        />
                    </g>
                </React.Fragment>
            );
        }
    });
};
