import L from "leaflet";

export const MagnifyLayer = L.Layer.extend({
    options: {
        radius: 125,
        zoomOffset: 2,
        layers: [],
        fixedPosition: false,
        latLng: [0, 0],
        fixedZoom: -1,
        xOffset: 0,
        yOffset: 0,
        horizontal: false,
        railsFlipped: false,
        backwardsImage: false,
        horizontalFlip: false,
        currentMousePosition: null,
        maxZoom: 6,
    },

    initialize: function (options) {
        L.Util.setOptions(this, options);
        this._fixedZoom = this.options.fixedZoom !== -1;
        this._mainMap = null;
        this._glassMap = null;
        this._wrapperElt = null;
    },

    getMap: function () {
        return this._glassMap;
    },

    _createMiniMap: function (elt) {
        return new L.Map(elt, {
            layers: this.options.layers,
            zoom: this._getZoom(),
            maxZoom: this._mainMap.getMaxZoom(),
            minZoom: this._mainMap.getMinZoom(),
            crs: this._mainMap.options.crs,
            fadeAnimation: false,
            // disable every controls and interaction means
            attributionControl: false,
            zoomControl: false,
            boxZoom: false,
            touchZoom: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            dragging: false,
            keyboard: false,
        });
    },

    _getZoom: function () {
        return this._fixedZoom ? this.options.fixedZoom : this._mainMap.getZoom() + this.options.zoomOffset;
    },

    _updateZoom: function () {
        if (this._mainMap.getZoom() >= this.options.maxZoom) {
            this._wrapperElt.firstChild.style.zoom = 1.5;
        } else {
            this._wrapperElt.firstChild.style.zoom = 1;
        }

        this._glassMap.setZoom(this._getZoom());
    },

    setRadius: function (radius) {
        this.options.radius = radius;
        if (this._wrapperElt) {
            this._wrapperElt.style.width = this.options.radius * 2 + "px";
            this._wrapperElt.style.height = this.options.radius * 2 + "px";
        }
    },

    setLatLng: function (latLng) {
        this.options.latLng = latLng;
        this._update(latLng);
    },

    setOffset: function (xOffset, yOffset) {
        this.options.xOffset = xOffset;
        this.options.yOffset = yOffset;
    },

    setHorizontal: function (horizontal) {
        this.options.horizontal = horizontal;
    },

    setRailsFlipped: function (railsFlipped) {
        this.options.railsFlipped = railsFlipped;
    },

    setBackwardsImage: function (backwardsImage) {
        this.options.backwardsImage = backwardsImage;
    },

    setHorizontalFlip: function (horizontalFlip) {
        this.options.horizontalFlip = horizontalFlip;
    },

    _updateFromMouse: function (evt) {
        if (evt.originalEvent.buttons === 0) {
            this._update(evt.latlng, evt.layerPoint);
        }
    },

    _updateFixed: function () {
        this._update(this.options.latLng);
    },

    _update: function (latLng, layerPoint) {
        this._glassMap.setView(latLng, this._getZoom(), {
            pan: { animate: false },
        });

        layerPoint = layerPoint || this._mainMap.latLngToLayerPoint(latLng);

        let left = layerPoint.x - this.options.radius + this.options.xOffset;
        let top = layerPoint.y - this.options.radius + this.options.yOffset;

        this._wrapperElt.style.left = left + "px";
        this._wrapperElt.style.top = top + "px";
    },

    onAdd: function (map) {
        this._mainMap = map;
        this._wrapperElt = L.DomUtil.create("div", "leaflet-magnifying-glass");
        var glassMapElt = L.DomUtil.create("div", "", this._wrapperElt);
        if (L.Browser.webkit) L.DomUtil.addClass(glassMapElt, "leaflet-magnifying-glass-webkit");
        this._glassMap = this._createMiniMap(glassMapElt);

        L.DomEvent.addListener(this._wrapperElt, "click", this._fireClick, this);

        var opts = this.options;

        this._glassMap.whenReady(function () {
            if (opts.fixedPosition) {
                this._mainMap.on("zoomend", this._updateFixed, this);
                L.DomUtil.addClass(this._wrapperElt, "leaflet-zoom-hide");
            } else {
                this._mainMap.on("zoomend", this._updateZoom, this);
                this._mainMap.on("mousemove", this._updateFromMouse, this);
            }
            this._wrapperElt.style.backgroundColor = "#1C1E30";
            this._wrapperElt.style.cursor = "none";

            this._glassMap.panTo(this._mainMap.getCenter());
            if (this._mainMap.getZoom() >= this.options.maxZoom) {
                this._wrapperElt.firstChild.style.zoom = 1.5;
            }
        }, this);

        map.getPanes().popupPane.appendChild(this._wrapperElt);
        this._glassMap.invalidateSize();

        let initialLayerPoint;
        let initialLatLng;
        if (opts.currentMousePosition.current) {
            initialLayerPoint = map.containerPointToLayerPoint(opts.currentMousePosition.current);
            initialLatLng = map.layerPointToLatLng(initialLayerPoint);
        } else {
            console.log("centre: ", map.getCenter());
            initialLatLng = map.getCenter();
            initialLayerPoint = map.latLngToLayerPoint(initialLatLng);
        }

        this.setRadius(opts.radius);
        this.setLatLng(initialLatLng);

        this._update(initialLatLng, initialLayerPoint);

        return this;
    },

    _fireClick: function (domMouseEvt) {
        this.fire("click", domMouseEvt);
        L.DomEvent.stopPropagation(domMouseEvt);
    },

    onRemove: function (map) {
        if (this._glassMap) {
            this._glassMap.remove();
        }
        if (!this._mainMap || !this._wrapperElt) return;
        this._mainMap.off("zoomend", this._updateFixed, this);
        this._mainMap.off("mousemove", this._updateFromMouse, this);
        this._mainMap.off("zoomend", this._updateZoom, this);

        for (let i = 0; i < this.options.layers.length; i++) {
            this._glassMap.removeLayer(this.options.layers[i]);
        }

        L.DomEvent.off(this._wrapperElt, "click", this._fireClick);
        map.getPanes().popupPane.removeChild(this._wrapperElt);

        this._mainMap = null;
        this._glassMap = null;
        this._wrapperElt = null;
    },
});

L.magnifyingGlass = function (options) {
    return new L.MagnifyingGlass(options);
};
