import React, { useMemo, useCallback, useRef } from "react";
import { Input, Table, Button, Select, Checkbox, DatePicker, Tooltip } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
    setPatrolReportForm,
    setPatrolReportOpen,
    setPatrolLocation,
    updateInspectionAnnotation,
    updateBookmark,
    deleteBookmark,
    deleteInspectionAnnotation,
} from "../../redux/actions/index";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const patrolReportFormSelector = (state) => state.schemaInterface.patrolReport.form;
const patrolStatusSelector = (state) => state.schemaInterface.status;

const { Option } = Select;
const defectCodesArray = ["M0", "M1", "M3", "M6", "M9", "M12", "M24"];
const DATE_FORMAT = "DD-MM-YYYY";

const PatrolDefectsTable = ({ saveForm, patrolID, match }) => {
    const scrollTargetRef = useRef();

    const annotationTypes = useSelector(annotationTypesSelector);
    const patrolReportForm = useSelector(patrolReportFormSelector);
    const patrolStatus = useSelector(patrolStatusSelector);

    const dispatch = useDispatch();
    const isReview = match.path.includes("review-patrol");

    const onTableRowChange = useCallback(
        (id, type, field, value) => {
            if (field === "elr") {
                value = value.toUpperCase();
            }
            if (type === "custom") {
                const newReport = _.cloneDeep(patrolReportForm);
                const currentCustomDefects = newReport.customDefectList;
                const editingIndex = _.findIndex(currentCustomDefects, { id: id });
                currentCustomDefects[editingIndex][field] = value;
                if (isReview) {
                    currentCustomDefects[editingIndex]["supervisor_edited"] = true;
                }
                newReport.customDefectList = currentCustomDefects;
                dispatch(setPatrolReportForm(newReport));
                saveForm(newReport, patrolID);
            } else {
                const newReport = _.cloneDeep(patrolReportForm);
                const currentDefects = newReport.defectList;
                const editingIndex = _.findIndex(currentDefects, { id: id });
                currentDefects[editingIndex][field] = value;

                if (isReview) {
                    currentDefects[editingIndex]["supervisor_edited"] = true;
                }
                newReport.defectList = currentDefects;
                dispatch(setPatrolReportForm(newReport));
                saveForm(newReport, patrolID);
            }
        },
        [dispatch, patrolReportForm, patrolID, saveForm, isReview],
    );

    const onAnnotationUpdate = useCallback(
        _.debounce(
            (id, key, value) => {
                dispatch(updateInspectionAnnotation(id, { [key]: value }));
            },
            2000,
            { leading: false, trailing: true },
        ),
        [],
    );

    const onBookmarkUpdate = useCallback(
        _.debounce(
            (id, value) => {
                dispatch(updateBookmark(id, value, true, false));
            },
            2000,
            { leading: false, trailing: true },
        ),
        [],
    );

    const defectTypes = useMemo(() => {
        const nonExtraTypes = _.orderBy(
            annotationTypes.filter((type) => !type.extra),
            "type",
        ).map((type) => {
            return (
                <Select.Option
                    className="DefectSelectorOption"
                    value={type.id}
                    key={type.id}>
                    {type.type}
                </Select.Option>
            );
        });

        return <Select.OptGroup label="Types">{nonExtraTypes}</Select.OptGroup>;
    }, [annotationTypes]);

    const extraDefectTypes = useMemo(() => {
        const extraTypes = _.orderBy(
            annotationTypes.filter((type) => type.extra),
            "type",
        ).map((type) => {
            return (
                <Select.Option
                    className="DefectSelectorOption"
                    value={type.id}
                    key={type.id}>
                    {type.type}
                </Select.Option>
            );
        });

        return <Select.OptGroup label="More">{extraTypes}</Select.OptGroup>;
    }, [annotationTypes]);

    const defectCodes = useMemo(() => {
        return defectCodesArray.map((code) => {
            return <Option value={code}>{code}</Option>;
        });
    }, []);

    const patrollerInputsDisabled = useMemo(() => {
        if (isReview) {
            return patrolStatus === 3;
        } else {
            return patrolStatus >= 2;
        }
    }, [patrolStatus, isReview]);

    const supervisorInputsDisabled = useMemo(() => {
        return patrolStatus === 3 || (patrolStatus === 2 && !isReview);
    }, [patrolStatus, isReview]);

    const defectTypeDisplay = useCallback(
        (value, record) => {
            let typeDisplay = null;
            let descriptionDisplay = null;

            if (record.type !== "bookmark") {
                if (patrollerInputsDisabled) {
                    typeDisplay = <h4 className="Text">{value}</h4>;
                } else {
                    typeDisplay = (
                        <Select
                            labelInValue
                            style={{ width: "100%", marginBottom: 10 }}
                            value={{ key: value }}
                            onChange={(option) => {
                                onTableRowChange(record.id, record.type, "defectType", option.label);
                                if (record.type !== "custom") {
                                    onAnnotationUpdate(record.id, "annotation_type", option.key);
                                }
                            }}>
                            {defectTypes}
                            {extraDefectTypes}
                        </Select>
                    );
                }
            }

            if (patrollerInputsDisabled) {
                descriptionDisplay = <p className="Text">{record.description}</p>;
            } else {
                descriptionDisplay = (
                    <Input.TextArea
                        rows={3}
                        value={record.description}
                        placeholder="Description"
                        maxLength="120"
                        onChange={(e) => {
                            onTableRowChange(record.id, record.type, "description", e.target.value);

                            if (record.type === "annotation") {
                                onAnnotationUpdate(record.id, "comment", e.target.value);
                            } else if (record.type === "bookmark") {
                                onBookmarkUpdate(record.id, e.target.value);
                            }
                        }}
                    />
                );
            }

            return (
                <div>
                    {typeDisplay}
                    {descriptionDisplay}
                </div>
            );
        },
        [defectTypes, extraDefectTypes, onAnnotationUpdate, onBookmarkUpdate, onTableRowChange, patrollerInputsDisabled],
    );

    const columns = [
        {
            title: "",
            dataIndex: "supervisor_edited",
            key: "supervisor_edited",
            width: 10,
            fixed: "left",
            render: (value, record) => {
                if (value) {
                    return (
                        <Tooltip title="Row edited or created by Supervisor">
                            <FontAwesomeIcon
                                className="InfoIcon"
                                icon={faUserEdit}
                            />
                        </Tooltip>
                    );
                }
                return "";
            },
        },
        {
            title: "Defect Type & Description",
            dataIndex: "defectType",
            key: "defectType",
            width: 200,
            fixed: "left",
            render: (value, record) => {
                return defectTypeDisplay(value, record);
            },
        },
        {
            title: "To be completed by Patroller",
            children: [
                {
                    title: "ELR",
                    dataIndex: "elr",
                    key: "elr",
                    width: 100,
                    render: (value, record) => {
                        if (record.type !== "custom") {
                            return value;
                        }
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                placeholder="ELR"
                                value={value}
                                onChange={(e) => onTableRowChange(record.id, record.type, "elr", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Track ID",
                    dataIndex: "trackID",
                    key: "trackID",
                    width: 100,
                    render: (value, record) => {
                        if (record.type !== "custom") {
                            return value;
                        }
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="Track ID"
                                onChange={(e) => onTableRowChange(record.id, record.type, "trackID", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Position",
                    dataIndex: "position",
                    key: "position",
                    width: 140,
                    render: (value, record) => {
                        if (record.type !== "custom") {
                            return value;
                        }
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                placeholder="Position"
                                value={value}
                                onChange={(e) => onTableRowChange(record.id, record.type, "position", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Fault No.",
                    dataIndex: "faultNo",
                    key: "faultNo",
                    width: 100,
                    render: (value, record) => {
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="No."
                                onChange={(e) => onTableRowChange(record.id, record.type, "faultNo", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Qty",
                    dataIndex: "qty",
                    key: "qty",
                    width: 100,
                    render: (value, record) => {
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="Qty"
                                onChange={(e) => onTableRowChange(record.id, record.type, "qty", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Unit of Work",
                    dataIndex: "unitOfWork",
                    key: "unitOfWork",
                    width: 100,
                    render: (value, record) => {
                        return patrollerInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="Unit"
                                onChange={(e) => onTableRowChange(record.id, record.type, "unitOfWork", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Urgent",
                    dataIndex: "urgent",
                    key: "urgent",
                    width: 80,
                    render: (value, record) => {
                        return (
                            <Checkbox
                                checked={value}
                                disabled={patrollerInputsDisabled}
                                onChange={(e) => {
                                    onTableRowChange(record.id, record.type, "urgent", e.target.checked);
                                }}
                            />
                        );
                    },
                },
            ],
        },
        {
            title: "Completed work",
            className: "completed",
            children: [
                {
                    title: "Work Completed?",
                    dataIndex: "workCompleted",
                    key: "workCompleted",
                    width: 120,
                    render: (value, record) => {
                        return (
                            <Checkbox
                                disabled={supervisorInputsDisabled}
                                checked={value}
                                onChange={(e) => {
                                    onTableRowChange(record.id, record.type, "workCompleted", e.target.checked);
                                }}
                            />
                        );
                    },
                },
                {
                    title: "Units Comp",
                    dataIndex: "unitsComp",
                    key: "unitsComp",
                    width: 100,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                placeholder="Units"
                                value={value}
                                onChange={(e) => onTableRowChange(record.id, record.type, "unitsComp", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "T.O.T",
                    dataIndex: "tot",
                    key: "tot",
                    width: 100,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                placeholder="Time"
                                value={value}
                                onChange={(e) => onTableRowChange(record.id, record.type, "tot", e.target.value)}
                            />
                        );
                    },
                },
            ],
        },
        {
            title: "To be completed by Supervisor",
            className: "supervisor",
            children: [
                {
                    title: "Priority",
                    dataIndex: "priority",
                    key: "priority",
                    width: 120,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="Priority"
                                onChange={(e) => onTableRowChange(record.id, record.type, "priority", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Prot. Type",
                    dataIndex: "protType",
                    key: "protType",
                    width: 100,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="Type"
                                onChange={(e) => onTableRowChange(record.id, record.type, "protType", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Defect Code",
                    dataIndex: "defectCode",
                    key: "defectCode",
                    width: 100,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Select
                                style={{ width: "100%" }}
                                value={value}
                                onChange={(val) => onTableRowChange(record.id, record.type, "defectCode", val)}>
                                {defectCodes}
                            </Select>
                        );
                    },
                },
                {
                    title: "Std Job No.",
                    dataIndex: "stdJobNo",
                    key: "stdJobNo",
                    width: 100,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="No."
                                onChange={(e) => onTableRowChange(record.id, record.type, "stdJobNo", e.target.value)}
                            />
                        );
                    },
                },
                {
                    title: "Req Finish Date",
                    dataIndex: "reqFinishDate",
                    key: "reqFinishDate",
                    width: 160,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value ? moment.unix(value).format(DATE_FORMAT) : ""}</p>
                        ) : (
                            <DatePicker
                                format={DATE_FORMAT}
                                value={value ? moment.unix(value) : null}
                                onChange={(date) =>
                                    date
                                        ? onTableRowChange(record.id, record.type, "reqFinishDate", date.unix())
                                        : onTableRowChange(record.id, record.type, "reqFinishDate", null)
                                }
                            />
                        );
                    },
                },
                {
                    title: "W/O No. (If known)",
                    dataIndex: "woNo",
                    key: "woNo",
                    width: 85,
                    render: (value, record) => {
                        return supervisorInputsDisabled ? (
                            <p className="Text">{value}</p>
                        ) : (
                            <Input
                                value={value}
                                placeholder="No."
                                onChange={(e) => onTableRowChange(record.id, record.type, "woNo", e.target.value)}
                            />
                        );
                    },
                },
            ],
        },
        {
            title: "View",
            dataIndex: "",
            key: "view",
            width: 100,
            className: "NoPrint",
            render: (value, record) => {
                if (record.type === "custom") {
                    return value;
                }
                return (
                    <Button
                        className="NoPrint"
                        type="primary"
                        onClick={() => onRowClick(record)}>
                        View
                    </Button>
                );
            },
        },
        {
            title: "Delete",
            dataIndex: "",
            key: "delete",
            width: 100,
            className: "NoPrint",
            render: (value, record) => {
                return supervisorInputsDisabled ? null : (
                    <Button
                        className="NoPrint"
                        type="danger"
                        onClick={() => deleteDefect(record.type, record.id)}>
                        Delete
                    </Button>
                );
            },
        },
    ];

    const rows = useMemo(() => {
        const allMarkups = patrolReportForm.defectList;
        const customDefects = patrolReportForm.customDefectList;

        const sortedRows = _.orderBy(allMarkups, ["elr", "trackID", "position"]);
        const sortedCustomRows = _.orderBy(customDefects, ["id"], ["asc"]);
        return [...sortedRows, ...sortedCustomRows];
    }, [patrolReportForm]);

    const addDefect = useCallback(() => {
        const newReport = _.clone(patrolReportForm);
        const currentCustomDefects = newReport.customDefectList;
        currentCustomDefects.push({ id: Date.now(), type: "custom" });
        dispatch(setPatrolReportForm(newReport));

        if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView();
        }
    }, [dispatch, patrolReportForm]);

    const removeMarkupFromReport = useCallback(
        (id) => {
            const newReport = _.clone(patrolReportForm);
            const currentDefects = newReport.defectList;
            const idxToDelete = _.findIndex(currentDefects, { id: id });
            currentDefects.splice(idxToDelete, 1);
            dispatch(setPatrolReportForm(newReport));
        },
        [dispatch, patrolReportForm],
    );

    const deleteDefect = useCallback(
        (type, id) => {
            if (type === "custom") {
                const newReport = _.clone(patrolReportForm);
                const currentCustomDefects = newReport.customDefectList;
                const idxToDelete = _.findIndex(currentCustomDefects, { id: id });
                currentCustomDefects.splice(idxToDelete, 1);
                dispatch(setPatrolReportForm(newReport));
            } else if (type === "bookmark") {
                removeMarkupFromReport(id);
                dispatch(deleteBookmark(id));
            } else if (type === "annotation") {
                removeMarkupFromReport(id);
                dispatch(deleteInspectionAnnotation(id));
            }
        },
        [patrolReportForm, dispatch, removeMarkupFromReport],
    );

    const onRowClick = useCallback(
        (record) => {
            const locationObj = {
                elr: record.elr,
                chain: record.chain,
                trackID: record.trackID,
            };

            dispatch(setPatrolReportOpen(false));
            dispatch(setPatrolLocation(locationObj));
        },
        [dispatch],
    );

    return (
        <div className="Patrol__Defects">
            <div className="Patrol__Defects__Header">
                <h2>Defects</h2>
                {!supervisorInputsDisabled && patrolStatus !== 0 && (
                    <Button
                        type="primary"
                        className="NoPrint"
                        onClick={addDefect}>
                        Add Defect
                    </Button>
                )}
            </div>
            <Table
                scroll={{ x: "max-content" }}
                pagination={false}
                bordered={true}
                columns={columns}
                dataSource={rows}
            />
            <div ref={scrollTargetRef}></div>
        </div>
    );
};

export default withRouter(PatrolDefectsTable);
