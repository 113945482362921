import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraggableWrapper from "../DraggableWrapper";
import { setClassificationsTabOpen } from "../../../../redux/actions/index";
import { Tabs } from "antd";
import _ from "lodash";
import { groupAreasOfInterest } from "../../../util/PlaylistUtils";
import UTUFaultNavigation from "./UTUFaultNavigation";
import { SET_SPERRY_MARKERS } from "redux/actions";
import { setTargetInpectionTimestamp } from "../../../../redux/actions/index";

const EMPTY_OBJ = {};
const routeMetadataSelector = (state) => _.get(state, ["routeMetadata", "AREA_OF_INTEREST"], EMPTY_OBJ);
const routeIDSelector = (state) => state.playlist.data.routeID;
const userAnnotationTypesSelector = (state) => state.userAnnotationTypes;

const limits = {
    minWidth: 300,
    minHeight: 490,
    maxWidth: 1000,
    maxHeight: 650,
}; //These need to correspond to the values in the css class draggableWrapper and &__tabContainer!

const SperrySuspectNavigation = ({ windowDimensions, setSperrySuspectNavigationWindowOpen }) => {
    const dispatch = useDispatch();

    const routeMetadata = useSelector(routeMetadataSelector);
    const routeID = useSelector(routeIDSelector);
    const userAnnotationTypes = useSelector(userAnnotationTypesSelector);
    const customDimensionsRef = useRef({ width: 600, height: limits.minHeight });

    const title = (
        <div className="inspectRail__BookmarkList__Title">
            <p>Sperry Suspect Viewer</p>
        </div>
    );

    const closeNavigator = () => {
        // dispatch(toggleDetectionsOpen());
        dispatch(setClassificationsTabOpen(false));
        setSperrySuspectNavigationWindowOpen(false);
        dispatch({
            type: SET_SPERRY_MARKERS,
            sperryMarkers: [],
        });
        dispatch(setTargetInpectionTimestamp(null, null));
    };

    const changeActiveTab = (activeKey) => {
        if (activeKey !== "Classifications") {
            dispatch(setClassificationsTabOpen(false));
        }
    };

    const sessionAreasOfInterest = useMemo(() => {
        const allMetadata = routeMetadata[routeID] || [];
        return groupAreasOfInterest(allMetadata, userAnnotationTypes);
    }, [routeMetadata, routeID, userAnnotationTypes]);

    const content = (
        <div className="inspectRail__Detections inspectRail__tabContainer">
            <Tabs
                className="draggable-tabs inspectRail__tabContainer"
                defaultActiveKey={"Detections"}
                destroyInactiveTabPane
                onChange={(activeKey) => changeActiveTab(activeKey)}>
                <Tabs.TabPane
                    tab="Sperry Suspect Viewer"
                    key="faults"
                    className="inspectRail__tab inspectRail__tabContent">
                    <UTUFaultNavigation inResizeDraggable />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );

    let width = "397px";

    if (sessionAreasOfInterest.length > 0) {
        width = "530px";
    }

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            closeAction={closeNavigator}
            customDimensionsRef={customDimensionsRef}
            customDimensions={{ width: customDimensionsRef.current.width, height: customDimensionsRef.current.height }}
            limits={limits}
            isResizable
            x={22}
            y={30}
        />
    );
};

export default SperrySuspectNavigation;
