import { Slider } from "antd";
import React, { useState } from "react";
import ZoomableImage from "./ZoomableImage";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";

const ZoomTest = () => {
    const [zoom, setZoom] = useState(1);

    return (
        <div className="ZoomContainer">
            <ZoomableImage
                zoom={zoom}
                imgSrc={`https://raw${MEMOIZED_DOMAIN_URL}/connect-HN93hEIc/d1b76d4e265c4ff987f296f7bf54c471/s0.c1.f30.t186308245b9-11.jpg`}
            />
            <Slider
                min={1}
                max={4}
                step={0.25}
                value={zoom}
                onChange={(v) => setZoom(v)}
                width={300}
            />
        </div>
    );
};

export default ZoomTest;
