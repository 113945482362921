import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import _ from "lodash";
import "leaflet-edgebuffer";
import { useSelector, useStore } from "react-redux";
import {
    selectBookmark,
    goToBounds,
    setSelectedRailInspectionImage,
    currentPlaylistPosition,
    fetchInspectionAnnotations,
    toggleInspectionAnnotationMode,
    clearRequestedTimestamp,
    getInspectionDetections,
    getInspectionClassifications,
    setCurrentDetection,
    setCurrentClassification,
    setCurrentInspectionMarkup,
    flipInspectionRails,
    getRouteMetadata,
    updatePrimaryRailImages,
    primaryRailImageConfig,
    setSomeMainRailInspectionImagesMissing,
    fetchInspectionPulseCounts,
    setUserPreference,
    setAutoScrollActive,
    getInspectionConditionTypes,
    setIsMagnifyToggled,
    setPositionLeftBiased,
    exportInspectionDetections,
} from "../../../redux/actions/index";
import { useDispatch } from "react-redux";
import {
    binarySearch,
    filterMarkers,
    getAbsoluteTimestamp,
    getOffsetAdjustedPosition,
    getVideolessLocation,
    groupAreasOfInterest,
} from "../../util/PlaylistUtils";
import OBCSpinner from "../../util/OBC";
import MapComponent from "../../map/MapComponent";
import LabelCreateWindow from "./LabelCreateWindow";
import { Icon, Button, Dropdown, Menu, notification } from "antd";
import { useHistory } from "react-router";
import { calculateElrGroups } from "../../util/Geometry";
import "leaflet-draw/dist/leaflet.draw.css";
import AnnotationCreateWindow from "./AnnotationCreateWindow";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DynamicLayer, InfoLayer, LeftRightDisplayLayer, GridOverlay, DetectionsLayer, RightInfoLayer } from "./RailInspectionLayers";
import { MagnifyLayer } from "./MagnifyLayer.js";
import KeyboardShortcuts from "../../SchemaInterface/KeyboardShortcuts";
import RailInspectionConverter from "../../util/RailInspectionConverter";
import { RailInspectionLoader } from "../../../datastores/railInspectionStore";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import RailInspectToolbar from "./RailInspectToolbar";
import RailInspectSourceModal from "./RailInspectSourceModal";
import { use } from "echarts";

const EMPTY_OBJ = {};
const EMPTY_ARRAY = [];
const TIMELINE_HEIGHT = 200;
const routeIDSelector = (state) => state.playlist.data.routeID;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => {
    return (
        state.railInspection.railInspectionImages.loadingInfo.percentageComplete > 0 &&
        state.railInspection.railInspectionImages.loadingInfo.sessionID === state.playlist.data.routeID
    );
};
const railDataPercentageCompleteSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.percentageComplete;
const railDataLoadCompleteSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const accessTokensSelector = (state) => state.access_token;
const naiveIndexSelector = (state) => state.playlist.position.currentIndex;
const naiveOffsetSelector = (state) => state.playlist.position.currentTimeOffset;
const mapLayerPreferenceSelector = (state) => state.userPreferences.aivrMapBaseLayer;
const reduxSessionIDSelector = (state) => state.playlist.data.routeID;
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const selectedBookmarkSelector = (state) => state.railInspection.selectedBookmark;
const detailViewOpenSelector = (state) => state.railInspection.detailViewOpen;
const currentImageAdjustmentsSelector = (state) => state.railInspection.imageAdjustments;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const playlistSelector = (state) => state.playlist;
const EMPTY_VIDEO_PLAYLIST = [];
const videoPlaylistSelector = (state) => _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex], EMPTY_VIDEO_PLAYLIST);
const requestDataSelector = (state) => ({
    index: state.playlist.position.requestedIndex,
    timeOffset: state.playlist.position.requestedTimeOffset,
    timestamp: state.playlist.position.requestTimestamp,
});
const requestedTimestampSelector = (state) => state.playlist.position.requestedTimestamp;
const currentTimestampSelector = (state) => state.playlist.position.timestamp;
const requestedHistoryTimestampSelector = (state) => _.get(state.playlist.consolidatedHistory, ["requestedTimestamp"], 0);
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const gridVisibleSelector = (state) => state.railInspection.gridVisible;
const gridSizeSelector = (state) => state.railInspection.gridSize;
const gridOffsetSelector = (state) => state.railInspection.gridOffset;
const annotationsSelector = (state) => state.railInspection.annotations.data;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const annotateModeEnabledSelector = (state) => state.railInspection.annotations.modeEnabled;
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const selectedInspectionMarkupSelector = (state) => state.railInspection.selectedMarkup;
const detectionsDataSelector = (state) => state.railInspection.detections.data;
const detectionsReviewFiltersSelector = (state) => state.railInspection.detections.reviewFilters;
const detectionsTypeFiltersSelector = (state) => state.railInspection.detections.typeFilters;
const allDetectionsFetchedSelector = (state) => state.railInspection.detections.allDetectionsFetched;
const currentDetectionIDSelector = (state) => state.railInspection.detections.selectedDetection;
const detectionsVisibleSelector = (state) => state.railInspection.detections.visibility;
const snapshotModalOpenSelector = (state) => state.railInspection.snapshot.open;
const gpsTimeOffsetsSelector = (state) => _.get(state.gpsTimeOffsets.offsets, state.playlist.position.sourceIndex, EMPTY_ARRAY);
const routeMetadataSelector = (state) => _.get(state, ["routeMetadata", "AREA_OF_INTEREST"], EMPTY_OBJ);
const userAnnotationTypesSelector = (state) => state.userAnnotationTypes;
const arrowLeftSelector = (state) => state.railInspection.directionArrowLeft;
const classificationDataSelector = (state) => state.railInspection.classifications.data;
const currentClassificationIDSelector = (state) => state.railInspection.classifications.selectedClassification;
const classificationScoreLimitSelector = (state) => state.railInspection.classifications.score;
const classificationsVisibleSelector = (state) => state.railInspection.classifications.visibility;
const classificationsTabOpenSelector = (state) => state.railInspection.classifications.classificationsTabOpen;
const targetDataSelector = (state) => state.railInspection.targetInspectionData;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const timelineWidgetOpenSelector = (state) => state.railInspection.timelineWidget.open;
const userPreferencesShowRailManualAlignmentSelector = (state) => _.get(state.userPreferences, "showRailManualAlignment", true);
const followDetectionsSelector = (state) => state.railInspection.detections.followDetections;
const detectionsExportOpenSelector = (state) => state.railInspection.detectionsExportOpen;
const detectionsExportIndexRangeSelector = (state) => state.railInspection.detectionsExportIndexRange;
const autoScrollActiveSelector = (state) => state.railInspection.autoScrollActive;
const assetNavigationOpenSelector = (state) => state.railInspection.detections.windowOpen;
const annotationVisibilitySelector = (state) => state.railInspection.annotations.visibility;
const sperryMarkerSelector = (state) => state.railInspection.sperryFaultMarkers.sperryMarkers;
const magnifyToggledSelector = (state) => state.railInspection.magnifyToggled;

const imageHeight = 62.5;
const imageWidth = 127.5;

const mapPinIcon = L.icon({
    iconUrl: "/bookmark-pin.png",
    iconSize: [30, 45], // size of the icon
    iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
});

const emptyObject = {};

const RailInspectBigScroller = ({
    match,
    markerToolActive,
    toggleMarkerTool,
    labelWindowOpen,
    setLabelWindowOpen,
    updatingID,
    setUpdatingID,
    pinCursorActive,
    videoless,
    inPlayer,
    newRailIndex,
    findModalVisible,
    annotationWindowOpen,
    setAnnotationWindowOpen,
    favouritePopoverOpen,
    secondaryRailImages,
    secondaryRailConfig,
    secondaryAlignment,
    manualAlignmentToolActive,
    setManualAlignmentToolActive,
    setSecondaryManualAdjustmentValues,
    secondaryManualAdjustmentValues,
    setSecondaryAlignmentAdjustment,
    secondaryAlignmentAdjustment,
    railManualAdjustmentLoading,
    setRailManualAdjustmentLoading,
    reportProblemVisible,
    currentHeaderHeight,
    toggleFindModalVisibility,
    toggleArchiveInspectionSessionModal,
    toggleRestoreArchivedInspectionSessionModal,
    showReportProblemDialog,
    clearSecondarySession,
    setSecondaryAlignment,
    toggleManualAlignmentTool,
    secondaryRailImageStatus,
    loadSecondarySession,
    assetNavigationWindowOpen,
    setAssetNavigationWindowOpen,
    bookmarksAndAnnotationsWindowOpen,
    setBookmarksAndAnnotationsWindowOpen,
    sperrySuspectNavigationWindowOpen,
    setSperrySuspectNavigationWindowOpen,
}) => {
    let store = useStore();
    const dispatch = useDispatch();
    const rejectionModalOpen = useSelector((state) => state.toggleRejectionModal) || false;
    const map = useRef(0);
    const leafletMap = useRef();
    const infoTilesRef = useRef();
    const leftRightLayerRef = useRef();
    const bookmarksLayerRef = useRef(L.layerGroup());
    const gridLayerRef = useRef();
    const scrollingRef = useRef(null);
    const scrollingCount = useRef(0);
    const mousePositionRef = useRef(null);

    const layersDictRef = useRef({});
    const railArrayOffsetRef = useRef(0);
    const drawingLayerRef = useRef(null);
    const drawingControlRef = useRef(null);
    const detectionsLayerRef = useRef(null);
    const rightInfoLayerRef = useRef(null);
    const dynamicLayerRef = useRef(null);

    const [mapOpen, setMapOpen] = useState(true);
    const [mapSize, setMapSize] = useState({ width: 250, height: 250 });
    const [previousMapSize, setPreviousMapSize] = useState({ width: 250, height: 250 });
    const [newMapSize, setNewMapSize] = useState({ width: 250, height: 250 });
    const [resizingMap, setResizingMap] = useState(false);
    const [draggablePosition, setDraggablePosition] = useState({ x: 0, y: 0 });
    const [draggableArea, setDraggableArea] = useState({ left: 0, top: 0, right: 0, bottom: 0 });
    const [updatingDraggablePosition, setUpdatingDraggablePosition] = useState(false);
    const autoScrollActive = useSelector(autoScrollActiveSelector);
    const [autoScrollSpeed, setAutoScrollSpeed] = useState(1);
    const [autoScrollDirection, setAutoScrollDirection] = useState("up");
    const [autoScrollSpeedUpdated, setAutoScrollSpeedUpdated] = useState(false);

    const [railInspectionConverter, setRailInspectionConverter] = useState(null);
    const [imageTimestampClicked, setImageTimestampClicked] = useState();
    const [imageSource, setImageSource] = useState();
    const [coords, setCoords] = useState();

    const [leafletTileOffset, setTileOffset] = useState(0);
    const [mapZoom, setMapZoom] = useState(1);
    const [mapPosition, setMapPosition] = useState(null);
    const [totalPrimaryRailWidth, setTotalPrimaryRailWidth] = useState(0);
    const [totalTileWidth, setTotalTileWidth] = useState(0);

    const [annotations, setAnnotations] = useState([]);
    const [annotationData, setAnnotationData] = useState({});
    const [editingAnnotationID, setEditingAnnotationID] = useState(null);
    const [dataLoadError, setDataLoadError] = useState(false);
    const [detectionsInvalidationTs, setDetectionInvalidationTs] = useState(0);
    const [sourceModalOpen, setSourceModalOpen] = useState(false);

    const accessToken = useSelector(accessTokensSelector);
    //const naiveIndex = useSelector(naiveIndexSelector);
    //const naiveOffset = useSelector(naiveOffsetSelector);
    //const mapLayerPreference = useSelector(mapLayerPreferenceSelector);
    const reduxSessionID = useSelector(reduxSessionIDSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const percentageComplete = useSelector(railDataPercentageCompleteSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const inspectionBookmarks = useSelector(bookmarksSelector);
    const selectedBookmark = useSelector(selectedBookmarkSelector);
    const detailViewOpen = useSelector(detailViewOpenSelector);
    const currentImageAdjustments = useSelector(currentImageAdjustmentsSelector);
    const playlist = useSelector(playlistSelector);
    const videoPlaylist = useSelector(videoPlaylistSelector);
    const requestData = useSelector(requestDataSelector);
    const requestedTimestamp = useSelector(requestedTimestampSelector);
    const currentTimestamp = useSelector(currentTimestampSelector);
    const requestedHistoryTimestamp = useSelector(requestedHistoryTimestampSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const railsFlipped = useSelector(railsFlippedSelector);
    const gridVisible = useSelector(gridVisibleSelector);
    const gridSize = useSelector(gridSizeSelector);
    const gridOffset = useSelector(gridOffsetSelector);
    const reduxAnnotations = useSelector(annotationsSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const annotateModeEnabled = useSelector(annotateModeEnabledSelector);
    const [requestedTimestampUrlLoaded, setRequestedTimestampUrlLoaded] = useState(true);
    const [newRequestData, setNewRequestData] = useState(requestData);
    const csrfToken = useSelector((state) => state.csrfToken);

    const classificationData = useSelector(classificationDataSelector);
    const detectionsData = useSelector(detectionsDataSelector);
    const detectionsReviewFilters = useSelector(detectionsReviewFiltersSelector);
    const detectionsTypeFilters = useSelector(detectionsTypeFiltersSelector);
    const selectedMarkup = useSelector(selectedInspectionMarkupSelector);
    const allDetectionsFetched = useSelector(allDetectionsFetchedSelector);
    const currentDetectionID = useSelector(currentDetectionIDSelector);
    const detectionsVisible = useSelector(detectionsVisibleSelector);
    const snapshotModalOpen = useSelector(snapshotModalOpenSelector);
    const defaultMarkers = useSelector((state) => state.defaultMarkersThresholds[reduxSessionID] || emptyObject);
    const routeMetadata = useSelector(routeMetadataSelector);
    const userAnnotationTypes = useSelector(userAnnotationTypesSelector);
    const arrowLeft = useSelector(arrowLeftSelector);
    const currentClassificationID = useSelector(currentClassificationIDSelector);
    const classificationScoreLimit = useSelector(classificationScoreLimitSelector);
    const classificationsVisible = useSelector(classificationsVisibleSelector);
    const classificationsTabOpen = useSelector(classificationsTabOpenSelector);
    const targetInspectionData = useSelector(targetDataSelector);
    const railDataLoadComplete = useSelector(railDataLoadCompleteSelector);
    const timelineWidgetOpen = useSelector(timelineWidgetOpenSelector);
    const userPreferencesShowRailManualAlignment = useSelector(userPreferencesShowRailManualAlignmentSelector);
    const followDetections = useSelector(followDetectionsSelector);
    const detectionsExportOpen = useSelector(detectionsExportOpenSelector);
    const detectionsExportIndexRange = useSelector(detectionsExportIndexRangeSelector);
    const [navbarCollapsed, setNavbarCollapsed] = useState(false);
    const assetNavigationOpen = useSelector(assetNavigationOpenSelector);
    const annotationsVisible = useSelector(annotationVisibilitySelector);
    const sperryMarkers = useSelector(sperryMarkerSelector);
    const magnifyToggled = useSelector(magnifyToggledSelector);

    const gpsTimeOffsets = useSelector(gpsTimeOffsetsSelector);

    const sessionID = match ? parseInt(match.params.sessionID) : reduxSessionID;
    const [requestedTimestampUrl, setRequestedTimestampUrl] = useState(match ? parseInt(match.params.ts) : 0);

    useEffect(() => {
        setRequestedTimestampUrlLoaded(requestedTimestampUrl ? false : true);
    }, [requestedTimestampUrl]);

    const railImageLoader = useRef();

    useEffect(() => {
        const railInspectionLoaderNotify = () => {
            dispatch(primaryRailImageConfig(railImageLoader.current.config));
            dispatch(
                updatePrimaryRailImages(
                    railImageLoader.current.data,
                    railImageLoader.current.loaded,
                    railImageLoader.current.percentageComplete,
                    railImageLoader.current.sessionID,
                ),
            );
            setDataLoadError(railImageLoader.current.failed);
        };
        railImageLoader.current = new RailInspectionLoader(railInspectionLoaderNotify);
        return () => {
            railImageLoader.current.abort();
        };
    }, [dispatch, railImageLoader]);

    const history = useHistory();

    const sessionAreasOfInterest = useMemo(() => {
        const allMetadata = routeMetadata[reduxSessionID] || [];
        return groupAreasOfInterest(allMetadata, userAnnotationTypes);
    }, [routeMetadata, reduxSessionID, userAnnotationTypes]);

    const closeBookmarkWindow = () => {
        setImageTimestampClicked(null);
        setImageSource(null);
        setCoords(null);
        setLabelWindowOpen(false);
    };

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject;
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const sessionSelected = useMemo(() => {
        return sessionID === reduxSessionID;
    }, [sessionID, reduxSessionID]);

    const railImageConfig = useMemo(() => {
        if (railImageConfigObject) {
            return railImageConfigObject;
        } else {
            return {};
        }
    }, [railImageConfigObject]);

    const dataEmpty = useMemo(() => {
        return !!(railDataLoaded && railImages !== null && railImages !== undefined && !railImages.length);
    }, [railDataLoaded, railImages]);

    const initialised = useMemo(() => {
        if (sessionSelected && railImageConfig && railImages && railDataLoaded && !dataEmpty) {
            return true;
        } else {
            return false;
        }
    }, [sessionSelected, railImageConfig, railImages, railDataLoaded, dataEmpty]);

    useEffect(() => {
        if (drawingControlRef && drawingControlRef.current) {
            if (annotateModeEnabled) {
                drawingControlRef.current.enable();
            } else {
                drawingControlRef.current.disable();
            }
        }
    }, [annotateModeEnabled]);

    const resetEditingID = useCallback(() => {
        setEditingAnnotationID(null);
    }, []);

    useEffect(() => {
        if (railImageConfigObject && railImageConfigObject.inspection_images && Object.keys(railImageConfigObject.inspection_images).length) {
            railImageConfigObject.inspection_images.forEach((item) => {
                const { name } = item;
                let opacity = 1;
                const visible = _.get(currentImageAdjustments, ["Primary", name, "visibility"], true);
                if (!visible) {
                    opacity = 0.25;
                }

                if (layersDictRef.current[name]) {
                    layersDictRef.current[name].setOpacity(opacity);
                    layersDictRef.current[name].redraw();
                }
            });
        }
    }, [currentImageAdjustments, railImageConfigObject]);

    useEffect(() => {
        setAnnotations(reduxAnnotations);
    }, [reduxAnnotations]);

    const horizontal = useMemo(() => {
        return !!railImageConfig.horizontal;
    }, [railImageConfig.horizontal]);

    useEffect(() => {
        if (horizontal) {
            setMapSize({ width: 350, height: 200 });
            setPreviousMapSize({ width: 350, height: 200 });
            setNewMapSize({ width: 350, height: 200 });
        } else {
            setMapSize({ width: 250, height: 250 });
            setPreviousMapSize({ width: 250, height: 250 });
            setNewMapSize({ width: 250, height: 250 });
        }
    }, [horizontal]);

    useEffect(() => {
        if (dynamicLayerRef.current) {
            dynamicLayerRef.current.setCsrfToken(csrfToken);
        }

        if (layersDictRef.current) {
            Object.values(layersDictRef.current).forEach((layer) => {
                layer.setCsrfToken(csrfToken);
            });
        }
    }, [csrfToken]);

    const sessionIsBackward = useMemo(() => {
        return (railImageConfig.imageryIsBackward && !railsFlipped) || (!railImageConfig.imageryIsBackward && railsFlipped);
    }, [railImageConfig, railsFlipped]);

    useEffect(() => {
        if (initialised) {
            if (horizontal) {
                if (sessionIsBackward) {
                    setAutoScrollDirection("left");
                } else {
                    setAutoScrollDirection("right");
                }
            } else {
                if (sessionIsBackward) {
                    setAutoScrollDirection("down");
                } else {
                    setAutoScrollDirection("up");
                }
            }
        }
    }, [initialised, sessionIsBackward, horizontal]);

    const filteredDetections = useMemo(() => {
        let filtered = [];
        if (detectionsData && detectionsData.length && detectionsReviewFilters) {
            filtered = filterMarkers(detectionsData, detectionsTypeFilters, detectionsReviewFilters, [], [], true, defaultMarkers);
        }
        return filtered;
    }, [detectionsData, detectionsReviewFilters, detectionsTypeFilters]);

    const filteredClassifications = useMemo(() => {
        return classificationData.filter((cl) => cl.score >= classificationScoreLimit);
    }, [classificationData, classificationScoreLimit]);

    const formattedDetections = useMemo(() => {
        let newDetections = {};
        if (filteredDetections && filteredDetections.length) {
            filteredDetections.forEach((detection) => {
                if (!newDetections[detection.image_timestamp]) {
                    newDetections[detection.image_timestamp] = [];
                }
                newDetections[detection.image_timestamp].push(detection);
            });
        }
        return newDetections;
    }, [filteredDetections]);

    const leftRightConfig = useMemo(() => {
        const leftRightConfig = {};
        if (railDataLoaded && routeLocationData && railImages && railImages.length) {
            const elrGroups = calculateElrGroups(railImages, routeLocationData, routeSystemID);
            elrGroups.forEach((elrGroup) => {
                let startPosition = elrGroup.startPosition;
                let endPosition = elrGroup.endPosition;

                let increasing = true;
                if (endPosition < startPosition) {
                    increasing = false;
                }

                for (let i = elrGroup.start; i <= elrGroup.end; i++) {
                    if ((!railsFlipped && !railImageConfig.imageryIsBackward) || (railsFlipped && railImageConfig.imageryIsBackward)) {
                        leftRightConfig[i] = {
                            top: increasing ? "left" : "right",
                            bottom: increasing ? "right" : "left",
                        };
                    } else {
                        leftRightConfig[i] = {
                            top: increasing ? "right" : "left",
                            bottom: increasing ? "left" : "right",
                        };
                    }
                }
            });
        }
        return leftRightConfig;
    }, [railDataLoaded, routeLocationData, railImages, railsFlipped, railImageConfig.imageryIsBackward, routeSystemID]);

    const resetMapBounds = useCallback(() => {
        let arrayOffset = railArrayOffsetRef.current;

        if (leafletMap.current && railDataLoadComplete && totalTileWidth) {
            let reversed = sessionIsBackward;

            let minLat = -imageHeight * (arrayOffset + 8.5);
            let maxLat = (railImages.length - arrayOffset + 5.5) * imageHeight;
            if (reversed) {
                const a = -minLat + imageHeight;
                minLat = -maxLat + imageHeight;
                maxLat = a;
            }

            const maxWidth = totalTileWidth / 16;

            let bounds = [
                [minLat, -maxWidth / 2],
                [maxLat, maxWidth + maxWidth / 2],
            ];

            if (horizontal) {
                let minLng = -imageWidth * (arrayOffset + 5.5);
                let maxLng = (railImages.length - arrayOffset + 3) * imageWidth;

                if (reversed) {
                    maxLng *= -1;
                    minLng *= -1;
                }

                bounds = [
                    [maxWidth / 2, minLng],
                    [-maxWidth * 1.5, maxLng],
                ];
            }

            leafletMap.current.setMaxBounds(bounds);
        }
    }, [horizontal, railImages.length, sessionIsBackward, totalTileWidth, railDataLoadComplete]);

    const noInspectionImages = useMemo(() => {
        return railDataLoadComplete && dataEmpty;
    }, [dataEmpty, railDataLoadComplete]);

    useEffect(() => {
        if (sessionSelected) {
            const mapOptions = {
                center: [0, imageWidth * 0.5],
                zoom: mapZoom,
                crs: L.CRS.Simple,
                attributionControl: false,
                fadeAnimation: false,
                inertiaMaxSpeed: 5000,
                maxBoundsViscosity: 0.5,
                zoomControl: !noInspectionImages, // Disable zooming if there is no images otherwise UI will crash
            };
            if (mapPosition) {
                mapOptions["center"] = L.latLng(mapPosition);
            }
            leafletMap.current = new L.Map(map.current, mapOptions);
            drawingControlRef.current = new L.Draw.Rectangle(leafletMap.current, { showArea: false });

            L.drawLocal.draw.handlers.rectangle.tooltip.start = "Click and drag to create an annotation";
            L.drawLocal.draw.handlers.simpleshape.tooltip.end = "";

            bookmarksLayerRef.current.addTo(leafletMap.current);

            return () => {
                leafletMap.current.remove();
                leafletMap.current = null;
                bookmarksLayerRef.current.remove();

                drawingControlRef.current = null;
            };
        }
    }, [sessionSelected, noInspectionImages]);

    useEffect(() => {
        resetMapBounds();
    }, [resetMapBounds]);

    const secondarySessionIsBackward = useMemo(() => {
        return secondaryRailConfig && ((secondaryRailConfig.imageryIsBackward && !railsFlipped) || (!secondaryRailConfig.imageryIsBackward && railsFlipped));
    }, [railsFlipped, secondaryRailConfig]);

    const alignSecondaryLayers = useCallback(() => {
        if (secondaryRailConfig && Object.keys(layersDictRef).length) {
            // const secondarySessionIsBackward = (secondaryRailConfig.imageryIsBackward && !railsFlipped) || (!secondaryRailConfig.imageryIsBackward && railsFlipped);
            let vertical_flip_state = sessionIsBackward;
            let horizontal_flip_state = sessionIsBackward !== secondarySessionIsBackward;

            if (secondaryAlignment.primaryIsUpstream !== secondaryAlignment.secondaryIsUpstream) {
                vertical_flip_state = !vertical_flip_state;
                horizontal_flip_state = !horizontal_flip_state;
            }

            Object.keys(layersDictRef.current).forEach(function (key) {
                if (layersDictRef.current[key].secondary) {
                    layersDictRef.current[key].setSecondaryAlignment(
                        secondaryAlignment.primaryIndex,
                        secondaryAlignment.secondaryIndex,
                        sessionIsBackward,
                        secondaryAlignment.adjustment,
                    );
                    layersDictRef.current[key].setReverse(vertical_flip_state);
                    layersDictRef.current[key].setHorizontalFlip(horizontal_flip_state);
                    layersDictRef.current[key].redraw();
                }
            });
        }
    }, [secondaryAlignment, secondaryRailConfig, sessionIsBackward, secondarySessionIsBackward]);

    const setSomeImagesMissing = () => {
        dispatch(setSomeMainRailInspectionImagesMissing(true));
    };

    const [currentMagLayer, setCurrentMagLayer] = useState(null);

    const renderTileLayer = useCallback(
        (_token) => {
            let xOffset = 0;
            let totalWidth = 0;
            let addedLayers = [];
            let imagesConfig = railImageConfig.inspection_images;
            if (_.findIndex(imagesConfig, { showInModal: true }) > -1) {
                imagesConfig = imagesConfig.filter((imageConfig) => !imageConfig.showInModal);
            }
            if (railsFlipped) {
                imagesConfig = _.clone(imagesConfig);
                imagesConfig.reverse();
            }

            const magLayers = [];

            imagesConfig.forEach(function (item, key) {
                const source = item.source;
                const name = item.name;
                const displayWidth = item.display_width;
                const srcWidth = item.src_width;
                const imgWidth = item.img_width;
                const srcX = item.src_x;
                let offsetY;
                if (sessionIsBackward) {
                    offsetY = item.offset_y_backward || 0;
                } else {
                    offsetY = item.offset_y_forward || 0;
                }

                let tileWidth = 1000;
                let tileHeight = 1000;

                const newLayer = new DynamicLayer({
                    minNativeZoom: 4,
                    maxNativeZoom: 4,
                    tileSize: L.point(tileWidth, tileHeight),
                    maxZoom: 6,
                    noWrap: true,
                    zoom: 0,
                    edgeBufferTiles: 2,
                });

                dynamicLayerRef.current = newLayer;

                let opacity = 1;
                const visible = _.get(currentImageAdjustments, ["Primary", name, "visibility"], true);
                if (!visible) {
                    opacity = 0.25;
                }

                let defaultBrightness = item.default_brightness || 0;
                const brightness = _.get(currentImageAdjustments, ["Primary", name, "brightness"], defaultBrightness);
                const contrast = _.get(currentImageAdjustments, ["Primary", name, "contrast"], 0);

                newLayer.setOpacity(opacity);
                newLayer.setBrightness(brightness);
                newLayer.setContrast(contrast);
                newLayer.setIndex(parseInt(key));
                newLayer.setDeviceName(source);
                newLayer.setDisplayWidth(displayWidth);
                newLayer.setSrcWidth(srcWidth);
                newLayer.setImgWidth(imgWidth);
                newLayer.setSrcX(srcX);
                newLayer.setXOffset(xOffset);
                newLayer.setReverse(sessionIsBackward);
                newLayer.setBackwardImage(railImageConfig.imageryIsBackward);
                newLayer.setFlipped(railsFlipped);
                newLayer.setArrayOffset(railArrayOffsetRef.current);
                newLayer.setup(
                    railImages,
                    horizontal,
                    filteredClassifications,
                    classificationsVisible && classificationsTabOpen,
                    targetInspectionData,
                    sperryMarkers,
                    false,
                    setSomeImagesMissing,
                );
                newLayer.setConstantOffset(offsetY);
                newLayer.setLeftRightConfig(leftRightConfig);
                newLayer.setCsrfToken(_token);

                if (detectionsExportOpen) {
                    newLayer.setIsExporting(detectionsExportOpen);
                    newLayer.setExportRange(detectionsExportIndexRange);
                }

                addedLayers.push(newLayer);

                layersDictRef.current[name] = newLayer;

                newLayer.addTo(leafletMap.current);

                const magWidth = horizontal ? tileWidth : tileWidth * 3;
                const magHeight = horizontal ? tileHeight : tileHeight;

                const magLayerMap = new DynamicLayer({
                    minNativeZoom: 4,
                    maxNativeZoom: 4,
                    tileSize: L.point(magWidth, magHeight),
                    maxZoom: 6,
                    noWrap: true,
                    zoom: 6,
                });

                magLayerMap.setOpacity(opacity);
                magLayerMap.setBrightness(brightness);
                magLayerMap.setContrast(contrast);
                magLayerMap.setIndex(parseInt(key));
                magLayerMap.setDeviceName(source);
                magLayerMap.setDisplayWidth(displayWidth);
                magLayerMap.setSrcWidth(srcWidth);
                magLayerMap.setImgWidth(imgWidth);
                magLayerMap.setSrcX(srcX);
                magLayerMap.setXOffset(xOffset);
                magLayerMap.setReverse(sessionIsBackward);
                magLayerMap.setBackwardImage(railImageConfig.imageryIsBackward);
                magLayerMap.setFlipped(railsFlipped);
                magLayerMap.setArrayOffset(railArrayOffsetRef.current);
                magLayerMap.setup(
                    railImages,
                    horizontal,
                    filteredClassifications,
                    classificationsVisible && classificationsTabOpen,
                    targetInspectionData,
                    false,
                    setSomeImagesMissing,
                );
                magLayerMap.setConstantOffset(offsetY);
                magLayerMap.setLeftRightConfig(leftRightConfig);
                magLayerMap.setCsrfToken(_token);

                layersDictRef.current[name + ".mag"] = magLayerMap;

                magLayers.push(magLayerMap);

                xOffset += displayWidth;
            });
            const magLayer = new MagnifyLayer({
                layers: magLayers,
                fixedPosition: false,
                latLng: [0, 0],
                xOffset: 0,
                yOffset: 0,
                currentMousePosition: mousePositionRef,
            });

            setCurrentMagLayer(magLayer);

            addedLayers.forEach((layer) => {
                layer.setTotalWidth(xOffset);
            });

            totalWidth = xOffset;

            if (!totalPrimaryRailWidth) {
                setTotalPrimaryRailWidth(totalWidth);
            }

            console.log(secondaryRailConfig, secondaryRailImages);

            if (secondaryRailConfig && secondaryRailConfig.inspection_images && secondaryRailImages) {
                xOffset = 0;
                addedLayers = [];
                // const secondarySessionIsBackward = (secondaryRailConfig.imageryIsBackward && !railsFlipped) || (!secondaryRailConfig.imageryIsBackward && railsFlipped);

                Object.keys(secondaryRailConfig.inspection_images).forEach(function (key) {
                    const item = secondaryRailConfig.inspection_images[key];
                    const source = item.source;
                    const name = item.name;
                    const displayWidth = item.display_width;
                    const srcWidth = item.src_width;
                    const imgWidth = item.img_width;
                    const srcX = item.src_x;
                    let offsetY;
                    if (secondarySessionIsBackward) {
                        offsetY = item.offset_y_backward || 0;
                    } else {
                        offsetY = item.offset_y_forward || 0;
                    }

                    let tileWidth = displayWidth;
                    let tileHeight = 1000;

                    if (horizontal) {
                        tileHeight = displayWidth;
                        tileWidth = 1000;
                    }

                    const newLayer = new DynamicLayer({
                        minNativeZoom: 4,
                        maxNativeZoom: 4,
                        tileSize: L.point(tileWidth, tileHeight),
                        maxZoom: 6,
                        noWrap: true,
                    });

                    let opacity = 1;
                    const visible = _.get(currentImageAdjustments, ["Secondary", name, "visibility"], true);
                    if (!visible) {
                        opacity = 0.25;
                    }

                    let defaultBrightness = item.default_brightness || 0;
                    const brightness = _.get(currentImageAdjustments, ["Secondary", name, "brightness"], defaultBrightness);
                    const contrast = _.get(currentImageAdjustments, ["Secondary", name, "contrast"], 0);

                    newLayer.setOpacity(opacity);
                    newLayer.setBrightness(brightness);
                    newLayer.setContrast(contrast);
                    newLayer.setIndex(parseInt(key));
                    newLayer.setDeviceName(source);
                    newLayer.setDisplayWidth(displayWidth);
                    newLayer.setSrcWidth(srcWidth);
                    newLayer.setImgWidth(imgWidth);
                    newLayer.setSrcX(srcX);
                    newLayer.setXOffset(xOffset);
                    newLayer.setBaseXOffset(totalWidth + 150);
                    newLayer.setReverse(sessionIsBackward);
                    newLayer.setSecondary(true);
                    newLayer.setConstantOffset(offsetY);
                    newLayer.setArrayOffset(railArrayOffsetRef.current);
                    newLayer.setCsrfToken(csrfToken);

                    xOffset += displayWidth;
                    newLayer.setup(secondaryRailImages.data, horizontal, filteredClassifications, classificationsVisible && classificationsTabOpen, true);

                    addedLayers.push(newLayer);

                    layersDictRef.current["secondary." + name] = newLayer;
                    newLayer.addTo(leafletMap.current);
                });

                addedLayers.forEach((layer) => {
                    layer.setTotalWidth(xOffset);
                });
                totalWidth += xOffset + 150;

                alignSecondaryLayers();
            }
            return totalWidth;
        },
        [
            targetInspectionData,
            horizontal,
            railImageConfig.inspection_images,
            railImageConfig.imageryIsBackward,
            railImages,
            sessionIsBackward,
            currentImageAdjustments,
            secondaryRailConfig,
            secondaryRailImages,
            filteredClassifications,
            railsFlipped,
            alignSecondaryLayers,
            classificationsVisible,
            classificationsTabOpen,
            secondarySessionIsBackward,
            sperryMarkers,
            setCurrentMagLayer,
            detectionsExportOpen,
            detectionsExportIndexRange,
        ],
    );

    useEffect(() => {
        if (magnifyToggled && currentMagLayer && leafletMap.current) {
            const magLayer = currentMagLayer;
            const currentMap = leafletMap.current;

            currentMap.addLayer(magLayer);

            return () => {
                try {
                    currentMap.removeLayer(magLayer);
                } catch (e) {
                    console.error(e);
                }
            };
        }
    }, [currentMagLayer, dispatch, magnifyToggled]);

    useEffect(() => {
        return () => {
            dispatch(setIsMagnifyToggled(false));
        };
    }, [dispatch]);

    const getImageConfigRatio = useCallback(
        (detection) => {
            let imagesConfig = _.get(railImageConfig, "inspection_images", []);
            const sourceDevice = _.get(detection, "source_device");
            const detectionBbox = _.get(detection, "bbox", []);

            let bbLeft = detectionBbox[0];
            let bbTop = detectionBbox[1];
            let width = detectionBbox[2];
            let height = detectionBbox[3];

            let imageWidthPx;

            if (horizontal) {
                imageWidthPx = imagesConfig.reduce((acc, cur) => acc + cur.display_width, 0);
                let yOffset = 0;
                let xOffset = 0;
                _.forEach(imagesConfig, (image) => {
                    const imgSource = _.get(image, "source", null);
                    if (imgSource === sourceDevice) {
                        if (bbLeft >= image.src_x && bbLeft < image.src_width + image.src_x) {
                            bbLeft -= image.src_x;
                            bbLeft = (bbLeft / image.src_width) * image.display_width;
                            width = (width / image.src_width) * image.display_width;
                            if (sessionIsBackward) {
                                xOffset = (image.offset_y_backward || 0) * 1000;
                            } else {
                                xOffset = (image.offset_y_forward || 0) * 1000;
                            }
                            bbTop -= xOffset;
                            return false;
                        }
                    }
                    yOffset += image.display_width;
                });

                bbLeft += yOffset;
            } else {
                _.forEach(imagesConfig, (image) => {
                    const imgSource = _.get(image, "source", null);

                    if (imgSource === sourceDevice) {
                        imageWidthPx = (image.img_width / image.src_width) * image.display_width;
                        const squashRatio = imageWidthPx / image.img_width;
                        bbLeft *= squashRatio;
                        width *= squashRatio;
                    }
                });
            }

            return { imageWidthPx, bbLeft, bbTop, height, width };
        },
        [railImageConfig, horizontal, sessionIsBackward],
    );

    const updateCurrentDetectionBasedOnMapPosition = useCallback(
        (currentDetections) => {
            const center = leafletMap.current.getCenter();
            const bounds = leafletMap.current.getBounds();

            const visibleDetections = [];

            _.forEach(currentDetections, (detection) => {
                const currentID = _.get(detection, "id");

                if (!currentID) {
                    return;
                }

                const { imageWidthPx, bbLeft, bbTop, height, width } = getImageConfigRatio(detection);

                const imageWidthCoord = imageHeight * (imageWidthPx / 1000);

                let arrayOffset = railArrayOffsetRef.current;
                let assetIndex = _.findIndex(railImages, { timestamp: detection.image_timestamp });
                let offset = assetIndex - arrayOffset;

                let topLat = imageHeight * (bbTop / 1000);
                let bottomLat = imageHeight * ((bbTop + height) / 1000);
                let rightLng = imageWidthCoord * ((bbLeft + width) / imageWidthPx);
                let leftLng = imageWidthCoord * (bbLeft / imageWidthPx);

                if (horizontal) {
                    topLat = imageWidthCoord * (bbLeft / imageWidthPx);
                    bottomLat = imageWidthCoord * ((bbLeft + width) / imageWidthPx);
                    leftLng = imageHeight - imageHeight * ((bbTop + height) / 1000);
                    rightLng = imageHeight - imageHeight * (bbTop / 1000);

                    if (railsFlipped) {
                        bottomLat = imageWidthCoord - imageWidthCoord * (bbLeft / imageWidthPx);
                        topLat = imageWidthCoord - imageWidthCoord * ((bbLeft + width) / imageWidthPx);
                    }

                    if (sessionIsBackward) {
                        leftLng = imageHeight - imageHeight * (1 - bbTop / 1000);
                        rightLng = imageHeight - imageHeight * (1 - (bbTop + height) / 1000);
                        leftLng -= imageHeight * offset;
                        rightLng -= imageHeight * offset;
                    } else {
                        leftLng += imageHeight * offset;
                        rightLng += imageHeight * offset;
                    }
                } else {
                    if (sessionIsBackward) {
                        bottomLat = imageHeight * (1 - bbTop / 1000);
                        topLat = imageHeight * (1 - (bbTop + height) / 1000);
                        bottomLat += imageHeight * offset;
                        topLat += imageHeight * offset;
                    } else {
                        topLat -= imageHeight * offset;
                        bottomLat -= imageHeight * offset;
                    }
                }

                if (center.lat >= -bottomLat && center.lat <= -topLat && center.lng >= leftLng && center.lng <= rightLng) {
                    // This will select detection if in the centre and will run even if two detections are still visible on the screen
                    if (detection.id !== currentDetectionID) {
                        dispatch(setCurrentDetection(currentID));
                    }
                }

                const { _northEast, _southWest } = bounds;

                if (
                    ((-bottomLat > _southWest.lat && -bottomLat < _northEast.lat) ||
                        (-topLat > _southWest.lat && -topLat < _northEast.lat) ||
                        (-bottomLat < _southWest.lat && -topLat > _northEast.lat)) &&
                    ((leftLng > _southWest.lng && leftLng < _northEast.lng) ||
                        (rightLng > _southWest.lng && rightLng < _northEast.lng) ||
                        (leftLng < _southWest.lng && rightLng > _northEast.lng))
                ) {
                    // Detection visible
                    visibleDetections.push(currentID);
                }
            });

            if (visibleDetections[0] !== currentDetectionID && !visibleDetections.includes(currentDetectionID)) {
                // Selects detection if its the only one visible and within the current image
                dispatch(setCurrentDetection(visibleDetections[0]));
            }
        },
        [
            filteredDetections,
            leafletMap,
            dispatch,
            currentDetectionID,
            getImageConfigRatio,
            sessionIsBackward,
            horizontal,
            railsFlipped,
            railImages,
            railArrayOffsetRef,
            formattedDetections,
        ],
    );

    useEffect(() => {
        return () => {
            dispatch(setIsMagnifyToggled(false));
        };
    }, [dispatch]);

    const renderDetectionsLayer = useCallback(() => {
        if (!detectionsVisible) {
            return;
        }

        let imagesConfig = railImageConfig.inspection_images;

        if (railsFlipped) {
            imagesConfig = _.clone(imagesConfig);
            imagesConfig.reverse();
        }

        let tileWidth = 1000;
        let tileHeight = 1000;

        const layer = new DetectionsLayer({
            tileSize: L.point(tileWidth, tileHeight),
            maxZoom: 6,
            minNativeZoom: 4,
            maxNativeZoom: 4,
        });

        layer.on("tileunload", (event) => layer.destroyTile(event.tile));
        layer.setReverse(sessionIsBackward);
        layer.setArrayOffset(railArrayOffsetRef.current);
        layer.setup(railImages, formattedDetections, horizontal);
        layer.setImagesConfig(imagesConfig);
        layer.setBackwardImage(railImageConfig.imageryIsBackward);
        layer.setFlipped(railsFlipped);
        layer.setHorizontalFlip(railsFlipped);
        layer.setClickHandler((detectionID) => {
            dispatch(setCurrentInspectionMarkup("detection", detectionID));
        });
        layer.addTo(leafletMap.current);
        detectionsLayerRef.current = layer;
        setDetectionInvalidationTs(Date.now());
    }, [
        detectionsVisible,
        railImageConfig.inspection_images,
        railImageConfig.imageryIsBackward,
        horizontal,
        sessionIsBackward,
        railImages,
        formattedDetections,
        railsFlipped,
        dispatch,
    ]);

    const renderInfoLayer = useCallback(
        (totalWidth) => {
            let infoTileWidth = imageWidth * 5;
            let infoTileHeight = 1000;
            if (horizontal) {
                infoTileWidth = 1000;
                infoTileHeight = imageWidth * 10;
            }
            const infoTiles = new InfoLayer({
                tileSize: L.point(infoTileWidth, infoTileHeight),
                maxZoom: 6,
                minNativeZoom: 4,
                maxNativeZoom: 4,
            });
            infoTiles.setReverse(sessionIsBackward);
            infoTiles.on("tileunload", (event) => infoTiles.destroyTile(event.tile));
            infoTiles.setXOffset(0);
            infoTiles.setArrayOffset(railArrayOffsetRef.current);

            if (railImages) {
                infoTiles.setup(railImages, store, horizontal, totalWidth, arrowLeft);
            }
            infoTiles.addTo(leafletMap.current);
            infoTilesRef.current = infoTiles;
            return infoTileWidth;
        },
        [horizontal, railImages, sessionIsBackward, store, arrowLeft],
    );

    const renderRightInfoLayer = useCallback(
        (totalWidth, infoTileWidth) => {
            if (!totalWidth) {
                return null;
            }

            const rightInfoLayer = new RightInfoLayer({
                tileSize: L.point(totalWidth, 1000),
                maxZoom: 6,
                minNativeZoom: 4,
                maxNativeZoom: 4,
            });

            rightInfoLayer.on("tileunload", (event) => rightInfoLayer.destroyTile(event.tile));
            rightInfoLayer.setReverse(sessionIsBackward);
            rightInfoLayer.setArrayOffset(railArrayOffsetRef.current);
            if (railImages) {
                rightInfoLayer.setup(railImages, sessionAreasOfInterest);
            }
            rightInfoLayer.addTo(leafletMap.current);
            rightInfoLayerRef.current = rightInfoLayer;
        },
        [railImages, sessionIsBackward, sessionAreasOfInterest],
    );

    const renderLeftRightLayer = useCallback(
        (totalWidth, infoTileWidth) => {
            let tileSize = [infoTileWidth, totalWidth];
            if (!horizontal) {
                tileSize = [1000, 1000];
            }
            const leftRightLayer = new LeftRightDisplayLayer({
                tileSize: L.point(tileSize[0], tileSize[1]),
                maxZoom: 6,
                minNativeZoom: 4,
                maxNativeZoom: 4,
            });

            leftRightLayer.on("tileunload", (event) => leftRightLayer.destroyTile(event.tile));
            leftRightLayer.setReverse(sessionIsBackward);
            leftRightLayer.setArrayOffset(railArrayOffsetRef.current);
            leftRightLayer.setup(railImages, leftRightConfig, horizontal, totalWidth);
            leftRightLayer.addTo(leafletMap.current);

            leftRightLayerRef.current = leftRightLayer;
        },
        [horizontal, leftRightConfig, railImages, sessionIsBackward],
    );

    const onAnnotationClick = useCallback((annotation) => {
        if (!annotation.is_users) {
            return;
        }
        setEditingAnnotationID(annotation.id);
        setAnnotationWindowOpen(true);
    }, []);

    const renderAnnotationLayer = useCallback(() => {
        drawingLayerRef.current = new L.FeatureGroup();
        if (!annotationsVisible) {
            return;
        }

        if (annotations && annotations.length && railInspectionConverter) {
            let imagesConfig = railImageConfig.inspection_images;

            annotations.forEach((annotation) => {
                const bbox = JSON.parse(annotation.bbox);
                let source = annotation.image_source;

                if (horizontal && !sessionIsBackward && railsFlipped) {
                    imagesConfig = _.clone(imagesConfig);
                    imagesConfig.reverse();
                    source = imagesConfig.length - 1 - source;
                }

                const item = imagesConfig[source];

                let annotationStartLocation = railInspectionConverter.sourceTimestampToCoordinates(annotation.image_timestamp, source, [bbox[0], bbox[1]]);
                if (horizontal) {
                    if (railsFlipped) {
                        annotationStartLocation = railInspectionConverter.sourceTimestampToCoordinates(annotation.image_timestamp, source, [
                            bbox[0],
                            item.display_width - bbox[1],
                        ]);
                    }
                }

                if (!annotationStartLocation) {
                    return;
                }

                let topLeft = [annotationStartLocation[0], annotationStartLocation[1]];
                let leftMost = topLeft;

                let bottomRightBbox = [bbox[0] - bbox[2], bbox[1] + bbox[3]];
                if (!horizontal) {
                    if (sessionIsBackward) {
                        bottomRightBbox = [bbox[0] - bbox[2], bbox[1] - bbox[3]];
                    }
                } else {
                    bottomRightBbox = [bbox[0] + bbox[3], bbox[1] + bbox[2]];
                    if (railsFlipped) {
                        bottomRightBbox = [bbox[0] + bbox[3], item.display_width - bbox[1] - bbox[2]];
                    }
                }

                const annotationEndLocation = railInspectionConverter.sourceTimestampToCoordinates(annotation.image_timestamp, source, bottomRightBbox);
                const bottomRight = [annotationEndLocation[0], annotationEndLocation[1]];
                if (!horizontal) {
                    if (sessionIsBackward) {
                        leftMost = bottomRight;
                    }
                } else {
                    if (sessionIsBackward && !railsFlipped) {
                        leftMost = _.clone(bottomRight);
                        leftMost[0] += bbox[2] / 16;
                    } else if (!sessionIsBackward && railsFlipped) {
                        leftMost = _.clone(bottomRight);
                        leftMost[1] -= bbox[3] / 16;
                    } else if (sessionIsBackward && railsFlipped) {
                        leftMost = _.clone(topLeft);
                        leftMost[0] += bbox[2] / 16;
                        leftMost[1] -= bbox[3] / 16;
                    }
                }

                const rect = L.rectangle([topLeft, bottomRight], { weight: 2 });
                rect.on("click", () => onAnnotationClick(annotation));

                let markerText = "";
                if (annotation.annotation_type === -1) {
                    markerText = annotation.custom_label;
                } else if (annotation.annotation_type) {
                    const annotationType = _.find(annotationTypes, (type) => parseInt(type.id) === parseInt(annotation.annotation_type));
                    if (annotationType) {
                        markerText = annotationType.type;
                    }
                }

                if (markerText) {
                    const invisibleMarker = L.circleMarker(leftMost, { opacity: 0, radius: 0 });
                    invisibleMarker.bindTooltip(markerText, {
                        permanent: true,
                        className: "AnnotationLabel dark",
                        offset: [10, 0],
                        direction: "top",
                        opacity: 0.8,
                    });
                    drawingLayerRef.current.addLayer(invisibleMarker);
                }
                drawingLayerRef.current.addLayer(rect);
            });
        }

        leafletMap.current.addLayer(drawingLayerRef.current);
    }, [annotations, onAnnotationClick, annotationTypes, sessionIsBackward, horizontal, railInspectionConverter, annotationsVisible]);

    useEffect(() => {
        let newRailInspectionConverter = new RailInspectionConverter({
            railImages,
            leafletTileOffset,
            railImageConfig,
            horizontal,
            sessionIsBackward: sessionIsBackward,
            flipped: railsFlipped,
        });

        setRailInspectionConverter(newRailInspectionConverter);
        return () => {
            setRailInspectionConverter(null);
        };
    }, [horizontal, leafletTileOffset, railImageConfig, sessionIsBackward, railImages, setRailInspectionConverter]);

    const panToIndex = useCallback(
        (newRailImageIndex, zoom = false, detection) => {
            if (autoScrollActive) {
                dispatch(setAutoScrollActive(false));
            }

            if (leafletMap.current) {
                if (newRailImageIndex < 0 || !newRailImageIndex) {
                    newRailImageIndex = 0;
                }

                let arrayOffset = railArrayOffsetRef.current;

                let animate = true;

                if (Math.abs(arrayOffset - newRailImageIndex) >= 5000) {
                    animate = false;
                }

                arrayOffset = newRailImageIndex;
                railArrayOffsetRef.current = arrayOffset;

                if (Object.keys(layersDictRef).length) {
                    Object.keys(layersDictRef.current).forEach(function (key) {
                        layersDictRef.current[key].setArrayOffset(newRailImageIndex);
                        layersDictRef.current[key].redraw();
                    });
                }

                setTileOffset(newRailImageIndex);

                if (infoTilesRef.current) {
                    infoTilesRef.current.setArrayOffset(arrayOffset);
                    infoTilesRef.current.redraw();
                }
                if (leftRightLayerRef.current) {
                    leftRightLayerRef.current.setArrayOffset(arrayOffset);
                    leftRightLayerRef.current.redraw();
                }
                if (detectionsLayerRef.current) {
                    detectionsLayerRef.current.setArrayOffset(arrayOffset);
                    detectionsLayerRef.current.redraw();
                }
                if (gridLayerRef.current) {
                    gridLayerRef.current.redraw();
                }
                if (rightInfoLayerRef.current) {
                    rightInfoLayerRef.current.setArrayOffset(arrayOffset);
                    rightInfoLayerRef.current.redraw();
                }

                let newLat = (newRailImageIndex - arrayOffset - 0.5) * imageHeight;

                if (followDetections && detection && !_.isEmpty(detection)) {
                    let newLng;
                    const { imageWidthPx, bbLeft, bbTop, height, width } = getImageConfigRatio(detection);

                    let bbHeightPercentage = (bbTop + height / 2) / 1000;
                    let bbWidthPercentage = (bbLeft + width / 2) / imageWidthPx;
                    const imageWidthCoord = imageHeight * (imageWidthPx / 1000);

                    newLat = imageHeight * bbHeightPercentage;
                    newLng = imageWidthCoord * bbWidthPercentage;

                    if (horizontal) {
                        newLng = imageHeight - imageHeight * bbHeightPercentage;
                        newLat = imageWidthCoord * bbWidthPercentage;

                        if (sessionIsBackward) {
                            newLng = imageHeight - imageHeight * (1 - bbHeightPercentage);
                        }
                        if (railsFlipped) {
                            newLat = imageWidthCoord - newLat;
                        }
                    } else {
                        if (sessionIsBackward) {
                            newLat = imageHeight * (1 - bbHeightPercentage);
                        }
                    }

                    leafletMap.current.panTo([-newLat, newLng], {
                        animate: animate,
                        easeLinearity: 1,
                    });
                } else if (newLat !== null) {
                    if (sessionIsBackward) {
                        newLat = -(newLat + imageHeight);
                    }
                    if (newLat) {
                        let lng = leafletMap.current.getCenter().lng;
                        if (horizontal) {
                            lng = newLat + imageHeight;
                            newLat = leafletMap.current.getCenter().lat;
                        }
                        if (!zoom) {
                            leafletMap.current.panTo([newLat, lng], {
                                animate: animate,
                                easeLinearity: 1,
                            });
                        } else {
                            leafletMap.current.setView([newLat, lng], 3, {
                                animate: animate,
                                easeLinearity: 1,
                            });
                        }
                    }
                }
                resetMapBounds();
            }
        },
        [
            railImages,
            dispatch,
            leftRightConfig,
            sessionIsBackward,
            horizontal,
            autoScrollActive,
            followDetections,
            railImageConfig,
            railsFlipped,
            getImageConfigRatio,
        ],
    );

    useEffect(() => {
        if (railDataLoadComplete && routeLocationData && routeLocationData.length) {
            let timestampToJumpTo = 0;
            let railImageIndex = 0;
            if (requestedTimestamp || (requestedTimestampUrl && !requestedTimestampUrlLoaded)) {
                timestampToJumpTo = requestedTimestamp || requestedTimestampUrl || 0;
                if (timestampToJumpTo < 9999999999) {
                    timestampToJumpTo *= 1000;
                }
                railImageIndex = binarySearch(timestampToJumpTo, railImages, (image) => image.timestamp);

                // if timestampToJumpTo is outside the session timestamp range set railImageIndex to 0 to move session to the start
                // we remove ts from url and let RailInspectionComponent handle that (add new ts at the end of the url)
                const lastTs = _.get(_.last(railDataObject), "timestamp");
                if (timestampToJumpTo > lastTs) {
                    const newUrlPath = `/rail-inspection/${reduxSessionID}/`;
                    history.replace(newUrlPath);
                    railImageIndex = 0;
                }

                panToIndex(railImageIndex);
                dispatch(clearRequestedTimestamp());
                setRequestedTimestampUrlLoaded(true);
            }

            if (!timestampToJumpTo || !railImageIndex) {
                return;
            }

            if (videoless && playlist.data.data) {
                const location = getVideolessLocation(timestampToJumpTo, playlist.data.data);
                dispatch(currentPlaylistPosition(railImageIndex, location, 0));
            } else if (videoPlaylist && videoPlaylist.length) {
                const [videoIndex, timeOffset, location] = getOffsetAdjustedPosition(timestampToJumpTo, videoPlaylist, gpsTimeOffsets, true);
                dispatch(currentPlaylistPosition(videoIndex, location, timeOffset));
            }
        }
    }, [
        railDataObject,
        requestedTimestampUrl,
        panToIndex,
        requestedTimestamp,
        videoPlaylist,
        railImages,
        dispatch,
        playlist.data.data,
        gpsTimeOffsets,
        videoless,
        railDataLoadComplete,
        requestedTimestampUrlLoaded,
        reduxSessionID,
        routeLocationData,
        history,
    ]);

    useEffect(() => {
        if (leafletMap.current) {
            if (initialised) {
                const totalWidth = renderTileLayer(csrfToken);
                const infoTileWidth = renderInfoLayer(totalWidth);
                renderLeftRightLayer(totalWidth, infoTileWidth);
                renderRightInfoLayer(totalWidth, infoTileWidth);
                if (assetNavigationOpen) {
                    renderDetectionsLayer();
                }
                setTotalTileWidth(totalWidth);
                if (gridLayerRef.current) {
                    gridLayerRef.current.bringToFront();
                }

                if (history.location?.state?.bookmarkID) {
                    const bookmarkID = history.location.state.bookmarkID;
                    dispatch(selectBookmark(bookmarkID));
                    history.replace();
                } else if (history.location?.state?.annotationID) {
                    const annotationID = history.location.state.annotationID;
                    dispatch(setCurrentInspectionMarkup("annotation", annotationID));
                    history.replace();
                }

                return () => {
                    Object.keys(layersDictRef.current).forEach(function (key) {
                        layersDictRef.current[key].remove();
                    });
                    layersDictRef.current = {};

                    if (infoTilesRef && infoTilesRef.current) {
                        infoTilesRef.current.remove();
                        infoTilesRef.current = null;
                    }

                    if (leftRightLayerRef && leftRightLayerRef.current) {
                        leftRightLayerRef.current.remove();
                        leftRightLayerRef.current = null;
                    }

                    if (detectionsLayerRef && detectionsLayerRef.current) {
                        detectionsLayerRef.current.remove();
                        detectionsLayerRef.current = null;
                    }

                    if (rightInfoLayerRef && rightInfoLayerRef.current) {
                        rightInfoLayerRef.current.remove();
                        rightInfoLayerRef.current = null;
                    }

                    setCurrentMagLayer(null);
                };
            }
        }
    }, [
        panToIndex,
        renderTileLayer,
        dispatch,
        history,
        initialised,
        renderDetectionsLayer,
        renderInfoLayer,
        renderLeftRightLayer,
        renderRightInfoLayer,
        assetNavigationOpen,
        setCurrentMagLayer,
    ]);

    useEffect(() => {
        if (leafletMap.current && initialised) {
            renderAnnotationLayer();

            return () => {
                if (drawingLayerRef && drawingLayerRef.current) {
                    drawingLayerRef.current.remove();
                }
            };
        }
    }, [renderAnnotationLayer, initialised]);

    useEffect(() => {
        if (sessionSelected) {
            const currentRouteID = routeIDSelector(store.getState());
            dispatch(goToBounds(currentRouteID));
        }
    }, [dispatch, store, sessionSelected]);

    useEffect(() => {
        if (Object.keys(layersDictRef.current).length) {
            Object.keys(layersDictRef.current).forEach(function (key) {
                if (!layersDictRef.current[key].secondary) {
                    layersDictRef.current[key].setup(
                        railImages,
                        horizontal,
                        filteredClassifications,
                        classificationsVisible && classificationsTabOpen,
                        targetInspectionData,
                    );
                }
            });
        }

        if (infoTilesRef.current) {
            infoTilesRef.current.setup(railImages, store, horizontal);
        }

        resetMapBounds();
    }, [railImages, secondaryRailImages, store, horizontal, resetMapBounds, targetInspectionData]);

    useEffect(() => {
        if (detectionsLayerRef.current && allDetectionsFetched) {
            detectionsLayerRef.current.setup(railImages, formattedDetections, horizontal);
        }
    }, [formattedDetections, horizontal, railImages, allDetectionsFetched]);

    const onBookmarkClick = useCallback(
        (bookmarkID) => {
            setUpdatingID(bookmarkID);
            setLabelWindowOpen(true);
        },
        [setUpdatingID, setLabelWindowOpen],
    );

    useEffect(() => {
        if (leafletMap.current && inspectionBookmarks && inspectionBookmarks.length && railImages.length && railInspectionConverter) {
            inspectionBookmarks.forEach((bookmark) => {
                const coordinates = railInspectionConverter.sourceTimestampToCoordinates(bookmark.image_timestamp, bookmark.source, bookmark.image_coordinates);
                if (!coordinates) {
                    return;
                }
                let bookmarkMarker = new L.marker(coordinates, { icon: mapPinIcon })
                    .bindTooltip(bookmark.description)
                    .on("click", () => (bookmark.is_users ? onBookmarkClick(bookmark.id) : null));

                bookmarksLayerRef.current.addLayer(bookmarkMarker);
            });
        }
        return () => {
            bookmarksLayerRef.current.clearLayers();
        };
    }, [onBookmarkClick, leafletMap, inspectionBookmarks, railImages, leafletTileOffset, sessionIsBackward, horizontal, railInspectionConverter]);

    useEffect(() => {
        if (railDataLoaded) {
            if (Object.keys(layersDictRef).length) {
                Object.keys(layersDictRef.current).forEach(function (key) {
                    layersDictRef.current[key].redraw();
                });
            }
            if (infoTilesRef.current) {
                infoTilesRef.current.redraw();
            }
            if (gridLayerRef.current) {
                gridLayerRef.current.redraw();
            }
        }
    }, [railDataLoaded]);

    const cancelAnnotationCreation = useCallback(() => {
        let newAnnotations = _.cloneDeep(annotations);
        newAnnotations = newAnnotations.filter((ann) => ann.id !== -2);
        setAnnotations(newAnnotations);
    }, [annotations]);

    useEffect(() => {
        if (leafletMap.current) {
            if (gridLayerRef.current) {
                gridLayerRef.current.remove();
                gridLayerRef.current = null;
            } else {
                if (gridVisible) {
                    let gridTileWidth = 1000;
                    let gridTileHeight = 1000;
                    gridLayerRef.current = new GridOverlay({
                        tileSize: L.point(gridTileWidth, gridTileHeight),
                        maxZoom: 6,
                        minNativeZoom: 4,
                        maxNativeZoom: 4,
                    });

                    gridLayerRef.current.setup(railImages);
                    gridLayerRef.current.setGridSize(gridSize);
                    gridLayerRef.current.addTo(leafletMap.current);
                    gridLayerRef.current.redraw();
                }
            }
        }
    }, [gridVisible, leafletMap.current]);

    useEffect(() => {
        if (gridLayerRef.current) {
            gridLayerRef.current.setGridSize(gridSize);
            gridLayerRef.current.redraw();
        }
    }, [gridSize]);

    useEffect(() => {
        if (gridLayerRef.current) {
            console.log("grid offfset:", gridOffset);
            gridLayerRef.current.setGridOffset(gridOffset);
            gridLayerRef.current.redraw();
        }
    }, [gridOffset]);

    const highlightDetection = useCallback(() => {
        if (detectionsLayerRef.current) {
            const detection = _.find(filteredDetections, { id: currentDetectionID });
            detectionsLayerRef.current.setClosestDetection(detection);
            detectionsLayerRef.current.redraw();
        }
    }, [currentDetectionID, filteredDetections]);

    useEffect(() => {
        highlightDetection();
    }, [highlightDetection, detectionsInvalidationTs]);

    useEffect(() => {
        if (railDataLoaded) {
            if (videoless && playlist.data.data && playlist.data.data.length) {
                const timestampToJumpTo = playlist.data.data[0].start[0];
                const location = getVideolessLocation(timestampToJumpTo, playlist.data.data);
                dispatch(currentPlaylistPosition(0, location, 0));
            } else if (videoPlaylist && videoPlaylist.length) {
                const timestampToJumpTo = videoPlaylist[0][1];
                const [videoIndex, timeOffset, location] = getOffsetAdjustedPosition(timestampToJumpTo, videoPlaylist, gpsTimeOffsets, true);
                dispatch(currentPlaylistPosition(videoIndex, location, timeOffset));
            }
        }
    }, [railDataLoaded]);

    useEffect(() => {
        if (railDataLoadComplete && railImages.length) {
            const firstImage = railImages[0];
            const lastImage = railImages[railImages.length - 1];
            dispatch(fetchInspectionPulseCounts(sessionID, firstImage.timestamp, lastImage.timestamp));
        }
    }, [railImages, railDataLoadComplete, dispatch, sessionID]);

    const panToTimestamp = useCallback(
        (timestamp, sourceIndex, zoom = true, detection) => {
            if (!timestamp) {
                return;
            }

            const source = railImageConfig.inspection_images[sourceIndex]; //add source to selected detection later
            let assetIndex = _.findIndex(railImages, { timestamp: timestamp });

            if (assetIndex < 0) {
                return;
            }

            let y_offset = 0;
            if (source) {
                if (sessionIsBackward) {
                    if (source.offset_y_backward) {
                        y_offset = Math.round(source.offset_y_backward);
                    }
                } else {
                    if (source.offset_y_forward) {
                        y_offset = Math.round(source.offset_y_forward);
                    }
                }
            }

            assetIndex += y_offset;
            panToIndex(assetIndex, zoom, detection);
        },
        [panToIndex, railImageConfig.inspection_images, railImages, sessionIsBackward],
    );

    const panToBookmark = useCallback(
        (bookmarkID) => {
            const bookmarkClicked = _.find(inspectionBookmarks, { id: bookmarkID });
            if (!bookmarkClicked) {
                return;
            }

            panToTimestamp(bookmarkClicked.image_timestamp, bookmarkClicked.source);
        },
        [inspectionBookmarks, panToTimestamp],
    );

    const panToMarkup = useCallback(
        (selectedMarkup) => {
            if (selectedMarkup.id) {
                if (selectedMarkup.type === "detection") {
                    if (filteredDetections && filteredDetections.length) {
                        const detectionClicked = _.find(filteredDetections, { id: selectedMarkup.id });
                        if (detectionClicked) {
                            panToTimestamp(detectionClicked.image_timestamp, 0, false, detectionClicked);
                            dispatch(setCurrentDetection(detectionClicked.id));
                        }
                    }
                } else if (selectedMarkup.type === "annotation") {
                    if (annotations && annotations.length) {
                        const annotationClicked = _.find(annotations, { id: selectedMarkup.id });
                        if (annotationClicked) {
                            let offsetParam;
                            if (sessionIsBackward) {
                                offsetParam = "offset_y_backward";
                            } else {
                                offsetParam = "offset_y_forward";
                            }
                            const annotationOffset = _.get(railImageConfig, ["inspection_images", annotationClicked.image_source, offsetParam], 0);

                            let imageIndex = _.findIndex(railImages, (img) => img.timestamp.toString() === annotationClicked.image_timestamp.toString());
                            const offsettedIndex = imageIndex + Math.round(annotationOffset);
                            const indexToUse = Math.min(Math.max(offsettedIndex, 0), railImages.length);

                            panToTimestamp(railImages[indexToUse].timestamp, 0, false);
                            dispatch(setCurrentInspectionMarkup(null, null));
                        }
                    }
                } else if (selectedMarkup.type === "classification") {
                    if (filteredClassifications && filteredClassifications.length) {
                        const classificationClicked = _.find(filteredClassifications, { id: selectedMarkup.id });
                        if (classificationClicked) {
                            panToTimestamp(classificationClicked.image_timestamp, 0, false);
                            dispatch(setCurrentClassification(classificationClicked.id));
                        }
                    }
                }
            }
        },
        [filteredDetections, annotations, panToTimestamp, dispatch, railImageConfig, railImages, sessionIsBackward, filteredClassifications, selectedMarkup],
    );

    useEffect(() => {
        if (selectedBookmark && railImages) {
            panToBookmark(selectedBookmark);
        }
    }, [selectedBookmark, railImages, panToBookmark]);

    useEffect(() => {
        if (selectedMarkup && railImages) {
            panToMarkup(selectedMarkup);
        }
    }, [selectedMarkup, railImages, panToMarkup]);

    const stopAutoScroll = useCallback(() => {
        if (leafletMap && leafletMap.current) {
            const currentPosition = leafletMap.current.getCenter();

            leafletMap.current.panTo([currentPosition.lat, currentPosition.lng], {
                animate: true,
                duration: 0.2,
                easeLinearity: 0.5,
            });
        }
    }, []);

    /// update main sources when map dragged
    const updateCurrentPosition = useCallback(() => {
        if (snapshotModalOpen) {
            return;
        }

        if (leafletMap.current && railImages && railImages.length) {
            const railArrayOffset = railArrayOffsetRef.current;

            let lat = leafletMap.current.getCenter().lat;
            let lng = leafletMap.current.getCenter().lng;

            if (totalTileWidth > 0) {
                const isLeft = lng * 32 <= totalTileWidth;
                dispatch(setPositionLeftBiased(isLeft));
            }

            setMapPosition([lat, lng]);
            let backwardsOffset = 1;

            if (sessionIsBackward) {
                lat *= -1;
                backwardsOffset = 0;
            }

            const latPos = lat / imageHeight;
            let centerIndex = Math.floor(latPos) + backwardsOffset + railArrayOffset;
            if (horizontal) {
                backwardsOffset = 0;
                if (sessionIsBackward) {
                    lng *= -1;
                    backwardsOffset = 1;
                }
                centerIndex = Math.floor(lng / imageHeight) + backwardsOffset + railArrayOffset;
            }

            if (centerIndex < 0) {
                centerIndex = 0;
            }

            if (centerIndex >= railImages.length) {
                centerIndex = railImages.length - 1;
            }

            // if autoscroll is on and position coming to the end or start, turn autoscroll off
            if (autoScrollActive) {
                if (centerIndex === 0 || centerIndex === railImages.length - 1) {
                    dispatch(setAutoScrollActive(false));
                }
            }

            if (!railImages[centerIndex]) {
                return;
            }

            const closestRailImage = railImages[centerIndex];
            const previousRailImage = railImages[centerIndex - 1];

            dispatch(setSelectedRailInspectionImage(centerIndex, closestRailImage, false, latPos, leftRightConfig[centerIndex]));

            // do not perform any heavy calculations when autoScroll enabled with live position to improve performance of auto scroll
            if (!autoScrollActive) {
                const currentImageDetections = [];

                _.forEach(filteredDetections, (detection) => {
                    if (detection.image_timestamp === closestRailImage.timestamp) {
                        currentImageDetections.push(detection);
                    } else if (detection.image_timestamp === _.get(previousRailImage, "timestamp")) {
                        const connectingDetection = _.get(detection, "connecting_detections", []);
                        if (_.find(connectingDetection, (det) => det.image_timestamp === closestRailImage.timestamp)) {
                            currentImageDetections.push(detection);
                        }
                    }
                });

                if (currentImageDetections.length) {
                    updateCurrentDetectionBasedOnMapPosition(currentImageDetections);
                } else {
                    dispatch(setCurrentDetection(null));
                    dispatch(setCurrentInspectionMarkup(null, null));
                }

                const currentImageClassifications = filteredClassifications.filter((cl) => cl.image_timestamp === closestRailImage.timestamp).map((c) => c.id);

                if (currentImageClassifications.length) {
                    if (!currentImageClassifications.includes(currentClassificationID)) {
                        dispatch(setCurrentClassification(currentImageClassifications[0]));
                    }
                } else {
                    dispatch(setCurrentClassification(null));
                    dispatch(setCurrentInspectionMarkup(null, null));
                }
            }

            const absoluteTimestamp = closestRailImage.timestamp / 1000;
            if (!videoless) {
                const position = getOffsetAdjustedPosition(absoluteTimestamp, videoPlaylist, gpsTimeOffsets, true);
                if (position) {
                    dispatch(currentPlaylistPosition(position[0], position[2], position[1]));
                }
            } else {
                if (railImages[centerIndex] && centerIndex >= 0) {
                    const timestamp = railImages[centerIndex].timestamp / 1000;
                    const location = getVideolessLocation(timestamp, playlist.data.data);
                    dispatch(currentPlaylistPosition(centerIndex, location, 0));
                }
            }
        }
    }, [
        snapshotModalOpen,
        railImages,
        sessionIsBackward,
        horizontal,
        dispatch,
        leftRightConfig,
        filteredDetections,
        filteredClassifications,
        videoless,
        currentDetectionID,
        currentClassificationID,
        videoPlaylist,
        gpsTimeOffsets,
        playlist.data.data,
        autoScrollActive,
        totalTileWidth,
    ]);

    useEffect(() => {
        if (requestData && requestData.timestamp && (newRequestData === null || newRequestData.timestamp !== requestData.timestamp)) {
            setNewRequestData(requestData);
        }
    }, [newRequestData, requestData]);

    useEffect(() => {
        if (newRequestData && newRequestData.timestamp && newRequestData.index !== null) {
            if (!videoless) {
                let videoTimestamp = getAbsoluteTimestamp(1, videoPlaylist, newRequestData.index, true, newRequestData.timeOffset);

                if (videoTimestamp < 9999999999) {
                    videoTimestamp *= 1000;
                }
                const railInspectionIndex = binarySearch(videoTimestamp, railImages, (image) => image.timestamp);
                panToIndex(railInspectionIndex);
            } else {
                if (newRequestData.index === -1) {
                    setRequestedTimestampUrl(newRequestData.timeOffset);
                } else {
                    panToIndex(newRequestData.index);
                }
            }
            const newReqData = _.clone(newRequestData);
            newReqData.index = null;
            setNewRequestData(newReqData);
        }
    }, [newRequestData, dispatch, railImages, videoless, videoPlaylist, panToIndex]);

    useEffect(() => {
        if (newRailIndex !== null && newRailIndex !== undefined) {
            panToIndex(newRailIndex);
        }
    }, [newRailIndex]);

    useEffect(() => {
        if (sessionSelected && !railDataLoaded && reduxSessionID) {
            dispatch(railImageLoader.current.load(reduxSessionID));
            dispatch(fetchInspectionAnnotations(sessionID));
            dispatch(getInspectionDetections(sessionID, 0, true));
            dispatch(getInspectionClassifications(sessionID, 0, true));
            dispatch(getInspectionConditionTypes());
        }
    }, [sessionSelected, dispatch, accessToken, reduxSessionID, railDataLoaded, sessionID]);

    useEffect(() => {
        if (railDataLoaded && railImages.length) {
            const firstImage = railImages[0].timestamp / 1000;
            const lastImage = railImages[railImages.length - 1].timestamp / 1000;
            dispatch(getRouteMetadata(reduxSessionID, "AREA_OF_INTEREST", firstImage, lastImage));
        }
    }, [railDataLoaded, dispatch, railImages, reduxSessionID]);

    const mouseDown = useCallback(() => {
        if (autoScrollActive) {
            dispatch(setAutoScrollActive(false));
        }

        dispatch(selectBookmark(null));
    }, [dispatch, autoScrollActive]);

    useEffect(() => {
        const primary = _.get(secondaryManualAdjustmentValues, "primary", null);

        if (primary && !manualAlignmentToolActive) {
            leafletMap.current.eachLayer((layer) => {
                if (layer.options.id === "primaryManualAlignmentLine") {
                    leafletMap.current.removeLayer(layer);
                }
            });
        }
    }, [manualAlignmentToolActive, secondaryManualAdjustmentValues]);

    useEffect(() => {
        if (
            manualAlignmentToolActive &&
            secondaryManualAdjustmentValues &&
            secondaryManualAdjustmentValues.primary &&
            secondaryManualAdjustmentValues.secondary
        ) {
            setManualAlignmentToolActive(false);
            setSecondaryManualAdjustmentValues(null);

            const newAdjustment = secondaryManualAdjustmentValues.primary - secondaryManualAdjustmentValues.secondary;

            // below will only be valid if (!secondarySessionIsBackward && sessionIsBackward) || (secondarySessionIsBackward && sessionIsBackward)
            let newAdjustmentVal = _.floor(secondaryAlignmentAdjustment + (newAdjustment * 16) / 1000, 2);

            if ((!secondarySessionIsBackward && !sessionIsBackward) || (secondarySessionIsBackward && !sessionIsBackward)) {
                newAdjustmentVal = _.floor(secondaryAlignmentAdjustment - (newAdjustment * 16) / 1000, 2);
            }

            setSecondaryAlignmentAdjustment(newAdjustmentVal);
        }
    }, [
        manualAlignmentToolActive,
        railsFlipped,
        secondaryAlignment,
        secondaryAlignmentAdjustment,
        secondaryManualAdjustmentValues,
        secondaryRailConfig,
        setManualAlignmentToolActive,
        setSecondaryAlignmentAdjustment,
        setSecondaryManualAdjustmentValues,
        sessionIsBackward,
        secondarySessionIsBackward,
    ]);

    const onMapClick = useCallback(
        (e) => {
            // manual alignment tool is on
            if (railDataLoaded && railImages && railImages.length && manualAlignmentToolActive) {
                let lng = e.latlng.lng * 16;

                let _aligmentValues = {
                    primary: _.get(secondaryManualAdjustmentValues, "primary", null),
                    secondary: _.get(secondaryManualAdjustmentValues, "secondary", null),
                };

                // setting primary lng
                if (lng > 0 && lng < totalPrimaryRailWidth) {
                    // if point on primary rail is not selected yet and userPreferencesShowRailManualAlignment === true, display notification
                    if (userPreferencesShowRailManualAlignment && !_aligmentValues.primary) {
                        notification.open({
                            message: "Secondary Rail",
                            description: "Now select the point on the secondary rail that aligns with the red line on the primary rail.",
                            placement: "bottomLeft",
                        });
                    }

                    if (leafletMap && leafletMap.current) {
                        _aligmentValues.primary = e.latlng.lat;

                        // below will remove ale already drawn aligment lines on the primary line if user click in different place on primary rail
                        leafletMap.current.eachLayer((layer) => {
                            if (layer.options.id === "primaryManualAlignmentLine") {
                                leafletMap.current.removeLayer(layer);
                            }
                        });

                        // draw line for primary rail on the map
                        const latLngBounds = [
                            [e.latlng.lat, e.latlng.lng - 30],
                            [e.latlng.lat, e.latlng.lng + 30],
                        ];
                        const polyline = L.polyline(latLngBounds, { color: "red", weight: 2, id: "primaryManualAlignmentLine" });
                        polyline.addTo(leafletMap.current);
                    } else {
                        return;
                    }
                } else {
                    // if user click on secondary rail but point on primary is not selected
                    if (!_aligmentValues.primary) {
                        notification.warning({
                            message: "Select point on primary rail first",
                            duration: 2,
                        });
                    } else {
                        if (leafletMap && leafletMap.current) {
                            _aligmentValues.secondary = e.latlng.lat;

                            // draw line for secondary rail on the map
                            const latLngBounds = [
                                [e.latlng.lat, e.latlng.lng - 30],
                                [e.latlng.lat, e.latlng.lng + 30],
                            ];

                            const polyline = L.polyline(latLngBounds, { color: "green", weight: 2, id: "secondaryManualAlignmentLine" });
                            polyline.addTo(leafletMap.current);
                            setRailManualAdjustmentLoading(true);

                            // annoying timeout only for better UX
                            setTimeout(() => {
                                // Find the polyline by id and remove it from the map
                                leafletMap.current.eachLayer((layer) => {
                                    if (layer.options.id === "primaryManualAlignmentLine" || layer.options.id === "secondaryManualAlignmentLine") {
                                        leafletMap.current.removeLayer(layer);
                                        // setRailManualAdjustmentLoading(false)
                                    }
                                });
                                if (userPreferencesShowRailManualAlignment) {
                                    notification.open({
                                        message: "Slight Adjustments",
                                        description: "If you need to make slight adjustments, you can still use the input field in the bottom-right corner.",
                                        placement: "bottomLeft",
                                    });
                                    dispatch(setUserPreference("showRailManualAlignment", false));
                                }
                            }, 1000);
                        }
                    }
                }

                // if both (primary and secondary) set setSecondaryManualAdjustmentValues
                if (
                    !secondaryManualAdjustmentValues ||
                    (secondaryManualAdjustmentValues && (!secondaryManualAdjustmentValues.secondary || !secondaryManualAdjustmentValues.primary))
                ) {
                    setSecondaryManualAdjustmentValues(_aligmentValues);
                }
            } else if (railDataLoaded && railImages && railImages.length && markerToolActive && !labelWindowOpen) {
                let lat = e.latlng.lat * 16;
                let lng = e.latlng.lng * 16;

                const imageData = railInspectionConverter.clickToImageData(lat, lng);
                if (!imageData) {
                    return;
                }
                const { imageClicked, source, coordinates } = imageData;
                const imageTimestampClicked = imageClicked.timestamp;

                setImageTimestampClicked(imageTimestampClicked);
                setImageSource(source);
                setCoords(coordinates);

                toggleMarkerTool();
                setLabelWindowOpen(true);
            }
        },
        [
            manualAlignmentToolActive,
            totalPrimaryRailWidth,
            railDataLoaded,
            railImages,
            markerToolActive,
            labelWindowOpen,
            userPreferencesShowRailManualAlignment,
            secondaryManualAdjustmentValues,
            railInspectionConverter,
            setRailManualAdjustmentLoading,
            dispatch,
            setSecondaryManualAdjustmentValues,
            toggleMarkerTool,
            setLabelWindowOpen,
        ],
    );

    const onZoom = useCallback(
        (e, val) => {
            if (autoScrollActive) {
                dispatch(setAutoScrollActive(false));
            }

            let zoom = e === null ? mapZoom : e.target._zoom;

            if (noInspectionImages) {
                return;
            }

            if (val !== undefined) {
                zoom = mapZoom + val;

                if (zoom >= 0 && zoom <= 6) {
                    setMapZoom(zoom);
                    leafletMap.current.setZoom(zoom);
                }
            } else {
                infoTilesRef.current.setMapZoom(zoom);
                infoTilesRef.current.redraw();

                dynamicLayerRef.current.setMapZoom(zoom);
                dynamicLayerRef.current.redraw();

                if (gridLayerRef.current) {
                    gridLayerRef.current.setMapZoom(zoom);
                    gridLayerRef.current.redraw();
                }
            }
        },
        [autoScrollActive, mapZoom, noInspectionImages],
    );

    const onKeyDown = useCallback(
        (e) => {
            if (
                annotationWindowOpen ||
                labelWindowOpen ||
                inPlayer ||
                snapshotModalOpen ||
                findModalVisible ||
                favouritePopoverOpen ||
                reportProblemVisible ||
                rejectionModalOpen ||
                detectionsExportOpen
            ) {
                return;
            }

            const keyPressed = e.key;
            e.stopPropagation();
            let panBy = null;

            if (keyPressed === "ArrowUp") {
                panBy = [0, -64];
            }

            if (keyPressed === "ArrowDown") {
                panBy = [0, 64];
            }

            if (keyPressed === "ArrowRight") {
                panBy = [128, 0];
            }

            if (keyPressed === "ArrowLeft") {
                panBy = [-128, 0];
            }

            if (!scrollingRef.current && panBy) {
                leafletMap.current.panBy(panBy);
                scrollingRef.current = setInterval(() => {
                    leafletMap.current.panBy(panBy);
                    scrollingCount.current += 1;
                    if (scrollingCount.current % 15 === 0) {
                        updateCurrentPosition(null, true);
                    }
                }, 100);
            }

            if (keyPressed === "a") {
                dispatch(toggleInspectionAnnotationMode(!annotateModeEnabled));
            } else if (keyPressed === "b") {
                toggleMarkerTool();
            } else if (keyPressed === "f" && horizontal) {
                dispatch(flipInspectionRails(!railsFlipped));
            } else if (keyPressed === "-") {
                onZoom(null, -1);
            } else if (keyPressed === "=") {
                onZoom(null, 1);
            } else if (keyPressed === "m") {
                dispatch(setIsMagnifyToggled(!magnifyToggled));
            }
        },
        [
            annotationWindowOpen,
            labelWindowOpen,
            inPlayer,
            snapshotModalOpen,
            findModalVisible,
            favouritePopoverOpen,
            reportProblemVisible,
            rejectionModalOpen,
            detectionsExportOpen,
            horizontal,
            updateCurrentPosition,
            dispatch,
            annotateModeEnabled,
            toggleMarkerTool,
            railsFlipped,
            onZoom,
            magnifyToggled,
        ],
    );

    const onKeyUp = (e) => {
        const keyUp = e.key;
        if (keyUp === "ArrowUp" || keyUp === "ArrowDown" || keyUp === "ArrowRight" || keyUp === "ArrowLeft") {
            if (scrollingRef.current) {
                clearInterval(scrollingRef.current);
                scrollingRef.current = null;
                scrollingCount.current = 0;
            }
        }
    };

    const onAnnotationCreated = useCallback(
        (e) => {
            const bounds = e.layer._latlngs[0];

            let topLeft = bounds[1];
            let bottomRight = bounds[3];

            if (!horizontal) {
                if (sessionIsBackward) {
                    topLeft = bounds[3];
                    bottomRight = bounds[1];
                }
            } else {
                if (sessionIsBackward && !railsFlipped) {
                    topLeft = bounds[2];
                    bottomRight = bounds[0];
                } else if (railsFlipped && sessionIsBackward) {
                    topLeft = bounds[3];
                    bottomRight = bounds[1];
                } else if (!sessionIsBackward && railsFlipped) {
                    topLeft = bounds[0];
                    bottomRight = bounds[2];
                }
            }

            const imageData = railInspectionConverter.clickToImageData(topLeft.lat * 16, topLeft.lng * 16);
            if (!imageData) {
                dispatch(toggleInspectionAnnotationMode(false));
                return;
            }
            const width = Math.abs(topLeft.lng - bottomRight.lng) * 16;
            const height = Math.abs(topLeft.lat - bottomRight.lat) * 16;

            const bbox = [imageData.coordinates[0], imageData.coordinates[1], height, width];

            const newAnnotations = _.cloneDeep(annotations);
            newAnnotations.push({
                id: -2,
                image_timestamp: imageData.imageClicked.timestamp,
                image_source: imageData.source,
                bbox: JSON.stringify(bbox),
            });
            setAnnotations(newAnnotations);
            setAnnotationWindowOpen(true);
            setAnnotationData({
                bbox,
                imageTimestamp: imageData.imageClicked.timestamp,
                source: imageData.source,
            });
            dispatch(toggleInspectionAnnotationMode(false));
        },
        [annotations, dispatch, sessionIsBackward, railInspectionConverter, railsFlipped],
    );

    const debouncedUpdateCurrentPosition = useCallback(_.debounce(updateCurrentPosition, 300), [updateCurrentPosition]);

    useEffect(() => {
        if (railDataLoaded) {
            const updateMousePosition = ({ containerPoint }) => {
                if (leafletMap.current) {
                    mousePositionRef.current = containerPoint;
                }
            };

            if (leafletMap.current) {
                leafletMap.current.on("moveend", debouncedUpdateCurrentPosition);
                leafletMap.current.on("mousedown", mouseDown);
                leafletMap.current.on("click", onMapClick);
                leafletMap.current.on("zoom", onZoom);
                leafletMap.current.on("draw:created", onAnnotationCreated);
                leafletMap.current.on("mousemove", updateMousePosition);
            }
            document.addEventListener("keydown", onKeyDown);
            document.addEventListener("keyup", onKeyUp);
            return () => {
                if (leafletMap.current) {
                    leafletMap.current.off("moveend", debouncedUpdateCurrentPosition);
                    leafletMap.current.off("mousedown", mouseDown);
                    leafletMap.current.off("click", onMapClick);
                    leafletMap.current.off("zoom", onZoom);
                    leafletMap.current.off("draw:created", onAnnotationCreated);
                    leafletMap.current.off("moveend", updateMousePosition);
                }
                document.removeEventListener("keyup", onKeyUp);
                document.removeEventListener("keydown", onKeyDown);
            };
        }
    }, [debouncedUpdateCurrentPosition, mouseDown, railDataLoaded, onMapClick, onZoom, onKeyDown, onAnnotationCreated]);

    const toggleMapOpen = () => {
        if (mapOpen) {
            setPreviousMapSize(mapSize);
            setMapSize({ width: 10, height: 10 });
        } else {
            setMapSize(previousMapSize);
        }
        setMapOpen(!mapOpen);
    };

    useEffect(() => {
        let x = map.current.clientWidth - mapSize.width;
        let y = map.current.clientHeight - mapSize.height;
        const area = {
            left: 20,
            top: map.current.clientHeight - map.current.clientHeight,
            right: map.current.clientWidth - 10,
            bottom: map.current.clientHeight + 60,
        };

        if (map.current.clientWidth && map.current.clientHeight && !updatingDraggablePosition) {
            x = map.current.clientWidth - 180;
            y = map.current.clientHeight - 280;
        }

        setDraggablePosition({ x, y });
        setDraggableArea(area);

        // add event listner on window resize
        window.addEventListener("resize", updateDraggablePosition);
    }, []);

    const updateDraggablePosition = () => {
        setUpdatingDraggablePosition(true);
    };

    useEffect(() => {
        // handle draggable position on window resize
        let x, y;
        if (navbarCollapsed) {
            x = map.current.clientWidth - mapSize.width - 137;
            y = map.current.clientHeight - (mapSize.height - currentHeaderHeight);
        } else {
            x = map.current.clientWidth - mapSize.width;
            y = map.current.clientHeight - (mapSize.height - currentHeaderHeight);
        }

        let _draggablePosition = { x: x, y: y };

        setDraggablePosition(_draggablePosition);
        setUpdatingDraggablePosition(false);
    }, [updatingDraggablePosition, mapSize, timelineWidgetOpen, resizingMap, currentHeaderHeight, map.current.clientWidth, map.current.clientHeight]);

    const extraStyle = {
        position: "absolute",
        bottom: timelineWidgetOpen ? `${15 + TIMELINE_HEIGHT}px` : "15px",
        right: "15px",
        height: mapSize.height,
        width: mapSize.width,
        zIndex: "1000",
    };

    const keyboardControls = useMemo(() => {
        let controlsArray = ["bookmark", "annotate", "zoomIn", "zoomOut", "magnify"];
        if (horizontal) {
            controlsArray.push("flip");
            controlsArray.push("left");
            controlsArray.push("right");
        } else {
            controlsArray.push("up");
            controlsArray.push("down");
        }
        return controlsArray;
    }, [horizontal]);

    const navigateVertical = useCallback(
        (e, direction) => {
            if (autoScrollActive) {
                dispatch(setAutoScrollActive(false));
                stopAutoScroll();
                return;
            }

            const currentPosition = leafletMap.current.getCenter();
            const bounds = leafletMap.current.getBounds();

            const bottomLat = bounds._southWest.lat;
            const topLat = bounds._northEast.lat;
            const currentLat = currentPosition.lat;
            const latDiff = currentLat - bottomLat;

            if (direction === "forward") {
                // const latDiff = topLat - currentLat;
                let newPos = topLat + latDiff - imageHeight;
                if (newPos < currentLat) {
                    newPos = topLat + latDiff;
                }
                leafletMap.current.panTo([newPos, leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            } else {
                let newPos = bottomLat - latDiff + imageHeight;
                if (newPos > currentLat) {
                    newPos = bottomLat - latDiff;
                }
                leafletMap.current.panTo([newPos, leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            }
        },
        [autoScrollActive, dispatch, stopAutoScroll],
    );

    const navigateHorizontal = useCallback(
        (e, direction) => {
            if (autoScrollActive) {
                dispatch(setAutoScrollActive(false));
                stopAutoScroll();
                return;
            }

            const currentPosition = leafletMap.current.getCenter();
            const bounds = leafletMap.current.getBounds();

            const leftLng = bounds._southWest.lng;
            const rightLng = bounds._northEast.lng;
            const currentLng = currentPosition.lng;

            const latDiff = rightLng - currentLng;

            if (direction === "right") {
                leafletMap.current.panTo([leafletMap.current.getCenter().lat, rightLng + latDiff - imageHeight], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            } else {
                leafletMap.current.panTo([leafletMap.current.getCenter().lat, leftLng - latDiff + imageHeight], {
                    animate: true,
                    duration: 0.2,
                    easeLinearity: 0.5,
                });
            }
        },
        [autoScrollActive, dispatch, stopAutoScroll],
    );

    const percentage = percentageComplete !== undefined ? percentageComplete.toFixed(0) : "Unknown";

    const onDragEventStart = () => {
        setResizingMap(true);
        setNewMapSize(mapSize);
    };

    const onDragEvent = (event, position) => {
        if (!navbarCollapsed) {
            const newWidth = map.current.clientWidth - position.x;
            let newHeight = map.current.clientHeight + currentHeaderHeight - position.y;
            setMapSize({ width: newWidth, height: newHeight });
        } else {
            const newWidth = map.current.clientWidth - position.x;
            let newHeight = map.current.clientHeight + currentHeaderHeight - position.y;
            setMapSize({ width: newWidth - 137, height: newHeight });
        }
    };

    const onDragEventStop = (event, position) => {
        // if draggable arrow pressed toggle open map
        if (newMapSize === mapSize) {
            toggleMapOpen();
        } else {
            if (timelineWidgetOpen) {
                setDraggablePosition({ x: position.x, y: position.y - TIMELINE_HEIGHT });
            } else {
                setDraggablePosition({ x: position.x, y: position.y });
            }
        }
        setResizingMap(false);
    };

    const autoScrollFunction = useCallback(
        (direction, speed = 1) => {
            const railArrayOffset = railArrayOffsetRef.current;
            const currentPosition = leafletMap.current.getCenter();
            const speedFactor = horizontal ? 200 : 75; // different factor needed - might need to implement function to calculate this based on images config but seams to work for now
            let duration = 30;
            let scrollSpeed = duration / speed;

            let lat = currentPosition.lat;
            let lng = currentPosition.lng;

            let backwardsOffset = 1;

            if (sessionIsBackward) {
                lat *= -1;
                backwardsOffset = 0;
            }

            const latPos = lat / imageHeight;
            let centerIndex = Math.floor(latPos) + backwardsOffset + railArrayOffset;
            const lastIndex = railImages.length - 1;
            if (horizontal) {
                backwardsOffset = 0;
                if (sessionIsBackward) {
                    lng *= -1;
                    backwardsOffset = 1;
                }
                centerIndex = Math.floor(lng / imageHeight) + backwardsOffset + railArrayOffset;
            }

            if (centerIndex < 0) {
                centerIndex = 0;
            }
            if (centerIndex >= railImages.length) {
                centerIndex = railImages.length - 1;
            }

            const currentLat = currentPosition.lat;
            const currentLng = currentPosition.lng;

            let newPos = currentLat + duration * imageHeight;

            if (direction === "up") {
                scrollSpeed = (duration * 100) / speed / imageHeight;

                // last or first 30 indexes
                if (centerIndex > lastIndex - duration || centerIndex < duration) {
                    // backward session
                    if (railImageConfig.imageryIsBackward) {
                        if (centerIndex === 0) {
                            dispatch(setAutoScrollActive(false));
                            return;
                        }
                        scrollSpeed = (centerIndex * speedFactor) / speed / imageHeight;
                        newPos = currentLat + centerIndex * imageHeight;
                    }
                    // forward session
                    if (!railImageConfig.imageryIsBackward) {
                        scrollSpeed = (lastIndex - centerIndex) / speed;
                        newPos = currentLat + (lastIndex - centerIndex) * imageHeight;
                    }
                }

                leafletMap.current.panTo([newPos, leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: scrollSpeed,
                    easeLinearity: 1,
                });
            }
            if (direction === "down") {
                scrollSpeed = (duration * speedFactor) / speed / imageHeight;
                newPos = currentLat - duration * imageHeight;

                // last or first 30 indexes
                if (centerIndex > lastIndex - duration || centerIndex < duration) {
                    // backward session
                    if (railImageConfig.imageryIsBackward) {
                        if (lastIndex === centerIndex) {
                            dispatch(setAutoScrollActive(false));
                            return;
                        }
                        scrollSpeed = (lastIndex - centerIndex) / speed;
                        newPos = currentLat - (lastIndex - centerIndex) * imageHeight;
                    }
                    // forward session
                    if (!railImageConfig.imageryIsBackward) {
                        scrollSpeed = (centerIndex * speedFactor) / speed / imageHeight;
                        newPos = currentLat - centerIndex * imageHeight;
                    }
                }

                leafletMap.current.panTo([newPos, leafletMap.current.getCenter().lng], {
                    animate: true,
                    duration: scrollSpeed,
                    easeLinearity: 1,
                });
            }
            if (direction === "right") {
                newPos = currentLng + duration * imageWidth;
                scrollSpeed = (duration * speedFactor) / speed / imageWidth;

                // in case there is more or less then 30 indexes (to the right) change speed and position
                // to let scroller update position at the end and stop the auto scrolling

                // last 30 indexes
                if (centerIndex > lastIndex - duration) {
                    // forward + NOT flipped OR backward + flipped
                    if ((!railsFlipped && !railImageConfig.imageryIsBackward) || (railsFlipped && railImageConfig.imageryIsBackward)) {
                        // if last index do nothing
                        if (lastIndex === centerIndex) {
                            dispatch(setAutoScrollActive(false));
                            return;
                        }
                        scrollSpeed = ((lastIndex - centerIndex) * speedFactor) / speed / imageWidth;
                        newPos = currentLat + (lastIndex - centerIndex) * imageWidth;
                    }
                }

                // first 30 indexes
                if (centerIndex < duration) {
                    // forward + flipped OR backward + NOT flipped
                    if ((railsFlipped && !railImageConfig.imageryIsBackward) || (!railsFlipped && railImageConfig.imageryIsBackward)) {
                        scrollSpeed = (centerIndex * speedFactor) / speed / imageWidth;
                        newPos = currentLat + (duration - centerIndex) * imageWidth;
                    }
                }

                leafletMap.current.panTo([leafletMap.current.getCenter().lat, newPos], {
                    animate: true,
                    duration: scrollSpeed,
                    easeLinearity: 1,
                });
            }
            if (direction === "left") {
                newPos = currentLng - duration * imageWidth;
                scrollSpeed = (duration * speedFactor) / speed / imageWidth;
                // in case there is more or less then 30 indexes (to the left) change speed and position
                // this currently taking view to the very edge of the rail (the same behaviour which
                // we have when you use  "Navigate Left/Right" buttons)

                // first 30 indexes
                if (centerIndex < duration) {
                    if ((!railsFlipped && !railImageConfig.imageryIsBackward) || (railsFlipped && railImageConfig.imageryIsBackward)) {
                        scrollSpeed = (centerIndex * speedFactor) / speed / imageWidth;
                        newPos = currentLat - (duration - centerIndex) * imageWidth;
                    }
                }

                // last 30 indexes
                if (centerIndex > lastIndex - duration) {
                    if ((railsFlipped && !railImageConfig.imageryIsBackward) || (!railsFlipped && railImageConfig.imageryIsBackward)) {
                        if (lastIndex === centerIndex) {
                            dispatch(setAutoScrollActive(false));
                            return;
                        }
                        scrollSpeed = ((lastIndex - centerIndex) * speedFactor) / speed / imageWidth;
                        newPos = currentLat - (lastIndex - centerIndex) * imageWidth;
                    }
                }

                leafletMap.current.panTo([leafletMap.current.getCenter().lat, newPos], {
                    animate: true,
                    duration: scrollSpeed,
                    easeLinearity: 1,
                });
            }
        },
        [dispatch, horizontal, railImageConfig.imageryIsBackward, railImages.length, railsFlipped, sessionIsBackward],
    );

    // Function to be repeatedly called
    const repeatedAutoScrollFunction = useCallback(
        (_autoScrollDirection, _autoScrollSpeed, _autoScrollSpeedUpdated) => {
            // if speed updated, stop scroll first then apply new speed.
            if (_autoScrollSpeedUpdated) {
                stopAutoScroll();
                setAutoScrollSpeedUpdated(false);
            }
            autoScrollFunction(_autoScrollDirection, _autoScrollSpeed);
        },
        [autoScrollFunction, stopAutoScroll],
    );

    useEffect(() => {
        let intervalId;
        const startInterval = () => {
            const duration = (30 / autoScrollSpeed) * 1000;
            intervalId = setInterval(() => repeatedAutoScrollFunction(autoScrollDirection, autoScrollSpeed, autoScrollSpeedUpdated), duration);
        };

        const stopInterval = () => {
            clearInterval(intervalId);
        };

        // Start or stop the interval based on isToggled
        if (autoScrollActive || autoScrollSpeedUpdated) {
            repeatedAutoScrollFunction(autoScrollDirection, autoScrollSpeed, autoScrollSpeedUpdated);
            startInterval();
        } else if (!autoScrollActive) {
            stopInterval();
            stopAutoScroll();
        }

        // Cleanup function to clear the interval when isToggled becomes false or component unmounts
        return stopInterval;
    }, [autoScrollActive, autoScrollSpeedUpdated, autoScrollDirection, repeatedAutoScrollFunction, autoScrollSpeed, stopAutoScroll]);

    useEffect(() => {
        let intervalId;
        const startInterval = () => {
            intervalId = setInterval(() => updateCurrentPosition(), 1000);
        };

        const stopInterval = () => {
            clearInterval(intervalId);
        };

        // Start or stop the interval based on isToggled
        if (autoScrollActive) {
            startInterval();
        } else if (!autoScrollActive) {
            stopInterval();
        }

        // Cleanup function to clear the interval when isToggled becomes false or component unmounts
        return stopInterval;
    }, [autoScrollActive, updateCurrentPosition]);

    const toggleAutoScroll = useCallback(() => {
        dispatch(setAutoScrollActive(!autoScrollActive));
    }, [autoScrollActive, dispatch]);

    const handleDirectionButtonClick = useCallback((direction) => {
        setAutoScrollDirection(direction);
    }, []);

    const autoScrollMenu = useCallback(
        (isHorizontal) => {
            return (
                <Menu>
                    <Menu.Item key={"direction"}>
                        <Button.Group className="MapNavigationButtonsAutoScrollSpeed">
                            {isHorizontal ? (
                                <>
                                    <Button
                                        size="small"
                                        type={autoScrollDirection === "left" ? "primary" : "default"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDirectionButtonClick("left");
                                        }}>
                                        Left
                                    </Button>

                                    <Button
                                        size="small"
                                        type={autoScrollDirection === "right" ? "primary" : "default"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDirectionButtonClick("right");
                                        }}>
                                        Right
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        size="small"
                                        type={autoScrollDirection === "up" ? "primary" : "default"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDirectionButtonClick("up");
                                        }}>
                                        Up
                                    </Button>

                                    <Button
                                        size="small"
                                        type={autoScrollDirection === "down" ? "primary" : "default"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDirectionButtonClick("down");
                                        }}>
                                        Down
                                    </Button>
                                </>
                            )}
                        </Button.Group>
                    </Menu.Item>
                    <Menu.Item
                        key="speed"
                        style={{ padding: "3px 10px" }}>
                        <Button.Group className="MapNavigationButtonsAutoScrollSpeed">
                            <Button
                                size="small"
                                type={autoScrollSpeed === 0.5 ? "primary" : "default"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAutoScrollSpeed(0.5);
                                    if (autoScrollActive) {
                                        setAutoScrollSpeedUpdated(true);
                                    }
                                }}>
                                x0.5
                            </Button>
                            <Button
                                size="small"
                                type={autoScrollSpeed === 1 ? "primary" : "default"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAutoScrollSpeed(1);
                                    if (autoScrollActive) {
                                        setAutoScrollSpeedUpdated(true);
                                    }
                                }}>
                                x1
                            </Button>
                            <Button
                                size="small"
                                type={autoScrollSpeed === 2 ? "primary" : "default"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAutoScrollSpeed(2);
                                    if (autoScrollActive) {
                                        setAutoScrollSpeedUpdated(true);
                                    }
                                }}>
                                x2
                            </Button>
                        </Button.Group>
                    </Menu.Item>
                </Menu>
            );
        },
        [autoScrollActive, autoScrollDirection, autoScrollSpeed, handleDirectionButtonClick],
    );

    const railNavigationButtons = useMemo(() => {
        if (!railDataLoaded || noInspectionImages) {
            return null;
        }

        if (horizontal) {
            return (
                <>
                    <Button
                        className="Button Top"
                        onClick={(e) => navigateHorizontal(e, "right")}>
                        Navigate Right
                        <Icon type="right" />
                    </Button>
                    <Button
                        className="Button Bottom"
                        onDoubleClick={(e) => e.stopPropagation()}
                        onClick={(e) => navigateHorizontal(e, "left")}>
                        <Icon type="left" />
                        Navigate Left
                    </Button>
                    <div style={{ marginTop: "3px", width: "100%" }}>
                        <Dropdown overlay={autoScrollMenu(true)}>
                            <Button
                                icon={autoScrollActive ? "pause" : "caret-right"}
                                size="small"
                                type={autoScrollActive ? "primary" : "default"}
                                style={{ width: "100%", fontWeight: "bold", fontSize: "13.5px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    toggleAutoScroll();
                                }}>
                                {autoScrollActive ? "Stop" : "Play"}
                            </Button>
                        </Dropdown>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Button
                        className="Button Top"
                        onDoubleClick={(e) => e.stopPropagation()}
                        onClick={(e) => navigateVertical(e, "forward")}>
                        <Icon type="up" />
                        Navigate Up
                    </Button>
                    <Button
                        className="Button Bottom"
                        onClick={(e) => navigateVertical(e, "backward")}>
                        <Icon type="down" />
                        Navigate Down
                    </Button>
                    <div style={{ marginTop: "3px", width: "100%" }}>
                        <Dropdown overlay={autoScrollMenu(false)}>
                            <Button
                                icon={autoScrollActive ? "pause" : "caret-right"}
                                size="small"
                                type={autoScrollActive ? "primary" : "default"}
                                style={{ width: "100%", fontWeight: "bold", fontSize: "13.5px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    dispatch(setIsMagnifyToggled(false));
                                    toggleAutoScroll();
                                }}>
                                {autoScrollActive ? "Stop" : "Play"}
                            </Button>
                        </Dropdown>
                    </div>
                </>
            );
        }
    }, [railDataLoaded, horizontal, autoScrollMenu, autoScrollActive, navigateHorizontal, toggleAutoScroll, navigateVertical, dispatch, noInspectionImages]);

    return (
        <>
            {!inPlayer && (
                <LabelCreateWindow
                    match={match}
                    coords={coords}
                    imageTimestampClicked={imageTimestampClicked}
                    imageSource={imageSource}
                    labelWindowOpen={labelWindowOpen}
                    closeBookmarkWindow={closeBookmarkWindow}
                    updatingID={updatingID}
                    setUpdatingID={setUpdatingID}
                />
            )}
            {!inPlayer && (
                <AnnotationCreateWindow
                    visible={annotationWindowOpen}
                    annotationData={annotationData}
                    sessionID={sessionID}
                    updatingID={editingAnnotationID}
                    resetEditingID={resetEditingID}
                    cancelAnnotationCreation={cancelAnnotationCreation}
                    closeModal={() => setAnnotationWindowOpen(false)}
                />
            )}

            <div className="MapWrapper">
                <RailInspectSourceModal
                    toggleModal={setSourceModalOpen}
                    sourceModalOpen={sourceModalOpen}
                />

                <RailInspectToolbar
                    toggleMarkerTool={toggleMarkerTool}
                    markerToolActive={markerToolActive}
                    toggleFindModalVisibility={toggleFindModalVisibility}
                    videoless={videoless}
                    toggleArchiveInspectionSessionModal={toggleArchiveInspectionSessionModal}
                    toggleRestoreArchivedInspectionSessionModal={toggleRestoreArchivedInspectionSessionModal}
                    showReportProblemDialog={showReportProblemDialog}
                    navbarCollapsed={navbarCollapsed}
                    setNavbarCollapsed={setNavbarCollapsed}
                    secondaryRailImages={secondaryRailImages}
                    clearSecondarySession={clearSecondarySession}
                    secondaryRailConfig={secondaryRailConfig}
                    setSecondaryAlignment={setSecondaryAlignment}
                    setSecondaryAlignmentAdjustment={setSecondaryAlignmentAdjustment}
                    toggleManualAlignmentTool={toggleManualAlignmentTool}
                    manualAlignmentToolActive={manualAlignmentToolActive}
                    secondaryRailImageStatus={secondaryRailImageStatus}
                    loadSecondarySession={loadSecondarySession}
                    toggleSourceModal={setSourceModalOpen}
                    sourceMoldalOpen={sourceModalOpen}
                    assetNavigationWindowOpen={assetNavigationWindowOpen}
                    setAssetNavigationWindowOpen={setAssetNavigationWindowOpen}
                    setBookmarksAndAnnotationsWindowOpen={setBookmarksAndAnnotationsWindowOpen}
                    bookmarksAndAnnotationsWindowOpen={bookmarksAndAnnotationsWindowOpen}
                    sperrySuspectNavigationWindowOpen={sperrySuspectNavigationWindowOpen}
                    setSperrySuspectNavigationWindowOpen={setSperrySuspectNavigationWindowOpen}
                />
                <div
                    className={
                        !window.location.href.includes("rail-inspect")
                            ? "MapNavigationButtons inline"
                            : navbarCollapsed
                              ? "MapNavigationButtons collapsed"
                              : "MapNavigationButtons"
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    {railNavigationButtons}
                </div>
                <div
                    ref={map}
                    id="mapContainer"
                    className={`leaflet-big-scroller`}
                    style={{
                        flex: "1",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        cursor: manualAlignmentToolActive ? "crosshair" : "",
                    }}>
                    {!inPlayer && (
                        <div className="inspectRail-Main__Shortcuts">
                            <KeyboardShortcuts controls={keyboardControls} />
                        </div>
                    )}
                    {detailViewOpen && <div className="Redline" />}
                    {!railDataLoaded || (requestedTimestamp && !requestedTimestampUrlLoaded) || !railDataLoadComplete || railManualAdjustmentLoading ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "180px",
                                zIndex: 999,
                            }}>
                            <OBCSpinner colorScheme={"mono"} />
                        </div>
                    ) : null}
                    {railDataLoadComplete && dataEmpty && !dataLoadError && <p>Awaiting Inspection Image Upload</p>}
                    {railDataLoadComplete && dataEmpty && dataLoadError && <p>Error fetching rail inspection images</p>}
                </div>
                {!inPlayer && (
                    <MapComponent
                        extraStyle={extraStyle}
                        resizingMap={resizingMap}
                        stripped
                        railInspection
                    />
                )}
                {!inPlayer && (
                    <Draggable
                        onStop={onDragEventStop}
                        onStart={onDragEventStart}
                        onDrag={onDragEvent}
                        onMouseDown={() => {
                            !mapOpen && toggleMapOpen();
                        }}
                        bounds={draggableArea}
                        position={draggablePosition}
                        defaultPosition={draggablePosition}
                        disabled={!mapOpen}
                        draggable={true}>
                        <div className={`inspectRail-Minimap-Handle ${mapOpen ? "Open" : "Close"}`}>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className="ResizeHandle"
                            />
                        </div>
                    </Draggable>
                )}
            </div>
        </>
    );
};

export default RailInspectBigScroller;
