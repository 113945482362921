import React, { useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faChartLine, faPhotoVideo, faCompass, faAnalytics, faExclamationTriangle, faTimes } from "@fortawesome/pro-light-svg-icons";
import OBCSpinner from "components/util/OBC";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { customAudit } from "redux/actions/index";
import { ReactComponent as RailSectionIcon } from "icons/rail-section.svg";
import { windowFeaturesString as railDetailWidgetWindowFeaturesString } from "components/Widgets/RailDetailWidget";
import { addWidget, focusWidget, removeWidget } from "redux/actions/widgetActions";

const wsTokenSelector = (state) => state.userDetails.userConfig.ws_token;
const sidekickTypesSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    return _.get(currentDashboard, ["config", "sidekick_widget_types"], []);
};
const userDetailsSelector = (state) => state.userDetails;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const sessionTagsSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID, "tags"], []);
const widgetsSelector = (state) => state.widgets;

const RailInspectToolbarWidgets = ({ visible, collapsed, setVisible }) => {
    const wsToken = useSelector(wsTokenSelector);
    const sidekickTypes = useSelector(sidekickTypesSelector);
    const userDetails = useSelector(userDetailsSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const widgets = useSelector(widgetsSelector);
    const dispatch = useDispatch();
    const makeStyle = useCallback(
        (widget) => {
            return { backgroundColor: widgets.hasOwnProperty(widget) ? (widgets[widget] ? "#4c527c" : "#42486d") : "" };
        },
        [widgets],
    );

    /**
     *
     * @param {string} widget
     * @param {string} target
     * @param {string} features
     */
    const openWidget = useCallback(
        (widget, _target, features) => {
            dispatch(
                customAudit(
                    "widget_opened",
                    {
                        widgetType: widget,
                    },
                    `${widget} widget opened`,
                ),
            );

            dispatch(addWidget(widget));

            window.open(`/widget/${widget}/${wsToken}`, widget, features);
        },
        [dispatch, wsToken],
    );

    const handleClick = useCallback(
        (widget, target = "", features = "width=600,height=400,left=200,top=200" /*string */) /* () => void */ => {
            if (!widgets.hasOwnProperty(widget)) {
                return openWidget(widget, target, features);
            }
            return focusWidget(widget);
        },
        [openWidget, widgets],
    );

    const openWidgets = useMemo(() => Object.keys(widgets), [widgets]);

    const hasDetailImages = useMemo(() => {
        return _.get(railImageConfigObject, "inspection_images", false)
            ? !!railImageConfigObject.inspection_images.filter((config) => config.showInModal).length
            : false;
    }, [railImageConfigObject]);

    const sessionTags = useSelector(sessionTagsSelector);

    return (
        <div style={{ position: "relative", width: 224 }}>
            {(sidekickTypes.includes("Map") || openWidgets.includes("map")) && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("map")}
                    style={makeStyle("map")}>
                    <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Map</div>
                    <Close widget={"map"} />
                </div>
            )}
            {((sidekickTypes.includes("Detail Rail") && hasDetailImages) || openWidgets.includes("rail-detail")) && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("rail-detail", "", railDetailWidgetWindowFeaturesString)}
                    style={makeStyle("rail-detail")}>
                    <RailSectionIcon style={{ color: "#e8dfff" }} />
                    <div className={"UserDetailsPopupButtonText"}>Rail Detail Images</div>
                    <Close
                        widget={"rail-detail"}
                        humanName={"rail detail"}
                    />
                </div>
            )}
            {(sidekickTypes.includes("Track Geometry") || openWidgets.includes("track-geom-v2")) && sessionTags.includes("Track Geometry") && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("track-geom-v2")}
                    style={makeStyle("track-geom-v2")}>
                    <FontAwesomeIcon
                        icon={faChartLine}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Track Geometry</div>
                    <Close
                        widget={"track-geom-v2"}
                        humanName={"track geometry"}
                    />
                </div>
            )}
            {(sidekickTypes.includes("Stills Player") || openWidgets.includes("stills")) && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("stills")}
                    style={makeStyle("stills")}>
                    <FontAwesomeIcon
                        icon={faPhotoVideo}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Stills Player</div>
                    <Close widget={"stills"} />
                </div>
            )}
            {(sidekickTypes.includes("Location Information") || openWidgets.includes("location")) && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("location")}
                    style={makeStyle("location")}>
                    <FontAwesomeIcon
                        icon={faCompass}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Location Information</div>
                    <Close
                        widget={"location"}
                        humanName={"location information"}
                    />
                </div>
            )}
            {(sidekickTypes.includes("Exceedances") || openWidgets.includes("exceedences")) && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("exceedences")}
                    style={makeStyle("exceedences")}>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Exceedances</div>
                    <Close widget={"exceedences"} />
                </div>
            )}
            {userDetails.userConfig.super_admin && (
                <div
                    className="UserDetailsPopupButton"
                    onClick={() => handleClick("timeline")}
                    style={makeStyle("timeline")}>
                    <FontAwesomeIcon
                        icon={faAnalytics}
                        style={{ fontSize: 28, color: "#e8dfff" }}
                    />
                    <div className={"UserDetailsPopupButtonText"}>Timeline View</div>
                    <Close widget={"timeline"} />
                </div>
            )}
        </div>
    );
};

const Close = ({ widget, humanName, showToolTips }) => {
    const widgets = useSelector((state) => state.widgets);
    const dispatch = useDispatch();

    return widgets.hasOwnProperty(widget) ? (
        widgets[widget] ? (
            <div
                className="CloseButton"
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(removeWidget(widget));
                }}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        ) : (
            <OBCSpinner
                size={28}
                color={"#e8dfff"}
            />
        )
    ) : (
        <></>
    );
};

export default RailInspectToolbarWidgets;
