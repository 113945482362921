import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionList } from "../redux/actions/index";

const sessionIdsSelector = (state) => state.sessionList;
const dataFilterSelector = (state) => state.loginDataFilters;
const userConfigSelector = (state) => state.userDetails;

const DliDebugComponent = () => {
    const dispatch = useDispatch();

    const sessionIDs = useSelector(sessionIdsSelector);
    const dataFilters = useSelector(dataFilterSelector);
    const userDetails = useSelector(userConfigSelector);

    useEffect(() => {
        dispatch(getSessionList());
    }, [dispatch]);

    return (
        <div className="DliDebugComponent">
            <p>You are authenticated successfully. This page is a debug placeholder for testing.</p>
            <p>
                <pre>User: {userDetails.email}</pre>
            </p>
            <p>
                <pre>Workspace: {userDetails.description}</pre>
            </p>
            <p>
                <pre>Filters: {JSON.stringify(dataFilters, null, 2)}</pre>
            </p>
            <p>Matching video sessions: {sessionIDs.length}</p>
        </div>
    );
};

export default DliDebugComponent;
