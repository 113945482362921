import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../aivr.scss";
import logo from "../images/new-branding-2.0/aivr-hybrid.svg";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { getDeviceStatuses, logout, adminPassword, getMarkerConfigurations, fetchDataPools } from "../redux/actions/index";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ManageMarkers from "./MarkerManagement";
import MarkerDashboard from "./MarkerDashboard";
import UserSeatManagement from "./UserSeatManagement";
import BehaviourZoneManagement from "./BehaviourZoneManagement";
import Tippy from "@tippyjs/react";
import ImportManagement from "./ImportManagement";
import ObservationReview from "./ObservationReview";
import DriverTrainingManager from "./DriverTrainingManager";
import { Menu, notification, Tooltip } from "antd";
import "../style/adminPanel.scss";
import OBCButton from "components/OBC/OBCButton";
import {
    CloudUploadOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    EyeOutlined,
    FileProtectOutlined,
    GlobalOutlined,
    InboxOutlined,
    LoadingOutlined,
    PieChartOutlined,
    SettingOutlined,
} from "@ant-design/icons";

const userDetailsSelector = (state) => state.userDetails;
const dashboardsSelector = (state) => state.dashboards;
const markersSelector = (state) => state.admin.markers;
const dataPoolsSelector = (state) => state.dataPools;
const emailSelector = (state) => state.userDetails.emai;
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;

const AdminApp = () => {
    const dispatch = useDispatch();

    const [loggedIn, setLoggedIn] = useState(false);
    const [activeWindow, setActiveWindow] = useState("markers");
    const [inputPassword, setInputPassword] = useState("");
    const [confirmEnabled, setConfirmEnabled] = useState(true);
    const [mainNavbarCollapsed, setMainNavbarCollapsed] = useState(true);

    const userDetails = useSelector(userDetailsSelector);
    const currentDashboard = _.find(useSelector(dashboardsSelector), (dash) => dash.access_id === userDetails.dashboardAccessID);
    const markers = useSelector(markersSelector);
    const dataPools = useSelector(dataPoolsSelector);
    const email = useSelector(emailSelector);
    const dataPoolPermissions = _.get(currentDashboard, ["data_pool_permissions"], {});
    const superAdmin = useSelector(superAdminSelector);
    const currentDashboardLayout = _.get(currentDashboard, "workspace_layout", null);

    const adminPasswordResult = (result) => {
        if (result) {
            dispatch(getDeviceStatuses());
            if (_.isEmpty(markers)) {
                dispatch(getMarkerConfigurations());
            }
            if (_.isEmpty(dataPools)) {
                dispatch(fetchDataPools());
            }
            setInputPassword("");
            setLoggedIn(true);
        } else {
            notification.error({
                message: "Incorrect password",
            });
            dispatch(adminPassword(null));
            setInputPassword("");
            setConfirmEnabled(true);
            setLoggedIn(false);
        }
    };

    const updateAdminPassword = (e) => {
        setInputPassword(e.target.value);
    };

    const confirmAdminPassword = () => {
        setConfirmEnabled(false);
        dispatch(adminPassword(inputPassword));
        dispatch(getMarkerConfigurations(adminPasswordResult, true));
    };

    const keyPressed = (e) => {
        if (e.key === "Enter" && inputPassword.length > 0) {
            confirmAdminPassword();
        }
    };

    const renderAdminComponent = () => {
        switch (activeWindow) {
            case "markers":
                return <ManageMarkers />;
            case "marker_dashboard":
                return <MarkerDashboard />;
            case "user_seats":
                return <UserSeatManagement />;
            case "behaviour_zones":
                return <BehaviourZoneManagement />;
            case "gpx_import":
                return <ImportManagement />;
            case "observation_review":
                return <ObservationReview />;
            case "driver_training_manager":
                return <DriverTrainingManager />;
            default:
                return null;
        }
    };

    const logoutClicked = () => {
        dispatch(logout());
        navToBase();
    };

    const navToBase = () => {
        let baseURL = window.location.origin;
        window.location.replace(baseURL);
    };

    const toggleMainNavbar = () => {
        setMainNavbarCollapsed(!mainNavbarCollapsed);
    };

    const hasBehaviourPermission =
        Object.values(dataPoolPermissions).filter((dataPoolPerms) => {
            return dataPoolPerms.curation;
        }).length || userDetails.userConfig.super_admin;

    const hasImportPermissions =
        Object.values(dataPoolPermissions).filter((dataPoolPerms) => {
            return dataPoolPerms.import;
        }).length || userDetails.userConfig.super_admin;

    return (
        <div className="App AdminPanelApp">
            <div className="MainNavBar">
                <div className="MainNavBarTop">
                    <img
                        className="MainNavBarLogo"
                        src={logo}
                        alt="AIVR Logo"
                        crossOrigin={"anonymous"}
                    />
                    {loggedIn && (
                        <>
                            <div className="MainNavBarToggleButton">
                                <div
                                    className="IconContainer"
                                    onClick={toggleMainNavbar}>
                                    {mainNavbarCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                                </div>
                                <Tooltip
                                    placement="topLeft"
                                    title={userDetails.description}
                                    arrowPointAtCenter>
                                    <div className={`WorkspaceLabel ${mainNavbarCollapsed ? "hide" : "show"}`}>{userDetails.description}</div>
                                </Tooltip>
                            </div>
                            <Menu
                                className="MainNavBarContainer"
                                defaultSelectedKeys={["markers"]}
                                defaultOpenKeys={[""]}
                                mode="inline"
                                theme="dark"
                                onClick={(e) => setActiveWindow(e.key)}
                                inlineCollapsed={mainNavbarCollapsed}>
                                {superAdmin ? (
                                    <Menu.Item key="markers">
                                        <SettingOutlined />
                                        <span>Detection Configuration</span>
                                    </Menu.Item>
                                ) : null}

                                {superAdmin ? (
                                    <Menu.Item key="marker_dashboard">
                                        <PieChartOutlined />
                                        <span>Detection Dashboard</span>
                                    </Menu.Item>
                                ) : null}

                                {superAdmin ? (
                                    <Menu.Item
                                        key="behaviour_zones"
                                        disabled={!hasBehaviourPermission}>
                                        <GlobalOutlined />
                                        <span>Behaviour Zones</span>
                                    </Menu.Item>
                                ) : null}

                                <Menu.Item
                                    key="gpx_import"
                                    disabled={!hasImportPermissions}>
                                    <CloudUploadOutlined />
                                    <span>GPX Import</span>
                                </Menu.Item>

                                {superAdmin ? (
                                    <Menu.Item key="observation_review">
                                        <EyeOutlined />
                                        <span>Observation Review</span>
                                    </Menu.Item>
                                ) : null}

                                {currentDashboardLayout === "driver_training" ? (
                                    <Menu.Item key="driver_training_manager">
                                        <FileProtectOutlined />
                                        <span>Driver Training Manager</span>
                                    </Menu.Item>
                                ) : null}
                            </Menu>
                        </>
                    )}
                </div>
                <div className="MainNavBarBottom">
                    <Tippy
                        zIndex="600"
                        arrow={true}
                        placement="right"
                        content="Back"
                        theme="dark"
                        delay="100">
                        <Link to="/">
                            <div className="SidebarButtonContainer">
                                <FontAwesomeIcon
                                    color="#e8dfff"
                                    icon={faArrowLeft}
                                    size="2x"
                                />
                            </div>
                        </Link>
                    </Tippy>

                    <Tippy
                        zIndex="600"
                        arrow={true}
                        placement="right"
                        content="Logout"
                        theme="dark"
                        delay="100">
                        <div
                            className="SidebarButtonContainer"
                            onClick={logoutClicked}>
                            <FontAwesomeIcon
                                color="#e8dfff"
                                icon={faSignOutAlt}
                                size="2x"
                            />
                        </div>
                    </Tippy>
                </div>
            </div>

            {!loggedIn ? (
                <div className="AdminPasswordContainer">
                    <input
                        type="password"
                        data-lpignore="true"
                        className="AdminPasswordInputField"
                        value={inputPassword}
                        onChange={updateAdminPassword}
                        onKeyPress={keyPressed}
                        placeholder={"Please re-enter your password"}
                        autoFocus
                    />
                    <button className="HiddenLoginButton">Login</button>
                    <OBCButton
                        className="AdminPasswordConfirmButton"
                        variant="success"
                        onClick={confirmAdminPassword}
                        disabled={!confirmEnabled || inputPassword.length < 1}>
                        Confirm {!confirmEnabled && <LoadingOutlined style={{ marginLeft: "4px" }} />}
                    </OBCButton>
                </div>
            ) : (
                renderAdminComponent()
            )}
        </div>
    );
};

export default AdminApp;
