import React, { useMemo } from "react";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, notification } from "antd";
import { faDownload, faPrint } from "@fortawesome/pro-regular-svg-icons";
import { getMediaUploadTokens, createNewMediaUploadToken, revokeMediaUploadToken } from "redux/actions";
import QRCode from "react-qr-code";
import UploadManagerListItem from "./UploadManagerListItem";
import logo from "../../images/new-branding-2.0/aivr-purple.svg";
import OBCSpinner from "components/util/OBC";
import OBCSearchInput from "components/OBC/OBCSearchInput";
import OBCButton from "components/OBC/OBCButton";
import { SyncOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const mediaUploadListSelector = (state) => state.mediaUploads.tokensList;
const tokensListLoadingSelector = (state) => state.mediaUploads.tokensListLoading;
const userWorkspaceSelector = (state) => state.userDetails.description;

const UploadManagerDrawer = () => {
    const dispatch = useDispatch();

    const userWorkspace = useSelector(userWorkspaceSelector);
    const mediaUploadList = useSelector(mediaUploadListSelector);
    const tokensListLoading = useSelector(tokensListLoadingSelector);

    const [searchString, setSearchString] = useState("");

    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
    const [qrCodeModalText, setQrCodeModalText] = useState(null);

    const [createNewTokenModalOpen, setCreateNewTokenModalOpen] = useState(false);
    const [newTokenDescription, setNewTokenDescription] = useState("");

    const onCopyToClipboardClicked = (e) => {
        e.stopPropagation();
        let svgData = document.getElementById("UploadManagerQrCode").outerHTML;
        let svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        let svgUrl = URL.createObjectURL(svgBlob);
        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "qr_code_aivr.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setQrCodeModalOpen(false);
    };

    const onPrintClicked = (e) => {
        e.stopPropagation();
        var content = document.getElementsByClassName("PrintableDiv");
        var print = document.getElementById("UploadManagerQrCodePrintPage").contentWindow;
        print.document.open();
        print.document.write("<html><head><title></title>");
        print.document.write("</head><body >");
        print.document.write(content[0].innerHTML);
        print.document.write("</body></html>");
        print.document.close();
        print.focus();
        print.print();
    };

    const createNewToken = () => {
        dispatch(
            createNewMediaUploadToken(newTokenDescription, (res) => {
                if (res.success) {
                    dispatch(getMediaUploadTokens(true));
                    notification.success({
                        message: "Success",
                        description: "New token created successfully.",
                        duration: 2,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: "Error creating token, try again.",
                        duration: 3,
                    });
                }
                setCreateNewTokenModalOpen(false);
            }),
        );
    };

    const onRevokeTokenClick = (token) => {
        console.log("debug onRevokeTokenClick", token);
        dispatch(
            revokeMediaUploadToken(token, (res) => {
                if (res.success) {
                    dispatch(getMediaUploadTokens(true));
                    notification.success({
                        message: "Success",
                        description: "Token revoked successfully.",
                        duration: 2,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: "Error occurred while removing token, try again.",
                        duration: 3,
                    });
                }
            }),
        );
    };

    const filteredList = useMemo(() => {
        const sortedList = _.sortBy(mediaUploadList, (token) => token.created_ts).reverse();
        return _.filter(sortedList, (token) => token.description.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }, [searchString, mediaUploadList]);

    return (
        <div style={{ position: "relative" }}>
            <iframe
                id="UploadManagerQrCodePrintPage"
                style={{ position: "absolute", display: "none" }}></iframe>

            {/* QR Code Modal */}
            <Modal
                className="UploadManagerQrCodeModal"
                visible={qrCodeModalOpen}
                onCancel={() => setQrCodeModalOpen(false)}
                footer={[
                    <OBCButton
                        key={"UploadManagerQrCodeModalButtonPrint"}
                        icon={faPrint}
                        onClick={onPrintClicked}>
                        Print
                    </OBCButton>,
                    <OBCButton
                        key={"UploadManagerQrCodeModalButtonCopy"}
                        icon={faDownload}
                        onClick={onCopyToClipboardClicked}>
                        Download
                    </OBCButton>,
                ]}>
                <div className="PrintableDiv">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: "20px" }}>
                        <img
                            className="hide-but-print"
                            style={{ width: "200px", height: "100px" }}
                            src={logo}
                            alt="Aivr Logo"
                            crossOrigin={"anonymous"}
                        />
                        <h3
                            className="hide-but-print"
                            style={{ maxWidth: "400px", textAlign: "center" }}>
                            Scan below QR code to start uploading media to <strong>{userWorkspace}</strong> workspace.
                        </h3>
                        {qrCodeModalText ? (
                            <QRCode
                                id="UploadManagerQrCode"
                                value={qrCodeModalText}
                            />
                        ) : (
                            <div>Unable to generate QR Code</div>
                        )}
                    </div>
                </div>
            </Modal>

            {/* Create New Token Modal */}
            <Modal
                title="Create new token"
                className="CreateNewTokenModal"
                visible={createNewTokenModalOpen}
                onCancel={() => {
                    setCreateNewTokenModalOpen(false);
                    setNewTokenDescription("");
                }}
                footer={<OBCButton onClick={createNewToken}>Create token</OBCButton>}>
                <>
                    <h4 style={{ marginBottom: 0 }}>
                        By creating a token you’ll be able to get members of your team to upload media directly to your workspace using their mobile phone,
                        tablet or laptop. Uploaded content will then become available in your workspace.
                    </h4>
                    <div className="CreateNewTokenRow">
                        <div className="Label">Token Name:</div>
                        <div className="Control">
                            <Input
                                autoFocus
                                placeholder="Enter Token name. eg. Electric Box 123"
                                onChange={(e) => setNewTokenDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            </Modal>

            {/* Upload manager drawer */}
            <div className="UploadManagerDrawerMain">
                <div className="DrawerMainHeader">
                    <OBCSearchInput
                        placeholder="Search for token"
                        disabled={mediaUploadList.length === 0}
                        value={searchString}
                        onChange={(value) => setSearchString(value)}
                    />
                    <div className="HeaderRightColumn">
                        <OBCButton
                            onClick={() => dispatch(getMediaUploadTokens())}
                            disabled={mediaUploadList.length === 0}>
                            <SyncOutlined
                                spin={tokensListLoading}
                                className="icon"
                            />
                        </OBCButton>
                        <OBCButton onClick={() => setCreateNewTokenModalOpen(true)}>Create New Token</OBCButton>
                    </div>
                </div>
                <div className="DrawerBody">
                    {tokensListLoading ? (
                        <div className="UploadManagerDrawerSpinner">
                            <OBCSpinner
                                size={90}
                                speed={3}
                                color={"#e8dfff"}
                            />
                        </div>
                    ) : (
                        <div className="TokenList">
                            {filteredList.length > 0 ? (
                                _.map(filteredList, (token) => {
                                    return (
                                        <UploadManagerListItem
                                            key={token.token}
                                            setQrCodeModalOpen={setQrCodeModalOpen}
                                            setQrCodeModalText={setQrCodeModalText}
                                            onRevokeTokenClick={onRevokeTokenClick}
                                            tokenData={token}
                                            searchString={searchString}
                                        />
                                    );
                                })
                            ) : searchString.length > 0 ? (
                                <h1 className="NoTokensFoundLabel">No match found</h1>
                            ) : (
                                <div className="NoTokensFoundLabel">
                                    <h1>No tokens found</h1>
                                    <h3>
                                        Click{" "}
                                        <OBCButton
                                            variant="link"
                                            style={{ top: "-1px" }}
                                            onClick={() => setCreateNewTokenModalOpen(true)}>
                                            here
                                        </OBCButton>{" "}
                                        to create one now.
                                    </h3>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadManagerDrawer;
