import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailPasswordLogin, receiveAccessToken } from "../redux/actions";
import { withRouter, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MediaQuery from "react-responsive";
import logo from "../images/new-branding-2.0/aivr-purple.svg";
import logoWhite from "../images/new-branding-2.0/aivr-hybrid.svg";
import OBCSpinner from "./util/OBC";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";

const accessTokenSelector = (state) => state.access_token;

const JwtToken = (props) => {
    const dispatch = useDispatch();

    const accessToken = useSelector(accessTokenSelector);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        console.log("debug resetting access token here");
        dispatch(receiveAccessToken(null));
    }, [dispatch]);

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const jwtToken = params.get("token");
        if (jwtToken) {
            setLoading(true);
            dispatch(
                emailPasswordLogin(
                    {
                        jwt: jwtToken,
                    },
                    {},
                    isMobile,
                    (response, error) => {
                        console.log("JWT login response", response, error);
                        setLoading(false);
                        if (error) {
                            setError(error);
                        } else {
                            setSuccess(true);
                        }
                    },
                ),
            );
        } else {
            if (window.location.hostname !== `view-widget${MEMOIZED_DOMAIN_URL}`) {
                setError("This link is invalid or expired");
            } else {
                setError("This session is invalid or expired");
            }
        }
    }, [props.location.search, dispatch]);

    if (!accessToken || !success) {
        return (
            <div className="LoginOverlay Center-Content">
                <div className="LoginContent__Container">
                    <div className="LoginContent">
                        <MediaQuery maxWidth={479}>
                            <img
                                className="LoginLogo mobile"
                                src={logoWhite}
                                alt="AIVR Logo"
                                crossOrigin={"anonymous"}
                            />
                        </MediaQuery>
                        <MediaQuery minWidth={479}>
                            <img
                                className="LoginLogo"
                                src={logo}
                                alt="AIVR Logo"
                                crossOrigin={"anonymous"}
                            />
                        </MediaQuery>

                        {loading && (
                            <OBCSpinner
                                size={100}
                                speed={3}
                                color={"#e8dfff"}
                            />
                        )}

                        {error && (
                            <div>
                                <p className="ErrorText">{error}</p>
                            </div>
                        )}

                        {window.location.hostname !== `view-widget${MEMOIZED_DOMAIN_URL}}` && (
                            <div className="LoginFooter">
                                <div className="LoginSales">
                                    For enquiries, please contact <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a>.
                                </div>
                                <div className="LoginTerms">
                                    By using this website you agree to our&nbsp;
                                    <p>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="/terms">
                                            Terms of Use
                                        </a>{" "}
                                        &{" "}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="/privacy">
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    } else if (success) {
        let redirect = { pathname: "/" };
        if (props.location.state && props.location.state.from) {
            redirect = props.location.state.from;
        } else if (isMobile && !props.requestedDesktop) {
            redirect = { pathname: "/mobile" };
        }

        return <Redirect to={redirect} />;
    }
};

export default withRouter(JwtToken);
