import React from "react";
import { Image } from "kaleidoscopejs";
import Measure from "react-measure";

class EquirectangularImage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.viewer = null;
    }

    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.destroy();
        }
    }

    onImageLoadComplete = () => {
        this.viewer.needsUpdate = true;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.viewer && this.props.src && this.imageRef.current) {
            this.viewer = new Image({
                source: this.props.src,
                container: this.imageRef.current,
                width: this.imageRef.current.offsetWidth - 1,
                height: this.imageRef.current.offsetHeight,
            });
            this.viewer.render();
        }

        if (this.viewer && this.viewer.texture && this.viewer.texture.image && this.viewer.texture.image.src) {
            this.viewer.texture.image.onload = this.onImageLoadComplete;
            this.viewer.texture.image.src = this.props.src;
            this.viewer.texture.needsUpdate = true;
        }
    }

    getMeasuredContent = ({ measureRef, contentRect }) => {
        if (this.viewer) {
            this.viewer.setSize({
                width: contentRect.bounds.width,
                height: (contentRect.bounds.width * 9) / 16,
            });
        }
        return (
            <div ref={measureRef}>
                <div ref={this.imageRef} />
            </div>
        );
    };

    render() {
        return <Measure bounds>{this.getMeasuredContent}</Measure>;
    }
}

export default EquirectangularImage;
