import { Select, notification } from "antd";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getEnvironmentalDataForBounds, goToMapPosition, setEnvironmentalDataFilters, setEnvironmentalDataSelectedSegment } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Highlighter from "react-highlight-words";

import { faChevronLeft, faChevronRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import OBCSearchInput from "./OBC/OBCSearchInput";

const selectedSegmentSelector = (state) => state.environmentData.selectedSegment;
const environmentDataTypesSelector = (state) => state.environmentData.types;
const environmentDataSubTypesSelector = (state) => state.environmentData.subTypes;
const environmentDataSubFiltersSelector = (state) => state.environmentData.filters;
const environmentDataMapDataSelector = (state) => state.environmentData.mapData;
const environmentDataLoadedSelector = (state) => state.environmentData.dataLoaded;
const environmentDataErrorLoadingSelector = (state) => state.environmentData.errorLoading;

// below values might have to be changed if more data added to the database (also will have to be updated in EnvironmentalPolygons.js)
const KEYS_PER_DATA_TYPE = {
    vegetation: [
        "data_type",
        "sub_data_type",
        "route_id",
        "full_data.display_fields.Vegetation Density Level",
        "full_data.display_fields.Vegetation Encroachment Status",
        "full_data.vegetation_density_percentage",
        "full_data.corridor_width",
        "start_location",
        "end_location",
    ],
    land_movement: [
        "data_type",
        "sub_data_type",
        "route_id",
        "full_data.display_fields.Land Movement Average Velocity",
        "full_data.display_fields.Land Movement Velocity Rate of Change",
        "full_data.velocity_rate_of_change",
        "full_data.number_of_points",
        "full_data.average_velocity",
        "full_data.corridor_width",
        "start_location",
        "end_location",
    ],
    flood_risk: [
        "data_type",
        "sub_data_type",
        "route_id",
        "full_data.display_fields.Fluvial Flood Risk",
        "full_data.display_fields.Pluvial Flood Risk",
        "full_data.extra_fields.max_flood_depth_cm",
        "full_data.corridor_width",
        "start_location",
        "end_location",
    ],
};

const SatelliteDataTab = () => {
    const dispatch = useDispatch();

    const selectedSegment = useSelector(selectedSegmentSelector);
    const environmentDataTypes = useSelector(environmentDataTypesSelector);
    const environmentDataSubTypes = useSelector(environmentDataSubTypesSelector);
    const environmentDataSubFilters = useSelector(environmentDataSubFiltersSelector);
    const environmentDataMapData = useSelector(environmentDataMapDataSelector);
    const environmentDataLoaded = useSelector(environmentDataLoadedSelector);
    const environmentDataErrorLoading = useSelector(environmentDataErrorLoadingSelector);

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRouteSection, setSelectedRouteSection] = useState(null);

    const filterType = useMemo(() => {
        return _.get(environmentDataSubFilters, "type");
    }, [environmentDataSubFilters]);

    const filterSubType = useMemo(() => {
        return _.get(environmentDataSubFilters, "subType");
    }, [environmentDataSubFilters]);

    const filteredSubTypes = useMemo(() => {
        const newList = _.filter(environmentDataSubTypes, (subType) => _.includes(subType, filterType));
        return newList;
    }, [environmentDataSubTypes, filterType]);

    useEffect(() => {
        dispatch(getEnvironmentalDataForBounds());
    }, [dispatch]);

    // when user changes the data type we need to reselect the sub type (default to the first position)
    const changeFilterType = (value) => {
        dispatch(setEnvironmentalDataSelectedSegment({}));
        const firstValidSubType = _.get(
            _.filter(environmentDataSubTypes, (subType) => _.includes(subType, value)),
            0,
        );
        dispatch(setEnvironmentalDataFilters({ type: value, subType: firstValidSubType }));
    };

    // when user change the data sub type clear currently selected
    const changeFilterSubType = (value) => {
        dispatch(setEnvironmentalDataSelectedSegment({}));
        dispatch(setEnvironmentalDataFilters({ subType: value }));
    };

    // below to optimise the amount od rerenders
    useEffect(() => {
        const currentRouteID = _.get(selectedSegment, "route_id");
        const selectedRouteID = _.get(selectedRouteSection, "route_id");
        if (selectedRouteID !== currentRouteID) {
            setSelectedRouteSection(selectedSegment);
        }
    }, [selectedRouteSection, selectedSegment]);

    const calculateStartMileAndChain = (position) => {
        const start_mile = Math.floor(position / 80);
        const start_chain = position % 80;
        return `MILE: ${start_mile} CHAIN: ${start_chain}`;
    };

    // this will return the list of all segments for currently selected segment so we can navigate next & previous
    const selectedRouteIdSegments = useMemo(() => {
        let listOfSegments = [];
        const selectedSegmentRouteID = _.get(selectedRouteSection, "route_id");
        const selectedSegmentDataType = _.get(selectedRouteSection, "data_type");
        const selectedSegmentSubDataType = _.get(selectedRouteSection, "sub_data_type");

        if (selectedSegmentRouteID) {
            _.map(environmentDataMapData, (segment, index) => {
                if (
                    segment &&
                    segment.route_id === selectedSegmentRouteID &&
                    segment.data_type === selectedSegmentDataType &&
                    segment.sub_data_type === selectedSegmentSubDataType
                ) {
                    const newSegment = { ...segment, ...{ id: index } };
                    listOfSegments.push(newSegment);
                }
            });
        }

        listOfSegments = _.orderBy(listOfSegments, ["start_location"], ["asc"]);

        // console.log("debug3 listOfSegments", listOfSegments)

        return listOfSegments;
    }, [environmentDataMapData, selectedRouteSection]);

    // this returns a list of all ELR's for selected data type & data subtype
    const filteredElrsList = useMemo(() => {
        // prevent from running this if not all envData loaded yet
        if (!environmentDataLoaded) {
            return null;
        }
        let elrList = [];

        // get all segments for selected data_type and sub_data_type
        _.map(environmentDataMapData, (segment, index) => {
            let includeSegment = false;
            if (segment && segment.data_type === filterType && segment.sub_data_type === filterSubType) {
                includeSegment = true;

                if (searchQuery && searchQuery.length > 0) {
                    if (String(segment.route_id).toLowerCase().indexOf(searchQuery.toLowerCase()) === -1) {
                        includeSegment = false;
                    }
                }

                if (includeSegment) {
                    const newSegment = { ...segment, ...{ id: index } };
                    elrList.push(newSegment);
                }
            }
        });
        let groupedList = _.groupBy(elrList, "route_id");

        // sort list alphabetically
        groupedList = Object.keys(groupedList)
            .sort() // Sort keys alphabetically
            .reduce((acc, key) => {
                acc[key] = groupedList[key];
                return acc;
            }, {});

        // console.log("debug4 groupedList", groupedList)
        return groupedList;
    }, [environmentDataLoaded, environmentDataMapData, filterSubType, filterType, searchQuery]);

    // this will calculate the center of the polygon so we can set map position to the center of the polygon
    const getPolygonCentroid = (vertices) => {
        let xSum = 0;
        let ySum = 0;

        if (_.isEmpty(vertices)) {
            return null;
        }
        const numVertices = vertices.length;

        vertices.forEach((vertex) => {
            xSum += vertex[0];
            ySum += vertex[1];
        });

        return [xSum / numVertices, ySum / numVertices];
    };

    // Update position of the map based on new segment coordinates
    const updateMapPosition = useCallback(
        (segment) => {
            const segmentCoordinates = getPolygonCentroid(segment.coordinates);
            if (segmentCoordinates) {
                // this commented part I'll leave for now in case we want to revert back to using goToBounds instead
                // const boundsObj = {
                //     north: segmentCoordinates[0] + 0.003,
                //     south: segmentCoordinates[0] - 0.003,
                //     east: segmentCoordinates[1] + 0.003,
                //     west: segmentCoordinates[1] - 0.003
                // }
                // dispatch(goToBounds(boundsObj))

                dispatch(goToMapPosition([segmentCoordinates[0], segmentCoordinates[1], 15]));
            }
        },
        [dispatch],
    );

    // navigate to the next segment if exists, otherwise display notification info that this is last segment for this ELR line
    const goToNextSegment = useCallback(() => {
        const selectedSegmentID = _.get(selectedSegment, "id");
        const selectedSegmentIndex = _.findIndex(selectedRouteIdSegments, { id: selectedSegmentID });
        const nextSegment = _.get(selectedRouteIdSegments, selectedSegmentIndex + 1);
        if (nextSegment) {
            dispatch(setEnvironmentalDataSelectedSegment(nextSegment));
            updateMapPosition(nextSegment);
        } else {
            notification.info({
                message: "Last segment",
                description: "You are currently on the last segment in this ELR section",
            });
        }
    }, [selectedSegment, selectedRouteIdSegments, dispatch, updateMapPosition]);

    // navigate to the previous segment if exists, otherwise display notification info that this is last segment for this ELR line
    const goToPreviousSegment = useCallback(() => {
        const selectedSegmentID = _.get(selectedSegment, "id");
        const selectedSegmentIndex = _.findIndex(selectedRouteIdSegments, { id: selectedSegmentID });
        const nextSegment = _.get(selectedRouteIdSegments, selectedSegmentIndex - 1);
        if (nextSegment) {
            dispatch(setEnvironmentalDataSelectedSegment(nextSegment));
            updateMapPosition(nextSegment);
        } else {
            notification.info({
                message: "First segment",
                description: "You are currently on first segment in this ELR section",
            });
        }
    }, [selectedSegment, selectedRouteIdSegments, dispatch, updateMapPosition]);

    // generate selected segment info which is displayed in the left below filters and nav buttons
    const selectedSegmentInfo = useMemo(() => {
        if (!_.isEmpty(selectedSegment)) {
            const dataTypeKeys = _.get(KEYS_PER_DATA_TYPE, selectedSegment.data_type, []);

            let startEndLocations = { start: null, end: null };

            return (
                <div className="SatelliteDataTabInfoContainer">
                    <div className="SatelliteDataTabInfoContainerTitle">Segment details:</div>
                    {_.map(dataTypeKeys, (key, index) => {
                        const keysList = _.split(key, ".");
                        const label = _.startCase(keysList.at(-1));
                        let value = _.get(selectedSegment, keysList, null);

                        if (keysList.at(-1) === "vegetation_density_percentage") {
                            value = `${_.floor(value, 2)}%`;
                        }

                        if (["data_type", "sub_data_type"].includes(keysList.at(-1))) {
                            value = _.startCase(value);
                        }

                        if (["start_location"].includes(keysList.at(0))) {
                            value = calculateStartMileAndChain(value);
                            startEndLocations.start = value;
                            value = null;
                        }
                        if (["end_location"].includes(keysList.at(0))) {
                            value = calculateStartMileAndChain(value);
                            startEndLocations.end = value;
                            value = null;
                        }

                        if (value) {
                            return (
                                <div
                                    key={index}
                                    className="SatelliteDataTabInfoContainerItem">
                                    <div className="SatelliteDataTabInfoContainerItemLabel">{label}:</div>
                                    <div className="SatelliteDataTabInfoContainerItemValue">{value}</div>
                                </div>
                            );
                        }
                    })}
                    {startEndLocations.start && startEndLocations.end ? (
                        <div className="SatelliteDataTabInfoContainerItem">
                            <div className="SatelliteDataTabInfoContainerItemLabel">Location:</div>
                            <div className="SatelliteDataTabInfoContainerItemValue">
                                {startEndLocations.start} - {startEndLocations.end}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }

        return (
            <div className="SatelliteDataTabInfoContainer">
                <div className="SatelliteDataTabInfoContainerCenterInfo">Select segment on the map or ELR from the list</div>
            </div>
        );
    }, [selectedSegment]);

    // this will find and select first element for selected ELR from the list on the right
    const selectFirstSegment = useCallback(
        (elrSegments) => {
            let firstElement = _.get(elrSegments, 0);
            if (firstElement) {
                dispatch(setEnvironmentalDataSelectedSegment(firstElement));
                updateMapPosition(firstElement);
            }
        },
        [dispatch, updateMapPosition],
    );

    // search query to be fired when user start typing in the search input
    const updateFilterSearchQuery = (value) => {
        dispatch(setEnvironmentalDataFilters({ searchQuery: value }));
    };

    // this will also get fired at the same time as above update but with debounce to improve performance
    const setFilterSearchQuery = useCallback(
        _.debounce((value) => {
            updateFilterSearchQuery(value);
        }, 250),
        [],
    );

    // will be called when user press X icon next to the nav buttons
    const clearSelectedSection = () => {
        dispatch(setEnvironmentalDataSelectedSegment({}));
    };

    // returns ELR name eg. CWL2
    const selectedElr = useMemo(() => {
        const currentSegmentElr = _.get(selectedRouteSection, "route_id");
        let selectedElr = _.get(filteredElrsList, currentSegmentElr, {});
        selectedElr = _.groupBy(selectedElr, "route_id");
        selectedElr = _.findKey(selectedElr);

        return selectedElr;
    }, [filteredElrsList, selectedRouteSection]);

    // returns null or current segment out of all segments number (1 out of 100)
    const segmentIndexLabel = useMemo(() => {
        const outOfSegments = Object.keys(selectedRouteIdSegments).length;
        if (!selectedSegment || !outOfSegments) {
            return null;
        }

        const selectedSegmentID = _.get(selectedSegment, "id");
        const selectedSegmentIndex = _.findIndex(selectedRouteIdSegments, { id: selectedSegmentID });

        return `${selectedSegmentIndex + 1} out of ${outOfSegments}`;
    }, [selectedRouteIdSegments, selectedSegment]);

    return (
        <div className="SatelliteDataTabMain">
            {environmentDataErrorLoading && !environmentDataMapData.length ? (
                <div className="SatelliteDataTabMainErrorMessage">There was an error loading data, please try refreshing a page.</div>
            ) : (
                <>
                    <div className="SatelliteDataTabFilterContainer">
                        <div className="LeftCol">
                            <div className="SatelliteDataTabFilterItem">
                                <span className="label">Type:</span>
                                <Select
                                    className="SatelliteDataTabFilter"
                                    dropdownMatchSelectWidth={false}
                                    size="small"
                                    value={filterType}
                                    onChange={changeFilterType}>
                                    {_.map(environmentDataTypes, (type) => {
                                        return (
                                            <Select.Option
                                                key={type}
                                                value={type}>
                                                {_.startCase(type)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>

                            <div className="SatelliteDataTabFilterItem">
                                <span className="label">Sub Type:</span>
                                <Select
                                    className="SatelliteDataTabFilter"
                                    size="small"
                                    dropdownMatchSelectWidth={false}
                                    value={filterSubType}
                                    onChange={changeFilterSubType}>
                                    {_.map(filteredSubTypes, (type) => {
                                        return (
                                            <Select.Option
                                                key={type}
                                                value={type}>
                                                {_.startCase(type)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>

                        <div className="RightCol">
                            <OBCSearchInput
                                value={searchQuery}
                                onChange={(value) => {
                                    setSearchQuery(value);
                                    setFilterSearchQuery(value);
                                }}
                                placeholder="Search for ELR"
                            />
                        </div>
                    </div>
                    <div className="SatelliteDataTabMainBottom">
                        <div className="SatelliteDataTabMainLeft">
                            {segmentIndexLabel ? (
                                <div className="SatelliteDataTabMainLeftNavigation">
                                    <div className="markerControls">
                                        <div
                                            className="markerControls__Item"
                                            onClick={goToPreviousSegment}>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </div>
                                        <div>{segmentIndexLabel}</div>
                                        <div
                                            className="markerControls__Item"
                                            onClick={goToNextSegment}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="closeButton"
                                            onClick={clearSelectedSection}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {environmentDataErrorLoading && environmentDataMapData.length ? (
                                <div>There was an problem loading data, some of the data will be missing!</div>
                            ) : null}
                            {selectedSegmentInfo}
                        </div>
                        <div className="SatelliteDataTabMainRight">
                            <div className="SatelliteDataTabMainRightFilters"></div>
                            <div className="SatelliteDataTabMainRightMain">
                                <div className="SatelliteDataTabMainRightList">
                                    {!filteredElrsList ? (
                                        <div>Loading...</div>
                                    ) : !Object.keys(filteredElrsList).length && searchQuery.length ? (
                                        <div>
                                            No results for <strong style={{ color: "#F8C46D" }}>{searchQuery}</strong>
                                        </div>
                                    ) : (
                                        _.map(filteredElrsList, (elrSegments, elr) => {
                                            return (
                                                <div
                                                    key={elr}
                                                    className={`SatelliteDataTabMainRightListItem ${selectedElr === elr ? "active" : ""}`}
                                                    onClick={() => selectFirstSegment(elrSegments)}>
                                                    <div>
                                                        ELR:{" "}
                                                        <strong>
                                                            <Highlighter
                                                                highlightClassName="ObcTextHighlight"
                                                                searchWords={searchQuery.split(" ")}
                                                                autoEscape={true}
                                                                textToHighlight={elr}
                                                            />
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        Total Segments: <strong>{Object.keys(elrSegments).length}</strong>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SatelliteDataTab;
