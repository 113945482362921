import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Select, Button, Input, notification, Checkbox, Slider, InputNumber } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { createMarkerExport, requestContinuousObservationsReport } from "../redux/actions/index";
import { combineContinuousObservations } from "./util/PlaylistUtils";
import ELRMileAndChain from "./util/ELRMileAndChain";

const sessionIDSelector = (state) => _.get(state, ["playlist", "data", "routeID"]);
const userEmailSelector = (state) => state.userDetails.email;
const markerSelector = (state) => {
    const sessionID = _.get(state, ["playlist", "data", "routeID"]);
    return _.get(state.markers.perSession, [sessionID], []);
};
const thresholdFilterSelector = (state) => state.markerThresholdFilters;
const sessionObservationsSelector = (state) => state.sessionObservations;
const detectionTypesSelector = (state) => state.detectionTypes;

const emptyObject = {};
const routeLocationSelector = (state) => state.playlist.data.route_locations;

const DEFAULT_CONTINUOUS_REPORT_FORM = {
    end_ts: null,
    filter_options: null,
    review_status: "0, 1",
    score: null,
    sessionID: null,
    start_ts: null,
    types: [],
};

const CONTINUOUS_OBSERVATION_REPORT_TYPES = {
    "Scrap Rail": "enable_cont_obser_report_scrap_rail",
    low_ballast: "enable_cont_obser_report_low_ballast",
    Troughing: "enable_cont_obser_report_troughing",
};

const MarkerExportModal = ({ closeModal, observationType = null }) => {
    const dispatch = useDispatch();

    const sessionID = useSelector(sessionIDSelector);
    const userEmail = useSelector(userEmailSelector);
    const markers = useSelector(markerSelector);
    const detectionTypes = useSelector(detectionTypesSelector);
    const thresholdFilters = useSelector(thresholdFilterSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const sessionObservations = useSelector(sessionObservationsSelector);

    const [exportMarkupTypes, setExportMarkupTypes] = useState(observationType ? `${observationType}_observations` : "all");
    const [reviewStatus, setReviewStatus] = useState(["all"]);
    const [timeSelection, setTimeSelection] = useState([]);

    const [recipient, setRecipient] = useState(userEmail);
    const [name, setName] = useState("");
    const [exportLoading, setExportLoading] = useState(false);
    const [allSessionsToggle, setAllSessionsToggle] = useState(false);
    const [allSessionsELR, setAllSessionsELR] = useState("");
    const [continuousObservationReport, setContinuousObservationReport] = useState(false);
    const [continuousReportForm, setContinuousReportForm] = useState({});

    const currentRouteID = useSelector((state) => state.playlist.data.routeID);
    const defaultMarkers = useSelector((state) => state.defaultMarkersThresholds[currentRouteID] || emptyObject);

    useEffect(() => {
        const form = DEFAULT_CONTINUOUS_REPORT_FORM;
        if (_.has(CONTINUOUS_OBSERVATION_REPORT_TYPES, observationType)) {
            form.types = [CONTINUOUS_OBSERVATION_REPORT_TYPES[observationType]];
        }
        setContinuousReportForm(form);
    }, [observationType]);

    const onClick = () => {
        let filters = {};
        if (reviewStatus && reviewStatus[0] !== "all") {
            filters.review_status_filters = reviewStatus;
        }
        if (exportMarkupTypes !== "all") {
            filters.types = exportMarkupTypes;
        }
        filters.score_filters = {};

        detectionTypes
            .filter((type) => {
                return type.min_score || type.max_score;
            })
            .forEach((type) => {
                const defValue = _.get(defaultMarkers, [type.type], type.default_score);
                const filterValue = _.get(thresholdFilters, [type.type], defValue);
                filters.score_filters[type.type] = filterValue;
            });

        if (allSessionsToggle) {
            filters.exportELR = allSessionsELR;
        }

        console.log("debug filters", filters);

        setExportLoading(true);
        dispatch(createMarkerExport(sessionID, name, recipient, filters)).then((success) => {
            setExportLoading(false);
            if (success) {
                notification.success({
                    message: "Successfully triggered export",
                });
                closeModal();
            } else {
                notification.error({
                    message: "Error creating export",
                });
            }
        });
    };

    const requestContinuousObservationReport = () => {
        const form = {
            ...continuousReportForm,
            start_ts: continuousReportForm.start_ts ? Math.round(continuousReportForm.start_ts / 1000) : null,
            end_ts: continuousReportForm.end_ts ? Math.round(continuousReportForm.end_ts / 1000) : null,
        };
        dispatch(
            requestContinuousObservationsReport(form, (response) => {
                if (response.success) {
                    notification.success({
                        message: response.message,
                    });
                    closeModal();
                } else {
                    notification.error({
                        message: "Error creating export",
                    });
                }
            }),
        );
    };

    const combinedObservations = useMemo(() => {
        return combineContinuousObservations(sessionObservations);
    }, [sessionObservations]);

    const onReviewStatusChange = useCallback(
        (options) => {
            if (!continuousObservationReport) {
                if ((options.includes("all") && !reviewStatus.includes("all")) || options.length === 0) {
                    options = ["all"];
                } else if (reviewStatus.includes("all") && options.length) {
                    const index = options.indexOf("all");
                    if (index > -1) {
                        options.splice(index, 1);
                    }
                }
                setReviewStatus(options);
            } else {
                if (options.length) {
                    setContinuousReportForm({ ...continuousReportForm, review_status: options.join(", ") });
                } else {
                    setContinuousReportForm({ ...continuousReportForm, review_status: null });
                }
            }
        },
        [reviewStatus, continuousObservationReport, continuousReportForm],
    );

    const exportCount = useMemo(() => {
        const filteredMarkers = markers.filter((marker) => {
            if (exportMarkupTypes !== "all") {
                if (marker.name !== exportMarkupTypes) {
                    return false;
                }
            }
            if (reviewStatus && reviewStatus[0] !== "all") {
                if (reviewStatus.includes(marker.review_status)) {
                    return false;
                }
            }

            let detectionType = _.find(detectionTypes, function (ann) {
                return ann.type === marker.name;
            });

            if (!detectionType || detectionType.display_type === "never") {
                return false;
            }
            console.log("debug detectionType", detectionType);

            const defValue = _.get(defaultMarkers, [detectionType.type], detectionType.default_score);
            console.log("debug def value", { defValue, thresholdFilters });

            if (!_.isNil(thresholdFilters[detectionType.type])) {
                if (marker.score && marker.score < thresholdFilters[detectionType.type]) {
                    return false;
                }
            } else if (defValue) {
                if (marker.score && marker.score < defValue) {
                    return false;
                }
            }
            return true;
        });

        const filteredObservations = combinedObservations.filter((observation) => {
            if (exportMarkupTypes !== "all") {
                if (`${observation.class}_observations` !== exportMarkupTypes) {
                    return false;
                }
            }

            if (reviewStatus && reviewStatus[0] !== "all") {
                if (!reviewStatus.includes(observation.review_status)) {
                    return false;
                }
            }

            return true;
        });
        return filteredMarkers.length + filteredObservations.length;
    }, [markers, combinedObservations, exportMarkupTypes, reviewStatus, detectionTypes, defaultMarkers, thresholdFilters]);

    const markerTypes = useMemo(() => {
        const observationTypes = _.uniqBy(sessionObservations, "class");
        const markerTypes = _.map(_.uniqBy(markers, "name"), "name").filter((type) => _.findIndex(observationTypes, { class: type }));

        if (!observationType) {
            const markerOptions = markerTypes.map((type) => {
                return (
                    <Select.Option
                        label={type}
                        value={type}
                        key={type}>
                        {type}
                    </Select.Option>
                );
            });
            return [
                <Select.Option
                    label="all"
                    value={"all"}>
                    All
                </Select.Option>,
                ...markerOptions,
            ];
        } else {
            return observationTypes
                .filter((obsObj) => {
                    return obsObj.class === observationType;
                })
                .map((obsObj) => {
                    return (
                        <Select.Option
                            label={`${obsObj.display_name || obsObj.class} Observations`}
                            value={`${obsObj.class}_observations`}
                            key={`${obsObj.class}observation`}>
                            {obsObj.display_name || obsObj.class} Observations
                        </Select.Option>
                    );
                });
        }
    }, [markers, observationType, sessionObservations]);

    const submitDisabled = useMemo(() => {
        if (continuousObservationReport) {
            return false;
        } else {
            return name === "" || recipient === "" || (allSessionsToggle && !allSessionsELR);
        }
    }, [name, recipient, allSessionsToggle, allSessionsELR, continuousObservationReport]);

    const elrOptions = useMemo(() => {
        return _.uniq(
            routeLocationData.map((locationData) => {
                return _.get(locationData, ["location", 0], null);
            }),
        ).map((elr) => {
            return (
                <Select.Option
                    value={elr}
                    key={elr}>
                    {elr}
                </Select.Option>
            );
        });
    }, [routeLocationData]);

    const getRouteStartAndEnd = useCallback(() => {
        if (routeLocationData && routeLocationData.length) {
            const startTS = parseInt(_.get(routeLocationData[0], "timestamp"));
            const endTS = parseInt(_.get(routeLocationData[routeLocationData.length - 1], "timestamp"));

            return {
                startTS,
                endTS,
            };
        }
        return {};
    }, [routeLocationData]);

    const openContinuousObservationReport = (e) => {
        setContinuousObservationReport(e.target.checked);
        const { startTS, endTS } = getRouteStartAndEnd();
        setTimeSelection([startTS, endTS]);
        setContinuousReportForm({ ...continuousReportForm, sessionID: sessionID });
    };

    const sliderRange = useMemo(() => {
        const { startTS, endTS } = getRouteStartAndEnd();
        if (timeSelection && timeSelection.length) {
            const formatTooltip = (ts) => {
                const dateObject = new Date(ts);
                const hours = ("0" + dateObject.getHours()).slice(-2);
                const minutes = ("0" + dateObject.getMinutes()).slice(-2);
                const seconds = ("0" + dateObject.getSeconds()).slice(-2);

                const position = _.minBy(routeLocationData, (location) => Math.abs(location.timestamp - ts));
                let elrMileAndChain = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_meterage",
                    {
                        ELR: position.location[0],
                        METERAGE: position.location[1],
                        TRACK: position.location[2],
                    },
                    [],
                    false,
                );

                return (
                    <div>
                        <p style={{ margin: "1px", textAlign: "center", fontSize: "12px" }}>
                            {hours}:{minutes}:{seconds}
                        </p>
                        <p style={{ margin: "0", textAlign: "center", fontSize: "12px" }}>
                            {position.location[0]} - {elrMileAndChain.to_mile_and_yard_string(false)}
                        </p>
                    </div>
                );
            };

            return (
                <Slider
                    style={{ marginTop: "3px" }}
                    className="exportInput"
                    tooltipPlacement="bottom"
                    range
                    min={startTS}
                    max={endTS}
                    tipFormatter={(e) => formatTooltip(e)}
                    onChange={(e) => setTimeSelection(e)}
                    value={timeSelection}
                />
            );
        }

        return null;
    }, [routeLocationData, timeSelection, getRouteStartAndEnd]);

    useEffect(() => {
        if (timeSelection && timeSelection.length) {
            setContinuousReportForm({ ...continuousReportForm, start_ts: timeSelection[0], end_ts: timeSelection[1] });
        }
    }, [timeSelection]);

    const reviewValue = useMemo(() => {
        if (continuousObservationReport) {
            return continuousReportForm.review_status ? _.map(continuousReportForm.review_status.split(", "), (r) => parseInt(r)) : [];
        } else {
            return reviewStatus;
        }
    }, [continuousObservationReport, continuousReportForm, reviewStatus]);

    return (
        <Modal
            title="Detection Export"
            visible={true}
            dropdownMatchSelectWidth={false}
            onOk={closeModal}
            onCancel={closeModal}
            footer={[
                <Button onClick={closeModal}>Cancel</Button>,
                <Button
                    loading={exportLoading}
                    type="primary"
                    onClick={continuousObservationReport ? requestContinuousObservationReport : onClick}
                    disabled={submitDisabled}>
                    Create Export
                </Button>,
            ]}>
            <div className="MarkupExportBody">
                {(observationType === "Troughing" || observationType === "Scrap Rail" || observationType === "low_ballast") && (
                    <Checkbox
                        style={{ marginBottom: "15px" }}
                        checked={continuousObservationReport}
                        onChange={(e) => openContinuousObservationReport(e)}>
                        Create continuous observation report?
                    </Checkbox>
                )}

                {!continuousObservationReport && (
                    <>
                        <div className="exportInputDiv">
                            <div className="exportInputLabel">Export Name</div>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ marginBottom: 10 }}
                                className="exportInput"
                            />
                        </div>

                        <div className="exportInputDiv">
                            <div className="exportInputLabel">Email Recipient</div>
                            <Input
                                value={recipient}
                                onChange={(e) => setRecipient(e.target.value)}
                                style={{ marginBottom: "15px" }}
                                className="exportInput"
                            />
                        </div>
                    </>
                )}

                <div className={"exportMarkupsContainer"}>
                    <div className="exportInputDiv">
                        <div className="exportInputLabel">Detection Type</div>
                        <Select
                            optionLabelProp="label"
                            value={exportMarkupTypes}
                            onChange={setExportMarkupTypes}
                            className="exportInput">
                            {markerTypes}
                        </Select>
                    </div>

                    {continuousObservationReport && (
                        <div className="exportInputDiv">
                            <div className="exportInputLabel">
                                Filter Options <span style={{ fontSize: "11px", fontStyle: "italic" }}>(You can select multiple)</span>
                            </div>
                            <div className="exportInput">
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="e.g. scrap, future_works, strategic..."
                                    disabled={observationType !== "Scrap Rail"}
                                    onChange={(e) => {
                                        if (e.length) {
                                            setContinuousReportForm({ ...continuousReportForm, filter_options: e.join(", ") });
                                        } else {
                                            setContinuousReportForm({ ...continuousReportForm, filter_options: null });
                                        }
                                    }}
                                    value={continuousReportForm.filter_options ? continuousReportForm.filter_options.split(", ") : []}>
                                    {[
                                        { label: "Future works", value: "future_works" },
                                        { label: "Scrap", value: "scrap" },
                                        { label: "Strategic", value: "strategic" },
                                    ].map((option) => {
                                        return (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    )}

                    <div className="exportInputDiv">
                        <div className="exportInputLabel">Review status</div>
                        <Select
                            value={reviewValue}
                            onChange={onReviewStatusChange}
                            className="exportInput"
                            mode="multiple">
                            {!continuousObservationReport && (
                                <Select.Option
                                    value={"all"}
                                    key="all">
                                    All
                                </Select.Option>
                            )}
                            <Select.Option
                                value={1}
                                key="verified">
                                Verified
                            </Select.Option>
                            <Select.Option
                                value={2}
                                key="verifiedandhidden">
                                Verified & Hidden
                            </Select.Option>
                            <Select.Option
                                value={0}
                                key="needs_review">
                                Needs Review
                            </Select.Option>
                            <Select.Option
                                value={3}
                                key="rejected">
                                Rejected
                            </Select.Option>
                        </Select>
                    </div>

                    {continuousObservationReport && (
                        <div className="exportInputDiv">
                            <div className="exportInputLabel">
                                Density <span style={{ fontSize: "11px", fontStyle: "italic" }}>(Optionally enter a number between 0 and 5)</span>
                            </div>
                            <InputNumber
                                min={0}
                                max={5}
                                step={0.1}
                                precision={2}
                                className="exportInput"
                                onChange={(e) => setContinuousReportForm({ ...continuousReportForm, score: e })}
                                value={continuousReportForm.score ? continuousReportForm.score : 0}
                            />
                        </div>
                    )}
                </div>

                {continuousObservationReport && (
                    <div className="exportInputDiv">
                        <div className="exportInputLabel">Start / End Time</div>
                        {sliderRange}
                    </div>
                )}

                {!observationType && (
                    <>
                        <h3>
                            Include Detections from other sessions
                            <Checkbox
                                disabled={exportMarkupTypes.includes("_observations")}
                                style={{ marginLeft: 10 }}
                                checked={allSessionsToggle}
                                onChange={(e) => setAllSessionsToggle(e.target.checked)}
                            />
                        </h3>

                        <div className={"exportInputDiv" + (allSessionsToggle ? "" : " Disabled")}>
                            <div className="exportInputLabel">ELR To Export</div>
                            <Select
                                style={{ width: 200 }}
                                disabled={!allSessionsToggle}
                                value={allSessionsELR}
                                onChange={setAllSessionsELR}>
                                {elrOptions}
                            </Select>
                        </div>
                    </>
                )}

                {!allSessionsToggle && !continuousObservationReport && <p style={{ marginBottom: -10, marginTop: 20 }}>{exportCount} markers expected</p>}
            </div>
        </Modal>
    );
};

export default MarkerExportModal;
