import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

export default function PatrolsNotice() {
    return (
        <div className="PatrolNotice">
            <div className="PatrolNotice__title">
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ marginRight: 7.5 }}
                />
                Work in Progress
            </div>
            <div className="PatrolNotice__content">
                This system is in development and under evaluation. Patrol definitions, imagery and system functionality are not final.
                <br />
                Your feedback is appreciated. Please contact us at <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a> with suggestions
                and issues.
            </div>
        </div>
    );
}
