import React from "react";
import "../../../aivr.scss";
import MapComponent from "../../map/MapComponent";
import SessionsComponent from "../../SessionsComponent";
import VideoOrImageComponent from "../../display/VideoOrImageComponent";
import ANPRDrawer from "../../anpr/ANPRDrawer";
import { Drawer, Tabs, Modal, Alert } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import {
    logout,
    swapDashboard,
    refresh,
    targetResource,
    shareLinkDetails,
    dismissMessage,
    routeSelected,
    toggleMarkerInfoShowing,
    toggleDesktop,
    clearShareDetails,
    setCurrentTab,
    setDisplayUserPreferences,
    customAudit,
} from "redux/actions";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Tippy from "@tippyjs/react";
import DashboardLogo from "../../DashboardLogoComponent";
import RouteInfoTabs from "../../RouteInfoTabs";
import RouteInformation from "../../route/RouteInformation";
import RouteContentList from "../../route/RouteContentList";
import FavouritesTab from "components/rootTabs/FavouritesTab";
import RouteHistoryList from "../../display/video/RouteHistoryList";
import RouteTimeOffset from "../../route/RouteTimeOffset";
import MarkerInformation from "../../MarkerInformation";
import ShortcutsTab from "../../ShortcutsTab";
import ReactMarkdown from "react-markdown";
import GlobalContentTab from "../../GlobalContentTab";
import IntroTour from "../../IntroTour";
import moment from "moment";
import ExpiryBanner from "../../ExpiryBanner";
import AppNavbar from "../../AppNavbar";
import IssuesTab from "components/Issues/IssuesTab";
import MediaTab from "components/mainTabs/media/MediaTab";
import AssetsTab from "components/assets/AssetsTab";
import AssetTable from "components/AssetTable";
import OBCButton from "components/OBC/OBCButton";
import QATab from "components/QATab/QATab";
import NewUserModal from "components/NewUserModal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SearchComponent from "../MegaSearch/SearchComponent";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";

const { TabPane } = Tabs;
const gfm = require("remark-gfm");

const TAB_ORDER = [
    "sessions",
    "issues",
    "shortcuts",
    "content_feed",
    "favourites",
    "map",
    "info",
    "content",
    "history",
    "markerInfo",
    "timeOffset",
    "assets",
    "media",
    "qa",
];

class V1Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anprWindowVisible: false,
            dismissedMotd: false,
            currentTab: this.props.previousCurrentTab === "qa" ? "qa" : "sessions",
            dismissedMessage: false,
            manualTrigger: false,
            infoTabName: "info",
            widgetBarVisible: false,
            preferenceModal: false,
        };

        this.appRef = React.createRef();
    }

    componentDidMount = () => {
        if (this.props.requestedIssue.issue) {
            this.updateCurrentTab("issues");
        } else if (this.props.requestedAsset.asset) {
            this.updateCurrentTab("assets");
        } else if (this.props.targetResource) {
            if (this.props.targetResource && ["Annotation", "Measurement", "Sketch", "Bookmark"].includes(this.props.targetResource.type)) {
                this.updateCurrentTab("content_feed");
            }
        } else if (this.props.defaultTab) {
            this.setDefaultTabKey(this.props.defaultTab);
        }

        if (this.props.displayPreferenceModal) {
            this.setState({
                preferenceModal: this.props.displayPreferenceModal,
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.windowDimensions.width !== this.props.windowDimensions.width) {
            if (this.props.windowDimensions.width < 1024 && prevProps.windowDimensions.width > 1024) {
                if (this.props.noPlaylistData) {
                    this.updateCurrentTab("sessions");
                } else {
                    if (!_.isEmpty(this.props.selectedMarker)) {
                        this.updateCurrentTab("markerInfo");
                    } else {
                        this.updateCurrentTab("info");
                    }
                }
            }
        }

        if (this.props.windowDimensions.width < 1024 && _.isEmpty(prevProps.selectedMarker) && !_.isEmpty(this.props.selectedMarker)) {
            this.updateCurrentTab("markerInfo");
        }

        if (this.props.selectedSessionID !== prevProps.selectedSessionID && this.state.currentTab !== "assets") {
            this.updateCurrentTab("sessions");
        }

        if (prevProps.currentDashboard && this.props.currentDashboard && this.props.currentDashboard.access_id !== prevProps.currentDashboard.access_id) {
            this.updateCurrentTab("sessions");
            this.setDefaultTabKey(this.props.defaultTab);
        }

        if (!prevProps.showingMarkerInfo && this.props.showingMarkerInfo) {
            if (this.props.windowDimensions.width < 1024 && prevProps.windowDimensions.width > 1024) {
                if (this.state.currentTab !== "markerInfo") {
                    this.updateCurrentTab("markerInfo");
                }
            }
        }

        if (!prevProps.targetResource && this.props.targetResource) {
            if (this.props.targetResource && ["Annotation", "Measurement", "Sketch", "Bookmark"].includes(this.props.targetResource.type)) {
                this.updateCurrentTab("content_feed");
            }
            if (this.props.targetResource && this.props.targetResource.access_granted === false) {
                this.renderAccessDeniedModal();
            }
        }

        // wait for userDetails to be populated before proceeding with below
        if (this.props.targetResource && this.props.shareLinkDetails && this.props.shareLinkDetails.sessionID && this.props.userDetails?.dashboardAccessID) {
            if (this.props.shareLinkDetails.inspection) {
                this.props.history.push(`/rail-inspection/${this.props.shareLinkDetails.sessionID}/${this.props.shareLinkDetails.timestamp}`);
            } else {
                this.props.dispatch(routeSelected(this.props.shareLinkDetails.sessionID, this.props.shareLinkDetails.timestamp));
                // audit log
                const email = _.get(this.props.userDetails, "email", "unknown email");
                const usedUrl = window.location.protocol + "//" + window.location.host + "/view/" + this.props.shareLinkDetails.sessionID;
                const auditText = `view_link_used by user ${email}: ${usedUrl}`;
                this.props.dispatch(customAudit("view_link_used", { sessionID: this.props.shareLinkDetails.sessionID }, auditText));
            }
            this.props.dispatch(targetResource(null));
        }

        if (this.props.requestedIssue.issue && !prevProps.requestedIssue.issue) {
            this.updateCurrentTab("issues");
        }

        if (this.props.requestedAsset.asset && this.props.requestedAsset.asset !== prevProps.requestedAsset.asset) {
            this.updateCurrentTab("assets");
        }

        if (this.props.selectedAsset !== prevProps.selectedAsset && this.props.displayingNearByAssets) {
            this.updateCurrentTab("assets");
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.workspaceMemberPoll);
        clearInterval(this.websocketStatusPoll);
    };

    dismissTargetResource = () => {
        this.props.history.replace("", null);
        this.props.dispatch(targetResource(null));
    };

    showANPRWindow = () => {
        this.setState({
            anprWindowVisible: true,
        });
    };

    closeANPRWindow = () => {
        this.setState({
            anprWindowVisible: false,
        });
    };

    logout = () => {
        this.props.dispatch(logout());

        if (this.props.shareLinkDetails) {
            this.props.history.push("/login");
            this.props.dispatch(clearShareDetails());
        }
    };

    organisationDetails = () => {
        let logo = null;
        if (this.props.userDetails.branding) {
            logo = (
                <div
                    className={"OrgLogo"}
                    style={{
                        backgroundImage: `url(https://branding${MEMOIZED_DOMAIN_URL}/` + this.props.userDetails.branding + "/view_watermark1.png)",
                    }}
                />
            );
        }

        return (
            <div className={"DetailsLine"}>
                <div className={"DashboardDetailsBlock"}>
                    <div className={"DetailsText Description"}>{this.props.userDetails.description}</div>
                    <div className={"DetailsLine"}>
                        <FontAwesomeIcon icon={faBuilding} />
                        <div className={"DetailsText Organisation"}>{this.props.userDetails.organisation}</div>
                    </div>
                </div>
                {logo}
            </div>
        );
    };

    swapDashboard = (dashboard) => {
        // on *manual* navigation away from current dashboard, effectively
        // dismiss any previously activated share link so it is not
        // re-activated if we swap back
        this.props.dispatch(
            shareLinkDetails({
                session_id: null,
                timestamp: 0,
                enhanced: false,
            }),
        );
        this.props.dispatch(swapDashboard(dashboard.access_token));
        this.props.dispatch(refresh());
    };

    dismissMotd = () => {
        this.setState({
            dismissedMotd: true,
        });
    };

    dismissMessage = (message_id) => {
        this.props.dispatch(dismissMessage(message_id));
        this.setState({
            dismissedMessage: true,
        });
    };

    updateCurrentTab = (tab) => {
        this.props.dispatch(setCurrentTab(tab));
        this.setState({ currentTab: tab });
        this.props.dispatch(toggleMarkerInfoShowing(tab === "markerInfo"));

        if (tab === "issues" || tab === "assets") {
            this.props.dispatch(customAudit(`${tab}_tab_click`, { target: `${tab} Tab`, source: "mouse" }, `${tab}_tab_click event`));
        }
    };

    startTour = () => {
        this.setManualTrigger(true);
    };

    userCount = () => {
        if (this.props.workspaceUsers.length < 100) {
            return this.props.workspaceUsers.length;
        } else if (this.props.workspaceUsers.length > 99) {
            return "99+";
        }
    };

    goToMobileSite = () => {
        this.props.dispatch(toggleDesktop(false));
        this.props.history.push("/mobile");
    };

    widgetCount = () => {
        if (this.props.widgetData.WIDGET) {
            let result = Object.values(this.props.widgetData.WIDGET).filter((x) => x.last_updated > moment.now() - 7000);
            return result.length;
        }
        return 0;
    };

    openWidgetBar = (e) => {
        this.setState({
            widgetBarVisible: true,
        });
    };

    hideWidgetBar = () => {
        this.setState({
            widgetBarVisible: false,
        });
    };

    navToPatrols = () => {
        this.props.history.push("/patrols");
    };

    setManualTrigger = (value) => {
        this.setState({
            manualTrigger: value,
        });
    };

    tabPanes = () => {
        const isSuperUser = this.props.userDetails.userConfig.super_admin;
        let tabs = [
            <TabPane
                tab="Sessions"
                key="sessions"
                className="route-tabs-pane">
                <SessionsComponent />
            </TabPane>,
            <TabPane
                tab="Favourites"
                key="favourites"
                className="route-tabs-pane">
                <FavouritesTab />
            </TabPane>,
        ];

        if (_.get(this.props.currentDashboard, ["config", "issue_groups", "length"], 0)) {
            tabs.push(
                <TabPane
                    tab="Issues"
                    key="issues"
                    className="route-tabs-pane">
                    <IssuesTab />
                </TabPane>,
            );
        }

        if (_.get(this.props.currentDashboard, ["config", "shortcuts_enabled"], false) || isSuperUser) {
            tabs.push(
                <TabPane
                    tab="Shortcuts"
                    key="shortcuts"
                    className="route-tabs-pane">
                    <ShortcutsTab />
                </TabPane>,
            );
        }

        if (_.get(this.props.currentDashboard, ["config", "markup_feed_enabled"], false)) {
            tabs.push(
                <TabPane
                    tab="Content Feed"
                    key="content_feed"
                    className="route-tabs-pane">
                    <GlobalContentTab />
                </TabPane>,
            );
        }

        if (this.props.windowDimensions.width < 1024) {
            tabs.push(
                <TabPane
                    tab="Map"
                    key="map"
                    className="route-tabs-pane">
                    <div className="map-container-mobile">
                        <MapComponent />
                    </div>
                </TabPane>,
                <TabPane
                    tab="Info"
                    key="info"
                    className="route-tabs-pane"
                    disabled={this.props.noPlaylistData || this.props.shareLink}>
                    <RouteInformation />
                </TabPane>,
                <TabPane
                    tab="Content"
                    key="content"
                    className="route-tabs-pane"
                    disabled={this.props.noPlaylistData || this.props.shareLink}>
                    <RouteContentList />
                </TabPane>,
                <TabPane
                    tab="History"
                    key="history"
                    className="route-tabs-pane scroll"
                    disabled={this.props.noPlaylistData || this.props.shareLink}>
                    <RouteHistoryList visible={this.state.currentTab === "history"} />
                </TabPane>,
                <TabPane
                    tab="Detection Info"
                    key="markerInfo"
                    className="route-tabs-pane DetectionInfoPane"
                    disabled={_.isEmpty(this.props.selectedMarker)}>
                    {!_.isNil(this.props.selectedMarker.review_status) && <MarkerInformation />}
                    {(this.props.selectedMarker.data || this.props.selectedMarker.custom_data) && <AssetTable />}
                </TabPane>,
            );

            if (isSuperUser) {
                tabs.push(
                    <TabPane
                        tab="GPS Adjustment"
                        key="timeOffset"
                        className="route-tabs-pane"
                        disabled={this.props.noPlaylistData || this.props.shareLink}>
                        <RouteTimeOffset />
                    </TabPane>,
                );
            }
        }

        if (_.get(this.props.currentDashboard, ["config", "asset_groups", "length"], 0)) {
            tabs.push(
                <TabPane
                    tab="Assets"
                    key="assets"
                    className="route-tabs-pane">
                    <AssetsTab />
                </TabPane>,
            );
        }

        if (this.props.media_upload_enabled) {
            tabs.push(
                <TabPane
                    tab="Media"
                    key="media"
                    className="route-tabs-pane">
                    <MediaTab />
                </TabPane>,
            );
        }

        if (isSuperUser) {
            tabs.push(
                <TabPane
                    tab="QA"
                    key="qa"
                    className="route-tabs-pane">
                    <QATab />
                </TabPane>,
            );
        }

        return tabs.sort((a, b) => {
            return TAB_ORDER.indexOf(a.key) - TAB_ORDER.indexOf(b.key);
        });
    };

    setDefaultTabKey = (tabKey) => {
        const isValidTabKey = _.find(this.tabPanes(), (tab) => tab.key === tabKey) ? true : false;
        if (isValidTabKey) {
            if (this.props.previousCurrentTab !== "qa") {
                this.setState({
                    currentTab: this.props.defaultTab,
                });
            }
        }
    };

    renderAccessDeniedModal = () => {
        Modal.confirm({
            title: "Access Denied",
            content: "Your user account does not have access to the shared content.",
            okText: "Continue Anyway",
            cancelText: "Logout",
            icon: <ExclamationCircleOutlined style={{ color: "orange" }} />,
            onCancel: this.logout,
            onOk: this.dismissTargetResource,
        });
    };

    closeNewUserModal = () => {
        this.props.dispatch(setDisplayUserPreferences());
        this.setState({
            preferenceModal: false,
        });
    };

    render() {
        return (
            <div
                ref={this.props.measureRef}
                className="App">
                {this.state.preferenceModal && <NewUserModal onClose={this.closeNewUserModal} />}
                <IntroTour
                    manualTrigger={this.state.manualTrigger}
                    setManualTrigger={this.setManualTrigger}
                />
                {(this.props.userDetails.userConfig.super_admin || this.props.currentDashboard?.isBeta) && <SearchComponent />}
                <AppNavbar
                    startTour={this.startTour}
                    logout={this.logout}
                    switchDashboard={this.switchDashboard}
                />

                <div
                    className="MainContent"
                    ref={this.appRef}>
                    <div className="messageBannerContainer">
                        {this.props.currentDashboard && this.props.currentDashboard.config.maintenance && !this.state.dismissedMotd && (
                            <div className="messageBanner alert">
                                <h5 className="messageBannerText title alert">Info</h5>
                                <ReactMarkdown className="messageBannerText alert">{this.props.currentDashboard.config.maintenance}</ReactMarkdown>
                                <div className="messageBannerButtonContainer">
                                    <button
                                        className="messageBannerButton dismiss alert"
                                        onClick={this.dismissMotd}>
                                        Dismiss
                                    </button>
                                </div>
                            </div>
                        )}

                        {this.props.currentDashboard && this.props.currentDashboard.message && !this.state.dismissedMessage && (
                            <div className="messageBanner">
                                <h5 className="messageBannerText title">
                                    {this.props.currentDashboard.message.title ? this.props.currentDashboard.message.title : "Message"}
                                </h5>
                                <ReactMarkdown
                                    remarkPlugins={[gfm]}
                                    className="messageBannerText">
                                    {this.props.currentDashboard.message.content}
                                </ReactMarkdown>
                                <div className="messageBannerButtonContainer">
                                    <button
                                        className="messageBannerButton dismiss"
                                        onClick={() => {
                                            this.dismissMessage(this.props.currentDashboard.message.id);
                                        }}>
                                        Dismiss
                                    </button>
                                </div>
                            </div>
                        )}
                        <ExpiryBanner />
                    </div>

                    <div className="MainContent__Inner">
                        <div className="Left-Container">
                            {this.props.layoutType === "driver_training" && this.props.adminPermission && (
                                <Alert
                                    className="DriverTrainingManagerBanner"
                                    message={
                                        <div className="DriverTrainingManagerBannerInner">
                                            <div className="TitleAndSubTitle">
                                                <div className="Title">
                                                    <span>You are now usinig Driver Training Workspace!</span>
                                                </div>
                                                <div className="SubTitle">
                                                    <span>You should only use this workspace to add/remove training sessions from favourite categories.</span>
                                                </div>
                                            </div>
                                            <Link to="/admin">
                                                <OBCButton variant="ghost">Manage Drivers</OBCButton>
                                            </Link>
                                        </div>
                                    }
                                    type="warning"
                                    closable
                                />
                            )}
                            <div className="Container Media">
                                <VideoOrImageComponent />
                            </div>
                            <div className="Container Sessions">
                                <div className="route-tabs-container hideOverflow">
                                    <Tabs
                                        animated={{ inkBar: false, tabPane: true }}
                                        activeKey={this.state.currentTab}
                                        onChange={this.updateCurrentTab}
                                        tabBarStyle={{ color: "white" }}
                                        destroyInactiveTabPane={true}>
                                        {this.tabPanes()}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {this.props.windowDimensions.width > 1024 && (
                            <div className={"Right-Container Container"}>
                                <MapComponent />
                                <div
                                    className="route-tabs-container"
                                    id="intro-tour-info-window">
                                    <RouteInfoTabs />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Drawer
                    onClose={this.closeANPRWindow}
                    visible={this.state.anprWindowVisible}
                    placement="left"
                    width={600}
                    title="ANPR Search">
                    <ANPRDrawer visible={true} />
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);

    return {
        access_token: state.access_token,
        canSwitchDashboard: state.dashboards && state.dashboards.length > 1,
        userDetails: state.userDetails,
        shareLink: state.shareLink,
        shareLinkDetails: state.shareLinkDetails,
        loading: state.mapGeometry === null && (!state.sessionList || _.isEmpty(state.sessionList)),
        adminPermission: !!state.permissions.admin,
        dashboards: state.dashboards,
        targetResource: state.targetResource,
        logout: state.logout,
        currentDashboard,
        noPlaylistData: _.isEmpty(state.playlist.data),
        windowDimensions: state.windowDimensions,
        workspaceUsers: state.team.users,
        sessions: state.sessions,
        selectedMarker: state.markers.selectedMarker,
        showingMarkerInfo: state.markers.showingMarkerInfo,
        requestedDesktop: state.requestedDesktop,
        widgetData: state.widgetData,
        ws_token: state.userDetails.userConfig.ws_token,
        sidekick_types: _.get(currentDashboard, ["config", "sidekick_widget_types"], []),
        media_upload_enabled: _.get(currentDashboard, ["config", "media_upload_enabled"], false),
        layoutType: currentDashboard?.workspace_layout,
        defaultTab: _.get(currentDashboard, ["config", "default_tab"], "sessions"),
        previousCurrentTab: state.currentTab,
        requestedIssue: state.issues.requested,
        requestedAsset: state.mlAssets.requested,
        displayPreferenceModal: state.userDetails.userConfig.display_user_preferences,
        selectedSessionID: state.playlist.data.routeID,
        selectedAsset: state.mlAssets.selected.asset,
        displayingNearByAssets: state.assets.displayingNearbyAssets,
    };
};

export default connect(mapStateToProps)(withRouter(V1Layout));
