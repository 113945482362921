import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import { findAudit } from "./auditActions";
import { goToBounds, sessionObservations } from "./index";
import _ from "lodash";
import { notification } from "antd";
import AssetIDHelper from "components/util/AssetIDHelper";

export const ADMIN_MARKER_LIST = "ADMIN_MARKER_LIST";
export const SELECTED_TAG_CATEGORY = "SELECTED_TAG_CATEGORY";
export const ALWAYS_SHOWN_MARKERS = "ALWAYS_SHOWN_MARKERS";
export const SESSION_MARKERS = "SESSION_MARKERS";
export const TOGGLE_ICON_CONFIGURATION = "TOGGLE_ICON_CONFIGURATION";
export const GET_MARKER_STATS = "GET_MARKER_STATS";
export const MARKER_DATA = "MARKER_DATA";
export const MARKER_DATA_LOADING = "MARKER_DATA_LOADING";
export const RECEIVE_ASSETS = "RECEIVE_ASSETS";
export const TOGGLE_ASSET_DISPLAY = "TOGGLE_ASSET_DISPLAY";
export const CLEAR_ASSETS = "CLEAR_ASSETS";
export const MARKER_INFO = "MARKER_INFO";
export const CLEAR_MARKER_INFO = "CLEAR_MARKER_INFO";
export const SEARCH_ASSETS = "SEARCH_ASSETS";
export const RECEIVE_STATIONS = "RECEIVE_STATIONS";
export const TOGGLE_STATION_DISPLAY = "TOGGLE_STATION_DISPLAY";
export const CLEAR_STATIONS = "CLEAR_STATIONS";
export const SET_ASSET_SEARCH_TYPE = "SET_ASSET_SEARCH_TYPE";
export const ADD_MARKER_REVIEW_FILTER = "ADD_MARKER_REVIEW_FILTER";
export const REMOVE_MARKER_REVIEW_FILTER = "REMOVE_MARKER_REVIEW_FILTER";
export const ADD_MARKER_THRESHOLD_FILTER = "ADD_MARKER_THRESHOLD_FILTER";
export const EXCEEDENCE_DATA = "EXCEEDENCE_DATA";
export const TOGGLE_DETECTIONS_OPEN = "TOGGLE_DETECTIONS_OPEN";
export const REVIEW_DETECTION = "REVIEW_DETECTION";
export const ADD_DETECTIONS_REVIEW_FILTER = "ADD_DETECTIONS_FILTER";
export const REMOVE_DETECTIONS_REVIEW_FILTER = "REMOVE_DETECTIONS_FILTER";
export const ADD_DETECTIONS_TYPE_FILTER = "ADD_DETECTIONS_TYPE_FILTER";
export const REMOVE_DETECTIONS_TYPE_FILTER = "REMOVE_DETECTIONS_TYPE_FILTER";
export const TOGGLE_DETECTION_VISIBILITY = "TOGGLE_DETECTION_VISIBILITY";
export const SAVE_MARKER_DEFAULTS = "SAVE_MARKER_DEFAULTS";
export const ASSETS = "ASSETS";
export const ADMIN_MARKER_CONFIGURATION = "ADMIN_MARKER_CONFIGURATION";
export const RECEIVE_HISTORY_MARKERS = "RECEIVE_HISTORY_MARKERS";
export const LOADING_MARKER_HISTORY = "LOADING_MARKER_HISTORY";
export const STORE_CONDITION_TYPES = "STORE_CONDITION_TYPES";
export const UPDATE_SESSION_MARKERS_BY_SESSION_ID = "UPDATE_SESSION_MARKERS_BY_SESSION_ID";
export const SET_MARKER_CONDITION_FILTER = "SET_MARKER_CONDITION_FILTER";
export const ADD_MARKER_CONDITION_FILTER = "ADD_MARKER_CONDITION_FILTER";
export const REMOVE_MARKER_CONDITION_FILTER = "REMOVE_MARKER_CONDITION_FILTER";
export const RECEIVE_ISSUES = "RECEIVE_ISSUES";
export const SELECT_ISSUE = "SELECT_ISSUE";
export const RECEIVE_ASSET_OBSERVATIONS = "RECEIVE_ASSET_OBSERVATIONS";
export const SELECT_OBSERVATION = "SELECT_OBSERVATION";
export const SET_ASSET_DATE_FILTER = "SET_ASSET_DATE_FILTER";
export const SET_ASSET_STATUS_FILTER = "SET_ASSET_STATUS_FILTER";
export const SET_ASSET_PRIORITY_FILTER = "SET_ASSET_PRIORITY_FILTER";
export const SET_ASSET_CLASS_FILTER = "SET_ASSET_CLASS_FILTER";
export const REQUEST_ISSUE = "REQUEST_ISSUE";
export const CLEAR_REQUESTED_ISSUE = "CLEAR_REQUESTED_ISSUE";
export const SET_ASSET_SEARCH_QUERY_FILTER = "SET_ASSET_SEARCH_QUERY_FILTER";
export const RECEIVE_ISSUE_OBSERVATIONS = "RECEIVE_ISSUE_OBSERVATIONS";
export const SET_ISSUE_CLASS_FILTER = "SET_ISSUE_CLASS_FILTER";
export const SET_ISSUE_DATE_FILTER = "SET_ISSUE_DATE_FILTER";
export const SET_ISSUE_PRIORITY_FILTER = "SET_ISSUE_PRIORITY_FILTER";
export const SET_ISSUE_SEARCH_QUERY_FILTER = "SET_ISSUE_SEARCH_QUERY_FILTER";
export const SET_ISSUE_STATUS_FILTER = "SET_ISSUE_STATUS_FILTER";
export const CLEAR_REQUESTED_ASSET = "CLEAR_REQUESTED_ASSET";
export const RECEIVE_ML_ASSETS = "RECEIVE_ML_ASSETS";
export const SELECT_ASSET = "SELECT_ASSET";
export const SELECT_ASSET_OBSERVATION = "SELECT_ASSET_OBSERVATION";
export const SET_GROUND_TRUTH_FILTER = "SET_GROUND_TRUTH_FILTER";
export const SET_ISSUE_CLASS_SEARCH_VALUE = "SET_ISSUE_CLASS_SEARCH_VALUE";
export const RECEIVE_ISSUE_GROUPS = "RECEIVE_ISSUE_GROUPS";
export const RECEIVE_ML_ASSET_GROUPS = "RECEIVE_ML_ASSET_GROUPS";
export const REQUEST_ASSET = "REQUEST_ASSET";
export const SET_ISSUE_GROUP_ID = "SET_ISSUE_GROUP_ID";
export const SET_ASSET_GROUP_ID = "SET_ASSET_GROUP_ID";
export const RECEIVE_ASSET_GROUPS = "RECEIVE_ASSET_GROUPS";
export const RECEIVE_ASSET_IDS = "RECEIVE_ASSET_IDS";
export const RECEIVE_ASSET_DATA = "RECEIVE_ASSET_DATA";
export const RECEIVE_ASSET_LOCATIONS = "RECEIVE_ASSET_LOCATIONS";
export const SET_FILTERED_ISSUES = "SET_FILTERED_ISSUES";
export const CCTV_MARKERS = "CCTV_MARKERS";
export const CCTV_IMAGES = "CCTV_IMAGES";
export const CCTV_MAP_REF = "CCTV_MAP_REF";
export const SET_TROUGHING_FILTER = "SET_TROUGHING_FILTER";
export const SET_LOW_BALLAST_CONFIDENCE_FILTER = "SET_LOW_BALLAST_CONFIDENCE_FILTER";
export const RECEIVE_DETECTION_TYPES = "RECEIVE_DETECTION_TYPES";
export const OBJECT_OBSERVATION_DATA_TYPE = "OBJECT_OBSERVATION_DATA_TYPE";

export const clearRequestedIssue = () => {
    return {
        type: CLEAR_REQUESTED_ISSUE,
    };
};

export const requestIssue = (issueID, observationID) => {
    return {
        type: REQUEST_ISSUE,
        issueID,
        observationID,
    };
};

export const requestAsset = (assetID, observationID) => {
    return {
        type: REQUEST_ASSET,
        assetID,
        observationID,
    };
};

export const receiveDetectionTypes = (types) => {
    return {
        type: RECEIVE_DETECTION_TYPES,
        types,
    };
};

export const setAssetGroupFilter = (assetGroupID) => {
    return {
        type: SET_ASSET_GROUP_ID,
        assetGroupID,
    };
};

export const setIssueGroupFilter = (issueGroupID) => {
    return {
        type: SET_ISSUE_GROUP_ID,
        issueGroupID,
    };
};

export const setGroundTruthFilter = (filter) => {
    return {
        type: SET_GROUND_TRUTH_FILTER,
        filter,
    };
};

export const setAssetPriorityFilter = (priority) => {
    return {
        type: SET_ASSET_PRIORITY_FILTER,
        priority,
    };
};

export const setIssuePriorityFilter = (priority) => {
    return {
        type: SET_ISSUE_PRIORITY_FILTER,
        priority,
    };
};

export const setAssetClassFilter = (asset_class) => {
    return {
        type: SET_ASSET_CLASS_FILTER,
        asset_class,
    };
};

export const setIssueSearchQueryFilter = (searchQuery) => {
    return {
        type: SET_ISSUE_SEARCH_QUERY_FILTER,
        searchQuery,
    };
};

export const setAssetSearchQueryFilter = (searchQuery) => {
    return {
        type: SET_ASSET_SEARCH_QUERY_FILTER,
        searchQuery,
    };
};

export const setAssetStatusFilter = (status) => {
    return {
        type: SET_ASSET_STATUS_FILTER,
        status,
    };
};

export const setIssueStatusFilter = (status) => {
    return {
        type: SET_ISSUE_STATUS_FILTER,
        status,
    };
};

export const setAssetDateFilter = (dates) => {
    return {
        type: SET_ASSET_DATE_FILTER,
        dates,
    };
};

export const setIssueDateFilter = (dates) => {
    return {
        type: SET_ISSUE_DATE_FILTER,
        dates,
    };
};

export const receiveAssetObservations = (observations) => {
    return {
        type: RECEIVE_ASSET_OBSERVATIONS,
        observations,
    };
};

export const receiveIssueObservations = (observations) => {
    return {
        type: RECEIVE_ISSUE_OBSERVATIONS,
        observations,
    };
};

export const selectObservation = (observationID) => {
    console.log("debug1 selectObservation", observationID);
    return {
        type: SELECT_OBSERVATION,
        observationID,
    };
};

export const setObjectObservationDataTypes = (types) => {
    return {
        type: OBJECT_OBSERVATION_DATA_TYPE,
        types,
    };
};

export const selectIssue = (issueID) => {
    return (dispatch) => {
        // dispatch(selectIssue(null));
        dispatch(selectAsset(null));
        dispatch(
            receiveAssetObservations({
                external_observations: [],
                observations: [],
            }),
        );
        dispatch(receiveIssueObservations([]));

        dispatch({
            type: SELECT_ISSUE,
            issueID,
        });
        if (issueID) {
            dispatch(getIssueObservations(issueID));
        }
    };
};

export const selectAsset = (assetID, showFailedMatches = false) => {
    return (dispatch, getState) => {
        dispatch(
            receiveAssetObservations({
                external_observations: [],
                observations: [],
            }),
        );
        dispatch(receiveIssueObservations([]));

        dispatch({
            type: SELECT_ASSET,
            assetID,
        });
        if (assetID) {
            return new Promise((resolve, reject) => {
                let postBody = {
                    action: "get_asset_observations",
                    asset_id: assetID,
                    show_failed_matches: showFailedMatches,
                };
                const url = "/mapGeometry";

                jsonPostV2(url, getState(), postBody, dispatch)
                    .then((response) => {
                        if (response) {
                            dispatch(receiveAssetObservations(response));
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        handleJsonPostError("Unable to fetch asset observations", "An error occurred while fetching asset observations", error);
                        reject();
                    });
            });
        }
    };
};

export const addMarkerReviewFilter = (reviewType) => {
    return {
        type: ADD_MARKER_REVIEW_FILTER,
        reviewType,
    };
};

export const toggleDetectionsOpen = () => {
    return {
        type: TOGGLE_DETECTIONS_OPEN,
    };
};

export const removeMarkerReviewFilter = (reviewType) => {
    return {
        type: REMOVE_MARKER_REVIEW_FILTER,
        reviewType,
    };
};

const receiveStations = (stations) => {
    return {
        type: RECEIVE_STATIONS,
        stations,
    };
};

export const setAssetSearchType = (searchType) => {
    return {
        type: SET_ASSET_SEARCH_TYPE,
        searchType,
    };
};

export function selectMarker(marker, isObservation = false) {
    return {
        type: MARKER_INFO,
        marker,
        isObservation,
    };
}

export function clearMarkerInfo() {
    return {
        type: CLEAR_MARKER_INFO,
    };
}

function receiveNearbyAssets(assets) {
    return {
        type: RECEIVE_ASSETS,
        assets,
    };
}

function clearAssets() {
    return {
        type: CLEAR_ASSETS,
    };
}

export function markerStats(markerData) {
    return {
        type: MARKER_DATA,
        markerData,
    };
}

export const addDetectionsReviewFilter = (reviewType) => {
    return {
        type: ADD_DETECTIONS_REVIEW_FILTER,
        reviewType,
    };
};

export const removeDetectionsReviewFilter = (reviewType) => {
    return {
        type: REMOVE_DETECTIONS_REVIEW_FILTER,
        reviewType,
    };
};

export const addDetectionsTypeFilter = (detectionType, replaceExisting = true) => {
    return {
        type: ADD_DETECTIONS_TYPE_FILTER,
        detectionType,
        replaceExisting,
    };
};

export const removeDetectionsTypeFilter = (detectionType) => {
    return {
        type: REMOVE_DETECTIONS_TYPE_FILTER,
        detectionType,
    };
};

export const setDetectionVisibility = () => {
    return {
        type: TOGGLE_DETECTION_VISIBILITY,
    };
};

export function updateThresholdFilter(key, value) {
    return {
        type: ADD_MARKER_THRESHOLD_FILTER,
        key,
        value,
    };
}

export function saveMarkerDefaults(scoreObject, sessionID) {
    return {
        type: SAVE_MARKER_DEFAULTS,
        sessionID,
        scoreObject,
    };
}

export function selectTag(category) {
    return {
        type: SELECTED_TAG_CATEGORY,
        category,
    };
}

export function annotationsList(annotations) {
    return {
        type: ADMIN_MARKER_LIST,
        annotations,
    };
}

export function toggleIconConfiguration(toggleValue, annotationID = 0) {
    return {
        toggleValue,
        annotationID,
        type: TOGGLE_ICON_CONFIGURATION,
    };
}

export function setMarkerConditionFilter(conditions) {
    return {
        type: SET_MARKER_CONDITION_FILTER,
        conditions,
    };
}

export function addMarkerConditionFilter(conditionType, conditionID) {
    return {
        type: ADD_MARKER_CONDITION_FILTER,
        conditionType,
        conditionID,
    };
}

export function removeMarkerConditionFilter(conditionType, conditionID) {
    return {
        type: REMOVE_MARKER_CONDITION_FILTER,
        conditionType,
        conditionID,
    };
}

export function setTroughingFilter(condition, troughingType) {
    return {
        type: SET_TROUGHING_FILTER,
        condition,
        troughingType,
    };
}

export function setLowBallastConfidenceFilter(lowBallastConfidenceFilter) {
    return {
        type: SET_LOW_BALLAST_CONFIDENCE_FILTER,
        lowBallastConfidenceFilter,
    };
}

function alwaysShownMarkers(markers) {
    return {
        type: ALWAYS_SHOWN_MARKERS,
        markers,
    };
}

export function sessionMarkers(sessionID, markers) {
    return {
        type: SESSION_MARKERS,
        sessionID,
        markers,
    };
}

function conditionTypes(types) {
    return {
        type: STORE_CONDITION_TYPES,
        conditionTypes: types,
    };
}

function updateMarkerConditions(sessionID, newData) {
    return {
        type: UPDATE_SESSION_MARKERS_BY_SESSION_ID,
        sessionID,
        newData,
    };
}

export const setIssueClassFilter = (issueClass) => {
    return {
        type: SET_ISSUE_CLASS_FILTER,
        issueClass,
    };
};

export const setClassSearchValue = (issueClassSearchValue) => {
    return {
        type: SET_ISSUE_CLASS_SEARCH_VALUE,
        issueClassSearchValue,
    };
};

function CCTVMarkers(markerLocations) {
    return {
        type: CCTV_MARKERS,
        cctvMarkers: markerLocations,
    };
}

function CCTVImages(images) {
    return {
        type: CCTV_IMAGES,
        cctvImages: images,
    };
}

export function receiveAssetData(assets) {
    return {
        type: RECEIVE_ASSET_DATA,
        assets,
    };
}

export function getCCTVImagesByCameraID(callback, cameraID) {
    return {
        queue: SESSION_MARKERS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_cctv_camera_images",
                camera_id: cameraID,
            };

            let url = "/routeMetadata";
            console.log("getting CCTV images for camera ID " + cameraID);

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response) {
                        dispatch(CCTVImages(response, cameraID));
                        callback(false);
                    }
                    next();
                })
                .catch((error) => {
                    callback(false);
                    handleJsonPostError("Error getting CCTV images", "An error occurred while fetching CCTV images", error);
                    next();
                });
        },
    };
}

export function getCCTVMarkers(callback = false) {
    return {
        queue: SESSION_MARKERS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_cctv_cameras",
            };

            let url = "/routeMetadata";
            console.log("getting CCTV markers");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        dispatch(CCTVMarkers(response));
                        if (callback) {
                            callback(false);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting CCTV markers", "An error occurred while fetching CCTV markers", error);
                    next();
                });
        },
    };
}

export function calculateThreshold(markers, userAnnotationTypes) {
    const score_object = {};
    const groupedMarkers = _.groupBy(markers, "name");
    Object.keys(groupedMarkers).forEach((filterType) => {
        let autoAnnotationType = _.find(userAnnotationTypes, { type: filterType });
        if (autoAnnotationType) {
            if (autoAnnotationType.auto_threshold) {
                const _newArray = groupedMarkers[filterType];
                const sortedArray = _.sortBy(_newArray, ["score"]).slice(_newArray.length - _newArray.length * autoAnnotationType.auto_threshold);
                const firstElement = _.get(sortedArray, [0], null);

                if (firstElement.score > autoAnnotationType.max_auto_threshold && autoAnnotationType.max_auto_threshold !== 0) {
                    score_object[filterType] = autoAnnotationType.max_auto_threshold;
                } else if (firstElement.score < autoAnnotationType.min_auto_threshold && autoAnnotationType.min_auto_threshold !== 0) {
                    score_object[filterType] = autoAnnotationType.min_auto_threshold;
                } else {
                    score_object[filterType] = firstElement.score;
                }
            } else {
                score_object[filterType] = autoAnnotationType.default_score;
            }
        }
    });
    return score_object;
}

export function getMarkersForSession(sessionID) {
    return {
        queue: SESSION_MARKERS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get",
            };
            if (sessionID !== undefined) {
                postBody["session_id"] = sessionID;
            }
            let url = "/markers";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    let markers = [];
                    if (response.markers) {
                        markers = response.markers.map((marker) => {
                            let marker_type_id = marker.type;
                            let marker_type = response.types[marker_type_id];
                            return {
                                source: marker_type[0],
                                name: marker_type[1],
                                id: marker.id,
                                session_id: marker.session_id || sessionID,
                                video_key: marker.video_key,
                                coords: [marker.coords[1], marker.coords[0]],
                                snappedCoords: [marker.snapped_coords[1], marker.snapped_coords[0]],
                                custom_data: marker.custom_data,
                                custom_thumbnail: marker.custom_thumbnail,
                                frame: marker.frame,
                                review_status: marker.review_status,
                                review_auto_selected: marker.review_auto_selected,
                                score: marker.score,
                                condition_id: marker.condition_id,
                                condition_auto_selected: marker.condition_auto_selected,
                                start_ts: marker.start_ts,
                                end_ts: marker.end_ts,
                            };
                        });

                        dispatch(saveMarkerDefaults(calculateThreshold(markers, getState().detectionTypes), sessionID));
                    }
                    if (sessionID !== undefined && sessionID !== null) {
                        dispatch(sessionMarkers(sessionID, markers));
                    } else {
                        dispatch(alwaysShownMarkers(markers));
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError(
                        "Unable to retrieve marker data",
                        "An error occurred while fetching route markers" + (sessionID ? " for session " + sessionID : ""),
                        error,
                    );
                    next();
                });
        },
    };
}

export function getMarkerConfigurations(callback, quiet = false) {
    console.log("Getting marker configurations");

    return {
        queue: ADMIN_MARKER_CONFIGURATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_annotations",
                access_secret: accessSecret,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ annotations }) => {
                    console.log("Got annotations", annotations);
                    if (_.isArray(annotations)) {
                        let annotationsMap = _.keyBy(annotations, (annotation) => {
                            return annotation.id;
                        });
                        dispatch(annotationsList(annotationsMap));
                        if (callback) {
                            callback(true);
                        }
                    } else {
                        callback(false);
                    }
                    next();
                })
                .catch((error) => {
                    if (!quiet) {
                        handleJsonPostError("Unable to read marker configuration", "An error occurred while fetching the marker configuration", error);
                    }
                    callback(false);

                    next();
                });
        },
    };
}

export function editMarkerDisplayType(markerID, displayType, callback) {
    console.log("Sending edit marker type");

    return {
        queue: ADMIN_MARKER_CONFIGURATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                access_secret: accessSecret,
                markerID,
                displayType,
                action: "edit_annotation_type",
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Marker type response", response);
                        dispatch(getMarkerConfigurations());
                    }
                    callback(response);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to obtain review data", "An error occurred while fetching the data for review", error);
                    next();
                });
        },
    };
}

export function editMarkerImportance(markerID, importance, callback) {
    console.log("Sending edit marker importance");

    return {
        queue: ADMIN_MARKER_CONFIGURATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                access_secret: accessSecret,
                markerID,
                importance,
                action: "edit_annotation_details",
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Marker type response", response);
                        dispatch(getMarkerConfigurations());
                        callback(response);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to obtain marker data", "An error occurred while fetching marker data", error);
                    next();
                });
        },
    };
}

export function editMarkerTagColour(markerID, tagColour, callback = () => {}) {
    console.log("Sending edit marker tag colour");

    return {
        queue: ADMIN_MARKER_CONFIGURATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                access_secret: accessSecret,
                markerID,
                tagColour,
                action: "edit_annotation_details",
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Marker type response", response);
                        dispatch(getMarkerConfigurations());
                        callback(response);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to obtain marker data", "An error occurred while fetching marker data", error);
                    next();
                });
        },
    };
}

export function getExceedenceData(sessionID, page = 0, callback = false) {
    return {
        queue: EXCEEDENCE_DATA,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_exceedence_data",
                session_id: sessionID,
                page,
            };
            let url = "/routeMetadata";
            console.log("getting exceedence data");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response.data) {
                        dispatch({
                            type: EXCEEDENCE_DATA,
                            data: response.data,
                        });
                    }
                    callback();

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting exceedence data", "An error occurred while fetching exceedence data", error);
                    next();
                });
        },
    };
}

export function editMarkerIcon(markerID, iconType, iconColour, callback) {
    console.log("Sending edit marker icon");

    return {
        queue: ADMIN_MARKER_CONFIGURATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                markerID,
                iconType,
                iconColour,
                action: "edit_annotation_icon",
                access_secret: accessSecret,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    if (callback) {
                        callback(true);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to update marker details", "An error occurred while updating marker details", error);
                    next();
                });
        },
    };
}

export function getMarkerStats(callback) {
    return {
        queue: GET_MARKER_STATS,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "marker_stats",
                access_secret: accessSecret,
                timeframe: "month",
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Got these markers", response);
                        if (callback) {
                            callback({ errorMsg: false });
                        }
                        dispatch(markerStats(response));
                    }
                    next();
                })
                .catch((error) => {
                    if (callback) {
                        callback({ errorMsg: "There was an error while fetching marker statistics data, please try again later." });
                    }
                    next();
                });
        },
    };
}

export function reviewMarker(markerID, review, sessionID, callback) {
    let postBody = {
        action: "review_marker",
        markerID,
        review,
    };

    let url = "/markers";
    console.log("sessionID", sessionID);
    return {
        queue: SESSION_MARKERS,
        callback: (next, dispatch, getState) => {
            jsonPostV2(url, getState(), postBody)
                .then((response) => {
                    console.log("Review response", response);

                    if (response.success) {
                        const currentMarkers = _.cloneDeep(getState().markers.perSession[sessionID]);
                        const updatingIndex = _.findIndex(currentMarkers, { id: markerID });

                        currentMarkers[updatingIndex].review_status = review;
                        currentMarkers[updatingIndex].review_auto_selected = false;

                        const updatedMarker = currentMarkers[updatingIndex];
                        dispatch(selectMarker(updatedMarker));
                        dispatch(sessionMarkers(sessionID, currentMarkers));
                    }

                    if (callback) {
                        callback(response.success);
                    }
                    next();
                })
                .catch((error) => {
                    console.log("Error reviewing marker: ", error);
                    if (callback) {
                        callback(false);
                    }

                    handleJsonPostError("Unable to submit detection review", "An error occurred while reviewing detection", error);
                    next();
                });
        },
    };
}

export function getNearbyAssets(assetType, mlAsset, callback, refresh = false) {
    return {
        queue: ASSETS,
        callback: (next, dispatch, getState) => {
            console.log("Getting local assets", assetType);

            const currentBounds = getState().sessionFilters.mapBounds.current;

            let postBody = {
                action: "lookup_nearby_assets",
                ...currentBounds,
            };

            dispatch(findAudit("find_nearby_assets", currentBounds));

            if (refresh) {
                postBody["type"] = getState().assets.assetSearch.type;
                postBody["mlAsset"] = getState().assets.assetSearch.mlAsset;
            } else {
                dispatch({
                    type: SEARCH_ASSETS,
                    assetType,
                    mlAsset,
                });
                postBody["type"] = assetType;
                postBody["mlAsset"] = mlAsset;
            }

            const url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got local assets", response);
                    if (response.nearby_assets) {
                        dispatch(receiveNearbyAssets(response.nearby_assets));
                        callback(response.nearby_assets);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get local assets", "An error occurred while fetching local assets", error);
                    next();
                });
        },
    };
}

export function toggleAssetDisplay() {
    return (dispatch, getState) => {
        let displaying = getState().assets.displayingAssets;

        dispatch({
            type: TOGGLE_ASSET_DISPLAY,
            display: !displaying,
        });

        if (displaying) {
            dispatch({
                type: CLEAR_ASSETS,
            });
            dispatch(clearAssets());
        }
    };
}

export function toggleStationDisplay(station) {
    return (dispatch, getState) => {
        let displaying = getState().assets.displayingStation;

        if (!_.isEmpty(displaying)) {
            dispatch({
                type: CLEAR_STATIONS,
            });
        } else {
            dispatch({
                type: TOGGLE_STATION_DISPLAY,
                station,
            });
        }
    };
}

export function findAsset(type, assetID, mlAsset, callback) {
    return {
        queue: ASSETS,
        callback: (next, dispatch, getState) => {
            console.log("Finding asset");

            let postBody = {
                action: "find_asset",
                asset_id: assetID,
                type,
                mlAsset,
            };

            const url = "/mapGeometry";

            dispatch(findAudit("search_assets", { type, assetID }));

            dispatch({
                type: SEARCH_ASSETS,
                assetType: type,
                mlAsset,
            });

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Found assets", response);
                    let assets = [];
                    if (response.assets) {
                        assets = response.assets;
                        if (assets.length > 1) {
                            let north = null;
                            let east = null;
                            let south = null;
                            let west = null;

                            assets.forEach((asset) => {
                                let lat = 0;
                                let lon = 0;
                                if (asset.lat) {
                                    lat = asset.lat;
                                    lon = asset.lon;
                                } else {
                                    [lon, lat] = asset.coords;
                                }

                                if (lat + 0.001 > north || !north) {
                                    north = lat + 0.001;
                                }

                                if (lon + 0.001 > east || !east) {
                                    east = lon + 0.001;
                                }

                                if (lat - 0.001 < south || !south) {
                                    south = lat - 0.001;
                                }

                                if (lon + 0.001 < west || !west) {
                                    west = lon - 0.001;
                                }
                            });

                            console.log("going to bounds", {
                                north,
                                south,
                                east,
                                west,
                            });
                            dispatch(
                                goToBounds({
                                    north,
                                    south,
                                    east,
                                    west,
                                }),
                            );
                        } else if (assets.length) {
                            if (assets[0].lat && assets[0].lon) {
                                const asset = assets[0];
                                const boundsObj = {
                                    north: asset.lat + 0.001,
                                    south: asset.lat - 0.001,
                                    east: asset.lon + 0.001,
                                    west: asset.lon - 0.001,
                                };
                                console.log("going to bounds", boundsObj);
                                dispatch(goToBounds(boundsObj));
                            }
                        }
                        dispatch(receiveNearbyAssets(assets));
                    }

                    if (callback) {
                        callback(assets);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to find asset", "An error occurred while fetching asset", error);
                    next();
                });
        },
    };
}

export function fetchStations() {
    return {
        queue: RECEIVE_STATIONS,
        callback: (next, dispatch, getState) => {
            console.log("getting stations");

            let postBody = {
                action: "get_stations",
            };

            const url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("fetch stations response", response);
                    if (response.stations) {
                        dispatch(receiveStations(response.stations));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch stations", "An error occurred while fetching fetching stations", error);
                    next();
                });
        },
    };
}

export function createAdminMarker(markerName, markerType, markerColour, markerImportance, markerTagColour, callback) {
    return {
        queue: ADMIN_MARKER_LIST,
        callback: (next, dispatch, getState) => {
            console.log("Creating new icon");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "create_marker",
                access_secret: accessSecret,
                marker_name: markerName,
                marker_type: markerType,
                marker_colour: markerColour,
                importance: markerImportance,
                tag_colour: markerTagColour,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got response", response);
                    if (callback) {
                        callback();
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to create annotation type", "An error occurred while creating the specified annotation type", error);
                    next();
                });
        },
    };
}

export const toggleMarkerHistoryLoading = () => {
    return {
        type: LOADING_MARKER_HISTORY,
    };
};

export function getMarkersForWorkspace(payload, callback, abortSignal) {
    return (dispatch, getState) => {
        let url = "/markers";

        jsonPostV2(url, getState(), payload, dispatch, false, abortSignal)
            .then((response) => {
                // let markers = [];
                if (response.markers) {
                    console.log("workspace markers response", response);
                    callback(response.markers);
                }
            })
            .catch((error) => {
                if (error !== "user_abort") {
                    handleJsonPostError("Unable to retrieve marker data", "An error occurred while fetching route markers", error);
                }
            });
    };
}

export const receiveHistoryMarkers = (markers) => {
    return {
        type: RECEIVE_HISTORY_MARKERS,
        markers,
    };
};

export function getAllMarkerConditionTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_all_marker_condition_types",
        };

        const url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                // console.log("fetch marker condition types response", response);
                if (response.results) {
                    dispatch(conditionTypes(response.results));
                    const groupedConditions = _.groupBy(response.results, "marker_type");

                    // add defaults to markerConditionFilter
                    let markerConditionFilters = {};
                    _.map(groupedConditions, (condition, name) => {
                        const types = _.map(condition, (type) => {
                            return type.id;
                        });
                        markerConditionFilters[name] = [null, ...types];
                    });
                    dispatch(setMarkerConditionFilter(markerConditionFilters));
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to fetch condition types", "An error occurred while fetching condition types", error);
            });
    };
}

export function setMarkerConditionType(sessionID, markerID, conditionID) {
    console.log("debug setting marker condition type", sessionID, markerID, conditionID);

    return (dispatch, getState) => {
        let postBody = {
            action: "set_marker_condition_type",
            marker_id: markerID,
            condition_id: conditionID,
        };

        const url = "/markers";

        const currentDataState = _.cloneDeep(getState().markers.perSession[sessionID]);
        const newDataState = currentDataState.map((item) =>
            item.id === markerID ? { ...item, condition_id: conditionID, condition_auto_selected: false } : item,
        );

        dispatch(updateMarkerConditions(sessionID, newDataState));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    notification.success({ message: "Successfully updated condition", duration: 1.5 });
                }
            })
            .catch((error) => {
                dispatch(updateMarkerConditions(sessionID, currentDataState));
                handleJsonPostError("Unable to set marker condition type", "An error occurred while setting condition type", error);
            });
    };
}

export function getAssetGroups() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_asset_groups",
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.asset_groups) {
                    dispatch({
                        type: RECEIVE_ML_ASSET_GROUPS,
                        groups: response.asset_groups,
                    });
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to fetch issue groups", "An error occurred while fetching issue groups", error);
            });
    };
}

export function getIssueGroups() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_issue_groups",
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.issue_groups) {
                    dispatch({
                        type: RECEIVE_ISSUE_GROUPS,
                        groups: response.issue_groups,
                    });
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to fetch issue groups", "An error occurred while fetching issue groups", error);
            });
    };
}

export function generateIssueAssetReport(parameters, export_type) {
    return (dispatch, getState) => {
        let postBody = {
            action: "request_issue_asset_report",
            export_type,
            parameters,
        };
        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Your report will be delivered via email" });
                } else {
                    notification.error({ message: "An error occurred requesting your report" });
                }
            })
            .catch((e) => {
                console.log("Error generating the report: ", e);
                notification.error({ message: "An error occurred generating your report" });
            });
    };
}

export function generateThermalReport(parameters) {
    return (dispatch, getState) => {
        let postBody = {
            action: "request_thermal_issues_report",
            parameters,
        };
        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Your report will be delivered via email" });
                } else {
                    notification.error({ message: "An error occurred requesting your report" });
                }
            })
            .catch((e) => {
                console.log("Error generating the report: ", e);
                notification.error({ message: "An error occurred generating your report" });
            });
    };
}

export function generateBalanceWeightReport(parameters) {
    return (dispatch, getState) => {
        let postBody = {
            action: "request_balance_weight_issues_report",
            parameters,
        };
        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Your report will be delivered via email" });
                } else {
                    notification.error({ message: "An error occurred requesting your report" });
                }
            })
            .catch((e) => {
                console.log("Error generating the report: ", e);
                notification.error({ message: "An error occurred generating your report" });
            });
    };
}

export function getIssues(showClosed, issueGroup, callback, page = 0) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_issues",
            show_closed: showClosed,
            issue_group: issueGroup,
            page,
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.issues) {
                    dispatch({
                        type: RECEIVE_ISSUES,
                        issues: response.issues,
                        clear: page === 0,
                    });
                }
                if (response.issue_groups) {
                    dispatch({
                        type: RECEIVE_ISSUE_GROUPS,
                        groups: response.issue_groups,
                    });
                }
                if (response.is_more) {
                    return dispatch(getIssues(showClosed, issueGroup, callback, page + 1));
                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to fetch issues", "An error occurred while fetching issues", error);
            });
    };
}

export function getThermalAssets(callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_assets",
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("debug thermal assets response", response);
                if (response.assets) {
                    dispatch({
                        type: RECEIVE_ISSUES,
                        issues: response.assets,
                    });
                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to fetch thermal assets", "An error occurred while fetching thermal assets", error);
            });
    };
}

export function getIssueObservations(issueID) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_issue_observations",
                asset_id: issueID,
            };
            const url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.observations) {
                        dispatch(receiveIssueObservations(response.observations));
                    }
                    resolve(response.observations);
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch issue observations", "An error occurred while fetching issue observations", error);
                    reject();
                });
        });
    };
}

export function updateIssue(issueID, updates, isIssue, updatingMidpoint = false) {
    return (dispatch, getState) => {
        let postBody = {
            action: "update_issue",
            issue_id: issueID,
            updates,
            isIssue,
        };
        const url = "/mapGeometry";

        console.log("debug postBody", postBody);
        if (isIssue) {
            dispatch(updateIssueLocally(issueID, updates, updatingMidpoint));
        } else {
            dispatch(updateAssetLocally(issueID, updates, updatingMidpoint));
        }

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("debug update issue response", response);
            })
            .catch((error) => {
                handleJsonPostError("Unable to update issue", "An error occurred while updating issue", error);
            });
    };
}

export function getMlAssetTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_searchable_asset_types",
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("debug get ml asset types", response);
            })
            .catch((error) => {
                handleJsonPostError("Unable to ml asset types", "An error occurred while fetching asset types", error);
            });
    };
}

function updateAssetLocally(assetID, updates, updatingMidpoint) {
    return (dispatch, getState) => {
        const currentAssets = _.cloneDeep(getState().mlAssets.assetData);

        if (!_.isNil(updates.note)) {
            currentAssets[assetID].note = updates.note;
            currentAssets[assetID].note_source = "You";
            currentAssets[assetID].note_change_ts = Date.now() / 1000;
        }

        if (!_.isNil(updates.ground_truth_data)) {
            currentAssets[assetID].ground_truth_data = JSON.parse(updates.ground_truth_data);
            currentAssets[assetID].ground_truth_source = "You";

            if (updatingMidpoint) {
                const currentObservations = _.cloneDeep(getState().mlAssets.observations);
                currentObservations.observations = _.map(currentObservations.observations, (observation) => {
                    return { ...observation, reprocessing: true };
                });
                dispatch(receiveAssetObservations(currentObservations));
            }
        }

        dispatch(receiveAssetData(currentAssets));
    };
}

function updateIssueLocally(issueID, updates, updatingMidpoint) {
    return (dispatch, getState) => {
        const currentIssues = _.cloneDeep(getState().issues.data);
        const updatingIndex = _.findIndex(currentIssues, { id: issueID });

        if (!_.isNil(updates.state)) {
            currentIssues[updatingIndex].state = updates.state;
            currentIssues[updatingIndex].state_change_ts = Date.now() / 1000;
        }

        if (!_.isNil(updates.priority)) {
            currentIssues[updatingIndex].priority = updates.priority;
        }

        if (!_.isNil(updates.note)) {
            currentIssues[updatingIndex].note = updates.note;
            currentIssues[updatingIndex].note_source = "You";
            currentIssues[updatingIndex].note_change_ts = Date.now() / 1000;
        }

        if (!_.isNil(updates.ground_truth_data)) {
            currentIssues[updatingIndex].ground_truth_data = JSON.parse(updates.ground_truth_data);

            if (updatingMidpoint) {
                let currentObservations = _.cloneDeep(getState().issues.observations);
                currentObservations = _.map(currentObservations.observations, (observation) => {
                    return { ...observation, reprocessing: true };
                });
                dispatch(receiveIssueObservations(currentObservations));
            }
        }

        dispatch({
            type: RECEIVE_ISSUES,
            issues: currentIssues,
        });
    };
}

export function reviewIssueObservation(observationID, reviewStatus, observationType, issueID = null, isIssue = true) {
    return (dispatch, getState) => {
        let postBody = {
            action: "review_observation",
            observation_id: observationID,
            review_status: reviewStatus,
            observation_type: observationType,
            issue_id: issueID,
            is_issue: isIssue,
        };
        const url = "/mapGeometry";

        if (reviewStatus === 3 || reviewStatus === 4) {
            let newObservations = getState().issues.observations;
            newObservations = newObservations.filter((observation) => {
                return observation.id !== observationID;
            });
            dispatch(receiveIssueObservations(newObservations));
            dispatch(selectObservation(null));
        }

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("debug reject observation response", response);
                if (!isIssue) {
                    dispatch(getAssetData(issueID, true));
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to reject observation", "An error occurred while rejecting observation", error);
            });
    };
}

export function getAssetList(
    assetType,
    groundTruthFilter,
    searchQuery,
    sortValue,
    showUnreadable,
    showFailedMatches,
    trustLevel,
    startTime,
    endTime,
    abortSignal,
    bottomOutTemp,
    vegetation,
    jointType,
    callback,
) {
    return (dispatch, getState) => {
        let formattedSearchQuery = new AssetIDHelper(searchQuery).format_search_query();
        let postBody = {
            action: "get_asset_ids",
            asset_type: assetType,
            ground_truth_filter: groundTruthFilter,
            query: formattedSearchQuery,
            sort: sortValue,
            show_unreadable: showUnreadable,
            show_failed_matches: showFailedMatches,
            trust_level_filter: trustLevel,
            start_time: startTime,
            end_time: endTime,
            bottom_out_temp: bottomOutTemp,
            vegetation,
            jointType,
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch, false, abortSignal)
            .then((response) => {
                if (response.asset_ids) {
                    dispatch({
                        type: RECEIVE_ASSET_IDS,
                        assets: response.asset_ids,
                    });
                    callback();
                }
            })
            .catch((error) => {
                if (error !== "user_abort") {
                    handleJsonPostError("Error getting asset list", "An error occurred while getting the asset list", error);
                }
            });
    };
}

let pendingAssetsToRequest = {};

export function getAssetData(asset_id, force = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const cachedAssets = getState().mlAssets.assetData;
            if (!force && cachedAssets.hasOwnProperty(asset_id)) {
                resolve(cachedAssets[asset_id]);
                return;
            }

            if (!pendingAssetsToRequest.hasOwnProperty(asset_id)) {
                pendingAssetsToRequest[asset_id] = [{ resolve, reject }];
            } else {
                pendingAssetsToRequest[asset_id].push({ resolve, reject });
            }
            dispatch(assetDataRequestDispatcher());
        });
    };
}

export function reviewExternalObservation(observationID, review, assetObservations) {
    return (dispatch, getState) => {
        let postBody = {
            action: "verify_external_observations",
            observation_id: observationID,
            verify: review,
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    const updatedExternalObservations = assetObservations["external_observations"].map((observation) => {
                        if (observation.id === observationID) {
                            observation.review_status = review ? 1 : 0;
                        }
                        return observation;
                    });

                    dispatch(
                        receiveAssetObservations({
                            observations: assetObservations.observations,
                            external_observations: updatedExternalObservations,
                        }),
                    );
                    notification.success({ message: `Observation ${review ? "verified" : "unverified"}` });
                } else {
                    notification.error({ message: "There was an error verifying the observation" });
                }
            })
            .catch((error) => {
                console.log("There was an error reviewing observation: ", observationID, error);
                notification.error({ message: "There was an error verifying the observation" });
            });
    };
}

function assetDataRequestDispatcher() {
    return {
        queue: RECEIVE_ASSET_IDS,
        callback: (next, dispatch, getState) => {
            const pendingSessionsAtTimeOfRequest = pendingAssetsToRequest;

            const asset_ids = Object.keys(pendingAssetsToRequest).slice(0, 100);
            const keysFiltered = Object.keys(pendingAssetsToRequest).slice(100);

            pendingAssetsToRequest = {};
            keysFiltered.forEach((assetID) => {
                pendingAssetsToRequest[assetID] = pendingSessionsAtTimeOfRequest[assetID];
            });

            if (asset_ids.length === 0) {
                //Nothing to do
                setTimeout(() => next(), 0);
                return;
            }

            let postBody = {
                action: "get_asset_data",
                asset_ids: asset_ids,
            };

            console.log("Making asset list request with IDs: ", asset_ids);

            let url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ assets }) => {
                    if (assets) {
                        let assetsMap = _.keyBy(assets, (asset) => {
                            return asset.id;
                        });
                        dispatch(receiveAssetData(assetsMap));
                    } else {
                        dispatch(receiveAssetData({}));
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve asset data", "An error occurred while fetching the asset data", error);
                    dispatch(receiveAssetData({}));
                })
                .then(() => {
                    const cachedAssets = getState().mlAssets.assetData;
                    asset_ids.forEach((asset_id) => {
                        const promises = pendingSessionsAtTimeOfRequest[asset_id];
                        if (cachedAssets.hasOwnProperty(asset_id)) {
                            const asset_data = cachedAssets[asset_id];
                            for (let i = 0; i < promises.length; i++) {
                                promises[i].resolve(asset_data);
                            }
                        } else {
                            for (let i = 0; i < promises.length; i++) {
                                promises[i].reject();
                            }
                        }
                    });
                })
                .finally(() => {
                    //After the request is complete, schedule another in case more session data is pending
                    next();
                    dispatch(assetDataRequestDispatcher());
                });
        },
    };
}

export function setAssetUnreadable(id, unreadable) {
    return (dispatch, getState) => {
        let postBody = {
            action: "set_asset_unreadable",
            id,
            unreadable,
        };
        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    console.log("Updated if asset is readable");
                }
            })
            .catch(() => {
                console.log("Error updating assets readability");
            });
    };
}

export function getGroundTruthedCount(assetType) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_ground_truth_count",
                asset_type: assetType,
            };
            let url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve({});
                    }
                })
                .catch((error) => {
                    console.log("Error getting ground truthed count", error);
                    reject(error);
                });
        });
    };
}

export function reviewSessionObservation(observationID, continuousObservation, review, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "review_observations",
            observationID: continuousObservation ? [observationID] : observationID,
            continuousObservation,
            review,
        };
        const url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    const _observations = _.cloneDeep(getState().sessionObservations);
                    const index = _.findIndex(_observations, { id: observationID });
                    _observations[index].review_status = review;
                    const updatedObservation = _observations[index];
                    dispatch(selectMarker(updatedObservation));
                    dispatch(sessionObservations(_observations));
                    callback(true);
                    console.log("Observation reviewed");
                } else {
                    callback(false);
                    console.log("There was an error reviewing observation: ", observationID);
                }
            })
            .catch((error) => {
                callback(false);
                console.log("There was an error reviewing observation: ", observationID, error);
            });
    };
}

export function setFilteredIsuesData(issues) {
    return {
        type: SET_FILTERED_ISSUES,
        issues,
    };
}

export function getAssetsCount(assetType) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_assets_stats",
                asset_type: assetType,
            };
            let url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve({});
                    }
                })
                .catch((error) => {
                    console.log("Error getting assets count", error);
                    reject(error);
                });
        });
    };
}

export function classifyContinuousObservation(observationIDs, data_type, overrideSectionClassifications, userData) {
    return (dispatch, getState) => {
        let postBody = {
            action: "classify_continuous_observations",
            user_data: userData,
            data_type: data_type,
            extentIds: observationIDs,
            override_classifications: overrideSectionClassifications,
        };

        let url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then(() => {
                const observations = _.cloneDeep(getState().sessionObservations);
                const amendedObservations = _.cloneDeep(observations).map((item) => {
                    if (!observationIDs.includes(item.id)) {
                        return item;
                    }

                    if (_.isEmpty(item.classifications)) {
                        item.classifications = { [data_type]: { auto: null, user: userData } };
                        return item;
                    }

                    if (overrideSectionClassifications) {
                        if (item.classifications.hasOwnProperty(data_type)) {
                            item.classifications = { ...item.classifications, [data_type]: { ...item.classifications[data_type], user: userData } };
                        } else {
                            item.classifications = { ...item.classifications, [data_type]: { auto: null, user: userData } };
                        }
                    } else if (_.get(item, `classifications.${data_type}.user`, null) === null) {
                        if (item.classifications.hasOwnProperty(data_type)) {
                            item.classifications = { ...item.classifications, [data_type]: { ...item.classifications[data_type], user: userData } };
                        } else {
                            item.classifications = { ...item.classifications, [data_type]: { auto: null, user: userData } };
                        }
                    }

                    return item;
                });

                // const amendedObservations = _.cloneDeep(observations).map(item => {
                //     if (observationIDs.includes(item.id)) {
                //         if (!_.isEmpty(item.classifications)) {
                //             if (overrideSectionClassifications) {
                //                 if (item.classifications.hasOwnProperty(data_type)) {
                //                     item.classifications = {...item.classifications, [data_type]: {...item.classifications[data_type], user: userData}}
                //                     console.log(item)
                //                 } else {
                //                     item.classifications = {...item.classifications, [data_type]: {auto: null, user: userData}}
                //                 }
                //             } else {
                //                 if (_.get(item, `classifications.${data_type}.user`, null) === null) {
                //                     if (item.classifications.hasOwnProperty(data_type)) {
                //                         item.classifications = {...item.classifications, [data_type]: {...item.classifications[data_type], user: userData}}
                //                         console.log(item)
                //                     } else {
                //                         item.classifications = {...item.classifications, [data_type]: {auto: null, user: userData}}
                //                     }
                //                 }
                //             }
                //         } else {
                //             item.classifications = {[data_type]: {auto: null, user: userData}}
                //         }
                //     }
                //     return item
                // })
                dispatch(sessionObservations(amendedObservations));
                console.log(`Classified observation: ${observationIDs.join(", ")}`);
            })
            .catch((error) => {
                console.log("Error submitting classification", error);
            });
    };
}

export function setObservationClassification(observation, classification, callback) {
    return (dispatch, getState) => {
        // observation_type 0 sets thermal, any other number runs the same action - this function only currently used for Structure ID Plates

        const _classification = JSON.stringify(classification);
        let postBody = {
            action: "update_observation_classification",
            observation_id: observation.id,
            observation_type: 2,
            classification: _classification,
        };

        let url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    const observations = _.map(getState().sessionObservations, (obs) => {
                        if (obs.id === observation.id) {
                            obs["class_specific_data"] = classification;
                        }
                        return obs;
                    });
                    dispatch(sessionObservations(observations));
                    notification.success({ message: "Successfully updated the OCR Result", duration: 1.5 });
                    callback(true);
                }
            })
            .catch((error) => {
                console.log("Error submitting classification", error);
                notification.error({ message: "An error occurred submitting your classifications" });
            });
    };
}

export function classifyObservationsObject(observation_id, classification, classificationType) {
    return (dispatch, getState) => {
        let postBody = {
            action: "classify_observations_object",
            observation_id,
            classification,
            classification_type: classificationType,
        };

        let url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    let observations = _.cloneDeep(getState().sessionObservations);
                    observations = observations.map((observation) => {
                        if (observation.id === observation_id) {
                            return {
                                ...observation,
                                classifications: {
                                    ...observation.classifications,
                                    [classificationType]: { ..._.get(observation, ["classifications", classificationType], {}), user: classification },
                                },
                            };
                        }
                        return observation;
                    });

                    dispatch(sessionObservations(observations));
                    notification.success({ message: "Successfully updated the information held for this signal", duration: 1.5 });
                }
            })
            .catch((error) => {
                console.log("Error submitting classification", error);
                notification.error({ message: "An error occurred submitting your classification" });
            });
    };
}

export function classifyScrapRailLength(extentID, length) {
    return (dispatch, getState) => {
        let postBody = {
            action: "set_scrap_rail_observation_length",
            extentID,
            length,
        };

        let url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    let observations = _.cloneDeep(getState().sessionObservations);

                    observations = observations.map((observation) => {
                        if (observation.id === extentID) {
                            return {
                                ...observation,
                                classifications: {
                                    ..._.get(observation, "classifications", {}),
                                    scrap_rail_length: { ..._.get(observation, ["classifications", "scrap_rail_length"], {}), user: length },
                                },
                            };
                        }
                        return observation;
                    });

                    dispatch(sessionObservations(observations));
                    notification.success({ message: "Successfully updated the Scrap Rail length", duration: 1.5 });
                }
            })
            .catch((error) => {
                console.log("Error submitting length", error);
                notification.error({ message: "An error occurred submitting your classification" });
            });
    };
}

export function fetchDetectionTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_detection_types",
        };
        const url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    dispatch(receiveDetectionTypes(response.types));
                    let allMarkers = getState().markers.perSession;
                    Object.keys(allMarkers).forEach((sessionID) => {
                        let sessionMarkers = allMarkers[sessionID];
                        dispatch(saveMarkerDefaults(calculateThreshold(sessionMarkers, response), sessionID));
                    });
                }
            })
            .catch((error) => {
                console.log("Error getting detection tpyes", error);
            });
    };
}

export function getObjectObservationDataTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_object_observation_data_types",
        };

        const url = "/markers";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(setObjectObservationDataTypes(response));
            })
            .catch((error) => {
                console.log("Error getting object observation data types", error);
            });
    };
}
