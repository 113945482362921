import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { toggleIconConfiguration, editMarkerIcon, getMarkerConfigurations } from "../redux/actions/index";
import { connect } from "react-redux";
import { CirclePicker } from "react-color";
import _ from "lodash";
import { ReactComponent as SprayCanSvg } from "icons/pins/spray-can.svg";
import { ReactComponent as TreeSvg } from "icons/pins/tree.svg";
import { ReactComponent as TrafficLightSvg } from "icons/pins/traffic-light.svg";
import { ReactComponent as PersonSvg } from "icons/pins/person.svg";
import { ReactComponent as ArchwaySvg } from "icons/pins/archway.svg";
import { ReactComponent as ExclamationTriangleSvg } from "icons/pins/exclamation-triangle.svg";
import { ReactComponent as BaliseSvg } from "icons/pins/balise.svg";
import { ReactComponent as AxleCounterSvg } from "icons/pins/axle-counter.svg";
import { ReactComponent as Model3dSvg } from "icons/pins/3d-model.svg";
import { ReactComponent as BallastBagSvg } from "icons/pins/ballast-bag.svg";
import { ReactComponent as BridgeSvg } from "icons/pins/bridge.svg";
import { ReactComponent as LimitedClearanceSvg } from "icons/pins/limited-clearance.svg";
import { ReactComponent as PointSvg } from "icons/pins/point.svg";
import { ReactComponent as ScrapRailSvg } from "icons/pins/scrap-rail.svg";
import { ReactComponent as SpeedBoardSvg } from "icons/pins/speed-board.svg";
import { ReactComponent as TunnelSvg } from "icons/pins/tunnel.svg";
import { ReactComponent as ThermalHotspotSvg } from "icons/pins/thermal-hotspot.svg";
import { ReactComponent as KonuxSvg } from "icons/pins/konux.svg";
import { Modal, notification } from "antd";

class IconEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedColour: "#000000",
            selectedIcon: "spray-can",
        };
    }

    componentDidMount = () => {
        this.findChosenAnnotation();
    };

    closeIconEditor = () => {
        this.props.dispatch(toggleIconConfiguration(false));
    };

    selectIcon = (iconName) => {
        this.setState({
            selectedIcon: iconName,
        });
    };

    findChosenAnnotation = () => {
        let annotationID = this.props.iconConfigID;
        let allMarkers = this.props.markers;

        let selectedMarker = _.find(allMarkers, function (marker) {
            return marker.id === annotationID;
        });

        console.log("Selected marker", selectedMarker);
        this.setState({
            selectedIcon: selectedMarker.icon,
            selectedColour: selectedMarker.colour,
        });
    };

    renderIcons = () => {
        const iconSize = { width: 60, height: 60 };

        const iconList = [
            { element: <SprayCanSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "spray-can" },
            { element: <TreeSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "tree" },
            { element: <TrafficLightSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "traffic-light" },
            { element: <PersonSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "male" },
            { element: <ArchwaySvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "archway" },
            { element: <ExclamationTriangleSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "exclamation-triangle" },
            { element: <BaliseSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "balise" },
            { element: <AxleCounterSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "axleCounter" },
            { element: <Model3dSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "3dModel" },
            { element: <BallastBagSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "ballast-bag" },
            { element: <BridgeSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "bridge" },
            { element: <LimitedClearanceSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "limited-clearance" },
            { element: <PointSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "point" },
            { element: <ScrapRailSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "scrap-rail" },
            { element: <SpeedBoardSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "speed-board" },
            { element: <TunnelSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "tunnel" },
            { element: <ThermalHotspotSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "thermal-hotspot" },
            { element: <KonuxSvg style={{ fill: this.state.selectedColour, ...iconSize }} />, iconName: "konux" },
        ];

        return iconList.map((icon) => {
            let selectedClass = "";

            if (icon.iconName === this.state.selectedIcon) {
                selectedClass = "selectedIconBlack";
            }

            return (
                <div
                    className={"individualIconDiv " + selectedClass}
                    key={icon.iconName}
                    onClick={() => this.selectIcon(icon.iconName)}>
                    {icon.element}
                </div>
            );
        });
    };

    changeSelectedColor = (colour) => {
        this.setState({
            selectedColour: colour.hex,
        });
    };

    isOkDisabled = () => {
        const currentMarker = this.props.markers[this.props.iconConfigID];
        return this.state.selectedColour === currentMarker.colour && this.state.selectedIcon === currentMarker.icon;
    };

    saveIconChanges = () => {
        let iconID = this.props.iconConfigID;
        let iconType = this.state.selectedIcon;
        let iconColour = this.state.selectedColour;

        console.log("Saving icon changes");
        console.log("iconID", iconID);
        console.log("iconType", iconType);
        console.log("iconColour", iconColour);

        this.props.dispatch(
            editMarkerIcon(iconID, iconType, iconColour, (success) => {
                this.props.dispatch(getMarkerConfigurations());
                this.closeIconEditor();
                if (success) {
                    notification.success({
                        message: "Success",
                        description: "Successfully updated marker icon & colour",
                    });
                }
            }),
        );
    };

    render() {
        let annotationID = this.props.iconConfigID;
        let allMarkers = this.props.markers;

        let selectedMarker = _.find(allMarkers, function (marker) {
            return marker.id === annotationID;
        });

        return (
            <Modal
                title={`Edit Marker: ${selectedMarker.type}`}
                visible={true}
                onOk={this.saveIconChanges}
                okButtonProps={{ disabled: this.isOkDisabled() }}
                onCancel={this.closeIconEditor}
                style={{ width: "50%" }}>
                <div style={{ display: "flex" }}>
                    <div className="newMarkerFlexItem color">
                        <label className="newIconLabel">Colour</label>
                        <div
                            className="newMarkerIconInput"
                            id="newMarkerColourPicker">
                            <CirclePicker
                                width="100%"
                                color={this.state.selectedColour}
                                colors={[
                                    "#AA201B",
                                    "#71000B",
                                    "#FF7F18",
                                    "#DBA400",
                                    "#714B30",
                                    "#55B431",
                                    "#417505",
                                    "#413F6B",
                                    "#4A90E2",
                                    "#2DB5BA",
                                    "#000000",
                                    "#4A4A4A",
                                    "#9B9B9B",
                                ]}
                                onChange={this.changeSelectedColor}
                            />
                        </div>
                    </div>

                    <div className="newMarkerFlexItem icons">
                        <label className="newIconLabel">Icon</label>
                        <div className="newMarkerFlexBox newMarkerIconInput">{this.renderIcons()}</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    iconConfigID: state.admin.iconConfiguration.annotationID,
    markers: state.admin.markers,
});

export default connect(mapStateToProps, null)(IconEditor);
