import React, { useEffect, useMemo, useRef } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Polyline } from "react-leaflet";
import memoize from "memoize-one";
import _ from "lodash";
import { SESSION_FILTERS_DEFAULTS, selectMapPoint, setMapPointSelected } from "redux/actions/index";
import { distance } from "../util/PlaylistUtils";
import { message } from "antd";

const geomColourDict = {
    blue: {
        1: "#94D6FC",
        2: "#629EE5",
        3: "#4765AC",
    },
    default: {
        1: "rgb(127, 127, 127)",
        2: "rgb(127, 127, 127)",
        3: "rgb(127, 127, 127)",
    },
};

const checkHighlight = (segment_id, sessions, highlighted_route_id) => {
    let isHighlighted = false;
    if (highlighted_route_id !== null) {
        const highlightedSession = sessions[highlighted_route_id];
        if (highlightedSession && highlightedSession.map_segments && highlightedSession.map_segments.length) {
            // Fast binary search is possible here because highlightedSession.map_segments is always in ascending order
            isHighlighted = _.sortedIndexOf(highlightedSession.map_segments, segment_id) !== -1;
        }
    }
    return isHighlighted;
};

const userConfigSelector = (state) => state.userDetails.userConfig;
const currentModeSelector = (state) => state.tool_mode;
const sessionsSelector = (state) => state.sessions;
const highlightedRouteIDSelector = (state) => state.highlightedRouteID;
const sessionListFiltersActiveSelector = (state) => {
    return !_.isEqual(SESSION_FILTERS_DEFAULTS, state.sessionListFilters);
};

export const MapGeometryV2 = ({ segment, showRouteAge, mapType, sessionListFiltersActive }) => {
    const polylineRef = useRef();
    const userConfig = useSelector(userConfigSelector);
    const currentMode = useSelector(currentModeSelector);
    const sessions = useSelector(sessionsSelector);
    const highlightedRouteID = useSelector(highlightedRouteIDSelector);
    // const sessionListFiltersActive = useSelector(sessionListFiltersActiveSelector)

    const dispatch = useDispatch();

    const isHighlighted = useMemo(() => {
        return checkHighlight(segment.id, sessions, highlightedRouteID);
    }, [highlightedRouteID, segment.id, sessions]);

    const bringToTop = () => {
        if (polylineRef.current) {
            polylineRef.current.leafletElement.bringToFront();
        }
    };

    const sendToBack = () => {
        if (polylineRef.current) {
            polylineRef.leafletElement.bringToBack();
        }
    };

    const getGeometry = (points) => {
        return points.map((point) => {
            return [point[2], point[1], point[0]];
        });
    };

    const geometry = useMemo(() => {
        return getGeometry(segment.points);
    }, [segment.points]);

    const routeColour = useMemo(() => {
        let routeColour = "rgb(127, 127, 127)";

        if (showRouteAge && segment.latest_session) {
            let colourScheme = null;

            switch (mapType) {
                case "Rail":
                case "Dark":
                case "Aerial":
                case "Detailed":
                case "Light":
                    colourScheme = geomColourDict["blue"];
                    break;
                default:
                    colourScheme = geomColourDict["default"];
                    break;
            }
            let timestamp = segment.latest_session;
            if (timestamp > Date.now() / 1000 - 60 * 60 * 24 * 7 * 6) {
                //6 weeks
                routeColour = colourScheme["1"];
            } else if (timestamp > Date.now() / 1000 - 60 * 60 * 24 * 7 * 12) {
                //12 weeks
                routeColour = colourScheme["2"];
            } else {
                routeColour = colourScheme["3"];
            }
        }

        if (segment && segment.isFilteredOut) {
            routeColour = "#45444a";
        }

        return routeColour;
    }, [mapType, segment, showRouteAge]);

    const routeOpacity = useMemo(() => {
        let routeOpacity = 1;

        if (segment && segment.isFilteredOut) {
            routeOpacity = 0.8;
        }

        return routeOpacity;
    }, [segment]);

    const strokeWeight = 4;

    const onMouseClick = (location) => {
        if (segment && segment.isFilteredOut) {
            if (userConfig.super_admin && !sessionListFiltersActive) {
                message.warning('This track is filtered out by "Flagged sessions"', 2);
            } else {
                message.info("This track is filtered out", 2);
            }
            return null;
        }
        dispatch(setMapPointSelected(true, [location.latlng.lng, location.latlng.lat]));

        if (currentMode) {
            return;
        }
        let orderedPoints = _.sortBy(
            segment.points.map((point) => {
                return {
                    id: point[0],
                    coords: [point[2], point[1]],
                    distance: distance([location.latlng.lng, location.latlng.lat], [point[1], point[2]]),
                };
            }),
            (point) => point.distance,
        );

        dispatch(selectMapPoint(orderedPoints[0].id, [location.latlng.lng, location.latlng.lat]));
    };

    useEffect(() => {
        if (segment.isFilteredOut) {
            sendToBack();
        } else {
            bringToTop();
        }
    }, [segment.isFilteredOut]);

    return (
        <>
            <Polyline
                ref={polylineRef.ref}
                color={routeColour}
                weight={strokeWeight}
                opacity={routeOpacity}
                positions={geometry}
                onClick={onMouseClick}
            />
            {isHighlighted ? (
                <Polyline
                    color="#6C43DF"
                    weight={4}
                    opacity={1}
                    // pathOptions={{ zIndex: 50000 }}
                    positions={geometry}
                />
            ) : null}
        </>
    );
};
