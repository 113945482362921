import ELRMileAndChain from "./ELRMileAndChain";
import GenericPositionalSystem from "./GenericPositionalSystem";
import IrishRailPositionalSystem from "./IrishRailPositionSystem";
import MBTAPositionalSystem from "./MBTAPositionalSystem";

function custom_elr_type(system_id, simpleName) {
    return {
        ID: system_id,
        simpleName,
        handlerClass: ELRMileAndChain,
        create: (route, position, subposition, sources = []) => new ELRMileAndChain(system_id, route, position, subposition, false, sources),
        from_fields: (display_format, fields, datum_offsets) => ELRMileAndChain.from_fields(system_id, display_format, fields, datum_offsets),
        from_search: (fields) => ELRMileAndChain.from_search(system_id, fields),
    };
}

function custom_irish_position_type(systemID, simpleName) {
    return {
        ID: systemID,
        simpleName,
        handlerClass: IrishRailPositionalSystem,
        create: (route, position, subposition, sources = []) => new IrishRailPositionalSystem(systemID, route, position, subposition, sources),
        from_fields: (display_format, fields, datum_offsets) => IrishRailPositionalSystem.from_fields(systemID, display_format, fields, datum_offsets),
    };
}

function custom_generic_position_type(systemID, simpleName) {
    return {
        ID: systemID,
        simpleName,
        handlerClass: GenericPositionalSystem,
        create: (route, position, subposition, sources = []) => new GenericPositionalSystem(systemID, route, position, subposition, sources),
        from_fields: (display_format, fields) => GenericPositionalSystem.from_fields(systemID, display_format, fields),
    };
}

function custom_mbta_position_type(systemID, simpleName) {
    return {
        ID: systemID,
        simpleName,
        handlerClass: MBTAPositionalSystem,
        create: (route, position, subposition, sources = []) => new MBTAPositionalSystem(systemID, route, position, subposition, sources),
        from_fields: (display_format, fields) => MBTAPositionalSystem.from_fields(systemID, display_format, fields),
    };
}

const RouteCoordinateSystems = [
    custom_elr_type("ELR Mile & Chain", "ELR"),
    custom_irish_position_type("Irish Rail", "Irish Rail"),
    custom_mbta_position_type("MBTA", "MBTA"),
    custom_generic_position_type("Generic", "Generic"),
];

export default RouteCoordinateSystems;
