import React, { useMemo, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import DtSessionListItem from "./DtSessionListItem";
import welcomeOverlayImage from "../../../../../src/images/videoloadingoverlay.png";
import OBCSpinner from "components/util/OBC";
import DtProgressBar from "./DtProgressBar";
import { getTrainingCategoryData, setCurrentPage, setSelectedSessionData } from "redux/actions/driverTrainingActions";
import { routeSelected } from "redux/actions";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";

const currentCategorySelector = (state) => _.get(state.driverTraining.trainee, "selectedTraineeCategoryData", null);
// const currentCategorySessionsSelector = state => _.get(state.driverTraining.trainee.selectedTraineeCategoryData, "sessions", null);
// const allFavouriteCategoriesDataSelector = state => state.userDetails.favouriteCategories;
const sessionsSelector = (state) => _.get(state, "sessions", []);
const userFavouriteCategoriesSelector = (state) => state.userDetails.favouriteCategories;
const csrfTokenSelector = (state) => state.csrfToken;

const DtCategoryPage = ({ loadingCategoryData }) => {
    const dispatch = useDispatch();
    const currentCategory = useSelector(currentCategorySelector);
    // const currentCategorySessions = useSelector(currentCategorySessionsSelector);
    // const allFavouriteCategoriesData = useSelector(allFavouriteCategoriesDataSelector);
    const sessions = useSelector(sessionsSelector);
    const userFavouriteCategories = useSelector(userFavouriteCategoriesSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [categoryData, setCategoryData] = useState([]);

    // useEffect(() => {
    //     console.log('debug currentCategoryID', currentCategoryID);

    // }, [currentCategoryID])

    // const sessionList2 = useMemo(() => {
    //     const index = _.findIndex(allFavouriteCategoriesData, category => category.id === currentCategoryID)
    //     if (allFavouriteCategoriesData && index) {
    //         if (allFavouriteCategoriesData[index]) {
    //             return allFavouriteCategoriesData[index].session_data
    //         }
    //     }
    // }, [currentCategoryID, allFavouriteCategoriesData])

    // const filteredSessionList = useMemo(() => {
    //     let list = {}
    //     let sessionsDesc = {}
    //     if (userFavouriteCategories && currentCategory) {
    //         sessionsDesc = _.find(userFavouriteCategories, cat => cat.id === currentCategory.category_id).session_data

    //         if (Object.keys(sessions).length && currentCategory && currentCategory.sessions) {
    //             list = _.pickBy(sessions, (value, key) => _.includes(currentCategory.sessions, String(key)));
    //         }

    //         // console.log('debug2 list', list);
    //         // console.log('debug2 sessionsDesc', sessionsDesc);
    //     }
    //     return _.merge(list, sessionsDesc)
    // }, [currentCategory, sessions, userFavouriteCategories])

    useEffect(() => {
        if (currentCategory) {
            console.log("debug getTrainingCategoryData...");
            dispatch(
                getTrainingCategoryData(currentCategory.sessions, currentCategory.category_id, (data) => {
                    if (data && data.length) {
                        console.log("debug getTrainingCategoryData", data);
                        setCategoryData(data);
                    }
                }),
            );
        }
    }, [currentCategory, dispatch]);

    // const allSessionsSelector = state => state.shortcuts.sessions[setelectedShortcut.id];
    // const allSessions = useSelector(allSessionsSelector);
    // const [sessionList, setSessionList] = useState([]);

    // useEffect(() => {
    //     const _sessionList = _.values(allSessions).filter((session) => session.coverage > 0.94)
    //     setSessionList(_sessionList);
    // }, [allSessions])

    // const sortedSessions = useMemo(() => {
    //     return _.sortBy(sessionList, 'session').reverse().map(session => {
    //         return (
    //             <DtSessionListItem key={session.session} sessionID={session.session} setelectedShortcut={setelectedShortcut} setCurrentPage={setCurrentPage} setSelectedTrainingSession={setSelectedTrainingSession} />
    //         )
    //     })
    // }, [sessionList, setCurrentPage, setSelectedTrainingSession]);

    const selectTrainingSession = (session) => {
        console.log("debug session_id", session);

        dispatch(setCurrentPage("training_session"));
        dispatch(setSelectedSessionData(session));
        // dispatch(routeSelected(session.id, null))
        // routeSelected
    };

    return loadingCategoryData ? (
        <div className="LoadingLogo">
            <OBCSpinner
                size={70}
                speed={3}
                color={"#e8dfff"}
            />
        </div>
    ) : currentCategory ? (
        <div className="CategorySessionsMain">
            <div className="CategorySessionsTop">{currentCategory && currentCategory.category_name}</div>
            <div className="CategorySessionsContent">
                {/* List of sessions per category  */}
                {categoryData.length &&
                    _.map(categoryData, (session) => {
                        // console.log('debug2 session =>', session);
                        let imageSrc = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_key}/${session.session_key}/thumb.jpg`;
                        if (csrfToken) {
                            imageSrc += `?csrf=${csrfToken}`;
                        }

                        return (
                            <div
                                key={session.id}
                                className="SessionListItem"
                                onClick={() => selectTrainingSession(session)}>
                                <img
                                    src={imageSrc}
                                    alt=""
                                    draggable={false}
                                    crossOrigin={"anonymous"}
                                />
                                <div className="SessionContent">
                                    <div className="Title">{session.description ?? session.route_name}</div>
                                    <div className="Description">{session.description ? session.route_name : session.description}</div>
                                </div>
                                <div className="Progress">
                                    <DtProgressBar
                                        className="ListItemContentProgressBar"
                                        progress={_.get(session, ["progress", "completed_percent"], 0)}
                                        textVisible={false}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    ) : (
        <div className="SessionsWelcomeFlex">
            <p>To begin select one of the categories from the list</p>
            <img
                className="WelcomeOverlayImage"
                src={welcomeOverlayImage}
                alt="background"
                crossOrigin={"anonymous"}
            />
        </div>
    );
};

export default DtCategoryPage;
