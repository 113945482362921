import { Input, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export function InputModal({ title, onConfirm, onCancel, confirmText, validate, initialValue, visible }) {
    const [loading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputError, setInputError] = useState(false);
    const inputRef = React.useRef(null);

    const cancel = useMemo(() => {
        return () => {
            setVisible(false);
            if (onCancel) {
                onCancel();
            }
        };
    }, [onCancel]);

    const confirm = useMemo(() => {
        return () => {
            setLoading(true);
            onConfirm(inputValue)
                .then(() => {
                    setLoading(false);
                    setVisible(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setInputError(error);
                });
        };
    }, [onConfirm, inputValue]);

    useEffect(() => {
        if (visible) {
            setInputValue(initialValue);
            setVisible(true);
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus({
                        cursor: "end",
                    });
                }
            }, 100);
        }
    }, [visible, initialValue]);

    useEffect(() => {
        if (validate) {
            const error = validate(inputValue);
            if (error) {
                setInputError(error);
            } else {
                setInputError(false);
            }
        } else {
            setInputError(false);
        }
    }, [inputValue, validate]);

    return (
        <Modal
            title={title}
            visible={isVisible}
            style={{ width: "50%" }}
            onOk={confirm}
            onCancel={cancel}
            afterClose={() => {
                setInputValue("");
            }}
            okText={confirmText || "Confirm"}
            okButtonProps={{ disabled: !!inputError, loading: loading }}
            cancelText={"Cancel"}>
            <Input
                ref={inputRef}
                value={inputValue}
                onChange={(evt) => setInputValue(evt.target.value)}
                onPressEnter={confirm}
                disabled={loading}
                className="sessionNameInput"
            />
            {inputError && <p className="error">{inputError}</p>}
        </Modal>
    );
}
