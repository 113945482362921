import React from "react";
import "../aivr.scss";
import logo from "../images/new-branding-2.0/aivr-hybrid.svg";
import placeholder from "../images/placeholder.png";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";
import { AreaChart, Area, CartesianGrid, Tooltip, ReferenceLine } from "recharts";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import YAxisComponent from "./YAxisComponent";
import { connect } from "react-redux";
import { getClassifications, logout, getSessionData } from "redux/actions/index";
import LoadingOverlay from "./util/LoadingOverlay";
import { routeSelected } from "../redux/actions/index";
import { Select, Tooltip as AntdTooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Brush from "./AnalyticsBrushComponent";
import { convertToTimezone } from "./util/TimezoneUtils";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
const { Option } = Select;

class AnalyticsComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sessionID: null,
            classification: null,
            imageSrc: null,
            selectedImage: placeholder,
            selectedScore: null,
            selectedLabel: "",
            loading: true,
            chartData: [],
            calculatedBrushPosition: 0,
            sessionData: null,
        };

        this.chartData = [];
    }

    componentDidMount() {
        this.componentDidUpdate({}, {}, null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match !== prevProps.match) {
            if (this.props.access_token && this.props.match.params.sessionID) {
                let session_id = Number(this.props.match.params.sessionID);
                let type = this.props.match.params.type;
                this.props
                    .getSessionData(session_id)
                    .then((sessionData) => {
                        this.setState({
                            sessionID: session_id,
                            classification: type,
                            loading: true,
                            noData: false,
                            imageSrc: null,
                            selectedImage: placeholder,
                            selectedScore: null,
                            selectedLabel: "",
                            sessionData,
                        });
                    })
                    .catch(() => {
                        this.setState({ noData: true, loading: false });
                    });
            } else {
                this.setState({
                    noData: true,
                    loading: false,
                });
            }
        }

        let session = this.state.sessionData;
        if (!session) {
            return;
        }
        let classifications = session.displayable_classifications;

        if (_.indexOf(classifications, this.state.classification) === -1) {
            console.log("Classification not in classifications list");
            if (classifications.length) {
                this.selectClassification(classifications[0]);
            } else {
                console.log("Classifications list is empty");
                this.setState({ noData: true, loading: false });
            }
            return;
        }

        if (this.props.videoData.routeID !== this.state.sessionID) {
            console.log("Getting playlist data");
            this.props.getRouteData(this.state.sessionID);
            return;
        }

        if (!this.props.videoData.video) {
            return;
        }

        if (this.props.classifications === null || this.state.classification !== prevState.classification) {
            console.log("Getting classification data");
            this.props.getClassifications(session.uuid, this.state.classification);
            if (this.state.chartData.length) {
                this.setState({ chartData: [] });
                this.chartData = [];
            }
        } else if (this.props.classifications !== prevProps.classifications) {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    noData: false,
                    imageSrc: null,
                    selectedImage: placeholder,
                    selectedScore: null,
                    selectedLabel: "",
                });
            }
            setTimeout(this.generateChartData, 10);
        }
    }

    generateImage = (evt) => {
        if (evt !== null) {
            let selectedLabel = evt.activePayload[0].payload.name;
            let selectedScore = evt.activePayload[0].payload.score;

            let sessionID = this.state.sessionID;
            let sessionUUID = this.props.sessions[sessionID].uuid;
            let deviceKey = this.props.sessions[sessionID].device_uuid;
            let selectedImage = `https://raw${MEMOIZED_DOMAIN_URL}/` + deviceKey + "/" + sessionUUID + "/" + selectedLabel + ".jpg";
            if (this.props.csrfToken) {
                selectedImage += `?csrf=${this.props.csrfToken}`;
            }
            this.setState({
                selectedImage,
                selectedLabel,
                selectedScore,
                sessionID,
            });
        }
        return this.renderDetails(evt);
    };

    calculateChartWidth = (arrLength) => {
        let calculatedChartWidth = arrLength * 8;
        return calculatedChartWidth;
    };

    generateChartData = () => {
        let startIndex = this.chartData.length;
        let endIndex = Math.min(this.props.videoData.video[0].length, startIndex + 200);
        console.log("Generating chart data between indexes: ", startIndex, endIndex);

        let allTimestamps = this.props.videoData.video[0];
        for (let i = startIndex; i < endIndex; i++) {
            let element = allTimestamps[i];
            let videoStamp = element[0];

            if (this.props.classifications[videoStamp]) {
                this.chartData.push({
                    name: videoStamp,
                    score: this.props.classifications[videoStamp],
                    non: 100 - this.props.classifications[videoStamp],
                });
            } else {
                this.chartData.push({
                    name: videoStamp,
                    score: 0,
                    non: 100,
                });
            }
        }

        if (this.chartData.length === this.props.videoData.video[0].length) {
            this.setState({
                chartData: this.chartData,
                noData: this.chartData.length === 0,
                loading: false,
            });
        } else {
            setTimeout(this.generateChartData, 10);
        }
    };

    handleChartScrollPos = () => {
        let chart = document.getElementById("toBeScrolled");
        let chartWidth = this.state.chartData.length * 8;
        let calculatedBrushPosition = (chart.scrollLeft / chartWidth) * 900;
        this.setState({
            calculatedBrushPosition,
        });
    };

    generateChart = () => {
        const chartData = this.state.chartData;

        return (
            <div
                id="toBeScrolled"
                className="ChartWrapper"
                onScroll={this.handleChartScrollPos}>
                <YAxisComponent />
                <AreaChart
                    width={this.calculateChartWidth(chartData.length)}
                    height={250}
                    data={chartData}
                    onClick={(e) => this.generateImage(e)}
                    stackOffset="silhouette"
                    baseValue={5}
                    margin={{ top: 5 }}>
                    <Tooltip
                        isAnimationActive={false}
                        content={this.customTooltip}
                    />
                    <defs>
                        <linearGradient
                            id="barColour"
                            x1="0"
                            y1="1"
                            x2="0"
                            y2="0">
                            <stop
                                offset="0%"
                                stopColor="#FF0000"
                                stopOpacity={0.35}
                            />
                            <stop
                                offset="50%"
                                stopColor="#E45353"
                                stopOpacity={1}
                            />
                            <stop
                                offset="70%"
                                stopColor="#D88E4A"
                                stopOpacity={1}
                            />
                            <stop
                                offset="80%"
                                stopColor="#BBD84A"
                                stopOpacity={1}
                            />
                            <stop
                                offset="100%"
                                stopColor="#77D84A"
                                stopOpacity={1}
                            />
                        </linearGradient>
                    </defs>
                    <ReferenceLine
                        y={0}
                        stroke="white"
                        strokeWidth={2}
                    />
                    <CartesianGrid vertical={false} />
                    <Area
                        type="step"
                        dataKey="score"
                        connectNulls={true}
                        stroke="none"
                        stackId="1"
                        fillOpacity={1}
                        fill="url(#barColour)"
                        isAnimationActive={false}
                    />
                    <Area
                        type="step"
                        dataKey="non"
                        connectNulls={true}
                        fillOpacity={0}
                        stackId="1"
                        fill="rgba(0,0,0,0);"
                        isAnimationActive={false}
                    />
                </AreaChart>
            </div>
        );
    };

    customTooltip = (props) => {
        if (props.payload[0] != null && props.payload[0].payload.name) {
            const keySections = props.payload[0].payload.name.split(/\./);
            const timeSection = keySections.filter((section) => section.startsWith("t"))[0];
            const dpDate = new Date(parseInt(timeSection.slice(1), 16));

            const niceDate = convertToTimezone(dpDate, this.props.userConfig.convert_to_utc);

            const newPayload = [
                {
                    name: "Time",
                    value: niceDate,
                },
                {
                    name: "Score",
                    value: `${props.payload[0].payload.score}%`,
                },
            ];
            return (
                <DefaultTooltipContent
                    {...props}
                    payload={newPayload}
                />
            );
        }
        return <DefaultTooltipContent {...props} />;
    };

    renderImage = () => {
        return (
            <img
                alt=""
                src={this.state.selectedImage}
                crossOrigin={"anonymous"}
            />
        );
    };

    logout = () => {
        this.props.logout();
        this.props.history.push("/");
    };

    renderDetails = () => {
        if (this.state.selectedLabel) {
            let slug = `/view/${this.state.sessionID}/${this.state.selectedLabel}`;
            return (
                <div className="ImageDetails">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>Score: </span>
                        <span style={{ marginLeft: 5 }}>{this.state.selectedScore}%</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Link
                            className="ESBackButton"
                            style={{ marginTop: 0 }}
                            to={slug}>
                            View
                        </Link>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    selectClassification = (newValue) => {
        this.props.history.push(`/analytics/${this.state.sessionID}/${newValue}`);
    };

    render() {
        let content = null;
        if (!_.isEmpty(this.props.classifications)) {
            let session = this.props.sessions[this.state.sessionID];
            if (session) {
                let classifications = session.displayable_classifications;
                let chartWidth = this.state.chartData.length * 8;
                content = (
                    <>
                        <div className="FoliageChartWrapper">
                            <Select
                                className={"FoliageChartClassificationSelect"}
                                onChange={this.selectClassification}
                                value={this.state.classification}>
                                {classifications.map((c) => (
                                    <Option
                                        key={c}
                                        value={c}>
                                        {c}
                                    </Option>
                                ))}
                            </Select>
                            <div className="FoliageChartInner">
                                <div className="Legend">
                                    <div className="LegendItem">
                                        <div
                                            className="Key"
                                            style={{ background: "#E45353" }}
                                        />
                                        <span>None</span>
                                    </div>
                                    <div className="LegendItem">
                                        <div
                                            className="Key"
                                            style={{ background: "#D88E4A" }}
                                        />
                                        <span>Low</span>
                                    </div>
                                    <div className="LegendItem">
                                        <div
                                            className="Key"
                                            style={{ background: "#BBD84A" }}
                                        />
                                        <span>Medium</span>
                                    </div>
                                    <div className="LegendItem">
                                        <div
                                            className="Key"
                                            style={{ background: "#77D84A" }}
                                        />
                                        <span>High</span>
                                    </div>
                                </div>
                                {this.generateChart()}
                                <Brush
                                    data={this.state.chartData}
                                    chartWidth={chartWidth}
                                    position={this.state.calculatedBrushPosition}
                                />
                            </div>
                            <div className="ImageWrapper">
                                {this.renderImage()}
                                {this.renderDetails()}
                            </div>
                        </div>
                    </>
                );
            }
        } else if (!this.state.loading) {
            content = (
                <>
                    <div className="FoliageChartWrapper">
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: 22, color: "white", fontWeight: "bold" }}>Data not found</span>
                            <Link
                                to="/"
                                style={{ color: "white" }}>
                                <button className="ESBackButton">Go Back</button>
                            </Link>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <div
                className="App"
                id="adminAppDiv">
                <div
                    className="NavBar"
                    style={{ zIndex: 9999 }}>
                    <img
                        className="NavBarLogo"
                        src={logo}
                        alt="AIVR Logo"
                        crossOrigin={"anonymous"}
                    />
                    <div id="menuBarSeperator" />
                    <Link
                        to="/"
                        style={{ width: "100%" }}>
                        <AntdTooltip
                            title="Back"
                            placement="right">
                            <div className="SidebarButtonContainer">
                                <FontAwesomeIcon
                                    color="#e8dfff"
                                    icon={faArrowLeft}
                                    size="2x"
                                />
                            </div>
                        </AntdTooltip>
                    </Link>
                    <AntdTooltip
                        title="Log out"
                        placement="right">
                        <div
                            className="SidebarButtonContainer"
                            onClick={this.logout}>
                            <FontAwesomeIcon
                                color="#e8dfff"
                                icon={faSignOutAlt}
                                size="2x"
                            />
                        </div>
                    </AntdTooltip>
                </div>
                {content}
                <LoadingOverlay loading={this.state.loading} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSessionData: (session_id) => dispatch(getSessionData(session_id)),
        getRouteData: (sessionID) => dispatch(routeSelected(sessionID, 0)),
        getClassifications: (sessionKey, classificationType) => dispatch(getClassifications(sessionKey, classificationType)),
        logout: () => dispatch(logout()),
    };
};

const mapStateToProps = (state) => ({
    access_token: state.access_token,
    classifications: state.classifications,
    sessions: state.sessions,
    videoData: state.playlist.data,
    userConfig: state.userDetails.userConfig,
    csrfToken: state.csrfToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalyticsComponent));
