import { Tag, Divider } from "antd";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSessionDateFilter, setSessionSearch, setSessionTagFilter } from "redux/actions";
import _ from "lodash";
import { CalendarOutlined, SearchOutlined, TagOutlined } from "@ant-design/icons";

const sessionSearchSelector = (state) => state.sessionListFilters.search;
const tagFiltersSelector = (state) => state.sessionListFilters.tags;
const dateFilterSelector = (state) => state.sessionListFilters.date;
const detectionTypesSelector = (state) => state.detectionTypes;
const currentDashboardSelector = (state) => _.find(state.dashboards, (dashboard) => dashboard.access_token === state.access_token);

const iconMap = {
    calendar: <CalendarOutlined style={{ paddingRight: "3px" }} />,
    search: <SearchOutlined style={{ paddingRight: "3px" }} />,
    tag: <TagOutlined style={{ paddingRight: "3px" }} />,
};

const SessionListFilterPill = ({ showText = false, textValue = "Current filters:", showDivider = false }) => {
    const dispatch = useDispatch();
    const sessionSearch = useSelector(sessionSearchSelector);
    const tagFilters = useSelector(tagFiltersSelector);
    const dateFilter = useSelector(dateFilterSelector);
    const currentDashboard = useSelector(currentDashboardSelector);
    const detectionTypes = useSelector(detectionTypesSelector);

    const removeTagFromActiveList = useCallback(
        (tag) => {
            const clearSessionSearch = sessionSearch === tag.name;

            const newTags = _.filter(tagFilters, (_tag) => _tag !== tag.name);

            if (!_.isEqual(tagFilters, newTags)) {
                dispatch(setSessionTagFilter(newTags));
            }

            if (clearSessionSearch) {
                dispatch(setSessionSearch(""));
            }

            if (tag.type === "calendar") {
                dispatch(setSessionDateFilter({ from: null, to: null }));
            }
        },
        [dispatch, sessionSearch, tagFilters],
    );

    const activeFilterButton = useMemo(() => {
        let _tagFilters = _.map(tagFilters, (tag) => {
            const detectionType = _.find(detectionTypes, { type: tag });
            return { name: tag, type: "tag", display_name: _.get(detectionType, ["display_name"]) };
        });
        if (sessionSearch.length) {
            _tagFilters.unshift({
                name: sessionSearch,
                type: "search",
            });
        }

        if (dateFilter.from && dateFilter.to && !["widget_layout", "minimal_layout"].includes(currentDashboard?.workspace_layout)) {
            const fromDate = moment.unix(dateFilter.from).format("DD/MM/YYYY");
            const toDate = moment.unix(dateFilter.to).format("DD/MM/YYYY");
            _tagFilters.unshift({
                name: `${fromDate} - ${toDate}`,
                type: "calendar",
            });
        }

        if (_tagFilters.length) {
            return (
                <>
                    {" "}
                    {_.map(_tagFilters, (tag) => {
                        return (
                            <Tag
                                key={tag.name}
                                closable
                                onClose={() => removeTagFromActiveList(tag)}>
                                {iconMap[tag.type]}
                                <span>{tag.display_name || tag.name}</span>
                            </Tag>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    }, [dateFilter.from, dateFilter.to, removeTagFromActiveList, sessionSearch, tagFilters, detectionTypes]);

    return (
        <>
            {!!activeFilterButton && showText && <span>{textValue}</span>}
            {activeFilterButton}
            {!!activeFilterButton && showDivider && <Divider />}
        </>
    );
};

export default SessionListFilterPill;
