import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";
import { logEvent, swapDashboardAudit } from "./auditActions";
import { isMobile } from "react-device-detect";
import { notification } from "antd";
import { getAbsoluteTimestamp } from "../../components/util/PlaylistUtils";
import { calculateRouteCoordinatesForLocation } from "../../components/util/Geometry";
import {
    setVideoSpeed,
    setSessionFavourites,
    clearRequestedContent,
    setDefaultVideoOverlaysSettings,
    sessionsList,
    updateSessions,
    setHideLowQualitySessions,
    setSessionTagFilter,
} from "./sessionActions";
import { fetchDetectionTypes, requestAsset, requestIssue } from "./markerActions";

export const WS_SEND = "WS_SEND";
export const USER_CONFIG = "USER_CONFIG";
export const WIDGET_USER_CONFIG = "WIDGET_USER_CONFIG";
export const USER_ORGANISATION_PREFIX = "USER_ORGANISATION_PREFIX";
export const RESET_USER_ORGANISATION_PREFIX = "RESET_USER_ORGANISATION_PREFIX";
export const KEY_TYPE = "KEY_TYPE";
export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";
export const SET_PREFERENCE = "SET_PREFERENCE";
export const WORKSPACE_USERS = "WORKSPACE_USERS";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const SUBMIT_NAME = "SUBMIT_NAME";
export const TOGGLE_DESKTOP = "TOGGLE_DESKTOP";
export const WS_STATUS_POLL = "WS_STATUS_POLL";
export const WS_CONNECT = "WS_CONNECT";
export const WS_CONNECTING = "WS_CONNECTING";
export const WS_CONNECTED = "WS_CONNECTED";
export const WS_DISCONNECT = "WS_DISCONNECT";
export const WS_WIDGET_PING = "WS_WIDGET_PING";
export const WS_DISCONNECTED = "WS_DISCONNECTED";
export const WIDGET_DATA = "WIDGET_DATA";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const WIDGET_KEY = "WIDGET_KEY";
export const USER_DETAILS = "USER_DETAILS";
export const VIEW_DATA = "VIEW_DATA";
export const EXIT_CURRENT_DASHBOARD = "EXIT_CURRENT_DASHBOARD";
export const DEVICE_STATUSES = "DEVICE_STATUSES";
export const USER_SEATS = "USER_SEATS";
export const RECEIVE_ASSET_TYPES = "RECEIVE_ASSET_TYPES";
export const ASSETS = "ASSETS";
export const RESET_LOGOUT = "RESET_LOGOUT";
export const TARGET_RESOURCE = "TARGET_RESOURCE";
export const DASHBOARD_CHOICE = "DASHBOARD_CHOICE";
export const GET_USER_FAVOURITE_CATEGORIES = "GET_USER_FAVOURITE_CATEGORIES";
export const SESSIONS_LIST = "SESSIONS_LIST";
export const RECEIVE_ACCCESS_TOKEN = "RECEIVE_ACCCESS_TOKEN";
export const ADMIN_PASSWORD = "ADMIN_PASSWORD";
export const DASHBOARD_WIDGET_KEY = "DASHBOARD_WIDGET_KEY";
export const DISMISS_MESSAGE = "DISMISS_MESSAGE";
export const RECEIVE_DATA_FILTERS = "RECEIVE_DATA_FILTERS";
export const RECEIVE_SESSION_CONFIG = "RECEIVE_SESSION_CONFIG";
export const CLEAR_SHARE_DETAILS = "CLEAR_SHARE_DETAILS";
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const SET_UPLOAD_TOKENS_LIST = "SET_UPLOAD_TOKENS_LIST";
export const UPLOAD_TOKENS_LIST_LOADING = "UPLOAD_TOKENS_LIST_LOADING";
export const SET_AUTOCOMPLETE_VALUES = "SET_AUTOCOMPLETE_VALUES";
export const PARENT_CONNECTED = "PARENT_CONNECTED";
export const SET_CURRENT_QA_TAB = "SET_CURRENT_QA_TAB";
export const SET_DISPLAY_USER_PREFERENCES = "SET_DISPLAY_USER_PREFERENCES";
export const TOGGLE_LOGGING = "TOGGLE_LOGGING";
export const RECEIVE_CSRF_TOKEN = "RECEIVE_CSRF_TOKEN";
export const RECEIVE_API_VERSION = "RECEIVE_API_VERSION";

export const toggleLogging = (enabled) => {
    return {
        type: SET_PREFERENCE,
        preferenceKey: "loggingEnabled",
        preferenceValue: enabled,
    };
};

export const parentConnected = (connected) => {
    return {
        type: PARENT_CONNECTED,
        connected,
    };
};

export const clearShareDetails = () => {
    return {
        type: CLEAR_SHARE_DETAILS,
    };
};

export const updateDashboardKey = (key) => {
    return {
        type: DASHBOARD_WIDGET_KEY,
        key,
    };
};

export function adminPassword(password) {
    return {
        type: ADMIN_PASSWORD,
        data: password,
    };
}

export const receiveAccessToken = (token) => {
    return {
        type: RECEIVE_ACCCESS_TOKEN,
        token,
    };
};

export function dashboardChoice(dashboards) {
    return {
        type: DASHBOARD_CHOICE,
        dashboards,
    };
}

export const userFavouriteCategories = (categories) => {
    return {
        type: GET_USER_FAVOURITE_CATEGORIES,
        categories,
    };
};

function viewData(views) {
    return {
        type: VIEW_DATA,
        views,
    };
}

export function targetResource(target_resource) {
    return {
        type: TARGET_RESOURCE,
        target_resource,
    };
}

function resetLogout() {
    return {
        type: RESET_LOGOUT,
    };
}

export function deviceStatuses(statuses) {
    return {
        type: DEVICE_STATUSES,
        statuses,
    };
}

function receiveAssetTypes(types) {
    return {
        type: RECEIVE_ASSET_TYPES,
        types,
    };
}

function userConfig(userConfig) {
    return {
        type: USER_CONFIG,
        userConfig,
    };
}

export function widgetUserConfig(userConfig) {
    return {
        type: WIDGET_USER_CONFIG,
        userConfig,
    };
}

function userOrganisationPrefix(userOrganisationPrefix) {
    return {
        type: USER_ORGANISATION_PREFIX,
        userOrganisationPrefix,
    };
}

function userPrimaryKey(isPrimaryKey) {
    return {
        type: KEY_TYPE,
        isPrimaryKey,
    };
}

export const updateWindowDimensions = (dimensions) => {
    return {
        type: UPDATE_DIMENSIONS,
        dimensions,
    };
};

export function setUserPreference(preferenceKey, preferenceValue) {
    return {
        type: SET_PREFERENCE,
        preferenceKey,
        preferenceValue,
    };
}

const receiveWorkspaceUsers = (users) => {
    return {
        type: WORKSPACE_USERS,
        users,
    };
};

function termsAccepted() {
    return {
        type: ACCEPT_TERMS,
    };
}

function nameSubmitted(usersName, remainHidden) {
    return {
        type: SUBMIT_NAME,
        usersName,
        remainHidden,
    };
}

export const toggleDesktop = (value) => {
    return {
        type: TOGGLE_DESKTOP,
        value,
    };
};

export function wsConnect(ws_token) {
    return {
        type: WS_CONNECT,
        ws_token,
    };
}

export function wsConnecting(ws_token) {
    return {
        type: WS_CONNECTING,
        ws_token,
    };
}

export function wsConnected(ws_token) {
    return {
        type: WS_CONNECTED,
        ws_token,
    };
}

export const websocketMessage = (payload, target = null) => {
    return {
        type: WS_SEND,
        msg: payload,
        target,
    };
};

export function wsDisconnect(ws_token) {
    return {
        type: WS_DISCONNECT,
        ws_token,
    };
}

export function wsDisconnected(ws_token) {
    return {
        type: WS_DISCONNECTED,
        ws_token,
    };
}

export function wsWidgetPing(widgetType) {
    return {
        type: WS_WIDGET_PING,
        widgetType,
    };
}

export function updateWidgetData(data) {
    return {
        type: WIDGET_DATA,
        data,
    };
}

export function wsStatusPoll() {
    return {
        type: WS_STATUS_POLL,
    };
}

export function setCurrentTab(tabName) {
    return {
        type: SET_CURRENT_TAB,
        tabName,
    };
}

export function setCurrentQaTab(option) {
    return {
        type: SET_CURRENT_QA_TAB,
        option,
    };
}

export function setDisplayUserPreferences() {
    return {
        type: SET_DISPLAY_USER_PREFERENCES,
    };
}

function setAutoCompleteValues(systemID, list) {
    return {
        type: SET_AUTOCOMPLETE_VALUES,
        systemID,
        list,
    };
}

export const handleRequestedContent = () => {
    return (dispatch, getState) => {
        const requestedContent = getState().requestedContent;
        // TODO Rewrite share details functionality to be handled in here
        if (requestedContent.type === "issue") {
            dispatch(requestIssue(requestedContent.data.id, requestedContent.data.observationID));
        } else if (requestedContent.type === "asset") {
            dispatch(requestAsset(requestedContent.data.id, requestedContent.data.observationID));
        }

        dispatch(clearRequestedContent());
    };
};

export function submitName(access_token, name, remainHidden) {
    console.log("Submitting name");
    return {
        queue: SUBMIT_NAME,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "user_update_config",
                access_token,
                userHidden: remainHidden,
            };

            if (!remainHidden) {
                postBody["name"] = name;
            }

            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("submit name", response);
                    // if(response.success){
                    //     dispatch(termsAccepted())
                    // }
                    dispatch(nameSubmitted(name, remainHidden));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error submitting name preferences", error);
                    next();
                });
        },
    };
}

export function getAssetTypes() {
    return {
        queue: ASSETS,
        callback: (next, dispatch, getState) => {
            console.log("Getting local assets");

            const currentDashboard = _.find(getState().dashboards, (dash) => dash.access_token === getState().access_token);

            if (!currentDashboard) {
                return next();
            }

            let postBody = {
                action: "get_asset_types",
            };

            const url = "/mapGeometry";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got asset types", response);
                    if (response.asset_types) {
                        dispatch(receiveAssetTypes(response.asset_types));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to get asset types", "An error occurred while fetching asset types", error);
                    next();
                });
        },
    };
}

function dashboardSelected({ access_token, access_id, permissions, email_address, organisation, description, branding, isBeta, config }, callback) {
    return (dispatch, getState) => {
        console.log("Dashboard selected: ", description, email_address);
        dispatch({
            type: ACCESS_TOKEN,
            token: access_token,
            permissions,
        });
        dispatch({
            type: USER_DETAILS,
            email: email_address,
            organisation,
            description,
            branding,
            dashboardAccessID: access_id,
            isDashboardBeta: isBeta,
        });
        dispatch(fetchViews());
        dispatch(getAssetTypes());
        dispatch(getFavouriteCategories(true));
        dispatch(setVideoSpeed(Number(_.get(config, "defaults_playbackSpeed", 1.0))));

        document.title = `AIVR: ${description}`;
        dispatch(logEvent("Login", "Log In"));
        if (callback) {
            callback();
        }
        const overlaysDefaults = _.get(config, ["overlays_defaults"], null);
        dispatch(setDefaultVideoOverlaysSettings(overlaysDefaults));

        const qaDefault = _.get(config, ["auto_qa"], false);
        dispatch(setHideLowQualitySessions(qaDefault));

        const autoTags = _.get(config, ["auto_tags"], []);
        if (autoTags && autoTags.length) {
            dispatch(setSessionTagFilter(autoTags));
        }
    };
}

function fetchViews() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_views",
        };
        let url = "/route";

        jsonPostV2(url, getState(), postBody, dispatch).then((response) => {
            if (_.isArray(response)) {
                dispatch(viewData(response));
            } else {
                dispatch(viewData([]));
            }
        });
    };
}

function logoutAPI() {
    return (dispatch, getState) => {
        let postBody = {
            action: "logout",
        };
        let url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch).then((response) => {
            console.log("Logout response");
        });
    };
}

export function logout() {
    return (dispatch, getState) => {
        const access_token = getState().access_token;
        if (access_token) {
            dispatch(logoutAPI());
            dispatch(logEvent("Login", "Log out"));
            dispatch(dashboardChoice(null));
            dispatch({
                type: EXIT_CURRENT_DASHBOARD,
            });
            document.title = "AIVR";
            dispatch(resetLogout());
        }
    };
}

export function changeDashboard() {
    return (dispatch, getState) => {
        let access_token = getState().access_token;
        if (access_token) {
            dispatch(logEvent("Login", "Switch Dashboard"));

            dispatch({
                type: EXIT_CURRENT_DASHBOARD,
            });
            document.title = "AIVR";
        }
    };
}

const receiveCSRFToken = (token) => {
    return {
        type: RECEIVE_CSRF_TOKEN,
        token,
    };
};

// This call is mostly used as a user status poll, as well as getting device status'
export function getDeviceStatuses(inactive = false) {
    let postBody = {
        action: "get",
    };

    let url = "/status";
    return {
        queue: DEVICE_STATUSES,
        callback: (next, dispatch, getState) => {
            let statusInfo = {
                selected_session: null,
                player_data: {},
                last_active: getState().userDetails.lastActive,
                inactive,
                mobile: isMobile,
            };
            let playlistState = getState().playlist;

            if (playlistState.data && playlistState.data.routeID) {
                statusInfo["selected_session"] = playlistState.data.routeID;
            }

            if (playlistState.position && playlistState.position.playerState) {
                statusInfo["player_data"]["player_state"] = playlistState.position.playerState;

                statusInfo["player_data"]["is_stills"] = playlistState.position.isStills;
                statusInfo["player_data"]["is_enhanced"] = playlistState.position.isEnhanced;
                statusInfo["player_data"]["current_index"] = playlistState.position.currentIndex;
            } else {
                statusInfo["player_data"]["player_state"] = "closed";
            }

            postBody["status_data"] = statusInfo;

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ statuses, csrf }) => {
                    if (_.isArray(statuses)) {
                        dispatch(deviceStatuses(statuses));
                    }

                    if (csrf) {
                        dispatch(receiveCSRFToken(csrf));
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve device statuses", "An error occurred while fetching device statuses", error);
                    next();
                });
        },
    };
}

export function swapDashboard(access_token, callback) {
    console.log("debug swapDashboard...");
    return (dispatch, getState) => {
        let selectedDashboard = _.find(getState().dashboards, (dashboard) => dashboard.access_token === access_token);
        if (selectedDashboard) {
            dispatch({
                type: EXIT_CURRENT_DASHBOARD,
            });
            dispatch(
                dashboardSelected(selectedDashboard, function () {
                    dispatch(swapDashboardAudit(access_token));
                    dispatch(fetchDetectionTypes());
                    if (callback) {
                        callback();
                    }
                }),
            );
        }
    };
}

export function selectDashboard(access_token) {
    console.log("selectDashboard...");
    return (dispatch, getState) => {
        let selectedDashboard = _.find(getState().dashboards, (dashboard) => dashboard.access_token === access_token);
        if (selectedDashboard) {
            dispatch(dashboardSelected(selectedDashboard));
        }
    };
}

export function emailPasswordLogin(loginData, target_resource_data, is_mobile, callback, asso_redirect = null) {
    return {
        queue: ACCESS_TOKEN,
        callback: (next, dispatch, getState) => {
            let postBody = {
                ...loginData,
                action: "user_login",
                target_resource_id: target_resource_data.id,
                target_resource_type: target_resource_data.type,
                target_workspace_id: target_resource_data.workspace_id,
                is_mobile,
                asso_redirect,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.jwt) {
                        callback(response);
                        next();
                        return;
                    } else if (asso_redirect) {
                        callback(false);
                    }

                    if (response.user_config) {
                        dispatch(userConfig(response.user_config));
                        const prefix = _.get(response.user_config, ["organisation", "prefix"], null);
                        dispatch(userOrganisationPrefix(prefix));

                        if (response.user_config.primary_access_token) {
                            dispatch(userPrimaryKey(response.user_config.primary_access_token));
                        } else if (response.primary_access_token) {
                            dispatch(userPrimaryKey(response.primary_access_token));
                        }
                    }

                    if (response.target_resource) {
                        dispatch(targetResource(response.target_resource));
                    } else if (target_resource_data) {
                        dispatch(targetResource(target_resource_data));
                    }

                    if (response.data_filter) {
                        dispatch({
                            type: RECEIVE_DATA_FILTERS,
                            filters: response.data_filter,
                        });
                    }

                    if (response.session_config) {
                        dispatch({
                            type: RECEIVE_SESSION_CONFIG,
                            config: response.session_config,
                        });
                    }

                    if (response.widget_key) {
                        dispatch({
                            type: WIDGET_KEY,
                            key: response.widget_key,
                        });
                    }

                    if (response.api_version) {
                        dispatch({
                            type: RECEIVE_API_VERSION,
                            version: response.api_version,
                        });
                    }

                    if (response.dashboards) {
                        if (callback) {
                            callback(true);
                        }
                        dispatch(dashboardChoice(response.dashboards));
                        if (response.dashboards.length === 1 || loginData.jwt) {
                            response = response.dashboards[0];
                            dispatch(
                                dashboardSelected(response, function () {
                                    dispatch(swapDashboardAudit(response.access_token));
                                }),
                            );
                        }
                    } else if (response.access_token) {
                        if (callback) {
                            callback(true);
                        }
                        dispatch(dashboardSelected(response));
                    } else if (callback) {
                        callback(false);
                    }
                    next();
                })
                .catch((response) => {
                    console.log("debug response", response);
                    if (callback) {
                        response.then((message) => {
                            callback(false, message);
                        });
                    }
                    next();
                });
        },
    };
}

export function resetUserOrganisationPrefix() {
    return {
        type: RESET_USER_ORGANISATION_PREFIX,
    };
}

export function requestPasswordReset(email, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "user_reset_password_email",
            email,
        };
        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                // check if reset request has been fulfilled
                if (response.success) {
                    callback(true);
                } else {
                    notification.error({
                        message: "Unable to request password reset",
                        description: response.message ?? "An error occurred while requesting password reset",
                        duration: 6,
                    });
                    callback(false);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to request password reset", "An error occurred while requesting password reset", error);
                callback(false);
            });
    };
}

export function updateUserPassword(token, new_password, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "user_reset_password",
            reset_token: token,
            new_password,
        };
        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("response:", response);
                callback(true);
            })
            .catch((error) => {
                handleJsonPostError("Unable to update password", "An error occurred while updating your password", error);
                callback(false);
            });
    };
}

export function accessTokenLogin(access_token, callback, target_resource_data = {}, asso_redirect = null) {
    return {
        queue: ACCESS_TOKEN,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "user_login",
                access_token,
                target_resource_id: target_resource_data.id,
                target_resource_type: target_resource_data.type,
                target_workspace_id: target_resource_data.workspace_id,
                asso_redirect,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.dashboards || response.access_token) {
                        if (response.user_config) {
                            dispatch(userConfig(response.user_config));
                        }

                        if (response.user_config.primary_access_token) {
                            dispatch(userPrimaryKey(response.user_config.primary_access_token));
                        } else if (response.primary_access_token) {
                            dispatch(userPrimaryKey(response.primary_access_token));
                        }
                        if (response.target_resource) {
                            dispatch(targetResource(response.target_resource));
                        } else if (target_resource_data) {
                            dispatch(targetResource(target_resource_data));
                        }

                        if (response.session_config) {
                            dispatch({
                                type: RECEIVE_SESSION_CONFIG,
                                config: response.session_config,
                            });
                        }

                        if (response.api_version) {
                            dispatch({
                                type: RECEIVE_API_VERSION,
                                version: response.api_version,
                            });
                        }

                        dispatch(fetchViews());

                        if (response.dashboards) {
                            dispatch(dashboardChoice(response.dashboards));

                            let selectedDashboard;
                            if (response.dashboards.length === 1) {
                                selectedDashboard = response.dashboards[0];
                            } else {
                                selectedDashboard = _.find(response.dashboards, (dashboard) => dashboard.access_token === access_token);
                            }

                            // was a specific resource requested in the login call,
                            // and if access has been granted do we need to select a
                            // different dashboard and trigger a refresh because
                            // the access token will have changed too
                            if (response.target_resource && response.target_resource.access_granted === true) {
                                let required_access_id = response.target_resource.required_access_id;
                                let target_dashboard = _.find(response.dashboards, { access_id: required_access_id });
                                if (target_dashboard && target_dashboard.access_token !== access_token) {
                                    dispatch(swapDashboard(target_dashboard.access_token, () => callback(true)));
                                    selectedDashboard = false;
                                } else {
                                    if (callback) {
                                        callback(true);
                                    }
                                }
                            } else if (!_.isEmpty(target_resource_data)) {
                                if (["Annotation", "Measurement", "Sketch", "Bookmark"].includes(target_resource_data.type)) {
                                    const target_dashboard = _.find(response.dashboards, { access_id: target_resource_data.workspace_id });
                                    if (target_dashboard) {
                                        dispatch(
                                            dashboardSelected(target_dashboard, function () {
                                                dispatch(swapDashboardAudit(target_dashboard.access_token));
                                            }),
                                        );
                                        selectedDashboard = false;
                                    }
                                    if (callback) {
                                        callback(true);
                                    }
                                }
                            } else {
                                if (callback) {
                                    callback(true);
                                }
                            }

                            if (selectedDashboard) {
                                dispatch(dashboardSelected(selectedDashboard));
                            }
                        } else {
                            dispatch(dashboardSelected(response));
                        }
                    } else if (response.jwt) {
                        callback(response);
                    } else if (callback) {
                        callback(false);
                    }
                    next();
                })
                .catch((response) => {
                    if (callback) {
                        response.then((message) => {
                            callback(false, message);
                        });
                    }
                    next();
                });
        },
    };
}

export function updateUserConfig(updates, callback) {
    return {
        queue: USER_SEATS,
        callback: (next, dispatch, getState) => {
            console.log("Updating user config", updates);

            let newUserConfig = _.clone(getState().userDetails.userConfig);

            if (updates.introTourViewedSetting !== undefined) {
                newUserConfig.intro_tour_viewed = updates.introTourViewedSetting;
            }
            if (updates.elrUnitSetting !== undefined) {
                newUserConfig.elr_units = updates.elrUnitSetting;
            }
            if (updates.measurementUnitSetting !== undefined) {
                newUserConfig.measurement_units = updates.measurementUnitSetting;
            }
            if (updates.sightingUnitSetting !== undefined) {
                newUserConfig.sighting_units = updates.sightingUnitSetting;
            }
            if (updates.speedUnitSetting !== undefined) {
                newUserConfig.speed_units = updates.speedUnitSetting;
            }
            if (updates.bingMapSetting !== undefined) {
                newUserConfig.bing_map = updates.bingMapSetting;
            }
            if (updates.userTipsSetting !== undefined) {
                newUserConfig.user_tips = updates.userTipsSetting;
            }
            if (updates.dailyDigestSetting !== undefined) {
                newUserConfig.daily_digest = updates.dailyDigestSetting;
            }

            if (updates.viewIdSetting !== undefined) {
                newUserConfig.view_id = updates.viewIdSetting;
            }

            if (updates.convertUTC !== undefined) {
                newUserConfig.convert_to_utc = updates.convertUTC;
            }

            if (updates.name !== undefined) {
                newUserConfig.name = updates.name;
            }

            if (updates.userHidden !== undefined) {
                newUserConfig.user_hidden = updates.userHidden;
            }

            if (updates.primaryWorkspaceID !== undefined) {
                newUserConfig.primary_workspace_id = updates.primaryWorkspaceID;
            }

            if (updates.default_content_privacy !== undefined) {
                newUserConfig.default_content_privacy = updates.default_content_privacy;
            }

            if (updates.autoShowHistoryPopup !== undefined) {
                newUserConfig.auto_show_history_popup = updates.autoShowHistoryPopup;
            }

            if (updates.temperatureUnitSetting !== undefined) {
                newUserConfig.temperature_units = updates.temperatureUnitSetting;
            }

            if (getState().userDetails.isPrimaryKey || getState().shareLink) {
                notification.success({
                    message: "Successfully updated preferences",
                });
                dispatch(userConfig(newUserConfig));
                if (callback) {
                    callback();
                }
                next();
            } else {
                let postBody = {
                    action: "user_update_config",
                    ...updates,
                };

                const url = "/admin";

                jsonPostV2(url, getState(), postBody, dispatch)
                    .then((response) => {
                        if (updates.introTourViewedSetting === undefined) {
                            notification.success({
                                message: "Successfully updated preferences",
                            });
                        }
                        console.log("Successfully updated user config", response);
                        dispatch(userConfig(newUserConfig));
                        if (callback) {
                            callback();
                        }
                        next();
                    })
                    .catch((error) => {
                        handleJsonPostError("Unable to update user configuration", "An error occurred while updating your user configuration", error);
                        next();
                    });
            }
        },
    };
}

export function changeUserPassword(oldPassword, newPassword, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "user_change_password",
            old_password: oldPassword,
            new_password: newPassword,
        };
        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("response:", response);
                if (response.success) {
                    callback(false);
                } else {
                    callback(response.message);
                }
            })
            .catch((error) => {
                error.then((message) => {
                    callback(message);
                });
            });
    };
}

export function removeFavouriteCategory(categoryID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "remove_favourite_category",
            category_id: categoryID,
        };
        let url = "/sessions";

        const oldSessions = getState().sessions;
        const favCategories = getState().userDetails.favouriteCategories;
        const deletingCategoryObject = _.find(favCategories, { id: categoryID });

        const allSessionsInCategories = Object.keys(_.get(deletingCategoryObject, "session_data"));

        if (allSessionsInCategories.length) {
            let newSessions = _.clone(oldSessions);
            _.forEach(allSessionsInCategories, (session) => {
                const sessionID = parseInt(session);
                if (newSessions[sessionID]) {
                    try {
                        delete newSessions[sessionID].favourite;
                        delete newSessions[sessionID].favourite_category_id;
                    } catch (error) {
                        console.error("Unable to remove favourite and favourite_category_id from session:", sessionID);
                    }
                }
            });
            dispatch(updateSessions(newSessions));
        }

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((request) => {
                if (request.success) {
                    notification.success({
                        message: "Successfully removed category",
                    });
                    dispatch(getFavouriteCategories(true));
                    dispatch(setSessionFavourites(true));
                }
            })
            .catch((error) => {
                dispatch(sessionsList(oldSessions));
                handleJsonPostError("Unable to remove category", error);
            });
    };
}

export function getFavouriteCategories(global = false, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_favourite_categories",
            global,
        };

        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((categories) => {
                if (categories.success) {
                    dispatch(userFavouriteCategories(categories.favourite_categories));
                }
                if (callback) {
                    callback(true);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to retrieve user categories", error);
                if (callback) {
                    callback(false);
                }
            });
    };
}

export function getWorkspaceUsers() {
    return {
        queue: WORKSPACE_USERS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_workspace_users",
            };

            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    dispatch(receiveWorkspaceUsers(response.users));
                    next();
                })
                .catch((error) => {
                    console.log("ERROR DOWN here", error);
                    handleJsonPostError("Unable to fetch workspace users", error);
                    next();
                });
        },
    };
}

export function acceptTerms(access_token) {
    return {
        queue: ACCEPT_TERMS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "accept_terms",
                access_token,
            };

            let url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Accept terms response", response);
                    if (response.success) {
                        dispatch(termsAccepted());
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error accepting terms and conditions", error);
                    next();
                });
        },
    };
}

export function submitUserSuggestion(submission, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "submit_suggestion",
            submission,
        };

        let url = "/admin";

        return jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("submit report request", response);
                if (response.success) {
                    callback(true);
                } else {
                    callback(false);
                }
            })
            .catch((error) => {
                handleJsonPostError("Unable to submit suggestion", "An error occurred while submitting suggestion", error);

                callback(false);
            });
    };
}

export function submitBugReport(description, additionalInformation, callback) {
    return {
        queue: USER_DETAILS,
        callback: (next, dispatch, getState) => {
            console.log("submitting report");

            let postBody = {
                action: "bug_report",
                description,
                metadata: {
                    user_agent: window.navigator.userAgent,
                    window_dimensions: {
                        width: window.innerWidth,
                        height: window.innerHeight,
                    },
                    selected_session: _.get(getState(), "playlist.data.routeID", null),
                    ...additionalInformation,
                    dashboard_id: _.get(getState(), "userDetails.dashboardAccessID", null),
                    operating_system: window.navigator.platform,
                    is_stills: _.get(getState(), "playlist.position.isStills", false),
                    mode: _.get(getState(), "markup.tool_mode", false) || "player",
                },
            };

            let state = getState();
            if (state.playlist.data.routeID) {
                const routeID = state.playlist.data.routeID;
                const isVideo = state.playlist.position.isVideo;
                const sourceIndex = state.playlist.position.sourceIndex;
                const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
                const index = state.playlist.position.currentIndex;
                const offset = state.playlist.position.currentTimeOffset || 0;
                const timestamp = getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
                const routeLocationData = state.playlist.data.route_locations;
                const routeSystemID = state.playlist.data.system_id;

                let location = calculateRouteCoordinatesForLocation(timestamp, routeLocationData, routeSystemID);
                if (location) {
                    postBody.metadata.location = location.to_string();
                }
            }

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("submit report request", response);
                    if (response.success) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to report problem", "An error occurred while submitting report", error);

                    callback(false);

                    next();
                });
        },
    };
}

export function validateResetToken(token) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "validate_reset_token",
                token,
            };

            let url = "/admin";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response.status);
                })
                .catch((error) => {
                    reject();
                });
        });
    };
}

export function setVirtualCabRideEmail(email) {
    return (dispatch, getState) => {
        let postBody = {
            action: "save_virtual_cab_email",
            email,
        };

        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    console.log("Saved Cab Rider Email");
                }
            })
            .catch(() => {
                console.log("Error saving Cab Rider Email");
            });
    };
}

export function dismissMessage(message_id) {
    return {
        queue: DISMISS_MESSAGE,
        callback: (next, dispatch, getState) => {
            const postBody = {
                action: "dismiss_message",
                id: message_id,
            };

            dispatch({
                type: DISMISS_MESSAGE,
                message_id: message_id,
            });

            const url = "/admin";
            jsonPostV2(url, getState(), postBody)
                .catch((error) => console.log("Error dismissing message", error))
                .finally(next);
        },
    };
}

export function receiveLoginFilters(filters) {
    return {
        type: RECEIVE_DATA_FILTERS,
        filters,
    };
}

export function getAutoCompleteValuesBySystemID(systemID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_all_elrs",
            system_id: systemID,
        };

        let elrsList = getState().searchLocationData.autoCompleteValues;
        const url = "/mapGeometry";

        // if elrsList for that systemID already populated avoid fetching again
        if (elrsList[systemID] && elrsList[systemID].length > 0) {
            return;
        }

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.elrs) {
                    dispatch(setAutoCompleteValues(systemID, response.elrs));
                }
            })
            .catch(() => {
                console.log("debug error while fetching AutoCompleteValues...");
            });
    };
}

export function getUserSurveyOptions() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_user_survey_options",
            };
            let url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    console.log("Error getting survery options");
                    reject(error);
                });
        });
    };
}

export function getUserSurveyChoices() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_user_survey_choices",
            };
            let url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        resolve({});
                    }
                })
                .catch((error) => {
                    console.log("Error getting survey choices");
                    reject(error);
                });
        });
    };
}

export const setUserSurveyChoices = (userSelection) => {
    return (dispatch, getState) => {
        let postBody = {
            action: "set_user_survey_choices",
            userSelection,
        };

        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response && response.message) {
                    console.log(response.message);
                }
            })
            .catch(() => {
                console.log("Error submitting user selection");
            });
    };
};

export const skipUserPreferenceSurvey = () => {
    return (dispatch, getState) => {
        let postBody = {
            action: "skip_user_preference_survey",
        };

        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response && response.message) {
                    console.log(response.message);
                }
            })
            .catch(() => {
                console.log("Error skipping user selection");
            });
    };
};

export const getCustomLoginDetails = (domainPrefix, callback) => {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_custom_login_details",
            domain_prefix: domainPrefix,
        };

        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response && response.success) {
                    // console.log("debug response", response)
                    callback(response);
                } else {
                    // console.log("debug NOT AVAILABLE!", response);
                    callback(response);
                }
            })
            .catch(() => {
                console.log("Error getting cutom login details...");
            });
    };
};

export const sendDataCaptureRequest = (message, callback) => {
    return (dispatch, getState) => {
        let postBody = {
            action: "data_capture_request",
            message: message,
        };

        const url = "/admin";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response && response.success) {
                    callback(response);
                } else {
                    callback(response);
                    notification.error({
                        message: "Error",
                        description: "Unable to send request, please try again",
                    });
                }
            })
            .catch(() => {
                callback({ success: false });
                notification.error({
                    message: "Error",
                    description: "Unable to send request, please try again",
                });
            });
    };
};
