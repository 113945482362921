import React from "react";
import "../../mobile.scss";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { logout, routeSelected, targetResource, favouriteSession } from "redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import MobilePlayerPage from "./MobilePlayerPage";
import { Modal } from "antd";
import LoadingOverlay from "../util/LoadingOverlay";
import InvalidShareLinkComponent from "../InvalidShareLinkComponent";
import { faStar as faStarOutline } from "@fortawesome/pro-regular-svg-icons";
import { convertToTimezone } from "../util/TimezoneUtils";

class MobileSessionViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);

        this.state = {
            loading: true,
            displayMode: "player",
        };
    }

    logout = () => {
        this.props.dispatch(logout());
        this.props.history.push("/mobile/login");
    };

    toggleDisplay = () => {
        let newdisplay = this.state.displayMode === "player" ? "map" : "player";
        this.setState({
            displayMode: newdisplay,
        });
    };

    navigateBack = () => {
        this.props.history.push("/mobile");
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.sessions !== this.props.sessions && this.props.sessions && this.props.match.params.sessionID) {
            this.props.dispatch(routeSelected(this.props.match.params.sessionID, 0));
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    dismissTargetResource = () => {
        this.props.history.push("/mobile");
        this.props.dispatch(targetResource(null));
    };

    favouriteSession = () => {
        let favourite = this.props.currentSession.favourite;
        this.props.dispatch(favouriteSession(this.props.currentSession.id, !favourite));
    };

    render() {
        let content = (
            <MobilePlayerPage
                display={this.state.displayMode}
                toggleDisplay={this.toggleDisplay}
            />
        );
        let routeName = "";
        let niceDate = "";

        if (this.props.currentSession) {
            routeName = this.props.currentSession.route_name;
            if (routeName.length > 22) {
                routeName = routeName.substring(0, 22) + "...";
            }
            const startDate = new Date(this.props.currentSession.first_seen * 1000);
            niceDate = convertToTimezone(startDate, this.props.userConfig.convert_to_utc);
        }

        if (this.props.shareLink) {
            if (!this.props.shareLinkDetails) {
                return <LoadingOverlay loading={true} />;
            } else if (!this.props.shareLinkDetails.sessionID) {
                return <InvalidShareLinkComponent />;
            }
        } else if (!this.props.access_token) {
            return <Redirect to={{ pathname: "/mobile/login" }} />;
        }

        let resource_access_denied = this.props.targetResource && this.props.targetResource.access_granted === false;

        let favIcon = (
            <FontAwesomeIcon
                className="nonFavouriteIcon"
                icon={faStarOutline}
                onClick={(e) => this.favouriteSession()}
                style={{ color: "#BFC5E2" }}
            />
        );

        if (this.props.currentSession) {
            if (this.props.currentSession.favourite) {
                favIcon = (
                    <FontAwesomeIcon
                        className="favouriteIcon"
                        icon={faStar}
                        onClick={(e) => this.favouriteSession()}
                    />
                );
            }
        }

        if (this.props.access_token) {
            return (
                <>
                    <Modal
                        title="Access Denied"
                        visible={resource_access_denied}
                        onOk={this.dismissTargetResource}
                        okText="Continue anyway"
                        onCancel={this.logout}
                        cancelText="Logout"
                        maskClosable={false}>
                        <p>Your user account does not have access to the shared content.</p>
                    </Modal>
                    <div className="mobileSessionWrapper">
                        <div className="mobileNavigationBlock">
                            <FontAwesomeIcon
                                className={"backNavigationIcon"}
                                icon={faArrowLeft}
                                onClick={this.navigateBack}
                            />
                            <div className="routeInfoContainer">
                                <span className="routeName">{routeName}</span>
                                <span className="routeDate">{niceDate}</span>
                            </div>
                            {favIcon}
                        </div>
                        {content}
                    </div>
                </>
            );
        } else {
            let redirect = { pathname: "/mobile/login" };

            return <Redirect to={redirect} />;
        }
    }
}

const mapStateToProps = (state) => {
    const dashboards = state.dashboards;
    const activeDashboard = _.find(dashboards, (dash) => state.access_token === dash.access_token);

    const currentSessionID = state.playlist.data.routeID;
    const currentSession = state.sessions[currentSessionID];

    return {
        userConfig: state.userDetails.userConfig,
        access_token: state.access_token,
        activeDashboard,
        sessions: state.sessions,
        currentSession,
        shareLink: state.shareLink,
        shareLinkDetails: state.shareLinkDetails,
        targetResource: state.targetResource,
        dashboards: state.dashboards,
        favouriteSessions: state.favouriteSessions,
    };
};

export default connect(mapStateToProps)(withRouter(MobileSessionViewer));
