import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";

export const ROUTE_HISTORY = "ROUTE_HISTORY";
export const CONSOLIDATED_HISTORY = "CONSOLIDATED_HISTORY";
export const ROUTE_HISTORY_TOGGLE = "ROUTE_HISTORY_TOGGLE";
export const FETCHING_HISTORY = "FETCHING_HISTORY";
export const SHOW_ROUTE_HISTORY = "SHOW_ROUTE_HISTORY";

export function consolidatedHistory(key, success, history) {
    return {
        type: CONSOLIDATED_HISTORY,
        key,
        success,
        history,
    };
}

export function routeHistory(key, success, history) {
    return {
        type: ROUTE_HISTORY,
        key,
        success,
        history,
    };
}

export function setRouteHistoryHidden(hidden) {
    return {
        type: ROUTE_HISTORY_TOGGLE,
        hidden,
    };
}

export const setFetchingHistory = () => {
    return {
        type: FETCHING_HISTORY,
    };
};

export const toggleShowHistory = (show) => {
    return {
        type: SHOW_ROUTE_HISTORY,
        show,
    };
};

export function routeHistoryLookup(deviceKey, sessionKey, videoKey) {
    return {
        queue: ROUTE_HISTORY,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "history",
                device_key: deviceKey,
                session_key: sessionKey,
                video_key: videoKey,
            };
            let url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.length) {
                        dispatch(
                            routeHistory(
                                videoKey,
                                true,
                                _.sortBy(response, (dp) => dp.timestamp),
                            ),
                        );
                    } else {
                        dispatch(routeHistory(videoKey, true, []));
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error finding route history", "An error occurred while searching route history", error);
                    dispatch(routeHistory(videoKey, false, []));
                    next();
                });
        },
    };
}

export function consolidatedRouteVideoLookup(route_location, callback) {
    return {
        queue: CONSOLIDATED_HISTORY,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "consolidated_history",
                system_id: route_location.system,
                route_id: route_location.route,
                route_position: route_location.position,
                route_subposition: route_location.subposition,
            };
            let url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    const most_recent = _.chain(response).filter({ sub_position: route_location.subposition }).maxBy("timestamp").value();
                    callback(most_recent === undefined, most_recent);
                })
                .catch((error) => {
                    handleJsonPostError("Error finding consolidated route history", "An error occurred while searching consolidated route history", error);
                    callback(error);
                });
            next();
        },
    };
}

export function consolidatedHistoryLookup(timestamp, route_location, same_track_only, qaFiltered) {
    return {
        queue: CONSOLIDATED_HISTORY,
        callback: (next, dispatch, getState) => {
            const fetchingHistory = getState().playlist.consolidatedHistory.fetching;
            if (fetchingHistory) {
                next();
                return;
            }
            dispatch(setFetchingHistory());

            let postBody = {
                action: "consolidated_history",
                system_id: route_location.system,
                route_id: route_location.route,
                route_position: route_location.position,
                qa_filtered: qaFiltered,
            };
            if (same_track_only && route_location.subposition !== null) {
                postBody.route_subposition = route_location.subposition;
            }
            let url = "/route";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    const state = getState();
                    if (response.length) {
                        const sessionConfig = state.userDetails.sessionConfig;
                        const forceFilters = _.get(sessionConfig, ["force_filters"], false);
                        let filteredHistory = response;
                        if (forceFilters) {
                            const sessionIds = state.sessionList;
                            filteredHistory = response.filter((historyItem) => {
                                return sessionIds.includes(historyItem.session_id);
                            });
                        }
                        dispatch(
                            consolidatedHistory(
                                timestamp,
                                true,
                                _.sortBy(filteredHistory, (dp) => dp.timestamp),
                            ),
                        );
                    } else {
                        dispatch(consolidatedHistory(timestamp, true, []));
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Error finding consolidated route history", "An error occurred while searching consolidated route history", error);
                    dispatch(consolidatedHistory(timestamp, false, []));
                });
            next();
        },
    };
}
