import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
    accessTokenLogin,
    emailPasswordLogin,
    requestPasswordReset,
    swapDashboard,
    selectDashboard,
    dashboardChoice,
    logout,
    targetResource,
    swapDashboardAudit,
    submitName,
    handleRequestedContent,
    getCustomLoginDetails,
} from "redux/actions/index";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import logo from "../images/new-branding-2.0/aivr-purple.svg";
import logoWhite from "../images/new-branding-2.0/aivr-hybrid.svg";
import { Button, Select, message, Modal, notification } from "antd";
import validator from "validator";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoNotEnter, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { isMobile } from "react-device-detect";
import MediaQuery from "react-responsive";
import TermsComponent from "./terms/TermsComponent";
import MicrosoftSSOButton from "./auth/MicrosoftSSOButton";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../ssoMsConfig";
import { MsalProvider } from "@azure/msal-react";
import Tippy from "@tippyjs/react";

const msalInstance = new PublicClientApplication(msalConfig);

class LoginComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            user_email: "",
            user_password: "",
            access_token: undefined,
            showEmailLogin: true,
            loginInProgress: false,
            lastLoginAttemptFailed: false,
            showForgotPassword: false,
            showResetRequestResult: false,
            resetRequestSuccessful: false,
            nameInput: "",
            hiddenInput: false,
            submitNameLoading: false,
            selectingDashboard: false,
            customLoginPageDetails: false,
        };
        console.log("debug props here", this.props);
    }

    validateEmail = () => {
        return validator.isEmail(this.state.user_email + "");
    };

    updateNameInput = (evt) => {
        this.setState({
            nameInput: evt.target.value,
        });
    };

    updateEmail = (evt) => {
        this.setState({
            user_email: evt.target.value,
            lastLoginAttemptFailed: false,
        });
    };

    updatePassword = (evt) => {
        this.setState({
            user_password: evt.target.value,
            lastLoginAttemptFailed: false,
        });
    };

    updateSelectedDashboard = (access_token) => {
        this.setState({
            access_token,
        });
    };

    updateAccessToken = (evt) => {
        this.setState({
            access_token: evt.target.value,
            lastLoginAttemptFailed: false,
        });
    };

    toggleLoginType = () => {
        if (!this.state.loginInProgress) {
            this.setState({
                showEmailLogin: !this.state.showEmailLogin,
                user_email: "",
                user_password: "",
                access_token: "",
                lastLoginAttemptFailed: false,
            });
        }
    };

    toggleForgotPassword = () => {
        if (!this.state.loginInProgress) {
            this.setState({
                showForgotPassword: !this.state.showForgotPassword,
            });
        }
    };

    requestResetPassword = (evt) => {
        evt.preventDefault();
        this.setState({
            resetRequestInProgress: true,
        });
        this.props.dispatch(
            requestPasswordReset(this.state.user_email, (success) => {
                // callback after request made
                // if success, then tell user to check their email
                // if failed, then tell the user there was a problem
                this.setState({
                    resetRequestInProgress: false,
                    resetRequestSuccessful: success,
                    showResetRequestResult: true,
                });
            }),
        );
    };

    reset = () => {
        this.setState({
            showForgotPassword: false,
            showResetRequestResult: false,
            user_password: "",
        });
    };

    cancelLogin = () => {
        this.dismissTargetResource();
        if (this.props.access_token) {
            this.props.dispatch(logout());
        } else {
            this.props.dispatch(dashboardChoice(null));
        }
        this.setState({
            loginInProgress: false,
        });
    };

    dismissTargetResource = () => {
        // at this point we also want to clear out props.location.state of
        this.props.history.replace("", null);
        this.props.dispatch(targetResource(null));
    };

    targetResourceDetails = () => {
        let target_resource_data = {};
        if (this.props.location.state && this.props.location.state.hasOwnProperty("target_resource_data")) {
            target_resource_data = this.props.location.state.target_resource_data;
        }
        return target_resource_data;
    };

    confirmEmailPasswordLogin = (evt) => {
        evt.preventDefault();
        this.setState({
            loginInProgress: true,
        });

        this.guessNameFromEmail();

        let is_mobile = false;
        if (isMobile) {
            is_mobile = true;
        }

        let target_resource_data = this.targetResourceDetails();

        // let loginFunction =

        let callbackFunction = this.loginAttemptComplete;
        if (this.props.loginCompleteCallback) {
            callbackFunction = this.props.loginCompleteCallback;
        }

        this.props.dispatch(
            emailPasswordLogin(
                {
                    email: this.state.user_email,
                    password: this.state.user_password,
                },
                target_resource_data,
                is_mobile,
                callbackFunction,
                this.props.ssoRedirect,
            ),
        );
    };

    guessNameFromEmail = () => {
        const email = this.state.user_email;

        const emailNamePart = email.split("@")[0];

        let name = emailNamePart;

        if (emailNamePart.includes(".")) {
            name = emailNamePart.split(".").join(" ");
        }

        name = this.titleCase(name);

        this.setState({
            nameInput: name,
        });
    };

    selectDashboard = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        this.props.dispatch(swapDashboard(this.state.access_token));
        this.setState({
            access_token: "",
        });
    };

    confirmAccessTokenLogin = (evt) => {
        evt.preventDefault();
        this.setState({
            loginInProgress: true,
        });

        let target_resource_data = this.targetResourceDetails();

        this.props.dispatch(accessTokenLogin(this.state.access_token, this.loginAttemptComplete, target_resource_data));
    };

    titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
    }

    submitNameConfig = (evt) => {
        evt.preventDefault();
        this.setState({
            submitNameLoading: true,
        });
        console.log("Submitting name");

        const nameInput = this.state.nameInput;
        const hiddenChecked = this.state.hiddenInput;

        this.props.dispatch(submitName(this.props.dashboards[0].access_token, nameInput, hiddenChecked));
    };

    loginAttemptComplete = (success, error) => {
        if (!success) {
            const info = () => {
                Modal.error({
                    title: "This account is archived",
                    content: (
                        <div>
                            Please contact <a href="mailto:support@onebigcircle.co.uk?subject=AIVR Account Reactivation">support</a> to reactivate.
                        </div>
                    ),
                    onOk() {},
                });
            };
            this.setState({
                loginInProgress: false,
            });

            if (error !== "User is Archived") {
                let timeout = 10;
                const errorMessage = error;
                this.setState({
                    lastLoginAttemptFailed: true,
                });
                message.error(errorMessage, timeout);
            } else {
                info();
            }
        } else {
            this.props.dispatch(handleRequestedContent());
        }
    };

    onHideUserChange = (evt) => {
        this.setState({
            hiddenInput: evt.target.checked,
        });
    };

    findAndSelectPrimaryWorkspace = () => {
        if (this.props.userConfig.primary_workspace_id) {
            const primary_workspace = _.find(this.props.dashboards, { access_id: this.props.userConfig.primary_workspace_id });
            if (primary_workspace) {
                this.setState({
                    access_token: primary_workspace.access_token,
                });
            }
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener("keydown", this.onKeyDown);
    };

    componentDidMount = () => {
        window.addEventListener("keydown", this.onKeyDown);

        if (this.props.match.params.type && this.props.match.params.type === "forgot") {
            this.setState({
                showForgotPassword: true,
            });
        }

        if (_.get(this.props.location, ["state", "forcedLogout"])) {
            window.history.replaceState({}, "");
            message.error("You have automatically been logged out");
        }

        // if custom login domain_prefix
        if (this.props.match.params.domain_prefix || this.props.domainPrefixFromASSO) {
            let domainPrefix = this.props.match.params.domain_prefix || this.props.domainPrefixFromASSO;
            this.props.dispatch(
                getCustomLoginDetails(domainPrefix, (response) => {
                    let workspaceDetails = response;
                    if (!workspaceDetails.success) {
                        window.location.replace("/login");
                    }
                    workspaceDetails = { ...workspaceDetails, login_config: JSON.parse(workspaceDetails.login_config) };

                    this.setState({
                        customLoginPageDetails: workspaceDetails,
                    });
                }),
            );
        }

        if (this.props.dashboards && this.props.dashboards.length && this.props.userConfig.primary_workspace_id) {
            this.findAndSelectPrimaryWorkspace();
        }

        msalInstance.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (message.payload && message.payload.accessToken) {
                    console.log("debug login success:", message.payload.accessToken);
                    this.setState({
                        loginInProgress: true,
                    });

                    let is_mobile = false;
                    if (isMobile) {
                        is_mobile = true;
                    }

                    let target_resource_data = this.targetResourceDetails();

                    let callbackFunction = this.loginAttemptComplete;
                    if (this.props.loginCompleteCallback) {
                        callbackFunction = this.props.loginCompleteCallback;
                    }
                    this.props.dispatch(
                        emailPasswordLogin(
                            {
                                sso_ms: message.payload.accessToken,
                            },
                            target_resource_data,
                            is_mobile,
                            callbackFunction,
                            this.props.ssoRedirect,
                        ),
                    );
                }
            }
        });
    };

    isDev = () => {
        return (
            window.location.href.includes("localhost") ||
            window.location.href.includes("view-dev.aivr.video") ||
            window.location.href.includes("view.aivr-dev.video")
        );
    };

    onKeyDown = (e) => {
        if (e.key === "Enter") {
            const resource_access_denied = this.props.targetResource && this.props.targetResource.access_granted === false;
            if (
                !this.state.showResetRequestResult &&
                !this.state.showForgotPassword &&
                !resource_access_denied &&
                this.props.dashboards &&
                this.props.termsAccepted &&
                e.target.className !== "ant-select-search__field" &&
                !this.state.selectingDashboard
            ) {
                this.setState({
                    selectingDashboard: true,
                });
                e.preventDefault();
                this.selectDashboard();
            }
        }
    };

    componentDidUpdate(prevProps) {
        // user has exchanged their credentials for a list of dashboards
        // check whether a required_access_id has been specified in
        // window.location.state or in redux targetResource

        if (this.props.dashboards && this.props.location.state && this.props.location.state.hasOwnProperty("required_access_id")) {
            let required_access_id = this.props.location.state.required_access_id;
            let dashboard = _.find(this.props.dashboards, { access_id: required_access_id });
            if (dashboard && !this.props.access_token) {
                //This stops two audits being sent
                // user has access to the specified dashboard so make
                // choice of dashboard for them
                this.props.dispatch(selectDashboard(dashboard.access_token));
                this.props.dispatch(swapDashboardAudit(dashboard.access_token));
            }
        } else if (!prevProps.dashboards && this.props.dashboards && !_.isEmpty(this.props.targetResource)) {
            let required_access_id = null;
            if (this.props.targetResource.access_granted === true) {
                required_access_id = this.props.targetResource.required_access_id;
                console.log("login was successful and the requested resource can be accessed via access id: ", required_access_id);
            } else if (this.props.targetResource.workspace_id) {
                required_access_id = this.props.targetResource.workspace_id;
            }
            let dashboard = _.find(this.props.dashboards, { access_id: required_access_id });
            if (dashboard && !this.props.access_token) {
                //This stops two audits being sent
                this.props.dispatch(selectDashboard(dashboard.access_token));
                this.props.dispatch(swapDashboardAudit(dashboard.access_token));
            }
        } else if (this.props.dashboards && !prevProps.dashboards) {
            if (this.props.userConfig.primary_workspace_id) {
                this.findAndSelectPrimaryWorkspace();
            }
        }

        // if access_token is null attempt to close aivr-tour-notification
        // this is to prevent for tour notification to be open in workspace selector component
        if (!this.props.access_token) {
            notification.close("aivr-tour-notification");
        }
    }

    customLoginLogo() {
        if (
            !this.props.dashboards &&
            this.state.customLoginPageDetails &&
            this.state.customLoginPageDetails.success &&
            this.state.customLoginPageDetails.login_config &&
            this.state.customLoginPageDetails.login_config.logo
        ) {
            return (
                <img
                    className="LoginLogo"
                    src={this.state.customLoginPageDetails.login_config.logo}
                    alt="Organisation Logo"
                    crossOrigin={"anonymous"}
                />
            );
        } else {
            return null;
        }
    }

    customLoginOrganisationName() {
        if (
            !this.props.dashboards &&
            this.state.customLoginPageDetails &&
            this.state.customLoginPageDetails.success &&
            this.state.customLoginPageDetails.name
        ) {
            return (
                <div className="LoginWorkspaceName">
                    Welcome to <strong>{this.state.customLoginPageDetails.name}</strong>
                </div>
            );
        } else {
            return null;
        }
    }

    customLoginMicrosoftAuth() {
        // check if request coming from custom organisation page, if not return true to keep current behaviour
        if (!this.state.customLoginPageDetails) {
            return false;
            //return !!window.location.href.includes("view-dev.aivr.video");
        } else {
            return (
                this.state.customLoginPageDetails &&
                this.state.customLoginPageDetails.success &&
                this.state.customLoginPageDetails.login_config &&
                this.state.customLoginPageDetails.login_config.microsoft_auth
            );
        }
    }

    customLoginAivrAuth() {
        // check if request coming from custom organisation page, if not return true to keep current behaviour
        if (!this.state.customLoginPageDetails) {
            return true;
        } else {
            return (
                this.state.customLoginPageDetails &&
                this.state.customLoginPageDetails.success &&
                this.state.customLoginPageDetails.login_config &&
                this.state.customLoginPageDetails.login_config.aivr_auth
            );
        }
    }

    customLoginHasLoginOption() {
        if (this.customLoginMicrosoftAuth() && (!isMobile || this.props.domainPrefixFromASSO)) {
            return true;
        }
        if (this.customLoginAivrAuth()) {
            return true;
        }
        return false;
    }

    render() {
        let resource_access_denied = this.props.targetResource && this.props.targetResource.access_granted === false;

        if (!this.props.access_token || resource_access_denied) {
            let loginForm = null;
            if (this.state.showResetRequestResult) {
                loginForm = (
                    <>
                        <div className="LoginEntry">
                            <div className="UpperArea text">
                                {this.state.resetRequestSuccessful ? (
                                    <span>An email has been sent to your email address with instructions on how to reset your password.</span>
                                ) : (
                                    <span>There was a problem processing your request. Please try again.</span>
                                )}
                            </div>
                            <div className="LowerArea">
                                <div
                                    className="ToggleLoginType"
                                    style={{ marginTop: "62px" }}
                                    onClick={this.reset}>
                                    Back to login
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else if (this.state.showForgotPassword) {
                loginForm = (
                    <>
                        <form
                            className="LoginEntry"
                            onSubmit={this.requestResetPassword}>
                            <div className="UpperArea">
                                <div
                                    className="LoginBlurb"
                                    style={{ width: "90%" }}>
                                    An email will be sent with instructions on how to reset your password.
                                </div>
                                <input
                                    align="center"
                                    className="LoginInput"
                                    type="email"
                                    pattern="\S(.*\S)?"
                                    placeholder="Enter your e-mail address"
                                    value={this.state.user_email}
                                    onChange={this.updateEmail}
                                />
                            </div>
                            <div className="LowerArea">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className="LoginConfirm"
                                    disabled={!this.validateEmail()}
                                    loading={this.state.resetRequestInProgress}>
                                    Request Password Reset
                                </Button>
                                <div
                                    className="ToggleLoginType"
                                    onClick={this.toggleForgotPassword}>
                                    Back to login
                                </div>
                            </div>
                        </form>
                    </>
                );
            } else if (resource_access_denied) {
                loginForm = (
                    <>
                        <div className="LoginEntry">
                            <div className="UpperArea">
                                <FontAwesomeIcon
                                    className="AccessDeniedIcon"
                                    icon={faDoNotEnter}
                                    size="2x"
                                />
                                <span className="AccessDeniedTitle">Access Denied</span>
                                <div className="LoginBlurb">Your user account does not have access to the requested content.</div>
                            </div>
                            <div className="LowerArea">
                                <Button
                                    onClick={this.dismissTargetResource}
                                    type="primary">
                                    Continue to Dashboard
                                </Button>

                                <div
                                    onClick={this.cancelLogin}
                                    className="ToggleLoginType"
                                    style={{ marginTop: 60 }}>
                                    Back to login
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else if (this.props.dashboards) {
                if (!this.props.termsAccepted) {
                    return <TermsComponent />;
                }
                // else if (this.props.userHiddenSetting === null){
                //     loginForm = <>
                //         <form className="LoginEntry" onSubmit={this.submitNameConfig}>
                //             <div className="UpperArea">
                //                 <div className="LoginBlurb" style={{width: '90%'}}>
                //                     Enter your display name
                //                 </div>
                //                 <input
                //                     align="center"
                //                     className={"LoginInput" + (this.state.hiddenInput ? " Disabled" : "")}
                //                     type="name"
                //                     placeholder="First Last"
                //                     value={this.state.nameInput}
                //                     onChange={this.updateNameInput}
                //                     autoFocus
                //                     disabled={this.state.hiddenInput}/>

                //                 <div className="DisplayNameBlurb">
                //                     This name will be used to identify content created by you and will be visible to other users in the workspace.
                //                 </div>

                //                 <div className="CheckboxContainer">
                //                     <Checkbox
                //                         onChange={this.onHideUserChange}
                //                         checked={this.state.hiddenInput}
                //                         style={{fontSize: 'smaller', color: '#00000080'}}
                //                     >
                //                         I'd rather remain anonymous
                //                     </Checkbox>
                //                 </div>

                //             </div>
                //             <div className="LowerArea">
                //                 <Button loading={this.state.submitNameLoading} htmlType="submit" disabled={!this.state.nameInput && !this.state.hiddenInput} type={"primary"} className="LoginConfirm">Submit</Button>
                //             </div>
                //         </form>
                //     </>;
                // }
                else {
                    const dashboards = _.cloneDeep(this.props.dashboards);

                    let primary_workspace = null;
                    if (this.props.userConfig.primary_workspace_id) {
                        const primary_workspace_index = _.findIndex(dashboards, { access_id: this.props.userConfig.primary_workspace_id });
                        if (primary_workspace_index > -1) {
                            primary_workspace = dashboards[primary_workspace_index];
                            dashboards.splice(primary_workspace_index, 1);
                        }
                    }

                    let dashboardsByOrg = _.groupBy(dashboards, "organisation");

                    let orgGroups = Object.keys(dashboardsByOrg)
                        .sort(function (a, b) {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                        .map((org) => {
                            return (
                                <Select.OptGroup
                                    key={org}
                                    label={org}>
                                    {_.map(
                                        _.sortBy(dashboardsByOrg[org], (dash) => dash.description.toLowerCase()),
                                        (dashboard) => {
                                            return (
                                                <Select.Option
                                                    key={dashboard.access_token}
                                                    value={dashboard.access_token}>
                                                    {dashboard.description}
                                                    <div className="workspace-picker-long-desc-container">
                                                        <p>{dashboard.long_description}</p>
                                                    </div>
                                                </Select.Option>
                                            );
                                        },
                                    )}
                                </Select.OptGroup>
                            );
                        });

                    loginForm = (
                        <>
                            <form
                                className="LoginEntry"
                                onSubmit={this.selectDashboard}>
                                <div className="UpperArea">
                                    <div
                                        className="LoginBlurb"
                                        style={{ width: "90%" }}>
                                        <div>
                                            Please select a workspace to use
                                            <Tippy
                                                content={
                                                    <div>
                                                        <p>Workspaces are configurable private environments for teams to collaborate on AIVR data together.</p>
                                                        <p>
                                                            Workspaces can show different datasets, support different features and be securely accessed by only
                                                            their members.
                                                        </p>
                                                        <p>
                                                            If you have access to more than one workspace, sessions visible in one may not be visible in
                                                            another.
                                                        </p>
                                                        <p>For more information please email support.</p>
                                                    </div>
                                                }
                                                arrow={true}
                                                zIndex="1000"
                                                placement="bottom">
                                                <span style={{ marginLeft: "0.3em" }}>
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        style={{ opacity: 0.4 }}
                                                    />
                                                </span>
                                            </Tippy>
                                        </div>
                                    </div>

                                    <Select
                                        showAction={["focus", "click"]}
                                        className="LoginInput select-no-padding"
                                        dropdownClassName="LoginInputDropdown"
                                        value={this.state.access_token}
                                        onSelect={this.updateSelectedDashboard}
                                        showSearch
                                        autoFocus={this.props.dashboardID ? true : false}
                                        placeholder="Select a workspace"
                                        optionFilterProp="children">
                                        {primary_workspace && (
                                            <Select.OptGroup
                                                key="default"
                                                label="Default">
                                                <Select.Option
                                                    key={primary_workspace.access_token}
                                                    value={primary_workspace.access_token}>
                                                    {primary_workspace.description}
                                                </Select.Option>
                                            </Select.OptGroup>
                                        )}
                                        {orgGroups}
                                    </Select>
                                </div>
                                <div className="LowerArea">
                                    <Button
                                        htmlType="submit"
                                        disabled={!this.state.access_token}
                                        type={"primary"}
                                        className="LoginConfirm">
                                        Enter Workspace
                                    </Button>
                                </div>
                            </form>
                        </>
                    );
                }
            } else if (this.state.showEmailLogin) {
                loginForm = (
                    <>
                        <form
                            className="LoginEntry"
                            onSubmit={this.confirmEmailPasswordLogin}>
                            {this.customLoginAivrAuth() && (
                                <div className="UpperArea">
                                    <input
                                        align="center"
                                        className="LoginInput"
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                        value={this.state.user_email}
                                        onChange={this.updateEmail}
                                        autoFocus
                                    />
                                    <input
                                        align="center"
                                        className="LoginInput"
                                        type="password"
                                        placeholder="Enter your password"
                                        value={this.state.user_password}
                                        onChange={this.updatePassword}
                                    />
                                </div>
                            )}
                            <div className="LowerArea">
                                {this.customLoginAivrAuth() && (
                                    <Button
                                        htmlType="submit"
                                        disabled={!this.state.user_email || !this.state.user_password}
                                        loading={this.state.loginInProgress}
                                        type={this.state.lastLoginAttemptFailed ? "danger" : "primary"}
                                        className="LoginConfirm">
                                        Sign In
                                    </Button>
                                )}
                                {this.customLoginMicrosoftAuth() && (!isMobile || this.props.domainPrefixFromASSO) && (
                                    <MsalProvider instance={msalInstance}>
                                        <MicrosoftSSOButton />
                                    </MsalProvider>
                                )}
                                {this.customLoginAivrAuth() && (
                                    <div
                                        className="ToggleLoginType"
                                        onClick={this.toggleForgotPassword}>
                                        Forgotten password?
                                    </div>
                                )}
                                {!this.customLoginHasLoginOption() && (
                                    <div className="NoLoginOptionLabel">There is no login option available, please try login using desktop browser</div>
                                )}
                            </div>
                        </form>
                    </>
                );
            } else {
                loginForm = (
                    <>
                        <form
                            className="LoginEntry"
                            onSubmit={this.confirmAccessTokenLogin}>
                            <div className="UpperArea">
                                <input
                                    align="center"
                                    className="LoginInput"
                                    placeholder="Enter your AIVR Access Key"
                                    value={this.state.access_token}
                                    onChange={this.updateAccessToken}
                                />
                            </div>
                            <div className="LowerArea">
                                <Button
                                    htmlType="submit"
                                    disabled={!this.state.access_token}
                                    loading={this.state.loginInProgress}
                                    type={this.state.lastLoginAttemptFailed ? "danger" : "primary"}
                                    className="LoginConfirm">
                                    Sign In
                                </Button>
                                <div
                                    className="ToggleLoginType"
                                    onClick={this.toggleLoginType}>
                                    Back to e-mail address/password sign in
                                </div>
                            </div>
                        </form>
                    </>
                );
            }

            return (
                <div className="LoginOverlay Center-Content">
                    <div className="LoginContent__Container">
                        <div className="LoginContent">
                            <MediaQuery maxWidth={479}>
                                <img
                                    className="LoginLogo mobile"
                                    src={logoWhite}
                                    alt="AIVR Logo"
                                    crossOrigin={"anonymous"}
                                />
                            </MediaQuery>
                            <MediaQuery minWidth={479}>
                                <img
                                    className="LoginLogo"
                                    src={logo}
                                    alt="AIVR Logo"
                                    crossOrigin={"anonymous"}
                                />
                            </MediaQuery>
                            {this.customLoginOrganisationName()}
                            {this.customLoginLogo()}
                            {window.location.hostname !== `view-widget${MEMOIZED_DOMAIN_URL}}` ? (
                                <>
                                    {loginForm}
                                    <div className="LoginFooter">
                                        <div className="LoginSales">
                                            For enquiries, please contact <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a>
                                        </div>
                                        <div className="LoginTerms">
                                            By using this website you agree to our&nbsp;
                                            <p>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/terms">
                                                    Terms of Use
                                                </a>{" "}
                                                &{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/privacy">
                                                    Privacy Policy
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <p className="LoginErrorMessage">This session is invalid or expired</p>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            if (!this.props.termsAccepted && !_.isEmpty(this.props.userConfig)) {
                return <TermsComponent />;
            }

            let redirect = { pathname: "/" };
            if (this.props.location.state && this.props.location.state.from) {
                redirect = this.props.location.state.from;
            } else if (isMobile && !this.props.requestedDesktop) {
                redirect = { pathname: "/mobile" };
            }

            return <Redirect to={redirect} />;
        }
    }
}

// NB props.location.state.required_access_id specifies the access_id needed
// to successfully authorise access to a page specified in state.from

const mapStateToProps = (state) => ({
    access_token: state.access_token,
    dashboards: state.dashboards,
    targetResource: state.targetResource,
    userConfig: state.userDetails.userConfig,
    termsAccepted: state.userDetails.userConfig.terms_accepted,
    userHiddenSetting: state.userDetails.userConfig.user_hidden,
    requestedDesktop: state.requestedDesktop,
    dashboardID: state.userDetails.dashboardAccessID,
});

export default connect(mapStateToProps)(withRouter(LoginComponent));
