import React, { useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { SegmentIcon, DatumIcon, SignalIcon } from "./Icons";
import { LazyMapTooltip } from "../util/LazyTooltips";
import { locationsAreEqual, nearestPointOnGeometry, nearestPointOnRoute, routePointDistance } from "../util/Geometry";
import { routeSelected, selectMapPoint } from "redux/actions/index";
import _ from "lodash";
import { notification } from "antd";

const routeDatumSelector = (state) => state.featureOverlay.route;
const datumOffsetsSelector = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);

    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);

    return workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
};
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const mapGeometrySelector = (state) => state.mapGeometry;
const currentRouteSelector = (state) => state.playlist.data.routeID;
const routeGeometrySelector = (state) => _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex]);
const useSnappedGeometrySelector = (state) => state.snappedRoute;
const featuresSelector = (state) => state.featureOverlay.features;
const datumSelector = (state) => _.get(state.featureOverlay, ["datum"], null);
const userConfigSelector = (state) => state.userDetails.userConfig;

function FeatureMarkerTooltip({ feature, isDatum }) {
    const latLon = useMemo(() => {
        return `${parseFloat(feature.location[1]).toFixed(5)}, ${parseFloat(feature.location[0]).toFixed(5)}`;
    }, [feature.location]);

    const datumInformation = useSelector(routeDatumSelector);
    const userConfig = useSelector(userConfigSelector);

    const distanceFromDatumInfo = useMemo(() => {
        if (isDatum) {
            return "Datum Point";
        } else if (feature.location && datumInformation) {
            const { datum, distanceMap } = datumInformation;
            let distanceFromDatum = routePointDistance(datum, feature.location, distanceMap);
            if (distanceFromDatum < 10) {
                distanceFromDatum = Math.round(distanceFromDatum * 10) / 10;
            } else {
                distanceFromDatum = Math.round(distanceFromDatum);
            }
            const measurementUnit = userConfig.measurement_units;

            if (measurementUnit === "metres") {
                return `${distanceFromDatum}m from datum`;
            } else if (measurementUnit === "yards") {
                let yardValue = distanceFromDatum * 1.0936132983;
                if (yardValue < 10) {
                    yardValue = Math.round(yardValue * 10) / 10;
                } else {
                    yardValue = Math.round(yardValue);
                }
                return `${yardValue}y from datum`;
            }
        } else {
            return null;
        }
    }, [feature.location, datumInformation, isDatum, userConfig.measurement_units]);

    const datumOffsets = useSelector(datumOffsetsSelector);
    const elrUnits = useSelector(elrUnitsSelector);

    let closestELRInfo = null;
    if (feature.routePosition) {
        closestELRInfo = feature.routePosition.to_string(elrUnits, datumOffsets);
    }

    return (
        <LazyMapTooltip
            className="Incident-Tooltip"
            permanent={false}
            direction={"top"}
            offset={[0, -35]}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span style={{ margin: 2.5 }}>Type: {feature.type}</span>
                {distanceFromDatumInfo && <span style={{ margin: 2.5 }}>{distanceFromDatumInfo}</span>}
                <span style={{ margin: 2.5 }}>Lat/lon: {latLon}</span>
                {closestELRInfo && <span style={{ margin: 2.5 }}>{closestELRInfo}</span>}
            </div>
        </LazyMapTooltip>
    );
}

function FeatureMarker({ feature, ...props }) {
    const dispatch = useDispatch();
    const store = useStore();

    const onClick = () => {
        const state = store.getState();
        console.log("debug feature", feature);

        const currentRoute = currentRouteSelector(state);
        if (!currentRoute) {
            dispatch(routeSelected(feature.session_id, feature.session_ts));
        } else {
            const [lon, lat] = feature.location;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            let closestPoint = nearestPointOnRoute(lat, lon, routeGeometrySelector(state), useSnappedGeometrySelector(state));
            if (closestPoint.distanceSquared <= 25 * 25) {
                dispatch(routeSelected(currentRouteSelector(state), closestPoint.point));
            } else {
                closestPoint = nearestPointOnGeometry(lat, lon, mapGeometrySelector(state));
                if (closestPoint) {
                    const closestPointID = closestPoint.point[0];
                    dispatch(selectMapPoint(closestPointID, closestPoint.coordinates));
                } else {
                    notification.error({
                        message: "No nearby session found",
                    });
                }
            }
        }
    };

    const datum = useSelector(datumSelector);

    const isDatum = useMemo(() => {
        if (!datum) {
            return false;
        }
        return locationsAreEqual(feature.location, datum.location);
    }, [feature.location, datum]);

    let icon;
    if (isDatum) {
        icon = DatumIcon;
    } else if (feature.type === "signal") {
        icon = SignalIcon;
    } else {
        icon = SegmentIcon;
    }

    return (
        <Marker
            {...props}
            onClick={onClick}
            position={[feature.location[1], feature.location[0]]}
            icon={icon}>
            <FeatureMarkerTooltip
                feature={feature}
                isDatum={isDatum}
            />
        </Marker>
    );
}

export default function FeatureMarkers() {
    const features = useSelector(featuresSelector);

    const featureMarkers = useMemo(() => {
        return features.map((feature, idx) => (
            <FeatureMarker
                feature={feature}
                key={`feature${idx}`}
            />
        ));
    }, [features]);

    return <LayerGroup>{featureMarkers}</LayerGroup>;
}
