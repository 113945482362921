import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { notification, Button } from "antd";
import { editMarkerDisplayType, toggleIconConfiguration, editMarkerImportance, editMarkerTagColour } from "../redux/actions/index";
import { ReactComponent as SprayCanSvg } from "../icons/pins/spray-can.svg";
import { ReactComponent as TreeSvg } from "../icons/pins/tree.svg";
import { ReactComponent as TrafficLightSvg } from "../icons/pins/traffic-light.svg";
import { ReactComponent as PersonSvg } from "../icons/pins/person.svg";
import { ReactComponent as ArchwaySvg } from "../icons/pins/archway.svg";
import { ReactComponent as ExclamationTriangleSvg } from "../icons/pins/exclamation-triangle.svg";
import { ReactComponent as BaliseSvg } from "../icons/pins/balise.svg";
import { ReactComponent as AxleCounterSvg } from "../icons/pins/axle-counter.svg";
import { ReactComponent as Model3dSvg } from "../icons/pins/3d-model.svg";
import { ReactComponent as KeySvg } from "../icons/pins/key.svg";
import { ReactComponent as TrainSvg } from "../icons/pins/train.svg";
import { ReactComponent as BallastBagSvg } from "../icons/pins/ballast-bag.svg";
import { ReactComponent as BridgeSvg } from "icons/pins/bridge.svg";
import { ReactComponent as LimitedClearanceSvg } from "icons/pins/limited-clearance.svg";
import { ReactComponent as PointSvg } from "icons/pins/point.svg";
import { ReactComponent as ScrapRailSvg } from "icons/pins/scrap-rail.svg";
import { ReactComponent as SpeedBoardSvg } from "icons/pins/speed-board.svg";
import { ReactComponent as TunnelSvg } from "icons/pins/tunnel.svg";
import { ReactComponent as ThermalHotspotSvg } from "icons/pins/thermal-hotspot.svg";
import { ReactComponent as KonuxSvg } from "icons/pins/konux.svg";

class MarkerCard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayType: this.props.markerObj.display_type || "route_selected",
            importance: this.props.markerObj.importance || 0,
            labelTagColour: this.props.markerObj.tag_colour || "#ffffff",
            newLabelTagColour: "",
        };
    }

    componentDidMount() {
        this.setState({
            newLabelTagColour: this.state.labelTagColour,
        });
    }

    checkboxChange = (event) => {
        console.log("Checkbox clicked", event.target.value);
        if (this.state.displayType !== event.target.value) {
            this.setState({ displayType: event.target.value });
            this.props.dispatch(
                editMarkerDisplayType(this.props.markerObj.id, event.target.value, (response) => {
                    console.log("debug completed...", response.message);
                    if (response.message) {
                        notification.success({
                            message: "Success",
                            description: response.message,
                        });
                    }
                }),
            );
        }
    };

    importanceChange = (event) => {
        const importance = parseInt(event.target.value);
        console.log("Importance changed", importance);
        this.setState({ importance });
        this.props.dispatch(
            editMarkerImportance(this.props.markerObj.id, importance, (response) => {
                if (response.message) {
                    notification.success({
                        message: "Success",
                        description: response.message,
                    });
                }
            }),
        );
    };

    cheangeTagLabelColour = (newValue) => {
        this.setState({
            labelTagColour: newValue,
        });
    };

    onButtonSaveClick = () => {
        console.log("debug this.state.labelTagColour", this.state.labelTagColour);
        this.setState({
            labelTagColour: this.state.labelTagColour,
            newLabelTagColour: this.state.labelTagColour,
        });
        this.props.dispatch(
            editMarkerTagColour(this.props.markerObj.id, this.state.labelTagColour, (response) => {
                if (response.message) {
                    notification.success({
                        message: "Success",
                        description: response.message,
                    });
                }
            }),
        );
    };

    onButtonCancelClick = () => {
        this.setState({
            labelTagColour: this.props.markerObj.tag_colour || "#ffffff",
        });
    };

    onButtonResetDefaultClick = () => {
        this.props.dispatch(
            editMarkerTagColour(this.props.markerObj.id, "", (response) => {
                if (response.message) {
                    this.setState({
                        labelTagColour: "#ffffff",
                        newLabelTagColour: "#ffffff",
                    });
                    notification.success({
                        message: "Success",
                        description: response.message,
                    });
                }
            }),
        );
    };

    checkIfNewColorSet = () => {
        if (this.state.newLabelTagColour === this.state.labelTagColour) {
            return false;
        }
        return this.props.markerObj.tag_colour !== this.state.labelTagColour;
    };

    openIconConfig = () => {
        console.log("Opening icon config", this.props.markerObj.id);
        this.props.dispatch(toggleIconConfiguration(true, this.props.markerObj.id));
    };

    render() {
        let iconSize = { width: 85, height: 85 };

        let iconMap = {
            "spray-can": (
                <SprayCanSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            tree: (
                <TreeSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "traffic-light": (
                <TrafficLightSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            male: (
                <PersonSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            archway: (
                <ArchwaySvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "exclamation-triangle": (
                <ExclamationTriangleSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            balise: (
                <BaliseSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            axleCounter: (
                <AxleCounterSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "3dModel": (
                <Model3dSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            key: (
                <KeySvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            train: (
                <TrainSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "ballast-bag": (
                <BallastBagSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            bridge: (
                <BridgeSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "limited-clearance": (
                <LimitedClearanceSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            point: (
                <PointSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "scrap-rail": (
                <ScrapRailSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "speed-board": (
                <SpeedBoardSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            tunnel: (
                <TunnelSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            "thermal-hotspot": (
                <ThermalHotspotSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
            konux: (
                <KonuxSvg
                    className="none"
                    style={{ fill: this.props.markerObj.colour || "#000000", ...iconSize }}
                />
            ),
        };

        console.log("Marker icon", this.props.markerObj.icon);
        return (
            <div
                className="MarkerGridItem"
                key={this.props.markerObj.id}>
                <table className="markerConfigurationTable">
                    <thead>
                        <tr>
                            <td colSpan={2}>
                                <h2>{this.props.markerObj.type}</h2>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="leftColumn">
                                <div className="iconDiv">
                                    {this.props.markerObj.icon ? (
                                        iconMap[this.props.markerObj.icon]
                                    ) : (
                                        <span style={{ color: "white", fontWeight: "bold" }}>No Icon Selected</span>
                                    )}
                                    <br />

                                    <button
                                        onClick={this.openIconConfig}
                                        className="adminChangesMadeButtons">
                                        Edit Icon
                                    </button>
                                </div>
                            </td>
                            <td className="rightColumn">
                                <div className="checkboxesDiv">
                                    <p style={{ textAlign: "left" }}>Display marker on map:</p>

                                    <div className="checkBoxContainer">
                                        <input
                                            id={`markerConfiguration${this.props.markerObj.icon}always`}
                                            type="checkbox"
                                            className="adminMarkerCheckbox"
                                            checked={"always" === this.state.displayType}
                                            onChange={this.checkboxChange}
                                            value="always"
                                        />
                                        <label
                                            className="adminCheckboxLabel"
                                            htmlFor={`markerConfiguration${this.props.markerObj.icon}always`}>
                                            Always
                                        </label>
                                    </div>

                                    <div className="checkBoxContainer">
                                        <input
                                            id={`markerConfiguration${this.props.markerObj.icon}route_selected`}
                                            type="checkbox"
                                            className="adminMarkerCheckbox"
                                            checked={"route_selected" === this.state.displayType}
                                            onChange={this.checkboxChange}
                                            value="route_selected"
                                        />
                                        <label
                                            className="adminCheckboxLabel"
                                            htmlFor={`markerConfiguration${this.props.markerObj.icon}route_selected`}>
                                            When route selected
                                        </label>
                                    </div>

                                    <div className="checkBoxContainer">
                                        <input
                                            id={`markerConfiguration${this.props.markerObj.icon}type_selected`}
                                            type="checkbox"
                                            className="adminMarkerCheckbox"
                                            checked={"type_selected" === this.state.displayType}
                                            onChange={this.checkboxChange}
                                            value="type_selected"
                                        />
                                        <label
                                            className="adminCheckboxLabel"
                                            htmlFor={`markerConfiguration${this.props.markerObj.icon}type_selected`}>
                                            When detection type selected
                                        </label>
                                    </div>

                                    <div className="checkBoxContainer">
                                        <input
                                            id={`markerConfiguration${this.props.markerObj.icon}never`}
                                            type="checkbox"
                                            className="adminMarkerCheckbox"
                                            checked={"never" === this.state.displayType}
                                            onChange={this.checkboxChange}
                                            value="never"
                                        />
                                        <label
                                            className="adminCheckboxLabel"
                                            htmlFor={`markerConfiguration${this.props.markerObj.icon}never`}>
                                            Never
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="leftColumn">
                                <label className="markerImportanceLabel">Importance: </label>
                            </td>
                            <td className="rightColumn">
                                <input
                                    className="markerImportanceInput"
                                    type="number"
                                    min={-99}
                                    max={99}
                                    value={this.state.importance}
                                    onChange={this.importanceChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="leftColumn">
                                <label className="markerImportanceLabel">Label colour: </label>
                            </td>
                            <td className="rightColumn">
                                <div className="lablelTagColourDiv">
                                    <input
                                        id="tag_colour"
                                        type="color"
                                        value={this.state.labelTagColour}
                                        onChange={(e) => this.cheangeTagLabelColour(e.target.value)}
                                    />
                                    {this.checkIfNewColorSet() && (
                                        <>
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() => this.onButtonSaveClick()}>
                                                Save
                                            </Button>
                                            <Button
                                                size="small"
                                                onClick={() => this.onButtonCancelClick()}>
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                    {!this.checkIfNewColorSet() && this.state.labelTagColour !== "#ffffff" && (
                                        <Button
                                            size="small"
                                            onClick={() => this.onButtonResetDefaultClick()}>
                                            Reset to default
                                        </Button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    markers: state.admin.markers,
});

export default connect(mapStateToProps, null)(MarkerCard);
