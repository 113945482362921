import React, { useState, useEffect, useMemo } from "react";
import { getShortcutSessions, archiveShortcut, updateShortcut, resetShortcutSessions, getShortcuts } from "../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faCog, faBell, faBellSlash } from "@fortawesome/pro-solid-svg-icons";
import ShortcutSessionItem from "./ShortcutSessionItem";
import OBCSpinner from "./util/OBC";
import { Popconfirm, Button, Tooltip, Select, notification } from "antd";
import Highlighter from "react-highlight-words";

const shortcutSessionsSelector = (state) => state.shortcuts.sessions;
const userDetailsSelector = (state) => state.userDetails;
const deviceStatusesSelector = (state) => state.shortcuts.devices;
const detectionTypesSelector = (state) => state.detectionTypes;

const ShortcutsItem = ({ shortcut, openModal, searchString }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noSessionsFound, setNoSessionsFound] = useState(false);
    const [sortBy, setSortBy] = useState("coverage");
    const shortcutID = shortcut.id;
    const allShortcutSessions = useSelector(shortcutSessionsSelector);
    const shortcutSessions = useMemo(() => _.get(allShortcutSessions, [shortcut.id], []), [allShortcutSessions, shortcut.id]);
    const deviceStatuses = useSelector(deviceStatusesSelector);
    const detectionTypes = useSelector(detectionTypesSelector);

    const userDetails = useSelector(userDetailsSelector);

    useEffect(() => {
        let st = 0;
        let en = 0;
        const start_ts = shortcut.config.start_ts;
        const end_ts = shortcut.config.end_ts;

        if (start_ts > 0) {
            const startDate = new Date(start_ts * 1000);
            st = startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear();
        }

        if (end_ts > 0) {
            const endDate = new Date(end_ts * 1000);
            en = endDate.getDate() + "/" + (endDate.getMonth() + 1) + "/" + endDate.getFullYear();
        }

        setStartDate(st);
        setEndDate(en);
    }, [shortcut]);

    const selectedDevices = useMemo(() => {
        if (!shortcut.config.devices) {
            return null;
        }
        let devicesList = [];

        _.map(shortcut.config.devices, (deviceID) => {
            const deviceDescription = _.get(
                _.find(deviceStatuses, (device) => device.device_id === deviceID),
                "description",
            );
            devicesList.push(deviceDescription || deviceID);
        });

        return devicesList.length ? `Devices: ${devicesList.join(", ")}` : null;
    }, [deviceStatuses, shortcut.config.devices]);

    const tippyTags = (
        <>
            {shortcut.config.tags.map((tag, index) => {
                if (index > 3) {
                    const detectionType = _.find(detectionTypes, { type: tag });
                    return (
                        <div
                            key={index}
                            className="tippyTag">
                            {detectionType?.display_name || detectionType?.type || tag}
                        </div>
                    );
                }
                return null;
            })}
        </>
    );

    const calculateELR = (section) => {
        const elr = section.elr.toUpperCase();
        const start_mile = Math.trunc(section.start_chain / 80);
        const start_chain = section.start_chain - start_mile * 80;
        const end_mile = Math.trunc(section.end_chain / 80);
        const end_chain = section.end_chain - end_mile * 80;
        const trackID = section.track_id;

        return (
            <>
                <div className="PropertyItem">
                    <p>ELR: </p>
                    <span>{elr}</span>
                </div>
                {trackID && (
                    <div className="PropertyItem">
                        <p>TRACK ID: </p>
                        <span>{trackID}</span>
                    </div>
                )}
                <div className="PropertyItem">
                    <p>MILE: </p>
                    <span>{start_mile}</span>
                </div>
                <div className="PropertyItem">
                    <p>CHAIN: </p>
                    <span>{start_chain}</span>
                </div>
                <span>-</span>
                <div className="PropertyItem">
                    <p>MILE: </p>
                    <span>{end_mile}</span>
                </div>
                <div className="PropertyItem">
                    <p>CHAIN: </p>
                    <span>{end_chain}</span>
                </div>
            </>
        );
    };

    const renderELRTooltip = (sections) => {
        const list = _.map(sections, (section, index) => {
            const elr = section.elr.toUpperCase();
            const start_mile = Math.trunc(section.start_chain / 80);
            const start_chain = section.start_chain - start_mile * 80;
            const end_mile = Math.trunc(section.end_chain / 80);
            const end_chain = section.end_chain - end_mile * 80;

            return (
                <div
                    className="PropertyMain"
                    key={index}>
                    <div className="PropertyItem">
                        <p>ELR: </p>
                        <span>{elr}</span>
                    </div>
                    <div className="PropertyItem">
                        <p>MILE: </p>
                        <span>{start_mile}</span>
                    </div>
                    <div className="PropertyItem">
                        <p>CHAIN: </p>
                        <span>{start_chain}</span>
                    </div>
                    <span>-</span>
                    <div className="PropertyItem">
                        <p>MILE: </p>
                        <span>{end_mile}</span>
                    </div>
                    <div className="PropertyItem">
                        <p>CHAIN: </p>
                        <span>{end_chain}</span>
                    </div>
                </div>
            );
        });

        return <div className="ShortcutSectionsPopover">{list}</div>;
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        openModal(shortcut.id);
    };

    const handleDelete = () => {
        dispatch(archiveShortcut(shortcut.id));
    };

    const handleNotificationToggle = (e) => {
        e.stopPropagation();
        const newNotificationEmailValue = !shortcut.email_notifications;

        dispatch(updateShortcut(shortcut.id, null, null, newNotificationEmailValue))
            .then(() => {
                dispatch(resetShortcutSessions(shortcut.id));
                dispatch(getShortcuts());
                notification.success({
                    message: `Email Notifications turned ${shortcut.email_notifications ? "off" : "on"}`,
                });
            })
            .catch(() => {
                notification.error({
                    message: `Error`,
                    description: `There was and error while ${shortcut.email_notifications ? "disabling" : "enabling"} email notifications, please try again.`,
                });
            });
    };

    useEffect(() => {
        if (active && !shortcut.processing && (!allShortcutSessions[shortcutID] || !allShortcutSessions[shortcutID].length)) {
            setLoading(true);
            dispatch(getShortcutSessions(shortcutID)).then((response) => {
                if (!response.sessions || !response.sessions.length) {
                    setNoSessionsFound(true);
                } else {
                    setNoSessionsFound(false);
                }
                setLoading(false);
            });
        }
    }, [active, shortcut.processing]);

    const sortedSessions = useMemo(() => {
        const sortByMap = {
            coverage: ["coverage", "session"],
            recency: ["session", "coverage"],
        };

        const sortedSessions = _.orderBy(shortcutSessions, sortByMap[sortBy || 0], ["desc", "desc"]);
        return sortedSessions.map((session) => {
            return (
                <ShortcutSessionItem
                    key={session.session}
                    session={session}
                    shortcutID={shortcut.id}
                />
            );
        });
    }, [shortcutSessions, sortBy]);

    const minimiseShortcut = (e) => {
        e.stopPropagation();
        setActive(false);
    };

    const updateSortBy = (value) => {
        setSortBy(value);
    };

    return (
        <div
            className={`ShortcutListItem ${active ? " active" : ""}`}
            onClick={() => setActive((lastActiveValue) => !lastActiveValue)}>
            {shortcut.processing && (
                <Tooltip title="Shortcut Processing">
                    <FontAwesomeIcon
                        className="ShortcutListItemProcessingIcon"
                        icon={faCog}
                        spin={true}
                        style={{ fontSize: 18 }}
                        color="#BEB9D8"
                    />
                </Tooltip>
            )}
            <div className="ShortcutListItemTop">
                <div className="ShortcutTitle">
                    <Highlighter
                        highlightClassName="ObcTextHighlight"
                        searchWords={searchString.split(" ")}
                        autoEscape={true}
                        textToHighlight={shortcut.config.name}
                    />
                    {selectedDevices && <div className="device"> {selectedDevices}</div>}
                </div>
                <div className="ShortcutItemControls">
                    {startDate !== 0 && (
                        <div className="ShortcutItemControlDate">
                            <p>From: </p>
                            <span>{startDate}</span>
                        </div>
                    )}
                    {endDate !== 0 && (
                        <div className="ShortcutItemControlDate">
                            <p>To: </p>
                            <span>{endDate}</span>
                        </div>
                    )}

                    <Popconfirm
                        placement="top"
                        title={
                            shortcut.email_notifications
                                ? "Disable email notifications for this shortcut?"
                                : "Do you want to enable email notifications for new sessions matching this shortcut? Emails will be sent no more than once per day"
                        }
                        onConfirm={handleNotificationToggle}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Yes"
                        cancelText="No">
                        <FontAwesomeIcon
                            className="notification active"
                            color={shortcut.email_notifications ? "#73c0ff" : "#e8dfff"}
                            icon={shortcut.email_notifications ? faBell : faBellSlash}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>

                    {(shortcut.is_users || userDetails.userConfig.super_admin) && (
                        <>
                            <FontAwesomeIcon
                                icon={faEdit}
                                onClick={handleEdit}
                            />

                            <Popconfirm
                                placement="top"
                                title="This will delete the shortcut, are you sure?"
                                onConfirm={handleDelete}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Yes"
                                cancelText="Cancel">
                                <FontAwesomeIcon
                                    className="delete"
                                    icon={faTrashAlt}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </Popconfirm>
                        </>
                    )}
                </div>
            </div>
            <div className="ShortcutListItemBody">
                <div className="ShortcutSections">
                    {shortcut.config.sections.length > 0 && calculateELR(shortcut.config.sections[0])}
                    {shortcut.config.sections.length > 1 && (
                        <Tippy
                            placement="top"
                            size="big"
                            arrow={true}
                            maxWidth={400}
                            theme="dark"
                            delay={[150, null]}
                            content={renderELRTooltip(shortcut.config.sections)}
                            className="ShortcutSectionsPopover">
                            <div className="MoreButton">{shortcut.config.sections.length - 1} more</div>
                        </Tippy>
                    )}
                </div>

                <div className="ShortcutTags">
                    <span>Tags:</span>
                    {shortcut.config.tags.map((tag, index) => {
                        if (index < 4) {
                            const detectionType = _.find(detectionTypes, { type: tag });
                            return (
                                <div
                                    key={index}
                                    className="tag">
                                    {detectionType?.display_name || detectionType?.type || tag}
                                </div>
                            );
                        }
                        return null;
                    })}
                    {shortcut.config.tags.length > 4 ? (
                        <Tippy
                            placement="top"
                            arrow={true}
                            theme="dark"
                            delay={[150, null]}
                            content={tippyTags}>
                            <div className="MoreButton">{shortcut.config.tags.length - 4} more</div>
                        </Tippy>
                    ) : null}
                </div>
            </div>
            {loading && (
                <div className="ShortcutListItemSpinner">
                    <OBCSpinner
                        size={50}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            )}
            {active && !noSessionsFound && (
                <div className="ShortcutListItemBottom">
                    <div className="ShortcutFilters">
                        <div
                            className="ShortcutFiltersSelect"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            <span>Sort by:</span>
                            <Select
                                value={sortBy}
                                onChange={updateSortBy}
                                size="small">
                                <Select.Option value="coverage">% Coverage</Select.Option>
                                <Select.Option value="recency">Most recent</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="ShortcutSessionsList">{sortedSessions}</div>
                </div>
            )}
            {active && noSessionsFound && (
                <div>
                    <p className="SessionsNotFoudLabel">No sessions found in this shortcut</p>
                </div>
            )}
            {active && !loading && (
                <div className="ShortcutListItemCollapse">
                    <Button onClick={minimiseShortcut}>Hide</Button>
                </div>
            )}
        </div>
    );
};

export default ShortcutsItem;
