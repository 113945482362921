import React, { useMemo } from "react";
import { ReactComponent as BallastBagSvg } from "../../icons/ballast-bag-plain.svg";
import { ReactComponent as SpeedboardSvg } from "../../icons/speedboard-plain.svg";
import { ReactComponent as ScrapRailSvg } from "../../icons/scrap-rail-plain.svg";
import { ReactComponent as BridgeSvg } from "../../icons/bridge-plain.svg";
import { ReactComponent as GraffitiSvg } from "../../icons/graffiti-plain.svg";
import { ReactComponent as SnowploughSvg } from "../../icons/snowplough-plain.svg";
import { ReactComponent as AnimalSvg } from "../../icons/animal-plain.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrafficLightGo } from "@fortawesome/pro-solid-svg-icons";

import limitedClearance from "../../icons/limited-clearance.png";
import damagedTroughing from "../../icons/damaged-troughing.png";
import wetBeds from "../../icons/wetbed.png";

let hardCodedTotals = { ballastBag: 7, limitedClearance: 5, scrapRail: 35, damagedTroughing: 9, wetBeds: 3, signal: 23 };
let collectedTotal = 0;
for (const key in hardCodedTotals) {
    collectedTotal += hardCodedTotals[key];
}

const HazardResults = ({ clickCounter, totalCount }) => {
    const diffTotal = useMemo(() => {
        let diffTotal = 0;
        if (totalCount) {
            Object.keys(hardCodedTotals).forEach((type) => {
                let diff = 0;
                if (!clickCounter[type]) {
                    diff = hardCodedTotals[type];
                } else {
                    diff = Math.abs(hardCodedTotals[type] - clickCounter[type]);
                }
                diffTotal += diff;
            });
        } else {
            diffTotal = 82;
        }
        return diffTotal;
    }, [totalCount, clickCounter]);

    return (
        <div className="HazardPerception__Results">
            <h1>Results</h1>

            <div className="HazardPerception__Results__Items">
                <div className="HazardPerception__Results__Items__Item">
                    <BallastBagSvg />
                    <span>Ballast Bag</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["ballastBag"] ? clickCounter["ballastBag"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.ballastBag}</span></div> */}
                    </div>
                </div>
                <div className="HazardPerception__Results__Items__Item">
                    <img
                        src={limitedClearance}
                        alt="limited clerance"
                        height={40}
                        width={40}
                    />
                    <span>Limited Clearance</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["limitedClearance"] ? clickCounter["limitedClearance"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.speedBoard}</span></div> */}
                    </div>
                </div>
                <div className="HazardPerception__Results__Items__Item">
                    <ScrapRailSvg />
                    <span>Scrap Rail</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["scrapRail"] ? clickCounter["scrapRail"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.scrapRail}</span></div> */}
                    </div>
                </div>
                <div className="HazardPerception__Results__Items__Item">
                    <img
                        src={damagedTroughing}
                        alt="limited clerance"
                        height={40}
                        width={40}
                    />
                    <span>Damaged Troughing</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["damagedTroughing"] ? clickCounter["damagedTroughing"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.bridge}</span></div> */}
                    </div>
                </div>
            </div>

            <div className="HazardPerception__Results__Items">
                <div className="HazardPerception__Results__Items__Item">
                    <img
                        src={wetBeds}
                        alt="limited clerance"
                        height={40}
                        width={40}
                    />
                    <span>Wet Beds / Voiding</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["wetBeds"] ? clickCounter["wetBeds"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.graffiti}</span></div> */}
                    </div>
                </div>
                <div className="HazardPerception__Results__Items__Item">
                    <FontAwesomeIcon icon={faTrafficLightGo} />
                    <span>Signal</span>
                    <div className="score">
                        <div className="user">
                            <span>{clickCounter["signal"] ? clickCounter["signal"] : 0}</span>
                        </div>
                        {/* <div className="machine"><span>{hardCodedTotals.signal}</span></div> */}
                    </div>
                </div>
            </div>

            <div className="HazardPerception__Results__Info">
                <div className="HazardPerception__Results__Info-Inner">
                    <p>The correct amount of detections was</p>
                    <span>{collectedTotal}</span>
                </div>
                <div className="HazardPerception__Results__Info-Inner">
                    <p>You counted</p>
                    <span>{totalCount}</span>
                </div>
                <div className="HazardPerception__Results__Info-Inner large">
                    <p>Score: </p>
                    <span>{Math.max(0, collectedTotal - diffTotal)} / 82</span>
                </div>
            </div>
        </div>
    );
};

export default HazardResults;
