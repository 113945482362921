import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { faStepBackward, faStepForward, faTimes, faFastForward, faFastBackward, faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { asyncLoadImage, calculateFrame, getUrlDataForFrame } from "components/util/PlaylistUtils";
import { requestPlaylistPosition, toggleFullscreen, customAudit } from "../../../redux/actions/index";
import { findIndexAtDistance } from "../../util/Geometry";
import OBCSpinner from "../../util/OBC";
import ThreeDFeatureOverlay from "./3d/3dFeatureOverlay";
import { Button, InputNumber } from "antd";
import Tippy from "@tippyjs/react";

class DistanceViewInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageData: null,
            jumpingForward: false,
            jumpingBack: false,
            distanceInput: 10,
            isLastJumpingOption: false,
        };
        this.jumpForwardButtonRef = React.createRef();
        this.jumpInputNumberRef = React.createRef();

        this.jumpBackwardButtonRef = React.createRef();
        this.jumpBackwardInputNumberRef = React.createRef();
    }

    close = () => {
        this.props.onClose();
    };

    toggleBackDistancePicker = () => {
        if (!this.isPreviousDisabled()) {
            this.setState({
                jumpingBack: !this.state.jumpingBack,
                jumpingForward: false,
                isLastJumpingOption: false,
            });
        }
    };

    toggleForwardDistancePicker = () => {
        this.setState({
            jumpingForward: !this.state.jumpingForward,
            jumpingBack: false,
            isLastJumpingOption: false,
        });
    };

    jumpForward = (distance) => {
        console.log("Jumping forward", distance);
        let [index, offset] = findIndexAtDistance(
            distance,
            this.props.video,
            this.props.position,
            this.props.timeOffset,
            this.props.offsets,
            this.props.use_snapped,
            true,
        );

        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, index, offset + 0.001),
        );
        this.props.dispatch(
            customAudit(
                "quick_sighting_jump",
                {
                    direction: "forward",
                    distance,
                },
                `Quick sighting jump forward used - ${distance}m`,
            ),
        );
    };

    jumpBackward = (distance) => {
        console.log("Jumping backwards", distance);
        const [index, offset] = findIndexAtDistance(
            distance,
            this.props.video,
            this.props.position,
            this.props.timeOffset,
            this.props.offsets,
            this.props.use_snapped,
            false,
        );
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, index, offset + 0.001),
        );
        this.props.dispatch(
            customAudit(
                "quick_sighting_jump",
                {
                    direction: "backward",
                    distance,
                },
                `Quick sighting jump backward used - ${distance}m`,
            ),
        );
    };

    closePopups = () => {
        this.setState({
            jumpingBack: false,
            jumpingForward: false,
        });
    };

    isNextDisabled = () => {
        if (this.props.position < this.props.video.length - 1) {
            return false;
        }
        if (!this.props.video[this.props.position]) {
            return true;
        }
        if (!this.props.video[this.props.position][5]) {
            return true;
        }

        let duration = this.props.video[this.props.position][2];
        let frameCount = this.props.video[this.props.position][5];
        return this.props.timeOffset + duration / frameCount >= duration;
    };

    isPreviousDisabled = () => {
        return this.props.position === 0 && this.props.timeOffset < 0.01;
    };

    next = () => {
        let currentIndex = this.props.position;
        let currentTimeOffset = this.props.timeOffset;
        console.log("Currently at position: ", currentIndex, currentTimeOffset);
        let currentKey = this.props.video[currentIndex];
        if (currentKey) {
            let duration = currentKey[2];
            let frameCount = currentKey[5];
            if (duration && frameCount) {
                currentTimeOffset += duration / frameCount;
                if (currentTimeOffset >= duration) {
                    currentTimeOffset = 0;
                    currentIndex += 1;
                }
            } else {
                currentTimeOffset = 0;
                currentIndex += 1;
            }
        }

        if (currentIndex < this.props.video.length) {
            console.log("Going to new position: ", currentIndex, currentTimeOffset);
            this.props.dispatch(
                requestPlaylistPosition(
                    this.props.isVideo,
                    this.props.isEnhanced,
                    this.props.isStills,
                    this.props.sourceIndex,
                    currentIndex,
                    currentTimeOffset + 0.001,
                ),
            );
        }
    };

    previous = () => {
        let currentIndex = this.props.position;
        let currentTimeOffset = this.props.timeOffset;
        console.log("Currently at position: ", currentIndex, currentTimeOffset);
        let currentKey = this.props.video[currentIndex];
        if (currentKey) {
            let duration = currentKey[2];
            let frameCount = currentKey[5];
            if (duration && frameCount) {
                currentTimeOffset -= duration / frameCount;
                if (currentTimeOffset < 0) {
                    currentIndex -= 1;
                    if (currentIndex >= 0) {
                        currentKey = this.props.video[currentIndex];
                        if (currentKey[2] && currentKey[5]) {
                            currentTimeOffset = currentKey[2] - currentKey[2] / currentKey[5];
                        } else {
                            currentTimeOffset = 0;
                        }
                    } else {
                        currentTimeOffset = 0;
                    }
                }
            } else {
                currentTimeOffset = 0;
                currentIndex -= 1;
            }
        }

        if (currentIndex >= 0) {
            console.log("Going to new position: ", currentIndex, currentTimeOffset);
            this.props.dispatch(
                requestPlaylistPosition(
                    this.props.isVideo,
                    this.props.isEnhanced,
                    this.props.isStills,
                    this.props.sourceIndex,
                    currentIndex,
                    currentTimeOffset - 0.001,
                ),
            );
        }
    };

    componentDidMount() {
        this.componentDidUpdate({}, {});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let changed_props = _.filter(["position", "timeOffset", "video", "baseURL"], (item) => this.props[item] !== prevProps[item]);

        if (changed_props.length) {
            console.log("Got changed props: ", changed_props, this.props);
            let videoKey = this.props.video[this.props.position];
            if (videoKey) {
                console.log("Got videoKey: ", videoKey);
                const frameCount = videoKey[5];
                let subIndex = 0;
                if (frameCount) {
                    subIndex = calculateFrame(this.props.video, this.props.position, this.props.timeOffset);
                }

                const urlData = getUrlDataForFrame(this.props.video, this.props.position, subIndex);

                const url = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;

                asyncLoadImage(url, urlData.range)
                    .then((imageData) => {
                        this.setState({
                            imageData,
                        });
                    })
                    .catch(() => {});
            }
        }

        // focus in distance input when jumping forward popover open
        if (this.state.jumpingForward && !prevState.jumpingForward) {
            if (this.jumpInputNumberRef && this.jumpInputNumberRef.current) {
                this.jumpInputNumberRef.current.focus();
            }
        }

        // focus in distance input when jumping backward popover open
        if (this.state.jumpingBack && !prevState.jumpingBack) {
            if (this.jumpBackwardInputNumberRef && this.jumpBackwardInputNumberRef.current) {
                this.jumpBackwardInputNumberRef.current.focus();
            }
        }
    }

    jumpForwardCustom = () => {
        if (this.state.distanceInput >= 10) {
            this.jumpForward(this.state.distanceInput);
            this.setState({
                jumpingForward: false,
                jumpingBack: false,
            });
        }
    };

    jumpBackwardCustom = () => {
        if (this.state.distanceInput >= 10) {
            this.jumpBackward(this.state.distanceInput);
            this.setState({
                jumpingBack: false,
                jumpingForward: false,
            });
        }
    };

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    hideDistancePickers(e) {
        e.stopPropagation();
        if (this.state.jumpingBack || this.state.jumpingForward) {
            this.setState({
                jumpingForward: false,
                jumpingBack: false,
            });
        }
    }

    focusOnJumpDropdownButton = (e, direction) => {
        e.preventDefault();
        if (direction === "backwards" && this.jumpBackwardButtonRef && this.jumpBackwardButtonRef.current) {
            this.toggleBackDistancePicker();
            this.jumpBackwardButtonRef.current.focus();
        } else if (direction === "forwards" && this.jumpForwardButtonRef && this.jumpForwardButtonRef.current) {
            this.toggleForwardDistancePicker();
            this.jumpForwardButtonRef.current.focus();
        }
    };

    jumpOptions = (direction) => {
        const isWidgetLayout = ["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout);
        let metre_options = isWidgetLayout ? [10, 30, 50, 100, 150, 200] : [10, 30, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
        let yard_options = isWidgetLayout ? [10, 30, 50, 100, 150, 200] : [10, 30, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
        let allOptions = [];
        const sightingUnits = this.capitalizeFirstLetter(this.props.sightingUnits);

        const jumpBackwardOrForward = (value = null) => {
            if (direction === "backwards") {
                if (value) {
                    this.jumpBackward(value);
                } else {
                    this.jumpBackwardCustom();
                }
            } else {
                if (value) {
                    this.jumpForward(value);
                } else {
                    this.jumpForwardCustom();
                }
            }
        };

        if (this.props.sightingUnits === "yards") {
            allOptions = yard_options.map((yard_value) => (
                <div
                    className={`jumpDistanceOption${isWidgetLayout ? "Widget" : ""}`}
                    onClick={(e) => {
                        this.focusOnJumpDropdownButton(e, direction);
                        jumpBackwardOrForward(yard_value * 0.9144);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            this.focusOnJumpDropdownButton(e, direction);
                            jumpBackwardOrForward(yard_value * 0.9144);
                        }
                    }}
                    role="button"
                    onFocus={() => {
                        this.setState({
                            isLastJumpingOption: yard_value === _.last(yard_options),
                        });
                    }}
                    tabIndex={0}>
                    <p className={`jumpDistanceOptionText${isWidgetLayout ? "Widget" : ""}`}>{yard_value}yd</p>
                </div>
            ));
        } else {
            allOptions = metre_options.map((m_value) => (
                <div
                    className={`jumpDistanceOption${isWidgetLayout ? "Widget" : ""}`}
                    onClick={(e) => {
                        this.focusOnJumpDropdownButton(e, direction);
                        jumpBackwardOrForward(m_value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            this.focusOnJumpDropdownButton(e, direction);
                            jumpBackwardOrForward(m_value);
                        }
                    }}
                    role="button"
                    tabIndex={0}>
                    <p className={`jumpDistanceOptionText${isWidgetLayout ? "Widget" : ""}`}>{m_value}m</p>
                </div>
            ));
        }

        allOptions.unshift(
            <div
                className={`jumpDistanceOption${isWidgetLayout ? "Widget" : ""} Input`}
                onClick={(e) => e.stopPropagation()}>
                <div className={`NumberInputAndUnits ${this.state.distanceInput < 10 ? "has-error" : ""}`}>
                    <InputNumber
                        ref={direction === "backwards" ? this.jumpBackwardInputNumberRef : this.jumpInputNumberRef}
                        className="NumberInput"
                        value={this.state.distanceInput}
                        onChange={(value) => {
                            if (/^[0-9]+$/.test(value)) {
                                this.setState({ distanceInput: value });
                            }
                        }}
                        onKeyDown={(e) => {
                            // if tab + shift pressed
                            if (e.key === "Tab" && e.shiftKey) {
                                this.focusOnJumpDropdownButton(e, direction);
                            }
                        }}
                        onPressEnter={(e) => {
                            this.focusOnJumpDropdownButton(e, direction);
                            jumpBackwardOrForward();
                        }}
                        min={10}
                        max={1000}
                        formatter={(v) => `${v} ${sightingUnits}`}
                        parser={(v) => v.replace(/[^0-9.]/g, "")}></InputNumber>
                </div>
                <Button
                    onClick={(e) => {
                        this.focusOnJumpDropdownButton(e, direction);
                        jumpBackwardOrForward();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            this.focusOnJumpDropdownButton(e, direction);
                            jumpBackwardOrForward();
                        }
                    }}>
                    Jump
                </Button>
            </div>,
        );

        return allOptions;
    };

    render() {
        const isWidgetLayout = ["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout);

        return (
            <div
                className="AnnotationFrame"
                onClick={(e) => this.hideDistancePickers(e)}>
                <div className="TopToolbar TopToolbar--annotation">
                    <Tippy
                        key={"jumpDistancePopoverLeftTippy"}
                        placement="bottom"
                        className="jumpDistancePopoverTippy"
                        arrow={false}
                        interactive={true}
                        theme="dark"
                        visible={this.state.jumpingBack}
                        onClickOutside={this.toggleBackDistancePicker}
                        content={
                            <div
                                className={`jumpDistancePopover${isWidgetLayout ? "Widget" : ""}`}
                                onKeyDown={(e) => {
                                    if (e.key === "Escape") {
                                        this.toggleBackDistancePicker();
                                        if (this.jumpBackwardButtonRef && this.jumpBackwardButtonRef.current) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.jumpBackwardButtonRef.current.focus();
                                        }
                                    }
                                    if (e.key === "Tab" && this.state.isLastJumpingOption) {
                                        this.toggleBackDistancePicker();
                                        if (this.jumpBackwardButtonRef && this.jumpBackwardButtonRef.current) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.jumpBackwardButtonRef.current.focus();
                                        }
                                    }
                                }}>
                                {this.jumpOptions("backwards")}
                            </div>
                        }>
                        <div className={`AnnotationIconOuter ${this.state.jumpingBack ? "isActive" : ""} `}>
                            <div
                                className={`AnnotationIcon ${this.isPreviousDisabled() ? " Disabled" : ""}`}
                                ref={this.jumpBackwardButtonRef}
                                role="button"
                                tabIndex={this.isPreviousDisabled() ? -1 : 0}
                                onClick={this.toggleBackDistancePicker}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        this.toggleBackDistancePicker();
                                    }
                                }}
                                aria-haspopup={!this.isPreviousDisabled()}
                                aria-expanded={this.state.jumpingBack}
                                aria-controls="popover-content">
                                <FontAwesomeIcon icon={faFastBackward} />
                                <div className="AnnotationLabel">Jump Back</div>
                            </div>
                        </div>
                    </Tippy>

                    <div className={`AnnotationIconOuter`}>
                        <div
                            className={`AnnotationIcon ${this.isPreviousDisabled() ? " Disabled" : ""}`}
                            role="button"
                            tabIndex={this.isPreviousDisabled() ? -1 : 0}
                            onClick={this.previous}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.previous();
                                }
                            }}>
                            <FontAwesomeIcon icon={faStepBackward} />
                            <div className="AnnotationLabel">Prev Frame</div>
                        </div>
                    </div>
                    <div className={`AnnotationIconOuter`}>
                        <div
                            className={`AnnotationIcon ${this.isNextDisabled() ? " Disabled" : ""}`}
                            role="button"
                            tabIndex={this.isNextDisabled() ? -1 : 0}
                            onClick={this.next}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.next();
                                }
                            }}>
                            <FontAwesomeIcon icon={faStepForward} />
                            <div className="AnnotationLabel">Next Frame</div>
                        </div>
                    </div>

                    <Tippy
                        placement="bottom"
                        className="jumpDistancePopoverTippy"
                        arrow={false}
                        interactive={true}
                        theme="dark"
                        visible={this.state.jumpingForward}
                        onClickOutside={this.toggleForwardDistancePicker}
                        content={
                            <div
                                className={`jumpDistancePopover${isWidgetLayout ? "Widget" : ""}`}
                                onKeyDown={(e) => {
                                    if (e.key === "Escape") {
                                        this.toggleForwardDistancePicker();
                                        if (this.jumpForwardButtonRef && this.jumpForwardButtonRef.current) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.jumpForwardButtonRef.current.focus();
                                        }
                                    }
                                    if (e.key === "Tab" && this.state.isLastJumpingOption) {
                                        this.toggleForwardDistancePicker();
                                        if (this.jumpForwardButtonRef && this.jumpForwardButtonRef.current) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.jumpForwardButtonRef.current.focus();
                                        }
                                    }
                                }}>
                                {this.jumpOptions("forwards")}
                            </div>
                        }>
                        <div className={`AnnotationIconOuter ${this.state.jumpingForward ? "isActive" : ""}`}>
                            <div
                                ref={this.jumpForwardButtonRef}
                                className={`AnnotationIcon ${this.isNextDisabled() ? " Disabled" : ""}`}
                                role="button"
                                tabIndex={this.isNextDisabled() ? -1 : 0}
                                onClick={this.toggleForwardDistancePicker}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        this.toggleForwardDistancePicker();
                                    }
                                }}
                                aria-haspopup={!this.isNextDisabled()}
                                aria-expanded={this.state.jumpingForward}
                                aria-controls="popover-content">
                                <FontAwesomeIcon icon={faFastForward} />
                                <div className="AnnotationLabel">Jump Forward</div>
                            </div>
                        </div>
                    </Tippy>

                    <div className="AnnotationIconOuter">
                        <div
                            className={"AnnotationIcon"}
                            role="button"
                            tabIndex={0}
                            onClick={this.close}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.close();
                                }
                            }}>
                            <FontAwesomeIcon icon={faTimes} />
                            <div className="AnnotationLabel">Close</div>
                        </div>
                    </div>
                </div>

                <div className="AnnotationImageFrame">
                    <div
                        className={"FullScreenIconOverlay Top Right"}
                        onClick={() => this.props.dispatch(toggleFullscreen())}
                        aria-label={this.props.fullscreen ? "Exit Full Screen" : "Enter Full Screen"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                this.props.dispatch(toggleFullscreen());
                            }
                        }}
                        role="button"
                        tabIndex={0}>
                        <div className="Image-Fullscreen-Icon">
                            <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                        </div>
                    </div>

                    {this.state.imageData ? (
                        <>
                            <img
                                className="quickSightingImage"
                                src={this.state.imageData}
                                alt="Placeholder"
                                onClick={this.closePopups}
                                crossOrigin={"anonymous"}
                            />
                            <div className="ThreeDFeatureOverlayContainer">
                                <ThreeDFeatureOverlay />
                            </div>
                        </>
                    ) : (
                        <div className="quickSightingImage">
                            <OBCSpinner colorScheme="mono" />
                        </div>
                    )}
                </div>
                <div className="BottomToolbar BottomToolbar--markup" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const routeID = state.playlist.data.routeID;
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);

    let sightingUnits = _.get(state, "userDetails.userConfig.sighting_units", "metres");

    let offsets = [];

    if (routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }

    return {
        baseURL: _.get(state.playlist.data, ["mpdURLs", `snapshots`]),
        position: state.playlist.position.currentIndex,
        timeOffset: state.playlist.position.currentTimeOffset,
        sessionID: routeID,
        userAnnotationTypes: state.userAnnotationTypes,
        userAnnotations: state.userAnnotations,
        video,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        sourceIndex,
        userIsAdmin: state.permissions.admin,
        sightingUnits,
        offsets,
        use_snapped: state.snappedRoute || false,
        fullscreen: state.fullscreen,
        workspaceLayout: currentDashboard?.workspace_layout,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(DistanceViewInterface);
