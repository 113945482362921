import { faWindowRestore } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import SidekickPopoutContent from "components/SidekickPopoutContent";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const widgetSelector = (state) => state.widgets;

const SideKickTippy = memo(({ setActiveDrawer, activeDrawer }) => {
    const widgets = useSelector(widgetSelector);
    const widgetCount = Object.keys(widgets).length;

    return (
        <Tippy
            zIndex="600"
            arrow={true}
            placement="right"
            interactive={true}
            maxWidth={"none"}
            theme={"aivr"}
            hideOnClick={false}
            onTrigger={() => setActiveDrawer("Restore")}
            onUntrigger={() => setActiveDrawer("")}
            content={<SidekickPopoutContent showToolTips />}>
            <div
                className={`SidebarButtonContainer ${activeDrawer === "Restore" ? "active" : ""}`}
                role="button"
                aria-label="Map and Extensions"
                style={{ position: "relative" }}>
                <FontAwesomeIcon
                    icon={faWindowRestore}
                    style={{ fontSize: 32, fontWeight: "bold", color: "#e8dfff" }}
                />
                {widgetCount > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgb(232, 223, 255)",
                            width: "18px",
                            height: "18px",
                            borderRadius: "18px",
                            border: "2px solid rgb(232, 223, 255)",
                            backgroundColor: "rgb(47, 51, 77)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <span>{widgetCount}</span>
                    </div>
                )}
            </div>
        </Tippy>
    );
});

export default SideKickTippy;
