import { Modal, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendDataCaptureRequest } from "redux/actions";
import { notification } from "antd";

const { TextArea } = Input;

const DataCaptureDialog = ({ onClose, visible }) => {
    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [sendingRequest, setSendingRequest] = useState(false);

    const stripHTML = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.body.textContent || "";
    };

    const sendRequest = () => {
        setSendingRequest(true);
        // strip any potential html code from the message
        const encodedMessage = stripHTML(message);

        dispatch(
            sendDataCaptureRequest(encodedMessage.replace(/\n/g, "<br>"), (response) => {
                if (response && response.success) {
                    notification.success({
                        message: "Request Sent",
                        description: "Our support team will contact you shortly",
                    });
                    closeModal();
                }
                setSendingRequest(false);
            }),
        );
    };

    const closeModal = () => {
        setMessage("");
        onClose();
    };

    return (
        <Modal
            title="New Data Capture Request"
            visible={visible}
            centered
            okText="Send Request"
            okButtonProps={{ disabled: !message.length, loading: sendingRequest }}
            onOk={sendRequest}
            onCancel={closeModal}>
            <p>
                Use this form to submit a request for data capture. The AIVR team will relay requests to local delivery teams who may be able to fulfil your
                request.
            </p>
            <TextArea
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your request here"
                value={message}
            />
        </Modal>
    );
};

export default DataCaptureDialog;
