import React from "react";
import "./OBCSpinner.css";

const COLOR_SCHEMES = {
    default: ["#380963", "#6920AF", "#9F6CCA"],
    mono: ["#787878", "#b1b1b1", "#f8f8f8"],
};

const OBCSpinner = (props) => {
    // pixel value for size of spinner
    let size = props.size ? props.size : "120";

    let height = props.height ? props.height : "100%";

    // pass color prop to set monochrome value
    let color = props.hasOwnProperty("color") ? props.color : false;

    let colorScheme = props.hasOwnProperty("colorScheme") ? props.colorScheme : "default";

    // obc default purples
    let innerColour = color ? color : COLOR_SCHEMES[colorScheme][0];
    let midColour = color ? color : COLOR_SCHEMES[colorScheme][1];
    let outColour = color ? color : COLOR_SCHEMES[colorScheme][2];

    let speed = props.speed ? props.speed : 5;
    if (speed > 10) {
        speed = 10;
    } else if (speed < 1) {
        speed = 1;
    }
    let base_dur = 30 / speed;

    return (
        <div
            id="logo"
            style={{
                display: "flex",
                alignItems: "center",
                height: `${height}`,
                width: `${size}px`,
            }}>
            <svg
                style={{
                    margin: "auto",
                    display: "block",
                    width: "100%",
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid">
                <circle
                    id="inner"
                    cx="50"
                    cy="50"
                    r="13"
                    fill="none"
                    style={{
                        stroke: innerColour,
                        animationDuration: `${base_dur / 1.5}s`,
                    }}
                    strokeLinecap="none"
                    strokeWidth="7"
                    stroke="#1B1B1B"
                    strokeDasharray="62 20"></circle>

                <circle
                    id="middle"
                    style={{
                        stroke: midColour,
                        animationDuration: `${base_dur / 3}s`,
                    }}
                    cx="50"
                    cy="50"
                    r="30"
                    fill="none"
                    strokeLinecap="none"
                    strokeWidth="7"
                    stroke="#1B1B1B"
                    strokeDasharray="47"></circle>

                <circle
                    id="outer"
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    strokeLinecap="none"
                    strokeWidth="7"
                    stroke="#1B1B1B"
                    style={{
                        stroke: outColour,
                        animationDuration: `${base_dur / 2}s`,
                    }}
                    strokeDasharray="37 40 60 60 46 40"></circle>
            </svg>
        </div>
    );
};

export default OBCSpinner;
