import React, { useCallback, useEffect, useState } from "react";
import AppNavbar from "../../AppNavbar";
import { useDispatch, useSelector } from "react-redux";
import { logout, setCurrentTab, toggleMarkerInfoShowing, toggleMegasearch } from "../../../redux/actions";
import VideoOrImageComponent from "../../display/VideoOrImageComponent";
import MapComponent from "../../map/MapComponent";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import CustomResizeHandle from "../v2/CustomResizeHandle";
import RouteInfoTabs from "../../RouteInfoTabs";
import SessionsComponent from "../../SessionsComponent";
import { Button, Tabs } from "antd";
import SearchComponent from "./SearchComponent";
import AssetsTab from "components/assets/AssetsTab";
import RouteInformation from "components/route/RouteInformation";
import RouteContentList from "components/route/RouteContentList";
import RouteHistoryList from "components/display/video/RouteHistoryList";
import MarkerInformation from "components/MarkerInformation";
import NearbyAssetTab from "components/Issues/NearbyAssetTab";
import AssetTable from "components/AssetTable";
import _ from "lodash";

const { TabPane } = Tabs;

const selectedMarkerSelector = (state) => state.markers.selectedMarker;
const noPlaylistDataSelector = (state) => _.isEmpty(state.playlist.data);
const shareLinkSelector = (state) => state.shareLink;
const requestedAssetSelector = (state) => state.mlAssets.requested;

const V2Layout = () => {
    const dispatch = useDispatch();

    const [windowWidth, setWindowWidth] = useState(800);
    const [currentTab, setCurrentTabState] = useState("sessions");

    const selectedMarker = useSelector(selectedMarkerSelector);
    const noPlaylistData = useSelector(noPlaylistDataSelector);
    const shareLink = useSelector(shareLinkSelector);
    const requestedAsset = useSelector(requestedAssetSelector);

    const updateCurrentTab = useCallback(
        (tab) => {
            console.log("debug updating current tab", tab);
            dispatch(setCurrentTab(tab));
            setCurrentTabState(tab);
            dispatch(toggleMarkerInfoShowing(tab === "markerInfo"));
        },
        [dispatch],
    );

    const triggerLogout = () => {
        dispatch(logout());
    };

    const onResize = (event, { element, size, handle }) => {
        setWindowWidth(size.width);
    };

    useEffect(() => {
        if (requestedAsset.asset) {
            console.log("debug updating current tab", requestedAsset);
            updateCurrentTab("assets");
        }
    }, [requestedAsset, updateCurrentTab]);

    const tabs = () => {
        return [
            <TabPane
                tab="Sessions"
                key="sessions"
                className="route-tabs-pane">
                <SessionsComponent />
            </TabPane>,
            <TabPane
                tab="Info"
                key="info"
                className="route-tabs-pane">
                <RouteInformation />
            </TabPane>,
            <TabPane
                tab="History"
                key="history"
                className="route-tabs-pane"
                disabled={noPlaylistData || shareLink}>
                <RouteHistoryList visible={currentTab === "history"} />
            </TabPane>,
            <TabPane
                tab="Detection Info"
                key="markerInfo"
                className="route-tabs-pane DetectionInfoPane"
                disabled={_.isEmpty(selectedMarker)}>
                {!_.isNil(selectedMarker.review_status) && <MarkerInformation />}
                {(selectedMarker.data || selectedMarker.custom_data) && !selectedMarker.consensus_data && <AssetTable />}
                {selectedMarker.consensus_data && <NearbyAssetTab />}
            </TabPane>,
            <TabPane
                tab="Assets"
                key="assets"
                className="route-tabs-pane">
                <AssetsTab />
            </TabPane>,
        ];
    };

    return (
        <div className="App">
            <AppNavbar logout={triggerLogout} />
            <SearchComponent />

            <div className="v2-main">
                <ResizableBox
                    width={windowWidth}
                    minConstraints={[450, 100]}
                    onResize={onResize}
                    handle={<CustomResizeHandle />}>
                    <div className="v2-main__inner left">
                        <div className="Container Sessions">
                            {/* <Button onClick={() => dispatch(toggleMegasearch(true))}>
                                Search
                            </Button> */}
                            <div
                                className="route-tabs-container"
                                id="intro-tour-info-window">
                                {/* <RouteInfoTabs extraTab={extraTab}/> */}
                                <Tabs
                                    animated={{ inkBar: false, tabPane: true }}
                                    activeKey={currentTab}
                                    onChange={updateCurrentTab}
                                    tabBarStyle={{ color: "white" }}>
                                    {tabs()}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </ResizableBox>

                <div className="v2-main__inner right">
                    <div className="Container Media">
                        <VideoOrImageComponent />
                    </div>
                    <MapComponent />
                </div>
            </div>
        </div>
    );
};

export default V2Layout;
