import React from "react";
import { connect } from "react-redux";
import { refresh, toggleLogging, updateUserConfig } from "../redux/actions/index";
import { Modal, Switch, Input, Checkbox, Col, Row, Popover, Button } from "antd";
import _ from "lodash";
import historyOff from "../images/route_history_off.png";
import historyOn from "../images/route_history_on.png";

class UserSettingsDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            changeMade: false,
            userConfig: {},
        };
    }

    componentDidMount = () => {
        this.setState({
            userConfig: this.props.userConfig,
        });
    };

    cancelSettingsChange = () => {
        console.log("Cancelling settings change");
        this.props.onClose();
    };

    userConfigChange = (name, value) => {
        console.log(`switch ${name} to ${value}`);
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                [name]: value,
            },
        });
    };

    changesMade = () => {
        return !_.isEqual(this.state.userConfig, this.props.userConfig);
    };

    saveChanges = () => {
        let fieldsToUpdate = {};
        let callback = false;

        if (this.state.userConfig.user_tips !== this.props.userConfig.user_tips) {
            fieldsToUpdate.userTipsSetting = this.state.userConfig.user_tips;
        }

        if (this.state.userConfig.daily_digest !== this.props.userConfig.daily_digest) {
            fieldsToUpdate.dailyDigestSetting = this.state.userConfig.daily_digest;
        }

        if (this.state.userConfig.convert_to_utc !== this.props.userConfig.convert_to_utc) {
            fieldsToUpdate.convertUTC = this.state.userConfig.convert_to_utc;
        }

        if (this.state.userConfig.name !== this.props.userConfig.name) {
            fieldsToUpdate.name = this.state.userConfig.name;
        }

        if (this.state.userConfig.user_hidden !== this.props.userConfig.user_hidden) {
            fieldsToUpdate.userHidden = this.state.userConfig.user_hidden;
        }

        if (this.state.userConfig.primary_workspace_id !== this.props.userConfig.primary_workspace_id) {
            fieldsToUpdate.primaryWorkspaceID = this.state.userConfig.primary_workspace_id;
        }

        if (this.state.userConfig.default_content_privacy !== this.props.userConfig.default_content_privacy) {
            fieldsToUpdate.default_content_privacy = this.state.userConfig.default_content_privacy;
        }

        if (this.state.userConfig.auto_show_history_popup !== this.props.userConfig.auto_show_history_popup) {
            fieldsToUpdate.autoShowHistoryPopup = this.state.userConfig.auto_show_history_popup;
        }

        if (this.props.userConfig.super_admin) {
            if (this.state.userConfig.view_id !== this.props.userConfig.view_id) {
                fieldsToUpdate.viewIdSetting = this.state.userConfig.view_id;
                callback = () => {
                    this.props.dispatch(refresh());
                };
            }
        }

        this.props.dispatch(updateUserConfig(fieldsToUpdate, callback));
    };

    inputChange = (key, value) => {
        let newConfig = _.clone(this.state.userConfig);
        newConfig[key] = value;
        this.setState({
            userConfig: newConfig,
        });
    };

    render() {
        const routeHistoryPopover = (
            <div style={{ height: 150, width: 250 }}>
                <img
                    height="100%"
                    width="100%"
                    crossOrigin={"anonymous"}
                    src={this.state.userConfig.auto_show_history_popup ? historyOn : historyOff}
                    alt={this.state.userConfig.auto_show_history_popup ? "Route history On" : "Route history Off"}
                />
            </div>
        );

        return (
            <Modal
                title="User Settings"
                centered
                zIndex={2000}
                getContainer={false}
                // okButtonProps={{disabled: !this.changesMade()}}
                visible={true}
                footer={[
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="UserSettingsFooterText">
                            <div style={{ display: "flex", gap: 20 }}>
                                <p>Version: {process.env["REACT_APP_GIT_VERSION"]}</p>
                                <p>API Version: {this.props.apiVersion}</p>
                            </div>

                            <Checkbox
                                checked={this.props.loggingEnabled}
                                onChange={(e) => this.props.dispatch(toggleLogging(e.target.checked))}>
                                Enable Logging
                            </Checkbox>
                        </div>
                        <div>
                            <Button onClick={this.cancelSettingsChange}>Cancel</Button>
                            <Button
                                onClick={this.saveChanges}
                                type="primary"
                                disabled={!this.changesMade()}>
                                Save
                            </Button>
                        </div>
                    </div>,
                ]}
                maskClosable={false}
                onOk={this.saveChanges}
                onCancel={this.cancelSettingsChange}
                width={800}>
                <Row gutter={[16, 16]}>
                    <Col
                        className="gutter-row"
                        span={12}>
                        <div style={{ display: "flex" }}>
                            <Switch
                                id="userTipsSwitchUTC"
                                onChange={(checked) => this.userConfigChange("convert_to_utc", checked)}
                                checked={this.state.userConfig.convert_to_utc}
                            />
                            <label
                                htmlFor="userTipsSwitchUTC"
                                className="userSettingsLabel">
                                Show UTC date/time
                            </label>
                        </div>
                    </Col>
                    <Col
                        className="gutter-row"
                        span={12}>
                        <div style={{ display: "flex" }}>
                            <Switch
                                id="userTipsSwitchDailyDigests"
                                onChange={(checked) => this.userConfigChange("daily_digest", checked)}
                                checked={this.state.userConfig.daily_digest}
                            />
                            <label
                                htmlFor="userTipsSwitchDailyDigests"
                                className="userSettingsLabel">
                                Receive Daily Digests
                            </label>
                        </div>
                    </Col>
                    <Col
                        className="gutter-row"
                        span={12}>
                        <div style={{ display: "flex" }}>
                            <Switch
                                id="userTipsSwitchTips"
                                onChange={(checked) => this.userConfigChange("user_tips", checked)}
                                checked={this.state.userConfig.user_tips}
                            />
                            <label
                                htmlFor="userTipsSwitchTips"
                                className="userSettingsLabel">
                                Receive Feature Focus Emails
                            </label>
                        </div>
                    </Col>
                    <Col
                        className="gutter-row"
                        span={12}>
                        <div style={{ display: "flex" }}>
                            <Switch
                                id="userTipsSwitchContentPrivate"
                                onChange={(checked) => this.userConfigChange("default_content_privacy", checked)}
                                checked={this.state.userConfig.default_content_privacy}
                            />
                            <label
                                htmlFor="userTipsSwitchContentPrivate"
                                className="userSettingsLabel">
                                Content private by default
                            </label>
                        </div>
                    </Col>
                    <Col
                        className="gutter-row"
                        span={12}>
                        <Popover
                            zIndex={3000}
                            placement="top"
                            content={routeHistoryPopover}>
                            <div style={{ display: "flex" }}>
                                <Switch
                                    id="userTipsSwitchAutoHistory"
                                    onChange={(checked) => this.userConfigChange("auto_show_history_popup", checked)}
                                    checked={this.state.userConfig.auto_show_history_popup}
                                />
                                <label
                                    htmlFor="userTipsSwitchAutoHistory"
                                    className="userSettingsLabel">
                                    Automatically show route history
                                </label>
                            </div>
                        </Popover>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col
                        className="gutter-row"
                        span={12}>
                        {this.props.userConfig.super_admin && (
                            <div style={{ marginBottom: 30 }}>
                                <label
                                    className="userSettingsLabel"
                                    style={{ marginLeft: 0 }}>
                                    Selected View
                                </label>
                                <select
                                    onChange={(e) => this.userConfigChange("view_id", parseInt(e.target.value))}
                                    value={this.state.userConfig.view_id}>
                                    {_.map(this.props.views, (v) => (
                                        <option
                                            key={`${v.id}`}
                                            value={v.id}>
                                            {v.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {this.props.dashboards.length > 1 && (
                            <div style={{ marginBottom: 30 }}>
                                <label
                                    className="userSettingsLabel"
                                    style={{ marginLeft: 0 }}>
                                    Default Workspace
                                </label>
                                <select
                                    onChange={(e) => this.userConfigChange("primary_workspace_id", parseInt(e.target.value))}
                                    value={this.state.userConfig.primary_workspace_id ? this.state.userConfig.primary_workspace_id : -1}>
                                    <option
                                        key={-1}
                                        value={-1}>
                                        No Default
                                    </option>
                                    {this.props.dashboards.map((workspace) => (
                                        <option
                                            key={`${workspace.access_id}`}
                                            value={workspace.access_id}>
                                            {workspace.description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userConfig: state.userDetails.userConfig,
    views: state.views,
    dashboards: state.dashboards || [],
    loggingEnabled: state.userPreferences.loggingEnabled,
    apiVersion: state.userDetails.apiVersion,
});

export default connect(mapStateToProps)(UserSettingsDialog);
