import { Divider, Button, Input, Modal, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import _ from "lodash";
import {
    getAssetObservations,
    getIssueObservations,
    goToBounds,
    receiveAssetObservations,
    selectAsset,
    selectIssue,
    selectObservation,
    updateIssue,
    receiveIssueObservations,
    customAudit,
} from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationCircle,
    faCheckCircle,
    faEdit,
    faInfoCircle,
    faRuler,
    faSpellCheck,
    faHashtag,
    faUserLock,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faThermometerEmpty } from "@fortawesome/pro-light-svg-icons";
import Highlighter from "react-highlight-words";
import { convertToTimezone } from "components/util/TimezoneUtils";
import Tippy from "@tippyjs/react";
import { combineGroundTruthConsensus, StatusElement } from "./issueUtils";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { degreesToUserPreference, getTempUnit } from "../util/TemperatureUtils";
import RouteCoordinateSystems from "../util/RouteCoordinateSystems";
import { RawImageComponent } from "components/RawImageComponent";

const issueObservationsSelector = (state) => state.issues.observations;
const assetObservationsSelector = (state) => state.mlAssets.observations;
const currentBoundsSelector = (state) => state.sessionFilters.mapBounds.current;
const userConfigSelector = (state) => state.userDetails.userConfig;
const issueGroupsSelector = (state) => state.issues.groups;
const assetGroupsSelector = (state) => state.mlAssets.groups;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;

const ObservationItem = ({ observation, setParentSize, temperatureUnits, showFailedMatches }) => {
    const dispatch = useDispatch();

    const onClick = (e) => {
        e.stopPropagation();
        dispatch(selectObservation(observation.id));
    };

    const humanDate = useMemo(() => {
        return moment.unix(observation.timestamp / 1000).format("LLL");
    }, [observation.timestamp]);

    const infoItems = useMemo(() => {
        const observationInfo = observation.observation_info;
        const infoItems = [];

        if (observationInfo.best_temp) {
            infoItems.push(
                <span key="temp">
                    {degreesToUserPreference(observationInfo.best_temp, temperatureUnits)} {getTempUnit(temperatureUnits)}
                </span>,
            );
        }

        if (observationInfo.gap_mm) {
            infoItems.push(<span key="gap">{observationInfo.gap_mm}mm</span>);
        }
        return infoItems;
    }, [observation.observation_info]);

    return (
        <div
            className="ObservationItem"
            onClick={onClick}>
            <span className="ID">
                #{observation.id}
                {observation.matching_state === 1 && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Matched On Consensus"}
                        theme="aivr"
                        zIndex="600"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faSpellCheck}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
                {observation.matching_state === 9 && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Force Matched"}
                        theme="aivr"
                        zIndex="600"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faUserLock}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
                {[88, 89].includes(observation.matching_state) && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Not Matched"}
                        theme="aivr"
                        zIndex="600"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
            </span>
            <span className="SessionID">
                Session:{" "}
                <span style={{ marginLeft: 5 }}>
                    <b>#{observation.session_id}</b>
                </span>
            </span>
            <span className="DateAndTime">{humanDate}</span>
            {infoItems}

            <Tippy
                arrow={true}
                placement="bottom"
                content={
                    <div className="IssueImageContainerToolTip">
                        <RawImageComponent
                            className="Observation"
                            src={observation.image_url}
                            alt="Observation"
                            crossOrigin={"anonymous"}
                        />
                    </div>
                }
                theme="aivr"
                zIndex="1000"
                delay="100">
                <div className="ObservationImageContainer">
                    <RawImageComponent
                        onLoad={setParentSize}
                        className={"ObservationImage"}
                        src={observation.image_url}
                        alt="Observation"
                        crossOrigin={"anonymous"}
                    />
                </div>
            </Tippy>
        </div>
    );
};

const IssueItem = ({ index, issue, searchQuery, isIssue, autoAck, setSize, windowWidth, selected, minHeight, showFailedMatches }) => {
    const dispatch = useDispatch();

    const [notes, setNotes] = useState("");
    const [originalNotes, setOriginalNotes] = useState("");
    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const issueObservations = useSelector(issueObservationsSelector);
    const assetObservations = useSelector(assetObservationsSelector);
    const userConfig = useSelector(userConfigSelector);
    const issueGroups = useSelector(issueGroupsSelector);
    const assetGroups = useSelector(assetGroupsSelector);
    const elrUnits = useSelector(elrUnitsSelector);

    const ref = useRef();

    let observations = [];
    if (isIssue) {
        observations = issueObservations;
    } else {
        observations = assetObservations.observations;
    }

    const setIssueSize = () => {
        const size = ref.current.getBoundingClientRect().height - 20;
        setSize(index, size);
    };

    useEffect(() => {
        setIssueSize();
    }, [setSize, index, windowWidth, selected, observations]);

    useEffect(() => {
        if (notesModalOpen) {
            let note_value = issue.note ? issue.note : "";
            setNotes(note_value);
            setOriginalNotes(note_value);
        }
    }, [notesModalOpen]);

    const humanReadableDate = useMemo(() => {
        if (issue.summary_data && issue.summary_data.last_observed) {
            return moment.unix(issue.summary_data.last_observed / 1000).format("DD/M/YY [at] HH:mm");
        } else {
            return null;
        }
    }, [issue.summary_data]);

    const onIssueItemClick = (evt, openModal = false) => {
        if (
            [
                "StatusContainer",
                "StatusContainer Clickable",
                "StatusContainer ant-popover-open",
                "ant-btn ant-btn-sm",
                "ant-btn ant-btn-primary ant-btn-sm",
            ].includes(evt.target?.className)
        ) {
            return;
        }
        if (selected) {
            if (_.isEmpty(evt)) {
                if (openModal && observations && observations.length) {
                    dispatch(selectObservation(observations[0].id));
                }
            } else {
                dispatch(selectIssue(null));
                dispatch(selectAsset(null));
            }
        } else {
            if (isIssue) {
                if (autoAck && (issue.state === 0 || (issue.state === 1 && issueHasUpdates))) {
                    // acknowledge new issues on selection, and for already acknowledged issues
                    // this will update the time at which they were issued and suppress 'new observations' warnings
                    dispatch(updateIssue(issue.id, { state: 1 }, true));
                }
                dispatch(selectIssue(issue.id));
            } else {
                dispatch(selectAsset(issue.id, showFailedMatches)).then((observations) => {
                    if (openModal && observations && observations.length) {
                        dispatch(selectObservation(observations[0].id));
                    }
                });
            }

            let north = issue["coords"][1] + 0.002;
            let east = issue["coords"][0] + 0.002;
            let south = issue["coords"][1] - 0.002;
            let west = issue["coords"][0] - 0.002;
            dispatch(
                goToBounds({
                    north,
                    south,
                    east,
                    west,
                }),
            );

            const issueType = _.get(issue, "class", "");
            dispatch(
                customAudit(
                    "issue_item_click",
                    { issue_type: issueType, target: "Issue Tab", source: "mouse" },
                    `issue_item_click ${issueType} clicked from the Issues tab`,
                ),
            );
        }
    };

    useEffect(() => {
        if (observations && observations.length) {
            setLoading(false);
        }
    }, [observations]);

    const filteredObservations = useMemo(() => {
        if (!observations) {
            return [];
        }

        return observations.filter((obs) => {
            return !obs.classification || obs.classification === "verified";
        });
    }, [observations]);

    const orderedObservations = useMemo(() => {
        return _.orderBy(filteredObservations, "timestamp", "desc");
    }, [filteredObservations]);

    const observationItems = useMemo(() => {
        if (loading) {
            return (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        if (!selected) {
            return [];
        }

        if (!orderedObservations.length) {
            return <p className="NoObservationsText">No Observations Found</p>;
        }

        return (
            <div
                className="ObservationsList"
                onClick={(e) => e.stopPropagation()}>
                {orderedObservations.map((observation, idx) => {
                    return (
                        <ObservationItem
                            setParentSize={() => setIssueSize()}
                            key={observation.id}
                            observation={observation}
                            temperatureUnits={userConfig.temperature_units}
                        />
                    );
                })}
            </div>
        );
    }, [orderedObservations, selected, loading]);

    const issueHasUpdates = useMemo(() => {
        // 'updated' if match_ts (the last time this issue was updated with new observations)
        // is greater than 'state change ts' - state_change_ts is updated whenever 'state'
        // is passed in update issue - even if it is the same
        if (issue.state_change_ts && issue.state_change_ts * 1000 < issue.match_ts) {
            return true;
        } else {
            return false;
        }
    }, [issue.match_ts, issue.state_change_ts]);

    const issueStatus = useMemo(() => {
        let colour = "red";
        let icon = faExclamationCircle;
        let text = isIssue ? "New Issue" : "New Asset";

        if (issue.state === 1) {
            colour = "grey";
            text = "Acknowledged";
            icon = faInfoCircle;

            if (issueHasUpdates) {
                colour = "orange";
                text = "New Observations";
                icon = faExclamationCircle;
            }
        } else if (issue.state === 2) {
            colour = "green";
            text = "Closed";
            icon = faCheckCircle;
        }

        return (
            <div className={`StatusDiv`}>
                <FontAwesomeIcon
                    icon={icon}
                    className={`Icon ${colour}`}
                />
                <p className="StatusText">{text}</p>
            </div>
        );
    }, [isIssue, issue.state, issueHasUpdates]);

    const updateStatus = useCallback(
        (e, status) => {
            e.stopPropagation();

            dispatch(updateIssue(issue.id, { state: status }, isIssue));
        },
        [dispatch, issue.id, isIssue],
    );

    const reviewButtons = useMemo(() => {
        const buttons = [];

        if ((issue.state !== 1 || issueHasUpdates) && issue.state !== 2) {
            buttons.push(
                <Button
                    key="ack"
                    onClick={(e) => {
                        updateStatus(e, 1);
                        const issueType = _.get(issue, "class", "");
                        dispatch(
                            customAudit(
                                "issue_acknowledge_click",
                                { issue_type: issueType, target: "Issue Tab", source: "mouse" },
                                `issue_acknowledge_click ${issueType} acknowledged from the Issues tab`,
                            ),
                        );
                    }}
                    className="UpdateButton">
                    Acknowledge
                </Button>,
            );
        }

        if (issue.state !== 2) {
            buttons.push(
                <Button
                    key="update"
                    onClick={(e) => updateStatus(e, 2)}
                    className="UpdateButton">
                    Close
                </Button>,
            );
        }

        if (issue.state === 2) {
            buttons.push(
                <Button
                    key="open"
                    onClick={(e) => updateStatus(e, 0)}
                    className="UpdateButton">
                    Re-Open
                </Button>,
            );
        }

        return buttons;
    }, [issue.state, issueHasUpdates, updateStatus]);

    const updateIssuePriority = (e, priority) => {
        e.stopPropagation();
        let update = { priority };
        if (autoAck && issue.state === 0) {
            update.state = 1;
        }
        dispatch(updateIssue(issue.id, update, isIssue));
    };

    const openNotesModal = (e) => {
        e.stopPropagation();
        setNotesModalOpen(true);
    };

    const saveNotes = () => {
        let update = { note: notes };
        if (autoAck && issue.state === 0) {
            update.state = 1;
        }
        dispatch(updateIssue(issue.id, update, isIssue));
        setOriginalNotes(notes);
        setNotesModalOpen(false);
    };

    const handleCancel = () => {
        setNotesModalOpen(false);
        setNotes(originalNotes);
    };

    const convertDate = (date) => {
        const newDate = new Date(date * 1000);
        return convertToTimezone(newDate, userConfig.convert_to_utc);
    };

    const openGroundTruthModal = (e) => {
        e.stopPropagation();
        onIssueItemClick({}, true);
    };

    const observationsWorkspaceWarning = useMemo(() => {
        let content = null;
        if (selected && !loading) {
            const issueCount = _.get(issue, "summary_data.match_count", 0);
            if (issueCount !== issueObservations.length) {
                content = (
                    <Tippy content="Some observations of this issue are not visible in this workspace">
                        <div style={{ display: "inline" }}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                style={{ color: "#e55151", fontSize: "16px", cursor: "pointer" }}
                            />
                        </div>
                    </Tippy>
                );
            }
        }
        return content;
    }, [issue, issueObservations, selected, loading]);

    const greenZoneOverflowExplanation = (
        <Tippy content="'Green zone overflow' refers to how far outside of the safe operating zone the tensioner is sitting, measured in cm">
            <div style={{ display: "inline" }}>
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ fontSize: "16px", cursor: "pointer" }}
                />
            </div>
        </Tippy>
    );

    const infoFields = useMemo(() => {
        const fields = issue.summary_data.fields || {};

        const infoElements = [];

        let match_count = _.get(issue, ["summary_data", "match_count"], false);
        if (match_count) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="match_info">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faHashtag}
                    />
                    <p className="IssueInfoText">
                        <b>Observations</b>: {match_count} (Last seen {humanReadableDate}) {observationsWorkspaceWarning}
                    </p>
                </div>,
            );
        } else {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="match_info">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faHashtag}
                    />
                    <p className="IssueInfoText">
                        <b>Last Observation</b>: {humanReadableDate}
                    </p>
                </div>,
            );
        }

        if (fields.last_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="temp">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Last Temperature</b>: {degreesToUserPreference(fields.last_temperature, userConfig.temperature_units)}{" "}
                        {getTempUnit(userConfig.temperature_units)}
                    </p>
                </div>,
            );
        }

        if (fields.highest_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="max_temp">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Highest Temperature</b>: {degreesToUserPreference(fields.highest_temperature, userConfig.temperature_units)}{" "}
                        {getTempUnit(userConfig.temperature_units)}
                    </p>
                </div>,
            );
        }

        if (fields.last_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Last Gap</b>: {fields.last_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.max_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="max_gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Largest Gap</b>: {fields.max_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.min_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="min_gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Smallest Gap</b>: {fields.min_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.last_height_diff_px) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="last_height_diff_px">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Last Height Difference</b>: {fields.last_height_diff_px}px
                    </p>
                </div>,
            );
        }

        if (fields.bottom_out_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="severity">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Latest Bottom Out Temperature</b>:{" "}
                        {fields.bottom_out_temperature > 50 ? `50°C+ (${fields.bottom_out_temperature}°C)` : `${fields.bottom_out_temperature}°C`}
                    </p>
                </div>,
            );
        }

        if (fields.severity) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="severity">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Green zone overflow</b>: {fields.severity}cm {greenZoneOverflowExplanation}
                    </p>
                </div>,
            );
        }

        if (issue.elr) {
            let elrObj = null;
            RouteCoordinateSystems.forEach((system) => {
                if (Object.keys(system.handlerClass.display_formats).indexOf(elrUnits) !== -1) {
                    elrObj = system.create(issue.elr, issue.chains, issue.trid);
                }
            });
            if (elrObj !== null) {
                infoElements.push(
                    <div
                        className="IssueInfo"
                        key="severity">
                        <FontAwesomeIcon
                            className="InfoIcon"
                            icon={faMapMarkerAlt}
                        />
                        <p className="IssueInfoText">
                            <b>Route Position</b>: {elrObj.to_string(elrUnits)}
                        </p>
                    </div>,
                );
            }
        }

        if (!isIssue) {
            let propertyElements = [];

            const propertyData = combineGroundTruthConsensus(issue.ground_truth_data, issue.consensus_data, issue.class);
            propertyData.forEach((property) => {
                propertyElements.push(
                    <tr className="PropertyRow">
                        <td className="PropertyKey">{property.name}</td>
                        <td className="PropertyValue">{property.value}</td>
                        {property.status && (
                            <>
                                <td className="PropertyValue">
                                    <StatusElement
                                        issue={issue}
                                        groundTruthKey={property.fieldName}
                                        status={property.status}
                                    />
                                </td>
                                <td className="PropertyValue">
                                    <FontAwesomeIcon
                                        className={"EditIcon"}
                                        icon={faEdit}
                                        onClick={openGroundTruthModal}
                                    />
                                </td>
                            </>
                        )}
                    </tr>,
                );
            });

            let propertiesContainerElement = (
                <table className="PropertiesContainer">
                    <tbody>{propertyElements}</tbody>
                </table>
            );

            infoElements.push(propertiesContainerElement);
        }

        return infoElements;
    }, [issue, isIssue, humanReadableDate, observationsWorkspaceWarning]);

    const image = useMemo(() => {
        let classes = ["IssueImage"];
        if (issue.observation_type === 2 && !_.get(issue.summary_data, ["images", "annotated"], false)) {
            classes.push("Rotated");
        }
        return (
            <RawImageComponent
                className={classes.join(" ")}
                src={_.get(issue.summary_data, ["images", "thumbnail"])}
                alt="Observation"
                crossOrigin={"anonymous"}
                loading="lazy"
            />
        );
    }, [issue.observation_type, issue.summary_data]);

    const groupsToUse = useMemo(() => {
        return isIssue ? issueGroups : assetGroups;
    }, [assetGroups, isIssue, issueGroups]);

    return (
        <>
            {!!notesModalOpen && (
                <Modal
                    key={`notes-modal-${issue.id}`}
                    title="Edit Issue Notes"
                    onCancel={handleCancel}
                    onOk={saveNotes}
                    footer={[
                        <Button
                            key="cancel"
                            type="default"
                            onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={saveNotes}>
                            Save
                        </Button>,
                    ]}
                    visible={true}>
                    <div className="IssueNotesContainer">
                        <Input.TextArea
                            autoFocus
                            placeholder="Enter a note about this issue..."
                            rows={10}
                            className="NotesInput"
                            value={notes}
                            onPressEnter={saveNotes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </Modal>
            )}

            <div
                className={"IssueListItem" + (selected ? " Open" : " Closed")}
                onClick={onIssueItemClick}
                style={{ minHeight }}
                ref={ref}>
                <div className="IssueHeader">
                    <div className="IssueLeftContainer">
                        <div className="IssueInfo">
                            <p className="IssueType">{_.get(groupsToUse, [issue.issue_group_id, "display_name"], issue.class) || issue.class}</p>
                            {issueStatus}
                        </div>

                        <div className="IssueInfo Note">
                            {issue.note ? (
                                <div className="IssueNoteText">
                                    <Tooltip
                                        overlayClassName="IssueNoteTextTooltip"
                                        placement="bottomLeft"
                                        mouseLeaveDelay={0}
                                        title={issue.note}>
                                        <div className={searchQuery.length ? "" : "IssueNoteTextCollapsed"}>
                                            <Highlighter
                                                highlightClassName="ObcTextHighlight"
                                                searchWords={searchQuery.split(" ")}
                                                autoEscape={true}
                                                textToHighlight={issue.note}
                                            />
                                        </div>
                                    </Tooltip>
                                    {issue.note_source && issue.note_change_ts && (
                                        <Tooltip
                                            placement="bottomLeft"
                                            mouseLeaveDelay={0}
                                            title={
                                                <span>
                                                    Last edited by {issue.note_source} <br />
                                                    Date: {convertDate(issue.note_change_ts)}
                                                </span>
                                            }>
                                            <div className="IssueAuthorAndDate">
                                                {issue.note_source} - {moment.duration(moment().diff(moment(issue.note_change_ts * 1000))).humanize()} ago
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            ) : issue.note_source && issue.note_change_ts ? (
                                <>
                                    <span className="IssueRemoved">[Removed]</span>
                                    <Tooltip
                                        placement="bottomLeft"
                                        mouseLeaveDelay={0}
                                        title={
                                            <span>
                                                Note removed by {issue.note_source} <br />
                                                Date: {convertDate(issue.note_change_ts)}
                                            </span>
                                        }>
                                        <span className="IssueAuthorAndDate">
                                            {issue.note_source} - {moment.duration(moment().diff(moment(issue.note_change_ts * 1000))).humanize()} ago
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <p className="IssueNoteText Empty">Enter a note</p>
                            )}
                            <FontAwesomeIcon
                                className={"InfoIcon Edit" + (issue.note ? "" : " Empty")}
                                icon={faEdit}
                                onClick={openNotesModal}
                            />
                        </div>

                        {/* <div className="IssueInfo">
                            <FontAwesomeIcon className="InfoIcon" icon={faClock} />
                            <p className="IssueInfoText">
                                <b>Last observed</b>: {humanReadableDate}
                            </p>
                        </div> */}

                        {infoFields}

                        {isIssue && (
                            <div
                                className="IssueInfo"
                                style={{ marginTop: 10, flexDirection: "column" }}>
                                <p style={{ marginBottom: 3 }}>
                                    <strong>Priority</strong>
                                </p>
                                <Button.Group>
                                    <Button
                                        value="low"
                                        className={"RadioButton" + (issue.priority === 1 ? " Active" : "")}
                                        onClick={(e) => updateIssuePriority(e, 1)}>
                                        Low
                                    </Button>
                                    <Button
                                        value="medium"
                                        className={"RadioButton" + (issue.priority === 2 ? " Active" : "")}
                                        onClick={(e) => updateIssuePriority(e, 2)}>
                                        Medium
                                    </Button>
                                    <Button
                                        value="high"
                                        className={"RadioButton" + (issue.priority === 3 ? " Active" : "")}
                                        onClick={(e) => updateIssuePriority(e, 3)}>
                                        High
                                    </Button>
                                </Button.Group>
                            </div>
                        )}

                        {isIssue && (
                            <div
                                className="IssueInfo"
                                style={{ marginTop: 10 }}>
                                {reviewButtons}
                            </div>
                        )}
                    </div>

                    <div className="IssueRightContainer">
                        <div className="IssueRightContainerTop">
                            <div className="IssueIdContainer">
                                {issue.recalculating && (
                                    <Tooltip
                                        title={
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <span>Recalculating {isIssue ? "Issue" : "Asset"}</span>
                                                <span>Some data may be out of date</span>
                                            </div>
                                        }>
                                        <div>
                                            <OBCSpinner
                                                size={20}
                                                color={"#e8dfff"}
                                                speed={3}
                                            />
                                        </div>
                                    </Tooltip>
                                )}

                                <div className="IssueID">
                                    <span>
                                        #
                                        <Highlighter
                                            highlightClassName="ObcTextHighlight"
                                            searchWords={searchQuery.split(" ")}
                                            autoEscape={true}
                                            textToHighlight={String(issue.id)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="IssueImageContainer">
                            <Tippy
                                arrow={true}
                                placement="bottom"
                                content={<div className="IssueImageContainerToolTip">{image}</div>}
                                theme="aivr"
                                zIndex="1000"
                                delay="100">
                                {image}
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className="IssueObservations">
                    <Divider className="Divider">Observations</Divider>
                    {observationItems}
                </div>
            </div>
        </>
    );
};

export default IssueItem;
