import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Tippy from "@tippyjs/react";
import { getRouteMetadata } from "redux/actions/index";
import MetadataFeed from "../route/MetadataFeed";
import { getAbsoluteTimestamp } from "../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class DeviceInformation extends React.PureComponent {
    findMostRecent = (metadata, timestamp) => {
        if (metadata) {
            return _.findLast(metadata, (data) => data.timestamp <= timestamp);
        }
    };

    componentDidMount() {
        this.componentDidUpdate({});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.routeID !== prevProps.routeID) {
            this.props.dispatch(getRouteMetadata(this.props.routeID, ["DEVICE_INFO"]));
        }
    }

    render() {
        let timestamp = this.props.timestamp;

        let deviceStatus = null;
        let signalStatus = null;
        let batteryStatus = null;
        let storageStatus = null;

        if (timestamp) {
            let deviceInfo = this.findMostRecent(this.props.deviceData, timestamp);
            if (deviceInfo) {
                deviceStatus = (
                    <div>
                        <small>
                            App version: {deviceInfo.data.app.version} build {deviceInfo.data.app.build}
                        </small>
                        <br />
                        <small>
                            Device: {deviceInfo.data.device.brand} {deviceInfo.data.device.model}
                        </small>
                    </div>
                );
            }
            let batteryInfo = this.findMostRecent(this.props.batteryData, timestamp);
            if (batteryInfo) {
                batteryStatus = (
                    <small>
                        Battery: {batteryInfo.data.status}, {batteryInfo.data.chargePercentage}% remaining
                    </small>
                );
            }
            let storageInfo = this.findMostRecent(this.props.storageData, timestamp);
            if (storageInfo) {
                storageStatus = <small>Storage: {storageInfo.data.percentFree}% free</small>;
            }
            let signalInfo = this.findMostRecent(this.props.cellSignalData, timestamp);
            if (signalInfo) {
                signalStatus = <small>Cell signal strength: {signalInfo.data}dB</small>;
            } else {
                signalStatus = <small>Cell signal strength: Unknown</small>;
            }
        }

        return (
            <MetadataFeed
                items={["BATTERY", "STORAGE", "CELL_SIGNAL_STRENGTH"]}
                lowerOffset={120}
                upperOffset={120}
                stepSize={60}>
                <Tippy
                    placement="right"
                    arrow={true}
                    theme="light"
                    content={
                        <div>
                            {deviceStatus}
                            {signalStatus}
                            <br />
                            {batteryStatus}
                            <br />
                            {storageStatus}
                        </div>
                    }>
                    <span
                        style={{
                            backgroundColor: "#6C43DF",
                            color: "#E8DFFF",
                            borderRadius: "0px 0px 0px 12px",
                            fontWeight: 600,
                            padding: "5px 10px",
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                </Tippy>
            </MetadataFeed>
        );
    }
}

const mapStateToProps = ({ playlist, routeMetadata }) => {
    const routeID = playlist.data.routeID;
    const isVideo = playlist.position.isVideo;
    const sourceIndex = playlist.position.sourceIndex;

    const playingPlaylist = isVideo ? _.get(playlist.data, ["video", sourceIndex], []) : playlist.data.image;
    const index = playlist.position.currentIndex;
    const offset = playlist.position.currentTimeOffset || 0;

    let absoluteTimestamp = getAbsoluteTimestamp(routeID, playingPlaylist, index, isVideo, offset);

    return {
        routeID: routeID,
        timestamp: absoluteTimestamp,
        deviceData: _.get(routeMetadata, ["DEVICE_INFO", routeID], []),
        batteryData: _.get(routeMetadata, ["BATTERY", routeID], []),
        storageData: _.get(routeMetadata, ["STORAGE", routeID], []),
        cellSignalData: _.get(routeMetadata, ["CELL_SIGNAL_STRENGTH", routeID], []),
    };
};

export default connect(mapStateToProps)(DeviceInformation);
