// TODO: fix memory leak

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFileExport, faPlus, faFile } from "@fortawesome/pro-light-svg-icons";

import {
    getTrackGeomHeaders,
    getTrackGeomData,
    routeSelected,
    receiveTrackGeomHeaders,
    receiveTrackGeomData,
    websocketMessage,
    getTrackGeomHistory,
    addTrackGeomExport,
    getInspectionDeviceConfig,
} from "../../redux/actions/index";
import { useSelector, useDispatch, useStore } from "react-redux";
import ChartV2 from "./ChartV2";
import { Button, InputNumber, Modal, Select, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import LoadingOverlay from "../util/LoadingOverlay";
import ELRMileAndChain from "../util/ELRMileAndChain";
import moment from "moment";
import { boundedBinarySearch } from "../util/PlaylistUtils";
import { asyncLoadGeometryFile } from "./ChartV2/utils";
import { convertToTimezone } from "../util/TimezoneUtils";

const { Option } = Select;

const geomHeadersSelector = (state) => state.trackGeometry.trackGeometryHeaders;
const geomDataFilesSelector = (state) => state.trackGeometry.dataFiles;
const geometryDataSelector = (state) => state.trackGeometry.data;
const deviceDescriptionSelector = (state) => state.trackGeometry.description;
const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
const videoSelector = (state) => {
    return _.get(state.playlist.data, ["video"], []);
};
const userConfigSelector = (state) => state.userDetails.userConfig;

const sessionDataSelector = (state) => {
    if (state.playlist.data) {
        return state.sessions[state.playlist.data.routeID];
    } else {
        return null;
    }
};
const sessionTagsSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID, "tags"], []);

const userEmailSelector = (state) => {
    const widgetKey = state.dashboardWidgetKey;
    const workspaceID = state.widgetData.DASHBOARD[widgetKey].state.workspace_id;
    for (let dashboard of state.dashboards) {
        if (dashboard.access_id === workspaceID) {
            return dashboard.email_address;
        }
    }
    return undefined;
};

const userIsAdminSelector = (state) => {
    const widgetKey = state.dashboardWidgetKey;
    const workspaceID = state.widgetData.DASHBOARD[widgetKey].state.workspace_id;
    for (let dashboard of state.dashboards) {
        if (dashboard.access_id === workspaceID) {
            return dashboard.permissions.admin;
        }
    }
    return undefined;
};

const ChartV2Container = () => {
    const store = useStore();
    const dispatch = useDispatch();

    const [selectedDataTypes, setSelectedDataTypes] = useState([]);
    const [initialised, setInitialised] = useState(false);
    const [gettingData, setGettingData] = useState(false);
    const [lastRequestedTimestamp, setLastRequestedTimestamp] = useState(0);
    const [dataPresent, setDataPresent] = useState(false);
    const [headersRouteID, setHeadersRouteID] = useState(null);
    const [colorScheme, setColorScheme] = useState("dark");
    const [exceedenceSpeed, setExceedenceSpeed] = useState("line");
    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [comparisonSession, setComparisonSession] = useState(null);
    const [comparisonChoice, setComparisonChoice] = useState(null);
    const [comparisonOffset, setComparisonOffset] = useState(0.0);
    const [verticalComparisonOffset, setVerticalComparisonOffset] = useState(0.0);
    const [showCompareConfig, setShowCompareConfig] = useState(false);
    const [showExportConfig, setShowExportConfig] = useState(false);
    const [trackGeometryPresignedFiles, setTrackGeometryPresignedFiles] = useState([]);
    const [trackGeometryPdfRequested, setTrackGeometryPdfRequested] = useState(false);

    const sessionData = useSelector(sessionDataSelector);
    const sessionTags = useSelector(sessionTagsSelector);
    const geomHeaders = useSelector(geomHeadersSelector);
    const geomDataFiles = useSelector(geomDataFilesSelector);
    const geometryData = useSelector(geometryDataSelector);
    const dashboardData = useSelector(dashboardDataSelector);
    const deviceDescription = useSelector(deviceDescriptionSelector);
    const video = useSelector(videoSelector);
    const userConfig = useSelector(userConfigSelector);
    const userEmail = useSelector(userEmailSelector);
    const userIsAdmin = useSelector(userIsAdminSelector);

    const [active, setActive] = useState(true);
    const [selectOpen, setSelectOpen] = useState(false);

    const currentVideo = useMemo(() => {
        return _.get(video, [dashboardData.position.sourceIndex], []);
    }, [video, dashboardData.position.sourceIndex]);

    const handleSelect = (e) => {
        setSelectedDataTypes(e);
    };

    const currentDashboardTimestamp = useMemo(() => {
        return _.get(dashboardData, ["position", "timestamp"], 0);
    }, [dashboardData]);

    useEffect(() => {
        setSelectedTimestamp(currentDashboardTimestamp);
    }, [currentDashboardTimestamp]);

    const geometryBounds = useMemo(() => {
        if (!currentVideo || !currentVideo.length) {
            if (sessionData) {
                return [sessionData.first_seen, sessionData.first_seen + sessionData.length_seconds];
            } else {
                return [0, 0];
            }
        }
        return [currentVideo[0][3][2], currentVideo[currentVideo.length - 1][4][2]];
    }, [currentVideo, sessionData]);

    const timestampsToRequest = useMemo(() => {
        if (geomDataFiles && geomDataFiles.length) {
            return [0, 0];
        } else {
            let startingTimestamp = geometryBounds[0];
            if (lastRequestedTimestamp) {
                startingTimestamp = lastRequestedTimestamp + 0.0001;
            }
            return [startingTimestamp, Math.min(startingTimestamp + 60, geometryBounds[1])];
        }
    }, [geometryBounds, geomDataFiles, lastRequestedTimestamp]);

    const sessionHasGeometryData = useMemo(() => sessionTags.includes("Track Geometry"), [sessionTags]);

    useEffect(() => {
        dispatch(routeSelected(null));
        dispatch(receiveTrackGeomData([]));
        dispatch(receiveTrackGeomHeaders([], [], [], null));
        setSelectedDataTypes([]);
        setShowCompareConfig(false);
        setShowExportConfig(false);
        setTrackGeometryPdfRequested(false);
        setInitialised(false);
        setHeadersRouteID(null);
    }, [dashboardData.routeID, dispatch]);

    useEffect(() => {
        if (dashboardData.routeID && !initialised && currentDashboardTimestamp > 0) {
            dispatch(routeSelected(dashboardData.routeID, 0));
            dispatch(getInspectionDeviceConfig(dashboardData.routeID));
        }
    }, [dispatch, dashboardData.routeID, currentDashboardTimestamp, initialised]);

    // cleanup
    useEffect(() => {
        setDataPresent(false);
        setTrackGeometryPresignedFiles([]);
    }, [dashboardData.routeID]);

    useEffect(() => {
        if (dashboardData.routeID && dashboardData.routeID !== headersRouteID && sessionHasGeometryData) {
            setComparisonSession(null);
            setComparisonChoice(null);
            dispatch(getTrackGeomHeaders(dashboardData.routeID)).then(([headers, data_files, presigned_src_file_links]) => {
                setHeadersRouteID(dashboardData.routeID);
                if (headers) {
                    const defaultHeaders = headers
                        .filter((header) => {
                            return header.info.default;
                        })
                        .map((header) => header.header_name);
                    setGettingData(false);
                    setLastRequestedTimestamp(0);
                    setSelectedDataTypes(defaultHeaders);
                }

                if (data_files && data_files.length) {
                    const blankGeometryData = data_files.flatMap((f) => {
                        return [{ loaded: false }, ...Array(f.record_count - 1).fill({})];
                    });
                    dispatch(receiveTrackGeomData(blankGeometryData));
                    setDataPresent(true);
                }

                const processPresignedFile = (presignedFileLink) => {
                    return {
                        presigned_link: presignedFileLink.linkStr,
                        filesize_str: presignedFileLink.fileSizeStr,
                        disabled: presignedFileLink.linkStr === "" || presignedFileLink.linkStr === null,
                    };
                };

                if (presigned_src_file_links && presigned_src_file_links.length) {
                    setTrackGeometryPresignedFiles(
                        presigned_src_file_links.map((presigned_file) => {
                            return {
                                root_filename: presigned_file.object_filename,
                                tgd_file: processPresignedFile(presigned_file.tgd),
                                csv_file: processPresignedFile(presigned_file.csv),
                                mwv_csv_file: processPresignedFile(presigned_file.mwv_csv),
                            };
                        }),
                    );
                }
                setInitialised(true);
            });
        }
    }, [dashboardData.routeID, dispatch, headersRouteID, sessionHasGeometryData]);

    const onSelectTimestamp = useCallback(
        (timestamp) => {
            setSelectedTimestamp(timestamp);
            dispatch(
                websocketMessage({
                    action: "request_position",
                    timestamp: timestamp,
                }),
            );
        },
        [dispatch],
    );

    const dataFiles = useMemo(() => {
        if (sessionHasGeometryData && geomDataFiles && geomDataFiles.length) {
            let offset = 0;
            return geomDataFiles.map((d) => {
                const mile_sign = d.mile_and_eighth < 0 ? -1 : 1;
                const mile = Math.floor(Math.abs(d.mile_and_eighth));
                const chain = (Math.abs(d.mile_and_eighth) - mile) * 100;
                const position = Math.round(mile_sign * (mile * 80 + chain));
                const start_offset = offset;
                offset += d.record_count;
                const end_offset = offset - 1;
                return {
                    elr: d.elr === null || d.elr === "unk" ? "?" : d.elr,
                    track: d.track_id === null || d.track_id === "unk" ? "?" : d.track_id,
                    track_category: d.track_category,
                    line_speed: d.line_speed,
                    start: start_offset,
                    end: end_offset,
                    position,
                    startTS: d.start_timestamp,
                    endTS: d.end_timestamp,
                    filename: d.filename,
                    poi: d.poi,
                };
            });
        } else {
            return null;
        }
    }, [geomDataFiles, sessionHasGeometryData]);

    const elrDataFromDataFiles = useMemo(() => {
        if (dataFiles) {
            return dataFiles.reduce((a, d) => {
                const elrCategory = `${d.elr} ${d.track}`;
                const new_data = {
                    name: elrCategory,
                    elr: d.elr,
                    track: d.track,
                    track_category: d.track_category,
                    line_speed: d.line_speed,
                    start: d.start,
                    end: d.end,
                    startPos: d.position,
                    endPos: d.position,
                    startTS: d.startTS,
                    endTS: d.endTS,
                    intermediates: [],
                };

                if (a.length === 0) {
                    a.push(new_data);
                } else {
                    const last = a[a.length - 1];
                    if (elrCategory === last.name) {
                        last.intermediates.push([d.start, d.position, d.track_category, d.line_speed]);
                        last.end = d.end;
                        last.endPos = d.position;
                        last.endTS = d.end_timestamp;
                    } else {
                        a.push(new_data);
                    }
                }
                return a;
            }, []);
        } else {
            return null;
        }
    }, [dataFiles]);

    const poiData = useMemo(() => {
        let seen_poi = {};
        if (dataFiles) {
            return dataFiles
                .filter((d) => {
                    return !!d.poi && (seen_poi.hasOwnProperty(d.poi) ? false : (seen_poi[d.poi] = true));
                })
                .map((d, index) => ({
                    location: (d.start + d.end) / 2,
                    name: d.poi,
                    index,
                }));
        } else {
            return [];
        }
    }, [dataFiles]);

    const elrData = useMemo(() => {
        if (elrDataFromDataFiles) {
            return elrDataFromDataFiles;
        } else {
            return geometryData.reduce((a, d, i) => {
                const location = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_mile_yards", {
                    ELR: d.elr,
                    MILE: d.miles,
                    YARDS: d.yards,
                    TRACK: d.trid,
                });
                const elrCategory = `${location.elr} ${location.track}`;
                if (a.length === 0) {
                    a.push({
                        name: elrCategory,
                        elr: location.elr,
                        track: location.track,
                        start: i,
                        end: i,
                        startPos: location.position,
                        endPos: location.position,
                        track_category: "Unknown",
                        line_speed: "Unknown",
                        intermediates: [],
                    });
                } else {
                    const last = a[a.length - 1];
                    if (elrCategory === last.name) {
                        last.end = i;
                        const last_eighth = Math.floor(last.endPos / 10);
                        last.endPos = location.position;
                        const new_eighth = Math.floor(last.endPos / 10);
                        if (new_eighth !== last_eighth) {
                            last.intermediates.push([i, location.position, null, null]);
                        }
                    } else {
                        a.push({
                            name: elrCategory,
                            elr: location.elr,
                            track: location.track,
                            start: i,
                            end: i,
                            startPos: location.position,
                            endPos: location.position,
                            track_category: "Unknown",
                            line_speed: "Unknown",
                            intermediates: [],
                        });
                    }
                }
                return a;
            }, []);
        }
    }, [elrDataFromDataFiles, geometryData]);

    const lastUpdate = useMemo(() => {
        return new Date().getTime();
    }, [geometryData]);

    useEffect(() => {
        if (
            sessionHasGeometryData &&
            initialised &&
            dashboardData.routeID === headersRouteID &&
            !gettingData &&
            lastRequestedTimestamp < timestampsToRequest[1]
        ) {
            setGettingData(true);

            const [startTS, endTS] = timestampsToRequest;

            const combine = lastRequestedTimestamp > 0;

            console.log("Requesting track geometry data for time bounds: ", startTS, endTS);

            dispatch(getTrackGeomData(dashboardData.routeID, startTS * 1000, endTS * 1000, combine)).then((data) => {
                console.log(`Track geometry ${(100 * (endTS - geometryBounds[0])) / (geometryBounds[1] - geometryBounds[0])}% loaded with ${data} items`);
                setLastRequestedTimestamp(endTS);
                setGettingData(false);
                if (data) {
                    setDataPresent(true);
                }
            });
        }
    }, [
        currentVideo,
        dispatch,
        gettingData,
        dashboardData.routeID,
        headersRouteID,
        lastRequestedTimestamp,
        initialised,
        timestampsToRequest,
        geometryBounds,
        sessionHasGeometryData,
    ]);

    const orderedDataTypes = useMemo(() => {
        return _.chain(selectedDataTypes)
            .map((t) => geomHeaders.find((h) => h.header_name === t))
            .sortBy((h) => (h.info.order !== null ? h.info.order : h.name))
            .map((h) => h.header_name)
            .value();
    }, [selectedDataTypes, geomHeaders]);

    const title = useMemo(() => {
        if (geometryBounds[0] === 0) {
            return `${deviceDescription}`;
        }

        const startTS = moment.unix(geometryBounds[0]).format("DD/MM/YYYY HH:mm:ss");
        const endTS = moment.unix(geometryBounds[1]).format("HH:mm:ss");
        return `${deviceDescription} ${startTS} - ${endTS}`;
    }, [deviceDescription, geometryBounds]);

    const comparisonTitle = useMemo(() => {
        if (comparisonSession) {
            const [vehicle, , route] = comparisonSession.names[0].split("-");
            const comparisonSessionDate = moment.unix(comparisonSession.timestamp / 1000).format("DD/MM/YYYY");
            return (
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <p>
                        {vehicle} {comparisonSessionDate} {route}
                    </p>
                </div>
            );
        }
    }, [comparisonSession]);

    const comparisonAdjustment = useMemo(() => {
        return (
            <div style={{ display: "flex", justifyContent: "center", justifyItems: "center" }}>
                <p style={{ padding: "0px 0px 0px 20px" }}>Horizontal adjustment:&nbsp;</p>
                <InputNumber
                    value={comparisonOffset}
                    onChange={setComparisonOffset}
                    min={-25}
                    max={25}
                    step={0.2}
                    formatter={(v) => `${v}m`}
                    parser={(v) => v.replace(/[^-.0-9]/g, "")}
                />
                <p style={{ padding: "0px 0px 0px 20px" }}>Vertical adjustment (divs):&nbsp;</p>
                <InputNumber
                    value={verticalComparisonOffset}
                    onChange={setVerticalComparisonOffset}
                    min={-25}
                    max={25}
                    step={0.05}
                    formatter={(v) => `${v}`}
                    parser={(v) => v.replace(/[^-.0-9]/g, "")}
                />
            </div>
        );
    }, [comparisonOffset, setComparisonOffset, verticalComparisonOffset, setVerticalComparisonOffset]);

    const getTrackGeometryHistory = useCallback(() => {
        if (dataFiles) {
            const dataForTimestamp = dataFiles.find((_f) => _f.endTS >= selectedTimestamp && _f.startTS <= selectedTimestamp);
            if (dataForTimestamp) {
                asyncLoadGeometryFile(dataForTimestamp, store, dispatch).then((allData) => {
                    const lowIndex = dataForTimestamp.start;
                    const highIndex = dataForTimestamp.end;
                    const index = boundedBinarySearch(selectedTimestamp, allData, lowIndex, highIndex, (geom) => geom.timestamp);
                    const selectedData = allData[index];
                    dispatch(getTrackGeomHistory(dashboardData.routeID, selectedData.elr, selectedData.trid, selectedData.miles, selectedData.yards)).then(
                        (history) => {
                            if (history.length) {
                                setComparisonChoice(_.uniqBy(history, (h) => h.session_id));
                            }
                        },
                    );
                });
            }
        }
    }, [dataFiles, dashboardData.routeID, selectedTimestamp, store, dispatch]);

    const sessionCompareSelect = useCallback((sessionInfo) => {
        console.log("Selected session for comparison:", sessionInfo);
        setComparisonChoice(null);
        setComparisonSession(sessionInfo);
    }, []);

    const sessionCompareCancelSelect = useCallback(() => {
        setComparisonChoice(null);
    }, []);

    const clearCompare = useCallback(() => {
        setComparisonSession(null);
        setComparisonOffset(0);
        setVerticalComparisonOffset(0);
    }, []);

    const compareSessionInfo = useMemo(() => {
        if (comparisonChoice) {
            return (
                <>
                    {_.map(comparisonChoice, (f) => {
                        const dpDate = new Date(f.timestamp);
                        const niceDate = convertToTimezone(dpDate, userConfig.convert_to_utc);
                        return (
                            <div
                                className="trackGeomCompareItem"
                                key={f.session_id}>
                                <div className="trackGeomCompareDate">
                                    <span className="trackGeomCompareSessionID">#{f.session_id}</span> {niceDate}
                                </div>
                                {_.uniq(f.names).map((name) => (
                                    <div
                                        key={name}
                                        className={"trackGeomCompareSessionListItem"}>
                                        - {name}
                                    </div>
                                ))}
                                <Button
                                    className={"trackGeomCompareSelect"}
                                    onClick={() => sessionCompareSelect(f)}>
                                    Compare
                                </Button>
                            </div>
                        );
                    })}
                    <Button
                        className={"trackGeomCompareCancel"}
                        onClick={sessionCompareCancelSelect}>
                        Cancel
                    </Button>
                </>
            );
        } else if (comparisonSession) {
            const dpDate = new Date(comparisonSession.timestamp);
            const niceDate = convertToTimezone(dpDate, userConfig.convert_to_utc);
            return (
                <div className="trackGeomCompareItem">
                    <div className="trackGeomCompareTitle">Comparing against:</div>
                    <div className="trackGeomCompareDate">
                        <span className="trackGeomCompareSessionID">#{comparisonSession.session_id}</span> {niceDate}
                    </div>
                    {_.uniq(comparisonSession.names).map((name) => (
                        <div
                            className={"trackGeomCompareSessionListItem"}
                            key={name}>
                            - {name}
                        </div>
                    ))}
                    <Button
                        className={"trackGeomCompareCancel"}
                        onClick={clearCompare}>
                        Stop Comparing
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="trackGeomCompareItem">
                    <div className="trackGeomCompareTitle">No session selected for comparison</div>
                    <Button
                        className="trackGeomCompareChoose"
                        onClick={getTrackGeometryHistory}>
                        Pick a session
                    </Button>
                </div>
            );
        }
    }, [
        comparisonChoice,
        comparisonSession,
        clearCompare,
        getTrackGeometryHistory,
        sessionCompareSelect,
        userConfig.convert_to_utc,
        sessionCompareCancelSelect,
    ]);

    const compareModal = useMemo(() => {
        return (
            <Modal
                visible={showCompareConfig}
                closable={true}
                maskClosable={true}
                onCancel={() => setShowCompareConfig(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setShowCompareConfig(false)}>
                        Close
                    </Button>,
                ]}
                title={"Track Geometry Comparison"}>
                {compareSessionInfo}
            </Modal>
        );
    }, [compareSessionInfo, showCompareConfig]);

    const exportSrcFilesDownloads = useMemo(() => {
        if (trackGeometryPresignedFiles.length === 0) {
            return <p>No associated files available for download</p>;
        }
        return (
            <>
                {trackGeometryPresignedFiles.map((presignedFile) => {
                    return (
                        <div
                            key={presignedFile.root_filename}
                            className="widget__Chart__Container__Export__SrcFiles__Row">
                            <p>{presignedFile.root_filename}</p>
                            <Button
                                title="Download RAW tgd"
                                href={presignedFile.tgd_file.presigned_link}
                                disabled={presignedFile.tgd_file.disabled}
                                target="_blank"
                                rel="noopener noreferrer"
                                type="primary">
                                TGD {presignedFile.tgd_file.filesize_str}
                                {presignedFile.tgd_file.disabled && "Unavailable"} <DownloadOutlined />
                            </Button>
                            <Button
                                title="Download csv"
                                href={presignedFile.csv_file.presigned_link}
                                disabled={presignedFile.csv_file.disabled}
                                target="_blank"
                                rel="noopener noreferrer"
                                type="primary">
                                CSV {presignedFile.csv_file.filesize_str}
                                {presignedFile.csv_file.disabled && "Unavailable"} <DownloadOutlined />
                            </Button>
                            <Button
                                title="Download mwv csv"
                                href={presignedFile.mwv_csv_file.presigned_link}
                                disabled={presignedFile.mwv_csv_file.disabled}
                                target="_blank"
                                rel="noopener noreferrer"
                                type="primary">
                                MWV CSV {presignedFile.mwv_csv_file.filesize_str}
                                {presignedFile.mwv_csv_file.disabled && "Unavailable"} <DownloadOutlined />
                            </Button>
                        </div>
                    );
                })}
            </>
        );
    }, [trackGeometryPresignedFiles]);

    const handleTrackGeometryPdfRequest = useCallback(() => {
        if (dashboardData["routeID"] === undefined || !userEmail) {
            message.error("Unable to create PDF generation request", 5);
        } else {
            dispatch(addTrackGeomExport(dashboardData["routeID"], userEmail)).then((success) => {
                if (success) {
                    message.success(`PDF will be sent to ${userEmail}`, 5);
                    setTrackGeometryPdfRequested(true);
                } else {
                    message.error("Unable to create PDF generation request", 5);
                }
            });
        }
    }, [dashboardData, userEmail, dispatch]);

    const exportInfo = useMemo(() => {
        let missingMWVData = trackGeometryPresignedFiles.some((file_obj) => {
            return file_obj.mwv_csv_file === null || file_obj.mwv_csv_file?.disabled;
        });
        return (
            <div className="widget__Chart__Container__Export">
                <div className="widget__Chart__Container__Export__SrcFiles">
                    <h3>Track Geometry Source Files</h3>
                    {exportSrcFilesDownloads}
                </div>
                <div className="widget__Chart__Container__Export__PdfTrigger">
                    <h3>Track Geometry PDF Export</h3>
                    <Button
                        type="primary"
                        onClick={handleTrackGeometryPdfRequest}
                        disabled={!dataPresent || !userEmail || !dashboardData.routeID || trackGeometryPdfRequested || missingMWVData}>
                        {trackGeometryPdfRequested ? "PDF Requested" : "Request PDF"}
                    </Button>
                </div>
            </div>
        );
    }, [
        exportSrcFilesDownloads,
        handleTrackGeometryPdfRequest,
        dataPresent,
        userEmail,
        dashboardData.routeID,
        trackGeometryPdfRequested,
        trackGeometryPresignedFiles,
    ]);

    const exportModal = useMemo(() => {
        return (
            <Modal
                visible={showExportConfig}
                closable={true}
                maskClosable={true}
                onCancel={() => setShowExportConfig(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setShowExportConfig(false)}>
                        Close
                    </Button>,
                ]}
                title={"Track Geometry Export"}>
                {exportInfo}
            </Modal>
        );
    }, [showExportConfig, exportInfo]);

    if (!sessionHasGeometryData) {
        return (
            <div className="widget__Info center">
                <p>The selected session has no track geometry data</p>
            </div>
        );
    }

    if (!initialised) {
        return <LoadingOverlay loading={true} />;
    }

    let content = null;

    if (!dataPresent) {
        content = (
            <div className="widget__Info center">
                <p>No data for this session.</p>
            </div>
        );
    } else {
        if (selectedDataTypes && selectedDataTypes.length) {
            content = (
                <ChartV2
                    key={dashboardData.routeID}
                    colorScheme={colorScheme}
                    dataTypes={orderedDataTypes}
                    elrData={elrData}
                    poiData={poiData}
                    lastUpdate={lastUpdate}
                    selectedTimestamp={selectedTimestamp}
                    exceedenceSpeed={exceedenceSpeed}
                    loading={gettingData}
                    onSelectTimestamp={onSelectTimestamp}
                    dataFiles={dataFiles}
                    comparisonSession={comparisonSession?.session_id}
                    comparisonOffset={Math.round(comparisonOffset * 5)}
                    verticalComparisonOffset={verticalComparisonOffset}
                />
            );
        } else {
            content = (
                <div className="widget__Info box">
                    <p>Select measurement types above to populate the window</p>
                </div>
            );
        }
    }

    return (
        <div
            className="widget__Chart__Container"
            onClick={() => {
                setSelectOpen(false);
            }}>
            {/* <div className="widget__Chart__Container__Title">{title}</div> */}
            <div className="widget__Chart__Container__Controls__Zoom">
                <div className="toolbar-parent-container">
                    <div className="toolbar-access">
                        <div className="toolbar-access__title">
                            <p>{title}</p>

                            <div className="widget__Chart__Container__Disclaimer">
                                <p>
                                    <b>Track geometry data is in an evaluation phase</b>
                                </p>
                            </div>
                        </div>
                        <button
                            className="toolbar-button"
                            onClick={() => {
                                active ? setActive(false) : setActive(true);
                            }}>
                            <FontAwesomeIcon icon={faCog} />
                            {active ? "Close Toolbar" : "Open Toolbar"}
                        </button>
                    </div>
                    <div className="wrapper">
                        <div className={active ? "toolbar-container active" : "toolbar-container"}>
                            <div className={active ? "widget-toolbar" : "widget-toolbar closed"}>
                                <div className="widget-toolbar__item">
                                    <p>Geometry Measurements:</p>{" "}
                                    <div className="measurement-container">
                                        <div
                                            className="select-wrapper"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                selectOpen ? setSelectOpen(false) : setSelectOpen(true);
                                            }}>
                                            <Select
                                                onChange={handleSelect}
                                                value={orderedDataTypes}
                                                mode="multiple"
                                                allowClear
                                                placeholder="Please select"
                                                style={{ width: "100%" }}
                                                open={selectOpen}>
                                                {geomHeaders &&
                                                    _.chain(geomHeaders)
                                                        .filter((option) => option.name)
                                                        .filter((h) => {
                                                            return !_.isEmpty(h.info);
                                                        })
                                                        .sortBy((h) => (h.info.order !== null ? h.info.order : h.name))
                                                        .map((option, index) => (
                                                            <Option
                                                                key={index}
                                                                value={option.header_name}
                                                                onClick={(e) => {
                                                                    e.domEvent.stopPropagation();
                                                                }}>
                                                                {_.get(option, "info.display_name", option.name)}
                                                            </Option>
                                                        ))
                                                        .value()}
                                            </Select>
                                        </div>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                selectOpen ? setSelectOpen(false) : setSelectOpen(true);
                                            }}>
                                            <div className="tool-button">
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{ fontSize: 16 }}
                                                />
                                                Add Measurements
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div className="widget-toolbar__item_group">
                                    <div className="widget-toolbar__item">
                                        <p>Exceedence Bounds:</p>
                                        <Select
                                            value={exceedenceSpeed}
                                            onChange={(value) => setExceedenceSpeed(value)}
                                            className="ChartTypeSelect">
                                            <Option value="none">Don't Show</Option>
                                            <Option value="line">For line speed</Option>
                                            <Option value={25}>Up to 25 mph</Option>
                                            <Option value={50}>26-50 mph</Option>
                                            <Option value={75}>51-75 mph</Option>
                                            <Option value={100}>76-100 mph</Option>
                                            <Option value={125}>101-125 mph</Option>
                                        </Select>
                                    </div>
                                    <div className="widget-toolbar__item">
                                        {(!!userIsAdmin || !!userConfig["super_admin"]) && (
                                            <div className="widget-tools">
                                                <p>Tools:</p>
                                                <div className="widget-tools__item">
                                                    <Button
                                                        block
                                                        onClick={() => setShowCompareConfig(true)}>
                                                        <div className="tool-button">
                                                            <FontAwesomeIcon
                                                                icon={faFile}
                                                                style={{ fontSize: 16 }}
                                                            />
                                                            Compare
                                                        </div>
                                                    </Button>
                                                    <Button
                                                        block
                                                        onClick={() => setShowExportConfig(true)}>
                                                        <div className="tool-button">
                                                            <FontAwesomeIcon
                                                                icon={faFileExport}
                                                                style={{ fontSize: 16 }}
                                                            />
                                                            Export
                                                        </div>
                                                    </Button>
                                                    <Button
                                                        block
                                                        onClick={() => {
                                                            colorScheme === "dark" ? setColorScheme("light") : setColorScheme("dark");
                                                        }}>
                                                        {colorScheme === "dark" ? "Light Theme" : "Dark Theme"}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="widget__Chart__Container__Controls__Zoom"
                    hidden={comparisonSession === null}>
                    {comparisonTitle}
                    {comparisonAdjustment}
                </div>
            </div>
            {compareModal}
            {exportModal}
            {content}
        </div>
    );
};

export default ChartV2Container;
