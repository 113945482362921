import React from "react";
import logo from "../../images/obc-logo-hybrid.svg";
import { Tooltip } from "antd";

const OBCAutoIndicator = ({ tooltip, className, placement, onClick = () => {} }) => {
    return (
        <Tooltip
            title={tooltip}
            arrowPointAtCenter
            placement={placement}>
            <div
                className={`OBCAutoIndicator ${className}`}
                onClick={onClick}>
                <img
                    src={logo}
                    alt="Auto detection"
                    crossOrigin={"anonymous"}
                />
                <span>Auto</span>
            </div>
        </Tooltip>
    );
};

OBCAutoIndicator.defaultProps = {
    tooltip: null,
    className: "",
    placement: "top",
};

export default OBCAutoIndicator;
