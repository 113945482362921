import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionContinuousObservations, routeSelected, toggleRailInspectionTimelineWidget, websocketMessage } from "../../redux/actions";
import _ from "lodash";
import moment from "moment";
import Measure from "react-measure";
import { Select, Button } from "antd";

import * as echarts from "echarts";

const EMPTY_OBJECT = {};

const continObservationSelector = (state) => state.continuousObservations;
const playlistSelector = (state) => state.playlist;
const positionTimestampSelector = (state) => state.playlist.position.timestamp;
const currentSessionSelector = (state) => state.sessions[state.playlist.data.routeID];

const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], EMPTY_OBJECT);
};

const updatePlaylistTime = _.debounce(function (dispatch, session_id, timestamp, inRailInspection) {
    if (inRailInspection) {
        dispatch(routeSelected(session_id, timestamp));
    } else {
        dispatch(
            websocketMessage({
                action: "request_position",
                timestamp: timestamp,
            }),
        );
    }
}, 5);

const SessionTimeline = ({ inRailInspection = false, formattedTime = null }) => {
    const allObservations = useSelector(continObservationSelector);
    const currentSession = useSelector(currentSessionSelector);
    const playlist = useSelector(playlistSelector);
    const positionTimestamp = useSelector(positionTimestampSelector);
    const dashboardData = useSelector(inRailInspection ? playlistSelector : dashboardDataSelector);

    const [requestedTimeStamp, setRequestedTimeStamp] = useState(0);
    const [updatingTimestamp, setUpdatingTimestamp] = useState(false);

    const dispatch = useDispatch();

    const [selectedClass, setSelectedClass] = useState("");

    const chartRef = useRef();
    const [chart, setChart] = useState(null);

    const continObservations = useMemo(() => {
        if (!inRailInspection) {
            return allObservations;
        }

        // if we are in railInspection, only show relevant observation data
        return allObservations.filter((observation) => {
            // contaminated is currently the only relevant class - continuous observation data on inspection images
            // but ideally this would check something in the data itself rather than hardcoding classes
            if (observation.class !== "contaminated") {
                return false;
            }
            return true;
        });
    }, [allObservations, inRailInspection]);

    const observations_by_class = useMemo(() => {
        if (continObservations) {
            return _.groupBy(continObservations, "class");
        }
        return {};
    }, [continObservations]);

    const observation_classes = useMemo(() => {
        if (observations_by_class) {
            return _.keys(observations_by_class);
        }
        return [];
    }, [observations_by_class]);

    const chartData = useMemo(() => {
        let _data = [];
        if (observation_classes && selectedClass && observations_by_class[selectedClass]) {
            _.map(observations_by_class[selectedClass], (obs) => {
                _data.push({ timestamp: obs.start_ts, density: obs.density });
            });
        }
        return _data;
    }, [observation_classes, observations_by_class, selectedClass]);

    useEffect(() => {
        if (currentSession?.id) {
            dispatch(getSessionContinuousObservations(currentSession.id));
        }
    }, [currentSession?.id, dispatch]);

    const currentTimestamp = useMemo(() => {
        let _currentTimestamp = dashboardData.position?.timestamp / 1000;
        if (inRailInspection) {
            _currentTimestamp = positionTimestamp / 1000;
        }

        return _currentTimestamp;
    }, [dashboardData.position.timestamp, inRailInspection, positionTimestamp]);

    useEffect(() => {
        setRequestedTimeStamp(currentTimestamp);
        setUpdatingTimestamp(false);
    }, [currentTimestamp]);

    useEffect(() => {
        if (chartData.length) {
            const new_chart = echarts.init(chartRef.current);

            new_chart.getZr().on("click", (params) => {
                const pointInPixel = [params.offsetX, params.offsetY];
                const pointInGrid = new_chart.convertFromPixel("grid", pointInPixel);
                if (pointInGrid) {
                    setUpdatingTimestamp(true);
                    setRequestedTimeStamp(pointInGrid[0] / 1000);
                    updatePlaylistTime(dispatch, currentSession.id, pointInGrid[0] / 1000, inRailInspection);
                }
            });

            setChart(new_chart);
            return () => {
                new_chart.dispose();
            };
        }
    }, [chartData.length, currentSession, dispatch, inRailInspection]);

    useEffect(() => {
        let first_class = observation_classes[0];
        setSelectedClass(first_class);
    }, [observation_classes]);

    useEffect(() => {
        if (!chart) {
            return;
        }

        let lower_bound_s = null;
        let upper_bound_s = null;
        let lower_bound_ms = null;
        let upper_bound_ms = null;

        // if rail inspection images
        if (!playlist || !playlist.data.video || !_.get(playlist.data.video, [0], false)) {
            const stream = _.get(playlist, ["data", "data"], []);
            const streamLastIndex = stream ? stream.length - 1 : -1;

            lower_bound_s = parseInt(_.get(stream, [0, "start", 0], 0));
            upper_bound_s = parseInt(_.get(stream, [streamLastIndex, "start", 0], 0));
            lower_bound_ms = lower_bound_s * 1000;
            upper_bound_ms = upper_bound_s * 1000;
        } else {
            const stream = playlist.data.video[0];
            lower_bound_s = parseInt(stream[0][3][2]);
            upper_bound_s = parseInt(stream[stream.length - 1][3][2]);
            lower_bound_ms = lower_bound_s * 1000;
            upper_bound_ms = upper_bound_s * 1000;
        }

        if (lower_bound_ms && upper_bound_ms) {
            chart.setOption({
                grid: inRailInspection
                    ? {
                          left: "30",
                          right: "30",
                          top: "30",
                          bottom: "30",
                          containLabel: false,
                      }
                    : {},
                legend: {},
                xAxis: {
                    type: "time",
                    min: lower_bound_ms,
                    max: upper_bound_ms,
                    axisPointer: {
                        show: true,
                        type: "line",
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "Density",
                        type: "bar",
                        itemStyle: {
                            color: "#C16E3E",
                        },
                        markLine: {
                            symbol: "none",
                            label: {
                                show: false,
                            },
                            lineStyle: {
                                type: "solid",
                                color: "#FF0000",
                                width: 2,
                            },
                            animation: false,
                            emphasis: { disabled: true },
                            data: [
                                {
                                    xAxis: updatingTimestamp ? requestedTimeStamp * 1000 : currentTimestamp * 1000,
                                },
                            ],
                        },
                    },
                ],
                dataset: {
                    dimension: ["timestamp", "density"],
                    source: chartData,
                },
                // dataZoom: [  TODO: enable overview chart
                //     {
                //         type: 'inside',  // enable zooming and panning inside the chart
                //         filterMode: 'none',
                //         zoomOnMouseWheel: false,
                //         moveOnMouseMove: true,
                //         moveOnMouseWheel: true,
                //         animation: false,
                //         throttle: 0,
                //     },
                //     {
                //         type: 'slider',  // enable slider below the chart
                //         filterMode: 'none',
                //         zoomOnMouseWheel: false,
                //         moveOnMouseMove: false,
                //         moveOnMouseWheel: false,
                //         animation: false,
                //     }
                // ],
            });
        }
    }, [currentSession, observations_by_class, playlist, selectedClass, currentTimestamp, chartData, chart, updatingTimestamp, requestedTimeStamp]);

    let moment_time = moment.unix(currentTimestamp);

    let disabled = !currentSession || !currentSession.id;

    const onResize = useCallback(() => {
        if (chart) {
            chart.resize();
        }
    }, [chart]);

    return (
        <div className={`Container SessionLinear__Container ${inRailInspection ? "SessionTimelineInRailInspection" : ""}`}>
            <div className="SessionTimelineInRailInspectionTopControls">
                <Select
                    value={selectedClass}
                    size={inRailInspection ? "small" : "default"}
                    placeholder={chartData.length ? "Select Observation type" : "No Observations for this session"}
                    disabled={!chartData.length}
                    style={{ width: "fit-content", justifySelf: "flex-start" }}
                    onChange={(value) => setSelectedClass(value)}>
                    {Object.keys(observations_by_class).map((key) => (
                        <Select.Option value={key}>{_.capitalize(key)}</Select.Option>
                    ))}
                </Select>
                {inRailInspection && (
                    <>
                        <div className="SessionTimelineInRailInspectionTopControlsCenterInfo">
                            <span className="session">
                                <span>Session:</span>
                                <span>{_.get(currentSession, "id", null)}</span>
                            </span>
                            <span className="clock">{formattedTime}</span>
                        </div>
                        <div className="SessionTimelineInRailInspectionTopControlsHideButton">
                            <Button
                                type="primary"
                                onClick={() => dispatch(toggleRailInspectionTimelineWidget())}
                                icon="down">
                                Hide Analytics
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {!inRailInspection && <span className="session">{disabled ? "N/A" : `#${currentSession.id}`}</span>}

            <div className="SessionLinear__TimelineContainer">
                {!inRailInspection && <span className="clock">{disabled ? "N/A" : `${moment_time.format("DD/MM/YY HH:mm:ss")}`}</span>}
                {chartData.length ? (
                    <Measure
                        bounds
                        onResize={onResize}>
                        {({ measureRef }) => (
                            <div
                                className="SessionLinear__Timeline"
                                ref={measureRef}>
                                <div
                                    className="SessionLinear__Chart"
                                    ref={chartRef}
                                />
                            </div>
                        )}
                    </Measure>
                ) : (
                    <div className="SessionLinear__Timeline_NoDataToDisplay">There is no data to display for this session</div>
                )}
            </div>
        </div>
    );
};

export default SessionTimeline;
