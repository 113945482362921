import React from "react";
import "../../aivr.scss";
import "antd/dist/antd.css";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchExport, logout, accessTokenLogin, selectDashboard } from "redux/actions/index";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import _ from "lodash";
import LoadingOverlay from "../util/LoadingOverlay";
import logo from "../../images/new-branding-2.0/aivr-hybrid.svg";
class ExportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialised: false,
        };
    }

    componentDidMount() {
        if (this.props.access_token) {
            this.validateAccessToken();
        } else {
            this.fetchExport();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.access_token && prevProps.access_token !== this.props.access_token) {
            // access token changed - re-initialise
            this.validateAccessToken();
        }
    }

    validateAccessToken = () => {
        this.props.dispatch(
            accessTokenLogin(this.props.access_token, (success) => {
                if (!success) {
                    // if login/validate failed then logout and go to index
                    this.logout();
                } else {
                    this.fetchExport();
                }
            }),
        );
    };

    logout = () => {
        this.props.dispatch(logout());
        let baseURL = window.location.origin;
        window.location.replace(baseURL);
    };

    switchDashboard = (token) => {
        this.props.dispatch(selectDashboard(token));
    };

    fetchExport = () => {
        let exportUUID = this.props.match.params.exportID;

        this.props.dispatch(fetchExport(exportUUID, this.props.type));
        setTimeout(() => {
            this.setState({ initialised: true });
        }, 300);
    };

    render() {
        console.log("export page render:", this.props);

        let content = null;
        let { viewExport } = this.props;

        if (viewExport.loading || !this.state.initialised) {
            return <LoadingOverlay loading={true} />;
        } else if (viewExport.details !== null && !viewExport.details.expired) {
            // API call succeeded, show export details

            content = (
                <React.Fragment>
                    <div className="ExportPage__brandedHeader">
                        <div>
                            <span className="ExportPage__subtitle">AIVR EXPORT</span>

                            <h2 className="ExportPage__title">{viewExport.details.export_name}</h2>
                            {viewExport.details.session_id && (
                                <h3 className="ExportPage__subtitle">
                                    #{viewExport.details.session_id} {viewExport.details.session_name}
                                </h3>
                            )}
                        </div>
                        {viewExport.details.tenant_brand ? (
                            <div className="ExportPage__orgBrandImageContainer">
                                <a href={`https://view${MEMOIZED_DOMAIN_URL}/`}>
                                    <img
                                        className="ExportPage__orgBrandImage"
                                        src={`https://branding${MEMOIZED_DOMAIN_URL}/${viewExport.details.tenant_brand}/view_watermark1.png`}
                                        alt=""
                                        crossOrigin={"anonymous"}
                                    />
                                </a>
                            </div>
                        ) : null}
                    </div>

                    <img
                        className="ExportPage__exportStrip"
                        src={viewExport.details.strip}
                        crossOrigin={"anonymous"}
                        alt=""
                    />

                    <div className="ExportPage__buttonContainer">
                        {Object.keys(viewExport.details.links)
                            .sort()
                            .map((linkKey) => {
                                return (
                                    <a
                                        className="aivr-button"
                                        href={viewExport.details.links[linkKey]}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Download {linkKey}
                                    </a>
                                );
                            })}
                    </div>
                </React.Fragment>
            );
        } else if (viewExport.details !== null && viewExport.details.expired === true) {
            content = (
                <React.Fragment>
                    <h2>Export Expired</h2>
                    <p>This export was created over 7 days ago, and has expired. It can be re-exported from the AIVR Dashboard.</p>
                    <div className="text-center">
                        <Link to={{ pathname: "/" }}>Go to Dashboard</Link>
                    </div>
                </React.Fragment>
            );
        } else if (viewExport.required_access_id && !this.props.access_token) {
            // the user is not signed in, but this export requires auth
            console.log("case 2: user not signed in but auth required");

            content = (
                <React.Fragment>
                    <h2 className="">Authorisation Required</h2>
                    <p>You must be signed in and have valid authorisation to view this content.</p>

                    <div className="text-center">
                        <Link
                            to={{
                                pathname: "/login",
                                state: {
                                    from: this.props.location,
                                    required_access_id: viewExport.required_access_id,
                                },
                            }}>
                            Log In
                        </Link>
                    </div>
                </React.Fragment>
            );
        } else if (viewExport.required_access_id && this.props.access_token) {
            console.log("case 3: user signed in but access denied");

            // the user is signed in but not to the required dashboard
            let required_dashboard = _.find(this.props.dashboards, { access_id: viewExport.required_access_id });
            if (required_dashboard) {
                console.log("case 3a: user needs to change dashboards...");
                // the user does actually have access to the required dashboard, but needs to switch to it
                content = (
                    <React.Fragment>
                        <h2 className="">Switch Workspace</h2>
                        <p>You need to switch Workspaces to view this export</p>
                        <div className="text-center">
                            <p
                                onClick={() => this.switchDashboard(required_dashboard.access_token)}
                                className="dashboardSwitchLink">
                                Switch to {required_dashboard.description}
                            </p>
                        </div>
                    </React.Fragment>
                );
            } else {
                // the user doesn't have access to the required dashboard
                console.log("case 3b: user really doesnt have access");

                content = (
                    <React.Fragment>
                        <h2 className="">Access Denied</h2>
                        <p>Your user account does not have permission to access this content.</p>
                        <div className="text-center">
                            <Link to={{ pathname: "/" }}>Go to Dashboard</Link>
                        </div>
                    </React.Fragment>
                );
            }
        } else {
            console.log("case 4: user not signed in, could not fetch export, but no required_access_id");
            content = (
                <React.Fragment>
                    <h2 className="">Export not found</h2>
                    <p>The requested export could not be found. Are you sure you have the correct link?</p>
                    <div className="text-center">
                        <Link to={{ pathname: "/" }}>Go to Dashboard</Link>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div className="aivr-overlay-bg Center-Content">
                <div className="ExportPage__brand">
                    <a href={`https://view${MEMOIZED_DOMAIN_URL}/`}>
                        <img
                            className="ExportPage__brandImage"
                            src={logo}
                            alt="aivr logo"
                            crossOrigin={"anonymous"}
                        />
                    </a>
                </div>

                <div className="ExportPage__container">
                    <div className="ExportPage__content">{content}</div>
                </div>

                <div className="ExportPage__footer">
                    <div className="ExportPage__footerItem">
                        <a
                            className="aivr-link"
                            href="https://onebigcircle.co.uk/aivr/">
                            About AIVR
                        </a>
                    </div>

                    <div className="ExportPage__footerItem">
                        <a
                            className="aivr-link"
                            href="https://onebigcircle.co.uk/terms-of-use/">
                            Terms & Conditions
                        </a>
                    </div>

                    <div className="ExportPage__footerItem">
                        <a
                            className="aivr-link"
                            href="https://onebigcircle.co.uk/privacy-policy/">
                            Privacy
                        </a>
                    </div>

                    <div className="ExportPage__footerItem">
                        <a
                            className="aivr-link"
                            href="https://onebigcircle.co.uk/contact/">
                            Contact
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    access_token: state.access_token,
    viewExport: state.viewExport,
    userDetails: state.userDetails,
    dashboards: state.dashboards,
});

export default connect(mapStateToProps)(withRouter(ExportPage));
