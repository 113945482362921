import React from "react";
import { Tabs } from "antd";
import DriversTab from "./components/DriversTab";
import SessionsTab from "./components/SessionsTab";
const { TabPane } = Tabs;

const DriverTrainingManager = () => {
    const onTabChanged = (tab) => {
        console.log("debug tab changed", tab);
    };
    return (
        <div className="DriverTrainingManagerMain">
            <Tabs
                className="DriverTrainingManagerMainTab"
                defaultActiveKey="drivers"
                onChange={onTabChanged}>
                <TabPane
                    tab="Drivers"
                    key="drivers">
                    <DriversTab />
                </TabPane>
                <TabPane
                    tab="Training Sessions"
                    key="sessions">
                    <SessionsTab />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default DriverTrainingManager;
