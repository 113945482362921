import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { calculateOffset, calculateFrame, keySourceLookup, absoluteTimeLookup } from "components/util/PlaylistUtils";
import { requestPlaylistPosition, changeToolMode, customAudit } from "redux/actions/index";
import moment from "moment";
import { Input, Modal, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import memoizeOne from "memoize-one";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import MarkupExportModal from "../MarkupExportModal";
import { calculateRouteCoordinatesForLocation } from "components/util/Geometry";

const { confirm } = Modal;

moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("ss", 1);

moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d second",
        ss: "%d seconds",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        w: "1 week",
        ww: "%d weeks",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
    },
});

class AnnotationSearch extends React.PureComponent {
    constructor(props) {
        super(props);
        this.searchField = React.createRef();
        this.state = {
            searchPopupVisible: false,
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.accessToken !== prevProps.accessToken) {
            this.setState({
                searchPopupVisible: false,
            });
        }
    };

    clearSearch = () => {
        this.updateSearchField("");
    };

    updateSearchField = (value) => {
        this.props.onSearch(value);
    };

    render() {
        return (
            <div
                className="sessionSearch"
                style={{ marginLeft: 0 }}>
                <Input
                    ref={this.searchField}
                    value={this.props.searchQuery}
                    onChange={(e) => this.updateSearchField(e.target.value)}
                    placeholder="Search for content"
                    style={{ width: 250, height: 35 }}
                    spellCheck={false}
                    prefix={<SearchOutlined />}
                    allowClear
                />
            </div>
        );
    }
}

class RouteContentList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            exportModalShowing: false,
        };

        this.searchRef = React.createRef();
    }

    componentDidMount = () => {
        this.props.dispatch(customAudit("session_content_tab_opened"));
    };

    filterSessionByName = (sessionName) => {
        this.setState({
            searchQuery: sessionName ? sessionName.toLowerCase() : null,
        });
    };

    filteredAnnotations = () => {
        const _annotations = this.props.videoContent;
        const _searchQuery = this.state.searchQuery;

        return this.getFilteredAnnotations(_annotations, _searchQuery);
    };

    getFilteredAnnotations = memoizeOne((_annotations, _searchQuery) => {
        return _annotations.filter((annotation) => this.isAnnotationVisible(annotation, _searchQuery));
    });

    isAnnotationVisible = (annotation, searchQuery) => {
        if (searchQuery) {
            if (
                (!annotation.name || !annotation.name.toLowerCase().includes(searchQuery)) &&
                (!annotation.user || !annotation.user.toLowerCase().includes(searchQuery))
            ) {
                return false;
            }
        }

        return true;
    };

    goToVideo = (sourceIndex, playlistIndex, type, frame) => {
        this.props.dispatch(
            customAudit(
                "session_content_tab_item_selected",
                {
                    markupType: type,
                },
                `${type} session content item selected`,
            ),
        );

        console.log("Navigating to source/video index", sourceIndex, playlistIndex);

        const nextOffset = calculateOffset(this.props.video[sourceIndex], playlistIndex, frame);
        this.props.dispatch(requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, sourceIndex, playlistIndex, nextOffset));

        if (type === "measurement") {
            this.props.dispatch(changeToolMode("measure"));
        }

        if (type === "markup") {
            this.props.dispatch(changeToolMode("draw"));
        }

        if (type === "annotation") {
            this.props.dispatch(changeToolMode("annotate"));
        }
    };

    checkForChanges = (callback) => {
        if (this.props.markupChanged) {
            confirm({
                title: "Unsaved Changes",
                content: "You have unsaved changes. Navigating away from here will delete these changes. Are you sure?",
                onOk() {
                    callback();
                },
                onCancel() {},
            });
        } else {
            callback();
        }
    };

    isViewingContent = (videoKey, type, frame) => {
        let currentIndex = this.props.position.currentIndex;
        let allVideo = this.props.video;
        let sourceIndex = this.props.position.sourceIndex;
        let currentFrame = calculateFrame(this.props.video[sourceIndex], currentIndex, this.props.position.currentTimeOffset);

        if (allVideo[sourceIndex] && allVideo[sourceIndex][currentIndex]) {
            return (
                allVideo[sourceIndex][currentIndex][0] === videoKey &&
                currentFrame === frame &&
                ((this.props.toolMode === "draw" && type === "markup") ||
                    (this.props.toolMode === "measure" && type === "measurement") ||
                    (this.props.toolMode === "annotate" && type === "annotation"))
            );
        } else {
            return false;
        }
    };

    getContentLocation = (content) => {
        let location = calculateRouteCoordinatesForLocation(content.timestamp, this.props.routeLocations, this.props.routeLocationSystemID);

        if (location) {
            return _.map(location.fields(this.props.userConfig.elr_units), (field_name) => (
                <span key={field_name}>
                    <b>{field_name}:</b> <span className="secondary">{location.field_value(field_name, [])}</span>
                </span>
            ));
        }
        return null;
    };

    renderTableRows = () => {
        return this.filteredAnnotations().map((cnt) => {
            return (
                <tr
                    className={"contentTableRow" + (this.isViewingContent(cnt.videoKey, cnt.type, cnt.frame) ? " activeContentRow" : "")}
                    key={`${cnt.type}_${cnt.id}`}
                    onClick={() => this.checkForChanges(() => this.goToVideo(cnt.sourceIndex, cnt.playlistIndex, cnt.type, cnt.frame))}>
                    <td className="contentTableCell">{cnt.timestamp ? moment.unix(cnt.timestamp).format("HH:mm:ss") : "-"}</td>
                    <td className="contentTableCell">{cnt.type}</td>
                    <td className="contentTableCell">{cnt.name}</td>
                    <td className="contentTableCell">{cnt.user}</td>
                    <td className="contentTableCell Location">{this.getContentLocation(cnt)}</td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="contentTabDiv">
                {this.props.videoContent.length > 0 ? (
                    <>
                        <div className="flex">
                            <MarkupExportModal
                                sessionID={this.props.routeID}
                                closeModal={() => this.setState({ exportModalShowing: false })}
                                visible={this.state.exportModalShowing}
                            />
                            <AnnotationSearch
                                ref={this.searchRef}
                                searchQuery={this.state.searchQuery}
                                onSearch={this.filterSessionByName}
                                accessToken={this.props.accessToken}
                            />

                            <Tooltip
                                title="Export Content"
                                trigger="hover">
                                <div
                                    className="ExportIconButton"
                                    onClick={() => this.setState({ exportModalShowing: true })}>
                                    <FontAwesomeIcon
                                        className="ExportIcon"
                                        icon={faDownload}
                                    />
                                </div>
                            </Tooltip>
                        </div>

                        <table className="routeContentTable">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>User</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTableRows()}</tbody>
                        </table>
                    </>
                ) : (
                    <h2 className="adminMenuText">No content for this session</h2>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let video = _.get(state.playlist.data, ["video"], []);
    if (video === null) {
        video = [];
    }

    let measurements = state.userMeasurements.map((measurement) => {
        let measureDetails = JSON.parse(measurement.measurements);
        let keySource = keySourceLookup(measurement.video_key, video);
        let playlistIndex = keySource[0];
        let sourceIndex = keySource[1];
        let timestamp = 0;
        if (playlistIndex !== -1) {
            let videoItem = video[sourceIndex][playlistIndex];
            if (videoItem[3] && videoItem[3][2]) {
                timestamp = videoItem[3][2];
            }
        }

        return {
            timestamp,
            id: measurement.id,
            name: measureDetails.name,
            sourceIndex: sourceIndex,
            playlistIndex: playlistIndex,
            videoKey: measurement.video_key,
            email: measurement.email,
            type: "measurement",
            frame: measurement.frame,
            user: measurement.name,
        };
    });

    let sketches = state.userSketches.map((sketch) => {
        let keySource = keySourceLookup(sketch.video_key, video);
        let playlistIndex = keySource[0];
        let sourceIndex = keySource[1];
        let timestamp = 0;
        if (playlistIndex !== -1) {
            let videoItem = video[sourceIndex][playlistIndex];
            if (videoItem[3] && videoItem[3][2]) {
                timestamp = videoItem[3][2];
            }
        }

        return {
            timestamp,
            id: sketch.id,
            name: "Markup",
            sourceIndex: sourceIndex,
            playlistIndex: playlistIndex,
            videoKey: sketch.video_key,
            email: sketch.email,
            type: "markup",
            frame: sketch.frame,
            user: sketch.name,
        };
    });

    let annotationTypes = state.userAnnotationTypes;

    let annotations = state.userAnnotations.map((ann) => {
        let keySource = keySourceLookup(ann.videoKey, video);
        let playlistIndex = keySource[0];
        let sourceIndex = keySource[1];
        let timestamp = 0;
        if (playlistIndex !== -1) {
            let videoItem = video[sourceIndex][playlistIndex];
            if (videoItem[3] && videoItem[3][2]) {
                timestamp = videoItem[3][2];
            }
        }

        let typeIndex = _.findIndex(annotationTypes, function (type) {
            return type.id === ann.type;
        });

        let name = "Annotation";
        if (typeIndex > -1) {
            name = annotationTypes[typeIndex].type;
        }
        if (ann.type === -1) {
            name = ann.custom_tag;
        }

        return {
            timestamp,
            id: ann.id,
            sourceIndex: sourceIndex,
            playlistIndex: playlistIndex,
            videoKey: ann.videoKey,
            name,
            type: "annotation",
            email: ann.email,
            frame: ann.frame,
            user: ann.name,
        };
    });

    const features = state.featureOverlay.features
        .filter((feature) => {
            return feature.session_id === state.playlist.data.routeID;
        })
        .map((feature) => {
            const firstSourceVideo = _.get(video, [0], []);
            const playlistIndex = absoluteTimeLookup(feature.session_ts, firstSourceVideo);
            return {
                timestamp: feature.session_ts,
                id: feature.id,
                sourceIndex: 0,
                type: feature.type,
                user: feature.owner_name,
                playlistIndex,
                name: feature.description || `${feature.type} feature`,
            };
        });

    let videoContent = _.sortBy([...sketches, ...measurements, ...annotations, ...features], function (cnt) {
        return cnt.timestamp;
    });

    return {
        position: state.playlist.position,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        video,
        videoContent,
        toolMode: state.markup.tool_mode,
        markupChanged: state.markup.hasUnsavedChange,
        routeID: state.playlist.data.routeID,
        routeLocations: state.playlist.data.route_locations,
        userConfig: state.userDetails.userConfig,
        routeLocationSystemID: state.playlist.data.system_id,
    };
};

export default connect(mapStateToProps)(RouteContentList);
