import { Divider, Input } from "antd";
import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    customAudit,
    goToMapPosition,
    logout,
    lookupLatLon,
    requestAsset,
    routeCoordinateReverseLookup,
    routeSelected,
    sendMegaSearch,
    swapDashboard,
    toggleMegasearch,
    whatThreeWordsLookup,
} from "redux/actions";
import { notification } from "antd";
import OBCSpinner from "components/util/OBC";
import { checkForPartialMatch, checkQueryForSingleMatch } from "components/util/MegasearchUtils";
import AssetIDHelper from "components/util/AssetIDHelper";
import Highlighter from "react-highlight-words";

const megasearchOpenSelector = (state) => state.megasearch.open;
const workspacesSelector = (state) => state.dashboards;

const SearchInput = ({ onChange, searchInput, loading }) => {
    const [inputRef, setInputRef] = useState(null);

    useEffect(() => {
        if (inputRef) {
            inputRef.input.select();
        }
    }, [inputRef]);

    return (
        <div className={"MegaSearchInput"}>
            <Input.Search
                allowClear
                ref={(ref) => setInputRef(ref)}
                loading={loading}
                style={{ width: 200 }}
                onChange={(e) => onChange(e.target.value)}
                value={searchInput}
                placeholder="Search..."
            />
        </div>
    );
};

const SearchResults = ({ results, noResults, query }) => {
    const [highlightedResult, setHighlightedResult] = useState([]); //Hightlight result in format [groupLabel, resultIndex]
    const optionRefs = useRef({});
    const dispatch = useDispatch();

    const megasearchOpen = useSelector(megasearchOpenSelector);

    useEffect(() => {
        if (results.length) {
            setHighlightedResult([0, 0]);
        }
    }, [results]);

    const scrollToItem = (groupIndex, itemIndex) => {
        const refLabel = `${groupIndex}.${itemIndex}`;
        if (optionRefs.current[refLabel] && optionRefs.current[refLabel]) {
            optionRefs.current[refLabel].scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
    };

    const titleString = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleResultClick = useCallback(
        (result) => {
            if (!result) {
                return;
            }
            console.log("debug handle result click", result);
            dispatch(
                customAudit("megasearch_result_selected", {
                    ...result,
                }),
            );

            dispatch(toggleMegasearch(false));
            if (result.type === "session") {
                const sessionID = result.data.id;
                dispatch(routeSelected(sessionID));
            } else if (result.type === "station") {
                const stationCoords = result.data.location;
                dispatch(goToMapPosition([stationCoords[0], stationCoords[1], 13]));
            } else if (result.type === "signal") {
                dispatch(requestAsset(result.data.id.toString(), null));
            } else if (result.type === "structure") {
                dispatch(requestAsset(result.data.id.toString(), null));
            } else if (result.type === "latln") {
                dispatch(lookupLatLon(result.data.lat, result.data.lng, false));
            } else if (result.type === "elr") {
                dispatch(routeCoordinateReverseLookup("ELR Mile & Chain", result.data.elr, result.data.position, result.data.trackID || null, true));
            } else if (result.type === "what3words") {
                dispatch(whatThreeWordsLookup(result.data.words, true));
            } else if (result.type === "logout") {
                dispatch(logout());
            } else if (result.type === "workspace") {
                dispatch(swapDashboard(result.data.workspaceToken));
            }
        },
        [dispatch],
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                const currentGroupIndex = highlightedResult[0];
                const currentItemIndex = highlightedResult[1];
                if (_.isNil(currentItemIndex)) {
                    const newItemIndex = 0;
                    const newGroupIndex = 0;
                    setHighlightedResult([newGroupIndex, newItemIndex]);
                    return;
                }
                let newItemIndex = currentItemIndex + 1;
                let newGroupIndex = currentGroupIndex;
                const currentGroupItems = _.get(results, [currentGroupIndex, "results"], []);
                if (newItemIndex >= currentGroupItems.length) {
                    let nextGroupIndex = currentGroupIndex + 1;
                    if (nextGroupIndex >= results.length) {
                        nextGroupIndex = 0;
                    }
                    newGroupIndex = nextGroupIndex;
                    newItemIndex = 0;
                }

                scrollToItem(newGroupIndex, newItemIndex);
                setHighlightedResult([newGroupIndex, newItemIndex]);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                const currentGroupIndex = highlightedResult[0];
                const currentItemIndex = highlightedResult[1];

                if (_.isNil(currentItemIndex)) {
                    const newGroupIndex = results.length - 1;
                    const newItemIndex = _.get(results, [currentGroupIndex, "results", "length"], 1) - 1;
                    setHighlightedResult([newGroupIndex, newItemIndex]);
                    return;
                }
                let newItemIndex = currentItemIndex - 1;
                let newGroupIndex = currentGroupIndex;
                if (newItemIndex < 0) {
                    newGroupIndex = newGroupIndex - 1;
                    if (newGroupIndex < 0) {
                        newGroupIndex = results.length - 1;
                    }
                    if (results[newGroupIndex] && results[newGroupIndex].results) {
                        newItemIndex = results[newGroupIndex].results.length - 1;
                    }
                }

                scrollToItem(newGroupIndex, newItemIndex);
                setHighlightedResult([newGroupIndex, newItemIndex]);
            } else if (e.key === "Enter") {
                e.preventDefault();
                const currentGroupIndex = highlightedResult[0];
                const currentItemIndex = highlightedResult[1];

                if (_.isNil(currentGroupIndex) || _.isNil(currentItemIndex)) {
                    return;
                }

                const groupItems = results[currentGroupIndex];
                if (!groupItems) {
                    return;
                }
                const selectedItem = groupItems.results[currentItemIndex];
                handleResultClick(selectedItem);
            } else if (e.key === "Escape") {
                dispatch(toggleMegasearch(false));
            }
        },
        [dispatch, handleResultClick, highlightedResult, results],
    );

    useEffect(() => {
        if (megasearchOpen) {
            window.addEventListener("keydown", onKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown, megasearchOpen]);

    const renderSection = (groupIndex, resultSet, resultsLength) => {
        const results = resultSet.results;
        const sectionLabel = resultSet.resultTypeLabel;
        const enterText = resultSet.enterLabel || "to jump to";
        if (results.length) {
            return (
                <div
                    className="ResultsSectionContainer"
                    key={sectionLabel}>
                    <div className="SectionTitleContainer">
                        <p className="SectionTitle">{titleString(sectionLabel)}</p>
                    </div>
                    {results.map((result, idx) => {
                        let highlighted = false;
                        if (groupIndex === highlightedResult[0] && idx === highlightedResult[1]) {
                            highlighted = true;
                        }

                        const reformattedSearchQuery = new AssetIDHelper(query).formatted_search_query();
                        let formattedLabel = result.label;
                        if (["signal", "structure"].includes(result.type)) {
                            formattedLabel = new AssetIDHelper(result.label).csv_to_slashes();
                        }

                        return (
                            <div
                                ref={(ref) => {
                                    let refLabel = `${groupIndex}.${idx}`;
                                    optionRefs.current[refLabel] = ref;
                                }}
                                className={`Result Section${sectionLabel}` + (highlighted ? " Highlighted" : "")}
                                onMouseEnter={() => setHighlightedResult([groupIndex, idx])}
                                onClick={() => handleResultClick(result)}>
                                <p className="Label">
                                    <Highlighter
                                        highlightClassName="ObcTextHighlight"
                                        searchWords={query ? [reformattedSearchQuery, query] : []}
                                        autoEscape={true}
                                        textToHighlight={String(formattedLabel)}
                                    />
                                </p>

                                {highlighted && (
                                    <div className="EnterContainer">
                                        <div className="EnterButton">
                                            <p className="EnterText">Enter</p>
                                        </div>
                                        <p className="Text">{enterText}</p>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {groupIndex !== resultsLength - 1 && <Divider />}
                </div>
            );
        } else {
            return null;
        }
    };

    if (!megasearchOpen) {
        return null;
    }

    if (noResults) {
        return (
            <div className="MegaSearchResultsContainer">
                <p className="NoResults">No Results Found</p>
            </div>
        );
    }

    if (!query) {
        return (
            <div
                className="MegaSearchResultsContainer"
                style={{ display: megasearchOpen ? "default" : "none" }}>
                <div
                    className="ScrollContainer"
                    style={{ paddingBottom: 5 }}>
                    <p className="SearchSuggestion">Search for Session</p>
                    <p className="SearchSuggestion">Search for ELR</p>
                    <p className="SearchSuggestion">Search for Location</p>
                    <p className="SearchSuggestion">Search for Asset</p>
                </div>
            </div>
        );
    }

    if (results.length) {
        return (
            <div
                className="MegaSearchResultsContainer"
                style={{ display: megasearchOpen ? "default" : "none" }}>
                <div className="ScrollContainer">
                    {results.map((resultSet, idx) => {
                        return renderSection(idx, resultSet, results.length);
                    })}
                </div>
            </div>
        );
    }
    return (
        <div
            className="MegaSearchResultsContainer"
            style={{ display: megasearchOpen ? "default" : "none" }}>
            <div className="SpinnerContainer">
                <OBCSpinner
                    colorScheme={"mono"}
                    size={50}
                />
            </div>
        </div>
    );
};

const SearchComponent = () => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("");
    const [querySearched, setQuerySearched] = useState("");
    const [loadingSearchTypes, setLoadingSearchTypes] = useState([]);
    const [results, setResults] = useState([]);

    const megasearchOpen = useSelector(megasearchOpenSelector);
    const workspaces = useSelector(workspacesSelector);

    const getResultsDebounce = useRef(_.debounce((query) => getResults(query), 500)).current;

    useEffect(() => {
        if (megasearchOpen) {
            dispatch(customAudit("megasearch_open"));
        }
    }, [dispatch, megasearchOpen]);

    const resultsLoading = useMemo(() => {
        return !!loadingSearchTypes.length || querySearched !== searchInput;
    }, [loadingSearchTypes.length, querySearched, searchInput]);

    const noResults = useMemo(() => {
        const hasResult = !!results.length;
        return !resultsLoading && !hasResult && searchInput;
    }, [results.length, resultsLoading, searchInput]);

    const handleResultSet = useCallback((type, resultGroup) => {
        setLoadingSearchTypes((types) => {
            return types.filter((searchType) => searchType !== type);
        });
        if (resultGroup?.results?.length) {
            setResults((results) => {
                const newResults = _.cloneDeep(results);
                newResults.push(resultGroup);
                return newResults;
            });
        }
    }, []);

    const getResults = (query) => {
        if (!query) {
            setResults([]);
            setQuerySearched(query);
            return;
        }

        const originalQuery = query;
        if (query.startsWith("#")) {
            query = query.substring(1);
        }

        const exactMatchResults = checkQueryForSingleMatch(query, workspaces);
        if (exactMatchResults && exactMatchResults.length) {
            setResults(exactMatchResults);
            setQuerySearched(originalQuery);
            return;
        }

        let searchTypes = ["sessions", "stations", "elrs", "signals", "structure_ids"];

        setLoadingSearchTypes(searchTypes);
        setResults([]);
        setQuerySearched(originalQuery);

        checkForPartialMatch(query, workspaces, (resultType, resultSet) => {
            handleResultSet(resultType, resultSet);
        });

        searchTypes.forEach((type) => {
            let newQuery = query;
            if (["signals", "structure_ids"].includes(type)) {
                newQuery = new AssetIDHelper(query).format_search_query();
                console.log("debug2 newQuery", newQuery);
            }
            dispatch(sendMegaSearch(newQuery, type))
                .then((resultGroup) => {
                    handleResultSet(type, resultGroup);
                })
                .catch((error) => {
                    console.log("debug error sending megasearch", error);
                    notification.error({ message: "An error occurred while performing search" });
                    handleResultSet(type, []);
                });
        });
    };

    const onBackgroundClick = (event) => {
        if (event.target === event.currentTarget) {
            dispatch(toggleMegasearch(false));
        }
    };

    useEffect(() => {
        setResults([]);
        getResultsDebounce(searchInput);
    }, [searchInput]);

    if (megasearchOpen) {
        return (
            <div
                className="MegaSearchContainer"
                onClick={onBackgroundClick}>
                <div className="SearchContainer">
                    <SearchInput
                        loading={resultsLoading}
                        onChange={setSearchInput}
                        searchInput={searchInput}
                    />

                    <SearchResults
                        loading={resultsLoading}
                        query={searchInput}
                        noResults={noResults}
                        results={results}
                    />
                </div>
            </div>
        );
    }

    return null;
};

export default SearchComponent;
