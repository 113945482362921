import React from "react";
import { connect } from "react-redux";
import { Select, Spin } from "antd";
import "antd/dist/antd.css";
import { fetchStations, goToBounds, toggleStationDisplay } from "redux/actions/index";
import _ from "lodash";

function DebounceSelect({ fetchOptions, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            setOptions([]);
            setFetching(true);
            fetchOptions(value, (newOptions) => {
                setOptions(newOptions);
                setFetching(false);
            });
        };

        return _.debounce(loadOptions, 800);
    }, [fetchOptions]);

    return (
        <>
            <p className="inputLabel">Station name :</p>
            <Select
                showSearch
                filterOption={false}
                onSearch={debounceFetcher}
                onSelect={props.onSelect}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                placeholder="Search for station">
                {options.map((option) => (
                    <Select.Option
                        key={option.value}
                        value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
}

class FindStationForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: "",
        };
    }

    componentDidMount = () => {
        if (!this.props.assets.stations || !this.props.assets.stations.length) {
            this.props.dispatch(fetchStations());
        }
    };

    selectOption = (option) => {
        if (option) {
            this.props.findStationDisabled(false);
        } else {
            this.props.findStationDisabled(true);
        }

        this.setState({
            selectedOption: option,
        });
    };

    fetchStationList = (search, callback) => {
        if (search === "") {
            return callback([]);
        }

        const stations = _.orderBy(
            _.uniqBy(
                this.props.assets.stations
                    .filter((station) => {
                        return station.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    })
                    .map((station) => {
                        return {
                            label: station.name,
                            value: station.name,
                        };
                    }),
                "label",
            ),
            [(station) => station.label.toLowerCase()],
        );

        callback(stations);
    };

    findStation = () => {
        const selectedOption = _.find(this.props.assets.stations, (station) => station.name === this.state.selectedOption);
        if (selectedOption) {
            let north = null;
            let east = null;
            let south = null;
            let west = null;

            if (selectedOption.location[0] + 0.001 > north || !north) {
                north = selectedOption.location[0] + 0.001;
            }

            if (selectedOption.location[1] + 0.001 > east || !east) {
                east = selectedOption.location[1] + 0.001;
            }

            if (selectedOption.location[0] - 0.001 < south || !south) {
                south = selectedOption.location[0] - 0.001;
            }

            if (selectedOption.location[1] + 0.001 < west || !west) {
                west = selectedOption.location[1] - 0.001;
            }

            this.props.dispatch(
                goToBounds({
                    north,
                    south,
                    east,
                    west,
                }),
            );
            this.props.dispatch(toggleStationDisplay(selectedOption));
            this.props.closeModal();
        }
    };

    render = () => {
        return (
            <div className="stationForm">
                <DebounceSelect
                    value={this.state.selectedOption}
                    placeholder="Select users"
                    fetchOptions={this.fetchStationList}
                    onSelect={this.selectOption}
                    style={{
                        width: "80%",
                    }}
                />
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        assets: state.assets,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(FindStationForm);
