import React, { useEffect, useState } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useDispatch, useSelector } from "react-redux";
import { getCCTVMarkers } from "../../redux/actions/markerActions";
import { CCTVMarkerIconRecent, CCTVMarkerIconSemiRecent, CCTVMarkerIconOld } from "./Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExpand } from "@fortawesome/free-solid-svg-icons";
import OBCSpinner from "components/util/OBC";
import cctvMarker from "icons/cctv-marker.svg";
import moment from "moment";
import { customAudit } from "redux/actions/auditActions";

const createCustomClusterIcon = function (cluster) {
    return L.divIcon({
        className: "dummy",
        html: `
        <div class="CCTVMarkerGroupIcon">
            <div class="CCTVCameraCount">${cluster.getChildCount()}</div>
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 15px; height: 15px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
        iconSize: [25, 25],
        iconAnchor: [11, 27],
    });
};

// Custom pop up for images because the React Leaflet Popup component was crashing when the image went off the screen
const CCTVPopup = ({ visible, setVisible, position, map, imgSource, cameraName, cameraID, setModalVisible, setCameraInfo, camera }) => {
    const [point, setPoint] = useState();
    const [popupImageLoaded, setPopupImageLoaded] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);
    const dispatch = useDispatch();
    const csrfToken = useSelector((state) => state.csrfToken);

    useEffect(() => {
        if (visible && position && map) {
            const pointFromPixel = map.current.leafletElement.latLngToContainerPoint(position);
            setPoint(pointFromPixel);
        }

        if (map) {
            const updatePosition = () => {
                if (visible && position) {
                    const pointFromPixel = map.current.leafletElement.latLngToContainerPoint(position);
                    setPoint(pointFromPixel);
                }
            };

            map.current.leafletElement.on("move", updatePosition);

            // ESLint said this is the proper way of cleaning up the event listener with a ref, so who am I to disagree?
            const refVariableForCleanup = map.current.leafletElement;

            return () => {
                refVariableForCleanup.off("move", updatePosition);
            };
        }
    }, [visible, position, map]);

    if (visible && point) {
        return (
            <div
                className="CCTVPopup"
                style={{
                    left: `${point.x}px`,
                    top: `${point.y}px`,
                }}>
                <div className="PopupTitlebar">
                    <h5>{cameraName}</h5>
                    <button
                        className="PopupCloseButton"
                        onClick={() => setVisible(false)}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="PopupCloseIcon"
                        />
                    </button>
                </div>
                <div className={!popupImageLoaded ? "PopupImageLoader active" : "PopupImageLoader"}>
                    {!imageLoadError ? (
                        <OBCSpinner
                            size={50}
                            speed={3}
                        />
                    ) : (
                        "ERROR LOADING IMAGE"
                    )}
                </div>
                <img
                    className={!popupImageLoaded ? "CCTVImage" : "CCTVImage active"}
                    src={`https://cimages.aivr.video/${imgSource}?csrf=${csrfToken}`}
                    alt="CCTV"
                    onLoad={() => {
                        setPopupImageLoaded(true);
                    }}
                    onError={() => {
                        setImageLoadError(true);
                    }}
                />
                <div
                    className="CCTVImageHoverOverlay"
                    onClick={() => {
                        setCameraInfo(camera);
                        setVisible(null);
                        setModalVisible(true);
                        dispatch(customAudit("cctv_modal_opened", { modal_location: "map", camera_id: cameraID }, "CCTV modal opened from map"));
                    }}>
                    <FontAwesomeIcon icon={faExpand} />
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const CCTVImageMarkers = ({ map, setModalVisible, setCameraInfo }) => {
    const [camerasLoading, setCamerasLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState();

    const dispatch = useDispatch();
    const cameras = useSelector((state) => state.cctv.CCTVMarkers.cameras);

    useEffect(() => {
        dispatch(getCCTVMarkers(setCamerasLoading));
    }, [dispatch]);

    const getRecency = (timestamp) => {
        const date = moment.unix(timestamp);
        const hoursAgo = moment().diff(date, "hours");

        if (hoursAgo < 12) {
            return "recent";
        } else if (hoursAgo >= 12 && hoursAgo <= 24) {
            return "semi-recent";
        } else {
            return "old";
        }
    };

    if (!camerasLoading) {
        return (
            <MarkerClusterGroup iconCreateFunction={createCustomClusterIcon}>
                {cameras.map((camera) => {
                    return (
                        <Marker
                            position={camera.location}
                            onclick={(e) => {
                                setVisible(camera.id);
                                setPosition(e.latlng);
                            }}
                            key={camera.id}
                            icon={
                                getRecency(camera.thumbnail_ts) === "recent"
                                    ? CCTVMarkerIconRecent
                                    : getRecency(camera.thumbnail_ts) === "semi-recent"
                                      ? CCTVMarkerIconSemiRecent
                                      : CCTVMarkerIconOld
                            }>
                            <div className="CCTVImageStatus"></div>
                            <CCTVPopup
                                visible={visible === camera.id}
                                setVisible={() => setVisible(null)}
                                position={position}
                                imgSource={camera.thumbnail_url}
                                cameraName={camera.name}
                                imgTime={camera.thumbnail_ts}
                                map={map}
                                setModalVisible={setModalVisible}
                                setCameraInfo={setCameraInfo}
                                camera={camera}
                                cameraID={camera.id}
                            />
                        </Marker>
                    );
                })}
            </MarkerClusterGroup>
        );
    } else {
        return null;
    }
};

export default CCTVImageMarkers;
