import { Cone, Ring } from "@react-three/drei";
import React, { useMemo } from "react";

function CompassPoint({ heading, size }) {
    return (
        <group rotation={[0, (Math.PI * heading) / 180, 0]}>
            <group
                position={[-2.1, 0, 0]}
                scale={[size, 1, Math.sqrt(size)]}>
                <group position={[-0.1, 0, 0]}>
                    <Cone
                        args={[1, 1, 8, 1, false, 0, Math.PI]}
                        scale={[0.01, 0.2, 0.03]}
                        rotation={[0, 0, Math.PI / 2]}>
                        <meshBasicMaterial color="#80ff80" />
                    </Cone>
                </group>
            </group>
        </group>
    );
}

export function CompassRose({ complexity }) {
    const points = useMemo(() => {
        let stepDegrees;
        if (complexity === 0) {
            return [];
        } else if (complexity === 1) {
            stepDegrees = 90;
        } else if (complexity === 2) {
            stepDegrees = 45;
        } else if (complexity === 3) {
            stepDegrees = 22.5;
        } else if (complexity === 4) {
            stepDegrees = 10;
        } else if (complexity === 5) {
            stepDegrees = 5;
        } else if (complexity === 6) {
            stepDegrees = 1;
        } else {
            stepDegrees = 45;
        }
        const retVal = [];
        let size;
        for (let angle = 0; angle < 360; angle += stepDegrees) {
            if (angle === 0) {
                size = 3;
            } else if (angle % 90 === 0) {
                size = 1.4;
            } else if (angle % 10 === 0) {
                size = 0.8;
            } else if (angle % 5 === 0) {
                size = 0.5;
            } else {
                size = 0.2;
            }
            retVal.push(
                <CompassPoint
                    key={`${angle}`}
                    heading={angle}
                    size={size}
                />,
            );
        }
        return retVal;
    }, [complexity]);

    return (
        <group>
            <Ring
                rotation={[-Math.PI / 2, 0, 0]}
                args={[1.9, 2, 64]}>
                <meshBasicMaterial color="#80ff80" />
            </Ring>
            {points}
        </group>
    );
}
