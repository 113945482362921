import React from "react";

const DtProgressBar = ({ textVisible = true, ...props }) => {
    const test = props.progress ?? (props.score / props.sessionscount) * 100;
    const progressStyle = {
        maxWidth: "100%",
        width: `${test}%`,
    };

    if (props.score && props.sessionscount) {
        return (
            <div
                {...props}
                className={`progressBar ${props.className}`}>
                <div
                    className="progressBarInner"
                    style={progressStyle}></div>
                {props.textVisible && <span>{`${props.score}/${props.sessionscount}`}</span>}
            </div>
        );
    } else {
        return (
            <div
                {...props}
                className={`progressBar ${props.className}`}>
                <div
                    className="progressBarInner"
                    style={progressStyle}></div>
                {props.textVisible && <span>{props.progress}%</span>}
            </div>
        );
    }
};

export default DtProgressBar;
