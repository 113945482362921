import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import OBCSpinner from "../util/OBC";
import InfinitePatrols from "./InfinitePatrols";
import moment from "moment";
import _ from "lodash";
import { calculateRoutePercentageCovered, calculateTotalPatrolChainage } from "../util/PlaylistUtils";
import { Button } from "antd";

const patrolsSelector = (state) => state.schemaInterface.patrols;
const patrolsLoadedSelector = (state) => state.schemaInterface.patrolsLoaded;
const reviewerPatrolsSelector = (state) => state.schemaInterface.reviewerPatrols;
const reviewerPatrolsLoadedSelector = (state) => state.schemaInterface.reviewerPatrolsLoaded;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;

const PATROL_STATUS_NOT_STARTED = 0;
const PATROL_STATUS_IN_PROGRESS = 1;
const PATROL_STATUS_AWAITING = 2;
const PATROL_STATUS_REVIEWED = 3;

const PatrolList = ({
    onlyIncomplete,
    showUnusedPatrols,
    searchQuery,
    dateRange,
    sameDay,
    statusFilter,
    archivedPatrolsVisible,
    sortType,
    userSelected,
    openDiagramDrawer,
}) => {
    const routePatrols = useSelector(patrolsSelector);
    const routePatrolsLoaded = useSelector(patrolsLoadedSelector);
    const reviewerPatrols = useSelector(reviewerPatrolsSelector);
    const reviewerPatrolsLoaded = useSelector(reviewerPatrolsLoadedSelector);
    const isSupervisor = useSelector(isSupervisorSelector);

    const patrols = useMemo(() => {
        if (isSupervisor) {
            return reviewerPatrols;
        } else {
            return routePatrols;
        }
    }, [routePatrols, reviewerPatrols, isSupervisor]);

    const patrolsWithCoverage = useMemo(() => {
        return patrols.map((patrol) => {
            const totalChainage = calculateTotalPatrolChainage(patrol.svg.line);
            const coverage = calculateRoutePercentageCovered(patrol.svg.line, patrol.progress, totalChainage);
            return { ...patrol, calculatedCoverage: coverage };
        });
    }, [patrols]);

    const filteredPatrols = useMemo(() => {
        let retVal = [];

        if (patrolsWithCoverage && patrolsWithCoverage.length) {
            let patrols = patrolsWithCoverage;
            if (!_.isNil(sortType)) {
                if (sortType === 0) {
                    //deadline date
                    patrols = _.sortBy(patrols, (patrol) => {
                        let due_ts = patrol.due_ts ? patrol.due_ts : 0;
                        return [due_ts, patrol.name];
                    });
                } else if (sortType === 1) {
                    //status
                    patrols = _.sortBy(patrols, (patrol) => {
                        let due_ts = patrol.due_ts ? patrol.due_ts : 0;
                        let name = _.get(patrol, "name");
                        let status = _.get(patrol, "status");
                        return [status, due_ts, name];
                    });
                }
            }

            retVal = patrols.filter((patrol) => {
                if (onlyIncomplete) {
                    let filtered = patrol.calculatedCoverage === 100;
                    if (filtered) {
                        return false;
                    }
                }

                if (isSupervisor && !showUnusedPatrols) {
                    // unused patrols are both unassigned and past-due
                    let unassigned = !patrol.user_id;
                    let due_ts = patrol.due_ts ? patrol.due_ts : 0;
                    let overdue = due_ts < moment().startOf("day").unix();
                    if (unassigned && overdue) {
                        return false;
                    }
                }

                if (searchQuery) {
                    let filtered = !patrol.name.toLowerCase().includes(searchQuery);
                    if (filtered) {
                        return false;
                    }
                }

                if (dateRange.to && dateRange.from) {
                    const start = moment(dateRange.from).startOf("day").unix();
                    const end = moment(dateRange.to).endOf("day").unix();

                    const scheduledDate = new Date(patrol.due_ts * 1000);
                    const scheduledTs = moment(scheduledDate).endOf("day").unix();

                    if (scheduledTs < start || scheduledTs > end) {
                        return false;
                    }
                }

                if (statusFilter == "all_current") {
                    // all_current: anything which is not reviewed already
                    if (patrol.status == PATROL_STATUS_REVIEWED) {
                        return false;
                    }
                } else if (statusFilter == "unassigned") {
                    // unassigned: only show patrols without assigned patroller
                    if (patrol.user_name || patrol.user_email) {
                        return false;
                    }
                } else if (Number.isInteger(statusFilter)) {
                    // specific statuses: not started, in progress, awaiting review, reviewed
                    // we also want to hide unassigned patrols from 'assigned - not started' status
                    if (patrol.status !== statusFilter || (isSupervisor && !patrol.user_email)) {
                        return false;
                    }
                }

                if (!archivedPatrolsVisible) {
                    if (patrol.archived) {
                        return false;
                    }
                }

                if (userSelected) {
                    if (patrol.user_id !== userSelected) {
                        return false;
                    }
                }

                return true;
            });
        }

        return retVal;
    }, [patrolsWithCoverage, statusFilter, searchQuery, dateRange, onlyIncomplete, archivedPatrolsVisible, sortType, userSelected, showUnusedPatrols]);

    const content = useMemo(() => {
        if (!reviewerPatrolsLoaded && !routePatrolsLoaded) {
            return (
                <div className="SpinnerContainer">
                    <OBCSpinner colorScheme={"mono"} />
                </div>
            );
        }
        if ((patrols && !patrols.length) || (filteredPatrols && !filteredPatrols.length)) {
            return (
                <div className="SpinnerContainer">
                    <p className="Text">
                        No patrol jobs matching the current filters
                        <br />
                        <Button
                            type="primary"
                            onClick={() => openDiagramDrawer(true)}>
                            View all patrol diagrams
                        </Button>
                    </p>
                </div>
            );
        }
        return (
            <InfinitePatrols
                groupByDate={sortType === 0}
                patrols={filteredPatrols}
                searchQuery={searchQuery}
            />
        );
    }, [reviewerPatrolsLoaded, routePatrolsLoaded, patrols, filteredPatrols, searchQuery]);

    return <div className="PatrolList">{content}</div>;
};

export default PatrolList;
