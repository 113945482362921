import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs } from "antd";
import RouteInformation from "./route/RouteInformation";
import RouteContentList from "./route/RouteContentList";
import RouteHistoryList from "./display/video/RouteHistoryList";
import RouteTimeOffset from "./route/RouteTimeOffset";
import MarkerInformation from "./MarkerInformation";
import { toggleMarkerInfoShowing, toggleShowHistory, toggleSatelliteTabOpen } from "../redux/actions/index";
import SessionLogs from "./SessionLogs";
import AssetTable from "./AssetTable";
import MediaTab from "./mainTabs/media/MediaTab";
import NearbyAssetTab from "./Issues/NearbyAssetTab";
import SatelliteDataTab from "./SatelliteDataTab";

const { TabPane } = Tabs;

const TAB_ORDER = [
    "sessions",
    "issues",
    "shortcuts",
    "content_feed",
    "favourites",
    "map",
    "info",
    "content",
    "satellite_data",
    "history",
    "markerInfo",
    "timeOffset",
    "assets",
    "media",
];
class RouteInfoTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            routeHistoryVisible: false,
            currentTab: "info",
        };
    }

    componentDidMount = () => {
        if (this.props.defaultTab) {
            this.setDefaultTabKey(this.props.defaultTab);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedMarker !== this.props.selectedMarker) {
            if (_.isEmpty(this.props.selectedMarker) && this.state.currentTab !== "history") {
                this.updateCurrentTab("info");
            }
        }

        const previousType = _.get(
            prevProps.selectedMarker,
            "class",
            _.get(prevProps.selectedMarker, "name", _.get(prevProps.selectedMarker, "asset_type", "")),
        );
        const currentType = _.get(
            this.props.selectedMarker,
            "class",
            _.get(this.props.selectedMarker, "name", _.get(this.props.selectedMarker, "asset_type", "")),
        );

        if (this.state.currentTab !== "markerInfo" && previousType !== currentType && currentType) {
            this.updateCurrentTab("markerInfo");
        }

        if (prevProps.selectedAsset && !this.props.selectedAsset) {
            this.updateCurrentTab("info");
        }

        if (!prevProps.showHistory && this.props.showHistory) {
            this.updateCurrentTab("history");
        }

        if (this.props.currentDashboard !== prevProps.currentDashboard) {
            this.setDefaultTabKey(this.props.defaultTab);
        }

        if (
            !_.isEmpty(this.props.environmentDataSelectedSegment) &&
            this.props.environmentDataSelectedSegment !== prevProps.environmentDataSelectedSegment &&
            this.state.currentTab !== "satellite_data"
        ) {
            this.updateCurrentTab("satellite_data");
        }
    }

    updateCurrentTab = (tab) => {
        this.setState({ currentTab: tab });
        this.props.dispatch(toggleMarkerInfoShowing(tab === "markerInfo"));
        this.props.dispatch(toggleSatelliteTabOpen(tab === "satellite_data"));
        this.props.dispatch(toggleShowHistory(false));
    };

    routeDetailsTabChange = (tab) => {
        this.updateCurrentTab(tab);
    };

    reviewPermissions = () => {
        const reviewPermissions =
            !!_.get(this.props.data_pool_permissions, [_.get(this.props.session, ["data_pool"], false), "review"], false) ||
            this.props.userDetails.userConfig.super_admin;
        return reviewPermissions;
    };

    setDefaultTabKey = (tabKey) => {
        const isValidTabKey = _.find(this.tabPanes(), (tab) => tab.key === tabKey) ? true : false;
        if (isValidTabKey) {
            this.setState({
                currentTab: this.props.defaultTab,
            });
        }
    };

    tabPanes = () => {
        const isSuperUser = this.props.userDetails.userConfig.super_admin;
        const tabs = [
            <TabPane
                tab="Info"
                key="info"
                className="route-tabs-pane">
                <RouteInformation />
            </TabPane>,
            <TabPane
                tab="Session Content"
                key="content"
                className="route-tabs-pane"
                disabled={this.props.noPlaylistData || this.props.shareLink}>
                <RouteContentList />
            </TabPane>,
            <TabPane
                tab="History"
                key="history"
                className="route-tabs-pane"
                disabled={this.props.noPlaylistData || this.props.shareLink}>
                <RouteHistoryList visible={this.state.currentTab === "history"} />
            </TabPane>,
            <TabPane
                tab="Detection Info"
                key="markerInfo"
                className="route-tabs-pane DetectionInfoPane"
                disabled={_.isEmpty(this.props.selectedMarker) && !this.props.selectedHotspot}>
                {!_.isNil(this.props.selectedMarker.review_status) && <MarkerInformation />}
                {(this.props.selectedMarker.data || this.props.selectedMarker.custom_data) && !this.props.selectedMarker.consensus_data && <AssetTable />}
                {this.props.selectedMarker.consensus_data && <NearbyAssetTab />}
            </TabPane>,
        ];
        if (this.props.adminPermission && isSuperUser) {
            tabs.push(
                <TabPane
                    tab="GPS Adjustment"
                    key="timeOffset"
                    className="route-tabs-pane"
                    disabled={this.props.noPlaylistData || this.props.shareLink}>
                    <RouteTimeOffset />
                </TabPane>,
            );
        }
        if (isSuperUser) {
            tabs.push(
                <TabPane
                    tab="Log"
                    key="log"
                    className="route-tabs-pane"
                    disabled={this.props.noPlaylistData || this.props.shareLink}>
                    <SessionLogs />
                </TabPane>,
            );
        }
        if (this.props.extraTab) {
            tabs.push(this.props.extraTab);
        }

        if (this.props.extraMapLayers.includes("satellite")) {
            tabs.push(
                <TabPane
                    tab="Satellite Data"
                    key="satellite_data"
                    className="route-tabs-pane">
                    <SatelliteDataTab />
                </TabPane>,
            );
        }

        if (isSuperUser) {
            tabs.push(
                <TabPane
                    tab="Media"
                    key="media"
                    className="route-tabs-pane">
                    <MediaTab />
                </TabPane>,
            );
        }

        return tabs.sort((a, b) => {
            return TAB_ORDER.indexOf(a.key) - TAB_ORDER.indexOf(b.key);
        });
    };

    render() {
        return (
            <Tabs
                animated={{ inkBar: false, tabPane: true }}
                activeKey={this.state.currentTab}
                onChange={this.routeDetailsTabChange}
                tabBarStyle={{ color: "white" }}>
                {this.tabPanes()}
            </Tabs>
        );
    }
}

const mapStateToProps = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const environmentDataSelectedSegment = _.get(state.environmentData.selectedSegment, "id");
    const environmentDataLoaded = state.environmentData.dataLoaded;

    return {
        userDetails: state.userDetails,
        shareLink: state.shareLink,
        noPlaylistData: _.isEmpty(state.playlist.data),
        adminPermission: state.permissions.admin,
        selectedMarker: state.markers?.selectedMarker || {},
        showingMarkerInfo: state.markers.showingMarkerInfo,
        showHistory: state.playlist.consolidatedHistory.show,
        data_pool_permissions: _.get(currentDashboard, ["data_pool_permissions"], {}),
        session: state.sessions[state.playlist.data.routeID],
        selectedAsset: state.issues.selected,
        currentDashboard,
        defaultTab: _.get(currentDashboard, ["config", "default_tab"], "sessions"),
        extraMapLayers: _.get(currentDashboard, ["config", "extra_map_layers"], []),
        environmentDataSelectedSegment,
        environmentDataLoaded,
    };
};

export default connect(mapStateToProps)(withRouter(RouteInfoTabs));
