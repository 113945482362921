import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserPassword, validateResetToken } from "redux/actions/index";
import logo from "../images/new-branding-2.0/aivr-purple.svg";
import logoWhite from "../images/new-branding-2.0/aivr-hybrid.svg";
import { Button, Input } from "antd";
import validator from "validator";
import MediaQuery from "react-responsive";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new_password: "",
            new_password_confirm: "",
            saving: false,
            update_complete: false,
            has_error: false,
            token_error: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(validateResetToken(this.props.match.params.token)).then((status) => {
            if (status !== "valid") {
                this.setState({
                    token_error: status,
                });
            }
        });
    }

    updatePassword = (evt) => {
        this.setState({
            new_password: evt.target.value,
        });
    };

    updatePasswordConfirm = (evt) => {
        this.setState({
            new_password_confirm: evt.target.value,
        });
    };

    validate = () => {
        let password = this.state.new_password;
        let confirm = this.state.new_password_confirm;

        let password_valid = validator.isLength(password, { min: 12 });
        // room for more rules here

        return password_valid && password === confirm;
    };

    submitNewPassword = (evt) => {
        evt.preventDefault();
        let token = this.props.match.params.token;
        let new_password = this.state.new_password;
        this.setState({ saving: true });
        this.props.dispatch(
            updateUserPassword(token, new_password, (success) => {
                console.log("callback success:", success);

                if (success) {
                    this.setState({
                        saving: false,
                        update_complete: success,
                    });
                } else {
                    this.setState({
                        saving: false,
                        has_error: true,
                    });
                }
            }),
        );
    };

    render() {
        // let token = this.props.match.params.token;
        let content = null;

        if (this.state.token_error) {
            if (this.state.token_error === "expired") {
                content = (
                    <>
                        <div className="LoginBlurb">
                            <h3>This page has expired. Please request a new link by clicking below:</h3>
                        </div>
                        <Link
                            className="default"
                            to="/login/forgot">
                            Reset Password
                        </Link>
                    </>
                );
            } else {
                content = (
                    <>
                        <div className="LoginBlurb">
                            <h3>Invalid password reset link. Please try again by clicking below:</h3>
                        </div>
                        <Link
                            className="default"
                            to="/login/forgot">
                            Reset Password
                        </Link>
                    </>
                );
            }
        } else if (this.state.update_complete) {
            content = (
                <>
                    <div>Your new password has been set</div>
                    <Link
                        className="default"
                        to="/">
                        Go to Login
                    </Link>
                </>
            );
        } else {
            content = (
                <>
                    <div style={{ textAlign: "center" }}>
                        <div className="LoginBlurb">Enter your new password below.</div>
                        <div className="LoginBlurbAdvice">
                            Passwords must be at least 12 characters in length. We suggest using a combination of <b>three random words</b>.
                        </div>
                    </div>
                    <form
                        onSubmit={this.submitNewPassword}
                        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div className={"NewPasswordInputLine"}>
                            Enter new password:
                            <Input.Password
                                value={this.state.new_password}
                                onChange={this.updatePassword}
                            />
                        </div>

                        <div
                            className={"NewPasswordInputLine"}
                            style={{ marginBottom: 20 }}>
                            Re-enter new password:
                            <Input.Password
                                value={this.state.new_password_confirm}
                                onChange={this.updatePasswordConfirm}
                            />
                        </div>
                        <div className="LoginBlurbAdvice">
                            <b>Is your password unique?</b> Don't use the same password as other websites. Use a password manager rather than writing it down.
                        </div>
                        <Button
                            htmlType="submit"
                            type="primary"
                            disabled={!this.validate()}
                            loading={this.state.saving}>
                            Submit
                        </Button>
                    </form>
                    {this.state.has_error ? <div>There was an error processing your request. Please try again.</div> : null}
                </>
            );
        }

        return (
            <div className="LoginOverlay Center-Content">
                <div className="LoginContent">
                    <MediaQuery maxWidth={479}>
                        <img
                            className="LoginLogo mobile"
                            src={logoWhite}
                            alt="AIVR Logo"
                            crossOrigin={"anonymous"}
                        />
                    </MediaQuery>
                    <MediaQuery minWidth={479}>
                        <img
                            className="LoginLogo"
                            src={logo}
                            alt="AIVR Logo"
                            crossOrigin={"anonymous"}
                        />
                    </MediaQuery>
                    {content}
                    <div className="LoginFooter">
                        <div className="LoginTerms">
                            By using this website you agree to our&nbsp;
                            <p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/terms">
                                    Terms of Use
                                </a>{" "}
                                &{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/privacy">
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// need this to get this.props.dispatch - is mapStateToProps necessary?
export default connect()(withRouter(ForgotPassword));
