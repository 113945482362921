import { combineReducers } from "redux";
import {
    ADMIN_SESSIONS_LIST,
    EXIT_CURRENT_DASHBOARD,
    ADMIN_PASSWORD,
    ADMIN_MARKER_LIST,
    TOGGLE_ICON_CONFIGURATION,
    MARKER_DATA,
    USER_SEATS,
    BEHAVIOUR_ZONES,
    GPX_IMPORTS,
    RECEIVE_ADMIN_ANNOTATIONS,
    REVIEW_ANNOTATION,
    OBSERVATIONS_DATA,
    SET_OBSERVATION_DATA,
    FETCHING_OBSERVATION_DATA,
    SET_CURRENT_OBSERVATION,
    MARKER_DATA_LOADING,
    UPDATE_OBSERVATION,
    SET_THERMAL_CLASSIFICATIONS,
    SET_DATA_POOLS,
    ADD_BEHAVIOUR_ZONES_DETECTION,
    SET_DETECTIONS_LOADING,
} from "redux/actions";
import _ from "lodash";

function adminSessions(state = null, action) {
    if (action.type === ADMIN_SESSIONS_LIST) {
        state = action.sessions;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }

    return state;
}

function adminPassword(state = null, action) {
    if (action.type === ADMIN_PASSWORD) {
        state = action.data;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function adminMarkers(state = null, action) {
    if (action.type === ADMIN_MARKER_LIST) {
        state = action.annotations;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function iconConfiguration(state = { display: false, annotationID: 0 }, action) {
    if (action.type === TOGGLE_ICON_CONFIGURATION) {
        state = {
            display: action.toggleValue,
            annotationID: action.annotationID,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = { display: false, annotationID: 0 };
    }
    return state;
}

function markerStats(state = { markers: {} }, action) {
    if (action.type === MARKER_DATA) {
        state = action.markerData;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = { markers: {} };
    } else if (action.type === MARKER_DATA_LOADING) {
        state = action.loading;
        state = action.loadingError;
    }
    return state;
}

function userSeats(state = null, action) {
    if (action.type === USER_SEATS) {
        state = {
            seatLimit: action.seat_limit,
            userSeats: action.user_seats,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function behaviourZones(state = { devices: {}, zones: [] }, action) {
    if (action.type === BEHAVIOUR_ZONES) {
        state = {
            devices: action.devices,
            zones: action.zones,
        };
    }
    return state;
}

function behaviourZonesDetections(state = {}, action) {
    if (action.type === ADD_BEHAVIOUR_ZONES_DETECTION) {
        const newState = _.clone(state);
        const mergedObject = _.mergeWith(newState[action.detectionType], action.detections, (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        });
        newState[action.detectionType] = mergedObject;
        state = newState;
    }
    return state;
}

function detectionsLoading(state = {}, action) {
    if (action.type === SET_DETECTIONS_LOADING) {
        const newState = _.clone(state);
        newState[action.detectionType] = action.loading;
        state = newState;
    }
    return state;
}

function dataPools(state = [], action) {
    if (action.type === SET_DATA_POOLS) {
        state = action.data_pools;
    }
    return state;
}

function gpxImportTasks(state = [], action) {
    if (action.type === GPX_IMPORTS) {
        state = action.tasks;
    }
    return state;
}

function annotationReview(state = { annotations: [], nextPage: 0 }, action) {
    if (action.type === RECEIVE_ADMIN_ANNOTATIONS) {
        state = _.cloneDeep(state);
        state.annotations = _.unionBy(state.annotations, action.annotations, "id");
        state.nextPage = action.nextPage;
    } else if (action.type === REVIEW_ANNOTATION) {
        state = _.cloneDeep(state);
        const annIdx = _.findIndex(state.annotations, { id: action.id });
        if (annIdx > -1) {
            state.annotations[annIdx].reviewed = action.reviewed;
        }
    }
    return state;
}

const OBSERVATIONS_DEFAULT_DATA = {
    sessionList: {
        data: [],
        page_index: 0,
    },
    thermalClassifications: [],
    selectedSession: {
        sessionID: null,
        observations: [],
        currentObservation: null,
        count: null,
        loading: null,
    },
};

function observationReview(state = OBSERVATIONS_DEFAULT_DATA, action) {
    if (action.type === OBSERVATIONS_DATA) {
        state = _.cloneDeep(state);
        state.sessionList = action.observations;
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = OBSERVATIONS_DEFAULT_DATA;
    } else if (action.type === SET_OBSERVATION_DATA) {
        state = _.cloneDeep(state);
        state.selectedSession.observations = action.observations;
        state.selectedSession.count = action.count;
        state.selectedSession.currentObservation = 0;
    } else if (action.type === FETCHING_OBSERVATION_DATA) {
        state = _.cloneDeep(state);
        state.selectedSession.sessionID = action.sessionID;
        state.selectedSession.loading = action.loading;
    } else if (action.type === SET_CURRENT_OBSERVATION) {
        state = _.cloneDeep(state);
        state.selectedSession.currentObservation = action.index;
    } else if (action.type === UPDATE_OBSERVATION) {
        state = _.cloneDeep(state);
        state.selectedSession.observations = action.observations;
    } else if (action.type === SET_THERMAL_CLASSIFICATIONS) {
        state = _.cloneDeep(state);
        state.thermalClassifications = action.thermalClassifications;
    }

    return state;
}

const admin = combineReducers({
    sessions: adminSessions,
    password: adminPassword,
    markers: adminMarkers,
    iconConfiguration,
    markerStats,
    userSeats,
    behaviourZones,
    dataPools,
    gpxImportTasks,
    annotationReview,
    observationReview,
    behaviourZonesDetections,
    detectionsLoading,
});

export default admin;
