import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeToolMode,
    fetchGlobalSessionContent,
    getSessionData,
    goToBounds,
    routeHighlighted,
    routeSelected,
    targetResource,
    clearGlobalContent,
    customAudit,
} from "redux/actions/index";
import OBCSpinner from "./util/OBC";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ContentLoader from "react-content-loader";
import { calculateFrame, keySourceLookup } from "components/util/PlaylistUtils";
import _ from "lodash";
import { Input, Tooltip, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { faObjectUngroup, faPencilAlt, faRulerCombined, faUser, faClock, faLink, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { convertToTimezone } from "./util/TimezoneUtils";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import { faTrafficLight } from "@fortawesome/pro-solid-svg-icons";
import RouteCoordinateSystems from "./util/RouteCoordinateSystems";
import { RawImageComponent } from "./RawImageComponent";

const GUTTER_SIZE = 8;
const CONTENT_ITEM_HEIGHT = 120;

const EMPTY_ARRAY = [];
const globalContentSelector = (state) => state.globalContent.content;
const videoSelector = (state) => _.get(state.playlist.data, ["video"], EMPTY_ARRAY);
const currentIndexSelector = (state) => _.get(state, ["playlist", "position", "currentIndex"], 0);
const currentSourceSelector = (state) => _.get(state, ["playlist", "position", "sourceIndex"], 0);
const currentTimeOffsetSelector = (state) => _.get(state, ["playlist", "position", "currentTimeOffset"], 0);
const toolModeSelector = (state) => state.markup.tool_mode;
const targetResourceSelector = (state) => state.targetResource;
const currentDashboardSelector = (state) => _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
const globalContentPagingSelector = (state) => state.globalContent.paging;
const userConfigSelector = (state) => state.userDetails.userConfig;
const csrfTokenSelector = (state) => state.csrfToken;

const ContentSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={112}
        viewBox="0 0 780 112"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175"
        {...props}>
        <rect
            x="15"
            y="5"
            rx="3"
            ry="3"
            width="30"
            height="30"
        />
        <rect
            x="68"
            y="5"
            rx="3"
            ry="3"
            width="190"
            height="25"
        />
        <rect
            x="68"
            y="38"
            rx="3"
            ry="3"
            width="313"
            height="17"
        />
        <rect
            x="69"
            y="75"
            rx="3"
            ry="3"
            width="19"
            height="19"
        />
        <rect
            x="96"
            y="75"
            rx="3"
            ry="3"
            width="113"
            height="19"
        />
        <rect
            x="244"
            y="78"
            rx="3"
            ry="3"
            width="19"
            height="19"
        />
        <rect
            x="271"
            y="78"
            rx="3"
            ry="3"
            width="113"
            height="19"
        />
        <rect
            x="419"
            y="78"
            rx="3"
            ry="3"
            width="113"
            height="19"
        />
        <rect
            x="640"
            y="5"
            rx="3"
            ry="3"
            width="120"
            height="92"
        />
    </ContentLoader>
);

const baseRailInspectionURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const ContentItem = ({ contentData, style, goToVideo }) => {
    const dispatch = useDispatch();
    const [sessionData, setSessionData] = useState(null);
    const [sessionError, setSessionError] = useState(false);

    const video = useSelector(videoSelector);
    const currentIndex = useSelector(currentIndexSelector);
    const currentSource = useSelector(currentSourceSelector);
    const currentTimeOffset = useSelector(currentTimeOffsetSelector);
    const toolMode = useSelector(toolModeSelector);
    const currentDashboard = useSelector(currentDashboardSelector);
    const userConfig = useSelector(userConfigSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const [shareTooltipVisible, setTooltipVisible] = useState(false);

    useEffect(() => {
        dispatch(getSessionData(contentData.session_id))
            .then((sessionData) => {
                setSessionData(sessionData);
            })
            .catch((e) => {
                setSessionError(true);
            });
    }, [dispatch, contentData.session_id]);

    const dateCreated = useMemo(() => {
        if (contentData.timestamp) {
            const date = new Date(contentData.timestamp * 1000);
            return convertToTimezone(date, userConfig.convert_to_utc);
        } else {
            return "Unknown";
        }
    }, [contentData.timestamp, userConfig.convert_to_utc]);

    const shareLink = useMemo(() => {
        if (!currentDashboard) {
            return null;
        }
        const dataToEncode = {
            type: contentData.type,
            id: contentData.id,
            workspace_id: currentDashboard.access_id,
        };
        const encodedData = btoa(JSON.stringify(dataToEncode));
        const base = window.location.href.replace(/\?.*/, "");
        return base + "content/" + encodedData;
    }, [contentData, currentDashboard]);

    const isViewingContent = useMemo(() => {
        const videoKey = contentData.video_key;
        const type = contentData.type;
        const frame = contentData.frame;

        if (!video || !video.length) {
            return false;
        }
        let currentFrame = calculateFrame(video[currentSource], currentIndex, currentTimeOffset);

        if (video[currentSource] && video[currentSource][currentIndex]) {
            return (
                video[currentSource][currentIndex][0] === videoKey &&
                currentFrame === frame &&
                ((toolMode === "draw" && type === "Sketch") ||
                    (toolMode === "measure" && type === "Measurement") ||
                    (toolMode === "annotate" && type === "Annotation"))
            );
        } else {
            return false;
        }
    }, [contentData, video, currentSource, currentIndex, currentTimeOffset, toolMode]);

    const copyShareLink = useCallback(
        (e) => {
            e.stopPropagation();
            setTooltipVisible(true);
            console.log("Copying share link here", shareLink);
            navigator.clipboard.writeText(shareLink);
            setTimeout(() => {
                setTooltipVisible(false);
            }, 2000);
        },
        [shareLink],
    );

    const contentTypeIcon = useMemo(() => {
        // let contentTypeIcon = faPencilAlt;
        if (contentData.type === "Annotation") {
            // contentTypeIcon = faObjectUngroup;
            return (
                <Tooltip
                    title="Annotation"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faObjectUngroup}
                    />
                </Tooltip>
            );
        } else if (contentData.type === "Measurement") {
            // contentTypeIcon = faRulerCombined;
            return (
                <Tooltip
                    title="Measurement"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRulerCombined}
                    />
                </Tooltip>
            );
        } else if (contentData.type === "Bookmark") {
            // contentTypeIcon = faMapPin
            return (
                <Tooltip
                    title="Bookmark"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faMapPin}
                    />
                </Tooltip>
            );
        } else if (contentData.type === "Inspection Annotation") {
            return (
                <Tooltip
                    title="Inspection Annotation"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faObjectUngroup}
                    />
                </Tooltip>
            );
        } else if (contentData.type === "Feature") {
            return (
                <Tooltip
                    title="Feature"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faTrafficLight}
                    />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    title="Sketch"
                    trigger="hover">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faPencilAlt}
                    />
                </Tooltip>
            );
        }
    }, [contentData.type]);

    const image = useMemo(() => {
        if (contentData.type === "Feature") {
            let locationElements = null;

            if (contentData.rawRoutePosition && contentData.rawRoutePosition.length) {
                const routeCoordSystem = _.find(RouteCoordinateSystems, (sys) => {
                    return sys.ID === contentData.rawRoutePosition[0];
                });

                const location = routeCoordSystem.create(contentData.rawRoutePosition[1], contentData.rawRoutePosition[2], contentData.rawRoutePosition[3]);
                locationElements = _.map(location.fields(userConfig.elr_units), (field_name) => (
                    <span key={field_name}>
                        <b>{field_name}:</b> <span className="secondary">{location.field_value(field_name, [])}</span>
                    </span>
                ));
            }
            return (
                <div className="ContentPosInfo">
                    <span>Virtual Feature</span>
                    {locationElements}
                </div>
            );
        }
        let imageSrc = `https://raw${MEMOIZED_DOMAIN_URL}/${contentData.device}/${contentData.session_key}/${contentData.video_key}${!_.isNil(contentData.frame) ? "-" + contentData.frame : ""}.jpg`;
        if (contentData.image_path) {
            imageSrc = baseRailInspectionURL + contentData.image_path;
        }

        if (csrfToken) {
            imageSrc += `?csrf=${csrfToken}`;
        }

        return (
            <div className="ContentImage">
                <RawImageComponent
                    src={imageSrc}
                    alt="Content"
                    crossOrigin={"anonymous"}
                />
            </div>
        );
    }, [contentData.device, contentData.session_key, contentData.video_key, contentData.image_path, contentData.coordinates]);

    const onClick = (contentData, sessionData) => {
        dispatch(
            customAudit(
                "markup_feed_item_selected",
                {
                    markupType: contentData.type,
                    label: contentData.label,
                    id: contentData.id,
                    session_id: contentData.session_id,
                },
                `${contentData.type} content feed item selected`,
            ),
        );
        console.log("debug go to session", { sessionData, contentData });
        goToVideo(contentData, sessionData);
    };

    if ((!sessionData && !sessionError) || _.isEmpty(contentData)) {
        return (
            <div
                className="GlobalContentList__Item"
                style={style}>
                <ContentSkeleton />
            </div>
        );
    }

    return (
        <div
            className={"GlobalContentList__Item" + (isViewingContent ? " active" : "") + (sessionError ? " Disabled" : "")}
            style={style}
            onClick={() => (!sessionError ? onClick(contentData, sessionData) : null)}>
            <div className="Content__Icon">{contentTypeIcon}</div>

            <div className="Content__Inner">
                <div className="Content__Inner__Section">
                    <div className="Content__Inner__Info">
                        <p className="ContentLabel">{contentData.label}</p>
                        {sessionData ? (
                            <p className="ContentId">
                                #{contentData.session_id} - {sessionData.route_name}
                            </p>
                        ) : (
                            <p className="ContentId">Session has been deleted</p>
                        )}
                    </div>
                </div>

                <div className="Content__Inner__Section bottom">
                    <div className="InfoRow">
                        <FontAwesomeIcon
                            className="InfoIcon"
                            icon={faUser}
                        />
                        <p className="">{contentData.user}</p>
                    </div>

                    <div className="InfoRow Wide">
                        <FontAwesomeIcon
                            className="InfoIcon"
                            icon={faClock}
                        />
                        <p className="">{dateCreated}</p>
                    </div>

                    {!sessionError && (
                        <div className="InfoRow">
                            <Tooltip
                                title="Link copied!"
                                trigger="click"
                                mouseLeaveDelay={3}
                                visible={shareTooltipVisible}>
                                <div
                                    className="ShareContainer"
                                    onClick={(e) => copyShareLink(e)}>
                                    <FontAwesomeIcon
                                        className="InfoShareLinkIcon"
                                        icon={faLink}
                                    />
                                    <span>Copy Share Link</span>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            {image}
        </div>
    );
};

const GlobalContentTab = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const globalContent = useSelector(globalContentSelector);
    const currentTargetResource = useSelector(targetResourceSelector);
    const video = useSelector(videoSelector);
    const pagingData = useSelector(globalContentPagingSelector);
    const userConfig = useSelector(userConfigSelector);

    const contentList = useRef();

    const [initialised, setInitialised] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("all");
    const [sortValue, setSortValue] = useState("recent");
    const [scrolledToShare, setScrolledToShare] = useState(false);
    const [fetchingContent, setFetchingContent] = useState(true);
    const [exportModalShowing, setExportModalShowing] = useState(false);

    useEffect(() => {
        dispatch(customAudit("markup_feed_opened", {}));
    }, [dispatch]);

    const scrollToContent = (index) => {
        if (index !== -1 && contentList.current) {
            let scrolltop = index * CONTENT_ITEM_HEIGHT;
            contentList.current.scrollTo({
                top: scrolltop,
                behavior: "smooth",
            });
        }
    };

    const getFilteredContent = useCallback(
        (page = 0) => {
            setFetchingContent(true);
            const options = {};
            if (searchFilter) {
                options.query = searchFilter;
            }
            if (typeFilter) {
                options.type = typeFilter;
            }
            if (sortValue) {
                options.sort = sortValue;
            }

            dispatch(
                fetchGlobalSessionContent(
                    options,
                    () => {
                        setFetchingContent(false);
                        setInitialised(true);
                    },
                    page,
                ),
            );
        },
        [searchFilter, typeFilter, sortValue, dispatch],
    );

    useEffect(() => {
        setInitialised(false);
        dispatch(clearGlobalContent());
        getFilteredContent();
    }, [getFilteredContent, dispatch]);

    const goToVideo = useCallback(
        (contentData, sessionData) => {
            if (sessionData) {
                dispatch(goToBounds(sessionData.bounds));
            }

            if (contentData.type === "Bookmark") {
                history.push({
                    pathname: `/rail-inspection/${contentData.session_id}`,
                    state: { bookmarkID: contentData.id },
                });
            }

            if (contentData.type === "Inspection Annotation") {
                history.push({
                    pathname: `/rail-inspection/${contentData.session_id}`,
                    state: { annotationID: contentData.id },
                });
            }
            if (contentData.video_key) {
                let keySource = keySourceLookup(contentData.video_key, video);
                dispatch(routeSelected(contentData.session_id, contentData.video_key, undefined, undefined, keySource, contentData.frame, false, true));
            } else {
                console.log("debug contentdata", contentData);
                dispatch(routeSelected(contentData.session_id, contentData.feature_timestamp, undefined, undefined, 0, 0, false, true));
            }

            if (contentData.type === "Measurement") {
                dispatch(changeToolMode("measure"));
            }

            if (contentData.type === "Sketch") {
                dispatch(changeToolMode("draw"));
            }

            if (contentData.type === "Annotation") {
                dispatch(changeToolMode("annotate"));
            }
        },
        [dispatch, video, history],
    );

    const Row = useCallback(
        ({ index, style }) => {
            const contentData = globalContent[index] || {};
            return (
                <ContentItem
                    style={{
                        ...style,
                        left: style.left,
                        top: style.top + GUTTER_SIZE,
                        width: !isNaN(style.width) && style.width - GUTTER_SIZE,
                        height: style.height - GUTTER_SIZE,
                    }}
                    contentData={contentData}
                    goToVideo={goToVideo}
                />
            );
        },
        [globalContent, goToVideo],
    );

    const handleScroll = useCallback(
        (e, _e) => {
            const idx = Math.round(e.scrollOffset / CONTENT_ITEM_HEIGHT);
            if (idx >= globalContent.length - 10 && pagingData.isMore) {
                if (!fetchingContent) {
                    getFilteredContent(pagingData.count + 1);
                }
            }
        },
        [pagingData, getFilteredContent, globalContent.length, fetchingContent],
    );

    const content = useMemo(() => {
        if (initialised && searchQuery === searchFilter) {
            if (globalContent.length) {
                return (
                    <div
                        className="sessionListContainer"
                        id="ScrollList">
                        <AutoSizer className="sessionAutosizer">
                            {({ height, width }) => (
                                <FixedSizeList
                                    onScroll={handleScroll}
                                    height={height + GUTTER_SIZE}
                                    width={width}
                                    itemSize={CONTENT_ITEM_HEIGHT}
                                    outerRef={contentList}
                                    itemCount={globalContent.length + (fetchingContent ? 1 : 0)}>
                                    {Row}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                );
            } else {
                return (
                    <div
                        className="sessionListContainer"
                        id="ScrollList">
                        <h1 className="noContentText">No content found</h1>
                    </div>
                );
            }
        } else {
            return (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }
    }, [initialised, globalContent, searchQuery, searchFilter, Row, handleScroll, fetchingContent]);

    const updateSearchFilter = useCallback(
        _.debounce((query) => {
            setSearchFilter(query);
        }, 1500),
        [],
    );

    const onSearchChange = (e) => {
        const value = e.target.value;
        updateSearchFilter(value);
        setSearchQuery(value);
    };

    const findAndSelectContent = useCallback(
        (type, id) => {
            const contentIndex = _.findIndex(globalContent, { type, id });
            if (contentIndex > -1) {
                const content = globalContent[contentIndex];
                dispatch(getSessionData(content.session_id)).then((sessionData) => {
                    goToVideo(content, sessionData);
                    scrollToContent(contentIndex);
                    dispatch(targetResource(null));
                });
            } else {
                dispatch(
                    fetchGlobalSessionContent(
                        {
                            markup_id: id,
                            markup_type: type,
                        },
                        (markup) => {
                            if (markup.length) {
                                dispatch(getSessionData(markup[0].session_id)).then((sessionData) => {
                                    goToVideo(markup[0], sessionData);
                                    dispatch(targetResource(null));
                                });
                            }
                        },
                        0,
                        false,
                    ),
                );
            }
        },
        [dispatch, goToVideo, globalContent],
    );

    useEffect(() => {
        if (globalContent && globalContent.length && !scrolledToShare) {
            if (currentTargetResource && currentTargetResource.type && currentTargetResource.id) {
                findAndSelectContent(currentTargetResource.type, currentTargetResource.id);
                setScrolledToShare(true);
            }
        }
    }, [globalContent, currentTargetResource, findAndSelectContent, scrolledToShare]);

    useEffect(() => {
        if (globalContent.length && currentTargetResource && currentTargetResource.type && currentTargetResource.id) {
            findAndSelectContent(currentTargetResource.type, currentTargetResource.id);
        }
    }, [dispatch, currentTargetResource, findAndSelectContent, globalContent.length]);

    const openExportModal = () => {
        setExportModalShowing(true);
    };

    return (
        <div className="GlobalContentList">
            <div className="sessionFilterContainer">
                <div className="sessionFilterSelect">
                    <span>Type:</span>
                    <Select
                        style={{ width: "100%" }}
                        value={typeFilter}
                        onChange={setTypeFilter}>
                        <Select.Option value="all">All</Select.Option>
                        <Select.Option value="Measurement">Measurements</Select.Option>
                        <Select.Option value="Annotation">Annotations</Select.Option>
                        <Select.Option value="Sketch">Sketches</Select.Option>
                        <Select.Option value="Bookmark">Bookmarks</Select.Option>
                    </Select>
                </div>
                <div className="sessionFilterSelect">
                    <span>Sort by:</span>
                    <Select
                        style={{ width: "100%" }}
                        value={sortValue}
                        onChange={setSortValue}>
                        <Select.Option value="recent">Most Recent</Select.Option>
                        <Select.Option value="session">Session</Select.Option>
                        <Select.Option value="user">User</Select.Option>
                    </Select>
                </div>

                <div className="sessionSearch">
                    <Input
                        value={searchQuery}
                        onChange={onSearchChange}
                        placeholder="Search for a user / label"
                        style={{ height: 35 }}
                        spellCheck={false}
                        prefix={<SearchOutlined />}
                        allowClear
                    />
                </div>
            </div>

            {content}
        </div>
    );
};
export default GlobalContentTab;
