const originalLog = console.log;

export const setupLogging = (enabled) => {
    if (enabled) {
        console.log = originalLog;
    } else {
        console.log = function () {
            return;
        };
    }
};
