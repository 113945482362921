import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DraggableWrapper from "./DraggableWrapper";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faLock, faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { deleteBookmark, updateBookmark, selectBookmark, setPatrolLocation } from "../../../redux/actions/index";
import { Tooltip } from "antd";
import OBCSpinner from "../../util/OBC";
import { calculateOffsettedPatrolImage } from "../../util/PlaylistUtils";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";

const EMPTY_OBJ = {};
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const userNameSelector = (state) => state.userDetails.userConfig.name;
const currentBookmarkSelected = (state) => state.railInspection.selectedBookmark;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const railImageConfigObjectSelector = (state) => {
    const railInspectConfig = _.get(state, ["railInspection", "railInspectionImageConfig"], false);
    if (!_.isEmpty(railInspectConfig)) {
        return railInspectConfig;
    } else {
        return EMPTY_OBJ;
    }
};
const railDataObjectSelector = (state) => {
    const railInspectImages = _.get(state, ["railInspection", "railInspectionImages", "data"], []);
    const patrolImages = _.get(state, ["schemaInterface", "images"], []);

    if (railInspectImages.length) {
        return railInspectImages;
    } else if (patrolImages.length) {
        return patrolImages;
    } else {
        return [];
    }
};
const railDataLoadedSelector = (state) => {
    const railInspectionLoaded = state.railInspection.railInspectionImages.loadingInfo.loaded;
    const routePatrolLoaded = state.schemaInterface.imagesLoaded;

    if (routePatrolLoaded || railInspectionLoaded) {
        return true;
    } else {
        return false;
    }
};
const allBookmarksFetchedSelector = (state) => state.railInspection.allInspectionBookmarksFetched;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const csrfTokenSelector = (state) => state.csrfToken;

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const BookmarkList = ({
    setLabelWindowOpen,
    labelWindowOpen,
    setUpdatingBookmarkID,
    routePatrol,
    wrapInDraggable,
    windowDimensions,
    closeAction,
    inResizeDraggable,
}) => {
    const dispatch = useDispatch();

    const bookmarks = useSelector(bookmarksSelector);
    const railDataObject = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const userName = useSelector(userNameSelector);
    const activeBookmark = useSelector(currentBookmarkSelected);
    const railImageConfig = useSelector(railImageConfigObjectSelector);
    const allBookmarksFetched = useSelector(allBookmarksFetchedSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const showButtons = useMemo(() => {
        if (!patrolStatus || (patrolStatus && patrolStatus === 1)) {
            return true;
        }
        if (patrolStatus === 2 || patrolStatus === 3) {
            return !!isSupervisor;
        }
    }, [patrolStatus, isSupervisor]);

    const railImages = useMemo(() => {
        let retVal;
        if (railDataObject && railDataLoaded) {
            retVal = railDataObject;
        } else {
            retVal = [];
        }
        return retVal;
    }, [railDataObject, railDataLoaded]);

    const handleOpenUpdateModal = useCallback(
        (id) => {
            setUpdatingBookmarkID(id);
            setLabelWindowOpen(true);
        },
        [setLabelWindowOpen, setUpdatingBookmarkID],
    );

    const handleDelete = useCallback(
        (e, id) => {
            e.stopPropagation();
            dispatch(deleteBookmark(id));
        },
        [dispatch],
    );

    const handlePrivate = useCallback(
        (e, id, description, isItemPrivate) => {
            e.stopPropagation();
            dispatch(updateBookmark(id, description, !isItemPrivate));
        },
        [dispatch],
    );

    const handleBookmarkClick = useCallback(
        (bookmark) => {
            if (!routePatrol) {
                dispatch(selectBookmark(bookmark.id));
            } else {
                const bookmarkImage = calculateOffsettedPatrolImage(bookmark, railImageConfig, patrolDirections, railImages);
                if (!bookmarkImage) {
                    return null;
                }
                const chainValue = parseInt(bookmarkImage.mwv.mile) * 80 + parseFloat(bookmarkImage.mwv.yard) / 22;
                const trackID = bookmarkImage.mwv.trid;
                const elr = bookmarkImage.mwv.elr;
                const locationObj = {
                    elr: elr,
                    chain: chainValue,
                    trackID: trackID,
                };
                dispatch(setPatrolLocation(locationObj, bookmarkImage.timestamp));
            }
        },
        [dispatch, railImages, routePatrol, patrolDirections, railImageConfig],
    );

    const content = useMemo(() => {
        let body = (
            <div className="SchemaRuns__Spinner">
                <OBCSpinner
                    size={70}
                    speed={3}
                    colorScheme="mono"
                />
            </div>
        );

        if (allBookmarksFetched) {
            body = (
                <div
                    className="inspectRail__BookmarkList"
                    style={{ maxHeight: inResizeDraggable ? "none" : "initial" }}>
                    {bookmarks &&
                        railImageConfig.inspection_images &&
                        bookmarks.map((item, index) => {
                            const image = _.find(railImages, ["timestamp", item.image_timestamp]);
                            const validInspectionImages = railImageConfig.inspection_images.filter((source) => {
                                return !source.showInModal;
                            });
                            const source = validInspectionImages[item.source];

                            if (_.isNil(source) || _.isNil(image)) {
                                return null;
                            }

                            const deviceName = source.source;
                            const imgWidth = source.display_width;
                            let base_path = image.base_path;
                            const new_base_path = base_path.replace("$DEVICE", `${deviceName}`);
                            let imagePath = baseURL + new_base_path + "/" + image.timestamp + ".jpg";

                            if (csrfToken) {
                                imagePath += `?csrf=${csrfToken}`;
                            }

                            const backgroundWidth = (source.img_width / source.src_width) * 90;
                            const backgroundX = (source.src_x / source.src_width) * 90;

                            const style = {
                                backgroundImage: `url(${imagePath})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundPosition: `-${backgroundX}px 0`,
                                backgroundSize: `${backgroundWidth}px 100%`,
                                width: `90px`,
                                height: `100%`,
                            };

                            const left = (item.image_coordinates[1] / imgWidth) * 90;
                            const bottom = (item.image_coordinates[0] / 1000) * 100;

                            return (
                                <div
                                    key={index}
                                    className={`inspectRail__BookmarkList__Item ${activeBookmark && activeBookmark === item.id ? " active" : ""}`}
                                    onClick={() => handleBookmarkClick(item)}>
                                    <div className="inspectRail__BookmarkList__Item__Image">
                                        <div className="inspectRail__BookmarkList__Item__Image-Inner">
                                            <span style={{ left: left + "px", bottom: bottom + "%" }} />
                                            {image && <div style={style} />}
                                        </div>
                                    </div>
                                    <div className="inspectRail__BookmarkList__Item__Inner">
                                        <div className="inspectRail__BookmarkList__Item__Info">
                                            <span>{item.description}</span>
                                            <span className="user">{item.user}</span>
                                        </div>
                                        <div className="inspectRail__BookmarkList__Item__Controls">
                                            {userName === item.user && showButtons && (
                                                <>
                                                    <button
                                                        disabled={labelWindowOpen}
                                                        onClick={() => handleOpenUpdateModal(item.id)}>
                                                        <Tooltip title="Edit">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Tooltip>
                                                    </button>
                                                    <button onClick={(e) => handleDelete(e, item.id)}>
                                                        <Tooltip title="Delete">
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Tooltip>
                                                    </button>
                                                    <button
                                                        onClick={(e) => handlePrivate(e, item.id, item.description, item.private)}
                                                        className={item.private ? "private" : ""}>
                                                        <Tooltip title="Privacy">
                                                            <FontAwesomeIcon icon={item.private ? faLock : faUnlock} />
                                                        </Tooltip>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            );
        }

        if (allBookmarksFetched && !bookmarks.length) {
            body = (
                <div className="inspectRail__Detections__Message">
                    <span>No Bookmarks on this session</span>
                </div>
            );
        }

        return body;
    }, [
        activeBookmark,
        bookmarks,
        handleBookmarkClick,
        handleDelete,
        handleOpenUpdateModal,
        handlePrivate,
        labelWindowOpen,
        railImages,
        userName,
        railImageConfig.inspection_images,
        allBookmarksFetched,
    ]);

    if (wrapInDraggable) {
        return (
            <DraggableWrapper
                content={content}
                title="Bookmarks"
                windowDimensions={windowDimensions}
                closeAction={closeAction}
                customDimensions={{ x: "300px", y: "auto" }}
            />
        );
    } else {
        return content;
    }
};

export default BookmarkList;
