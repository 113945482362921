import React, { forwardRef } from "react";
import "../../style/aivrComponents.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OBCButton = forwardRef((props, ref) => {
    // available variants: primary, primary-antd, danger, success, tool, ghost, amber, link, white
    // available sizes: sm, md, lg
    // iconPosition; left, right

    const iconSize = (buttonSize) => {
        switch (buttonSize) {
            case "sm":
                return "xs";

            case "lg":
                return "1x";

            default:
                return "sm";
        }
    };

    if (props.type === "file") {
        return (
            <input
                {...props}
                ref={ref}
                type={props.type}
                style={props.style}
                onClick={(e) => props.onClick(e)}
                className={`${props.className ? props.className + " " : ""}OBCButton OBCButton-${props.variant} OBCButton-${props.size}`}
                disabled={props.disabled}
                placeholder="test"
            />
        );
    }

    return (
        <button
            {...props}
            ref={ref}
            style={props.style}
            onClick={(e) => props.onClick(e)}
            className={`${props.className ? props.className + " " : ""}OBCButton OBCButton-${props.variant} OBCButton-${props.size} ${props.active ? "active" : ""}`}
            disabled={props.disabled}>
            {props.icon && props.iconPosition === "left" && (
                <FontAwesomeIcon
                    size={iconSize(props.size)}
                    className="OBCButton-icon"
                    icon={props.icon}
                />
            )}
            {props.children && <>{props.children}</>}
            {props.icon && props.iconPosition === "right" && (
                <FontAwesomeIcon
                    size={iconSize(props.size)}
                    className="OBCButton-icon"
                    icon={props.icon}
                />
            )}
        </button>
    );
});

OBCButton.defaultProps = {
    onClick: (e) => {
        e.stopPropagation();
    },
    children: "",
    type: "text",
    variant: "primary",
    size: "md",
    disabled: false,
    style: null,
    className: false,
    icon: null,
    iconPosition: "left",
    active: false,
};

export default OBCButton;
