import React from "react";
import "../../mobile.scss";
import { connect } from "react-redux";
import _ from "lodash";
import { Slider } from "antd";

class MobileScrubber extends React.PureComponent {
    render() {
        let maxValue = this.props.imageKeys.length - 1;
        if (maxValue < 0) {
            maxValue = 1;
        }

        return (
            <div className="mobileScrubberContainer">
                <Slider
                    min={0}
                    max={maxValue}
                    value={this.props.index}
                    onChange={this.props.imageIndexChanged}
                    tipFormatter={null}
                    className={"mobileSlider"}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ playlist }) => {
    let imageKeys = _.get(playlist.data, ["video", playlist.position.sourceIndex], []);

    return {
        imageKeys,
    };
};

export default connect(mapStateToProps)(MobileScrubber);
