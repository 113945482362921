import React, { useState } from "react";
import { Button, Input } from "antd";
import { useDispatch } from "react-redux";
import { setVirtualCabRideEmail } from "../../redux/actions/index";

const HazardSplash = ({ updateEmail, emailIsValid, userEmail, setEmailSubmitted }) => {
    const dispatch = useDispatch();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const submitEmail = () => {
        if (emailIsValid) {
            setEmailSubmitted(true);
            dispatch(setVirtualCabRideEmail(userEmail));
        } else {
            setShowErrorMessage(true);
        }
    };

    return (
        <div className="HazardPerception__Splash">
            <div className="HazardPerception__Splash__Inner">
                <h2>Let's get started</h2>
                <Input
                    align="center"
                    className="hazardInput"
                    type="email"
                    placeholder="Enter your e-mail address"
                    value={userEmail}
                    onChange={(e) => updateEmail(e)}
                    onPressEnter={submitEmail}
                />
                <Button
                    htmlType="submit"
                    type="primary"
                    className="hazardSignup"
                    onClick={submitEmail}>
                    I'm Ready
                </Button>
                {showErrorMessage && <span className="errorMessage">Enter a valid e-mail address</span>}
            </div>
        </div>
    );
};

export default HazardSplash;
