import React from "react";
import { connect } from "react-redux";
import { Marker } from "react-leaflet";
import _ from "lodash";

class PlaylistPositionMarker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.timeOfLastCenter = 0;
        this.centerMapExecutor = null;
    }

    componentWillUnmount() {
        clearTimeout(this.centerMapExecutor);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.centerMap && this.props.playlistPosition && this.props.playlistPosition !== prevProps.playlistPosition) {
            const now = new Date().getTime();
            clearTimeout(this.centerMapExecutor);
            const timeTillNextUpdate = this.timeOfLastCenter + 500 - now;
            if (timeTillNextUpdate <= 0) {
                this.props.mapRef.current.leafletElement.invalidateSize();
                this.props.centerMap(this.props.playlistPosition);
                this.timeOfLastCenter = now;
            } else {
                this.centerMapExecutor = setTimeout(() => {
                    if (this.props.playlistPosition) {
                        this.props.mapRef.current.leafletElement.invalidateSize();
                        this.props.centerMap(this.props.playlistPosition);
                        this.timeOfLastCenter = new Date().getTime();
                    }
                }, timeTillNextUpdate);
            }
        }
    }

    render() {
        if (this.props.playlistPosition && this.props.playlistPosition[1] !== null && this.props.playlistPosition[0] !== null) {
            return (
                <Marker
                    zIndexOffset={99999}
                    position={[this.props.playlistPosition[1], this.props.playlistPosition[0]]}
                />
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    let position = state.playlist.position.coords;
    if (ownProps.stripped) {
        position = _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "position", "coords"], [0, 0]);
    }

    return {
        playlistPosition: position,
    };
};

export default connect(mapStateToProps)(PlaylistPositionMarker);
