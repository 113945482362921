import React, { useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import HazardSplash from "./HazardSplash";
import HazardInterface from "./HazardInterface";
import validator from "validator";
import aivrLogo from "../../images/new-branding-2.0/aivr-hybrid.svg";
import obcLogo from "../../images/new-branding-2.0/obc-hybrid.svg";
import networkRailLogo from "../../images/network-rail-white-transparent.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import HazardResults from "./HazardResults";

const HazardPerception = () => {
    const hazardRef = useRef();
    const [userEmail, setUserEmail] = useState();
    const [clickCounter, setClickCounter] = useState({});
    const [email, setEmailSubmitted] = useState();
    const [resultsSubmitted, setResultsSubmitted] = useState(false);

    useEffect(() => {
        if (hazardRef && hazardRef.current) {
            hazardRef.current.ontouchmove = (e) => {
                e.preventDefault();
            };
        }
    }, [hazardRef]);

    const updateEmail = (e) => {
        setUserEmail(e.target.value);
    };

    const emailIsValid = useMemo(() => {
        return validator.isEmail(userEmail + "");
    }, [userEmail]);

    const countClick = (type) => {
        let newCount = _.cloneDeep(clickCounter);
        if (!newCount[type]) {
            newCount[type] = 1;
        } else {
            newCount[type] += 1;
        }
        setClickCounter(newCount);
    };

    const totalCount = useMemo(() => {
        let count = 0;
        Object.keys(clickCounter).forEach((item) => {
            count = count + clickCounter[item];
        });
        return count;
    }, [clickCounter]);

    const onBackClick = () => {
        setUserEmail();
        setClickCounter({});
        setEmailSubmitted(false);
        setResultsSubmitted(false);
    };

    const resetClicked = () => {
        setClickCounter({});
    };

    const submitClicked = () => {
        setResultsSubmitted(true);
    };

    let backIcon;
    let permBackIcon;
    let pageClass = "HarzardPerceptionLoginPage";
    let content = (
        <HazardSplash
            updateEmail={updateEmail}
            emailIsValid={emailIsValid}
            userEmail={userEmail}
            setEmailSubmitted={setEmailSubmitted}
        />
    );

    if (email) {
        pageClass = "HarzardPerceptionInputPage";
        content = (
            <HazardInterface
                countClick={countClick}
                totalCount={totalCount}
                clickCounter={clickCounter}
                resetClicked={resetClicked}
                submitClicked={submitClicked}
            />
        );
        backIcon = (
            <div className="BackContainer">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={onBackClick}
                />
            </div>
        );

        permBackIcon = (
            <div className="HazardPerception__BackIconPerm BackContainer">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={onBackClick}
                />
            </div>
        );
    }

    if (resultsSubmitted) {
        pageClass = "HarzardPerceptionResultsPage";
        content = (
            <HazardResults
                clickCounter={clickCounter}
                totalCount={totalCount}
            />
        );
    }

    return (
        <div
            className={`HazardPerception ${pageClass}`}
            ref={hazardRef}>
            <div className="HazardPerception__Header">
                {backIcon}
                <div>
                    <h1>Virtual Cab Ride</h1>
                </div>
                <div className="logo">
                    <img
                        className="aivr-logo"
                        src={aivrLogo}
                        alt="AIVR Logo"
                        crossOrigin={"anonymous"}
                    />
                </div>
            </div>
            {permBackIcon}
            {content}
            <div
                className="HazardPerception__Footer"
                onClick={() => (window.location.href = "https://onebigcircle.co.uk/aivr/")}>
                <img
                    className="obc-logo"
                    src={obcLogo}
                    alt="OneBigCircle Logo"
                />
            </div>
        </div>
    );
};

export default HazardPerception;
