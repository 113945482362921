import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useResizeContext } from "../DraggableWrapper";

const DraggableBar = ({ top, height, width, right, bottom, limits, customDimensionsRef }) => {
    const barRef = useRef();
    const lastMousePosition = useRef({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    const handleMouseDown = (e) => {
        lastMousePosition.current = { x: e.clientX, y: e.clientY };
        setDragging(true);
    };

    const handleResize = useResizeContext()[0];

    const handleMove = (e) => {
        if (dragging) {
            const deltaX = e.clientX - lastMousePosition.current.x;
            const deltaY = e.clientY - lastMousePosition.current.y;
            handleResize((prev) => {
                const newWidth = Math.max(limits.minWidth, Math.min(limits.maxWidth, prev.width + deltaX));
                const newHeight = Math.max(limits.minHeight, Math.min(limits.maxHeight, prev.height + deltaY));

                customDimensionsRef.current = { width: newWidth, height: newHeight };

                return {
                    ...prev,
                    width: newWidth,
                    height: newHeight,
                };
            });

            lastMousePosition.current = { x: e.clientX, y: e.clientY };
        }
    };

    useEffect(() => {
        const handleMouseUp = () => setDragging(false);

        if (dragging) {
            document.addEventListener("mousemove", handleMove);
            document.addEventListener("mouseup", handleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", handleMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragging]);

    return (
        <div
            ref={barRef}
            style={{
                position: "absolute",
                right: right,
                top: top,
                bottom: bottom,
                height: height,
                width: width,
                cursor: "nwse-resize",
                // borderRight: "3px solid white",
                // borderBottom: "3px solid white"
            }}
            onMouseDown={handleMouseDown}>
            <FontAwesomeIcon
                icon={faChevronDown}
                style={{ position: "absolute", transform: "rotate(-45deg)" }}
            />
        </div>
    );
};

export default DraggableBar;
