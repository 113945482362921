import Measure from "react-measure";
import React from "react";

export function WithAspectRatio({ ratio, vPadding, children }) {
    return (
        <Measure bounds>
            {({ measureRef, contentRect }) => {
                let height = 0;
                let width = 0;
                let left = 0;
                let top = 0;

                if (contentRect.bounds.width && contentRect.bounds.height) {
                    width = contentRect.bounds.width;
                    height = contentRect.bounds.height;
                    const minHeight = width / ratio;
                    const maxHeight = minHeight + (vPadding || 0);

                    if (height > maxHeight) {
                        height = maxHeight;
                    } else if (height < minHeight) {
                        width = height * ratio;
                    }
                } else if (contentRect.bounds.width) {
                    width = contentRect.bounds.width;
                    height = width / ratio;
                } else {
                    height = 0;
                    width = 0;
                }

                left = contentRect.bounds.width ? Math.max(0, (contentRect.bounds.width - width) / 2) : null;
                top = contentRect.bounds.height ? Math.max(0, (contentRect.bounds.height - height - (vPadding || 0)) / 2) : null;

                return (
                    <div
                        ref={measureRef}
                        className="WithAspectRatioOuter"
                        key={top}>
                        <div style={{ paddingBottom: vPadding || 0, width: "100%", paddingTop: `${100 / ratio}%` }} />
                        <div
                            className="WithAspectRatioInner"
                            style={{ width: width, height: height, left: left, top: top }}>
                            {children}
                        </div>
                    </div>
                );
            }}
        </Measure>
    );
}
