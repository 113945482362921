import React, { PureComponent, useState } from "react";
import Tippy from "@tippyjs/react";
import { Tooltip } from "react-leaflet";

export class LazyTippy extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }

    lazyPlugin = {
        fn: () => ({
            onMount: () => this.setState({ mounted: true }),
            onHidden: () => this.setState({ mounted: false }),
        }),
    };

    render() {
        const computedProps = { ...this.props };
        computedProps.plugins = [this.lazyPlugin, ...(this.props.plugins || [])];

        if (this.props.render) {
            computedProps.render = (...args) => (this.state.mounted ? this.props.render(...args) : "");
        } else {
            computedProps.content = this.state.mounted ? this.props.content : "";
        }

        return <Tippy {...computedProps} />;
    }
}

export function LazyMapTooltip(props) {
    const [mounted, setMounted] = useState(false);

    const computedProps = { ...props };

    computedProps.onOpen = () => setMounted(true);
    computedProps.onClose = () => setMounted(false);

    computedProps.content = mounted ? props.content : "";

    return <Tooltip {...computedProps} />;
}
