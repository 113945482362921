import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../ssoMsConfig";
import { Button } from "antd";
import microsoftIcon from "../../images/microsoft-icon.png";

function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
const MicrosoftSSOButton = () => {
    const { instance } = useMsal();

    return (
        <Button
            variant="secondary"
            className="LoginConfirm microsoft"
            onClick={() => handleLogin(instance)}>
            <img
                alt="Microsoft"
                src={microsoftIcon}
                crossOrigin={"anonymous"}
            />
            Sign in using Microsoft
        </Button>
    );
};

export default MicrosoftSSOButton;
