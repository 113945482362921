import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { calculateRouteCoordinatesForLocation, coordinateToLatLon, coordinateToOSGB, calculateBearingText, convertSpeed } from "../util/Geometry";
import { findMostRecentMetadata } from "../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyTippy } from "../util/LazyTooltips";
import { sticky } from "tippy.js";
import { faClipboard, faCog } from "@fortawesome/free-solid-svg-icons";
import MetadataFeed from "../route/MetadataFeed";
import { convertToTimezone } from "../util/TimezoneUtils";

const datumOffsetsSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);
    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    return workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
};
const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
const altitudeSelector = (state) => {
    return _.get(state.routeMetadata, ["ALTITUDE", state.playlist.data.routeID], []);
};
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const userConfigSelector = (state) => state.userDetails.userConfig;
const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], []);
const tachoDataSelector = (state) => _.get(state.routeMetadata, ["TACHO_DATA", state.playlist.data.routeID], []);
const routeSystemIDSelector = (state) => state.playlist.data.system_id;

function Copier({ data }) {
    return (
        <LazyTippy
            placement="right"
            sticky={true}
            plugins={[sticky]}
            arrow={true}
            theme="aivrlight"
            interactive="true"
            content="Copy Lat / Lon data to clipboard">
            <div
                className="CopyToClipboard"
                onClick={() => {
                    console.log("debug copying coords", data);
                    navigator.clipboard.writeText(data);
                }}>
                <FontAwesomeIcon icon={faClipboard} />
            </div>
        </LazyTippy>
    );
}

export const PositionalInfo = () => {
    let altMetadata = useSelector(altitudeSelector);
    let speedMetadata = useSelector(speedAndBearingSelector);
    let tachoMetadata = useSelector(tachoDataSelector);
    const dashboardData = useSelector(dashboardDataSelector);
    const userConfig = useSelector(userConfigSelector);

    const currentTimestamp = useMemo(() => {
        return dashboardData.position?.timestamp;
    }, [dashboardData.position]);

    let altitude = "Unknown";
    let speed = "Unknown";
    let speedSource = "";
    let bearing = "Unknown";
    let timestampSeconds = currentTimestamp / 1000;

    if (timestampSeconds) {
        const altitudeInfo = findMostRecentMetadata(altMetadata, timestampSeconds);
        if (altitudeInfo) {
            altitude = Math.round(altitudeInfo.data) + "m";
        }
        let baseSpeed = null;
        const tachoInfo = findMostRecentMetadata(tachoMetadata, timestampSeconds);
        if (!_.isNil(tachoInfo?.data.speed) && tachoInfo.timestamp > timestampSeconds - 5) {
            baseSpeed = tachoInfo.data.speed;
            speedSource = " (Tachometer)";
        }
        const speedInfo = findMostRecentMetadata(speedMetadata, timestampSeconds);
        if (baseSpeed == null && !_.isNil(speedInfo?.data.speed) && speedInfo.timestamp > timestampSeconds - 5) {
            baseSpeed = speedInfo.data.speed;
            speedSource = " (GPS)";
        }
        if (baseSpeed !== null) {
            const unitDict = {
                metres: "m/s",
                miles: "MPH",
                kilometers: "KPH",
            };
            let unit = userConfig.speed_units || "miles";
            speed = Math.round(convertSpeed(baseSpeed, unit)) + " " + unitDict[unit] + speedSource;
        }
        if (!_.isNil(speedInfo?.data.bearing)) {
            bearing = calculateBearingText(speedInfo.data.bearing);
        }
    }

    return (
        <MetadataFeed
            items={["ALTITUDE", "SPEED_AND_BEARING", "TACHO_DATA"]}
            timestamp={timestampSeconds || null}>
            <div className="RouteInfo-Row">
                <div className="RouteInfo-Altitude">
                    <span className="RouteInfo-Label">Altitude</span>
                    <div className="RouteInfo-Text-Container">{altitude}</div>
                </div>
                <div className="RouteInfo-Altitude">
                    <span className="RouteInfo-Label">Speed</span>
                    <div className="RouteInfo-Text-Container">{speed}</div>
                </div>
                <div className="RouteInfo-Altitude">
                    <span className="RouteInfo-Label">Bearing</span>
                    <div className="RouteInfo-Text-Container">{bearing}</div>
                </div>
            </div>
        </MetadataFeed>
    );
};

export const VideoTimeDisplay = () => {
    const userConfig = useSelector(userConfigSelector);

    const dashboardData = useSelector(dashboardDataSelector);

    const humanTime = useMemo(() => {
        const timestamp = dashboardData.position.timestamp;
        const dpDate = new Date(timestamp);
        return convertToTimezone(dpDate, userConfig.convert_to_utc);
    }, [dashboardData.position.timestamp, userConfig.convert_to_utc]);

    return (
        <div className="RouteInfo-Inline">
            <div className="RouteInfo-Altitude">
                <div className="RouteInfo-Text-Container nowrap">
                    <span className="secondary">{dashboardData.position.timestamp ? humanTime : "No Route Selected"}</span>
                </div>
            </div>
        </div>
    );
};

const LocationWidget = () => {
    const routeLocationData = useSelector(routeLocationSelector);
    const userConfig = useSelector(userConfigSelector);
    const datum_offsets = useSelector(datumOffsetsSelector);
    const dashboardData = useSelector(dashboardDataSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const currentCoords = useMemo(() => {
        return dashboardData.position.coords;
    }, [dashboardData.position.coords]);

    const currentTimestamp = useMemo(() => {
        return dashboardData.position?.timestamp;
    }, [dashboardData.position]);

    const routeCoordinateForSystem = useMemo(() => {
        if (!currentTimestamp) {
            return null;
        }
        const location = calculateRouteCoordinatesForLocation(currentTimestamp, routeLocationData, routeSystemID);
        return location;
    }, [currentTimestamp, routeLocationData, routeSystemID]);

    const locationData = useMemo(() => {
        if (routeCoordinateForSystem) {
            return _.map(routeCoordinateForSystem.fields(userConfig.elr_units), (field_name) => (
                <span
                    key={field_name}
                    className="RouteInfoELR__Item">
                    <b>{field_name}:</b>
                    {routeCoordinateForSystem.field_value(field_name, datum_offsets)}
                </span>
            ));
        }
        return null;
    }, [routeCoordinateForSystem, userConfig.elr_units, datum_offsets]);

    const latLong = useMemo(() => {
        return coordinateToLatLon(currentCoords);
    }, [currentCoords]);

    const osgb = useMemo(() => {
        return coordinateToOSGB(currentCoords);
    }, [currentCoords]);

    const latLonDisplay = useMemo(() => {
        if (latLong) {
            return (
                <div className="RouteInfoELR__Container column">
                    <p style={{ marginRight: 7.5, marginBottom: 0 }}>{latLong}</p>
                    <Copier data={latLong} />
                </div>
            );
        } else {
            return null;
        }
    }, [latLong]);

    const osgbDisplay = useMemo(() => {
        if (osgb) {
            return (
                <div className="RouteInfoELR__Container column">
                    <p style={{ marginRight: 7.5, marginBottom: 0 }}>{osgb}</p>
                    <Copier data={osgb} />
                </div>
            );
        }
    }, [osgb]);

    return (
        <div className="LocationDataContainer">
            <div className="elrContainer">{locationData}</div>
            <div className="positionWrapper">
                <div className="positionContainer">
                    <span className="RouteInfo-Label">LAT, LON</span>
                    {latLonDisplay}
                </div>
                <div className="positionContainer">
                    <span className="RouteInfo-Label">EASTING, NORTHING</span>
                    {osgbDisplay}
                </div>
            </div>
            <PositionalInfo />
            <div className="positionWrapper">
                <span className="RouteInfo-Label">Video Time</span>
                <VideoTimeDisplay />
            </div>

            <FontAwesomeIcon
                icon={faCog}
                className="UnitSettingsIcon"
            />
        </div>
    );
};

export default LocationWidget;
