import React, { useEffect, useState, useMemo, useCallback } from "react";
import { getRoutePatrols, getReviewerPatrolPlans, clearPatrolState, getPatrolUsers, changeDashboard } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFolderOpen } from "@fortawesome/pro-light-svg-icons";
import PatrolsNotice from "./PatrolsNotice";
import PatrolList from "./PatrolList";
import { Input, Tooltip, Switch, Select, Button } from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import DayPicker from "react-day-picker";
import logo from "../../images/new-branding-2.0/aivr-hybrid.svg";
import _ from "lodash";
import PatrolPlanDrawer from "./PatrolPlanDrawer";

const { Option } = Select;

const patrolPlansSelector = (state) => state.schemaInterface.patrolPlans;
const patrolsSelector = (state) => state.schemaInterface.patrols; // not actually patrol plans - patrols
const reviewerPatrolPlansSelector = (state) => state.schemaInterface.reviewerPatrols;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;
const userDetailsSelector = (state) => state.userDetails;
const currentDashboardSelector = (state) => _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);
const patrolUsersSelector = (state) => state.schemaInterface.patrolUsers.users;

const PatrolNavigation = () => {
    const patrolPlans = useSelector(patrolPlansSelector);
    const patrols = useSelector(patrolsSelector);
    const reviewerPatrolPlans = useSelector(reviewerPatrolPlansSelector);
    const isSupervisor = useSelector(isSupervisorSelector);
    const userDetails = useSelector(userDetailsSelector);
    const currentDashboard = useSelector(currentDashboardSelector);
    const patrolUsers = useSelector(patrolUsersSelector);

    const history = useHistory();
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState();
    const [dateRange, setDateRange] = useState({ from: undefined, to: undefined });
    const [onlyIncomplete, setOnlyIncomplete] = useState(false);
    const [showUnusedPatrols, setShowUnusedPatrols] = useState(true);
    const [archivedPatrolsVisible, setArchivedPatrolsVisible] = useState();
    const [statusFilter, setStatusFilter] = useState("all_current");
    const [sortType, setSortType] = useState(0);
    const [userSelected, setUserSelected] = useState(0);
    const [planDrawerOpen, setPlanDrawerOpen] = useState(false);

    const { from, to } = dateRange;
    const modifiers = { start: from, end: to };

    useEffect(() => {
        dispatch(clearPatrolState());
    }, [dispatch]);

    useEffect(() => {
        if (userDetails.email) {
            if (userDetails.userConfig.is_patrol_supervisor) {
                if (!reviewerPatrolPlans.length) {
                    console.log("debug sending get patrols request here");
                    dispatch(getPatrolUsers());
                    dispatch(getReviewerPatrolPlans());
                }
            } else {
                if (!patrols.length) {
                    console.log("debug getting non supervisor patrols here");
                    dispatch(getRoutePatrols());
                }
            }
        }
    }, [dispatch, patrols.length, reviewerPatrolPlans.length, userDetails.email, userDetails.userConfig.is_patrol_supervisor]);

    const onBackClick = () => {
        if (currentDashboard?.workspace_layout === "route_patrols") {
            dispatch(changeDashboard());
        } else {
            history.push("/");
        }
    };

    const updateSearchField = (value) => {
        setSearchQuery(value.toLowerCase());
    };

    const sameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    const handleRangeReset = () => {
        setDateRange({ from: undefined, to: undefined });
    };

    const handleDayClick = (day) => {
        const range = DayPicker.DateUtils.addDayToRange(day, dateRange);
        if (range.to === undefined || range.to === null) {
            range.to = day;
            range.from = day;
        }

        setDateRange({ from: range.from, to: range.to });
    };

    const handleSwitchChange = (value) => {
        setOnlyIncomplete(value);
    };

    const handleSortChange = (value) => {
        setSortType(value);
    };

    const handleUserSelectedChange = (user) => {
        setUserSelected(user);
    };

    const toggleArchivedPatrols = () => {
        setArchivedPatrolsVisible(!archivedPatrolsVisible);
    };

    const assignUserOptions = useMemo(() => {
        if (patrolUsers && patrolUsers.length) {
            const sorted = patrolUsers
                .sort((a, b) => {
                    let compareA = a.name || a.email;
                    let compareB = b.name || b.email;

                    if (compareA && compareB) {
                        return compareA.localeCompare(compareB);
                    } else {
                        return a;
                    }
                })
                .sort((x, y) => {
                    return x.email === userDetails.email ? -1 : y.email === userDetails.email ? 1 : 0;
                });

            return sorted.map((user) => {
                return (
                    <Option
                        key={user.id}
                        value={user.id}>
                        {user.name ? user.name : user.email}
                    </Option>
                );
            });
        }
    }, [userDetails, patrolUsers]);

    return (
        <div className="App">
            <div className="NavBarContainer">
                <img
                    className="NavBarLogo"
                    src={logo}
                    alt="AIVR Logo"
                    crossOrigin={"anonymous"}
                />
                {/* below required for a scroller to take up space and push bottom to the bottom of the container */}
                <div className="DashboardScroller"></div>

                <div className="NabBarBottom">
                    <div className="SidebarButtonWrap">
                        <Tippy
                            arrow={true}
                            placement="right"
                            content="Patrol Diagrams"
                            theme="aivr"
                            zIndex="600"
                            delay="100">
                            <div
                                className="SidebarButtonContainer"
                                onClick={() => setPlanDrawerOpen(true)}>
                                <FontAwesomeIcon
                                    icon={faFolderOpen}
                                    style={{ fontSize: 32, color: "#e8dfff" }}
                                />
                            </div>
                        </Tippy>

                        <Tippy
                            arrow={true}
                            placement="right"
                            content="Go back"
                            theme="aivr"
                            zIndex="600"
                            delay="100">
                            <div
                                className="SidebarButtonContainer"
                                onClick={onBackClick}>
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    style={{ fontSize: 32, color: "#e8dfff" }}
                                />
                            </div>
                        </Tippy>
                    </div>
                </div>
            </div>

            <div className="PatrolNavigation">
                <PatrolPlanDrawer
                    open={planDrawerOpen}
                    onClose={() => setPlanDrawerOpen(false)}
                />

                <div className="PatrolNavigation__Notice">{PatrolsNotice()}</div>

                <div className="PatrolNavigation__Filters">
                    <div className="PatrolNavigation__Filters__Row">
                        <div className="PatrolNavigation__Filters__Row__Inner">
                            <Select
                                value={statusFilter}
                                style={{ width: 300 }}
                                placeholder="Filter by status"
                                onChange={(value) => setStatusFilter(value)}>
                                <Option
                                    key={1}
                                    value="all">
                                    All
                                </Option>
                                <Option
                                    key={0}
                                    value="all_current">
                                    All Unreviewed
                                </Option>
                                {isSupervisor && (
                                    <Option
                                        key={2}
                                        value="unassigned">
                                        Unassigned
                                    </Option>
                                )}
                                <Option
                                    key={3}
                                    value={0}>
                                    {isSupervisor ? "Assigned - Not Started" : "Not Started"}
                                </Option>
                                <Option
                                    key={4}
                                    value={1}>
                                    In Progress
                                </Option>
                                <Option
                                    key={5}
                                    value={2}>
                                    Awaiting Review
                                </Option>
                                <Option
                                    key={6}
                                    value={3}>
                                    Reviewed
                                </Option>
                            </Select>
                            {isSupervisor && (
                                <Select
                                    showSearch
                                    defaultValue={userSelected}
                                    placeholder="Filter by user"
                                    optionFilterProp="children"
                                    onChange={(val) => handleUserSelectedChange(val)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    <Option
                                        key={0}
                                        value={0}>
                                        All Users
                                    </Option>
                                    {assignUserOptions}
                                </Select>
                            )}
                            <Select
                                placeholder="Sort By"
                                onChange={handleSortChange}
                                value={sortType}>
                                <Option
                                    key={0}
                                    value={0}>
                                    Scheduled Date of Patrol
                                </Option>
                                <Option
                                    key={1}
                                    value={1}>
                                    Status
                                </Option>
                            </Select>
                            <Tooltip title="Filter by date">
                                <div>
                                    <Tippy
                                        placement="top"
                                        sticky={true}
                                        plugins={[sticky]}
                                        arrow={false}
                                        delay={[0, 20000]}
                                        theme="aivr"
                                        trigger={"click"}
                                        interactive="true"
                                        content={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <DayPicker
                                                    className="Selectable"
                                                    selectedDays={[from, { from, to }]}
                                                    modifiers={modifiers}
                                                    onDayClick={handleDayClick}
                                                />
                                                <span
                                                    onClick={handleRangeReset}
                                                    className="Filter-Reset">
                                                    Reset
                                                </span>
                                            </div>
                                        }>
                                        <div
                                            className="calendarFilter"
                                            style={{ background: !dateRange.to ? "#414664" : "#2D81CF" }}>
                                            <CalendarOutlined className="icon" />
                                        </div>
                                    </Tippy>
                                </div>
                            </Tooltip>
                            <Input
                                className="SearchInput"
                                value={searchQuery}
                                onChange={(e) => updateSearchField(e.target.value)}
                                placeholder="Search for a patrol"
                                style={{ height: 32 }}
                                spellCheck={false}
                                prefix={<SearchOutlined />}
                                allowClear
                            />
                        </div>
                    </div>
                    <div className="PatrolNavigation__Filters__Row">
                        {/* {isSupervisor && <div className="PatrolNavigation__Switch">
                        <span>Show unused patrols</span>
                        <Switch onChange={(value) => setShowUnusedPatrols(value) }/>
                    </div>} */}

                        <div className="PatrolNavigation__Switch">
                            <span>Only show incomplete</span>
                            <Switch onChange={handleSwitchChange} />
                        </div>
                        {isSupervisor && (
                            <div className="PatrolNavigation__Switch">
                                <span>Show Archived Patrols</span>
                                <Switch onChange={toggleArchivedPatrols} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="PatrolList__Container">
                    <PatrolList
                        onlyIncomplete={onlyIncomplete}
                        showUnusedPatrols={showUnusedPatrols}
                        searchQuery={searchQuery}
                        dateRange={dateRange}
                        sameDay={sameDay}
                        archivedPatrolsVisible={archivedPatrolsVisible}
                        statusFilter={statusFilter}
                        sortType={sortType}
                        userSelected={userSelected}
                        isSupervisor={isSupervisor}
                        openDiagramDrawer={setPlanDrawerOpen}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatrolNavigation;
