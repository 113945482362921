import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { calculatePatrolDeviceConfig, chainageFromImage, findNearestRailImage, ONE_METRE_IN_CHAINS } from "../util/Geometry";
import { DynamicLayer, InfoLayer } from "./SchemaScrollerLayers";
import L from "leaflet";
import { Checkbox, Select } from "antd";
import _ from "lodash";
import { getPatrolHistory, setPatrolHistoryTimestamp } from "../../redux/actions/index";
import { useDispatch } from "react-redux";
import { convertToTimezone } from "../util/TimezoneUtils";

const schemaLocationSelector = (state) => state.schemaInterface.location;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const currentImageAdjustmentsSelector = (state) => state.railInspection.imageAdjustments;
const deviceIDSelector = (state) => state.schemaInterface.plan.device_group_id;
const patrolHistorySessionsSelector = (state) => state.schemaInterface.history.images;
const userConfigSelector = (state) => state.userDetails.userConfig;
const lastHistoryTimestampSelector = (state) => state.schemaInterface.history.lastViewingTimestamp;
const sessionSelector = (state) => state.schemaInterface.sessions;
const selectedSessionSelector = (state) => state.schemaInterface.selectedSession;
const selectedRailImageSelector = (state) => state.railInspection.selectedRailInspectionImage;

const imageHeight = 62.5;
const imageWidth = 127.5;

const PatrolHistory = () => {
    const leafletMap = useRef();
    const map = useRef();
    const layersDictRef = useRef({});
    const infoTilesRef = useRef();

    const dispatch = useDispatch();

    const store = useStore();

    const sessions = useSelector(sessionSelector);
    const currentLocation = useSelector(schemaLocationSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const currentImageAdjustments = useSelector(currentImageAdjustmentsSelector);
    const deviceID = useSelector(deviceIDSelector);
    const patrolHistorySessions = useSelector(patrolHistorySessionsSelector);
    const userConfig = useSelector(userConfigSelector);
    const lastHistoryTimestamp = useSelector(lastHistoryTimestampSelector);
    const selectedSession = useSelector(selectedSessionSelector);
    const selectedRailImage = useSelector(selectedRailImageSelector);

    const [currentSession, setCurrentSession] = useState(null);
    const [flipped, setFlipped] = useState(false);

    const getHistorySessions = useCallback(
        _.debounce(
            (currentLocation) => {
                const { trackID, elr, chain } = currentLocation;

                const startChainage = Math.max(0, chain - 1);
                const endChainage = chain + 1;

                const startMile = Math.floor(startChainage / 80);
                const endMile = Math.floor(endChainage / 80);

                const startYard = (startChainage % 80) * 22;
                const endYard = (endChainage % 80) * 22;

                dispatch(getPatrolHistory(trackID, startMile, endMile, startYard, endYard, elr, deviceID));
            },
            1000,
            { trailing: true },
        ),
        [dispatch, deviceID],
    );

    const setSession = (e) => {
        setCurrentSession(e);

        const selectedSession = patrolHistorySessions[e];
        if (selectedSession.images && selectedSession.images.length) {
            const startTimestamp = selectedSession.images[0].timestamp;
            dispatch(setPatrolHistoryTimestamp(startTimestamp));
        }
    };

    const sessionImages = useMemo(() => {
        if (!_.isNil(patrolHistorySessions) && patrolHistorySessions && patrolHistorySessions.length) {
            const sessionImages = _.get(patrolHistorySessions, [currentSession, "images"], []);
            return sessionImages;
        } else {
            return [];
        }
    }, [currentSession, patrolHistorySessions]);

    const railImages = useMemo(() => {
        let retVal;
        if (sessions && sessions[selectedSession]) {
            retVal = sessions[selectedSession];
        } else {
            retVal = [];
        }
        return retVal;
    }, [selectedSession, sessions]);

    const railImageConfig = useMemo(() => {
        return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [railImageConfigs, railImages]);

    const sessionIsBackwards = useMemo(() => {
        if (!_.isNil(patrolHistorySessions) && patrolHistorySessions && patrolHistorySessions.length) {
            return _.get(patrolHistorySessions, [currentSession, "direction"], "Forward") === "Backward";
        } else {
            return false;
        }
    }, [currentSession, patrolHistorySessions]);

    const shouldFlip = useMemo(() => {
        if (!selectedRailImage) {
            return false;
        }

        const currentIdx = selectedRailImage.index;

        const beforeIdx = Math.max(0, currentIdx - 1);
        const afterIdx = Math.min(railImages.length - 1, currentIdx + 1);
        const beforeImage = railImages[beforeIdx];
        const afterImage = railImages[afterIdx];

        let mainImagesIncreasing = true;

        if (beforeImage && afterImage && beforeImage.mwv.elr === afterImage.mwv.elr && beforeImage.mwv.trid === afterImage.mwv.trid) {
            const beforeChain = parseInt(beforeImage.mwv.mile) * 80 + parseFloat(beforeImage.mwv.yard) / 22;
            const afterChain = parseInt(afterImage.mwv.mile) * 80 + parseFloat(afterImage.mwv.yard) / 22;

            mainImagesIncreasing = beforeChain < afterChain;
        }

        let historyImagesIncreasing = true;
        const firstHistoryImage = sessionImages[0];
        const lastHistoryImage = sessionImages[sessionImages.length - 1];

        if (
            firstHistoryImage &&
            lastHistoryImage &&
            firstHistoryImage.mwv.elr === lastHistoryImage.mwv.elr &&
            firstHistoryImage.mwv.trid === lastHistoryImage.mwv.trid
        ) {
            const beforeChain = parseInt(firstHistoryImage.mwv.mile) * 80 + parseFloat(firstHistoryImage.mwv.yard) / 22;
            const afterChain = parseInt(lastHistoryImage.mwv.mile) * 80 + parseFloat(lastHistoryImage.mwv.yard) / 22;
            historyImagesIncreasing = beforeChain < afterChain;
        }

        return mainImagesIncreasing !== historyImagesIncreasing;
    }, [selectedRailImage, railImages, sessionImages]);

    useEffect(() => {
        setFlipped(!!shouldFlip);
    }, [shouldFlip]);

    useEffect(() => {
        getHistorySessions(currentLocation);
    }, [getHistorySessions, currentLocation]);

    const largestOffset = useMemo(() => {
        let largestOffset = 0;
        Object.values(railImageConfig.inspection_images).forEach(function (item) {
            let offsetY;
            if (sessionIsBackwards) {
                offsetY = item.offset_y_backward || 0;
            } else {
                offsetY = item.offset_y_forward || 0;
            }

            if (!largestOffset || Math.abs(offsetY) > Math.abs(largestOffset)) {
                largestOffset = offsetY;
            }
        });

        return Math.round(largestOffset);
    }, [railImageConfig.inspection_images, sessionIsBackwards]);

    const bounds = useMemo(() => {
        const factor = flipped ? -1 : 1;

        return [
            [sessionImages.length * factor * imageHeight, -imageWidth],
            [0, imageWidth * 2],
        ];
    }, [sessionImages, flipped]);

    const panToIndex = useCallback(
        (newRailImageIndex, zoom = false) => {
            if (leafletMap.current) {
                if (newRailImageIndex < 0 || !newRailImageIndex) {
                    newRailImageIndex = 0;
                }
                const flippedFactor = flipped ? -1 : 1;
                if (!zoom) {
                    leafletMap.current.setView(
                        [(newRailImageIndex - 0.5) * imageHeight * flippedFactor, leafletMap.current.getCenter().lng],
                        leafletMap.current.getZoom(),
                        {
                            animate: false,
                            duration: 1,
                            easeLinearity: 0.5,
                        },
                    );
                }
            }
        },
        [flipped],
    );

    useEffect(() => {
        if (map.current) {
            leafletMap.current = new L.Map(map.current, {
                center: [0, imageWidth * 0.5],
                zoom: 1,
                crs: L.CRS.Simple,
                maxBounds: bounds,
                attributionControl: false,
            });

            let xOffset = 0;

            if (railImageConfig && railImageConfig.inspection_images && railImageConfig.inspection_images.length) {
                const closestIndex = findNearestRailImage(currentLocation.elr, currentLocation.chain, currentLocation.trackID, sessionImages, true);
                panToIndex(closestIndex);

                let totalWidth = 0;
                Object.keys(railImageConfig.inspection_images).forEach(function (key) {
                    const item = railImageConfig.inspection_images[key];
                    totalWidth += item.display_width;
                });

                Object.keys(railImageConfig.inspection_images).forEach(function (key) {
                    const item = railImageConfig.inspection_images[key];
                    const source = item.source;
                    const name = item.name;
                    const displayWidth = item.display_width;
                    const srcWidth = item.src_width;
                    const imgWidth = item.img_width;
                    const srcX = item.src_x;
                    let offsetY;

                    if (sessionIsBackwards) {
                        offsetY = item.offset_y_backward || 0;
                    } else {
                        offsetY = item.offset_y_forward || 0;
                    }
                    const newLayer = new DynamicLayer({
                        minNativeZoom: 4,
                        maxNativeZoom: 4,
                        tileSize: L.point(displayWidth, 1000),
                        maxZoom: 6,
                        noWrap: true,
                    });

                    let opacity = 1;
                    const visible = _.get(currentImageAdjustments, ["Primary", name, "visibility"], true);
                    if (!visible) {
                        opacity = 0.25;
                    }

                    let defaultBrightness = item.default_brightness || 0;
                    const brightness = _.get(currentImageAdjustments, ["Primary", name, "brightness"], defaultBrightness);
                    const contrast = _.get(currentImageAdjustments, ["Primary", name, "contrast"], 0);

                    newLayer.setOpacity(opacity);
                    newLayer.setBrightness(brightness);
                    newLayer.setContrast(contrast);
                    newLayer.setDeviceName(source);
                    newLayer.setDisplayWidth(displayWidth);
                    newLayer.setSrcWidth(srcWidth);
                    newLayer.setImgWidth(imgWidth);
                    newLayer.setSrcX(srcX);
                    newLayer.setXOffset(xOffset);
                    newLayer.setConstantOffset(offsetY);
                    newLayer.setReverse(sessionIsBackwards);
                    newLayer.setFlipped(flipped);

                    if (!sessionIsBackwards) {
                        newLayer.setXOffset(xOffset);
                        xOffset += displayWidth;
                    } else {
                        totalWidth -= displayWidth;
                        newLayer.setXOffset(totalWidth);
                    }
                    newLayer.setup(sessionImages);

                    layersDictRef.current[name] = newLayer;
                    newLayer.addTo(leafletMap.current);
                });
            }

            const infoTiles = new InfoLayer({
                tileSize: L.point(imageWidth * 5, 1000),
                maxZoom: 6,
                minNativeZoom: 4,
                maxNativeZoom: 4,
            });
            infoTiles.on("tileunload", (event) => infoTiles.destroyTile(event.tile));

            infoTiles.addTo(leafletMap.current);
            infoTiles.setup(sessionImages, store);
            // infoTilesRef.current.setReverse(sessionIsBackwards);
            infoTiles.setFlipped(flipped);
            infoTiles.redraw();

            infoTilesRef.current = infoTiles;
        }

        return () => {
            infoTilesRef.current.remove();
            infoTilesRef.current = null;
            leafletMap.current.remove();
            leafletMap.current = null;

            Object.keys(layersDictRef.current).forEach(function (key) {
                layersDictRef.current[key].remove();
            });

            layersDictRef.current = {};
        };
    }, [railImageConfig, sessionImages, store, largestOffset, sessionIsBackwards, flipped, bounds, panToIndex, currentLocation]);

    useEffect(() => {
        if (layersDictRef.current) {
            Object.keys(layersDictRef.current).forEach(function (key) {
                layersDictRef.current[key].setReverse(sessionIsBackwards);
                layersDictRef.current[key].redraw();
            });
        }

        if (infoTilesRef.current) {
            // infoTilesRef.current.setReverse(sessionIsBackwards);
            infoTilesRef.current.redraw();
        }
    }, [sessionIsBackwards]);

    // useEffect(() => {
    //     setFlipped(false);
    // }, [currentSession]);

    const sessionOptions = useMemo(() => {
        let sessionOptions = [];

        if (patrolHistorySessions && patrolHistorySessions.length) {
            patrolHistorySessions.forEach((session, idx) => {
                const nearestIndex = findNearestRailImage(currentLocation.elr, currentLocation.chain, currentLocation.trackID, session.images, false);
                const nearestImage = session.images[nearestIndex];
                if (nearestImage && nearestImage.mwv && Math.abs(chainageFromImage(nearestImage) - currentLocation.chain < ONE_METRE_IN_CHAINS)) {
                    const startTimestamp = session.images[0].timestamp;
                    const date = new Date(startTimestamp);
                    const niceTime = convertToTimezone(date, userConfig.convert_to_utc);

                    sessionOptions.push(
                        <Select.Option
                            timestamp={startTimestamp}
                            value={idx}>
                            {niceTime}
                        </Select.Option>,
                    );
                }
            });
        }
        return sessionOptions;
    }, [currentLocation, patrolHistorySessions, userConfig.convert_to_utc]);

    useEffect(() => {
        if (sessionOptions && sessionOptions.length) {
            const allTimestamps = sessionOptions.map((option) => {
                return option.props.timestamp;
            });

            let indexToSelect = 0;

            if (lastHistoryTimestamp) {
                const closest = allTimestamps.reduce(function (prev, curr) {
                    return Math.abs(curr - lastHistoryTimestamp) < Math.abs(prev - lastHistoryTimestamp) ? curr : prev;
                });

                if (Math.abs(closest - lastHistoryTimestamp) < 24 * 60 * 60) {
                    let tsIndex = allTimestamps.indexOf(closest);
                    indexToSelect = sessionOptions[tsIndex].props.value;
                }
            }

            setCurrentSession(indexToSelect);
        } else {
            setCurrentSession(null);
        }
    }, [sessionOptions, lastHistoryTimestamp]);

    const onFlippedChange = (e) => {
        setFlipped(e.target.checked);
    };

    return (
        <div className="PatrolHistory">
            <div className="PatrolHistory__Controls">
                <Select
                    value={currentSession}
                    onChange={setSession}
                    className="historyDropdown">
                    {sessionOptions}
                </Select>
                <Checkbox
                    onChange={onFlippedChange}
                    checked={flipped}>
                    Flip Direction
                </Checkbox>
            </div>

            <div
                ref={map}
                style={{
                    flex: "1",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "100%",
                }}>
                {(!sessionImages.length || !sessionOptions.length) && (
                    <div className="PatrolHistory__Notice">
                        <span>No Images, Try Changing Selected Run.</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PatrolHistory;
