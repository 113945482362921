import React, { useState, useEffect } from "react";
import AppNavbar from "../../AppNavbar";
import { useDispatch, useSelector } from "react-redux";
import { logout, routeSelected, setCurrentTab } from "../../../redux/actions";
import VideoOrImageComponent from "../../display/VideoOrImageComponent";
import MapComponent from "../../map/MapComponent";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import CustomResizeHandle from "./CustomResizeHandle";
import RouteInfoTabs from "../../RouteInfoTabs";
import SessionsComponent from "../../SessionsComponent";
import FavouritesTab from "components/rootTabs/FavouritesTab";
import IssuesTab from "components/Issues/IssuesTab";
import GlobalContentTab from "components/GlobalContentTab";
import ShortcutsTab from "components/ShortcutsTab";
import RouteInformation from "components/route/RouteInformation";
import MarkerInformation from "components/MarkerInformation";
import AssetsTab from "components/assets/AssetsTab";
import MediaTab from "components/mainTabs/media/MediaTab";
import RouteHistoryList from "components/display/video/RouteHistoryList";

import { Tabs, Button } from "antd";
import _ from "lodash";

const { TabPane } = Tabs;

const V2Layout = () => {
    const dispatch = useDispatch();

    const selectedSession = useSelector((state) => state.playlist.data);
    const noPlaylistData = useSelector((state) => _.isEmpty(state.playlist.data));
    const shareLink = useSelector((state) => state.shareLink);
    const selectedMarker = useSelector((state) => state.markers.selectedMarker);
    const currentTab = useSelector((state) => state.currentTab);
    const currentDashboard = useSelector((state) => {
        return _.find(state.dashboards, (d) => d.access_token === state.access_token);
    });

    const mediaEnabled = !!_.get(currentDashboard, ["config", "media_upload_enabled"], false);
    const assetsEnabled = !!_.get(currentDashboard, ["config", "asset_groups", "length"], 0);
    const issuesTab = !!_.get(currentDashboard, ["config", "issue_groups", "length"], 0);
    const contentFeedTab = !!_.get(currentDashboard, ["config", "markup_feed_enabled"], false);
    const shortcutsTabEnabled = !!_.get(currentDashboard, ["config", "shortcuts_enabled"], false);

    const [windowWidth, setWindowWidth] = useState(800);

    const triggerLogout = () => {
        dispatch(logout());
    };

    const onResize = (event, { element, size, handle }) => {
        setWindowWidth(size.width);
    };

    useEffect(() => {
        if (_.get(selectedSession, "routeID", false)) {
            dispatch(setCurrentTab("info"));
        }
    }, [selectedSession, dispatch]);

    console.log("selected session:", selectedSession);

    return (
        <div className="App">
            <AppNavbar logout={triggerLogout} />
            <div className="v2-main">
                <div className="v2-main__inner left">
                    <div className="Container Media">
                        <VideoOrImageComponent />
                    </div>
                    <MapComponent />
                </div>
                <div className="v2-main__inner right">
                    {/* <div className="route-tabs-container" id="intro-tour-info-window">
                        <RouteInfoTabs />
                    </div> */}
                    <div className="Container Sessions">
                        <div
                            className="route-tabs-container"
                            id="intro-tour-info-window">
                            <Tabs
                                animated={{ inkBar: false, tabPane: true }}
                                activeKey={currentTab}
                                onChange={(tab) => dispatch(setCurrentTab(tab))}
                                tabBarStyle={{ color: "white" }}
                                destroyInactiveTabPane={false}>
                                <TabPane
                                    tab="Sessions"
                                    key="sessions"
                                    className="route-tabs-pane">
                                    <SessionsComponent />
                                </TabPane>

                                <TabPane
                                    tab="Info"
                                    key="info"
                                    className="route-tabs-pane"
                                    disabled={!_.get(selectedSession, "routeID", false)}>
                                    <RouteInformation />
                                </TabPane>

                                <TabPane
                                    tab="History"
                                    key="history"
                                    className="route-tabs-pane"
                                    disabled={noPlaylistData || shareLink}>
                                    <RouteHistoryList visible={currentTab === "history"} />
                                </TabPane>

                                <TabPane
                                    tab="Detection Info"
                                    key="markerInfo"
                                    className="route-tabs-pane DetectionInfoPane"
                                    disabled={_.isEmpty(selectedMarker)}>
                                    {!_.isNil(selectedMarker.review_status) && <MarkerInformation />}
                                </TabPane>

                                {issuesTab && (
                                    <TabPane
                                        tab="Issues"
                                        key="issues"
                                        className="route-tabs-pane">
                                        <IssuesTab />
                                    </TabPane>
                                )}

                                <TabPane
                                    tab="Favourites"
                                    key="favourites"
                                    className="route-tabs-pane">
                                    <FavouritesTab />
                                </TabPane>

                                {contentFeedTab && (
                                    <TabPane
                                        tab="Content Feed"
                                        key="content_feed"
                                        className="route-tabs-pane">
                                        <GlobalContentTab />
                                    </TabPane>
                                )}

                                {shortcutsTabEnabled && (
                                    <TabPane
                                        tab="Shortcuts"
                                        key="shortcuts"
                                        className="route-tabs-pane">
                                        <ShortcutsTab />
                                    </TabPane>
                                )}

                                {assetsEnabled && (
                                    <TabPane
                                        tab="Assets"
                                        key="assets"
                                        className="route-tabs-pane">
                                        <AssetsTab />
                                    </TabPane>
                                )}

                                {mediaEnabled && (
                                    <TabPane
                                        tab="Media"
                                        key="media"
                                        className="route-tabs-pane">
                                        <MediaTab />
                                    </TabPane>
                                )}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default V2Layout;
