import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DtAnnotationCanvas from "./DtAnnotationCanvas";
import DtProgressBar from "./DtProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/pro-regular-svg-icons";
import { updateSelectedSeenAnnotationsList, updateSkippedAnnotationsList, updateTrainingProgress } from "redux/actions/driverTrainingActions";
import _ from "lodash";

const ANNOTATION_TRIGGER_DISTANCE = 0.05; // this would be 50m
const selectedSessionProgressSelector = (state) => _.get(state.driverTraining.trainee.selectedSession, ["progress", "completed"], []);
const selectedSessionSeenSelector = (state) => _.get(state.driverTraining.trainee.selectedSession, ["progress", "seen"], []);
const selectedSessionSkippedSelector = (state) => _.get(state.driverTraining.trainee.selectedSession, ["progress", "skipped"], []);

const DtImageWithBbox = ({ annotation, first, distance, skippedAnnotationsIDs, adjustedTriggerDistance, currentSpeed }) => {
    const dispatch = useDispatch();

    const imgRef = useRef();
    const previousDistanceRef = useRef(distance);
    const selectedSessionProgress = useSelector(selectedSessionProgressSelector);
    const selectedSessionSeenData = useSelector(selectedSessionSeenSelector);
    const selectedSessionSkipped = useSelector(selectedSessionSkippedSelector);

    const [gettingCloser, setGettingCloser] = useState(true);
    const [annotaionImageWidth, setAnnotationImageWidth] = useState(434);
    const [annotaionImageHeight, setAnnotationImageHeight] = useState(249);
    const [progressUpdated, setProgressUpdated] = useState(false);

    // this will check if the distance to the object is increassing or decreasing
    useEffect(() => {
        if (distance > previousDistanceRef.current && currentSpeed > 2) {
            setGettingCloser(false);
        } else if (distance < previousDistanceRef.current) {
            setGettingCloser(true);
        }
        previousDistanceRef.current = distance;
    }, [annotation.id, currentSpeed, distance]);

    const checkAnnotationImgDimensions = () => {
        if (imgRef.current) {
            setAnnotationImageHeight(imgRef.current.offsetHeight);
            setAnnotationImageWidth(imgRef.current.offsetWidth);
        }
    };

    // when session went pass annotation, display for another 2secs and hide
    const hideAnnotation = useCallback(
        (newSeenList) => {
            dispatch(updateSelectedSeenAnnotationsList(newSeenList));
            // setTimeout(() => {
            // }, 2000);
        },
        [dispatch],
    );

    const isCompleted = useMemo(() => _.includes(selectedSessionProgress, String(annotation.id)), [annotation.id, selectedSessionProgress]);
    const wasSkipped = useMemo(() => _.includes(selectedSessionSkipped, annotation.id), [annotation.id, selectedSessionSkipped]);

    useEffect(() => {
        // console.log('debug2 currentSpeed', gettingCloser, currentSpeed);
        // const _completed = _.includes(selectedSessionProgress, annotation.id)
        if (!progressUpdated && !isCompleted && !gettingCloser) {
            if (!skippedAnnotationsIDs.includes(annotation.id)) {
                // if annotation was skipped before
                if (wasSkipped) {
                    let newSkippedList = _.remove(selectedSessionSkipped, (id) => id === annotation.id);
                    dispatch(updateSkippedAnnotationsList(newSkippedList));
                }
                let newCompletedList = [...selectedSessionProgress, String(annotation.id)];
                dispatch(updateTrainingProgress(newCompletedList));
            } else {
                let newSkippedList = [...selectedSessionSkipped, annotation.id];
                dispatch(updateSkippedAnnotationsList(newSkippedList));
            }
            setProgressUpdated(true);
        } else if (!gettingCloser) {
            if (!_.includes(selectedSessionSeenData, annotation.id)) {
                let newSeenList = [...selectedSessionSeenData, annotation.id];
                hideAnnotation(newSeenList);
                // if (isCompleted) {
                //     dispatch(updateSelectedSeenAnnotationsList(newSeenList))
                // } else {
                // }
            }
        }
    }, [
        annotation.id,
        dispatch,
        distance,
        gettingCloser,
        hideAnnotation,
        isCompleted,
        progressUpdated,
        selectedSessionProgress,
        selectedSessionSeenData,
        selectedSessionSkipped,
        skippedAnnotationsIDs,
        wasSkipped,
    ]);

    // Function to be triggered when the image is loaded to draw bbox in right place
    useEffect(() => {
        const handleImageLoad = () => {
            checkAnnotationImgDimensions();
        };

        if (imgRef.current) {
            imgRef.current.addEventListener("load", handleImageLoad);
        }

        return () => {
            if (imgRef.current) {
                imgRef.current.removeEventListener("load", handleImageLoad);
            }
        };
    }, []);

    return (
        <div className={`UpcomingAnnotationItem ${first ? "Current" : "NextUp"}`}>
            <img
                src={annotation.url}
                alt="Next annotation"
                ref={imgRef}
                crossOrigin={"anonymous"}
            />
            <div className="AnnotationLabel">
                <DtAnnotationCanvas
                    key={annotation.id}
                    annotation={annotation}
                    width_={annotaionImageWidth}
                    height_={annotaionImageHeight}
                    small={!first}
                    bBoxVisible={true}
                />
            </div>
            {isCompleted ? (
                <div className="AnnotationCompletedOverlay">
                    <FontAwesomeIcon
                        color="green"
                        icon={faCheck}
                        size={first ? "3x" : "2x"}
                    />
                </div>
            ) : (
                wasSkipped && (
                    <div className="AnnotationSkippedOverlay">
                        <FontAwesomeIcon
                            color="red"
                            icon={faBan}
                            size={first ? "3x" : "2x"}
                        />
                    </div>
                )
            )}
            <div className="AnnotationProgress">{gettingCloser && <DtProgressBar progress={100 - (distance / adjustedTriggerDistance) * 100} />}</div>
        </div>
    );
};

export default DtImageWithBbox;
