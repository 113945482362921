import { Button, Input, Modal } from "antd";
import OBCSpinner from "components/util/OBC";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createPatrolPlan, getPendingPatrol } from "redux/actions";

const POLLING_INTERVAL = 10000;

const PatrolPlanCreator = ({ visible, closeModal }) => {
    const dispatch = useDispatch();
    const [patrolName, setPatrolName] = useState("");
    const [patrolSVG, setPatrolSVG] = useState("");

    const [pendingPatrolID, setPendingPatrolID] = useState(null);
    const [patrolCreationError, setPatrolCreationError] = useState(null);

    useEffect(() => {
        let interval = null;
        if (pendingPatrolID) {
            interval = setInterval(() => {
                dispatch(getPendingPatrol(pendingPatrolID)).then((response) => {
                    if (response.error) {
                        setPatrolCreationError(response.error);
                    } else if (response.plan_id) {
                        const baseURL = window.location.origin;
                        console.log("debug baseURL", baseURL);
                        window.location.href = `${baseURL}/patrol-planner/${response.plan_id}`;
                    }
                });
            }, POLLING_INTERVAL);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [pendingPatrolID, dispatch]);

    const cancelCreate = () => {
        setPatrolName("");
        setPatrolSVG("");
        closeModal();
        setPendingPatrolID(null);
        setPatrolCreationError(null);
    };

    const onCreateClick = () => {
        dispatch(createPatrolPlan(patrolName, patrolSVG)).then((pendingPlanID) => {
            setPendingPatrolID(pendingPlanID);
        });
    };

    let content = (
        <>
            <div className="CreatorInputContainer">
                <h3 className="InputTitle">Plan Name</h3>
                <Input
                    value={patrolName}
                    onChange={(e) => setPatrolName(e.target.value)}
                    placeholder="Plan Name"
                />
            </div>

            <div className="CreatorInputContainer">
                <h3 className="InputTitle">Plan SVG</h3>
                <Input.TextArea
                    rows={10}
                    placeholder="SVG"
                    value={patrolSVG}
                    onChange={(e) => setPatrolSVG(e.target.value)}
                />
            </div>
        </>
    );

    let footer = [
        <Button onClick={cancelCreate}>Cancel</Button>,
        <Button
            onClick={onCreateClick}
            type="primary"
            disabled={patrolName === "" || patrolSVG === ""}>
            Create
        </Button>,
    ];

    if (pendingPatrolID) {
        content = (
            <>
                <OBCSpinner
                    size={90}
                    speed={3}
                    color={"#e8dfff"}
                />
                <p className="WarningText">This could take a while</p>
            </>
        );

        footer = [<Button onClick={cancelCreate}>Close</Button>];
    }

    if (patrolCreationError) {
        content = (
            <>
                <p className="CreationError">Error creating patrol SVG</p>
                <p className="CreationError">{patrolCreationError}</p>
            </>
        );
    }

    return (
        <Modal
            onCancel={cancelCreate}
            visible={visible}
            width="60vw"
            footer={footer}>
            <div className="PatrolPlanCreatorContainer">{content}</div>
        </Modal>
    );
};

export default PatrolPlanCreator;
