import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import _ from "lodash";
import {
    dotProduct,
    getEndCoordinate,
    getHeading,
    getStartCoordinate,
    latLonToMeters,
    locationsAreEqual,
    nearestPointOnGeometry,
    nearestPointOnLine,
    projectToIntersection,
} from "../../../util/Geometry";
import { useDispatch, useSelector, useStore } from "react-redux";
import { d2, distance, get_source_calibration, getAbsoluteTimestamp, getInterpolatedPosition, getOffsetAdjustedTime } from "../../../util/PlaylistUtils";
import { useDrag } from "react-use-gesture";
import { gpsTimeOffsets } from "../../../../redux/actions/index";

const videoDataSelector = (state) => {
    const playlistData = state.playlist.data;
    const sourceIndex = state.playlist.position.sourceIndex;
    return _.get(playlistData, ["video", sourceIndex], null);
};

const useSnappedSelector = (state) => state.snappedRoute || false;
const currentPositionSelector = (state) => state.playlist.position.coords;
const sourceIndexSelector = (state) => state.playlist.position.sourceIndex;
const offsetsSelector = (state) => state.gpsTimeOffsets.offsets || [];
const routeIDSelector = (state) => state.playlist.data.routeID;
const calibrationSelector = (state) => {
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }
    return get_source_calibration(
        state.measurement.calibration,
        state.playlist.position.sourceIndex,
        getAbsoluteTimestamp(state.playlist) * 1000,
        state.userDetails.userConfig.super_admin,
        defaultRailSeparation,
    );
};

const currentPlaylistTimeSelector = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlistIndex = state.playlist.position.currentIndex;
    const timeOffset = state.playlist.position.currentTimeOffset || 0;
    const playlistTime = _.get(state.playlist.data, ["video", sourceIndex, playlistIndex, 1], 0);
    return timeOffset + playlistTime;
};

export const sessionIsBackwardSelector = (state) => {
    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    return _.indexOf(sessionTags, "Backward") !== -1;
};

const arrowCoords = new Float32Array([
    0, 0, 2, 0, 0, 1.7, -0.3, 0, 0.8, 0.3, 0, 0.8, -0.5, 0, 0.5, -0.125, 0, 0.5, 0.125, 0, 0.5, 0.5, 0, 0.5, -0.5, 0, -0.5, -0.125, 0, -0.5, 0.125, 0, -0.5,
    0.5, 0, -0.5, -0.3, 0, -0.8, 0.3, 0, -0.8, 0, 0, -1.7, 0, 0, -2,
]);

const innerArrowVertices = new Uint32Array([1, 3, 2, 12, 13, 14]);

const outerArrowVertices = new Uint32Array([
    0, 1, 4, 0, 3, 1, 1, 2, 4, 0, 7, 3, 2, 5, 4, 2, 3, 5, 3, 6, 5, 3, 7, 6, 5, 6, 9, 6, 10, 9, 8, 9, 12, 9, 10, 13, 10, 11, 13, 9, 13, 12, 11, 14, 13, 8, 12,
    14, 8, 14, 15, 11, 15, 14,
]);

function GPSCorrection() {
    // Trigger updates on redux position state changes for this component only

    const store = useStore();
    const dispatch = useDispatch();

    const ref = useRef();
    const dragInProgress = useRef(false);
    const dragPoint = useRef(null);
    const dragPointHeading = useRef(null);
    const currentPositionRef = useRef(currentPositionSelector(store.getState()));
    const dragStartPositionRef = useRef(null);
    const currentPlaylistTimeRef = useRef(currentPlaylistTimeSelector(store.getState()));
    const calibrationPointRef = useRef();
    const calibrationPointZDistanceRef = useRef(null);
    const adjustedTimeRef = useRef(0);
    const speedAtTimeRef = useRef(0);
    const sessionIsBackward = useSelector(sessionIsBackwardSelector);

    const videoData = useSelector(videoDataSelector);
    const useSnapped = useSelector(useSnappedSelector);
    const calibration = useSelector(calibrationSelector);

    const { routeGeometry, zeroPoint } = useMemo(() => {
        const routeGeometry = [];
        let lastEndCoordinate = null;
        let zeroPoint = null;

        videoData.forEach((playlistItem) => {
            const startCoordinate = getStartCoordinate(playlistItem, useSnapped);
            const endCoordinate = getEndCoordinate(playlistItem, useSnapped);

            if (zeroPoint === null) {
                zeroPoint = startCoordinate;
            }

            if (lastEndCoordinate && !locationsAreEqual(startCoordinate, lastEndCoordinate)) {
                routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
            }
            if (startCoordinate) {
                routeGeometry.push(latLonToMeters(zeroPoint, startCoordinate));
            }
            lastEndCoordinate = endCoordinate;
        });
        if (lastEndCoordinate) {
            routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
        }

        return { routeGeometry, zeroPoint };
    }, [videoData, useSnapped]);

    const recalculateDragPoint = useCallback(() => {
        const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);

        const [, nearestIdx] = _.chain(routeGeometry)
            .reduce((a, b, idx) => {
                if (a === null) {
                    return [b, idx];
                }
                const da = d2(a[0], positionOffset);
                const db = d2(b, positionOffset);
                if (da < db) {
                    return a;
                } else {
                    return [b, idx];
                }
            }, null)
            .value();

        let distance = 20;

        let pIdx;
        if (sessionIsBackward) {
            pIdx = Math.min(routeGeometry.length - 1, nearestIdx + 1);
        } else {
            pIdx = Math.max(0, nearestIdx - 1);
        }

        while (distance > 0) {
            const p1 = routeGeometry[pIdx];
            let p2;
            if (sessionIsBackward) {
                p2 = routeGeometry[pIdx - 1];
            } else {
                p2 = routeGeometry[pIdx + 1];
            }

            const sp = nearestPointOnLine(positionOffset, p1, p2);
            const p1Dist = d2(positionOffset, sp);
            const p2Dist = d2(positionOffset, p2);

            if (p1Dist <= distance ** 2 && p2Dist >= distance ** 2) {
                distance -= Math.sqrt(p1Dist);
                const lDist = Math.sqrt(d2(sp, p2));
                const dx = p2[0] - sp[0];
                const dy = p2[1] - sp[1];
                dragPoint.current = [sp[0] + (dx * distance) / lDist, sp[1] + (dy * distance) / lDist];

                dragPointHeading.current = Math.atan2(dy, dx);
                distance = 0;
            } else {
                pIdx += sessionIsBackward ? -1 : 1;
                if (pIdx === 0 || pIdx === routeGeometry.length - 1) {
                    break;
                }
            }
        }
    }, [routeGeometry, zeroPoint, dragPoint, currentPositionRef, sessionIsBackward]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
            if (dragPoint.current === null || !dragInProgress.current) {
                dragStartPositionRef.current = currentPositionRef.current;
                currentPlaylistTimeRef.current = currentPlaylistTimeSelector(store.getState());
                recalculateDragPoint();
            }
        });
    }, [store, dragInProgress, routeGeometry, zeroPoint, dragPoint, recalculateDragPoint]);

    const innerArrow = useMemo(() => {
        return (
            <bufferGeometry attach={"geometry"}>
                <bufferAttribute
                    attachObject={["attributes", "position"]}
                    array={arrowCoords}
                    count={16}
                    itemSize={3}
                />
                <bufferAttribute
                    attach="index"
                    array={innerArrowVertices}
                    count={6}
                    itemSize={1}
                />
            </bufferGeometry>
        );
    }, []);

    const outerArrow = useMemo(() => {
        return (
            <bufferGeometry attach={"geometry"}>
                <bufferAttribute
                    attachObject={["attributes", "position"]}
                    array={arrowCoords}
                    count={16}
                    itemSize={3}
                />
                <bufferAttribute
                    attach="index"
                    array={outerArrowVertices}
                    count={54}
                    itemSize={1}
                />
            </bufferGeometry>
        );
    }, []);

    const { size } = useThree();

    const bind = useDrag(({ dragging, xy: [x, y] }) => {
        dragInProgress.current = dragging;

        if (!dragging) {
            calibrationPointZDistanceRef.current = null;
            recalculateDragPoint();
            return;
        }

        if (!dragStartPositionRef.current) {
            return;
        }

        const state = store.getState();

        const wx = 2 * (x / size.width) - 1;
        const wy = 2 * (y / size.height) - 1;

        let worldCoordinates = projectToIntersection([wx, wy], [-10000, 0, 1000], calibration);

        let zDistance = worldCoordinates[2];

        const playlistTime = currentPlaylistTimeRef.current;

        const routeID = routeIDSelector(state);
        const sourceIndex = sourceIndexSelector(state);
        const newOffsets = _.cloneDeep(offsetsSelector(state));

        while (newOffsets.length < sourceIndex) {
            newOffsets.push([]);
        }

        if (calibrationPointZDistanceRef.current == null) {
            calibrationPointZDistanceRef.current = zDistance;
            adjustedTimeRef.current = getOffsetAdjustedTime(playlistTime, newOffsets[sourceIndex]) - playlistTime;
            const positionA = getInterpolatedPosition(playlistTime + adjustedTimeRef.current, videoData, useSnapped);
            const positionB = getInterpolatedPosition(playlistTime + adjustedTimeRef.current + 1, videoData, useSnapped);
            speedAtTimeRef.current = distance(latLonToMeters(positionA, positionB));
        }

        //The difference between these is the amount of distance that needs to be corrected now
        const requiredZDistanceOffset = calibrationPointZDistanceRef.current - zDistance;

        let requiredTimeOffset = 0;
        if (speedAtTimeRef.current > 0) {
            requiredTimeOffset = requiredZDistanceOffset / speedAtTimeRef.current;
        }

        const sessionIsBackward = sessionIsBackwardSelector(state);
        if (sessionIsBackward) {
            requiredTimeOffset *= -1;
        }

        let newTimeOffset = Math.round((adjustedTimeRef.current + requiredTimeOffset) * 100) / 100;
        newOffsets[sourceIndex] = _.reject(newOffsets[sourceIndex], (offset) => Math.abs(offset[0] - playlistTime) <= 10);
        newOffsets[sourceIndex].push([playlistTime, newTimeOffset, 0]);

        newOffsets[sourceIndex] = _.sortBy(newOffsets[sourceIndex], (i) => i[0]);
        dispatch(gpsTimeOffsets(routeID, newOffsets));
    });

    useFrame(() => {
        if (currentPositionRef.current) {
            const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);

            if (ref.current) {
                ref.current.position.set(positionOffset[1], 0, positionOffset[0]);
            }

            if (calibrationPointRef.current && dragPoint.current) {
                //p is closest point
                calibrationPointRef.current.position.set(-dragPoint.current[1], 0.0, -dragPoint.current[0]);
                calibrationPointRef.current.rotation.set(0, dragPointHeading.current, 0);
            }
        }
    });

    return (
        <group ref={ref}>
            <group
                position={[0, 0, 0]}
                ref={calibrationPointRef}>
                <mesh
                    position={[0, 0, 0]}
                    {...bind()}>
                    {outerArrow}
                    <meshBasicMaterial color="black" />
                </mesh>
                <mesh
                    position={[0, 0, 0]}
                    {...bind()}>
                    {innerArrow}
                    <meshBasicMaterial color="white" />
                </mesh>
            </group>
        </group>
    );
}

export default GPSCorrection;
