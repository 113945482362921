import React from "react";
import useMousePosition from "./hooks/useMousePosition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/pro-solid-svg-icons";

const PinCursor = ({ active }) => {
    const { x, y } = useMousePosition();

    return (
        <>
            <div
                style={{ left: `${x - 6}px`, top: `${y - 25}px` }}
                className={`pinCursor ${active ? "active" : ""}`}>
                <FontAwesomeIcon icon={faMapPin} />
            </div>
        </>
    );
};

export default PinCursor;
