import post_1500 from "../../../../../models/post_1500.glb";
import post_3300 from "../../../../../models/post_3300.glb";
import post_4000 from "../../../../../models/post_4000.glb";
import { useGLTF } from "@react-three/drei";
import React, { useMemo } from "react";
import Meshes from "./Meshes";
function Post(props) {
    const post = useMemo(() => {
        const height = props.height;
        if (height > 3300) {
            return post_4000;
        } else if (height > 1500) {
            return post_3300;
        } else {
            return post_1500;
        }
    }, [props.height]);

    const scale = useMemo(() => {
        const height = props.height;
        if (height === 5100) {
            return [100, 127.5, 100];
        } else {
            return 100;
        }
    }, [props.height]);

    const scene = useGLTF(post);

    const geometries = useMemo(() => [scene.nodes.Post.geometry], [scene]);

    const materials = useMemo(() => [scene.materials["Post_Tile_Material__Post_Tile_jpg"]], [scene]);

    return (
        <group
            {...props}
            dispose={null}>
            <group
                rotation={[0, 0, 0]}
                scale={scale}>
                <Meshes
                    geometries={geometries}
                    materials={materials}
                    selected={props.selected}
                    highlighted={props.highlighted}
                />
            </group>
        </group>
    );
}

export default Post;
