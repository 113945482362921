import React from "react";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import _ from "lodash";
import { videoTimeLookup } from "../../../util/PlaylistUtils";
import { SVGAnnotations } from "./AnnotationComponents";
import memoizeOne from "memoize-one";

class AnnotationOverlay extends React.PureComponent {
    constructor(props) {
        super(props);

        this.displayDiv = React.createRef();
    }
    getBoundingBoxStyle = (annotation) => {
        let top = annotation.bbTop;
        let left = annotation.bbLeft;
        let height = annotation.bbBottom - top;
        let width = annotation.bbRight - left;
        return {
            top: top * 100 + "%",
            left: left * 100 + "%",
            width: width * 100 + "%",
            height: height * 100 + "%",
        };
    };

    renderContent = () => {
        return this.props.annotations.map((annotation, index) => {
            if (annotation.shape === "line" || annotation.shape === "text") {
                return null;
            }

            let tagName = "";
            if (annotation.bbType) {
                if (annotation.bbType === -1) {
                    tagName = annotation.customTag;
                } else {
                    let selectedTag = this.props.userAnnotationTypes.find((type) => type.id === annotation.bbType);
                    if (selectedTag) {
                        tagName = selectedTag.type;
                    }
                }
            }

            let annotationClassName = "AnnotationBoundingBox";

            if (annotation.shape === "circle") {
                annotationClassName = "AnnotationBoundingCircle";
            }
            let annotationDiv = (
                <Tippy
                    content={<div className="AnnotationLabel">{tagName}</div>}
                    visible={tagName !== ""}
                    placement={"bottom"}
                    hideOnClick={false}
                    interactive={true}
                    sticky={true}
                    plugins={[sticky]}
                    theme={"translucent"}
                    zIndex="400">
                    <div></div>
                </Tippy>
            );

            if (annotation.bbHeight === 0 && annotation.bbWidth === 0) {
                annotationClassName = null;
            }

            return (
                <div
                    key={"Annotation" + index}
                    className={annotationClassName}
                    style={this.getBoundingBoxStyle(annotation, index)}>
                    {annotationDiv}
                </div>
            );
        });
    };

    render() {
        let height = 0;
        let width = 0;

        if (this.displayDiv.current) {
            const clientRect = this.displayDiv.current.getBoundingClientRect();
            height = clientRect.height;
            width = clientRect.width;
        }

        return (
            <div
                className="AnnotationImageOverlay"
                ref={this.displayDiv}
                onClick={this.props.togglePlayPause}>
                <svg className="AnnotationSVGOverlay">
                    <SVGAnnotations
                        annotations={this.props.annotations}
                        boundingClientHeight={height}
                        boundingClientWidth={width}
                    />
                </svg>
                {this.renderContent()}
            </div>
        );
    }
}

const formatAnnotations = memoizeOne((annotations, videoKey) => {
    return annotations
        .filter((annotation) => {
            return annotation.videoKey === videoKey;
        })
        .map((annotation) => {
            return {
                id: annotation.id,
                bbTop: annotation.bounds[0],
                bbLeft: annotation.bounds[1],
                bbBottom: annotation.bounds[2],
                bbRight: annotation.bounds[3],
                bbHeight: annotation.bounds[2] - annotation.bounds[0],
                bbWidth: annotation.bounds[3] - annotation.bounds[1],
                bbType: annotation.type,
                shape: annotation.shape,
                customTag: annotation.custom_tag,
            };
        });
});

const mapStateToProps = (state, ownProps) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlistData = _.get(state.playlist.data, ["video", sourceIndex]) || [];
    let playlistIndex = videoTimeLookup(ownProps.timestamp, playlistData);
    let videoKey = null;
    if (playlistIndex !== -1) {
        let playlistItem = playlistData[playlistIndex];
        videoKey = playlistItem[0];
    }

    const currentAnnotations = formatAnnotations(state.userAnnotations, videoKey);

    return {
        userAnnotationTypes: state.userAnnotationTypes,
        annotations: currentAnnotations,
    };
};

export default connect(mapStateToProps)(AnnotationOverlay);
