import { combineReducers } from "redux";
import {
    SET_CURRENT_PAGE,
    SELECT_SESSION,
    SET_CATEGORY_ID,
    SET_DRIVERS_DATA,
    SET_SELECTED_CATEGORY_DATA,
    SET_TRAINING_DATA,
    SET_AVALIABLE_TRAINING_CATEGORIES,
    SET_TRAINEE_TRAINING_DATA,
    SET_PROGRESS_COMPLETED_DATA,
    SET_PROGRESS_SEEN_DATA,
    SET_PROGRESS_SKIPPED_DATA,
} from "redux/actions/driverTrainingActions";
import _ from "lodash";

function currentPage(state = "welcome", action) {
    if (action.type === SET_CURRENT_PAGE) {
        state = action.currentPage;
    }

    return state;
}

function selectedSession(state = null, action) {
    if (action.type === SELECT_SESSION) {
        state = _.cloneDeep(state);
        state = action.sessionData;
        state.progress.seen = [];
        state.progress.skipped = [];
    } else if (action.type === SET_PROGRESS_COMPLETED_DATA) {
        state = _.cloneDeep(state);
        state.progress.completed = action.annotations;
    } else if (action.type === SET_PROGRESS_SEEN_DATA) {
        state = _.cloneDeep(state);
        state.progress.seen = action.annotations;
    } else if (action.type === SET_PROGRESS_SKIPPED_DATA) {
        state = _.cloneDeep(state);
        state.progress.skipped = action.annotations;
    }
    return state;
}

function selectedTraineeCategoryData(state = null, action) {
    if (action.type === SET_CATEGORY_ID) {
        state = _.cloneDeep(state);
        state = action.categoryData;
    }
    return state;
}

function drviersList(state = [], action) {
    if (action.type === SET_DRIVERS_DATA) {
        state = _.cloneDeep(state);
        state = action.driversData;
    }
    return state;
}

function drvierTrainingData(state = {}, action) {
    if (action.type === SET_TRAINING_DATA) {
        state = _.cloneDeep(state);
        state = action.trainingData;
    }
    return state;
}

function selectedCategoryData(state = [], action) {
    if (action.type === SET_SELECTED_CATEGORY_DATA) {
        state = _.cloneDeep(state);
        state = action.categoryData;
    } else if (action.type === SET_TRAINING_DATA) {
        state = _.cloneDeep(state);
        state = [];
    }

    return state;
}

function availableTrainingCategoriesList(state = [], action) {
    if (action.type === SET_AVALIABLE_TRAINING_CATEGORIES) {
        state = _.cloneDeep(state);
        state = action.categories;
    }

    return state;
}

function trainingList(state = [], action) {
    if (action.type === SET_TRAINEE_TRAINING_DATA) {
        state = _.cloneDeep(state);
        state = action.trainingData;
    }
    return state;
}

function selectedTrainigData(state = null, action) {
    // if (action.type === SET_CATEGORY_ID) {
    //     state = _.cloneDeep(state);
    //     state = action.categoryID;
    // }
    return state;
}

const admin = combineReducers({
    availableTrainingCategoriesList,
    drviersList,
    drvierTrainingData,
    selectedCategoryData: selectedCategoryData,
});

const trainee = combineReducers({
    currentPage,
    selectedTraineeCategoryData,
    selectedSession,
    trainingList,
    selectedTrainigData,
});

const driverTraining = combineReducers({
    // currentPage,
    trainee,
    // selectedCategory,
    // selectedSession,
    admin,
});

export default driverTraining;
