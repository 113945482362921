import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsH } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "antd";

const CustomResizeHandle = React.forwardRef((props, ref) => {
    const { handleAxis, ...restProps } = props;

    return (
        <div
            ref={ref}
            className={`v2-main__handle handle-${handleAxis}`}
            {...restProps}>
            <Tooltip title={"Drag to resize"}>
                <div className="v2-main__handle__box">
                    <FontAwesomeIcon icon={faArrowsH} />
                </div>
            </Tooltip>
        </div>
    );
});

export default CustomResizeHandle;
