import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";
import { findNearestRailImage, ONE_METRE_IN_CHAINS, chainageFromImage, mileYardToYard } from "../../components/util/Geometry";
import { notification } from "antd";

export const TOGGLE_FETCHING_ELRS = "TOGGLE_FETCHING_ELRS";
export const RESET_PATROL_COVERAGE = "RESET_PATROL_COVERAGE";
export const PATROL_STATUS = "PATROL_STATUS";
export const RECEIVE_PATROL_USERS = "RECEIVE_PATROL_USERS";
export const ROUTE_PATROLS_FETCHED = "ROUTE_PATROLS_FETCHED";
export const SET_PATROL_REPORT_OPEN = "SET_PATROL_REPORT_OPEN";
export const SET_PATROL_REPORT_FORM = "SET_PATROL_REPORT_FORM";
export const SET_SCHEMA_MARKUP = "SET_SCHEMA_MARKUP";
export const RECEIVE_PATROL_DIRECTIONS = "RECEIVE_PATROL_DIRECTIONS";
export const ADD_FETCHING_ELR = "ADD_FETCHING_ELR";
export const ELR_FETCHED = "ELR_FETCHED";
export const ROUTE_PATROL_EXITED = "ROUTE_PATROL_EXITED";
export const SCHEMA_NAVIGATION_PAGE = "SCHEMA_NAVIGATION_PAGE";
export const CLEAR_PATROL_STATE = "CLEAR_PATROL_STATE";
export const RECEIVE_ROUTE_PATROLS = "RECEIVE_ROUTE_PATROLS";
export const RECEIVE_REVIEWER_ROUTE_PATROLS = "RECEIVE_REVIEWER_ROUTE_PATROLS";
export const INSPECTING_PATROL = "INSPECTING_PATROL";
export const RECEIVE_PATROL_IMAGES = "RECEIVE_PATROL_IMAGES";
export const SET_PATROL_LOCATION = "SET_PATROL_LOCATION";
export const PATROL_IMAGES_LOADED = "PATROL_IMAGES_LOADED";
export const PATROL_COVERED = "PATROL_COVERED";
export const PATROL = "PATROL";
export const RECEIVE_PATROL_PLAN = "RECEIVE_PATROL_PLAN";
export const RECEIVE_PATROL_DISPLAY_CONFIGS = "RECEIVE_PATROL_DISPLAY_CONFIGS";
export const RECEIVE_PATROL_DISPLAY_CONFIG = "RECEIVE_PATROL_DISPLAY_CONFIG";
export const SET_SELECTED_PATROL_SESSION = "SET_SELECTED_PATROL_SESSION";
export const SET_SELECTED_PATROL_SESSION_ID = "SET_SELECTED_PATROL_SESSION_ID";
export const HOVERED_SESSION_LINES = "HOVERED_SESSION_LINES";
export const CALCULATE_PATROL_SESSIONS = "CALCULATE_PATROL_SESSIONS";
export const TOGGLE_FETCHING_PATROL_HISTORY = "TOGGLE_FETCHING_PATROL_HISTORY";
export const RECEIVE_PATROL_HISTORY = "RECEIVE_PATROL_HISTORY";
export const RECEIVE_PATROL_LOCATION = "RECEIVE_PATROL_LOCATION";
export const SET_PATROL_HISTORY_TIMESTAMP = "SET_PATROL_HISTORY_TIMESTAMP";
export const RECEIVE_PATROL_PLANS = "RECEIVE_PATROL_PLANS";
export const CLEAR_PATROL_IMAGES = "CLEAR_PATROL_IMAGES";
export const TOGGLE_PATROL_BUG_REPORT = "TOGGLE_PATROL_BUG_REPORT";
export const TOGGLE_PATROL_DIAGRAM_REPORT = "TOGGLE_PATROL_DIAGRAM_REPORT";
export const SET_TOTAL_PATROL_PAGES = "SET_TOTAL_PATROL_PAGES";
export const SET_PATROL_PAGE = "SET_PATROL_PAGE";
export const RECEIVE_PATROL_RUNS = "RECEIVE_PATROL_RUNS";
export const TOGGLE_PATROL_DETAIL_MODAL = "TOGGLE_PATROL_DETAIL_MODAL";
export const PATROL_PLAN_IMAGES = "PATROL_PLAN_IMAGES";

export function togglePatrolDetailModal(open) {
    return {
        type: TOGGLE_PATROL_DETAIL_MODAL,
        open,
    };
}

export function toggleDiagramReport(open) {
    return {
        type: TOGGLE_PATROL_DIAGRAM_REPORT,
        open,
    };
}

export function togglePatrolBugReport(open) {
    return {
        type: TOGGLE_PATROL_BUG_REPORT,
        open,
    };
}

export function setPatrolHistoryTimestamp(timestamp) {
    return {
        type: SET_PATROL_HISTORY_TIMESTAMP,
        timestamp,
    };
}

export function setHoveredSessionLines(lines) {
    return {
        type: HOVERED_SESSION_LINES,
        lines,
    };
}
export function patrolStatus(status) {
    return {
        type: PATROL_STATUS,
        status,
    };
}

export function clearPatrolState() {
    return {
        type: CLEAR_PATROL_STATE,
    };
}

export function receivePatrolImages(images) {
    return {
        type: RECEIVE_PATROL_IMAGES,
        images,
    };
}

export function resetPatrolCoverage() {
    return {
        type: RESET_PATROL_COVERAGE,
    };
}

export function routePatrolExited() {
    return {
        type: ROUTE_PATROL_EXITED,
    };
}

export function clearPatrolImages() {
    return {
        type: CLEAR_PATROL_IMAGES,
    };
}

export function schemaNavigationPage(page, itemOffset) {
    return {
        type: SCHEMA_NAVIGATION_PAGE,
        page,
        itemOffset,
    };
}

export function togglePatrolInspecting(value) {
    return {
        type: INSPECTING_PATROL,
        inspecting: value,
    };
}

export function setCurrentSchemaMarkup(type, id) {
    return {
        type: SET_SCHEMA_MARKUP,
        markupType: type,
        id,
    };
}

function receivePatrolDirections(directions) {
    return {
        type: RECEIVE_PATROL_DIRECTIONS,
        directions,
    };
}

export function toggleFetchingELRs(fetching) {
    return {
        type: TOGGLE_FETCHING_ELRS,
        fetching,
    };
}

export const setPatrolReportOpen = (open) => {
    return {
        type: SET_PATROL_REPORT_OPEN,
        open,
    };
};

export const setPatrolReportForm = (form) => {
    return {
        type: SET_PATROL_REPORT_FORM,
        form,
    };
};

export function setSelectedPatrolSession(session) {
    return {
        type: SET_SELECTED_PATROL_SESSION,
        session,
    };
}

export function setSelectedPatrolSessionId(id) {
    return {
        type: SET_SELECTED_PATROL_SESSION_ID,
        id,
    };
}

export function patrolImagesLoaded(loaded) {
    return {
        type: PATROL_IMAGES_LOADED,
        loaded,
    };
}

export function patrolCovered(coverage) {
    return {
        type: PATROL_COVERED,
        coverage,
    };
}

export function receivePatrolPlan(plan) {
    return {
        type: RECEIVE_PATROL_PLAN,
        plan,
    };
}

export function numberPatrolImages(value) {
    return {
        type: PATROL_PLAN_IMAGES,
        value,
    };
}

export function setPatrolDisplayConfigs(configs) {
    return {
        type: RECEIVE_PATROL_DISPLAY_CONFIGS,
        configs,
    };
}

export function setPatrolDisplayConfig(config) {
    return {
        type: RECEIVE_PATROL_DISPLAY_CONFIG,
        config,
    };
}

export function lockPatrolImages() {
    return (dispatch, getState) => {
        const lockedPatrol = _.cloneDeep(getState().schemaInterface.plan);
        lockedPatrol.image_lock_timestamp = Date.now() / 1000;
        lockedPatrol.svg = JSON.stringify(lockedPatrol.svg);
        dispatch(receivePatrolPlan(lockedPatrol));
    };
}

function filterRailImages(images, patrolLines) {
    const filteredImages = images.filter((img) => {
        let hasMatchingLine = false;
        patrolLines.forEach((line) => {
            if (hasMatchingLine) {
                return;
            }
            if (line.ELR.toLowerCase() === img.mwv.elr.toLowerCase() && line.TRID.toString() === img.mwv.trid.toString()) {
                const imageChains = chainageFromImage(img);
                const lineStart = Math.min(line.chains1, line.chains2);
                const lineEnd = Math.max(line.chains1, line.chains2);
                if (imageChains > lineStart - 0.5 && imageChains < lineEnd + 0.5) {
                    hasMatchingLine = true;
                }
            }
        });

        return hasMatchingLine;
    });

    return filteredImages;
}

export function getPatrolRuns(plan_id) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_patrol_runs",
                plan_id,
            };
            let url = "/routeMetadata";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("patrol runs response", response);
                    dispatch({
                        type: RECEIVE_PATROL_RUNS,
                        runs: response.runs,
                    });
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrol runs", "An error occurred while fetching patrol runs", error);
                    next();
                });
        },
    };
}

export function updatePatrolProgress(patrol_id, progress, status, reportForm, review = false) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "update_patrol_progress",
                patrol_id,
                progress,
                status,
                review,
                report: reportForm,
            };
            let url = "/routeMetadata";
            console.log("updating patrol progress", progress);

            const metadata = {
                patrol_id: patrol_id,
            };
            dispatch(railPatrolAudit("save_route_patrol_progress", metadata));
            if (status) {
                dispatch(patrolStatus(status));
            }

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("patrol progress update response", response);
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error updating progress", "An error occurred while updating patrol progress", error);

                    next();
                });
        },
    };
}

export function getPatrolPlan(patrolID, review, callback) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_patrol_plan",
                id: patrolID,
                review,
            };
            let url = "/routeMetadata";
            console.log("Getting plan for patrol ID:", patrolID);

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.report) {
                        dispatch(setPatrolReportForm(JSON.parse(response.report)));
                    }
                    if (response.progress) {
                        dispatch(patrolCovered(response.progress));
                    }
                    if (!_.isNil(response.status)) {
                        dispatch(patrolStatus(response.status));
                    }
                    if (response.svg) {
                        dispatch(receivePatrolPlan(response));
                    } else {
                        if (callback) {
                            callback(true);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrol plan", "An error occurred while fetching patrol plan", error);
                    next();
                });
        },
    };
}

export function getRoutePatrols(callback, page = 0) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_patrols",
                page,
            };
            let url = "/routeMetadata";
            console.log("getting route patrols");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response.patrols) {
                        const svgParsedPatrols = response.patrols.map((patrol) => {
                            patrol.svg = JSON.parse(patrol.svg);
                            return patrol;
                        });

                        console.log("parsed patrols", svgParsedPatrols);

                        dispatch({
                            type: RECEIVE_ROUTE_PATROLS,
                            patrols: svgParsedPatrols,
                            page: page,
                        });
                    }

                    if (response.is_more) {
                        dispatch(getRoutePatrols(callback, page + 1));
                    } else {
                        dispatch({
                            type: ROUTE_PATROLS_FETCHED,
                        });
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrols", "An error occurred while fetching patrols", error);
                    next();
                });
        },
    };
}

export function getReviewerPatrolPlans() {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_reviewer_patrols",
            };
            let url = "/routeMetadata";
            console.log("getting reviewer route patrols");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug reviewer patrols response", response);

                    if (response) {
                        const svgParsedPatrols = response.response.map((patrol) => {
                            patrol.svg = JSON.parse(patrol.svg);
                            return patrol;
                        });

                        dispatch({
                            type: RECEIVE_REVIEWER_ROUTE_PATROLS,
                            patrols: svgParsedPatrols,
                        });
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting reviewer patrols", "An error occurred while fetching reviewer patrols", error);
                    next();
                });
        },
    };
}

export const savePatrolReport = (report, patrol_id, review = false) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "save_report",
                report: JSON.stringify(report),
                patrol_id,
                review,
            };

            let url = "/routeMetadata";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log("debug error saving patrol report");
                    reject();
                });
        });
    };
};

export function archivePatrolPlan(patrolID, archive) {
    return (dispatch, getState) => {
        let postBody = {
            action: "archive_patrol",
            patrol_id: patrolID,
            archived: archive,
        };

        const patrols = _.cloneDeep(getState().schemaInterface.reviewerPatrols);
        const updatedIndex = _.findIndex(patrols, { id: patrolID });
        const oldArchiveStatus = patrols[updatedIndex].archived;
        patrols[updatedIndex].archived = archive;
        dispatch({
            type: RECEIVE_REVIEWER_ROUTE_PATROLS,
            patrols: patrols,
        });

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (!response.success) {
                    patrols[updatedIndex].archived = oldArchiveStatus;
                    dispatch({
                        type: RECEIVE_REVIEWER_ROUTE_PATROLS,
                        patrols: patrols,
                    });
                }
            })
            .catch((e) => {
                console.log("Error archiving patrol", e);
            });
    };
}

export function getPatrolUsers() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_patrol_users",
        };

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch({
                    type: RECEIVE_PATROL_USERS,
                    users: response,
                });
            })
            .catch((e) => {
                console.log("Error archiving patrol", e);
            });
    };
}

export function assignPatrol(patrolID, userID, notify) {
    return (dispatch, getState) => {
        let postBody = {
            action: "assign_patrol",
            patrol_id: patrolID,
            user_id: userID,
            notify,
        };

        const patrolUser = _.find(getState().schemaInterface.patrolUsers.users, { id: userID });
        const patrols = _.cloneDeep(getState().schemaInterface.reviewerPatrols);
        const updatedIndex = _.findIndex(patrols, { id: patrolID });
        const oldID = patrols[updatedIndex].id;
        const oldEmail = patrols[updatedIndex].user_email;
        const oldUser = patrols[updatedIndex].user_name;

        patrols[updatedIndex].user_email = patrolUser.email;
        patrols[updatedIndex].user_name = patrolUser.name;

        dispatch({
            type: RECEIVE_REVIEWER_ROUTE_PATROLS,
            patrols: patrols,
        });

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    const newPatrols = _.cloneDeep(getState().schemaInterface.reviewerPatrols);
                    newPatrols[updatedIndex].id = response.new_patrol_id;
                    dispatch({
                        type: RECEIVE_REVIEWER_ROUTE_PATROLS,
                        patrols: newPatrols,
                    });
                    notification.success({ message: "Patrol successfully assigned" });
                    dispatch(getReviewerPatrolPlans());
                } else {
                    patrols[updatedIndex].user_email = oldEmail;
                    patrols[updatedIndex].user_name = oldUser;
                    patrols[updatedIndex].id = oldID;
                    dispatch({
                        type: RECEIVE_REVIEWER_ROUTE_PATROLS,
                        patrols: patrols,
                    });
                }
            })
            .catch((e) => {
                console.log("Error assigning patrol", e);
            });
    };
}

export function updatePatrolDefect(updatingID, type, notes, supervisor = false) {
    return (dispatch, getState) => {
        const state = getState();
        const report = _.cloneDeep(state.schemaInterface.patrolReport.form);
        const defects = report.defectList;
        if (!defects) return;

        const updatingIndex = _.findIndex(defects, { id: updatingID });
        if (updatingIndex || updatingIndex === 0) {
            defects[updatingIndex] = {
                ...defects[updatingIndex],
                description: notes,
                defectType: type,
                supervisor_edited: supervisor,
            };
        }

        report.defectList = defects;

        dispatch(updatePatrolProgress(state.schemaInterface.plan.id, null, null, report, true));
        dispatch(setPatrolReportForm(report));
    };
}

export function getPatrolDirections(deviceID, from, to) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_patrol_directions",
            device_group: deviceID,
            from,
            to,
        };

        const url = "/routeMetadata";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.directions) {
                    dispatch(receivePatrolDirections(response.directions));
                }
            })
            .catch(() => {
                console.log("Error getting inspection annotation");
            });
    };
}

export function getPatrolDirectionsV2(deviceID, from, to) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_patrol_directions_v2",
            device_group: deviceID,
            from,
            to,
        };

        const url = "/routeMetadata";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.directions) {
                    dispatch(receivePatrolDirections(response.directions));
                }
            })
            .catch(() => {
                console.log("Error getting inspection annotation");
            });
    };
}

export function getStaticPatrolImages(patrolImageFileLink) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            dispatch(receivePatrolImages([]));

            if (!patrolImageFileLink) {
                dispatch(toggleFetchingELRs(false));
                dispatch(patrolImagesLoaded(true));

                next();
                return;
            }

            dispatch(toggleFetchingELRs(true));

            return fetch(patrolImageFileLink, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
            })
                .then((response) => {
                    if (!response.ok) {
                        // we throw an error, but return the response object to the calling
                        // code in case there is useful data in the body, making no assumptions
                        // about whether or not the body will be JSON
                        return Promise.reject(response);
                    } else {
                        return response.json();
                    }
                })
                .then((json) => {
                    const state = getState();
                    const patrolLines = state.schemaInterface.plan.svg.line;
                    const filteredImages = filterRailImages(json, patrolLines);
                    dispatch(receivePatrolImages(filteredImages));

                    dispatch(patrolImagesLoaded(true));
                    dispatch(toggleFetchingELRs(false));
                    dispatch({
                        type: CALCULATE_PATROL_SESSIONS,
                        images: getState().schemaInterface.images,
                    });
                    next();
                })
                .catch((error) => {
                    console.log("debug error getting static patrol images", error);
                    next();
                });
        },
    };
}

export function getPatrolRailImagesV2(plan_id, device_group_id, next_timestamp = null, customData = {}, imagesReceived) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            dispatch(toggleFetchingELRs(true));

            let postBody = {
                action: "get_patrol_images_v2",
                next_timestamp,
                device_id: device_group_id,
                plan_id,
                custom_data: customData,
            };
            let url = "/routeMetadata";
            console.log("getting rail images page", next_timestamp, postBody);

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.data && response.data.length) {
                        const state = getState();
                        const patrolLines = state.schemaInterface.plan.svg.line;
                        const filteredImages = filterRailImages(response.data, patrolLines);
                        dispatch(receivePatrolImages(filteredImages, response.more));
                        dispatch(numberPatrolImages(imagesReceived + response.data.length));
                    }

                    if (response.loaded) {
                        dispatch({
                            type: SET_PATROL_PAGE,
                            page: response.loaded,
                        });
                    }

                    if (response.next_timestamp) {
                        dispatch(getPatrolRailImagesV2(plan_id, device_group_id, response.next_timestamp, customData, response.data.length + imagesReceived));
                    } else {
                        dispatch(patrolImagesLoaded(true));
                        dispatch(toggleFetchingELRs(false));
                        dispatch({
                            type: CALCULATE_PATROL_SESSIONS,
                            images: getState().schemaInterface.images,
                        });
                        dispatch(numberPatrolImages(0));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrol rail images", "An error occurred while fetching patrol rail images", error);
                    next();
                });
        },
    };
}

export function getPatrolRailImages(elrs, plan_id, device_group_id, page = 0) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            // const mile_from = Math.floor(chainFrom/80)
            // const mile_to = Math.ceil(chainTo/80);

            console.log("debug getting images for elrs", elrs);
            let postBody = {
                action: "get_patrol_images",
                // mile_from,
                // mile_to,
                elrs,
                page: page,
                device_id: device_group_id,
                plan_id,
            };
            let url = "/routeMetadata";
            console.log("getting rail images page", page);

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.data && response.data.length) {
                        const state = getState();
                        const patrolLines = state.schemaInterface.plan.svg.line;
                        // const relevantLines = patrolLines.filter(line => {
                        //     return line.ELR.toLowerCase() === elr.toLowerCase();
                        // });
                        const filteredImages = filterRailImages(response.data, patrolLines);
                        dispatch(receivePatrolImages(filteredImages, response.more));
                    }

                    if (response.more) {
                        dispatch(getPatrolRailImages(elrs, plan_id, device_group_id, page + 1));
                    } else {
                        dispatch(patrolImagesLoaded(true));
                        dispatch(toggleFetchingELRs(false));
                        dispatch({
                            type: CALCULATE_PATROL_SESSIONS,
                            images: getState().schemaInterface.images,
                        });
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrol rail images", "An error occurred while fetching patrol rail images", error);
                    next();
                });
        },
    };
}

export function setPatrolLocation(location, imageTimestamp = false) {
    return (dispatch, getState) => {
        const state = getState();
        let sessionsToSelect = [];
        const sessions = state.schemaInterface.sessions;
        sessions.forEach((session, idx) => {
            if (!imageTimestamp) {
                const nearestImageIndex = findNearestRailImage(location.elr, location.chain, location.trackID, session);
                const nearestImage = session[nearestImageIndex];
                if (
                    nearestImage &&
                    nearestImage.mwv &&
                    Math.abs(parseInt(nearestImage.mwv.mile) * 80 + parseFloat(nearestImage.mwv.yard) / 22 - location.chain) < ONE_METRE_IN_CHAINS * 4
                ) {
                    sessionsToSelect.push(idx);
                }
            } else {
                const matchingImageIndex = _.findIndex(session, { timestamp: imageTimestamp });
                if (matchingImageIndex > -1) {
                    sessionsToSelect.push(idx);
                }
            }
        });

        if (sessionsToSelect.length) {
            const selectedPatrolSession = state.schemaInterface.selectedSession;
            if (!sessionsToSelect.includes(selectedPatrolSession)) {
                dispatch(setSelectedPatrolSession(sessionsToSelect[0]));
            }
        } else {
            dispatch(setSelectedPatrolSession(-1));
        }

        dispatch({
            type: SET_PATROL_LOCATION,
            location,
        });
    };
}

export function getPatrolDeviceConfig(deviceID) {
    return {
        queue: PATROL,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_patrol_display_config_v2",
                device_group: deviceID,
            };
            let url = "/routeMetadata";
            console.log("getting patrol device config");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response) {
                        const config = _.get(response, 0, {});
                        dispatch(setPatrolDisplayConfig(config));
                        dispatch(setPatrolDisplayConfigs(response));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting patrol device config", "An error occurred while fetching patrol device config", error);
                    next();
                });
        },
    };
}

export function resetMWVData(elrData, device_id, from, to, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "reset_mwv_data",
            elr_data: elrData,
            device_id,
            from,
            to,
        };

        const url = "/routeMetadata";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Successfully re triggered MWV data" });
                    callback();
                } else {
                    notification.error({ message: "Error re triggering MWV data" });
                    callback();
                }
            })
            .catch(() => {
                notification.error({ message: "Error re triggering MWV data" });
                callback();
            });
    };
}

export function railPatrolAudit(type, metadata) {
    return (dispatch, getState) => {
        let log_string = `${type} event - metadata unavailable`;

        let postBody = {
            action: "custom_audit",
            metadata: metadata,
            audit_event: type,
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

export function getPatrolHistory(track_id, min_mile, max_mile, min_yard, max_yard, elr, device_id) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_patrol_history",
            track_id,
            device_id,
            min_mile,
            max_mile,
            min_yard,
            max_yard,
            elr,
        };

        const url = "/routeMetadata";

        const currentHistoryLocation = getState().schemaInterface.history.position;

        if (!_.isEmpty(currentHistoryLocation)) {
            const startPositionYard = mileYardToYard(min_mile, min_yard);
            const endPositionYard = mileYardToYard(max_mile, max_yard);
            const middlePoint = (startPositionYard + endPositionYard) / 2;

            const currentHistoryStartYard = mileYardToYard(currentHistoryLocation.min_mile, currentHistoryLocation.min_yard);
            const currentHistoryEndYard = mileYardToYard(currentHistoryLocation.max_mile, currentHistoryLocation.max_yard);
            const currentMiddlePoint = (currentHistoryStartYard + currentHistoryEndYard) / 2;

            if (currentHistoryLocation.track_id === track_id && currentHistoryLocation.elr === elr && Math.abs(middlePoint - currentMiddlePoint) < 11) {
                return;
            }
        }

        dispatch({
            type: TOGGLE_FETCHING_PATROL_HISTORY,
            fetching: true,
        });

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.history) {
                    const sortedHistory = _.orderBy(
                        response.history,
                        (historySet) => {
                            return historySet.images[0].timestamp;
                        },
                        "desc",
                    );

                    dispatch({
                        type: RECEIVE_PATROL_HISTORY,
                        // history : response.history
                        history: sortedHistory,
                    });
                    dispatch({
                        type: RECEIVE_PATROL_LOCATION,
                        position: {
                            track_id,
                            device_id,
                            min_mile,
                            max_mile,
                            min_yard,
                            max_yard,
                            elr,
                        },
                    });
                }
            })
            .catch(() => {
                console.log("Error getting patrol history");
            });
    };
}

export function reportPatrolDiagramProblem(planID, issueType, issueDescription, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "bug_report",
            description: "Diagram Issue: " + issueDescription,
            metadata: {
                type: "Route patrol track diagram issue",
                planID,
                issueType,
            },
        };

        const url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Issue submitted" });
                    callback();
                } else {
                    notification.error({ message: "Error submitting report" });
                }
            })
            .catch((e) => {
                console.log("Error submitting report here", e);
                notification.error({ message: "Error submitting report" });
            });
    };
}

export function resetPatrol(patrolID, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "reset_patrol",
            patrol_id: patrolID,
        };

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    dispatch(clearPatrolState());
                }
                callback(response.success);
            })
            .catch((e) => {
                console.log("Error resetting patrol", e);
            });
    };
}

export function getPatrolPlans(page = 0) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_patrol_plans",
            page,
        };

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.plans) {
                    const currentPatrolPlans = getState().schemaInterface.patrolPlans;
                    const mergedPatrolPlans = [...currentPatrolPlans, ...response.plans];
                    dispatch({
                        type: RECEIVE_PATROL_PLANS,
                        plans: mergedPatrolPlans,
                    });

                    if (response.is_more) {
                        dispatch(getPatrolPlans(page + 1));
                    }
                }
            })
            .catch((e) => {
                notification.error({ message: "Error getting patrol plans" });
            });
    };
}

export function createPatrol(planID, name, startTimestamp, endTimestamp, deviceGroup, dueTimestamp, sendEmail, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "create_patrol",
            plan_id: planID,
            name,
            start_timestamp: startTimestamp,
            end_timestamp: endTimestamp,
            device_group: deviceGroup,
            due_timestamp: dueTimestamp,
            send_email: sendEmail,
        };

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("create patrol request", response);
                callback(response.success);
            })
            .catch((e) => {
                notification.error({ message: "Error creating patrol" });
            });
    };
}

export const createPatrolPlan = (name, svg) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const postBody = {
                action: "create_patrol_plan",
                name,
                svg,
            };

            let url = "/routeMetadata";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.pending_id) {
                        resolve(response.pending_id);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log("debug error creating patrol plan", error);
                    notification.error({ message: "Error creating patrol plan" });
                    reject();
                });
        });
    };
};

export const getPendingPatrol = (planID) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const postBody = {
                action: "get_pending_patrol_plan",
                id: planID,
            };

            let url = "/routeMetadata";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log("debug error getting pending patrol plan", error);
                    reject();
                });
        });
    };
};
