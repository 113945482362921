import React, { useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import _ from "lodash";
import { getEndCoordinate, getStartCoordinate, latLonToMeters, locationsAreEqual } from "../../../util/Geometry";
import { useSelector, useStore } from "react-redux";
import { LineSegment } from "./LineSegment";
import { Line } from "@react-three/drei";
import { videoTimeLookup } from "components/util/PlaylistUtils";

const videoDataSelector = (state) => {
    const playlistData = state.playlist.data;
    const sourceIndex = state.playlist.position.sourceIndex;
    return _.get(playlistData, ["video", sourceIndex], null);
};

const useSnappedSelector = (state) => state.snappedRoute || false;
const currentPositionSelector = (state) => state.playlist.position.coords;
const tunnelWidthSelector = (state) => _.get(state.playlist.overlays, ["Tunnel", "width"], 4000) / 1000;
const tunnelHeightSelector = (state) => _.get(state.playlist.overlays, ["Tunnel", "height"], 4000) / 1000;
const tunnelLengthSelector = (state) => _.get(state.playlist.overlays, ["Tunnel", "length"], 5);
const currentSessionTagsSelector = (state) => _.get(state.sessions[state.playlist.data.routeID], "tags", []);
const currentIndexSelector = (state) => _.get(state.playlist, ["position", "currentIndex"], null);

function ThreeDTunnel() {
    // Trigger updates on redux position state changes for this component only

    const store = useStore();

    const ref = useRef();
    const currentPositionRef = useRef(currentPositionSelector(store.getState()));

    const videoData = useSelector(videoDataSelector);
    const useSnapped = useSelector(useSnappedSelector);

    const tunnelHalfWidth = useSelector(tunnelWidthSelector) / 2;
    const tunnelHeight = useSelector(tunnelHeightSelector);
    const tunnelLength = useSelector(tunnelLengthSelector);
    const currentSessionTags = useSelector(currentSessionTagsSelector);
    const currentIndex = useSelector(currentIndexSelector);

    const sessionHasDirectionTag = useMemo(() => {
        return _.includes(currentSessionTags, "Forward") || _.includes(currentSessionTags, "Backward");
    }, [currentSessionTags]);

    const sessionIsBackward = useMemo(() => {
        return _.includes(currentSessionTags, "Backward");
    }, [currentSessionTags]);

    const { routeGeometry, zeroPoint } = useMemo(() => {
        console.log("Generating tunnel geometry...");

        const routeGeometry = [];
        let lastEndCoordinate = null;
        let zeroPoint = null;

        let indexesRange = [];
        if (sessionIsBackward) {
            indexesRange = _.range(currentIndex - tunnelLength, currentIndex);
        } else {
            indexesRange = _.range(currentIndex, currentIndex + tunnelLength);
        }

        const videoDataChank = _.filter(videoData, (data, index) => indexesRange.includes(index));
        videoDataChank.forEach((playlistItem) => {
            const startCoordinate = getStartCoordinate(playlistItem, useSnapped);
            const endCoordinate = getEndCoordinate(playlistItem, useSnapped);

            if (zeroPoint === null) {
                zeroPoint = startCoordinate;
            }

            if (lastEndCoordinate && !locationsAreEqual(startCoordinate, lastEndCoordinate)) {
                routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
            }
            if (startCoordinate) {
                routeGeometry.push(latLonToMeters(zeroPoint, startCoordinate));
            }
            lastEndCoordinate = endCoordinate;
        });
        if (lastEndCoordinate) {
            routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
        }

        return { routeGeometry, zeroPoint };
    }, [currentIndex, videoData, sessionIsBackward, tunnelLength, useSnapped]);

    const { blRail, brRail, tlRail, trRail, pillars } = useMemo(() => {
        const blRail = [];
        const brRail = [];
        const tlRail = [];
        const trRail = [];
        const pillars = [];

        if (routeGeometry.length < 2) {
            //do nothing
        } else {
            for (let i = 0; i < routeGeometry.length; i++) {
                let o0, o1, p;
                if (i === 0) {
                    o0 = p = routeGeometry[i];
                    o1 = routeGeometry[i + 1];
                } else {
                    o0 = routeGeometry[i - 1];
                    o1 = p = routeGeometry[i];
                }
                const angle = Math.atan2(o1[1] - o0[1], o1[0] - o0[0]);
                const sinAngle = Math.sin(angle);
                const cosAngle = Math.cos(angle);
                const xOffset = cosAngle * tunnelHalfWidth;
                const zOffset = -sinAngle * tunnelHalfWidth;

                if (i === 0) {
                    const startX = sinAngle * 25;
                    const startZ = cosAngle * 25;
                    const startBL = [-p[1] - xOffset + startX, 0, -p[0] - zOffset + startZ];
                    const startBR = [-p[1] + xOffset + startX, 0, -p[0] + zOffset + startZ];
                    const startTL = [-p[1] - xOffset + startX, tunnelHeight, -p[0] - zOffset + startZ];
                    const startTR = [-p[1] + xOffset + startX, tunnelHeight, -p[0] + zOffset + startZ];

                    blRail.push(startBL);
                    brRail.push(startBR);
                    tlRail.push(startTL);
                    trRail.push(startTR);

                    pillars.push(startBL, startTL, startTL, startTR, startTR, startBR);
                }

                const bottomLeft = [-p[1] - xOffset, 0, -p[0] - zOffset];
                const bottomRight = [-p[1] + xOffset, 0, -p[0] + zOffset];
                const topLeft = [-p[1] - xOffset, tunnelHeight, -p[0] - zOffset];
                const topRight = [-p[1] + xOffset, tunnelHeight, -p[0] + zOffset];

                blRail.push(bottomLeft);
                brRail.push(bottomRight);
                tlRail.push(topLeft);
                trRail.push(topRight);

                pillars.push(bottomLeft, topLeft, topLeft, topRight, topRight, bottomRight);

                if (i === routeGeometry.length - 1) {
                    const endX = -sinAngle * 25;
                    const endZ = -cosAngle * 25;
                    const startBL = [-p[1] - xOffset + endX, 0, -p[0] - zOffset + endZ];
                    const startBR = [-p[1] + xOffset + endX, 0, -p[0] + zOffset + endZ];
                    const startTL = [-p[1] - xOffset + endX, tunnelHeight, -p[0] - zOffset + endZ];
                    const startTR = [-p[1] + xOffset + endX, tunnelHeight, -p[0] + zOffset + endZ];

                    blRail.push(startBL);
                    brRail.push(startBR);
                    tlRail.push(startTL);
                    trRail.push(startTR);

                    pillars.push(startBL, startTL, startTL, startTR, startTR, startBR);
                }
            }
        }

        return { blRail, brRail, tlRail, trRail, pillars };
    }, [routeGeometry, tunnelHalfWidth, tunnelHeight]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
        });
    }, [store]);

    useFrame(() => {
        if (currentPositionRef.current && zeroPoint) {
            const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);
            if (ref.current) {
                ref.current.position.set(positionOffset[1], 0, positionOffset[0]);
            }
        }
    });

    const blLine = useMemo(() => {
        return (
            <Line
                points={blRail}
                color={"red"}
            />
        );
    }, [blRail]);

    const brLine = useMemo(() => {
        return (
            <Line
                points={brRail}
                color={"red"}
            />
        );
    }, [brRail]);

    const tlLine = useMemo(() => {
        return (
            <Line
                points={tlRail}
                color={"red"}
            />
        );
    }, [tlRail]);

    const trLine = useMemo(() => {
        return (
            <Line
                points={trRail}
                color={"red"}
            />
        );
    }, [trRail]);

    const pillarsLine = useMemo(() => {
        return (
            <LineSegment
                points={pillars}
                color={"white"}
            />
        );
    }, [pillars]);

    if (sessionHasDirectionTag && routeGeometry.length) {
        return (
            <group ref={ref}>
                {blLine}
                {brLine}
                {tlLine}
                {trLine}
                {pillarsLine}
            </group>
        );
    } else {
        return null;
    }
}

export default ThreeDTunnel;
