import React from "react";
import { connect } from "react-redux";
import { sticky } from "tippy.js";
import _ from "lodash";
import { Marker } from "react-leaflet";
import { createCustomIcon, createDefaultIcon } from "./Icons";
import { getMarkersForSession, logEvent, routeSelected, selectMarker, selectTag } from "redux/actions/index";
import { LazyMapTooltip } from "../util/LazyTooltips";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import { filterMarkers, filterMarkersByObservations } from "../util/PlaylistUtils";
import memoizeOne from "memoize-one";

const customMarkerDict = {};

class Markers extends React.PureComponent {
    componentDidMount() {
        this.updateMarkers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sessionID !== prevProps.sessionID) {
            this.updateMarkers();
        }
    }

    updateMarkers = () => {
        if (this.props.markers === null && !window.location.href.includes("widget")) {
            this.props.dispatch(getMarkersForSession(this.props.sessionID));
        }
    };

    onDatapointMarkerClick = (videoKey, sessionID, markerName, frame) => {
        console.log("Marker click");
        this.props.dispatch(logEvent("Marker", "Click", markerName));
        this.props.dispatch(routeSelected(sessionID, videoKey, undefined, undefined, undefined, frame));
    };

    onPointCloudClick = (videoKey, sessionID, markerName, modelName) => {
        this.props.dispatch(logEvent("Marker", "Click", markerName));
        this.props.dispatch(routeSelected(sessionID, videoKey));
        if (modelName && modelName.length > 0) {
            window.open(`https://pointcloud${MEMOIZED_DOMAIN_URL}/#model=` + modelName, "_aivrpointcloud_" + modelName);
        }
    };

    renderMarkerTooltip = memoizeOne((marker, sessions) => {
        let session = sessions[marker.session_id];
        if (!session) {
            return null;
        }
        let snapshotBaseURL = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_uuid}/${session.uuid}/`;

        //If marker.custom_thumbnail is set, use that instead.
        let imageURL;
        let defaultImageUrl;
        if (marker.custom_thumbnail) {
            imageURL = marker.custom_thumbnail;
            defaultImageUrl = marker.custom_thumbnail;
        } else {
            if (marker.frame > 1) {
                imageURL = `${snapshotBaseURL}${marker.video_key}-${marker.frame}.jpg`;
                defaultImageUrl = `${snapshotBaseURL}${marker.video_key}.jpg`;
                if (this.props.csrfToken) {
                    snapshotBaseURL += `?csrf=${this.props.csrfToken}`;
                }
            } else {
                imageURL = `${snapshotBaseURL}${marker.video_key}.jpg`;
                defaultImageUrl = `${snapshotBaseURL}${marker.video_key}.jpg`;
                if (this.props.csrfToken) {
                    snapshotBaseURL += `?csrf=${this.props.csrfToken}`;
                }
            }
        }

        let extraDataPanel = null;

        if (marker.custom_data) {
            if (marker.custom_data.custom_type === "fields") {
                const fields = _.map(marker.custom_data.fields, (value, key) => {
                    // hide keys with _ and ~ from tooltip for marker
                    if (!["_", "~"].includes(key.charAt(0))) {
                        return (
                            <tr>
                                <td className={"Key"}>{key}:</td>
                                <td className={"Value"}>{value}</td>
                            </tr>
                        );
                    }
                });
                extraDataPanel = (
                    <table className={"MarkerTooltipAdditionalInfo"}>
                        <thead>
                            <tr>
                                <th colSpan={2}>Additional Data:</th>
                            </tr>
                        </thead>
                        <tbody>{fields}</tbody>
                    </table>
                );
            } else {
                //Add handlers for other types of custom data here
            }
        }

        return (
            <div className={"MarkerTooltipOuter"}>
                <div className={"MarkerTooltipPrimaryInfo"}>
                    <span className={"MarkerTooltipTitle"}>{marker.name}</span>
                    <img
                        className={"MarkerTooltipImage"}
                        src={imageURL}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImageUrl;
                        }}
                        crossOrigin={"anonymous"}
                        alt=""
                    />
                    <span className={"MarkerTooltipSource"}>Source: {marker.source.toLowerCase()}</span>
                </div>
                {extraDataPanel}
            </div>
        );
    });

    markerClick = (marker) => {
        console.log("marker has been clicked: ", marker);
        this.props.dispatch(selectMarker(marker));
        this.props.dispatch(selectTag(marker.name));
        this.props.dispatch(routeSelected(marker.session_id, marker.video_key, undefined, true, undefined, marker.frame));
    };

    renderMarker = (marker) => {
        let selected = false;

        if (this.props.selectedMarker && this.props.selectedMarker.id === marker.id) {
            selected = true;
        }

        let markerIconToUse = createDefaultIcon(selected);

        let annotationType = _.find(this.props.annotationTypes, function (ann) {
            return ann.type === marker.name;
        });

        if (annotationType) {
            if (annotationType.display_type) {
                if (annotationType.display_type === "never") {
                    return null;
                }

                if (annotationType.display_type === "type_selected" && marker.name !== this.props.selectedTagCategory) {
                    return null;
                }
            }

            if (annotationType.icon_type) {
                let cacheIconName = annotationType.icon_type + annotationType.icon_colour + (selected ? "_selected" : "");

                if (!(cacheIconName in customMarkerDict)) {
                    customMarkerDict[cacheIconName] = createCustomIcon(annotationType.icon_type, annotationType.icon_colour, selected);
                }

                markerIconToUse = customMarkerDict[cacheIconName];
            }
        } else {
            return null;
        }

        let onClickFunction = _.partial(this.markerClick, marker);

        if (marker.source === "POINTCLOUD") {
            onClickFunction = _.partial(this.onPointCloudClick, marker.video_key, marker.session_id, marker.name, marker.custom_data.model);
        }

        let markerCoords = marker.coords;

        if (this.props.useSnapped) {
            if (marker.snappedCoords) {
                markerCoords = marker.snappedCoords;
            }
        }

        return (
            <Marker
                // className={'div-icon' + (selected ? ' selected' : '')}
                className="testing"
                key={"Marker" + marker.id}
                position={markerCoords}
                icon={markerIconToUse}
                onClick={onClickFunction}>
                <LazyMapTooltip
                    className="Incident-Tooltip"
                    permanent={false}
                    interactive={true}
                    direction={"top"}
                    sticky={true}
                    plugins={[sticky]}>
                    {this.renderMarkerTooltip(marker, this.props.sessions)}
                </LazyMapTooltip>
            </Marker>
        );
    };

    render() {
        let markers = this.props.markers || [];
        let { selectedTagCategory, markerReviewFilters, annotationTypes, thresholdFilters } = this.props;

        if (!this.props.showHidden) {
            markers = filterMarkers(
                markers,
                selectedTagCategory,
                markerReviewFilters,
                annotationTypes,
                thresholdFilters,
                false,
                this.props.defaultMarkersThreshold,
            );
        }

        markers = markers.filter((marker) => {
            let markerCoords = marker.coords;

            if (this.props.useSnapped) {
                if (marker.snappedCoords) {
                    markerCoords = marker.snappedCoords;
                }
            }

            return (
                markerCoords[0] < this.props.mapBounds.current.north &&
                markerCoords[0] > this.props.mapBounds.current.south &&
                markerCoords[1] < this.props.mapBounds.current.east &&
                markerCoords[1] > this.props.mapBounds.current.west
            );
        });

        return <React.Fragment>{markers.map(this.renderMarker)}</React.Fragment>;
    }
}

const mapStateToProps = (state, ownProps) => {
    let markers;
    if (ownProps.sessionID === undefined || ownProps.sessionID === null) {
        markers = state.markers.alwaysShown;
    } else {
        markers = _.get(state.markers.perSession, [ownProps.sessionID], null);
        if (markers && markers.length) {
            markers = filterMarkersByObservations(markers, state.sessionObservations);
        }
    }

    return {
        markers,
        markerReviewFilters: state.markerReviewFilters,
        sessions: state.sessions,
        selectedTagCategory: state.selectedTagCategory,
        annotationTypes: state.userAnnotationTypes,
        mapBounds: state.sessionFilters.mapBounds,
        useSnapped: state.snappedRoute || false,
        selectedMarker: state.markers.selectedMarker,
        thresholdFilters: state.markerThresholdFilters,
        defaultMarkersThreshold: state.defaultMarkersThresholds[ownProps.sessionID],
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(Markers);
