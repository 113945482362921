import React from "react";
import "../aivr.scss";
import logo from "../images/obc-logo-hybrid.svg";

const PrivacyComponent = () => {
    return (
        <div className="PrivacyPolicy">
            <div className="PrivacyPolicyContainer">
                <div className="PrivacyPolicyLogoContainer">
                    <img
                        className="PrivacyPolicyLogo"
                        src={logo}
                        alt=""
                    />
                    <h3 className="PrivacyPolicyLogoText">OneBigCircle</h3>
                </div>
                <h1 className="PrivacyPolicyHeading">Privacy Policy</h1>

                <h3 className="PrivacyPolicySubHeading">Website Privacy Policy</h3>
                <p className="PrivacyPolicyText">
                    One Big Circle is the business name for the legal practice carried on by One Big Circle Ltd. References on this website to “One Big Circle”,
                    “we” or “us” are to One Big Circle Ltd.
                </p>

                <h3 className="PrivacyPolicySubHeading">About this Privacy Notice</h3>
                <p className="PrivacyPolicyText">
                    This privacy notice describes how One Big Circle collects and uses your personal information during and after your relationship with us.
                </p>
                <p className="PrivacyPolicyText">
                    We are a “controller”. This means that we are responsible for deciding how we hold and use personal information about you, and for
                    explaining this clearly to you.
                </p>
                <p className="PrivacyPolicyText">
                    This notice applies to prospective, existing and former customers of the company (including employees and representatives of our customers);
                    individual and business contacts and prospects; any person who provides services to us – either as an individual or as the employee or
                    representative of a corporate service provider; and users of this website www.onebigcircle.co.uk.
                </p>
                <p className="PrivacyPolicyText">
                    It is important that you read this notice, together with any other privacy information or notices we may provide on specific occasions when
                    we are collecting or processing personal information about you, so that you are aware of how and why we are using such information.
                </p>

                <h3 className="PrivacyPolicySubHeading">Who we are</h3>
                <p className="PrivacyPolicyText">
                    One Big Circle Ltd is a company registered in England and Wales (registration number 11022970), whose registered office is at Unit 7 & 8,
                    Temple Studios, Lower Approach Road, Bristol, BS16QA. We respect your right to privacy and will only process personal information you
                    provide to us in accordance with all relevant laws that relate to Data Protection & Privacy laws, including the EU and UK General Data
                    Protections Regulation (GDPR). We are registered with the Information Commissioner’s Office (ICO) reference ZA301643. If you have any
                    questions about how we collect, store and use personal information, or if you have any other privacy-related questions, please contact us at
                    info@onebigcircle.co.uk.
                </p>

                <h3 className="PrivacyPolicySubHeading">A quick word about children</h3>
                <p className="PrivacyPolicyText">
                    We do not and will not knowingly collect information from any unsupervised child under the age of 18. If you are under the age of 18, you
                    may not use this website unless you have the consent of, and are supervised by, a parent or guardian.
                </p>

                <h3 className="PrivacyPolicySubHeading">Video content</h3>
                <p className="PrivacyPolicyText">
                    Video content accessible through any of One Big Circle’s products and platforms is subject to separate licence terms and conditions agreed
                    with our customers. Individual users are provided with confidential log-in details for a secure licence to access this content and use the
                    digital tools provided.
                </p>
                <p className="PrivacyPolicyText">All content is accessed and viewed for the express purpose agreed with our customers.</p>
                <p className="PrivacyPolicyText">
                    Users/Licence holders are not permitted to share content outside their organisation or to share in the public domain unless a specific
                    agreement is made with One Big Circle. Any content that does have agreement to be shared must not contain identifiable images of people
                    unless explicit agreement is made concerning the subject footage.
                </p>

                <h3 className="PrivacyPolicySubHeading">Personal information</h3>
                <p className="PrivacyPolicyText">
                    As used in this privacy policy, the term ‘personal information’ means information that specifically identifies an individual (including, but
                    not limited to, your name and social networking information) or information about an individual that is directly linked to personally
                    identifiable information. Personal information does not include ‘aggregate’ information, which is data we collect about the use of this
                    website or about a group or category of users, from which individual identities or other personal information has been removed. This privacy
                    policy in no way restricts or limits our collection and use of aggregate information.
                </p>

                <h3 className="PrivacyPolicySubHeading">Information we collect and process</h3>
                <p className="PrivacyPolicyText">
                    We collect personal information directly from you when you inquire about our products and services, when we establish you as a customer,
                    where we enter into a contract to receive services from you, or when you use our site.
                </p>
                <p className="PrivacyPolicyText">We collect further information from you during the period of our relationship.</p>
                <p className="PrivacyPolicyText">In summary, we use your personal data for the following purposes:</p>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        to carry out obligations arising from any contracts between us and you; with our customers; with suppliers; or with third parties;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        to help us comply with our legal obligations (for example, Health and Safety laws if you or a visitor is hurt on our premises),
                    </li>
                    <li className="PrivacyPolicyListItem">to communicate with you, including to provide you with information, products or services; and</li>
                    <li className="PrivacyPolicyListItem">where it is necessary for our business or our customers’ business.</li>
                </ul>
                <p className="PrivacyPolicyText">We set out below further information and our lawful basis for processing such information.</p>

                <h3 className="PrivacyPolicySubHeading">Customers:</h3>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        We collect and process your name, email address, telephone number, address and bank details so we can provide products and services to
                        you. Such processing is necessary for performance of the contract between us.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We may also process feedback you provide to us on our products and services. Such processing is necessary for the legitimate interest of
                        managing our business and improving our products and services.
                    </li>
                </ul>

                <h3 className="PrivacyPolicySubHeading">Suppliers, third party business contacts and prospects:</h3>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        We process names, job titles and business contact information including addresses, telephone numbers and email addresses for your
                        employees and representatives and other information to maintain and manage our relationship with you. Such processing is necessary for
                        the performance of the contract between us.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        Where you are an employee of a corporate client or contractor, we process this information for the legitimate interest of performing our
                        contract with your employer.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        If there is a problem, for example, if we have a complaint about a service you have provided then we have a legitimate interest in using
                        personal information in connection with that complaint, for example, so that we can tell you what happened so that you can put things
                        right.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We may perform due diligence in the form of credit checks on companies, including checking photographic identification and proof of
                        address of directors and, in some cases, your shareholders, and verification of the company registration details. We do this to comply
                        with our legal obligations, and as necessary for our legitimate interests of checking that the entities we contract with are financially
                        viable and would not cause us reputational damage.
                    </li>
                </ul>

                <h3 className="PrivacyPolicySubHeading">Users of our site:</h3>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        When you browse our site or purchase products through our site we may collect information about your preferences and the types of
                        products you are interested in.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We also collect technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your
                        login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We also collect information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our
                        site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain
                        pages, page interaction information (such as scrolling, clicks, and mouse- overs), and methods used to browse away from the page and any
                        phone number used to call our customer service number. This is for our legitimate interest in giving you content which is bespoke to you
                        and making sure that our website runs smoothly.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        Regardless of the relationship we have with you, we may need to use your personal information for legal reasons such as fraud prevention
                        and to check that you are complying with our terms and conditions and to enforce our legal rights.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        Please note that we may process your personal information without your knowledge or consent where this is required or permitted by law.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We may contact you about our products and services by email, telephone, post or by text message but we will only do this where we are
                        allowed to under data protection law. If you tell us that you do not want to be contacted for any of these purposes then we will of
                        course respect that. Sometimes we will need your consent before contacting you for these reasons. If you give us your consent then you
                        have a right to withdraw that consent at any time. Any use of your personal information before you withdraw consent remains valid.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        We may take photographs and videos of events taking place that relate to our products for use in connection with publicity. This will
                        include publications, social media and on our website. We will seek specific consent for such use. If there is any reason why we should
                        not take such photographs or videos, or if you have any concerns or objections to this, please let us know by any convenient means of
                        communication.
                    </li>
                </ul>

                <h3 className="PrivacyPolicySubHeading">Information we receive from other sources</h3>
                <p className="PrivacyPolicyText">
                    We may receive information about you if you use any of the other websites we operate or the other services we provide. In this case we will
                    have informed you when we collected that data that it may be shared internally and combined with data collected on this site. We are also
                    working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services,
                    advertising networks, analytics providers, search information providers) and may receive information about you from them.
                </p>

                <h3 className="PrivacyPolicySubHeading">What personal information do we share with Third Parties</h3>
                <p className="PrivacyPolicyText">
                    We recommend you be cautious about giving out personal information to others or sharing personal information in public or private online
                    forums. We will not knowingly share the personal information we collect from you through the website with third parties, except as described
                    in this privacy policy. For example, we may share personal information as follows:
                </p>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        with business partners, vendors, consultants, sub-contractors, and other service providers for the performance of any contract we enter
                        into with them or you;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        with analytics and search engine providers that assist us in the improvement and optimisation of our site;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        with vendors, consultants, and other service providers (‘Service Providers’) who are engaged by or working with us in connection with
                        the operation of this website and who need access to such information to carry out their work for us;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        when you give us your consent to do so (including, without limitation, if we notify you via the website or by email that the information
                        you provide will be shared in a particular manner and you provide such information);
                    </li>
                    <li className="PrivacyPolicyListItem">in an aggregated or anonymised form that does not directly identify you;</li>
                    <li className="PrivacyPolicyListItem">
                        when we believe in good faith that we are lawfully authorised or required to do so or that doing so is reasonably necessary or
                        appropriate to comply with the law or legal processes or respond to lawful requests or legal authorities, including responding to lawful
                        subpoenas, warrants, or court orders;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        when we believe in good faith that doing so is reasonably necessary or appropriate to protect the rights, property, or safety of OBC,
                        our users, our employees, copyright owners, third parties or the public including without limitation to protect OBC or our users from
                        fraudulent or unlawful use of the website;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        to enforce or apply this privacy policy, our terms and conditions of use, our loyalty scheme terms and conditions, or our other policies
                        or agreements; and
                    </li>
                    <li className="PrivacyPolicyListItem">
                        in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation
                        where personal information may be disclosed or transferred as one of the business assets of OBC.
                    </li>
                    <li className="PrivacyPolicyListItem">
                        with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section
                        1159 of the UK Companies Act 2006;
                    </li>
                </ul>
                <p className="PrivacyPolicyText">
                    You understand that when you use the website, information you post on the website, information you provide via the use of the website, as
                    well as any information you share with individuals through the website will be available to other users. All users will agree to the Terms
                    of Use when accessing the website but we always recommend you be cautious about giving out personal information to others or sharing
                    personal information in public or private online forums. We are not responsible for the actions of external Service Providers nor are we
                    responsible for any additional information you provide directly to any other users or third parties, and we encourage you to become familiar
                    with other entities and individuals privacy practices. Nothing herein restricts One Big Circle producing Model Data from the data that is
                    shared. Model Data means a dataset produced by aggregating anonymised versions of the data with equivalent datasets from One Big Circles
                    other customers and external data sources.
                </p>

                <h3 className="PrivacyPolicySubHeading">Cookies</h3>
                <p className="PrivacyPolicyText">
                    A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies
                    contain information that is transferred to your computer’s hard drive. Our website uses cookies to distinguish you from other users of the
                    website. This helps us to provide you with a good experience when you browse the website and also allows us to improve our site. We also use
                    cookies which collect information about your browsing habits in order to try and display advertising which is relevant to you and your
                    interests. By continuing to browse the site, you are agreeing to our use of cookies. We use the following cookies:
                </p>
                <p className="PrivacyPolicyText">
                    <span className="PrivacyPolicyBold">Strictly necessary cookies. </span>These are cookies that are required for the operation of our website.
                    They include, for example, cookies that enable you to log into secure areas of the website.
                </p>
                <p className="PrivacyPolicyText">
                    <span className="PrivacyPolicyBold">Analytical/performance cookies. </span>They allow us to recognise and count the number of visitors and
                    to see how visitors move around the website when they are using them. This helps us to improve the way the website works, for example, by
                    ensuring that users are finding what they are looking for easily.
                </p>
                <p className="PrivacyPolicyText">
                    <span className="PrivacyPolicyBold">Functionality cookies. </span>These are used to recognise you when you return to the website. This
                    enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or
                    region).
                </p>
                <p className="PrivacyPolicyText">
                    <span className="PrivacyPolicyBold">Targeting cookies. </span>These cookies record your visit to the website, the pages you have visited and
                    the links you have followed. We will use this information to make the website and the advertising displayed on it more relevant to your
                    interests. We may also share this information with third parties for this purpose. You can block cookies by activating the setting on your
                    browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies
                    (including essential cookies) you may not be able to access all or parts of our site. Log files, and passive information collection As you
                    use the website certain information can be collected using other technologies such as cookies, clicktags, beacons and server logs. Your
                    browser automatically transmits some information such as the URL of the website you came from, your IP address, and the type of browser you
                    are using.
                </p>

                <h3 className="PrivacyPolicySubHeading">Where will we store your personal information?</h3>
                <p className="PrivacyPolicyText">
                    Any personal information that you submit to us will be held on secure servers, based within the UK or the European Economic Area (EEA). We
                    may send your information to countries which do not have the same level of protection for personal information as there is in the UK. For
                    example, we may:
                </p>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">store your information on cloud computer storage based overseas; or</li>
                    <li className="PrivacyPolicyListItem">
                        communicate with you using your work email address when you are overseas (for example, when you are on holiday) or to organise an
                        overseas school trip.
                    </li>
                </ul>
                <p className="PrivacyPolicyText">
                    In those cases we will comply with applicable UK and/or EEA laws designed to ensure the privacy of your personal data.
                </p>
                <p className="PrivacyPolicyText">
                    If the country that we are sending your information to has not been found by the UK Government to ensure an adequate level of protection of
                    personal data (known as an <span className="PrivacyPolicyBold">‘adequacy regulation’</span>), then we will ensure that either (i) there are
                    appropriate safeguards in place, together with enforceable rights and effective legal remedies for you, or (ii) a specific exception applies
                    under relevant Data Protection & Privacy laws.
                </p>
                <p className="PrivacyPolicyText">We will provide you with details about the safeguards which we have in place on request.</p>

                <h3 className="PrivacyPolicySubHeading">How long will we use your personal information for?</h3>
                <p className="PrivacyPolicyText">
                    We only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of
                    satisfying any legal, accounting, or reporting requirements.
                </p>
                <p className="PrivacyPolicyText">
                    To determine the appropriate retention period for personal information, we consider the amount, nature and sensitivity of the personal
                    information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process
                    your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
                </p>

                <h3 className="PrivacyPolicySubHeading">Your rights in relation to our processing of your personal information</h3>
                <p className="PrivacyPolicyText">You have the right to:</p>
                <ul className="PrivacyPolicyList">
                    <li className="PrivacyPolicyListItem">
                        be informed about what we are doing with your personal information – we do this by providing you with this privacy notice;
                    </li>
                    <li className="PrivacyPolicyListItem">object to the processing of your personal information;</li>
                    <li className="PrivacyPolicyListItem">
                        request a copy of the personal information we hold about you and to check that we are lawfully processing it;
                    </li>
                    <li className="PrivacyPolicyListItem">request correction of the personal data that we hold about you if it is incorrect;</li>
                    <li className="PrivacyPolicyListItem">
                        ask us to delete the information that we hold about you where there is no good reason for us continuing to process it;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        to ask us to stop processing personal information where we are relying on a legitimate interest and there is something about your
                        particular situation which makes you want to object to processing on this ground;
                    </li>
                    <li className="PrivacyPolicyListItem">
                        ask us to restrict how we use your personal information for a period of time if you claim that it is inaccurate and we want to verify
                        the position, or if our processing is unlawful but you do not want us to erase your personal information, or for some other limited
                        circumstances; and
                    </li>
                    <li className="PrivacyPolicyListItem">
                        ask us to send another organisation information that you have provided to us in a format that can be read by computer.
                    </li>
                </ul>
                <p className="PrivacyPolicyText">
                    If you want to exercise any of your rights, please write to{" "}
                    <span className="PrivacyPolicyBold">One Big Circle, Unit 7 & 8, Temple Studios, Lower Approach Road, Bristol, BS16QA.</span>
                </p>
                <p className="PrivacyPolicyText">
                    We may need to request specific information from you to help us confirm your identity and ensure you right to access the information (or to
                    exercise any of your other rights).
                </p>
                <p className="PrivacyPolicyText">
                    If you ask us to restrict our use of your personal information or delete it, we will not be able to provide you with our products or
                    services.
                </p>
                <p className="PrivacyPolicyText">
                    If you consider that we have not acted properly when using your personal information, you can contact the Information Commissioner’s Office:{" "}
                    <a
                        className="PrivacyPolicyLink"
                        href="https://ico.org.uk/global/contact-us">
                        https://ico.org.uk/global/contact-us/
                    </a>
                    .
                </p>

                <h3 className="PrivacyPolicySubHeading">Security</h3>
                <p className="PrivacyPolicyText">
                    Whilst we take appropriate technical and organisational measures to safeguard the personal information that you provide to us, no
                    transmission over the Internet can ever be guaranteed secure. Consequently, please note that we cannot guarantee the security of any
                    personal information that you transfer over the Internet to us. We have put in place suitable physical and electronic procedures to
                    safeguard and secure the information we collect online.
                </p>

                <h3 className="PrivacyPolicySubHeading">Questions?</h3>
                <p className="PrivacyPolicyText">
                    If you have any questions about this privacy policy, please contact us at{" "}
                    <span className="PrivacyPolicyBold">enquiries@onebigcircle.co.uk</span>. To make a request for the data we hold on you please email{" "}
                    <span className="PrivacyPolicyBold">enquiries@onebigcircle.co.uk</span> or contact us on{" "}
                    <span className="PrivacyPolicyBold">0845 838 7178</span>. To the extent permitted under the relevant Data Protection & Privacy laws, we may
                    charge a fee for completing this and will provide details for payment once we receive your request.
                </p>
            </div>
        </div>
    );
};

export default PrivacyComponent;
