import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { faStepBackward, faStepForward, faTimes, faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateNext, calculatePrevious, getCurrentVideoKey, getCurrentFrame } from "components/util/PlaylistUtils";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { requestPlaylistPosition, toggleFullscreen } from "../../../redux/actions/index";
import { Slider } from "antd";
import ZoomableImage from "components/ZoomableImage";

class ZoomInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageData: null,
            zoom: 1,
        };
    }

    navigate = (nav) => {
        if (nav === null) {
            return;
        }
        const [newKeyIndex, offset] = nav;
        console.log("Requesting index/offset:", newKeyIndex, offset);
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, newKeyIndex, offset),
        );
    };

    navigateNext = () => this.navigate(calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePrevious = () => this.navigate(calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    get isNextDisabled() {
        return calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }
    get isPreviousDisabled() {
        return calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    get imageURL() {
        if (this.props.index !== -1) {
            let selectedKey = this.props.imageKeys[this.props.index];
            if (selectedKey) {
                let imageKey = selectedKey[0];
                const frameCount = selectedKey[5];
                if (frameCount) {
                    imageKey += "-" + this.props.frame;
                }
                imageKey += ".jpg";

                let imageURL = this.props.baseURL + imageKey;
                if (this.props.csrfToken) {
                    imageURL += `?csrf=${this.props.csrfToken}`;
                }
                return imageURL;
            }
        }
        return null;
    }

    render() {
        let brightness = 0;
        let contrast = 0;
        if (this.props.currentStillImageAdjustments[this.props.sourceIndex] && this.props.currentStillImageAdjustments[this.props.sourceIndex].brightness) {
            brightness = this.props.currentStillImageAdjustments[this.props.sourceIndex].brightness * 0.6;
        }
        if (this.props.currentStillImageAdjustments[this.props.sourceIndex] && this.props.currentStillImageAdjustments[this.props.sourceIndex].contrast) {
            contrast = this.props.currentStillImageAdjustments[this.props.sourceIndex].contrast * 0.6;
        }

        return (
            <div className={"AnnotationFrame " + (this.props.fullscreen ? "Fullscreen" : "")}>
                <div className="TopToolbar TopToolbar--annotation">
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon" + (this.isPreviousDisabled ? " Disabled" : "")}
                            onClick={this.navigatePrevious}>
                            <FontAwesomeIcon icon={faStepBackward} /> Previous Frame
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon" + (this.isNextDisabled ? " Disabled" : "")}
                            onClick={this.navigateNext}>
                            <FontAwesomeIcon icon={faStepForward} /> Next Frame
                        </span>
                    </div>

                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon"}
                            onClick={() => this.props.onClose()}>
                            <FontAwesomeIcon icon={faTimes} /> Close
                        </span>
                    </div>
                </div>

                <div className="AnnotationImageFrame">
                    <div className={"FullScreenIconOverlay Top Right"}>
                        <div
                            className="Image-Fullscreen-Icon"
                            onClick={() => this.props.dispatch(toggleFullscreen())}>
                            <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                        </div>
                    </div>

                    <div className="ZoomSlider">
                        <p>Zoom</p>
                        <Slider
                            step={0.1}
                            tooltipVisible={false}
                            min={1}
                            max={20}
                            value={this.state.zoom}
                            onChange={(value) => this.setState({ zoom: value })}
                        />
                    </div>

                    <ZoomableImage
                        filters={{ brightness, contrast }}
                        imgSrc={this.imageURL}
                        zoom={this.state.zoom}
                        updateZoom={(zoom) => this.setState({ zoom: zoom })}
                    />
                </div>
                <div className="BottomToolbar BottomToolbar--markup" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const baseURL = _.get(state.playlist.data, ["mpdURLs", `snapshots`]);
    const imageKeys = _.get(state.playlist.data, ["video", sourceIndex], []);
    const index = state.playlist.position.currentIndex;
    const videoKey = getCurrentVideoKey(state.playlist);
    const frame = getCurrentFrame(state.playlist);
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);
    const currentIndex = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset;
    const currentStillImageAdjustments = state.playlist.stillImageAdjustments;

    return {
        sourceIndex,
        baseURL,
        videoKey,
        imageKeys,
        index,
        frame,
        currentIndex,
        video,
        offset,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        fullscreen: state.fullscreen,
        currentStillImageAdjustments,
        csrfToken: state.csrfToken,
    };
};
export default connect(mapStateToProps)(ZoomInterface);
