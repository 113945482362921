import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker } from "react-leaflet";
import { MarkerIcon, createCustomIcon } from "./Icons";
import { selectAsset, selectIssue, selectMarker } from "redux/actions";
import _ from "lodash";
import { LazyMapTooltip } from "components/util/LazyTooltips";
import { RawImageComponent } from "components/RawImageComponent";

const currentTabSelector = (state) => state.currentTab;
const selectedIssueSelector = (state) => state.issues.selected;
const selectedAssetSelector = (state) => state.mlAssets.selected;
const issueGroupsSelector = (state) => state.issues.groups;
const assetGroupsSelector = (state) => state.mlAssets.groups;

const customMarkerDict = {
    color: "",
};

const MlAssetMarker = ({ asset, displayingAsNearby = false, isAsset }) => {
    const dispatch = useDispatch();

    const currentTab = useSelector(currentTabSelector);
    const selectedIssue = useSelector(selectedIssueSelector);
    const selectedAsset = useSelector(selectedAssetSelector);
    const assetGroups = useSelector(assetGroupsSelector);
    const issueGroups = useSelector(issueGroupsSelector);

    const iconClick = () => {
        if (displayingAsNearby) {
            dispatch(selectAsset(asset.id));
            dispatch(selectMarker(asset));
        } else {
            if (currentTab === "assets") {
                dispatch(selectAsset(asset.id));
            } else {
                dispatch(selectIssue(asset.id));
            }
        }
    };

    const createTooltip = (asset) => {
        if (asset.summary_data) {
            return (
                <div className={"MarkerTooltipOuter"}>
                    <RawImageComponent
                        alt="Asset"
                        className="MarkerToolTipImage"
                        src={_.get(asset.summary_data, ["images", "thumbnail"])}
                        crossOrigin={"anonymous"}
                    />
                </div>
            );
        }
        return null;
    };

    const icon = useMemo(() => {
        let markerIconToUse = MarkerIcon;
        if (asset) {
            let cacheIconName = asset.display_icon || "exclamation-triangle";
            if (isAsset) {
                if (asset.assetGroup) {
                    const assetGroup = _.get(assetGroups, asset.assetGroup);
                    if (assetGroup !== undefined && assetGroup.hasOwnProperty("display_icon")) {
                        cacheIconName = assetGroup.display_icon;
                    }
                }
            } else {
                if (asset.issue_group_id) {
                    const issueGroup = _.get(issueGroups, asset.issue_group_id);
                    if (issueGroup !== undefined && issueGroup.hasOwnProperty("display_icon")) {
                        cacheIconName = issueGroup.display_icon;
                    }
                }
            }
            let markerColor = asset.observation_type || asset.observation_type === 0 ? "red" : "#6C43DF";
            if (asset.assetObservationType === "magic") {
                markerColor = "#6C43DF";
            }
            if (asset.assetObservationType === "external") {
                markerColor = "orange";
            }
            if (asset.assetObservationType === "magicAndExternal") {
                markerColor = "green";
            }

            if (!(cacheIconName in customMarkerDict) || customMarkerDict["color"] !== markerColor) {
                customMarkerDict[cacheIconName] = createCustomIcon(cacheIconName, markerColor, false);
                customMarkerDict["color"] = markerColor;
            }
            markerIconToUse = customMarkerDict[cacheIconName];
        }
        return markerIconToUse;
    }, [asset, assetGroups, isAsset, issueGroups]);

    let opacity = 0.3;
    let markerToolTip = createTooltip(asset);

    if (!selectedAsset.asset && !selectedIssue.issue) {
        opacity = 1;
    }

    if (selectedAsset.asset && parseInt(selectedAsset.asset) === asset.id) {
        opacity = 1;
    }

    if (selectedIssue.issue && parseInt(selectedIssue.issue) === asset.id) {
        opacity = 1;
    }

    return (
        <Marker
            onClick={iconClick}
            key={"Marker" + asset.id}
            position={[asset.coords[1], asset.coords[0]]}
            weight={50}
            opacity={opacity}
            icon={icon}>
            {markerToolTip && (
                <LazyMapTooltip
                    className="Incident-Tooltip"
                    permanent={false}
                    interactive={true}
                    direction={"top"}
                    offset={[0, -30]}>
                    {markerToolTip}
                </LazyMapTooltip>
            )}
        </Marker>
    );
};

export default MlAssetMarker;
