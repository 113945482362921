import React, { useState, useEffect } from "react";
import { Modal, Select, InputNumber, Switch, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addTrainingToDriver, getDriverTrainingData } from "redux/actions/driverTrainingActions";
import moment from "moment";
import _ from "lodash";
const { Option } = Select;

const ADD_NEW_TRAINING_FORM_INITIAL_DATA = {
    trainingList: undefined,
    interval: undefined,
    customInterval: undefined,
    lastTrainingCompletedTs: undefined,
    nextTrainingTs: undefined,
};

const availableTrainingCategoriesListSelector = (state) => state.driverTraining.admin.availableTrainingCategoriesList;
const drvierTrainingDataSelector = (state) => state.driverTraining.admin.drvierTrainingData;

const AddNewTrainingModal = ({ addTrainingModalOpen, setAddTrainingModalOpen, driverData }) => {
    const dispatch = useDispatch();

    const availableTrainingCategoriesList = useSelector(availableTrainingCategoriesListSelector);
    const drvierTrainingData = useSelector(drvierTrainingDataSelector);

    const [addTrainingErrors, setAddTrainingErrors] = useState(ADD_NEW_TRAINING_FORM_INITIAL_DATA);
    const [addTrainingData, setAddTrainingData] = useState(ADD_NEW_TRAINING_FORM_INITIAL_DATA);

    const [addTrainingCompeltedBefore, setAddTrainingCompeltedBefore] = useState(false);
    const [savingNewTraining, setSavingNewTraining] = useState(false);

    useEffect(() => {
        if (!addTrainingModalOpen) {
            setAddTrainingErrors(ADD_NEW_TRAINING_FORM_INITIAL_DATA);
            setAddTrainingData(ADD_NEW_TRAINING_FORM_INITIAL_DATA);
            setAddTrainingCompeltedBefore(false);
        }
    }, [addTrainingModalOpen]);

    useEffect(() => {
        console.log("debug drvierTrainingData", drvierTrainingData);
    }, [drvierTrainingData]);

    const onAddNewTrainingOkClicked = () => {
        console.log("debug onAddNewTrainingOkClicked", driverData);
        const isFormValid = validateAddTrainingForm(true);
        setSavingNewTraining(true);
        if (isFormValid) {
            const { trainingList, interval, customInterval, lastTrainingCompletedTs, nextTrainingTs } = addTrainingData;

            // determin training interval
            let trainingIntervals = customInterval && interval === -1 ? customInterval : interval;
            const _nextTrainingTs = nextTrainingTs ?? moment.unix(lastTrainingCompletedTs).add(interval, "days").unix();

            const lastCompletedTs = addTrainingCompeltedBefore ? lastTrainingCompletedTs : null;
            // console.log("debug requresting add training", driverData.user_seat_id, trainingList, _nextTrainingTs, lastCompletedTs, trainingIntervals);

            dispatch(
                addTrainingToDriver(driverData.user_seat_id, trainingList, _nextTrainingTs, lastCompletedTs, trainingIntervals, (response) => {
                    console.log("debug reponse from addTrainingToDriver", response);
                    if (response.success) {
                        console.log("debug re-fetching driver traning...");
                        dispatch(getDriverTrainingData(driverData.user_seat_id));
                        setSavingNewTraining(false);
                        setAddTrainingModalOpen(false);
                    }
                }),
            );
        }
    };

    const onAddNewTrainingCancelClicked = () => {
        console.log("debug onAddNewTrainingCancelClicked");

        setAddTrainingModalOpen(false);
    };

    const validateAddTrainingForm = (hard = false) => {
        console.log("debug validating form...", addTrainingData);

        let success = true;
        let errorObj = {
            trainingList: undefined,
            interval: undefined,
            customInterval: undefined,
            lastTrainingCompletedTs: undefined,
            nextTrainingTs: undefined,
        };

        const { trainingList, interval, customInterval, lastTrainingCompletedTs, nextTrainingTs } = addTrainingData;

        // validate training list
        if (hard ? trainingList === undefined : trainingList !== undefined && !trainingList.length) {
            success = false;
            errorObj.trainingList = "You need to sellect at least one training";
        }

        // validate interval
        if (hard ? interval === undefined : interval !== undefined && !interval) {
            success = false;
            errorObj.interval = "Training interval not set";
        }

        // validate customInterval
        if (interval === -1 && hard ? customInterval === undefined : customInterval !== undefined && !customInterval) {
            success = false;
            errorObj.customInterval = "Training custom interval not set";
        }

        // validate lastTrainingCompletedTs
        if (addTrainingCompeltedBefore && hard ? lastTrainingCompletedTs === undefined : lastTrainingCompletedTs !== undefined && !lastTrainingCompletedTs) {
            success = false;
            errorObj.lastTrainingCompletedTs = "Date of last training not set";
        }

        // validate nextTrainingTs
        if (!addTrainingCompeltedBefore && hard ? nextTrainingTs === undefined : nextTrainingTs !== undefined && !nextTrainingTs) {
            success = false;
            errorObj.nextTrainingTs = "Date of last training not set";
        }

        setAddTrainingErrors(errorObj);

        return success;
    };

    const updateFormaData = (field, value) => {
        let _data = _.cloneDeep(addTrainingData);
        _data[field] = value;
        setAddTrainingData(_data);
    };

    return (
        <Modal
            className="AddTrainingModal"
            title="Add New Training"
            visible={addTrainingModalOpen}
            onOk={onAddNewTrainingOkClicked}
            okButtonProps={{ disabled: savingNewTraining, loading: savingNewTraining }}
            okText={savingNewTraining ? "Saving" : "Add"}
            maskClosable={false}
            destroyOnClose
            onCancel={onAddNewTrainingCancelClicked}>
            <>
                <h3>
                    Select training you want to add to <span>{driverData && driverData.name}</span>
                </h3>
                <div className="FormGrid">
                    <div className="Label">Training Name</div>
                    <div className="Control">
                        <Select
                            className={addTrainingErrors.trainingList ? "has-error" : ""}
                            showSearch
                            placeholder="Select a  training"
                            optionFilterProp="children"
                            mode="multiple"
                            onChange={(list) => updateFormaData("trainingList", list)}>
                            {availableTrainingCategoriesList.length &&
                                _.map(availableTrainingCategoriesList, (cateogry) => {
                                    const alreadyAdded = _.has(drvierTrainingData, cateogry.id);
                                    const notPublic = !cateogry.privacy;
                                    return (
                                        <Option
                                            disabled={notPublic || alreadyAdded}
                                            value={cateogry.id}>
                                            {cateogry.name} {alreadyAdded && "(Added}"} {notPublic && "(Private Category)"}
                                        </Option>
                                    );
                                })}
                        </Select>
                        <span className="error-msg">{addTrainingErrors.trainingList && addTrainingErrors.trainingList}</span>
                    </div>

                    <div className="Label">To be repeated every</div>
                    <div className="Control">
                        <div className="Row">
                            <Select
                                className={addTrainingErrors.interval ? "has-error" : ""}
                                style={{ width: 180 }}
                                onChange={(value) => updateFormaData("interval", value)}>
                                <Option value={182}>6 Months</Option>
                                <Option value={365}>12 Months</Option>
                                <Option value={730}>24 Months</Option>
                                <Option value={0}>Never</Option>
                                <Option value={-1}>Custom</Option>
                            </Select>
                            {addTrainingData.interval < 0 && (
                                <>
                                    <InputNumber
                                        className={addTrainingErrors.customInterval ? "has-error" : ""}
                                        min={1}
                                        max={2000}
                                        onChange={(value) => updateFormaData("customInterval", value)}
                                    />
                                    <span>Days</span>
                                </>
                            )}
                        </div>
                        <span className="error-msg">{addTrainingErrors.interval && addTrainingErrors.interval}</span>
                        <span className="error-msg">{addTrainingErrors.customInterval && addTrainingErrors.customInterval}</span>
                    </div>

                    <div className="Label">Completed recently</div>
                    <div>
                        <Switch
                            onChange={() => {
                                setAddTrainingCompeltedBefore(!addTrainingCompeltedBefore);
                                let _data = _.cloneDeep(addTrainingData);
                                _data.lastTrainingCompletedTs = undefined;
                                _data.nextTrainingTs = undefined;
                                setAddTrainingData(_data);
                            }}
                        />
                    </div>

                    {addTrainingCompeltedBefore ? (
                        <>
                            <div className="Label">Last completion date</div>
                            <div className="Control">
                                <>
                                    <DatePicker
                                        key={"last_completed_date"}
                                        className={addTrainingErrors.lastTrainingCompletedTs ? "has-error" : ""}
                                        onChange={(date) => updateFormaData("lastTrainingCompletedTs", date.unix())}
                                        // disabled={addTrainingData.interval === undefined || (addTrainingData.interval === -1 && (addTrainingData.customInterval === null))}
                                    />
                                    <span className="error-msg">{addTrainingErrors.lastTrainingCompletedTs && addTrainingErrors.lastTrainingCompletedTs}</span>

                                    {addTrainingData.lastTrainingCompletedTs && (
                                        <span>
                                            Next training due:{" "}
                                            {moment.unix(addTrainingData.lastTrainingCompletedTs).add(addTrainingData.interval, "days").format("Do MMM YYYY")}
                                        </span>
                                    )}
                                </>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="Label">To be completed by</div>
                            <div className="Control">
                                <DatePicker
                                    key={"to_be_completed_date"}
                                    className={addTrainingErrors.nextTrainingTs ? "has-error" : ""}
                                    onChange={(date) => updateFormaData("nextTrainingTs", date.unix())}
                                    // disabled={addTrainingData.interval === undefined || (addTrainingData.interval === -1 && (addTrainingData.customInterval === null))}
                                />
                                <span className="error-msg">{addTrainingErrors.nextTrainingTs && addTrainingErrors.nextTrainingTs}</span>
                            </div>
                        </>
                    )}
                </div>
            </>
        </Modal>
    );
};

export default AddNewTrainingModal;
