import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import { USER_SEATS } from "./userActions";
import _ from "lodash";
import { notification } from "antd";

export const ANPR_DATA = "ANPR_DATA";
export const GPX_IMPORTS = "GPX_IMPORT";
export const RECEIVE_DATA_POOLS = "RECEIVE_DATA_POOLS";
export const DATA_POOLS = "DATA_POOLS";
export const CLASSIFICATIONS_DATA = "CLASSIFICATIONS_DATA";
export const GET_CLASSIFICATIONS = "GET_CLASSIFICATIONS";
export const NEXT_TENSOR_REVIEW = "NEXT_TENSOR_REVIEW";
export const GET_OBSERVATIONS = "GET_OBSERVATIONS";
export const EXIT_CURRENT_DASHBOARD = "EXIT_CURRENT_DASHBOARD";
export const OBSERVATIONS_DATA = "OBSERVATIONS_DATA";
export const GET_SESSION_ID_BY_OBSERVATIONS_ID = "GET_SESSION_ID_BY_OBSERVATIONS_ID";
export const SET_OBSERVATION_DATA = "SET_OBSERVATION_DATA";
export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION";
export const FETCHING_OBSERVATION_DATA = "FETCHING_OBSERVATION_DATA";
export const SET_CURRENT_OBSERVATION = "SET_CURRENT_OBSERVATION";
export const SET_THERMAL_CLASSIFICATIONS = "SET_THERMAL_CLASSIFICATIONS";
export const SET_OBSERVATION_CLASSIFICATION = "SET_OBSERVATION_CLASSIFICATION";

export function nextTensorReview() {
    return {
        type: NEXT_TENSOR_REVIEW,
    };
}

export function classifications(classificationsData) {
    return {
        type: CLASSIFICATIONS_DATA,
        classificationsData,
    };
}

function anprData(data) {
    return {
        type: ANPR_DATA,
        data,
    };
}

function gpxImportTasks(tasks) {
    return {
        type: GPX_IMPORTS,
        tasks,
    };
}

const receiveDataPools = (dataPools) => {
    return {
        dataPools,
        type: RECEIVE_DATA_POOLS,
    };
};

function userSeats(seat_limit, user_seats) {
    return {
        type: USER_SEATS,
        seat_limit,
        user_seats,
    };
}

function setObservations(observations) {
    return {
        type: OBSERVATIONS_DATA,
        observations,
    };
}

function setObservationData(observations, sessionID, count) {
    return {
        type: SET_OBSERVATION_DATA,
        observations,
        sessionID,
        count,
    };
}

function updateObservations(observations) {
    return {
        type: UPDATE_OBSERVATION,
        observations,
    };
}

function fetchingObservationData(loading, sessionID) {
    return {
        type: FETCHING_OBSERVATION_DATA,
        loading,
        sessionID,
    };
}

export function resetObservations() {
    return {
        type: EXIT_CURRENT_DASHBOARD,
    };
}

export function goToObservation(index) {
    return {
        type: SET_CURRENT_OBSERVATION,
        index,
    };
}

function setThermalClassifications(thermalClassifications) {
    return {
        type: SET_THERMAL_CLASSIFICATIONS,
        thermalClassifications,
    };
}

export function fetchANPRData(from, to, plate) {
    return {
        queue: ANPR_DATA,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "list",
                start_ts: from,
                end_ts: to,
                plate: plate,
            };
            let url = "/anpr";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.anpr_results) {
                        dispatch(anprData(response.anpr_results));
                    } else {
                        dispatch(anprData([]));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve ANPR data", "An error occurred while fetching ANPR data", error);
                    next();
                });
        },
    };
}

export function addUserSeat(email, seatType, seatDetails, callback) {
    return {
        queue: USER_SEATS,
        callback: (next, dispatch, getState) => {
            console.log("Adding user seat");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "add_user_seat",
                access_secret: accessSecret,
                email,
                seat_type: seatType,
            };

            if (seatDetails) {
                postBody["seatDetails"] = seatDetails;
            }

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got response", response);
                    if (response.success) {
                        callback(response.password, false);
                        dispatch(getUserSeats());
                    } else {
                        callback(null, response.message);
                    }
                    next();
                })
                .catch((error) => {
                    error.then((message) => {
                        callback(null, message);
                    });
                    next();
                });
        },
    };
}

export function changeSeatType(email, newSeatType) {
    return {
        queue: USER_SEATS,
        callback: (next, dispatch, getState) => {
            console.log("Changing user seat type", email);

            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "change_user_seat_type",
                access_secret: accessSecret,
                email: email,
                seat_type: newSeatType,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Succesfully changed seat type", response);
                    dispatch(getUserSeats());
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to change user seat type", "An error occurred while changing the user seat type", error);
                    next();
                });
        },
    };
}

export function removeUserSeat(email) {
    return {
        queue: USER_SEATS,
        callback: (next, dispatch, getState) => {
            console.log("Removing user seat");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "remove_user_seat",
                access_secret: accessSecret,
                email,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got response", response);
                    if (response.success) {
                        dispatch(getUserSeats());
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to remove user seat", "An error occurred while removing the selected user seat", error);
                    next();
                });
        },
    };
}

export function fetchImportTasks() {
    return {
        queue: GPX_IMPORTS,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_gpx_import_tasks",
                access_secret: accessSecret,
            };

            let url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(({ results }) => {
                    dispatch(gpxImportTasks(results || []));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve import task list", "An error occurred while retrieving the list of import tasks", error);
                    dispatch(gpxImportTasks([]));
                    next();
                });
        },
    };
}

export function createGPXImport(title, gpxFilename, videoFilename, dataPool, callback, errorCallback) {
    return {
        queue: GPX_IMPORTS,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            console.log("Creating new GPX import");

            let postBody = {
                action: "create_gpx_import",
                access_secret: accessSecret,
                gpxFile: gpxFilename,
                videoFile: videoFilename,
                title: title,
                dataPool,
            };

            const url = "/admin";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.gpxURL && response.videoURL) {
                        console.log("GPX import prepared", response);
                        callback(response.gpxURL, response.videoURL);
                    } else {
                        console.log("GPX import failed", response);
                        errorCallback();
                    }
                    next();
                })
                .catch((error) => {
                    console.log("Error creating GPX import: ", error);
                    errorCallback();
                    next();
                });
        },
    };
}

export function fetchDataPools() {
    return {
        queue: DATA_POOLS,
        callback: (next, dispatch, getState) => {
            console.log("getting data pools");

            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_data_pools",
                access_secret: accessSecret,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("fetch data pools response", response);
                    if (response.data_pools) {
                        dispatch(receiveDataPools(response.data_pools));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch data pools", "An error occurred while fetching fetching data pools", error);
                    next();
                });
        },
    };
}

export function getUserSeats() {
    return {
        queue: USER_SEATS,
        callback: (next, dispatch, getState) => {
            console.log("Getting user seats");
            const accessSecret = getState().admin.password;
            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_user_seats",
                access_secret: accessSecret,
            };

            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got response", response);
                    let seat_limit = response.seat_limit || 0;
                    let user_seats = response.user_seats || [];
                    dispatch(userSeats(seat_limit, user_seats));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve user seat list", "An error occurred while fetching the list of user seats", error);
                    dispatch(userSeats(0, []));
                    next();
                });
        },
    };
}

export function getClassifications(sessionKey, classificationType) {
    return {
        queue: GET_CLASSIFICATIONS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_classifications",
                session_key: sessionKey,
                classification_type: classificationType,
            };
            const url = "/annotations";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.results) {
                        console.log("Found classifications", response);
                        dispatch(classifications(response.results));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to obtain classification data", "An error occurred while fetching classification data", error);
                    next();
                });
        },
    };
}

export function getObservation(searchQuery, pageIndex, callback) {
    return {
        queue: OBSERVATIONS_DATA,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;
            const hasMore = getState().admin.observationReview.sessionList.has_more ?? undefined;
            const data = _.cloneDeep(getState().admin.observationReview.sessionList.data);

            if (accessSecret === null) {
                next();
                return;
            }

            if ((_.isNaN(hasMore) && searchQuery && !hasMore) || (_.isNaN(hasMore) && !hasMore)) {
                next();
                return;
            }

            // do not call api if data allready in redux
            if (data.length > 0 && pageIndex === 0 && searchQuery && searchQuery.length > 0) {
                if (callback) {
                    callback(true);
                }
                next();
                return;
            }

            let postBody = {
                action: "get_observations_per_workspace_admin",
                session_id: searchQuery,
                access_secret: accessSecret,
                page_index: pageIndex,
            };
            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        let _response = response;
                        _response.data = _.unionBy(data, response.data, "id");
                        dispatch(setObservations(_response));
                        if (callback) {
                            callback(true);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve observations list", "An error occurred while fetching the list of observations", error);
                    if (callback) {
                        callback({ success: false });
                    }
                    next();
                });
        },
    };
}

export function getObservationData(sessionID, count, callback) {
    return {
        queue: SET_OBSERVATION_DATA,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_detections_for_session_admin",
                access_secret: accessSecret,
                session_id: sessionID,
            };
            const url = "/admin";
            dispatch(fetchingObservationData(true, sessionID));

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        dispatch(setObservationData(response.data, sessionID, count));
                        if (callback) {
                            callback({ success: true });
                        }
                    }
                    dispatch(fetchingObservationData(false, sessionID));
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve observation data", "An error occurred while fetching the observation data", error);
                    if (callback) {
                        callback({ success: false });
                    }
                    dispatch(fetchingObservationData(false, sessionID));
                });
            next();
        },
    };
}

export function reviewObservationAdmin(observationID, reviewStatus, observationType, callback) {
    return {
        queue: UPDATE_OBSERVATION,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            let postBody = {
                action: "review_observation_admin",
                observation_id: observationID,
                review_status: reviewStatus,
                observation_type: observationType,
                access_secret: accessSecret,
            };

            const url = "/admin";

            // update state in Redux
            const observations = _.cloneDeep(getState().admin.observationReview.selectedSession.observations);
            let newObservations = _.cloneDeep(observations);
            const index = _.findIndex(newObservations, (obs) => obs["id"] === observationID);
            newObservations[index]["review_status"] = reviewStatus;
            dispatch(updateObservations(newObservations));

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        notification.success({
                            message: "Successfully updated observation status",
                        });
                    } else {
                        notification.error({
                            message: "Error updating observation status",
                        });
                        // if error revert back state in Redux
                        dispatch(updateObservations(observations));
                    }
                    if (callback) {
                        callback(response);
                    }
                })
                .catch((error) => {
                    // if error revert back state in Redux
                    dispatch(updateObservations(observations));
                    handleJsonPostError("Unable to update observation status", error);
                    if (callback) {
                        callback({ success: false });
                    }
                });
            next();
        },
    };
}

export function getThermalClassifications(callback) {
    return {
        queue: SET_THERMAL_CLASSIFICATIONS,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            if (accessSecret === null) {
                next();
                return;
            }

            let postBody = {
                action: "get_thermal_classifier_types",
                access_secret: accessSecret,
            };
            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        dispatch(setThermalClassifications(response.data));
                        if (callback) {
                            callback({ success: true });
                        }
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Unable to retrieve Thermal Classifications data", error);
                    if (callback) {
                        callback({ success: false });
                    }
                });
            next();
        },
    };
}

export function setObservationClassificationAdmin(observationID, observationType, classification, callback) {
    return {
        queue: SET_OBSERVATION_CLASSIFICATION,
        callback: (next, dispatch, getState) => {
            // Update observations in Redux
            const observations = _.cloneDeep(getState().admin.observationReview.selectedSession.observations);
            let newObservations = _.cloneDeep(observations);
            const index = _.findIndex(newObservations, (obs) => obs["id"] === observationID);
            newObservations[index]["auto_classified"] = false;

            let newClassification = "";
            if (observationType === 0) {
                // if Thermal
                newClassification = classification;
            } else {
                // if ocr_result_custom removed (empty string), remove from the object
                if (classification.length === 0) {
                    newClassification = JSON.parse(newObservations[index]["classification"]);
                    delete newClassification["ocr_result_custom"];
                    if (Object.keys(newClassification).length > 0) {
                        newObservations[index]["auto_classified"] = true;
                    }
                    newClassification = JSON.stringify(newClassification);
                } else {
                    newClassification = JSON.stringify({ ...JSON.parse(newObservations[index]["classification"]), ocr_result_custom: classification });
                }
            }

            newObservations[index]["classification"] = newClassification;

            dispatch(updateObservations(newObservations));

            let postBody = {
                action: "update_observation_classification",
                observation_id: observationID,
                observation_type: observationType,
                classification: newClassification,
            };
            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.success) {
                        notification.success({
                            message: "Successfully updated observation classification",
                        });
                    }
                    if (callback) {
                        callback({ success: true });
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Unable to update observation Classifications data", error);
                    // revert back to redux last state
                    dispatch(updateObservations(observations));
                    if (callback) {
                        callback({ success: false });
                    }
                });
            next();
        },
    };
}

export function getSessionIdByObservationId(observationID, callback) {
    return {
        queue: GET_SESSION_ID_BY_OBSERVATIONS_ID,
        callback: (next, dispatch, getState) => {
            const accessSecret = getState().admin.password;

            let postBody = {
                action: "get_session_id_by_observation_id",
                observation_id: observationID,
                access_secret: accessSecret,
            };
            const url = "/admin";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response && response.data) {
                        if (callback) {
                            callback(response.data);
                        }
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error while finding session id", "An error occurred while fetching the list of observations", error);
                    if (callback) {
                        callback(false);
                    }
                    next();
                });
        },
    };
}
