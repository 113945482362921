import { calculateRouteCoordinatesForLocation, nearestPointOnRoute, routeDistanceMap } from "../../components/util/Geometry";
import _ from "lodash";
import { handleJsonPostError, jsonPostV2 } from "./apiUtils";
import RouteCoordinateSystems from "components/util/RouteCoordinateSystems";

export const FEATURE_OVERLAY_ADD_FEATURE = "FEATURE_OVERLAY_ADD_FEATURE";
export const FEATURE_OVERLAY_REMOVE_FEATURE = "FEATURE_OVERLAY_REMOVE_FEATURE";
export const FEATURE_OVERLAY_UPDATE_FEATURE = "FEATURE_OVERLAY_UPDATE_FEATURE";
export const FEATURE_OVERLAY_CLEAR_FEATURES = "FEATURE_OVERLAY_CLEAR_FEATURES";
export const FEATURE_OVERLAY_SET_DATUM = "FEATURE_OVERLAY_SET_DATUM";
export const FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES = "FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES";
export const ADD_FEATURE_OVERLAY_CORRECTION = "ADD_FEATURE_OVERLAY_CORRECTION";
export const REMOVE_FEATURE_OVERLAY_CORRECTION = "REMOVE_FEATURE_OVERLAY_CORRECTION";
export const RECEIVE_REMOTE_FEATURES = "RECEIVE_REMOTE_FEATURES";

export function receiveRemoteFeatures(features) {
    return {
        type: RECEIVE_REMOTE_FEATURES,
        features,
    };
}

export function addFeatureOverlayCorrection(data) {
    return {
        type: ADD_FEATURE_OVERLAY_CORRECTION,
        data,
    };
}

export function removeFeatureOverlayCorrection(index) {
    return {
        type: REMOVE_FEATURE_OVERLAY_CORRECTION,
        index,
    };
}

export function featureOverlayAddFeature(feature, saveRemotely = true) {
    return (dispatch, _) => {
        if (saveRemotely) {
            dispatch(remoteSaveFeature(feature));
        } else {
            dispatch({
                type: FEATURE_OVERLAY_ADD_FEATURE,
                feature,
            });
        }
    };
}

export function featureOverlayUpdateFeature(index, feature) {
    return (dispatch, _) => {
        dispatch({
            type: FEATURE_OVERLAY_UPDATE_FEATURE,
            index,
            feature,
        });
        dispatch(remoteSaveFeature(feature));
    };
}

export function featureOverlayRemoveFeature(index) {
    return (dispatch, getState) => {
        const currentFeatures = getState().featureOverlay.features;
        const deletingFeature = currentFeatures[index];
        dispatch({
            type: FEATURE_OVERLAY_REMOVE_FEATURE,
            index,
        });

        deletingFeature.archived = true;
        dispatch(remoteSaveFeature(deletingFeature));
    };
}

export function featureOverlayClearFeatures() {
    return {
        type: FEATURE_OVERLAY_CLEAR_FEATURES,
    };
}

export function featureOverlaySetDatum(timestamp, location) {
    return (dispatch, getState) => {
        if (timestamp) {
            const routeCoordinateData = getState().playlist.data.route_locations;
            const routeCoordinateSystemID = getState().playlist.data.system_id;
            const elr = calculateRouteCoordinatesForLocation(timestamp, routeCoordinateData, routeCoordinateSystemID);
            dispatch({
                type: FEATURE_OVERLAY_SET_DATUM,
                datum: {
                    location,
                    routePosition: elr,
                },
            });
        } else {
            dispatch({
                type: FEATURE_OVERLAY_SET_DATUM,
                datum: null,
            });
        }
        dispatch(updateRouteDatumDistances());
    };
}

export function updateRouteDatumDistances() {
    return (dispatch, getState) => {
        const state = getState();
        const datum = state.featureOverlay.datum;
        const useSnappedGeometry = state.snappedRoute;
        const sourceIndex = state.playlist.position.sourceIndex;
        const videoRouteGeometry = _.get(state.playlist.data, ["video", sourceIndex]);
        const videolessRouteGeometry = _.get(state.playlist.data, ["data"]);
        const routeGeometry = videoRouteGeometry ? videoRouteGeometry : videolessRouteGeometry;

        if (datum && routeGeometry) {
            const routeDatum = nearestPointOnRoute(datum.location[1], datum.location[0], routeGeometry, useSnappedGeometry);
            const distanceMap = routeDistanceMap(routeDatum, routeGeometry, useSnappedGeometry);
            dispatch({
                type: FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES,
                routeDatum,
                distanceMap,
            });
        } else {
            dispatch({
                type: FEATURE_OVERLAY_ROUTE_DATUM_DISTANCES,
            });
        }
    };
}

export function remoteSaveFeature(feature) {
    console.log("debug saving feature to remote", { feature });
    return (dispatch, getState) => {
        const state = getState();
        const currentSession = _.get(state.sessions, [state.playlist.data.routeID]);
        let postBody = {
            action: "save_user_feature",
            location: feature.location,
            options: feature.options,
            route_pos: feature.rawRoutePosition,
            type: feature.type,
            heading: feature.heading,
            session_id: currentSession.id,
            session_timestamp: feature.timestamp,
            archived: feature.archived || false,
            private: feature.private || false,
            description: feature.description,
        };

        if (feature.id) {
            postBody["feature_id"] = feature.id;
        }

        const url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (!feature.archived && !feature.id) {
                    feature.id = response.feature_id;
                    dispatch({
                        type: FEATURE_OVERLAY_ADD_FEATURE,
                        feature,
                    });
                }
            })
            .catch((error) => {
                handleJsonPostError("Error saving feature", "An error occurred while attemping to save feature", error);
            });
    };
}

export function fetchRemoteFeatures() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_user_features",
        };
        let url = "/sessions";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                const features = response.features.map((feature) => {
                    let position = null;
                    if (feature.rawRoutePosition && feature.rawRoutePosition.length) {
                        const routeCoordSystem = _.find(RouteCoordinateSystems, (sys) => {
                            return sys.ID === feature.rawRoutePosition[0];
                        });
                        position = routeCoordSystem.create(feature.rawRoutePosition[1], feature.rawRoutePosition[2], feature.rawRoutePosition[3]);
                    }

                    return {
                        ...feature,
                        routePosition: position,
                    };
                });
                dispatch(receiveRemoteFeatures(features));
            })
            .catch((error) => {
                handleJsonPostError("Error fetching user features", "An error occurred while fetching user features", error);
            });
    };
}
