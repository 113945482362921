import React, { Suspense, useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { getHeading, latLonToMeters, locationsAreEqual, routePointDistance } from "../../../util/Geometry";
import Signal from "./features/Signal";
import Flag from "./features/Flag";
import { Text, Circle, Ring, Plane } from "@react-three/drei";
import { distance } from "../../../util/PlaylistUtils";
import Datum from "./features/Datum";
import { useSelector, useStore } from "react-redux";
import _ from "lodash";
import { Box3, DoubleSide, Vector3 } from "three";

const feature_models = {
    signal: Signal,
    flag: Flag,
};

const cube = (
    <mesh
        castShadow
        receiveShadow
        position={[0, 0.5, 0]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="grey" />
    </mesh>
);

const datumPositionSelector = (state) => _.get(state.featureOverlay, ["datum", "location"], null);
const routeDatumSelector = (state) => state.featureOverlay.route;
const datumOffsetsSelector = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);

    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);

    return workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
};

const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const currentPositionSelector = (state) => state.playlist.position.coords;

export const currentHeadingSelector = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = _.get(state.playlist.data, ["video", sourceIndex]);
    const playlistIndex = state.playlist.position.currentIndex;
    const timeOffset = state.playlist.position.currentTimeOffset || 0;
    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;

    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }
    const use_snapped = state.snappedRoute || false;
    return getHeading(playlist, playlistIndex, timeOffset, offsets, use_snapped, sessionIsBackward);
};

const userConfigSelector = (state) => state.userDetails.userConfig;

const ThreeDFeature = ({ feature, children, highlighted, selected, hidden, showLabel, sunBrightness }) => {
    const { type, heading, location, routePosition, description, options } = feature;
    const store = useStore();

    const currentPositionRef = useRef(currentPositionSelector(store.getState()));
    const currentHeadingRef = useRef(currentHeadingSelector(store.getState()));
    const datumLocation = useSelector(datumPositionSelector);
    const datumInformation = useSelector(routeDatumSelector);
    const datumOffsets = useSelector(datumOffsetsSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const userConfig = useSelector(userConfigSelector);

    const ref = useRef();
    const labelRef = useRef();
    const labelBGRef = useRef();
    const datumRef = useRef();

    const labelBox = useMemo(() => new Box3(), []);
    const labelSize = useMemo(() => new Vector3(), []);

    const isDatum = useMemo(() => {
        return locationsAreEqual(location, datumLocation);
    }, [location, datumLocation]);

    let elrInfo = useMemo(() => {
        if (routePosition) {
            const stringPosition = routePosition.to_string(elrUnits, datumOffsets);
            return stringPosition;
        } else {
            return null;
        }
    }, [routePosition, elrUnits, datumOffsets]);

    const datum_info = useMemo(() => {
        if (isDatum) {
            return "Datum point";
        } else if (location && datumInformation) {
            const { datum, distanceMap } = datumInformation;
            let distanceFromDatum = routePointDistance(datum, location, distanceMap);
            if (distanceFromDatum < 10) {
                distanceFromDatum = Math.round(distanceFromDatum * 10) / 10;
            } else {
                distanceFromDatum = Math.round(distanceFromDatum);
            }
            const measurementUnit = userConfig.measurement_units;

            if (measurementUnit === "metres") {
                return `${distanceFromDatum}m from datum`;
            } else if (measurementUnit === "yards") {
                let yardValue = distanceFromDatum * 1.0936132983;
                if (yardValue < 10) {
                    yardValue = Math.round(yardValue * 10) / 10;
                } else {
                    yardValue = Math.round(yardValue);
                }
                return `${yardValue}y from datum`;
            }
        } else {
            return false;
        }
    }, [location, isDatum, datumInformation, userConfig.measurement_units]);

    const Feature = useMemo(() => {
        if (!type) {
            return feature_models["flag"];
        } else {
            return feature_models[type];
        }
    }, [type]);

    const latLon = useMemo(() => {
        if (location) {
            return `${parseFloat(location[1]).toFixed(5)}, ${parseFloat(location[0]).toFixed(5)}`;
        } else {
            return null;
        }
    }, [location]);

    const label = useMemo(() => {
        if (showLabel) {
            let text = [];
            if (description) {
                if (description.length > 64) {
                    text.push(description.substring(0, 61) + "...");
                } else {
                    text.push(description);
                }
            }
            if (latLon) {
                text.push("Lat/lon: " + latLon);
            }
            if (elrInfo) {
                text.push(elrInfo);
            }
            if (datum_info) {
                text.push(datum_info);
            }
            text = text.join("\n");
            return (
                <group>
                    <Text
                        ref={labelRef}
                        position={[0.1, 1, 0.5]}
                        fontSize={0.5}
                        color="white"
                        maxWidth={10}>
                        <meshBasicMaterial
                            attach="material"
                            side={DoubleSide}
                            color={"#ffffff"}
                        />
                        {text}
                    </Text>
                    <Plane
                        ref={labelBGRef}
                        position={[0.1, 1, 0.49]}
                        args={[1, 1]}>
                        <meshBasicMaterial
                            color="black"
                            opacity={0.5}
                            transparent
                        />
                    </Plane>
                </group>
            );
        } else {
            return null;
        }
    }, [description, latLon, elrInfo, datum_info, labelRef, labelBGRef, showLabel]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
            const currentHeading = currentHeadingSelector(store.getState());
            if (currentHeading !== currentHeadingRef.current) {
                currentHeadingRef.current = currentHeading;
            }
        });
    }, [store]);

    useFrame(() => {
        const currentPosition = currentPositionRef.current;
        const _orientation = (Math.PI * (heading || 0)) / 180;

        let objectPosition = null;
        let dist = 999999;
        if (location && currentPosition) {
            objectPosition = latLonToMeters(currentPosition, location);
            dist = distance(objectPosition);
        }

        if (ref.current) {
            ref.current.visible = !hidden && dist <= 500;
            if (objectPosition) {
                ref.current.position.set(-objectPosition[1], 0, -objectPosition[0]);
            }
            ref.current.rotation.set(0, _orientation, 0);
        }
        if (labelRef.current && labelBGRef.current) {
            labelRef.current.visible = !hidden && dist <= 25;
            labelBGRef.current.visible = !hidden && dist <= 25;
            labelRef.current.scale.set(dist / 25, dist / 25, 1);

            labelBox.setFromObject(labelRef.current);
            labelBox.getSize(labelSize);
            labelBGRef.current.scale.set(labelSize.x + 0.1, labelSize.y + 0.1);
        }

        if (datumRef.current) {
            const heading = currentHeadingRef.current;
            let headingAdjustment = heading - _orientation;
            datumRef.current.rotation.set(0, headingAdjustment, 0);
        }
    });

    return (
        <group ref={ref}>
            <Suspense fallback={cube}>
                <Feature
                    selected={selected}
                    highlighted={highlighted}
                    options={options}
                    sunBrightness={sunBrightness}
                />
                <group ref={datumRef}>
                    {isDatum && <Datum />}
                    {label}
                </group>
            </Suspense>
            <Circle
                visible={highlighted}
                rotation={[-Math.PI / 2, 0, 0]}
                args={[1.5, 64]}
                renderOrder={1}>
                <meshBasicMaterial
                    color="#008000"
                    opacity={0.5}
                    transparent
                />
            </Circle>
            <Ring
                visible={selected}
                rotation={[-Math.PI / 2, 0, 0]}
                args={[0.4, 0.5, 64]}
                renderOrder={2}>
                <meshBasicMaterial
                    color="#000080"
                    opacity={0.5}
                    transparent
                />
            </Ring>
            {children}
        </group>
    );
};

export default ThreeDFeature;
