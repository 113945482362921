import React from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { acceptTerms } from "../../redux/actions/index";
import TermsText from "./TermsText";

const dashboardSelector = (state) => state.dashboards;

const TermsComponent = () => {
    const dispatch = useDispatch();

    const dashboards = useSelector(dashboardSelector);

    const onClick = () => {
        console.log("Accepting terms");

        dispatch(acceptTerms(dashboards[0].access_token));
    };

    return (
        <div className="TermsPage">
            <div className="TermsContainer">
                <div className="TermsContainer__title">Updated Terms</div>

                <div className="TermsContainer__subtitle">Please read and accept the terms of use to continue</div>

                <div className="TermsTextContainer">
                    <p className="Title"> Terms of use</p>

                    <TermsText />
                </div>
                <div className="TermsFooter">
                    <Button
                        type="primary"
                        onClick={onClick}>
                        Accept
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TermsComponent;
