import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { routeSelected, setRouteHistoryHidden, consolidatedHistoryLookup, customAudit } from "../../../redux/actions/index";
import { getAbsoluteTimestamp, getCurrentVideoKey } from "../../util/PlaylistUtils";
import { calculateRouteCoordinatesForLocation } from "../../util/Geometry";
import { convertToTimezone } from "../../util/TimezoneUtils";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import { resetStillImageAdjustments } from "redux/actions/sessionActions";
import { SyncOutlined } from "@ant-design/icons";

const routeIDSelector = (state) => _.get(state, ["playlist", "data", "routeID"], null);
const playlistSelector = (state) => state.playlist;
const userConfigSelector = (state) => state.userDetails.userConfig;
const routeHistoryHiddenSelector = (state) => state.playlist.history.hidden;
const routeHistoryAutoPopupSelector = (state) => !state.userDetails.userConfig.auto_show_history_popup;
const routeHistorySelector = (state) => state.playlist.consolidatedHistory.history;
const fetchingRouteHistorySelector = (state) => state.playlist.consolidatedHistory.fetching;
const playerStateSelector = (state) => state.playlist.position.playerState;
const dashboardIdSelector = (state) => state.userDetails.dashboardAccessID;
const dashboardsSelector = (state) => state.dashboards;
const csrfTokenSelector = (state) => state.csrfToken;

function getCardinalDirection(bearing) {
    if (bearing < 22.5) {
        return "North";
    } else if (bearing < 67.5) {
        return "Northeast";
    } else if (bearing < 112.5) {
        return "East";
    } else if (bearing < 157.5) {
        return "Southeast";
    } else if (bearing < 202.5) {
        return "South";
    } else if (bearing < 247.5) {
        return "Southwest";
    } else if (bearing < 292.5) {
        return "West";
    } else if (bearing < 337.5) {
        return "Northwest";
    } else {
        return "North";
    }
}

function getBearingText(bearing, facing) {
    if (bearing === null) {
        return "Unknown";
    }

    while (bearing < 0) {
        bearing += 360;
    }
    if (bearing >= 360) {
        bearing = bearing % 360;
    }

    let direction = getCardinalDirection(bearing);
    return `${direction}`;
}

function generateButtonAltText(bearingText, niceDate) {
    return `Direction: ${bearingText}, Date: ${niceDate}`;
}

function RouteHistoryImage({ dp, highlightedKey, extraClasses, onSelect, historyVideoKey }) {
    const dispatch = useDispatch();
    const userConfig = useSelector(userConfigSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const selectSession = () => {
        dispatch(resetStillImageAdjustments());
        dispatch(
            customAudit(
                "history_popup_item_selected",
                {
                    sessionID: dp.session_id,
                    videoKey: dp.video_key,
                },
                `History popup item selected - Session #${dp.session_id}`,
            ),
        );
        dispatch(routeSelected(dp.session_id, dp.video_key));
    };

    if (dp === null || dp === undefined) {
        return null;
    }
    // if no frame_url return null
    if (dp.frame_url === null) {
        return null;
    }

    let imageURL = `https://raw${MEMOIZED_DOMAIN_URL}/` + dp.frame_url;
    if (csrfToken) {
        imageURL += `?csrf=${csrfToken}`;
    }

    const imageClasses = ["RouteHistoryImage"].concat(extraClasses);
    if (dp.video_key === historyVideoKey) {
        imageClasses.push("Selected");
    }
    const className = imageClasses.join(" ");

    const dpDate = new Date(dp.timestamp);
    const niceDate = convertToTimezone(dpDate, userConfig.convert_to_utc);
    const bearingText = dp.bearing ? getCardinalDirection(dp.bearing) : "Unknown";

    if (dp === highlightedKey) {
        return (
            <img
                src={imageURL}
                crossOrigin={"anonymous"}
                key={dp.video_key}
                className={className}
                alt={generateButtonAltText(bearingText, niceDate)}
                aria-label={generateButtonAltText(bearingText, niceDate)}
                tabIndex={0}
                role="button"
                onClick={() => selectSession()}
                onFocus={() => onSelect(dp)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        selectSession();
                    }
                }}
            />
        );
    } else {
        return (
            <img
                src={imageURL}
                crossOrigin={"anonymous"}
                key={dp.video_key}
                className={className}
                tabIndex={0}
                role="button"
                alt={generateButtonAltText(bearingText, niceDate)}
                aria-label={generateButtonAltText(bearingText, niceDate)}
                onFocus={() => onSelect(dp)}
                onClick={() => onSelect(dp)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        selectSession(dp);
                    }
                }}
            />
        );
    }
}

function RouteHistoryItem({ dp, highlightedKey, onSelect, historyVideoKey }) {
    const dispatch = useDispatch();
    const userConfig = useSelector(userConfigSelector);

    const selectSession = () => {
        dispatch(resetStillImageAdjustments());
        dispatch(
            customAudit(
                "history_popup_item_selected",
                {
                    sessionID: dp.session_id,
                    videoKey: dp.video_key,
                },
                `History popup item selected - Session #${dp.session_id}`,
            ),
        );
        dispatch(routeSelected(dp.session_id, dp.video_key));
    };
    // if no frame_url return null
    if (dp.frame_url === null) {
        return null;
    }

    const dpDate = new Date(dp.timestamp);
    const niceDate = convertToTimezone(dpDate, userConfig.convert_to_utc);
    const bearingText = dp.bearing ? getCardinalDirection(dp.bearing) : "Unknown";

    return (
        <div
            className="HistoryTimelineSlot"
            role="button"
            key={dp.timestamp}
            alt={generateButtonAltText(bearingText, niceDate)}
            aria-label={generateButtonAltText(bearingText, niceDate)}
            onMouseEnter={() => onSelect(dp)}
            onClick={() => selectSession()}
            onFocus={() => onSelect(dp)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    selectSession();
                }
            }}
            tabIndex={0}>
            <div className={"HistoryTimelineItem" + (dp.video_key === historyVideoKey ? " Selected" : "") + (dp === highlightedKey ? " Hover" : "")}></div>
        </div>
    );
}

function RouteHistoryToggle() {
    const dispatch = useDispatch();
    const history = useSelector(routeHistorySelector);
    const routeHistoryHidden = useSelector(routeHistoryHiddenSelector);
    const routeHistoryAutoPopup = useSelector(routeHistoryAutoPopupSelector);
    const playerState = useSelector(playerStateSelector);
    const fetchingRouteHistory = useSelector(fetchingRouteHistorySelector);

    const hidden = routeHistoryHidden === null ? routeHistoryAutoPopup : routeHistoryHidden;

    const toggleRouteHistory = () => {
        if (routeHistoryHidden === null) {
            dispatch(setRouteHistoryHidden(!hidden));
        } else {
            dispatch(setRouteHistoryHidden(!routeHistoryHidden));
        }
    };

    const content = useMemo(() => {
        if (hidden) {
            return (
                <>
                    Show Route History&nbsp;
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        size={"2x"}
                    />
                </>
            );
        } else if (fetchingRouteHistory && playerState !== "playing") {
            return (
                <>
                    Loading Route History&nbsp;
                    <SyncOutlined
                        style={{ fontSize: "20px" }}
                        spin
                    />
                </>
            );
        } else {
            return (
                <>
                    Hide Route History&nbsp;
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size={"2x"}
                    />
                </>
            );
        }
    }, [hidden, fetchingRouteHistory, playerState]);

    if (!history.length && playerState !== "paused") {
        return null;
    } else {
        return (
            <div
                className="RouteHistoryShowHide"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        toggleRouteHistory();
                    }
                }}
                onClick={() => {
                    toggleRouteHistory();
                }}>
                {content}
            </div>
        );
    }
}

function SelectedTimelineImages({ points, selection, onSelect, historyVideoKey }) {
    const userConfig = useSelector(userConfigSelector);

    const bearingText = useMemo(() => {
        if (selection) {
            return getBearingText(selection.bearing, selection.facing);
        } else {
            return "Unknown";
        }
    }, [selection]);

    const images = useMemo(() => {
        const pointsForBearing = points[bearingText];
        if (pointsForBearing && pointsForBearing.length) {
            const dpIndex = _.findIndex(pointsForBearing, selection);
            return pointsForBearing.map((dp, idx) => {
                let diff = Math.abs(idx - dpIndex);
                if (diff > 4) {
                    diff = 4;
                }
                let extraClass = "small-" + diff;
                if (dp === selection) {
                    extraClass = "Active";
                }

                return (
                    <RouteHistoryImage
                        key={dp.video_key}
                        dp={dp}
                        highlightedKey={selection}
                        historyVideoKey={historyVideoKey}
                        extraClasses={[extraClass]}
                        onSelect={onSelect}
                        onFocus={onSelect}
                    />
                );
            });
        } else {
            return [];
        }
    }, [points, bearingText, selection, onSelect]);

    const sessionName = useMemo(() => {
        if (selection) {
            return selection.name;
        } else {
            return "Unknown";
        }
    }, [selection]);

    const dateTime = useMemo(() => {
        if (selection) {
            const dpDate = new Date(selection.timestamp);
            const niceDate = convertToTimezone(dpDate, userConfig.convert_to_utc);

            return <div className={"RouteHistoryDate"}>{niceDate}</div>;
        } else {
            return null;
        }
    }, [selection, userConfig]);

    return (
        <div className={"RouteHistoryItem"}>
            <div className="RouteHistoryImagesWrapper">
                <div className="RouteHistoryImages">{images}</div>
            </div>
            <div className={"RouteHistoryTitle"}>Session: {sessionName}</div>
            {dateTime}
        </div>
    );
}

function RouteHistoryTimeline({ direction, points, allPoints, selection, onSelect, historyVideoKey }) {
    // points are sessions to be displayed in the list - matching the given bearing/direction
    // allPoints is the complete list of points disregarding bearing completely
    //

    const bearingText = useMemo(() => {
        if (selection) {
            return getBearingText(selection.bearing, selection.facing);
        } else {
            return "Unknown";
        }
    }, [selection]);

    if (points.length === 0) {
        return null;
    }

    const selectedTimeline = direction === bearingText;

    const mappedPoints = [];

    let pointIndex = 0;
    let dirIndex = 0;

    while (dirIndex < points.length) {
        // iterate through the sessions on this timeline

        const currentPoint = points[dirIndex];

        while (allPoints[pointIndex] !== currentPoint) {
            // fill the timline with spacers as many times as there are sessions in the 'master' timeline
            // that sit between the previous and next on this timeline
            // the effect of this is that only a single session can occupy a vertical 'slot' across all timelines
            // this essentially negates any space saving you could have from breaking into multiple timelines -
            // but also makes sense - because it guarantees adjacent sessions do lay out 'in order'

            // can we just compress everything horizontally and have it expand to be clickable somehow?
            // the spacer are currently just invisible dots!

            mappedPoints.push(
                <div
                    className="HistoryTimelineSlot"
                    key={allPoints[pointIndex].video_key}>
                    {/* <FontAwesomeIcon icon={faDotCircle}/> */}
                </div>,
            );
            pointIndex += 1;
        }
        mappedPoints.push(
            <RouteHistoryItem
                key={allPoints[pointIndex].video_key}
                dp={allPoints[pointIndex]}
                highlightedKey={selection}
                historyVideoKey={historyVideoKey}
                onSelect={onSelect}
            />,
        );
        pointIndex += 1;
        dirIndex += 1;
    }

    while (pointIndex < allPoints.length) {
        mappedPoints.push(
            <div
                className="HistoryTimelineSlot"
                key={allPoints[pointIndex].video_key}>
                {/* <FontAwesomeIcon icon={faDotCircle}/> */}
            </div>,
        );
        pointIndex += 1;
    }

    return (
        <div
            className={"HistoryTimeline" + (selectedTimeline ? " Selected" : "")}
            key={direction}>
            <div className="HistoryTimelineDirection">{direction}</div>
            <div className="HistoryTimelineItems">
                <div className="HistoryTimelineSliderBG" />
                {mappedPoints}
            </div>
        </div>
    );
}

const timestampSelector = (state) => {
    const routeID = _.get(state, ["playlist", "data", "routeID"], null);
    const sourceIndex = state.playlist.position.sourceIndex;
    const isVideo = state.playlist.position.isVideo;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
};

const requestedHistoryTimestampSelector = (state) => _.get(state.playlist.consolidatedHistory, ["requestedTimestamp"], 0);
const routeLocationDataSelector = (state) => state.playlist.data.route_locations;
const currentSourceIndexSelector = (state) => state.playlist.position.sourceIndex;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;

export default function RouteHistoryComponent() {
    // const [routeHistoryHidden, setRouteHistoryHidden] = useState();
    const [highlightedRouteHistoryItem, setHighlightedRouteHistoryItem] = useState();
    const dispatch = useDispatch();

    const routeID = useSelector(routeIDSelector);
    const routeHistoryHidden = useSelector(routeHistoryHiddenSelector);
    const routeHistoryAutoPopup = useSelector(routeHistoryAutoPopupSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const timestamp = useSelector(timestampSelector);
    const fetchingHistory = useSelector(fetchingRouteHistorySelector);
    const requestedHistoryTimestamp = useSelector(requestedHistoryTimestampSelector);
    const routeLocationData = useSelector(routeLocationDataSelector);
    const routeHistory = useSelector(routeHistorySelector);
    const currentSourceIndex = useSelector(currentSourceIndexSelector);
    const playlist = useSelector(playlistSelector);
    const dashboardID = useSelector(dashboardIdSelector);
    const dashboards = useSelector(dashboardsSelector);
    const currentDashboardLayout = _.get(
        _.find(dashboards, (dash) => dash.access_id === dashboardID),
        "workspace_layout",
        null,
    );

    const hidden = routeHistoryHidden === null ? routeHistoryAutoPopup : routeHistoryHidden;

    useEffect(() => {
        if (!hidden) {
            if (!fetchingHistory && timestamp !== requestedHistoryTimestamp) {
                const currentLocation = calculateRouteCoordinatesForLocation(timestamp, routeLocationData, routeSystemID);
                if (currentLocation) {
                    dispatch(consolidatedHistoryLookup(timestamp, currentLocation, false));
                }
            }
        }
    }, [hidden, fetchingHistory, requestedHistoryTimestamp, timestamp, routeLocationData, dispatch, routeSystemID]);

    useEffect(() => {
        const selectedDP = _.find(routeHistory, (dp) => dp.session_id === routeID && currentSourceIndex === dp.stream_index);

        if (selectedDP) {
            setHighlightedRouteHistoryItem(selectedDP);
        } else if (routeHistory && routeHistory.length) {
            setHighlightedRouteHistoryItem(routeHistory[routeHistory.length - 1]);
        }
    }, [routeHistory, routeID, currentSourceIndex]);

    const routeHistoryHiddenValue = useMemo(() => {
        return routeHistoryHidden === null ? routeHistoryAutoPopup : routeHistoryHidden || hidden;
    }, [hidden, routeHistoryAutoPopup, routeHistoryHidden]);

    const routeHistoryClasses = useMemo(() => {
        const retval = ["vjs-pause-overlay-route-history"];
        if (!routeHistory.length || fetchingHistory) {
            retval.push("Empty");
        }
        if (routeHistoryHiddenValue) {
            retval.push("Compressed");
        }
        return retval;
    }, [routeHistory.length, fetchingHistory, routeHistoryHiddenValue]);

    const MAX_HISTORY_ITEMS = 50;

    const { renderablePoints, routesByDirection, allHistoryPoints, maxHistoryReached } = useMemo(() => {
        let maxHistoryReached = false;
        if (routeHistory) {
            const allHistoryPoints = routeHistory;
            var renderablePoints;
            if (allHistoryPoints.length > MAX_HISTORY_ITEMS) {
                maxHistoryReached = allHistoryPoints.length - MAX_HISTORY_ITEMS;
                renderablePoints = allHistoryPoints.slice(-MAX_HISTORY_ITEMS);
            } else {
                renderablePoints = allHistoryPoints;
            }

            const routesByDirection = _.groupBy(renderablePoints, (dp) => getBearingText(dp.bearing, dp.facing));
            return { renderablePoints, routesByDirection, allHistoryPoints, maxHistoryReached };
        } else {
            return { renderablePoints: [], routesByDirection: {}, allHistoryPoints: [], maxHistoryReached };
        }
    }, [routeHistory]);

    const content = useMemo(() => {
        const historyVideoKey = getCurrentVideoKey(playlist);

        let content = null;
        if (!routeHistoryHiddenValue && routeHistory.length) {
            console.log("HISTORY:", {
                routesByDirection,
                renderablePoints,
                allHistoryPoints,
            });

            content = (
                <div className="RouteHistoryContainer">
                    {maxHistoryReached ? (
                        <div className="RouteHistoryMaxWarning">
                            {`Showing last ${MAX_HISTORY_ITEMS} sessions - ${maxHistoryReached} more in the History tab`}
                        </div>
                    ) : (
                        <div className="RouteHistoryMaxWarning">{`${renderablePoints.length} sessions in history`}</div>
                    )}
                    <div className="SelectedHistoryItem">
                        <SelectedTimelineImages
                            points={routesByDirection}
                            selection={highlightedRouteHistoryItem}
                            onSelect={setHighlightedRouteHistoryItem}
                            historyVideoKey={historyVideoKey}
                        />
                    </div>
                    <div className="HistoryTimelines">
                        {_.map(routesByDirection, (points, direction) => (
                            <RouteHistoryTimeline
                                key={direction}
                                allPoints={renderablePoints}
                                points={points}
                                direction={direction}
                                selection={highlightedRouteHistoryItem}
                                onSelect={setHighlightedRouteHistoryItem}
                                historyVideoKey={historyVideoKey}
                            />
                        ))}
                    </div>
                </div>
            );
        }
        return content;
    }, [routeHistoryHiddenValue, routeHistory.length, renderablePoints, allHistoryPoints, routesByDirection, highlightedRouteHistoryItem]);

    return currentDashboardLayout !== "widget_layout" ? (
        <div className={routeHistoryClasses.join(" ")}>
            <RouteHistoryToggle />
            {content}
        </div>
    ) : null;
}
