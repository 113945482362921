import React, { useMemo, useCallback, useState, useEffect } from "react";

import { Tooltip, Progress, Button, Popover, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faKey } from "@fortawesome/pro-regular-svg-icons";
import RoutePatrolSvg from "./RoutePatrolSvg";
import SchemaToolbar from "./SchemaToolbar";
import ELRMileAndChain from "../util/ELRMileAndChain";
import moment from "moment";
import PatrolStatus from "./PatrolStatus";
import { faCog, faLock } from "@fortawesome/pro-solid-svg-icons";
import {
    getPatrolPlan,
    updatePatrolProgress,
    togglePatrolInspecting,
    railPatrolAudit,
    routePatrolExited,
    setPatrolReportOpen,
    resetPatrol,
    lockPatrolImages,
} from "../../redux/actions/index";
import { useHistory, withRouter } from "react-router-dom";
import UnitSelectionModal from "components/UnitSelectionModal";

const viewedProgressSelector = (state) => state.schemaInterface.covered;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const schemaLocationSelector = (state) => state.schemaInterface.location;
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;
const schemaPlanSelector = (state) => state.schemaInterface.plan;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const inspectingPatrolSelector = (state) => state.schemaInterface.inspectingPatrol;
const fetchingELRsSelector = (state) => state.schemaInterface.fetchingELRs;
const railDataLoadedSelector = (state) => state.schemaInterface.imagesLoaded;
const annotationsSelector = (state) => state.railInspection.annotations.data;
const bookmarksSelector = (state) => state.railInspection.bookmarks;

const keyContent = (
    <div className="SchemaLegend">
        <div className="SchemaLegend__item">
            <span>Current position</span>
            <div className="SchemaLegend__itemDotSample red" />
        </div>
        <div className="SchemaLegend__item">
            <span>Area with coverage</span>
            <div className="SchemaLegend__itemLineSample white" />
        </div>
        <div className="SchemaLegend__item">
            <span>Area without coverage</span>
            <div className="SchemaLegend__itemLineSample grey" />
        </div>
        <div className="SchemaLegend__item">
            <span>Current selected route</span>
            <div className="SchemaLegend__itemLineSample blue" />
        </div>
        <div className="SchemaLegend__item">
            <span>Inspection progress</span>
            <div className="SchemaLegend__itemLineSample green" />
        </div>
        <div className="SchemaLegend__item">
            <span>Inspection area</span>
            <div className="SchemaLegend__itemLineSample dashed" />
        </div>
    </div>
);

const PatrolInterfaceHeader = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const progressMap = useSelector(viewedProgressSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const currentLocation = useSelector(schemaLocationSelector);
    const superAdmin = useSelector(superAdminSelector);
    const schemaPlan = useSelector(schemaPlanSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const inspectingPatrol = useSelector(inspectingPatrolSelector);
    const fetchingELRs = useSelector(fetchingELRsSelector);
    const imagesLoaded = useSelector(railDataLoadedSelector);
    const annotations = useSelector(annotationsSelector);
    const bookmarks = useSelector(bookmarksSelector);

    const isReview = props.match.path.includes("review-patrol");
    const planID = parseInt(props.match.params.patrolID);

    const [timeStarted, setTimeStarted] = useState();
    const [unitSelectionModalVisible, setUnitSelectionModalVisible] = useState(false);

    const initialised = useMemo(() => {
        return !fetchingELRs && imagesLoaded;
    }, [fetchingELRs, imagesLoaded]);

    useEffect(() => {
        if (patrolStatus === 1) {
            dispatch(togglePatrolInspecting(true));
        }
    }, [initialised, dispatch, patrolStatus]);

    const distanceUnits = useMemo(() => {
        if (elrUnits === "elr_mile_chain") {
            return "ELR Mile & Chain";
        } else if (elrUnits === "elr_mile_yards") {
            return "ELR Mile & Yards";
        } else if (elrUnits === "elr_meterage") {
            return "ELR & Meterage";
        }
    }, [elrUnits]);

    const locationDisplay = useMemo(() => {
        if (!currentLocation.elr) {
            return null;
        }
        const fieldObj = {
            ELR: currentLocation.elr,
            METERAGE: currentLocation.chain * 20.1168,
            TRACK: currentLocation.trackID,
        };
        const location = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_meterage", fieldObj, true);
        return location.to_string(elrUnits, []);
    }, [currentLocation, elrUnits]);

    const dateText = useMemo(() => {
        const startTimestamp = schemaPlan.start_timestamp;
        const endTimestamp = schemaPlan.end_timestamp;

        const startDate = moment.unix(startTimestamp).format("DD/MM/YYYY");
        const endDate = moment.unix(endTimestamp).format("DD/MM/YYYY");

        return `${startDate} - ${endDate}`;
    }, [schemaPlan]);

    const stopInspecting = useCallback(() => {
        dispatch(togglePatrolInspecting(false));
        const metadata = {
            time_inspecting: Date.now() / 1000 - timeStarted,
            patrol_id: planID,
        };
        dispatch(railPatrolAudit("stopped_route_inspection", metadata));
    }, [dispatch, planID, timeStarted]);

    const startInspecting = useCallback(() => {
        dispatch(togglePatrolInspecting(true));
        if (patrolStatus === 0) {
            dispatch(updatePatrolProgress(planID, null, 1, null));
            dispatch(lockPatrolImages());
        }
        setTimeStarted(Date.now() / 1000);
    }, [dispatch, patrolStatus, planID]);

    const abandonPatrol = useCallback(() => {
        stopInspecting();
        dispatch(
            resetPatrol(planID, (success) => {
                if (success) {
                    window.location.reload();
                } else {
                    history.push("/patrols");
                }
            }),
        );
    }, [planID, dispatch, stopInspecting, history]);

    const abandonPatrolButton = useMemo(() => {
        if (patrolStatus === 2 || patrolStatus === 3) {
            return null;
        }

        if (initialised && !isReview) {
            if (!props.totalCoveragePercent && !bookmarks.length && !annotations.length) {
                return (
                    <Button
                        className="AbandonPatrolButton"
                        disabled={true}>
                        Abandon Patrol
                    </Button>
                );
            }
            return (
                <Popconfirm
                    className="AbandonPatrolButton-Popover"
                    placement="top"
                    title={"This action will reset all progress and remove all annotations and bookmarks. Are you sure?"}
                    onConfirm={abandonPatrol}
                    okText="Yes"
                    cancelText="No">
                    <Button className="AbandonPatrolButton">Abandon Patrol</Button>
                </Popconfirm>
            );
        }
    }, [abandonPatrol, props.totalCoveragePercent, initialised, annotations, bookmarks, isReview, patrolStatus]);

    const scheduledDateReached = useMemo(() => {
        if (schemaPlan.due_ts) {
            return Math.floor(Date.now() / 1000) > schemaPlan.due_ts;
        } else {
            return true;
        }
    }, [schemaPlan]);

    const startStopInspectingButtons = useMemo(() => {
        if (props.patrolCompleted || !initialised) {
            return null;
        }
        if (inspectingPatrol) {
            return (
                <Button
                    onClick={stopInspecting}
                    className="StopInspectionButton">
                    Pause Inspection
                </Button>
            );
        } else {
            let text = "Start Inspection";

            if (patrolStatus === 1) {
                text = "Resume Inspection";
            }

            return (
                <Popover
                    placement="right"
                    content={"Patrol cannot be started until the Scheduled Date."}
                    trigger={!scheduledDateReached ? "hover" : ""}>
                    <Button
                        type="primary"
                        onClick={startInspecting}
                        className="StartInspectionButton"
                        disabled={!scheduledDateReached}>
                        {text}
                    </Button>
                </Popover>
            );
        }
    }, [inspectingPatrol, startInspecting, stopInspecting, props.patrolCompleted, initialised, scheduledDateReached, patrolStatus]);

    const imageLock = useMemo(() => {
        if (schemaPlan && schemaPlan.image_lock_timestamp) {
            const time = new Date(schemaPlan.image_lock_timestamp * 1000).toUTCString();

            return (
                <Tooltip title={`Images locked at ${time}`}>
                    <FontAwesomeIcon icon={faLock} />
                </Tooltip>
            );
        }
    }, [schemaPlan]);

    const onBackClick = () => {
        dispatch(updatePatrolProgress(planID, progressMap, null, null));
        dispatch(routePatrolExited());
        stopInspecting();
        history.push("/patrols");
    };

    return (
        <div className="SchemaContentWrapper">
            {unitSelectionModalVisible && <UnitSelectionModal onClose={() => setUnitSelectionModalVisible(false)} />}
            <div className="SchemaProgress">
                <Tooltip title="Total Inspected">
                    <Progress
                        type="circle"
                        percent={props.totalCoveragePercent}
                        width={60}
                        strokeColor="#a9c447"
                        className="ProgressCircle"
                    />
                </Tooltip>
                {/* <Button onClick={resetCoverage} type="primary">
                    Reset Progress
                </Button> */}
            </div>
            <FontAwesomeIcon
                icon={faArrowLeft}
                className="BackIcon"
                onClick={onBackClick}
            />
            <div className="TopContainer">
                <div className="SchemaLocationContainer">
                    <p className="Units">{distanceUnits}</p>
                    <FontAwesomeIcon
                        style={{ marginRight: 13, marginLeft: -5 }}
                        className="RouteInfo-ELRSettings__Icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            setUnitSelectionModalVisible(true);
                        }}
                        icon={faCog}
                    />
                    <span className="Value">{!!locationDisplay ? locationDisplay : "Unknown"}</span>
                </div>
                {/* {superAdmin &&
                <Button type="danger" onClick={resetData} loading={resetLoading}>Reset MWV
                    Data</Button>} */}
            </div>

            <div className="SchemaInfo">
                <div className="SchemaInfoInner">
                    {!!schemaPlan.start_timestamp && (
                        <div className="SchemaTitleContainer">
                            <h1>{schemaPlan.name}</h1>
                            <div className="SchemaTitleContainer-Row">
                                <h3>{dateText}</h3>
                                {imageLock}
                            </div>
                        </div>
                    )}

                    <PatrolStatus status={patrolStatus} />

                    <div className="ButtonContainer">
                        {startStopInspectingButtons}

                        {initialised && (
                            <Button
                                onClick={() => dispatch(setPatrolReportOpen(true))}
                                className={props.patrolCompleted ? "" : "ReportButton"}
                                type="primary">
                                {props.patrolCompleted ? "View Report" : "View Draft Report"}
                            </Button>
                        )}

                        {abandonPatrolButton}
                    </div>
                </div>
            </div>

            <RoutePatrolSvg
                planData={schemaPlan}
                progressMap={progressMap}
            />

            <Popover
                title={null}
                content={keyContent}
                placement="left">
                <div className="SchemaInfoKey">
                    <FontAwesomeIcon icon={faKey} />
                    <p>Map Key</p>
                </div>
            </Popover>
            <SchemaToolbar
                toggleBookmarkMode={props.toggleBookmarkMode}
                snapshotActive={props.snapshotActive}
                setSnapshotActive={props.setSnapshotActive}
                placingBookmark={props.placingBookmark}
                toggleAssetNavigation={props.toggleAssetNavigation}
                setHistoryOpen={props.setHistoryOpen}
                historyActive={props.historyOpen}
                annotatingActive={props.annotatingActive}
                setAnnotatingActive={props.setAnnotatingActive}
                patrolCompleted={props.patrolCompleted}
                assetNavigationActive={props.assetNavigationOpen}
                setAssetNavigationOpen={props.setAssetNavigationOpen}
            />
        </div>
    );
};

export default withRouter(PatrolInterfaceHeader);
