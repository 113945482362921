import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import ELRMileAndChain from "../../util/ELRMileAndChain";
import { asyncSideLoadGeometryFile, convertRange, useHover } from "./utils";
import { convertToTimezone } from "../../util/TimezoneUtils";

const geomHeadersSelector = (state) => state.trackGeometry.trackGeometryHeaders;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const convertToUTCSelector = (state) => state.userDetails.userConfig.convert_to_utc;
const geometryDataSelector = (state) => state.trackGeometry.data;

function useDataSeries({
    chart,
    elrData,
    usingDataFiles,
    dataTypes,
    zoomBounds,
    palette,
    exceedenceSpeed,
    comparisonSessionHeaders,
    comparisonOffset,
    verticalComparisonOffset,
}) {
    const store = useStore();
    const dispatch = useDispatch();

    const geomHeaders = useSelector(geomHeadersSelector);
    const convertToUTC = useSelector(convertToUTCSelector);
    const geometryData = useSelector(geometryDataSelector);

    const comparisonSessionData = useRef(null);
    const [comparisonSessionDataUpdated, setComparisonSessionDataUpdated] = useState(0);
    const [comparisonIndexOffset, setComparisonIndexOffset] = useState(null);
    const [reverseComparison, setReverseComparison] = useState(null);

    const elrDataRef = useRef([]);

    useEffect(() => {
        elrDataRef.current = elrData;
    }, [elrData]);

    const highlightGridIndex = useRef(-999);

    useEffect(() => {
        if (comparisonSessionHeaders !== null && comparisonSessionHeaders.length > 0) {
            comparisonSessionData.current = Array(comparisonSessionHeaders[comparisonSessionHeaders.length - 1].end + 1).fill({});
            comparisonSessionHeaders.forEach((f) => {
                comparisonSessionData.current[f.start] = { loaded: false };
            });
        } else {
            comparisonSessionData.current = null;
        }
        console.log("Comparison session data generated by effect");
        setComparisonSessionDataUpdated(new Date().getTime());
    }, [comparisonSessionHeaders, store, dispatch]);

    const centreLocation = useMemo(() => {
        const centreIndex = Math.round((zoomBounds[0] + zoomBounds[1]) / 2);
        if (!geometryData || !geometryData.length) {
            return null;
        }
        let data = geometryData[centreIndex];
        if (!data) {
            return null;
        }

        const centrePosition = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_mile_yards", {
            ELR: data.elr,
            MILE: data.miles,
            YARDS: data.yards,
            TRACK: data.trid,
        }).position;

        let upstream = data.upstream;
        if (upstream === null || upstream === undefined) {
            let searchIndex = centreIndex + 10;
            let nextData = searchIndex < geometryData.length ? geometryData[searchIndex] : geometryData[geometryData.length - 1];
            const nextPosition = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_mile_yards", {
                ELR: nextData.elr,
                MILE: nextData.miles,
                YARDS: nextData.yards,
                TRACK: nextData.trid,
            }).position;
            upstream = nextPosition > centrePosition;
        }

        const result = {
            elr: data.elr,
            track: data.trid,
            position: centrePosition,
            upstream,
            index: centreIndex,
        };

        console.log("Calculated centre position:", result);
        return result;
    }, [zoomBounds, geometryData]);

    const dimensions = useMemo(() => {
        const dimensions = [
            {
                name: "index",
                displayName: "Index",
                type: "number",
            },
            {
                name: "tacho",
                displayName: "Tachometer",
                type: "number",
            },
            {
                name: "timestamp",
                displayName: "Timestamp",
                type: "time",
            },
            {
                name: "speed_ms",
                displayName: "Speed",
                type: "number",
            },
            {
                name: "track_category",
                displayName: "Track Category",
                type: "ordinal",
            },
        ];

        dataTypes.forEach((dataType) => {
            const header = _.find(geomHeaders, { header_name: dataType });
            if (header) {
                const yLabel = _.get(header, ["info", "display_name"], header.name);
                dimensions.push({
                    name: usingDataFiles ? header.name : dataType,
                    displayName: yLabel,
                    type: "number",
                });
            }
        });
        return dimensions;
    }, [dataTypes, geomHeaders, usingDataFiles]);

    const nearbyDataset = useMemo(() => {
        return [
            {
                id: "nearby_dataset",
                transform: {
                    type: "aivr:nearbyData",
                    config: {
                        lowIndex: zoomBounds[0],
                        highIndex: zoomBounds[1],
                        dimensions,
                        usingDataFiles,
                    },
                },
                fromDatasetId: "overview_dataset",
            },
            {
                id: "exceedences_dataset",
                transform: {
                    type: "aivr:exceedences",
                    config: {
                        speed: exceedenceSpeed,
                    },
                },
                fromDatasetId: "nearby_dataset",
            },
        ];
    }, [dimensions, zoomBounds, usingDataFiles, exceedenceSpeed]);

    useEffect(() => {
        if (comparisonSessionHeaders === null || !comparisonSessionHeaders.length || centreLocation === null) {
            setComparisonIndexOffset(null);
            setReverseComparison(null);
            return;
        }
        const ratio = (centreLocation.position % 10) / 10;
        let firstIndex = null;
        let lastIndex = null;
        let upstream = null;
        for (let idx = 0; idx < comparisonSessionHeaders.length; idx++) {
            const f = comparisonSessionHeaders[idx];
            if (f.elr === centreLocation.elr && f.track === centreLocation.track) {
                if (f.position <= centreLocation.position && f.position + 10 > centreLocation.position) {
                    if (firstIndex === null) {
                        firstIndex = f.start;
                        if (f.upstream === true || f.upstream === false) {
                            upstream = f.upstream;
                        }
                        if (idx > 0 && upstream === null) {
                            const prev_file = comparisonSessionHeaders[idx - 1];
                            if (prev_file.elr === f.elr) {
                                upstream = f.position > prev_file.position;
                            }
                        }
                    }
                    lastIndex = f.end;
                    if (upstream === null && idx < comparisonSessionHeaders.length - 1) {
                        const next_file = comparisonSessionHeaders[idx + 1];
                        if (next_file.elr === f.elr) {
                            if (f.position < next_file.position) {
                                upstream = true;
                            } else if (f.position > next_file.position) {
                                upstream = false;
                            }
                        }
                    }
                }
            }
        }

        if (upstream) {
            setComparisonIndexOffset(Math.round(ratio * (lastIndex - firstIndex) + firstIndex) - centreLocation.index);
            setReverseComparison(!centreLocation.upstream);
        } else if (upstream === false) {
            setComparisonIndexOffset(Math.round(ratio * (firstIndex - lastIndex) + lastIndex) - centreLocation.index);
            setReverseComparison(centreLocation.upstream);
        }
        console.log("Recalculated comparison index offset and reverse");
    }, [comparisonSessionHeaders, centreLocation]);

    const comparisonDataset = useMemo(() => {
        let lowIndex = 0;
        let highIndex = 0;
        if (comparisonIndexOffset !== null && comparisonSessionData.current && comparisonSessionHeaders && comparisonSessionHeaders.length) {
            lowIndex = Math.ceil(zoomBounds[0] + comparisonIndexOffset + comparisonOffset);
            highIndex = Math.floor(zoomBounds[1] + comparisonIndexOffset + comparisonOffset);

            const visibleData = comparisonSessionHeaders.filter((_f) => _f.end >= lowIndex && _f.start <= highIndex);
            Promise.all(visibleData.map((data) => asyncSideLoadGeometryFile(data, comparisonSessionData.current))).then((updated) => {
                if (_.reduce(updated, (a, b) => a || b)) {
                    console.log("Track geom comparison data has updated");
                    setComparisonSessionDataUpdated(new Date().getTime());
                }
            });
        }

        return [
            {
                id: "comparison_dataset",
                transform: {
                    type: "aivr:comparisonData",
                    config: {
                        lowIndex,
                        highIndex,
                        viewIndex: Math.ceil(zoomBounds[0]),
                        reverse: reverseComparison,
                        data_files: comparisonSessionHeaders,
                        data: comparisonSessionData.current,
                        dimensions,
                    },
                },
                fromDatasetId: "nearby_dataset",
            },
        ];
    }, [
        dimensions,
        zoomBounds,
        comparisonIndexOffset,
        reverseComparison,
        comparisonSessionHeaders,
        comparisonSessionData,
        comparisonSessionDataUpdated,
        comparisonOffset,
    ]);

    const seriesScales = useMemo(() => {
        return dataTypes.map((dataType) => {
            const header = _.find(geomHeaders, { header_name: dataType });
            const header_type = header.name.split("_")[0] !== "DJ" ? header.name : "DJ";
            const scale = header.info.scale;
            const minTick = Math.floor(parseFloat(header.info.min) / scale);
            const maxTick = Math.ceil(parseFloat(header.info.max) / scale);
            return {
                type: usingDataFiles ? header.name : dataType,
                uniq_type: usingDataFiles ? header_type : dataType,
                name: header.name,
                displayName: header.info.display_name,
                scale: scale,
                units: header.unit,
                minTick: minTick,
                maxTick: maxTick,
                ticks: maxTick - minTick,
                minScale: minTick * scale,
                maxScale: maxTick * scale,
            };
        });
    }, [dataTypes, geomHeaders, usingDataFiles]);

    const seriesGrids = useMemo(() => {
        const tickCount = _.sumBy(_.uniqBy(seriesScales, "uniq_type"), "ticks");

        const pcPerTick = 90 / tickCount;
        let cumulativeOffset = 0;
        return _.uniqBy(seriesScales, "uniq_type").map((scale) => {
            const top_val = cumulativeOffset * pcPerTick;
            cumulativeOffset += scale.ticks;
            const bottom_val = 100 - cumulativeOffset * pcPerTick;
            return {
                id: `${scale.uniq_type}_grid`,
                top: `${top_val}%`,
                bottom: `${bottom_val}%`,
            };
        });
    }, [seriesScales]);

    const gridIds = useMemo(() => seriesGrids.map((grid) => grid.id), [seriesGrids]);

    const seriesXAxis = useMemo(() => {
        return _.uniqBy(seriesScales, "uniq_type").map((scale) => {
            return {
                id: `${scale.uniq_type}_x`,
                type: "value",
                min: zoomBounds[0],
                max: zoomBounds[1],
                gridId: `${scale.uniq_type}_grid`,
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: palette.yZero,
                    },
                },
            };
        });
    }, [seriesScales, palette, zoomBounds]);

    const seriesYAxis = useMemo(() => {
        return _.uniqBy(seriesScales, "uniq_type").flatMap((scale) => {
            return [
                {
                    id: `${scale.uniq_type}_y`,
                    min: scale.minScale,
                    max: scale.maxScale,
                    interval: scale.scale,
                    gridId: `${scale.uniq_type}_grid`,
                    position: "left",
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        formatter: (value, _) => {
                            const highlightIndex = highlightGridIndex.current;
                            const gridIndex = gridIds.indexOf(`${scale.uniq_type}_grid`);

                            if (value === 0) {
                                let axisText;
                                if (scale.uniq_type === "DJ") {
                                    axisText = `Dip Left ${scale.scale}${scale.units}/div\n\nDip Right ${scale.scale}${scale.units}/div`;
                                } else {
                                    axisText = `${scale.displayName}\n${scale.scale}${scale.units}/div`;
                                }

                                if (gridIndex === highlightIndex) {
                                    return `{bold|${axisText}}`;
                                } else {
                                    return axisText;
                                }
                            } else if (highlightIndex >= 0) {
                                const newScale = seriesScales[highlightIndex];

                                if (gridIndex === highlightIndex) {
                                    return value;
                                } else if (gridIndex === highlightIndex - 1) {
                                    if (value === scale.minScale) {
                                        // Overlaps with primary scale
                                        return "";
                                    }
                                    const step = (value - scale.minScale) / scale.scale;
                                    return step * newScale.scale + newScale.maxScale;
                                } else if (gridIndex === highlightIndex + 1) {
                                    if (value === scale.maxScale) {
                                        // Overlaps with primary scale
                                        return "";
                                    }
                                    const step = (scale.maxScale - value) / scale.scale;
                                    return newScale.minScale - step * newScale.scale;
                                } else {
                                    return "";
                                }
                            } else {
                                return "";
                            }
                        },
                        color: palette.label,
                        rich: {
                            bold: {
                                color: palette.lineHighlight,
                            },
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: palette.div,
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: palette.xZero,
                        },
                        onZero: false,
                    },
                },
                {
                    id: `${scale.uniq_type}_comparison_y`,
                    min: scale.minScale - verticalComparisonOffset * scale.scale,
                    max: scale.maxScale - verticalComparisonOffset * scale.scale,
                    interval: scale.scale,
                    gridId: `${scale.uniq_type}_grid`,
                    position: "left",
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                        onZero: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
            ];
        });
    }, [seriesScales, gridIds, highlightGridIndex, palette, verticalComparisonOffset]);

    const series = useMemo(() => {
        return seriesScales.flatMap((scale, _index) => {
            const seriesId = `${scale.type}_series`;
            return [
                {
                    id: seriesId,
                    _index,
                    datasetId: "nearby_dataset",
                    xAxisId: `${scale.uniq_type}_x`,
                    yAxisId: `${scale.uniq_type}_y`,
                    gridId: `${scale.uniq_type}_grid`,
                    type: "line",
                    showSymbol: false,
                    clip: false,
                    connectNulls: false,
                    sampling: false,
                    animation: false,
                    encode: {
                        x: "index",
                        y: scale.type,
                    },
                    lineStyle: {
                        width: palette.lineWidth,
                        color: palette.line,
                    },
                    emphasis: {
                        disabled: true,
                        scale: false,
                        lineStyle: {
                            color: palette.lineHighlight,
                        },
                    },
                    axisPointer: {
                        show: false,
                    },
                },
                {
                    id: seriesId + "_comparison",
                    _index,
                    datasetId: "comparison_dataset",
                    xAxisId: `${scale.uniq_type}_x`,
                    yAxisId: `${scale.uniq_type}_comparison_y`,
                    type: "line",
                    showSymbol: false,
                    clip: false,
                    connectNulls: false,
                    sampling: false,
                    animation: false,
                    encode: {
                        x: "index",
                        y: scale.type,
                    },
                    lineStyle: {
                        width: palette.lineWidth,
                        color: palette.comparisonLine,
                    },
                    emphasis: {
                        disabled: true,
                        scale: false,
                    },
                    axisPointer: {
                        show: false,
                    },
                },
            ];
        });
    }, [seriesScales, palette]);

    const exceedences = useMemo(() => {
        return _.uniqBy(seriesScales, "uniq_type").flatMap((scale, _index) => {
            return [
                {
                    id: `${scale.type}_exceedence_neg`,
                    _index,
                    datasetId: "exceedences_dataset",
                    xAxisId: `${scale.uniq_type}_x`,
                    yAxisId: `${scale.uniq_type}_y`,
                    type: "line",
                    showSymbol: false,
                    clip: false,
                    connectNulls: false,
                    sampling: false,
                    animation: false,
                    encode: {
                        x: "index",
                        y: `${scale.name}_NEG`,
                    },
                    lineStyle: {
                        color: palette.exceedence,
                        type: [10, 10],
                        width: 1,
                    },
                    emphasis: {
                        disabled: true,
                        scale: false,
                    },
                    axisPointer: {
                        show: false,
                    },
                },
                {
                    id: `${scale.type}_exceedence_pos`,
                    _index,
                    datasetId: "exceedences_dataset",
                    xAxisId: `${scale.uniq_type}_x`,
                    yAxisId: `${scale.uniq_type}_y`,
                    type: "line",
                    showSymbol: false,
                    clip: false,
                    connectNulls: false,
                    sampling: false,
                    animation: false,
                    encode: {
                        x: "index",
                        y: `${scale.name}_POS`,
                    },
                    lineStyle: {
                        color: palette.exceedence,
                        type: [10, 10],
                        width: 1,
                    },
                    emphasis: {
                        disabled: true,
                        scale: false,
                    },
                    axisPointer: {
                        show: false,
                    },
                },
            ];
        });
    }, [seriesScales, palette]);

    const seriesTooltip = useMemo(() => {
        const axisPointer = {
            type: "line",
            axis: "x",
            label: {
                show: false,
            },
        };

        return [
            {
                trigger: "axis",
                axisPointer,
                formatter: () => {
                    const xAxes = chart.getOption().xAxis;
                    const overviewAxis = xAxes.find((axis) => axis.id === "overview_x");
                    if (overviewAxis) {
                        const state = store.getState();
                        const axisValue = overviewAxis.axisPointer.value;
                        const geometryIndex = Math.round(axisValue);
                        const geometryData = state.trackGeometry.data;
                        const elrUnits = elrUnitsSelector(state);

                        let data = geometryData[geometryIndex];

                        if (data && data.timestamp) {
                            const location = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_mile_yards", {
                                ELR: data.elr,
                                MILE: data.miles,
                                YARDS: data.yards,
                                TRACK: data.trid,
                            });

                            const date = new Date(data.timestamp);
                            const timestamp = convertToTimezone(date, convertToUTC);
                            const speed = data.speed_ms;
                            return [
                                `<b>Location: ${location.to_string(elrUnits)}</b>`,
                                `Timestamp: ${timestamp}`,
                                `Track Category: ${data.track_category === null ? "Unknown" : data.track_category}`,
                                `Line Speed: ${_.isNil(data.line_speed) ? "Unknown" : data.line_speed}`,
                                `Vehicle Speed: ${Math.round(speed * 10) / 10} m/s (${Math.round(speed * 2.23694)} mph)`,
                                ...seriesScales.map((scale) => {
                                    const label = scale.displayName;
                                    if (highlightGridIndex.current >= 0 && highlightGridIndex.current === gridIds.indexOf(`${scale.uniq_type}_grid`)) {
                                        return `<b>${label}: ${data[scale.type]}</b>`;
                                    } else {
                                        return `${label}: ${data[scale.type]}`;
                                    }
                                }),
                            ].join("<br/>");
                        } else if (elrDataRef.current && elrDataRef.current.length) {
                            data = elrDataRef.current.find((_f) => _f.start <= axisValue && _f.end >= axisValue);
                            if (!data) {
                                return "Unknown";
                            }
                            let startIdx = data.start;
                            let endIdx = data.end;
                            let startPos = data.startPos;
                            let endPos = data.endPos;
                            let track_category = data.track_category;
                            let line_speed = data.line_speed;
                            if (data.intermediates && data.intermediates.length) {
                                const lowerIntermediates = data.intermediates.filter((_i) => _i[0] <= axisValue);
                                if (lowerIntermediates.length) {
                                    [startIdx, startPos, track_category, line_speed] = lowerIntermediates[lowerIntermediates.length - 1];
                                }
                                const higherIntermediates = data.intermediates.filter((_i) => _i[0] >= axisValue);
                                if (higherIntermediates.length) {
                                    endIdx = higherIntermediates[0][0];
                                    endPos = higherIntermediates[0][1];
                                }
                            }
                            let position = convertRange(axisValue, startIdx, endIdx, startPos, endPos);
                            const location = new ELRMileAndChain("ELR Mile & Chain", data.elr, position, data.track);
                            return [
                                `<b>Location: ${location.to_string(elrUnits)}</b>`,
                                `Track Category: ${track_category === null ? "Unknown" : track_category}`,
                                `Line Speed: ${_.isNil(line_speed) ? "Unknown" : line_speed}`,
                            ].join("<br/>");
                        } else {
                            return "Unknown";
                        }
                    } else {
                        return "Unknown";
                    }
                },
            },
        ];
    }, [seriesScales, gridIds, store, chart, convertToUTC]);

    const seriesMap = useMemo(
        () =>
            series.reduce((map, series) => {
                if (map.hasOwnProperty(series.gridId)) {
                    map[series.gridId].push(series);
                } else {
                    map[series.gridId] = [series];
                }
                return map;
            }, {}),
        [series],
    );

    const onHoverStateChanged = useCallback(
        (hoverStates) => {
            if (chart) {
                Object.entries(hoverStates).forEach(([key, value]) => {
                    const seriesCollection = seriesMap[key];
                    seriesCollection.forEach((series) => {
                        chart.dispatchAction({
                            type: value ? "highlight" : "downplay",
                            seriesId: series.id,
                        });
                        if (value) {
                            highlightGridIndex.current = gridIds.indexOf(series.gridId);
                        } else if (highlightGridIndex.current === gridIds.indexOf(series.gridId)) {
                            highlightGridIndex.current = -999;
                        }
                    });
                });

                chart.setOption({
                    yAxis: [],
                });
            }
        },
        [chart, seriesMap, gridIds],
    );

    useHover(chart, gridIds, onHoverStateChanged);

    const combinedSeries = useMemo(() => {
        return [...series, ...exceedences];
    }, [series, exceedences]);

    const combinedDatasets = useMemo(() => {
        return [...nearbyDataset, ...comparisonDataset];
    }, [nearbyDataset, comparisonDataset]);

    return useMemo(
        () => ({
            dataset: combinedDatasets,
            grid: seriesGrids,
            xAxis: seriesXAxis,
            yAxis: seriesYAxis,
            series: combinedSeries,
            tooltip: seriesTooltip,
        }),
        [combinedDatasets, seriesGrids, seriesXAxis, seriesYAxis, combinedSeries, seriesTooltip],
    );
}

export default useDataSeries;
