import { combineReducers } from "redux";
import _ from "lodash";

export const SET_AUTOCOMPLETE_VALUES = "SET_AUTOCOMPLETE_VALUES";

function autoCompleteValues(state = {}, action) {
    if (action.type === SET_AUTOCOMPLETE_VALUES) {
        state = _.cloneDeep(state);
        state[action.systemID] = action.list;
    }

    return state;
}

const searchLocationData = combineReducers({
    autoCompleteValues,
});

export default searchLocationData;
