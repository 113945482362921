import React from "react";
import { connect } from "react-redux";
import { getRouteMetadata } from "redux/actions/index";
import { getAbsoluteTimestamp } from "../util/PlaylistUtils";
import _ from "lodash";

const MIN_TIME_BETWEEN_METADATA_UPDATES = 1000;

class BaseMetadataFeed extends React.PureComponent {
    componentDidMount() {
        this.updateTimer = null;
        this.timeOfLastUpdate = 0;
        this.componentDidUpdate({});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.routeID && this.props.lowerTimestamp > 0) {
            if (
                this.props.upperTimestamp !== prevProps.upperTimestamp ||
                this.props.lowerTimestamp !== prevProps.lowerTimestamp ||
                this.props.routeID !== prevProps.routeID
            ) {
                const now = new Date().getTime();
                clearTimeout(this.updateTimer);
                if (now - this.timeOfLastUpdate >= MIN_TIME_BETWEEN_METADATA_UPDATES) {
                    this.performMetadataUpdate();
                } else {
                    this.updateTimer = setTimeout(this.performMetadataUpdate, 250);
                }
            }
        }
    }

    performMetadataUpdate = () => {
        this.timeOfLastUpdate = new Date().getTime();
        this.props.dispatch(getRouteMetadata(this.props.routeID, this.props.items, this.props.lowerTimestamp, this.props.upperTimestamp));
    };

    render() {
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

const mapStateToProps = (state, ownProps) => {
    let absoluteTimestamp;
    let playlistPositionData = state.playlist.position;

    if (window.location.pathname.startsWith("/widget")) {
        const sidekickData = _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], null);
        playlistPositionData = sidekickData.position;
    }

    const routeID = state.playlist.data.routeID;

    if (ownProps.timestamp) {
        absoluteTimestamp = ownProps.timestamp;
    } else {
        const isVideo = playlistPositionData.isVideo;
        const sourceIndex = playlistPositionData.sourceIndex;

        const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
        const index = playlistPositionData.currentIndex;
        const offset = playlistPositionData.currentTimeOffset || 0;
        absoluteTimestamp = getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
    }

    let steppedTimestamp = Math.floor(absoluteTimestamp / ownProps.stepSize) * ownProps.stepSize;
    let lowerTimestamp = steppedTimestamp - ownProps.lowerOffset;
    let upperTimestamp = steppedTimestamp + ownProps.upperOffset;

    return {
        routeID: routeID,
        lowerTimestamp: lowerTimestamp,
        upperTimestamp: upperTimestamp,
    };
};

const MetadataFeed = connect(mapStateToProps)(BaseMetadataFeed);

MetadataFeed.defaultProps = {
    items: [],
    stepSize: 30,
    lowerOffset: 30,
    upperOffset: 30,
};

export default MetadataFeed;
