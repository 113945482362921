import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import LoadingOverlay from "../components/util/LoadingOverlay";
import { addUserSeat, changeSeatType, getUserSeats, removeUserSeat } from "../redux/actions/index";
import _ from "lodash";
import { Button, Modal, Select } from "antd";
import { faUser, faUserCog, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons";

const { confirm } = Modal;

const range = (start, end, length = end - start) => Array.from({ length }, (_, i) => start + i);

class UserSeatManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            newUserEmail: "",
            newUserRole: "user",
            newSeatPassword: null,
        };
    }

    updateNewUserEmail = (evt) => {
        this.setState({
            newUserEmail: evt.target.value,
        });
    };

    updateNewUserRole = (newUserRole) => {
        this.setState({
            newUserRole,
        });
    };

    addUserSeat = () => {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.newUserEmail)) {
            this.props.addUserSeat(this.state.newUserEmail, this.state.newUserRole, {}, (password, error) => {
                if (error) {
                    Modal.error({
                        title: "Error adding user",
                        content: error,
                    });
                } else {
                    this.setState({
                        newSeatPassword: password,
                        newUserEmail: "",
                        newUserRole: "user",
                    });
                }
            });
        } else {
            Modal.error({
                title: "Error adding user",
                content: "Invalid email address",
            });
        }
    };

    clearSeatPassword = () => {
        this.setState({
            newSeatPassword: null,
        });
    };

    updateNewUserPassword = (pw) => {
        this.setState({
            newSeatPassword: pw,
        });
    };

    componentDidMount() {
        this.props.getUserSeats();
    }

    promoteUser = (email) => {
        confirm({
            title: "Change User Role",
            content: `Are you sure you want to change ${email}'s role to administrator? ` + `They will be able to perform administrative tasks as a result.`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                this.props.changeSeatType(email, "admin");
            },
            onCancel: () => {},
        });
    };

    demoteUser = (email) => {
        confirm({
            title: "Change User Role",
            content: `Are you sure you want to change ${email}'s role to user? ` + `They will no longer be able to perform administrative tasks as a result.`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                this.props.changeSeatType(email, "user");
            },
            onCancel: () => {},
        });
    };

    deleteUserSeat = (email) => {
        confirm({
            title: "Delete User",
            content: `Are you sure you want to delete user ${email}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                this.props.removeUserSeat(email);
            },
            onCancel: () => {},
        });
    };

    seat = (index) => {
        let content;
        let className = index % 2 === 0 ? "evenRow" : "oddRow";

        if (index < this.props.adminSeats.length) {
            const email = this.props.adminSeats[index].email;
            const seatType = this.props.adminSeats[index].seat_type;
            const icon = seatType === "super_admin" ? faUserCrown : faUserCog;
            const editable = this.props.currentEmail !== email && seatType !== "super_admin";
            const title = this.props.currentEmail === email ? "You" : seatType === "super_admin" ? "Super Admin" : "Administrator";

            content = (
                <>
                    <td className="adminTableCellID">
                        <FontAwesomeIcon
                            icon={icon}
                            color="#D4AF37"
                            size="2x"
                        />
                    </td>
                    <td className="adminTableCellID email">
                        {email} ({title})
                    </td>
                    <td>
                        {editable && (
                            <>
                                <button
                                    type="button"
                                    className="adminChangeUserRoleButton"
                                    onClick={_.partial(this.demoteUser, email)}>
                                    Change Role
                                </button>
                                <button
                                    type="button"
                                    className="adminDeleteUserButton"
                                    onClick={_.partial(this.deleteUserSeat, email)}>
                                    Delete User
                                </button>
                            </>
                        )}
                    </td>
                </>
            );
        } else {
            const userIndex = index - this.props.adminSeats.length;
            if (userIndex < this.props.userSeats.length) {
                const email = this.props.userSeats[userIndex].email;
                content = (
                    <>
                        <td className="adminTableCellID">
                            <FontAwesomeIcon
                                icon={faUser}
                                size="2x"
                            />
                        </td>
                        <td className="adminTableCellID email">{email}</td>
                        <td>
                            <button
                                type="button"
                                className="adminChangeUserRoleButton"
                                onClick={_.partial(this.promoteUser, email)}>
                                Change Role
                            </button>
                            <button
                                type="button"
                                className="adminDeleteUserButton"
                                onClick={_.partial(this.deleteUserSeat, email)}>
                                Delete User
                            </button>
                        </td>
                    </>
                );
            } else if (userIndex === this.props.userSeats.length) {
                content = this.newSeat();
            } else {
                content = this.emptySeat();
            }
        }

        return (
            <tr
                className={className}
                key={index}>
                {content}
            </tr>
        );
    };

    newSeat = () => (
        <>
            <td className="adminTableCellID">
                <FontAwesomeIcon
                    icon={faUserPlus}
                    className={"adminNewUserIcon"}
                    size="2x"
                />
            </td>
            <td className="adminTableCellRoute">
                <input
                    type="email"
                    className="adminNewUserEmail"
                    placeholder="Enter user's email address"
                    value={this.state.newUserEmail}
                    onChange={this.updateNewUserEmail}
                />
            </td>
            <td>
                <Select
                    className="adminNewUserRoleSelect"
                    value={this.state.newUserRole}
                    onSelect={this.updateNewUserRole}>
                    <Select.Option value={"admin"}>Administrator</Select.Option>
                    <Select.Option value={"user"}>User</Select.Option>
                </Select>
                <button
                    type="button"
                    className="adminNewUserButton"
                    onClick={this.addUserSeat}>
                    Add User
                </button>
            </td>
        </>
    );

    emptySeat = () => (
        <>
            <td className="adminTableCellID">
                <FontAwesomeIcon
                    icon={faUser}
                    className="FadedIcon"
                    size="2x"
                />
            </td>
            <td className="adminTableCellID">Empty seat</td>
            <td />
            <td />
        </>
    );

    render() {
        const seatCount = this.props.seatLimit >= 0 ? this.props.seatLimit : this.props.adminSeats.length + this.props.userSeats.length + 1;

        const seatsInUse = this.props.adminSeats.length + this.props.userSeats.length;
        const seatsAvailable = Math.max(0, seatCount - seatsInUse);

        let userTable = (
            <table className="adminTable PaddedCells">
                <thead>
                    <tr>
                        <th className="adminTableHeader" />
                        <th className="adminTableHeader">Email Address</th>
                        <th className="adminTableHeader">{this.props.seatLimit >= 0 ? `${seatsAvailable} of ${seatCount}` : "Unlimited"} Seats Available</th>
                    </tr>
                </thead>
                <tbody>{_.map(range(0, Math.max(seatsInUse, seatCount)), (index) => this.seat(index))}</tbody>
            </table>
        );

        return (
            <div className="adminTableDiv">
                <div>
                    {userTable}
                    <LoadingOverlay loading={this.props.loading} />
                </div>
                <Modal
                    visible={!!this.state.newSeatPassword}
                    title={"New user Password"}
                    onOk={this.clearSeatPassword}
                    onCancel={this.clearSeatPassword}
                    closable={false}
                    maskClosable={false}
                    footer={[
                        <Button
                            key="submit"
                            type="primary"
                            onClick={this.clearSeatPassword}>
                            Close
                        </Button>,
                    ]}>
                    <p>This user's password is:</p>
                    <p className="SeatPasswordDisplay">{this.state.newSeatPassword}</p>
                    <p>Please copy or write down this password as it cannot be shown again.</p>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSeats: () => dispatch(getUserSeats()),
        addUserSeat: (email, seatType, seatDetails, callback) => dispatch(addUserSeat(email, seatType, seatDetails, callback)),
        removeUserSeat: (email) => dispatch(removeUserSeat(email)),
        changeSeatType: (email, newSeatType) => dispatch(changeSeatType(email, newSeatType)),
    };
};

const mapStateToProps = (state) => {
    return {
        loading: state.admin.userSeats === null,
        currentEmail: state.userDetails.email,
        seatLimit: _.get(state.admin.userSeats, "seatLimit", 0),
        adminSeats: _.filter(_.get(state.admin.userSeats, "userSeats", []), (seat) => seat.seat_type === "super_admin" || seat.seat_type === "admin"),
        userSeats: _.filter(_.get(state.admin.userSeats, "userSeats", []), (seat) => seat.seat_type === "user"),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSeatManagement);
