import React, { useMemo, useEffect, useCallback } from "react";
import { setImageAdjustments } from "../../../redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Slider, Tooltip } from "antd";

import { faAdjust as lightAdjust, faSun as lightSun } from "@fortawesome/pro-light-svg-icons";
import { faSun as solidSun } from "@fortawesome/free-solid-svg-icons";
import { faAdjust as solidAdjust } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { calculatePatrolDeviceConfig } from "components/util/Geometry";

const adjustmentsSelector = (state) => state.railInspection.imageAdjustments;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const railImagesSelector = (state) => {
    return _.get(state.schemaInterface.sessions, [state.schemaInterface.selectedSession], []);
};

const ImageAdjustments = ({ secondaryRailConfig }) => {
    const dispatch = useDispatch();
    const currentAdjustments = useSelector(adjustmentsSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const railInspectImageConfig = useSelector(railImageConfigObjectSelector);
    const railImages = useSelector(railImagesSelector);

    const railImageConfig = useMemo(() => {
        if (!_.isEmpty(railInspectImageConfig)) {
            return railInspectImageConfig;
        }

        return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [railImageConfigs, railImages, railInspectImageConfig]);

    const imageAdjustmentGroups = useMemo(() => {
        let groups = [];

        if (_.isEmpty(railImageConfig.inspection_images)) {
            return groups;
        }

        groups = [
            {
                name: "Primary",
                images: _.get(railImageConfig, ["inspection_images"], []).filter((item) => !item.showInModal) || [],
            },
        ];

        if (secondaryRailConfig && secondaryRailConfig.inspection_images && secondaryRailConfig.inspection_images.length) {
            groups.push({
                name: "Secondary",
                images: _.get(secondaryRailConfig, ["inspection_images"], []).filter((item) => !item.showInModal),
            });
        }

        return groups;
    }, [railImageConfig, secondaryRailConfig]);

    const initialiseAdjustments = useCallback(
        (forceRefresh = false) => {
            let adjustments = {};

            imageAdjustmentGroups.forEach((group) => {
                adjustments[group.name] = {};

                const imagesGroup = _.get(group, "images", null);
                if (imagesGroup) {
                    imagesGroup.forEach((item) => {
                        adjustments[group.name][item.name] = {
                            brightness: item.default_brightness || 0,
                            contrast: 0,
                            visibility: true,
                        };
                    });
                }
                adjustments["initialised"] = true;
            });

            // only dispatch if settings not initialised yet or forceRefresh - this helps with whole scroller getting rerendered when settings window is re-opened
            if (!currentAdjustments.initialised || forceRefresh) {
                dispatch(setImageAdjustments(adjustments));
            }
        },
        [currentAdjustments, dispatch, imageAdjustmentGroups],
    );

    useEffect(() => {
        if (!_.isEmpty(imageAdjustmentGroups)) {
            initialiseAdjustments();
        }
    }, [imageAdjustmentGroups]);

    const handleChange = (val, updatePath) => {
        let adj = _.cloneDeep(currentAdjustments);
        adj = _.set(adj, updatePath, val);
        dispatch(setImageAdjustments(adj));
    };

    const handleReset = () => {
        initialiseAdjustments(true);
    };

    return (
        <div className="inspectRail__Adjustments">
            <h3>Image Adjustments</h3>
            {imageAdjustmentGroups.map((group) => {
                return (
                    <div className="inspectRail__Adjustments__Item-Container">
                        <Divider className="AdjustmentsDivider">{group.name}</Divider>
                        {group.images &&
                            group.images.map((imageColumn) => {
                                const columnAdjustments = _.get(currentAdjustments, [group.name, imageColumn.name], {});

                                const visibility = _.get(columnAdjustments, ["visibility"], false);
                                const brightness = _.get(columnAdjustments, ["brightness"], false);
                                const contrast = _.get(columnAdjustments, ["contrast"], false);

                                let eyeIcon = faEye;
                                if (!visibility) {
                                    eyeIcon = faEyeSlash;
                                }

                                return (
                                    <>
                                        <div className="inspectRail__Adjustments__Item__Header">
                                            <p>{imageColumn.name}</p>
                                            <div
                                                className="inspectRail__Adjustments__Item__Hide"
                                                onClick={(_) => handleChange(!visibility, `${group.name}.${imageColumn.name}.visibility`)}>
                                                <Tooltip title="Toggle Visibility">
                                                    <FontAwesomeIcon icon={eyeIcon} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="inspectRail__Adjustments__Item">
                                            <Tooltip
                                                title="Brightness"
                                                placement="top">
                                                <FontAwesomeIcon icon={lightSun} />
                                            </Tooltip>
                                            <Slider
                                                step={0.1}
                                                min={-1}
                                                max={1}
                                                onChange={(e) => handleChange(e, `${group.name}.${imageColumn.name}.brightness`)}
                                                value={brightness}
                                            />
                                            <FontAwesomeIcon icon={solidSun} />
                                        </div>
                                        <div className="inspectRail__Adjustments__Item">
                                            <Tooltip
                                                title="Contrast"
                                                placement="top">
                                                <FontAwesomeIcon icon={lightAdjust} />
                                            </Tooltip>
                                            <Slider
                                                step={0.1}
                                                min={-1}
                                                max={1}
                                                onChange={(e) => handleChange(e, `${group.name}.${imageColumn.name}.contrast`)}
                                                value={contrast}
                                            />
                                            <FontAwesomeIcon icon={solidAdjust} />
                                        </div>
                                    </>
                                );
                            })}
                    </div>
                );
            })}

            <div className="inspectRail__Adjustments__Item-Container">
                <button
                    className="inspection-default-button center"
                    onClick={handleReset}>
                    Reset
                </button>
            </div>
        </div>
    );
};

export default ImageAdjustments;
