import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import _ from "lodash";
import { createAdminMarker, getMarkerConfigurations } from "../redux/actions/index";
import MarkerCard from "./MarkerCard";
import LoadingOverlay from "../components/util/LoadingOverlay";
import { Modal, Button, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import IconEditor from "./IconEditor";

import { CirclePicker } from "react-color";
import { ReactComponent as SprayCanSvg } from "../icons/pins/spray-can.svg";
import { ReactComponent as TreeSvg } from "../icons/pins/tree.svg";
import { ReactComponent as TrafficLightSvg } from "../icons/pins/traffic-light.svg";
import { ReactComponent as PersonSvg } from "../icons/pins/person.svg";
import { ReactComponent as ArchwaySvg } from "../icons/pins/archway.svg";
import { ReactComponent as ExclamationTriangleSvg } from "../icons/pins/exclamation-triangle.svg";
import { ReactComponent as BaliseSvg } from "../icons/pins/balise.svg";
import { ReactComponent as AxleCounterSvg } from "../icons/pins/axle-counter.svg";
import { ReactComponent as Model3dSvg } from "../icons/pins/3d-model.svg";
import { ReactComponent as BallastBagSvg } from "../icons/pins/ballast-bag.svg";
import { ReactComponent as BridgeSvg } from "icons/pins/bridge.svg";
import { ReactComponent as LimitedClearanceSvg } from "icons/pins/limited-clearance.svg";
import { ReactComponent as PointSvg } from "icons/pins/point.svg";
import { ReactComponent as ScrapRailSvg } from "icons/pins/scrap-rail.svg";
import { ReactComponent as SpeedBoardSvg } from "icons/pins/speed-board.svg";
import { ReactComponent as TunnelSvg } from "icons/pins/tunnel.svg";
import { ReactComponent as ThermalHotspotSvg } from "icons/pins/thermal-hotspot.svg";
import { ReactComponent as KonuxSvg } from "icons/pins/konux.svg";

class MarkerManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anprWindowVisible: false,
            routeNameUpdate: {},
            newMarkerModalVisible: false,
            selectedColour: "#000000",
            selectedIcon: "spray-can",
            markerName: "",
            markerImportance: 0,
            markerTagColour: "#ffffff",
        };
    }

    showModal = () => {
        console.log("Showing modal");
        this.setState({
            newMarkerModalVisible: true,
        });
    };

    isMarkerNameWhitespace = () => {
        return /^\s*$/.test(this.state.markerName) && !this.state.markerName.trim().length;
    };

    handleInputChange = (event) => {
        this.setState({ markerName: event.target.value });
    };

    handleImportanceChange = (event) => {
        this.setState({ markerImportance: parseInt(event.target.value) });
    };

    handleOk = () => {
        console.log("Creating new icon", this.state.markerName, this.state.selectedIcon, this.state.selectedColour);

        this.props.dispatch(
            createAdminMarker(
                this.state.markerName,
                this.state.selectedIcon,
                this.state.selectedColour,
                this.state.markerImportance,
                this.state.markerTagColour,
                () => {
                    this.props.dispatch(getMarkerConfigurations());
                    this.setState({
                        newMarkerModalVisible: false,
                        markerName: "",
                        markerImportance: 0,
                        markerTagColour: "#ffffff",
                    });
                    notification.success({
                        message: "Success",
                        description: "Successfully added new marker",
                    });
                },
            ),
        );
    };

    handleCancel = () => {
        this.setState({
            newMarkerModalVisible: false,
            markerName: "",
            markerImportance: 0,
            markerTagColour: "#ffffff",
        });
    };

    renderSessionRows = () => {
        let markers = this.props.markers;

        if (markers) {
            let rowsToReturn = [];

            _.orderBy(Object.values(markers), [
                function (marker) {
                    return marker.type.toLowerCase();
                },
            ]).forEach((markerData) => {
                rowsToReturn.push(
                    <MarkerCard
                        markerObj={markerData}
                        key={markerData.id}
                    />,
                );
            });

            return rowsToReturn;
        } else {
            return null;
        }
    };

    changeSelectedColor = (colour) => {
        this.setState({
            selectedColour: colour.hex,
        });
    };

    renderIcons = () => {
        const iconList = [
            { element: <SprayCanSvg style={{ fill: this.state.selectedColour }} />, iconName: "spray-can" },
            { element: <TreeSvg style={{ fill: this.state.selectedColour }} />, iconName: "tree" },
            { element: <TrafficLightSvg style={{ fill: this.state.selectedColour }} />, iconName: "traffic-light" },
            { element: <PersonSvg style={{ fill: this.state.selectedColour }} />, iconName: "male" },
            { element: <ArchwaySvg style={{ fill: this.state.selectedColour }} />, iconName: "archway" },
            { element: <ExclamationTriangleSvg style={{ fill: this.state.selectedColour }} />, iconName: "exclamation-triangle" },
            { element: <BaliseSvg style={{ fill: this.state.selectedColour }} />, iconName: "balise" },
            { element: <AxleCounterSvg style={{ fill: this.state.selectedColour }} />, iconName: "axleCounter" },
            { element: <Model3dSvg style={{ fill: this.state.selectedColour }} />, iconName: "3dModel" },
            { element: <BallastBagSvg style={{ fill: this.state.selectedColour }} />, iconName: "ballast-bag" },
            { element: <BridgeSvg style={{ fill: this.state.selectedColour }} />, iconName: "bridge" },
            { element: <LimitedClearanceSvg style={{ fill: this.state.selectedColour }} />, iconName: "limited-clearance" },
            { element: <PointSvg style={{ fill: this.state.selectedColour }} />, iconName: "point" },
            { element: <ScrapRailSvg style={{ fill: this.state.selectedColour }} />, iconName: "scrap-rail" },
            { element: <SpeedBoardSvg style={{ fill: this.state.selectedColour }} />, iconName: "speed-board" },
            { element: <TunnelSvg style={{ fill: this.state.selectedColour }} />, iconName: "tunnel" },
            { element: <ThermalHotspotSvg style={{ fill: this.state.selectedColour }} />, iconName: "thermalHotspot" },
            { element: <KonuxSvg style={{ fill: this.state.selectedColour }} />, iconName: "konux" },
        ];

        return iconList.map((icon) => {
            let selectedClass = "";

            if (icon.iconName === this.state.selectedIcon) {
                selectedClass = "selectedIconBlack";
            }

            return (
                <div
                    className={"individualIconDiv " + selectedClass}
                    key={icon.iconName}
                    onClick={() => this.selectIcon(icon.iconName)}>
                    {icon.element}
                </div>
            );
        });
    };

    selectIcon = (iconName) => {
        this.setState({
            selectedIcon: iconName,
        });
    };

    cheangeTagLabelColour = (value) => {
        this.setState({
            markerTagColour: value,
        });
    };

    onButtonResetDefaultClick = () => {
        this.setState({
            markerTagColour: "#ffffff",
        });
    };

    render() {
        return (
            <div className="MarkerManagmentMainContiamer">
                {this.props.displayIconConfiguration && <IconEditor />}
                <div className="AdminPageMainHeader">
                    <span className="Header">Detection Configuration</span>
                </div>
                <button
                    className="AddMarkerButton"
                    onClick={this.showModal}>
                    <FontAwesomeIcon
                        icon={faPlusCircle}
                        size="1x"
                    />
                    <h3 style={{ marginLeft: 5 }}>Add New Detection Type</h3>
                </button>
                <div className="MarkerGrid">{this.renderSessionRows()}</div>
                <LoadingOverlay loading={!_.isObject(this.props.markers)} />

                <Modal
                    title="New Detection Type"
                    visible={this.state.newMarkerModalVisible}
                    okButtonProps={{
                        disabled: !this.state.markerName || this.isMarkerNameWhitespace(),
                    }}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{ width: "80%" }}
                    className="newDetectionTypeModal">
                    <table className="newDetectionTypeTable">
                        <tbody>
                            <tr>
                                <td className="leftColumn">
                                    <span>Marker Name: </span>
                                </td>
                                <td className="rightColumn">
                                    <input
                                        id="markerNameInput"
                                        value={this.state.markerName}
                                        onChange={this.handleInputChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="leftColumn">
                                    <span>Importance: </span>
                                </td>
                                <td className="rightColumn">
                                    <input
                                        class="markerImportanceInput"
                                        type={"number"}
                                        min={-99}
                                        max={99}
                                        value={this.state.markerImportance}
                                        onChange={this.handleImportanceChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="leftColumn">
                                    <span>Label colour: </span>
                                </td>
                                <td className="rightColumn">
                                    <div className="lablelTagColourDiv">
                                        <input
                                            id="tag_colour"
                                            type="color"
                                            value={this.state.markerTagColour}
                                            onChange={(e) => this.cheangeTagLabelColour(e.target.value)}
                                        />
                                        {this.state.markerTagColour !== "#ffffff" && (
                                            <Button
                                                size="small"
                                                onClick={() => this.onButtonResetDefaultClick()}>
                                                Reset to default
                                            </Button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="colourAndIconPickerContainer">
                        <div className="newMarkerFlexItem">
                            <label className="newIconLabel">Colour</label>
                            <div
                                className="newMarkerIconInput"
                                id="newMarkerColourPicker">
                                <CirclePicker
                                    width="100%"
                                    color={this.state.selectedColour}
                                    colors={[
                                        "#AA201B",
                                        "#71000B",
                                        "#FF7F18",
                                        "#DBA400",
                                        "#714B30",
                                        "#55B431",
                                        "#417505",
                                        "#413F6B",
                                        "#4A90E2",
                                        "#2DB5BA",
                                        "#000000",
                                        "#4A4A4A",
                                        "#9B9B9B",
                                        "#FFFFFF",
                                    ]}
                                    onChange={this.changeSelectedColor}
                                />
                            </div>
                        </div>

                        <div className="newMarkerFlexItem">
                            <label className="newIconLabel">Icon</label>
                            <div className="newMarkerFlexBox newMarkerIconInput">{this.renderIcons()}</div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    markers: state.admin.markers,
    displayIconConfiguration: state.admin.iconConfiguration.display,
});

export default connect(mapStateToProps)(MarkerManagement);
