import React from "react";
import { Layout, Button, Row, Col } from "antd";
import ProgressBar from "./DtProgressBar";
import { setCurrentPage } from "redux/actions/driverTrainingActions";
import { useDispatch } from "react-redux";

const { Content } = Layout;

const WelcomePage = ({ userName, trainingProgress, traingScore, trainingSessionsCount, setCurrentPage2 }) => {
    const dispatch = useDispatch();

    return (
        <Content>
            <div className="WelcomePage">
                <Row>
                    <Col
                        className="GreetingMessage"
                        span={24}>
                        Welcome back {userName}!
                    </Col>
                    <Col span={24}>
                        <ProgressBar
                            className="WelcomeProgressBar"
                            progress={trainingProgress}
                            textVisible={false}
                            sessionscount={trainingSessionsCount}
                        />
                    </Col>
                    <Col
                        className="MainScoreMessage"
                        span={24}>
                        You have completed {traingScore}/{trainingSessionsCount} of your training sessions
                    </Col>
                </Row>
                <Row>
                    <Col
                        style={{ textAlign: "end" }}
                        span={24}>
                        <Button
                            className="ButtonResume"
                            onClick={() => dispatch(setCurrentPage("sessions_main"))}>
                            Resume Trainings
                        </Button>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};

export default WelcomePage;
