import store from "../../redux/store";
import ELRMileAndChain from "../util/ELRMileAndChain";
import { boundedValue } from "./ChartV2/utils";
import _ from "lodash";

const geometryDataSelector = (state) => state.trackGeometry.data;

const nearbyData = {
    type: "aivr:nearbyData",
    transform: function transform(params) {
        const config = params.config || {};
        const state = store.getState();
        const geometryData = geometryDataSelector(state);
        const lowIndex = Math.ceil(boundedValue(0, config.lowIndex, geometryData.length - 1));
        const highIndex = Math.floor(boundedValue(lowIndex, config.highIndex, geometryData.length - 1));

        const relevantData = geometryData.slice(lowIndex, highIndex);

        const data = relevantData.map((item, idx) => {
            const location = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_mile_yards", {
                ELR: item.elr,
                MILE: item.miles,
                YARDS: item.yards,
                TRACK: item.trid,
            });
            const elrCategory = `${location.elr} ${location.track} ${location.mile}.${Math.floor(location.chain / 10)}`;
            const ret_val = {
                index: idx + lowIndex,
                elrText: location.to_string(config.elrUnits),
                elrCategory,
                ...item,
            };

            if (ret_val["DJ_R"]) {
                ret_val["DJ_R"] = -ret_val["DJ_R"];
            }

            return ret_val;
        });

        const dimensions = config.dimensions;

        return [
            {
                dimensions,
                data,
            },
        ];
    },
};

const comparisonData = {
    type: "aivr:comparisonData",
    transform: function transform(params) {
        const config = params.config || {};

        const dimensions = config.dimensions;
        const all_data = config.data;

        if (all_data === null) {
            return [
                {
                    dimensions,
                    data: [],
                },
            ];
        }

        let data = [];
        if (config.lowIndex !== null) {
            const lowerFill = Math.max(0, -config.lowIndex);
            const upperFill = Math.max(0, config.highIndex - all_data.length);
            const relevantData = [
                ...(lowerFill ? Array(lowerFill).fill({}) : []),
                ...all_data.slice(Math.max(0, config.lowIndex), Math.min(all_data.length, config.highIndex)),
                ...(upperFill ? Array(upperFill).fill({}) : []),
            ];
            if (config.reverse) {
                _.reverse(relevantData);
            }

            data = relevantData.map((item, idx) => {
                return {
                    index: idx + config.viewIndex,
                    ...item,
                };
            });
        }

        return [
            {
                dimensions,
                data,
            },
        ];
    },
};

function eighthToText(elr, eighth) {
    let sign = "";
    if (eighth < 0) {
        sign = "-";
        eighth = Math.abs(eighth);
    }
    return `${elr} ${sign}${Math.floor(eighth / 8)}.${eighth % 8}`;
}

const elrBoundaries = {
    type: "aivr:elrBoundaries",
    transform: function transform(params) {
        const upstream = params.upstream;
        const upstreamData = upstream.cloneRawData();

        const data = upstreamData.flatMap((item) => {
            let startingEighth = Math.floor(item.startPos / 10);
            let endingEighth = Math.floor(item.endPos / 10);

            const retVal = [
                {
                    index: item.start,
                    text: eighthToText(item.name, startingEighth),
                },
            ];

            if (item.intermediates) {
                item.intermediates.forEach(([index, position, _category, _speed]) => {
                    retVal.push({
                        index,
                        text: eighthToText(item.name, Math.round(position / 10)),
                    });
                });
            } else if (startingEighth !== endingEighth) {
                const direction = startingEighth < endingEighth ? 1 : -1;
                const tickCount = Math.abs(startingEighth - endingEighth);
                const eighths = [...Array(tickCount).keys()].map((i) => (i + 1) * direction + startingEighth);

                eighths.forEach((eighth) => {
                    const tick = ((item.end - item.start) * (eighth * 10 - item.startPos)) / (item.endPos - item.startPos) + item.start;
                    retVal.push({
                        index: Math.round(tick),
                        text: eighthToText(item.name, eighth),
                    });
                });
            }

            return retVal;
        });

        const dimensions = ["index", "text"];

        return [
            {
                dimensions,
                data,
            },
        ];
    },
};

const lineSpeeds = {
    type: "aivr:lineSpeeds",
    transform: function transform(params) {
        const upstream = params.upstream;
        const upstreamData = upstream.cloneRawData();

        const data = upstreamData.reduce((array, item) => {
            let start = item.start;
            let line_speed = item.line_speed;

            if (array.length && array[array.length - 1].speed === line_speed) {
                start = array.pop().start;
            }

            if (item.intermediates) {
                item.intermediates.forEach(([index, _position, _category, speed]) => {
                    if (speed !== line_speed) {
                        array.push({
                            start,
                            end: index,
                            speed: line_speed,
                        });
                        line_speed = speed;
                        start = index;
                    }
                });
            }

            array.push({
                start,
                end: item.end,
                speed: line_speed,
            });

            return array;
        }, []);

        const dimensions = ["start", "end", "speed"];

        return [
            {
                dimensions,
                data,
            },
        ];
    },
};

const EXCEEDENCE_VALUES = {
    TWIST_A: {
        125: [
            [-12, 12],
            [-15, 15],
        ],
    },
    GAUGE: {
        25: [
            [-11, 20],
            [null, 25],
        ],
        100: [
            [-11, 15],
            [null, 25],
        ],
        125: [
            [-7, 15],
            [null, 23],
        ],
    },
    TOP_35M_L: {
        25: [
            [-23, 23],
            [-26, 26],
        ],
        50: [
            [-20, 20],
            [-23, 23],
        ],
        75: [
            [-18, 18],
            [-21, 21],
        ],
        100: [
            [-16, 16],
            [-19, 19],
        ],
        125: [
            [-14, 14],
            [-18, 18],
        ],
    },
    TOP_35M_R: {
        25: [
            [-23, 23],
            [-26, 26],
        ],
        50: [
            [-20, 20],
            [-23, 23],
        ],
        75: [
            [-18, 18],
            [-21, 21],
        ],
        100: [
            [-16, 16],
            [-19, 19],
        ],
        125: [
            [-14, 14],
            [-18, 18],
        ],
    },
    ALIGN_35M_M: {
        25: [
            [-21, 21],
            [-27, 27],
        ],
        50: [
            [-17, 17],
            [-21, 21],
        ],
        75: [
            [-13, 13],
            [-16, 16],
        ],
        100: [
            [-10, 10],
            [-12, 12],
        ],
        125: [
            [-9, 9],
            [-11, 11],
        ],
    },
    ALIGN_70M_M: {},
    TOP_70M: {},
    SUPER_ELE: {},
    CURVE: {},
    CANT_DEF: {},
    DJ_L: {},
    DJ_R: {},
};

const exceedences = {
    type: "aivr:exceedences",
    transform: function transform(params) {
        const upstream = params.upstream;
        const upstreamData = upstream.cloneRawData();
        const config = params.config || {};

        const dimensions = ["index", ...Object.keys(EXCEEDENCE_VALUES).flatMap((k) => [`${k}_NEG`, `${k}_POS`])];

        const speed = config.speed;

        if (speed === "none") {
            return [
                {
                    dimensions,
                    data: [],
                },
            ];
        }

        const data = upstreamData
            .map((item) => {
                const retVal = {
                    index: item.index,
                };

                _.forEach(EXCEEDENCE_VALUES, (v, k) => {
                    let lookupSpeed = speed;
                    if (lookupSpeed === "train") {
                        lookupSpeed = item.speed_ms ? item.speed_ms * 2.23694 : 9999;
                    } else if (lookupSpeed === "line") {
                        lookupSpeed = _.isNil(item.line_speed) ? 9999 : item.line_speed;
                    }
                    const exceedence = _.find(EXCEEDENCE_VALUES[k], (v, k) => k >= lookupSpeed);
                    if (exceedence) {
                        retVal[`${k}_NEG`] = exceedence[0][0];
                        retVal[`${k}_POS`] = exceedence[0][1];
                    } else {
                        retVal[`${k}_NEG`] = null;
                        retVal[`${k}_POS`] = null;
                    }
                });

                return retVal;
            })
            .reduce((a, d, i, o) => {
                if (i === 0 || i === o.length - 1) {
                    a.push(d);
                } else {
                    const changedKeys = _.filter(d, (v, k) => k !== "index" && a[a.length - 1][k] !== v);
                    if (changedKeys.length) {
                        a.push(o[i - 1]);
                        a.push(d);
                    }
                }
                return a;
            }, []);

        return [
            {
                dimensions,
                data,
            },
        ];
    },
};

export { nearbyData, comparisonData, elrBoundaries, lineSpeeds, exceedences };
