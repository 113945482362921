import React, { useMemo, useState, useEffect } from "react";
import { Select, Input, Modal, Radio, notification, Tooltip } from "antd";
import { faLockOpen, faLock, faPen, faFilm, faClock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {} from "redux/actions";
import ContentLoader from "react-content-loader";
import moment from "moment";
import {
    customAudit,
    goToBounds,
    routeSelected,
    routeHighlighted,
    changeToolMode,
    railInspectionExited,
    resetStillImageAdjustments,
    setFavouriteSessionDescription,
    getFavouriteCategories,
    getSessionData,
    setFavouriteCategoryPrivacy,
} from "redux/actions";
import OBCSpinner from "../util/OBC";
import OBCSearchInput from "../OBC/OBCSearchInput";
import OBCButton from "../OBC/OBCButton";
import Highlighter from "react-highlight-words";
import "../../style/favouritesTab.scss";
import { SyncOutlined } from "@ant-design/icons";

const { Option } = Select;

const searchForMatch = (text, query) => {
    if (text && text.length > 0) {
        return text.toLowerCase().indexOf(query.toLowerCase()) > -1;
    } else {
        return false;
    }
};

const FavouriteCategoryCard = ({ category, searchString }) => {
    const dispatch = useDispatch();
    const sessionsSelector = (state) => state.sessions || null;
    const allSessions = useSelector(sessionsSelector);

    const [cardIsOpen, setCardIsOpen] = useState(false);
    const [sessionIDs, setSessionIDs] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [privacy, setPrivacy] = useState(0);

    const [modalEditDescOpen, setModalEditDescOpen] = useState(false);
    const [editSessionData, setEditSessionData] = useState(null);
    const [descriptionEdit, setDescriptionEdit] = useState(null);

    useEffect(() => {
        setPrivacy(category.privacy);
        let ids = [];
        Object.keys(category.session_data).forEach((key, index) => ids.push(key));
        setSessionIDs(ids);
    }, [category]);

    useEffect(() => {
        if (editSessionData) {
            setDescriptionEdit(category.session_data[editSessionData.id] ? category.session_data[editSessionData.id].description : "");
        }
    }, [editSessionData]);

    const summary = useMemo(() => {
        let totalLength = 0;
        let count = 0;
        if (sessionIDs && allSessions) {
            sessionIDs.forEach((id) => {
                if (
                    allSessions[id] &&
                    (searchForMatch(allSessions[id]["route_name"], searchString) || searchForMatch(category.session_data[id].description, searchString))
                ) {
                    totalLength += allSessions[id]["length_seconds"];
                    count += 1;
                }
            });
        }
        return {
            count: count,
            totalLength: totalLength,
        };
    }, [sessionIDs, allSessions, searchString]);

    const onPrivacyButtonClicked = (event) => {
        event.stopPropagation();
        setModalOpen(true);
    };

    const onCardCliked = () => {
        if (!modalOpen && !modalEditDescOpen) {
            setCardIsOpen(!cardIsOpen);
        }
    };

    const onPrivacyRadioChanged = (value) => {
        setPrivacy(value);
    };

    const onPrivacyModalSaveClicked = () => {
        setConfirmLoading(true);
        dispatch(
            setFavouriteCategoryPrivacy(category.id, privacy, (response) => {
                if (response.success) {
                    setModalOpen(false);
                    notification.success({
                        message: "Category privacy updated successfully",
                        duration: 1.5,
                    });
                } else {
                    notification.error({
                        message: "Error ocurred while saving privacy settings",
                        duration: 1.5,
                    });
                }
                setConfirmLoading(false);
            }),
        );
    };

    const onPrivacyModalCancelClicked = () => {
        setModalOpen(false);
        setPrivacy(category.privacy);
    };

    const onEditDescriptionSaveCliked = () => {
        dispatch(
            setFavouriteSessionDescription(category.id, editSessionData.id, descriptionEdit, (response) => {
                // leave modal open if error...
                if (response.success) {
                    setModalEditDescOpen(false);
                }
            }),
        );
    };

    const renderPrivacyIcon = () => {
        if (privacy === 0) {
            return faLock;
        } else if (privacy === 1) {
            return faUnlock;
        } else {
            return faLockOpen;
        }
    };

    const renderPrivacyText = () => {
        if (privacy === 1) {
            return "Open (read only) - You can view sessions shared with you by other members of your organisation.";
        } else {
            return "Open (read & write) - You can add and edit descriptions for the sessions shared with you in this category.";
        }
    };

    // perform search and if true return category Card
    if (
        summary.count === 0 &&
        searchString.length > 0 &&
        !searchForMatch(category.name, searchString) &&
        !searchForMatch(category.is_user_category ? "You" : category.owner_name, searchString)
    ) {
        return false;
    } else {
        return (
            <div
                className={`favouriteCategoryCard ${cardIsOpen ? "open" : ""}`}
                onClick={() => onCardCliked()}>
                {/* Modal to edit privacy */}
                <Modal
                    title="Update Category Privacy"
                    visible={modalOpen}
                    okText="Save"
                    onOk={() => onPrivacyModalSaveClicked()}
                    confirmLoading={confirmLoading}
                    onCancel={() => onPrivacyModalCancelClicked()}>
                    <Radio.Group
                        buttonStyle="solid"
                        style={{ width: "100%" }}
                        onChange={(e) => onPrivacyRadioChanged(e.target.value)}
                        value={privacy}>
                        <Radio
                            style={{ display: "block", lineHeight: "20px", width: "100%", whiteSpace: "normal", marginTop: "14px" }}
                            value={0}>
                            <FontAwesomeIcon icon={faLock} />
                            <strong> Private</strong> Sessions in this category will only be visible to you.
                        </Radio>
                        <Radio
                            style={{ display: "block", lineHeight: "20px", width: "100%", whiteSpace: "normal", marginTop: "14px" }}
                            value={1}>
                            <FontAwesomeIcon icon={faUnlock} />
                            <strong> Open (read only)</strong> Sessions will be visible to you and other members of your organisation.
                        </Radio>
                        <Radio
                            style={{ display: "block", lineHeight: "20px", width: "100%", whiteSpace: "normal", marginTop: "14px" }}
                            value={2}>
                            <FontAwesomeIcon icon={faLockOpen} />
                            <strong> Open (read & write)</strong> Sessions will be visible to all members of your or organisation and they will be able to
                            add/edit session description.
                        </Radio>
                    </Radio.Group>
                </Modal>

                <Modal
                    title="Edit Description Name"
                    visible={modalEditDescOpen}
                    okText="Save"
                    onOk={() => onEditDescriptionSaveCliked()}
                    okButtonProps={{ disabled: editSessionData && editSessionData.description === descriptionEdit }}
                    // confirmLoading={confirmLoading}
                    onCancel={() => setModalEditDescOpen(false)}>
                    <Input
                        value={descriptionEdit}
                        onChange={(e) => setDescriptionEdit(e.target.value)}
                    />
                </Modal>

                <div className="cardTop">
                    <div className="categoryName">
                        <Highlighter
                            highlightClassName="ObcTextHighlight"
                            searchWords={searchString.split(" ")}
                            autoEscape={true}
                            textToHighlight={category.name}
                        />
                    </div>
                    {category.name !== "General" ? (
                        <div className="categoryControls">
                            {category.is_user_category ? (
                                <FontAwesomeIcon
                                    className="privacyButtom"
                                    icon={renderPrivacyIcon()}
                                    onClick={(e) => onPrivacyButtonClicked(e)}
                                />
                            ) : (
                                <Tooltip
                                    placement="topLeft"
                                    title={renderPrivacyText()}
                                    arrowPointAtCenter={true}
                                    mouseLeaveDelay={0}>
                                    <FontAwesomeIcon
                                        className="privacyButtom disabled"
                                        icon={renderPrivacyIcon()}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    ) : null}
                </div>

                <div className="quickOverview">
                    <div className="rowStart">
                        <div className="quickItem">
                            <FontAwesomeIcon
                                className="icon"
                                size="lg"
                                icon={faFilm}
                            />
                            <div className={`badge visible ${searchString.length > 0 ? "searchFound" : ""} ${summary.count === 0 ? "foundNone" : ""}`}>
                                {summary && summary.count}
                            </div>
                        </div>
                        <div
                            className="quickItem"
                            style={{ marginLeft: "7px", marginTop: "3px" }}>
                            <FontAwesomeIcon
                                className="icon"
                                size="1x"
                                icon={faClock}
                            />
                            <span className={`label ${summary.totalLength > 0 ? "visible" : ""}`}>
                                {moment.duration(summary.totalLength / 60, "minutes").humanize()}
                            </span>
                        </div>
                    </div>
                    <div className="rowEnd">
                        <span>
                            <strong style={{ color: "white" }}>Owner:</strong>{" "}
                        </span>{" "}
                        <span>
                            <Highlighter
                                highlightClassName="ObcTextHighlight"
                                searchWords={searchString.split(" ")}
                                autoEscape={true}
                                textToHighlight={category.is_user_category ? "You" : category.owner_name}
                            />
                        </span>
                    </div>
                </div>

                <div className={`sessionsList${cardIsOpen || (summary.count > 0 && searchString.length > 0) ? " open" : ""}`}>
                    {_.map(sessionIDs, (sessionID) => (
                        <CardSessionListItem
                            key={sessionID}
                            sessionID={sessionID}
                            category={category}
                            setModalEditDescOpen={setModalEditDescOpen}
                            setEditSessionData={setEditSessionData}
                            searchString={searchString}
                        />
                    ))}
                </div>
            </div>
        );
    }
};

// CARD LIST ITEM
const CardSessionListItem = ({ sessionID, category, setModalEditDescOpen, setEditSessionData, searchString }) => {
    const dispatch = useDispatch();
    const selectedSessionIdSelectros = (state) => state.playlist.data.routeID || null;
    const selectedSessionId = useSelector(selectedSessionIdSelectros);

    const [sessionData, setSessionData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const ContentSkeleton = () => (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={60}
            viewBox="0 0 780 60"
            backgroundColor="#5a608a"
            foregroundColor="#4b5175"
            style={{ backgroundColor: "#30334a", borderRadius: "3px", marginTop: "5px" }}>
            <rect
                x="10"
                y="11"
                rx="4"
                ry="4"
                width="60"
                height="15"
            />

            <rect
                x="80"
                y="7"
                rx="4"
                ry="4"
                width="230"
                height="18"
            />
            <rect
                x="80"
                y="38"
                rx="4"
                ry="4"
                width="350"
                height="13"
            />

            <rect
                x="750"
                y="7"
                rx="4"
                ry="4"
                width="20"
                height="20"
            />
            <rect
                x="720"
                y="7"
                rx="4"
                ry="4"
                width="20"
                height="20"
            />
        </ContentLoader>
    );

    const onSessionSelected = (e) => {
        e.stopPropagation();
        dispatch(customAudit("session_list_click", { selected_session: sessionData.id }));
        dispatch(goToBounds(sessionData.bounds));
        // dispatch(routeSelected(sessionData.id, 0, undefined, this.props.has8K ? true : undefined));
        dispatch(routeSelected(sessionData.id, 0, undefined, undefined));
        dispatch(routeHighlighted(null));
        dispatch(changeToolMode(null));
        dispatch(railInspectionExited());
        dispatch(resetStillImageAdjustments());
    };

    useEffect(() => {
        dispatch(getSessionData(sessionID, false, category.id)).then((sessionData) => {
            setSessionData(sessionData);
            setIsLoading(false);
        });
    }, [sessionID]);

    const openEditDescriptionModal = (event) => {
        event.stopPropagation();
        setEditSessionData(sessionData);
        setModalEditDescOpen(true);
    };

    if (!sessionData || !sessionData.route_name) {
        return <ContentSkeleton />;
    } else {
        if (searchForMatch(sessionData.route_name, searchString) || searchForMatch(category.session_data[sessionData.id].description, searchString)) {
            return (
                <>
                    <div
                        className={`sessionItem ${selectedSessionId === sessionData.id ? "selected" : ""}`}
                        onClick={(e) => onSessionSelected(e)}>
                        <div className="sessionID">{sessionData.id}</div>
                        <div className="sessionMainInfo">
                            <div className="title">
                                <Highlighter
                                    highlightClassName="ObcTextHighlight"
                                    searchWords={searchString.split(" ")}
                                    autoEscape={true}
                                    textToHighlight={sessionData.route_name}
                                />
                            </div>
                            <div className="description">
                                <Highlighter
                                    highlightClassName="ObcTextHighlight"
                                    searchWords={searchString.split(" ")}
                                    autoEscape={true}
                                    textToHighlight={
                                        category.session_data[sessionData.id] && category.session_data[sessionData.id].description
                                            ? category.session_data[sessionData.id].description
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                        <div className="controls">
                            <div className="buttons">
                                {(category.is_user_category || category.privacy === 2) && (
                                    <>
                                        <FontAwesomeIcon
                                            className="button"
                                            icon={faPen}
                                            onClick={(e) => openEditDescriptionModal(e)}
                                        />
                                        {/* {category.is_user_category &&
                                            <FontAwesomeIcon className="button delete" icon={faTrash} />
                                        } */}
                                    </>
                                )}
                            </div>
                            {/* TODO */}
                            {/* <div className="trimVisulation">
                                Trim visulation
                            </div> */}
                        </div>
                    </div>
                </>
            );
        } else {
            return <></>;
        }
    }
};

// MAIN FAVOURITES TAB
const FavouritesTab = () => {
    const userFavouriteCategoriesSelector = (state) => state.userDetails.favouriteCategories;
    const userFavouriteCategories = useSelector(userFavouriteCategoriesSelector);
    const dispatch = useDispatch();
    const [listLoading, setListLoading] = useState(false);
    const [selectedFavourites, setSelectedFavourites] = useState("");
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        // if user have favourite categories when component is mounted select mine
        if (userFavouriteCategories && userFavouriteCategories.length) {
            onFavourteSelectChanged("mine");
        } else {
            onFavourteSelectChanged("all");
        }
    }, []);

    const onFavourteSelectChanged = (event) => {
        setListLoading(true);
        setSelectedFavourites(event);
        if (event === "all") {
            dispatch(
                getFavouriteCategories(true, (success) => {
                    if (success) {
                        setListLoading(false);
                    }
                }),
            );
        } else {
            dispatch(
                getFavouriteCategories(false, (success) => {
                    if (success) {
                        setListLoading(false);
                    }
                }),
            );
        }
    };

    const onRefreshClicked = () => {
        if (!listLoading) {
            setListLoading(true);
            dispatch(
                getFavouriteCategories(selectedFavourites !== "mine", (success) => {
                    if (success) {
                        setListLoading(false);
                    }
                }),
            );
        }
    };

    return (
        <div className="favouritesTab">
            <div className="contentHeader">
                <div className="leftColumn">
                    <Select
                        className="favouriteSelect"
                        value={selectedFavourites}
                        onChange={(e) => onFavourteSelectChanged(e)}
                        dropdownMatchSelectWidth={false}>
                        <Option value={"mine"}>Mine</Option>
                        <Option value={"all"}>All</Option>
                    </Select>
                    <OBCButton
                        className="refreshButton"
                        size="lg"
                        onClick={() => onRefreshClicked()}>
                        <SyncOutlined
                            spin={listLoading}
                            className="icon"
                        />
                    </OBCButton>
                    {/* <div className="refreshButton">
                    </div> */}
                </div>
                <div className="rightColumn">
                    <OBCSearchInput
                        className="IssuesFilterSearchInput"
                        placeholder="Search for session"
                        onChange={(value) => setSearchString(value)}
                        value={searchString}
                    />
                </div>
            </div>

            <div className={listLoading ? "favouritesLoading" : "favouriteMainList"}>
                {listLoading ? (
                    <OBCSpinner
                        className="favouriteSpinner"
                        colorScheme={"mono"}
                        size={70}
                        color={"#e8dfff"}
                    />
                ) : userFavouriteCategories && userFavouriteCategories.length > 0 ? (
                    _.map(userFavouriteCategories, (category, index) => {
                        return (
                            <FavouriteCategoryCard
                                key={index}
                                category={category}
                                searchString={searchString}
                            />
                        );
                    })
                ) : (
                    <div className="noSessionLabel">
                        <h1>No sessions found</h1>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FavouritesTab;
