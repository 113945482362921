import React from "react";
import { connect } from "react-redux";
import VideoComponent from "./video/VideoComponent";
import ImageComponent from "./image/ImageComponent";
import { logEvent, requestPlaylistPosition, routeSelected, setCurrentTab, setUserPreference } from "redux/actions/index";
import { absoluteTimeLookup, getAbsoluteTimestamp, imageToVideoIndex, videoToImageIndex } from "../util/PlaylistUtils";
import _ from "lodash";
import OBCSpinner from "../util/OBC";
import RailInspectBigScroller from "./image/RailInspectBigScroller";
import RailInspectControls from "./image/RailInspectControls";
import { Link } from "react-router-dom";

class VideoOrImageComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            railIndex: 0,
        };
        this.loadingSessionLabel = React.createRef();
    }

    componentDidUpdate() {
        if (!this.props.playlist.data) {
            // if loadingSessionLabel is rendered focus on it so screen reader if triggered.
            if (this.loadingSessionLabel && this.loadingSessionLabel.current) {
                this.loadingSessionLabel.current.focus();
            }
        }
    }

    setNewRailIndex = (newIndex) => {
        this.setState({
            railIndex: newIndex,
        });
    };

    getSourceList = () => {
        const sources = [];
        const playlist = this.props.playlist;
        const streamInfo = this.props.streamInfo.filter((stream) => {
            return stream.archived !== 1;
        });

        if (!playlist || !playlist.video) {
            return sources;
        }

        for (let i = 0; i < playlist.video.length; i++) {
            if (!playlist.video[i].length) {
                continue;
            }

            const sourceStreamData = _.get(streamInfo, [i], {});
            const sourceStreamInfo = _.get(sourceStreamData, ["info"], {});
            const archived = _.get(sourceStreamData, "archived", 0);
            if (archived === 1) {
                continue;
            }

            const sourceName = _.get(sourceStreamInfo, ["type"], "Camera " + (i + 1));
            const sourceLabel = _.get(sourceStreamInfo, ["label"], "");

            let has8K = sourceLabel.toLowerCase().includes("8k");

            let name = sourceName;
            if (sourceLabel) {
                name += " (" + sourceLabel + ")";
            }

            const source = {
                name: name,
                streams: [
                    {
                        name: "Video",
                        videoIndex: i,
                        isVideo: true,
                        isEnhanced: false,
                        isStills: false,
                        disabled: has8K,
                    },
                ],
            };

            if (this.props.session.first_seen > 1631710005) {
                source.streams.push({
                    name: "Low Bandwidth",
                    videoIndex: i,
                    isVideo: true,
                    isEnhanced: "low_res",
                    isStills: false,
                });
            }

            source.streams = source.streams.concat([
                {
                    name: "Still Images",
                    videoIndex: i,
                    isVideo: true,
                    isEnhanced: false,
                    isStills: true,
                },
            ]);

            sources.push(source);
        }

        if (playlist.image && playlist.image.length) {
            const source = {
                name: `Camera ${sources.length + 1}`,
                streams: [
                    {
                        name: "Stills",
                        videoIndex: 0,
                        isVideo: false,
                        isEnhanced: false,
                        isStills: false,
                    },
                ],
            };

            sources.push(source);
        }

        return sources;
    };

    selectStream = (stream) => {
        if (stream && stream.target) {
            const sources = this.getSourceList();
            let streamReference = stream.target.value.split(".");
            let sourceIndex = 1 * streamReference[0];
            let streamIndex = streamReference[1];
            for (let i in sources) {
                let source = sources[i];
                for (let j in source.streams) {
                    let _stream = source.streams[j];
                    if (_stream.videoIndex === sourceIndex && j === streamIndex) {
                        stream = _stream;
                        break;
                    }
                }
            }
        }

        let newIndex = 0;
        let newOffset = 0;

        console.log("New stream selected: ", stream);

        if (this.props.isVideo) {
            //Switching from video
            if (stream.isVideo) {
                //Switching to video
                const currentPlaylist = this.props.playlist.video[this.props.sourceIndex];
                let currentPlaylistItem = currentPlaylist[this.props.index];
                if (!currentPlaylistItem) {
                    currentPlaylistItem = currentPlaylist[0];
                }
                const absoluteTimestamp = _.get(currentPlaylistItem, [3, 2], 0) + this.props.timeOffset;

                let newPlaylist = this.props.playlist.video[stream.videoIndex];
                newIndex = absoluteTimeLookup(absoluteTimestamp, newPlaylist);
                if (newIndex !== -1) {
                    const newAbsoluteTimestamp = newPlaylist[newIndex][3][2];
                    newOffset = absoluteTimestamp - newAbsoluteTimestamp;
                }
                if (newOffset < 0) {
                    newOffset = 0;
                }
            } else {
                //Switching to images
                newIndex = videoToImageIndex(this.props.index, this.props.playlist.video[this.props.sourceIndex], this.props.playlist.image);
            }
        } else {
            //Switching from images
            if (stream.isVideo) {
                //Switching to video
                newIndex = imageToVideoIndex(this.props.index, this.props.playlist.video[stream.videoIndex], this.props.playlist.image);
            } else {
                //Switching to images (shouldn't really happen, because there's only one image source available)
                newIndex = this.props.index || 0;
            }
        }

        if (newIndex === -1) {
            newIndex = 0;
            newOffset = 0;
        }

        if (this.props.isVideo && !stream.isVideo) {
            this.props.dispatch(logEvent("Video", "Switch to Images"));
        } else if (!this.props.isVideo && stream.isVideo) {
            this.props.dispatch(logEvent("Images", "Switch to Video"));
        } else {
            if (this.props.sourceIndex !== stream.videoIndex) {
                this.props.dispatch(logEvent("Video", "Select Source", stream.videoIndex));
            }
            if (this.props.isStills !== stream.isStills) {
                this.props.dispatch(logEvent("Video", "Toggle Stills Only", stream.isStills));
            }
        }

        if (this.props.isEnhanced !== stream.isEnhanced) {
            this.props.dispatch(logEvent("Video", "Toggle Enhanced", stream.isEnhanced));
        }

        if (stream.isVideo) {
            this.props.dispatch(setUserPreference("defaultStills", stream.isStills));
            this.props.dispatch(setUserPreference("defaultEnhanced", stream.isEnhanced));
        }

        console.log("Switching to source: ", stream, "at position and offset:", newIndex, newOffset);
        this.props.dispatch(requestPlaylistPosition(stream.isVideo, stream.isEnhanced, stream.isStills, stream.videoIndex, newIndex, newOffset));
    };

    imageIndexChanged = (newIndex) => {
        // this.props.dispatch(selectBookmark(null));
        this.props.dispatch(requestPlaylistPosition(true, false, true, 0, newIndex, 0));
    };

    render() {
        const sources = this.getSourceList();

        let content = (
            <div className={`Center-Content Info-Message ${this.props.isWidget ? "Info-Message-Widget" : ""}`}>
                <div>{this.props.infoMessage ?? "Please select a session from the map or list below."}</div>
            </div>
        );

        if (this.props.isPointOnTheMapClicked) {
            content = (
                <div className={`Center-Content Info-Message ${this.props.isWidget ? "Info-Message-Widget" : ""}`}>
                    <OBCSpinner colorScheme="mono" />
                    <div
                        className="HiddenFocus"
                        ref={this.loadingSessionLabel}
                        tabIndex={0}>
                        Loading session data, please wait...
                    </div>
                </div>
            );
        } else if (this.props.playlist && this.props.playlist.mpdURLs && this.props.playlist.routeID) {
            let hasVideo = false;
            this.props.streamInfo.forEach((stream) => {
                if (!stream.archived && _.get(this.props.playlist, ["video", "length"])) {
                    hasVideo = true;
                }
            });
            let hasImages = _.get(this.props.playlist, ["image", "length"], false);

            if (this.props.playlist && (hasVideo || hasImages)) {
                if (this.props.isVideo) {
                    content = (
                        <VideoComponent
                            sources={sources}
                            selectStream={this.selectStream}
                        />
                    );
                } else {
                    content = (
                        <ImageComponent
                            playerControls={true}
                            sources={sources}
                            selectStream={this.selectStream}
                        />
                    );
                }
            } else if (!this.props.playlist.data && !this.props.playlist.loaded) {
                content = (
                    <div className={`Center-Content Info-Message ${this.props.isWidget ? "Info-Message-Widget" : ""}`}>
                        <OBCSpinner colorScheme="mono" />
                        <div
                            className="HiddenFocus"
                            ref={this.loadingSessionLabel}
                            tabIndex={0}>
                            Loading session data, please wait...
                        </div>
                    </div>
                );
            } else if (this.props.playlist.loaded && this.props.playlist.data) {
                content = (
                    <div
                        id="intro-tour-rail-inspection"
                        className={"VideoContainer"}
                        style={{ minHeight: "40vh" }}>
                        <div className="VideoContainer__Button">
                            <button
                                onClick={() => {
                                    this.props.dispatch(setCurrentTab("sessions"));
                                    this.props.dispatch(routeSelected(null));
                                }}>
                                Close Session
                            </button>
                            {this.props.hasRailInspectionImagesData ? (
                                <Link
                                    to={`/rail-inspection/${this.props.playlist.routeID}${this.props.absolutePlaylistTimestamp ? "/" + this.props.absolutePlaylistTimestamp : ""}`}>
                                    <button id="intro-tour-image-fullscreen">Open Inspection Interface</button>
                                </Link>
                            ) : null}
                        </div>
                        <div className="VideoContainer__Button Left"></div>
                        <RailInspectBigScroller
                            inPlayer
                            videoless
                            newRailIndex={this.state.railIndex}
                            imageIndexChanged={this.setNewRailIndex}
                        />
                        <RailInspectControls
                            extraStyles={{
                                position: "absolute",
                                zIndex: 400,
                                height: 55,
                            }}
                            playing={false}
                            // imageIndexChanged={this.imageIndexChanged}
                            videoless={true}
                            imageIndexChanged={this.setNewRailIndex}
                            newRailIndex={this.state.railIndex}
                            inPlayer
                        />
                    </div>
                );
            }
        }

        return content;
    }
}

const mapStateToProps = ({ playlist, dashboards, access_token, sessions, railInspection }) => {
    let dashboard = _.find(dashboards, { access_token: access_token });

    const isWidget = ["widget_layout", "minimal_layout"].includes(dashboard?.workspace_layout);

    const currentSession = _.get(sessions, [playlist.data.routeID], {});
    let streamInfo = _.get(currentSession, ["stream_info"], []);
    const hasRailInspectionImagesData = !_.isEmpty(_.get(railInspection.railInspectionImages, "data", []));

    let absolutePlaylistTimestamp = getAbsoluteTimestamp(playlist) * 1000;
    if (!absolutePlaylistTimestamp && railInspection.railInspectionImages.data?.length && !playlist.video?.length) {
        const currentIndex = _.get(playlist, ["position", "currentIndex"]);
        if (currentIndex) {
            absolutePlaylistTimestamp = _.get(railInspection.railInspectionImages.data, [currentIndex, "timestamp"], 0);
        }
    }

    return {
        playlist: playlist.data,
        isVideo: playlist.position.isVideo,
        isEnhanced: playlist.position.isEnhanced,
        isStills: playlist.position.isStills,
        isPointOnTheMapClicked: playlist.position.pointOnTheMapClicked,
        sourceIndex: playlist.position.sourceIndex,
        index: playlist.position.currentIndex,
        timeOffset: playlist.position.currentTimeOffset,
        isBetaDashboard: dashboard && dashboard.isBeta,
        streamInfo,
        session: currentSession,
        absolutePlaylistTimestamp,
        isWidget,
        hasRailInspectionImagesData,
    };
};

export default connect(mapStateToProps)(VideoOrImageComponent);
