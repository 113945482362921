import React from "react";
import "../../style/aivrComponents.scss";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const OBCSearchInput = ({ placeholder, value, onChange, onPressEnter, allowClear, disabled }) => {
    return (
        <div className="OBCSearchInput">
            <Input
                style={{ height: 35 }}
                prefix={<SearchOutlined />}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                spellCheck={false}
                allowClear={allowClear}
                disabled={disabled}
                onPressEnter={(e) => onPressEnter && onPressEnter(e.target.value)}
            />
        </div>
    );
};

OBCSearchInput.defaultProps = {
    allowClear: true,
    disabled: false,
};

export default OBCSearchInput;
