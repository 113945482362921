import { useEffect, useState } from "react";
import React from "react";
import { MEMOIZED_API_BASE_URL } from "./util/HostUtils";
import { useSelector } from "react-redux";
import _ from "lodash";
import OBCSpinner from "./util/OBC";

const currentDashboardSelector = (state) => _.find(state.dashboards, (dashboard) => dashboard.access_token === state.access_token);

export const getRawApiImage = (url, workspaceAccessToken, callback) => {
    if (url && url.includes("raw.aivr.video")) {
        let updatedSrc = url.replace("https://raw.aivr.video", `${MEMOIZED_API_BASE_URL}/frame_image`);
        updatedSrc += `&access_token=${workspaceAccessToken}`;

        return fetch(updatedSrc, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response);
                } else {
                    return response.json();
                }
            })
            .then((json) => {
                if (callback) {
                    callback("data:image/png;base64," + json.base64Jpeg);
                }
            })
            .catch((err) => {
                console.log("Error getting raw image", err);
            });
    } else {
        if (callback) {
            callback(url);
        }
    }
};

export const RawImageComponent = (props) => {
    const currentDashboard = useSelector(currentDashboardSelector);

    const [imageData, setImageData] = useState("");
    const [imageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        const imageSrc = props.src;
        const callback = (imageSrc) => {
            setImageData(imageSrc);
            setImageLoading(false);
        };
        getRawApiImage(imageSrc, currentDashboard.access_token, callback);
    }, [currentDashboard, props.src]);

    if (imageLoading) {
        return (
            <OBCSpinner
                size={50}
                speed={3}
                colorScheme={"mono"}
            />
        );
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return (
        <img
            {...props}
            src={imageData}
        />
    );
};
