import { calculatePulseFromTimestamp } from "components/util/Geometry";
import OBCSpinner from "components/util/OBC";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_SPERRY_MARKERS, fetchInspectionSperryFaults, requestPlaylistTimestamp, setTargetInpectionTimestamp } from "redux/actions";
import { Input, Select } from "antd";

const faultsSelector = (state) => state.railInspection.sperryFaults.faults;
const sessionIDSelector = (state) => state.playlist.data.routeID;
const inspectionPulseCountsSelector = (state) => state.railInspection.railInspectionPulseData;

const INITIAL_FILTER_STATE = { priority: null, suspectType: null, search: "" };

const UTUFaultNavigation = ({ inResizeDraggable }) => {
    const dispatch = useDispatch();
    const { Option } = Select;

    const faults = useSelector(faultsSelector);
    const sessionID = useSelector(sessionIDSelector);
    const inpsectionPulseCounts = useSelector(inspectionPulseCountsSelector);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(INITIAL_FILTER_STATE);

    const filteredFaults = useMemo(() => {
        return faults.filter((fault) => {
            if (filters.priority || filters.priority === 0) {
                if (filters.priority !== fault.priority) {
                    return false;
                }
            }

            if (filters.suspectType && filters.suspectType !== fault.type) {
                return false;
            }

            if (filters.search && !fault.uid.toString().includes(filters.search)) {
                return false;
            }

            return true;
        });
    }, [faults, filters]);

    useEffect(() => {
        const sperryMarkers = faults.map((fault) => {
            const { timestamp, interpolationRatio } = calculatePulseFromTimestamp(fault.pulse_count, inpsectionPulseCounts);
            return {
                timestamp,
                interpolationRatio,
                uid: fault.uid,
            };
        });
        dispatch({
            type: SET_SPERRY_MARKERS,
            sperryMarkers: sperryMarkers,
        });
    }, [faults, dispatch, inpsectionPulseCounts]);

    useEffect(() => {
        if (!faults.length) {
            setLoading(true);
            dispatch(fetchInspectionSperryFaults(sessionID)).then(() => {
                setLoading(false);
            });
        }
    }, [dispatch, faults.length, sessionID]);

    const goToFault = useCallback(
        (fault) => {
            const { timestamp, interpolationRatio } = calculatePulseFromTimestamp(fault.pulse_count, inpsectionPulseCounts);
            if (!_.isNil(interpolationRatio)) {
                dispatch(setTargetInpectionTimestamp(timestamp, interpolationRatio));
            }

            if (timestamp) {
                dispatch(requestPlaylistTimestamp(timestamp / 1000));
            }
        },
        [dispatch, inpsectionPulseCounts],
    );

    const faultRows = useMemo(() => {
        return filteredFaults.map((fault) => {
            return (
                <div
                    className="InspectRailFaultRow"
                    onClick={() => goToFault(fault)}
                    key={fault.id}>
                    <span className="FaultType">{fault.type}</span>
                    <span className="FaultComment">{fault.comment}</span>
                    <div className="Row">
                        <span className="FaultPriority">
                            <b>Priority:</b> {fault.priority}
                        </span>
                        <span className="FaultRail">
                            <b>Rail:</b> {fault.rail}
                        </span>
                        <span className="FaultRail">
                            <b>Pulse count:</b> {fault.pulse_count}
                        </span>
                        <span className="FaultRail">
                            <b>UID:</b> {fault.uid}
                        </span>
                    </div>
                </div>
            );
        });
    }, [filteredFaults, goToFault]);

    const renderFilters = useMemo(() => {
        if (faults && faults.length) {
            return (
                <div className="FaultListFilters">
                    <div className="FaultListContainer">
                        <span className="FaultListLabel">Priority:</span>
                        <Select
                            className="FaultListSelect"
                            onChange={(e) => setFilters({ ...filters, priority: e })}
                            placeholder="Select priority level"
                            style={{ width: "100px" }}>
                            <Option value={null}>All</Option>
                            {_.chain(faults)
                                .uniqBy((fault) => fault.priority)
                                .orderBy("priority")
                                .map((fault) => {
                                    return (
                                        <Option
                                            value={fault.priority}
                                            key={fault.id}>
                                            {fault.priority}
                                        </Option>
                                    );
                                })
                                .value()}
                        </Select>
                    </div>
                    <div className="FaultListContainer">
                        <span className="FaultListLabel">Suspect Type:</span>
                        <Select
                            className="FaultListSelect"
                            onChange={(e) => setFilters({ ...filters, suspectType: e })}
                            placeholder="Select suspect type"
                            style={{ width: "300px" }}
                            dropdownMatchSelectWidth={false}>
                            <Option value={null}>All</Option>
                            {_.map(
                                _.uniqBy(faults, (o) => o.type),
                                (fault) => {
                                    return (
                                        <Option
                                            value={fault.type}
                                            key={fault.id}>
                                            {fault.type}
                                        </Option>
                                    );
                                },
                            )}
                        </Select>
                    </div>
                    <div className="FaultListContainer">
                        <span className="FaultListLabel">Search:</span>
                        <Input
                            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                            placeholder="Search for UID"
                        />
                    </div>
                </div>
            );
        }
        return null;
    }, [faults, filters]);

    return (
        <div
            className="UTUFaultListContainer"
            style={{ minHeight: inResizeDraggable ? "none" : "initial" }}>
            {!!faults.length && !loading && (
                <div className="UTUFaultListNotice">
                    While pulse count is fully integrated, indicated suspect position may currently vary by several metres from true position.
                </div>
            )}
            {renderFilters}
            {loading && (
                <div className="Spinner">
                    <OBCSpinner
                        size={70}
                        colorScheme="mono"
                    />
                </div>
            )}
            {!!filteredFaults.length && !loading && (
                <div
                    className="InspectRailFaultList"
                    style={{ maxHeight: inResizeDraggable ? "1000px" : "initial" }}>
                    {faultRows}
                </div>
            )}
            {!!faults.length && !filteredFaults.length && <div className="FaultListFilteredMessage">All results filtered</div>}
            {!faults.length && !loading && <div>No suspects found for this session</div>}
        </div>
    );
};

export default UTUFaultNavigation;
