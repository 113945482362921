import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchUserExports, reprocessExport } from "redux/actions/index";
import moment from "moment";
import { Tooltip, Modal, Button, Input } from "antd";
import OBCSpinner from "./util/OBC";
import placeholder from "../images/obc-placeholder-thumb.png";
import validator from "validator";
import Tippy from "@tippyjs/react";
import { convertToTimezone } from "./util/TimezoneUtils";
import MarkupExportModal from "./MarkupExportModal";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";
import { SearchOutlined } from "@ant-design/icons";

class ExportDrawer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.search = React.createRef();
        this.state = {
            showConfirmModal: false,
            idToExport: -1,
            emailToExport: "",
            emailInputError: "",
            creatingExport: false,
            exportNameSearch: "",
        };

        this.poll = null;
    }

    openConfirmModal = (id, email) => {
        this.setState({
            showConfirmModal: true,
            idToExport: id,
            emailToExport: email,
        });
    };

    hideConfirmModal = () => {
        this.setState({
            showConfirmModal: false,
            emailToExport: "",
            idToExport: -1,
            emailInputError: "",
        });
    };

    componentWillMount = () => {
        if (!this.props.exports || this.props.exports.length === 0) {
            this.props.dispatch(fetchUserExports());
        }
    };

    componentDidUpdate = (prevProps) => {
        const _this = this;

        if (prevProps.exports !== this.props.exports) {
            let processingIndex = _.findIndex(this.props.exports, function (exp) {
                return exp.processed_ts === 0;
            });
            if (processingIndex > -1 && !this.poll) {
                this.poll = setInterval(function () {
                    _this.props.dispatch(fetchUserExports());
                }, 5000);
            } else if (processingIndex === -1 && this.poll) {
                clearInterval(_this.poll);
                this.poll = null;
            }
        }

        if (!prevProps.visible && this.props.visible) {
            if (!this.props.exports || this.props.exports.length === 0) {
                this.props.dispatch(fetchUserExports());
            }
        }
    };

    reprocessExport = () => {
        let id = this.state.idToExport;

        if (this.state.emailToExport === "") {
            this.setState({
                emailInputError: "Email address cannot be empty",
            });
            return;
        }
        if (this.validateEmail()) {
            this.props.dispatch(reprocessExport(id, this.state.emailToExport));
            this.hideConfirmModal();
        } else {
            this.setState({
                emailInputError: "Recipient must be an email address",
            });
        }
    };

    validateEmail = () => {
        return validator.isEmail(this.state.emailToExport + "");
    };

    renderExportItems = () => {
        let allExports = this.props.exports;

        if (!allExports || allExports.length < 1) {
            return <h1 className="noExportText">No Exports</h1>;
        }

        const searchText = this.state.exportNameSearch;

        allExports = _.sortBy(allExports, function (exp) {
            return exp.requested_ts * -1;
        }).filter((exp) => {
            const exportName = exp.export_name ? exp.export_name.toUpperCase() : "";
            const exportUser = exp.exported_by;
            const exportTo = exp.email_to || "";

            return (
                exportName.indexOf(searchText.toUpperCase()) > -1 ||
                exportUser.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
                exportTo.toUpperCase().indexOf(searchText.toUpperCase()) > -1
            );
        });

        return allExports.map((exp) => {
            let requestedString = "Processing";

            if (exp.requested_ts > 0) {
                const date = new Date(exp.requested_ts * 1000);
                requestedString = convertToTimezone(date, this.props.userConfig.convert_to_utc);
            }

            let segmentStart = moment(exp.start_ts);
            let segmentEnd = moment(exp.end_ts);
            let startString = segmentStart.format("HH:mm:ss a");
            let endString = segmentEnd.format("HH:mm:ss a");

            let downloadButtons = null;

            if (exp.processed_ts > 0) {
                let openButton = (
                    <button
                        className={"exportButton"}
                        onClick={() => this.openExportPage(exp.uploaded_file, exp.type)}>
                        Open Export
                    </button>
                );
                if (this.isExportExpired(exp.processed_ts)) {
                    openButton = (
                        <Tippy
                            arrow={true}
                            theme="aivrlight"
                            interactive={true}
                            content={"Export expired. Re-export to enable download"}>
                            <button className={"exportButton exportDisabledButton"}>Open Export</button>
                        </Tippy>
                    );
                }

                downloadButtons = (
                    <React.Fragment>
                        <button
                            className="exportButton"
                            onClick={() => this.openConfirmModal(exp.id, exp.email_to)}>
                            <Tooltip
                                title="This will re-process the export and send out a new download link"
                                placement="right">
                                Re-export
                            </Tooltip>
                        </button>
                        {openButton}
                    </React.Fragment>
                );
            } else {
                downloadButtons = (
                    <button
                        className={"exportButton exportButtonProcessing"}
                        disabled>
                        <span>
                            <p className="processingButtonText">Processing</p>

                            <div className="processingSpinner">
                                <OBCSpinner
                                    size={30}
                                    speed={3}
                                    color={"#e8dfff"}
                                />
                            </div>
                        </span>
                    </button>
                );
            }

            if (this.isExportExpired(exp.processed_ts) && exp.processed_ts > 0) {
                return (
                    <div
                        key={exp.id}
                        className="expiredExportDiv">
                        <h2 className="expiredExportHeader">{exp.export_name} (Expired)</h2>

                        <div className="expiredExportFlex">
                            {exp.session_id && <p className="expiredExportInfoText">Session #{exp.session_id}</p>}
                            <p className="expiredExportInfoText">Created : {requestedString}</p>
                            <br />

                            {exp.email_to && (
                                <p className="expiredExportInfoText">
                                    Export delivered to <br />
                                    <b>{exp.email_to}</b>
                                </p>
                            )}
                            {exp.exported_by && (
                                <p className="expiredExportInfoText">
                                    Exported by <br />
                                    <b>{exp.exported_by}</b>
                                </p>
                            )}
                            <div className="expiredExportButtonDiv">{downloadButtons}</div>
                        </div>
                    </div>
                );
            } else {
                console.log("debug image src", `https://exports${MEMOIZED_DOMAIN_URL}/v/${exp.uploaded_file}.thumb.jpg`);
                return (
                    <div
                        key={exp.id}
                        className="exportDiv">
                        <div className="exportDiv-Inner">
                            <div className="exportPreviewDiv">
                                {exp.processed_ts <= 0 ? (
                                    <div className="processingImageDiv">
                                        <OBCSpinner
                                            color="white"
                                            size={100}
                                            speed={3}
                                            height={"auto"}
                                        />
                                        <span>Processing</span>
                                    </div>
                                ) : (
                                    <img
                                        className="exportPreview"
                                        src={`https://exports${MEMOIZED_DOMAIN_URL}/v/${exp.uploaded_file}.thumb.jpg`}
                                        crossOrigin={"anonymous"}
                                        onError={(event) => event.target.setAttribute("src", placeholder)}
                                        alt={"thumbnail"}
                                    />
                                )}

                                {exp.session_id && <p className="exportInfoText">Session #{exp.session_id}</p>}
                                <p className="exportInfoText">Created : {requestedString}</p>
                                <br />
                            </div>
                            <div className="exportInfoDiv">
                                <h2 className="exportHeader">{exp.export_name}</h2>
                                {exp.email_to && (
                                    <p className="exportInfoTextMargin">
                                        Export delivered to <br />
                                        <b>{exp.email_to}</b>
                                    </p>
                                )}
                                {exp.exported_by && (
                                    <p className="exportInfoTextMargin">
                                        Exported by <br />
                                        <b>{exp.exported_by}</b>
                                    </p>
                                )}
                                <p className="exportInfoTextMargin">
                                    {startString} - {endString}
                                </p>
                            </div>
                        </div>
                        <div className="exportDiv-Inner">
                            <div className="exportButtonDiv">{downloadButtons}</div>
                        </div>
                    </div>
                );
            }
        });
    };

    isExportExpired = (processedTS) => {
        return Date.now() / 1000 > processedTS + 60 * 60 * 24 * 7;
    };

    openExportDownload = (exportDownloadID) => {
        window.open(`https://exports${MEMOIZED_DOMAIN_URL}/v/` + exportDownloadID + ".mp4");
    };

    openExportPDF = (exportDownloadID) => {
        window.open(`https://exports${MEMOIZED_DOMAIN_URL}/v/` + exportDownloadID + ".pdf");
    };

    openExportPage = (exportDownloadID, type) => {
        let export_url = `${window.location.origin}/export/${exportDownloadID}`;
        if (type === "marker") {
            export_url = `${window.location.origin}/marker-export/${exportDownloadID}`;
        }
        window.open(export_url, "_blank");
    };

    updateExportEmail = (e) => {
        this.setState({
            emailToExport: e.target.value,
        });
    };

    onExportNameSearchChanged = (searchText) => {
        this.setState({
            exportNameSearch: searchText,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Modal
                    title="Video Export"
                    visible={this.state.showConfirmModal}
                    onOk={this.reprocessExport}
                    onCancel={this.hideConfirmModal}>
                    <p>Re-exporting will cause this export to be completely regenerated using the latest available data, and a new email will be sent to</p>
                    {this.props.adminPermission ? (
                        <input
                            className="exportEmailInput"
                            value={this.state.emailToExport}
                            onChange={this.updateExportEmail}></input>
                    ) : (
                        <p>{this.state.emailToExport}</p>
                    )}
                    {this.state.emailInputError && <p className="error">{this.state.emailInputError}</p>}
                </Modal>
                <MarkupExportModal
                    closeModal={() => this.setState({ creatingExport: false })}
                    visible={this.state.creatingExport}
                />

                <div className="ANPR-InnerDrawer">
                    <div className="sessionSearchFlexRow">
                        <div className="sessionSearch">
                            <Input
                                value={this.state.exportNameSearch}
                                onChange={(e) => this.onExportNameSearchChanged(e.target.value)}
                                placeholder="Search for export"
                                style={{ height: 35 }}
                                spellCheck={false}
                                prefix={<SearchOutlined />}
                                allowClear
                            />
                        </div>
                        {this.props.currentDashboard.config.markup_exports_enabled && (
                            <div className="buttonDiv">
                                <Button
                                    type="primary"
                                    className="csvExportButton"
                                    onClick={() => this.setState({ creatingExport: true })}>
                                    New CSV Export
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className="exportDrawerContainer">
                        <div className="exportDrawerContainer-Inner">
                            {!this.props.exports ? (
                                <div style={{ marginTop: 40 }}>
                                    <OBCSpinner
                                        size={150}
                                        speed={3}
                                        color={"#e8dfff"}
                                    />
                                </div>
                            ) : (
                                this.renderExportItems()
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    exports: state.exports,
    adminPermission: !!state.permissions.admin,
    userConfig: state.userDetails.userConfig,
    currentDashboard: _.find(state.dashboards, (dash) => dash.access_token === state.access_token),
});

export default connect(mapStateToProps)(ExportDrawer);
