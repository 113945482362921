import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { calculateImagePulseCount, calculateRouteCoordinatesForLocation } from "../../util/Geometry";
import mwvLogo from "../../../images/MWV-logo-small.png";
import ELRMileAndChain from "../../util/ELRMileAndChain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { Popover, notification } from "antd";
import moment from "moment";

const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const displayFormatSelector = (state) => state.userDetails.userConfig.elr_units;
const datumOffsetsSelector = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);

    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);

    return workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
};
const mwvPowerUserSelector = (state) => {
    const userEmail = state.userDetails.email;
    return state.userDetails.userConfig.super_admin || (userEmail && userEmail.includes("machineswithvision.com"));
};
const selectedRailImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const pulseCountsSelector = (state) => state.railInspection.railInspectionPulseData;

const RailInspectTileInfo = ({ arrayIndex, railImages, zoom, horizontal, reverse, leftArrow, rightArrow }) => {
    const [extraClass, setExtraClass] = useState([]);

    const elrUnits = useSelector(elrUnitsSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const displayFormat = useSelector(displayFormatSelector);
    const datumOffsets = useSelector(datumOffsetsSelector);
    const mwvPowerUser = useSelector(mwvPowerUserSelector);
    const selectedRailImage = useSelector(selectedRailImageSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const pulseCounts = useSelector(pulseCountsSelector);
    const railImage = railImages[arrayIndex];

    useEffect(() => {
        const classes = [];
        if (leftArrow) {
            if (selectedRailImage && railImage && selectedRailImage.timestamp === railImage.timestamp) {
                if (horizontal) {
                    classes.push("horizontalClosest");
                } else {
                    classes.push("verticalClosest");
                }
                if (reverse) {
                    classes.push("revArrow");
                } else {
                    classes.push("regArrow");
                }
            }
        }
        setExtraClass(classes);
    }, [selectedRailImage, railImage, horizontal, reverse, leftArrow]);

    const imageTime = useMemo(() => {
        if (railImage && railImage.timestamp) {
            const ts = railImage.timestamp;
            const seconds = Math.floor(ts / 1000);
            const ms = (ts % 1000) * 1000000;
            return { seconds, ms };
        }
    }, [railImage]);

    const readableImageTimestamp = useMemo(() => {
        if (railImage && railImage.timestamp) {
            return new Date(railImage.timestamp).toUTCString();
        }
    }, [railImage]);

    const dataAgeTimestamp = useMemo(() => {
        if (railImage.data_timestamp === 0) {
            return "Not Requested";
        } else if (railImage.data_timestamp < 1000) {
            return "Pending";
        } else if (railImage.data_timestamp) {
            return moment.unix(railImage.data_timestamp).format("DD/MM/YYYY");
        }
    }, [railImage.data_timestamp]);

    const calculatedELR = useMemo(() => {
        if (routeLocationData && railImage && railImage.timestamp) {
            let timestamp = railImage.timestamp;

            if (timestamp > -1) {
                const calculatedELR = calculateRouteCoordinatesForLocation(timestamp / 1000, routeLocationData, routeSystemID);
                if (calculatedELR) {
                    const elr = calculatedELR.to_string(elrUnits, datumOffsets, false, horizontal);

                    return elr;
                }
            }
        }
    }, [routeLocationData, elrUnits, datumOffsets, railImage, routeSystemID]);

    const calculatedPulseCount = useMemo(() => {
        if (!_.isEmpty(pulseCounts)) {
            const pulseCount = calculateImagePulseCount(railImage.timestamp, pulseCounts);
            return pulseCount;
        }
    }, [pulseCounts, railImage.timestamp]);

    const mwvData = useMemo(() => {
        if (railImages && railImage) {
            let mwv = railImage["mwv"];
            if (mwv && mwv.elr && mwv.mile && mwv.trid && mwv.yard) {
                mwv = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: mwv.elr,
                        MILE: mwv.mile,
                        TRACK: mwv.trid,
                        YARDS: mwv.yard,
                    },
                    datumOffsets,
                    true,
                );
                return mwv.to_string(displayFormat, datumOffsets, true, horizontal);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }, [railImage, railImages, displayFormat, datumOffsets]);

    const fontSize = useMemo(() => {
        const f = 150 - 30 * zoom;
        if (f < 60) {
            return 30;
        }
        return f;
    }, [zoom]);

    const imgSize = useMemo(() => {
        if (fontSize < 60) {
            return fontSize - 10;
        } else {
            return fontSize - 25;
        }
    }, [fontSize]);

    const onCopyClicked = () => {
        const timestamp = imageTime.seconds + "," + imageTime.ms.toString().padStart(9, "0");
        navigator.clipboard.writeText(timestamp);
        notification.success({ message: "Timestamp copied to clipboard" });
    };

    const isMwvConfirmed = useMemo(() => {
        return _.get(railImage, ["mwv", "confirmed"]);
    });

    if (rightArrow) {
        if (selectedRailImage && railImage && selectedRailImage.timestamp === railImage.timestamp) {
            return <div className={`inspectRail__Tile-Info verticalClosest rightArrow ${reverse ? "revArrow" : "regArrow"}`} />;
        }
        return null;
    }

    return (
        <div className={`inspectRail__Tile-Info ${extraClass.join(" ")}`}>
            {/* when we don't have MWV data */}
            {!mwvData && calculatedELR ? (
                <div className={"inspectRail__Tile-Info__Inner" + (horizontal ? " horizontal" : " vertical")}>
                    <div className="inspectRail__Tile-Info__Inner__Text">
                        <span style={{ fontSize: fontSize + "px" }}>{calculatedELR}</span>
                    </div>
                </div>
            ) : null}

            {!mwvData && calculatedPulseCount ? (
                <div className={"inspectRail__Tile-Info__Inner" + (horizontal ? " horizontal" : " vertical")}>
                    <div className="inspectRail__Tile-Info__Inner__Text__Pulse">
                        <span style={{ fontSize: fontSize / 1.5 + "px" }}>{calculatedPulseCount}</span>
                    </div>
                </div>
            ) : null}

            {/* when we do have MWV data */}
            {mwvData ? (
                <div className={"inspectRail__Tile-Info__Inner" + (horizontal ? " horizontal" : " vertical")}>
                    <Popover
                        content={isMwvConfirmed ? "Confirmed Machines With Vision position" : "Unconfirmed Machines With Vision position"}
                        overlayStyle={{ maxWidth: "500px" }}>
                        <img
                            className="mwvLogo"
                            alt="mwv"
                            src={mwvLogo}
                            style={{ height: imgSize + "px", opacity: isMwvConfirmed ? 1 : 0.4 }}
                            crossOrigin={"anonymous"}
                        />
                    </Popover>
                    <div className="inspectRail__Tile-Info__Inner__Text">
                        <span style={{ fontSize: fontSize + "px" }}>{mwvData}</span>
                        {mwvPowerUser ? (
                            <div>
                                <span
                                    style={{
                                        fontSize: fontSize - 6 + "px",
                                        color: "#4b4b4b",
                                    }}>
                                    {imageTime.seconds},{imageTime.ms.toString().padStart(9, "0")}
                                </span>
                                <FontAwesomeIcon
                                    style={{ fontSize: fontSize - 10 + "px" }}
                                    icon={faCopy}
                                    className="TileCopyButton"
                                    onClick={onCopyClicked}
                                />{" "}
                            </div>
                        ) : null}

                        <span
                            style={{
                                fontSize: fontSize - 6 + "px",
                                color: "#4b4b4b",
                            }}>
                            {readableImageTimestamp}
                        </span>

                        {mwvPowerUser ? (
                            <div>
                                <span
                                    style={{
                                        fontSize: fontSize - 6 + "px",
                                        color: "#4b4b4b",
                                    }}>
                                    MWV Data: {dataAgeTimestamp}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default RailInspectTileInfo;
