import React from "react";
import useMousePosition from "./hooks/useMousePosition";

const PinCursorLine = ({ active }) => {
    const { x, y } = useMousePosition();

    return (
        <div
            style={{ left: `${x - 10}px`, top: `${y}px` }}
            className={`pinCursor pinCursorManualAlignment ${active ? "active" : ""}`}></div>
    );
};

export default PinCursorLine;
