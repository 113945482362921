import React from "react";
import _ from "lodash";
import { MEMOIZED_DOMAIN_URL } from "./util/HostUtils";

class DashboardLogo extends React.PureComponent {
    render() {
        if (this.props.dashboard.branding) {
            let imgURL = `https://branding${MEMOIZED_DOMAIN_URL}/${this.props.dashboard.branding}/square_logo.png?versionId=null`;
            return (
                <img
                    className="DashboardSwapImg"
                    src={imgURL}
                    width={_.get(this.props, "width", 38)}
                    height={_.get(this.props, "height", 38)}
                    alt={"AIVR Logo"}
                    style={{
                        width: _.get(this.props, "width", 38),
                        height: _.get(this.props, "height", 38),
                        ...this.props.customStyles,
                    }}
                />
            );
        } else {
            let abbreviation = this.props.dashboard.description.match(/\b[a-zA-Z]/g).join("");
            let trimmedAbbreviation = abbreviation.substring(0, 3);
            return (
                <div
                    className={"DashboardSwapElement NoBranding"}
                    style={{
                        width: _.get(this.props, "width", 38),
                        height: _.get(this.props, "height", 38),
                        ...this.props.customStyles,
                    }}>
                    {trimmedAbbreviation}
                </div>
            );
        }
    }
}

export default DashboardLogo;
