import * as echarts from "echarts";
import { useCallback, useMemo } from "react";
import { boundedRange, boundedValue, useMouseMovement, useOnClick } from "./utils";

function calculateNewBounds(chart, dragState) {
    const options = chart.getOption();
    const datasets = options.dataset;
    const dataset = datasets.find((_ds) => _ds.id === "nearby_dataset");
    const lowBounds = dataset.transform.config.lowIndex;
    const highBounds = dataset.transform.config.highIndex;
    const xOffset = Math.round(dragState.delta[0]);
    const zOffset = dragState.delta[2];

    if (zOffset) {
        const startingDelta = highBounds - lowBounds;
        const newDelta = boundedValue(50, Math.pow(4, zOffset) * startingDelta, 8200);
        const deltaOffset = (dragState.start[0] - lowBounds) / startingDelta;
        const newDeltaOffset = deltaOffset * newDelta;
        const newLowBound = dragState.start[0] - newDeltaOffset;
        const newUpperBound = newLowBound + newDelta;
        return [newLowBound, newUpperBound];
    } else {
        return [lowBounds + xOffset, highBounds + xOffset];
    }
}

function useELRBoundaries({ chart, palette, zoomBounds, viewBounds, selectBounds, selectIndex, maxBoundsRef }) {
    const elrBoundariesDataset = useMemo(() => {
        return [
            {
                id: "elr_boundaries_dataset",
                transform: {
                    type: "aivr:elrBoundaries",
                },
                fromDatasetId: "overview_dataset",
            },
        ];
    }, []);

    const elrBoundaryGrid = useMemo(() => {
        return [
            {
                id: `elr_boundary_grid`,
                top: `0%`,
                bottom: `10%`,
            },
        ];
    }, []);

    const elrBoundaryXAxis = useMemo(() => {
        return [
            {
                id: `elr_boundary_x`,
                type: "value",
                min: zoomBounds[0],
                max: zoomBounds[1],
                gridId: "elr_boundary_grid",
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisPointer: {
                    show: true,
                    snap: false,
                },
            },
        ];
    }, [zoomBounds]);

    const elrBoundaryYAxis = useMemo(() => {
        return [
            {
                id: `elr_boundary_y`,
                min: 0,
                max: 1,
                gridId: "elr_boundary_grid",
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                    onZero: false,
                },
            },
        ];
    }, []);

    const elrBoundarySeries = useMemo(() => {
        return [
            {
                id: `elr_boundary_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                datasetId: "elr_boundaries_dataset",
                xAxisId: "elr_boundary_x",
                yAxisId: "elr_boundary_y",
                encode: {
                    value: ["index", "text"],
                },
                renderItem: function (params, api) {
                    const index = api.value(0);
                    const text = api.value(1);
                    const startPoint = api.coord([index, 1]);
                    const height = api.size([0, 1])[1];

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: 0,
                            height: height,
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    return (
                        bounds && {
                            type: "group",
                            transition: ["shape"],
                            children: [
                                {
                                    type: "line",
                                    transition: ["shape"],
                                    emphasisDisabled: true,
                                    shape: {
                                        x1: bounds.x,
                                        x2: bounds.x,
                                        y1: bounds.y,
                                        y2: bounds.y + bounds.height,
                                    },
                                    style: {
                                        stroke: palette.elrBoundary,
                                        lineWidth: 1,
                                    },
                                },
                                {
                                    type: "text",
                                    transition: ["shape"],
                                    emphasisDisabled: true,
                                    x: bounds.x,
                                    y: bounds.y + bounds.height,
                                    rotation: Math.PI / 2,
                                    style: {
                                        text,
                                        fill: palette.label,
                                    },
                                },
                            ],
                        }
                    );
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
            {
                id: `selected_index_main_series`,
                type: "custom",
                coordinateSystem: "cartesian2d",
                xAxisId: "elr_boundary_x",
                yAxisId: "elr_boundary_y",
                data: [[-1]],
                renderItem: function (params, api) {
                    const index = api.value(0);
                    const startPoint = api.coord([index, 1]);
                    const height = api.size([0, 1])[1];

                    const bounds = echarts.graphic.clipRectByRect(
                        {
                            x: startPoint[0],
                            y: startPoint[1],
                            width: 0,
                            height: height,
                        },
                        {
                            x: params.coordSys.x,
                            y: params.coordSys.y,
                            width: params.coordSys.width,
                            height: params.coordSys.height,
                        },
                    );

                    return (
                        bounds && {
                            type: "line",
                            transition: ["shape"],
                            emphasisDisabled: true,
                            shape: {
                                x1: bounds.x,
                                x2: bounds.x,
                                y1: bounds.y,
                                y2: bounds.y + bounds.height,
                            },
                            style: {
                                stroke: palette.selected,
                                lineWidth: 1,
                            },
                        }
                    );
                },
                emphasis: {
                    disabled: true,
                },
                animation: false,
            },
        ];
    }, [palette]);

    const dragCallback = useCallback(
        (dragState) => {
            if (chart) {
                viewBounds(calculateNewBounds(chart, dragState));
            }
        },
        [chart, viewBounds],
    );

    const endDragCallback = useCallback(
        (dragState) => {
            let newBounds = calculateNewBounds(chart, dragState);
            newBounds = boundedRange(0, newBounds[0], newBounds[1], maxBoundsRef.current);
            selectBounds(newBounds);
        },
        [chart, selectBounds, maxBoundsRef],
    );

    useMouseMovement(chart, "elr_boundary_grid", dragCallback, endDragCallback);

    const onGridClick = useCallback(
        (coordinateMap) => {
            const coordinates = coordinateMap["elr_boundary_grid"];
            const clickIndex = boundedValue(0, Math.round(coordinates[0]), maxBoundsRef.current);
            selectIndex(clickIndex);
        },
        [selectIndex, maxBoundsRef],
    );

    useOnClick(chart, ["elr_boundary_grid"], onGridClick);

    return useMemo(
        () => ({
            dataset: elrBoundariesDataset,
            grid: elrBoundaryGrid,
            xAxis: elrBoundaryXAxis,
            yAxis: elrBoundaryYAxis,
            series: elrBoundarySeries,
        }),
        [elrBoundariesDataset, elrBoundaryGrid, elrBoundaryXAxis, elrBoundaryYAxis, elrBoundarySeries],
    );
}

export default useELRBoundaries;
