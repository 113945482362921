import React, { useState, useMemo, useCallback, useEffect } from "react";
import { InputNumber, Modal, Slider, Checkbox, Tooltip } from "antd";
import { faEye, faEyeSlash, faChevronLeft, faChevronRight, faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
    setClassificationVisibility,
    setClassificationScoreFilter,
    setCurrentInspectionMarkup,
    reviewClassification,
    setClassificationsTabOpen,
} from "redux/actions";
import { binarySearch } from "../../../util/PlaylistUtils";
import _ from "lodash";
import OBCSpinner from "../../../util/OBC";
import Tippy from "@tippyjs/react";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";

const classificationsVisibleSelector = (state) => state.railInspection.classifications.visibility;
const classificationDataSelector = (state) => state.railInspection.classifications.data;
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const currentClassificationIDSelector = (state) => state.railInspection.classifications.selectedClassification;
const railConfigSelector = (state) => state.railInspection.railInspectionImageConfig;
const allClassificationsFetchedSelector = (state) => state.railInspection.classifications.allClassificationsFetched;
const scoreLimitSelector = (state) => state.railInspection.classifications.score;
const leftRightConfigSelector = (state) => state.railInspection.selectedRailInspectionImage.leftRightConfig;
const superUserSelector = (state) => state.userDetails.userConfig.super_admin;
const csrfTokenSelector = (state) => state.csrfToken;

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

const ClassificationsNavigation = ({ shortcutsActive }) => {
    const [displayFilters, setDisplayFilters] = useState(false);
    const [autoNavigation, setAutoNavigation] = useState(true);
    const [verifiedFilter, setVerifiedFilter] = useState(true);
    const [rejectedFilter, setRejectedFilter] = useState(true);
    const [reviewNeededFilter, setReviewNeededFilter] = useState(true);
    const [recalculateFilters, setRecalculateFilters] = useState(false);

    const classificationsVisible = useSelector(classificationsVisibleSelector);
    const classificationData = useSelector(classificationDataSelector);
    const selectedRailInspectionImage = useSelector(selectedRailInspectionImageSelector);
    const railData = useSelector(railDataObjectSelector);
    const currentClassificationID = useSelector(currentClassificationIDSelector);
    const railConfig = useSelector(railConfigSelector);
    const allClassificationsFetched = useSelector(allClassificationsFetchedSelector);
    const scoreLimit = useSelector(scoreLimitSelector);
    const leftRightConfig = useSelector(leftRightConfigSelector);
    const superAdmin = useSelector(superUserSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setClassificationsTabOpen(true));
    }, []);

    const filteredClassificationData = useMemo(() => {
        let filteredClassifications = [];

        if (verifiedFilter) {
            classificationData.forEach((item) => {
                if (item.review_status === 1) {
                    filteredClassifications.push(item);
                }
            });
        }

        if (rejectedFilter) {
            classificationData.forEach((item) => {
                if (item.review_status === 3) {
                    filteredClassifications.push(item);
                }
            });
        }

        if (reviewNeededFilter) {
            classificationData.forEach((item) => {
                if (item.review_status === 0) {
                    filteredClassifications.push(item);
                }
            });
        }

        return filteredClassifications.filter((cl) => cl.score >= scoreLimit);
    }, [classificationData, recalculateFilters]);

    const currentImageHasClassification = useMemo(() => {
        if (filteredClassificationData && filteredClassificationData.length && selectedRailInspectionImage && selectedRailInspectionImage.timestamp) {
            const index = _.findIndex(filteredClassificationData, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            return index > -1;
        } else {
            return false;
        }
    }, [filteredClassificationData, selectedRailInspectionImage]);

    const closestToLeftIndex = useMemo(() => {
        if (!selectedRailInspectionImage || !filteredClassificationData || !filteredClassificationData.length) {
            return null;
        }
        return binarySearch(selectedRailInspectionImage.timestamp, filteredClassificationData, (c) => c.image_timestamp);
    }, [filteredClassificationData, selectedRailInspectionImage]);

    const closestToRightIndex = useMemo(() => {
        if (filteredClassificationData && closestToLeftIndex > -1) {
            const closestToLeftClassification = filteredClassificationData[closestToLeftIndex];
            if (closestToLeftClassification && closestToLeftClassification.image_timestamp > selectedRailInspectionImage.timestamp) {
                return closestToLeftIndex;
            } else {
                return closestToLeftIndex + 1;
            }
        } else {
            return null;
        }
    }, [closestToLeftIndex, filteredClassificationData, selectedRailInspectionImage]);

    const distanceToRight = useMemo(() => {
        if (
            filteredClassificationData &&
            railData &&
            closestToLeftIndex > -1 &&
            closestToRightIndex &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp
        ) {
            if (closestToRightIndex > filteredClassificationData.length - 1) {
                return null;
            }
            const closestToRight = filteredClassificationData[closestToRightIndex];
            const rightIndex = _.findIndex(railData, (i) => i.timestamp === closestToRight.image_timestamp);

            const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            if (rightIndex && currentIndex > -1) {
                return rightIndex - currentIndex;
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredClassificationData, railData, selectedRailInspectionImage, closestToRightIndex]);

    const distanceToLeft = useMemo(() => {
        if (
            filteredClassificationData &&
            railData &&
            closestToLeftIndex > -1 &&
            filteredClassificationData[closestToLeftIndex] &&
            selectedRailInspectionImage &&
            selectedRailInspectionImage.timestamp
        ) {
            const closestToLeft = filteredClassificationData[closestToLeftIndex];
            if (closestToLeft) {
                const leftIndex = _.findIndex(railData, (i) => i.timestamp === closestToLeft.image_timestamp);
                const currentIndex = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

                // LINE BELOW WAS : if (leftIndex && currentIndex > -1) {
                if (leftIndex > -1 && currentIndex > -1) {
                    return currentIndex - leftIndex;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }, [closestToLeftIndex, filteredClassificationData, railData, selectedRailInspectionImage]);

    const closestIndex = useMemo(() => {
        if (filteredClassificationData && railData && selectedRailInspectionImage) {
            if (currentImageHasClassification) {
                return _.findIndex(filteredClassificationData, (i) => i.image_timestamp === selectedRailInspectionImage.timestamp);
            } else {
                if (!distanceToLeft) {
                    return closestToRightIndex;
                } else if (!distanceToRight) {
                    return closestToLeftIndex;
                } else if (distanceToLeft < distanceToRight) {
                    return closestToLeftIndex;
                } else {
                    return closestToRightIndex;
                }
            }
        } else {
            return null;
        }
    }, [
        closestToLeftIndex,
        currentImageHasClassification,
        filteredClassificationData,
        railData,
        selectedRailInspectionImage,
        closestToRightIndex,
        distanceToLeft,
        distanceToRight,
    ]);

    const currentClassification = useMemo(() => {
        if (!_.isNil(currentClassificationID)) {
            return _.find(filteredClassificationData, { id: currentClassificationID });
        } else {
            if (closestIndex >= 0 && filteredClassificationData) {
                const closestClassification = filteredClassificationData[closestIndex];
                return closestClassification;
            } else {
                return null;
            }
        }
    }, [filteredClassificationData, closestIndex, currentClassificationID]);

    const currentIndex = useMemo(() => {
        if (currentClassificationID) {
            return _.findIndex(filteredClassificationData, { id: currentClassificationID });
        }
        return null;
    }, [filteredClassificationData, currentClassificationID]);

    const navigate = useCallback(
        (index) => {
            if (filteredClassificationData && filteredClassificationData.length) {
                if (filteredClassificationData[index]) {
                    dispatch(setCurrentInspectionMarkup("classification", filteredClassificationData[index].id));
                }
            }
        },
        [dispatch, filteredClassificationData],
    );

    const disableBack = useMemo(() => {
        if (!filteredClassificationData.length) {
            return true;
        }
        if (filteredClassificationData.length > 1) {
            return closestToRightIndex <= 0;
        } else {
            return closestIndex === currentIndex || closestToRightIndex === 0;
        }
    }, [currentIndex, closestToLeftIndex, closestToRightIndex, filteredClassificationData, closestIndex]);

    const disableForward = useMemo(() => {
        if (!filteredClassificationData.length) {
            return true;
        }
        if (filteredClassificationData.length > 1) {
            return currentIndex + 2 > filteredClassificationData.length;
        } else {
            return closestIndex === currentIndex || closestToRightIndex === filteredClassificationData.length;
        }
    }, [currentIndex, filteredClassificationData.length, closestIndex, closestToRightIndex]);

    const goForward = useCallback(() => {
        if (!disableForward) {
            if (!_.isNil(currentIndex) && currentIndex > -1) {
                navigate(currentIndex + 1);
            } else {
                navigate(closestToRightIndex);
            }
        }
    }, [navigate, currentIndex, closestToRightIndex, disableForward]);

    const goBack = useCallback(() => {
        if (!disableBack) {
            if (currentIndex && currentIndex > -1) {
                navigate(currentIndex - 1);
            } else {
                navigate(closestToLeftIndex);
            }
        }
    }, [navigate, currentIndex, closestToLeftIndex, disableBack]);

    const handleKeyPress = useCallback(
        (event) => {
            if (!shortcutsActive) {
                return;
            }
            if (event.key === ",") {
                goBack();
            } else if (event.key === ".") {
                goForward();
            } else if (event.key === "v" || event.key === "V") {
                revMarker(1);
            } else if (event.key === "r" || event.key === "R") {
                revMarker(3);
            }
        },
        [goBack, goForward, shortcutsActive],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress, false);

        return () => {
            document.removeEventListener("keydown", handleKeyPress, false);
        };
    }, [handleKeyPress]);

    const distanceFromClassification = useMemo(() => {
        let position = distanceToLeft;

        let directionIcon = <FontAwesomeIcon icon={faArrowLeft} />;

        if (Math.abs(distanceToLeft) >= Math.abs(distanceToRight)) {
            position = distanceToRight;
            directionIcon = <FontAwesomeIcon icon={faArrowRight} />;
        }

        if (!distanceToRight) {
            position = distanceToLeft;
            if (!_.isNil(closestIndex) && closestIndex + 1 > filteredClassificationData.length - 1) {
                directionIcon = <FontAwesomeIcon icon={faArrowLeft} />;
            } else {
                directionIcon = <FontAwesomeIcon icon={faArrowRight} />;
            }
        }

        if (position === 0) {
            directionIcon = null;
        }

        return (
            <div
                style={{ color: "#a9c447", fontWeight: "bold" }}
                className="distance">
                <span>{Math.abs(position)}m </span>
                {directionIcon}
            </div>
        );
    }, [distanceToLeft, distanceToRight, closestIndex, filteredClassificationData]);

    const railScores = useMemo(() => {
        let railScoresStr = "";
        if (currentClassification && leftRightConfig) {
            if (currentClassification.bbox.left_rail.class === "contaminated") {
                railScoresStr += `${leftRightConfig.top === "left" ? "Left rail" : "Right rail"} (${Math.round(currentClassification.bbox.left_rail.score * 100)}%)`;
            }
            if (currentClassification.bbox.right_rail.class === "contaminated") {
                if (railScoresStr.length > 0) {
                    railScoresStr += " - ";
                }
                railScoresStr += `${leftRightConfig.top === "left" ? "Right rail" : "Left rail"} (${Math.round(currentClassification.bbox.right_rail.score * 100)}%)`;
            }
        }
        return railScoresStr;
    }, [currentClassification, leftRightConfig]);

    const classificationItem = useMemo(() => {
        if (currentClassification && railConfig && railConfig.inspection_images && railConfig.inspection_images.length) {
            let classificationImage = null;
            const deviceName = railConfig.inspection_images[0].source;
            const image = _.find(railData, ["timestamp", currentClassification.image_timestamp]);

            if (image) {
                let base_path = image.base_path;
                const new_base_path = base_path.replace("$DEVICE", `${deviceName}`);
                let imagePath = baseURL + new_base_path + "/" + image.timestamp + ".jpg";

                if (csrfToken) {
                    imagePath += `?csrf=${csrfToken}`;
                }

                classificationImage = (
                    <img
                        alt="Classification Thumbnail"
                        src={imagePath}
                        crossOrigin={"anonymous"}
                    />
                );
            }

            return (
                <div className="InspectRailClassificationsMain">
                    <div className={`InspectRailClassificationsMainImage ${Math.abs(distanceToLeft) === 0 ? "current" : ""}`}>
                        {classificationImage}
                        {currentImageHasClassification && superAdmin && (
                            <Tippy
                                content={currentClassification.image_timestamp}
                                placement="bottom"
                                arrow={true}>
                                <div className="InspectionRailClassificationsTooltip" />
                            </Tippy>
                        )}
                    </div>
                    <div className="InspectRailClassificationsMainInfoContent">
                        {currentImageHasClassification ? (
                            <>
                                <div className="InspectRailClassificationsMainInfo">
                                    <h2 className="InspectRailClassificationsMainTitle">{currentClassification.name}</h2>
                                    <p className="InspectRailClassificationsMainValue">{currentClassification.id}</p>
                                </div>
                                <div className="InspectRailClassificationsMainInfo">
                                    <div className="InspectRailClassificationsMainTitle">{currentClassification.result}</div>
                                </div>
                                <div className="InspectRailClassificationsMainInfo">
                                    <div className="InspectRailClassificationsMainTitle">Score:</div>
                                    <div className="InspectRailClassificationsMainValue Success">{currentClassification.score}%</div>
                                </div>
                                <div className="InspectRailClassificationsMainInfo">
                                    <div className="InspectRailClassificationsMainValue Success">{railScores}</div>
                                </div>
                                {distanceFromClassification}
                            </>
                        ) : (
                            <div className="InspectRailClassificationsMainInfo">
                                <h2 className="InspectRailClassificationsMainTitle">No result</h2>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }, [currentClassification, railData, railConfig, distanceFromClassification, distanceToLeft, railScores]);

    let classificationIndex = useMemo(() => {
        let index = currentIndex;
        if (_.isNil(currentIndex) || currentIndex < 0) {
            index = closestIndex;
        }

        if (index > -1 && filteredClassificationData && filteredClassificationData.length) {
            return (
                <div className="markerIndex">
                    <p>{index + 1}</p>
                    <span>of</span>
                    <p>{filteredClassificationData.length}</p>
                </div>
            );
        } else {
            return null;
        }
    }, [filteredClassificationData, currentIndex, closestIndex]);

    const navigationButtons = useMemo(() => {
        if (!classificationItem) {
            return null;
        }
        return (
            <div className="markerControls wide">
                <div
                    className={`markerControls__Item ${disableBack ? "disabled" : ""}`}
                    onClick={goBack}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>Back (,)</span>
                </div>
                {classificationIndex}
                <div
                    className={`markerControls__Item ${disableForward ? "disabled" : ""}`}
                    onClick={goForward}>
                    <span>Next (.)</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </div>
        );
    }, [classificationIndex, goBack, goForward, disableForward, disableBack, classificationItem]);

    const distanceToNextClassification = useMemo(() => {
        if (selectedRailInspectionImage) {
            const index = _.findIndex(railData, (i) => i.timestamp === selectedRailInspectionImage.timestamp);

            let prevClassification;
            if (closestToLeftIndex > -1 && filteredClassificationData[closestToLeftIndex]) {
                const closestDet = filteredClassificationData[closestToLeftIndex];

                prevClassification = closestDet;
                if (closestDet && closestDet.image_timestamp === selectedRailInspectionImage.timestamp) {
                    prevClassification = filteredClassificationData[closestToLeftIndex - 1];
                }
            }

            const nextClassification = filteredClassificationData[closestToRightIndex];
            let prevDistance = 0;
            let nextDistance = 0;

            if (prevClassification && closestToLeftIndex !== 0) {
                const prevClassificationIndex = _.findIndex(railData, (i) => i.timestamp === prevClassification.image_timestamp);
                prevDistance = index - prevClassificationIndex;
            }
            if (nextClassification) {
                const nextClassificationIndex = _.findIndex(railData, (i) => i.timestamp === nextClassification.image_timestamp);
                nextDistance = nextClassificationIndex - index;
            }

            if (prevClassification || nextClassification) {
                return (
                    <div className="InspectRailClassificationsInfo">
                        <div className="InspectRailClassificationsInfoPrev">
                            {!!prevDistance && (
                                <>
                                    <p>Previous Classifications</p>
                                    <span style={{ color: "#a9c447" }}>{prevDistance}m</span>
                                </>
                            )}
                        </div>
                        <div className="InspectRailClassificationsInfoNext">
                            {!!nextDistance && (
                                <>
                                    <p>Next Classifications</p>
                                    <span style={{ color: "#a9c447" }}>{nextDistance}m</span>
                                </>
                            )}
                        </div>
                    </div>
                );
            }
        }
    }, [filteredClassificationData, railData, selectedRailInspectionImage, closestToLeftIndex, closestToRightIndex]);

    const handleVisibility = () => {
        dispatch(setClassificationVisibility());
    };

    const visibilityButton = useMemo(() => {
        let icon = faEye;
        if (classificationsVisible) {
            icon = faEyeSlash;
        }

        return (
            <div
                className="inspection-default-button VisibilityButton"
                onClick={handleVisibility}>
                <FontAwesomeIcon icon={icon} />
            </div>
        );
    }, [classificationsVisible, handleVisibility]);

    const revMarker = useCallback(
        (review) => {
            dispatch(reviewClassification(currentClassification.id, review));
            currentClassification.review_status = review;
            if (autoNavigation) {
                goForward();
            }
        },
        [currentClassification, dispatch, goForward, autoNavigation],
    );

    const reviewSection = useMemo(() => {
        if (currentClassification && Math.abs(distanceToLeft) === 0) {
            return (
                <div className="inspectRail__Detections__Review">
                    <div className="inspectRail__Detections__Review__Inner">
                        <button
                            className="reviewButton reject"
                            onClick={() => revMarker(3)}>
                            <span>Reject (R)</span>
                        </button>
                        <button
                            className="reviewButton verify"
                            onClick={() => revMarker(1)}>
                            <span>Verify (V)</span>
                        </button>
                    </div>
                </div>
            );
        }
    }, [currentClassification, revMarker, distanceToLeft]);

    let content = (
        <div className="SchemaRuns__Spinner">
            <OBCSpinner
                size={70}
                speed={3}
                colorScheme="mono"
            />
        </div>
    );

    if (allClassificationsFetched) {
        content = (
            <div className="InspectRailClassificationsContent">
                <div className="InspectRailClassificationsTop">
                    <div className="Title">Classifications</div>
                </div>
                {classificationItem}
                {distanceToNextClassification}
                {navigationButtons}
                {reviewSection}
                <div className="InspectionClassificationsRow">
                    <Tooltip title="Automatically go to next classification after selecting condition">
                        <Checkbox
                            style={{ color: "white" }}
                            checked={autoNavigation}
                            onChange={(e) => setAutoNavigation(e.target.checked)}>
                            Auto next
                        </Checkbox>
                    </Tooltip>
                    {currentClassification && (
                        <p className="InspectionClassificationCondition">
                            {currentClassification.review_status === 1 ? "Verified" : currentClassification.review_status === 3 ? "Rejected" : ""}
                        </p>
                    )}
                </div>
            </div>
        );
    }

    if (allClassificationsFetched && !filteredClassificationData.length) {
        content = (
            <div className="InspectRailClassificationsMessage">
                <span>No Classifications</span>
            </div>
        );
    }

    return (
        <div className="InspectRailClassifications">
            <div className="Controlls">
                <button
                    className="inspection-default-button"
                    onClick={() => setDisplayFilters(!displayFilters)}>
                    Filter
                </button>
                {visibilityButton}
            </div>
            <Modal
                className="InspectRailClassificationsFilters"
                title="Classification Filters"
                visible={displayFilters}
                onCancel={() => {
                    setDisplayFilters(false);
                    dispatch(setClassificationScoreFilter(0));
                    setRejectedFilter(true);
                    setVerifiedFilter(true);
                    setReviewNeededFilter(true);
                }}
                onOk={() => {
                    setDisplayFilters(false);
                    setRecalculateFilters(!recalculateFilters);
                }}>
                <div className="InspectRailClassificationsReviewRow">
                    <h3>Review Status</h3>
                    <div className="InspectRailClassificationsReviewFilter">
                        <Checkbox
                            className="InspectRailClassificationsReviewFilter-Checkbox"
                            key={1}
                            checked={verifiedFilter}
                            s
                            onChange={(e) => setVerifiedFilter(e.target.checked)}>
                            Verified
                        </Checkbox>
                        <Checkbox
                            className="InspectRailClassificationsReviewFilter-Checkbox"
                            key={0}
                            checked={reviewNeededFilter}
                            onChange={(e) => setReviewNeededFilter(e.target.checked)}>
                            Needs Review
                        </Checkbox>
                        <Checkbox
                            className="InspectRailClassificationsReviewFilter-Checkbox"
                            key={3}
                            checked={rejectedFilter}
                            onChange={(e) => setRejectedFilter(e.target.checked)}>
                            Rejected
                        </Checkbox>
                    </div>
                </div>
                <div className="InspectRailClassificationsReviewRow">
                    <h3 className="InspectRailClassificationsScore">Score:</h3>
                    <div className="InspectRailClassificationsFiltersContainer">
                        <Slider
                            className="InspectRailClassificationsFiltersSlider"
                            min={0}
                            max={100}
                            onChange={(e) => dispatch(setClassificationScoreFilter(e))}
                            value={scoreLimit}
                        />
                        <InputNumber
                            min={0}
                            max={100}
                            value={scoreLimit}
                            onChange={(e) => dispatch(setClassificationScoreFilter(e))}
                        />
                    </div>
                </div>
            </Modal>
            {content}
        </div>
    );
};

export default ClassificationsNavigation;
