import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { LayerGroup, Marker, Tooltip } from "react-leaflet";
import { DeviceIcon } from "./Icons";

class DeviceMarkers extends React.PureComponent {
    constructor(props) {
        super(props);
        this.deviceStatusPoller = null;
    }

    renderDevice = (device) => {
        if (device.latitude && device.longitude) {
            let device_age = Math.floor(new Date().getTime() / 1000 - device.last_seen);

            let too_old = false;

            let units = "seconds";

            if (device_age > 60) {
                device_age = Math.floor(device_age / 60);
                units = "minutes";

                if (device_age > 60) {
                    device_age = Math.floor(device_age / 60);
                    units = "hours";

                    if (device_age > 24) {
                        device_age = Math.floor(device_age / 24);
                        units = "days";

                        if (device_age >= 7) {
                            too_old = true;
                        }
                    }
                }
            }

            if (!too_old) {
                return (
                    <Marker
                        key={device.device_id}
                        icon={DeviceIcon}
                        position={[device.latitude, device.longitude]}>
                        <Tooltip
                            className="Incident-Tooltip"
                            permanent={false}
                            direction={"top"}>
                            <strong>{device.description}</strong>
                            <br />
                            Last seen: {device_age} {units} ago
                            <br />
                            Recording: {device.recording ? "Yes" : "No"}
                            <br />
                            Battery: {device.battery_percentage ? device.battery_percentage + "%" : "Unknown"}
                        </Tooltip>
                    </Marker>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    render() {
        return <LayerGroup>{_.map(this.props.deviceStatuses, this.renderDevice)}</LayerGroup>;
    }
}

const mapStateToProps = (state) => ({
    deviceStatuses: state.deviceStatuses,
});

export default connect(mapStateToProps)(withRouter(DeviceMarkers));
