import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, DatePicker, Input, Modal, notification, Radio, Select, Tag, Tooltip, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    createShortcut,
    getShortcutDevices,
    getShortcuts,
    multiRouteCoordinateReverseLookup,
    resetShortcutSessions,
    updateShortcut,
    customAudit,
    getElrStartEnd,
} from "../redux/actions/index";
import _ from "lodash";
import ELRMileAndChain from "./util/ELRMileAndChain";
import ShortcutsItem from "./ShortcutsItem";
import moment from "moment";
import validator from "validator";
import { faFlagCheckered, faSpinner } from "@fortawesome/free-solid-svg-icons";
import OBCSearchInput from "./OBC/OBCSearchInput";
import OBCSpinner from "./util/OBC";
import { renderTagPanels, filterSessionTags } from "./util/TagUtils.js";

const { CheckableTag } = Tag;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const sessionTagsSelector = (state) => state.sessionTags;
const shortcutDevicesSelector = (state) => state.shortcuts.devices;
const shortcutsSelector = (state) => state.shortcuts.shortcuts || [];
const isAdminSelector = (state) => {
    let currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);
    return _.get(currentDashboard, ["permissions", "admin"], false) || _.get(state.userDetails, ["userConfig", "super_admin"], false);
};

const RequestStartEndPosition = ({ elr, callback }) => {
    const [loading, setLoading] = useState(false);
    const [fetchedELR, setFetched] = useState("");

    const dispatch = useDispatch();

    const fetchELRStartEnd = useCallback(
        (elr) => {
            setLoading(true);
            dispatch(getElrStartEnd(elr))
                .then((response) => {
                    callback(response);
                    setLoading(false);
                    setFetched(elr);
                })
                .catch((err) => {
                    notification.error({ message: "Error getting elr start and end" });
                });
        },
        [dispatch, callback],
    );

    return loading ? (
        <FontAwesomeIcon
            className={"StartEndIcon"}
            icon={faSpinner}
            spin
        />
    ) : (
        <Tooltip title="Calculate Start & End Positions">
            <FontAwesomeIcon
                onClick={() => fetchELRStartEnd(elr)}
                className={"StartEndIcon" + (!elr || fetchedELR === elr ? " Disabled" : "")}
                icon={faFlagCheckered}
            />
        </Tooltip>
    );
};

const ShortcutsTab = () => {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [elrRows, setElrRows] = useState([
        { elr: "", start_mile: "", start_chain: "", end_mile: "", end_chain: "", elrErrored: false, startErrored: false, endErrored: false },
    ]);
    const [elrRowsError, setElrRowsError] = useState(false);

    const [name, setName] = useState("");
    const [searchString, setSearchString] = useState("");
    const [calendarRadio, setCalendarRadio] = useState("no-date");
    const [devicesOrGroup, setDevicesOrGroup] = useState([-1]);
    const [devicesOrGroupError, setDevicesOrGroupError] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [updatingID, setUpdatingID] = useState();
    const [updatingShortcut, setUpdatingShortcut] = useState(false);
    const [dateMoment, setDateMoment] = useState();
    const [createDisabled, setCreateDisabled] = useState(true);
    const [shortcutPrivate, setPrivate] = useState(false);
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [loadingShortcutList, setLoadingShortcutList] = useState(true);
    const intervalRef = useRef(null);

    const sessionTags = useSelector(sessionTagsSelector);
    const devices = useSelector(shortcutDevicesSelector);
    const shortcuts = useSelector(shortcutsSelector);
    const isAdmin = useSelector(isAdminSelector);

    const filteredSessionTags = useMemo(() => {
        return filterSessionTags(sessionTags);
    }, [sessionTags]);

    useEffect(() => {
        dispatch(getShortcuts(() => setLoadingShortcutList(false)));
        if ((!devices || !devices.length) && isAdmin) {
            dispatch(getShortcutDevices());
        }
    }, []);

    const includesProcessingItems = useMemo(() => {
        if (shortcuts && shortcuts.length > 0) {
            return shortcuts.some((shortcut) => shortcut.processing);
        }
        return false;
    }, [shortcuts]);

    useEffect(() => {
        if (includesProcessingItems) {
            intervalRef.current = setInterval(() => {
                dispatch(getShortcuts());
            }, 10000);
        } else {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [dispatch, includesProcessingItems]);

    useEffect(() => {
        dispatch(customAudit("shortcut_list_opened"));
    }, [dispatch]);

    const openModal = useCallback(
        (shortcutID) => {
            setUpdatingID(shortcutID);
            if (shortcutID) {
                const shortcutItem = _.find(shortcuts, { id: shortcutID });
                setName(shortcutItem.config.name);
                setPrivate(shortcutItem.private);
                setEmailNotifications(shortcutItem.email_notifications);

                const newElrRows = shortcutItem.config.sections.map((row) => {
                    const elr = row.elr;
                    const start_mile = Math.trunc(row.start_chain / 80);
                    const start_chain = row.start_chain - start_mile * 80;
                    const end_mile = Math.trunc(row.end_chain / 80);
                    const end_chain = row.end_chain - end_mile * 80;
                    const trid = row.track_id || "";

                    return {
                        elr: elr,
                        start_mile: start_mile,
                        start_chain: start_chain,
                        end_mile: end_mile,
                        end_chain: end_chain,
                        trid: trid,
                    };
                });
                setElrRows(newElrRows);

                let dateObj = [0, 0];
                if (shortcutItem.config.start_ts > 0) {
                    dateObj[0] = moment(shortcutItem.config.start_ts * 1000);
                }
                if (shortcutItem.config.end_ts > 0) {
                    dateObj[1] = moment(shortcutItem.config.end_ts * 1000);
                }
                setDateMoment(dateObj);

                if (shortcutItem.config.start_ts > 0 && shortcutItem.config.end_ts <= 0) {
                    setCalendarRadio("start-date");
                } else if (shortcutItem.config.start_ts > 0 && shortcutItem.config.end_ts > 0) {
                    setCalendarRadio("end-date");
                } else {
                    setCalendarRadio("no-date");
                }

                let devicesOrGroups = [-1];
                // this is back compatibility - when user update shortcut it will replace device with devices list
                if (shortcutItem.config.device) {
                    devicesOrGroups = [shortcutItem.config.device];
                }

                if (shortcutItem.config.devices) {
                    devicesOrGroups = shortcutItem.config.devices;
                }
                setDevicesOrGroup(devicesOrGroups);

                let newTags = [];
                if (shortcutItem.config.tags) {
                    newTags = shortcutItem.config.tags;
                }
                setSelectedTags(newTags);
            }
            setModalOpen(true);
        },
        [shortcuts],
    );

    const closeModal = () => {
        setModalOpen(false);
        setName("");
        setPrivate(false);
        setElrRows([{ elr: "", start_mile: "", start_chain: "", end_mile: "", end_chain: "", elrErrored: false, startErrored: false, endErrored: false }]);
        setCalendarRadio("no-date");
        setDateMoment([]);
        setDevicesOrGroup([-1]);
        setSelectedTags([]);
        setElrRowsError(false);
        setDevicesOrGroupError(false);
    };

    const validateModal = useCallback(() => {
        return (
            !validator.isEmpty(name) &&
            validator.isLength(name, { min: 1 }) &&
            !validator.isEmpty(elrRows[0].elr) &&
            validator.isLength(elrRows[0].elr, { min: 3 }) &&
            !validator.isEmpty(elrRows[0].start_mile + "") &&
            validator.isLength(elrRows[0].start_mile + "", { min: 1 }) &&
            !validator.isEmpty(elrRows[0].start_chain + "") &&
            validator.isLength(elrRows[0].start_chain + "", { min: 1 }) &&
            !validator.isEmpty(elrRows[0].end_mile + "") &&
            validator.isLength(elrRows[0].end_mile + "", { min: 1 }) &&
            !validator.isEmpty(elrRows[0].end_chain + "") &&
            validator.isLength(elrRows[0].end_chain + "", { min: 1 })
        );
    }, [name, elrRows]);

    useEffect(() => {
        if (validateModal()) {
            setCreateDisabled(false);
        } else {
            setCreateDisabled(true);
        }
    }, [name, elrRows, validateModal]);

    const handleModalOk = () => {
        setElrRowsError(false);
        setDevicesOrGroupError(false);

        const convertedElrRows = elrRows.map((item) => {
            let start = ELRMileAndChain.from_fields(
                "ELR Mile & Chain",
                "elr_mile_chain",
                {
                    ELR: item.elr,
                    MILE: parseInt(item.start_mile),
                    CHAIN: parseInt(item.start_chain),
                },
                [],
            );
            let start_position = start.position;

            let end = ELRMileAndChain.from_fields(
                "ELR Mile & Chain",
                "elr_mile_chain",
                {
                    ELR: item.elr,
                    MILE: parseInt(item.end_mile),
                    CHAIN: parseInt(item.end_chain),
                },
                [],
            );
            let end_position = end.position;

            return {
                elr: item.elr,
                start_chain: start_position,
                end_chain: end_position,
                track_id: item.trid,
            };
        });

        dispatch(
            multiRouteCoordinateReverseLookup("ELR Mile & Chain", convertedElrRows, (response) => {
                const chunkedRows = _.chunk(response, 2);

                let newElrRows = _.clone(elrRows);
                let isDevicesError = false;
                let isElrError = false;

                if (_.isEmpty(devicesOrGroup)) {
                    isDevicesError = true;
                }

                chunkedRows.forEach((chunk, index) => {
                    if (chunk[0] === "no_matches") {
                        newElrRows[index].elrErrored = true;
                        isElrError = true;
                    } else if (chunk[0] === "closest") {
                        newElrRows[index].startErrored = true;
                        isElrError = true;
                    }
                    if (chunk[1] === "no_matches") {
                        newElrRows[index].elrErrored = true;
                        isElrError = true;
                    } else if (chunk[1] === "closest") {
                        newElrRows[index].endErrored = true;
                        isElrError = true;
                    }
                });

                setElrRows(newElrRows);

                setElrRowsError(isElrError);
                setDevicesOrGroupError(isDevicesError);

                if (isDevicesError || isElrError) {
                    return;
                }

                let start_ts = 0;
                let end_ts = 0;
                if (calendarRadio === "start-date" && dateMoment[0]) {
                    start_ts = dateMoment[0].unix();
                }
                if (calendarRadio === "end-date" && dateMoment[0] && dateMoment[1]) {
                    start_ts = dateMoment[0].unix();
                    end_ts = dateMoment[1].unix();
                }

                const jsonObject = {
                    name: name,
                    start_ts: start_ts,
                    end_ts: end_ts,
                    sections: convertedElrRows,
                    tags: selectedTags,
                };

                if (devicesOrGroup.length >= 1 && devicesOrGroup[0] !== -1) {
                    jsonObject["devices"] = devicesOrGroup;
                }

                setUpdatingShortcut(true);

                if (updatingID) {
                    dispatch(updateShortcut(updatingID, jsonObject, shortcutPrivate, emailNotifications))
                        .then(() => {
                            dispatch(resetShortcutSessions(updatingID));
                            dispatch(
                                getShortcuts(() => {
                                    closeModal();
                                    setUpdatingShortcut(false);
                                }),
                            );
                        })
                        .catch(() => {
                            notification["error"]({
                                message: "Error creating shortcut",
                            });
                            setUpdatingShortcut(false);
                        });
                } else {
                    dispatch(createShortcut(jsonObject, shortcutPrivate, emailNotifications))
                        .then(() => {
                            dispatch(
                                getShortcuts(() => {
                                    closeModal();
                                    setUpdatingShortcut(false);
                                }),
                            );
                        })
                        .catch(() => {
                            notification["error"]({
                                message: "Error creating shortcut",
                            });
                            setUpdatingShortcut(false);
                        });
                }
            }),
        );
    };

    const handleTagChange = (tag) => {
        console.log("debug handle tag change", tag);
        let nextSelectedTags = _.clone(selectedTags);
        if (selectedTags.includes(tag)) {
            nextSelectedTags = nextSelectedTags.filter((t) => t !== tag);
        } else {
            nextSelectedTags.push(tag);
        }
        console.log("debug setting nextSelectedTags", nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleCalendarRadios = (e) => {
        setCalendarRadio(e.target.value);
    };

    const handleExtraRow = () => {
        const updatedElrRows = _.clone(elrRows);
        updatedElrRows.push({
            elr: "",
            track_id: "",
            start_mile: "",
            start_chain: "",
            end_mile: "",
            end_chain: "",
            elrErrored: false,
            startErrored: false,
            endErrored: false,
        });
        setElrRows(updatedElrRows);
    };

    const handleRemoveRow = (idx) => {
        let updatedElrRows = _.clone(elrRows);
        updatedElrRows.splice(idx, 1);
        setElrRows(updatedElrRows);
    };

    const handleDeviceSelection = (values) => {
        let newValues = _.cloneDeep(values);
        if (newValues.includes(-1)) {
            if (newValues.length > 1) {
                newValues = newValues.filter((val) => val !== -1);
            }
            if (devicesOrGroup.length > 1) {
                newValues = [-1];
            }
        }
        setDevicesOrGroup(newValues);
    };

    const handlePicker = (single, value) => {
        let start = 0;
        let end = 0;

        if (value) {
            if (single) {
                start = value.startOf("day");
            } else {
                start = value[0].startOf("day");
                end = value[1].endOf("day");
            }
        }
        setDateMoment([start, end]);
    };

    const handleELRInput = (e, key, index) => {
        const updatedElrRows = _.clone(elrRows);
        elrRows[index][key] = e.target.value;
        setElrRows(updatedElrRows);
    };

    const shortcutItems = useMemo(() => {
        let items = {};
        if (shortcuts && shortcuts.length) {
            items = _.filter(shortcuts, (shortcut) => {
                // below will still return if the shortcut has blank name
                if (searchString.length > 0) {
                    return shortcut.config && shortcut.config.name && shortcut.config.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
                } else {
                    return true;
                }
            });

            items = _.map(items, (shortcut) => {
                return (
                    <ShortcutsItem
                        key={shortcut.id}
                        shortcut={shortcut}
                        openModal={openModal}
                        searchString={searchString}
                    />
                );
            });

            if (items.length === 0 && searchString.length > 0) {
                return <h1 className="NoShortcutsText">No Shortcuts Found</h1>;
            }
        } else {
            return <h1 className="NoShortcutsText">No Shortcuts Created</h1>;
        }

        return items;
    }, [shortcuts, openModal, searchString]);

    const elrError = useMemo(() => {
        return _.findIndex(elrRows, (row) => row.startErrored || row.endErrored) > -1;
    }, [elrRows]);

    const handleStartEndResponse = useCallback(
        (response, idx) => {
            if (response.start_position) {
                let startPos = response.start_position;
                let endPos = response.end_position + 1;

                startPos = Math.max(0, startPos - 1);

                let startMile = Math.floor(startPos / 80);
                let startChain = startPos - startMile * 80;

                let endMile = Math.floor(endPos / 80);
                let endChain = endPos - endMile * 80;

                const currentELRs = _.clone(elrRows);
                currentELRs[idx].start_mile = startMile;
                currentELRs[idx].start_chain = startChain;
                currentELRs[idx].end_mile = endMile;
                currentELRs[idx].end_chain = endChain;
                setElrRows(currentELRs);
            } else {
                notification.error({ message: "Error finding ELR data" });
            }
        },
        [elrRows],
    );

    const onSearchStringChanged = (value) => {
        setSearchString(value);
    };

    return (
        <>
            <Modal
                title={`${updatingID ? "Edit" : "Create"} Shortcut`}
                className="ShortcutsTabModal"
                visible={modalOpen}
                onCancel={closeModal}
                onOk={handleModalOk}
                width={"40%"}
                okText={updatingID ? "Update" : "Create"}
                okButtonProps={{ disabled: createDisabled, loading: updatingShortcut }}>
                <div className="ModalRow">
                    <h4>Name</h4>
                    <div className="ModalSectionRow">
                        <Input
                            placeholder="Name"
                            value={name}
                            onChange={(e) => handleNameChange(e)}
                        />
                    </div>
                </div>

                <div className="ModalRow">
                    <div className="ModalRowElrRange">
                        <h4>ELR Ranges</h4>
                        <button className="AddButton">
                            <FontAwesomeIcon
                                icon={faPlus}
                                onClick={handleExtraRow}
                            />
                        </button>
                    </div>
                    {elrRows.map((elrObject, index) => (
                        <div
                            className={`ModalSectionRow ${index === 0 && "first-child"}`}
                            key={index}>
                            <Input
                                style={{ textTransform: "uppercase" }}
                                value={elrObject.elr}
                                placeholder="ELR"
                                className={`${elrObject.elrErrored ? "elr-error" : ""}`}
                                onChange={(e) => handleELRInput(e, "elr", index)}
                            />
                            <RequestStartEndPosition
                                elr={elrObject.elr}
                                callback={(response) => handleStartEndResponse(response, index)}
                            />
                            <Input
                                value={elrObject.trid}
                                placeholder="Track ID"
                                onChange={(e) => handleELRInput(e, "trid", index)}
                            />
                            <Input
                                value={elrObject.start_mile}
                                placeholder="Start Mile"
                                className={`${elrObject.startErrored ? "elr-error" : ""}`}
                                onChange={(e) => handleELRInput(e, "start_mile", index)}
                            />
                            <Input
                                value={elrObject.start_chain}
                                placeholder="Start Chain"
                                className={`${elrObject.startErrored ? "elr-error" : ""}`}
                                onChange={(e) => handleELRInput(e, "start_chain", index)}
                            />
                            <span>-</span>
                            <Input
                                value={elrObject.end_mile}
                                placeholder="End Mile"
                                className={`${elrObject.endErrored ? "elr-error" : ""}`}
                                onChange={(e) => handleELRInput(e, "end_mile", index)}
                            />
                            <Input
                                value={elrObject.end_chain}
                                placeholder="End Chain"
                                className={`${elrObject.endErrored ? "elr-error" : ""}`}
                                onChange={(e) => handleELRInput(e, "end_chain", index)}
                            />
                            {index > 0 && (
                                <button
                                    className="RemoveButton"
                                    onClick={() => handleRemoveRow(index)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </div>
                    ))}
                    {elrRowsError ? <h5 style={{ color: "red" }}>Invalid data provided</h5> : null}
                </div>

                <div className="ModalRow">
                    <h4>Date</h4>
                    <Radio.Button
                        onChange={handleCalendarRadios}
                        value="no-date"
                        checked={calendarRadio === "no-date"}>
                        No Date
                    </Radio.Button>
                    <Radio.Button
                        onChange={handleCalendarRadios}
                        value="start-date"
                        checked={calendarRadio === "start-date"}>
                        Start Date Only
                    </Radio.Button>
                    <Radio.Button
                        onChange={handleCalendarRadios}
                        value="end-date"
                        checked={calendarRadio === "end-date"}>
                        Start and End Date
                    </Radio.Button>
                    {calendarRadio === "start-date" && (
                        <div className="ShortcutsTabModalDatePicker">
                            <DatePicker
                                value={!!dateMoment && dateMoment[0]}
                                onChange={(value) => handlePicker(true, value)}
                            />
                        </div>
                    )}
                    {calendarRadio === "end-date" && (
                        <div className="ShortcutsTabModalDatePicker">
                            <RangePicker
                                value={dateMoment}
                                onChange={(value) => handlePicker(false, value)}
                            />
                        </div>
                    )}
                </div>

                <div className="ModalRow">
                    <h4>Devices/Device Groups</h4>
                    <Select
                        showSearch
                        className={devicesOrGroupError ? "has-error" : ""}
                        style={{ width: "100%" }}
                        mode="multiple"
                        placeholder="Select one or more devices/groups"
                        optionFilterProp="children"
                        onChange={handleDeviceSelection}
                        value={devicesOrGroup}
                        allowClear>
                        <Option
                            value={-1}
                            key={"all"}>
                            All Devices
                        </Option>
                        <OptGroup label="Groups">
                            {devices &&
                                devices.length &&
                                devices
                                    .filter((item) => item.device_type === "group")
                                    .map((item) => (
                                        <Option
                                            value={item.device_id}
                                            key={item.device_id}>
                                            {item.description || item.device_id}
                                        </Option>
                                    ))}
                        </OptGroup>
                        <OptGroup label="Devices">
                            {devices &&
                                devices.length &&
                                devices
                                    .filter((item) => item.device_type === "device")
                                    .map((item) => (
                                        <Option
                                            key={item.device_id}
                                            value={item.device_id}>
                                            {item.description || item.device_id}
                                        </Option>
                                    ))}
                        </OptGroup>
                    </Select>
                    {devicesOrGroupError ? <h5 style={{ color: "red" }}>At least one device or device group need to be selected</h5> : null}
                </div>

                <div className="ModalRow">
                    <h4>Tags</h4>
                    <div className="ShortcutsTabModalTags">
                        <Collapse>
                            {renderTagPanels(
                                filteredSessionTags,
                                handleTagChange,
                                selectedTags.map((tag) => tag.toLowerCase()),
                            )}
                        </Collapse>
                    </div>
                </div>

                <div className="ModalRow">
                    <Checkbox
                        checked={shortcutPrivate}
                        onChange={(e) => setPrivate(e.target.checked)}>
                        Private
                    </Checkbox>
                    <Checkbox
                        checked={emailNotifications}
                        onChange={(e) => setEmailNotifications(e.target.checked)}>
                        Email notifications for new sessions
                    </Checkbox>
                </div>

                {elrError && (
                    <div className="ShortcutsTabModalLocationNotFound">
                        <p>Location not found.</p>
                    </div>
                )}
            </Modal>
            <div className="ShortcutsTab">
                <div className="ShortcutsTabHeader">
                    <div
                        className="CreateButton"
                        onClick={() => openModal()}
                        data-lpignore="true">
                        Create Shortcut
                    </div>
                    <OBCSearchInput
                        placeholder="Search for a shortcut"
                        value={searchString}
                        onChange={(value) => onSearchStringChanged(value)}
                    />
                </div>
                <div className="ShortcutsTabBody">
                    {loadingShortcutList ? (
                        <OBCSpinner
                            size={70}
                            speed={3}
                            color={"#e8dfff"}
                        />
                    ) : (
                        shortcutItems
                    )}
                </div>
            </div>
        </>
    );
};

export default ShortcutsTab;
