import React, { memo } from "react";
import { Marker, LayerGroup } from "react-leaflet";
import { mediaUploadIcon, mediaUploadIconActive } from "./Icons";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { filterMedia } from "components/util/PlaylistUtils";
import { LazyMapTooltip } from "components/util/LazyTooltips";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { setSelectedMediaUpload, setSelectedMediaUploadIndex, setPreveiwModalOpen } from "redux/actions";

const mediaTabFiltersSelector = (state) => state.mediaUploads.mediaTabFilters || [];
const mediaUploadListSelector = (state) => state.mediaUploads.mediaUploadsList || [];
const isSuperAdminSelector = (state) => state.userDetails.userConfig.super_admin || false;
const isAdminSelector = (state) => state.permissions.admin || false;
const selectedMediaUploadSelector = (state) => state.mediaUploads.selectedMediaUpload;

const MediaMarkers = memo(() => {
    const dispatch = useDispatch();
    const mediaTabFilters = useSelector(mediaTabFiltersSelector);
    const mediaUploadList = useSelector(mediaUploadListSelector);

    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isAdmin = useSelector(isAdminSelector);
    const selectedMediaUpload = useSelector(selectedMediaUploadSelector);
    const checkIfAdmin = isSuperAdmin || isAdmin;

    const filteredUploads = () => {
        const filteredMedia = filterMedia(
            mediaTabFilters.date,
            mediaTabFilters.owner,
            mediaTabFilters.searchQurey,
            mediaTabFilters.sortBy,
            checkIfAdmin,
            mediaTabFilters.status,
            mediaUploadList,
        );
        return filteredMedia.list;
    };

    const selectMediaUpload = (upload, index) => {
        dispatch(setPreveiwModalOpen(true));
        dispatch(setSelectedMediaUploadIndex(index));
        dispatch(setSelectedMediaUpload(upload.id));
    };

    const renderUploadMarker = (upload, index) => {
        return (
            <Marker
                onClick={() => selectMediaUpload(upload, index)}
                key={"MediaUpload" + upload.id}
                position={[upload.location_X, upload.location_Y]}
                weight={50}
                opacity={selectedMediaUpload !== -1 && selectedMediaUpload !== upload.id ? 0.5 : 1}
                icon={selectedMediaUpload === upload.id ? mediaUploadIconActive : mediaUploadIcon}
                zIndexOffset={selectedMediaUpload === upload.id ? 900 : 100}>
                <LazyMapTooltip
                    className="Incident-Tooltip"
                    permanent={false}
                    interactive={true}
                    direction={"top"}
                    offset={[0, -30]}>
                    <div className={"MarkerTooltipOuter"}>
                        <div className={"MarkerTooltipPrimaryInfo"}>
                            <span className={"MarkerTooltipTitle"}>{upload.name}</span>
                            <img
                                className={"MarkerTooltipImage"}
                                src={upload.media_urls[0].replace(".jpg", ".thumb.jpg")}
                                crossOrigin={"anonymous"}
                                alt=""
                            />
                        </div>
                    </div>
                </LazyMapTooltip>
            </Marker>
        );
    };

    return (
        <LayerGroup>
            <MarkerClusterGroup
                maxClusterRadius={32}
                disableClusteringAtZoom={18}
                showCoverageOnHover={false}
                spiderfyOnMaxZoom={true}
                animate={true}
                zoomToBoundsOnClick={true}>
                {filteredUploads().map(renderUploadMarker)}
            </MarkerClusterGroup>
        </LayerGroup>
    );
});

export default MediaMarkers;
