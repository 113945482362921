import DraggableWrapper from "../display/image/DraggableWrapper";
import React, { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPatrolLocation, setSelectedPatrolSession, setHoveredSessionLines } from "../../redux/actions/index";
import MiniPatrolSvg from "./MiniPatrolSvg";
import { convertToTimezone } from "../util/TimezoneUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { svgLineHasCoverage } from "../util/Geometry";
import _ from "lodash";
import OBCSpinner from "../util/OBC";
import ELRMileAndChain from "../util/ELRMileAndChain";

const selectedPatrolSessionSelector = (state) => state.schemaInterface.selectedSession;
const sessionSelector = (state) => state.schemaInterface.sessions;
const userConfigSelector = (state) => state.userDetails.userConfig;
const schemaPlanSelector = (state) => state.schemaInterface.plan;
const displayFormatSelector = (state) => state.userDetails.userConfig.elr_units;
const fetchingELRsSelector = (state) => state.schemaInterface.fetchingELRs;
const railDataLoadedSelector = (state) => state.schemaInterface.imagesLoaded;

const RunItem = ({ session, index }) => {
    const dispatch = useDispatch();

    const elemRef = useRef(null);

    const selectedPatrolSession = useSelector(selectedPatrolSessionSelector);
    const schemaPlan = useSelector(schemaPlanSelector);
    const sessions = useSelector(sessionSelector);
    const displayFormat = useSelector(displayFormatSelector);
    const userConfig = useSelector(userConfigSelector);

    const selected = index === selectedPatrolSession;

    const startTimestamp = session[0].timestamp;
    const date = new Date(startTimestamp);
    const niceTime = convertToTimezone(date, userConfig.convert_to_utc);

    const startPos = session[0].mwv;
    const endPos = session[session.length - 1].mwv;

    useEffect(() => {
        if (selected) {
            elemRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selected]);

    const linesCovered = useMemo(() => {
        let linesWithCoverage = schemaPlan.svg.line
            .filter((line) => {
                const covered = svgLineHasCoverage(line, session);
                return covered;
            })
            .map((line) => line.OBJ);
        return linesWithCoverage;
    }, [schemaPlan.svg.line, session]);

    const onHoverSession = (index) => {
        if (!_.isNil(index)) {
            dispatch(setHoveredSessionLines(linesCovered));
        } else {
            dispatch(setHoveredSessionLines([]));
        }
    };

    const onSessionClick = (index) => {
        const sessionSelected = sessions[index];

        const indexToNavTo = Math.min(2, sessionSelected.length);
        const startImage = sessionSelected[indexToNavTo];

        const locationObj = {
            elr: startImage.mwv.elr.toUpperCase(),
            chain: parseInt(startImage.mwv.mile) * 80 + parseFloat(startImage.mwv.yard) / 22,
            trackID: startImage.mwv.trid,
        };
        dispatch(setSelectedPatrolSession(index));
        dispatch(setPatrolLocation(locationObj));
    };

    const runTitleStart = useMemo(() => {
        const mwv = ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_mile_yards",
            {
                ELR: startPos.elr,
                MILE: startPos.mile,
                TRACK: startPos.trid,
                YARDS: startPos.yard,
            },
            [],
            true,
        );

        return <span>{mwv.to_string(displayFormat, [], true, true)}</span>;
    }, [displayFormat, startPos]);

    const runTitleEnd = useMemo(() => {
        const mwv = ELRMileAndChain.from_fields(
            "ELR Mile & Chain",
            "elr_mile_yards",
            {
                ELR: endPos.elr,
                MILE: endPos.mile,
                TRACK: endPos.trid,
                YARDS: endPos.yard,
            },
            [],
            true,
        );

        return <span>{mwv.to_string(displayFormat, [], true, true)}</span>;
    }, [displayFormat, endPos]);

    return (
        <div
            ref={elemRef}
            className={`SchemaRuns__Item ${selected ? "selected" : ""}`}
            onClick={() => onSessionClick(index)}
            onMouseOver={() => onHoverSession(index)}
            onMouseOut={() => onHoverSession(null)}>
            <MiniPatrolSvg
                sessionImages={session}
                planData={schemaPlan}
                selected={selected}
            />
            <div className="SchemaRuns__Item__Info">
                <div className="position">
                    <div className="inner">{runTitleStart}</div>

                    <FontAwesomeIcon
                        className="chevron"
                        icon={faArrowRight}
                    />

                    <div className="inner">{runTitleEnd}</div>
                </div>

                <div className="date">
                    <span>{niceTime}</span>
                </div>
            </div>
        </div>
    );
};

const SchemaRunsList = ({ setHoveredSession, planData }) => {
    const sessions = useSelector(sessionSelector);
    const fetchingELRs = useSelector(fetchingELRsSelector);
    const imagesLoaded = useSelector(railDataLoadedSelector);

    const initialised = useMemo(() => {
        return !fetchingELRs && imagesLoaded && sessions?.length;
    }, [fetchingELRs, imagesLoaded, sessions]);

    const content = useMemo(() => {
        if (!initialised) {
            return (
                <div className="SchemaRuns__Spinner">
                    <OBCSpinner
                        colorScheme="mono"
                        size={70}
                        speed={3}
                    />
                </div>
            );
        } else {
            return (
                <div className="SchemaRuns">
                    {sessions.map((session, index) => {
                        return (
                            <RunItem
                                session={session}
                                index={index}
                                setHoveredSession={setHoveredSession}
                            />
                        );
                    })}
                </div>
            );
        }
    }, [initialised, setHoveredSession, sessions]);

    return content;
};

export default SchemaRunsList;
