import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleLeft, faAngleRight, faFastBackward, faFastForward, faForward, faBackward, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getCCTVImagesByCameraID } from "redux/actions";
import OBCSpinner from "components/util/OBC";
import moment from "moment";
import _, { set } from "lodash";

const URL_PREFIX = "https://cimages.aivr.video/";

const CCTVImageModalJumpControls = ({ setActiveIndex, availableIndexes, activeIndex }) => {
    const [daysExistForward, setDaysExistForward] = useState(true);
    const [daysExistBackward, setDaysExistBackward] = useState(true);

    // This incredibly cumbersome useEffect checks to see if there is an available date in the past or future, and sets the appropriate button states. Open to suggestions for improving this.
    useEffect(() => {
        const hoursRemainingForward = [0];
        const hoursRemainingBackward = [0];

        for (let i = activeIndex - 1; i >= 0; i--) {
            if (i === 0) {
                hoursRemainingForward.push(0);
            } else {
                const activeIndexDate = moment(new Date(availableIndexes[activeIndex].timestamp * 1000));
                const nextIndexDate = moment(new Date(availableIndexes[i].timestamp * 1000));
                const dateDiff = nextIndexDate.diff(activeIndexDate, "hours");
                hoursRemainingForward.push(dateDiff);
            }
        }

        for (let i = activeIndex + 1; i <= availableIndexes.length - 1; i++) {
            if (i <= availableIndexes.length - 1) {
                const activeIndexDate = moment(new Date(availableIndexes[activeIndex].timestamp * 1000));
                const nextIndexDate = moment(new Date(availableIndexes[i].timestamp * 1000));
                const dateDiff = activeIndexDate.diff(nextIndexDate, "hours");
                hoursRemainingBackward.push(dateDiff);
            }
        }

        if (_.max(hoursRemainingForward) < 24) {
            setDaysExistForward(false);
        } else {
            setDaysExistForward(true);
        }
        if (_.max(hoursRemainingBackward) >= 24) {
            setDaysExistBackward(true);
        } else {
            setDaysExistBackward(false);
        }
    }, [activeIndex, availableIndexes]);

    const handleOneDayBack = () => {
        let newActiveIndex;
        for (let i = 0 + activeIndex; i < availableIndexes.length; i++) {
            const thumbnail = availableIndexes[i];
            const activeIndexDate = moment(new Date(availableIndexes[activeIndex].timestamp * 1000).setHours(0, 0, 0, 0));
            const thumbnailDate = moment(new Date(thumbnail.timestamp * 1000).setHours(0, 0, 0, 0));
            const dateDiff = activeIndexDate.diff(thumbnailDate, "days", false);
            if (dateDiff >= 1) {
                newActiveIndex = _.findIndex(availableIndexes, (thumbnail) => {
                    return thumbnail.id === availableIndexes[i].id;
                });
                setActiveIndex(newActiveIndex);
                break;
            }
        }
    };

    const handleOneDayForward = () => {
        let newActiveIndex;
        for (let i = activeIndex - 1; i >= 0; i--) {
            const thumbnail = availableIndexes[i];
            const activeIndexDate = moment(new Date(availableIndexes[activeIndex].timestamp * 1000));
            const thumbnailDate = moment(new Date(thumbnail.timestamp * 1000));
            const dateDiff = thumbnailDate.diff(activeIndexDate, "hours");
            if (dateDiff >= 24) {
                newActiveIndex = _.findIndex(availableIndexes, (thumbnail) => {
                    return thumbnail.id === availableIndexes[i].id;
                });
                setActiveIndex(newActiveIndex);
                break;
            }
        }
    };

    return (
        <div className="JumpControlsContainer">
            <button disabled>
                <FontAwesomeIcon icon={faFastBackward} />
                one month
            </button>
            <button
                disabled={!daysExistBackward ? true : false}
                onClick={handleOneDayBack}>
                <FontAwesomeIcon icon={faBackward} />
                one day
            </button>
            <button
                disabled={!daysExistForward ? true : false}
                onClick={handleOneDayForward}>
                one day
                <FontAwesomeIcon icon={faForward} />
            </button>
            <button disabled>
                one month
                <FontAwesomeIcon icon={faFastForward} />
            </button>
        </div>
    );
};

const CCTVImageModal = ({ visible, setVisible, cameraInfo, origin, cctvIndex }) => {
    const [cameraDataLoading, setCameraDataLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(cctvIndex);
    const [loadedImageIndexes, setLoadedImageIndexes] = useState([]);
    const [imageCopiedToClipboard, setImageCopiedToClipboard] = useState(false);
    let userIsOnFirefox = false;

    const dispatch = useDispatch();
    const cameraImages = useSelector((state) => state.cctv.CCTVImages.images);
    const csrfToken = useSelector((state) => state.csrfToken);

    if (typeof window !== "undefined") {
        userIsOnFirefox = navigator.userAgent.includes("Firefox");
    }

    const handleKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === "Escape") {
            closeModal();
        } else if (e.key === "ArrowLeft") {
            setPreviousImage();
        } else if (e.key === "ArrowRight") {
            setNextImage();
        }
    };

    useEffect(() => {
        if (imageCopiedToClipboard) {
            setTimeout(() => {
                setImageCopiedToClipboard(false);
            }, 2000);
        }
    }, [imageCopiedToClipboard]);

    useEffect(() => {
        let targets = document.querySelectorAll(".CCTVThumbnailContainer");
        let options = {
            root: document.querySelector(".CCTVImageControlsContainer"),
            rootMargin: "0px",
            threshold: 0.2,
        };

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.getAttribute("data-observed") === "0") {
                    const observedIndex = entry.target.getAttribute("data-index");
                    const image = entry.target.querySelector("img");
                    image.setAttribute("src", URL_PREFIX + cameraImages[observedIndex].path + "?csrf=" + csrfToken);
                    entry.target.setAttribute("data-observed", "1");
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (targets.length) {
            targets.forEach((target) => observer.observe(target));
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    });

    useEffect(() => {
        if (visible) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    useEffect(() => {
        if (visible && cameraInfo) {
            setLoadedImageIndexes([]);
            setActiveIndex(0);
            setCameraDataLoading(true);
            dispatch(getCCTVImagesByCameraID(setCameraDataLoading, cameraInfo.remote_id));
        }
    }, [dispatch, cameraInfo, visible]);

    useEffect(() => {
        const activeThumbnail = document.querySelector(".CCTVThumbnailContainer.active");
        if (activeThumbnail) {
            activeThumbnail.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [activeIndex]);

    if (!visible) {
        return null;
    }

    const closeModal = () => {
        setLoadedImageIndexes([]);
        setVisible(false);
        setActiveIndex(0);
        dispatch({ type: "CCTV_IMAGES", cctvImages: [] });
        setCameraDataLoading(true);
    };

    const setPreviousImage = () => {
        if (activeIndex < cameraImages.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const setNextImage = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const calculateHoursAgo = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const thumbnailAge = moment(date).fromNow();

        return thumbnailAge;
    };

    const copyMostRecentImageToClipBoard = () => {
        navigator.clipboard
            .write([
                new window.ClipboardItem({
                    "image/png": fetch(URL_PREFIX + cameraImages[activeIndex].path)
                        .then((response) => response.blob())
                        .then((blob) => new Blob([blob], { type: "image/png" })),
                }),
            ])
            .then(() => {
                console.log("Image copied to clipboard");
                setImageCopiedToClipboard(true);
            })
            .catch((error) => {
                console.error("Could not copy image: ", error);
            });
    };

    if (cameraDataLoading) {
        return createPortal(
            <div className={origin && origin === "navbar" ? "CCTVNavbarModalOverlay" : "CCTVModalOverlay"}>
                <div className="CCTVModalContainer">
                    <OBCSpinner
                        size={60}
                        speed={3}
                    />
                </div>
            </div>,
            document.body,
        );
    } else if (!cameraImages) {
        return createPortal(
            <div className={origin && origin === "navbar" ? "CCTVNavbarModalOverlay" : "CCTVModalOverlay"}>
                <div className="CCTVModalContainer">
                    <div className="CCTVModalHeader">
                        <h1>ERROR LOADING CCTV IMAGES</h1>
                        <div className="HeaderGroup">
                            <button onClick={copyMostRecentImageToClipBoard}>
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button onClick={closeModal}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="CCTVModalCloseIcon"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="CCTVImageError">ERROR LOADING CCTV IMAGES</div>
                </div>
            </div>,
            document.body,
        );
    } else {
        return createPortal(
            <div className={origin && origin === "navbar" ? "CCTVNavbarModalOverlay" : "CCTVModalOverlay"}>
                <div className="CCTVModalContainer">
                    <div className="CCTVModalHeader">
                        <h1>{cameraInfo.name}</h1>
                        <div className="HeaderGroup">
                            {!userIsOnFirefox ? (
                                <>
                                    <div className={imageCopiedToClipboard ? "CopyToClipboardMessage active" : "CopyToClipboardMessage"}>
                                        Copied image to clipboard
                                    </div>
                                    <button onClick={copyMostRecentImageToClipBoard}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                </>
                            ) : null}
                            <button onClick={closeModal}>
                                {
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="CCTVModalCloseIcon"
                                    />
                                }
                            </button>
                        </div>
                    </div>
                    <div className="CCTVModalBody">
                        <div
                            className={activeIndex === cameraImages.length - 1 ? "ScrollLeftButton" : "ScrollLeftButton active"}
                            onClick={setPreviousImage}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                        {cameraImages.map((image, index) => {
                            const translateXValue = 100 * activeIndex;
                            return (
                                <img
                                    src={loadedImageIndexes.includes(index) ? URL_PREFIX + image.path + "?csrf=" + csrfToken : ""}
                                    alt="CCTV"
                                    className="CCTVModalHeroImage"
                                    style={{ transform: `translateX(+${translateXValue}%)` }}
                                    key={image.id}
                                />
                            );
                        })}
                        <div
                            className={activeIndex === 0 ? "ScrollRightButton" : "ScrollRightButton active"}
                            onClick={setNextImage}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    </div>
                    <CCTVImageModalJumpControls
                        setActiveIndex={setActiveIndex}
                        availableIndexes={cameraImages}
                        activeIndex={activeIndex}
                    />
                    <div className="CCTVImageControlsContainer">
                        <div className="CCTVModalImageControls">
                            {cameraImages.map((image, index) => {
                                const date = calculateHoursAgo(image.timestamp);
                                return (
                                    <div
                                        className={index === activeIndex ? "CCTVThumbnailContainer active" : "CCTVThumbnailContainer"}
                                        key={index}
                                        data-index={index}
                                        data-observed="0">
                                        <div className="CCTVThumbnailHeader">{date}</div>
                                        <img
                                            src=""
                                            alt="CCTV"
                                            className={
                                                index === activeIndex && loadedImageIndexes.includes(index)
                                                    ? "CCTVModalThumbnailImage active loaded"
                                                    : loadedImageIndexes.includes(index)
                                                      ? "CCTVModalThumbnailImage loaded"
                                                      : "CCTVModalThumbnailImage"
                                            }
                                            onClick={() => {
                                                setActiveIndex(index);
                                            }}
                                            onLoad={() => {
                                                setLoadedImageIndexes([...loadedImageIndexes, index]);
                                            }}
                                        />
                                        <div className={loadedImageIndexes.includes(index) ? "CCTVThumbnailLoader" : "CCTVThumbnailLoader active"}>
                                            <OBCSpinner
                                                size={30}
                                                speed={3}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>,
            document.body,
        );
    }
};

export default CCTVImageModal;
