import React from "react";
import "../../mobile.scss";
import { connect } from "react-redux";
import EquirectangularImage from "../display/image/EquirectangularImage";
import OBCSpinner from "../util/OBC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faFastForward, faStepBackward, faStepForward, faPlayCircle, faPauseCircle } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { Select } from "antd";
import { requestPlaylistPosition } from "../../redux/actions/index";

const { Option } = Select;

class MobileImagePlayer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            controlsVisible: false,
            isSelected: 0,
        };
    }

    componentWillMount = () => {
        this.clickTimeout = null;
    };

    handleClick = (e, clickFunction, fast, hideControls) => {
        if (clickFunction && hideControls) {
            e.stopPropagation();
            clickFunction();
            this.setState({ controlsVisible: !this.state.controlsVisible });
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        } else if (!clickFunction && hideControls) {
            this.setState({ controlsVisible: !this.state.controlsVisible });
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        } else {
            e.stopPropagation();
            if (fast) {
                clickFunction("fast");
            } else {
                clickFunction();
            }
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }

        this.clickTimeout = setTimeout(() => {
            this.setState({ controlsVisible: false });
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }, 2000);
    };

    renderSourcesDropdown = () => {
        if (!this.props.playlist || !this.props.playlist.video || !this.props.playlist.video.length) {
            return null;
        }

        const menu = (
            <Select
                value={this.props.sourceIndex === 0 ? "Camera 1" : "Camera 2"}
                onChange={this.selectSource}>
                {_.map(this.props.playlist.video, (source, source_index) => (
                    <Option
                        key={"Camera " + source_index}
                        value={source_index}
                        style={{ backgroundColor: this.state.isSelected === source_index ? "#e6f7ff" : "transparent" }}>
                        {"Camera " + (source_index + 1)}
                    </Option>
                ))}
            </Select>
        );

        return <div className="imagePlayer__SourceSelect">{menu}</div>;
    };

    selectSource = (sourceIndex) => {
        let newIndex = 0;
        let newOffset = 0;

        this.setState({
            isSelected: sourceIndex,
        });

        console.log("New stream selected: ", sourceIndex);
        this.props.dispatch(requestPlaylistPosition(true, false, true, sourceIndex, newIndex, newOffset));
    };

    render() {
        let image = null;
        if (this.props.loadedImage) {
            if (this.props.imageIsEquirectangular) {
                image = (
                    <EquirectangularImage
                        key={"eqImageDisplay"}
                        src={this.props.loadedImage}
                    />
                );
            } else {
                image = (
                    <div className="mobileImage">
                        <img
                            key={"imageDisplay"}
                            src={this.props.loadedImage}
                            alt="Mobile Player"
                            crossOrigin={"anonymous"}
                        />
                        <div
                            className={`imagePlayer__Controls ${this.state.controlsVisible ? "active" : ""}`}
                            onClick={(e) => {
                                this.handleClick(e, null, null, true);
                            }}>
                            <div className="imagePlayer__Controls__Section">
                                <div className="imagePlayer__Controls__Item">
                                    <FontAwesomeIcon
                                        icon={faFastBackward}
                                        onClick={(e) => {
                                            this.handleClick(e, this.props.goToPrevFrame, true, false);
                                        }}
                                    />
                                    <div className="imagePlayer__Controls__Item__Label">-5</div>
                                </div>
                                <div className="imagePlayer__Controls__Item">
                                    <FontAwesomeIcon
                                        icon={faStepBackward}
                                        onClick={(e) => {
                                            this.handleClick(e, this.props.goToPrevFrame, null, false);
                                        }}
                                    />
                                    <div className="imagePlayer__Controls__Item__Label">-1</div>
                                </div>
                            </div>
                            <div className="imagePlayer__Controls__Section wider">
                                <div className="imagePlayer__Controls__Item">
                                    {this.props.playing ? (
                                        <FontAwesomeIcon
                                            icon={faPauseCircle}
                                            onClick={(e) => {
                                                this.handleClick(e, this.props.togglePlaying, null, true);
                                            }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faPlayCircle}
                                            onClick={(e) => {
                                                this.handleClick(e, this.props.togglePlaying, null, true);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="imagePlayer__Controls__Section">
                                <div className="imagePlayer__Controls__Item">
                                    <FontAwesomeIcon
                                        icon={faStepForward}
                                        onClick={(e) => {
                                            this.handleClick(e, this.props.goToNextFrame, null, false);
                                        }}
                                    />
                                    <div className="imagePlayer__Controls__Item__Label">+1</div>
                                </div>
                                <div className="imagePlayer__Controls__Item">
                                    <FontAwesomeIcon
                                        icon={faFastForward}
                                        onClick={(e) => {
                                            this.handleClick(e, this.props.goToNextFrame, true, false);
                                        }}
                                    />
                                    <div className="imagePlayer__Controls__Item__Label">+5</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <>
                {this.props.playlist.video && this.props.playlist.video.length > 1 ? this.renderSourcesDropdown() : null}
                <div className="imagePlayer">
                    {this.props.loading ? (
                        <div className="Image-Loading-Overlay">
                            <OBCSpinner colorScheme={"mono"} />
                        </div>
                    ) : null}
                    {this.props.loadedImage && !this.props.playing && false ? (
                        <FontAwesomeIcon
                            icon={faPlayCircle}
                            onClick={this.props.togglePlaying}
                            style={{
                                color: "#E8DFFF",
                                fontSize: "60px",
                                position: "absolute",
                                top: "calc(50% - 30px)",
                                left: "calc(50% - 30px)",
                                opacity: 0.6,
                            }}
                        />
                    ) : null}
                    {image}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ playlist }) => {
    return {
        playlist: playlist.data,
        sourceIndex: playlist.position.sourceIndex,
    };
};

export default connect(mapStateToProps)(MobileImagePlayer);
