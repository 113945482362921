import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Popover } from "antd";
import "../../../style/driverTraining.scss";
import WelcomePage from "./components/WelcomePage";
import TrainingSessionsMainPage from "./components/TrainingSessionsMainPage";
import DtTrainingSessionPlayer from "./components/DtTrainingSessionPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AIVRlogo from "../../../images/new-branding-2.0/aivr-hybrid.svg";
import { faSignOutAlt, faSignInAlt } from "@fortawesome/pro-light-svg-icons";
import { logout } from "redux/actions";

import { faUser, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { changeDashboard } from "../../../redux/actions/userActions";

const { Header } = Layout;
const userConfigSelector = (state) => state.userDetails.userConfig;
const currentPageSelector = (state) => state.driverTraining.trainee.currentPage;

const DriverTrainingLayout = () => {
    const dispatch = useDispatch();
    const userConfig = useSelector(userConfigSelector);
    const currentPage = useSelector(currentPageSelector);

    // const [currentPage, setCurrentPage] = useState('training_session')
    // const [selectedTrainingSession, setSelectedTrainingSession] = useState(127917);
    // const [currentPage, setCurrentPage] = useState('welcome')
    const [selectedTrainingSession, setSelectedTrainingSession] = useState(0);
    const [userPopoverVisible, setUserPopoverVisible] = useState(false);
    const [selectedShortcut, setSelectedShortcut] = useState({
        id: 0,
        title: "",
    });

    const logoutUser = () => {
        dispatch(logout());
    };

    const switchDashboard = () => {
        dispatch(changeDashboard());
    };
    const content = (
        <div className="UserPopoverContainer">
            <div
                className="UserPopoverButton"
                onClick={switchDashboard}>
                <FontAwesomeIcon
                    className="UserPopoverButtonIcon"
                    icon={faSignInAlt}
                />
                <div className={"UserPopoverButtonText"}>Switch Workspace</div>
            </div>
            <div
                className="UserPopoverButton"
                onClick={logoutUser}>
                <FontAwesomeIcon
                    className="UserPopoverButtonIcon"
                    icon={faSignOutAlt}
                />
                <div className={"UserPopoverButtonText"}>Logout</div>
            </div>
        </div>
    );

    return (
        <div className="App">
            <Layout className="DriverTrainingMainContent">
                <Header className="MainContentHeader">
                    <div className="HeaderLogoContainer">
                        <img
                            src={AIVRlogo}
                            alt="Customer logo"
                            crossOrigin={"anonymous"}
                        />
                        <p className="LogoLabel">Driver Training</p>
                    </div>
                    {currentPage !== "welcome" && (
                        <Popover
                            title={`Hi, ${userConfig.name ?? ""}`}
                            content={content}
                            placement="bottomRight"
                            trigger="click"
                            visible={userPopoverVisible}
                            onVisibleChange={() => setUserPopoverVisible(!userPopoverVisible)}
                            align={{ offset: [0, -10] }}>
                            <div className={`HeaderUserButton ${userPopoverVisible && "active"}`}>
                                <FontAwesomeIcon
                                    icon={faUserGraduate}
                                    size="lg"
                                />
                                <span>{userConfig.name ? userConfig.name.split(" ")[0] : ""}</span>
                            </div>
                        </Popover>
                    )}
                </Header>
                {currentPage === "welcome" && (
                    <WelcomePage
                        userName={userConfig.name}
                        trainingProgress={50}
                        traingScore={10}
                        trainingSessionsCount={20}
                        // setCurrentPage2={setCurrentPage}
                    />
                )}
                {currentPage === "sessions_main" && (
                    <TrainingSessionsMainPage
                        selectedShortcut={selectedShortcut}
                        setSelectedShortcut={setSelectedShortcut}
                        // setCurrentPage={setCurrentPage}
                        setSelectedTrainingSession={setSelectedTrainingSession}
                    />
                )}
                {currentPage === "training_session" && (
                    <DtTrainingSessionPlayer
                        selectedTrainingSession={selectedTrainingSession}
                        // setCurrentPage={setCurrentPage}
                    />
                )}
            </Layout>
        </div>
    );
};

export default DriverTrainingLayout;
