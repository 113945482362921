import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as echarts from "echarts";
import { nearbyData, elrBoundaries, exceedences, lineSpeeds, comparisonData } from "./ChartV2DataFilter";
import Measure from "react-measure";
import useOverview from "./ChartV2/overview";
import useELRBoundaries from "./ChartV2/elrBoundaries";
import useDataSeries from "./ChartV2/series";
import { asyncLoadGeometryFile, boundedRange, boundedValue, downloadTrackGeometryHeaders, geometryDataSelector, useCombinedOptions } from "./ChartV2/utils";
import { useStore, useDispatch, useSelector } from "react-redux";
import { binarySearch, boundedBinarySearch } from "../util/PlaylistUtils";
import useLineSpeeds from "./ChartV2/lineSpeeds";
import _ from "lodash";

echarts.registerTransform(nearbyData);
echarts.registerTransform(comparisonData);
echarts.registerTransform(elrBoundaries);
echarts.registerTransform(lineSpeeds);
echarts.registerTransform(exceedences);

const deviceConfigSelector = (state) => {
    return state.railInspection.railInspectionImageConfig;
};

const sessionDataSelector = (state) => {
    const dashboardData = _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
    return _.get(state.sessions, [dashboardData.routeID]);
};

const ChartV2 = ({
    selectedTimestamp,
    dataTypes,
    colorScheme,
    exceedenceSpeed,
    elrData,
    poiData,
    lastUpdate,
    onSelectTimestamp,
    dataFiles,
    comparisonSession,
    comparisonOffset,
    verticalComparisonOffset,
}) => {
    const store = useStore();
    const dispatch = useDispatch();

    const geometryData = useSelector(geometryDataSelector);
    const sessionData = useSelector(sessionDataSelector);

    const [chart, setChart] = useState(null);
    const [zoomBounds, setZoomBounds] = useState([0, 8200]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [lastChartOptions, setLastChartOptions] = useState({});

    const chartRef = useRef();
    const maxBoundsRef = useRef(0);
    const recentZoomBoundsRef = useRef(null);

    const [comparisonSessionHeaders, setComparisonSessionHeaders] = useState([]);
    const [loadedComparisonSession, setLoadedComparisonSession] = useState(null);

    const railInspectionConfig = useSelector(deviceConfigSelector);

    const indexOffset = useMemo(() => {
        return _.get(railInspectionConfig, ["alignment_offsets", "geometry_index_offset"], 0);
    }, [railInspectionConfig]);

    const imageryIsBackward = useMemo(() => {
        if (!sessionData) {
            return false;
        }
        const cameraAtRear = _.get(railInspectionConfig, ["video_orientation"]) === "Rear";
        const sessionIsBackward = _.indexOf(sessionData.tags, "Backward") !== -1;

        return sessionIsBackward !== cameraAtRear;
    }, [railInspectionConfig, sessionData]);

    useEffect(() => {
        if (dataFiles) {
            const dataForTimestamp = dataFiles.find((_f) => _f.endTS >= selectedTimestamp && _f.startTS <= selectedTimestamp);
            if (dataForTimestamp) {
                asyncLoadGeometryFile(dataForTimestamp, store, dispatch).then((allData) => {
                    const lowIndex = dataForTimestamp.start;
                    const highIndex = dataForTimestamp.end;
                    const nearestIndex = boundedBinarySearch(selectedTimestamp, allData, lowIndex, highIndex, (geom) => geom.timestamp);
                    const offsettedIndex = !imageryIsBackward
                        ? Math.max(0, nearestIndex - indexOffset)
                        : Math.min(allData.length - 1, nearestIndex + indexOffset);
                    setSelectedIndex(offsettedIndex);
                });
            }
        } else {
            setSelectedIndex(binarySearch(selectedTimestamp, geometryData, (geom) => geom.timestamp));
        }
    }, [selectedTimestamp, geometryData, dataFiles, store, dispatch, railInspectionConfig, indexOffset, imageryIsBackward]);

    useEffect(() => {
        if (comparisonSession !== loadedComparisonSession) {
            console.log("Comparison session is not yet loaded:", comparisonSession);
            setComparisonSessionHeaders([]);
            if (comparisonSession) {
                downloadTrackGeometryHeaders(comparisonSession, store, dispatch).then((headers) => {
                    let offset = 0;
                    console.log("Track geometry headers for comparison session have been loaded:", comparisonSession);
                    const comparison_headers = headers
                        .map((d) => {
                            const mile_sign = d.mile_and_eighth < 0 ? -1 : 1;
                            const mile = Math.floor(Math.abs(d.mile_and_eighth));
                            const chain = (Math.abs(d.mile_and_eighth) - mile) * 100;
                            const position = Math.round(mile_sign * (mile * 80 + chain));
                            const start_offset = offset;
                            offset += d.record_count;
                            const end_offset = offset - 1;
                            return {
                                elr: d.elr === null || d.elr === "unk" ? "?" : d.elr,
                                track: d.track_id === null || d.track_id === "unk" ? "?" : d.track_id,
                                start: start_offset,
                                end: end_offset,
                                position,
                                filename: d.filename,
                                record_count: d.record_count,
                            };
                        })
                        .filter(
                            (header) => dataFiles.filter((f) => f.elr === header.elr && f.track === header.track && f.position === header.position).length > 0,
                        );
                    setComparisonSessionHeaders(comparison_headers);
                    setLoadedComparisonSession(comparisonSession);
                });
            } else {
            }
        }
    }, [comparisonSession, loadedComparisonSession, dispatch, store, dataFiles]);

    const palette = useMemo(() => {
        return {
            darkMode: colorScheme === "dark",
            elrBoundary: colorScheme === "dark" ? "#caf" : "#60c",
            xZero: colorScheme === "dark" ? "#aaa" : "#666",
            yZero: colorScheme === "dark" ? "#caf" : "#60c",
            selected: colorScheme === "dark" ? "#f44" : "#c00",
            div: colorScheme === "dark" ? "#666" : "#aaa",
            bg: colorScheme === "dark" ? "#1C1E30" : "#fff",
            line: colorScheme === "dark" ? "#fff" : "#000",
            comparisonLine: colorScheme === "dark" ? "#9191ff" : "#3838ff",
            lineHighlight: colorScheme === "dark" ? "#8f8" : "#070",
            lineSelect: colorScheme === "dark" ? "#0f0" : "#0c0",
            lineWidth: colorScheme === "dark" ? 2 : 1,
            label: colorScheme === "dark" ? "#fff" : "#000",
            poiLabel: colorScheme === "dark" ? "#fff" : "#000",
            overviewLabel: colorScheme === "dark" ? "#000" : "#fff",
            overviewBorder: colorScheme === "dark" ? "#000" : "#fff",
            speedMinR: colorScheme === "dark" ? 230 : 150,
            speedMinG: colorScheme === "dark" ? 255 : 200,
            speedMinB: colorScheme === "dark" ? 190 : 100,
            speedMaxR: colorScheme === "dark" ? 100 : 80,
            speedMaxG: colorScheme === "dark" ? 60 : 0,
            speedMaxB: colorScheme === "dark" ? 155 : 120,
            exceedence: colorScheme === "dark" ? "#f88" : "#f00",
            currentViewFill: "rgba(220,192,255,0.5)",
            currentViewBorder: "rgba(220,192,255,0.9)",
        };
    }, [colorScheme]);

    useEffect(() => {
        const new_chart = echarts.init(chartRef.current);
        setChart(new_chart);
        return () => {
            new_chart.dispose();
        };
    }, []);

    const viewBounds = useCallback(
        (bounds) => {
            if (chart) {
                const options = chart.getOption();
                const xAxes = options.xAxis;
                const newAxisBounds = xAxes
                    .filter((axis) => axis.id !== "overview_x")
                    .map((axis) => ({
                        id: axis.id,
                        min: bounds[0],
                        max: bounds[1],
                    }));

                const minHighlight = boundedValue(0, bounds[0], maxBoundsRef.current);
                const maxHighlight = boundedValue(0, bounds[1], maxBoundsRef.current);
                chart.setOption({
                    xAxis: newAxisBounds,
                    series: [
                        {
                            id: "current_view_series",
                            data: [[minHighlight, maxHighlight]],
                        },
                    ],
                });
            }
        },
        [chart],
    );

    const selectIndex = useCallback(
        (index) => {
            if (index !== undefined) {
                console.log("Selecting index: ", index);

                const offsettedIndex = imageryIsBackward ? Math.max(0, index - indexOffset) : index + indexOffset;
                setSelectedIndex(offsettedIndex);

                if (dataFiles) {
                    const dataForIndex = dataFiles.find((_f) => _f.end >= offsettedIndex && _f.start <= offsettedIndex);
                    if (dataForIndex) {
                        asyncLoadGeometryFile(dataForIndex, store, dispatch).then((allData) => {
                            onSelectTimestamp(allData[offsettedIndex].timestamp);
                        });
                    }
                } else {
                    if (0 <= offsettedIndex && offsettedIndex <= maxBoundsRef.current) {
                        onSelectTimestamp(geometryData[offsettedIndex].timestamp);
                    }
                }
            }
        },
        [imageryIsBackward, indexOffset, dataFiles, store, dispatch, onSelectTimestamp, geometryData],
    );

    const selectBounds = useCallback(
        (bounds) => {
            console.log("Selecting bounds: ", bounds);
            setZoomBounds(bounds);
            viewBounds(bounds);
        },
        [viewBounds],
    );

    useEffect(() => {
        if (elrData.length) {
            const lastELRDataLength = maxBoundsRef.current;
            maxBoundsRef.current = elrData[elrData.length - 1].end;
            if (lastELRDataLength === 0 && maxBoundsRef.current > 0) {
                selectBounds([0, 8200]);
            }
        } else {
            maxBoundsRef.current = 0;
        }
    }, [elrData, selectBounds]);

    const chartInfo = useMemo(
        () => ({
            chart,
            palette,
            elrData,
            poiData,
            zoomBounds,
            maxBoundsRef,
            viewBounds,
            selectBounds,
            selectIndex,
            comparisonSessionHeaders,
            usingDataFiles: !!(dataFiles && dataFiles.length),
            dataTypes,
            exceedenceSpeed,
            comparisonOffset,
            verticalComparisonOffset,
        }),
        [
            chart,
            palette,
            elrData,
            poiData,
            zoomBounds,
            maxBoundsRef,
            viewBounds,
            selectBounds,
            selectIndex,
            dataFiles,
            dataTypes,
            exceedenceSpeed,
            comparisonSessionHeaders,
            comparisonOffset,
            verticalComparisonOffset,
        ],
    );

    const overviewOptions = useOverview(chartInfo);
    const seriesOptions = useDataSeries(chartInfo);
    const elrBoundaryOptions = useELRBoundaries(chartInfo);
    const lineSpeedOptions = useLineSpeeds(chartInfo);
    const colourOptions = useMemo(
        () => ({
            backgroundColor: [palette.bg],
            darkMode: [palette.darkMode],
            axisPointer: [
                {
                    link: [
                        {
                            xAxisId: ["overview_x", "elr_boundary_x"],
                        },
                    ],
                },
            ],
        }),
        [palette],
    );

    const [chartOptions, changedOptions] = useCombinedOptions([overviewOptions, seriesOptions, elrBoundaryOptions, lineSpeedOptions, colourOptions]);

    useEffect(() => {
        if (chart) {
            let clearBeforeSet = false;

            if (!Object.is(chartOptions.grid, lastChartOptions.grid)) {
                clearBeforeSet = true;
            }

            if (clearBeforeSet) {
                chart.setOption({}, { notMerge: true });

                const minHighlight = boundedValue(0, zoomBounds[0], maxBoundsRef.current);
                const maxHighlight = boundedValue(0, zoomBounds[1], maxBoundsRef.current);

                chartOptions.series.find((series) => series.id === "current_view_series").data = [[minHighlight, maxHighlight]];
                chartOptions.series.find((series) => series.id === "selected_index_main_series").data = [[selectedIndex]];
                chartOptions.series.find((series) => series.id === "selected_index_overview_series").data = [[selectedIndex]];

                chart.setOption(chartOptions);
            } else {
                chart.setOption(changedOptions, { lazyUpdate: true });
            }

            setLastChartOptions(chartOptions);
        }
    }, [chart, chartOptions]);

    useEffect(() => {
        if (chart) {
            recentZoomBoundsRef.current = zoomBounds;
            if (dataFiles) {
                const visibleData = dataFiles.filter((_f) => _f.end >= zoomBounds[0] && _f.start <= zoomBounds[1]);
                visibleData.forEach((data) => asyncLoadGeometryFile(data, store, dispatch));
            }
        }
    }, [zoomBounds, dataFiles, store, dispatch, chart]);

    useEffect(() => {
        if (chart) {
            chart.setOption({
                series: [
                    {
                        id: "selected_index_main_series",
                        data: [[selectedIndex]],
                    },
                    {
                        id: "selected_index_overview_series",
                        data: [[selectedIndex]],
                    },
                ],
            });

            if (selectedIndex < recentZoomBoundsRef.current[0] || selectedIndex > recentZoomBoundsRef.current[1]) {
                const current_delta = (recentZoomBoundsRef.current[1] - recentZoomBoundsRef.current[0]) / 2;
                const newBounds = boundedRange(0, Math.floor(selectedIndex - current_delta), Math.floor(selectedIndex + current_delta), maxBoundsRef.current);

                console.log("Setting zoom bounds based on selected index: ", newBounds, selectedIndex);
                selectBounds(newBounds);
            }
        }
    }, [chart, selectedIndex, selectBounds]);

    useEffect(() => {
        if (chart) {
            chart.setOption({
                dataset: [
                    {
                        id: "nearby_dataset",
                        source: [lastUpdate],
                    },
                ],
            });
        }
    }, [lastUpdate, chart]);

    const onResize = useCallback(() => {
        if (chart) {
            chart.resize();
        }
    }, [chart]);

    return (
        <Measure
            bounds
            onResize={onResize}>
            {({ measureRef }) => (
                <div
                    className="widget__Chart__Inner"
                    ref={measureRef}>
                    <div
                        className="widget__Chart"
                        ref={chartRef}
                    />
                </div>
            )}
        </Measure>
    );
};

export default ChartV2;
