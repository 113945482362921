import { Select, Modal } from "antd";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { getRouteCoordinateSystems, updateUserConfig } from "../redux/actions/index";
import RouteCoordinateSystems from "./util/RouteCoordinateSystems";

const { Option } = Select;

class UnitSelectionModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasChanged: false,
            userConfig: {},
        };
    }

    componentDidMount = () => {
        this.setState({
            userConfig: this.props.userConfig,
        });

        if (!this.props.workspaceCoordinateSystemNames.length) {
            this.props.dispatch(getRouteCoordinateSystems());
        }
    };

    changeUnitSystem = () => {
        let fieldsToUpdate = {};

        if (this.state.userConfig.elr_units !== this.props.userConfig.elr_units) {
            fieldsToUpdate.elrUnitSetting = this.state.userConfig.elr_units;
        }
        if (this.state.userConfig.measurement_units !== this.props.userConfig.measurement_units) {
            fieldsToUpdate.measurementUnitSetting = this.state.userConfig.measurement_units;
        }
        if (this.state.userConfig.sighting_units !== this.props.userConfig.sighting_units) {
            fieldsToUpdate.sightingUnitSetting = this.state.userConfig.sighting_units;
        }

        if (this.state.userConfig.speed_units !== this.props.userConfig.speed_units) {
            fieldsToUpdate.speedUnitSetting = this.state.userConfig.speed_units;
        }

        if (this.state.userConfig.temperature_units !== this.props.userConfig.temperature_units) {
            fieldsToUpdate.temperatureUnitSetting = this.state.userConfig.temperature_units;
        }

        this.props.dispatch(updateUserConfig(fieldsToUpdate));
    };

    handleELRChange = (value) => {
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                elr_units: value,
            },
        });
    };

    handleMeasurementChange = (value) => {
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                measurement_units: value,
            },
        });
    };

    handleSightingChange = (value) => {
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                sighting_units: value,
            },
        });
    };

    handleSpeedChange = (value) => {
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                speed_units: value,
            },
        });
    };

    handleTempUnitChange = (value) => {
        this.setState({
            userConfig: {
                ...this.state.userConfig,
                temperature_units: value,
            },
        });
    };

    hasChanged = () => {
        if (
            this.state.userConfig.elr_units !== this.props.userConfig.elr_units ||
            this.state.userConfig.measurement_units !== this.props.userConfig.measurement_units ||
            this.state.userConfig.sighting_units !== this.props.userConfig.sighting_units ||
            this.state.userConfig.speed_units !== this.props.userConfig.speed_units ||
            this.state.userConfig.temperature_units !== this.props.userConfig.temperature_units
        ) {
            return false;
        } else {
            return true;
        }
    };

    workspaceRouteCoordinateSystems = () => {
        return RouteCoordinateSystems.filter((system) => {
            return this.props.workspaceCoordinateSystemNames.includes(system.ID);
        });
    };

    render = () => {
        return (
            <Modal
                title="Change Unit System"
                visible={true}
                onOk={this.changeUnitSystem}
                okText="Save"
                okButtonProps={{
                    disabled: this.hasChanged(),
                }}
                cancelText="Close"
                onCancel={this.props.onClose}>
                <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                    <span className="UnitSystemDescription">
                        You can change what unit system is used for displaying measurements such as ELR, Mile and Chains, Meterage or Yards and where in the
                        dashboard to apply them.
                    </span>
                    <span className="UnitSystemLabel">Route Coordinate Format</span>
                    <Select
                        className="UnitSystemSelector"
                        defaultValue={this.props.userConfig.elr_units}
                        onChange={this.handleELRChange}>
                        {_.chain(this.workspaceRouteCoordinateSystems())
                            .flatMap((system) => _.map(system.handlerClass.display_formats, (desc, name) => ({ name, desc })))
                            .uniqBy("name")
                            .map(({ desc, name }) => (
                                <Option
                                    key={name}
                                    value={name}>
                                    {desc}
                                </Option>
                            ))
                            .value()}
                    </Select>
                    <span className="UnitSystemLabel">Measurement Tools</span>
                    <Select
                        className="UnitSystemSelector"
                        defaultValue={this.props.userConfig.measurement_units}
                        onChange={this.handleMeasurementChange}>
                        <Option value="metres">Metres</Option>
                        <Option value="yards">Yards</Option>
                    </Select>
                    <span className="UnitSystemLabel">Sighting Tools</span>
                    <Select
                        className="UnitSystemSelector"
                        defaultValue={this.props.userConfig.sighting_units}
                        onChange={this.handleSightingChange}>
                        <Option value="metres">Metres</Option>
                        <Option value="yards">Yards</Option>
                    </Select>

                    <span className="UnitSystemLabel">Speed Units</span>
                    <Select
                        className="UnitSystemSelector"
                        defaultValue={this.props.userConfig.speed_units || "miles"}
                        onChange={this.handleSpeedChange}>
                        <Option value="metres">Meters Per Second</Option>
                        <Option value="miles">Miles Per Hour</Option>
                        <Option value="kilometers">Kilometers Per Hour</Option>
                    </Select>

                    <span className="UnitSystemLabel">Temperature Units</span>
                    <Select
                        className="UnitSystemSelector"
                        defaultValue={this.props.userConfig.temperature_units || "celsius"}
                        onChange={this.handleTempUnitChange}>
                        <Option value="celsius">Celsius</Option>
                        <Option value="fahrenheit">Fahrenheit</Option>
                    </Select>
                </div>
            </Modal>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userConfig: state.userDetails.userConfig,
        workspaceCoordinateSystemNames: state.routeCoordinateSystems,
    };
};

export default connect(mapStateToProps)(UnitSelectionModal);
