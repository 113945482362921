import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import logo from "../images/new-branding-2.0/aivr-purple.svg";

class InvalidShareLinkComponent extends React.PureComponent {
    render() {
        let baseURL = window.location.href.replace(/\?.*/, "");
        return (
            <div className="LoginOverlay Center-Content">
                <div className="LoginContent">
                    <img
                        className="LoginLogo"
                        src={logo}
                        alt="AIVR Logo"
                        crossOrigin={"anonymous"}
                    />
                    <div className="InvalidShareLinkMessage">
                        <p>The share link you have provided is invalid or has expired.</p>
                        <p>
                            Please <a href={baseURL}>click here</a> to log in.
                        </p>
                    </div>
                    <div className="LoginFooter">
                        <div className="LoginSales">
                            <p>
                                For enquiries, please contact <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(InvalidShareLinkComponent);
