import React, { useEffect, useRef, useMemo, useCallback } from "react";
import _ from "lodash";
import {
    setPatrolLocation,
    getPatrolDeviceConfig,
    getInspectionBookmarks,
    clearPatrolState,
    toggleFetchingELRs,
    fetchInspectionAnnotations,
    getStaticPatrolImages,
} from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import {
    chainLineToCoords,
    coordsLineToChain,
    findNearestRailImage,
    getSvgLineCoverage,
    chainageFromImage,
    calculatePatrolDeviceConfig,
} from "../util/Geometry";
import { calculateOffsettedPatrolImage } from "../util/PlaylistUtils";
import { withRouter } from "react-router-dom";

const schemaLocationSelector = (state) => state.schemaInterface.location;
const allImagesSelector = (state) => state.schemaInterface.images;
const sessionImagesSelector = (state) => {
    if (state.schemaInterface.sessions.length && state.schemaInterface.selectedSession > -1) {
        return state.schemaInterface.sessions[state.schemaInterface.selectedSession];
    }
    return [];
};
const sessionSelector = (state) => state.schemaInterface.sessions;
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const railDataLoadedSelector = (state) => state.schemaInterface.imagesLoaded;
const fetchingELRsSelector = (state) => state.schemaInterface.fetchingELRs;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const annotationsSelector = (state) => state.railInspection.annotations.data;
const patrolIDSelector = (state) => state.schemaInterface.plan.id;
const deviceConfigsSelector = (state) => state.schemaInterface.config;

const BookmarkSVG = () => {
    return (
        <>
            <path
                className="BookmarkSVGPath Black"
                d="M77.78,232.14c11.26-13,55.63-79.86,70.63-127.47A75.42,75.42,0,1,0,4.28,94.16l0,0c.11.51.24,1,.36,1.53s.3,1.21.46,1.81C17.49,145.25,65.94,218.47,77.78,232.14Z"
            />
            <path
                className="BookmarkSVGPath Colour"
                d="M77.78,232.14l1.89,1.64c1.48-1.72,3.43-4.22,5.78-7.42a594.7,594.7,0,0,0,33.81-53c12.63-22.18,24.87-46.78,31.53-67.91l-2.38-.75,2.34.88A77.91,77.91,0,1,0,0,77.91,78.57,78.57,0,0,0,1.83,94.69l2.45-.53,1.46-2h0L.33,88.22l1.49,6.49c.12.52.25,1,.37,1.55h0c.15.62.31,1.24.48,1.88v0c3.17,12.17,8.57,25.81,15.09,39.7a581.38,581.38,0,0,0,33,59.64c5.47,8.73,10.61,16.48,15,22.71s7.87,10.92,10.2,13.62L77.78,236l1.89-2.18-1.89-1.64,1.89-1.64c-1.4-1.61-3.43-4.22-5.88-7.58a603.91,603.91,0,0,1-35.63-56.46c-6.54-11.72-12.88-24-18.24-35.89A218.36,218.36,0,0,1,7.5,96.85v0c-.16-.57-.3-1.15-.45-1.75h0c-.12-.52-.25-1-.36-1.5l-2.43.56-1.47,2h0L8.12,100l-1.4-6.4a72.9,72.9,0,1,1,139.35,10.16l0,.06,0,.08c-7.38,23.46-22.16,52-36.48,76.19-7.16,12.1-14.21,23.13-20.15,31.92-3,4.4-5.68,8.24-8,11.37s-4.2,5.57-5.53,7.1l1.89,1.64,1.89-1.64Z"
            />
            <path
                className="BookmarkSVGPath Colour"
                d="M77.74,35.73a31.54,31.54,0,1,0,31.54,31.54A31.54,31.54,0,0,0,77.74,35.73Zm-1.59,16A14.17,14.17,0,0,0,62,65.89a2.7,2.7,0,1,1-5.39,0A19.56,19.56,0,0,1,76.15,46.35a2.7,2.7,0,0,1,0,5.39Z"
            />
            <path
                className="BookmarkSVGPath Colour"
                d="M85.5,102.72a38.72,38.72,0,0,1-15.77,0v37.67l8,7.72,7.78-7.72S85.46,105.91,85.5,102.72Z"
            />
        </>
    );
};

const AnnotationSVG = () => {
    return (
        <>
            <path
                className="BookmarkSVGPath"
                d="M77.78,232.14c11.26-13,55.63-79.86,70.63-127.47A75.42,75.42,0,1,0,4.28,94.16l0,0c.11.51.24,1,.36,1.53s.3,1.21.46,1.81C17.49,145.25,65.94,218.47,77.78,232.14Z"
            />
            <path
                className="BookmarkSVGPath"
                d="M77.78,232.14l1.89,1.64c1.48-1.72,3.43-4.22,5.78-7.42a594.7,594.7,0,0,0,33.81-53c12.63-22.18,24.87-46.78,31.53-67.91l-2.38-.75,2.34.88A77.91,77.91,0,1,0,0,77.91,78.57,78.57,0,0,0,1.83,94.69l2.45-.53,1.46-2h0L.33,88.22l1.49,6.49c.12.52.25,1,.37,1.55h0c.15.62.31,1.24.48,1.88v0c3.17,12.17,8.57,25.81,15.09,39.7a581.38,581.38,0,0,0,33,59.64c5.47,8.73,10.61,16.48,15,22.71s7.87,10.92,10.2,13.62L77.78,236l1.89-2.18-1.89-1.64,1.89-1.64c-1.4-1.61-3.43-4.22-5.88-7.58a603.91,603.91,0,0,1-35.63-56.46c-6.54-11.72-12.88-24-18.24-35.89A218.36,218.36,0,0,1,7.5,96.85v0c-.16-.57-.3-1.15-.45-1.75h0c-.12-.52-.25-1-.36-1.5l-2.43.56-1.47,2h0L8.12,100l-1.4-6.4a72.9,72.9,0,1,1,139.35,10.16l0,.06,0,.08c-7.38,23.46-22.16,52-36.48,76.19-7.16,12.1-14.21,23.13-20.15,31.92-3,4.4-5.68,8.24-8,11.37s-4.2,5.57-5.53,7.1l1.89,1.64,1.89-1.64Z"
            />
            <polygon
                className="BookmarkSVGPath Colour"
                points="117.34 121.87 117.34 119.87 40.3 119.87 40.3 44.83 115.34 44.83 115.34 121.87 117.34 121.87 117.34 119.87 117.34 121.87 119.34 121.87 119.34 40.83 36.3 40.83 36.3 123.87 119.34 123.87 119.34 121.87 117.34 121.87"
            />
            <rect
                className="BookmarkSVGPath Colour"
                x="30.47"
                y="35.84"
                width="15.32"
                height="15.32"
            />
            <rect
                className="BookmarkSVGPath Colour"
                x="109.47"
                y="35.84"
                width="15.32"
                height="15.32"
            />
            <rect
                className="BookmarkSVGPath Colour"
                x="109.47"
                y="113.84"
                width="15.32"
                height="15.32"
            />
            <rect
                className="BookmarkSVGPath Colour"
                x="30.47"
                y="113.84"
                width="15.32"
                height="15.32"
            />
        </>
    );
};

const Y_OFFSET = 0;
const X_OFFSET = 0;

const STROKE_WIDTH = 7;

const RoutePatrolSvg = ({ planData, preview, progressMap, match, calculateCoveragePercentage = false }) => {
    const currentLocation = useSelector(schemaLocationSelector);
    const railImages = useSelector(allImagesSelector);
    const sessionImages = useSelector(sessionImagesSelector);
    const inspectionBookmarks = useSelector(bookmarksSelector);
    const sessions = useSelector(sessionSelector);
    const imagesLoaded = useSelector(railDataLoadedSelector);
    const fetchingELRs = useSelector(fetchingELRsSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const inspectionAnnotations = useSelector(annotationsSelector);
    const patrolID = useSelector(patrolIDSelector);
    const deviceConfigs = useSelector(deviceConfigsSelector);

    const svgRef = useRef();
    const dispatch = useDispatch();

    const isPlanner = match.path.includes("patrol-planner");

    const initialised = useMemo(() => {
        return !fetchingELRs && imagesLoaded && sessions?.length;
    }, [fetchingELRs, imagesLoaded, sessions]);

    const deviceConfig = useMemo(() => {
        return calculatePatrolDeviceConfig(deviceConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [deviceConfigs, railImages]);

    useEffect(() => {
        if (initialised) {
            const firstSession = sessions[0];

            if (firstSession.length > 1) {
                let startImage = null;

                for (let i = 0; i < firstSession.length && !startImage; i++) {
                    const image = firstSession[i];

                    const chains = chainageFromImage(image);

                    for (let l = 0; l < planData.svg.line.length && !startImage; l++) {
                        const line = planData.svg.line[l];
                        const lineStart = Math.min(line["chains1"], line["chains2"]);
                        const lineEnd = Math.max(line["chains1"], line["chains2"]);
                        if (image.mwv.elr === line.ELR && image.mwv.trid === line["TRID"] && chains > lineStart && chains < lineEnd) {
                            startImage = image;
                        }
                    }
                }

                if (startImage) {
                    const locationObj = {
                        elr: startImage.mwv.elr,
                        chain: parseInt(startImage.mwv.mile) * 80 + parseFloat(startImage.mwv.yard) / 22,
                        trackID: startImage.mwv.trid,
                    };
                    dispatch(setPatrolLocation(locationObj));
                }
            }
        }
    }, [dispatch, sessions, initialised, planData.id]);

    useEffect(() => {
        if (!preview) {
            dispatch(clearPatrolState());
        }
    }, [dispatch, preview]);

    useEffect(() => {
        if (preview) {
            return;
        }

        if (!_.isEmpty(planData.svg.line) && !isPlanner && !fetchingELRs && _.isEmpty(deviceConfig)) {
            dispatch(toggleFetchingELRs(true));
            dispatch(getPatrolDeviceConfig(planData.device_group_id));
            dispatch(getInspectionBookmarks(-1, patrolID, match.path.includes("review-patrol")));
            dispatch(fetchInspectionAnnotations(-1, patrolID, match.path.includes("review-patrol")));
            dispatch(getStaticPatrolImages(planData.image_file_link));
        }
    }, [dispatch, planData, patrolID, preview, match, isPlanner, fetchingELRs, deviceConfig]);

    const onClick = (evt) => {
        if (!evt.target.attributes.chains1) {
            return;
        }
        const svg = svgRef.current;
        const pt = svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;

        // // The cursor point, translated into svg coordinates
        const cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse());
        const trackID = evt.target.getAttribute("TRID");
        const elr = evt.target.getAttribute("ELR");

        const lineStartX = parseFloat(evt.target.getAttribute("x1"));
        const lineEndX = parseFloat(evt.target.getAttribute("x2"));
        const totalX = Math.abs(lineEndX - lineStartX);
        const minX = Math.min(lineStartX, lineEndX);

        const startChain = parseFloat(evt.target.getAttribute("chains1"));
        const endChain = parseFloat(evt.target.getAttribute("chains2"));
        const totalChains = Math.abs(endChain - startChain);
        const minChain = Math.min(startChain, endChain);
        const maxChain = Math.max(startChain, endChain);

        const distanceAlongLine = (cursorpt.x - minX) / totalX;

        let clickedChain = minChain + totalChains * distanceAlongLine;
        if (lineStartX > lineEndX) {
            clickedChain = maxChain - totalChains * distanceAlongLine;
        }

        const locationObj = {
            elr: elr.toUpperCase(),
            chain: clickedChain,
            trackID: trackID,
        };
        dispatch(setPatrolLocation(locationObj));
    };

    const currentLine = useMemo(() => {
        const currentChain = currentLocation.chain;
        const currentTrack = currentLocation.trackID;
        const currentELR = currentLocation.elr;

        if (!currentTrack || !currentChain) {
            return null;
        }

        let currentLine = null;

        planData.svg.line.forEach((line) => {
            if (
                line["TRID"].toString() === currentTrack.toString() &&
                line["ELR"].toString() === currentELR.toString() &&
                ((currentChain >= parseFloat(line["chains1"]) && currentChain <= parseFloat(line["chains2"])) ||
                    (currentChain <= parseFloat(line["chains1"]) && currentChain >= parseFloat(line["chains2"])))
            ) {
                currentLine = line;
            }
        });

        if (!currentLine) {
            return null;
        }

        return currentLine;
    }, [currentLocation, planData]);

    const currentSVGPosition = useMemo(() => {
        if (!currentLine) {
            return null;
        }
        const currentChain = currentLocation.chain;
        const currentPosition = chainLineToCoords(currentLine, currentChain);
        return currentPosition;
    }, [currentLine, currentLocation]);

    const linesWithFootageCoverage = useMemo(() => {
        if (preview || !initialised) {
            return planData.svg.line;
        }
        let linesWithCoverage = planData.svg.line.map((original_line) => {
            let line = { ...original_line };
            line.coverage = getSvgLineCoverage(line, railImages);
            return line;
        });

        return linesWithCoverage;
    }, [railImages, planData.svg.line, preview, initialised]);

    const linesOnSelectedRoute = useMemo(() => {
        return linesWithFootageCoverage.map((line) => {
            line.selectedCoverage = getSvgLineCoverage(line, sessionImages);
            return line;
        });
    }, [linesWithFootageCoverage, sessionImages]);

    const coverageLines = useMemo(() => {
        const filteredLinesWithFootage = linesWithFootageCoverage.filter((line) => {
            return line.coverage && line.coverage.length;
        });

        if (calculateCoveragePercentage) {
            calculateCoveragePercentage(
                filteredLinesWithFootage,
                planData.svg.line.filter((line) => {
                    return line.patrol_chain_start || line.patrol_chain_start === 0;
                }),
            );
        }

        return filteredLinesWithFootage.map((line) => {
            return line.coverage.flatMap((coverage) => {
                const startCoords = chainLineToCoords(line, coverage.start);
                const endCoords = chainLineToCoords(line, coverage.end);

                return (
                    <line
                        x1={startCoords[0]}
                        y1={startCoords[1]}
                        x2={endCoords[0]}
                        y2={endCoords[1]}
                        stroke-width={STROKE_WIDTH}
                        className="CoverageLine"
                    />
                );
            });
        });
    }, [linesWithFootageCoverage, calculateCoveragePercentage, planData.svg.line]);

    const progressLines = useMemo(() => {
        const lines = planData.svg.line.flatMap((line) => {
            const progress = _.get(progressMap, [line["ELR"].toUpperCase(), line["TRID"]], []);
            const startChain = Math.min(line.chains1, line.chains2);
            const endChain = Math.max(line.chains1, line.chains2);

            const filteredProgress = progress.filter((progressData) => {
                const { start, end } = progressData;

                return (start > startChain && start < endChain) || (end > startChain && end < endChain) || (start < startChain && end > endChain);
            });

            return filteredProgress.map((progressData) => {
                const progressStart = Math.max(startChain, progressData.start);
                const progressEnd = Math.min(endChain, progressData.end);

                const startCoords = chainLineToCoords(line, progressStart);
                const endCoords = chainLineToCoords(line, progressEnd);

                return (
                    <line
                        x1={startCoords[0]}
                        y1={startCoords[1]}
                        x2={endCoords[0]}
                        y2={endCoords[1]}
                        stroke-width={STROKE_WIDTH}
                        className="ProgressLine"
                    />
                );
            });
        });
        return lines;
    }, [planData.svg.line, progressMap]);

    const inspectionLines = useMemo(() => {
        return planData.svg.line
            .filter((line) => {
                return line.patrol_chain_start || line.patrol_chain_start === 0;
            })
            .map((line) => {
                const startCoords = chainLineToCoords(line, line.patrol_chain_start);
                const endCoords = chainLineToCoords(line, line.patrol_chain_end);

                return (
                    <line
                        x1={startCoords[0]}
                        y1={startCoords[1]}
                        x2={endCoords[0]}
                        y2={endCoords[1]}
                        className="PatrolLine"
                        stroke={"red"}
                        stroke-width={2}
                        stroke-dasharray="8 15"
                    />
                );
            });
    }, [planData.svg.line]);

    const selectedRouteLines = useMemo(() => {
        return linesOnSelectedRoute
            .filter((line) => {
                return line.selectedCoverage && line.selectedCoverage.length;
            })
            .map((line) => {
                return line.selectedCoverage.flatMap((coverage) => {
                    const startCoords = chainLineToCoords(line, coverage.start);
                    const endCoords = chainLineToCoords(line, coverage.end);

                    return (
                        <line
                            x1={startCoords[0]}
                            y1={startCoords[1]}
                            x2={endCoords[0]}
                            y2={endCoords[1]}
                            stroke-width={STROKE_WIDTH}
                            className="SelectedRouteLine"
                        />
                    );
                });
            });
    }, [linesOnSelectedRoute]);

    const svgContent = useMemo(() => {
        const lines = [];

        let colour = "grey";

        if (preview) {
            colour = "white";
        }

        linesWithFootageCoverage.forEach((line) => {
            line["y1"] = parseFloat(line["y1"]) + Y_OFFSET;
            line["y2"] = parseFloat(line["y2"]) + Y_OFFSET;

            line["x1"] = parseFloat(line["x1"]) + X_OFFSET;
            line["x2"] = parseFloat(line["x2"]) + X_OFFSET;

            line["stroke-width"] = STROKE_WIDTH;

            lines.push(
                <line
                    {...line}
                    stroke={colour}
                    className={preview ? "" : " NonPreview"}
                />,
            );
        });

        const text = planData.svg.text.map((text) => {
            const x = parseFloat(text["x"]) + X_OFFSET;
            const y = parseFloat(text["y"]) + Y_OFFSET + 10;

            return (
                <text
                    x={x}
                    y={y}
                    {...text}
                    fill="white"
                    stroke="white">
                    {text["text"]}
                </text>
            );
        });

        return (
            <>
                {lines}
                {text}
            </>
        );
    }, [linesWithFootageCoverage, planData.svg.text, preview]);

    const markupClick = useCallback(
        (image) => {
            const locationObj = {
                elr: image.mwv.elr,
                chain: parseInt(image.mwv.mile) * 80 + parseFloat(image.mwv.yard) / 22,
                trackID: image.mwv.trid,
            };
            dispatch(setPatrolLocation(locationObj, image.timestamp));
        },
        [dispatch],
    );

    const bookmarks = useMemo(() => {
        if (!railImages || !railImages.length || !initialised) {
            return null;
        }

        if (inspectionBookmarks && inspectionBookmarks.length) {
            return inspectionBookmarks.map((bookmark) => {
                const bookmarkImage = calculateOffsettedPatrolImage(bookmark, deviceConfig, patrolDirections, railImages);
                if (!bookmarkImage) {
                    return null;
                }
                const chainValue = parseInt(bookmarkImage.mwv.mile) * 80 + parseFloat(bookmarkImage.mwv.yard) / 22;
                const trackID = bookmarkImage.mwv.trid;
                const elr = bookmarkImage.mwv.elr;

                const currentLine = _.find(planData.svg.line, (line) => {
                    return (
                        line["TRID"] === trackID.toString() &&
                        chainValue > parseFloat(line["chains1"]) &&
                        chainValue < parseFloat(line["chains2"]) &&
                        elr === line["ELR"]
                    );
                });

                // TODO - better fix might be needed here
                if (currentLine) {
                    const coords = chainLineToCoords(currentLine, chainValue);
                    return (
                        <g
                            transform={`translate(${coords[0]}, ${coords[1]})`}
                            height={20}
                            width="200"
                            onClick={() => markupClick(bookmarkImage)}>
                            <BookmarkSVG />
                        </g>
                    );
                }
            });
        } else {
            return null;
        }
    }, [inspectionBookmarks, railImages, planData, markupClick, patrolDirections, deviceConfig, initialised]);

    const annotations = useMemo(() => {
        if (!railImages || !railImages.length || !initialised) {
            return null;
        }

        if (inspectionAnnotations && inspectionAnnotations.length) {
            return inspectionAnnotations.map((annotation) => {
                const annotationImage = calculateOffsettedPatrolImage(annotation, deviceConfig, patrolDirections, railImages);

                if (!annotationImage) {
                    return null;
                }
                const chainValue = parseInt(annotationImage.mwv.mile) * 80 + parseFloat(annotationImage.mwv.yard) / 22;
                const trackID = annotationImage.mwv.trid;
                const elr = annotationImage.mwv.elr;

                const currentLine = _.find(planData.svg.line, (line) => {
                    return (
                        line["TRID"] === trackID.toString() &&
                        chainValue > parseFloat(line["chains1"]) &&
                        chainValue < parseFloat(line["chains2"]) &&
                        elr === line["ELR"]
                    );
                });

                // TODO - better fix might be needed here
                if (currentLine) {
                    const coords = chainLineToCoords(currentLine, chainValue);

                    return (
                        <g
                            transform={`translate(${coords[0]}, ${coords[1]})`}
                            height={20}
                            width="200"
                            onClick={() => markupClick(annotationImage)}>
                            <AnnotationSVG />
                        </g>
                    );
                }
            });
        } else {
            return null;
        }
    }, [inspectionAnnotations, railImages, planData, markupClick, patrolDirections, deviceConfig, initialised]);

    const circleRotation = useMemo(() => {
        if (!currentLine) {
            return 0;
        }

        let leftY, rightY, leftX, rightX;

        if (parseInt(currentLine.x2) > parseInt(currentLine.x1)) {
            leftY = currentLine.y1;
            rightY = currentLine.y2;
            leftX = currentLine.x1;
            rightX = currentLine.x2;
        } else {
            leftY = currentLine.y2;
            rightY = currentLine.y1;
            rightX = currentLine.x1;
            leftX = currentLine.x2;
        }

        const leftChain = coordsLineToChain(currentLine, leftX);
        const rightChain = coordsLineToChain(currentLine, rightX);

        const nearestLeftImageIndex = findNearestRailImage(currentLine.ELR, leftChain, currentLine.TRID, sessionImages, true);
        const nearestRightImageIndex = findNearestRailImage(currentLine.ELR, rightChain, currentLine.TRID, sessionImages, true);

        const nearestLeftImage = sessionImages[nearestLeftImageIndex];
        const nearestRightImage = sessionImages[nearestRightImageIndex];
        let angle = (Math.atan2(rightY - leftY, rightX - leftX) * 180) / Math.PI;

        if (nearestLeftImage && nearestRightImage && nearestLeftImage.timestamp > nearestRightImage.timestamp) {
            // flip if there are images and they run from
            return `${angle + 180} 230 230`;
        }

        return `${angle} 230 230`;
    }, [currentLine, sessionImages]);

    const positionCircle = useMemo(() => {
        if (preview || !currentSVGPosition) {
            return null;
        }
        return (
            <g
                transform={`
                translate(${currentSVGPosition[0] - 14.38},${currentSVGPosition[1] - 14.38})
                scale(0.06)
                rotate(${circleRotation})`}>
                <circle
                    cx="227.85"
                    cy="227.85"
                    r="227.85"
                    className="PatrolPositionCircle"
                />
                <polyline
                    class="PatrolPositionCircleFill"
                    points="185.92 107.74 306.39 228.21 185.92 348.68"
                />
            </g>
        );
    }, [currentSVGPosition, preview, circleRotation]);

    return (
        <svg
            className="SchemaSVG"
            onClick={onClick}
            ref={svgRef}
            baseProfile="full"
            version="1.1"
            viewBox="0 0 1176 350"
            xmlns="http://www.w3.org/2000/svg">
            {svgContent}
            {!preview && coverageLines}
            {!preview && selectedRouteLines}
            {!preview && inspectionLines}
            {!preview && progressLines}

            {!preview && bookmarks}
            {!preview && annotations}
            {positionCircle}
        </svg>
    );
};

export default withRouter(RoutePatrolSvg);
