import React, { useMemo, useCallback } from "react";
import { Marker, LayerGroup } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { createCustomIcon } from "./Icons";
import _ from "lodash";
import { goToBounds, selectAsset, setAssetGroupFilter, setCurrentNearbyAsset } from "redux/actions";

const nearbyAssetsSelector = (state) => state.assets.nearestAssets;
const dashboardSelector = (state) => _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
const currentNearbyAssetSelector = (state) => state.assets.currentNearbyAsset;

const NearbyAssetMarkers = () => {
    const nearbyAssets = useSelector(nearbyAssetsSelector);
    const dashboard = useSelector(dashboardSelector);
    const currentNearbyAsset = useSelector(currentNearbyAssetSelector);

    const dispatch = useDispatch();

    const handleClick = useCallback(
        (asset) => {
            const availableAssetGroups = _.get(dashboard, ["config", "asset_groups"], []);

            if (availableAssetGroups.includes(asset.asset_group_id.toString())) {
                dispatch(selectAsset(asset.id));
                dispatch(setAssetGroupFilter(_.get(asset, "asset_group_id", 1)));

                let north = asset["coords"][0] + 0.002;
                let east = asset["coords"][1] + 0.002;
                let south = asset["coords"][0] - 0.002;
                let west = asset["coords"][1] - 0.002;

                dispatch(
                    goToBounds({
                        north,
                        south,
                        east,
                        west,
                    }),
                );
            } else {
                dispatch(setCurrentNearbyAsset(asset));
            }
        },
        [dispatch, dashboard],
    );

    const renderNearbyAssets = useMemo(() => {
        if (!nearbyAssets.length) {
            return null;
        }

        return nearbyAssets.map((asset) => {
            const iconType = _.get(asset, "icon", "exclamation-triangle");
            const markerColour = currentNearbyAsset.id === asset.id ? "#6C43DF" : "rgba(108, 67, 223, 0.4)";
            const markerIcon = createCustomIcon(iconType, markerColour, false);

            return (
                <Marker
                    key={asset.id}
                    id={asset.id}
                    icon={markerIcon}
                    position={[asset.coords[0], asset.coords[1]]}
                    onClick={() => handleClick(asset)}
                />
            );
        });
    }, [nearbyAssets, handleClick, currentNearbyAsset]);

    return <LayerGroup>{renderNearbyAssets}</LayerGroup>;
};

export default NearbyAssetMarkers;
