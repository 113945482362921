import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { SegmentIcon } from "./Icons";
import _ from "lodash";
import { getInterpolatedPosition, getOffsetAdjustedTime, absoluteTimeLookup } from "../util/PlaylistUtils";

class ShortcutMarkers extends React.PureComponent {
    renderShortcutMarkers = () => {
        let markerIconToUse = SegmentIcon;

        const markers = [];

        if (this.props.viewingRouteData.video && this.props.viewingRouteData.video[this.props.sourceIndex] && this.props.shortcutMarkers.length) {
            let videoParts = this.props.viewingRouteData.video[this.props.sourceIndex];
            const startTime = _.minBy(this.props.shortcutMarkers, "timestamp");
            const endTime = _.maxBy(this.props.shortcutMarkers, "timestamp");

            const startVideoKeyIndex = absoluteTimeLookup(startTime.timestamp / 1000, videoParts) || 0;
            let segmentStartVideo = videoParts[startVideoKeyIndex];
            const startVideoTime = segmentStartVideo[1];
            let offsetStartTime = getOffsetAdjustedTime(startVideoTime, this.props.offsets) + 0.0001;
            let coords = getInterpolatedPosition(offsetStartTime, videoParts, this.props.use_snapped);

            if (coords !== null) {
                let startCoords = [coords[1], coords[0]];
                markers.push(
                    <Marker
                        position={startCoords}
                        icon={markerIconToUse}
                    />,
                );
            }

            const endVideoKeyIndex = absoluteTimeLookup(endTime.timestamp / 1000, videoParts) || 0;
            let segmentEndVideo = videoParts[endVideoKeyIndex];
            if (endVideoKeyIndex < videoParts.length - 2) {
                segmentEndVideo = videoParts[endVideoKeyIndex + 1];
            }
            const endVideoTime = segmentEndVideo[1];
            let offsetEndTime = getOffsetAdjustedTime(endVideoTime, this.props.offsets);
            let endCoords = getInterpolatedPosition(offsetEndTime, videoParts, this.props.use_snapped);
            if (endCoords !== null) {
                let coords = [endCoords[1], endCoords[0]];
                markers.push(
                    <Marker
                        position={coords}
                        icon={markerIconToUse}
                    />,
                );
            }
        }
        return markers;
    };

    render() {
        return <LayerGroup>{this.renderShortcutMarkers()}</LayerGroup>;
    }
}

const mapStateToProps = (state) => {
    const allPoints = _.get(state.shortcuts.shortcutSessionPoints, [state.playlist.data.routeID], []).flatMap((ranges) => {
        return ranges;
    });

    return {
        shortcutMarkers: allPoints,
        sourceIndex: state.playlist.position.sourceIndex,
        viewingRouteData: state.playlist.data,
        use_snapped: state.snappedRoute || false,
    };
};

export default connect(mapStateToProps)(ShortcutMarkers);
