import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { faCamera, faDotCircle, faTimes, faTrafficLight, faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    asyncLoadImage,
    calculateFrame,
    distance,
    get_source_calibration,
    calculateNext,
    calculatePrevious,
    getCurrentVideoKey,
    videoKeyToTimestamp,
    getAbsoluteTimestamp,
    getUrlDataForFrame,
} from "components/util/PlaylistUtils";
import OBCSpinner from "../../util/OBC";
import ThreeDFeatureOverlay from "./3d/3dFeatureOverlay";
import {
    calculateRouteCoordinatesForLocation,
    getHeading,
    latLonToMeters,
    locationsAreEqual,
    metersToLatLon,
    projectToIntersection,
    coordinateToTimestamp,
} from "../../util/Geometry";
import {
    featureOverlayAddFeature,
    featureOverlayClearFeatures,
    featureOverlayRemoveFeature,
    featureOverlaySetDatum,
    findAudit,
    toggleSnapshot,
    requestPlaylistPosition,
    toggleFullscreen,
    featureOverlayUpdateFeature,
} from "../../../redux/actions/index";
import {
    faArrowsAlt,
    faCheck,
    faLocationCircle,
    faLock,
    faLockOpen,
    faMapMarkerAltSlash,
    faPennant,
    faStepBackward,
    faStepForward,
} from "@fortawesome/pro-solid-svg-icons";
import { WithAspectRatio } from "../../util/WithAspectRatio";
import { faCommentAltEdit } from "@fortawesome/pro-regular-svg-icons";
import { InputModal } from "../../util/InputModal";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import { Options as FlagOptions } from "./3d/features/Flag";
import { Options as SignalOptions } from "./3d/features/Signal";
import { TreeSelect } from "antd";

const overlayFeaturesSelector = (state) => state.featureOverlay.features;
const datumPositionSelector = (state) => _.get(state.featureOverlay, ["datum", "location"], null);

class FeaturePlacementInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageData: null,
            placingFeatureType: null,
            placingFeatureOptions: {},
            cursorLocation: [0, 0],
            placeFeatureMode: 0,
            cursorOrientation: 0,
            highlightedFeature: null,
            selectedFeature: null,
            movingFeature: null,
            editingFeatureLabel: false,
        };

        this.canvas = React.createRef();
    }

    close = () => {
        this.props.onClose();
    };

    componentDidMount() {
        this.props.dispatch(findAudit("open_feature_placement", {}));
        this.componentDidUpdate({}, {});
    }

    navigate = (nav) => {
        if (nav === null) {
            return;
        }
        const [newKeyIndex, offset] = nav;
        console.log("Requesting index/offset:", newKeyIndex, offset);
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, newKeyIndex, offset),
        );
    };

    navigateNext = () => this.navigate(calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePrevious = () => this.navigate(calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    get isNextDisabled() {
        return calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }
    get isPreviousDisabled() {
        return calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let changed_props = _.filter(["position", "timeOffset", "video", "baseURL"], (item) => this.props[item] !== prevProps[item]);

        if (changed_props.length) {
            let videoKey = this.props.video[this.props.position];
            if (videoKey) {
                const frameCount = videoKey[5];
                let subIndex = 0;
                if (frameCount) {
                    subIndex = calculateFrame(this.props.video, this.props.position, this.props.timeOffset);
                }

                const urlData = getUrlDataForFrame(this.props.video, this.props.position, subIndex);

                const url = `${this.props.baseURL}${urlData.imageFile}?csrf=${this.props.csrfToken}`;

                asyncLoadImage(url, urlData.range)
                    .then((imageData) => {
                        this.setState({
                            imageData,
                        });
                    })
                    .catch(() => {});
            }
        }
    }

    mouseMove = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        let currentTargetRect = this.canvas.current.getBoundingClientRect();
        let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
            y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;
        x = 2 * Math.max(0, Math.min(1, x)) - 1;
        y = 2 * Math.max(0, Math.min(1, y)) - 1;

        let location = null;

        if (this.props.calibration) {
            let worldCoordinates = projectToIntersection([x, y], [-10000, 0, 1000], this.props.calibration);
            if (worldCoordinates !== null) {
                let eastingOffset = Math.cos(this.props.heading) * worldCoordinates[2] + Math.sin(this.props.heading) * worldCoordinates[0];
                let northingOffset = Math.sin(this.props.heading) * worldCoordinates[2] - Math.cos(this.props.heading) * worldCoordinates[0];
                location = metersToLatLon(this.props.location, [eastingOffset, northingOffset]);
            }
        }

        if (this.state.placeFeatureMode === 0) {
            let highlightedFeature = null;

            if (location !== null) {
                const closestFeatures = _.chain(this.props.features)
                    .map((feature, index) => ({
                        index,
                        distance: distance(latLonToMeters(location, feature.location)),
                        is_current_users: feature.is_current_users,
                    }))
                    .sortBy("distance")
                    .filter((f) => f.distance <= 1.5)
                    .value();
                if (closestFeatures.length) {
                    if (closestFeatures[0].is_current_users) {
                        highlightedFeature = closestFeatures[0].index;
                    }
                }
            }
            this.setState({
                highlightedFeature,
            });
        } else if (this.state.placeFeatureMode === 1) {
            this.setState({
                cursorLocation: location,
            });
        } else if (this.state.placeFeatureMode === 2) {
            const offsets = latLonToMeters(this.state.cursorLocation, location);
            const orientation = Math.round((36 * Math.atan2(offsets[1], offsets[0])) / Math.PI) * 5 + 180;
            this.setState({
                cursorOrientation: orientation,
            });
        }
    };

    mouseClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (this.state.placeFeatureMode === 0) {
            if (this.state.selectedFeature !== null && this.state.highlightedFeature === this.state.selectedFeature) {
                this.setState({
                    selectedFeature: null,
                });
            } else {
                this.setState({
                    selectedFeature: this.state.highlightedFeature,
                });
            }
        } else if (this.state.placeFeatureMode === 1) {
            this.setState({
                placeFeatureMode: this.state.placingFeatureType === "flag" ? -1 : 2,
            });
        } else if (this.state.placeFeatureMode === 2) {
            this.setState({
                placeFeatureMode: -1,
            });
        }
    };

    unconfirmPlaceFeature = () => {
        this.setState({
            placeFeatureMode: 1,
        });
    };

    confirmPlaceFeature = () => {
        const playlist = this.props.playlists[this.props.sourceIndex];
        const timestamp = coordinateToTimestamp(this.state.cursorLocation, playlist, this.props.useSnapped, this.props.offsets, true) * 1000;
        const elr = calculateRouteCoordinatesForLocation(timestamp, this.props.routeLocationData, this.props.routeLocationSystemID);
        if (this.state.movingFeature !== null) {
            if (locationsAreEqual(this.props.features[this.state.movingFeature].location, this.props.datumPosition)) {
                this.props.dispatch(featureOverlaySetDatum(timestamp, this.state.cursorLocation));
            }
            const feature = {
                ...this.props.features[this.state.movingFeature],
                heading: this.state.cursorOrientation,
                location: this.state.cursorLocation,
                routePosition: elr,
                rawRoutePosition: [elr.system, elr.route, elr.position, elr.track],
                options: this.state.placingFeatureOptions,
            };
            this.props.dispatch(featureOverlayUpdateFeature(this.state.movingFeature, feature));

            this.setState({
                placeFeatureMode: 0,
                placingFeatureType: null,
                placingFeatureOptions: {},
                cursorOrientation: 0,
                cursorLocation: [0, 0],
                selectedFeature: this.state.movingFeature,
                movingFeature: null,
            });
        } else {
            const newFeatureIndex = this.props.features.length;

            this.props.dispatch(
                featureOverlayAddFeature({
                    type: this.state.placingFeatureType,
                    heading: this.state.cursorOrientation,
                    location: this.state.cursorLocation,
                    routePosition: elr,
                    rawRoutePosition: [elr.system, elr.route, elr.position, elr.track],
                    options: this.state.placingFeatureOptions,
                    timestamp: Math.round(this.props.timestamp),
                    session_ts: Math.round(this.props.timestamp),
                    session_id: this.props.routeID,
                    is_current_users: true,
                    private: this.props.privateByDefault,
                    owner_name: this.props.user_name,
                }),
            );

            this.setState({
                placeFeatureMode: 0,
                placingFeatureType: null,
                placingFeatureOptions: {},
                cursorOrientation: 0,
                cursorLocation: [0, 0],
                selectedFeature: newFeatureIndex,
                movingFeature: null,
            });
        }
    };

    placeFlagAtLocation = () => {
        const currentTimestamp = videoKeyToTimestamp(this.props.videoKey);
        const elr = calculateRouteCoordinatesForLocation(currentTimestamp, this.props.routeLocationData, this.props.routeLocationSystemID);

        this.props.dispatch(
            featureOverlayAddFeature({
                type: "flag",
                heading: (180 * this.props.heading) / Math.PI,
                location: this.props.location,
                routePosition: elr,
                options: { color: "#ff0000" },
                rawRoutePosition: [elr.system, elr.route, elr.position, elr.track],
                timestamp: Math.round(this.props.timestamp),
                session_ts: Math.round(this.props.timestamp),
                session_id: this.props.routeID,
                is_current_users: true,
                private: this.props.privateByDefault,
                owner_name: this.props.user_name,
            }),
        );
    };

    placeFlag = () => {
        this.setState({
            placingFeatureType: "flag",
            placingFeatureOptions: { color: "#ff0000" },
            cursorLocation: [0, 0],
            placeFeatureMode: 1,
            cursorOrientation: 0,
        });
    };

    placeSignal = () => {
        this.setState({
            placingFeatureType: "signal",
            placingFeatureOptions: { lit: null, height: 2500 },
            cursorLocation: [0, 0],
            placeFeatureMode: 1,
            cursorOrientation: 0,
        });
    };

    clearAll = () => {
        this.props.dispatch(featureOverlaySetDatum(null, null));
        this.props.dispatch(featureOverlayClearFeatures());
        this.cancel();
    };

    cancel = () => {
        this.setState({
            placeFeatureMode: 0,
            placingFeatureType: null,
            placingFeatureOptions: {},
            cursorOrientation: 0,
            cursorLocation: [0, 0],
            selectedFeature: null,
            movingFeature: null,
        });
    };

    setAsDatum = () => {
        let coordinates = this.props.features[this.state.selectedFeature].location;
        const sourceIndex = this.props.sourceIndex;
        const playlist = this.props.playlists[sourceIndex];
        const timestamp = coordinateToTimestamp(coordinates, playlist, this.props.useSnapped, this.props.offsets, true) * 1000;

        this.props.dispatch(featureOverlaySetDatum(timestamp, this.props.features[this.state.selectedFeature].location));
        this.cancel();
    };

    editFeatureDescription = () => {
        let existingDescription = this.props.features[this.state.selectedFeature].description;
        if (!existingDescription) {
            existingDescription = "";
        }
        this.setState({
            editingFeatureLabel: existingDescription,
        });
    };

    setFeatureOptions = (evt) => {
        const options = _.reduce(
            evt,
            (memo, optStr) => {
                return {
                    ...memo,
                    ...JSON.parse(optStr),
                };
            },
            _.get(this.props.features, [this.state.selectedFeature, "options"], {}),
        );

        const feature = {
            ...this.props.features[this.state.selectedFeature],
            options,
        };
        this.props.dispatch(featureOverlayUpdateFeature(this.state.selectedFeature, feature));
    };

    confirmEditFeatureDescription = (description) => {
        const feature = {
            ...this.props.features[this.state.selectedFeature],
            description,
        };
        this.props.dispatch(featureOverlayUpdateFeature(this.state.selectedFeature, feature));
        this.setState({
            editingFeatureLabel: false,
        });
        return Promise.resolve();
    };

    cancelEditFeatureDescription = () => {
        this.setState({
            editingFeatureLabel: false,
        });
    };

    moveFeature = () => {
        const feature = this.props.features[this.state.selectedFeature];
        this.setState({
            movingFeature: this.state.selectedFeature,
            placingFeatureType: feature.type,
            placingFeatureOptions: feature.options,
            cursorLocation: feature.location,
            cursorOrientation: feature.heading,
            placeFeatureMode: 1,
        });
    };

    deleteFeature = () => {
        const location = this.props.features[this.state.selectedFeature].location;
        if (locationsAreEqual(location, this.props.datumPosition)) {
            this.props.dispatch(featureOverlaySetDatum(null, null));
        }
        this.props.dispatch(featureOverlayRemoveFeature(this.state.selectedFeature));
        this.cancel();
    };

    toggleFeaturePrivacy = () => {
        console.log("debug toggling feature privacy");
        const currentFeature = this.props.features[this.state.selectedFeature];

        const feature = {
            ...currentFeature,
            private: !currentFeature.private,
        };
        this.props.dispatch(featureOverlayUpdateFeature(this.state.selectedFeature, feature));
    };

    render() {
        const aspectRatio = this.props.calibration.aspectRatio || 1;

        let menuOptions;
        if (this.state.placeFeatureMode === 0 && this.state.selectedFeature === null) {
            // Default controls
            menuOptions = (
                <>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.placeFlagAtLocation}>
                            <FontAwesomeIcon icon={faLocationCircle} /> Place Flag at Current Position
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.placeFlag}>
                            <FontAwesomeIcon icon={faPennant} /> Place Flag
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.placeSignal}>
                            <FontAwesomeIcon icon={faTrafficLight} /> Place Signal
                        </span>
                    </div>
                    <span className={"AnnotationIconSpacer"} />
                    <div className="AnnotationIconOuter">
                        <span
                            className="AnnotationIcon AnnotationIconPadding"
                            onClick={() => this.props.dispatch(toggleSnapshot())}>
                            <FontAwesomeIcon icon={faCamera} />
                            Snapshot
                        </span>
                    </div>
                </>
            );
        } else if (this.state.placeFeatureMode === 0) {
            const selectedFeature = _.get(this.props.features, [this.state.selectedFeature]);
            const featureType = _.get(selectedFeature, ["type"]);
            let featureOptions = null;
            const selectedOptions = _.map(_.get(this.props.features, [this.state.selectedFeature, "options"], {}), (v, k) => {
                return JSON.stringify(Object.fromEntries([[k, v]]));
            });

            if (featureType === "flag") {
                featureOptions = FlagOptions;
            } else if (featureType === "signal") {
                featureOptions = SignalOptions;
            }

            // Selected object controls
            menuOptions = (
                <>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.editFeatureDescription}>
                            <FontAwesomeIcon icon={faCommentAltEdit} /> Add/Edit Label
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <TreeSelect
                            value={selectedOptions}
                            treeCheckable={true}
                            treeData={featureOptions}
                            treeDefaultExpandAll={true}
                            onChange={this.setFeatureOptions}
                        />
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.setAsDatum}>
                            <FontAwesomeIcon icon={faDotCircle} /> Set as Datum
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.moveFeature}>
                            <FontAwesomeIcon icon={faArrowsAlt} /> Reposition
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.deleteFeature}>
                            <FontAwesomeIcon icon={faTimes} /> Delete
                        </span>
                    </div>

                    {selectedFeature && (
                        <div className="AnnotationIconOuter">
                            <span
                                className={"AnnotationIcon AnnotationIconPadding"}
                                onClick={this.toggleFeaturePrivacy}>
                                <FontAwesomeIcon icon={selectedFeature.private ? faLock : faLockOpen} />
                                {selectedFeature.private ? "Private" : "Public"}
                            </span>
                        </div>
                    )}
                    <span className={"AnnotationIconSpacer"} />
                </>
            );
        } else {
            // New feature placement options
            menuOptions = (
                <div className="AnnotationIconOuter">
                    {this.state.placeFeatureMode === -1 && (
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.confirmPlaceFeature}>
                            <FontAwesomeIcon icon={faCheck} /> Confirm
                        </span>
                    )}
                    {this.state.placeFeatureMode === -1 && (
                        <span
                            className={"AnnotationIcon AnnotationIconPadding"}
                            onClick={this.unconfirmPlaceFeature}>
                            <FontAwesomeIcon icon={faArrowsAlt} /> Reposition
                        </span>
                    )}
                    <span className={"AnnotationIconSpacer"} />
                    <span
                        className={"AnnotationIcon AnnotationIconPadding"}
                        onClick={this.cancel}>
                        <FontAwesomeIcon icon={faTimes} /> Cancel
                    </span>
                </div>
            );
        }

        let cursorTooltip = "Mouse over a feature to highlight";

        if (this.state.placeFeatureMode === 1) {
            cursorTooltip = "Click to place feature at cursor location";
        } else if (this.state.placeFeatureMode === 2) {
            cursorTooltip = "Click to orient towards cursor location";
        } else if (this.state.placeFeatureMode === -1) {
            cursorTooltip = "Confirm placement using bottom menu";
        } else if (this.state.highlightedFeature !== null && this.state.highlightedFeature === this.state.selectedFeature) {
            cursorTooltip = "Click to deselect feature";
        } else if (this.state.highlightedFeature !== null) {
            cursorTooltip = "Click to select feature";
        } else if (this.state.selectedFeature !== null) {
            cursorTooltip = "Click to deselect feature";
        }

        return (
            <div className="AnnotationFrame">
                <InputModal
                    title={"Enter Label"}
                    onCancel={this.cancelEditFeatureDescription}
                    visible={this.state.editingFeatureLabel !== false}
                    initialValue={this.state.editingFeatureLabel}
                    confirmText={"Update"}
                    onConfirm={this.confirmEditFeatureDescription}
                />
                <div className="TopToolbar TopToolbar--annotation">
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon" + (this.isPreviousDisabled ? " Disabled" : "")}
                            onClick={this.navigatePrevious}>
                            <FontAwesomeIcon icon={faStepBackward} /> Previous Image
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon" + (this.isNextDisabled ? " Disabled" : "")}
                            onClick={this.navigateNext}>
                            <FontAwesomeIcon icon={faStepForward} /> Next Image
                        </span>
                    </div>
                    <div className="AnnotationIconOuter">
                        <span
                            className={"AnnotationIcon"}
                            onClick={this.close}>
                            <FontAwesomeIcon icon={faTimes} /> Close
                        </span>
                    </div>
                </div>
                <div className="AnnotationImageFrame">
                    <div className={"FullScreenIconOverlay Top Right"}>
                        <div
                            className="Image-Fullscreen-Icon"
                            onClick={() => this.props.dispatch(toggleFullscreen())}>
                            <FontAwesomeIcon icon={this.props.fullscreen ? faCompress : faExpand} />
                        </div>
                    </div>
                    {this.state.imageData ? (
                        <WithAspectRatio ratio={1 / aspectRatio}>
                            <img
                                className="featurePlacementImage"
                                src={this.state.imageData}
                                alt="Placeholder"
                                onClick={this.closePopups}
                                crossOrigin={"anonymous"}
                            />
                            <Tippy
                                content={cursorTooltip}
                                arrow={false}
                                hideOnClick={false}
                                theme={"aivr"}
                                placement={"bottom-start"}
                                followCursor={true}
                                plugins={[followCursor]}>
                                <div
                                    className="ThreeDFeatureOverlayContainer"
                                    ref={this.canvas}
                                    onMouseMove={this.mouseMove}
                                    onClick={this.mouseClick}>
                                    <ThreeDFeatureOverlay
                                        showRails={true}
                                        cursorLocation={this.state.cursorLocation}
                                        showGPSCorrection={this.props.showGPSCorrection}
                                        cursorOrientation={this.state.cursorOrientation}
                                        cursorType={this.state.placingFeatureType}
                                        cursorOptions={this.state.placingFeatureOptions}
                                        highlightedFeature={this.state.placeFeatureMode === 0 ? this.state.highlightedFeature : null}
                                        selectedFeature={this.state.placeFeatureMode === 0 ? this.state.selectedFeature : null}
                                        hiddenFeature={this.state.movingFeature}
                                    />
                                </div>
                            </Tippy>
                        </WithAspectRatio>
                    ) : (
                        <div className="featurePlacementImage">
                            <OBCSpinner colorScheme="mono" />
                        </div>
                    )}
                </div>
                <div className="BottomToolbar BottomToolbar--annotation">{menuOptions}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);

    const playlistIndex = state.playlist.position.currentIndex;
    const timeOffset = state.playlist.position.currentTimeOffset || 0;
    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }
    const use_snapped = state.snappedRoute || false;
    const currentIndex = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset;

    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;
    const timestamp = getAbsoluteTimestamp(state.playlist.data.routeID, video, currentIndex, true, offset);

    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }

    return {
        baseURL: _.get(state.playlist.data, ["mpdURLs", `snapshots`]),
        position: state.playlist.position.currentIndex,
        location: state.playlist.position.coords,
        heading: getHeading(video, playlistIndex, timeOffset, offsets, use_snapped, sessionIsBackward),
        useSnapped: use_snapped,
        offsets,
        timeOffset: state.playlist.position.currentTimeOffset,
        calibration: get_source_calibration(state.measurement.calibration, state.playlist.position.sourceIndex, timestamp * 1000, true, defaultRailSeparation),
        timestamp,
        video,
        routeLocationData: state.playlist.data.route_locations,
        supportedCoordinateSystems: state.routeCoordinateSystems,
        features: overlayFeaturesSelector(state),
        datumPosition: datumPositionSelector(state),
        videoKey: getCurrentVideoKey(state.playlist),
        currentIndex,
        offset,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        sourceIndex: state.playlist.position.sourceIndex,
        fullscreen: state.fullscreen,
        showGPSCorrection: !!state.userDetails.userConfig.super_admin,
        routeID: state.playlist.data.routeID,
        playlists: state.playlist.data.video,
        routeLocationSystemID: state.playlist.data.system_id,
        csrfToken: state.csrfToken,
        privateByDefault: _.get(state.userDetails.userConfig, ["default_content_privacy"], false),
        user_name: state.userDetails.userConfig.name,
    };
};

export default connect(mapStateToProps)(FeaturePlacementInterface);
