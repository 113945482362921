import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { gpsTimeOffsets, persistGPSOffsets } from "../../redux/actions/index";
import { getCurrentPlaylistTS } from "../util/PlaylistUtils";
import { Button, InputNumber } from "antd";
import moment from "moment";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, ReferenceDot, Label } from "recharts";
import { scaleLinear } from "d3";
import { DeleteOutlined } from "@ant-design/icons";

class RouteTimeOffset extends React.PureComponent {
    constructor(props) {
        super(props);

        this.xScale = scaleLinear();
        this.yScale = scaleLinear().domain([-10, 10]);

        this.state = {
            changesMade: false,
            adjustingTS: null,
            selectedTS: null,
        };
    }

    updateOffset = (sourceIndex, timestamp, value) => {
        let newOffsets = _.cloneDeep(this.props.offsets);

        let index = _.findIndex(newOffsets[sourceIndex], (i) => i[0] === this.state.adjustingTS);
        newOffsets[sourceIndex][index][0] = timestamp;
        newOffsets[sourceIndex][index][1] = value;
        newOffsets[sourceIndex] = _.sortBy(newOffsets[sourceIndex], (i) => i[0]);
        this.props.dispatch(gpsTimeOffsets(this.props.routeID, newOffsets));
        this.setState({
            changesMade: true,
            adjustingTS: timestamp,
            selectedTS: timestamp,
        });
    };

    updateSelectedOffset = (value) => {
        if (this.state.selectedTS !== null) {
            let newOffsets = _.cloneDeep(this.props.offsets);
            let index = _.findIndex(newOffsets[this.props.sourceIndex], (i) => i[0] === this.state.selectedTS);
            newOffsets[this.props.sourceIndex][index][1] = value;
            newOffsets[this.props.sourceIndex] = _.sortBy(newOffsets[this.props.sourceIndex], (i) => i[0]);
            this.props.dispatch(gpsTimeOffsets(this.props.routeID, newOffsets));
            this.setState({
                changesMade: true,
            });
        }
    };

    addOffset = () => {
        let newOffsets = _.cloneDeep(this.props.offsets);
        while (newOffsets.length <= this.props.sourceIndex) {
            newOffsets.push([]);
        }
        newOffsets[this.props.sourceIndex].push([this.props.timestamp, 0, 0]);
        newOffsets[this.props.sourceIndex] = _.sortBy(newOffsets[this.props.sourceIndex], (i) => i[0]);
        this.props.dispatch(gpsTimeOffsets(this.props.routeID, newOffsets));
        this.setState({
            changesMade: true,
            selectedTS: this.props.timestamp,
            adjustingTS: null,
        });
    };

    renderTimestamp = (ts) => {
        return moment.utc(ts * 1000).format("HH:mm:ss");
    };

    renderOffset = (offset) => {
        if (offset < 0) {
            return `${offset}s`;
        } else {
            return `+${offset}s`;
        }
    };

    deleteOffset = () => {
        let newOffsets = _.cloneDeep(this.props.offsets);
        while (newOffsets.length <= this.props.sourceIndex) {
            newOffsets.push([]);
        }
        let index = _.findIndex(newOffsets[this.props.sourceIndex], (data) => data[0] === this.state.selectedTS);
        newOffsets[this.props.sourceIndex].splice(index, 1);
        this.props.dispatch(gpsTimeOffsets(this.props.routeID, newOffsets));
        this.setState({
            changesMade: true,
            selectedTS: null,
            adjustingTS: null,
        });
    };

    reset = () => {
        this.props.dispatch(gpsTimeOffsets(this.props.routeID, this.props.originalOffsets));
        this.setState({
            changesMade: false,
            selectedTS: null,
            adjustingTS: null,
        });
    };

    save = () => {
        this.props.dispatch(persistGPSOffsets(this.props.routeID, this.props.offsets));
        this.setState({
            changesMade: false,
            selectedTS: null,
            adjustingTS: null,
        });
    };

    startAdjustingOffset = (ts) => {
        this.setState({
            adjustingTS: ts,
            selectedTS: ts,
        });
    };

    stopAdjustingOffset = () => {
        this.setState({
            adjustingTS: null,
        });
    };

    updateAdjustingOffset = (position) => {
        if (this.state.adjustingTS !== null && position.chartX && position.chartY) {
            let newTimestamp = Math.round(this.xScale.invert(position.chartX));
            let newOffsetValue = Math.round(this.yScale.invert(position.chartY) * 10) / 10;
            if (!_.isNaN(newOffsetValue)) {
                this.updateOffset(this.props.sourceIndex, newTimestamp, newOffsetValue);
            }
        }
    };

    render() {
        let lineData = _.map(this.props.offsets[this.props.sourceIndex], (data) => ({
            ts: data[0],
            offset: data[1],
            editable: true,
        }));

        if (lineData.length === 0) {
            lineData = [
                {
                    ts: 0,
                    offset: 0,
                    editable: false,
                },
                {
                    ts: this.props.sessionDuration,
                    offset: 0,
                    editable: false,
                },
            ];
        } else {
            if (lineData[0].ts > 0) {
                lineData.unshift({
                    ts: 0,
                    offset: lineData[0].offset,
                    editable: false,
                });
            }
            if (lineData[lineData.length - 1].ts < this.props.sessionDuration) {
                lineData.push({
                    ts: this.props.sessionDuration,
                    offset: lineData[lineData.length - 1].offset,
                    editable: false,
                });
            }
        }

        const referenceDots = lineData
            .filter((data) => data.editable)
            .map((data, idx) => (
                <ReferenceDot
                    key={`offset${idx}`}
                    x={data.ts}
                    y={data.offset}
                    r={6}
                    fill={this.state.selectedTS === data.ts ? "#80ff80" : "#ffffff"}
                    stroke="#8884d8"
                    inFront={this.state.adjustingTS === data.ts}
                    cursor={"pointer"}
                    onMouseDown={() => this.startAdjustingOffset(data.ts)}
                    onMouseUp={this.stopAdjustingOffset}>
                    <Label
                        content={<DeleteOutlined className="icon" />}
                        position="right"
                    />
                    <Label
                        value={this.renderOffset(data.offset)}
                        position={data.offset > 0 ? "bottom" : "top"}
                        fill={"#ffffff"}
                        stroke={"#ffffff"}
                    />
                </ReferenceDot>
            ));

        const selectedTS = _.find(this.props.offsets[this.props.sourceIndex], (data) => data[0] === this.state.selectedTS);

        return (
            <div className="contentTabDiv gpsAdjustmentTab">
                <div className="gpsChartDiv">
                    <ResponsiveContainer>
                        <LineChart
                            key={this.props.routeID}
                            data={lineData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            onMouseMove={this.updateAdjustingOffset}
                            onMouseUp={this.stopAdjustingOffset}
                            onMouseLeave={this.stopAdjustingOffset}>
                            <Line
                                type="linear"
                                dataKey="offset"
                                stroke="#8884d8"
                                dot={false}
                                activeDot={false}
                            />
                            {referenceDots}
                            <XAxis
                                dataKey="ts"
                                type="number"
                                allowDecimals={false}
                                scale={this.xScale}
                                domain={[0, this.props.sessionDuration]}
                                tickFormatter={this.renderTimestamp}
                            />
                            <YAxis
                                type="number"
                                domain={[-10, 10]}
                                scale={this.yScale}
                                tickFormatter={this.renderOffset}
                            />
                            <Tooltip
                                cursor={{ strokeDasharray: "3 3" }}
                                formatter={this.renderOffset}
                                labelFormatter={this.renderTimestamp}
                                labelStyle={{ color: "#000000" }}
                                itemStyle={{ color: "#000000" }}
                            />
                            <ReferenceLine
                                x={this.props.timestamp}
                                stroke="green"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="gpsChartButtons">
                    <Button onClick={this.addOffset}>Add Offset</Button>
                    <InputNumber
                        disabled={selectedTS === undefined}
                        className={"offset-number"}
                        value={_.get(selectedTS, [1], 0)}
                        formatter={(value) => (value >= 0 ? "+" : "") + `${value}s`}
                        parser={(value) => value.replaceAll(/[^0-9-.]/g, "")}
                        onChange={this.updateSelectedOffset}
                        min={-10}
                        max={10}
                        step={0.1}
                    />
                    <Button
                        type="danger"
                        onClick={this.deleteOffset}
                        disabled={this.state.selectedTS === null}>
                        Delete Selected Offset
                    </Button>
                    <Button
                        onClick={this.reset}
                        disabled={!this.state.changesMade}>
                        Reset
                    </Button>
                    <Button
                        type="default"
                        onClick={this.save}
                        disabled={!this.state.changesMade}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    let video = _.get(state.playlist.data, ["video", sourceIndex], []);

    const offsetData = state.gpsTimeOffsets;
    let offsets = [[]];
    let originalOffsets = [[]];
    if (offsetData.sessionID === state.playlist.data.routeID) {
        offsets = offsetData.offsets;
        originalOffsets = offsetData.originalOffsets;
    }

    let sessionDuration = 0;
    if (video.length > 0) {
        sessionDuration = video[video.length - 1][1] + video[video.length - 1][2];
    }

    return {
        position: state.playlist.position,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        routeID: state.playlist.data.routeID,
        offsets,
        originalOffsets,
        timestamp: getCurrentPlaylistTS(state.playlist),
        sessionDuration,
        video,
        sourceIndex,
    };
};

export default connect(mapStateToProps)(RouteTimeOffset);
