import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartesianGrid, Label, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart, Area } from "recharts";
import _ from "lodash";
import { websocketMessage } from "redux/actions/index";
import moment from "moment";
import OBCSpinner from "../util/OBC";

const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "position"], {});
};
const geomHeadersSelector = (state) => state.trackGeometry.trackGeometryHeaders;
const findClosest = (arr, n) => arr.sort((a, b) => Math.abs(a - n) - Math.abs(b - n))[0];

const CustomTooltip = ({ active, payload, dataTypeName, type, dataType }) => {
    if (active && payload && payload.length) {
        let data = payload[0];
        let content = (
            <>
                <p className="label">{`${dataTypeName} : ${data.value}`}</p>
                <p className="label">{`Speed : ${(data.payload.speed_ms / 0.44704).toFixed(2)}(mph)`}</p>
                <p className="label">{`Date : ${moment.unix(data.payload.timestamp / 1000).format("DD/MM/YY HH:mm:ss")}`}</p>
            </>
        );

        if (type === "area") {
            data = payload[0].payload;
            content = (
                <>
                    <p className="label">{`${dataTypeName} Max: ${data[dataType][1]}`}</p>
                    <p className="label">{`${dataTypeName} Min: ${data[dataType][0]}`}</p>
                    <p className="label">{`Speed : ${(data.speed_ms / 0.44704).toFixed(2)}(mph)`}</p>
                    <p className="label">{`Date : ${moment.unix(data.timestamp / 1000).format("DD/MM/YY HH:mm:ss")}`}</p>
                </>
            );
        }
        return <div className="ChartTooltip">{content}</div>;
    }
    return null;
};

const DistanceAxis = ({ x, y, chunkedData, show, chartTickIndexes, index }) => {
    if (!show) {
        return null;
    }

    const datapointLocation = _.get(chunkedData, [chartTickIndexes[index], "location"], null);
    let text = "N/A";
    if (datapointLocation && !isNaN(datapointLocation.mile) && !isNaN(datapointLocation.yard)) {
        text = `${datapointLocation.mile}m.${Math.round(parseFloat(datapointLocation.yard))}y`;
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={12}
                textAnchor="end"
                fill="white"
                fontSize={12}>
                {text}
            </text>
        </g>
    );
};

const ELRXAxis = ({ x, y, chunkedData, show, chartTickIndexes, index }) => {
    if (!show) {
        return null;
    }

    let datapointLocation = _.get(chunkedData, [chartTickIndexes[index], "location"], null);
    let text = "N/A";
    if (datapointLocation && datapointLocation.elr && datapointLocation.subposition) {
        text = `${datapointLocation.elr} ${datapointLocation.track}`;
    }

    return (
        <g transform={`translate(${x},${y - 15})`}>
            <text
                x={0}
                y={0}
                dy={12}
                textAnchor="end"
                fill="white"
                fontSize={12}>
                {text}
            </text>
        </g>
    );
};

const CustomYTick = ({ x, y, payload, index }) => {
    let colour = "#666";
    if (index === 0 || index === 4) {
        colour = "white";
    }
    return (
        <g transform={`translate(${x},${y - 7})`}>
            <text
                x={0}
                y={0}
                dy={12}
                textAnchor="end"
                fill={colour}
                fontSize={12}>
                {payload.value}
            </text>
        </g>
    );
};

const Chart = ({ dataType, setClickedTimestamp, clickedTimestamp, chartType, loading, first, last, chunkedData }) => {
    const dashboardData = useSelector(dashboardDataSelector);
    const geomHeaders = useSelector(geomHeadersSelector);
    const dispatch = useDispatch();

    const currentDashboardTimestamp = useMemo(() => {
        const timestamp = _.get(dashboardData, ["timestamp"], 0);
        return timestamp;
    }, [dashboardData]);

    const nearestDatapointIndex = useMemo(() => {
        const videoTimestamp = currentDashboardTimestamp;
        let sortedData = _.orderBy(chunkedData, "timestamp");
        let chunkedDatapointTimestamps = _.map(sortedData, "timestamp");
        let closest = null;
        if (clickedTimestamp) {
            closest = findClosest(_.clone(chunkedDatapointTimestamps), clickedTimestamp);
        } else {
            closest = findClosest(_.clone(chunkedDatapointTimestamps), videoTimestamp);
        }
        let closestIndex = chunkedDatapointTimestamps.indexOf(closest);
        return closestIndex;
    }, [currentDashboardTimestamp, chunkedData, clickedTimestamp]);

    const onChartClick = useCallback(
        (e) => {
            const timestampClicked = _.get(e, ["activePayload", 0, "payload", "timestamp"], 0);
            if (!timestampClicked) {
                return;
            }

            setClickedTimestamp(timestampClicked);
            dispatch(
                websocketMessage({
                    action: "request_position",
                    timestamp: timestampClicked,
                }),
            );
        },
        [dispatch, setClickedTimestamp],
    );

    const chartHeader = useMemo(() => {
        const header = _.find(geomHeaders, { header_name: dataType });
        return header;
    }, [geomHeaders, dataType]);

    const yLabel = useMemo(() => {
        let name = _.get(chartHeader, ["info", "display_name"], chartHeader.name);
        return `${name}`;
    }, [chartHeader]);

    const chartDomain = useMemo(() => {
        let domain = ["dataMin", "dataMax"];
        if (chartHeader.info.min && chartHeader.info.max) {
            domain = [parseFloat(chartHeader.info.min), parseFloat(chartHeader.info.max)];
        }
        return domain;
    }, [chartHeader.info.max, chartHeader.info.min]);

    const refLine = useMemo(() => {
        const refLineTachoCount = _.get(chunkedData, [nearestDatapointIndex, "tacho"], 0);
        return (
            <ReferenceLine
                x={refLineTachoCount}
                stroke="red"
                ifOverflow
            />
        );
    }, [chunkedData, nearestDatapointIndex]);

    const chartTicksY = useMemo(() => {
        if (!_.get(chartHeader, ["info", "min"], false) || !_.get(chartHeader, ["info", "max"], false)) {
            return null;
        }
        const min = chartHeader.info.min;
        const max = chartHeader.info.max;

        const midPoint = (max + min) / 2;

        const upperMid = (max + midPoint) / 2;
        const lowerMid = (midPoint + min) / 2;

        const ticks = [max.toFixed(2), upperMid.toFixed(2), midPoint.toFixed(2), lowerMid.toFixed(2), min.toFixed(2)];
        return ticks;
    }, [chartHeader]);

    const chartTickIndexes = useMemo(() => {
        const totalLength = chunkedData.length;
        return [0, Math.round(totalLength / 4), Math.round(totalLength / 2), Math.round((totalLength / 4) * 3), totalLength - 1];
    }, [chunkedData.length]);

    const chartTicksX = useMemo(() => {
        const start = chunkedData[chartTickIndexes[0]];
        const quarter = chunkedData[chartTickIndexes[1]];
        const half = chunkedData[chartTickIndexes[2]];
        const threeQuarter = chunkedData[chartTickIndexes[3]];
        const end = chunkedData[chartTickIndexes[4]];

        const ticks = [start?.tacho, quarter?.tacho, half?.tacho, threeQuarter?.tacho, end?.tacho];
        return ticks;
    }, [chunkedData, chartTickIndexes]);

    const xAxis = useMemo(() => {
        if (first) {
            return (
                <ELRXAxis
                    chunkedData={chunkedData}
                    show={first || last}
                    chartType={chartType}
                    chartTickIndexes={chartTickIndexes}
                />
            );
        } else {
            return (
                <DistanceAxis
                    chunkedData={chunkedData}
                    show={first || last}
                    chartTickIndexes={chartTickIndexes}
                />
            );
        }
    }, [first, last, chunkedData, chartType, chartTickIndexes]);

    const chart = useMemo(() => {
        if (chartType === "area") {
            return (
                <ComposedChart
                    height={200}
                    data={chunkedData}
                    syncId="Charts"
                    margin={{
                        left: 25,
                        right: 10,
                        top: 10,
                        bottom: 10,
                    }}
                    onClick={onChartClick}>
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis
                        dataKey="tacho"
                        tick={xAxis}
                        ticks={chartTicksX}
                        orientation={first ? "top" : "bottom"}
                        height={55}
                        label=""
                    />
                    <YAxis
                        interval={0}
                        ticks={chartTicksY}
                        tick={<CustomYTick />}
                        domain={chartDomain}
                        allowDataOverflow={true}
                        label={
                            <Label
                                value={yLabel}
                                angle="-90"
                                position="insideBottomLeft"
                                fill={"#8884d8"}
                            />
                        }
                    />

                    {loading && (
                        <foreignObject
                            x={"51.5%"}
                            y={first ? "50%" : "20%"}
                            width={100}
                            height={100}>
                            <OBCSpinner
                                colorScheme="mono"
                                size={50}
                                height={"50px"}
                            />
                        </foreignObject>
                    )}
                    <Line
                        isAnimationActive={false}
                        dot={false}
                        type="linear"
                        dataKey={"average"}
                        stroke="white"
                        activeDot={{ r: 1 }}
                        legendType="none"
                    />
                    <Area
                        dataKey={dataType}
                        stroke="#756ffc"
                        fill="#8884d8"
                        isAnimationActive={false}
                        dot={false}
                        activeDot={{ r: 1 }}
                    />
                    {refLine}
                    <Tooltip
                        content={
                            <CustomTooltip
                                dataType={dataType}
                                dataTypeName={yLabel}
                                type={chartType}
                            />
                        }
                    />
                </ComposedChart>
            );
        } else if (chartType === "minMax" || chartType === "average") {
            return (
                <LineChart
                    onClick={onChartClick}
                    height={200}
                    data={chunkedData}
                    syncId="Charts"
                    margin={{
                        left: 25,
                        right: 10,
                        top: 10,
                        bottom: 10,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="tacho"
                        tick={xAxis}
                        ticks={chartTicksX}
                        orientation={first ? "top" : "bottom"}
                        interval={0}
                        height={55}
                    />
                    <YAxis
                        interval={0}
                        ticks={chartTicksY}
                        tick={<CustomYTick />}
                        domain={chartDomain}
                        allowDataOverflow={true}
                        label={
                            <Label
                                value={yLabel}
                                angle="-90"
                                position="insideBottomLeft"
                                fill={"#8884d8"}
                            />
                        }
                    />

                    <Tooltip
                        content={
                            <CustomTooltip
                                dataType={dataType}
                                dataTypeName={yLabel}
                                type={chartType}
                            />
                        }
                    />
                    <Legend />
                    {loading && (
                        <foreignObject
                            x={"51.5%"}
                            y={first ? "50%" : "20%"}
                            width={100}
                            height={100}>
                            <OBCSpinner
                                colorScheme="mono"
                                size={50}
                                height={"50px"}
                            />
                        </foreignObject>
                    )}
                    <Line
                        isAnimationActive={false}
                        dot={false}
                        type="linear"
                        dataKey={dataType}
                        stroke="#8884d8"
                        activeDot={{ r: 1 }}
                        legendType="none"
                    />

                    {refLine}
                </LineChart>
            );
        }
    }, [chartType, chartDomain, chunkedData, dataType, onChartClick, refLine, yLabel, loading, chartTicksX, chartTicksY, first, xAxis]);

    const extraClasses = useMemo(() => {
        let classes = [];
        if (first || last) {
            if (first) {
                classes.push("First");
            }
            if (last) {
                classes.push("Last");
            }
        } else {
            classes.push("Middle");
        }

        return classes;
    }, [first, last]);

    return <ResponsiveContainer className={"widget__Chart " + extraClasses.join(" ")}>{chart}</ResponsiveContainer>;
};

export default Chart;
