import { Button, Checkbox, Input, notification, Tooltip } from "antd";
import ELRMileAndChain from "components/util/ELRMileAndChain";
import { calculateRouteCoordinatesForLocation } from "components/util/Geometry";
import { filterMarkers } from "components/util/PlaylistUtils";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    customAudit,
    exportInspectionDetections,
    resetInspectionDetectionsExportIndexRange,
    setInspectionDetectionsExportIndexRangeValue,
    toggleInspectionDetectionsExport,
} from "redux/actions";

const userEmailSelector = (state) => state.userDetails.email;
const railInspectionImagesSelector = (state) => state.railInspection.railInspectionImages.data;
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const viewsSelector = (state) => state.views;
const dashboardsSelector = (state) => state.dashboards;
const userDetailsSelector = (state) => state.userDetails;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const detectionsTypeFiltersSelector = (state) => state.railInspection.detections.typeFilters;
const detectionsReviewFiltersSelector = (state) => state.railInspection.detections.reviewFilters;
const sessionIDSelector = (state) => state.playlist.data.routeID;
const detectionsDataSelector = (state) => state.railInspection.detections.data;
const detectionsConditionFiltersSelector = (state) => state.railInspection.detections.detectionConditionFilters || {};
const dipAngleFilterSelector = (state) => state.railInspection.dipAngleFilter;
const defaultMarkersSelector = (state) => _.get(state.defaultMarkersThresholds, [state.playlist.data.routeID], {});
const detectionsExportIndexRangeSelector = (state) => state.railInspection.detectionsExportIndexRange;

const DetectionsExport = () => {
    const dispatch = useDispatch();

    const userEmail = useSelector(userEmailSelector);
    const railInspectionImages = useSelector(railInspectionImagesSelector);
    const selectedRailInspectionImage = useSelector(selectedRailInspectionImageSelector);
    const views = useSelector(viewsSelector);
    const userDetails = useSelector(userDetailsSelector);
    const dashboardID = userDetails.dashboardAccessID;
    const dashboards = useSelector(dashboardsSelector);
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const elrUnits = useSelector(elrUnitsSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const detectionsTypeFilters = useSelector(detectionsTypeFiltersSelector);
    const detectionsReviewFilters = useSelector(detectionsReviewFiltersSelector);
    const sessionID = useSelector(sessionIDSelector);
    const detectionsData = useSelector(detectionsDataSelector);
    const detectionsConditionFilters = useSelector(detectionsConditionFiltersSelector);
    const defaultMarkers = useSelector(defaultMarkersSelector);
    const dipAngleFilter = useSelector(dipAngleFilterSelector);
    const detectionsExportIndexRange = useSelector(detectionsExportIndexRangeSelector);

    const [exportName, setExportName] = useState("");
    const [recipientEmail, setRecipientEmail] = useState(userEmail);
    const [includeImages, setIncludeImages] = useState(true);

    const filteredDetectionsCount = useMemo(() => {
        let filtered = [];

        if (detectionsData && detectionsData.length && detectionsReviewFilters && detectionsTypeFilters && detectionsConditionFilters) {
            filtered = filterMarkers(
                detectionsData,
                detectionsTypeFilters,
                detectionsReviewFilters,
                [],
                [],
                true,
                defaultMarkers,
                false,
                detectionsConditionFilters,
                dipAngleFilter,
            );
        }

        let startTimestamp = null;
        if (detectionsExportIndexRange.start) {
            startTimestamp = railInspectionImages[detectionsExportIndexRange.start].timestamp;
        }
        let endTimestamp = null;
        if (detectionsExportIndexRange.end) {
            endTimestamp = railInspectionImages[detectionsExportIndexRange.end].timestamp;
        }

        return filtered.filter((item) => {
            if (startTimestamp !== null && item.image_timestamp < startTimestamp) {
                return false;
            }

            if (endTimestamp !== null && item.image_timestamp > endTimestamp) {
                return false;
            }

            return true;
        }).length;
    }, [
        defaultMarkers,
        detectionsConditionFilters,
        detectionsData,
        detectionsReviewFilters,
        detectionsTypeFilters,
        dipAngleFilter,
        detectionsExportIndexRange.start,
        detectionsExportIndexRange.end,
        railInspectionImages,
    ]);

    const calculatedELR = useCallback(
        (index) => {
            let timestampToUse = railInspectionImages[index].timestamp;

            if (timestampToUse && routeLocationData) {
                const calculatedELR = calculateRouteCoordinatesForLocation(timestampToUse / 1000, routeLocationData, routeSystemID);
                if (calculatedELR) {
                    return {
                        string: calculatedELR.to_simple_string(elrUnits, datumOffsets),
                        route: calculatedELR.route,
                        track: calculatedELR.track,
                        position: calculatedELR.position,
                    };
                }
            }
        },
        [railInspectionImages, routeLocationData, routeSystemID, elrUnits, datumOffsets],
    );

    const mwvData = useCallback(
        (index) => {
            let mwv = railInspectionImages[index]["mwv"];
            if (mwv && mwv.elr && mwv.mile && mwv.trid && mwv.yard) {
                mwv = ELRMileAndChain.from_fields(
                    "ELR Mile & Chain",
                    "elr_mile_yards",
                    {
                        ELR: mwv.elr,
                        MILE: mwv.mile,
                        TRACK: mwv.trid,
                        YARDS: mwv.yard,
                    },
                    datumOffsets,
                    true,
                );
                return {
                    string: mwv.to_simple_string(elrUnits, datumOffsets),
                    route: mwv.route,
                    track: mwv.track,
                    position: mwv.position,
                };
            }

            return null;
        },
        [datumOffsets, elrUnits, railInspectionImages],
    );

    const formatMileage = useCallback(
        (index) => {
            if (index > -1) {
                let result = mwvData(index);

                if (!result) {
                    result = calculatedELR(index);
                }

                if (result) {
                    return result.string;
                }
            }
        },
        [calculatedELR, mwvData],
    );

    const startLocation = useMemo(() => {
        if (detectionsExportIndexRange.start === null) {
            return formatMileage(0);
        } else {
            return formatMileage(detectionsExportIndexRange.start);
        }
    }, [detectionsExportIndexRange.start, formatMileage]);

    const endLocation = useMemo(() => {
        if (detectionsExportIndexRange.end === null) {
            return formatMileage(railInspectionImages.length - 1);
        } else {
            return formatMileage(detectionsExportIndexRange.end);
        }
    }, [detectionsExportIndexRange.end, formatMileage, railInspectionImages.length]);

    const createExport = () => {
        const detectionFilters = {
            reviewFilters: detectionsReviewFilters,
            typeFilters: detectionsTypeFilters,
            includeImages: includeImages,
        };

        if (!(detectionsExportIndexRange.start === null && detectionsExportIndexRange.end === null)) {
            const startIndex = detectionsExportIndexRange.start || 0;
            const endIndex = detectionsExportIndexRange.end || railInspectionImages.length - 1;

            detectionFilters["lower_bound_ms"] = railInspectionImages[startIndex].timestamp;
            detectionFilters["upper_bound_ms"] = railInspectionImages[endIndex].timestamp;
        }

        const exportPayload = {
            export_name: exportName,
            filters: detectionFilters,
            recipient: recipientEmail,
            session_id: sessionID,
        };

        dispatch(customAudit("inspection_detections_navigator.create_export", exportPayload, `Inspection detections navigator - create export: ${exportName}`));

        dispatch(exportInspectionDetections(exportPayload)).then((success) => {
            if (success) {
                notification.success({
                    message: "Successfully triggered export",
                });
            } else {
                notification.error({
                    message: "Error triggering export",
                });
            }
            dispatch(toggleInspectionDetectionsExport());
            dispatch(resetInspectionDetectionsExportIndexRange());
        });
    };

    return (
        <div className="InspectionDetectionsExport">
            <div className="InspectionDetectionsExport_Body">
                <div>
                    <span>Export Name</span>
                    <Input
                        value={exportName}
                        onChange={(e) => setExportName(e.target.value)}
                    />
                </div>
                <div>
                    <span>Recipient Email</span>
                    <Input
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.target.value)}
                    />
                </div>
                <Checkbox
                    checked={includeImages}
                    onChange={(e) => setIncludeImages(e.target.checked)}>
                    Include images
                </Checkbox>
                <div className="RangeButtons">
                    <div className="RangeButton">
                        <span>Start Position</span>
                        <div>
                            <span>{startLocation}</span>
                            <Tooltip title="Sets the start position to the currently selected location">
                                <Button
                                    type="default"
                                    size="small"
                                    onClick={() => dispatch(setInspectionDetectionsExportIndexRangeValue("start", selectedRailInspectionImage.index))}>
                                    Set to current position
                                </Button>
                            </Tooltip>
                            {detectionsExportIndexRange.start !== null && (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => dispatch(setInspectionDetectionsExportIndexRangeValue("start", null))}>
                                    reset
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="RangeButton">
                        <span>End Position</span>
                        <div>
                            <span>{endLocation}</span>
                            <Tooltip title="Selects the current location as the end position">
                                <Button
                                    type="default"
                                    size="small"
                                    onClick={() => dispatch(setInspectionDetectionsExportIndexRangeValue("end", selectedRailInspectionImage.index))}>
                                    Set to current position
                                </Button>
                            </Tooltip>
                            {detectionsExportIndexRange.end !== null && (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => dispatch(setInspectionDetectionsExportIndexRangeValue("end", null))}>
                                    reset
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ActionButtons">
                <Button
                    onClick={() => {
                        dispatch(toggleInspectionDetectionsExport());
                        dispatch(resetInspectionDetectionsExportIndexRange());
                    }}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={createExport}
                    disabled={exportName === "" || filteredDetectionsCount === 0}>
                    <span>
                        Export <strong>{filteredDetectionsCount}</strong> detections
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default DetectionsExport;
