/**@typedef {Object<string, boolean>} WidgetState*/

import { ADD_WIDGET, CHANGE_WIDGET_STATUS, REMOVE_WIDGET } from "redux/actions/widgetActions";

/**
 *
 * @param {WidgetState} state
 * @param {string} action
 * @returns {WidgetState}
 */
const widgets = (state = {}, action) => {
    switch (action.type) {
        case ADD_WIDGET:
            console.log("ADD WIDGET");
            return {
                ...state,
                [action.widget]: false,
            };

        case REMOVE_WIDGET:
            const { [action.widget]: _, ...newState } = state;
            return newState;

        case CHANGE_WIDGET_STATUS:
            return {
                ...state,
                [action.widget]: action.status,
            };

        default:
            return state;
    }
};

export default widgets;
