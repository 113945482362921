import L from "leaflet";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import RailInspectTileInfo from "../display/image/RailInspectTileInfo";
import React from "react";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

export const DynamicLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    brightness: 1,
    contrast: 1,
    opacity: 1,
    deviceName: "",
    srcWidth: 0,
    imgWidth: 0,
    srcX: 0,
    xOffset: 0,
    yOffset: 0,
    reverse: false,
    constantArrayOffset: 0,
    flipped: false,
    croppingOffset: 0,
    csrfToken: null,
    setup: function (railImages) {
        this.railImages = railImages;
    },
    setCsrfToken: function (token) {
        this.csrfToken = token;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setFlipped: function (flipped) {
        this.flipped = flipped;
    },
    setConstantOffset: function (offset) {
        offset *= -1;
        this.constantArrayOffset = Math.floor(offset);
        this.yOffset = (offset - this.constantArrayOffset) * 1000;
    },
    setCroppingOffset: function (offset) {
        this.croppingOffset = offset;
    },
    setBrightness: function (val) {
        this.brightness = Math.pow(3, val);
    },
    setContrast: function (val) {
        this.contrast = Math.pow(2, val);
    },
    setOpacity: function (val) {
        this.opacity = val;
    },
    setDeviceName: function (val) {
        this.deviceName = val;
    },
    setSrcWidth: function (val) {
        this.srcWidth = val;
    },
    setImgWidth: function (val) {
        this.imgWidth = val;
    },
    setDisplayWidth: function (val) {
        this.displayWidth = val;
    },
    setSrcX: function (val) {
        this.srcX = val;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    isCropped: function (index) {
        if (this.croppingOffset && this.croppingOffset > 0) {
            if (!this.constantArrayOffset) {
                if (index > this.croppingOffset) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            if (this.constantArrayOffset) {
                if (index > Math.abs(this.croppingOffset)) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    },
    createTile: function (coords, done) {
        let coordToCompare = coords.x;
        let factor = this.flipped ? 1 : -1;

        let arrayIndex = factor * coords.y + this.arrayOffset + this.constantArrayOffset;

        let container = document.createElement("div");
        if (coordToCompare === 0 && this.railImages && arrayIndex >= 0 && arrayIndex < this.railImages.length && !this.isCropped(arrayIndex)) {
            setTimeout(() => {
                let tile = L.DomUtil.create("div");

                if (this.railImages[arrayIndex]) {
                    let base_path = this.railImages[arrayIndex].base_path;
                    const new_base_path = base_path.replace("$DEVICE", `${this.deviceName}`);
                    let imagePath = baseURL + new_base_path + "/" + this.railImages[arrayIndex].timestamp + ".jpg";
                    if (this.csrfToken) {
                        imagePath += `?csrf=${this.csrfToken}`;
                    }
                    const backgroundWidth = (this.imgWidth / this.srcWidth) * this.displayWidth;

                    tile.setAttribute(
                        "style",
                        `background: url("${imagePath}") no-repeat -${this.srcX}px 0; background-size: ${backgroundWidth}px 1000px; width: ${this.displayWidth}px; height: 1000px; filter: brightness(${this.brightness}) contrast(${this.contrast}); opacity: ${this.opacity};`,
                    );
                    tile.setAttribute("data-timestamp", this.railImages[arrayIndex].timestamp);

                    const classes = [];

                    // tile.innerHTML = "-------------" + [coords.x, coords.y].join(', ');
                    // tile.style.color = "red";
                    // tile.style.fontSize = "50px";

                    if (this.flipped) {
                        classes.push("patrolFlipped");
                    } else if (this.reverse) {
                        classes.push("patrolReverse");
                    }

                    if (classes.length) {
                        tile.setAttribute("class", classes.join(" "));
                    }
                    container.append(tile);

                    if (this.xOffset === 0) {
                        let greenLine = L.DomUtil.create("div");
                        greenLine.setAttribute("class", "greenLine");
                        container.append(greenLine);
                    }
                    done(null, container);
                }
            }, 0);
        } else {
            done(null, container);
        }

        return container;
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        const newCoords = pos.add([this.xOffset, (this.flipped ? -1 : 1) * this.yOffset]);
        return newCoords;
    },
});

export const InfoLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    store: null,
    zoom: 0,
    flipped: false,
    setup: function (railImages, store, totalWidth = 0, arrowLeft = true) {
        this.railImages = railImages;
        this.store = store;
        this.totalWidth = totalWidth;
        this.arrowLeft = arrowLeft;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setCroppingOffset: function (offset) {
        this.croppingOffset = offset;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setFlipped: function (flipped) {
        this.flipped = flipped;
    },
    isCropped: function (index) {
        if (this.arrayOffset) {
            return false;
        }
        if (index > this.croppingOffset) {
            return false;
        }
        return true;
    },
    createTile: function (coords) {
        let arrayIndex = (this.flipped ? 1 : -1) * coords.y + this.arrayOffset;
        const coordToCompare = coords.x;
        let container = document.createElement("div");

        if (
            (coordToCompare === -1 || (coordToCompare === 0 && !this.arrowLeft)) &&
            this.railImages &&
            arrayIndex >= 0 &&
            arrayIndex < this.railImages.length &&
            arrayIndex % 1 === 0 &&
            !this.isCropped(arrayIndex)
        ) {
            ReactDOM.render(
                <Provider store={this.store}>
                    <RailInspectTileInfo
                        leftArrow={this.arrowLeft}
                        rightArrow={coordToCompare === 0}
                        railImages={this.railImages}
                        arrayIndex={arrayIndex}
                        zoom={this.zoom}
                    />
                </Provider>,
                container,
            );
        }
        return container;
    },
    destroyTile: function (tile) {
        if (tile) {
            ReactDOM.unmountComponentAtNode(tile);
        }
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        if (coords.x === 0) {
            pos = pos.add([this.totalWidth - this._tileSize.x, 0]);
        }
        return pos;
    },
});
