import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import asyncQueueMiddleware from "redux-async-queue";
import rootReducer from "./reducers";
import ReduxQuerySync from "redux-query-sync";
import reduxCookiesMiddleware, { getStateFromCookies } from "redux-cookies-middleware";
import { shareLink } from "./actions/index";
import wsService from "./wsService";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import { calculateBearingText, calculateRouteCoordinatesForLocation, convertSpeed } from "../components/util/Geometry";
import _ from "lodash";
import { calculateFrame, findMostRecentMetadata, getAbsoluteTimestamp } from "../components/util/PlaylistUtils";
import windowManager from "./windowManager";

// state to persist in cookies

let firstSessionCheck = true;
const cookiePaths = {
    userPreferences: {
        name: "aivr_preferences",
    },
    customAnnotationLabels: {
        name: "customAnnotationLabels",
    },
    requestedDesktop: {
        name: "requested_desktop",
    },
    "issues.filters.group": {
        name: "aivr_issue_filter_group",
    },
    "issues.filters.status": {
        name: "aivr_issue_filter_status",
    },
    "issues.filters.priority": {
        name: "aivr_issue_filter_priority",
    },
};

// We dont want to read access tokens from cookie when in sidekick
if (!window.location.pathname.startsWith("/widget")) {
    cookiePaths["access_token"] = {
        name: "workspace_key",
        equalityCheck: (a, b) => {
            if (a !== b) {
                return false;
            } else if (b && firstSessionCheck) {
                firstSessionCheck = false;
                return false;
            } else {
                return true;
            }
        },
    };
}

const setCookie = (name, value) => {
    Cookies.set(name, value, { secure: true, sameSite: "Strict" });
};

const cookieOptions = {
    setCookie,
};

const speedAndBearingSelector = (state) => _.get(state.routeMetadata, ["SPEED_AND_BEARING", state.playlist.data.routeID], []);
const tachoDataSelector = (state) => _.get(state.routeMetadata, ["TACHO_DATA", state.playlist.data.routeID], []);

const handleLocationUpdate = _.throttle((state, action, parent) => {
    const currentIndex = action.index;
    const latLonCoords = action.coords;
    const currentTimeOffset = action.offset;
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const routeSystemID = state.playlist.data.system_id;
    const playingPlaylist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
    let absoluteTimestamp = getAbsoluteTimestamp(routeID, playingPlaylist, currentIndex, isVideo, currentTimeOffset);
    const routeLocationData = state.playlist.data.route_locations;
    const elr = calculateRouteCoordinatesForLocation(absoluteTimestamp, routeLocationData, routeSystemID);
    const speedMetadata = speedAndBearingSelector(state);
    const tachoMetadata = tachoDataSelector(state);
    const tachoInfo = findMostRecentMetadata(tachoMetadata, absoluteTimestamp);
    const speedInfo = findMostRecentMetadata(speedMetadata, absoluteTimestamp);
    let speed = "Unknown";
    let bearing = "Unknown";
    let baseSpeed = null;
    if (!_.isNil(tachoInfo?.data.speed) && tachoInfo.timestamp > absoluteTimestamp - 5) {
        baseSpeed = tachoInfo.data.speed;
    }
    if (baseSpeed == null && !_.isNil(speedInfo?.data.speed) && speedInfo.timestamp > absoluteTimestamp - 5) {
        baseSpeed = speedInfo.data.speed;
    }
    if (baseSpeed !== null) {
        speed = Math.round(convertSpeed(baseSpeed, "miles"));
    }
    if (!_.isNil(speedInfo?.data.bearing)) {
        bearing = calculateBearingText(speedInfo.data.bearing);
    }
    const frameNumber = calculateFrame(playingPlaylist, currentIndex, currentTimeOffset);
    let snapshotKey = `${state.sessions[routeID].device_uuid}/${state.sessions[routeID].uuid}/${_.get(playingPlaylist, [currentIndex, 0])}:${frameNumber || 1}`;

    let elr_data = null;

    if (elr) {
        elr_data = {
            ELR: elr.elr,
            MILE: elr.sign * elr.mile,
            YARD: elr.yard,
            TRACK: elr.track,
            SOURCES: elr.sources,
        };
    }

    const location_object = {
        TYPE: "LOCATION",
        DATA: {
            LAT: latLonCoords[1],
            LON: latLonCoords[0],
            TIME: absoluteTimestamp,
            ELR: elr_data,
            SPEED: speed,
            BEARING: bearing,
            SNAPSHOT_KEY: snapshotKey,
        },
    };

    //DEBUG LOG: TO BE REMOVED
    console.log("Sending widget msg location data:", location_object);
    window.parent.postMessage(location_object, "*");
}, 500);

const postToParentWindow = (store) => (next) => (action) => {
    if (action.type === "CURRENT_PLAYLIST_POSITION") {
        console.log("debug posting to parent here");
        const state = store.getState();
        handleLocationUpdate(state, action, window.parent);
    }
    return next(action);
};

function configureStore(preloadedState) {
    const middlewares = [windowManager, thunkMiddleware, asyncQueueMiddleware, reduxCookiesMiddleware(cookiePaths, cookieOptions)];
    if (window.parent && window.parent !== window) {
        middlewares.push(postToParentWindow);
    }
    const middlewareEnhancer = applyMiddleware(...middlewares);

    ReactGA.initialize([
        {
            trackingId: "G-TXK832S725",
            gaOptions: {
                siteSpeedSampleRate: 100,
            },
        },
    ]);

    ReactGA.send({ hitType: "pageview" });

    const storeEnhancer = ReduxQuerySync.enhancer({
        params: {
            share: {
                selector: (state) => state.shareLink,
                action: shareLink,
                defaultValue: null,
            },
        },
        initialTruth: "location",
        replaceState: true,
    });

    // Note
    // This is used to stop redux devtools crashing when the redux store tries to display large objects.
    // Add keys to the whitelist if you would like to view them in the extension
    const devToolsConfiguration = {
        stateSanitizer: (state) => {
            const newState = {};
            const whitelistItems = ["admin"];
            Object.keys(state).forEach((key) => {
                if (!whitelistItems.includes(key)) {
                    newState[key] = "<<LONG_BLOB>>";
                } else {
                    newState[key] = state[key];
                }
            });

            return newState;
        },
    };

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfiguration) : compose;

    const enhancers = [storeEnhancer, middlewareEnhancer];
    const composedEnhancers = composeEnhancers(...enhancers);
    return createStore(rootReducer, preloadedState, composedEnhancers);
}

const cookieState = getStateFromCookies(
    {
        userPreferences: {
            thermalHistory: false,
        },
    },
    cookiePaths,
);

const store = configureStore({
    ...cookieState,
});

store.dispatch({ type: "__INIT__" });

export default store;
