import React from "react";
import "../aivr.scss";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../images/new-branding-2.0/aivr-purple.svg";
import logoWhite from "../images/new-branding-2.0/aivr-hybrid.svg";
import { logout } from "redux/actions/index";
import { Button } from "antd";
import MediaQuery from "react-responsive";

class LogoutComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    logout = () => {
        this.props.dispatch(logout());
        this.props.history.push("/login");
    };

    render() {
        return (
            <div className="LoginOverlay Center-Content">
                <div className="LoginContent">
                    <MediaQuery maxWidth={479}>
                        <img
                            className="LoginLogo mobile"
                            src={logoWhite}
                            alt="AIVR Logo"
                            crossOrigin={"anonymous"}
                        />
                    </MediaQuery>
                    <MediaQuery minWidth={479}>
                        <img
                            className="LoginLogo"
                            src={logo}
                            alt="AIVR Logo"
                            crossOrigin={"anonymous"}
                        />
                    </MediaQuery>
                    <h1 className="logoutText">You have been logged out automatically</h1>
                    <Button
                        className="LoginConfirm"
                        type="primary"
                        onClick={() => this.logout()}>
                        Go to login
                    </Button>
                    <div className="LoginFooter">
                        <div className="LoginSales">
                            For enquiries, please contact <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a>.
                        </div>
                        <div className="LoginTerms">
                            By using this website you agree to our&nbsp;
                            <p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/terms">
                                    Terms of Use
                                </a>{" "}
                                &{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/privacy">
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null)(withRouter(LogoutComponent));
