import React from "react";
import { connect } from "react-redux";
import { logEvent } from "../../../redux/actions/index";
import validator from "validator";
import { Button, Input, Modal } from "antd";
import AdvancedSightingInterface from "./AdvancedSightingInterface";

class AdvancedSightingModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.sightingInterface = React.createRef();

        this.state = {
            isExportingSighting: false,
            isExportComplete: false,
            sightingExportNameInput: "",
            sightingExportEmailInput: props.user_email ? props.user_email : "",
            sightingExportNameError: false,
            sightingExportEmailError: false,
        };
    }

    handleClose = () => {
        if (this.sightingInterface.current && this.sightingInterface.current.state.imageFocused) {
            this.sightingInterface.current.closeImage();
        } else if (this.state.isExportingSighting) {
            this.setState({
                isExportingSighting: false,
            });
        } else {
            this.props.onClose();
        }
    };

    handleOK = () => {
        if (!this.state.isExportingSighting) {
            this.setState({
                isExportingSighting: true,
            });
            console.log("Creating parent report");
        } else {
            this.createSightingReport();
        }
    };

    createSightingReport = () => {
        if (!this.validateSighting()) {
            return;
        }

        this.props.dispatch(logEvent("Advanced Sighting", "Create Report"));

        if (this.sightingInterface.current) {
            this.sightingInterface.current.createReport(this.state.sightingExportEmailInput, this.state.sightingExportNameInput);
            this.setState({
                isExportingSighting: false,
                isExportComplete: true,
            });
        }
    };

    handleSightingExportNameChange = (event) => {
        this.setState({
            sightingExportNameInput: event.target.value,
            sightingExportNameError: false,
        });
    };

    handleSightingExportEmailChange = (event) => {
        this.setState({
            sightingExportEmailInput: event.target.value,
            sightingExportEmailError: false,
        });
    };

    validateSighting = () => {
        let errors = {};
        let valid = true;
        if (this.state.sightingExportNameInput === "") {
            errors.sightingExportNameError = "Export name cannot be empty";
            valid = false;
        }

        if (this.state.sightingExportNameInput.length > 40) {
            errors.sightingExportNameError = "Export name cannot be more than 40 characters";
            valid = false;
        }

        if (!validator.isEmail(this.state.sightingExportEmailInput)) {
            errors.sightingExportEmailError = "You must enter a valid email";
            valid = false;
        }

        this.setState(errors);
        return valid;
    };

    renderSubmissionComplete = () => {
        return <p>The segment has been sent for exporting. A download link will be sent via email when the export is complete.</p>;
    };

    renderExportFields = () => {
        return (
            <React.Fragment>
                <h3>Export Name</h3>
                <Input
                    value={this.state.sightingExportNameInput}
                    onChange={this.handleSightingExportNameChange}
                    placeholder="Enter export name"
                    style={{ marginBottom: 10 }}
                />
                {this.state.sightingExportNameError && <p className="error">{this.state.sightingExportNameError}</p>}
                <h3>Email Recipient</h3>
                <Input
                    value={this.state.sightingExportEmailInput}
                    onChange={this.handleSightingExportEmailChange}
                    placeholder="Enter email address"
                    style={{ marginBottom: 10 }}
                />
                {this.state.sightingExportEmailError && <p className="error">{this.state.sightingExportEmailError}</p>}
            </React.Fragment>
        );
    };

    getFooter = () => {
        const footer = [
            <Button
                onClick={this.handleClose}
                key="close">
                Close
            </Button>,
        ];

        if (!this.state.isExportComplete) {
            footer.push(
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleOK}>
                    Create Report
                </Button>,
            );
        }

        return footer;
    };

    render() {
        return (
            <Modal
                visible={true}
                width={this.state.isExportingSighting || this.state.isExportComplete ? "50%" : "80%"}
                height={"90vh"}
                className="sightingModal"
                centered={true}
                onCancel={this.handleClose}
                onOk={this.handleOK}
                footer={this.getFooter()}>
                <AdvancedSightingInterface
                    ref={this.sightingInterface}
                    hidden={this.state.isExportingSighting || this.state.isExportComplete}
                />

                {this.state.isExportingSighting && this.renderExportFields()}
                {this.state.isExportComplete && this.renderSubmissionComplete()}
            </Modal>
        );
    }
}

const mapStateToProps = ({ userDetails }) => {
    return {
        user_email: userDetails.email,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AdvancedSightingModal);
