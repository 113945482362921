export default class GenericPositionalSystem {
    constructor(systemID, route, position, subpostion, sources) {
        this.system = systemID;
        this.route = route;
        this.position = position;
        this.subposition = subpostion;
        this.sources = sources;
        this.meterage = position;
    }

    static display_formats = {
        route_id_position: "Route ID Meters",
    };

    static display_fields = {};

    static from_fields(system_id, display_format, fields) {
        if (display_format === "track_id_position") {
            const position = fields.POSITION;
            return new GenericPositionalSystem(system_id, fields.ROUTE, position, fields.TRACK, []);
        }
    }

    to_string(_display_format, _datum_offsets) {
        const stringFormat = `Route: ${this.route}, Distance: ${this.position}m`;
        return stringFormat;
    }

    field_value(field, _datum_offsets) {
        switch (field) {
            case "ROUTE":
                return this.route;
            case "METER":
                return Math.floor(this.meterage);
            case "TRACK":
                return this.subposition || null;
            default:
                return null;
        }
    }

    fields(display_format) {
        return GenericPositionalSystem.fields(display_format);
    }

    static fields(_displayFormat) {
        return ["ROUTE", "METER", "TRACK"];
    }

    displayFields(display_format) {
        return GenericPositionalSystem.displayFields(display_format);
    }

    static displayFields(_displayFormat) {
        return ["ROUTE", "TRACK", "METER"];
    }
}
