import React from "react";
import { connect } from "react-redux";
import { Marker } from "react-leaflet";
import { SegmentIcon } from "./Icons";
import _ from "lodash";
import { getInterpolatedPosition, getOffsetAdjustedTime } from "../util/PlaylistUtils";
import { LazyMapTooltip } from "../util/LazyTooltips";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";

class SegmentMarkers extends React.PureComponent {
    renderMarkerTooltip = (marker) => {
        let session = this.props.sessions[marker.session_id];
        if (!session) {
            return null;
        }
        let snapshotBaseURL = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_uuid}/${session.uuid}/`;

        let imageURL = snapshotBaseURL + marker.video_key + ".jpg";
        if (this.props.csrfToken) {
            imageURL += `?csrf=${this.props.csrfToken}`;
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span style={{ margin: 2.5 }}>{marker.name}</span>
                <img
                    style={{ margin: 2.5, width: "200px" }}
                    src={imageURL}
                    crossOrigin={"anonymous"}
                    alt=""
                />
                <span style={{ margin: 2.5, textTransform: "capitalize" }}>Source: {marker.source.toLowerCase()}</span>
            </div>
        );
    };

    getSegmentCoords = () => {
        if (
            this.lastEndSegment === this.props.mapSegment.end &&
            this.lastStartSegment === this.props.mapSegment.start &&
            this.lastUseSnapped === this.props.use_snapped &&
            this.lastSessionID === this.props.mapSegment.sessionID
        ) {
            return this.lastSegmentMarkers;
        }

        let segmentMarkers = [];
        let sourceIndex = this.props.sourceIndex;

        // console.log("this.props.viewingRouteData.video", this.props.viewingRouteData.video)

        if (this.props.viewingRouteData.video && this.props.viewingRouteData.video[sourceIndex]) {
            this.lastEndSegment = this.props.mapSegment.end;
            this.lastStartSegment = this.props.mapSegment.start;
            this.lastSessionID = this.props.mapSegment.sessionID;
            this.lastUseSnapped = this.props.use_snapped;
            let videoParts = this.props.viewingRouteData.video[sourceIndex];

            if (this.lastEndSegment !== -1) {
                let segmentEndVideo = videoParts[this.props.mapSegment.end];
                const endTime = segmentEndVideo[1];
                let offsetTime = getOffsetAdjustedTime(endTime, this.props.offsets);
                let coords = getInterpolatedPosition(offsetTime, videoParts, this.props.use_snapped);

                if (coords) {
                    let endCoords = [coords[1], coords[0]];

                    segmentMarkers.push({
                        source: "TRIGGER",
                        name: "Segment End",
                        id: "segmentEnd",
                        session_id: this.props.mapSegment.session,
                        video_key: segmentEndVideo[0],
                        coords: endCoords,
                    });
                }
            }

            if (this.lastStartSegment !== -1) {
                let segmentStartVideo = videoParts[this.props.mapSegment.start];
                const startTime = segmentStartVideo[1];
                let offsetTime = getOffsetAdjustedTime(startTime, this.props.offsets);
                let coords = getInterpolatedPosition(offsetTime, videoParts, this.props.use_snapped);
                if (coords !== null) {
                    let startCoords = [coords[1], coords[0]];

                    segmentMarkers.push({
                        source: "TRIGGER",
                        name: "Segment Start",
                        id: "segmentStart",
                        session_id: this.props.mapSegment.session,
                        video_key: segmentStartVideo[0],
                        coords: startCoords,
                    });
                }
            }
            return (this.lastSegmentMarkers = segmentMarkers);
        } else {
            return [];
        }
    };

    renderMarker = (marker) => {
        let markerIconToUse = SegmentIcon;

        if (marker.coords[0] && marker.coords[1]) {
            return (
                <Marker
                    key={"Marker" + marker.id}
                    position={marker.coords}
                    icon={markerIconToUse}>
                    <LazyMapTooltip
                        className="Incident-Tooltip"
                        permanent={false}
                        interactive={true}
                        direction={"top"}>
                        {this.renderMarkerTooltip(marker)}
                    </LazyMapTooltip>
                </Marker>
            );
        }
    };

    render() {
        let markers = this.getSegmentCoords();

        return <React.Fragment>{markers.map(this.renderMarker)}</React.Fragment>;
    }
}

const mapStateToProps = (state) => {
    let offsets = [];

    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, state.playlist.position.sourceIndex, []);
    }
    return {
        sessions: state.sessions,
        offsets,
        use_snapped: state.snappedRoute || false,
        annotationTypes: state.userAnnotationTypes,
        viewingRouteData: state.playlist.data,
        mapSegment: state.segmentSelection,
        sourceIndex: state.playlist.position.sourceIndex,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(SegmentMarkers);
