import React from "react";
import { CustomPicker } from "react-color";

const CustomColorPicker = ({ onChangeComplete, color, colors }) => {
    return (
        <div className="customColorPicker">
            {colors &&
                colors.map((itemColor) => {
                    return (
                        <div
                            className={`customColorPicker__Item ${color === itemColor ? "selected" : ""}`}
                            style={{
                                background: itemColor,
                                boxShadow: color === itemColor ? `${itemColor} 0 0 4px` : "none",
                            }}
                            onClick={() => onChangeComplete(itemColor)}
                            key={itemColor}
                        />
                    );
                })}
        </div>
    );
};

export default CustomPicker(CustomColorPicker);
