import ReactDOM from "react-dom";
import videojs from "video.js";
import React from "react";
import { connect, Provider } from "react-redux";
import store from "../../../redux/store";
import ThreeDFeatureOverlay from "./3d/3dFeatureOverlay";
import _ from "lodash";

const Component = videojs.getComponent("Component");
const dom = videojs.dom || videojs;
const registerPlugin = videojs.registerPlugin || videojs.plugin;
const Plugin = videojs.getPlugin("plugin");

class ReactEnhancedVideoOverlay extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="ThreeDFeatureOverlayContainer">
                    <ThreeDFeatureOverlay />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showTunnelInEnhanced: _.get(state.playlist.overlays, ["Tunnel", "enabled"], false),
        isEnhanced: state.playlist.position.isEnhanced,
    };
};

const EnhancedVideoOverlayComponent = connect(mapStateToProps, null, null, { forwardRef: true })(ReactEnhancedVideoOverlay);

class EnhancedVideoOverlay extends Component {
    createEl() {
        const el = dom.createEl("div", {
            className: "vjs-enhanced-overlay",
        });

        this.containerRef = React.createRef();

        ReactDOM.render(
            <Provider store={store}>
                <EnhancedVideoOverlayComponent
                    player={this.player()}
                    ref={this.containerRef}
                />
            </Provider>,
            el,
        );

        console.log("Container ref: ", this.containerRef);

        return el;
    }

    dispose() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el());
        }
    }
}

videojs.registerComponent("EnhancedVideoOverlay", EnhancedVideoOverlay);

class EnhancedVideoOverlayPlugin extends Plugin {
    constructor(player, options) {
        super(player);
        this.tid = null;
        this.div = null;
        this.options = options;
        this.player.ready(() => this._setup());
    }

    _setup() {
        const video = this.player.el();
        const playerChild = this.player.addChild("enhancedVideoOverlay", this.options);
        video.appendChild(playerChild.el());
    }
}

registerPlugin("enhancedVideoOverlay", EnhancedVideoOverlayPlugin);
