import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { Modal, Input, Button, Checkbox, Select } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    createInspectionAnnotation,
    getInspectionAnnotationTypes,
    updateInspectionAnnotation,
    deleteInspectionAnnotation,
    updatePatrolDefect,
} from "../../../redux/actions/index";
import _ from "lodash";

const { TextArea } = Input;

const defaultPrivateSelector = (state) => state.userDetails.userConfig.default_content_privacy;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const annotationsSelector = (state) => state.railInspection.annotations.data;

const AnnotationCreateWindow = ({
    updatingID,
    visible,
    annotationData,
    sessionID,
    closeModal,
    resetEditingID,
    cancelAnnotationCreation,
    patrol,
    disabled,
    isUsersPatrol,
}) => {
    const defaultPrivate = useSelector(defaultPrivateSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const annotations = useSelector(annotationsSelector);

    const [label, setLabel] = useState("");
    const [comment, setComment] = useState("");
    const [activeAnnotationType, setActiveAnnotationType] = useState(null);
    const [privateChecked, setPrivateChecked] = useState(defaultPrivate || !!patrol || false);
    const inputRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInspectionAnnotationTypes());
    }, [dispatch]);

    useEffect(() => {
        if (visible) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            if (updatingID) {
                const annotation = _.find(annotations, (ann) => parseInt(ann.id) === parseInt(updatingID));

                if (annotation) {
                    setActiveAnnotationType(annotation.annotation_type);
                    setComment(annotation.comment);
                }
                if (annotation.annotation_type === -1) {
                    setLabel(annotation.custom_label);
                }
            }
        }
    }, [visible, annotations, updatingID, resetEditingID]);

    useEffect(() => {
        if (!visible) {
            setLabel("");
            setComment("");
            setActiveAnnotationType(null);
            resetEditingID();
        }
    }, [visible, resetEditingID]);

    const createAnnotation = useCallback(() => {
        console.log("db updating", updatingID);
        if (!updatingID) {
            const annotation = {
                timestamp: annotationData.imageTimestamp,
                bbox: annotationData.bbox,
                annotation_type: label ? -1 : activeAnnotationType,
                custom_label: label,
                comment: comment,
                private: privateChecked,
                source: annotationData.source,
                session_id: sessionID,
                patrol,
            };
            dispatch(createInspectionAnnotation(annotation));
        } else {
            const payload = {
                private: privateChecked,
                annotation_type: activeAnnotationType ? activeAnnotationType : -1,
                custom_label: activeAnnotationType ? null : label,
                comment: comment,
            };
            dispatch(updateInspectionAnnotation(updatingID, payload));

            const annotation_type = _.get(_.find(annotationTypes, { id: activeAnnotationType }), "type", null);
            if (annotation_type) {
                dispatch(updatePatrolDefect(updatingID, annotation_type, comment, !isUsersPatrol));
            }
        }
        closeModal();
    }, [
        updatingID,
        closeModal,
        annotationData.imageTimestamp,
        annotationData.bbox,
        annotationData.source,
        label,
        activeAnnotationType,
        comment,
        privateChecked,
        sessionID,
        patrol,
        dispatch,
        annotationTypes,
        isUsersPatrol,
    ]);

    const handleCancel = useCallback(
        (e) => {
            cancelAnnotationCreation();
            closeModal();
        },
        [closeModal, cancelAnnotationCreation],
    );

    const deleteAnnotation = useCallback(() => {
        dispatch(deleteInspectionAnnotation(updatingID));
        closeModal();
    }, [dispatch, closeModal, updatingID]);

    const handleCustomLabel = (e) => {
        if (activeAnnotationType) {
            setActiveAnnotationType(null);
        }
        setLabel(e.target.value);
    };

    const handleLabelChange = (id) => {
        setLabel("");
        setActiveAnnotationType(id);
    };

    const createAnnotationDisabled = useMemo(() => {
        if (patrol) {
            return !activeAnnotationType;
        } else {
            return !activeAnnotationType && !label;
        }
    }, [patrol, activeAnnotationType, label]);

    const footer = useMemo(() => {
        const footer = [
            <Button
                onClick={handleCancel}
                key="cancel">
                Cancel
            </Button>,
        ];

        if (updatingID) {
            footer.push(
                <Button
                    onClick={() => deleteAnnotation()}
                    type="danger"
                    key="delete"
                    disabled={!!disabled}>
                    Delete
                </Button>,
            );
        }

        footer.push(
            <Button
                disabled={createAnnotationDisabled || disabled}
                onClick={() => createAnnotation()}
                type="primary"
                key="save">
                Save
            </Button>,
        );

        return footer;
    }, [handleCancel, createAnnotation, deleteAnnotation, updatingID, createAnnotationDisabled]);

    const defectTypes = useMemo(() => {
        const nonExtraTypes = _.orderBy(
            annotationTypes.filter((type) => !type.extra),
            "type",
        ).map((type) => {
            return (
                <Select.Option
                    className="DefectSelectorOption"
                    value={type.id}
                    key={type.id}>
                    {type.type}
                </Select.Option>
            );
        });

        return <Select.OptGroup label="Types">{nonExtraTypes}</Select.OptGroup>;
    }, [annotationTypes]);

    const extraDefectTypes = useMemo(() => {
        const extraTypes = _.orderBy(
            annotationTypes.filter((type) => type.extra),
            "type",
        ).map((type) => {
            return (
                <Select.Option
                    className="DefectSelectorOption"
                    value={type.id}
                    key={type.id}>
                    {type.type}
                </Select.Option>
            );
        });

        return (
            <Select.OptGroup label="More">
                {extraTypes}
                <Select.Option
                    className="DefectSelectorOption"
                    value={-1}
                    key="other">
                    Other
                </Select.Option>
            </Select.OptGroup>
        );
    }, [annotationTypes]);

    return (
        <Modal
            title={updatingID ? "Update Annotation" : "Create Annotation"}
            visible={visible}
            onOk={() => createAnnotation()}
            onCancel={handleCancel}
            footer={footer}>
            <div className="AnnotationModalContainer">
                <h4>{!patrol ? "Label" : "Defect"}</h4>
                {!patrol && (
                    <>
                        <Input
                            placeholder="Custom Label"
                            onChange={(e) => handleCustomLabel(e)}
                            value={label}
                            onPressEnter={() => createAnnotation()}
                            ref={inputRef}
                            className={`${activeAnnotationType ? "disabled" : ""}`}
                        />
                        <div className="divider">OR</div>
                    </>
                )}

                <Select
                    value={activeAnnotationType}
                    disabled={!!disabled}
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Search to Select Label"
                    optionFilterProp="children"
                    filterOption={(value, option) => {
                        const { children } = option.props;
                        if (option.type.isSelectOptGroup) {
                            return children.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
                        }
                        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                    }}
                    onChange={(id) => handleLabelChange(id)}>
                    {defectTypes}
                    {extraDefectTypes}
                </Select>

                <div className="AnnotationModal__Comment">
                    <h4>Notes</h4>
                    <TextArea
                        disabled={!!disabled}
                        value={comment}
                        rows={3}
                        placeholder="..."
                        maxLength={500}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>

                {!patrol && (
                    <div className="inspectRail__BookmarkList__Modal__Options">
                        <span>Private</span>
                        <Checkbox
                            checked={privateChecked}
                            onChange={(e) => setPrivateChecked(e.target.checked)}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default AnnotationCreateWindow;
