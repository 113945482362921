import { jsonPostV2, handleJsonPostError } from "./apiUtils";
import _ from "lodash";
import { notification } from "antd";
import { addDetectionsTypeFilter } from "./markerActions";

export const SELECTED_RAIL_INSPECTION_IMAGE = "SELECTED_RAIL_INSPECTION_IMAGE";
export const EXTRA_RAIL_INSPECTION_SOURCE = "EXTRA RAIL INSPECTION SOURCE";
export const RAIL_INSPECTION_SOURCES_OPEN = "RAIL_INSPECTION_SOURCES_OPEN";
export const RAIL_INSPECTION_SOURCE_CLOSED = "RAIL_INSPECTION_SOURCE_CLOSED";
export const RECEIVE_INSPECTION_ANNOTATION_TYPES = "RECEIVE_INSPECTION_ANNOTATION_TYPES";
export const RECEIVE_INSPECTION_ANNOTATIONS = "RECEIVE_INSPECTION_ANNOTATIONS";
export const TOGGLE_INSPECTION_ANNOTATION_MODE = "TOGGLE_INSPECTION_ANNOTATION_MODE";
export const INSPECTION_ANNOTATIONS_FETCHED = "INSPECTION_ANNOTATIONS_FETCHED";
export const INSPECTION_BOOKMARKS_FETCHED = "INSPECTION_BOOKMARKS_FETCHED";
export const FLIP_INSPECTION_RAILS = "FLIP_INSPECTION_RAILS";
export const INSPECTION_DETECTIONS = "INSPECTION_DETECTIONS";
export const SET_INSPECTION_MARKUP = "SET_INSPECTION_MARKUP";
export const RAIL_INSPECTION_EXITED = "RAIL_INSPECTION_EXITED";
export const INSPECTION_DETECTIONS_FETCHED = "INSPECTION_DETECTIONS_FETCHED";
export const TOGGLE_INSPECTION_SNAPSHOT = "TOGGLE_INSPECTION_SNAPSHOT";
export const RESET_INSPECTION_DETECTIONS = "RESET_INSPECTION_DETECTIONS";
export const SELECT_BOOKMARK = "SELECT_BOOKMARK";
export const TOGGLE_FETCHING_DETECTIONS = "TOGGLE_FETCHING_DETECTIONS";
export const SET_CURRENT_DETECTION = "SET_CURRENT_DETECTION";
export const RAIL_INSPECTION = "RAIL_INSPECTION";
export const RECEIVE_BOOKMARKS = "RECEIVE_BOOKMARKS";
export const UPDATE_PRIMARY_RAIL_IMAGES = "UPDATE_PRIMARY_RAIL_IMAGES";
export const PRIMARY_RAIL_IMAGE_CONFIG = "RECEIVE_RAIL_IMAGE_CONFIG";
export const TOGGLE_ROUTE_SNAPPING = "TOGGLE_ROUTE_SNAPPING";
export const TOGGLE_VERTICAL_NAV_WINDOW = "TOGGLE_VERTICAL_NAV_WINDOW";
export const RESET_IMAGE_ADJUSTMENTS = "RESET_IMAGE_ADJUSTMENTS";
export const SET_RAIL_DETAIL_WIDGET_SELECTED_SOURCES = "SET_RAIL_DETAIL_WIDGET_SELECTED_DEVICES";
export const SET_NEW_IMAGE_ADJUSTMENTS = "SET_NEW_IMAGE_ADJUSTMENTS";
export const SET_NEW_DETAIL_IMAGE_ADJUSTMENTS = "SET_NEW_DETAIL_IMAGE_ADJUSTMENTS";
export const RESET_GRID_ADJUSTMENTS = "RESET_GRID_ADJUSTMENTS";
export const CHANGE_GRID_OFFSET = "CHANGE_GRID_OFFSET";
export const CHANGE_GRID_SIZE = "CHANGE_GRID_SIZE";
export const SET_MAGNIFY_TOGGLE = "SET_MAGNIFY_TOGGLE";
export const IS_MAGNIFY_TOGGLED = "IS_MAGNIFY_TOGGLED";
export const TOGGLE_GRID_VISIBLE = "TOGGLE_GRID_VISIBLE";
export const SET_STILL_IMAGE_ADJUSTMENTS = "SET_STILL_IMAGE_ADJUSTMENTS";
export const RESET_STILL_IMAGE_ADJUSTMENTS = "RESET_STILL_IMAGE_ADJUSTMENTS";
export const TOGGLE_DETAIL_VIEW = "TOGGLE_DETAIL_VIEW";
export const TOGGLE_SETTINGS_OPEN = "TOGGLE_SETTINGS_OPEN";
export const DEVICE_GROUP_INFO = "DEVICE_GROUP_INFO";
export const RAIL_INSPECTION_CONDITIONS = "RAIL_INSPECTION_CONDITIONS";
export const COMPARABLE_SESSIONS = "COMPARABLE_SESSIONS";
export const INSPECTION_CONDITIONS_FETCHED = "INSPECTION_CONDITIONS_FETCHED";
export const UPDATE_RAIL_INSPECTION_DETECTION_CONDITION = "UPDATE_RAIL_INSPECTION_DETECTION_CONDITION";
export const SET_DETECTION_CONDITIONS_FILTERS = "SET_DETECTION_CONDITIONS_FILTERS";
export const DISPLAY_EXTRA_INSPECTION_LAYERS = "DISPLAY_EXTRA_INSPECTION_LAYERS";
export const SET_DIRECTION_ARROW = "SET_DIRECTION_ARROW";
export const GET_RAIL_INSPECTION_CLASSIFICATIONS = "GET_RAIL_INSPECTION_CLASSIFICATIONS";
export const INSPECTION_CLASSIFICATIONS_FETCHED = "INSPECTION_CLASSIFICATIONS_FETCHED";
export const INSPECTION_CLASSIFICATIONS = "INSPECTION_CLASSIFICATIONS";
export const RESET_INSPECTION_CLASSIFICATIONS = "RESET_INSPECTION_CLASSIFICATIONS";
export const TOGGLE_FETCHING_CLASSIFICATIONS = "TOGGLE_FETCHING_CLASSIFICATIONS";
export const SET_SELECTED_CLASSIFICATION = "SET_SELECTED_CLASSIFICATION";
export const SET_CLASSIFICATION_SCORE = "SET_CLASSIFICATION_SCORE";
export const CLASSIFICATIONS_TAB_OPEN = "CLASSIFICATIONS_TAB_OPEN";
export const TOGGLE_CLASSIFICATION_VISIBILITY = "TOGGLE_CLASSIFICATION_VISIBILITY";
export const SET_INSPECTION_TARGET_TIMESTAMP = "SET_INSPECTION_TARGET_TIMESTAMP";
export const SET_SOME_MAIN_RAIL_INSPECTION_IMAGES_MISSING = "SET_SOME_MAIN_RAIL_INSPECTION_IMAGES_MISSING";
export const RECEIVE_INSPECTION_PULSE_COUNTS = "RECEIVE_INSPECTION_PULSE_COUNTS";
export const ARCHIVE_INSPECTION_SESSION = "ARCHIVE_INSPECTION_SESSION";
export const TOGGLE_RAIL_INSPECTION_WIDGET_TIMELINE = "TOGGLE_RAIL_INSPECTION_WIDGET_TIMELINE";
export const TOGGLE_REJECTION_MODAL = "TOGGLE_REJECTION_MODAL";
export const SET_FOLLOW_DETECTIONS = "SET_FOLLOW_DETECTIONS";
export const TOGGLE_DETECTIONS_EXPORT_OPEN = "TOGGLE_DETECTIONS_EXPORT_OPEN";
export const SET_AUTO_SCROLL = "SET_AUTO_SCROLL";
export const RECEIVE_INSPECTION_FAULTS = "RECEIVE_INSPECTION_FAULTS";
export const TOGGLE_ANNOTATION_VISIBILITY = "TOGGLE_ANNOTATION_VISIBILITY";
export const RECEIVE_INSPECTION_CONDITION_TYPES = "RECEIVE_INSPECTION_CONDITION_TYPES";
export const SET_SPERRY_MARKERS = "SET_SPERRY_MARKERS";
export const TOGGLE_DETAIL_CAMERA_AUTO_SELECTION = "TOGGLE_DETAIL_CAMERA_AUTO_SELECTION";
export const SET_POSITION_LEFT_BIASED = "SET_POSITION_LEFT_BIASED";
export const SET_DIP_ANGLE_FILTER = "SET_DIP_ANGLE_FILTER";
export const SET_INSPECTION_DETECTIONS_EXPORT_RANGE = "SET_INSPECTION_DETECTIONS_EXPORT_RANGE";
export const RESET_INSPECTION_DETECTIONS_EXPORT_RANGE = "RESET_INSPECTION_DETECTIONS_EXPORT_RANGE";

export const toggleAnnotationVisibility = (visible) => {
    return {
        type: TOGGLE_ANNOTATION_VISIBILITY,
        visible,
    };
};

export const setTargetInpectionTimestamp = (timestamp, interpolationDistance) => {
    return {
        type: SET_INSPECTION_TARGET_TIMESTAMP,
        timestamp,
        interpolationDistance,
    };
};

export const setDirectionArrow = (left) => {
    return {
        type: SET_DIRECTION_ARROW,
        left,
    };
};

export const updatePrimaryRailImages = (railImages, loaded, percentageComplete, sessionID) => {
    return {
        type: UPDATE_PRIMARY_RAIL_IMAGES,
        railImages,
        loaded,
        percentageComplete,
        sessionID,
    };
};

export const setStillImageAdjustments = (value, adjustmentType, sourceIndex) => {
    return {
        type: SET_STILL_IMAGE_ADJUSTMENTS,
        value,
        adjustmentType,
        sourceIndex,
    };
};

export const resetStillImageAdjustments = () => {
    return {
        type: RESET_STILL_IMAGE_ADJUSTMENTS,
    };
};

export const toggleDataView = () => {
    return {
        type: TOGGLE_DETAIL_VIEW,
    };
};

export const toggleSettingsOpen = () => {
    return {
        type: TOGGLE_SETTINGS_OPEN,
    };
};

export const setAutoScrollActive = (value) => {
    return {
        type: SET_AUTO_SCROLL,
        value,
    };
};

export const toggleGridVisible = () => {
    return {
        type: TOGGLE_GRID_VISIBLE,
    };
};

export const changeGridSize = (size) => {
    return {
        type: CHANGE_GRID_SIZE,
        size,
    };
};

export const changeGridOffset = (direction, value) => {
    return {
        type: CHANGE_GRID_OFFSET,
        direction,
        value,
    };
};

export const resetGridAdjustments = () => {
    return {
        type: RESET_GRID_ADJUSTMENTS,
    };
};

export const toggleInspectionDetectionsExport = () => {
    return {
        type: TOGGLE_DETECTIONS_EXPORT_OPEN,
    };
};

export const setInspectionDetectionsExportIndexRangeValue = (key, value) => {
    return {
        type: SET_INSPECTION_DETECTIONS_EXPORT_RANGE,
        key,
        value,
    };
};

export const resetInspectionDetectionsExportIndexRange = () => {
    return {
        type: RESET_INSPECTION_DETECTIONS_EXPORT_RANGE,
    };
};

export const setImageAdjustments = (adjustments) => {
    return {
        type: SET_NEW_IMAGE_ADJUSTMENTS,
        adjustments,
    };
};

export const setDetailImageAdjustments = (adjustments) => {
    return {
        type: SET_NEW_DETAIL_IMAGE_ADJUSTMENTS,
        adjustments,
    };
};

export const setIsMagnifyToggled = (value) => {
    return {
        type: IS_MAGNIFY_TOGGLED,
        value,
    };
};

export const resetImageAdjustments = () => {
    return {
        type: RESET_IMAGE_ADJUSTMENTS,
    };
};

export const setRailDetailWidgetSelectedSources = (sources) => {
    return {
        type: SET_RAIL_DETAIL_WIDGET_SELECTED_SOURCES,
        sources,
    };
};

export const toggleVerticalNav = () => {
    return {
        type: TOGGLE_VERTICAL_NAV_WINDOW,
    };
};

export const toggleRouteSnapping = (snap) => {
    return {
        type: TOGGLE_ROUTE_SNAPPING,
        snap,
    };
};

export const primaryRailImageConfig = (config) => {
    return {
        type: PRIMARY_RAIL_IMAGE_CONFIG,
        config,
    };
};

const receiveBookmarks = (bookmarks) => {
    return {
        type: RECEIVE_BOOKMARKS,
        bookmarks,
    };
};

export function flipInspectionRails(flipped) {
    return {
        type: FLIP_INSPECTION_RAILS,
        flipped,
    };
}

export const selectBookmark = (bookmarkID) => {
    return {
        type: SELECT_BOOKMARK,
        bookmarkID,
    };
};

export const setSelectedRailInspectionImage = (index, object, updateTimestamp, latPos = null, leftRightConfig) => {
    return {
        type: SELECTED_RAIL_INSPECTION_IMAGE,
        index,
        object,
        updateTimestamp,
        latPos,
        leftRightConfig,
    };
};

function inspectionAnnotationsFetched(fetched) {
    return {
        type: INSPECTION_ANNOTATIONS_FETCHED,
        fetched,
    };
}

export const extraRailInspectionSource = (sourceIndex) => {
    return {
        type: EXTRA_RAIL_INSPECTION_SOURCE,
        sourceIndex,
    };
};

export const setSomeMainRailInspectionImagesMissing = (value) => {
    return {
        type: SET_SOME_MAIN_RAIL_INSPECTION_IMAGES_MISSING,
        value,
    };
};

export function railInspectionExited() {
    return {
        type: RAIL_INSPECTION_EXITED,
    };
}

function inspectionBookmarksFetched(fetched) {
    return {
        type: INSPECTION_BOOKMARKS_FETCHED,
        fetched,
    };
}

export const railInspectionSourcesOpen = (sourceIndexes) => {
    return {
        type: RAIL_INSPECTION_SOURCES_OPEN,
        sourceIndexes,
    };
};

export const railInspectionSourceClosed = (sourceIndex) => {
    return {
        type: RAIL_INSPECTION_SOURCE_CLOSED,
        sourceIndex,
    };
};

export function toggleInspectionAnnotationMode(value) {
    return {
        type: TOGGLE_INSPECTION_ANNOTATION_MODE,
        value,
    };
}

export function setCurrentInspectionMarkup(type, id) {
    return {
        type: SET_INSPECTION_MARKUP,
        markupType: type,
        id,
    };
}

export function setClassificationScoreFilter(score) {
    return {
        type: SET_CLASSIFICATION_SCORE,
        score,
    };
}

export function setCurrentClassification(id) {
    return {
        type: SET_SELECTED_CLASSIFICATION,
        id,
    };
}

export function setClassificationsTabOpen(open) {
    return {
        type: CLASSIFICATIONS_TAB_OPEN,
        open,
    };
}

export const setClassificationVisibility = () => {
    return {
        type: TOGGLE_CLASSIFICATION_VISIBILITY,
    };
};

export function toggleInspectionSnapshot(open) {
    return {
        type: TOGGLE_INSPECTION_SNAPSHOT,
        open,
    };
}

export function fetchingDetections(sessionID) {
    return {
        type: TOGGLE_FETCHING_DETECTIONS,
        sessionID,
    };
}

function inspectionDetections(detections, replace = false) {
    return {
        type: INSPECTION_DETECTIONS,
        detections,
        replace,
    };
}

function inspectionDetectionsFetched(fetched) {
    return {
        type: INSPECTION_DETECTIONS_FETCHED,
        fetched,
    };
}

function inspectionClassificationsFetched(fetched) {
    return {
        type: INSPECTION_CLASSIFICATIONS_FETCHED,
        fetched,
    };
}

function inspectionClassifications(classifications, replace = false) {
    return {
        type: INSPECTION_CLASSIFICATIONS,
        classifications,
        replace,
    };
}

export const setCurrentDetection = (detectionID) => {
    return {
        type: SET_CURRENT_DETECTION,
        detectionID,
    };
};

export const resetComparableSessions = () => {
    return {
        type: COMPARABLE_SESSIONS,
        sessions: [],
    };
};

function setRailInspectionConditions(fetched) {
    return {
        type: INSPECTION_CONDITIONS_FETCHED,
        types: fetched,
    };
}

function updateRailInspectionDetectionConditions(newData) {
    return {
        type: UPDATE_RAIL_INSPECTION_DETECTION_CONDITION,
        newData,
    };
}

export function setDetectionConditionFilters(types) {
    return {
        type: SET_DETECTION_CONDITIONS_FILTERS,
        types,
    };
}

export function setDipAngleFilter(range) {
    return {
        type: SET_DIP_ANGLE_FILTER,
        range,
    };
}

export function setDisplayExtraLayers(display) {
    return {
        type: DISPLAY_EXTRA_INSPECTION_LAYERS,
        display,
    };
}

export function toggleRailInspectionTimelineWidget() {
    return {
        type: TOGGLE_RAIL_INSPECTION_WIDGET_TIMELINE,
    };
}

export const toggleRejectionModal = () => {
    return {
        type: TOGGLE_REJECTION_MODAL,
    };
};

export const setFollowDetections = (bool) => {
    return {
        type: SET_FOLLOW_DETECTIONS,
        bool,
    };
};

const receiveInspectionConditionTypes = (types) => {
    return {
        type: RECEIVE_INSPECTION_CONDITION_TYPES,
        types,
    };
};

export function setPositionLeftBiased(isLeft) {
    return {
        type: SET_POSITION_LEFT_BIASED,
        isLeft,
    };
}

export function toggleAutoDetailCameraOption(value) {
    return {
        type: TOGGLE_DETAIL_CAMERA_AUTO_SELECTION,
        value,
    };
}

export function getInspectionAnnotationTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_inspection_annotation_types",
        };

        const url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.annotation_types) {
                    dispatch({
                        type: RECEIVE_INSPECTION_ANNOTATION_TYPES,
                        types: response.annotation_types,
                    });
                }
            })
            .catch(() => {
                console.log("Error getting annotation types");
            });
    };
}

export function reviewDetection(id, review_status, review_comment = "No comment", connecting = false) {
    return {
        queue: RAIL_INSPECTION,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "review_inspection_detection",
                id,
                review_status,
                review_comment,
            };

            let url = "/routeMetadata";
            let detections;
            let updatedIndex;
            let oldReviewStatus;

            if (!connecting) {
                detections = _.cloneDeep(getState().railInspection.detections.data);
                updatedIndex = _.findIndex(detections, { id: id });
                oldReviewStatus = detections[updatedIndex].review_status;
                detections[updatedIndex].review_status = review_status;
                dispatch(inspectionDetections(detections, true));
            }

            jsonPostV2(url, getState(), postBody)
                .then((response) => {
                    console.log("Review response", response);
                    if (!response.success && !connecting) {
                        detections[updatedIndex].review_status = oldReviewStatus;
                        dispatch(inspectionDetections(detections, true));
                    }
                    next();
                })
                .catch((error) => {
                    console.log("Error reviewing detection: ", error);
                    next();
                });
        },
    };
}

export function createInspectionAnnotation(annotation) {
    return (dispatch, getState) => {
        let postBody = {
            action: "create_inspection_annotation",
            ...annotation,
        };

        const url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    let currentAnnotations = _.cloneDeep(getState().railInspection.annotations.data);
                    currentAnnotations.push({
                        id: -1,
                        image_timestamp: annotation.timestamp,
                        annotation_type: annotation.annotation_type,
                        custom_label: annotation.custom_label,
                        comment: annotation.comment,
                        private: annotation.private,
                        image_source: annotation.source,
                        bbox: JSON.stringify(annotation.bbox),
                    });
                    dispatch({
                        type: RECEIVE_INSPECTION_ANNOTATIONS,
                        annotations: currentAnnotations,
                    });
                    dispatch(fetchInspectionAnnotations(annotation.session_id, annotation.patrol));
                }
            })
            .catch((e) => {
                console.log("Error creating annotation", e);
            });
    };
}

export function fetchInspectionAnnotations(sessionID, patrol = null, review = false) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_inspection_annotations",
            session_id: sessionID,
            patrol,
            review,
        };

        const url = "/annotations";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.annotations) {
                    dispatch(inspectionAnnotationsFetched(true));
                    dispatch({
                        type: RECEIVE_INSPECTION_ANNOTATIONS,
                        annotations: response.annotations,
                    });
                }
            })
            .catch((error) => {
                console.log("Error getting inspection annotation");
                dispatch(inspectionAnnotationsFetched(true));
                handleJsonPostError("Fetch Inspection Annotations", "Unable to fetch annotations", error);
            });
    };
}

export function updateInspectionAnnotation(annotationID, updates) {
    return (dispatch, getState) => {
        let postBody = {
            action: "update_inspection_annotation",
            id: annotationID,
            ...updates,
        };

        const url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    let currentAnnotations = _.cloneDeep(getState().railInspection.annotations.data);
                    const updatingIndex = _.findIndex(currentAnnotations, (ann) => parseInt(ann.id) === parseInt(annotationID));
                    if (updatingIndex > -1) {
                        currentAnnotations[updatingIndex] = {
                            ...currentAnnotations[updatingIndex],
                            ...updates,
                        };
                    }
                    dispatch({
                        type: RECEIVE_INSPECTION_ANNOTATIONS,
                        annotations: currentAnnotations,
                    });
                }
            })
            .catch((e) => {
                console.log("Error creating annotation", e);
            });
    };
}

export function deleteInspectionAnnotation(annotationID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "delete_inspection_annotation",
            id: annotationID,
        };

        const url = "/annotations";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    let currentAnnotations = _.cloneDeep(getState().railInspection.annotations.data);
                    const updatingIndex = _.findIndex(currentAnnotations, (ann) => parseInt(ann.id) === parseInt(annotationID));
                    if (updatingIndex > -1) {
                        currentAnnotations.splice(updatingIndex, 1);
                    }
                    dispatch({
                        type: RECEIVE_INSPECTION_ANNOTATIONS,
                        annotations: currentAnnotations,
                    });
                }
            })
            .catch((e) => {
                console.log("Error creating annotation", e);
            });
    };
}

export function getInspectionDetections(session_id, page, replace = false) {
    return {
        queue: RAIL_INSPECTION,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_inspection_detections",
                session_id,
                page,
            };
            let url = "/routeMetadata";

            const reduxSessionID = getState().playlist.data.routeID;
            const fetchingSessionID = getState().railInspection.detections.fetchingSession;

            if (session_id !== reduxSessionID || (session_id === fetchingSessionID && page === 0)) {
                next();
                return;
            }

            if (replace) {
                dispatch({
                    type: RESET_INSPECTION_DETECTIONS,
                });
                dispatch({
                    type: TOGGLE_FETCHING_DETECTIONS,
                    sessionID: session_id,
                });
            }

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.detections) {
                        let detectionNames = _.uniq(_.map(response.detections, (det) => det.name));
                        dispatch(inspectionDetections(response.detections, replace));
                        if (page === 0) {
                            dispatch(addDetectionsTypeFilter(detectionNames));
                        } else {
                            dispatch(addDetectionsTypeFilter(detectionNames, false));
                        }
                    }

                    if (response.is_more) {
                        dispatch(getInspectionDetections(session_id, page + 1));
                    } else {
                        dispatch(inspectionDetectionsFetched(true));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting detections", "An error occurred while fetching inspection detections", error);
                    dispatch(inspectionDetections([]));
                    next();
                });
        },
    };
}

export function getInspectionBookmarks(session_id, patrol = false, review = false) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_bookmarks",
            session_id,
            patrol,
            review,
        };

        let url = "/sessions";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.bookmarks) {
                    dispatch(inspectionBookmarksFetched(true));
                    dispatch(receiveBookmarks(response.bookmarks));
                }
            })
            .catch((error) => {
                console.log("get bookmarks error: ", error);
            });
    };
}

export function addInspectionBookmark(session_id, timestamp, source, coordinates, description, isPrivate, userName, route_patrol = false) {
    return (dispatch, getState) => {
        let postBody = {
            action: "add_bookmark",
            session_id,
            image_timestamp: timestamp,
            source,
            coordinates,
            description,
            video_key: "test video",
            private: isPrivate,
            route_patrol,
        };

        let url = "/sessions";

        let updatedBookmarks = _.cloneDeep(getState().railInspection.bookmarks);
        updatedBookmarks.push({
            session_id,
            image_timestamp: timestamp,
            source,
            image_coordinates: coordinates,
            description,
            video_key: "test video",
            private: true,
            user: userName,
        });

        dispatch(receiveBookmarks(updatedBookmarks));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (!response.success) {
                    updatedBookmarks.pop();
                    dispatch(receiveBookmarks(updatedBookmarks));
                } else {
                    notification.success({ message: "Successfully added bookmark", duration: 2.5, top: 80 });
                    dispatch(getInspectionBookmarks(session_id, route_patrol));
                }
            })
            .catch((error) => {
                console.log("add bookmark error: ", error);
            });
    };
}

export const deleteBookmark = (bookmark_id) => {
    return (dispatch, getState) => {
        let postBody = {
            action: "delete_bookmark",
            bookmark_id,
        };

        let url = "/sessions";

        let updatedBookmarks = _.cloneDeep(getState().railInspection.bookmarks);
        let deletingIndex = _.findIndex(updatedBookmarks, { id: bookmark_id });
        updatedBookmarks.splice(deletingIndex, 1);
        dispatch(receiveBookmarks(updatedBookmarks));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    notification.success({ message: "Successfully deleted bookmark", duration: 2.5 });
                }
            })
            .catch((error) => {
                console.log("deleting bookmark error: ", error);
            });
    };
};

export const updateBookmark = (bookmark_id, description, privacy, alert = true) => {
    return (dispatch, getState) => {
        let postBody = {
            action: "update_bookmark",
            bookmark_id,
            description,
            private: privacy,
        };

        let url = "/sessions";

        let updatedBookmarks = _.cloneDeep(getState().railInspection.bookmarks);
        let updatingIndex = _.findIndex(updatedBookmarks, { id: bookmark_id });
        if (updatedBookmarks[updatingIndex]) {
            updatedBookmarks[updatingIndex].description = description;
            updatedBookmarks[updatingIndex].private = privacy;
        }
        dispatch(receiveBookmarks(updatedBookmarks));

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    if (alert) {
                        notification.success({ message: "Successfully updated bookmark" });
                    }
                }
            })
            .catch((error) => {
                console.log("updating bookmark error: ", error);
            });
    };
};

export function getDeviceGroupInfo(sessionID) {
    return {
        queue: DEVICE_GROUP_INFO,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_device_group_info",
                session_id: sessionID,
            };
            let url = "/sessions";
            console.log("getting datapoint percentages");

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("response", response);

                    if (response) {
                        dispatch({
                            type: DEVICE_GROUP_INFO,
                            data: response,
                        });
                    }

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting datapoint percentages", "An error occurred while fetching datapoint percentages", error);
                    next();
                });
        },
    };
}

export function getInspectionDeviceConfig(sessionID) {
    return {
        queue: DEVICE_GROUP_INFO,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "rail_inspection_config",
                session_id: sessionID,
            };

            let url = "/routeMetadata";
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response) {
                        console.log("Rail inspection config response: ", response);
                        dispatch(primaryRailImageConfig(response));
                    }
                })
                .catch((error) => {
                    handleJsonPostError("Unable to fetch rail inspection config", error);
                });
        },
    };
}

export function getRailInspectionConditions() {
    return {
        queue: RAIL_INSPECTION_CONDITIONS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_conditions_types",
            };
            let url = "/routeMetadata";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    dispatch(setRailInspectionConditions(response.results));
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting rail inspection condtions", error);
                    next();
                });
        },
    };
}

export function updateRailInspectionDetectionCondition(detectionID, conditionID, callback) {
    return {
        queue: UPDATE_RAIL_INSPECTION_DETECTION_CONDITION,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "set_detection_condition_type",
                detectionID,
                conditionID,
            };
            let url = "/routeMetadata";
            const currentDataState = _.cloneDeep(getState().railInspection.detections.data);
            const newDataState = currentDataState.map((item) => (item.id === detectionID ? { ...item, condition_id: conditionID } : item));

            dispatch(updateRailInspectionDetectionConditions(newDataState));

            jsonPostV2(url, getState(), postBody, dispatch)
                .then(() => {
                    notification.success({ message: "Successfully updated condition", duration: 1.5 });
                    callback({ success: true });
                    next();
                })
                .catch((error) => {
                    console.log("debug error updating inspetion detection condtion type");
                    handleJsonPostError("Error updating inspetion detection condtion type", error);
                    dispatch(updateRailInspectionDetectionConditions(currentDataState));
                    callback({ success: false });
                    next();
                });
        },
    };
}

export function getComparableSessions(elr, position, callback) {
    return {
        queue: COMPARABLE_SESSIONS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_rail_compare_sessions",
                elr,
                position,
            };
            let url = "/sessions";

            console.log("debug getting comparable sessions", elr, position);
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("Got comparable session list:", response);

                    if (response.sessions) {
                        dispatch({
                            type: COMPARABLE_SESSIONS,
                            sessions: response.sessions,
                        });
                    }

                    callback();

                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting comparable sessions", "An error occurred while fetching comparable sessions", error);
                    next();
                });
        },
    };
}

export function getInspectionClassifications(session_id, page, replace = false) {
    console.log("debug running get_inspection_classifications", session_id);

    return {
        queue: GET_RAIL_INSPECTION_CLASSIFICATIONS,
        callback: (next, dispatch, getState) => {
            let postBody = {
                action: "get_inspection_classifications",
                session_id,
                page,
            };
            let url = "/routeMetadata";

            const reduxSessionID = getState().playlist.data.routeID;
            const fetchingSessionID = getState().railInspection.classifications.fetchingSession;
            console.log("debug session_id", session_id);
            console.log("debug reduxSessionID", reduxSessionID);
            console.log("debug fetchingSessionID", fetchingSessionID);
            console.log("debug page", page);

            if (session_id !== reduxSessionID || (session_id === fetchingSessionID && page === 0)) {
                next();
                return;
            }

            if (replace) {
                dispatch({
                    type: RESET_INSPECTION_CLASSIFICATIONS,
                });
                dispatch({
                    type: TOGGLE_FETCHING_CLASSIFICATIONS,
                    sessionID: session_id,
                });
            }

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.classifications) {
                        dispatch(inspectionClassifications(response.classifications, replace));
                    }

                    if (response.is_more) {
                        dispatch(getInspectionClassifications(session_id, page + 1));
                    } else {
                        dispatch(inspectionClassificationsFetched(true));
                    }
                    next();
                })
                .catch((error) => {
                    handleJsonPostError("Error getting classifications", "An error occurred while fetching inspection classifications", error);
                    dispatch(inspectionClassifications([]));
                    next();
                });
        },
    };
}

export function reviewClassification(id, reviewStatus) {
    return (dispatch, getState) => {
        let postBody = {
            action: "verify_inspection_classifications",
            id,
            reviewStatus,
        };

        let url = "/routeMetadata";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response) {
                    if (response.success) {
                        console.log("Successfully reviewed classification");
                    } else {
                        console.log("Error reviewing classification", response);
                    }
                }
            })
            .catch((error) => {
                console.log("Error reviewing classification", error);
            });
    };
}

export function searchForInspectionPulse(pulse, timestamp, deviceGroup, extraFilters, callback) {
    return (dispatch, getState) => {
        let postBody = {
            action: "find_session_from_pulse",
            pulse_count: pulse,
            timestamp,
            device_group: deviceGroup,
            extra_filters: extraFilters,
        };

        const url = "/mapGeometry";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                return callback(response);
            })
            .catch(() => {
                console.log("Error searching for timestamp");
                callback({});
            });
    };
}

export function fetchInspectionPulseCounts(sessionID, startTimestamp, endTimestamp) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_inspection_pulse_counts",
            session_id: sessionID,
            start_timestamp: startTimestamp,
            end_timestamp: endTimestamp,
        };

        const url = "/routeMetadata";

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                // return callback(response);
                console.log("debug pulse coounts response", response);
                if (response.pulse_counts) {
                    dispatch({
                        type: RECEIVE_INSPECTION_PULSE_COUNTS,
                        pulseCounts: response.pulse_counts,
                    });
                }
            })
            .catch(() => {
                console.log("Error getting inspection pulse counts");
            });
    };
}

export function exportInspectionDetections(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "export_inspection_detections",
                ...payload,
            };

            const url = "/routeMetadata";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug inspection export response", response);
                    resolve(response.success);
                })
                .catch((e) => {
                    console.log("Error getting inspection pulse counts");
                    reject(e);
                });
        });
    };
}

export function fetchInspectionSperryFaults(sessionID) {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_utu_suspects_for_session",
            session_id: sessionID,
        };

        const url = "/routeMetadata";

        return new Promise((resolve, reject) => {
            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    // return callback(response);
                    console.log("debug get suspects response", response);
                    if (response.faults) {
                        dispatch({
                            type: RECEIVE_INSPECTION_FAULTS,
                            faults: response.faults,
                        });
                        resolve();
                    }
                })
                .catch(() => {
                    console.log("Error getting inspection pulse counts");
                });
        });
    };
}

export function getInspectionConditionTypes() {
    return (dispatch, getState) => {
        let postBody = {
            action: "get_rail_inspection_condition_types",
        };

        const url = "/routeMetadata";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                dispatch(receiveInspectionConditionTypes(response));
                const conditionFilters = {};
                if (response) {
                    _.forEach(Object.keys(response), (det) => {
                        conditionFilters[det] = {};

                        if (det === "Joint") {
                            conditionFilters[det].dip_angle = ["dip_angle_present", "no_dip_angle", "none"];
                        }

                        _.forEach(Object.keys(response[det]), (type) => {
                            conditionFilters[det][type] = [];
                            const dataValues = _.get(response[det][type], "data_values", {});

                            _.forEach(Object.keys(dataValues), (value) => {
                                if (typeof dataValues[value] === "string") {
                                    conditionFilters[det][type].push(dataValues[value]);
                                } else {
                                    conditionFilters[det][type].push(value);
                                }
                            });

                            conditionFilters[det][type].push("none");
                        });
                    });
                }
                dispatch(setDetectionConditionFilters(conditionFilters));
            })
            .catch(() => {
                console.log("Error getting rail inspection condition types");
            });
    };
}

export function setDetectionConditionData(detectionID, userData, dataType) {
    return (dispatch, getState) => {
        let postBody = {
            action: "set_detection_condition_data",
            detectionID,
            userData,
            dataType,
        };

        const url = "/routeMetadata";

        console.log(postBody);

        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                if (response.success) {
                    console.log("Successfully submitted detection condition data");
                    const detectionData = _.cloneDeep(getState().railInspection.detections.data);
                    const updatedDetectionsData = detectionData.map((item) =>
                        item.id === detectionID
                            ? { ...item, conditions: { ...item.conditions, [dataType]: { ...item.conditions.dataType, user: userData } } }
                            : item,
                    );
                    dispatch(updateRailInspectionDetectionConditions(updatedDetectionsData));
                } else {
                    console.log("Failed to submit detection condition data");
                    notification.error("Failed to submit data");
                }
            })
            .catch((error) => {
                notification.error("Failed to submit data");
                console.log("Failed to submit detection condition data", error);
            });
    };
}
