import React, { useMemo, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import MarkerExportModal from "../MarkerExportModal";
import MarkerThresholdFiltersModal from "../MarkerThresholdFiltersModal";
import { selectTag, routeSelected, selectMarker, customAudit, getObservationDetections, clearMarkerInfo } from "redux/actions/index";
import { Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEyeSlash, faQuestion, faTimes, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import memoize from "memoize-one";
import Tippy from "@tippyjs/react";
import { filterSessionObservations, getStreamFromVideoKey, findClosestMarker, combineContinuousObservations } from "components/util/PlaylistUtils";

const routeIsLoadedSelector = (state) => {
    const routeID = state.playlist.data.routeID;
    const isVideo = state.playlist.position.isVideo;
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    return routeID && playlist && index !== -1;
};
const filtersArraySelector = (state) => state.markerReviewFilters || [];
const filtersConditionArraySelector = (state) => state.markerConditionFilter || [];
const markerPerSessionSelector = (state) => state.markers.perSession;
const routeIDSelector = (state) => state.playlist.data.routeID;
const thresholdFilterSelector = (state) => state.markerThresholdFilters;
const selectedTagCategorySelector = (state) => state.selectedTagCategory;
const sessionObservationsSelector = (state) => state.sessionObservations;
const sessionDataSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], null);
const observationFilterSelector = (state) => state.observationFilters;
const detectionTypesSelector = (state) => state.detectionTypes;
const playlistSelector = (state) =>
    state.playlist.position.isVideo ? _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex], []) : state.playlist.data.image;
const indexSelector = (state) => state.playlist.position.currentIndex;
const timeOffsetSelector = (state) => state.playlist.position.currentTimeOffset;

const markerConverter = memoize((markers, filtersArray, filtersConditionArray, observation, observationFilters = []) => {
    let formattedMarkers = {};

    markers.forEach((marker) => {
        const markerName = !observation ? marker.name : marker.class;
        if (!formattedMarkers[markerName]) {
            formattedMarkers[markerName] = {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                count: 0,
                needsReviewFiltered: filtersArray.includes(0),
                verifiedFiltered: filtersArray.includes(1),
                verifiedHiddenFiltered: filtersArray.includes(2),
                rejectedFiltered: filtersArray.includes(3),
                displayName: marker.display_name || markerName,
            };
        }

        formattedMarkers[markerName][marker.review_status] += 1;

        if (!marker.condition_id || (filtersConditionArray[marker.name] && filtersConditionArray[marker.name].includes(marker.condition_id))) {
            let markerExtents = _.get(marker, "extents", []);
            if (markerExtents.length) {
                if (marker.class === "Troughing") {
                    const troughingCondition = _.get(observationFilters, "condition", []);
                    const troughingType = _.get(observationFilters, "troughingType", []);
                    if (troughingCondition.length || troughingType.length) {
                        markerExtents = _.filter(markerExtents, (extent) => {
                            let condition = _.get(extent, "classifications.troughing_condition");
                            condition = _.get(condition, "user") ? condition.user : _.get(condition, "auto");
                            let type = _.get(extent, "classifications.troughing_type");
                            type = _.get(type, "user") ? type.user : _.get(type, "auto");
                            if (troughingCondition.length && troughingType.length) {
                                return troughingCondition.includes(condition) && troughingType.includes(type);
                            } else {
                                return troughingCondition.includes(condition) || troughingType.includes(type);
                            }
                        });
                    }
                }
                const markerExtentReviews = _.map(markerExtents, (extent) => extent.review_status);
                if (!_.isEmpty(_.intersection(markerExtentReviews, filtersArray))) {
                    formattedMarkers[markerName].count += 1;
                }
            } else if (filtersArray.includes(marker.review_status)) {
                formattedMarkers[markerName].count += 1;
            }

            if (!formattedMarkers[markerName].videoKey) {
                formattedMarkers[markerName].videoKey = marker.video_key;
                formattedMarkers[markerName].frame = marker.frame;
            }
        }
    });

    return formattedMarkers;
});

const MarkerButtons = ({ filtersArray, formattedMarkers, hasMarkers, allMarkers, allObservations }) => {
    const selectedTagCategory = useSelector(selectedTagCategorySelector);
    const sessionID = useSelector(routeIDSelector);
    const playlist = useSelector(playlistSelector);
    const index = useSelector(indexSelector);
    const timeOffset = useSelector(timeOffsetSelector);

    const currentVideoKey = _.get(playlist, [index, 0]);

    let dispatch = useDispatch();

    if (!_.isEmpty(formattedMarkers)) {
        return Object.keys(formattedMarkers).map((markerType) => {
            const markerData = formattedMarkers[markerType];
            let bg = "#4F4776";
            let opacity = "1";
            let clickHandler = () => {
                dispatch(
                    customAudit(
                        "detection_type_selected",
                        { detection_type: markerType, session_id: sessionID },
                        `detection_type_selected used type: ${markerType}`,
                    ),
                );
                dispatch(selectTag(markerType));
                if (markerData.videoKey) {
                    let formatMarkers;
                    const isMarker = _.some(allMarkers, ["name", markerType]);
                    if (isMarker) {
                        formatMarkers = _.filter(allMarkers, (marker) => marker.name === markerType && filtersArray.includes(marker.review_status));
                    } else {
                        formatMarkers = _.filter(allObservations, (obs) => {
                            if (obs.class === markerType) {
                                const observationExtents = _.get(obs, "extents", []);
                                if (observationExtents.length) {
                                    const observationExtentReviews = _.map(observationExtents, (extent) => extent.review_status);
                                    return !_.isEmpty(_.intersection(observationExtentReviews, filtersArray));
                                } else {
                                    return filtersArray.includes(obs.review_status);
                                }
                            }

                            return false;
                        });
                        if (formatMarkers.length && _.has(formatMarkers[0], "extents") && formatMarkers[0].extents.length) {
                            formatMarkers = _.flatMap(formatMarkers, "extents");
                        }
                    }

                    const closestMarker = findClosestMarker(formatMarkers, currentVideoKey, playlist, index, timeOffset);

                    dispatch(selectMarker(closestMarker, _.has(closestMarker, "observation_number")));

                    let videoKey = markerData.videoKey;
                    let frame = 0;

                    if (closestMarker && (closestMarker.videoKey || closestMarker.video_key)) {
                        videoKey = _.has(closestMarker, "video_key") ? closestMarker.video_key : closestMarker.videoKey;
                        frame = closestMarker.frame;
                    }
                    dispatch(routeSelected(sessionID, videoKey, false, true, undefined, frame));
                    if (!isMarker) {
                        dispatch(getObservationDetections(sessionID, closestMarker.class, closestMarker.continuous));
                    }
                }
            };

            if (markerType === selectedTagCategory) {
                bg = "#7A60CC";
                clickHandler = () => {
                    dispatch(selectTag(null));
                    dispatch(clearMarkerInfo());
                };
            }

            if (markerData.count === 0) {
                opacity = "0.5";
                bg = "#4F4776";
                clickHandler = () => {};
            }

            let content = (
                <>
                    <div className={`RouteInfo-Incidents-Item-Count popover green ${!markerData.verifiedFiltered ? "faded" : ""}`}>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>{markerData[1]} verified</span>
                    </div>
                    <div className={`RouteInfo-Incidents-Item-Count popover greenOutline ${!markerData.verifiedHiddenFiltered ? "faded" : ""}`}>
                        <FontAwesomeIcon icon={faEyeSlash} />
                        <span>{markerData[2]} verified & hidden</span>
                    </div>
                    <div className={`RouteInfo-Incidents-Item-Count popover orange ${!markerData.needsReviewFiltered ? "faded" : ""}`}>
                        <FontAwesomeIcon icon={faQuestion} />
                        <span>{markerData[0]} needs review</span>
                    </div>
                    <div className={`RouteInfo-Incidents-Item-Count popover red ${!markerData.rejectedFiltered ? "faded" : ""}`}>
                        <FontAwesomeIcon icon={faTimes} />
                        <span>{markerData[3]} rejected</span>
                    </div>
                </>
            );

            return (
                <Popover
                    content={content}
                    key={markerType}>
                    <div
                        onClick={clickHandler}
                        className="RouteInfo-Incidents-Item"
                        style={{ background: bg, opacity: opacity }}>
                        <div className="RouteInfo-Incidents-Item-Count">
                            <span>{markerData.count}</span>
                        </div>
                        <div className="RouteInfo-Incidents-Item-Label">{markerData.displayName}</div>
                    </div>
                </Popover>
            );
        });
    } else if (hasMarkers) {
        return <span className="secondary">All Markers Filtered</span>;
    } else {
        return <span className="secondary">No Route Markers</span>;
    }
};

const emptyObject = {};

const MarkerPanel = ({ displayDownload = true }) => {
    const routeIsLoaded = useSelector(routeIsLoadedSelector);
    const filtersArray = useSelector(filtersArraySelector);
    const filtersConditionArray = useSelector(filtersConditionArraySelector);
    const observationFilters = useSelector(observationFilterSelector);

    const markerPerSession = useSelector(markerPerSessionSelector);
    const routeID = useSelector(routeIDSelector);
    const thresholdFilters = useSelector(thresholdFilterSelector);
    const detectionTypes = useSelector(detectionTypesSelector);
    const sessionData = useSelector(sessionDataSelector);

    const sessionObservations = useSelector(sessionObservationsSelector);

    const [displayMarkupExport, toggleDisplayMarkupExport] = useState(false);
    const [markerThresholdFilterOpen, setMarkerThresholdFilterOpen] = useState(false);

    const currentRouteID = useSelector((state) => state.playlist.data.routeID);
    const defaultMarkers = useSelector((state) => state.defaultMarkersThresholds[currentRouteID] || emptyObject);

    const allMarkers = useMemo(() => {
        return _.get(markerPerSession, [routeID], []).filter((marker) => marker.source !== "ANNOTATION");
    }, [markerPerSession, routeID]);

    const filteredObservations = useMemo(() => {
        const amendedObservations = filterSessionObservations(sessionObservations, observationFilters);
        return combineContinuousObservations(amendedObservations);
    }, [sessionObservations, observationFilters]);

    const filteredMarkers = useMemo(() => {
        let filteredMarkers = allMarkers;

        const streamInfo = _.get(sessionData, "stream_info", []);
        const streamsByIndex = _.keyBy(streamInfo, "stream_index");
        filteredMarkers = filteredMarkers.filter((marker) => {
            const markerStreamSource = getStreamFromVideoKey(marker.video_key);
            const markerStreamInfo = _.get(streamsByIndex, markerStreamSource, {});
            if (markerStreamInfo.archived) {
                return false;
            }

            let detectionType = _.find(detectionTypes, function (detType) {
                return detType.type === marker.name;
            });
            if (!detectionType || detectionType.display_type === "never") {
                return false;
            }

            if (detectionType) {
                const defValue = _.get(defaultMarkers, [detectionType.type], detectionType.default_score);
                if (!_.isNil(thresholdFilters[detectionType.type])) {
                    if (marker.score && marker.score < thresholdFilters[detectionType.type]) {
                        return false;
                    }
                } else if (defValue) {
                    if (marker.score && marker.score < defValue) {
                        return false;
                    }
                }
            }

            return true;
        });

        return filteredMarkers;
    }, [allMarkers, defaultMarkers, detectionTypes, sessionData, thresholdFilters]);

    const formattedMarkers = useMemo(() => {
        let formattedMarkers = {};
        let formattedObservations = {};

        if (filteredMarkers && filtersArray) {
            formattedMarkers = markerConverter(filteredMarkers, filtersArray, filtersConditionArray, false);
        }

        if (filteredObservations && filtersArray) {
            formattedObservations = markerConverter(filteredObservations, filtersArray, filtersConditionArray, true, observationFilters);
        }

        return { ...formattedMarkers, ...formattedObservations };
    }, [filteredMarkers, filtersArray, filtersConditionArray, filteredObservations, observationFilters]);

    return (
        <div
            className="RouteInfo-Incidents"
            id="intro-tour-info-markers">
            {displayMarkupExport && <MarkerExportModal closeModal={() => toggleDisplayMarkupExport((prev) => !prev)} />}
            <MarkerThresholdFiltersModal
                markerThresholdFilterOpen={markerThresholdFilterOpen}
                setMarkerThresholdFilterOpen={setMarkerThresholdFilterOpen}
            />

            <div className="RouteInfo-Text-Container">
                {routeIsLoaded ? (
                    <>
                        <MarkerButtons
                            filtersArray={filtersArray}
                            formattedMarkers={formattedMarkers}
                            hasMarkers={allMarkers.length + filteredObservations.length}
                            allMarkers={filteredMarkers}
                            allObservations={filteredObservations}
                        />
                        {displayDownload && (
                            <div
                                className="RouteInfo-Incidents-Filter"
                                onClick={() => setMarkerThresholdFilterOpen(true)}>
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    style={{ color: "#40A9FF" }}
                                />
                            </div>
                        )}
                        {!_.isEmpty(formattedMarkers) && displayDownload && (
                            <Tippy
                                placement="top"
                                content="Download Detections"
                                theme="aivrlight"
                                zIndex="600">
                                <div className="RouteInfo-Incidents-Filter">
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className={"ExportMarkerButton"}
                                        onClick={() => toggleDisplayMarkupExport((prev) => !prev)}
                                    />
                                </div>
                            </Tippy>
                        )}
                    </>
                ) : (
                    <span className="secondary">No Route Selected</span>
                )}
            </div>
        </div>
    );
};

export default MarkerPanel;
