import React, { useEffect, useMemo, useRef } from "react";
import { Provider, useSelector, useStore } from "react-redux";
import { calculateRouteCoordinatesForLocation, getCameraCharacteristics, getHeading, coordinateToTimestamp } from "../../../util/Geometry";
import _ from "lodash";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import ThreeDRails from "./3dRails";
import ThreeDFeature from "./3dFeature";
import SunCalc from "suncalc";
import { PerspectiveCamera, Plane, Preload } from "@react-three/drei";
import ThreeDCursor from "./3dCursor";
import { WithAspectRatio } from "../../../util/WithAspectRatio";
import { Color } from "three";
import GPSCorrection from "./GPSCorrection";
import ThreeDCorridor from "./3dCorridor";
import ThreeDTunnel from "./3dTunnel";
import ThreeDGroundClearance from "./3dGroundClearance";

function updateCameraCalibration({ pitch, yaw, roll }, camera) {
    camera.rotation.set(pitch, yaw, roll, "YXZ");
}

const currentPositionSelector = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const playlist = _.get(state.playlist.data, ["video", sourceIndex]);
    const playlistIndex = state.playlist.position.currentIndex;
    const timeOffset = state.playlist.position.currentTimeOffset || 0;
    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }
    const use_snapped = state.snappedRoute || false;

    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;

    return {
        location: state.playlist.position.coords,
        heading: getHeading(playlist, playlistIndex, timeOffset, offsets, use_snapped, sessionIsBackward),
        timestamp: _.get(playlist, [playlistIndex, 3, 2], 0) + timeOffset,
    };
};

const routeLocationSelector = (state) => state.playlist.data.route_locations;
const offsetsSelector = (state) => _.get(state, ["gpsTimeOffsets", "offsets"], []);
const sourceIndexSelector = (state) => state.playlist.position.sourceIndex;
const playlistsSelector = (state) => state.playlist.data.video;
const showSnappedSelector = (state) => state.snappedRoute || false;
const overlayFeaturesSelector = (state) => state.featureOverlay.features;
const isEnhancedSelector = (state) => state.playlist.position.isEnhanced;
const featureOverlayIsEnabledSelector = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const userViewEnabled = !!_.get(state.views, [state.userDetails.userConfig.view_id, "ui_controls", "feature_overlay"], false);
    const workspaceViewEnabled = !!_.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "ui_controls", "feature_overlay"], false);
    const session = state.sessions[state.playlist.data.routeID];
    return userViewEnabled || workspaceViewEnabled || _.get(session, ["3d_features_enabled"], false);
};

const safecessOverlaySelector = (state) => _.get(state.playlist.overlays, "Safe Cess", null);
const tunnelOverlaySelector = (state) => _.get(state.playlist.overlays, "Tunnel", null);
const groundClearanceOverlaySelector = (state) => _.get(state.playlist.overlays, "Ground Clearance", null);
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const calibrationSelector = (state) => {
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
        if (currentDashboard) {
            defaultRailSeparation = currentDashboard.config.measurements_rail_gauge / 1000;
        }
    }

    return get_source_calibration(
        state.measurement.calibration,
        state.playlist.position.sourceIndex,
        getAbsoluteTimestamp(state.playlist) * 1000,
        true,
        defaultRailSeparation,
    );
};

function ratioBetween(i, min, max) {
    return (i - min) / (max - min);
}

export const ThreeDFeatureOverlayScene = ({
    showRails,
    showCorridor,
    showTunnel,
    showGPSCorrection,
    showLabels,
    cursorLocation,
    cursorOrientation,
    cursorType,
    cursorOptions,
    highlightedFeature,
    selectedFeature,
    hiddenFeature,
    callback,
    calibration,
}) => {
    if (showLabels === undefined) {
        showLabels = true;
    }

    const store = useStore();
    const state = store.getState();

    const { invalidate } = useThree();

    const currentPositionRef = useRef(currentPositionSelector(state));
    const lightRef = useRef();
    const ambientLightRef = useRef();
    const sunBrightnessRef = useRef(0);
    const cameraControlRef = useRef();

    const sunColor = useMemo(() => new Color(0, 0, 0), []);

    const playlists = useSelector(playlistsSelector);
    const sourceIndex = useSelector(sourceIndexSelector);
    const useSnapped = useSelector(showSnappedSelector);
    const allOffsets = useSelector(offsetsSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const featureOverlayIsEnabled = useSelector(featureOverlayIsEnabledSelector);
    const isEnhanced = useSelector(isEnhancedSelector) === "enhanced";
    let _showRails = (isEnhanced && featureOverlayIsEnabled) || showRails;
    const safecessOverlay = useSelector(safecessOverlaySelector);
    const tunnelOverlay = useSelector(tunnelOverlaySelector);
    const groundClearanceOverlay = useSelector(groundClearanceOverlaySelector);
    const _calibration = useSelector(calibrationSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    if (showRails === false) {
        _showRails = false;
    }

    const featureData = useSelector(overlayFeaturesSelector, () => true);

    const calibrationToUse = useMemo(() => {
        if (calibration === undefined) {
            return _calibration;
        } else {
            return calibration;
        }
    }, [_calibration, calibration]);

    const currentSourceOffsets = useMemo(() => {
        return _.get(allOffsets, [sourceIndex], []);
    }, [allOffsets, sourceIndex]);

    const cursorRoutePosition = useMemo(() => {
        if (cursorLocation) {
            const coordinates = cursorLocation;
            const playlist = playlists[sourceIndex];
            const timestamp = coordinateToTimestamp(coordinates, playlist, useSnapped, currentSourceOffsets, true) * 1000;
            const elr = calculateRouteCoordinatesForLocation(timestamp, routeLocationData, routeSystemID);
            return elr;
        } else {
            return null;
        }
    }, [cursorLocation, currentSourceOffsets, playlists, sourceIndex, useSnapped, routeLocationData, routeSystemID]);

    const features = useMemo(
        () =>
            featureOverlayIsEnabled
                ? _.map(featureData, (feature, idx) => {
                      return (
                          <ThreeDFeature
                              key={`feature${idx}`}
                              feature={feature}
                              showLabel={showLabels}
                              selected={selectedFeature === idx}
                              highlighted={highlightedFeature === idx}
                              sunBrightness={sunBrightnessRef}
                              hidden={hiddenFeature === idx}
                          />
                      );
                  })
                : null,
        [showLabels, featureData, selectedFeature, highlightedFeature, hiddenFeature, featureOverlayIsEnabled],
    );

    const rails = useMemo(() => {
        if (_showRails) {
            return <ThreeDRails />;
        } else {
            return null;
        }
    }, [_showRails]);

    const tunnel = useMemo(() => {
        if (tunnelOverlay && tunnelOverlay.enabled) {
            return <ThreeDTunnel />;
        } else {
            return null;
        }
    }, [tunnelOverlay]);

    const corridor = useMemo(() => {
        if (safecessOverlay && safecessOverlay.enabled) {
            return <ThreeDCorridor />;
        } else {
            return null;
        }
    }, [safecessOverlay]);

    const groundClearance = useMemo(() => {
        if (groundClearanceOverlay && groundClearanceOverlay.enabled) {
            return <ThreeDGroundClearance />;
        } else {
            return null;
        }
    }, [groundClearanceOverlay]);

    const gpsCorrection = useMemo(() => {
        if (showGPSCorrection) {
            return <GPSCorrection />;
        } else {
            return null;
        }
    }, [showGPSCorrection]);

    const cursorFeature = useMemo(() => {
        if (cursorLocation) {
            return (
                <ThreeDCursor
                    heading={cursorOrientation}
                    location={cursorLocation}
                    type={cursorType}
                    showLabel={showLabels}
                    options={cursorOptions}
                    routePosition={cursorRoutePosition}
                    sunBrightness={sunBrightnessRef}
                    showOrientation={cursorType !== "flag"}
                />
            );
        } else {
            return null;
        }
    }, [showLabels, cursorLocation, cursorOrientation, cursorType, cursorRoutePosition, cursorOptions]);

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState();
            const currentPosition = currentPositionSelector(state);
            let shouldInvalidate = false;

            if (
                !currentPositionRef.current ||
                currentPosition.location !== currentPositionRef.current.location ||
                currentPosition.heading !== currentPositionRef.current.heading ||
                currentPosition.timestamp !== currentPositionRef.current.timestamp
            ) {
                currentPositionRef.current = currentPosition;
                shouldInvalidate = true;
            }

            if (shouldInvalidate) {
                invalidate();
            }
        });
    }, [store, currentPositionRef, invalidate]);

    useFrame(({ camera }) => {
        if (!currentPositionRef.current) {
            return;
        }
        const location = currentPositionRef.current.location;
        const heading = currentPositionRef.current.heading;
        const timestamp = currentPositionRef.current.timestamp;

        if (!location) {
            return;
        }
        if (calibrationToUse === null) {
            return;
        }

        const deviceFov = calibrationToUse.horizontalFov;
        const deviceAspectRatio = calibrationToUse.aspectRatio;
        const vFov = (Math.atan(deviceAspectRatio * Math.tan((Math.PI * deviceFov) / 360)) * 360) / Math.PI;
        const currentDate = new Date(timestamp * 1000);
        const sunPosition = SunCalc.getPosition(currentDate, location[1], location[0]);
        const sunPosE = Math.sin(sunPosition.azimuth) * Math.cos(sunPosition.altitude);
        const sunPosN = Math.cos(sunPosition.azimuth) * Math.cos(sunPosition.altitude);
        const sunPosX = Math.cos(heading) * sunPosE + Math.sin(heading) * sunPosN;
        const sunPosY = Math.cos(heading) * sunPosN - Math.sin(heading) * sunPosE;

        const sunAltDegs = (sunPosition.altitude * 180) / Math.PI;

        if (sunAltDegs >= 6) {
            sunBrightnessRef.current = 1;
            sunColor.setRGB(1, 1, 1);
        } else if (sunAltDegs >= -0.3) {
            const height = ratioBetween(sunAltDegs, -0.3, 6);
            sunBrightnessRef.current = 0.5 + 0.5 * height;
            sunColor.setRGB(1, 0.5 + 0.5 * height, height);
        } else if (sunAltDegs >= -0.833) {
            const height = ratioBetween(sunAltDegs, -0.833, -0.3);
            sunBrightnessRef.current = 0.25 + 0.25 * height;
            sunColor.setRGB(0.5 + 0.5 * height, 0.5, 1.0 - height);
        } else if (sunAltDegs >= -6) {
            const height = ratioBetween(sunAltDegs, -6, -0.833);
            sunBrightnessRef.current = 0.1 + 0.15 * height;
            sunColor.setRGB(0.5, 0.5, 1);
        } else if (sunAltDegs >= -12) {
            const height = ratioBetween(sunAltDegs, -12, -6);
            sunBrightnessRef.current = 0.1 * height;
            sunColor.setRGB(0.5, 0.5, 1);
        } else {
            sunBrightnessRef.current = 0;
            sunColor.setRGB(0, 0, 0);
        }

        const sunCoordinates = [sunPosY * 20, Math.sin(sunPosition.altitude) * 20, sunPosX * 20];

        const { pitch, yaw, roll, x, y, z } = getCameraCharacteristics(calibrationToUse);
        camera.fov = vFov;
        camera.aspect = 1 / calibrationToUse.aspectRatio;
        camera.updateProjectionMatrix();
        updateCameraCalibration({ pitch, yaw, roll }, camera);

        if (cameraControlRef.current) {
            cameraControlRef.current.rotation.set(0, -heading, 0);
            cameraControlRef.current.position.set(-x, -y, -z);
        }

        if (lightRef.current) {
            if (sunPosition.altitude >= 0) {
                lightRef.current.position.set(sunCoordinates[0], sunCoordinates[1], sunCoordinates[2]);
                lightRef.current.intensity = 2 * (sunBrightnessRef.current - 0.5);
            } else {
                lightRef.current.intensity = 0;
            }
            lightRef.current.color = sunColor;
        }

        if (ambientLightRef.current) {
            ambientLightRef.current.intensity = sunBrightnessRef.current;
            ambientLightRef.current.color = sunColor;
        }
    });

    useFrame(
        ({ gl, scene, camera }) => {
            if (callback) {
                gl.render(scene, camera);
                callback();
            }
        },
        callback ? 1 : 0,
    );

    if (calibrationToUse === null) {
        return null;
    }

    return (
        <scene>
            <PerspectiveCamera
                makeDefault
                fov={66.5}
                aspect={1.77778}
                near={0.01}
                far={500}
                position={[0, 0, 0]}
            />
            <ambientLight
                ref={ambientLightRef}
                color={"#808080"}
            />
            <directionalLight
                ref={lightRef}
                color={"#ffffff"}
                intensity={0.7}
                castShadow
                position={[0, 20, 0]}
                shadow-camera-near={1}
                shadow-camera-far={100}
                shadow-camera-left={-50}
                shadow-camera-right={50}
                shadow-camera-top={50}
                shadow-camera-bottom={-50}
                shadow-mapSize-height={1024}
                shadow-mapSize-width={1024}
            />
            <group ref={cameraControlRef}>
                {rails}
                {corridor}
                {tunnel}
                {groundClearance}
                {gpsCorrection}
                {features}
                {cursorFeature}
                <Plane
                    receiveShadow
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, -0.01, 0]}
                    args={[500, 500]}
                    renderOrder={10}>
                    <shadowMaterial
                        attach="material"
                        opacity={0.6}
                        transparent
                    />
                </Plane>
            </group>

            <Preload all />
        </scene>
    );
};

const ThreeDFeatureOverlay = (props) => {
    const store = useStore();
    const _calibration = useSelector(calibrationSelector);

    const calibrationToUse = useMemo(() => {
        if (props.calibration === undefined) {
            return _calibration;
        } else {
            return props.calibration;
        }
    }, [_calibration, props.calibration]);

    return (
        <WithAspectRatio ratio={1 / calibrationToUse.aspectRatio}>
            <Canvas
                concurrent
                gl={{ alpha: true }}
                shadows={true}>
                <Provider store={store}>
                    <ThreeDFeatureOverlayScene {...props} />
                </Provider>
            </Canvas>
        </WithAspectRatio>
    );
};

export default ThreeDFeatureOverlay;
