import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { reviewDetection, toggleRejectionModal } from "../../../../redux/actions/index";

const RejectionModal = ({ detection, goForward, autoNavigation }) => {
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const visible = useSelector((state) => state.toggleRejectionModal);

    const [reviewComment, setReviewComment] = useState("");
    const [option, setOption] = useState("Surface damage");

    const handleOk = () => {
        dispatch(toggleRejectionModal());
        const comment = option + ": " + reviewComment;
        dispatch(reviewDetection(detection.id, 3, comment));
        if (detection.connecting_detections && detection.connecting_detections.length) {
            detection.connecting_detections.forEach((detection) => {
                console.log("debug reviewing connecting detection", detection);
                dispatch(reviewDetection(detection.id, 3, comment, true));
            });
        }
        if (autoNavigation) {
            goForward();
        }

        setReviewComment("");
        setOption("Surface damage");
    };

    const handleCancel = () => {
        setReviewComment("");
        dispatch(toggleRejectionModal());
    };

    return (
        <Modal
            title="Reject Inspection Detection"
            visible={visible}
            onCancel={() => dispatch(toggleRejectionModal())}
            footer={[
                <Button
                    key="back"
                    onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="danger"
                    onClick={handleOk}>
                    Reject Detection
                </Button>,
            ]}>
            <Select
                style={{ width: "100%", paddingBottom: "1rem" }}
                defaultValue="Surface damage"
                onChange={(value) => {
                    console.log(value);
                    setOption(value);
                }}>
                <Select.Option value="Surface damage">Surface damage</Select.Option>
                <Select.Option value="Contamination">Contamination</Select.Option>
                <Select.Option value="Rolling contact fatigue">Rolling contact fatigue</Select.Option>
                <Select.Option value="Wide gap weld">Wide gap weld</Select.Option>
                <Select.Option value="Wheel burn">Wheel burn</Select.Option>
                <Select.Option value="Longitudinal split">Longitudinal split</Select.Option>
                <Select.Option value="allast imprint">Ballast imprint</Select.Option>
                <Select.Option value="Variation in running band">Variation in running band</Select.Option>
                <Select.Option value="Other / Unsure">Other / Unsure</Select.Option>
            </Select>
            <TextArea
                rows={4}
                placeholder="No comment"
                value={reviewComment}
                onChange={(e) => {
                    setReviewComment(e.target.value);
                }}
            />
        </Modal>
    );
};

export default RejectionModal;
