import React from "react";
import { LineChart, Line } from "recharts";
import Draggable from "react-draggable";

class Brush extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: {
                x: 0,
                y: 0,
            },
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.position !== prevProps.position) {
            this.setState({
                position: {
                    x: this.props.position,
                    y: 0,
                },
            });
        }
    }

    handleDrag = (evt, data) => {
        // called continuously while the brush is dragged
        // evt is a mousemove event
        // data is a custom object that draggable library gives us
        // {x, y, deltaX, deltaY, lastX, lastY, node}
        this.setState({
            position: { x: data.x, y: 0 },
        });
        let chart = document.getElementById("toBeScrolled");
        chart.scroll(data.x * (this.props.chartWidth / 900), null);
    };

    handleClickScroll = (evt) => {
        if (evt === null) {
            return;
        }
        this.setState({
            position: { x: evt.chartX, y: 0 },
        });
        let chart = document.getElementById("toBeScrolled");
        chart.scroll(evt.chartX * (this.props.chartWidth / 900), null);
    };

    render() {
        return (
            <div className="analyticsBrushContainer">
                <div className="analyticsDraggableContainer">
                    <Draggable
                        axis="x"
                        bounds="parent"
                        onDrag={this.handleDrag}
                        position={this.state.position}>
                        <div
                            className="analyticsBrushHandle"
                            style={{ width: this.props.chartWidth ? (900 / this.props.chartWidth) * 900 : 10 }}>
                            <div className="leftPad"></div>
                            <div
                                className="rightPad"
                                style={{ left: this.props.chartWidth ? (900 / this.props.chartWidth) * (900 - 3) : 5 }}></div>
                        </div>
                    </Draggable>
                </div>
                <LineChart
                    width={900}
                    height={75}
                    data={this.props.data}
                    onClick={this.handleClickScroll}>
                    <Line
                        type="step"
                        dataKey="score"
                        stroke="#8884d8"
                        dot={false}
                        activeDot={false}
                        connectNulls={true}
                        isAnimationActive={false}
                    />
                </LineChart>
            </div>
        );
    }
}

export default Brush;
