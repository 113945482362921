import React, { useState } from "react";
import { faClipboard, faQrcode, faUpload, faBan, faCloudUploadAlt } from "@fortawesome/pro-regular-svg-icons";
import OBCButton from "../../components/OBC/OBCButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Modal } from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { convertToTimezone } from "components/util/TimezoneUtils";
import { useSelector } from "react-redux";

const userConfigSelector = (state) => state.userDetails.userConfig;

const UploadManagerListItem = ({ setQrCodeModalOpen, setQrCodeModalText, onRevokeTokenClick, tokenData, searchString }) => {
    let BASE_URL = window.location.origin;
    const userConfig = useSelector(userConfigSelector);

    const { token, description, created_ts: createdTs, last_upload: lastUploadTs, uploaded_files_count: uploadedFilesCount } = tokenData;

    const [open, setOpen] = useState(false);
    const [copyToClipboardText, setCopyToClipboardText] = useState("Copy link to the clipboard");
    const uploadLink = BASE_URL + "/upload/" + token;

    const toggleOpen = () => {
        setOpen(!open);
    };

    const onCopyLinkCliked = (e) => {
        e.stopPropagation();
        setCopyToClipboardText("Copied!");
        navigator.clipboard.writeText(uploadLink);
    };

    const onGenerateQrCodeClicked = (e) => {
        e.stopPropagation();
        setQrCodeModalOpen(true);
        setQrCodeModalText(uploadLink);
    };

    const onUploadNowClicked = (e) => {
        e.stopPropagation();
        window.open("/upload/" + token, "_blank");
    };

    const onRevokeClicked = (e) => {
        e.stopPropagation();
        Modal.confirm({
            title: "Revoke access token?",
            content:
                "By revoking acceass token upload link for this token will not longer be valid but all data uploaded so far will remain avaialble in your workspace.",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                revokeToken();
            },
        });
    };

    const revokeToken = () => {
        toggleOpen();
        onRevokeTokenClick(token);
    };

    const convertTS = (date_ts) => {
        const date = new Date(date_ts * 1000);
        return convertToTimezone(date, userConfig.convert_to_utc);
    };

    return (
        <div
            className={`TokenListItem ${open ? "active" : ""}`}
            onClick={toggleOpen}>
            <div className="TokenListItemMain">
                <div className="TokenListItemTopRow">
                    <div className="InfoPanel">
                        <div className="InfoText">
                            Description:
                            <Highlighter
                                highlightClassName="ObcTextHighlight"
                                searchWords={searchString.split(" ")}
                                autoEscape={true}
                                textToHighlight={description}
                            />
                        </div>
                        <div className="InfoText">
                            Last Upload:
                            <Tooltip title={lastUploadTs ? convertTS(lastUploadTs) : null}>
                                {lastUploadTs ? <span>{moment.unix(lastUploadTs).fromNow()}</span> : <span className="grayyed">Unknown</span>}
                            </Tooltip>
                        </div>
                        <dic className="InfoText">
                            {" "}
                            Created:
                            <Tooltip title={createdTs ? convertTS(createdTs) : null}>
                                {createdTs ? <span>{moment.unix(createdTs).fromNow()}</span> : <span className="grayyed">Unknown</span>}
                            </Tooltip>
                        </dic>
                    </div>
                    <div className="InfoCountIcon">
                        <FontAwesomeIcon
                            size="4x"
                            icon={faCloudUploadAlt}
                        />
                        <Tooltip
                            className="TokenManagerTooltip"
                            title="Number of uploaded files"
                            placement="topLeft"
                            arrowPointAtCenter>
                            <div className="UploadsCounter">{uploadedFilesCount}</div>
                        </Tooltip>
                    </div>
                </div>
                <div className={`TokenListItemBottom ${open ? "" : "collapsed"}`}>
                    <Tooltip
                        className="TokenManagerTooltip"
                        title={copyToClipboardText}
                        onVisibleChange={(visible) => {
                            if (!visible) {
                                setCopyToClipboardText("Copy link to the clipboard");
                            }
                        }}>
                        <OBCButton
                            icon={faClipboard}
                            onClick={(e) => onCopyLinkCliked(e)}>
                            Copy Link
                        </OBCButton>
                    </Tooltip>
                    <Tooltip title="Generate QR code">
                        <OBCButton
                            icon={faQrcode}
                            onClick={(e) => onGenerateQrCodeClicked(e)}>
                            QR Code
                        </OBCButton>
                    </Tooltip>
                    <Tooltip title="Open new tab with Media Uploader">
                        <OBCButton
                            icon={faUpload}
                            onClick={onUploadNowClicked}>
                            Upload Now
                        </OBCButton>
                    </Tooltip>
                    <Tooltip title="Revoke token">
                        <OBCButton
                            icon={faBan}
                            onClick={onRevokeClicked}
                            variant="danger">
                            Revoke
                        </OBCButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default UploadManagerListItem;
