import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
    faStepBackward,
    faStepForward,
    faTimes,
    faFastForward,
    faFastBackward,
    faSearchPlus,
    faBinoculars,
    faEye,
    faObjectUngroup,
    faRulerCombined,
    faCross,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentVideoKey, calculateNext, calculatePrevious, previousContent, nextContent } from "components/util/PlaylistUtils";
import { changeToolMode, requestPlaylistPosition, routeSelected, selectObservation, updateZoom, setCurrentTab } from "../../../redux/actions/index";
import { Modal, Popover } from "antd";
import { faPennant } from "@fortawesome/pro-solid-svg-icons";

const { confirm } = Modal;

class ImageNavigationContent extends React.PureComponent {
    navigate = (nav) => {
        if (nav === null) {
            return;
        }
        const newKeyIndex = nav[0];
        const offset = nav[1];
        console.log("Requesting index/offset:", newKeyIndex, offset);
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, newKeyIndex, offset),
        );
    };

    navigateNext = () => this.navigate(calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePrevious = () => this.navigate(calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));

    get isNextDisabled() {
        return calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    get isPreviousDisabled() {
        return calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    navigateNextSketch = () =>
        this.navigate(nextContent(this.props.sketches, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigateNextMeasurement = () =>
        this.navigate(nextContent(this.props.measurements, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePreviousSketch = () =>
        this.navigate(previousContent(this.props.sketches, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigatePreviousMeasurement = () =>
        this.navigate(previousContent(this.props.measurements, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));

    get isNextMarkupDisabled() {
        const content = this.props.toolMode === "draw" ? this.props.sketches : this.props.measurements;
        return nextContent(content, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    get isPreviousMarkupDisabled() {
        const content = this.props.toolMode === "draw" ? this.props.sketches : this.props.measurements;
        return previousContent(content, this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    loadFrameData = (frameDataURL) => {
        return fetch(frameDataURL, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
        }).then((response) => {
            if (response && response.ok) {
                return response.json();
            } else {
                return Promise.reject();
            }
        });
    };

    checkForChanges = (callback) => {
        if (this.props.markupChanged) {
            confirm({
                title: "Unsaved Changes",
                content: "You have unsaved changes. Navigating away from here will delete these changes. Are you sure?",
                okText: "Discard & Proceed",
                cancelText: "Cancel",
                onOk() {
                    // proceed with whatever the intended action was
                    callback();
                },
                onCancel() {
                    // do nothing - cancel action (modal will be closed)
                },
                getContainer: this.props.fullscreenComponent.current.fullscreenComponent.current,
            });
        } else {
            // no unsaved changes - proceed
            callback();
        }
    };

    render() {
        return (
            <div className="TopToolbar TopToolbar--annotation">
                <div
                    className="AnnotationIconOuter"
                    type="button"
                    aria-label={"Previous Image"}
                    tabIndex={this.isPreviousDisabled ? -1 : 0}
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.checkForChanges(this.navigatePrevious);
                        }
                    }}>
                    <div
                        className={"AnnotationIcon" + (this.isPreviousDisabled ? " Disabled" : "")}
                        onClick={() => this.checkForChanges(this.navigatePrevious)}>
                        <FontAwesomeIcon icon={faFastBackward} />
                        <div className="AnnotationLabel">Previous Image</div>
                    </div>
                </div>
                <div
                    className="AnnotationIconOuter"
                    type="button"
                    aria-label={`Previous ${this.props.toolMode === "draw" ? "Markup" : "Measurement"}`}
                    tabIndex={this.isPreviousMarkupDisabled ? -1 : 0}
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.props.toolMode === "draw"
                                ? this.checkForChanges(this.navigatePreviousSketch)
                                : this.checkForChanges(this.navigatePreviousMeasurement);
                        }
                    }}>
                    <span
                        className={"AnnotationIcon" + (this.isPreviousMarkupDisabled ? " Disabled" : "")}
                        onClick={() =>
                            this.props.toolMode === "draw"
                                ? this.checkForChanges(this.navigatePreviousSketch)
                                : this.checkForChanges(this.navigatePreviousMeasurement)
                        }>
                        <FontAwesomeIcon icon={faStepBackward} />
                        <div className="AnnotationLabel">Previous {this.props.toolMode === "draw" ? "Markup" : "Measurement"}</div>
                    </span>
                </div>
                <div
                    className="AnnotationIconOuter"
                    type="button"
                    aria-label={`Next ${this.props.toolMode === "draw" ? "Markup" : "Measurement"}`}
                    tabIndex={this.isNextMarkupDisabled ? -1 : 0}
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.props.toolMode === "draw" ? this.checkForChanges(this.navigateNextSketch) : this.checkForChanges(this.navigateNextMeasurement);
                        }
                    }}>
                    <span
                        className={"AnnotationIcon" + (this.isNextMarkupDisabled ? " Disabled" : "")}
                        onClick={() =>
                            this.props.toolMode === "draw" ? this.checkForChanges(this.navigateNextSketch) : this.checkForChanges(this.navigateNextMeasurement)
                        }>
                        <FontAwesomeIcon icon={faStepForward} />
                        <div className="AnnotationLabel">Next {this.props.toolMode === "draw" ? "Markup" : "Measurement"}</div>
                    </span>
                </div>
                <div
                    className="AnnotationIconOuter"
                    type="button"
                    aria-label={`Next Image`}
                    tabIndex={this.isNextDisabled ? -1 : 0}
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.checkForChanges(this.navigateNext);
                        }
                    }}>
                    <span
                        className={"AnnotationIcon" + (this.isNextDisabled ? " Disabled" : "")}
                        onClick={() => this.checkForChanges(this.navigateNext)}>
                        <FontAwesomeIcon icon={faFastForward} />
                        <div className="AnnotationLabel">Next Image</div>
                    </span>
                </div>
                <div
                    className="AnnotationIconOuter"
                    type="button"
                    aria-label="Close"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.checkForChanges(() => this.props.changeToolMode(false));
                        }
                    }}>
                    <span
                        className={"AnnotationIcon"}
                        onClick={() => this.checkForChanges(() => this.props.changeToolMode(false))}>
                        <FontAwesomeIcon icon={faTimes} />
                        <div className="AnnotationLabel HideForSmallScreens">Close</div>
                    </span>
                </div>
            </div>
        );
    }
}

const mapImageComponentStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex], []);
    const currentIndex = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset;

    return {
        video,
        offset,
        sourceIndex,
        currentIndex,
        sketches: state.userSketches,
        measurements: state.userMeasurements,
        markupChanged: state.markup.hasUnsavedChange,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        toolMode: state.markup.tool_mode,
        videoKey: getCurrentVideoKey(state.playlist),
    };
};

const ImageNavigation = connect(mapImageComponentStateToProps)(ImageNavigationContent);

class MarkupNavigation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.popoverMountPoint = React.createRef();
    }

    measurementDisabled = () => {
        // return _.get(this.props, ["session", "tags"], []).includes("Overhead");
        return _.get(this.props, ["session", "tags"], []).includes("Overhead");
    };

    closePlayer = (e = null) => {
        if (!e || e.key === "Enter") {
            this.props.dispatch(updateZoom(1));
            this.props.dispatch(selectObservation(null));
            this.props.dispatch(routeSelected(null));
        }
    };

    showPopover = () => {
        return this.props.windowDimensions.width > 1500 || this.props.workspaceLayout === "widget_layout" ? "" : "hover";
    };

    render() {
        console.log("debug rendering toolNavigationBar... ", this.props.workspaceLayout);

        // check if enable features (3d) if disabled for whole workspace in the config then disable.
        let featuresEnabled = this.props.disable3dFeatures
            ? false
            : this.props.featureOverlayEnabled || _.get(this.props.session, "3d_features_enabled", false);

        let imageNavigationContent = (
            <ImageNavigation
                changeToolMode={this.props.changeToolMode}
                fullscreenComponent={this.props.fullscreenComponent}
            />
        );

        let toolNavigationContent = (
            <div
                className={`toolNavigationBar ${["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) ? "toolNavigationBarWidgetView" : ""}`}
                ref={this.popoverMountPoint}>
                <Popover
                    getPopupContainer={() => this.popoverMountPoint.current}
                    content="Close"
                    trigger={this.showPopover()}>
                    <span
                        onClick={() => {
                            this.closePlayer();
                            this.props.dispatch(setCurrentTab("sessions"));
                        }}
                        onKeyDown={this.closePlayer}
                        role="button"
                        tabIndex={0}
                        className={"toolbarOption"}>
                        <FontAwesomeIcon icon={faTimes} />
                        <p>Close</p>
                    </span>
                </Popover>

                {!["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                    <Popover
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content="Advanced Sighting"
                        trigger={this.showPopover()}>
                        <span
                            onClick={this.props.openAdvancedSighting}
                            role="button"
                            tabIndex={0}
                            className={"toolbarOption" + (this.props.shareLink || this.props.videoLoading ? " Disabled" : "")}>
                            <FontAwesomeIcon icon={faBinoculars} />
                            <p>Advanced Sighting</p>
                        </span>
                    </Popover>
                )}
                <Popover
                    getPopupContainer={() => this.popoverMountPoint.current}
                    content="Quick Sighting"
                    trigger={this.showPopover()}>
                    <span
                        onClick={() => this.props.changeToolMode("distance_view")}
                        onKeyDown={(e) => {
                            if (e.key && e.key === "Enter") {
                                this.props.changeToolMode("distance_view");
                            }
                        }}
                        role="button"
                        tabIndex={0}
                        className={"toolbarOption" + (this.props.shareLink || this.props.videoLoading ? " Disabled" : "")}>
                        <FontAwesomeIcon icon={faEye} />
                        <p>Quick Sighting</p>
                    </span>
                </Popover>

                {featuresEnabled && !["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                    <Popover
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content="Features"
                        trigger={this.showPopover()}>
                        <span
                            onClick={() => this.props.changeToolMode("feature_placement")}
                            role="button"
                            tabIndex={0}
                            className={"toolbarOption" + (this.props.shareLink || this.props.videoLoading ? " Disabled" : "")}>
                            <FontAwesomeIcon icon={faPennant} />
                            <p>Features</p>
                        </span>
                    </Popover>
                )}

                {!["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                    <Popover
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content="Annotate"
                        trigger={this.showPopover()}>
                        <span
                            id="intro-tour-video-annotate"
                            role="button"
                            tabIndex={0}
                            onClick={() => this.props.changeToolMode("annotate")}
                            className={"toolbarOption" + (this.props.shareLink || this.props.videoLoading ? " Disabled" : "")}>
                            <FontAwesomeIcon icon={faObjectUngroup} />
                            <p>Annotate</p>
                        </span>
                    </Popover>
                )}

                {!["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) &&
                    (this.measurementDisabled() || this.props.videoLoading || this.props.shareLink ? (
                        <Popover
                            getPopupContainer={() => this.popoverMountPoint.current}
                            content="Measurement is disabled on overhead footage"
                            trigger="hover"
                            overlayStyle={{ width: 240 }}
                            placement="bottomLeft">
                            <span
                                id="intro-tour-video-measure"
                                role="button"
                                tabIndex={0}
                                className="toolbarOption grey">
                                <FontAwesomeIcon icon={faRulerCombined} />
                                <p>Measure</p>
                            </span>
                        </Popover>
                    ) : (
                        <Popover
                            getPopupContainer={() => this.popoverMountPoint.current}
                            content="Measure"
                            trigger={this.showPopover()}
                            placement="bottom">
                            <span
                                onClick={() => this.props.changeToolMode("measure")}
                                id="intro-tour-video-measure"
                                role="button"
                                tabIndex={0}
                                className="toolbarOption">
                                <FontAwesomeIcon icon={faRulerCombined} />
                                <p>Measure</p>
                            </span>
                        </Popover>
                    ))}
                {this.props.workspaceLayout !== "minimal_layout" && (
                    <Popover
                        getPopupContainer={() => this.popoverMountPoint.current}
                        content="Zoom"
                        trigger={this.showPopover()}>
                        <span
                            onClick={() => this.props.dispatch(changeToolMode("zoom"))}
                            role="button"
                            tabIndex={0}
                            className={"toolbarOption AlignRight"}>
                            <FontAwesomeIcon icon={faSearchPlus} />
                            <p>Zoom</p>
                        </span>
                    </Popover>
                )}
            </div>
        );

        return this.props.toolMode ? imageNavigationContent : toolNavigationContent;
    }
}

const mapStateToProps = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const userViewEnabled = !!_.get(state.views, [state.userDetails.userConfig.view_id, "ui_controls", "feature_overlay"], false);
    const workspaceViewEnabled = !!_.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "ui_controls", "feature_overlay"], false);
    const disable3dFeatures = _.get(currentDashboard, ["config", "sessions_disable_3d_features"], false);
    let featureOverlayEnabled = userViewEnabled || workspaceViewEnabled;

    return {
        shareLink: state.shareLink,
        isStills: state.playlist.position.isStills,
        featureOverlayEnabled: featureOverlayEnabled,
        disable3dFeatures: disable3dFeatures,
        windowDimensions: state.windowDimensions,
        session: state.sessions[state.playlist.data.routeID],
        workspaceLayout: currentDashboard?.workspace_layout,
    };
};

export default connect(mapStateToProps)(MarkupNavigation);
