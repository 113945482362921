import React from "react";
import { useSelector } from "react-redux";
import OBCSpinner from "components/util/OBC";
import DtCategoryListItem from "./DtCategoryListItem";
import _ from "lodash";

const userTrainingCategoriesSelector = (state) => state.driverTraining.trainee.trainingList;

const DtTrainingCategoryList = ({ loadingCategoryData }) => {
    const userTrainingCategories = useSelector(userTrainingCategoriesSelector);

    return (
        <div className="MainPageTrainingList">
            <div className="Header">Available Training:</div>
            {loadingCategoryData ? (
                <div className="BasicSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            ) : (
                _.size(userTrainingCategories) > 0 &&
                _.map(userTrainingCategories, (category, index) => {
                    return (
                        <DtCategoryListItem
                            key={index}
                            category={category}
                        />
                    );
                })
            )}
        </div>
    );
};

export default DtTrainingCategoryList;
