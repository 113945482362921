import React from "react";
import "../../aivr.scss";

const TermsText = () => {
    return (
        <>
            <span className="TermsComponentBold">
                1. About our Terms
                <br />
                The following defined terms apply throughout these Terms:
                <br />
                Authorised User
            </span>{" "}
            means an individual user granted access to the Solution by the Customer in connection with the performance of the user’s duties for the Customer.
            <br />
            <span className="TermsComponentBold">Content</span> means any text, images, video, audio or other multimedia content recorded by and stored on the
            Solution, together with any related software, information or material submitted to or otherwise accessible via the Solution.
            <br />
            <span className="TermsComponentBold">Customer</span> means the legal person (which may be a business owner, partnership, incorporated or
            unincorporated entity, or public body) which has a valid and current subscription to our Solution and grants access to you as an Authorised User.
            <br />
            <span className="TermsComponentBold">Data Protection Legislation</span> means all applicable laws and regulations from time to time in force
            relating to the protection of personal information, including the UK GDPR regime comprised of the Retained Regulation (EU) 2016/679 (‘UK GDPR’) and
            the Data Protection Act 2018 and any laws substituting, re-enacting or replacing any of the foregoing, as amended or updated from time to time.
            <br />
            <span className="TermsComponentBold">Personal Data</span> has the meaning given in the Data Protection Legislation.
            <br />
            <span className="TermsComponentBold">Software</span> means the software applications provided by us as part of the Solution.
            <br />
            <span className="TermsComponentBold">Solution</span> means the Automated Intelligent Video Review (AIVR) solution, which provides access to the
            Content.
            <br />
            <span className="TermsComponentBold">Terms</span> means these terms and conditions of use as updated from time to time under clause 8.
            <br />
            <span className="TermsComponentBold">Virus(es)</span> means any thing or device (including any software, code, file or program) which may: prevent,
            impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or
            network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any program or data, including
            the reliability of any program or data (whether by re-arranging, altering or erasing the program or data in whole or part or otherwise); or
            adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices;
            <br />
            <span className="TermsComponentBold">We, us or our</span> means One Big Circle Ltd, company registration number 11022970, with its registered office
            at Unit 7 & 8, Temple Studios, Lower Approach Road, Bristol, United Kingdom, BS16QA.
            <br />
            <span className="TermsComponentBold">You or your</span> means the Authorised User accessing or using the Solution or its Content.
            <br />
            <span className="TermsComponentBold">1.2</span> These terms explain how you may use the Solution. You should read these Terms carefully before using
            the Solution.
            <br />
            <span className="TermsComponentBold">1.3</span> By accessing or using the Solution or otherwise indicating your consent, you agree to be bound by
            these Terms.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">2. Using the Solution</span>
            <br />
            <span className="TermsComponentBold">2.1</span> By accessing and/or using the Solution, you warrant and represent to us that you are acting as an
            Authorised User on behalf of a Customer. If you are not an Authorised User, or cease to be one, you must not access or use the Solution.
            <br />
            <span className="TermsComponentBold">2.2</span> The Solution is available for use in connection with your duties on behalf of a Customer only. You
            must not access or use any of the Content available via the Solution (including any exported Content) other than in accordance with these Terms and
            the Customer’s requirements and instructions from time to time. We shall not be liable for your use of the Solution and Content in contravention of
            this clause 2.2.
            <br />
            <span className="TermsComponentBold">2.3</span> You agree that you are solely responsible for keeping your password and other account details (if
            applicable) confidential. You should notify us or the Customer immediately if you become aware that your password or any other account details have
            been compromised or lost.
            <br />
            <span className="TermsComponentBold">2.4</span> We may prevent or suspend your access to the Solution if you do not comply with any part of these
            Terms, any terms or policies to which they refer or any applicable law.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">3. Restrictions on use</span>
            <br />
            <span className="TermsComponentBold">3.1</span> As a condition of your use of the Solution, you agree:
            <br />
            <span className="TermsComponentBold">3.1.1</span> not to use the Solution other than in the performance of your duties for and on behalf of the
            Customer;
            <br />
            <span className="TermsComponentBold">3.1.2</span> not to use the Solution for any purpose that is unlawful under any applicable law or prohibited by
            these Terms;
            <br />
            <span className="TermsComponentBold">3.1.3</span> not to use the Solution to commit any act of fraud;
            <br />
            <span className="TermsComponentBold">3.1.4</span> not to use the Solution to distribute Viruses or malware or other similar harmful software code;
            <br />
            <span className="TermsComponentBold">3.1.5</span> not to use the Solution for purposes of promoting unsolicited advertising or sending spam;
            <br />
            <span className="TermsComponentBold">3.1.6</span> not to use the Solution to simulate communications from us or another service or entity in order
            to collect identity information, authentication credentials, or other information (‘phishing’);
            <br />
            <span className="TermsComponentBold">3.1.7</span> not to use the Solution in any manner that disrupts the operation of our Solution or business or
            the business of any other entity;
            <br />
            <span className="TermsComponentBold">3.1.8</span> not to use the Solution in any manner that harms minors or is discriminatory based on race,
            gender, colour, religious belief, sexual orientation, disability;
            <br />
            <span className="TermsComponentBold">3.1.9</span> not to promote or faciliate any unlawful activity;
            <br />
            <span className="TermsComponentBold">3.1.10</span> not to use the Solution to gain unauthorised access to or use of computers, data, systems,
            accounts or networks;
            <br />
            <span className="TermsComponentBold">3.1.11</span> not to attempt to circumvent password or user authentication methods; and
            <br />
            <span className="TermsComponentBold">3.1.12</span> with the provisions relating to our intellectual property rights and software contained in these
            Terms.
            <br />
            <span className="TermsComponentBold">3.2</span> You agree not to copy, download, export or otherwise transfer Content out of the Solution except as
            strictly necessary for the proper use of such Content in accordance with the Client’s requirements and instructions.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">4. Privacy and personal information</span>
            <br />
            <span className="TermsComponentBold">4.1</span> You acknowledge that the Solution and Content may contain Personal Data, including but not limited
            to images of identifiable individuals, and you agree to treat such Personal Data as highly sensitive and confidential.
            <br />
            <span className="TermsComponentBold">4.2</span> Without limiting the generality of clause 4.1, your use of the Solution must comply with the Data
            Protection Legislation and any policies and procedures implemented by the Client in relation to the storage, handling and processing of Personal
            Data.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">5. Software and intellectual property rights</span>
            <br />
            <span className="TermsComponentBold">5.1</span> Nothing in these Terms grants you any legal rights in the Solution other than as necessary to enable
            you to access the Solution. You agree not to adjust to try to circumvent or delete any notices contained on the Solution (including any intellectual
            property notices) and in particular in any digital rights or other security technology embedded or contained within the Solution.
            <br />
            <span className="TermsComponentBold">5.2</span> Trade marks and trade names may be used on this Solution. Your use of any trade marks on the
            Solution is strictly prohibited unless you have our prior written permission.
            <br />
            <span className="TermsComponentBold">5.3</span> The Solution is provided using proprietary Software. Using the Software in an unlawful way (such as
            reproducing or redistributing it in a way that breaches these Terms) is expressly prohibited and may result in civil and criminal penalties.
            <br />
            <span className="TermsComponentBold">5.4</span> You agree that you shall not:
            <br />
            <span className="TermsComponentBold">5.4.1</span> except as may be allowed by any applicable law which is incapable of exclusion by agreement
            between us:
            <br />
            <span className="TermsComponentBold">(a)</span> and except to the extent expressly permitted under these Terms, attempt to copy, modify, duplicate,
            create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software in any form or
            media or by any means; or
            <br />
            <span className="TermsComponentBold">(b)</span> attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human- perceivable
            form all or any part of the Software; or
            <br />
            <span className="TermsComponentBold">5.4.2</span> access all or any part of the Solution in order to build a product or service which competes with
            the Solution;
            <br />
            <span className="TermsComponentBold">5.4.3</span> license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise
            commercially exploit, or otherwise make the Solution available to any third party
            <br />
            <span className="TermsComponentBold">5.4.4</span> attempt to obtain, or assist third parties in obtaining, access to the Solution.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">6. Assignment</span>
            <br />
            These Terms are personal to you as an Authorised User and the rights and obligations hereunder may not be assigned or transferred by you without our
            prior written consent.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">7. Rights of third parties</span>
            <br />
            No one other than a party to these Terms has any right to enforce any of these Terms.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">8. Variation</span>
            <br />
            We reserve the right to vary these Terms from time to time. Our updated terms will be displayed on the Solution and by continuing to use and access
            the Solution following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these Terms from time to
            time to verify such variations.
            <br />
            <div className="TermsComponentSpace" />
            <span className="TermsComponentBold">9. Miscellaneous</span>
            <br />
            <span className="TermsComponentBold">9.1</span> Any failure or delay by either party in exercising any right or remedy under these Terms shall not
            constitute a waiver.
            <br />
            <span className="TermsComponentBold">9.2</span> We shall apply these Terms in our absolute discretion. In the event of your breach of these Terms we
            may:
            <br />
            <span className="TermsComponentBold">9.2.1</span> terminate or suspend your use of the Solution;
            <br />
            <span className="TermsComponentBold">9.2.2</span> report the nature of the breach to the Client, which may result in disciplinary or other
            proceedings;
            <br />
            <span className="TermsComponentBold">9.2.3</span> report to law enforcement authorities; and/or
            <br />
            <span className="TermsComponentBold">9.2.4</span> take any action we consider necessary to remedy the breach.
            <br />
            <span className="TermsComponentBold">9.3</span> These terms shall be subject to English law and the courts of England and Wales will have exclusive
            jurisdiction in relation to these Terms.
            <br />
        </>
    );
};

export default TermsText;
