import React from "react";
import CCTVImageModal from "./map/CCTVImageModal";

const CCTVCameraModal = ({ visible, setVisible, cameraInfo }) => {
    return (
        <CCTVImageModal
            visible={visible}
            setVisible={setVisible}
            cameraInfo={cameraInfo}
            origin={"navbar"}
        />
    );
};

export default CCTVCameraModal;
