import React from "react";
import { connect } from "react-redux";
import { drawLine, projectToIntersection, toPercent, worldToImageCoordinates } from "../../../util/Geometry";
import _ from "lodash";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";

function roundTo2dp(value) {
    return Math.round(value * 100) / 100;
}
class MeasureOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            horizontalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
            verticalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
            measureDistance: [0, 0, 0],
            railLines: [
                [
                    [-2, -2],
                    [-2, -2],
                ],
                [
                    [-2, -2],
                    [-2, -2],
                ],
            ],
            measureVertical: false,
            mouseX: 0,
            mouseY: 0,
        };

        this.canvas = React.createRef();
    }

    componentDidMount() {
        const halfWidth = this.props.calibration.railSeparation / 2;
        const closestPoint1 = worldToImageCoordinates([halfWidth, 0, 0], this.props.calibration);
        const vanishingPoint = worldToImageCoordinates([0, 0, 10000], this.props.calibration);
        const closestPoint2 = worldToImageCoordinates([-halfWidth, 0, 0], this.props.calibration);

        this.setState({
            railLines: [
                [closestPoint1, vanishingPoint],
                [closestPoint2, vanishingPoint],
            ],
        });
    }

    mouseClick = (evt) => {
        if (this.props.calibration) {
            this.setState({
                measureVertical: !this.state.measureVertical,
            });

            let currentTargetRect = this.canvas.current.getBoundingClientRect();
            let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
                y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;

            x = 2 * Math.max(0, Math.min(1, x)) - 1;
            y = 2 * Math.max(0, Math.min(1, y)) - 1;

            this.updateMeasureDistances(x, y);
        }
    };

    mouseMove = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        let currentTargetRect = this.canvas.current.getBoundingClientRect();
        let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
            y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;
        x = 2 * Math.max(0, Math.min(1, x)) - 1;
        y = 2 * Math.max(0, Math.min(1, y)) - 1;

        this.setState({
            mouseX: x,
            mouseY: y,
        });

        if (this.props.calibration) {
            this.updateMeasureDistances(x, y);
        }
    };

    mouseLeave = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            measureDistance: [0, 0, 0],
            horizontalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
            verticalMeasure: [
                [-2, -2],
                [-2, -2],
            ],
        });
    };

    updateMeasureDistances(x, y) {
        let worldIntersection;
        if (this.state.measureVertical) {
            let distance = this.state.measureDistance[2];
            worldIntersection = [-10000, -10000, distance];
        } else {
            worldIntersection = [-10000, 0, 1000];
        }

        let worldCoordinates = projectToIntersection([x, y], worldIntersection, this.props.calibration);

        if (worldCoordinates !== null) {
            let railPosition = [this.props.calibration.railSeparation / 2, 0, worldCoordinates[2]];
            let multiplier = 1;
            if (worldCoordinates[0] < 0) {
                railPosition[0] *= -1;
                multiplier *= -1;
            }

            this.setState({
                measureDistance: [
                    Math.round(multiplier * (worldCoordinates[0] - railPosition[0]) * 100) / 100,
                    Math.round(worldCoordinates[1] * 100) / 100,
                    Math.round(worldCoordinates[2] * 100) / 100,
                ],
            });

            const railImageCoords = worldToImageCoordinates(railPosition, this.props.calibration);
            const groundImageCoords = worldToImageCoordinates([worldCoordinates[0], 0, worldCoordinates[2]], this.props.calibration);
            this.setState({
                horizontalMeasure: [railImageCoords, groundImageCoords],
                verticalMeasure: [groundImageCoords, [x, y]],
            });
        }
    }

    getDisplayDistance = (metres) => {
        if (this.props.measurementUnits === "yards") {
            return `${roundTo2dp(metres * 1.09361)}yd`;
        } else {
            return `${metres}m`;
        }
    };

    render() {
        let xMouse = this.state.verticalMeasure[1][0];
        if (this.state.mouseX > 0) {
            const rectWidth = 330 / this.canvas.current.getBoundingClientRect().width;
            xMouse -= rectWidth;
        }

        let yMouse = this.state.horizontalMeasure[1][1];
        if (this.state.mouseY > 0) {
            const rectHeight = 150 / this.canvas.current.getBoundingClientRect().height;
            yMouse -= rectHeight;
        }

        let rectYcord = toPercent(yMouse);

        let rectXcord = toPercent(xMouse);

        return (
            <svg
                className={"MeasurementOverlay " + this.props.className}
                onMouseMove={this.mouseMove}
                onClick={this.mouseClick}
                onMouseLeave={this.mouseLeave}
                ref={this.canvas}>
                {drawLine(this.state.railLines[0][0], this.state.railLines[0][1], "CentreLine", "CentreLine")}
                {drawLine(this.state.railLines[1][0], this.state.railLines[1][1], "CentreLine1", "CentreLine")}
                {drawLine(this.state.horizontalMeasure[0], this.state.horizontalMeasure[1], "HorizontalMeasureLine", "HorizontalMeasureLine")}
                {drawLine(this.state.verticalMeasure[0], this.state.verticalMeasure[1], "VerticalMeasureLine", "VerticalMeasureLine")}
                <rect
                    x={rectXcord}
                    y={rectYcord}
                    width={165}
                    height={75}
                    rx={8}
                    className="MeasureBG"
                />
                <text
                    x={rectXcord}
                    y={rectYcord}
                    dx={20}
                    dy={20}
                    className="MeasureText">
                    Distance: {this.getDisplayDistance(this.state.measureDistance[2])}
                </text>
                <text
                    x={rectXcord}
                    y={rectYcord}
                    dx={20}
                    dy={40}
                    className="MeasureText">
                    From Rail: {this.getDisplayDistance(this.state.measureDistance[0])}
                </text>
                <text
                    x={rectXcord}
                    y={rectYcord}
                    dx={20}
                    dy={60}
                    className="MeasureText">
                    From Ground: {this.getDisplayDistance(this.state.measureDistance[1])}
                </text>
            </svg>
        );
    }
}

const mapStateToProps = (state) => {
    let sourceIndex = state.playlist.position.sourceIndex;

    let measurementUnits = _.get(state, "userDetails.userConfig.measurement_units", "metres");
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }
    return {
        calibration: get_source_calibration(
            state.measurement.calibration,
            sourceIndex,
            getAbsoluteTimestamp(state.playlist) * 1000,
            false,
            defaultRailSeparation,
        ),
        measurementUnits: measurementUnits,
    };
};

export default connect(mapStateToProps)(MeasureOverlay);
