import React, { useEffect, useState } from "react";
import { Modal, Button, Checkbox, Radio, Select } from "antd";
import _ from "lodash";
import { getSessionTrims } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import "../aivr.scss";

const workspacesSelector = (state) => state.dashboards;

const CurationModal = ({ session, closeCuration, curationMonths, dispatchCuration, displayQaAccept }) => {
    const [curation, setCuration] = useState({
        forward: session.hasOwnProperty("curation") ? session.curation.forward : null,
        allow_3d_features: session.hasOwnProperty("curation") ? session.curation["3d_features"] : null,
        qa_checked: session.hasOwnProperty("curation") ? session.curation.qa_checked : false,
        trainflix: session.hasOwnProperty("curation") ? session.curation.trainflix : false,
        curation_months: !curationMonths ? 36 : curationMonths > 36 ? 36 : curationMonths,
    });
    const [sessionTrims, setSessionTrims] = useState([]);

    const workspaces = useSelector(workspacesSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSessionTrims(session.id)).then((response) => {
            setSessionTrims(response);
        });
    }, []);

    function convertUnixTime(unixTimestamp) {
        const dateObj = new Date(parseInt(unixTimestamp));

        const hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();
        const seconds = dateObj.getUTCSeconds();

        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return (
        <Modal
            visible={true}
            title="Curation"
            onOk={() => dispatchCuration(curation, true)}
            onCancel={closeCuration}
            footer={[
                <Button
                    key="back"
                    onClick={closeCuration}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => dispatchCuration(curation, true)}>
                    Save
                </Button>,
            ]}>
            <div className="CurationContainer">
                <div className="SelectionDiv">
                    <p className="SelectionLabel">Retention Period</p>
                    <Radio.Group
                        buttonStyle="solid"
                        value={curation["curation_months"]}
                        onChange={(e) => setCuration({ ...curation, curation_months: e.target.value })}>
                        <Radio.Button value={6}>6 months</Radio.Button>
                        <Radio.Button value={12}>1 year</Radio.Button>
                        <Radio.Button value={36}>3 years</Radio.Button>
                    </Radio.Group>
                </div>

                <div className="SelectionDiv">
                    <p className="SelectionLabel">Camera Orientation</p>
                    <Radio.Group
                        buttonStyle="solid"
                        value={curation["forward"]}
                        onChange={(e) => setCuration({ ...curation, forward: e.target.value })}>
                        <Radio.Button value={null}>Not Set</Radio.Button>
                        <Radio.Button value={0}>Backwards</Radio.Button>
                        <Radio.Button value={1}>Forwards</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="SelectionDiv Column">
                    <span className="SelectionLabel">Enable 3D Features on workspace</span>
                    <Select
                        showSearch
                        onChange={(val) => {
                            setCuration({
                                ...curation,
                                allow_3d_features: val,
                            });
                        }}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={curation.allow_3d_features}>
                        <Select.Option value={null}>None</Select.Option>
                        {_.orderBy(workspaces, "description").map((workspace) => {
                            return <Select.Option value={workspace.access_id}>{workspace.description}</Select.Option>;
                        })}
                    </Select>
                </div>
                <div className="SelectionDiv">
                    <Checkbox
                        checked={curation.trainflix}
                        onChange={(e) => setCuration({ ...curation, trainflix: e.target.checked })}>
                        <span className="SelectionLabel">Suitable For TrainFlix</span>
                    </Checkbox>
                </div>
                {displayQaAccept && (
                    <div className="SelectionDiv">
                        <Checkbox
                            checked={curation.qa_checked}
                            onChange={(e) => setCuration({ ...curation, qa_checked: e.target.checked })}>
                            <span className="SelectionLabel">QA Accepted</span>
                        </Checkbox>
                    </div>
                )}
                {sessionTrims.length > 0 && (
                    <div className="SessionTrim">
                        <p className="SessionTrimLabel">Session Trims:</p>
                        <ul className="SessionTrimList">
                            {sessionTrims.map((item, index) => {
                                return (
                                    <li
                                        className="SessionTrimList--Item"
                                        key={index}>
                                        {convertUnixTime(item.trim_start)} - {convertUnixTime(item.trim_end)}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default CurationModal;
