import ReactDOM from "react-dom";
import videojs from "video.js";
import React from "react";
import { connect, Provider } from "react-redux";
import store from "../../../redux/store";
import RouteHistoryComponent from "./RouteHistoryComponent";

const Component = videojs.getComponent("Component");
const dom = videojs.dom || videojs;
const registerPlugin = videojs.registerPlugin || videojs.plugin;

class ReactPauseOverlay extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            hidden: true,
        };
    }

    hide = () => {
        this.setState({
            hidden: true,
        });
    };

    show = () => {
        this.setState({
            hidden: false,
        });
    };

    render() {
        return (
            <React.Fragment>
                <RouteHistoryComponent />
            </React.Fragment>
        );
    }
}

const PauseOverlayComponent = connect(null, null, null, { forwardRef: true })(ReactPauseOverlay);

class PauseOverlay extends Component {
    constructor(player, options) {
        super(player, options);
        this.loading = false;

        player.on("pause", () => {
            this.checkPause();
        });
        player.on("play", () => {
            this.checkPause();
        });
        player.on("seeking", () => {
            this.checkPause();
        });
        player.on("seeked", () => {
            this.checkPause();
        });
        player.on("export", () => {
            this.hide();
            this.setState({
                exporting: true,
            });
        });
        player.on("cancelExport", () => {
            this.show();
            this.setState({
                exporting: false,
            });
        });
        player.on("aivrLoadingStarted", () => {
            this.loading = true;
            this.checkPause();
        });
        player.on("aivrLoadingComplete", () => {
            this.loading = false;
            this.checkPause();
        });

        this.hide();
    }

    checkPause() {
        try {
            if (!this.loading && !this.player().seeking() && !this.player().scrubbing()) {
                if (this.player().paused() && !this.state.exporting) {
                    this.show();
                } else {
                    this.hide();
                }
            } else {
                this.hide();
            }
        } catch (ex) {
            console.log("Unexpected exception:", ex);
        }
    }

    createEl() {
        const el = dom.createEl("div", {
            className: "vjs-pause-overlay vjs-hidden",
        });

        this.containerRef = React.createRef();

        ReactDOM.render(
            <Provider store={store}>
                <PauseOverlayComponent
                    player={this.player()}
                    ref={this.containerRef}
                />
            </Provider>,
            el,
        );

        console.log("Container ref: ", this.containerRef);

        return el;
    }

    dispose() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el());
        }
    }

    hide() {
        super.hide();
        if (this.containerRef.current) {
            this.containerRef.current.hide();
        }
    }

    show() {
        super.show();
        if (this.containerRef.current) {
            this.containerRef.current.show();
        }
    }
}

videojs.registerComponent("PauseOverlay", PauseOverlay);

const pauseOverlayPlugin = function (options) {
    const playerChild = this.addChild("pauseOverlay", options);

    this.el().insertBefore(playerChild.el(), this.controlBar.el());

    return playerChild;
};

registerPlugin("pauseOverlay", pauseOverlayPlugin);
