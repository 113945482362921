import React from "react";
import { connect } from "react-redux";
import { faArchway, faDraftingCompass, faRoute, faRulerCombined, faRuler, faCamera } from "@fortawesome/free-solid-svg-icons";
import { faHatWitch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent, logTiming, toggleSnapshot } from "../../../../redux/actions/index";
import { source_is_calibrated } from "../../../util/PlaylistUtils";
import { Modal, Popover } from "antd";
import overlayTunnelHint from "../../../../images/overlay_tunnel_hint.png";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";

const { confirm } = Modal;

class MeasureInterface extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mouseDown: false,
            timeInterfaceEntered: 0,
            mode: -1,
            overlaysHintPopoverOpen: false,
        };
    }

    componentDidMount() {
        this.setState({
            timeInterfaceEntered: new Date().getTime(),
        });

        this.props.dispatch(logEvent("Measurement", "Enter Measurement Interface"));

        if (!this.props.calibrated) {
            this.changeMode(0);
        } else {
            this.changeMode(4);
        }
    }

    changeMode = (mode) => {
        this.props.changeMode(mode);
    };

    componentWillUnmount() {
        this.props.dispatch(logEvent("Measurement", "Exit Measurement Interface"));
        logTiming("Measurement", "Time spent Measuring", new Date().getTime() - this.state.timeInterfaceEntered);
    }

    close = () => {
        this.props.onClose();
    };

    checkForChanges = (callback, snapshot = false) => {
        let message = "You have unsaved changes. Navigating away from here will delete these changes. Are you sure?";
        if (snapshot) {
            message = "You have unsaved changes. These changes will not be shown on snapshots.";
        }
        if (this.props.markupChanged) {
            confirm({
                title: "Unsaved Changes",
                content: message,
                onOk() {
                    callback();
                },
                onCancel() {},
                getContainer: this.props.fullscreenComponent.current.fullscreenComponent.current,
            });
        } else {
            callback();
        }
    };

    render() {
        return (
            <div className="BottomToolbar BottomToolbar--measurement">
                <div
                    className="MeasurementIconOuter"
                    type="button"
                    tabIndex={0}
                    aria-label="Calibrate"
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            this.checkForChanges(() => this.changeMode(0));
                        }
                    }}>
                    <span
                        className={"MeasurementIcon" + (this.props.mode === 0 ? " Active toolActive" : "")}
                        onClick={() => this.checkForChanges(() => this.changeMode(0))}>
                        <FontAwesomeIcon icon={faDraftingCompass} /> Calibrate
                    </span>
                </div>
                {this.props.userConfig.super_admin && (
                    <div
                        className="MeasurementIconOuter"
                        type="button"
                        tabIndex={0}
                        aria-label="Whichcraft"
                        onKeyDown={(e) => {
                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                this.checkForChanges(() => this.changeMode(5));
                            }
                        }}>
                        <span
                            className={"MeasurementIcon" + (this.props.mode === 5 ? " Active toolActive" : "")}
                            onClick={() => this.checkForChanges(() => this.changeMode(5))}>
                            <FontAwesomeIcon icon={faHatWitch} />
                        </span>
                    </div>
                )}
                <div
                    className="MeasurementIconOuter"
                    type="button"
                    tabIndex={this.props.calibrated ? 0 : -1}
                    aria-label="Measure"
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            if (this.props.calibrated) {
                                this.checkForChanges(() => this.changeMode(4));
                            }
                        }
                    }}>
                    <span
                        className={"MeasurementIcon" + (this.props.mode === 4 ? " Active toolActive" : "") + (this.props.calibrated ? "" : " Disabled")}
                        onClick={() => (this.props.calibrated ? this.checkForChanges(() => this.changeMode(4)) : null)}>
                        <FontAwesomeIcon icon={faRuler} /> Measure
                    </span>
                </div>
                <div
                    className="MeasurementIconOuter"
                    type="button"
                    tabIndex={this.props.calibrated ? 0 : -1}
                    aria-label="Quick Measure"
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            if (this.props.calibrated) {
                                this.checkForChanges(() => this.changeMode(2));
                            }
                        }
                    }}>
                    <span
                        className={"MeasurementIcon" + (this.props.mode === 2 ? " Active toolActive" : "") + (this.props.calibrated ? "" : " Disabled")}
                        onClick={() => (this.props.calibrated ? this.checkForChanges(() => this.changeMode(2)) : null)}>
                        <FontAwesomeIcon icon={faRulerCombined} />
                        Quick Measure
                    </span>
                </div>
                <div
                    className="MeasurementIconOuter"
                    type="button"
                    tabIndex={this.props.calibrated ? 0 : -1}
                    aria-label="Route GPS"
                    onKeyDown={(e) => {
                        if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                            if (this.props.calibrated) {
                                this.checkForChanges(() => this.changeMode(1));
                            }
                        }
                    }}>
                    <span
                        className={"MeasurementIcon" + (this.props.mode === 1 ? " Active toolActive" : "") + (this.props.calibrated ? "" : " Disabled")}
                        onClick={() => (this.props.calibrated ? this.checkForChanges(() => this.changeMode(1)) : null)}>
                        <FontAwesomeIcon icon={faRoute} /> Route GPS
                    </span>
                </div>
                <Popover
                    trigger="click"
                    visible={this.state.overlaysHintPopoverOpen}
                    onVisibleChange={(value) => this.setState({ overlaysHintPopoverOpen: value })}
                    placement={this.props.isWidget ? "topRight" : "top"}
                    arrowPointAtCenter
                    content={
                        <div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    color="orange"
                                />{" "}
                                <strong>Tunnel tool has moved</strong>
                            </div>
                            <div>You can find the new Overlays button at the bottom of the player.</div>
                            <div style={{ height: 150, width: "auto", marginTop: "5px" }}>
                                <img
                                    height="100%"
                                    width="100%"
                                    style={{ borderRadius: "6px", background: "#f3f3f3" }}
                                    crossOrigin={"anonymous"}
                                    src={overlayTunnelHint}
                                    alt={"Screenshot of new Overmays Mode Tool"}
                                />
                            </div>
                        </div>
                    }>
                    <div
                        className="MeasurementIconOuter"
                        type="button"
                        tabIndex={0}
                        aria-label="Tunnel"
                        onKeyDown={(e) => {
                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                this.setState({ overlaysHintPopoverOpen: !this.state.overlaysHintPopoverOpen });
                            }
                        }}>
                        <span className={"MeasurementIcon"}>
                            <FontAwesomeIcon icon={faArchway} /> Tunnel
                        </span>
                    </div>
                </Popover>
                {!this.props.isWidget ? (
                    <div
                        className="MeasurementIconOuter"
                        type="button"
                        aria-label="Snapshot"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                this.checkForChanges(() => this.props.dispatch(toggleSnapshot()), true);
                            }
                        }}>
                        <span
                            className="MeasurementIcon"
                            onClick={() => this.checkForChanges(() => this.props.dispatch(toggleSnapshot()), true)}>
                            {" "}
                            <FontAwesomeIcon icon={faCamera} />
                            Snapshot
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const dashboard = _.find(state.dashboards, { access_token: state.access_token });
    const isWidget = ["widget_layout", "minimal_layout"].includes(dashboard?.workspace_layout);

    return {
        calibrated: source_is_calibrated(state.measurement.calibration, sourceIndex),
        markupChanged: state.markup.hasUnsavedChange,
        userConfig: state.userDetails.userConfig,
        isWidget,
    };
};

export default connect(mapStateToProps)(MeasureInterface);
