import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Checkbox, Input, Modal, Select, Tooltip } from "antd";
import { toggleInspectionSnapshot, getEncodedSnapshot, customAudit } from "../../../redux/actions/index";
import { MEMOIZED_API_BASE_URL } from "../../util/HostUtils";
import OBCSpinner from "../../util/OBC";
import missingImage from "../../../images/obc-placeholder.png";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectUngroup } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import { calculateRouteCoordinatesForLocation } from "../../util/Geometry";
import sanitize from "sanitize-filename";
import RouteCoordinateSystems from "components/util/RouteCoordinateSystems";
import OBCButton from "components/OBC/OBCButton";
import { convertToTimezone } from "components/util/TimezoneUtils";

const visibleSelector = (state) => state.railInspection.snapshot.open;
const selectedRailImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const sessionIDSelector = (state) => state.playlist.data.routeID;
const accessTokenSelector = (state) => state.access_token;
const railImagesSelector = (state) => state.railInspection.railInspectionImages.data;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const detectionTypesSelector = (state) => state.railInspection.detections.types;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const userConfigSelector = (state) => state.userDetails.userConfig;
const imageAdjustmentsSelector = (state) => state.railInspection.imageAdjustments;
const patrolSelector = (state) => state.schemaInterface.plan;
const patrolIDSelector = (state) => state.schemaInterface.currentSessionId;
const patrolImagesSelector = (state) => _.get(state.schemaInterface.sessions, [state.schemaInterface.selectedSession], []);
const routeSystemIDSelector = (state) => state.playlist.data.system_id;

const MAX_IMAGES = 4;

const serialize = (obj) => {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    return str.join("&");
};

const LocationSelect = ({ location, setLocation, visible, patrolView }) => {
    const selectedRailImage = useSelector(selectedRailImageSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const currentLocation = useSelector((state) => state.schemaInterface.location);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const calculatedELRs = useMemo(() => {
        if (patrolView) {
            const system = RouteCoordinateSystems[0];
            if (currentLocation) {
                const locationObj = system.create(currentLocation["elr"], currentLocation["chain"], currentLocation["trackID"], []);
                return [locationObj.to_string("elr_mile_yards", []), locationObj.to_string("elr_meterage", []), locationObj.to_string("elr_mile_chain", [])];
            }
        } else if (selectedRailImage.timestamp && routeLocationData) {
            const calculatedELR = calculateRouteCoordinatesForLocation(selectedRailImage.timestamp, routeLocationData, routeSystemID);
            if (calculatedELR) {
                return [
                    calculatedELR.to_string("elr_mile_yards", []),
                    calculatedELR.to_string("elr_meterage", []),
                    calculatedELR.to_string("elr_mile_chain", []),
                ];
            }
        }
        return [];
    }, [routeLocationData, selectedRailImage.timestamp]);

    useEffect(() => {
        if (visible && calculatedELRs.length) {
            setLocation(calculatedELRs[0]);
        }
    }, [visible, calculatedELRs]);

    const locationOptions = useMemo(() => {
        return calculatedELRs.map((locationString) => {
            return (
                <Select.Option
                    key={locationString}
                    value={locationString}>
                    {locationString}
                </Select.Option>
            );
        });
    }, [calculatedELRs]);

    return (
        <Select
            style={{ width: 400 }}
            value={location}
            onChange={setLocation}>
            {locationOptions}
        </Select>
    );
};

const setCanvasImage = (path, func) => {
    const img = new Image();
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");

    img.onload = function () {
        c.width = this.naturalWidth;
        c.height = this.naturalHeight;
        ctx.drawImage(this, 0, 0);
        c.toBlob((blob) => {
            func(blob);
        }, "image/png");
    };
    img.src = path;
};

const InspectionSnapshotModal = ({ patrolView }) => {
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);
    const [footer, setFooter] = useState(true);
    const [detections, setDetections] = useState("none");
    const [annotations, setAnnotations] = useState("all");
    const [payload, setPayload] = useState(null);
    const [location, setLocation] = useState("");
    const [bookmarks, setBookmarks] = useState("all");
    const [imageTimestamps, setImageTimestamps] = useState([]);
    const [imageFlippedHorizontal, setImageFlippedHorizontal] = useState(false);

    const visible = useSelector(visibleSelector);
    const selectedRailImage = useSelector(selectedRailImageSelector);
    const sessionID = useSelector(patrolView ? patrolIDSelector : sessionIDSelector);
    const accessToken = useSelector(accessTokenSelector);
    const railImages = useSelector(patrolView ? patrolImagesSelector : railImagesSelector);
    const currentSession = useSelector(sessionSelector);
    const railImageConfig = useSelector(railImageConfigObjectSelector);
    const railsFlipped = useSelector(railsFlippedSelector);
    const detectionTypes = useSelector(detectionTypesSelector);
    const userConfig = useSelector(userConfigSelector);
    const imageAdjustments = useSelector(imageAdjustmentsSelector);
    const currentPatrol = useSelector(patrolSelector);
    const dispatch = useDispatch();

    let userIsOnFirefox = false;

    if (typeof window !== "undefined") {
        userIsOnFirefox = navigator.userAgent.includes("Firefox");
    }

    useEffect(() => {
        let snapshotName = "";
        if (patrolView) {
            if (currentPatrol && selectedRailImage) {
                snapshotName = `#${currentPatrol.id} ${currentPatrol.name}`;
            }
        } else {
            if (currentSession && selectedRailImage) {
                snapshotName = `#${currentSession.id} ${currentSession.route_name}`;
            }
        }
        const dateOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        const startTime = new Date(selectedRailImage.timestamp);
        const subTitle = convertToTimezone(startTime, userConfig.convert_to_utc, dateOptions);

        setSubtitle(subTitle);
        setTitle(snapshotName);
    }, [currentSession, currentPatrol, selectedRailImage, userConfig, patrolView]);

    const horizontal = useMemo(() => {
        return !!railImageConfig.horizontal;
    }, [railImageConfig.horizontal]);

    const cameraAtRear = useMemo(() => {
        return _.get(railImageConfig, ["video_orientation"]) === "Rear";
    }, [railImageConfig]);

    const flipped = useMemo(() => {
        const sessionTags = _.get(currentSession, ["tags"], []);
        const backwardSession = (_.indexOf(sessionTags, "Backward") !== -1) !== cameraAtRear;
        return (backwardSession && !railsFlipped) || (!backwardSession && railsFlipped);
    }, [currentSession, cameraAtRear, railsFlipped]);

    const handleClose = useCallback(() => {
        dispatch(toggleInspectionSnapshot(false));
    }, [dispatch]);

    const downloadImage = useCallback(() => {
        dispatch(customAudit("inspection_snapshot_download", { sessionID }, "Inspection snapshot downloaded"));
        var a = document.createElement("a"); //Create <a>
        a.href = image; //Image Base64 Goes here
        a.download = sanitize(`${title}`).substring(0, 50) + ".png";
        a.click(); //Downloaded file
    }, [image, title, sessionID, dispatch]);

    const copyImage = useCallback(() => {
        setCanvasImage(image, (imgBlob) => {
            navigator.clipboard
                .write([
                    /*global ClipboardItem*/
                    /*eslint no-undef: "error"*/
                    new ClipboardItem({ "image/png": imgBlob }),
                ])
                .then(() => {
                    console.log("Image copied to clipboard");
                })
                .catch((e) => {
                    console.log(e);
                });
        });
    }, [image]);

    const handleImageHorizontalFlipToggle = (value) => {
        setImageFlippedHorizontal(value);
    };

    useEffect(() => {
        if (!visible) {
            setPayload(null);
            setImage(null);
        }

        if (visible) {
            // setImages()
            if (selectedRailImage) {
                setImageTimestamps([selectedRailImage.timestamp]);
            }
        }
    }, [visible, selectedRailImage]);

    const getImageSource = useCallback(
        (payload) => {
            setLoading(true);

            let queryStrings = serialize(payload);

            const baseURL = MEMOIZED_API_BASE_URL;
            let source = `${baseURL}/snapshot?` + queryStrings;

            dispatch(getEncodedSnapshot(source, null)).then((image) => {
                if (image) {
                    setImage("data:image/png;base64," + image);
                    setLoading(false);
                } else {
                    setImage(missingImage);
                    setLoading(false);
                }
            });
        },
        [dispatch],
    );

    useEffect(() => {
        if (payload) {
            getImageSource(payload);
        }
    }, [payload, getImageSource]);

    const setPayloadDebounce = useCallback(
        _.debounce((payload) => {
            setPayload(payload);
        }, 500),
        [],
    );

    useEffect(() => {
        if (visible) {
            if (!selectedRailImage) {
                return null;
            }

            const adjustments = _.get(imageAdjustments, ["Primary"], {});

            let payload = {
                inspection: "1",
                image_timestamps: JSON.stringify(imageTimestamps),
                horizontal: horizontal,
                flipped: (imageFlippedHorizontal ?? flipped) ? 1 : 0,
                a: annotations,
                d: detections,
                b: bookmarks,
                image_adjustments: JSON.stringify(adjustments),
                title: title,
                subtitle: subtitle,
                footer: footer ? 1 : 0,
                location: location,
                session_id: sessionID,
                access_token: accessToken,
                show_os_watermark: true,
            };

            setPayloadDebounce(payload);
        }
    }, [
        imageAdjustments,
        title,
        subtitle,
        visible,
        accessToken,
        sessionID,
        selectedRailImage,
        flipped,
        railImages,
        footer,
        detections,
        annotations,
        location,
        bookmarks,
        imageTimestamps,
        horizontal,
        imageFlippedHorizontal,
    ]);

    const CopyToClipboardButton = () => {
        if (!userIsOnFirefox) {
            return (
                <Tooltip
                    title="Copied to clipboard"
                    trigger="click"
                    key="copy">
                    <Button
                        type="primary"
                        onClick={copyImage}
                        disabled={title === ""}>
                        Copy image to clipboard
                    </Button>
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    const addImageRight = useCallback(() => {
        const lastTimestamp = imageTimestamps[imageTimestamps.length - 1];
        const selectedIndex = _.findIndex(railImages, { timestamp: lastTimestamp });

        const after = railImages[selectedIndex + 1];
        const currentTimestamps = _.clone(imageTimestamps);
        const newTimestamps = [...currentTimestamps, after.timestamp];
        newTimestamps.sort(function (a, b) {
            return a - b;
        });
        setImageTimestamps(newTimestamps);
    }, [imageTimestamps, railImages]);

    const addImageLeft = useCallback(() => {
        const firstTimestamp = imageTimestamps[0];
        const selectedIndex = _.findIndex(railImages, { timestamp: firstTimestamp });
        const before = railImages[selectedIndex - 1];
        const currentTimestamps = _.clone(imageTimestamps);
        const newTimestamps = [...currentTimestamps, before.timestamp];
        newTimestamps.sort(function (a, b) {
            return a - b;
        });
        setImageTimestamps(newTimestamps);
    }, [imageTimestamps, railImages]);

    const addEnabledRight = useCallback(() => {
        const lastTimestamp = imageTimestamps[imageTimestamps.length - 1];
        const selectedIndex = _.findIndex(railImages, { timestamp: lastTimestamp });
        const after = railImages[selectedIndex + 1];
        return after;
    }, [imageTimestamps, railImages]);

    const addEnabledLeft = useCallback(() => {
        const firstTimestamp = imageTimestamps[0];
        const selectedIndex = _.findIndex(railImages, { timestamp: firstTimestamp });
        const before = railImages[selectedIndex - 1];
        return before;
    }, [imageTimestamps, railImages]);

    const removeImageRight = useCallback(() => {
        const currentTimestamps = _.clone(imageTimestamps);
        currentTimestamps.pop();
        setImageTimestamps(currentTimestamps);
    }, [imageTimestamps]);

    const removeImageLeft = useCallback(() => {
        const currentTimestamps = _.clone(imageTimestamps);
        currentTimestamps.shift();
        setImageTimestamps(currentTimestamps);
    }, [imageTimestamps]);

    const modalFooter = useMemo(() => {
        return [
            <Button
                onClick={handleClose}
                key="cancel">
                Cancel
            </Button>,
            <CopyToClipboardButton key="copy" />,
            <Button
                type="primary"
                onClick={downloadImage}
                disabled={title === ""}
                key="download">
                Download
            </Button>,
        ];
    }, [handleClose, title, downloadImage, copyImage]);

    const detectionOptions = useMemo(() => {
        return detectionTypes.map((type) => {
            return <Select.Option value={type}>{type}</Select.Option>;
        });
    }, [detectionTypes]);

    const beforeButtons = useMemo(() => {
        let addFunction = addImageLeft;
        let removeFunction = removeImageLeft;
        let isAddingPossible = addEnabledLeft();
        let plusDirection = "Extend Snapshot Left +1m";
        let minusDirection = "Undo";

        if (!horizontal) {
            plusDirection = "Extend Snapshot Above +1m";
            minusDirection = "Undo";
            if (!flipped) {
                addFunction = addImageRight;
                removeFunction = removeImageRight;
            }
        } else if (flipped) {
            addFunction = addImageRight;
            removeFunction = removeImageRight;
            isAddingPossible = addEnabledRight();
        }

        const lastTimestamp = imageTimestamps[imageTimestamps.length - 1];
        const selectedIndex = _.findIndex(railImages, { timestamp: lastTimestamp });
        const after = horizontal ? isAddingPossible : railImages[selectedIndex + 1];

        return (
            <div className={`SnapshotControllButtons ${horizontal ? "Col" : "Row"}`}>
                <OBCButton
                    icon={faPlusCircle}
                    onClick={addFunction}
                    disabled={loading || imageTimestamps.length === MAX_IMAGES || !after}>
                    {plusDirection}
                </OBCButton>
                <OBCButton
                    icon={faMinusCircle}
                    variant="danger"
                    onClick={removeFunction}
                    disabled={loading || imageTimestamps.length === 1}>
                    {minusDirection}
                </OBCButton>
            </div>
        );
    }, [
        addImageLeft,
        removeImageLeft,
        addEnabledLeft,
        horizontal,
        flipped,
        imageTimestamps,
        railImages,
        loading,
        addImageRight,
        removeImageRight,
        addEnabledRight,
    ]);

    const afterButtons = useMemo(() => {
        let addFunction = addImageRight;
        let removeFunction = removeImageRight;
        let isAddingPossible = addEnabledRight();
        let plusDirection = "Extend Snapshot Right +1m";
        let minusDirection = "Undo";

        if (!horizontal) {
            plusDirection = "Extend Snapshot Below +1m";
            minusDirection = "Undo";
            if (!flipped) {
                addFunction = addImageLeft;
                removeFunction = removeImageLeft;
            }
        } else if (flipped) {
            addFunction = addImageLeft;
            removeFunction = removeImageLeft;
            isAddingPossible = addEnabledLeft();
        }

        const lastTimestamp = imageTimestamps[imageTimestamps.length - 1];
        const selectedIndex = _.findIndex(railImages, { timestamp: lastTimestamp });
        const before = horizontal ? isAddingPossible : railImages[selectedIndex - 1];

        return (
            <div className={`SnapshotControllButtons ${horizontal ? "Col" : "Row"}`}>
                <OBCButton
                    icon={faPlusCircle}
                    onClick={addFunction}
                    disabled={loading || imageTimestamps.length === MAX_IMAGES || !before}>
                    {plusDirection}
                </OBCButton>
                <OBCButton
                    icon={faMinusCircle}
                    variant="danger"
                    onClick={removeFunction}
                    disabled={loading || imageTimestamps.length === 1}>
                    {minusDirection}
                </OBCButton>
            </div>
        );
    }, [
        addImageRight,
        removeImageRight,
        addEnabledRight,
        horizontal,
        flipped,
        imageTimestamps,
        railImages,
        loading,
        addImageLeft,
        removeImageLeft,
        addEnabledLeft,
    ]);

    return (
        <Modal
            visible={visible}
            className="SnapshotModalMain"
            width={"90%"}
            bodyStyle={{ height: "80vh", overflow: "auto", display: "flex", justifyContent: "center" }}
            centered={true}
            onCancel={handleClose}
            footer={modalFooter}>
            <div className="snapshotModal">
                <div className={"snapshotModal__imageContainer" + (horizontal ? "" : " Vertical")}>
                    {!horizontal && beforeButtons}
                    <div className={"InspectionSnapshotImage__Container" + (horizontal ? "" : " Vertical")}>
                        {image && (
                            <div className="ImageSrollable">
                                <img
                                    className={`InspectionSnapshotImage ${loading ? "loading" : ""}`}
                                    src={image}
                                    alt={"Snapshot"}
                                    crossOrigin={"anonymous"}
                                />
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div className="snapshotSpinner">
                            <OBCSpinner
                                colorScheme="mono"
                                size={70}
                            />
                        </div>
                    )}
                    {!horizontal && afterButtons}
                </div>
                <div className="HorizontalSnapshotControlls">
                    {horizontal && beforeButtons}
                    {horizontal && afterButtons}
                </div>

                <div className="snapshotModal__settingsContainer">
                    <div className="snapshotModal__setting">
                        <div className="snapshotModal__settingLabel">Title</div>
                        <div className="snapshotModal__settingInput">
                            <Input
                                autoFocus
                                className="textInput"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>
                    </div>

                    <div className="snapshotModal__setting">
                        <div className="snapshotModal__settingLabel">Subtitle</div>
                        <div className="snapshotModal__settingInput">
                            <Input
                                className="textInput"
                                onChange={(e) => setSubtitle(e.target.value)}
                                value={subtitle}
                            />
                        </div>
                    </div>

                    <div className="snapshotModal__settingsRow">
                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">
                                <FontAwesomeIcon
                                    icon={faObjectUngroup}
                                    className="snapshotIcon"
                                />
                                Detections
                            </div>
                            <div className="snapshotModal__settingInput">
                                <Select
                                    defaultValue="none"
                                    style={{ width: 120 }}
                                    onChange={(value) => setDetections(value)}>
                                    <Select.Option value="none">None</Select.Option>
                                    <Select.Option value="all">All</Select.Option>

                                    {detectionOptions}
                                </Select>
                            </div>
                        </div>

                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">
                                <FontAwesomeIcon
                                    icon={faObjectUngroup}
                                    className="snapshotIcon"
                                />
                                Annotations
                            </div>
                            <div className="snapshotModal__settingInput">
                                <Select
                                    defaultValue="all"
                                    style={{ width: 120 }}
                                    onChange={(value) => setAnnotations(value)}>
                                    <Select.Option value="all">All</Select.Option>
                                    <Select.Option value="mine">Mine</Select.Option>
                                    <Select.Option value="none">None</Select.Option>
                                </Select>
                            </div>
                        </div>

                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">
                                <FontAwesomeIcon
                                    icon={faObjectUngroup}
                                    className="snapshotIcon"
                                />
                                Bookmarks
                            </div>
                            <div className="snapshotModal__settingInput">
                                <Select
                                    defaultValue="all"
                                    style={{ width: 120 }}
                                    onChange={(value) => setBookmarks(value)}>
                                    <Select.Option value="all">All</Select.Option>
                                    <Select.Option value="mine">Mine</Select.Option>
                                    <Select.Option value="none">None</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="snapshotModal__setting">
                        <div className="snapshotModal__settingLabel">Footer</div>
                        <div
                            className="snapshoModal__settingInput"
                            style={{ marginLeft: 10 }}>
                            <Checkbox
                                onChange={(e) => setFooter(e.target.checked)}
                                checked={footer}>
                                Include a footer on the snapshot, containing location, author, workspace and session information.
                            </Checkbox>
                        </div>
                    </div>

                    <div className={"snapshotModal__setting" + (footer ? "" : " Disabled")}>
                        <div className="snapshotModal__settingLabel">Location value</div>
                        <div className="snapshoModal__settingInput">
                            <LocationSelect
                                location={location}
                                setLocation={(value) => setLocation(value)}
                                visible={visible}
                                patrolView={patrolView}
                            />
                        </div>
                    </div>

                    <div className="snapshotModal__setting">
                        <div className="snapshotModal__settingLabel">Image adjustments</div>
                        <div
                            className="snapshoModal__settingInput"
                            style={{ marginLeft: 10 }}>
                            <Checkbox
                                checked={imageFlippedHorizontal}
                                onChange={(e) => handleImageHorizontalFlipToggle(e.target.checked)}>
                                Flip horizontally
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InspectionSnapshotModal;
