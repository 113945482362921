import React, { useMemo, useEffect, useCallback, useRef, useState } from "react";
import { Modal, Input, DatePicker, Button, Progress } from "antd";
import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PatrolDefectsTable from "./PatrolDefectsTable";
import { setPatrolReportOpen, setPatrolReportForm, updatePatrolProgress, getPatrolUsers } from "../../redux/actions/index";
import { useReactToPrint } from "react-to-print";
import { calculateOffsettedPatrolImage } from "../util/PlaylistUtils";
import PatrolDefectsPrinted from "./PatrolDefectsPrinted";
import { useHistory, withRouter } from "react-router-dom";
import PatrolStatus from "./PatrolStatus";
import { calculatePatrolDeviceConfig } from "components/util/Geometry";

const DATE_FORMAT = "DD/MM/YYYY";

const userConfigSelector = (state) => state.userDetails.userConfig;
const patrolReportOpenSelector = (state) => state.schemaInterface.patrolReport.patrolReportOpen;
const patrolReportFormSelector = (state) => state.schemaInterface.patrolReport.form;
const annotationsSelector = (state) => state.railInspection.annotations.data;
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const railImageConfigsSelector = (state) => state.schemaInterface.config;
const allImagesSelector = (state) => state.schemaInterface.images;
const patrolDirectionsSelector = (state) => state.schemaInterface.patrolDirections;
const annotationTypesSelector = (state) => state.railInspection.annotations.types;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const userEmailSelector = (state) => state.userDetails.email;
const isSupervisorSelector = (state) => state.userDetails.userConfig.is_patrol_supervisor;

const PatrolReportForm = ({ patrolPlan, totalCoveragePercent, patrolID, match, status }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const componentRef = useRef();
    const commentInputRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: patrolPlan.name,
    });

    const isReview = match.path.includes("review-patrol");

    const userConfig = useSelector(userConfigSelector);
    const patrolReportVisible = useSelector(patrolReportOpenSelector);
    const patrolReportForm = useSelector(patrolReportFormSelector);
    const annotations = useSelector(annotationsSelector);
    const bookmarks = useSelector(bookmarksSelector);
    const railImageConfigs = useSelector(railImageConfigsSelector);
    const railImages = useSelector(allImagesSelector);
    const patrolDirections = useSelector(patrolDirectionsSelector);
    const annotationTypes = useSelector(annotationTypesSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const patrolPlanQueryID = parseInt(match.params.patrolID);
    const userEmail = useSelector(userEmailSelector);
    const isSupervisor = useSelector(isSupervisorSelector);

    const [displayCommentError, setDisplayCommentError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [confirmModalOpen, setConfirmModalOpen] = useState();

    const railImageConfig = useMemo(() => {
        return calculatePatrolDeviceConfig(railImageConfigs, railImages[0] ? railImages[0].timestamp / 1000 : 0);
    }, [railImageConfigs, railImages]);

    const annotationRows = useMemo(() => {
        if (!annotations || !annotations.length) {
            return [];
        }
        return annotations
            .filter((annotation) => {
                const annotationImageIndex = _.findIndex(railImages, (img) => img.timestamp.toString() === annotation.image_timestamp.toString());
                if (annotationImageIndex < 0) {
                    return false;
                }
                return true;
            })
            .map((annotation) => {
                const annotationImage = calculateOffsettedPatrolImage(annotation, railImageConfig, patrolDirections, railImages);
                const annotationType = _.find(annotationTypes, { id: annotation.annotation_type });
                return {
                    elr: annotationImage.mwv.elr,
                    trackID: annotationImage.mwv.trid,
                    position: `${annotationImage.mwv.mile}m ${parseFloat(annotationImage.mwv.yard).toFixed(2)}y`,
                    description: annotation.comment,
                    defectType: annotationType ? annotationType.type : null,
                    type: "annotation",
                    id: annotation.id,
                    source: annotation.image_source,
                    chain: parseInt(annotationImage.mwv.mile) * 80 + parseFloat(annotationImage.mwv.yard) / 22,
                    supervisor_edited: !annotation.is_users,
                };
            });
    }, [annotations, railImages, annotationTypes, railImageConfig, patrolDirections]);

    const bookmarkRows = useMemo(() => {
        if (!bookmarks || !bookmarks.length) {
            return [];
        }
        return bookmarks
            .filter((bookmark) => {
                const bookmarkImageIndex = _.findIndex(railImages, (img) => img.timestamp.toString() === bookmark.image_timestamp.toString());

                if (bookmarkImageIndex < 0) {
                    return false;
                }
                return true;
            })
            .map((bookmark) => {
                const bookmarkImage = calculateOffsettedPatrolImage(bookmark, railImageConfig, patrolDirections, railImages);

                return {
                    elr: bookmarkImage.mwv.elr,
                    trackID: bookmarkImage.mwv.trid,
                    position: `${bookmarkImage.mwv.mile}m ${parseFloat(bookmarkImage.mwv.yard).toFixed(2)}y`,
                    description: bookmark.description,
                    type: "bookmark",
                    id: bookmark.id,
                    source: bookmark.source,
                    chain: parseInt(bookmarkImage.mwv.mile) * 80 + parseFloat(bookmarkImage.mwv.yard) / 22,
                    supervisor_edited: !bookmark.is_users,
                };
            });
    }, [bookmarks, railImages, railImageConfig, patrolDirections]);

    const getPatrolledArea = useCallback(() => {
        const elrsPatrolled = {};
        _.get(patrolPlan, ["svg", "line"], []).forEach((line) => {
            if (!line.patrol_chain_start || !line.patrol_chain_end) {
                return;
            }

            const elr = line.ELR;
            const startChain = Math.min(line.patrol_chain_start, line.patrol_chain_end);
            const endChain = Math.max(line.patrol_chain_start, line.patrol_chain_end);
            const trackID = line.TRID;

            if (!elrsPatrolled[elr]) {
                elrsPatrolled[elr] = {
                    [trackID]: [
                        {
                            start: startChain,
                            end: endChain,
                        },
                    ],
                };
            } else if (!elrsPatrolled[elr][trackID]) {
                elrsPatrolled[elr][trackID] = [
                    {
                        start: startChain,
                        end: endChain,
                    },
                ];
            } else {
                let alreadyCovered = elrsPatrolled[elr][trackID];
                let added = false;

                let newCoverage = alreadyCovered.map((coverage) => {
                    if (Math.abs(startChain - coverage.end) <= 0.1 && startChain > coverage.start && endChain > coverage.end) {
                        coverage.end = endChain;
                        added = true;
                    } else if (Math.abs(endChain - coverage.start) >= 0.1 && endChain < coverage.end && startChain < coverage.start) {
                        coverage.start = startChain;
                        added = true;
                    } else if (startChain < coverage.start && endChain > coverage.end) {
                        coverage.start = startChain;
                        coverage.end = endChain;
                        added = true;
                    }
                    return coverage;
                });

                if (!added) {
                    newCoverage.push({
                        start: startChain,
                        end: endChain,
                    });
                }

                elrsPatrolled[elr][trackID] = newCoverage;
            }
        });

        return elrsPatrolled;
    }, [patrolPlan]);

    useEffect(() => {
        if (patrolReportVisible) {
            dispatch(getPatrolUsers());

            if (!patrolReportForm?.patrolName) {
                // Initialise report here
                const initialReportForm = {
                    patrolNumber: patrolReportForm.patrolNumber ? patrolReportForm.patrolNumber : patrolPlan.patrol_number || "",
                    patrolName: patrolReportForm.patrolName ? patrolReportForm.patrolName : patrolPlan.diagram_id || "",
                    patrollersName: patrolReportForm.patrollersName ? patrolReportForm.patrollersName : patrolPlan.assigned_user_email,
                    scheduledDate: patrolReportForm.scheduledDate ? patrolReportForm.scheduledDate : patrolPlan.due_ts,
                    patrolledDate: patrolReportForm.patrolledDate ? patrolReportForm.patrolledDate : Date.now() / 1000,
                    patrolArea: getPatrolledArea(),
                    defectList:
                        patrolReportForm.defectList && patrolReportForm.defectList.length ? patrolReportForm.defectList : [...bookmarkRows, ...annotationRows],
                    customDefectList: patrolReportForm.customDefectList ? patrolReportForm.customDefectList : [],
                    patrolComplete: !_.isNil(patrolReportForm.patrolComplete) ? patrolReportForm.patrolComplete : totalCoveragePercent >= 99.5,
                    patrolCompleteReason: patrolReportForm.patrolCompleteReason ? patrolReportForm.patrolCompleteReason : "",
                    supervisorName: supervisorsNameValue,
                    reviewDate: patrolReportForm.reviewDate ? patrolReportForm.reviewDate : null,
                    supervisorComment: patrolReportForm.supervisorComment ? patrolReportForm.supervisorComment : "",
                };
                dispatch(setPatrolReportForm(initialReportForm));
            } else {
                // Handle merging saved report with new field changes. E.g annotation description updates
                const mergedReport = _.cloneDeep(patrolReportForm);
                mergedReport.patrolComplete = !_.isNil(patrolReportForm.patrolComplete) ? patrolReportForm.patrolComplete : totalCoveragePercent >= 99.5;

                bookmarkRows.forEach((bookmark) => {
                    const newID = bookmark.id;
                    const inExistingList = _.findIndex(mergedReport.defectList, { type: bookmark.type, id: newID });

                    if (inExistingList === -1) {
                        mergedReport.defectList.push(bookmark);
                    }
                });

                annotationRows.forEach((annotation) => {
                    const newID = annotation.id;
                    const inExistingList = _.findIndex(mergedReport.defectList, { id: newID });

                    if (inExistingList === -1) {
                        mergedReport.defectList.push(annotation);
                    }
                });
                dispatch(setPatrolReportForm(mergedReport));
            }
        }
    }, [patrolReportVisible, bookmarkRows, annotationRows]);

    const patrolIdToUse = useMemo(() => {
        return isReview ? patrolPlanQueryID : patrolID;
    }, [isReview, patrolID, patrolPlanQueryID]);

    const updateForm = (key, value) => {
        const newForm = _.clone(patrolReportForm);
        newForm[key] = value;
        dispatch(setPatrolReportForm(newForm));
        saveReportDebounce(newForm, patrolIdToUse);
    };

    const saveReportDebounce = useCallback(
        _.debounce(
            (form, patrolID) => {
                dispatch(updatePatrolProgress(patrolID, null, null, form, isReview));
            },
            2000,
            { leading: false, trailing: true },
        ),
        [],
    );

    const updateStatus = useCallback(
        (status) => {
            dispatch(setPatrolReportOpen(false));
            dispatch(updatePatrolProgress(patrolID, null, status, patrolReportForm, isReview));
        },
        [dispatch, patrolID, patrolReportForm],
    );

    const patrolFinishedDisabled = useMemo(() => {
        return !patrolReportForm.patrolComplete && patrolReportForm.patrolCompleteReason === "";
    }, [patrolReportForm.patrolComplete, patrolReportForm.patrolCompleteReason]);

    const markPatrolAsFinished = (status) => {
        if (!patrolReportForm.patrolComplete && patrolReportForm.patrolCompleteReason === "") {
            if (commentInputRef.current) {
                commentInputRef.current.focus();
            }
            setErrorMessage("Please provide reason patrol was not completed");
            setDisplayCommentError(true);
        } else {
            setErrorMessage("");
            setDisplayCommentError(false);
            if (status === 1) {
                updateStatus(status);
                if (isSupervisor) {
                    history.push(`/patrol/${patrolID}`);
                }
            } else if (status === 2) {
                setConfirmModalOpen(true);
            } else {
                updateStatus(status);
            }
        }
    };

    const confirmFinished = () => {
        if (isSupervisor) {
            updateStatus(2);
            setConfirmModalOpen(false);
            history.push(`/review-patrol/${patrolID}`);
        } else {
            updateStatus(2);
            setConfirmModalOpen(false);
        }
    };

    const confirmModal = (
        <Modal
            title="Confirm"
            visible={confirmModalOpen}
            onOk={confirmFinished}
            okText={"Submit Report"}
            onCancel={() => setConfirmModalOpen(false)}>
            <p>Are you sure?</p>
            <p>Once you have submitted your report you will no longer be able to make changes.</p>
        </Modal>
    );

    const footer = useMemo(() => {
        const footer = [
            <Button
                onClick={() => dispatch(setPatrolReportOpen(false))}
                key="cancel">
                Close
            </Button>,
            <Button
                key="print"
                type="primary"
                onClick={handlePrint}>
                Print Report
            </Button>,
        ];

        if (!isReview && patrolStatus === 1) {
            footer.push(
                <Button
                    key="print"
                    type="primary"
                    onClick={() => markPatrolAsFinished(2)}>
                    Mark Patrol As Finished
                </Button>,
            );
        }

        if (isReview && patrolStatus === 2) {
            footer.push(
                <Button
                    key="print"
                    type="primary"
                    onClick={() => markPatrolAsFinished(3)}>
                    Mark Patrol As Reviewed
                </Button>,
            );
        }

        if (isReview && patrolStatus === 2) {
            footer.push(
                <Button
                    key="print"
                    type="primary"
                    onClick={() => markPatrolAsFinished(1)}>
                    Push back to patroller
                </Button>,
            );
        }

        return footer;
    }, [dispatch, handlePrint, patrolStatus, updateStatus, isReview, patrolFinishedDisabled]);

    const patrollerInputsDisabled = useMemo(() => {
        return patrolStatus >= 2;
    }, [patrolStatus]);

    const updateCompletion = (checked) => {
        if (supervisorInputsDisabled) {
            return;
        }
        if (commentInputRef.current) {
            commentInputRef.current.focus();
            setDisplayCommentError(false);
            setErrorMessage("");
        }
        updateForm("patrolComplete", checked);
    };

    const supervisorInputsDisabled = useMemo(() => {
        return patrolStatus === 3 || (patrolStatus === 2 && !isReview);
    }, [patrolStatus, isReview]);

    const dueDate = useMemo(() => {
        if (patrolPlan.due_ts) {
            return moment.unix(patrolPlan.due_ts).format("DD/MM/YYYY");
        }
    }, [patrolPlan.due_ts]);

    const supervisorsNameValue = useMemo(() => {
        return patrolReportForm.supervisorName ? patrolReportForm.supervisorName : "";
    }, [patrolReportForm]);

    return (
        <div className="PatrolFormContainer">
            {confirmModal}
            <Modal
                footer={footer}
                width={"90vw"}
                bodyStyle={{ height: "86vh", overflowY: "scroll", overflowX: "hidden", padding: 0 }}
                style={{ top: "4vh" }}
                visible={patrolReportVisible}
                onCancel={() => dispatch(setPatrolReportOpen(false))}>
                <PatrolDefectsPrinted ref={componentRef} />
                <div className="PatrolReportForm">
                    <style
                        type="text/css"
                        media="print">
                        {
                            "\
                            @page {size: landscape;padding:10px}\
                        "
                        }
                    </style>
                    <div className="PatrolReportForm__Header">
                        <div className="PatrolReportForm__Header__Title">
                            <h1>Basic Visual Inspection Report</h1>
                            <span>TEF 3015 v3</span>
                            <PatrolStatus status={patrolStatus} />
                        </div>
                        <Progress
                            type="circle"
                            percent={totalCoveragePercent}
                            width={55}
                            strokeColor="#a9c447"
                            className="ProgressCircle"
                        />
                    </div>

                    <div className="Patrol__Info">
                        <div className="PatrolInput__Container__Parent">
                            <div className="PatrolInput__Container">
                                <h3 className="InputHeader">Patrol No.</h3>
                                {patrollerInputsDisabled ? (
                                    <p className="Text">{patrolReportForm.patrolNumber ? patrolReportForm.patrolNumber : "Not specified"}</p>
                                ) : (
                                    <Input
                                        placeholder="Patrol Number"
                                        onChange={(e) => updateForm("patrolNumber", e.target.value)}
                                        value={patrolReportForm.patrolNumber}
                                        className="Input"
                                    />
                                )}
                            </div>

                            <div className="PatrolInput__Container">
                                <h3 className="InputHeader">Diagram ID</h3>
                                {patrollerInputsDisabled ? (
                                    <p className="Text">{patrolReportForm.patrolName ? patrolReportForm.patrolName : "Not specified"}</p>
                                ) : (
                                    <Input
                                        placeholder="Patrol Name"
                                        onChange={(e) => updateForm("patrolName", e.target.value)}
                                        value={patrolReportForm.patrolName}
                                        className="Input"
                                    />
                                )}
                            </div>

                            <div className="PatrolInput__Container">
                                <h3 className="InputHeader">Patroller's Name</h3>
                                {patrollerInputsDisabled ? (
                                    <>
                                        <p className="Text">{patrolReportForm.patrollersName}</p> {patrolStatus > 1 && userEmail && <span>{userEmail}</span>}
                                    </>
                                ) : (
                                    <Input
                                        placeholder="Name"
                                        onChange={(e) => updateForm("patrollersName", e.target.value)}
                                        value={patrolReportForm.patrollersName}
                                        className="Input"
                                    />
                                )}
                            </div>

                            <div className="PatrolInput__Container">
                                <h3 className="InputHeader">Scheduled Date of Patrol</h3>
                                <p className="Text">{dueDate ? dueDate : "Not specified"}</p>
                            </div>
                            <div className="PatrolInput__Container">
                                <h3 className="InputHeader">Actual Date of Patrol</h3>
                                <p className="Text">
                                    {patrolReportForm.patrolledDate ? moment.unix(patrolReportForm.patrolledDate).format(DATE_FORMAT) : "Not specified"}
                                </p>
                            </div>
                        </div>
                    </div>

                    <PatrolDefectsTable
                        saveForm={saveReportDebounce}
                        patrolID={patrolIdToUse}
                    />

                    <div className="Patrol__Row">
                        <div className="Patrol__Row">
                            <h2>Was this patrol fully completed?</h2>
                            <div className={`Patrol__Row__Switch ${supervisorInputsDisabled ? "disabled" : ""}`}>
                                <div
                                    className={`no ${patrolReportForm.patrolComplete ? "" : "active"}`}
                                    onClick={() => updateCompletion(false)}>
                                    No
                                </div>
                                <div
                                    className={`yes ${patrolReportForm.patrolComplete ? "active" : ""}`}
                                    onClick={() => updateCompletion(true)}>
                                    Yes
                                </div>
                            </div>
                            <div className="completion">
                                <Progress
                                    type="circle"
                                    percent={totalCoveragePercent}
                                    width={40}
                                    strokeColor="#a9c447"
                                    className="ProgressCircle"
                                />
                                <span>of images reviewed</span>
                            </div>
                        </div>
                        {displayCommentError && (
                            <div className="Patrol__Row Error">
                                <p>{errorMessage}</p>
                            </div>
                        )}
                        <div className={`Patrol__Row Comment ${displayCommentError ? "error" : ""}`}>
                            {supervisorInputsDisabled ? (
                                <p className="Text">{patrolReportForm.patrolCompleteReason}</p>
                            ) : (
                                <Input.TextArea
                                    className="TextArea"
                                    disabled={patrolReportForm.patrolComplete}
                                    rows={3}
                                    value={patrolReportForm.patrolCompleteReason}
                                    placeholder="If patrol was not completed please state reason why"
                                    onChange={(e) => updateForm("patrolCompleteReason", e.target.value)}
                                    ref={commentInputRef}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className="Patrol__Row"
                        style={{ marginBottom: -30, marginTop: 20 }}>
                        <div className="PatrollersSignatureContainer Small">
                            <h3>Supervisor's Name:</h3>
                            {supervisorInputsDisabled ? (
                                <p className="Text">{supervisorsNameValue}</p>
                            ) : (
                                <Input
                                    disabled={!isSupervisor}
                                    defaultValue={supervisorsNameValue}
                                    placeholder={supervisorsNameValue}
                                    className="Input"
                                />
                            )}
                        </div>
                        <div className="PatrollersSignatureContainer Small">
                            <h3>Date:</h3>
                            {supervisorInputsDisabled ? (
                                <p className="Text">{patrolReportForm.reviewDate ? moment.unix(patrolReportForm.reviewDate).format(DATE_FORMAT) : ""}</p>
                            ) : (
                                <DatePicker
                                    format={DATE_FORMAT}
                                    value={patrolReportForm.reviewDate ? moment.unix(patrolReportForm.reviewDate) : moment()}
                                    onChange={(date) => (date ? updateForm("reviewDate", date.unix()) : updateForm("reviewDate", null))}
                                />
                            )}
                        </div>
                    </div>
                    <div className="Patrol__Row Signature">
                        <div className="PatrollersSignatureContainer Large">
                            <h3>Supervisor's Comments:</h3>
                            {supervisorInputsDisabled ? (
                                <p className="Text">{patrolReportForm.supervisorComment}</p>
                            ) : (
                                <Input.TextArea
                                    placeholder="Supervisor's Comments"
                                    rows={3}
                                    onChange={(e) => updateForm("supervisorComment", e.target.value)}
                                    value={patrolReportForm.supervisorComment}
                                    className="TextArea"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default withRouter(PatrolReportForm);
