import { Divider, Button, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useMemo, useState, useRef } from "react";
import _ from "lodash";
import { goToBounds, selectAsset, selectIssue, selectObservation, updateIssue, getAssetData, reviewExternalObservation, customAudit } from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClipboard,
    faExclamationCircle,
    faCheckCircle,
    faEdit,
    faInfoCircle,
    faRuler,
    faSpellCheck,
    faHashtag,
    faUserLock,
    faTimes,
    faChevronRight,
    faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faThermometerEmpty } from "@fortawesome/pro-light-svg-icons";
import Highlighter from "react-highlight-words";
import { convertToTimezone } from "components/util/TimezoneUtils";
import Tippy from "@tippyjs/react";
import { combineGroundTruthConsensus, StatusElement } from "./issueUtils";
import ContentLoader from "react-content-loader";
import AssetIDHelper from "components/util/AssetIDHelper";
import ELRMileAndChain from "components/util/ELRMileAndChain";
import { LazyTippy } from "components/util/LazyTooltips";
import { sticky } from "tippy.js";
import { RawImageComponent } from "components/RawImageComponent";

const assetObservationsSelector = (state) => state.mlAssets.observations;
const userConfigSelector = (state) => state.userDetails.userConfig;
const assetGroupsSelector = (state) => state.mlAssets.groups;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const csrfTokenSelector = (state) => state.csrfToken;

const EMPTY_DICT = {};

const AssetSkeleton = ({ minHeight, assetRef }) => {
    return (
        <div
            ref={assetRef}
            className={"IssueListItem Closed"}
            style={{ minHeight }}>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 733 220"
                backgroundColor="#5a608a"
                foregroundColor="#4b5175">
                <rect
                    x="536"
                    y="89"
                    width="179"
                    height="108"
                    rx="3"
                    ry="3"
                />
                <rect
                    x="635"
                    y="15"
                    width="80"
                    height="25"
                    rx="3"
                    ry="3"
                />
                <rect
                    x="26"
                    y="15"
                    width="188"
                    height="25"
                    rx="3"
                    ry="3"
                />
                <rect
                    x="26"
                    y="54"
                    width="119"
                    height="25"
                    rx="3"
                    ry="3"
                />
                <rect
                    x="26"
                    y="93"
                    width="314"
                    height="25"
                    rx="3"
                    ry="3"
                />
                <rect
                    x="26"
                    y="132"
                    width="280"
                    height="65"
                    rx="3"
                    ry="3"
                />
            </ContentLoader>
        </div>
    );
};

const ExternalObservationItem = ({ observation, setParentSize }) => {
    const dispatch = useDispatch();
    const assetObservations = useSelector(assetObservationsSelector);

    useEffect(() => {
        setParentSize();
    }, []);

    const verifyExternalObservation = (e) => {
        e.stopPropagation();
        dispatch(reviewExternalObservation(observation.id, observation.review_status === 0, assetObservations));
    };

    let asset_id = observation.asset_id;
    let description = observation.description;
    let elr = observation.route_position_elr;
    let chains = observation.route_position_chains;
    let trid = observation.route_position_trid;
    let item_lat_lng = `${parseFloat(observation.lat.toFixed(6))}, ${parseFloat(observation.lng.toFixed(6))}`;

    // TODO this audit various places that use explicit coord system like this - buggy?
    let line_ref = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_meterage", {
        ELR: elr,
        METERAGE: chains * 20.1168,
        TRACK: trid,
    });

    let item_text = `${asset_id}`;

    if (description) {
        item_text += ` (${description})`;
    }

    if (line_ref && line_ref.elr) {
        item_text += ` at ${line_ref.to_mile_and_yard_string()}`;
    }
    const verifyStatus = observation.review_status ? (
        <button
            className="ExternalAssetVerify"
            onClick={verifyExternalObservation}>
            <FontAwesomeIcon
                icon={faCheckCircle}
                className="ExternalAssetIconBlue"
            />
            Verified
        </button>
    ) : (
        <button
            className="ExternalAssetVerify"
            onClick={verifyExternalObservation}>
            <FontAwesomeIcon
                icon={faCheckCircle}
                className="ExternalAssetIconGreen"
            />
            Verify observation
        </button>
    );

    return (
        <div className="ObservationItem">
            <span className="ID">#{observation.id}</span>
            <span className="SessionID">
                <b>External Record</b>
            </span>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="DateAndTime">{item_text}</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ textAlign: "center", marginRight: "0.5em" }}>{item_lat_lng}</span>
                    <LazyTippy
                        placement="top"
                        sticky={true}
                        plugins={[sticky]}
                        arrow={true}
                        theme="aivrlight"
                        interactive="true"
                        content="Copy Lat / Lon data to clipboard">
                        <div
                            className="CopyToClipboard"
                            onClick={() => {
                                navigator.clipboard.writeText(item_lat_lng);
                            }}>
                            <FontAwesomeIcon icon={faClipboard} />
                        </div>
                    </LazyTippy>
                </div>
            </div>

            <div>{verifyStatus}</div>
        </div>
    );
};

const ObservationItem = ({ observation, setParentSize, showFailedMatches }) => {
    const dispatch = useDispatch();
    const csrfToken = useSelector(csrfTokenSelector);
    const onClick = (e) => {
        e.stopPropagation();
        dispatch(selectObservation(observation.id));
    };

    const formatImage = (imgUrl) => {
        // remove frame index if session is from before 01/05/2020
        if (Math.round(observation.timestamp / 1000) <= 1588287599) {
            const arr = imgUrl.split("");
            const lastSlash = _.findLastIndex(arr, (e) => e === "/");
            const lastIndex = _.findLastIndex(arr, (e) => e === "-");
            if (lastIndex > lastSlash) {
                return arr.slice(0, lastIndex).join("") + ".jpg";
            }
        }
        if (csrfToken) {
            imgUrl += `?csrf=${csrfToken}`;
        }
        return imgUrl;
    };

    const humanDate = useMemo(() => {
        return moment.unix(observation.timestamp / 1000).format("LLL");
    }, [observation.timestamp]);

    const infoItems = useMemo(() => {
        const observationInfo = observation.observation_info;
        const infoItems = [];

        if (!observationInfo) {
            return [];
        }
        if (observationInfo.best_temp) {
            infoItems.push(<span key="temp">{observationInfo.best_temp} Degrees</span>);
        }

        if (observationInfo.gap_mm) {
            infoItems.push(<span key="gap">{observationInfo.gap_mm}mm</span>);
        }
        return infoItems;
    }, [observation.observation_info]);

    return (
        <div
            className="ObservationItem"
            onClick={onClick}>
            <span className="ID">
                #{observation.id}
                {observation.matching_state === 1 && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Matched On Consensus"}
                        theme="aivr"
                        zIndex="1000"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faSpellCheck}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
                {observation.matching_state === 9 && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Force Matched"}
                        theme="aivr"
                        zIndex="1000"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faUserLock}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
                {[88, 89].includes(observation.matching_state) && (
                    <Tippy
                        arrow={true}
                        placement="top"
                        content={"Not Matched"}
                        theme="aivr"
                        zIndex="1000"
                        delay="100">
                        <div className="MatchingIcon">
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="MatchingIcon"
                            />
                        </div>
                    </Tippy>
                )}
            </span>
            <span className="SessionID">
                Session:{" "}
                <span style={{ marginLeft: 5 }}>
                    <b>#{observation.session_id}</b>
                </span>
            </span>
            <span className="DateAndTime">{humanDate}</span>
            {infoItems}

            <Tippy
                arrow={true}
                placement="bottom"
                content={
                    <div className="IssueImageContainerToolTip">
                        <RawImageComponent
                            className="Observation"
                            src={formatImage(observation.image_url)}
                            alt="Observation"
                            crossOrigin={"anonymous"}
                        />
                    </div>
                }
                theme="aivr"
                zIndex="1000"
                delay="100">
                <div className="ObservationImageContainer">
                    <RawImageComponent
                        onLoad={setParentSize}
                        className={"ObservationImage"}
                        src={formatImage(observation.image_url)}
                        alt="Observation"
                        crossOrigin={"anonymous"}
                    />
                </div>
            </Tippy>
        </div>
    );
};

const AssetItem = ({ index, assetID, searchQuery, autoAck, setSize, windowWidth, selected, minHeight, showFailedMatches }) => {
    const dispatch = useDispatch();

    const [notes, setNotes] = useState("");
    const [originalNotes, setOriginalNotes] = useState("");
    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [displayExternalAsset, setDisplayExternalAsset] = useState(false);
    const [externalAssetNumber, setExternalAssetNumber] = useState(0);

    const assetObservations = useSelector(assetObservationsSelector);
    const userConfig = useSelector(userConfigSelector);
    const assetGroups = useSelector(assetGroupsSelector);
    const assetData = useSelector((state) => state.mlAssets.assetData[assetID] || EMPTY_DICT);
    const elrUnits = useSelector(elrUnitsSelector);

    const ref = useRef();

    let observations = [];
    let external_observations = [];
    observations = assetObservations.observations;
    external_observations = assetObservations.external_observations;

    const hasOnlyExternalObservations = useMemo(() => {
        if (_.get(assetData, "summary_data.match_count") == 0 && _.get(assetData, "summary_data.external_match_count") > 0) {
            return true;
        }
        return false;
    }, [assetData]);

    const setIssueSize = () => {
        if (ref.current) {
            const size = ref.current.getBoundingClientRect().height - 20;
            setSize(index, size);
        }
    };

    useEffect(() => {
        dispatch(getAssetData(assetID)).catch((error) => {
            console.log("debug error getting asset data", error);
        });
    }, [assetID, dispatch]);

    useEffect(() => {
        setTimeout(() => {
            setIssueSize();
        }, 100);
    }, [setSize, index, windowWidth, selected, observations, assetData]);

    useEffect(() => {
        if (notesModalOpen) {
            let note_value = assetData.note ? assetData.note : "";
            setNotes(note_value);
            setOriginalNotes(note_value);
        }
    }, [notesModalOpen]);

    const humanReadableDate = useMemo(() => {
        if (assetData.summary_data && assetData.summary_data.last_observed) {
            return moment.unix(assetData.summary_data.last_observed / 1000).format("DD/M/YY [at] HH:mm");
        } else {
            return null;
        }
    }, [assetData.summary_data]);

    const positionDifference = useMemo(() => {
        return _.get(assetData, "summary_data.fields.external_pos_diff");
    }, [assetData]);

    const onIssueItemClick = (evt, openModal = false) => {
        if (
            [
                "StatusContainer",
                "StatusContainer Clickable",
                "StatusContainer ant-popover-open",
                "ant-btn ant-btn-sm",
                "ant-btn ant-btn-primary ant-btn-sm",
            ].includes(evt.target?.className)
        ) {
            return;
        }
        if (selected) {
            if (_.isEmpty(evt)) {
                if (openModal && observations && observations.length) {
                    dispatch(selectObservation(observations[0].id));
                }
            } else {
                dispatch(selectIssue(null));
                dispatch(selectAsset(null));
            }
        } else {
            dispatch(selectAsset(assetData.id, showFailedMatches)).then((observations) => {
                if (openModal && observations && observations.length) {
                    dispatch(selectObservation(observations[0].id));
                }
            });

            let north = assetData["coords"][1] + 0.002;
            let east = assetData["coords"][0] + 0.002;
            let south = assetData["coords"][1] - 0.002;
            let west = assetData["coords"][0] - 0.002;
            dispatch(
                goToBounds({
                    north,
                    south,
                    east,
                    west,
                }),
            );
            const assetType = _.get(assetData, "class", "");
            dispatch(
                customAudit(
                    "asset_item_click",
                    { asset_type: assetType, target: "Asset Tab", source: "mouse" },
                    `asset_item_click ${assetType} clicked from the Assets tab`,
                ),
            );
        }
    };

    useEffect(() => {
        if ((observations && observations.length) || (external_observations && external_observations.length)) {
            setLoading(false);
        }
    }, [observations, external_observations]);

    const filteredObservations = useMemo(() => {
        if (!observations) {
            return [];
        }

        return observations.filter((obs) => {
            return !obs.classification || obs.classification === "verified";
        });
    }, [observations]);

    const orderedObservations = useMemo(() => {
        return _.orderBy(filteredObservations, "timestamp", "desc");
    }, [filteredObservations]);

    const observationItems = useMemo(() => {
        if (loading) {
            return (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        if (!selected) {
            return [];
        }

        if (!orderedObservations.length && external_observations.length == 0) {
            return <p className="NoObservationsText">No Observations Found</p>;
        }

        return (
            <div
                className="ObservationsList"
                onClick={(e) => e.stopPropagation()}>
                {orderedObservations.map((observation, idx) => {
                    return (
                        <ObservationItem
                            setParentSize={() => setIssueSize()}
                            key={observation.id}
                            observation={observation}
                        />
                    );
                })}

                {external_observations.map((ext_observation, idx) => {
                    return (
                        <ExternalObservationItem
                            key={"ext-" + ext_observation.id}
                            setParentSize={() => setIssueSize()}
                            observation={ext_observation}
                        />
                    );
                })}
            </div>
        );
    }, [external_observations, orderedObservations, selected, loading]);

    const issueHasUpdates = useMemo(() => {
        // 'updated' if match_ts (the last time this issue was updated with new observations)
        // is greater than 'state change ts' - state_change_ts is updated whenever 'state'
        // is passed in update issue - even if it is the same
        if (assetData.state_change_ts && assetData.state_change_ts * 1000 < assetData.match_ts) {
            return true;
        } else {
            return false;
        }
    }, [assetData.match_ts, assetData.state_change_ts]);

    const issueStatus = useMemo(() => {
        let colour = "red";
        let icon = faExclamationCircle;
        let text = "New Asset";

        if (assetData.state === 1) {
            colour = "grey";
            text = "Acknowledged";
            icon = faInfoCircle;

            if (issueHasUpdates) {
                colour = "orange";
                text = "New Observations";
                icon = faExclamationCircle;
            }
        } else if (assetData.state === 2) {
            colour = "green";
            text = "Closed";
            icon = faCheckCircle;
        }

        return (
            <div className={`StatusDiv`}>
                <FontAwesomeIcon
                    icon={icon}
                    className={`Icon ${colour}`}
                />
                <p className="StatusText">{text}</p>
            </div>
        );
    }, [assetData.state, issueHasUpdates]);

    const openNotesModal = (e) => {
        e.stopPropagation();
        setNotesModalOpen(true);
    };

    const saveNotes = () => {
        let update = { note: notes };
        if (autoAck && assetData.state === 0) {
            update.state = 1;
        }
        dispatch(updateIssue(assetData.id, update, false));
        setOriginalNotes(notes);
        setNotesModalOpen(false);
    };

    const handleCancel = () => {
        setNotesModalOpen(false);
        setNotes(originalNotes);
    };

    const convertDate = (date) => {
        const newDate = new Date(date * 1000);
        return convertToTimezone(newDate, userConfig.convert_to_utc);
    };

    const openGroundTruthModal = (e) => {
        e.stopPropagation();
        onIssueItemClick({}, true);
    };

    const observationsWorkspaceWarning = useMemo(() => {
        let content = null;
        if (selected && !loading) {
            const issueCount = _.get(assetData, "summary_data.match_count", 0);
            if (issueCount !== observations.length) {
                content = (
                    <Tippy content="Some observations of this asset are not visible in this workspace">
                        <div style={{ display: "inline" }}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                style={{ color: "#e55151", fontSize: "16px", cursor: "pointer" }}
                            />
                        </div>
                    </Tippy>
                );
            }
        }
        return content;
    }, [assetData, observations, selected, loading]);

    const infoFields = useMemo(() => {
        const fields = _.get(assetData, ["summary_data", "fields"], {});

        const infoElements = [];

        let match_count = _.get(assetData, ["summary_data", "match_count"], false);
        if (match_count) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="match_info">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faHashtag}
                    />
                    <p className="IssueInfoText">
                        <b>Observations</b>: {match_count} (Last seen {humanReadableDate}) {observationsWorkspaceWarning}
                    </p>
                </div>,
            );
        } else {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="match_info">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faHashtag}
                    />
                    <p className="IssueInfoText">
                        <b>Last Observation</b>: {humanReadableDate}
                    </p>
                </div>,
            );
        }

        if (fields.last_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="temp">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Last Temperature</b>: {fields.last_temperature} Degrees
                    </p>
                </div>,
            );
        }

        if (fields.highest_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="max_temp">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Highest Temperature</b>: {fields.highest_temperature} Degrees
                    </p>
                </div>,
            );
        }

        if (fields.last_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Last Gap</b>: {fields.last_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.max_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="max_gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Largest Gap</b>: {fields.max_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.min_gap_mm) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="min_gap">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Smallest Gap</b>: {fields.min_gap_mm}mm
                    </p>
                </div>,
            );
        }

        if (fields.last_height_diff_px) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="last_height_diff_px">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Last Height Difference</b>: {fields.last_height_diff_px}px
                    </p>
                </div>,
            );
        }

        if (fields.bottom_out_temperature) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="bottom_out_temp">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faThermometerEmpty}
                    />
                    <p className="IssueInfoText">
                        <b>Latest Bottom Out Temperature</b>:{" "}
                        {fields.bottom_out_temperature > 50 ? `50°C+ (${fields.bottom_out_temperature}°C)` : `${fields.bottom_out_temperature}°C`}
                    </p>
                </div>,
            );
        }

        if (fields.severity) {
            infoElements.push(
                <div
                    className="IssueInfo"
                    key="severity">
                    <FontAwesomeIcon
                        className="InfoIcon"
                        icon={faRuler}
                    />
                    <p className="IssueInfoText">
                        <b>Green zone overflow</b>: {fields.severity}cm
                    </p>
                </div>,
            );
        }

        let propertyElements = [];

        const groundTruthData = assetData.ground_truth_data || {};
        const consensusData = assetData.consensus_data || {};

        const propertyData = combineGroundTruthConsensus(groundTruthData, consensusData, assetData.class);

        if (_.get(assetData, "class") === "Tensioner" && _.has(assetData, "route_position_chains") && _.has(assetData, "route_position_trid")) {
            const elrObj = ELRMileAndChain.from_fields("ELR Mile & Chain", "elr_position", {
                ELR: assetData.route_position_elr,
                MILE: 0,
                CHAIN: assetData.route_position_chains,
                TRACK: assetData.route_position_trid,
            });

            propertyData.push({ name: "Route Position:", value: elrObj.to_string(elrUnits), filedName: "routePosition", icon: {} });
        } else {
            const elr = _.get(assetData, "route_position_elr", null);
            if (elr) {
                propertyData.push({ name: "ELR", value: elr, fieldName: "elr", icon: {} });
            }
        }

        propertyData.forEach((property) => {
            let value = property.value;
            let reformattedSearchQuery = new AssetIDHelper(searchQuery).formatted_search_query();

            // replace the way we display Signal and "Structure IDs
            if (["Signal ID", "Structure ID"].includes(property.name)) {
                value = AssetIDHelper.from_asset_data(assetData).csv_to_slashes();
            }
            // do not display too many digits after decimal point for coherence
            else if (property.name === "Coherence") {
                value = String(_.floor(parseInt(property.value), 2)) + "%";
            }

            propertyElements.push(
                <tr className="PropertyRow">
                    <td className="PropertyKey">{property.name}</td>
                    <td className="PropertyValue">
                        {["ELR", "Signal ID", "Structure ID"].includes(property.name) ? (
                            <Highlighter
                                highlightClassName="ObcTextHighlight"
                                searchWords={searchQuery ? [reformattedSearchQuery, searchQuery] : []}
                                autoEscape={true}
                                textToHighlight={String(value)}
                            />
                        ) : (
                            value
                        )}
                    </td>
                    {property.status && !hasOnlyExternalObservations && (
                        <>
                            <td className="PropertyValue">
                                <StatusElement
                                    issue={assetData}
                                    groundTruthKey={property.fieldName}
                                    status={property.status}
                                />
                            </td>
                            <td className="PropertyValue">
                                <FontAwesomeIcon
                                    className={"EditIcon"}
                                    icon={faEdit}
                                    onClick={openGroundTruthModal}
                                />
                            </td>
                        </>
                    )}
                </tr>,
            );
        });

        let propertiesContainerElement = (
            <table className="PropertiesContainer">
                <tbody>{propertyElements}</tbody>
            </table>
        );

        infoElements.push(propertiesContainerElement);

        return infoElements;
    }, [assetData, humanReadableDate, observationsWorkspaceWarning, searchQuery, openGroundTruthModal]);

    const image = useMemo(() => {
        let classes = ["IssueImage"];
        if (assetData.observation_type === 2 && !_.get(assetData.summary_data, ["images", "annotated"], false)) {
            classes.push("Rotated");
        }
        let thumbnail = _.get(assetData.summary_data, ["images", "thumbnail"]);
        if (thumbnail) {
            return (
                <RawImageComponent
                    className={classes.join(" ")}
                    src={thumbnail}
                    alt="Observation"
                    crossOrigin={"anonymous"}
                    loading="lazy"
                />
            );
        }
    }, [assetData.observation_type, assetData.summary_data]);

    const groupsToUse = useMemo(() => {
        return assetGroups;
    }, [assetGroups]);

    const handleExternalObservationClick = (e) => {
        e.stopPropagation();
        setDisplayExternalAsset(!displayExternalAsset);

        if (!selected) {
            onIssueItemClick({});
        }
    };

    const incrementExternalAsset = (e) => {
        e.stopPropagation();
        if (externalAssetNumber < external_observations.length - 1) {
            setExternalAssetNumber(externalAssetNumber + 1);
        } else {
            setExternalAssetNumber(0);
        }
    };

    const decrementExternalAsset = (e) => {
        e.stopPropagation();
        if (externalAssetNumber === 0) {
            setExternalAssetNumber(external_observations.length - 1);
        } else {
            setExternalAssetNumber(externalAssetNumber - 1);
        }
    };

    const verifyExternalObservation = (e) => {
        e.stopPropagation();
        dispatch(
            reviewExternalObservation(
                external_observations[externalAssetNumber].id,
                external_observations[externalAssetNumber].review_status === 0,
                assetObservations,
            ),
        );
    };

    const trustLevel = _.get(assetData, "summary_data.fields.trust_level", null);

    const trustLevelInfo = useMemo(() => {
        const trustLevelText = `This asset record currently has trust level ${trustLevel}.`;

        if (trustLevel === 1) {
            return `${trustLevelText} This means the asset has been detected by AIVR, validated by a person and also matched to external record.`;
        } else if (trustLevel === 2) {
            return `${trustLevelText} This means the asset is an external record which has been verified by a person.`;
        } else if (trustLevel === 3) {
            return `${trustLevelText} This means the asset has been detected by AIVR and validated by a person, but not matched to any external record.`;
        } else if (trustLevel === 4) {
            return `${trustLevelText} This means the asset is an external record that has not yet been validated by a person.`;
        } else if (trustLevel === 5) {
            return `${trustLevelText} This means the asset has been detected by AIVR, and may have been matched to an external record, but neither AIVR nor the external record has been validated by a person.`;
        } else if (trustLevel === 6) {
            return `${trustLevelText} This means that there is a potential problem with this asset data (for example, there is conflicting data that also exists) and it should be used with caution.`;
        } else {
            return "";
        }
    }, [trustLevel]);

    const vegetationState = useMemo(() => {
        const vegetationState = _.get(assetData, ["summary_data", "fields", "vegetation_state"]);
        if (vegetationState) {
            let vegetationContent = "Acceptable vegetation observed";
            let colour = "green";
            if (vegetationState === "vegetation_around_base") {
                vegetationContent = "Vegetation around the base";
                colour = "yellow";
            }
            if (vegetationState === "bad") {
                vegetationContent = "Unacceptable vegetation observed";
                colour = "red";
            }

            return (
                <Tippy
                    theme={"aivr"}
                    zIndex="1000"
                    delay="100"
                    arrow={true}
                    placement="left"
                    content={
                        <p
                            className="VegetationTooltip"
                            style={{ maxWidth: "300px", margin: "0px" }}>
                            {vegetationContent}
                        </p>
                    }>
                    <div className="Vegetation">
                        <p className="VegetationText">Vegetation</p>
                        <div
                            className="VegetationMarker"
                            style={{ backgroundColor: colour }}></div>
                    </div>
                </Tippy>
            );
        }

        return null;
    }, [assetData]);

    const externalAssetTooltip = () => {
        if (external_observations.length > 0) {
            const verifyStatus = external_observations[externalAssetNumber].review_status ? (
                <button
                    className="ExternalAssetVerify"
                    onClick={verifyExternalObservation}>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="ExternalAssetIconBlue"
                    />
                    Verified
                </button>
            ) : (
                <button
                    className="ExternalAssetVerify"
                    onClick={verifyExternalObservation}>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="ExternalAssetIconGreen"
                    />
                    Verify observation
                </button>
            );

            return (
                <div className="ExternalAssetContainer">
                    <div className="ExternalAssetTooltip">
                        <button
                            className={`ExternalAssetButton${external_observations.length === 1 ? "Hidden" : ""}`}
                            onClick={decrementExternalAsset}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <div className="ExternalAssetTooltipContainer">
                            <p className="ExternalAssetTooltipText">Asset ID: {external_observations[externalAssetNumber].asset_id}</p>
                            <p className="ExternalAssetTooltipText">{external_observations[externalAssetNumber].description}</p>
                        </div>
                        <button
                            className={`ExternalAssetButton${external_observations.length === 1 ? "Hidden" : ""}`}
                            onClick={incrementExternalAsset}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    {verifyStatus}
                </div>
            );
        }
    };

    if (_.isEmpty(assetData)) {
        return (
            <AssetSkeleton
                assetRef={ref}
                minHeight={minHeight}
            />
        );
    }

    return (
        <>
            {!!notesModalOpen && (
                <Modal
                    key={`notes-modal-${assetData.id}`}
                    title="Edit Issue Notes"
                    onCancel={handleCancel}
                    onOk={saveNotes}
                    footer={[
                        <Button
                            key="cancel"
                            type="default"
                            onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={saveNotes}>
                            Save
                        </Button>,
                    ]}
                    visible={true}>
                    <div className="IssueNotesContainer">
                        <Input.TextArea
                            autoFocus
                            placeholder="Enter a note about this issue..."
                            maxLength={140}
                            rows={4}
                            className="NotesInput"
                            value={notes}
                            onPressEnter={saveNotes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </Modal>
            )}

            <div
                className={"IssueListItem" + (selected ? " Open" : " Closed")}
                onClick={onIssueItemClick}
                style={{ minHeight }}
                ref={ref}>
                <div className="IssueHeader">
                    <div className="IssueLeftContainer">
                        <div className="IssueInfo">
                            <p className="IssueType">{_.get(groupsToUse, [assetData.issue_group_id, "display_name"], assetData.class) || assetData.class}</p>
                            {/* {issueStatus} */}
                        </div>

                        <div className="IssueInfo Note">
                            {assetData.note ? (
                                <p className="IssueNoteText">
                                    <Highlighter
                                        highlightClassName="ObcTextHighlight"
                                        searchWords={searchQuery ? searchQuery.split(" ") : []}
                                        autoEscape={true}
                                        textToHighlight={assetData.note}
                                    />
                                    {assetData.note_source && assetData.note_change_ts && (
                                        <Tooltip
                                            placement="bottom"
                                            mouseLeaveDelay={0}
                                            title={
                                                <span>
                                                    Last edited by {assetData.note_source} <br />
                                                    Date: {convertDate(assetData.note_change_ts)}
                                                </span>
                                            }>
                                            <span className="IssueAuthorAndDate">
                                                {assetData.note_source} - {moment.duration(moment().diff(moment(assetData.note_change_ts * 1000))).humanize()}{" "}
                                                ago
                                            </span>
                                        </Tooltip>
                                    )}
                                </p>
                            ) : assetData.note_source && assetData.note_change_ts ? (
                                <>
                                    <span className="IssueRemoved">[Removed]</span>
                                    <Tooltip
                                        placement="bottom"
                                        mouseLeaveDelay={0}
                                        title={
                                            <span>
                                                Note removed by {assetData.note_source} <br />
                                                Date: {convertDate(assetData.note_change_ts)}
                                            </span>
                                        }>
                                        <span className="IssueAuthorAndDate">
                                            {assetData.note_source} - {moment.duration(moment().diff(moment(assetData.note_change_ts * 1000))).humanize()} ago
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <p className="IssueNoteText Empty">Enter a note</p>
                            )}
                            <FontAwesomeIcon
                                className={"InfoIcon Edit" + (assetData.note ? "" : " Empty")}
                                icon={faEdit}
                                onClick={openNotesModal}
                            />
                        </div>
                        {infoFields}
                    </div>

                    <div className="IssueRightContainer">
                        <div className="IssueRightContainerTop">
                            <div className="IssueIdContainer">
                                {assetData.recalculating && (
                                    <Tooltip
                                        title={
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <span>Recalculating Asset</span>
                                                <span>Some data may be out of date</span>
                                            </div>
                                        }>
                                        <div>
                                            <OBCSpinner
                                                size={20}
                                                color={"#e8dfff"}
                                                speed={3}
                                            />
                                        </div>
                                    </Tooltip>
                                )}

                                <div className="IssueID">
                                    <span>
                                        #
                                        <Highlighter
                                            highlightClassName="ObcTextHighlight"
                                            searchWords={searchQuery ? searchQuery.split(" ") : []}
                                            autoEscape={true}
                                            textToHighlight={String(assetData.id)}
                                        />
                                    </span>
                                </div>
                            </div>
                            {trustLevel && (
                                <Tippy
                                    theme={"aivr"}
                                    zIndex="1000"
                                    delay="100"
                                    arrow={true}
                                    placement="left"
                                    content={
                                        <p
                                            className="AssetTrustLevelTooltip"
                                            style={{ maxWidth: "300px" }}>
                                            {trustLevelInfo}
                                        </p>
                                    }>
                                    <div className="AssetTrustLevelContainer">
                                        <p className="AssetTrustLevel">Level {trustLevel}</p>
                                    </div>
                                </Tippy>
                            )}
                            {vegetationState}
                        </div>

                        {assetData.has_external_observations && (
                            <div
                                className="ExternalAssetsContainer"
                                onClick={handleExternalObservationClick}>
                                <p className="ExternalAssets">External Data Match{positionDifference && `: ~${positionDifference}m`}</p>
                            </div>
                        )}
                        <Tippy
                            content={externalAssetTooltip()}
                            visible={displayExternalAsset}
                            interactive={true}
                            placement="bottom">
                            <div className="ExternalAssetPlacement" />
                        </Tippy>

                        <div className="IssueImageContainer">
                            <Tippy
                                arrow={true}
                                placement="bottom"
                                content={<div className="IssueImageContainerToolTip">{image}</div>}
                                theme="aivr"
                                zIndex="1000"
                                delay="100">
                                {image}
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className="IssueObservations">
                    <Divider className="Divider">Observations</Divider>
                    {observationItems}
                </div>
            </div>
        </>
    );
};

export default AssetItem;
