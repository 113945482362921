import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { MarkerIcon } from "./Icons";
import _ from "lodash";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MlAssetMarker from "./MlAssetMarker";
import memoizeOne from "memoize-one";

class MlAssetMarkers extends React.PureComponent {
    renderAsset = (asset) => {
        return (
            <MlAssetMarker
                asset={asset}
                key={asset.id}
                isAsset={this.props.currentTab === "assets"}
            />
        );
    };

    renderObservationMarkers = () => {
        if (!this.props.selectedObservation) {
            return null;
        }

        const obs = this.props.selectedObservation;
        return obs.map_points.map((point) => {
            return (
                <Marker
                    key={point.label}
                    position={[point.coords[1], point.coords[0]]}
                    icon={MarkerIcon}></Marker>
            );
        });
    };

    render() {
        return (
            <LayerGroup>
                <MarkerClusterGroup
                    maxClusterRadius={32}
                    showCoverageOnHover={false}
                    animate={true}
                    zoomToBoundsOnClick={true}>
                    {this.props.displayIssues &&
                        this.props.filteredIssues
                            .filter((asset) => !this.props.selectedAsset || asset.id !== this.props.selectedAsset.id)
                            .map(this.renderAsset)}
                </MarkerClusterGroup>
                {!_.isEmpty(this.props.selectedIssue) && (
                    <MlAssetMarker
                        asset={this.props.selectedIssue}
                        isAsset={false}
                    />
                )}
                {!_.isEmpty(this.props.selectedAsset) && (
                    <MlAssetMarker
                        asset={this.props.selectedAsset}
                        isAsset={true}
                    />
                )}
                {this.renderObservationMarkers()}
            </LayerGroup>
        );
    }
}

const getSelectedMarkers = memoizeOne((selectedIssueID, selectedAssetID, issues, assetLocations) => {
    const selectedIssue = _.find(issues, { id: selectedIssueID });
    let selectedAsset = null;
    if (selectedAssetID) {
        const assetData = _.find(assetLocations, (asset) => asset[0] === selectedAssetID);
        if (assetData) {
            selectedAsset = {
                id: selectedAssetID,
                coords: assetData[1],
                assetGroup: assetData[2],
                assetObservationType: assetData[3],
            };
        }
    }

    return {
        issue: selectedIssue,
        asset: selectedAsset,
    };
});

const mapStateToProps = (state) => {
    const selectedObservation = _.find(state.issues.observations, { id: state.issues.selectedObservation });

    const selectedIssueID = state.issues.selected.issue;
    const selectedAssetID = state.mlAssets.selected.asset;

    const { issue, asset } = getSelectedMarkers(parseInt(selectedIssueID), parseInt(selectedAssetID), state.issues.data, state.mlAssets.ids);

    return {
        selectedIssue: issue,
        selectedAsset: asset,
        selectedObservation,
        currentTab: state.currentTab,
        mapZoom: state.sessionFilters.mapBounds.zoom,
    };
};

export default connect(mapStateToProps)(MlAssetMarkers);
