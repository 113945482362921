import React, { useMemo, useState, useEffect, useCallback } from "react";
import ImagePlayer from "./ImagePlayer";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { logEvent, railInspectionSourceClosed } from "../../../redux/actions/index";
import DraggableWrapper from "./DraggableWrapper";
import { InputNumber } from "antd";

const playlistSelector = (state) => state.playlist.data;
const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const naiveIndexSelector = (state) => state.playlist.position.currentIndex;
const currentOffsetSelector = (state) => state.playlist.position.currentTimeOffset;
const imageKeysSelector = (state) => _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex], []);
const isStillsSelector = (state) => state.playlist.position.isStills;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const selectedRailInspectionImageSelector = (state) => state.railInspection.selectedRailInspectionImage;

const RailInspectNormalSource = ({ imagePlayer, match, windowDimensions, isSlave, isMainSource, fullscreenID, idx }) => {
    const dispatch = useDispatch();

    const [newAbsoluteTimestamp, setNewAbsoluteTimestamp] = useState(0);
    const [srcIndex, setSrcIndex] = useState(0);
    const [frameOffset, setFrameOffset] = useState(0);

    const playlist = useSelector(playlistSelector);
    const session = useSelector(sessionSelector);
    const sessionID = session.id || match.params.sessionID;
    const sessionName = session.route_name;
    const naiveIndex = useSelector(naiveIndexSelector);
    const currentOffset = useSelector(currentOffsetSelector);
    const imageKeys = useSelector(imageKeysSelector);
    const isStills = useSelector(isStillsSelector);
    const railData = useSelector(railDataObjectSelector);
    const selectedRailInspectionImage = useSelector(selectedRailInspectionImageSelector);

    useEffect(() => {
        if (playlist && playlist.video && playlist.video.length && railData && railData.length && selectedRailInspectionImage) {
            let index = selectedRailInspectionImage.index + frameOffset;
            if (index <= 0) {
                index = 0;
            } else if (index >= railData.length) {
                index = railData.length - 1;
            }

            const image = railData[index];
            let absoluteTimestamp = image.timestamp / 1000;
            setNewAbsoluteTimestamp(absoluteTimestamp);
        }
    }, [naiveIndex, currentOffset, playlist, railData, selectedRailInspectionImage, frameOffset, idx]);

    useEffect(() => {
        setSrcIndex(idx);
    }, [idx]);

    const getSourceList = useCallback(() => {
        const sources = [];

        if (!playlist || !playlist.video) {
            return sources;
        }

        for (let i = 0; i < playlist.video.length; i++) {
            const source = {
                name: "Camera " + (i + 1),
                streams: [
                    {
                        name: "Stills",
                        videoIndex: i,
                        isVideo: true,
                        isEnhanced: false,
                        isStills: true,
                    },
                ],
            };

            sources.push(source);
        }

        return sources;
    }, [playlist]);

    const sources = useMemo(() => {
        return getSourceList();
    }, [getSourceList]);

    const selectStream = (stream) => {
        if (stream && stream.target) {
            let streamReference = stream.target.value.split(".");
            let sourceIndex = 1 * streamReference[0];

            if (sourceIndex !== stream.videoIndex) {
                dispatch(logEvent("Video", "Select Source", stream.videoIndex));
            }

            setSrcIndex(streamReference[0]);
        }
    };

    const onOffsetChange = (offset) => {
        if (offset && offset > -1 && Number.isInteger(offset)) {
            setFrameOffset(offset);
        }
    };

    const offsetRange = useMemo(() => {
        let range = [-30, 30];
        if (selectedRailInspectionImage && railData && railData.length) {
            let index = selectedRailInspectionImage.index + frameOffset;
            if (index <= 0) {
                range[0] = frameOffset;
            } else if (index >= railData.length - 1) {
                range[1] = frameOffset;
            }
        }
        return range;
    }, [frameOffset, railData, selectedRailInspectionImage]);

    const closeSource = useCallback(() => {
        dispatch(railInspectionSourceClosed(idx));
    }, [dispatch, idx]);

    useEffect(() => {
        return () => {
            closeSource(idx);
        };
    }, [closeSource, idx]);

    const content = (
        <>
            <div className="inspectRail__Source__Controls">
                <InputNumber
                    className="offsetInput"
                    value={frameOffset}
                    onChange={(val) => onOffsetChange(val)}
                    min={offsetRange[0]}
                    max={offsetRange[1]}></InputNumber>

                <label
                    className="VideoControls__Toggle"
                    style={{ margin: "0 4px" }}>
                    <span>
                        <select
                            className="SourceSelect"
                            size="small"
                            value={srcIndex + ".0"}
                            onChange={selectStream}>
                            {_.map(sources, (source, source_index) => (
                                <React.Fragment key={source_index}>
                                    {_.map(source.streams, (stream, stream_index) => (
                                        <option
                                            key={source.name}
                                            value={`${source_index}.${stream_index}`}>
                                            {source.name}
                                        </option>
                                    ))}
                                </React.Fragment>
                            ))}
                        </select>
                    </span>
                </label>
            </div>
            <div className="inspectRail__Source__Image">
                {imageKeys && imageKeys.length && isStills ? (
                    <ImagePlayer
                        playerControls={false}
                        playSpeed={1}
                        ref={imagePlayer}
                        sourceIndex={srcIndex}
                        newAbsoluteTimestamp={newAbsoluteTimestamp}
                        isSlave={isSlave}
                        fullscreenID={fullscreenID}
                    />
                ) : null}
            </div>
        </>
    );

    const title = (
        <div className="inspectRail__Source__Controls__Title">
            <p>{sessionName}</p>
            <span>#{sessionID}</span>
        </div>
    );

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            canEnlarge
            customLocation={isSlave ? { x: 7, y: 13 } : {}}
            closeAction={() => closeSource(idx)}
        />
    );
};

export default RailInspectNormalSource;
