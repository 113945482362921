import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faCamera, faDrawSquare, faFastBackward, faFastForward, faMoon, faSun, faTag, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { Badge, Button, Collapse, Input, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customAudit, setSessionSearch, setSessionTagFilter } from "redux/actions";
import { SearchOutlined, FilterOutlined, EnterOutlined, CloseOutlined, CaretRightOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { TOGGLE_TAG_PAIRS, filterSessionTags, tagCategoryProperties, tagIcons } from "components/util/TagUtils";

const sessionSearchSelector = (state) => state.sessionListFilters.search;
const tagFilterSelector = (state) => state.sessionListFilters.tags;
const sessionTagsSelector = (state) => state.sessionTags;
const currentDashboardSelector = (state) => _.find(state.dashboards, (dashboard) => dashboard.access_token === state.access_token);
const detectionTypesSelector = (state) => state.detectionTypes;
const { Panel } = Collapse;

const SessionsSearch = ({ searchID, lightTheme = false, tagsOnly = false }) => {
    const dispatch = useDispatch();
    const popoverRef = useRef(null);
    const searchInputRef = useRef(null);
    const reqAbortRef = useRef();

    const tagFilter = useSelector(tagFilterSelector);
    const sessionSearch = useSelector(sessionSearchSelector);
    const sessionTags = useSelector(sessionTagsSelector);
    const detectionTypes = useSelector(detectionTypesSelector);
    const currentDashboard = useSelector(currentDashboardSelector);

    const [searchInputHasFocus, setSearchInputHasFocus] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [currentlyExpandedTags, setCurrentlyExpandedTags] = useState([]);
    const [currentSessionSearch, setCurrentSessionSearch] = useState("");
    const [activeSessionSearch, setActiveSessionSearch] = useState("");

    const whitelistFilters = _.get(currentDashboard, ["config", "tags_filter_whitelist"]);
    const filteredSessionTags = filterSessionTags(sessionTags, detectionTypes, whitelistFilters);

    const orderedCategories = useMemo(() => {
        return _.orderBy(Object.keys(filteredSessionTags), (cat) => _.get(tagCategoryProperties, [cat, "priority"], 999));
    }, [filteredSessionTags]);

    useEffect(() => {
        if (activeSessionSearch !== sessionSearch) {
            setActiveSessionSearch(sessionSearch);
        }
    }, [activeSessionSearch, sessionSearch]);

    const abortGetSessionRequest = () => {
        if (reqAbortRef.current) {
            reqAbortRef.current.abort("user_abort");
            reqAbortRef.current = null;
        }
    };

    const isSelected = useCallback(
        (tag) => {
            return _.includes(tagFilter, tag);
        },
        [tagFilter],
    );

    const selectedInCategoryCount = useCallback(
        (category) => {
            let count = 0;
            _.map(filteredSessionTags[category], (tag) => {
                if (_.includes(tagFilter, tag.tag)) {
                    count += 1;
                }
            });
            return count;
        },
        [filteredSessionTags, tagFilter],
    );

    // add click event listener to detect when user click outside popover
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is outside the popover and input field
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                if (searchInputRef && searchInputRef.current && searchInputRef.current.props.id !== event.target.id) {
                    setPopoverVisible(false);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // currently not in use will leave it here for now in case will have to enable again
    // const focusFirstElementAfterSessionSearchClear = () => {
    //     let focusableFirstElement = null
    //     let focusableFirstElements = []

    //     if (popoverRef.current) {
    //         const popoverDiv = popoverRef.current;
    //         if (popoverDiv) {

    //             focusableFirstElements = Array.from(popoverDiv.querySelectorAll('*'))
    //                 .filter(element => element.tabIndex >= 0 && !element.disabled);

    //             focusableFirstElement = _.get(focusableFirstElements, 1, false) // selecting 1 as the 0 is still clear button

    //             if (focusableFirstElement) {
    //                 focusableFirstElement.focus()
    //             }

    //         }
    //     }
    // }

    useEffect(() => {
        const getNextElement = (direction, currentFocusableElementIndex, focusableFirstElements) => {
            const currentElement = _.get(focusableFirstElements, [currentFocusableElementIndex], null);
            let currentElementY = null;

            if (currentElement) {
                currentElementY = _.get(currentElement.getBoundingClientRect(), "y", null);
            } else {
                return;
            }

            if (["ArrowLeft", "ArrowRight"].includes(direction)) {
                let nextElementHorizontal = _.get(focusableFirstElements, [currentFocusableElementIndex + 1], null);
                if (direction === "ArrowLeft") {
                    nextElementHorizontal = _.get(focusableFirstElements, [currentFocusableElementIndex - 1], null);
                }
                const nextElementY = _.get(nextElementHorizontal.getBoundingClientRect(), "y", null);
                if (currentElementY === nextElementY) {
                    return nextElementHorizontal;
                }
            } else if (["ArrowUp", "ArrowDown"].includes(direction)) {
                const currentElementPosition = currentElement.getBoundingClientRect();
                let nextElementVertical = null;

                // if current index is 0 then focus search input
                if (direction === "ArrowUp" && currentFocusableElementIndex === 0) {
                    if (searchInputRef.current) {
                        searchInputRef.current.focus();
                    }
                } else if (currentElementPosition) {
                    const currentX = _.get(currentElementPosition, "x", 0);
                    const currentWidth = _.get(currentElementPosition, "width", 0);
                    let centerPoint = currentX + currentWidth / 4;
                    if (currentElement.className === "ant-collapse-header") {
                        centerPoint = currentX + 20;
                    }

                    let indices = _.range(currentFocusableElementIndex, focusableFirstElements.length);

                    // different logic if up arrow pressed
                    if (direction === "ArrowUp") {
                        indices = _.range(currentFocusableElementIndex, -1);
                    }

                    _.forEach(indices, (index) => {
                        const element = _.get(focusableFirstElements, index);
                        if (element) {
                            const elementPosition = element.getBoundingClientRect();
                            const elementX = _.get(elementPosition, "x", null);
                            const elementY = _.get(elementPosition, "y", null);
                            const elementWidth = _.get(elementPosition, "width", null);
                            const nextOrPReviousRow = direction === "ArrowUp" ? elementY < currentElementY : elementY > currentElementY;

                            if (nextOrPReviousRow && !nextElementVertical) {
                                const elementPositionStart = elementX;
                                const elementPositionEnd = elementX + elementWidth;

                                if (elementPositionStart <= centerPoint && elementPositionEnd >= centerPoint) {
                                    nextElementVertical = element;
                                } else if (index === 0) {
                                    nextElementVertical = element;
                                }
                            }
                        }
                    });

                    return nextElementVertical;
                }

                return false;
            }
        };

        const handleKeyDown = (event) => {
            const regexAlphanumeric = /^[a-zA-Z0-9]$/; // Alphanumeric characters a-Z 1-9
            let firstFocusableElementSelected = false;
            let lastFocusableElementSelected = false;
            let currentFocusableElementIndex = null;
            let focusableFirstElement = null;
            let focusableFirstElements = [];

            if (popoverRef.current) {
                const popoverDiv = popoverRef.current;
                if (popoverDiv) {
                    focusableFirstElements = Array.from(popoverDiv.querySelectorAll("*")).filter((element) => element.tabIndex >= 0 && !element.disabled);

                    _.map(focusableFirstElements, (element, index) => {
                        if (element === document.activeElement) {
                            currentFocusableElementIndex = index;
                        }
                    });

                    focusableFirstElement = _.get(focusableFirstElements, 0, false);
                    const focusableLastElement = _.get(focusableFirstElements, focusableFirstElements.length - 1);

                    if (focusableFirstElement === event.target && searchInputRef && searchInputRef.current) {
                        firstFocusableElementSelected = true;
                    }

                    if (focusableLastElement === event.target) {
                        lastFocusableElementSelected = true;
                    }
                }
            }

            const getNextFocusableItem = () => {
                const nextElement = getNextElement(event.key, currentFocusableElementIndex, focusableFirstElements);
                if (nextElement) {
                    nextElement.focus();
                }
            };

            if (event.key === "Tab" && popoverVisible) {
                if (searchInputRef.current.props.id === document.activeElement.id) {
                    // if search input focused and tab or tab+shift pressed
                    if (event.shiftKey) {
                        setPopoverVisible(false);
                    } else {
                        if (focusableFirstElement) {
                            event.preventDefault();
                            focusableFirstElement.focus();
                        }
                    }
                } else if (lastFocusableElementSelected) {
                    // if last elelemnt in popover
                    event.preventDefault();
                    searchInputRef.current.focus();
                    searchInputRef.current.blur();
                    setPopoverVisible(false);
                } else if (event.shiftKey) {
                    // popover focussed and tab+shift pressed
                    if (firstFocusableElementSelected) {
                        event.preventDefault();
                        searchInputRef.current.focus();
                    }
                }
            } else if (event.key === "Escape" && popoverVisible) {
                setPopoverVisible(false);
            } else if (event.key === "ArrowDown") {
                // if search input currently focussed
                if (searchInputRef.current.props.id === document.activeElement.id) {
                    if (popoverVisible && focusableFirstElement) {
                        event.preventDefault();
                        focusableFirstElement.focus();
                    } else {
                        event.preventDefault();
                        setPopoverVisible(true);
                    }
                } else {
                    getNextFocusableItem();
                }
            } else if (event.key === "ArrowUp" && popoverVisible) {
                // if search input currently focussed
                if (searchInputRef.current.props.id === document.activeElement.id) {
                    setPopoverVisible(false);
                } else {
                    getNextFocusableItem();
                }
            } else if (["ArrowRight", "ArrowLeft"].includes(event.key) && popoverVisible) {
                getNextFocusableItem();
            } else if (regexAlphanumeric.test(event.key) && popoverVisible) {
                if (searchInputRef.current.props.id !== document.activeElement.id && popoverRef.current) {
                    searchInputRef.current.focus();
                }
            } else if (
                searchInputRef.current.props.id === document.activeElement.id &&
                event.key === "Enter" &&
                !popoverVisible &&
                !currentSessionSearch.length
            ) {
                setPopoverVisible(true);
            } else if (event.key === "Backspace" && popoverVisible) {
                searchInputRef.current.focus();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentSessionSearch, popoverVisible]);

    const checkForExactMatch = useCallback(() => {
        const popoverDiv = popoverRef.current;
        if (!popoverDiv) {
            return false;
        }
        const focusableFirstElements = Array.from(popoverDiv.querySelectorAll("*")).filter((element) => element.tabIndex >= 0 && !element.disabled);

        const exactMatch = _.find(focusableFirstElements, (button) => {
            return button.textContent.toLowerCase() === currentSessionSearch.toLowerCase() && button.nodeName === "BUTTON";
        });
        if (exactMatch) {
            exactMatch.focus();
        }
        return exactMatch;
    }, [currentSessionSearch]);

    useEffect(() => {
        const foundExactMatch = checkForExactMatch();
        if (searchInputRef.current.props.id !== document.activeElement.id && !foundExactMatch) {
            if (searchInputRef.current && currentSessionSearch) {
                searchInputRef.current.focus();
            }
        }
    }, [currentSessionSearch, checkForExactMatch]);

    const onTagClick = useCallback(
        (tag) => {
            let tags = _.clone(tagFilter);
            let selected = true;

            // if selected tag has tag pair make sure we deselect tag pair
            const tagPairIndex = _.findIndex(TOGGLE_TAG_PAIRS, (t) => {
                return _.includes(t, String(tag));
            });
            if (tagPairIndex >= 0) {
                tags = tags.filter((tag) => !_.includes(TOGGLE_TAG_PAIRS[tagPairIndex], tag));
            }

            if (tagFilter.includes(tag)) {
                tags = tags.filter((t) => tag !== t);
                selected = false;
            } else {
                tags.push(tag);
            }

            dispatch(
                customAudit(
                    "session_search_tag",
                    { tag: tag, action: selected ? "selected" : "deselected", target: "Session Search Popover" },
                    `session_search_tag tag "${tag}" ${selected ? "selected" : "deselected"} from Session Search Popover`,
                ),
            );

            abortGetSessionRequest();
            const abortController = new AbortController();
            reqAbortRef.current = abortController;

            dispatch(setSessionTagFilter(tags, abortController.signal));
            if (currentSessionSearch.length) {
                setCurrentSessionSearch("");
                if (searchInputRef && searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }
        },
        [dispatch, currentSessionSearch.length, tagFilter],
    );

    const renderSessionPanels = useMemo(() => {
        let groupedTags = [];
        _.map(orderedCategories, (tagCategory) => {
            let hasFilteredCategoryTags = false;
            let tagButtons = [];
            _.map(_.sortBy(filteredSessionTags[tagCategory], ["tag"]), (tag) => {
                const icon = _.get(tagIcons, [tag.icon, "icon"], _.get(tagIcons, [tagCategory, "icon"], faTag));
                const iconColor = _.get(tagIcons, [tag.icon, "color"], _.get(tagIcons, [tagCategory, "color"], "darkGrey"));
                const tagDisplayName = tag.display_name || tag.tag;
                if (
                    currentSessionSearch.length === 0 ||
                    (currentSessionSearch.length && tagDisplayName.toLowerCase().includes(currentSessionSearch.toLowerCase()))
                ) {
                    hasFilteredCategoryTags = true;

                    tagButtons.push(
                        <Button
                            className={`sessionSearchPopoverCollapsePanelItem ${isSelected(tag.tag) ? " active" : ""}`}
                            key={tag.tag}
                            tabIndex={0}
                            onClick={() => onTagClick(tag.tag)}>
                            <div className={`sessionSearchPopoverCollapsePanelItem__Icon ${iconColor}`}>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                            <span className="sessionSearchPopoverCollapsePanelItemLabel">
                                <Highlighter
                                    highlightClassName="ObcTextHighlight"
                                    searchWords={currentSessionSearch.split(" ")}
                                    autoEscape={true}
                                    textToHighlight={tagDisplayName}
                                />
                            </span>
                        </Button>,
                    );
                }
            });

            if (!hasFilteredCategoryTags && currentSessionSearch.length && tagCategory.toLocaleLowerCase().includes(currentSessionSearch.toLocaleLowerCase())) {
                hasFilteredCategoryTags = true;
            }

            if (!currentSessionSearch.length || (currentSessionSearch.length && hasFilteredCategoryTags)) {
                groupedTags.push(
                    <Panel
                        className="sessionSearchPopoverCollapsePanel"
                        key={tagCategory}
                        disabled={currentSessionSearch.length ? true : false} // if user type anything to reduce number of up/down key downs disable focus on the panel header
                        header={
                            <Badge
                                style={{ backgroundColor: "#40a9ff" }}
                                offset={[12, 0]}
                                count={selectedInCategoryCount(tagCategory)}>
                                <Highlighter
                                    highlightClassName="ObcTextHighlight"
                                    searchWords={currentSessionSearch.split(" ")}
                                    autoEscape={true}
                                    textToHighlight={tagCategory}
                                />
                            </Badge>
                        }>
                        {tagButtons.length ? (
                            tagButtons
                        ) : (
                            <div className="sessionSearchPopoverCollapsePanelNoMatch">
                                No tag matching "<strong>{currentSessionSearch}</strong>" in this category
                            </div>
                        )}
                    </Panel>,
                );
            }
        });

        return groupedTags;
    }, [currentSessionSearch, filteredSessionTags, isSelected, onTagClick, orderedCategories, selectedInCategoryCount]);

    const onSessionTextSearchClick = () => {
        if (currentSessionSearch.length && !tagsOnly) {
            setActiveSessionSearch(currentSessionSearch);
            dispatch(setSessionSearch(currentSessionSearch));
            setCurrentSessionSearch("");
            searchInputRef.current.focus();
            setPopoverVisible(false);
        }
    };

    const clearCurrentSessionSearch = (event = null) => {
        if (!event || event.key === "Enter") {
            setActiveSessionSearch("");
            dispatch(setSessionSearch(""));
            searchInputRef.current.focus();
            setPopoverVisible(false);
        }
    };

    const content = (
        <div ref={popoverRef}>
            {!tagsOnly ? (
                <div
                    className="sessionSearchTextSearchItem"
                    style={{ display: currentSessionSearch.length ? "block" : "none" }}>
                    <button
                        className={searchInputHasFocus ? "sessionSearchTextSearchItemButtonShowHint" : ""}
                        aria-label={currentSessionSearch.length ? `Search for ${currentSessionSearch}` : ""}
                        disabled={!currentSessionSearch.length}
                        onClick={onSessionTextSearchClick}>
                        <div className="sessionSearchTextSearchItemButton">
                            <div className="sessionSearchTextSearchItemButtonText">
                                <SearchOutlined />
                                {currentSessionSearch}
                            </div>
                            <div className="sessionSearchTextSearchItemButtonEnterHint">
                                <EnterOutlined />
                                <span>Enter</span>
                            </div>
                        </div>
                    </button>
                </div>
            ) : null}

            {!tagsOnly ? (
                <div
                    className="sessionSearchActiveTextSearchItem"
                    style={{ display: activeSessionSearch.length ? "block" : "none" }}>
                    <span>Search text: </span>
                    <button
                        tabIndex={-1}
                        disabled={!activeSessionSearch.length}>
                        <div className="sessionSearchActiveTextSearchItemButton">
                            {activeSessionSearch}
                            <div
                                className="sessionSearchActiveTextSearchItemClearButton"
                                onClick={() => clearCurrentSessionSearch()}
                                onKeyPress={(e) => {
                                    clearCurrentSessionSearch(e);
                                }}
                                aria-label={activeSessionSearch.length ? `Clear ${activeSessionSearch}` : ""}
                                type="button"
                                disabled={!activeSessionSearch.length}
                                tabIndex={sessionSearch.length ? 0 : -1}>
                                <CloseOutlined />
                            </div>
                        </div>
                    </button>
                </div>
            ) : null}

            {!orderedCategories.length ? (
                <div className="sessionSearchPopoverLoadingInfo">Loading...</div>
            ) : (
                <Collapse
                    className="sessionSearchPopoverCollapse"
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    onChange={(tags) => (!currentSessionSearch.length ? setCurrentlyExpandedTags(tags) : null)}
                    activeKey={currentSessionSearch.length ? orderedCategories : currentlyExpandedTags}>
                    {renderSessionPanels}
                    {!renderSessionPanels.length ? (
                        <div className="sessionSearchPopoverNoMatch">
                            No tag matching "<strong>{currentSessionSearch}</strong>" found
                        </div>
                    ) : null}
                </Collapse>
            )}
        </div>
    );

    return (
        <div
            className={`sessionSearch ${lightTheme ? "lightTheme" : "darkTheme"}`}
            id="intro-tour-session-search">
            <Popover
                overlayClassName={`sessionSearchPopover ${lightTheme ? "lightTheme" : "darkTheme"}`}
                content={content}
                title={null}
                placement="bottomRight"
                transitionName=""
                visible={popoverVisible}
                trigger="click">
                <Input
                    id={`session-search-input-${searchID}`} // we are adding key here to make sure popover gets open only in one place on ArrowDown event
                    value={currentSessionSearch}
                    ref={searchInputRef}
                    autoComplete="off"
                    onClick={() => {
                        if (!popoverVisible) {
                            setPopoverVisible(true);
                        }
                    }}
                    onChange={(e) => {
                        setCurrentSessionSearch(e.target.value);
                    }}
                    onPressEnter={onSessionTextSearchClick}
                    placeholder={tagsOnly ? "Apply filters" : "Search for a session"}
                    style={{ width: 250, height: 35 }}
                    spellCheck={false}
                    prefix={tagsOnly ? <FilterOutlined /> : <SearchOutlined />}
                    onFocus={() => {
                        setPopoverVisible(true);
                        setSearchInputHasFocus(true);
                        // move cursor to the end of the line, known issue - will not move cursor to the end when focused from ArrowUp
                        searchInputRef.current.input.setSelectionRange(currentSessionSearch.length, currentSessionSearch.length);
                    }}
                    onBlur={() => setSearchInputHasFocus(false)}
                    suffix={
                        currentSessionSearch.length ? (
                            <div
                                aria-label="Clear"
                                role="button"
                                tabIndex={0}
                                onClick={() => setCurrentSessionSearch("")}
                                onKeyDown={(e) => {
                                    if (e.keyCode && _.includes([13, 32], e.keyCode)) {
                                        setCurrentSessionSearch("");
                                    }
                                }}>
                                <FontAwesomeIcon
                                    className="sessionSearchInputClearButton"
                                    icon={faTimesCircle}
                                    style={{ color: "white" }}
                                />
                            </div>
                        ) : null
                    }
                />
            </Popover>
        </div>
    );
};

export default SessionsSearch;
