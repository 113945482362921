import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { BookmarkIcon } from "./Icons";
import { getInterpolatedPosition, getOffsetAdjustedTime, absoluteTimeLookup } from "../util/PlaylistUtils";
import { selectBookmark } from "redux/actions/index";

class BookmarkMarkers extends React.PureComponent {
    renderBookmarkMarkers = () => {
        let markerIconToUse = BookmarkIcon;

        const markers = [];

        if (this.props.viewingRouteData.video && this.props.viewingRouteData.video[this.props.sourceIndex] && this.props.bookmarks.length) {
            let videoParts = this.props.viewingRouteData.video[this.props.sourceIndex];
            this.props.bookmarks.forEach((bookmark, index) => {
                let bookmarkIndex = absoluteTimeLookup(bookmark.image_timestamp / 1000, videoParts) || 0;
                let bookmarkVideo = videoParts[bookmarkIndex];
                const startVideoTime = bookmarkVideo[1];
                let offsetStartTime = getOffsetAdjustedTime(startVideoTime, this.props.offsets) + 0.0001;
                let coords = getInterpolatedPosition(offsetStartTime, videoParts, this.props.use_snapped);
                let currentTimeOffset = this.props.offset;

                let currentKey = videoParts[bookmarkIndex];
                if (currentKey) {
                    let duration = currentKey[2];
                    let frameCount = currentKey[5];
                    if (duration && frameCount) {
                        currentTimeOffset += duration / frameCount;
                        if (currentTimeOffset >= duration) {
                            currentTimeOffset = 0;
                            bookmarkIndex += 1;
                        }
                    } else {
                        currentTimeOffset = 0;
                        bookmarkIndex += 1;
                    }
                }

                if (coords !== null) {
                    let startCoords = [coords[1], coords[0]];
                    markers.push(
                        <Marker
                            position={startCoords}
                            onClick={() => this.onBookmarkClick(bookmark, bookmarkIndex, currentTimeOffset)}
                            icon={markerIconToUse}
                            key={index}
                        />,
                    );
                }
            });
        }
        return markers;
    };

    onBookmarkClick = (bookmark) => {
        this.props.dispatch(selectBookmark(bookmark.id));
    };

    render() {
        return <LayerGroup>{this.renderBookmarkMarkers()}</LayerGroup>;
    }
}

const mapStateToProps = (state) => {
    const offset = state.playlist.position.currentTimeOffset;

    return {
        bookmarks: state.railInspection.bookmarks || [],
        sourceIndex: state.playlist.position.sourceIndex,
        viewingRouteData: state.playlist.data,
        use_snapped: state.snappedRoute || false,
        index: state.playlist.position.currentIndex,
        offset,
    };
};

export default connect(mapStateToProps)(BookmarkMarkers);
