import React from "react";
import { connect } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { MarkupIcon, MeasureIcon, AnnotationIcon } from "./Icons";
import _ from "lodash";
import { calculateOffset, getInterpolatedPosition, getOffsetAdjustedTime, keySourceLookup } from "components/util/PlaylistUtils";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import { requestPlaylistPosition, changeToolMode } from "../../redux/actions/index";
import { Modal } from "antd";
import { LazyMapTooltip } from "../util/LazyTooltips";
import memoize from "memoize-one";
const { confirm } = Modal;

class MarkupMarkers extends React.PureComponent {
    renderMarkerTooltip = (marker) => {
        let session = this.props.sessions[marker.session_id];
        if (!session) {
            return null;
        }
        let snapshotBaseURL = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_uuid}/${session.uuid}/`;

        let imageURL = snapshotBaseURL + marker.video_key + ".jpg";
        if (this.props.csrfToken) {
            imageURL += `?csrf=${this.props.csrfToken}`;
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span style={{ margin: 2.5 }}>{marker.name}</span>
                <img
                    style={{ margin: 2.5, width: "200px" }}
                    src={imageURL}
                    crossOrigin={"anonymous"}
                    alt=""
                />
                <span style={{ margin: 2.5, textTransform: "capitalize" }}>Source: {marker.source.toLowerCase()}</span>
            </div>
        );
    };

    checkForChanges = (callback) => {
        if (this.props.markupChanged) {
            confirm({
                title: "Unsaved Changes",
                content: "You have unsaved changes. Navigating away from here will delete these changes. Are you sure?",
                onOk() {
                    callback();
                },
                onCancel() {},
            });
        } else {
            callback();
        }
    };

    iconClick = (marker) => {
        const frameTimeOffset = calculateOffset(this.props.viewingRouteData.video[marker.source_index], marker.video_index, marker.frame);
        this.props.dispatch(
            requestPlaylistPosition(this.props.isVideo, this.props.isEnhanced, this.props.isStills, marker.source_index, marker.video_index, frameTimeOffset),
        );
        this.props.dispatch(changeToolMode(marker.type));
    };

    renderMarker = (marker) => {
        let markerIconToUse = MarkupIcon;

        if (marker.type === "measure") {
            markerIconToUse = MeasureIcon;
        } else if (marker.type === "annotate") {
            markerIconToUse = AnnotationIcon;
        }

        if (marker.coords && marker.coords[0] && marker.coords[1]) {
            return (
                <Marker
                    key={"Marker" + marker.id}
                    position={marker.coords}
                    icon={markerIconToUse}
                    onclick={() => this.checkForChanges(() => this.iconClick(marker))}>
                    <LazyMapTooltip
                        className="Incident-Tooltip"
                        permanent={false}
                        interactive={true}
                        direction={"top"}
                        offset={[0, -30]}>
                        {this.renderMarkerTooltip(marker)}
                    </LazyMapTooltip>
                </Marker>
            );
        } else {
            return null;
        }
    };

    getMarkerCoords = () => {
        let markers = [];

        if (this.props.viewingRouteData.video) {
            let sketches = this.props.sketches;
            let measurements = this.props.measurements;
            let annotations = this.props.annotations;

            let videoParts = this.props.viewingRouteData.video;
            if (this.props.toolMode === "draw") {
                sketches.forEach((sketch) => {
                    let keySource = keySourceLookup(sketch.video_key, videoParts);
                    let playlistIndex = keySource[0];
                    let sourceIndex = keySource[1];

                    if (playlistIndex !== -1) {
                        let videoTimestamp = videoParts[sourceIndex][playlistIndex][1];
                        let offsetTime = getOffsetAdjustedTime(videoTimestamp, this.props.offsets[sourceIndex]);
                        let coords = getInterpolatedPosition(offsetTime, videoParts[sourceIndex], this.props.use_snapped);
                        if (coords && coords[0] && coords[1]) {
                            console.log("Placing marker", sketch.video_key, "at coordinates:", coords);
                            markers.push({
                                source: "Sketch",
                                name: "Sketch",
                                id: `sketch-${sketch.video_key}`,
                                session_id: this.props.viewingRouteData.routeID,
                                video_key: sketch.video_key,
                                source_index: sourceIndex,
                                video_index: playlistIndex,
                                frame: sketch.frame,
                                coords: [coords[1], coords[0]],
                                type: "draw",
                            });
                        }
                    }
                });
            } else if (this.props.toolMode === "measure") {
                measurements.forEach((measure) => {
                    let keySource = keySourceLookup(measure.video_key, videoParts);
                    let playlistIndex = keySource[0];
                    let sourceIndex = keySource[1];

                    if (playlistIndex !== -1) {
                        let videoTimestamp = videoParts[sourceIndex][playlistIndex][1];
                        let offsetTime = getOffsetAdjustedTime(videoTimestamp, this.props.offsets[sourceIndex]);
                        let coords = getInterpolatedPosition(offsetTime, videoParts[sourceIndex], this.props.use_snapped);
                        markers.push({
                            source: "Measurement",
                            name: "Measurement",
                            id: `measurement-${measure.video_key}`,
                            session_id: this.props.viewingRouteData.routeID,
                            video_key: measure.video_key,
                            source_index: sourceIndex,
                            video_index: playlistIndex,
                            frame: measure.frame,
                            coords: [coords[1], coords[0]],
                            type: "measure",
                        });
                    }
                });
            } else if (this.props.toolMode === "annotate") {
                annotations.forEach((annotation) => {
                    let keySource = keySourceLookup(annotation.videoKey, videoParts);
                    let playlistIndex = keySource[0];
                    let sourceIndex = keySource[1];

                    if (playlistIndex !== -1) {
                        let videoTimestamp = videoParts[sourceIndex][playlistIndex][1];
                        let offsetTime = getOffsetAdjustedTime(videoTimestamp, this.props.offsets[sourceIndex]);
                        let coords = getInterpolatedPosition(offsetTime, videoParts[sourceIndex], this.props.use_snapped);
                        if (coords) {
                            markers.push({
                                source: "Annotation",
                                name: "Annotation",
                                id: `annotation-${annotation.videoKey}`,
                                session_id: this.props.viewingRouteData.routeID,
                                video_key: annotation.videoKey,
                                source_index: sourceIndex,
                                video_index: playlistIndex,
                                frame: annotation.frame,
                                coords: [coords[1], coords[0]],
                                type: "annotate",
                            });
                        }
                    }
                });
            }
        }

        return markers;
    };

    render() {
        let markers = this.getMarkerCoords();

        return (
            <LayerGroup>
                {this.props.toolMode && markers.map(this.renderMarker)}
                {/* {this.createMapMarkers()} */}
            </LayerGroup>
        );
    }
}

const uniqueMeasurements = memoize((ms) =>
    _.uniqBy(ms, function (m) {
        return m.video_key;
    }),
);
const uniqueSketches = memoize((ss) =>
    _.uniqBy(ss, function (s) {
        return s.video_key;
    }),
);
const uniqueAnnotations = memoize((annotations) =>
    _.uniqBy(annotations, function (a) {
        return a.videoKey;
    }),
);

const mapStateToProps = (state) => {
    const routeID = state.playlist.data.routeID;

    let offsets = [];

    if (routeID === state.gpsTimeOffsets.sessionID) {
        offsets = state.gpsTimeOffsets.offsets;
    }

    return {
        sessions: state.sessions,
        viewingRouteData: state.playlist.data,
        measurements: uniqueMeasurements(state.userMeasurements),
        sketches: uniqueSketches(state.userSketches),
        annotations: uniqueAnnotations(state.userAnnotations),
        toolMode: state.markup.tool_mode,
        isVideo: state.playlist.position.isVideo,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        markupChanged: state.markup.hasUnsavedChange,
        offsets,
        use_snapped: state.snappedRoute || false,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(MarkupMarkers);
