import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Popup } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { customAudit, routeSelected, SESSION_FILTERS_DEFAULTS } from "../../redux/actions/index";
import RouteCoordinateSystems from "../util/RouteCoordinateSystems";
import memoize from "memoize-one";
import { Tooltip } from "antd";
import ELRMileAndChain from "../util/ELRMileAndChain";
import OBCSpinner from "components/util/OBC";

class RouteCoordinatePopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentCoordinateIndex: 0,
            videoButtonFocused: false,
        };

        this.openVideoButton = React.createRef();
    }

    componentDidMount() {
        if (this.openVideoButton.current) {
            this.openVideoButton.current.focus();
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        e.stopPropagation();
        if (e.keyCode === 37 && this.state.currentCoordinateIndex > 0) {
            this.prevCoordinate();
        }

        if (e.keyCode === 39 && this.state.currentCoordinateIndex < this.props.coordinates.length - 1) {
            this.nextCoordinate();
        }
    };

    componentDidUpdate() {
        if (this.openVideoButton.current) {
            this.openVideoButton.current.focus();
        }
    }

    prevCoordinate = () => {
        const coordinates = this.props.coordinates;
        if (coordinates && coordinates.length > 0 && this.state.currentCoordinateIndex > 0) {
            const newIndex = this.state.currentCoordinateIndex - 1;
            this.setState({
                currentCoordinateIndex: newIndex,
            });
        }
    };

    nextCoordinate = () => {
        const coordinates = this.props.coordinates;
        if (coordinates && this.state.currentCoordinateIndex < coordinates.length - 1) {
            const newIndex = this.state.currentCoordinateIndex + 1;
            this.setState({
                currentCoordinateIndex: newIndex,
            });
        }
    };

    openStillImage = () => {
        const video = _.get(this.props.coordinates, [this.state.currentCoordinateIndex, "video"], null);
        if (!video) {
            return;
        }
        this.props.dispatch(
            customAudit(
                "find_popup_view_stills",
                {
                    sessionID: video.session_id,
                    videoKey: video.video_key,
                },
                `Find location popup view stills selected - Session #${video.session_id}`,
            ),
        );
        this.props.dispatch(routeSelected(video.session_id, video.video_key, undefined, true, video.stream_index, video.video_frame));
    };

    openVideo = () => {
        const video = _.get(this.props.coordinates, [this.state.currentCoordinateIndex, "video"], null);
        if (!video) {
            return;
        }
        this.props.dispatch(
            customAudit(
                "find_popup_view_video",
                {
                    sessionID: video.session_id,
                    videoKey: video.video_key,
                },
                `Find location popup view video selected - Session #${video.session_id}`,
            ),
        );
        this.props.dispatch(routeSelected(video.session_id, video.video_key, undefined, false, video.stream_index, video.video_frame));
    };

    openRailInspection = () => {
        const video = _.get(this.props.coordinates, [this.state.currentCoordinateIndex, "video"], null);
        if (!video) {
            return;
        }
        this.props.dispatch(
            customAudit(
                "find_popup_view_rail_inspection",
                {
                    sessionID: video.session_id,
                    timestamp: video.timestamp / 1000,
                },
                `Find location popup view rail inspection selected - Session #${video.session_id}`,
            ),
        );
        this.props.dispatch(routeSelected(video.session_id, video.timestamp / 1000, undefined, true));
    };

    footageRequestLink = () => {
        const coordinate = this.props.coordinates[this.state.currentCoordinateIndex];

        let bodyString = "";
        if (coordinate.route && coordinate.system) {
            const position = new ELRMileAndChain(coordinate.system, coordinate.route, coordinate.position, coordinate.subposition);
            bodyString = `${position.to_string()}%0D%0AWorkspace Name - ${encodeURIComponent(this.props.currentDashboard.description)}`;
        } else {
            bodyString = `${coordinate.system} - (${coordinate.lat.toFixed(6)},${coordinate.lon.toFixed(6)})%0D%0AWorkspace Name - ${encodeURIComponent(
                this.props.currentDashboard.description,
            )}`;
        }
        const link = `mailto:support@onebigcircle.co.uk?subject=Coverage Enquiry&body=${bodyString}`;
        return link;
    };

    render() {
        const coordinate = this.props.coordinates[this.state.currentCoordinateIndex];

        if (!coordinate) {
            return null;
        }

        const hasPrevious = this.state.currentCoordinateIndex > 0;
        const hasNext = this.state.currentCoordinateIndex < this.props.coordinates.length - 1;

        const hasVideo = coordinate.video && coordinate.video.video_key;
        const hasInspection = coordinate.video && coordinate.video.video_key === null;

        let locationDescription = "Unknown";

        if (coordinate.system === "Latitude/Longitude") {
            locationDescription = "";
        } else {
            const system = _.find(RouteCoordinateSystems, (system) => coordinate.system === system.ID);
            if (system) {
                const route_coordinate = system.create(coordinate.route, coordinate.position, coordinate.subposition);
                locationDescription = route_coordinate.to_string(this.props.elrUnits, this.props.datum_offsets);
            }
        }

        let prevLocationTitle = "No previous results";
        let nextLocationTitle = "No further results";
        if (hasPrevious) {
            prevLocationTitle = "Previous result";
        }
        if (hasNext) {
            nextLocationTitle = "Next result";
        }

        return (
            <Popup
                position={[coordinate.lat, coordinate.lon]}
                keepInView={false}
                closeOnEscapeKey={false}
                closeOnClick={false}
                onClose={this.props.onClose}
                autoPan={false}
                className={"RouteLocationInfoPopup"}>
                <div className={"RouteLocationInfo"}>
                    <Tooltip
                        title={prevLocationTitle}
                        placement="top"
                        trigger="hover">
                        <FontAwesomeIcon
                            className={`PrevLocation ${!hasPrevious && "disabled"}`}
                            icon={faChevronLeft}
                            size="3x"
                            onClick={hasPrevious && this.prevCoordinate}
                        />
                    </Tooltip>
                    <div className={"RouteLocation"}>
                        <h4>
                            Search Results ({this.state.currentCoordinateIndex + 1} of {this.props.coordinates.length})
                        </h4>
                        <div className={"RouteLocationDescription"}>{locationDescription}</div>
                        <div className={"RouteLocationSystem"}>{coordinate.system}</div>
                        <div className={"RouteLocationOrigin"}>
                            {coordinate.lat.toFixed(6)},{coordinate.lon.toFixed(6)}
                        </div>
                        <div className={"RouteLocationButtonContainer"}>
                            {!this.props.sessionListRefreshing && !this.props.locationSearchLoading ? (
                                <>
                                    {hasInspection && (
                                        <div
                                            className={"RouteLocationButton"}
                                            onClick={this.openRailInspection}>
                                            Open Session
                                        </div>
                                    )}
                                    {hasVideo && (
                                        <button
                                            className={"RouteLocationButton"}
                                            onClick={this.openVideo}
                                            tabIndex="0"
                                            ref={(element) => {
                                                this.openVideoButton.current = element;
                                                this.setState({ videoButtonFocused: true });
                                            }}>
                                            Open Video
                                        </button>
                                    )}
                                    {hasVideo && (
                                        <button
                                            className={"RouteLocationButton"}
                                            onClick={this.openStillImage}
                                            tabIndex="0">
                                            Open Images
                                        </button>
                                    )}
                                    {!hasVideo && !hasInspection && (
                                        <div className={"RouteLocationLink"}>
                                            <span>No footage found</span>
                                            {!this.props.sessionListFiltersActive ? (
                                                <a
                                                    href={this.footageRequestLink()}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Click here to enquire about data collection options
                                                </a>
                                            ) : (
                                                <span>Please review your session list filters and try again.</span>
                                            )}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <OBCSpinner
                                    size={30}
                                    speed={3}
                                    color={"#e8dfff"}
                                />
                            )}
                        </div>
                    </div>
                    <Tooltip
                        title={nextLocationTitle}
                        placement="top"
                        trigger="hover">
                        <FontAwesomeIcon
                            className={`NextLocation ${!hasNext && "disabled"}`}
                            icon={faChevronRight}
                            size="3x"
                            onClick={hasNext && this.nextCoordinate}
                        />
                    </Tooltip>
                </div>
            </Popup>
        );
    }
}

const uniqueMeasurements = memoize((ms) =>
    _.uniqBy(ms, function (m) {
        return m.video_key;
    }),
);
const uniqueSketches = memoize((ss) =>
    _.uniqBy(ss, function (s) {
        return s.video_key;
    }),
);

const mapStateToProps = (state) => {
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(state.views, [state.userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(state.views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;
    const sessionListFiltersActive = !state.sessionListFilters.refreshing && !_.isEqual(state.sessionListFilters, SESSION_FILTERS_DEFAULTS);

    return {
        mapGeometry: state.mapGeometry,
        highlightedRouteID: state.highlightedRouteID,
        requestedBounds: state.sessionFilters.mapBounds.requested,
        viewingRouteData: state.playlist.data,
        measurements: uniqueMeasurements(state.userMeasurements),
        sketches: uniqueSketches(state.userSketches),
        elrUnits: _.get(state, ["userDetails", "userConfig", "elr_units"], "elr_mile_chain"),
        datum_offsets: datumOffsets,
        currentDashboard,
        sessionListFiltersActive,
        sessionListRefreshing: state.sessionListFilters.refreshing,
        locationSearchLoading: state.locationSearch.loading,
    };
};

export default connect(mapStateToProps)(RouteCoordinatePopup);
