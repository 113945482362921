import React from "react";
import { connect } from "react-redux";
import {
    clearCameraCalibrations,
    deleteCameraCalibration,
    persistCameraCalibration,
    persistGPSOffsets,
    requestPlaylistPosition,
} from "../../../../redux/actions/index";
import {
    drawCircle,
    drawLine,
    getCameraCharacteristics,
    getHeading,
    getNextRouteCoordinates,
    intersection,
    matrix,
    matrixMultiply,
    vector,
    vectorFromImageCoordinate,
    worldToImageCoordinates,
} from "../../../util/Geometry";
import Measure from "react-measure";
import _ from "lodash";
import { absoluteTimeLookup, get_source_calibration, getAbsoluteTimestamp, getStreamFOVs, interpolate } from "../../../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faSave,
    faUndo,
    faTrash,
    faDumpster,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faStepForward,
    faStepBackward,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { Modal, Select } from "antd";
import ThreeDFeatureOverlay from "../3d/3dFeatureOverlay";

class CalibrationOverlayV2 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mouseDown: false,
            horizon: [
                [-0.9, -0.35],
                [0.9, -0.35],
            ],
            vanishingPoint: [0, -0.3],
            parallels: [
                [-0.2, 0.8],
                [0.2, 0.8],
            ],
            centre: [0, 0.8],
            rail: [
                [-2, -2],
                [-2, -2],
            ],
            railSeparation: this.props.defaultRailSeparation,
            horizontalFov: 66.5,
            aspectRatio: 0.5625,
            rotationMatrix: [
                [1, 0, 0],
                [0, 1, 0],
                [0, 0, 1],
            ],
            translationVector: [0, 2, 0],
            selectedMarker: null,
            measureVertical: false,
            sessionIDOnEntry: null,
            sourceIndexOnEntry: null,
            changed: false,
            dimensions: { width: 0, height: 0 },
            fovLocked: true,
            aspectLocked: true,
            showRoute: true,
            autoSave: true,
            menuVisible: false,
        };

        this.horizonOne = React.createRef();
        this.horizonTwo = React.createRef();
        this.vanishingPoint = React.createRef();
        this.parallelOne = React.createRef();
        this.parallelTwo = React.createRef();
        this.railCentre = React.createRef();
        this.canvas = React.createRef();
    }

    changeRailSeparation = (value) => {
        let width = parseFloat(value);

        if (width !== undefined && !isNaN(width)) {
            this.setState(
                {
                    railSeparation: width,
                    changed: true,
                },
                this.updateCalculations,
            );
        }
    };

    changeDeviceFOV = (evt) => {
        if (!this.state.fovLocked) {
            let valueStr = evt.target.value;
            let fov = parseFloat(valueStr);

            if (fov !== undefined && !isNaN(fov)) {
                this.setState(
                    {
                        horizontalFov: fov,
                        changed: true,
                    },
                    this.updateCalculations,
                );
            }
        }
    };

    resetDeviceFov = () => {
        if (this.props.deviceFov === null) {
            this.setState(
                {
                    horizontalFov: 66.5,
                    changed: true,
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    horizontalFov: this.props.deviceFov,
                    fovLocked: true,
                    changed: true,
                },
                this.updateCalculations,
            );
        }
    };

    unlockDeviceFov = () => {
        this.setState({
            fovLocked: false,
        });
    };

    changeDeviceAspectRatio = (evt) => {
        if (!this.state.aspectLocked) {
            let valueStr = evt.target.value;
            let aspectRatio = parseFloat(valueStr);

            if (aspectRatio !== undefined && !isNaN(aspectRatio)) {
                this.setState(
                    {
                        aspectRatio: aspectRatio,
                        changed: true,
                    },
                    this.updateCalculations,
                );
            }
        }
    };

    resetDeviceAspectRatio = () => {
        if (this.props.deviceAspectRatio === null) {
            this.setState(
                {
                    aspectRatio: 0.5625,
                    changed: true,
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    aspectRatio: this.props.deviceAspectRatio,
                    aspectLocked: true,
                    changed: true,
                },
                this.updateCalculations,
            );
        }
    };

    unlockDeviceAspectRatio = () => {
        this.setState({
            aspectLocked: false,
        });
    };

    componentDidMount() {
        this.setState({
            sessionIDOnEntry: this.props.sessionID,
            sourceIndexOnEntry: this.props.sourceIndex,
            changed: false,
        });

        this.generateCalibrationHandles();
        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.calibration, this.props.calibration)) {
            this.generateCalibrationHandles();
        }
    }

    setAutoSave = (evt) => {
        this.setState({
            autoSave: evt.target.checked,
        });
    };

    setShowRoute = (evt) => {
        this.setState({
            showRoute: evt.target.checked,
        });
    };

    handleKeyDown = (evt) => {
        const callback = {
            ArrowLeft: this.previousCalibration,
            ArrowRight: this.nextCalibration,
            ArrowUp: this.next,
            ArrowDown: this.previous,
        }[evt.key];
        if (callback) {
            callback();
        }
    };

    generateCalibrationHandles() {
        if (this.props.calibration) {
            const calibration = _.cloneDeep(this.props.calibration);
            let fovLocked = false;
            let aspectLocked = false;

            if (!calibration.fovIsKnown) {
                if (this.props.deviceFov === null) {
                    calibration.horizontalFov = 66.5;
                } else {
                    fovLocked = true;
                    calibration.horizontalFov = this.props.deviceFov;
                }
            } else if (calibration.horizontalFov === this.props.deviceFov) {
                fovLocked = true;
            }

            if (!calibration.aspectIsKnown) {
                if (this.props.deviceAspectRatio === null) {
                    calibration.aspectRatio = 0.5625;
                } else {
                    aspectLocked = true;
                    calibration.aspectRatio = this.props.deviceAspectRatio;
                }
            } else if (calibration.aspectRatio === this.props.deviceAspectRatio) {
                aspectLocked = true;
            }

            console.log("New calibration: ", calibration);

            let distance = 1;
            let railP1, railP2;
            while (distance < 100) {
                railP1 = worldToImageCoordinates([-calibration.railSeparation / 2, 0, distance], calibration);
                railP2 = worldToImageCoordinates([calibration.railSeparation / 2, 0, distance], calibration);
                if (railP1[0] > -0.9 && railP1[0] < 0.9 && railP1[1] < 0.9 && railP2[0] > -0.9 && railP2[0] < 0.9 && railP2[1] < 0.8) {
                    break;
                } else {
                    distance *= 1.414;
                }
            }
            if (railP1[0] < -0.9 || railP1[0] > 0.9 || railP1[1] < -0.9 || railP1[1] > 0.9) {
                railP1 = [-0.5, 0.5];
            }
            if (railP2[0] < -0.9 || railP2[0] > 0.9 || railP2[1] < -0.9 || railP2[1] > 0.9) {
                railP2 = [0.5, 0.5];
            }

            const { pitch, yaw, roll } = getCameraCharacteristics(calibration);

            const tanHalfFov = Math.tan((Math.PI * calibration.horizontalFov) / 360);

            let vpxp = Math.tan(yaw) / tanHalfFov;
            let vpyp = Math.tan(pitch) / tanHalfFov;

            let vpx = vpxp * Math.cos(roll) - vpyp * Math.sin(roll);
            let vpy = vpyp * Math.cos(roll) + vpxp * Math.sin(roll);

            if (vpx < -0.9) {
                vpx = -0.9;
            } else if (vpx > 0.9) {
                vpx = 0.9;
            }
            if (vpy < -0.9) {
                vpy = -0.9;
            } else if (vpy > 0.9) {
                vpy = 0.9;
            }
            const vanishingPoint = [vpx, vpy / calibration.aspectRatio];

            let hyOffset = (Math.sin(roll) * 0.8) / calibration.aspectRatio;
            let hxOffset = Math.cos(roll) * 0.8;

            let h1 = [-hxOffset, -hyOffset - 0.5];
            let h2 = [hxOffset, hyOffset - 0.5];

            this.setState(
                {
                    railSeparation: calibration.railSeparation,
                    rotationMatrix: calibration.rotationMatrix,
                    translationVector: calibration.translationVector,
                    horizontalFov: calibration.horizontalFov,
                    aspectRatio: calibration.aspectRatio,
                    fovLocked,
                    aspectLocked,
                    horizon: [h1, h2],
                    vanishingPoint: vanishingPoint,
                    parallels: [railP1, railP2],
                    centre: interpolate(railP1, railP2, 0.5),
                    rail: [railP1, railP2],
                },
                this.updateCalculations,
            );
        } else {
            this.setState(
                {
                    aspectRatio: this.props.deviceAspectRatio === null ? 0.5625 : this.props.deviceAspectRatio,
                    aspectLocked: this.props.deviceAspectRatio !== null,
                    horizontalFov: this.props.deviceFov === null ? 66.5 : this.props.deviceFov,
                    fovLocked: this.props.deviceFov !== null,
                },
                this.updateCalculations,
            );
        }
    }

    confirmCalibration = () => {
        return new Promise((resolve) => {
            if (this.state.sessionIDOnEntry && this.props.gpsOffsetsChanged) {
                this.props.dispatch(persistGPSOffsets(this.state.sessionIDOnEntry, null));
            }
            if (this.state.sessionIDOnEntry && this.state.changed) {
                const deletableCalibrations = _.chain(this.props.calibrations)
                    .filter((c) => Math.abs(c.timestamp - this.props.timestamp) < 250)
                    .value();
                _.map(deletableCalibrations, "timestamp").forEach((ts) => {
                    console.log("Deleting calibration: ", ts);
                    this.props.dispatch(deleteCameraCalibration(this.state.sessionIDOnEntry, this.state.sourceIndexOnEntry, ts));
                });

                let calibration = {
                    source: this.state.sourceIndexOnEntry,
                    railSeparation: this.state.railSeparation,
                    rotationMatrix: this.state.rotationMatrix,
                    translationVector: this.state.translationVector,
                    horizontalFov: this.state.horizontalFov,
                    aspectRatio: this.state.aspectRatio,
                    timestamp: this.props.timestamp,
                };
                this.props.dispatch(persistCameraCalibration(this.state.sessionIDOnEntry, calibration));

                this.setState(
                    {
                        changed: false,
                    },
                    () => {
                        resolve();
                    },
                );
            } else {
                resolve();
            }
        });
    };

    deleteCalibration = () => {
        if (this.state.sessionIDOnEntry) {
            this.props.dispatch(deleteCameraCalibration(this.state.sessionIDOnEntry, this.state.sourceIndexOnEntry, this.props.timestamp));
            this.setState({
                changed: false,
            });
        }
    };

    clearCalibrations = () => {
        if (this.state.sessionIDOnEntry) {
            const self = this;
            Modal.confirm({
                title: "Clear all Calibrations",
                content: "Are you sure you wish to clear all calibrations for this session? This action cannot be undone.",
                onOk() {
                    self.props.dispatch(clearCameraCalibrations(self.state.sessionIDOnEntry, self.state.sourceIndexOnEntry));
                },
                onCancel() {
                    //do nothing
                },
                okText: "Clear All",
                okType: "danger",
                cancelText: "Cancel",
                getContainer: self.props.fullscreenComponent.current.fullscreenComponent.current,
            });
        }
    };

    verify = () => {
        const self = this;

        if (self.state.changed || this.props.gpsOffsetsChanged) {
            if (self.state.autoSave) {
                return self.confirmCalibration();
            } else {
                return new Promise((resolve, reject) => {
                    Modal.confirm({
                        title: "",
                        content: "Current calibration is unsaved. Continue anyway?",
                        onOk() {
                            resolve();
                        },
                        onCancel() {
                            reject();
                        },
                        okText: "Continue",
                        cancelText: "Cancel",
                        getContainer: self.props.fullscreenComponent.current.fullscreenComponent.current,
                    });
                });
            }
        } else {
            return Promise.resolve();
        }
    };

    toggleMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible,
        });
    };

    exitCalibration = () => {
        this.verify().then(() => {
            this.props.changeMode(4);
        });
    };

    mouseDown = (evt) => {
        let target = evt.target;

        const valid_targets = [
            this.horizonOne.current,
            this.horizonTwo.current,
            this.vanishingPoint.current,
            this.parallelOne.current,
            this.parallelTwo.current,
            this.railCentre.current,
        ];

        let target_index = valid_targets.indexOf(target);

        if (target_index !== -1) {
            this.setState({
                selectedMarker: target_index,
            });
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            this.setState({
                selectedMarker: null,
            });
        }
    };

    mouseMove = (evt) => {
        let currentTargetRect = this.canvas.current.getBoundingClientRect();
        let x = (evt.pageX - currentTargetRect.left) / currentTargetRect.width,
            y = (evt.pageY - currentTargetRect.top) / currentTargetRect.height;

        x = 2 * Math.max(0.05, Math.min(0.95, x)) - 1;
        y = 2 * Math.max(0.05, Math.min(0.95, y)) - 1;

        if (this.state.selectedMarker !== null) {
            evt.preventDefault();
            evt.stopPropagation();

            let newState = {};

            if (this.state.selectedMarker === 0) {
                newState = {
                    horizon: [[x, y], this.state.horizon[1]],
                };
            } else if (this.state.selectedMarker === 1) {
                newState = {
                    horizon: [this.state.horizon[0], [x, y]],
                };
            } else if (this.state.selectedMarker === 2) {
                newState = {
                    vanishingPoint: [x, y],
                };
            } else if (this.state.selectedMarker === 3) {
                newState = {
                    centre: interpolate([x, y], this.state.parallels[1], 0.5),
                    parallels: [[x, y], this.state.parallels[1]],
                };
            } else if (this.state.selectedMarker === 4) {
                newState = {
                    centre: interpolate(this.state.parallels[0], [x, y], 0.5),
                    parallels: [this.state.parallels[0], [x, y]],
                };
            } else if (this.state.selectedMarker === 5) {
                const xDiff = x - this.state.centre[0];
                const yDiff = y - this.state.centre[1];
                newState = {
                    centre: [x, y],
                    parallels: [
                        [this.state.parallels[0][0] + xDiff, this.state.parallels[0][1] + yDiff],
                        [this.state.parallels[1][0] + xDiff, this.state.parallels[1][1] + yDiff],
                    ],
                };
            }

            newState["changed"] = true;

            this.setState(newState, this.updateCalculations);
        }
    };

    mouseUp = () => {
        this.setState({
            selectedMarker: null,
        });
    };

    calibrationLines = () => {
        if (this.state.showRoute) {
            return null;
        } else {
            return (
                <>
                    {drawLine(this.state.vanishingPoint, this.state.parallels[0], "Perspective1", "Perspective")}
                    {drawLine(this.state.vanishingPoint, this.state.parallels[1], "Perspective2", "Perspective")}
                    {drawLine(this.state.rail[0], this.state.rail[1], "Rail", "Rail")}
                </>
            );
        }
    };

    getMeasuredContent = ({ measureRef }) => {
        return (
            <div
                ref={measureRef}
                className="MeasuredContainer">
                <svg
                    className={"CalibrationUI MeasurementOverlay" + (this.state.selectedMarker !== null ? " Dragging" : "") + " " + this.props.className}
                    onMouseDown={this.mouseDown}
                    onMouseMove={this.mouseMove}
                    onMouseUp={this.mouseUp}
                    onMouseLeave={this.mouseUp}
                    ref={this.canvas}
                    viewBox={`0 0 ${this.state.dimensions.width} ${this.state.dimensions.height}`}>
                    {drawLine(this.state.horizon[0], this.state.horizon[1], "Horizon", "Horizon")}
                    {this.calibrationLines()}
                    {drawCircle(this.state.horizon[0], 5, "HorizonMarker1", "HorizonMarker", this.horizonOne)}
                    {drawCircle(this.state.horizon[1], 5, "HorizonMarker2", "HorizonMarker", this.horizonTwo)}
                    {drawCircle(this.state.vanishingPoint, 5, "VanishingPointMarker", "VanishingPointMarker", this.vanishingPoint)}
                    {drawCircle(this.state.parallels[0], 5, "ParallelLineMarker1", "ParallelLineMarker", this.parallelOne)}
                    {drawCircle(this.state.parallels[1], 5, "ParallelLineMarker2", "ParallelLineMarker", this.parallelTwo)}
                    {drawCircle(this.state.centre, 5, "CentreLineMarker", "CentreLineMarker", this.railCentre)}
                </svg>
                <div className="ThreeDFeatureOverlayContainer">
                    <ThreeDFeatureOverlay
                        showRails={!!this.state.showRoute}
                        showGPSCorrection={!!this.state.showRoute}
                        calibration={{
                            source: this.state.sourceIndexOnEntry,
                            railSeparation: this.state.railSeparation,
                            rotationMatrix: this.state.rotationMatrix,
                            translationVector: this.state.translationVector,
                            horizontalFov: this.state.horizontalFov,
                            aspectRatio: this.state.aspectRatio,
                        }}
                    />
                </div>
                <div className={"CalibrationMenu " + (this.state.menuVisible ? "Visible" : "Hidden")}>
                    <div className="MenuItem">
                        <span className="Description">Track Gauge:</span>
                        <Select
                            size="small"
                            value={this.state.railSeparation}
                            style={{ width: 150 }}
                            onChange={this.changeRailSeparation}>
                            <Select.Option value={1.435}>1.435m {this.props.defaultRailSeparation === 1.435 && "(default)"}</Select.Option>
                            <Select.Option value={1.6}>1.6m {this.props.defaultRailSeparation === 1.6 && "(default)"}</Select.Option>
                        </Select>
                    </div>
                    <div className="MenuItem">
                        <span className="Description">Field of View:</span>
                        <input
                            type="number"
                            min={0.01}
                            max={180}
                            step={0.1}
                            className="Entry"
                            value={this.state.horizontalFov}
                            disabled={this.state.fovLocked}
                            onChange={this.changeDeviceFOV}
                        />
                        {this.state.fovLocked ? (
                            <button
                                type="button"
                                className="ResetButton"
                                onClick={this.unlockDeviceFov}>
                                Edit
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="ResetButton"
                                onClick={this.resetDeviceFov}>
                                Reset
                            </button>
                        )}
                    </div>
                    <div className="MenuItem">
                        <span className="Description">Aspect Ratio:</span>
                        <input
                            type="number"
                            min={0.01}
                            max={100}
                            step={0.0001}
                            className="Entry"
                            value={this.state.aspectRatio}
                            disabled={this.state.aspectLocked}
                            onChange={this.changeDeviceAspectRatio}
                        />
                        {this.state.aspectLocked ? (
                            <button
                                type="button"
                                className="ResetButton"
                                onClick={this.unlockDeviceAspectRatio}>
                                Edit
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="ResetButton"
                                onClick={this.resetDeviceAspectRatio}>
                                Reset
                            </button>
                        )}
                    </div>
                    <div className="MenuItem">
                        <label className="ToggleShowRoute">
                            <input
                                type="checkbox"
                                checked={this.state.showRoute}
                                onChange={this.setShowRoute}
                            />
                            <span className="Description"> Show Route</span>
                        </label>
                    </div>
                    <div className="MenuItem">
                        <label className="ToggleShowRoute">
                            <input
                                type="checkbox"
                                checked={this.state.autoSave}
                                onChange={this.setAutoSave}
                            />
                            <span className="Description"> Auto Save Calibrations</span>
                        </label>
                    </div>
                </div>
                <div className="CalibrationEntryPanel">
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Calibration Settings"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faBars}
                                onClick={this.toggleMenu}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Previous Calibration"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faStepBackward}
                                onClick={this.previousCalibration}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Step Back 2s"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faAngleDoubleLeft}
                                onClick={this.previous}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Step Forward 2s"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                onClick={this.next}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Next Calibration"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faStepForward}
                                onClick={this.nextCalibration}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Reset Current Calibration"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faUndo}
                                onClick={this.resetCalibration}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Save Current Calibration"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faSave}
                                onClick={this.confirmCalibration}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Delete Current Calibration"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={this.deleteCalibration}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Clear All Calibrations"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faDumpster}
                                onClick={this.clearCalibrations}
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        placement="top"
                        arrow={false}
                        theme="dark"
                        content={"Confirm Calibrations"}>
                        <div>
                            <FontAwesomeIcon
                                icon={faCheck}
                                onClick={this.exitCalibration}
                            />
                        </div>
                    </Tippy>
                </div>
            </div>
        );
    };

    previousCalibration = () => {
        let previousCalibration = _.reduce(
            this.props.calibrations,
            (best, c) => (c.timestamp < this.props.timestamp && (best === null || c.timestamp > best.timestamp) ? c : best),
            null,
        );
        if (previousCalibration !== null) {
            console.log("Found calibration before ", this.props.timestamp, ": ", previousCalibration.timestamp);
            this.goToTimestamp(previousCalibration.timestamp);
        }
    };

    nextCalibration = () => {
        let nextCalibration = _.reduce(
            this.props.calibrations,
            (best, c) => (c.timestamp > this.props.timestamp && (best === null || c.timestamp < best.timestamp) ? c : best),
            null,
        );
        if (nextCalibration !== null) {
            console.log("Found calibration after ", this.props.timestamp, ": ", nextCalibration.timestamp);
            this.goToTimestamp(nextCalibration.timestamp);
        }
    };

    previous = () => {
        this.goToTimestamp(this.props.timestamp - 2000);
    };

    next = () => {
        this.goToTimestamp(this.props.timestamp + 2000);
    };

    goToTimestamp = (ts) => {
        const videoIndex = absoluteTimeLookup(ts / 1000, this.props.video);
        if (videoIndex !== -1) {
            let targetKey = this.props.video[videoIndex];
            if (targetKey) {
                const newAbsoluteTimestamp = targetKey[3][2];
                const timeOffset = ts / 1000 - newAbsoluteTimestamp;
                this.verify().then(() => {
                    console.log("Jumping to timestamp: ", ts, ", which is video index ", videoIndex, " and time offset ", timeOffset);
                    this.props.dispatch(
                        requestPlaylistPosition(true, this.props.isEnhanced, this.props.isStills, this.props.sourceIndex, videoIndex, timeOffset),
                    );
                });
            }
        }
    };

    resetCalibration = () => {
        this.setState({
            horizon: [
                [-0.8, -0.5],
                [0.8, -0.5],
            ],
            aspectRatio: this.props.deviceAspectRatio === null ? 0.5625 : this.props.deviceAspectRatio,
            aspectLocked: this.props.deviceAspectRatio !== null,
            horizontalFov: this.props.deviceFov === null ? 66.5 : this.props.deviceFov,
            fovLocked: this.props.deviceFov !== null,
            parallels: [
                [-0.13692018362663982, 0.6785049749984474],
                [0.13692018362663982, 0.6785049749984474],
            ],
            centre: [0, 0.6785049749984474],
            rail: [
                [-0.13692018362663982, 0.6785049749984474],
                [0.13692018362663982, 0.6785049749984474],
            ],
            railSeparation: this.props.defaultRailSeparation,
            rotationMatrix: [
                [1, 0, 0],
                [0, 1, 0],
                [0, 0, 1],
            ],
            translationVector: [0, 2, 0],
            vanishingPoint: [0, 0],
        });
    };

    render() {
        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.setState({ dimensions: contentRect.bounds });
                }}>
                {this.getMeasuredContent}
            </Measure>
        );
    }

    calculateRotationMatrix = (pitch, roll, yaw) => {
        let rollMatrix = [
            [Math.cos(roll), -Math.sin(roll), 0],
            [Math.sin(roll), Math.cos(roll), 0],
            [0, 0, 1],
        ];
        let pitchMatrix = [
            [1, 0, 0],
            [0, Math.cos(pitch), Math.sin(pitch)],
            [0, -Math.sin(pitch), Math.cos(pitch)],
        ];
        let yawMatrix = [
            [Math.cos(yaw), 0, -Math.sin(yaw)],
            [0, 1, 0],
            [Math.sin(yaw), 0, Math.cos(yaw)],
        ];
        return matrixMultiply(rollMatrix, matrixMultiply(yawMatrix, pitchMatrix));
    };

    updateCalculations = () => {
        let hfov = this.state.horizontalFov;
        let aspectRatio = this.state.aspectRatio;
        if (!hfov || !aspectRatio) {
            return;
        }

        const vpxp = this.state.vanishingPoint[0];
        const vpyp = this.state.vanishingPoint[1] * aspectRatio;

        const tanHalfFov = Math.tan((Math.PI * hfov) / 360);

        let horizonX = this.state.horizon[1][0] - this.state.horizon[0][0];
        let horizonY = (this.state.horizon[1][1] - this.state.horizon[0][1]) * aspectRatio;
        if (horizonX < 0) {
            horizonX *= -1;
            horizonY *= -1;
        }

        const roll = Math.atan2(horizonY, horizonX);

        const cosRoll = Math.cos(roll);
        const sinRoll = Math.sin(roll);
        const vpx = vpxp * cosRoll + vpyp * sinRoll;
        const vpy = vpyp * cosRoll - vpxp * sinRoll;

        const yaw = Math.atan(vpx * tanHalfFov);
        const pitch = Math.atan(vpy * tanHalfFov);

        let rotationMatrix = this.calculateRotationMatrix(pitch, roll, yaw);

        //rail 1 point 1
        let rail1 = [this.state.parallels[0][0], this.state.parallels[0][1] * aspectRatio];

        let rail2 = intersection(
            [rail1, [rail1[0] + horizonX, rail1[1] + horizonY]],
            [
                [this.state.vanishingPoint[0], this.state.vanishingPoint[1] * aspectRatio],
                [this.state.parallels[1][0], this.state.parallels[1][1] * aspectRatio],
            ],
        );
        const newState = {};

        let translationVector = this.calculateTranslationVector(rail1, rail2, tanHalfFov, rotationMatrix);

        if (translationVector[1] > 0) {
            newState.rotationMatrix = rotationMatrix;
            newState.translationVector = translationVector;
        }

        let distance = 1;
        let railP1, railP2;
        let calibration = {
            ...this.state,
            rotationMatrix,
            translationVector,
        };

        getCameraCharacteristics(calibration);

        while (distance < 100) {
            railP1 = worldToImageCoordinates([-this.state.railSeparation / 2, 0, distance], calibration);
            railP2 = worldToImageCoordinates([this.state.railSeparation / 2, 0, distance], calibration);
            if (railP1[0] > -0.9 && railP1[0] < 0.9 && railP1[1] < 0.8 && railP2[0] > -0.9 && railP2[0] < 0.9 && railP2[1] < 0.8) {
                break;
            } else {
                distance *= 2;
            }
        }
        this.setState({
            ...newState,
            rail: [railP1, railP2],
        });
    };

    calculateTranslationVector(rail1, rail2, tanHalfFov, rotationMatrix) {
        let rail1CameraRelative = vectorFromImageCoordinate(rail1[0], rail1[1], tanHalfFov);
        let rail2CameraRelative = vectorFromImageCoordinate(rail2[0], rail2[1], tanHalfFov);

        let rail1World = vector(matrixMultiply(matrix(rail1CameraRelative, 1, 3), rotationMatrix));
        let rail2World = vector(matrixMultiply(matrix(rail2CameraRelative, 1, 3), rotationMatrix));

        let r1p1ThetaXZ = Math.atan2(rail1World[0], rail1World[2]);
        let r2p1ThetaXZ = Math.atan2(rail2World[0], rail2World[2]);

        let rp1AngleXZ = r2p1ThetaXZ - r1p1ThetaXZ;

        let sinA = Math.sin(rp1AngleXZ);
        let sinB = Math.sin(Math.PI / 2 - r1p1ThetaXZ);
        let sinC = Math.sin(Math.PI / 2 - r2p1ThetaXZ);

        let rail1XZHyp = (this.state.railSeparation / sinA) * sinB;
        let rail2XZHyp = (this.state.railSeparation / sinA) * sinC;
        let cameraP1ZOffset = (Math.cos(r1p1ThetaXZ) * rail1XZHyp + Math.cos(r2p1ThetaXZ) * rail2XZHyp) / 2;

        let r1X = -0.5 * this.state.railSeparation;
        let r2X = 0.5 * this.state.railSeparation;

        let r1XZRatio = rail1World[0] / rail1World[2];
        let r2XZRatio = rail2World[0] / rail2World[2];

        let r1YZRatio = rail1World[1] / rail1World[2];
        let r2YZRatio = rail2World[1] / rail2World[2];

        let cameraX = -(r1X + r1XZRatio * cameraP1ZOffset + r2X + r2XZRatio * cameraP1ZOffset) / 2;
        let cameraY = -(r1YZRatio * cameraP1ZOffset + r2YZRatio * cameraP1ZOffset) / 2;
        let translationVector = [cameraX, cameraY, 0];
        return translationVector;
    }
}

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex || 0;

    const session = _.get(state.sessions, state.playlist.data.routeID, false);
    let fovsForIndex = getStreamFOVs(state.playlist, state.routeMetadata, session, sourceIndex);

    let deviceFov = null;
    let deviceAspectRatio = null;

    if (fovsForIndex.length > 0) {
        deviceFov = Math.max(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
        let vFov = Math.min(fovsForIndex[0].data.x, fovsForIndex[0].data.y);
        deviceAspectRatio = Math.tan((Math.PI * vFov) / 360) / Math.tan((Math.PI * deviceFov) / 360);
    }

    let offsets = [];
    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, sourceIndex, []);
    }

    let calibrationsForSource = _.filter(state.measurement.calibration, (c) => c.source === state.playlist.position.sourceIndex);

    const currentSession = _.get(state.sessions, [state.playlist.data.routeID], []);
    const sessionTags = _.get(currentSession, ["tags"], []);
    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;
    const timestamp = Math.round(getAbsoluteTimestamp(state.playlist) * 1000);

    const gpsOffsetsChanged = state.gpsTimeOffsets.offsets !== state.gpsTimeOffsets.originalOffsets;
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }

    return {
        calibrations: calibrationsForSource,
        calibration: get_source_calibration(state.measurement.calibration, state.playlist.position.sourceIndex, timestamp, true, defaultRailSeparation),
        deviceAspectRatio: deviceAspectRatio === null ? null : Math.round(deviceAspectRatio * 10000) / 10000,
        deviceFov: deviceFov === null ? null : Math.round(deviceFov * 10) / 10,
        sessionID: state.playlist.data.routeID,
        sourceIndex,
        currentPlaylistIndex: state.playlist.position.currentIndex,
        sessionIsBackward,
        video: _.get(state.playlist.data, ["video", sourceIndex]),
        timeOffset: state.playlist.position.currentTimeOffset || 0,
        offsets,
        timestamp: timestamp,
        isStills: state.playlist.position.isStills,
        isEnhanced: state.playlist.position.isEnhanced,
        gpsOffsetsChanged,
        defaultRailSeparation,
    };
};

export default connect(mapStateToProps)(CalibrationOverlayV2);
