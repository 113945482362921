import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker, LayerGroup } from "react-leaflet";
import { createCustomIcon } from "./Icons";
import { selectMarker, routeSelected } from "redux/actions/index";
import { LazyMapTooltip } from "components/util/LazyTooltips";
import { MEMOIZED_DOMAIN_URL } from "../util/HostUtils";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { filterSessionObservations } from "../util/PlaylistUtils";
import _ from "lodash";
import { RawImageComponent } from "components/RawImageComponent";

const sessionObservationsSelector = (state) => state.sessionObservations;
const selectedMarkerClassSelector = (state) => state.markers.selectedMarker?.class || null;
const markerReviewFiltersSelector = (state) => state.markerReviewFilters;
const sessionIdSelector = (state) => state.playlist.data.routeID;
const sessionsSelector = (state) => state.sessions;
const csrfTokenSelector = (state) => state.csrfToken;
const observationFiltersSelector = (state) => state.observationFilters;

const ObservationMarkers = () => {
    const dispatch = useDispatch();

    const sessionObservations = useSelector(sessionObservationsSelector);
    const selectedMarkerClass = useSelector(selectedMarkerClassSelector);
    const markerReviewFilters = useSelector(markerReviewFiltersSelector);
    const sessionID = useSelector(sessionIdSelector);
    const sessions = useSelector(sessionsSelector);
    const csrfToken = useSelector(csrfTokenSelector);
    const observationFilters = useSelector(observationFiltersSelector);

    const filteredObservations = useMemo(() => {
        if (!selectedMarkerClass) {
            return [];
        }
        return _.filter(sessionObservations, (obs) => obs.class === selectedMarkerClass && markerReviewFilters.includes(obs.review_status));
    }, [markerReviewFilters, selectedMarkerClass, sessionObservations]);

    const selectObservation = useCallback(
        (id) => {
            const selectedObservation = _.find(filteredObservations, { id: id });

            dispatch(selectMarker(selectedObservation, true));
            dispatch(routeSelected(sessionID, selectedObservation.video_key, undefined, undefined, undefined, selectedObservation.frame));
        },
        [dispatch, filteredObservations, sessionID],
    );

    const createTooltip = useCallback(
        (observation) => {
            let session = sessions[sessionID];
            if (observation.video_key && session) {
                let enhancedImgSrc = `https://raw${MEMOIZED_DOMAIN_URL}/${session.device_uuid}/${session.uuid}/${observation.video_key}-${observation.frame}.jpg`;
                if (csrfToken) {
                    enhancedImgSrc += `?csrf=${csrfToken}`;
                }
                let image = null;
                if (observation.frame >= 0) {
                    image = (
                        <RawImageComponent
                            src={enhancedImgSrc}
                            className="MarkerToolTipImage"
                            alt="Observation Thumbnail"
                            crossOrigin={"anonymous"}
                        />
                    );
                }
                return (
                    <div className={"MarkerTooltipOuter"}>
                        {_.get(observation, "class", "") ? <div>{_.get(observation, "class", "")}</div> : null}
                        {image}
                    </div>
                );
            }
            return null;
        },
        [csrfToken, sessionID, sessions],
    );

    const renderObservationMarkers = useMemo(() => {
        if (filteredObservations.length < 1) {
            return null;
        }

        let icon = _.get(filteredObservations[0], "icon_type");
        let iconColor = _.get(filteredObservations[0], "icon_colour");
        let markerIcon = createCustomIcon(icon, iconColor, false);

        // in case markerIcon is not being rendered display default (exclamation-triangle)
        if (!_.get(markerIcon, ["options", "html"], "").length) {
            markerIcon = createCustomIcon("exclamation-triangle", null, false);
        }

        let uniqueObservations = filterSessionObservations(filteredObservations, observationFilters);
        uniqueObservations = _.uniqBy(_.sortBy(uniqueObservations, ["observation_number", "id"]), "observation_number");

        return uniqueObservations.map((obs) => {
            const tooltip = createTooltip(obs);

            return (
                <Marker
                    key={obs.id}
                    position={[obs.coords[1], obs.coords[0]]}
                    icon={markerIcon}
                    onClick={() => selectObservation(obs.id)}>
                    {tooltip && (
                        <LazyMapTooltip
                            className="Incident-Tooltip"
                            permanent={false}
                            interactive={true}
                            direction={"top"}
                            offset={[0, -30]}>
                            {tooltip}
                        </LazyMapTooltip>
                    )}
                </Marker>
            );
        });
    }, [createTooltip, filteredObservations, observationFilters, selectObservation]);

    return (
        <LayerGroup>
            <MarkerClusterGroup
                maxClusterRadius={32}
                showCoverageOnHover={false}
                animate={true}
                zoomToBoundsOnClick={true}>
                {renderObservationMarkers}
            </MarkerClusterGroup>
        </LayerGroup>
    );
};

export default ObservationMarkers;
