import React, { useState, useEffect, useMemo, useRef } from "react";
import OBCSearchInput from "components/OBC/OBCSearchInput";
import OBCProgressBar from "components/OBC/OBCProgressBar";
import OBCButton from "components/OBC/OBCButton";
import { Modal, Badge } from "antd";
import _ from "lodash";
import moment from "moment";
import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import OBCSpinner from "components/util/OBC";
import Highlighter from "react-highlight-words";
import {
    getAllDriversData,
    getDriverTrainingData,
    getDriverTrainingCategoryData,
    getAvailableTrainingCategories,
    removeTrainingForDriver,
} from "redux/actions/driverTrainingActions";
import { useSelector, useDispatch } from "react-redux";
import AddNewTrainingModal from "./AddNewTrainingModal";
import RemoveTrainingModal from "./RemoveTrainingModal";
import { MenuOutlined } from "@ant-design/icons";

const driversListSelector = (state) => state.driverTraining.admin.drviersList;
const trainingDataSelector = (state) => state.driverTraining.admin.drvierTrainingData;
const selectedCategoryDataSelector = (state) => state.driverTraining.admin.selectedCategoryData;

const DriversTab = () => {
    const dispatch = useDispatch();

    const reqAbortRef = useRef();

    const [driverMenuOpen, setDriverMenuOpen] = useState(false);
    const [driverListSearchQuery, setDriverListSearchQuery] = useState("");
    const [trainingSearchQuery, setTrainingSearchQuery] = useState("");

    const [fetchingDriverData, setFetchingDriversData] = useState(true);
    const [fetchingCategoryData, setFetchingCategoryData] = useState(false);
    // const [driversList, setDriversList] = useState([])

    const driversList = useSelector(driversListSelector);
    const trainingData = useSelector(trainingDataSelector);
    const selectedCategoryData = useSelector(selectedCategoryDataSelector);

    const [selectedDriverISeatID, setselectedDriverISeatID] = useState(null);
    const [selectedCategoryID, setselectedCategoryID] = useState(null);
    const [driverData, setDriverData] = useState();
    const [driverDataLoading, setDriverDataLoading] = useState(false);

    // Remove training modal
    const [removeTrainingModalOpen, setRemoveTrainingModalOpen] = useState(false);

    // Add new training data
    const [addTrainingModalOpen, setAddTrainingModalOpen] = useState(false);

    useEffect(() => {
        dispatch(
            getAllDriversData((response) => {
                setFetchingDriversData(false);
            }),
        );
        dispatch(getAvailableTrainingCategories());
    }, []);

    useEffect(() => {
        console.log("debug selectedCategoryData", selectedCategoryData);
    }, [selectedCategoryData]);

    const driversFilteredList = useMemo(() => {
        const newList = _.filter(driversList, (driver) => {
            console.log("debug driver", driver);
            if (driver.user_type !== "user") {
                return false;
            }

            if (driver.name.toLowerCase().indexOf(driverListSearchQuery.toLowerCase()) === -1) {
                return false;
            } else {
                return true;
            }
        });
        return newList;
    }, [driverListSearchQuery, driversList]);

    const categoryFilteredList = useMemo(() => {
        const newList = _.filter(trainingData, (category) => {
            if (category.category_name.toLowerCase().indexOf(trainingSearchQuery.toLowerCase()) === -1) {
                return false;
            }
            return true;
        });
        return newList;
    }, [trainingData, trainingSearchQuery]);

    // Add training functions
    const onAddNewTrainingClicked = () => {
        setAddTrainingModalOpen(true);
    };

    // Remove training functions
    const onRemoveTrainingClicked = (category_id) => {
        console.log("debug onRemoveNewTrainingClicked", category_id);

        setRemoveTrainingModalOpen(true);
    };

    const abortDriverTrainingRequest = () => {
        if (reqAbortRef.current) {
            reqAbortRef.current.abort("user_abort");
            reqAbortRef.current = null;
        }
    };

    const onDriverSelected = (driver) => {
        if (!selectedDriverISeatID || selectedDriverISeatID !== driver.user_seat_id) {
            setDriverDataLoading(true);
            setselectedDriverISeatID(driver.user_seat_id);
            setselectedCategoryID(null);
            setDriverData(driver);
            setFetchingCategoryData(true);

            dispatch(
                getDriverTrainingData(driver.user_seat_id, (response) => {
                    setDriverDataLoading(false);
                    const firstCategory = response[Object.keys(response)[0]];

                    console.log("debug firstCategory", firstCategory);
                    console.log("debug response", response);

                    if (firstCategory) {
                        selectTrainingCategory(firstCategory.sessions, firstCategory.category_id, driver);
                    } else {
                        setFetchingCategoryData(false);
                    }
                }),
            );
        }
    };

    const selectTrainingCategory = (sessionsIDs, categoryID, driver) => {
        abortDriverTrainingRequest();
        const abortController = new AbortController();
        reqAbortRef.current = abortController;

        setselectedCategoryID(categoryID);
        setFetchingCategoryData(true);
        dispatch(
            getDriverTrainingCategoryData(
                driver.user_seat_id,
                sessionsIDs,
                categoryID,
                () => {
                    reqAbortRef.current = null;
                    setFetchingCategoryData(false);
                },
                abortController.signal,
            ),
        );
    };

    const calculateTimeFromDate = (timestamp) => {
        const lastUpdate = moment.unix(timestamp);
        return lastUpdate.fromNow();
    };

    const calculateDaysFromNow = (timestamp) => {
        const fromDate = moment();
        const toDate = moment.unix(timestamp);
        return fromDate.diff(toDate, "days");
    };

    const renderBadgeLabel = (isRecentlyAdded, daysFromLastUpdate, progress) => {
        console.log("debug renderBadgeLabel", isRecentlyAdded, daysFromLastUpdate, progress);
        if (progress === 1) {
            return "Completed";
        }
        if (isRecentlyAdded) {
            return "New";
        }
        if (daysFromLastUpdate !== null && daysFromLastUpdate < 7) {
            return "Updated";
        }
        return null;
    };

    const getTraningCategoryStatus = (isRecentlyAdded, daysFromLastUpdate, progress) => {
        if (progress === 1) {
            return "completed";
        }
        if (isRecentlyAdded) {
            return "new";
        }
        if (daysFromLastUpdate !== null && daysFromLastUpdate < 7) {
            return "updated";
        }
        return null;
    };

    const selectedTrainingData = useMemo(() => {
        let selectedCategory = null;
        if (selectedCategoryID && trainingData) {
            selectedCategory = _.get(trainingData, selectedCategoryID);
        }
        // console.log("debug2 selectedCategory", selectedCategory);
        return selectedCategory;
    }, [selectedCategoryID, trainingData]);

    if (fetchingDriverData) {
        return (
            <div className="DriversTab">
                <div className="LoadingDriverData">
                    <OBCSpinner
                        size={125}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            {/* Add new training to the driver Modal */}
            <AddNewTrainingModal
                addTrainingModalOpen={addTrainingModalOpen}
                setAddTrainingModalOpen={setAddTrainingModalOpen}
                driverData={driverData}
            />

            {/* Remove training from the user Modal */}
            <RemoveTrainingModal
                removeTrainingModalOpen={removeTrainingModalOpen}
                setRemoveTrainingModalOpen={setRemoveTrainingModalOpen}
                trainingData={trainingData}
                selectedCategoryID={selectedCategoryID}
                driverData={driverData}
            />

            <div className="DriversTab">
                {driversList.length > 0 && (
                    <div className="TabMainLeftCol">
                        <OBCSearchInput
                            value={driverListSearchQuery}
                            onChange={setDriverListSearchQuery}
                            placeholder="Search for driver"
                        />
                        <div className="DriversList">
                            {driversFilteredList.length > 0
                                ? _.map(driversFilteredList, (driver) => {
                                      const lastUpdate = moment(driver.last_update);
                                      // console.log('debug2 driverData', driverData);
                                      // console.log('debug2 driver', driver);

                                      return (
                                          <div
                                              key={driver.user_seat_id}
                                              className={`DriverListItem ${selectedDriverISeatID === driver.user_seat_id ? "active" : ""}`}
                                              onClick={() => onDriverSelected(driver)}>
                                              <span className="Title">
                                                  <Highlighter
                                                      highlightClassName="ObcTextHighlight"
                                                      searchWords={driverListSearchQuery.split(" ")}
                                                      autoEscape={true}
                                                      textToHighlight={driver.name}
                                                  />
                                              </span>
                                              <span className="SubTitle">
                                                  Last activity: {driver.last_update ? calculateTimeFromDate(driver.last_update) : "No activity yet"}
                                              </span>
                                              <OBCProgressBar
                                                  className="DriverListProgressBar"
                                                  lableVisible={false}
                                                  progress={driver.progress}
                                              />
                                          </div>
                                      );
                                  })
                                : driversList.length > 0 &&
                                  driversFilteredList.length === 0 && (
                                      <div className="DriversListFeedbackContainer">
                                          <h2>No match found</h2>
                                      </div>
                                  )}
                        </div>
                    </div>
                )}
                <div className="TabMainRightCol">
                    {selectedDriverISeatID && !driverDataLoading && (
                        <>
                            <div className="DriverPageTopRow">
                                <span className="DriverName">{driverData && driverData.name}</span>
                                <div className="DriverPageRightColumn">
                                    <OBCButton
                                        icon={faPlus}
                                        variant="success"
                                        onClick={onAddNewTrainingClicked}>
                                        Add New Training
                                    </OBCButton>
                                </div>
                                {/* <Tooltip
                                className='DriverMenuPopover2'
                                placement="bottomRight"
                                onVisibleChange={setDriverMenuOpen}
                                visible={driverMenuOpen}
                                arrowPointAtCenter={true}
                                title={<div className="DriverMenuPopover">
                                    <div className="MenuItem" onClick={() => onAddNewTrainingClicked()}>Add New Training</div>
                                </div>}
                                trigger="click">
                                <div>
                                    <MenuOutlined className="DriverMenuButton"/>
                                    </div>
                                </Tooltip> */}
                            </div>
                            <div className="DriverPageBottomRow">
                                {!!Object.keys(trainingData).length && (
                                    <div className="DriverPageLeftCol">
                                        <div className="TrainingSearchInput">
                                            <OBCSearchInput
                                                value={trainingSearchQuery}
                                                disabled={!(Object.keys(trainingData).length > 0)}
                                                onChange={setTrainingSearchQuery}
                                                placeholder="Search for training"
                                            />
                                        </div>
                                        <div className="DriverTrainingList">
                                            {!Object.keys(categoryFilteredList).length && trainingSearchQuery.length ? (
                                                <div className="TrainingListEpmtyContainer">
                                                    <h2>No match found</h2>
                                                </div>
                                            ) : null}
                                            {Object.keys(trainingData).length > 0 &&
                                                _.map(categoryFilteredList, (training) => {
                                                    console.log("debug training", training);
                                                    const daysFromLastUpdate = training.category_last_update_ts
                                                        ? calculateDaysFromNow(training.category_last_update_ts)
                                                        : null;
                                                    const isRecentlyAdded = calculateDaysFromNow(training.category_created_ts) < 7;

                                                    return (
                                                        <div
                                                            key={training.category_id}
                                                            className={`DriverTrainingListItem ${selectedCategoryID === training.category_id && "active"}`}
                                                            onClick={() => selectTrainingCategory(training.sessions, training.category_id, driverData)}>
                                                            {getTraningCategoryStatus(isRecentlyAdded, daysFromLastUpdate, training.progress) && (
                                                                <div
                                                                    className={`Badge Badge-${renderBadgeLabel(isRecentlyAdded, daysFromLastUpdate, training.progress)}`}>
                                                                    {renderBadgeLabel(isRecentlyAdded, daysFromLastUpdate, training.progress)}
                                                                </div>
                                                            )}
                                                            <div className="CategoryName">
                                                                <Highlighter
                                                                    highlightClassName="ObcTextHighlight"
                                                                    searchWords={trainingSearchQuery.split(" ")}
                                                                    autoEscape={true}
                                                                    textToHighlight={training.category_name}
                                                                />
                                                            </div>
                                                            <div className="LastUpdate">Assigned: {calculateTimeFromDate(training.category_created_ts)}</div>
                                                            <div className="LastUpdate">
                                                                <span>Updated: </span>
                                                                <span
                                                                    className={
                                                                        getTraningCategoryStatus(isRecentlyAdded, daysFromLastUpdate, training.progress) ===
                                                                            "updated" && "Updated"
                                                                    }>
                                                                    {training.category_last_update_ts
                                                                        ? calculateTimeFromDate(training.category_last_update_ts)
                                                                        : "N/a"}
                                                                </span>
                                                            </div>
                                                            <div className="LastUpdate">
                                                                Last activity:{" "}
                                                                {training.training_last_update_ts
                                                                    ? calculateTimeFromDate(training.training_last_update_ts)
                                                                    : "No activity yet"}
                                                            </div>
                                                            <div className="Status">Status: Awaiting Completion</div>
                                                            <OBCProgressBar
                                                                className="DriverListProgressBar"
                                                                lableVisible={false}
                                                                progress={training.progress}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )}

                                <div className="DriverPageRightCol">
                                    {!Object.keys(trainingData).length ? (
                                        <div className="TrainingListEpmtyContainer">
                                            <h2>No training added yet</h2>
                                            <span>
                                                Click on the button below to add training for <b>{driverData["name"]}</b>
                                            </span>
                                            <OBCButton
                                                icon={faPlus}
                                                onClick={onAddNewTrainingClicked}>
                                                Add Training
                                            </OBCButton>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="TrainingTopRow">
                                                <div className="TrainingTopRowLeftCol">
                                                    <div className="CategoryTitle">
                                                        {trainingData[selectedCategoryID] && trainingData[selectedCategoryID].category_name}
                                                    </div>
                                                </div>
                                                <div className="TrainingTopRowRightCol">
                                                    <OBCButton
                                                        icon={faTrash}
                                                        variant="danger"
                                                        disabled={trainingData && !trainingData[selectedCategoryID]}
                                                        onClick={() => onRemoveTrainingClicked(selectedCategoryData.category_id)}>
                                                        Remove
                                                    </OBCButton>
                                                </div>
                                            </div>
                                            <div className="TrainingDetailsContainer">
                                                {selectedTrainingData && (
                                                    <>
                                                        <div className="DetailLabel Assigned">
                                                            Assigned date:{" "}
                                                            <span>{moment.unix(selectedTrainingData.category_created_ts).format("Do MMM YYYY")}</span>
                                                        </div>
                                                        <div className="DetailLabel DaysLeft">
                                                            To be completed by:{" "}
                                                            <span>{moment.unix(selectedTrainingData.category_required_by_ts).format("Do MMM YYYY")}</span>
                                                        </div>
                                                        <div className="DetailLabel TimeSpent">
                                                            Time spent in training: <span>3 hours 20 min</span>
                                                        </div>
                                                        <div className="DetailLabel TotalSessionsTime">
                                                            Total time of all sessions: <span>5 hours 20 min</span>
                                                        </div>
                                                        <div className="DetailLabel LastCategoryUpdateTime">
                                                            Training category updated: <span>20 days ago</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="TrainingListFilter">
                                                <div className="Filter">fitler 1</div>
                                                <div className="Filter">fitler 2</div>
                                                <div className="Filter">fitler 3</div>
                                            </div>
                                            <div className="MainCategorySessionList">
                                                {fetchingCategoryData ? (
                                                    <div className="DriverPageRightCol">
                                                        <div className="CategorySpinner">
                                                            <OBCSpinner
                                                                size={125}
                                                                speed={3}
                                                                color={"#e8dfff"}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    selectedCategoryData &&
                                                    _.map(selectedCategoryData, (session) => {
                                                        const tags = _.uniq(session.tags.split(","));
                                                        return (
                                                            <div
                                                                key={session.session_id}
                                                                className="SessionListItem">
                                                                <div className="SessionThunbnail">
                                                                    <img
                                                                        src={session.thumb_url_sd}
                                                                        alt="Session thumbnail"
                                                                    />
                                                                </div>
                                                                <div className="SessionListMainContent">
                                                                    <div className="SessionName">{session.route_name}</div>
                                                                    <div className="SessionDescription">Description: {session.description ?? "N/A"}</div>
                                                                    <div className="SessionLenghts">Duration: {session.session_lenght ?? "Unknown"}</div>
                                                                    <div className="SessionTags">
                                                                        {tags &&
                                                                            tags.length &&
                                                                            _.map(tags, (tag) => {
                                                                                return (
                                                                                    <div
                                                                                        key={tag}
                                                                                        className="SessionTag">
                                                                                        {tag}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                    <div className="CompletedPercent">
                                                                        <OBCProgressBar
                                                                            className="SessionProgressBar"
                                                                            lableVisible={false}
                                                                            progress={_.get(session, ["progress", "completed_percent"], 0) / 100}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="SessionListControls">
                                                                    <OBCButton>Go to session</OBCButton>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/* } */}
                            </div>
                        </>
                    )}
                    {!driverData && !driverDataLoading && Object.keys(trainingData).length === 0 && (
                        <div className="SelectDriverLabel">
                            {driversList.length > 0 ? (
                                <span>Select Driver</span>
                            ) : (
                                <div className="DriversListFeedbackContainer">
                                    <h1>No Drivers Yet</h1>
                                    <span>
                                        Please contact our Support to add drivers <a href="mailto:support@onebigcircle.co.uk">support@onebigcircle.co.uk</a>
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {driverDataLoading && (
                        <div className="SelectDriverLabel">
                            <OBCSpinner
                                size={125}
                                speed={3}
                                color={"#e8dfff"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DriversTab;
