import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circle, Map, TileLayer, FeatureGroup, Popup, Marker, GeoJSON, LayerGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {
    fetchBehaviourZones,
    deleteBehaviourZone,
    updateBehaviourZone,
    newBehaviourZone,
    fetchWheelSlipsDetections,
    setDetectionsLoading,
} from "../redux/actions/index";
import MarkerClusterGroup from "react-leaflet-markercluster";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Checkbox, Input, Select as AntdSelect, notification, Switch, InputNumber, DatePicker, Alert, Badge, Drawer } from "antd";
import { Tooltip } from "antd";
import { faExclamationTriangle, faLayerGroup, faSignIn, faSignOut, faTint, faTintSlash } from "@fortawesome/pro-regular-svg-icons";
import OBCSpinner from "components/util/OBC";
import { createCustomIcon } from "components/map/Icons";
import moment from "moment";
import { lineString, featureCollection } from "turf";
import { point, distance, bbox as turfBbox } from "@turf/turf";
import exclamationIconRaw from "../icons/pins/exclamation.svg";
import leafIconRaw from "../icons/pins/leaf.svg";
import sprayCanRaw from "../icons/pins/spray-can.svg";
import L from "leaflet";
import { LoadingOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const BEHAVIOUR_OPTIONS = [
    { value: "NO_RECORDING", label: "No recording", toolTip: "Disable recording while in zone" },
    { value: "KEEP_RECORDING", label: "Keep recording", toolTip: "Continue recording when stationary while in zone" },
    { value: "NO_VIDEO", label: "No video", toolTip: "Do not include video in recordings while in zone" },
    {
        value: "NO_VIDEO_PROCESSING",
        label: "No video processing",
        toolTip: "Do not perform additional processing (object detection, analytics) on video captured inside zone",
    },
    { value: "SYNC_ON_ENTER", label: "Sync on enter", toolTip: "Trigger data sync on entering zone" },
    { value: "SYNC_ON_EXIT", label: "Sync on exit", toolTip: "Trigger data sync on exiting zone" },
    { value: "NEW_SESSION_ON_ENTER", label: "New session on enter", toolTip: "Start a new session on entering zone" },
    { value: "NEW_SESSION_ON_EXIT", label: "New session on exit", toolTip: "Start a new session on exiting zone" },
    {
        value: "AREA_OF_INTEREST",
        label: "Area of Interest",
        toolTip: "Continue recording when stationary, ignore recording time of day restrictions and mark zone entry/exit on video (AIVR Connect only)",
    },
    { value: "HIGH_PRIORITY", label: "High priority", toolTip: "Footage in this zone should be uploaded first (AIVR Connect only)" },
    { value: "RECORDABLE_AREA", label: "Recordable Area", toolTip: "Record only when within a recordable area zone (AIVR Connect only)" },
    { value: "SESSION_REQUIRED", label: "Session required", toolTip: "Sessions recorded within this zone must be uploaded (AIVR Connect only)" },
    { value: "INSPECTION_REQUIRED", label: "Inspection required", toolTip: "Inspection images captured within this zone must be uploaded (AIVR Connect only)" },
    {
        value: "END_MOVEMENT_ON_GPS_LOSS",
        label: "End movement on GPS loss",
        toolTip: "If GPS is lost while within this zone, assume movement has halted immediately instead of waiting the GPS loss timeout (AIVR Connect only)",
    },
    { value: "EXT_TRIGGER_1", label: "External output #1", toolTip: "enable External output number 1 on the AIVR device" },
    { value: "EXT_TRIGGER_2", label: "External output #2", toolTip: "enable External output number 2 on the AIVR device" },
];

const DATE_FORMAT = ["DD/MMM/YYYY", "DD/MMM/YY"];

const globalDevicesSelector = (state) => state.admin.behaviourZones.devices;
const globalZonesSelector = (state) => state.admin.behaviourZones.zones;
const globalDataPoolsSelector = (state) => state.admin.dataPools;
const behaviourZonesDetectionsSelector = (state) => state.admin.behaviourZonesDetections;
const detectionsLoadingSelector = (state) => state.admin.detectionsLoading;

const DETECTION_TYPES = {
    "Low Adhesion": {
        load: true,
        icon: createCustomIcon("exclamation"),
        iconRaw: exclamationIconRaw,
    },
    "Low Adhesion-active": {
        load: false,
        icon: createCustomIcon("exclamation", "#006fe5"),
        iconRaw: exclamationIconRaw,
    },
    Contamination: {
        load: true,
        icon: createCustomIcon("leaf"),
        iconRaw: leafIconRaw,
    },
    "Contamination-active": {
        load: false,
        icon: createCustomIcon("leaf", "#006fe5"),
        iconRaw: leafIconRaw,
    },

    Treatment: {
        load: true,
        icon: createCustomIcon("spray-can"),
        iconRaw: sprayCanRaw,
    },
    "Treatment-active": {
        load: false,
        icon: createCustomIcon("spray-can", "#006fe5"),
        iconRaw: sprayCanRaw,
    },

    // zone-enter
    "Treatment-zone-enter": {
        load: false,
        icon: createCustomIcon("zone-enter"),
        iconRaw: sprayCanRaw,
    },
    "Treatment-zone-enter-active": {
        load: false,
        icon: createCustomIcon("zone-enter", "#006fe5"),
        iconRaw: sprayCanRaw,
    },
    // treatment-on
    "Treatment-treatment-on": {
        load: false,
        icon: createCustomIcon("treatment-on"),
        iconRaw: sprayCanRaw,
    },
    "Treatment-treatment-on-active": {
        load: false,
        icon: createCustomIcon("treatment-on", "#006fe5"),
        iconRaw: sprayCanRaw,
    },
    // treatment-off
    "Treatment-treatment-off": {
        load: false,
        icon: createCustomIcon("treatment-off"),
        iconRaw: sprayCanRaw,
    },
    "Treatment-treatment-off-active": {
        load: false,
        icon: createCustomIcon("treatment-off", "#006fe5"),
        iconRaw: sprayCanRaw,
    },
    // zone-leave
    "Treatment-zone-leave": {
        load: false,
        icon: createCustomIcon("zone-leave"),
        iconRaw: sprayCanRaw,
    },
    "Treatment-zone-leave-active": {
        load: false,
        icon: createCustomIcon("zone-leave", "#006fe5"),
        iconRaw: sprayCanRaw,
    },
};

const DetectionListItem = ({
    detection,
    isMaster,
    isGroup,
    groupCollapsed = false,
    setGroupCollapsed = null,
    setSelectedDetectionData,
    selectedDetectionData,
    customHoveredLineGeoJSON,
    setCustomLine,
    setCustomHoveredLineGeoJSON,
    groupGeoJson,
    eventDuration,
}) => {
    const detectionType = _.get(detection, "detection_type", "unknown");

    const toggleGroupCollapsed = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isGroup) {
            setGroupCollapsed(!groupCollapsed);
        }
    };

    const isSelected = useMemo(() => {
        if (selectedDetectionData?.id === detection?.id) {
            return true;
        }
        return false;
    }, [detection, selectedDetectionData]);

    const selectDetection = () => {
        if (isMaster && !_.isEmpty(groupGeoJson)) {
            setCustomLine(groupGeoJson);
        } else {
            setCustomLine(null);
        }

        if (groupCollapsed) {
            setGroupCollapsed(false);
        }

        setSelectedDetectionData(detection);
    };

    const showLineOnHover = () => {
        if (groupGeoJson) {
            const geoJson = lineString(groupGeoJson);
            if (!_.isEqual(customHoveredLineGeoJSON, geoJson)) {
                setCustomHoveredLineGeoJSON(geoJson);
            }
        }
    };

    const hideLineOnHover = () => {
        setCustomHoveredLineGeoJSON([]);
    };

    const treatmentDistance = useMemo(() => {
        let _distance = undefined;
        const firstPoint = _.first(groupGeoJson);
        const lastPoint = _.last(groupGeoJson);

        if (firstPoint && lastPoint) {
            const point1 = point([firstPoint[1], firstPoint[0]]);
            const point2 = point([lastPoint[1], lastPoint[0]]);

            var options = { units: "miles" };

            _distance = _.floor(distance(point1, point2, options), 2);
        }

        return _distance;
    }, [groupGeoJson]);

    const eventLabel = useMemo(() => {
        let evLabel = null;
        let evIcon = null;
        let evIconColor = null;
        const label = _.get(detection, ["json_data", "description"]);
        const value = _.get(detection, ["json_data", "entered"]);

        if (label === "Within Treatment Area") {
            if (value) {
                evLabel = "Entered Zone Area";
                evIcon = faSignIn;
                evIconColor = "#33ff43";
            } else {
                evLabel = "Left Zone Area";
                evIcon = faSignOut;
                evIconColor = "#ffa32d";
            }
        } else if (label === "Treatment Active") {
            if (value) {
                evLabel = "Treatment Activated";
                evIcon = faTint;
                evIconColor = "#00beff";
            } else {
                evLabel = "Treatment Deactivated";
                evIcon = faTintSlash;
                evIconColor = "#d8d8d8";
            }
        }

        if (evLabel && evIcon) {
            return (
                <div className="DetectionsListItemEventLabel">
                    <FontAwesomeIcon
                        icon={evIcon}
                        color={evIconColor}
                    />{" "}
                    <span>{evLabel}</span>
                </div>
            );
        }

        return null;
    }, [detection]);

    return (
        <div
            className={`DetectionsListItem ${isSelected ? "selected" : ""}`}
            onClick={() => selectDetection()}
            onMouseOver={showLineOnHover}
            onMouseOut={hideLineOnHover}>
            <div className="DetectionsListItemLeft">
                <div>
                    Type: <span>{detectionType}</span>
                </div>
                {eventLabel}
                {isMaster && treatmentDistance && detectionType.toLowerCase() === "treatment" ? (
                    <div>
                        <span style={{ color: "#ffa32d" }}>Treatment distance: </span>
                        <span>{treatmentDistance}miles</span>
                    </div>
                ) : null}

                {isMaster && isGroup && eventDuration ? (
                    <div>
                        <span style={{ color: "#ffa32d" }}>Treatment duration: </span>
                        <span>{eventDuration}</span>
                    </div>
                ) : null}

                {isMaster && isGroup && !eventDuration ? (
                    <div>
                        <span style={{ color: "#ffa32d" }}>Treatment fault: </span>
                        <span style={{ color: "#fc6565", fontWeight: "bold" }}>Entered zone but no treatment triggered!</span>
                    </div>
                ) : null}

                {detectionType.toLowerCase() !== "treatment" ? (
                    <div>
                        duration: <span>{_.get(detection, "duration")}</span>
                    </div>
                ) : null}
                <div>
                    <span style={{ color: "#ffa32d" }}>Event time: </span>
                    <span>{moment(_.get(detection, "start_timestamp")).format("ddd DD-MMM-YYYY HH:mm:ss")}</span>
                </div>
            </div>
            <div className="DetectionsListItemRight">
                {(isGroup && isMaster) || !isGroup ? (
                    <img
                        draggable={false}
                        className="DetectionsListItemIcon"
                        src={_.get(DETECTION_TYPES, [detectionType, "iconRaw"], null)}
                        alt="Detection Icon"
                        crossOrigin={"anonymous"}
                    />
                ) : null}
                {isGroup && isMaster ? (
                    <Button
                        size="small"
                        type="primary"
                        onClick={toggleGroupCollapsed}>
                        {groupCollapsed ? "Show all events" : "Collapse"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

const GroupedDetectionsListItem = ({
    eventGroup,
    groupIndex,
    setSelectedDetectionData,
    customHoveredLineGeoJSON,
    setCustomLine,
    selectedDetectionData,
    setCustomHoveredLineGeoJSON,
}) => {
    const [groupCollapsed, setGroupCollapsed] = useState(true);
    const [groupGeoJson, setGroupGeoJson] = useState([]);
    const [eventDuration, setEventDuration] = useState(null);

    useEffect(() => {
        let geoJson = [];
        let startTreatmentTs = null;
        let endTreatmentTs = null;

        _.map(eventGroup, (event) => {
            const jsonDataEntered = _.get(event, ["json_data", "entered"], null);
            const jsonDataDescription = _.get(event, ["json_data", "description"], null);

            if (jsonDataDescription === "Treatment Active") {
                if (jsonDataEntered) {
                    geoJson = _.get(event, "line_geometry", []);
                    startTreatmentTs = _.get(event, "start_timestamp", null);
                } else {
                    endTreatmentTs = _.get(event, "start_timestamp", null);
                }
            }
        });

        if (startTreatmentTs && endTreatmentTs) {
            let durationString = "";
            const startTime = moment(startTreatmentTs);
            const endTime = moment(endTreatmentTs);
            const duration = moment.duration(endTime.diff(startTime));
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            if (hours && minutes && seconds) {
                durationString = `${Math.abs(hours)}h ${Math.abs(minutes)}m ${Math.abs(seconds)}s`;
            } else if (minutes && seconds) {
                durationString = `${Math.abs(minutes)}m ${Math.abs(seconds)}s`;
            } else if (seconds) {
                durationString = `${Math.abs(seconds)}s`;
            }
            setEventDuration(durationString);
        }
        setGroupGeoJson(geoJson);
    }, [eventGroup]);

    return (
        <div className="GroupedDetectionsListItem">
            {parseInt(groupIndex) ? (
                <div className="GroupedDetectionsListItemLabel">
                    <div>#{groupIndex} </div>{" "}
                    <div>
                        <FontAwesomeIcon
                            size="1x"
                            icon={faLayerGroup}
                            color="#EFEAF2"
                            title="This is grouped detection"
                        />
                    </div>
                </div>
            ) : null}
            {_.map(eventGroup, (event, eventIndex) => {
                // if groupIndex is 0 return all items (do not collapse)
                if (parseInt(groupIndex) === 0) {
                    return (
                        <DetectionListItem
                            isMaster={false}
                            isGroup={false}
                            detection={event}
                            customHoveredLineGeoJSON={customHoveredLineGeoJSON}
                            selectedDetectionData={selectedDetectionData}
                            setSelectedDetectionData={setSelectedDetectionData}
                            setCustomLine={setCustomLine}
                            setCustomHoveredLineGeoJSON={setCustomHoveredLineGeoJSON}
                            groupGeoJson={groupGeoJson}
                            eventDuration={eventDuration}
                        />
                    );
                } else {
                    if ((groupCollapsed && eventIndex === 0) || !groupCollapsed) {
                        return (
                            <DetectionListItem
                                isMaster={eventIndex === 0}
                                isGroup={eventGroup.length > 1}
                                setGroupCollapsed={setGroupCollapsed}
                                groupCollapsed={groupCollapsed}
                                detection={event}
                                selectedDetectionData={selectedDetectionData}
                                customHoveredLineGeoJSON={customHoveredLineGeoJSON}
                                setSelectedDetectionData={setSelectedDetectionData}
                                setCustomLine={setCustomLine}
                                setCustomHoveredLineGeoJSON={setCustomHoveredLineGeoJSON}
                                groupGeoJson={groupGeoJson}
                                eventDuration={eventDuration}
                            />
                        );
                    }
                }
            })}
        </div>
    );
};

const DetectionPerSessionItem = ({
    detections,
    setSelectedDetectionData,
    setCustomLine,
    selectedDetectionData,
    customHoveredLineGeoJSON,
    setCustomHoveredLineGeoJSON,
}) => {
    const groupedEvents = useMemo(() => {
        let events = {};
        let currentEventID = null;

        _.map(detections, (detection) => {
            const jsonData = _.get(detection, "json_data", {});

            // if json_data have id this means new treatment has started (maybe not? :D)
            if (_.has(jsonData, "id") && currentEventID !== jsonData.id) {
                currentEventID = jsonData.id;
                events[jsonData.id] = [detection];
            } else {
                if (currentEventID) {
                    events[currentEventID] = [...events[currentEventID], detection];
                } else {
                    if (_.has(events, 0)) {
                        events[0] = [...events[0], detection];
                    } else {
                        events[0] = [detection];
                    }
                }
            }
        });

        return events;
    }, [detections]);

    return (
        <div>
            {_.map(groupedEvents, (eventGroup, index) => {
                return (
                    <GroupedDetectionsListItem
                        eventGroup={eventGroup}
                        groupIndex={index}
                        setSelectedDetectionData={setSelectedDetectionData}
                        customHoveredLineGeoJSON={customHoveredLineGeoJSON}
                        setCustomLine={setCustomLine}
                        selectedDetectionData={selectedDetectionData}
                        setCustomHoveredLineGeoJSON={setCustomHoveredLineGeoJSON}
                    />
                );
            })}
        </div>
    );
};

const BehaviourZoneManagement = () => {
    const map = useRef();
    const zoneRefs = useRef({});
    const toolBar = useRef();

    const dispatch = useDispatch();

    const globalDevices = useSelector(globalDevicesSelector);
    const globalZones = useSelector(globalZonesSelector);
    const globalDataPools = useSelector(globalDataPoolsSelector);
    const behaviourZonesDetections = useSelector(behaviourZonesDetectionsSelector);
    const detectionsLoading = useSelector(detectionsLoadingSelector);
    const [currentMapZoom, setCurrentMapZoom] = useState(6);

    const [devices, setDevices] = useState({});
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [zones, setZones] = useState([]);
    const [zonesBeforeSave, setZonesBeforeSave] = useState([]);
    const [selectedDataPool, setSelectedDataPool] = useState(0);
    const [selectedDataPoolZoneFilter, setSelectedDataPoolZoneFilter] = useState([]);

    const [selectedDetectionType, setSelectedDetectionType] = useState(0);
    const [selectedDetectionDuration, setSelectedDetectionDuration] = useState(8);
    const [selectedDetectionDurationDirty, setSelectedDetectionDurationDirty] = useState(false);
    const [selectedDetectionMinScore, setSelectedDetectionMinScore] = useState(1);
    const [selectedDetectionDateInMonths, setSelectedDetectionDateInMonths] = useState(12);
    const [selectedDetectionDateRange, setSelectedDetectionDateRange] = useState([null, null]);

    const [hoveredDetectionID, setHoveredDetectionID] = useState(null);
    const [selectedDetectionData, setSelectedDetectionData] = useState(null);
    const [selectedDetectionGeoJSON, setSelectedDetectionGeoJSON] = useState([]);
    const [customLine, setCustomLine] = useState(null);
    const [customHoveredLineGeoJSON, setCustomHoveredLineGeoJSON] = useState([]);
    const [customLineGeoJSON, setCustomLineGeoJSON] = useState([]);

    const [showZonesOnTheMap, setShowZonesOnTheMap] = useState(true);
    const [showDetectionsOnTheMap, setShowDetectionsOnTheMap] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editingID, setEditingID] = useState(-1);
    const [editingPosition, setEditingPosition] = useState(false);
    const [editingSelectedDataPoolID, setEditingSelectedDataPoolID] = useState(null);
    const [editingCurrentDataPoolID, setEditingCurrentDataPoolID] = useState(0);
    const [deviceError, setDeviceError] = useState(false);
    const [behaviourError, setBehaviourError] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [allCirclesInClickedPoint, setAllCirclesInClickedPoint] = useState(null);
    const [mapClickedPosition, setMapClickedPosition] = useState(null);
    const [popupSelectedZoneID, setPopupSelectedZoneID] = useState(null);
    const [popupPreSelectedZoneID, setPopupPreSelectedZoneID] = useState(null);

    const [detectionsDrawerVisible, setDetectionsDrawerVisible] = useState(false);
    const [openedMarkerPopup, setOpenedMarkerPopup] = useState(null);

    useEffect(() => {
        dispatch(fetchBehaviourZones());
        _.map(DETECTION_TYPES, (detection, detectionType) => {
            if (detection.load) {
                dispatch(setDetectionsLoading(detectionType, true));
                dispatch(fetchWheelSlipsDetections(detectionType));
            }
        });

        // select all zones when page loaded
        const allZones = _.map(BEHAVIOUR_OPTIONS, (zone) => zone.value);
        setSelectedDataPoolZoneFilter(allZones);
    }, [dispatch]);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf("day");
    };

    useEffect(() => {
        if (selectedDetectionDateInMonths > 0) {
            const detectionAgeFilterInDays = moment.duration(selectedDetectionDateInMonths, "months").asDays();
            const startDate = moment().subtract(detectionAgeFilterInDays, "days");
            const endDate = moment();
            setSelectedDetectionDateRange([startDate, endDate]);
        }
    }, [selectedDetectionDateInMonths]);

    const currentlyLoadingDetectionsList = useMemo(() => {
        let newList = [];
        _.map(detectionsLoading, (status, detection) => {
            if (status) {
                newList.push(detection);
            }
        });

        return newList;
    }, [detectionsLoading]);

    useEffect(() => {
        if (!_.isEmpty(globalDevices) && _.isEmpty(devices)) {
            let new_bounds = [
                [180, 90],
                [-180, -90],
            ];

            if (globalZones.length) {
                _.forEach(globalZones, (zone) => {
                    if (zone.latitude < new_bounds[0][0]) {
                        new_bounds[0][0] = zone.latitude;
                    }
                    if (zone.latitude > new_bounds[1][0]) {
                        new_bounds[1][0] = zone.latitude;
                    }
                    if (zone.longitude < new_bounds[0][1]) {
                        new_bounds[0][1] = zone.longitude;
                    }
                    if (zone.longitude > new_bounds[1][1]) {
                        new_bounds[1][1] = zone.longitude;
                    }
                });
            } else {
                new_bounds = [
                    [50, -12],
                    [58, 2],
                ];
            }

            let deviceOptions = [];
            for (let key in globalDevices) {
                deviceOptions.push({
                    label: globalDevices[key].description || key,
                    value: key,
                    data_pool_id: globalDevices[key].data_pool_id,
                });
            }

            setDevices(globalDevices);
            setZones(globalZones);
            setLoadingData(false);
            // setBounds(new_bounds)
            setDeviceOptions(deviceOptions);

            if (map.current) {
                map.current.leafletElement.fitBounds(new_bounds, {
                    padding: [50, 50],
                });
            }
        }
    }, [devices, globalDevices, globalZones, map]);

    useEffect(() => {
        setZones(globalZones);
    }, [globalZones]);

    const handleZoomChanged = () => {
        if (map && map.current) {
            const zoomLevel = map.current.leafletElement.getZoom();
            // console.log('debug new zoomLevel', zoomLevel);
            setCurrentMapZoom(zoomLevel);
        }
    };

    useEffect(() => {
        if (editingID === -2 && editingPosition) {
            if (zoneRefs.current && zoneRefs.current[-2]) {
                zoneRefs.current[-2].leafletElement.editing.enable();
            }
        }
    }, [editingID, editingPosition, zoneRefs]);

    const calculateDistanceFromBounds = (bounds) => {
        let distance = undefined;

        const lat1 = _.get(bounds, [0], null);
        const lon1 = _.get(bounds, [1], null);
        const lat2 = _.get(bounds, [2], null);
        const lon2 = _.get(bounds, [3], null);
        if (!lat1 || !lon2 || !lat2 || !lon2) {
            return distance;
        }

        const R = 6371; // Radius of the Earth in kilometers
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        distance = R * c; // Distance in kilometers
        return distance;
    };

    const calculateCenterFromBounds = (bounds) => {
        let centerCoords = null;

        const lat1 = _.get(bounds, [0], null);
        const lon1 = _.get(bounds, [1], null);
        const lat2 = _.get(bounds, [2], null);
        const lon2 = _.get(bounds, [3], null);
        if (!lat1 || !lon2 || !lat2 || !lon2) {
            return centerCoords;
        }

        const centerLat = (lat1 + lat2) / 2;
        const centerLng = (lon1 + lon2) / 2;

        centerCoords = {
            lat: centerLat,
            lng: centerLng,
        };

        return centerCoords;
    };

    const addBehaviourZone = useCallback(
        (bounds = null) => {
            setPopupPreSelectedZoneID(null);
            setPopupSelectedZoneID(null);
            setAllCirclesInClickedPoint(null);

            const newZones = _.clone(zones);
            let mapCenter = map.current.leafletElement.getCenter();
            const mapScaleMeters = mapCenter.distanceTo(map.current.leafletElement.getBounds().getNorthWest());
            let newZoneRadius = mapScaleMeters / `4`;

            if (bounds) {
                const newDistance = calculateDistanceFromBounds(bounds);
                mapCenter = calculateCenterFromBounds(bounds);
                newZoneRadius = newDistance * 1000 * 0.6;
            }

            newZones.push({
                id: -2, //Use id -2 to know what zones are new when calling add/update/delete
                latitude: mapCenter.lat,
                longitude: mapCenter.lng,
                radius: newZoneRadius,
                devices: [], //Zones without at least one device cannot be saved
                behaviours: [], //Zones without at least one behaviour cannot be saved
                tag: null,
            });

            setZones(newZones);
            setZonesBeforeSave(newZones);
            setEditingPosition(true);
            setSelectedDataPool(0);
            setEditingID(-2);
        },
        [zones],
    );

    const deleteZone = useCallback(
        (id) => {
            console.log("Deleting zone", id);
            setLoadingData(true);
            setPopupPreSelectedZoneID(null);
            setPopupSelectedZoneID(null);
            setAllCirclesInClickedPoint(null);

            dispatch(
                deleteBehaviourZone(id, (success) => {
                    setLoadingData(false);
                    if (success) {
                        notification.success({
                            message: "Successfully deleted behaviour zone",
                        });
                    } else {
                        notification.error({
                            message: "Unable to delete behaviour zone",
                        });
                    }
                }),
            );
        },
        [dispatch],
    );

    const editZonePosition = useCallback(
        (id) => {
            setEditingID(id);
            let editingZone = _.find(zones, function (zone) {
                return zone.id === id;
            });

            setEditingPosition(true);

            map.current.leafletElement.setView([editingZone.latitude, editingZone.longitude]);
            _.forEach(zoneRefs.current, (ref) => {
                if (ref) {
                    ref.leafletElement.unbindPopup();
                }
            });

            if (zoneRefs.current[id]) {
                zoneRefs.current[id].leafletElement.editing.enable();
            }
            setShowEditModal(false);
        },
        [zones],
    );

    const cancelPositionChange = () => {
        setEditingPosition(false);
        setPopupPreSelectedZoneID(null);
        // if edit popup with edit behaviour, position and delete button open and only one in allCirclesInClickedPoint close edit selection popup
        if (allCirclesInClickedPoint && allCirclesInClickedPoint.length === 1) {
            setAllCirclesInClickedPoint(null);
        }
        if (editingID === -2) {
            let newZones = zones.slice();
            let newZoneIndex = _.findIndex(newZones, function (zone) {
                return zone.id === -2;
            });
            newZones.splice(newZoneIndex, 1);
            setZones(newZones);
        } else {
            if (zoneRefs.current[editingID]) {
                zoneRefs.current[editingID].leafletElement.editing.disable();
            }
        }

        zones.forEach((zone) => {
            if (zoneRefs.current[zone.id]) {
                zoneRefs.current[zone.id].leafletElement.setRadius(zone.radius);
            }
        });

        setEditingID(-1);
    };

    const closeEditModal = useCallback(() => {
        setPopupSelectedZoneID(null);
        setPopupPreSelectedZoneID(null);
        // if edit popup with edit behaviour, position and delete button open and only one in allCirclesInClickedPoint close edit selection popup
        if (allCirclesInClickedPoint && allCirclesInClickedPoint.length === 1) {
            setAllCirclesInClickedPoint(null);
        }
        if (editingID === -2) {
            let newZones = _.clone(zones);
            let newZoneIndex = _.findIndex(newZones, function (zone) {
                return zone.id === -2;
            });

            newZones.splice(newZoneIndex, 1);
            setZones(newZones);
        }
        setShowEditModal(false);
        setDeviceError(false);
        setBehaviourError(false);
        setEditingID(-1);
        setEditingSelectedDataPoolID(null);
        setEditingCurrentDataPoolID(0);
        if (!_.isEqual(zonesBeforeSave, zones)) {
            setZones(zonesBeforeSave);
        }
    }, [allCirclesInClickedPoint, editingID, zones, zonesBeforeSave]);

    const saveZoneChanges = useCallback(() => {
        setDeviceError(false);
        setBehaviourError(false);
        setPopupPreSelectedZoneID(null);
        setLoadingData(true);

        let editingZone = _.find(zones, function (zone) {
            return zone.id === editingID;
        });

        let behaviours = editingZone["behaviours"];
        let devices = editingZone["devices"];
        let tag = editingZone["tag"];

        if (devices.length === 0) {
            setDeviceError("You must select at least one device");
            setLoadingData(false);
            return;
        }

        if (behaviours.length === 0 && !tag) {
            setBehaviourError("You must select at least one behaviour or enter a tag");
            setLoadingData(false);
            return;
        }

        let latitude = editingZone["latitude"];
        let longitude = editingZone.longitude;
        let radius = editingZone.radius;

        if (editingID === -2) {
            dispatch(
                newBehaviourZone(latitude, longitude, radius, devices, behaviours, tag, (success) => {
                    setLoadingData(false);
                    if (success) {
                        notification.success({
                            message: "Saved",
                            description: "New behaviour zone saved successfully",
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: "An error occurred while adding this new behaviour zone",
                        });
                    }
                }),
            );
        } else {
            dispatch(
                updateBehaviourZone(editingID, latitude, longitude, radius, behaviours, devices, tag, (success) => {
                    setLoadingData(false);
                    if (success) {
                        notification.success({
                            message: "Updated",
                            description: "Behaviour zone updated successfully",
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: "An error occurred while updating this behaviour zone",
                        });
                    }
                }),
            );
        }
        closeEditModal();
    }, [closeEditModal, dispatch, editingID, zones]);

    const openEditModal = useCallback(
        (id) => {
            if (editingID === -1) {
                setEditingID(id);
                setEditingSelectedDataPoolID(null);
                setShowEditModal(true);
                setZonesBeforeSave(_.cloneDeep(zones));
            }
        },
        [editingID, zones],
    );

    const toggleBehaviourCheckbox = (evt) => {
        let newZones = _.clone(zones);
        let editingZoneIndex = _.findIndex(zones, function (zone) {
            return zone.id === editingID;
        });

        let value = evt.target.value;

        if (evt.target.checked) {
            if (!_.includes(newZones[editingZoneIndex].behaviours, value)) {
                newZones[editingZoneIndex].behaviours.push(value);
            }
        } else {
            _.remove(newZones[editingZoneIndex].behaviours, function (behaviour) {
                return behaviour === value;
            });
        }

        setZones(newZones);
    };

    const renderBehaviourCheckbox = (data) => {
        let editingZone = _.find(zones, function (zone) {
            return zone.id === editingID;
        });
        let isChecked = false;

        if (editingZone) {
            isChecked = _.includes(editingZone.behaviours, data.value);
        }

        return (
            <div
                className="zoneCheckboxContainer"
                key={data.value}>
                <Checkbox
                    className="behaviourCheckbox"
                    id={data.value}
                    value={data.value}
                    checked={isChecked}
                    onChange={toggleBehaviourCheckbox}
                />
                <label
                    className="behaviourLabel"
                    htmlFor={data.value}>
                    {data.label}
                </label>

                <Tooltip
                    title={data.toolTip}
                    placement="top">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
            </div>
        );
    };

    const savePositionChanges = useCallback(() => {
        setPopupPreSelectedZoneID(null);
        // if edit popup with edit behaviour, position and delete button open and only one in allCirclesInClickedPoint close edit selection popup
        if (allCirclesInClickedPoint && allCirclesInClickedPoint.length === 1) {
            setAllCirclesInClickedPoint(null);
        }

        let newZones = _.clone(zones);
        let editingZoneIndex = _.findIndex(newZones, function (zone) {
            return zone.id === editingID;
        });

        if (zoneRefs.current[editingID]) {
            newZones[editingZoneIndex].latitude = zoneRefs.current[editingID].leafletElement.editing._moveMarker._latlng["lat"].toFixed(5);
            newZones[editingZoneIndex].longitude = zoneRefs.current[editingID].leafletElement.editing._moveMarker._latlng["lng"].toFixed(5);
            newZones[editingZoneIndex].radius = zoneRefs.current[editingID].leafletElement._mRadius;
            zoneRefs.current[editingID].leafletElement.editing.disable();
        }

        if (editingID !== -2) {
            saveZoneChanges();
        } else {
            setShowEditModal(true);
        }
        setZones(newZones);
        setEditingPosition(false);
    }, [allCirclesInClickedPoint, editingID, saveZoneChanges, zones]);

    const zoneDeviceChange = (newDevices) => {
        let newZones = _.clone(zones);
        let editingZoneIndex = _.findIndex(newZones, function (zone) {
            return zone.id === editingID;
        });

        if (newDevices) {
            newZones[editingZoneIndex].devices = newDevices.map((device) => {
                return device.key.value || device.key;
            });
        } else {
            newZones[editingZoneIndex].devices = [];
        }

        setZones(newZones);
    };

    const zoneTagChange = (evt) => {
        const newTag = evt.target.value;
        let newZones = _.clone(zones);

        let editingZoneIndex = _.findIndex(newZones, function (zone) {
            return zone.id === editingID;
        });
        newZones[editingZoneIndex].tag = newTag;
        setZones(newZones);
    };

    const editZoneDevicesList = useMemo(() => {
        let editingZone = _.find(zones, function (zone) {
            return zone.id === editingID;
        });

        if (editingZone) {
            let options = _.map(editingZone.devices, (deviceKey) => {
                const device = _.find(deviceOptions, function (device) {
                    return device.value === deviceKey;
                });

                return {
                    key: device.value,
                    label: device.label,
                    data_pool_id: device.data_pool_id,
                };
            });

            return options;
        } else {
            return [];
        }
    }, [deviceOptions, editingID, zones]);

    const getInitialTagValue = () => {
        let editingZone = _.find(zones, function (zone) {
            return zone.id === editingID;
        });

        if (editingZone) {
            return editingZone.tag || "";
        } else {
            return "";
        }
    };

    const renderZone = useCallback(
        (zoneData, index) => {
            let colour = "#3388ff";
            let borderWeight = 2;
            if (popupPreSelectedZoneID === zoneData.id) {
                colour = "orange";
                borderWeight = 4;
            }

            let coords = [zoneData.latitude, zoneData.longitude];

            // This block is to stop the circle resetting back to original position when rerendering
            if (
                editingPosition &&
                _.has(zoneRefs.current[zoneData.id], "leafletElement.editing._moveMarker._latlng.lng") &&
                _.has(zoneRefs.current[zoneData.id], "leafletElement.editing._moveMarker._latlng.lat") &&
                zoneData.id === editingID
            ) {
                coords = [
                    zoneRefs.current[zoneData.id].leafletElement.editing._moveMarker._latlng.lat,
                    zoneRefs.current[zoneData.id].leafletElement.editing._moveMarker._latlng.lng,
                ];
            }

            let marker = null;
            if (map && map.current && map.current.leafletElement.getZoom() < 12 && zoneData.radius < 2000) {
                marker = (
                    <Marker
                        key={zoneData.id}
                        position={coords}></Marker>
                );
            }

            // if show zones if turned off still display zone after pressing Add Zone button
            if (!showZonesOnTheMap && zoneData.id !== editingID) {
                return null;
            }

            return (
                <Circle
                    ref={(ref) => (zoneRefs.current[zoneData.id] = ref)}
                    color={colour}
                    weight={borderWeight}
                    key={`${index}`}
                    data={{ test: zoneData.id }}
                    center={coords}
                    interactive={false}
                    radius={zoneData.radius}>
                    {marker}
                </Circle>
            );
        },
        [editingID, editingPosition, popupPreSelectedZoneID, showZonesOnTheMap],
    );

    const openSessionInNewTab = (linkToSession) => {
        window.open(linkToSession, "_blank");
    };

    const createZoneFromLine = useCallback(
        (lineGeometry) => {
            const line = lineString(lineGeometry);
            const bbox = turfBbox(line);
            const bounds = [bbox[1], bbox[0], bbox[3], bbox[2]];
            addBehaviourZone(bounds);
        },
        [addBehaviourZone],
    );

    const renderDetection = useCallback(
        (detectionsData, index) => {
            const startTs = _.get(detectionsData, "start_timestamp", null);
            const coords = _.get(detectionsData, "start_location", null);
            const detectionLineGeometry = _.get(detectionsData, "line_geometry", null);
            const session_id = _.get(detectionsData, "session_id", null);
            const jsonDataEntered = _.get(detectionsData, ["json_data", "entered"], null);
            const jsonDataDescription = _.get(detectionsData, ["json_data", "description"], null);
            let detectionType = _.get(detectionsData, "detection_type", null);
            const startDateTime = moment(startTs).format("ddd DD-MMM-YYYY [@] HH:mm:ss");
            const detectionDaysFromNow = moment(startTs).fromNow();
            const linkToSession = `https://view.aivr.video/rail-inspection/${session_id}/${startTs}`;

            if (map && map.current && coords) {
                if (detectionType) {
                    // if type is treatment and json_data indicates that treatment had started display adequate icon and colour
                    if (detectionType === "Treatment") {
                        if (jsonDataDescription === "Treatment Active") {
                            if (jsonDataEntered) {
                                detectionType = `${detectionType}-treatment-on`;
                            } else {
                                detectionType = `${detectionType}-treatment-off`;
                            }
                        }

                        if (jsonDataDescription === "Within Treatment Area") {
                            if (jsonDataEntered) {
                                detectionType = `${detectionType}-zone-enter`;
                            } else {
                                detectionType = `${detectionType}-zone-leave`;
                            }
                        }
                    }

                    // if marker is selected change color to green
                    if (selectedDetectionData === detectionsData) {
                        detectionType = `${detectionType}-active`;
                    } else {
                    }
                }
                return (
                    <Marker
                        key={"detectionMarker" + index}
                        position={coords}
                        icon={_.get(DETECTION_TYPES, [detectionType, "icon"], null)}
                        onmouseover={() => setHoveredDetectionID(detectionsData.id)}
                        zIndexOffset={selectedDetectionData === detectionsData ? 2000 : 1000}
                        onmouseout={() => {
                            setHoveredDetectionID(null);
                        }}>
                        {!editingPosition ? (
                            <Popup
                                onClose={() => {
                                    setSelectedDetectionData(null);
                                    setHoveredDetectionID(null);
                                }}
                                onOpen={() => {
                                    setSelectedDetectionData(detectionsData);
                                }}
                                open={true}>
                                <div>
                                    <strong>Detection ID:</strong> <span>{_.get(detectionsData, "id", "Unknown")}</span>
                                </div>
                                <div>
                                    <strong>Detection Type:</strong> <span>{_.get(detectionsData, "detection_type", "Unknown")}</span>
                                </div>
                                <div>
                                    <strong>Detection duration:</strong> <span>{_.get(detectionsData, "duration", "Unknown")}s</span>
                                </div>
                                <div>
                                    <strong>Device:</strong> <span>{_.get(detectionsData, "device_description", "Unknown")}</span>
                                </div>
                                <div>
                                    <strong>Session ID:</strong> <span>{_.get(detectionsData, "session_id", "Unknown")}</span>
                                </div>
                                {_.get(detectionsData, "score", null) ? (
                                    <div>
                                        <strong>Score:</strong> <span>{_.round(_.get(detectionsData, "score", 0) * 100, 2)}%</span>
                                    </div>
                                ) : null}
                                <div>
                                    <strong>Recorded:</strong> <span>{detectionDaysFromNow}</span>
                                </div>
                                <div>
                                    <strong>Full Date:</strong>{" "}
                                    <span>
                                        {startDateTime} ({startTs})
                                    </span>
                                </div>
                                <Button
                                    type="primary"
                                    size="small"
                                    style={{ width: "100%" }}
                                    onClick={() => openSessionInNewTab(linkToSession)}>
                                    Open This Session in AIVR
                                </Button>
                                {_.get(detectionsData, "detection_type") === "Contamination" && !_.isEmpty(detectionLineGeometry) ? (
                                    <Button
                                        type="primary"
                                        size="small"
                                        style={{ width: "100%", marginTop: "4px", backgroundColor: "#ff8c00", borderColor: "#e8850b" }}
                                        onClick={() => createZoneFromLine(detectionLineGeometry)}>
                                        Treat this area
                                    </Button>
                                ) : null}
                            </Popup>
                        ) : null}
                    </Marker>
                );
            }

            return null;
        },
        [createZoneFromLine, editingPosition, selectedDetectionData],
    );

    const mapData = useMemo(() => {
        return _.filter(zones, (zone) => {
            let displayZone = true;

            if (editingID === -2) {
                return true;
            }

            if (selectedDataPool && zone.data_pool_id !== selectedDataPool) {
                displayZone = false;
            }

            if (displayZone && _.intersection(zone.behaviours, selectedDataPoolZoneFilter).length === 0) {
                displayZone = false;
            }

            return displayZone;
        });
    }, [selectedDataPool, zones, selectedDataPoolZoneFilter, editingID]);

    const mapDetectionsData = useMemo(() => {
        let detectionsData = [];

        // filtering detections based on selected data pool, detection type, duration, date filter
        _.map(behaviourZonesDetections, (groupedDetections, detectionsType) => {
            // check if selected detection type is All or equal to current detectionType
            if (selectedDetectionType === 0 || detectionsType === selectedDetectionType) {
                // map through all objects in detectionType (grouped by data_pool_id)
                _.map(groupedDetections, (detections, dataPoolID) => {
                    // check if currently selected "Filter Data Pool" is All or not, if not check if objet key (data_pool_id) equals selected data poll
                    if (selectedDataPool === 0 || (selectedDataPool > 0 && selectedDataPool === parseInt(dataPoolID))) {
                        // map through all detections
                        _.map(detections, (detection) => {
                            // check if detection in date range
                            if (selectedDetectionDateInMonths !== 0) {
                                const isInRange = moment(_.get(detection, "start_timestamp", 0)).isBetween(
                                    selectedDetectionDateRange[0],
                                    selectedDetectionDateRange[1],
                                    null,
                                    "[]",
                                );
                                if (!isInRange) {
                                    return false;
                                }
                            }

                            // first check if duration is greater then duration filter
                            const duration = _.get(detection, "duration", 0);
                            if (duration <= selectedDetectionDuration) {
                                return false;
                            }

                            // check if contamination pass score
                            if (detectionsType === "Contamination") {
                                const score = _.get(detection, "score", 0);
                                if (score < selectedDetectionMinScore) {
                                    return false;
                                }
                            }

                            // if all above passed push to detectionsData
                            detectionsData.push(detection);
                        });
                    }
                });
            }
        });

        detectionsData = _.flatten(detectionsData); // before flatten there will be a array of objects with key being a data_pool_id

        const sortedDetectionsData = _.sortBy(detectionsData, ["start_timestamp"]);

        return sortedDetectionsData;
    }, [
        behaviourZonesDetections,
        selectedDataPool,
        selectedDetectionType,
        selectedDetectionDuration,
        selectedDetectionDateInMonths,
        selectedDetectionDateRange,
        selectedDetectionMinScore,
    ]);

    useEffect(() => {
        const coords = _.get(selectedDetectionData, "start_location", null);
        let bounds = null;

        if (customLine) {
            const line = lineString(customLine);
            const bbox = turfBbox(line);
            bounds = [bbox[1], bbox[0], bbox[3], bbox[2]];
        }

        if (map.current && coords) {
            // if user click on the master grouped list item bounds will be set so
            // we position map in line bounds otherwise we go to the point on the map
            if (bounds) {
                let boundAdjustment = Math.abs(bounds[1] - bounds[3]) / 5;
                const _northEast = L.latLng(bounds[0] + boundAdjustment, bounds[1] + boundAdjustment * 1);
                const _southWest = L.latLng(bounds[2] - boundAdjustment, bounds[3] + boundAdjustment * 1);
                const calculatedBounds = L.latLngBounds(_northEast, _southWest);
                map.current.leafletElement.fitBounds(calculatedBounds, { padding: [150, 150] });
            } else {
                map.current.leafletElement.setView(coords, 18);
            }
        }
    }, [selectedDetectionData, customLine]);

    const groupedDetectionsBySessionID = useMemo(() => {
        if (showDetectionsOnTheMap) {
            const _groupedDetections = _.groupBy(mapDetectionsData, "session_id");
            return _groupedDetections;
        }
        return [];
    }, [mapDetectionsData, showDetectionsOnTheMap]);

    const reversedGroupedDetectionsBySessionIDList = useMemo(() => {
        const keys = _.reverse(Object.keys(groupedDetectionsBySessionID));
        return keys;
    }, [groupedDetectionsBySessionID]);

    // draw a lines for Contamination and Treatment if turned on in filters
    const mapDetectionsGeoJSON = useMemo(() => {
        let allContaminations = [];

        _.map(mapDetectionsData, (detection) => {
            if (["Contamination", "Treatment"].includes(detection.detection_type)) {
                const geometryLines = _.get(detection, "line_geometry", null);

                if (geometryLines) {
                    allContaminations.push(lineString(geometryLines, detection));
                }
            }
        });
        const collection = featureCollection(allContaminations);

        return collection;
    }, [mapDetectionsData]);

    // render and add to the map hovered line, done this way to help with bringing line to the front
    useEffect(() => {
        if (hoveredDetectionID) {
            const detectionIndex = _.findIndex(mapDetectionsData, { id: hoveredDetectionID });
            const detection = mapDetectionsData[detectionIndex];
            const geometryLines = _.get(detection, "line_geometry", null);

            // create geoJson
            if (geometryLines) {
                const geoJson = lineString(geometryLines, detection);
                setSelectedDetectionGeoJSON(geoJson);
            } else {
                setSelectedDetectionGeoJSON([]);
            }
        } else if (customHoveredLineGeoJSON) {
            setSelectedDetectionGeoJSON(customHoveredLineGeoJSON);
        } else {
            // here we'll remove line
            setSelectedDetectionGeoJSON([]);
        }
    }, [customHoveredLineGeoJSON, hoveredDetectionID, mapDetectionsData]);

    // on customLine change generate geoJSON object from given coordinates
    useEffect(() => {
        if (customLine) {
            const geoJson = lineString(customLine);
            setCustomLineGeoJSON(geoJson);
        } else {
            setCustomLineGeoJSON([]);
        }
    }, [customLine]);

    const filteredDevices = useMemo(() => {
        let editingZone = _.find(zones, function (zone) {
            return zone.id === editingID;
        });

        if (editingZone && editingZone.data_pool_id) {
            setEditingCurrentDataPoolID(editingZone.data_pool_id);
            setEditingSelectedDataPoolID(editingZone.data_pool_id);
        }

        return _.map(
            _.filter(deviceOptions, (device) => {
                if (editingZone && editingZone.devices && _.includes(editingZone.devices, device.value)) {
                    return device;
                }
                if (editingSelectedDataPoolID === device.data_pool_id) {
                    return device;
                }
            }),
        );
    }, [deviceOptions, editingID, editingSelectedDataPoolID, zones]);

    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setMapClickedPosition([lat, lng]);

        // Check if the clicked point is within any of the circles
        const allCirclesInClickedPoint = _.filter(mapData, (circle) => {
            return isCircleInRange(circle.latitude, circle.longitude, circle.radius / 1000, lat, lng);
        });

        if (!_.isEmpty(allCirclesInClickedPoint)) {
            setAllCirclesInClickedPoint(allCirclesInClickedPoint);
        } else {
            setAllCirclesInClickedPoint(null);
            setPopupPreSelectedZoneID(null);
            setPopupSelectedZoneID(null);
        }
    };

    function isCircleInRange(circleLat, circleLon, circleRadius, targetLat, targetLon) {
        // Convert latitude and longitude from degrees to radians
        const radCircleLat = (Math.PI * circleLat) / 180;
        const radCircleLon = (Math.PI * circleLon) / 180;
        const radTargetLat = (Math.PI * targetLat) / 180;
        const radTargetLon = (Math.PI * targetLon) / 180;

        // Earth's radius in kilometers
        const earthRadius = 6371;

        // Haversine formula to calculate distance
        const dLat = radTargetLat - radCircleLat;
        const dLon = radTargetLon - radCircleLon;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(radCircleLat) * Math.cos(radTargetLat) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;

        // Check if the distance is less than or equal to the circle's radius
        return distance <= circleRadius;
    }

    const renderZoneSelectionPopup = useMemo(() => {
        const isMoreThanOne = allCirclesInClickedPoint && Object.keys(allCirclesInClickedPoint).length === 1;
        let selectedZoneID = popupSelectedZoneID;

        if (!showZonesOnTheMap || editingID > 0) {
            return null;
        }

        if (isMoreThanOne) {
            selectedZoneID = _.get(allCirclesInClickedPoint, [0, "id"]);
            setPopupPreSelectedZoneID(selectedZoneID);
        }

        return (
            <Popup
                key={"zone-selector-" + Date.now()}
                position={mapClickedPosition}
                onClose={() => {
                    setPopupSelectedZoneID(null);
                    setPopupPreSelectedZoneID(null);
                }}>
                {!selectedZoneID ? (
                    <div className="ZoneEditPopupContainer">
                        <h3>Select One Zone</h3>
                        <div className="ZoneEditPopupList">
                            {_.map(allCirclesInClickedPoint, (circle) => {
                                const circleDataPoolName = _.get(
                                    _.find(globalDataPools, (dataPool) => {
                                        return dataPool.id === circle.data_pool_id;
                                    }),
                                    "description",
                                    null,
                                );
                                return (
                                    <div
                                        className="ZoneEditPopupListItem"
                                        onClick={() => setPopupSelectedZoneID(circle.id)}
                                        onMouseEnter={() => {
                                            setPopupPreSelectedZoneID(circle.id);
                                        }}
                                        onMouseLeave={() => {
                                            setPopupPreSelectedZoneID(null);
                                        }}>
                                        {`${circleDataPoolName || circle.id} (${Object.keys(circle.devices).length})`}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="behaviourPopupDiv">
                        <button
                            className="behaviourPopupButton edit"
                            onClick={() => {
                                openEditModal(selectedZoneID);
                                setPopupSelectedZoneID(null);
                            }}>
                            Edit behaviours
                        </button>
                        <button
                            className="behaviourPopupButton edit"
                            onClick={() => {
                                editZonePosition(selectedZoneID);
                                setPopupSelectedZoneID(null);
                            }}>
                            Edit Position
                        </button>
                        <button
                            className="behaviourPopupButton delete"
                            onClick={() => {
                                deleteZone(selectedZoneID);
                            }}>
                            Delete
                        </button>
                    </div>
                )}
            </Popup>
        );
    }, [
        allCirclesInClickedPoint,
        deleteZone,
        editZonePosition,
        globalDataPools,
        mapClickedPosition,
        openEditModal,
        popupSelectedZoneID,
        editingID,
        showZonesOnTheMap,
    ]);

    const detectionsComponent = useMemo(() => {
        return (
            <MarkerClusterGroup
                maxClusterRadius={32}
                disableClusteringAtZoom={16}
                showCoverageOnHover={false}
                spiderfyOnMaxZoom={true}
                animate={true}
                zoomToBoundsOnClick={true}>
                {showDetectionsOnTheMap ? _.map(mapDetectionsData, renderDetection) : null}
            </MarkerClusterGroup>
        );
    }, [mapDetectionsData, renderDetection, showDetectionsOnTheMap]);

    const getSelectedLineColors = (feature, showDetectionsOnTheMap, currentMapZoom) => {
        let colorProperty = "#1890ff";
        let widthProperty = 5;
        let opacityProperty = 5;

        // hide lines when map zoom lever 10 or less
        if (!showDetectionsOnTheMap || currentMapZoom <= 10) {
            widthProperty = 0;
        }

        return { color: colorProperty, weight: widthProperty, opacity: opacityProperty };
    };

    return (
        <React.Fragment>
            <Modal
                title={`Edit Behaviour Zone`}
                width={600}
                className="EditBehaviourZoneModal"
                visible={showEditModal}
                onCancel={closeEditModal}
                footer={[
                    <Button
                        key="close"
                        type="primary"
                        onClick={closeEditModal}>
                        Cancel
                    </Button>,
                    <Button
                        className="saveBehaviourButton"
                        key="save"
                        type="primary"
                        onClick={saveZoneChanges}>
                        Save
                    </Button>,
                ]}>
                <div>
                    <h3>Data Pool {editingID === -2 ? "(Select one)" : ""} </h3>
                    <AntdSelect
                        showSearch
                        allowClear
                        style={{ width: "100%", paddingBottom: "5px" }}
                        value={editingID !== -2 ? editingCurrentDataPoolID : editingSelectedDataPoolID} // if editing existing zone use this zone data_pool_id
                        disabled={editingID !== -2} // if editing existing zone disable option to change data_pool_id
                        onChange={(value) => setEditingSelectedDataPoolID(value)}
                        filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {_.map(globalDataPools, (dataPool) => {
                            return (
                                <AntdSelect.Option
                                    key={dataPool.id}
                                    value={dataPool.id}>
                                    {dataPool.description}
                                </AntdSelect.Option>
                            );
                        })}
                    </AntdSelect>

                    <h3>Devices (Select 1 or more)</h3>

                    <AntdSelect
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select devices"
                        onChange={zoneDeviceChange}
                        labelInValue={true}
                        disabled={!editingSelectedDataPoolID} // if data pool not selected disable
                        value={editZoneDevicesList}>
                        {_.map(filteredDevices, (device) => {
                            return (
                                <AntdSelect.Option
                                    key={device}
                                    value={device.value}>
                                    <span style={{ color: editingSelectedDataPoolID !== device.data_pool_id ? "red" : "#000000a6" }}>
                                        {editingSelectedDataPoolID !== device.data_pool_id && (
                                            <FontAwesomeIcon
                                                style={{ paddingRight: "2px" }}
                                                icon={faExclamationTriangle}
                                                color="red"
                                                title="This device does not belong to the appropriate data pool, and the behaviour zone will not affect it"
                                            />
                                        )}
                                        {device.label}
                                    </span>
                                </AntdSelect.Option>
                            );
                        })}
                    </AntdSelect>
                    {deviceError && <p className="error">{deviceError}</p>}
                    <div className="zoneCheckboxDiv">{_.map(BEHAVIOUR_OPTIONS, renderBehaviourCheckbox)}</div>
                    {behaviourError && <p className="error">{behaviourError}</p>}
                    <Input
                        placeholder="Tag to apply:"
                        onChange={zoneTagChange}
                        value={getInitialTagValue()}
                    />
                </div>
            </Modal>

            <div className="ZoneMainContainer">
                <div className="MapAndControls">
                    <div className="ZoneMainContainerTopToolbar">
                        <div className="LeftRow">
                            <div className="ZoneMainContainerTopToolbarItem">
                                <div className="ZoneMainContainerTopToolbarItemLabel">Zones:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <Switch
                                        checked={showZonesOnTheMap}
                                        onChange={() => setShowZonesOnTheMap(!showZonesOnTheMap)}
                                    />
                                </div>
                            </div>

                            <div className={`ZoneMainContainerTopToolbarItem`}>
                                <div className="ZoneMainContainerTopToolbarItemLabel">Data Pools Filter:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <AntdSelect
                                        size="small"
                                        showSearch
                                        allowClear
                                        style={{ width: 300 }}
                                        value={selectedDataPool}
                                        dropdownMatchSelectWidth={false}
                                        onChange={(value) => setSelectedDataPool(value || 0)}
                                        filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        <AntdSelect.Option value={0}>All</AntdSelect.Option>
                                        {_.map(globalDataPools, (dataPool) => {
                                            const count = _.filter(zones, (zone) => zone.data_pool_id === dataPool.id).length;
                                            return (
                                                <AntdSelect.Option
                                                    key={dataPool.id}
                                                    value={dataPool.id}>
                                                    {dataPool.description} ({count})
                                                </AntdSelect.Option>
                                            );
                                        })}
                                    </AntdSelect>
                                </div>
                            </div>

                            {/* zone filter */}
                            <div className={`ZoneMainContainerTopToolbarItem`}>
                                <div className="ZoneMainContainerTopToolbarItemLabel">Zone Filter:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <AntdSelect
                                        size="small"
                                        style={{ width: 320 }}
                                        mode="multiple"
                                        maxTagCount={1}
                                        value={selectedDataPoolZoneFilter}
                                        dropdownMatchSelectWidth={false}
                                        onChange={(zonesList) => setSelectedDataPoolZoneFilter(zonesList)}>
                                        {_.map(BEHAVIOUR_OPTIONS, (option, index) => {
                                            return (
                                                <AntdSelect.Option
                                                    key={"zone-filter" + index}
                                                    value={option.value}>
                                                    {option.label}
                                                </AntdSelect.Option>
                                            );
                                        })}
                                    </AntdSelect>
                                </div>
                            </div>

                            <div className="ZoneMainContainerTopToolbarItemDivider"></div>

                            <div className="ZoneMainContainerTopToolbarItem">
                                <div className="ZoneMainContainerTopToolbarItemLabel">Detections:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <Switch
                                        checked={showDetectionsOnTheMap}
                                        onChange={() => setShowDetectionsOnTheMap(!showDetectionsOnTheMap)}
                                    />
                                </div>
                            </div>

                            <div className={`ZoneMainContainerTopToolbarItem ${!showDetectionsOnTheMap ? "Disabled-NOT" : ""}`}>
                                <div className="ZoneMainContainerTopToolbarItemLabel">Detection type:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <AntdSelect
                                        size="small"
                                        showSearch
                                        allowClear
                                        style={{ width: 170 }}
                                        value={selectedDetectionType}
                                        dropdownMatchSelectWidth={false}
                                        onChange={(value) => {
                                            setSelectedDetectionType(value);
                                            // automatically toggle detections layer on when selection made
                                            if (!showDetectionsOnTheMap && value !== 0) {
                                                setShowDetectionsOnTheMap(true);
                                            }
                                            // preset default duration
                                            if (value === "Contamination" && !selectedDetectionDurationDirty) {
                                                setSelectedDetectionDuration(8);
                                            }
                                            if (["Low Adhesion", "Treatment"].includes(value) && !selectedDetectionDurationDirty) {
                                                setSelectedDetectionDuration(0.9);
                                            }
                                        }}
                                        filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        <AntdSelect.Option value={0}>All</AntdSelect.Option>
                                        {_.map(detectionsLoading, (isLoading, detectionName) => {
                                            return (
                                                <AntdSelect.Option
                                                    key={detectionName}
                                                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "3px" }}
                                                    value={detectionName}
                                                    disabled={isLoading}>
                                                    <span>{detectionName}</span> {isLoading ? <LoadingOutlined /> : null}
                                                </AntdSelect.Option>
                                            );
                                        })}
                                    </AntdSelect>
                                </div>
                            </div>

                            <div className={`ZoneMainContainerTopToolbarItem ${!showDetectionsOnTheMap ? "Disabled-NOT" : ""}`}>
                                <div className="ZoneMainContainerTopToolbarItemLabel">Min Duration:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <InputNumber
                                        min={0}
                                        max={20}
                                        size="small"
                                        value={selectedDetectionDuration}
                                        precision={2}
                                        step={0.1}
                                        onChange={(value) => {
                                            setSelectedDetectionDurationDirty(true);
                                            setSelectedDetectionDuration(value);
                                        }}
                                        formatter={(v) => `${v}s`}
                                        parser={(v) => v.replace(/[^0-9.]/g, "")}
                                    />
                                </div>
                            </div>

                            <div className={`ZoneMainContainerTopToolbarItem ${!showDetectionsOnTheMap ? "Disabled-NOT" : ""}`}>
                                <div className="ZoneMainContainerTopToolbarItemLabel">Date Filter:</div>
                                <div className="ZoneMainContainerTopToolbarItemControl">
                                    <AntdSelect
                                        size="small"
                                        style={{ width: 140 }}
                                        value={selectedDetectionDateInMonths}
                                        dropdownMatchSelectWidth={false}
                                        onChange={(value) => setSelectedDetectionDateInMonths(value)}>
                                        <AntdSelect.Option
                                            key={"date-filter" + 0}
                                            value={0}>
                                            All
                                        </AntdSelect.Option>
                                        <AntdSelect.Option
                                            key={"date-filter" + 1}
                                            value={1}>
                                            Last month
                                        </AntdSelect.Option>
                                        <AntdSelect.Option
                                            key={"date-filter" + 3}
                                            value={3}>
                                            Last 3 months
                                        </AntdSelect.Option>
                                        <AntdSelect.Option
                                            key={"date-filter" + 6}
                                            value={6}>
                                            Last 6 months
                                        </AntdSelect.Option>
                                        <AntdSelect.Option
                                            key={"date-filter" + 12}
                                            value={12}>
                                            Last 12 months
                                        </AntdSelect.Option>
                                        <AntdSelect.Option
                                            key={"date-filter" + -1}
                                            value={-1}>
                                            Custom
                                        </AntdSelect.Option>
                                    </AntdSelect>
                                </div>
                            </div>

                            {selectedDetectionDateInMonths === -1 ? (
                                <div className={`ZoneMainContainerTopToolbarItem ${!showDetectionsOnTheMap ? "Disabled-NOT" : ""}`}>
                                    <div className="ZoneMainContainerTopToolbarItemLabel">Date Filter:</div>
                                    <div className="ZoneMainContainerTopToolbarItemControl">
                                        <RangePicker
                                            size="small"
                                            onChange={(range) => {
                                                console.log("debug2 range", range);
                                                setSelectedDetectionDateRange(range);
                                            }}
                                            value={selectedDetectionDateRange}
                                            disabledDate={disabledDate}
                                            format={DATE_FORMAT}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            {!["Low Adhesion", "Treatment"].includes(selectedDetectionType) ? (
                                <div className={`ZoneMainContainerTopToolbarItem ${!showDetectionsOnTheMap ? "Disabled-NOT" : ""}`}>
                                    <div className="ZoneMainContainerTopToolbarItemLabel">Min score:</div>
                                    <div className="ZoneMainContainerTopToolbarItemControl">
                                        <InputNumber
                                            min={0}
                                            max={1}
                                            size="small"
                                            value={selectedDetectionMinScore}
                                            precision={2}
                                            step={0.1}
                                            onChange={(value) => setSelectedDetectionMinScore(value)}
                                            formatter={(v) => `${v * 100}%`}
                                            parser={(v) => v.replace(/[^0-9.]/g, "")}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="RightRow">
                            <div className="ZoneMainContainerTopToolbarItem">
                                <Button
                                    disabled={!mapDetectionsData.length || !showDetectionsOnTheMap}
                                    size="small"
                                    icon="unordered-list"
                                    onClick={() => setDetectionsDrawerVisible(!detectionsDrawerVisible)}>
                                    Show List
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="MapContainer">
                        {loadingData && (
                            <div className="MapContainerLoading">
                                <OBCSpinner
                                    size={70}
                                    speed={3}
                                    color={"#e8dfff"}
                                />
                            </div>
                        )}
                        <Drawer
                            title="List of detections"
                            placement="right"
                            width={500}
                            mask={false}
                            className="DetectionsDrawerMain"
                            onClose={() => setDetectionsDrawerVisible(false)}
                            visible={detectionsDrawerVisible}>
                            <div className="DetectionsDrawerContent">
                                {/* <div className="TopBar"> Leaving it here as potentially we'll want to add search bar or some kind of filters to this list... </div> */}
                                <div className="ListContent">
                                    {_.map(reversedGroupedDetectionsBySessionIDList, (sessionID) => {
                                        return (
                                            <div
                                                key={sessionID}
                                                className="DetectionsListItemGrouped">
                                                <div className="DetectionsListItemTitle">#{sessionID}</div>
                                                <DetectionPerSessionItem
                                                    detections={groupedDetectionsBySessionID[sessionID]}
                                                    setSelectedDetectionData={setSelectedDetectionData}
                                                    setCustomLine={setCustomLine}
                                                    selectedDetectionData={selectedDetectionData}
                                                    customHoveredLineGeoJSON={customHoveredLineGeoJSON}
                                                    setCustomHoveredLineGeoJSON={setCustomHoveredLineGeoJSON}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Drawer>
                        <Map
                            ref={map}
                            className="AdminMap"
                            maxZoom={18}
                            center={[52.9, -1.1743]}
                            zoom={6}
                            onClick={handleMapClick}
                            interactive={true}
                            onZoomend={handleZoomChanged}>
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {currentlyLoadingDetectionsList.length ? (
                                <div className="BehaviourZoneManagementAlert">
                                    {_.map(currentlyLoadingDetectionsList, (detections) => {
                                        return (
                                            <Alert
                                                key={detections}
                                                message={
                                                    <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
                                                        <LoadingOutlined style={{ marginTop: "2px" }} /> <div>Loading {detections} detections...</div>
                                                    </div>
                                                }
                                                type="info"
                                            />
                                        );
                                    })}
                                </div>
                            ) : null}

                            {showDetectionsOnTheMap ? (
                                <div className="BehaviourZoneManagementCounter">
                                    <Alert
                                        type="warning"
                                        message={
                                            mapDetectionsData.length ? (
                                                <div>
                                                    Detections count:{" "}
                                                    <Badge
                                                        overflowCount={5000}
                                                        style={{ background: "#59c22a", marginBottom: "4px" }}
                                                        count={mapDetectionsData.length}
                                                    />
                                                </div>
                                            ) : (
                                                "No detections to display"
                                            )
                                        }
                                    />
                                </div>
                            ) : null}

                            <FeatureGroup>
                                <EditControl
                                    ref={toolBar}
                                    draw={{
                                        polygon: false,
                                        marker: false,
                                        polyline: false,
                                        rectangle: false,
                                        circle: false,
                                        circlemarker: false,
                                    }}
                                    edit={{
                                        remove: false,
                                        edit: false,
                                    }}
                                />
                                {_.map(mapData, renderZone)}
                                {detectionsComponent}
                            </FeatureGroup>
                            <LayerGroup>
                                <GeoJSON
                                    key={`contamination-lines-${_.get(mapDetectionsGeoJSON, "features", []).length}`} // using a key here like this to force rerender
                                    data={mapDetectionsGeoJSON}
                                    style={(feature) => getSelectedLineColors(feature, showDetectionsOnTheMap, currentMapZoom)}
                                />
                                {/* line on hover (mainly Low Adhesion) */}
                                <GeoJSON
                                    key={`selected-line-${hoveredDetectionID}-${_.get(selectedDetectionGeoJSON, ["geometry", "coordinates"], []).length}`} // using a key here like this to force rerender
                                    data={selectedDetectionGeoJSON}
                                    style={{ color: "#ffb306", weight: 6 }}
                                />

                                {/* custom line  */}
                                <GeoJSON
                                    key={`custom-selected-line-${selectedDetectionData?.id}-${_.get(customLineGeoJSON, ["geometry", "coordinates"], []).length}`} // using a key here like this to force rerender
                                    data={customLineGeoJSON}
                                    style={{ color: "#6C43DF", weight: 6 }}
                                />
                            </LayerGroup>
                            {!_.isEmpty(allCirclesInClickedPoint) && !editingPosition && renderZoneSelectionPopup}
                        </Map>
                    </div>
                    <div className="ControlsContainer">
                        {editingPosition ? (
                            <React.Fragment>
                                <Button
                                    className="behaviourMapButtons"
                                    onClick={cancelPositionChange}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    className="behaviourMapButtons"
                                    onClick={savePositionChanges}>
                                    Save Position
                                </Button>
                            </React.Fragment>
                        ) : (
                            <Button
                                className="behaviourMapButtons"
                                onClick={() => addBehaviourZone(null)}>
                                Add Zone
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BehaviourZoneManagement;
