import React from "react";

const OBCProgressBar = ({ ...props }) => {
    const barWidth = props.progress * 100;
    const progressStyle = {
        maxWidth: "100%",
        width: `${barWidth}%`,
    };

    // className, progress, lableVisible

    return (
        <div className={`OBCProgressBar ${props.className}`}>
            <div
                className="OBCProgressBarInner"
                style={progressStyle}></div>
            {props.lableVisible && (props.customLabel ? <span>{props.customLabel}%</span> : <span>{barWidth}%</span>)}
        </div>
    );
};

OBCProgressBar.defaultProps = {
    className: false,
    progress: 0.4,
    lableVisible: true,
    customLabel: false,
};

export default OBCProgressBar;
