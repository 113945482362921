import React, { useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";

const sessionSelector = (state) => _.get(state.sessions, [state.playlist.data.routeID], []);
const playlistSelector = (state) => state.playlist.data.video;
const csrfTokenSelector = (state) => state.csrfToken;

const SourceModalItem = ({ idx, handleSourceClick, sourcesSelected }) => {
    const session = useSelector(sessionSelector);
    const playlist = useSelector(playlistSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    const device = session.device_uuid;
    const uuid = session.uuid;
    const path = playlist[idx][0][0];
    const frame = "1";

    let url = `https://raw${MEMOIZED_DOMAIN_URL}/` + device + "/" + uuid + "/" + path + "-" + frame + ".jpg";
    if (csrfToken) {
        url += `?csrf=${csrfToken}`;
    }

    const itemSelected = useMemo(() => {
        return sourcesSelected.includes(idx);
    }, [sourcesSelected, idx]);

    return (
        <div
            className={`inspectRail__Modal__Source ${itemSelected ? "selected" : ""}`}
            key={idx}
            onClick={() => {
                handleSourceClick(idx);
            }}>
            <img
                src={url}
                width="200"
                className="inspectRail__Modal__Source__Preview"
                alt="No Preview Available"
                crossOrigin={"anonymous"}
            />
        </div>
    );
};

export default SourceModalItem;
