import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShortcutSession, getSessionData, changeToolMode, routeSelected } from "redux/actions";
import { Layout, Button, Row, Col } from "antd";
import MapComponent from "components/map/MapComponent";
import VideoOrImageComponent from "components/display/VideoOrImageComponent";
import { calculateRouteCoordinatesForLocation } from "components/util/Geometry";
import { PositionalInfo } from "components/route/RouteInformation";
import DtAnnotationComponent from "./DtAnnotationComponent";
// import aivrLogo from "../../../../../src/images/new-branding-2.0/obc-hybrid.svg"
import aivrLogo from "../../../../../src/images/new-branding-2.0/aivr-hybrid.svg";
import _ from "lodash";
// getAbsoluteTimestamp
import { getAbsoluteTimestamp } from "components/util/PlaylistUtils";
import { setCurrentPage } from "redux/actions/driverTrainingActions";
import OBCSpinner from "components/util/OBC";
import OBCButton from "components/OBC/OBCButton";

const { Content } = Layout;

const selectedSessionSelector = (state) => state.driverTraining.trainee.selectedSession;
// const playlistPositionSelector = state => state.playlist.position;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const annotationToolModeSelector = (state) => state.markup.tool_mode;
const currentRouteIDSelector = (state) => _.get(state.playlist.data, "routeID", null);
const routeSystemIDSelector = (state) => state.playlist.data.system_id;

const DtTrainingSessionPlayer = ({ selectedTrainingSession }) => {
    const dispatch = useDispatch();
    const [sessionData, setSessionData] = useState([]);
    const [annotatingEnabled, setAnnotatingEnable] = useState(false);
    const [loadMap, setLoadMap] = useState(false);

    const selectedSession = useSelector(selectedSessionSelector);
    const currentRouteID = useSelector(currentRouteIDSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);

    const timestampSelector = (state) => {
        const routeID = state.playlist.data.routeID;
        const isVideo = state.playlist.position.isVideo;
        const sourceIndex = state.playlist.position.sourceIndex;
        const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], []) : state.playlist.data.image;
        const index = state.playlist.position.currentIndex;
        const offset = state.playlist.position.currentTimeOffset || 0;
        return getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);
    };

    // const playlistPosition = useSelector(playlistPositionSelector) || null;
    const routeLocationData = useSelector(routeLocationSelector);
    const annotationToolMode = useSelector(annotationToolModeSelector);
    const timestamp = useSelector(timestampSelector);

    const goBackToSessionList = () => {
        // dispatch(selectShortcutSession(null, null));
        dispatch(setCurrentPage("sessions_main"));
        dispatch(routeSelected(null));

        // setCurrentPage("sessions_main")
    };

    const routeCoordinatesForSystem = useMemo(() => {
        let timestampToUse = timestamp ?? [];
        let location = calculateRouteCoordinatesForLocation(timestampToUse, routeLocationData, routeSystemID);
        if (location) {
            return location;
        } else {
            return [];
        }
    }, [timestamp, routeLocationData, routeSystemID]);

    useEffect(() => {
        if (selectedSession && selectedSession.session_id && currentRouteID !== selectedSession.session_id) {
            dispatch(routeSelected(selectedSession.session_id, null));
            setLoadMap(true);
            // setTimeout(() => {
            // }, 2000);
        }
    }, [currentRouteID, dispatch, selectedSession]);

    // below to get annotations for session
    // useEffect(() => {
    //   console.log('debug selectedSession', selectedSession);
    //   dispatch(getSessionData(selectedSession))
    //     .then(sessionData => {
    //       // console.log('debug sessionData', sessionData);
    //       setSessionData(sessionData);
    //     })
    // }, [dispatch, selectedSession, sessionData]);

    const enableAnnotation = () => {
        if (!annotatingEnabled) {
            dispatch(changeToolMode("annotate"));
        } else {
            dispatch(changeToolMode(null));
        }
    };

    useEffect(() => {
        console.log("debug annotationToolMode");

        if (!annotationToolMode) {
            setAnnotatingEnable(false);
        } else {
            setAnnotatingEnable(true);
        }
    }, [annotationToolMode]);

    const renderMap = useMemo(() => {
        if (loadMap) {
            return <MapComponent stripped="driver_training" />;
        } else {
            return (
                <OBCSpinner
                    className="DtMapSpinner"
                    size={50}
                    speed={3}
                    color={"#e8dfff"}
                />
            );
        }
    }, [loadMap]);

    return (
        <Content className="SessionPlayerPage">
            <div className="SessionPlayerHeader">
                <h3>{selectedSession.description ? selectedSession.description : selectedSession.route_name}</h3>
                <div className="FlexRow">
                    {/* <Button ghost onClick={() => enableAnnotation()}>{annotatingEnabled ? "Close Annotation" : "Annotate Session"}</Button> */}
                    <OBCButton
                        variant="success"
                        onClick={() => goBackToSessionList()}>
                        Save & Exit
                    </OBCButton>
                </div>
            </div>
            <div className="ManiContent">
                <div className="LeftColumn">
                    <div className={`VideoPlayerContainer ${annotatingEnabled && "enableToolbar"}`}>
                        <VideoOrImageComponent />
                    </div>
                    <div className={`VideoControlsContatiner ${annotatingEnabled && "enableToolbarMargin"}`}>
                        <div className="ControlsTitle">
                            <h4>Additional info & controls</h4>
                        </div>
                        <div className="ControlsColumn">
                            <img
                                className="LogoOverlayImage"
                                src={aivrLogo}
                                width={100}
                                alt=""
                                crossOrigin={"anonymous"}
                            />
                            <div className="ControlsItem">
                                <h5>ELR (ESTIMATE)</h5>
                                <Row
                                    className="ElrRow"
                                    type="flex"
                                    justify="start"
                                    gutter={[16, 0]}>
                                    <Col>
                                        <b>ELR: </b>
                                        {routeCoordinatesForSystem.elr ?? "---"}
                                    </Col>
                                    <Col>
                                        <b>MILE: </b>
                                        {routeCoordinatesForSystem.mile ?? "---"}
                                    </Col>
                                    <Col>
                                        <b>CHAIN: </b>
                                        {routeCoordinatesForSystem.chain ?? "---"}
                                    </Col>
                                    <Col>
                                        <b>TRACK: </b>
                                        {routeCoordinatesForSystem.track ?? "---"}
                                    </Col>
                                </Row>
                            </div>
                            <div className="ControlsItem">
                                <h5>SPEED, BEARING & ALTITUDE</h5>
                                <PositionalInfo />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RightColumn">
                    <DtAnnotationComponent />
                    <div className="MapContaioner">{renderMap}</div>
                </div>
            </div>
        </Content>
    );
};

export default DtTrainingSessionPlayer;
