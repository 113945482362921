import React from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { Redirect, withRouter } from "react-router-dom";

class FindSetup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initialised: false,
        };
    }

    getQueryParams = () => {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        return params;
    };

    render() {
        let searchType = null;
        let paramOne = null;
        let paramTwo = null;
        let paramThree = null;
        let filterParams = {};
        let autoplay = false;

        if (this.props.match.params.type) {
            searchType = this.props.match.params.type;
            paramOne = this.props.match.params.param1;
            paramTwo = this.props.match.params.param2;
            paramThree = this.props.match.params.param3;
            const queryParams = this.getQueryParams();

            filterParams = {
                timestamp: queryParams.get("timestamp"),
                deviceKey: queryParams.get("device_key"),
            };

            if (this.props.match.params.type === "pulse") {
                filterParams = {
                    elr: queryParams.get("elr"),
                    mile: queryParams.get("mile"),
                    yard: queryParams.get("yard"),
                };
            }

            autoplay = queryParams.get("autoplay") === "1";
        }

        if (!this.props.access_token) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {
                            from: this.props.location,
                            searchType,
                            paramOne,
                            paramTwo,
                            paramThree,
                            filterParams,
                        },
                    }}
                />
            );
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/",
                        state: {
                            searchType,
                            paramOne,
                            paramTwo,
                            paramThree,
                            filterParams,
                            autoplay,
                        },
                    }}
                />
            );
        }
    }
}

const mapStateToProps = (state) => {
    return { access_token: state.access_token };
};

export default connect(mapStateToProps)(withRouter(FindSetup));
