import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSessionData, getShortcutSessions, selectShortcutSession } from "../../../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "./DtProgressBar";
import _ from "lodash";
// import moment from 'moment';
// import mapImg from "../temps/map1.png"
import ContentLoader from "react-content-loader";
import { Carousel } from "antd";
import OBCSpinner from "components/util/OBC";
import { getTrainingCategoryData, setSelecteCategoryData } from "redux/actions/driverTrainingActions";
import moment from "moment";
import { MEMOIZED_DOMAIN_URL } from "../../../util/HostUtils";
// const shortcutSessionsSelector = state => state.shortcuts.sessions;
const currentCategoryIdSelector = (state) => _.get(state.driverTraining.trainee.selectedTraineeCategoryData, "category_id", null);
const sessionsSelector = (state) => _.get(state, "sessions", []);
const csrfTokenSelector = (state) => state.csrfToken;

const ContentSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"100%"}
        viewBox="0 0 780 155"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175"
        {...props}>
        <rect
            x="10"
            y="15"
            rx="3"
            ry="3"
            width="500"
            height="45"
        />
        <rect
            x="10"
            y="72"
            rx="3"
            ry="3"
            width="313"
            height="20"
        />
        <rect
            x="10"
            y="102"
            rx="3"
            ry="3"
            width="700"
            height="17"
        />
    </ContentLoader>
);

const ImageSkeleton = () => (
    <div className="LoadingPictureContainer">
        <OBCSpinner
            size={30}
            speed={3}
            color={"#e8dfff"}
        />
    </div>
);

// const getSessionDuration = (duration) => {
//     let formattedDuration = "00h 01min";
//     if (duration > 0) {
//         formattedDuration = moment.utc(duration * 1000).format("HH[h] mm[min]");
//         return formattedDuration;
//     }
//     return formattedDuration;
// }

const DtCategoryListItem = ({ category }) => {
    const [thumbPictures, setThumbPictures] = useState([]);
    const [thunbsLoading, setThunmbsLoading] = useState(true);
    const dispatch = useDispatch();
    const currentCategoryID = useSelector(currentCategoryIdSelector);
    const sessions = useSelector(sessionsSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    // when component created fetch sessions data
    useEffect(() => {
        _.forEach(category.sessions, (sessionId) => {
            dispatch(getSessionData(sessionId));
        });
    }, [category, dispatch]);

    const addToThumbnails = (url) => {
        setThumbPictures((thumbPictures) => [...thumbPictures, url]);
    };

    const sessionThumbnail = (sessionData) => {
        let device = sessionData.device_uuid;
        let uuid = sessionData.uuid;
        if (device && uuid) {
            let url = `https://raw${MEMOIZED_DOMAIN_URL}/` + device + "/" + uuid + "/thumb.sml.jpg";

            if (csrfToken) {
                url += `?csrf=${csrfToken}`;
            }
            return url;
        } else {
            return null;
        }
    };

    const filteredSessionList = useMemo(() => {
        let list = {};
        if (Object.keys(sessions).length && thumbPictures.length < 2) {
            list = _.pickBy(sessions, (value, key) => _.includes(category.sessions, String(key)));
        }
        return list;
    }, [category.sessions, sessions, thumbPictures.length]);

    useEffect(() => {
        if (Object.keys(filteredSessionList).length > 0) {
            setThumbPictures([]);
            _.map(filteredSessionList, (session) => {
                const thumbnail = sessionThumbnail(session);
                if (thumbnail) {
                    addToThumbnails(thumbnail);
                }
            });
        }
    }, [filteredSessionList]);

    useEffect(() => {
        if (thumbPictures.length > 0) {
            setThunmbsLoading(false);
        }
    }, [thumbPictures]);

    const renderImages = useMemo(() => {
        return thumbPictures.map((url, index) => (
            <div key={index}>
                <img
                    src={url}
                    alt="preview"
                    crossOrigin={"anonymous"}
                />
            </div>
        ));
    }, [thumbPictures]);

    const loadTraningSessions = (category) => {
        // dispatch(setCurrentPage())
        console.log("debug selected category ID", category);
        if (category.sessions && category.sessions.length) {
            dispatch(setSelecteCategoryData(category));
        }
    };

    const calculateDaysFromNow = (timestamp) => {
        const fromDate = moment();
        const toDate = moment.unix(timestamp);
        return fromDate.diff(toDate, "days");
    };

    const calculateTimeFromDate = (timestamp) => {
        const lastUpdate = moment.unix(timestamp);
        return lastUpdate.fromNow();
    };

    const getTraningCategoryStatus = (isRecentlyAdded, daysFromLastUpdate, progress) => {
        if (progress === 1) {
            return "completed";
        }
        if (isRecentlyAdded) {
            return "new";
        }
        if (daysFromLastUpdate !== null && daysFromLastUpdate < 7) {
            return "updated";
        }
        return null;
    };

    const renderBadgeLabel = (isRecentlyAdded, daysFromLastUpdate, progress) => {
        if (progress === 1) {
            return "Completed";
        }
        if (isRecentlyAdded) {
            return "New";
        }
        if (daysFromLastUpdate !== null && daysFromLastUpdate < 7) {
            return "Updated";
        }
        return null;
    };

    const daysFromLastUpdate = category.category_last_update_ts ? calculateDaysFromNow(category.category_last_update_ts) : null;
    const isRecentlyAdded = calculateDaysFromNow(category.category_created_ts) < 7;

    return (
        <div
            className={`TrainingListItem ${currentCategoryID === category.category_id ? "selected" : ""} ${category.sessions && category.sessions.length ? "" : "disabled"}`}
            onClick={() => loadTraningSessions(category)}
            title={category.sessions && category.sessions.length ? null : "No sessions in this category"}>
            {getTraningCategoryStatus(isRecentlyAdded, daysFromLastUpdate, category.progress) && (
                <div className={`Badge Badge-${renderBadgeLabel(isRecentlyAdded, daysFromLastUpdate, category.progress)}`}>
                    {renderBadgeLabel(isRecentlyAdded, daysFromLastUpdate, category.progress)}
                </div>
            )}
            <div className="TrainingListItemTop">
                <div className="TrainingListItemContent">
                    <div className="TitleLabel">
                        <span>{category.category_name}</span>
                    </div>
                    {/* <div className="DateInfo">
                        <div className="title">Added:</div>
                        <div className="value">{moment.unix(category.category_created_ts).format("Do MMM YYYY")}</div>
                    </div> */}
                    <div className="DateInfo">
                        <div className="title">Updated:</div>
                        <div className="value">
                            {category.category_last_update_ts
                                ? calculateTimeFromDate(category.category_last_update_ts)
                                : calculateTimeFromDate(category.category_created_ts)}
                        </div>
                    </div>
                    <div className="DateInfo">
                        <div className="title">Expiry date:</div>
                        <div className="value">{moment.unix(category.category_required_by_ts).format("Do MMM YYYY")}</div>
                    </div>

                    {/* <div className="ConditionsTags">
                        <span>
                            <FontAwesomeIcon icon={faSun} />
                            {["Day", "Forward", "Rain"].join(", ")}
                        </span>
                    </div> */}

                    {/* <div className="LastUpdatedLabel"><span>Updated: {Math.floor(Math.random() * 20)} Days ago</span></div> */}
                </div>

                {category.sessions && category.sessions.length ? (
                    <div className="TrainingListItemThumbnailCarousel">
                        <Carousel autoplay>{thunbsLoading ? <ImageSkeleton /> : renderImages}</Carousel>
                    </div>
                ) : (
                    <div className="TrainingListItemNoSessions">No sessions</div>
                )}
            </div>
            <div className="TrainingListItemBottom">
                <ProgressBar
                    className="ListItemContentProgressBar"
                    progress={_.get(category, "progress", 0)}
                    textVisible={false}
                />
            </div>
        </div>
    );
};

export default DtCategoryListItem;
