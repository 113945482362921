import React from "react";
import "../../mobile.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { currentPlaylistPosition, getSessionData, logTiming, togglePlayerState } from "redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faPlay, faPause, faDesktop } from "@fortawesome/free-solid-svg-icons";
import MobileImagePlayer from "./MobileImagePlayer";
import MobileScrubber from "./MobileScrubber";
import {
    absoluteTimeLookup,
    asyncLoadImage,
    getCoordinates,
    getInterpolatedPosition,
    getOffsetAdjustedPosition,
    getOffsetAdjustedTime,
    interpolate,
    makePromiseCancelable,
} from "../util/PlaylistUtils";
import MobileMap from "./MobileMap";
import { convertToTimezone } from "../util/TimezoneUtils";

class MobilePlayerPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.imageURLToDisplay = null;
        this.imageCache = {};
        this.imageCacheOrder = [];
        this.videoPausedTimer = null;
        this.nextFrameTimer = null;
        this.imageLoadPromises = {};

        this.state = {
            index: 0,
            subIndex: 1,
            loading: false,
            timeOfLastRouteChange: 0,
            imagesViewed: 0,
            enhancedImagesViewed: 0,
            imageIsEquirectangular: false,
            loadedImage: null,
            playing: false,
            markers: {},
            playSpeed: 1.0,
            mapPosition: {
                zoom: 7,
                lat: 52.9,
                lng: -1.1743,
            },
        };
    }

    componentDidMount() {
        const sessionID = _.get(this.props, ["match", "params", "sessionID"], null);
        if (sessionID) {
            this.props.dispatch(getSessionData(sessionID));
        }
        this.componentDidUpdate({}, {});
        this.setState({
            timeOfLastRouteChange: new Date().getTime(),
        });
    }

    componentWillUnmount() {
        // resolved promises should already have been removed
        for (const key in this.imageLoadPromises) {
            if (this.imageLoadPromises.hasOwnProperty(key)) {
                const promise = this.imageLoadPromises[key];
                promise.cancel();
            }
        }
    }

    updateMapPosition = (positionObj) => {
        this.setState({
            mapPosition: positionObj,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevProps.routeID && prevProps.routeID !== this.props.routeID) {
        //     this.recordTimeSpent(prevProps.routeID);
        // } else if (prevProps.routeID && prevProps.isEnhanced !== this.props.isEnhanced) {
        //     this.recordTimeSpentInMode(prevProps.routeID);
        // }

        if (prevProps.imageKeys && !prevProps.imageKeys.length && this.props.imageKeys && this.props.imageKeys.length) {
            const targetKey = this.props.imageKeys[0];
            let offsetTime = getOffsetAdjustedTime(targetKey[1], this.props.imageKeys);
            let interpolatedPosition = getInterpolatedPosition(offsetTime, this.props.imageKeys, this.props.use_snapped);
            this.props.dispatch(currentPlaylistPosition(0, interpolatedPosition, 0));
        }

        if (this.state.playing && this.state.playSpeed && !prevState.playSpeed) {
            const timeToNextFrame = 1000 / this.state.playSpeed;
            this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
        }

        if (this.props.imageKeys && this.props.imageKeys.length) {
            let forceUpdate = false;

            if (
                !(prevProps.imageKeys && prevProps.imageKeys.length) ||
                prevProps.routeID !== this.props.routeID ||
                prevProps.isEnhanced !== this.props.isEnhanced
            ) {
                this.clearImageCache();
                forceUpdate = true;
            } else if (this.props.imageKeys !== prevProps.imageKeys) {
                // grab current index and sub index from this.state
                let targetKey = prevProps.imageKeys[this.state.index];
                let currentTime = 0;

                if (targetKey) {
                    // convert into timestamp
                    const frameCount = targetKey[5];
                    const duration = targetKey[2];
                    let timeOffset = 0;
                    let ratio = 0;
                    if (frameCount) {
                        ratio = (this.state.subIndex - 1) / frameCount;
                        timeOffset = ratio * duration;
                    }
                    currentTime = targetKey[3][2] + timeOffset;
                }
                //get same absolute time point in new source
                const newIndex = absoluteTimeLookup(currentTime, this.props.imageKeys);
                const newTargetKey = this.props.imageKeys[newIndex];

                if (newTargetKey) {
                    const newFrameCount = newTargetKey[5];
                    const newDuration = newTargetKey[2];

                    const newTimeOffset = currentTime - newTargetKey[3][2];
                    const newRatio = newTimeOffset / newDuration;

                    let newSubIndex = newRatio * newFrameCount;
                    newSubIndex = Math.floor(newSubIndex) + 1;
                    if (newSubIndex < 1) {
                        newSubIndex = 1;
                    } else if (newSubIndex > newFrameCount) {
                        newSubIndex = newFrameCount;
                    }
                    this.selectIndex(newIndex, newSubIndex, true);
                } else {
                    this.selectIndex(0, 1, true);
                }
            }

            if (forceUpdate || this.props.position !== prevProps.position || this.props.requestedTimeOffset !== prevProps.requestedTimeOffset) {
                if (this.props.isStills) {
                    let targetKey = this.props.imageKeys[this.props.position];
                    if (targetKey) {
                        const frameCount = targetKey[5];
                        let subIndex = 0;
                        if (frameCount) {
                            const duration = targetKey[2];
                            const ratio = this.props.requestedTimeOffset / duration;
                            if (ratio >= 1) {
                                subIndex = frameCount;
                            } else if (ratio <= 0) {
                                subIndex = 1;
                            } else {
                                subIndex = Math.floor(ratio * frameCount) + 1;
                            }
                        }
                        this.selectIndex(this.props.position, subIndex, forceUpdate);
                    } else {
                        this.selectIndex(this.props.position, 0, forceUpdate);
                    }
                } else {
                    this.selectIndex(this.props.position, 1, forceUpdate);
                }
            } else if (this.state.index !== prevState.index || this.state.subIndex !== prevState.subIndex) {
                let targetKey = this.props.imageKeys[this.state.index];
                if (targetKey) {
                    clearTimeout(this.videoPausedTimer);
                    this.props.dispatch(togglePlayerState("playing"));
                    this.videoPausedTimer = setTimeout(() => {
                        this.props.dispatch(togglePlayerState("paused"));
                    }, 1200);

                    const frameCount = targetKey[5];
                    const duration = targetKey[2];
                    let timeOffset = 0;
                    let ratio = 0;
                    if (frameCount) {
                        ratio = (this.state.subIndex - 1) / frameCount;
                        timeOffset = ratio * duration;
                    }
                    let currentTime = targetKey[1] + timeOffset;

                    let position = getOffsetAdjustedPosition(currentTime, this.props.imageKeys, this.props.offsets, this.props.use_snapped);

                    if (position !== null && position[2] !== null) {
                        let playlistIndex = position[0];
                        let timeOffset = position[1];
                        let coords = position[2];
                        this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
                    } else {
                        let interpolatedPosition;
                        let nextTargetKey = this.props.imageKeys[this.state.index + 1];
                        if (nextTargetKey) {
                            let start = getCoordinates(targetKey[3], this.props.use_snapped);
                            let end = getCoordinates(nextTargetKey[3], this.props.use_snapped);
                            interpolatedPosition = interpolate(start, end, ratio);
                        } else {
                            interpolatedPosition = targetKey[3];
                        }
                        this.props.dispatch(currentPlaylistPosition(this.state.index, interpolatedPosition, timeOffset));
                    }
                }
            }
        }
    }

    clearImageCache = () => {
        this.imageCache = {};
        this.imageCacheOrder = [];
    };

    isInCache = (imageURL) => {
        return this.imageCache.hasOwnProperty(imageURL);
    };

    imageIndexChanged = (newIndex) => {
        this.selectIndex(newIndex, 1);
    };

    selectIndex = (newIndex, subIndex, force = false) => {
        if (newIndex >= 0 && newIndex < this.props.imageKeys.length && (newIndex !== this.state.index || subIndex !== this.state.subIndex || force)) {
            console.log("Selecting index:", newIndex, ", subindex:", subIndex);
            this.setState({
                index: newIndex,
                subIndex,
                loading: true,
                imagesViewed: this.state.imagesViewed + (this.props.isEnhanced ? 0 : 1),
                enhancedImagesViewed: this.state.enhancedImagesViewed + (this.props.isEnhanced ? 1 : 0),
            });
            this.preload(newIndex, subIndex, true);
        }
    };

    preload = (index, subIndex, small) => {
        console.log("Preload...");
        if (index !== -1) {
            let selectedKey = this.props.imageKeys[index];
            if (selectedKey) {
                const imageUrlData = this.getUrlDataForImage(index, subIndex, small);
                if (!imageUrlData || !imageUrlData.url) {
                    return;
                }

                let isEquirectangular;
                if (this.props.isStills) {
                    isEquirectangular = false;
                    small = false;
                } else {
                    isEquirectangular = selectedKey[0].startsWith("eq_");
                }

                const imageCacheKey = imageUrlData.cacheKey;
                this.imageURLToDisplay = imageCacheKey;
                const cancelableLoadImage = makePromiseCancelable(this.loadImage(imageUrlData));

                this.imageLoadPromises[imageCacheKey] = cancelableLoadImage;
                cancelableLoadImage.promise
                    .then((image_data) => {
                        if (image_data && this.imageURLToDisplay === imageCacheKey) {
                            delete this.imageLoadPromises[imageCacheKey];
                            this.imageLoadComplete(index, subIndex, small, isEquirectangular, image_data);
                        }
                    })
                    .catch(() => {});
            }
        }
    };

    getUrlDataForImage = (index, subIndex, small) => {
        if (index !== -1) {
            let selectedKey = this.props.imageKeys[index];
            if (selectedKey) {
                let imageKey = selectedKey[0];
                let stillsStride = _.get(selectedKey, [6], null);

                let imageFile = imageKey;
                let range = null;

                if (this.props.isStills) {
                    if (stillsStride !== null && stillsStride > 0) {
                        imageFile = `${imageKey}.stills.txt`;
                        const frame = subIndex;
                        const startRange = stillsStride * (frame - 1);
                        const endRange = startRange + stillsStride - 1;
                        range = {
                            start: startRange,
                            end: endRange,
                        };
                    } else if (selectedKey[5]) {
                        imageFile += `-${subIndex}.jpg`;
                    }
                } else {
                    if (this.props.isEnhanced === "enhanced") {
                        imageFile += ".enh.jpg";
                    }

                    if (small) {
                        imageFile += ".sml.jpg";
                    }
                }

                let url = this.props.baseURL + imageFile;
                if (this.props.csrfToken) {
                    url += `?csrf=${this.props.csrfToken}`;
                }

                return {
                    url,
                    range,
                    cacheKey: `${url}.${subIndex}`,
                };
            }
        }
        return null;
    };

    recordTimeSpent = (lastRouteID) => {
        const now = new Date().getTime();
        logTiming("Images", "Time Spent Open", now - this.state.timeOfLastRouteChange, lastRouteID);
        this.setState({
            timeOfLastRouteChange: now,
        });
        this.recordTimeSpentInMode(lastRouteID);
    };

    recordTimeSpentInMode = (lastRouteID) => {
        if (this.state.imagesViewed) {
            logTiming("Images", "Images Viewed", this.state.imagesViewed, lastRouteID);
        }
        if (this.state.enhancedImagesViewed) {
            logTiming("Images", "Enhanced Images Viewed", this.state.enhancedImagesViewed, lastRouteID);
        }
        this.setState({
            imagesViewed: 0,
            enhancedImagesViewed: 0,
        });
    };

    formatTime = (sliderIndex) => {
        let playlistItem = this.props.imageKeys[sliderIndex];
        let displayTime = 0;
        let timeTooltip = "Unknown";
        if (playlistItem) {
            if (this.props.isStills) {
                if (playlistItem[3]) {
                    displayTime = playlistItem[3][2];
                }
            } else {
                if (playlistItem[1]) {
                    displayTime = playlistItem[1][2];
                }
            }
        }

        if (displayTime > 0) {
            const dpDate = new Date(displayTime * 1000);
            timeTooltip = convertToTimezone(dpDate, this.props.userConfig.convert_to_utc);
        }

        return timeTooltip;
    };

    imageLoadComplete = (index, subIndex, small, isEquirectangular, image_data) => {
        let newState = {
            imageIsEquirectangular: isEquirectangular,
            loadedImage: image_data,
            loading: false,
        };

        this.setState(newState, () => {
            if (small) {
                this.preload(index, subIndex, false);
            } else {
                let precache;
                if (this.props.isStills) {
                    precache = ({ index, subIndex }) => {
                        const frameData = this.getUrlDataForImage(index, subIndex, false);
                        if (frameData && !this.isInCache(frameData.cacheKey)) {
                            this.precacheImage(frameData).catch(() => {});
                        }
                    };
                } else {
                    precache = ({ index, subIndex }) => {
                        const smallFrameUrlData = this.getUrlDataForImage(index, subIndex, true);
                        const largeFrameUrlData = this.getUrlDataForImage(index, subIndex, false);

                        if (smallFrameUrlData && !this.isInCache(smallFrameUrlData.cacheKey)) {
                            this.precacheImage(smallFrameUrlData)
                                .catch(() => {})
                                .then(() => {
                                    if (largeFrameUrlData && !this.isInCache(largeFrameUrlData.cacheKey)) {
                                        this.precacheImage(largeFrameUrlData).catch(() => {});
                                    }
                                });
                        } else if (largeFrameUrlData && !this.isInCache(largeFrameUrlData.cacheKey)) {
                            this.precacheImage(largeFrameUrlData).catch(() => {});
                        }
                    };
                }

                precache(this.adjust(index, subIndex, 1));
                precache(this.adjust(index, subIndex, -1));
                precache(this.adjust(index, subIndex, 60));
                precache(this.adjust(index, subIndex, -60));

                precache(this.getIndexByDirection(1));
                precache(this.getIndexByDirection(-1));
            }
        });
    };

    getIndexByDirection(direction) {
        let nextIndex;
        let nextSubIndex;

        if (this.props.isStills) {
            nextIndex = this.state.index;
            nextSubIndex = this.state.subIndex + direction;

            const playlistItem = this.props.imageKeys[nextIndex];
            if (!playlistItem[5] || nextSubIndex > playlistItem[5]) {
                nextIndex += direction;
                nextSubIndex = 1;
            } else if (nextSubIndex <= 0) {
                if (nextIndex > 0) {
                    nextIndex -= 1;
                    const playlistItem = this.props.imageKeys[nextIndex];
                    if (playlistItem[5]) {
                        nextSubIndex = playlistItem[5];
                    } else {
                        nextSubIndex = 1;
                    }
                } else {
                    nextIndex = 0;
                    nextSubIndex = 1;
                }
            }
        } else {
            nextIndex = Math.max(0, this.state.index + direction);
            nextSubIndex = 1;
        }
        return { index: nextIndex, subIndex: nextSubIndex };
    }

    forwardByTime(time) {
        let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, time);
        this.selectIndex(index, subIndex);
    }

    backwardByTime(time) {
        let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, -time);
        this.selectIndex(index, subIndex);
    }

    precacheImage = (imageUrlData) => {
        const imageCacheKey = imageUrlData.cacheKey;
        const imageURL = imageUrlData.url;
        const imageRange = imageUrlData.range;

        const imageLoadCallback = (imageData) => {
            this.addToCache(imageCacheKey, imageData);
        };
        return asyncLoadImage(imageURL, imageRange, imageLoadCallback);
    };

    loadImage = (imageUrlData) => {
        const imageCacheKey = imageUrlData.cacheKey;

        if (this.isInCache(imageCacheKey)) {
            return new Promise((resolve) => {
                resolve(this.getFromCache(imageCacheKey));
            });
        } else {
            return this.precacheImage(imageUrlData);
        }
    };

    getFromCache = (imageURL) => {
        return this.imageCache[imageURL];
    };

    togglePlaying = () => {
        if (this.state.playing) {
            clearTimeout(this.nextFrameTimer);
            this.setState({
                playing: false,
            });
        } else {
            this.setState({
                playing: true,
            });
            if (this.state.playSpeed !== 0) {
                const timeToNextFrame = 1000 / this.state.playSpeed;
                this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
            }
        }
    };

    nextFrame = (stepOrFastBack) => {
        if (stepOrFastBack === "fast") {
            let { index, subIndex } = this.getIndexByDirection(5);
            this.selectIndex(index, subIndex);
        } else {
            let { index, subIndex } = this.getIndexByDirection(1);
            this.selectIndex(index, subIndex);
        }
    };

    prevFrame = (stepOrFastBack) => {
        if (stepOrFastBack === "fast") {
            let { index, subIndex } = this.getIndexByDirection(-5);
            this.selectIndex(index, subIndex);
        } else {
            let { index, subIndex } = this.getIndexByDirection(-1);
            this.selectIndex(index, subIndex);
        }
    };

    playNext = () => {
        console.log("playNext...");
        if (this.state.playSpeed !== 0) {
            let { index, subIndex } = this.adjust(this.state.index, this.state.subIndex, 1);
            if (index !== this.state.index || subIndex !== this.state.subIndex) {
                this.selectIndex(index, subIndex);
                const timeToNextFrame = 1000 / this.state.playSpeed;
                this.nextFrameTimer = setTimeout(this.playNext, timeToNextFrame);
            } else {
                this.setState({
                    playing: false,
                });
            }
        }
    };

    adjust(index, subIndex, time) {
        let playlistItem = this.props.imageKeys[index];
        console.log("Getting new index for adjusting:", index, subIndex, "by time:", time);

        let frameCount = playlistItem[5];
        if (!frameCount) {
            frameCount = 1;
        }
        let frameDuration = playlistItem[2];

        let newIndex = index;

        const subIndexTimeOffset = ((subIndex - 1) / frameCount) * frameDuration;
        let targetTimeOffset = subIndexTimeOffset + time;
        while (targetTimeOffset < 0) {
            newIndex -= 1;
            if (newIndex < 0) {
                newIndex = 0;
                targetTimeOffset = 0;
                break;
            }
            playlistItem = this.props.imageKeys[newIndex];
            frameDuration = playlistItem[2];
            targetTimeOffset += frameDuration;
        }
        while (targetTimeOffset >= frameDuration) {
            targetTimeOffset -= frameDuration;
            newIndex += 1;
            if (newIndex >= this.props.imageKeys.length) {
                newIndex -= 1;
                targetTimeOffset = this.props.imageKeys[newIndex][2];
                break;
            }
            playlistItem = this.props.imageKeys[newIndex];
            frameDuration = playlistItem[2];
        }

        targetTimeOffset += 0.001;

        frameCount = playlistItem[5];

        const frameRatio = targetTimeOffset / frameDuration;
        let newSubIndex;

        if (!frameCount) {
            if (frameRatio >= 0.5 && newIndex < this.props.imageKeys.length - 1) {
                newIndex += 1;
            }
            newSubIndex = 1;
        } else if (frameRatio < 0) {
            newSubIndex = 1;
        } else if (frameRatio >= 1) {
            newSubIndex = frameCount;
        } else {
            newSubIndex = Math.floor(frameRatio * frameCount) + 1;
        }

        console.log("New index is:", newIndex, newSubIndex);
        return { index: newIndex, subIndex: newSubIndex };
    }

    nextSecond = () => {
        this.forwardByTime(1);
    };

    prevSecond = () => {
        this.backwardByTime(1);
    };

    nextMinute = () => {
        this.forwardByTime(60);
    };

    prevMinute = () => {
        this.backwardByTime(60);
    };

    toggleFullscreen = () => {
        this.fullscreenComponent.current.toggleFullscreen();
    };

    addToCache = (imageURL, imageData) => {
        if (!this.isInCache(imageURL)) {
            this.imageCache[imageURL] = imageData;
            this.imageCacheOrder.push(imageURL);

            if (this.imageCacheOrder.length > 20) {
                const urlToRemove = this.imageCacheOrder.shift();
                delete this.imageCache[urlToRemove];
            }
        }
    };

    render() {
        let content = (
            <>
                <MobileImagePlayer
                    loadedImage={this.state.loadedImage}
                    imageIsEquirectangular={this.state.imageIsEquirectangular}
                    togglePlaying={this.togglePlaying}
                    goToNextFrame={this.nextFrame}
                    goToPrevFrame={this.prevFrame}
                    playing={this.state.playing}
                    loading={this.state.loading}
                />
            </>
        );

        if (this.props.display === "map") {
            content = (
                <MobileMap
                    updateMapPosition={this.updateMapPosition}
                    mapPosition={this.state.mapPosition}
                />
            );
        }

        const navigationIcon =
            this.props.display === "player" ? (
                <FontAwesomeIcon
                    className={"player-footer__button-icon"}
                    icon={faMap}
                />
            ) : (
                <FontAwesomeIcon
                    icon={faDesktop}
                    className="player-footer__button-icon"
                />
            );

        const controlIcon = this.state.playing ? faPause : faPlay;

        return (
            <>
                <div className="sessionContent">
                    <div className="playerMapContent">{content}</div>
                </div>

                <div className="player-footer">
                    <div className="player-footer-inner">
                        <div
                            className="player-footer__left-button-container"
                            onClick={this.togglePlaying}>
                            <FontAwesomeIcon
                                icon={controlIcon}
                                className="player-footer__button-icon"
                            />
                        </div>

                        <div className="player-footer__scrubber-container">
                            <MobileScrubber
                                imageIndexChanged={this.imageIndexChanged}
                                index={this.state.index}
                            />
                        </div>

                        <div
                            className="player-footer__right-button-container"
                            onClick={this.props.toggleDisplay}>
                            {navigationIcon}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const dashboards = state.dashboards;
    const activeDashboard = _.find(dashboards, (dash) => state.access_token === dash.access_token);

    const routeID = _.get(ownProps, ["match", "params", "sessionID"], null);

    // const routeID = state.playlist.data.routeID;

    const baseURL = _.get(state.playlist.data, ["mpdURLs", `snapshots`]);
    const imageKeys = _.get(state.playlist.data, ["video", state.playlist.position.sourceIndex], []);

    return {
        access_token: state.access_token,
        activeDashboard,
        playlist: state.playlist.data,
        baseURL,
        routeID,
        imageKeys,
        use_snapped: state.snappedRoute || false,
        markers: _.get(state.markers.perSession, [routeID], []),
        selectedTagCategory: state.selectedTagCategory,
        annotationTypes: state.userAnnotationTypes,
        position: state.playlist.position.requestedIndex || 0,
        requestedTimeOffset: state.playlist.position.requestedTimeOffset || 0,
        isEnhanced: state.playlist.position.isEnhanced,
        isStills: state.playlist.position.isStills,
        sourceIndex: state.playlist.position.sourceIndex,
        userConfig: state.userDetails.userConfig,
        csrfToken: state.csrfToken,
    };
};

export default connect(mapStateToProps)(withRouter(MobilePlayerPage));
