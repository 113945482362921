import OBCProgressBar from "components/OBC/OBCProgressBar";
import OBCSearchInput from "components/OBC/OBCSearchInput";
import _ from "lodash";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";

const availableTrainingCategoriesListSelector = (state) => state.driverTraining.admin.availableTrainingCategoriesList;

const SessionsTab = () => {
    const dispatch = useDispatch();
    const availableTrainingCategoriesList = useSelector(availableTrainingCategoriesListSelector);

    const [selectedCategoryID, setSelectedCategoryID] = useState(null);
    const [traningListSearchQuery, setTraningListSearchQuery] = useState("");

    const selectCategory = (category) => {
        console.log("debug selectCategory");
    };

    return (
        <div className="TrainingSessionsTab">
            <div className="TabMainLeftCol">
                <OBCSearchInput
                    value={traningListSearchQuery}
                    onChange={setTraningListSearchQuery}
                    placeholder="Search for traning"
                />
                <div className="TraningsList">
                    {_.map(availableTrainingCategoriesList, (category) => {
                        // return <div>Name: {category.name}</div>
                        return (
                            <div
                                key={category.id}
                                className={`DriverListItem ${selectedCategoryID === category.id ? "active" : ""}`}
                                onClick={() => selectCategory(category)}>
                                <span className="Title">
                                    <Highlighter
                                        highlightClassName="ObcTextHighlight"
                                        searchWords={traningListSearchQuery.split(" ")}
                                        autoEscape={true}
                                        textToHighlight={category.name}
                                    />
                                </span>
                                <span className="SubTitle">Last activity: No activity yet</span>
                                <OBCProgressBar
                                    className="DriverListProgressBar"
                                    lableVisible={false}
                                    progress={0.2}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="TabMainRightCol">Manage Sessions RIGHT COL</div>
        </div>
    );
};

export default SessionsTab;
