import React, { useEffect, useState, useMemo, useCallback } from "react";
// import {getExceedenceData} from "../../redux/actions/index";
import { routeSelected, websocketMessage, getExceedenceData } from "../../redux/actions/index";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

const dashboardDataSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state"], {});
};
// const videoSelector = state => {
//     return _.get(state.playlist.data, ['video'], []);
// }
const exceedencesSelector = (state) => state.exceedenceData;

const ExceedenceRow = ({ row }) => {
    const dispatch = useDispatch();

    const dashboardData = useSelector(dashboardDataSelector);

    const onRowClick = useCallback(
        (e) => {
            const timestampClicked = row.start_timestamp;

            dispatch(
                websocketMessage({
                    action: "request_position",
                    timestamp: timestampClicked + 1000,
                }),
            );
        },
        [dispatch, row.start_timestamp],
    );

    const currentDashboardTimestamp = useMemo(() => {
        const timestamp = _.get(dashboardData, ["position", "timestamp"], 0);
        return timestamp;
    }, [dashboardData]);

    const columns = useMemo(() => {
        console.log("debug row", row);

        return Object.values(row.data).map((value_data) => {
            return <div className={"ExceedenceColumn " + value_data.colour}>{value_data.value}</div>;
        });
    }, [row]);

    const isActive = useMemo(() => {
        return currentDashboardTimestamp > row.start_timestamp && currentDashboardTimestamp < row.end_timestamp;
    }, [currentDashboardTimestamp, row.end_timestamp, row.start_timestamp]);

    return (
        <div
            className={"ExceedenceRow" + (isActive ? " Active" : "")}
            onClick={onRowClick}>
            {columns}
        </div>
    );
};

const ExceedencesHeaderRow = ({ headerData }) => {
    const columns = useMemo(() => {
        return Object.keys(headerData).map((key) => {
            return <div className="ExceedenceColumn">{key}</div>;
        });
    }, [headerData]);

    return <div className="ExceedenceRow header">{columns}</div>;
};

const ExceedencesPage = () => {
    const [initialised, setInitialised] = useState(false);

    const dashboardData = useSelector(dashboardDataSelector);
    const exceedences = useSelector(exceedencesSelector);
    // const video = useSelector(videoSelector);

    const dispatch = useDispatch();

    const currentDashboardTimestamp = useMemo(() => {
        const timestamp = _.get(dashboardData, ["position", "timestamp"], 0);
        return timestamp;
    }, [dashboardData]);

    useEffect(() => {
        if (dashboardData.routeID && !initialised && currentDashboardTimestamp > 0) {
            dispatch(routeSelected(dashboardData.routeID, 0));
            console.log("debug getting exceedances here", initialised);
            dispatch(
                getExceedenceData(dashboardData.routeID, 0, () => {
                    setInitialised(true);
                }),
            );
        }
    }, [dispatch, dashboardData.routeID, currentDashboardTimestamp, initialised]);

    const exceedenceRows = useMemo(() => {
        return exceedences.map((exceedence) => {
            return <ExceedenceRow row={exceedence} />;
        });
    }, [exceedences]);

    const exceedenceHeader = useMemo(() => {
        if (exceedences && exceedences.length) {
            const xc = exceedences[0].data;
            return <ExceedencesHeaderRow headerData={xc} />;
        }
    }, [exceedences]);

    return (
        <>
            {exceedences && exceedences.length ? (
                <div className="ExceedenceBody">
                    {exceedenceHeader}
                    <div className="ExceedenceRowContainer">{exceedenceRows}</div>
                </div>
            ) : (
                <div className="ExceedenceFallback">
                    <p>No Exceedance Data Available For This Session</p>
                </div>
            )}
        </>
    );
};

export default ExceedencesPage;
