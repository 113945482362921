import React from "react";
import { connect } from "react-redux";
import { Polyline } from "react-leaflet";
import memoize from "memoize-one";
import _ from "lodash";
import { routeSelected, selectBookmark, websocketMessage } from "../../redux/actions/index";
import { getCoordinates, getInterpolatedPosition, getOffsetAdjustedTime, absoluteTimeLookup, groupAreasOfInterest } from "../util/PlaylistUtils";

class SelectedRouteSegmentGeometry extends React.PureComponent {
    constructor(props) {
        super(props);

        this.polyPreRef = React.createRef();
        this.polyMidRef = React.createRef();
        this.polyExpRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectRouteToFront !== this.props.selectRouteToFront) {
            if (this.polyPreRef.current) {
                this.polyPreRef.current.leafletElement.bringToFront();
            }
            if (this.polyMidRef.current) {
                this.polyMidRef.current.leafletElement.bringToFront();
            }
            if (this.polyExpRef.current) {
                this.polyExpRef.current.leafletElement.bringToFront();
            }
        }
        if (prevProps.loading !== this.props.loading) {
            if (this.polyPreRef.current) {
                this.polyPreRef.current.leafletElement.bringToFront();
            }
            if (this.polyMidRef.current) {
                this.polyMidRef.current.leafletElement.bringToFront();
            }
            if (this.polyExpRef.current) {
                this.polyExpRef.current.leafletElement.bringToFront();
            }
        }
    };

    getGeometry = memoize((segments, offsets, use_snapped) => {
        if (segments.length === 0) {
            return [];
        }
        return segments
            .map((seg) => {
                if (seg.length > 4) {
                    let offsetTime = getOffsetAdjustedTime(seg[1], offsets);
                    let interpolatedPosition = getInterpolatedPosition(offsetTime, segments, use_snapped);
                    if (interpolatedPosition !== null) {
                        return [interpolatedPosition[1], interpolatedPosition[0], seg[3][2]];
                    } else {
                        return [null, null, seg[3][2]];
                    }
                } else if (seg.start) {
                    return [seg.start[2], seg.start[1], seg.start[0]];
                } else {
                    let coords = getCoordinates(seg[1], use_snapped);
                    return [coords[1], coords[0], seg[1][2]];
                }
            })
            .filter(([lat, lon, time]) => lat !== null && lon !== null && time !== null);
    });

    onMouseClick = (location) => {
        console.log("location", location);
        if (this.props.currentMode) {
            return;
        }

        const l = [location.latlng.lng, location.latlng.lat];

        console.log("location target", location);
        console.log("Clicked point: ", l);

        if (this.props.stripped) {
            if (this.props.stripped === "sidekick") {
                this.props.dispatch(
                    websocketMessage({
                        action: "request_map_position",
                        coords: l,
                        sessionID: this.props.routeData.routeID,
                    }),
                );
            }
        }

        this.props.dispatch(selectBookmark(null));
        this.props.dispatch(routeSelected(this.props.routeData.routeID, l));
    };

    render() {
        const retVal = [];

        if (this.props.segmentsType === "export") {
            let preExportSections = [];
            let midExportSections = [];
            let postExportSections = [];

            preExportSections = this.getGeometry(this.props.exportSections[0], this.props.offsets, this.props.use_snapped);
            midExportSections = this.getGeometry(this.props.exportSections[1], this.props.offsets, this.props.use_snapped);
            postExportSections = this.getGeometry(this.props.exportSections[2], this.props.offsets, this.props.use_snapped);

            if (preExportSections.length === 0 && midExportSections.length > 0 && postExportSections.length === 0) {
                retVal.push(
                    <Polyline
                        key="midRoute"
                        color={"#5dd65f"}
                        weight={5}
                        positions={midExportSections}
                        onClick={this.onMouseClick}
                        ref={this.polyPreRef}
                    />,
                );
            } else {
                if (preExportSections.length > 0) {
                    retVal.push(
                        <Polyline
                            key="preRoute"
                            color={"#5dd65f"}
                            weight={5}
                            positions={preExportSections}
                            onClick={this.onMouseClick}
                            ref={this.polyPreRef}
                        />,
                    );
                }
                if (midExportSections.length > 0) {
                    retVal.push(
                        <Polyline
                            key="midRoute"
                            color={"#6C43DF"}
                            weight={5}
                            positions={midExportSections}
                            onClick={this.onMouseClick}
                            ref={this.polyMidRef}
                        />,
                    );
                }
                if (postExportSections.length > 0) {
                    retVal.push(
                        <Polyline
                            key="postRoute"
                            color={"#5dd65f"}
                            weight={5}
                            positions={postExportSections}
                            onClick={this.onMouseClick}
                            ref={this.polyExpRef}
                        />,
                    );
                }
            }
        } else {
            this.props.shortcutSections[0].forEach((nonShortcutSection, idx) => {
                let geom = this.getGeometry(nonShortcutSection, this.props.offsets, this.props.use_snapped);
                retVal.push(
                    <Polyline
                        key={"shortcutSection" + idx.toString()}
                        color={"#6C43DF"}
                        weight={5}
                        positions={geom}
                        onClick={this.onMouseClick}
                        ref={this.polyExpRef}
                    />,
                );
            });

            this.props.shortcutSections[1].forEach((nonShortcutSection, idx) => {
                let geom = this.getGeometry(nonShortcutSection, this.props.offsets, this.props.use_snapped);
                retVal.push(
                    <Polyline
                        key={"nonShortcutSection" + idx.toString()}
                        color={"#5dd65f"}
                        weight={5}
                        positions={geom}
                        onClick={this.onMouseClick}
                        ref={this.polyExpRef}
                    />,
                );
            });
        }

        return retVal;
    }
}

const getExportIndices = memoize((segmentSelection) => {
    if (!segmentSelection) {
        return null;
    }
    let segmentStartIndex = segmentSelection.start;
    let segmentEndIndex = segmentSelection.end;

    if (segmentStartIndex === -1 || segmentEndIndex === -1) {
        return null;
    }

    if (segmentStartIndex > segmentEndIndex) {
        let temp = segmentStartIndex;
        segmentStartIndex = segmentEndIndex;
        segmentEndIndex = temp;
    }
    return [segmentStartIndex, segmentEndIndex];
});

const getRouteSections = memoize((videoParts, exportIndices) => {
    if (videoParts === null) {
        return [[], [], []];
    }

    if (!exportIndices) {
        return [[], videoParts, []];
    }

    return [videoParts.slice(0, exportIndices[0] + 1), videoParts.slice(exportIndices[0], exportIndices[1] + 1), videoParts.slice(exportIndices[1])];
});

const getShortcutIndices = memoize((shortcutRanges, video) => {
    let ranges = shortcutRanges
        .filter((range) => range[0] || range[1])
        .map((range) => {
            const start = _.get(range, [0, "timestamp"], 0) / 1000;
            const end = _.get(range, [1, "timestamp"], 0) / 1000;

            return {
                start: absoluteTimeLookup(start, video) || 0,
                end: absoluteTimeLookup(end, video) || 0,
            };
        });
    return ranges;
});

const getShortcutRouteSections = memoize((videoParts, shortcutRanges) => {
    console.log("shortcutRanges", shortcutRanges);
    let shortcutSegments = [];
    let nonShortcutSegments = [];

    let lastAddedTo = null;

    let segment = [];
    videoParts.forEach((part, idx) => {
        let shortcutIndex = _.findIndex(shortcutRanges, (range) => idx >= range.start && idx <= range.end);

        if (shortcutIndex > -1) {
            if (!lastAddedTo) {
                lastAddedTo = "shortcut";
            }
            if (lastAddedTo === "video") {
                segment.push(part);
                nonShortcutSegments.push(segment);
                segment = [];
            }
            lastAddedTo = "shortcut";
        } else {
            if (!lastAddedTo) {
                lastAddedTo = "video";
            }
            if (lastAddedTo === "shortcut") {
                segment.push(part);
                shortcutSegments.push(segment);
                segment = [];
            }
            lastAddedTo = "video";
        }
        segment.push(part);
    });

    if (lastAddedTo === "video") {
        nonShortcutSegments.push(segment);
    } else {
        shortcutSegments.push(segment);
    }
    return [shortcutSegments, nonShortcutSegments];
});

const getAreaOfInterestSections = memoize((playlistData, allMetadata) => {
    const aoiSections = [];
    const nonAOISections = [];

    let lastAddedTo = null;
    let segment = [];

    playlistData.forEach((data) => {
        const dataTimestamp = data.end[0];
        let aoi = false;

        allMetadata.forEach((metadata) => {
            if (dataTimestamp > metadata.start && dataTimestamp < metadata.end) {
                aoi = true;
            }
        });

        if ((aoi && lastAddedTo === "aoi") || (!aoi && lastAddedTo !== "aoi") || !lastAddedTo) {
            segment.push(data);
            lastAddedTo = aoi ? "aoi" : "default";
        } else {
            segment.push(data);
            if (aoi) {
                nonAOISections.push(segment);
            } else {
                aoiSections.push(segment);
            }
            segment = [data];

            lastAddedTo = aoi ? "aoi" : "default";
        }
    });

    if (segment.length) {
        if (lastAddedTo === "aoi") {
            aoiSections.push(segment);
        } else {
            nonAOISections.push(segment);
        }
    }

    return [aoiSections, nonAOISections];
});

const mapStateToProps = (state) => {
    const exportIndices = getExportIndices(state.segmentSelection);

    const annotationTypes = state.userAnnotationTypes;

    let isVideo = true;
    const playlistData = state.playlist.data;
    if (playlistData && playlistData.mpdURLs) {
        if (playlistData.mpdURLs.imageBase && playlistData.mpdURLs["raw.0"]) {
            isVideo = state.playlist.position.isVideo;
        } else if (playlistData.mpdURLs.imageBase) {
            isVideo = false;
        } else if (playlistData.mpdURLs["raw.0"]) {
            isVideo = true;
        }
    }

    let offsets = [];

    if (state.playlist.data.routeID === state.gpsTimeOffsets.sessionID) {
        offsets = _.get(state.gpsTimeOffsets.offsets, state.playlist.position.sourceIndex, []);
    }

    let routeSections = [[], [], []];
    let shortcutSections = [[], []];
    let segmentsType = "export";

    let videoParts = [];
    if (isVideo) {
        videoParts = _.get(playlistData, ["video", state.playlist.position.sourceIndex], null);
    } else {
        videoParts = _.get(playlistData, ["image"], null);
    }

    if (videoParts && videoParts.length) {
        const selectedPoints = _.get(state.shortcuts.shortcutSessionPoints, [state.playlist.data.routeID], []);
        const shortcutIndices = getShortcutIndices(selectedPoints, videoParts);

        if (shortcutIndices && shortcutIndices.length) {
            segmentsType = "shortcut";
            shortcutSections = getShortcutRouteSections(videoParts, shortcutIndices);
        } else {
            routeSections = getRouteSections(videoParts, exportIndices);
        }
    } else if (state.playlist.data.data) {
        let areasOfInterest = [];

        const areaOfInterestMetadata = _.get(state, ["routeMetadata", "AREA_OF_INTEREST", state.playlist.data.routeID], {});
        areasOfInterest = groupAreasOfInterest(areaOfInterestMetadata, annotationTypes);

        if (areasOfInterest.length) {
            shortcutSections = getAreaOfInterestSections(state.playlist.data.data, areasOfInterest);
            segmentsType = "shortcut";
        } else {
            routeSections = getRouteSections(state.playlist.data.data, exportIndices);
        }
    }

    const railData = state.railInspection.railInspectionImages.data;

    return {
        routeData: playlistData,
        use_snapped: state.snappedRoute || false,
        offsets,
        sourceIndex: state.playlist.position.sourceIndex,
        isVideo: isVideo,
        preExport: routeSections[0],
        midExport: routeSections[1],
        postExport: routeSections[2],
        exportSections: routeSections,
        currentMode: state.tool_mode,
        loading: state.mapGeometry === null,
        segmentsType,
        shortcutSections,
        railData,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SelectedRouteSegmentGeometry);
