import React from "react";
import L from "leaflet";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import RailInspectTileInfo from "./RailInspectTileInfo";
import MissingImageComponent from "./MissingImageComponent";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import _ from "lodash";

const baseURL = `https://inspection${MEMOIZED_DOMAIN_URL}/`;

export const DynamicLayer = L.GridLayer.extend({
    railImages: null,
    index: null,
    arrayOffset: 0,
    brightness: 1,
    contrast: 1,
    deviceName: "",
    srcWidth: 0,
    imgWidth: 0,
    srcX: 0,
    xOffset: 0,
    yOffset: 0,
    reverse: false,
    horizontalFlip: false,
    secondary: false,
    constantArrayOffset: 0,
    zoom: 0,
    secondaryAlignment: { primaryIndex: 0, secondaryIndex: 0, primaryIsReversed: 0, baseYOffset: 0, fineYOffset: 0 },
    horizontal: false,
    totalWidth: 0,
    baseXOffset: 0,
    backwardsImage: false,
    flipped: false,
    classifications: [],
    leftRightConfiguration: [],
    displayContamination: false,
    targetInspectionData: null,
    sperryMarkers: [],
    csrfToken: null,
    isExporting: false,
    exportRange: null,
    setup: function (
        railImages,
        horizontal,
        classifications,
        displayContamination,
        targetInspectionData = null,
        sperryMarkers = [],
        debug = false,
        setSomeImagesMissing = null,
    ) {
        this.railImages = railImages;
        this.horizontal = horizontal;
        this.classifications = classifications;
        this.displayContamination = displayContamination;
        this.debug = debug;
        this.targetInspectionData = targetInspectionData;
        this.sperryMarkers = sperryMarkers;
        if (setSomeImagesMissing) {
            this.setSomeImagesMissing = setSomeImagesMissing;
        }
    },
    setIndex: function (index) {
        this.index = index;
    },
    setCsrfToken: function (token) {
        this.csrfToken = token;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setFlipped: function (flipped) {
        this.flipped = flipped;
    },
    setBackwardImage: function (back) {
        this.backwardsImage = back;
    },
    setSecondary: function (secondary) {
        this.secondary = secondary;
    },
    setConstantOffset: function (offset) {
        if (!this.horizontal || !this.flipped) {
            offset *= -1;
        }
        if (this.horizontal && this.reverse) {
            this.constantArrayOffset = Math.ceil(offset);
            this.yOffset = (this.constantArrayOffset - offset) * 1000;
        } else if (this.horizontal) {
            this.constantArrayOffset = Math.floor(offset);
            this.yOffset = (this.constantArrayOffset - offset) * 1000;
        } else {
            this.constantArrayOffset = Math.floor(offset);
            this.yOffset = (offset - this.constantArrayOffset) * 1000;
        }
        console.log("Setting constant offset:", { offset, reverse: this.reverse, arrayOffset: this.constantArrayOffset, yOffset: this.yOffset });
    },
    setSecondaryAlignment: function (primaryIndex, secondaryIndex, primaryIsReversed, yOffset) {
        if (this.secondary) {
            const baseYOffset = Math.floor(yOffset);
            const fineYOffset = (yOffset - baseYOffset) * 1000;
            this.secondaryAlignment = { primaryIndex, secondaryIndex, primaryIsReversed, baseYOffset, fineYOffset };
            console.log("Setting secondary alignment:", this.secondaryAlignment);
        }
    },
    setHorizontalFlip: function (flip) {
        this.horizontalFlip = flip;
    },
    setBrightness: function (val) {
        this.brightness = Math.pow(3, val);
    },
    setContrast: function (val) {
        this.contrast = Math.pow(2, val);
    },
    setDeviceName: function (val) {
        this.deviceName = val;
    },
    setSrcWidth: function (val) {
        this.srcWidth = val;
    },
    setImgWidth: function (val) {
        this.imgWidth = val;
    },
    setDisplayWidth: function (val) {
        this.displayWidth = val;
    },
    setSrcX: function (val) {
        this.srcX = val;
    },
    setBaseXOffset: function (val) {
        this.baseXOffset = val;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    setTotalWidth: function (val) {
        this.totalWidth = val;
    },
    setLeftRightConfig: function (val) {
        this.leftRightConfiguration = val;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setIsExporting: function (val) {
        this.isExporting = val;
    },
    setExportRange: function (val) {
        this.exportRange = val;
    },
    createTile: function (coords, done) {
        let coordToCompare = this.horizontal ? coords.y : coords.x;
        let arrayIndexCoord = this.horizontal ? -coords.x : coords.y;

        let arrayIndex;
        if (this.secondary) {
            let primaryArrayIndex = (this.secondaryAlignment.primaryIsReversed ? 1 : -1) * arrayIndexCoord + this.arrayOffset;
            let offsetFromPrimary =
                (this.secondaryAlignment.primaryIsReversed !== this.reverse ? -1 : 1) * (primaryArrayIndex - this.secondaryAlignment.primaryIndex);
            arrayIndex = offsetFromPrimary + this.secondaryAlignment.secondaryIndex + this.constantArrayOffset + this.secondaryAlignment.baseYOffset;
        } else {
            arrayIndex = (this.reverse ? 1 : -1) * arrayIndexCoord + this.arrayOffset + this.constantArrayOffset + this.secondaryAlignment.baseYOffset;
        }

        let xOffset = this.xOffset;
        const xIndex = Math.floor((xOffset + this.baseXOffset) / this.options.tileSize.x);

        let container = document.createElement("div");

        if (coordToCompare === xIndex && this.railImages && arrayIndex >= 0 && arrayIndex < this.railImages.length) {
            const imageTimestamp = this.railImages[arrayIndex].timestamp;

            setTimeout(() => {
                let tile = L.DomUtil.create("div");

                let base_path = this.railImages[arrayIndex].base_path;
                const new_base_path = base_path.replace("$DEVICE", `${this.deviceName}`);
                let imagePath = baseURL + new_base_path + "/" + imageTimestamp + ".jpg";

                if (this.csrfToken) {
                    imagePath += `?csrf=${this.csrfToken}`;
                }

                const backgroundWidth = (this.imgWidth / this.srcWidth) * this.displayWidth;

                let displayHeight = 1000;

                // Create an Image object to check background image loading
                const image = new Image();

                // Add event handlers to check if the background image has loaded
                image.onerror = () => {
                    console.error("Rail inspection background image failed to load.");
                    if (this.setSomeImagesMissing) {
                        this.setSomeImagesMissing();
                    }

                    tile.classList.add("ErrorTile");
                    ReactDOM.render(
                        <MissingImageComponent
                            zoom={this.zoom}
                            backwards={this.backwardsImage}
                        />,
                        tile,
                    );
                };

                // Set the src attribute to initiate loading (there is a change this will reduce performance, didn't notice much different when testing locally)
                image.src = imagePath;

                // // Display coordinates on tiles for debugging
                // tile.innerHTML = "==========" + [coords.x, coords.y].join(', ') + "==========" + coordToCompare + "==========" + xIndex;
                //  tile.style.color = "red";
                //  tile.style.fontSize = "50px";
                // tile.style.border = "red solid 2px";
                console.log("LAYER MARKERS", this.sperryMarkers);
                if (this.targetInspectionData && this.targetInspectionData.timestamp && this.targetInspectionData.timestamp === imageTimestamp) {
                    const targetLineDiv = L.DomUtil.create("div");
                    targetLineDiv.setAttribute(
                        "style",
                        `position: absolute;
                        bottom: ${this.targetInspectionData.distance * 100}%;
                        background: rgba(255,0,0,0.8);
                        height: 10px;
                        width: 100%;
                    `,
                    );
                    targetLineDiv.setAttribute("class", "TargetImageLine");
                    tile.append(targetLineDiv);
                }

                if (this.sperryMarkers !== null && this.sperryMarkers.length > 0) {
                    this.sperryMarkers.forEach((marker) => {
                        if (marker.timestamp === imageTimestamp) {
                            const markerDiv = L.DomUtil.create("div");
                            markerDiv.setAttribute(
                                "style",
                                `position: absolute;
                                bottom: ${marker.interpolationRatio * 100}%;
                                background: rgba(255,0,0,0.4);
                                height: 10px;
                                width: 100%;
                                font-size: 200px;
                            `,
                            );
                            tile.append(markerDiv);

                            const markerText = L.DomUtil.create("div");
                            markerText.setAttribute(
                                "style",
                                `position: absolute;
                                bottom: ${marker.interpolationRatio * 100}%;
                                right: 0;
                                background: rgba(255,0,0,0.4);
                                padding-left: 10px;
                                padding-right: 10px;
                                font-size: 100px;
                                color: white;
                                transform: rotate(180deg) scaleX(-1);
                            `,
                            );
                            markerText.textContent = `Suspect UID: ${marker.uid}`;
                            tile.append(markerText);
                        }
                    });
                }

                tile.setAttribute("data-timestamp", imageTimestamp);

                let styles = {};

                if (this.horizontal) {
                    if (!this.backwardsImage) {
                        if (this.flipped) {
                            styles["transform-origin"] = "top left";
                            styles["transform"] = "rotate(-90deg) translate(-100%, 0px)";
                            styles["height"] = "1000px";
                        } else {
                            styles["transform-origin"] = "0 0";
                            styles["transform"] = "translate(500px, -500px) rotate(90deg) translate(500px, -500px)";
                        }
                    } else {
                        if (this.flipped) {
                            styles["transform-origin"] = "top left";
                            styles["transform"] = "rotate(90deg) scaleX(-1) translate(-100%, -1000px)";
                            styles["height"] = "1000px";
                        } else {
                            styles["transform-origin"] = "top left";
                            styles["transform"] = "translate(500px, -500px) rotate(-90deg) scaleX(-1) translate(500px, -500px)";
                            styles["height"] = "1000px";
                        }
                    }
                } else {
                    if (this.reverse) {
                        styles["transform"] = "scaleY(-1)";
                    }
                }

                if (this.horizontalFlip) {
                    if (styles["transform"]) {
                        styles["transform"] += " scaleX(-1)";
                    } else {
                        styles["transform"] = "scaleX(-1)";
                    }
                }

                let styleString = "";
                Object.keys(styles).forEach((styleKey) => {
                    styleString += `${styleKey}: ${styles[styleKey]};`;
                });

                tile.setAttribute(
                    "style",
                    `background: url("${imagePath}") no-repeat -${this.srcX}px 0;
                    background-size: ${backgroundWidth}px 1000px; 
                    width: ${this.displayWidth}px; 
                    height: ${displayHeight}px; 
                    filter: brightness(${this.brightness}) 
                    contrast(${this.contrast});
                    position: relative;
                    ${styleString}`,
                );

                if (this.displayContamination) {
                    let currentContamination = _.find(this.classifications, (classification) => classification.image_timestamp === imageTimestamp);

                    if (currentContamination) {
                        let leftContaminated = currentContamination.bbox.left_rail.class === "contaminated";
                        let rightContaminated = currentContamination.bbox.right_rail.class === "contaminated";

                        if (leftContaminated) {
                            let contaminationMarkerTop = L.DomUtil.create("div");

                            let rightBorder = rightContaminated ? "" : "border-right: 12px solid yellow;";

                            contaminationMarkerTop.setAttribute(
                                "style",
                                `
                                border-left: 12px solid yellow;
                                ${rightBorder}
                                width: 48.5%; 
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 1.5%;
                                z-index: 1000;
                                `,
                            );

                            tile.append(contaminationMarkerTop);
                        }

                        if (rightContaminated) {
                            let contaminationMarkerBottom = L.DomUtil.create("div");

                            let leftBorder = leftContaminated ? "" : "border-left: 12px solid yellow;";

                            contaminationMarkerBottom.setAttribute(
                                "style",
                                `
                                ${leftBorder}
                                border-right: 12px solid yellow;
                                width: 50%; 
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                z-index: 1000;
                                `,
                            );

                            tile.append(contaminationMarkerBottom);
                        }
                    }
                }

                container.append(tile);

                if (xOffset === 0) {
                    let line = L.DomUtil.create("div");
                    const lineClasses = ["line"];

                    if (this.horizontal) {
                        lineClasses.push("horizontalLine");
                    } else {
                        lineClasses.push("verticalLine");
                    }

                    if (this.isExporting && this.exportRange) {
                        const startIndex = this.exportRange.start || 0;
                        const endIndex = this.exportRange.end || this.railImages.length - 1;

                        if (arrayIndex === startIndex || arrayIndex === endIndex) {
                            lineClasses.push("exportRangeMarker");
                        }
                    }

                    line.setAttribute("class", lineClasses.join(" "));

                    container.append(line);
                }

                done(null, container);
            }, 0);
        } else {
            done(null, container);
        }
        return container;
    },
    _getTilePos: function (coords) {
        let yOffset = this.yOffset;
        if (this.secondary) {
            yOffset += this.secondaryAlignment.fineYOffset;
        }
        let xOffset = this.xOffset;
        xOffset += this.baseXOffset;
        xOffset -= Math.floor(xOffset / this.options.tileSize.x) * this.options.tileSize.x;

        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        if (this.horizontal) {
            return pos.add([(this.reverse ? -1 : 1) * yOffset, xOffset]);
        } else {
            return pos.add([xOffset, (this.reverse ? -1 : 1) * yOffset]);
        }
    },
});

export const InfoLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    zoom: 0,
    store: null,
    reverse: false,
    xOffset: 0,
    horizontal: false,
    arrowLeft: true,
    setup: function (railImages, store, horizontal, totalWidth = 0, arrowLeft = true) {
        this.railImages = railImages;
        this.store = store;
        this.horizontal = horizontal;
        this.totalWidth = totalWidth;
        this.arrowLeft = arrowLeft;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    createTile: function (coords, done) {
        let coordToCompare = this.horizontal ? coords.y : coords.x;
        let arrayIndexCoord = this.horizontal ? -coords.x : coords.y;

        let arrayIndex = (this.reverse ? 1 : -1) * arrayIndexCoord + this.arrayOffset;
        let container = document.createElement("div");

        if (
            (coordToCompare === -1 || (coordToCompare === 0 && !this.arrowLeft && !this.horizontal)) &&
            this.railImages &&
            arrayIndex >= 0 &&
            arrayIndex < this.railImages.length &&
            arrayIndex % 1 === 0
        ) {
            setTimeout(() => {
                ReactDOM.render(
                    <Provider store={this.store}>
                        <RailInspectTileInfo
                            leftArrow={this.arrowLeft}
                            rightArrow={coordToCompare === 0}
                            railImages={this.railImages}
                            arrayIndex={arrayIndex}
                            zoom={this.zoom}
                            horizontal={this.horizontal}
                            reverse={this.reverse}
                        />
                    </Provider>,
                    container,
                );
                done(null, container);
            }, 0);
        } else {
            done(null, container);
        }

        return container;
    },
    destroyTile: function (tile) {
        if (tile) {
            ReactDOM.unmountComponentAtNode(tile);
        }
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        pos = pos.add([this.xOffset, 0]);
        if (coords.x === 0 && !this.horizontal) {
            pos = pos.add([this.totalWidth - this._tileSize.x, 0]);
        }
        return pos;
    },
});

export const LeftRightDisplayLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    zoom: 0,
    reverse: false,
    xOffset: 0,
    leftRightConfig: {},
    horizontal: false,
    railImagesWidth: 0,
    constantArrayOffset: 0,
    yOffset: 0,
    setup: function (railImages, leftRightConfig, horizontal, railImagesWidth) {
        this.railImages = railImages;
        this.leftRightConfig = leftRightConfig;
        this.horizontal = horizontal;
        this.railImagesWidth = railImagesWidth;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    setConstantOffset: function (offset) {
        offset *= -1;
        this.constantArrayOffset = Math.floor(offset);
        this.yOffset = (offset - this.constantArrayOffset) * 1000;
    },
    createTile: function (coords) {
        let coordToCompare = this.horizontal ? coords.y : coords.x;
        let arrayIndexCoord = this.horizontal ? -coords.x : coords.y;

        let arrayIndex = (this.reverse ? 1 : -1) * arrayIndexCoord + this.arrayOffset;

        let container = L.DomUtil.create("div");

        let passedCoordCriteria = false;

        if (this.horizontal) {
            if (coordToCompare === -1 || coordToCompare === 1) {
                passedCoordCriteria = true;
            }
        } else {
            if (coordToCompare === -1 || coordToCompare === 0) {
                passedCoordCriteria = true;
            }
        }

        if (passedCoordCriteria && this.railImages && arrayIndex >= 0 && arrayIndex < this.railImages.length) {
            let tile = L.DomUtil.create("div");

            const configItem = this.leftRightConfig[arrayIndex];
            let textToDisplay = coordToCompare === -1 ? "L" : "R";
            if (configItem && configItem.top === "right") {
                textToDisplay = coordToCompare === -1 ? "R" : "L";
            }
            const top = coordToCompare === -1;

            const containerClassNames = ["LeftRightContainer", top ? "Top" : "Bottom"];
            if (this.horizontal) {
                containerClassNames.push("Horizontal");
            } else {
                containerClassNames.push("Vertical");
            }
            tile.setAttribute("class", containerClassNames.join(" "));

            const pElem = L.DomUtil.create("p");
            const pElemClassNames = ["Text", this.reverse ? "Reverse" : ""];
            pElem.setAttribute("class", pElemClassNames.join(" "));
            pElem.innerHTML = textToDisplay;

            tile.append(pElem);
            container.append(tile);
        }

        return container;
    },
    destroyTile: function (tile) {
        if (tile) {
            ReactDOM.unmountComponentAtNode(tile);
        }
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        if (this.horizontal) {
            return pos.add([this.xOffset, 0]);
        } else {
            pos = pos.add([0, this.yOffset]);
            if (coords.x === 0) {
                return pos.add([this.railImagesWidth, 0]);
            } else {
                return pos;
            }
        }
    },
});

export const GridOverlay = L.GridLayer.extend({
    zoom: 0,
    gridSize: 10,
    offset: { x: 0, y: 0 },
    setup: function (railImages) {
        this.railImages = railImages;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setGridSize: function (gridSize) {
        this.gridSize = gridSize;
    },
    setGridOffset: function (offset) {
        this.offset = offset;
    },
    createTile: function (coords) {
        let arrayIndexCoord = coords.x;
        let arrayIndex = arrayIndexCoord;
        let container = document.createElement("div");

        if (this.railImages && arrayIndex < this.railImages.length && arrayIndex % 1 === 0) {
            container.classList.add("GridLayerTile");
            container.classList.add("width-" + this.gridSize);
        }

        return container;
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        return pos.add([this.offset.x, this.offset.y]);
    },
});

export const DetectionsLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    zoom: 0,
    reverse: false,
    xOffset: 0,
    horizontal: false,
    detections: {},
    constantArrayOffset: 0,
    closestDetection: null,
    backwardsImage: false,
    flipped: false,
    imagesConfig: [],
    clickHandler: () => {},
    setup: function (railImages, detections, horizontal) {
        this.railImages = railImages;
        this.detections = detections;
        this.horizontal = horizontal;
    },
    setClickHandler: function (func) {
        this.clickHandler = func;
    },
    setClosestDetection: function (detection) {
        this.closestDetection = detection;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    setFlipped: function (flipped) {
        this.flipped = flipped;
    },
    setBackwardImage: function (back) {
        this.backwardsImage = back;
    },
    setHorizontalFlip: function (flip) {
        this.horizontalFlip = flip;
    },
    setImagesConfig: function (imagesConfig) {
        this.imagesConfig = imagesConfig;
    },
    setConstantOffset: function (offset) {
        offset *= -1;
        this.constantArrayOffset = Math.floor(offset);
        this.yOffset = (offset - this.constantArrayOffset) * 1000;
        console.log("debug11 Setting constant offset:", { offset, reverse: this.reverse, arrayOffset: this.constantArrayOffset, yOffset: this.yOffset });
    },
    createTile: function (coords, done) {
        let coordToCompare = this.horizontal ? coords.y : coords.x;
        let arrayIndexCoord = this.horizontal ? -coords.x : coords.y;

        let arrayIndex = (this.reverse ? 1 : -1) * arrayIndexCoord + this.arrayOffset + this.constantArrayOffset;

        let container = document.createElement("div");
        let innerContainer = document.createElement("div");
        container.append(innerContainer);

        if (this.horizontal) {
            if (!this.backwardsImage) {
                if (this.flipped) {
                    innerContainer.classList.add("horizontalReversed");
                } else {
                    innerContainer.classList.add("horizontal");
                }
            } else {
                if (this.flipped) {
                    innerContainer.classList.add("backwardsImageFlipped");
                } else {
                    innerContainer.classList.add("backwardsImage");
                }
            }
        }

        if (coordToCompare === 0 && this.railImages && arrayIndex >= 0 && arrayIndex < this.railImages.length) {
            const imageTimestamp = this.railImages[arrayIndex].timestamp;
            const currentDetections = this.detections[imageTimestamp];

            let imagesConfig = this.imagesConfig;

            setTimeout(() => {
                if (currentDetections && currentDetections.length) {
                    currentDetections.forEach((detection) => {
                        let done = false;
                        let yOffset = 0;
                        let xOffset = 0;
                        let bbLeft = detection.bbox[0];
                        let bbTop = detection.bbox[1];

                        let width = detection.bbox[2];
                        const height = detection.bbox[3];

                        let verticalStyleName = "top";

                        if (this.horizontal) {
                            _.forEach(imagesConfig, (image, index) => {
                                const imgSource = _.get(image, "source", null);

                                if (imgSource === detection.source_device) {
                                    if (bbLeft >= image.src_x && bbLeft < image.src_width + image.src_x) {
                                        bbLeft -= image.src_x;
                                        bbLeft = (bbLeft / image.src_width) * image.display_width;
                                        width = (width / image.src_width) * image.display_width;
                                        if (this.flipped) {
                                            bbLeft += 1000 - image.display_width;
                                        }
                                        done = true;

                                        // move detection x left or right
                                        if (done) {
                                            if (this.backwardsImage) {
                                                xOffset = (image.offset_y_backward || 0) * 1000;
                                            } else {
                                                xOffset = (image.offset_y_forward || 0) * 1000;
                                            }
                                            bbTop -= xOffset;
                                        }
                                        return false;
                                    }
                                }
                                yOffset += image.display_width;

                                if (Object.keys(imagesConfig).length - 1 === index && !done) {
                                    done = true;
                                }
                            });
                        } else {
                            _.forEach(imagesConfig, (image, index) => {
                                const imgSource = _.get(image, "source", null);

                                if (imgSource === detection.source_device) {
                                    const transformedImageWidth = (image.img_width / image.src_width) * image.display_width;
                                    const squashRatio = transformedImageWidth / image.img_width;
                                    bbLeft *= squashRatio;
                                    width *= squashRatio;
                                }
                            });

                            if (this.reverse) {
                                verticalStyleName = "bottom";
                            }
                            done = true;
                        }

                        if (this.horizontal) {
                            if (this.flipped) {
                                bbLeft -= yOffset;
                            } else {
                                bbLeft += yOffset;
                            }
                        }

                        if (done) {
                            const bb = L.DomUtil.create("div");

                            bb.addEventListener("click", () => {
                                if (_.get(detection, "id") !== _.get(this.closestDetection, "id")) {
                                    this.clickHandler(_.get(detection, "id"));
                                }
                            });

                            bb.setAttribute("class", "BoundingBox");
                            if (this.closestDetection) {
                                if (detection.id === this.closestDetection.id) {
                                    bb.setAttribute("class", "BoundingBox closest");
                                }
                            }
                            bb.setAttribute(
                                "style",
                                `position:absolute; ${verticalStyleName}:${bbTop}px; left:${bbLeft}px; width:${width}px; height:${height}px`,
                            );
                            innerContainer.append(bb);
                        }
                    });
                }
                done(null, container);
            }, 0);
        } else {
            done(null, container);
        }
        return container;
    },
    destroyTile: function (tile) {
        if (tile) {
            ReactDOM.unmountComponentAtNode(tile);
        }
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        return pos.add([this.xOffset, 0]);
    },
});

export const RightInfoLayer = L.GridLayer.extend({
    railImages: null,
    arrayOffset: 0,
    zoom: 0,
    reverse: false,
    xOffset: 0,
    yOffset: 0,
    areasOfInterest: [],
    setup: function (railImages, areasOfInterest) {
        this.railImages = railImages;
        this.areasOfInterest = areasOfInterest;
    },
    setArrayOffset: function (offset) {
        this.arrayOffset = offset;
    },
    setReverse: function (reverse) {
        this.reverse = reverse;
    },
    setMapZoom: function (zoom) {
        this.zoom = zoom;
    },
    setXOffset: function (val) {
        this.xOffset = val;
    },
    createTile: function (coords) {
        let container = L.DomUtil.create("div");

        let coordToCompare = coords.x;
        let arrayIndexCoord = coords.y;

        let arrayIndex = (this.reverse ? 1 : -1) * arrayIndexCoord + this.arrayOffset;

        if (coordToCompare === 1 && this.railImages && arrayIndex >= 0 && arrayIndex < this.railImages.length && arrayIndex % 1 === 0) {
            let areaMatch;
            const imageTimestamp = this.railImages[arrayIndex].timestamp;

            let beforeTimestamp;
            let afterTimestamp;
            let isStart = false;
            let isEnd = false;

            if (this.railImages.length > arrayIndex + 1) {
                afterTimestamp = this.railImages[arrayIndex + 1].timestamp;
            }

            if (arrayIndex - 1 > -1) {
                beforeTimestamp = this.railImages[arrayIndex - 1].timestamp;
            }

            this.areasOfInterest.forEach((area) => {
                if (imageTimestamp / 1000 > area.start && imageTimestamp / 1000 < area.end) {
                    areaMatch = area;

                    if (beforeTimestamp / 1000 < area.start) {
                        isStart = true;
                    }

                    if (afterTimestamp / 1000 > area.end) {
                        isEnd = true;
                    }
                }
            });

            if (areaMatch) {
                let tile = L.DomUtil.create("div");
                const containerClassNames = ["AreaOfInterestContainer"];
                let text;

                if (isStart) {
                    containerClassNames.push(this.reverse ? "End" : "Start");
                    text = `${areaMatch.type} - Start`;
                } else if (isEnd) {
                    containerClassNames.push(this.reverse ? "Start" : "End");
                    text = `${areaMatch.type} - End`;
                }
                tile.setAttribute("class", containerClassNames.join(" "));

                if (text) {
                    const pElem = L.DomUtil.create("p");
                    const pElemClassNames = ["Text", this.reverse ? "Reverse" : ""];
                    pElem.setAttribute("class", pElemClassNames.join(" "));
                    pElem.innerHTML = text;
                    tile.append(pElem);
                }

                container.append(tile);
            }
        }

        return container;
    },
    destroyTile: function (tile) {
        if (tile) {
            ReactDOM.unmountComponentAtNode(tile);
        }
    },
    _getTilePos: function (coords) {
        let pos = L.GridLayer.prototype._getTilePos.call(this, coords);
        return pos.add([this.xOffset, 0]);
    },
});
