import React, { createRef } from "react";
import "react-day-picker/lib/style.css";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRedoAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Collapse } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import { setSessionTagFilter } from "redux/actions";
import OBCButton from "./OBC/OBCButton";
import { TOGGLE_TAG_PAIRS, tagCategoryProperties, tagIcons, filterSessionTags } from "./util/TagUtils";
import { renderTagPanels } from "./util/TagUtils.js";

class SessionsTags extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tagPopupVisible: false,
        };
        this.tippyRef = createRef();
        this.reqAbortRef = createRef();
    }

    abortGetSessionRequest = () => {
        if (this.reqAbortRef.current) {
            this.reqAbortRef.current.abort("user_abort");
            this.reqAbortRef.current = null;
        }
    };

    onTagClick = (tag) => {
        let tags = _.clone(this.props.tagFilter);

        // if selected tag has tag pair make sure we diselect tag pair
        const tagPairIndex = _.findIndex(TOGGLE_TAG_PAIRS, (t) => {
            return _.includes(t, String(tag));
        });
        if (tagPairIndex >= 0) {
            tags = tags.filter((tag) => !_.includes(TOGGLE_TAG_PAIRS[tagPairIndex], tag));
        }

        if (this.isSelected(tag)) {
            tags = tags.filter((t) => tag.toLowerCase() !== t.toLowerCase());
        } else {
            tags.push(tag);
        }

        this.abortGetSessionRequest();
        const abortController = new AbortController();
        this.reqAbortRef.current = abortController;

        this.props.dispatch(setSessionTagFilter(tags, abortController.signal));
    };

    onClear = () => {
        setTimeout(() => {
            // this.props.onFilter([]);
            this.abortGetSessionRequest();
            const abortController = new AbortController();
            this.reqAbortRef.current = abortController;

            this.props.dispatch(setSessionTagFilter([], abortController.signal));
        }, 10);
        this.hideSearchPopup();
    };

    hideSearchPopup = () => {
        this.tippyRef.current._tippy.hide();
    };

    isSelected = (tag) => {
        const selected = this.props.tagFilter.includes(tag.toLowerCase());
        return selected;
    };

    render() {
        const searchPresent = this.props.tagFilter.length;

        return (
            <Tippy
                placement="bottom"
                ref={this.tippyRef}
                arrow={true}
                sticky={true}
                plugins={[sticky]}
                interactive="true"
                theme="aivr"
                trigger="click"
                maxWidth={"90%"}
                hideOnClick={true}
                onShow={() => {
                    if (_.isEmpty(this.props.sessionTags)) {
                        return false;
                    }
                    this.setState({ tagPopupVisible: true });
                }}
                onHide={() => {
                    this.setState({ tagPopupVisible: false });
                }}
                content={
                    <>
                        <div className="TagControlButtons">
                            <Button
                                className="TagsButton__Clear TagsButton"
                                onClick={this.onClear}
                                disabled={!this.props.tagFilter.length}>
                                Clear
                                <FontAwesomeIcon icon={faRedoAlt} />
                            </Button>
                            <Button
                                className="TagsButton"
                                onClick={this.hideSearchPopup}
                                type="primary">
                                Done
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </Button>
                        </div>
                        <div className="tagFilterContainer">
                            <div className="tagContainer">
                                <Collapse
                                    ghost
                                    defaultActiveKey={Object.keys(tagCategoryProperties).filter((cat) => tagCategoryProperties[cat].defaultOpen)}>
                                    {renderTagPanels(this.props.sessionTags, this.onTagClick, this.props.tagFilter)}
                                </Collapse>
                            </div>
                        </div>
                    </>
                }>
                <div
                    className={"sessionFilterItem"}
                    id="intro-tour-session-filters"
                    style={{ background: "#414664" }}>
                    <OBCButton
                        size="lg"
                        variant={searchPresent ? "primary" : "ghost"}
                        aria-label="Filters"
                        active={this.state.tagPopupVisible}
                        disabled={_.isEmpty(this.props.sessionTags)}>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faFilter}
                        />
                    </OBCButton>
                </div>
            </Tippy>
        );
    }
}

const mapStateToProps = (state) => {
    const currentDashboard = _.find(state.dashboards, (dashboard) => dashboard.access_token === state.access_token);
    const whitelistFilters = _.get(currentDashboard, ["config", "tags_filter_whitelist"]);
    const detectionTypes = state.detectionTypes;
    const sessionTags = filterSessionTags(state.sessionTags, detectionTypes, whitelistFilters);
    const lowercaseTags = state.sessionListFilters.tags.map((tag) => tag.toLowerCase());
    return {
        sessions: state.sessions,
        sessionTags,
        tagFilter: lowercaseTags,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SessionsTags);
