import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Select, Tooltip, Switch, Modal, Button, Popover, Slider } from "antd";
import _ from "lodash";
import {
    setAssetDateFilter,
    setAssetPriorityFilter,
    selectObservation,
    clearRequestedIssue,
    selectAsset,
    setAssetSearchQueryFilter,
    setGroundTruthFilter,
    setAssetGroupFilter,
    getAssetGroups,
    getAssetList,
    getAssetData,
    getGroundTruthedCount,
    getAssetsCount,
    generateIssueAssetReport,
} from "redux/actions";
import OBCSpinner from "components/util/OBC";
import { useDispatch, useSelector } from "react-redux";
// import IssueItem from "./IssueItem";
import DayPicker from "react-day-picker";
import moment from "moment";
import OBCButton from "../OBC/OBCButton";
import OBCSearchInput from "../OBC/OBCSearchInput";
import OBCToggleButton from "components/OBC/OBCToggleButton";
import AssetItem from "components/Issues/AssetItem";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ObservationModal from "components/Issues/ObservationModal";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import { CalendarOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";

const dateFiltersSelector = (state) => state.mlAssets.filters.date;
const searchQueryFilterSelector = (state) => state.mlAssets.filters.searchQuery;
const requestedAssetSelector = (state) => state.mlAssets.requested;
const groundTruthFilterSelector = (state) => state.mlAssets.filters.groundTruth;
const selectedIssueDataSelector = (state) => state.mlAssets.selected;
const selectedAssetSelector = (state) => state.mlAssets.selected.asset;
const assetGroupsSelector = (state) => state.mlAssets.groups;
const assetGroupFilterSelector = (state) => state.mlAssets.filters.group;
const assetObservationsSelector = (state) => state.mlAssets.observations;
const superuserSelector = (state) => state.userDetails.userConfig.super_admin;
const assetIDsSelector = (state) => state.mlAssets.ids;
const assetDataSelector = (state) => state.mlAssets.assetData;

const GUTTER_SIZE = 30;
const ISSUE_ITEM_HEIGHT_CLOSED = 230;
const BOTTOM_OUT_TEMPERATURE_DEFAULT = { min: 0, max: 50 };

const GENERIC_SORT_DATA = [
    {
        value: "last_observed",
        label: "Last Observed",
        short_title: "Most Recent",
        description: "newness observations first",
    },
    {
        value: "observations_count",
        label: "Most Observations",
        short_title: "Observations",
        description: "highest number of observations",
    },
];

const CLASS_SPECIFIC_SORT_DATA = {
    Signal: [
        {
            value: "consensus_coherence",
            label: "Consensus Coherence Desc",
            short_title: "Coherence Desc",
            description: "highest consensus coherence first",
        },
        {
            value: "consensus_coherence_asc",
            label: "Consensus Coherence Asc",
            short_title: "Coherence Asc",
            description: "lowest consensus coherence first",
        },
    ],
    "Structure ID Plate": [
        {
            value: "consensus_coherence",
            label: "Consensus Coherence Desc",
            short_title: "Coherence Desc",
            description: "highest consensus coherence first",
        },
        {
            value: "consensus_coherence_asc",
            label: "Consensus Coherence Asc",
            short_title: "Coherence Asc",
            description: "lowest consensus coherence first",
        },
        {
            value: "position_difference_desc",
            label: "Position Difference Desc",
            short_title: "Position Desc",
            description: "lowest position difference first",
        },
        {
            value: "position_difference_asc",
            label: "Position Difference Asc",
            short_title: "Position Asc",
            description: "largest position difference first",
        },
    ],
    Tensioner: [
        {
            value: "severity",
            label: "Green Zone Overflow (cm)",
            short_title: "Overflow",
            description: "green zone overflow",
        },
        {
            value: "bottom_out_temperature",
            label: "Bottom Out Temperature (degrees)",
            short_title: "Bottom Out Temp",
            description: "bottom out temperature ascending",
            default: true,
        },
    ],
};

const AssetsList = React.memo(({ unreadableUpdated, displayUnreadable, selectedAssetGroup }) => {
    const dispatch = useDispatch();

    const dateFilter = useSelector(dateFiltersSelector);
    const assetGroupFilter = useSelector(assetGroupFilterSelector);
    const requestedAsset = useSelector(requestedAssetSelector);
    const searchQueryFilter = useSelector(searchQueryFilterSelector);
    const groundTruthFilter = useSelector(groundTruthFilterSelector);
    const selectedAsset = useSelector(selectedAssetSelector);
    const assetGroups = useSelector(assetGroupsSelector);
    const superuser = useSelector(superuserSelector);
    const assetIDs = useSelector(assetIDsSelector);
    const assetData = useSelector(assetDataSelector);

    const [listRef, setListRef] = useState();
    const [showFailedMatches, setShowFailedMatches] = useState(false);
    const [sortValue, setSortValue] = useState("last_observed");
    const [initialised, setInitialised] = useState(false);
    const [loading, setLoading] = useState(true);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [classesInitialised, setClassesInitialised] = useState(false);
    const [queryToRequest, setQueryToRequest] = useState("");
    const [showUnreadable, setShowUnreadable] = useState(false);
    const [groundTruthCount, setGroundTruthCount] = useState({});
    const [trustLevelFilter, setTrustLevelFilter] = useState(0);
    const [displayAdditionalFilters, setDisplayAdditionalFilters] = useState(false);
    const [displayCounters, setDisplayCounters] = useState(false);
    const [assetStats, setAssetStats] = useState({});
    const [fitlersOpen, setFilterOpen] = useState(false);
    const [currentlyVisibleTooltip, setCurrentlyVisibleTooltip] = useState(null);
    const [isReportPopoverVisible, setIsReportPopoverVisible] = useState(false);
    const [bottomOutTemp, setBottomOutTemp] = useState({});
    const [temperatureMinMax, setTemperatureMinMax] = useState(BOTTOM_OUT_TEMPERATURE_DEFAULT);
    const [vegetationFilter, setVegetationFilter] = useState("");
    const [jointFilter, setJointFilter] = useState("");
    const setQueryToRequestDebounce = useRef(_.debounce(setQueryToRequest, 1500));
    const dateFrom = new Date(dateFilter.from * 1000);
    const dateTo = new Date(dateFilter.to * 1000);
    const modifiers = { start: dateFrom, end: dateTo };

    // const listRef = useRef();
    const sizeMap = useRef({});
    const apiAbortController = useRef();

    const setSize = useCallback(
        (index, size) => {
            sizeMap.current = { ...sizeMap.current, [index]: size };
            if (listRef) {
                listRef.resetAfterIndex(index);
            }
        },
        [listRef],
    );

    const abortRequest = () => {
        if (apiAbortController.current) {
            apiAbortController.current.abort("user_abort");
            apiAbortController.current = null;
        }
    };

    const assetListRequest = useCallback(
        (bottomOutTemperature = {}) => {
            if (assetGroupFilter) {
                abortRequest();
                setLoading(true);
                const abortController = new AbortController();
                apiAbortController.current = abortController;

                dispatch(
                    getAssetList(
                        assetGroupFilter,
                        groundTruthFilter,
                        queryToRequest,
                        sortValue,
                        showUnreadable,
                        showFailedMatches,
                        trustLevelFilter,
                        dateFilter.from,
                        dateFilter.to,
                        abortController.signal,
                        bottomOutTemperature.max >= 50 ? { ...bottomOutTemperature, max: 99999999 } : bottomOutTemperature,
                        vegetationFilter,
                        jointFilter,
                        () => {
                            apiAbortController.current = null;
                            setInitialised(true);
                            setLoading(false);
                        },
                    ),
                );
            }
        },
        [
            assetGroupFilter,
            dispatch,
            groundTruthFilter,
            queryToRequest,
            sortValue,
            showUnreadable,
            showFailedMatches,
            trustLevelFilter,
            dateFilter,
            vegetationFilter,
            jointFilter,
        ],
    );

    useEffect(() => {
        if (assetGroupFilter === 3) {
            if (_.isEmpty(bottomOutTemp)) {
                setBottomOutTemp(BOTTOM_OUT_TEMPERATURE_DEFAULT);
            }
        } else {
            setBottomOutTemp({});
        }
    }, [assetIDs]);

    const assetListRequestDebounce = useCallback(_.debounce(assetListRequest, 500), [assetListRequest]);

    useEffect(() => {
        if (
            (BOTTOM_OUT_TEMPERATURE_DEFAULT.min !== bottomOutTemp.min || BOTTOM_OUT_TEMPERATURE_DEFAULT.max !== bottomOutTemp.max) &&
            _.has(bottomOutTemp, "max") &&
            _.has(bottomOutTemp, "min")
        ) {
            assetListRequestDebounce(bottomOutTemp);
        }
    }, [bottomOutTemp]);

    useEffect(() => {
        assetListRequest(assetGroupFilter !== 3 ? {} : bottomOutTemp);
    }, [assetListRequest, assetGroupFilter]);

    useEffect(() => {
        if (initialised) {
            if (requestedAsset.asset) {
                dispatch(selectAsset(parseInt(requestedAsset.asset)));
                if (requestedAsset.observation) {
                    dispatch(selectObservation(parseInt(requestedAsset.observation)));
                }
                dispatch(clearRequestedIssue());
            }
        }
    }, [dispatch, initialised, requestedAsset.asset, requestedAsset.observation]);

    const onSearchQueryChanged = useCallback(
        (value) => {
            dispatch(setAssetSearchQueryFilter(value));
            setQueryToRequestDebounce.current(value);
        },
        [dispatch],
    );

    useEffect(() => {
        if (requestedAsset.asset) {
            dispatch(getAssetData(requestedAsset.asset)).then((assetData) => {
                dispatch(setAssetGroupFilter(assetData.issue_group_id));
                onSearchQueryChanged(requestedAsset.asset);
            });
        }
    }, [dispatch, requestedAsset.asset, onSearchQueryChanged]);

    useEffect(() => {
        dispatch(getAssetGroups());
    }, [dispatch]);

    useEffect(() => {
        if (!_.isEmpty(assetGroups) && !assetGroupFilter && !classesInitialised) {
            const firstAssetGroup = Object.values(assetGroups)[0];
            dispatch(setAssetGroupFilter(firstAssetGroup.id));
            setClassesInitialised(true);
        }
    }, [assetGroupFilter, assetGroups, classesInitialised, dispatch]);

    const handleDayClick = (day) => {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        if (day > today) {
            return;
        }
        const newDateFilter = {
            to: moment(new Date()).endOf("day").unix(),
            from: moment(day).startOf("day").unix(),
        };

        dispatch(setAssetDateFilter(newDateFilter));
    };

    const renderDatePicker = () => {
        const today = new Date();
        return (
            <>
                <DayPicker
                    className="Selectable"
                    selectedDays={[dateFrom, { from: dateFrom, to: dateTo }]}
                    modifiers={modifiers}
                    onDayClick={handleDayClick}
                    disabledDays={{ after: today }}
                />
                <div className="DatePickerButtons">
                    <OBCButton
                        className="ButtonReset"
                        disabled={dateFilter.from ? false : true}
                        onClick={() => dispatch(setAssetDateFilter({ from: null, to: null }))}>
                        Reset
                    </OBCButton>
                    <OBCButton
                        className="ButtonOk"
                        variant="secondary"
                        onClick={() => setDatePickerOpen(false)}>
                        Close
                    </OBCButton>
                </div>
            </>
        );
    };

    const getItemSize = (index) => {
        if (sizeMap.current[index]) {
            return Math.max(sizeMap.current[index] + GUTTER_SIZE, ISSUE_ITEM_HEIGHT_CLOSED);
        }
        return ISSUE_ITEM_HEIGHT_CLOSED; // - 30;
    };

    useEffect(() => {
        if (selectedAsset && listRef) {
            const issueIndex = Math.max(
                _.findIndex(assetIDs, (asset) => asset[0] === selectedAsset),
                0,
            );
            if (listRef) {
                listRef.resetAfterIndex(issueIndex);
                listRef.scrollToItem(issueIndex, "smart");
            }
        }

        return () => {
            if (selectedAsset) {
                const issueIndex = Math.max(
                    _.findIndex(assetIDs, (asset) => asset[1] === selectedAsset),
                    0,
                );
                if (listRef) {
                    listRef.resetAfterIndex(issueIndex);
                }
            }
        };
    }, [selectedAsset, assetIDs, listRef]);

    const Row = useCallback(
        ({ index, style }) => {
            const assetID = assetIDs[index][0] || null;

            return (
                <div
                    style={{
                        ...style,
                        left: style.left + 2,
                    }}>
                    <AssetItem
                        index={index}
                        setSize={setSize}
                        key={`${assetID}`}
                        assetID={assetID}
                        selected={parseInt(assetID) === selectedAsset}
                        searchQuery={searchQueryFilter}
                        isIssue={false}
                        minHeight={ISSUE_ITEM_HEIGHT_CLOSED}
                        showFailedMatches={showFailedMatches}
                        autoAck={false}
                    />
                </div>
            );
        },
        [searchQueryFilter, setSize, selectedAsset, showFailedMatches, assetIDs],
    );

    const content = () => {
        if (initialised && !loading && queryToRequest === searchQueryFilter) {
            if (!assetIDs.length) {
                return (
                    <div className="NoIssuesContaner">
                        <h1 className="NoIssuesText">No Assets matching current filters</h1>
                    </div>
                );
            } else {
                return (
                    <div
                        className="IssuesList"
                        id="ScrollList">
                        <AutoSizer className="issuesAutosizer">
                            {({ height, width }) => (
                                <VariableSizeList
                                    height={height}
                                    width={width - 5}
                                    style={{ overflowX: "hidden" }}
                                    itemSize={getItemSize}
                                    ref={(ref) => setListRef(ref)}
                                    itemCount={assetIDs.length}>
                                    {Row.bind(this)}
                                </VariableSizeList>
                            )}
                        </AutoSizer>
                    </div>
                );
            }
        } else {
            return (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }
    };

    const onClassFilterChange = (val) => {
        if (_.get(assetGroups, val, {}).class !== "Structure ID Plate") {
            setVegetationFilter("");
            setJointFilter("");
        }

        if (listRef) {
            listRef.resetAfterIndex(0);
        }
        dispatch(setAssetGroupFilter(val));
    };

    const sortByOptions = useMemo(() => {
        let options = [];

        let allSortData = _.concat(GENERIC_SORT_DATA, _.get(CLASS_SPECIFIC_SORT_DATA, selectedAssetGroup.class, {}));

        options = allSortData.map((option) => (
            <Select.Option
                key={option.value}
                value={option.value}
                label={
                    <>
                        <FontAwesomeIcon
                            icon={faSortAmountDown}
                            style={{ marginRight: "5px" }}
                        />
                        {option.short_title}
                    </>
                }>
                {option.label}
            </Select.Option>
        ));

        return options;
    }, [selectedAssetGroup.class]);

    useEffect(() => {
        if (assetGroupFilter) {
            dispatch(getGroundTruthedCount(assetGroupFilter))
                .then((resp) => setGroundTruthCount(resp))
                .catch((error) => {
                    console.log("Error getting ground truthed data", error);
                });
            dispatch(getAssetsCount(assetGroupFilter)).then((resp) => setAssetStats(resp));
        }
    }, [assetGroupFilter]);

    const toggleAdditionalFilters = () => {
        if (displayAdditionalFilters) {
            setTrustLevelFilter(0);
            setShowUnreadable(false);
            setShowFailedMatches(false);
            setVegetationFilter("");
            setJointFilter("");
        }
        setDisplayAdditionalFilters(!displayAdditionalFilters);
    };

    const requestAssetReport = () => {
        const startDate = moment.unix(dateFilter.from).format("YYYY-MM-DD");
        const endDate = moment.unix(dateFilter.to).format("YYYY-MM-DD");
        const parameters = {
            asset_group_id: assetGroupFilter,
            start_date: startDate,
        };

        if (startDate !== endDate) {
            parameters["end_date"] = endDate;
        }

        dispatch(generateIssueAssetReport(parameters, "asset_report_export"));
    };

    return (
        <div className="IssuesContentTab Assets">
            <Modal
                className="AssetStats"
                title={assetGroupFilter === 1 ? "Type: Signal" : assetGroupFilter === 2 ? "Type: Structure ID Plate" : ""}
                footer={
                    <Button
                        onClick={() => setDisplayCounters(false)}
                        type="primary">
                        Close
                    </Button>
                }
                visible={displayCounters}
                onCancel={() => setDisplayCounters(false)}>
                <ul className="AssetStatsList">
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">AIVR (any):</span> {assetStats.aivr_any}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">AIVR only:</span> {assetStats.aivr_only}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">AIVR and External:</span> {assetStats.aivr_and_external}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">External only:</span> {assetStats.external_count}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">Unreadable count:</span> {assetStats.unreadable_assets}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">Ground truthed:</span> {groundTruthCount.ground_truthed}
                    </li>
                    <li className="AssetStatsItem">
                        <span className="AssetStatsItemBold">To be ground truthed:</span> {groundTruthCount.to_be_ground_truthed}
                    </li>
                </ul>
            </Modal>
            <div className="IssueFilterContainer">
                <div className="LeftColumn">
                    <div className="Row">
                        <div className="IssuesFilterSelect">
                            <div
                                className="FilterButton"
                                onClick={() => assetListRequest(bottomOutTemp)}>
                                <SyncOutlined className="icon" />
                            </div>
                        </div>

                        <div className="FilterItemDatePicker">
                            <Tooltip
                                overlayClassName="IssueTabDatePicker"
                                overlayStyle={{ opacity: 1 }}
                                title={renderDatePicker}
                                visible={datePickerOpen}
                                placement="bottomLeft"
                                trigger="click"
                                onVisibleChange={(visible) => setDatePickerOpen(visible)}>
                                <OBCToggleButton
                                    toggled={datePickerOpen}
                                    active={dateFilter.from}>
                                    <CalendarOutlined />
                                </OBCToggleButton>
                            </Tooltip>
                        </div>

                        <div className="IssuesFilterSelect">
                            <span>Type:</span>
                            <Select
                                value={assetGroupFilter}
                                onChange={(val) => onClassFilterChange(val)}
                                dropdownMatchSelectWidth={false}>
                                {Object.values(assetGroups).map((issueGroup) => {
                                    let nameToDisplay = `${issueGroup.class}`;
                                    if (issueGroup.display_name) {
                                        nameToDisplay = issueGroup.display_name;
                                    }
                                    return (
                                        <Select.Option
                                            value={issueGroup.id}
                                            key={issueGroup.id}>
                                            {nameToDisplay}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            {/* <Select value={classFilter} onChange={val => onClassFilterChange(val)} dropdownMatchSelectWidth={false}>
                                {classOptions.map(issueClass => {
                                    return <Select.Option value={issueClass} key={issueClass}>
                                        {_.get(assetGroups, [issueClass, "display_name"], issueClass) || issueClass}
                                    </Select.Option>
                                })}
                            </Select> */}
                        </div>
                        <div className="IssuesFilterSelect">
                            <span>Ground Truth Data:</span>
                            <Select
                                value={groundTruthFilter}
                                onChange={(val) => dispatch(setGroundTruthFilter(val))}
                                dropdownMatchSelectWidth={false}>
                                <Select.Option value={null}>All</Select.Option>
                                <Select.Option value={true}>Set</Select.Option>
                                <Select.Option value={false}>Not Set</Select.Option>
                            </Select>
                        </div>
                        <div className="IssuesFilterSelect">
                            <span>Sort:</span>
                            <Select
                                optionLabelProp="label"
                                value={sortValue}
                                onChange={setSortValue}
                                dropdownMatchSelectWidth={false}>
                                {sortByOptions}
                            </Select>
                        </div>
                        <div className="IssuesFilterSelect">
                            <span>Report</span>
                            <Tooltip
                                mouseEnterDelay={0.5}
                                title={`Generate ${_.get(selectedAssetGroup, ["display_name"], selectedAssetGroup.class) || selectedAssetGroup.class} Report`}
                                visible={isReportPopoverVisible ? false : currentlyVisibleTooltip === "report"}
                                onVisibleChange={(val) => setCurrentlyVisibleTooltip(!val || "report")}>
                                <div className="RequestIssuesReport">
                                    <Popover
                                        title={`${selectedAssetGroup.class} Report`}
                                        content={
                                            <div className="ThermalIssueReportPopover">
                                                <div>
                                                    Select a date / date range to enable{" "}
                                                    {_.get(selectedAssetGroup, ["display_name"], selectedAssetGroup.class) || selectedAssetGroup.class} Report
                                                    generation.
                                                </div>
                                                <OBCButton
                                                    onClick={() => {
                                                        setIsReportPopoverVisible(false);
                                                        setFilterOpen(true);
                                                        setDatePickerOpen(true);
                                                    }}>
                                                    <CalendarOutlined /> Open Date Picker
                                                </OBCButton>
                                            </div>
                                        }
                                        trigger="click"
                                        placement="bottom"
                                        visible={isReportPopoverVisible && (!dateFilter.to || !dateFilter.from)}
                                        onVisibleChange={(value) => {
                                            setCurrentlyVisibleTooltip(null);
                                            if (dateFilter.to || dateFilter.from) {
                                                setIsReportPopoverVisible(false);
                                            } else {
                                                setIsReportPopoverVisible(value);
                                            }
                                        }}>
                                        {/* <p>TESTING HERE</p> */}
                                        <OBCToggleButton
                                            icon={faFileDownload}
                                            onClick={() => {
                                                if (dateFilter.to || dateFilter.from) {
                                                    requestAssetReport();
                                                    setIsReportPopoverVisible(false);
                                                }
                                            }}
                                        />
                                    </Popover>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="IssuesFilterSelect">
                            <Tippy
                                content={`${displayAdditionalFilters ? "Hide" : "View"} additional filters`}
                                placement="top"
                                duration="500">
                                <div
                                    className="FilterButton"
                                    onClick={toggleAdditionalFilters}>
                                    {displayAdditionalFilters ? <MinusOutlined className="icon" /> : <PlusOutlined className="icon" />}
                                </div>
                            </Tippy>
                        </div>
                        <div className="IssuesFilterSelect">
                            <div
                                className="FilterButton"
                                onClick={() => setDisplayCounters(true)}>
                                <InfoCircleOutlined className="icon" />
                            </div>
                        </div>
                    </div>

                    {displayAdditionalFilters && (
                        <div className="Row">
                            <div className="IssuesFilterSelect">
                                <span>Trust Level:</span>
                                <Select
                                    value={trustLevelFilter}
                                    onChange={setTrustLevelFilter}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "90px" }}>
                                    <Select.Option value={0}>All</Select.Option>
                                    <Select.Option value={1}>Level 1</Select.Option>
                                    <Select.Option value={2}>Level 2</Select.Option>
                                    <Select.Option value={3}>Level 3</Select.Option>
                                    <Select.Option value={4}>Level 4</Select.Option>
                                    <Select.Option value={5}>Level 5</Select.Option>
                                    <Select.Option value={6}>Level 6</Select.Option>
                                </Select>
                            </div>
                            {_.get(assetGroups, assetGroupFilter, {}).class === "Structure ID Plate" && (
                                <div className="IssuesFilterSelect">
                                    <span>Vegetation:</span>
                                    <Select
                                        value={vegetationFilter}
                                        onChange={(e) => setVegetationFilter(e)}
                                        style={{ width: "160px" }}
                                        dropdownMatchSelectWidth={false}>
                                        <Select.Option value={""}>All</Select.Option>
                                        <Select.Option value={"any"}>Any vegetation observed</Select.Option>
                                        <Select.Option value={"ok"}>Acceptable vegetation observed</Select.Option>
                                        <Select.Option value={"vegetation_around_base"}>Vegetation around the base</Select.Option>
                                        <Select.Option value={"bad"}>Unacceptable vegetation observed</Select.Option>
                                    </Select>
                                </div>
                            )}
                            {_.get(assetGroups, assetGroupFilter, {}).class === "Joint" && (
                                <div className="IssuesFilterSelect">
                                    <span>Joint Type:</span>
                                    <Select
                                        value={jointFilter}
                                        onChange={(e) => setJointFilter(e)}
                                        style={{ width: "160px" }}
                                        dropdownMatchSelectWidth={false}>
                                        <Select.Option value={""}>Not set</Select.Option>
                                        <Select.Option value={"Four_Bolt_Encapsulated"}>Four Bolt Encapsulated</Select.Option>
                                        <Select.Option value={"Four_Bolt_Glued"}>Four Bolt Glued</Select.Option>
                                        <Select.Option value={"Four_Bolt_Expansion"}>Four Bolt Expansion</Select.Option>
                                        <Select.Option value={"Six_Bolt_Encapsulated"}>Six Bolt Encapsulated</Select.Option>
                                        <Select.Option value={"Six_Bolt_Glued"}>Six Bolt Glued</Select.Option>
                                        <Select.Option value={"Tight"}>Tight Joint</Select.Option>
                                        <Select.Option value={"Temporary_Or_Clamp"}>Temporary Joint Or Clamp</Select.Option>
                                        <Select.Option value={"Other"}>Other</Select.Option>
                                    </Select>
                                </div>
                            )}
                            {displayUnreadable && (
                                <div className="IssuesFilterSelect">
                                    <span>Unreadable:</span>
                                    <div className="SwitchContainer">
                                        <Switch
                                            className="Switch"
                                            checked={showUnreadable}
                                            onChange={(checked) => setShowUnreadable(checked)}
                                        />
                                    </div>
                                </div>
                            )}
                            {superuser && (
                                <div className="IssuesFilterSelect">
                                    <span>Show failed:</span>
                                    <div className="SwitchContainer">
                                        <Switch
                                            className="Switch"
                                            checked={showFailedMatches}
                                            onChange={(checked) => setShowFailedMatches(checked)}
                                        />
                                    </div>
                                </div>
                            )}
                            {assetGroupFilter === 3 && (
                                <div className="IssuesFilterSlider">
                                    <div className="SliderLabel">
                                        Bottom out temperature:{" "}
                                        {bottomOutTemp.min === BOTTOM_OUT_TEMPERATURE_DEFAULT.min &&
                                        bottomOutTemp.max === BOTTOM_OUT_TEMPERATURE_DEFAULT.max ? (
                                            <p style={{ color: "#ffb100" }}>Show all</p>
                                        ) : (
                                            <p>
                                                {bottomOutTemp.min} -{" "}
                                                <span style={{ color: "#ffb100" }}>{bottomOutTemp.max === 50 ? "50°C+" : bottomOutTemp.max}</span>
                                            </p>
                                        )}
                                    </div>
                                    <Slider
                                        className="BottomOutTemp"
                                        range
                                        min={BOTTOM_OUT_TEMPERATURE_DEFAULT.min}
                                        max={BOTTOM_OUT_TEMPERATURE_DEFAULT.max}
                                        tooltipVisible={false}
                                        onChange={(e) => setBottomOutTemp({ min: e[0], max: e[1] })}
                                        value={[bottomOutTemp.min, bottomOutTemp.max]}
                                        marks={{
                                            [BOTTOM_OUT_TEMPERATURE_DEFAULT.min]: {
                                                style: {
                                                    color: "#fff",
                                                },
                                                label: <strong>{BOTTOM_OUT_TEMPERATURE_DEFAULT.min}°C</strong>,
                                            },
                                            [BOTTOM_OUT_TEMPERATURE_DEFAULT.max]: {
                                                style: {
                                                    color: "#ffb100",
                                                },
                                                label: <strong>{BOTTOM_OUT_TEMPERATURE_DEFAULT.max}°C+</strong>,
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="RightColumn">
                    <OBCSearchInput
                        className="IssuesFilterSearchInput"
                        placeholder="Asset ID or ELR"
                        onChange={onSearchQueryChanged}
                        value={searchQueryFilter}
                    />
                </div>
            </div>
            {!loading && <p className="SelectedAssetsCount">Selected asset count: {assetIDs.length}</p>}
            {content()}
        </div>
    );
});

const AssetsTab = () => {
    const dispatch = useDispatch();
    const selectedData = useSelector(selectedIssueDataSelector);
    const assetObservations = useSelector(assetObservationsSelector);
    const assetGroupFilter = useSelector(assetGroupFilterSelector);
    const assetGroups = useSelector(assetGroupsSelector);

    const [unreadableUpdated, setUnreadableUpdated] = useState(false);

    const selectedAssetGroup = useMemo(() => {
        return _.get(assetGroups, assetGroupFilter, {});
    }, [assetGroups, assetGroupFilter]);

    const displayUnreadable = useMemo(() => {
        return ["Signal", "Structure ID Plate"].includes(selectedAssetGroup.class);
    }, [selectedAssetGroup]);

    return (
        <>
            {!_.isNil(selectedData.observation) && (
                <ObservationModal
                    isIssue={false}
                    closeModal={() => dispatch(selectObservation(null))}
                    observations={assetObservations.observations}
                    unreadableUpdated={unreadableUpdated}
                    setUnreadableUpdated={(e) => setUnreadableUpdated(e)}
                    displayUnreadable={displayUnreadable}
                />
            )}
            <AssetsList
                unreadableUpdated={unreadableUpdated}
                displayUnreadable={displayUnreadable}
                selectedAssetGroup={selectedAssetGroup}
            />
        </>
    );
};

export default AssetsTab;
