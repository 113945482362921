import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import "./QATab.scss";
import QATabItem from "./QATabItem";
import OBCSpinner from "components/util/OBC";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getQaSessions, getFlaggedSessionsByUser, setCurrentQaTab } from "redux/actions/index";
import { Menu } from "antd";
import { faFlag, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchOutlined } from "@ant-design/icons";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const ALL_FLAGGED_SESSIONS_ITEM_HEIGHT = 125;

const QATab = () => {
    const [qaSessions, setQaSessions] = useState([]);
    const [yourFlaggedSessions, setYourFlaggedSessions] = useState([]);
    const [allFlaggedSessions, setAllFlaggedSessions] = useState([]);
    const [gettingSessions, setGettingSessions] = useState(true);
    const [noSessionMessage, setNoSessionMessage] = useState("No sessions to QA in this workspace.");
    const [userSearch, setUserSearch] = useState("");
    const searchBox = useRef(null);
    const currentTab = useSelector((state) => state.currentQaTab);
    const sessionList = useRef(null);

    const dispatch = useDispatch();

    const getNewSessions = useCallback(() => {
        dispatch(getQaSessions()).then((response) => {
            setQaSessions(response);
            setGettingSessions(false);
        });
    }, [dispatch]);

    const getFlaggedSessions = useCallback(() => {
        dispatch(getFlaggedSessionsByUser(true)).then((response) => {
            setYourFlaggedSessions(response);
        });
        dispatch(getFlaggedSessionsByUser(false)).then((response) => {
            setAllFlaggedSessions(response);
        });
    }, [dispatch]);

    const filteredAllFlaggedSessions = useMemo(
        () => _.filter(_.reverse([...allFlaggedSessions]), (sessionId) => `${sessionId}`.indexOf(userSearch) !== -1),
        [userSearch, allFlaggedSessions],
    );

    useEffect(() => {
        getNewSessions();
        getFlaggedSessions();
    }, [getFlaggedSessions, getNewSessions]);

    const setTabView = (option) => {
        dispatch(setCurrentQaTab(parseInt(option)));
        setUserSearch("");
        if (option === "1") {
            setNoSessionMessage("No sessions to QA in this workspace.");
        } else {
            setNoSessionMessage("You have no flagged sessions in this workspace");
        }
    };

    const renderSessions = useMemo(
        () =>
            _.map(qaSessions, (session) => (
                <QATabItem
                    key={session}
                    sessionId={session}
                    getNewSessions={getNewSessions}
                    getFlaggedSessions={getFlaggedSessions}
                    currentTab={currentTab}
                    userSearch={userSearch}
                />
            )),
        [currentTab, getFlaggedSessions, getNewSessions, qaSessions, userSearch],
    );

    const renderYourFlaggedSessions = useMemo(
        () =>
            _.map(yourFlaggedSessions, (session) => (
                <QATabItem
                    key={session}
                    sessionId={session}
                    getNewSessions={getNewSessions}
                    getFlaggedSessions={getFlaggedSessions}
                    currentTab={currentTab}
                    userSearch={userSearch}
                />
            )),
        [yourFlaggedSessions, userSearch],
    );

    const GUTTER_SIZE = 8;

    const Row = ({ index, style }) => {
        return (
            <QATabItem
                key={filteredAllFlaggedSessions[index]}
                sessionId={filteredAllFlaggedSessions[index]}
                getNewSessions={getNewSessions}
                getFlaggedSessions={getFlaggedSessions}
                currentTab={currentTab}
                style={{
                    ...style,
                    left: style.left + GUTTER_SIZE,
                    top: index === 0 ? style.top : style.top + GUTTER_SIZE,
                    height: index === 0 ? style.height : style.height - GUTTER_SIZE,
                }}
            />
        );
    };

    let allFlaggedSessionsContent = useMemo(
        () => (
            <AutoSizer className="sessionAutosizer">
                {({ height, width }) => (
                    <FixedSizeList
                        height={height + GUTTER_SIZE}
                        width={width}
                        itemSize={ALL_FLAGGED_SESSIONS_ITEM_HEIGHT}
                        ref={sessionList}
                        itemCount={filteredAllFlaggedSessions.length}>
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer>
        ),
        [filteredAllFlaggedSessions],
    );

    const sessionsContent = useMemo(
        () =>
            qaSessions.length > 0 && currentTab === 1 ? (
                renderSessions
            ) : yourFlaggedSessions.length > 0 && currentTab === 2 ? (
                renderYourFlaggedSessions
            ) : allFlaggedSessions.length > 0 && currentTab === 3 ? (
                allFlaggedSessionsContent
            ) : (
                <p className="qaTab-text">{noSessionMessage}</p>
            ),
        [renderSessions, renderYourFlaggedSessions, allFlaggedSessionsContent, currentTab],
    );

    return (
        <div className="qaTab">
            <div className="topTabs">
                <Menu
                    mode="horizontal"
                    className="qaTab__subMenu"
                    theme="dark"
                    onClick={(e) => setTabView(e.key)}>
                    <Menu.Item
                        key={1}
                        className={currentTab === 1 ? "qaTab__subMenu--option" : ""}>
                        <FontAwesomeIcon
                            icon={faVideo}
                            style={{ marginRight: "10px" }}
                        />
                        Your sessions
                    </Menu.Item>
                    <Menu.Item
                        key={2}
                        className={currentTab === 2 ? "qaTab__subMenu--option" : ""}>
                        <FontAwesomeIcon
                            icon={faFlag}
                            style={yourFlaggedSessions.length > 0 ? { marginRight: "10px", color: "#ff0000" } : { marginRight: "10px" }}
                        />
                        Your Flagged Sessions {yourFlaggedSessions.length > 0 ? `(${yourFlaggedSessions.length})` : ""}
                    </Menu.Item>
                    <Menu.Item
                        key={3}
                        className={currentTab === 3 ? "qaTab__subMenu--option" : ""}>
                        <FontAwesomeIcon
                            icon={faFlag}
                            style={allFlaggedSessions.length > 0 ? { marginRight: "10px", color: "#ff0000" } : { marginRight: "10px" }}
                        />
                        All Flagged Sessions {allFlaggedSessions.length > 0 ? `(${allFlaggedSessions.length})` : ""}
                    </Menu.Item>
                </Menu>

                <div className="qaTab__search">
                    <div
                        className="qaTab__search--container"
                        onClick={() => searchBox.current.focus()}>
                        <SearchOutlined />
                        <input
                            ref={searchBox}
                            className="qaTab__search--input"
                            value={userSearch}
                            onChange={(e) => setUserSearch(e.target.value)}
                            placeholder="Search for a session"
                        />
                    </div>
                </div>
            </div>

            <div className="qaSessionsContent">
                {!gettingSessions ? (
                    sessionsContent
                ) : (
                    <div className="qaTabLoading">
                        <OBCSpinner
                            size={70}
                            speed={3}
                            color={"#e8dfff"}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default QATab;
