import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Map, TileLayer, Marker } from "react-leaflet";
import { useDispatch } from "react-redux";

import { Layout, Modal, Input, Steps, Alert, notification, Select, Tag } from "antd";
import AVIRlogo from "../../images/new-branding-2.0/aivr-hybrid.svg";
import greenCheckIcon from "../../icons/check-circle-green.png";
import pointerHand from "../../icons/hand_pointer.png";
import redCrossIcon from "../../icons/cross-circle-red.png";
import { MEMOIZED_DOMAIN_URL } from "components/util/HostUtils.js";
import OBCButton from "../OBC/OBCButton";
import OBCSpinner from "components/util/OBC";
import OBCProgressBar from "components/OBC/OBCProgressBar";
import _ from "lodash";
import { useMemo } from "react";
import { faCloudUpload, faMapMarkedAlt, faInfoSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EXIF from "exif-js";
import { getWorkspaceNameByUploadToken, uploadUserMedia, confirmUserMediaUploaded } from "redux/actions";
import LoadingOverlay from "components/util/LoadingOverlay";

import "../../style/mediaUploader.scss";
import "leaflet/dist/leaflet.css";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

const { Header, Footer, Content } = Layout;
const { Step } = Steps;
const { confirm } = Modal;
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const MediaUploadsPage = (props) => {
    const dispatch = useDispatch();
    const textInput = useRef(null);
    const inputFileRef = useRef(null);

    const [uploadToken, setUploadToken] = useState(null);
    const [freshStart, setFreshStart] = useState(true);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadModalStep, setUploadModalStep] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState([]);

    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const [uploadWorkspace, setUploadWorkspace] = useState(false);
    const [uploadTokenDescription, setUploadTokenDescription] = useState(false);
    const [uploadName, setUploadName] = useState("");
    const [uploadNameTouched, setUploadNameTouched] = useState(false);
    const [uploadDescription, setUploadDescription] = useState("");
    const [availableTags, setAvailableTags] = useState([]);
    const [uploadTags, setUploadTags] = useState({});
    const [defaultTags, setDefaultTags] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    const [currentlyUploading, setCurrentlyUploading] = useState(0);
    const [submittionSuccess, setSubmittionSuccess] = useState(undefined);
    const [errorUploading, setErrorUploading] = useState(false);
    const [queuedUploads, setQueuedUploads] = useState([]);
    const [uploadPaths, setUploadPaths] = useState([]);
    const [uploadLinks, setUploadLinks] = useState([]);
    const [selectionChanged, setSelectionChanged] = useState(false);

    const [imageCoordinates, setImageCoordinates] = useState(null);
    const [deviceLocationUsed, setDeviceLocationUsed] = useState(false);
    const [deviceLocationErrorMsg, setDeviceLocationErrorMsg] = useState(null);
    const [centerMarker, setCenterMarker] = useState(null);
    const [mapInstructionOverlayVisible, setMapInstructionOverlayVisible] = useState(true);

    useEffect(() => {
        document.title = "AIVR - Media Uploader";
        const token = props.match.params.token;
        dispatch(
            getWorkspaceNameByUploadToken(token, (details) => {
                setUploadWorkspace(details.workspace_name);
                setUploadTokenDescription(details.token_description);
                setAvailableTags(details.tags);
                document.title = "AIVR - Media Uploader [" + details.workspace_name + "]";
            }),
        );
        setUploadToken(token);
    }, []);

    // autoFocus on input when uploadNameModal is open
    useEffect(() => {
        if (textInput && textInput.current) {
            textInput.current.focus();
        }
        if (!uploadModalOpen && !submittionSuccess) {
            // add time out to let close animation finish befe data is cleared
            setTimeout(() => {
                setUploadName("");
                setSelectedMedia([]);
                setImageCoordinates(null);
            }, 300);
        }
    }, [uploadModalOpen]);

    // if submitting chenged to true
    useEffect(() => {
        if (submitting) {
            onSubmitClicked();
            setCurrentlyUploading(1);
            setErrorUploading(false);
            goToStep(4);
        }
    }, [submitting]);

    const onStartButtonClicked = () => {
        setUploadModalOpen(true);
        setUploadModalStep(0);
        setUploadName("");
        setUploadLinks([]);
        setImageCoordinates(null);
        setUploadDescription("");
        setSelectedMedia([]);
        setSubmittionSuccess(null);
        setCenterMarker(null);
        setDeviceLocationUsed(false);
        setMapInstructionOverlayVisible(true);
        setUploadTags(availableTags.reduce((acc, cur) => ({ ...acc, [cur["Group"]]: [] }), {}));
        setDefaultTags([]);
    };

    const onUploadModalClose = () => {
        if (uploadModalStep !== 0 && uploadModalStep !== 5) {
            confirm({
                title: "Do you really want to Cancel?",
                content: "All data entered so far will be lost",
                okText: "Yes",
                cancelText: "No",
                onOk: () => setUploadModalOpen(false),
            });
        } else {
            setUploadModalOpen(false);
        }
    };

    const onUploadNameChanged = (value) => {
        if (!uploadNameTouched) {
            setUploadNameTouched(true);
        }
        setUploadName(value);
    };

    const onAddMediaButtonClicked = () => {
        inputFileRef.current.click();
    };

    const startUpload = (uploads) => {
        let { fileObj, fileType, url } = uploads[0];

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", fileType);
        console.log("debug uploads", uploads);

        const noProgressAfter30SecondsTimeout = setTimeout(() => {
            setErrorUploading(true);
            xhr.abort();
        }, 30000);

        const handleProgress = (event) => {
            clearTimeout(noProgressAfter30SecondsTimeout);
        };

        const handleSuccess = () => {
            if (xhr.status == 200) {
                let newUploads = _.filter(uploads, (obj) => obj.fileObj.name !== fileObj.name);
                // console.log('debug newUploads', newUploads);
                setQueuedUploads(newUploads);
            } else if (xhr.status >= 400) {
                // console.log('debug ERROR WHILE UPLOADING');
                setErrorUploading(true);
            }
        };

        const handleFailure = () => {
            // console.log('debug ERROR WHILE UPLOADING');
            setErrorUploading(true);
        };

        xhr.onabort = handleFailure;
        xhr.ontimeout = handleFailure;
        xhr.onload = handleSuccess;
        xhr.upload.onprogress = handleProgress;

        try {
            xhr.send(fileObj);
        } catch (ex) {
            // console.log("debug Exception starting upload: ", ex);
            xhr.onerror(ex);
        }
    };

    const getDeviceLocation = () => {
        if (!imageCoordinates) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setDeviceLocationUsed(true);
                    setImageCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude });
                },
                (error) => {
                    setDeviceLocationUsed(false);
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setDeviceLocationErrorMsg(
                                <span>
                                    User denied the request for Geolocation. <strong>Location will have to be set manually</strong>
                                </span>,
                            );
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setDeviceLocationErrorMsg(
                                <span>
                                    Location information is unavailable. <strong>Location will have to be set manually</strong>
                                </span>,
                            );
                            break;
                        case error.TIMEOUT:
                            setDeviceLocationErrorMsg(
                                <span>
                                    The request to get user location timed out. <strong>Location will have to be set manually</strong>
                                </span>,
                            );
                            break;
                        case error.UNKNOWN_ERROR:
                            setDeviceLocationErrorMsg(
                                <span>
                                    An unknown error occurred. <strong>Location will have to be set manually</strong>
                                </span>,
                            );
                            break;
                    }
                    notification.error({
                        message: "Error getting location",
                        description: (
                            <span>
                                Unable to get current location
                                <br />
                                Please check your device and browser settings then try again
                            </span>
                        ),
                        duration: 0,
                    });
                },
            );
        }
    };

    const onFilesChanged = (files, addNew = false) => {
        // convert lat and lng
        const convert = (array, southOrWest = true) => {
            if (array) {
                return array[0] * (southOrWest ? -1 : 1) + (array[1] * (southOrWest ? -60 : 60) + array[2] * (southOrWest ? -1 : 1)) / 3600;
            } else {
                return null;
            }
        };

        let gpsData = false;
        if (files && files[0]) {
            setFreshStart(false);
            let selected = [];

            if (addNew) {
                selected = _.cloneDeep(selectedMedia);
            } else {
                setImageCoordinates(null);
            }

            let filesLenght = files.length - 1;

            _.forEach(files, (file) => {
                if (file.size > 0) {
                    EXIF.getData(file, function () {
                        var exifLong = EXIF.getTag(this, "GPSLongitude");
                        var exifLongRef = EXIF.getTag(this, "GPSLongitudeRef");
                        var exifLat = EXIF.getTag(this, "GPSLatitude");
                        var exifLatRef = EXIF.getTag(this, "GPSLatitudeRef");

                        let latitude = null;
                        let longitude = null;
                        latitude = convert(exifLat, exifLatRef === "S");
                        longitude = convert(exifLong, exifLongRef === "W");
                        console.log("debug filesLenght", filesLenght);

                        if (!addNew) {
                            if (latitude && longitude && !gpsData) {
                                console.log("debug GPS DATA FOUND");
                                setImageCoordinates({ lat: latitude, lng: longitude });
                                setCenterMarker([latitude, longitude]);
                                gpsData = true;
                            }
                            if (filesLenght === 0 && !gpsData) {
                                // if last image in the list get device location if not in images
                                // this will be the only way to tell if above function has finished
                                // so we can perform check for gpsData
                                console.log("debug No location in pictures, get device location");
                                getDeviceLocation();
                            }
                        }
                        filesLenght -= 1;
                    });
                    selected.push(file);
                }
            });

            setSelectedMedia(selected);

            // go to step 1 if this is initial media selected...
            if (!addNew) {
                setUploadModalStep(1);
            }
        }
    };

    const confirmLocation = () => {
        setImageCoordinates({ lat: centerMarker[0], lng: centerMarker[1] });
        goToNextStep();
    };

    const goToStep = (step) => {
        setUploadModalStep(step);
    };

    const goToNextStep = () => {
        if (uploadModalStep < 3 && uploadModalStep > 0) {
            setUploadModalStep(uploadModalStep + 1);
        }
    };

    const goToPrevStep = () => {
        if (uploadModalStep > 1) {
            setUploadModalStep(uploadModalStep - 1);
        }
    };

    const onDescriptionChanged = (e) => {
        setUploadDescription(e.target.value);
    };

    const onSubmitClicked = () => {
        let mediaArray = [];
        _.forEach(selectedMedia, (media) => mediaArray.push({ name: media.name, type: media.type }));
        // if error ocured, resume where error ocured
        if (errorUploading && !selectionChanged) {
            let queuedFilesObject = [];
            let uploadLinks_ = [];
            let uploadPaths_ = [];
            setQueuedUploads([]);
            _.forEach(uploadLinks, (link, name) => {
                const fileObj = _.find(selectedMedia, (file) => file.name === name);
                queuedFilesObject.push({ fileObj: fileObj, fileType: fileObj.type, url: link });
                uploadPaths_.push(link.upload_path);
                uploadLinks_.push(link.upload_link);
            });
            setQueuedUploads(queuedFilesObject);
            setUploadLinks(uploadLinks_);
            setUploadPaths(uploadPaths_);
            setErrorUploading(false);
            setSelectionChanged(false);
        } else {
            dispatch(
                uploadUserMedia(uploadToken, uploadName, uploadDescription, mediaArray, imageCoordinates, uploadTags, (response) => {
                    let queuedFilesObject = [];
                    let uploadLinks = [];
                    let uploadPaths = [];
                    if (response.success) {
                        const uploadLinks_ = response.upload_links;
                        _.forEach(uploadLinks_, (link, name) => {
                            const fileObj = _.find(selectedMedia, (file) => file.name === name);
                            queuedFilesObject.push({ fileObj: fileObj, fileType: fileObj.type, url: link.upload_link });
                            uploadPaths.push(link.upload_path);
                            uploadLinks.push(link.upload_link);
                        });
                        setUploadLinks(uploadLinks);
                        setUploadPaths(uploadPaths);
                        setQueuedUploads(queuedFilesObject);
                    }
                }),
            );
        }
    };

    useEffect(() => {
        // console.log("debug CHANGED queuedUploads");
        // if error detected stop and go back to step 5
        if (errorUploading) {
            // console.log('debug error detected');
            setSubmitting(false);
            goToStep(5);
        } else {
            if (queuedUploads.length !== 0) {
                setCurrentlyUploading(selectedMedia.length - queuedUploads.length + 1);
                startUpload(queuedUploads);
            } else {
                if (!freshStart) {
                    console.log("debug uploading completed!", uploadPaths);
                    dispatch(
                        confirmUserMediaUploaded(uploadToken, uploadPaths, (res) => {
                            // send to backend message that upload completed and wait for success response
                            if (res.success) {
                                setSubmittionSuccess(true);
                            } else {
                                setSubmittionSuccess(false);
                            }
                            setSubmitting(false);
                            goToStep(5);
                        }),
                    );
                }
            }
        }
    }, [queuedUploads, errorUploading]);

    // if user change selected media (add or remove)
    useEffect(() => {
        if (selectedMedia.length === 0) {
            setDeviceLocationUsed(false);
            setImageCoordinates(null);
        }
        setSelectionChanged(true);
        setCurrentlyUploading(1);
    }, [selectedMedia]);

    const renderWelcomeMesage = useMemo(() => {
        if (uploadWorkspace) {
            return (
                <div className="NoMediaMessage">
                    <h2>No media available</h2>
                    <p>
                        {" "}
                        on <strong>Start</strong> to upload media
                    </p>
                </div>
            );
        } else if (uploadWorkspace === null) {
            return (
                <div className="InvalidTokenMediaMessage">
                    <h2>Provided link is invalid or has expired</h2>
                    <p>
                        Please contact the workspace owner to obtain a new link or get in touch with our{" "}
                        <OBCButton
                            variant="link"
                            onClick={() => console.log("debug contact clicked!")}>
                            support
                        </OBCButton>{" "}
                        team if you need further assistance.
                    </p>
                </div>
            );
        }
    }, [uploadWorkspace]);

    const renderSelectedPictures = useMemo(() => {
        const removeImage = (item) => {
            let newArray = _.filter(selectedMedia, (media) => media !== item);
            setSelectedMedia(newArray);
        };

        let images = _.map(selectedMedia, (media) => {
            return (
                <div
                    className="UploadedImage"
                    onClick={() => {
                        if (!uploadModalOpen) {
                            setPreviewImage(media);
                            setPreviewModalOpen(true);
                        }
                    }}>
                    {!submittionSuccess && (
                        <CloseOutlined
                            className="CloseButton"
                            onClick={() => removeImage(media)}
                        />
                    )}
                    <img
                        src={URL.createObjectURL(media)}
                        crossOrigin={"anonymous"}
                    />
                </div>
            );
        });

        return images;
    }, [selectedMedia, submittionSuccess, uploadModalOpen]);

    const renderModalFooter = useMemo(() => {
        // eslint-disable-next-line default-case
        switch (uploadModalStep) {
            // Upload Name
            case 0:
                return (
                    <div className="Footer">
                        <OBCButton
                            className="ButtonStart"
                            variant="ghost"
                            onClick={onUploadModalClose}>
                            Cancel
                        </OBCButton>
                    </div>
                );

            // Location
            case 1:
                return (
                    <div className="Footer">
                        <OBCButton
                            className="ButtonStart"
                            variant="ghost"
                            onClick={onUploadModalClose}>
                            Cancel
                        </OBCButton>
                        <OBCButton
                            disabled={selectedMedia.length === 0 || !centerMarker}
                            variant="primary"
                            onClick={() => confirmLocation()}>
                            Next
                        </OBCButton>
                    </div>
                );

            // Info
            case 2:
                return (
                    <div className="Footer">
                        <OBCButton
                            className="ButtonStart"
                            disabled={false}
                            variant="ghost"
                            onClick={() => goToPrevStep()}>
                            Back
                        </OBCButton>
                        <OBCButton
                            disabled={uploadName.length === 0}
                            variant="primary"
                            onClick={() => goToNextStep()}>
                            Next
                        </OBCButton>
                    </div>
                );

            // Submit
            case 3:
                return (
                    <div className="Footer">
                        <OBCButton
                            className="ButtonStart"
                            disabled={false}
                            variant="ghost"
                            onClick={() => goToPrevStep()}>
                            Back
                        </OBCButton>
                        <OBCButton
                            disabled={selectedMedia.length === 0}
                            variant="success"
                            onClick={() => setSubmitting(true)}>
                            Submit
                        </OBCButton>
                    </div>
                );
            // Uploading
            case 4:
                return (
                    <div className="Footer">
                        <OBCButton
                            disabled={submitting}
                            variant="secondary"
                            onClick={() => setSubmitting(true)}>
                            Uploading...
                        </OBCButton>
                    </div>
                );
            // Completed (success or not)
            case 5:
                // success
                if (submittionSuccess) {
                    return (
                        <div className="Footer">
                            <OBCButton
                                variant="primary"
                                onClick={() => setUploadModalOpen(false)}>
                                Done
                            </OBCButton>
                        </div>
                    );
                } else {
                    // upload failed
                    return (
                        <div className="Footer">
                            <OBCButton
                                className="ButtonStart"
                                disabled={false}
                                variant="ghost"
                                onClick={() => goToStep(3)}>
                                Back
                            </OBCButton>
                            <OBCButton
                                disabled={selectedMedia.length === 0}
                                variant="success"
                                onClick={() => setSubmitting(true)}>
                                Try again
                            </OBCButton>
                        </div>
                    );
                }
        }
    }, [uploadModalStep, uploadName, selectedMedia, centerMarker, submitting, submittionSuccess]);

    const renderTags = () => {
        const groups = _.uniq(_.map(availableTags, (item) => item["Group"]));
        return _.map(groups, (group) => {
            return (
                <OptGroup label={group}>
                    {_.map(
                        availableTags.filter((tag) => tag["Group"] === group),
                        (tag) => {
                            if (tag["Group"] === group) {
                                return (
                                    <Option
                                        label={group}
                                        value={`${tag["Name"]}~${tag["Group"]}`}>
                                        {tag["Name"]}
                                    </Option>
                                );
                            }
                        },
                    )}
                </OptGroup>
            );
        });
    };

    const handleTagSelection = (tag, option) => {
        const properties = [];
        option.forEach((item) => properties.push(item.props));
        setDefaultTags(properties);
        const data = _.clone(uploadTags);
        _.keys(data).map((key) => (data[key] = []));
        _.forEach(properties, (item) => {
            data[item.label].push(item.children);
        });
        setUploadTags(data);
    };

    const renderStepContent = useMemo(() => {
        // eslint-disable-next-line default-case
        switch (uploadModalStep) {
            // Select pictures
            case 0:
                return (
                    <div className="SelectMediaStep">
                        <h4>Select pictures you want to upload.</h4>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            ref={inputFileRef}
                            multiple={true}
                            // accept="image/png,image/jpeg"
                            onChange={(e) => onFilesChanged(e.target.files)}
                        />
                        <OBCButton onClick={onAddMediaButtonClicked}>Add Media</OBCButton>
                    </div>
                );

            // Location
            case 1:
                return (
                    <div className="StepContent">
                        {Object.keys(renderSelectedPictures).length > 0 && (
                            <>
                                <div className="TopPanel">
                                    <h2 className="PrimaryInfo">Confirm Location by selecting a point on the map</h2>
                                </div>
                                <div className="MiddleContent">
                                    <div className="MapComponent">
                                        <Map
                                            className="MapLocationPicker"
                                            center={imageCoordinates ?? { lat: 51.448335, lng: -2.582493 }}
                                            maxZoom={18}
                                            zoom={16}
                                            onViewportChange={(e) => setCenterMarker(e.center)}>
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                maxZoom={20}
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            />
                                            {centerMarker && <Marker position={centerMarker} />}
                                        </Map>
                                        <div
                                            className={`MapInstructionOverlay ${mapInstructionOverlayVisible && !centerMarker ? " visible" : "hidden"}`}
                                            onTouchStart={() => setMapInstructionOverlayVisible(false)}
                                            onMouseDown={() => setMapInstructionOverlayVisible(false)}>
                                            <img
                                                draggable={false}
                                                className="PointerHandIndicator"
                                                src={pointerHand}
                                                alt="Hand Pointer"
                                                crossOrigin={"anonymous"}
                                            />
                                            <div className="InstructionsMessage">Drag, zoom in and out the map to set precise location.</div>
                                        </div>
                                    </div>
                                    {!imageCoordinates && !deviceLocationErrorMsg && selectedMedia.length > 0 && (
                                        <Alert
                                            className="AllertMessage"
                                            description={<span>Unable to get location from provided media</span>}
                                            type="info"
                                            closable
                                        />
                                    )}
                                    {deviceLocationUsed && selectedMedia.length > 0 && (
                                        <Alert
                                            className="AllertMessage"
                                            description={
                                                <span>
                                                    Unable to get location from provided media, <strong>using device location instead</strong>
                                                </span>
                                            }
                                            type="info"
                                            closable
                                        />
                                    )}
                                    {selectedMedia.length > 0 && deviceLocationErrorMsg && (
                                        <Alert
                                            className="AllertMessage"
                                            description={deviceLocationErrorMsg}
                                            type="warning"
                                            closable
                                        />
                                    )}
                                </div>
                            </>
                        )}
                        <div className="BottomContent">
                            {Object.keys(renderSelectedPictures).length !== 0 && (
                                <div className="ImagesPreviewPane">
                                    {renderSelectedPictures}
                                    <div className="UploadedImage AddImage">
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            ref={inputFileRef}
                                            multiple={true}
                                            accept="image/png,image/jpeg"
                                            onChange={(e) => onFilesChanged(e.target.files, true)}
                                        />
                                        <span onClick={() => onAddMediaButtonClicked()}>+ Add</span>
                                    </div>
                                </div>
                            )}
                            {Object.keys(renderSelectedPictures).length === 0 && (
                                <div className="NoMediaContainer">
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        ref={inputFileRef}
                                        multiple={true}
                                        accept="image/png,image/jpeg"
                                        onChange={(e) => onFilesChanged(e.target.files)}
                                    />
                                    <div className="NoMediaLabel">No media selected</div>
                                    <OBCButton
                                        className="AddMediaButton"
                                        variant="primary"
                                        onClick={() => onAddMediaButtonClicked()}>
                                        Add
                                    </OBCButton>
                                </div>
                            )}
                        </div>
                    </div>
                );

            // Info
            case 2:
                return (
                    <>
                        <h2 className="PrimaryInfo">More details</h2>
                        <div className="FormGrid">
                            <div className="Label">Upload Name</div>
                            <div className="Control">
                                <Input
                                    className={uploadNameTouched && uploadName.length === 0 ? "has-error" : ""}
                                    size="small"
                                    ref={textInput}
                                    autoFocus
                                    placeholder="Enter name"
                                    value={uploadName}
                                    onChange={(e) => onUploadNameChanged(e.target.value)}
                                />
                                {uploadNameTouched && uploadName.length === 0 && <p className="danger">Missing upload name</p>}
                            </div>
                            <div className="Label">Description</div>
                            <TextArea
                                className="Control DescriptionTextArea"
                                value={uploadDescription}
                                onChange={onDescriptionChanged}
                                placeholder="Upload description"
                            />
                            <div className="Label">Tags</div>
                            <Select
                                mode="multiple"
                                notFoundContent={"Tag Not Found"}
                                style={{ width: "100%" }}
                                placeholder="Select Tags Here"
                                onChange={handleTagSelection}
                                defaultValue={defaultTags.map((item) => item.value)}>
                                {renderTags()}
                            </Select>
                        </div>
                    </>
                );

            // Submit
            case 3:
                return (
                    <>
                        <h2 className="PrimaryInfo">Confirm all details</h2>
                        <div className="FormGrid">
                            <div className="Label">Workspace</div>
                            <div className="Control Text">{uploadWorkspace}</div>

                            <div className="Label">Token Name</div>
                            <div className="Control Text">{uploadTokenDescription}</div>

                            <div className="Label">Upload Name</div>
                            <div className="Control Text">{uploadName}</div>

                            <div className="Label">Description</div>
                            <div className="Control Text Description">
                                {uploadDescription.length ? uploadDescription : <div className="NotProvided">Not provided</div>}
                            </div>

                            {_.keys(uploadTags).map((key) => {
                                return (
                                    <>
                                        <div className="Label">{key}</div>
                                        <div className="Control Text Tags">
                                            {uploadTags[key].length > 0 ? (
                                                uploadTags[key].map((tag, index) => (
                                                    <Tag
                                                        key={index}
                                                        color="#108ee9">
                                                        {tag}
                                                    </Tag>
                                                ))
                                            ) : (
                                                <div className="NotProvided">Not provided</div>
                                            )}
                                        </div>
                                    </>
                                );
                            })}

                            <div className="Label">Media</div>
                            <div className="Control">
                                <div className="ImagesFlexContainer">
                                    {renderSelectedPictures}
                                    <div className="UploadedImage AddImage">
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            ref={inputFileRef}
                                            multiple={true}
                                            accept="image/png,image/jpeg,image/heic"
                                            onChange={(e) => onFilesChanged(e.target.files, true)}
                                        />
                                        <span onClick={() => onAddMediaButtonClicked()}>+ Add</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            // Uploading media
            case 4:
                return (
                    <div className="StepContent">
                        <div className="TopPanel">
                            <h2 className="PrimaryInfo">Uploading...</h2>
                        </div>
                        <div className="MiddleContent Uploading">
                            <h4>Please wait while media is being uploaded to the cloud.</h4>
                            <p className="Desktop">Do not refresh or click back on your browser</p>
                            <p className="Mobile">Do not refresh or navigate back</p>
                            <div className="SpinningLogo">
                                <OBCSpinner
                                    size={150}
                                    speed={3}
                                />
                            </div>
                            <div className="UploadCountLabel">
                                Uploading:{" "}
                                <span>
                                    {currentlyUploading}/{selectedMedia.length}
                                </span>
                            </div>
                            <OBCProgressBar
                                progress={currentlyUploading / selectedMedia.length}
                                lableVisible={false}
                            />
                        </div>
                    </div>
                );

            // Completed (success or not)
            case 5:
                // success
                if (submittionSuccess) {
                    return (
                        <div className="StepContent">
                            <div className="TopPanel">
                                <h2 className="PrimaryInfo">Uploading is now completed</h2>
                            </div>
                            <div className="MiddleContent Complete">
                                <h4>
                                    Media can be accessed in <strong>{uploadWorkspace}</strong> workspace
                                </h4>
                                <div className="GreenCheckIcon">
                                    <img
                                        src={greenCheckIcon}
                                        alt="Green check"
                                        crossOrigin={"anonymous"}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    // upload failed
                    return (
                        <div className="StepContent">
                            <div className="TopPanel">
                                <h2 className="PrimaryInfo">Uploading failed</h2>
                            </div>
                            <div className="MiddleContent Complete">
                                <h4>There was an error whilst uploading media, please try again or contact support.</h4>
                                <div className="GreenCheckIcon">
                                    <img
                                        src={redCrossIcon}
                                        alt="Red cross"
                                        crossOrigin={"anonymous"}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
        }
    }, [
        uploadModalStep,
        imageCoordinates,
        selectedMedia,
        centerMarker,
        renderSelectedPictures,
        inputFileRef,
        uploadDescription,
        uploadName,
        submitting,
        currentlyUploading,
        submittionSuccess,
        errorUploading,
        deviceLocationUsed,
        deviceLocationErrorMsg,
        mapInstructionOverlayVisible,
        uploadTags,
    ]);

    const steps = [
        {
            title: <span>Location</span>,
            icon: <FontAwesomeIcon icon={faMapMarkedAlt} />,
        },
        {
            title: <span>Info</span>,
            icon: <FontAwesomeIcon icon={faInfoSquare} />,
        },
        {
            title: <span>Submit</span>,
            icon: submitting ? <LoadingOutlined /> : <FontAwesomeIcon icon={faCloudUpload} />,
        },
    ];

    return (
        <>
            <LoadingOverlay loading={uploadWorkspace === false} />

            {/* Upload name modal */}
            <Modal
                className="UploadMediaModal"
                title={uploadModalStep === 0 ? "Select Media" : uploadModalStep === 5 ? (submittionSuccess ? "Completed" : "Error") : null}
                visible={uploadModalOpen}
                forceRender={true}
                footer={renderModalFooter}
                onCancel={onUploadModalClose}
                closable={uploadModalStep === 0 || uploadModalStep === 5}
                maskClosable={false}>
                <>
                    <Steps
                        className="StepsMain"
                        current={uploadModalStep}
                        // onChange={onSteChanged}
                        initial={1}
                        content={steps}>
                        {uploadModalStep !== 0 &&
                            uploadModalStep !== 5 &&
                            steps.map((step) => (
                                <Step
                                    className="Step"
                                    key={step.title}
                                    title={step.title}
                                    icon={step.icon}
                                />
                            ))}
                    </Steps>
                    <div className="steps-content">{renderStepContent}</div>
                </>
            </Modal>

            {/* preview images modal */}
            <Modal
                title={null}
                visible={previewModalOpen}
                footer={null}
                centered={true}
                className="MediaUploadPreviewModal"
                onCancel={() => setPreviewModalOpen(false)}>
                <img
                    src={previewImage !== null ? URL.createObjectURL(previewImage) : ""}
                    alt="Preview Image"
                    crossOrigin={"anonymous"}
                />
            </Modal>

            {/* Main page content */}
            <Layout className="MediaUploadMainLayout">
                <Header className="Header">
                    <img
                        className="Logo"
                        src={AVIRlogo}
                        alt="AVIR logo"
                        crossOrigin={"anonymous"}
                    />
                    <p className="LogoLabel">Media Uploader</p>
                </Header>
                <Content className="MainContent">
                    <div className="Summary">
                        <div className="Grid">
                            <div className="LeftCol">
                                <div className="Row">
                                    <div className="Title">Workspace:</div>
                                    <div className="Label">{uploadWorkspace ?? <div className="NotProvided">Unknown</div>}</div>
                                </div>
                                <div className="Row">
                                    <div className="Title">Token name:</div>
                                    <div className="Label">{uploadTokenDescription ?? <div className="NotProvided">Unknown</div>}</div>
                                </div>
                                {!freshStart && submittionSuccess && (
                                    <div className="Row">
                                        <div className="Title">Name:</div>
                                        <div className="Label">{uploadName}</div>
                                    </div>
                                )}
                            </div>
                            {!freshStart && submittionSuccess && (
                                <div className="RightCol">
                                    <div className="Row">
                                        <div className="Title">Description:</div>
                                        <div className="Label">
                                            {uploadDescription.length ? uploadDescription : <div className="NotProvided">Not provided</div>}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ImagesContainer">
                        {!freshStart ? <div className="ImagesFlexContainer">{renderSelectedPictures}</div> : renderWelcomeMesage}
                    </div>
                </Content>
                {uploadWorkspace && (
                    <Footer className="Footer">
                        {submittionSuccess ? (
                            <>
                                <OBCButton
                                    variant="primary"
                                    onClick={() => onStartButtonClicked()}>
                                    Upload another
                                </OBCButton>
                                <OBCButton
                                    variant="success"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.location.href = `https://view${MEMOIZED_DOMAIN_URL}/`;
                                    }}>
                                    Done
                                </OBCButton>
                            </>
                        ) : (
                            <OBCButton
                                className="btnStart"
                                variant="primary"
                                onClick={() => onStartButtonClicked()}>
                                Start
                            </OBCButton>
                        )}
                    </Footer>
                )}
            </Layout>
        </>
    );
};

export default withRouter(MediaUploadsPage);
