import React from "react";
import { connect, Provider } from "react-redux";
import { currentPlaylistPosition, getBaseURL, getEncodedSnapshot, snapshotAudit, toggleSnapshot, fetchWhatThreeWords } from "../../../redux/actions/index";
import { Button, Checkbox, Input, Modal, Select, Tooltip } from "antd";
import _ from "lodash";
import { calculateRouteCoordinatesForLocation, coordinateToOSGB } from "../../util/Geometry";
import { calculateFrame, getCurrentVideoKey, getOffsetAdjustedPosition, videoKeyToTimestamp, calculateNext, calculatePrevious } from "../../util/PlaylistUtils";
import { MEMOIZED_API_BASE_URL } from "../../util/HostUtils";
import { faBackward, faForward, faObjectUngroup, faPencilAlt, faRulerCombined, faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import missingImage from "../../../images/obc-placeholder.png";
import OBCSpinner from "../../util/OBC";
import { render, unmountComponentAtNode } from "@react-three/fiber";
import { ThreeDFeatureOverlayScene } from "./3d/3dFeatureOverlay";
import store from "../../../redux/store";
import sanitize from "sanitize-filename";
import { convertToTimezone } from "../../util/TimezoneUtils";
import { getTempUnit, degreesToUserPreference } from "components/util/TemperatureUtils";

const three_render = render;

const { Option } = Select;

class LocationSelect extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            what3Words: "",
        };
    }

    componentDidMount() {
        const locationOptions = this.getLocationOptions();
        if (locationOptions.length) {
            this.props.onSelectChange(locationOptions[locationOptions.length - 1]);
        }
        this.getWhat3Words();
    }

    getCurrentCoords = () => {
        let playlistCoords = this.props.coords;
        if (playlistCoords && playlistCoords[0] !== null && playlistCoords[1] !== null) {
            return playlistCoords;
        } else {
            return null;
        }
    };

    getLocationOptions = () => {
        let coordString = "";
        const options = [];
        if (this.props.coords && this.props.coords[0] && this.props.coords[1]) {
            coordString = `${this.props.coords[0].toFixed(5)}, ${this.props.coords[1].toFixed(5)}`;
            options.push(coordString);

            const eastingNorthing = coordinateToOSGB(this.props.coords);
            if (eastingNorthing) {
                options.push(`${eastingNorthing} (Easting, Northing)`);
            }
        }

        const localElR = this.calculateELR();
        if (localElR) {
            options.push(coordString + " / " + localElR);
        }

        if (this.state.what3Words) {
            options.push(this.state.what3Words);
        }

        return options;
    };

    calculateELR = () => {
        const currentTimestamp = videoKeyToTimestamp(this.props.videoKey);
        const elr = calculateRouteCoordinatesForLocation(currentTimestamp, this.props.routeLocationData, this.props.routeLocationSystemID);
        if (elr) {
            return elr.to_string(this.props.userConfig.elr_units);
        } else {
            return null;
        }
    };

    getWhat3Words = () => {
        if (this.props.coords && this.props.coords[0] && this.props.coords[1]) {
            this.props.dispatch(fetchWhatThreeWords(this.props.coords[1], this.props.coords[0])).then((resp) =>
                this.setState({
                    what3Words: resp,
                }),
            );
        }
    };

    renderLocationOptions = () => {
        const locationOptions = this.getLocationOptions();

        return locationOptions.map((option) => {
            return (
                <Option
                    value={option}
                    key={option}>
                    {option}
                </Option>
            );
        });
    };

    render = () => {
        return (
            <Select
                style={{ width: 400 }}
                value={this.props.locationValue}
                onChange={this.props.onSelectChange}>
                {this.renderLocationOptions()}
            </Select>
        );
    };
}

const mapStateToPropsLocation = ({ playlist, views, userDetails, routeCoordinateSystems, dashboards }) => {
    const dashboardID = userDetails.dashboardAccessID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;

    return {
        playlistData: playlist.position,
        position: playlist.position.currentIndex,
        coords: playlist.position.coords,
        offset: playlist.position.currentTimeOffset,
        videoKey: getCurrentVideoKey(playlist),
        userConfig: userDetails.userConfig,
        datum_offsets: datumOffsets,
        supportedCoordinateSystems: routeCoordinateSystems,
        routeLocationData: playlist.data.route_locations,
        routeLocationSystemID: playlist.data.system_id,
    };
};

LocationSelect = connect(mapStateToPropsLocation)(LocationSelect);

class SnapshotModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            sketches: "all",
            annotations: "all",
            measurements: "all",
            footer: true,
            title: " ",
            subTitle: " ",
            loading: true,
            coordString: "",
            imageSrc: null,
            locationString: "",
            overlayData: null,
            nearByHotspotsText: "",
            nearByHotspotsList: [],
            includeNearbyHotspot: false,
            isElr: false,
        };

        this.canvasRefs = {};

        this.overlayCanvas = null;
    }

    navigateNext = () => this.navigate(calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    navigateBack = () => this.navigate(calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset));
    get isNextDisabled() {
        return calculateNext(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }
    get isPreviousDisabled() {
        return calculatePrevious(this.props.videoKey, this.props.currentIndex, this.props.video, this.props.offset) === null;
    }

    componentDidUpdate(prevProps, prevState) {
        const _this = this;
        if (this.props.visible && !prevProps.visible) {
            let snapshotName = `#${this.props.session.id} ${this.props.session.route_name}`;

            if (this.nameInput) {
                this.nameInput.focus();
            }

            const dateOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
            const date = new Date(videoKeyToTimestamp(this.props.videoKey));

            const subTitle = convertToTimezone(date, this.props.userConfig.convert_to_utc, dateOptions);

            this.setState({ title: snapshotName, subTitle: subTitle }, () => {
                _this.getImageSourceDebounce();
            });

            this.props.dispatch(snapshotAudit("snapshot_open", this.props.session.id, this.props.videoKey));
        } else if (this.props.visible) {
            if (this.props.offset !== prevProps.offset || this.props.currentIndex !== prevProps.currentIndex) {
                this.getImageSourceDebounce();
            } else if (this.state.overlayData && this.state.overlayData !== prevState.overlayData) {
                this.getImageSourceDebounce();
            }
        }

        if (!_.isEqual(this.props.hotspotVideoKeyRangeList, prevProps.hotspotVideoKeyRangeList)) {
            const hotspotsList = this.findNearbyHotSpots();

            const firstElement = _.get(hotspotsList, [0], "");
            this.setState({
                nearByHotspotsList: hotspotsList,
                nearByHotspotsText: firstElement,
            });
            _this.selectHotspotTemp(firstElement);
        }
    }

    componentDidMount() {
        this.overlayCanvas = document.createElement("canvas");
        this.overlayCanvas.width = 1920;
        this.overlayCanvas.height = 1080;
        this.componentDidUpdate({});

        three_render(
            <Provider store={store}>
                <ThreeDFeatureOverlayScene
                    callback={this.onRender}
                    showRails={false}
                    showCorridor={false}
                />
            </Provider>,
            this.overlayCanvas,
            {
                frameloop: "demand",
                size: { width: 1920, height: 1080 },
                shadows: true,
                alpha: true,
                preserveDrawingBuffer: true,
            },
        );
    }

    componentWillUnmount() {
        unmountComponentAtNode(this.overlayCanvas);
        this.overlayCanvas = null;
    }

    onRender = () => {
        console.log("Overlay has re-rendered!");
        this.setState({
            overlayData: this.overlayCanvas.toDataURL(),
        });
    };

    handleClose = () => {
        this.props.dispatch(toggleSnapshot());
    };

    serialize = (obj) => {
        let str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        return str.join("&");
    };

    getImageSource = () => {
        this.setState({
            loading: true,
        });

        const currentFrame = calculateFrame(this.props.video, this.props.currentIndex, this.props.offset);

        let title = this.state.title;
        if (this.state.includeNearbyHotspot) {
            title += ` ${degreesToUserPreference(this.state.nearByHotspotsText, this.props.userTempPreference)}${getTempUnit(this.props.userTempPreference)}`;
        }

        let payload = {
            a: this.state.annotations,
            m: this.state.measurements,
            s: this.state.sketches,
            f: this.state.footer ? 1 : 0,
            device_key: this.props.session.device_uuid,
            session_key: this.props.session.uuid,
            video_key: this.props.videoKey,
            frame: 1,
            title,
            subtitle: this.state.subTitle,
            access_token: this.props.access_token,
            loc: this.state.locationString,
            image_adjustments: JSON.stringify(this.props.stillImageAdjustments),
            show_os_watermark: this.state.isElr,
        };

        if (currentFrame) {
            payload["frame"] = currentFrame;
        }

        let queryStrings = this.serialize(payload);

        const baseURL = MEMOIZED_API_BASE_URL;
        let source = `${baseURL}/snapshot?` + queryStrings;

        const _this = this;
        this.props.dispatch(getEncodedSnapshot(source, this.state.overlayData)).then((image) => {
            if (image) {
                _this.setState({
                    imageSrc: "data:image/png;base64," + image,
                    loading: false,
                });
            } else {
                // image failed to load
                _this.setState({
                    imageSrc: missingImage,
                    loading: false,
                });
            }
        });
    };

    getImageSourceDebounce = _.debounce(this.getImageSource, 300);

    onTextChange = (name, value) => {
        const _this = this;
        this.setState(
            {
                [name]: value,
            },
            () => {
                _this.getImageSourceDebounce();
            },
        );
    };

    onSelectChange = (name, value) => {
        const _this = this;
        this.setState(
            {
                [name]: value,
            },
            () => {
                _this.getImageSource();
            },
        );
    };

    footerToggle = () => {
        const _this = this;
        this.setState(
            {
                footer: !this.state.footer,
            },
            () => {
                _this.getImageSource();
            },
        );
    };

    downloadImage = () => {
        this.props.dispatch(snapshotAudit("snapshot_download", this.props.session.id, this.props.videoKey));
        var a = document.createElement("a"); //Create <a>
        a.href = this.state.imageSrc; //Image Base64 Goes here
        a.download = sanitize(`${this.state.title}`).substring(0, 50) + ".png";
        a.click(); //Downloaded file
    };

    copyImage = () => {
        this.setCanvasImage(this.state.imageSrc, (imgBlob) => {
            navigator.clipboard
                .write([
                    /*global ClipboardItem*/
                    /*eslint no-undef: "error"*/
                    new ClipboardItem({ "image/png": imgBlob }),
                ])
                .then(() => {
                    console.log("Image copied to clipboard");
                })
                .catch((e) => {
                    console.log(e);
                });
        });
    };

    setCanvasImage = (path, func) => {
        const img = new Image();
        const c = document.createElement("canvas");
        const ctx = c.getContext("2d");

        img.onload = function () {
            c.width = this.naturalWidth;
            c.height = this.naturalHeight;
            ctx.drawImage(this, 0, 0);
            c.toBlob((blob) => {
                func(blob);
            }, "image/png");
        };
        img.src = path;
    };

    renderFooter = () => {
        let userIsOnFirefox = false;

        if (typeof window !== "undefined") {
            userIsOnFirefox = navigator.userAgent.includes("Firefox");
        }

        const CopyToClipboardButton = () => {
            if (!userIsOnFirefox) {
                return (
                    <Tooltip
                        title="Copied to clipboard"
                        trigger="click"
                        key="copy">
                        <Button
                            type="primary"
                            onClick={this.copyImage}
                            disabled={this.state.title === ""}
                            loading={this.state.loading}>
                            Copy image to clipboard
                        </Button>
                    </Tooltip>
                );
            } else {
                return null;
            }
        };

        return [
            <Button
                onClick={this.handleClose}
                key="cancel">
                Cancel
            </Button>,
            <CopyToClipboardButton />,
            <Button
                type="primary"
                onClick={this.downloadImage}
                disabled={this.state.title === ""}
                loading={this.state.loading}
                key="download">
                Download
            </Button>,
        ];
    };

    navigate = (nav) => {
        if (nav === null) {
            return;
        }
        const [newKeyIndex, offset] = nav;
        const newTime = this.props.video[newKeyIndex][1] + offset;
        let position = getOffsetAdjustedPosition(newTime, this.props.video, this.props.offsets, this.props.use_snapped);
        if (position !== null && position[2] !== null) {
            let playlistIndex = position[0];
            let timeOffset = position[1];
            let coords = position[2];
            this.props.dispatch(currentPlaylistPosition(playlistIndex, coords, timeOffset));
        }
    };

    includeHotspotInTitle = (checked) => {
        const _this = this;
        this.setState(
            {
                includeNearbyHotspot: checked,
            },
            () => {
                _this.getImageSource();
            },
        );
    };

    selectHotspotTemp = (temp) => {
        const _this = this;
        this.setState(
            {
                nearByHotspotsText: temp,
            },
            () => {
                if (this.state.includeNearbyHotspot) {
                    _this.getImageSource();
                }
            },
        );
    };

    findNearbyHotSpots = () => {
        let nearByHotspotsList = [];
        _.forEach(this.props.currentMarkers, (marker) => {
            const markerName = _.get(marker, "name", "unknown");
            if (markerName === "Thermal Hotspot" && marker.score >= this.props.currentThermalThresholdFilter) {
                if (_.includes(this.props.hotspotVideoKeyRangeList, marker.video_key)) {
                    nearByHotspotsList.push(marker.score);
                }
            }
        });

        return nearByHotspotsList;
    };

    render() {
        return (
            <Modal
                visible={this.props.visible}
                width={"80%"}
                bodyStyle={{ height: "80vh", overflow: "auto" }}
                centered={true}
                onCancel={this.handleClose}
                footer={this.renderFooter()}>
                <Tooltip title="Skip to next frame">
                    <div className="snapshotNextDiv">
                        <FontAwesomeIcon
                            icon={faForward}
                            className={"snapshotNavIcon" + (this.isNextDisabled ? " disabled" : "")}
                            onClick={this.navigateNext}
                        />
                    </div>
                </Tooltip>

                <Tooltip title="Skip to previous frame">
                    <div className="snapshotBackDiv">
                        <FontAwesomeIcon
                            icon={faBackward}
                            className={"snapshotNavIcon" + (this.isPreviousDisabled ? " disabled" : "")}
                            onClick={this.navigateBack}
                        />
                    </div>
                </Tooltip>

                <div className="snapshotModal__content">
                    <div className="snapshotModal__imageContainer">
                        {this.state.loading && (
                            <div className="snapshotSpinner">
                                <OBCSpinner
                                    colorScheme="mono"
                                    size={70}
                                />
                            </div>
                        )}

                        {this.state.imageSrc !== null ? (
                            <img
                                className="snapshotImage"
                                src={this.state.imageSrc}
                                alt={"Snapshot"}
                                crossOrigin={"anonymous"}
                            />
                        ) : (
                            <div className="snapshotImagePlaceholder" />
                        )}
                    </div>

                    <div className="snapshotModal__settingsContainer">
                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">Title</div>
                            <div className="snapshotModal__settingInput">
                                <Input
                                    autoFocus
                                    ref={(input) => {
                                        this.nameInput = input;
                                    }}
                                    className="textInput"
                                    onChange={(e) => this.onTextChange("title", e.target.value)}
                                    value={this.state.title}
                                />
                            </div>
                        </div>

                        {this.props.hasThermalHotspots && (
                            <div className="snapshotModal__setting">
                                <div className="snapshotModal__settingLabel">
                                    <FontAwesomeIcon
                                        icon={faTemperatureHigh}
                                        className="snapshotIcon"
                                    />{" "}
                                    Thermal Hotspot
                                </div>
                                <div className="snapshotModal__settingInput">
                                    {this.state.nearByHotspotsList.length ? (
                                        <>
                                            <span>
                                                {`Found ${this.state.nearByHotspotsList.length > 1 ? this.state.nearByHotspotsList.length : ""} nearby thermal hotspot${this.state.nearByHotspotsList.length > 1 ? "s" : ""} `}
                                                {this.state.nearByHotspotsList.length > 1 ? (
                                                    <>
                                                        <Select
                                                            size="small"
                                                            value={this.state.nearByHotspotsText}
                                                            onSelect={this.selectHotspotTemp}>
                                                            {_.map(this.state.nearByHotspotsList, (temp, index) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={index}
                                                                        value={temp}>
                                                                        {degreesToUserPreference(temp, this.props.userTempPreference)}
                                                                        {getTempUnit(this.props.userTempPreference)}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        <span style={{ fontStyle: "italic", fontSize: "13px" }}> (Select temperature to display)</span>
                                                    </>
                                                ) : (
                                                    <span>
                                                        {degreesToUserPreference(this.state.nearByHotspotsText, this.props.userTempPreference)}
                                                        {getTempUnit(this.props.userTempPreference)}
                                                    </span>
                                                )}
                                            </span>
                                            <div className="snapshotModal__thermalHotspot">
                                                <Checkbox onChange={(e) => this.includeHotspotInTitle(e.target.checked)}>
                                                    Enable this option to include the hotspot temperature in the snapshot title
                                                </Checkbox>
                                            </div>
                                        </>
                                    ) : (
                                        <span style={{ fontStyle: "italic" }}>No thermal hotspot nearby</span>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">Subtitle</div>
                            <div className="snapshotModal__settingInput">
                                <Input
                                    className="textInput"
                                    onChange={(e) => this.onTextChange("subTitle", e.target.value)}
                                    value={this.state.subTitle}
                                />
                            </div>
                        </div>

                        <div className="snapshotModal__settingsRow">
                            <div className="snapshotModal__setting">
                                <div className="snapshotModal__settingLabel">
                                    <FontAwesomeIcon
                                        icon={faObjectUngroup}
                                        className="snapshotIcon"
                                    />
                                    Annotations
                                </div>
                                <div className="snapshotModal__settingInput">
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 120 }}
                                        onChange={(value) => this.onSelectChange("annotations", value)}>
                                        <Option value="all">All</Option>
                                        <Option value="mine">Mine</Option>
                                        <Option value="none">None</Option>
                                    </Select>
                                </div>
                            </div>

                            <div className="snapshotModal__setting">
                                <div className="snapshotModal__settingLabel">
                                    <FontAwesomeIcon
                                        icon={faRulerCombined}
                                        className="snapshotIcon"
                                    />
                                    Measurements
                                </div>
                                <div className="snapshotModal__settingInput">
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 120 }}
                                        onChange={(value) => this.onSelectChange("measurements", value)}>
                                        <Option value="all">All</Option>
                                        <Option value="mine">Mine</Option>
                                        <Option value="none">None</Option>
                                    </Select>
                                </div>
                            </div>

                            <div className="snapshotModal__setting">
                                <div className="snapshotModal__settingLabel">
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="snapshotIcon"
                                    />
                                    Markups
                                </div>
                                <div className="snapshotModal__settingInput">
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 120 }}
                                        onChange={(value) => this.onSelectChange("sketches", value)}>
                                        <Option value="all">All</Option>
                                        <Option value="mine">Mine</Option>
                                        <Option value="none">None</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">
                                <Checkbox
                                    onChange={this.footerToggle}
                                    checked={this.state.footer}
                                    style={{ marginLeft: 10 }}>
                                    Footer
                                </Checkbox>
                            </div>
                            <div className="snapshoModal__settingInput">
                                Include a footer on the snapshot, containing location, author, workspace and session information.
                            </div>
                        </div>

                        <div className="snapshotModal__setting">
                            <div className="snapshotModal__settingLabel">Location value</div>
                            <div className="snapshoModal__settingInput">
                                <LocationSelect
                                    locationValue={this.state.locationString}
                                    onSelectChange={(value) => {
                                        if (value.includes("/")) {
                                            this.setState({ isElr: true });
                                        } else {
                                            this.setState({ isElr: false });
                                        }
                                        this.onSelectChange("locationString", value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({
    playlist,
    sessions,
    access_token,
    userPreferences,
    gpsTimeOffsets,
    viewSnapshot,
    userDetails,
    snappedRoute,
    markers,
    markerThresholdFilters,
    defaultMarkersThresholds,
    markerReviewFilters,
}) => {
    const routeID = playlist.data.routeID;
    const sourceIndex = playlist.position.sourceIndex;
    const EMPTY_ARRAY = [];

    const video = _.get(playlist.data, ["video", sourceIndex], []);

    let offsets = [];

    if (routeID === gpsTimeOffsets.sessionID) {
        offsets = _.get(gpsTimeOffsets.offsets, sourceIndex, []);
    }

    // check if sessions has any thermal hotspots
    const currentSessionTags = _.get(sessions[routeID], "tags", []);
    const hasThermalHotspots = currentSessionTags.some((tag) => tag.includes("Thermal Hotspot"));
    let currentMarkers = [];
    let currentThermalThresholdFilter = null;

    if (hasThermalHotspots) {
        const currentMarkerReviewFilters = _.cloneDeep(markerReviewFilters);

        currentMarkers = _.filter(_.get(markers.perSession, routeID, []), (marker) => _.includes(currentMarkerReviewFilters, marker.review_status));

        currentThermalThresholdFilter = _.get(markerThresholdFilters, "Thermal Hotspot", null);
        if (!currentThermalThresholdFilter) {
            currentThermalThresholdFilter = _.get(defaultMarkersThresholds, [[routeID], "Thermal Hotspot"], null);
        }
    }

    const videoKey = getCurrentVideoKey(playlist);
    const currentIndex = playlist.position.currentIndex;
    const videoKeyIndexesRange = _.range(Math.max(currentIndex - 2, 0), Math.min(currentIndex + 2, video.length));
    const hotspotVideoKeyRangeList = _.map(
        _.at(video, videoKeyIndexesRange),
        (videoKey) => {
            return videoKey[0];
        },
        EMPTY_ARRAY,
    );

    return {
        session: sessions[routeID],
        playlistData: playlist.position,
        access_token,
        position: currentIndex,
        coords: playlist.position.coords,
        offset: playlist.position.currentTimeOffset,
        videoKey: videoKey,
        offsets,
        use_snapped: snappedRoute,
        video,
        sourceIndex,
        currentIndex: playlist.position.currentIndex,
        visible: viewSnapshot,
        userConfig: userDetails.userConfig,
        stillImageAdjustments: playlist.stillImageAdjustments,
        currentMarkers: currentMarkers,
        currentThermalThresholdFilter: currentThermalThresholdFilter,
        hotspotVideoKeyRangeList: hotspotVideoKeyRangeList,
        hasThermalHotspots: hasThermalHotspots,
        userTempPreference: userDetails.userConfig.temperature_units,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SnapshotModal);
