import ReactGA from "react-ga4";
import { jsonPostV2 } from "./apiUtils";

export const UPDATE_LAST_ACTIVE = "UPDATE_LAST_ACTIVE";

export function updateLastActive() {
    return {
        type: UPDATE_LAST_ACTIVE,
    };
}

export function logTiming(category, variable, timePeriod, label = undefined) {
    ReactGA.ga("send", "timing", category, variable, timePeriod);
}

export function logEvent(category, action, label = undefined) {
    return (dispatch, getState) => {
        let access_token = getState().access_token;
        let email_address = getState().userDetails.email;
        let organisation = getState().userDetails.organisation;
        let dashboardAccessID = getState().userDetails.dashboardAccessID;

        let extraProps = {};
        if (label !== undefined) {
            extraProps["label"] = `${label}`;
        }

        ReactGA.event(action, {
            event_category: category,
            action,
            ...extraProps,
            dimension1: access_token,
            dimension2: email_address,
            dimension3: organisation,
            dimension4: dashboardAccessID,
        });

        dispatch(updateLastActive());
    };
}

export function customAudit(type, metadata, log = false) {
    return (dispatch, getState) => {
        if (!log) {
            log = `${type} event`;
        }

        let postBody = {
            action: "custom_audit",
            metadata: metadata,
            audit_event: type,
            log_string: log,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

export function findAudit(type, metadata) {
    return (dispatch, getState) => {
        let state = getState();
        let { email, description } = state.userDetails;
        let log_string = `${type} event - metadata unavailable`;
        if (email && description) {
            log_string = `${type} from ${email}`;
            if (metadata.locationType) {
                log_string += `, Search type : ${metadata.locationType}`;
            }
        }
        let postBody = {
            action: "custom_audit",
            metadata: metadata,
            audit_event: type,
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

export function snapshotAudit(type, sessionID, videoKey) {
    return (dispatch, getState) => {
        let state = getState();
        let { email, description } = state.userDetails;
        let log_string = `${type} event - metadata unavailable`;
        if (email && description) {
            log_string = `${type} from ${email} for session ${sessionID}`;
        }
        let postBody = {
            action: "custom_audit",
            metadata: {
                sessionID,
                videoKey,
            },
            audit_event: type,
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

let defferedWorkspaceAuditEvent = null;
export const handleDeferredAuditEvent = () => {
    return (dispatch, _getState) => {
        if (defferedWorkspaceAuditEvent) {
            dispatch(swapDashboardAudit(defferedWorkspaceAuditEvent));
        }
    };
};

export function swapDashboardAudit(dashboard_token) {
    return (dispatch, getState) => {
        let state = getState();

        if (!state.csrfToken) {
            defferedWorkspaceAuditEvent = dashboard_token;
            return;
        }
        let { email, description } = state.userDetails;
        let log_string = `dashboard_select event - metadata unavailable`;
        if (email && description) {
            log_string = `User ${email} selected dashboard ${description}`;
        }
        let postBody = {
            action: "custom_audit",
            metadata: {
                dashboard_token,
            },
            audit_event: "dashboard_select",
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

export function introTourStageAudit(stepReached) {
    return (dispatch, getState) => {
        let state = getState();
        let { email, description } = state.userDetails;
        let log_string = `intro_tour_state_reached event - metadata unavailable`;
        if (email && description) {
            log_string = `User ${email} reached step ${stepReached}`;
        }
        let postBody = {
            action: "custom_audit",
            metadata: {
                stepReached,
            },
            audit_event: "intro_step_reached",
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}

export function railInspectionOpenedAudit(sessionID) {
    return (dispatch, getState) => {
        let state = getState();
        let { email, description } = state.userDetails;
        let log_string = `Rail Inspection Interface opened event - metadata unavailable`;
        if (email && description) {
            log_string = `User ${email} viewed ${sessionID} in the Rail Inspection Interface`;
        }
        let postBody = {
            action: "custom_audit",
            metadata: {
                sessionID,
            },
            audit_event: "rail_inspection_opened",
            log_string: log_string,
        };

        let url = "/admin";
        jsonPostV2(url, getState(), postBody, dispatch)
            .then((response) => {
                console.log("audit response:", response);
            })
            .catch((error) => {
                console.log("Audit error", error);
            });
    };
}
