import React from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";

const ArchiveInspectionSessionModal = ({ archiveSessionModalVisible, toggleModal, sessionID, archiveInspectionSession, setArchiveStatus }) => {
    const title = `Archive Inspection Session #${sessionID}`;

    const handleOk = () => {
        archiveInspectionSession(1);
        toggleModal();
    };

    const handleCancel = () => {
        toggleModal();
    };

    return (
        <Modal
            title={title}
            visible={archiveSessionModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"40%"}>
            <div className="inspectRail__Modal">This will cause the presence of inspection data on this session to be hidden - do you want to continue?</div>
        </Modal>
    );
};

export default ArchiveInspectionSessionModal;
