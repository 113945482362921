import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { railInspectionSourcesOpen } from "../../../redux/actions/index";
import SourceModalItem from "./SourceModalItem";

const playlistSelector = (state) => state.playlist.data.video;
const railInspectionSourcesOpenSelector = (state) => state.railInspection.railInspectionSourcesOpen;

const RailInspectSourceModal = ({ sourceModalOpen, toggleModal }) => {
    const dispatch = useDispatch();

    const sourcesOpen = useSelector(railInspectionSourcesOpenSelector);
    const [sourcesSelected, setSourcesSelected] = useState(sourcesOpen);
    const playlist = useSelector(playlistSelector);

    useEffect(() => {
        setSourcesSelected(sourcesOpen.map((i) => i.id));
    }, [sourceModalOpen, sourcesOpen]);

    const handleOk = () => {
        dispatch(railInspectionSourcesOpen(sourcesSelected));
        toggleModal();
    };

    const handleCancel = () => {
        toggleModal();
    };

    const handleSourceClick = (idx) => {
        let newArray = _.clone(sourcesSelected);
        if (sourcesSelected.includes(idx)) {
            newArray = newArray.filter((i) => i !== idx);
        } else {
            newArray.push(idx);
        }
        setSourcesSelected(newArray);
    };

    return (
        <Modal
            title="Select Source"
            visible={sourceModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={"40%"}>
            <div className="inspectRail__Modal">
                {playlist &&
                    playlist.map((item, idx) => (
                        <SourceModalItem
                            key={idx}
                            idx={idx}
                            handleSourceClick={handleSourceClick}
                            sourcesSelected={sourcesSelected}
                        />
                    ))}
            </div>
        </Modal>
    );
};

export default RailInspectSourceModal;
