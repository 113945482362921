import {
    wsConnected,
    wsDisconnected,
    updateWidgetData,
    updateDashboardKey,
    requestPlaylistPosition,
    routeSelected,
    requestPlaylistTimestamp,
} from "./actions/index";
import moment from "moment";
import { nanoid } from "nanoid";
import _ from "lodash";
import { getCurrentVideoKey, videoKeyToTimestamp, absoluteTimeLookup, binarySearch } from "../components/util/PlaylistUtils";
import { MEMOIZED_DOMAIN_URL } from "../components/util/HostUtils";

const AIVR_WS_SERVICE_URL = `wss://ws${MEMOIZED_DOMAIN_URL}/`;

const wsService = () => {
    let socket = null;
    let current_token = null;
    let local_id = nanoid(6);
    let ws_state = {};
    let dashboardKeyData = {};
    let current_status = {
        type: "DASHBOARD",
        src: local_id,
        data: {
            routeID: null,
            position: null,
        },
    };

    const onOpen = (store) => (event) => {
        console.log("[WS] Open:", event.target.url);
        store.dispatch(wsConnected(event.target.url));
    };

    const onClose = (store) => () => {
        console.log("[WS] onClose");
        store.dispatch(wsDisconnected());
    };

    const onError = (store) => (error) => {
        console.log("[WS] Error", error);
    };

    const onMessage = (store) => (event) => {
        const payload = JSON.parse(event.data);
        // console.log('[WS RX]', payload);
        if (payload.action) {
            if (payload.action === "request_position") {
                const state = store.getState();
                const isVideo = state.playlist.position.isVideo;

                if (!isVideo) {
                    store.dispatch(requestPlaylistTimestamp(payload.timestamp));
                    return;
                }
                const playlistPosition = state.playlist.position;
                const playlistData = state.playlist.data;

                let playlistIndex = -1;
                let newOffset = 0;
                const sourceIndex = playlistPosition.sourceIndex;

                let timestamp = payload.timestamp;
                if (timestamp > 9999999999) {
                    timestamp = timestamp / 1000;
                }

                const video = _.get(playlistData, ["video", sourceIndex], []);
                if (video.length) {
                    playlistIndex = absoluteTimeLookup(timestamp, video);

                    if (playlistIndex !== -1) {
                        const newAbsoluteTimestamp = video[playlistIndex][3][2];
                        newOffset = timestamp - newAbsoluteTimestamp;
                    }
                    if (newOffset < 0) {
                        newOffset = 0;
                    }
                } else {
                    const railImages = _.get(state, ["railInspection", "railInspectionImages", "data"], []);
                    playlistIndex = binarySearch(payload.timestamp, railImages, (image) => image.timestamp);
                }
                store.dispatch(
                    requestPlaylistPosition(
                        playlistPosition.isVideo,
                        playlistPosition.isEnhanced,
                        playlistPosition.isStills,
                        sourceIndex,
                        playlistIndex,
                        newOffset,
                    ),
                );
            } else if (payload.action === "request_map_position") {
                store.dispatch(routeSelected(payload.sessionID, payload.coords));
            }
        } else if (payload.src && payload.type && payload.data) {
            if (!window.location.href.includes("widget")) {
                return;
            }
            if (!ws_state[payload.type]) {
                ws_state[payload.type] = {};
            }
            ws_state[payload.type][payload.src] = {
                last_updated: moment.now(),
                state: payload.data,
            };
            store.dispatch(updateWidgetData(_.cloneDeep(ws_state)));

            if (_.isEmpty(dashboardKeyData) || dashboardKeyData.last_message < Date.now() / 1000 - 60) {
                store.dispatch(updateDashboardKey(payload.src));
                dashboardKeyData = {
                    key: payload.src,
                    last_message: Date.now() / 1000,
                };
            } else if (dashboardKeyData.key === payload.src) {
                dashboardKeyData.last_message = Date.now() / 1000;
            }
        }
        // else if(payload && )
    };

    const sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(message);
        }
    };

    const connectSocket = (store) => {
        if (socket === null && current_token !== null) {
            let ws_url = AIVR_WS_SERVICE_URL + current_token;
            socket = new WebSocket(ws_url);
            console.log("[WS] Socket opening to: ", ws_url);
            socket.onmessage = onMessage(store);
            socket.onclose = onClose(store);
            socket.onopen = onOpen(store);
            socket.onerror = onError(store);
        }
    };

    // Processing function for all dispatch events
    return (store) => (next) => (action) => {
        const result = next(action);
        switch (action.type) {
            case "USER_CONFIG":
                if (action.userConfig.ws_token !== current_token) {
                    current_token = action.userConfig.ws_token;
                    if (socket !== null) {
                        socket.close();
                    }
                    connectSocket(store);
                }
                break;
            case "WS_DISCONNECTED":
            case "WS_DISCONNECT":
                if (socket !== null) {
                    socket.close();
                }
                socket = null;
                console.log("[WS] Closed, reconnecting...");
                connectSocket(store);
                break;
            case "WS_SEND":
                console.log("[WS TX]", action.msg);
                sendMessage(JSON.stringify(action.msg));
                break;
            case "WS_STATUS_POLL":
                if (socket !== null) {
                    const state = store.getState();
                    current_status.data.position = state.playlist.position;
                    current_status.data.routeID = _.get(state, ["playlist", "data", "routeID"], null);

                    let currentTimestamp = 0;

                    const selectedRailImage = _.get(state, ["railInspection", "selectedRailInspectionImage"]);
                    if (!_.isEmpty(selectedRailImage) && selectedRailImage.index !== 0) {
                        currentTimestamp = selectedRailImage.timestamp;
                    } else {
                        const currentVideoKey = getCurrentVideoKey(state.playlist);
                        if (currentVideoKey) {
                            currentTimestamp = videoKeyToTimestamp(currentVideoKey) + state.playlist.position.currentTimeOffset * 1000;
                        }
                    }

                    current_status.data.position.timestamp = currentTimestamp;
                    current_status.data.workspace_id = _.get(state, ["userDetails", "dashboardAccessID"], null);

                    sendMessage(JSON.stringify(current_status));
                } else if (current_token) {
                    console.log("[WS] Reconnecting");
                    connectSocket(store);
                }
                break;
            case "WS_WIDGET_PING":
                sendMessage(
                    JSON.stringify({
                        type: "WIDGET",
                        src: local_id,
                        data: {
                            widgetType: action.widgetType,
                        },
                    }),
                );
                break;
            default:
        }
        return result;
    };
};

export default wsService();
