import { combineReducers } from "redux";
import _ from "lodash";

import {
    RECEIVE_ISSUES,
    EXIT_CURRENT_DASHBOARD,
    SELECT_ISSUE,
    RECEIVE_ISSUE_OBSERVATIONS,
    SELECT_OBSERVATION,
    SET_ISSUE_DATE_FILTER,
    SET_ISSUE_STATUS_FILTER,
    SET_ISSUE_CLASS_FILTER,
    SET_ISSUE_PRIORITY_FILTER,
    REQUEST_ISSUE,
    CLEAR_REQUESTED_ISSUE,
    SET_ISSUE_SEARCH_QUERY_FILTER,
    SET_ISSUE_CLASS_SEARCH_VALUE,
    RECEIVE_ISSUE_GROUPS,
    SET_ISSUE_GROUP_ID,
    SET_FILTERED_ISSUES,
} from "redux/actions";

function data(state = [], action) {
    if (action.type === RECEIVE_ISSUES) {
        if (action.clear) {
            state = action.issues;
        } else {
            state = [...action.issues, ...state];
        }
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
}

function filteredIssues(state = [], action) {
    if (action.type === SET_FILTERED_ISSUES) {
        state = action.issues;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }

    return state;
}

function groups(state = {}, action) {
    if (action.type === RECEIVE_ISSUE_GROUPS) {
        state = action.groups;
    }

    return state;
}

function selectedIssue(state = null, action) {
    if (action.type === SELECT_ISSUE) {
        state = action.issueID;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function observations(state = [], action) {
    if (action.type === RECEIVE_ISSUE_OBSERVATIONS) {
        state = action.observations;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = [];
    }
    return state;
}

function selectedObservation(state = null, action) {
    if (action.type === SELECT_OBSERVATION) {
        state = action.observationID;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function dateFilter(state = { from: null, to: null }, action) {
    if (action.type === SET_ISSUE_DATE_FILTER) {
        state = action.dates;
    }
    return state;
}

// change to new and updated?
function statusFilter(state = "new_and_updated", action) {
    if (action.type === SET_ISSUE_STATUS_FILTER) {
        state = action.status;
    }
    return state;
}

function classFilter(state = "", action) {
    if (action.type === SET_ISSUE_CLASS_FILTER) {
        state = action.issueClass;
    }
    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = "";
    }

    return state;
}

function issueGroupFilter(state = "", action) {
    if (action.type === SET_ISSUE_GROUP_ID) {
        state = action.issueGroupID;
    }
    return state;
}

function searchQueryFilter(state = "", action) {
    if (action.type === SET_ISSUE_SEARCH_QUERY_FILTER) {
        state = action.searchQuery;
    }
    return state;
}

// 2 is medium
function priorityFilter(state = "all", action) {
    if (action.type === SET_ISSUE_PRIORITY_FILTER) {
        state = action.priority;
    }
    return state;
}

function requestedObservation(state = null, action) {
    if (action.type === REQUEST_ISSUE && action.observationID) {
        state = action.observationID;
    }

    if (action.type === CLEAR_REQUESTED_ISSUE) {
        state = null;
    }

    return state;
}

function requestedIssue(state = null, action) {
    if (action.type === REQUEST_ISSUE) {
        state = action.issueID;
    }

    if (action.type === CLEAR_REQUESTED_ISSUE) {
        state = null;
    }

    return state;
}

function classSearchValue(state = {}, action) {
    if (action.type === SET_ISSUE_CLASS_SEARCH_VALUE) {
        state = action.issueClassSearchValue;
    }

    return state;
}

const selected = combineReducers({
    issue: selectedIssue,
    observation: selectedObservation,
});

const requested = combineReducers({
    observation: requestedObservation,
    issue: requestedIssue,
});

const filters = combineReducers({
    date: dateFilter,
    status: statusFilter,
    priority: priorityFilter,
    classSearchValue: classSearchValue,
    searchQuery: searchQueryFilter,
    group: issueGroupFilter,
});

const issues = combineReducers({
    data,
    filteredIssues,
    selected: selected,
    observations,
    selectedObservation,
    filters,
    requested,
    groups,
});

export default issues;
