import React from "react";
import {
    d2,
    distance,
    getCoordinates,
    getHeadingAdjustment,
    getOffsetAdjustedPosition,
    reverseOffsetAdjustment,
    getOffsetAdjustedTime,
    videoTimeLookup,
    binarySearch,
} from "./PlaylistUtils";
import _ from "lodash";
import memoize from "memoize-one";
import RouteCoordinateSystems from "./RouteCoordinateSystems";
import proj4 from "proj4";

const TIME_LOCATION_THRESHOLD = 5000; //Max time allowed between timestamp and location timestamp to still be classed as valid.

export function toPercent(number) {
    return Math.round((number + 1) * 500) / 10 + "%";
}

export function toViewBoxCoord(number, scale) {
    let factor = (1 + number) / 2;
    let coord = factor * scale;
    return coord;
}

export function vectorFromImageCoordinate(x, y, tanHalfFov) {
    const dx = x * tanHalfFov;
    const dy = -y * tanHalfFov;
    const magnitude = Math.sqrt(dx ** 2 + dy ** 2 + 1);
    return [dx / magnitude, dy / magnitude, 1 / magnitude];
}

export function vectorToImageCoordinate(v, tanHalfFov) {
    return [v[0] / (v[2] * tanHalfFov), -v[1] / (v[2] * tanHalfFov)];
}

export function intersection(a, b) {
    const p1 = a[0];
    const p2 = a[1];
    const p3 = b[0];
    const p4 = b[1];

    const x1 = p1[0];
    const y1 = p1[1];
    const x2 = p2[0];
    const y2 = p2[1];
    const x3 = p3[0];
    const y3 = p3[1];
    const x4 = p4[0];
    const y4 = p4[1];

    const A = x1 - x2;
    const B = y1 - y2;
    const C = x3 - x4;
    const D = y3 - y4;
    const E = x1 * y2 - y1 * x2;
    const F = x3 * y4 - y3 * x4;
    const G = A * D - B * C;

    return [(E * C - A * F) / G, (E * D - B * F) / G];
}

export function matrix(v, w, h) {
    let result = [];
    if (v.length === w * h) {
        let i = 0;
        for (let y = 0; y < h; y++) {
            let row = [];
            for (let x = 0; x < w; x++) {
                let value = v[i++];
                row.push(value);
            }
            result.push(row);
        }
    }
    return result;
}

export function vector(m) {
    let result = [];
    let h = m.length;
    let w = m[0].length;
    for (let y = 0; y < h; y++) {
        for (let x = 0; x < w; x++) {
            result.push(m[y][x]);
        }
    }
    return result;
}

export function transpose(m) {
    let result = [];
    let h = m.length;
    let w = m[0].length;

    for (let x = 0; x < w; x++) {
        let row = [];
        for (let y = 0; y < h; y++) {
            row.push(m[y][x]);
        }
        result.push(row);
    }
    return result;
}

export function matrixMultiply(ma, mb) {
    if (ma.length !== mb[0].length) {
        return [];
    }

    let width = ma[0].length;
    let height = mb.length;
    let depth = ma.length;

    let result = [];

    for (let y = 0; y < height; y++) {
        let row = [];
        for (let x = 0; x < width; x++) {
            let value = 0;
            for (let z = 0; z < depth; z++) {
                value += ma[z][x] * mb[y][z];
            }
            row.push(value);
        }
        result.push(row);
    }

    return result;
}

export function worldToImageCoordinates(v, calibration) {
    const tanHalfFov = Math.tan((Math.PI * calibration.horizontalFov) / 360);

    const worldRelativeVector = [v[0] - calibration.translationVector[0], v[1] - calibration.translationVector[1], v[2] - calibration.translationVector[2]];

    const cameraRelativeVector = vector(matrixMultiply(matrix(worldRelativeVector, 1, 3), transpose(calibration.rotationMatrix)));
    if (cameraRelativeVector[2] < 0.001) {
        cameraRelativeVector[2] = 0.001;
    }
    const imageCoordinates = vectorToImageCoordinate(cameraRelativeVector, tanHalfFov);
    imageCoordinates[1] /= calibration.aspectRatio;

    return [imageCoordinates[0], imageCoordinates[1]];
}

export function projectToIntersection(imageCoords, intersectionCoords, calibration) {
    const tanHalfFov = Math.tan((Math.PI * calibration.horizontalFov) / 360);

    let cameraRelativeVector = vectorFromImageCoordinate(imageCoords[0], imageCoords[1] * calibration.aspectRatio, tanHalfFov);
    let worldRelativeVector = vector(matrixMultiply(matrix(cameraRelativeVector, 1, 3), calibration.rotationMatrix));
    //Vector describes a line starting at translationVector with points n.worldRelativeVector
    //Find n where translationVector[1] + n.worldRelativeVector[1] = 0
    // n = translationVector[1]/worldRelativeVector[1]

    const xDistance = intersectionCoords[0] - calibration.translationVector[0];
    const yDistance = intersectionCoords[1] - calibration.translationVector[1];
    const zDistance = intersectionCoords[2] - calibration.translationVector[2];
    let xScale = worldRelativeVector[0] === 0 ? -1 : xDistance / worldRelativeVector[0];
    let yScale = worldRelativeVector[1] === 0 ? -1 : yDistance / worldRelativeVector[1];
    let zScale = worldRelativeVector[2] === 0 ? -1 : zDistance / worldRelativeVector[2];

    let scale = -1;
    if (xScale > 0 && (scale < 0 || xScale < scale)) {
        scale = xScale;
    }
    if (yScale > 0 && (scale < 0 || yScale < scale)) {
        scale = yScale;
    }
    if (zScale > 0 && (scale < 0 || zScale < scale)) {
        scale = zScale;
    }

    if (scale < 0) {
        return null;
    }

    return [
        calibration.translationVector[0] + scale * worldRelativeVector[0],
        calibration.translationVector[1] + scale * worldRelativeVector[1],
        calibration.translationVector[2] + scale * worldRelativeVector[2],
    ];
}

export function dotProduct(v1, v2) {
    // a.b = a_x * b_x + a_y * b_y
    // provide vectors in [x, y] format
    return v1[0] * v2[0] + v1[1] * v2[1];
}

export function nearestPointOnLine(op, p1, p2) {
    let lineVector = [p2[0] - p1[0], p2[1] - p1[1]];
    let pointVector = [op[0] - p1[0], op[1] - p1[1]];

    let t = 0;
    let distanceSquared = d2(lineVector);
    if (distanceSquared) {
        t = dotProduct(lineVector, pointVector) / distanceSquared;
    }

    if (t < 0) {
        t = 0;
    } else if (t > 1) {
        t = 1;
    }

    return [p1[0] + lineVector[0] * t, p1[1] + lineVector[1] * t];
}

export function drawLine(a, b, key, className, ref = null) {
    let x1 = a[0];
    let y1 = a[1];
    let x2 = b[0];
    let y2 = b[1];

    let dx = x2 - x1;
    let dy = y2 - y1;

    let yAtMinX = null;
    let yAtMaxX = null;
    let xAtMinY = null;
    let xAtMaxY = null;

    if (dx !== 0) {
        yAtMinX = y1 + (-1 - x1) * (dy / dx);
        yAtMaxX = y1 + (1 - x1) * (dy / dx);
    }
    if (dy !== 0) {
        xAtMinY = x1 + (-1 - y1) * (dx / dy);
        xAtMaxY = x1 + (1 - y1) * (dx / dy);
    }

    if (x1 < -1) {
        x1 = -1;
        y1 = yAtMinX;
    } else if (x1 > 1) {
        x1 = 1;
        y1 = yAtMaxX;
    }
    if (x2 < -1) {
        x2 = -1;
        y2 = yAtMinX;
    } else if (x2 > 1) {
        x2 = 1;
        y2 = yAtMaxX;
    }
    if (y1 < -1) {
        y1 = -1;
        x1 = xAtMinY;
    } else if (y1 > 1) {
        y1 = 1;
        x1 = xAtMaxY;
    }
    if (y2 < -1) {
        y2 = -1;
        x2 = xAtMinY;
    } else if (y2 > 1) {
        y2 = 1;
        x2 = xAtMaxY;
    }

    if (x1 === null || y1 === null || x2 === null || y2 === null) {
        return null;
    }

    return (
        <line
            key={key}
            ref={ref}
            className={className}
            x1={toPercent(x1)}
            y1={toPercent(y1)}
            x2={toPercent(x2)}
            y2={toPercent(y2)}
        />
    );
}

export function drawQuadrilateral(viewHeight, viewWidth, a, b, c, d, key, className, ref = null) {
    let xa = a[0];
    let ya = a[1];

    let xb = b[0];
    let yb = b[1];

    let xc = c[0];
    let yc = c[1];

    let xd = d[0];
    let yd = d[1];

    let points = `${toViewBoxCoord(xa, viewWidth)},${toViewBoxCoord(ya, viewHeight)} ${toViewBoxCoord(xb, viewWidth)},${toViewBoxCoord(yb, viewHeight)} ${toViewBoxCoord(xc, viewWidth)},${toViewBoxCoord(yc, viewHeight)} ${toViewBoxCoord(xd, viewWidth)},${toViewBoxCoord(yd, viewHeight)}`;

    return (
        <polygon
            key={key}
            ref={ref}
            className={className}
            points={points}></polygon>
    );
}

export function drawCircle(p, radius, key, className, ref = null) {
    let x = p[0];
    let y = p[1];

    if (x < -1 || x > 1 || y < -1 || y > 1) {
        return null;
    }

    return (
        <circle
            key={key}
            ref={ref}
            className={className}
            r={radius}
            cx={toPercent(x)}
            cy={toPercent(y)}
        />
    );
}

export function metersToLatLon(latLon1, offset) {
    let latRads = (Math.PI * latLon1[1]) / 180;

    let metersPerLatitude = 111132.92 - 559.82 * Math.cos(2 * latRads) + 1.175 * Math.cos(4 * latRads) - 0.0023 * Math.cos(6 * latRads);
    let metersPerLongitude = 111412.84 * Math.cos(latRads) - 93.5 * Math.cos(3 * latRads) + 0.118 * Math.cos(5 * latRads);

    let latOffset = offset[1] / metersPerLatitude;
    let lonOffset = offset[0] / metersPerLongitude;

    return [latLon1[0] + lonOffset, latLon1[1] + latOffset];
}

export function latLonToMeters(latLon1, latLon2) {
    if (!latLon1 || !latLon2) {
        return [null, null];
    }

    let latRads = (Math.PI * (latLon1[1] + latLon2[1])) / 360;
    let latOffset = latLon2[1] - latLon1[1];
    let lonOffset = latLon2[0] - latLon1[0];

    let metersPerLatitude = 111132.92 - 559.82 * Math.cos(2 * latRads) + 1.175 * Math.cos(4 * latRads) - 0.0023 * Math.cos(6 * latRads);
    let metersPerLongitude = 111412.84 * Math.cos(latRads) - 93.5 * Math.cos(3 * latRads) + 0.118 * Math.cos(5 * latRads);

    let latMeters = latOffset * metersPerLatitude;
    let lonMeters = lonOffset * metersPerLongitude;

    return [lonMeters, latMeters];
}

function CatmullRomInAxis(p0, p1, p2, p3, dt0, dt1, dt2, t, t2, t3) {
    // compute tangents when parameterized in [t1,t2]
    let t0 = (p1 - p0) / dt0 - (p2 - p0) / (dt0 + dt1) + (p2 - p1) / dt1;
    let t1 = (p2 - p1) / dt1 - (p3 - p1) / (dt1 + dt2) + (p3 - p2) / dt2;
    // rescale tangents for parametrization in [0,1]
    t0 *= dt1;
    t1 *= dt1;
    let c0 = p1;
    let c1 = t0;
    let c2 = -3 * p1 + 3 * p2 - 2 * t0 - t1;
    let c3 = 2 * p1 - 2 * p2 + t0 + t1;

    return c0 + c1 * t + c2 * t2 + c3 * t3;
}

function CentripetalCatmullRom(t, p0, p1, p2, p3) {
    let dt0 = Math.pow(d2(p0, p1), 0.25);
    let dt1 = Math.pow(d2(p1, p2), 0.25);
    let dt2 = Math.pow(d2(p2, p3), 0.25);
    // safety check for repeated points
    if (dt1 < 1e-4) dt1 = 1.0;
    if (dt0 < 1e-4) dt0 = dt1;
    if (dt2 < 1e-4) dt2 = dt1;

    const t2 = t * t;
    const t3 = t2 * t;

    let x = CatmullRomInAxis(p0[0], p1[0], p2[0], p3[0], dt0, dt1, dt2, t, t2, t3);
    let y = CatmullRomInAxis(p0[1], p1[1], p2[1], p3[1], dt0, dt1, dt2, t, t2, t3);
    return [x, y];
}

export function getHeading(playlist, playlistIndex, timeOffset, offsets, use_snapped, backwards = false) {
    if (!playlist) {
        return 0;
    }

    const currentTime = _.get(playlist, [playlistIndex, 1], 0) + timeOffset;

    let o1, o2, o3;
    let o12Distance = 0;
    let timestep = 0.5;

    while (o12Distance < 25 && timestep < 240) {
        timestep *= 2;
        const p0 = getOffsetAdjustedPosition(currentTime - 3 * timestep, playlist, offsets, use_snapped);
        const p1 = getOffsetAdjustedPosition(currentTime - timestep, playlist, offsets, use_snapped);
        const p2 = getOffsetAdjustedPosition(currentTime + timestep, playlist, offsets, use_snapped);
        const p3 = getOffsetAdjustedPosition(currentTime + 3 * timestep, playlist, offsets, use_snapped);
        if (p0 === null || p0[2] === null) {
            return 0;
        }
        if (p1 === null || p1[2] === null) {
            return 0;
        }
        if (p2 === null || p2[2] === null) {
            return 0;
        }
        if (p3 === null || p3[2] === null) {
            return 0;
        }

        o1 = latLonToMeters(p0[2], p1[2]);
        o2 = latLonToMeters(p0[2], p2[2]);
        o3 = latLonToMeters(p0[2], p3[2]);
        o12Distance = distance(o1, o2);
    }

    const [x0, y0] = CentripetalCatmullRom(0.299, [0, 0], o1, o2, o3);
    const [x1, y1] = CentripetalCatmullRom(0.301, [0, 0], o1, o2, o3);

    const heading = Math.atan2(y1 - y0, x1 - x0);
    const headingAdjustment = (Math.PI * getHeadingAdjustment(currentTime, offsets)) / 180;
    let backwardsAdjustment = 0;

    if (backwards) {
        backwardsAdjustment = Math.PI;
    }

    return heading + headingAdjustment + backwardsAdjustment;
}

export function getNextRouteCoordinates(playList, playlistIndex, timeOffset, offsets, use_snapped, maxDistance) {
    let routeCoordinates = [];
    let distance = 1;

    if (!playList || !(playlistIndex >= 0 && playlistIndex < playList.length)) {
        return [];
    }

    let currentTime = _.get(playList, [playlistIndex, 1], 0) + timeOffset;
    let start = getOffsetAdjustedPosition(currentTime, playList, offsets, use_snapped);
    if (start === null || start[2] === null) {
        return routeCoordinates;
    }
    let lastMetersOffset = [0, 0];
    let cumulativeDistance = 0;
    const startPosition = start[2];
    while (distance < maxDistance) {
        if (playlistIndex >= playList.length) {
            break;
        }

        const itemPosition = getCoordinates(_.get(playList, [playlistIndex, 3], null), use_snapped);
        if (itemPosition !== null) {
            const metersOffset = latLonToMeters(startPosition, itemPosition);
            const pointDistance = Math.sqrt((metersOffset[0] - lastMetersOffset[0]) ** 2 + (metersOffset[1] - lastMetersOffset[1]) ** 2);
            cumulativeDistance += pointDistance;
            if (routeCoordinates.length < 3 || cumulativeDistance >= distance) {
                routeCoordinates.push(metersOffset);
                distance *= 1.1;
            }
            lastMetersOffset = metersOffset;
        }
        playlistIndex += 1;
    }

    return routeCoordinates;
}

export function getStartCoordinate(playlistItem, use_snapped) {
    if (playlistItem && playlistItem[3]) {
        return getCoordinates(playlistItem[3], use_snapped);
    } else if (playlistItem.start) {
        return [playlistItem.start[1], playlistItem.start[2]];
    } else {
        return null;
    }
}

export function getEndCoordinate(playlistItem, use_snapped) {
    if (playlistItem && playlistItem[4]) {
        return getCoordinates(playlistItem[4], use_snapped);
    } else if (playlistItem.end) {
        return [playlistItem.end[1], playlistItem.end[2]];
    } else {
        return null;
    }
}

export function calculatePointDistance(startLL, endLL) {
    try {
        if (startLL && endLL) {
            let distanceMeters = latLonToMeters(startLL, endLL);
            return Math.pow(distanceMeters[0] ** 2 + distanceMeters[1] ** 2, 0.5);
        } else {
            return 0;
        }
    } catch {
        return 0;
    }
}

export function getCameraCharacteristics(calibration) {
    let yaw, pitch, roll;

    if (Math.abs(calibration.rotationMatrix[0][2]) !== 1) {
        yaw = -Math.asin(calibration.rotationMatrix[0][2]);
        const cosPitch = Math.cos(yaw);
        pitch = Math.atan2(calibration.rotationMatrix[1][2] / cosPitch, calibration.rotationMatrix[2][2] / cosPitch);
        roll = -Math.atan2(calibration.rotationMatrix[0][1] / cosPitch, calibration.rotationMatrix[0][0] / cosPitch);
    } else {
        roll = 0;
        if (calibration.rotationMatrix[0][2] === -1) {
            pitch = Math.PI / 2;
            yaw = Math.atan2(calibration.rotationMatrix[1][0], calibration.rotationMatrix[2][0]);
        } else {
            pitch = -Math.PI / 2;
            yaw = Math.atan2(-calibration.rotationMatrix[1][0], -calibration.rotationMatrix[2][0]);
        }
    }

    return {
        pitch,
        yaw,
        roll,
        x: calibration.translationVector[0],
        y: calibration.translationVector[1],
        z: calibration.translationVector[2],
    };
}

export function calculatePositionOffset(nearest_position, positions, currentCoords) {
    /*

    given our current position and list of the nearest route positions determine whether or
    not the closest route position is up or downline, and therefore whether distance offset
    should be added or subtracted when doing sub-chain precise route location.

    all lines have a direction in which the ELR / distance increases. i.e it might be zero at
    paddington and 198 in exeter. generally - if you are travelling 'up' the line, your elr would increase
    as you travel, and if you are travelling down the line it would decrease.

    when presenting our elr position to a sub-chain accuracy, we need to know which side of
    the nearest chain we are: up or down the line.

    if we are down the line from the nearest chain, we need to add a positive offset to that
    chain's position: 18 chains + 7 metres

    if we are up the line from the nearest chain, we need to subtract that offset from the
    chain's position: 18 chains - 7 metres

    to work out whether we (the device) are up or down the line, we will take the dot product
    of two vectors. the first vector is from the nearest chain (C2) to the aivr device (D), call
    this vector C2->D. the second vector is from the chain before the nearest chain (C1), to
    the nearest chain (C2), call this C1->C2.

    If the dot product is positive, then we know that these two vectors are pointing in roughly
    the same direction. In which case the orientation is: C1 -> C2 -> Device, and the device
    is up the line from C2 (positive offset)

    If the dot product is negative, then we know that these two vectors are pointing in opposite
    directions. In this case the orientation is: C1 -> Device -> C2, and the device is down the line
    from C2 (negative offset)

    */

    let prior_position = _.find(positions, function (element) {
        return parseInt(element.position) === parseInt(nearest_position.position) - 1;
    });
    let next_position = _.find(positions, function (element) {
        return parseInt(element.position) === parseInt(nearest_position.position) + 1;
    });

    if (prior_position === undefined && next_position === undefined) {
        // there is no prior or next position, so offset can't be calculated
        return 0;
    }

    if (!currentCoords) {
        return 0;
    }

    // determine vector from nearest position to device
    let v2 = latLonToMeters([nearest_position.lon, nearest_position.lat], currentCoords);

    // determine vector from nearest-position-less-one to nearest-position
    // NOTE that latLonToMeters actually expects arrays of [lon, lat]
    let v1 = 0,
        v1_l2 = 0;

    if (prior_position !== undefined) {
        v1 = latLonToMeters([nearest_position.lon, nearest_position.lat], [prior_position.lon, prior_position.lat]);
        v1_l2 = dotProduct(v1, v1);
        // Dot product is the length of the projection onto v1 of v2 - ie how far along the track we are, ignoring lateral offset
        if (v1_l2 > 0) {
            let dot_product = dotProduct(v1, v2) / v1_l2;
            // Use dot product only if it's between prior and current or there is no next
            if ((0 <= dot_product && dot_product <= 1) || next_position === undefined) {
                return -dot_product;
            }
        }
    }

    // determine vector from nearest-position to nearest-position-plus-one
    // NOTE that latLonToMeters actually expects arrays of [lon, lat]
    if (next_position !== undefined) {
        v1 = latLonToMeters([nearest_position.lon, nearest_position.lat], [next_position.lon, next_position.lat]);
        v1_l2 = dotProduct(v1, v1);
        // Use dot regardless if it's negative as better cases are already handled above
        if (v1_l2 > 0) {
            return dotProduct(v1, v2) / v1_l2;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

export const getClosestLocation = (timestamp, locationData) => {
    if (!locationData) {
        return null;
    }
    // const allTimestamps = Object.keys(locationData)
    const foundTimestampIndex = getClosestTimestamp(timestamp, locationData);

    if (foundTimestampIndex < 0) {
        return null;
    }
    const foundTimestamp = locationData[foundTimestampIndex].timestamp;

    let indexToCompare = foundTimestampIndex;

    if (timestamp < foundTimestamp) {
        if (locationData[indexToCompare - 1]) {
            indexToCompare = foundTimestampIndex - 1;
        }
    } else if (timestamp > foundTimestamp) {
        if (locationData[indexToCompare + 1]) {
            indexToCompare = foundTimestampIndex + 1;
        }
    }

    if (indexToCompare === foundTimestampIndex) {
        return locationData[foundTimestampIndex].location;
    }

    let [interpolatedELR, interpolatedPosition, interpolatedTrack] = locationData[foundTimestampIndex].location;

    let compareTimestamp = locationData[indexToCompare].timestamp;

    if (Math.abs(foundTimestamp - timestamp) > TIME_LOCATION_THRESHOLD && Math.abs(compareTimestamp - timestamp) > TIME_LOCATION_THRESHOLD) {
        return null;
    }

    let [compareELR, comparePosition, compareTrack] = locationData[indexToCompare].location;

    if (compareELR !== interpolatedELR) {
        if (Math.abs(foundTimestamp - timestamp) > Math.abs(compareTimestamp - timestamp)) {
            interpolatedELR = compareELR;
            interpolatedPosition = comparePosition;
            interpolatedTrack = compareTrack;
        }
    } else {
        if (compareTrack !== interpolatedTrack) {
            if (Math.abs(foundTimestamp - timestamp) > Math.abs(compareTimestamp - timestamp)) {
                interpolatedTrack = compareTrack;
            }
        }

        let interpolateValue = Math.abs(timestamp - foundTimestamp) / Math.abs(foundTimestamp - compareTimestamp);
        let distanceToAdd = interpolateValue * (comparePosition - interpolatedPosition);
        interpolatedPosition = interpolatedPosition + distanceToAdd;
    }
    return [interpolatedELR, interpolatedPosition, interpolatedTrack, locationData[foundTimestampIndex].sources];
};
// calculatePositionSystemsForLocation
export const calculateRouteCoordinatesForLocation = (timestamp, locationData, systemID) => {
    if (timestamp < 1000000000000) {
        timestamp *= 1000;
    }
    const closestLocation = getClosestLocation(timestamp, locationData);
    if (!closestLocation) {
        return null;
    }

    // const system = RouteCoordinateSystems[0];
    const system = _.find(RouteCoordinateSystems, (system) => {
        return system.ID === systemID;
    });

    if (closestLocation && system) {
        return system.create(closestLocation[0], closestLocation[1], closestLocation[2], closestLocation[3]);
    }
};

const getClosestTimestamp = (timestamp, allTimestamps) => {
    // Binary search implementation - could be replaced with use of _.sortedIndexOf or _.sortedIndexBy from lodash
    timestamp += 0.0001;
    let min = 0,
        max = allTimestamps.length - 1;
    while (min < max) {
        let idx = Math.ceil((min + max) / 2);
        if (parseInt(allTimestamps[idx].timestamp) <= timestamp) {
            min = idx;
        } else {
            max = idx - 1;
        }
    }
    if (min === max) {
        return min;
    } else {
        return max;
    }
};

export const calculateELREstimates = memoize((coordinates, groupedCoordinates, supportedCoordinateSystems) => {
    let elrs = [];

    let considerationDistance = 50;
    let considerationD2 = considerationDistance ** 2;

    if (groupedCoordinates) {
        Object.keys(groupedCoordinates).forEach((key) => {
            const system = _.find(RouteCoordinateSystems, (system) => key === system.ID);
            if (system && supportedCoordinateSystems.includes(key)) {
                const coordinatesForSystem = groupedCoordinates[key];
                let extraDistance = 0;
                if (coordinatesForSystem.length > 0) {
                    const originalCoordinate = coordinatesForSystem[0].from;
                    extraDistance = distance(latLonToMeters(originalCoordinate, coordinates));
                }

                const minDistanceAwaySquared = Math.max(0, extraDistance - considerationDistance) ** 2;
                const maxDistanceAwaySquared = (extraDistance + considerationDistance) ** 2;

                let resortedCoordinates = _.chain(coordinatesForSystem)
                    .filter((c) => c.distanceAwaySquared >= minDistanceAwaySquared && c.distanceAwaySquared <= maxDistanceAwaySquared)
                    .map((c) => ({ c, d: d2(latLonToMeters(coordinates, [c.lon, c.lat])) }))
                    .filter((c) => c.d <= considerationD2)
                    .sortBy((c) => c.d)
                    .map((c) => c.c)
                    .value();

                let uniqueCoordMap = {};
                let uniqueCoordList = [];

                for (let i = 0; i < resortedCoordinates.length; i++) {
                    let route_track = resortedCoordinates[i].route + "." + resortedCoordinates[i].subposition;
                    if (!uniqueCoordMap[route_track]) {
                        uniqueCoordMap[route_track] = true;
                        uniqueCoordList.push(resortedCoordinates[i]);
                    }

                    if (uniqueCoordList.length >= 4) {
                        break;
                    }
                }
                if (uniqueCoordList.length > 0) {
                    uniqueCoordList.forEach((nearest) => {
                        let coordsInGroup = _.filter(resortedCoordinates, (coord) => {
                            return coord.route === nearest.route && coord.subposition === nearest.subposition;
                        });
                        const coordinate = getCoordinate(key, nearest, coordsInGroup, coordinates, RouteCoordinateSystems);
                        if (coordinate !== null) {
                            elrs.push(coordinate);
                        }
                    });
                }
            }
        });
        return elrs;
    }
});

export const getCoordinate = memoize((systemID, nearest, groupedCoords, playlistCoords, routeCoordinateSystems) => {
    const position_offset = calculatePositionOffset(nearest, groupedCoords, playlistCoords);
    const system = _.find(routeCoordinateSystems, (system) => systemID === system.ID);
    if (system) {
        return system.create(nearest.route, nearest.position + position_offset, nearest.subposition);
    } else {
        return null;
    }
});

const chooseClosest = (a, b) => {
    const d1 = a.distance;
    const d2 = b.distance;
    if (d1 <= d2) {
        return a;
    } else {
        return b;
    }
};

export const nearestPointOnGeometry = memoize((lat, lon, mapGeometry) => {
    const coordinatePosition = [lon, lat];
    return _.chain(mapGeometry)
        .filter(({ bounds }) => {
            return bounds[0] - 0.01 <= lon && lon <= bounds[2] + 0.01 && bounds[1] - 0.01 <= lat && lat <= bounds[3] + 0.01;
        })
        .map(({ points }) => {
            let closestPoint = null;
            let closestIdx = null;
            let closestLatLon = null;
            let closestDistance = null;
            let lastOffset = null;
            let lastPoint = null;
            let lastIdx = null;
            points.forEach((p, idx) => {
                const offset = latLonToMeters(coordinatePosition, [p[1], p[2]]);
                if (lastOffset) {
                    const closestPointOnLine = nearestPointOnLine([0, 0], lastOffset, offset);
                    const dist2 = d2(closestPointOnLine);
                    if (closestDistance === null || dist2 < closestDistance) {
                        const p1d2 = d2(lastOffset);
                        const p2d2 = d2(offset);
                        if (p1d2 < p2d2) {
                            closestPoint = lastPoint;
                            closestIdx = lastIdx;
                        } else {
                            closestPoint = p;
                            closestIdx = idx;
                        }
                        closestDistance = dist2;
                        closestLatLon = metersToLatLon(coordinatePosition, closestPointOnLine);
                    }
                }
                lastOffset = offset;
                lastPoint = p;
                lastIdx = idx;
            });

            return {
                index: closestIdx,
                point: closestPoint,
                coordinates: closestLatLon,
                distance: Math.sqrt(closestDistance),
            };
        })
        .reduce(chooseClosest)
        .value();
});

export const nearestPointOnRoute = memoize((lat, lon, routeGeometry, use_snapped) => {
    const coordinatePosition = [lon, lat];
    return _.chain(routeGeometry)
        .map((point, index) => {
            const start = getStartCoordinate(point, use_snapped);
            const end = getEndCoordinate(point, use_snapped);
            if (start && end) {
                return {
                    index,
                    start: latLonToMeters(coordinatePosition, start),
                    end: latLonToMeters(coordinatePosition, end),
                };
            } else {
                return false;
            }
        })
        .filter((point) => point)
        .map(({ start, end, index }) => {
            const closestPointOnLine = nearestPointOnLine([0, 0], start, end);
            const distanceSquared = d2(closestPointOnLine);
            return { point: metersToLatLon(coordinatePosition, closestPointOnLine), index, distanceSquared };
        })
        .reduce((a, b) => (a.distanceSquared <= b.distanceSquared ? a : b))
        .value();
});

export function locationsAreEqual(loc1, loc2) {
    return loc1 && loc2 && loc1[0] === loc2[0] && loc1[1] === loc2[1];
}

export const routeDistanceMap = memoize((datum, routeGeometry, use_snapped) => {
    const startIndex = datum.index;

    const geometryAsMeters = routeGeometry.map((point, idx) => {
        const startCoordinate = getStartCoordinate(point, use_snapped);
        let offset = null;
        if (startCoordinate) {
            offset = latLonToMeters(datum.point, startCoordinate);
        }
        return {
            distance: 0,
            downStream: idx >= startIndex,
            offset,
            location: startCoordinate,
        };
    });

    const targetDistance = -distance(geometryAsMeters[startIndex].offset);

    for (let index = 1; index < geometryAsMeters.length; index += 1) {
        const distanceGain = distance(latLonToMeters(geometryAsMeters[index - 1].location, geometryAsMeters[index].location));
        geometryAsMeters[index].distance = geometryAsMeters[index - 1].distance + distanceGain;
    }

    const distanceOffset = targetDistance - geometryAsMeters[startIndex].distance;

    for (let index = 0; index < geometryAsMeters.length; index += 1) {
        geometryAsMeters[index].distance += distanceOffset;
        if (!geometryAsMeters[index].downStream) {
            geometryAsMeters[index].distance *= -1;
        }
        delete geometryAsMeters[index].location;
    }

    return geometryAsMeters;
});

export const routePointDistance = memoize((datum, point, routeDistanceMap, indexHint) => {
    const pm = latLonToMeters(datum.point, point);

    const min = indexHint === undefined ? 0 : indexHint - 10;
    const max = indexHint === undefined ? routeDistanceMap.length : indexHint + 11;

    let lp = routeDistanceMap[min];
    let ld = null;
    let li = null;
    let lo = null;

    for (let i = min + 1; i < max; i++) {
        const p = routeDistanceMap[i];
        if (!p || !lp || !p.offset || !lp.offset) {
            if (p && p.offset) {
                lp = p;
            }
            continue;
        }

        let lineVector = [p.offset[0] - lp.offset[0], p.offset[1] - lp.offset[1]];
        let pointVector = [pm[0] - lp.offset[0], pm[1] - lp.offset[1]];

        let t = 0;
        let distanceSquared = d2(lineVector);
        if (distanceSquared) {
            t = dotProduct(lineVector, pointVector) / distanceSquared;
        }
        if (t < 0) {
            t = 0;
        } else if (t > 1) {
            t = 1;
        }
        const o = [lineVector[0] * t, lineVector[1] * t];
        const distanceSquaredFromLine = d2(pointVector, o);

        if (ld === null || distanceSquaredFromLine < ld) {
            ld = distanceSquaredFromLine;
            li = i - 1;
            lo = o;
        }

        lp = p;
    }

    if (li === datum.index) {
        return distance([lo[0] + routeDistanceMap[li].offset[0], lo[1] + routeDistanceMap[li].offset[1]]);
    } else if (routeDistanceMap[li]) {
        return routeDistanceMap[li].distance + (routeDistanceMap[li].downStream ? 1 : -1) * distance(lo);
    } else {
        return 0;
    }
});

export const coordinateToTimestamp = (coordinates, playlist, use_snapped, gpsOffsets, absolute = false) => {
    let orderedPoints = _.sortBy(
        playlist.map((point, idx) => {
            let d;
            let t;
            if (point.length > 4) {
                let p1 = getStartCoordinate(point, use_snapped);
                let p2 = getEndCoordinate(point, use_snapped);
                if (p2 === null && idx < playlist.length - 1) {
                    p2 = getStartCoordinate(playlist[idx + 1], use_snapped);
                }
                if (p1 !== null && p2 !== null) {
                    const nearestPoint = nearestPointOnLine(coordinates, p1, p2);
                    d = distance(coordinates, nearestPoint);
                    let lineVector = [p2[0] - p1[0], p2[1] - p1[1]];
                    let pointVector = [nearestPoint[0] - p1[0], nearestPoint[1] - p1[1]];
                    let offset = (dotProduct(lineVector, pointVector) / dotProduct(lineVector, lineVector)) * point[2];
                    let timestampForCoordinate = point[1] + offset;
                    if (absolute) {
                        t = point[3][2];
                    } else {
                        t = reverseOffsetAdjustment(timestampForCoordinate, gpsOffsets);
                    }
                } else if (p1 !== null) {
                    d = distance(coordinates, p1);
                    if (absolute) {
                        t = point[3][2];
                    } else {
                        t = reverseOffsetAdjustment(point[1], gpsOffsets);
                    }
                } else {
                    d = 360;
                    if (absolute) {
                        t = point[3][2];
                    } else {
                        t = point[1];
                    }
                }
            } else if (point.start) {
                d = distance(coordinates, [point.start[1], point.start[2]]);
                t = point.start[0];
            } else {
                d = distance(coordinates, point[1]);
                t = point[0];
            }
            return {
                point,
                distance: d,
                timestamp: t,
            };
        }),
        (point) => point.distance,
    );

    return orderedPoints[0].timestamp;
};

export const findIndexAtDistance = (distance, video, position, offset, offsets, useSnapped, forward) => {
    let allVideos = video;
    if (!allVideos || !allVideos.length || position > allVideos.length) {
        return [0, 0];
    }

    let startIndex = position;
    let startTime = allVideos[startIndex][1] + offset;
    let offsetStartTime = getOffsetAdjustedTime(startTime, offsets);
    let offsetStartIndex = videoTimeLookup(offsetStartTime, allVideos);
    if (offsetStartIndex === -1) {
        return [0, 0];
    }
    let offsetStartTimeOffset = offsetStartTime - allVideos[offsetStartIndex][1];

    let totalDistance = 0;
    let distanceBetweenPoints;

    if (offsetStartTimeOffset) {
        distanceBetweenPoints = calculatePointDistance(
            getStartCoordinate(allVideos[offsetStartIndex], useSnapped),
            getStartCoordinate(allVideos[offsetStartIndex + 1], useSnapped),
        );
        let duration = allVideos[offsetStartIndex][2];
        if (forward) {
            totalDistance -= (distanceBetweenPoints / duration) * offsetStartTimeOffset;
        } else {
            totalDistance += (distanceBetweenPoints / duration) * offsetStartTimeOffset;
        }
    }

    let i;
    let timeOffset;

    if (forward) {
        for (i = offsetStartIndex; i < allVideos.length - 1 && totalDistance < distance; i += 1) {
            distanceBetweenPoints = calculatePointDistance(getStartCoordinate(allVideos[i], useSnapped), getStartCoordinate(allVideos[i + 1], useSnapped));
            totalDistance += distanceBetweenPoints;
        }

        if (totalDistance > distance && _.get(allVideos, [i - 1, 5], null)) {
            let distanceOvershoot = totalDistance - distance;
            let distanceFromLast = distanceBetweenPoints - distanceOvershoot;
            totalDistance -= distanceOvershoot;
            i -= 1;
            let lastDuration = allVideos[i][2];
            timeOffset = (distanceFromLast / distanceBetweenPoints) * lastDuration;
        } else {
            timeOffset = allVideos[i][2];
        }
    } else {
        for (i = offsetStartIndex; i > 0 && totalDistance < distance; i -= 1) {
            distanceBetweenPoints = calculatePointDistance(
                getStartCoordinate(allVideos[i], useSnapped),
                getStartCoordinate(_.get(allVideos, [i - 1], null), useSnapped),
            );
            totalDistance += distanceBetweenPoints;
        }

        if (totalDistance > distance && allVideos[i][5]) {
            let distanceOvershoot = totalDistance - distance;
            totalDistance -= distanceOvershoot;
            let lastDuration = allVideos[i][2];
            timeOffset = (distanceOvershoot / distanceBetweenPoints) * lastDuration;
        } else {
            timeOffset = 0;
        }
    }

    let offsetEndTime = allVideos[i][1] + timeOffset;
    let endTime = reverseOffsetAdjustment(offsetEndTime, offsets);
    let endIndex = videoTimeLookup(endTime, allVideos);
    let endOffset = endTime - allVideos[endIndex][1];
    return [endIndex, endOffset];
};

export function calculateBearingText(bearing) {
    if (bearing === null) {
        return "Unknown";
    }

    while (bearing < 0) {
        bearing += 360;
    }
    if (bearing >= 360) {
        bearing = bearing % 360;
    }

    if (bearing < 22.5) {
        return "North";
    } else if (bearing < 67.5) {
        return "Northeast";
    } else if (bearing < 112.5) {
        return "East";
    } else if (bearing < 157.5) {
        return "Southeast";
    } else if (bearing < 202.5) {
        return "South";
    } else if (bearing < 247.5) {
        return "Southwest";
    } else if (bearing < 292.5) {
        return "West";
    } else if (bearing < 337.5) {
        return "Northwest";
    } else {
        return "North";
    }
}

//Convert speed from meters per second to another unit
export function convertSpeed(speed, toUnit) {
    if (toUnit === "miles") {
        return speed * 2.23694;
    } else if (toUnit === "kilometers") {
        return speed * 3.6;
    }

    return speed;
}

export function SVGCoordToLocation(x, positionData) {
    const positionalKeys = Object.keys(positionData)
        .map(parseFloat)
        .sort(function (a, b) {
            return a - b;
        });

    const nearestIndex = binarySearch(x, positionalKeys, (key) => key);

    const foundCoordinate = positionalKeys[nearestIndex];
    const indexToCompare = nearestIndex + 1;
    const compareCoordinate = positionalKeys[indexToCompare];

    const startValue = parseFloat(positionData[foundCoordinate]);
    const endValue = parseFloat(positionData[compareCoordinate]);

    const totalDiffChain = endValue - startValue;
    const totalDistanceX = compareCoordinate - foundCoordinate;
    const distanceAlong = x - foundCoordinate;

    const ratio = distanceAlong / totalDistanceX;
    const distanceAlongLine = totalDiffChain * ratio;
    const chainValue = startValue + distanceAlongLine;

    return chainValue;
}

export function findNearestRailImage(elr, targetChain, track, images, forceNearest = false) {
    let filteredImages = images
        .filter((img) => {
            return img.mwv.trid === track && elr.toLowerCase() === img.mwv.elr.toLowerCase();
        })
        .map((img) => {
            const chains = parseInt(img.mwv.mile) * 80 + parseFloat(img.mwv.yard) / 22;
            return {
                timestamp: img.timestamp,
                chains,
            };
        });

    filteredImages = _.orderBy(filteredImages, "chains");
    // when search fails this currently relocates us back to the beginning
    let indexFound = -1;
    indexFound = binarySearch(targetChain, filteredImages, (item) => item.chains);
    if (forceNearest && filteredImages[indexFound + 1]) {
        let foundValue = filteredImages[indexFound].chains;
        let nextValue = filteredImages[indexFound + 1].chains;
        if (Math.abs(nextValue - targetChain) < Math.abs(foundValue - targetChain)) {
            indexFound = indexFound + 1;
        }
    }

    let timestampFound = filteredImages[indexFound];
    if (!timestampFound) {
        return -1;
    }
    // image timestamp is unique because of how the list is built - although that may be problematic? what if
    // two images are collected at the same timestamp by two trains in two locations??
    const railIndex = _.findIndex(images, (img) => img.timestamp === timestampFound.timestamp);
    return railIndex;
}

export const chainageFromImage = (image) => {
    return parseInt(image.mwv.mile) * 80 + parseFloat(image.mwv.yard) / 22;
};

export const chainLineToCoords = (line, chain) => {
    const lineStartX = parseFloat(line["x1"]);
    const lineEndX = parseFloat(line["x2"]);
    const totalX = Math.abs(lineEndX - lineStartX);
    const minX = Math.min(lineStartX, lineEndX);

    const lineStartY = parseFloat(line["y1"]);
    const lineEndY = parseFloat(line["y2"]);
    const totalY = Math.abs(lineEndY - lineStartY);
    const minY = Math.min(lineStartY, lineEndY);

    const startChain = parseFloat(line["chains1"]);
    const endChain = parseFloat(line["chains2"]);

    const distanceAlongLine = (chain - startChain) / (endChain - startChain);

    let x = minX + totalX * distanceAlongLine;
    let y = minY + totalY * distanceAlongLine;

    if (lineStartX > lineEndX) {
        x = lineStartX - totalX * distanceAlongLine;
    }

    if (lineStartY > lineEndY) {
        y = lineStartY - totalY * distanceAlongLine;
    }
    return [x, y];
};

export const coordsLineToChain = (line, x) => {
    const lineStartX = parseFloat(line.x1);
    const lineEndX = parseFloat(line.x2);
    const totalX = Math.abs(lineEndX - lineStartX);
    const minX = Math.min(lineStartX, lineEndX);

    const startChain = parseFloat(line.chains1);
    const endChain = parseFloat(line.chains2);
    const totalChains = Math.abs(endChain - startChain);
    const minChain = Math.min(startChain, endChain);
    const maxChain = Math.max(startChain, endChain);

    const distanceAlongLine = (x - minX) / totalX;

    let clickedChain = minChain + totalChains * distanceAlongLine;
    if (lineStartX > lineEndX) {
        clickedChain = maxChain - totalChains * distanceAlongLine;
    }
    return clickedChain;
};

export function coordinateToLatLon(coordinate) {
    if (coordinate) {
        return `${parseFloat(coordinate[1]).toFixed(6)}, ${parseFloat(coordinate[0]).toFixed(6)}`;
    } else {
        return "";
    }
}

export function coordinateToOSGB(coordinate) {
    if (coordinate) {
        var osgb =
            "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs ";
        var wgs84 = "+proj=longlat +datum=WGS84 +no_defs ";
        const osgbConversion = proj4(wgs84, osgb, [coordinate[0], coordinate[1]]);

        return `${Math.round(osgbConversion[0])}, ${Math.round(osgbConversion[1])}`;
    } else {
        return "";
    }
}

export function osgbToCoordinate(osgbToConvert) {
    if (osgbToConvert) {
        var osgb =
            "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs ";
        var wgs84 = "+proj=longlat +datum=WGS84 +no_defs ";
        const latLonConversion = proj4(osgb, wgs84, [osgbToConvert[0], osgbToConvert[1]]);

        return [Number(parseFloat(latLonConversion[1])), Number(parseFloat(latLonConversion[0]))];
    } else {
        return "";
    }
}

export function coordinateToIrishEasting(coordinate) {
    if (coordinate) {
        const osgb =
            "+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +a=6377340.189 +rf=299.3249646 +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs +type=crs";
        const wgs84 = "+proj=longlat +datum=WGS84 +no_defs ";

        const osgbConversion = proj4(wgs84, osgb, [coordinate[0], coordinate[1]]);
        return `${Math.round(osgbConversion[0])}, ${Math.round(osgbConversion[1])}`;
    } else {
        return "";
    }
}

export const calculateElrGroups = (railImages, locationData, routeSystemID) => {
    if (!railImages.length) {
        return [];
    }

    const elrs = [];

    let firstLocation;
    if (_.get(railImages, [0, "mwv", "elr"], false)) {
        firstLocation = {
            elr: _.get(railImages, [0, "mwv", "elr"]),
            position: parseInt(railImages[0].mwv.mile) * 80 + parseFloat(railImages[0].mwv.yard) / 22,
        };
    } else {
        firstLocation = calculateRouteCoordinatesForLocation(railImages[0].timestamp / 1000, locationData, routeSystemID);
    }

    if (firstLocation && firstLocation.elr) {
        let currentElrObject = {
            elr: firstLocation.elr,
            start: 0,
            end: 0,
            startPosition: firstLocation.position,
            endPosition: firstLocation.position,
        };
        elrs.push(currentElrObject);
        for (let i = 1; i < railImages.length; i++) {
            let location;
            if (_.get(railImages, [i, "mwv", "elr"], false)) {
                const mile = _.get(railImages, [i, "mwv", "mile"], 0);
                const yard = _.get(railImages, [i, "mwv", "yard"], 0);
                location = {
                    elr: _.get(railImages, [i, "mwv", "elr"]),
                    position: parseInt(mile) * 80 + yard / 22,
                };
            } else {
                location = calculateRouteCoordinatesForLocation(railImages[i].timestamp / 1000, locationData, routeSystemID);
            }
            if (location && location.elr) {
                if (location.elr !== currentElrObject.elr) {
                    currentElrObject = {
                        elr: location.elr,
                        start: i,
                        end: i,
                        startPosition: location.position,
                        endPosition: location.position,
                    };
                    elrs.push(currentElrObject);
                } else {
                    currentElrObject.end = i;
                    currentElrObject.endPosition = location.position;
                }
            }
        }
        return elrs;
    } else {
        return [];
    }
};

export function svgLineHasCoverage(line, images) {
    // given an svg line and an array of rail images, check if the images cover ~50%+ of the line
    const trackID = line["TRID"];

    const lineEndChain = line["chains2"];
    const lineStartChain = line["chains1"];
    const lineELR = line["ELR"];

    const nearestStartIndex = findNearestRailImage(lineELR, lineStartChain, trackID, images, true);
    const nearestStartImage = images[nearestStartIndex];

    const quarterChainDistance = Math.abs(parseFloat(line["chains1"]) - parseFloat(line["chains2"])) / 4;

    const lineQuarterChain = Math.min(lineStartChain, lineEndChain) + quarterChainDistance;
    const nearestQuarterIndex = findNearestRailImage(lineELR, lineQuarterChain, trackID, images, true);
    const nearestQuarterImage = images[nearestQuarterIndex];

    const lineThreeQuarterChain = Math.min(lineStartChain, lineEndChain) + quarterChainDistance * 3;
    const nearestThreeQuarterIndex = findNearestRailImage(lineELR, lineThreeQuarterChain, trackID, images, true);
    const nearestThreeQuarterImage = images[nearestThreeQuarterIndex];

    const nearestEndIndex = findNearestRailImage(lineELR, lineEndChain, trackID, images, true);
    const nearestEndImage = images[nearestEndIndex];

    let countIncluded = 0;
    if (nearestStartImage && nearestStartImage.mwv && Math.abs(chainageFromImage(nearestStartImage) - lineStartChain < ONE_METRE_IN_CHAINS * 4)) {
        countIncluded += 1;
    }

    if (nearestQuarterImage && nearestQuarterImage.mwv && Math.abs(chainageFromImage(nearestQuarterImage) - lineQuarterChain) < ONE_METRE_IN_CHAINS * 4) {
        countIncluded += 1;
    }

    if (
        nearestThreeQuarterImage &&
        nearestThreeQuarterImage.mwv &&
        Math.abs(chainageFromImage(nearestThreeQuarterImage) - lineThreeQuarterChain) < ONE_METRE_IN_CHAINS * 4
    ) {
        countIncluded += 1;
    }

    if (nearestEndImage && nearestEndImage.mwv && Math.abs(chainageFromImage(nearestEndImage) - lineEndChain) < ONE_METRE_IN_CHAINS * 4) {
        countIncluded += 1;
    }
    return countIncluded > 1;
}

export const ONE_METRE_IN_CHAINS = 0.0497097;

export const getSvgLineCoverage = (line, railImages) => {
    const trackID = line["TRID"];

    const lineEndChain = line["chains2"];
    const lineStartChain = line["chains1"];
    const lineELR = line["ELR"];

    const chainDiff = Math.abs(lineStartChain - lineEndChain);

    const interval = chainDiff / 100;

    const coverage = [];
    let start = null;
    let end = null;

    for (let i = lineStartChain; i < lineEndChain; i += interval) {
        const chainToFind = i;
        const nearestImageIndex = findNearestRailImage(lineELR, chainToFind, trackID, railImages, true);
        const nearestImage = railImages[nearestImageIndex];
        if (nearestImage && nearestImage.mwv && Math.abs(chainageFromImage(nearestImage) - chainToFind) < ONE_METRE_IN_CHAINS * 4) {
            if (_.isNil(start)) {
                start = chainToFind;
                end = chainToFind;
            } else if (Math.abs(end - chainToFind) > interval + 0.1) {
                coverage.push({
                    start,
                    end,
                });
                start = chainToFind;
                end = chainToFind;
            } else {
                end = chainToFind;
            }
        }
    }
    coverage.push({
        start,
        end,
    });

    return coverage;
};

export const mileYardToYard = (mile, yard) => {
    return mile * 1760 + yard;
};

export const calculatePatrolDeviceConfig = (configs, timestamp) => {
    const timestamps = Object.keys(configs);

    if (!timestamp) {
        if (configs[timestamps[0]]) {
            return configs[timestamps[0]];
        }
        return {};
    }

    const timestampsBeforeSession = timestamps
        .map((ts) => parseInt(ts))
        .sort(function (a, b) {
            return a - b;
        })
        .filter((ts) => {
            return ts < timestamp;
        });

    let config = {};

    if (timestampsBeforeSession && timestampsBeforeSession.length) {
        let maxTimestamp = Math.max(...timestampsBeforeSession);
        config = configs[maxTimestamp.toString()];
    }
    return config;
};

export const handleLocationResults = (result_type, coordinates, search_location) => {
    if (result_type === "no_matches" || !coordinates) {
        return false;
    }

    if (search_location) {
        let { lat, lon } = search_location;
        coordinates = _.sortBy(coordinates, (coordinate) => {
            return d2(latLonToMeters([lon, lat], [coordinate.lon, coordinate.lat]));
        });
        const closest_with_video = {};
        const closest_without_video = {};
        coordinates.forEach((c) => {
            let route_and_track = `${c.system}.${c.route}.${c.subposition}`;
            if (closest_with_video[route_and_track] || closest_without_video[route_and_track]) {
                return;
            }
            if (c.video !== undefined) {
                closest_with_video[route_and_track] = c;
            } else {
                closest_without_video[route_and_track] = c;
            }
        });
        coordinates = _.valuesIn(closest_with_video).concat(_.valuesIn(closest_without_video));
    } else {
        let coordinates_with_video = coordinates.filter((c) => c.video !== undefined);
        let coordinates_without_video = coordinates.filter((c) => c.video === undefined);
        coordinates_with_video = _.sortBy(coordinates_with_video, (coordinate) => -1 * coordinate.video.timestamp);
        coordinates_without_video = _.sortBy(coordinates_without_video, (coordinate) => coordinate.subposition);
        coordinates = coordinates_with_video.concat(coordinates_without_video);
    }

    return coordinates;
};

export const calculateImagePulseCount = (timestamp, pulseCounts) => {
    return _.get(pulseCounts, [timestamp, "pulse_count"], null);
};

export const calculatePulseFromTimestamp = (pulseTarget, pulseCounts) => {
    const pulseDatas = Object.values(pulseCounts);
    const sortedPulseValues = _.clone(pulseDatas).sort((a, b) => a.pulse_count - b.pulse_count);
    const pulseKeys = Object.keys(pulseCounts);
    const closestIndex = binarySearch(pulseTarget, sortedPulseValues, (val) => parseInt(val.pulse_count));
    const closestPulseCount = sortedPulseValues.length > 0 ? sortedPulseValues[closestIndex].pulse_count : 0;
    let timestampIdx = _.findIndex(pulseDatas, (pulseData) => {
        return parseInt(pulseData.pulse_count) === parseInt(closestPulseCount);
    });

    if (timestampIdx < pulseKeys.length - 2 && timestampIdx > 0) {
        const pulseCountAfter = sortedPulseValues[closestIndex + 1].pulse_count;
        // console.log("debug closestPulseCount", {closestPulseCount, sortedPulseValues, closestIndex, pulseCountBefore})
        const diffBetweenPulses = pulseCountAfter - closestPulseCount;
        const diffBetweenTarget = pulseTarget - closestPulseCount;
        const interpolationRatio = diffBetweenTarget / diffBetweenPulses;
        return {
            timestamp: parseInt(pulseKeys[timestampIdx + 1]),
            interpolationRatio,
        };
    } else {
        return {
            timestamp: pulseKeys[timestampIdx],
        };
    }
};
