import { combineReducers } from "redux";
import {
    SET_UPLOAD_TOKENS_LIST,
    UPLOAD_TOKENS_LIST_LOADING,
    SET_MEDIA_FILTER,
    SET_MEDIA_UPLOAD_LIST,
    MEDIA_UPLOADS_LIST_LOADING,
    SET_SELECTED_MEDIA_UPLOAD_ID,
    SET_SELECTED_MEDIA_UPLOAD_INDEX,
    PREVIEW_MODAL_OPEN,
} from "redux/actions";

function tokensList(state = [], action) {
    if (action.type === SET_UPLOAD_TOKENS_LIST) {
        state = action.list;
    }

    return state;
}

function tokensListLoading(state = false, action) {
    if (action.type === UPLOAD_TOKENS_LIST_LOADING) {
        state = action.state;
    }

    return state;
}

function mediaTabFilters(state = [], action) {
    if (action.type === SET_MEDIA_FILTER) {
        state = action.filters;
    }

    return state;
}

function mediaUploadsList(state = [], action) {
    if (action.type === SET_MEDIA_UPLOAD_LIST) {
        state = action.mediaUploadsList;
    }

    return state;
}

function mediaUploadsListLoading(state = false, action) {
    if (action.type === MEDIA_UPLOADS_LIST_LOADING) {
        state = action.state;
    }

    return state;
}

function selectedMediaUpload(state = -1, action) {
    if (action.type === SET_SELECTED_MEDIA_UPLOAD_ID) {
        state = action.mediaId;
    }

    return state;
}

function selectedMediaUploadIndex(state = -1, action) {
    if (action.type === SET_SELECTED_MEDIA_UPLOAD_INDEX) {
        state = action.mediaIndex;
    }

    return state;
}

function previewModalOpen(state = false, action) {
    if (action.type === PREVIEW_MODAL_OPEN) {
        state = action.modalOpen;
    }

    return state;
}

const mediaUploads = combineReducers({
    tokensList,
    tokensListLoading,
    mediaTabFilters,
    mediaUploadsList,
    mediaUploadsListLoading,
    selectedMediaUpload,
    selectedMediaUploadIndex,
    previewModalOpen,
});

export default mediaUploads;
