import _ from "lodash";
import memoize from "memoize-one";
import moment from "moment";
export function keyLookup(key, playlist) {
    return _.findIndex(playlist, (p) => p[0] === key);
}

export function keySourceLookup(key, playlists) {
    let keyIndex = -1;
    let sourceIndex = 0;

    if (playlists) {
        playlists.forEach((playlist, idx) => {
            let subidx = _.findIndex(playlist, (p) => p[0] === key);
            if (subidx > -1) {
                keyIndex = subidx;
                sourceIndex = idx;
            }
        });
    }
    return [keyIndex, sourceIndex];
}

export function videoTimeLookup(timestamp, playlist) {
    timestamp += 0.0001;
    let min = 0,
        max = playlist.length - 1;
    while (min < max) {
        let idx = Math.ceil((min + max) / 2);
        if (playlist[idx][1] <= timestamp) {
            min = idx;
        } else {
            max = idx - 1;
        }
    }
    if (min === max) {
        return min;
    } else {
        return max;
    }
}

export function absoluteTimeLookup(timestamp, playlist) {
    timestamp += 0.0001;
    let min = 0,
        max = playlist.length - 1;
    while (min < max) {
        let idx = Math.ceil((min + max) / 2);
        let time_idx = playlist[idx].length === 2 ? 1 : 3;
        if (playlist[idx][time_idx][2] <= timestamp) {
            min = idx;
        } else {
            max = idx - 1;
        }
    }
    if (min === max) {
        return min;
    } else {
        return max;
    }
}

export function d2(p1, p2) {
    if (p2 === undefined) {
        return p1[0] ** 2 + p1[1] ** 2;
    }
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return dx ** 2 + dy ** 2;
}

export function distance(p1, p2) {
    return Math.sqrt(d2(p1, p2));
}

export function videoToImageIndex(videoIndex, videoPlaylist, imagePlaylist) {
    const playlistItem = videoPlaylist[videoIndex];
    if (playlistItem) {
        let coords = playlistItem[3];
        if (imagePlaylist.length > 0) {
            return _.sortBy(
                imagePlaylist.map((data, index) => [index, distance(data[1], coords)]),
                (data) => data[1],
            )[0][0];
        }
    }
    return -1;
}

export function imageToVideoIndex(imageIndex, videoPlaylist, imagePlaylist) {
    const playlistItem = imagePlaylist[imageIndex];
    if (playlistItem) {
        let coords = playlistItem[1];
        if (videoPlaylist.length > 0) {
            const coordinateList = videoPlaylist.map((data, index) => [index, distance(data[3], coords)]);
            const sortedCoordinates = _.sortBy(coordinateList, (data) => data[1]);
            return sortedCoordinates[0][0];
        }
    }
    return -1;
}

export function getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset) {
    if (routeID && routeID.data && routeID.position) {
        const sourceIndex = routeID.position.sourceIndex;
        index = routeID.position.currentIndex;
        offset = routeID.position.currentTimeOffset || 0;
        isVideo = true;
        playlist = _.get(routeID.data, ["video", sourceIndex], []);
        routeID = routeID.data.routeID;
    }

    let absoluteTimestamp = 0;
    if (routeID && playlist && index !== -1) {
        let playlistItem = playlist[index];
        if (playlistItem) {
            if (isVideo) {
                if (playlistItem[3]) {
                    absoluteTimestamp = playlistItem[3][2] + offset;
                } else {
                    console.warn("Expected playlistItem[3] to exist:", playlistItem);
                }
            } else {
                absoluteTimestamp = playlistItem[1][2] + offset;
            }
        }
    }
    return absoluteTimestamp;
}

export function videoKeyToTimestamp(videoKey) {
    let videoKeyParts = videoKey.split(".");
    let partsToUse = videoKeyParts.filter((part) => {
        return part[0] === "t" && part.length > 5;
    });

    let timestamp = 0;
    if (partsToUse.length > 0) {
        timestamp = parseInt(partsToUse[0].substr(1), 16);
    }

    return timestamp;
}

export function arrayBufferToBase64(ab) {
    const dView = new Uint8Array(ab); //Get a byte view
    const arr = Array.prototype.slice.call(dView); //Create a normal array
    const arr1 = arr.map(function (item) {
        return String.fromCharCode(item); //Convert
    });
    return window.btoa(arr1.join("")); //Form a string
}

export function getCurrentVideoKey(playlist) {
    let sourceIndex = playlist.position.sourceIndex || 0;
    const allVideo = _.get(playlist.data, "video", []);
    if (allVideo === null || sourceIndex < 0 || sourceIndex >= allVideo.length) {
        sourceIndex = 0;
    }
    const video = _.get(allVideo, sourceIndex, []);
    let currentIndex = playlist.position.currentIndex || 0;
    if (video === null || currentIndex < 0 || currentIndex >= video.length) {
        currentIndex = 0;
    }
    return _.get(video, [currentIndex, 0]);
}

export function getRequestedVideoKey(playlist) {
    let sourceIndex = playlist.position.sourceIndex || 0;
    const allVideo = _.get(playlist.data, "video", []);
    if (allVideo === null || sourceIndex < 0 || sourceIndex >= allVideo.length) {
        sourceIndex = 0;
    }
    const video = _.get(allVideo, sourceIndex, []);
    let currentIndex = playlist.position.requestedIndex || 0;
    if (video === null || currentIndex < 0 || currentIndex >= video.length) {
        currentIndex = 0;
    }
    return _.get(video, [currentIndex, 0]);
}

export function getCurrentPlaylistTS(playlist) {
    let sourceIndex = playlist.position.sourceIndex || 0;
    const allVideo = _.get(playlist.data, "video", []);
    if (allVideo === null || sourceIndex < 0 || sourceIndex >= allVideo.length) {
        sourceIndex = 0;
    }
    const video = _.get(allVideo, sourceIndex, []);
    let currentIndex = playlist.position.currentIndex || 0;
    if (video === null || currentIndex < 0 || currentIndex >= video.length) {
        currentIndex = 0;
    }
    return _.get(video, [currentIndex, 1], 0) + playlist.position.currentTimeOffset;
}

export function calculateFrame(playlist, playlistIndex, timeOffset) {
    let count = 1;
    if (playlist && playlist[playlistIndex]) {
        const frameCount = playlist[playlistIndex][5];
        const duration = playlist[playlistIndex][2];

        if (frameCount) {
            const frameRatio = (timeOffset + 0.0001) / duration;
            if (frameRatio >= 0) {
                count = Math.min(frameCount, Math.floor(frameRatio * frameCount) + 1);
            }
        }
    }
    return count;
}

export function calculateOffset(playlist, playlistIndex, frameIndex) {
    let timeOffset = 0;
    if (playlist && playlist[playlistIndex]) {
        const frameCount = playlist[playlistIndex][5];
        const duration = playlist[playlistIndex][2];

        if (frameCount) {
            const frameRatio = (frameIndex - 1) / frameCount;
            if (frameRatio >= 0) {
                timeOffset = frameRatio * duration;
            }
        }
    }
    return timeOffset;
}

export function getCurrentFrame(playlist) {
    const sourceIndex = playlist.position.sourceIndex;
    const video = _.get(playlist.data, ["video", sourceIndex]);
    const currentIndex = playlist.position.currentIndex;
    const currentOffset = playlist.position.currentTimeOffset;
    return calculateFrame(video, currentIndex, currentOffset);
}

export function getRequestedFrame(playlist) {
    const sourceIndex = playlist.position.sourceIndex;
    const video = _.get(playlist.data, ["video", sourceIndex]);
    const currentIndex = playlist.position.requestedIndex;
    const currentOffset = playlist.position.requestedTimeOffset;
    return calculateFrame(video, currentIndex, currentOffset);
}

export const getBaseVideoStillImageURL = memoize((playlist) => {
    const snapshotBaseURL = _.get(playlist.data, ["mpdURLs", "snapshots"]);
    const frame = getCurrentFrame(playlist);
    const videoKey = getCurrentVideoKey(playlist);
    return snapshotBaseURL + videoKey + "-" + frame + ".jpg";
});

export function getUrlDataForFrame(playlist, index, subIndex, videoKey = null) {
    if (videoKey) {
        index = _.findIndex(playlist, (playlistItem) => {
            return playlistItem[0] === videoKey;
        });
    }
    let selectedKey = playlist[index];
    if(!selectedKey){
        return {}
    }
    let imageKey = selectedKey[0];
    let stillsStride = _.get(selectedKey, [6], null);

    let imageFile = imageKey;
    let range = null;

    if (stillsStride !== null && stillsStride > 0) {
        imageFile = `${imageKey}.stills.txt`;
        const frame = subIndex;
        const startRange = stillsStride * (frame - 1);
        const endRange = startRange + stillsStride - 1;
        range = {
            start: startRange,
            end: endRange,
        };
    } else if (selectedKey[5]) {
        imageFile += `-${subIndex}.jpg`;
    }

    return {
        imageFile,
        range,
    };
}

export function asyncLoadImageWithRange(imageURL, range) {
    return fetch(imageURL, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
            Range: `bytes=${range.start}-${range.end}`,
        },
    }).then((response) => {
        if (response && response.ok) {
            return response.text().then((encodedImage) => {
                const base64Flag = "data:image/jpeg;base64,";
                return base64Flag + encodedImage;
            });
        } else {
            return Promise.reject();
        }
    });
}

export function asyncLoadImageWithoutRange(imageURL) {
    return fetch(imageURL, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
    }).then((response) => {
        if (response && response.ok) {
            return response.arrayBuffer().then((buffer) => {
                const base64Flag = "data:image/jpeg;base64,";
                const imageStr = arrayBufferToBase64(buffer);
                return base64Flag + imageStr;
            });
        } else {
            console.log("Failed to get data for image URL: ", imageURL);
            return Promise.reject();
        }
    });
}

export function asyncLoadImage(imageURL, range, callback) {
    if (range) {
        return asyncLoadImageWithRange(imageURL, range)
            .then((imageData) => {
                if (callback) {
                    callback(imageData);
                }

                return imageData;
            })
            .catch((error) => {
                console.log("debug error precaching image", error);
            });
    } else {
        return asyncLoadImageWithoutRange(imageURL)
            .then((imageData) => {
                if (callback) {
                    callback(imageData);
                }
                return imageData;
            })
            .catch((error) => {
                console.log("debug error precaching image", error);
            });
    }
}

export function makePromiseCancelable(promise) {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}

export function getHeadingAdjustment(time, offsets) {
    // Ensure offsets is sorted in time order
    let headingOffset = 0;
    if (offsets && offsets.length > 0) {
        offsets.sort((a, b) => a[0] - b[0]);

        let lowerRatio;
        let upperRatio;
        let lowerOffset;
        let upperOffset;

        let offsetIndex = 0;
        while (offsetIndex < offsets.length && offsets[offsetIndex][0] <= time) {
            offsetIndex += 1;
        }

        if (offsetIndex < offsets.length) {
            upperOffset = offsets[offsetIndex][2];
            if (offsetIndex > 0) {
                lowerOffset = offsets[offsetIndex - 1][2];
                let ratioBounds = Math.min(10, offsets[offsetIndex][0] - offsets[offsetIndex - 1][0]);
                lowerRatio = 1 - Math.min(1, (time - offsets[offsetIndex - 1][0]) / ratioBounds);
                upperRatio = 1 - Math.min(1, (offsets[offsetIndex][0] - time) / ratioBounds);
            } else {
                lowerOffset = 0;
                lowerRatio = 0;
                upperRatio = 1 - Math.min(1, (offsets[offsetIndex][0] - time) / 10);
            }
        } else {
            lowerOffset = offsets[offsets.length - 1][2];
            lowerRatio = 1 - Math.min(1, (time - offsets[offsets.length - 1][0]) / 10);
            upperOffset = 0;
            upperRatio = 0;
        }

        headingOffset = upperOffset * upperRatio + lowerOffset * lowerRatio;
    }
    return headingOffset;
}

export function getOffsetAdjustedTime(time, offsets) {
    // Ensure offsets is sorted in time order
    let offset = 0;
    if (offsets && offsets.length > 0) {
        offsets.sort((a, b) => a[0] - b[0]);

        let offsetRatio;
        let lowerOffset;
        let upperOffset;

        let offsetIndex = 0;
        while (offsetIndex < offsets.length && offsets[offsetIndex][0] <= time) {
            offsetIndex += 1;
        }

        if (offsetIndex < offsets.length) {
            upperOffset = offsets[offsetIndex][1];
            if (offsetIndex > 0) {
                lowerOffset = offsets[offsetIndex - 1][1];
                offsetRatio = (time - offsets[offsetIndex - 1][0]) / (offsets[offsetIndex][0] - offsets[offsetIndex - 1][0]);
            } else {
                lowerOffset = upperOffset;
                offsetRatio = 0;
            }
        } else {
            lowerOffset = upperOffset = offsets[offsets.length - 1][1];
            offsetRatio = 0;
        }

        offset = upperOffset * offsetRatio + lowerOffset * (1 - offsetRatio);
    }
    return time + offset;
}

export function getOffsetAdjustedIndex(time, playlist, offsets) {
    // Ensure offsets is sorted in time order
    let offsetTime = getOffsetAdjustedTime(time, offsets);
    return videoTimeLookup(offsetTime, playlist);
}

export function getCoordinates(point, use_snapped) {
    if (!point) {
        return null;
    }
    // Use snapped coordinates if present and requested
    if (use_snapped && point[3] != null && point[4] != null && (point[3] !== 0 || point[4] !== 0)) {
        return [point[3], point[4]];
    } else if (point[0] != null && point[1] != null && (point[0] !== 0 || point[1] !== 0)) {
        return [point[0], point[1]];
    } else {
        return null;
    }
}

export function boundedBinarySearch(timestamp, data, min, max, extractor) {
    timestamp += 0.0001;
    while (min < max) {
        let idx = Math.ceil((min + max) / 2);
        if (extractor(data[idx]) <= timestamp) {
            min = idx;
        } else {
            max = idx - 1;
        }
    }
    if (min === max) {
        return min;
    } else {
        return max;
    }
}

export function binarySearch(timestamp, data, extractor) {
    return boundedBinarySearch(timestamp, data, 0, data.length - 1, extractor);
}

export function getVideolessLocation(time, locationData) {
    if (time > 9999999999) {
        time /= 1000;
    }
    const dataIndex = binarySearch(time, locationData, (dp) => dp.start[0]);
    const startPoint = locationData[dataIndex] ? _.get(locationData[dataIndex], "start", null) : null;
    const endPoint = locationData[dataIndex] ? _.get(locationData[dataIndex], "end", null) : null;

    if (startPoint && endPoint) {
        const duration = endPoint[0] - startPoint[0];
        const ratio = Math.min((time - startPoint[0]) / duration, 1);

        const startCoordinate = [startPoint[1], startPoint[2]];
        const endCoordinate = [endPoint[1], endPoint[2]];
        const interpolatedPosition = interpolate(startCoordinate, endCoordinate, ratio);
        return interpolatedPosition;
    }
}

export function getPositionAndOffset(time, playlist, use_snapped) {
    let playlistIndex;
    if (time < 100000) {
        playlistIndex = videoTimeLookup(time, playlist);
    } else {
        playlistIndex = absoluteTimeLookup(time, playlist);
    }

    let playlistItem = playlist[playlistIndex];
    let startCoordinates = playlistItem[3];
    let endCoordinates = playlistItem[4];
    if (!endCoordinates && playlistIndex < playlist.length - 1) {
        endCoordinates = playlist[playlistIndex + 1][3];
    }

    startCoordinates = getCoordinates(startCoordinates, use_snapped);
    endCoordinates = getCoordinates(endCoordinates, use_snapped);

    let timeOffset;
    if (time < 100000) {
        timeOffset = time - playlistItem[1];
    } else {
        timeOffset = time - playlistItem[3][2];
    }

    let duration = playlistItem[2];
    return { playlistIndex, startCoordinates, endCoordinates, timeOffset, duration };
}

export function getPositionAndOffsetWorldTimestamp(time, playlist, use_snapped) {
    let playlistIndex = absoluteTimeLookup(time, playlist);
    let playlistItem = playlist[playlistIndex];
    let startCoordinates = playlistItem[3];
    let endCoordinates = playlistItem[4];
    if (!endCoordinates && playlistIndex < playlist.length - 1) {
        endCoordinates = playlist[playlistIndex + 1][3];
    }

    startCoordinates = getCoordinates(startCoordinates, use_snapped);
    endCoordinates = getCoordinates(endCoordinates, use_snapped);
    let timeOffset = time - playlistItem[1];
    let duration = playlistItem[2];
    return { playlistIndex, startCoordinates, endCoordinates, timeOffset, duration };
}

export function getInterpolatedPosition(time, playlist, use_snapped) {
    let { startCoordinates, endCoordinates, timeOffset, duration } = getPositionAndOffset(time, playlist, use_snapped);
    let interpolatedPosition;
    if (startCoordinates && endCoordinates) {
        let ratio = 0;
        if (duration > 0) {
            ratio = timeOffset / duration;
        }

        interpolatedPosition = interpolate(startCoordinates, endCoordinates, ratio);
    } else {
        if (startCoordinates) {
            interpolatedPosition = startCoordinates;
        } else {
            interpolatedPosition = endCoordinates;
        }
    }
    return interpolatedPosition;
}

export function interpolate(start, end, ratio) {
    if (!start || !end) {
        return;
    }

    return [start[0] * (1 - ratio) + end[0] * ratio, start[1] * (1 - ratio) + end[1] * ratio];
}

export function getOffsetAdjustedPosition(time, playlist, offsets, use_snapped) {
    if (time > 1000000) {
        let absIndex = absoluteTimeLookup(time, playlist);
        if (absIndex !== -1) {
            const oldOffset = playlist[absIndex][3][2];
            const newOffset = playlist[absIndex][1];
            time = time - oldOffset + newOffset;
        }
    }
    let originalPlaylistIndex = videoTimeLookup(time, playlist);

    if (originalPlaylistIndex !== -1) {
        let originalPlaylistItem = playlist[originalPlaylistIndex];
        let originalTimeOffset = time - originalPlaylistItem[1];

        let offsetTime = getOffsetAdjustedTime(time, offsets);
        let interpolatedPosition = getInterpolatedPosition(offsetTime, playlist, use_snapped);

        return [originalPlaylistIndex, originalTimeOffset, interpolatedPosition];
    } else {
        return null;
    }
}

export function getOffsetAdjustedPositionAndOffset(time, playlist, offsets, use_snapped) {
    let offsetTime = getOffsetAdjustedTime(time, offsets);
    return getPositionAndOffset(offsetTime, playlist, use_snapped);
}

export function reverseOffsetAdjustment(time, offsets) {
    // Ensure offsets is sorted in time order
    let offset = 0;
    if (offsets && offsets.length > 0) {
        offsets.sort((a, b) => a[0] - b[0]);

        let offsetRatio;
        let lowerOffset;
        let upperOffset;

        let offsetIndex = 0;
        while (offsetIndex < offsets.length && offsets[offsetIndex][0] <= time - offsets[offsetIndex][1]) {
            offsetIndex += 1;
        }

        if (offsetIndex < offsets.length) {
            upperOffset = offsets[offsetIndex][1];

            if (offsetIndex > 0) {
                let lowerOffsetOriginalTime = offsets[offsetIndex - 1][0] - offsets[offsetIndex - 1][1];
                let upperOffsetOriginalTime = offsets[offsetIndex][0] - offsets[offsetIndex][1];

                lowerOffset = offsets[offsetIndex - 1][1];
                offsetRatio = (time - lowerOffsetOriginalTime) / (upperOffsetOriginalTime - lowerOffsetOriginalTime);
            } else {
                lowerOffset = upperOffset;
                offsetRatio = 0;
            }
        } else {
            lowerOffset = upperOffset = offsets[offsets.length - 1][1];
            offsetRatio = 0;
        }

        offset = upperOffset * offsetRatio + lowerOffset * (1 - offsetRatio);
    }

    return time - offset;
}

export function source_is_calibrated(calibrations, sourceIndex) {
    if (!_.isNumber(sourceIndex) || sourceIndex < 0) {
        return false;
    }
    if (_.isArray(calibrations) && calibrations.length > 0) {
        return _.findIndex(calibrations, (c) => c.source === sourceIndex) !== -1;
    } else {
        return false;
    }
}

function interp(a, b, r) {
    if (!_.isNil(a) && !_.isNil(b)) {
        return a * (1 - r) + b * r;
    } else if (!_.isNil(a)) {
        return a;
    } else {
        return b;
    }
}

export function getStreamFOVs(playlist, routeMetadata, session, sourceIndex) {
    const fovs = _.get(routeMetadata, ["VIDEO_FOV", playlist.data.routeID], []);
    let fovsForIndex = _.filter(fovs, (fov) => _.get(fov.data, ["source"], 0) === sourceIndex);

    const mediaSources = _.get(session, "mediaSources", false);

    if (_.get(mediaSources, [sourceIndex, "override_session_fov_metadata"], false) == true) {
        fovsForIndex = [
            {
                data: {
                    x: _.get(mediaSources[sourceIndex], "horizontalFOV"),
                    y: _.get(mediaSources[sourceIndex], "verticalFOV"),
                    source: sourceIndex,
                },
            },
        ];
    }

    return fovsForIndex;
}

export function get_source_calibration(calibrations, sourceIndex, timestamp, use_per_segment, default_rail_separation) {
    if (!_.isNumber(sourceIndex) || sourceIndex < 0) {
        sourceIndex = 0;
    }
    let result;
    if (_.isArray(calibrations) && calibrations.length > 0) {
        let results;
        if (use_per_segment) {
            let results = _.filter(calibrations, (c) => c.source === sourceIndex && c.timestamp !== 0);
            if (results.length === 0) {
                results = _.filter(calibrations, (c) => c.source === sourceIndex && c.timestamp === 0);
                if (results.length > 0) {
                    result = results[0];
                }
            } else {
                let lowerCalibration = _.reduce(
                    results,
                    (best, c) => (c.timestamp < timestamp && (best === null || c.timestamp > best.timestamp) ? c : best),
                    null,
                );
                let upperCalibration = _.reduce(
                    results,
                    (best, c) => (c.timestamp >= timestamp && (best === null || c.timestamp < best.timestamp) ? c : best),
                    null,
                );

                if (upperCalibration !== null && lowerCalibration !== null) {
                    const r = (timestamp - lowerCalibration.timestamp) / (upperCalibration.timestamp - lowerCalibration.timestamp);
                    result = {
                        timestamp,
                        railSeparation: interp(lowerCalibration.railSeparation, upperCalibration.railSeparation, r),
                        horizontalFov: interp(lowerCalibration.horizontalFov, upperCalibration.horizontalFov, r),
                        aspectRatio: interp(lowerCalibration.aspectRatio, upperCalibration.aspectRatio, r),
                        rotationMatrix: [
                            [
                                interp(lowerCalibration.rotationMatrix[0][0], upperCalibration.rotationMatrix[0][0], r),
                                interp(lowerCalibration.rotationMatrix[0][1], upperCalibration.rotationMatrix[0][1], r),
                                interp(lowerCalibration.rotationMatrix[0][2], upperCalibration.rotationMatrix[0][2], r),
                            ],
                            [
                                interp(lowerCalibration.rotationMatrix[1][0], upperCalibration.rotationMatrix[1][0], r),
                                interp(lowerCalibration.rotationMatrix[1][1], upperCalibration.rotationMatrix[1][1], r),
                                interp(lowerCalibration.rotationMatrix[1][2], upperCalibration.rotationMatrix[1][2], r),
                            ],
                            [
                                interp(lowerCalibration.rotationMatrix[2][0], upperCalibration.rotationMatrix[2][0], r),
                                interp(lowerCalibration.rotationMatrix[2][1], upperCalibration.rotationMatrix[2][1], r),
                                interp(lowerCalibration.rotationMatrix[2][2], upperCalibration.rotationMatrix[2][2], r),
                            ],
                        ],
                        translationVector: [
                            interp(lowerCalibration.translationVector[0], upperCalibration.translationVector[0], r),
                            interp(lowerCalibration.translationVector[1], upperCalibration.translationVector[1], r),
                            interp(lowerCalibration.translationVector[2], upperCalibration.translationVector[2], r),
                        ],
                    };
                } else if (lowerCalibration !== null) {
                    result = lowerCalibration;
                } else if (upperCalibration !== null) {
                    result = upperCalibration;
                }
            }
        } else {
            results = _.filter(calibrations, (c) => c.source === sourceIndex && c.timestamp === 0);
            if (results.length > 0) {
                result = results[0];
            }
        }
    }
    if (result !== undefined) {
        return {
            horizontalFov: 66.5,
            aspectRatio: 0.5625,
            fovIsKnown: !_.isNil(result.horizontalFov),
            aspectIsKnown: !_.isNil(result.aspectRatio),
            ...result,
        };
    } else {
        return {
            timestamp: 0,
            railSeparation: default_rail_separation,
            horizontalFov: 66.5,
            aspectRatio: 0.5625,
            fovIsKnown: false,
            aspectIsKnown: false,
            rotationMatrix: [
                [1, 0, 0],
                [0, 1, 0],
                [0, 0, 1],
            ],
            translationVector: [0, 2, 0],
        };
    }
}

export function calculateNext(videoKey, currentIndex, video, currentOffset) {
    if (videoKey === null) {
        return null;
    }
    let nextKeyIndex = currentIndex;
    if (nextKeyIndex === null || nextKeyIndex === undefined || nextKeyIndex < 0 || nextKeyIndex >= video.length) {
        return null;
    }
    const frameCount = video[nextKeyIndex][5];

    let nextFrame = calculateFrame(video, nextKeyIndex, currentOffset) + 1;

    if (!frameCount || nextFrame > frameCount) {
        nextKeyIndex += 1;
        nextFrame = 1;
    }
    if (video[nextKeyIndex] == null) {
        return null;
    }
    const offset = calculateOffset(video, nextKeyIndex, nextFrame);
    return [nextKeyIndex, offset];
}

export function calculatePrevious(videoKey, currentIndex, video, currentOffset) {
    if (videoKey === null) {
        return null;
    }
    let previousKeyIndex = currentIndex;
    if (previousKeyIndex === null || previousKeyIndex === undefined || previousKeyIndex < 0 || previousKeyIndex >= video.length) {
        return null;
    }
    let previousFrame = calculateFrame(video, previousKeyIndex, currentOffset) - 1;

    if (previousFrame < 1) {
        previousKeyIndex -= 1;
        if (video[previousKeyIndex] == null) {
            return null;
        }
        const frameCount = video[previousKeyIndex][5];
        if (frameCount) {
            previousFrame = frameCount;
        } else {
            previousFrame = 1;
        }
    }
    const offset = calculateOffset(video, previousKeyIndex, previousFrame);
    return [previousKeyIndex, offset];
}

export function previousContent(content, videoKey, currentIndex, video, offset, observationNumber = -1) {
    if (videoKey == null) {
        return null;
    }
    const playlistIndexesWithContent = findPlaylistIndexesWithContent(content, video);
    let last;
    if (observationNumber >= 0) {
        let currentPosition = _.findIndex(playlistIndexesWithContent, ([_idx, __frame, content]) => content.observation_number === observationNumber);
        last = playlistIndexesWithContent[currentPosition - 1];
    } else {
        let currentFrame = calculateFrame(video, currentIndex, offset);
        last = _.findLast(playlistIndexesWithContent, ([idx, frame, _content]) => idx < currentIndex || (idx === currentIndex && frame < currentFrame));
    }

    if (last === undefined) {
        return null;
    }

    const [prevPlaylistIndex, prevFrame, _prevContent] = last;
    const prevOffset = calculateOffset(video, prevPlaylistIndex, prevFrame);
    return [prevPlaylistIndex, prevOffset, _prevContent];
}

export function nextContent(content, videoKey, currentIndex, video, offset, observationNumber = -1) {
    if (videoKey == null) {
        return null;
    }

    const playlistIndexesWithContent = findPlaylistIndexesWithContent(content, video);
    let first;
    if (observationNumber >= 0) {
        let currentPosition = _.findIndex(playlistIndexesWithContent, ([_idx, __frame, content]) => content.observation_number === observationNumber);
        first = playlistIndexesWithContent[currentPosition + 1];
    } else {
        let currentFrame = calculateFrame(video, currentIndex, offset);
        first = _.find(playlistIndexesWithContent, ([idx, frame, _content]) => idx > currentIndex || (idx === currentIndex && frame > currentFrame));
    }

    if (first === undefined) {
        return null;
    }

    const [nextPlaylistIndex, nextFrame, _nextContent] = first;
    const nextOffset = calculateOffset(video, nextPlaylistIndex, nextFrame);
    return [nextPlaylistIndex, nextOffset, _nextContent];
}

export function findNextExtent(content, video, currentMarker) {
    const playlistIndexesWithContent = findPlaylistIndexesWithContent(content, video);
    const currentIndex = _.findIndex(playlistIndexesWithContent, ([_idx, __frame, content]) => content.id === currentMarker.id);
    if (currentIndex + 1 < playlistIndexesWithContent.length) {
        return playlistIndexesWithContent[currentIndex + 1];
    } else {
        return null;
    }
}

export function findPreviousExtent(content, video, currentMarker) {
    const playlistIndexesWithContent = findPlaylistIndexesWithContent(content, video);
    const currentIndex = _.findIndex(playlistIndexesWithContent, ([_idx, __frame, content]) => content.id === currentMarker.id);
    if (currentIndex - 1 >= 0) {
        return playlistIndexesWithContent[currentIndex - 1];
    } else {
        return null;
    }
}

export function jumpToContent(content, video, positionNumber) {
    const playlistIndexesWithContent = findPlaylistIndexesWithContent(content, video);
    return playlistIndexesWithContent[positionNumber - 1] || null;
}

function findPlaylistIndexesWithContent(content, video) {
    let indexes = content.map((content) => {
        let videoKey = content.videoKey;
        if (!videoKey) {
            videoKey = content.video_key;
        }
        if (_.has(content, "first_video_key")) {
            videoKey = content.first_video_key;
        }
        const firstObservationFrame = _.has(content, "first_observation_frame") ? content.first_observation_frame : content.frame;
        return [keyLookup(videoKey, video), firstObservationFrame, content];
    });

    if (indexes && indexes.length) {
        indexes = _.sortBy(
            _.filter(indexes, ([idx, _frame, _content]) => idx > -1),
            ([idx, _frame, _content]) => idx,
            ([_idx, frame, _content]) => frame,
            ([_idx, _frame, content]) => content.observation_number,
        );
    }
    return indexes;
}

export function filterMarkers(
    markers,
    selectedTagCategory,
    reviewFilters,
    annotationTypes,
    thresholdFilters,
    isDetection,
    defaultScores,
    markerConditionFilter,
    detectionConditionFilters,
    dipAngleFilter,
) {
    const filtered = markers.filter((marker) => {
        if (isDetection) {
            if (detectionConditionFilters && detectionConditionFilters[marker.name]) {
                let filter = false;
                _.forEach(Object.keys(detectionConditionFilters[marker.name]), (condition) => {
                    let markerCondition = _.get(marker, ["conditions", condition, "user"], null);
                    if (!markerCondition) {
                        markerCondition = _.get(marker, ["conditions", condition, "auto"], null);
                    }

                    if (!markerCondition && marker.name === "Joint" && condition === "dip_angle") {
                        if (marker.analysis.dip_angle_valid && marker.analysis.dip_angle_mrad > 0) {
                            markerCondition = "dip_angle_present";
                        }

                        if (marker.analysis.dip_angle_valid && marker.analysis.dip_angle_mrad === 0) {
                            markerCondition = "no_dip_angle";
                        }
                    }

                    if (!markerCondition) {
                        markerCondition = "none";
                    }
                    if (!detectionConditionFilters[marker.name][condition].includes(markerCondition)) {
                        filter = true;
                    }
                });

                if (filter) {
                    return false;
                }
            }

            if (marker.name === "Joint" && dipAngleFilter && dipAngleFilter.length) {
                let dipAngle = _.get(marker, ["analysis", "dip_angle_mrad"], 0);
                if (!_.get(marker, ["analysis", "dip_angle_valid"], false)) {
                    dipAngle = 0;
                }
                if (dipAngle < dipAngleFilter[0] || dipAngle > dipAngleFilter[1]) {
                    return false;
                }
            }

            if (selectedTagCategory && !selectedTagCategory.includes(marker.name)) {
                return false;
            }
        } else {
            if (selectedTagCategory && marker.name !== selectedTagCategory) {
                return false;
            }
            let annotationType = _.find(annotationTypes, function (ann) {
                return ann.type === marker.name;
            });
            if (annotationType && annotationType.display_type === "never") {
                return false;
            }

            if (annotationType && annotationType.display_type === "type_selected" && !selectedTagCategory) {
                return false;
            }

            if (annotationType) {
                const defValue = _.get(defaultScores, [annotationType.type], annotationType.default_score);
                if (!_.isNil(thresholdFilters[annotationType.type])) {
                    if (marker.score && marker.score < thresholdFilters[annotationType.type]) {
                        return false;
                    }
                } else if (defValue) {
                    if (marker.score && marker.score < defValue) {
                        return false;
                    }
                }
            }

            if (markerConditionFilter) {
                if (markerConditionFilter[marker.name]) {
                    if (!markerConditionFilter[marker.name].includes(marker.condition_id)) {
                        return false;
                    }
                }
            }
        }

        if (reviewFilters.length && !reviewFilters.includes(marker.review_status)) {
            return false;
        }

        return true;
    });

    return filtered;
}

export function getClosestImageKey(imageKeys, timestamp) {
    // Find the closest image key index by time
    let imageKeyIndex = _.findLastIndex(imageKeys, (i) => {
        return videoKeyToTimestamp(i[0]) <= timestamp;
    });

    if (imageKeyIndex < 0) {
        return [null, null];
    }
    let timeOffset = (timestamp - videoKeyToTimestamp(imageKeys[imageKeyIndex][0])) / 1000;
    return [imageKeyIndex, timeOffset];
}

export function getClosestImage(keyTimestamp, array) {
    if (array && array.length > 1) {
        return array.reduce((a, b) => {
            let aDiff = Math.abs(a["timestamp"] - keyTimestamp);
            let bDiff = Math.abs(b["timestamp"] - keyTimestamp);

            if (aDiff === bDiff) {
                return a["timestamp"] < b["timestamp"] ? a : b;
                //returns the smaller number if two frames are equal distances apart from keyTimestamp
            } else {
                return bDiff < aDiff ? b : a;
            }
        });
    } else if (array && array.length === 1) {
        return array[0];
    } else {
        return null;
    }
}

export function calculateRoutePercentageCovered(schemaPlanLines, progressMap, totalPatrolChainage) {
    let totalCoverage = 0;
    schemaPlanLines.forEach((line) => {
        if (!line.patrol_chain_start) {
            return 0;
        }

        const lineCoverage = _.get(progressMap, [line.ELR, line.TRID], []);

        const startPatrolChain = Math.min(line.patrol_chain_start, line.patrol_chain_end);
        const endPatrolChain = Math.max(line.patrol_chain_start, line.patrol_chain_end);

        lineCoverage.forEach((coverage) => {
            if (coverage.end < startPatrolChain || coverage.start > endPatrolChain) {
                return;
            }

            let distance = 0;
            if (coverage.start >= startPatrolChain && coverage.end <= endPatrolChain) {
                distance = coverage.end - coverage.start;
            } else if (coverage.start < startPatrolChain && coverage.end <= endPatrolChain && coverage.end > startPatrolChain) {
                distance = coverage.end - startPatrolChain;
            } else if (coverage.start < endPatrolChain && coverage.start >= startPatrolChain && coverage.end > endPatrolChain) {
                distance = endPatrolChain - coverage.start;
            } else if (coverage.start < startPatrolChain && coverage.end > endPatrolChain) {
                distance = endPatrolChain - startPatrolChain;
            }
            totalCoverage += distance;
        });
    });
    if (totalCoverage === 0 && totalPatrolChainage === 0) {
        return 0;
    } else {
        const percent = (totalCoverage / totalPatrolChainage) * 100;
        return Math.round(percent * 10) / 10;
    }
}

export function calculateTotalPatrolChainage(schemaPlanLines) {
    let totalChains = 0;

    schemaPlanLines.forEach((line) => {
        if (line.patrol_chain_start) {
            const lineDistanceChain = Math.abs(parseFloat(line["patrol_chain_start"]) - parseFloat(line["patrol_chain_end"]));
            if (lineDistanceChain) {
                totalChains += lineDistanceChain;
            }
        }
    });
    return totalChains;
}

export const findMostRecentMetadata = memoize((metadata, timestamp) => {
    if (metadata) {
        return _.findLast(metadata, (data) => data.timestamp <= timestamp);
    } else {
        return null;
    }
});

export const isPatrolSessionBackwards = (sessionStartTimestamp, patrolDirections) => {
    let nearestDirectionKey = null;
    const directionKeys = Object.keys(patrolDirections);
    const sortedDirectionKeys = directionKeys.map((ts) => parseInt(ts));
    sortedDirectionKeys.sort(function (a, b) {
        return a - b;
    });
    sortedDirectionKeys.forEach((directionTimestamp) => {
        if (sessionStartTimestamp >= directionTimestamp) {
            nearestDirectionKey = directionTimestamp;
        }
    });
    if (nearestDirectionKey) {
        return patrolDirections[nearestDirectionKey].direction === "Backward";
    }
    return false;
};

export const calculateOffsettedPatrolImage = (markup, railImageConfig, patrolDirections, railImages) => {
    let offsetParam;
    const backwards = isPatrolSessionBackwards(markup.image_timestamp / 1000, patrolDirections);
    if (!backwards) {
        offsetParam = "offset_y_backward";
    } else {
        offsetParam = "offset_y_forward";
    }

    let sourceKey = "image_source";
    if (!_.isNil(markup.source)) {
        sourceKey = "source";
    }

    const markupOffset = _.get(railImageConfig, ["inspection_images", markup[sourceKey], offsetParam], 0);
    const markupImageIndex = _.findIndex(railImages, (img) => img.timestamp.toString() === markup.image_timestamp.toString());

    if (markupImageIndex < 0) {
        return null;
    }
    const offsettedIndex = markupImageIndex - Math.round(markupOffset);

    const indexToUse = Math.min(Math.max(offsettedIndex, 0), railImages.length);
    const markupImage = railImages[indexToUse];

    if (!markupImage) {
        return null;
    }

    return markupImage;
};

export const groupAreasOfInterest = (sessionMetadata, annotationTypes) => {
    const sortedMetadata = _.orderBy(sessionMetadata, "timestamp");

    const areas = [];
    let currentArea = {};

    sortedMetadata.forEach((metadata) => {
        const annotationType = _.find(annotationTypes, { type: metadata.data.description });
        if (!annotationType || annotationType.display_type === "never") {
            return;
        }

        if (metadata.data.entered) {
            currentArea.start = metadata.timestamp;
        } else {
            currentArea.end = metadata.timestamp;

            currentArea.type = metadata.data.description;
            areas.push(currentArea);
            currentArea = {};
        }
    });

    return areas;
};

export const filterMedia = (dateFilter, owner, searchQuery, sortBy, isAdmin, status, mediaList, filterTags) => {
    if (!mediaList) {
        return [];
    }

    let awaitingReview = 0;

    let newList = _.filter(mediaList, (upload) => {
        if (upload.status === 0 && isAdmin) {
            awaitingReview += 1;
        }

        if (dateFilter.from && dateFilter.from && !moment(upload.upload_ts).isBetween(dateFilter.from, dateFilter.to)) {
            return false;
        }

        if (owner === "mine" && upload.is_owner === 0) {
            return false;
        }

        if (owner === "others" && upload.is_owner === 1) {
            return false;
        }

        if (searchQuery && searchQuery.length > 0) {
            if (
                upload.description.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1 &&
                upload.name.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1 &&
                upload.token_description.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1
            ) {
                return false;
            }
        }

        if ((!isAdmin && upload.status === 0) || owner === 0) {
            return false;
        }

        if (status !== upload.status) {
            return false;
        }

        let count = 0;
        _.forIn(filterTags, (value, key) => {
            if (value.length !== 0) {
                return !upload.tags ? count++ : (count += _.difference(value, upload.tags[key]).length);
            }
        });
        if (count !== 0) {
            return false;
        }

        return true;
    });

    switch (sortBy) {
        case "last_uploaded":
            newList = _.sortBy(newList, ["upload_ts"]).reverse();
            break;

        case "descr_az":
            newList = _.sortBy(newList, ["description"]);
            break;

        case "descr_za":
            newList = _.sortBy(newList, ["description"]).reverse();
            break;

        case "name_az":
            newList = _.sortBy(newList, ["name"]);
            break;

        case "name_za":
            newList = _.sortBy(newList, ["name"]).reverse();
            break;

        case "token_name_az":
            newList = _.sortBy(newList, ["token_description"]);
            break;

        case "token_name_za":
            newList = _.sortBy(newList, ["token_description"]).reverse();
            break;

        case "media_count_high":
            newList = _.sortBy(newList, (media) => media.media_urls.length).reverse();
            break;

        case "media_count_low":
            newList = _.sortBy(newList, (media) => media.media_urls.length);
            break;

        default:
            newList = _.sortBy(newList, ["upload_ts"]).reverse();
            break;
    }
    return { list: newList, awaitingReview: awaitingReview };
};

export const statusRanker = (issue) => {
    if (issue.state === 0) {
        return 0;
    } else if (issue.state === 1) {
        if (issue.state_change_ts && issue.state_change_ts * 1000 < issue.match_ts) {
            return 1;
        } else {
            return 2;
        }
    } else if (issue.state === 2) {
        return 3;
    } else {
        return 4;
    }
};

export const priorityRanker = (issue) => {
    if (issue.priority) {
        return issue.priority;
    } else if (!issue.priority) {
        return 0;
    }
};

export const issueTimeRanker = (issue) => {
    return _.get(issue, ["summary_data", "last_observed"], 0);
};

export const issueMatchCountRanker = (issue) => {
    return _.get(issue, ["summary_data", "match_count"], 0);
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getStreamFromVideoKey = (videoKey) => {
    const splits = videoKey.split(".");

    if (splits.length) {
        const sourceSplit = _.find(splits, (split) => split.startsWith("s"));
        if (sourceSplit) {
            return sourceSplit.slice(-1);
        }
    }
    return null;
};

export const filterMarkersByObservations = memoize((markers, observations) => {
    return _.filter(markers, (marker) => !_.find(observations, { class: marker.name }));
});

export const filterSessionObservations = (observations, observationFilters) => {
    const filteredObservations = observations.filter((observation) => {
        if (observation.class === "Troughing") {
            let troughingCondition = _.get(observation, "classifications.troughing_condition.user", null);
            if (!troughingCondition) {
                troughingCondition = _.get(observation, "classifications.troughing_condition.auto", null);
            }
            let troughingType = _.get(observation, "classifications.troughing_type.user", null);
            if (!troughingType) {
                troughingType = _.get(observation, "classifications.troughing_type.auto", null);
            }
            if (observationFilters.condition.length) {
                if (!observationFilters.condition.includes(troughingCondition)) {
                    return false;
                }
            }
            if (observationFilters.troughingType.length) {
                if (!observationFilters.troughingType.includes(troughingType)) {
                    return false;
                }
            }
        }

        if (observation.class === "Low Ballast" || observation.class === "low_ballast") {
            const lowBallastFilter = _.get(observationFilters, "lowBallastConfidenceFilter", [0, 1]);
            if (observation.density < lowBallastFilter[0] || observation.density > lowBallastFilter[1]) {
                return false;
            }
        }

        return true;
    });

    return observations.filter((observation) =>
        _.find(filteredObservations, (obs) => obs.observation_number === observation.observation_number && obs.class === observation.class),
    );
};

export const combineContinuousObservations = (observations) => {
    const combinedObservations = [];

    observations.forEach((obs) => {
        if (obs.continuous) {
            let observation = _.find(combinedObservations, { observation_number: obs.observation_number, class: obs.class });
            if (observation) {
                observation.extents = [...observation.extents, obs];
                observation.review_status = [...observation.review_status, obs.review_status];
            } else {
                obs.extents = [];
                combinedObservations.push({
                    ...obs,
                    class: obs.class,
                    observation_number: obs.observation_number,
                    extents: [obs],
                    review_status: [obs.review_status],
                    video_key: obs.video_key,
                    continuous: true,
                    frames: obs.frames,
                });
            }
        } else {
            combinedObservations.push(obs);
        }
    });

    // Only set review status of a continuous observation to rejected if all extents inside have been rejected. Individual extents will be hidden but overall observation should still display
    return combinedObservations.map((item) => {
        if (item.continuous) {
            if (item.review_status.includes(0)) {
                item.review_status = 0;
            } else if (item.review_status.includes(1)) {
                item.review_status = 1;
            } else if (item.review_status.includes(2)) {
                item.review_status = 2;
            } else {
                item.review_status = 3;
            }
        }
        return item;
    });
};

export const getReviewLabel = (review) => {
    if (review === 3) {
        return "Reject";
    } else if (review === 2) {
        return "Verify & Hide";
    } else {
        return "Verify";
    }
};

export const findClosestMarker = (mks, videoKey, video, currentIndex, offset, previousMarker = {}) => {
    if (!videoKey) {
        return null;
    }

    const currentTS = videoKeyToTimestamp(videoKey);
    const currentFrame = calculateFrame(video, currentIndex, offset);

    const formatMarkers = _.sortBy(
        _.map(mks, (m) => {
            let markerTS = videoKeyToTimestamp(m.video_key);
            return { ...m, distance: markerTS - currentTS };
        }),
        (marker) => Math.abs(marker.distance),
    );
    const minDistance = Math.abs(_.get(formatMarkers[0], "distance"));
    let filteredMarkers = _.filter(formatMarkers, (marker) => Math.abs(marker.distance) === minDistance);

    if (filteredMarkers.length === 1) {
        return filteredMarkers[0];
    } else {
        if (minDistance === 0) {
            if (!_.isEmpty(previousMarker)) {
                const newFilteredMarkers = filteredMarkers.filter((item) => item.id === previousMarker.id);
                if (newFilteredMarkers.length) {
                    filteredMarkers = newFilteredMarkers;
                }
            }
            return _.minBy(filteredMarkers, (item) => Math.abs(item.frame - currentFrame));
        } else {
            const currentFrameLength = video[currentIndex][5];
            return _.minBy(filteredMarkers, (marker) => {
                const markerFrameLength = _.find(video, (videoKey) => videoKey[0] === marker.video_key)[5];
                if (marker.distance > 0) {
                    return currentFrameLength - currentFrame + marker.frame;
                } else {
                    return currentFrame + (markerFrameLength - marker.frame);
                }
            });
        }
    }
};

export const findEnvironmentalData = (data, routePos) => {
    if (!routePos || typeof routePos !== "object" || !routePos.hasOwnProperty("elr") || _.isNil(routePos.position)) {
        return [];
    }

    const elrData = _.get(data, routePos.elr, {});
    const routePositions = Object.keys(elrData);
    const nearestKeyIndex = binarySearch(routePos.position, routePositions, (val) => parseFloat(val));
    const nearestKey = routePositions[nearestKeyIndex];
    const nearestData = elrData[nearestKey];

    if (nearestData && nearestData.start_location < routePos.position && nearestData.end_location > routePos.position) {
        return nearestData.datas;
    }

    return [];
};

export const processTroughingBbox = (bbox, selectedMarker) => {
    if (selectedMarker && selectedMarker.class === "Troughing") {
        const targetCropSize = 384;
        const frameRegion = _.get(selectedMarker, "frame_region", "");
        if (!frameRegion || !bbox.length) {
            return bbox;
        }

        const bboxWidth = bbox[2];
        const bboxHeight = bbox[3];
        const cropSizeWidth = _.min([bboxWidth, targetCropSize]);
        const cropSizeHeight = _.min([bboxHeight, targetCropSize]);

        if (frameRegion === "left") {
            return [bbox[0], _.max([bbox[1] + bbox[3] - cropSizeHeight - 1, 0]), cropSizeWidth, cropSizeHeight];
        } else {
            return [bbox[0] + bbox[2] - cropSizeWidth - 1, bbox[1] + bbox[3] - cropSizeHeight - 1, cropSizeWidth, cropSizeHeight];
        }
    } else {
        return bbox;
    }
};
