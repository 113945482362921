import React, { useMemo } from "react";
import Post from "./Post";
import SignalHead from "./Signal_head";

function Signal(props) {
    const options = useMemo(() => {
        return props.options || {};
    }, [props.options]);

    const yOffset = useMemo(() => {
        if (options.height) {
            return options.height / 1000;
        } else {
            return 1.5;
        }
    }, [options]);

    return (
        <group>
            <Post
                height={options.height}
                selected={props.selected}
                highlighted={props.highlighted}
            />
            <SignalHead
                position={[0, yOffset, 0.1]}
                lit={options.lit}
                selected={props.selected}
                highlighted={props.highlighted}
                sunBrightness={props.sunBrightness}
            />
        </group>
    );
}

export const Options = [
    {
        title: "Lighting",
        value: "lighting",
        selectable: false,
        checkable: false,
        children: [
            {
                title: "Unlit",
                value: JSON.stringify({ lit: null }),
            },
            {
                title: "Lit (red)",
                value: JSON.stringify({ lit: "red" }),
            },
            {
                title: "Lit (green)",
                value: JSON.stringify({ lit: "green" }),
            },
        ],
    },
    {
        title: "Height",
        value: "height",
        selectable: false,
        checkable: false,
        children: [
            {
                title: "1.5m",
                value: JSON.stringify({ height: 1500 }),
            },
            {
                title: "2.5m",
                value: JSON.stringify({ height: 2500 }),
            },
            {
                title: "3.3m",
                value: JSON.stringify({ height: 3300 }),
            },
            {
                title: "4.0m",
                value: JSON.stringify({ height: 4000 }),
            },
            {
                title: "5.1m",
                value: JSON.stringify({ height: 5100 }),
            },
        ],
    },
];

export default Signal;
