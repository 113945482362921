import { FastBackwardFilled, FastForwardFilled, StepBackwardFilled, StepForwardFilled } from "@ant-design/icons";
import { Checkbox, InputNumber, Slider, Tooltip, Drawer, Button, notification } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { calculateRouteCoordinatesForLocation, calculateElrGroups } from "../../util/Geometry";
import { flipInspectionRails, getSessionContinuousObservations, setAutoScrollActive, toggleRailInspectionTimelineWidget } from "../../../redux/actions/index";
import { convertToTimezone } from "../../util/TimezoneUtils";
import SessionTimeline from "components/session/SessionTimeline";

const userConfigSelector = (state) => state.userDetails.userConfig;
const naiveIndexSelector = (state) => state.playlist.position.currentIndex;
const currentOffsetSelector = (state) => state.playlist.position.currentTimeOffset;
const routeLocationSelector = (state) => state.playlist.data.route_locations;
const elrUnitsSelector = (state) => state.userDetails.userConfig.elr_units;
const dashboardsSelector = (state) => state.dashboards;
const viewsSelector = (state) => state.views;
const userDetailsSelector = (state) => state.userDetails;
const railDataObjectSelector = (state) => state.railInspection.railInspectionImages.data;
const railDataLoadedSelector = (state) => state.railInspection.railInspectionImages.loadingInfo.loaded;
const selectedRailImageSelector = (state) => state.railInspection.selectedRailInspectionImage;
const railsFlippedSelector = (state) => state.railInspection.flipRails;
const routeSystemIDSelector = (state) => state.playlist.data.system_id;
const timelineWidgetOpenSelector = (state) => state.railInspection.timelineWidget.open;
const userPreferencesShowRailManualAlimentSelector = (state) => _.get(state.userPreferences, "showRailManualAlignment", true);
const currentSessionSelector = (state) => state.sessions[state.playlist.data.routeID];
const continuousObservationsSelector = (state) => state.continuousObservations;
const autoScrollActiveSelector = (state) => state.railInspection.autoScrollActive;
const railImageConfigObjectSelector = (state) => state.railInspection.railInspectionImageConfig;
const detectionsExportOpenSelector = (state) => state.railInspection.detectionsExportOpen;
const detectionsExportIndexRangeSelector = (state) => state.railInspection.detectionsExportIndexRange;

const RailInspectControls = ({
    imagePlayer,
    imageIndexChanged,
    extraStyles,
    inPlayer,
    videoless,
    secondaryRailImages,
    secondaryAlignmentAdjustment,
    setSecondaryAlignmentAdjustment,
    newRailIndex,
}) => {
    const dispatch = useDispatch();

    const userConfig = useSelector(userConfigSelector);
    const naiveIndex = useSelector(naiveIndexSelector);
    const currentOffset = useSelector(currentOffsetSelector);
    const routeLocationData = useSelector(routeLocationSelector);
    const elrUnits = useSelector(elrUnitsSelector);
    const dashboards = useSelector(dashboardsSelector);
    const views = useSelector(viewsSelector);
    const userDetails = useSelector(userDetailsSelector);
    const railData = useSelector(railDataObjectSelector);
    const railDataLoaded = useSelector(railDataLoadedSelector);
    const selectedRailImage = useSelector(selectedRailImageSelector);
    const railsFlippedValue = useSelector(railsFlippedSelector);
    const routeSystemID = useSelector(routeSystemIDSelector);
    const timelineWidgetOpen = useSelector(timelineWidgetOpenSelector);
    const userPreferencesShowRailManualAliment = useSelector(userPreferencesShowRailManualAlimentSelector);
    const currentSession = useSelector(currentSessionSelector);
    const continuousObservations = useSelector(continuousObservationsSelector);
    const autoScrollActive = useSelector(autoScrollActiveSelector);
    const railImageConfigObject = useSelector(railImageConfigObjectSelector);
    const detectionsExportOpen = useSelector(detectionsExportOpenSelector);
    const detectionsExportIndexRange = useSelector(detectionsExportIndexRangeSelector);

    const dashboardID = userDetails.dashboardAccessID;
    const currentDashboard = _.find(dashboards, (dash) => dash.access_id === dashboardID);
    const userViewOffsets = _.get(views, [userDetails.userConfig.view_id, "datum_offsets"], []);
    const workspaceViewOffsets = _.get(views, [_.get(currentDashboard, ["config", "view_id"], -1), "datum_offsets"], []);
    const datumOffsets = workspaceViewOffsets.length ? workspaceViewOffsets : userViewOffsets;

    const railIndex = useMemo(() => {
        if (selectedRailImage) {
            return selectedRailImage.index || 0;
        } else {
            return newRailIndex;
        }
    }, [newRailIndex, selectedRailImage]);

    const elrIndexes = useMemo(() => {
        if (railData && railData.length && routeLocationData && routeLocationData.length && railDataLoaded) {
            return calculateElrGroups(railData, routeLocationData, routeSystemID);
        }
        return {};
    }, [railData, routeLocationData, railDataLoaded, routeSystemID]);

    useEffect(() => {
        if (currentSession && currentSession.id) {
            dispatch(getSessionContinuousObservations(currentSession.id));
        }
    }, [currentSession, dispatch]);

    const sessionHasAnalyticsData = useMemo(() => {
        // currently we are only showing contaminated observations in the rail inspection
        return _.filter(continuousObservations, (observation) => observation.class === "contaminated").length ? true : false;
    }, [continuousObservations]);

    const allowRailFlip = useMemo(() => {
        return railImageConfigObject.horizontal;
    }, [railImageConfigObject.horizontal]);

    const elrLabels = useMemo(() => {
        if (inPlayer) {
            return {};
        }
        if (railData && railData.length && railDataLoaded) {
            let marks = {};
            const groupRatio = railData.length / 55;
            const heightRatio = railData.length / 20;

            let lastELR;
            let elrGroups = {};
            let elrArray = [];
            let lastPos = 0;

            for (let i = 0; i < elrIndexes.length; i++) {
                const elr = elrIndexes[i].elr;
                const index = elrIndexes[i].start;
                if (lastELR && lastELR !== elr) {
                    const difference = index - lastPos;

                    if (difference < groupRatio) {
                        elrArray.push(elr);
                    } else {
                        if (elrArray.length) {
                            elrGroups[lastPos] = elrArray;
                        }
                        elrArray = [elr];
                    }
                    lastPos = index;
                }
                lastELR = elr;
            }

            if (elrArray.length) {
                elrGroups[lastPos] = elrArray;
            }

            let marginTop = "-34px";
            let lastMarginTop = 0;
            let lastMark = 0;
            let lastDifference = 0;

            const keys = Object.keys(elrGroups);
            for (let i = 0; i < keys.length; i++) {
                let content = elrGroups[keys[i]];
                let markIndex = parseInt(keys[i]);

                let element;

                if (content.length > 1) {
                    let values = content.map((i) => {
                        return <span className="inspectRail-Controls__Inner__Label__Elr">{i}</span>;
                    });

                    element = (
                        <Tooltip
                            title={values}
                            placement="top">
                            <div className="inspectRail-Controls__Inner__Label">
                                <span>{content.length}</span>
                            </div>
                        </Tooltip>
                    );
                } else {
                    element = (
                        <div className="inspectRail-Controls__Inner__Label">
                            <strong>{content}</strong>
                        </div>
                    );
                }

                let difference = markIndex - lastMark;

                if (lastMark > 0) {
                    if (difference < heightRatio) {
                        marginTop = "-50px";
                        if (lastDifference < heightRatio) {
                            if (lastMarginTop === -"34px") {
                                marginTop = "-50px";
                            } else {
                                marginTop = "-34px";
                            }
                        }
                    } else {
                        marginTop = "-34px";
                    }
                }

                lastMark = markIndex;
                lastDifference = difference;
                lastMarginTop = marginTop;

                marks[Object.keys(elrGroups)[i]] = {
                    style: { color: "#a9c447", fontSize: "0.7vw", marginTop: marginTop },
                    label: element,
                };
            }

            const interval = Math.floor(railData.length / 5);

            for (let i = 0; i <= 5; i++) {
                let index = interval * i;
                let scrollerIndex = interval * i;
                if (i === 5) {
                    index -= 1;
                }
                if (scrollerIndex === railData.length) {
                    scrollerIndex -= 1;
                }

                if (railData[index]) {
                    const timestamp = railData[index].timestamp;
                    const calculatedELR = calculateRouteCoordinatesForLocation(timestamp, routeLocationData, routeSystemID);

                    if (calculatedELR) {
                        let remove_label = true;

                        if (i === 0 || i === 5) {
                            remove_label = false;
                        }

                        const elr = calculatedELR.to_simple_string(elrUnits, datumOffsets, remove_label);

                        marks[scrollerIndex] = {
                            style: { color: "#b09aee", fontSize: 12 },
                            label: <strong>{elr}</strong>,
                        };
                    }
                }
            }

            if (detectionsExportOpen && !_.isNil(detectionsExportIndexRange)) {
                let startIndex = detectionsExportIndexRange.start || 0;
                if (marks.hasOwnProperty(startIndex)) {
                    startIndex += 1;
                }
                marks[startIndex] = {
                    label: <span className="inspectRail-Controls__ExportRangeMarker"></span>,
                };

                let endIndex = detectionsExportIndexRange.end || railData.length - 1;
                if (marks.hasOwnProperty(endIndex)) {
                    endIndex -= 1;
                }
                marks[endIndex] = {
                    label: <span className="inspectRail-Controls__ExportRangeMarker"></span>,
                };
            }

            return marks;
        }
    }, [
        inPlayer,
        railData,
        railDataLoaded,
        detectionsExportOpen,
        elrIndexes,
        routeLocationData,
        routeSystemID,
        elrUnits,
        datumOffsets,
        detectionsExportIndexRange,
    ]);

    const moveIndex = (indexesToMove) => {
        const newIndex = newRailIndex + indexesToMove;
        console.log("newIndex should be - moveIndex: ", newIndex);
        imageIndexChanged(newIndex);
    };

    const formatTime = useCallback(
        (sliderIndex, offset) => {
            let displayTime = 0;
            let timeTooltip = "Unknown";

            if (railData[sliderIndex]) {
                displayTime = railData[sliderIndex].timestamp / 1000;
            }

            if (displayTime > 0) {
                const dpDate = new Date(displayTime * 1000);
                const date = convertToTimezone(dpDate, userConfig.convert_to_utc);

                const nd = <span>{date.split(" ")[0]}</span>;
                const nt = <span>{date.split(" ")[1]}</span>;
                const tz = <span>{date.split(" ")[2]}</span>;

                timeTooltip = (
                    <>
                        {nd}
                        {nt}
                        {tz}
                    </>
                );
            }

            return timeTooltip;
        },
        [userConfig, railData],
    );

    const formattedTime = useMemo(() => {
        if (railData) {
            return formatTime(railIndex, currentOffset);
        }
    }, [railData, formatTime, railIndex, currentOffset]);

    const setSecondaryAdjustment = useCallback(
        (amount) => {
            if (autoScrollActive) {
                dispatch(setAutoScrollActive(false));
            }
            setSecondaryAlignmentAdjustment(amount);
        },
        [autoScrollActive, setSecondaryAlignmentAdjustment, dispatch],
    );

    const slider = useMemo(() => {
        return (
            <Slider
                min={0}
                max={railData.length - 1}
                value={railIndex}
                onChange={(index) => {
                    if (Math.abs(index - railIndex) > 1) {
                        imageIndexChanged(index);
                    }
                }}
                tipFormatter={null}
                marks={elrLabels}
                included={false}
                step={1}
                className={"Image-Step-Slider"}
            />
        );
    }, [railData.length, railIndex, imageIndexChanged, elrLabels]);

    return (
        <div
            className="inspectRail-Controls"
            style={extraStyles}>
            <div className="inspectRail-Controls__Outer">
                <div className="inspectRail-Controls__Outer__Icons">
                    <div>
                        <FastBackwardFilled onClick={() => moveIndex(-5)} />
                    </div>
                    <div>
                        <StepBackwardFilled onClick={() => moveIndex(-1)} />
                    </div>
                    <div>
                        <StepForwardFilled onClick={() => moveIndex(1)} />
                    </div>
                    <div>
                        <FastForwardFilled onClick={() => moveIndex(5)} />
                    </div>
                </div>
            </div>
            <div className="inspectRail-Controls__Inner">
                <div className="inspectRail-Controls__Inner__Time">{formattedTime}</div>
                <div className="inspectRail-Controls__Inner__Slider">{slider}</div>
            </div>
            <div className="inspectRail-Controls__Outer right">
                {inPlayer && videoless && allowRailFlip && (
                    <Checkbox
                        onChange={(e) => {
                            dispatch(flipInspectionRails(e.target.checked));
                            if (autoScrollActive) {
                                dispatch(setAutoScrollActive(false));
                            }
                        }}
                        checked={railsFlippedValue}>
                        Flip Rails
                    </Checkbox>
                )}

                {currentDashboard && currentDashboard.isBeta && secondaryRailImages && (
                    <div
                        className={`inspectRail-Controls__Outer_RailAdjustment ${secondaryRailImages ? "" : "inspectRail-Controls__Outer_RailAdjustment-Disabled"}`}>
                        <label>Adjust Alignment:</label>
                        <InputNumber
                            onChange={setSecondaryAdjustment}
                            min={-500000}
                            max={500000}
                            precision={2}
                            step={0.1}
                            value={secondaryAlignmentAdjustment}
                            onFocus={() => {
                                if (userPreferencesShowRailManualAliment) {
                                    notification.open({
                                        message: "Better Alignment Tool",
                                        description: "For an enhanced align experience, utilize the new Manual Align tool in the top-right corner.",
                                        placement: "bottomRight",
                                        onClick: () => {
                                            notification.destroy();
                                        },
                                    });
                                }
                            }}
                        />
                    </div>
                )}
            </div>

            {((currentDashboard && currentDashboard.isBeta) || userDetails?.isDashboardBeta) && !inPlayer && sessionHasAnalyticsData && (
                <div className="inspectRail-Controls__ToggleTimelineButton">
                    <Button
                        type="primary"
                        onClick={() => dispatch(toggleRailInspectionTimelineWidget())}
                        icon="up">
                        Show Analytics
                    </Button>
                </div>
            )}

            <Drawer
                title={false}
                className="RailInspectionControlsTimelineDrawer"
                placement={"bottom"}
                closable={false}
                visible={timelineWidgetOpen}
                mask={false}>
                <SessionTimeline
                    inRailInspection={true}
                    formattedTime={formattedTime}
                />
            </Drawer>
        </div>
    );
};

export default RailInspectControls;
