import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ZoomableImage from "components/ZoomableImage";
import { GROUND_TRUTH_DISPLAY_NAMES } from "./issueUtils";
import { Button, Tooltip } from "antd";
import { getAssetData, receiveAssetData, routeSelected, selectObservation } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import ObservationModal from "./ObservationModal";

const selectedMarkerSelector = (state) => state.markers.selectedMarker;
const assetObservationsSelector = (state) => state.mlAssets.observations;
const assetIDSelector = (state) => state.mlAssets.selected.asset;

const NearbyAssetTab = () => {
    const dispatch = useDispatch();

    const selectedMarker = useSelector(selectedMarkerSelector);
    const assetObservations = useSelector(assetObservationsSelector);
    const assetID = useSelector(assetIDSelector);

    const [selectedObservationIndex, setSelectedObservationIndex] = useState(0);
    const [displayObservationModal, setDisplayObservationModal] = useState(false);

    const markerInfo = {
        "Observation Count": assetObservations.observations.length,
    };

    const navigate = (forward) => {
        if (forward) {
            if (selectedObservationIndex < assetObservations.observations.length - 1) {
                setSelectedObservationIndex((idx) => idx + 1);
            }
        } else {
            if (selectedObservationIndex > 0) {
                setSelectedObservationIndex((idx) => idx - 1);
            }
        }
    };

    const goToSession = () => {
        dispatch(routeSelected(selectedObservation.session_id, selectedObservation.timestamp / 1000));
    };

    useEffect(() => {
        setSelectedObservationIndex(0);
    }, [selectedMarker.id]);

    const selectedObservation = assetObservations.observations[selectedObservationIndex];

    if (!_.isEmpty(selectedMarker.ground_truth_data)) {
        Object.keys(selectedMarker.ground_truth_data).forEach((groundTruthKey) => {
            if (GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]) {
                markerInfo[GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]] = selectedMarker.ground_truth_data[groundTruthKey];
                markerInfo["Confidence"] = "Ground Truthed";
            }
        });
    } else if (selectedMarker.consensus_data) {
        Object.keys(selectedMarker.consensus_data).forEach((groundTruthKey) => {
            if (GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]) {
                markerInfo[GROUND_TRUTH_DISPLAY_NAMES[groundTruthKey]] = selectedMarker.consensus_data[groundTruthKey];
            } else if (groundTruthKey === "confidence") {
                markerInfo["Confidence"] = selectedMarker.consensus_data[groundTruthKey];
            }
        });
    }

    const fields = (markerInfo) => {
        return _.map(markerInfo, (value, key) => {
            return (
                <tr
                    key={key}
                    className="Row">
                    <td className={"Key"}>{key}:</td>
                    <td className={"Value " + value}>{value}</td>
                </tr>
            );
        });
    };

    const openObservationModal = () => {
        if (_.has(selectedObservation, "id")) {
            dispatch(getAssetData(assetID)).then(() => {
                setDisplayObservationModal(true);
                dispatch(selectObservation(selectedObservation.id));
            });
        }
    };

    return (
        <div className="NearbyAssetTab">
            {displayObservationModal && (
                <ObservationModal
                    isIssue={false}
                    closeModal={() => {
                        setDisplayObservationModal(false);
                        dispatch(receiveAssetData({}));
                        dispatch(selectObservation(null));
                    }}
                    observations={assetObservations.observations}
                />
            )}
            {selectedObservation && (
                <div className="NearbyAssetContainer">
                    <Tooltip title="Previous Observation">
                        <FontAwesomeIcon
                            onClick={() => navigate()}
                            className={"NavigationIcon Left" + (selectedObservationIndex === 0 ? " Disabled" : "")}
                            icon={faArrowCircleLeft}
                        />
                    </Tooltip>

                    <Tooltip title="Next Observation">
                        <FontAwesomeIcon
                            onClick={() => navigate(true)}
                            className={"NavigationIcon Right" + (selectedObservationIndex === assetObservations.observations.length - 1 ? " Disabled" : "")}
                            icon={faArrowCircleRight}
                        />
                    </Tooltip>

                    <div className="NearbyAssetImageContainer">
                        <ZoomableImage
                            zoom={1}
                            imgSrc={selectedObservation.image_url}
                            destroyOnClose={true}
                            updateZoom={() => {}}
                        />
                    </div>
                </div>
            )}

            <Button
                onClick={goToSession}
                type="primary"
                className="SessionButton"
                disabled={assetObservations.observations.length === 0}>
                View In Session
            </Button>

            <Button
                onClick={openObservationModal}
                type="primary"
                className="SessionButton"
                disabled={assetObservations.observations.length === 0}
                style={{ marginTop: "5px" }}>
                View Asset
            </Button>

            <table className={"markerInformationTable"}>
                <tbody>{fields(markerInfo)}</tbody>
            </table>
        </div>
    );
};

export default NearbyAssetTab;
