import React, { useState, useEffect } from "react";
import "./CameraPosition.scss";

import viu from "./images/VIU.svg";
import crvs from "./images/CRVS.svg";
import tlcThermal from "./images/TLC-thermal.svg";
import tlc from "./images/TLC.svg";
import overheadPantograph from "./images/Overhead-Pantograph.svg";
import overhead from "./images/Overhead.svg";
import fixedCab from "./images/Fixed-Cab.svg";
import phoneCab from "./images/Phone-Cab.svg";
import mpv from "./images/MPV.svg";
import dbso from "./images/DBSO.svg";

const CameraPosition = ({ sessionData }) => {
    const [imgSrc, setImgSrc] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        switch (sessionData) {
            case "tlc":
                setImgSrc(tlc);
                setMessage("Tail lamp camera");
                break;
            case "tlc-thermal":
                setImgSrc(tlcThermal);
                setMessage("Tail lamp camera with thermal imaging");
                break;
            case "cab-go":
                setImgSrc(phoneCab);
                setMessage("AIVR Go device in the cab");
                break;
            case "cab-fixed":
                setImgSrc(fixedCab);
                setMessage("Fixed camera installed in the cab");
                break;
            case "viu":
                setImgSrc(viu);
                setMessage("Visible and thermal front mounted cameras and line scanning imagery");
                break;
            case "inspection-only":
                setImgSrc(crvs);
                setMessage("Line scanning imagery only");
                break;
            case "inspection-ffv":
                setImgSrc(mpv);
                setMessage("Fixed camera installed in the cab and line scanning imagery");
                break;
            case "overhead-pantograph":
                setImgSrc(overheadPantograph);
                setMessage("Overhead camera pointing at the pantograph");
                break;
            case "overhead":
                setImgSrc(overhead);
                setMessage("Overhead camera");
                break;
            case "dbso":
                setImgSrc(dbso);
                setMessage("Fixed installation standard and near-infrared cameras");
                break;
            default:
                setImgSrc("");
                setMessage("");
        }
    }, []);

    return (
        <div className="camera-position">
            {sessionData && (
                <>
                    <img
                        className="camera-position__diagram"
                        alt="Camera position diagram"
                        src={imgSrc}
                    />
                    <p className="camera-position__message">{message}</p>
                </>
            )}
        </div>
    );
};

export default CameraPosition;
