import React, { useCallback, useEffect, useRef } from "react";
import { getDeviceStatuses } from "redux/actions/index";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import { setupLogging } from "logging/logger";

const INACTIVITY_TIME = 90 * 60;

const playerStateSelector = (state) => _.get(state, ["playlist", "position", "playerState"], false);
const accessTokenSelector = (state) => state.access_token;
const loggingEnabledSelector = (state) => state.userPreferences.loggingEnabled;
const dashboardWidgetKeySelector = (state) => state.dashboardWidgetKey;
const widgetWorkspaceSelector = (state) => {
    return _.get(state.widgetData.DASHBOARD, [state.dashboardWidgetKey, "state", "workspace_id"], null);
};
const shareLinkSelector = (state) => state.shareLink;

const ActivityComponent = () => {
    const dispatch = useDispatch();
    const store = useStore();
    const statusPoll = useRef(null);
    const lastActiveTS = useRef(Date.now() / 1000);
    const accessToken = useSelector(accessTokenSelector);
    const dashboardWidgetKey = useSelector(dashboardWidgetKeySelector);
    const loggingEnabled = useSelector(loggingEnabledSelector);
    const widgetWorkspaceID = useSelector(widgetWorkspaceSelector);
    const shareLink = useSelector(shareLinkSelector);

    useEffect(() => {
        setupLogging(loggingEnabled);
    }, [loggingEnabled]);

    const pollDevices = useCallback(() => {
        // Getting devices is also used as a user status poll
        const playerState = playerStateSelector(store.getState());
        let inactive = true;
        if (playerState === "playing") {
            inactive = false;
        } else if (lastActiveTS.current > Date.now() / 1000 - INACTIVITY_TIME) {
            inactive = false;
        }
        dispatch(getDeviceStatuses(inactive));
    }, [dispatch, store]);

    const updateLastActive = useCallback(
        _.throttle(() => {
            lastActiveTS.current = Date.now() / 1000;
        }, 5000),
        [],
    );

    useEffect(() => {
        function cleanup() {
            document.removeEventListener("keydown", updateLastActive);
            document.removeEventListener("mousemove", updateLastActive);
            document.removeEventListener("touchend", updateLastActive);
        }

        document.addEventListener("keydown", updateLastActive);
        document.addEventListener("mousemove", updateLastActive);
        document.addEventListener("touchend", updateLastActive);
        return cleanup;
    }, [updateLastActive]);

    const shouldPoll = useCallback(() => {
        return (
            (accessToken &&
                !accessToken?.startsWith("ws_") &&
                !window.location.pathname.startsWith("/forgot/") &&
                !window.location.pathname.startsWith("/virtual-cab-ride")) ||
            (dashboardWidgetKey && accessToken?.startsWith("ws_") && widgetWorkspaceID) ||
            shareLink
        );
    }, [accessToken, dashboardWidgetKey, widgetWorkspaceID, shareLink]);

    useEffect(() => {
        if (shouldPoll()) {
            statusPoll.current = setInterval(pollDevices, 60000);
            return function cleanup() {
                clearInterval(statusPoll.current);
            };
        }
    }, [pollDevices, accessToken, dashboardWidgetKey, shouldPoll]);

    useEffect(() => {
        if (shouldPoll()) {
            dispatch(getDeviceStatuses());
        }
    }, [accessToken, dispatch, dashboardWidgetKey, widgetWorkspaceID, shouldPoll]);

    return <React.Fragment />;
};

export default ActivityComponent;
