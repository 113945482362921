import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Measure from "react-measure";
import { Modal, Button, Input } from "antd";
import { GithubPicker } from "react-color";
import Tippy from "@tippyjs/react";
import { getCurrentVideoKey } from "../../util/PlaylistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getEncodedSnapshot } from "redux/actions/index";
import { MEMOIZED_API_BASE_URL } from "../../util/HostUtils";
/*

props.sketches is a list of all of the existing saved sketches for the current
frame. Each sketch looks like:

{
    access_id: integer
    email: string
    encodedPng: base64
    is_admin: 0/1
    is_users_sketch: true/false (returned by API)
    session, video, device keys: strings
}

on mount, a copy of props.sketches is taken and saved into state and augmented
with a .hidden property. This is a bit of a weird pattern and the proper way to
do it is probably to manage user visibility state in a separate array and then
combine the two at render, but it's not the end of the world.

canvasRefs is a map of react refs. the refs are created on the first render, and
one ref is created for each saved sketch not belonging to the current user.
a ref for the current user's canvas is created independently and stored outside
canvasRefs - this is because we *always* need it, and on a new frame there will
be no previously saved sketch for the user yet. the refs are passed into each
canvas element so that we can do manual canvas ops (drawing) as necessary.

currentSketch is the base64 value of the current canvas state after each draw
operation - it's the current value of the canvas in it's save format, rather
than the actual saved value. if currentSketch is not an empty string, it will
be an updated version of the initial saved sketch.

*/

class SketchOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mouseDown: false,
            colors: ["#228b22", "#000080", "#ffff00", "#00ff00", "#00ffff", "#ff00ff", "#ffdab9"],
            sketches: [],
            textAddYCord: 0,
            textAddXCord: 0,
            modalTextInput: "",
            showTextModal: false,
            doneInit: false,
            dimensions: {
                height: -1,
                width: -1,
            },
            currentSketch: "",
            customColours: [],
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);

        this.canvasRefs = {};
        this.sketchCanv = React.createRef();
        this.textInput = React.createRef();
    }

    componentDidMount() {
        this.initialiseSketches();
        window.addEventListener("keydown", this.keyBindListener);
    }

    createSourceURL = (sketchID) => {
        const baseURL = MEMOIZED_API_BASE_URL;
        return `${baseURL}/sketch?id=${sketchID}&access_token=${this.props.access_token}`;
    };

    initialiseSketches = () => {
        const _this = this;
        this.clearUserCanvas();
        this.clearLoadedCanvas();

        if (this.props.userSketch) {
            const source = this.createSourceURL(this.props.userSketch.id);
            this.props.dispatch(getEncodedSnapshot(source, {})).then((image) => {
                if (image) {
                    _this.setState(
                        {
                            currentSketch: "data:image/png;base64, " + image,
                            originalSketch: "data:image/png;base64, " + image,
                        },
                        () => {
                            _this.renderUserSketch();
                            this.props.setChangeMade(false);
                        },
                    );
                    _this.props.setCurrentSketch(image);
                }
            });
        } else {
            console.log("Setting user sketch to empty");
            this.setState({
                currentSketch: "",
            });
            this.props.setCurrentSketch("");
        }

        this.setState({
            sketches: this.props.savedSketches,
            doneInit: false,
        });
    };

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyBindListener);
    }

    keyBindListener = (event) => {
        if (!this.state.showTextModal) {
            return;
        }

        event.stopPropagation();
        switch (event.key) {
            case "Enter":
                this.addTextToCanvas();
                break;
            default:
                break;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        // under certain conditions, we want to redraw the canvas (which
        // won't be updated by normal renders)
        if (!this.state.doneInit && this.sketchCanv.current) {
            this.renderUserSketch();
            this.renderSavedSketches();
            this.setState({
                doneInit: true,
            });
        }

        if (this.props.sketches !== prevProps.sketches) {
            this.initialiseSketches();
            this.renderUserSketch();
            this.renderSavedSketches();
        }

        if (this.props.currentColor !== prevProps.currentColor) {
            this.renderUserSketch();
            this.props.setChangeMade(this.changeMade());
        }

        if (prevProps.videoKey !== this.props.videoKey || prevProps.frame !== this.props.frame) {
            console.log("redrawing sketches");
            this.initialiseSketches();
            this.props.closeColourPicker();
            this.props.onColorChange({ hex: "" });
        }
    }

    getCanvasData = () => {
        return this.sketchCanv.current.toDataURL();
    };

    clearUserCanvas = () => {
        if (this.sketchCanv.current) {
            const context = this.sketchCanv.current.getContext("2d");
            context.clearRect(0, 0, this.sketchCanv.current.width, this.sketchCanv.current.height);
            this.setState({ currentSketch: "" }, () => this.props.setChangeMade(this.changeMade()));
            this.props.setCurrentSketch("");
        }
    };

    clearLoadedCanvas = () => {
        const _this = this;
        this.state.sketches.forEach((sketch) => {
            if (sketch.is_users_sketch) {
                // do not clear the user's canvas
                return;
            }
            if (_this.canvasRefs[`sketch_${sketch.id}`] && _this.canvasRefs[`sketch_${sketch.id}`].current) {
                let context = _this.canvasRefs[`sketch_${sketch.id}`].current.getContext("2d");
                context.clearRect(0, 0, _this.canvasRefs[`sketch_${sketch.id}`].current.width, _this.canvasRefs[`sketch_${sketch.id}`].current.height);
            }
        });
    };

    canvasOnclick = (e) => {
        if (this.props.sketchMode === "text") {
            console.log("Starting text", e);
            let canvas = this.sketchCanv.current;
            let rect = canvas.getBoundingClientRect(),
                scaleX = canvas.width / rect.width,
                scaleY = canvas.height / rect.height;

            let xCord = (e.clientX - rect.left) * scaleX;
            let yCord = (e.clientY - rect.top) * scaleY;
            this.setState({
                textAddYCord: yCord,
                textAddXCord: xCord,
            });
            this.openModal();
        }
    };

    addTextToCanvas = () => {
        let textToAdd = this.state.modalTextInput;

        if (textToAdd) {
            const context = this.sketchCanv.current.getContext("2d");
            context.font = "22px Arial";
            context.fillStyle = this.props.currentColor;
            context.fillText(textToAdd, this.state.textAddXCord, this.state.textAddYCord);
            if (this.sketchCanv.current) {
                console.log("Saving current sketch");
                this.updateCurrentSketch();
            }
        }
        this.closeModal();
    };

    handleMouseDown = (e) => {
        if (this.props.currentMode !== "draw" || this.props.sketchMode !== "pencil") {
            return;
        }
        this.setState({
            mouseDown: true,
        });
        this.props.closeColourPicker();

        let canvas = this.sketchCanv.current;
        let rect = canvas.getBoundingClientRect(),
            scaleX = canvas.width / rect.width,
            scaleY = canvas.height / rect.height;

        let ctx = canvas.getContext("2d");
        ctx.beginPath();
        ctx.lineWidth = "2";
        if (this.props.currentColor) {
            ctx.strokeStyle = this.props.currentColor;
        } else {
            ctx.strokeStyle = "#DB3E00";
            this.props.onColorChange({ hex: "#DB3E00" });
        }
        ctx.moveTo((e.clientX - rect.left) * scaleX, (e.clientY - rect.top) * scaleY);
    };

    updateCurrentSketch = () => {
        if (this.sketchCanv.current) {
            console.log("Saving current sketch");
            let currentSketch = this.getCanvasData();
            this.setState(
                {
                    currentSketch,
                },
                () => {
                    this.props.setChangeMade(this.changeMade());
                },
            );
            this.props.setCurrentSketch(currentSketch);
        }
    };

    handleMouseUp = () => {
        if (this.state.mouseDown) {
            this.setState({
                mouseDown: false,
            });
            this.updateCurrentSketch();
        }
    };

    handleMouseMove = (e) => {
        if (this.state.mouseDown) {
            let canvas = this.sketchCanv.current;
            let rect = canvas.getBoundingClientRect(),
                scaleX = canvas.width / rect.width,
                scaleY = canvas.height / rect.height;

            let ctx = canvas.getContext("2d");
            ctx.lineTo((e.clientX - rect.left) * scaleX, (e.clientY - rect.top) * scaleY);
            ctx.stroke();
        }
    };

    renderSavedSketches = () => {
        const _this = this;

        _this.clearLoadedCanvas();
        this.state.sketches.forEach((sketch, index) => {
            if (sketch.video_key !== _this.props.videoKey || sketch.hidden || sketch.is_users_sketch) {
                return;
            }

            let colourIndex = index % _this.state.colors.length;
            let fillStyle = _this.state.colors[colourIndex];

            if (sketch.colour) {
                fillStyle = sketch.colour;
            }

            let customColour = _.find(this.state.customColours, (colourObj) => colourObj.id === sketch.id);
            if (customColour) {
                fillStyle = customColour.colour;
            }

            const source = this.createSourceURL(sketch.id);
            this.props.dispatch(getEncodedSnapshot(source, {})).then((image) => {
                if (image) {
                    let img = new Image();
                    img.onload = function () {
                        let width = _this.canvasRefs[`sketch_${sketch.id}`].current.width;
                        let height = _this.canvasRefs[`sketch_${sketch.id}`].current.height;
                        let ctx = _this.canvasRefs[`sketch_${sketch.id}`].current.getContext("2d");
                        ctx.fillStyle = fillStyle;
                        ctx.fillRect(0, 0, _this.canvasRefs[`sketch_${sketch.id}`].current.width, _this.canvasRefs[`sketch_${sketch.id}`].current.height);
                        ctx.globalCompositeOperation = "destination-in";
                        ctx.drawImage(img, 0, 0, width, height);
                        ctx.globalCompositeOperation = "source-over";
                    };
                    img.src = `data:image/png;base64, ${image}`;
                }
            });
        });
    };

    renderUserSketch = () => {
        let _this = this;

        let userSketch = this.props.userSketch;

        let currentSketch = this.state.currentSketch;
        if (!currentSketch && !userSketch) {
            return;
        }
        let canvas = _this.sketchCanv.current;
        if (!canvas) {
            return;
        }
        let img = new Image();
        img.onload = function () {
            let { width, height } = canvas;
            let ctx = canvas.getContext("2d");
            if (_this.props.currentColor) {
                ctx.fillStyle = _this.props.currentColor;
            } else if (userSketch.colour) {
                ctx.fillStyle = userSketch.colour;
                _this.props.onColorChange({ hex: userSketch.colour });
            } else {
                ctx.fillStyle = "#DB3E00";
                _this.props.onColorChange({ hex: "#DB3E00" });
            }
            ctx.fillRect(0, 0, width, height);
            ctx.globalCompositeOperation = "destination-in";
            ctx.drawImage(img, 0, 0, width, height);
            ctx.globalCompositeOperation = "source-over";
        };

        if (this.changeMade()) {
            img.src = currentSketch;
        } else if (userSketch) {
            img.src = this.state.originalSketch;
        }
    };

    changeMade = () => {
        let currentSketch = this.state.currentSketch;

        if (!this.props.userSketch && currentSketch) {
            return true;
        } else if (!this.props.userSketch && !currentSketch) {
            return false;
        } else {
            return (
                currentSketch !== this.state.originalSketch ||
                (this.props.userSketch.colour && this.props.userSketch.colour.toLowerCase() !== this.props.currentColor.toLowerCase())
            );
        }
    };

    toggleHidden = (index) => {
        const _this = this;
        let value = this.state.sketches[index].hidden;
        let copy = this.state.sketches.slice();

        console.log("Toggling sketch visibility", value);
        copy[index].hidden = !value;
        this.setState(
            {
                sketches: copy,
            },
            _this.renderSavedSketches(),
        );
    };

    togglePrivate = (index) => {
        const _this = this;
        let value = this.state.sketches[index].private;
        let copy = this.state.sketches.slice();

        console.log("Toggling sketch privacy", value);
        copy[index].private = !value;
        this.setState(
            {
                sketches: copy,
            },
            _this.renderSavedSketches(),
        );
    };

    changeMarkupColour = (colour, sketchid) => {
        let newColourObject = { id: sketchid, colour };

        this.setState((state) => {
            let customColours = state.customColours;
            let existingIndex = _.findIndex(customColours, function (colour) {
                return colour.id === sketchid;
            });
            if (existingIndex > -1) {
                customColours.splice(existingIndex, 1);
            }
            customColours = customColours.concat(newColourObject);
            console.log("setting new colour for this sketch: ", sketchid, colour);
            return {
                customColours,
            };
        });
        this.renderSavedSketches();
    };

    renderSketchUser = (sketch, index) => {
        if (sketch.video_key !== this.props.videoKey || sketch.is_users_sketch) {
            return null;
        } else {
            let colourIndex = index % this.state.colors.length;
            let colourCircleBackground = this.state.colors[colourIndex];
            let customColour = _.find(this.state.customColours, (colourObj) => colourObj.id === sketch.id);
            if (customColour) {
                colourCircleBackground = customColour.colour;
            } else if (sketch.colour) {
                colourCircleBackground = sketch.colour;
            }

            return (
                <div
                    key={sketch.id}
                    className="emailColorDiv">
                    <FontAwesomeIcon
                        icon={sketch.hidden ? faEyeSlash : faEye}
                        onClick={() => this.toggleHidden(index)}
                    />
                    <span className="sketchEmail">{sketch.name}</span>
                    <Tippy
                        arrow={true}
                        theme="aivrlight"
                        interactive={true}
                        content={
                            <GithubPicker
                                triangle="hide"
                                onChangeComplete={(colour) => this.changeMarkupColour(colour.hex, sketch.id)}
                            />
                        }>
                        <div
                            className="colorCircle"
                            style={{ background: colourCircleBackground }}></div>
                    </Tippy>
                </div>
            );
        }
    };

    get renderSketchUsers() {
        if (!this.state.sketches || this.state.sketches.length < 1) {
            return "No team markup";
        } else {
            return this.state.sketches.map(this.renderSketchUser);
        }
    }

    getCanvasClass = () => {
        if (this.props.currentMode === "draw") {
            if (this.props.sketchMode === false) {
                return " not-allowed";
            } else if (this.props.sketchMode === "text") {
                return " textCanvas";
            } else {
                return " crosshair";
            }
        }

        return "";
    };

    getSizedCanvases = ({ measureRef, contentRect }) => {
        let canvasObj = null;
        if (contentRect.bounds.width && contentRect.bounds.height) {
            let { height, width } = contentRect.bounds;
            // the div has been measured, so we can return the correctly sized
            // canvas objects

            let otherUserCanvases = null;
            otherUserCanvases = this.state.sketches.map((sketch) => {
                if (sketch.is_users_sketch) {
                    // user sketch is dealt with separately
                    // to ensure it is created even if there is no initial saved version
                    return null;
                }
                if (!this.canvasRefs[`sketch_${sketch.id}`]) {
                    console.log("Creating ref for", sketch.id);
                    this.canvasRefs[`sketch_${sketch.id}`] = React.createRef();
                }
                return (
                    <canvas
                        key={sketch.id}
                        height={height}
                        width={width}
                        ref={this.canvasRefs[`sketch_${sketch.id}`]}
                        className="SketchOverlay"></canvas>
                );
            });

            canvasObj = (
                <React.Fragment>
                    <canvas
                        key="userSketchCanvas"
                        height={height}
                        width={width}
                        ref={this.sketchCanv}
                        className={"SketchOverlay userCanvas" + this.getCanvasClass()}
                        onClick={this.canvasOnclick}
                        onMouseDown={(e) => {
                            let nativeEvent = e.nativeEvent;
                            this.handleMouseDown(nativeEvent);
                        }}
                        onMouseMove={(e) => {
                            let nativeEvent = e.nativeEvent;
                            this.handleMouseMove(nativeEvent);
                        }}
                        onMouseUp={(e) => {
                            let nativeEvent = e.nativeEvent;
                            this.handleMouseUp(nativeEvent);
                        }}
                        onMouseOut={(e) => {
                            let nativeEvent = e.nativeEvent;
                            this.handleMouseUp(nativeEvent);
                        }}></canvas>
                    {otherUserCanvases}
                </React.Fragment>
            );
        }
        return (
            <div
                ref={measureRef}
                style={{ width: "100%", height: "100%" }}>
                {canvasObj}
            </div>
        );
    };

    closeModal = () => {
        this.setState({
            showTextModal: false,
            textAddXCord: 0,
            textAddYCord: 0,
            modalTextInput: "",
        });
    };

    openModal = () => {
        this.setState(
            {
                showTextModal: true,
            },
            () => {
                this.textInput.focus();
            },
        );
    };

    onInputChange = (event) => {
        this.setState({
            modalTextInput: event.target.value,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Modal
                    title="Add Text"
                    centered
                    zIndex={2000}
                    getContainer={false}
                    visible={this.state.showTextModal}
                    onCancel={this.closeModal}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.closeModal}>
                            Cancel
                        </Button>,
                        <Button
                            key="enter"
                            onClick={this.addTextToCanvas}>
                            Add
                        </Button>,
                    ]}>
                    <Input
                        placeholder="Text to add"
                        className=""
                        onChange={this.onInputChange}
                        value={this.state.modalTextInput}
                        ref={(input) => {
                            this.textInput = input;
                        }}
                    />
                </Modal>

                <div className="MeasurementOverlay">
                    <Measure
                        bounds
                        onResize={(contentRect) => {
                            this.setState({ dimensions: contentRect.bounds });
                            this.renderSavedSketches();
                            this.renderUserSketch();
                        }}>
                        {this.getSizedCanvases}
                    </Measure>

                    {this.props.showingTeam && (
                        <div className="sketchControlOverlay">
                            {this.renderSketchUsers}
                            <div className="emailColorDiv">
                                {this.state.currentSketch !== "" && (
                                    <FontAwesomeIcon
                                        icon={this.props.isPrivate ? faLock : faLockOpen}
                                        onClick={() => this.props.togglePrivate()}
                                    />
                                )}
                                <span className="sketchEmail">You</span>
                                <div
                                    className="colorCircle"
                                    style={{ background: this.props.currentColor }}></div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let allSketches = state.userSketches;

    let videoKey = getCurrentVideoKey(state.playlist);
    const frame = ownProps.frame;

    let videoSketches = allSketches.filter((sketch) => {
        return sketch.video_key === videoKey && sketch.frame === frame;
    });

    videoSketches.forEach((sketch) => {
        sketch.hidden = false;
    });

    let savedSketches = videoSketches.filter((sketch) => {
        return !sketch.is_users_sketch;
    });

    let userSketch = _.find(videoSketches, function (sketch) {
        return sketch.is_users_sketch;
    });

    return {
        sketches: state.userSketches,
        savedSketches,
        userSketch,
        videoKey,
        currentMode: state.markup.tool_mode,
        session: state.sessions[state.playlist.data.routeID],
        access_token: state.access_token,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SketchOverlay);
