import NetworkRailLogo from "../../images/network-rail-logo.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import aivrLogo from "../../images/new-branding-2.0/aivr-purple.svg";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

const patrolIDSelector = (state) => state.schemaInterface.plan.id;
const patrolReportFormSelector = (state) => state.schemaInterface.patrolReport.form;
const patrolStatusSelector = (state) => state.schemaInterface.status;
const userEmailSelector = (state) => state.userDetails.email;

const PatrolTablePrinted = ({ chunk, i, extraBlankRows, numberOfSheets, blankPage }) => {
    const patrolID = useSelector(patrolIDSelector);
    const patrolReportForm = useSelector(patrolReportFormSelector);
    const patrolStatus = useSelector(patrolStatusSelector);
    const userEmail = useSelector(userEmailSelector);

    return (
        <div className="patrolDefectsPrinted">
            <div className="patrolDefectsPrinted__Header">
                <div className="patrolDefectsPrinted__Header-Logo">
                    <img
                        src={NetworkRailLogo}
                        alt="Network Rail Logo"
                        crossOrigin={"anonymous"}
                    />
                </div>
                <div className="patrolDefectsPrinted__Header-Title">
                    <h1>Basic Visual Inspection Report: TEF 3015 v3 Sept 2010</h1>
                </div>
            </div>

            <table className="patrolDefectsPrinted__Table header">
                <tbody>
                    <tr className="row">
                        <td className="small">Patrol No.</td>
                        <td className="large">{patrolReportForm.patrolNumber}</td>
                        <td className="small">Diagram ID</td>
                        <td className="large">{patrolReportForm.patrolName}</td>
                        <td className="small">Week No.</td>
                        <td className="large"></td>
                        <td className="small">Signature of mentor (and role) if accompanying patroller</td>
                        <td className="large"></td>
                    </tr>
                    <tr className="row">
                        <td className="small">Patroller's Name</td>
                        <td className="large">
                            {patrolReportForm.patrollersName} {patrolStatus > 1 && userEmail}
                        </td>
                        <td className="small">Scheduled Date of Patrol</td>
                        <td className="large">{patrolReportForm.scheduledDate ? new Date(patrolReportForm.scheduledDate * 1000).toDateString() : null}</td>
                        <td className="small">Actual Date of Patrol</td>
                        <td className="large">{patrolReportForm.patrolledDate ? new Date(patrolReportForm.patrolledDate * 1000).toDateString() : null}</td>
                        <td className="small">No. of sheets</td>
                        <td className="large">
                            Sheet {i + 1} of {numberOfSheets}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="patrolDefectsPrinted__Table">
                <tbody>
                    <tr className="row to-be-completed">
                        <td colSpan={11}>To be completed by Patroller</td>
                        <td colSpan={3}>Completed work</td>
                        <td colSpan={6}>To be completed by Supervisor</td>
                    </tr>
                    <tr className="centre">
                        <td className="small"></td>
                        <td className="small">ELR</td>
                        <td className="small">Track ID</td>
                        <td className="large">Mileage From M ch/yds</td>
                        <td className="large">Mileage To M ch/yds</td>
                        <td className="large">Work Identified Description (if in S&C include Pts No.)</td>
                        <td className="large">Defect Type</td>
                        <td className="small">Fault No.</td>
                        <td className="small">Qty</td>
                        <td className="small">Unit of Work</td>
                        <td className="small">Urgent Yes/No</td>

                        <td className="small">Work Completed?</td>
                        <td className="small">Units Comp</td>
                        <td className="small">T.O.T</td>

                        <td className="small">Priority</td>
                        <td className="small">Prot. Type</td>
                        <td className="small">Defect Code</td>
                        <td className="small">Std Job No.</td>
                        <td className="small">Req Finish Date</td>
                        <td className="small">W/O No (If known)</td>
                    </tr>

                    {!blankPage &&
                        chunk &&
                        chunk.length &&
                        chunk.map((item, index) => {
                            let descriptionColumn = item.description;

                            if (item.type === "annotation" || item.type === "bookmark") {
                                const link = `${window.location.origin}/review-patrol/${patrolID}/${item.type}?id=${item.id}`;
                                descriptionColumn = (
                                    <>
                                        <span className="Link">{item.description}</span>
                                        <br />(<a href={link}>{link}</a>)
                                    </>
                                );
                            }

                            return (
                                <tr className="centre">
                                    <td>
                                        {item.supervisor_edited ? (
                                            <FontAwesomeIcon
                                                className="InfoIcon"
                                                icon={faUserEdit}
                                            />
                                        ) : null}
                                    </td>
                                    <td>{item.elr}</td>
                                    <td>{item.trackID}</td>
                                    <td>{item.position}</td>
                                    <td>{item.position}</td>
                                    <td>{descriptionColumn}</td>
                                    <td>{item.defectType}</td>
                                    <td>{item.faultNo}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.unitOfWork}</td>
                                    <td>{item.urgent ? "Yes" : "No"}</td>

                                    <td>{item.workCompleted ? "Yes" : "No"}</td>
                                    <td>{item.unitsComp}</td>
                                    <td>{item.tot}</td>

                                    <td>{item.priority}</td>
                                    <td>{item.protType}</td>
                                    <td>{item.defectCode}</td>
                                    <td>{item.stdJobNo}</td>
                                    <td>{item.reqFinishDate ? new Date(item.reqFinishDate * 1000).toDateString() : ""}</td>
                                    <td>{item.woNo}</td>
                                </tr>
                            );
                        })}

                    {extraBlankRows(i, chunk)}
                </tbody>
            </table>

            <table className="patrolDefectsPrinted__Table">
                <tbody>
                    <tr className="row">
                        <td>"No Actionable Defects" Statement:</td>
                        <td className="label">
                            The Patroller must write "no actionable defects" in this box in the event that no defects requiring action within 4 weeks were
                            identified during the basic visual inspection.
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="patrolDefectsPrinted__Table">
                <tbody>
                    <tr className="row">
                        <td className="title">All information to be completed accurately and the relevant systems updated</td>
                    </tr>
                </tbody>
            </table>

            <table className="patrolDefectsPrinted__Table footer">
                <tbody>
                    <tr>
                        <td>Patroller's Signature:</td>
                        <td colSpan="2"></td>
                        <td className="rightAlign">Was this Patrol fully completed:</td>
                        <td>{patrolReportForm.patrolComplete ? "Yes" : "No"}</td>
                        <td colSpan="2"></td>
                    </tr>
                    <tr>
                        <td>If Patrol was not completed state reason why and provide IFC fault number:</td>
                        <td colSpan="6">{patrolReportForm.patrolCompleteReason}</td>
                    </tr>
                    <tr>
                        <td>Guidelines for Supervisor:</td>
                        <td colSpan="6">
                            <div>1&#41; This report is to be reviewed in conjunction with the Ellipse Workbank / Walkout Report.</div>
                            <div>2&#41; This report is to be signed and passed on for inputting in Ellipse within 3 days of the patrol.</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Supervisor's Comments:</td>
                        <td colSpan="6">{patrolReportForm.supervisorComment}</td>
                    </tr>
                    <tr>
                        <td>Supervisor's Name / Signature: I have reviewed this report and authorise for the information to be inputted in Ellipse.</td>
                        <td className="rightAlign">Name:</td>
                        <td>{patrolReportForm.supervisorName}</td>
                        <td className="rightAlign">Signature:</td>
                        <td></td>
                        <td className="rightAlign">Date:</td>
                        <td>{patrolReportForm.reviewDate ? new Date(patrolReportForm.reviewDate * 1000).toDateString() : null}</td>
                    </tr>
                    <tr>
                        <td>Guidelines for Ellipse User:</td>
                        <td colSpan="6">
                            <div>1&#41; Required Finish Date = Report Date + Priority Timescale.</div>
                            <div>2&#41; Work entered into Ellipse MUST be entered in as Miles and Yards &#40;1ch = 22yds&#41;.</div>
                            <div>
                                3&#41; Review any changes in the Priority and update Ellipse accordingly completing the audit requirements and the person
                                authorising the changes.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Ellipse Updated by:</td>
                        <td colSpan="2"></td>
                        <td className="rightAlign">Date:</td>
                        <td colSpan="3"></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr className="patrolDefectsPrinted__Aivr">
                        <span>Report completed with</span>
                        <img
                            src={aivrLogo}
                            crossOrigin={"anonymous"}
                        />
                    </tr>
                </tbody>
            </table>

            <div className="patrolDefectsPrinted__KeyContainer">
                <FontAwesomeIcon
                    className="Icon"
                    icon={faUserEdit}
                />
                = Row edited or created by supervisor
            </div>
        </div>
    );
};

export default PatrolTablePrinted;
