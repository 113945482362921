import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faSearchMinus, faSearchPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Draggable from "react-draggable";
import DraggableBar from "./inspectionNavigation/DraggableBar";

const ResizeContext = createContext([null, null]);

export const useResizeContext = () => {
    const [resizeFunction, currentDimensions] = useContext(ResizeContext);

    if (resizeFunction === null) {
        throw new Error("resizeFunction is null, resizeFunction: ");
    }

    return [resizeFunction, currentDimensions];
};

const DraggableWrapper = ({
    windowDimensions,
    content,
    title,
    canEnlarge,
    customDimensions,
    customDimensionsRef,
    closeAction,
    customLocation,
    isVerticalNav,
    extraStyles,
    isResizable,
    Children,
    limits,
    x = 5,
    y = 11,
}) => {
    const sourceRectRef = useRef();
    const dispatch = useDispatch();

    const [draggablePositionState, setDraggablePositionState] = useState(null);
    const [windowEnlarged, setWindowEnlarged] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);
    const [dynamicStyle, setDynamicStyle] = useState({});

    const saveDraggablePosition = (data) => {
        setDraggablePositionState({ x: data.x, y: data.y });
    };

    const keepInBounds = useCallback(() => {
        let newPositionObj = { x: draggablePositionState.x, y: draggablePositionState.y };
        const sourceRect = sourceRectRef.current.getBoundingClientRect();

        if (windowDimensions && sourceRect.right > windowDimensions.width) {
            newPositionObj.x = windowDimensions.width - sourceRect.width;
        }

        if (windowDimensions && sourceRect.bottom > windowDimensions.bottom) {
            newPositionObj.y = windowDimensions.height - sourceRect.height;
        }

        return newPositionObj;
    }, [windowDimensions, draggablePositionState]);

    useEffect(() => {
        let xPos = x;
        let yPos = y;

        if (customLocation && customLocation.x) {
            xPos = customLocation.x;
        }
        if (customLocation && customLocation.y) {
            yPos = customLocation.y;
        }

        if (windowDimensions && windowDimensions.width !== 0 && windowDimensions.height !== 0) {
            if (!isInitialised) {
                setDraggablePositionState({ x: (windowDimensions.width / 100) * xPos, y: (windowDimensions.height / 100) * yPos });
                setIsInitialised(true);
            }
        }
    }, [windowDimensions, keepInBounds, dispatch, customLocation, isInitialised]);

    const onDragEvent = (e, data) => {
        setDraggablePositionState({ x: data.x, y: data.y });
    };

    const setWindowSize = () => {
        setWindowEnlarged(!windowEnlarged);
    };

    useEffect(() => {
        if (draggablePositionState && draggablePositionState.x.length && draggablePositionState.y.length) {
            const newPos = keepInBounds();
            setDraggablePositionState(newPos);
        }
    }, [windowEnlarged, keepInBounds]);

    const handleClose = useCallback(() => {
        closeAction();
    }, [closeAction]);

    let closeButton = useMemo(() => {
        return (
            <>
                <button
                    className="inspectRail__Source__Controls__Remove"
                    onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </>
        );
    }, [handleClose]);

    useLayoutEffect(() => {
        let styleObj = {};

        if (!customDimensions) {
            if (windowEnlarged) {
                styleObj = { width: 600, height: "22.5vw" };
            } else {
                styleObj = { width: 600, height: "17.75vw" };
            }
        } else {
            styleObj = { width: customDimensions.width, height: customDimensions.height };
        }

        styleObj = {
            ...styleObj,
            ...extraStyles,
        };

        setDynamicStyle(styleObj);
    }, [customDimensions, extraStyles, windowEnlarged]);

    const handleResize = useCallback((styleChangeCallback /*(previousState)=>void */) => {
        setDynamicStyle(styleChangeCallback);
    }, []);

    return (
        <ResizeContext.Provider value={[handleResize, customDimensionsRef]}>
            <Draggable
                handle="#DragHandle"
                enableUserSelectHack={false}
                onStop={(event, data) => saveDraggablePosition(data)}
                position={draggablePositionState}
                onDrag={(event, data) => onDragEvent(event, data)}
                bounds="parent"
                disabled={false}
                draggable={true}>
                <div
                    className="draggableWrapper"
                    ref={sourceRectRef}
                    style={dynamicStyle}>
                    {isResizable && (
                        <DraggableBar
                            bottom={0}
                            right={0}
                            height="12px"
                            width="12px"
                            corner="bottomRight"
                            handleResize={handleResize}
                            limits={limits}
                            customDimensionsRef={customDimensionsRef}
                        />
                    )}
                    <div className={`draggableWrapper__Top ${isVerticalNav ? "alignRight" : ""}`}>
                        {title && title}
                        <div className="draggableWrapper__Controls">
                            <span
                                id="DragHandle"
                                className="drag-handle">
                                <FontAwesomeIcon icon={faArrowsAlt} />
                            </span>
                            {canEnlarge && (
                                <span
                                    className="size-button"
                                    onClick={setWindowSize}>
                                    <FontAwesomeIcon icon={windowEnlarged ? faSearchMinus : faSearchPlus} />
                                </span>
                            )}
                            {closeAction ? closeButton : null}
                        </div>
                    </div>
                    {content ?? <Children />}
                </div>
            </Draggable>
        </ResizeContext.Provider>
    );
};

export default DraggableWrapper;
