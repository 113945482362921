import { combineReducers } from "redux";
import _ from "lodash";
import {
    EXIT_CURRENT_DASHBOARD,
    SELECT_ASSET,
    RECEIVE_ASSET_OBSERVATIONS,
    SELECT_OBSERVATION,
    SET_ASSET_DATE_FILTER,
    SET_ASSET_STATUS_FILTER,
    SET_ASSET_CLASS_FILTER,
    SET_ASSET_PRIORITY_FILTER,
    SET_ASSET_SEARCH_QUERY_FILTER,
    CLEAR_REQUESTED_ASSET,
    SET_GROUND_TRUTH_FILTER,
    RECEIVE_ML_ASSET_GROUPS,
    REQUEST_ASSET,
    SET_ASSET_GROUP_ID,
    RECEIVE_ASSET_IDS,
    RECEIVE_ASSET_DATA,
    RECEIVE_ASSET_LOCATIONS,
} from "redux/actions";

function groups(state = [], action) {
    if (action.type === RECEIVE_ML_ASSET_GROUPS) {
        state = action.groups;
    }

    return state;
}

function selectedAsset(state = null, action) {
    if (action.type === SELECT_ASSET) {
        state = action.assetID;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

const initialObservationsState = {
    external_observations: [],
    observations: [],
};

function observations(state = initialObservationsState, action) {
    if (action.type === RECEIVE_ASSET_OBSERVATIONS) {
        state = action.observations;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = initialObservationsState;
    }
    return state;
}

function selectedObservationID(state = null, action) {
    if (action.type === SELECT_OBSERVATION) {
        state = action.observationID;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = null;
    }
    return state;
}

function dateFilter(state = { from: null, to: null }, action) {
    if (action.type === SET_ASSET_DATE_FILTER) {
        state = action.dates;
    }
    return state;
}

function statusFilter(state = "all", action) {
    if (action.type === SET_ASSET_STATUS_FILTER) {
        state = action.status;
    }
    return state;
}

function searchQueryFilter(state = "", action) {
    if (action.type === SET_ASSET_SEARCH_QUERY_FILTER) {
        state = action.searchQuery;
    }
    return state;
}

function priorityFilter(state = "all", action) {
    if (action.type === SET_ASSET_PRIORITY_FILTER) {
        state = action.priority;
    }
    return state;
}

function requestedObservation(state = null, action) {
    if (action.type === REQUEST_ASSET && action.observationID) {
        state = action.observationID;
    }

    if (action.type === CLEAR_REQUESTED_ASSET) {
        state = null;
    }

    return state;
}

function requestedAsset(state = null, action) {
    if (action.type === REQUEST_ASSET) {
        state = action.assetID;
    }

    if (action.type === CLEAR_REQUESTED_ASSET) {
        state = null;
    }

    return state;
}

function groundTruthFilter(state = null, action) {
    if (action.type === SET_GROUND_TRUTH_FILTER) {
        state = action.filter;
    }

    return state;
}

function assetGroupFilter(state = "", action) {
    if (action.type === SET_ASSET_GROUP_ID) {
        state = action.assetGroupID;
    }

    if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = "";
    }
    return state;
}

function assetIds(state = [], action) {
    if (action.type === RECEIVE_ASSET_IDS) {
        state = action.assets;
    }
    return state;
}

function assetData(state = {}, action) {
    if (action.type === RECEIVE_ASSET_DATA) {
        let newState = _.clone(state);
        state = {
            ...newState,
            ...action.assets,
        };
    } else if (action.type === EXIT_CURRENT_DASHBOARD) {
        state = {};
    }

    return state;
}

const selected = combineReducers({
    asset: selectedAsset,
    observation: selectedObservationID,
});

const requested = combineReducers({
    observation: requestedObservation,
    asset: requestedAsset,
});

const mlAssetFilters = combineReducers({
    date: dateFilter,
    status: statusFilter,
    priority: priorityFilter,
    searchQuery: searchQueryFilter,
    groundTruth: groundTruthFilter,
    group: assetGroupFilter,
});

const mlAssets = combineReducers({
    selected,
    observations,
    filters: mlAssetFilters,
    requested,
    groups,
    ids: assetIds,
    assetData,
});

export default mlAssets;
