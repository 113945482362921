import _ from "lodash";

const imageHeight = 62.5;

export default class RailInspectionConverter {
    constructor(railInfo) {
        this.railImages = railInfo.railImages;
        this.leafletTileOffset = railInfo.leafletTileOffset;
        this.railImageConfig = railInfo.railImageConfig;
        this.horizontal = railInfo.horizontal;
        this.sessionIsBackward = railInfo.sessionIsBackward;
        this.patrol = railInfo.patrol;
        this.flipped = railInfo.flipped;
    }

    sourceTimestampToCoordinates = (imageTimestamp, imageSource, coordinates) => {
        if (!this.railImageConfig || _.isEmpty(this.railImageConfig)) {
            return [0, 0];
        }
        let imageIndex = _.findIndex(this.railImages, { timestamp: imageTimestamp });
        if (imageIndex < 0) {
            return null;
        }

        let imagesConfig = this.railImageConfig.inspection_images;
        if (_.findIndex(imagesConfig, { showInModal: true }) > -1) {
            imagesConfig = imagesConfig.filter((imageConfig) => !imageConfig.showInModal);
        }

        const latBase = imageIndex * imageHeight - this.leafletTileOffset * imageHeight;

        let xOffset = 0;

        if (this.horizontal && this.sessionIsBackward && this.flipped) {
            imagesConfig = _.clone(imagesConfig);
            imagesConfig.reverse();
            imageSource = imagesConfig.length - 1 - imageSource;
        }

        for (let i = 0; i < Object.keys(imagesConfig).length; i++) {
            const item = imagesConfig[i];
            if (imageSource > i) {
                xOffset += item.display_width;
            }
        }

        let offsetParam;
        if (this.sessionIsBackward) {
            offsetParam = "offset_y_backward";
        } else {
            offsetParam = "offset_y_forward";
        }

        let latOffset = (_.get(imagesConfig[imageSource], offsetParam, 0) * 1000) / 16;

        if (this.horizontal && this.flipped) {
            latOffset *= -1;
        }

        let markerLat = latBase + (coordinates[0] / 1000) * imageHeight + latOffset;
        let markerLng = (coordinates[1] + xOffset) / 16;

        if (!this.horizontal) {
            if (this.sessionIsBackward && !this.patrol) {
                markerLat *= -1;
            } else {
                markerLat -= imageHeight;
            }
        } else {
            if (this.sessionIsBackward) {
                markerLat = -markerLat + imageHeight;
            }

            let oldLat = markerLat;
            markerLat = markerLng * -1;
            markerLng = oldLat;
        }

        return [markerLat, markerLng];
    };

    clickToImageData = (lat, lng) => {
        let xOffset = 0;
        let yOffset = 0;
        let xShift = 0;
        let source = null;

        let imagesConfig = this.railImageConfig.inspection_images;
        if (_.findIndex(imagesConfig, { showInModal: true }) > -1) {
            imagesConfig = imagesConfig.filter((imageConfig) => !imageConfig.showInModal);
        }

        if (this.horizontal) {
            let _lat = lat * -1;

            if (this.flipped) {
                imagesConfig = _.clone(imagesConfig);
                imagesConfig.reverse();
            }
            _.each(imagesConfig, (inspectionImage, index) => {
                if (_lat >= yOffset && _lat <= yOffset + inspectionImage.display_width) {
                    source = index;
                    return false; // brake out from the loop
                }
                yOffset += inspectionImage.display_width;
            });
        } else {
            for (let i = 0; i < Object.keys(imagesConfig).length; i++) {
                const item = imagesConfig[i];
                if (lng >= xOffset && lng < xOffset + item.display_width) {
                    source = i;
                    lng -= xOffset;
                    break;
                }
                xOffset += item.display_width;
            }
        }

        if (source === null) {
            return null;
        }

        const item = imagesConfig[source];

        let offsetParam;
        if (this.sessionIsBackward) {
            offsetParam = "offset_y_backward";
        } else {
            offsetParam = "offset_y_forward";
        }

        let indexClicked;

        if (this.horizontal) {
            if (this.flipped) {
                source = imagesConfig.length - 1 - source;
                const imageWidth = _.get(imagesConfig, [source, "display_width"], 0);
                lat = -(imageWidth + 2 * yOffset + lat);
            }

            if (this.sessionIsBackward) {
                lng *= -1;
                lng += 1000;
            }

            if (this.sessionIsBackward !== this.flipped) {
                xOffset = Math.ceil(item.offset_y_backward * -1 || 0);
                xShift = (item.offset_y_backward % 1 || 0) * -1000;
            } else {
                xOffset = Math.floor(item.offset_y_forward * -1 || 0);
                xShift = (item.offset_y_forward % 1 || 0) * -1000;
            }

            indexClicked = Math.floor(lng / 1000) + xOffset + this.leafletTileOffset;

            const oldLat = lat;
            lat = lng;
            lng = -oldLat;
        } else {
            const latOffset = _.get(imagesConfig[source], offsetParam, 0);

            let offset = latOffset * 1000;
            if (this.sessionIsBackward) {
                if (this.patrol) {
                    lat = lat + 1000 - offset;
                } else {
                    lat = (lat + offset) * -1;
                }
            } else {
                offset *= -1;
                lat = lat + offset + 1000;
            }
            indexClicked = Math.floor(lat / 1000) + this.leafletTileOffset;
        }

        if (indexClicked < 0 || indexClicked >= this.railImages.length) {
            return null;
        }
        let imageLat = lat % 1000;

        if (imageLat < 0) {
            imageLat = 1000 + imageLat;
        }

        const coordinates = [imageLat + xShift, lng - yOffset];
        const imageClicked = this.railImages[indexClicked];

        return { imageClicked, source, coordinates };
    };
}
