import React, { memo } from "react";
import { connect } from "react-redux";
import {
    fetchUserSketches,
    fetchUserAnnotations,
    fetchUserMeasurements,
    getAllMarkerConditionTypes,
    removeFavouriteCategory,
    getSessionList,
    getAllSessionTags,
    receiveLoginFilters,
    setSessionFavourites,
    setSessionFlagged,
    setSessionTagFilter,
    setSessionDateFilter,
    setSessionSearch,
    setHideLowQualitySessions,
    fetchDetectionTypes,
    getObjectObservationDataTypes,
} from "redux/actions/index";
import _ from "lodash";
import moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import memoizeOne from "memoize-one";
import { Button, Modal, Tooltip, Select } from "antd";
import OBCSpinner from "./util/OBC";
import SessionsTags from "../components/SessionsTags";
import { FixedSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { withRouter } from "react-router-dom";
import SessionComponent from "./SessionListItem";
import OBCButton from "./OBC/OBCButton";
import SessionsSearch from "./session/SessionsSearch";
import SessionListFilterPill from "./session/SessionListFilterPill";
import { CalendarOutlined, SyncOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;
const { confirm } = Modal;

const SESSION_ITEM_HEIGHT = 75;
class SessionsComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            displayMarkupExport: false,
            datePickerOpen: false,
            sessionFavouritesDropdownOpen: false,
        };

        this.searchRef = React.createRef();
        this.tagsRef = React.createRef();
        this.sessionList = React.createRef();
        this.reqAbortRef = React.createRef();
    }

    handleDayClick = (day) => {
        const currentRange = {
            from: new Date(this.props.dateFilter.from * 1000),
            to: new Date(this.props.dateFilter.to * 1000),
        };
        const range = DayPicker.DateUtils.addDayToRange(day, currentRange);
        if (!this.props.dateFilter.to) {
            range.to = day;
            range.from = day;
        }
        const newDateFilter = {
            to: moment(range.to).endOf("day").unix(),
            from: moment(range.from).startOf("day").unix(),
        };
        this.props.dispatch(setSessionDateFilter(newDateFilter));
    };

    onSessionListRef = (ref) => {
        this.sessionList.current = ref;

        if (this.props.viewedRouteID) {
            const elemIndex = this.props.sessionIDs.indexOf(this.props.viewedRouteID);
            if (elemIndex !== -1 && this.sessionList.current) {
                this.sessionList.current.scrollToItem(elemIndex, "center");
            }
        }
    };

    componentDidMount() {
        this.props.dispatch(getAllMarkerConditionTypes());
        if (!_.isEmpty(this.props.loginDataFilters)) {
            this.props.history.replace({
                state: { loginDataFilters: this.props.loginDataFilters },
            });
        } else if (this.props.location.state && this.props.location.state.loginDataFilters) {
            this.props.dispatch(receiveLoginFilters(this.props.location.state.loginDataFilters));
            this.props.history.replace({
                state: { loginDataFilters: this.props.location.state.loginDataFilters },
            });
        }

        if (_.isEmpty(this.props.sessionsIDs)) {
            this.props.dispatch(getSessionList());
            this.props.dispatch(getAllSessionTags());
            this.props.dispatch(fetchDetectionTypes());
        }

        if (_.isEmpty(this.props.objectObservationDataTypes)) {
            this.props.dispatch(getObjectObservationDataTypes());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.viewedRouteID !== prevProps.viewedRouteID) {
            const elemIndex = this.props.sessionIDs.indexOf(this.props.viewedRouteID);
            if (elemIndex !== -1 && this.sessionList.current) {
                console.log("this.sessionList.current", this.sessionList.current);
                this.sessionList.current.scrollToItem(elemIndex, "center");
            }
        }
        if (this.props.hideLowQualitySessions !== prevProps.hideLowQualitySessions) {
            this.props.dispatch(getSessionList());
        }
    }

    sessionListResize = () => {
        const elemIndex = this.props.sessionIDs.indexOf(this.props.viewedRouteID);
        if (elemIndex !== -1 && this.sessionList.current) {
            this.sessionList.current.scrollToItem(elemIndex, "center");
        }
    };

    refreshSessions = () => {
        if (!this.props.refreshingList) {
            setTimeout(() => {
                this.props.dispatch(getSessionList());
                if (this.props.viewedRouteID) {
                    this.props.dispatch(fetchUserSketches(this.props.viewedRouteID));
                    this.props.dispatch(fetchUserMeasurements(this.props.viewedRouteID));
                    this.props.dispatch(fetchUserAnnotations(this.props.viewedRouteID));
                }
            }, 100);
        }
    };

    toggleMarkupExport = () => {
        this.setState({
            displayMarkupExport: !this.state.displayMarkupExport,
        });
    };

    showDeleteConfirm = () => {
        confirm({
            title: "Permanently remove category?",
            content: (
                <>
                    <div>Deleting this list will erase all saved sessions in it and the category name.</div>
                    <div>Do you want to proceed?</div>
                </>
            ),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                this.props.dispatch(removeFavouriteCategory(this.props.favouriteCategory));
            },
        });
    };

    renderDatePicker = (dateFrom, dateTo, modifiers) => {
        return (
            <>
                <DayPicker
                    className="Selectable"
                    selectedDays={[dateFrom, { from: dateFrom, to: dateTo }]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                />
                <div className="DatePickerButtons">
                    <OBCButton
                        className="ButtonReset"
                        disabled={this.props.dateFilter.from ? false : true}
                        onClick={() => this.props.dispatch(setSessionDateFilter({ from: null, to: null }))}>
                        Reset
                    </OBCButton>
                    <OBCButton
                        className="ButtonOk"
                        variant="secondary"
                        onClick={() => this.setState({ datePickerOpen: false })}>
                        Close
                    </OBCButton>
                </div>
            </>
        );
    };

    canRemoveFavouriteCategory = () => {
        const categoryIndex = _.findIndex(this.props.favouriteCategories, { id: this.props.favouriteCategory });
        return categoryIndex >= 0 ? _.get(this.props.favouriteCategories[categoryIndex], "is_user_category", false) : false;
    };

    sharedFavouriteCategoryBy = () => {
        let shareByText = null;
        const categoryIndex = _.findIndex(this.props.favouriteCategories, { id: this.props.favouriteCategory });
        if (!_.get(this.props.favouriteCategories[categoryIndex], "is_user_category", false)) {
            const ownerName = _.get(this.props.favouriteCategories[categoryIndex], "owner_name", false);
            if (ownerName) {
                shareByText = (
                    <span className="sessionFavouritesContainerSharedByText">
                        Shared by: <strong>{ownerName}</strong>
                    </span>
                );
            }
        }

        return shareByText;
    };

    render() {
        const sessions = this.props.sessionIDs;
        const GUTTER_SIZE = 8;
        const dateFrom = new Date(this.props.dateFilter.from * 1000);
        const dateTo = new Date(this.props.dateFilter.to * 1000);
        const modifiers = { start: dateFrom, end: dateTo };
        const Row = memo(({ index, style }) => {
            const sessionID = sessions[index];
            return (
                <SessionComponent
                    style={{
                        ...style,
                        left: style.left,
                        top: index === 0 ? style.top : style.top + GUTTER_SIZE,
                        width: style.width,
                        height: index === 0 ? style.height : style.height - GUTTER_SIZE,
                    }}
                    key={sessionID}
                    viewing={sessionID === this.props.viewedRouteID}
                    sessionID={sessionID}
                />
            );
        }, areEqual);

        let sessionContent = (
            <AutoSizer
                className="sessionAutosizer"
                onResize={this.sessionListResize}>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height + GUTTER_SIZE}
                        width={width}
                        itemSize={SESSION_ITEM_HEIGHT}
                        ref={this.onSessionListRef}
                        itemCount={this.props.sessionIDs.length}>
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer>
        );

        if (!this.props.sessionIDs.length && !this.props.refreshingList) {
            if (_.get(this.props.sessionFilters, ["force_filters"], false)) {
                sessionContent = <h1 className="NoSessionText">No sessions found matching the requested filters</h1>;
            } else {
                sessionContent = <h1 className="NoSessionText">No sessions found</h1>;
            }
        }

        if (this.props.refreshingList) {
            sessionContent = (
                <div className="SessionSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        return (
            <div
                className="SessionList"
                id="intro-tour-session-list">
                <div className="sessionFilterContainer">
                    <div
                        id="intro-tour-session-filter-fav"
                        style={{ display: "inline-flex" }}>
                        {!["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                            <Tooltip
                                overlayClassName="IssueTabDatePicker"
                                overlayStyle={{ opacity: 1 }}
                                onVisibleChange={(val) => this.setState({ datePickerOpen: val })}
                                title={this.renderDatePicker(dateFrom, dateTo, modifiers)}
                                visible={this.state.datePickerOpen}
                                placement="bottomLeft"
                                trigger="click">
                                <div
                                    className="sessionFilterItem FilterItemDatePicker"
                                    style={{ background: "#414664" }}>
                                    <OBCButton
                                        size="lg"
                                        active={this.state.datePickerOpen}
                                        variant={this.props.dateFilter.from ? "primary" : "ghost"}
                                        onClick={() => {
                                            this.setState({
                                                datePickerOpen: !this.state.datePickerOpen,
                                            });
                                        }}
                                        aria-label="Calendar">
                                        <CalendarOutlined
                                            className="icon"
                                            aria-hidden="true"
                                        />
                                    </OBCButton>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip title="Refresh sessions">
                            <div
                                className="sessionFilterItem"
                                style={{ background: "#414664" }}>
                                <OBCButton
                                    size="lg"
                                    variant="ghost"
                                    aria-label="Refresh"
                                    onClick={this.refreshSessions}>
                                    <SyncOutlined
                                        spin={this.props.refreshingList}
                                        className="icon"
                                        aria-hidden="true"
                                    />
                                </OBCButton>
                            </div>
                        </Tooltip>
                        <SessionsTags ref={this.tagsRef} />
                        <Tooltip title="Show favourites only">
                            <div
                                className="sessionFilterItem"
                                style={{ background: "#414664" }}>
                                <OBCButton
                                    size="lg"
                                    variant={this.props.favouriteCategory ? "primary" : "ghost"}
                                    aria-label="Favourites"
                                    onClick={() => this.props.dispatch(setSessionFavourites(!this.props.favouriteCategory))}>
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={faStar}
                                    />
                                </OBCButton>
                            </div>
                        </Tooltip>
                        {(this.props.userConfig.super_admin || this.props.userConfig.beta || this.props.dashboard?.is_beta) && (
                            <>
                                <Tooltip title="Show flagged sessions">
                                    <div
                                        className="sessionFilterItem"
                                        style={{ background: "#414664" }}>
                                        <OBCButton
                                            size="lg"
                                            variant={this.props.flaggedFilter ? "primary" : "ghost"}
                                            aria-label="Flagged"
                                            onClick={() => this.props.dispatch(setSessionFlagged(!this.props.flaggedFilter))}>
                                            <FontAwesomeIcon
                                                className="icon"
                                                icon={faFlag}
                                            />
                                        </OBCButton>
                                    </div>
                                </Tooltip>

                                {/* make sure we don't display Auto Quality button in widget - (below more as an reminder to include widget exception when released from beta testers...) */}
                                {!["widget_layout", "minimal_layout"].includes(this.props.workspaceLayout) && (
                                    <Tooltip title={this.props.hideLowQualitySessions ? "Show all sessions" : "Auto Quality Control"}>
                                        <div
                                            className="sessionFilterItem"
                                            style={{ background: "#414664" }}>
                                            <OBCButton
                                                size="lg"
                                                variant={this.props.hideLowQualitySessions ? "primary" : "ghost"}
                                                aria-label="Flagged"
                                                onClick={() => {
                                                    this.props.dispatch(setHideLowQualitySessions(!this.props.hideLowQualitySessions));
                                                    this.props.dispatch(getSessionList());
                                                }}>
                                                <FontAwesomeIcon
                                                    className={"icon"}
                                                    icon={faCheckCircle}
                                                />
                                            </OBCButton>
                                            <div className="BetaTag">Beta</div>
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                    <SessionsSearch searchID="MainSessionSearch" />
                </div>
                {this.props.favouriteCategory && this.props.favouriteCategories.length > 0 && (
                    <div className="sessionFavouritesContainer">
                        <Select
                            defaultValue={0}
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={this.props.favouriteCategory}
                            onDropdownVisibleChange={(open) => this.setState({ sessionFavouritesDropdownOpen: open })}
                            onChange={(val) => {
                                this.props.dispatch(setSessionFavourites(val));
                            }}>
                            {_.map(this.props.groupedFavourites, (favGroup, name) => (
                                <OptGroup label={name}>
                                    {_.map(favGroup, (category) => (
                                        <Option
                                            key={category.id}
                                            value={category.id}>
                                            <Tooltip
                                                mouseEnterDelay={0.5}
                                                placement="right"
                                                title={
                                                    this.state.sessionFavouritesDropdownOpen && category.owner_name && name !== "Mine"
                                                        ? `Owner: ${category.owner_name}` || null
                                                        : null
                                                }>
                                                {category.name}
                                            </Tooltip>
                                        </Option>
                                    ))}
                                </OptGroup>
                            ))}
                        </Select>
                        {this.canRemoveFavouriteCategory() && (
                            <Button
                                className="removeCategoryButton"
                                size="small"
                                onClick={() => this.showDeleteConfirm()}>
                                Remove List
                            </Button>
                        )}
                        {this.sharedFavouriteCategoryBy()}
                    </div>
                )}
                <div className="sessionListFilterPill">
                    <SessionListFilterPill />
                </div>
                <div
                    className="sessionListContainer"
                    id="ScrollList">
                    {sessionContent}
                </div>
            </div>
        );
    }
}

const orderSessions = memoizeOne((sessions) => _.orderBy(sessions, ["first_seen"], ["desc"]));

const groupFavourites = memoizeOne((categories) => {
    let groupedFavourites = {
        Mine: [
            { id: true, name: "All My Favourites" },
            { id: "general", name: "General" },
        ],
        "Shared with me": [],
    };
    _.map(categories, (favourite) => {
        if (favourite.id !== -1) {
            if (favourite.is_user_category) {
                groupedFavourites["Mine"] = [...groupedFavourites["Mine"], favourite];
            } else {
                groupedFavourites["Shared with me"] = [...groupedFavourites["Shared with me"], favourite];
            }
        }
    });

    return groupedFavourites;
});

const mapStateToProps = (state) => {
    const sessions = orderSessions(state.sessions);
    const dashboardID = state.userDetails.dashboardAccessID;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === dashboardID);
    const favouriteCategories = state.userDetails.favouriteCategories;
    const groupedFavourites = groupFavourites(favouriteCategories);

    return {
        sessions,
        sessionsData: state.sessions,
        sessionPagination: state.sessionPagination,
        viewedRouteID: parseInt(state.playlist.data.routeID),
        dateRange: state.sessionFilters.dateRange,
        accessToken: state.access_token,
        sessionIDs: state.sessionList,
        userConfig: state.userDetails.userConfig,
        loginDataFilters: state.loginDataFilters,
        sessionFilters: state.userDetails.sessionConfig,
        favouriteCategories,
        groupedFavourites,
        favouriteCategory: state.sessionListFilters.favourites,
        flaggedFilter: state.sessionListFilters.show_flagged,
        dateFilter: state.sessionListFilters.date,
        refreshingList: state.sessionListFilters.refreshing,
        workspaceLayout: currentDashboard?.workspace_layout,
        hideLowQualitySessions: state.sessionListFilters.filter_qa_tags,
        dashboard: currentDashboard,
        objectObservationDataTypes: state.objectObservationDataTypes,
    };
};

export default connect(mapStateToProps)(withRouter(SessionsComponent));
