import React from "react";
import logo from "../../images/obc-logo-hybrid.svg";
import "../../aivr.scss";
import TermsText from "./TermsText";

const TermsPage = () => {
    return (
        <div className="TermsComponent">
            <div className="TermsComponentContainer">
                <div className="TermsComponentHeader">
                    <div className="TermsComponentHeaderLeft">
                        <img
                            className="TermsComponentLogo"
                            src={logo}
                            alt=""
                        />
                        <h3 className="TermsComponentLogoText">OneBigCircle</h3>
                    </div>
                    <div className="TermsComponentHeaderRight">
                        <h3 className="TermsComponentHeaderText">AIVR Terms of Use</h3>
                        <h3 className="TermsComponentHeaderText">COMMERCIAL IN CONFIDENCE</h3>
                    </div>
                </div>
                <h1 className="TermsComponentTitle">AIVR Terms of Use</h1>
                <div className="TermsComponentContent">
                    <TermsText />
                </div>
            </div>
        </div>
    );
};

export default TermsPage;
