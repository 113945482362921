import React, { useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import _ from "lodash";
import { getEndCoordinate, getStartCoordinate, latLonToMeters, locationsAreEqual } from "../../../util/Geometry";
import { useSelector, useStore } from "react-redux";
import { get_source_calibration, getAbsoluteTimestamp } from "../../../util/PlaylistUtils";
import * as THREE from "three";

const videoDataSelector = (state) => {
    const playlistData = state.playlist.data;
    const sourceIndex = state.playlist.position.sourceIndex;
    return _.get(playlistData, ["video", sourceIndex], null);
};

const useSnappedSelector = (state) => state.snappedRoute || false;
const currentPositionSelector = (state) => state.playlist.position.coords;
const groundClearanceModeSelector = (state) => _.get(state.playlist.overlays, ["Ground Clearance", "mode"], "left");
const groundClearanceWidthSelector = (state) => _.get(state.playlist.overlays, ["Ground Clearance", "width"], 3000) / 1000;
const currentSessionTagsSelector = (state) => _.get(state.sessions[state.playlist.data.routeID], "tags", []);
const currentSessionIDSelector = (state) => state.playlist.data.routeID;

const calibrationSelector = (state) => {
    let defaultRailSeparation = 1.435;
    if (state.dashboards) {
        // this could be null, depending on timings?
        const dashboardIndex = _.findIndex(state.dashboards, (dashboard) => dashboard.access_id === state.userDetails.dashboardAccessID);
        defaultRailSeparation = state.dashboards[dashboardIndex].config.measurements_rail_gauge / 1000;
    }
    return get_source_calibration(
        state.measurement.calibration,
        state.playlist.position.sourceIndex,
        getAbsoluteTimestamp(state.playlist) * 1000,
        true,
        defaultRailSeparation,
    );
};

const vertexShader = `
      varying float Opacity;
      attribute float opacity;
    
      void main() {
        Opacity = float(opacity);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;

const groundClearanceShader = `
    varying float Opacity;
    void main() {
        vec3 blue = vec3(0.0, 0.0, 1.0);
        gl_FragColor = vec4(blue, pow(Opacity, 0.5) * min(0.5, 1.0 - (gl_FragCoord.z / gl_FragCoord.w)/25.0));
    }`;

function triangulate(triangle_strip) {
    let ccw = false;

    let coords = [];
    let indices = [];

    for (let i = 0; i < triangle_strip.length; i++) {
        triangle_strip[i].forEach((c) => coords.push(c));
    }

    for (let i = 2; i < triangle_strip.length; i++) {
        const p0 = i - 2;
        const p1 = i - 1;
        const p2 = i;

        if (ccw) {
            indices.push(p1, p0, p2);
        } else {
            indices.push(p0, p1, p2);
        }

        ccw = !ccw;
    }

    return { coords, indices };
}

function convertToGeometry(coordinates, opacity = null) {
    const { coords, indices } = triangulate(coordinates);

    return (
        <bufferGeometry attach={"geometry"}>
            <bufferAttribute
                attachObject={["attributes", "position"]}
                array={new Float32Array(coords)}
                count={coords.length / 3}
                itemSize={3}
            />
            <bufferAttribute
                attach="index"
                array={new Uint32Array(indices)}
                count={indices.length}
                itemSize={1}
            />
            {opacity !== null ? (
                <bufferAttribute
                    attachObject={["attributes", "opacity"]}
                    array={new Float32Array(opacity)}
                    count={opacity.length}
                    itemSize={1}
                />
            ) : null}
        </bufferGeometry>
    );
}

function ThreeDGroundClearance() {
    // Trigger updates on redux position state changes for this component only
    const store = useStore();

    const ref = useRef();
    const currentPositionRef = useRef(currentPositionSelector(store.getState()));

    const videoData = useSelector(videoDataSelector);
    const useSnapped = useSelector(useSnappedSelector);
    const mode = useSelector(groundClearanceModeSelector);
    const groundClearanceWidth = useSelector(groundClearanceWidthSelector);
    const calibration = useSelector(calibrationSelector);
    const currentSessionTags = useSelector(currentSessionTagsSelector);
    const currentSessionID = useSelector(currentSessionIDSelector);

    const { sessionHasDirectionTag, sessionIsForward } = useMemo(() => {
        const sessionHasDirectionTag = _.includes(currentSessionTags, "Forward") || _.includes(currentSessionTags, "Backward");
        const sessionIsForward = _.includes(currentSessionTags, "Forward");
        return { sessionHasDirectionTag, sessionIsForward };
    }, [currentSessionTags]);

    let showLeft = (sessionIsForward ? mode === "left" : mode === "right") || mode === "both" ? true : false;
    let showRight = (sessionIsForward ? mode === "right" : mode === "left") || mode === "both" ? true : false;

    const railSeparation = useMemo(() => calibration.railSeparation, [calibration]);

    const { routeGeometry, zeroPoint } = useMemo(() => {
        console.log("Generating Ground Clearance geometry...");

        const routeGeometry = [];
        let lastEndCoordinate = null;
        let zeroPoint = null;

        videoData.forEach((playlistItem) => {
            const startCoordinate = getStartCoordinate(playlistItem, useSnapped);
            const endCoordinate = getEndCoordinate(playlistItem, useSnapped);

            if (zeroPoint === null) {
                zeroPoint = startCoordinate;
            }

            if (lastEndCoordinate && !locationsAreEqual(startCoordinate, lastEndCoordinate)) {
                routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
            }
            if (startCoordinate) {
                routeGeometry.push(latLonToMeters(zeroPoint, startCoordinate));
            }
            lastEndCoordinate = endCoordinate;
        });
        if (lastEndCoordinate) {
            routeGeometry.push(latLonToMeters(zeroPoint, lastEndCoordinate));
        }

        return { routeGeometry, zeroPoint };
    }, [videoData, useSnapped]);

    const { leftGroundClearanceZoneCoords, rightGroundClearanceZoneCoords } = useMemo(() => {
        const halfRailWidth = railSeparation / 2 + 0.01;

        const leftGroundClearanceZoneCoords = [];
        const rightGroundClearanceZoneCoords = [];

        if (routeGeometry.length < 2) {
            //do nothing
        } else {
            for (let i = 0; i < routeGeometry.length; i++) {
                let o0, o1, p;
                if (i === 0) {
                    o0 = p = routeGeometry[i];
                    o1 = routeGeometry[i + 1];
                } else {
                    o0 = routeGeometry[i - 1];
                    o1 = p = routeGeometry[i];
                }
                const angle = Math.atan2(o1[1] - o0[1], o1[0] - o0[0]);
                const sinAngle = Math.sin(angle);
                const cosAngle = Math.cos(angle);
                const railXOffset = cosAngle * halfRailWidth;
                const railZOffset = -sinAngle * halfRailWidth;
                const redZoneXOffset = cosAngle * (halfRailWidth + groundClearanceWidth);
                const redZoneZOffset = -sinAngle * (halfRailWidth + groundClearanceWidth);

                if (i === 0) {
                    const startX = sinAngle * 25;
                    const startZ = cosAngle * 25;

                    leftGroundClearanceZoneCoords.push([-p[1] - redZoneXOffset + startX, 0, -p[0] - redZoneZOffset + startZ]);
                    leftGroundClearanceZoneCoords.push([-p[1] - railXOffset + startX, 0, -p[0] - railZOffset + startZ]);
                    rightGroundClearanceZoneCoords.push([-p[1] + railXOffset + startX, 0, -p[0] + railZOffset + startZ]);
                    rightGroundClearanceZoneCoords.push([-p[1] + redZoneXOffset + startX, 0, -p[0] + redZoneZOffset + startZ]);
                }

                leftGroundClearanceZoneCoords.push([-p[1] - redZoneXOffset, 0, -p[0] - redZoneZOffset]);
                leftGroundClearanceZoneCoords.push([-p[1] - railXOffset, 0, -p[0] - railZOffset]);
                rightGroundClearanceZoneCoords.push([-p[1] + railXOffset, 0, -p[0] + railZOffset]);
                rightGroundClearanceZoneCoords.push([-p[1] + redZoneXOffset, 0, -p[0] + redZoneZOffset]);

                if (i === routeGeometry.length - 1) {
                    const endX = -sinAngle * 25;
                    const endZ = -cosAngle * 25;

                    leftGroundClearanceZoneCoords.push([-p[1] - redZoneXOffset + endX, 0, -p[0] - redZoneZOffset + endZ]);
                    leftGroundClearanceZoneCoords.push([-p[1] - railXOffset + endX, 0, -p[0] - railZOffset + endZ]);
                    rightGroundClearanceZoneCoords.push([-p[1] + railXOffset + endX, 0, -p[0] + railZOffset + endZ]);
                    rightGroundClearanceZoneCoords.push([-p[1] + redZoneXOffset + endX, 0, -p[0] + redZoneZOffset + endZ]);
                }
            }
        }
        return { leftGroundClearanceZoneCoords, rightGroundClearanceZoneCoords };
    }, [routeGeometry, railSeparation, groundClearanceWidth]);

    useEffect(() => {
        return store.subscribe(() => {
            const currentPosition = currentPositionSelector(store.getState());
            if (!locationsAreEqual(currentPosition, currentPositionRef.current)) {
                currentPositionRef.current = currentPosition;
            }
        });
    }, [store]);

    useFrame(() => {
        if (currentPositionRef.current) {
            const positionOffset = latLonToMeters(zeroPoint, currentPositionRef.current);

            if (ref.current) {
                ref.current.position.set(positionOffset[1], 0, positionOffset[0]);
            }
        }
    });

    const leftGroundClearanceGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < leftGroundClearanceZoneCoords.length; i += 2) {
            opacities.push(1.0);
            opacities.push(0.0);
        }
        return convertToGeometry(leftGroundClearanceZoneCoords, opacities);
    }, [leftGroundClearanceZoneCoords]);

    const rightGroundClearanceGeometry = useMemo(() => {
        const opacities = [];
        for (let i = 0; i < rightGroundClearanceZoneCoords.length; i += 2) {
            opacities.push(0.0);
            opacities.push(1.0);
        }
        return convertToGeometry(rightGroundClearanceZoneCoords, opacities);
    }, [rightGroundClearanceZoneCoords]);

    return (
        <group ref={ref}>
            {showLeft && sessionHasDirectionTag && (
                <>
                    {/* we are passing currentSessionID to avoid problems with overlay not being drawn on session change */}
                    <mesh key={`left-blue-${currentSessionID}-${groundClearanceWidth}`}>
                        {leftGroundClearanceGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={groundClearanceShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                </>
            )}
            {showRight && sessionHasDirectionTag && (
                <>
                    <mesh key={`right-blue-${currentSessionID}-${groundClearanceWidth}`}>
                        {rightGroundClearanceGeometry}
                        <shaderMaterial
                            attach="material"
                            vertexShader={vertexShader}
                            fragmentShader={groundClearanceShader}
                            side={THREE.DoubleSide}
                            transparent
                        />
                    </mesh>
                </>
            )}
        </group>
    );
}

export default ThreeDGroundClearance;
