import React, { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { accessTokenLogin, handleRequestedContent, logout, resetUserOrganisationPrefix, shareLinkDetails } from "../redux/actions/index";

const accessTokenSelector = (state) => state.access_token;
const userEmailSelector = (state) => state.userDetails.email;
const logoutSelector = (state) => state.logout;
const shareLinkSelector = (state) => state.shareLink;
const userOrganisationPrefixSelector = (state) => state.userOrganisationPrefix;
const patrolAccessSelector = (state) => {
    const userDetails = state.userDetails;
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    const patrols_enabled_on_workspace = _.get(currentDashboard, ["config", "patrols_enabled"], false);
    const user_has_patrol_access = _.get(userDetails.userConfig, ["patrol_access"], false);
    return patrols_enabled_on_workspace === 2 || (user_has_patrol_access && patrols_enabled_on_workspace);
};
const inspectionAccessSelector = (state) => {
    const currentDashboard = _.find(state.dashboards, (dash) => dash.access_token === state.access_token);
    return _.get(currentDashboard, ["config", "rail_inspection_enabled"], false);
};

const AuthedRoute = (props) => {
    const accessToken = useSelector(accessTokenSelector);
    const email = useSelector(userEmailSelector);
    const reduxLogout = useSelector(logoutSelector);
    const shareLink = useSelector(shareLinkSelector);
    const userOrganisationPrefix = useSelector(userOrganisationPrefixSelector);
    const hasPatrolAccess = useSelector(patrolAccessSelector);
    const inspectionAccess = useSelector(inspectionAccessSelector);

    const dispatch = useDispatch();

    const windowRefreshing = useRef(false);

    const targetResourceDetails = useCallback(() => {
        let target_resource_data = {};
        if (props.location.state && props.location.state.hasOwnProperty("target_resource_data")) {
            target_resource_data = props.location.state.target_resource_data;
            props.history.replace({ state: {} });
        } else if (props.location.pathname.includes("rail-inspection")) {
            const { sessionID, ts } = props.computedMatch.params;
            dispatch(
                shareLinkDetails({
                    session_id: sessionID,
                    timestamp: ts || 0,
                    inspection: true,
                }),
            );
            return {
                id: sessionID,
                type: "session",
            };
        }
        return target_resource_data;
    }, [props.history, props.location.state, dispatch]);

    useEffect(() => {
        if (accessToken && !email) {
            let target_resource_data = targetResourceDetails();
            dispatch(
                accessTokenLogin(
                    accessToken,
                    (success) => {
                        if (!success && !windowRefreshing.current) {
                            dispatch(logout());
                            return;
                        } else {
                            dispatch(handleRequestedContent());
                        }
                    },
                    target_resource_data,
                ),
            );
        }
    }, [accessToken, dispatch, email]);

    useEffect(() => {
        if (accessToken && email && props.patrolRoute) {
            if (!hasPatrolAccess) {
                props.history.push("/");
            }
        }
    }, [accessToken, email, hasPatrolAccess, props.history, props.patrol, props.patrolRoute]);

    useEffect(() => {
        if (accessToken && email && props.inspectionRoute) {
            if (!inspectionAccess) {
                props.history.push("/");
            }
        }
    }, [accessToken, email, inspectionAccess, props.history, props.inspectionRoute]);

    useEffect(() => {
        if ((!accessToken && !shareLink) || reduxLogout) {
            dispatch(logout());
            // check if user has organisation prefix, if so redirect to
            // it instead of standard login page
            const forced = reduxLogout === "forced";
            if (userOrganisationPrefix) {
                props.history.push(`/a/${userOrganisationPrefix}`, { forcedLogout: forced });
            } else {
                props.history.push("/login", { forcedLogout: forced });
            }
        }
    }, [accessToken, dispatch, props.history, reduxLogout, shareLink, userOrganisationPrefix]);

    useEffect(() => {
        window.onbeforeunload = () => {
            windowRefreshing.current = true;
        };
    }, []);

    return <Route {...props} />;
};

export default withRouter(AuthedRoute);
