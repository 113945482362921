import React, { useState, useEffect, useCallback } from "react";
import { Select, Modal, Button, Input, notification, Tooltip } from "antd";
import "./QATab.scss";
import { faStarHalfAlt, faFlag, faTrashAlt, faCheckCircle, faHourglassHalf, faEdit, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurationModal from "components/CurationModal";
import { convertToTimezone } from "../util/TimezoneUtils";
import { useSelector, useDispatch } from "react-redux";
import {
    getSessionData,
    updateSession,
    routeHighlighted,
    changeToolMode,
    railInspectionExited,
    resetStillImageAdjustments,
    goToBounds,
    customAudit,
    routeSelected,
    submitCuration,
    archiveSession,
    flagSession,
} from "redux/actions/index";
import moment from "moment";
import FavoriteButton from "../session/FavoriteButton";
import Tippy from "@tippyjs/react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const { Option } = Select;

const QATabItem = ({ sessionId, getNewSessions, getFlaggedSessions, currentTab, userSearch, style }) => {
    const userSettingsSelector = (state) => state.userDetails.userConfig;
    const sessionPlayingSelector = (state) => state.playlist.data;

    const [displayCuration, setDisplayCuration] = useState(false);
    const [displayFlagModal, setDisplayFlagModal] = useState(false);
    const [displayEditName, setDisplayEditName] = useState(false);
    const [sessionName, setSessionName] = useState("");
    const [flagComment, setFlagComment] = useState("");
    const [inputError, setInputError] = useState({
        message: "",
        save_disabled: true,
    });
    const [currentSession, setCurrentSession] = useState({});
    const [sessionIdCopyText, setSessionIdCopyText] = useState("Click to copy");
    const [sessionIdHovered, setSessionIdHovered] = useState(false);
    const [acceptCuration, setAcceptCuration] = useState({
        curation_months: 36,
        qa_checked: true,
    });
    const [archived, setArchived] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [highlightSession, setHighlightSession] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [displaySession, setDisplaySession] = useState(true);
    const [displayQaPolicy, setDisplayQaPolicy] = useState(false);

    const userSettings = useSelector(userSettingsSelector);
    const sessionPlayingId = useSelector(sessionPlayingSelector);

    const dispatch = useDispatch();

    const getSessionDetails = useCallback(() => {
        dispatch(getSessionData(sessionId, true))
            .then((response) => {
                setCurrentSession(response);
                setIsLoading(false);
            })
            .catch((error) => setIsLoading(true));
    }, [dispatch, sessionId, setCurrentSession, setIsLoading]);

    useEffect(() => {
        getSessionDetails();
    }, [getSessionDetails]);

    useEffect(() => {
        if (sessionPlayingId.hasOwnProperty("routeID")) {
            sessionPlayingId.routeID === sessionId ? setHighlightSession(true) : setHighlightSession(false);
        }
    }, [sessionPlayingId]);

    useEffect(() => {
        if (currentSession.hasOwnProperty("curation")) {
            setAcceptCuration({
                ...acceptCuration,
                curation_months: currentSession.curation.hasOwnProperty("curation_months")
                    ? currentSession.curation.curation_months > 36
                        ? 36
                        : currentSession.curation.curation_months
                    : 36,
            });
        }
    }, [currentSession]);

    useEffect(() => {
        filterSession();
    }, [userSearch]);

    const filterSession = () => {
        if (userSearch !== undefined) {
            if (
                sessionId.toString().slice(0, userSearch.length) !== userSearch &&
                currentSession.route_name.slice(0, userSearch.length).toLowerCase() !== userSearch.toLowerCase()
            ) {
                setDisplaySession(false);
            } else {
                setDisplaySession(true);
            }
        } else {
            setDisplaySession(true);
        }
    };

    const check8k = (arr) => {
        let output = undefined;
        if (Array.isArray(arr)) {
            arr.forEach((stream) => {
                let label = _.get(stream, ["info", "label"], "");
                if (label.toLowerCase().includes("8k")) {
                    output = true;
                }
            });
        }
        return output;
    };

    const selectSession = () => {
        if (sessionIdHovered) {
            return;
        }
        if (new Date().getTime() / 1000 - currentSession.first_seen >= 300) {
            dispatch(
                customAudit("session_list_click", {
                    selected_session: currentSession.id,
                }),
            );
            dispatch(goToBounds(currentSession.bounds));
            dispatch(routeSelected(currentSession.id, 0, undefined, check8k(currentSession.stream_info)));
            dispatch(routeHighlighted(null));
            dispatch(changeToolMode(null));
            dispatch(railInspectionExited());
            dispatch(resetStillImageAdjustments());
        }
    };

    const startTime = new Date(currentSession["first_seen"] * 1000);
    const formatDate = convertToTimezone(startTime, userSettings.convert_to_utc);

    let duration = 0;
    let formatDuration = 0;
    if (currentSession["length_seconds"] > 0) {
        duration = moment.duration(currentSession["length_seconds"] * 1000);
        if (duration._data.hours === 0 && duration._data.minutes === 0 && duration._data.seconds <= 60) {
            formatDuration = "00:01";
        } else {
            formatDuration = moment.utc(duration.as("milliseconds")).format("HH:mm");
        }
    }

    const toggleFlagModal = useCallback(() => {
        setSessionIdHovered(!sessionIdHovered);
        setDisplayFlagModal(!displayFlagModal);
        setModalOpen(!modalOpen);
    }, [displayFlagModal, modalOpen, sessionIdHovered]);

    const toggleCuration = () => {
        setSessionIdHovered(!sessionIdHovered);
        setDisplayCuration(!displayCuration);
        setModalOpen(!modalOpen);
    };

    const toggleEditName = () => {
        setDisplayEditName(!displayEditName);
        setSessionIdHovered(!sessionIdHovered);
        setSessionName(currentSession["route_name"]);
    };

    const validateSessionNameChange = (value) => {
        let format = /[`!@#$%^&*+=[\]{};'"\\|,.<>/?~]/;
        if (!value) {
            setInputError({
                message: "Session name cannot be empty",
                save_disabled: true,
            });
        } else if (value.trim().length < 5) {
            setInputError({
                message: "Session name must be more than 5 characters",
                save_disabled: true,
            });
        } else if (format.test(value)) {
            setInputError({
                message: "Session name cannot include special characters",
                save_disabled: true,
            });
        } else {
            setInputError({ message: "", save_disabled: false });
        }
        setSessionName(value);
    };

    const saveSessionNameChange = () => {
        dispatch(
            updateSession(currentSession.id, sessionName, (success) => {
                if (success) {
                    dispatch(getSessionData(currentSession.id, true)).then((session) => {
                        setCurrentSession(session);
                        setSessionName(_.get(session, ["route_name"], ""));
                    });
                    notification.success({
                        message: "Successfully updated session name",
                    });
                }
                setDisplayEditName(!displayEditName);
            }),
        );
    };

    const handleSessionIdClick = (e, id) => {
        e.preventDefault();
        navigator.clipboard.writeText(id);
        setSessionIdCopyText("Copied!");
    };

    const handleSessionIdHovered = (e) => {
        if (e && sessionIdCopyText === "Copied!") {
            setSessionIdCopyText("Click to copy");
        }
        setSessionIdHovered(e);
    };

    const flagModalFooter = () => {
        let footer = [<Button onClick={toggleFlagModal}>Cancel</Button>];
        if (currentSession.flagged) {
            footer.push(
                <Button
                    type="danger"
                    onClick={flagSelectedSession}>
                    Unflag
                </Button>,
            );
        } else {
            footer.push(
                <Button
                    type="danger"
                    onClick={flagSelectedSession}>
                    Flag
                </Button>,
            );
        }
        return footer;
    };

    const confirmArchive = (e) => {
        e.stopPropagation();
        setModalOpen(!modalOpen);
        Modal.confirm({
            title: !archived ? "Are you sure you would like to archive this session?" : "Are you sure you would like to unarchive this session?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                archiveSelectedSession();
                return;
            },
            onCancel() {
                setModalOpen(false);
                return;
            },
        });
    };

    const confirmAccept = (e) => {
        e.stopPropagation();
        setModalOpen(!modalOpen);
        Modal.confirm({
            title: "Are you sure you would like to accept this session?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                dispatchCuration(acceptCuration, false);
                return;
            },
            onCancel() {
                setModalOpen(false);
                return;
            },
        });
    };

    const dispatchCuration = (curation, toggle) => {
        dispatch(
            submitCuration(currentSession.id, curation, !toggle, (success) => {
                if (success) {
                    notification.success({
                        message: "Success",
                        description: "Curation Saved",
                    });
                    if (!toggle) {
                        getNewSessions();
                        dispatch(routeSelected(null));
                        getFlaggedSessions();
                    } else {
                        toggleCuration();
                        getSessionDetails();
                    }
                }
            }),
        );
    };

    const flagSelectedSession = useCallback(() => {
        dispatch(
            flagSession(currentSession.id, !currentSession.flagged, flagComment, () => {
                setFlagComment("");
                toggleFlagModal();
                getNewSessions();
                getFlaggedSessions();
                getSessionDetails();
            }),
        );
    }, [currentSession, dispatch, flagComment, getFlaggedSessions, getNewSessions, getSessionDetails, toggleFlagModal]);

    const handleKeyPress = useCallback(
        (e) => {
            if (displayFlagModal) {
                if (e.key === "Enter") {
                    flagSelectedSession();
                }
            }
        },
        [displayFlagModal, flagSelectedSession],
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const archiveSelectedSession = () => {
        dispatch(
            archiveSession(currentSession.id, !archived, (success) => {
                if (success) {
                    notification.success({
                        message: archived ? "Successfully removed session from the archive" : "Successfully archived session",
                    });
                    getNewSessions();
                    dispatch(routeSelected(null));
                } else {
                    notification.error({ message: "Error archiving session" });
                }
            }),
        );
        setArchived(!archived);
    };

    const displayQaSessions =
        !isLoading && displaySession ? (
            <div
                className="qaTabItem"
                onClick={selectSession}
                style={highlightSession || modalOpen ? { ...style, border: " 2px solid #5dd65f" } : { ...style, border: "2px solid rgba(0, 0, 0, 0)" }}>
                <Modal
                    title={`Edit Session Name`}
                    visible={displayEditName}
                    style={{ width: "50%" }}
                    onCancel={toggleEditName}
                    footer={[
                        <Button
                            key="close"
                            type="primary"
                            className="cancelBehaviourButton"
                            onClick={toggleEditName}>
                            Cancel
                        </Button>,
                        <Button
                            className="saveBehaviourButton"
                            key="save"
                            type="primary"
                            onClick={saveSessionNameChange}
                            disabled={inputError.save_disabled}>
                            Save
                        </Button>,
                    ]}>
                    <>
                        <input
                            value={sessionName}
                            className="qaTabItem__nameInput"
                            onChange={(e) => validateSessionNameChange(e.target.value)}
                            style={{ width: "100%" }}
                            onFocus={(e) => e.stopPropagation()}
                        />
                        {inputError.save_disabled && <p className="error">{inputError.message}</p>}
                    </>
                </Modal>
                <Modal
                    footer={flagModalFooter()}
                    style={{ width: "50%" }}
                    onCancel={toggleFlagModal}
                    visible={displayFlagModal}>
                    {!currentSession.flagged ? (
                        `Flagging this session will cause it to be hidden from everyone except superusers. This is not the same as archiving the session.`
                    ) : (
                        <>
                            <p>Unflagging this session will make it visible to everyone</p>
                            <p>Reason for flagging: {currentSession.flagged_comment}</p>
                        </>
                    )}
                    {!currentSession.flagged && (
                        <Input.TextArea
                            onChange={(e) => setFlagComment(e.target.value)}
                            value={flagComment}
                            style={{ marginTop: 10 }}
                            placeholder="Reason"
                        />
                    )}
                </Modal>
                <Modal
                    title="QA Policy"
                    visible={displayQaPolicy}
                    footer={null}
                    onCancel={(e) => {
                        e.stopPropagation();
                        setDisplayQaPolicy(false);
                    }}>
                    <p style={{ margin: "0" }}>{currentSession.qa_policy ? JSON.parse(currentSession.qa_policy).notes : "Not set for this device"}</p>
                </Modal>
                {displayCuration && (
                    <CurationModal
                        session={currentSession}
                        curationMonths={acceptCuration.curation_months}
                        closeCuration={toggleCuration}
                        dispatchCuration={dispatchCuration}
                        displayQaAccept={false}
                    />
                )}
                <div className="leftColumn">
                    <Tooltip
                        title={sessionIdCopyText}
                        onVisibleChange={(e) => handleSessionIdHovered(e)}>
                        <span
                            className="qaTabItem__index"
                            onClick={(e) => handleSessionIdClick(e, currentSession.id)}>{`#${currentSession.id}`}</span>
                    </Tooltip>
                    {currentSession["length_seconds"] > 0 && <p className="qaTabItem__time">{formatDuration}</p>}
                </div>
                <div className="rightColumn">
                    <div className="topRow">
                        <div className="topRow__left">
                            <Tippy
                                placement="top"
                                arrow={false}
                                theme="dark"
                                content={currentSession["route_name"]}
                                delay={0}>
                                <h2 className="qaTabItem__title">{currentSession["route_name"]}</h2>
                            </Tippy>

                            <div
                                className="qaTabItem__tools"
                                onClick={(e) => e.stopPropagation()}
                                style={{ marginBottom: "10px" }}>
                                <Tippy
                                    placement="top"
                                    arrow={false}
                                    theme="dark"
                                    content={"Rate session"}
                                    delay={0}>
                                    <div className="">
                                        <FontAwesomeIcon
                                            className="qaTabItem__tool"
                                            icon={faStarHalfAlt}
                                            onClick={toggleCuration}
                                            style={{ marginRight: "10px" }}
                                        />
                                    </div>
                                </Tippy>
                                <Tippy
                                    placement="top"
                                    arrow={false}
                                    theme="dark"
                                    content={"Edit"}
                                    delay={0}>
                                    <div className="">
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            onClick={toggleEditName}
                                            className="qaTabItem__tool"
                                            style={{ marginRight: "10px" }}
                                        />
                                    </div>
                                </Tippy>
                                <Tippy
                                    placement="top"
                                    arrow={false}
                                    theme="dark"
                                    content={"QA Policy"}
                                    delay={0}>
                                    <div className="">
                                        <FontAwesomeIcon
                                            icon={faQuestion}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDisplayQaPolicy(!displayQaPolicy);
                                            }}
                                            className="qaTabItem__tool"
                                        />
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                        <div className="topRow__right">
                            <FavoriteButton
                                sessionID={currentSession.id}
                                placement="bottomLeft"
                                align={{ offset: [-9, 1] }}
                            />
                        </div>
                    </div>
                    <div className="bottomRow">
                        <div className="bottomRow--container">
                            <h4 className="qaTabItem__date">{formatDate}</h4>
                            {currentTab === 3 && (
                                <p className="qaTabItem__flagText">Flagged by: {currentSession.qa_user_email ? currentSession.qa_user_email : "Unknown"}</p>
                            )}
                            {currentTab === 2 || (currentTab === 3 && <p className="qaTabItem__flagText">{currentSession.flagged_comment}</p>)}
                        </div>
                        <div
                            className="qaTabItem__selection"
                            onClick={(e) => e.stopPropagation()}>
                            <Select
                                value={acceptCuration.curation_months}
                                dropdownMatchSelectWidth={false}
                                onChange={(e) => {
                                    setAcceptCuration({ ...acceptCuration, curation_months: e });
                                }}>
                                <Option value={6}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faHourglassHalf}
                                            style={{ marginRight: "5px" }}
                                        />{" "}
                                        6mo
                                    </span>
                                </Option>
                                <Option value={12}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faHourglassHalf}
                                            style={{ marginRight: "5px" }}
                                        />{" "}
                                        1yr
                                    </span>
                                </Option>
                                <Option value={36}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faHourglassHalf}
                                            style={{ marginRight: "5px" }}
                                        />{" "}
                                        3yr
                                    </span>
                                </Option>
                            </Select>
                            <button
                                className="qaTabItem__button qaTabItem__flagButton"
                                onClick={toggleFlagModal}>
                                <FontAwesomeIcon
                                    icon={faFlag}
                                    className={`qaTabItem__flag ${currentSession.flagged ? "qaTabItem__flagged" : "qaTabItem__unflagged"}`}
                                    style={{ marginRight: "5px" }}
                                />{" "}
                                {currentSession.flagged ? "Unflag" : "Flag"}
                            </button>
                            <button
                                className="qaTabItem__button qaTabItem__archiveButton"
                                onClick={confirmArchive}>
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    style={{ marginRight: "5px" }}
                                    className="qaTabItem__archive"
                                />{" "}
                                Archive
                            </button>
                            <button
                                className="qaTabItem__button qaTabItem__accept"
                                onClick={confirmAccept}>
                                <FontAwesomeIcon
                                    className="qaTabItem__checked"
                                    icon={faCheckCircle}
                                    style={{ marginRight: "5px" }}
                                />
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;

    return displayQaSessions;
};

export default QATabItem;
