import React from "react";
import { connect } from "react-redux";
import { Button, Divider, Input, Select, Tooltip } from "antd";
import "antd/dist/antd.css";
import { findAsset, getNearbyAssets, setAssetSearchType, toggleAssetDisplay } from "redux/actions/index";
import _ from "lodash";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import AssetIDHelper from "components/util/AssetIDHelper";

class FindAssetForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            nameInput: "",
            assetTypeSelect: {},
            errorText: "",
            currentMinZoomLevel: 13,
        };
    }

    componentDidMount = () => {
        if (this.props.assets.assetTypes.length) {
            this.setState({
                assetTypeSelect: this.props.assets.assetTypes[0],
            });
        }
    };

    onInputChange = (e) => {
        this.setState({
            nameInput: e.target.value,
        });
    };

    componentDidUpdate = (prevProps) => {
        if (!prevProps.assets.assetTypes.length && this.props.assets.assetTypes.length) {
            this.setState({
                assetTypeSelect: this.props.assets.assetTypes[0],
            });
        }
    };

    searchForAsset = () => {
        const _this = this;
        const isSearchForMLAsset = this.state.assetTypeSelect.ml;
        let formatName;
        if (isSearchForMLAsset) {
            // ML assets all use CSV format, convert input to the same format
            formatName = new AssetIDHelper(_this.state.nameInput).format_search_query();
        } else {
            // imported asset data uses original format, search must match
            formatName = _this.state.nameInput;
        }

        this.props.dispatch(
            findAsset(this.state.assetTypeSelect.id, formatName, this.state.assetTypeSelect.ml, function (assets) {
                if (assets && assets.length) {
                    _this.props.dispatch(toggleAssetDisplay());
                    _this.props.dispatch(setAssetSearchType({ searchType: "specific" }));
                    _this.props.closeModal();
                } else {
                    _this.setState({
                        errorText: "No matching assets found",
                    });
                }
            }),
        );
    };

    findNearby = () => {
        console.log("Searching nearby assets");
        const _this = this;
        this.props.dispatch(
            getNearbyAssets(this.state.assetTypeSelect.id, this.state.assetTypeSelect.ml, function (assets) {
                if (assets && assets.length) {
                    _this.props.dispatch(toggleAssetDisplay());
                    _this.props.dispatch(setAssetSearchType({ searchType: "nearby" }));
                    _this.props.closeModal();
                } else {
                    _this.setState({
                        errorText: "No assets found in area",
                    });
                }
            }),
        );
    };

    renderSelectOptions = () => {
        const aivrOptions = [];
        const externalOptions = [];

        this.props.assets.assetTypes.forEach((type) => {
            if (type.ml) {
                aivrOptions.push(
                    <Select.Option
                        value={`${type.id}-${type.name}`}
                        key={`${type.id}-${type.name}`}
                        asset={type}
                        min_zoom_level={type.min_zoom_level}>
                        {type.name}
                    </Select.Option>,
                );
            } else {
                externalOptions.push(
                    <Select.Option
                        value={`${type.id}-${type.name}`}
                        key={`${type.id}-${type.name}`}
                        asset={type}
                        min_zoom_level={type.min_zoom_level}>
                        {type.name}
                    </Select.Option>,
                );
            }
        });

        return (
            <Select
                onSelect={this.selectAssetType}
                className="assetSelect"
                value={`${this.state.assetTypeSelect.id}-${this.state.assetTypeSelect.name}`}>
                <Select.OptGroup
                    value="aivr asset data"
                    label="AIVR Asset Data">
                    {aivrOptions}
                </Select.OptGroup>
                <Select.OptGroup
                    value="imported data"
                    label="Imported Data">
                    {externalOptions}
                </Select.OptGroup>
            </Select>
        );
    };

    selectAssetType = (_, obj) => {
        console.log("debug asset props", obj.props);
        this.setState({
            assetTypeSelect: obj.props.asset,
            currentMinZoomLevel: obj.props.min_zoom_level ?? 13,
        });
    };

    assetInfoString = () => {
        if (!this.state.assetTypeSelect || !this.state.assetTypeSelect.description) {
            return null;
        } else {
            return `${this.state.assetTypeSelect.description}`;
        }
    };

    render = () => {
        return (
            <div className="assetForm">
                <div className="assetSelectContainer">
                    {this.renderSelectOptions()}
                    <Tippy
                        arrow={true}
                        placement="right"
                        zIndex={1100}
                        delay={100}
                        theme={"aivr"}
                        content={
                            <div
                                className="assetTooltip"
                                style={{ maxWidth: "300px" }}>
                                <p className="assetTooltipText">
                                    AIVR Asset Data is constantly updated, using Machine Learning to identify assets which are matched against existing external
                                    records for verification.
                                </p>
                                <p className="assetTooltipText">Imported data is data imported from any external source</p>
                            </div>
                        }>
                        <div className="assetTooltipContainer">
                            <FontAwesomeIcon
                                className="assetInfoIcon"
                                icon={faInfoCircle}
                            />
                        </div>
                    </Tippy>
                </div>

                <p className="assetInfoString">{this.assetInfoString()}</p>

                <div className="assetSections">
                    <div className="assetSection">
                        <Input
                            onChange={this.onInputChange}
                            className="assetInput"
                            placeholder="Asset Name"
                            value={this.state.nameInput}
                        />
                        <Button
                            onClick={this.searchForAsset}
                            type="primary"
                            className="assetButton"
                            disabled={this.state.nameInput === ""}>
                            Search for asset
                        </Button>
                    </div>

                    <Divider
                        type="vertical"
                        className="assetDivider"
                    />

                    <div className="assetSection">
                        {this.props.mapBounds.zoom < this.state.currentMinZoomLevel ? (
                            <Tooltip title="Zoom in to search nearby assets">
                                <Button
                                    key="find"
                                    className="assetButton"
                                    disabled={true}>
                                    Find Nearby
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                onClick={this.findNearby}
                                key="find"
                                type="primary"
                                className="assetButton">
                                Find Nearby
                            </Button>
                        )}
                    </div>
                </div>
                {this.state.errorText && <p className={"Error"}>{this.state.errorText}</p>}
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    let currentDashboard = _.find(state.dashboards, (dash) => dash.access_id === state.userDetails.dashboardAccessID);

    return {
        assets: state.assets,
        mapBounds: state.sessionFilters.mapBounds,
        dashboardAssetTypes: currentDashboard.config.asset_types,
    };
};

export default connect(mapStateToProps)(FindAssetForm);
