import React from "react";
import moment from "moment";
import { faMapMarked, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { goToBounds } from "redux/actions";
import { customAudit } from "redux/actions/auditActions";
import { useSelector } from "react-redux";
import OBCSpinner from "./util/OBC";

const IMG_URL = "https://cimages.aivr.video/";

const CCTVCameraListItem = ({ camera, setCameraInfo, toggleModal, modalVisible, active, setActive, index, visible }) => {
    const csrfToken = useSelector((state) => state.csrfToken);
    const FULL_URL = IMG_URL + camera.thumbnail_url + "?csrf=" + csrfToken;
    const [thumbnailsLoaded, setThumbnailsLoaded] = React.useState(false);

    const dispatch = useDispatch();
    const calculateHoursAgo = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const thumbnailAge = moment(date).fromNow();

        return thumbnailAge;
    };

    const getRecency = (timestamp) => {
        const date = moment.unix(timestamp);
        const hoursAgo = moment().diff(date, "hours");

        if (hoursAgo < 12) {
            return "recent";
        } else if (hoursAgo >= 12 && hoursAgo <= 24) {
            return "semi-recent";
        } else {
            return "old";
        }
    };

    const handleShowOnMap = (e, index) => {
        e.stopPropagation();
        if (modalVisible) {
            toggleModal();
        }
        setActive(index);
        const boundsObj = {
            north: camera.location[0] + 0.001,
            south: camera.location[0] - 0.001,
            east: camera.location[1] + 0.001,
            west: camera.location[1] - 0.001,
        };
        dispatch(goToBounds(boundsObj));
    };

    return (
        <div
            className={active ? "CCTVListItemContainer active" : "CCTVListItemContainer"}
            onClick={() => {
                setActive(index);
                camera.origin = "navbar";
                dispatch(customAudit("cctv_modal_opened", { modal_location: "navbar", camera_id: camera.camera_id }, "CCTV modal opened from CCTV drawer"));
                setCameraInfo(camera);
                if (!modalVisible) {
                    toggleModal();
                }
            }}>
            <div className={"CCTVListItemStatus " + getRecency(camera.thumbnail_ts)}></div>
            <div className="CCTVListItemThumbnail">
                <img
                    onLoad={() => setThumbnailsLoaded(true)}
                    src={visible ? FULL_URL : ""}
                    alt="CCTV"
                    className={thumbnailsLoaded ? "" : "CCTVLoading"}
                />
                <div className="CCTVCameraListItemExpand">
                    <FontAwesomeIcon icon={faExpand} />
                </div>
                <div className={!thumbnailsLoaded ? "CCTVDrawerImagePlaceholder active" : "CCTVDrawerImagePlaceholder"}>
                    <OBCSpinner size={60} />
                </div>
            </div>
            <div className="CCTVListItemDetails">
                <div className="CCTVListItemTitle">
                    <span>CAMERA NAME</span>
                    {camera.name}
                </div>
                <div className="CCTVListItemGroup">
                    <div className="CCTVListItemTitle">
                        <span>LAST UPDATED</span>
                        {calculateHoursAgo(camera.thumbnail_ts)}
                    </div>
                    <div className="CCTVShowOnMap">
                        <div
                            className="CCTVShowOnMapIcon"
                            onClick={(e) => {
                                handleShowOnMap(e, index);
                            }}>
                            <FontAwesomeIcon icon={faMapMarked} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CCTVCameraListItem;
