import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { routeSelected, getSessionData, routeHighlighted, consolidatedHistoryLookup, customAudit, railInspectionExited } from "../../../redux/actions/index";
import { LazyTippy } from "../../util/LazyTooltips";
import ContentLoader from "react-content-loader";
import { Select, Tooltip } from "antd";
import { calculateBearingText, calculateRouteCoordinatesForLocation } from "../../util/Geometry";
import { getAbsoluteTimestamp } from "../../util/PlaylistUtils";
import memoizeOne from "memoize-one";
import { convertToTimezone } from "../../util/TimezoneUtils";
import OBCSearchInput from "components/OBC/OBCSearchInput";
import OBCSpinner from "components/util/OBC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faArrowRight, faArrowLeft, faCompass, faTags, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import AutoSizer from "react-virtualized-auto-sizer";
import { areEqual, FixedSizeList } from "react-window";
import Highlighter from "react-highlight-words";
import railInspectionImage from "../../../images/rail-inspection-session.png";
import { MEMOIZED_DOMAIN_URL } from "../../util/HostUtils";
import { resetStillImageAdjustments, setHideLowQualitySessions } from "redux/actions/sessionActions";
import OBCButton from "components/OBC/OBCButton";

const userConfigSelector = (state) => state.userDetails.userConfig;
const csrfTokenSelector = (state) => state.csrfToken;
const ICON_DICT = { Day: faSun, Night: faMoon, Forward: faArrowRight, Backward: faArrowLeft, Compass: faCompass };
const GUTTER_SIZE = 8;
const SESSION_ITEM_HEIGHT = 90;

const SessionSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={78}
        viewBox="0 0 100% 78"
        backgroundColor="#5a608a"
        foregroundColor="#4b5175">
        <rect
            x="152"
            y="5"
            rx="3"
            ry="3"
            width="220"
            height="15"
        />
        <rect
            x="152"
            y="28"
            rx="3"
            ry="3"
            width="60"
            height="9"
        />
        <rect
            x="152"
            y="42"
            rx="3"
            ry="3"
            width="130"
            height="12"
        />
        <rect
            x="152"
            y="60"
            rx="3"
            ry="3"
            width="50"
            height="15"
        />
        <rect
            x="206"
            y="60"
            rx="3"
            ry="3"
            width="50"
            height="15"
        />
        <rect
            x="260"
            y="60"
            rx="3"
            ry="3"
            width="150"
            height="15"
        />
        <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="140"
            height="78"
        />
    </ContentLoader>
);

const HistorySessionItem = ({ sessionID, point, currentItem, videoKey, availableTags, refProp, sessionSearch, style }) => {
    const dispatch = useDispatch();
    const [sessionData, setSessionData] = useState({});
    const [sessionIDCopyText, setSessionIDCopyText] = useState("Click to copy");
    const userConfig = useSelector(userConfigSelector);
    const csrfToken = useSelector(csrfTokenSelector);

    useEffect(() => {
        dispatch(getSessionData(sessionID)).then((sessionData) => {
            setSessionData(sessionData);
        });
    }, []);

    const bearingText = useMemo(() => {
        if (point.bearing === null) {
            return "Unknown";
        }

        return calculateBearingText(point.bearing);
    }, [point.bearing]);

    const selectSession = useCallback(() => {
        dispatch(
            customAudit(
                "history_tab_item_selected",
                {
                    sessionID: sessionID,
                    videoKey: point.video_key,
                },
                `History tab item selected - Session #${sessionID}`,
            ),
        );
        dispatch(resetStillImageAdjustments());
        dispatch(routeSelected(sessionID, point.video_key ?? point.timestamp / 1000));
        dispatch(railInspectionExited());
    }, [dispatch, sessionID, point.video_key, point.timestamp]);

    const smallImage = useMemo(() => {
        if (point === null || point === undefined) {
            return null;
        }
        let imageURL = `https://raw${MEMOIZED_DOMAIN_URL}/` + point.frame_url;
        if (csrfToken) {
            imageURL += `?csrf=${csrfToken}`;
        }
        if (!point.frame_url) {
            imageURL = railInspectionImage;
        }
        const imageClasses = ["RouteHistoryListImage"];
        if (point.video_key === videoKey) {
            imageClasses.push("Selected");
        }
        const className = imageClasses.join(" ");
        return (
            <div className="HistoryListImage">
                <img
                    src={imageURL}
                    crossOrigin={"anonymous"}
                    key={point.video_key}
                    className={className}
                    alt=""
                    onClick={selectSession}
                />
            </div>
        );
    }, [csrfToken, point, selectSession, videoKey]);

    const image = useMemo(() => {
        if (point === null || point === undefined) {
            return null;
        }
        let imageURL = `https://raw${MEMOIZED_DOMAIN_URL}/` + point.hq_frame_url;
        if (csrfToken) {
            imageURL += `?csrf=${csrfToken}`;
        }
        const imageClasses = ["RouteHistoryListImage"];
        if (point.video_key === videoKey) {
            imageClasses.push("Selected");
        }
        const className = imageClasses.join(" ");
        return (
            <div className="HistoryListImage">
                <img
                    src={imageURL}
                    crossOrigin={"anonymous"}
                    key={point.video_key}
                    className={className}
                    alt=""
                    onClick={selectSession}
                />
            </div>
        );
    }, [csrfToken, point, selectSession, videoKey]);

    const date = useMemo(() => {
        const dpDate = new Date(point.timestamp);
        return convertToTimezone(dpDate, userConfig.convert_to_utc);
    }, [point.timestamp, userConfig.convert_to_utc]);

    const direction = useMemo(() => {
        let direction = null;
        if (sessionData.tags && sessionData.tags.includes("Forward")) {
            direction = "Forward";
        } else if (sessionData.tags && sessionData.tags.includes("Backward")) {
            direction = "Backward";
        }
        return direction;
    }, [sessionData.tags]);

    const dayOrNight = useMemo(() => {
        return sessionData.tags && sessionData.tags.includes("Day") ? "Day" : "Night";
    }, [sessionData.tags]);

    const sessionTags = useMemo(() => {
        let tags = [];
        tags = _.filter(sessionData.tags, (tag) => {
            return !["Day", "Night", "Backward", "Forward"].includes(tag) && availableTags.includes(tag);
        }).sort();

        if (tags.length > 0) {
            return (
                <div className="Tag TagList">
                    <FontAwesomeIcon
                        style={{ marginRight: "3px" }}
                        icon={faTags}
                    />
                    {_.chain(tags).join(", ").value()}
                </div>
            );
        } else {
            return null;
        }
    }, [sessionData.tags, availableTags]);

    const onMouseOver = () => {
        dispatch(routeHighlighted(sessionID));
    };

    const onMouseOut = () => {
        dispatch(routeHighlighted(null));
    };

    const handleSessionIDClick = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(id);
            setSessionIDCopyText("Copied!");
        }
    };

    const handleSessionIDHovered = (e) => {
        if (e && sessionIDCopyText === "Copied!") {
            setSessionIDCopyText("Click to copy");
        }
    };

    if (!sessionData.route_name) {
        return (
            <div
                style={{
                    ...style,
                    left: style.left + 4,
                    top: style.top + GUTTER_SIZE,
                    width: "98%",
                    height: style.height - GUTTER_SIZE,
                }}
                className="historyListItem"
                ref={refProp}>
                <SessionSkeleton />
            </div>
        );
    }

    return (
        <div
            onMouseEnter={onMouseOver}
            onMouseLeave={onMouseOut}
            key={point.video_key}
            className={"historyListItem" + (currentItem ? " active" : "")}
            onClick={selectSession}
            ref={refProp}
            style={{
                ...style,
                left: style.left + 4,
                top: style.top + GUTTER_SIZE,
                // width: style.width,
                width: "98%",
                height: style.height - GUTTER_SIZE,
            }}>
            {point.frame_url ? (
                <LazyTippy
                    placement="top"
                    arrow={false}
                    theme="dark"
                    className="HistoryResultImageToolTip"
                    content={image}>
                    {smallImage}
                </LazyTippy>
            ) : (
                smallImage
            )}
            <div className="HistoryItemMainContent">
                <div className="HistoryItemRow HistoryItemRowTitle">
                    <Tooltip
                        title={sessionData.route_name}
                        mouseEnterDelay={0.1}
                        mouseLeaveDelay={0}>
                        <Highlighter
                            highlightClassName="ObcTextHighlight"
                            searchWords={sessionSearch.split(" ")}
                            autoEscape={true}
                            textToHighlight={sessionData.route_name}
                        />
                    </Tooltip>
                </div>
                <div className="HistoryItemRow">
                    <Tooltip
                        title={sessionIDCopyText}
                        mouseEnterDelay={0.05}
                        mouseLeaveDelay={0}
                        onVisibleChange={(e) => handleSessionIDHovered(e)}>
                        <span
                            className="Small SessionID"
                            onClick={(e) => handleSessionIDClick(e, sessionID)}>
                            #
                            <Highlighter
                                highlightClassName="ObcTextHighlight"
                                searchWords={sessionSearch.split(" ")}
                                autoEscape={true}
                                textToHighlight={String(sessionID)}
                            />
                        </span>
                    </Tooltip>
                </div>
                <div className="HistoryItemRow">
                    <div className="Date">
                        <span className="Small">{date}</span>
                    </div>
                </div>

                <div className="HistoryItemRow">
                    {dayOrNight && (
                        <div className="Tag">
                            <FontAwesomeIcon
                                icon={ICON_DICT[dayOrNight]}
                                style={{ marginRight: "3px", color: dayOrNight === "Day" ? "#F8C46D" : "#728dd4" }}
                            />
                            <span className="Small">{dayOrNight}</span>
                        </div>
                    )}
                    {direction && (
                        <div className="Tag">
                            <FontAwesomeIcon
                                icon={ICON_DICT[direction]}
                                style={{ marginRight: "4px" }}
                            />
                            <span className="Small">{direction}</span>
                        </div>
                    )}
                    {bearingText && (
                        <div className="Tag">
                            <FontAwesomeIcon
                                icon={ICON_DICT["Compass"]}
                                style={{ marginRight: "4px" }}
                            />
                            <span className="Small">{bearingText}</span>
                        </div>
                    )}
                    {point.sub_position && (
                        <Tooltip title="Track ID">
                            <div className="Tag">
                                <span className="Small">{point.sub_position}</span>
                            </div>
                        </Tooltip>
                    )}
                    {sessionTags}
                </div>
            </div>
        </div>
    );
};

class RouteHistoryList extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            directionFilter: "all",
            trackFilter: "all",
            bearingFilter: "all",
            tagFilter: "all",
            dayFilter: "all",
            yearFilter: "all",
            sessionSearch: "",
            currentItemRef: React.createRef(),
        };

        this.currentItemRef = React.createRef();
    }

    componentDidMount = () => {
        this.scrollToCurrent();
        this.componentDidUpdate({});
        this.props.dispatch(
            customAudit("history_tab_opened", {
                sessionID: this.props.sessionID,
                videoTimestamp: this.props.timestamp,
            }),
        );
    };

    componentDidUpdate(prevProps, prevState) {
        const isRailImages = this.props.railInspectionImageTimestamp ? true : false;
        let isActive = false;

        if (this.props.visible) {
            if (isRailImages) {
                isActive = this.props.railInspectionImageTimestamp;
            } else if (this.props.playlistPosition.playerState === "paused" || !this.props.playlistPosition.playerState) {
                isActive = this.props.timestamp;
            }
        }
        if (
            isActive &&
            ((!isRailImages && this.props.requestedHistoryTimestamp !== this.props.timestamp) ||
                (isRailImages && this.props.requestedHistoryTimestamp * 1000 !== this.props.railInspectionImageTimestamp)) &&
            !this.props.fetchingHistory
        ) {
            const timestamp = this.props.timestamp ? this.props.timestamp : this.props.railInspectionImageTimestamp / 1000;
            const currentLocation = calculateRouteCoordinatesForLocation(timestamp, this.props.routeLocationData, this.props.routeLocationSystemID);
            if (currentLocation) {
                this.props.dispatch(consolidatedHistoryLookup(timestamp, currentLocation, false, this.props.qaFilter));
            }
        }

        if (this.props.consolidatedHistory !== prevProps.consolidatedHistory) {
            const _this = this;
            setTimeout(() => {
                _this.scrollToCurrent();
            }, 500);
        }

        if (prevProps && this.props.qaFilter !== prevProps.qaFilter && isActive) {
            const timestamp = this.props.timestamp ? this.props.timestamp : this.props.railInspectionImageTimestamp / 1000;
            const currentLocation = calculateRouteCoordinatesForLocation(timestamp, this.props.routeLocationData, this.props.routeLocationSystemID);
            if (currentLocation) {
                this.props.dispatch(consolidatedHistoryLookup(timestamp, currentLocation, false, this.props.qaFilter));
            }
        }
    }

    scrollToCurrent = () => {
        if (this.currentItemRef.current) {
            this.currentItemRef.current.scrollIntoView({ block: "center" });
        }
    };

    renderTrackFilterOptions = () => {
        return _.uniq(_.map(this.props.consolidatedHistory, "sub_position"))
            .sort()
            .map((trackID) => {
                return (
                    <Select.Option
                        key={trackID}
                        value={trackID}>
                        {trackID}
                    </Select.Option>
                );
            });
    };

    getBearingOptions = () => {
        return _.uniq(
            this.props.consolidatedHistory.map((session) => {
                return calculateBearingText(session.bearing);
            }),
        ).map((bearing) => {
            return (
                <Select.Option
                    value={bearing}
                    key={bearing}>
                    {bearing}
                </Select.Option>
            );
        });
    };

    renderTagFilterOptions = () => {
        return _.map(this.props.tags, (tag) => {
            if (!["day", "night"].includes(tag.toLowerCase())) {
                return (
                    <Select.Option
                        key={tag}
                        value={tag}>
                        {tag}
                    </Select.Option>
                );
            }
        });
    };

    renderDayNightFilterOptions = () => {
        return _.map(this.props.tags, (tag) => {
            if (["day", "night"].includes(tag.toLowerCase())) {
                return (
                    <Select.Option
                        key={tag}
                        value={tag}>
                        {tag}
                    </Select.Option>
                );
            }
        });
    };

    // each session in the consolidated history array has a timestamp, which can be transformed into a year...
    renderYearFilterOptions = () => {
        const years = _.uniq(
            _.map(this.props.consolidatedHistory, (session) => {
                return new Date(session.timestamp).getFullYear();
            }),
        );
        return _.map(years, (year) => {
            return (
                <Select.Option
                    key={year}
                    value={year}>
                    {year}
                </Select.Option>
            );
        });
    };

    filteredPoints = () => {
        let filteredPoints = this.props.consolidatedHistory.filter((session) => {
            if (this.state.directionFilter !== "all" && !session["session_tags"].includes(this.state.directionFilter)) {
                return false;
            }

            if (this.state.dayFilter !== "all" && !session["session_tags"].includes(this.state.dayFilter)) {
                return false;
            }

            if (this.state.trackFilter !== "all" && this.state.trackFilter !== session.sub_position) {
                return false;
            }

            if (this.state.bearingFilter !== "all" && this.state.bearingFilter !== calculateBearingText(session.bearing)) {
                return false;
            }

            if (this.state.tagFilter !== "all" && !session["session_tags"].includes(this.state.tagFilter)) {
                return false;
            }

            if (this.state.sessionSearch.length !== 0) {
                if (
                    !session.name.toLowerCase().includes(this.state.sessionSearch.toLowerCase()) &&
                    !String(session.session_id).includes(this.state.sessionSearch)
                ) {
                    return false;
                }
            }

            if (this.state.yearFilter !== "all") {
                const sessionDate = new Date(session.timestamp).getFullYear();
                if (this.state.yearFilter !== sessionDate) {
                    return false;
                }
            }

            return true;
        });
        return filteredPoints;
    };

    render() {
        let empty = !this.props.visible || this.props.consolidatedHistory.length === 0;

        let paused = this.props.playlistPosition.playerState === "paused" || !this.props.playlistPosition.playerState;

        if (this.props.autoScrollActive) {
            paused = false;
        }

        let content;

        const isRailImages = this.props.railInspectionImageTimestamp ? true : false;

        if (
            paused &&
            !empty &&
            !this.props.fetchingHistory &&
            (!isRailImages
                ? this.props.requestedHistoryTimestamp === this.props.timestamp
                : this.props.requestedHistoryTimestamp * 1000 === this.props.railInspectionImageTimestamp)
        ) {
            const filteredPoints = this.filteredPoints();

            const Row = memo(({ index, style }) => {
                const point = filteredPoints[index];
                let currentItem = false;
                let ref = null;

                if (
                    this.props.sessionID === point.session_id &&
                    (this.props.currentSourceIndex === point.stream_index || (!this.props.currentSourceIndex && !point.stream_index))
                ) {
                    currentItem = true;
                    ref = this.currentItemRef;
                }

                return (
                    <HistorySessionItem
                        key={`${index}.${point.frame_url}`}
                        sessionID={point.session_id}
                        point={point}
                        currentItem={currentItem}
                        videoKey={this.props.videoKey}
                        availableTags={this.props.tags}
                        refProp={ref}
                        sessionSearch={this.state.sessionSearch}
                        style={style}
                    />
                );
            }, areEqual);

            const isAnyFilterApplied = () => {
                return (
                    this.state.directionFilter !== "all" ||
                    this.state.trackFilter !== "all" ||
                    this.state.bearingFilter !== "all" ||
                    this.state.tagFilter !== "all" ||
                    this.state.dayFilter !== "all" ||
                    this.state.yearFilter !== "all" ||
                    this.state.sessionSearch.length > 0
                );
            };

            if (filteredPoints.length === 0 && isAnyFilterApplied) {
                content = (
                    <div className="InformationPanel">
                        <h2>No route history for selected filters, please adjust filters</h2>
                    </div>
                );
            } else {
                content = (
                    <AutoSizer
                        className="HistoryAutoSizer"
                        onResize={this.sessionListResize}>
                        {({ height, width }) => (
                            <FixedSizeList
                                height={height}
                                width={width}
                                style={{ paddingBottom: 10 }}
                                itemSize={SESSION_ITEM_HEIGHT}
                                ref={this.onSessionListRef}
                                itemCount={filteredPoints.length}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                );
            }
        } else if (!paused) {
            content = (
                <div className="InformationPanel">
                    <h2>Pause the player to view route history</h2>
                </div>
            );
        } else if (!this.props.consolidatedHistory.length && !this.props.fetchingHistory) {
            content = (
                <div className="InformationPanel">
                    <h2>No route history for the current position</h2>
                </div>
            );
        } else {
            content = (
                <div className="LoadingSpinner">
                    <OBCSpinner
                        size={70}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            );
        }

        return (
            <div className="RouteHistoryTab">
                <div className="HistoryListFilterContainer">
                    <div className="LeftCol">
                        <div className="HistoryListFilterItem">
                            <span className="label">Bearing:</span>
                            <Select
                                className="HistoryListFilter"
                                dropdownMatchSelectWidth={false}
                                size="small"
                                value={this.state.bearingFilter}
                                onChange={(value) => this.setState({ bearingFilter: value })}>
                                <Select.Option value="all">All</Select.Option>
                                {this.getBearingOptions()}
                            </Select>
                        </div>

                        <div className="HistoryListFilterItem">
                            <span className="label">Day Time:</span>
                            <Select
                                className="HistoryListFilter"
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={this.state.dayFilter}
                                onChange={(value) => this.setState({ dayFilter: value })}>
                                <Select.Option value="all">Any</Select.Option>
                                {this.renderDayNightFilterOptions()}
                            </Select>
                        </div>

                        <div className="HistoryListFilterItem">
                            <span className="label">Video Direction:</span>
                            <Select
                                className="HistoryListFilter"
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={this.state.directionFilter}
                                onChange={(value) => this.setState({ directionFilter: value })}>
                                <Select.Option value="all">All</Select.Option>
                                <Select.Option value="Forward">Forward</Select.Option>
                                <Select.Option value="Backward">Backward</Select.Option>
                            </Select>
                        </div>

                        <div className="HistoryListFilterItem">
                            <span className="label">Track ID:</span>
                            <Select
                                className="HistoryListFilter"
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={this.state.trackFilter}
                                onChange={(value) => this.setState({ trackFilter: value })}>
                                <Select.Option value="all">All</Select.Option>
                                {this.renderTrackFilterOptions()}
                            </Select>
                        </div>
                        <div className="HistoryListFilterItem">
                            <span className="label">Tags:</span>
                            <Select
                                className="HistoryListFilter"
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={this.state.tagFilter}
                                onChange={(value) => this.setState({ tagFilter: value })}>
                                <Select.Option value="all">All</Select.Option>
                                {this.renderTagFilterOptions()}
                            </Select>
                        </div>
                        <div className="HistoryListFilterItem">
                            <span className="label">Year:</span>
                            <Select
                                className="HistoryListFilter"
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={this.state.yearFilter}
                                onChange={(value) => this.setState({ yearFilter: value })}>
                                <Select.Option value="all">All</Select.Option>
                                {this.renderYearFilterOptions()}
                            </Select>
                        </div>

                        {this.props.userConfig.super_admin && (
                            <div className="HistoryListFilterItem">
                                <span className="label">QA Filter:</span>
                                <Select
                                    className="HistoryListFilter"
                                    size="small"
                                    dropdownMatchSelectWidth={false}
                                    value={this.props.qaFilter ? "filtered" : "all"}
                                    onChange={(value) => this.props.dispatch(setHideLowQualitySessions(value === "filtered"))}>
                                    <Select.Option value="all">All</Select.Option>
                                    <Select.Option value="filtered">QA Filtered</Select.Option>
                                </Select>
                            </div>
                        )}
                    </div>

                    <div className="RightCol">
                        <div
                            className="sessionSearch"
                            id="intro-tour-session-search">
                            <OBCSearchInput
                                value={this.state.sessionSearch}
                                onChange={(value) => this.setState({ sessionSearch: value })}
                                placeholder="Search for a session"
                                allowClear
                            />
                        </div>
                    </div>
                </div>
                <div className="RouteHistoryListContainer">{content}</div>
            </div>
        );
    }
}

const uniqHistory = memoizeOne((history) => {
    return _.orderBy(
        _.uniqWith(history, (item, nextItem) => {
            if (
                item.session_id === nextItem.session_id &&
                item.stream_index === nextItem.stream_index &&
                Math.abs(item.timestamp - nextItem.timestamp) < 60 * 5 * 1000
            ) {
                return true;
            }
        }),
        ["timestamp"],
        ["desc"],
    );
});

const EMPTY_ARRAY = [];

const mapStateToProps = (state) => {
    const sourceIndex = state.playlist.position.sourceIndex;
    const video = _.get(state.playlist.data, ["video", sourceIndex]);
    const currentPlaylistIndex = state.playlist.position.currentIndex;

    const routeID = _.get(state, ["playlist", "data", "routeID"], null);

    const isVideo = state.playlist.position.isVideo;
    const playlist = isVideo ? _.get(state.playlist.data, ["video", sourceIndex], EMPTY_ARRAY) : state.playlist.data.image;
    const index = state.playlist.position.currentIndex;
    const offset = state.playlist.position.currentTimeOffset || 0;
    const timestamp = getAbsoluteTimestamp(routeID, playlist, index, isVideo, offset);

    let videoKey = null;

    if (routeID !== null && state.sessions[routeID]) {
        if (video && video.length && currentPlaylistIndex !== undefined && video[currentPlaylistIndex]) {
            videoKey = video[currentPlaylistIndex][0];
        }
    }

    const consolidatedHistory = uniqHistory(_.get(state.playlist.consolidatedHistory, ["history"], EMPTY_ARRAY));

    let tags = EMPTY_ARRAY;
    if (consolidatedHistory.length > 0) {
        _.forEach(consolidatedHistory, (session) => {
            _.forEach(session.session_tags, (tag) => {
                if (!tags.includes(tag) && !["Backward", "Forward"].includes(tag)) {
                    tags.push(tag);
                }
            });
        });
    }

    return {
        routeLocationData: state.playlist.data.route_locations,
        timestamp,
        videoKey,
        consolidatedHistory: consolidatedHistory,
        requestedHistoryTimestamp: _.get(state.playlist.consolidatedHistory, ["requestedTimestamp"], 0),
        playlistPosition: state.playlist.position,
        sessionIDs: state.sessionList,
        fetchingHistory: _.get(state.playlist.consolidatedHistory, ["fetching"], false),
        currentSourceIndex: sourceIndex,
        tags: tags.sort(),
        railInspectionImageTimestamp: state.railInspection.selectedRailInspectionImage.timestamp,
        sessionID: routeID,
        routeLocationSystemID: state.playlist.data.system_id,
        userConfig: state.userDetails.userConfig,
        autoScrollActive: state.railInspection.autoScrollActive,
        qaFilter: state.sessionListFilters.filter_qa_tags,
    };
};

export default connect(mapStateToProps)(RouteHistoryList);
