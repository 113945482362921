import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatrolPlans } from "redux/actions";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { useHistory } from "react-router-dom";
import { Button, Input, Progress, Select } from "antd";
import RoutePatrolSvg from "./RoutePatrolSvg";
import PatrolPlanCreator from "./PatrolPlanCreator";
import moment from "moment";
import _ from "lodash";

const GUTTER_SIZE = 8;
const SESSION_ITEM_HEIGHT = 100;

const patrolPlansSelector = (state) => state.schemaInterface.patrolPlans;
const superAdminSelector = (state) => state.userDetails.userConfig.super_admin;
const userEmailSelector = (state) => state.userDetails.email;

const PatrolPlanItem = ({ plan, style }) => {
    const history = useHistory();

    const navigate = () => {
        history.push(`/patrol-planner/${plan.id}`, {
            planID: plan.id,
        });
    };

    return (
        <div
            style={style}
            className="PatrolItem"
            onClick={navigate}>
            <div className="PatrolItem__Svg">
                <RoutePatrolSvg
                    planData={plan}
                    preview
                />
            </div>

            <div className="PatrolItem__Info">
                <div style={{ width: "50%" }}>
                    <h3 className="Title">{plan.name}</h3>
                    <h3
                        className="data-collection"
                        style={{ marginBottom: 0 }}>
                        {plan.last_covered_percentage || 0}% covered {plan.last_covered_ts ? moment.unix(plan.last_covered_ts / 1000).fromNow() : ""}{" "}
                        {plan.last_covered_device ? `by ${plan.last_covered_device}` : ""}
                    </h3>
                    <Progress
                        percent={plan.last_covered_percentage || 0}
                        showInfo={false}
                        trailColor={"#26293a"}
                    />
                </div>
            </div>
        </div>
    );
};

const PatrolPlanInterface = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const patrolPlans = useSelector(patrolPlansSelector);
    const superAdmin = useSelector(superAdminSelector);
    const userEmail = useSelector(userEmailSelector);
    const [sortValue, setSortValue] = useState("time");

    const [searchValue, setSearchValue] = useState();

    const [planCreatorOpen, setPlanCreatorVisible] = useState(false);

    const filteredPlans = useMemo(() => {
        const filteredPlans = patrolPlans.filter((plan) => {
            if (searchValue) {
                return plan.name.toLowerCase().includes(searchValue.toLowerCase());
            }
            return true;
        });

        let sortedPlans = filteredPlans;
        if (sortValue === "time") {
            sortedPlans = _.orderBy(filteredPlans, "last_covered_ts", "desc");
        } else if (sortValue === "coverage") {
            sortedPlans = _.orderBy(filteredPlans, "last_covered_percentage", "desc");
        }
        return sortedPlans;
    }, [patrolPlans, searchValue, sortValue]);

    useEffect(() => {
        if (!patrolPlans.length) {
            dispatch(getPatrolPlans());
        }
    }, [dispatch, patrolPlans.length]);

    useEffect(() => {
        if (userEmail && !superAdmin) {
            history.push("/patrols");
        }
    }, [userEmail, superAdmin, history]);

    const Row = ({ index, style }) => {
        const patrol = filteredPlans[index];

        return (
            <PatrolPlanItem
                style={{
                    ...style,
                    left: style.left,
                    top: style.top + GUTTER_SIZE,
                    width: style.width - GUTTER_SIZE,
                    height: style.height - GUTTER_SIZE,
                }}
                plan={patrol}
                index={index}
            />
        );
    };

    return (
        <div className="PatrolPlansContainer">
            <PatrolPlanCreator
                visible={planCreatorOpen}
                closeModal={() => setPlanCreatorVisible(false)}
            />

            <div className="Header">
                <Input
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="Input"
                />

                <Select
                    value={sortValue}
                    onChange={setSortValue}
                    style={{ width: 200 }}>
                    <Select.Option value="time">Sort by last covered</Select.Option>
                    <Select.Option value="coverage">Sort by coverage percent</Select.Option>
                </Select>

                <Button
                    onClick={() => setPlanCreatorVisible(true)}
                    type="primary"
                    className="CreateButton">
                    New Plan
                </Button>
            </div>

            <div className="PlansContainer">
                {filteredPlans.length ? (
                    <AutoSizer className="sessionAutosizer">
                        {({ height, width }) => (
                            <FixedSizeList
                                height={height + GUTTER_SIZE}
                                width={width}
                                itemSize={SESSION_ITEM_HEIGHT}
                                itemCount={filteredPlans.length}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                ) : (
                    <span className="NoPatrolsText">No patrol plans matching filter</span>
                )}
            </div>
        </div>
    );
};

export default PatrolPlanInterface;
