import React, { useEffect, useMemo, useCallback } from "react";
import moment from "moment";
import { Divider, Modal, Tooltip, Carousel, notification } from "antd";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { faDownload, faAngleRight, faAngleLeft, faCheck, faBan } from "@fortawesome/pro-light-svg-icons";
import Highlighter from "react-highlight-words";
import OBCButton from "../../OBC/OBCButton";
import { reviewUploadedMedia, removeUploadedMedia, setSelectedMediaUpload, setSelectedMediaUploadIndex, setPreveiwModalOpen } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import OBCSpinner from "components/util/OBC";
import { goToBounds } from "redux/actions";
import { convertToTimezone } from "components/util/TimezoneUtils";
import ELRMileAndChain from "components/util/ELRMileAndChain";
const { confirm } = Modal;

const isAdminSelector = (state) => state.permissions.admin || false;
const selectedMediaUploadSelector = (state) => state.mediaUploads.selectedMediaUpload;
const previewModalOpenSelector = (state) => state.mediaUploads.previewModalOpen;
const currentBoundsSelector = (state) => state.sessionFilters.mapBounds.current;
const userConfigSelector = (state) => state.userDetails.userConfig;
const displayFormatSelector = (state) => state.userDetails.userConfig.elr_units;

const CarouselImage = ({ url }) => {
    const [loading, setLoading] = useState(true);
    const [displayImage, setDisplayImage] = useState(false);

    useEffect(() => {
        if (!loading) {
            // warkaround to wait for image to be rendered and styled properly before displaying.
            setTimeout(() => {
                setDisplayImage(true);
            }, 300);
        }
    }, [loading]);

    return (
        <>
            {loading && (
                <div className="ImageLoadingAnimation">
                    <OBCSpinner
                        size={90}
                        speed={3}
                        color={"#e8dfff"}
                    />
                </div>
            )}
            <img
                className={`${displayImage ? "visible" : "hidden"}`}
                style={loading ? { display: "none" } : { display: "block" }}
                onLoad={() => setLoading(false)}
                key={"MediaPrevieImg" + url}
                src={url}
                crossOrigin={"anonymous"}
                alt="preveiw"
            />
        </>
    );
};

const MediaTabListItem = ({ index, mediaData, searchString }) => {
    const dispatch = useDispatch();
    const displayFormat = useSelector(displayFormatSelector);
    const isAdmin = useSelector(isAdminSelector);
    const selectedMediaUpload = useSelector(selectedMediaUploadSelector);
    const previewModalOpen = useSelector(previewModalOpenSelector);
    const currentBounds = useSelector(currentBoundsSelector);
    const userConfig = useSelector(userConfigSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [previousBounds, setPreviousBounds] = useState({});
    const [sliderStartIndex, setSliderStartIndex] = useState(null);

    const [sliderRef, setSliderRef] = useState();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        if (selectedMediaUpload === mediaData.id) {
            dispatch(setSelectedMediaUpload(-1));
            dispatch(setSelectedMediaUploadIndex(-1));
            dispatch(goToBounds(previousBounds));
        } else {
            setPreviousBounds(currentBounds);
            dispatch(setSelectedMediaUpload(mediaData.id));
            dispatch(setSelectedMediaUploadIndex(index));
            let north = mediaData.location_X + 0.0015;
            let east = mediaData.location_Y + 0.001;
            let south = mediaData.location_X - 0.0005;
            let west = mediaData.location_Y - 0.001;

            dispatch(
                goToBounds({
                    north,
                    south,
                    east,
                    west,
                }),
            );
        }
    };

    useEffect(() => {
        setIsOpen(selectedMediaUpload === mediaData.id);
    }, [mediaData.id, selectedMediaUpload]);

    const convertTS = (date_ts) => {
        const date = new Date(date_ts * 1000);
        return convertToTimezone(date, userConfig.convert_to_utc);
    };

    const openModal = (e, index) => {
        e.stopPropagation();
        dispatch(setPreveiwModalOpen(true));
        setSliderStartIndex(index);
    };

    const onDownloadMediaClicked = useCallback(() => {
        window.open(mediaData.media_urls[currentSlideIndex]);
    }, [currentSlideIndex, mediaData.media_urls]);

    const goToPrevSlide = useCallback(() => {
        sliderRef.prev();
    }, [sliderRef]);

    const goToNextSlide = useCallback(() => {
        sliderRef.next();
    }, [sliderRef]);

    const goToSlide = (number) => {
        if (sliderRef) {
            sliderRef.goTo(number, true);
        }
    };

    useEffect(() => {
        if (sliderRef && sliderStartIndex) {
            goToSlide(sliderStartIndex);
        }
    }, [sliderRef, sliderStartIndex]);

    const onApproveClicked = (event, uploadId, reviewStatus) => {
        event.stopPropagation();
        if (!reviewStatus) {
            showConfirmReject(uploadId, reviewStatus);
        } else {
            dispatch(
                reviewUploadedMedia(uploadId, reviewStatus, (response) => {
                    if (response.success) {
                        setIsOpen(false);
                        notification.success({
                            message: "Success",
                            description: "Successfully approved",
                            duration: 1,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: "An error occurred while approving media, try again.",
                            duration: 2.5,
                        });
                    }
                }),
            );
        }
    };

    const onRemoveClicked = (event, uploadId) => {
        event.stopPropagation();
        showConfirmRemove(uploadId);
    };

    const showConfirmReject = (uploadId, reviewStatus) => {
        confirm({
            title: "Do you want to reject these uploads?",
            content: (
                <span>
                    When you click Yes button, {mediaData.media_urls.length > 1 ? "all" : ""} <strong>{mediaData.media_urls.length}</strong>{" "}
                    {mediaData.media_urls.length > 1 ? "items" : "item"} will be pernamently removed.
                </span>
            ),
            okText: "Yes",
            cancelText: "No",
            onOk() {
                dispatch(
                    reviewUploadedMedia(uploadId, reviewStatus, (response) => {
                        if (response.success) {
                            setIsOpen(false);
                            notification.success({
                                message: "Success",
                                description: "Successfully rejected",
                                duration: 1,
                            });
                        } else {
                            notification.error({
                                message: "Error",
                                description: "An error occurred while rejecting media, try again.",
                                duration: 2.5,
                            });
                        }
                    }),
                );
            },
        });
    };
    const renderTags = _.map(mediaData.tags, (tags, key) => {
        return (
            <div
                className="tagGroup"
                key={key}>
                <strong>{`${key}: `}</strong>
                {tags.length > 0 ? tags.join(", ") : <span className="grayyed">Not provided</span>}
            </div>
        );
    });

    const showConfirmRemove = (uploadId) => {
        confirm({
            title: "Do you want remove these uploads?",
            content: (
                <span>
                    When you click Yes button, {mediaData.media_urls.length > 1 ? "all" : ""} <strong>{mediaData.media_urls.length}</strong>{" "}
                    {mediaData.media_urls.length > 1 ? "items" : "item"} will be pernamently removed.
                </span>
            ),
            okText: "Yes",
            cancelText: "No",
            onOk() {
                dispatch(
                    removeUploadedMedia(uploadId, (response) => {
                        if (response.success) {
                            setIsOpen(false);
                            notification.success({
                                message: "Success",
                                description: "Successfully removed",
                                duration: 1,
                            });
                        } else {
                            notification.error({
                                message: "Error",
                                description: "An error occurred while removing media, try again.",
                                duration: 2.5,
                            });
                        }
                    }),
                );
            },
        });
    };

    const renderCarousel = useMemo(() => {
        if (previewModalOpen && selectedMediaUpload === mediaData.id) {
            console.log("debug rendering modal...");
            return (
                <Modal
                    className="MediaPreviewModal"
                    title={null}
                    visible={previewModalOpen}
                    key={selectedMediaUpload}
                    onOk={() => onDownloadMediaClicked()}
                    destroyOnClose={true}
                    onCancel={() => {
                        dispatch(setPreveiwModalOpen(false));
                        setSliderStartIndex(0);
                    }}
                    okText="Download"
                    cancelText="Close">
                    <div className="ThumbnailCarousel">
                        {mediaData.media_urls.length > 1 && (
                            <div
                                className="PreviewButton"
                                onClick={() => goToPrevSlide()}>
                                <FontAwesomeIcon
                                    icon={faAngleLeft}
                                    size="3x"
                                />
                            </div>
                        )}
                        <Carousel
                            ref={(ref) => setSliderRef(ref)}
                            style={mediaData.media_urls.length > 1 && { display: "flex", justifyContent: "center" }}
                            autoplay={false}
                            beforeChange={(oldIndex, newIndex) => {
                                if (previewModalOpen) {
                                    setCurrentSlideIndex(newIndex);
                                }
                            }}>
                            {_.map(mediaData.media_urls, (mediaUrl) => (
                                <CarouselImage
                                    key={mediaUrl}
                                    url={mediaUrl}
                                />
                            ))}
                        </Carousel>
                        {mediaData.media_urls.length > 1 && (
                            <div
                                className="NextButton"
                                onClick={() => goToNextSlide()}>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    size="3x"
                                />
                            </div>
                        )}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }, [previewModalOpen, selectedMediaUpload, mediaData.id, mediaData.media_urls, onDownloadMediaClicked, dispatch, goToPrevSlide, goToNextSlide]);

    const convertChainsToMilesAndChains = (chains) => {
        const miles = Math.floor(chains / 80);
        const remainingChains = chains % 80;
        return { miles: miles, chains: remainingChains };
    };

    const renderElr = () => {
        const position = new ELRMileAndChain("ELR Mile & Chain", mediaData.route_id, mediaData.route_position, mediaData.route_subposition_id);

        return (
            <>
                {_.map(position.fields(userConfig.elr_units), (field_name) => (
                    <span
                        key={field_name}
                        className="RouteInfoItem">
                        <b>{field_name}:</b> <span className="secondary">{position.field_value(field_name, [])}</span>
                    </span>
                ))}
            </>
        );
    };

    return (
        <>
            {renderCarousel}

            <div
                className={`MediaListItem ${selectedMediaUpload === mediaData.id && isOpen ? "active" : ""}`}
                onClick={toggleOpen}>
                <div className="MediaItemMainRow">
                    <div className="MediaItemLeftCol">
                        <div className="name">
                            <Highlighter
                                highlightClassName="ObcTextHighlight"
                                searchWords={searchString.split(" ")}
                                autoEscape={true}
                                textToHighlight={mediaData.name}
                            />{" "}
                            {mediaData.status === 0 ? <span className="awaitingReview">{`(Awaiting review)`}</span> : ""}
                        </div>

                        <div className={`description ${selectedMediaUpload === mediaData.id && isOpen ? "open" : ""}`}>
                            <strong>Description:</strong>{" "}
                            {mediaData.description.length > 0 ? (
                                <Highlighter
                                    highlightClassName="ObcTextHighlight"
                                    searchWords={searchString.split(" ")}
                                    autoEscape={true}
                                    textToHighlight={mediaData.description}
                                />
                            ) : (
                                <span className="grayyed">Not provided</span>
                            )}
                        </div>

                        <div className={isOpen ? "displayLayoutOpen" : null}>
                            <div className="tokenName">
                                <strong>Token:</strong>{" "}
                                {mediaData.token_description.length > 0 ? (
                                    <Highlighter
                                        highlightClassName="ObcTextHighlight"
                                        searchWords={searchString.split(" ")}
                                        autoEscape={true}
                                        textToHighlight={mediaData.token_description}
                                    />
                                ) : (
                                    <span className="grayyed">Not provided</span>
                                )}
                            </div>

                            <div className="uploadedDate">
                                {" "}
                                <strong>Uploaded:</strong>{" "}
                                {
                                    <Tooltip title={mediaData.upload_ts ? convertTS(mediaData.upload_ts) : null}>
                                        {mediaData.upload_ts ? (
                                            <span>{moment.unix(mediaData.upload_ts).fromNow()}</span>
                                        ) : (
                                            <span className="grayyed">Unknown</span>
                                        )}
                                    </Tooltip>
                                }
                            </div>

                            {isOpen && (
                                <>
                                    <div className="tokenOwner">
                                        <strong>Token Created by:</strong>{" "}
                                        {<span className={!mediaData.owner_name ? "grayyed" : ""}>{mediaData.owner_name ?? "Unknown"}</span>}
                                    </div>
                                    {renderTags}
                                </>
                            )}
                        </div>
                        {isOpen && (
                            <div className={`Location`}>
                                <span>
                                    <strong>ELR (Estimate): </strong>
                                </span>
                                {mediaData.route_id ? renderElr() : <span className="grayyed">Unknown</span>}
                            </div>
                        )}
                        <div className={`additionalInfo ${selectedMediaUpload === mediaData.id && isOpen ? "open" : ""}`}>
                            <Divider>Uploaded Media</Divider>
                            <div className="MediaGrid">
                                {_.map(mediaData.media_urls, (imageUrl, index) => {
                                    return (
                                        <div
                                            className="GridItem"
                                            key={"MediaUploadImg" + imageUrl}
                                            onClick={(e) => openModal(e, index)}>
                                            <img
                                                src={imageUrl.replace(".jpg", ".thumb.jpg")}
                                                alt="uploaded media"
                                                crossOrigin={"anonymous"}
                                            />
                                            <Tooltip
                                                title="Download"
                                                key={"MediaUploadImgTooltip" + imageUrl}>
                                                <a
                                                    className="downloadButton"
                                                    href={imageUrl}
                                                    download="file"
                                                    onClick={(e) => e.stopPropagation()}>
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        size="1x"
                                                    />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="Footer">
                                {(mediaData.status === 0 || (mediaData.status === 0 && userConfig.super_admin) || (mediaData.status === 0 && isAdmin)) && (
                                    <>
                                        <OBCButton
                                            icon={faCheck}
                                            variant="success"
                                            onClick={(e) => onApproveClicked(e, mediaData.id, true)}>
                                            Approve
                                        </OBCButton>
                                        <OBCButton
                                            icon={faBan}
                                            variant="danger"
                                            onClick={(e) => onApproveClicked(e, mediaData.id, false)}>
                                            Reject
                                        </OBCButton>
                                    </>
                                )}
                                {(mediaData.status === 1 || (mediaData.status === 1 && userConfig.super_admin) || (mediaData.status === 1 && isAdmin)) && (
                                    <OBCButton
                                        icon={faBan}
                                        variant="danger"
                                        onClick={(e) => onRemoveClicked(e, mediaData.id)}>
                                        Remove
                                    </OBCButton>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="MediaItemRightCol">
                        <div className="Preview">
                            <div className="UploadsCounter">{mediaData.media_urls.length}</div>
                            <img
                                src={mediaData.media_urls[0].replace(".jpg", ".thumb.jpg")}
                                alt="Media preview"
                                crossOrigin={"anonymous"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaTabListItem;
