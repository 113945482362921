import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Modal, Input, Checkbox, Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addInspectionBookmark, updateBookmark, deleteBookmark, updatePatrolDefect } from "../../../redux/actions/index";
import _ from "lodash";

const userNameSelector = (state) => state.userDetails.userConfig.name;
const bookmarksSelector = (state) => state.railInspection.bookmarks;
const defaultPrivateSelector = (state) => state.userDetails.userConfig.default_content_privacy;

const LabelCreateWindow = ({
    disabled,
    match,
    coords,
    imageTimestampClicked,
    imageSource,
    labelWindowOpen,
    closeBookmarkWindow,
    updatingID,
    setUpdatingID,
    patrolID,
    isUsersPatrol,
}) => {
    const dispatch = useDispatch();
    let sessionID = match ? parseInt(match.params.sessionID) : null;

    const defaultPrivate = useSelector(defaultPrivateSelector);

    const [description, setDescription] = useState("");
    const [descriptionMaxReached, setDescriptionMaxReached] = useState(false);
    const [isPrivateChecked, setIsPrivateChecked] = useState(defaultPrivate || !!patrolID || false);

    const bookmarks = useSelector(bookmarksSelector);
    const userName = useSelector(userNameSelector);

    const inputRef = useRef();

    useEffect(() => {
        if (updatingID) {
            const item = _.find(bookmarks, ["id", updatingID]);
            if (!item) {
                return;
            }
            setDescription(item.description);
            setIsPrivateChecked(item.private);
        }
    }, [updatingID, bookmarks]);

    const handleOk = useCallback(() => {
        if (description.length) {
            if (updatingID) {
                setUpdatingID();
                dispatch(updateBookmark(updatingID, description, isPrivateChecked));
                dispatch(updatePatrolDefect(updatingID, null, description, !isUsersPatrol));
            } else {
                let bookmarkTypeID = sessionID;
                if (patrolID) {
                    bookmarkTypeID = -1;
                }
                dispatch(addInspectionBookmark(bookmarkTypeID, imageTimestampClicked, imageSource, coords, description, isPrivateChecked, userName, patrolID));
            }
            // setLabelWindowOpen(false);
            closeBookmarkWindow();
            setDescription("");
        }
    }, [
        closeBookmarkWindow,
        setDescription,
        imageTimestampClicked,
        coords,
        description,
        dispatch,
        sessionID,
        isPrivateChecked,
        userName,
        setUpdatingID,
        imageSource,
        patrolID,
        updatingID,
        isUsersPatrol,
    ]);

    const handleCancel = useCallback(() => {
        closeBookmarkWindow();
        setDescription("");
        setUpdatingID(null);
    }, [closeBookmarkWindow, setUpdatingID]);

    const handleInput = (e) => {
        const newValue = e.target.value;
        setDescription(newValue);
        setDescriptionMaxReached(String(newValue).length > 49);
    };

    const handlePrivacyChange = (e) => {
        setIsPrivateChecked(e.target.checked);
    };

    useEffect(() => {
        if (labelWindowOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [labelWindowOpen]);

    const onDelete = useCallback(() => {
        dispatch(deleteBookmark(updatingID));
        closeBookmarkWindow();
        setDescription("");
        setUpdatingID(null);
    }, [dispatch, updatingID, closeBookmarkWindow, setUpdatingID]);

    const footer = useMemo(() => {
        const footer = [
            <Button
                onClick={handleCancel}
                key="cancel">
                Cancel
            </Button>,
        ];
        if (updatingID) {
            footer.push(
                <Button
                    onClick={onDelete}
                    type="danger"
                    key="delete"
                    disabled={!!disabled}>
                    Delete
                </Button>,
            );
        }

        footer.push(
            <Button
                onClick={handleOk}
                type="primary"
                key="save"
                disabled={!description.length || !!disabled}>
                Save
            </Button>,
        );

        return footer;
    }, [handleCancel, updatingID, handleOk, description.length, onDelete, disabled]);

    return (
        <Modal
            title={updatingID ? "Update Bookmark" : "Add Bookmark"}
            visible={labelWindowOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !description.length }}
            footer={footer}>
            <Form.Item
                label="Bookmark description"
                validateStatus={descriptionMaxReached ? "warning" : null}
                help={descriptionMaxReached ? "Maximum 50 characters" : null}>
                <Input
                    placeholder="Max 50 characters"
                    onChange={handleInput}
                    value={description}
                    maxLength={50}
                    onPressEnter={handleOk}
                    ref={inputRef}
                    disabled={!!disabled}
                    autoFocus
                />
            </Form.Item>
            {!patrolID && (
                <div className="inspectRail__BookmarkList__Modal__Options">
                    <Checkbox
                        checked={isPrivateChecked}
                        onChange={handlePrivacyChange}>
                        Private
                    </Checkbox>
                </div>
            )}
        </Modal>
    );
};

export default LabelCreateWindow;
