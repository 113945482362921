import { Popover } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { ReactComponent as TelecomsSvg } from "icons/telecoms.svg";
import { ReactComponent as TelecomsFibreSvg } from "icons/telecoms-fibre.svg";
import { ReactComponent as KonuxSvg } from "icons/konux.svg";
import { ReactComponent as SpeedboardPlain } from "icons/speedboard-plain.svg";
import { ReactComponent as BridgeSvg } from "icons/bridge-plain.svg";
import { ReactComponent as TrafficLightSvg } from "icons/traffic-light.svg";
import { ReactComponent as OhlSvg } from "icons/ohl.svg";

import {
    faEye,
    faLink,
    faSatellite,
    faScrewdriver,
    faExclamation,
    faBarcode,
    faFire,
    faPhone,
    faArchway,
    faKey,
    faBolt,
    faExclamationTriangle,
    faTrafficLight,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const assetIcons = {
    barcode: faBarcode,
    fire: faFire,
    phone: faPhone,
    archway: faArchway,
    key: faKey,
    bolt: faBolt,
    "exclamation-triangle": faExclamationTriangle,
    satellite: faSatellite,
    screwdriver: faScrewdriver,
    exclamation: faExclamation,
    signal: faTrafficLight,
    link: faLink,
    eye: faEye,
};

const mapZoomSelector = (state) => state.sessionFilters.mapBounds.zoom;

const AssetShortcutItem = ({ asset, getAssets }) => {
    const [visible, setVisible] = useState(false);

    const zoom = useSelector(mapZoomSelector);

    const isButtonDisabled = (level) => {
        return level ? zoom < level : zoom < 13;
    };

    const icon = useMemo(() => {
        let icon = _.get(assetIcons, asset.icon_type, false);

        if (!icon) {
            if (asset.icon_type === "telecoms") {
                icon = <TelecomsSvg />;
            } else if (asset.icon_type === "telecoms-fibre") {
                icon = <TelecomsFibreSvg />;
            } else if (asset.icon_type === "konux") {
                icon = <KonuxSvg />;
            } else if (asset.icon_type === "point") {
                icon = <KonuxSvg />;
            } else if (asset.icon_type === "speedboard") {
                icon = <SpeedboardPlain />;
            } else if (asset.icon_type === "ohl") {
                icon = <OhlSvg />;
            } else {
                icon = <TrafficLightSvg />;
            }
        } else {
            icon = (
                <FontAwesomeIcon
                    icon={icon}
                    style={{ pointerEvents: "none" }}
                />
            );
        }

        return icon;
    }, [asset.icon_type]);

    return (
        <Popover
            visible={visible}
            content={
                isButtonDisabled(asset.min_zoom_level) ? "Zoom in to enable this feature: " + asset.name + " Finder" : "Click to find nearby " + asset.name
            }
            placement="bottom"
            trigger="hover"
            mouseLeaveDelay={0}
            key={asset.id}>
            <div
                className={`mapShortcutWidget`}
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                onClick={() => {
                    if (!isButtonDisabled(asset.min_zoom_level)) {
                        getAssets(asset.id, asset.min_zoom_level, asset.ml);
                    }
                }}>
                <button
                    style={{ pointerEvents: "none" }}
                    disabled={isButtonDisabled(asset.min_zoom_level)}>
                    {icon}
                </button>
            </div>
        </Popover>
    );
};

export default AssetShortcutItem;
