import { getSessionData, handleJsonPostError, jsonPostV2 } from "../redux/actions";
import _ from "lodash";
import { notification } from "antd";

export class RailInspectionLoader {
    constructor(notify) {
        this.data = [];
        this.config = { detail_cameras: [], inspection_images: [], video_offsets: {}, imageryIsBackward: false };
        this.imageLocations = {};
        this.next_start = 0;
        this.loaded = false;
        this.percentageComplete = 0;
        this.sessionID = null;
        this.aborted = false;
        this.failed = false;
        this.notify = notify;
        this.imagePackUUID = null;
    }

    abort() {
        console.log("Aborting load operation");
        this.aborted = true;
    }

    load(session_id) {
        return (dispatch, getState) => {
            console.log("Load invoked for session", session_id);

            if (this.sessionID !== session_id || this.failed || this.aborted) {
                this.clear();
                this.sessionID = session_id;

                dispatch(getSessionData(session_id)).then((sessionData) => {
                    const device_uuid = sessionData.device_uuid;
                    const sessionTags = _.get(sessionData, ["tags"], []);
                    const sessionIsBackward = _.indexOf(sessionTags, "Backward") !== -1;

                    let postBody = {
                        action: "rail_inspection_config",
                        session_id: session_id,
                    };

                    let url = "/routeMetadata";
                    jsonPostV2(url, getState(), postBody, dispatch)
                        .then((response) => {
                            if (response) {
                                console.log("Rail inspection config response: ", response);
                                this.config = response;
                                this.config.alignmentOffset = _.get(this.config, ["alignment_offsets", device_uuid], 0);
                                this.config.imageryIsBackward = sessionIsBackward !== this.isCameraAtRear();
                                console.log("Inspection imagery for session", this.sessionID, "is", this.config.imageryIsBackward ? "backward" : "forward");
                                this.notify();
                                if (response.image_pack_uuid) {
                                    this.imagePackUUID = response.image_pack_uuid;
                                }
                                dispatch(this._continue());
                            }
                        })
                        .catch((error) => {
                            handleJsonPostError("Unable to fetch rail inspection config", error);
                            this.failed = true;
                        });
                });
            }
        };
    }

    _continue() {
        return (dispatch, getState) => {
            if (this.aborted) {
                return;
            }

            if (!this.imagePackUUID) {
                this.loaded = true;
                this.percentageComplete = 100;
                this.notify();
                return;
            }

            const _this = this;
            const fileName = `https://imagepack.aivr.video/${this.imagePackUUID}.json`;

            const req = new XMLHttpRequest();
            req.addEventListener("progress", (e) => {
                const progress = (e.loaded / e.total) * 100;
                _this.percentageComplete = progress;
                _this.notify();
            });
            req.open("GET", fileName, true);
            req.onloadend = (e) => {
                console.log("debug e", e);
                if (!_this.failed) {
                    _this.data = JSON.parse(req.responseText);
                    _this.loaded = true;
                    _this.notify();
                }
            };
            req.onerror = () => {
                _this.failed = true;
                _this.loaded = true;
                _this.percentageComplete = 100;
                _this.notify();
                notification.error({
                    message: "Unable to fetch rail inspection images",
                });
            };
            req.send();
        };
    }

    getSessionLocations() {
        return (dispatch, getState) => {
            let postBody = {
                action: "playlist",
                session_id: this.sessionID,
            };

            let url = "/route";

            jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    console.log("debug playlist get response", response);
                    if (response.route_coordinates) {
                        this.imageLocations = response.route_coordinates;
                        this.notify();
                    }
                })
                .catch((error) => {
                    console.log("Error getting route playlist: ", error);
                    this.notify();
                });
        };
    }

    clear() {
        console.log("Clearing existing stored rail data");
        this.data = [];
        this.config = { detail_cameras: [], inspection_images: [], video_offsets: {}, alignment_offsets: {}, imageryIsBackward: false };
        this.next_start = 0;
        this.sessionID = null;
        this.loaded = false;
        this.failed = false;
        this.aborted = false;
        this.percentageComplete = 0;
    }

    isCameraAtRear() {
        return _.get(this.config, ["video_orientation"]) === "Rear";
    }
}
