import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, InputNumber, Select } from "antd";
import { toggleGridVisible, changeGridSize, changeGridOffset, resetGridAdjustments } from "../../../redux/actions/index";

const { Option } = Select;

const gridVisibleSelector = (state) => state.railInspection.gridVisible;
const gridSizeSelector = (state) => state.railInspection.gridSize;
const gridOffsetSelector = (state) => state.railInspection.gridOffset;

const GridAdjustments = () => {
    const dispatch = useDispatch();

    const gridVisible = useSelector(gridVisibleSelector);
    const gridSize = useSelector(gridSizeSelector);
    const gridOffset = useSelector(gridOffsetSelector);

    const toggleGrid = () => {
        dispatch(toggleGridVisible());
    };

    const onSizeChange = (val) => {
        dispatch(changeGridSize(val));
    };

    const onOffsetChange = (direction, value) => {
        dispatch(changeGridOffset(direction, value));
    };

    const handleReset = () => {
        dispatch(resetGridAdjustments());
    };

    return (
        <div className="inspectRail__Adjustments marginTop">
            <div className="inspectRail__Adjustments__Item-Container">
                <h3>Grid</h3>
                <div className="inspectRail__Adjustments__Item">
                    <Checkbox
                        onChange={toggleGrid}
                        checked={gridVisible}>
                        Toggle Grid
                    </Checkbox>
                </div>
                <span>Grid Size</span>
                <div className="inspectRail__Adjustments__Item leftAlign">
                    <Select
                        size="small"
                        value={gridSize}
                        style={{ width: 75 }}
                        onChange={onSizeChange}>
                        <Option value={250}>0.25</Option>
                        <Option value={500}>0.5</Option>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={5}>5</Option>
                        <Option value={10}>10</Option>
                    </Select>
                </div>
                <span>Offsets</span>
                <div className="inspectRail__Adjustments__Item leftAlign">
                    <span>X:</span>
                    <InputNumber
                        size="small"
                        min={-500}
                        max={500}
                        step={10}
                        value={gridOffset.x}
                        onChange={(val) => onOffsetChange("x", val)}
                    />
                    <span>Y:</span>
                    <InputNumber
                        size="small"
                        min={-500}
                        max={500}
                        step={10}
                        value={gridOffset.y}
                        onChange={(val) => onOffsetChange("y", val)}
                    />
                </div>
            </div>
            <div className="inspectRail__Adjustments__Item-Container">
                <button
                    className="inspection-default-button center"
                    onClick={handleReset}>
                    Reset
                </button>
            </div>
        </div>
    );
};

export default GridAdjustments;
