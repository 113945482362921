import L from "leaflet";

import { ReactComponent as SprayCanSvg } from "icons/pins/spray-can.svg";
import { ReactComponent as TreeSvg } from "icons/pins/tree.svg";
import { ReactComponent as TrafficLightSvg } from "icons/pins/traffic-light.svg";
import { ReactComponent as PersonSvg } from "icons/pins/person.svg";
import { ReactComponent as ArchwaySvg } from "icons/pins/archway.svg";
import { ReactComponent as ExclamationTriangleSvg } from "icons/pins/exclamation-triangle.svg";
import { ReactComponent as BaliseSvg } from "icons/pins/balise.svg";
import { ReactComponent as AxleCounterSvg } from "icons/pins/axle-counter.svg";
import { ReactComponent as Model3dSvg } from "icons/pins/3d-model.svg";
import { ReactComponent as KeySvg } from "icons/pins/key.svg";
import { ReactComponent as TrainSvg } from "icons/pins/train.svg";
import { ReactComponent as BoltSvg } from "icons/pins/bolt.svg";
import { ReactComponent as TelecomsSvg } from "icons/pins/telecoms.svg";
import { ReactComponent as TelecomsFibreSvg } from "icons/pins/telecoms-fibre.svg";
import { ReactComponent as BookmarkSvg } from "icons/pins/bookmark-icon.svg";
import { ReactComponent as BallastBagSvg } from "icons/pins/ballast-bag.svg";
import { ReactComponent as BridgeSvg } from "icons/pins/bridge.svg";
import { ReactComponent as LimitedClearanceSvg } from "icons/pins/limited-clearance.svg";
import { ReactComponent as PointSvg } from "icons/pins/point.svg";
import { ReactComponent as ScrapRailSvg } from "icons/pins/scrap-rail.svg";
import { ReactComponent as SpeedBoardSvg } from "icons/pins/speed-board.svg";
import { ReactComponent as TunnelSvg } from "icons/pins/tunnel.svg";
import { ReactComponent as ThermalHotspotSvg } from "icons/pins/thermal-hotspot.svg";
import { ReactComponent as KonuxSvg } from "icons/pins/konux.svg";
import { ReactComponent as MediaUploadSvg } from "icons/pins/media-upload.svg";
import { ReactComponent as MediaUploadActiveSvg } from "icons/pins/media-upload-active.svg";
import { ReactComponent as ZoneEnterSvg } from "icons/pins/zone-enter.svg";
import { ReactComponent as ZoneLeaveSvg } from "icons/pins/zone-leave.svg";
import { ReactComponent as TreatmentOnSvg } from "icons/pins/treatment-on.svg";
import { ReactComponent as TreatmentOffSvg } from "icons/pins/treatment-off.svg";
import { ReactComponent as ExclamationSvg } from "icons/pins/exclamation.svg";
import { ReactComponent as LeafSvg } from "icons/pins/leaf.svg";
import OhlPin from "icons/pins/ohl.js";

import cctvMarker from "icons/cctv-marker.svg";

import ReactDOMServer from "react-dom/server";
import React from "react";

export const MarkerIcon = new L.Icon({
    iconUrl: require("icons/pins/exclamation-triangle.svg"),
    iconRetinaUrl: require("icons/pins/exclamation-triangle.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const createDefaultIcon = (selected) => {
    return new L.Icon({
        iconUrl: require("icons/pins/exclamation-triangle.svg"),
        iconRetinaUrl: require("icons/pins/exclamation-triangle.svg"),
        iconAnchor: new L.Point(20, 40),
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(40, 40),
        className: "div-icon" + (selected ? " selected" : ""),
    });
};

export const SegmentIcon = new L.Icon({
    iconUrl: require("icons/pins/flag-checkered-solid.svg"),
    iconRetinaUrl: require("icons/pins/flag-checkered-solid.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const DatumIcon = new L.Icon({
    iconUrl: require("icons/pins/dot-circle-regular.svg"),
    iconRetinaUrl: require("icons/pins/dot-circle-regular.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const MarkupIcon = new L.Icon({
    iconUrl: require("icons/pins/pencil.svg"),
    iconRetinaUrl: require("icons/pins/pencil.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const AnnotationIcon = new L.Icon({
    iconUrl: require("icons/pins/annotation.svg"),
    iconRetinaUrl: require("icons/pins/annotation.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const MeasureIcon = new L.Icon({
    iconUrl: require("icons/pins/measure.svg"),
    iconRetinaUrl: require("icons/pins/measure.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const DeviceIcon = new L.Icon({
    iconUrl: require("icons/device.svg"),
    iconRetinaUrl: require("icons/device.svg"),
    iconAnchor: new L.Point(11, 27),
    popupAnchor: null,
    iconSize: new L.Point(23, 30),
    shadowAnchor: new L.Point(13, 38),
    shadowSize: new L.Point(41, 41),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export const CCTVMarkerIcon = L.divIcon({
    className: "dummy",
    html: `
        <div class="CCTVMarkerIcon">
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 25px; height: 25px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
    iconSize: [25, 25],
    iconAnchor: [11, 27],
});

export const CCTVMarkerIconRecent = L.divIcon({
    className: "dummy",
    html: `
        <div class="CCTVMarkerIcon">
            <div class="CCTVMarkerStatus recent"></div>
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 25px; height: 25px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
    iconSize: [25, 25],
    iconAnchor: [11, 27],
});

export const CCTVMarkerIconSemiRecent = L.divIcon({
    className: "dummy",
    html: `
        <div class="CCTVMarkerIcon">
            <div class="CCTVMarkerStatus semi-recent"></div>
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 25px; height: 25px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
    iconSize: [25, 25],
    iconAnchor: [11, 27],
});

export const CCTVMarkerIconOld = L.divIcon({
    className: "dummy",
    html: `
        <div class="CCTVMarkerIcon">
            <div class="CCTVMarkerStatus old"></div>
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 25px; height: 25px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
    iconSize: [25, 25],
    iconAnchor: [11, 27],
});

export const CCTVMarkerGroupIcon = L.divIcon({
    className: "dummy",
    html: `
        <div class="CCTVMarkerIcon">
            <div class="cctv-icon" style="margin-left: 1px; margin-bottom: -2px; width: 25px; height: 25px; background: url(${cctvMarker}) no-repeat center center;"></div>
        </div>
        `,
    iconSize: [25, 25],
    iconAnchor: [11, 27],
});

export const TreeIcon = new L.Icon({
    iconUrl: require("icons/pins/tree.svg"),
    iconRetinaUrl: require("icons/pins/tree.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const SignalIcon = new L.Icon({
    iconUrl: require("icons/pins/traffic-light.svg"),
    iconRetinaUrl: require("icons/pins/traffic-light.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const TelecomsIcon = new L.Icon({
    iconUrl: require("icons/pins/telecoms.svg"),
    iconRetinaUrl: require("icons/pins/telecoms.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const TelecomsFibreIcon = new L.Icon({
    iconUrl: require("icons/pins/telecoms-fibre.svg"),
    iconRetinaUrl: require("icons/pins/telecoms-fibre.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const BookmarkIcon = new L.Icon({
    iconUrl: require("icons/pins/bookmark-icon.svg"),
    iconRetinaUrl: require("icons/pins/bookmark-icon.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const BallastBagIcon = new L.Icon({
    iconUrl: require("icons/pins/ballast-bag.svg"),
    iconRetinaUrl: require("icons/pins/ballast-bag.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const bridgeIcon = new L.Icon({
    iconUrl: require("icons/pins/bridge.svg"),
    iconRetinaUrl: require("icons/pins/bridge.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const limitedClearanceIcon = new L.Icon({
    iconUrl: require("icons/pins/limited-clearance.svg"),
    iconRetinaUrl: require("icons/pins/limited-clearance.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const pointIcon = new L.Icon({
    iconUrl: require("icons/pins/point.svg"),
    iconRetinaUrl: require("icons/pins/point.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const scrapRailIcon = new L.Icon({
    iconUrl: require("icons/pins/scrap-rail.svg"),
    iconRetinaUrl: require("icons/pins/scrap-rail.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: new L.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const speedBoardIcon = new L.Icon({
    iconUrl: require("icons/pins/speed-board.svg"),
    iconRetinaUrl: require("icons/pins/speed-board.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const tunnelIcon = new L.Icon({
    iconUrl: require("icons/pins/tunnel.svg"),
    iconRetinaUrl: require("icons/pins/tunnel.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const thermalHotspotIcon = new L.Icon({
    iconUrl: require("icons/pins/thermal-hotspot.svg"),
    iconRetinaUrl: require("icons/pins/thermal-hotspot.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const konuxIcon = new L.Icon({
    iconUrl: require("icons/pins/konux.svg"),
    iconRetinaUrl: require("icons/pins/konux.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const mediaUploadIcon = new L.Icon({
    iconUrl: require("icons/pins/media-upload.svg"),
    iconRetinaUrl: require("icons/pins/media-upload.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export const mediaUploadIconActive = new L.Icon({
    iconUrl: require("icons/pins/media-upload-active.svg"),
    iconRetinaUrl: require("icons/pins/media-upload-active.svg"),
    iconAnchor: new L.Point(20, 40),
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
});

export function createCustomIcon(iconName, colour = "#000000", selected = false) {
    let iconMap = {
        "spray-can": (
            <SprayCanSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        tree: (
            <TreeSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "traffic-light": (
            <TrafficLightSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        male: (
            <PersonSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        archway: (
            <ArchwaySvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "exclamation-triangle": (
            <ExclamationTriangleSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        balise: (
            <BaliseSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        axleCounter: (
            <AxleCounterSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "3dModel": (
            <Model3dSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        key: (
            <KeySvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        train: (
            <TrainSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        bolt: (
            <BoltSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        telecoms: (
            <TelecomsSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "telecoms-fibre": (
            <TelecomsFibreSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        bookmark: (
            <BookmarkSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "ballast-bag": (
            <BallastBagSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        bridge: (
            <BridgeSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "limited-clearance": (
            <LimitedClearanceSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        point: (
            <PointSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "scrap-rail": (
            <ScrapRailSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "speed-board": (
            <SpeedBoardSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        tunnel: (
            <TunnelSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "thermal-hotspot": (
            <ThermalHotspotSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        konux: (
            <KonuxSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "media-upload": (
            <MediaUploadSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "media-upload-active": (
            <MediaUploadActiveSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        ohl: (
            <OhlPin
                className="none"
                color={colour}
            />
        ),
        "zone-enter": (
            <ZoneEnterSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "zone-leave": (
            <ZoneLeaveSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "treatment-on": (
            <TreatmentOnSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        "treatment-off": (
            <TreatmentOffSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        exclamation: (
            <ExclamationSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
        leaf: (
            <LeafSvg
                id="leaflet-marker-icon"
                className="none"
                style={{ fill: colour }}
            />
        ),
    };
    return new L.divIcon({
        className: "div-icon" + (selected ? " selected" : ""),
        html: ReactDOMServer.renderToString(iconMap[iconName]),
        iconSize: new L.Point(40, 40),
        iconAnchor: new L.Point(20, 40),
        popupAnchor: new L.Point(0, -40),
    });
}
