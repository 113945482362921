import { jsonPostV2 } from "./apiUtils";

export const TOGGLE_MEGASEARCH = "TOGGLE_MEGASEARCH";

export const toggleMegasearch = (open) => {
    return {
        type: TOGGLE_MEGASEARCH,
        open,
    };
};

export const sendMegaSearch = (query, type) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let postBody = {
                action: "get_search_results",
                query,
                type,
            };

            let url = "/admin";

            return jsonPostV2(url, getState(), postBody, dispatch)
                .then((response) => {
                    if (response.results) {
                        resolve(response);
                    } else {
                        resolve({});
                    }
                })
                .catch((error) => {
                    reject();
                });
        });
    };
};
