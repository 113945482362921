import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraggableWrapper from "../DraggableWrapper";
import {
    toggleDetectionsOpen,
    setClassificationsTabOpen,
    toggleInspectionDetectionsExport,
    resetInspectionDetectionsExportIndexRange,
} from "../../../../redux/actions/index";
import { Tabs } from "antd";
import DetectionsNavigation from "./DetectionsNavigation";
import ClassificationsNavigation from "./ClassificationsNavigation";
import EventsNavigation from "./EventsNavigation";
import _ from "lodash";
import { groupAreasOfInterest } from "../../../util/PlaylistUtils";
import DetectionsExport from "./DetectionsExport";

const EMPTY_OBJ = {};
const routeMetadataSelector = (state) => _.get(state, ["routeMetadata", "AREA_OF_INTEREST"], EMPTY_OBJ);
const routeIDSelector = (state) => state.playlist.data.routeID;
const userAnnotationTypesSelector = (state) => state.userAnnotationTypes;
const detectionsExportOpenSelector = (state) => state.railInspection.detectionsExportOpen;

const limits = {
    minWidth: 400,
    minHeight: 350,
    maxWidth: 1000,
    maxHeight: 650,
}; //These need to correspond to the values in the css class draggableWrapper and &__tabContainer!

const AssetNavigation = ({ windowDimensions, labelWindowOpen, annotationWindowOpen, setAssetNavigationWindowOpen }) => {
    const dispatch = useDispatch();

    const routeMetadata = useSelector(routeMetadataSelector);
    const routeID = useSelector(routeIDSelector);
    const userAnnotationTypes = useSelector(userAnnotationTypesSelector);
    const detectionsExportOpen = useSelector(detectionsExportOpenSelector);

    const customDimensionsRef = useRef({ width: 600, height: limits.minHeight });

    const title = <div className="inspectRail__BookmarkList__Title">{detectionsExportOpen ? <p>Export Filtered Detections</p> : <p>Detections</p>}</div>;

    const closeNavigator = () => {
        if (detectionsExportOpen) {
            dispatch(toggleInspectionDetectionsExport());
            dispatch(resetInspectionDetectionsExportIndexRange());
        }
        dispatch(toggleDetectionsOpen());
        dispatch(setClassificationsTabOpen(false));
        setAssetNavigationWindowOpen(false);
    };

    const changeActiveTab = (activeKey) => {
        if (activeKey !== "Classifications") {
            dispatch(setClassificationsTabOpen(false));
        }
    };

    const sessionAreasOfInterest = useMemo(() => {
        const allMetadata = routeMetadata[routeID] || [];
        return groupAreasOfInterest(allMetadata, userAnnotationTypes);
    }, [routeMetadata, routeID, userAnnotationTypes]);

    const content = (
        <div className="inspectRail__Detections inspectRail__tabContainer">
            {detectionsExportOpen ? (
                <DetectionsExport />
            ) : (
                <Tabs
                    className="draggable-tabs inspectRail__tabContainer"
                    defaultActiveKey={"Detections"}
                    destroyInactiveTabPane
                    onChange={(activeKey) => changeActiveTab(activeKey)}>
                    <Tabs.TabPane
                        tab="Detections"
                        key="Detections"
                        className="inspectRail__tab inspectRail__tabContent">
                        <DetectionsNavigation shortcutsActive={!labelWindowOpen && !annotationWindowOpen} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Classifications"
                        key="Classifications"
                        className="inspectRail__tab inspectRail__tabContent">
                        <ClassificationsNavigation shortcutsActive={!labelWindowOpen && !annotationWindowOpen} />
                    </Tabs.TabPane>
                    {sessionAreasOfInterest.length > 0 && (
                        <Tabs.TabPane
                            tab="Events"
                            key="Events"
                            className="inspectRail__tab inspectRail__tabContent">
                            <EventsNavigation sessionAreasOfInterest={sessionAreasOfInterest} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            )}
        </div>
    );

    return (
        <DraggableWrapper
            content={content}
            title={title}
            windowDimensions={windowDimensions}
            closeAction={closeNavigator}
            customDimensionsRef={customDimensionsRef}
            customDimensions={{ width: customDimensionsRef.current.width, height: customDimensionsRef.current.height }}
            limits={limits}
            isResizable
            x={17}
            y={20}
        />
    );
};

export default AssetNavigation;
